import { isEmpty } from 'lodash';
import initialState from './initialState';
import {
  GET_IR_LIST_REQUEST,
  GET_IR_LIST_SUCCESS,
  GET_IR_LIST_FAILURE,
  CLEAR_IR_LIST_SUCCESS,
  ADD_EXISTING_IR_REQUEST, 
  ADD_EXISTING_IR_SUCCESS, 
  ADD_EXISTING_IR_FAILURE,
  GET_MANAGE_IR_LIST_REQUEST,
  GET_MANAGE_IR_LIST_SUCCESS,
  GET_MANAGE_IR_LIST_FAILURE,
} from '../../constants';

export default function reducer(
  state = initialState.industryRepresentatives,
  action
) {
  switch (action.type) {
  case GET_IR_LIST_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };
  case GET_IR_LIST_SUCCESS: {
    const irs = action.data.detail;
    return {
      ...state,
      isRequesting: false,
      data: [...state.data, ...irs],
    };
  }
  case GET_IR_LIST_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  case CLEAR_IR_LIST_SUCCESS:
    return {
      ...state,
      data: [],
      manageIrs: [],
    };

  case ADD_EXISTING_IR_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };

  case ADD_EXISTING_IR_SUCCESS: {
    return {
      ...state,
      isRequesting: false,
    };
  }
  case ADD_EXISTING_IR_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };
 
  case GET_MANAGE_IR_LIST_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };
  case GET_MANAGE_IR_LIST_SUCCESS: {
    let manageIrs = action?.data?.detail?.data;
    const page = action?.data?.detail?.page;
    const pageCount = action?.data?.detail?.total_pages;
    manageIrs = manageIrs.map((irDetail) =>{
      let feedbackRequests = [];
      if(isEmpty(irDetail.feedbackRequests)){
        feedbackRequests = ['Pending (0)', 'Completed (0)', 'Canceled (0)'];
      } else{
        const pendingStatus = irDetail.feedbackRequests.findIndex(status => status.search('Pending') !== -1 );
        if(pendingStatus === -1){
          feedbackRequests.push('Pending (0)');
        }else {
          feedbackRequests.push(irDetail.feedbackRequests[pendingStatus]);
        }

        const completedStatus = irDetail.feedbackRequests.findIndex(status => status.search('Completed') !== -1 );
        if(completedStatus === -1){
          feedbackRequests.push('Completed (0)');
        }else {
          feedbackRequests.push(irDetail.feedbackRequests[completedStatus]);
        }

        const canceledStatus = irDetail.feedbackRequests.findIndex(status => status.search('Canceled') !== -1 );
        if(canceledStatus === -1){
          feedbackRequests.push('Canceled (0)');
        }else {
          feedbackRequests.push(irDetail.feedbackRequests[canceledStatus]);
        }
      }
      return {
        ...irDetail,
        industry: irDetail.cluster,
        feedbackRequests: feedbackRequests,
      };
    });

    return {
      ...state,
      isRequesting: false,
      manageIrs,
      page: page,
      pageCount: pageCount,
    };
  }
  case GET_MANAGE_IR_LIST_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

    
  default:
    return state;
  }
}
