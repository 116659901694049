import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
  CardTitle,
  Popover,
  PopoverBody,
  Progress,
} from 'reactstrap';
import { isString, get } from 'lodash';
import classNames from 'classnames';

import { formatFormDataArray, getSkillsRating } from '../../../utils';
import Validator from '../../../validator';
import {
  editSkillVisibility,
  editBadgeVisibility,
} from '../../../redux/actions/myskills';
import { uploadFile } from '../../../redux/actions/files';
import StarRating from '../../rating/StarRating';
import { openModal } from '../../../redux/actions/components';
import { saveSelectedBadges } from '../../../redux/actions/badges';
import { deleteSkill } from '../../../redux/actions/skills';
import { updateSkillsOrder } from '../../../redux/actions/profile';
import { updateMyCareerPreppedStatus } from '../../../redux/actions/my-careerprepped-status';

import HideFromCareerSiteModal from '../HideFromCareerSiteModal';
import ShowOnCareerSiteModal from '../ShowOnCareerSiteModal';
import AllFeedbackReceived from '../AllFeedbackReceived';
import LevelUpBadgeModal from '../LevelUpBadgeModal.js';
import RequestEducators from '../RequestEducators.js';
import RequestPeers from '../RequestPeers.js';
import RequestIRs from '../RequestIRs.js';
import IRReqSubmitted from '../IRReqSubmitted.js';
import UpdateDetails from '../UpdateDetails';
import PortfolioFileModal from '../PortfolioFileUploadModal';
import ReviewsBar from '../ReviewsBar';
import EndorsementModal from '../../../containers/skill-badges/EndorsementModal';
import RequestFeedbackModal from '../../../containers/feedback/RequestFeedbackModal';
import { SimpleToolTipWrapper } from '../../common/ToolTipWrapper';
import { SETTINGS_DATE_FORMAT, FEEDBACK_REQUESTED_DATE_FORMAT } from '../../../constants';
import LinkingPortfolioModal from '../../../containers/skill-badges/LinkingPortfolioModal';
import ProfileModal from '../../../components/profile/modal/ProfileModal';

import CloseIcon from '../../../assets/images/close.svg';

const prefixWithZero = (num) => {
  return Number(num)?.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
};

class SkillCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      popoverOpen: false,
      isShown: this.props.data?.visibility,
      hideOnCareerSite: false,
      showOnCareerSite: false,
      allFeedbackReceived: false,
      requestModal: false,
      requestEducators: false,
      requestPeers: false,
      requestIRs: false,
      iRRequestSubmiited: false,
      detailModal: false,
      requestFeedbackModalForAdmin: false,
      fileUploaded: false,
      selectedBadgeId: '',
      toggleDeleteModal: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.setState({ popoverOpen: false });
    }
  }

  toggleRequestFeedbackModal = () => {
    this.setState({
      requestFeedbackModalForAdmin: !this.state.requestFeedbackModalForAdmin,
    });
  };

  toggleModal = (event) => {
    const { data } = this.props;
    let modal = null;

    const dataValue = event?.target?.getAttribute('data-value');

    if (isString(event)) {
      modal = event;
    } else {
      modal = dataValue || event.target.value;
    }

    if (
      modal === 'requestEducators' ||
      modal === 'requestIRs' ||
      modal === 'requestModal' ||
      modal === 'fileUploaded'
    ) {
      if (parseInt(data.filesCount) === 0 && modal !== 'fileUploaded' && data.skill_id) {
        this.setState({ selectedOption: modal });
        modal = 'fileUploaded';
      }
    }

    this.setState((state) => {
      return {
        [modal]: !state[modal],
        popoverOpen: false,
      };
    });
  };

  proceed = (modal) => {
    let arr = [
      'requestModal',
      'requestEducators',
      'requestPeers',
      'requestIRs',
      'iRRequestSubmiited',
      'fileUploaded',
      'reviewsBar',
    ];

    this.setState((state) => {
      const d = {};
      arr.forEach((a) => {
        if (a === modal) {
          d[a] = !state[modal];
        } else {
          d[a] = false;
        }
      });
      return d;
    });
  };

  proceedPortfolioFile = (file) => {
    this.onSavePortfolioFile(file);
  };

  createFormData(
    description,
    file,
    title,
    selectedVisibiltyId,
    externalurl,
    badge_id,
    skill_id
  ) {
    let formData = new FormData();

    formData.append('description', description);
    formData.append('title', title);
    formData = formatFormDataArray(formData, 'badges', [badge_id]);
    formData.append('visibility', selectedVisibiltyId);
    formData = formatFormDataArray(formData, 'skills', [skill_id]);
    formData.append('section', 'portfolio');

    if (externalurl !== '') {
      formData.append('externalurl', externalurl);
      return formData;
    }

    if (file) {
      formData.append('file', file);
    }

    return formData;
  }

  isValid(field = null) {
    const validate = Validator.createValidator(
      {
        title: ['required', 'maxLength|60'],
        description: ['required', 'minLength|1', 'maxLength|600'],
        url: ['url'],
        file: ['choseAtleastOne|file,url'],
      },
      this.state,
      field
    );

    const { errors: prevErrors } = this.state;
    const { errors, isValid } = validate;

    if (field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors) });

    return isValid;
  }

  onSavePortfolioFile = (file) => {
    const data = this.props.data;

    const description = data.name;
    const title = file?.name;
    const badges = data.badge_id ? data.badge_id : null;
    const skills = data.skill_id ? data.skill_id : null;
    const externalurl = null;
    const selectedVisibiltyId = data.visibility;

    this.props.actions
      .uploadFile(
        this.createFormData(
          description,
          file,
          title,
          selectedVisibiltyId,
          externalurl,
          badges,
          skills
        )
      )
      .then(() => {
        toastr.success('File uploaded successfully');
      });
    this.proceed('fileUploaded');
  };

  showBadge = () =>
    this.setState(() => {
      return {
        showOnCareerSite: true,
      };
    });

  hideBadge = () => {
    this.setState({
      hideOnCareerSite: true,
    });
  };

  confirmHide = async () => {
    await this.props.setIsSearchQueryState(true);
    if (this.props.data?.badge_id) {
      this.props.actions
        .editBadgeVisibility(
          {
            visibility: 0,
          },
          this.props.data?.issuedID
        )
        .then(() => {
          this.setState({
            hideOnCareerSite: false,
            isShown: false,
          });
          this.props.getMySkills(null, null, 1, null, true);
        });
    } else {
      this.props.actions
        .editSkillVisibility(
          {
            visibility: 0,
          },
          this.props.data?.skill_id
        )
        .then(() => {
          this.setState({
            hideOnCareerSite: false,
            isShown: false,
          });
          this.props.getMySkills(null, null, 1, null, true);
        });
    }
  };

  confirmShow = async () => {
    await this.props.setIsSearchQueryState(true);
    if (this.props.data?.badge_id) {
      this.props.actions
        .editBadgeVisibility(
          {
            visibility: 1,
          },
          this.props.data?.issuedID
        )
        .then(() => {
          this.setState({
            showOnCareerSite: false,
            isShown: true,
          });
          this.props.getMySkills(null, null, 1, null, true);
        });
    } else {
      this.props.actions
        .editSkillVisibility(
          {
            visibility: 1,
          },
          this.props.data?.skill_id
        )
        .then(() => {
          this.setState({
            showOnCareerSite: false,
            isShown: true,
          });
          this.props.getMySkills(null, null, 1, null, true);
        });
    }
  };

  closeHideModal = () => {
    this.setState({
      hideOnCareerSite: false,
    });
  };

  closeShowModal = () => {
    this.setState({
      showOnCareerSite: false,
    });
  };

  seeAllFeedback = () => {
    this.setState({
      allFeedbackReceived: true,
      popoverOpen: false,
    });
  };
  closeAllFeedback = () => {
    this.setState({
      allFeedbackReceived: false,
    });
  };

  togglePopover = () =>
    this.setState((state) => {
      return {
        popoverOpen: !state.popoverOpen,
      };
    });

  toggleDropdown = () =>
    this.setState((state) => {
      return {
        dropdownOpen: !state.dropdownOpen,
      };
    });

  getRatings = (id) => {
    return (
      (this.props.ratings &&
        this.props.ratings.find(r => (r?.skillId === id || r?.badgeId === id))) || {
        endorsementCount: 0,
        endorsements: 0,
        endorsementDate: null,
        portfolioFileCount: 0,
        questionRespRating: 0,
        porfolioRating: 0,
        final_score: 0,
      }
    );
  };

  getStarRating(data, showAvgRating = true) {
    if (showAvgRating) {
      return getSkillsRating(data);
    }
  }

  saveSelectedBadges = (data) => {
    const { actions } = this.props;
    const selectedBadges = [
      {
        id: data.badge_id ? data.badge_id : data.skill_id,
        name: data.name,
        issued: { id: data.badge_id ? data.issuedID : data.skill_id },
        files: data.files,
        type: data.badge_id ? 'badge' : 'skill'
      },
    ];

    this.setState({
      selectedBadgeId: data.badge_id && data.skill_id
    });
    actions.saveSelectedBadges(selectedBadges);
  }

  onToggleEndorsementModal = () => {
    const { data } = this.props;
    this.setState({ popoverOpen: false });

    if (!this.state.endorsementModal && (data.badge_id || data.skill_id)) {
      this.saveSelectedBadges(data);
      return this.toggleModal('endorsementModal');
    }
  }

  closeEndorsementModal = () => {
    this.setState({
      endorsementModal: false,
    });
  }

  onToggleLinkingFilesModal = (event) => {
    const { modal, actions, data } = this.props;
    const selectedId = event.target.getAttribute('data-value');
    const isFilesModalOpen = modal && modal === 'fileModalInBakedBadgePage';

    this.setState({ popoverOpen: false });

    if (!isFilesModalOpen && (selectedId === data.badge_id || selectedId === data.skill_id)) {
      this.saveSelectedBadges(data);
      return actions.openModal('fileModalInBakedBadgePage');
    }
    this.setState({ selectedBadgeId: '' });
    actions.closeModal();
  }

  openGiveFeedbackPage = (event) => {
    const skillId = event.target.getAttribute('skill-id');
    const badgeId = event.target.getAttribute('badge-id');
    if (skillId !== null) {
      window.location.href = `/admingivefeedback/skill/${skillId}`;
    }
    if (badgeId !== null) {
      window.location.href = `/admingivefeedback/badge/${badgeId}`;
    }
  }

  onToggleDeleteModal = () => {
    this.setState({ toggleDeleteModal: !this.state.toggleDeleteModal });
  }

  onDelete = () => {
    const { data, actions, profile, currentUser } = this.props;

    const skill = {
      skillsOrder: profile.skillsOrder.split(',').filter(skillId => skillId !== data.skill_id).join()
    };

    actions.deleteSkill(data.skill_id)
      .then(() =>
        this.setState({ toggleDeleteModal: !this.state.toggleDeleteModal })
      );
    actions.updateSkillsOrder(skill, currentUser.id);
    actions.updateMyCareerPreppedStatus();
  }

  render() {
    const { toggleDeleteModal } = this.state;
    const { data, modal, profile, isDeleting, currentUser } = this.props;

    const ownerId = data.user.id;
    const currentUserId = profile.id;
    const isEducator = profile.role === 'educator';
    const isOwner = ownerId === currentUser.id;
    const uniqueId = data.skill_id
      ? `Popover-${data.skill_id}`
      : `Popover-${data.badge_id}`;

    const defaultImageLink = data.skill_id ? '../../../assets/images/temp/skill.png' : '../../../assets/images/temp/badge.png';
    const isFilesModalOpen = modal && modal === 'fileModalInBakedBadgePage';
    const totalReview = parseInt(data.totalReview);

    return (
      <div className="skill-card">
        <Card>
          <CardBody className="p-0">
            <div className="skill-card-header">
              <Link
                to={data.skill_id ? `/skill-evidence/${data.skill_id}`
                  : `/skill-badges/badge-details/${data.issuedID}`}
                className="d-flex align-items-center">
                {data.badge_id && <div>
                  <img
                    className="skillImg"
                    src={data.imageURL ? data.imageURL : defaultImageLink} />
                </div>}
                <div className={`skillTitleContainer ${data.badge_id ? 'skillBadgeTitle' : 'skillTitle'}`}>
                  <CardTitle title={data.name} className="font-weight-bold font16 mb-0">
                    {data.name}
                  </CardTitle>
                </div>
              </Link>
            </div>
            <div className="skill-card-details d-flex">
              <div className="count d-flex flex-column align-items-start">
                <div>
                  <SimpleToolTipWrapper
                    id={`last-file-cont-${uniqueId}`}
                    toolTipContent={
                      <div className="inside-tooltip">
                        {`Last File:
                      ${data?.lastPortfolioUploadedOn}`}
                      </div>
                    }>
                    <span className="d-inline-block countBadge">
                      {prefixWithZero(data.filesCount)}
                    </span>
                  </SimpleToolTipWrapper>

                  <span>Portfolio Files</span>
                </div>
                <div>
                  {data.skill_id && (
                    <SimpleToolTipWrapper
                      id={`last_endorsement_cont-${uniqueId}`}
                      toolTipContent={
                        <div className="inside-tooltip">
                          {`Last Endorsement:
                          ${data?.endorsementDate
                      ? moment(
                        data.endorsementDate,
                        SETTINGS_DATE_FORMAT
                      ).format(FEEDBACK_REQUESTED_DATE_FORMAT)
                      : 'N/A'
                    }`}
                        </div>
                      }>
                      <span className="d-inline-block countBadge">
                        {data
                          .endorsementCount
                          ? prefixWithZero(
                            data
                              .endorsementCount
                          )
                          : '00'}
                      </span>
                    </SimpleToolTipWrapper>
                  )}

                  {data.badge_id && (
                    <SimpleToolTipWrapper
                      id={`last_endorsement_cont_${uniqueId}`}
                      toolTipContent={
                        <div className="inside-tooltip">
                          {`Last Endorsement:  ${data?.endorsementDate
                            ? moment(
                              data.endorsementDate,
                              SETTINGS_DATE_FORMAT
                            ).format(FEEDBACK_REQUESTED_DATE_FORMAT)
                            : 'N/A'
                          }`}
                        </div>
                      }>
                      <span className="d-inline-block countBadge">
                        {data
                          .endorsementCount
                          ? prefixWithZero(
                            data
                              .endorsementCount
                          )
                          : '00'}
                      </span>
                    </SimpleToolTipWrapper>
                  )}

                  <span>Endorsements</span>
                </div>
              </div>
              <div className="ratingRow d-flex flex-column align-items-end">
                <div className="rating mb-2">
                  <div
                    id={uniqueId}
                    onClick={this.togglePopover}
                    className="d-inline pointer">
                    <StarRating rating={`${data.avgRating}`} endorsementCount={parseInt(data.endorsementCount)} />
                  </div>
                  <Popover
                    className="mySkillsContainerStyle"
                    placement="right"
                    isOpen={this.state.popoverOpen}
                    target={
                      data.skill_id
                        ? `Popover-${data.skill_id}`
                        : `Popover-${data.badge_id}`
                    }
                    trigger="legacy"
                    toggle={this.togglePopover}>
                    <PopoverBody className="p-0 mySkillsContainerStyle">
                      <div className="p-3">
                        <div onClick={this.togglePopover}>
                          <img
                            className="popoverClose"
                            src={CloseIcon} />
                        </div>
                        <div className="d-flex justify-content-between">
                          <StarRating rating={`${data.avgRating}`} endorsementCount={parseInt(data.endorsementCount)} />
                          <span className="font-weight-bold ml-1 font16">
                            {
                              `${this.getStarRating(data) || 0}`
                            }{' '}
                            out of 5 Stars
                          </span>
                        </div>
                        <p className="font-weight-bold mb-1 mt-3">
                          {totalReview || 'No'}{' '}
                          Reviews
                        </p>
                        <p className="popover-text">
                          {data.badge_id ?
                            `Badges with strong evidence get the best ratings. Add
                              portfolio evidence, seek endorsements, and make
                              improvements based on feedback you get.` :
                            `Skills with strong portfolio evidence get the best ratings.
                                  Add portfolio evidence, seek endorsements,
                                  and make improvements based on feedback you get.`}
                        </p>
                        {data.issuedID &&
                          <>
                            <p className="font-weight-bold mb-1 mt-3">
                              Question Response Ratings:
                            </p>
                            <div className="d-flex justify-content-around align-items-center">
                              <span>
                                0
                              </span>
                              <Progress
                                className="mx-1 d-inline-block mb-0 roundedCorners"
                                value={
                                  (get(data,
                                    'questionRespRating', 0) * 20)
                                } />
                              <span>5</span>
                            </div>
                          </>
                        }

                        {<>
                          <p className="font-weight-bold mb-1 mt-3">
                            Portfolio Ratings:
                          </p>
                          <div className="d-flex justify-content-around align-items-center">
                            <span>
                              0
                            </span>
                            <Progress
                              className="mx-1 d-inline-block mb-0 roundedCorners"
                              value={
                                (get(data,
                                  'porfolioRating', 0) * 20)
                              } />
                            <span>5</span>
                          </div>
                        </>
                        }
                        {
                          (!this.props.isAdmin && parseFloat(data.avgRating) >= 4.5 && data.endorsementCount === 0) ?
                            (<div className="mt-3 d-flex align-items-center mb-3">
                              <div className="iconWrapper ml-0 mr-3">
                                <i className="icon-star-border" />
                              </div>
                              <span className="orangeLink pointer" data-value={data.badge_id || data.skill_id}
                                onClick={this.onToggleEndorsementModal}>
                                Seek Endorsement to Level up
                              </span>
                            </div>) : (!this.props.isAdmin && parseInt(data.filesCount) === 0 ?
                              <div className="mt-3 d-flex align-items-center mb-3">
                                <div className="iconWrapper ml-0 mr-3">
                                  <i className="icon-star-border" />
                                </div>
                                <span className="orangeLink pointer" data-value={data.badge_id || data.skill_id}
                                  onClick={this.onToggleLinkingFilesModal}>
                                  Add portfolio evidence to level up
                                </span>
                              </div> : null)
                        }
                      </div>
                      <div className="popover-footer text-center p-1">
                        <Button className="popover-button" onClick={this.seeAllFeedback}>See Feedback</Button>
                      </div>
                    </PopoverBody>
                  </Popover>
                </div>
                <div className="reviews">
                  <span
                    data-value="reviewsBar"
                    onClick={totalReview > 0 ? this.toggleModal : undefined}
                    className={classNames({
                      'text-orange': totalReview === 0,
                      'themeLink pointer': totalReview > 0
                    })}>
                    {`${totalReview} Reviews`}
                  </span>
                </div>
              </div>
            </div>
          </CardBody>
          <div className="customCardFooter d-flex justify-content-between align-items-center">
            {!this.props.isAdmin && data.visibility === '0' && (
              <SimpleToolTipWrapper id={`${uniqueId}-tooltipshow`} toolTipContent={
                <div
                  className="inside-tooltip">
                  {`This ${data.skill_id ? 'skill\'s evidence page' : 'badge'} is hidden on your Career Site. Click to make it visibile.`}
                </div>
              }>
                <span
                  onClick={
                    localStorage.getItem([
                      `${data.badge_id || data.skill_id}_show`,
                    ])
                      ? this.confirmShow
                      : this.showBadge
                  }
                  className="pointer">
                  <i className="fa fa-eye eye pr-2" />
                  {`Show ${data.skill_id ? 'Evidence' : 'Badge'}`}

                </span>
              </SimpleToolTipWrapper>
            )}
            {!this.props.isAdmin && data.visibility === '1' ? (
              <SimpleToolTipWrapper id={`${uniqueId}-tooltiphide`} toolTipContent={
                <div
                  className="inside-tooltip">
                  {`This ${data.skill_id ? 'skill’s evidence page ' : 'badge'} is visible on your Career Site. Click to hide it.`}
                </div>
              }>
                <span
                  onClick={
                    localStorage.getItem([
                      `${data.badge_id || data.skill_id}_hide`,
                    ])
                      ? this.confirmHide
                      : this.hideBadge
                  }
                  className="pointer">
                  <i className="fa fa-eye-slash eye pr-2" />
                  {`Hide ${data.skill_id ? 'Evidence' : 'Badge'}`}
                </span>
              </SimpleToolTipWrapper>
            ) : null}
            <Dropdown
              isOpen={this.state.dropdownOpen}
              toggle={this.toggleDropdown}>
              <DropdownToggle caret className="dropdownAction addSkillButton">
                Actions
              </DropdownToggle>
              <DropdownMenu>
                {(data.filesCount > 0 || data.badge_id) &&
                  this.props.isAdmin &&
                  (ownerId !== currentUserId) &&
                  (!isEducator) &&
                    (<DropdownItem onClick={this.toggleRequestFeedbackModal}>
                      Request Feedback
                    </DropdownItem>)}

                {(data.filesCount > 0 || data.badge_id) &&
                  this.props.isAdmin &&
                  (ownerId !== currentUserId) &&
                  (!isEducator) &&
                   (<DropdownItem
                     onClick={this.openGiveFeedbackPage}
                     skill-id={data.skill_id}
                     badge-id={data.issuedID}>
                     Give Feedback
                   </DropdownItem>)}

                {!this.props.isAdmin && (
                  <>
                    <DropdownItem data-value={data.badge_id || data.skill_id}
                      onClick={this.onToggleLinkingFilesModal}>Add Portfolio Evidence</DropdownItem>
                    <DropdownItem
                      value="requestModal"
                      onClick={this.toggleModal}>
                      Seek Feedback
                    </DropdownItem>
                    <DropdownItem
                      onClick={this.onToggleEndorsementModal}>
                      Seek Endorsements
                    </DropdownItem>
                  </>
                )}
                <DropdownItem onClick={this.seeAllFeedback}>
                  See Feedback
                </DropdownItem>
                {!this.props.isAdmin && (
                  <DropdownItem>
                    <Link
                      to={data.skill_id ? `/skill-evidence/${data.skill_id}`
                        : `/skill-badges/badge-details/${data.issuedID}`}>
                      {`Edit ${data.skill_id ? 'Skill' : 'Badge'
                      }`}
                    </Link></DropdownItem>
                )}

                {(data.skill_id && isOwner) &&
                  <DropdownItem onClick={this.onToggleDeleteModal}>
                    Delete
                  </DropdownItem>}
              </DropdownMenu>
            </Dropdown>
          </div>

          <ProfileModal
            isOpen={toggleDeleteModal}
            toggle={this.onToggleDeleteModal}
            headerText="Delete Skill"
            body="Are you sure you want to delete this skill?"
            onClick={this.onDelete}
            disabled={isDeleting.status}
            buttonText={isDeleting.status ? 'Deleting...' : 'Delete'} />

          {this.state.requestFeedbackModalForAdmin && data.skill_id && (
            <RequestFeedbackModal
              isOpen={this.state.requestFeedbackModalForAdmin}
              onCancel={this.toggleRequestFeedbackModal}
              skillId={data.skill_id}
              assertionBadge={data}
              institutionId={this.props.institutionId}
              user={data.user} />
          )}
          {this.state.requestFeedbackModalForAdmin && data.badge_id && (
            <RequestFeedbackModal
              isOpen={this.state.requestFeedbackModalForAdmin}
              onCancel={this.toggleRequestFeedbackModal}
              badgeIssuedId={data.issuedID}
              assertionBadge={data}
              institutionId={this.props.institutionId}
              user={data.user} />
          )}
        </Card>
        {!localStorage.getItem([`${data.badge_id || data.skill_id}_hide`]) && (
          <HideFromCareerSiteModal
            isBadge={!!data.badge_id}
            resourceId={data.badge_id || data.skill_id}
            toggleModal={this.closeHideModal}
            confirmHide={this.confirmHide}
            isOpen={this.state.hideOnCareerSite} />
        )}
        {!localStorage.getItem([`${data.badge_id || data.skill_id}_show`]) && (
          <ShowOnCareerSiteModal
            isBadge={!!data.badge_id}
            resourceId={data.badge_id || data.skill_id}
            toggleModal={this.closeShowModal}
            confirmShow={this.confirmShow}
            isOpen={this.state.showOnCareerSite} />
        )}
        {this.state.allFeedbackReceived && (
          <AllFeedbackReceived
            data={data}
            toggleModal={this.closeAllFeedback}
            ratings={data}
            isOpen={this.state.allFeedbackReceived} />
        )}
        {isFilesModalOpen && (data?.badge_id === this.state.selectedBadgeId || data?.skill_id === this.state.selectedBadgeId) && (
          <LinkingPortfolioModal isOpen={isFilesModalOpen}
            bakedBadgePage
            getMyBadges={this.props.getMySkills}
            getMySkills={this.props.getMySkills}
            skillsCard
            linkingSkill={data?.skill_id && true}
            filesCount={`${data.filesCount}`} />
        )}
        <PortfolioFileModal
          isOpen={this.state.fileUploaded}
          toggleModal={this.toggleModal}
          proceedWithFile={this.onToggleLinkingFilesModal}
          selectedId={data.skill_id || data.badge_id} />
        <LevelUpBadgeModal
          isOpen={this.state.requestModal}
          educators={this.props.educators}
          industryReps={this.props.industryReps}
          toggleModal={this.toggleModal}
          proceed={this.proceed}
          type={data.skill_id ? 'Skill' : 'Badge'} />
        <UpdateDetails
          isOpen={this.state.detailModal}
          toggleModal={this.toggleModal}
          proceed={this.proceed}
          profile={this.props.profile} />
        {this.state.endorsementModal && <EndorsementModal onClose={this.closeEndorsementModal} />}
        {this.state.requestEducators && <RequestEducators
          badge_id={data.badge_id}
          data={data}
          skill_id={data.skill_id}
          isOpen={this.state.requestEducators}
          toggleModal={this.toggleModal} />}
        {this.state.requestPeers && <RequestPeers
          badge_id={data.badge_id}
          data={data}
          skill_id={data.skill_id}
          isOpen={this.state.requestPeers}
          toggleModal={this.toggleModal} />}
        <RequestIRs
          badge_id={data.badge_id}
          skill_id={data.skill_id}
          data={data}
          isOpen={this.state.requestIRs}
          toggleModal={this.toggleModal}
          proceed={this.proceed}
          profile={this.props.profile} />
        <IRReqSubmitted
          isOpen={this.state.iRRequestSubmiited}
          toggleModal={this.toggleModal} />
        {this.state.reviewsBar && <ReviewsBar
          resourceId={data.skill_id || data.issuedID}
          isOpen={this.state.reviewsBar}
          toggleModal={this.toggleModal} />
        }
      </div>
    );
  }
}

SkillCard.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  getMySkills: PropTypes.func.isRequired,
  ratings: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool,
  educators: PropTypes.array,
  industryReps: PropTypes.number,
  profile: PropTypes.object,
  modal: PropTypes.string,
  institutionId: PropTypes.string,
  isDeleting: PropTypes.object.isRequired,
  setIsSearchQueryState: PropTypes.func,
  currentUser: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  const {
    auth: { data: { currentUser } },
    components: { modal },
    myskills: {
      isDeleting,
      data: { totalPages, badgeLinks, educators, industryReps, ratings }
    }
  } = state;

  let ratingsArray = [];

  if (ratings && ratings.length) {
    ratingsArray = [...ratings];
  }

  return {
    totalPages,
    badgeLinks,
    educators,
    industryReps,
    isDeleting,
    modal,
    currentUser,
    ratings: ratingsArray
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = {
    editBadgeVisibility,
    editSkillVisibility,
    uploadFile,
    openModal,
    saveSelectedBadges,
    deleteSkill,
    updateSkillsOrder,
    updateMyCareerPreppedStatus
  };

  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillCard);
