import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { parseJSON } from '../../../utils';

import * as webpageActions from '../../../redux/actions/webpage';
import * as componentActions from '../../../redux/actions/components';
import * as badgeActions from '../../../redux/actions/badges';
import * as networksActions from '../../../redux/actions/networks';
import * as routerActions from '../../../redux/actions/router';

import { Helmet } from 'react-helmet';
import Summary from '../sections/Summary';
import Loading from '../../../components/common/Loading';
import SkillBadgeSectionContainer from '../sections/SkillBadgeSectionContainer';
import ProfileJumbotron from '../../../components/profile/header/ProfileJumbotron';
import WebpageSectionList from '../../../components/profile/visiting/WebpageSectionList';
import RestrictedWebpageSection from '../../../components/profile/visiting/RestrictedWebpageSection';
import { forEach, intersection, isEmpty } from 'lodash';

class Webpage extends Component {
  static propTypes = {
    isRequesting: PropTypes.bool,
    actions: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    webpage: PropTypes.object.isRequired,
    vanity: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    networks: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    profileOrder: PropTypes.array.isRequired,
    adminInstitutionIds: PropTypes.array,
  }

  constructor(props) {
    super(props);

    this.state = {
      isAdminViewing: null,
      showHiddenSkillBadges: false
    };
  }

  componentDidMount(){
    const { vanity, actions, isAuthenticated } = this.props;
    const isVanityArray = Array.isArray(vanity);
    actions.resetSocialLinksState();
    actions.clearWebpageData();

    if(isVanityArray){
      return actions.webpageDetailsRequest(vanity[0], isAuthenticated);
    }

    actions.webpageDetailsRequest(vanity, isAuthenticated);
  }

  componentWillReceiveProps(nextProps){
    const { isRequesting: prevIsRequesting, actions } = this.props;
    const { data } = this.props.webpage;
    const { isRequesting, webpage } = nextProps;

    if(data !== webpage.data && !isRequesting && webpage.data.isPrivacyVisible ){
      this.props.actions.userSocialLinksRequest(webpage.data.id);
    }

    if(isRequesting !== prevIsRequesting && !isRequesting && prevIsRequesting &&
        Object.keys(webpage.data).length === 0){
      return actions.redirect('/user-not-found');
    }
  }

  componentWillUnmount(){
    this.props.actions.clearWebpageData();
  }

  setShowHiddenSkillBadges = (event) => {
    const { checked } = event.target;
    this.setState({
      showHiddenSkillBadges: checked
    });
  }

  getAffiliatedOrganizationsId = () => {
    const { webpage } = this.props;
    const { data: webpageData } = webpage;
    let institutionIds = [];
    forEach(webpageData.affiliatedOrganizations, (institution) => {
      institutionIds.push(institution.id);
    });
    return institutionIds;
  }

  render() {
    const {
      isRequesting,
      webpage,
      currentUser,
      profileOrder,
      networks,
      isAuthenticated,
      adminInstitutionIds,
    } = this.props;

    const { data: webpageData } = webpage;
    const affiliatedOrganizationsIds = this.getAffiliatedOrganizationsId();

    let component = null;

    if(isRequesting){
      component = (
        <div style={{marginTop: 100}}>
          <Loading/>
        </div>
      );
    }

    if (!isRequesting && Object.keys(webpageData).length > 0) {
      component = (
        <section id="profile">
          <Helmet title={isRequesting ? '' : `${webpageData.name}'s Webpage`}/>
          <ProfileJumbotron
            profile={webpageData}
            networks={networks}
            currentUser={currentUser}
            isAuthenticated={isAuthenticated}/>
          <div className="container">
            <div className="row">
              {
                webpageData.isPrivacyVisible &&
                  <div className="profile-page col-lg-12">
                    <div className="profile-item summary">
                      <Summary
                        profile={webpageData}
                        isPublicProfile/>
                    </div>
                    <SkillBadgeSectionContainer
                      profile={webpageData}
                      isVisiting
                      isPublicProfile
                      setShowHiddenSkillBadges={this.setShowHiddenSkillBadges}
                      showHiddenSkillBadges={this.state.showHiddenSkillBadges}
                      displayHiddenBadges={!isEmpty(intersection(adminInstitutionIds, affiliatedOrganizationsIds))}/>
                    <WebpageSectionList
                      profile={webpageData}
                      profileOrder={profileOrder}
                      isAdminViewing={!isEmpty(intersection(adminInstitutionIds, affiliatedOrganizationsIds))}/>
                  </div>
              }

              {!webpageData.isPrivacyVisible && <RestrictedWebpageSection/>}
            </div>
          </div>
        </section>);
    }

    return (
      <div>
        {component}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { webpage } = state;

  const defaultOrder = [
    { id: 1, name: 'Skills' },
    { id: 2, name: 'Education' },
    { id: 3, name: 'Experience' },
  ];

  const profileOrder = typeof webpage.data.profileorder === 'undefined' ?
    defaultOrder :
    webpage.data.profileorder.length === 0 ?
      defaultOrder :
      parseJSON(webpage.data.profileorder);

  return {
    isRequesting: webpage.isRequesting,
    profileOrder,
    isAuthenticated: state.auth.isAuthenticated,
    networks: state.networks,
    webpage
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, webpageActions, componentActions, badgeActions, networksActions, routerActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};



export default connect(mapStateToProps, mapDispatchToProps)(Webpage);
