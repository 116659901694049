import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { toastr } from 'react-redux-toastr';

import { isEmpty } from 'lodash';

import { withHooks } from '../../utils/withHooks';

import Validator from '../../validator';
import Loading from '../../components/common/Loading';
import InvitationLogin from './InvitationLogin';
import InvitationSignupForm from '../../components/auth/InvitationSignupForm';

import * as routerActions from '../../redux/actions/router';
import * as componentActions from '../../redux/actions/components';
import * as authenticationActions from '../../redux/actions/authentication';
import * as orgInvitationActions from '../../redux/actions/organization-invitations';

const cpLogo = require('../../assets/images/CP_logo_no-mark.png');

class Invitation extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    newUser: PropTypes.object.isRequired,
    organizationInvitations: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    inviteDetails: PropTypes.object,
    auth: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      isSignupProcess: false,
      loadingUserDetails: false,
      newUser: Object.assign({}, this.props.newUser)
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { actions, newUser, isAuthenticated } = this.props;
    const { token } = newUser;

    if(isAuthenticated)
      actions.userLogout();

    actions.getInviteDetails(token);
  }

  componentWillReceiveProps(nextProps) {
    const { data } = this.props.auth;
    const { isRequesting: nextIsSubmitting, data: nextData, isAuthenticated } = nextProps.auth;

    if (data !== nextData && !nextIsSubmitting && isAuthenticated && !this.state.isSignupProcess) {
      this.setState({
        loadingUserDetails: true,
      }, () => this.joinOrganisationAndRedirect());
    }

    const { data: signupData, inviteDetails } = this.props.organizationInvitations;
    const {
      isSubmitting: nextSignupIsSubmitting,
      data: nextSignupData,
      inviteDetails: nextInviteDetails
    } = nextProps.organizationInvitations;

    if (inviteDetails !== nextInviteDetails && nextInviteDetails.user.name) {
      this.setState({
        newUser: {
          ...this.state.newUser,
          name: nextInviteDetails.user.name
        }
      });
    }

    if (signupData !== nextSignupData && !nextSignupIsSubmitting && !isAuthenticated) {
      this.setState({
        loadingUserDetails: true,
      }, () => this.autoLogin());
    }
  }

  autoLogin() {
    const { email, password } = this.state.newUser;

    this.props.actions.authenticateUser({ email, password })
      .then(() => this.redirectAndCloseModal());
  }

  joinOrganisationAndRedirect() {
    const { actions, auth } = this.props;
    const { newUser: { token, name, password } } = this.state;

    const data = { token, name, password };

    actions.joinCP(data, auth.isAuthenticated)
      .then(() => this.redirectAndCloseModal());
  }

  redirectAndCloseModal() {
    const { actions, navigate, isAuthenticated } = this.props;
    const { redirect } = actions;

    if (isAuthenticated) {
      redirect('/home');
      navigate('/home');
    }
  }

  isValid(field = null) {
    let validate = Validator.createValidator({
      firstname: ['required', 'minLength|1'],
      lastname: ['required', 'minLength|1'],
      password: ['required', 'minLength|8'],
      rePassword: ['required', 'minLength|8', 'match|password']
    }, this.state.newUser, field);

    let { isValid, errors } = validate;

    this.setState({ errors });

    return isValid;
  }

  onLogin() {
    const { newUser: { email, password }, errors } = this.state;

    if (Object.keys(errors).length > 0) return false;

    this.props.actions.authenticateUser({ email, password });
  }

  onSubmit(event) {
    event.preventDefault();

    const { inviteDetails } = this.props;

    if (!isEmpty(inviteDetails.user)) {
      return this.onLogin();
    }

    const { newUser } = this.state;

    if (! this.isValid()) return false;

    if (newUser.password !== newUser.rePassword) {
      return toastr.error('Entered passwords do not match.');
    }

    const name = `${newUser.firstname} ${newUser.lastname}`;

    const data = {
      'token': newUser.token,
      'name': name,
      'password': newUser.password,
    };

    this.setState({
      isSignupProcess: true
    }, () => this.props.actions.joinCP(data));

  }

  onChange(event) {
    let { name, value, type, checked } = event.target;
    const { newUser } = this.state;
    value = type === 'checkbox' ? checked : value;

    newUser[name] = value;

    this.setState({ newUser }, () => this.isValid(name));
  }

  render() {
    const { organizationInvitations, inviteDetails } = this.props;
    const { isSubmitting, data, isRequesting } = organizationInvitations;
    const {
      newUser,
      errors,
      loadingUserDetails
    } = this.state;

    const isUserRegistered = !isEmpty(inviteDetails.user);

    const component = !isUserRegistered ?
      (<InvitationSignupForm
        onChange={this.onChange}
        formData={newUser}
        errors={errors}
        onSubmit={this.onSubmit}
        isSubmitting={isSubmitting}
        data={data}/>) :
      (<InvitationLogin
        inviteDetails={inviteDetails}
        errors={errors}
        onChange={this.onChange}
        userDetails={newUser}/>);

    if (loadingUserDetails) {
      return (
        <div className="interior info">
          <Helmet title="Join CareerPrepped"/>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="content">
                  <div className="loading-container">
                    <Loading/>
                    <span>You're in! Just a sec - we're getting you all set up!</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="interior info">
        <Helmet title="Join CareerPrepped"/>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="content">
                {isRequesting && Object.keys(inviteDetails).length === 0 ?
                  (<div className="loading-container">
                    <Loading/>
                    <span>Fetching sign up details...</span>
                  </div>) :
                  inviteDetails.status === 'accepted' ?
                    (<div>
                      <h5 className="modal-title" id="myModalLabel">Join &nbsp;&nbsp;
                        <img src={cpLogo} alt="Career Prepped" style={{height: 20}}/>
                      </h5>
                      <div className="invitation-body">
                        <div className="accept-invitation-message">
                          <p>You have already accepted this invitation.</p>
                        </div>
                      </div>
                    </div>) :
                    (<div className="invitation-body" style={{marginTop: 0}}>
                      {component}
                    </div>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { '*': linkParams } = ownProps.params;
  const linkParamsArray = linkParams.split('/');

  let newUser = {
    email: linkParamsArray[1],
    firstname: '',
    lastname: '',
    token: linkParamsArray[0],
    password: '',
    rePassword: ''
  };

  return {
    newUser,
    inviteDetails: state.organizationInvitations.inviteDetails,
    isAuthenticated: state.auth.isAuthenticated,
    auth: state.auth,
    organizationInvitations: state.organizationInvitations
  };
};
const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({},
    orgInvitationActions,
    routerActions,
    componentActions,
    authenticationActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};


export default withHooks(connect(mapStateToProps, mapDispatchToProps)(Invitation));
