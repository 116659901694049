import PropTypes from 'prop-types';
import React from 'react';

const NewPasswordForm = ({ openLoginModal, pageTitle, onChange, formData, errors, onSubmit, isSubmitting, data, onCloseResetPassword }) => {
  const success = !isSubmitting && data.status === 200;

  return(
    <div>
      <div className="modal-body homepage-modal-body">
        <button onClick={onCloseResetPassword} className="close">
          <span aria-hidden="true">&times;</span>
        </button>
        {
          !success && <h4 className="modal-title" id="myModalLabel">{pageTitle}</h4>
        }
        {
          success &&
            <div>
              <p className="success-message">You successfully setup your password.</p>
              <p>Please sign in to explore.</p>
              <button
                onClick={openLoginModal}
                className="btn btn-primary w-100">
                Sign in
              </button>
            </div>
        }
        {
          !success &&
            <form onSubmit={onSubmit}>
              <div className="form-group">
                <label className="sr-only">Password</label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={onChange}
                  className="form-control"
                  placeholder="Password"/>
                {
                  errors.password
                  &&
                    <div className="text-danger">{errors.password}</div>
                }
              </div>
              <div className="form-group">
                <label className="sr-only">Confirm Password</label>
                <input
                  type="password"
                  name="rePassword"
                  value={formData.rePassword}
                  onChange={onChange}
                  className="form-control"
                  placeholder="Confirm Password"/>
                {
                  errors.rePassword
                  &&
                    <div className="text-danger">{errors.rePassword}</div>
                }
              </div>
              <input
                type="submit"
                value={isSubmitting ? 'Saving ...': 'Save'}
                className="btn btn-primary w-100"/>
            </form>
        }
      </div>
    </div>
  );
};
NewPasswordForm.propTypes = {
  pageTitle: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onCloseResetPassword: PropTypes.func.isRequired,
  openLoginModal: PropTypes.func.isRequired,
};
export default NewPasswordForm;
