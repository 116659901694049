import React from 'react';

import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';

import Label from '../../common/Label';
import TextArea from '../../common/TextArea';
import TextInput from '../../common/TextInput';
import LoadedFile from '../../portfolio/LoadedFile';
import UploadFile from '../../portfolio/UploadFile';

import { parseUrlString } from '../../../utils';
import { MAX_UPLOAD_ERROR_MSG } from '../../../utilConstants';

const UploadCredentialTabContent = ({
  errors,
  selectedFile,
  onDropFile,
  toggleGuidelinesTooltip,
  guidelinesTooltipOpen,
  showInsertTextBox,
  onDeleteLoadedFile,
  onChange,
  maxfileUploadError,
}) => {
  const mimetype = selectedFile.file.type ?
    selectedFile.file.type : null;
  const portfolioFileLoaded = mimetype !== null;
  const parseUrl = parseUrlString(selectedFile.externalurl);

  const imagePreviewText = selectedFile.file.name ? selectedFile.file.name : '';
  const imagePreviewUrl = selectedFile.file !== '' ?
    URL.createObjectURL(selectedFile.file) : selectedFile.externalurl;

  const fileUploadError = (errors.file && errors.file.length > 0) && (errors.externalurl && errors.externalurl.length > 0);

  return (
    <div className="tab-content upload-tab" id="myTabContent">
      <div
        className="tab-pane show active"
        id="upload"
        role="tabpanel"
        aria-labelledby="upload">
        <div className="content portfolio-files mb-0">
          <div className="add-portfolio-files mb-0">
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group">
                  <label>Add an evidence file:</label>&nbsp;
                  <small>
                    <a href="#" id="adding-portfolio-guidelines">See guidelines</a>
                  </small>

                  <Tooltip
                    placement="bottom"
                    isOpen={guidelinesTooltipOpen}
                    target="adding-portfolio-guidelines"
                    toggle={toggleGuidelinesTooltip}>
                    <div className="inside-tooltip" style={{fontSize: 12}}>
                      <div><b>Portfolio File Guidelines</b></div>
                      <div style={{textAlign: 'left', marginTop: 10}}>
                        Attach items such as awards, training certificates, and/or work samples.
                      </div>

                      <div style={{textAlign: 'left', marginTop: 10}}><b>All items must...</b></div>
                      <ul style={{padding: '0px 0px 20px 20px', textAlign: 'left', marginBottom: 0}}>
                        <li>not violate copyright or any other laws</li>
                        <li>not be confidential or sensitive in nature</li>
                        <li>respect privacy of others. (You can remove/blackout names from evidence you upload and indicate "For confidentiality, names have been removed.")</li>
                      </ul>
                    </div>
                  </Tooltip>
                  {(!showInsertTextBox && !portfolioFileLoaded) &&
                    <div className="portfolio-file-selector-container">
                      <UploadFile
                        onDropFile={onDropFile}
                        imagePreviewUrl={imagePreviewUrl}/>
                    </div>}

                  {portfolioFileLoaded &&
                    <LoadedFile
                      imagePreviewUrl={imagePreviewUrl}
                      imagePreviewText={imagePreviewText}
                      parseUrl={parseUrl}
                      mimetype={mimetype}
                      onDeleteLoadedFile={onDeleteLoadedFile}/>}

                  {fileUploadError &&
                    <div className="file-error">
                      Please select either a file to upload or a video link.
                    </div>}

                  {maxfileUploadError &&
                    <div className="file-error">
                      { MAX_UPLOAD_ERROR_MSG }
                    </div>}
                </div>
                <Label str="Title"/>

                <TextInput
                  name="title"
                  placeholder="Enter a title for this portfolio file"
                  value={selectedFile.title}
                  onChange={onChange}
                  error={errors.title}/>

                <Label str="Description"/>

                <TextArea
                  name="description"
                  value={selectedFile.description}
                  onChange={onChange}
                  rows={'5'}
                  placeholder={'Describe this portfolio file'}
                  error={errors.description}/>
              </div>
              <div className="col-lg-12">
                <div className="dropdown dropup visibility float-right">
                  <button
                    disabled
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    <i className="fa fa-globe"/> Public
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton">
                    <a className="dropdown-item" href="#">
                      <i className="fa fa-globe"/>Public
                      <span>viewable to anyone</span>
                    </a>
                    <a className="dropdown-item" href="#">
                      <i className="fa fa-lock"/>Private
                      <span>viewable only to me</span>
                    </a>
                    <a className="dropdown-item" href="#">
                      <i className="fa fa-user-circle-o"/>Followers Only
                    </a>
                    <a className="dropdown-item" href="#">
                      <i className="fa fa-user-circle"/>Following Only
                    </a>
                    <a className="dropdown-item" href="#">
                      <i className="fa fa-users"/>Followers and Following
                    </a>
                  </div>
                </div>
                <small className="foot-note float-right mr-1">
                  Portfolio items linked as evidence of your skill claims must be public.
                </small>
                <br/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

UploadCredentialTabContent.propTypes = {
  errors: PropTypes.object.isRequired,
  onDropFile: PropTypes.func.isRequired,
  selectedFile: PropTypes.object.isRequired,
  toggleGuidelinesTooltip: PropTypes.func,
  guidelinesTooltipOpen: PropTypes.bool,
  showInsertTextBox: PropTypes.bool.isRequired,
  onDeleteLoadedFile: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  maxfileUploadError: PropTypes.bool,
};

export default UploadCredentialTabContent;
