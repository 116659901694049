import PropTypes from 'prop-types';
import React from 'react';
import InfiniteScroller from '../../../containers/common/infinite-scroll/InfiniteScroller';
import classNames from 'classnames';
import Loading from '../../../components/common/Loading';
import PostList from '../../../components/skill-builders/discussions/PostList';

const DiscussionContent = (props) => {
  const loadingStyles = classNames({
    'col-lg-9': props.isAuthenticated,
    'offset-lg-3': props.isAuthenticated,
    'col-lg-12': !props.isAuthenticated
  });

  if (props.isRequestingSort) {
    return (
      <div className="dashboard-activity" style={{ marginTop: 10 }}>
        <div className="row">
          <div className={loadingStyles}>
            <Loading />
          </div>
        </div>
      </div>
    );
  }


  return (
    <InfiniteScroller
      component={PostList}
      callAPI={props.callAPI}
      page_count={props.page_count}
      activeSort={props.activeSort}
      page={props.page}
      onComponentUnmount={props.initializeStore}
      isLoaderInternal
      shouldCallAPIInitially={props.shouldCallAPIInitially}
      unit={props.unit}
      discussions={props.discussions.data}
      isSortByOpen={props.isSortByOpen}
      toggleSortBy={props.toggleSortBy}
      discussionShare={props.discussionShare}
      handleBeforeSorting={props.handleBeforeSorting}
      isAuthenticated={props.isAuthenticated}/>
  );
};

DiscussionContent.propTypes = {
  unit: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool,
  isAuthenticated: PropTypes.bool.isRequired,
  discussions: PropTypes.object.isRequired,
  discussionShare: PropTypes.object.isRequired,
  isRequestingSort: PropTypes.bool,
  handleBeforeSorting: PropTypes.func,
  callAPI: PropTypes.func,
  page_count: PropTypes.number,
  page: PropTypes.number,
  initializeStore: PropTypes.func,
  isSortByOpen: PropTypes.bool,
  toggleSortBy: PropTypes.func,
  shouldCallAPIInitially: PropTypes.bool,
  activeSort: PropTypes.string
};

export default DiscussionContent;
