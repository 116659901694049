import PropTypes from 'prop-types';
import React from 'react';
import TextInput from '../common/TextInput';
import Label from '../common/Label';
import TextArea from '../common/TextArea';
import { Tooltip } from 'reactstrap';
import LoadedFile from '../portfolio/LoadedFile';
import UploadFile from '../portfolio/UploadFile';
import { parseUrlString } from '../../utils';
import { MAX_UPLOAD_ERROR_MSG } from '../../utilConstants';

const UploadPortfolioTab = ({
  onDropFile,
  fileDetails,
  toggleGuidelinesTooltip,
  guideLinesTooltipOpen,
  showInsertTextBox,
  onInsertLinkClicked,
  onDeleteLoadedFile,
  onChange,
  maxfileUploadError,
}) => {
  const { title, description, mimetype, imagePreviewUrl, url, errors, imagePreviewText} = fileDetails;
  const portfolioFileLoaded = mimetype !== null;
  const parseUrl = parseUrlString(url);
  const fileUrl = imagePreviewUrl !== '' ? imagePreviewUrl : url;
  const fileUploadError = (errors.file && errors.file.length > 0) && (errors.url && errors.url.length > 0);

  return (
    <div className="tab-content upload-tab" id="myTabContent">
      <div className="tab-pane show active" id="upload" role="tabpanel" aria-labelledby="upload">
        <div className="content portfolio-files mb-0">
          <div className="add-portfolio-files mb-0">
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group">
                  <label>Add a portfolio file:</label>&nbsp;
                  <small>
                    <a href="#" id="adding-portfolio-guidelines">See guidelines</a>
                  </small>

                  <Tooltip
                    placement="bottom"
                    isOpen={guideLinesTooltipOpen}
                    target="adding-portfolio-guidelines"
                    toggle={toggleGuidelinesTooltip}>
                    <div className="inside-tooltip" style={{fontSize: 12}}>
                      <div><b>Portfolio File Guidelines</b></div>
                      <div style={{textAlign: 'left', marginTop: 10}}>
                        Attach items such as awards, training certificates, and/or work samples.
                      </div>

                      <div style={{textAlign: 'left', marginTop: 10}}><b>All items must...</b></div>
                      <ul style={{padding: '0px 0px 20px 20px', textAlign: 'left', marginBottom: 0}}>
                        <li>not violate copyright or any other laws</li>
                        <li>not be confidential or sensitive in nature</li>
                        <li>respect privacy of others. (You can remove/blackout names from evidence you upload and indicate "For confidentiality, names have been removed.")</li>
                      </ul>
                    </div>
                  </Tooltip>
                  {(!showInsertTextBox && !portfolioFileLoaded) &&
                    <div className="portfolio-file-selector-container">
                      <UploadFile
                        url={url}
                        onDropFile={onDropFile}
                        imagePreviewUrl={imagePreviewUrl}/>
                      <hr className="hr-line"/>
                      <div
                        onClick={onInsertLinkClicked}
                        className="insert-link">
                        <span className="insert-text">
                          <i className="fa fa-paperclip upload-icon"/>
                          Paste a Website Link
                        </span>
                        <span className="supported-types">
                          YouTube or any Website
                        </span>
                      </div>
                    </div>}
                  {
                    (showInsertTextBox && !portfolioFileLoaded) &&
                      <div className="insert-url-textinput-conatiner">
                        <span
                          onClick={onInsertLinkClicked}
                          className="close-icon">
                          <i className="fa fa-times"/>
                        </span>
                        <span style={{ width: '90%'}}>
                          <TextInput
                            name="url"
                            placeholder="Paste a link to showcase your work from another website"
                            value={url}
                            error={errors.url}
                            style={{ marginBottom : 0}}
                            onChange={onChange}/>
                          <div className="pf-help-text">
                            Example: https://youtu.be/z_7UlNyZaKY
                          </div>
                        </span>
                      </div>
                  }

                  {
                    portfolioFileLoaded &&
                      <LoadedFile
                        imagePreviewUrl={fileUrl}
                        imagePreviewText={imagePreviewText}
                        parseUrl={parseUrl}
                        mimetype={mimetype}
                        onDeleteLoadedFile={onDeleteLoadedFile}/>
                  }

                  {
                    fileUploadError &&
                      <div className="file-error">
                        Please select either a file to upload or a video link.
                      </div>
                  }

                  {
                    maxfileUploadError &&
                      <div className="file-error">
                        { MAX_UPLOAD_ERROR_MSG }
                      </div>
                  }
                </div>
                <Label str="Title"/>

                <TextInput
                  name="title"
                  placeholder="Enter a title for this portfolio file"
                  value={title}
                  onChange={onChange}
                  error={errors.title}/>

                <Label str="Description"/>

                <TextArea
                  name="description"
                  value={description}
                  onChange={onChange}
                  rows={'5'}
                  placeholder={'Describe this portfolio file'}
                  error={errors.description}/>
              </div>
              <div className="col-lg-12">
                <div className="dropdown dropup visibility float-right">
                  <button className="btn btn-secondary dropdown-toggle" disabled type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fa fa-globe"/> Public
                  </button>
                  <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                    <a className="dropdown-item" href="#">
                      <i className="fa fa-globe"/>Public
                      <span>viewable to anyone</span>
                    </a>
                    <a className="dropdown-item" href="#">
                      <i className="fa fa-lock"/>Private
                      <span>viewable only to me</span>
                    </a>
                    <a className="dropdown-item" href="#">
                      <i className="fa fa-user-circle-o"/>Followers Only
                    </a>
                    <a className="dropdown-item" href="#">
                      <i className="fa fa-user-circle"/>Following Only
                    </a>
                    <a className="dropdown-item" href="#">
                      <i className="fa fa-users"/>Followers and Following
                    </a>
                  </div>
                </div>
                <small className="foot-note float-right mr-1">Portfolio items linked as evidence of your skill claims must be public.</small><br/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

UploadPortfolioTab.propTypes = {
  onDropFile: PropTypes.func.isRequired,
  fileDetails: PropTypes.object,
  toggleGuidelinesTooltip: PropTypes.func.isRequired,
  guideLinesTooltipOpen: PropTypes.bool.isRequired,
  showInsertTextBox: PropTypes.bool.isRequired,
  onInsertLinkClicked: PropTypes.func.isRequired,
  onDeleteLoadedFile: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  maxfileUploadError: PropTypes.bool,
};

export default UploadPortfolioTab;
