import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const CookiesConsent = ({ onAcceptCookiesConsent }) => {
  return (
    <div className="modal-body homepage-modal-body">
      <h4 className="modal-title" id="myModalLabel">We use cookies</h4>

      <p>We use cookies to personalize your browsing experience. By using this site, you consent to our use of cookies as explained in our <Link to="/privacy" target="_blank">Privacy Policy</Link>.</p>

      <a
        onClick={onAcceptCookiesConsent}
        className="btn btn-primary d-flex justify-content-center">
        Accept Cookies and Close this Message
      </a>
    </div>
  );
};

CookiesConsent.propTypes = {
  onAcceptCookiesConsent: PropTypes.func.isRequired
};

export default CookiesConsent;
