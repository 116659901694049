import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ItemTypes from '../../../constants/ItemTypes';
import { DragSource, DropTarget } from 'react-dnd';
import flow from 'lodash/flow';
import classNames from 'classnames';

const cardSource = {
  beginDrag(props) {
    return {
      id: props.section.id,
      index: props.index
    };
  }
};

const cardTarget = {
  hover(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) return;

    // Perform the action
    props.moveCard(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item!
    // Generally it's better to avoid mutations,
    // but it's good here for performance sake
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};

class ProfileSection extends Component {
  static propTypes = {
    section: PropTypes.object.isRequired,
    isOver: PropTypes.bool.isRequired,
    isDragging: PropTypes.bool.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    showVisibilitySelect: PropTypes.bool,
    onChangeVisibility: PropTypes.func,
    editingSection: PropTypes.array,
    onToggleSectionEdit: PropTypes.func,
    profile: PropTypes.object
  }

  render() {

    const {
      isOver, isDragging, section,
      connectDragSource, connectDropTarget
    } = this.props;
    let title = '';
    let icon = '';

    switch(section.name) {
    case 'Award':
    case 'awards':
      title='Honors & Awards';
      icon='awards';
      break;

    case 'Association':
    case 'associations':
      title='Associations';
      icon='associations';
      break;

    case 'Club':
    case 'clubs':
      title='Extracurricular Activites & Clubs';
      icon='activities';
      break;

    case 'Certification':
    case 'certifications':
      title='Certifications and Licenses';
      icon='certifications';
      break;

    case 'Education':
    case 'education':
      title='Education';
      icon='education';
      break;

    case 'Experience':
    case 'experiences':
      title='Experience';
      icon='experience';
      break;

    case 'Interests':
      title='Interests';
      icon='interests';
      break;

    case 'Project':
    case 'projects':
      title='Projects';
      icon='projects';
      break;

    case 'Publication':
    case 'publications':
      title='Publications';
      icon='publications';
      break;

    case 'Skills':
    case 'skills':
      title='Skills';
      icon='skill';
      break;
    }

    let component = (
      <li
        className={classNames('sort-section-item',{
          'is-dragging': isDragging,
          'is-over': isOver
        })}>
        <div className={`profile-item ${icon}`}>
          <div className="head">
            {title}
          </div>
        </div>
      </li>
    );

    return connectDragSource(connectDropTarget(component));
  }
}

export default flow(DragSource(
  ItemTypes.SECTIONS,
  cardSource,
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  })),
DropTarget(ItemTypes.SECTIONS, cardTarget, (connect, monitor) => ({
  isOver: monitor.isOver(),
  connectDropTarget: connect.dropTarget()
})))(ProfileSection);
