import React from 'react';

import {
  Popover,
  PopoverBody
} from 'reactstrap';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

const ClusterColumn = (props) => {
  const rowDetails = props.rowData;
  let clusterData = JSON.parse(rowDetails.clusterNames);
  const remainingData = cloneDeep(clusterData);

  if(clusterData.length > 1) {
    remainingData.shift();
  }

  return (
    <td scope="row">
      {clusterData.length > 1 ?
        <>
          <span>{clusterData[0].clusterName || 'NA'}</span>
          <span id={`popover-${rowDetails.id}`}
            className="orange pointer ml-1"
            onClick={props.toggleIRViewCluster(rowDetails.id)}>
            {`+${remainingData.length}`}
          </span>
          <Popover placement="bottom" className="industryCluster-popover"
            isOpen={rowDetails.id === props.clusterPopOver}
            target={`popover-${rowDetails.id}`}>
            <PopoverBody>
              {remainingData.map((cluster, index) =>
                <span className="d-block" key={index}>{cluster.clusterName || 'NA'}</span>)}
            </PopoverBody>
          </Popover>
        </> :
        <span>{clusterData[0]?.clusterName || 'NA'}</span>}
    </td>
  );
};

ClusterColumn.propTypes = {
  rowData: PropTypes.object.isRequired,
  clusterPopOver: PropTypes.string,
  toggleIRViewCluster: PropTypes.func,
};

export default ClusterColumn;
