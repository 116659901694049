import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withHooks } from '../../utils/withHooks';

import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import TextInput from '../../components/common/TextInput';
import Validator from '../../validator';
import { Modal } from 'reactstrap';
import ForgotPassword from '../auth/ForgotPassword';

import * as routerActions from '../../redux/actions/router';
import * as authActions from '../../redux/actions/authentication';
import * as componentsActions from '../../redux/actions/components';

class UnAuthorisedLogin extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    credentials: PropTypes.object,
    router: PropTypes.object,
    navigate: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      forgotPassword: false,
      newPassword: false,
      credentials: Object.assign({}, this.props.credentials)
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.authenticateUser = this.authenticateUser.bind(this);
    this.toggleForgotPassword = this.toggleForgotPassword.bind(this);
    this.updateCredentialState = this.updateCredentialState.bind(this);
    this.onCloseResetPassword = this.onCloseResetPassword.bind(this);
  }

  componentDidMount(){
    if (this.email) {
      this.email.focus();
    }

    const { actions, navigate, isAuthenticated } = this.props;

    if (isAuthenticated) {
      navigate('/home');
      actions.redirect('/home');
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      actions,
      navigate,
      isAuthenticated,
      router: { nextPathname },
      auth: { data, lastLoggedInUserId }
    } = nextProps;

    if (isAuthenticated !== this.props.isAuthenticated && isAuthenticated) {

      if (nextPathname && (lastLoggedInUserId === data.currentUser.id)) {
        actions.redirect(nextPathname);
        navigate(nextPathname);

        return;
      }

      actions.redirect('/home');
      navigate('/home');
    }
  }

  toggleForgotPassword(){
    const { forgotPassword } = this.state;

    this.setState({ forgotPassword: !forgotPassword });
  }

  isValid(field = null) {
    const { credentials, errors: prevErrors } = this.state;

    let validate = Validator.createValidator({
      email: ['required', 'email'],
      password: ['required', 'minLength|8']
    }, credentials, field);

    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  renderErrors() {
    let errorMessage = [];
    let { error } = this.props.auth;

    if (Object.keys(error).length > 0) {
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        errorMessage.push(error.message);
      } else {
        Object.keys(error).map(key => errorMessage.push(error[key][0]));
      }

      if (errorMessage.length == 0) return false;
      toastr.error(errorMessage.join('<br/>'));
    }
  }

  updateCredentialState(event) {
    const field = event.currentTarget['name'];
    let credentials = this.state.credentials;

    credentials[field] = event.currentTarget['value'];

    this.setState({ credentials }, () => this.isValid(field));
  }

  authenticateUser(event) {
    event.preventDefault();

    if (! this.isValid()) return false;

    this.props.actions.authenticateUser(this.state.credentials);
  }

  handleKeyPress(event) {
    if (event.key === 'Enter')
      return this.authenticateUser(event);

    if (event.key === 'Escape')
      return this.toggleLoginModal();
  }

  onCloseResetPassword(){
    const { actions: { closeModal, redirect }, navigate } = this.props;

    this.setState({newPassword: false}, () => {
      redirect('/');
      closeModal();
      navigate('/');
    });
  }


  render() {
    const { auth } = this.props;
    const { credentials, errors, forgotPassword } = this.state;

    return (
      <div
        style={{marginTop: 100}}
        className="section">
        <div className="container">
          <div className="flex-center text-center mt-3">
            <h2 className="mt-2 mb-2">Hmm ... We're having trouble finding your credentials. Let's have you sign in again.</h2>

            <form
              style={{margin: '10px auto', width: 220}}
              onSubmit={this.authenticateUser}
              onKeyPress={this.handleKeyPress}>
              <div className="form-group">
                <label className="sr-only">Email</label>
                <TextInput
                  autoFocus
                  type="text"
                  name="email"
                  placeholder="Email"
                  style={{width: '100%'}}
                  value={credentials.email}
                  onChange={this.updateCredentialState}/>
                {errors.email && <div className="text-danger">{errors.email}</div>}
              </div>
              <div className="form-group">
                <label className="sr-only">Password</label>
                <TextInput
                  type="password"
                  name="password"
                  placeholder="Password"
                  style={{width: '100%'}}
                  value={credentials.password}
                  onChange={this.updateCredentialState}/>
                {errors.password && <div className="text-danger">{errors.password}</div>}
              </div>

              <input
                style={{width: '100%'}}
                type="submit"
                disabled={auth.isRequesting}
                value={auth.isRequesting ? 'Authenticating...' : 'Sign In'}
                className="btn btn-lg btn-primary"/>
            </form>
            <a onClick={this.toggleForgotPassword} className="forgot-password link-orange">Forgot Password?</a>
            <div style={{marginTop: 50}}>
              <Link className="orange" to="/">No thanks, take me back home!</Link>
            </div>
          </div>
        </div>
        {
          forgotPassword &&
            <Modal
              isOpen={forgotPassword}
              toggle={this.toggleForgotPassword}
              className="forgotModal">
              <ForgotPassword
                onCloseResetPassword={this.onCloseResetPassword}
                toggleForgotPassword={this.toggleForgotPassword}/>
            </Modal>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let credentials = { email: '', password: '' };

  return {
    credentials,
    auth: state.auth,
    router: state.router,
    isAuthenticated: state.auth.isAuthenticated
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, routerActions, authActions, componentsActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(UnAuthorisedLogin));
