import React, { useState, useCallback } from 'react';

import PropTypes from 'prop-types';
import { Button, Modal, ModalBody } from 'reactstrap';
import Dropzone from 'react-dropzone';

import _isObject from 'lodash/isObject';
import CloseIcon from '../../assets/images/close.svg';

const PortfolioFileUploadModal = ({ isOpen, toggleModal, proceedWithFile, selectedId }) => {
  const [selectedFile, setSelectedFile] = useState();

  const onFileSelected = useCallback((files) => {
    if (files.length > 0 && _isObject(files[0])) {
      const selectedFile = Object.assign({}, selectedFile, {
        file: files[0],
      });

      setSelectedFile(selectedFile);
    }
  }, []);

  function handleTriggerEvent() {
    toggleModal('fileUploaded');
  }

  function handleProceed(event) {
    proceedWithFile(event);
    toggleModal('fileUploaded');
  }

  return (
    <Modal
      centered
      className="customLevelUpModal"
      isOpen={isOpen}
      toggle={handleTriggerEvent}>
      <div className="modal-header">
        <span className="modal-title">Add Portfolio Evidence</span>
        <div
          onClick={handleTriggerEvent}
          className="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">
            <img src={CloseIcon} />
          </span>
        </div>
      </div>

      <ModalBody>
        <span className="pb-5 d-block">
          You must have at least 1 portfolio file that backs up this skill before
          you can seek feedback to level up.
        </span>
        <span>{selectedFile?.file?.name}</span>

        {isOpen && (
          <Dropzone onDrop={onFileSelected}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {/* <p className="dragfilebox">
                    {selectedFile?.file?.name
                      ? selectedFile?.file?.name
                      : "Drop an image or click to select file to upload."}
                  </p> */}
                </div>
              </section>
            )}
          </Dropzone>
        )}
        <div className="text-right">
          <Button
            onClick={handleTriggerEvent}
            className="mr-1 btn btn-secondary">
            Cancel
          </Button>
          <Button
            data-value={selectedId}
            onClick={handleProceed}
            className="mx-1 sendButton"
            color="warning">
            Add Portfolio Evidence
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

PortfolioFileUploadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  proceedWithFile: PropTypes.func.isRequired,
  selectedId: PropTypes.string,
};

export default PortfolioFileUploadModal;
