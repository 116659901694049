import PropTypes from 'prop-types';
import React from 'react';
import { uniqueId } from '../../../utils';
import ExperienceCardHandler from '../../../containers/profile/sections/ExperienceCardHandler';

const ExperienceList = ({experiences, isPublicProfile, onEdit}) => {
  const sortExperiences = (experiences) => {
    const newExperienceList = [].concat(experiences);
    return newExperienceList.sort(function(a, b){
      return new Date(b.enddate.date) - new Date(a.enddate.date);
    });
  };

  const experiencesSortedList = sortExperiences(experiences);

  if(experiences.length === 0){
    return(
      <div className="empty-section-message">
        <p>Add experience such as work, volunteer, internship, or military experience.</p>
      </div>
    );
  }
  return (
    <div>
      {experiencesSortedList.map(experience =>
        (<ExperienceCardHandler
          key={uniqueId()}
          experience={experience}
          onEdit={onEdit}
          isPublicProfile={isPublicProfile}/>))}
    </div>
  );
};

ExperienceList.propTypes = {
  experiences: PropTypes.array.isRequired,
  isPublicProfile: PropTypes.bool.isRequired,
  onEdit: PropTypes.func
};

export default ExperienceList;
