import initialState from './initialState';

import {
  LOAD_ENDORSEMENT,
  UNLOAD_ENDORSEMENT,
  RESET_BADGE_ENDORSEMENT_REDUCER,
  ASK_ENDORSEMENT_REQUEST,
  ASK_ENDORSEMENT_SUCCESS,
  ASK_ENDORSEMENT_FAILURE,
  ENDORSEMENT_BY_ID_REQUEST,
  ENDORSEMENT_BY_ID_SUCCESS,
  ENDORSEMENT_BY_ID_FAILURE,
  CONFIRM_ENDORSEMENT_REQUEST,
  CONFIRM_ENDORSEMENT_SUCCESS,
  CONFIRM_ENDORSEMENT_FAILURE,
  ENDORSE_BADGE_REQUEST,
  ENDORSE_BADGE_SUCCESS,
  ENDORSE_BADGE_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.badgeEndorsement, action) {
  switch (action.type) {
  case LOAD_ENDORSEMENT:
    return Object.assign({}, state, {
      selectedBadges: [...action.selected],
    });

  case UNLOAD_ENDORSEMENT:
    return Object.assign({}, state, {
      selectedBadges: [],
      data: {},
    });

  case RESET_BADGE_ENDORSEMENT_REDUCER:
    return Object.assign({}, initialState.badgeEndorsement);

  case ASK_ENDORSEMENT_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: true,
    });

  case ASK_ENDORSEMENT_SUCCESS:
    return Object.assign({}, state, {
      data: action.data,
      isSubmitting: false,
    });

  case ASK_ENDORSEMENT_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case ENDORSEMENT_BY_ID_REQUEST:
    return Object.assign({}, state, {
      isRequesting: true,
    });

  case ENDORSEMENT_BY_ID_SUCCESS:
    return Object.assign({}, state, {
      requestedEndorsements: action.data['endorsement-requests'],
      isRequesting: false,
    });

  case ENDORSEMENT_BY_ID_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case CONFIRM_ENDORSEMENT_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: true,
    });

  case CONFIRM_ENDORSEMENT_SUCCESS:
    return Object.assign({}, state, {
      data: action.data,
      isSubmitting: false,
    });

  case CONFIRM_ENDORSEMENT_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case ENDORSE_BADGE_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: true,
    });

  case ENDORSE_BADGE_SUCCESS:
    return Object.assign({}, state, {
      data: action.data,
      isSubmitting: false,
    });

  case ENDORSE_BADGE_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  default:
    return state;
  }
}
