import { CALL_API } from '../../middlewares/api';

import {
  JOB_MATCHES_REQUEST,
  JOB_MATCHES_SUCCESS,
  JOB_MATCHES_FAILURE,

  JOB_MATCHES_CITIES_REQUEST,
  JOB_MATCHES_CITIES_SUCCESS,
  JOB_MATCHES_CITIES_FAILURE,

  ADD_JOB_TO_TRACKER_REQUEST,
  ADD_JOB_TO_TRACKER_SUCCESS,
  ADD_JOB_TO_TRACKER_FAILURE,

  UPDATE_JOB_MATCHES_QUERY_PARAMS,
  RESET_JOB_MATCHES,
  RESET_JOB_MATCHES_DATA,
  TOGGLE_SELECT_JOB_MATCH,
  TOGGLE_JOB_MATCHES_FILTER,
  TOGGLE_SELECT_ALL_JOB_MATCHES,
  TOGGLE_SORT_JOB_MATCHES_FILTER,
  TOGGLE_JOB_MATCHES_LOCATION_FILTER
} from '../../constants';

export const resetJobMatches = () => ({ type: RESET_JOB_MATCHES });

export const resetJobMatchesData = () => ({ type: RESET_JOB_MATCHES_DATA });

export const toggleSelectJobMatch = selectedId =>
  ({ type: TOGGLE_SELECT_JOB_MATCH, selectedId });

export const toggleSelectAllJobMatches = () =>
  ({ type: TOGGLE_SELECT_ALL_JOB_MATCHES });

export const toggleSortFilter = sort =>
  ({ type: TOGGLE_SORT_JOB_MATCHES_FILTER, sort });

export const updateJobMatchesQueryParams = queryParams =>
  ({ type: UPDATE_JOB_MATCHES_QUERY_PARAMS, queryParams });

export const toggleJobMatchesFilter = (filterName, filterValue) =>
  ({ type: TOGGLE_JOB_MATCHES_FILTER, filterName, filterValue });

export const toggleJobMatchesLocationFilter = filterValues =>
  ({ type: TOGGLE_JOB_MATCHES_LOCATION_FILTER, filterValues });

export const requestJobMatches = (page = 1, params, paginationData = null) => {
  let endpoint = `job/job-matches?page=${page}&query=${params.query}&city=${params.city.label}&remoteJobs=${params.remoteJobs}&radius=${params.radius.value}&sort=${params.sort.value}`;

  if (page > 1) {
    const searchAfter = encodeURIComponent(JSON.stringify(paginationData.searchAfter));

    endpoint = `${endpoint}&searchAfter=${searchAfter}`;
  }

  return {
    [CALL_API]: {
      id: page,
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        JOB_MATCHES_REQUEST,
        JOB_MATCHES_SUCCESS,
        JOB_MATCHES_FAILURE
      ]
    }
  };
};

export const requestJobMatchesCities = () => {
  const endpoint = 'job/cities';

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        JOB_MATCHES_CITIES_REQUEST,
        JOB_MATCHES_CITIES_SUCCESS,
        JOB_MATCHES_CITIES_FAILURE
      ]
    }
  };
};

export const addJobsToTracker = (data) => {
  const endpoint = 'job/job-matches';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        ADD_JOB_TO_TRACKER_REQUEST,
        ADD_JOB_TO_TRACKER_SUCCESS,
        ADD_JOB_TO_TRACKER_FAILURE
      ]
    }
  };
};
