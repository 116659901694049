import { createSelector } from 'reselect';
import { uniqueId } from '../../utils';

const selectClusters = state => state.clusters;

export const selectClustersList = createSelector(
  [selectClusters],
  clusters => clusters.data
);

export const selectFormattedClusters = createSelector(
  [selectClustersList],
  clusters =>
    clusters.map(
      cluster => ({
        id: uniqueId(),
        label: cluster.name,
        value: cluster.id
      })
    )
);

export const selectClustersPageCount = createSelector(
  [selectClusters],
  clusters => clusters.pageCount
);
