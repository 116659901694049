import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Chance from 'chance';

import Linkify from 'react-linkify';
import { toastr } from 'react-redux-toastr';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { SimpleToolTipWrapper } from '../../components/common/ToolTipWrapper';

class CopyGroupCodeButton extends Component {
  static propTypes = {
    selectedGroup: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.onCopyCode = this.onCopyCode.bind(this);
  }

  onCopyCode() {
    const { selectedGroup: { name: groupName } } = this.props;

    toastr.success('', `You successfully copied the group code for ${groupName}.`);
  }

  render() {
    const chance = new Chance();
    const randomId = `simple-tooltip-${chance.hash({ length: 5})}`;

    const { location: { hostname } } = window;
    const host = (hostname === 'localhost') ? 'http://localhost:3030' : hostname;

    const selfRegistrationUrl = `${host}/group`;

    const { selectedGroup: { inviteCode } } = this.props;

    return (
      <span
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}>
        <CopyToClipboard text={inviteCode} onCopy={this.onCopyCode}>
          <button className="btn btn-sm btn-primary">
            Copy Group Code
          </button>
        </CopyToClipboard>
        <SimpleToolTipWrapper id={randomId} toolTipContent={
          <div
            className="inside-tooltip">
            <Linkify>Send users to {selfRegistrationUrl} with code to sign up</Linkify>
          </div>
        }>
          <i
            className="fa fa-question-circle text-orange"
            style={{
              cursor: 'pointer',
              marginLeft: 8,
              fontSize: 20
            }}/>
        </SimpleToolTipWrapper>
      </span>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    profile: { data: { role } },
    groups: { selectedGroup: institutionSelectedGroup },
    educators: { selectedGroup: educatorSelectedGroup }
  } = state;

  const selectedGroup = (role === 'educator') ? educatorSelectedGroup : institutionSelectedGroup;

  return {
    selectedGroup
  };
};

export default connect(mapStateToProps)(CopyGroupCodeButton);
