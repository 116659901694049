import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';

import ConfirmModal from '../../components/portfolio/ConfirmModal';
import * as sectionActions from '../../redux/actions/sections';
import * as reflectionsActions from '../../redux/actions/reflections';
import * as componentsActions from '../../redux/actions/components';
import ReflectionQuestionForm from '../../components/skill-builders/essentials/ReflectionQuestionForm';
import ReflectionQuestionSection from '../../components/skill-builders/essentials/ReflectionQuestionSection';

class ReflectionQuestionContainer extends Component {
  static propTypes = {
    unit: PropTypes.object.isRequired,
    value: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    profile: PropTypes.object,
    question: PropTypes.string,
    name: PropTypes.string,
    response: PropTypes.object,
    isUpdating: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    onShareIndividualReflection: PropTypes.func,
    actions: PropTypes.object.isRequired,
    isDeleting: PropTypes.object,
    modal: PropTypes.string,
    reflections: PropTypes.array,
    deleteId: PropTypes.string,
  }

  constructor(props) {
    super(props);

    this.state = {
      error: '',
      isEditing: false,
      answer: '',
    };

    this.onToggleEditing = this.onToggleEditing.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onClearText = this.onClearText.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.onDeleteCancel = this.onDeleteCancel.bind(this);
  }

  componentWillMount(){
    if(this.props.value && this.props.value !== '')
      this.setState({
        answer: this.props.value
      });
  }

  componentWillReceiveProps(nextProps){
    const { value, isUpdating, isSubmitting, isDeleting } = nextProps;

    if(value !== this.props.value){
      this.setState({
        answer: value
      });

      if((isSubmitting !== this.isSubmitting && !isSubmitting ) ||
      (isUpdating !== this.props.isUpdating && !isUpdating)){
        this.setState({
          isEditing: false
        });
      }
    }

    if( this.props.isDeleting.id === this.props.response.id &&
      isDeleting.status !== this.props.isDeleting.status &&
      !isDeleting.status && this.props.isDeleting.status){
      toastr.success('Reflection Answer Deleted');
      this.props.actions.closeModal();
    }
  }

  onToggleEditing(){
    this.setState({
      isEditing: !this.state.isEditing,
      answer: this.props.value
    });
  }

  onChange(event) {
    const { value } = event.target;

    this.setState({ answer: value });
  }

  onClearText(){
    this.setState({ answer: '', error: '' });
  }

  onSave(event) {
    event.preventDefault();
    const { answer } = this.state;
    const { unit, actions, isAuthenticated, response, name } = this.props;
    const { id } = unit;

    if (!isAuthenticated) {
      toastr.warning('Please Login/Signup');
      return ;
    }

    if (answer === '') {
      return toastr.warning('', 'Answer cannot be empty');
    }

    if(answer.length > 2000) {
      return toastr.warning('', 'Only 2000 characters are allowed');
    }

    const reflectionAnswer = {
      unit: id,
      question: name,
      answer
    };

    if (response.id) {
      return actions.updateReflection(reflectionAnswer, response.id);
    }

    actions.saveReflection(reflectionAnswer);
  }

  onDelete(event) {
    const buttonId = event.target.id;
    const currentReflection = this.props.reflections.filter(i => i.question === buttonId)[0];
    this.props.actions.openModal(`delete-reflection-question-modal-${currentReflection.id}`);
    this.props.actions.setDeleteReflectionId(currentReflection.id);
  }

  onDeleteConfirm() {
    this.props.actions.deleteReflection(this.props.deleteId);
  }

  onDeleteCancel() {
    this.props.actions.closeModal();
  }

  render() {
    const { isEditing, answer, error } = this.state;
    const {
      question,
      value,
      name,
      isSubmitting,
      isUpdating,
      onShareIndividualReflection,
      isDeleting,
      response,
      modal
    } = this.props;

    const isSavingReflection = (isSubmitting && !isEditing && value === '' && answer !== '') ||
      (isUpdating && isEditing);
    const isDeleteModalOpen = modal && modal === `delete-reflection-question-modal-${response.id}`;
    return (
      <div className="col-lg-12 question-item">
        {
          !isEditing && value !== '' ?
            <ReflectionQuestionSection
              nameOne={name}
              questionOne={question}
              valueOne={value}
              onShareIndividualReflection={onShareIndividualReflection}
              isDeleting={isDeleting}
              onDelete={this.onDelete}
              onReflectionEdit={this.onToggleEditing}/>:
            <ReflectionQuestionForm
              question={question}
              hasAnswer={value !== ''}
              name={name}
              value={answer}
              error={error}
              onChange={this.onChange}
              onSave={this.onSave}
              isSubmitting={isSavingReflection}
              onCancel={this.onToggleEditing}
              onClearText={this.onClearText}
              answer={response.answer}/>
        }
        {
          isDeleteModalOpen &&
            <ConfirmModal
              modalHeaderText="Delete Reflection"
              modalMessage="Are you sure you want to permanently delete your reflection answer?"
              modalFooterButtonText="Yes, Delete"
              onCancel={this.onDeleteCancel}
              onConfirm={this.onDeleteConfirm}
              isConfirming={isDeleting.status}
              confirminginProgressText="Deleting..."
              isOpen={isDeleteModalOpen}/>
        }
      </div>

    );
  }
}

const mapStateToProps = (state) => {
  const { profile, auth, sections, reflections, components } = state;

  return {
    isSubmitting: reflections.isSubmitting,
    isUpdating: reflections.isUpdating,
    isDeleting: reflections.isDeleting,
    isAuthenticated: auth.isAuthenticated,
    profile: profile.data,
    sections: sections.data,
    modal: components.modal,
    reflections: reflections.data,
    deleteId: reflections.deleteId,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    sectionActions,
    reflectionsActions,
    componentsActions,
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(ReflectionQuestionContainer);
