import React from 'react';

import PropTypes from 'prop-types';

import ResultsWrapper from '../../containers/jobs/ResultsWrapper';

const JobResult = ({
  search,
  addToBoard,
  applyToJob,
  searchResult,
  deleteJob,
  confirmDeleteJobModalIsOpen,
  onConfirmDeleteJob,
  onCancelDeleteJob,
  onChangeSort
}) => {
  if (searchResult.empty) {
    return (
      <div>
        <p
          style={{marginTop: '2rem'}}
          className="text-center">
          No results found.<br/>
          Try broader keywords, a different location, check spelling, or use full words for better matches.
        </p>
      </div>
    );
  }

  return (
    <div>
      <ResultsWrapper
        search={search}
        addToBoard={addToBoard}
        applyToJob={applyToJob}
        deleteJob={deleteJob}
        onChangeSort={onChangeSort}
        confirmDeleteJobModalIsOpen={confirmDeleteJobModalIsOpen}
        onConfirmDeleteJob={onConfirmDeleteJob}
        onCancelDeleteJob={onCancelDeleteJob}/>
    </div>
  );
};

JobResult.propTypes = {
  search: PropTypes.object.isRequired,
  addToBoard: PropTypes.func.isRequired,
  searchResult: PropTypes.object.isRequired,
  applyToJob: PropTypes.func.isRequired,
  deleteJob: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onConfirmDeleteJob: PropTypes.func.isRequired,
  onCancelDeleteJob: PropTypes.func.isRequired,
  confirmDeleteJobModalIsOpen: PropTypes.bool.isRequired
};

export default JobResult;
