import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as badgeActions from '../../redux/actions/badges';
import { bindActionCreators } from 'redux';
import SkillBadgesContainer from './SkillBadgesContainer';
import SkillbadgesSideMenu from '../../components/skill-badges/SkillbadgesSideMenu';
import PaginationHandler from '../../components/common/PaginationHandler';

class SkillBadges extends Component {
  static propTypes = {
    badges: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    currentUser: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    isVisiting: PropTypes.bool,
    isAuthenticated: PropTypes.bool.isRequired,
    isRequestingIssued: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);

    this.state={
      layout: 'grid'
    };

    this.changeLayout = this.changeLayout.bind(this);
    this.onNext = this.onNext.bind(this);
    this.onPrev = this.onPrev.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
  }

  componentWillMount(){
    const { actions, isVisiting, profile, isAuthenticated } = this.props;
    actions.resetBadges();

    if(isVisiting){
      return actions.getIssuedBadges(isAuthenticated, profile.id, 1);
    }
    if(isAuthenticated)
      actions.requestAllBadges(1);
  }

  onNext(){
    const { actions, badges, isVisiting, profile, isAuthenticated } = this.props;
    actions.resetBadges();
    if(isVisiting){
      return actions.getIssuedBadges(isAuthenticated, profile.id ,badges.paginationData.page+1);
    }
    actions.requestAllBadges(badges.paginationData.page+1);
  }

  onPrev(){
    const { actions, badges, isVisiting, profile, isAuthenticated } = this.props;
    actions.resetBadges();
    if(isVisiting){
      return actions.getIssuedBadges(isAuthenticated, profile.id, badges.paginationData.page-1);
    }
    actions.requestAllBadges(badges.paginationData.page-1);
  }

  onSelectPage(page){
    return () => {
      const { actions, isVisiting, profile, isAuthenticated } = this.props;
      actions.resetBadges();
      if(isVisiting){
        return actions.getIssuedBadges(isAuthenticated, profile.id, page);
      }
      return actions.requestAllBadges(page);
    };
  }

  changeLayout(type){
    return () => {
      if(type === this.state.layout)
        return;

      this.setState({
        layout: type
      });
    };
  }

  render() {
    const { layout } = this.state;
    const { badges, isRequesting, profile, currentUser, isVisiting, isRequestingIssued } = this.props;
    const paginationData = isVisiting ?
      badges.issuedBadges.paginationData :
      badges.paginationData;

    const { page_count, page } = paginationData;
    const isOwner = profile.id === currentUser.id;
    const objectPath = isVisiting ? badges.issuedBadges.data :
      badges.data;

    return(
      <div>
        <SkillbadgesSideMenu
          isVisiting={isVisiting}
          profile={profile}
          changeLayout={this.changeLayout}
          layout={layout}
          skillBadge/>

        <SkillBadgesContainer
          layout={layout}
          isOwner={isOwner}
          profile={profile}
          page_count={page_count}
          isRequesting={isRequesting || isRequestingIssued}
          isVisiting={isVisiting}
          page={page}/>

        {
          !isRequesting && !isRequestingIssued && objectPath.length > 0 &&
            <PaginationHandler
              page={page}
              onNext={this.onNext}
              onPrev={this.onPrev}
              onSelectPage={this.onSelectPage}
              page_count={page_count}/>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    badges: state.badges,
    isAuthenticated: state.auth.isAuthenticated,
    isRequesting: state.badges.isRequesting,
    isRequestingIssued: state.badges.issuedBadges.isRequesting,
    currentUser: state.auth.data.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, badgeActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillBadges);
