import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as wallPostsActions from '../../redux/actions/wall-posts';

const styles = {
  icon: { marginRight: 5 },
  iconText:{ marginRight: 15 }
};

class WallShareButton extends Component {
  static propTypes = {
    discussion: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    event.preventDefault();

    const { actions, discussion } = this.props;

    actions.shareWallpost(discussion);
  }

  render() {
    return (
      <a href="#"
        data-toggle="modal"
        onClick={this.onClick}
        data-target="#confirm-post-share">
        <i className="fa fa-share" style={styles.icon}/>
        <span style={styles.iconText}>Share</span>
      </a>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(wallPostsActions, dispatch)
  };
};

export default connect(null, mapDispatchToProps)(WallShareButton);
