import React from 'react';
import { connect } from 'react-redux';

import { isEmpty } from 'lodash';

import PropTypes from 'prop-types';
import { Input, Label, FormGroup } from 'reactstrap';

import SkillsHeaderTextTip from './SkillsHeaderText';

const ProfileCardHeader = (props) => {
  const isOwner = props.profileId === props.currentUser;
  const allowedAdmins = ['institution-admin', 'group-admin'];
  const isSuperAdmin = props.currentUserProfileData.role === 'super-admin';

  let isAffiliated = false;

  if (!isEmpty(props.profile) && (!isEmpty(props.currentUserProfileData))) {
    isAffiliated = props.isAuthenticated &&
      props.currentUserProfileData
        .affiliatedOrganizations
        .some(currentUserOrg =>
          props.profile.affiliatedOrganizations.some(ownerOrg => ownerOrg.id === currentUserOrg.id)
        );
  }

  const isAllowedAdmins = allowedAdmins.includes(props.currentUserProfileData.role) && isAffiliated;

  const displayShowHiddenSkillsButton = props.isAuthenticated &&
    (isOwner || isAllowedAdmins || isSuperAdmin);

  const [ , , , previewURL] = location.pathname.split('/');
  const isPreview = previewURL === 'preview';

  return (
    <div>
      {!props.isPublicProfile ?
        <div className="head">
          <div className="d-flex align-items-center">
            {props.title}{' '}
            {!props.isEditing &&
              <a>
                <i
                  className={
                    props.title === 'About Me' ||
                      props.title === 'My Work Situation' ||
                        props.title === 'Career Interests' ?
                      'add-button edit-profile-btn fa fa-xs fa-pencil'
                      :
                      'add-button edit-profile-btn fa fa-xs fa-plus-circle'
                  }
                  onClick={props.onEditClick}/>
              </a>}

            {!props.isEditing &&
              <a style={{ position: 'absolute', right: 0 }}>
                <i
                  className={
                    props.isDeletable ?
                      'edit-profile-btn fa fa-xs fa-trash clickable text-danger'
                      :
                      null
                  }
                  onClick={props.onDeleteClick}/>
              </a>}

            {props.title === 'Skills' ? <SkillsHeaderTextTip /> : null}
          </div>

          {props.title === 'Skills' && displayShowHiddenSkillsButton &&
            <FormGroup check className="pl-0 custom-checkbox-wrapper">
              <Label check className="container pl-4 m-0">
                Show Hidden Skills
                <Input
                  type="checkbox"
                  checked={props.toggleComponents.includes('show-hidden-skills')}
                  className="mr-1"
                  onChange={props.toggleShowHiddenSkills}/>
                <span className="checkmark" />
              </Label>
            </FormGroup>}
        </div>
        :
        <div className="head">
          {props.title}

          {isOwner && props.title === 'Skills' ?
            <SkillsHeaderTextTip /> : null}

          {props.title === 'Skills' &&
            (!isPreview) &&
            displayShowHiddenSkillsButton &&
            <FormGroup check className="pl-0 custom-checkbox-wrapper">
              <Label check className="container pl-4 m-0">
                Show Hidden Skills
                <Input
                  type="checkbox"
                  checked={props.toggleComponents.includes('show-hidden-skills')}
                  className="mr-1"
                  onChange={props.toggleShowHiddenSkills}/>
                <span className="checkmark" />
              </Label>
            </FormGroup>}
        </div>}
    </div>
  );
};

ProfileCardHeader.propTypes = {
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  isPublicProfile: PropTypes.bool,
  isEditing: PropTypes.bool,
  isDeletable: PropTypes.bool,
  profileData: PropTypes.object,
  currentUser: PropTypes.string,
  profileId: PropTypes.string,
  toggleComponents: PropTypes.array,
  toggleShowHiddenSkills: PropTypes.func,
  currentUserProfileData: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = ({
  auth: { isAuthenticated },
  profile: { data: currentUserProfileData },
  webpage: { data: profile }
}) => ({ profile, isAuthenticated, currentUserProfileData });

export default connect(mapStateToProps)(ProfileCardHeader);
