import React from 'react';

import PropTypes from 'prop-types';
import Parser from 'html-react-parser';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

const SeeExampleModal = ({
  isOpen,
  onToggle,
  question
}) => {
  return(
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="lg"
      toggle={onToggle}>
      <ModalHeader
        className="share-modal"
        toggle={onToggle}>Example Response to Question</ModalHeader>
      <ModalBody>
        <div>
          <div className="alert alert-danger">
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <i className="far fa-2x fa-exclamation-triangle text-danger mr-3" />
              <p className="mb-0">Use this example as a guide. It's visible to all community members and helps reviewers like educators, mentors and recruiters evaluate response quality. Make your response unique.</p>
            </div>
          </div>
          {Parser(question.exampleResponse)}
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-secondary" onClick={onToggle}>Close</button>
      </ModalFooter>
    </Modal>
  );
};

SeeExampleModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired
};

export default SeeExampleModal;
