import PropTypes from 'prop-types';
import React from 'react';
import SkillOptionItem from './SkillOptionItem';
import _find from 'lodash/find';
import _isUndefined from 'lodash/isUndefined';
import { Collapse } from 'reactstrap';

const SkillOptionsList = ({
  profile,
  isSkillsShown,
  skills,
  setSkillWithEvidence,
  selectedSkills,
  toggleSkillCheckbox
}) => {
  const skillsOrder = profile.data.skillsOrder.split(',').filter(id => id !== '').map(id => ({ id }));

  return (
    <Collapse isOpen={isSkillsShown}>
      <div className="skill-container">
        {skillsOrder.map((order, index) =>{
          const skill = _find(skills, item => item.id === order.id);

          if(_isUndefined(skill))
            return null;

          return (
            <SkillOptionItem
              key={index}
              skill={skill}
              profile={profile}
              selectedSkills={selectedSkills}
              toggleSkillCheckbox={toggleSkillCheckbox}
              setSkillWithEvidence={setSkillWithEvidence}/>
          );
        })}
      </div>
    </Collapse>
  );
};

SkillOptionsList.propTypes = {
  skills: PropTypes.array,
  isSkillsShown: PropTypes.bool,
  profile: PropTypes.object,
  selectedSkills: PropTypes.array.isRequired,
  toggleSkillCheckbox: PropTypes.func.isRequired,
  setSkillWithEvidence: PropTypes.func.isRequired
};

export default SkillOptionsList;
