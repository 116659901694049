import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withHooks } from '../../utils/withHooks';

import { Helmet } from 'react-helmet';
import { toastr } from 'react-redux-toastr';
import Validator from '../../validator';
import NewPasswordForm from '../../components/auth/NewPasswordForm';

import * as resetPasswordActions from '../../redux/actions/reset-password';
import * as componentActions from '../../redux/actions/components';

class NewPassword extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    newPassword: PropTypes.object.isRequired,
    linkParams: PropTypes.string,
    location: PropTypes.string,
    navigate: PropTypes.func.isRequired,
    onCloseResetPassword: PropTypes.func.isRequired,
    resetPassword: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      errors: {},
      newPassword: Object.assign({}, this.props.newPassword)
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.openLoginLodal = this.openLoginLodal.bind(this);
  }

  componentWillMount(){
    const { linkParams, location } = this.props;
    const linkParamsArray = linkParams?.split('/');
    const [,,,token,emailId] = location.pathname.split('/');

    this.setState({
      newPassword:{
        email: (linkParamsArray && linkParamsArray[1]) ? linkParamsArray[1] : decodeURIComponent(emailId),
        token: (linkParamsArray && linkParamsArray[0]) ? linkParamsArray[0] : token,
        password: '',
        rePassword: ''
      }
    });

  }

  componentWillReceiveProps(nextProps){
    const { data } = this.props.resetPassword;
    const { isSubmitting: nextIsSubmitting, data: nextData } = nextProps.resetPassword;
    if (data !== nextData && nextData.status === 200 && !nextIsSubmitting) {
      const initState = {
        email: '',
        token: '',
        password: '',
        rePassword: ''
      };

      this.setState({newPassword: Object.assign({}, initState)});
    }
  }


  isValid(field = null) {
    let validate = Validator.createValidator({
      password: ['required', 'minLength|8'],
      rePassword: ['required', 'minLength|8']
    }, this.state.newPassword, field);

    let { isValid, errors } = validate;

    this.setState({ errors });

    return isValid;
  }

  onSubmit(event) {
    event.preventDefault();

    const { newPassword } = this.state;

    if (! this.isValid()) return false;

    if (newPassword.password !== newPassword.rePassword) {
      return toastr.error('Entered passwords does not match.');
    }
    const [,,typeOfLink] = location.pathname.split('/');



    let data = {
      'email': newPassword.email,
      'token': newPassword.token,
      'password': newPassword.password,
      'passwordConfirmation': newPassword.rePassword,
    };

    if(typeOfLink === 'reset-password'){
      data.checkExpiry = true;
    }

    this.props.actions.resetPassword(data);

  }

  onChange(event) {
    const { name, value } = event.target;
    const { newPassword } = this.state;
    newPassword[name] = value;

    this.setState({ newPassword }, () => this.isValid(name));
  }

  openLoginLodal() {
    setTimeout(() => {
      this.props.actions.openModal('login-modal');
    },
    100);
    this.props.actions.closeModal();
    this.props.navigate('/');
  }

  render() {
    const { resetPassword, onCloseResetPassword } = this.props;
    const { newPassword , errors} = this.state;
    const { data, isSubmitting } = resetPassword;
    const [,,typeOfLink] = location.pathname.split('/');

    const pageTitle = typeOfLink === 'new-password' ? 'Setup your password' :
      'Reset your password';

    return(
      <div>
        <Helmet title={pageTitle}/>
        <NewPasswordForm
          openLoginModal={this.openLoginLodal}
          pageTitle={pageTitle}
          data={data}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
          errors={errors}
          isSubmitting={isSubmitting}
          onCloseResetPassword={onCloseResetPassword}
          formData={newPassword}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let newPassword = {
    email: '',
    token: '',
    password: '',
    rePassword: ''
  };

  return {
    newPassword,
    resetPassword: state.resetPassword
  };
};
const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, componentActions, resetPasswordActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(withHooks(NewPassword));
