import React from 'react';

import { useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';

const UnitResume = ({
  resume,
  title,
  onDeleteResume
}) => {
  const { id, docUrl } = resume;
  const location = useLocation();

  const isOwner = location.state === null;

  return(
    <div className="saved-item clearfix">
      <div className="title title-responsive float-left">
        <i className="fa fa-file-text-o mr-2"/>{title}
      </div>
      <div className="ui-container float-right">
        {isOwner &&
          <button
            onClick={onDeleteResume}
            type="button"
            id={id}
            name="button"
            className="btn btn-danger">
            <i className="fa fa-trash mr-2"/>
            <span className="hidden-sm-down">Delete</span>
          </button>}

        <a
          className="btn btn-tertiary ml-1"
          href={docUrl}>
          <i className="fa fa-download mr-2"/>
          <span className="hidden-sm-down">Download</span>
        </a>

      </div>
    </div>
  );
};

UnitResume.propTypes = {
  title: PropTypes.string,
  resume: PropTypes.object,
  onDeleteResume: PropTypes.func.isRequired
};
export default UnitResume;
