import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Validator from '../../validator';

import Select from 'react-select';
import { toastr } from 'react-redux-toastr';
import { Helmet } from 'react-helmet';
import TextInput from '../../components/common/TextInput';
import TextArea from '../../components/common/TextArea';
import Button from '../../components/common/Button';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import * as followersActions from '../../redux/actions/followers';
import * as followingActions from '../../redux/actions/following';
import * as messageActions from '../../redux/actions/messages';

class ComposeMessageModal extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool,
    message: PropTypes.object,
    isOpen: PropTypes.bool,
    toggleModal: PropTypes.func,
    selectableUsers: PropTypes.array,
    messages: PropTypes.array.isRequired
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      error: {},
      selectedUser: [],
      message: Object.assign({}, props.message),
    };

    this.onMessageChange = this.onMessageChange.bind(this);
    this.onChangeRecipients = this.onChangeRecipients.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps){
    const { messages, isSubmitting } = nextProps;

    if(!isSubmitting && messages !== this.props.messages){
      nextProps.toggleModal();
    }
  }

  clearErrorMessage() {
    let { error } = this.state;
    error = {};
    this.setState({ error });
  }

  onMessageChange(event) {
    const field = event.target.name;
    const { message } = this.state;

    message[field] = event.target.value;

    this.setState({ message }, () => this.isValid(field));
  }

  isValid(field = null) {
    const validate = Validator.createValidator({
      subject: ['required'],
      body: ['required']
    }, this.state, field);

    const { isValid, errors } = validate;

    this.setState({ errors });

    return isValid;
  }

  onChangeRecipients(value){
    this.setState({
      selectedUser : value
    });
  }

  onSubmit(){
    const { message, selectedUser } = this.state;

    if(selectedUser.length === 0)
      return toastr.warning('Please select at least one recipient.');

    if(message.subject === '' && message.body === '')
      return toastr.warning('You must enter a subject and message to send.');

    if(message.subject === '')
      return toastr.warning('You must enter a subject to send.');

    if(message.body === '')
      return toastr.warning('You must enter a message to send.');

    const data = {
      recipients : [...selectedUser.map(selected => selected.user.id)],
      subject: message.subject,
      body: message.body
    };

    this.props.actions.sendMessage(data);
  }

  render() {
    const { isOpen,toggleModal, isSubmitting, selectableUsers } = this.props;
    const { message, error, selectedUser } = this.state;

    if(selectableUsers.length === 0){
      return (
        <Modal
          className="modal-margin-top"
          isOpen={isOpen}
          toggle={toggleModal}>
          <ModalBody>
            <div style={{display: 'flex', textAlign: 'center', justifyContent: 'center'}}>
              <p>You can compose messages to followers or people you follow. Follow others to start composing messages.</p>
            </div>
            <Button
              onClick={toggleModal}
              buttonClass="btn-secondary"
              buttonText="Close"/>
          </ModalBody>
        </Modal>
      );
    }

    return (
      <div>
        <Helmet title="Compose new message"/>
        <Modal
          className="modal-margin-top"
          isOpen={isOpen}
          toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Compose New Message</ModalHeader>
          <ModalBody>
            <Select
              autoFocus
              isMulti
              closeMenuOnSelect={false}
              placeholder="Recipient(s)"
              onChange={this.onChangeRecipients}
              value={selectedUser}
              className={'form-group'}
              options={selectableUsers}/>
            <TextInput
              name="subject"
              type="textarea"
              error={error.subject}
              value={message.subject}
              onChange={this.onMessageChange}
              placeholder="Enter subject..."/>
            <TextArea
              name="body"
              type="textarea"
              error={error.message}
              value={message.message}
              onChange={this.onMessageChange}
              placeholder="Enter message..."/>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={isSubmitting}
              onClick={toggleModal}
              buttonClass="btn-secondary"
              buttonText="Cancel"/>
            <Button
              onClick={this.onSubmit}
              buttonClass="btn-primary"
              disabled={isSubmitting}
              buttonText={isSubmitting ? 'Sending...' : 'Send'}/>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const message = { recipients: [], subject: '', body: '' };

  return {
    message,
    messages: state.messages.data,
    isSubmitting: state.messages.isSubmitting,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    followersActions,
    messageActions,
    followingActions
  );
  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ComposeMessageModal);
