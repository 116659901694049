import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import initialState from '../../../../redux/reducers/initialState';

import { without } from 'lodash';

import { generateRequestUrl } from '../../../../utils';

import {
  getUsers,
  onChangeUsersFilters,
  displayAdvancedFilters
} from '../../../../redux/actions/users';

import { toastr } from 'react-redux-toastr';
import { Container, FormGroup, Jumbotron, Input, Label, Row, Col } from 'reactstrap';

import Button from '../../../common/Button';

class AdvancedFilterControls extends Component{
  static propTypes = {
    actions: PropTypes.object.isRequired,
    institutionId: PropTypes.string.isRequired,
    groupId: PropTypes.string,
    sort: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    displayAdvancedFilters: PropTypes.bool.isRequired,
    isAuthUserSelectedInstitutionAdmin: PropTypes.bool.isRequired
  }

  constructor(props){
    super(props);

    this.onChangeFilters = this.onChangeFilters.bind(this);
    this.onHideAdvancedFilters = this.onHideAdvancedFilters.bind(this);
  }

  componentWillUnmount() {
    const { users: { page, sort, filters } } = initialState;
    const {
      actions,
      groupId,
      institutionId,
      isAuthUserSelectedInstitutionAdmin
    } = this.props;

    const urlProps = {
      page,
      sort,
      filters,
      groupId,
      institutionId,
      isAuthUserSelectedInstitutionAdmin
    };

    const urlParamString = generateRequestUrl(urlProps);

    actions.getUsers(urlParamString);

    this.onHideAdvancedFilters();
  }

  onChangeFilters(event) {
    const { type, value, checked, name } = event.target;
    const { actions, filters } = this.props;

    let usersFilters = { ...filters };

    if (type !== 'checkbox') {
      usersFilters[name] = value;
    }

    if (type === 'checkbox') {
      usersFilters = {
        [name]: checked ? [...filters[name], value] : without(filters[name], value)
      };
    }

    actions.onChangeUsersFilters(usersFilters);
  }

  onHideAdvancedFilters() {
    const { actions, displayAdvancedFilters } = this.props;

    actions.displayAdvancedFilters(! displayAdvancedFilters);
  }

  onKeyDown = (e) => {
    if (e.key === 'Enter') this.onSearch();
  }

  onSearch = () => {
    const { actions } = this.props;

    if (this.isInvalidSearch()) {
      return toastr.error('', 'You must enter search criteria to conduct a search.');
    }

    const urlParamString = generateRequestUrl(this.props);

    actions.getUsers(urlParamString);
  }

  isInvalidSearch() {
    const { filters: { location, employmentType, employmentStatus } } = this.props;

    const invalidLocation = location.trim().length === 0;
    const invalidEmplymentType = employmentType.length === 0;
    const invalidEmploymentStatus = employmentStatus.length === 0;

    return invalidLocation && invalidEmplymentType && invalidEmploymentStatus;
  }

  render() {
    const {
      isRequesting,
      filters: { location, employmentType, employmentStatus }
    } = this.props;

    return (
      <Jumbotron fluid className="mt-2 mb-3 p-4">
        <Container fluid>
          <Row className="justify-content-between align-items-center mb-3">
            <Col xs="auto">
              <span className="text-secondary">FILTER BY</span>
            </Col>
            <Col xs="auto">
              <span
                aria-hidden="true"
                style={{color: 'red', cursor: 'pointer'}}
                onClick={this.onHideAdvancedFilters}>
                <i className="fa fa-times"/>
                {' '}CLEAR
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Row>
                <Col md="4">
                  <h6>Employment Status</h6>
                  <FormGroup tag="fieldset">
                    <FormGroup check>
                      <Label check>
                        <Input
                          onChange={this.onChangeFilters}
                          type="checkbox"
                          value="0"
                          checked={employmentStatus.includes('0')}
                          name="employmentStatus" />{' '}
                        Employed, Not Seeking
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          onChange={this.onChangeFilters}
                          type="checkbox"
                          value="1"
                          checked={employmentStatus.includes('1')}
                          name="employmentStatus" />{' '}
                        Employed, Seeking
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          onChange={this.onChangeFilters}
                          type="checkbox"
                          value="2"
                          checked={employmentStatus.includes('2')}
                          name="employmentStatus" />{' '}
                        Unemployed, Seeking
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          onChange={this.onChangeFilters}
                          type="checkbox"
                          value="3"
                          checked={employmentStatus.includes('3')}
                          name="employmentStatus" />{' '}
                        Unemployed, Not Seeking
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          onChange={this.onChangeFilters}
                          type="checkbox"
                          value="NULL"
                          checked={employmentStatus.includes('NULL')}
                          name="employmentStatus" />{' '}
                        Not Provided
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <h6>Employment Type</h6>
                  <FormGroup tag="fieldset">
                    <FormGroup check>
                      <Label check>
                        <Input
                          onChange={this.onChangeFilters}
                          type="checkbox"
                          value="0"
                          checked={employmentType.includes('0')}
                          name="employmentType" />{' '}
                        Full-Time
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          onChange={this.onChangeFilters}
                          type="checkbox"
                          value="3"
                          checked={employmentType.includes('3')}
                          name="employmentType" />{' '}
                        Part-Time
                      </Label>
                    </FormGroup>

                    <FormGroup check>
                      <Label check>
                        <Input
                          onChange={this.onChangeFilters}
                          type="checkbox"
                          value="1"
                          checked={employmentType.includes('1')}
                          name="employmentType" />{' '}
                        Paid Internship
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          onChange={this.onChangeFilters}
                          type="checkbox"
                          value="4"
                          checked={employmentType.includes('4')}
                          name="employmentType" />{' '}
                        Unpaid Internship
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          onChange={this.onChangeFilters}
                          type="checkbox"
                          value="2"
                          checked={employmentType.includes('2')}
                          name="employmentType" />{' '}
                        Temporary Contract
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          onChange={this.onChangeFilters}
                          type="checkbox"
                          value="5"
                          checked={employmentType.includes('5')}
                          name="employmentType" />{' '}
                        Self-employed
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          onChange={this.onChangeFilters}
                          type="checkbox"
                          value="6"
                          checked={employmentType.includes('6')}
                          name="employmentType" />{' '}
                        Not Working
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          onChange={this.onChangeFilters}
                          type="checkbox"
                          value="NULL"
                          checked={employmentType.includes('NULL')}
                          name="employmentType" />{' '}
                        Not Provided
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <h6>Location</h6>
                  <Input
                    type="text"
                    value={location}
                    onKeyDown={this.onKeyDown}
                    onChange={this.onChangeFilters}
                    aria-label="location"
                    name="location" />
                  <Button
                    onClick={this.onSearch}
                    styles={{marginLeft: 0}}
                    buttonText={isRequesting ? 'Searching' : 'Search'}
                    buttonClass="btn-block btn-primary mt-2"
                    disabled={isRequesting}/>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    users: { page, sort, filters, isRequesting, displayAdvancedFilters },
    institutions: { selectedInstitution: { id: institutionId } },
    groups: {
      selectedGroup: { id: groupId },
      isAuthUserSelectedInstitutionAdmin
    }
  } = state;

  return {
    page,
    sort,
    filters,
    groupId,
    isRequesting,
    institutionId,
    displayAdvancedFilters,
    isAuthUserSelectedInstitutionAdmin
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    getUsers,
    onChangeUsersFilters,
    displayAdvancedFilters
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedFilterControls);
