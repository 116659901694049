import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getUsers, getEducatorUsers, onChangeUsersSearchQuery } from '../../../../redux/actions/users';
import { SEARCH_CRITERIA_VALIDATION } from '../../../../utilConstants';

import { toastr } from 'react-redux-toastr';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';

class SearchInput extends Component {
  static propTypes = {
    role: PropTypes.string,
    groupId: PropTypes.string,
    actions: PropTypes.object.isRequired,
    sort: PropTypes.object,
    searchQuery: PropTypes.string.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    isRequestingIR: PropTypes.bool,
    institutionId: PropTypes.string,
    displayAdvancedFilters: PropTypes.bool.isRequired,
    isAuthUserSelectedInstitutionAdmin: PropTypes.bool.isRequired,
    placeholder: PropTypes.string,
    searchManageIR: PropTypes.func,
    onChangeManageIR: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      displayClearButton: false
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillUnmount() {
    this.props.actions.onChangeUsersSearchQuery('');
  }

  onChange(e) {
    this.props.actions.onChangeUsersSearchQuery(e.target.value);
  }

  onSearch = () => {
    const { searchQuery } = this.props;

    if (searchQuery.trim().length === 0) {
      return toastr.error(SEARCH_CRITERIA_VALIDATION);
    }

    this.setState({ displayClearButton: true });
    this.sendHttpRequest();
  }

  onKeyDown = (e) => {
    if(e.key === 'Enter') this.onSearch();
  }

  onClear = () => {
    this.props.actions.onChangeUsersSearchQuery('');
    this.setState({ displayClearButton: false }, () => this.sendHttpRequest());
  }

  sendHttpRequest = () => {
    const {
      role,
      sort,
      actions,
      groupId,
      searchQuery,
      institutionId,
      isAuthUserSelectedInstitutionAdmin,
      searchManageIR,
    } = this.props;

    if(searchManageIR){
      searchManageIR(searchQuery);
      return;
    }

    let sortParam = '';

    const { order, column } = sort;

    if (column.length !== 0) {
      const sortOrder = order ? 'asc' : 'desc';
      sortParam = `${column}:${sortOrder}`;
    }

    let urlParamString = `group=${groupId}`;

    if ((role === 'educator') && (groupId === 'all-groups')) {
      urlParamString = '';
    }

    if (isAuthUserSelectedInstitutionAdmin && (groupId === 'all-groups')) {
      urlParamString = `institution=${institutionId}`;
    }

    urlParamString = `${urlParamString}&query=${searchQuery}`;

    if (sortParam.length !== 0) {
      urlParamString = `${urlParamString}&sort=${sortParam}`;
    }

    if (role === 'educator') {
      actions.getEducatorUsers(urlParamString);
      return;
    }

    actions.getUsers(urlParamString);
  }

  render() {
    const { displayClearButton } = this.state;
    const { searchQuery, isRequesting, isRequestingIR, displayAdvancedFilters } = this.props;

    return (
      <InputGroup>
        <div style={{ position: 'relative', flex: 1 }}>
          <Input
            placeholder="Search table..."
            value={searchQuery}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            style={{borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: 'none'}}
            className="mr-1"
            disabled={displayAdvancedFilters}/>

          {displayClearButton && <span className="fa fa-times"
            style={{
              position: 'absolute',
              zIndex: 9,
              right: '10px',
              top: '10px',
              cursor: 'pointer',
            }}
            onClick={this.onClear}/>}
        </div>
        <InputGroupAddon className="p-0" addonType="append">
          <button
            style={{borderLeft: 'none', borderRadius: '0px 5px 5px 0px'}}
            onClick={this.onSearch}
            disabled={((isRequesting || isRequestingIR) && (searchQuery.trim().length !== 0) || displayAdvancedFilters)}
            className="btn btn-primary">
            {((isRequesting || isRequestingIR) && (searchQuery.trim().length !== 0) ? 'Searching...' : 'Search')}
          </button>
        </InputGroupAddon>
      </InputGroup>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    users: {
      sort,
      filters: { searchQuery },
      isRequesting,
      displayAdvancedFilters
    },
    groups: {
      selectedGroup: { id: institutionSelectedGroupId },
      isAuthUserSelectedInstitutionAdmin
    },
    educators: { selectedGroup: { id: educatorSelectedGroupId } },
    profile: { data: { role } },
    institutions: { selectedInstitution: { id: institutionId } }
  } = state;

  const groupId = (role === 'educator') ? educatorSelectedGroupId : institutionSelectedGroupId;

  return {
    role,
    sort,
    groupId,
    searchQuery,
    isRequesting,
    institutionId,
    displayAdvancedFilters,
    isAuthUserSelectedInstitutionAdmin
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    getUsers,
    getEducatorUsers,
    onChangeUsersSearchQuery
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput);
