import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import * as interviewActions from '../../redux/actions/interview';
import * as componentsActions from '../../redux/actions/components';

import InterviewPrepVideosList from '../../components/toolbox/InterviewPrepVideosList';

class InterviewPrepVideos extends Component {
  static propTypes = {
    modal: PropTypes.string,
    interview: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.onLikeVideo = this.onLikeVideo.bind(this);
    this.onShareToCp = this.onShareToCp.bind(this);
  }


  componentDidMount(){
    this.props.actions.fetchInterviewPrepVideos();
  }

  onLikeVideo(id){

    return () => {
      const { actions, interview } = this.props;
      const video = interview.data.find(video => video.id === id);

      if(video.liked){
        return actions.unlikeVideo(id);
      }

      const data = {
        'resource': id
      };

      actions.onLikeVideo(data);
    };
  }

  onShareToCp(id){
    return () => {
      this.props.actions.openModal(`share-video-from-list-to-cp-${id}`);
    };
  }


  render() {
    const { modal, interview } = this.props;
    const { isRequesting, data: interviewPrepVides, unitVideo } = interview;

    return (
      <div>
        <Helmet title="Interview Prep Videos"/>
        <div className="content targeted-resume" style={{marginTop: 30}}>
          <div className="row">
            <div className="col-lg-12">
              <h2 className="mb-1">Interview Prep Videos</h2>
              <p>Get detailed advice on how to answer common interview questions. Watch samples of ineffective vs. effective responses with feedback you can use to ace your next interview. Check out <Link to="/skill-builders/excel-in-job-interviews">Excel in Job Interviews</Link> for tips on how to ace your next interview.</p>
            </div>
          </div>
          <div id="prep-videos" className="classname">
            <div className="mt-2 mb-2">
              <InterviewPrepVideosList
                modal={modal}
                isLiking={unitVideo.isLiking}
                onLikeVideo={this.onLikeVideo}
                isRequesting={isRequesting}
                onShareToCp={this.onShareToCp}
                videoList={interviewPrepVides}/>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    modal: state.components.modal,
    interview: state.interview
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, interviewActions, componentsActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InterviewPrepVideos);
