import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as componentActions from '../../../redux/actions/components';
import * as wallPostActions from '../../../redux/actions/wall-posts';
import TextArea from '../../../components/common/TextArea';
import { Dropdown, DropdownToggle, DropdownMenu, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Button from '../../../components/common/Button';
import { isEmpty } from 'lodash';
import { toastr } from 'react-redux-toastr';


const visibilityOptionsArray = [
  {
    title:'Everyone (Public)',
    alias: 'public',
    value:'1'
  },
  {
    title:'Followers Only',
    alias: 'followers',
    value:'2'
  },
  {
    title:'Only Me',
    alias: 'private',
    value:'0'
  },
];


class ShareInterviewVideoModal extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    shareData: PropTypes.object.isRequired,
    videoData: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired
  }

  constructor(props, context) {
    super(props, context);

    this.state ={
      errors: {},
      visibilityDropdownOpen: false,
      shareData: Object.assign({}, this.props.shareData)
    };

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onShare = this.onShare.bind(this);
    this.onChangeShareData = this.onChangeShareData.bind(this);
    this.onToggleVisibilityDropdown = this.onToggleVisibilityDropdown.bind(this);
    this.onSelectVisibilty = this.onSelectVisibilty.bind(this);
  }

  onChangeShareData(event) {
    let { value, name } = event.target;
    const { shareData } = this.state;

    shareData[name] = value;

    this.setState({ shareData });
  }

  onToggleVisibilityDropdown() {
    const { visibilityDropdownOpen } = this.state;
    this.setState({
      visibilityDropdownOpen: !visibilityDropdownOpen
    });
  }

  onSelectVisibilty(id){

    return () => {
      const shareData = { ...this.state.shareData, permissions:id};

      this.setState({
        shareData,
        visibilityDropdownOpen: false
      });
    };
  }

  onCloseModal(){
    const { actions } = this.props;
    actions.closeModal();
  }

  onShare(){
    const { shareData } = this.state;
    const { actions, videoData } = this.props;
    const { title, id, description } = videoData;
    const dropdownButton = visibilityOptionsArray.find(option =>
      option.value == shareData.permissions || option.alias == shareData.permissions);

    const data = {
      'post': `${title} : ${description}`,
      'permissions': dropdownButton.value,
      'sharedInterviewResource': id,
      'sharedQuotedText': shareData.quote
    };

    actions.saveWallPost(data).
      then((response) =>{     
        if(isEmpty(response.wallPosts.error)){
          toastr.success('', 'You successfully shared this as a post');
        }
        actions.closeModal();
      });
  }

  render() {
    const { visibilityDropdownOpen, shareData } = this.state;
    const { isOpen, videoData, isSubmitting } = this.props;
    const { title, thumbnail } = videoData;
    const dropdownButton = visibilityOptionsArray.find(option =>
      option.value == shareData.permissions || option.alias == shareData.permissions);

    return (
      <Modal 
        backdrop="static"
        isOpen={isOpen} 
        toggle={this.onCloseModal}>
        <ModalHeader
          toggle={this.onCloseModal}>
          Share Interview Prep Videos
        </ModalHeader>
        <ModalBody>
          <div className="shared-item">
            <TextArea 
              name="quote"
              type="text"
              rows="3"
              value={shareData.quote}
              onChange={this.onChangeShareData}
              placeholder="Say something about this video"/>
            <div>
              <div className="shared-item-container">
                <div className="shared-item-image">
                  <img src={thumbnail}/>
                </div>
                <div className="shared-item-description">
                  <h6>{title}</h6>
                  <div className="description">
                    <p>Click to see details for this Interview Prep Video.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            disabled={isSubmitting}
            onClick={this.onCloseModal}
            buttonClass="btn-secondary mr-auto"
            styles={{ marginLeft: 0 }}
            buttonText="Cancel"/>
          <Dropdown
            isOpen={visibilityDropdownOpen}
            toggle={this.onToggleVisibilityDropdown}>
            <DropdownToggle
              caret
              tag="button"
              type="button"
              className="btn btn-tertiary">
              {dropdownButton == null ? 'Who can see this post' : dropdownButton.title}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu">
              {visibilityOptionsArray.map((option,index) => {
                return (
                  <a className="dropdown-item" key={index} onClick={this.onSelectVisibilty(option.value)}>
                    {option.title}
                  </a>
                );
              })}
            </DropdownMenu>
          </Dropdown>
          <Button
            type="submit"
            disabled={isSubmitting}
            onClick={this.onShare}
            buttonClass="btn-primary"
            styles={{ marginLeft: 0 }}
            buttonText={isSubmitting ? 'Sharing...' : 'Share'}/>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  let shareData = {
    quote: '',
    permissions: '1'
  };

  return {
    shareData,
    isSubmitting: state.wallPosts.isSubmitting
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({},
    componentActions,
    wallPostActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(ShareInterviewVideoModal);
