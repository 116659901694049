import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import { isUndefined } from 'lodash';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as organizationInvitationActions from '../../../../redux/actions/organization-invitations';
import * as organizationsActions from '../../../../redux/actions/organizations';

class Search extends Component {
  static propTypes = {
    params: PropTypes.object,
    className: PropTypes.string,
    sort: PropTypes.object,
    table: PropTypes.string.isRequired,
    searchQuery: PropTypes.string.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired,
    institutionId: PropTypes.string,
    onChangeSearchQuery: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      displayClearButton: false
    };
  }

  onChange = (e) => {
    this.setState({ query: e.target.value });
    this.props.onChangeSearchQuery(e.target.value);
  }

  onSearch = () => {
    const { searchQuery } = this.props;

    if (searchQuery.trim().length === 0) {
      return toastr.error('You must enter search criteria to conduct a search.');
    }

    this.setState({ displayClearButton: true });
    this.sendHttpRequest();
  }

  onKeyDown = (e) => {
    if(e.key === 'Enter') this.onSearch();
  }

  onClear = () => {
    this.props.onChangeSearchQuery('');
    this.setState({ displayClearButton: false }, () => this.sendHttpRequest());
  }

  sendHttpRequest = () => {
    const { institutionId, sort, table, searchQuery } = this.props;
    let sortParam = '';

    if (! isUndefined(sort)) {
      const { asc, column } = sort;

      if (column.length !== 0) {
        const sortOrder = asc ? 'asc' : 'desc';
        sortParam = `${column}:${sortOrder}`;
      }
    }

    if (table === 'invitations') {
      let urlParams = `institution=${institutionId}&query=${searchQuery}`;

      if (sortParam.length !== 0) {
        urlParams = `${urlParams}&sort=${sortParam}`;
      }

      return this.props.actions.getInvitations(urlParams);
    }

    let urlParams = `query=${searchQuery}`;

    return this.props.actions.searchOrganizations(urlParams);
  }

  render() {
    const { displayClearButton } = this.state;
    const { searchQuery, isRequesting } = this.props;

    return (
      <InputGroup
        className={this.props.className}>
        <div style={{ position: 'relative' }}>
          <Input
            placeholder="Search invitations..."
            value={searchQuery}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            style={{borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: 'none'}}
            className="mr-1"/>

          {displayClearButton && <span className="fa fa-times"
            style={{
              position: 'absolute',
              zIndex: 9,
              right: '12px',
              top: '12px',
              cursor: 'pointer',
            }}
            onClick={this.onClear}/>}
        </div>
        <InputGroupAddon className="p-0" addonType="append">
          <button
            style={{borderLeft: 'none', borderRadius: '0px 5px 5px 0px'}}
            onClick={this.onSearch}
            disabled={(isRequesting && (searchQuery.trim().length !== 0))}
            className="btn btn-primary">
            {(isRequesting && (searchQuery.trim().length !== 0) ? 'Searching...' : 'Search')}
          </button>
        </InputGroupAddon>
      </InputGroup>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, organizationsActions, organizationInvitationActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(null, mapDispatchToProps)(Search);
