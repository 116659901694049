import PropTypes from 'prop-types';
import React from 'react';

import _isUndefined from 'lodash/isUndefined';

import Gallery from './Gallery';
import ImageCard from './ImageCard';
import { DropTarget } from 'react-dnd';
import ItemTypes from '../../../constants/ItemTypes';

const ImageList = ({
  images,
  isAdmin,
  openPhotoSwipe,
  onDeleteImage,
  moveCard,
  activeSlideIndex,
  changeActiveSlideIndex,
  isEditing,
  tooltipOpen,
  toggleTooltip
}) => {
  if (!_isUndefined(isEditing))
    return (
      <div>
        {! _isUndefined(images) &&
          images.map((image, index) =>
            (<ImageCard
              type="image-type"
              key={image.url}
              index={index}
              images={images}
              {...image}
              isAdmin={isAdmin}
              tooltipOpen={tooltipOpen}
              toggleTooltip={toggleTooltip}
              moveCard={moveCard}
              onDeleteImage={onDeleteImage}
              openPhotoSwipe={openPhotoSwipe}/>))}
      </div>
    );

  return (
    <div>
      <div className="slider-container">
        <div className="slider-content">
          <div className={'panel-wrapper animated'}>
            {activeSlideIndex !== 0 &&
              <span
                className="prev-icon"
                id="prev-img"
                onClick={changeActiveSlideIndex}/>}

            {! _isUndefined(images) &&
              images
                .slice(activeSlideIndex, images.length)
                .map((image, index) =>
                  (<ImageCard
                    type="image-type"
                    key={image.url}
                    index={index}
                    images={images}
                    {...image}
                    tooltipOpen={tooltipOpen}
                    toggleTooltip={toggleTooltip}
                    isAdmin={isAdmin}
                    moveCard={moveCard}
                    onDeleteImage={onDeleteImage}
                    openPhotoSwipe={openPhotoSwipe}/>))}

            {! _isUndefined(images) && <Gallery/>}

            {activeSlideIndex !== images.length - 1 &&
              <span
                className="next-icon"
                id="next-img"
                onClick={changeActiveSlideIndex}/>}
          </div>
        </div>
      </div>

      <div className="slide-indicator">
        {activeSlideIndex + 1} of {images.length}
      </div>
    </div>
  );
};

ImageList.propTypes = {
  images: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  openPhotoSwipe: PropTypes.func,
  onDeleteImage: PropTypes.func,
  moveCard: PropTypes.func,
  activeSlideIndex: PropTypes.number,
  changeActiveSlideIndex: PropTypes.func,
  isEditing: PropTypes.bool,
  tooltipOpen: PropTypes.bool,
  toggleTooltip: PropTypes.func
};

export default DropTarget(ItemTypes.CARD, {}, connect => ({
  connectDropTarget: connect.dropTarget()
}))(ImageList);
