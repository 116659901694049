import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toastr } from 'react-redux-toastr';
import { Helmet } from 'react-helmet';

import { isUndefined } from 'lodash';

import { withHooks } from '../../utils/withHooks';

import * as fileActions from '../../redux/actions/files';
import * as componentsActions from '../../redux/actions/components';
import * as profileActions from '../../redux/actions/profile';
import * as wallPostActions from '../../redux/actions/wall-posts';
import * as wallPostCommentActions from '../../redux/actions/wall-post-comments';

import ActivityFeedPane from '../../components/dashboard/ActivityFeedPane';

class ActivityFeed extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    wallPosts: PropTypes.object.isRequired,
    wallShare: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool,
    modal: PropTypes.string,
    route: PropTypes.object,
    params: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      isSortByOpen:false,
      isRequestingSort: false,
      sort: 'newest',
      activityFilter: 'all_activity',
      shouldCallAPIInitially: true,
      toggleActiveBanner: false
    };

    this.toggleSortBy = this.toggleSortBy.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.callAPI = this.callAPI.bind(this);
    this.handleBeforeSorting = this.handleBeforeSorting.bind(this);
    this.onChangeActivityFilter = this.onChangeActivityFilter.bind(this);
  }

  componentWillMount(){
    this.resetStore();

    const { params: { postId }, actions } = this.props;

    if (!isUndefined(postId)) {
      actions.wallPostRequest(postId);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.params.postId !== prevProps.params.postId) &&
        (!isUndefined(this.props.params.postId))
    ) {
      this.resetStore();
      this.props.actions.wallPostRequest(this.props.params.postId);
    }
  }

  resetStore(){
    const { actions } = this.props;

    actions.resetWall();
    actions.resetWallComments();
  }

  handleBeforeSorting(event){
    const sort = event.target.id;

    if (sort === this.state.sort)
      return toastr.warning('', `Already sorted by ${sort}.`);

    this.setState({
      shouldCallAPIInitially: false,
      isRequestingSort: true,
      isSortByOpen: false,
      sort
    }, () => this.fetchSortedPosts());

    this.resetStore();
  }

  onChangeActivityFilter(event) {
    const activityFilter = event.target.value;

    if (activityFilter === this.state.activityFilter) {
      return toastr.warning('', `Aready filtered by ${activityFilter}`);
    }

    this.setState({ activityFilter, isRequestingSort: true }, () => this.fetchSortedPosts());

    this.resetStore();
  }

  callAPI(pageNo) {
    const { sort, activityFilter } = this.state;
    const { currentUser, actions } = this.props;

    return actions.wallPostsRequest(currentUser.id, pageNo, sort, activityFilter);
  }

  fetchSortedPosts() {
    const { sort, activityFilter } = this.state;
    const { currentUser, actions } = this.props;

    actions
      .wallPostsRequest(currentUser.id, 1, sort, activityFilter)
      .then(() => this.setState({ isRequestingSort: false }));
  }

  toggleSortBy(){
    this.setState({ isSortByOpen: !this.state.isSortByOpen });
  }

  onCloseModal(){
    const { modal, actions } = this.props;

    if(modal)
      actions.closeModal();
  }

  render() {
    const {
      isSortByOpen,
      shouldCallAPIInitially,
      isRequestingSort,
      sort
    } = this.state;

    const {
      route,
      profile,
      wallPosts,
      wallShare,
      isAuthenticated,
      modal
    } = this.props;

    const emptyPostModal = modal && modal === 'emptyPostModal';

    return (
      <div>
        <Helmet title="Activity Feed"/>

        <ActivityFeedPane
          sort={sort}
          route={route}
          profile={profile}
          wallPosts={wallPosts}
          isSortByOpen={isSortByOpen}
          toggleSortBy={this.toggleSortBy}
          isAuthenticated={isAuthenticated}
          callAPI={this.callAPI}
          isRequestingSort={isRequestingSort}
          onChangeActivityFilter={this.onChangeActivityFilter}
          shouldCallAPIInitially={shouldCallAPIInitially}
          wallShare={wallShare}
          emptyPostModal={emptyPostModal}
          onCloseModal={this.onCloseModal}
          handleBeforeSorting={this.handleBeforeSorting}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.data,
    wallPosts: state.wallPosts,
    currentUser: state.auth.data.currentUser,
    wallShare: state.wallPosts.wallShare,
    modal: state.components.modal,
    isAuthenticated: state.auth.isAuthenticated
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    profileActions,
    wallPostActions,
    wallPostCommentActions,
    fileActions,
    componentsActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(ActivityFeed));
