import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'reactstrap';

import Loading from '../../components/common/Loading';
import SkillChallenge from '../../components/skill-builders/essentials/SkillChallenge';

const engageIcon = require('../../assets/images/img-skillchallenge.png');

class EndSectionSkillChallenge extends Component {
  static propTypes = {
    unit: PropTypes.object.isRequired,
    isRequesting: PropTypes.bool.isRequired
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      tooltipOpen: false
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    const { unit, isRequesting } = this.props;

    return (
      <article className="essential-action skill-challenge">
        <div className="challenge-header">
          <img src={engageIcon}/>
          <span className="heading">Skill Challenge</span>
        </div>

        <p className="challenge-info">Practice and demonstrate your abilities, and develop portfolio evidence to link to your claimed Skill Badge.
          <i style={{color: 'orange'}} id="skill-challege-tooltip" className="fa fa-question-circle" aria-hidden="true"/>
        </p>
        <Tooltip
          placement="top"
          isOpen={this.state.tooltipOpen}
          target={'skill-challege-tooltip'}
          toggle={this.toggleTooltip}>
          <div className="inside-tooltip" style={{fontSize: 12}}>
            <p>
              Accepting a Skill Challenge will allow you to download a
              Word document with instructions to complete the
              challenge and prepare the associated portfolio file.
            </p>
          </div>
        </Tooltip>
        {isRequesting && <Loading/>}

        {(! isRequesting) && (unit.skillBuilderChallenges) &&
          <div className="challenges">
            {unit.skillBuilderChallenges.map(challenge =>
              (<SkillChallenge challenge={challenge} key={challenge.id}/>))}
          </div>}
      </article>
    );
  }
}

export default EndSectionSkillChallenge;