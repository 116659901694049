import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import {
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
  Collapse,
} from 'reactstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { get, isEmpty, isNull } from 'lodash';

import TopPraises from '../../components/myfeedback/TopPraises';
import FeedbackGiven from '../../components/myfeedback/FeedbackGiven';
import FeedbackReceived from '../../components/myfeedback/FeedbackReceived';
import IgnoreFeedbackRequest from '../../components/myfeedback/IgnoreFeedbackRequest';
import { withHooks } from '../../utils/withHooks';
// import IRRank from './IRRank';

import 'react-datepicker/dist/react-datepicker.css';

import {
  getGivenFeedbacks,
  getReceivedFeedbacks,
  resendFeedbackRequest,
  removeCancelledFeedbackRequest,
  ignoreFeedbackRequest,
  getRequestedFeedbacks,
  getGivenCompletedFeedbacks,
  getIRRank,
  getFeedbackTopPraises,
  clearMyFeedback,
} from '../../redux/actions/feedback';

import {
  getFeedbackOptionsRequest,
  submitFeedbackPraiseRequest,
} from '../../redux/actions/feedback-praise';

import FeedbackTableComponent from '../../components/myfeedback/FeedbackTableComponent';

import {
  ALL,
  CANCEL,
  DATE_RANGE_VALIDATION,
  FEEDBACK_GIVEN,
  FEEDBACK_RECEIVED,
  FEEDBACK_REMOVED_SUCCESS,
  FEEDBACK_REQUESTS,
  FEEDBACK_REQUEST_CANCEL_SUCCESS,
  FEEDBACK_RESEND_SUCCESS,
  FEEDBACK_STATUS_MESSAGE,
  FROM,
  GIVEN,
  IGNORE,
  MANAGE_FEEDBACK_HEADER,
  MY_REQUESTS,
  NO_RECORDS,
  NO_GIVEN_RECORDS,
  RECEIVED,
  REMOVE,
  RESEND,
  RESPOND,
  SHOW_MORE,
  START_DATE_VALIDATION,
  TO,
  VIEW_FEEDBACK_HEADER,
  VIEW_REQUESTS_HEADER,
} from '../../constants/feedback';
import {
  FEEDBACK_DATE_FORMAT,
  SETTINGS_DATE_FORMAT,
  TABLE_DATE_FORMAT,
} from '../../constants';
import { SimpleToolTipWrapper } from '../../components/common/ToolTipWrapper';
import Loading from '../../components/common/Loading';

const statusList = [
  { label: 'All Status', value: 'all_status' },
  { label: 'Completed', value: 'completed' },
  { label: 'Pending', value: 'pending' },
  { label: 'Canceled', value: 'canceled' },
  { label: 'Matching', value: 'matching' },
  { label: 'Removed', value: 'removed' },
];

const filterOptions = [
  { label: 'Newest', value: 'newrequest' },
  { label: 'Oldest', value: 'oldrequest' },
  { label: 'Recipient Name (A- Z)', value: 'a-z-name' },
  { label: 'Recipient Name: (Z-A)', value: 'z-a-name' },
  { label: 'Skill Name (A-Z)', value: 'a-z-resourcename' },
  { label: 'Skill Name (Z-A)', value: 'z-a-resourcename' },
];

class MyFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapseOpen: true,
      filteredReceived: this.props.feedbackReceived,
      filteredCompletedFeedback: null,
      filteredFeedbackReceived: null,
      filteredRequested: null,
      currentTab: 'given',
      isOpen: false,
      isIgnoreModalOpen: false,
      operation: 'Ignore',
      currentRequest: null,
      startDate: null,
      page: 1,
      sort: {},
      filteredGivenOption: 'Newest',
      filteredReceivedOption: 'Newest',
      statusRequested: 'all_status',
      resourceRequested: 'all',
      isRankPopoverOpen: false,
      isSearchChanged: false,
      isRequesting: true,
      prevSortOption: false,
      searchGiven: '',
      searchReceived: '',
      searchRequested: ''
    };

    this.onSortTable = this.onSortTable.bind(this);
    this.onMyRequestSortTable = this.onMyRequestSortTable.bind(this);
  }

  componentDidMount() {
    this.updateFeedbacksData();
    const tab = this.props?.params?.requestType;

    switch (tab) {
    case 'received':
      this.setCurrentTab('received');
      break;
    case 'given':
      this.setCurrentTab('given');
      break;
    case 'myRequests':
      this.setCurrentTab('myRequests');
      break;
    default:
      break;
    }

    if (this.props?.location?.hash === '#myRequest') {
      this.setCurrentTab('myRequests');
    }
  }

  updateFeedbacksData = async () => {
    this.clearMyFeedbackData();
    this.props.actions.getFeedbackOptionsRequest();
    this.props.actions.getFeedbackTopPraises();

    await this.props.actions.getGivenFeedbacks(this.props.currentUser.id, null);
    await this.props.actions.getGivenCompletedFeedbacks(
      this.props.currentUser.id,
      null
    );
    await this.props.actions.getRequestedFeedbacks(this.props.currentUser.id, null);
    await this.props.actions.getReceivedFeedbacks(this.props.currentUser.id, null);

    this.setState({isRequesting: false});

    if(this.props.profile.role === 'industry-representative'){
      await this.props.actions.getIRRank();
    }
  };

  clearMyFeedbackData(){
    this.props.actions.clearMyFeedback(); // to clear all feedback tab data
  }

  setCurrentRequest = (requestId) => {
    this.setState({ currentRequest: requestId });
  };

  operationFeedback = (operation) => {
    const requestID = this.state.currentRequest;
    if (operation === CANCEL || operation === IGNORE) {
      this.ignoreFeedbackRequest(requestID);
    }

    if (operation === REMOVE) {
      this.removeCancelledFeedbackRequest(requestID);
    }

    if (operation === RESEND) {
      this.resendFeedbackRequest(requestID);
    }
  };

  ignoreFeedbackRequest = (request_id) => {
    const requestID = this.state.currentRequest || request_id;
    const status = this.state.currentTab === 'given' ? 'ignored' : 'canceled';
    if (requestID) {
      this.props.actions.ignoreFeedbackRequest(requestID, status).then(() => {
        if (status === 'ignored') {
          this.props.actions.getGivenFeedbacks(this.props.currentUser.id, null);
          toastr.success(FEEDBACK_REQUEST_CANCEL_SUCCESS);
        } else {
          toastr.success(FEEDBACK_STATUS_MESSAGE(status));
        }
        this.props.actions.getRequestedFeedbacks(this.props.currentUser.id, null);
      });
    }
  };

  setCurrentTab = (event) => {
    const currentTab = event.target?.getAttribute('data-value') || event;
    this.setState({ currentTab });
  };

  toggleCollapse = () => {
    this.setState((state) => {
      return {
        isCollapseOpen: !state.isCollapseOpen,
      };
    });
  };

  toggleIgnore = (operation) => {
    this.setState((state) => {
      return {
        isIgnoreModalOpen: !state.isIgnoreModalOpen,
        operation,
      };
    });
  };

  toggleActions = (event) => {
    const key =
      event.target.getAttribute('data-value') ||
      event.target.parentNode.getAttribute('data-value');
    this.setState({ [key]: !this.state[key] });
  };

  getSearchParam(param , value){
    return `${param}&search=${value?.toLowerCase()?.trim()}`;
  }

  searchReceivedHandler = (e) => {
    this.setState({ searchReceived: e.target.value, isSearchChanged: true});
  };

  // Add query param for my given , received feedback
  getQueryParam(option, status) {
    let param = '';

    if( option !== 'search') { // Search Field
      const searchLength = this.state[`search${status}`];
      if(searchLength && searchLength?.length > 2) {
        let searchParam = this.state[`search${status}`];
        param = this.getSearchParam(param, searchParam);
      }
    }

    if( option !== 'sort') {
      if(this.state[`filtered${status}Option`]) {
        const value = filterOptions.filter(res => res.label === this.state[`filtered${status}Option`]);
        param = `${param}&sort=${value[0]?.value}`;
      }
    }

    return param;
  }

  // Add query param for my request feedback
  getRequestedQueryParam(option, status, value = null) {
    let param = '';

    if( option !== 'search') {
      if(this.state[`search${status}`] > 2) {
        let searchParam = this.state[`search${status}`];
        param = this.getSearchParam(param, searchParam);
      }
    }

    if(option === 'status') {
      param =
      value !== 'all_status' ? `${param}&feedback_status=${value}` :
        `${param}&feedback_status=completed,canceled,matching,pending`;
    }

    if(option !== 'status') {
      param =
      this.state.statusRequested !== 'all_status' ? `${param}&feedback_status=${this.state.statusRequested}` :
        `${param}&feedback_status=completed,canceled,matching,pending`;
    }

    if(option !== 'resource') {
      param = this.state.resourceRequested !== 'all' ?
        `${param}&resource_type=${this.state.resourceRequested}` : `${param}&resource_type=all`;
    }

    if(option === 'resource') {
      param = value !== 'all' ?
        `${param}&resource_type=${value}` : `${param}&resource_type=all`;
    }

    if(option !== 'date' && this.state.startDate && this.state.endDate) { // to add date param in url
      param = `${param}&from_date=${moment(this.state.startDate).format(
        SETTINGS_DATE_FORMAT
      )}&to_date=${moment(this.state.endDate).format(
        SETTINGS_DATE_FORMAT
      )}`;
    }

    return param;
  }

  searchGivenHandler = (e) => {
    this.setState({ searchGiven: e.target.value, isSearchChanged: true });
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if(event?.target?.id === 'requested-search') {
        this.setState({requestedAction: 'search'}, () => this.submitRequestedSearch());
        return;
      }
      return this.submitSearch(event);
    }
  }

  submitSearch = async (event) => {
    const clickedInput = event.target.id;
    const { actions, currentUser } = this.props;

    if(!this.state.isSearchChanged){
      return;
    }

    this.setState({isSearchChanged: false, isRequesting: true});

    const searchFor = clickedInput === 'given-search' ? 'Given' : 'Received';
    const searchValue = clickedInput === 'given-search' ? this.state.searchGiven : this.state.searchReceived;
    let param = '';

    param = this.getQueryParam('search', searchFor);
    if(searchValue?.length > 2) {
      param = this.getSearchParam(param, searchValue);
    }

    if(searchValue?.length > 2 || !searchValue) {
      if(clickedInput === 'given-search') {
        await actions.clearMyFeedback('completedFeedback');
        await actions.getGivenCompletedFeedbacks(
          currentUser.id,
          null,
          param
        );
      } else if(clickedInput === 'received-search'){
        await actions.clearMyFeedback('received');
        await actions.getReceivedFeedbacks(
          currentUser.id,
          null,
          param
        );
      }
    }
    this.setState({isRequesting: false});
  }

  // make following function a class component
  getFeedbackGiven = (completedFeedback) => {
    return completedFeedback.map(feedback => (
      <FeedbackGiven key={feedback.feedbackRequestId} feedback={feedback} />
    ));
  };

  // make following function a class component
  getFeedbackReceived = (
    feedbackReceived,
    praiseOptions,
    submitFeedbackPraise
  ) => {
    return feedbackReceived.map(feedback => (
      <FeedbackReceived
        key={feedback.feedbackRequestId}
        feedback={feedback}
        praiseOptions={praiseOptions}
        submitFeedbackPraise={submitFeedbackPraise}/>
    ));
  };

  resendFeedbackRequest = (requestID) => {
    this.props.actions.resendFeedbackRequest(requestID).then(() => {
      this.getRequestedFeedbacks();
      toastr.success(FEEDBACK_RESEND_SUCCESS);
    });
  };

  removeCancelledFeedbackRequest = (requestID) => {
    this.props.actions.removeCancelledFeedbackRequest(requestID).then(() => {
      this.getRequestedFeedbacks();
      toastr.success(FEEDBACK_REMOVED_SUCCESS);
    });
  };

  getRequestedFeedbacks(){
    this.props.actions.getRequestedFeedbacks(this.props.currentUser.id, null);
  }

  handleRequestedFilter = (e) => {
    const action = e.target.getAttribute('data-action');
    const label = e.target.getAttribute('data-label') || null;
    const allSkillsFilter = ['all' ,'skill', 'badge'];
    let param = '';
    if ( this.state.prevSortOption === false || this.state.prevSortOption != label || label === null) {
      this.setState({prevSortOption: label});
      if (action === 'status') {
        param = this.getRequestedQueryParam('status', 'Request', e.target.value);

        this.setState({ status: label, statusRequested: e.target.value, isSearchChanged: false});
      }else if (allSkillsFilter.includes(action)) {
        param = this.getRequestedQueryParam('resource', 'Request', action);

        this.setState({ allSkills: label, resourceRequested: action, isSearchChanged: false });

      } else if (action === 'search') {
        param = this.getRequestedQueryParam('search', 'Request');

        if(e.target.value?.length > 2) {
          param = this.getSearchParam(param, e.target.value);
        }

        this.setState({ searchRequested: e.target.value, isSearchChanged: true });
      }

      this.setState({searchRequestedParam: param, requestedAction: action}, () => {
        if(this.state.requestedAction !== 'search' && (!isEmpty(this.state.searchRequestedParam)) || this.state.dateReset) {
          this.submitRequestedSearch();
          this.setState({ dateReset: false});
        }
      });

      let arr = ['isAllOpen', 'isOpen'];

      this.setState(() => {
        const d = { };
        arr.forEach((a) => {
          d[a] = false;
        });
        return d;
      });
    }
  };

  submitRequestedSearch = async () => {
    const { currentUser, actions } = this.props;
    const {searchRequestedParam: param, requestedAction: action, searchRequested } = this.state;

    if(action === 'search' && !this.state.isSearchChanged){
      return;
    }
    this.setState({isSearchChanged: false, isRequesting: true});

    actions.clearMyFeedback('requested');
    if(action !== 'search' || (action === 'search' && (searchRequested?.length > 2 || !searchRequested))){
      await actions.getRequestedFeedbacks(currentUser.id, null, param);
    }
    this.setState({isRequesting: false});
  }

  handleDatePicker = (event) => {
    if (!this.state.startDate) {
      toastr.error('', START_DATE_VALIDATION);
      return false;
    }

    if (moment(event).isBefore(this.state.startDate)) {
      toastr.error('', DATE_RANGE_VALIDATION);
      return false;
    }

    if (this.state.startDate && event) {
      if (
        moment(event).isSame(this.state.startDate) ||
        moment(event).isAfter(this.state.startDate)
      ) {
        const { currentUser, actions } = this.props;

        let param = '';

        param = param.concat(this.getRequestedQueryParam('date'));

        param = `${param}&from_date=${moment(this.state.startDate).format(
          SETTINGS_DATE_FORMAT
        )}&to_date=${moment(event).format(
          SETTINGS_DATE_FORMAT
        )}`;

        actions.clearMyFeedback('requested');
        actions.getRequestedFeedbacks(currentUser.id, null, param);

        this.setState({ endDate: event });
      }
    }
  };

  // Feedback Requests-- Table Sort
  onSortTable(event) {
    const column = event.currentTarget.dataset['columnName'];
    const asc = (! this.state.sort[column]);
    const { currentUser, actions } = this.props;

    actions.clearMyFeedback('pendingFeedback');

    this.setState((state) => {
      let d = { [column]: asc };

      Object.entries(state.sort || {[column] : asc }).forEach(([key]) =>{
        if( key !== column ){
          d[key] = false;
        }
      });

      return { sort : d } ;
    });

    let urlParamString;

    if (column === 'sortGiven') {
      const sortParam = asc ? 'newrequest' : 'oldrequest';

      urlParamString = `&sort=${sortParam}`;
    }

    if (column === 'resourceTypeGiven') {
      const sortParam = asc ? 'a-z-resourcename' : 'z-a-resourcename';

      urlParamString = `&sort=${sortParam}`;
    }

    if (column === 'requestorGiven') {
      const sortParam = asc ? 'a-z-name' : 'z-a-name';

      urlParamString = `&sort=${sortParam}`;
    }

    actions.getGivenFeedbacks(currentUser.id, null, urlParamString);
  }

  // My Feedback Request -- Table Sort
  onMyRequestSortTable(event) {
    const column = event.currentTarget.dataset['columnName'];
    const asc = (! this.state.sort[column]);
    const { currentUser, actions } = this.props;

    actions.clearMyFeedback('requested');

    this.setState((state) => {
      let d = { [column]: asc };

      Object.entries(state.sort || {[column] : asc }).forEach(([key]) =>{
        if( key !== column ){
          d[key] = false;
        }
      });

      return { sort : {...d} } ;
    });

    let urlParamString = this.getRequestedQueryParam('', 'Request');

    if (column === 'sortRequest') {
      const sortParam = asc ? 'newrequest' : 'oldrequest';

      urlParamString = `${urlParamString}&sort=${sortParam}`;
    }

    if (column === 'resourceTypeRequest') {
      const sortParam = asc ? 'a-z-resourcename' : 'z-a-resourcename';

      urlParamString = `${urlParamString}&sort=${sortParam}`;
    }

    if (column === 'reviewerRequest') {
      const sortParam = asc ? 'a-z-name' : 'z-a-name';

      urlParamString = `${urlParamString}&sort=${sortParam}`;
    }

    if (column === 'resentdRequest') {
      const sortParam = asc ? 'resend-asc' : 'resend-desc';

      urlParamString = `${urlParamString}&sort=${sortParam}`;
    }

    if (column === 'statusRequest') {
      const sortParam = asc ? 'status-asc' : 'status-desc';

      urlParamString = `${urlParamString}&sort=${sortParam}`;
    }

    actions.getRequestedFeedbacks(currentUser.id, null, urlParamString);
  }

  showMoreFeedback = (event) => {
    const action = event.target.getAttribute('data-value');
    const {
      requestLink,
      receivedFeedbackLink,
      completedFeedbackLink,
      pendingFeedbackLink,
    } = this.props;
    if (
      action === 'requested' &&
      requestLink &&
      requestLink.self?.href !== requestLink.last?.href
    ) {
      this.props.actions.getRequestedFeedbacks(
        this.props.currentUser.id,
        requestLink?.next?.href
      );
    }

    if (
      action === 'received' &&
      receivedFeedbackLink &&
      receivedFeedbackLink.self?.href !== receivedFeedbackLink.last?.href
    ) {
      this.props.actions.getReceivedFeedbacks(
        this.props.currentUser.id,
        receivedFeedbackLink?.next?.href
      );
    }

    if (
      action === 'completed' &&
      completedFeedbackLink &&
      completedFeedbackLink.self?.href !== completedFeedbackLink.last?.href
    ) {
      this.props.actions.getGivenCompletedFeedbacks(
        this.props.currentUser.id,
        completedFeedbackLink?.next?.href
      );
    }

    if (
      action === 'pending' &&
      pendingFeedbackLink &&
      pendingFeedbackLink.self?.href !== pendingFeedbackLink.last?.href
    ) {
      this.props.actions.getGivenFeedbacks(
        this.props.currentUser.id,
        pendingFeedbackLink?.next?.href
      );
    }
  };

  handleFilterOptions = (event) => {
    const value = event.target.getAttribute('value');
    const label = event.target.getAttribute('label');
    const name = event.target.getAttribute('data-name');
    const { actions, currentUser } = this.props;

    let param = '';
    if ( this.state.prevSortOption === false || this.state.prevSortOption != label) {
      this.setState({prevSortOption: label});
      const status = name === 'filteredGivenOption' ? 'Given' : 'Received';
      param = this.getQueryParam('sort', status );

      if(value) {
        param = `${param}&sort=${value}`;
      }

      const statusArrayKey = name === 'filteredGivenOption' ? 'completedFeedback' : 'received';

      const actionMethod = name === 'filteredGivenOption' ? 'getGivenCompletedFeedbacks' : 'getReceivedFeedbacks';

      actions.clearMyFeedback(statusArrayKey);
      actions[actionMethod](
        currentUser.id,
        null,
        param
      );

      const option = name === 'filteredGivenOption' ? 'givenOption' : 'receivedOption';

      this.setState({ [name]: label, [option]: !this.state[option] });
    }
  };

  getMyRequests = (filteredMyRequests) => {
    return (
      <FeedbackTableComponent
        tblData={filteredMyRequests}
        status="requested"
        paging={false}
        search={false}
        defaultCSS={false}
        toggleIgnore={this.toggleIgnore}
        onSortTable={this.onMyRequestSortTable}
        setCurrentRequest={this.setCurrentRequest}
        resendFeedbackRequest={this.resendFeedbackRequest}/>
    );
  };

  setRequest = (event) => {
    const requestAction = event.target.getAttribute('data-value');
    const requestId = event.target.getAttribute('data-requestID');

    this.setCurrentRequest(requestId);
    this.toggleIgnore(requestAction);
  };

  setStartDateValue = (event) => {
    if(isNull(event) && !isNull(this.state.endDate)){
      this.setState({ dateReset: true});
    }
    this.setState({ startDate: event, endDate: null });
  };

  toggleRankPopover = () => {
    this.setState((state) => {
      return {
        isRankPopoverOpen: !state.isRankPopoverOpen,
      };
    });
  };

  getTableContent = () => {
    return (
      <tbody>
        {this.props.pendingFeedback.map((r) => {
          return (
            <tr key={r.feedbackRequestId}>
              <td scope="row">
                {`${moment(
                  r?.feedbackRequestDate?.date,
                  SETTINGS_DATE_FORMAT
                ).format(TABLE_DATE_FORMAT)}`}
                {r.feedbackRequestDate && (
                  <SimpleToolTipWrapper
                    id={'my-feedback-reminder-info'}
                    toolTipContent={
                      <div className="inside-tooltip">
                        {`You were sent a reminder on ${`${moment(
                          r.feedbackRequestDate.date,
                          SETTINGS_DATE_FORMAT
                        ).format(TABLE_DATE_FORMAT)}`}`}
                      </div>
                    }>
                    <i
                      className={r?.resendOn && 'fa fa-info-circle infoIcon'}/>
                  </SimpleToolTipWrapper>
                )}
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <Link
                    to={
                      r.resourceType == 'badge'
                        ? `/skill-badges/badge-details/${r?.resourceId}`
                        : `/skill-evidence/${r?.resourceId}`
                    }>
                    <img
                      className="receivedRequestImage"
                      src={get(
                        r,
                        'resourceUrl',
                        require(r.resourceType === 'badge'
                          ? '../../assets/images/badge_fake.png'
                          : '../../assets/images/temp/skill.png')
                      )}/>
                    <span>{r.resourcename}</span>
                  </Link>
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <Link to={`/cp/${r.vanity}`} className="user-name">
                    <img
                      src={
                        r.avatarURl
                          ? r.avatarURl
                          : require('../../assets/images/avatar.jpg')
                      }
                      className="requestorAvatar"/>
                    <span>{r.fullname}</span>
                  </Link>
                </div>
              </td>
              <td>
                <Link
                  className="respondLink"
                  to={`/givefeedback/${r?.feedbackRequestId}?revise`}
                  state={{
                    name: r?.fullname,
                  }}>
                  <Button className="sendButton respondButton mr-3">
                    {RESPOND}
                  </Button>
                </Link>

                <button
                  onClick={this.setRequest}
                  data-value="Ignore"
                  data-requestID={r.feedbackRequestId}
                  type="button"
                  className="btn btn-secondary">
                  {IGNORE}
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  submitFeedbackPraise = (
    feedbackRequestId,
    praiseDetails,
    togglePraise,
    giverName
  ) => {
    const jsonPraiseDetails = JSON.stringify(praiseDetails);
    const data = {
      feedbackRequestId: feedbackRequestId,
      praiseDetails: jsonPraiseDetails,
    };

    const {
      filteredFeedbackReceived,
    } = this.state;

    const {
      feedbackReceived,
      feedbackPraiseOptions,
      actions,
      currentUser,
    } = this.props;

    actions.submitFeedbackPraiseRequest(data).then(() => {
      actions.getReceivedFeedbacks(currentUser.id, null).then(() => {
        toastr.success(`Success! Your praise was given to ${giverName}`);

        this.getFeedbackReceived(
          filteredFeedbackReceived || feedbackReceived,
          feedbackPraiseOptions,
          this.submitFeedbackPraise
        );

        togglePraise();
      });
    });
  };

  render() {
    const {
      feedbackReceived,
      completedFeedback,
      pendingFeedback,
      feedbackRequested,
      requestLink,
      pendingFeedbackLink,
      completedFeedbackLink,
      receivedFeedbackLink,
      profile,
      feedbackPraises,
    } = this.props;

    const {
      isOpen,
      filteredRequested,
      filteredCompletedFeedback,
      filteredFeedbackReceived,
    } = this.state;

    const params = new URLSearchParams(this.props?.location?.search);
    const name = params?.get('username');

    return (
      <>
        <div className="container py-4">
          <div className="mySkillsContainer myFeedbackContainerStyle">
            <div className="row header-profile">
              <div className="col-md-8">
                <div className="badge-header media mb-2">
                  <div className="media-left media-middle">
                    <Link
                      to={`/cp/${profile.vanity}`}
                      title={`Link to ${profile.name}’s Career Site`}>
                      {
                        profile.avatar && profile.avatar !== '' ?
                          <img
                            className="media-object header-image large"
                            src={profile.avatar}
                            alt={`${profile.name}'s Avatar`}/>:
                          <span
                            style={{
                              lineHeight: '60px',
                              fontSize: 32
                            }}
                            className="media-object header-image large initial-avatar">
                            {profile?.name?.slice(0,1)}
                          </span>
                      }
                    </Link>
                  </div>
                  <div className="media-body">
                    <div className="float-left">
                      <h4 className="media-title mb-0 ml-2 mt-3">{`${name ? `${name}'s` : 'My'} Feedback`}</h4>
                    </div>
                  </div>
                </div>
              </div>
              {/** this.state.currentTab === 'given' &&
              profile.role === 'industry-representative' && (
                <IRRank
                  toggleRankPopover={this.toggleRankPopover}
                  isRankPopoverOpen={this.state.isRankPopoverOpen}
                  irRankDetails={this.props.irRank}/>
              ) */}
            </div>
            <Row>
              <Col>
                <TopPraises topPraises={feedbackPraises} />
              </Col>
            </Row>
            <hr className="mb-0" />
            <Row>
              <Col md="12">
                <div
                  onClick={this.setCurrentTab}
                  data-value="given"
                  className={`pointer customBadge ${
                    this.state.currentTab === 'given' ? 'selected' : ''
                  }`}>
                  {`${GIVEN} (${this.props.totalCompletedCount ? this.props.totalCompletedCount : 0})`}
                </div>
                <div
                  onClick={this.setCurrentTab}
                  data-value="received"
                  className={`pointer customBadge ${
                    this.state.currentTab === 'received' ? 'selected' : ''
                  }`}>
                  {`${RECEIVED} (${this.props.totalReceivedCount ? this.props.totalReceivedCount : 0})`}
                </div>
                <div
                  onClick={this.setCurrentTab}
                  data-value="myRequests"
                  className={`pointer customBadge ${
                    this.state.currentTab === 'myRequests' ? 'selected' : ''
                  }`}>
                  {`${MY_REQUESTS} (${this.props.totalRequestedCount ? this.props.totalRequestedCount : 0})`}
                </div>
              </Col>
            </Row>
            {/* GIVEN TAB CONTENT STARTS HERE*/}
            {this.state.currentTab === 'given' && (
              <div>
                <p className="mb-4">{MANAGE_FEEDBACK_HEADER}</p>
                <Row>
                  <Col md="12">
                    <div className="feedback__requests-container mb-3">
                      <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                        <p className="font-weight-bold font18 mb-0">
                          {`${FEEDBACK_REQUESTS} (${this.props.totalPendingCount ? this.props.totalPendingCount : 0})`}
                        </p>
                        {pendingFeedback.length === 0 && <div className="font14 font-italic">{NO_GIVEN_RECORDS}</div>}
                        {!this.state.isCollapseOpen && (
                          <i
                            onClick={this.toggleCollapse}
                            className="fa fa-chevron-down pointer"/>
                        )}
                        {this.state.isCollapseOpen && (
                          <i
                            onClick={this.toggleCollapse}
                            className="fa fa-chevron-up pointer"/>
                        )}
                      </div>
                      <Collapse isOpen={this.state.isCollapseOpen} id="myfeedback">
                        {!isEmpty(pendingFeedback) ?
                          <div>
                            <FeedbackTableComponent
                              tblData={pendingFeedback}
                              status="pending"
                              paging={false}
                              search={false}
                              defaultCSS={false}
                              toggleIgnore={this.toggleIgnore}
                              onSortTable={this.onSortTable}
                              setCurrentRequest={this.setCurrentRequest}
                              sortByDate={this.handleSortByDate}/>
                            <div className="text-center themeLinkWithoutUnderline">
                              {pendingFeedbackLink &&
                            pendingFeedbackLink.self?.href !==
                              pendingFeedbackLink.last?.href ? (
                                  pendingFeedback?.length === 0 ? (
                                    <span className="pointer"> {NO_GIVEN_RECORDS} </span>
                                  ) : (
                                    <span
                                      onClick={this.showMoreFeedback}
                                      data-value="received"
                                      className="pointer orange">
                                      {SHOW_MORE}
                                    </span>
                                  )
                                ) : null}
                            </div>
                          </div>
                          : this.state.isRequesting ? <Loading /> :null}
                      </Collapse>
                    </div>
                  </Col>
                </Row>
                <p className="font-weight-bold font18">{FEEDBACK_GIVEN}</p>
                <div className="d-flex align-items-center myskillAdvSearch mb-3">
                  <Row className="w-100">
                    <Col md="3">
                      <FormGroup>
                        <InputGroup>
                          <Input
                            type="text"
                            name="search"
                            id="given-search"
                            placeholder="Search"
                            value={this.state.searchGiven}
                            onChange={this.searchGivenHandler}
                            onKeyPress={this.handleKeyPress}/>
                          <InputGroupAddon id="given-search" className="pointer" addonType="append" onClick={this.submitSearch}>
                            <i id="given-search" className="fa fa-search" />
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <ButtonDropdown
                          isOpen={this.state.givenOption}
                          data-value="givenOption"
                          toggle={this.toggleActions}>
                          <DropdownToggle caret>
                            {this.state.filteredGivenOption}
                          </DropdownToggle>
                          <DropdownMenu>
                            {filterOptions.map((data, i) => (
                              <DropdownItem
                                key={i}
                                label={data.label}
                                value={data.value}
                                data-name="filteredGivenOption"
                                data-array="completedFeedback"
                                data-filter="filteredCompletedFeedback"
                                onClick={this.handleFilterOptions}>
                                {data.label}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </ButtonDropdown>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                {!isEmpty(completedFeedback) || !isEmpty(filteredCompletedFeedback) ?
                  <div>
                    {this.getFeedbackGiven(
                      filteredCompletedFeedback
                        ? filteredCompletedFeedback
                        : completedFeedback
                    )}
                    <div className="text-center themeLinkWithoutUnderline p-1">
                      {completedFeedbackLink &&
                    completedFeedbackLink.self?.href !==
                      completedFeedbackLink.last?.href ? (
                          filteredCompletedFeedback?.length === 0 ? (
                            <span className="pointer">{NO_RECORDS}</span>
                          ) : (
                            <span
                              onClick={this.showMoreFeedback}
                              data-value="completed"
                              className="pointer orange">
                              {SHOW_MORE}
                            </span>
                          )
                        ) : null}
                    </div>
                  </div>
                  : null }
                {this.state.isRequesting ? <Loading /> :
                  completedFeedback.length === 0 && isEmpty(this.state.searchGiven) && <div className="text-center my-5 no-feedback-given"><div><i className="fas fa-info-circle" aria-hidden="true" /></div><p>Community members you follow, or who follow you, can request your feedback on their skill evidence to help them understand which skills they prove well and which need stronger evidence. Feedback you give others will show here as well as the praises your feedback earns you.</p></div>}
                {completedFeedback.length === 0 && !this.state.isRequesting && !isEmpty(this.state.searchGiven) && <div className="text-center themeLinkWithoutUnderline p-1 text-dark">Sorry, we couldn't find anything!</div>}
              </div>
            )}
            {/* GIVEN TAB CONTENT ENDS HERE*/}
            {/* RECEIVED TAB CONTENT STARTS HERE*/}
            {this.state.currentTab === 'received' && (
              <div>
                <p className="mb-4">{VIEW_FEEDBACK_HEADER}</p>
                <p className="font-weight-bold font18 mb-2">{FEEDBACK_RECEIVED}</p>
                <div className="d-flex align-items-center myskillAdvSearch border-bottom mb-3">
                  <Row className="w-100">
                    <Col md="3">
                      <FormGroup>
                        <InputGroup>
                          <Input
                            type="text"
                            name="search"
                            id="received-search"
                            placeholder="Search"
                            value={this.state.searchReceived}
                            onChange={this.searchReceivedHandler}
                            onKeyPress={this.handleKeyPress}/>
                          <InputGroupAddon id="received-search" className="pointer" addonType="append" onClick={this.submitSearch}>
                            <i id="received-search" className="fa fa-search" />
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <ButtonDropdown
                          isOpen={this.state.receivedOption}
                          data-value="receivedOption"
                          toggle={this.toggleActions}>
                          <DropdownToggle caret>
                            {this.state.filteredReceivedOption}
                          </DropdownToggle>
                          <DropdownMenu>
                            {filterOptions.map((data, index) => (
                              <DropdownItem
                                key={index}
                                name="option"
                                label={data.label}
                                value={data.value}
                                data-name="filteredReceivedOption"
                                data-array="feedbackReceived"
                                data-filter="filteredFeedbackReceived"
                                onClick={this.handleFilterOptions}>
                                {data.label}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </ButtonDropdown>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                {this.getFeedbackReceived(
                  filteredFeedbackReceived
                    ? filteredFeedbackReceived
                    : feedbackReceived,
                  this.props.feedbackPraiseOptions,
                  this.submitFeedbackPraise
                )}
                <div className="text-center themeLinkWithoutUnderline p-1">
                  {receivedFeedbackLink &&
                receivedFeedbackLink.self?.href !==
                  receivedFeedbackLink.last?.href ? (
                      filteredFeedbackReceived?.length === 0 && !this.state.isRequesting ? (
                        <span className="text-dark">{NO_RECORDS}</span>
                      ) : (
                        !this.state.isRequesting && <span
                          onClick={this.showMoreFeedback}
                          data-value="received"
                          className="pointer orange">
                          {SHOW_MORE}
                        </span>
                      )
                    ) : feedbackReceived.length === 0 && !this.state.isRequesting && isEmpty(this.state.searchReceived) ? (
                      <div className="text-center my-5 no-feedback-given"><div><i className="fas fa-info-circle" aria-hidden="true" /></div><p>Feedback received from community members responding to your requests will show here and you'll be able to praise their feedback. You haven't received any feedback yet.</p></div>
                    ) : feedbackReceived.length === 0 && !this.state.isRequesting ? <span className="text-dark">{NO_RECORDS}</span> : ''}
                  {this.state.isRequesting && <span className="text-dark"><Loading /></span>}
                </div>
              </div>
            )}
            {/* RECEIVED TAB CONTENT ENDS HERE*/}
            {/* MY REQUEST TAB CONTENT STARTS HERE*/}
            {this.state.currentTab === 'myRequests' && (
              <div>
                <p className="mb-4">{VIEW_REQUESTS_HEADER}</p>
                <Row className="myskillAdvSearch">
                  <Col md="12" lg="9" className="pr-md-4 pr-lg-4">
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <InputGroup>
                            <Input
                              type="text"
                              name="search"
                              id="requested-search"
                              placeholder="Search"
                              data-action="search"
                              value={this.state.searchRequested}
                              onChange={this.handleRequestedFilter}
                              onKeyPress={this.handleKeyPress}/>
                            <InputGroupAddon
                              id="requested-search"
                              className="pointer"
                              addonType="append"
                              onClick={this.submitRequestedSearch}>
                              <i id="requested-search" className="fa fa-search" />
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="4" className="pl-md-0 pl-lg-0">
                        <FormGroup>
                          <ButtonDropdown
                            isOpen={isOpen}
                            data-value="isOpen"
                            toggle={this.toggleActions}>
                            <DropdownToggle caret>
                              {this.state.status || 'status'}
                            </DropdownToggle>
                            <DropdownMenu>
                              {statusList.map((data, i) => (
                                <DropdownItem
                                  name="status"
                                  key={i}
                                  label={data.label}
                                  value={data.value}
                                  data-action="status"
                                  data-label={data.label}
                                  onClick={this.handleRequestedFilter}>
                                  {data.label}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </ButtonDropdown>
                        </FormGroup>
                      </Col>
                      <Col md="4" className="pr-md-2">
                        <FormGroup>
                          <ButtonDropdown
                            isOpen={this.state.isAllOpen}
                            data-value="isAllOpen"
                            toggle={this.toggleActions}>
                            <DropdownToggle caret>
                              {this.state.allSkills
                                ? this.state.allSkills
                                : 'All'}
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                name="allSkills"
                                data-action="all"
                                data-label="All"
                                onClick={this.handleRequestedFilter}>
                                {ALL}
                              </DropdownItem>
                              <DropdownItem
                                name="allSkills"
                                data-action="skill"
                                data-label="Other Skills"
                                onClick={this.handleRequestedFilter}>
                                Other Skills
                              </DropdownItem>
                              <DropdownItem
                                name="allSkills"
                                data-action="badge"
                                data-label="Skill Badges"
                                onClick={this.handleRequestedFilter}>
                                Skill Badges
                              </DropdownItem>
                            </DropdownMenu>
                          </ButtonDropdown>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="12" lg="3">
                    <Row>
                      <Col md="6" className="pl-lg-0 col-12">
                        <FormGroup>
                          <DatePicker
                            selected={
                              this.state.startDate &&
                            new Date(this.state.startDate)
                            }
                            dateFormat={FEEDBACK_DATE_FORMAT}
                            onChange={this.setStartDateValue}
                            className="form-control dateIcon"
                            onBlur={this.handleRequestedFilter}
                            placeholderText={FROM}/>
                        </FormGroup>
                      </Col>
                      <Col md="6" className="pl-md-0 pl-lg-0 col-12">
                        <DatePicker
                          selected={
                            this.state.endDate && new Date(this.state.endDate)
                          }
                          dateFormat={FEEDBACK_DATE_FORMAT}
                          onChange={this.handleDatePicker}
                          className="form-control dateIcon"
                          placeholderText={TO}/>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <hr className="mt-0 mb-0" />
                <div id="myfeedback">
                  {
                    this.getMyRequests(
                      filteredRequested ? filteredRequested : feedbackRequested
                    )}
                  <div className="text-center themeLinkWithoutUnderline p-1">
                    {requestLink &&
                  requestLink.self?.href !== requestLink.last?.href ? (
                        filteredRequested?.length === 0 && !this.state.isRequesting ? (
                          <span className="text-dark">{NO_RECORDS}</span>
                        ) : (
                          !this.state.isRequesting && <span
                            className="pointer orange"
                            onClick={this.showMoreFeedback}
                            style={{color: '#ff5000'}}
                            data-value="requested">
                            {SHOW_MORE}
                          </span>
                        )
                      ) : feedbackRequested.length === 0 && !this.state.isRequesting && isEmpty(this.state.searchRequested) ? (
                        <span className="text-dark">You currently have no requests for feedback</span>
                      ) : feedbackRequested.length === 0 && !this.state.isRequesting ? <span className="text-dark">{NO_RECORDS}</span> : ''}
                  </div>
                  {this.state.isRequesting && <Loading/>}
                </div>
              </div>
            )}

            <IgnoreFeedbackRequest
              requestFor={this.state.operation}
              isOpen={this.state.isIgnoreModalOpen}
              toggleModal={this.toggleIgnore}
              operationFeedback={this.operationFeedback}/>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { profile } = state;

  return {
    currentUser: state.auth.data.currentUser,
    completedFeedback: state.myfeedback.data.completedFeedback,
    pendingFeedback: state.myfeedback.data.pendingFeedback,
    feedbackReceived: state.myfeedback.data.received,
    feedbackRequested: state.myfeedback.data.requested,
    requestLink: state.myfeedback.data.requestLink,
    pendingFeedbackLink: state.myfeedback.data.pendingFeedbackLink,
    completedFeedbackLink: state.myfeedback.data.completedFeedbackLink,
    receivedFeedbackLink: state.myfeedback.data.receivedFeedbackLink,
    totalPendingCount: state.myfeedback.data.totalPendingCount,
    totalCompletedCount: state.myfeedback.data.totalCompletedCount,
    totalRequestedCount: state.myfeedback.data.totalRequestedCount,
    totalReceivedCount: state.myfeedback.data.totalReceivedCount,
    isAuthenticated: state.auth.isAuthenticated,
    profile: profile.data,
    feedbackPraiseOptions: state.feedbackPraise.data,
    irRank: state.myfeedback.data.irRank,
    feedbackPraises: state.myfeedback.data.feedbackPraises,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      resendFeedbackRequest,
      removeCancelledFeedbackRequest,
      ignoreFeedbackRequest,
      getGivenFeedbacks,
      getReceivedFeedbacks,
      getRequestedFeedbacks,
      getGivenCompletedFeedbacks,
      getFeedbackOptionsRequest,
      submitFeedbackPraiseRequest,
      getIRRank,
      getFeedbackTopPraises,
      clearMyFeedback,
    },
    dispatch
  ),
});

MyFeedback.propTypes = {
  feedbackReceived: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  feedbackRequested: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  requestLink: PropTypes.object,
  receivedFeedbackLink: PropTypes.object,
  completedFeedbackLink: PropTypes.object,
  pendingFeedbackLink: PropTypes.object,
  pendingFeedback: PropTypes.array,
  completedFeedback: PropTypes.array,
  totalCompletedCount: PropTypes.number,
  totalReceivedCount: PropTypes.number,
  totalRequestedCount: PropTypes.number,
  totalPendingCount: PropTypes.number,
  isAuthenticated: PropTypes.bool,
  profile: PropTypes.object,
  feedbackPraiseOptions: PropTypes.object.isRequired,
  irRank: PropTypes.object,
  feedbackPraises: PropTypes.array,
  params: PropTypes.object,
};

export default withHooks(
  connect(mapStateToProps, mapDispatchToProps)(MyFeedback)
);
