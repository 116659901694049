import React from 'react';

import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

import CloseIcon from '../../assets/images/close.svg';

const ConfirmSubmit = ({
  isOpen,
  toggleModal,
  saveTestimonial,
}) => {
  function handleToggleModal() {
    toggleModal();
  }

  return (
    <Modal
      centered
      className="customLevelUpModal"
      isOpen={isOpen}
      toggle={handleToggleModal}>
      <div className="modal-header">
        <span className="modal-title">Finish Revision</span>
        <div
          onClick={handleToggleModal}
          className="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">
            <img src={CloseIcon} />{' '}
          </span>
        </div>
      </div>

      <ModalBody>
        <p style={{ color: '#55595C' }}>
          Your revised testimonial will replace your previous testimonial. Are you sure you’re ready to finish your revision?
        </p>
      </ModalBody>
      <ModalFooter className="bordertop-0">
        <button
          type="button"
          onClick={handleToggleModal}
          className="btn btn-secondary">
          Cancel
        </button>
        <Button className="ml-1 sendButton" onClick={saveTestimonial}>
          Yes, I’m Finished
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ConfirmSubmit.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired, 
  saveTestimonial: PropTypes.func.isRequired,
};

export default ConfirmSubmit;
