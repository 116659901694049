import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link } from 'react-router-dom';
import _find from 'lodash/find';
import _isUndefined from 'lodash/isUndefined';

import Loading from '../../../components/common/Loading';

import * as skillsActions from '../../../redux/actions/skills';
import * as componentActions from '../../../redux/actions/components';

import ProfileCardHeader from '../../../components/profile/header/ProfileCardHeader';
import { get } from 'lodash';

const styles = {
  editButton: {
    marginLeft: 10,
    fontWeight: 'normal',
    cursor: 'pointer',
    fontSize: 12
  },
  linkedSkillName:{
    paddingRight: 17
  }
};

class WebpageSkills extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    skills: PropTypes.object.isRequired,
    skillsOrder: PropTypes.array,
    webpage: PropTypes.object,
    isAdminViewing: PropTypes.bool,
    currentUser: PropTypes.string,
    toggleComponents: PropTypes.array.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      showAllSkills: false
    };

    this.setSkillBeingUpdatedInStore = this.setSkillBeingUpdatedInStore.bind(this);
    this.toggleAllSkills = this.toggleAllSkills.bind(this);
    this.toggleShowHiddenSkills = this.toggleShowHiddenSkills.bind(this);
  }

  componentDidMount(){
    const { profile, actions } = this.props;
    actions.skillsRequest(profile.id, true);
  }

  toggleShowHiddenSkills() {
    this.props.actions.toggleComponent('show-hidden-skills');
  }

  setSkillBeingUpdatedInStore(skillId){
    return () => {
      const { skills, actions } = this.props;
      const skill = skills.data._find(skill => skill.id === skillId);
      actions.setSkillBeingUpdatedInStore(skill);
    };
  }

  toggleAllSkills(){
    this.setState({
      showAllSkills: !this.state.showAllSkills
    });
  }


  render() {
    const { showAllSkills } = this.state;
    const { skills, skillsOrder, currentUser, toggleComponents } = this.props;
    const { isRequesting, data } = skills;

    const showMoreCondition = skillsOrder.length > 5;
    const skillsArray = showMoreCondition && !showAllSkills ? skillsOrder.slice(0,5):
      skillsOrder;

    const showHiddenSkills = toggleComponents.includes('show-hidden-skills');

    if(data.length === 0){
      return null;
    }

    return (
      <div className="profile-item skill">
        <ProfileCardHeader
          type="list"
          title="Skills"
          isPublicProfile
          icon="skill"
          currentUser={currentUser}
          profileId={this.props.profile.id}
          toggleComponents={toggleComponents}
          toggleShowHiddenSkills={this.toggleShowHiddenSkills}/>
        {
          isRequesting &&
            <Loading/>
        }
        <div className="row">
          <div className="col-lg-12">
            {!isRequesting &&
              skillsArray.map((order, index) => {
                const skill = _find(data, item => item.id === order.id);
                if(_isUndefined(skill))
                  return null;
                const skillClass = (showHiddenSkills || get(skill, 'visibility', 1) === 1) ?
                  'skill-pill file-linked' : 'skill-pill';

                return (
                  <div
                    key={index}
                    className={'card-item mr-1 mb-2'}
                    style={{display:'inline-block'}}>

                    <div className={`${skillClass} ${(showHiddenSkills || get(skill, 'visibility', 1) === 1) ? 'visible-evidence' : ''}`}>
                      {
                        skill && (showHiddenSkills || get(skill, 'visibility', 1) === 1) ?
                          <Link
                            to= {`/skill-evidence/${skill.id}`}
                            onClick={this.setSkillBeingUpdatedInStore(skill.id)}>
                            <span style={styles.linkedSkillName}>{skill.name}</span>
                          </Link>
                          :
                          <span style={styles.skillName}>{skill.name}</span>
                      }
                    </div>

                  </div>);
              })}

            {
              !isRequesting && showMoreCondition &&
                <div
                  style={{borderRadius: 10, backgroundColor: '#6dabe4',display:'inline-block'}}
                  className="card-item m-l-5 skill-pill">
                  <a
                    onClick={this.toggleAllSkills}
                    className="clickable orange"
                    style={{color: '#fff'}}>
                    <span style={{ color: 'white' }}>{showAllSkills ? 'show less' : `+${skillsOrder.length - 5} more`}</span>
                  </a>
                </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    skillsOrder: ownProps.profile.skillsOrder.split(',').filter(id => id !== '').map(id => ({ id })),
    webpage: state.webpage.data,
    skills: state.skills,
    currentUser: state.auth.data.currentUser.id,
    toggleComponents: state.components.toggleComponents
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, skillsActions, componentActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WebpageSkills);
