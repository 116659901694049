import PropTypes from 'prop-types';
import React from 'react';
import { uniqueId } from '../../../utils';
import ClubCardHandler from '../../../containers/profile/sections/ClubCardHandler';

const ClubList = ({clubs, isPublicProfile, onEdit}) => {
  if(clubs.length === 0){
    return(
      <div className="empty-section-message">
        <p>List any involvement with student clubs, athletics, or competitions.</p>
      </div>
    );
  }
  return (
    <div>
      {clubs.map(club =>
        (<ClubCardHandler
          key={uniqueId()}
          club={club}
          onEdit={onEdit}
          isPublicProfile={isPublicProfile}/>))}
    </div>
  );
};

ClubList.propTypes = {
  clubs: PropTypes.array.isRequired,
  isPublicProfile: PropTypes.bool.isRequired,
  onEdit: PropTypes.func
};

export default ClubList;
