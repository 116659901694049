import { CALL_API } from '../../middlewares/api';

import {
  GET_EDUCATORS_REQUEST,
  GET_EDUCATORS_SUCCESS,
  GET_EDUCATORS_FAILURE,
  GET_INDUSTRY_REPS_REQUEST,
  GET_INDUSTRY_REPS_SUCCESS,
  GET_INDUSTRY_REPS_FAILURE,
  GET_PEERS_REQUEST,
  GET_PEERS_SUCCESS,
  GET_PEERS_FAILURE,
  GET_FOLLOWERS_REQUEST,
  GET_FOLLOWERS_SUCCESS,
  GET_FOLLOWERS_FAILURE,
  REQUEST_FEEDBACK_REQUEST,
  REQUEST_FEEDBACK_SUCCESS,
  REQUEST_FEEDBACK_FAILURE,
  GET_RATINGS_REQUEST,
  GET_RATINGS_SUCCESS,
  GET_RATINGS_FAILURE,
  GET_MYBADGES_REQUEST,
  GET_MYBADGES_SUCCESS,
  GET_MYBADGES_FAILURE,
  GET_MYSKILLS_REQUEST,
  GET_MYSKILLS_SUCCESS,
  GET_MYSKILLS_FAILURE,
  EDIT_BADGE_VISIBILITY_REQUEST,
  EDIT_BADGE_VISIBILITY_SUCCESS,
  EDIT_BADGE_VISIBILITY_FAILURE,
  EDIT_SKILL_VISIBILITY_REQUEST,
  EDIT_SKILL_VISIBILITY_SUCCESS,
  EDIT_SKILL_VISIBILITY_FAILURE,
  CLEAR_MYSKILLS_SUCCESS,
  CLEAR_MYBADGES_SUCCESS,
  GET_REVIEWS_BREAKUP_REQUEST,
  GET_REVIEWS_BREAKUP_SUCCESS,
  GET_REVIEWS_BREAKUP_FAILURE,
  GET_DEFAULT_SKILLS_REQUEST,
  GET_DEFAULT_SKILLS_SUCCESS,
  GET_DEFAULT_SKILLS_FAILURE,
  RESET_DEFAULT_SKILLS,
  GET_MYSKILLSPAGE_REQUEST,
  GET_MYSKILLSPAGE_SUCCESS,
  GET_MYSKILLSPAGE_FAILURE,
} from '../../constants';

export const getEducators = (endPoint=false) => {
  let endpoint = (endPoint) ? `${endPoint}&page_size=500` : 'institution/educators?page_size=500';

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        GET_EDUCATORS_REQUEST,
        GET_EDUCATORS_SUCCESS,
        GET_EDUCATORS_FAILURE,
      ],
    },
  };
};

export const getIndustryReps = () => {
  const endpoint = 'user/ir-count';

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        GET_INDUSTRY_REPS_REQUEST,
        GET_INDUSTRY_REPS_SUCCESS,
        GET_INDUSTRY_REPS_FAILURE,
      ],
    },
  };
};

export const getPeers = (userid) => {
  const endpoint = `user/connect?user=${userid}&peers=1`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [GET_PEERS_REQUEST, GET_PEERS_SUCCESS, GET_PEERS_FAILURE],
    },
  };
};

export const getFollowers = (userid) => {
  const endpoint = `user/connect?user=${userid}&inverse=1&peers=1`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        GET_FOLLOWERS_REQUEST,
        GET_FOLLOWERS_SUCCESS,
        GET_FOLLOWERS_FAILURE,
      ],
    },
  };
};

export const requestFeedback = (data) => {
  const endpoint = 'feedback/feedback_request';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        REQUEST_FEEDBACK_REQUEST,
        REQUEST_FEEDBACK_SUCCESS,
        REQUEST_FEEDBACK_FAILURE,
      ],
    },
  };
};

export const getMySkills = (userid, _links, filterData) => {
  let endpoint = _links
    ? _links.replace(/^http[s]?:\/\/.+?\//, '')
    : `user/skills?user=${userid}`;

  if(filterData){
    endpoint= endpoint.concat(filterData);
  }

  return {
    [CALL_API]: {
      endpoint,
      id: userid,
      method: 'get',
      authenticated: true,
      types: [GET_MYSKILLS_REQUEST, GET_MYSKILLS_SUCCESS, GET_MYSKILLS_FAILURE],

    },
  };
};

export const clearMySkills = () => {
  return dispatch => dispatch({type: CLEAR_MYSKILLS_SUCCESS});
};

export const getRatings = (claim_type, claim_id, userId = false) => {
  let endpoint = `user/star_rating?claim_type=${claim_type}&resource_id=${claim_id}`;
  if ( userId ) {
    endpoint = `${endpoint}&user_id=${userId}`;
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [GET_RATINGS_REQUEST, GET_RATINGS_SUCCESS, GET_RATINGS_FAILURE],
    },
  };
};

export const getMyBadges = (userid) => {
  const endpoint = `badge/issued?user=${userid}`;

  return {
    [CALL_API]: {
      endpoint,
      id: userid,
      method: 'get',
      authenticated: true,
      types: [GET_MYBADGES_REQUEST, GET_MYBADGES_SUCCESS, GET_MYBADGES_FAILURE],
    },
  };
};

export const clearMyBadges = () => {
  return dispatch => dispatch({type: CLEAR_MYBADGES_SUCCESS});
};

export const editBadgeVisibility = (data, badgeIssueID) => {
  const endpoint = `badge/issued/${badgeIssueID}`;

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'patch',
      authenticated: true,
      types: [
        EDIT_BADGE_VISIBILITY_REQUEST,
        EDIT_BADGE_VISIBILITY_SUCCESS,
        EDIT_BADGE_VISIBILITY_FAILURE,
      ],
    },
  };
};

export const editSkillVisibility = (data, skill_id) => {
  const endpoint = `user/skills/${skill_id}`;

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'patch',
      authenticated: true,
      types: [
        EDIT_SKILL_VISIBILITY_REQUEST,
        EDIT_SKILL_VISIBILITY_SUCCESS,
        EDIT_SKILL_VISIBILITY_FAILURE,
      ],
    },
  };
};

export const getReviewsBreakup =(resource_id) => {
  const endpoint = `relationships-rating-graph/${resource_id}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [
        GET_REVIEWS_BREAKUP_REQUEST,
        GET_REVIEWS_BREAKUP_SUCCESS,
        GET_REVIEWS_BREAKUP_FAILURE,
      ],
    },
  };
};

export const resetDefaultSkills = () => ({ type: RESET_DEFAULT_SKILLS });

export const getDefaultSkills = (query='', page = 1, categoryId = '', subCategoryId = '') => {
  let endpoint = `skill/suggested?page=${page}`;

  if (query.length > 0) {
    endpoint = `${endpoint}&query=${query}`;
  }

  if (categoryId.length > 0) {
    endpoint = `${endpoint}&categoryId=${categoryId}`;
  }

  if (subCategoryId.length > 0) {
    endpoint = `${endpoint}&subCategoryId=${subCategoryId}`;
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        GET_DEFAULT_SKILLS_REQUEST,
        GET_DEFAULT_SKILLS_SUCCESS,
        GET_DEFAULT_SKILLS_FAILURE
      ],
    },
  };
};

export const getMySkillsPage = (userid, _links, filterData, resourceType, page=1, isSearchQuery = false) => {
  let endpoint = _links
    ? _links.replace(/^http[s]?:\/\/.+?\//, '')
    : `my-skills?user=${userid}&page=${page}&resource_type=${resourceType}`;

  if(filterData){
    endpoint= endpoint.concat(filterData);
  }

  return {
    [CALL_API]: {
      endpoint,
      id: userid,
      method: 'get',
      authenticated: true,
      types: [GET_MYSKILLSPAGE_REQUEST, GET_MYSKILLSPAGE_SUCCESS, GET_MYSKILLSPAGE_FAILURE],
      requestData: {
        requestingPage: page,
        isSearch: isSearchQuery,
        resource: resourceType
      },
    },
  };
};
