import PropTypes from 'prop-types';
import React from 'react';
import TextInput from '../common/TextInput';

const EndorseForm = ({ 
  userData, 
  updateUserDataState, 
  onEndorse, 
  // requestor,
  checkedAnyBadges,
  errors,
  isSubmitting
}) => {
  return(
    <div>
      <h6>Please provide your name, position and organization</h6>
      <div className="form-group">
        <TextInput
          error={errors.name}
          name="name"
          placeholder="Your Name"
          onChange={updateUserDataState}
          value={userData.name}/>
      </div>
      <div className="form-group">
        <TextInput
          error={errors.position}
          name="position"
          placeholder="Your Position"
          onChange={updateUserDataState}
          value={userData.position}/>
      </div>
      <div className="form-group">
        <TextInput
          error={errors.organization}
          name="organization"
          placeholder="Your Organization"
          onChange={updateUserDataState}
          value={userData.organization}/>
      </div>

      {
        !checkedAnyBadges &&
          <div 
            style={{marginBottom: 10}}
            className="note">
            You must check the acknowledgement box for each Skill Badge you want to endorse before completing the endorsement process.
          </div>
      }

      <button 
        type="button" 
        className="btn btn-primary" 
        onClick={onEndorse}
        disabled={!checkedAnyBadges}
        name="button">
        {isSubmitting ?'Endorsing...': 'Endorse'}
      </button>
    </div>
  );
};

EndorseForm.propTypes = {
  userData: PropTypes.object.isRequired,
  onEndorse: PropTypes.func.isRequired,
  requestor: PropTypes.object,
  checkedAnyBadges: PropTypes.bool,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool,
  updateUserDataState: PropTypes.func.isRequired
};

export default EndorseForm;
