import PropTypes from 'prop-types';
import React from 'react';
import Parser from 'html-react-parser';

const styles = {
  userNameEditIcon: {
    cursor: 'pointer',
    marginLeft: 10,
  },

  editActions: {
    position: 'relative',
  }
};

const ClubSectionCard = ({
  clubsDetails,
  onEditClub,
  onRemoveClub
}) => {
  return (
    <div className="resume-section section-item">
      <span style={styles.editActions} className="float-right">
        <i
          onClick={onEditClub}
          id={clubsDetails.id}
          style={styles.userNameEditIcon}
          className="fa fa-pencil"/>

        <i
          onClick={onRemoveClub}
          id={clubsDetails.id}
          style={styles.userNameEditIcon}
          className="fa fa-close"/>
      </span>
      <span>{clubsDetails.name}</span>
      {Parser(clubsDetails.description)}
    </div>
  );
};

ClubSectionCard.propTypes = {
  clubsDetails: PropTypes.object.isRequired,
  onEditClub: PropTypes.func.isRequired,
  onRemoveClub: PropTypes.func.isRequired
};

export default ClubSectionCard;
