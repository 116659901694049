import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { isNull, isEmpty, isUndefined } from 'lodash';

import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Modal,
  Label,
  ModalBody,
  ModalFooter,
  FormGroup,
  Button,
} from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import { AsyncPaginate } from 'react-select-async-paginate';

import { getCountries } from '../../redux/actions/countries';
import { getStates } from '../../redux/actions/states';
import { getCities } from '../../redux/actions/cities';

import { getCareerClusters } from '../../redux/actions/clusters';
import { getCareerIndustries } from '../../redux/actions/industries';
import { getCareerOccupations } from '../../redux/actions/occupations';

import { updateProfile } from '../../redux/actions/profile';

import {
  selectFormattedCountries,
  selectCountriesPageCount
} from '../../redux/selectors/countries';
import {
  selectFormattedStates,
  selectStatesPageCount
} from '../../redux/selectors/states';
import {
  selectFormattedCities,
  selectCitiesPageCount
} from '../../redux/selectors/cities';

import {
  selectFormattedClusters,
  selectClustersPageCount
} from '../../redux/selectors/clusters';
import {
  selectFormattedIndustries,
  selectIndustriesPageCount
} from '../../redux/selectors/industries';
import {
  selectFormattedOccupations,
  selectOccupationsPageCount
} from '../../redux/selectors/occupations';

import validator from '../../validator';
import { validateCluster } from '../../validator/clusterValidator';
import Interests from './skillCardComponents/Interests';
import CloseIcon from '../../assets/images/close.svg';

class UpdateDetails extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      countries: [],
      states: [],
      cities: [],
      country: null,
      stateProvince: null,
      city: null,
      countryId: '',
      stateProvinceId: '',
      clusterId: '',
      industryId: '',
      inputList: [{}],
      errors: [],
      headerText: '',
      clusterErrors: [],
      clusterDetails: [],
      isDropdownOpen: false,
      isForCareerInterest: false,
      consolidatedClusterError: '',
      consolidatedClusterErrorDetails: []
    };

    this.loadOptions = this.loadOptions.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleRemoveAll = this.handleRemoveAll.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.toggleDetailsModal = this.toggleDetailsModal.bind(this);
    this.submitUpdateDetails = this.submitUpdateDetails.bind(this);
    this.loadLocationOptions = this.loadLocationOptions.bind(this);
    this.onChangeCareerClusters = this.onChangeCareerClusters.bind(this);
  }

  // handle click event of the Remove button
  handleRemoveClick(e) {
    const { inputList } = this.state;
    const index = e.target.getAttribute('data-value');

    const list = [...inputList];
    if (inputList.length > 1) {
      list.splice(index, 1);
      this.setState({ inputList: list });
    } else {
      this.setState({ inputList: [{}] });
    }
  }

  handleRemoveAll() {
    this.setState({ inputList: [{}] });
  }

  handleAddClick() {
    this.setState({ inputList: [ ...this.state.inputList, {} ] });
  }

  validateUpdateDetailForm() {
    const rules = {
      country: ['required'],
      stateProvince: ['required'],
      city: ['required'],
    };

    return validator.createValidator(rules, this.state);
  }

  isValidClusterInput() {
    const stateInputErrors = [];
    let isInputValid = false;

    const { inputList } = this.state;

    const clusterRules = {
      cluster: ['required'],
      industry: ['required'],
      occupation: ['required'],
    };

    inputList.filter((element, index) => {
      const filteredElement = {};

      if (element.cluster?.value) {
        filteredElement.cluster = element.cluster;

        if (element.industry?.value) {
          filteredElement.industry = element.industry;

          if (element.occupation?.value) {
            filteredElement.occupation = element.occupation;
          }
        }
      }

      const validate = validator.createValidator(clusterRules, filteredElement);
      const { errors, isValid } = validate;

      if (!isValid) {
        const errorField = Object.keys(errors)[0];
        const inputDataError = {
          index: index,
          message: errors[errorField],
          field: errorField,
        };

        stateInputErrors.push(inputDataError);
        isInputValid = false;
        return;
      }

      isInputValid = true;
      return;
    });

    if (! isEmpty(stateInputErrors)) {
      this.setState({ clusterErrors: stateInputErrors });
    }

    return isInputValid;
  }

  updateClusterDetails() {
    const { actions, profile, processSendRequest } = this.props;
    const { country, stateProvince, city, inputList, isForCareerInterest } = this.state;

    let reqObject = !isEmpty(inputList[0]) ? {
      occupations: inputList.map(elem => elem.occupation.value).toString(),
      careerCluster: inputList.map((input) => {
        return {
          clusterId: input.cluster.value,
          industryId: input.industry.value,
          occupationId: input.occupation.value,
          clusterName: input.cluster.label,
          industryName: input.industry.label,
          occupationName: input.occupation.label,
        };
      }),
    } : {};

    if (isForCareerInterest && isEmpty(reqObject)) {
      reqObject = { careerCluster: [] };
    }

    if (!isEmpty(reqObject)) {
      if (!isEmpty(country)) {
        reqObject['zip'] = country.value;
      }

      if (!isEmpty(stateProvince)) {
        reqObject['state'] = stateProvince.value;
      }

      if (!isEmpty(city)) {
        reqObject['city'] = city.value;
      }

      actions.updateProfile(profile.id, reqObject);

      if (processSendRequest) {
        processSendRequest();
      }
    } else {
      toastr.warning('Career and Interests are mandatory');
    }
  }

  async loadLocationOptions(search, loadedOptions, actionMeta) {
    const { page, name: inputName } = actionMeta;

    if (inputName === 'country') {
      await this.props.actions.getCountries(page, search);
    }

    if (inputName === 'stateProvince' && (! isNull(this.state.country))) {
      await this.props.actions.getStates(this.state.country.value, page, search);
    }

    if (inputName === 'city' && (! isNull(this.state.stateProvince))) {
      await this.props.actions.getCities(this.state.stateProvince.value, page, search);
    }

    const options = (inputName === 'country') ?
      this.props.countries : (inputName === 'stateProvince') ?
        this.props.states : this.props.cities;

    const pageCount = (inputName === 'country') ?
      this.props.countriesPageCount : (inputName === 'stateProvince') ?
        this.props.statesPageCount : this.props.citiesPageCount;

    return {
      options,
      hasMore: pageCount > page,
      additional: { page: page + 1, name: inputName }
    };
  }

  onChangeLocation(value, actionMeta) {
    const { name } = actionMeta;

    const city = null;
    const stateProvince = null;

    const state = {
      [name]: value,
      [`${name}Id`]: value.id,
      ...((name === 'country') && { stateProvince, city }),
      ...((name === 'stateProvince') && { city })
    };

    this.setState(state);
  }

  getLocationDetail() {
    const {
      errors,
      country,
      stateProvince,
      city,
      countryId,
      stateProvinceId
    } = this.state;

    return (
      <>
        <Row>
          <Col sm="4" md="4" lg="4" className="font14 mb-6">
            <FormGroup>
              <Label for="country">Country</Label>

              <AsyncPaginate
                cacheOptions
                isSearchable
                name="country"
                classNamePrefix="select"
                className={`basic-single ${errors?.country ? 'is-invalid' : ''}`}
                additional={{ page: 1, name: 'country' }}
                isClearable={false}
                loadOptions={this.loadLocationOptions}
                placeholder="Select Country"
                hideSelectedOptions={false}
                value={country}
                onChange={this.onChangeLocation} />

              <div className="form-control-feedback">{errors?.country}</div>
            </FormGroup>
          </Col>

          <Col sm="4" md="4" lg="4" className="font14 mb-6">
            <FormGroup>
              <Label for="stateProvince">State/Province</Label>

              <AsyncPaginate
                cacheOptions
                isSearchable
                name="stateProvince"
                classNamePrefix="select"
                className={`basic-single ${
                  errors?.country || errors?.stateProvince ? 'is-invalid' : ''
                }`}
                cacheUniqs={[countryId]}
                additional={{ page: 1, name: 'stateProvince' }}
                isClearable={false}
                loadOptions={this.loadLocationOptions}
                placeholder="Select State/Province"
                hideSelectedOptions={false}
                value={stateProvince}
                onChange={this.onChangeLocation} />

              <div className="form-control-feedback">{errors?.stateProvince}</div>
            </FormGroup>
          </Col>
          <Col sm="4" md="4" lg="4" className="font14 mb-6">
            <FormGroup>
              <Label for="city">City</Label>

              <AsyncPaginate
                cacheOptions
                isSearchable
                name="city"
                classNamePrefix="select"
                className={`basic-single ${
                  errors?.country || errors?.stateProvince || errors?.city ? 'is-invalid' : ''
                }`}
                cacheUniqs={[stateProvinceId]}
                additional={{ page: 1, name: 'city' }}
                isClearable={false}
                loadOptions={this.loadLocationOptions}
                placeholder="Select City"
                hideSelectedOptions={false}
                value={city}
                onChange={this.onChangeLocation} />

              <div className="form-control-feedback">{errors?.city}</div>
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }

  toggleDetailsModal() {
    this.props.toggleModal('detailModal');
  }

  submitUpdateDetails() {
    this.setState({
      errors: [],
      clusterErrors: [],
      consolidatedClusterError: '',
      consolidatedClusterErrorDetails: []
    });

    const inputValidation = this.isValidClusterInput();
    const { errors, isValid } = this.validateUpdateDetailForm();

    if (!inputValidation) return;

    if ((isEmpty(this.props.profile.state) || isEmpty(this.props.profile.city)) && !isValid) {
      this.setState({ errors });
      return;
    }

    if (isEmpty(this.state.clusterDetails)) {
      const clusterValidation = validateCluster(this.state.inputList);

      if (! clusterValidation.isValid) {
        this.setState({
          consolidatedClusterError: clusterValidation.errorMessage,
          consolidatedClusterErrorDetails: clusterValidation.details
        });
        return;
      }
    }

    this.toggleDetailsModal();
    this.updateClusterDetails();
  }

  async loadOptions(search, loadedOptions, actionMeta) {
    const { page, index, name: inputName } = actionMeta;
    const clusterStore = [ ...this.state.inputList ];
    const inputData = clusterStore[index];

    if (inputName === 'cluster') {
      await this.props.actions.getCareerClusters(page, search);
    }

    if (inputName === 'industry' && (! isUndefined(inputData.cluster))) {
      await this.props.actions.getCareerIndustries(inputData.cluster.value, page, search);
    }

    if (inputName === 'occupation' && (! isUndefined(inputData.industry))) {
      await this.props.actions.getCareerOccupations(inputData.industry.value, page, search);
    }

    const options = (inputName === 'cluster') ?
      this.props.clusters : (inputName === 'industry') ?
        this.props.industries : this.props.occupations;

    const pageCount = (inputName === 'cluster') ?
      this.props.clustersPageCount : (inputName === 'industry') ?
        this.props.industriesPageCount : this.props.occupationsPageCount;

    return {
      options,
      hasMore: pageCount > page,
      additional: { page: page + 1, name: inputName }
    };
  }

  onChangeCareerClusters(elemValue, actionMeta) {
    const { inputList } = this.state;
    const { name: inputName } = actionMeta;

    const industry = null;
    const occupation = null;
    const [name, indexString] = inputName.split('-');
    const index = parseInt(indexString);

    const list = [
      ...inputList.slice(0, index),
      {
        ...inputList[index],
        [name]: { index, ...elemValue },
        ...((name === 'cluster') && { industry, occupation }),
        ...((name === 'industry') && { occupation })
      },
      ...inputList.slice(index + 1)
    ];

    this.setState({ inputList: list, [`${name}Id`]: elemValue.id });
  }

  render() {
    const { isOpen, profile } = this.props;

    const {
      inputList,
      clusterId,
      industryId,
      headerText,
      clusterErrors,
      clusterDetails,
      isForCareerInterest,
      consolidatedClusterError,
      consolidatedClusterErrorDetails
    } = this.state;

    return (
      <Modal
        centered
        size="lg"
        className="customLevelUpModal"
        isOpen={isOpen}
        toggle={this.toggleDetailsModal}>
        <div className="modal-header">
          <span className="modal-title">{`${
            headerText == '' ? 'Update your Details' : headerText
          }`}</span>
          <div
            onClick={this.toggleDetailsModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">
              <img src={CloseIcon} />
            </span>
          </div>
        </div>
        <ModalBody>
          <div className="row">
            <div className="col-sm-12">
              {!isForCareerInterest && (
                <label>
                  Please provide the details below so we can find matches for your feedback request.
                </label>
              )}

              {isEmpty(clusterDetails) && (
                <>
                  <label>
                    Add career clusters, industries and/or occupations that match
                    your career interests.
                  </label>

                  {inputList.map((input, index) => {
                    const clusterError = clusterErrors.filter(
                      error => error.index === index
                    );
                    const isDuplicate = consolidatedClusterErrorDetails.filter(errorDetails => errorDetails.industry.index === index);

                    return (
                      <div key={`interests-${index}`} className={`${!isEmpty(isDuplicate) ? 'is-invalid' : ''}`}>
                        <Interests
                          index={index}
                          key={index}
                          clusterId={clusterId}
                          industryId={industryId}
                          loadOptions={this.loadOptions}
                          onChangeCareerClusters={this.onChangeCareerClusters}
                          inputData={input}
                          handleRemoveClick={this.handleRemoveClick}
                          handleRemoveAll={this.handleRemoveAll}
                          clusterError={clusterError}/>
                        <div className="form-control-feedback">
                          {!isEmpty(isDuplicate) ? consolidatedClusterError : ''}
                        </div>
                      </div>
                    );
                  })}

                  {inputList.length < 9 &&
                    <div className="text-right d-flex mr-5 pr-3 justify-content-end">
                      <a
                        className="clickable large btn btn-link myFeedbackLink font12 d-block pr-0"
                        onClick={this.handleAddClick}>
                        <i className="fa fa-plus-square mt-1 mr-1" />
                        Add
                      </a>
                    </div>}
                </>
              )}
            </div>
          </div>

          {/* This is going to be separate component */}
          {!isForCareerInterest && (isEmpty(profile.state) || isEmpty(profile.city)) && (
            <div className="">{this.getLocationDetail()}</div>
          )}

          {/* This is going to be separate component */}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.toggleDetailsModal}>
            {'Cancel'}
          </Button>
          <Button color="primary" onClick={this.submitUpdateDetails}>
            {'Update Details'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

UpdateDetails.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  profile: PropTypes.object,
  isForCareerInterest: PropTypes.bool,
  headerText: PropTypes.string,
  clusterDetails: PropTypes.array,
  processSendRequest: PropTypes.func,
  countries: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  countriesPageCount: PropTypes.number.isRequired,
  statesPageCount: PropTypes.number.isRequired,
  citiesPageCount: PropTypes.number.isRequired,
  actions: PropTypes.object.isRequired,
  clusters: PropTypes.array.isRequired,
  industries: PropTypes.array.isRequired,
  occupations: PropTypes.array.isRequired,
  clustersPageCount: PropTypes.number.isRequired,
  industriesPageCount: PropTypes.number.isRequired,
  occupationsPageCount: PropTypes.number.isRequired
};

const mapStateToProps = createStructuredSelector({
  clusters: selectFormattedClusters,
  clustersPageCount: selectClustersPageCount,
  industries: selectFormattedIndustries,
  industriesPageCount: selectIndustriesPageCount,
  occupations: selectFormattedOccupations,
  occupationsPageCount: selectOccupationsPageCount,
  states: selectFormattedStates,
  statesPageCount: selectStatesPageCount,
  cities: selectFormattedCities,
  citiesPageCount: selectCitiesPageCount,
  countries: selectFormattedCountries,
  countriesPageCount: selectCountriesPageCount
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCities,
      getStates,
      getCountries,
      updateProfile,
      getCareerClusters,
      getCareerIndustries,
      getCareerOccupations
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDetails);
