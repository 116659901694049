import { createSelector } from 'reselect';
import { uniqueId } from '../../utils';

const selectIndustries = state => state.industries;

export const selectIndustriesList = createSelector(
  [selectIndustries],
  industries => industries.data
);

export const selectFormattedIndustries = createSelector(
  [selectIndustriesList],
  industries =>
    industries.map(
      industry => ({
        id: uniqueId(),
        label: industry.name,
        value: industry.id
      })
    )
);

export const selectIndustriesPageCount = createSelector(
  [selectIndustries],
  industries => industries.pageCount
);
