import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const ButtonActionsFollowing = ({
  toggleModal,
  onUnfollowClick,
  onFollowClick,
  recordId,
  isFollowing,
  isUnFollowing,
  isUserFollower
}) => {
  return (
    <div className="btn-actions">
      <button type="button"
        className={
          classNames(
            'btn',
            {'btn-danger': isUserFollower, 'btn-follow': !isUserFollower}
          )}
        disabled={isFollowing || isUnFollowing}
        onClick={isUserFollower ? onUnfollowClick(recordId) : onFollowClick} >
        {
          isUserFollower ?
            <i
              style={{fontSize: 12}}
              className={classNames('d-lg-none',
                {
                  'fa fa-spinner fa-pulse': isUnFollowing,
                  'fas fa-user-minus': !isUnFollowing
                })}/>:
            <i
              style={{fontSize: 12}}
              className={classNames('d-lg-none',
                {
                  'fa fa-spinner fa-pulse': isFollowing,
                  'fas fa-user-plus': !isFollowing
                })}/>
        }

        <span className="d-none d-lg-block">{
          isUserFollower?
            isUnFollowing ? 'Unfollowing...' :'Unfollow' :
            isFollowing ? 'Following...' :'Follow'
        }</span>
      </button>
      <button
        type="button"
        className="btn btn-primary"
        onClick={toggleModal}>
        <i className="fab fa-telegram-plane d-lg-none"/>
        <span className="d-none d-lg-block">Message</span>
      </button>
    </div>
  );

};

ButtonActionsFollowing.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  onUnfollowClick: PropTypes.func,
  onFollowClick: PropTypes.func,
  isDisabledFollow: PropTypes.bool,
  isFollowing: PropTypes.bool,
  isUnFollowing: PropTypes.bool,
  isUserFollower: PropTypes.bool,
  recordId: PropTypes.string
};

export default ButtonActionsFollowing;
