import PropTypes from 'prop-types';
import React from 'react';
import _capitalize from 'lodash/capitalize';
import { Link } from 'react-router-dom';

let sharedPostOwnerPronoun;

const SharedPostOwnerText = (props) => {
  if(props.wallPost.sharedEntityType === 'wall' || props.wallPost.sharedEntityType === 'file'){
    const sharedPostKey = `shared${_capitalize(props.wallPost.sharedEntityType)}`;

    const userName = props.wallPost[sharedPostKey].user.name;

    sharedPostOwnerPronoun = props.wallPost.user.name === userName ?
      <span>their own</span> :
      (<span>
        <Link
          className="profile-name"
          to={`/cp/${props.wallPost[sharedPostKey].user.vanity}`}
          target="_blank"
          title={`Link to ${userName}’s Career Site`}>
          {userName}
        </Link>'s
      </span>);
  } else sharedPostOwnerPronoun = 'a';

  return (
    <span className="shared-type-text">
      &nbsp;shared {sharedPostOwnerPronoun}{` ${props.postType}`}
    </span>
  );
};

SharedPostOwnerText.propTypes = {
  wallPost: PropTypes.object.isRequired,
  postType: PropTypes.string.isRequired,
};

export default SharedPostOwnerText;
