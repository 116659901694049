export const commonConstant = {
  FILE_EXTENSION: '.pdf, .doc, .ppt, .xls, .png, .psd, etc.',
  DEFAULT_TIMESTAMP: ' 00:00:00.000000',
  MESSAGE: 'Message',
  HIDE: 'Hide',
  BLOCK: 'Block',
  REPORT: 'Report',
  UNFOLLOW: 'Unfollow',
  FOLLOW: 'Follow',
  TITLE: 'title',
  TITLE_TEXT: 'Title',
  ENTER_SUBJECT: 'Enter subject...',
  ENTER_MESSAGE: 'Enter message...',
  ENTER_TITLE: 'Enter your title',
  CANCEL: 'Cancel',
  SENDING: 'Sending...',
  SEND: 'Send',
  NAME : 'Name',
  PRIMARY_EMAIL: 'Email',
  ALTERNATE_EMAIL: 'Alternate Email',
  PHONE_NUMBER:'Phone number',
  SORT_BY: 'Sort by',
  ACTION: 'Action',
  ANOTHER_ACTION: 'Another action',
  SOMETHING_ELSE_HERE: 'Something else here',
  ORGANIZATION_NAME: 'Organization Name: ',
  ENTER_ORGANIZATION: 'Enter your organization name',
  DESCRIPTION: 'Description',
  ENTER_ASSOCIATION_DESCRIPTION: 'Enter your association description',
  ORGANIZATION_URL: 'Organization URL:',
  ENTER_ORGANIZATION_URL: 'Enter your organization URL',
  POSITION_HELD: 'Position(s) Held:',
  ENTER_POSITION_HELD: 'Enter positions held',
  START_DATE: 'Start Date: ',
  END_DATE: 'End Date: ',
  CHECK_IF_PRESENT: 'Check if present',
  DELETING: 'Deleting...',
  DELETE: 'Delete',
  SAVING: 'Saving...',
  SAVE: 'Save',
  ENTER_AWARD_DESCRIPTION: 'Enter your award description',
  ISSUER: 'Issuer',
  ENTER_ISSUER: 'Enter your issuer',
  ISSUER_URL: 'Issuer URL:',
  ENTER_ISSUER_URL: 'Enter your issuer URL',
  DATE: 'Date: ',
  REQUIRED: 'Required',
  FIRST_NAME: 'First name:',
  ENTER_FIRST_NAME: 'Enter your first name',
  LAST_NAME: 'Last name:',
  ENTER_LAST_NAME: 'Enter your last name',
  HEADLINE: 'Headline:',
  ENTER_YOUR_HEADLINE: 'Enter your headline',
  CITY: 'City',
  STATE_PROVINCE: 'State/Province',
  CAREER_SITE_URL: 'Career Site URL:',
  ENTER_VANITY: 'Enter your vanity',
  DATE_FORMAT: 'YYYY/MM/DD',
  ENTER_CITY: 'Enter your city',
  ENTER_STATE: 'Enter your state',
  ENTER_ZIP: 'Enter your zip',
  SUMMARY: 'Summary',
  PROGRAM: 'Program',
  MAJOR: 'Major',
  INSTITUTION: 'Institution',
  LEVEL: 'Level',
};

export const BulkActionsConstant = {
  BULK_ACTIONS: 'Bulk Actions'
};

export const FollowersConstant = 'Followers';

export const FollowingConstant = 'Following';

export const LevelPageComponentConstant = {
  YOUR_LEVEL: 'Your Level',
  FACTORS_EVALUATED: 'Factors Evaluated',
  PROFILE_STRENGTH: 'Profile Strength',
  STRONG_PROFILE: 'Strong profiles gain more interest from employers and demonstrate your ability to market yourself.',
  IMPROVE_PROFILE: 'Improve your profile strength as you add detail to your Professional Profile and increase profile views.',
  SKILLS_STRENGTH: 'Skills Strength',
  TO_ATTRACT_EMPLOYER: 'Having skills employers want and providing evidence of your skills improves your ability to attract employers.',
  IMPROVE_SKILLS: 'Improve your skills strength by claiming Skill Badges and earning endorsements for your badges.',
  POST_STRENGTH: 'Post Strength',
  SIGN_OF_LEADERSHIP: 'Positive reactions to your posts demonstrates your ability to influence others which is a sign of leadership.',
  PUBLISHING_POST: 'Improve your post strength by publishing helpful posts that earn you followers, shares, and likes.'
};

export const CertificationFormConstant = {
  CERTIFICATE_EXPIRE_MESSAGE: 'This certification does not expire',
  DATES_COMPLETED: 'Dates Completed:',
  EXPECTED_COMPLETION_DATE: '(or expected completion date)',
  CERTIFICATION_NAME: 'Enter your certification name',
  LICENSE_NUMBER: 'License Number: ',
  ENTER_LICENSE_NUMBER: 'Enter your license number',
  CERTIFICATION_AUTHORITY: 'Certification Authority: ',
  ENTER_CERTIFICATION_AUTHORITY: 'Enter your certification authority',
  CERTIFICATION_AUTHORITY_URL: 'Certification Authority URL:',
  ENTER_CERTIFICATION_AUTHORITY_URL: 'Enter your certification authority URL',
};

export const ClubFormConstant = {
  ACTIVITY_OR_CLUB: 'Activity or Club',
  ENTER_ACTIVITY_OR_CLUB: 'Enter your activity or club'
};

export const EducationFormConstant = {
  ENTER_INSTITUTION_NAME: 'Enter your institution\'s name',
  SELECT_PROGRAM: 'Please select a program',
  INSTITUTION_URL: 'Institution URL',
  ENTER_INSTITUTION_URL: 'Enter your institution\'s URL',
  MAJOR: 'Major',
  SELECT_MAJOR: 'Select or type in your major',
  GPA: 'GPA',
  ENTER_GPA: 'Enter your GPA',
  DATES_ATTENDED: 'Dates Attended',
  EXPECTED_GRADUATION_DATE: '(or expected graduation date)',
  CHECK_EXPECTED_DATE: 'Check if expected date',
};

export const EvidenceListConst = {
  ENTER_TITLE_URL: 'Enter the Title and URL for an evidence and click on Add Evidence button below to add an evidence.',
};

export const EvidenceSectionConstant = {
  EVIDENCES: 'Evidences',
  TITLE: 'Title',
  ENTER_TITLE_EVIDENCE: 'Enter title of evidence',
  URL: 'URL',
  ENTER_URL_EVIDENCE: 'Enter url of evidence',
  ADD_EVIDENCE: 'Add Evidence',
  CLEAR_EVIDENCE: 'Clear Evidence Section',
};

export const ExperienceFormConstant = {
  TITLE: 'Title',
  ENTER_EXPERIENCE_TITLE: 'Enter experience title',
  ORGANIZATION: 'Organization Name',
  ENTER_ORGANIZATIONS_NAME: 'Enter your organization\'s name',
  ENTER_ORGANIZATIONS_URL: 'Enter your organization\'s URL',
};

export const InstitutionFormConstant = {
  ENTER_INSTITUTION: 'Enter your institution',
  ENTER_PROGRAM: 'Enter your program',
  ENTER_MAJOR: 'Enter your major',
};

export const ENTER_INTEREST = 'Enter your interests. (e.g. Volunteering, Web Design, Photography, Poetry, Music Production, Blogging, Entrepreneurship)';

export const ProjectFormConstant = {
  PROJECT_URL: 'Project URL',
  ENTER_PROJECT_URL: 'Enter your project URL',
  ENTER_PROJECT_DESCRIPTION: 'Enter your project description',
};

export const PublicationFormConstant = {
  AUTHOR: 'Author(s)',
  AUTHOR_LIST: 'Author(s) - Comma separated for a list',
  PUBLICATION_PUBLICATION: 'Publication/Publisher',
  ENTER_PUBLICATION_PUBLICATION: 'Enter your publication/publisher',
  PUBLICATION_PUBLISHER_URL: 'Publication/Publisher URL',
  ENTER_PUBLICATION_PUBLISHER_URL: 'Enter your publication/publisher URL'
};

export const SituationFormConstant = {
  EMPLOYMENT_STATUS: 'Your Employment Status',
  WORKING_STATUS: 'I’m working and not seeking a job',
  NEW_OPPORTUNITIES: 'I’m working but open to new opportunities',
  SEEKING_A_JOB: 'I’m not working and actively seeking a job',
  NOT_SEEKING_JOB: 'I’m not working and not seeking a job at this time',
  EMPLOYMENT_TYPE: 'Employment Type',
  DESCRIBE_SITUATION: 'Choose the one that best describes your situation',
  FULL_TIME: 'Full-Time',
  PART_TIME: 'Part-Time',
  PAID_INTERNSHIP: 'Paid Internship',
  UNPAID_INTERNSHIP: 'Unpaid Internship',
  TEMPORARY_CONTRACT: 'Temporary Contract',
  SELF_EMPLOYED: 'Self-employed',
  NOT_WORKING: 'Not working',
};

export const ENTER_SKILL_NAME = 'Enter your skill name';

export const SummaryFormConstant = {
  VIDEO_EMBED_CODE_NOT_VALID: 'Video URL or embed code is not valid' ,
  PROFESSIONAL_HIGHLIGHTS: 'Video Resume, Demo Reel, or Professional Highlights:',
  ENTER_EMBED_CODE: 'Enter your YouTube or Vimeo video URL or embed code',
  REMOVE_VIDEO: 'Remove Video',
};

export const HeaderOptionsBarConstant = {
  BADGES: 'Badges',
  SHARES: 'Shares',
  FEEDBACK: 'Feedback',
  VIEWS: 'Views',
};
