import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { checkHttp } from '../../../utils';

const styles = {
  userNameEditIcon: {
    cursor: 'pointer',
    marginLeft: 10,
  },

  editActions: {
    position: 'relative',
  }
};

const EducationSectionCard = ({
  educationDetails,
  onEditEducation,
  onRemoveEducation
}) => {
  const isStartDateObject = typeof educationDetails.startdate === 'object';
  const startDateString = isStartDateObject ? educationDetails.startdate.date :
    educationDetails.startdate === '' ? undefined : educationDetails.startdate;

  const isEndDateObject = typeof educationDetails.enddate === 'object';
  const endDateString = isEndDateObject ? educationDetails.enddate.date :
    educationDetails.enddate === '' ? undefined : educationDetails.enddate;

  const startDate = moment(startDateString).format('MM/YYYY');
  let endDate;

  if (educationDetails.noend) {
    endDate = 'Present';
  } else {
    endDate = moment(endDateString).format('MM/YYYY');
  }

  let institution = <span>{educationDetails.institution}</span>;

  if (educationDetails.insurl !== '') {
    const urlString = checkHttp(educationDetails.insurl) ?
      educationDetails.insurl : `//${educationDetails.insurl}`;
    institution = <span><a href={urlString} target="_blank" rel="noopener noreferrer">{educationDetails.institution}</a></span>;
  }

  const replacer = (match, p1) => {
    return p1;
  };
  const formattedGPA = educationDetails.gpa.replace(/(\d)(0+$)/, replacer);

  return (
    <div className="resume-section section-item">
      <div className="info-split">
        <span style={styles.editActions} className="float-right">
          <i
            onClick={onEditEducation}
            id={educationDetails.id}
            style={styles.userNameEditIcon}
            className="fa fa-pencil"/>

          <i
            onClick={onRemoveEducation}
            id={educationDetails.id}
            style={styles.userNameEditIcon}
            className="fa fa-close"/>
        </span>

        <span>
          <b>{educationDetails.program}: {educationDetails.major}</b> - {institution}
        </span>
        <span style={{marginLeft: 10}}>({startDate} - {endDate})</span>

      </div>

      {(formattedGPA.length !== 0) &&
        <p style={{overflow: 'hidden'}}>
          <b>GPA</b>: {formattedGPA}
        </p>}
    </div>
  );
};

EducationSectionCard.propTypes = {
  educationDetails: PropTypes.object.isRequired,
  onEditEducation: PropTypes.func.isRequired,
  onRemoveEducation: PropTypes.func.isRequired
};

export default EducationSectionCard;
