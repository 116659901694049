import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Validator from '../../../validator';

import * as projectActions from '../../../redux/actions/projects';
import * as componentsActions from '../../../redux/actions/components';

import Loading from '../../../components/common/Loading';
import ProjectForm from '../../../components/profile/forms/ProjectForm';
import ProjectList from '../../../components/profile/sections/ProjectList';
import ConfirmModal from '../../../components/portfolio/ConfirmModal';
import ProfileCardHeader from '../../../components/profile/header/ProfileCardHeader';
import formWithModal from '../../../components/profile/modal/formWithModal';

class Project extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    projects: PropTypes.array.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    isPublicProfile: PropTypes.bool.isRequired,
    showVisibilitySelect: PropTypes.bool,
    onChangeVisibility: PropTypes.func,
    isEditingSections: PropTypes.bool,
    onToggleSectionEdit: PropTypes.func,
    onConfirmDeleteSection: PropTypes.func,
    modal: PropTypes.string,
    isUpdatingProfile: PropTypes.bool,
    isDeleting: PropTypes.bool,
    isUpdating: PropTypes.bool,
    profile: PropTypes.object
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      isEditing: false,
      isSubmitting: false,
      editingId: null,
      project: Object.assign({}, this.props.project),
      editorText: {description: this.props.project}
    };

    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDeleteSection = this.onDeleteSection.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.onEditProject = this.onEditProject.bind(this);
    this.onDeleteProject = this.onDeleteProject.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
  }

  componentDidMount() {
    const { profile } = this.props;
    this.props.actions.projectsRequest(profile.id);
  }

  formatEndDate = (dateString) => {
    return dateString === '' ? null : dateString;
  };

  isValid(field = null) {
    const { noend, startdate } = this.state.project;
    let rules = {};
    if (noend === true) {
      rules = {
        title: ['required', 'minLength|6', 'maxLength|200'],
        description: ['required'],
        url: ['unsafeurl'],
        startdate: ['required', 'startdate'],
      };
    } else {
      rules = {
        title: ['required', 'minLength|6', 'maxLength|200'],
        description: ['required'],
        url: ['unsafeurl'],
        startdate: ['required', 'startdate'],
        enddate: ['required', 'startdate', `beforeStart|${this.formatEndDate(startdate)}`],
      };
    }

    const { project, errors: prevErrors} = this.state;
    const validate = Validator.createValidator(rules, project, field);
    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    if (noend === true) {
      delete prevErrors['enddate'];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  isValidDescription(field = null) {
    const rules = {
      description: ['required', 'minWords|6', 'maxWords|200']
    };
    const { editorText, errors: prevErrors } = this.state;
    const validate = Validator.createValidator(rules, editorText, field);
    const { errors, isValid } = validate;

    if (field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }
    this.setState({ errors: Object.assign({}, prevErrors, errors) });

    return isValid;
  }

  onChange(event) {
    let { name, value, type, checked } = event.target;
    const { project } = this.state;

    value = type === 'checkbox' ? checked : value;

    project[name] = value;

    this.setState({ project }, () => this.isValid(name));

    if (name === 'startdate') {
      this.isValid('enddate');
    }

  }

  onChangeDescription(value, ...params) {
    const { project, editorText } = this.state;

    project['description'] = value;
    editorText['description'] = params[2].getText().replace(/\r?\n|\r/gm, ' ');

    this.setState({ project, editorText }, () => this.isValidDescription('description'));
  }

  onSave(event) {
    event.preventDefault();

    this.setState({ isSubmitting: true });

    if (! this.isValid() || ! this.isValidDescription()) {
      return this.setState({ isSubmitting: false });
    }

    const { project, editingId } = this.state;
    const { actions, projects } = this.props;

    if(editingId !== null){
      const index = projects.findIndex(element =>
        element.id === project.id);

      return actions.updateProject(index, project)
        .then(() => this.handleResponse());
    }

    this.props.actions.saveProject(project)
      .then(() => this.handleResponse());
  }

  onCancel() {
    this.setState({
      isEditing: false,
      errors: {},
      editingId: null,
      project: this.props.project
    }, () => this.props.onToggleSectionEdit('project'));
  }

  onEditClick(event) {
    event.preventDefault();

    this.setState({ isEditing: true },
      () => this.props.onToggleSectionEdit('project'));
  }

  onDeleteSection(){
    this.props.actions.openModal('delete-webpage-project-section-modal');
  }

  onCloseModal(){
    this.props.actions.closeModal();
  }

  onDeleteConfirm(){
    const { projects, actions } = this.props;

    projects.map(project => actions.deleteProject(project.id));
    this.props.onConfirmDeleteSection('Project');
  }

  onEditProject(id){
    const { projects } = this.props;
    const projectObject = projects.find(project => project.id === id);
    const project = Object.assign({}, this.state.project, projectObject);
    this.setState({
      isEditing: true,
      project,
      editingId: id
    }, () => this.props.onToggleSectionEdit('project'));
  }

  onDeleteProject(event) {
    event.preventDefault();

    const { project } = this.state;
    this.props.actions.deleteProject(project.id)
      .then(() => this.handleResponse());
  }

  handleResponse() {
    const project = {
      title: '',
      description: '',
      url: '',
      startdate: '',
      enddate: '',
      noend: false
    };

    this.setState({
      isEditing: false,
      isSubmitting: false,
      editingId: null,
      project
    }, () => this.props.onToggleSectionEdit('project'));
  }

  EditingProject= formWithModal(ProjectForm);

  render() {
    const {
      modal,
      projects,
      isRequesting,
      isPublicProfile,
      showVisibilitySelect,
      onChangeVisibility,
      isEditingSections,
      isDeleting,
      isUpdatingProfile,
      isUpdating
    } = this.props;
    const { errors, project, isEditing, isSubmitting, editingId } = this.state;

    if(projects.length === 0 && isPublicProfile){
      return null;
    }

    const isDeleteConfirmModalOpen = modal && modal === 'delete-webpage-project-section-modal';

    return (
      <div className="profile-item projects">
        <ProfileCardHeader
          isEditing={isEditing}
          isEditingSections={isEditingSections}
          type="list"
          title="Projects"
          isPublicProfile={isPublicProfile}
          showVisibilitySelect={showVisibilitySelect}
          onChangeVisibility={onChangeVisibility}
          onDeleteClick={this.onDeleteSection}
          onEditClick={this.onEditClick}
          icon="projects"
          isDeletable/>

        {isRequesting ?
          <Loading/> :
          isEditing ?
            <div className="profile-item__card">
              <this.EditingProject
                errors={errors}
                project={project}
                isSubmitting={isSubmitting}
                isUpdating={isUpdating}
                isDeleting={isDeleting}
                showDelete={editingId !== null}
                onChange={this.onChange}
                onSave={this.onSave}
                onCancel={this.onCancel}
                exampleType={'Project'}
                onDelete={this.onDeleteProject}
                onChangeDescription={this.onChangeDescription}/>
            </div> :
            <ProjectList
              projects={projects}
              onEdit={this.onEditProject}
              isPublicProfile={isPublicProfile}/>}
        {
          isDeleteConfirmModalOpen &&
            <ConfirmModal
              modalHeaderText="Delete Section"
              modalMessage="Are you sure you want to permanently delete this Career Site section?"
              modalFooterButtonText="Yes, Delete"
              onCancel={this.onCloseModal}
              onConfirm={this.onDeleteConfirm}
              isConfirming={isUpdatingProfile || isDeleting.status}
              confirminginProgressText="Deleting..."
              isOpen={isDeleteConfirmModalOpen}/>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const project = {
    title: '',
    description: '',
    url: '',
    startdate: '',
    enddate: '',
    noend: false
  };

  return {
    project,
    modal: state.components.modal,
    projects: state.projects.data,
    isUpdatingProfile: state.profile.isUpdating,
    isDeleting: state.projects.isDeleting.status,
    isUpdating: state.projects.isUpdating.status,
    isRequesting: state.projects.isRequesting
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({},
    projectActions,
    componentsActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Project);
