import { CALL_API } from '../../middlewares/api';

import {
  FETCH_JOB_NOTES_REQUEST,
  FETCH_JOB_NOTES_SUCCESS,
  FETCH_JOB_NOTES_FAILURE,
  ADD_JOB_NOTE_REQUEST,
  ADD_JOB_NOTE_SUCCESS,
  ADD_JOB_NOTE_FAILURE,
  EDIT_JOB_NOTE_REQUEST,
  EDIT_JOB_NOTE_SUCCESS,
  EDIT_JOB_NOTE_FAILURE,
  DELETE_JOB_NOTE_REQUEST,
  DELETE_JOB_NOTE_SUCCESS,
  DELETE_JOB_NOTE_FAILURE,
} from '../../constants';

export const fetchJobNotes = (jobId, userId) => {
  const endpoint = `job/note?job=${jobId}&user=${userId}`;

  return {
    [CALL_API]: {
      authenticated: true,
      endpoint,
      method: 'get',
      types: [
        FETCH_JOB_NOTES_REQUEST,
        FETCH_JOB_NOTES_SUCCESS,
        FETCH_JOB_NOTES_FAILURE,
      ],
    },
  };
};

export const addNote = (jobId, note) => {
  const endpoint = 'job/note';

  const data = {
    job: jobId,
    note: note,
  };

  return {
    [CALL_API]: {
      authenticated: true,
      data,
      endpoint,
      method: 'post',
      types: [ADD_JOB_NOTE_REQUEST, ADD_JOB_NOTE_SUCCESS, ADD_JOB_NOTE_FAILURE],
    },
  };
};

export const editNote = (noteId, data) => {
  const endpoint = `job/note/${noteId}`;

  return {
    [CALL_API]: {
      authenticated: true,
      data,
      id: noteId,
      endpoint,
      method: 'put',
      types: [
        EDIT_JOB_NOTE_REQUEST,
        EDIT_JOB_NOTE_SUCCESS,
        EDIT_JOB_NOTE_FAILURE,
      ],
    },
  };
};

export const deleteNote = (noteId) => {
  const endpoint = `job/note/${noteId}`;

  return {
    [CALL_API]: {
      authenticated: true,
      id: noteId,
      endpoint,
      method: 'delete',
      types: [
        DELETE_JOB_NOTE_REQUEST,
        DELETE_JOB_NOTE_SUCCESS,
        DELETE_JOB_NOTE_FAILURE,
      ],
    },
  };
};
