import initialState from './initialState';

import {
  SKILLS_REQUEST,
  SKILLS_SUCCESS,
  SKILLS_FAILURE,
  CREATE_SKILL_REQUEST,
  CREATE_SKILL_SUCCESS,
  CREATE_SKILL_FAILURE,
  UPDATE_SKILL_REQUEST,
  UPDATE_SKILL_SUCCESS,
  UPDATE_SKILL_FAILURE,
  DELETE_SKILL_REQUEST,
  DELETE_SKILL_SUCCESS,
  DELETE_SKILL_FAILURE,
  SET_SKILL_BEING_UPDATED_IN_STORE,
  UNIT_SKILL_REQUEST,
  UNIT_SKILL_SUCCESS,
  UNIT_SKILL_FAILURE,
  RESET_SKILLS_DATA,
  LIKE_UNIT_SKILL_FILE_REQUEST,
  LIKE_UNIT_SKILL_FILE_SUCCESS,
  LIKE_UNIT_SKILL_FILE_FAILURE,
  UNLIKE_UNIT_SKILL_FILE_REQUEST,
  UNLIKE_UNIT_SKILL_FILE_SUCCESS,
  UNLIKE_UNIT_SKILL_FILE_FAILURE,
  UPDATE_FILE_EVIDENCE_PAGE_REQUEST,
  UPDATE_FILE_EVIDENCE_PAGE_SUCCESS,
  UPDATE_FILE_EVIDENCE_PAGE_FAILURE,
  REMOVE_EVIDENCE_FILE_REQUEST,
  REMOVE_EVIDENCE_FILE_SUCCESS,
  REMOVE_EVIDENCE_FILE_FAILURE,
  LINK_EVIDENCE_FILES_REQUEST,
  LINK_EVIDENCE_FILES_SUCCESS,
  LINK_EVIDENCE_FILES_FAILURE,
  GET_MYSKILLSPAGE_REQUEST,
  GET_MYSKILLSPAGE_SUCCESS,
  GET_MYSKILLSPAGE_FAILURE,
  UPDATE_UNIT_SKILL_FILES
} from '../../constants';

export default function reducer(state = initialState.skills, action) {
  switch (action.type) {
  case SET_SKILL_BEING_UPDATED_IN_STORE:
    return Object.assign({}, state, { skillBeingUpdated: action.skill });

  case SKILLS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case SKILLS_SUCCESS:
    return Object.assign({}, state, {
      data: action.data._embedded.skills,
      isRequesting: false,
    });

  case SKILLS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case GET_MYSKILLSPAGE_REQUEST:
    return Object.assign({}, state, {
      isRequesting: true,
      skillsUserId: action.id
    });

  case GET_MYSKILLSPAGE_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data, ...action.data.detail.record],
      paginationData: {
        page: action.data.detail.page,
        page_count: action.data.detail.total_pages,
        total_items: action.data.detail.total_items
      },
      isRequesting: false,
      skillsUserId: null
    });

  case GET_MYSKILLSPAGE_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
      skillsUserId: null
    });

  case CREATE_SKILL_REQUEST:
    return Object.assign({}, state, { isSubmitting: true });

  case CREATE_SKILL_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data, ...action.data.skills],
      isSubmitting: false,
      error: {},
      isUpdating: {
        status: false,
        skillIndex: null,
        skillId: action.data.skills.map(skill => skill.id),
      },
    });

  case CREATE_SKILL_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case UPDATE_SKILL_REQUEST:
    return Object.assign({}, state, {
      isUpdating: {
        status: true,
        skillIndex: action.index,
        skillId: state.isUpdating.skillId,
      },
    });

  case UPDATE_SKILL_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, state.isUpdating.skillIndex),
        Object.assign({}, action.data),
        ...state.data.slice(state.isUpdating.skillIndex + 1),
      ],
      isUpdating: {
        status: false,
        skillIndex: null,
        skillId: state.isUpdating.skillId,
      },
    });

  case UPDATE_SKILL_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: {
        status: false,
        skillIndex: null,
        skillId: state.isUpdating.skillId,
      },
    });

  case DELETE_SKILL_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: action.id },
    });

  case DELETE_SKILL_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.filter(skill => skill.id !== state.isDeleting.id),
      ],
      error: {},
      isDeleting: Object.assign({}, state.isDeleting, { status: false, id: null }),
    });

  case DELETE_SKILL_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: Object.assign({}, state.isDeleting, { status: false, id: null }),
    });

  case UNIT_SKILL_REQUEST:
    return Object.assign({}, state, {
      unitSkill: Object.assign({}, state.unitSkill, {
        isRequesting: true,
      }),
    });

  case UNIT_SKILL_SUCCESS:
    return Object.assign({}, state, {
      unitSkill: Object.assign({}, state.unitSkill, {
        isRequesting: false,
        data: action.data,
      }),
    });

  case UNIT_SKILL_FAILURE:
    return Object.assign({}, state, {
      unitSkill: Object.assign({}, state.unitSkill, {
        isRequesting: false,
        error: action.error,
      }),
    });

  case UPDATE_UNIT_SKILL_FILES:
    return Object.assign({}, state, {
      unitSkill: Object.assign({}, state.unitSkill, {
        data: Object.assign({}, state.unitSkill.data, {
          files: [
            action.file,
            ...state.unitSkill.data.files
          ]
        })
      })
    });

  case RESET_SKILLS_DATA:
    return Object.assign({}, state, {
      unitSkill: Object.assign({}, state.unitSkill, {
        data: {},
      }),
    });

  case UPDATE_FILE_EVIDENCE_PAGE_REQUEST:
    return Object.assign({}, state, {
      unitSkill: Object.assign({}, state.unitSkill, {
        isUpdating: {
          status: true,
          id: action.id,
        },
      }),
    });

  case UPDATE_FILE_EVIDENCE_PAGE_SUCCESS: {
    const { isUpdating, data } = state.unitSkill;
    const { files } = data;
    const fileIndex = files.findIndex(file => file.id === isUpdating.id);

    return Object.assign({}, state, {
      unitSkill: Object.assign({}, state.unitSkill, {
        isUpdating: {
          status: false,
          id: null,
        },
        data: Object.assign({}, data, {
          files: [
            ...files.slice(0, fileIndex),
            Object.assign({}, action.data),
            ...files.slice(fileIndex + 1),
          ],
        }),
      }),
    });
  }

  case UPDATE_FILE_EVIDENCE_PAGE_FAILURE:
    return Object.assign({}, state, {
      unitSkill: Object.assign({}, state.unitSkill, {
        isUpdating: {
          status: false,
          id: null,
        },
      }),
    });

  case LIKE_UNIT_SKILL_FILE_REQUEST:
  case UNLIKE_UNIT_SKILL_FILE_REQUEST:
    return Object.assign({}, state, {
      unitSkill: Object.assign({}, state.unitSkill, {
        isLikingFile: {
          status: true,
          id: action.id,
        },
      }),
    });

  case LIKE_UNIT_SKILL_FILE_SUCCESS: {
    const { isLikingFile, data } = state.unitSkill;
    const { files } = data;
    const fileIndex = files.findIndex(file => file.id === isLikingFile.id);
    const updatedFileObject = Object.assign({}, files[fileIndex], {
      liked: true,
      like_count: files[fileIndex].like_count + 1,
    });

    return Object.assign({}, state, {
      unitSkill: Object.assign({}, state.unitSkill, {
        isLikingFile: {
          status: false,
          id: null,
        },
        data: Object.assign({}, data, {
          files: [
            ...files.slice(0, fileIndex),
            Object.assign({}, updatedFileObject),
            ...files.slice(fileIndex + 1),
          ],
        }),
      }),
    });
  }

  case UNLIKE_UNIT_SKILL_FILE_SUCCESS: {
    const { isLikingFile, data } = state.unitSkill;
    const { files } = data;
    const fileIndex = files.findIndex(file => file.id === isLikingFile.id);
    const updatedFileObject = Object.assign({}, files[fileIndex], {
      liked: false,
      like_count: files[fileIndex].like_count - 0,
    });

    return Object.assign({}, state, {
      unitSkill: Object.assign({}, state.unitSkill, {
        isLikingFile: {
          status: false,
          id: null,
        },
        data: Object.assign({}, data, {
          files: [
            ...files.slice(0, fileIndex),
            Object.assign({}, updatedFileObject),
            ...files.slice(fileIndex + 1),
          ],
        }),
      }),
    });
  }

  case LIKE_UNIT_SKILL_FILE_FAILURE:
  case UNLIKE_UNIT_SKILL_FILE_FAILURE:
    return Object.assign({}, state, {
      unitSkill: Object.assign({}, state.unitSkill, {
        isLikingFile: {
          status: false,
          id: null,
        },
      }),
    });

  case LINK_EVIDENCE_FILES_REQUEST:
    return Object.assign({}, state, {
      unitSkill: {
        ...state.unitSkill,
        isUpdating: { status: true, index: action.index }
      }
    });

  case LINK_EVIDENCE_FILES_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, state.unitSkill.isUpdating.index),
        action.data,
        ...state.data.slice(state.unitSkill.isUpdating.index + 1)
      ],
      unitSkill: {
        ...state.unitSkill,
        data: action.data,
        isUpdating: { status: false, index: null }
      }
    });

  case LINK_EVIDENCE_FILES_FAILURE:
    return Object.assign({}, state, {
      unitSkill: {
        ...state.unitSkill,
        error: action.error,
        isUpdating: { status: false, index: false }
      }
    });

  case REMOVE_EVIDENCE_FILE_REQUEST:
    return Object.assign({}, state, {
      unitSkill: {
        ...state.unitSkill,
        isUpdating: { status: true, id: action.id }
      }
    });

  case REMOVE_EVIDENCE_FILE_SUCCESS:
    return Object.assign({}, state, {
      unitSkill: {
        ...state.unitSkill,
        data: {
          ...state.unitSkill.data,
          files: state.unitSkill.data.files.filter(file => file.id !== state.unitSkill.isUpdating.id)
        },
        isUpdating: { status: false, id: null }
      }
    });

  case REMOVE_EVIDENCE_FILE_FAILURE:
    return Object.assign({}, state, {
      unitSkill: {
        ...state.unitSkill,
        error: action.error,
        isUpdating: { status: false, id: null }
      }
    });

  default:
    return state;
  }
}
