import { CALL_API } from '../../middlewares/api';

import {
  STATES_REQUEST,
  STATES_SUCCESS,
  STATES_FAILURE
} from '../../constants';

export const getStates = (countryId, page = 1, query = '') => {
  let endpoint = `countries?countryId=${countryId}&page=${page}`;

  if (query.length > 0) {
    endpoint = `${endpoint}&query=${query}`;
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [
        STATES_REQUEST,
        STATES_SUCCESS,
        STATES_FAILURE
      ]
    }
  };
};
