import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withHooks } from '../../utils/withHooks';

import * as componentsActions from '../../redux/actions/components';

import PropTypes from 'prop-types';

import HeaderComponent from '../../components/navbar/HeaderComponent';

class Header extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    toggleLoginModal: PropTypes.func.isRequired,
    toggleRequestInvite: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    redirectToSearch: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      placeholder: 'Search',
      inputIcon: 'fa fa-bars',
      isClicked: 'Dashboard',
      showNav: false,
      showNavHomepage: false,
      isTalentDevelopersOpen: false,
      isTalentEmployersOpen: false
    };

    this.onCloseWindow = this.onCloseWindow.bind(this);
    this.toggleTalentDevelopers = this.toggleTalentDevelopers.bind(this);
    this.toggleNavHomepage = this.toggleNavHomepage.bind(this);
    this.onSearchIconClicked = this.onSearchIconClicked.bind(this);
    this.toggleTalentEmployers = this.toggleTalentEmployers.bind(this);
    this.openRecruiterModal = this.openRecruiterModal.bind(this);
  }

  openRecruiterModal(event) {
    event.preventDefault();
    this.props.actions.openModal('recruiter-list-modal');
  }

  toggleNavHomepage(){
    this.setState({
      showNavHomepage: !this.state.showNavHomepage
    });
  }

  onCloseWindow(){
    window.close();
  }

  onSearchIconClicked(){
    const { location: { pathname } } = this.props;

    if (pathname.includes('search'))
      return;

    this.props.redirectToSearch();
  }

  toggleTalentDevelopers() {
    this.setState({ isTalentDevelopersOpen: ! this.state.isTalentDevelopersOpen });
  }

  toggleTalentEmployers() {
    this.setState({ isTalentEmployersOpen: ! this.state.isTalentEmployersOpen });
  }

  render() {
    return (
      <HeaderComponent
        {...this.props}
        {...this.state}
        toggleNavbar={this.toggleNavbar}
        onCloseWindow={this.onCloseWindow}
        toggleNavHomepage={this.toggleNavHomepage}
        onSearchIconClicked={this.onSearchIconClicked}
        toggleTalentDevelopers={this.toggleTalentDevelopers}
        toggleTalentEmployers={this.toggleTalentEmployers}
        openRecruiterModal={this.openRecruiterModal}/>
    );
  }
}

const mapDispatchToProps = dispatch =>
  ({ actions: bindActionCreators(componentsActions, dispatch) });

export default withHooks(connect(null, mapDispatchToProps)(Header));
