import PropTypes from 'prop-types';
import React from 'react';

const AvatarCard = ({ profile }) => {
  return (
    <div className="follower-image">
      {
        profile.avatar && profile.avatar.length !== 0 ?
          <img 
            className="img-responsive header-image"
            src={profile.avatar} 
            alt={`${profile.name}'s Avatar`}/>:
          <span
            style={{
              width: '40px',
              height: '40px',
              fontSize: 18,
              marginRight: '10px',
              borderRadius: '50%',
              lineHeight: '40px',
              display: 'inline-block'
            }}
            className="img-responsive initial-avatar">
            {profile.name.slice(0,1)}
          </span>
      }
    </div>
  );
};

AvatarCard.propTypes = {
  profile: PropTypes.object
};

export default AvatarCard;
