import PropTypes from 'prop-types';
import React from 'react';
import Parser from 'html-react-parser';

const styles = {
  answerWrapper: {
    borderTop: '1px solid #DDDDDD',
    marginRight: -19,
    marginLeft: -19,
    padding: 10
  },
  deleteButton: {
    cursor: 'pointer',
    color: '#f00'
  },

  editButton: {
    marginRight: 10,
    cursor: 'pointer'
  },

  buttonWrapper: {
    fontSize: 10,
    position: 'absolute',
    right: 20,
    top: 17
  },

  listGroupItem: { paddingBottom: 0 },

  formLabel: {
    paddingLeft: 10
  }
};

const ThinkingBreakAnswer = ({
  item,
  index,
  checked,
  onChange,
  onDeleteListItem,
  onEditListItem
}) => {
  return (
    <li className="list-group-item item" id={item.itemId}>
      <div className="row">
        <div className="col-sm-12">
          <label 
            className="form-label" 
            style={styles.formLabel}>
            {`Answer #${index + 1}`}
          </label>
          <label className="float-left">
            <input
              type="radio"
              name="correct"
              value={item.value}
              onChange={onChange}
              checked={checked}/>
          </label>
        </div>
      </div>
      <div className="row" style={styles.answerWrapper}>
        <div className="col-sm-12" style={{marginBottom: 15}}>
          {Parser(item.value)}
        </div>
        <div className="col-sm-12">
          {Parser(item.feedback)}
        </div>
        <span id={item.itemId} style={styles.buttonWrapper}>
          <i
            className="fa fa-pencil"
            style={styles.editButton}
            onClick={onEditListItem}/>
          <i
            className="fa fa-close"
            style={styles.deleteButton}
            onClick={onDeleteListItem}/>
        </span>
      </div>
    </li>
  );
};

ThinkingBreakAnswer.propTypes = {
  item: PropTypes.object,
  errors: PropTypes.object,
  checked: PropTypes.bool,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onEditListItem: PropTypes.func.isRequired,
  onDeleteListItem: PropTypes.func.isRequired
};

export default ThinkingBreakAnswer;
