import React from 'react';

import PropTypes from 'prop-types';

import moment from 'moment';
import { isArray } from 'lodash';

import { checkHttp } from '../../../utils';

const styles = {
  editButton: {
    marginLeft: 10,
    cursor: 'pointer',
  }
};

const CertificationCard = ({
  certification,
  onEditCertificationClick,
  isPublicProfile,
  onDisplayEvidence
}) => {
  const startDate = moment(certification.startdate.date).format('MMMM YYYY');
  let endDate;

  if (certification.noend) {
    endDate = 'Present';
  } else {
    endDate = moment(certification.enddate.date).format('MMMM YYYY');
  }

  const urlAvailable = certification.url !== '';
  const urlString = checkHttp(certification.url) ?
    certification.url : `//${certification.url}`;

  return (
    <div className="card-block">
      <div className="certifications">
        <div className="row">
          <div className="col-lg-12">
            <div className="display">
              <div className="item">
                <div className="title">
                  {isArray(certification.evidence) ?
                    certification.name :
                    <a href="#"
                      onClick={onDisplayEvidence(certification.evidence)}>
                      {certification.name}
                    </a>}
                  {! isPublicProfile ?
                    <i
                      style={styles.editButton}
                      onClick={onEditCertificationClick}
                      id={`update-certification-form-${certification.id}`}
                      className="fa fa-xs fa-pencil orange" aria-hidden="true"/> : ''}</div>
                <div className="authority">
                  {urlAvailable ?
                    <a href={urlString} target="_blank" rel="noopener noreferrer" className="">
                      {`${certification.authority}`}
                    </a>: `${certification.authority}`}

                  {(certification.license.length !== 0) &&
                    <span className="license">
                      {`, License: ${certification.license}`}
                    </span>}
                </div>
                <div className="date">
                  {startDate}&nbsp;-&nbsp;{endDate}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CertificationCard.propTypes = {
  certification: PropTypes.object.isRequired,
  isPublicProfile: PropTypes.bool.isRequired,
  onEditCertificationClick: PropTypes.func.isRequired,
  onDisplayEvidence: PropTypes.func.isRequired
};

export default CertificationCard;
