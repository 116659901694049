import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'reactstrap';

const img_jobCenter = require('../../assets/images/img-job-search-modal.png');

const JobCenterModal = ({isQACommons, showJobCenterModal, toggleJobCenterModal}) => {
  return (
    <Modal className="custom-md-modal" isOpen={showJobCenterModal} toggle={toggleJobCenterModal} size={'lg'}>
      <div className="modal1" id="skillBadge">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel">Job Center</h4>
            <button type="button" className="close" onClick={toggleJobCenterModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="text-center modal-body">
            <img className="p-3 img-fluid img-responsive" src={img_jobCenter} />
            <p style={{fontSize:14}}>
              {`${isQACommons ? 'From the Job Center, learners can search and save jobs, track their progress from application to offer, and save notes on each job.' : 'From the Job Center, you can search and save jobs, track your progress from application to offer, and save notes on each job.'}`}
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

JobCenterModal.propTypes = {
  isQACommons: PropTypes.bool,
  showJobCenterModal: PropTypes.bool,
  toggleJobCenterModal: PropTypes.func
};

export default JobCenterModal;
