import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Validator from '../../../validator';

import * as associationActions from '../../../redux/actions/associations';
import * as componentsActions from '../../../redux/actions/components';

import Loading from '../../../components/common/Loading';
import AssociationForm from '../../../components/profile/forms/AssociationForm';
import AssociationList from '../../../components/profile/sections/AssociationList';
import ProfileCardHeader from '../../../components/profile/header/ProfileCardHeader';
import formWithModal from '../../../components/profile/modal/formWithModal';
import ConfirmModal from '../../../components/portfolio/ConfirmModal';

class Association extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    association: PropTypes.object.isRequired,
    associations: PropTypes.array.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    isPublicProfile: PropTypes.bool.isRequired,
    showVisibilitySelect: PropTypes.bool,
    onChangeVisibility: PropTypes.func,
    isEditingSections: PropTypes.bool,
    onToggleSectionEdit: PropTypes.func,
    onConfirmDeleteSection: PropTypes.func,
    modal: PropTypes.string,
    isUpdatingProfile: PropTypes.bool,
    profile: PropTypes.object,
    isDeleting: PropTypes.bool.isRequired,
    isUpdating: PropTypes.bool.isRequired,
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      isEditing: false,
      editingId: null,
      isSubmitting: false,
      association: Object.assign({}, this.props.association),
      editorText: { description: this.props.association.description }
    };

    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onDeleteSection = this.onDeleteSection.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.onDeleteAssociation = this.onDeleteAssociation.bind(this);
    this.onEditAssociation = this.onEditAssociation.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
  }

  componentDidMount() {
    const { profile } = this.props;
    this.props.actions.associationsRequest(profile.id);
  }

  formatEndDate = (dateString) => {
    return dateString === '' ? null : dateString;
  }

  sortAssociation = (associations) => {
    const newAssociation = [].concat(associations);
    return newAssociation.sort(function(a, b){
      return new Date(b.enddate.date) - new Date(a.enddate.date);
    });
  }

  isValid(field = null) {
    const { noend, startdate } = this.state.association;
    let rules = {};
    if (noend === true) {
      rules = {
        name: ['required', 'minLength|6', 'maxLength|100'],
        position: ['maxLength|200'],
        url: ['unsafeurl'],
        startdate: ['required', 'startdate'],
      };
    } else {
      rules = {
        name: ['required', 'minLength|6', 'maxLength|100'],
        position: ['maxLength|200'],
        url: ['unsafeurl'],
        startdate: ['required', 'startdate'],
        enddate: ['required', 'startdate', `beforeStart|${this.formatEndDate(startdate)}`],
      };
    }

    const { association, errors: prevErrors } = this.state;
    const validate = Validator.createValidator(rules, association, field);
    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    if (noend === true) {
      delete prevErrors['enddate'];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  isValidDescription(field = null) {
    const rules = {
      description: ['maxWords|200']
    };
    const { editorText, errors: prevErrors } = this.state;
    const validate = Validator.createValidator(rules, editorText, field);
    const { errors, isValid } = validate;

    if (field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }
    this.setState({ errors: Object.assign({}, prevErrors, errors) });

    return isValid;
  }

  onChange(event) {
    let { name, type, checked, value } = event.target;
    const { association } = this.state;

    value = type === 'checkbox' ? checked : value;

    association[name] = value;

    this.setState({ association }, () => this.isValid(name));

    if (name === 'startdate') {
      this.isValid('enddate');
    }

  }

  onChangeDescription(value, ...params) {
    const { association, editorText} = this.state;

    association['description'] = value;
    editorText['description'] = params[2].getText().replace(/\r?\n|\r/gm, ' ');

    this.setState({ association, editorText }, () => this.isValidDescription('description'));
  }

  onSave(event) {
    event.preventDefault();

    this.setState({ isSubmitting: true });

    if (! this.isValid() || ! this.isValidDescription()) {
      return this.setState({ isSubmitting: false });
    }

    const { association, editingId } = this.state;
    const { actions, associations } = this.props;

    if(editingId !== null){
      const index = associations.findIndex(element =>
        element.id === association.id);

      return actions.updateAssociation(index, association)
        .then(() => this.handleResponse());
    }

    this.props.actions.saveAssociation(association)
      .then(() => this.handleResponse());
  }

  onCancel() {
    this.setState({
      isEditing: false,
      errors: {},
      editingId: null,
      association: this.props.association
    });
    this.props.onToggleSectionEdit('association');
  }

  onEditClick(event) {
    event.preventDefault();

    this.setState({ isEditing: true });
    this.props.onToggleSectionEdit('association');
  }

  onEditAssociation(id){
    const { associations } = this.props;
    const assocObject = associations.find(assoc => assoc.id === id);
    const association = Object.assign({}, this.state.association, assocObject);
    this.setState({
      isEditing: true,
      association,
      editingId: id
    }, () => this.props.onToggleSectionEdit('association'));
  }

  onDeleteSection(){
    this.props.actions.openModal('delete-webpage-association-section-modal');
  }

  onCloseModal(){
    this.props.actions.closeModal();
  }

  onDeleteConfirm(){
    const { associations, actions } = this.props;

    associations.map(association => actions.deleteAssociation(association.id));
    this.props.onConfirmDeleteSection('Association');
  }

  onDeleteAssociation(event) {
    event.preventDefault();

    const { association } = this.state;
    this.props.actions.deleteAssociation(association.id)
      .then(() => this.handleResponse());
  }

  handleResponse() {
    this.setState({
      isEditing: false,
      isSubmitting: false,
      editingId: null,
      association: this.props.association
    }, () => this.props.onToggleSectionEdit('association'));
  }

  EditingAssociation = formWithModal(AssociationForm);

  render() {
    const {
      modal,
      associations,
      isRequesting,
      isPublicProfile,
      showVisibilitySelect,
      onChangeVisibility,
      isEditingSections,
      isUpdatingProfile,
      isDeleting,
      isUpdating
    } = this.props;
    const { errors, association, isEditing, isSubmitting, editingId } = this.state;

    if(associations.length === 0 && isPublicProfile){
      return null;
    }

    const isDeleteConfirmModalOpen = modal && modal === 'delete-webpage-association-section-modal';

    return (
      <div className="profile-item associations">
        <ProfileCardHeader
          isEditing={isEditing}
          isEditingSections={isEditingSections}
          type="list"
          title="Associations"
          onEditClick={this.onEditClick}
          isPublicProfile={isPublicProfile}
          showVisibilitySelect={showVisibilitySelect}
          onDeleteClick={this.onDeleteSection}
          onChangeVisibility={onChangeVisibility}
          icon="associations"
          isDeletable/>

        {isRequesting ?
          <Loading/> :
          isEditing ?
            <div className="profile-item__card">
              <this.EditingAssociation
                errors={errors}
                association={association}
                isSubmitting={isSubmitting}
                isUpdating={isUpdating}
                isDeleting={isDeleting}
                showDelete={editingId !== null}
                onChange={this.onChange}
                onSave={this.onSave}
                onCancel={this.onCancel}
                exampleType={'Associations'}
                onDelete={this.onDeleteAssociation}
                onChangeDescription={this.onChangeDescription}/>
            </div> :
            <AssociationList
              associations={this.sortAssociation(associations)}
              onEdit={this.onEditAssociation}
              isPublicProfile={isPublicProfile}/>}
        {
          isDeleteConfirmModalOpen &&
            <ConfirmModal
              modalHeaderText="Delete Section"
              modalMessage="Are you sure you want to permanently delete this Career Site section?"
              modalFooterButtonText="Yes, Delete"
              onCancel={this.onCloseModal}
              onConfirm={this.onDeleteConfirm}
              isConfirming={isUpdatingProfile}
              confirminginProgressText="Deleting..."
              isOpen={isDeleteConfirmModalOpen}/>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const association = {
    startdate: '',
    enddate: '',
    noend: false,
    name: '',
    position: '',
    url: '',
    description: ''
  };

  return {
    association,
    modal: state.components.modal,
    associations: state.associations.data,
    isUpdatingProfile: state.profile.isUpdating,
    isRequesting: state.associations.isRequesting,
    isDeleting: state.associations.isDeleting.status,
    isUpdating: state.associations.isUpdating.status
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, associationActions, componentsActions);
  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Association);
