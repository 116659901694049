import PropTypes from 'prop-types';
import React from 'react';

import UnitFileCommentHandler from '../../containers/portfolio/UnitFileCommentHandler';

const FileComments = ({ comments, profile, fileId }) => {
  if(comments.length === 0)
    return null;

  return (
    <div className="forums">
      <div className="post">
        {comments.map(comment =>
          (<UnitFileCommentHandler
            key={comment.id}
            profile={profile}
            fileId={fileId}
            comment={comment}/>))}
      </div>
    </div>
  );
};

FileComments.propTypes = {
  comments: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
  fileId: PropTypes.string,
};

export default FileComments;
