import React, {useState} from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import Validator from '../../validator';
import { Modal, ModalBody, ModalFooter, Label, Input, Button } from 'reactstrap';

import _isEmpty from 'lodash/isEmpty';

import { requestReviseTestimonial } from '../../redux/actions/testimonials';
import CloseIcon from '../../assets/images/close.svg';

function RequestTestimonialModal({ isOpen, toggleModal, testimonialData }) {
  const dispatch = useDispatch();
  const [testimonial, setTestimonial] = useState();
  const [errors, setErrors] = useState();

  function handleTestimonial(e){
    setTestimonial(e.target.value);
  }

  function validateTestimonial(field = null){
    const rules = {
      testimonial: [
        'required','minLength|1', 'maxLength|200'
      ]
    };
    const validate = Validator.createValidator(rules, {testimonial}, field);
    return validate;
  }

  function sendReviseTestimonial(){  
    const { errors, isValid } = validateTestimonial();

    if(!isValid){
      if(errors?.testimonial === 'Required'){
        errors['testimonial']= 'The message cannot be blank';
      }if(errors?.testimonial === ' Must be no more than 200 characters'){
        errors['testimonial']= 'The message should not have more than 200 word';
      }  
      setErrors( errors );      
      return;
    }    
    
    const data = {
      testimonialId :  testimonialData.id,
      message : testimonial,
    };
    
    dispatch(requestReviseTestimonial(data)).then( (res) => {
      if(res.testimonials?.requestMessage?.status === 200)
        toastr.success(res.testimonials?.requestMessage.detail);
      toggleModal();  
    });    
  }

  return (
    <Modal
      centered
      className="customLevelUpModal"
      isOpen={isOpen}
      toggle={toggleModal}>
      <div className="modal-header">
        <span className="modal-title"> Ask {testimonialData?.giverName?.split(' ')[0]} for a Revision</span>
        <div
          onClick={toggleModal}
          className="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">
            <img src={CloseIcon} />
          </span>
        </div>    
      </div>

      <ModalBody>
        <div className="d-flex align-items-center flex-column">
          <Label
            for="feedbackComment">
            <strong className="font16 mt-2">               
              Include a personalized message about what revisions you’re asking for
            </strong>
          </Label>
          <Input
            type="textarea"
            placeholder=""
            name="testimonial"
            value={testimonial}
            onChange={handleTestimonial}
            invalid={!_isEmpty(errors) && errors.testimonial}
            id="feedbackComment"/>
          <div className="form-control-feedback">
            {errors?.testimonial}
          </div>
        </div>        
      </ModalBody>
      <ModalFooter className="bordertop-0">
        <button
          type="button"
          onClick={toggleModal}
          className="btn btn-secondary">
          Cancel
        </button>
        <Button className="ml-1 sendButton" onClick={sendReviseTestimonial}>
          Send
        </Button>
      </ModalFooter>
    </Modal>
  );
}

RequestTestimonialModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  testimonialData: PropTypes.object.isRequired,
};

export default RequestTestimonialModal;
