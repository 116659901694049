import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from '../../components/common/Button';

const ReflectionGuidanceModal = ({ isOpen, toggleReflectionGuidanceModal }) => {
  return (
    <Modal 
      isOpen={isOpen}
      backdrop="static"
      size="lg"
      toggle={toggleReflectionGuidanceModal}
      className="reflection-guidance-modal">
      <ModalHeader toggle={toggleReflectionGuidanceModal}>Tips for a Thoughtful Response</ModalHeader>
      <ModalBody>
        <h6 className="font-weight-bold">My response…</h6>
        <ul className="checkmark">
          <li>Connects directly with what’s being asked</li>
          <li>Gives details to indicate deep understanding of the question</li>
          <li>Is based on my experiences, attitudes, beliefs, behaviors or emotions</li>
          <li>Is not a one-word answer or a few short statements</li>
          <li>Is specific, not vague or general</li>
          <li>Doesn’t have typos or writing errors</li>
        </ul>
        <h6 className="font-weight-bold">How does reflection help me?</h6>
        <p>It helps you recognize skills you may not realize you have, how you’ve applied skills in your past, how you’ve overcome obstacles, what you’ve learned from your experiences, and/or how you can apply what you’ve learned toward the future. Practicing reflection can improve your critical thinking and communication skills over time and help you grow more confident talking about your skills. This can help you in job interviews, networking conversations, or even job performance reviews.</p>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={toggleReflectionGuidanceModal}
          buttonClass="btn-primary"
          styles={{marginLeft: 0}}
          buttonText="Close"/>
      </ModalFooter>
    </Modal>
  );
};

ReflectionGuidanceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleReflectionGuidanceModal: PropTypes.func.isRequired
};

export default ReflectionGuidanceModal;
