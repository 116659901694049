import React from 'react';

import { connect } from 'react-redux';

import {
  selectIsRequestingJobMatches
} from '../../redux/selectors/job-matches';

import {
  selectJobMatchSettingsObject
} from '../../redux/selectors/job-match-settings';

import PropTypes from 'prop-types';

import Loading from '../common/Loading';
import JobMatchCard from '../../components/jobs/JobMatchCard';

const JobMatchesResults = ({ wallPosts, callingAPI, isRequesting, jobMatchSettings }) => {
  if ((!callingAPI) && (!isRequesting) && wallPosts.length === 0) {
    return (
      <div className="jobmatch-grid">
        <p>No jobs to show based on filters selected.</p>
      </div>
    );
  }

  return (
    <div className="jobmatch-grid">
      {wallPosts.map(job =>
        <JobMatchCard key={job.jobkey} job={job} />)}

      {(callingAPI || isRequesting) &&
        <div className="job-loading">
          <Loading />

          {(!jobMatchSettings.initialSearchRequest) &&
            <h5>Just a sec...we're scanning the internet to find jobs!</h5>}
        </div>}
    </div>
  );
};

JobMatchesResults.propTypes = {
  wallPosts: PropTypes.array.isRequired,
  callingAPI: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  jobMatchSettings: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  isRequesting: selectIsRequestingJobMatches(state),
  jobMatchSettings: selectJobMatchSettingsObject(state)
});

export default connect(mapStateToProps)(JobMatchesResults);
