import PropTypes from 'prop-types';
import React from 'react';

import Button from '../common/Button';
import classNames from 'classnames';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

const visibilityOptions = [
  {id: 'public', name: 'Public', icon: 'fa fa-globe mr-1', desc: 'viewable to anyone' },
  {id: 'followers', name: 'Followers Only', icon: 'fa fa-user-circle-o mr-1' },
  {id: 'private', name: 'Private', icon: 'fa fa-lock mr-1', desc: 'viewable only to me' },
];

const FileFormButtons = ({
  onSaveSupportingEvidence,
  onCancel,
  files,
  unitSkill,
  updateCard,
  selectedVisibiltyId,
  onToggleVisibilityDropdown,
  onSelectVisibilty,
  visibilityDropdownOpen,
  seletedSkillsOrBadges
}) => {
  const visibiltyId = selectedVisibiltyId === '' ? 'public' : selectedVisibiltyId;
  const { isUpdating, isUploading } = files;
  const { isUpdating: isUpdatingUnitSkill } = unitSkill;

  const updateButton = (
    isUpdating || isUpdatingUnitSkill.status ? 'Updating...' : 'Update'
  );

  const saveButton = (isUploading.status ? 'Saving...' : 'Save Portfolio File');
  const buttonText = (updateCard ? updateButton : saveButton);
  const selectedVisibilty = visibilityOptions.find(option => option.id == visibiltyId) ;

  return (
    <div className="portfolio-file-modal-footer">
      {seletedSkillsOrBadges &&
        <div className="portfolio-upload-note">
          <p>
            Portfolio files must be public when linked to Skill Badges or Career Site Skills as evidence.
            If you want a file to be <strong>Private</strong>, uncheck the badges and skills it supports.
          </p>
        </div>}
      <div>
        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'flex-end'}}>
          <Button
            type="button"
            onClick={onCancel}
            disabled={isUploading.status || isUpdating}
            buttonClass="btn-secondary ml-0"
            buttonText="Cancel"/>

          <div className="portfolio-files-visibility" style={{ marginLeft: 10 }}>
            {
              seletedSkillsOrBadges &&
                <div className="dropdown">
                  <span className="dropdown-selected disabled-dropdown">
                    <i className="fa fa-globe mr-1"/>Public
                  </span>
                </div>
            }
            {! seletedSkillsOrBadges &&
              <Dropdown
                toggle={onToggleVisibilityDropdown}
                isOpen={visibilityDropdownOpen}>
                <DropdownToggle color="tertiary">
                  <span className="dropdown-selected">
                    <i className={selectedVisibilty.icon}/>{selectedVisibilty.name}
                  </span>
                </DropdownToggle>
                <DropdownMenu>
                  {visibilityOptions.map(option =>
                    (<a
                      key={option.id}
                      className={classNames(
                        'dropdown-item',
                        { 'active-visibilty': option.id == selectedVisibiltyId}
                      )}
                      onClick={onSelectVisibilty(option.id)}>
                      <i className={option.icon}/>{option.name}
                      {option.desc && <span>&nbsp;{option.desc}</span>}
                    </a>))}
                </DropdownMenu>
              </Dropdown>
            }
          </div>
          <Button
            type="submit"
            onClick={updateCard ? updateCard.onUpdate : onSaveSupportingEvidence}
            disabled={updateCard ? isUpdating : isUploading.status}
            buttonClass="btn-primary"
            buttonText={buttonText}/>
        </div>
      </div>
    </div>
  );
};

FileFormButtons.propTypes = {
  onSaveSupportingEvidence: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  files: PropTypes.object.isRequired,
  updateCard: PropTypes.object,
  seletedSkillsOrBadges: PropTypes.bool,
  selectedVisibiltyId: PropTypes.string.isRequired,
  visibilityDropdownOpen: PropTypes.bool.isRequired,
  onToggleVisibilityDropdown: PropTypes.func.isRequired,
  onSelectVisibilty: PropTypes.func.isRequired,
  unitSkill: PropTypes.object
};

export default FileFormButtons;
