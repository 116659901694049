import initialState from './initialState';

import {
  FETCH_JOB_NOTES_REQUEST,
  FETCH_JOB_NOTES_SUCCESS,
  FETCH_JOB_NOTES_FAILURE,
  ADD_JOB_NOTE_REQUEST,
  ADD_JOB_NOTE_SUCCESS,
  ADD_JOB_NOTE_FAILURE,
  EDIT_JOB_NOTE_REQUEST,
  EDIT_JOB_NOTE_SUCCESS,
  EDIT_JOB_NOTE_FAILURE,
  DELETE_JOB_NOTE_REQUEST,
  DELETE_JOB_NOTE_SUCCESS,
  DELETE_JOB_NOTE_FAILURE,
} from '../../constants';

const editNote = (state, action) => {
  const { data, isUpdating } = state;
  const indexOfJob = data.findIndex(item => item.id === isUpdating.id);

  return {
    ...state,
    data: [
      ...data.slice(0, indexOfJob),
      action.data,
      ...data.slice(indexOfJob + 1),
    ],
  };
};

export default function reducer(state = initialState.jobNotes, action) {
  switch (action.type) {
  case FETCH_JOB_NOTES_REQUEST:
    return Object.assign({}, state, {
      isRequesting: true,
    });

  case FETCH_JOB_NOTES_SUCCESS:
    return Object.assign({}, state, {
      data: action.data._embedded.note,
      isRequesting: false,
    });

  case FETCH_JOB_NOTES_FAILURE:
    return Object.assign({}, state, {
      data: [],
      error: action.error,
      isRequesting: false,
    });

  case ADD_JOB_NOTE_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: true,
    });

  case ADD_JOB_NOTE_SUCCESS:
    return Object.assign({}, state, {
      data: [Object.assign({}, action.data), ...state.data],
      isSubmitting: false,
    });

  case ADD_JOB_NOTE_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case EDIT_JOB_NOTE_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, id: action.id },
    });

  case EDIT_JOB_NOTE_SUCCESS:
    return Object.assign({}, state, {
      ...editNote(state, action),
      isUpdating: { status: false, id: null },
    });

  case EDIT_JOB_NOTE_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false, id: null },
    });
  case DELETE_JOB_NOTE_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: action.id },
    });

  case DELETE_JOB_NOTE_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data.filter(item => item.id !== state.isDeleting.id)],
      isDeleting: { status: false, id: null },
    });

  case DELETE_JOB_NOTE_FAILURE:
    return Object.assign({}, state, {
      isDeleting: { status: false, id: null },
      error: action.error,
    });

  default:
    return state;
  }
}
