import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as sectionsActions from '../../redux/actions/sections';

import { Outlet } from 'react-router-dom';
import SidePanel from '../../components/skill-builders/admin/SidePanel';

class SkillBuilderAdmin extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    skillBuilders: PropTypes.array.isRequired
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      skillBuilders: [
        ...this.props.skillBuilders
      ]
    };

    this.onSearch = this.onSearch.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ skillBuilders: nextProps.skillBuilders });
  }

  getUnitBySlug(slug, units) {
    const unit = units.filter(unit => unit.slug === slug);

    if (unit) return unit[0];

    return null;
  }

  onSearch(event) {
    const { skillBuilders } = this.props;
    let value = event.target.value;

    if (value.length === 0) return this.setState({ skillBuilders });

    const units = skillBuilders.filter(unit => unit.title.includes(value));

    this.setState({ skillBuilders: units });
  }

  render() {
    return (
      <div className="container">
        <Helmet title="Admin"/>
        <div className="row mt-4">
          <div className="col-sm-4 col-lg-3">
            <SidePanel
              onSearch={this.onSearch}
              isRequesting={this.props.isRequesting}
              skillBuilders={this.state.skillBuilders}/>
          </div>
          <div className="col-sm-8 col-lg-9">
            <Outlet/>
          </div>
        </div>
      </div>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    skillBuilders: state.skillBuilders.data,
    isRequesting: state.skillBuilders.isRequesting
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(sectionsActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillBuilderAdmin);
