import initialState from './initialState';

import {
  ORGANIZATION_GROUP_STATS_REQUEST,
  ORGANIZATION_GROUP_STATS_SUCCESS,
  ORGANIZATION_GROUP_STATS_FAILURE,
  ORGANIZATION_INSTITUTION_STATS_REQUEST,
  ORGANIZATION_INSTITUTION_STATS_SUCCESS,
  ORGANIZATION_INSTITUTION_STATS_FAILURE,
  SET_SELECTED_INSTITUTION_INDEX,
  SET_NUMBER_OF_GROUPS_TO_CREATE,
} from '../../constants';

export default function reducer(
  state = initialState.organizationStats,
  action
) {
  switch (action.type) {
  case SET_SELECTED_INSTITUTION_INDEX:
    return {
      ...state,
      selectedInstitionIndex: action.index,
    };

  case SET_NUMBER_OF_GROUPS_TO_CREATE:
    return {
      ...state,
      numberOfGroupsToCreate: action.numberOfGroupsToCreate,
    };

  case ORGANIZATION_GROUP_STATS_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };

  case ORGANIZATION_GROUP_STATS_SUCCESS:
    return {
      ...state,
      data: action.data,
      isRequesting: false,
    };

  case ORGANIZATION_GROUP_STATS_FAILURE:
    return {
      ...state,
      error: action.error,
      isRequesting: false,
    };

  case ORGANIZATION_INSTITUTION_STATS_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };

  case ORGANIZATION_INSTITUTION_STATS_SUCCESS:
    return {
      ...state,
      data: action.data,
      isRequesting: false,
    };

  case ORGANIZATION_INSTITUTION_STATS_FAILURE:
    return {
      ...state,
      error: action.error,
      isRequesting: false,
    };

  default:
    return state;
  }
}
