import PropTypes from 'prop-types';
import React from 'react';

import Button from '../../common/Button';
import QuizAnswer from './QuizAnswer';
import QuizSectionAnswerForm from '../../../containers/skill-builders/admin/QuizSectionAnswerForm';
import SectionQuestionForm from '../../../containers/skill-builders/admin/SectionQuestionForm';
import RichEditor from '../../../plugins/RichEditor';

const blockTypes = [
  { label: 'Link', style: 'LINK', icon: 'fa-link' },
];

const inlineTypes = [
  { label: 'Bold', style: 'BOLD', icon: 'fa-bold'},
  { label: 'Italic', style: 'ITALIC', icon: 'fa-italic' },
  { label: 'Underline', style: 'UNDERLINE', icon: 'fa-underline' },
];

const styles = {
  editorWrapper: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    padding: '.5rem .5rem',
    borderRadius: 4,
    marginBottom: '1rem'
  },
  withError: {
    border: '1px solid #d9534f',
    padding: '.5rem .5rem',
    borderRadius: 4,
  },
  addListItemGroup: {
    position: 'absolute',
    right: 10,
    fontSize: '.8rem'
  },
  errorText: {
    fontSize: 14,
    color: '#d9534f'
  },
  cardWrapper: { marginBottom: 15 },
  panelHeading: { minHeight: 30, backgroundColor: '#fafafa', marginBottom: 10 },
  heading: { textAlign: 'center', paddingRight: 40, color: 'black', paddingTop: 10 }
};

const QuizForm = ({
  isAdmin,
  onCancel,
  errors,
  section,
  onChange,
  onSave,
  onDelete,
  isSubmitting,
  isUpdating,
  isDeleting,
  formType,
  onDeleteListItem,
  onEditListItem,
  editingItemId,
  setEditorState,
  getEditorState,
  onAddOption,
  onEditOption,
  onChangeQuestion
}) => {
  const formId = formType && `${formType.formName}_${formType.id}`;
  const wrapperStyle = errors.feedback ? styles.withError : styles.editorWrapper;

  const quizAnswer = section.content.map((item, index) => {
    const checked = section.correct === index;

    return (
      <QuizAnswer
        key={index}
        index={index}
        item={item}
        checked={checked}
        onChange={onChange}
        error={errors[`content[${index}].value`]}
        onEditListItem={onEditListItem}
        onDeleteListItem={onDeleteListItem}/>
    );
  });

  return (
    <div>
      {isAdmin &&
        <div className="card-header" style={styles.panelHeading}>
          <h3 style={styles.heading}>
            Quiz
          </h3>
        </div>}

      <div className="card-block p-3">
        <form id={formId}>
          <SectionQuestionForm
            errors={errors}
            question={section.title}
            onChangeQuestion={onChangeQuestion}/>
          <div className="row">
            <div className="col-sm-12">
              <label htmlFor="feedback">Feedback</label>
              <div style={wrapperStyle}>
                <RichEditor
                  blockTypes={blockTypes}
                  inlineTypes={inlineTypes}
                  placeholder="Feedback"
                  setEditorState={setEditorState}
                  getEditorState={getEditorState}/>
              </div>
              {
                errors.feedback &&
                  <span style={styles.errorText}>{errors.feedback}</span>
              }
            </div>
          </div>
          <QuizSectionAnswerForm
            editingItemId={editingItemId}
            sectionContent={section.content}
            onEditOption={onEditOption}
            onAddOption={onAddOption}/>

          <h5>Quiz Options</h5>
          <hr/>

          <div className="row">
            <div className="col-sm-12">
              {section.content.length === 0 &&
                <div className="search-hint badge-hint">
                  <span className="fa fa-info-circle" aria-hidden="true"/>
                  <p>This section does not have quiz options yet.</p>
                </div>}

              {section.content.length > 0 &&
                <ul className="list-group" style={{marginBottom: 20}}>
                  {quizAnswer}
                </ul>}
            </div>
          </div>

          {!formType &&
            <Button
              type="submit"
              onClick={onDelete}
              disabled={isDeleting}
              buttonClass="btn-danger"
              styles={{marginLeft: 0}}
              buttonText={isDeleting ? 'Deleting...' : 'Delete'}/>}

          <Button
            type="submit"
            onClick={onSave}
            disabled={isSubmitting || isUpdating}
            buttonClass="btn-primary float-right"
            buttonText={isSubmitting || isUpdating ? 'Saving...' : 'Save'}/>

          <Button
            type="button"
            buttonId={section ? `text-list_${section.id}` : ''}
            onClick={onCancel}
            buttonClass="btn-secondary float-right"
            buttonText="Cancel"/>
        </form>
      </div>
    </div>
  );
};

QuizForm.propTypes = {
  section: PropTypes.object,
  errors: PropTypes.object,
  formType: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEditListItem: PropTypes.func.isRequired,
  onDeleteListItem: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  setEditorState: PropTypes.func.isRequired,
  getEditorState: PropTypes.func.isRequired,
  editingItemId: PropTypes.string,
  onAddOption: PropTypes.func.isRequired,
  onChangeQuestion: PropTypes.func.isRequired,
  onEditOption: PropTypes.func.isRequired,
};

export default QuizForm;
