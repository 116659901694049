import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import { toastr } from 'react-redux-toastr';

import * as badgesActions from '../../redux/actions/badges';
import * as badgeAssertionActions from '../../redux/actions/badge-assertion';
import * as componentActions from '../../redux/actions/components';

import LinkingSkillsModal from './LinkingSkillsModal';
import SkillBadgeCardScore from './skillBadgeCardScore';
import Row from 'reactstrap/lib/Row';
import Container from 'reactstrap/lib/Container';

class UnitBadgeSkills extends Component {
  static propTypes = {
    files: PropTypes.array,
    actions: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    badgeAssertion: PropTypes.object.isRequired,
    modal: PropTypes.string,
    skillBadges: PropTypes.array,
    badges: PropTypes.array,
    isBadge: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);

    this.state ={
      showAllSkills: false
    };

    this.onToggleLinkingSkillsModal = this.onToggleLinkingSkillsModal.bind(this);
    this.toggleAllSkills = this.toggleAllSkills.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      !_isEqual(
        this.props.badgeAssertion?.data?.issued?.skills,
        nextProps.badgeAssertion?.data?.issued?.skills
      )
    ) {
      toastr.success('Skills linked succesfully.');
    }
  }

  onToggleLinkingSkillsModal(){
    const { modal, actions, badgeAssertion } = this.props;
    const isSkillsModalOpen = modal && modal === 'skillsModalInBakedBadgePage';
    if(!isSkillsModalOpen){
      const selectedBadges = [{
        id: badgeAssertion.data.badge.id,
        name: badgeAssertion.data.badge.name,
        issued: badgeAssertion.data.issued
      }];

      actions.saveSelectedBadges(selectedBadges);
      return actions.openModal('skillsModalInBakedBadgePage');
    }

    actions.closeModal();
  }

  toggleAllSkills(){
    this.setState({
      showAllSkills: !this.state.showAllSkills
    });
  }

  render() {
    const { showAllSkills } = this.state;
    const { isBadge, modal, badgeAssertion, skillBadges, currentUser } = this.props;
    const isSkillsModalOpen = modal && modal === 'skillsModalInBakedBadgePage';
    let skills = [];
    if(badgeAssertion?.data.issued){
      skills = badgeAssertion?.data.issued.skills;
    }
    const showMoreCondition = skills.length > 5;
    const skillsArray = showMoreCondition && !showAllSkills ? skills.slice(0,5) : skills;
    const isBadgeOwner = currentUser?.id === badgeAssertion?.data?.user?.id;

    return (
      <div className="section-badge">
        <h6 className="d-flex align-items-center">
          {badgeAssertion?.data.issued ? 'Skills Supported by Badge' : 'Badges Supported By Skill'}

          {isBadgeOwner && (
            <a
              className="clickable small ml-3"
              onClick={this.onToggleLinkingSkillsModal}>
              <i style={{ paddingRight: 5 }} className="fa fa-sm fa-pencil" />
              Edit
            </a>
          )}
        </h6>
        <div className="section-badge-content">
          <div className="link-skills-content custom">
            {_isEmpty(skillBadges) && !isBadge && 'No Badge supporting Skill'}
            {skillsArray.length === 0 && (
              <span>
                <Container>
                  <Row>
                    {!_isEmpty(skillBadges) &&
                      skillBadges.map((data, index) => (
                        <SkillBadgeCardScore key={index} data={data} />
                      ))}
                  </Row>
                </Container>
              </span>
            )}
            {
              skillsArray.length === 0 &&
                <span className="font14">No skills linked to this badge yet!</span>
            }
            {skillsArray.map(skill =>
              (<Link
                key={skill.id}
                style={{cursor: 'pointer', maxWidth: 260}}
                to={`/skill-evidence/${skill.id}`}
                className="skill-pill linked">
                {skill.name}
              </Link>))}

            {
              showMoreCondition &&
                <span
                  onClick={this.toggleAllSkills}
                  style={{borderRadius: 10, backgroundColor: '#6dabe4', cursor: 'pointer'}}
                  className="skill-pill">
                  <span style={{ color: 'white' }}>
                    {showAllSkills ? 'show less' : `+${skills.length - 5} more`}
                  </span>
                </span>
            }
          </div>
        </div>
        {isSkillsModalOpen && <LinkingSkillsModal assertion isOpen={isSkillsModalOpen}/>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, badgeAssertion, components } = state;

  return {
    badgeAssertion,
    modal: components.modal,
    currentUser: auth.data.currentUser
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, badgeAssertionActions, componentActions, badgesActions);
  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitBadgeSkills);
