import initialState from './initialState';
import {
  ROUTE_REDIRECT,
  SET_ROUTER_HOOKED_TO_A_ROUTE,
  REMOVE_ROUTER_HOOKED_TO_A_ROUTE,
  STORE_AN_ACTION,
  CLEAR_ALL_ACTIONS_STORED,
} from '../../constants';

export default function reducer(state = initialState.router, action) {
  switch (action.type) {
  case ROUTE_REDIRECT:
    return Object.assign({}, state, action.routes);

  case STORE_AN_ACTION:
    return Object.assign({}, state, {
      storedAction: action.actionName,
    });

  case CLEAR_ALL_ACTIONS_STORED:
    return Object.assign({}, state, {
      storedAction: null,
    });

  case SET_ROUTER_HOOKED_TO_A_ROUTE:
    return Object.assign({}, state, {
      isHookedToARoute: {
        status: true,
        route: action.route,
      },
    });

  case REMOVE_ROUTER_HOOKED_TO_A_ROUTE:
    return Object.assign({}, state, {
      isHookedToARoute: {
        status: false,
        route: null,
      },
    });

  default:
    return state;
  }
}
