import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as registrationActions from '../../redux/actions/registration';
import * as componentsActions from '../../redux/actions/components';
import * as skillBuildersActions from '../../redux/actions/skill-builders';

import { omit } from 'lodash';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { toastr } from 'react-redux-toastr';

import Validator from '../../validator';
import Header from '../../components/theqacommons/Header';
import Footer from '../../components/theqacommons/Footer';
import QACModal from '../../components/theqacommons/QACModal';
import SkillBadgeModal from '../../components/home/SkillBadgeModal';
import ToolboxModal from '../../components/home/ToolboxModal';
import EPortfolioModal from '../../components/home/EPortfolioModal';
import RealisticEndorsementsModal from '../../components/home/RealisticEndorsementsModal';
import SampleWebpageModal from '../../components/home/SampleWebpageModal';
import JobCenterModal from '../../components/home/JobCenterModal';
import SkillBuildersModalContainer from '../home/SkillBuildersModalContainer';

const iframeStyle = {
  border: '14px solid white',
  boxShadow: '0px 0px 4px rgba(0,0,0,.4)',
};

class TheQACommons extends Component {
  static propTypes = {
    request: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    skillBuilders: PropTypes.object.isRequired,
    registration: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      request: Object.assign({}, this.props.request),
      showRequestInviteModal: false,
      mediaDropdownOpen: false,
      whitePapersDropdownOpen: false,
      showSkillBuildersModal: false,
      showSkillBadgeModal: false,
      showToolboxModal: false,
      showEPortfolioModal: false,
      showRealisticEndorsementsModal: false,
      showSampleWebpageModal: false,
      showJobCenterModal: false
    };

    this.onChange = this.onChange.bind(this);
    this.requestInvite = this.requestInvite.bind(this);
    this.toggleRequestInviteModal = this.toggleRequestInviteModal.bind(this);
    this.toggleSkillBuildersModal = this.toggleSkillBuildersModal.bind(this);
    this.toggleSkillBadgeModal = this.toggleSkillBadgeModal.bind(this);
    this.toggleToolboxModal = this.toggleToolboxModal.bind(this);
    this.toggleEPortfolioModal = this.toggleEPortfolioModal.bind(this);
    this.toggleRealisticEndorsementsModal = this.toggleRealisticEndorsementsModal.bind(this);
    this.toggleSampleWebpageModal = this.toggleSampleWebpageModal.bind(this);
    this.toggleJobCenterModal = this.toggleJobCenterModal.bind(this);
    this.toggleMediaDropdown = this.toggleMediaDropdown.bind(this);
    this.toggleWhitePapersDropdown = this.toggleWhitePapersDropdown.bind(this);
    this.refNameField = this.refNameField.bind(this);
  }

  componentDidMount(){
    const { skillBuildersRequest } = this.props.actions;
    skillBuildersRequest();
  }

  refNameField(name) {
    this.name = name;
  }

  isValid(field = null) {
    const { request, errors: prevErrors } = this.state;

    let validate = Validator.createValidator({
      firstName: ['required'],
      lastName: ['required'],
      jobTitle: ['required'],
      email: ['required', 'email'],
      phone: ['required'],
      organization: ['required']
    }, request, field);

    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  onChange(event) {
    const { name, value } = event.target;
    let { request } = this.state;

    request[name] = value;

    this.setState({ request }, () => this.isValid(name));
  }

  requestInvite(event) {
    event.preventDefault();

    let { request } = this.state;

    if (! this.isValid()) return false;

    const firstName = request.firstName.trim();
    const lastName = request.lastName.trim();
    const name = `${firstName} ${lastName}`;

    request.name = name;
    request.page = 'theQAcommons';
    request.type = parseInt(request.type);

    request = omit(request, ['firstName', 'lastName']);

    this.props.actions.requestInvite(request)
      .then(() => {
        this.toggleRequestInviteModal();
        toastr.success('Your request for an invitation was successfully submitted!');
      });
  }

  toggleRequestInviteModal(){
    this.setState({
      showRequestInviteModal: !this.state.showRequestInviteModal
    });
  }

  toggleWhitePapersDropdown() {
    this.setState({ whitePapersDropdownOpen: ! this.state.whitePapersDropdownOpen });
  }

  toggleMediaDropdown() {
    this.setState({ mediaDropdownOpen: ! this.state.mediaDropdownOpen });
  }

  toggleToolboxModal(){
    this.setState({
      showToolboxModal: !this.state.showToolboxModal
    });
  }

  toggleSkillBuildersModal(){
    this.setState({
      showSkillBuildersModal: !this.state.showSkillBuildersModal
    });
  }

  toggleSkillBadgeModal(){
    this.setState({
      showSkillBadgeModal: !this.state.showSkillBadgeModal
    });
  }

  toggleEPortfolioModal(){
    this.setState({
      showEPortfolioModal: !this.state.showEPortfolioModal
    });
  }

  toggleRealisticEndorsementsModal(){
    this.setState({
      showRealisticEndorsementsModal: !this.state.showRealisticEndorsementsModal
    });
  }

  toggleSampleWebpageModal(){
    this.setState({
      showSampleWebpageModal: !this.state.showSampleWebpageModal
    });
  }

  toggleJobCenterModal(){
    this.setState({
      showJobCenterModal: !this.state.showJobCenterModal
    });
  }

  render() {
    const {
      errors,
      request,
      mediaDropdownOpen,
      showRequestInviteModal,
      whitePapersDropdownOpen
    } = this.state;

    const {
      registration,
      skillBuilders: { isRequesting, data: skillBuildersData }
    } = this.props;

    const isOrg = true;
    const isQACommons = true;
    const {
      showToolboxModal,
      showJobCenterModal,
      showSkillBuildersModal,
      showSkillBadgeModal,
      showEPortfolioModal,
      showRealisticEndorsementsModal,
      showSampleWebpageModal
    } = this.state;

    return (
      <div className="for-org non-logged-homepage" id="qa-commons">
        <Helmet title="The QA Commons"/>

        <Header
          mediaDropdownOpen={mediaDropdownOpen}
          toggleMediaDropdown={this.toggleMediaDropdown}
          whitePapersDropdownOpen={whitePapersDropdownOpen}
          toggleWhitePapersDropdown={this.toggleWhitePapersDropdown}/>
        <div className="bg">
          <header>
            <article className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h1 style={{marginBottom:'50px'}}>QA Commons and CareerPrepped Support Institutions in Helping all Learners Develop and Demonstrate EEQs Needed for the Workforce</h1>
                </div>
                <div className="col-lg-6">
                  <p>
                    QA Commons' mission is to ensure all learners are prepared for the changing dynamics of the workforce and economy. We aspire to help all people realize upward mobility by developing a foundation of employability skills needed by all workers in all jobs.
                  </p>
                  <p>
                    To meet our mission, we partnered with CareerPrepped, a lifelong career success system which empowers learners to develop and demonstrate their Essential Employability Qualities (EEQs) with authentic evidence.  CareerPrepped also helps learners stand out in the competitive labor market, exhibit achievements, and evidence continuous skill development.
                  </p>
                </div>
                <div className="col-lg-6 jumbo-image">
                  <div className="hytPlayerWrapOuter" style={iframeStyle}>
                    <div className="hytPlayerWrap embed-responsive embed-responsive-16by9">
                      <iframe className="embed-responsive-item" src={`https://www.youtube.com/embed/f8MO58YT4Xs?rel=0&enablejsapi=1&origin=${window.location.origin}`}/>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </header>

          <div id="qacommons-benefits">
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <h1>
                      CareerPrepped Benefits for the QA Commons' Learner Community:
                    </h1>
                    <div className="row">
                      <div className="col-lg-6">
                        <ul className="fa-ul">
                          <li><span className="fa-li"><i className="fas fa-check"/></span>Helps learners build, prove, and showcase EEQs, and any other skill claims, no matter how skills are acquired (formal or informal learning).</li>
                          <li><span className="fa-li"><i className="fas fa-check"/></span>Provides a system to chronicle lifelong learning and self-improvement.</li>
                          <li><span className="fa-li"><i className="fas fa-check"/></span>Offers instructors plug and play competency-based tools and materials to support self-directed learning or supplement in-class learning.</li>
                        </ul>
                      </div>
                      <div className="col-lg-6">
                        <ul className="fa-ul">
                          <li><span className="fa-li"><i className="fas fa-check"/></span>
                            <p>
                              Cultivates close advising relationships between learners and instructors.
                            </p>
                          </li>
                          <li><span className="fa-li"><i className="fas fa-check"/></span>
                            <p>
                              Offers an integrated Skill Feedback feature that helps learners and educators identify EEQ perception gaps among different stakeholders (peers, educators, employers) to continuously work towards closing those gaps.
                            </p>
                          </li>
                          <li><span className="fa-li"><i className="fas fa-check"/></span>Provides 24/7 mobile-friendly online career support services. </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div id="qacommons-core">
          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h1>CareerPrepped Core Features Include:</h1>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="card">
                        <div onClick={this.toggleSkillBuildersModal}>Skill Builders <i className="fa fa-external-link"/></div>
                        <p>40+ competency-based Skill Builders enable learners to build employability skills necessary for lifelong career success through a continuous process of learning, reflection, and practice.</p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="card">
                        <div onClick={this.toggleSkillBadgeModal}>Skill Badges <i className="fa fa-external-link"/></div>
                        <p>Learners claim open badges that represent their “employability skill” claims learning how to articulate and evidence the skills employers say are hard to find and screen for and growing in demand.</p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="card">
                        <div onClick={this.toggleEPortfolioModal}>Career Portfolio <i className="fa fa-external-link"/></div>
                        <p>Every learner gets unlimited storage to upload multimedia files to prove any skill they claim to have. They can link files to their Skill Badges or skills they indicate in their career site. Portfolio files could represent projects, awards, work samples, credentials, etc.</p>
                      </div>
                    </div>
                    <div className="col-lg-4 ">
                      <div className="card">
                        <div onClick={this.toggleSampleWebpageModal}>Career Site <i className="fa fa-external-link"/></div>
                        <p>The Career Site gives learners a professional web presence. Learners can link their Skill Badges and portfolio files to their listed skills allowing visitors to see evidence of their skill claims.</p>
                      </div>
                    </div>
                    <div className="col-lg-4 ">
                      <div className="card">
                        <div onClick={this.toggleToolboxModal}>Career Toolbox <i className="fa fa-external-link"/></div>
                        <p>The Career Toolbox gives learners access to a Targeted Resume Builder to build custom resumes from their Career Site, Interview Prep Videos to enhance interview skills, and free curated career tools to use on-demand.</p>
                      </div>
                    </div>
                    <div className="col-lg-4 ">
                      <div className="card ">
                        <div onClick={this.toggleJobCenterModal}>Job Center <i className="fa fa-external-link"/></div>
                        <p>The Job Center connects learners with relevant opportunities. It allows learners to search and save jobs, track their progress from application to offer, and save notes on each job.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 text-center">
                  <a
                    className="btn btn-success"
                    style={{marginTop: '50px'}}
                    onClick={this.toggleRequestInviteModal}>
                    Request CareerPrepped Account
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer/>

        {showRequestInviteModal &&
          <QACModal
            showRequestInviteModal={showRequestInviteModal}
            errors={errors}
            request={request}
            onChange={this.onChange}
            refNameField={this.refNameField}
            isRequesting={registration.isRequesting}
            requestInvite={this.requestInvite}
            toggleRequestInviteModal={this.toggleRequestInviteModal} />}
        {showSkillBuildersModal &&
          <SkillBuildersModalContainer
            toggleOrgContent={isOrg}
            isRequesting={isRequesting}
            skillbuilders={skillBuildersData}
            showSkillBuildersModal={showSkillBuildersModal}
            toggleSkillBuildersModal={this.toggleSkillBuildersModal} />
        }
        {showSkillBadgeModal &&
          <SkillBadgeModal
            toggleOrgContent={isOrg}
            showSkillBadgeModal={showSkillBadgeModal}
            toggleSkillBadgeModal={this.toggleSkillBadgeModal} />
        }
        {showToolboxModal &&
          <ToolboxModal
            isQACommons={isQACommons}
            toggleOrgContent={isOrg}
            showToolboxModal={showToolboxModal}
            toggleToolboxModal={this.toggleToolboxModal} />
        }
        {showEPortfolioModal &&
          <EPortfolioModal
            toggleOrgContent={isOrg}
            showEPortfolioModal={showEPortfolioModal}
            toggleEPortfolioModal={this.toggleEPortfolioModal}/>
        }
        {showRealisticEndorsementsModal &&
          <RealisticEndorsementsModal
            toggleOrgContent={isOrg}
            showRealisticEndorsementsModal={showRealisticEndorsementsModal}
            toggleRealisticEndorsementsModal={this.toggleRealisticEndorsementsModal}/>
        }
        {showSampleWebpageModal &&
          <SampleWebpageModal
            toggleOrgContent={isOrg}
            showSampleWebpageModal={showSampleWebpageModal}
            toggleSampleWebpageModal={this.toggleSampleWebpageModal}/>
        }
        {showJobCenterModal &&
          <JobCenterModal
            isQACommons={isQACommons}
            showJobCenterModal={showJobCenterModal}
            toggleJobCenterModal={this.toggleJobCenterModal} />
        }

      </div>

    );
  }
}
const mapStateToProps = ({ skillBuilders, registration }) => {
  const request = {
    firstName: '',
    lastName: '',
    jobTitle: '',
    email: '',
    phone: '',
    organization: '',
    additionalInformation: ''
  };

  return {
    request,
    registration,
    skillBuilders
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({},componentsActions, skillBuildersActions, registrationActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TheQACommons);
