import PropTypes from 'prop-types';
import React from 'react';

const SelectInput = ({
  name,
  label,
  onChange,
  defaultOption,
  value,
  error,
  options,
  className,
  styles,
  id,
  disabled,
  onKeyPress,
  redText,
  fullWidth
}) => {
  let wrapperClass = 'form-group';

  if (error && error.length > 0) {
    wrapperClass += ' ' + 'has-danger';
  }

  return (
    <div className={wrapperClass} style={fullWidth && { flex: 1 }}>
      {label &&
        <label className="form-control-label">
          {label}
          {redText &&
            <span className="danger" style={{ marginLeft: 4 }}>
              {`(${redText})`}
            </span>}
        </label>}

      <div className="field">
        <select
          id={id}
          name={name}
          value={value}
          className={className || 'form-control select-input'}
          onChange={onChange}
          style={styles}
          disabled={disabled}
          onKeyDown={onKeyPress}>
          {defaultOption && <option value="">{defaultOption}</option>}
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>

        {error && <div className="form-control-feedback">{error}</div>}
      </div>
    </div>
  );
};

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultOption: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ]),
  error: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  styles: PropTypes.object,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  onKeyPress: PropTypes.func,
  redText: PropTypes.string,
  fullWidth: PropTypes.bool
};

export default SelectInput;
