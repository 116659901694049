import React from 'react';
import PropTypes from 'prop-types';

import { isEmpty, isUndefined } from 'lodash';

import { Popover, PopoverBody } from 'reactstrap';

import SelectInput from '../common/SelectInput';
import TextInput from '../common/TextInput';
import Alert from './Alert';

const educatorImg = require('../../assets/images/educator.png');
const cteLearnLogo = require('../../assets/images/CTE_Learn_logo.png');

const roleTypes = [
  {
    text: 'Teacher/Instructor/Trainer',
    value: 'Teacher/Instructor/Trainer'
  },

  {
    text: 'Advisor/Coach/Counselor',
    value: 'Advisor/Coach/Counselor'
  },

  {
    text: 'Administrator/Director/Coordinator',
    value: 'Administrator/Director/Coordinator'
  },

  {
    text: 'Workforce Developer/Agent/Manager',
    value: 'Workforce Developer/Agent/Manager'
  },

  {
    text: 'Other',
    value: 'Other'
  }
];

const organizationTypes = [
  {
    text: 'School/College/University',
    value: 'School/College/University'
  },

  {
    text: 'Training Services',
    value: 'Training Services'
  },

  {
    text: 'Coaching Services',
    value: 'Coaching Services'
  },

  {
    text: 'Workforce Development Board/Agency',
    value: 'Workforce Development Board/Agency'
  },

  {
    text: 'Professional Association',
    value: 'Professional Association'
  },

  {
    text: 'Other',
    value: 'Other'
  }
];

const EducatorSignUpForm = ({
  credentials,
  errors,
  routePath,
  onChange,
  onSubmit,
  onKeyPress,
  registration,
  displayAlertType,
  onDismissAlert,
  toggleLoginModal,
  alternateEmailPopover,
  toggleAlternateEmailPopover
}) => {
  let alertHeading = 'Thank you for signing up!';
  let alertMessage = 'You\'re almost finished! Please check either your work or alternate email to complete your account creation. We\'ve copied your alternate email in case your work email blocks your invitation email. In your email, we\'ve provided a link to setup your password and sign in. If you don\'t see the email in either of your inboxes, please check any spam, junk, clutter, or promotions folders and search "CareerPrepped" in all email folders. If you still can\'t locate your email, contact support at careerprepped@maxknowledge.com and let us know you didn\'t get your sign up email.';

  if (! isUndefined(registration.error.errorType)) {
    alertHeading = 'There\'s a problem with the email entered.';
    alertMessage = registration.error.detail;

    if ((registration.error.errorType === 'DuplicateEducatorRegistrationException')) {
      alertHeading = 'Duplicate registration requests.';
    }
  }

  const headerImg = (routePath === '/educator-signup') ? educatorImg : cteLearnLogo;
  let headerImgStyles = { backgroundColor: 'white', width: 250 };

  if (routePath !== '/educator-signup') {
    headerImgStyles = { ...headerImgStyles, width: 200 };
  }

  return (
    <div className="educator-signup info">
      <div className="self-register">
        <div style={{textAlign:'center'}}>
          <div className="double-lines" style={{top:64}}/>
          <img src={headerImg} style={headerImgStyles}/>
        </div>
        <h4>
          Sign up for CareerPrepped
        </h4>
        <form onSubmit={onSubmit} onKeyPress={onKeyPress} style={{fontSize:13}}>
          <div className="row">
            {(! isEmpty(displayAlertType)) &&
              <Alert
                displayAlertType={displayAlertType}
                alertHeading={alertHeading}
                alertMessage={alertMessage}
                dismissAlert={onDismissAlert}/>}

            <div className="col-6">
              <TextInput
                name="firstName"
                label="First Name"
                placeholder="First Name"
                value={credentials.firstName}
                onChange={onChange}
                error={errors.firstName}
                autoFocus
                srOnly/>
            </div>
            <div className="col-6">
              <TextInput
                name="lastName"
                label="Last Name"
                placeholder="Last Name"
                value={credentials.lastName}
                onChange={onChange}
                error={errors.lastName}
                srOnly/>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <SelectInput
                name="roleType"
                value={credentials.roleType}
                onChange={onChange}
                options={roleTypes}
                error={errors.roleType}
                defaultOption="Role Type"/>
            </div>

            <div className="col-6">
              <SelectInput
                name="organizationType"
                value={credentials.organizationType}
                onChange={onChange}
                error={errors.organizationType}
                options={organizationTypes}
                defaultOption="Organization Type"/>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <TextInput
                name="workEmail"
                label="Work Email"
                placeholder="Work Email"
                value={credentials.workEmail}
                onChange={onChange}
                error={errors.workEmail}
                srOnly/>
            </div>
            <div className="col-6 alternate">
              <TextInput
                name="alternateEmail"
                label="Alternate Email"
                placeholder="Alternate Email"
                value={credentials.alternateEmail}
                onChange={onChange}
                error={errors.alternateEmail}
                srOnly/>
              <Popover
                placement="left"
                isOpen={alternateEmailPopover}
                target="alternateEmailPopover" >
                <PopoverBody>
                  If your work email blocks your invitation email, we copy your alternate email to finish signup
                </PopoverBody>
              </Popover>
              <span
                id="alternateEmailPopover"
                onClick={toggleAlternateEmailPopover}>?</span>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <TextInput
                name="jobTitle"
                label="Job Title"
                placeholder="Job Title"
                value={credentials.jobTitle}
                onChange={onChange}
                error={errors.jobTitle}
                srOnly/>
            </div>
            <div className="col-6">
              <TextInput
                name="organization"
                label="Organization"
                placeholder="Organization"
                value={credentials.organization}
                onChange={onChange}
                error={errors.organization}
                srOnly/>
            </div>
          </div>

          <input
            type="submit"
            disabled={registration.isSubmitting}
            value={registration.isSubmitting ?
              'Just a sec while we process your request' :
              'Sign Up for a Free Talent Developer Account'
            }
            className="btn btn-primary btn-xlg w-100"/>

          <p style={{ textAlign: 'center', marginTop: 10 }}>
            Already have an account? <a href="#" onClick={toggleLoginModal}>Sign In</a>
          </p>
        </form>
      </div>
    </div>
  );
};

EducatorSignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  registration: PropTypes.object.isRequired,
  errors: PropTypes.object,
  credentials: PropTypes.object,
  routePath: PropTypes.string.isRequired,
  displayAlertType: PropTypes.string.isRequired,
  onDismissAlert: PropTypes.func.isRequired,
  toggleLoginModal: PropTypes.func.isRequired,
  alternateEmailPopover: PropTypes.bool.isRequired,
  toggleAlternateEmailPopover: PropTypes.func.isRequired
};

export default EducatorSignUpForm;
