import React, { useState } from 'react';

import PropTypes from 'prop-types';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Label,
  Container,
  Button,
} from 'reactstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import classnames from 'classnames';
import { isEmpty } from 'lodash';

import PrimaryEmail from '../../components/settings/PrimaryEmail';
import Password from '../../components/settings/Password';
import SecondaryEmail from '../../components/settings/SecondaryEmail';
import ConfirmPassword from '../../components/settings/ConfirmPassword';
import PhoneNumber from '../../components/settings/PhoneNumber';
import PrivacySettings from '../../components/settings/PrivacySettings';
import FeedbackFrequency from '../../components/settings/feedbackFrequency';
import ToggleSwitch from '../../components/common/toggleSwitch';
import { SimpleToolTipWrapper } from '../../components/common/ToolTipWrapper';
import {
  SETTINGS_DATE_FORMAT,
  PAUSE_REQUESTS_TOOLTIP,
} from '../../utilConstants';
import { FEEDBACK_DATE_FORMAT } from '../../constants';

import 'react-datepicker/dist/react-datepicker.css';
import frequencyOptions from './FrequencyOptions';

const PrivacySettingsTab = (props) => {
  const [activeTab, setActiveTab] = useState('1');

  function toggle(event) {
    const clickedTabName = event.target.innerHTML;
    const tab = clickedTabName === 'Account Details' ? '1' : '2';
    if (activeTab !== tab) setActiveTab(tab);
  }

  const renderAccountDetails = (props) => {
    const {
      profile,
      profileState,
      errors,
      handleChange,
      isUpdating,
      onKeyPress,
      togglePopover,
      popoverOpen,
      submitChange,
      updateSettingsText,
      privacyOptions,
    } = props;

    return (
      <Container className="account-details-container">
        <Row className="padbot30">
          <Label>Affiliated Organizations:</Label>
          <span className="org-name">
            {profile.affiliatedOrganizations[0]?.name}
          </span>
        </Row>
        <Row>
          <Col xs="12" sm="12" xl="5">
            <PrimaryEmail
              primaryEmail={profileState.email}
              error={errors.email}
              changePrimaryEmail={handleChange}
              isUpdating={isUpdating}
              onKeyPress={onKeyPress}/>
            <Password
              password={profileState.password}
              changePassword={handleChange}
              error={errors.password}
              isUpdating={isUpdating}
              onKeyPress={onKeyPress}/>
            <PrivacySettings
              changePrivacySetting={handleChange}
              privacySettings={profileState.privacySettings}
              error={errors.privacySettings}
              options={privacyOptions}
              isUpdating={isUpdating}/>
          </Col>
          <Col xs="12" sm="12" xl="5" className="offset-xl-1 offset-sm-0">
            <SecondaryEmail
              profile={profile}
              secondaryEmail={profileState.secondaryEmail}
              changeSecondaryEmail={handleChange}
              error={errors.secondaryEmail}
              isUpdating={isUpdating}
              popoverOpen={popoverOpen}
              togglePopover={togglePopover}
              onKeyPress={onKeyPress}/>
            <ConfirmPassword
              confirmPassword={profileState.confirmPassword}
              changeConfirmPassword={handleChange}
              error={errors.confirmPassword}
              isUpdating={isUpdating}
              onKeyPress={onKeyPress}/>
            <PhoneNumber
              phoneNumber={profileState.phoneNumber}
              changePhoneNumber={handleChange}
              error={errors.phoneNumber}
              isUpdating={isUpdating}
              onKeyPress={onKeyPress}/>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" xl="5" />
          <Col
            xs="12"
            sm="12"
            xl="6"
            className="offset-xl-1 offset-sm-0 text-right">
            <Button
              type="button"
              className="mx-1 sendButton"
              name="button"
              disabled={updateSettingsText === 'Saving...'}
              onClick={submitChange}>
              {updateSettingsText}
            </Button>
          </Col>
        </Row>
      </Container>
    );
  };

  const renderFeedbackSettings = (props) => {
    const {
      errors,
      submitIRChange,
      handleChange,
      isUpdating,
      updateSettingsText,
      industryRepresentativeConfigurationState,
    } = props;

    function handlePauseStartDateChange(v) {
      if (
        moment(moment(v).format(SETTINGS_DATE_FORMAT)).isSameOrAfter(
          moment().format(SETTINGS_DATE_FORMAT)
        )
      ) {
        handleChange({
          target: {
            name: 'pauseStartDate',
            value: moment(v).format(SETTINGS_DATE_FORMAT),
          },
        });
      } else {
        handleChange({
          target: { name: 'pauseStartDate', value: '' },
        });
      }
    }

    function handlePauseEndDateChange(v) {
      if (
        moment(moment(v).format(SETTINGS_DATE_FORMAT)).isSameOrAfter(
          moment(
            industryRepresentativeConfigurationState.pauseStartDate
          ).format(SETTINGS_DATE_FORMAT)
        )
      ) {
        handleChange({
          target: {
            name: 'pauseEndDate',
            value: moment(v).format(SETTINGS_DATE_FORMAT),
          },
        });
      } else {
        handleChange({
          target: { name: 'pauseEndDate', value: '' },
        });
      }
    }

    function setPauseEndMinDateRange(){
      let selectedStartDate = new Date(
        industryRepresentativeConfigurationState.pauseStartDate
      );
      selectedStartDate.setDate(selectedStartDate.getDate() + 1);
      return selectedStartDate;
    }

    const renderPauseDateComponents = () => (
      <Row id="privacy-settings-date-container">
        <Col lg="6">
          <div className="form-group">
            <Row>
              <Col lg="11">
                <Row>
                  <DatePicker
                    id="example-datepicker"
                    name="pauseStartDate"
                    className="form-control dateIcon"
                    placeholderText="From"
                    dateFormat={FEEDBACK_DATE_FORMAT}
                    selected={
                      industryRepresentativeConfigurationState.pauseStartDate &&
                      new Date(
                        industryRepresentativeConfigurationState.pauseStartDate
                      )
                    }
                    onChange={handlePauseStartDateChange}
                    disabled={
                      !industryRepresentativeConfigurationState.pauseFeedbackRequests
                    }
                    minDate={new Date()}/>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>

        <Col lg="6">
          <div className="form-group">
            <Row className="flex-end">
              <Col lg="11">
                <Row>
                  <DatePicker
                    id="example-datepicker"
                    name="pauseEndDate"
                    className="form-control dateIcon"
                    dateFormat={FEEDBACK_DATE_FORMAT}
                    placeholderText="To"
                    selected={
                      industryRepresentativeConfigurationState.pauseEndDate &&
                      new Date(
                        industryRepresentativeConfigurationState.pauseEndDate
                      )
                    }
                    onChange={handlePauseEndDateChange}
                    minDate={
                      industryRepresentativeConfigurationState.pauseStartDate &&
                      setPauseEndMinDateRange()
                    }
                    disabled={
                      !industryRepresentativeConfigurationState.pauseFeedbackRequests ||
                      isEmpty(
                        industryRepresentativeConfigurationState.pauseStartDate
                      )
                    }/>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );

    return (
      <Container className="account-details-container">
        <Row>
          <Col xs="12" sm="12" lg="6" xl="6">
            <FeedbackFrequency
              changePrivacySetting={handleChange}
              error={errors.privacySettings}
              options={frequencyOptions}
              isUpdating={isUpdating}
              name={'frequencyInWeeks'}
              value={
                industryRepresentativeConfigurationState.frequencyInWeeks
              }/>
            <Container className="form-group">
              <Row className="space-between">
                <div className="label-button">
                  <label className="padright10">Pause Feedback Requests</label>{' '}
                  <SimpleToolTipWrapper
                    id={'pause-feedback-requests-info'}
                    toolTipContent={
                      <div className="inside-tooltip">
                        {PAUSE_REQUESTS_TOOLTIP}
                      </div>
                    }>
                    <i className="fa fa-info-circle" />
                  </SimpleToolTipWrapper>
                </div>
                <ToggleSwitch
                  pauseDisableRequest={
                    !!industryRepresentativeConfigurationState.pauseFeedbackRequests
                  }
                  changePauseDisableRequest={handleChange}
                  isUpdating={isUpdating}
                  name="pauseFeedbackRequests"
                  disabled={
                    !!industryRepresentativeConfigurationState.disableFeedbackRequests
                  }/>
              </Row>
              {renderPauseDateComponents()}
            </Container>
            <Container className="form-group">
              <Row className="space-between">
                <Label>Do not send any feedback requests</Label>
                <ToggleSwitch
                  pauseDisableRequest={
                    !!industryRepresentativeConfigurationState.disableFeedbackRequests
                  }
                  changePauseDisableRequest={handleChange}
                  isUpdating={isUpdating}
                  name="disableFeedbackRequests"
                  disabled={
                    !!industryRepresentativeConfigurationState.pauseFeedbackRequests
                  }/>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" xl="5" />
          <Col
            xs="12"
            sm="12"
            xl="6"
            className="offset-xl-1 offset-sm-0 text-right">
            <Button
              type="button"
              className="mx-1 sendButton"
              name="button"
              disabled={updateSettingsText === 'Saving...'}
              onClick={submitIRChange}>
              {updateSettingsText}
            </Button>
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={toggle}>
            Account Details
          </NavLink>
        </NavItem>
        {props.profile.role === 'industry-representative' && (
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={toggle}>
              Feedback Settings
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">{renderAccountDetails(props)}</Col>
          </Row>
        </TabPane>
        {props.profile.role === 'industry-representative' && (
          <TabPane tabId="2">
            <Row>
              <Col sm="12">{renderFeedbackSettings(props)}</Col>
            </Row>
          </TabPane>
        )}
      </TabContent>
    </div>
  );
};

PrivacySettingsTab.propTypes = {
  profile: PropTypes.object.isRequired,
  profileState: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  togglePopover: PropTypes.func.isRequired,
  popoverOpen: PropTypes.bool.isRequired,
  submitChange: PropTypes.func.isRequired,
  updateSettingsText: PropTypes.string.isRequired,
  privacyOptions: PropTypes.array.isRequired,
  submitIRChange: PropTypes.func,
  industryRepresentativeConfigurationState: PropTypes.object,
};

export default PrivacySettingsTab;
