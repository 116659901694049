import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

const ConfirmResetFileModal = ({ 
  files,
  isOpen,
  onCancel,
  onConfirm
}) => {
  const moreThanOneFile = files.length > 0;
  return(
    <Modal
      backdrop="static"
      className="modal-margin-top"
      isOpen={isOpen}
      toggle={onCancel}>
      <ModalHeader
        className="no-border"
        toggle={onCancel}>
        Remove file
      </ModalHeader>

      <ModalBody>
        Are you sure you want to remove the {moreThanOneFile ? 'files': 'file'} you've attached?
      </ModalBody>

      <ModalFooter className="no-border">
        <button
          onClick={onCancel}
          className="btn btn-secondary">
          Cancel
        </button>

        <button
          onClick={onConfirm}
          className="btn btn-primary">
          Yes, remove
        </button>
      </ModalFooter>
    </Modal>
  );
};

ConfirmResetFileModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  files: PropTypes.array
};

export default ConfirmResetFileModal;
