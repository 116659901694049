import PropTypes from 'prop-types';
import React from 'react';
import { loadForm } from '../../utils';
import OrganizationForm from './OrganizationForm';
import Table from './manage-organizations/organizations/Table';

const OrganizationsContainer = ({
  isRequesting,
  editingId,
  forms,
  onAddOrganization,
  errors,
  organization,
  organizationArray,
  onCancel,
  onSaveOrganization,
  onChangeOrganizationDetails,
  onEditOrganization,
  organizationObject,
  onNext,
  onPrev,
  onSelectPage,
  page,
  page_count,
  onSortTable,
  onChangeOrganizationsSearchQuery
}) => {

  const { isSubmitting, isUpdating } = organizationObject;

  if(loadForm(forms, 'addOrganizationForm')){
    return (
      <OrganizationForm
        editingId={editingId}
        errors={errors}
        organization={organization}
        onCancel={onCancel}
        onSaveOrganization={onSaveOrganization}
        isSubmitting={isSubmitting}
        isUpdating={isUpdating}
        onChangeOrganizationDetails={onChangeOrganizationDetails}/>
    );
  }

  return (
    <div className="institution-list">
      <h6>List of Organizations</h6>

      <button
        onClick={onAddOrganization}
        className="btn btn-primary mb-10">
        Add Organization
      </button>

      <Table
        page={page}
        onNext={onNext}
        onPrev={onPrev}
        page_count={page_count}
        onSortTable={onSortTable}
        onSelectPage={onSelectPage}
        isRequesting={isRequesting}
        organizationArray={organizationArray}
        onEditOrganization={onEditOrganization}
        searchQuery={organizationObject.filters.searchQuery}
        onChangeSearchQuery={onChangeOrganizationsSearchQuery}/>
    </div>
  );
};

OrganizationsContainer.propTypes = {
  isRequesting: PropTypes.bool.isRequired,
  editingId: PropTypes.string,
  forms: PropTypes.array,
  organizationObject: PropTypes.object.isRequired,
  onAddOrganization: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  onChangeOrganizationDetails: PropTypes.func.isRequired,
  organizationArray: PropTypes.array,
  onCancel: PropTypes.func.isRequired,
  onSaveOrganization: PropTypes.func.isRequired,
  onEditOrganization: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  page_count: PropTypes.number.isRequired,
  onSelectPage: PropTypes.func.isRequired,
  onSortTable: PropTypes.func.isRequired,
  onChangeOrganizationsSearchQuery: PropTypes.func.isRequired
};

export default OrganizationsContainer;
