import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

const DiscussionAvatar = ({ user }) => {
  return (
    <div className="left">
      <Link to={`/cp/${user.vanity}`}>
        {user.avatar && user.avatar.length !== 0 ?
          <img
            className="header-image medium align-top"
            src={user.avatar}
            alt="User avatar"/>:
          <span
            className="header-image medium align-top initial-avatar">
            {user.name.slice(0,1)}
          </span>}
      </Link>
    </div>
  );
};

DiscussionAvatar.propTypes = {
  user: PropTypes.object.isRequired
};

export default DiscussionAvatar;
