export default [
  {
    id: '1447d292-6224-4318-be79-f4ed0fa952e1',
    question: 'This learning opportunity has improved my understanding of global competence',
    choices: [
      'Strongly Agree',
      'Agree',
      'Neutral',
      'Disagree',
      'Strongly Disagree'
    ],
    answer: ''
  },
  {
    id: '14a33b0e-0ef4-4862-bb94-f200b651c3ff',
    question: 'I am more knowledgeable about the importance of being aware of different cultural perspectives and global issues',
    choices: [
      'Strongly Agree',
      'Agree',
      'Neutral',
      'Disagree',
      'Strongly Disagree'
    ],
    answer: ''
  },
  {
    id: '9dcc1225-85a7-4ed7-b9e9-2724f4aa2a40',
    question: 'I am likely to try some of these strategies in my career',
    choices: [
      'Strongly Agree',
      'Agree',
      'Neutral',
      'Disagree',
      'Strongly Disagree'
    ],
    answer: ''
  },
  {
    id: 'fef1e580-7882-4e8d-83f4-65bd98d85ccf',
    question: 'I believe these strategies will help me in my career',
    choices: [
      'Strongly Agree',
      'Agree',
      'Neutral',
      'Disagree',
      'Strongly Disagree'
    ],
    answer: ''
  },
  {
    id: 'e5929bd7-a8ee-4851-abee-03b68b2f9b9f',
    question: 'I would recommend these modules to a friend',
    choices: [
      'Strongly Agree',
      'Agree',
      'Neutral',
      'Disagree',
      'Strongly Disagree'
    ],
    answer: ''
  }
];
