import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { IGNORE, RESPOND } from '../../constants/feedback';

const BaseActionComponent = (props) => {
  const rowDetails = props.rowData;

  function setRequest() {
    props.setCurrentRequest(rowDetails.feedbackRequestId);
    props.toggleIgnore(IGNORE);
  }

  return (
    <td>
      <div className="d-flex">
        <Link
          className="respondLink"
          to={`/givefeedback/${rowDetails?.feedbackRequestId}?revise`}
          state={{
            name: rowDetails?.fullname,
          }}>
          <Button className="sendButton respondButton mr-3">{RESPOND}</Button>
        </Link>

        <button
          onClick={setRequest}
          type="button"
          className="btn btn-secondary">
          {IGNORE}
        </button>
      </div>
    </td>
  );
};

BaseActionComponent.propTypes = {
  rowData: PropTypes.object.isRequired,
  setCurrentRequest: PropTypes.func,
  toggleIgnore: PropTypes.func,
};

export default BaseActionComponent;
