import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import CpHtmlParser from '../../common/CpHtmlParser';

const styles = {
  userNameEditIcon: {
    cursor: 'pointer',
    marginLeft: 10,
  },

  editActions: {
    position: 'relative',
  }
};

const ExperienceSectionCard = ({
  experienceDetails,
  onEditExperience,
  onRemoveExperience
}) => {
  const isStartDateObject = typeof experienceDetails.startdate === 'object';
  const startDateString = isStartDateObject ? experienceDetails.startdate.date :
    experienceDetails.startdate === '' ? undefined : experienceDetails.startdate;

  const isEndDateObject = typeof experienceDetails.enddate === 'object';
  const endDateString = isEndDateObject ? experienceDetails.enddate.date :
    experienceDetails.enddate === '' ? undefined : experienceDetails.enddate;

  const startDate = moment(startDateString).format('MM/YYYY');
  let endDate;

  if (experienceDetails.noend) {
    endDate = 'Present';
  } else {
    endDate = moment(endDateString).format('MM/YYYY');
  }

  return (
    <div className="resume-section section-item">
      <span style={styles.editActions} className="float-right">
        <i
          onClick={onEditExperience}
          id={experienceDetails.id}
          style={styles.userNameEditIcon}
          className="fa fa-pencil"/>

        <i
          onClick={onRemoveExperience}
          id={experienceDetails.id}
          style={styles.userNameEditIcon}
          className="fa fa-close"/>
      </span>
      {experienceDetails.orgurl !== '' ?
        <a href={experienceDetails.orgurl} target="_blank" rel="noopener noreferrer">{experienceDetails.orgname}</a> :
        <span>{experienceDetails.orgname}</span>}

      <span style={{marginLeft: 10}}>({startDate} - {endDate})</span>
      <p><b>{experienceDetails.title}</b></p>
      <div className="ql-editor no-padding-in-quill">
        <CpHtmlParser htmlString={experienceDetails.description}/>
      </div>
    </div>
  );
};

ExperienceSectionCard.propTypes = {
  experienceDetails: PropTypes.object.isRequired,
  index: PropTypes.number,
  onEditExperience: PropTypes.func.isRequired,
  onRemoveExperience: PropTypes.func.isRequired
};

export default ExperienceSectionCard;
