import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { checkHttp } from '../../../utils';
import Parser from 'html-react-parser';

const styles = {
  editButton: {
    marginLeft: 10,
    cursor: 'pointer',
  }
};

const AwardCard = ({award, onEditAwardClick, isPublicProfile}) => {
  let date = '';

  if (typeof award.date === 'object') {
    date = moment(award.date.date).format('MMMM YYYY');
  }

  const urlAvailable = award.url !== '';
  const urlString = checkHttp(award.url) ?
    award.url : `//${award.url}`;

  return (
    <div className="card-block">
      <div className="awards">
        <div className="row">
          <div className="col-lg-12">
            <div className="display">
              <div className="item">
                <div className="title">
                  {award.title}
                  {! isPublicProfile &&
                    <i
                      style={styles.editButton}
                      onClick={onEditAwardClick}
                      id={`update-award-form-${award.id}`}
                      className="fa fa-xs fa-pencil orange" aria-hidden="true"/>}
                </div>
                <div className="authority">
                  {
                    urlAvailable ? <a href={urlString} target="_blank" rel="noopener noreferrer">{award.issuer}</a> :
                      award.issuer
                  }

                </div>
                <div className="date">{date}</div>
                <div className="description">{Parser(award.description)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AwardCard.propTypes = {
  award: PropTypes.object.isRequired,
  onEditAwardClick: PropTypes.func.isRequired,
  isPublicProfile: PropTypes.bool.isRequired
};

export default AwardCard;
