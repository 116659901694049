import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { USER_AVATAR } from '../../../utilConstants';

const NameImageColumn = (props) => {
  const rowDetails = props.rowData;
  return (
    <td scope="row">
      <div className="user-info">
        <div className="media align-items-center">
          <div className="media-left mr-3">
            <Link
              title={`Link to ${rowDetails.name}’s Career Site`}
              target={'_blank'}
              to={`/cp/${rowDetails.vanity}`}>
              {!rowDetails.avatar ? (
                <span className="media-object profile-image medium initial-avatar">
                  {rowDetails.name.slice(0, 1)}
                </span>
              ) : (
                <span>
                  <img
                    className="media-object avatar"
                    src={rowDetails.avatar}
                    alt={USER_AVATAR}/>
                </span>
              )}
            </Link>
          </div>
          <div className="media-body" style={{ textAlign: 'left' }}>
            <h5 className="media-heading mt-0 mb-0">
              <Link
                className="user-name"
                title={`Link to ${rowDetails.name}’s Career Site`}
                target="_blank"
                to={`/cp/${rowDetails.vanity}`}>
                {rowDetails.name}
              </Link>
              {' '}
            </h5>
            {rowDetails?.email || 'no-email@dummy.com'}
          </div>
        </div>
      </div>
    </td>
  );
};

NameImageColumn.propTypes = {
  rowData: PropTypes.object.isRequired,
};

export default NameImageColumn;