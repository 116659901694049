import PropTypes from 'prop-types';
import React from 'react';

import WallShareModal from '../../containers/dashboard/WallShareModal';
import WallCardContainer from '../../containers/dashboard/WallCardContainer';
import Loading from '../../components/common/Loading';

const WallList = ({ wallPosts, wallShare, callingAPI, isDeleting }) => {
  return (
    <div>
      {wallPosts.map((post, index) =>
        (<WallCardContainer
          key={post.id}
          postIndex={index}
          wallPost={post}
          isDeleting={isDeleting}/>))}
      <div style={{ paddingTop: 20, paddingBottom: 20 }}>
        {callingAPI && <Loading />}
      </div>

      {Object.keys(wallShare).length > 0 && <WallShareModal/>}
    </div>
  );
};

WallList.propTypes = {
  wallPosts: PropTypes.array.isRequired,
  wallShare: PropTypes.object.isRequired,
  callingAPI: PropTypes.bool,
  isDeleting: PropTypes.object.isRequired,
};

export default WallList;
