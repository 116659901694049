import React from 'react';

import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import parse from 'html-react-parser';
import moment from 'moment';
import CloseIcon from '../../assets/images/close.svg';
import {
  SETTINGS_DATE_FORMAT,
  FEEDBACK_REQUESTED_DATE_FORMAT,
} from '../../constants';

function SeePraise({ isOpen, toggleModal, feedback, receivedPraiseSelection }) {

  const seeReceivedPraiseFields = () => receivedPraiseSelection?.praises?.map((row) => {
    return (
      <div key={row.id} className="mb-3">
        <i
          aria-hidden="true"
          style={{ color: '#F04E30' }}
          className="icon-checkbox font18 orange mr-3 align-middle"/>
        <span>{parse(row.answerText)} <i>({row.answerDescription})</i></span>
      </div>
    );
  });

  return (
    <Modal
      centered
      className="customLevelUpModal"
      isOpen={isOpen}
      toggle={toggleModal}>
      <div className="modal-header">
        <span className="modal-title">Praise You Received</span>
        <div
          onClick={toggleModal}
          className="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">
            <img src={CloseIcon} />
          </span>
        </div>
      </div>

      <ModalBody>
        <div className="d-flex align-items-center">
          <img
            className="feedbackAvatar"
            src={
              feedback.avatarURl
                ? feedback.avatarURl
                : require('../../assets/images/avatar.jpg')
            }/>
          <div className="d-inline-block ml-3">
            <span className="d-block font-weight-bold">
              {feedback.fullname}
            </span>
            <span className="d-block font12">
              {`${moment(
                receivedPraiseSelection?.givenOn?.date,
                SETTINGS_DATE_FORMAT
              ).format(FEEDBACK_REQUESTED_DATE_FORMAT)}`}
            </span>
          </div>
        </div>
        <div className="pl-5 ml-3 mt-3">
          <p className="mb-3">The recipient of your feedback said it was:</p>
          {seeReceivedPraiseFields()}
        </div>
      </ModalBody>
      <ModalFooter className="bordertop-0">
        <Button className="ml-1 sendButton" onClick={toggleModal}>
          Done
        </Button>
      </ModalFooter>
    </Modal>
  );
}

SeePraise.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  feedback: PropTypes.object.isRequired,
  receivedPraiseSelection: PropTypes.object.isRequired,
};

export default SeePraise;
