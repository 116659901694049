const isString = (props, propName, errorMessage) =>
  props[propName].length > 0 ? null : new Error(errorMessage);

export function isStringOrObject(props, propName, componentName) {
  let errorMessage = `${propName} in ${componentName}`;
  errorMessage += ' must be either an object or a string.';

  if (props[propName]) {
    if (typeof props[propName] === 'string')
      return isString(props, propName, errorMessage);

    return typeof props[propName] === 'object' ? null : new Error(errorMessage);
  }

  return null;
}

export function isStringOrNumber(props, propName, componentName) {
  let errorMessage = `${propName} in ${componentName}`;
  errorMessage += ' must be either a string or a number.';

  if (props[propName]) {
    if (typeof props[propName] === 'string')
      return isString(props, propName, errorMessage);

    return typeof props[propName] === 'number' ? null : new Error(errorMessage);
  }

  return null;
}