import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as filesActions from '../../redux/actions/files';
import * as componentActions from '../../redux/actions/components';
import * as skillsActions from '../../redux/actions/skills';

import { Helmet } from 'react-helmet';
import { toastr } from 'react-redux-toastr';
import PaginationHandler from '../../components/common/PaginationHandler';
import PortfolioComponent from '../../components/portfolio/PortfolioComponent';
import AddPortfolioModal from '../../components/portfolio/AddPortfolioModal';
import { withHooks } from '../../utils/withHooks';

class Portfolio extends Component {
  static propTypes = {
    profile: PropTypes.object,
    files: PropTypes.object,
    actions: PropTypes.object.isRequired,
    userId: PropTypes.string,
    skills: PropTypes.array,
    modalName: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    isRequesting: PropTypes.bool,
    location: PropTypes.object,
    params: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      activeSort: 'newest',
      searchValue: '',
      guideLinesTooltipOpen: false,
      isDropdownOpen: false,
      isRequestingSort: false,
      isRequestingSearch: false
    };

    this.callAPI = this.callAPI.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.onAddPortfolio = this.onAddPortfolio.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.toggleGuidelinesTooltip = this.toggleGuidelinesTooltip.bind(this);
    this.handleSearchFormKeyPress = this.handleSearchFormKeyPress.bind(this);
    this.onNext = this.onNext.bind(this);
    this.onPrev = this.onPrev.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
    this.onClearSearch = this.onClearSearch.bind(this);
  }

  componentWillMount(){
    const { actions, userId } = this.props;
    if(userId)
      actions.skillsRequest(userId);
  }

  componentDidMount(){
    const [,,slug] = location.pathname.split('/');

    const { actions, isAuthenticated } = this.props;
    if(slug || isAuthenticated){
      actions.clearFilesReducer();
      this.fetchSortedFiles();
    }
  }

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  toggleDropdown() {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen
    });
  }

  onSearchChange(event) {
    const { value: searchValue } = event.target;

    this.setState({ searchValue });
  }

  onSortChange(event) {
    const { id: sortValue } = event.target;
    const { actions } = this.props;

    if (sortValue === this.state.activeSort) {
      return toastr.warning(`Already sort by ${this.state.activeSort}`);
    }

    this.setState({
      activeSort: sortValue,
      isDropdownOpen: false,
      isRequestingSort: true,
    }, () => this.fetchSortedFiles());

    actions.clearFilesReducer();
  }

  onAddPortfolio(){
    this.props.actions.openModal('add-portfolio-modal');
  }

  onCloseModal(){
    this.props.actions.closeModal();
  }

  toggleGuidelinesTooltip(){
    this.setState({
      guideLinesTooltipOpen: !this.state.guideLinesTooltipOpen
    });
  }

  handleSearchFormKeyPress(event) {

    if (event.key === 'Enter'){
      return this.onSearchSubmit(event);
    }
  }

  onSearchSubmit(event) {
    event.preventDefault();


    this.setState({
      isRequestingSearch: true
    }, this.fetchSortedFiles());

    this.props.actions.clearFilesReducer();
  }

  onClearSearch(){
    const { activeSort } = this.state;
    const { actions, isAuthenticated } = this.props;
    const userId = this.getUserId();

    actions.clearFilesReducer();
    this.setState({
      searchValue: ''
    }, () =>
      actions.fetchFiles(isAuthenticated, userId, 1, activeSort)
        .then(() => this.setState({
          isRequestingSort: false,
          isRequestingSearch: false
        })));
  }

  fetchSortedFiles() {
    const { activeSort, searchValue } = this.state;
    const { actions, isAuthenticated } = this.props;
    const userId = this.getUserId();

    actions.fetchFiles(isAuthenticated, userId, 1, activeSort, searchValue)
      .then(() => this.setState({
        isRequestingSort: false,
        isRequestingSearch: false
      }));
  }

  callAPI(pageNo) {
    const { actions, isAuthenticated } = this.props;
    const { activeSort, searchValue } = this.state;
    const userId = this.getUserId();

    return actions.fetchFiles(isAuthenticated, userId, pageNo, activeSort, searchValue);
  }

  onNext(){
    const { actions, files, isAuthenticated } = this.props;
    const { activeSort, searchValue } = this.state;
    const userId = this.getUserId();

    actions.clearFilesReducer();
    return actions.fetchFiles(isAuthenticated, userId, files.fileData.page + 1, activeSort, searchValue);
  }

  onPrev(){
    const { actions, files, isAuthenticated } = this.props;
    const { activeSort, searchValue } = this.state;
    const userId = this.getUserId();

    actions.clearFilesReducer();
    return actions.fetchFiles(isAuthenticated, userId, files.fileData.page - 1, activeSort, searchValue);
  }

  onSelectPage(page){
    return () => {
      const { actions, isAuthenticated } = this.props;
      const { activeSort, searchValue } = this.state;
      const userId = this.getUserId();

      actions.clearFilesReducer();
      return actions.fetchFiles(isAuthenticated, userId, page, activeSort, searchValue);
    };
  }

  getUserId () {
    const { params, profile } = this.props;
    return (params?.userId) ? params?.userId: profile?.id;
  }

  render() {
    const {
      profile,
      files,
      modalName,
      userId,
      skills,
      isRequesting,
      params
    } = this.props;

    const {
      activeSort,
      searchValue,
      isDropdownOpen,
      isRequestingSort,
      isRequestingSearch,
      guideLinesTooltipOpen
    } = this.state;
    const { isRequesting: isRequestingFiles, fileData, data: filesArray } = files;
    const { page_count, page } = fileData;
    const isAddPortfolioModalOpen = modalName && modalName === 'add-portfolio-modal';
    let isOwner = profile.id === userId;
    if ( params.userId) {
      isOwner = profile.id === params?.userId;
    }

    return(
      <div className="row">
        <Helmet title={isRequesting ? 'Portfolio' : `${profile.name}'s Portfolio`}/>
        <PortfolioComponent
          page_count={page_count}
          page={page}
          files={files}
          skills={skills}
          profile={profile}
          callingAPI={isRequestingFiles}
          activeSort={activeSort}
          searchValue={searchValue}
          onClearSearch={this.onClearSearch}
          onSortChange={this.onSortChange}
          onAddPortfolio={this.onAddPortfolio}
          onSearchChange={this.onSearchChange}
          onSearchSubmit={this.onSearchSubmit}
          handleSearchFormKeyPress={this.handleSearchFormKeyPress}
          toggleDropdown={this.toggleDropdown}
          isDropdownOpen={isDropdownOpen}
          isRequestingSearch={isRequestingSearch}
          isRequestingSort={isRequestingSort}
          isOwner={isOwner}/>

        {
          isAddPortfolioModalOpen &&
            <AddPortfolioModal
              onCancel={this.onCloseModal}
              guideLinesTooltipOpen={guideLinesTooltipOpen}
              toggleGuidelinesTooltip={this.toggleGuidelinesTooltip}
              isOpen={isAddPortfolioModalOpen}/>
        }
        {
          (!isRequestingFiles && filesArray.length > 0) &&
          <div className="col-lg-12">
            <PaginationHandler
              onNext={this.onNext}
              onPrev={this.onPrev}
              onSelectPage={this.onSelectPage}
              page={page}
              page_count={page_count}/>
          </div>
        }

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modalName: state.components.modal,
    files: state.files,
    skills: state.skills.data,
    isRequesting: state.webpage.isRequesting,
    isAuthenticated: state.auth.isAuthenticated,
    userId: state.auth.data.currentUser.id
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, filesActions, componentActions, skillsActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default withHooks(
  connect(mapStateToProps, mapDispatchToProps)(Portfolio)
);
