import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';

import Parser from 'html-react-parser';
import { parseJSON, parseUrlString } from '../../utils';
import AdminSectionHeader from '../../components/skill-builders/admin/AdminSectionHeader';

const videoPlaceholder = require('../../assets/images/video-placeholder.png');

class VideoSection extends Component {
  static propTypes = {
    hover: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    section: PropTypes.object.isRequired,
    onEditClick: PropTypes.func.isRequired,
    isDragging: PropTypes.bool,
    drag: PropTypes.func.isRequired,
    drop: PropTypes.func.isRequired,
    preview: PropTypes.func.isRequired
  };

  render() {
    const {
      drag,
      drop,
      preview,
      section,
      hover,
      isAdmin,
      onEditClick,
      isDragging
    } = this.props;

    let data = parseJSON(section.content);
    const videoUrl = parseUrlString(data.url);

    return (
      <section className="essential-media" id={`youtube-${section.id}`} ref={preview}>
        {isAdmin &&
          <div style={{ marginTop: -20 }}>
            <AdminSectionHeader
              hover={hover}
              drag={drag}
              drop={drop}
              title="Video"
              sectionId={section.id}
              onEditClick={onEditClick}
              componentName={`youtube-${section.id}`}/>
          </div>}

        <div className="section-icon">
          <svg viewBox="0 0 120 100">
            <path d="M38,2 L82,2 A12,12 0 0,1 94,10 L112,44 A12,12 0 0,1 112,56 L94,90 A12,12 0 0,1 82,98 L38,98 A12,12 0 0,1 26,90 L8,56 A12,12 0 0,1 8,44 L26,10 A12,12 0 0,1 38,2" />
          </svg>
          <div className="icon"><div className="ico-media"/></div>
        </div>

        <h4>{data.title}</h4>

        <div className="embed-responsive embed-responsive-16by9">
          {!isDragging ?
            <div className="embed-responsive-item">
              {(videoUrl === 'embed') ?
                Parser(data.url) :
                <iframe
                  src={videoUrl.url}
                  style={{'overflow': 'hidden'}}
                  scrolling="no"
                  allowFullScreen/>}
            </div>
            :
            <div className="video-placeholder">
              <img src={videoPlaceholder}/>
            </div>}
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({ sections: { isDragging } }) => ({ isDragging });

export default connect(mapStateToProps, null)(VideoSection);
