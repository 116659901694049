import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Validator from '../../../validator';

import {
  educationRequest,
  saveEducation,
  updateEducation,
  deleteEducation
} from '../../../redux/actions/education';
import {updateMyCareerPreppedStatus, getMyCareerPreppedStatus} from '../../../redux/actions/my-careerprepped-status';

import Loading from '../../../components/common/Loading';
import ProfileCardHeader from '../../../components/profile/header/ProfileCardHeader';
import EducationForm from '../../../components/profile/forms/EducationForm';
import EducationList from '../../../components/profile/sections/EducationList';
import { toastr } from 'react-redux-toastr';

class Education extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    education: PropTypes.array.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    educationData: PropTypes.object.isRequired,
    isPublicProfile: PropTypes.bool.isRequired,
    showVisibilitySelect: PropTypes.bool,
    onChangeVisibility: PropTypes.func,
    isEditingSections: PropTypes.bool,
    isEditing: PropTypes.bool,
    onCancelModal: PropTypes.func,
    onToggleSectionEdit: PropTypes.func,
    profile: PropTypes.object,
    isDeleting: PropTypes.bool.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    isEditingFromModal: PropTypes.bool,
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      editingId: null,
      isEditing: ( (this.props.isEditing) ? this.props.isEditing : false ),
      isSubmitting: false,
      educationData: Object.assign({}, this.props.educationData)
    };

    this.onEditClick = this.onEditClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeMajor = this.onChangeMajor.bind(this);
    this.onChangeProgram = this.onChangeProgram.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onEditEducation = this.onEditEducation.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
  }

  componentDidMount() {
    const { profile, actions } = this.props;
    actions.educationRequest(profile.id);
  }

  formatEndDate = (dateString) => {
    return dateString === '' ? null : dateString;
  };

  isValid(field = null) {
    const { noend, startdate } = this.state.educationData;
    let rules = {};
    if (noend === true) {
      rules = {
        institution: ['required', 'minLength|3', 'maxLength|100'],
        program: ['required'],
        insurl: ['unsafeurl'],
        gpa: ['lessThan|5', 'isNumber'],
        startdate: ['required','startdateYear'],
      };
    } else {
      rules = {
        institution: ['required', 'minLength|3', 'maxLength|100'],
        program: ['required'],
        insurl: ['unsafeurl'],
        gpa: ['lessThan|5', 'isNumber'],
        startdate: ['required', 'startdateYear'],
        enddate: ['required', 'enddate', `beforeStart|${this.formatEndDate(startdate)}`]
      };
    }
    const { educationData, errors: prevErrors} = this.state;
    const validate = Validator.createValidator(rules, educationData, field);
    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    if ( noend === true ) {
      delete prevErrors['enddate'];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});
    return isValid;
  }

  onChange(event) {
    let { name, type, checked, value } = event.target;
    const { educationData } = this.state;

    value = type === 'checkbox' ? checked : value;

    educationData[name] = value;
    this.setState({ educationData }, () => this.isValid(name));

    if (name === 'startdate') {
      this.isValid('enddate');
    }
  }

  onChangeMajor(value){
    const { educationData } = this.state;

    let formattedValue = value && value.label;

    if(value && value.label.includes('Create option ')){
      const splat = value.label.split('Create option ');
      formattedValue = splat[1].replace(/"/g, '');
    }

    educationData['major'] = value ? formattedValue : '';
    this.setState({ educationData });

  }

  onChangeProgram(value){
    const { educationData } = this.state;

    let formattedValue = value && value.label;

    if(value && value.label.includes('Create option ')){
      const splat = value.label.split('Create option ');
      formattedValue = splat[1].replace(/"/g, '');
    }

    educationData['program'] = value ? formattedValue : '';
    this.setState({ educationData }, () => this.isValid('program'));
  }

  onSave(event) {
    event.preventDefault();

    this.setState({ isSubmitting: true });

    if (! this.isValid()) {
      return this.setState({ isSubmitting: false });
    }

    const { educationData, editingId } = this.state;
    const { actions, education, isEditingFromModal, onCancelModal } = this.props;

    if(editingId !== null){
      const index = education.findIndex(element =>
        element.id === educationData.id);

      return actions.updateEducation(index, educationData)
        .then(() => {
          if ( isEditingFromModal ) {
            toastr.success('Education added successfully');
            onCancelModal();
          }
          this.handleResponse();
        });
    }


    actions.saveEducation(educationData).then(async () => {
      await actions.updateMyCareerPreppedStatus();
      actions.getMyCareerPreppedStatus();
      if ( isEditingFromModal ) {
        toastr.success('Education added successfully');
        onCancelModal();
      }
      this.handleResponse();
    });
  }

  handleResponse() {
    this.setState({
      isEditing: false,
      isSubmitting: false,
      educationData: this.props.educationData,
      editingId: null
    },() => this.props.onToggleSectionEdit('education'));
  }

  onCancel() {
    this.setState({
      isEditing: false,
      errors: {},
      editingId: null,
      educationData: this.props.educationData
    }, () => this.props.onToggleSectionEdit('education'));
  }

  onEditClick(event) {
    event.preventDefault();

    this.setState({ isEditing: true },
      () => this.props.onToggleSectionEdit('education'));
  }

  onEditEducation(id){
    const { education } = this.props;
    const educationObject = education.find(education => education.id === id);
    const educationData = Object.assign({}, this.state.educationData, educationObject);
    this.setState({
      isEditing: true,
      educationData,
      editingId: id
    }, () => this.props.onToggleSectionEdit('education'));
  }

  onDelete(event) {
    event.preventDefault();

    const { educationData } = this.state;
    this.props.actions.deleteEducation(educationData.id)
      .then(async () => {
        this.handleResponse();
        await this.props.actions.updateMyCareerPreppedStatus();
        this.props.actions.getMyCareerPreppedStatus();
      });
  }

  render() {
    const {
      education,
      isRequesting,
      isUpdating,
      isDeleting,
      isPublicProfile,
      showVisibilitySelect,
      onChangeVisibility,
      isEditingSections
    } = this.props;
    const { errors, educationData, isEditing, isSubmitting, editingId } = this.state;

    if(education.length === 0 && isPublicProfile){
      return null;
    }

    return (
      <div className="profile-item education">
        <ProfileCardHeader
          isEditing={isEditing}
          isEditingSections={isEditingSections}
          type="list"
          title="Education"
          onEditClick={this.onEditClick}
          isPublicProfile={isPublicProfile}
          showVisibilitySelect={showVisibilitySelect}
          onChangeVisibility={onChangeVisibility}
          icon="education"/>

        {isRequesting ? (
          <Loading />
        ) : isEditing ? (
          <div className="profile-item__card">
            <EducationForm
              errors={errors}
              isSubmitting={isSubmitting}
              isUpdating={isUpdating}
              isDeleting={isDeleting}
              showDelete={editingId !== null}
              educationData={educationData}
              onChange={this.onChange}
              onChangeMajor={this.onChangeMajor}
              onChangeProgram={this.onChangeProgram}
              onSave={this.onSave}
              onCancel={(this.props.onCancelModal) ? this.props.onCancelModal : this.onCancel}
              onDelete={this.onDelete}/>
          </div>
        ) : (
          <EducationList
            onEdit={this.onEditEducation}
            education={education}
            isPublicProfile={isPublicProfile}/>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const educationData = {
    startdate: '',
    enddate: '',
    noend: false,
    program: '',
    major: '',
    gpa: '',
    institution: '',
    insurl: '',
  };

  return {
    educationData,
    education: state.education.data,
    isRequesting: state.education.isRequesting,
    isDeleting: state.education.isDeleting.status,
    isUpdating: state.education.isUpdating.status
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    educationRequest,
    saveEducation,
    updateEducation,
    deleteEducation,
    updateMyCareerPreppedStatus,
    getMyCareerPreppedStatus,
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Education);
