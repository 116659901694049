import PropTypes from 'prop-types';
import React from 'react';
import _isObject from 'lodash/isObject';
import Button from '../../common/Button';
import TextInput from '../../common/TextInput';
import QuillEditor from '../../common/QuillEditor';
import classNames from 'classnames';


const modules = {
  toolbar: [
    [{ 'align': [] }],
    ['bold', 'italic', 'underline'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{ 'indent': '-1' }, { 'indent': '+1' }],  
    ['link'],
  ],
  clipboard: {
    matchVisual: false,
  }
};

const AssociationForm = ({
  association,
  onChange,
  onSave,
  isDeleting,
  onCancel,
  onDelete,
  isSubmitting,
  isUpdating,
  errors,
  showDelete,
  onChangeDescription
}) => {
  const nameLabel = (
    <span>
      Organization Name: 
    </span>
  );

  if (_isObject(association.startdate)) {
    association.startdate = association.startdate.date.replace(' 00:00:00.000000', '');
  }

  if (_isObject(association.enddate)) {
    association.enddate = association.enddate.date.replace(' 00:00:00.000000', '');
  }

  const endDateNote = association.noend ? '' : 'Required';

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <TextInput
            name="name"
            label={nameLabel}
            note="Required"
            placeholder="Enter your organization name"
            error={errors.name}
            value={association.name}
            onChange={onChange}/>

          <div style={{marginBottom: 10}}
            className={classNames('statement-form-container', {
              'has-danger': errors.description
            })}>
            <label className="form-control-label">Description:</label>
            <QuillEditor
              modules={modules}
              placeholder="Enter your association description"
              onChange={onChangeDescription}
              value={association.description}/>
            {errors.description &&
              <div className="text-danger">{errors.description}</div>}
          </div>
        </div>
        <div className="col-lg-6">
          <TextInput
            name="url"
            label="Organization URL:"
            placeholder="Enter your organization URL"
            error={errors.url}
            value={association.url}
            onChange={onChange}/>
        </div>
        <div className="col-lg-6">
          <TextInput
            name="position"
            label="Position(s) Held:"
            placeholder="Enter positions held"
            error={errors.position}
            value={association.position}
            onChange={onChange}/>
        </div>
        <div className="col-lg-12">
          <div className="row date">
            <div className="col-lg-6">
              <TextInput
                type="date"
                name="startdate"
                label="Start Date: "
                note="Required"
                error={errors.startdate}
                value={association.startdate}
                onChange={onChange}/>
            </div>
            <div className="col-lg-6">
              <TextInput
                type="date"
                name="enddate"
                label="End Date: "
                note={endDateNote}
                error={errors.enddate}
                value={association.enddate}
                disabled={association.noend}
                onChange={onChange}/>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-check mb-3">
              <input
                name="noend"
                checked={association.noend}
                className="form-check-input"
                type="checkbox"
                onChange={onChange}/>
              <label className="form-check-label">Check if present</label>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="action-bar text-right">
            {showDelete &&
              <Button
                type="submit"
                onClick={onDelete}
                styles={{marginLeft: 0}}
                disabled={isDeleting}
                buttonClass="btn-danger"
                buttonText={isDeleting ? 'Deleting...' : 'Delete'}/>}

            <Button
              type="button"
              onClick={onCancel}
              buttonClass="btn-secondary"
              buttonText="Cancel"/>

            <Button
              type="submit"
              onClick={onSave}
              disabled={isSubmitting || isUpdating}
              buttonClass="btn-primary"
              buttonText={isSubmitting || isUpdating ? 'Saving...' : 'Save'}/>

          </div>
        </div>
      </div>

    </div>
  );
};

AssociationForm.propTypes = {
  onDelete: PropTypes.func,
  isUpdating: PropTypes.bool,
  isDeleting: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  association: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  showDelete: PropTypes.bool,
  onChangeDescription: PropTypes.func.isRequired
};

export default AssociationForm;
