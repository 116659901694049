import React from 'react';

import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';

import EvidenceList from './EvidenceList';
import LinkPortfolioFilesModal from '../../../containers/skill-builders/LinkPortfolioFilesModal';

const BadgeSupportingEvidence = ({
  badge,
  errors,
  issuedBadge,
  selectedFile,
  onRemoveFile,
  onAddEvidence,
  isAuthenticated,
  onChangeSelectedFile,
  evidenceGuidelinesTooltipOpen,
  toggleEvidenceGuidelinesTooltip,
  displayLinkingPortfolioModal,
  onDisplayLinkingPortfolioModal,
  selectedFiles,
  onSelectFile,
  onDeleteLoadedFile,
  onInsertLinkClicked,
  showInsertTextBox,
  guidelinesTooltipOpen,
  onDropFile,
  toggleGuidelinesTooltip
}) => {
  const isFileLoaded = selectedFile.file;

  return (
    <div className="mt-1 evidence">
      <strong>Add Portfolio Evidence</strong>&nbsp;
      <a href="#" id="supporting-evidence-guidelines">(See guidelines)</a>

      <Tooltip
        placement="top"
        isOpen={evidenceGuidelinesTooltipOpen}
        target="supporting-evidence-guidelines"
        toggle={toggleEvidenceGuidelinesTooltip}>
        <div className="inside-tooltip" style={{fontSize: 12}}>
          <div><b>Portfolio File Guidelines</b></div>
          <div style={{textAlign: 'left', marginTop: 10}}>
            Add portfolio files that support your claims of having this skill.
          </div>

          <div style={{textAlign: 'left', marginTop: 10}}><b>All items must...</b></div>
          <ul style={{padding: '0px 0px 20px 20px', textAlign: 'left', marginBottom: 0}}>
            <li>not violate copyright or any other laws</li>
            <li>not be confidential or sensitive in nature</li>
            <li>respect privacy of others. (You can remove/blackout names from evidence you upload and indicate "For confidentiality, names have been removed.")</li>
          </ul>
        </div>
      </Tooltip>

      <div className="evidence-container">
        {(!isAuthenticated || !badge) &&
          <button
            className="btn add-evidence-button"
            disabled={!badge}
            onClick={onAddEvidence}>
            Add portfolio files that support your claims of having this skill.
          </button>}

        {(!isFileLoaded && isAuthenticated && badge) &&
          <p className="mt-1 mb-1 add-evidence" onClick={onDisplayLinkingPortfolioModal}>
            Add portfolio files that support your claims of having this skill.
          </p>}

        <hr/>
        <EvidenceList
          files={issuedBadge.files}
          onRemoveFile={onRemoveFile}/>
      </div>

      {displayLinkingPortfolioModal &&
        <LinkPortfolioFilesModal
          errors={errors}
          selectedFile={selectedFile}
          isOpen={displayLinkingPortfolioModal}
          selectedFiles={selectedFiles}
          onSelectFile={onSelectFile}
          onAddEvidence={onAddEvidence}
          onDeleteLoadedFile={onDeleteLoadedFile}
          onInsertLinkClicked={onInsertLinkClicked}
          showInsertTextBox={showInsertTextBox}
          guidelinesTooltipOpen={guidelinesTooltipOpen}
          onChangeSelectedFile={onChangeSelectedFile}
          onDropFile={onDropFile}
          toggleGuidelinesTooltip={toggleGuidelinesTooltip}/>}
    </div>
  );
};

BadgeSupportingEvidence.propTypes = {
  badge: PropTypes.object,
  errors: PropTypes.object.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  onAddEvidence: PropTypes.func.isRequired,
  issuedBadge: PropTypes.object.isRequired,
  selectedFile: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  onChangeSelectedFile: PropTypes.func.isRequired,
  evidenceGuidelinesTooltipOpen: PropTypes.bool.isRequired,
  toggleEvidenceGuidelinesTooltip: PropTypes.func.isRequired,
  selectedFiles: PropTypes.array.isRequired,
  onSelectFile: PropTypes.func.isRequired,
  onDeleteLoadedFile: PropTypes.func.isRequired,
  onInsertLinkClicked: PropTypes.func.isRequired,
  showInsertTextBox: PropTypes.bool.isRequired,
  guidelinesTooltipOpen: PropTypes.bool.isRequired,
  onDropFile: PropTypes.func.isRequired,
  toggleGuidelinesTooltip: PropTypes.func.isRequired,
  displayLinkingPortfolioModal: PropTypes.bool,
  onDisplayLinkingPortfolioModal: PropTypes.func.isRequired
};

export default BadgeSupportingEvidence;
