import { isUndefined } from 'lodash';

import initialState from './initialState';

import {
  GROUPS_REQUEST,
  GROUPS_SUCCESS,
  GROUPS_FAILURE,
  GROUP_ADMINS_REQUEST,
  GROUP_ADMINS_SUCCESS,
  GROUP_ADMINS_FAILURE,
  RESET_GROUP_ADMINS,
  CREATE_GROUPS_REQUEST,
  CREATE_GROUPS_SUCCESS,
  CREATE_GROUPS_FAILURE,
  RESET_SUBMITTING_DUPLICATE_GROUPS,
  ONCHANGE_GROUPS_SEARCH_QUERY,
  UPDATE_GROUP_REQUEST,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAILURE,
  UPDATE_GROUP_ADD_ADMINS_REQUEST,
  UPDATE_GROUP_ADD_ADMINS_SUCCESS,
  UPDATE_GROUP_ADD_ADMINS_FAILURE,
  UPDATE_GROUP_REMOVE_ADMINS_REQUEST,
  UPDATE_GROUP_REMOVE_ADMINS_SUCCESS,
  UPDATE_GROUP_REMOVE_ADMINS_FAILURE,
  SET_SELECTED_GROUP,
  SET_SELECTED_PARENT_GROUP,
  SET_IS_AUTH_USER_SELECTED_INSTITUTION_ADMIN,
} from '../../constants';

const populateGroups = (state, action) => {
  const {
    data: {
      _embedded: { groups },
      page: currentPage,
      page_size,
      page_count,
    },
  } = action;

  let {
    page: initialPage,
    pageSize: initialPageSize,
    pageCount: initialPageCount,
    selectedGroupAdmins,
    selectedGroup: defaultSelectedGroup,
  } = initialState.groups;

  const page = isUndefined(currentPage) ? initialPage : currentPage;
  const pageSize = isUndefined(page_size) ? initialPageSize : page_size;
  const pageCount = isUndefined(page_count) ? initialPageCount : page_count;

  if (state.isAuthUserSelectedInstitutionAdmin) {
    const allGroups = {
      id: 'all-groups',
      name: 'All Groups',
      label: 'All Groups',
      value: 'all-groups',
    };

    defaultSelectedGroup =
      state.data.length === 0 && groups.length === 1 ? groups[0] : allGroups;
    const dataArray =
      state.data.length === 0 && groups.length === 1
        ? groups
        : state.isInitialRequest
          ? [allGroups, ...groups]
          : [...state.data, ...groups];

    return {
      data: [...dataArray],

      page,
      pageSize,
      pageCount,
      selectedGroupAdmins,
      selectedGroup: defaultSelectedGroup,
      selectedParentGroup: defaultSelectedGroup,
      isChildGroupSelected: false,
      isRequesting: false,
      isInitialRequest: false,
    };
  }

  defaultSelectedGroup = groups.length === 0 ? defaultSelectedGroup : groups[0];
  const dataArray = state.isInitialRequest
    ? groups
    : [...state.data, ...groups];

  return {
    page,
    pageSize,
    pageCount,
    data: [...dataArray],
    selectedGroupAdmins,
    selectedGroup: defaultSelectedGroup,
    selectedParentGroup: defaultSelectedGroup,
    isChildGroupSelected: false,
    isRequesting: false,
    isInitialRequest: false,
  };
};

const populateSelectedGroup = (state, action) => {
  if (
    state.isAuthUserSelectedInstitutionAdmin &&
    action.selectedGroup.id === 'all-groups'
  ) {
    return {
      id: 'all-groups',
      name: 'All Groups',
      label: 'All Groups',
      value: 'all-groups',
    };
  }

  return {
    ...state.selectedGroup,
    ...action.selectedGroup,
  };
};

const updateGroupState = (state, action) => {
  if (state.isChildGroupSelected) {
    return state.data.map((group) => {
      if (group.id === state.selectedParentGroup.id) {
        const childIndex = group.children.findIndex(
          group => group.id === state.selectedGroup.id
        );

        return {
          ...group,
          children: [
            ...group.children.slice(0, childIndex),
            action.data,
            ...group.children.slice(childIndex + 1),
          ],
        };
      }

      return group;
    });
  }

  const parentIndex = state.data.findIndex(
    group => group.id === state.selectedGroup.id
  );

  return [
    ...state.data.slice(0, parentIndex),
    action.data,
    ...state.data.slice(parentIndex + 1),
  ];
};

export default function reducer(state = initialState.groups, action) {
  switch (action.type) {
  case GROUPS_REQUEST:
    return Object.assign({}, state, {
      isRequesting: true,
      isInitialRequest: action.isInitialRequest,
      data: action.isInitialRequest ? initialState.groups.data : state.data,

      selectedGroup: action.isInitialRequest ?
        initialState.groups.selectedGroup : state.selectedGroup,

      selectedParentGroup: action.isInitialRequest ?
        initialState.groups.selectedParentGroup : state.selectedParentGroup
    });

  case GROUPS_SUCCESS:
    return Object.assign({}, state, populateGroups(state, action));

  case GROUPS_FAILURE:
    return Object.assign({}, state, {
      isRequesting: false,
      isInitialRequest: false,
    });

  case GROUP_ADMINS_REQUEST:
    return Object.assign({}, state, {
      isRequestingGroupAdmins: true,
    });

  case GROUP_ADMINS_SUCCESS:
    return Object.assign({}, state, {
      selectedGroupAdmins: action.data._embedded.admins,

      groupAdminsPage: (isUndefined(action.data.page) || (action.data.page === 0)) ?
        initialState.groups.groupAdminsPage : action.data.page,

      groupAdminsPageSize: (isUndefined(action.data.page_size)) ?
        initialState.groups.groupAdminsPageSize : action.data.page_size,

      groupAdminsPageCount: (isUndefined(action.data.page_count) || (action.data.page_count === 0)) ?
        initialState.groups.groupAdminsPageCount : action.data.page_count,

      isRequestingGroupAdmins: false,
    });

  case GROUP_ADMINS_FAILURE:
    return Object.assign({}, state, { isRequestingGroupAdmins: false });

  case RESET_GROUP_ADMINS:
    return Object.assign({}, state, {
      selectedGroupAdmins: initialState.groups.selectedGroupAdmins,
      isChildGroupSelected: false,
    });

  case CREATE_GROUPS_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: { status: true, parentGroupId: action.id },
    });

  case CREATE_GROUPS_SUCCESS:
    return Object.assign({}, state, {
      data: [...action.data.groups, ...state.data],
      isSubmitting: {
        status: false,
        parentGroupId: null,
        duplicateGroups: action.data.duplicateGroups,
      },
    });

  case CREATE_GROUPS_FAILURE:
    return Object.assign({}, state, {
      isSubmitting: { status: false, parentGroupId: null },
    });

  case RESET_SUBMITTING_DUPLICATE_GROUPS:
    return Object.assign({}, state, {
      isSubmitting: { ...initialState.groups.isSubmitting },
    });

  case UPDATE_GROUP_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true },
    });

  case UPDATE_GROUP_SUCCESS:
    return Object.assign({}, state, {
      data: [...updateGroupState(state, action)],
      isUpdating: { status: false },
    });

  case UPDATE_GROUP_FAILURE:
    return Object.assign({}, state, {
      isUpdating: { status: false },
    });

  case SET_SELECTED_GROUP:
    return Object.assign({}, state, {
      selectedGroup: {
        ...populateSelectedGroup(state, action),
      },
      isChildGroupSelected: action.isChildGroupSelected,
    });

  case SET_SELECTED_PARENT_GROUP:
    return Object.assign({}, state, {
      selectedGroup: action.selectedParentGroup,
      selectedParentGroup: action.selectedParentGroup,
    });

  case ONCHANGE_GROUPS_SEARCH_QUERY:
    return Object.assign({}, state, {
      filters: {
        ...state.filters,
        searchQuery: action.searchQuery,
      },
    });

  case SET_IS_AUTH_USER_SELECTED_INSTITUTION_ADMIN:
    return Object.assign({}, state, {
      isAuthUserSelectedInstitutionAdmin: action.isAdmin,
    });

  case UPDATE_GROUP_ADD_ADMINS_REQUEST:
    return Object.assign({}, state, { isUpdating: { status: true } });

  case UPDATE_GROUP_ADD_ADMINS_SUCCESS:
    return Object.assign({}, state, { isUpdating: { status: false } });

  case UPDATE_GROUP_ADD_ADMINS_FAILURE:
    return Object.assign({}, state, { isUpdating: { status: false } });

  case UPDATE_GROUP_REMOVE_ADMINS_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, adminId: action.id },
    });

  case UPDATE_GROUP_REMOVE_ADMINS_SUCCESS:
    return Object.assign({}, state, {
      selectedGroupAdmins: [
        ...state.selectedGroupAdmins.filter(
          admin => admin.id !== state.isUpdating.adminId
        ),
      ],
      isUpdating: { status: false, adminId: null },
    });

  case UPDATE_GROUP_REMOVE_ADMINS_FAILURE:
    return Object.assign({}, state, {
      isUpdating: { status: false, adminId: null },
    });

  default:
    return state;
  }
}
