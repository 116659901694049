import PropTypes from 'prop-types';
import React from 'react';

import Button from '../../common/Button';
import Creatable from 'react-select/creatable/dist/react-select.esm';

import { checkIfEnterHit } from '../../../utils';

const AddSkillForm = ({ skills, onChange, onSave, onCancel, error }) => {
  let wrapperClass = 'col-sm-12';

  if (error.length !== 0) {
    wrapperClass += ' ' + 'invalid';
  }

  return (
    <div>
      <div className="row">
        <div className={wrapperClass}>
          <label>Name: <span className="text-danger">(required)</span></label>
          <Creatable
            name="name"
            placeholder="Enter your skill name"
            onChange={onChange}
            value={skills}
            className={'form-group'}
            backspaceRemoves
            shouldKeyDownEventCreateNewOption={checkIfEnterHit}
            isMulti/>

          {(error.length !== 0) &&
            <div
              className="form-group"
              style={{ color: '#d9534f', fontSize: 15, marginTop: -12 }}>
              Required
            </div>}
        </div>
      </div>

      <div className="action-bar mt-0 text-right">
        <Button
          type="button"
          onClick={onCancel}
          buttonClass="btn-secondary"
          buttonText="Cancel"
          styles={{marginLeft: 0}}/>
        <Button
          type="submit"
          onClick={onSave}
          buttonClass="btn-primary"
          buttonText="Save"/>
      </div>
    </div>
  );
};

AddSkillForm.propTypes = {
  error: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  skills: PropTypes.array.isRequired
};

export default AddSkillForm;
