import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextInput from '../../components/common/TextInput';
import Validator from '../../validator';
import { bindActionCreators } from 'redux';
import * as jobBoardActions from '../../redux/actions/job-boards';
import * as componentsActions from '../../redux/actions/components';

class JobConnectEditForm extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    contact: PropTypes.object.isRequired,
    isUpdating: PropTypes.object.isRequired
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      contact: this.props.contact
    };

    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  isValid(field = null) {
    const validate = Validator.createValidator({
      name: ['required'],
      email: ['required', 'email'],
      phone: ['required', 'phone'],
    }, this.state.contact, field);

    const { isValid, errors } = validate;

    this.setState({ errors });

    return isValid;
  }

  handleResponse() {
    const { contactId } = this.state.contact;

    if (contactId)
      this.props.actions
        .unloadForm(contactId);
  }

  onSave(event){
    event.preventDefault();

    const { id, name, phone, email} = this.state.contact;
    const { actions } = this.props;

    if (!this.isValid()) return ;

    actions.updateJobDetails({
      id:id,
      cname: name,
      cemail: email,
      cnumber: phone
    })
      .then(() => this.handleResponse());
  }

  onChange(event){
    const { name, value } = event.target;
    const { contact } = this.state;

    contact[name] = value;

    this.setState({ contact });

    this.isValid(name);
  }

  onCancel(){
    const { contactId } = this.state.contact;

    this.props.actions
      .unloadForm(contactId);
  }

  render() {
    const { contact, errors } = this.state;
    const { isUpdating } = this.props;

    return (
      <div>
        <form className="">
          <div className="form-group">
            <TextInput
              error={errors.name}
              name="name"
              className="form-control"
              placeholder="Contact Name"
              onChange={this.onChange}
              value={contact.name} />
          </div>
          <div className="form-group">
            <TextInput
              error={errors.email}
              name="email"
              className="form-control"
              placeholder="Contact Email"
              onChange={this.onChange}
              value={contact.email} />
          </div>
          <div className="form-group">
            <TextInput
              error={errors.phone}
              name="phone"
              className="form-control"
              placeholder="Contact Phone"
              onChange={this.onChange}
              value={contact.phone} />
          </div>
          <div className="form-group text-right">
            <button
              type="button"
              name="button"
              className="btn btn-secondary"
              onClick={this.onCancel}>
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              style={{ marginLeft: 8 }}
              name="button"
              onClick={this.onSave}>
              {isUpdating.status ? 'Updating ...' : 'Update'}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let contact = {
    id: '',
    name: '',
    email: '',
    phone: '',
    contactId: ''
  };

  if (ownProps.contact) {
    contact = Object.assign(
      {},
      contact,
      ownProps.contact
    );
  }

  return {
    contact,
    isUpdating: state.jobBoards.isUpdating
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, jobBoardActions, componentsActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobConnectEditForm);
