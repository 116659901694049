import React from 'react';

import { loadForm } from '../../../utils';

import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import { Link } from 'react-router-dom';

import WallComment from '../WallComment';
import Loading from '../../common/Loading';
import WallSocialActions from '../WallSocialActions';
import DashboardPostEdit from '../../../containers/dashboard/DashboardPostEdit';
import SharedPostEdit from '../../../containers/dashboard/SharedPostEdit';
import Timestamp from '../../skill-builders/discussions/Timestamp';
import WallPostActions from '../../../containers/dashboard/WallPostActions';
import PostItemContainer from '../PostItemContainer';
import ShowMoreComments from '../../skill-builders/discussions/ShowMoreComments';
import WallCommentFormHandler from '../../../containers/dashboard/WallCommentFormHandler';
import SharedPostOwnerText from './SharedPostOwnerText';

const WallCard = ({
  profile,
  wallPost,
  isMobile,
  wallPostComments,
  discussionComments,
  postIndex,
  loadForms,
  onDeleteClick,
  onshowMore,
  showMore,
  actualCommentCount,
  currentUser,
  isRequesting,
  renderMoreComments,
  isUserLikesTooltipOpen,
  toggleUserLikesTooltip,
  toggleUserLikesListModal
}) => {
  const renderedCommentsCount = discussionComments.reduce(
    (acc, curr)=> curr.replies ? acc + curr.replies.length + 1 : acc + 1, 0);

  const isLoadForm = loadForm(loadForms, `update-wall-post-form-${wallPost.id}`);
  const avatarAvailable = wallPost.user.avatar && wallPost.user.avatar.length !== 0;
  const isSharedPost = wallPost.sharedEntityType !== '';
  let postType = null;

  switch(wallPost.sharedEntityType){
  case 'interviewResource':
    postType = 'video';
    break;

  case 'wall':
    postType = 'post';
    break;

  case 'badgeIssued':
    postType = 'badge';
    break;

  case 'file':
    postType = 'portfolio file';
    break;

  case 'discussion':
    postType = 'discussion';
    break;

  default:
    postType = 'post';
    break;
  }

  return (
    <div className="post">
      <div className="primary-post">
        <div className="left">
          <Link to={`/cp/${wallPost.user.vanity}`}>
            {avatarAvailable ?
              <img
                className="header-image medium align-top"
                width="64"
                height="64"
                src={wallPost.user.avatar} alt="User Avatar"/> :
              <span
                className="header-image medium align-top initial-avatar">
                {wallPost.user.name.slice(0,1)}
              </span>}
          </Link>
        </div>
        <div className="right">
          <div className="btn-group edit-menu align-top float-right">
            {(((wallPost.user.id === currentUser.id) || profile.role === 'super-admin') && !isLoadForm) &&
              <WallPostActions wallPost={wallPost} onDeleteClick={onDeleteClick}/>}
          </div>
          <div className="profile-title">
            <Link
              to={`/cp/${wallPost.user.vanity}`}
              style={{fontWeight: '500'}}
              className="profile-name">{wallPost.user.name}
            </Link>

            {isSharedPost && wallPost.sharedEntityType !== 'skillBuilderReflections' &&
              <SharedPostOwnerText postType={postType} wallPost={wallPost}/>}

            {isSharedPost && wallPost.sharedEntityType === 'skillBuilderReflections' &&
              <span className="shared-type-text">&nbsp;shared
                {wallPost.user.name === wallPost.sharedSkillBuilderReflections[0].user.name ?
                  ' their reflection(s)' :
                  <span>
                    &nbsp;
                    <Link
                      className="profile-name"
                      to={`/cp/${wallPost.sharedSkillBuilderReflections[0].user.vanity}`}
                      target="_blank"
                      title={`Link to ${wallPost.sharedSkillBuilderReflections[0].user.name}'s webpage`}>
                      {wallPost.sharedSkillBuilderReflections[0].user.name}
                    </Link>'s reflection(s)
                  </span>}
              </span>}

            <Timestamp postedAt={wallPost.posted.date} visibilty={wallPost.permissions}/>
          </div>

          {isLoadForm &&
            (!isSharedPost?
              <DashboardPostEdit
                wallId={wallPost.id}
                wallPost={wallPost}/> :
              <SharedPostEdit
                postType={postType}
                showMore={showMore}
                onshowMore={onshowMore}
                wallPost={wallPost}/>)}

          {!isLoadForm &&
            <PostItemContainer
              showMore={showMore}
              onshowMore={onshowMore}
              wallPost={wallPost} />}

          <div className="share-stats">
            <span
              id={`tooltip-${wallPost.id}`}
              className="likes"
              onClick={toggleUserLikesListModal}>
              {wallPost.like_count} Likes
            </span>

            {(wallPost.likesList.length > 0) && (! isMobile) &&
              <Tooltip
                placement="bottom"
                isOpen={isUserLikesTooltipOpen}
                target={`tooltip-${wallPost.id}`}
                toggle={toggleUserLikesTooltip}>
                <div className="inside-tooltip" style={{fontSize: 12}}>
                  <ul style={{padding: '10px', textAlign: 'left', marginBottom: 0, listStyle: 'none'}}>
                    {
                      wallPost.likesList.map((user, index) =>
                        <li key={index}>{user.name}</li>)
                    }
                  </ul>
                </div>
              </Tooltip>}

            <span style={{paddingLeft:5}}>
              {wallPost.comment_count} Comments
            </span>
          </div>
        </div>
        <WallSocialActions
          type="wall-post"
          wallPost={wallPost}
          postIndex={postIndex}/>
      </div>
      <WallComment
        loadForms={loadForms}
        wallPost={wallPost}
        postComments={wallPostComments}/>

      {isRequesting.status && isRequesting.wallPostId === wallPost.id ?
        <div className="showMoreBtn">
          <Loading loaderStyles={{ marginTop: 0 }}/>
        </div> :
        <ShowMoreComments
          actualCommentCount={actualCommentCount}
          renderedCommentsCount={renderedCommentsCount}
          renderMoreComments={renderMoreComments}/>}

      <WallCommentFormHandler wallPostId={wallPost.id}/>
    </div>
  );
};

WallCard.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
  wallPost: PropTypes.object.isRequired,
  wallPostComments: PropTypes.array.isRequired,
  discussionComments: PropTypes.array,
  postIndex: PropTypes.number,
  loadForms: PropTypes.array.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onshowMore: PropTypes.func.isRequired,
  showMore: PropTypes.object.isRequired,
  actualCommentCount: PropTypes.number,
  isRequesting: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  renderMoreComments: PropTypes.func.isRequired,
  isUserLikesTooltipOpen: PropTypes.bool.isRequired,
  toggleUserLikesTooltip: PropTypes.func.isRequired,
  toggleUserLikesListModal: PropTypes.func.isRequired
};

export default WallCard;
