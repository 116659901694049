import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

const CareerInterestsListModal = ({ isOpen, onClose, careerClusters }) => {
  return (
    <Modal
      size="lg"
      className="modal-margin-top"
      backdrop="static"
      isOpen={isOpen}
      toggle={onClose}>
      <ModalHeader
        className="no-border"
        toggle={onClose}>Career Interests</ModalHeader>
      <ModalBody>
        <ul>
          {careerClusters.map(cluster =>
            <li key={cluster.occupationId}>{cluster.occupationName}</li>)}
        </ul>
      </ModalBody>
      <ModalFooter className="no-border">
        <button
          onClick={onClose}
          className="btn btn-secondary float-left">
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
};

CareerInterestsListModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  careerClusters: PropTypes.array.isRequired
};

export default CareerInterestsListModal;
