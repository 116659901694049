import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as searchActions from '../../redux/actions/search';
import * as routerActions from '../../redux/actions/router';

import Loading from '../../components/common/Loading';
import SkillBadgesSearchItem from '../../components/search/SkillBadgesSearchItem';
import PaginationHandler from '../../components/common/PaginationHandler';

class CpSkillBadgesSearch extends Component {
  static propTypes = {
    search: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      sort: 'ascending',
    };

    this.onNext = this.onNext.bind(this);
    this.onPrev = this.onPrev.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
    this.handleSort = this.handleSort.bind(this);
  }

  componentWillMount(){
    const { actions, search } = this.props;

    if(search.query && search.query !== ''){
      actions.clearSearchReducer();
      actions.cpSearch(search.query, 'badges');
    }
  }

  componentWillReceiveProps(nextProps){
    const { query: nextQuery } = nextProps.search;
    const { query } = this.props.search;

    if(nextQuery && nextQuery !== query){
      this.props.actions.clearSearchReducer();
      this.props.actions.cpSearch(nextQuery, 'badges');
    }
  }

  handleSort(event){
    const sort = event.target.value;

    if (sort === this.state.sort)
      return;

    this.setState({
      sort
    }, () => {
      const { actions, search } = this.props;
      const { query, paginationData } = search;
      actions.clearSearchReducer();
      actions.cpSearch(query, 'badges', paginationData.page, sort );
    });
  }

  onNext(){
    const { actions, search } = this.props;
    const { query, paginationData } = search;
    actions.clearSearchReducer();
    actions.cpSearch(query, 'badges', paginationData.page + 1);
  }

  onPrev(){
    const { actions, search } = this.props;
    const { query, paginationData } = search;
    actions.clearSearchReducer();
    actions.cpSearch(query, 'badges', paginationData.page - 1);
  }

  onSelectPage(page){
    return () => {
      const { sort } = this.state;
      const { actions, search } = this.props;
      const { query } = search;
      actions.clearSearchReducer();
      actions.cpSearch(query, 'badges', page, sort);
    };
  }

  render() {
    const { sort } = this.state;
    const { data: results, paginationData, query, isRequesting } = this.props.search;

    if(query === '' || !query){
      return (
        <div className="searchResultsContainer">
          <div className="row search-hint no-badges-claimed-hint mt-0">
            <div className="col-sm-12">
              <span className="fa fa-info-circle" aria-hidden="true"/>
              <p>Search People, Skill Badges, Portfolio Files, or Posts.</p>
            </div>
          </div>
        </div>
      );
    }

    if(isRequesting && query && query !== ''){
      return(
        <div className="loading-container">
          <Loading/>
        </div>
      );
    }
    return (
      <div className="searchResultsContainer">
        <div className="container">
          <div className="searchResultsContent">
            <div
              style={{borderBottom: 'none'}}
              className="searchResultsHeader clearfix">
              <div className="row align-items-center">
                <div className="col searchHeader">
                  <h2>Showing <strong>{paginationData.total_items} Results</strong> for <strong>"{query}"</strong></h2>
                </div>
                {
                  results.badges.length > 1 &&
                    <div className="col-md-auto sortBy">
                      <form>
                        <div className="form-inline form-group mb-0">
                          <strong className="align-middle">
                            Sort by&nbsp;
                          </strong>
                          <select
                            value={sort}
                            onChange={this.handleSort}
                            className="form-control form-control-sm">
                            <option value="ascending">Ascending</option>
                            <option value="descending">Descending</option>
                          </select>
                        </div>
                      </form>
                    </div>
                }
              </div>
            </div>
            <div className="row" id="list-grid-view">
              <div className="badges col">

                {results.badges.length === 0 &&
                  <div className="empty-search-container">
                    <div className="message-box">
                      <h4>No results found</h4>
                      <div>Sorry, there were no matches for your search query.</div>
                    </div>
                  </div>}

                {results.badges.map((badge, index) =>
                  <SkillBadgesSearchItem key={index} badge={badge}/>)}
              </div>
            </div>
            {
              !isRequesting && results.badges.length > 0 &&
                <PaginationHandler
                  page={paginationData.page}
                  page_count={paginationData.page_count}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSelectPage={this.onSelectPage}/>
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    search: state.search,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, searchActions, routerActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(CpSkillBadgesSearch);
