import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'reactstrap';

const ePortfolioIcon = require('../../assets/images/career-portfolio-modal.png');

const EPortfolioModal = ({showEPortfolioModal, toggleEPortfolioModal,toggleOrgContent}) => {
  const CareerPortfolioContent = toggleOrgContent ?
    (<div>
      <p className="mt-3" style={{fontSize:14}}>Every learner gets unlimited space to upload multimedia files evidencing their skills with privacy settings to control visibility.</p>
    </div>) :
    (<div>
      <p className="mt-3" style={{fontSize:14}}>
        You get unlimited space to upload multimedia files evidencing your skills such as project achievements, work samples and credentials. Privacy settings let you control visibility.
      </p>
    </div>);
  return (
    <Modal className="custom-md-modal" isOpen={showEPortfolioModal} toggle={toggleEPortfolioModal} size={'md'}>
      <div className="modal1" id="portfolioFiles">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel">Career Portfolio</h4>
            <button type="button" className="close" onClick={toggleEPortfolioModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="text-center modal-body">
            <img className="p-3 img-fluid img-responsive" src={ePortfolioIcon}/>
            {CareerPortfolioContent}
          </div>
        </div>
      </div>
    </Modal>
  );
};

EPortfolioModal.propTypes = {
  showEPortfolioModal: PropTypes.bool,
  toggleOrgContent: PropTypes.bool,
  toggleEPortfolioModal: PropTypes.func
};

export default EPortfolioModal;
