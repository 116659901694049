import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import update from 'immutability-helper';

import * as componentsActions from '../../redux/actions/components';

import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import SkillEdit from '../../components/resume/SkillEdit';
import DeleteSectionItemConfirmModal from '../../components/resume/DeleteSectionItemConfirmModal';

class SkillsEditModal extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    isOpen: PropTypes.bool,
    skillsOrder: PropTypes.array,
    resumeSkills: PropTypes.object.isRequired,
    onChangeSection: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      showConfirmModal: false,
      removeSkillId: null,
      skillsOrder: this.props.resumeSkills.skillsOrder,
      skills: this.props.resumeSkills.skillsArray,
    };

    this.closeModal = this.closeModal.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.moveCard = this.moveCard.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onCancelConfirm = this.onCancelConfirm.bind(this);
    this.onCloseModalConfirm = this.onCloseModalConfirm.bind(this);
    this.onCancelRemoveSkill = this.onCancelRemoveSkill.bind(this);
    this.onConfirmRemoveSkill = this.onConfirmRemoveSkill.bind(this);
  }

  onCloseModalConfirm(){
    this.setState({ showConfirmModal: false }, () => this.props.actions.closeModal());
  }

  closeModal() {
    this.setState({ showConfirmModal: true });
  }

  onCancelConfirm(){
    this.setState({ showConfirmModal: false });
  }

  onChangeTitle(event){
    const { id, value } = event.target;
    const { skills } = this.state;
    const skillIndex = skills.findIndex(item => item.id === id);
    const skill = skills[skillIndex];
    const updatedSkill = Object.assign({}, skill, { name: value });

    const newSkillsArray = [
      ...skills.slice(0, skillIndex),
      updatedSkill,
      ...skills.slice(skillIndex + 1)
    ];

    this.setState({
      skills: newSkillsArray
    });
  }

  moveCard(dragIndex, hoverIndex) {
    const { skillsOrder } = this.state;
    const dragItem = skillsOrder[dragIndex];

    this.setState(update(this.state, {
      skillsOrder: {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragItem]
        ]
      }
    }));
  }

  onDelete(skillId) {
    return () => {
      this.setState({ removeSkillId: skillId });
    };
  }

  onConfirmRemoveSkill() {
    const { skills, skillsOrder, removeSkillId } = this.state;
    const skillIndex = skills.findIndex(item => item.id === removeSkillId);

    const newSkillsArray = [
      ...skills.slice(0, skillIndex),
      ...skills.slice(skillIndex + 1)
    ];

    this.setState({
      skills: newSkillsArray,
      skillsOrder: skillsOrder.filter(skill => skill.id !== removeSkillId)
    });

    this.onCancelRemoveSkill();
  }

  onCancelRemoveSkill() {
    this.setState({ removeSkillId: null });
  }

  onSave() {
    const { skills: skillsArray, skillsOrder } = this.state;
    const { onChangeSection, resumeSkills } = this.props;

    const data = Object.assign({}, resumeSkills, { skillsArray, skillsOrder });

    onChangeSection(data, 'skills');
    this.onCloseModalConfirm();
  }

  render() {
    const { skillsOrder, skills, showConfirmModal, removeSkillId } = this.state;
    const { isOpen } = this.props;

    const isConfirmRemoveModalOpen = removeSkillId !== null;

    return (
      <Modal
        isOpen={isOpen}
        toggle={this.closeModal}
        backdrop="static"
        size="lg">
        <ModalHeader
          toggle={this.closeModal}>
          Edit Your Skills
        </ModalHeader>
        <ModalBody>
          <div>
            {showConfirmModal &&
              <Modal
                className="modal-margin-top"
                isOpen={showConfirmModal}
                toggle={this.onCancelConfirm}
                backdrop="static">
                <ModalBody>
                  <div>
                    Are you sure you want to close this modal and discard any changes you made?
                  </div>
                </ModalBody>
                <ModalFooter>
                  <button
                    onClick={this.onCancelConfirm}
                    className="btn btn-secondary">
                    No
                  </button>

                  <button
                    onClick={this.onCloseModalConfirm}
                    className="btn btn-primary">
                    Yes, Close Modal
                  </button>
                </ModalFooter>
              </Modal>}

            {isConfirmRemoveModalOpen &&
              <DeleteSectionItemConfirmModal
                onCancel={this.onCancelRemoveSkill}
                onConfirm={this.onConfirmRemoveSkill}
                isOpen={isConfirmRemoveModalOpen}/>}

            {skillsOrder.map((order, index) => {
              const skill = skills.find(item => item.id === order.id);

              if (! skill) return null;

              return (
                <SkillEdit
                  key={index}
                  index={index}
                  onChangeTitle={this.onChangeTitle}
                  moveCard={this.moveCard}
                  onDelete={this.onDelete}
                  skill={skill}/>
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            onClick={this.closeModal}
            className="btn btn-secondary">
            Cancel
          </button>
          <button
            onClick={this.onSave}
            className="btn btn-primary">
            Save
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, componentsActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(null, mapDispatchToProps)(SkillsEditModal);
