import { CALL_API } from '../../middlewares/api';

import {
  RESET_FOLLOWER_DATA,
  FOLLOWERS_REQUEST,
  FOLLOWERS_SUCCESS,
  FOLLOWERS_FAILURE,
  CREATE_FOLLOWER_REQUEST,
  CREATE_FOLLOWER_SUCCESS,
  CREATE_FOLLOWER_FAILURE,
  UPDATE_FOLLOWER_REQUEST,
  UPDATE_FOLLOWER_SUCCESS,
  UPDATE_FOLLOWER_FAILURE,
  DELETE_FOLLOWER_REQUEST,
  DELETE_FOLLOWER_SUCCESS,
  DELETE_FOLLOWER_FAILURE,
} from '../../constants';

export const clearFollowersData = () => {
  return { type: RESET_FOLLOWER_DATA };
};

export const followersRequest = (isAuthenticated, userId = null, page = 1, exclude = false) => {
  let endpoint = `user/connect?inverse=1&page=${page}${! isAuthenticated ? `&user=${userId}` : ''}`;

  if(isAuthenticated && exclude) {    
    endpoint= `${endpoint}&excludeUsersWithDisableMessaging=1`;
  }
 
  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: isAuthenticated,
      types: [FOLLOWERS_REQUEST, FOLLOWERS_SUCCESS, FOLLOWERS_FAILURE],
    },
  };
};

export const saveFollower = (profile) => {
  const { id } = profile;
  const endpoint = 'user/connect';
  const data = { user: id };

  return {
    [CALL_API]: {
      data,
      profile,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        CREATE_FOLLOWER_REQUEST,
        CREATE_FOLLOWER_SUCCESS,
        CREATE_FOLLOWER_FAILURE,
      ],
    },
  };
};

export const updateFollower = (index, data) => {
  const endpoint = `user/connect/${data.id}`;

  return {
    [CALL_API]: {
      data,
      index,
      endpoint,
      method: 'put',
      authenticated: true,
      types: [
        UPDATE_FOLLOWER_REQUEST,
        UPDATE_FOLLOWER_SUCCESS,
        UPDATE_FOLLOWER_FAILURE,
      ],
    },
  };
};

export const deleteFollower = (followerId) => {
  const endpoint = `user/connect/${followerId}`;

  return {
    [CALL_API]: {
      endpoint,
      id: followerId,
      method: 'delete',
      authenticated: true,
      types: [
        DELETE_FOLLOWER_REQUEST,
        DELETE_FOLLOWER_SUCCESS,
        DELETE_FOLLOWER_FAILURE,
      ],
    },
  };
};
