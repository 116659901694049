import React, { Component } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'lodash';
import { getCareerInterest } from '../../../redux/actions/clusterIndustryOccupation';
import { updateProfile } from '../../../redux/actions/profile';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import {
  updateElementsCount,
  updateSelectedCounts,
  updateSubSelectedCounts
} from '../../../utils/MyCareerInterestDOMChanges';
import Loading from '../../../components/common/Loading';

import CloseIcon from '../../../assets/images/close.svg';

class MyCareerInterest extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    proceed: PropTypes.func,
    profile: PropTypes.object,
    isForCareerInterest: PropTypes.bool,
    isLoading: PropTypes.bool,
    clusterDetails: PropTypes.array,
    processSendRequest: PropTypes.func,
    actions: PropTypes.object.isRequired,
    allClusterDetails: PropTypes.object,
    isRequesting: PropTypes.bool,
    careerClusters: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      checked: [],
      expanded: [],
      filterText: '',
      nodesFiltered: [],
      careerTreeDOMNode: [],
      isLoading: false,
      careerInterest: [],
    };

    this.onSelectCareerInterest = this.onSelectCareerInterest.bind(this);
    this.onExpand = this.onExpand.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.filterTree = this.filterTree.bind(this);
    this.filterNodes = this.filterNodes.bind(this);
    this.toggleDetailsModal = this.toggleDetailsModal.bind(this);
    this.submitUpdateDetails = this.submitUpdateDetails.bind(this);
    this.clearAllSelections = this.clearAllSelections.bind(this);
  }

  componentDidMount(){
    this.props.actions.getCareerInterest();
  }

  componentWillReceiveProps(nextProps) {
    const {isRequesting} = this.props;
    if(isEmpty(this.state.careerInterest) && !isEmpty(nextProps.allClusterDetails.careerInterest)) {
      this.setState({
        careerInterest: nextProps.allClusterDetails.careerInterest
      }, () => this.generateAllClusterDetails());
    }

    if (!isEmpty(nextProps.allClusterDetails) && isRequesting) {
      this.showSelectedBoxes();
      this.setState({ isLoading: true });

      setTimeout(() => {
        updateElementsCount('.ci-main-category', nextProps.allClusterDetails);
        updateSelectedCounts();
        updateSubSelectedCounts();
      }, 100);
    }
  }

  componentWillUpdate() {
    updateSelectedCounts();
    updateSubSelectedCounts();
  }

  onSelectCareerInterest(checked) {
    this.setState({ checked });
  }

  onExpand(expanded) {
    this.setState({ expanded });
  }

  onFilterChange(e) {
    this.setState({ filterText: e.target.value }, this.filterTree);
  }

  filterTree() {
    if (!this.state.filterText) {
      this.setState(prevState => ({
        nodesFiltered: prevState.careerTreeDOMNode,
      }));
      return;
    }

    const nodesFiltered = prevState => (
      { nodesFiltered: prevState.careerTreeDOMNode.reduce(this.filterNodes, []) }
    );

    this.setState(nodesFiltered);
  }

  filterNodes(filtered, node) {
    const { filterText } = this.state;
    const children = (node.children || []).reduce(this.filterNodes, []);

    if ( (node.label && !isEmpty(node.label) ) && (node.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1 || children.length) ) {
      filtered.push({ ...node, children });
    }

    return filtered;
  }

  toggleDetailsModal() {
    this.props.toggleModal();
  }

  generateAllClusterDetails() {
    const allDetails = this.state.careerInterest;

    if ( allDetails && allDetails.length > 0 ) {
      this.setState({
        nodesFiltered: allDetails,
        careerTreeDOMNode:allDetails
      });
    }
  }

  showSelectedBoxes() {
    const { careerClusters } = this.props;
    let getSelectedIds = [];
    let getSelectedExpanded = [];

    careerClusters.map((clusterItem) => {
      getSelectedIds = [...getSelectedIds, `C${clusterItem.clusterId}`, `I${clusterItem.industryId}`, `O${clusterItem.occupationId}`];
      getSelectedExpanded = [...getSelectedExpanded, `C${clusterItem.clusterId}`, `I${clusterItem.industryId}`];
    });

    const checked = [...new Set(getSelectedIds)];
    const expanded = [...new Set(getSelectedExpanded)];

    this.setState({ checked, expanded });
  }

  submitUpdateDetails() {
    const {checked, careerTreeDOMNode} = this.state;
    const {profile, actions} = this.props;
    let clusterData = [];
    clusterData = {'careerCluster': []};

    let occupationsIds = [];

    careerTreeDOMNode.map((clusterItem) => {
      clusterItem.children.map((industryItem) => {
        industryItem.children.map((ocupationItem) => {
          if ( checked.includes(ocupationItem.value) ) {
            clusterData['careerCluster'].push({
              'clusterId': clusterItem.value.replace('C', ''),
              'clusterName': clusterItem.label,

              'industryId': industryItem.value.replace('I', ''),
              'industryName': industryItem.label,

              'occupationId': ocupationItem.value.replace('O', ''),
              'occupationName': ocupationItem.label
            });

            occupationsIds.push(ocupationItem.value.slice(1));
          }
        });
      });
    });

    const occupations = occupationsIds.toString();
    actions.updateProfile(profile.id, { occupations });
    //    actions.updateProfile(profile.id, clusterData);
    this.toggleDetailsModal();
  }

  clearAllSelections() {
    this.setState({
      checked: []
    });
  }

  onClick() {
  }

  render() {
    const {
      checked,
      expanded,
      filterText,
      nodesFiltered,
      isLoading,
    } = this.state;

    return (
      <Modal
        centered
        size="lg"
        className="customLevelUpModal"
        isOpen={this.props.isOpen}
        toggle={this.toggleDetailsModal}>
        <div className="modal-header">
          <span className="modal-title">Update Your Career Interests</span>
          <div
            onClick={this.toggleDetailsModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">
              <img src={CloseIcon} />
            </span>
          </div>
        </div>
        <ModalBody>
          {!isLoading &&
            <Loading loaderStyles={{ marginTop: 0 }}/>
          }

          {isLoading &&
            (
              <div className="filter-container ci-wrapper">
                <input
                  className="filter-text"
                  placeholder="Search for Career Interest"
                  type="text"
                  value={filterText}
                  onChange={this.onFilterChange}/>
                <div className="d-flex justify-content-between align-items-center mt-3 mb-2 font14">
                  <span>Choose at least three career interests</span>
                  <div onClick={this.clearAllSelections} className="font14 orange pointer" >Clear All selection</div>
                </div>
                <div className="myCareerInterest-checkbox-tree">
                  <CheckboxTree
                    checked={checked}
                    expanded={expanded}
                    iconsClass="fa5"
                    nodes={nodesFiltered}
                    onCheck={this.onSelectCareerInterest}
                    onExpand={this.onExpand}
                    showNodeIcon={false}
                    expandOnClick
                    onClick={this.onClick}/>
                </div>
              </div>
            )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.toggleDetailsModal}>
            {'Cancel'}
          </Button>
          <Button color="primary" onClick={this.submitUpdateDetails}>
            {'Update Details'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    careerClusters: state.profile.data.careerClusters,
    allClusterDetails: state.clusterIndustryOccupation.data,
    isRequesting: state.clusterIndustryOccupation.isRequesting
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCareerInterest,
      updateProfile,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyCareerInterest);
