import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { redirect } from '../../redux/actions/router';
import { displayAdvancedFilters } from '../../redux/actions/users';
import {
  getUserAdminGroups,
  setSelectedParentGroup,
  setIsAuthUserSelectedInstitutionAdmin
} from '../../redux/actions/groups';

import { Container, Row, Col } from 'reactstrap';

import AdminBreadcrumb from './AdminBreadcrumb';
import Loading from '../../components/common/Loading';
import ParentGroupDropdown from './ParentGroupDropdown';
import Dashboard from '../../components/admin/Dashboard';

class AdminDashboard extends Component {
  static propTypes = {
    actions:PropTypes.object.isRequired,
    isAdmin: PropTypes.bool,
    selectedGroup: PropTypes.object.isRequired,
    isChildGroupSelected: PropTypes.bool.isRequired,
    displayAdvancedFilters: PropTypes.bool.isRequired,
    isInitialRequest: PropTypes.bool.isRequired,
    parentGroupsCount: PropTypes.string,
    userParentGroupsCount: PropTypes.number.isRequired,
    selectedInstitutionId: PropTypes.string
  }

  constructor(props) {
    super(props);

    this.state = {
      displayAdvancedFilter: false
    };

    this.toggleAdvancedFilter = this.toggleAdvancedFilter.bind(this);
  }

  componentWillMount() {
    const {
      actions,
      isAdmin,
      isInitialRequest,
      parentGroupsCount,
      selectedInstitutionId
    } = this.props;

    if (isAdmin && (parentGroupsCount === 0)) {
      actions.redirect('/organization/admin/manage-groups');
    }

    if (selectedInstitutionId && (! isInitialRequest)) {
      actions.setIsAuthUserSelectedInstitutionAdmin(isAdmin);
      actions.getUserAdminGroups(selectedInstitutionId, '', true);
    }
  }

  toggleAdvancedFilter() {
    const { actions, displayAdvancedFilters } = this.props;

    actions.displayAdvancedFilters(! displayAdvancedFilters );
  }

  render() {
    const {
      isInitialRequest,
      isChildGroupSelected,
      userParentGroupsCount
    } = this.props;

    return (
      <div className="admin-dashboard">
        <div className="interior invite-users mt-3">
          <Container>
            <AdminBreadcrumb parentPage="admin-dashboard"/>
            <Row>
              <Col>
                {((! isChildGroupSelected) && (userParentGroupsCount > 1)) &&
                  <ParentGroupDropdown parentPage="admin-dashboard"/>}
              </Col>
            </Row>
            {(isInitialRequest) && <Loading/>}
            {(! isInitialRequest) &&
              <Dashboard toggleAdvancedFilter={this.toggleAdvancedFilter} selectedInstitutionId={this.props.selectedInstitutionId}/>}
          </Container>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    groups: {
      isRequesting,
      isInitialRequest,
      selectedGroup,
      isChildGroupSelected,
      data: userAdminGroups
    },
    users: { displayAdvancedFilters },
    institutions: {
      selectedInstitution: {
        isAdmin,
        groupsCount: parentGroupsCount,
        id: selectedInstitutionId
      }
    }
  } = state;

  const allGroups = userAdminGroups.filter(group => group.id !== 'all-groups');
  const userParentGroupsCount = allGroups.length;

  return {
    isAdmin,
    isRequesting,
    isInitialRequest,
    selectedGroup,
    userParentGroupsCount,
    userAdminGroups,
    parentGroupsCount,
    isChildGroupSelected,
    selectedInstitutionId,
    displayAdvancedFilters,
    profile: state.profile.data,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    redirect,
    getUserAdminGroups,
    setSelectedParentGroup,
    displayAdvancedFilters,
    setIsAuthUserSelectedInstitutionAdmin
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
