import React from 'react';
import PropTypes from 'prop-types';

import className from 'classnames';
import { checkHttp } from '../../../utils';

import { Link } from 'react-router-dom';

import FollowingButton from './FollowingButton';
import FollowersButton from './FollowersButton';

const socialArray = [
  {
    id: 1,
    name: 'twitter',
    icon: 'fa fa-lg fa-twitter-square'
  },
  {
    id: 2,
    name: 'linkedin',
    icon: 'fa fa-lg fa-linkedin-square'
  },
  {
    id: 3,
    name: 'github',
    icon: 'fa fa-lg fa-github-square'
  },
  {
    id: 4,
    name: 'facebook',
    icon: 'fa fa-lg fa-facebook-square'
  },
  {
    id: 5,
    name: 'instagram',
    icon: 'fa fa-lg fa-instagram'
  },
  {
    id: 6,
    name: 'youtube',
    icon: 'fa fa-lg fa-youtube-square'
  },
  {
    id: 7,
    name: 'vimeo',
    icon: 'fa fa-lg fa-vimeo-square'
  },
  {
    id: 8,
    name: 'other',
    icon: 'fa fa-lg fa-globe'
  }
];

const ProfileShare = ({
  onToggleContactInfoModal,
  onToggleSocialModal,
  isPublicProfile,
  networks,
  privacySettings,
  profile,
  isOwner,
  isAuthenticated
}) => {
  const { data, isRequesting } = networks;
  const isPrivatePreview = isPublicProfile && privacySettings === 1;

  if (isRequesting || isPrivatePreview) {
    return (
      <div className="container profile-share">
        <div className="row">
          <div className="col-lg-12">
            <div className="profile-share-container extra-padding"/>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container profile-share">
      <div className="row">
        <div className="col-lg-12">
          <div className={className(
            'profile-share-container',
            {
              'extra-padding': data.length === 0
            })}>
            <div className="d-flex align-items-center">
              <div className="profile-stat-block">
                {(isOwner && (! isPublicProfile)) &&
                  <>
                    <FollowingButton
                      profile={profile}
                      isAuthenticated={isAuthenticated}/>

                    <FollowersButton
                      profile={profile}
                      isAuthenticated={isAuthenticated}/>
                  </>}

                {(isOwner && (!isPublicProfile)) &&
                  <Link to={isOwner ? '/portfolio' : `/portfolio/${profile.id}`} className="stat-block-item">
                    <span className="stat-number">{profile.filesCount}</span> Portfolio Files
                  </Link>}
              </div>
            </div>
            <div>

              <div className="profile-social-media">
                <div>
                  {(isOwner && (! isPublicProfile)) &&
                  <div>
                    <a className="clickable message" onClick={onToggleContactInfoModal}>Contact Info</a>
                  </div>
                  }
                </div>
                <div className="profile-social-media--links">
                  {
                    data.map((link, index) => {
                      const linkObject = socialArray.find(soc => soc.name === link.name);
                      if(!linkObject)
                        return null;

                      const urlString = checkHttp(link.url) ?
                        link.url : `//${link.url}`;
                      return (<a key={index} target="_blank" rel="noopener noreferrer" href={urlString}>
                        <i className={linkObject.icon}/><span className="sr-only">Social Icon</span>
                      </a>);
                    })
                  }
                </div>
                <div className="profile-social-media--add">
                  {
                    !isPublicProfile && onToggleSocialModal &&
                      <a onClick={onToggleSocialModal} className="social-add clickable">
                        <i className="fa fa-lg fa-plus-circle btn-none "/>
                      </a>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProfileShare.propTypes = {
  onToggleSocialModal: PropTypes.func,
  onToggleContactInfoModal:PropTypes.func,
  isPublicProfile: PropTypes.bool,
  networks: PropTypes.object,
  privacySettings: PropTypes.number,
  profile: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
  isOwner: PropTypes.bool
};

export default ProfileShare;
