import initialState from './initialState';

import {
  WEBPAGE_DETAILS_REQUEST,
  WEBPAGE_DETAILS_SUCCESS,
  WEBPAGE_DETAILS_FAILURE,
  CREATE_FOLLOWING_SUCCESS,
  DELETE_FOLLOWING_SUCCESS,
  RESET_WEBPAGE_DETAILS
} from '../../constants';

export default function reducer(state = initialState.webpage, action) {
  switch (action.type) {
  case RESET_WEBPAGE_DETAILS:
    return initialState.webpage;

  case WEBPAGE_DETAILS_REQUEST:
    return Object.assign({}, state, {
      isRequesting: true,
      data: {},
    });

  case WEBPAGE_DETAILS_SUCCESS:
    return Object.assign({}, state, {
      data: action.data,
      isRequesting: false,
    });

  case WEBPAGE_DETAILS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case CREATE_FOLLOWING_SUCCESS:
    return Object.assign({}, state, {
      data: Object.assign({}, state.data, {
        isUserFollower: {
          status: true,
          connectId: action.data.record,
        },
      }),
    });

  case DELETE_FOLLOWING_SUCCESS:
    return Object.assign({}, state, {
      data: Object.assign({}, state.data, {
        isUserFollower: {
          status: false,
          connectId: '',
        },
      }),
    });

  default:
    return state;
  }
}
