import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loadForm, guid } from '../../utils';

import * as componentActions from '../../redux/actions/components';

import Validator from '../../validator';
import AwardSectionCard from '../../components/resume/sections/AwardSectionCard';
import AwardForm from '../../components/profile/forms/AwardForm';
import DeleteSectionItemConfirmModal from '../../components/resume/DeleteSectionItemConfirmModal';

class ResumeAwards extends Component {
  static propTypes = {
    awards: PropTypes.object,
    onChangeSection: PropTypes.func.isRequired,
    onChangeSectionTitle: PropTypes.func.isRequired,
    onUnloadForm: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    resumeAwards: PropTypes.object.isRequired,
    onLoadForm: PropTypes.func.isRequired,
    forms: PropTypes.array,
    onRemoveResumeSection: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      editingId: null,
      removeAwardId: null,
      awards: Object.assign({}, this.props.awards),
      editorText: { description: this.props.awards.description }
    };

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onEditAward = this.onEditAward.bind(this);
    this.onRemoveAward = this.onRemoveAward.bind(this);
    this.onRichEditorChange = this.onRichEditorChange.bind(this);
    this.onCancelRemoveAward = this.onCancelRemoveAward.bind(this);
    this.onConfirmRemoveAward = this.onConfirmRemoveAward.bind(this);
  }

  isValid(field = null) {
    const rules = {
      title: ['required', 'minLength|6', 'maxLength|100'],
      issuer: ['required', 'maxLength|100'],
      url: ['unsafeurl'],
      date: ['required', 'startdate'],
    };

    const { awards, errors: prevErrors} = this.state;
    const validate = Validator.createValidator(rules, awards, field);
    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  isValidDescription(field = null) {
    const rules = {
      description: ['maxWords|200']
    };
    const { editorText, errors: prevErrors } = this.state;
    const validate = Validator.createValidator(rules, editorText, field);
    const { errors, isValid } = validate;

    if (field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }
    this.setState({ errors: Object.assign({}, prevErrors, errors) });

    return isValid;
  }

  onChangeTitle(event){
    const { value } = event.target;
    this.props.onChangeSectionTitle(value, 'awards');
  }

  handleChange(event){
    let { name, value } = event.target;
    const { awards } = this.state;

    awards[name] = value;

    this.setState({ awards }, () => this.isValid(name));
  }

  onRichEditorChange(description, ...params) {
    let { awards, editorText } = this.state;

    awards = Object.assign({}, awards, { description });
    editorText['description'] = params[2].getText().replace(/\r?\n|\r/gm, ' ');

    this.setState({ awards, editorText }, () => this.isValidDescription('description'));
  }

  onSave(){
    const { awards, editingId } = this.state;
    const { resumeAwards } = this.props;

    if (!this.isValid() || ! this.isValidDescription())
      return;

    const newAward = Object.assign({},{
      id: guid()
    }, awards );

    const index = resumeAwards.awardsArray.findIndex(award => award.id === editingId);

    const awardsArray = editingId === null ? [
      ...resumeAwards.awardsArray,
      newAward
    ] : [
      ...resumeAwards.awardsArray.slice(0, index),
      newAward,
      ...resumeAwards.awardsArray.slice(index+1)
    ];

    const data = Object.assign({}, resumeAwards,{
      awardsArray
    });

    this.props.onChangeSection(data, 'awards');
    this.onCancel();
  }

  onCancel(){
    const { unloadForm } = this.props.actions;
    this.clearFormField();
    unloadForm('editAwardsForm');
  }

  clearFormField(){
    const awards = {
      title: '',
      date: '',
      url: '',
      description: '',
      issuer: '',
    };

    this.setState({
      awards,
      errors: {},
      editingId: null
    });
  }

  onEditAward(event){
    const { id } = event.target;
    const { loadForm } = this.props.actions;
    const { awardsArray } = this.props.resumeAwards;
    const awardObject = awardsArray.find(award => award.id === id);
    const awards = Object.assign({}, this.state.awards, awardObject);

    this.setState({
      awards,
      editingId: id
    }, () => loadForm('editAwardsForm'));
  }

  onRemoveAward(event){
    const { id: removeAwardId } = event.target;

    this.setState({ removeAwardId });
  }

  onConfirmRemoveAward() {
    const { removeAwardId } = this.state;
    const { resumeAwards } = this.props;

    const awardsArray = resumeAwards
      .awardsArray
      .filter(award => award.id !== removeAwardId);

    const data = Object.assign({}, resumeAwards, { awardsArray });

    this.props.onChangeSection(data, 'awards');
    this.onCancelRemoveAward();
  }

  onCancelRemoveAward() {
    this.setState({ removeAwardId: null });
  }

  render() {
    const { resumeAwards, onLoadForm, forms, onRemoveResumeSection } = this.props;
    const { awards, errors, removeAwardId } = this.state;

    const isFormOpen = loadForm(forms, 'editAwardsForm');
    const isConfirmModalOpen = removeAwardId !== null;

    return (
      <div className="resume-section create-resume-section-card">
        <div className="create-resume-title">
          <input
            type="text"
            value={resumeAwards.title}
            placeholder="Awards header"
            className="header-edit-textbox"
            onChange={this.onChangeTitle}/>
          <button
            onClick={onRemoveResumeSection}
            id="Award"
            className="btn btn-danger btn-sm">
            <i className="fa fa-close"/>
          </button>
        </div>
        {!isFormOpen &&
          <div className="section-list-container">
            {resumeAwards.awardsArray.map(award =>
              (<AwardSectionCard
                key={award.id}
                awardDetails={award}
                onEditAward={this.onEditAward}
                onRemoveAward={this.onRemoveAward}/>))}

            <button
              onClick={onLoadForm}
              data-form-name="editAwardsForm"
              className="btn btn-primary w-100">
              Add Award
            </button>
          </div>}

        {isFormOpen &&
          <div className="resume-form-container">
            <AwardForm
              errors={errors}
              award={awards}
              onSave={this.onSave}
              onCancel={this.onCancel}
              onChange={this.handleChange}
              onChangeDescription={this.onRichEditorChange}/>
          </div>}

        {isConfirmModalOpen &&
          <DeleteSectionItemConfirmModal
            onCancel={this.onCancelRemoveAward}
            onConfirm={this.onConfirmRemoveAward}
            isOpen={isConfirmModalOpen}/>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const awards = {
    title: '',
    date: '',
    url: '',
    description: '',
    issuer: '',
  };

  return {
    awards,
    forms: state.components.forms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(componentActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResumeAwards);
