import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SupportingEvidence from '../../components/portfolio/SupportingEvidence';
import * as skillActions from '../../redux/actions/skills';
import * as badgeActions from '../../redux/actions/badges';

class SupportingEvidenceHandler extends Component {
  static propTypes = {
    actions: PropTypes.object,
    skills: PropTypes.array.isRequired,
    badges: PropTypes.array.isRequired,
    isRequesting: PropTypes.bool,
    currentUser: PropTypes.object.isRequired,
    getSelectedBadges: PropTypes.func.isRequired,
    getSelectedSkills: PropTypes.func.isRequired,
    selectedBadges: PropTypes.array,
    selectedSkills: PropTypes.array,
    isEditingCard: PropTypes.bool,
    skillEvidence: PropTypes.bool,
    profile: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      isSkillsShown: false,
      isBadgesShown: false,
      selectedBadges: [],
      selectedSkills: []
    };

    this.toggleIsSkillsShown = this.toggleIsSkillsShown.bind(this);
    this.toggleIsBadgesShown = this.toggleIsBadgesShown.bind(this);
    this.toggleBadgeCheckbox = this.toggleBadgeCheckbox.bind(this);
    this.toggleSkillCheckbox = this.toggleSkillCheckbox.bind(this);
    this.setSkillWithEvidence = this.setSkillWithEvidence.bind(this);
  }

  componentWillMount() {
    const { actions, isEditingCard, selectedBadges, selectedSkills, currentUser, skillEvidence } = this.props;
    if(isEditingCard){
      const skillsIds = selectedSkills.map(skill => skill.id);
      const badgeIds = selectedBadges.map(badge => skillEvidence ? badge.issued.id : badge.id);

      this.setState({
        selectedBadges: badgeIds,
        selectedSkills: skillsIds
      });
    }

    actions.skillsRequest(currentUser.id);
    actions.getIssuedBadges(true, currentUser.id, 1);
  }

  componentWillReceiveProps(nextProps){
    const { selectedBadges: nextSelectedBadges, selectedSkills: nextSelectedSkills } = nextProps;
    const { selectedBadges, selectedSkills } = this.props;
    if( selectedBadges !== nextSelectedBadges && nextSelectedBadges.length === 0 &&
      selectedSkills !== nextSelectedSkills && nextSelectedSkills.length === 0){
      this.setState({
        selectedBadges: [],
        isBadgesShown: false,
        selectedSkills: [],
        isSkillsShown: false
      });
    }
  }

  toggleIsSkillsShown() {
    this.setState({
      isSkillsShown: !this.state.isSkillsShown
    });
  }

  toggleIsBadgesShown() {
    this.setState({
      isBadgesShown: !this.state.isBadgesShown
    });
  }

  toggleBadgeCheckbox(event) {
    const value = event.target.value;
    let { selectedBadges: badges } = this.state;

    if (badges.includes(value)) {
      const selectedBadges = badges.filter(badge => badge !== value);

      this.setState({ selectedBadges });
      return this.props.getSelectedBadges(selectedBadges);
    }

    badges.push(value);

    this.setState({ selectedBadges: badges });
    this.props.getSelectedBadges(badges);
  }

  toggleSkillCheckbox(skillId) {
    return () => {
      let { selectedSkills: skills } = this.state;

      if (skills.includes(skillId)) {
        const selectedSkills = skills.filter(skill => skill !== skillId);

        this.setState({ selectedSkills });
        return this.props.getSelectedSkills(selectedSkills);
      }

      skills.push(skillId);

      this.setState({ selectedSkills: skills });
      this.props.getSelectedSkills(skills);
    };
  }

  setSkillWithEvidence(skill) {
    return () => this.props.actions.setSkillBeingUpdatedInStore(skill);
  }

  render() {
    const { skills, badges, profile } = this.props;
    const sortedBadges = badges.sort((badge1, badge2) => Number(badge1.unit.code) - Number(badge2.unit.code));

    const {
      isSkillsShown,
      isBadgesShown,
      selectedBadges,
      selectedSkills
    } = this.state;

    return(
      <SupportingEvidence
        skills={skills}
        badges={sortedBadges}
        profile={profile}
        toggleIsSkillsShown={this.toggleIsSkillsShown}
        isSkillsShown={isSkillsShown}
        isBadgesShown={isBadgesShown}
        selectedBadges={selectedBadges}
        selectedSkills={selectedSkills}
        toggleIsBadgesShown={this.toggleIsBadgesShown}
        toggleBadgeCheckbox={this.toggleBadgeCheckbox}
        toggleSkillCheckbox={this.toggleSkillCheckbox}
        setSkillWithEvidence={this.setSkillWithEvidence}/>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, skills, badges, profile } = state;

  return {
    skills: skills.data,
    badges: badges.issuedBadges.data,
    profile,
    isRequesting: skills.isRequesting,
    currentUser: auth.data.currentUser
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, badgeActions, skillActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportingEvidenceHandler);
