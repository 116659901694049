import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const VanityTextInput = ({
  // name,
  label,
  // onChange,
  // placeholder,
  value,
  error,
  // inputId,
  // disabled,
  note,
  // onKeyPress,
  redText,
  // autoFocus
}) => {
  let wrapperClass = 'form-group';

  if (error && error.length > 0) {
    wrapperClass += ' ' + 'has-danger';
  }

  return (
    <div className={wrapperClass}>
      {label &&
        <label className="form-control-label">
          {label}
          {redText &&
            <span className="danger" style={{ marginLeft: 4 }}>
              {`(${redText})`}
            </span>}
        </label>}

      {
        note &&
          <span className={classNames(
            'note',
            'pl-5',
            {[note]:note}
          )}>
            ({note})
          </span>
      }

      <div className="input-group custom-vanity-input-group">
        <div className="input-group-prepend form-control">
          <span className="input-group-text"><small>{`https://careerprepped.com/cp/${value}`}</small></span>
        </div>
        {/* <input
          id={inputId}
          type="text"
          name={name}
          className="form-control"
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={onChange}
          onKeyDown={onKeyPress}
          autoFocus={autoFocus}/> */}
      </div>
      {error && <div className="form-control-feedback">{error}</div>}
    </div>
  );
};

VanityTextInput.propTypes = {
  inputId: PropTypes.string,
  note: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onKeyPress: PropTypes.func,
  redText: PropTypes.string,
  autoFocus: PropTypes.bool
};

export default VanityTextInput;
