import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ClubCard from '../../../components/profile/sections/ClubCard';

class ClubCardHandler extends Component {
  static propTypes = {
    club: PropTypes.object.isRequired,
    isPublicProfile: PropTypes.bool.isRequired,
    onEdit: PropTypes.func
  }

  constructor(props, context) {
    super(props, context);

    this.onEditClubClick = this.onEditClubClick.bind(this);
  }

  onEditClubClick(event) {
    event.preventDefault();
    const { id } = this.props.club;

    this.props.onEdit(id);
  }

  render() {
    const { club, isPublicProfile } = this.props;

    return (
      <div className="profile-item__card-list">
        <ClubCard
          club={club}
          onEditClubClick={this.onEditClubClick}
          isPublicProfile={isPublicProfile}/>
      </div>
    );
  }
}

export default ClubCardHandler;
