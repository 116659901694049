import { CALL_API } from '../../middlewares/api';

import {
  SHARE_DISCUSSION,
  RESET_SHARE_DISCUSSION,
  DISCUSSION_REQUEST,
  DISCUSSION_SUCCESS,
  DISCUSSION_FAILURE,
  DISCUSSIONS_REQUEST,
  DISCUSSIONS_SUCCESS,
  DISCUSSIONS_FAILURE,
  CREATE_DISCUSSION_LIKE_REQUEST,
  CREATE_DISCUSSION_LIKE_SUCCESS,
  CREATE_DISCUSSION_LIKE_FAILURE,
  CREATE_DISCUSSION_REQUEST,
  CREATE_DISCUSSION_SUCCESS,
  CREATE_DISCUSSION_FAILURE,
  UPDATE_DISCUSSION_REQUEST,
  UPDATE_DISCUSSION_SUCCESS,
  UPDATE_DISCUSSION_FAILURE,
  DELETE_DISCUSSION_REQUEST,
  DELETE_DISCUSSION_SUCCESS,
  DELETE_DISCUSSION_FAILURE,
  INITIALIZE_DISCUSSIONS_STATE,
  INCREMENT_DISCUSSION_COMMENT_COUNT,
  DECREMENT_DISCUSSION_COMMENT_COUNT,
  UNLIKE_DISCUSSION_POST_REQUEST,
  UNLIKE_DISCUSSION_POST_SUCCESS,
  UNLIKE_DISCUSSION_POST_FAILURE,
} from '../../constants';

export const shareDiscussion = (discussion) => {
  return dispatch => dispatch({ type: SHARE_DISCUSSION, discussion });
};

export const resetShareDiscussion = () => {
  return dispatch =>
    dispatch({ type: RESET_SHARE_DISCUSSION, discussion: {} });
};

export const initializeDiscussionsState = () => {
  return dispatch => dispatch({ type: INITIALIZE_DISCUSSIONS_STATE });
};

export const incrementDiscussionCommentCount = (id, typeOfComment) => {
  return dispatch =>
    dispatch({
      type: INCREMENT_DISCUSSION_COMMENT_COUNT,
      id,
      typeOfComment,
    });
};

export const decrementDiscussionCommentCount = (id, count, typeOfComment) => {
  return dispatch =>
    dispatch({
      type: DECREMENT_DISCUSSION_COMMENT_COUNT,
      id,
      count,
      typeOfComment,
    });
};

export const unitDiscussionRequest = (discussionId) => {
  const endpoint = `discussion/discussion/${discussionId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [DISCUSSION_REQUEST, DISCUSSION_SUCCESS, DISCUSSION_FAILURE],
    },
  };
};

export const unitDiscussionsRequest = (
  unitId,
  isAuthenticated,
  pageNo,
  sortBy
) => {
  let endpoint = `discussion/discussion?unit=${unitId}&page=${pageNo}&comment=1&sort=${sortBy}`;

  if (sortBy === 'date')
    endpoint = `discussion/discussion?unit=${unitId}&page=${pageNo}&comment=1`;

  const callApiWithoutAuthentication = {
    endpoint,
    method: 'get',
    types: [DISCUSSIONS_REQUEST, DISCUSSIONS_SUCCESS, DISCUSSIONS_FAILURE],
  };

  return {
    [CALL_API]: isAuthenticated
      ? Object.assign({}, callApiWithoutAuthentication, {
        authenticated: true,
      })
      : Object.assign({}, callApiWithoutAuthentication),
  };
};

export const saveUnitDiscussion = (data) => {
  const endpoint = 'discussion/discussion';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        CREATE_DISCUSSION_REQUEST,
        CREATE_DISCUSSION_SUCCESS,
        CREATE_DISCUSSION_FAILURE,
      ],
    },
  };
};

export const saveUnitDiscussionLike = (data) => {
  const endpoint = 'discussion/discussion_like';

  return {
    [CALL_API]: {
      data,
      id: data.discussion,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        CREATE_DISCUSSION_LIKE_REQUEST,
        CREATE_DISCUSSION_LIKE_SUCCESS,
        CREATE_DISCUSSION_LIKE_FAILURE,
      ],
    },
  };
};

export const unlikeUnitDiscussion = (data) => {
  const endpoint = `discussion/discussion_like/${data.discussion}`;

  return {
    [CALL_API]: {
      id: data.discussion,
      userId: data.userId,
      endpoint,
      method: 'delete',
      authenticated: true,
      types: [
        UNLIKE_DISCUSSION_POST_REQUEST,
        UNLIKE_DISCUSSION_POST_SUCCESS,
        UNLIKE_DISCUSSION_POST_FAILURE,
      ],
    },
  };
};

export const updateUnitDiscussion = (id, data) => {
  const endpoint = `discussion/discussion/${id}`;

  return {
    [CALL_API]: {
      data,
      id,
      endpoint,
      method: 'patch',
      authenticated: true,
      types: [
        UPDATE_DISCUSSION_REQUEST,
        UPDATE_DISCUSSION_SUCCESS,
        UPDATE_DISCUSSION_FAILURE,
      ],
    },
  };
};

export const deleteUnitDiscussion = (discussionId) => {
  const endpoint = `discussion/discussion/${discussionId}`;

  return {
    [CALL_API]: {
      endpoint,
      id: discussionId,
      method: 'delete',
      authenticated: true,
      types: [
        DELETE_DISCUSSION_REQUEST,
        DELETE_DISCUSSION_SUCCESS,
        DELETE_DISCUSSION_FAILURE,
      ],
    },
  };
};
