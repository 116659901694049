import PropTypes from 'prop-types';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const InviteLinkCell = ({ onCopy, invite }) => {
  return (
    <CopyToClipboard
      text={invite.inviteLink}
      onCopy={onCopy}>
      <button
        disabled={((invite.status === 'accepted') || (invite.status === 'revoked'))}
        className="btn-block btn btn-primary btn-sm">
        Copy Link
      </button>
    </CopyToClipboard>
  );
};

InviteLinkCell.propTypes = {
  onCopy: PropTypes.func.isRequired,
  invite: PropTypes.object.isRequired
};

export default InviteLinkCell;
