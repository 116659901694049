export const SHOW_MORE = '... Show more';
export const SHOW_LESS = 'Show less';
export const UNLIKING = 'Unliking ...';
export const UNLIKE = 'Unlike';
export const LIKING = 'Liking ...';
export const LIKE = 'Like';
export const CANCEL = 'Cancel';

export const ALTERNATING_IMAGE_TITLE = 'Alternative image title goes here...';
export const AN_IMAGE = 'an image';

export const OPTIONAL = 'Optional';
export const REQUIRED = 'Required';

export const PREVIOUS = 'Previous';
export const NEXT = 'Next';

export const HOME = 'Home';
export const SKILL_BUILDERS = 'Skill Builders';
export const SKILL_BADGES = 'Skill Badges';
export const COMMUNITY='Community';
export const CAREER_TOOLBOX = 'Career Toolbox';
export const JOB_CENTER = 'Job Center';

export const WRITE_SOMETHING = 'Write something...';
export const LISTEN_TO_CONTENT = 'Listen to this content';
export const LISTEN = 'Listen';
export const SEARCH = 'Search';
export const ADVANCED = 'Advanced';

export const SKILL_BADGE_CP_DETAILS =
  'Skill Badge on CareerPrepped. Click to see details and evidence for this Skill Badge.';
export const GOT_THE = ' got the ';
export const PROFILE_FILE_DETAILS =
  'Click to see details for this Portfolio File.';
export const INTERVIEW_PREP_VIDEO =
  'Click to see details for this Interview Prep Video.';

export const JUST_ANSWERED =
  'I just answered the reflection question(s) below from';

export const REVIEW_ESSENTIALS = 'Review Essentials';
export const ENGAGE_CONNECT = 'Engage & Connect';
export const CLAIM_BADGE = 'Claim Badge';

export const FINISHING = 'Finishing...';
export const STOP_FEEDBACK = 'Stop All Feedback Requests';
export const STOP_FEEDBACK_CONFIRMATION = 'Are you sure you want to stop all feedback requests indefinitely? ';
export const YES_STOP_REQUEST = ' Yes, Stop Requests';
export const NO_CANCEL = 'No, Cancel';

export const SAVING_RESUME = 'Saving Resume';
export const DISCARD_CHANGES = 'Discard you changes';
export const DISCARD_CHANGES_CONFIRMATION =
  'You have some unsaved changes.Are you sure you want to discard your changes?';
export const DISCARD_CHANGES_BUTTON = 'Discard changes';

export const VIEW_PRICING = 'View Pricing';
export const SIGNUP = 'Sign Up';

export const GET_IN_TOUCH = 'Get in touch with CareerPrepped';
export const EMAIL = 'Email';
export const PHONE_OPTIONAL = 'Phone(Optional)';
export const PLEASE_SELECT = 'Please select';
export const MESSAGE = 'Message';
export const SUBMITTING = 'Submitting ....';
export const SUBMIT = 'Submit';

export const PROFILE_PICTURE_AVATAR = 'Profile User Avatar';
