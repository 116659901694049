import React from 'react';

import PropTypes from 'prop-types';

import Alert from './Alert';
import TextInput from '../common/TextInput';

import { isEmpty, isUndefined } from 'lodash';

const signpImg = require('../../assets/images/logo-icon.png');

const RegistrationForm = ({
  userData,
  errors,
  isRequestingLogin,
  onChange,
  onSubmit,
  handleKeyPress,
  onClickTerms,
  registration,
  dismissAlert,
  displayAlertType
}) => {
  let alertHeading = 'Thank you for signing up for CareerPrepped.';
  let alertMessage = 'You\'re almost finished! Please check your email to complete your account creation. We\'ve emailed you a link to setup your password and sign in. If you don\'t see the email in your inbox, please check any spam, junk, clutter, or promotions folders and search "CareerPrepped" in all email folders. If you still can\'t locate your email, contact support at careerprepped@maxknowledge.com and let us know you didn\'t get your sign up email.';

  if (! isUndefined(registration.error.errorType)) {
    alertHeading = 'There\'s a problem with the email entered.';
    alertMessage = registration.error.detail;

    if ((registration.error.errorType === 'DuplicateSelfRegistrationException')) {
      alertHeading = 'Duplicate registration requests.';
    }

    if ((registration.error.errorType === 'InvalidGroupCodeException')) {
      alertHeading = 'Invalid group code.';
    }
  }

  return (
    <div className="self-register" style={{ border: 'none' }}>
      <div style={{textAlign:'center'}}>
        <div className="double-lines"/>
        <img src={signpImg} style={{backgroundColor:'white'}}/>
      </div>
      <h4>
        Sign up for CareerPrepped
      </h4>
      <form onSubmit={onSubmit} onKeyPress={handleKeyPress} autoComplete={'nope'}>
        <div className="row">
          {(! isEmpty(displayAlertType)) &&
            <Alert
              displayAlertType={displayAlertType}
              alertHeading={alertHeading}
              alertMessage={alertMessage}
              dismissAlert={dismissAlert}/>}

          <div className="col-12">
            <TextInput
              name="email"
              label="Email"
              placeholder="Email"
              value={userData.email}
              onChange={onChange}
              error={errors.email}
              autoFocus
              srOnly/>
          </div>
          <div className="col-12">
            <TextInput
              name="confirmEmail"
              label="Confirm Email"
              placeholder="Confirm Email"
              value={userData.confirmEmail}
              onChange={onChange}
              error={errors.confirmEmail}
              srOnly/>
          </div>
          <div className="col-12">
            <TextInput
              name="groupCode"
              label="Group Code"
              placeholder="Enter group code if you have one"
              value={userData.groupCode}
              onChange={onChange}
              error={errors.groupCode}
              srOnly/>
          </div>
          <div className="col-12">
            <input
              type="submit"
              disabled={registration.isSubmitting || isRequestingLogin}
              value={
                registration.isSubmitting || isRequestingLogin ?
                  'Signing Up...' : 'Sign Up'
              }
              className="btn btn-primary w-100"/>
            <p className="terms-text text-center">
              By registering you agree to our <a onClick={onClickTerms} href="#">Terms</a>
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

RegistrationForm.propTypes = {
  userData: PropTypes.object,
  errors: PropTypes.object,
  handleKeyPress: PropTypes.func.isRequired,
  isRequestingLogin: PropTypes.bool.isRequired,
  nameRef: PropTypes.func,
  onClickTerms: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  registration: PropTypes.object.isRequired,
  dismissAlert: PropTypes.func.isRequired,
  displayAlertType: PropTypes.string.isRequired
};

export default RegistrationForm;
