import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as searchActions from '../../redux/actions/search';
import * as routerActions from '../../redux/actions/router';
import Loading from '../../components/common/Loading';
import WallCommentCardContainer from '../dashboard/WallCommentCardContainer';
import WallCommentFormHandler from '../dashboard/WallCommentFormHandler';


class PostResultComments extends Component {
  static propTypes = {
    search: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    wallPost: PropTypes.object.isRequired,
    commentShowMore: PropTypes.object.isRequired,
    loadForms: PropTypes.array.isRequired,
    wallPostIndex: PropTypes.number,
    comments: PropTypes.array
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      commentShowMore: Object.assign({}, this.props.commentShowMore)
    };

    this.onCommentShowMore = this.onCommentShowMore.bind(this);
  }

  onCommentShowMore(id){
    return () => {
      const { condition, id: conditionId } = this.state.commentShowMore;
      let newShowMore = { condition: true, id: id};

      if (conditionId !== id)
        newShowMore = {condition: !condition, id: id};

      this.setState({ commentShowMore: newShowMore });
    };
  }

  render() {
    const { commentShowMore } = this.state;
    const { search, comments, wallPostIndex, wallPost, loadForms } = this.props;
    const { isRequestingComments } = search;
    const isRequesting = isRequestingComments.status && isRequestingComments.wallPostIndex === wallPostIndex;

    if(!comments && !isRequesting){
      return null;
    }

    if(isRequesting){
      return (
        <div className="secondary-post-comment">
          <div
            className="small-loading-container">
            <Loading/>
          </div>
        </div>
      );
    }

    return (
      <div 
        style={{borderTop: 'none'}}
        className="secondary-post-comment">
        {
          comments.map((comment, index) => {
            const lastIndex = ((comments.length - 1) === index);
    
            return(
              <div key={comment.id}>
                <WallCommentCardContainer
                  searchPage
                  commentData={comment}
                  commentShowMore={commentShowMore}
                  onCommentShowMore={this.onCommentShowMore}
                  lastIndex={lastIndex}
                  loadForms={loadForms}
                  wallPostId={wallPost.id}/>
              </div>
            );
          })
        }

        <WallCommentFormHandler
          searchPage
          wallPostId={wallPost.id}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let commentShowMore = { condition: false, id: null};

  return {
    commentShowMore,
    loadForms: state.components.forms,
    search: state.search,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, searchActions, routerActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(PostResultComments);
