import { CALL_API } from '../../middlewares/api';

import {
  GET_FEEDBACK_QUESTIONS_REQUEST,
  GET_FEEDBACK_QUESTIONS_SUCCESS,
  GET_FEEDBACK_QUESTIONS_FAILURE,
  GET_FEEDBACK_DATA_REQUEST,
  GET_FEEDBACK_DATA_SUCCESS,
  GET_FEEDBACK_DATA_FAILURE,
  RESET_FEEDBACK_DATA,
  RESET_GIVE_FEEDBACK,
  POST_FEEDBACK_DATA_REQUEST,
  POST_FEEDBACK_DATA_SUCCESS,
  POST_FEEDBACK_DATA_FAILURE,
  EDIT_FEEDBACK_DATA_REQUEST,
  EDIT_FEEDBACK_DATA_SUCCESS,
  EDIT_FEEDBACK_DATA_FAILURE,
  GET_FEEDBACK_USER_DATA_REQUEST,
  GET_FEEDBACK_USER_DATA_SUCCESS,
  GET_FEEDBACK_USER_DATA_FAILURE,
} from '../../constants';

export const getfeedbackQuestions = (feedback_request_id, params) => {
  let endpoint = `feedback/feedback_questions?feedback_request=${feedback_request_id}`;

  endpoint = params.type === 'badge' ?
    `${endpoint}&badgeIssuedId=${params.rquestId}` :
    `${endpoint}&skillId=${params.rquestId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        GET_FEEDBACK_QUESTIONS_REQUEST,
        GET_FEEDBACK_QUESTIONS_SUCCESS,
        GET_FEEDBACK_QUESTIONS_FAILURE,
      ],
    },
  };
};

export const getFeedbackData = (feedbackRequestId) => {
  const endpoint = `feedback/feedbacks?feedback_request_id=${feedbackRequestId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        GET_FEEDBACK_DATA_REQUEST,
        GET_FEEDBACK_DATA_SUCCESS,
        GET_FEEDBACK_DATA_FAILURE,
      ],
    },
  };
};

export const resetFeedbackData = () => {
  return { type: RESET_FEEDBACK_DATA };
};

export const resetGiveFeedback = () => {
  return { type: RESET_GIVE_FEEDBACK };
};

export const postFeedbackData = (data) => {
  const endpoint = 'feedback/feedback-response';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        POST_FEEDBACK_DATA_REQUEST,
        POST_FEEDBACK_DATA_SUCCESS,
        POST_FEEDBACK_DATA_FAILURE,
      ],
    },
  };
};

export const editFeedbackData = (data) => {
  const endpoint = `feedback/feedback-response/${data.feedbackRequestId}`;

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'patch',
      authenticated: true,
      types: [
        EDIT_FEEDBACK_DATA_REQUEST,
        EDIT_FEEDBACK_DATA_SUCCESS,
        EDIT_FEEDBACK_DATA_FAILURE,
      ],
    },
  };
};

export const getFeedbackStatusByRequestId = (feedbackRequestId) => {
  const endpoint = `feedback/feedback_request/${feedbackRequestId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types:[ GET_FEEDBACK_USER_DATA_REQUEST,
        GET_FEEDBACK_USER_DATA_SUCCESS,
        GET_FEEDBACK_USER_DATA_FAILURE,
      ],
    },
  };
};
