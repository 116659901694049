import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../common/Button';
import TextInput from '../../common/TextInput';
import DropdownInput from './DropdownInput';
import DropdownListItems from './DropdownListItems';

const styles = {
  panelHeading: { minHeight: 30, backgroundColor: '#fafafa', marginBottom: 10 },
  heading: { textAlign: 'center', paddingRight: 40, color: 'black', paddingTop: 10 }
};

const DropdownForm = ({
  isAdmin,
  onCancel,
  errors,
  section,
  onChange,
  onSave,
  onDelete,
  isSubmitting,
  isUpdating,
  isDeleting,
  formType,
  onDeleteListItem,
  onAddListItem,
  onEditListItem,
  resetEditorState,
  updateEditorState,
  setEditorState,
  getEditorState
}) => {
  return (
    <div>
      {isAdmin &&
        <div className="card-header" style={styles.panelHeading}>
          <h3 style={styles.heading}>
            Dropdown list
          </h3>
        </div>}

      <div className="card-block p-3">
        <form id={formType ? `${formType.formName}_${formType.id}` : ''}>
          <div className="row">
            <div className="col-sm-12">
              <TextInput
                autoFocus
                label="Title"
                name="title"
                error={errors.title}
                value={section.title}
                onChange={onChange}
                placeholder="Title"/>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <TextInput
                label="Heading"
                name="heading"
                error={errors.heading}
                value={section.heading}
                onChange={onChange}
                placeholder="Heading"/>
            </div>
          </div>

          <DropdownInput
            errors={errors}
            section={section}
            resetEditorState={resetEditorState}
            updateEditorState={updateEditorState}
            listItemsLimit={20}
            setEditorState={setEditorState}
            getEditorState={getEditorState}
            onAddListItem={onAddListItem}/>

          <DropdownListItems
            section={section}
            onEditListItem={onEditListItem}
            onDeleteListItem={onDeleteListItem}/>

          {!formType ?
            <Button
              styles={{marginLeft: 0}}
              type="submit"
              onClick={onDelete}
              disabled={isDeleting}
              buttonClass="btn-danger"
              buttonText={isDeleting ? 'Deleting...' : 'Delete'}/> : ''}

          <Button
            type="submit"
            onClick={onSave}
            disabled={isSubmitting || isUpdating}
            buttonClass="btn-primary float-right"
            buttonText={isSubmitting || isUpdating ? 'Saving...' : 'Save'}/>

          <Button
            type="button"
            buttonId={section ? `text-list_${section.id}` : ''}
            onClick={onCancel}
            buttonClass="btn-secondary float-right"
            buttonText="Cancel"/>
        </form>
      </div>
    </div>
  );
};

DropdownForm.propTypes = {
  section: PropTypes.object,
  errors: PropTypes.object,
  formType: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAddListItem: PropTypes.func.isRequired,
  onDeleteListItem: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  onEditListItem: PropTypes.func.isRequired,
  resetEditorState: PropTypes.bool.isRequired,
  updateEditorState: PropTypes.bool.isRequired,
  setEditorState: PropTypes.func.isRequired,
  getEditorState: PropTypes.func.isRequired
};

export default DropdownForm;
