import React, {useState} from 'react';

import PropTypes from 'prop-types';

import MessageModal from '../profile/connects/MessageModal';
import { isEmpty } from 'lodash';
import { toastr } from 'react-redux-toastr';

const SendMessageModal = ({showMessageModal, onToggleMessagingModal, selectedUserId, sendMessage, selectedUserName}) => {
  const [error, setError] = useState({});
  const [message, setMessage] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  function onMessageChange(event) {
    const field = event.target.name;    
    const messageInput = {...message};
    messageInput[field] = event.target.value;
    isValid(messageInput);
    setMessage(messageInput);
  }

  function isValid(value) {
    setError({});
    let isValid = true;

    if (!value.body || !value.body.length > 0) {
      error['body'] = 'Message cannot be empty';
      setError(error);
      isValid = false;
    }
    if (!value.subject || !value.subject.length > 0) {
      error['subject'] = 'Subject cannot be empty';
      setError(error);
      isValid = false;
    }

    return isValid;
  }

  function onMessageSubmit() {
    if (!isValid(message)) return;

    message.recipients = [selectedUserId];
    setIsSubmitting(true);
    sendMessage(message).then(()=>{
      toastr.success(`Message sent successfully to ${selectedUserName}`);
      onToggleMessagingModal();
      setIsSubmitting(false);
    });
  }

  return (
    <MessageModal
      toggleModal={onToggleMessagingModal}
      modalOpen={showMessageModal}
      error={error}
      isSubmitting={isSubmitting}
      message={message}
      disableSubmit={isEmpty(message)}
      onMessageChange={onMessageChange}
      onMessageSubmit={onMessageSubmit}/>
  );
};

SendMessageModal.propTypes = {
  showMessageModal: PropTypes.bool.isRequired,
  onToggleMessagingModal: PropTypes.func,
  selectedUserId: PropTypes.string,
  sendMessage: PropTypes.func,
  selectedUserName: PropTypes.string,
};

export default SendMessageModal;
