import { CALL_API } from '../../middlewares/api';
import {
  ASSOCIATIONS_REQUEST,
  ASSOCIATIONS_SUCCESS,
  ASSOCIATIONS_FAILURE,
  CREATE_ASSOCIATION_REQUEST,
  CREATE_ASSOCIATION_SUCCESS,
  CREATE_ASSOCIATION_FAILURE,
  UPDATE_ASSOCIATION_REQUEST,
  UPDATE_ASSOCIATION_SUCCESS,
  UPDATE_ASSOCIATION_FAILURE,
  DELETE_ASSOCIATION_REQUEST,
  DELETE_ASSOCIATION_SUCCESS,
  DELETE_ASSOCIATION_FAILURE,
} from '../../constants';

export const associationsRequest = (userId) => {
  const endpoint = `user/association?user=${userId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [ASSOCIATIONS_REQUEST, ASSOCIATIONS_SUCCESS, ASSOCIATIONS_FAILURE],
    },
  };
};

export const saveAssociation = (data) => {
  const endpoint = 'user/association';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        CREATE_ASSOCIATION_REQUEST,
        CREATE_ASSOCIATION_SUCCESS,
        CREATE_ASSOCIATION_FAILURE,
      ],
    },
  };
};

export const updateAssociation = (index, data) => {
  const endpoint = `user/association/${data.id}`;

  return {
    [CALL_API]: {
      data,
      index,
      endpoint,
      method: 'put',
      authenticated: true,
      types: [
        UPDATE_ASSOCIATION_REQUEST,
        UPDATE_ASSOCIATION_SUCCESS,
        UPDATE_ASSOCIATION_FAILURE,
      ],
    },
  };
};

export const deleteAssociation = (associationId) => {
  const endpoint = `user/association/${associationId}`;

  return {
    [CALL_API]: {
      endpoint,
      id: associationId,
      method: 'delete',
      authenticated: true,
      types: [
        DELETE_ASSOCIATION_REQUEST,
        DELETE_ASSOCIATION_SUCCESS,
        DELETE_ASSOCIATION_FAILURE,
      ],
    },
  };
};
