import initialState from './initialState';

import {
  CLUBS_REQUEST,
  CLUBS_SUCCESS,
  CLUBS_FAILURE,
  CREATE_CLUB_REQUEST,
  CREATE_CLUB_SUCCESS,
  CREATE_CLUB_FAILURE,
  UPDATE_CLUB_REQUEST,
  UPDATE_CLUB_SUCCESS,
  UPDATE_CLUB_FAILURE,
  DELETE_CLUB_REQUEST,
  DELETE_CLUB_SUCCESS,
  DELETE_CLUB_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.clubs, action) {
  switch (action.type) {
  case CLUBS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case CLUBS_SUCCESS:
    return Object.assign({}, state, {
      data: action.data._embedded.clubs,
      isRequesting: false,
    });

  case CLUBS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case CREATE_CLUB_REQUEST:
    return Object.assign({}, state, { isSubmitting: true });

  case CREATE_CLUB_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data, Object.assign({}, action.data)],
      isSubmitting: false,
    });

  case CREATE_CLUB_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case UPDATE_CLUB_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, clubIndex: action.index },
    });

  case UPDATE_CLUB_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, state.isUpdating.clubIndex),
        Object.assign({}, action.data),
        ...state.data.slice(state.isUpdating.clubIndex + 1),
      ],
      isUpdating: { status: false, clubIndex: null },
    });

  case UPDATE_CLUB_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false, clubIndex: null },
    });

  case DELETE_CLUB_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: action.id },
    });

  case DELETE_CLUB_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data.filter(club => club.id !== state.isDeleting.id)],
      isDeleting: { status: false, id: null },
    });

  case DELETE_CLUB_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: { status: false, id: null },
    });

  default:
    return state;
  }
}
