import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../common/Button';
import TextInput from '../../common/TextInput';
import QuillEditor from '../../common/QuillEditor';
import classNames from 'classnames';

const modules = {
  toolbar: [
    [{ 'align': [] }],
    ['bold', 'italic', 'underline'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{ 'indent': '-1' }, { 'indent': '+1' }],  
    ['link'],
  ],
  clipboard: {
    matchVisual: false,
  }
};

const ClubForm = ({
  club,
  onChange,
  onSave,
  onDelete,
  onCancel,
  isSubmitting,
  isUpdating,
  isDeleting,
  errors,
  showDelete,
  onChangeDescription
}) => {
  const nameLabel = (
    <span>
      Activity or Club: 
    </span>
  );

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <TextInput
            name="name"
            label={nameLabel}
            note="Required"
            placeholder="Enter your activity or club"
            error={errors.name}
            value={club.name}
            onChange={onChange}/>

          <div style={{marginBottom: 20}}
            className={classNames('statement-form-container', {
              'has-danger': errors.description
            })}>
            <label className="form-control-label">Description:</label>
            <QuillEditor
              modules={modules}
              placeholder="Description"
              onChange={onChangeDescription}
              value={club.description}/>
            {errors.description &&
              <div className="text-danger">{errors.description}</div>}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="action-bar text-right">
            {showDelete &&
              <Button
                type="submit"
                onClick={onDelete}
                styles={{marginLeft: 0}}
                disabled={isDeleting}
                buttonClass="btn-danger"
                buttonText={isDeleting ? 'Deleting...' : 'Delete'}/>}

            <Button
              type="button"
              onClick={onCancel}
              buttonClass="btn-secondary"
              buttonText="Cancel"/>

            <Button
              type="submit"
              onClick={onSave}
              disabled={isSubmitting || isUpdating}
              buttonClass="btn-primary"
              buttonText={isSubmitting || isUpdating ? 'Saving...' : 'Save'}/>
          </div>
        </div>
      </div>
      
    </div>
  );
};

ClubForm.propTypes = {
  onDelete: PropTypes.func,
  isUpdating: PropTypes.bool,
  isDeleting: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  club: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  showDelete: PropTypes.bool,
  onChangeDescription: PropTypes.func.isRequired
};

export default ClubForm;
