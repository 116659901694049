import { CALL_API } from '../../middlewares/api';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  USER_LOGOUT,
  CURRENT_USER_REQUEST,
  CURRENT_USER_SUCCESS,
  CURRENT_USER_FAILURE,
  CLEAR_USER_REDUCERS,
  LINKEDIN_LOGIN_REQUEST,
  LINKEDIN_LOGIN_SUCCESS,
  LINKEDIN_LOGIN_FAILURE,
  CREATE_PASSWORD_IR_REQUEST,
  CREATE_PASSWORD_IR_SUCCESS,
  CREATE_PASSWORD_IR_FAILURE,
  CREATE_PASSWORD_PEER_REQUEST,
  CREATE_PASSWORD_PEER_SUCCESS,
  CREATE_PASSWORD_PEER_FAILURE,
} from '../../constants';

export const clearUserReducers = (currentUserId) => {
  return {
    type: CLEAR_USER_REDUCERS,
    currentUserId,
  };
};

export const checkForToken = (currentUserId) => {
  return function (dispatch) {
    const token = localStorage.getItem('careerPrepped.auth.token') || null;

    if (!token) {
      dispatch(clearUserReducers(currentUserId));
    }
  };
};

export const authenticateUser = (credentials) => {
  const data = Object.assign(credentials, {
    grant_type: 'password',
    client_id: 'careerprepped',
    username: credentials.email,
  });

  return {
    [CALL_API]: {
      data,
      method: 'post',
      endpoint: 'oauth',
      types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
    },
  };
};

export const currentUserRequest = (userId) => {
  const endpoint = `user/user/${userId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [CURRENT_USER_REQUEST, CURRENT_USER_SUCCESS, CURRENT_USER_FAILURE],
    },
  };
};

export const userLogout = () => {
  localStorage.removeItem('careerPrepped.auth.token');
  localStorage.removeItem('IRConfigBar');
  return dispatch =>
    dispatch({
      type: USER_LOGOUT,
    });
};

export const linkedInLogin = (code, token) => {
  const endpoint = `signinwithlinkedin?code=${code}&token=${token}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [
        LINKEDIN_LOGIN_REQUEST,
        LINKEDIN_LOGIN_SUCCESS,
        LINKEDIN_LOGIN_FAILURE,
      ],
    },
  };
};

export const createPasswordForIR = (data) => {
  const endpoint = 'industry-representative-account-setup';

  return {
    [CALL_API]: {
      data,
      method: 'post',
      endpoint,
      types: [
        CREATE_PASSWORD_IR_REQUEST,
        CREATE_PASSWORD_IR_SUCCESS,
        CREATE_PASSWORD_IR_FAILURE,
      ],
    },
  };
};

export const createPasswordForPeer = (data) => {
  const endpoint = 'institution/signup';

  return {
    [CALL_API]: {
      data,
      method: 'post',
      endpoint,
      types: [
        CREATE_PASSWORD_PEER_REQUEST,
        CREATE_PASSWORD_PEER_SUCCESS,
        CREATE_PASSWORD_PEER_FAILURE,
      ],
    },
  };
};
