import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

import CloseIcon from '../../assets/images/close.svg';
import { Link } from 'react-router-dom';

class IRReqSubmitted extends Component {
  constructor(props){
    super(props);

    this.handleToggleModal = this.handleToggleModal.bind(this);
  }

  handleToggleModal(){
    this.props.toggleModal('iRRequestSubmiited');
  }

  render() {
    const { isOpen } = this.props;
    return (
      <Modal
        centered
        className="customLevelUpModal"
        isOpen={isOpen}
        toggle={this.handleToggleModal}>
        <div className="modal-header">
          <span className="modal-title">Feedback Request Submitted</span>
          <div
            onClick={this.handleToggleModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">
              <img src={CloseIcon} />
            </span>
          </div>
        </div>
        <ModalBody>
          <div className="myFeedbackLink">
            <div>
              <i className="fa fa-check-circle fa-4x" />
            </div>
            <div>
              <p>
                Your request has been successfully submitted! Once we match you
                with an Industry Mentor, you'll see your pending
                feedback request in your "My Feedback" section under{' '}"
                <Link to="/myfeedback#myRequest" className="btn-link">
                  My Requests
                </Link>". Once feedback is given, you'll see it in your "My Feedback" section under "Received". If no Industry Mentor is available to give feedback after multiple attempts to match you, we'll notify you.
              </p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="bordertop-0">
          <Button
            onClick={this.handleToggleModal}
            className="sendButton btn">
            Done
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

IRReqSubmitted.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default IRReqSubmitted;