import React from 'react';

import { Link } from 'react-router-dom';
import Chance from 'chance';
import { SimpleToolTipWrapper } from '../common/ToolTipWrapper';

const SkillsSection = () => {
  const chance = new Chance();
  const randomId1 = `simple-tooltip-${chance.hash({ length: 5})}`;
  const randomId2 = `simple-tooltip-${chance.hash({ length: 5})}`;
  const randomId3 = `simple-tooltip-${chance.hash({ length: 5})}`;
  const randomId4 = `simple-tooltip-${chance.hash({ length: 5})}`;
  return (
    <div className="dashboard-quick-links-container">
      <div className="dashboard-quick-links-header">
        Tools to Highlight Your Skills
      </div>
      <div className="dashboard-quick-links-menu">
        <ul>
          <h4 className="dashboard-quick-links-section-header">Grow & Track Your Skills</h4>
          <li>
            <h4>
              <SimpleToolTipWrapper id={randomId1} toolTipContent={
                <div className="inside-tooltip">
                  Build essential skills and claim badges with proof others can see and endorse.
                </div>
              }>
                <Link to="/skill-builders">Skill Builders</Link>
              </SimpleToolTipWrapper>
            </h4>
          </li>
          <li>
            <h4>
              <SimpleToolTipWrapper id={randomId2} toolTipContent={
                <div className="inside-tooltip">
                  Level up your skills and badges with evidence, feedback, and endorsements.
                </div>
              }>
                <Link to="/myskills">Claimed Skills</Link>
              </SimpleToolTipWrapper>
            </h4>
          </li>
        </ul>
        <ul className="mb-0">
          <h4 className="dashboard-quick-links-section-header">Prove & Showcase Your Skills</h4>
          <li>
            <h4>
              <SimpleToolTipWrapper id={randomId3} toolTipContent={
                <div className="inside-tooltip">
                  Save and manage files to show proof of your claimed skills.
                </div>
              }>
                <Link to="/portfolio">Portfolio</Link>
              </SimpleToolTipWrapper>
            </h4>
          </li>
          <li>
            <h4>
              <SimpleToolTipWrapper id={randomId4} toolTipContent={
                <div className="inside-tooltip">
                  View feedback & manage requests to know your strengths and growth areas.
                </div>
              }>
                <Link to="/myfeedback">Feedback</Link>
              </SimpleToolTipWrapper>
            </h4>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SkillsSection;
