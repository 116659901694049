import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { Row, Col } from 'reactstrap';

import AdminListTable from './AdminListTable';
import AdminGroupAddAdmin from './AdminGroupAddAdmin';
import AdminSubGroups from '../../containers/admin/AdminSubGroups';

const AdminsDashboard = ({ isGroupOwner, selectedGroup }) => {
  return (
    <div>
      <Row>
        <Col sm="12">
          <div className="groupName">
            {selectedGroup.name &&
              <span
                className="img-responsive initial-avatar group">
                {selectedGroup.name.slice(0, 1)}
              </span>}

            {selectedGroup.name}
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          {(selectedGroup.children) &&
            (selectedGroup.children.length !== 0) &&
            <AdminSubGroups parentPage="manage-admins"/>}
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="manage-admins-header">
            <h2>Admins of&nbsp;
              <span className="text-orange">
                {selectedGroup.label}
              </span>
              {isGroupOwner &&
                <AdminGroupAddAdmin>
                  <i className="fa fa-plus"/>{' '}Add New Admin
                </AdminGroupAddAdmin>}
            </h2>
          </div>
        </Col>
        <AdminListTable />
      </Row>
    </div>
  );
};

AdminsDashboard.propTypes = {
  isGroupOwner: PropTypes.bool.isRequired,
  selectedGroup: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  const {
    groups: { selectedGroup },
    auth: { data: { currentUser: { id: currentUserId } } }
  } = state;

  const isGroupOwner = currentUserId === selectedGroup.ownerId;

  return {
    isGroupOwner,
    selectedGroup
  };
};

export default connect(mapStateToProps)(AdminsDashboard);
