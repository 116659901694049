import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';
import classNames from 'classnames';

const SideMenuLinks = ({
  profile,
  isFollowerActive
}) => {
  const activeLinkFollower = classNames({
    'active': isFollowerActive
  });

  const activeLinkFollowing = classNames({
    'active': !isFollowerActive
  });

  return(
    <div className="sub-menu">
      <Link
        to={`/cp/${profile.vanity}/following`}
        className={activeLinkFollowing}>
        Following
      </Link>

      <Link
        to={`/cp/${profile.vanity}/followers`}
        className={activeLinkFollower}>
        Followers
      </Link>
    </div>
  );
};

SideMenuLinks.propTypes = {
  profile: PropTypes.object,
  isFollowerActive: PropTypes.bool
};

export default SideMenuLinks;
