import PropTypes from 'prop-types';
import React from 'react';
import _isObject from 'lodash/isObject';
import classNames from 'classnames';

const HeaderLinks = ({
  slug,
  path,
  unit,
  noSection,
  onClickReviewEssentials,
  onClickEngage,
  onClickGetBadge
}) => {
  const reviewStyles = classNames({
    'active': path.includes('essentials')
  });

  const engageStyles = classNames({
    'active': path.includes('discussions')
  });

  const pathArray = path.split('/');
  const isClaimBadgeActive = (pathArray[2] === 'claim-badge');

  const claimStyles = classNames({
    'active': isClaimBadgeActive
  });

  const claimBadgeAnchorStyles = classNames('clickable link',{
    'claimed': noSection
  });

  const redirectPath = 'skill-builders';

  const isBadgeClaimed = _isObject(unit.badge) && unit.badge.isClaimed;
  let claimBadgeAnchorLink = !noSection ? `/${redirectPath}/claim-badge/${slug}` : null;

  if (isBadgeClaimed) {
    claimBadgeAnchorLink = `/skill-badges/badge-details/${unit.badge.issued.id}`;
  }

  const claimBadgeAnchorText = isBadgeClaimed ? 'View Your Badge' : 'Claim Badge';

  return(
    <div className="essential-menu">
      <ul>
        <li className={reviewStyles}>
          <a
            onClick={onClickReviewEssentials}
            className="clickable link">
            Reflect & Practice
          </a>
        </li>

        <li className={engageStyles}>
          <a
            onClick={onClickEngage}
            className="clickable link">
            Engage & Connect
          </a>
        </li>

        <li className={claimStyles}>
          <a
            onClick={onClickGetBadge(claimBadgeAnchorLink)}
            className={claimBadgeAnchorStyles}>
            {claimBadgeAnchorText}
          </a>
        </li>
      </ul>
    </div>
  );
};

HeaderLinks.propTypes = {
  slug: PropTypes.string,
  path: PropTypes.string,
  noSection: PropTypes.bool,
  unit: PropTypes.object.isRequired,
  onClickEngage: PropTypes.func.isRequired,
  onClickGetBadge: PropTypes.func.isRequired,
  onClickReviewEssentials: PropTypes.func.isRequired
};

export default HeaderLinks;
