import React from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

import PropTypes from 'prop-types';

const ConfirmModal = ({
  isOpen,
  modalHeaderText,
  modalMessage,
  modalMessageParam,
  modalFooterButtonText,
  onCancel,
  onConfirm,
  isConfirming,
  confirminginProgressText,
  cancelText = 'Cancel',
  onlyConfirmButton = false
}) => {
  return (
    <Modal className="modal-margin-top" isOpen={isOpen} toggle={onCancel}>
      <ModalHeader className="no-border" toggle={onCancel}>
        {modalHeaderText}
      </ModalHeader>

      {(modalMessage || modalMessageParam) && <ModalBody>{modalMessage || modalMessageParam}</ModalBody>}

      <ModalFooter className="no-border">
        {!onlyConfirmButton && <button onClick={onCancel} className="btn btn-secondary">
          {cancelText}
        </button>}

        <button
          onClick={onConfirm}
          className="btn btn-primary"
          disabled={isConfirming}>
          {isConfirming ? confirminginProgressText : modalFooterButtonText}
        </button>
      </ModalFooter>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  modalHeaderText: PropTypes.string.isRequired,
  modalMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  modalMessageParam: PropTypes.object,
  modalFooterButtonText: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isConfirming: PropTypes.bool,
  confirminginProgressText: PropTypes.string,
  cancelText: PropTypes.string,
  onlyConfirmButton: PropTypes.bool,
};

export default ConfirmModal;