import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col, Button } from 'reactstrap';
import { get } from 'lodash';

import ViewAll from './ViewAll';
import StarRating from '../rating/StarRating';
import SeePraise from './SeePraise';
import { getFeedbackRating } from '../../utils';
import {
  SETTINGS_DATE_FORMAT,
  FEEDBACK_REQUESTED_DATE_FORMAT,
} from '../../constants';

import {callApi} from '../../middlewares/api';

class FeedbackGiven extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isViewAllOpen: false,
      isSeePraiseOpen: false,
      receivedPraiseSelection: {},
    };
  }

  toggleViewAll = () => {
    this.setState((state) => {
      return {
        isViewAllOpen: !state.isViewAllOpen,
      };
    });
  };

  toggleSeePraise = () => {
    const {feedback} = this.props;
    if ( !this.state.isSeePraiseOpen ) {
      this.getSeePraiseOptions(feedback.feedbackRequestId);
    } else {
      this.callToggleSeePraise();
    }
  };

  callToggleSeePraise = () => {
    this.setState((state) => {
      return {
        isSeePraiseOpen: !state.isSeePraiseOpen,
      };
    });
  }

  getSeePraiseOptions = (feedbackRequestId) => {
    callApi('', 'get', `feedback-praise/${feedbackRequestId}`, true).then(
      (response) => {
        if (response.status === 200) {
          const receivedPraises = response.data.detail;
          this.setState({
            receivedPraiseSelection: receivedPraises,
          });
          this.callToggleSeePraise();
        }
      }
    );
  }

  render() {
    const { feedback } = this.props;

    return (
      <div key={feedback.feedbackRequestId}>
        <Row className="myfeedback-skillcontainer">
          <Col md="3">
            <div className="d-flex align-items-center mb-1 mt-1">
              <Link to={`/cp/${feedback.vanity}`} className="user-name">
                <img
                  className="feedbackAvatar"
                  src={
                    feedback.avatarURl
                      ? feedback.avatarURl
                      : require('../../assets/images/avatar.jpg')
                  }/>
              </Link>
              <div className="ml-3">
                <span>
                  <Link to={`/cp/${feedback.vanity}`} className="user-name">
                    <strong>{`${feedback.fullname}`}</strong>
                  </Link>
                </span>
                <div className="d-flex align-items-center">
                  {feedback.isRevised >= 1 && (
                    <i className="fa fa-sm fa-pencil writtenOn" />
                  )}
                  <span className="font12">{`${moment(
                    feedback.feedbackResponseDate.date,
                    SETTINGS_DATE_FORMAT
                  ).format(FEEDBACK_REQUESTED_DATE_FORMAT)}`}</span>
                </div>
              </div>
            </div>
          </Col>
          <Col md="9">
            <Row className="align-items-start mb-3 mb-lg-0">
              <Col md="6" className="mt-3 mt-lg-0 mt-md-0">
                <div className="d-flex align-items-center mb-3">
                  <Link
                    className="title-link"
                    to={
                      feedback.resourceType === 'badge' ?
                        `/skill-badges/badge-details/${feedback?.resourceId}` :
                        `/skill-evidence/${feedback.resourceId}`
                    }>
                    {
                      feedback.resourceType === 'badge' &&
                        <img
                          className="feedbackGivenSkill"
                          src={get(feedback, 'resourceUrl',require(feedback.resourceType === 'badge'
                            ? '../../assets/images/badge_fake.png'
                            : '../../assets/images/temp/skill.png'))}/>
                    }
                    <p className="d-inline font-weight-bold mb-0">
                      {feedback.resourcename}
                    </p>
                  </Link>
                </div>
                {feedback.questionResponseRating?.overall && (
                  <p className="font-weight-bold mt-2">
                    Question Response Ratings: &nbsp;
                    <span className="font-weight-normal">{`${feedback.questionResponseRating.overall}`}</span>
                    <br />
                    <span className="font-weight-normal text-justify text-break">
                      {`${feedback.questionResponseRating.multichoiceComments}`}
                    </span>
                    <br />
                    <span className="font-weight-normal text-justify text-break">
                      {`${feedback.questionResponseRating.additionalComment.substring(0, 140)}`}
                      {(feedback.questionResponseRating.additionalComment.length > 140 ) ? '...' : ''}
                    </span>
                  </p>
                )}
                {feedback.portfolioEvidenceRating?.overall && (
                  <p className="font-weight-bold mt-2">
                    Portfolio Evidence Ratings: &nbsp;
                    <span className="font-weight-normal">{`${feedback.portfolioEvidenceRating.overall}`}</span>
                    <br />
                    <span className="font-weight-normal text-justify text-break">{`${feedback.portfolioEvidenceRating.multichoiceComments}`}</span>
                    <br />
                    <span className="font-weight-normal text-justify text-break">
                      {`${feedback.portfolioEvidenceRating.additionalComment.substring(0, 140)}`}
                      {(feedback.portfolioEvidenceRating.additionalComment.length > 140 ) ? '...' : ''}
                    </span>
                  </p>
                )}
                { (feedback.questionResponseRating?.additionalComment?.length > 140 || feedback.portfolioEvidenceRating?.additionalComment?.length > 140) && <span
                  onClick={this.toggleViewAll}
                  className="d-inline-block pointer themeLinkWithoutUnderline pb-1">
                  View All
                </span>
                }
              </Col>
              <Col md="6">
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <div>
                    <StarRating rating={getFeedbackRating(feedback)} skipEndorsement/>
                  </div>
                  {feedback.receivedPraise ? (
                    <div>
                      <img
                        className="praiseIcon"
                        src={require('../../assets/images/temp/praise.png')}/>
                      <span
                        onClick={this.toggleSeePraise}
                        className="d-inline-block pointer themeLinkWithoutUnderline">
                        See Praise
                      </span>
                    </div>
                  ): ''}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <ViewAll
          feedback={feedback}
          isOpen={this.state.isViewAllOpen}
          toggleModal={this.toggleViewAll}/>
        <SeePraise
          feedback={feedback}
          isOpen={this.state.isSeePraiseOpen}
          toggleModal={this.toggleSeePraise}
          receivedPraiseSelection={this.state.receivedPraiseSelection}/>
        <div className="text-right mb-4">
          <Link
            className="respondLink"
            to={`/givefeedback/${feedback.feedbackRequestId}?revise`}
            state={{
              name: feedback?.fullname,
            }}>
            <Button className="ml-1 sendButton">Revise</Button>
          </Link>
        </div>
        <div className="grayLine mb-3" />
      </div>
    );
  }
}

FeedbackGiven.propTypes = {
  feedback: PropTypes.object.isRequired,
};

export default FeedbackGiven;
