import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { checkHttp } from '../../utils';
import Parser from 'html-react-parser';

const ResumeAwards = ({ resumeAwards }) => {
  return (
    <div className="resume-section">
      <div className="resume-title">
        {resumeAwards.title !== '' ? resumeAwards.title : 'Honors & Awards'}
      </div>
      {
        resumeAwards.awardsArray.map((award) =>{
          const isDateObject = typeof award.date === 'object';
          const dateString = isDateObject ? award.date.date :
            award.date === '' ? undefined : award.date;
          const date = moment(dateString).format('MMMM YYYY');

          const urlAvailable = award.url !== '';
          const urlString = checkHttp(award.url) ?
            award.url : `//${award.url}`;

          return(
            <div key={award.id} style={{marginBottom: 10}}>
              <span><b>{award.title}</b> - {!urlAvailable ?
                award.issuer :
                <a href={urlString} target="_blank" rel="noopener noreferrer">{award.issuer}</a>}
              </span>
              <span style={{marginLeft: 10}}>({date})</span>
              {Parser(award.description)}
            </div>
          );
        })
      }
    </div>
  );
};

ResumeAwards.propTypes = {
  resumeAwards: PropTypes.object.isRequired,
};

export default ResumeAwards;
