import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Validator from '../../validator';
import { Helmet } from 'react-helmet';
import { isEmpty, isUndefined } from 'lodash';
import IAFSelfRegisterForm from '../../components/auth/IAFSelfRegisterForm';
import * as registrationActions from '../../redux/actions/registration';

let credentials = { email: '', confirmEmail: '', groupCode: '' };

class IAFSelfRegister extends Component {
  static propTypes = {
    credentials: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    registration: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      displayAlertType: '',
      credentials: Object.assign({}, this.props.credentials)
    };

    this.dismissAlert = this.dismissAlert.bind(this);
    this.submitRegistration = this.submitRegistration.bind(this);
    this.updateCredentialState = this.updateCredentialState.bind(this);
    
  }

  componentWillMount(){
    document.body.classList.add('iaf-group-signup');
  }
  componentWillUnmount(){
    document.body.classList.remove('iaf-group-signup');
  }

  isValid(field = null) {
    const { credentials, errors: prevErrors } = this.state;

    let validate = Validator.createValidator({
      email: ['required', 'email'],
      confirmEmail: ['required', 'email', 'match|email'],
      groupCode: ['required', 'minLength|10']
    }, credentials, field);

    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }


  updateCredentialState(event) {
    const field = event.currentTarget['name'];
    let credentials = this.state.credentials;

    credentials[field] = event.currentTarget['value'];

    this.setState({ credentials }, () => this.isValid(field));
  }

  handleKeyPress(event) {
    if (event.key === 'Enter')
      return this.submitRegistration(event);
  }

  submitRegistration(event) {
    event.preventDefault();

    if (! this.isValid()) return false;

    this.props
      .actions
      .requestSelfRegistration(this.state.credentials)
      .then(() => {
        const { registration } = this.props;
        let displayAlertType = 'success';

        if ((! isEmpty(registration.error)) && (! isUndefined(registration.error.errorType))) {
          credentials = this.state.credentials;
          displayAlertType = (registration.error.errorType === 'DuplicateSelfRegistrationException') ? 'warning' : 'danger';
        }

        this.setState({ credentials, displayAlertType });
      })
      .catch((error) => {
        let displayAlertType = 'danger';

        if ((! isEmpty(error)) && (! isUndefined(error.errorType))) {
          displayAlertType = (error.errorType === 'DuplicateSelfRegistrationException') ? 'warning' : displayAlertType;
        }

        this.setState({ displayAlertType });
      });
  }

  dismissAlert() {
    this.setState({ displayAlertType: '' });
  }

  render() {
    const { registration } = this.props;
    const { credentials, errors, displayAlertType } = this.state;

    return (
      <div className="info" style={{paddingTop: 50, paddingBottom: 50, marginTop:104}}>
        <Helmet title="Self Register"/>
        <div className="container">
          <div className="row justify-content-center">
            <IAFSelfRegisterForm
              errors={errors}
              credentials={credentials}
              onSubmit={this.submitRegistration}
              handleKeyPress={this.handleKeyPress}
              registration={registration}
              displayAlertType={displayAlertType}
              onChange={this.updateCredentialState}
              dismissAlert={this.dismissAlert}/>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ registration }) => {
  return {
    credentials,
    registration
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(registrationActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IAFSelfRegister);
