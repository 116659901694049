import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

class BadgeAbilitiesCheckbox extends Component {
  static propTypes = {
    badge: PropTypes.object,
    item: PropTypes.object.isRequired,
    isChecked: PropTypes.bool.isRequired,
    onAcknowledgementChange: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      isChecked: props.isChecked
    };

    this.toggleCheckboxChange = this.toggleCheckboxChange.bind(this);
  }

  toggleCheckboxChange() {
    const { item, onAcknowledgementChange } = this.props;

    this.setState(({ isChecked }) => ({ isChecked: !isChecked }));

    onAcknowledgementChange(item.value);
  }

  render() {
    const { isChecked } = this.state;
    const { item, badge } = this.props;
    const labelClass = classNames(
      'ack-form-check-label',
      {
        'enabled': badge,
        'checked': isChecked
      }
    );

    return(
      <label
        style={{display: 'block'}}
        className={labelClass}>
        <input
          disabled={!badge}
          checked={isChecked}
          onChange={this.toggleCheckboxChange}
          className="form-check-input"
          value={item.value}
          type="checkbox"/>
        {item.value}
      </label>);
  }
}

export default BadgeAbilitiesCheckbox;
