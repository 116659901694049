import { CALL_API } from '../../middlewares/api';

import {
  REQUEST_PASSWORD_RESET_REQUEST,
  REQUEST_PASSWORD_RESET_SUCCESS,
  REQUEST_PASSWORD_RESET_FAILURE,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE,
} from '../../constants';

export const resetPasswordRequest = (data) => {
  const endpoint = 'password/reset-request';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      types: [
        REQUEST_PASSWORD_RESET_REQUEST,
        REQUEST_PASSWORD_RESET_SUCCESS,
        REQUEST_PASSWORD_RESET_FAILURE,
      ],
    },
  };
};

export const resetPassword = (data) => {
  const endpoint = 'password/reset-password';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      types: [
        PASSWORD_RESET_REQUEST,
        PASSWORD_RESET_SUCCESS,
        PASSWORD_RESET_FAILURE,
      ],
    },
  };
};
