import React from 'react';

import { connect } from 'react-redux';

import { parseUrlString } from '../../../utils';

import PropTypes from 'prop-types';
import Parser from 'html-react-parser';
import CpHtmlParser from '../../common/CpHtmlParser';

const SummaryCard = ({ profile, currentUserId }) => {
  const { id: profileId, name, summary, video } = profile;
  const videoAvailable = video && video !== '';

  const isSummaryAvailable = (summary.length !== 0) || videoAvailable;
  const isVisiting = profileId !== currentUserId;

  const videoUrl = videoAvailable && parseUrlString(video);

  if (!isSummaryAvailable) {
    return (
      <div className="badge-none empty-section-message">
        {isVisiting ?
          `${name} hasn't added their about me information yet.` :
          <span>Briefly tell the world what they should know about you.</span>}
      </div>
    );
  }

  return (
    <div className="profile-item__card card-flex">
      <div className="ql-editor no-padding-in-quill">
        {summary && <CpHtmlParser htmlString={summary}/>}
      </div>
      {
        videoAvailable &&
          <div className="embed-responsive embed-responsive-16by9">
            {
              parseUrlString(video) === 'embed' ?
                Parser(video):
                <iframe className="embed-responsive-item"
                  src={videoUrl.url}
                  allowFullScreen/>
            }
          </div>
      }
    </div>
  );
};

SummaryCard.propTypes = {
  profile: PropTypes.object.isRequired,
  currentUserId: PropTypes.string
};

const mapStateToProps = ({ auth: { data: { currentUser: { id: currentUserId } } } }) =>
  ({ currentUserId });

export default connect(mapStateToProps)(SummaryCard);
