import PropTypes from 'prop-types';
import React from 'react';

import moment from 'moment';
import Parser from 'html-react-parser';
import classNames from 'classnames';

const MessageListItem = ({ message, slug, isMobile, onThrashIconClicked, onMessageItemClicked }) => {
  const { subject, user, lastBody: lastMessage, lastBodyCreatedAt, readAt, id } = message;
  const dateString = moment(lastBodyCreatedAt.date).format('MMM DD');
  const isActiveItem = id === slug;
  const read = readAt !== null;

  return(
    <div className={classNames('messageListItem clearfix', {
      read,
      'unread': !read,
      'current': isActiveItem
    })}>
      <div onClick={onMessageItemClicked(id)} className="message-list-link clickable">
        <div className="media">
          <div className="media-left message-left">
            <div className="messageNewIndicator float-left"/>
            {user.avatar !== '' ?
              <img
                className="profile-avatar media-object"
                src={user.avatar}
                alt="User avatar"/> :
              <span
                style={{
                  lineHeight: '64px',
                  fontSize: 32,
                }}
                className="profile-avatar media-object initial-avatar">
                {user.name && user.name.slice(0,1)}
              </span>}
          </div>
          <div className="media-body">
            <div className="message-subject-container">
              <h4 className={classNames('media-heading messageListItemTitle message-subject', {
                'message-subject-ellipsis': !isMobile,
                'two-line-body one': isMobile
              })}>{subject}</h4>
            </div>
            <span className="messageListItemBody two-line-body"><strong>{user.name}</strong>: {Parser(lastMessage)}</span>
          </div>
          <div className="messageMetaContainer">
            <span className="messageListItemDate">{dateString}</span>
            <span className="messageListItemActions">
              <i
                id={id}
                onClick={onThrashIconClicked}
                className="fas text-danger fa-trash-alt"/>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

MessageListItem.propTypes = {
  message: PropTypes.object.isRequired,
  slug: PropTypes.string,
  isMobile: PropTypes.bool,
  onThrashIconClicked: PropTypes.func.isRequired,
  onMessageItemClicked: PropTypes.func.isRequired
};

export default MessageListItem;
