import PropTypes from 'prop-types';
import React from 'react';

const LoginForm = ({
  toggleLoginModal,
  credentials,
  errors,
  isRequesting,
  onChange,
  onSubmit,
  handleKeyPress,
  emailRef,
  toggleForgotPassword
}) => {
  return (
    <div className="modal-body homepage-modal-body">
      <button onClick={toggleLoginModal} className="close">
        <span aria-hidden="true">&times;</span>
      </button>
      <h4 className="modal-title" id="myModalLabel">Sign In</h4>
      <form onSubmit={onSubmit} onKeyPress={handleKeyPress}>
        <div className="form-group">
          <label className="sr-only">Email</label>
          <input
            ref={emailRef || null}
            type="text"
            name="email"
            className="form-control"
            placeholder="Email"
            value={credentials.email}
            onChange={onChange}/>
          {errors.email && <div className="text-danger">{errors.email}</div>}
        </div>
        <div className="form-group">
          <label className="sr-only">Password</label>
          <input
            type="password"
            name="password"
            className="form-control"
            placeholder="Password"
            value={credentials.password}
            onChange={onChange}/>
          {errors.password && <div className="text-danger">{errors.password}</div>}
        </div>

        <input
          type="submit"
          disabled={isRequesting}
          value={isRequesting ? 'Authenticating...' : 'Sign In'}
          className="btn btn-primary w-100"/>
        <a onClick={toggleForgotPassword} className="forgot-password link-orange">Forgot Password?</a>
      </form>
    </div>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  handleKeyPress: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  emailRef: PropTypes.func,
  errors: PropTypes.object,
  credentials: PropTypes.object,
  toggleLoginModal: PropTypes.func.isRequired,
  toggleForgotPassword: PropTypes.func.isRequired,
};

export default LoginForm;
