import PropTypes from 'prop-types';
import React from 'react';
import Parser from 'html-react-parser';

const FullPost = ({post}) => {
  const arrayToMap = post[0].length === 1 || typeof post[0] === 'string' ? 
    post : 
    post[0]; 
    
  return (
    <span className="dont-break-out">
      {
        arrayToMap && 
          arrayToMap.map((item, index) => {
            if (React.isValidElement(item)) {
              return <span key={index}>{item}</span>;
            }

            if(Array.isArray(item)){
              return item.map((subitem, subIndex)=> {
                if(React.isValidElement(subitem))
                  return <span key={subIndex}>{subitem}</span>;

                return <span key={subIndex}>{subitem}</span>;
              });
            }

            const parsedItems = Parser(typeof item === 'string' ? item : item[0]);
            return <span key={index}>{parsedItems}</span>;
          })
      }
    </span>
  );
};

FullPost.propTypes = {
  post: PropTypes.array.isRequired,
  secondary: PropTypes.bool,
  showMoreCondition: PropTypes.bool
};

export default FullPost;
