import PropTypes from 'prop-types';
import React from 'react';
import Parser from 'html-react-parser';

const styles = {
  deleteButton: {
    cursor: 'pointer',
    color: '#f00'
  },

  editButton: {
    marginRight: 10,
    cursor: 'pointer'
  },

  buttonWrapper: {
    fontSize: 10,
    position: 'absolute',
    right: 10,
    top: 0
  },

  listGroupItem: { paddingBottom: 0 }
};

const QuizAnswer = ({
  item,
  checked,
  onChange,
  onDeleteListItem,
  onEditListItem
}) => {
  return (
    <li className="list-group-item item" id={item.itemId}>
      <div className="row">
        <div className="col-sm-1">
          <label>
            <input
              type="radio"
              name="correct"
              value={item.value}
              onChange={onChange}
              checked={checked}/>
          </label>
        </div>
        <div className="col-sm-11">
          {Parser(item.value)}
          <span id={item.itemId} style={styles.buttonWrapper}>
            <i
              className="fa fa-pencil"
              style={styles.editButton}
              onClick={onEditListItem}/>
            <i
              className="fa fa-close"
              style={styles.deleteButton}
              onClick={onDeleteListItem}/>
          </span>
        </div>
      </div>
    </li>
  );
};

QuizAnswer.propTypes = {
  item: PropTypes.object,
  error: PropTypes.string,
  checked: PropTypes.bool,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onEditListItem: PropTypes.func.isRequired,
  onDeleteListItem: PropTypes.func.isRequired
};

export default QuizAnswer;
