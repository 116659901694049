import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Button from '../../components/common/Button';
import TextInput from '../../components/common/TextInput';
import Validator from '../../validator';

import _isEqual from 'lodash/isEqual';

class UserDetailsForm extends Component {
  static propTypes = {
    resume: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onUnloadForm: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      resume: Object.assign({}, this.props.resume)
    };

    this.onSave = this.onSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (! _isEqual(this.state.resume, nextProps.resume)) {
      this.setState({ resume: nextProps.resume });
    }
  }

  isValid(field = null) {
    const rules = {
      name: ['required'],
      email: ['required', 'email'],
      city: ['required'],
      state: ['required'],
      vanity: ['required'],
      phone: ['required', 'phone']
    };
    const { resume, errors: prevErrors} = this.state;
    const validate = Validator.createValidator(rules, resume, field);
    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    const newErrors = Object.assign({}, prevErrors, errors);

    this.setState({ errors: newErrors });

    return isValid;
  }

  onSave(event){
    const { resume } = this.state;

    if(!this.isValid())
      return;

    this.props.onChange(resume);
    this.props.onUnloadForm(event);
  }

  handleChange(event){
    const { name, value } = event.target;
    const resume = Object.assign({}, this.state.resume, { [name]: value });

    this.setState({ resume }, () => this.isValid(name));
  }

  render() {
    const { onUnloadForm } = this.props;
    const { resume, errors } = this.state;
    const { host, protocol } = window.location;
    const vanityUrl = `${protocol}//${host}/cp/${resume.vanity}`;

    return (

      <div style={{border: '1px solid #e3e5e5', padding: 15, fontSize: 14}}>
        <div className="row">
          <div className="col-sm-6">
            <TextInput
              type="text"
              name="name"
              label="Name"
              className="form-control"
              placeholder="Name"
              value={resume.name}
              onChange={this.handleChange}
              error={errors.name}/>
          </div>

          <div className="col-sm-6">
            <TextInput
              type="text"
              name="city"
              label="City"
              className="form-control"
              placeholder="City"
              value={resume.city}
              onChange={this.handleChange}
              error={errors.city}/>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <TextInput
              type="text"
              name="state"
              label="State/Province"
              className="form-control"
              placeholder="State/Province"
              value={resume.state}
              onChange={this.handleChange}
              error={errors.state}/>
          </div>
          <div className="col-sm-6">
            <TextInput
              type="text"
              name="phone"
              label="Phone Number"
              className="form-control"
              placeholder="Phone number"
              value={resume.phone}
              onChange={this.handleChange}
              error={errors.phone}/>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <TextInput
              type="text"
              name="email"
              label="Email"
              className="form-control"
              placeholder="Email"
              value={resume.email}
              onChange={this.handleChange}
              error={errors.email}/>
          </div>
          <div className="col-sm-6">
            <TextInput
              type="text"
              name="vanity"
              label="Custom URL"
              className="form-control"
              placeholder="Custom URL"
              disabled
              value={vanityUrl}
              onChange={this.handleChange}
              error={errors.vanity}/>
          </div>
          <div className="col-sm-12 text-right">
            <hr/>
            <Button
              buttonText="Cancel"
              styles={{marginLeft: 0}}
              buttonId="editUsernameForm"
              onClick={onUnloadForm}
              buttonClass="btn-secondary"/>
            <Button
              buttonText="Save"
              onClick={this.onSave}
              buttonId="editUsernameForm"
              buttonClass="btn-primary"/>
          </div>
        </div>
      </div>
    );
  }
}

export default UserDetailsForm;
