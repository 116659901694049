import PropTypes from 'prop-types';
import React from 'react';

import FileCardActions from '../../containers/portfolio/FileCardActions';
import LazyLoadImage from '../../containers/common/lazy-load-image/LazyLoadImage';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import classNames from 'classnames';
import { checkUrl } from '../../utils';

import IconBookmark from '../../../src/assets/images/icon-bookmark.svg';
import IconVideo from '../../../src/assets/images/icon-video.svg';

const FileCard = ({
  fileItem,
  onShareToCp,
  onEditFile,
  onViewFile,
  visitingUser,
  isLikingFile,
  onCopy,
  onUpdateFileLike
}) => {
  const { id, url, mimetype, like_count, title, description: rawDescription, liked, comment_count, user } = fileItem;
  const docFile = mimetype.includes('application') || mimetype.includes('text');
  const fileUrl = `${location.href}/${user.vanity}/portfolio-details/${id}`;
  const videoFile = (checkUrl(url, 'youtube') || checkUrl(url, 'vimeo'));
  const webLink = (!videoFile && !docFile && !mimetype.includes('image'));

  const seeMoreCondition = rawDescription.replace(/<[^>]+>/g, '').length > 85;
  const descriptionString = rawDescription.replace(/<[^>]+>/g, '');
  const description = seeMoreCondition ?
    `${descriptionString.slice(0, 85)}...` : descriptionString;

  const portfolioItemClassNames = classNames('port-file', {
    'portfolio-img': mimetype.includes('image'),
    'portfolio-doc': docFile,
    'portfolio-video': videoFile,
    'portfolio-weblink': webLink
  });

  return (
    <div className={portfolioItemClassNames}>
      <div
        className="portfolio-file-image clickable"
        onClick={onViewFile(id)}>
        {docFile &&
          <div className="thumb file-icon-container">
            <i className="fa fa-file-text-o"/>
          </div>}

        {videoFile &&
          <div className="thumb file-icon-container">
            <i>
              <img src={IconVideo} />
            </i>
          </div>}

        {webLink &&
          <div className="thumb file-icon-container">
            <i>
              <img src={IconBookmark} />
            </i>
          </div>}

        {!docFile && mimetype !== '' &&
          <div className="portfolio-file-image-container">
            <LazyLoadImage
              imageClassName="thumb-image"
              alt={`${title}'s thumnail`}
              srcLoaded={url}/>
          </div>}
      </div>
      <div className="file-details">
        <h3 className="portfolio-title">
          <span
            className="clickable link"
            onClick={onViewFile(id)}>
            {title || 'No title'}
          </span>
        </h3>
        <div className="portfolio-description">{description}</div>
      </div>
      <div className="portfolio-stats">
        <ul className="list-inline portfolio-details-list">
          <li className="list-inline-item">
            {
              isLikingFile ?
                <span style={{cursor: 'no-drop'}}><i className="fa fa-heart" aria-hidden="true"/></span> :
                <a
                  className="clickable"
                  onClick={onUpdateFileLike(id)}
                  title={liked ? 'UNLIKE': 'LIKE'}>
                  <i className={classNames('fa fa-heart', {
                    'liked-heart': liked
                  })} aria-hidden="true"/>
                </a>
            }
            {like_count}
          </li>
          <li className="list-inline-item" title="Comments"><i className="fa fa-comment" aria-hidden="true"/>{comment_count}</li>
        </ul>

        <div className="d-flex align-items-center justify-content-end">
          <a onClick={onShareToCp} className="clickable" title="SHARE">
            <i className="fa fa-share" aria-hidden="true"/>
          </a>
          {! visitingUser &&
            <CopyToClipboard text={fileUrl} onCopy={onCopy}>
              <a className="clickable" title="Copy Link">
                <i className="fa fa-link" aria-hidden="true"/>
              </a>
            </CopyToClipboard>}
          {! visitingUser &&
            <FileCardActions
              fileItem={fileItem}
              onEdit={onEditFile}/>}
        </div>
      </div>
    </div>
  );
};

FileCard.propTypes = {
  fileItem: PropTypes.object,
  showShareDropdown: PropTypes.bool,
  onEditFile: PropTypes.func,
  onViewFile: PropTypes.func,
  onShareClick: PropTypes.func,
  onShareToCp: PropTypes.func,
  onCopy: PropTypes.func,
  visitingUser: PropTypes.bool,
  isLikingFile: PropTypes.bool,
  onUpdateFileLike: PropTypes.func,
  inEvidence: PropTypes.bool
};

export default FileCard;
