import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { parseJSON } from '../../../utils';
import { toastr } from 'react-redux-toastr';
import Validator from '../../../validator';
import * as sectionActions from '../../../redux/actions/sections';
import * as unitActions from '../../../redux/actions/skill-builders';
import * as componentsActions from '../../../redux/actions/components';
import ParagraphForm from '../../../components/skill-builders/admin/ParagraphForm';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

class Paragraph extends Component {
  static propTypes = {
    section: PropTypes.object,
    sections: PropTypes.array.isRequired,
    unit: PropTypes.object.isRequired,
    units: PropTypes.array.isRequired,
    actions: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    isDeleting: PropTypes.bool.isRequired,
    formType: PropTypes.object
  }

  constructor(props, context) {
    super(props, context);

    let editorState = EditorState.createEmpty();

    if (props.section.content.length !== 0) {
      const blocksFromHTML = convertFromHTML(props.section.content);

      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );

      editorState = EditorState.createWithContent(contentState);
    }

    this.state = {
      errors: {},
      editorState,
      section: Object.assign({}, this.props.section)
    };

    this.updateSectionState = this.updateSectionState.bind(this);
    this.saveSection = this.saveSection.bind(this);
    this.deleteSection = this.deleteSection.bind(this);
    this.setEditorState = this.setEditorState.bind(this);
    this.getEditorState = editorState => this._getEditorState(editorState);
  }

  componentDidMount() {
    window.onbeforeunload = () => {
      return 'Changes you made might get lost!';
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = () => {
      return null;
    };
  }

  isValid(field = null) {
    let validate = Validator.createValidator(
      {
        title: ['required', 'minLength|1'],
        content: ['required', 'minLength|1']
      },
      this.state.section,
      field
    );

    let { isValid, errors } = validate;

    this.setState({ errors });

    return isValid;
  }

  setEditorState() {
    return this.state.editorState;
  }

  _getEditorState(editorState) {
    const currentContent = editorState.getCurrentContent();

    const content = currentContent.getPlainText();
    const section = Object.assign({}, this.state.section, { content });

    this.setState({ editorState, section },() => this.isValid('content'));
  }

  updateSectionState(event) {
    const field = event.target.name;
    const section = this.state.section;

    section[field] = event.target.value;

    this.setState({ section }, () => this.isValid(field));
  }

  formatData() {
    const { section, editorState } = this.state;
    const { title, sectiontype } = section;
    const currentContent = editorState.getCurrentContent();
    const htmlContentString = stateToHTML(currentContent);
    const content = JSON.stringify({ title, content: htmlContentString });

    return {
      content,
      sectiontype,
      unit: this.props.unit.id
    };
  }

  saveSection(event) {
    event.preventDefault();

    if (! this.isValid()) return;

    const { unit, units, section, sections, actions } = this.props;
    const formId = event.target.parentElement.id;
    const formIdArray = formId.split('_');
    const formIndex = parseInt(formIdArray[1]);
    const sectionData = this.formatData();

    if (section.id) {
      sectionData.id = section.id;

      const sectionIndex = sections.findIndex(sectionItem =>
        sectionItem.id === section.id);

      return actions.updateSection(sectionData, sectionIndex)
        .then(() => {
          toastr.success('Section Updated.');
          actions.unloadUpdateForm(String(section.id));
        });
    }

    const unitIndex = units.findIndex(unitItem => unitItem.id === unit.id);

    actions.saveSection(sectionData, unit, unitIndex)
      .then(() => {
        toastr.success('Section saved.');
        actions.unloadSectionForm({ id: formIndex, formType: 'sections' });
      });
  }

  deleteSection(event) {
    event.preventDefault();

    const { actions, section, unit, units } = this.props;

    actions.deleteSection(section.id)
      .then(() => {
        const sectionsArray = unit.sections.split(',');
        const sectionsList = [...sectionsArray.filter(id => id !== section.id)];

        const sectionsString = sectionsList.join();
        const unitData = Object.assign({}, unit);
        const unitIndex = units.findIndex(unitItem => unitItem.id === unit.id);

        unitData.sections = sectionsString;

        actions.updateUnit(unitData, unitIndex)
          .then(() => {
            toastr.success('Section deleted.');
            actions.unloadUpdateForm(String(section.id));
          });
      });
  }

  render() {
    const { errors, section } = this.state;
    const {
      isAdmin,
      onCancel,
      isSubmitting,
      isUpdating,
      formType,
      isDeleting
    } = this.props;

    return (
      <ParagraphForm
        isAdmin={isAdmin}
        errors={errors}
        section={section}
        onCancel={onCancel}
        isSubmitting={isSubmitting}
        isUpdating={isUpdating}
        isDeleting={isDeleting}
        formType={formType}
        onChange={this.updateSectionState}
        onSave={this.saveSection}
        onDelete={this.deleteSection}
        setEditorState={this.setEditorState}
        getEditorState={this.getEditorState}/>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let section = { sectiontype: 'paragraph', content: '', title: '' };
  const { isSubmitting, isUpdating, isDeleting } = state.sections;

  if (ownProps.section) {
    const data = parseJSON(ownProps.section.content);
    const { title, content } = data;

    section = Object.assign({}, section, { id: ownProps.section.id, title, content });
  }

  return {
    section,
    unit: ownProps.unit,
    isSubmitting,
    sections: state.sections.data,
    units: state.skillBuilders.data,
    isUpdating: isUpdating.status,
    isDeleting: isDeleting.status
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    unitActions,
    sectionActions,
    componentsActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Paragraph);
