import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Club from './Club';
import Award from './Award';
import Interests from './Interests';
import Project from './Project';
import Skills from './Skills';
import Education from './Education';
import Experience from './Experience';
import Publication from './Publication';
import Association from './Association';
import Certification from './Certification';


class ProfileSection extends Component {
  static propTypes = {
    section: PropTypes.object.isRequired,
    isPublicProfile: PropTypes.bool.isRequired,
    showVisibilitySelect: PropTypes.bool,
    onChangeVisibility: PropTypes.func,
    editingSection: PropTypes.array,
    onToggleSectionEdit: PropTypes.func,
    onConfirmDeleteSection: PropTypes.func,
    profile: PropTypes.object,
  };

  render() {
    let sectionComponent = null;

    const {
      section,
      showVisibilitySelect,
      editingSection,
      onConfirmDeleteSection,
      profile,
      isPublicProfile,
      onChangeVisibility,
      onToggleSectionEdit,
    } = this.props;

    const isEditingSections = editingSection && editingSection.length > 0;

    switch(section.name) {
    case 'Award':
      sectionComponent = (<Award
        profile={profile}
        isPublicProfile={isPublicProfile}
        onConfirmDeleteSection={onConfirmDeleteSection}
        onChangeVisibility={onChangeVisibility}
        onToggleSectionEdit={onToggleSectionEdit}
        isEditingSections={isEditingSections}
        showVisibilitySelect={showVisibilitySelect}/>);
      break;

    case 'Association':
      sectionComponent = (<Association
        isPublicProfile={isPublicProfile}
        onConfirmDeleteSection={onConfirmDeleteSection}
        profile={profile}
        onChangeVisibility={onChangeVisibility}
        onToggleSectionEdit={onToggleSectionEdit}
        isEditingSections={isEditingSections}
        showVisibilitySelect={showVisibilitySelect}/>);
      break;

    case 'Club':
      sectionComponent = (<Club
        profile={profile}
        isPublicProfile={isPublicProfile}
        onConfirmDeleteSection={onConfirmDeleteSection}
        onChangeVisibility={onChangeVisibility}
        onToggleSectionEdit={onToggleSectionEdit}
        isEditingSections={isEditingSections}
        showVisibilitySelect={showVisibilitySelect}/>);
      break;

    case 'Certification':
      sectionComponent = (<Certification
        profile={profile}
        isPublicProfile={isPublicProfile}
        onConfirmDeleteSection={onConfirmDeleteSection}
        onChangeVisibility={onChangeVisibility}
        onToggleSectionEdit={onToggleSectionEdit}
        isEditingSections={isEditingSections}
        showVisibilitySelect={showVisibilitySelect}/>);
      break;

    case 'Education':
      sectionComponent = (<Education
        profile={profile}
        isPublicProfile={isPublicProfile}
        onChangeVisibility={onChangeVisibility}
        onToggleSectionEdit={onToggleSectionEdit}
        isEditingSections={isEditingSections}
        showVisibilitySelect={showVisibilitySelect}/>);
      break;

    case 'Experience':
      sectionComponent = (<Experience
        profile={profile}
        isPublicProfile={isPublicProfile}
        onChangeVisibility={onChangeVisibility}
        onToggleSectionEdit={onToggleSectionEdit}
        isEditingSections={isEditingSections}
        showVisibilitySelect={showVisibilitySelect}/>);
      break;

    case 'Interests':
      sectionComponent = (<Interests
        profile={profile}
        isPublicProfile={isPublicProfile}
        onConfirmDeleteSection={onConfirmDeleteSection}
        onChangeVisibility={onChangeVisibility}
        onToggleSectionEdit={onToggleSectionEdit}
        isEditingSections={isEditingSections}
        showVisibilitySelect={showVisibilitySelect}/>);
      break;

    case 'Project':
      sectionComponent = (<Project
        profile={profile}
        isPublicProfile={isPublicProfile}
        onConfirmDeleteSection={onConfirmDeleteSection}
        onChangeVisibility={onChangeVisibility}
        onToggleSectionEdit={onToggleSectionEdit}
        isEditingSections={isEditingSections}
        showVisibilitySelect={showVisibilitySelect}/>);
      break;

    case 'Publication':
      sectionComponent = (<Publication
        onConfirmDeleteSection={onConfirmDeleteSection}
        profile={profile}
        isPublicProfile={isPublicProfile}
        onChangeVisibility={onChangeVisibility}
        onToggleSectionEdit={onToggleSectionEdit}
        isEditingSections={isEditingSections}
        showVisibilitySelect={showVisibilitySelect}/>);
      break;

    case 'Skills':
      sectionComponent = (
        <Skills
          profile={profile}
          isPublicProfile={isPublicProfile}
          onToggleSectionEdit={onToggleSectionEdit}
          isEditingSections={isEditingSections} />
      );
      break;

    default:
      '';
    }

    let component = (
      <div
        style={{opacity: 1, border: 'none'}}
        className="card admin-panel">
        {sectionComponent}
      </div>
    );

    return component;
  }
}

export default ProfileSection;
