import isUndefined from 'lodash/isUndefined';

import { CALL_API } from '../../middlewares/api';
import {
  CLEAR_ISSUED_BADGES,
  RESET_BADGES,
  BADGES_REQUEST,
  BADGES_SUCCESS,
  BADGES_FAILURE,
  GET_ISSUED_BADGES_REQUEST,
  GET_ISSUED_BADGES_SUCCESS,
  GET_ISSUED_BADGES_FAILURE,
  GET_MY_ISSUED_BADGES_SUCCESS,
  ISSUE_BADGE_REQUEST,
  ISSUE_BADGE_SUCCESS,
  ISSUE_BADGE_FAILURE,
  SAVE_SELECTED_BADGES,
  RESET_SELECTED_BADGES,
  REMOVE_ACKNOWLEDGEMENT_REQUEST,
  REMOVE_ACKNOWLEDGEMENT_SUCCESS,
  REMOVE_ACKNOWLEDGEMENT_FAILURE,
  LINK_PORTFOLIO_FILE_REQUEST,
  LINK_PORTFOLIO_FILE_SUCCESS,
  LINK_PORTFOLIO_FILE_FAILURE,
  LINK_MY_SKILLS_REQUEST,
  LINK_MY_SKILLS_SUCCESS,
  LINK_MY_SKILLS_FAILURE,
  GET_RESOURCE_DATA_REQUEST,
  GET_RESOURCE_DATA_SUCCESS,
  GET_RESOURCE_DATA_FAILURE,
} from '../../constants';

export const saveSelected = (selectedBadges) => {
  return { type: SAVE_SELECTED_BADGES, selectedBadges };
};

export const saveSelectedBadges = (selectedBadges) => {
  return dispatch => dispatch(saveSelected(selectedBadges));
};

export const resetSelected = () => {
  return { type: RESET_SELECTED_BADGES };
};

export const resetSelectedBadges = () => {
  return dispatch => dispatch(resetSelected());
};

export const resetBadges = () => {
  return { type: RESET_BADGES };
};

export const clearIssuedBadges = () => {
  return { type: CLEAR_ISSUED_BADGES };
};

export const getMyIssuedBadges = (userid) => {
  const endpoint = `badge/issued?user=${userid}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [GET_ISSUED_BADGES_REQUEST, GET_MY_ISSUED_BADGES_SUCCESS, GET_ISSUED_BADGES_FAILURE],
    },
  };
};

export const getIssuedBadges = (isAuthenticated, userId, pageNo = 1) => {
  const endpoint = `badge/badge?user=${userId}&page=${pageNo}`;

  return {
    [CALL_API]: isAuthenticated
      ? {
        endpoint,
        method: 'get',
        authenticated: true,
        types: [
          GET_ISSUED_BADGES_REQUEST,
          GET_ISSUED_BADGES_SUCCESS,
          GET_ISSUED_BADGES_FAILURE,
        ],
      }
      : {
        endpoint,
        method: 'get',
        types: [
          GET_ISSUED_BADGES_REQUEST,
          GET_ISSUED_BADGES_SUCCESS,
          GET_ISSUED_BADGES_FAILURE,
        ],
      },
  };
};

export const requestAllBadges = (pageNo = 1, sortBy, searchValue) => {
  let endpoint = `badge/badge?page=${pageNo}`;

  if (!isUndefined(sortBy) && sortBy !== 'none') {
    endpoint = `${endpoint}&sort=${sortBy}`;
  }

  if (!isUndefined(searchValue) && searchValue !== '') {
    endpoint = `${endpoint}&search=${searchValue}`;
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [BADGES_REQUEST, BADGES_SUCCESS, BADGES_FAILURE],
    },
  };
};

export const issueBadge = (data, index) => {
  const endpoint = 'badge/issued';

  return {
    [CALL_API]: {
      data,
      index,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [ISSUE_BADGE_REQUEST, ISSUE_BADGE_SUCCESS, ISSUE_BADGE_FAILURE],
    },
  };
};

export const removeAcknowledgement = (ackId) => {
  const endpoint = `badge/acknowledgement/${ackId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'delete',
      authenticated: true,
      types: [
        REMOVE_ACKNOWLEDGEMENT_REQUEST,
        REMOVE_ACKNOWLEDGEMENT_SUCCESS,
        REMOVE_ACKNOWLEDGEMENT_FAILURE,
      ],
    },
  };
};

export const linkfiles = (data, index) => {
  const endpoint = 'badge/file';

  return {
    [CALL_API]: {
      data,
      index,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        LINK_PORTFOLIO_FILE_REQUEST,
        LINK_PORTFOLIO_FILE_SUCCESS,
        LINK_PORTFOLIO_FILE_FAILURE,
      ],
    },
  };
};

export const linkMySkills = (data) => {
  const endpoint = 'badge/skills';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        LINK_MY_SKILLS_REQUEST,
        LINK_MY_SKILLS_SUCCESS,
        LINK_MY_SKILLS_FAILURE,
      ],
    },
  };
};

export const getResourceData = (id) => {
  const endpoint = `badge/issued/${id}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        GET_RESOURCE_DATA_REQUEST,
        GET_RESOURCE_DATA_SUCCESS,
        GET_RESOURCE_DATA_FAILURE,
      ],
    },
  };
};
