import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { checkHttp } from '../../utils';
import Parser from 'html-react-parser';

const ResumeAssociations = ({ resumeAssociations }) => {
  return (
    <div className="resume-section">
      <div className="resume-title">
        {resumeAssociations.title !== '' ? resumeAssociations.title : 'Associations'}
      </div>
      {resumeAssociations.associationsArray.map((association) => {
        const isStartDateObject = typeof association.startdate === 'object';
        const startDateString = isStartDateObject ? association.startdate.date :
          association.startdate === '' ? undefined : association.startdate;

        const isEndDateObject = typeof association.enddate === 'object';
        const endDateString = isEndDateObject ? association.enddate.date :
          association.enddate === '' ? undefined : association.enddate;

        const startDate = moment(startDateString).format('MM/YYYY');
        let endDate;

        if (association.noend) {
          endDate = 'Present';
        } else {
          endDate = moment(endDateString).format('MM/YYYY');
        }

        const urlAvailable = association.url !== '';
        const urlString = checkHttp(association.url) ?
          association.url : `//${association.url}`;

        return(
          <div key={association.id} style={{marginBottom: 10}}>
            {!urlAvailable ?
              <span><b>{association.name}</b></span> :
              <span><b><a href={urlString} target="_blank" rel="noopener noreferrer">{association.name}</a></b></span>}

            <span style={{marginLeft: 10}}>({startDate} - {endDate})</span>
            <p><b>Position(s):</b> {association.position}</p>
            {Parser(association.description)}
          </div>
        );
      })}
    </div>
  );
};

ResumeAssociations.propTypes = {
  resumeAssociations: PropTypes.object.isRequired,
};

export default ResumeAssociations;
