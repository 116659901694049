import PropTypes from 'prop-types';
import React from 'react';
import { loadForm } from '../../../utils';
import UnitBadgeForm from '../../../containers/skill-builders/admin/UnitBadgeForm';

const styles = {
  editMenuIcon: {
    fontSize: 14
  }
};

const UnitBadgeCard = ({
  unit,
  loadForms,
  onEditBadge,
  isUpdatingBadge
}) => {
  const { badge } = unit;
  const isForm = loadForm(loadForms, `edit-unit-badge-${badge.id}`);
  const isUpdating = isUpdatingBadge.status;

  if (isForm) {
    return <UnitBadgeForm unit={unit} unitBadge={badge}/>;
  }

  return(
    <div className="unit-badge">
      <div className="row">
        <div className="col-sm-3">
          <img
            style={{width:100,height:100}}
            src={badge.url}/>
        </div>
        <div className="col-sm-9">
          <div className="unit-badge-name">{badge.name}</div>
          <div className="unit-badge-description">{badge.description}</div>
        </div>
      </div>
      <span className="btn-group edit-menu align-top float-right">
        <button
          onClick={onEditBadge}
          disabled={isUpdating}
          className="btn-link notes-btns badge-btn p-3">
          <i
            className="fa fa-pencil"
            aria-hidden="true"
            style={styles.editMenuIcon}/>
        </button>
      </span>
    </div>
  );
};
UnitBadgeCard.propTypes = {
  unit: PropTypes.object.isRequired,
  onEditBadge: PropTypes.func.isRequired,
  isUpdatingBadge: PropTypes.object.isRequired,
  loadForms: PropTypes.array.isRequired,
};
export default UnitBadgeCard;
