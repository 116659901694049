import React from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

import HelpTopicCard from './HelpTopicCard';

import helpTopics from './popular-help-topics';

const PopularHelpTopics = ({ profile }) => {
  let role = '';

  switch(profile.role) {
  case 'industry-representative':
    role = 'industryMentor';
    break;

  case 'educator':
  case 'group-admin':
  case 'institution-admin':
    role = 'talentDeveloper';
    break;

  default:
    role = 'regularUser';
    break;
  }

  const topics = helpTopics[role];

  return (
    <>
      <h5 className="dashboard-h5">Popular Help Topics</h5>
      <Row className="mb-5">
        <Col md="6">
          {topics.slice(0, 2).map(topic => <HelpTopicCard key={topic.url} {...topic} />)}
        </Col>
        <Col md="6">
          {topics.slice(-2).map(topic => <HelpTopicCard key={topic.url} {...topic}/>)}
        </Col>
      </Row>
    </>
  );
};

PopularHelpTopics.propTypes = {
  profile: PropTypes.object.isRequired
};

const mapStateToProps = ({ profile: { data: profile } }) => ({ profile });

export default connect(mapStateToProps)(PopularHelpTopics);
