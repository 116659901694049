import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withHooks } from '../../utils/withHooks';
import { uniqueId, parseJSON, stringifyJSON } from '../../utils';

import * as unitActions from '../../redux/actions/skill-builders';
import * as categoryActions from '../../redux/actions/sb-category';

import Loading from '../../components/common/Loading';
import UnitForm from '../../components/skill-builders/admin/UnitForm';
import Validator from '../../validator';
import { toastr } from 'react-redux-toastr';

import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';

class Unit extends Component {
  static propTypes = {
    isSubmitting: PropTypes.bool,
    isUpdating: PropTypes.bool,
    isDeleting: PropTypes.bool,
    unit: PropTypes.object,
    navigate: PropTypes.func.isRequired,
    units: PropTypes.array.isRequired,
    actions: PropTypes.object.isRequired,
    skillbuilderCategory: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      unit: Object.assign({}, this.props.unit),
      descriptions: []
    };

    this.saveUnit = this.saveUnit.bind(this);
    this.updateUnitState = this.updateUnitState.bind(this);
    this.deleteUnit = this.deleteUnit.bind(this);
    this.onAddAcknowledgement = this.onAddAcknowledgement.bind(this);
    this.onDeleteAcknowledgement = this.onDeleteAcknowledgement.bind(this);
    this.setListItem = this.setListItem.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onChangeDesc = this.onChangeDesc.bind(this);
  }

  componentWillMount() {
    const {
      unit, actions
    } = this.props;

    actions.fetchSBcategory();

    let val = [];

    // Transforming unit.description to suit react-select
    if (unit.description !== '') {
      unit.description
        .split('^^/')
        .forEach((item) => {
          val.push(
            item = {
              value: `${item}`,
              label: `${item}`
            }
          );
        });

      this.setState({
        descriptions: val
      });
    }
  }

  componentDidMount() {
    window.onbeforeunload = () => {
      return 'Changes you made might get lost!';
    };
  }

  componentWillReceiveProps(nextProps) {
    const { unit } = this.props;
    const { unit: nextUnit } = nextProps;

    if (! isEqual(unit, nextUnit) && ! isNull(nextUnit)) {
      this.setState({ unit: Object.assign({}, nextUnit) });
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = () => {
      return null;
    };
  }

  handleKeyPress(event) {
    if (event.key === 'Enter')
      return this.saveUnit(event);

    this.updateUnitState(event);
  }

  isValid(field = null) {
    const rules = {
      'title': ['required', 'minLength|10'],
      'category': ['required'],
      'description': ['required'],
      'slug': ['required'],
      'code': ['required'],
      'isavailable': ['required'],
      'acknowledgement.*.value': ['required', 'minLength|3']
    };

    let validate = Validator.createValidator(rules, this.state.unit, field);
    const { isValid, errors } = validate;

    let errorsObj = Object.assign({}, this.state.errors, errors);

    if (field && isUndefined(errors[field])) {
      delete errorsObj[field];
    }

    if (!field && isEmpty(errors)) {
      errorsObj = errors;
    }

    this.setState({ errors: errorsObj });

    return isValid;
  }

  onAddAcknowledgement(event) {
    event.preventDefault();

    let acknowledgement = this.state.unit.acknowledgement;

    acknowledgement.push({ itemId: uniqueId(), value: ''});

    const unit = Object.assign({}, this.state.unit, { acknowledgement });

    this.setState({ unit });
  }

  onDeleteAcknowledgement(event) {
    event.preventDefault();

    const { unit, errors } = this.state;
    const { acknowledgement } = unit;
    const itemId = event.target.closest('.row').id;
    const itemIndex = event.target.closest('.row').dataset.index;

    const newUnit = Object.assign({}, unit, {
      acknowledgement: acknowledgement.filter(item => item.itemId !== itemId)
    });

    const errorObj = Object.assign({}, errors);

    delete errorObj[`acknowledgement[${itemIndex}].value`];

    this.setState({ unit: newUnit, errors: errorObj });
  }

  setUnitProps(name, value) {
    const { unit } = this.state;
    const newUnit = Object.assign({}, unit, { [name]: value });

    this.setState({ unit: newUnit }, () => this.isValid(name));
  }

  setListItem(itemId, name, value) {
    const { unit } = this.state;

    const newUnit = Object.assign({}, unit, {
      acknowledgement: [
        ...unit.acknowledgement.map((item) => {
          if (item.itemId === itemId) {
            return Object.assign({}, item, { value });
          }

          return item;
        })
      ]
    });

    this.setState({ unit: newUnit }, () => this.isValid(name));
  }

  updateUnitState(event) {
    const { name, value } = event.target;
    const itemId = event.target.closest('.row').id;

    if (! name.includes('content')) {
      return this.setUnitProps(name, value);
    }

    return this.setListItem(itemId, name, value);
  }

  handleResponse(message, redirectUrl) {
    const unit = {
      title: '',
      description: '',
      category: '',
      slug: '',
      code: '',
      reflectone: '',
      reflecttwo: '',
      reflectthree: '',
      reflectfour: '',
      acknowledgement: []
    };

    toastr.success(message);
    this.setState({ unit });

    if (redirectUrl) this.props.navigate(redirectUrl);
  }

  formatData() {
    const { unit } = this.state;
    const { acknowledgement } = unit;

    return Object.assign({}, unit, {
      acknowledgement: stringifyJSON(acknowledgement)
    });
  }

  saveUnit(event) {
    event.preventDefault();

    if (! this.isValid()) return;

    const unit = this.formatData();
    const { units, actions } = this.props;
    const redirectUrl = '/admin/skill-builders';

    if (unit.id) {
      const unitIndex = units.findIndex(unitItem => unitItem.id === unit.id);

      return actions.updateUnit(unit, unitIndex)
        .then(() => this.handleResponse('Unit updated.', redirectUrl));
    }

    actions.saveUnit(unit)
      .then(() => this.handleResponse('New unit created.', redirectUrl));
  }

  deleteUnit(event) {
    event.preventDefault();

    const redirectUrl = '/admin/skill-builders';

    this.props.actions.deleteUnit(this.state.unit.id)
      .then(() => this.handleResponse('Unit deleted.', redirectUrl));
  }

  onChangeDesc(val) {
    if (val.length > 0 &&
      !Validator.createValidator({
        'value': ['required']
      }, val[val.length - 1], null).isValid
    )
      return;

    const formattedVal = val.map((item) => {
      if(item.value.includes('Create option ')){
        const splat = item.value.split('Create option ');
        return Object.assign({}, item, {
          value: splat[1].replace(/"/g, ''),
          label: splat[1].replace(/"/g, '')
        });
      }
      return item;
    });


    // Transforming val to suit cp-apigility
    const values = map(formattedVal, 'value');
    const descString = values.join('^^/');// joined using \^^/ to allow comma seperated options.

    this.setState({
      unit: Object.assign({}, this.state.unit, {description: descString}),
      descriptions: formattedVal
    }, this.isValid);
  }

  render() {
    const { unit, errors, descriptions } = this.state;
    const { isSubmitting, isUpdating, isDeleting, skillbuilderCategory } = this.props;
    const { isRequesting, data: categories } = skillbuilderCategory;

    if(isRequesting){
      return(
        <div className="loading-container">
          <Loading/>
        </div>
      );
    }

    return (
      <UnitForm
        categories={categories}
        unit={unit}
        errors={errors}
        isSubmitting={isSubmitting}
        isUpdating={isUpdating}
        isDeleting={isDeleting}
        onChange={this.updateUnitState}
        onSave={this.saveUnit}
        onDelete={this.deleteUnit}
        onDeleteAcknowledgement={this.onDeleteAcknowledgement}
        onAddAcknowledgement={this.onAddAcknowledgement}
        handleKeyPress={this.handleKeyPress}
        onChangeDesc={this.onChangeDesc}
        descriptions={descriptions}/>
    );
  }
}

const getAcknowledgement = (acknowledgement) => {
  return acknowledgement === '' ? [] : parseJSON(acknowledgement);
};

const getUnitById = (unitId, units) => {
  const unit = units.filter(unit => unit.id == unitId);

  if (unit && unit.length !== 0) {
    return Object.assign({}, unit[0], {
      acknowledgement: getAcknowledgement(unit[0].acknowledgement)
    });
  }

  return null;
};

const mapStateToProps = (state, ownProps) => {
  let unit = {
    title: '',
    description: '',
    category: '',
    slug: '',
    code: '',
    isavailable: '',
    reflectone: '',
    reflecttwo: '',
    reflectthree: '',
    reflectfour: '',
    acknowledgement: []
  };

  const { data, isSubmitting, isUpdating, isDeleting } = state.skillBuilders;

  const unitId = ownProps.params.id;

  if (unitId && data.length > 0) unit = getUnitById(unitId, data);

  return {
    skillbuilderCategory: state.skillbuilderCategory,
    unit,
    units: data,
    isDeleting: isDeleting.status,
    isUpdating: isUpdating.status,
    isSubmitting
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, unitActions, categoryActions);
  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(Unit));
