import PropTypes from 'prop-types';
import React from 'react';
import TextArea from '../common/TextArea';
const JobUnitNotesForm = ({
  errors,
  noteForm,
  onChangeNoteForm,
  isSubmitting,
  onNotePost,
  isJobOwner
}) => {
  return (
    <div>
      <form className="notes-form">
        <div className="form-group">
          <label className="form-control-label">Notes</label>
          <TextArea
            className="form-control input-styles-owht"
            name="text"
            rows="6"
            cols=""
            disabled={!isJobOwner}
            error={errors.text}
            onChange={onChangeNoteForm}
            value={noteForm.text} />
        </div>
        {isJobOwner &&
          <div className="form-group">
            <button
              type="button"
              className="float-right btn btn-primary"
              name="button"
              onClick={onNotePost}>
              {isSubmitting ? 'Adding ...' : 'Add Note'}
            </button>
          </div>}
      </form>
    </div>
  );
};

JobUnitNotesForm.propTypes = {
  noteForm: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isJobOwner: PropTypes.bool.isRequired,
  onNotePost: PropTypes.func.isRequired,
  onChangeNoteForm: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

export default JobUnitNotesForm;

