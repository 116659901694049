import React from 'react';

import PropTypes from 'prop-types';
import PendingFeedbackHeader from './table-columns/PendingFeedbackHeader';
import RequestedFeedbackHeader from './RequestedFeedbackHeader';
import PendingFeedbackComponent from './PendingFeedbackComponent';
import RequestedFeedbackComponent from './RequestedFeedbackComponent';

const FeedbackTableComponent = (props) => {

  const getHeaderData = {
    pending : <PendingFeedbackHeader onSortTable={props.onSortTable}/>,          
    requested  : <RequestedFeedbackHeader onSortTable={props.onSortTable} />,   
  };

  const Map = {
    'pending': PendingFeedbackComponent,
    'requested': RequestedFeedbackComponent
  };

  const Tagname = Map[props.status];

  return(
    <div style={{ position: 'relative' }}>
      <table className="table table-responsive-lg mb-0">
        <thead>
          <tr className="cp-table-header">
            {getHeaderData[props.status]}
          </tr>  
        </thead>
        <tbody>
          <Tagname {...props}/>
        </tbody>      
      </table>
    </div>
  ); 
};

FeedbackTableComponent.propTypes = {
  status: PropTypes.string.isRequired,
  onSortTable: PropTypes.func.isRequired,
};

export default FeedbackTableComponent;
