import PropTypes from 'prop-types';
import React from 'react';

import Button from '../../common/Button';
import TextInput from '../../common/TextInput';
import QuillEditor from '../../common/QuillEditor';
import { parseUrlString } from '../../../utils';
import Parser from 'html-react-parser';

const modules = {
  toolbar: {
    container: [
      [{ 'align': [] }],
      ['bold', 'italic', 'underline'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      ['link'],
    ],
    handlers: {
      // adds custom handlers https://quilljs.com/docs/modules/toolbar/
      link: function(value) {
        if (value) {
          let range = this.quill.getSelection();

          if (range == null || range.length == 0) {
            return;
          }

          this.quill.theme.tooltip.edit('link', '');

          const toolTipSave = this.quill.theme.tooltip.save;
          const toolTipElement = this.quill.theme.tooltip.root;
          toolTipElement.classList.remove('ql-error');

          // overwrite save link functionality
          this.quill.theme.tooltip.save = function() {
            let url = this.textbox.value;
            toolTipElement.classList.remove('ql-error');
            if(/(https?:\/\/)?(www\.)?[^\s.]+\.[^\s]{2,}/g.test(url)) {
              toolTipSave.call(this);
            } else {
              toolTipElement.classList.add('ql-error');
            }
          };

        } else {
          this.quill.format('link', false);
        }
      }
    }
  },
  clipboard: {
    matchVisual: false,
  },
};

const SummaryForm = ({
  errors,
  profile,
  onChange,
  onChangeSummary,
  onSave,
  onCancel,
  isUpdating,
  videoPreview,
  iframePreview,
  onRemoveVideo
}) => {
  const videoPreviewPresent = videoPreview !== '';
  const iframePreviewPresent = iframePreview !== '';
  const urlError = errors.video ? errors.video :
    profile.video !== ''
      && !videoPreviewPresent &&
        !iframePreviewPresent ? 'Video URL or embed code is not valid': '';

  return (
    <div className="profile-item__card">
      <div className="row">
        <div className="col-lg-12">
          <div style={{marginBottom: 10}}>
            <QuillEditor
              modules={modules}
              value={profile.summary}
              placeholder="About Me"
              onChange={onChangeSummary} />
            {errors.summary && (
              <span className="text-danger" style={{ marginLeft: 4, fontSize: 12 }}>
                {`${errors.summary}`}
              </span>
            )}
          </div>
          <TextInput
            name="video"
            label="Video Resume, Demo Reel, or Professional Highlights:"
            placeholder="Enter your YouTube or Vimeo video URL or embed code"
            value={profile.video}
            onChange={onChange}/>
          {
            urlError !== '' &&
              <div className="form-control-feedback text-danger mb-3">{urlError}</div>

          }
          {
            (videoPreviewPresent || iframePreviewPresent) &&
              <button
                className="btn btn-danger mb-1"
                onClick={onRemoveVideo}>
                Remove Video
              </button>
          }


          {
            videoPreviewPresent &&
              <div className="embed-responsive embed-responsive-16by9 mt-3">
                <iframe className="embed-responsive-item"
                  src={parseUrlString(videoPreview).url}
                  allowFullScreen/>
              </div>
          }
          {
            iframePreviewPresent &&
              <div className="embed-responsive embed-responsive-16by9 mt-3">
                {Parser(iframePreview)}
              </div>
          }
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="action-bar text-right">
            <Button
              type="button"
              onClick={onCancel}
              buttonClass="btn-secondary"
              buttonText="Cancel"/>
            <Button
              type="submit"
              onClick={onSave}
              disabled={isUpdating || (urlError !== '')}
              buttonClass="btn-primary"
              buttonText={isUpdating ? 'Saving...' : 'Save'}/>
          </div>
        </div>
      </div>
    </div>
  );
};

SummaryForm.propTypes = {
  videoPreview: PropTypes.string,
  iframePreview: PropTypes.string,
  onRemoveVideo: PropTypes.func,
  errors: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeSummary: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired
};

export default SummaryForm;
