import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

import img_mediaf1 from '../../assets/images/img-CP-case-study-4.jpg';
import img_mediaf2 from '../../assets/images/img-CP-partner-press-release-3.jpg';

import img_media0 from '../../assets/images/img-CP-press-release-2.jpg';
import img_media2 from '../../assets/images/img-CP-partner-press-release.jpg';
import img_media3 from '../../assets/images/img-CP-press-release-3.jpg';
import img_media4 from '../../assets/images/img-CP-press-release-5.jpg';

import img_media1_0 from '../../assets/images/img-CP-partner-video.jpg';
import img_media1_1 from '../../assets/images/img-CP-white-paper.jpg';
import img_media1_2 from '../../assets/images/img-CP-white-paper-2.jpg';

import img_media2_0 from '../../assets/images/img-CP-case-study-3.jpg';
import img_media2_1 from '../../assets/images/img-CP-case-study.jpg';
import img_media2_2 from '../../assets/images/img-CP-case-study-2.jpg';


class Media extends Component {
  constructor(props) {
    super(props);
    this.state = {
      featured: [
        {
          id: 0,
          link: 'https://www.prnewswire.com/news-releases/careerprepped-unveils-transformative-institutional-service-to-support-student-career-success-in-an-ai-driven-job-market-302307660.html?',
          img: img_media4,
          title:'CareerPrepped Unveils Transformative Institutional Service to Support Student Career Success in an AI-Driven Job Market',
          tag: 'Press Release'
        },
        {
          id: 1,
          link: 'https://www.prnewswire.com/news-releases/the-ohio-state-board-of-career-colleges-and-schools-facilitates-statewide-access-to-careerprepped-301959520.html',
          img: img_media3,
          title:'CareerPrepped and the Ohio State Board of Career Colleges and Schools Collaborate to Advance Skills-Based Education and Hiring in Ohio',
          tag: 'Press Release'
        },
        {
          id: 2,
          link: 'https://www.prnewswire.com/news-releases/careerprepped-propels-commonwealth-technical-institute-to-achieve-landmark-essential-workforce-skills-programmatic-certification-301862582.html',
          img: img_media0,
          title:'CareerPrepped® Propels Commonwealth Technical Institute to Achieve Landmark Essential Workforce Skills Programmatic Certification',
          tag: 'Press Release'
        },
      ],
      media: [
        {
          id: 0,
          link: 'https://www.ctelearn.org/downloads/CTE_Learn_CareerPrepped_Paper.pdf',
          img: img_mediaf1,
          title:'ACTE\'s Online Learning Network, CTE Learn, Offers an Innovative Solution for Statewide Adoption to Enable Skills-Based Hiring at Scale',
          tag: 'Partner Paper'
        },
        {
          id: 1,
          link: 'https://www.youtube.com/watch?v=FGmt65JmATA',
          img: img_media1_0,
          title:'CareerPrepped Adopted by the Association for Career and Technical Education (ACTE) as CTE\'s Virtual Community',
          tag: 'Partner Video'
        },
        {
          id: 2,
          link: 'https://www.accsc.org/wp-content/uploads/2023/09/ACCSC-Press-Release-EWS-8822.pdf',
          img: img_media2,
          title:'ACCSC Launches Essential Workforce Skills (EWS) Programmatic Certification Supported by CareerPrepped',
          tag: 'Partner Press Release'
        },
        {
          id: 3,
          link: 'https://media.maxknowledge.com/careerprepped/CareerPrepped-Real-World-Examples-of-Essential-Skills-Applied-in-Work-Relevant-Context.pdf',
          img: img_mediaf2,
          title:'Examples of Essential Skills Applied in Work-Relevant Scenarios',
          tag: 'Essential Skills Resource'
        },
        {
          id: 4,
          link: 'https://media.maxknowledge.com/careerprepped/CareerPrepped-Whitepaper-The-21st-Century-Career-Why-Perpetual-Career-Preparation-is-Necessary-to-Survive-and-Thrive-in-Todays-Economy.pdf',
          img: img_media1_1,
          title:'The 21st Century Career',
          tag: 'White Paper'
        },
        {
          id: 5,
          link: 'https://media.maxknowledge.com/careerprepped/CareerPrepped-Whitepaper-Open-Badges-for-Empowerment_How-Open-Badges-Can-Be-Used-to-Recognize-and-Communicate-Hidden-Human-Capital.pdf',
          img: img_media1_2,
          title:'Open Badges for Empowerment',
          tag: 'White Paper'
        },
        {
          id: 6,
          link: 'https://www.ctelearn.org/careerprepped/downloads/BHS_Case_Study_Spotlight.pdf',
          img: img_media2_0,
          title:'CareerPrepped Adopted by Beverly High School in Massachusetts',
          tag: 'Case Study Spotlight'
        },
        {
          id: 7,
          link: 'https://www.essentialworkforceskills.org/images/CTI_Case_Study_Spotlight.pdf',
          img: img_media2_1,
          title:'CareerPrepped Adopted by the Commonwealth Technical Institute',
          tag: 'Case Study Spotlight'
        },
        {
          id: 8,
          link: 'https://www.ctelearn.org/careerprepped/downloads/GIRESD_Case_Study_Spotlight.pdf',
          img: img_media2_2,
          title:'CareerPrepped Adopted by Gratiot-Isabella Regional Education Service District',
          tag: 'Case Study Spotlight'
        },
      ]
    };
  }
  componentWillMount() {
    document.body.classList.add('home-media');
  }

  componentWillUnmount() {
    document.body.classList.remove('home-media');
  }

  render() {
    const img_logo_cp = require('../../assets/images/CP_logo.png');
    const img_dont_squish = require('../../assets/images/img-dont-squish.png');
    const img_dont_rotate = require('../../assets/images/img-dont-rotate.png');
    const img_dont_skew = require('../../assets/images/img-dont-skew.png');
    const img_do_bg = require('../../assets/images/img-do-bg.png');
    const img_dont_bg = require('../../assets/images/img-dont-bg.png');
    const img_logo_wm = require('../../assets/images/img-icon-wordmark.png');
    const img_logo_solo = require('../../assets/images/img-icon-solo.png');

    const items = this.state.media.map((item,key) => (
      <div className="col-lg-4 card-holder px-lg-1" key={key}>
        <a target="_blank" href={item.link} className="card">
          <img src={item.img} className="card-img-top img-fluid" alt={item.title} />
          <div className="card-body">
            <div className="tag">{item.tag}</div>
            <p className="card-text">{item.title}</p>
          </div>
        </a>
      </div>
    ));
    const featured = this.state.featured.map((item,key) => (
      <div className="col-lg-4 card-holder px-lg-1" key={key}>
        <a target="_blank" href={item.link} className="card">
          <img src={item.img} className="card-img-top img-fluid" alt={item.title} />
          <div className="card-body">
            <div className="tag">{item.tag}</div>
            <p className="card-text">{item.title}</p>
          </div>
        </a>
      </div>
    ));

    return (
      <div className="interior info">
        <Helmet title="Media"/>
        <div className="container">
          <div className="row">
            <div className="content">
              <div className="info-title">
                <h2 className="media-heading">CareerPrepped Media</h2>
                <article>
                  <h3>Media Contact</h3>
                  <div>
                    <div>Robert Pearl Starks</div>
                    <div><a href="mailto:roberts@maxknowledge.com" target="_blank">roberts@maxknowledge.com</a></div>
                  </div>
                </article>
                <article>
                  <h3>Featured Media</h3>
                  <div className="row mx-lg-n1 mb-3">
                    {featured}
                  </div>
                  <div className="row mx-lg-n1 mb-3">
                    {items}
                  </div>
                </article>
                <article>
                  <h3>CareerPrepped Branding Guidelines</h3>
                  <div className="row">
                    <div className="col-lg-12">
                      <p>Branding guidelines for institutions wanting to create their own marketing collateral using the CareerPrepped logo. <br /><a href="https://media.maxknowledge.com/careerprepped-marketing-collateral/CareerPrepped_Logos.zip" target="_blank">Download CareerPrepped Logo Package</a></p>
                    </div>
                    <div className="col-lg-6">
                      <h4>Our Logo</h4>
                      <p>Our logo should be treated with care and should be implemented as found in the branding guide. Please follow the examples as described below. In certain cases, there might be slight modifications that may not follow the rules. These modifications and allowances have been described below.</p>
                    </div>
                    <div className="col-lg-6">
                      <div className="logo-cont">
                        <img src={img_logo_cp} className="img-fluid" alt="" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h4>Our Color Structure</h4>
                      <p>Our branding uses 3 main colors to define its identiy. All logos and icons should not stray away from these colors.</p>
                    </div>
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="swatch-container sw-navy">
                            <div className="swatch-content">
                              <div className="title">CP Navy</div>
                              <div><b>HEX</b>: #041E41</div>
                              <div><b>C</b>:100% <b>M</b>:87% <b>Y</b>:42% <b>K</b>:51%</div>
                              <div><b>R</b>:4 <b>G</b>:30 <b>B</b>:65</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="swatch-container sw-orange">
                            <div className="swatch-content">
                              <div className="title">CP Orange</div>
                              <div><b>HEX</b>: #F2673D</div>
                              <div><b>C</b>:0% <b>M</b>:74% <b>Y</b>:83% <b>K</b>:0%</div>
                              <div><b>R</b>:242 <b>G</b>:103 <b>B</b>:61</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="swatch-container sw-ltblue">
                            <div className="swatch-content">
                              <div className="title">CP Light Blue</div>
                              <div><b>HEX</b>: #8BBBE3</div>
                              <div><b>C</b>:43% <b>M</b>:15% <b>Y</b>:1% <b>K</b>:0%</div>
                              <div><b>R</b>:139 <b>G</b>:187 <b>B</b>:227</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
                <hr />
                <article className="dnd">
                  <div className="row">
                    <div className="col-lg-6 mb-3">
                      <h4 className="text-center">Do's</h4>
                      <div className="dnd-content">
                        <div className="dnd-item">
                          Always use our logo with the <b>icon and wordmark</b> together.
                          <div className="mt-3 good"><img src={img_logo_wm} className="img-fluid" alt="" /></div>
                        </div>

                        <div className="dnd-item">
                          In the event where horizontal space is limited, the <b>CareerPrepped icon</b> may be used as a stand-alone mark.
                          <div className="mt-3 good"><img src={img_logo_solo} className="img-fluid" alt="" /></div>
                        </div>
                        <div className="dnd-item">
                          Always use our colored logo on the following backgrounds.
                          <div className="mt-3">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="swatch blue">
                                  <div className="hex">HEX: #041E41</div>
                                  <div><b>C</b>:100% <b>M</b>:87% <b>Y</b>:42% <b>K</b>:51%</div>
                                  <div><b>R</b>:4 <b>G</b>:30 <b>B</b>:65</div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="swatch white">
                                  <div className="hex"><b>HEX</b>: #FFFFFF</div>
                                  <div><b>C</b>:0% <b>M</b>:0% <b>Y</b>:0% <b>K</b>:0%</div>
                                  <div><b>R</b>:255 <b>G</b>:255 <b>B</b>:255</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <p><b>NOTE</b>: In the event where those background colors cannot be used, use the appropriate black and white logos provided in the branding package i.e. white logo over dark backgrounds.</p>
                        <p className="dnd-item">Always spell our name as <b>CareerPrepped</b>. The “<b>C</b>” and “<b>P</b>” of the wordmark must be capitalized.</p>

                      </div>

                    </div>
                    <div className="col-lg-6">
                      <h4 className="text-center">Dont's</h4>
                      <div className="dnd-content">
                        <div className="dnd-item">
                          Do not stretch, skew, or rotate the <b>CareerPrepped logo</b>. This includes both icon and wordmark.
                          <div className="mt-3 bad"><img src={img_dont_squish} className="img-fluid" alt="" /></div>
                          <div className="mt-3 bad"><img src={img_dont_rotate} className="img-fluid" alt="" /></div>
                          <div className="mt-3 bad"><img src={img_dont_skew} className="img-fluid" alt="" /></div>
                        </div>
                        <div className="dnd-item">
                          Do not overlay the logo on colored backgrounds or images where the logo becomes unreadable. If placed over an image, make sure to treat the image accordingly.

                          <div className="mt-3 bad"><img src={img_dont_bg} className="img-fluid" alt="" /></div>
                          <div className="mt-3 good"><img src={img_do_bg} className="img-fluid" alt="" /></div>

                        </div>
                      </div>
                    </div>
                  </div>
                </article>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Media;
