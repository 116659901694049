import PropTypes from 'prop-types';
import React from 'react';
import Linkify from 'react-linkify';
import classNames from 'classnames';

const Alert = ({ alertHeading, alertMessage, dismissAlert, displayAlertType }) => {
  const alertClassNames = classNames([
    'alert',
    'alert-dismissible',
    `alert-${displayAlertType}`
  ]);

  return (
    <div className="col-12">
      <div className={alertClassNames} role="alert">
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
          onClick={dismissAlert}>
          <span aria-hidden="true">&times;</span>
        </button>
        <h6 className="alert-heading">{alertHeading}</h6>
        <Linkify>{alertMessage}</Linkify>
      </div>
    </div>
  );
};

Alert.propTypes = {
  alertHeading: PropTypes.string.isRequired,
  alertMessage: PropTypes.string.isRequired,
  dismissAlert: PropTypes.func.isRequired,
  displayAlertType: PropTypes.string.isRequired
};

export default Alert;
