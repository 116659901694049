import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { includes } from 'lodash';
import {
  SETTINGS_DATE_FORMAT,
  FEEDBACK_REQUESTED_DATE_FORMAT,
} from '../../../constants';

import { SimpleToolTipWrapper } from '../../common/ToolTipWrapper';
import { useSelector } from 'react-redux';

const RequestedBaseRequestedOn = (props) => {
  const rowDetails = props.rowData;
  const currentUser = useSelector(({profile}) => profile.data);
  let showTooltip = false;
  if(!includes([rowDetails.requestForUserId, currentUser.id], rowDetails.requesterId)){
    showTooltip = true; 
  }

  return (
    <td scope="row">
      {`${moment(
        rowDetails?.feedbackRequestDate?.date,
        SETTINGS_DATE_FORMAT
      ).format(FEEDBACK_REQUESTED_DATE_FORMAT)}`}
      {showTooltip && (
        <SimpleToolTipWrapper
          id={`sortable-table-admin-request-id-${rowDetails.feedbackRequestId}`}
          toolTipContent={
            <div className="inside-tooltip">
              {`Initiated by ${rowDetails.requesterName.split(' ')[0]}`}
            </div>
          }>
          <i className={'fa fa-info-circle infoIcon'} />
        </SimpleToolTipWrapper>
      )}
    </td>
  );
};

RequestedBaseRequestedOn.propTypes = {
  rowData: PropTypes.object.isRequired,
};

export default RequestedBaseRequestedOn;