import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withHooks } from '../../utils/withHooks';

import * as jobBoardActions from '../../redux/actions/job-boards';

import PropTypes from 'prop-types';
import InfiniteScroller from '../common/infinite-scroll/InfiniteScroller';
import JobBoardComponent from './JobsBoardComponent';

class JobBoard extends Component {
  static propTypes = {
    params: PropTypes.object,
    currentUserId: PropTypes.string.isRequired,
    actions: PropTypes.object.isRequired,
    paginationData: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.callAPI = this.callAPI.bind(this);
  }

  componentWillMount(){
    this.props.actions.resetJobBoard();
  }

  callAPI(pageNo) {
    const { actions, currentUserId, params } = this.props;

    const userId = params.userId ?? currentUserId;

    return actions.fetchAllJobs(userId, pageNo);
  }

  render() {
    const { page_count, page } = this.props.paginationData;
    return(
      <InfiniteScroller
        component={JobBoardComponent}
        callAPI={this.callAPI}
        page_count={page_count}
        page={page}
        isLoaderInternal
        shouldCallAPIInitially/>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUserId: state.auth.data.currentUser.id,
    paginationData: state.jobBoards.paginationData,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, jobBoardActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(JobBoard));
