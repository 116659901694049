import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import classNames from 'classnames';

import { withHooks } from '../../../utils/withHooks';

import * as interviewActions from '../../../redux/actions/interview';
import * as componentActions from '../../../redux/actions/components';

import { Helmet } from 'react-helmet';
import Parser from 'html-react-parser';

import Loading from '../../../components/common/Loading';
import VideoComments from './VideoComments';
import ShareVideoModal from './ShareVideoModal';

class VideoDetails extends Component {
  static propTypes = {
    params: PropTypes.object,
    modal: PropTypes.string,
    actions: PropTypes.object.isRequired,
    unitVideo: PropTypes.object.isRequired,
    unitVideoComments: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      showFullDesc: false
    };

    this.onLikeVideo = this.onLikeVideo.bind(this);
    this.onShareToCp = this.onShareToCp.bind(this);
    this.onToggleFullDesc = this.onToggleFullDesc.bind(this);
  }

  componentDidMount(){
    const { slug } = this.props.params;

    if(slug){
      this.props.actions.fetchUnitInterviewPrepVideo(slug);
      this.props.actions.getUnitVideoComments(slug);
    }
  }

  onLikeVideo(e){
    e.preventDefault();

    const { actions, unitVideo } = this.props;
    const { liked, id } = unitVideo.videoDetails;

    if(liked){
      return actions.unlikeVideo(id);
    }

    const data = {
      'resource': id
    };

    actions.onLikeVideo(data);
  }

  onShareToCp(){
    const { actions, unitVideo } = this.props;
    actions.openModal(`share-video-details-to-cp-${unitVideo.videoDetails.id}`);
  }

  onToggleFullDesc(){
    const { showFullDesc } = this.state;

    this.setState({ showFullDesc: !showFullDesc });
  }

  render() {
    const { showFullDesc } = this.state;
    const { params, unitVideo, unitVideoComments, modal } = this.props;
    const { isRequestingDetails, videoDetails } = unitVideo;
    const { comments } = unitVideoComments;
    const isShareModalOpen = modal && modal === `share-video-details-to-cp-${unitVideo.videoDetails.id}`;

    const hasCompletedFetching = !isRequestingDetails && Object.keys(videoDetails).length > 0&&
      videoDetails.id === params.slug;

    if (!hasCompletedFetching) {
      return (
        <div className="loading-container">
          <Loading/>
        </div>
      );
    }

    const { like_count, title, url, duration, description, liked, comment_count } = videoDetails;
    const showMoreCondition = description.length > 380;
    const videoDesc = showMoreCondition && !showFullDesc ?
      description.slice(0,380) : description;

    return (
      <div>
        <Helmet title={`Interview Prep Videos: ${title}`}/>
        <div className="content targeted-resume" style={{marginTop: 30}}>
          <div id="prep-videos" className="row">
            <div className="col-lg-12">
              <div className="video-link">
                <iframe
                  width="560"
                  height="315"
                  src={`${url}?rel=0&amp;showinfo=0`}
                  frameBorder="0"
                  allowFullScreen/>
              </div>
              <div className="video-details">
                <h5 className="video-title mt-3">{title}</h5>
                <div className="d-flex space-between align-items-center pb-2">
                  <span>Duration: {duration}</span>
                  <div className="portfolio-stats m-0 p-0 no-border">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a
                          onClick={this.onLikeVideo}
                          className="clickable"

                          title={liked ? 'UNLIKE': 'LIKE'}>
                          <i className={classNames('fa fa-heart', {
                            'liked-heart': liked
                          })} aria-hidden="true"/>
                        </a>{like_count}
                      </li>
                      <li className="list-inline-item">
                        <i className="fa fa-comment" aria-hidden="true"/>{comment_count}
                      </li>
                      <li className="list-inline-item mr-0">
                        <div className="d-flex align-items-center clickable">
                          <a
                            onClick={this.onShareToCp}
                            title="SHARE">
                            <i className="fa fa-share pr-2" aria-hidden="true"/>
                            <span>SHARE</span>
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <hr className="mt-1" style={{clear:'both'}}/>
                <div>
                  {Parser(videoDesc)}

                  {showMoreCondition &&
                    <a
                      style={{
                        fontSize: 14,
                        fontWeight: '500'
                      }}
                      className="clickable orange"
                      onClick={this.onToggleFullDesc}>
                      {showMoreCondition && !showFullDesc ? 'SHOW MORE': 'SHOW LESS'}
                    </a>}
                </div>
              </div>
              <hr />
              <VideoComments
                videoDetails={videoDetails}
                comments={comments}/>
            </div>
          </div>
        </div>
        {
          isShareModalOpen &&
            <ShareVideoModal
              isOpen={isShareModalOpen}
              videoData={videoDetails}/>
        }
      </div>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    modal: state.components.modal,
    unitVideo: state.interview.unitVideo,
    unitVideoComments: state.interview.unitVideoComments,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, interviewActions, componentActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(withHooks(VideoDetails));
