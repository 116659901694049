import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  userNameEditIcon: {
    fontSize: 16,
    cursor: 'pointer',
    marginLeft: 10,
  }
};

const SkillEditCard = React.forwardRef(({
  skill,
  onChangeTitle,
  onDelete
}, ref) => {
  return (
    <div>
      <span className="create-resume-title">
        <input
          id={skill.id}
          type="text"
          style={{color: '#373a3c'}}
          value={skill.name.replace('^^/', ',')}
          placeholder="Skill name"
          className="header-edit-textbox skill-edit-textbox"
          onChange={onChangeTitle}/>
      </span>
      <span className="skill-edit-action-section">
        <i
          style={styles.userNameEditIcon}
          onClick={onDelete(skill.id)}
          className="fa fa-trash text-danger"/>

        <i ref={ref} style={styles.userNameEditIcon} className="fa fa-arrows mover" />
      </span>
    </div>
  );
});

SkillEditCard.propTypes = {
  skill: PropTypes.object,
  onChangeTitle: PropTypes.func.isRequired,
  onDelete: PropTypes.func
};

SkillEditCard.displayName = 'SkillEditCard';

export default SkillEditCard;