import { isUndefined } from 'lodash';

import initialState from './initialState';

import {
  RESET_ORG_REDUCER,
  GET_ALL_ORG_REQUEST,
  GET_ALL_ORG_SUCCESS,
  GET_ALL_ORG_FAILURE,
  SET_SELECTED_ORGANIZATION,
  CREATE_ORGANIZATION_REQUEST,
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_FAILURE,
  UPDATE_ORGANIZATION_REQUEST,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAILURE,
  SEARCH_ORGANIZATIONS_REQUEST,
  SEARCH_ORGANIZATIONS_SUCCESS,
  SEARCH_ORGANIZATIONS_FAILURE,
  ONCHANGE_ORGANIZATIONS_SEARCH_QUERY
} from '../../constants';

const updateOrganization = (state, action) => {
  const { data, isUpdating } = state;
  const indexOfOrg = data.findIndex(item => item.id === isUpdating.id);

  return [
    ...data.slice(0, indexOfOrg),
    action.data,
    ...data.slice(indexOfOrg + 1),
  ];
};

const allOrganizations = {
  id: 'all-organizations',
  name: 'All Organizations'
};

export default function reducer(state = initialState.organizations, action) {
  switch (action.type) {
  case RESET_ORG_REDUCER:
    return Object.assign({}, initialState.organizations);

  case GET_ALL_ORG_REQUEST:
    return Object.assign({}, state, {
      isRequesting: true,
      isInitialRequest: action.isInitialRequest
    });

  case GET_ALL_ORG_SUCCESS:
    return Object.assign({}, state, {
      data: state.data.length === 0 &&
        action.data._embedded.institution.length === 1 ?
        action.data._embedded.institution : state.isInitialRequest ?
          [allOrganizations, ...action.data._embedded.institution] :
          [...state.data, ...action.data._embedded.institution],

      paginationData: {
        page: isUndefined(action.data.page)
          ? initialState.organizations.paginationData.page
          : action.data.page,
        page_count: isUndefined(action.data.page_count)
          ? initialState.organizations.paginationData.page_count
          : action.data.page_count,
        total_items: action.data.total_items,
      },

      selectedOrganization: state.data.length === 0 &&
        action.data._embedded.institution.length === 1 ?
        action.data._embedded.institution[0] : allOrganizations,

      isRequesting: false,
      isInitialRequest: false
    });

  case GET_ALL_ORG_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
      isInitialRequest: false
    });

  case SEARCH_ORGANIZATIONS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case SEARCH_ORGANIZATIONS_SUCCESS:
    return Object.assign({}, state, {
      data: action.data._embedded.institution,
      paginationData: {
        page: isUndefined(action.data.page)
          ? initialState.organization.paginationData.page
          : action.data.page,
        page_count: isUndefined(action.data.page_count)
          ? initialState.organization.paginationData.page_count
          : action.data.page_count,
        total_items: action.data.total_items,
      },
      isRequesting: false,
    });

  case SEARCH_ORGANIZATIONS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false
    });

  case CREATE_ORGANIZATION_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: true,
    });

  case CREATE_ORGANIZATION_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data, Object.assign({}, action.data)],
      isSubmitting: false,
    });

  case CREATE_ORGANIZATION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case UPDATE_ORGANIZATION_REQUEST:
    return Object.assign({}, state, {
      isUpdating: {
        status: true,
        id: action.id,
      },
    });

  case UPDATE_ORGANIZATION_SUCCESS:
    return Object.assign({}, state, {
      data: updateOrganization(state, action),
      isUpdating: {
        status: false,
        id: null,
      },
    });

  case UPDATE_ORGANIZATION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: {
        status: false,
        id: null,
      },
    });

  case ONCHANGE_ORGANIZATIONS_SEARCH_QUERY:
    return Object.assign({}, state, {
      filters: {
        ...state.filters,
        searchQuery: action.searchQuery,
      },
    });

  case SET_SELECTED_ORGANIZATION:
    return Object.assign({}, state, {
      selectedOrganization: action.selectedOrganization
    });

  default:
    return state;
  }
}
