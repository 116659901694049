import React, { Component } from 'react';

import {
  Button,
  Modal,
  ModalBody,
  Row,
  Col,
  Collapse,
  Input,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

// import SignInWithLinkedIn from '../sign-in-with-linkedIn/SignInWithLinkedIn';
import validator from '../../../validator';
import * as authenticationActions from '../../../redux/actions/authentication';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CloseIcon from '../../../assets/images/close.svg';
import CPLogo from '../../../assets/images/CP_logo.svg';

class CreatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: false,
      isPasswordVisible: false,
      isConfirmPasswordVisible: false,
      passwordType: 'password',
      password: null,
      confirmpassword: null,
      createPasswordModal: false,
      confirmType: 'password',
      isOpen: this.props.isOpen,
      errors: [],
      isSubmitting: false,
      submitBtnText: 'Next',
    };
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.toggleEye = this.toggleEye.bind(this);
    this.toggleConfirmPasswordVisibility = this.toggleConfirmPasswordVisibility.bind(
      this
    );
    this.togglePasswordModal = this.togglePasswordModal.bind(this);
  }

  componentDidMount = () => {
    const { actions } = this.props;
    if (this.props.isAuthenticated) {
      actions.userLogout();
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validatePasswordForm = () => {
    let { password } = this.state;
    if ( isEmpty(password)) {
      password = null;
    }
    const rules = {
      password: ['required', 'minLength|8'],
      confirmpassword: ['required', `confirmPasswordMatch|${password}`]
    };
    const validate = validator.createValidator(rules, this.state);
    return validate;
  }

  submitData = () => {
    const { isProvideFeedback } = this.props;
    const { password, confirmpassword } = this.state;

    const { errors, isValid } = this.validatePasswordForm();

    if (!isValid) {
      this.setState({errors: errors});
      return;
    } else {
      this.setState({
        isSubmitting: true,
        submitBtnText: 'Creating...',
      });
      if(!isProvideFeedback){
        this.props.proceed('relationModal', {
          password,
          confirmpassword,
        });
      }else{
        this.props.proceed('relationModal',{password});
      }
    }
  };

  toggleCollapse() {
    this.setState((state) => {
      return {
        isCollapsed: !state.isCollapsed,
      };
    });
  }

  toggleEye() {
    this.setState((state) => {
      return {
        passwordType: state.passwordType === 'password' ? 'text' : 'password',
        isPasswordVisible: !state.isPasswordVisible,
      };
    });
  }

  toggleConfirmPasswordVisibility() {
    this.setState((state) => {
      return {
        confirmType: state.confirmType === 'password' ? 'text' : 'password',
        isConfirmPasswordVisible: !state.isConfirmPasswordVisible,
      };
    });
  }
  togglePasswordModal() {
    this.props.toggleModal('createPasswordModal');
  }

  render() {
    const { isOpen, isProvideFeedback, username } = this.props;
    const {errors} = this.state;

    return (
      <Modal size="lg" isOpen={isOpen} className={'externalUserInviteContainerStyle'}>
        <ModalBody className="p-0">
          <div
            onClick={this.togglePasswordModal}
            className="closeIcon"
            data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">
              {' '}
              <img src={CloseIcon} />{' '}
            </span>
          </div>
          <Row className="mx-0">
            <Col className="leftPanel col-lg-6 col-12">
              <h4 className="fontW600">Amazing feedback begins with you!</h4>
              <p>
                To get started, create your password to join the CareerPrepped
                community so you can give your feedback{isProvideFeedback && ' to'}
                {isProvideFeedback && <span><strong> {username}</strong>.</span>}
              </p>
              <p className="text-center mb-10 mt-2">
                <span className="font14">
                  Why does CareerPrepped ask for this?
                </span>
                {!this.state.isCollapsed && (
                  <small>
                    <i
                      className="fa fa-chevron-down ml-2 pointer"
                      onClick={this.toggleCollapse}/>
                  </small>
                )}
                {this.state.isCollapsed && (
                  <small>
                    <i
                      className="fa fa-chevron-up ml-2 pointer"
                      onClick={this.toggleCollapse}/>
                  </small>
                )}
              </p>
              <Collapse isOpen={this.state.isCollapsed}>
                <div>
                  {'CareerPrepped helps fellow community members understand how well\ntheir claimed skills are being proven with authentic evidence. One must\nbe part of the community to give and receive feedback to ensure feedback is from a trusted member. Once you join the community, you can continue to give feedback and seek feedback on your own skills.'}
                </div>
              </Collapse>
            </Col>
            <Col className="col-lg-6 col-12">
              <div className="rightPanel">
                <div className="loginFormbox">
                  <div className="text-center mb-5">
                    <img
                      src={CPLogo}
                      className="logoImg"/>
                  </div>
                  <div className="showPassword-wrapper">
                    <div className="position-relative">
                      <Input
                        type={this.state.passwordType}
                        name="password"
                        value={this.state.password || ''}
                        onChange={this.handleChange}
                        invalid={!isEmpty(errors) && true}
                        placeholder="Create a password"/>
                      <div className="ml-1 d-flex align-items-center showPassword">
                        {this.state.isPasswordVisible && (
                          <i
                            className="fa fa-eye pointer eyecolor"
                            onClick={this.toggleEye}/>
                        )}
                        {!this.state.isPasswordVisible && (
                          <i
                            className="fa fa-eye-slash pointer eyecolor"
                            onClick={this.toggleEye}/>
                        )}
                      </div>
                    </div>
                    <div className="form-control-feedback">{errors?.password}</div>
                  </div>
                  <div className="showPassword-wrapper">
                    <div className="position-relative">
                      <Input
                        type={this.state.confirmType}
                        className=""
                        name="confirmpassword"
                        value={this.state.confirmpassword || ''}
                        onChange={this.handleChange}
                        invalid={!isEmpty(errors) && true}
                        placeholder="Confirm your password"/>
                      <div className="ml-1 d-flex align-items-center showPassword">
                        {this.state.isConfirmPasswordVisible && (
                          <i
                            className="fa fa-eye pointer eyecolor"
                            onClick={this.toggleConfirmPasswordVisibility}/>
                        )}
                        {!this.state.isConfirmPasswordVisible && (
                          <i
                            className="fa fa-eye-slash pointer eyecolor"
                            onClick={this.toggleConfirmPasswordVisibility}/>
                        )}
                      </div>
                    </div>
                    <div className="form-control-feedback">{errors?.confirmpassword}</div>
                  </div>
                  <div className="text-right">
                    <Button
                      className="btn sendButton mt-1"
                      onClick={this.submitData}
                      disabled={this.state.isSubmitting}>
                      {this.state.submitBtnText}
                    </Button>
                  </div>
                </div>
              </div>
              <Row>
                <div className="w-100 text-center mb-4">
                  {/** !isProvideFeedback &&<SignInWithLinkedIn /> */}
                </div>
              </Row>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

CreatePassword.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  proceed: PropTypes.func.isRequired,
  isProvideFeedback: PropTypes.bool,
  username: PropTypes.string,
  actions: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...authenticationActions
  }, dispatch)
});

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePassword);
