import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as discussionsActions from '../../../redux/actions/discussions';
import * as componentsActions from '../../../redux/actions/components';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

class PostActions extends Component {
  static propTypes = {
    discussion: PropTypes.object,
    actions: PropTypes.object.isRequired,
    onDeleteClick: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      dropdownOpen: false
    };

    this.toggle = this.toggle.bind(this);
    this.onEdit = this.onEdit.bind(this);
  }

  toggle() {
    this.setState({ dropdownOpen: ! this.state.dropdownOpen });
  }

  onEdit() {
    const { actions, discussion } = this.props;
    const form = `update-post-form-${discussion.id}`;

    this.setState({
      dropdownOpen: false
    });
    actions.loadForm(form);
  }

  render() {
    const { onDeleteClick } = this.props;
    return (
      <span className="action-dropdowns">
        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle className="edit-menu-button">
            &#9679;&#9679;&#9679;
          </DropdownToggle>
          <DropdownMenu right>
            <a className="dropdown-item" onClick={this.onEdit}>Edit post</a>
            <a className="dropdown-item" onClick={onDeleteClick}>Delete post</a>
          </DropdownMenu>
        </Dropdown>
      </span>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, discussionsActions, componentsActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(null, mapDispatchToProps)(PostActions);
