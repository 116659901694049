import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import { get } from 'lodash';

const BaseSkillComponent = (props) => {
  const rowDetails = props.rowData;
  return (
    <td>
      <div className="d-flex align-items-center">
        <Link
          to={
            rowDetails.resourceType === 'badge'
              ? `/skill-badges/badge-details/${rowDetails?.resourceId}`
              : `/skill-evidence/${rowDetails?.resourceId}`
          }
          className="d-flex align-items-center">
          {
            rowDetails.resourceType === 'badge' &&
              <img
                className="receivedRequestImage"
                src={get(
                  rowDetails,
                  'resourceUrl',
                  require(rowDetails.resourceType === 'badge'
                    ? '../../../assets/images/badge_fake.png'
                    : '../../../assets/images/temp/skill.png')
                )}/>
          }
          <span>{rowDetails.resourcename}</span>
        </Link>
      </div>
    </td>
  );
};

BaseSkillComponent.propTypes = {
  rowData: PropTypes.object.isRequired,
};

export default BaseSkillComponent;
