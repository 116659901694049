import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../common/Button';
import TextInput from '../../common/TextInput';
import TextListInput from './TextListInput';
import TextListItems from './TextListItems';

const styles = {
  panelHeading: { minHeight: 30, backgroundColor: '#fafafa', marginBottom: 10 },
  heading: { textAlign: 'center', paddingRight: 40, color: 'black', paddingTop: 10 }
};

const TextListForm = ({
  headerTitle,
  isAdmin,
  isEditing,
  onCancel,
  errors,
  section,
  onChange,
  onSave,
  onDelete,
  isSubmitting,
  isUpdating,
  isDeleting,
  formType,
  listItemsLimit,
  onEditListItem,
  onDeleteListItem,
  onAddListItem,
  setEditorState,
  getEditorState,
  resetEditorState,
  updateEditorState
}) => {
  return (
    <div>
      {isAdmin &&
        <div className="card-header" style={styles.panelHeading}>
          <h3 style={styles.heading}>
            {headerTitle}
          </h3>
        </div>}

      <div className="card-block p-3">
        <form id={formType ? `${formType.formName}_${formType.id}` : ''}>
          <div className="row">
            <div className="col-sm-12">
              <TextInput
                autoFocus
                label="Title"
                name="title"
                error={errors.title}
                value={section.title}
                onChange={onChange}
                placeholder="Title"/>
            </div>
          </div>

          <TextListInput
            isEditing={isEditing}
            errors={errors}
            section={section}
            resetEditorState={resetEditorState}
            updateEditorState={updateEditorState}
            setEditorState={setEditorState}
            getEditorState={getEditorState}
            listItemsLimit={listItemsLimit}
            onAddListItem={onAddListItem}/>

          <TextListItems
            section={section}
            onEditListItem={onEditListItem}
            onDeleteListItem={onDeleteListItem}/>

          <div className="row">
            <div className="col-sm-12">
              <hr/>
              {!formType &&
                <Button
                  styles={{marginLeft: 0}}
                  type="submit"
                  onClick={onDelete}
                  disabled={isDeleting}
                  buttonClass="btn-danger"
                  buttonText={isDeleting ? 'Deleting...' : 'Delete'}/>}

              <Button
                type="submit"
                onClick={onSave}
                disabled={isSubmitting || isUpdating}
                buttonClass="btn-primary float-right"
                buttonText={isSubmitting || isUpdating ? 'Saving...' : 'Save'}/>

              <Button
                type="button"
                buttonId={section ? `text-list_${section.id}` : ''}
                onClick={onCancel}
                buttonClass="btn-secondary float-right"
                buttonText="Cancel"/>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

TextListForm.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  section: PropTypes.object,
  isEditing: PropTypes.string,
  errors: PropTypes.object,
  formType: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAddListItem: PropTypes.func.isRequired,
  listItemsLimit: PropTypes.number.isRequired,
  onEditListItem: PropTypes.func.isRequired,
  onDeleteListItem: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  setEditorState: PropTypes.func.isRequired,
  getEditorState: PropTypes.func.isRequired,
  updateEditorState: PropTypes.bool.isRequired,
  resetEditorState: PropTypes.bool.isRequired
};

export default TextListForm;
