import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import LazyLoad from 'react-lazyload';
import Loading from '../common/Loading';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

const ImageRenderer = ({
  attachment,
  secondary
}) => {
  const imageStyles = classNames({
    'comment-img': secondary,
    'post-img': !secondary
  });

  return(
    <div style={{ marginTop: 10 }}>
      <a href={attachment} target="_blank" aria-label="post image"> 
        <LazyLoad 
          throttle={100} 
          height={200} 
          placeholder={
            <div 
              style={{
                width: '100%',
                height: '200px',
                color: '#fff',
                backgroundColor: '#E0E0E0'
              }}
              className={classNames('loading-container', {
                'comment-img': secondary,
                'post-img': !secondary
              })}>
              <Loading/>
            </div>
          }>
          <ReactCSSTransitionGroup
            transitionName="lazyImage"
            transitionAppear
            transitionAppearTimeout={500}
            transitionEnterTimeout={500}
            transitionLeaveTimeout={300}>
            <img 
              className={imageStyles} 
              src={attachment} 
              alt="an image"/>
          </ReactCSSTransitionGroup>
        </LazyLoad>
      </a>
    </div>
  );
};

ImageRenderer.propTypes = {
  attachment: PropTypes.string,
  componentName: PropTypes.string,
  secondary: PropTypes.bool
};

export default ImageRenderer;
