import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as badgeAssertionActions from '../../redux/actions/badge-assertion';

import CpHtmlParser from '../../components/common/CpHtmlParser';
import JustificationAnswerForm from './JustificationAnswerForm';
import SeeTipsModal from '../../components/skill-builders/claim-badge/SeeTipsModal';

class UnitBadgeJustifications extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    badgeAssertion: PropTypes.object.isRequired,
    justification: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      editAnswer: '',
      showExampleModal: false,
      justification: Object.assign({}, this.props.justification)
    };

    this.onToggleEditAnswer = this.onToggleEditAnswer.bind(this);
    this.onCancelEditAnswer = this.onCancelEditAnswer.bind(this);
    this.onToggleExampleModal = this.onToggleExampleModal.bind(this);
  }

  onToggleEditAnswer(event) {
    event.preventDefault();

    const editAnswer = event.target.id;

    const justification = this.props.badgeAssertion.data.justifications.find(justification =>
      justification.id === editAnswer);

    this.setState({ editAnswer, justification });
  }

  onToggleExampleModal(event) {
    event.preventDefault();

    const justificationId = event.target.dataset.justificationId;
    const justification = this.props
      .badgeAssertion
      .data
      .justifications
      .find(justification => justification.id === justificationId);

    const { showExampleModal } = this.state;

    this.setState({
      justification,
      showExampleModal: !showExampleModal
    });
  }

  onCancelEditAnswer() {
    this.setState({ editAnswer: '' });
  }

  render() {
    const { currentUser, badgeAssertion } = this.props;
    const { editAnswer, justification, showExampleModal } = this.state;
    const isJustificationAnswerOwner = currentUser.id === badgeAssertion.data.user.id;

    return (
      <div className="section-badge">
        <h6>
          Question Responses
        </h6>
        <div className="section-badge-content">
          {(editAnswer.length !== 0) ?
            <JustificationAnswerForm
              justification={justification}
              badgeAssertion={badgeAssertion}
              onCancelEditAnswer={this.onCancelEditAnswer}/> :
            <div className="answers">
              {badgeAssertion.data.justifications.map(justification =>
                (<div
                  className="ql-editor no-padding-in-quill"
                  key={justification.id}
                  style={{marginBottom: 20}}>
                  <b>{justification.question}</b>&nbsp;

                  {isJustificationAnswerOwner &&
                    <a
                      id={justification.id}
                      onClick={this.onToggleEditAnswer}
                      className="ml-2 clickable small"
                      style={{cursor: 'pointer'}}>
                      <i style={{paddingRight: 5}} className="fa fa-pencil"/>Edit
                    </a>}

                  <div className="mb-3">
                    <a href="#"
                      data-justification-id={justification.id}
                      className="small underline"
                      onClick={this.onToggleExampleModal}>
                      (Tips for evaluating this response)
                    </a>
                  </div>

                  <div style={{marginTop: 10}}>
                    <CpHtmlParser htmlString={justification.answer}/>
                  </div>
                </div>))}
            </div>}
        </div>

        {showExampleModal &&
          <SeeTipsModal
            isOpen={showExampleModal}
            justification={justification}
            onToggle={this.onToggleExampleModal} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, badgeAssertion } = state;
  let justification = {
    answer: ''
  };

  return {
    badgeAssertion,
    justification,
    currentUser: auth.data.currentUser
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(badgeAssertionActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitBadgeJustifications);
