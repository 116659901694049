import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Validator from '../../../validator';
import CommentForm from '../../../components/skill-builders/discussions/CommentForm';

import * as commentsActions from '../../../redux/actions/comments';
import * as discussionsActions from '../../../redux/actions/discussions';
import * as componentsActions from '../../../redux/actions/components';

import { toastr } from 'react-redux-toastr';
import isEmpty from 'lodash/isEmpty';

class CommentFormHandler extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    comment: PropTypes.object.isRequired,
    discussionId: PropTypes.string.isRequired,
    styles: PropTypes.object,
    isForm: PropTypes.bool,
    form: PropTypes.string,
    profile: PropTypes.object.isRequired,
    loadedComments: PropTypes.object.isRequired,
    isSubmitting: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      comment: Object.assign({}, this.props.comment),
      errors: {},
      attachment: {},
      imagePreviewUrl: this.props.comment.attachment
    };

    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDropFile = this.onDropFile.bind(this);
    this.onEscape = this.onEscape.bind(this);
    this.refTextarea = this.refTextarea.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentWillReceiveProps(nextProps){
    const { isSubmitting: nextSubmitting, loadedComments: nextLoadedComments } = nextProps;
    const { isSubmitting, loadedComments, discussionId, actions } = this.props;

    if(nextSubmitting !== isSubmitting && !nextSubmitting.status && isSubmitting.discussionId === discussionId &&
      nextLoadedComments[isSubmitting.discussionId] !== loadedComments[isSubmitting.discussionId]){

      actions.incrementDiscussionCommentCount(discussionId, 'comment');

      this.textarea.style.cssText = 'height:auto';
      this.handleResponse();

      this.onFocus();
    }
  }

  onFocus() {
    // focus on input field.
    let formId = `comment-form-discussionId-${this.props.discussionId}`;
    if (this.props.form)
      formId = this.props.form;
    document.getElementById(formId).focus();
  }

  onDropFile(files) {
    if (files[0].size > 8000000)
      return toastr.info('Sorry, this image exceeds the 8MB limit.');

    this.onFocus();

    const imagePreviewUrl = URL.createObjectURL(files[0]);

    this.setState({
      attachment: files[0],
      imagePreviewUrl
    });
  }

  onCancel() {
    this.setState({
      attachment: {},
      imagePreviewUrl: ''
    });

    this.onFocus();
  }

  isValid(field = null) {
    const validate = Validator.createValidator({
      comment: ['maxLength|1000']
    }, this.state.comment, field);

    const { isValid, errors } = validate;

    this.setState({ errors });

    return isValid;
  }

  handleResponse() {
    const commentData = this.state.comment;

    this.setState({
      comment: { comment: '', attachment: '' },
      attachment: {},
      imagePreviewUrl: ''
    });

    if (commentData.id)
      this.props.actions.unloadForm(`update-comment-form-${commentData.id}`);
  }

  onChange(event) {
    const el = event.target;
    el.style.cssText = 'height:auto; padding:0; overflow:auto';
    el.style.cssText = `height: ${el.scrollHeight + 5}px`;
    const field = el.name;
    const { comment } = this.state;

    comment[field] = el.value;

    this.setState({ comment });

    this.isValid(field);
  }

  onSave(event) {
    if (event.key === 'Enter') {
      event.preventDefault();

      let { comment, attachment } = this.state;
      const { actions, discussionId } = this.props;

      if (! this.isValid()) return;

      if(comment.comment === '' && isEmpty(attachment))
        return toastr.error('Comment cannot be empty.');

      comment.discussion = discussionId;

      if (comment.id)
        return actions.updateUnitDiscussionComment(
          discussionId, comment.id, this.createFormData('update')
        )
          .then(() => {
            this.textarea.style.cssText = 'height:auto';
            this.handleResponse();
          });

      actions.saveUnitDiscussionComment(discussionId, this.createFormData('create'));
    }
  }

  createFormData(type) {
    const {
      comment,
      attachment,
      imagePreviewUrl
    } = this.state;

    let formData = new FormData();

    if(comment.comment !== '')
      formData.append('comment', comment.comment);
    formData.append('discussion', comment.discussion);

    if (!isEmpty(attachment)) {
      formData.append('attachment', attachment);
      return formData;
    }

    if (!imagePreviewUrl && type === 'update')
      formData.append('attachment', '');

    return formData;
  }

  refTextarea(textarea) {
    this.textarea = textarea;
  }

  onEscape() {
    const {
      comment,
      actions
    } = this.props;

    const form = `update-comment-form-${comment.id}`;

    actions.unloadForm(form);
  }

  handleKeyPress(event) {
    if (event.key === 'Enter')
      return this.onSave(event);

    if (event.key === 'Escape')
      return this.onEscape();
  }

  render() {
    const { comment, imagePreviewUrl, errors } = this.state;

    const { styles, discussionId, isForm, profile } = this.props;

    let formId = `comment-form-discussionId-${discussionId}`;
    if (this.props.form)
      formId = this.props.form;

    return (
      <CommentForm
        textareaRef={this.refTextarea}
        errors={errors}
        styles={styles}
        comment={comment}
        onChange={this.onChange}
        handleKeyPress={this.handleKeyPress}
        discussionId={discussionId}
        imagePreviewUrl={imagePreviewUrl}
        onCancel={this.onCancel}
        onDropFile={this.onDropFile}
        isForm={isForm}
        onEscape={this.onEscape}
        form={formId}
        profile={profile}/>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let comment = { comment: '', attachment: '' };

  if (ownProps.comment) {
    comment = Object.assign({}, comment, ownProps.comment);
  }

  return {
    comment,
    profile: state.profile.data,
    isUpdating: state.comments.isUpdating,
    loadedComments: state.comments.loadedComments,
    isSubmitting: state.comments.isSubmitting
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {}, componentsActions, commentsActions, discussionsActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentFormHandler);
