import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';

import CreatePassword from './create-password/CreatePassword';
import { inviteToken } from '../../redux/actions/invite-users';
import { createPasswordForIR } from '../../redux/actions/authentication';
import { withHooks } from '../../utils/withHooks';
import {
  TOKEN_USED,
  TOKEN_INVALID,
  TOKEN_VALID,
  INVITATION_ACCEPTED,
  SIGN_IN_INFO_MESSAGE,
  REVIEW_LINK
} from '../../constants/invite';

import { redirect } from '../../redux/actions/router';

class Invite extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    params: PropTypes.object,
    profile: PropTypes.object,
    auth: PropTypes.object,
    navigate: PropTypes.func,
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      createPasswordModal: false,
      tokenIsUsed: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.proceed = this.proceed.bind(this);
  }

  componentDidMount() {
    this.props.actions.inviteToken(this.props.params?.token).then((res) => {
      if (
        res.inviteUsers?.data?.detail?.tokenStatus &&
        res.inviteUsers?.data?.detail?.tokenStatus === TOKEN_INVALID
      ) {
        toastr.error('', res.inviteUsers?.data?.detail);
      } else if (
        res.inviteUsers?.data?.detail?.tokenStatus &&
        res.inviteUsers?.data?.detail?.tokenStatus === TOKEN_USED
      ) {
        this.setState({ tokenIsUsed: true });
      } else if(res.inviteUsers?.data?.detail?.tokenStatus === TOKEN_VALID
             && res.inviteUsers?.data?.detail?.isUserExists){
        this.redirect();
      }else{
        this.setState({ createPasswordModal: true });
      }
    });
  }

  proceed(modal, data) {
    if (this.state.createPasswordModal) {
      this.props.actions
        .createPasswordForIR({
          token: this.props.params?.token,
          ...data,
        })
        .then(() => this.redirect());
    }
  }

  redirect() {
    const { actions, navigate, router, auth } = this.props;
    const { nextPathname } = router;

    if (auth.isAuthenticated) {
      if (nextPathname) {
        actions.redirect(nextPathname);
        navigate(nextPathname);
        return;
      }

      actions.redirect('/home');
      navigate('/home');
    }
  }

  toggleModal(modal) {
    this.setState((state) => {
      return {
        [modal]: !state[modal],
      };
    });
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <CreatePassword
            isOpen={this.state.createPasswordModal}
            toggleModal={this.toggleModal}
            proceed={this.proceed}/>
          {this.state.tokenIsUsed && (
            <div className="invitation-body">
              <div className="accept-invitation-message">
                <p className="mb-2">
                  Join
                  <img
                    src={require('../../assets/images/CP_logo_no-mark.png')}
                    className="img-responsive ml-2"
                    height="16"
                    alt="CareerPrepped"/>
                </p>
                <p>
                  {INVITATION_ACCEPTED}
                  {[
                    `${!this.props.profile?.name}` && ` ${SIGN_IN_INFO_MESSAGE}`,
                    <a target="_blank" href={REVIEW_LINK} key={this.props.profile?.id || '0'}>help article.</a>
                  ]}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.data,
    auth: state.auth,
    router: state.router,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      inviteToken,
      createPasswordForIR,
      redirect,
    },
    dispatch
  ),
});

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(Invite));
