import initialState from './initialState';

import {
  SHARE_DISCUSSION,
  RESET_SHARE_DISCUSSION,
  DISCUSSION_REQUEST,
  DISCUSSION_SUCCESS,
  DISCUSSION_FAILURE,
  DISCUSSIONS_REQUEST,
  DISCUSSIONS_SUCCESS,
  DISCUSSIONS_FAILURE,
  CREATE_DISCUSSION_LIKE_REQUEST,
  CREATE_DISCUSSION_LIKE_SUCCESS,
  CREATE_DISCUSSION_LIKE_FAILURE,
  CREATE_DISCUSSION_REQUEST,
  CREATE_DISCUSSION_SUCCESS,
  CREATE_DISCUSSION_FAILURE,
  UPDATE_DISCUSSION_REQUEST,
  UPDATE_DISCUSSION_SUCCESS,
  UPDATE_DISCUSSION_FAILURE,
  DELETE_DISCUSSION_REQUEST,
  DELETE_DISCUSSION_SUCCESS,
  DELETE_DISCUSSION_FAILURE,
  INITIALIZE_DISCUSSIONS_STATE,
  INCREMENT_DISCUSSION_COMMENT_COUNT,
  DECREMENT_DISCUSSION_COMMENT_COUNT,
  UNLIKE_DISCUSSION_POST_REQUEST,
  UNLIKE_DISCUSSION_POST_SUCCESS,
  UNLIKE_DISCUSSION_POST_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.discussions, action) {
  switch (action.type) {
  case INITIALIZE_DISCUSSIONS_STATE: {
    return Object.assign({}, state, initialState.discussions);
  }

  case DECREMENT_DISCUSSION_COMMENT_COUNT: {
    const index = state.data.findIndex(
      discussion => discussion.id === action.id
    );

    let returnObject = {};
    if (action.typeOfComment)
      returnObject = { [action.id]: state.commentCount[action.id] - 1 };

    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, index),
        Object.assign({}, state.data[index], {
          comment_count: state.data[index].comment_count - action.count,
        }),
        ...state.data.slice(index + 1),
      ],
      commentCount: Object.assign({}, state.commentCount, returnObject),
    });
  }

  case INCREMENT_DISCUSSION_COMMENT_COUNT: {
    const index = state.data.findIndex(
      discussion => discussion.id === action.id
    );

    let returnObject = {};
    let count = 0;

    if (action.typeOfComment) {
      if (state.commentCount[action.id]) {
        count = state.commentCount[action.id];
      }

      returnObject = { [action.id]: count + 1 };
    }

    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, index),
        Object.assign({}, state.data[index], {
          comment_count: state.data[index].comment_count + 1,
        }),
        ...state.data.slice(index + 1),
      ],
      commentCount: Object.assign({}, state.commentCount, returnObject),
    });
  }

  case SHARE_DISCUSSION:
    return Object.assign({}, state, { discussionShare: action.discussion });

  case RESET_SHARE_DISCUSSION:
    return Object.assign({}, state, { discussionShare: action.discussion });

  case DISCUSSION_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case DISCUSSION_SUCCESS:
    return Object.assign({}, initialState.discussions, {
      data: [action.data],

      isRequesting: false,
    });

  case DISCUSSION_FAILURE:
    return Object.assign({}, initialState.discussions, {
      error: action.error,
      isRequesting: false,
    });

  case DISCUSSIONS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case DISCUSSIONS_SUCCESS:
    return Object.assign({}, state, {
      data: state.data.concat(action.data._embedded.discussion),
      commentCount: Object.assign(
        {},
        state.commentCount,
        action.data._embedded.discussion.reduce((item, obj) => {
          item[obj.id] = obj.comment_count;
          return item;
        }, {})
      ),
      isRequesting: false,
      paginationData: Object.assign(
        {},
        {
          page: action.data.page,
          page_count: action.data.page_count,
        }
      ),
    });

  case DISCUSSIONS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case CREATE_DISCUSSION_REQUEST:
    return Object.assign({}, state, { isSubmitting: true });

  case CREATE_DISCUSSION_SUCCESS:
    return Object.assign({}, state, {
      data: [Object.assign({}, action.data), ...state.data],
      commentCount: Object.assign(
        {},
        {
          [action.data.id]: 0,
        },
        state.commentCount
      ),
      isSubmitting: false,
      error: {},
    });

  case CREATE_DISCUSSION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case CREATE_DISCUSSION_LIKE_REQUEST:
  case UNLIKE_DISCUSSION_POST_REQUEST:
    return Object.assign({}, state, {
      isSubmittingLike: {
        status: true,
        discussionId: action.id,
        userId: action.userId,
      },
    });

  case CREATE_DISCUSSION_LIKE_SUCCESS: {
    const index = state.data.findIndex(
      discussionItem =>
        discussionItem.id === state.isSubmittingLike.discussionId
    );

    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, index),
        Object.assign({}, state.data[index], {
          liked: true,
          like_count: state.data[index].like_count + 1,
          likesList: [
            ...state.data[index].likesList,
            Object.assign({}, action.data),
          ],
        }),
        ...state.data.slice(index + 1),
      ],
      isSubmittingLike: { status: false, discussionId: null },
      error: {},
    });
  }

  case UNLIKE_DISCUSSION_POST_SUCCESS: {
    const index = state.data.findIndex(
      discussionItem =>
        discussionItem.id === state.isSubmittingLike.discussionId
    );

    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, index),
        Object.assign({}, state.data[index], {
          liked: false,
          like_count: state.data[index].like_count - 1,
          likesList: [
            ...state.data.filter(
              like => like.id !== state.isSubmittingLike.userId
            ),
          ],
        }),
        ...state.data.slice(index + 1),
      ],
      isSubmittingLike: {
        status: false,
        userId: null,
        discussionId: null,
      },
      error: {},
    });
  }

  case CREATE_DISCUSSION_LIKE_FAILURE:
  case UNLIKE_DISCUSSION_POST_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmittingLike: {
        status: false,
        userId: null,
        discussionId: null,
      },
    });

  case UPDATE_DISCUSSION_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, discussionId: action.id },
    });

  case UPDATE_DISCUSSION_SUCCESS: {
    const index = state.data.findIndex(
      item => item.id === state.isUpdating.discussionId
    );
    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, index),
        Object.assign({}, state.data[index], action.data, {
          liked: state.data[index].liked,
        }),
        ...state.data.slice(index + 1),
      ],
      isUpdating: { status: false, discussionId: null },
      error: {},
    });
  }

  case UPDATE_DISCUSSION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false, discussionId: null },
    });

  case DELETE_DISCUSSION_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: action.id },
    });

  case DELETE_DISCUSSION_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.filter(
          discussion => discussion.id !== state.isDeleting.id
        ),
      ],
      isDeleting: { status: false, id: null },
    });

  case DELETE_DISCUSSION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: { status: false, id: null },
    });

  default:
    return state;
  }
}
