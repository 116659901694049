import isUndefined from 'lodash/isUndefined';

import { CALL_API } from '../../middlewares/api';
import {
  RESET_FILES_REDUCER,
  FILE_UPLOAD_REQUEST,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAILURE,
  DELETE_FILE_REQUEST,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_FAILURE,
  FETCH_FILE_REQUEST,
  FETCH_FILE_SUCCESS,
  FETCH_FILE_FAILURE,
  UPDATE_FILE_REQUEST,
  UPDATE_FILE_SUCCESS,
  UPDATE_FILE_FAILURE,
  RESET_RECENT_UPLOADED_FILE_ID,
  FETCH_UNIT_FILE_REQUEST,
  FETCH_UNIT_FILE_SUCCESS,
  FETCH_UNIT_FILE_FAILURE,
  SET_PREVIEW_FILE,
  CLEAR_PREVIEW_FILE,
  LINK_NEW_PORTFOLIO_FILE_TO_A_BADGE_REQUEST,
  LINK_NEW_PORTFOLIO_FILE_TO_A_BADGE_SUCCESS,
  LINK_NEW_PORTFOLIO_FILE_TO_A_BADGE_FAILURE,
  LINK_NEW_PORTFOLIO_FILE_TO_A_BAKED_BADGE_REQUEST,
  LINK_NEW_PORTFOLIO_FILE_TO_A_BAKED_BADGE_SUCCESS,
  LINK_NEW_PORTFOLIO_FILE_TO_A_BAKED_BADGE_FAILURE,
  SB_FILE_UPLOAD_REQUEST,
  SB_FILE_UPLOAD_SUCCESS,
  SB_FILE_UPLOAD_FAILURE,
  UNIT_FILE_COMMENTS_REQUEST,
  UNIT_FILE_COMMENTS_SUCCESS,
  UNIT_FILE_COMMENTS_FAILURE,
  ADD_UNIT_FILE_COMMENTS_REQUEST,
  ADD_UNIT_FILE_COMMENTS_SUCCESS,
  ADD_UNIT_FILE_COMMENTS_FAILURE,
  UPDATE_FILE_COMMENTS_REQUEST,
  UPDATE_FILE_COMMENTS_SUCCESS,
  UPDATE_FILE_COMMENTS_FAILURE,
  DELETE_FILE_COMMENT_REQUEST,
  DELETE_FILE_COMMENT_SUCCESS,
  DELETE_FILE_COMMENT_FAILURE,
  DELETE_FILE_COMMENT_REPLY_REQUEST,
  DELETE_FILE_COMMENT_REPLY_SUCCESS,
  DELETE_FILE_COMMENT_REPLY_FAILURE,
  LIKE_FILE_REQUEST,
  LIKE_FILE_SUCCESS,
  LIKE_FILE_FAILURE,
  UNLIKE_FILE_REQUEST,
  UNLIKE_FILE_SUCCESS,
  UNLIKE_FILE_FAILURE,
  LIKE_FILE_COMMENT_REQUEST,
  LIKE_FILE_COMMENT_SUCCESS,
  LIKE_FILE_COMMENT_FAILURE,
  ADD_FILE_COMMENT_REPLY_REQUEST,
  ADD_FILE_COMMENT_REPLY_SUCCESS,
  ADD_FILE_COMMENT_REPLY_FAILURE,
  LIKE_FILE_COMMENT_REPLY_REQUEST,
  LIKE_FILE_COMMENT_REPLY_SUCCESS,
  LIKE_FILE_COMMENT_REPLY_FAILURE,
  UPDATE_FILE_COMMENT_REPLY_REQUEST,
  UPDATE_FILE_COMMENT_REPLY_SUCCESS,
  UPDATE_FILE_COMMENT_REPLY_FAILURE,
  UNLIKE_FILE_COMMENT_REQUEST,
  UNLIKE_FILE_COMMENT_SUCCESS,
  UNLIKE_FILE_COMMENT_FAILURE,
  UNLIKE_FILE_COMMENT_REPLY_REQUEST,
  UNLIKE_FILE_COMMENT_REPLY_SUCCESS,
  UNLIKE_FILE_COMMENT_REPLY_FAILURE,
  UPDATE_FILE_EVIDENCE_PAGE_REQUEST,
  UPDATE_FILE_EVIDENCE_PAGE_SUCCESS,
  UPDATE_FILE_EVIDENCE_PAGE_FAILURE,
} from '../../constants';

export const clearFilesReducer = () => {
  return { type: RESET_FILES_REDUCER };
};

export const resetRecentUploadedFileId = () => {
  return { type: RESET_RECENT_UPLOADED_FILE_ID };
};

export const setPreviewFile = file => ({ type: SET_PREVIEW_FILE, file });

export const clearPreviewFile = () => ({ type: CLEAR_PREVIEW_FILE });

export const uploadFile = (data) => {
  const endpoint = 'file/file';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [FILE_UPLOAD_REQUEST, FILE_UPLOAD_SUCCESS, FILE_UPLOAD_FAILURE],
    },
  };
};

export const linkNewPortfolioFile = (data, bakedBadgePage) => {
  const endpoint = 'file/file';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: bakedBadgePage
        ? [
          LINK_NEW_PORTFOLIO_FILE_TO_A_BAKED_BADGE_REQUEST,
          LINK_NEW_PORTFOLIO_FILE_TO_A_BAKED_BADGE_SUCCESS,
          LINK_NEW_PORTFOLIO_FILE_TO_A_BAKED_BADGE_FAILURE,
        ]
        : [
          LINK_NEW_PORTFOLIO_FILE_TO_A_BADGE_REQUEST,
          LINK_NEW_PORTFOLIO_FILE_TO_A_BADGE_SUCCESS,
          LINK_NEW_PORTFOLIO_FILE_TO_A_BADGE_FAILURE,
        ],
    },
  };
};

export const fetchFiles = (
  isAuthenticated,
  userId,
  pageNo,
  sortBy,
  searchValue
) => {
  let endpoint = `file/file?user=${userId}&page=${pageNo}`;

  if (!isUndefined(sortBy) && sortBy !== 'none') {
    endpoint = `${endpoint}&sort=${sortBy}`;
  }

  if (!isUndefined(searchValue) && searchValue !== '') {
    endpoint = `${endpoint}&search=${searchValue}`;
  }

  return {
    [CALL_API]: isAuthenticated
      ? {
        endpoint,
        method: 'get',
        authenticated: true,
        types: [FETCH_FILE_REQUEST, FETCH_FILE_SUCCESS, FETCH_FILE_FAILURE],
      }
      : {
        endpoint,
        method: 'get',
        types: [FETCH_FILE_REQUEST, FETCH_FILE_SUCCESS, FETCH_FILE_FAILURE],
      },
  };
};

export const deleteFile = (id) => {
  const endpoint = `file/file/${id}`;

  return {
    [CALL_API]: {
      id,
      endpoint,
      method: 'delete',
      authenticated: true,
      types: [DELETE_FILE_REQUEST, DELETE_FILE_SUCCESS, DELETE_FILE_FAILURE],
    },
  };
};

export const updateFile = (id, data, skillEvidence) => {
  const endpoint = `file/file/${id}`;
  return {
    [CALL_API]: {
      data,
      endpoint,
      id: id,
      method: 'patch',
      authenticated: true,
      types: skillEvidence
        ? [
          UPDATE_FILE_EVIDENCE_PAGE_REQUEST,
          UPDATE_FILE_EVIDENCE_PAGE_SUCCESS,
          UPDATE_FILE_EVIDENCE_PAGE_FAILURE,
        ]
        : [UPDATE_FILE_REQUEST, UPDATE_FILE_SUCCESS, UPDATE_FILE_FAILURE],
    },
  };
};

export const fetchFileById = (id, isAuthenticated) => {
  const endpoint = `file/file/${id}`;
  return {
    [CALL_API]: isAuthenticated
      ? {
        endpoint,
        method: 'get',
        authenticated: true,
        types: [
          FETCH_UNIT_FILE_REQUEST,
          FETCH_UNIT_FILE_SUCCESS,
          FETCH_UNIT_FILE_FAILURE,
        ],
      }
      : {
        endpoint,
        method: 'get',
        types: [
          FETCH_UNIT_FILE_REQUEST,
          FETCH_UNIT_FILE_SUCCESS,
          FETCH_UNIT_FILE_FAILURE,
        ],
      },
  };
};

export const uploadSBFile = (data) => {
  const endpoint = 'file/file';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        SB_FILE_UPLOAD_REQUEST,
        SB_FILE_UPLOAD_SUCCESS,
        SB_FILE_UPLOAD_FAILURE,
      ],
    },
  };
};

export const getUnitFileComments = (fileId, isAuthenticated) => {
  const endpoint = `file/comments?file=${fileId}`;

  return {
    [CALL_API]: isAuthenticated
      ? {
        endpoint,
        method: 'get',
        authenticated: true,
        types: [
          UNIT_FILE_COMMENTS_REQUEST,
          UNIT_FILE_COMMENTS_SUCCESS,
          UNIT_FILE_COMMENTS_FAILURE,
        ],
      }
      : {
        endpoint,
        method: 'get',
        types: [
          UNIT_FILE_COMMENTS_REQUEST,
          UNIT_FILE_COMMENTS_SUCCESS,
          UNIT_FILE_COMMENTS_FAILURE,
        ],
      },
  };
};

export const likeFile = (data) => {
  const endpoint = 'file/likes';
  return {
    [CALL_API]: {
      endpoint,
      data,
      id: data.file,
      method: 'post',
      authenticated: true,
      types: [LIKE_FILE_REQUEST, LIKE_FILE_SUCCESS, LIKE_FILE_FAILURE],
    },
  };
};

export const unlikeFile = (fileId) => {
  const endpoint = `file/likes/${fileId}`;
  return {
    [CALL_API]: {
      endpoint,
      id: fileId,
      method: 'delete',
      authenticated: true,
      types: [UNLIKE_FILE_REQUEST, UNLIKE_FILE_SUCCESS, UNLIKE_FILE_FAILURE],
    },
  };
};

export const addCommentToFile = (data, reply) => {
  const endpoint = 'file/comments';
  return {
    [CALL_API]: {
      endpoint,
      data,
      id: reply && reply === 'reply' ? data.parent : data.file,
      method: 'post',
      authenticated: true,
      types:
        reply && reply === 'reply'
          ? [
            ADD_FILE_COMMENT_REPLY_REQUEST,
            ADD_FILE_COMMENT_REPLY_SUCCESS,
            ADD_FILE_COMMENT_REPLY_FAILURE,
          ]
          : [
            ADD_UNIT_FILE_COMMENTS_REQUEST,
            ADD_UNIT_FILE_COMMENTS_SUCCESS,
            ADD_UNIT_FILE_COMMENTS_FAILURE,
          ],
    },
  };
};

export const updateFileComment = (data, commentId, reply) => {
  const endpoint = `file/comments/${commentId}`;
  return {
    [CALL_API]: {
      endpoint,
      data,
      id: commentId,
      method: 'patch',
      authenticated: true,
      types:
        reply && reply === 'reply'
          ? [
            UPDATE_FILE_COMMENT_REPLY_REQUEST,
            UPDATE_FILE_COMMENT_REPLY_SUCCESS,
            UPDATE_FILE_COMMENT_REPLY_FAILURE,
          ]
          : [
            UPDATE_FILE_COMMENTS_REQUEST,
            UPDATE_FILE_COMMENTS_SUCCESS,
            UPDATE_FILE_COMMENTS_FAILURE,
          ],
    },
  };
};

export const deleteFileComment = (id, parentId) => {
  const endpoint = `file/comments/${id}`;
  return {
    [CALL_API]: {
      endpoint,
      id,
      query: parentId,
      method: 'delete',
      authenticated: true,
      types: parentId
        ? [
          DELETE_FILE_COMMENT_REPLY_REQUEST,
          DELETE_FILE_COMMENT_REPLY_SUCCESS,
          DELETE_FILE_COMMENT_REPLY_FAILURE,
        ]
        : [
          DELETE_FILE_COMMENT_REQUEST,
          DELETE_FILE_COMMENT_SUCCESS,
          DELETE_FILE_COMMENT_FAILURE,
        ],
    },
  };
};

export const likeFileComment = (data, reply) => {
  const endpoint = 'file/comment_likes';
  return {
    [CALL_API]: {
      endpoint,
      data,
      id: data.comment,
      method: 'post',
      authenticated: true,
      types:
        reply && reply === 'reply'
          ? [
            LIKE_FILE_COMMENT_REPLY_REQUEST,
            LIKE_FILE_COMMENT_REPLY_SUCCESS,
            LIKE_FILE_COMMENT_REPLY_FAILURE,
          ]
          : [
            LIKE_FILE_COMMENT_REQUEST,
            LIKE_FILE_COMMENT_SUCCESS,
            LIKE_FILE_COMMENT_FAILURE,
          ],
    },
  };
};

export const unlikeFileComment = (id, reply) => {
  const endpoint = `file/comment_likes/${id}`;
  return {
    [CALL_API]: {
      endpoint,
      id: id,
      method: 'delete',
      authenticated: true,
      types:
        reply && reply === 'reply'
          ? [
            UNLIKE_FILE_COMMENT_REPLY_REQUEST,
            UNLIKE_FILE_COMMENT_REPLY_SUCCESS,
            UNLIKE_FILE_COMMENT_REPLY_FAILURE,
          ]
          : [
            UNLIKE_FILE_COMMENT_REQUEST,
            UNLIKE_FILE_COMMENT_SUCCESS,
            UNLIKE_FILE_COMMENT_FAILURE,
          ],
    },
  };
};
