import {
  chain,
  transform,
  isEqual,
  isEmpty,
  isObject,
  isUndefined,
  forEach,
} from 'lodash';

export const parseJSON = stringData => JSON.parse(stringData);
export const stringifyJSON = data => JSON.stringify(data);

export const uniqueId = () => Math.random().toString(36).substr(2, 16);

export const guid = () => {
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

export const checkIfAuthTokenPresent = () => {
  return localStorage.getItem('careerPrepped.auth.token');
};

export const checkHttp = (url) => {
  const checkHttpRegex = /^((http|https|ftp):\/\/)/;
  return checkHttpRegex.test(url);
};

export const checkUrl = (string, domain) => {
  let regex;
  switch (domain) {
  case 'twitter':
    regex = /^(https?:\/\/)?((w{3}\.)?)twitter\.com\/[A-z 0-9 _]+\/?/;
    break;

  case 'linkedin':
    regex = /^(https?:\/\/)?((w{3}\.)?)linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
    break;

  case 'github':
    regex = /^(https?:\/\/)?((w{3}\.)?)github\.com\/[A-z 0-9 _]+\/?/;
    break;

  case 'facebook':
    regex = /^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/;
    break;

  case 'instagram':
    regex = /^(https?:\/\/)?([\w.]*)instagram\.com\/.*/;
    break;

  case 'youtube':
    regex = /^(https?:\/\/)?((w{3}\.)?)(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/;
    break;

  case 'vimeo':
    regex = /^(https?:\/\/)?((w{3}\.)?)(vimeo.com)\/(watch)?(\?v=)?(\S+)?/;
    break;

  case 'other':
    regex = /https?:\/\/(www\.)?[^\s.]+\.[^\s]{2,}/g;
    break;

  default:
    break;
  }

  return regex.test(string) && !/\s/g.test(string);
};

export const checkEmail = (email) => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return regex.test(email);
};

export const parseUrlString = (urlString) => {
  const urlRegex = /^(https?:\/\/)?((w{3}\.|m\.)?)(youtube.com|youtu.be|vimeo.com|hbr.org)\/(watch)?(\?v=)?(\S+)?/;
  const imageRegex = /(https?:\/\/.*\.(?:png|jpg|gif))/g;
  const embedRegex = /^<iframe\s/;

  if (urlString.match(embedRegex) !== null) {
    return 'embed';
  }

  const parsedArray = urlString.match(urlRegex) || urlString.match(imageRegex);

  if (parsedArray === null) return null;

  const [originalUrlString, , , , host, , , pathname] = parsedArray;

  if (!host && !pathname) {
    return Object.assign({}, { url: parsedArray[0], type: 'image' });
  }

  if (host && !pathname) {
    return Object.assign({}, { url: null, type: 'Invalid video' });
  }

  if (host.search('vimeo') > -1) {
    const url = `https://player.vimeo.com/video/${pathname}`;

    return Object.assign({}, { url: url, type: 'iframe' });
  }

  if (host.search('hbr') > -1 && pathname.search('embed') > -1) {
    return Object.assign({}, { url: originalUrlString, type: 'iframe' });
  }

  if (host.search('youtube') > -1 && pathname.search('embed') > -1) {
    const url = `https://www.youtube.com/${pathname}`;

    return Object.assign({}, { url: url, type: 'iframe' });
  }

  if (
    (host.search('youtube') > -1 || host.search('youtu') > -1) &&
    pathname.search('embed') === -1
  ) {
    const url = `https://www.youtube.com/embed/${pathname}`;

    return Object.assign({}, { url: url, type: 'iframe' });
  }

  if (host.search('hbr') > -1) {
    const embedPathnameArray = pathname.split('/');

    embedPathnameArray.splice(1, 0, 'embed');

    const embedPathname = embedPathnameArray.join('/');
    const url = `https://hbr.org/${embedPathname}`;

    return { url, type: 'iframe' };
  }
};

export const isOverflown = (element) => {
  if (element) return element.scrollHeight > element.clientHeight;

  return false;
};

export const loadForm = (forms, formId) =>
  forms.length > 0 && forms.includes(formId);

export const scrollToBottom = () => {
  window.scrollBy(0, (document.body.offsetHeight - window.pageYOffset) / 2);

  const favConditionToLoadMoreItems =
    window.innerHeight + window.pageYOffset - 30 >= document.body.offsetHeight;

  if (!favConditionToLoadMoreItems)
    setTimeout(() => {
      scrollToBottom();
    }, 100);
};

export const scrollToTop = () => {
  window.scrollBy(0, (-1 * document.body.offsetHeight) / 4);

  if (window.pageYOffset > 0)
    setTimeout(() => {
      scrollToTop();
    }, 100);
};

export const scrollToTopOnChange = () => {
  window.scrollTo(0, 0);
};

export const handleKeyDown = (event, onEnter) => {
  if (event.key === 'Enter') return onEnter(event);

  if (event.key === 'Escape')
    return document.getElementById(event.target.id).blur();
};

export const formatFormDataArray = (formData, key, inputArray) => {
  if (inputArray.length === 0) {
    formData.append(key, []);
    return formData;
  }

  inputArray.forEach((element, index) => {
    if (typeof element === 'object') {
      for (const elemProp in element) {
        const elemValue = element[elemProp];

        formData.append(`${key}[${index}][${elemProp}]`, elemValue);
      }
    }

    if (typeof element === 'string') {
      formData.append(`${key}[${index}]`, element);
    }
  });

  return formData;
};

export const countWords = (s) => {
  s = s.replace(/(^\s*)|(\s*$)/gi, '');
  s = s.replace(/[ ]{2,}/gi, ' ');
  s = s.replace(/\n /, '\n');
  return s.split(' ').length;
};

export const difference = (object, base) => {
  function changes(object, base) {
    return transform(object, function (result, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] =
          isObject(value) && isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(object, base);
};

export const checkIfEnterHit = (keyCode) => {
  if (keyCode === 13) return true;
};

export const processString = (options) => {
  let key = 0;

  function processInputWithRegex(option, input) {
    if (!option.fn || typeof option.fn !== 'function') return input;

    if (!option.regex || !(option.regex instanceof RegExp)) return input;

    if (typeof input === 'string') {
      let regex = option.regex;
      let result = null;
      let output = [];

      while ((result = regex.exec(input)) !== null) {
        let index = result.index;
        let match = result[0];

        output.push(input.substring(0, index));
        output.push(option.fn(++key, result));

        input = input.substring(index + match.length, input.length + 1);
        regex.lastIndex = 0;
      }

      output.push(input);
      return output;
    } else if (Array.isArray(input)) {
      return input.map(chunk => processInputWithRegex(option, chunk));
    } else return input;
  }

  return function (input) {
    if (!options || !Array.isArray(options) || !options.length) return input;

    options.forEach(option => (input = processInputWithRegex(option, input)));

    return input;
  };
};

export const parseQuery = (queryString) => {
  const query = {};
  const pairs = (queryString[0] === '?'
    ? queryString.substr(1)
    : queryString
  ).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
};

export const buildBreadcrumbFullPath = (objectTree, needle, data = []) => {
  if (isEmpty(needle)) {
    return isEmpty(objectTree) ? data : objectTree.children;
  }

  if (isEmpty(objectTree) || isUndefined(objectTree.children)) {
    return data;
  }

  const findPath = (node, needle) => {
    if (node.id === needle.id) {
      return [node];
    } else {
      for (const child of node.children) {
        const childPath = findPath(child, needle);

        if (Array.isArray(childPath)) {
          childPath.unshift(child);

          return childPath;
        }
      }
    }
  };

  const foundPath = findPath(objectTree, needle);

  if (Array.isArray(foundPath)) {
    data.push(objectTree);
    data.push(...foundPath);
  }

  return data;
};

export const formattedAdminUsersListTableGroupsList = (groups) => {
  return chain(groups)
    .omitBy(value => value.id === '')
    .map('name')
    .join('<br/>')
    .value();
};

export const formatGroupsBy = (property, collection) =>
  chain(collection)
    .groupBy(property)
    .map((value, key) => ({ owner: key, groups: value }))
    .value();

export const generateBaseRequestUrl = (props) => {
  const {
    role,
    page,
    groupId,
    institutionId,
    isAuthUserSelectedInstitutionAdmin,
  } = props;

  let urlParamString = `group=${groupId}&page=${page}`;

  if (role === 'educator') {
    if (groupId === 'all-groups') {
      urlParamString = '';
      return;
    }

    return urlParamString;
  }

  if (isAuthUserSelectedInstitutionAdmin && groupId === 'all-groups') {
    urlParamString = `institution=${institutionId}&page=${page}`;
  }

  return urlParamString;
};

export const generateRequestUrl = (props) => {
  const { sort, filters } = props;

  let urlParamString = generateBaseRequestUrl(props);

  const sortParamsString = generateSortParams(sort);

  if (sortParamsString.length !== 0) {
    urlParamString = `${urlParamString}${sortParamsString}`;
  }

  const filterParamsString = generateFilterParams(filters);

  if (filterParamsString.length !== 0) {
    urlParamString = `${urlParamString}${filterParamsString}`;
  }

  return urlParamString;
};

const generateSortParams = (sort) => {
  let { column, order, orderBy } = sort;

  let sortParamsString = '';

  if (column.length !== 0) {
    column = orderBy === 'lastName' ? orderBy : column;
    const sortParam = order ? 'asc' : 'desc';

    sortParamsString = `&sort=${column}:${sortParam}`;
  }

  return sortParamsString;
};

const generateFilterParams = (filters) => {
  const { location, searchQuery, employmentType, employmentStatus } = filters;

  let filterParamsString = '';

  if (location.trim().length !== 0) {
    filterParamsString = `${filterParamsString}&location=${location.trim()}`;
  }

  if (searchQuery.trim().length !== 0) {
    filterParamsString = `${filterParamsString}&query=${searchQuery.trim()}`;
  }

  if (employmentType.length !== 0) {
    filterParamsString = `${filterParamsString}&employmentType=${employmentType.join()}`;
  }

  if (employmentStatus.length !== 0) {
    filterParamsString = `${filterParamsString}&employmentStatus=${employmentStatus.join()}`;
  }

  return filterParamsString;
};

export const prefixWithZero = (num) => {
  return Number(num)?.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
};

export const formatTextByArrayObj = (text, arrayObj) => {
  let result = text;

  forEach(arrayObj, (response, index) => {
    const regularExpressionIndex = new RegExp(`\\{${index - 1}\\}`, 'g');
    result = result.replace(regularExpressionIndex, response);
  });

  return result;
};

export function getFeedbackRating(feedback){
  return feedback.averageRating;
}

export function getSkillsRating(data){
  return data.avgRating;
}
