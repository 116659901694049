import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const TextArea = ({
  name,
  label,
  error,
  onChange,
  placeholder,
  value,
  rows,
  inputId,
  onKeyPress,
  autofocus,
  textareaRef,
  styles,
  disabled,
  note,
  autoHeight,
  redText,
  labelDescription
}) => {
  let wrapperClass = 'form-group';
  const textareaStyle = autoHeight ? Object.assign({}, styles, { height : 'auto'}) : styles;

  if (error && error.length > 0) {
    wrapperClass += ' ' + 'has-danger';
  }

  return (
    <div className={wrapperClass}>
      {labelDescription && <div>{labelDescription}</div>}

      {
        label &&
          <label className="form-control-label" htmlFor={name}>
            {label}
            {redText &&
              <span className="text-danger" style={{ marginLeft: 4, fontSize: 12 }}>
                {`(${redText})`}
              </span>}
          </label>
      }

      {
        note &&
          <span className={classNames(
            'note',
            'pl-1',
            {[note]:note}
          )}>
            ({note})
          </span>
      }

      <textarea
        ref={textareaRef || null}
        id={inputId}
        className="form-control"
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyPress}
        rows={rows || '5'}
        autoFocus={autofocus}
        style={textareaStyle}/>

      {error && <div className="form-control-feedback">{error}</div>}
    </div>
  );
};

TextArea.propTypes = {
  inputId: PropTypes.string,
  note: PropTypes.string,
  rows: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  labelDescription: PropTypes.element,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  textareaRef: PropTypes.func,
  error: PropTypes.string,
  autofocus: PropTypes.bool,
  styles: PropTypes.object,
  disabled: PropTypes.bool,
  redText: PropTypes.string,
  autoHeight: PropTypes.bool
};

export default TextArea;
