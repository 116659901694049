import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import RichEditor from '../../../plugins/RichEditor';

const blockTypes = [
  { label: 'Link', style: 'LINK', icon: 'fa-link' }
];

const inlineTypes = [
  { label: 'Bold', style: 'BOLD', icon: 'fa-bold'},
  { label: 'Italic', style: 'ITALIC', icon: 'fa-italic' },
  { label: 'Underline', style: 'UNDERLINE', icon: 'fa-underline' }
];

const styles = {
  editorWrapper: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    padding: '.5rem .5rem',
    borderRadius: 4,
    width: '100%'
  },
  withError: {
    border: '1px solid #d9534f',
    padding: '.5rem .5rem',
    borderRadius: 4,
  },
  addListItemGroup: {
    position: 'absolute',
    right: 10,
    fontSize: '.8rem'
  },
  errorText: {
    fontSize: 14,
    color: '#d9534f'
  },
  cardWrapper: { marginBottom: 15 }
};

class ThinkingBreakAnswerTextForm extends Component {

  static propTypes = {
    editingItemId: PropTypes.string,
    onChangeText: PropTypes.func.isRequired,
    errors: PropTypes.string,
    text: PropTypes.string
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      editListItem: {},
      resetEditorState: false,
      updateEditorState: false,
      editorState: EditorState.createEmpty(),
    };

    this.setEditorState = this.setEditorState.bind(this);
    this.getEditorState = editorState => this._getEditorState(editorState);
  }

  componentWillReceiveProps(nextProps){
    const { editingItemId, text } = this.props;
    const { editingItemId: nextId, text: nextText } = nextProps;
    if(nextId !== null && editingItemId !== nextId){
      const blocksFromHTML = convertFromHTML(nextProps.text);

      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );

      const editorState = EditorState.createWithContent(contentState);

      this.setState(
        { editorState, updateEditorState: true },
        () => this.setState({ updateEditorState: false }));
    }

    if(nextId === null && text !== nextText && nextText === ''){
      this.setState({ resetEditorState: true }, () => {
        if (this.state.resetEditorState) {
          this.setState({ resetEditorState: false });
        }
      });

    }
  }

  setEditorState() {
    return this.state.editorState;
  }

  _getEditorState(editorState) {
    const currentContent = editorState.getCurrentContent();

    if (! currentContent.hasText())
      return this.props.onChangeText('', '');

    const answerPlainText = currentContent.getPlainText();

    const errors = answerPlainText.length < 1 ? 'Must be at least of one character' : '';
    const text = stateToHTML(currentContent);

    this.setState({ editorState });
    this.props.onChangeText(text, errors);
  }

  render() {
    const { resetEditorState, updateEditorState } = this.state;
    const { errors } = this.props;
    const wrapperStyle = errors === '' ? styles.editorWrapper : styles.withError;

    return (
      <div className="col col-sm-12" style={styles.cardWrapper}>
        <label className="form-control-label">Answer</label>
        <div className="input-group">
          <div style={wrapperStyle}>
            <RichEditor
              resetEditorState={resetEditorState}
              updateEditorState={updateEditorState}
              blockTypes={blockTypes}
              inlineTypes={inlineTypes}
              placeholder="Enter Answer text..."
              setEditorState={this.setEditorState}
              getEditorState={this.getEditorState}/>
          </div>

          {errors !== '' && <span style={styles.errorText}>{errors}</span>}
        </div>
      </div>
    );
  }
}

export default ThinkingBreakAnswerTextForm;
