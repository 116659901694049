import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Validator from '../../validator';
import { loadForm, guid } from '../../utils';

import * as componentActions from '../../redux/actions/components';

import EducationSectionCard from '../../components/resume/sections/EducationSectionCard';
import EducationForm from '../../components/profile/forms/EducationForm';
import DeleteSectionItemConfirmModal from '../../components/resume/DeleteSectionItemConfirmModal';

class ResumeEducation extends Component {
  static propTypes = {
    education: PropTypes.object,
    onChangeSection: PropTypes.func.isRequired,
    onChangeSectionTitle: PropTypes.func.isRequired,
    onUnloadForm: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    resumeEducation: PropTypes.object.isRequired,
    onLoadForm: PropTypes.func.isRequired,
    forms: PropTypes.array,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      editingId: null,
      removeEducationId: null,
      education: Object.assign({}, this.props.education),
    };

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeMajor = this.onChangeMajor.bind(this);
    this.onChangeProgram = this.onChangeProgram.bind(this);
    this.onEditEducation = this.onEditEducation.bind(this);
    this.onRemoveEducation = this.onRemoveEducation.bind(this);
    this.onCancelRemoveEducation = this.onCancelRemoveEducation.bind(this);
    this.onConfirmRemoveEducation = this.onConfirmRemoveEducation.bind(this);
  }

  formatEndDate = (dateString) => {
    return dateString === '' ? null : dateString;
  };

  isValid(field = null) {
    const { noend, startdate } = this.state.education;
    let rules = {};
    if (noend === true){
      rules = {
        institution: ['required', 'minLength|3', 'maxLength|100'],
        insurl: ['unsafeurl'],
        gpa: ['lessThan|5]', 'isNumber'],
        startdate: ['required', 'startdateYear'],
      };
    } else {
      rules = {
        institution: ['required', 'minLength|3', 'maxLength|100'],
        insurl: ['unsafeurl'],
        gpa: ['lessThan|5]', 'isNumber'],
        startdate: ['required','startdateYear'],
        enddate: ['required','enddate', `beforeStart|${this.formatEndDate(startdate)}`]
      };
    }
    const { education, errors: prevErrors} = this.state;
    const validate = Validator.createValidator(rules, education, field);
    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    if (noend === true) {
      delete prevErrors['enddate'];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  onChangeTitle(event){
    const { value } = event.target;

    this.props.onChangeSectionTitle(value, 'education');
  }

  handleChange(event){
    let { name, type, checked, value } = event.target;
    const { education } = this.state;
    value = type === 'checkbox' ? checked : value;
    education[name] = value;

    this.setState({ education }, () => this.isValid(name));
    if (name === 'startdate') {
      this.isValid('enddate');
    }

  }

  onChangeMajor(value) {
    const { education } = this.state;

    let formattedValue = value && value.label;

    if(value && value.label.includes('Create option ')){
      const splat = value.label.split('Create option ');
      formattedValue = splat[1].replace(/"/g, '');
    }

    education['major'] = value ? formattedValue : '';
    this.setState({ education });
  }

  onChangeProgram(value) {
    const { education } = this.state;

    let formattedValue = value && value.label;

    if(value && value.label.includes('Create option ')){
      const splat = value.label.split('Create option ');
      formattedValue = splat[1].replace(/"/g, '');
    }

    education['program'] = value ? formattedValue : '';
    this.setState({ education });
  }

  onSave() {
    const { education, editingId } = this.state;
    const { resumeEducation } = this.props;

    if (!this.isValid())
      return;

    const newEducation = Object.assign({},{
      id: guid()
    }, education );

    const index = resumeEducation.educationArray.findIndex(skill => skill.id === editingId);

    const educationArray = editingId === null ? [
      ...resumeEducation.educationArray,
      newEducation
    ] : [
      ...resumeEducation.educationArray.slice(0, index),
      newEducation,
      ...resumeEducation.educationArray.slice(index+1)
    ];

    const data = Object.assign({}, resumeEducation,{
      educationArray
    });

    this.props.onChangeSection(data, 'education');
    this.onCancel();
  }

  onCancel() {
    const { unloadForm } = this.props.actions;
    this.clearFormField();
    unloadForm('editEducationForm');
  }

  clearFormField() {
    const education = {
      startdate: '',
      enddate: '',
      noend: false,
      program: '',
      major: '',
      gpa: '',
      institution: '',
      insurl: '',
    };

    this.setState({
      education,
      errors: {},
      editingId: null
    });
  }

  onEditEducation(event) {
    const { id } = event.target;
    const { loadForm } = this.props.actions;
    const { educationArray } = this.props.resumeEducation;
    const educationObject = educationArray.find(education => education.id === id);
    const education = Object.assign({}, this.state.education, educationObject);
    this.setState({
      education,
      editingId: id
    }, ()=> loadForm('editEducationForm'));
  }

  onRemoveEducation(event) {
    const { id: removeEducationId } = event.target;

    this.setState({ removeEducationId });
  }

  onConfirmRemoveEducation() {
    const { removeEducationId } = this.state;
    const { resumeEducation } = this.props;

    const educationArray = resumeEducation
      .educationArray
      .filter(education => education.id !== removeEducationId);

    const data = Object.assign({}, resumeEducation, { educationArray });

    this.props.onChangeSection(data, 'education');
    this.onCancelRemoveEducation();
  }

  onCancelRemoveEducation() {
    this.setState({ removeEducationId: null });
  }

  render() {
    const { resumeEducation, onLoadForm, forms } = this.props;
    const { education, errors, removeEducationId } = this.state;

    const isFormOpen = loadForm(forms, 'editEducationForm');
    const isConfirmModalOpen = removeEducationId !== null;

    return (
      <div className="resume-section create-resume-section-card">
        <div className="create-resume-title">
          <input
            type="text"
            value={resumeEducation.title}
            placeholder="Awards header"
            className="header-edit-textbox"
            onChange={this.onChangeTitle}/>
        </div>
        {! isFormOpen &&
          <div className="section-list-container">
            {resumeEducation.educationArray.map(education =>
              (<EducationSectionCard
                key={education.id}
                educationDetails={education}
                onEditEducation={this.onEditEducation}
                onRemoveEducation={this.onRemoveEducation}/>))}

            <button
              onClick={onLoadForm}
              data-form-name="editEducationForm"
              className="btn btn-primary w-100">
              Add Education
            </button>
          </div>}

        {isFormOpen &&
          <div className="resume-form-container">
            <EducationForm
              errors={errors}
              educationData={education}
              onChangeMajor={this.onChangeMajor}
              onChangeProgram={this.onChangeProgram}
              onSave={this.onSave}
              onCancel={this.onCancel}
              onChange={this.handleChange}/>
          </div>}

        {isConfirmModalOpen &&
          <DeleteSectionItemConfirmModal
            onCancel={this.onCancelRemoveEducation}
            onConfirm={this.onConfirmRemoveEducation}
            isOpen={isConfirmModalOpen}/>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const education = {
    startdate: '',
    enddate: '',
    noend: false,
    program: '',
    major: '',
    gpa: '',
    institution: '',
    insurl: '',
  };

  return {
    education,
    forms: state.components.forms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(componentActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResumeEducation);
