import PropTypes from 'prop-types';
import React from 'react';

const SkillChallenge = ({ challenge }) => {
  return (
    <div className="challenge-item">
      <div className="challenge-title">{challenge.title}</div>
      <a className="btn" href={challenge.fileUrl}>Accept Challenge</a>
    </div>
  );
};

SkillChallenge.propTypes = {
  challenge: PropTypes.object.isRequired
};

export default SkillChallenge;
