import initialState from './initialState';

import {
  RESET_SEARCH_REDUCER,
  CP_SEARCH_REQUEST,
  CP_SEARCH_SUCCESS,
  CP_SEARCH_FAILURE,
  GET_POST_SEARCH_RESULT_COMMENTS_REQUEST,
  GET_POST_SEARCH_RESULT_COMMENTS_SUCCESS,
  GET_POST_SEARCH_RESULT_COMMENTS_FAILURE,
  LIKE_POST_SEARCH_RESULT_REQUEST,
  LIKE_POST_SEARCH_RESULT_SUCCESS,
  LIKE_POST_SEARCH_RESULT_FAILURE,
  UNLIKE_POST_SEARCH_RESULT_REQUEST,
  UNLIKE_POST_SEARCH_RESULT_SUCCESS,
  UNLIKE_POST_SEARCH_RESULT_FAILURE,
  SAVE_SEARCH_RESULT_POST_COMMENT_REQUEST,
  SAVE_SEARCH_RESULT_POST_COMMENT_SUCCESS,
  SAVE_SEARCH_RESULT_POST_COMMENT_FAILURE,
  DELETE_SEARCH_RESULT_POST_COMMENT_REQUEST,
  DELETE_SEARCH_RESULT_POST_COMMENT_SUCCESS,
  DELETE_SEARCH_RESULT_POST_COMMENT_FAILURE,
  LIKE_SEARCH_RESULT_POST_COMMENT_REQUEST,
  LIKE_SEARCH_RESULT_POST_COMMENT_SUCCESS,
  LIKE_SEARCH_RESULT_POST_COMMENT_FAILURE,
  UNLIKE_SEARCH_RESULT_POST_COMMENT_REQUEST,
  UNLIKE_SEARCH_RESULT_POST_COMMENT_SUCCESS,
  UNLIKE_SEARCH_RESULT_POST_COMMENT_FAILURE,
  SHARE_RESULT_ITEM_POST,
  RESET_SHARE_RESULT_ITEM_POST,
  UPDATE_SEARCH_QUERY,
  FOLLOW_SEARCH_RESULT_PEOPLE_REQUEST,
  FOLLOW_SEARCH_RESULT_PEOPLE_SUCCESS,
  FOLLOW_SEARCH_RESULT_PEOPLE_FAILURE,
  UNFOLLOW_SEARCH_RESULT_PEOPLE_REQUEST,
  UNFOLLOW_SEARCH_RESULT_PEOPLE_SUCCESS,
  UNFOLLOW_SEARCH_RESULT_PEOPLE_FAILURE,
  LIKE_CP_PORTFOLIO_SEARCH_RESULT_REQUEST,
  LIKE_CP_PORTFOLIO_SEARCH_RESULT_SUCCESS,
  LIKE_CP_PORTFOLIO_SEARCH_RESULT_FAILURE,
  UNLIKE_CP_PORTFOLIO_SEARCH_RESULT_REQUEST,
  UNLIKE_CP_PORTFOLIO_SEARCH_RESULT_SUCCESS,
  UNLIKE_CP_PORTFOLIO_SEARCH_RESULT_FAILURE,
  LIKE_FILE_REQUEST,
  LIKE_FILE_SUCCESS,
  LIKE_FILE_FAILURE,
  UNLIKE_FILE_REQUEST,
  UNLIKE_FILE_SUCCESS,
  UNLIKE_FILE_FAILURE,
} from '../../constants';

const addComment = (state, data) => {
  const { data: stateData, isSubmitting } = state;
  const { wallPostId: id } = isSubmitting;

  const indexOfComment = state.data.posts.findIndex(item => item.id === id);

  const wallPostObject = Object.assign({}, stateData.posts[indexOfComment], {
    comment_count: stateData.posts[indexOfComment].comment_count + 1,
    comments: stateData.posts[indexOfComment].comments
      ? [...stateData.posts[indexOfComment].comments, data]
      : [data],
  });

  return Object.assign({}, state, {
    data: Object.assign({}, state.data, {
      posts: [
        ...state.data.posts.slice(0, indexOfComment),
        wallPostObject,
        ...state.data.posts.slice(indexOfComment + 1),
      ],
    }),
    isSubmitting: { status: false, wallPostId: null },
  });
};

export default function reducer(state = initialState.search, action) {
  switch (action.type) {
  case RESET_SEARCH_REDUCER:
    return Object.assign({}, initialState.search);

  case RESET_SHARE_RESULT_ITEM_POST:
    return Object.assign({}, state, { resultPostItemShare: {} });

  case SHARE_RESULT_ITEM_POST:
    return Object.assign({}, state, { resultPostItemShare: action.wallpost });

  case UPDATE_SEARCH_QUERY:
    return Object.assign({}, state, { query: action.query });

  case CP_SEARCH_REQUEST:
    return Object.assign({}, state, {
      type: action.id,
      query: action.query,
      isRequesting: true,
    });

  case CP_SEARCH_SUCCESS:
    return Object.assign({}, state, {
      data: Object.assign({}, state.data, {
        [state.type]: action.data._embedded.search,
      }),
      paginationData: {
        page: action.data.page,
        page_count: action.data.page_count,
        total_items: action.data.total_items,
      },
      isRequesting: false,
    });

  case CP_SEARCH_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case GET_POST_SEARCH_RESULT_COMMENTS_REQUEST:
    return Object.assign({}, state, {
      isRequestingComments: { status: true, wallPostIndex: action.index },
    });

  case GET_POST_SEARCH_RESULT_COMMENTS_SUCCESS: {
    const { wallPostIndex: index } = state.isRequestingComments;
    const objectToUpdate = Object.assign({}, state.data.posts[index], {
      comments: action.data._embedded.wall_comment,
    });

    return Object.assign({}, state, {
      data: Object.assign({}, state.data, {
        posts: [
          ...state.data.posts.slice(0, index),
          objectToUpdate,
          ...state.data.posts.slice(index + 1),
        ],
      }),
      isRequestingComments: { status: false, wallPostIndex: null },
    });
  }

  case GET_POST_SEARCH_RESULT_COMMENTS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequestingComments: { status: false, wallPostIndex: null },
    });

  case LIKE_POST_SEARCH_RESULT_REQUEST:
  case UNLIKE_POST_SEARCH_RESULT_REQUEST:
    return Object.assign({}, state, {
      isLiking: { status: true, wallPostIndex: action.index },
    });

  case LIKE_POST_SEARCH_RESULT_SUCCESS: {
    const { wallPostIndex: index } = state.isLiking;
    const objectToUpdate = Object.assign({}, state.data.posts[index], {
      like_count: state.data.posts[index].like_count + 1,
      liked: true,
    });

    return Object.assign({}, state, {
      data: Object.assign({}, state.data, {
        posts: [
          ...state.data.posts.slice(0, index),
          objectToUpdate,
          ...state.data.posts.slice(index + 1),
        ],
      }),
      isLiking: { status: false, wallPostIndex: null },
    });
  }

  case UNLIKE_POST_SEARCH_RESULT_SUCCESS: {
    const { wallPostIndex: index } = state.isLiking;
    const objectToUpdate = Object.assign({}, state.data.posts[index], {
      like_count: state.data.posts[index].like_count - 1,
      liked: false,
    });

    return Object.assign({}, state, {
      data: Object.assign({}, state.data, {
        posts: [
          ...state.data.posts.slice(0, index),
          objectToUpdate,
          ...state.data.posts.slice(index + 1),
        ],
      }),
      isLiking: { status: false, wallPostIndex: null },
    });
  }

  case LIKE_POST_SEARCH_RESULT_FAILURE:
  case UNLIKE_POST_SEARCH_RESULT_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isLiking: { status: false, wallPostIndex: null },
    });

  case SAVE_SEARCH_RESULT_POST_COMMENT_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: { status: true, wallPostId: action.id },
    });

  case SAVE_SEARCH_RESULT_POST_COMMENT_SUCCESS:
    return addComment(state, action.data);

  case SAVE_SEARCH_RESULT_POST_COMMENT_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: { status: true, wallPostId: null },
    });

  case DELETE_SEARCH_RESULT_POST_COMMENT_REQUEST:
    return Object.assign({}, state, {
      isDeletingPostComment: {
        status: true,
        id: action.id.commentId,
        wallPostId: action.id.wallPostId,
      },
    });

  case DELETE_SEARCH_RESULT_POST_COMMENT_SUCCESS: {
    const { data: stateData, isDeletingPostComment } = state;
    const { id: commentId, wallPostId } = isDeletingPostComment;

    const indexOfComment = stateData.posts.findIndex(
      item => item.id === wallPostId
    );

    const wallPostObject = Object.assign(
      {},
      stateData.posts[indexOfComment],
      {
        comment_count: stateData.posts[indexOfComment].comment_count - 1,
        comments: stateData.posts[indexOfComment].comments.filter(
          comm => comm.id !== commentId
        ),
      }
    );

    return Object.assign({}, state, {
      data: Object.assign({}, stateData, {
        posts: [
          ...state.data.posts.slice(0, indexOfComment),
          wallPostObject,
          ...state.data.posts.slice(indexOfComment + 1),
        ],
      }),
      isDeletingPostComment: { status: false, id: null, wallPostId: null },
    });
  }

  case DELETE_SEARCH_RESULT_POST_COMMENT_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeletingPostComment: { status: false, id: null, wallPostId: null },
    });

  case LIKE_SEARCH_RESULT_POST_COMMENT_REQUEST:
  case UNLIKE_SEARCH_RESULT_POST_COMMENT_REQUEST:
    return Object.assign({}, state, {
      isLikingPostComment: {
        status: true,
        wallPostId: action.id.wallPostId,
        index: action.id.index,
      },
    });

  case LIKE_SEARCH_RESULT_POST_COMMENT_SUCCESS: {
    const { wallPostId, index } = state.isLikingPostComment;
    const indexOfPost = state.data.posts.findIndex(
      item => item.id === wallPostId
    );
    const objectToUpdate = state.data.posts[indexOfPost];

    const updatedObject = Object.assign({}, objectToUpdate, {
      comments: [
        ...objectToUpdate.comments.slice(0, index),
        Object.assign({}, objectToUpdate.comments[index], {
          liked: true,
          likes: objectToUpdate.comments[index].likes + 1,
        }),
        ...objectToUpdate.comments.slice(index + 1),
      ],
    });

    return Object.assign({}, state, {
      data: Object.assign({}, state.data, {
        posts: [
          ...state.data.posts.slice(0, indexOfPost),
          updatedObject,
          ...state.data.posts.slice(indexOfPost + 1),
        ],
      }),
      isLikingPostComment: { status: false, index: null, wallPostId: null },
    });
  }

  case UNLIKE_SEARCH_RESULT_POST_COMMENT_SUCCESS: {
    const { wallPostId, index } = state.isLikingPostComment;
    const indexOfPost = state.data.posts.findIndex(
      item => item.id === wallPostId
    );
    const objectToUpdate = state.data.posts[indexOfPost];

    const updatedObject = Object.assign({}, objectToUpdate, {
      comments: [
        ...objectToUpdate.comments.slice(0, index),
        Object.assign({}, objectToUpdate.comments[index], {
          liked: false,
          likes: objectToUpdate.comments[index].likes - 1,
        }),
        ...objectToUpdate.comments.slice(index + 1),
      ],
    });

    return Object.assign({}, state, {
      data: Object.assign({}, state.data, {
        posts: [
          ...state.data.posts.slice(0, indexOfPost),
          updatedObject,
          ...state.data.posts.slice(indexOfPost + 1),
        ],
      }),
      isLikingPostComment: { status: false, index: null, wallPostId: null },
    });
  }

  case LIKE_SEARCH_RESULT_POST_COMMENT_FAILURE:
  case UNLIKE_SEARCH_RESULT_POST_COMMENT_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isLikingPostComment: { status: false, wallPostId: null, index: null },
    });

  case FOLLOW_SEARCH_RESULT_PEOPLE_REQUEST:
  case UNFOLLOW_SEARCH_RESULT_PEOPLE_REQUEST:
    return Object.assign({}, state, {
      isFollowing: { status: true, userId: action.id },
    });

  case FOLLOW_SEARCH_RESULT_PEOPLE_SUCCESS: {
    const { userId } = state.isFollowing;
    const index = state.data.people.findIndex(
      people => people.id === userId
    );
    const objectToUpdate = Object.assign({}, state.data.people[index], {
      isUserFollower: {
        status: true,
        connectId: action.data.record,
      },
    });

    return Object.assign({}, state, {
      data: Object.assign({}, state.data, {
        people: [
          ...state.data.people.slice(0, index),
          objectToUpdate,
          ...state.data.people.slice(index + 1),
        ],
      }),
      isFollowing: { status: true, userId: null },
    });
  }

  case UNFOLLOW_SEARCH_RESULT_PEOPLE_SUCCESS: {
    const { userId } = state.isFollowing;
    const index = state.data.people.findIndex(
      people => people.id === userId
    );
    const objectToUpdate = Object.assign({}, state.data.people[index], {
      isUserFollower: {
        status: false,
        connectId: '',
      },
    });

    return Object.assign({}, state, {
      data: Object.assign({}, state.data, {
        people: [
          ...state.data.people.slice(0, index),
          objectToUpdate,
          ...state.data.people.slice(index + 1),
        ],
      }),
      isFollowing: { status: true, userId: null },
    });
  }

  case FOLLOW_SEARCH_RESULT_PEOPLE_FAILURE:
  case UNFOLLOW_SEARCH_RESULT_PEOPLE_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isFollowing: { status: true, userId: null },
    });

  case LIKE_CP_PORTFOLIO_SEARCH_RESULT_REQUEST:
  case UNLIKE_CP_PORTFOLIO_SEARCH_RESULT_REQUEST:
    return Object.assign({}, state, {
      isLikingFile: {
        status: true,
        fileId: action.id,
      },
    });

  case LIKE_CP_PORTFOLIO_SEARCH_RESULT_SUCCESS: {
    const { data, isLikingFile } = state;
    const index = data.files.findIndex(
      item => item.id === isLikingFile.fileId
    );
    const updatedObject = Object.assign({}, data.files[index], {
      liked: true,
      like_count: data.files[index].like_count + 1,
    });

    return Object.assign({}, state, {
      data: Object.assign({}, data, {
        files: [
          ...data.files.slice(0, index),
          updatedObject,
          ...data.files.slice(index + 1),
        ],
      }),
      isLikingFile: { status: false, fileId: null },
    });
  }

  case UNLIKE_CP_PORTFOLIO_SEARCH_RESULT_SUCCESS: {
    const { data, isLikingFile } = state;
    const index = data.files.findIndex(
      item => item.id === isLikingFile.fileId
    );
    const updatedObject = Object.assign({}, data.files[index], {
      liked: false,
      like_count: data.files[index].like_count - 1,
    });

    return Object.assign({}, state, {
      data: Object.assign({}, data, {
        files: [
          ...data.files.slice(0, index),
          updatedObject,
          ...data.files.slice(index + 1),
        ],
      }),
      isLikingFile: { status: false, fileId: null },
    });
  }

  case LIKE_CP_PORTFOLIO_SEARCH_RESULT_FAILURE:
  case UNLIKE_CP_PORTFOLIO_SEARCH_RESULT_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isLikingFile: { status: false, fileId: null },
    });

  case LIKE_FILE_REQUEST:
  case UNLIKE_FILE_REQUEST:
    return Object.assign({}, state, {
      isLikingFile: {
        status: true,
        fileId: action.id,
      },
    });

  case LIKE_FILE_SUCCESS: {
    const { data, isLikingFile } = state;
    const index = data.files.findIndex(
      item => item.id === isLikingFile.fileId
    );

    let updatedObject = {};

    if (index >= 0) {
      updatedObject = Object.assign({}, data.files[index], {
        liked: true,
        like_count: data.files[index].like_count + 1,
      });
    }

    return Object.assign({}, state, {
      data: Object.assign({}, data, {
        files:
            index >= 0
              ? [
                ...data.files.slice(0, index),
                updatedObject,
                ...data.files.slice(index + 1),
              ]
              : [...data.files],
      }),
      isLikingFile: { status: false, fileId: null },
    });
  }

  case UNLIKE_FILE_SUCCESS: {
    const { data, isLikingFile } = state;
    const index = data.files.findIndex(
      item => item.id === isLikingFile.fileId
    );
    let updatedObject = {};

    if (index >= 0) {
      updatedObject = Object.assign({}, data.files[index], {
        liked: false,
        like_count: data.files[index].like_count - 1,
      });
    }

    return Object.assign({}, state, {
      data: Object.assign({}, data, {
        files:
            index >= 0
              ? [
                ...data.files.slice(0, index),
                updatedObject,
                ...data.files.slice(index + 1),
              ]
              : [...data.files],
      }),
      isLikingFile: { status: false, fileId: null },
    });
  }

  case LIKE_FILE_FAILURE:
  case UNLIKE_FILE_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isLikingFile: { status: false, fileId: null },
    });

  default:
    return state;
  }
}
