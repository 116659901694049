import PropTypes from 'prop-types';
import React from 'react';
import { checkHttp } from '../../utils';
import camelCase from 'lodash/camelCase';
import parser from 'style-to-object';
import Parser from 'html-react-parser';
import domToReact from 'html-react-parser/lib/dom-to-react';
import Linkify from 'react-linkify';

const CpHtmlParser = ({htmlString}) => {
  return (
    <span>
      {Parser(htmlString, {
        replace: (domNode) => {// eslint-disable-line
          if (domNode.name === 'a') {
            const { attribs, children } = domNode;
            const styleParsed = {};

            parser(attribs.style, (name, value) => {
              styleParsed[`${camelCase(name)}`] = value;
            });

            const urlString = checkHttp(attribs.href) ? attribs.href : `//${attribs.href}`;

            return (
              <a
                {...attribs}
                style={styleParsed}
                href={urlString}>
                {domToReact(children)}
              </a>
            );
          }

          if (domNode.type === 'text') {
            return (
              <Linkify properties={{target: '_blank'}}>
                {domNode.data}
              </Linkify>
            );
          }
        }
      })}
    </span>
  );
};

CpHtmlParser.propTypes = {
  htmlString: PropTypes.string
};

export default CpHtmlParser;
