import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { closeModal } from '../../redux/actions/components';
import { addJobsToTracker } from '../../redux/actions/job-matches';

import { selectModal } from '../../redux/selectors/components';
import {
  selectSelectedJobMatches,
  selectIsSubmittingJobMatch,
  selectSelectedJobMatchesObjectList
} from '../../redux/selectors/job-matches';

import moment from 'moment';

import { uniqueId } from '../../utils';

import Button from '../common/Button';

import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

const tableHeaderProps = [
  { label: 'Position', value: 'title' },
  { label: 'Posting Date', value: 'postingDate' },
  { label: 'Organization', value: 'company' },
  { label: 'Location', value: 'city' },
  { label: 'Employment Type', value: 'employmentType' },
  { label: 'Education Level', value: 'educationLevel' },
  { label: 'Description', value: 'description' },
  { label: 'Match Score', value: 'score' }
];

const employmentTypeOptions = [
  'Full-Time',
  'Part-Time',
  'Full-Time/Part-Time'
];

const educationLevelOptions = [
  'Secondary Education',
  'Vocational Education',
  'Bachelor',
  'Master',
  'Post-Master'
];

const CompareJobsModal = ({
  actions,
  modal,
  isSubmitting,
  selectedJobMatches,
  selectedJobMatchesObjectList
}) => {
  function closeModal() {
    actions.closeModal();
  }

  async function onSaveJobs() {
    const selectedCount = selectedJobMatches.length;

    const storeData = await actions.addJobsToTracker({ jobMatchesIds: selectedJobMatches });

    let toastrType = 'success';
    let successMessage = selectedCount === 1 ?
      'This job was successfully saved! See all your saved jobs in "Job Tracker".' :
      'These jobs were successfully saved! See all your saved jobs in "Job Tracker".';

    if (storeData.jobMatches.allDuplicatesSubmitted) {
      toastrType = 'warning';
      successMessage = 'You\'ve already saved these jobs. See all your saved jobs in "Job Tracker".';
    }

    toastr[toastrType]('', successMessage);
    actions.closeModal();
  }

  const isCompareJobsModalOpen = modal === 'compare-jobs';

  return (
    <Modal
      size="lg"
      className="modal-margin-top wall-post-error-modal"
      isOpen={isCompareJobsModalOpen}
      backdrop="static"
      toggle={closeModal}>
      <ModalHeader toggle={closeModal}/>
      <ModalBody>
        <div className="table-responsive">
          <table className="table">
            <tbody>
              {tableHeaderProps.map(header => (
                <tr key={header.value}>
                  <th>{header.label}</th>
                  {selectedJobMatchesObjectList.map((jobMatch) => {
                    switch(header.value) {
                    case 'title':
                      return (
                        <td key={uniqueId()}>
                          <a href={jobMatch.url} target="_blank">
                            {jobMatch.title.replace(/<[^>]+>/g, '')}
                          </a>
                        </td>
                      );
                    case 'postingDate':
                      return (
                        <td key={uniqueId()}>
                          {moment(jobMatch.postingDate.date).format('DD/MM/YYYY')}
                        </td>
                      );

                    case 'employmentType':
                      return (
                        <td key={uniqueId()}>
                          {employmentTypeOptions[parseInt(jobMatch.employmentType)-1]}
                        </td>
                      );

                    case 'educationLevel':
                      return (
                        <td key={uniqueId()}>
                          {educationLevelOptions[parseInt(jobMatch.educationLevel)-1]}
                        </td>
                      );

                    case 'city':
                      return (
                        <td key={uniqueId()}>
                          {jobMatch.city.replace(/<[^>]+>/g, '')}
                        </td>
                      );

                    case 'company':
                      return (
                        <td key={uniqueId()}>
                          {jobMatch.company}
                        </td>
                      );

                    case 'description':
                      return (
                        <td key={uniqueId()}>
                          {`${jobMatch.description.replace(/<[^>]+>/g, '').slice(0, 105)}...`}
                        </td>
                      );

                    default:
                      return (
                        <td key={uniqueId()}>{Math.round(jobMatch[header.value]/1*100)}</td>
                      );
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={onSaveJobs}
          disabled={isSubmitting}
          buttonClass="btn btn-primary"
          buttonText={`${isSubmitting ? 'Saving...' : 'Save Jobs'}`}/>
      </ModalFooter>
    </Modal>
  );
};

CompareJobsModal.propTypes = {
  modal: PropTypes.string,
  actions: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  selectedJobMatches: PropTypes.array.isRequired,
  selectedJobMatchesObjectList: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  modal: selectModal(state),
  isSubmitting: selectIsSubmittingJobMatch(state),
  selectedJobMatches: selectSelectedJobMatches(state),
  selectedJobMatchesObjectList: selectSelectedJobMatchesObjectList(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ closeModal, addJobsToTracker }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CompareJobsModal);
