import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ExperienceCard from '../../../components/profile/sections/ExperienceCard';

class ExperienceCardHandler extends Component {
  static propTypes = {
    experience: PropTypes.object.isRequired,
    isPublicProfile: PropTypes.bool.isRequired,
    onEdit: PropTypes.func
  }

  constructor(props, context) {
    super(props, context);

    this.onEditExperienceClick = this.onEditExperienceClick.bind(this);
  }

  onEditExperienceClick(event) {
    event.preventDefault();
    const { id } = this.props.experience;

    this.props.onEdit(id);
  }

  render() {
    const { isPublicProfile, experience } = this.props;

    return (
      <div className="profile-item__card-list">
        <ExperienceCard
          experience={experience}
          isPublicProfile={isPublicProfile}
          onEditExperienceClick={this.onEditExperienceClick}/>
      </div>
    );
  }
}

export default ExperienceCardHandler;
