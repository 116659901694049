import initialState from './initialState';

import {
  GET_RELATION_LIST_REQUEST,
  GET_RELATION_LIST_SUCCESS,
  GET_RELATION_LIST_FAILURE,
  GET_INVITE_TOKEN_REQUEST,
  GET_INVITE_TOKEN_SUCCESS,
  GET_INVITE_TOKEN_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.invite, action) {
  switch (action.type) {
  case GET_RELATION_LIST_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };
  case GET_RELATION_LIST_SUCCESS: {
    const relationList = action.data?._embedded?.relation;
    relationList?.map((data) => {
      data['value'] = data.id;
      data['label'] = data.relation;
      return data;
    });

    return {
      ...state,
      isRequesting: false,
      data: relationList,
    };
  }
  case GET_RELATION_LIST_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  case GET_INVITE_TOKEN_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };
  case GET_INVITE_TOKEN_SUCCESS: {
    return {
      ...state,
      data: action.data,
      isRequesting: false,
      // data: relationList,
    };
  }
  case GET_INVITE_TOKEN_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  default:
    return state;
  }
}
