import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { omit } from 'lodash';
import { toastr } from 'react-redux-toastr';

import * as registrationActions from '../../redux/actions/registration';
import * as componentsActions from '../../redux/actions/components';
import * as skillBuildersActions from '../../redux/actions/skill-builders';

import Validator from '../../validator';
// import Header from '../../components/home/HomeHeader';
// import Footer from '../../components/home/Footer';
// import HomeFooter from '../../components/home/HomeFooter';
// import CareerEducationModal from '../../components/career-education/CareerEducationModal';

// const ews_grad = require("../../assets/images/ews-diff-grad.png");
// const ews_studentlearning = require("../../assets/images/ews-diff-studentlearning.png");
// const ews_ico_showcase = require("../../assets/images/ews-img-showcase.png");
// const ews_ico_prove = require("../../assets/images/ews-img-prove.png");
// const ews_ico_build = require("../../assets/images/ews-img-build.png");

class TalentDevPro extends Component {
  static propTypes = {
    request: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    // isRequesting: PropTypes.bool.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      showRequestInviteModal: false,
      request: Object.assign({}, this.props.request)
    };

    this.onChange = this.onChange.bind(this);
    this.refNameField = this.refNameField.bind(this);
    this.requestInvite = this.requestInvite.bind(this);
    this.toggleRequestInviteModal = this.toggleRequestInviteModal.bind(this);
  }

  refNameField(name) {
    this.name = name;
  }

  isValid(field = null) {
    const { request, errors: prevErrors } = this.state;

    let validate = Validator.createValidator({
      firstName: ['required'],
      lastName: ['required'],
      jobTitle: ['required'],
      email: ['required', 'email'],
      phone: ['required'],
      organization: ['required']
    }, request, field);

    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  onChange(event) {
    const { name, value } = event.target;
    let { request } = this.state;

    request[name] = value;

    this.setState({ request }, () => this.isValid(name));
  }

  requestInvite(event) {
    event.preventDefault();

    let { request } = this.state;

    if (! this.isValid()) return false;

    const firstName = request.firstName.trim();
    const lastName = request.lastName.trim();
    const name = `${firstName} ${lastName}`;

    request.name = name;
    request.page = 'Career Education';
    request.type = parseInt(request.type);

    request = omit(request, ['firstName', 'lastName']);

    this.props.actions.requestInvite(request)
      .then(() => {
        this.toggleRequestInviteModal();
        toastr.success('Your request for an invitation was successfully submitted!');
      });
  }

  toggleRequestInviteModal(){
    this.setState({
      showRequestInviteModal: !this.state.showRequestInviteModal
    });
  }

  render() {
    // const { isRequesting } = this.props;
    // const { request, showRequestInviteModal, errors } = this.state;

    return (
      <div className="for-org non-logged-homepage">
        <Helmet title="Talent Development Professionals"/>
        {/* <Header isMobile={false}/> */}

        {/* <Footer/> */}
        <div className="talent-development">
          <header>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h1>
                    Empower the talent you serve to take charge of their own lifelong career success.
                  </h1>
                </div>
                <div className="col-lg-10 offset-lg-1">
                  <p>
                    CareerPrepped's Talent Developer Account is for teachers, trainers advisors, coaches, counselors, coordinators, workforce development specialists, and other professionals engaged in facilitating career readiness, work-based learning, and job placement programs.
                  </p>
                </div>
              </div>
            </div>
          </header>
          <article className="talent-developer-account">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="talent-developer-account_form">
                    <h2>Talent Developer Account</h2>
                    <div className="sponsor">Sponsored by MaxKnowledge</div>
                    <p>
                      This free account gives you and the talent you serve unlimited, lifetime access to the CareerPrepped community and <a href="">toolkit</a>.
                    </p>

                    <div className="row">
                      <form>
                        <div className="row form-group">
                          <div className="col-lg-6">
                            <input type="input" className="form-control" placeholder="First Name"/>
                          </div>
                          <div className="col-lg-6">
                            <input type="input" className="form-control" placeholder="Last Name"/>
                          </div>
                        </div>
                        <div className="row form-group">
                          <div className="col-lg-6">
                            <select className="form-control" placeholder="Role Type">
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                            </select>
                          </div>
                          <div className="col-lg-6">
                            <select className="form-control" placeholder="Organization Type">
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                            </select>
                          </div>
                        </div>
                        <div className="row form-group">
                          <div className="col-lg-6">
                            <input type="input" className="form-control" placeholder="Work Email"/>
                          </div>
                          <div className="col-lg-6 alt">
                            <input type="input" className="form-control" placeholder="Alternate Email"/>
                            <span className="tooltip-alt">?</span>
                          </div>
                        </div>
                        <div className="row form-group">
                          <div className="col-lg-6">
                            <input type="input" className="form-control" placeholder="Job Title"/>
                          </div>
                          <div className="col-lg-6">
                            <input type="input" className="form-control" placeholder="Organization"/>
                          </div>
                        </div>
                        <div className="account_form_submit-cont">
                          <button className="account-signup-btn"><strong>sign up for free</strong></button>
                        </div>
                        <p className="text-center">If you wish to adopt CareerPrepped organization wide, <a href="">Click here</a>.</p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
          <article className="people-are-saying">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h1>What People are Saying</h1>
                  <div className="row row-eq-height">
                    <div className="col-lg-4">
                      <div className="saying-item">
                        <span className="quote">“</span>
                        <p>
                          Most platforms simply teach skills and ask people to repeat information back or to pass a quiz. Careerprepped is more comprehensive. It empowers people to build, prove and showcase their skills so they're visible and verfiyable to employers. I also use it for my own teacher portfolio.
                        </p>
                        <div className="author-cont">
                          <div className="author">Amanda M.</div>
                          <div className="title">Career Navigator Instructor</div>
                        </div>

                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="saying-item">
                        <span className="quote">“</span>
                        <p>
                          CareerPrepped has been an essential component on my instruction. Many of my students expressed gratitude and satisfaction with the videos and microlessons within the CareerPrepped platform. I'm particularly impressed with the ability to build a Career Site so effortlessly!
                        </p>
                        <div className="author-cont">
                          <div className="author">Yolanda G.</div>
                          <div className="title">DECA Teacher</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="saying-item">
                        <span className="quote">“</span>
                        <p>
                          Careerprepped is easy to navigate and the tools in the toolkit are very useful. The interview prep videos cover so many questions normally asked in job interviews and really mimic what we do in our workshops. The resume and career site tools are also an effective way to build a professional presence.
                        </p>
                        <div className="author-cont">
                          <div className="author">Carolyn J.</div>
                          <div className="title">Director of Career Services</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
          {/* <HomeFooter /> */}
        </div>
        {/* {showRequestInviteModal &&
          <CareerEducationModal
            errors={errors}
            request={request}
            onChange={this.onChange}
            isRequesting={isRequesting}
            refNameField={this.refNameField}
            requestInvite={this.requestInvite}
            showRequestInviteModal={showRequestInviteModal}
            toggleRequestInviteModal={this.toggleRequestInviteModal} />} */}
      </div>
    );
  }
}
const mapStateToProps = ({ registration: { isRequesting } }) => {
  const request = {
    firstName: '',
    lastName: '',
    jobTitle: '',
    email: '',
    phone: '',
    organization: '',
    additionalInformation: ''
  };

  return {
    request,
    isRequesting
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({},componentsActions, skillBuildersActions, registrationActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TalentDevPro);
