import PropTypes from 'prop-types';
import React from 'react';
import Loading from '../common/Loading';
import WallList from './WallList';
import { pure } from 'recompose';

const WallComponent = ({ wallPosts, wallShare, callingAPI }) => {
  const { data, isRequesting, isDeleting } = wallPosts;

  if (isRequesting && data.length === 0) {
    return (
      <div style={{marginTop: 10}}>
        <Loading />
      </div>
    );
  }

  return (
    <WallList
      callingAPI={callingAPI}
      wallPosts={data}
      isDeleting={isDeleting}
      wallShare={wallShare}/>
  );
};

WallComponent.propTypes = {
  wallPosts: PropTypes.object.isRequired,
  wallShare: PropTypes.object,
  callingAPI: PropTypes.bool
};

export default pure(WallComponent);
