import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

const FileCommentForm = ({ profile, commentData, onChange, onComment, isSubmitting }) => {
  return (
    <div className="forums">
      <div className="post">
        <div className="primary-post">
          <div className="comment-form-container">
            <div className="left">
              <Link to={`/cp/${profile.vanity}`}>
                {
                  profile.avatar && profile.avatar !== '' ?
                    <img
                      className="header-image medium align-top"
                      src={profile.avatar}/>:
                    <div
                      className="header-image medium align-top initial-avatar">
                      {profile.name.slice(0,1)}
                    </div>
                }
              </Link>
            </div>
            <div className="comment-post right">
              <div id="comment-container">
                <textarea
                  name="comment"
                  className="form-control"
                  value={commentData.comment}
                  onChange={onChange}
                  placeholder="What are your thoughts on this portfolio file?"
                  rows="3"
                  cols="60"/>
                <button
                  disabled={commentData.comment === '' || isSubmitting}
                  onClick={onComment}
                  className="btn btn-primary float-right mt-2 mb-2">
                  {isSubmitting ? 'Commenting...': 'Comment'}
                </button>
              </div>
            </div>
          </div>
          <div className="clearfix"/>
        </div>
      </div>
    </div>
  );
};

FileCommentForm.propTypes = {
  commentData: PropTypes.object.isRequired,
  avatar: PropTypes.string,
  profile: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onComment: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired
};

export default FileCommentForm;
