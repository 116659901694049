import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import {
  SETTINGS_DATE_FORMAT,
  FEEDBACK_REQUESTED_DATE_FORMAT,
} from '../../../constants';
import { SimpleToolTipWrapper } from '../../common/ToolTipWrapper';

const BaseRequestedOn = (props) => {
  const rowDetails = props.rowData;
  return (
    <td scope="row">
      {`${moment(
        rowDetails?.feedbackRequestDate?.date,
        SETTINGS_DATE_FORMAT
      ).format(FEEDBACK_REQUESTED_DATE_FORMAT)}`}
      {rowDetails.feedbackRequestDate && (
        <SimpleToolTipWrapper
          id={`sortable-table-feedback-reminder-info-${rowDetails.feedbackRequestId}`}
          toolTipContent={
            <div className="inside-tooltip">
              {rowDetails?.resendOn &&
                `You were sent a reminder on ${`${moment(
                  rowDetails?.resendOn,
                  SETTINGS_DATE_FORMAT
                ).format(FEEDBACK_REQUESTED_DATE_FORMAT)}`}`}
            </div>
          }>
          <i className={rowDetails?.resendOn && 'fa fa-info-circle infoIcon'} />
        </SimpleToolTipWrapper>
      )}
    </td>
  );
};
  
BaseRequestedOn.propTypes = {
  rowData: PropTypes.object.isRequired,
};

export default BaseRequestedOn;