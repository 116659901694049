import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import { parseJSON, uniqueId } from '../../../utils';
import { toastr } from 'react-redux-toastr';
import Validator from '../../../validator';
import ThinkingBreakForm from '../../../components/skill-builders/admin/ThinkingBreakForm';
import * as sectionActions from '../../../redux/actions/sections';
import * as unitActions from '../../../redux/actions/skill-builders';
import * as componentsActions from '../../../redux/actions/components';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';

class ThinkingBreak extends Component {
  static propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    formType: PropTypes.object,
    actions:PropTypes.object.isRequired,
    unit: PropTypes.object.isRequired,
    units: PropTypes.array.isRequired,
    sections: PropTypes.array.isRequired,
    section: PropTypes.object,
    isSubmitting: PropTypes.bool.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    isDeleting: PropTypes.bool.isRequired
  };

  constructor(props, context) {
    super(props, context);

    let editorState = EditorState.createEmpty();

    if (props.section.title.length !== 0) {
      const blocksFromHTML = convertFromHTML(props.section.title);

      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );

      editorState = EditorState.createWithContent(contentState);
    }

    this.state = {
      errors: {},
      editorState,
      editingItemId: null,
      section: Object.assign({}, this.props.section)
    };

    this.onChangeQuestion = this.onChangeQuestion.bind(this);
    this.updateSectionState = this.updateSectionState.bind(this);
    this.saveSection = this.saveSection.bind(this);
    this.deleteListItem = this.deleteListItem.bind(this);
    this.deleteSection = this.deleteSection.bind(this);
    this.setTitle = this.setTitle.bind(this);
    this.setListItem = this.setListItem.bind(this);
    this.setSelectedOption = this.setSelectedOption.bind(this);
    this.onAddAnswer = this.onAddAnswer.bind(this);
    this.onUpdateAnswer = this.onUpdateAnswer.bind(this);
    this.onEditListItem = this.onEditListItem.bind(this);

    this.setEditorState = this.setEditorState.bind(this);
    this.getEditorState = editorState => this._getEditorState(editorState);
  }

  componentDidMount() {
    window.onbeforeunload = () => {
      return 'Changes you made might get lost!';
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = () => {
      return null;
    };
  }

  isValid(field = null) {
    const { section } = this.state;
    const rules = {
      'title': ['required', 'minLength|1'],
      'content.*.value': ['required', 'minLength|1'],
      'content.*.feedback': ['required', 'minLength|1']
    };

    const validate = Validator.createValidator(rules, section, field);
    const { isValid, errors } = validate;

    let errorsObj = Object.assign({}, this.state.errors, errors);

    if (field && isUndefined(errors[field])) {
      delete errorsObj[field];
    }

    if (!field && isEmpty(errors)) {
      errorsObj = errors;
    }

    this.setState({ errors: errorsObj });

    return isValid;
  }

  setEditorState() {
    return this.state.editorState;
  }

  _getEditorState(editorState) {
    const currentContent = editorState.getCurrentContent();

    if (! currentContent.hasText()) return;

    const title = currentContent.getPlainText();
    const section = Object.assign({}, this.state.section, { title });

    this.setState({ editorState, section }, () => this.isValid('title'));
  }

  setTitle(name, value) {
    const { section } = this.state;
    let newSection = Object.assign({}, section, { [name]: value });

    this.setState({ section: newSection }, () => this.isValid(name));
  }

  setSelectedOption(itemId) {
    const { section } = this.state;
    const correct = section.content.findIndex(item => item.itemId === itemId);
    const newSection = Object.assign({}, section, { correct });

    this.setState({ section: newSection });
  }

  setListItem(itemId, field, value) {
    const { section } = this.state;
    const name = field.split('.')[1];

    const newSection = Object.assign({}, section, {
      content: [
        ...section.content.map((item) => {
          if (item.itemId === itemId) {
            return Object.assign({}, item, { [name]: value });
          }

          return item;
        })
      ]
    });

    this.setState({ section: newSection });
    this.isValid(field);
  }

  updateSectionState(event) {
    let itemId;
    const { name, value } = event.target;

    if (name !== 'title') itemId = event.target.closest('.item').id;

    switch(name) {
    case 'title':
      this.setTitle(name, value);
      break;

    case 'correct':
      this.setSelectedOption(itemId);
      break;

    default:
      this.setListItem(itemId, name, value);
    }
  }

  onChangeQuestion(question, questionError){
    const { section: oldSection, errors } = this.state;
    const section = Object.assign({}, oldSection, { title: question });
    const newError = Object.assign({}, errors, { title : questionError});
    this.setState({ section, errors: newError });
  }

  formatData() {
    const { section } = this.state;
    const { title, sectiontype, correct } = section;
    let contentObj = {};
    let feedbackObj = {};

    section.content.forEach((item, index) => {
      const { value, feedback, correct } = item;

      contentObj[index] = value;
      feedbackObj[index] = feedback;

      if (! isUndefined(correct)) contentObj['correct'] = correct;
    });

    const contentData = {
      title,
      correct,
      content: contentObj,
      feedback: feedbackObj
    };

    const content = JSON.stringify(contentData);

    return { content, sectiontype, unit: this.props.unit.id };
  }

  saveSection(event) {
    event.preventDefault();

    if (! this.isValid()) return;

    if (this.state.section.correct.length === 0) {
      return toastr.error('Please assign the correct answer.');
    }

    if (this.state.section.content.length < 3) {
      return toastr.error('Quiz should contain at least 3 answers');
    }

    const { unit, units, section, sections, actions } = this.props;
    const formId = event.target.parentElement.id;
    const formIdArray = formId.split('_');
    const formIndex = parseInt(formIdArray[1]);
    const sectionData = this.formatData();

    if (section.id) {
      sectionData.id = section.id;

      const sectionIndex = sections.findIndex(sectionItem =>
        sectionItem.id === section.id);

      return actions.updateSection(sectionData, sectionIndex)
        .then(() => {
          toastr.success('Section Updated.');
          actions.unloadUpdateForm(String(section.id));
        });
    }

    const unitIndex = units.findIndex(unitItem => unitItem.id === unit.id);

    actions.saveSection(sectionData, unit, unitIndex)
      .then(() => {
        toastr.success('Section saved.');
        actions.unloadSectionForm({ id: formIndex });
      });
  }

  onEditListItem(event) {
    event.preventDefault();

    const itemId = event.target.closest('.item').id;
    this.setState({
      editingItemId: itemId
    });
  }

  deleteListItem(event) {
    event.preventDefault();

    const { section } = this.state;
    const { content } = section;
    const itemId = event.target.closest('.item').id;

    const indexofItem = content.findIndex(item => item.itemId === itemId);
    const itemisTheCorrectAnswer = indexofItem === section.correct;

    const newSection = Object.assign({}, section, {
      content: content.filter(item => itemId !== item.itemId),
      correct: itemisTheCorrectAnswer ? '' : section.correct
    });

    this.setState({ section: newSection });
  }

  onAddAnswer(value, feedback) {
    const { section: oldSection } = this.state;
    let content = oldSection.content;
    content.push({ itemId: uniqueId(), value, feedback });
    const section = Object.assign({}, oldSection, { content });

    this.setState({ section });
  }

  onUpdateAnswer(value, feedback) {
    const { section, editingItemId } = this.state;
    const newSection = Object.assign({}, section, {
      content: [
        ...section.content.map((item) => {
          if (item.itemId === editingItemId) {
            return Object.assign({}, item, { value, feedback });
          }

          return item;
        })
      ]
    });

    this.setState({ section: newSection, editingItemId: null });
  }

  

  deleteSection(event) {
    event.preventDefault();

    const { actions, section, unit, units } = this.props;

    actions.deleteSection(section.id)
      .then(() => {
        const sectionsArray = unit.sections.split(',');
        const sectionsList = [...sectionsArray.filter(id => id !== section.id)];

        const sectionsString = sectionsList.join();
        const unitData = Object.assign({}, unit);
        const unitIndex = units.findIndex(unitItem => unitItem.id === unit.id);

        unitData.sections = sectionsString;

        actions.updateUnit(unitData, unitIndex)
          .then(() => {
            toastr.success('Section deleted.');
            actions.unloadUpdateForm(String(section.id));
          });
      });
  }
  render() {
    const { errors, section, editingItemId } = this.state;
    const {
      isAdmin,
      onCancel,
      isSubmitting,
      isUpdating,
      formType,
      isDeleting
    } = this.props;

    return (
      <ThinkingBreakForm
        isAdmin={isAdmin}
        errors={errors}
        section={section}
        onCancel={onCancel}
        isSubmitting={isSubmitting}
        isUpdating={isUpdating}
        isDeleting={isDeleting}
        formType={formType}
        onChange={this.updateSectionState}
        onChangeQuestion={this.onChangeQuestion}
        onSave={this.saveSection}
        onDeleteListItem={this.deleteListItem}
        editingItemId={editingItemId}
        onEditListItem={this.onEditListItem}
        onDelete={this.deleteSection}
        onAddAnswer={this.onAddAnswer}
        onUpdateAnswer={this.onUpdateAnswer}
        setEditorState={this.setEditorState}
        getEditorState={this.getEditorState}/>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let section = {
    sectiontype: 'thinkingbreak',
    title: '',
    content: [],
    correct: ''
  };
  const { isSubmitting, isUpdating, isDeleting } = state.sections;

  if (ownProps.section) {
    let content = [];
    const { id, content: sectionContent } = ownProps.section;
    let data = parseJSON(sectionContent);
    const { title, correct, content: dataContent, feedback: dataFeedback } = data;

    Object.keys(dataContent).forEach((key) => {
      const itemId = uniqueId();
      const value = !isUndefined(dataContent) ? dataContent[key] : '';
      const feedback = !isUndefined(dataFeedback) ? dataFeedback[key] : '';

      let item = { itemId, value, feedback };

      content.push(item);
    });

    section = Object.assign({}, section, { id, title, content, correct });
  }

  return {
    section,
    unit: ownProps.unit,
    isSubmitting,
    units: state.skillBuilders.data,
    sections: state.sections.data,
    isUpdating: isUpdating.status,
    isDeleting: isDeleting.status
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    unitActions,
    sectionActions,
    componentsActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThinkingBreak);
