import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Validator from '../../validator';
import { loadForm, guid } from '../../utils';

import * as componentActions from '../../redux/actions/components';

import ProjectSectionCard from '../../components/resume/sections/ProjectSectionCard';
import ProjectForm from '../../components/profile/forms/ProjectForm';
import DeleteSectionItemConfirmModal from '../../components/resume/DeleteSectionItemConfirmModal';

class ResumeProjects extends Component {
  static propTypes = {
    projects: PropTypes.object,
    onChangeSection: PropTypes.func.isRequired,
    onChangeSectionTitle: PropTypes.func.isRequired,
    onUnloadForm: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    resumeProjects: PropTypes.object.isRequired,
    onLoadForm: PropTypes.func.isRequired,
    forms: PropTypes.array,
    onRemoveResumeSection: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      editingId: null,
      removeProjectId: null,
      title: props.resumeProjects.title,
      projects: Object.assign({}, this.props.projects),
      editorText: { description: this.props.projects }
    };

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onEditProject = this.onEditProject.bind(this);
    this.onRemoveProject = this.onRemoveProject.bind(this);
    this.onRichEditorChange = this.onRichEditorChange.bind(this);
    this.onCancelRemoveProject = this.onCancelRemoveProject.bind(this);
    this.onConfirmRemoveProject = this.onConfirmRemoveProject.bind(this);
  }

  formatEndDate = (dateString) => {
    return dateString === '' ? null : dateString;
  };

  isValid(field = null) {
    const { noend, startdate } = this.state.projects;
    let rules = {};
    if (noend === true) {
      rules = {
        title: ['required', 'minLength|6', 'maxLength|200'],
        description: ['required'],
        url: ['unsafeurl'],
        startdate: ['required', 'startdate'],
      };
    } else {
      rules = {
        title: ['required', 'minLength|6', 'maxLength|200'],
        description: ['required'],
        url: ['unsafeurl'],
        startdate: ['required', 'startdate'],
        enddate: ['required', 'startdate', `beforeStart|${this.formatEndDate(startdate)}`],
      };
    }
    const { projects, errors: prevErrors} = this.state;
    const validate = Validator.createValidator(rules, projects, field);
    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    if (noend === true) {
      delete prevErrors['enddate'];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  isValidDescription(field = null) {
    const rules = {
      description: ['required', 'minWords|6', 'maxWords|200']
    };
    const { editorText, errors: prevErrors } = this.state;
    const validate = Validator.createValidator(rules, editorText, field);
    const { errors, isValid } = validate;

    if (field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }
    this.setState({ errors: Object.assign({}, prevErrors, errors) });

    return isValid;
  }


  onChangeTitle(event){
    const { value } = event.target;
    this.props.onChangeSectionTitle(value, 'projects');
  }

  handleChange(event){
    let { name, type, checked, value } = event.target;
    const { projects } = this.state;
    value = type === 'checkbox' ? checked : value;
    projects[name] = value;

    this.setState({ projects }, () => this.isValid(name));

    if (name === 'startdate') {
      this.isValid('enddate');
    }

  }

  onRichEditorChange(description, ...params) {
    let { projects, editorText } = this.state;

    projects = Object.assign({}, projects, { description });
    editorText['description'] = params[2].getText().replace(/\r?\n|\r/gm, ' ');

    this.setState({ projects, editorText }, () => this.isValidDescription('description'));
  }

  onSave(){
    const { projects, editingId } = this.state;
    const { resumeProjects } = this.props;

    if (!this.isValid() || !this.isValidDescription())
      return;

    const newProject = Object.assign({},{
      id: guid()
    }, projects );

    const index = resumeProjects.projectsArray.findIndex(project => project.id === editingId);

    const projectsArray = editingId === null ? [
      ...resumeProjects.projectsArray,
      newProject
    ] : [
      ...resumeProjects.projectsArray.slice(0, index),
      newProject,
      ...resumeProjects.projectsArray.slice(index+1)
    ];

    const data = Object.assign({}, resumeProjects,{
      projectsArray
    });

    this.props.onChangeSection(data, 'projects');
    this.onCancel();
  }

  onCancel(){
    const { unloadForm } = this.props.actions;
    this.clearFormField();
    unloadForm('editProjectsForm');
  }

  clearFormField(){
    const projects = {
      title: '',
      description: '',
      url: '',
      startdate: '',
      enddate: '',
      noend: false
    };

    this.setState({
      projects,
      errors: {},
      editingId: null
    });
  }

  onEditProject(event){
    const { id } = event.target;
    const { loadForm } = this.props.actions;
    const { projectsArray } = this.props.resumeProjects;
    const projectObject = projectsArray.find(education => education.id === id);
    const projects = Object.assign({}, this.state.projects, projectObject);
    this.setState({
      projects,
      editingId: id
    }, () => loadForm('editProjectsForm'));
  }

  onRemoveProject(event){
    const { id: removeProjectId } = event.target;

    this.setState({ removeProjectId });
  }

  onConfirmRemoveProject() {
    const { removeProjectId } = this.state;
    const { resumeProjects } = this.props;

    const projectsArray = resumeProjects
      .projectsArray
      .filter(project => project.id !== removeProjectId);

    const data = Object.assign({}, resumeProjects, { projectsArray });

    this.props.onChangeSection(data, 'projects');
    this.onCancelRemoveProject();
  }

  onCancelRemoveProject() {
    this.setState({ removeProjectId: null });
  }

  render() {
    const { resumeProjects, onLoadForm, forms, onRemoveResumeSection } = this.props;
    const { projects, errors, removeProjectId } = this.state;

    const isFormOpen = loadForm(forms, 'editProjectsForm');
    const isConfirmModalOpen = removeProjectId !== null;

    return (
      <div className="resume-section create-resume-section-card">
        <div className="create-resume-title">
          <input
            type="text"
            value={resumeProjects.title}
            placeholder="Projects header"
            className="header-edit-textbox"
            onChange={this.onChangeTitle}/>
          <button
            onClick={onRemoveResumeSection}
            id="Project"
            className="btn btn-danger btn-sm">
            <i className="fa fa-close"/>
          </button>
        </div>
        {
          !isFormOpen &&
            <div className="section-list-container">
              {resumeProjects.projectsArray.map(project =>
                (<ProjectSectionCard
                  key={project.id}
                  projectDetails={project}
                  onEditProject={this.onEditProject}
                  onRemoveProject={this.onRemoveProject}/>))}
              <button
                onClick={onLoadForm}
                data-form-name="editProjectsForm"
                className="btn btn-primary w-100">
                Add Project
              </button>
            </div>
        }

        {isFormOpen &&
          <div className="resume-form-container">
            <ProjectForm
              resume
              errors={errors}
              project={projects}
              onSave={this.onSave}
              onCancel={this.onCancel}
              onChange={this.handleChange}
              onChangeDescription={this.onRichEditorChange}/>
          </div>}

        {isConfirmModalOpen &&
          <DeleteSectionItemConfirmModal
            onCancel={this.onCancelRemoveProject}
            onConfirm={this.onConfirmRemoveProject}
            isOpen={isConfirmModalOpen}/>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const projects = {
    title: '',
    description: '',
    url: '',
    startdate: '',
    enddate: '',
    noend: false
  };

  return {
    projects,
    forms: state.components.forms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(componentActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResumeProjects);
