import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';
import _flatten from 'lodash/flatten';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import * as badgeAssertionActions from '../../redux/actions/badge-assertion';
import * as badgeActions from '../../redux/actions/badges';
import * as skillsActions from '../../redux/actions/skills';
import * as filesActions from '../../redux/actions/files';
import * as componentActions from '../../redux/actions/components';
import {updateMyCareerPreppedStatus, getMyCareerPreppedStatus} from '../../redux/actions/my-careerprepped-status';

import LinkPortfolioFilesTab from '../../components/skill-builders/claim-badge/LinkPortfolioFilesTab';

class LinkPortfolioFilesModal extends Component {
  static propTypes = {
    files: PropTypes.array.isRequired,
    badges: PropTypes.object.isRequired,
    badge: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    selectedFile: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
    fileData: PropTypes.object,
    bakedBadgePage: PropTypes.bool,
    isOpen: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    isRequestingFiles: PropTypes.bool.isRequired,
    skillsCard: PropTypes.bool,
    getMyBadges: PropTypes.func,
    getMySkills: PropTypes.func,
    linkingSkill: PropTypes.bool,
    onLinkedSkill: PropTypes.func,
    filesCount: PropTypes.string,
    skills: PropTypes.object.isRequired,
    myskills: PropTypes.object.isRequired,
    selectedFiles: PropTypes.array.isRequired,
    onSelectFile: PropTypes.func.isRequired,
    onAddEvidence: PropTypes.func.isRequired,
    onDeleteLoadedFile: PropTypes.func.isRequired,
    onInsertLinkClicked: PropTypes.func.isRequired,
    showInsertTextBox: PropTypes.bool.isRequired,
    guidelinesTooltipOpen: PropTypes.bool.isRequired,
    onDropFile: PropTypes.func.isRequired,
    toggleGuidelinesTooltip: PropTypes.func.isRequired,
    onChangeSelectedFile: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      selectedFiles: [],
      activeTab: 0,
      privateFiles: [],
      noOfPubicFiles: 0
    };

    this.onTabChange = this.onTabChange.bind(this);
    this.onNext = this.onNext.bind(this);
    this.onPrev = this.onPrev.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
    this.closeLinkFilesRequest = this.closeLinkFilesRequest.bind(this);
  }

  componentWillMount() {
    const { actions, userId, isAuthenticated } = this.props;
    actions.clearFilesReducer();
    actions.fetchFiles(isAuthenticated, userId, 1, 'newest', '');
  }

  componentWillUnmount() {
    this.props.actions.clearFilesReducer();
  }

  onTabChange(id){
    return () => {
      this.setState({
        activeTab: id
      });
    };
  }

  closeLinkFilesRequest(){
    this.props.actions.closeModal();
    this.props.actions.resetSelectedBadges();
  }

  onNext(){
    const { actions, userId, fileData, isAuthenticated } = this.props;
    actions.clearFilesReducer();
    return actions.fetchFiles(isAuthenticated, userId, fileData.page + 1, 'newest', '');
  }

  onPrev(){
    const { actions, userId, fileData, isAuthenticated } = this.props;
    actions.clearFilesReducer();
    return actions.fetchFiles(isAuthenticated, userId, fileData.page - 1, 'newest', '');
  }

  onSelectPage(page){
    return () => {
      const { actions, userId, isAuthenticated } = this.props;
      actions.clearFilesReducer();
      return actions.fetchFiles(isAuthenticated, userId, page, 'newest', '');
    };
  }

  render() {
    const {
      activeTab,
      privateFiles,
      noOfPubicFiles,
    } = this.state;

    const {
      files,
      badge,
      fileData,
      badges,
      skills,
      isRequestingFiles,
      bakedBadgePage,
      isOpen,
      skillsCard,
      getMyBadges,
      getMySkills,
      linkingSkill,
      onLinkedSkill,
      errors,
      selectedFile,
      selectedFiles,
      onSelectFile,
      onAddEvidence,
      onDeleteLoadedFile,
      onInsertLinkClicked,
      showInsertTextBox,
      guidelinesTooltipOpen,
      onChangeSelectedFile,
      onDropFile,
      toggleGuidelinesTooltip
    } = this.props;

    const { isSubmitting } = badges;
    const showPrivateConfirmation = privateFiles.length > 0;
    const { unitSkill: { isUpdating: { status: isLinkingSkillFiles } } } = skills;
    const selectedBadgesFiles = badges.selectedBadges.filter(bad => bad.files && bad.files.length > 0).map(badge => badge.files);
    const linkedFileIds = _flatten(selectedBadgesFiles.map(skill => skill.map(nestedItem => nestedItem.id)));

    return(
      <Modal
        backdrop="static"
        className="portfolio-file-modal"
        size="lg"
        isOpen={isOpen}
        toggle={this.closeLinkFilesRequest}>
        <ModalHeader
          toggle={this.closeLinkFilesRequest}>
          Link Portfolio File(s)
        </ModalHeader>
        <ModalBody>
          <LinkPortfolioFilesTab
            badge={badge}
            noOfPubicFiles={noOfPubicFiles}
            privateFiles={privateFiles}
            showPrivateConfirmation={showPrivateConfirmation}
            activeTab={activeTab}
            files={files}
            onNext={this.onNext}
            onPrev={this.onPrev}
            onSelectPage={this.onSelectPage}
            fileData={fileData}
            selectFile={this.selectFile}
            selectedFiles={selectedFiles}
            linkedFiledIds={linkedFileIds}
            isRequestingFiles={isRequestingFiles}
            onTabChange={this.onTabChange}
            bakedBadgePage={bakedBadgePage}
            closeModal={this.closeLinkFilesRequest}
            skillsCard={skillsCard}
            getMyBadges={getMyBadges}
            getMySkills={getMySkills}
            linkingSkill={linkingSkill}
            onLinkedSkill={onLinkedSkill}
            errors={errors}
            selectedFile={selectedFile}
            onSelectFile={onSelectFile}
            onDeleteLoadedFile={onDeleteLoadedFile}
            onInsertLinkClicked={onInsertLinkClicked}
            showInsertTextBox={showInsertTextBox}
            guidelinesTooltipOpen={guidelinesTooltipOpen}
            onChangeSelectedFile={onChangeSelectedFile}
            onDropFile={onDropFile}
            toggleGuidelinesTooltip={toggleGuidelinesTooltip}/>
        </ModalBody>
        <ModalFooter>
          {privateFiles.length === 0 &&
            <div id="cancel-link">
              <button
                type="button"
                name="button"
                className="btn btn-secondary"
                style={{marginLeft: 0}}
                onClick={this.closeLinkFilesRequest}>
                Cancel
              </button>
              <button
                type="button"
                name="button"
                id="link-portfolio-files"
                className="btn btn-primary"
                style={{marginLeft: 10}}
                onClick={onAddEvidence}
                disabled={isSubmitting || isLinkingSkillFiles}>
                {isSubmitting || isLinkingSkillFiles ? 'Linking ...' : 'Link Portfolio File(s)'}
              </button>
            </div>}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { skills, files, badges, myskills, skillBuilders: { unit: { badge } } } = state;

  return {
    skills,
    badge,
    badges,
    myskills,
    files: files.data,
    fileData: files.fileData,
    isRequestingFiles: files.isRequesting,
    userId: state.auth.data.currentUser.id,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...filesActions,
      ...badgeActions,
      ...skillsActions,
      ...componentActions,
      ...badgeAssertionActions,
      updateMyCareerPreppedStatus,
      getMyCareerPreppedStatus,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LinkPortfolioFilesModal);
