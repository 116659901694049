import PropTypes from 'prop-types';
import React from 'react';

import _isUndefined from 'lodash/isUndefined';
import classNames from 'classnames';
import { findDOMNode } from 'react-dom';
import flow from 'lodash/flow';

import ItemTypes from '../../../constants/ItemTypes';
import { DragSource, DropTarget } from 'react-dnd';
import { Tooltip } from 'reactstrap';

const styles = {
  adminIcon: {
    cursor: 'pointer',
    color: '#ff5000',
    marginLeft: 10
  }
};

const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
      type: props.type,
      images: props.images
    };
  }
};

const cardTarget = {
  canDrop(props) {
    return !_isUndefined(props.moveCard);
  },

  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) return;

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

    if (! monitor.canDrop()) return;

    // Perform the action
    props.moveCard(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item!
    // Generally it's better to avoid mutations,
    // but it's good here for performance sake
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};

const ImageCard = ({
  id,
  url,
  index,
  description,
  isAdmin,
  openPhotoSwipe,
  onDeleteImage,
  isDragging,
  connectDragSource,
  connectDragPreview,
  connectDropTarget,
  isOver,
  tooltipOpen,
  toggleTooltip
}) => {
  let imgComponent;

  if (_isUndefined(openPhotoSwipe)) {
    imgComponent = (
      <div className="card">
        {isAdmin &&
          (<div className="card-header">
            <i
              id={id}
              className="fa fa-times-circle-o float-sm-right"
              aria-hidden="true"
              style={styles.adminIcon}
              onClick={onDeleteImage}/>

            {connectDragSource(<i
              style={{...styles.adminIcon, cursor: 'move'}}
              className="fa fa-arrows float-sm-right"
              aria-hidden="true"/>)}
          </div>)}

        <img src={url} className="img-fluid" alt={description}/>
        <div className="card-block">
          <p className="card-text">{description}</p>
        </div>
      </div>
    );
  }

  if (! _isUndefined(openPhotoSwipe)) {
    imgComponent = (
      <span hidden={index !== 0}>
        <img src={url} className="img-fluid" alt={description}/>

        <div id={`image-viewer-tooltip-${id}`} className="image-section-icon-open" onClick={openPhotoSwipe}>
          <i className="fa fa-expand"/>
        </div>
        <Tooltip
          placement="top"
          isOpen={tooltipOpen && index === 0}
          target={`image-viewer-tooltip-${id}`}
          toggle={toggleTooltip}>
          <div className="inside-tooltip">
            Enlarge
          </div>
        </Tooltip>
      </span>
    );
  }

  const cardClass = classNames({
    'col-lg-6': _isUndefined(openPhotoSwipe),
    'offset-lg-3': _isUndefined(openPhotoSwipe)
  });

  const opacity = isDragging ? 0 : 1;
  const border = isOver ? '2px dashed #928e8e' : 'none';

  let component = (
    <div className={cardClass} style={{opacity, border, marginBottom: 10}} id={`img-${index}`}>
      {imgComponent}
    </div>
  );

  if (! isAdmin) return component;

  return connectDropTarget(connectDragPreview(component));
};

ImageCard.propTypes = {
  id: PropTypes.string,
  url: PropTypes.string.isRequired,
  description: PropTypes.string,
  index: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  openPhotoSwipe: PropTypes.func,
  onDeleteImage: PropTypes.func,
  tooltipOpen: PropTypes.bool,
  toggleTooltip: PropTypes.func
};

export default flow(DragSource(
  ItemTypes.CARD,
  cardSource,
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  })),
DropTarget(ItemTypes.CARD, cardTarget, (connect, monitor) => ({
  isOver: monitor.isOver(),
  connectDropTarget: connect.dropTarget()
})))(ImageCard);
