import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const UnitSkill = ({ skill, selectSkill, selectedSkills }) => {
  const { id, name } = skill;

  return(
    <div 
      className={classNames(
        'skill-pill m-l-5',
        { 'linked': selectedSkills.indexOf(id) > -1}
      )}
      onClick={selectSkill(id)}>
      <input 
        className="link-cb" 
        type="checkbox" 
        onChange={selectSkill(id)}
        checked={selectedSkills.indexOf(id) > -1}
        aria-label="link"/>{name}
    </div>
  );
};

UnitSkill.propTypes = {
  skill: PropTypes.object.isRequired,
  selectSkill: PropTypes.func.isRequired,
  selectedSkills: PropTypes.array.isRequired
};

export default UnitSkill;
