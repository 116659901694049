import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../common/Button';

const AdminSectionHeader = ({
  drag,
  drop,
  hover,
  title,
  componentName,
  onEditClick,
  sectionId,
  headerStyles
}) => {
  const styles = {
    panelHeading: { minHeight: 30, backgroundColor: '#fafafa', marginBottom: 10 },
    heading: Object.assign(
      {},
      { textAlign: 'center', paddingRight: 40, color: 'black', paddingTop: 10 },
      headerStyles
    ),
    actionIcons: { position: 'absolute', display: 'flex', top: 54, right: 70 },
    editButton: {
      minWidth: 20,
      marginLeft: 10,
      border: 'none',
      padding: 2,
      minHeight: 'auto',
    }
  };

  function dragDropRef(node) {
    drag(drop(node));
  }

  return (
    <div className="card-header" style={styles.panelHeading} ref={dragDropRef}>
      <h3 style={styles.heading} id={`${componentName}_${sectionId}`}>
        {title}

        {(hover && hover.status && hover.componentName == componentName) &&
          <span style={styles.actionIcons}>
            <Button
              buttonClass="btn-secondary edit-button"
              styles={styles.editButton}
              onClick={onEditClick}
              buttonText={<i className="fa fa-pencil" aria-hidden="true"/>}/>

            <Button
              buttonClass="btn-secondary edit-button"
              styles={{...styles.editButton, cursor: 'move'}}
              buttonText={<i className="fa fa-arrows" aria-hidden="true"/>}/>
          </span>}
      </h3>
    </div>
  );
};

AdminSectionHeader.propTypes = {
  hover: PropTypes.object,
  title: PropTypes.string.isRequired,
  onEditClick: PropTypes.func,
  sectionId: PropTypes.string,
  componentName: PropTypes.string.isRequired,
  headerStyles: PropTypes.object,
  drag: PropTypes.func.isRequired,
  drop: PropTypes.func.isRequired
};

export default AdminSectionHeader;
