import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link, Outlet } from 'react-router-dom';
import { checkUrl } from '../../utils';

import * as authActions from '../../redux/actions/authentication';
import * as profileActions from '../../redux/actions/profile';
import * as webpageActions from '../../redux/actions/webpage';
import * as componentActions from '../../redux/actions/components';
import * as networksActions from '../../redux/actions/networks';
import * as routerActions from '../../redux/actions/router';
import * as cpStatusActions from '../../redux/actions/my-careerprepped-status';

import ProfileJumbotron from '../../components/profile/header/ProfileJumbotron';
import SocialLinksModal from '../../components/profile/sections/SocialLinksModal';
import BasicEditModal from './header/BasicEditModal';
import ConfirmModal from '../../components/portfolio/ConfirmModal';
import ContactInfo from '../../components/profile/connects/ContactInfo';

const tempSocialLink = [
  {
    id: 1,
    name: 'twitter',
    url: '',
    error: false
  },
  {
    id: 2,
    name: 'linkedin',
    url: '',
    error: false
  },
  {
    id: 3,
    name: 'github',
    url: '',
    error: false
  },
  {
    id: 4,
    name: 'facebook',
    url: '',
    error: false
  },
  {
    id: 5,
    name: 'instagram',
    url: '',
    error: false
  },
  {
    id: 6,
    name: 'youtube',
    url: '',
    error: false
  },
  {
    id: 7,
    name: 'vimeo',
    url: '',
    error: false
  },
  {
    id: 8,
    name: 'other',
    url: '',
    error: false
  }
];

class OwnerProfile extends Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired,
    networks: PropTypes.object.isRequired,
    isOwner: PropTypes.bool,
    isUpdating: PropTypes.bool,
    params: PropTypes.object,
    modal: PropTypes.string,
    isPublicProfile: PropTypes.bool.isRequired
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      showContactInfo:false,
      isSocialModalOpen: false,
      selectedSocial: 'twitter',
      loadedCoverPic: null,
      coverPicFile: null,
      isUpdateCoverDropdownOpen: false,
      isBasicEditModalOpen: false,
      linkArray: tempSocialLink
    };

    this.onToggleCoverDropdown = this.onToggleCoverDropdown.bind(this);
    this.onToggleSocialModal = this.onToggleSocialModal.bind(this);
    this.onToggleContactInfoModal = this.onToggleContactInfoModal.bind(this),
    this.selectSocialType = this.selectSocialType.bind(this);
    this.onChangeLink = this.onChangeLink.bind(this);
    this.onEditBasicInfo = this.onEditBasicInfo.bind(this);
    this.onSaveLinks = this.onSaveLinks.bind(this);
    this.onSelectNewCover = this.onSelectNewCover.bind(this);
    this.onSaveCoverPic = this.onSaveCoverPic.bind(this);
    this.clearBackgroundPicData = this.clearBackgroundPicData.bind(this);
    this.onRemoveBackground = this.onRemoveBackground.bind(this);
    this.onConfirmRemoveBackground = this.onConfirmRemoveBackground.bind(this);
    this.clearUnsaveChangesInLinksArray = this.clearUnsaveChangesInLinksArray.bind(this);
  }

  componentWillMount() {
    const { isOwner, actions } = this.props;

    if (isOwner) {
      actions.resetSocialLinksState();
    }
  }

  componentDidMount() {
    const [,,slug,subpath] = location.pathname.split('/');
    const { currentUser, actions, isOwner, isAuthenticated } = this.props;

    if(!isAuthenticated && subpath && subpath !== 'portfolio'){
      return actions.redirect(`/cp/${slug}`);
    }

    if(isOwner)
      actions.userSocialLinksRequest(currentUser.id);
  }

  componentWillReceiveProps(nextProps){
    const { isOwner, actions, currentUser, networks, isUpdating } = nextProps;
    const { isRequesting, data } = networks;
    const { data: networksData } = this.props.networks;

    if(nextProps.profile.background !== this.props.profile.background && !isUpdating){
      this.clearBackgroundPicData();
    }

    if(!isRequesting && networksData !== data){
      const networksArray = data.reduce(((accumulator, currentValue)=> [...accumulator, currentValue.name]), []);
      const newLinkArray = tempSocialLink.map((social) => {
        if(networksArray.includes(social.name)){
          const linkObject = data.find(soc => soc.name === social.name);
          return Object.assign({}, social, {
            url: linkObject.url
          });
        }
        return social;
      });

      this.setState({
        linkArray: newLinkArray
      });
    }

    if(isOwner !== this.props.isOwner && isOwner){
      actions.resetSocialLinksState();
      actions.userSocialLinksRequest(currentUser.id);
    }
  }

  onToggleSocialModal(){
    const { isSocialModalOpen } = this.state;

    this.setState({
      isSocialModalOpen: !isSocialModalOpen
    }, () => this.clearUnsaveChangesInLinksArray());
  }

  onToggleContactInfoModal(){
    const { modal, actions } = this.props;

    if (modal === 'contact-info') {
      actions.closeModal();
      return;
    }

    actions.openModal('contact-info');
  }

  onToggleCoverDropdown(){
    const { isUpdateCoverDropdownOpen } = this.state;

    this.setState({
      isUpdateCoverDropdownOpen: !isUpdateCoverDropdownOpen
    });
  }

  clearBackgroundPicData(){
    const { modal, actions } = this.props;
    const isConfirmRemoveBackgroundOpen = modal && modal === 'remove_background_confirm';

    this.setState({
      coverPicFile: null,
      loadedCoverPic: null
    }, () => {
      if(isConfirmRemoveBackgroundOpen){
        return actions.closeModal();
      }
    });
  }

  async onSaveCoverPic(){
    const { coverPicFile } = this.state;
    const { actions, profile } = this.props;
    const formData = new FormData();
    formData.append('background', coverPicFile);

    await actions.updateProfile(profile.id, formData);
    await actions.updateMyCareerPreppedStatus();
    actions.getMyCareerPreppedStatus();
  }

  clearUnsaveChangesInLinksArray(){
    const { data } = this.props.networks;
    const networksArray = data.reduce(((accumulator, currentValue)=> [...accumulator, currentValue.name]), []);
    const newLinkArray = tempSocialLink.map((social) => {
      if(networksArray.includes(social.name)){
        const linkObject = data.find(soc => soc.name === social.name);
        return Object.assign({}, social, {
          url: linkObject.url
        });
      }
      return social;
    });

    this.setState({
      selectedSocial: 'twitter',
      linkArray: newLinkArray
    });
  }

  selectSocialType(name){
    return () => {
      this.setState({
        selectedSocial: name
      });
    };
  }

  onEditBasicInfo(){
    this.setState({
      isBasicEditModalOpen: !this.state.isBasicEditModalOpen
    });
  }

  onSelectNewCover(files){
    const file = files[0];

    this.setState({
      loadedCoverPic: URL.createObjectURL(file),
      coverPicFile: file,
      isUpdateCoverDropdownOpen: false
    });
  }

  onChangeLink(event){
    const { value } = event.target;
    const { selectedSocial, linkArray } = this.state;
    const index = linkArray.findIndex(link => link.name === selectedSocial);
    const checked = checkUrl(value, selectedSocial.toLowerCase());

    const newLink = Object.assign({}, linkArray[index], {
      url: value,
      error: !checked && value.trim() !== ''
    });

    const newLinkArray = [
      ...linkArray.slice(0, index),
      newLink,
      ...linkArray.slice(index+1)
    ];

    this.setState({
      linkArray: newLinkArray
    });
  }

  onRemoveBackground(e){
    e.preventDefault();
    this.props.actions.openModal('remove_background_confirm');
    this.setState({
      isUpdateCoverDropdownOpen: false
    });
  }

  onConfirmRemoveBackground(){
    const { actions, profile } = this.props;

    actions.updateProfile(profile.id, {
      'background': ''
    });
  }

  onSaveLinks(){
    const { networks, actions, currentUser } = this.props;
    const { data: networksArray } = networks;
    const { selectedSocial, linkArray } = this.state;
    const networksNameArray = networksArray.
      reduce(((accumulator, currentValue)=> [...accumulator, currentValue.name]), []);
    const socialObj = linkArray.find(link => link.name === selectedSocial);

    const checked = checkUrl(socialObj.url, selectedSocial.toLowerCase());
    if(socialObj.url.trim() === '' && !checked){
      const index = linkArray.findIndex(link => link.name === selectedSocial);
      const newLink = Object.assign({}, linkArray[index], {
        error: true
      });

      const newLinkArray = [
        ...linkArray.slice(0, index),
        newLink,
        ...linkArray.slice(index+1)
      ];

      return this.setState({
        linkArray: newLinkArray
      });
    }

    const withErrorLinkArray = linkArray.filter(link => link.url.trim() !== '' && !checkUrl(link.url, link.name.toLowerCase()));

    if(withErrorLinkArray.length > 0){
      return this.setState({
        selectedSocial: linkArray.filter(link => link.error)[0].name
      });
    }

    const isUpdateMethod = networksArray.filter(net => net.name === selectedSocial).length > 0;

    if(isUpdateMethod){
      const data = {
        name: selectedSocial,
        url: socialObj.url
      };
      const networkId = networksArray.filter(net => net.name === selectedSocial)[0].id;
      if (event.target.id === 'delete') {
        return actions.deleteSocialLink(networkId).
          then(() => actions.userSocialLinksRequest(currentUser.id)).
          then(() => this.onToggleSocialModal());
      } else {
        return actions.updateSocialLink(networkId, data).
          then(() => this.onToggleSocialModal());
      }
    }

    const filteredArray = linkArray.filter(link => !networksNameArray.includes(link.name) && link.url.trim() !== '').
      map((fil) => {
        return{name: fil.name, url: fil.url};
      });


    const postData = {
      networks: filteredArray
    };

    actions.addSocialLink(postData).
      then(() => this.onToggleSocialModal());
  }

  render() {
    const {
      profile,
      currentUser,
      isOwner,
      networks,
      modal,
      isUpdating,
      isPublicProfile,
      isAuthenticated
    } = this.props;

    const {
      isSocialModalOpen,
      selectedSocial,
      linkArray,
      isBasicEditModalOpen,
      isUpdateCoverDropdownOpen,
      loadedCoverPic,
      coverPicFile,
    } = this.state;

    const showContactInfo = modal === 'contact-info';

    const link = linkArray.find(link => link.name === selectedSocial);
    const isConfirmRemoveBackgroundOpen = modal && modal === 'remove_background_confirm';

    return (
      <div>
        {isPublicProfile &&
          <div className="container">
            <p className="alert alert-info" style={{ marginTop: 20 }}>
              This is how your Career Site will be seen by others you've allowed to view your Career Site based on your <Link to="/settings">privacy settings</Link>. Those who are not allowed to view your
              Career Site will only see your background, Career Site photo, and name.
            </p>
          </div>}
        {
          isOwner && Object.keys(profile).length > 0 &&
            <section id="profile">
              <ProfileJumbotron
                isOwner={isOwner}
                onToggleSocialModal={this.onToggleSocialModal}
                onToggleContactInfoModal = {this.onToggleContactInfoModal}
                profile={profile}
                currentUser={currentUser}
                onEditBasicInfo={this.onEditBasicInfo}
                networks={networks}
                isUpdating={isUpdating}
                onSaveCoverPic={this.onSaveCoverPic}
                onRemoveBackground={this.onRemoveBackground}
                loadedCoverPic={loadedCoverPic}
                coverPicFile={coverPicFile}
                clearBackgroundPicData={this.clearBackgroundPicData}
                onToggleCoverDropdown={this.onToggleCoverDropdown}
                isUpdateCoverDropdownOpen={isUpdateCoverDropdownOpen}
                onSelectNewCover={this.onSelectNewCover}
                isPublicProfile={isPublicProfile}
                isAuthenticated={isAuthenticated}/>
              <div className="container">
                <div className="row">
                  <Outlet/>
                </div>
              </div>
            </section>
        }
        {
          isSocialModalOpen && isOwner &&
            <SocialLinksModal
              isOpen={isSocialModalOpen}
              link={link}
              isSubmitting={networks.isSubmitting || networks.isUpdating.status}
              onChangeLink={this.onChangeLink}
              selectSocialType={this.selectSocialType}
              selectedType={selectedSocial}
              toggleModal={this.onToggleSocialModal}
              onSaveLinks={this.onSaveLinks} />
        }
        {
          isBasicEditModalOpen &&
            <BasicEditModal
              isOpen={isBasicEditModalOpen}
              onCancel={this.onEditBasicInfo}/>
        }
        {
          isConfirmRemoveBackgroundOpen &&
            <ConfirmModal
              isOpen={isConfirmRemoveBackgroundOpen}
              modalHeaderText="Remove Background Photo"
              modalMessage="Are you sure you want to remove your background photo?"
              modalFooterButtonText="Remove"
              onCancel={this.props.actions.closeModal}
              onConfirm={this.onConfirmRemoveBackground}
              isConfirming={isUpdating}
              confirminginProgressText="Removing..."/>
        }

        {showContactInfo && <ContactInfo owner={profile}/>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { profile, auth, networks } = state;

  return {
    isUpdating: profile.isUpdating,
    modal: state.components.modal,
    currentUser: auth.data.currentUser,
    isAuthenticated: state.auth.isAuthenticated,
    profile: profile.data,
    networks
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({},
    profileActions,
    webpageActions,
    networksActions,
    routerActions,
    componentActions,
    authActions,
    cpStatusActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnerProfile);
