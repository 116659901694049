import React from 'react';

import { isEmpty, isUndefined } from 'lodash';

import PropTypes from 'prop-types';
import { Popover, PopoverBody } from 'reactstrap';

import Alert from '../auth/Alert';
import TextInput from '../common/TextInput';
import SelectInput from '../common/SelectInput';

const roleTypes = [
  {
    text: 'Teacher/Instructor/Trainer',
    value: 'Teacher/Instructor/Trainer'
  },

  {
    text: 'Advisor/Coach/Counselor',
    value: 'Advisor/Coach/Counselor'
  },

  {
    text: 'Administrator/Director/Coordinator',
    value: 'Administrator/Director/Coordinator'
  },

  {
    text: 'Workforce Developer/Agent/Manager',
    value: 'Workforce Developer/Agent/Manager'
  },

  {
    text: 'Other',
    value: 'Other'
  }
];

const organizationTypes = [
  {
    text: 'School/College/University',
    value: 'School/College/University'
  },

  {
    text: 'Training Services',
    value: 'Training Services'
  },

  {
    text: 'Coaching Services',
    value: 'Coaching Services'
  },

  {
    text: 'Workforce Development Board/Agency',
    value: 'Workforce Development Board/Agency'
  },

  {
    text: 'Professional Association',
    value: 'Professional Association'
  },

  {
    text: 'Other',
    value: 'Other'
  }
];

const coalitionMembers = [
  {
    text: 'ACCSC',
    value: 'ACCSC'
  },
  {
    text: 'DEAC',
    value: 'DEAC'
  },
  {
    text: 'Ohio State Board',
    value: 'Ohio State Board'
  },
  {
    text: 'ACTE',
    value: 'ACTE'
  },
  {
    text: 'CECU',
    value: 'CECU'
  },
  {
    text: 'IAF',
    value: 'IAF'
  },
  {
    text: 'Other',
    value: 'Other'
  }
];

const TalentDeveloperAccountForm = ({
  errors,
  onSubmit,
  onChange,
  onKeyPress,
  credentials,
  registration,
  onDismissAlert,
  displayAlertType,
  alternateEmailPopover,
  toggleAlternateEmailPopover
}) => {
  let alertHeading = 'Thank you for signing up!';
  let alertMessage = 'You\'re almost finished! Please check either your work or alternate email to complete your account creation. We\'ve copied your alternate email in case your work email blocks your invitation email. In your email, we\'ve provided a link to setup your password and sign in. If you don\'t see the email in either of your inboxes, please check any spam, junk, clutter, or promotions folders and search "CareerPrepped" in all email folders. If you still can\'t locate your email, contact support at careerprepped@maxknowledge.com and let us know you didn\'t get your sign up email.';

  if (! isUndefined(registration.error.errorType)) {
    alertHeading = 'There\'s a problem with the email entered.';
    alertMessage = registration.error.detail;

    if ((registration.error.errorType === 'DuplicateEducatorRegistrationException')) {
      alertHeading = 'Duplicate registration requests.';
    }
  }

  return (
    <form onSubmit={onSubmit} onKeyPress={onKeyPress} style={{ fontSize: 13 }}>
      <div className="row">
        {(! isEmpty(displayAlertType)) &&
          <Alert
            displayAlertType={displayAlertType}
            alertHeading={alertHeading}
            alertMessage={alertMessage}
            dismissAlert={onDismissAlert}/>}

        <div className="col-lg-6">
          <TextInput
            name="firstName"
            label="First Name"
            placeholder="First Name"
            value={credentials.firstName}
            onChange={onChange}
            error={errors.firstName}
            srOnly/>
        </div>
        <div className="col-lg-6">
          <TextInput
            name="lastName"
            label="Last Name"
            placeholder="Last Name"
            value={credentials.lastName}
            onChange={onChange}
            error={errors.lastName}
            srOnly/>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <SelectInput
            name="roleType"
            value={credentials.roleType}
            onChange={onChange}
            options={roleTypes}
            error={errors.roleType}
            defaultOption="Role Type"/>
        </div>
        <div className="col-lg-6">
          <SelectInput
            name="organizationType"
            value={credentials.organizationType}
            onChange={onChange}
            error={errors.organizationType}
            options={organizationTypes}
            defaultOption="Organization Type"/>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <TextInput
            name="workEmail"
            label="Work Email"
            placeholder="Work Email"
            value={credentials.workEmail}
            onChange={onChange}
            error={errors.workEmail}
            srOnly/>
        </div>
        <div className="col-lg-6">
          <div className="input-group">
            <TextInput
              name="alternateEmail"
              label="Alternate Email"
              placeholder="Alternate Email"
              value={credentials.alternateEmail}
              onChange={onChange}
              error={errors.alternateEmail}
              className="altEmail form-control"
              srOnly/>
            <Popover
              placement="left"
              isOpen={alternateEmailPopover}
              target="alternateEmailPopover" >
              <PopoverBody>
                If your work email blocks your invitation email, we copy your alternate email to finish signup
              </PopoverBody>
            </Popover>
            <a
              id="alternateEmailPopover"
              href="#"
              className="altEmailBtn"
              onClick={toggleAlternateEmailPopover}>
              <i className="fa fa-question-circle"/>
            </a>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <TextInput
            name="jobTitle"
            label="Job Title"
            placeholder="Job Title"
            value={credentials.jobTitle}
            onChange={onChange}
            error={errors.jobTitle}
            srOnly/>
        </div>
        <div className="col-lg-6">
          <TextInput
            name="organization"
            label="Organization"
            placeholder="Organization Name"
            value={credentials.organization}
            onChange={onChange}
            error={errors.organization}
            srOnly/>
        </div>

      </div>
      <div className="row">
        <div className="col-lg-6">
          <p>How did you hear about this opportunity?</p>
          <SelectInput
            onChange={onChange}
            name="coalitionMember"
            error={errors.coalitionMember}
            value={credentials.coalitionMember}
            defaultOption="Select Initiative Supporter"
            options={coalitionMembers}/>
        </div>
      </div>
      <div className="developer_account-submit">
        <button
          disabled={registration.isSubmitting}
          className="btn account-signup-btn" style={{'display': 'inline-block'}}>
          {registration.isSubmitting ?
            'Just a sec while we process your request' :
            'Sign up for a free CareerPrepped Talent Developer Account'}
        </button>
      </div>
    </form>
  );
};

TalentDeveloperAccountForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  registration: PropTypes.object.isRequired,
  errors: PropTypes.object,
  credentials: PropTypes.object,
  displayAlertType: PropTypes.string.isRequired,
  onDismissAlert: PropTypes.func.isRequired,
  alternateEmailPopover: PropTypes.bool.isRequired,
  toggleAlternateEmailPopover: PropTypes.func.isRequired
};

export default TalentDeveloperAccountForm;
