import initialState from './initialState';

import {
  SB_CATEGORY_REQUEST,
  SB_CATEGORY_SUCCESS,
  SB_CATEGORY_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.category, action) {
  switch (action.type) {
  case SB_CATEGORY_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case SB_CATEGORY_SUCCESS:
    return Object.assign({}, state, {
      data: action.data._embedded.categories,
      isRequesting: false,
    });

  case SB_CATEGORY_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });
  default:
    return state;
  }
}
