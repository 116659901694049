import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as searchActions from '../../redux/actions/search';
import * as routerActions from '../../redux/actions/router';

import { Link } from 'react-router-dom';
import PostResultComments from './PostResultComments';
import PostItemContainer from '../../components/dashboard/PostItemContainer';
import Timestamp from '../../components/skill-builders/discussions/Timestamp';

class PostResultContainer extends Component {
  static propTypes = {
    resultItem: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    search: PropTypes.object.isRequired,
    showMore: PropTypes.object,
    postIndex: PropTypes.number,
    onshowMore: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);

    this.onLike = this.onLike.bind(this);
    this.onCommentClick = this.onCommentClick.bind(this);
    this.onShareClick = this.onShareClick.bind(this);
  }

  onLike(){
    const { resultItem, postIndex, actions } = this.props;
    const data = {
      wall: resultItem.id,
      index: postIndex
    };

    if(resultItem.liked)
      return actions.unlikePostSearchResult(data);

    actions.likePostSearchResult(data);

  }

  onCommentClick(event) {
    event.preventDefault();
    const { actions, resultItem, postIndex } = this.props;

    if(resultItem.comments){
      return this.focusCommentForm();
    }

    actions.getPostSearchResultComments(resultItem.id, postIndex, 1).
      then(() => this.focusCommentForm());
  }

  focusCommentForm() {
    // focus on input field.
    let formId = `wall-comment-form-wallPostId-${this.props.resultItem.id}`;
    document.getElementById(formId).focus();
  }

  onShareClick(event){
    event.preventDefault();
    const { resultItem, actions } = this.props;

    actions.shareResultItemPost(resultItem);
  }

  render() {
    const { resultItem, showMore, onshowMore, search, postIndex } = this.props;
    const { user, sharedEntityType, comments, liked, like_count, posted, comment_count, permissions } = resultItem;
    const { isLiking } = search;
    const isSharedPost = sharedEntityType !== '';
    let postType = null;

    switch(sharedEntityType){

    case 'interviewResource':
      postType = 'video';
      break;

    case 'wall':
      postType = 'post';
      break;

    case 'badgeIssued':
      postType = 'badge';
      break;

    case 'file':
      postType = 'portfolio file';
      break;

    case 'discussion':
      postType = 'discussion';
      break;

    default:
      postType = 'post';
      break;
    }

    let likeBtnText = liked ? 'Unlike': 'Like';

    if (isLiking.status && isLiking.wallPostIndex === postIndex) {
      likeBtnText = `${likeBtnText.slice(0, -1)}ing...`;
    }

    const likedComponent = liked ?
      (<a
        className="clickable"
        onClick={this.onLike}>
        <span className="liked">
          <i className="fa fa-heart" />
          {likeBtnText}
        </span>
      </a>) :
      (<a
        className="like-btn"
        onClick={this.onLike}>
        <i className="fa fa-heart" />
        <span>
          {likeBtnText}
        </span>
      </a>);

    return (
      <div className="forums col-lg-12">
        <div className="post">
          <div className="primary-post">
            <div className="left">
              <Link to={`/cp/${user.vanity}`}>
                {
                  user.avatar && user.avatar.length !== 0 ?
                    <img
                      className="header-image medium align-top"
                      src={user.avatar}
                      alt={`${user.name}'s Avatar`}/>:
                    <span
                      className="header-image medium align-top initial-avatar">
                      {user.name.slice(0,1)}
                    </span>
                }
              </Link>
            </div>
            <div className="right">
              <div className="profile-title">
                <Link
                  style={{fontWeight: '500'}}
                  to={`/cp/${user.vanity}`}
                  className="profile-name">
                  {user.name}
                </Link>
                {isSharedPost && <span className="shared-type-text"> sharedasasdasdasdsd a {postType}</span>}
                <Timestamp postedAt={posted.date} visibilty={permissions}/>
              </div>
              <PostItemContainer
                showMore={showMore}
                onshowMore={onshowMore}
                wallPost={resultItem} />
              <div className="share-stats">
                <span>{like_count} Likes</span>
                <span style={{paddingLeft:5}}>
                  {comment_count} Comments
                </span>
              </div>
            </div>
            <div className="post-actions">
              <span style={{paddingRight:5}}>
                {likedComponent}
              </span>
              <span style={{marginLeft:5}}>
                <a href="#"
                  onClick={this.onCommentClick}>
                  <i className="fa fa-comment"/>
                  <span style={{paddingRight:5}}>Comment</span>
                </a>
              </span>
              <span style={{marginLeft:5}}>
                <a href="#"
                  onClick={this.onShareClick}>
                  <i className="fa fa-share"/>
                  <span style={{paddingRight:5}}>Share</span>
                </a>
              </span>
            </div>
          </div>
          <div>
            <PostResultComments
              wallPost={resultItem}
              wallPostIndex={postIndex}
              comments={comments}/>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    search: state.search,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, searchActions, routerActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(PostResultContainer);
