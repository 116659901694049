import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';

import { checkUrl, formatFormDataArray } from '../../utils';
import * as endorsementActions from '../../redux/actions/badge-endorsement';
import * as badgeActions from '../../redux/actions/badges';
import * as filesActions from '../../redux/actions/files';
import * as skillsActions from '../../redux/actions/skills';
import * as componentActions from '../../redux/actions/components';
import Validator from '../../validator';
import UploadPortfoliTab from '../../components/skill-badges/UploadPortfolioTab';
import { MAX_FILE_NO } from '../../utilConstants';
import {updateMyCareerPreppedStatus, getMyCareerPreppedStatus} from '../../redux/actions/my-careerprepped-status';

class UploadNewPortfolioTabConatiner extends Component {
  static propTypes = {
    selectedBadges: PropTypes.array.isRequired,
    userId: PropTypes.string.isRequired,
    actions: PropTypes.object.isRequired,
    isUploading: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    bakedBadgePage: PropTypes.bool,
    skillsCard: PropTypes.bool,
    getMyBadges: PropTypes.func,
    getMySkills: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      title: '',
      description: '',
      file: {},
      errors: {},
      url: '',
      imagePreviewUrl: '',
      imagePreviewText: '',
      guideLinesTooltipOpen: false,
      mimetype: null,
      showInsertTextBox: false,
      maxfileUploadError: false,
    };

    this.onDropFile = this.onDropFile.bind(this);
    this.onInsertLinkClicked = this.onInsertLinkClicked.bind(this);
    this.onDeleteLoadedFile = this.onDeleteLoadedFile.bind(this);
    this.onChange = this.onChange.bind(this);
    this.toggleGuidelinesTooltip = this.toggleGuidelinesTooltip.bind(this);
    this.linkfile = this.linkfile.bind(this);
  }

  isValid(field = null) {
    const validate = Validator.createValidator(
      {
        title: ['required', 'maxLength|60'],
        description: ['required', 'minLength|1', 'maxLength|250'],
        url: ['choseAtleastOne|file,url', 'url'],
        file: ['choseAtleastOne|file,url']
      },
      this.state,
      field
    );

    const { errors: prevErrors } = this.state;
    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  onChange(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    }, () => this.isValid(name));

    if (name === 'url') {
      if (checkUrl(value, 'youtube') || checkUrl(value, 'vimeo')) {
        this.setState({
          file: {},
          imagePreviewUrl: '',
          mimetype: null
        }, () => this.isValid(name));
      }
    }
  }

  onDropFile(files) {
    if(files[0].size > 8000000)
      return toastr.info('Sorry, this image exceeds the 8MB limit.');

    this.setupImagePreview(files[0]);
  }

  setupImagePreview(file) {
    this.setState({
      imagePreviewUrl: URL.createObjectURL(file),
      imagePreviewText: file.name,
      mimetype: file.type,
      file: file
    },() => this.isValid('file'));
  }

  onInsertLinkClicked(){
    const { showInsertTextBox } = this.state;

    this.setState({
      showInsertTextBox: !showInsertTextBox
    });
  }

  onDeleteLoadedFile(){
    this.setState({
      imagePreviewUrl: '',
      file: {},
      imagePreviewText: '',
      url: '',
      showInsertTextBox: false,
      mimetype: null
    });
  }

  toggleGuidelinesTooltip(){
    this.setState({
      guideLinesTooltipOpen: !this.state.guideLinesTooltipOpen
    });
  }

  createFormData(description, file, title, selectedVisibiltyId, externalurl, badges, skills) {
    let formData = new FormData();
    const badgeIssuedIds = badges.map(badge =>
      typeof badge === 'object' ? badge.issued.id : badge
    );
    const skillIdsArray = skills.map(skill =>
      typeof skill === 'object' ? skill.id : skill
    );

    formData.append('description', description);
    formData.append('title', title);
    formData.append('visibility', selectedVisibiltyId);

    if(badges.length === 1 && badges[0].type && badges[0].type === 'skill'){
      formData = formatFormDataArray(formData, 'skills', badgeIssuedIds);
      formData = formatFormDataArray(formData, 'badges', skillIdsArray);
    }else{
      formData = formatFormDataArray(formData, 'badges', badgeIssuedIds);
      formData = formatFormDataArray(formData, 'skills', skillIdsArray);
    }

    if (externalurl !== '') {
      formData.append('externalurl', externalurl);
      formData.append('section', 'portfolio');
      return formData;
    }

    if(file){
      formData.append('file', file);
      formData.append('section', 'portfolio');
    }

    return formData;
  }

  async linkfile() {
    const { selectedBadges, actions, bakedBadgePage, skillsCard } = this.props;
    let { description, file, title, url: externalurl } = this.state;

    const selectedFiles = selectedBadges.map(
      badge => badge.files
    );
    const maxfileUploadError = selectedFiles[0] && selectedFiles[0]?.length >= MAX_FILE_NO;

    if (!this.isValid() || maxfileUploadError){
      this.setState({maxfileUploadError});
      return;
    }
    await actions.linkNewPortfolioFile(
      this.createFormData(
        description,
        file,
        title,
        'public',
        externalurl,
        selectedBadges,
        []
      ),
      bakedBadgePage
    ).then(async (state) => {
      if (!skillsCard) {
        this.props.actions.updateSkillUnitFiles(state.files.data[0]);
      }

      await this.props.actions.updateMyCareerPreppedStatus();
      this.props.actions.getMyCareerPreppedStatus();
      this.props.actions.closeModal();
    });

    if (skillsCard) {
      this.props.getMyBadges(null, null, 1, null, true);
      this.props.getMySkills(null, null, 1, null, true);
    }
  }

  render() {
    const {
      errors,
      url,
      title,
      file,
      description,
      imagePreviewText,
      imagePreviewUrl,
      mimetype,
      guideLinesTooltipOpen,
      showInsertTextBox,
      maxfileUploadError,
    } = this.state;
    const { isUploading, closeModal } = this.props;

    const fileDetails = Object.assign({}, {
      title,
      description,
      mimetype,
      file,
      url,
      imagePreviewText,
      imagePreviewUrl,
      errors
    });

    return(
      <div>
        <UploadPortfoliTab
          fileDetails={fileDetails}
          toggleGuidelinesTooltip={this.toggleGuidelinesTooltip}
          guideLinesTooltipOpen={guideLinesTooltipOpen}
          onDropFile={this.onDropFile}
          showInsertTextBox={showInsertTextBox}
          onInsertLinkClicked={this.onInsertLinkClicked}
          onDeleteLoadedFile={this.onDeleteLoadedFile}
          onChange={this.onChange}
          maxfileUploadError={maxfileUploadError}/>
        <div id="cancel-link only-padding-top" className="modal-footer">
          <button
            type="button"
            name="button"
            className="btn btn-secondary"
            disabled={isUploading}
            onClick={closeModal}>
            Cancel
          </button>
          <button
            type="button"
            name="button"
            id="link-portfolio-files"
            className="btn btn-primary"
            disabled={isUploading}
            style={{marginLeft: 5}}
            onClick={this.linkfile}>
            {isUploading ? 'Linking...' : 'Link Portfolio File'}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { files, badges } = state;

  return {
    isUploading: files.isUploading.status,
    selectedBadges: badges.selectedBadges,
    userId: state.auth.data.currentUser.id
  };
};


const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...filesActions,
      ...badgeActions,
      ...skillsActions,
      ...componentActions,
      ...endorsementActions,
      updateMyCareerPreppedStatus,
      getMyCareerPreppedStatus,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadNewPortfolioTabConatiner);
