import React from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const GlobalCompetenceSurveySuccess = () => {
  const certificateLink = 'https://global-competence-lessons.s3.us-west-2.amazonaws.com/global-competence-learning-recognition.pdf';

  return(
    <div>
      <Helmet title="Global Competence Survey Success"/>
      <div className="content subnav essential">
        <div className="bread">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/resources">Career Resources</Link></li>
            <li className="breadcrumb-item" aria-current="page">
              <Link to="/resources/global-competence-modules">
                Global Competence Modules
              </Link>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <Link to="/resources/global-competence-survey">
                Global Competence Survey
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Global Competence Survey Success
            </li>
          </ol>
        </div>
      </div>

      <div className="content targeted-resume">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="mb-1">Global Competence Survey Success</h2>
            <p>Thank you for your participation in this learning opportunity! Click the button below to download your certificate of learning. Once downloaded, simply enter your name and date on the certificate and save it for your records.</p>

            <a
              target="_blank"
              href={certificateLink}
              className="btn btn-xlg orange-btn">
              Download My Certificate
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalCompetenceSurveySuccess;
