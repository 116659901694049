import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import _isEmpty from 'lodash/isEmpty';

import initialState from './initialState';
import {
  GET_EDUCATORS_REQUEST,
  GET_EDUCATORS_SUCCESS,
  GET_EDUCATORS_FAILURE,
  GET_INDUSTRY_REPS_REQUEST,
  GET_INDUSTRY_REPS_SUCCESS,
  GET_INDUSTRY_REPS_FAILURE,
  GET_PEERS_REQUEST,
  GET_PEERS_SUCCESS,
  GET_PEERS_FAILURE,
  GET_FOLLOWERS_REQUEST,
  GET_FOLLOWERS_SUCCESS,
  GET_FOLLOWERS_FAILURE,
  GET_MYSKILLS_REQUEST,
  GET_MYSKILLS_SUCCESS,
  GET_MYSKILLS_FAILURE,
  GET_MYBADGES_REQUEST,
  GET_MYBADGES_SUCCESS,
  GET_MYBADGES_FAILURE,
  GET_RATINGS_REQUEST,
  GET_RATINGS_SUCCESS,
  GET_RATINGS_FAILURE,
  CLEAR_MYSKILLS_SUCCESS,
  CLEAR_MYBADGES_SUCCESS,
  GET_REVIEWS_BREAKUP_REQUEST,
  GET_REVIEWS_BREAKUP_SUCCESS,
  GET_REVIEWS_BREAKUP_FAILURE,
  RESET_DEFAULT_SKILLS,
  GET_DEFAULT_SKILLS_REQUEST,
  GET_DEFAULT_SKILLS_SUCCESS,
  GET_DEFAULT_SKILLS_FAILURE,
  GET_MYSKILLSPAGE_REQUEST,
  GET_MYSKILLSPAGE_SUCCESS,
  GET_MYSKILLSPAGE_FAILURE,
  DELETE_SKILL_REQUEST,
  DELETE_SKILL_SUCCESS,
  DELETE_SKILL_FAILURE,
  LINK_EVIDENCE_FILES_REQUEST,
  LINK_EVIDENCE_FILES_SUCCESS,
  LINK_EVIDENCE_FILES_FAILURE,
  LINK_PORTFOLIO_FILE_REQUEST,
  LINK_PORTFOLIO_FILE_SUCCESS,
  LINK_PORTFOLIO_FILE_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.myskills, action) {
  switch (action.type) {
  case GET_EDUCATORS_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };
  case GET_EDUCATORS_SUCCESS: {
    let educators = action?.data?._embedded?.educators
      .filter(e => e?.username || e?.userName)
      .map((e) => {
        return {
          label: e?.username || e?.userName,
          value: e.id,
        };
      });
    educators = uniqBy(educators, 'value');
    return {
      ...state,
      isRequesting: false,
      paginationData: {
        page: action.data.page,
        pageCount: action.data.page_count
      },
      data: {
        ...state.data,
        educators: (action.data.page === 1) ?
          educators :
          [
            ...state.data.educators,
            ...educators
          ],
      },
    };
  }

  case GET_EDUCATORS_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  case GET_INDUSTRY_REPS_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };
  case GET_INDUSTRY_REPS_SUCCESS: {
    return {
      ...state,
      isRequesting: false,
      data: {
        ...state.data,
        industryReps: action?.data?.detail,
      },
    };
  }
  case GET_INDUSTRY_REPS_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  case GET_PEERS_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };
  case GET_PEERS_SUCCESS: {
    let peers = action.data._embedded.connect
      .filter(p => p.user.name)
      .map((p) => {
        return {
          label: p.user.name,
          value: p.user.id,
        };
      });
    peers = uniqBy(peers, 'value');
    return {
      ...state,
      isRequesting: false,
      data: {
        ...state.data,
        peers,
      },
    };
  }
  case GET_PEERS_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  case GET_FOLLOWERS_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };
  case GET_FOLLOWERS_SUCCESS: {
    let followers = action.data._embedded.connect
      .filter(p => p.user.name)
      .map((p) => {
        return {
          label: p.user.name,
          value: p.user.id,
        };
      });
    followers = uniqBy(followers, 'value');
    return {
      ...state,
      isRequesting: false,
      data: {
        ...state.data,
        followers,
      },
    };
  }
  case GET_FOLLOWERS_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  case GET_MYSKILLS_REQUEST:
    return {
      ...state,
      isRequesting: true,
      skillsUserId: action.id,
    };
  case GET_MYSKILLS_SUCCESS: {
    const myskills = [
      ...new Map(
        [...state.data?.skills, ...action.data._embedded.skills]
          .filter(skill => skill?.user?.id === state.skillsUserId).map(
            (skill) => {
              const times = skill.files
                .map(x => moment(x.modified.date || x.uploaded.date))
                .sort((x, y) => y - x);

              const endorse = skill.endorsements
                ?.map(x => moment(x.endorsement_date?.date))
                .sort((x, y) => y - x);
              return [
                skill['skill_id'] ? skill['skill_id'] : skill['id'],
                {
                  skill_id: skill.id ? skill.id : skill['skill_id'],
                  imageURL: skill.url ? skill.url : skill.imageURL,
                  name: skill.name,
                  reviewCount: 4,
                  visibility: skill.visibility,
                  lastEndorsementGivenOn: endorse?.length > 0 ? endorse[0].format('MM/DD/YYYY') : 'N/A',
                  lastPortfolioUploadedOn:
                    times.length > 0 ? times[0].format('MM/DD/YYYY') : 'N/A',
                  user: skill.user,
                  filesCount: skill.filesCount,
                  files: skill.files,
                  endorsements: skill.endorsements,
                },
              ];
            }
          )
      ).values(),
    ];

    const _links = action.data._links;

    return {
      ...state,
      isRequesting: false,
      data: {
        ...state.data,
        skills: myskills,
        skillLinks: _links,
        skillsCount: action.data.total_items || 0
      },
    };
  }
  case CLEAR_MYSKILLS_SUCCESS: {
    return {
      ...state,
      data: {
        ...state.data,
        skills: [],
      },
    };
  }

  case GET_MYSKILLS_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };
  case GET_MYBADGES_REQUEST:
    return {
      ...state,
      isRequesting: true,
      badgesUserId: action.id,
    };
  case GET_MYBADGES_SUCCESS: {
    const mybadges = [
      ...new Map(
        [...state.data?.badges, ...action.data?._embedded?.issued]
          .filter(badge => badge?.user?.id === state.badgesUserId).map(
            (badge) => {
              const times = badge.files
                .map(x => moment(x.modified.date || x.uploaded.date))
                .sort((x, y) => y - x);

              const endorse = badge.endorsements?.filter(data => data.endorsement_date)
                .map(x => moment(x.endorsement_date?.date))
                .sort((x, y) => y - x);

              return [
                badge['badge_id'] ? badge['badge_id'] : badge['id'],
                {
                  badge_id: badge.id ? badge.id : badge.badge_id,
                  imageURL: badge.url ? badge.url : badge.imageURL,
                  name: badge.name,
                  reviewCount: 4,
                  visibility: badge.visibility
                    ? badge.visibility
                    : +badge.issued?.visibility,
                  lastEndorsementGivenOn: endorse?.length > 0 ? endorse[0].format('MM/DD/YYYY') : 'N/A',
                  lastPortfolioUploadedOn:
                    times.length > 0 ? times[0].format('MM/DD/YYYY') : 'N/A',
                  createdAt: badge.createdAt
                    ? badge.createdAt
                    : badge.issued.issuedAt.date,
                  issuedID: badge.issuedID ? badge.issuedID : badge.issued.id,
                  user: badge.user,
                  filesCount: badge.fileCount,
                  files: badge.files,
                  endorsements: badge.endorsements,
                },
              ];
            }
          ).filter(skill => skill?.user?.id == action.skillsUserId)
      ).values(),
    ];
    const _links = action.data._links;

    return {
      ...state,
      isRequesting: false,
      data: {
        ...state.data,
        badges: mybadges,
        badgeLinks: _links,
        badgesCount: action.data.total_items || 0,
      },
    };
  }

  case CLEAR_MYBADGES_SUCCESS: {
    return {
      ...state,
      data: {
        ...state.data,
        badges: [],
      },
    };
  }

  case GET_MYBADGES_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  case GET_RATINGS_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };

  case GET_RATINGS_SUCCESS: {
    return {
      ...state,
      isRequesting: false,
      data: {
        ...state.data,
        ratings: !_isEmpty(action?.data?._embedded?.starRating) ?
          [action?.data?._embedded?.starRating[0]] :
          [...state.data.ratings]
      },
    };
  }

  case GET_RATINGS_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  case GET_REVIEWS_BREAKUP_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };
  case GET_REVIEWS_BREAKUP_SUCCESS: {
    const detail = action.data?.detail;
    const data = {
      labels: detail.map(res => res.relation),
      datasets: [
        {
          label: '',
          data: detail.map(res => parseFloat(res.avgRating)),
          backgroundColor: 'rgba(61, 95, 126, 1)',
          borderWidth: 1,
        }
      ]
    };
    return {
      ...state,
      isRequesting: false,
      data :{
        ...state.data,
        reviewsBreakupData : data
      }
    };
  }
  case GET_REVIEWS_BREAKUP_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  case GET_DEFAULT_SKILLS_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };

  case GET_DEFAULT_SKILLS_SUCCESS: {
    let {
      data: {
        page,
        page_count: pageCount,
        _embedded: { suggested: defaultSkills }
      }
    } = action;

    if (page && page !== 1) {
      defaultSkills = [...state.data.defaultSkills, ...defaultSkills];
    }

    return {
      ...state,
      isRequesting: false,
      data: {
        ...state.data,
        defaultSkills,
      },
      defaultSkillsPaginationData: {
        page,
        pageCount
      }
    };
  }
  case GET_DEFAULT_SKILLS_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  case RESET_DEFAULT_SKILLS:
    return {
      ...state,
      data: {
        ...state.data,
        defaultSkills: initialState.myskills.data.defaultSkills
      }
    };

  case GET_MYSKILLSPAGE_REQUEST:
    return {
      ...state,
      isRequesting: true,
      skillsUserId: action.id,
      error: initialState.myskills.error
    };
  case GET_MYSKILLSPAGE_SUCCESS: {
    if ( parseInt(action.data.detail.total_items) > 0 ) {
      const resourceTypeParam = action.data.detail.record[0]?.resourceType;
      const requestedPage = action.requestData.requestingPage;
      const isSearchQuery = action.requestData.isSearch;

      // concatenate only in case of pagination
      const dataElements = requestedPage === 1 ?
        [...action.data.detail.record] :
        [...state.data[`${resourceTypeParam}s`], ...action.data.detail.record];

      const myskills = [
        ...new Map(
          [...dataElements]
            .filter(skill => (skill?.user?.id || skill.userid) === state.skillsUserId).map(
              (skill) => {
                const times = skill.portfolioUploadAt ? moment(skill.portfolioUploadAt).format('MM/DD/YYYY') : 'N/A';
                let fileIds = skill?.filesIds?.split(',').map((val) => {
                  return {'id': val};
                });

                return [
                  skill['skill_id'] ? skill['skill_id'] : skill['id'],
                  {
                    skill_id: skill['skill_id'] ? skill['skill_id'] : skill.resourceType === 'skill' ? skill.id : null,
                    badge_id: skill?.badge_id ? skill?.badge_id : skill.resourceType === 'badge' ? skill.id : null,
                    imageURL: skill.url ? skill.url : skill.imageURL,
                    name: skill.name,
                    reviewCount: 4,
                    visibility: skill.resourceType === 'skill' ? skill.visibility : skill.visibility
                      ? skill.visibility
                      : +skill.issued?.visibility,
                    createdAt: skill.createdAt
                      ? skill.createdAt
                      : skill.issuedAt,
                    issuedID: skill.issuedID ? skill.issuedID : skill.badgeIssuedId,
                    lastEndorsementGivenOn: skill.lastEndorsementGivenOn ? skill.lastEndorsementGivenOn : skill?.endorsementDate ? skill?.endorsementDate?.date : 'N/A',
                    lastPortfolioUploadedOn:  skill.lastPortfolioUploadedOn ? skill.lastPortfolioUploadedOn : times,
                    porfolioRating: skill?.porfolioRating,
                    questionRespRating: skill?.questionRespRating,
                    user: !_isEmpty(skill.user) ? skill.user : {
                      id: skill.userid,
                      name: skill.username,
                    },
                    filesCount: parseInt(skill.filesCount),
                    totalReview: skill?.totalReview,
                    avgRating: skill?.avgRating,
                    files: fileIds,
                    endorsementCount: parseInt(skill.endorsementCount || 0),
                    endorsementDate: skill.endorsementDate,
                  },
                ];
              }
            )
        ).values(),
      ];

      const pages = action.data?.detail?.total_pages;
      const totalItems = isSearchQuery ? state.data[`${resourceTypeParam}sTotalItems`]: action.data?.detail?.total_items;
      return {
        ...state,
        isRequesting: false,
        data: {
          ...state.data,
          [`${resourceTypeParam}s`]: myskills,
          [`${resourceTypeParam}sTotalPage`]: pages,
          [`${resourceTypeParam}sTotalItems`]: totalItems,
        },
      };
    } else {
      const resourceTypeParam = action.requestData.resource;
      const isSearchQuery = action.requestData.isSearch;
      return {
        ...state,
        isRequesting: false,
        data: {
          ...state.data,
          [`${resourceTypeParam}s`]: [],
          [`${resourceTypeParam}sTotalPage`]: 0,
          [`${resourceTypeParam}sTotalItems`]: isSearchQuery ? state.data[`${resourceTypeParam}sTotalItems`] : 0,
        },
      };
    }
  }

  case GET_MYSKILLSPAGE_FAILURE:
    return Object.assign({}, state, {
      ...initialState.myskills,
      error: action.error
    });

  case DELETE_SKILL_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: action.id }
    });

  case DELETE_SKILL_SUCCESS:
    return Object.assign({}, state, {
      data: {
        ...state.data,
        skills: [
          ...state.data.skills.filter(skill => skill.skill_id !== state.isDeleting.id)
        ]
      },
      isDeleting: { status: false, id: null }
    });

  case DELETE_SKILL_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: { status: false, id: null }
    });

  case LINK_EVIDENCE_FILES_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, index: action.index }
    });

  case LINK_EVIDENCE_FILES_SUCCESS:
    return Object.assign({}, state, {
      data: {
        ...state.data,
        skills: [
          ...state.data.skills.slice(0, state.isUpdating.index),
          {
            ...state.data.skills[state.isUpdating.index],
            files: action.data.files,
            filesCount: action.data.filesCount
          },
          ...state.data.skills.slice(state.isUpdating.index + 1)
        ]
      },
      isUpdating: { status: false, index: null }
    });

  case LINK_EVIDENCE_FILES_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false, index: null }
    });

  case LINK_PORTFOLIO_FILE_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, index: action.index }
    });

  case LINK_PORTFOLIO_FILE_SUCCESS:
    return Object.assign({}, state, {
      data: {
        ...state.data,
        badges: [
          ...state.data.badges.slice(0, state.isUpdating.index),
          {
            ...state.data.badges[state.isUpdating.index],
            files: action.data.badges[0].files,
            filesCount: action.data.badges[0].fileCount
          },
          ...state.data.badges.slice(state.isUpdating.index + 1)
        ]
      },
      isUpdating: { status: false, index: null }
    });

  case LINK_PORTFOLIO_FILE_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false, index: null }
    });

  default:
    return state;
  }
}
