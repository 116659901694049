import { CALL_API } from '../../middlewares/api';

import {
  EDUCATION_REQUEST,
  EDUCATION_SUCCESS,
  EDUCATION_FAILURE,
  CREATE_EDUCATION_REQUEST,
  CREATE_EDUCATION_SUCCESS,
  CREATE_EDUCATION_FAILURE,
  UPDATE_EDUCATION_REQUEST,
  UPDATE_EDUCATION_SUCCESS,
  UPDATE_EDUCATION_FAILURE,
  DELETE_EDUCATION_REQUEST,
  DELETE_EDUCATION_SUCCESS,
  DELETE_EDUCATION_FAILURE,
} from '../../constants';

export const educationRequest = (userId) => {
  const endpoint = `user/education?user=${userId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [EDUCATION_REQUEST, EDUCATION_SUCCESS, EDUCATION_FAILURE],
    },
  };
};

export const saveEducation = (data) => {
  const endpoint = 'user/education';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        CREATE_EDUCATION_REQUEST,
        CREATE_EDUCATION_SUCCESS,
        CREATE_EDUCATION_FAILURE,
      ],
    },
  };
};

export const updateEducation = (index, data) => {
  const endpoint = `user/education/${data.id}`;

  return {
    [CALL_API]: {
      data,
      index,
      endpoint,
      method: 'put',
      authenticated: true,
      types: [
        UPDATE_EDUCATION_REQUEST,
        UPDATE_EDUCATION_SUCCESS,
        UPDATE_EDUCATION_FAILURE,
      ],
    },
  };
};

export const deleteEducation = (educationId) => {
  const endpoint = `user/education/${educationId}`;

  return {
    [CALL_API]: {
      endpoint,
      id: educationId,
      method: 'delete',
      authenticated: true,
      types: [
        DELETE_EDUCATION_REQUEST,
        DELETE_EDUCATION_SUCCESS,
        DELETE_EDUCATION_FAILURE,
      ],
    },
  };
};
