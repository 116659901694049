import React from 'react';

import PropTypes from 'prop-types';

import LazyLoadImage from '../../containers/common/lazy-load-image/LazyLoadImage';

const LinkPreview = ({ linkPreview }) => {
  const { url, title, image, description } = linkPreview;

  if (!image) {
    return (
      /*  If no metadata */
      <a className="shared-item" href={url} target="_blank">
        <div style={{cursor: 'pointer'}}>
          <div className="shared-item-container blank-state">
            <div className="shared-item-image">
              <i className="fa fa-solid fa-link fa-lg"/>
            </div>
            <div className="shared-item-description">
              <h6>{title}</h6>
              <div className="description">
                <p>{url}</p>
              </div>
            </div>
          </div>
        </div>
      </a>
    );
  }

  return (
    <a className="shared-item" href={url} target="_blank">
      <div style={{cursor: 'pointer'}}>
        <div className="shared-item-container">
          <div className="shared-item-image">
            <LazyLoadImage alt={title} srcLoaded={image}/>
          </div>
          <div className="shared-item-description">
            <h6>{title}</h6>
            <div className="description">
              <p>{description}</p>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

LinkPreview.propTypes = {
  linkPreview: PropTypes.object.isRequired
};

export default LinkPreview;
