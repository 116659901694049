import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

import Button from '../../common/Button';
import AcknowledgementListForm from './AcknowledgementListForm';
import UnitBadge from '../../../containers/skill-builders/admin/UnitBadge';
import UnitReflections from './UnitReflections';
import UnitDetails from './UnitDetails';

const availability = [
  {
    text: 'Available',
    value: true
  },
  {
    text: 'Not Available',
    value: false
  }
];

const UnitForm = ({
  unit,
  errors,
  isSubmitting,
  isUpdating,
  isDeleting,
  onChange,
  onSave,
  onDelete,
  onAddAcknowledgement,
  onDeleteAcknowledgement,
  handleKeyPress,
  onChangeDesc,
  descriptions,
  categories
}) => {

  return (
    <div className="card">
      <div className="card-block">
        <UnitDetails
          unit={unit}
          errors={errors}
          onChange={onChange}
          onChangeDesc={onChangeDesc}
          descriptions={descriptions}
          categories={categories}
          availability={availability}
          handleKeyPress={handleKeyPress}/>

        <UnitReflections
          unit={unit}
          errors={errors}
          onChange={onChange}
          handleKeyPress={handleKeyPress}/>

        {
          unit.id &&
            <div className="row">
              <div className="col-sm-12">
                <UnitBadge unit={unit}/>
              </div>
            </div>
        }

        <div className="row">
          <div className="col-sm-12">
            <AcknowledgementListForm
              errors={errors}
              acknowledgement={unit.acknowledgement}
              onChange={onChange}
              onDeleteAcknowledgement={onDeleteAcknowledgement}
              onAddAcknowledgement={onAddAcknowledgement}/>
          </div>
        </div>
        <div className="p-3">
          <div className="row justify-content-between">
            <div className="col-md-auto">
              <Button
                onClick={onDelete}
                disabled={isDeleting}
                buttonClass="btn-danger"
                styles={{marginLeft: 0}}
                buttonText={isDeleting ? 'Deleting...' : 'Delete'}/>
            </div>
            <div className="col-md-auto justify-content-end">
              <Link
                to={`/admin/skill-builders/${unit.slug}`}
                style={{minWidth: 77}}
                className="btn btn-secondary">
                Cancel
              </Link>
              <Button
                onClick={onSave}
                disabled={isSubmitting || isUpdating}
                buttonClass="btn-primary"
                buttonText={isSubmitting || isUpdating ? 'Saving...' : 'Save'}/>
            </div>    
          </div>
        </div>
      </div>
    </div>
  );
};

UnitForm.propTypes = {
  unit: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool,
  isUpdating: PropTypes.bool,
  isDeleting: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onAddAcknowledgement: PropTypes.func.isRequired,
  onDeleteAcknowledgement: PropTypes.func.isRequired,
  handleKeyPress: PropTypes.func,
  onChangeDesc: PropTypes.func,
  descriptions: PropTypes.array,
  categories: PropTypes.array.isRequired
};

export default UnitForm;
