import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchJobMatchSettings } from '../../redux/actions/job-match-settings';

import {
  selectIsRequestingJobMatchSettings
} from '../../redux/selectors/job-match-settings';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import JobMatches from './JobMatches';
import Loading from '../../components/common/Loading';

class JobMatch extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    actions: PropTypes.object.isRequired,
    myCareerPreppedStatus: PropTypes.object.isRequired,
    isRequestingJobMatchSettings: PropTypes.bool.isRequired
  };

  constructor(props, context) {
    super(props, context);
  }

  render() {
    const {
      myCareerPreppedStatus,
      isRequestingJobMatchSettings
    } = this.props;

    return (
      <div className="admin-dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="content jobboard">
                <Helmet title="Job Match"/>
                <div>
                  <h5>CareerPrepped Job Sourcer Tool</h5>
                  <p className="mb-4">
                    The AI-Powered Job Sourcer tool helps you discover thousands of live job ads from across the web to turn job leads into job orders for your students and grads, identify employer prospects for outreach efforts, or even curate your own unique list of job alerts to send to job seekers you support, fostering stronger connections and increasing placement success.
                  </p>
                </div>
                {(isRequestingJobMatchSettings || myCareerPreppedStatus.isRequesting) && <Loading/>}
                <JobMatches />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userId: state.auth.data.currentUser.id,
  myCareerPreppedStatus: state.myCareerPreppedStatus,
  isRequestingJobMatchSettings: selectIsRequestingJobMatchSettings(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchJobMatchSettings }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(JobMatch);
