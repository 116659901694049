import initialState from './initialState';

import {
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  COUNTRIES_FAILURE
} from '../../constants';

export default function reducer(state = initialState.countries, action) {
  switch (action.type) {
  case COUNTRIES_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case COUNTRIES_SUCCESS:
    return Object.assign({}, state, {
      isRequesting: false,
      page: action.data.page,
      pageCount: action.data.page_count,
      data: action.data._embedded.tree
    });

  case COUNTRIES_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false
    });

  default:
    return state;
  }
}
