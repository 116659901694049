import initialState from './initialState';

import {
  CREATE_JOB_MATCH_SETTINGS_REQUEST,
  CREATE_JOB_MATCH_SETTINGS_SUCCESS,
  CREATE_JOB_MATCH_SETTINGS_FAILURE,
  UPDATE_JOB_MATCH_SETTINGS_REQUEST,
  UPDATE_JOB_MATCH_SETTINGS_SUCCESS,
  UPDATE_JOB_MATCH_SETTINGS_FAILURE,
  JOB_MATCH_SETTINGS_REQUEST,
  JOB_MATCH_SETTINGS_SUCCESS,
  JOB_MATCH_SETTINGS_FAILURE,
  TOGGLE_JOB_MATCH_SETTINGS_INITIAL_SEARCH_REQUEST
} from '../../constants';

export default function reducer(state = initialState.jobMatchSettings, action) {
  switch (action.type) {
  case CREATE_JOB_MATCH_SETTINGS_REQUEST:
    return Object.assign({}, state, { isSubmitting: true });

  case CREATE_JOB_MATCH_SETTINGS_SUCCESS:
    return Object.assign({}, state, {
      data: action.data,
      isSubmitting: false
    });

  case CREATE_JOB_MATCH_SETTINGS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false
    });

  case UPDATE_JOB_MATCH_SETTINGS_REQUEST:
    return Object.assign({}, state, { isUpdating: true });

  case UPDATE_JOB_MATCH_SETTINGS_SUCCESS:
    return Object.assign({}, state, {
      data: action.data,
      isUpdating: false
    });

  case UPDATE_JOB_MATCH_SETTINGS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: false
    });

  case JOB_MATCH_SETTINGS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case JOB_MATCH_SETTINGS_SUCCESS:
    return Object.assign({}, state, {
      data: action.data,
      isRequesting: false
    });

  case JOB_MATCH_SETTINGS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false
    });

  case TOGGLE_JOB_MATCH_SETTINGS_INITIAL_SEARCH_REQUEST:
    return Object.assign({}, state, {
      data: { ...state.data, initialSearchRequest: action.initialSearchRequest }
    });

  default:
    return state;
  }
}
