import unionBy from 'lodash/unionBy';

import initialState from './initialState';

import {
  RESET_FILES_REDUCER,
  FILE_UPLOAD_REQUEST,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAILURE,
  DELETE_FILE_REQUEST,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_FAILURE,
  FETCH_FILE_REQUEST,
  FETCH_FILE_SUCCESS,
  FETCH_FILE_FAILURE,
  UPDATE_FILE_REQUEST,
  UPDATE_FILE_SUCCESS,
  UPDATE_FILE_FAILURE,
  RESET_RECENT_UPLOADED_FILE_ID,
  FETCH_UNIT_FILE_REQUEST,
  FETCH_UNIT_FILE_SUCCESS,
  FETCH_UNIT_FILE_FAILURE,
  SET_PREVIEW_FILE,
  CLEAR_PREVIEW_FILE,
  LINK_NEW_PORTFOLIO_FILE_TO_A_BADGE_REQUEST,
  LINK_NEW_PORTFOLIO_FILE_TO_A_BADGE_SUCCESS,
  LINK_NEW_PORTFOLIO_FILE_TO_A_BADGE_FAILURE,
  LINK_NEW_PORTFOLIO_FILE_TO_A_BAKED_BADGE_REQUEST,
  LINK_NEW_PORTFOLIO_FILE_TO_A_BAKED_BADGE_SUCCESS,
  LINK_NEW_PORTFOLIO_FILE_TO_A_BAKED_BADGE_FAILURE,
  SB_FILE_UPLOAD_REQUEST,
  SB_FILE_UPLOAD_SUCCESS,
  SB_FILE_UPLOAD_FAILURE,
  UNIT_FILE_COMMENTS_REQUEST,
  UNIT_FILE_COMMENTS_SUCCESS,
  UNIT_FILE_COMMENTS_FAILURE,
  ADD_UNIT_FILE_COMMENTS_REQUEST,
  ADD_UNIT_FILE_COMMENTS_SUCCESS,
  ADD_UNIT_FILE_COMMENTS_FAILURE,
  UPDATE_FILE_COMMENTS_REQUEST,
  UPDATE_FILE_COMMENTS_SUCCESS,
  UPDATE_FILE_COMMENTS_FAILURE,
  DELETE_FILE_COMMENT_REQUEST,
  DELETE_FILE_COMMENT_SUCCESS,
  DELETE_FILE_COMMENT_FAILURE,
  DELETE_FILE_COMMENT_REPLY_REQUEST,
  DELETE_FILE_COMMENT_REPLY_SUCCESS,
  DELETE_FILE_COMMENT_REPLY_FAILURE,
  LIKE_FILE_REQUEST,
  LIKE_FILE_SUCCESS,
  LIKE_FILE_FAILURE,
  UNLIKE_FILE_REQUEST,
  UNLIKE_FILE_SUCCESS,
  UNLIKE_FILE_FAILURE,
  LIKE_FILE_COMMENT_REQUEST,
  LIKE_FILE_COMMENT_SUCCESS,
  LIKE_FILE_COMMENT_FAILURE,
  ADD_FILE_COMMENT_REPLY_REQUEST,
  ADD_FILE_COMMENT_REPLY_SUCCESS,
  ADD_FILE_COMMENT_REPLY_FAILURE,
  LIKE_FILE_COMMENT_REPLY_REQUEST,
  LIKE_FILE_COMMENT_REPLY_SUCCESS,
  LIKE_FILE_COMMENT_REPLY_FAILURE,
  UPDATE_FILE_COMMENT_REPLY_REQUEST,
  UPDATE_FILE_COMMENT_REPLY_SUCCESS,
  UPDATE_FILE_COMMENT_REPLY_FAILURE,
  UNLIKE_FILE_COMMENT_REQUEST,
  UNLIKE_FILE_COMMENT_SUCCESS,
  UNLIKE_FILE_COMMENT_FAILURE,
  UNLIKE_FILE_COMMENT_REPLY_REQUEST,
  UNLIKE_FILE_COMMENT_REPLY_SUCCESS,
  UNLIKE_FILE_COMMENT_REPLY_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.files, action) {
  switch (action.type) {
  case RESET_FILES_REDUCER:
    return Object.assign({}, initialState.files);

  case RESET_RECENT_UPLOADED_FILE_ID:
    return Object.assign({}, state, {
      isUploading: { status: false, recentId: null },
    });

  case LINK_NEW_PORTFOLIO_FILE_TO_A_BAKED_BADGE_REQUEST:
  case LINK_NEW_PORTFOLIO_FILE_TO_A_BADGE_REQUEST:
  case FILE_UPLOAD_REQUEST:
    return Object.assign({}, state, {
      isUploading: { status: true },
    });

  case LINK_NEW_PORTFOLIO_FILE_TO_A_BAKED_BADGE_SUCCESS:
  case LINK_NEW_PORTFOLIO_FILE_TO_A_BADGE_SUCCESS:
  case FILE_UPLOAD_SUCCESS:
    return Object.assign({}, state, {
      data: [Object.assign({}, action.data), ...state.data],
      isUploading: { status: false, recentId: action.data.id },
    });

  case LINK_NEW_PORTFOLIO_FILE_TO_A_BAKED_BADGE_FAILURE:
  case LINK_NEW_PORTFOLIO_FILE_TO_A_BADGE_FAILURE:
  case FILE_UPLOAD_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUploading: { status: false },
    });

  case SB_FILE_UPLOAD_REQUEST:
    return Object.assign({}, state, {
      isUploading: { status: true },
    });

  case SB_FILE_UPLOAD_SUCCESS:
    return Object.assign({}, state, {
      skillbuilderFiles: [
        Object.assign({}, action.data),
        ...state.skillbuilderFiles,
      ],
      isUploading: { status: false, recentId: action.data.id },
    });

  case SB_FILE_UPLOAD_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUploading: { status: false },
    });

  case DELETE_FILE_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: action.id },
    });

  case DELETE_FILE_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data.filter(file => file.id !== state.isDeleting.id)],
      isDeleting: { status: false, id: null },
    });

  case DELETE_FILE_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: { status: false, id: null },
    });

  case FETCH_FILE_REQUEST:
    return Object.assign({}, state, {
      isRequesting: true,
    });

  case FETCH_FILE_SUCCESS:
    return Object.assign(
      {},
      state,
      {
        data: unionBy(state.data, action.data._embedded.file, 'id'),
        error: {},
        isRequesting: false,
      },
      {
        fileData: Object.assign(
          {},
          {
            page: action.data.page || state.fileData.page,
            page_count: action.data.page_count || state.fileData.page_count,
          }
        ),
      }
    );

  case FETCH_FILE_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case UPDATE_FILE_REQUEST:
    return Object.assign({}, state, {
      isUpdating: true,
    });

  case UPDATE_FILE_SUCCESS: {
    const index = state.data.findIndex(item => item.id === action.data.id);

    return Object.assign(
      {},
      state,
      {
        data: [
          ...state.data.slice(0, index),
          Object.assign({}, action.data),
          ...state.data.slice(index + 1),
        ],
      },
      { isUpdating: false }
    );
  }

  case UPDATE_FILE_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: false,
    });

  case SET_PREVIEW_FILE:
    return Object.assign({}, state, {
      selectedPortfolio: action.file,
    });

  case CLEAR_PREVIEW_FILE:
    return Object.assign({}, state, {
      unitFile: initialState.files.unitFile,
      unitFileComments: initialState.files.unitFileComments,
      selectedPortfolio: {},
    });

  case FETCH_UNIT_FILE_REQUEST:
    return Object.assign({}, state, {
      unitFile: Object.assign({}, state.unitFile, { isRequesting: true }),
    });

  case FETCH_UNIT_FILE_SUCCESS:
    return Object.assign({}, state, {
      unitFile: Object.assign({}, state.unitFile, {
        isRequesting: false,
        data: action.data,
      }),
    });

  case FETCH_UNIT_FILE_FAILURE:
    return Object.assign({}, state, {
      unitFile: Object.assign({}, state.unitFile, {
        isRequesting: false,
        error: action.error,
      }),
    });

  case UNIT_FILE_COMMENTS_REQUEST:
    return Object.assign({}, state, {
      unitFileComments: Object.assign({}, state.unitFileComments, {
        isRequesting: true,
      }),
    });

  case UNIT_FILE_COMMENTS_SUCCESS:
    return Object.assign({}, state, {
      unitFileComments: Object.assign({}, state.unitFileComments, {
        isRequesting: false,
        comments: action.data._embedded.comments,
      }),
    });

  case UNIT_FILE_COMMENTS_FAILURE:
    return Object.assign({}, state, {
      unitFileComments: Object.assign({}, state.unitFileComments, {
        isRequesting: false,
        error: action.error,
      }),
    });

  case ADD_UNIT_FILE_COMMENTS_REQUEST:
    return Object.assign({}, state, {
      unitFileComments: Object.assign({}, state.unitFileComments, {
        isSubmitting: { status: true, fileId: action.id },
      }),
    });

  case ADD_UNIT_FILE_COMMENTS_SUCCESS: {
    const { data, unitFileComments } = state;
    const index = data.findIndex(
      item => item.id === unitFileComments.isSubmitting.fileId
    );

    let updatedFileObject = {};

    if (index >= 0) {
      updatedFileObject = Object.assign({}, data[index], {
        comment_count: data[index].comment_count + 1,
      });
    }

    return Object.assign({}, state, {
      data:
          index >= 0
            ? [
              ...data.slice(0, index),
              updatedFileObject,
              ...data.slice(index + 1),
            ]
            : data,
      unitFile: Object.assign({}, state.unitFile, {
        data: Object.assign({}, state.unitFile.data, {
          comment_count: state.unitFile.data.comment_count + 1,
        }),
      }),
      unitFileComments: Object.assign({}, unitFileComments, {
        isSubmitting: { status: false, fileId: null },
        comments: [action.data, ...unitFileComments.comments],
      }),
    });
  }

  case ADD_UNIT_FILE_COMMENTS_FAILURE:
    return Object.assign({}, state, {
      unitFileComments: Object.assign({}, state.unitFileComments, {
        isSubmitting: { status: false, fileId: null },
        error: action.error,
      }),
    });

  case UPDATE_FILE_COMMENTS_REQUEST:
    return Object.assign({}, state, {
      unitFileComments: Object.assign({}, state.unitFileComments, {
        isUpdating: { status: true, commentId: action.id },
      }),
    });

  case UPDATE_FILE_COMMENTS_SUCCESS: {
    const index = state.unitFileComments.comments.findIndex(
      item => item.id === action.data.id
    );
    const updatedComment = Object.assign(
      {},
      state.unitFileComments.comments[index],
      {
        comment: action.data.comment,
      }
    );

    return Object.assign({}, state, {
      unitFileComments: Object.assign({}, state.unitFileComments, {
        comments: [
          ...state.unitFileComments.comments.slice(0, index),
          updatedComment,
          ...state.unitFileComments.comments.slice(index + 1),
        ],
        isUpdating: { status: false, commentId: null },
      }),
    });
  }

  case UPDATE_FILE_COMMENTS_FAILURE:
    return Object.assign({}, state, {
      unitFileComments: Object.assign({}, state.unitFileComments, {
        isUpdating: { status: false, commentId: null },
        error: action.error,
      }),
    });

  case DELETE_FILE_COMMENT_REQUEST:
    return Object.assign({}, state, {
      unitFileComments: Object.assign({}, state.unitFileComments, {
        isDeleting: { status: true, id: action.id },
      }),
    });

  case DELETE_FILE_COMMENT_SUCCESS: {
    const { unitFileComments } = state;
    const { isDeleting, comments } = unitFileComments;
    const indexOfComment = comments.findIndex(
      item => item.id === isDeleting.id
    );
    const parentComment = comments[indexOfComment];
    const totalReplies = parentComment.replies
      ? parentComment.replies.length
      : 0;

    return Object.assign({}, state, {
      unitFile: Object.assign({}, state.unitFile, {
        data: Object.assign({}, state.unitFile.data, {
          comment_count: state.unitFile.data.comment_count - 1 - totalReplies,
        }),
      }),
      unitFileComments: Object.assign({}, state.unitFileComments, {
        isDeleting: { status: false, id: null },
        comments: state.unitFileComments.comments.filter(
          file => file.id !== state.unitFileComments.isDeleting.id
        ),
      }),
    });
  }

  case DELETE_FILE_COMMENT_FAILURE:
    return Object.assign({}, state, {
      unitFileComments: Object.assign({}, state.unitFileComments, {
        isDeleting: { status: false, id: null },
        error: action.error,
      }),
    });

  case DELETE_FILE_COMMENT_REPLY_REQUEST:
    return Object.assign({}, state, {
      unitFileComments: Object.assign({}, state.unitFileComments, {
        isDeleting: { status: true, replyId: action.id, id: action.query },
      }),
    });

  case DELETE_FILE_COMMENT_REPLY_SUCCESS: {
    const { unitFileComments } = state;
    const { isDeleting, comments } = unitFileComments;
    const indexOfComment = comments.findIndex(
      item => item.id === isDeleting.id
    );
    const parentComment = comments[indexOfComment];

    const updatedObject = Object.assign({}, parentComment, {
      replies: parentComment.replies.filter(
        reply => reply.id !== isDeleting.replyId
      ),
    });

    return Object.assign({}, state, {
      unitFile: Object.assign({}, state.unitFile, {
        data: Object.assign({}, state.unitFile.data, {
          comment_count: state.unitFile.data.comment_count - 1,
        }),
      }),
      unitFileComments: Object.assign({}, unitFileComments, {
        comments: [
          ...unitFileComments.comments.slice(0, indexOfComment),
          updatedObject,
          ...unitFileComments.comments.slice(indexOfComment + 1),
        ],
        isDeleting: { status: false, replyId: null, id: null },
      }),
    });
  }

  case DELETE_FILE_COMMENT_REPLY_FAILURE:
    return Object.assign({}, state, {
      unitFileComments: Object.assign({}, state.unitFileComments, {
        isDeleting: { status: false, replyId: null, id: null },
        error: action.error,
      }),
    });

  case LIKE_FILE_REQUEST:
  case UNLIKE_FILE_REQUEST:
    return Object.assign({}, state, {
      isLiking: {
        status: true,
        id: action.id,
      },
    });

  case LIKE_FILE_SUCCESS: {
    const { data, isLiking, unitFile } = state;
    const index = data.findIndex(item => item.id === isLiking.id);

    let updatedObject = {};

    if (index >= 0) {
      updatedObject = Object.assign({}, data[index], {
        liked: true,
        like_count: data[index].like_count + 1,
      });
    }

    const isInsideUnitFileModal = Object.keys(unitFile.data).length > 0;

    return Object.assign({}, state, {
      data:
          index >= 0
            ? [...data.slice(0, index), updatedObject, ...data.slice(index + 1)]
            : data,
      unitFile: isInsideUnitFileModal
        ? Object.assign({}, unitFile, {
          data: Object.assign({}, unitFile.data, {
            liked: true,
            like_count: unitFile.data.like_count + 1,
          }),
        })
        : Object.assign({}, unitFile),
      isLiking: { status: false, id: null },
    });
  }

  case UNLIKE_FILE_SUCCESS: {
    const { data, isLiking, unitFile } = state;
    const index = data.findIndex(item => item.id === isLiking.id);
    let updatedObject = {};

    if (index >= 0) {
      updatedObject = Object.assign({}, data[index], {
        liked: false,
        like_count: data[index].like_count - 1,
      });
    }

    const isInsideUnitFileModal = Object.keys(unitFile.data).length > 0;

    return Object.assign({}, state, {
      data:
          index >= 0
            ? [...data.slice(0, index), updatedObject, ...data.slice(index + 1)]
            : data,
      unitFile: isInsideUnitFileModal
        ? Object.assign({}, unitFile, {
          data: Object.assign({}, unitFile.data, {
            liked: false,
            like_count: unitFile.data.like_count - 1,
          }),
        })
        : Object.assign({}, unitFile),
      isLiking: { status: false, id: null },
    });
  }

  case LIKE_FILE_FAILURE:
  case UNLIKE_FILE_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isLiking: { status: false, id: null },
    });

  case LIKE_FILE_COMMENT_REQUEST:
  case UNLIKE_FILE_COMMENT_REQUEST:
    return Object.assign({}, state, {
      unitFileComments: Object.assign({}, state.unitFileComments, {
        isLiking: {
          status: true,
          id: action.id,
        },
      }),
    });

  case LIKE_FILE_COMMENT_SUCCESS: {
    const index = state.unitFileComments.comments.findIndex(
      item => item.id === state.unitFileComments.isLiking.id
    );
    const updatedObject = Object.assign(
      {},
      state.unitFileComments.comments[index],
      {
        liked: true,
        likes: state.unitFileComments.comments[index].likes + 1,
      }
    );

    return Object.assign({}, state, {
      unitFileComments: Object.assign({}, state.unitFileComments, {
        comments: [
          ...state.unitFileComments.comments.slice(0, index),
          updatedObject,
          ...state.unitFileComments.comments.slice(index + 1),
        ],
        isLiking: { status: false, id: null },
      }),
    });
  }

  case UNLIKE_FILE_COMMENT_SUCCESS: {
    const index = state.unitFileComments.comments.findIndex(
      item => item.id === state.unitFileComments.isLiking.id
    );
    const updatedObject = Object.assign(
      {},
      state.unitFileComments.comments[index],
      {
        liked: false,
        likes: state.unitFileComments.comments[index].likes - 1,
      }
    );

    return Object.assign({}, state, {
      unitFileComments: Object.assign({}, state.unitFileComments, {
        comments: [
          ...state.unitFileComments.comments.slice(0, index),
          updatedObject,
          ...state.unitFileComments.comments.slice(index + 1),
        ],
        isLiking: { status: false, id: null },
      }),
    });
  }

  case LIKE_FILE_COMMENT_FAILURE:
  case UNLIKE_FILE_COMMENT_FAILURE:
    return Object.assign({}, state, {
      unitFileComments: Object.assign({}, state.unitFileComments, {
        error: action.error,
        isLiking: { status: false, id: null },
      }),
    });

  case ADD_FILE_COMMENT_REPLY_REQUEST:
    return Object.assign({}, state, {
      unitFileComments: Object.assign({}, state.unitFileComments, {
        isSubmitting: { status: true, commentId: action.id },
      }),
    });

  case ADD_FILE_COMMENT_REPLY_SUCCESS: {
    const { unitFileComments } = state;
    const { isSubmitting, comments } = unitFileComments;
    const index = comments.findIndex(
      item => item.id === isSubmitting.commentId
    );
    const hasReplies = comments[index].replies;
    return Object.assign({}, state, {
      unitFile: Object.assign({}, state.unitFile, {
        data: Object.assign({}, state.unitFile.data, {
          comment_count: state.unitFile.data.comment_count + 1,
        }),
      }),
      unitFileComments: Object.assign({}, unitFileComments, {
        isSubmitting: { status: false, commentId: null },
        comments: [
          ...comments.slice(0, index),
          Object.assign({}, comments[index], {
            replies: hasReplies
              ? [...comments[index].replies, action.data]
              : [action.data],
          }),
          ...comments.slice(index + 1),
        ],
      }),
    });
  }

  case ADD_FILE_COMMENT_REPLY_FAILURE:
    return Object.assign({}, state, {
      unitFileComments: Object.assign({}, state.unitFileComments, {
        isSubmitting: { status: false, commentId: null },
        error: action.error,
      }),
    });

  case LIKE_FILE_COMMENT_REPLY_REQUEST:
  case UNLIKE_FILE_COMMENT_REPLY_REQUEST:
    return Object.assign({}, state, {
      unitFileComments: Object.assign({}, state.unitFileComments, {
        isLiking: {
          status: true,
          replyId: action.id,
        },
      }),
    });

  case LIKE_FILE_COMMENT_REPLY_SUCCESS: {
    const { unitFileComments } = state;
    const { isLiking, comments } = unitFileComments;
    const indexOfComment = comments.findIndex(item =>
      item.replies.reduce(
        (acc, curr) => (acc = curr.id === isLiking.replyId ? true : acc),
        false
      )
    );

    const replyIndex = comments[indexOfComment].replies.findIndex(
      item => item.id === isLiking.replyId
    );

    const parentComment = comments[indexOfComment];

    const updatedReplyObject = Object.assign(
      {},
      parentComment.replies[replyIndex],
      {
        liked: true,
        likes: parentComment.replies[replyIndex].likes + 1,
      }
    );

    const updatedObject = Object.assign({}, parentComment, {
      replies: [
        ...parentComment.replies.slice(0, replyIndex),
        Object.assign({}, updatedReplyObject),
        ...parentComment.replies.slice(replyIndex + 1),
      ],
    });

    return Object.assign({}, state, {
      unitFileComments: Object.assign({}, unitFileComments, {
        comments: [
          ...unitFileComments.comments.slice(0, indexOfComment),
          updatedObject,
          ...unitFileComments.comments.slice(indexOfComment + 1),
        ],
        isLiking: { status: false, replyId: null },
      }),
    });
  }

  case UNLIKE_FILE_COMMENT_REPLY_SUCCESS: {
    const { unitFileComments } = state;
    const { isLiking, comments } = unitFileComments;
    const indexOfComment = comments.findIndex(item =>
      item.replies.reduce(
        (acc, curr) => (acc = curr.id === isLiking.replyId ? true : acc),
        false
      )
    );

    const replyIndex = comments[indexOfComment].replies.findIndex(
      item => item.id === isLiking.replyId
    );

    const parentComment = comments[indexOfComment];

    const updatedReplyObject = Object.assign(
      {},
      parentComment.replies[replyIndex],
      {
        liked: false,
        likes: parentComment.replies[replyIndex].likes - 1,
      }
    );

    const updatedObject = Object.assign({}, parentComment, {
      replies: [
        ...parentComment.replies.slice(0, replyIndex),
        Object.assign({}, updatedReplyObject),
        ...parentComment.replies.slice(replyIndex + 1),
      ],
    });

    return Object.assign({}, state, {
      unitFileComments: Object.assign({}, unitFileComments, {
        comments: [
          ...unitFileComments.comments.slice(0, indexOfComment),
          updatedObject,
          ...unitFileComments.comments.slice(indexOfComment + 1),
        ],
        isLiking: { status: false, replyId: null },
      }),
    });
  }

  case LIKE_FILE_COMMENT_REPLY_FAILURE:
  case UNLIKE_FILE_COMMENT_REPLY_FAILURE:
    return Object.assign({}, state, {
      unitFileComments: Object.assign({}, state.unitFileComments, {
        error: action.error,
        isLiking: { status: false, replyId: null },
      }),
    });

  case UPDATE_FILE_COMMENT_REPLY_REQUEST:
    return Object.assign({}, state, {
      unitFileComments: Object.assign({}, state.unitFileComments, {
        isUpdating: {
          status: true,
          replyId: action.id,
        },
      }),
    });

  case UPDATE_FILE_COMMENT_REPLY_SUCCESS: {
    const { unitFileComments } = state;
    const { isUpdating, comments } = unitFileComments;
    const indexOfComment = comments.findIndex(item =>
      item.replies.reduce(
        (acc, curr) => curr.id === isUpdating.replyId,
        false
      )
    );

    const replyIndex = comments[indexOfComment].replies.findIndex(
      item => item.id === isUpdating.replyId
    );

    const parentComment = comments[indexOfComment];

    const updatedReplyObject = Object.assign(
      {},
      parentComment.replies[replyIndex],
      {
        comment: action.data.comment,
      }
    );

    const updatedObject = Object.assign({}, parentComment, {
      replies: [
        ...parentComment.replies.slice(0, replyIndex),
        updatedReplyObject,
        ...parentComment.replies.slice(replyIndex + 1),
      ],
    });

    return Object.assign({}, state, {
      unitFileComments: Object.assign({}, unitFileComments, {
        comments: [
          ...unitFileComments.comments.slice(0, indexOfComment),
          updatedObject,
          ...unitFileComments.comments.slice(indexOfComment + 1),
        ],
        isUpdating: { status: false, replyId: null },
      }),
    });
  }

  case UPDATE_FILE_COMMENT_REPLY_FAILURE:
    return Object.assign({}, state, {
      unitFileComments: Object.assign({}, state.unitFileComments, {
        error: action.error,
        isUpdating: { status: false, replyId: null },
      }),
    });

  default:
    return state;
  }
}
