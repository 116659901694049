import React from 'react';

import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import TextInput from '../common/TextInput';

const RecruiterListForm = ({
  errors,
  recruiter,
  onChange,
  isSubmitting,
  onSubmit,
  isOpenRecruiterListModal,
  closeRecruiterListModal
}) => {
  return (
    <Modal isOpen={isOpenRecruiterListModal} toggle={closeRecruiterListModal} size={'md'} centered className="talent-signup-modal">
      <ModalHeader
        className="no-border"
        toggle={closeRecruiterListModal}>
        Join CareerPrepped Recruiter List
      </ModalHeader>

      <ModalBody>
        <p>
          CareerPrepped helps you recruit talent with verifiable skills, reducing employee recruitment, training and turnover costs.
        </p>
        <p>
          Soon you’ll be able to recruit CareerPrepped talent through a skills-based candidate matching tool powered by Textkernel’s AI technology. Join our recruiter list to get our special offers.
        </p>

        <form>
          <div className="row">
            <div className="col-lg-6">
              <TextInput
                autoFocus
                name="firstName"
                placeholder="First Name"
                error={errors.firstName}
                value={recruiter.firstName}
                onChange={onChange}/>
            </div>
            <div className="col-lg-6">
              <TextInput
                name="lastName"
                placeholder="Last Name"
                error={errors.lastName}
                value={recruiter.lastName}
                onChange={onChange}/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <TextInput
                name="email"
                placeholder="Email"
                error={errors.email}
                value={recruiter.email}
                onChange={onChange}/>
            </div>
            <div className="col-lg-6">
              <TextInput
                name="phoneNumber"
                placeholder="Phone Number (Optional)"
                error={errors.phoneNumber}
                value={recruiter.phoneNumber}
                onChange={onChange}/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <TextInput
                name="jobTitle"
                placeholder="Job Title"
                error={errors.jobTitle}
                value={recruiter.jobTitle}
                onChange={onChange}/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <TextInput
                name="companyName"
                placeholder="Company Name"
                error={errors.companyName}
                value={recruiter.companyName}
                onChange={onChange}/>
            </div>
            <div className="col-lg-6">
              <TextInput
                name="country"
                placeholder="Country"
                error={errors.country}
                value={recruiter.country}
                onChange={onChange}/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <TextInput
                name="state"
                placeholder="State/Province"
                error={errors.state}
                value={recruiter.state}
                onChange={onChange}/>
            </div>
            <div className="col-lg-6">
              <TextInput
                name="city"
                placeholder="City"
                error={errors.city}
                value={recruiter.city}
                onChange={onChange}/>
            </div>
          </div>
          <div className="talent-signup-btn_content">
            <button
              className="talent-signup-btn"
              onClick={onSubmit}
              disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Join Recruiter List'}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

RecruiterListForm.propTypes = {
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  recruiter: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpenRecruiterListModal: PropTypes.bool.isRequired,
  closeRecruiterListModal: PropTypes.func.isRequired
};

export default RecruiterListForm;
