import initialState from './initialState';

import {
  CLUSTERS_REQUEST,
  CLUSTERS_SUCCESS,
  CLUSTERS_FAILURE
} from '../../constants';

export default function reducer(state = initialState.clusters, action) {
  switch (action.type) {
  case CLUSTERS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case CLUSTERS_SUCCESS:
    return Object.assign({}, state, {
      isRequesting: false,
      page: action.data.page,
      pageCount: action.data.page_count,
      data: action.data._embedded.career_clusters
    });

  case CLUSTERS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false
    });

  default:
    return state;
  }
}
