import { CALL_API } from '../../middlewares/api';

import {
  INVITE_USERS_REQUEST,
  INVITE_USERS_SUCCESS,
  INVITE_USERS_FAILURE,
  GET_RELATION_LIST_REQUEST,
  GET_RELATION_LIST_SUCCESS,
  GET_RELATION_LIST_FAILURE,
  GET_INVITE_TOKEN_REQUEST,
  GET_INVITE_TOKEN_SUCCESS,
  GET_INVITE_TOKEN_FAILURE,
} from '../../constants';

export const inviteInstitutionUsers = (data) => {
  const endpoint = '/institution/invitation';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [INVITE_USERS_REQUEST, INVITE_USERS_SUCCESS, INVITE_USERS_FAILURE],
    },
  };
};

export const getRelationList = () => {
  const endpoint = 'relationlist';

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [
        GET_RELATION_LIST_REQUEST,
        GET_RELATION_LIST_SUCCESS,
        GET_RELATION_LIST_FAILURE,
      ],
    },
  };
};

export const inviteToken = (token) => {
  const endpoint = `invite?token=${token}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [
        GET_INVITE_TOKEN_REQUEST,
        GET_INVITE_TOKEN_SUCCESS,
        GET_INVITE_TOKEN_FAILURE,
      ],
    },
  };
};
