import { CALL_API } from '../../middlewares/api';
import {
  GET_FEEDBACK_PRAISE_OPTIONS_REQUEST,
  GET_FEEDBACK_PRAISE_OPTIONS_SUCCESS,
  GET_FEEDBACK_PRAISE_OPTIONS_FAILURE,
  SUBMIT_FEEDBACK_PRAISE_REQUEST,
  SUBMIT_FEEDBACK_PRAISE_SUCCESS,
  SUBMIT_FEEDBACK_PRAISE_FAILURE,
} from '../../constants';

export const getFeedbackOptionsRequest = () => {
  return {
    [CALL_API]: {
      method: 'get',
      authenticated: true,
      endpoint: 'feedback-praise-options',
      types: [
        GET_FEEDBACK_PRAISE_OPTIONS_REQUEST,
        GET_FEEDBACK_PRAISE_OPTIONS_SUCCESS,
        GET_FEEDBACK_PRAISE_OPTIONS_FAILURE,
      ],
    },
  };
};

export const submitFeedbackPraiseRequest = (data) => {
  return {
    [CALL_API]: {
      data,
      method: 'post',
      authenticated: true,
      endpoint: 'feedback-praise',
      types: [
        SUBMIT_FEEDBACK_PRAISE_REQUEST,
        SUBMIT_FEEDBACK_PRAISE_SUCCESS,
        SUBMIT_FEEDBACK_PRAISE_FAILURE,
      ],
    },
  };
};