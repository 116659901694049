import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { checkHttp } from '../../../utils';
import Parser from 'html-react-parser';

const styles = {
  userNameEditIcon: {
    cursor: 'pointer',
    marginLeft: 10,
  },

  editActions: {
    position: 'relative',
  }
};

const AwardSectionCard = ({
  awardDetails,
  onEditAward,
  onRemoveAward
}) => {
  const isDateObject = typeof awardDetails.date === 'object';
  const dateString = isDateObject ? awardDetails.date.date :
    awardDetails.date === '' ? undefined : awardDetails.date;

  const date = moment(dateString).format('MMMM YYYY');

  const urlAvailable = awardDetails.url !== '';
  const urlString = checkHttp(awardDetails.url) ?
    awardDetails.url : `//${awardDetails.url}`;

  return (
    <div className="resume-section section-item">
      <span style={styles.editActions} className="float-right">
        <i
          onClick={onEditAward}
          id={awardDetails.id}
          style={styles.userNameEditIcon}
          className="fa fa-pencil"/>

        <i
          onClick={onRemoveAward}
          id={awardDetails.id}
          style={styles.userNameEditIcon}
          className="fa fa-close"/>
      </span>
      <span><b>{awardDetails.title}</b> - {!urlAvailable ?
        awardDetails.issuer :
        <a href={urlString} target="_blank" rel="noopener noreferrer">{awardDetails.issuer}</a>}
      </span>
      <span style={{marginLeft: 10}}>({date})</span>
      {Parser(awardDetails.description)}
    </div>
  );
};

AwardSectionCard.propTypes = {
  awardDetails: PropTypes.object.isRequired,
  onEditAward: PropTypes.func.isRequired,
  onRemoveAward: PropTypes.func.isRequired
};

export default AwardSectionCard;
