import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as wallPostActions from '../../redux/actions/wall-posts';
import * as discussionsActions from '../../redux/actions/discussions';
import * as componentActions from '../../redux/actions/components';
import TextArea from '../../components/common/TextArea';
import { toastr } from 'react-redux-toastr';
import { isOverflown } from '../../utils';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import Button from '../../components/common/Button';


const visibilityOptionsArray = [
  {
    title:'Everyone (Public)',
    alias: 'public',
    value:'1'
  },
  {
    title:'Followers Only',
    alias: 'followers',
    value:'2'
  },
  {
    title:'Only Me',
    alias: 'private',
    value:'0'
  },
];

class ShareReflectionToActivityStream extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    shareData: PropTypes.object.isRequired,
    unit: PropTypes.object.isRequired,
    selectedReflections: PropTypes.array,
    reflectionResponses: PropTypes.object,
    closeModal: PropTypes.func,
    shareToEngageAndConnect: PropTypes.bool,
    isSharingToEngageAndConnect: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      showAll: false,
      isContentsOverflown: false,
      visibilityDropdownOpen: false,
      shareData: Object.assign({}, this.props.shareData)
    };

    this.onChangeShareData = this.onChangeShareData.bind(this);
    this.onShareClick = this.onShareClick.bind(this);
    this.onShareToEngageAndConnect = this.onShareToEngageAndConnect.bind(this);
    this.onToggleVisibilityDropdown = this.onToggleVisibilityDropdown.bind(this);
    this.onSelectVisibilty = this.onSelectVisibilty.bind(this);
    this.onToggleShowAll = this.onToggleShowAll.bind(this);
    this.setReflectionAnswersContentContainerRef = this.setReflectionAnswersContentContainerRef.bind(this);
  }

  componentDidMount(){
    if(this.reflectionSharingContentsDiv){
      /* eslint-disable react/no-did-mount-set-state */
      this.setState({
        isContentsOverflown: isOverflown(this.reflectionSharingContentsDiv)
      });
    }
  }

  componentWillReceiveProps(nextProps){
    const { isSubmitting, isSharingToEngageAndConnect } = nextProps;
    const isNextSharing = isSharingToEngageAndConnect || isSubmitting;
    const isSharing = this.props.isSharingToEngageAndConnect || this.props.isSubmitting;

    if(isNextSharing !== isSharing && isSharing && !isNextSharing ){
      toastr.success('You\'ve successfully shared your reflection(s).');
      return this.props.closeModal();
    }
  }

  componentDidUpdate(prevProps, prevState){
    const { showAll } = prevState;

    if(showAll !== this.state.showAll){

      if(this.reflectionSharingContentsDiv){
        /* eslint-disable react/no-did-update-set-state */
        this.setState({
          isContentsOverflown: isOverflown(this.reflectionSharingContentsDiv)
        });
      }
    }
  }

  onChangeShareData(event) {
    let { value, name } = event.target;
    const { shareData } = this.state;

    shareData[name] = value;

    this.setState({ shareData });
  }

  onToggleVisibilityDropdown() {
    const { visibilityDropdownOpen } = this.state;
    this.setState({
      visibilityDropdownOpen: !visibilityDropdownOpen
    });
  }

  onSelectVisibilty(id){

    return () => {
      const shareData = { ...this.state.shareData, permissions:id};

      this.setState({
        shareData,
        visibilityDropdownOpen: false
      });
    };
  }

  onShareClick(){
    const { actions, unit, reflectionResponses, selectedReflections, shareToEngageAndConnect } = this.props;

    if(shareToEngageAndConnect)
      return this.onShareToEngageAndConnect();

    const { shareData } = this.state;
    const { title, code } = unit;
    const dropdownButton = visibilityOptionsArray.find(option =>
      option.value == shareData.permissions || option.alias == shareData.permissions);

    const reflectionArray = selectedReflections.map(selected => reflectionResponses[selected].id);

    const data = {
      'post': `${code} : ${title}`,
      'permissions': dropdownButton.value,
      'sharedSkillBuilderReflections': reflectionArray,
      'sharedQuotedText': shareData.quote
    };

    actions.saveWallPost(data);
  }

  onShareToEngageAndConnect(){
    const { shareData } = this.state;
    const { actions, unit, reflectionResponses, selectedReflections } = this.props;
    const { title, code, id } = unit;

    const reflectionArray = selectedReflections.map(selected => reflectionResponses[selected].id);

    const data = {
      'post': `${code} : ${title}`,
      'unit': id,
      'sharedSkillBuilderReflections': reflectionArray,
      'sharedQuotedText': shareData.quote
    };

    actions.saveUnitDiscussion(data);
  }

  onToggleShowAll() {
    this.setState({
      showAll: !this.state.showAll
    });
  }

  setReflectionAnswersContentContainerRef(div) {
    this.reflectionSharingContentsDiv = div;
  }

  render() {
    const { shareData, visibilityDropdownOpen, showAll, isContentsOverflown } = this.state;
    const {
      selectedReflections,
      closeModal,
      isSubmitting,
      reflectionResponses,
      unit,
      shareToEngageAndConnect,
      isSharingToEngageAndConnect
    } = this.props;
    const dropdownButton = visibilityOptionsArray.find(option =>
      option.value == shareData.permissions || option.alias == shareData.permissions);
    const isSharing = isSharingToEngageAndConnect || isSubmitting;

    return(
      <div>
        <div className="seek-endorsement-content mt-3">
          <div className="shared-item">
            <TextArea
              name="quote"
              type="text"
              rows="3"
              value={shareData.quote}
              onChange={this.onChangeShareData}
              placeholder="Say something about your reflection(s)"/>
            <div className="shared-item-container">
              <div className="shared-item-description reflection-sharing">
                <p>I just answered the reflection question(s) below from <span style={{color: '#ff5000'}}>{unit.title}</span></p>
                <hr/>
                <div
                  style={{
                    maxHeight: showAll ? 'initial': '100%',
                    overflow: isContentsOverflown ? 'hidden' : 'initial'
                  }}
                  ref={this.setReflectionAnswersContentContainerRef}
                  className="shared-reflection-contents">
                  {
                    selectedReflections.includes('q1') &&
                      <div>
                        <strong>{unit.reflectone}</strong><br/>
                        {reflectionResponses.q1.answer}<br/><br/>
                      </div>
                  }
                  {
                    selectedReflections.includes('q2') &&
                      <div>
                        <strong>{unit.reflecttwo}</strong><br/>
                        {reflectionResponses.q2.answer}<br/><br/>
                      </div>
                  }

                  {
                    selectedReflections.includes('q3') &&
                      <div>
                        <strong>{unit.reflectthree}</strong><br/>
                        {reflectionResponses.q3.answer}<br/><br/>
                      </div>
                  }

                  {
                    selectedReflections.includes('q4') &&
                      <div>
                        <strong>{unit.reflectfour}</strong><br/>
                        {reflectionResponses.q4.answer}
                      </div>
                  }
                </div>
                {
                  (isContentsOverflown || showAll) &&
                    <div
                      onClick={this.onToggleShowAll}
                      className="clickable link"
                      style={{marginTop: 10, fontWeight: '600'}}>
                      {showAll ? 'Show less': '...Show more'}</div>
                }
              </div>
            </div>
          </div>
          <div
            className="modal-footer row pl-3 pr-3 pt-3 pb-0 mt-4">
            <Button
              type="button"
              onClick={closeModal}
              buttonClass="btn-secondary"
              buttonText="Cancel"/>
            {
              !shareToEngageAndConnect &&
                <Dropdown
                  className="dropdown float-right"
                  isOpen={visibilityDropdownOpen}
                  toggle={this.onToggleVisibilityDropdown}>
                  <DropdownToggle
                    caret
                    color="tertiary"
                    className="btn btn-tertiary activity-filter-button">
                    {dropdownButton == null ? 'Who can see this post' : dropdownButton.title}
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu">
                    {visibilityOptionsArray.map((option,index) => {
                      return (
                        <a className="dropdown-item" key={index} onClick={this.onSelectVisibilty(option.value)}>
                          {option.title}
                        </a>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
            }
            <Button
              type="submit"
              onClick={this.onShareClick}
              styles={{ marginLeft: '.25rem' }}
              buttonClass="btn btn-primary"
              buttonText={isSharing ? 'Sharing...' : 'Share'}/>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let shareData = {
    quote: '',
    permissions: '1'
  };

  return {
    shareData,
    isSubmitting: state.wallPosts.isSubmitting,
    isSharingToEngageAndConnect: state.discussions.isSubmitting,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    wallPostActions,
    discussionsActions,
    componentActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareReflectionToActivityStream);
