import PropTypes from 'prop-types';
import React from 'react';
import PostItem from '../common/PostItem';
import SharedBadgePost from '../common/SharedBadgePost';
import SharedFilePost from '../common/SharedFilePost';
import SharedPost from '../common/SharedPost';
import SharedInterviewVideoPost from '../common/SharedInterviewVideoPost';
import { pure } from 'recompose';
import SharedReflectionAnswersPostContainer from '../../containers/dashboard/SharedReflectionAnswersPostContainer';

const PostItemContainer = ({ wallPost, showMore, onshowMore }) => {
  const { 
    id,
    sharedEntityType, 
    sharedQuotedText, 
    sharedBadgeIssued, 
    user, 
    sharedFile,
    sharedWall,
    sharedDiscussion,
    sharedInterviewResource,
    sharedSkillBuilderReflections
  } = wallPost;

  let component = null;

  switch(sharedEntityType){
  
  case 'skillBuilderReflections':
    component = (<SharedReflectionAnswersPostContainer
      id={id}
      quote={sharedQuotedText}
      reflection={sharedSkillBuilderReflections}/>);
    break;

  case 'interviewResource':
    component = (<SharedInterviewVideoPost
      quote={sharedQuotedText}
      video={sharedInterviewResource}/>);
    break;

  case 'badgeIssued':
    component = (<SharedBadgePost
      user={user}
      quote={sharedQuotedText}
      badge={sharedBadgeIssued}/>);
    break;

  case 'file': 
    component = (<SharedFilePost
      user={sharedFile.user}
      quote={sharedQuotedText}
      file={sharedFile}/>);  
    break;

  case 'discussion':
    component = (<SharedPost
      showMore={showMore}
      onshowMore={onshowMore}
      wallPost={wallPost} 
      originalPost={sharedDiscussion}/>);
    break;
  case 'wall':
    component = (<SharedPost
      showMore={showMore}
      onshowMore={onshowMore}
      wallPost={wallPost} 
      originalPost={sharedWall}/>);
    break;

  default:
    component = (<PostItem
      showMore={showMore}
      onshowMore={onshowMore}
      wallPost={wallPost} />);
  }
  return component;
};

PostItemContainer.propTypes = {
  wallPost: PropTypes.object.isRequired,
  showMore: PropTypes.object.isRequired,
  onshowMore: PropTypes.func.isRequired,
};

export default pure(PostItemContainer);
