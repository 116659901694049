import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

class FileCommentActions extends Component {
  static propTypes = {
    comment: PropTypes.object.isRequired,
    onEditClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      dropdownOpen: false
    };

    this.toggle = this.toggle.bind(this);
    this.onEditComment = this.onEditComment.bind(this);
    this.onDeleteComment = this.onDeleteComment.bind(this);
  }

  toggle() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  onEditComment() {
    this.setState({
      dropdownOpen: false
    }, () => this.props.onEditClick());
  }

  onDeleteComment(){
    this.setState({
      dropdownOpen: false
    }, () => this.props.onDeleteClick());
  }

  render() {
    const { dropdownOpen } = this.state;
    
    return (
      <span className="action-dropdowns">
        <Dropdown isOpen={dropdownOpen} toggle={this.toggle}>
          <DropdownToggle className="edit-menu-button">
            &#9679;&#9679;&#9679;
          </DropdownToggle>
          <DropdownMenu className="dropdown-item-left dropdown-menu dropdown-menu-right">
            <a className="dropdown-item" onClick={this.onEditComment}> Edit </a>
            <a className="dropdown-item" onClick={this.onDeleteComment}> Delete </a>
          </DropdownMenu>
        </Dropdown>
      </span>
    );
  }
}

export default FileCommentActions;
