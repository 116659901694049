import initialState from './initialState';

import {
  LOAD_FORM,
  RESET_FORMS,
  UNLOAD_FORM,
  LOAD_SECTION_FORM,
  UNLOAD_SECTION_FORM,
  LOAD_UPDATE_FORM,
  UNLOAD_UPDATE_FORM,
  SET_FORM_FOCUS,
  UNSET_FORM_FOCUS,
  OPEN_MODAL,
  CLOSE_MODAL,
  TOGGLE_COMPONENT,
  TOGGLE_AVATAR_MODAL,
  TOGGLE_MOBILE_VIEW,
} from '../../constants';

export default function reducer(state = initialState.components, action) {
  switch (action.type) {
  case TOGGLE_AVATAR_MODAL:
    return Object.assign({}, state, {
      viewAvatarModal: {
        status: !state.viewAvatarModal.status,
        data: action.data,
      },
    });

  case TOGGLE_MOBILE_VIEW:
    return Object.assign({}, state, { isMobile: action.bool });

  case OPEN_MODAL:
    return Object.assign({}, state, { modal: action.modal });

  case CLOSE_MODAL:
    return Object.assign({}, state, { modal: null });

  case RESET_FORMS:
    return initialState.components;

  case LOAD_FORM:
    return Object.assign({}, state, {
      forms: [...state.forms, action.form],
    });

  case UNLOAD_FORM:
    return Object.assign({}, state, {
      forms: [...state.forms.filter(form => form !== action.form)],
    });

  case TOGGLE_COMPONENT:
    return Object.assign({}, state, {
      toggleComponents: state.toggleComponents.includes(action.component) ?
        [...state.toggleComponents.filter(component => component !== action.component)] :
        [...state.toggleComponents, action.component]
    });

  case LOAD_SECTION_FORM:
    return Object.assign({}, state, {
      sectionForms: [...state.sectionForms, action.form],
    });

  case UNLOAD_SECTION_FORM:
    return Object.assign({}, state, {
      sectionForms: [
        ...state.sectionForms.filter(form => form.id !== action.form.id),
      ],
    });

  case LOAD_UPDATE_FORM:
    return Object.assign({}, state, {
      updateResourceForms: [...state.updateResourceForms, action.form],
    });

  case UNLOAD_UPDATE_FORM:
    return Object.assign({}, state, {
      updateResourceForms: [
        ...state.updateResourceForms.filter(form => form !== action.form),
      ],
    });

  case SET_FORM_FOCUS:
    return Object.assign({}, state, {
      onFocusForms: [...state.onFocusForms, action.form],
    });

  case UNSET_FORM_FOCUS:
    return Object.assign({}, state, {
      onFocusForms: [
        ...state.onFocusForms.filter(form => form !== action.form),
      ],
    });

  default:
    return state;
  }
}
