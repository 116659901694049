import { createSelector } from 'reselect';

const selectCities = state => state.cities;

export const selectCitiesList = createSelector(
  [selectCities],
  cities => cities.data
);

export const selectFormattedCities = createSelector(
  [selectCitiesList],
  cities =>
    cities.map(
      city => ({
        label: city.cityName,
        value: city.cityId
      })
    )
);

export const selectCitiesPageCount = createSelector(
  [selectCities],
  cities => cities.pageCount
);
