import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import { get, isEmpty } from 'lodash';
import ViewAll from './ViewAll';
import StarRating from '../rating/StarRating';
import Praise from './Praise';
import { getFeedbackRating } from '../../utils';
import {
  SETTINGS_DATE_FORMAT,
  FEEDBACK_REQUESTED_DATE_FORMAT,
} from '../../constants';

import {callApi} from '../../middlewares/api';

class FeedbackReceived extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isViewAllOpen: false,
      isPraiseOpen: false,
      praiseFormContent: [],
      praiseSelection: [],
      givePraise: true,
      seePraiseSelection: [],
    };
  }

  toggleViewAll = () => {
    this.setState((state) => {
      return {
        isViewAllOpen: !state.isViewAllOpen,
      };
    });
  };

  togglePraise = () => {
    const {feedback} = this.props;
    if ( feedback.receivedPraise === 1 && !this.state.isPraiseOpen) {
      this.getSeePraiseOptions(feedback.feedbackRequestId);  
    } else {
      this.callTogglePraise();
    }
  };

  callTogglePraise = () => {
    this.setState((state) => {
      return {
        isPraiseOpen: !state.isPraiseOpen,
      };
    });
  }

  setGivePraise = (val) => {
    this.setState({
      givePraise: val,
    });
  };

  submitPraise = () => {
    const {submitFeedbackPraise, feedback} = this.props;

    submitFeedbackPraise(feedback.feedbackRequestId, this.state.praiseSelection, this.togglePraise, feedback.fullname);
  }

  storePraiseOption = () => {
    const selectedPraise = event.target.value;
    const praiseSelected = [...this.state.praiseSelection];
    
    if (event.target.checked){
      praiseSelected.push(selectedPraise);
    } else {
      praiseSelected.splice(selectedPraise, 1);
    }
    
    this.setState({
      praiseSelection: [...praiseSelected]
    });

    if ( !isEmpty(praiseSelected) ) {
      this.setGivePraise(false);
    } else {
      this.setGivePraise(true);
    }
  }

  getSeePraiseOptions = (feedbackRequestId) => {
    callApi('', 'get', `feedback-praise/${feedbackRequestId}`, true).then(
      (response) => {
        if (response.status === 200) {
          const givenPraises = response.data.detail.praises;
          this.setState({
            seePraiseSelection: [...givenPraises]
          });
          this.callTogglePraise();
        }
      });
  }

  render() {
    const { feedback, praiseOptions } = this.props;

    return (
      <div key={feedback.feedbackRequestId}>
        <Row className="myfeedback-skillcontainer">
          <Col md="3">
            <div className="d-flex align-items-center mb-1 mt-1">
              <Link to={`/cp/${feedback.vanity}`} className="user-name">
                <img
                  className="feedbackAvatar"
                  src={
                    feedback.avatarURl
                      ? feedback.avatarURl
                      : require('../../assets/images/avatar.jpg')
                  }/>
              </Link>
              <div className="ml-3">
                <span>
                  <Link to={`/cp/${feedback.vanity}`} className="user-name">
                    <strong>{`${feedback.fullname}`}</strong>
                  </Link>
                </span>
                <div className="d-flex align-items-center">
                  {feedback.isRevised >= 1 && (
                    <i className="fa fa-sm fa-pencil writtenOn" />
                  )}
                  <span className="font12">{`${moment(
                    feedback.feedbackResponseDate.date,
                    SETTINGS_DATE_FORMAT
                  ).format(FEEDBACK_REQUESTED_DATE_FORMAT)}`}</span>
                </div>
              </div>
            </div>
          </Col>
          <Col md="9">
            <Row className="align-items-start">
              <Col md="6" className="mt-3 mt-lg-0 mt-md-0">
                <div className="d-flex align-items-center mb-3">
                  <Link
                    className="title-link"
                    to={
                      feedback.resourceType == 'badge'
                        ? `/skill-badges/badge-details/${feedback?.resourceId}`
                        : `/skill-evidence/${feedback?.resourceId}`
                    }>
                    {
                      feedback.resourceType === 'badge' && 
                        <img
                          className="feedbackGivenSkill"
                          src={get(feedback, 'resourceUrl',require(feedback.resourceType === 'badge'
                            ? '../../assets/images/badge_fake.png'
                            : '../../assets/images/temp/skill.png'))}/>
                    }
                    <p className="d-inline font-weight-bold mb-0">
                      {feedback.resourcename}
                    </p>
                  </Link>
                </div>
                {feedback.questionResponseRating?.overall && (
                  <p className="font-weight-bold mt-2">
                    Question Response Ratings: &nbsp; 
                    <span className="font-weight-normal">{`${feedback.questionResponseRating.overall}`}</span>
                    <br />
                    <span className="font-weight-normal text-justify text-break">
                      {`${feedback.questionResponseRating.multichoiceComments}`}
                    </span>
                    <br />
                    <span className="font-weight-normal text-justify text-break">
                      {`${feedback.questionResponseRating.additionalComment.substring(0, 140)}`}
                      {(feedback.questionResponseRating.additionalComment.length > 140 ) ? '...' : ''}
                    </span>
                  </p>
                )}
                {feedback.portfolioEvidenceRating?.overall && (
                  <p className="font-weight-bold mt-2">
                    Portfolio Evidence Ratings: &nbsp;
                    <span className="font-weight-normal">{`${feedback.portfolioEvidenceRating.overall}`}</span>
                    <br />
                    <span className="font-weight-normal text-justify text-break">
                      {`${feedback.portfolioEvidenceRating.multichoiceComments}`}
                    </span>
                    <br />
                    <span className="font-weight-normal text-justify text-break">
                      {`${feedback.portfolioEvidenceRating.additionalComment.substring(0, 140)}`}
                      {(feedback.portfolioEvidenceRating.additionalComment.length > 140 ) ? '...' : ''}
                    </span>
                  </p>
                )}
                { (feedback.questionResponseRating?.additionalComment?.length > 140 || feedback.portfolioEvidenceRating?.additionalComment?.length > 140) && <span
                  onClick={this.toggleViewAll}
                  className="d-inline-block pointer themeLinkWithoutUnderline pb-1">
                  View All
                </span>
                }
              </Col>
              <Col md="6" className="mb-3 mb-lg-0">
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <StarRating rating={getFeedbackRating(feedback)}/>
                  </div>
                  <div className="btn btn-primary" onClick={this.togglePraise}>
                    {feedback.receivedPraise? 'See Praise' : 'Praise'}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Praise
          feedback={feedback}
          isOpen={this.state.isPraiseOpen}
          toggleModal={this.togglePraise}
          praiseOptions={praiseOptions}
          submitPraise={this.submitPraise}
          storePraiseOption={this.storePraiseOption}
          givePraise={this.state.givePraise}
          seePraiseSelection={this.state.seePraiseSelection}/>
        <ViewAll
          feedback={feedback}
          isOpen={this.state.isViewAllOpen}
          action="received"
          toggleModal={this.toggleViewAll}/>
        <div className="grayLine mb-3" />
      </div>
    );
  }
}

FeedbackReceived.propTypes = {
  feedback: PropTypes.object.isRequired,
  submitFeedbackPraise: PropTypes.func.isRequired,
  praiseOptions: PropTypes.object.isRequired,
};

export default FeedbackReceived;
