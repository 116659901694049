import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import _find from 'lodash/find';
import _keys from 'lodash/keys';
import _isNull from 'lodash/isNull';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import _isUndefined from 'lodash/isUndefined';
import _omit from 'lodash/omit';

import { toastr } from 'react-redux-toastr';
import { uniqueId, handleKeyDown } from '../../../utils';
import Validator from '../../../validator';

import * as skillActions from '../../../redux/actions/skills';
import * as profileActions from '../../../redux/actions/profile';
import * as cpStatusActions from '../../../redux/actions/my-careerprepped-status';

import SkillCardHandler from './SkillCardHandler';
import SkillForm from '../../../components/profile/forms/SkillForm';
import ProfileModal from '../../../components/profile/modal/ProfileModal';

class SkillsList extends Component {
  static propTypes = {
    actions: PropTypes.object,
    skillsOrder: PropTypes.array,
    skills: PropTypes.array,
    reArrangeSkills: PropTypes.func,
    isPublicProfile: PropTypes.bool,
    userId: PropTypes.string,
    skillBeingUpdated: PropTypes.object,
    isDeleting: PropTypes.bool,
    editItemIsClicked: PropTypes.func,
    isItemBeingEdited: PropTypes.bool,
    isUpdating: PropTypes.object,
    onAddClicked: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      skillBeingUpdated: {},
      focusPreference: ['name'],
      allowUpdate: false,
      isDeletingSkillAndUpdatingSkillsOrder: false,
      askUserBeforeAction: false,
      modalMessage: 'Are you sure you want to delete this skill?',
      modalFooterButtonText: 'Delete',
      modalHeaderText: 'Delete Skill',
      idOfEvidenceBeingRemoved: '',
      showAllSkills: false
    };

    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.isValid = this.isValid.bind(this);
    this.focusOnInputField = this.focusOnInputField.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.checkIfValuesAreUnchanged = this.checkIfValuesAreUnchanged.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleAllSkills = this.toggleAllSkills.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.skillBeingUpdated !== this.props.skillBeingUpdated)
      this.setState({
        skillBeingUpdated: nextProps.skillBeingUpdated
      });

    if (nextProps && nextProps.skills.length < this.props.skills.length){
      this.setState({
        isDeletingSkillAndUpdatingSkillsOrder: false,
        modalFooterButtonText: 'Delete'
      }, () => {
        toastr.success('Skill(s) deleted');
        this.onCancel();
      });
    }
  }

  isValid(field = null, separateRules = null) {
    let rules = {};

    rules = {
      name: ['required', 'minLength|3', 'maxLength|80']
    };

    if (!_isNull(separateRules))
      rules = separateRules;

    const validate = Validator.createValidator(
      rules, this.state.skillBeingUpdated, field
    );
    const { isValid, errors } = validate;

    // Validation errors should persist in the form until they're resolved.
    const inputFieldValueIsChanged = !_isNull(field);
    const fieldValueWasInvalid = Object.prototype.hasOwnProperty.call(this.state.errors, field);
    const errorsAreResolved = _isEmpty(errors);

    const fieldValueIsNowValid = (
      inputFieldValueIsChanged && fieldValueWasInvalid && errorsAreResolved
    );

    if (fieldValueIsNowValid)
      this.setState({
        errors: _omit(this.state.errors, [field])
      });

    else {
      /* if isValid is called before trying to create new skill or trying to add
      new evidence; which results in an invalid form.
      */
      this.setState({
        errors: Object.assign({}, this.state.errors, errors)
      });
    }

    return isValid;
  }

  onChange(event) {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    const {
      skillBeingUpdated
    } = this.state;

    this.setState({
      skillBeingUpdated: Object.assign({}, skillBeingUpdated,{
        [fieldName]: fieldValue
      }),
      allowUpdate: true
    }, () => this.isValid(fieldName));
  }

  onSave(event) {
    event.preventDefault();

    const { skillBeingUpdated } = this.state;

    const {
      actions,
      skills
    } = this.props;

    if (!this.isValid())
      return this.focusOnInputField();

    if (this.checkIfValuesAreUnchanged())
      return toastr.warning('Values are unchanged');

    const index = skills.findIndex(element => element.id === skillBeingUpdated.id);

    actions.updateSkill(index, skillBeingUpdated)
      .then(() => {
        toastr.success('Skill Updated');

        this.onCancel();
      });
  }

  onCancel() {
    const {
      editItemIsClicked
    } = this.props;

    const initialState = {
      errors: {},
      skillBeingUpdated: {},
      allowUpdate: false,
      askUserBeforeAction: false,
      modalMessage: 'Are you sure you want to delete this skill?',
      modalFooterButtonText: 'Delete',
      modalHeaderText: 'Delete Skill'
    };

    editItemIsClicked();

    this.setState(initialState);
  }

  handleKeyPress(event) {
    let onEnter = this.onSave;
    const id = event.target.id;

    if (id === 'skill-evidenceTitle' || id === 'skill-evidenceURL')
      onEnter = this.addEvidence;

    handleKeyDown(event, onEnter);
  }

  focusOnInputField(field = null) {
    // focus on 1st field with an error.
    if (_isNull(field)) {
      return setTimeout(() => {
        const {
          errors,
          focusPreference
        } = this.state;

        const keysArr = _keys(errors);

        for (let item of focusPreference) {
          const match = keysArr.filter(field => field === item);

          if (match.length === 1)
            return document.getElementById(`skill-${match[0]}`).focus();
        }
      }, 100);
    }

    // focus on a given field.
    document.getElementById(field).focus();
  }

  checkIfValuesAreUnchanged() {
    const keysToBePicked = [
      'name', 'permission', 'evidenceTitle', 'evidenceURL', 'evidence'
    ];

    const skillFormInState = _pick(
      this.state.skillBeingUpdated, keysToBePicked
    );

    const skillFormInStore = _pick(
      this.props.skillBeingUpdated, keysToBePicked
    );

    for (let i = 0; i < keysToBePicked.length; i++) {
      const key = keysToBePicked[i];

      if (skillFormInState[key] !== skillFormInStore[key])
        return false;
    }

    this.setState({
      allowUpdate: false
    });

    return true;
  }

  onDelete() {
    const { skillBeingUpdated } = this.state;
    const { actions, skillsOrder, userId } = this.props;

    this.setState({
      isDeletingSkillAndUpdatingSkillsOrder: true,
      modalFooterButtonText: 'Deleting...'
    }, async () => {
      const updatedSkillsOrderObj = {
        skillsOrder: skillsOrder.slice(0).map(skill => skill.id).filter(id => id !== skillBeingUpdated.id).join(),
      };

      await actions.deleteSkill(skillBeingUpdated.id);
      actions.updateSkillsOrder(updatedSkillsOrderObj, userId);
      await actions.updateMyCareerPreppedStatus();
    });
  }

  toggleModal() {
    return this.setState({
      askUserBeforeAction: !this.state.askUserBeforeAction,
      modalMessage: 'Are you sure you want to delete this skill?',
      modalFooterButtonText: 'Delete',
      modalHeaderText: 'Delete Skill'
    });
  }

  toggleAllSkills(){
    this.setState({
      showAllSkills: !this.state.showAllSkills
    });
  }

  render() {
    const {
      skillsOrder,
      skills,
      reArrangeSkills,
      isPublicProfile,
      isItemBeingEdited,
      editItemIsClicked,
      isUpdating,
    } = this.props;

    const {
      errors,
      skillBeingUpdated,
      allowUpdate,
      isDeletingSkillAndUpdatingSkillsOrder,
      askUserBeforeAction,
      modalMessage,
      modalFooterButtonText,
      modalHeaderText,
      showAllSkills
    } = this.state;

    const visibleSkillsOrder = skillsOrder;

    const showMoreCondition = visibleSkillsOrder.length > 5;
    const skillsArray =
      showMoreCondition && !showAllSkills
        ? visibleSkillsOrder.slice(0, 5)
        : visibleSkillsOrder;

    if (isItemBeingEdited)
      return (
        <div>
          <SkillForm
            skill={skillBeingUpdated}
            errors={errors}
            onChange={this.onChange}
            onSave={this.onSave}
            onCancel={this.onCancel}
            handleKeyPress={this.handleKeyPress}
            focusInitially
            allowUpdate={allowUpdate}
            isUpdating={isUpdating.status}
            isDeletingSkillAndUpdatingSkillsOrder={isDeletingSkillAndUpdatingSkillsOrder}
            onDelete={this.toggleModal}/>

          <ProfileModal
            isOpen={askUserBeforeAction}
            toggle={this.toggleModal}
            headerText={modalHeaderText}
            body={modalMessage}
            onClick={
              modalFooterButtonText === 'Remove' ?
                this.removeEvidence : this.onDelete
            }
            disabled={isDeletingSkillAndUpdatingSkillsOrder}
            buttonText={modalFooterButtonText}/>
        </div>
      );

    // To eliminate extra space.
    if (skillsOrder.length === 0)
      return (
        <div className="empty-section-message">
          <p>Add skills to let people know what you can do (e.g. Photoshop, Project Management, Teamwork, etc.)</p>
        </div>
      );

    return(
      <div className="row">
        <div className="col-lg-12">
          {skillsArray.map((order, index) => {
            const skill = _find(skills, item => item.id === order.id);

            if(_isUndefined(skill))
              return null;

            return (
              <SkillCardHandler
                key={uniqueId()}
                skill={skill}
                index={index}
                reArrangeSkills={reArrangeSkills}
                isPublicProfile={isPublicProfile}
                editItemIsClicked={editItemIsClicked} />);
          })}
          {
            showMoreCondition &&
              <div
                style={{borderRadius: 3, backgroundColor: '#001d41', display: 'inline-block'}}
                className="mb-2 mr-2 more-pill">
                <a
                  onClick={this.toggleAllSkills}
                  className="clickable orange">
                  <span style={{ color: 'white' }}>{showAllSkills ? 'show less' : `+${skillsOrder.length - 5} more`}</span>
                </a>
              </div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    skillBeingUpdated: state.skills.skillBeingUpdated,
    isUpdating: state.skills.isUpdating,
    isDeleting: state.skills.isDeleting.status,
    userId: state.auth.data.currentUser.id
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, skillActions, profileActions, cpStatusActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillsList);
