import PropTypes from 'prop-types';
import React from 'react';

import CommentCardContainer from '../../../containers/skill-builders/discussions/CommentCardContainer';

const CommentList = ({
  discussionId,
  comments,
  loadForms,
  commentShowMore,
  onCommentShowMore
}) => {
  return (
    <div>
      {comments.map((comment, index) =>
        (<div key={index}>
          <CommentCardContainer
            key={comment.id}
            commentData={comment}
            loadForms={loadForms}
            commentShowMore={commentShowMore}
            onCommentShowMore={onCommentShowMore}
            discussionId={discussionId}/>
        </div>))}
    </div>
  );
};

CommentList.propTypes = {
  comments: PropTypes.array.isRequired,
  loadForms: PropTypes.array.isRequired,
  onCommentShowMore: PropTypes.func.isRequired,
  commentShowMore: PropTypes.object.isRequired,
  discussionId: PropTypes.string.isRequired
};

export default CommentList;
