import { isUndefined } from 'lodash';

import initialState from './initialState';

import {
  ORGANIZATION_USERS_REQUEST,
  ORGANIZATION_USERS_SUCCESS,
  ORGANIZATION_USERS_FAILURE,
  ORGANIZATION_USERS_FILTERED_REQUEST,
  ORGANIZATION_USERS_FILTERED_SUCCESS,
  ORGANIZATION_USERS_FILTERED_FAILURE,
  ORGANIZATION_USERS_FILTERED_CLEAR_SUCCESS,
  ORGANIZATION_GROUP_USERS_FILTERED_REQUEST,
  ORGANIZATION_GROUP_USERS_FILTERED_SUCCESS,
  ORGANIZATION_GROUP_USERS_FILTERED_FAILURE,
  FOLLOW_AN_USER_ADMIN_PANEL_REQUEST,
  FOLLOW_AN_USER_ADMIN_PANEL_SUCCESS,
  FOLLOW_AN_USER_ADMIN_PANEL_FAILURE,
  UNFOLLOW_AN_USER_ADMIN_PANEL_REQUEST,
  UNFOLLOW_AN_USER_ADMIN_PANEL_SUCCESS,
  UNFOLLOW_AN_USER_ADMIN_PANEL_FAILURE,
  ONCHANGE_ORGANIZATION_USERS_SEARCH_QUERY,
  ONCHANGE_ORGANIZATION_USERS_LOCATION,
  ONCHANGE_ORGANIZATION_USERS_EMPLOYMENT_TYPE,
  ONCHANGE_ORGANIZATION_USERS_EMPLOYMENT_STATUS,
} from '../../constants';

export default function reducer(
  state = initialState.organizationUsers,
  action
) {
  switch (action.type) {
  case ORGANIZATION_USERS_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };

  case ORGANIZATION_USERS_SUCCESS:
    return {
      ...state,
      data: action.data._embedded.users,
      page: isUndefined(action.data.page)
        ? initialState.organizationUsers.page
        : action.data.page,
      pageSize: action.data.page_size,
      pageCount: isUndefined(action.data.page_count)
        ? initialState.organizationUsers.pageCount
        : action.data.page_count,
      isRequesting: false,
    };

  case ORGANIZATION_USERS_FAILURE:
    return {
      ...state,
      error: action.error,
      isRequesting: false,
    };

  case ORGANIZATION_USERS_FILTERED_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };

  case ORGANIZATION_USERS_FILTERED_SUCCESS:
    return {
      ...state,
      data: [...state.data, ...action.data._embedded.users],
      isRequesting: false,
    };

  case ORGANIZATION_USERS_FILTERED_FAILURE:
    return {
      ...state,
      error: action.error,
      isRequesting: false,
    };

  case ORGANIZATION_USERS_FILTERED_CLEAR_SUCCESS:
    return {
      ...state,
      data: [],
    };

  case ORGANIZATION_GROUP_USERS_FILTERED_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };

  case ORGANIZATION_GROUP_USERS_FILTERED_SUCCESS:
    return {
      ...state,
      data: action.data._embedded.users,
      isRequesting: false,
    };

  case ORGANIZATION_GROUP_USERS_FILTERED_FAILURE:
    return {
      ...state,
      error: action.error,
      isRequesting: false,
    };

  case FOLLOW_AN_USER_ADMIN_PANEL_REQUEST:
  case UNFOLLOW_AN_USER_ADMIN_PANEL_REQUEST:
    return {
      ...state,
      isFollowing: {
        status: true,
        id: action.id,
      },
    };

  case FOLLOW_AN_USER_ADMIN_PANEL_SUCCESS: {
    const { data, isFollowing } = state;
    const { id } = isFollowing;
    const index = data.findIndex(obj => obj.id === id);
    const modifiedObject = Object.assign({}, data[index], {
      isUserFollower: {
        status: true,
        connectId: action.data.record,
      },
    });
    return {
      ...state,
      data: [
        ...data.slice(0, index),
        modifiedObject,
        ...data.slice(index + 1),
      ],
      isFollowing: {
        status: false,
        index: null,
      },
    };
  }

  case FOLLOW_AN_USER_ADMIN_PANEL_FAILURE:
  case UNFOLLOW_AN_USER_ADMIN_PANEL_FAILURE:
    return {
      ...state,
      isFollowing: {
        status: false,
        index: null,
      },
    };

  case UNFOLLOW_AN_USER_ADMIN_PANEL_SUCCESS: {
    const { data, isFollowing } = state;
    const { id } = isFollowing;
    const index = data.findIndex(obj => obj.id === id);
    const modifiedObject = Object.assign({}, data[index], {
      isUserFollower: {
        status: false,
        connectId: '',
      },
    });
    return {
      ...state,
      data: [
        ...data.slice(0, index),
        modifiedObject,
        ...data.slice(index + 1),
      ],
      isFollowing: {
        status: false,
        index: null,
      },
    };
  }

  case ONCHANGE_ORGANIZATION_USERS_SEARCH_QUERY:
    return Object.assign({}, state, {
      filters: {
        ...state.filters,
        usersSearchQuery: action.usersSearchQuery,
      },
    });

  case ONCHANGE_ORGANIZATION_USERS_LOCATION:
    return Object.assign({}, state, {
      filters: { ...state.filters, usersLocation: action.usersLocation },
    });

  case ONCHANGE_ORGANIZATION_USERS_EMPLOYMENT_TYPE:
    return Object.assign({}, state, {
      filters: {
        ...state.filters,
        usersEmploymentType: action.employmentType,
      },
    });

  case ONCHANGE_ORGANIZATION_USERS_EMPLOYMENT_STATUS:
    return Object.assign({}, state, {
      filters: {
        ...state.filters,
        usersEmploymentStatus: action.employmentStatus,
      },
    });

  default:
    return state;
  }
}
