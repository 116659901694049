import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { forEach } from 'lodash';
import { withHooks } from '../../utils/withHooks';

import * as networksActions from '../../redux/actions/networks';
import * as authActions from '../../redux/actions/authentication';

import Webpage from './visiting/Webpage';
import OwnerProfile from './OwnerProfile';

class ProfileWrapper extends Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    isPublicProfile: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired,
    isOwner: PropTypes.bool,
    isUpdating: PropTypes.bool,
    params: PropTypes.object,
    modal: PropTypes.string,
  }

  componentDidMount() {
    const { actions, isAuthenticated } = this.props;

    if (isAuthenticated) {
      actions.checkForToken();
    }
  }

  getAdminInstitutionIds = () => {
    let institutionIds = [];
    const { profile: { adminInstitutions } } = this.props;

    forEach(adminInstitutions, (institution) => {
      institutionIds.push(institution.id);
    });

    return institutionIds;
  }

  render() {
    const { profile, currentUser, params, isAuthenticated } = this.props;

    const [,, urlSlug, previewPathname] = location.pathname.split('/');
    const isOwner = isAuthenticated && urlSlug === profile.vanity;
    const isPublicProfile = previewPathname === 'preview';
    const adminInstitutionIds = this.getAdminInstitutionIds();

    if (!isOwner) {
      return (
        <div>
          <Webpage
            vanity={params.slug}
            currentUser={currentUser}
            adminInstitutionIds={adminInstitutionIds}/>
        </div>
      );
    }

    return <OwnerProfile isOwner={isOwner} isPublicProfile={isPublicProfile}/>;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { profile, auth } = state;
  const splittedURL = ownProps.location.pathname.split('/');
  const isPublicProfile = splittedURL[3] === 'preview';

  return {
    isPublicProfile,
    currentUser: auth.data.currentUser,
    isAuthenticated: state.auth.isAuthenticated,
    profile: profile.data
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, networksActions, authActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};
export default withHooks(connect(mapStateToProps, mapDispatchToProps)(ProfileWrapper));
