export const ALL_GROUPS = 'all-groups';
export const REMOVE_ADMIN_GROUP = 'admin-group-remove-admin';
export const ADMIN_REMOVED_MESSAGE = 'Admin removed successfully.';
export const NAME = 'Name';
export const ACTIONS = 'Actions';
export const SELECT_GROUPS = 'Select group to display admins.';
export const DEFAULT_GROUPS_MESSAGE = 'This group doesn\'t have any admins yet.';
export const USERS = 'Users';
export const NAME_NEW_GROUPS = 'Name New Group(s)';
export const GROUP_NAME = 'Group Name';
export const EMPTY_GROUP_MESSAGE = 'Group name cannot not be empty';
export const GROUPS_FORM_MESSAGE = {
  addGroup: 'Need to add another group?',
  clickToAdd: 'Click to add another',
  groupNamePlaceholder: 'Enter group name',
};
export const GROUP_LOCATIONS = 'Ground Location(s):';
export const GROUP_LOCATIONS_FORM = {
  locationName: 'Ground Location Name',
  locationNamePlaceholder: 'Enter Ground Location Name',
  locationNameEmpty: 'Ground Location Name should not be empty',
  locationDesignation: 'Designate Admins for this ground location',
  locationHelperText:
    'Ground location admins will be able to invite users and view stats for this ground location. You can designate as many admins as you\'d like or none if you prefer. You can also skip this for now and add admins later if you wish.',
  emailPlaceHolder: 'Enter emails separated by commas ',
  emailValidationMessage: 'Please enter valid emails',
  singleEmailValidationMessage: 'Please enter valid email',
  groupSelectionValidation: 'Select a group to add an admin.',
};
export const DESIGNATE_ADMIN = 'Designate Admins for this group';
export const OPTIONAL = '(*Optional)';
export const ONLINE_PROGRAMS = 'Online Program(s):';
export const ONLINE_PROGRAM_FORM = {
  programName: 'Online Program Name',
  programNamePlaceholder: 'Enter Online program Name',
  programNameValidation: 'Online Program Name should not be empty',
  programDesignation: 'Designate Admins for this online program',
  programHelperText:
    'Online program admins will be able to invite users and view stats for this online program. You can designate as many admins as you\'d like or none if you prefer. You can also skip this for now and add admins later if you wish.',
};
export const DATE_FORMAT = 'MM/DD/YY';
export const ACCEPTED = 'accepted';
export const REVOKED = 'revoked';
export const COPY_LINK = 'Copy Link';
export const ADMIN_GROUP_ADD_ADMIN = 'admin-group-add-admin';
export const ADMIN_INVITATION_SUCCESS = 'Successfully invited new admin(s)';
export const ADD_NEW_GROUPS = 'Add New Group(s)';
export const ADD_GROUPS_COUNT_MESSAGE =
  'How many new groups do you want to add?';
export const CONFIRM_UPDATE_CODE = 'confirmUpdateCode';
export const ADMIN_GROUP_EDIT = 'admin-group-edit';
export const ADD_NEW_ADMIN = 'Add New Admin';
export const DUPLICATE_GROUPS_VALIDATION =
  'You have duplicate group names. Please choose a unique name for each group you create.';
export const ENDORSEMENT_VALIDATION = {
  checkbox: 'Please acknowledge your observation',
  jobTitle: 'Kindly enter your Job Title',
  organization: 'Kindly enter your Organization',
  jobOrganization: 'Kindly enter your Job Title and Organization',
};
export const IR_FORM = {
  careerClusterPlaceholder: 'Choose Career Cluster',
  chooseIndustryPlaceholder: 'Choose Industry',
  chooseOccupationPlaceholder: 'Choose Occupation',
  email: 'Email',
  alternativeEmail: 'Alternative email',
  firstName: 'First Name',
  lastName: 'Last Name',
  jobTitle: 'Job Title',
  companyName: 'Company Name',
  country: 'Country',
  stateProvince: 'Region/State',
  city: 'City/Town',
};
export const ENDORSEMENT_CONFIRM = name =>
  `Before you finish, would you like to endorse ${name} for having this skill based on your own observation of ${name} demonstrating this skill?`;
export const SETTINGS_DATE_FORMAT = 'YYYY-MM-DD';
export const PAUSE_REQUESTS_TOOLTIP =
  'When you\'re unavailable to give feedback, pause requests for a set time until you\'re available again.';
export const CSV_OPEN_VALIDATION =
  'Please use a .csv file containing emails only in a single column or row. Remove any headers from your document.';
export const MANAGE_GROUP_INFO = 'Your account lets you create unlimited groups (10 at a time). For each, you can designate admins who can create their own groups with admins who can do the same. This lets you create a group hierarchy reflecting your organization\'s needs. You\'ll be able to see stats for all groups you create, and all the "child" groups other admins create nested within your own as your groups represent the "top of the hierarchy".';
export const GROUP_CODE_INFO = 'Each group created will have a unique group code that can be shared with users who can sign themselves up at ';
export const IR_SUCCESS = 'The industry mentor was successfully added.';
export const IR_REMOVE = 'The Industry Mentor is succesfully removed.';
export const CREATE_IR_CONFIRMATION =
  'Are you sure you want to create this Industry mentor account?';
export const CANCEL = 'Cancel';
export const YES_ADD = 'Yes, Add';
export const ADD_IR = 'Add Industry Mentor';
export const ADD = 'Add';
export const ADD_IR_REP = 'Add Industry Mentor';
export const PORTFOLIO_FILES = 'Portfolio Files';
export const USER_AVATAR = 'User avatar';
export const ITS_YOU = 'It\'s you';
export const GROUP_ADMIN = 'Group Admin';
export const ORG_ADMIN = 'Organization Admin';
export const ORG_LIST = 'List of Organizations';
export const ADD_ORG = 'Add Organization';
export const MESSAGE = 'Message';
export const UPDATING_GROUP_CODE = 'Updating group code...';
export const UPDATE_CODE = groupName => `Update Group Code for ${groupName}`;
export const UPDATE_CODE_CONFIRM = groupName =>
  `Are you sure you want to update the group code for ${groupName}?`;
export const UPDATE_CODE_CONFIRM_GENERATE = groupName =>
  `Once you click "Yes, Update" below, a new group code will be generated for ${groupName}.`;
export const YES_UPDATE = 'Yes, Update';
export const ADMIN = 'Admin';
export const CANT_REVOKE = 'Can\'t revoke';
export const REVOKE = 'Revoke';
export const SKILL_BADGES = 'Skill Badges';
export const NOT_PROVIDED = 'Not Provided';
export const REMOVE_ALL = 'Remove All';
export const SEARCH = 'Search';
export const NEXT = 'Next';
export const BACK = 'Back';
export const REQUEST_FEEDBACK = 'Request Feedback';
export const BULK_ACTIONS = 'Bulk Actions';
export const SEARCH_CRITERIA_VALIDATION =
  'You must enter search criteria to conduct a search.';
export const FILTER_BY = 'FILTER BY';
export const CLEAR = 'CLEAR';
export const LOCATION = 'Location';
export const SEARCHING = 'Searching...';
export const EDIT = 'Edit';
export const SEE_INVITES = 'See Invites';
export const NONE_DESIGNATED = 'None Designated';
export const ORGANIZATION = 'Organization';
export const NO_RECORDS = 'Sorry, we couldn\'t find anything!';
export const SEARCH_INVITATIONS = 'Search invitations...';
export const MAX_FILE_NO = 10;
export const MAX_UPLOAD_ERROR_MSG = 'A maximum of 10 files are allowed as evidence for a skill';
export const PEERS_AND_FOLLOWERS_REQUEST = `You’re able to request feedback from community members
                                      you’re following or who are following you. Start following
                                    members to be able to request feedback.`;
export const REVISED_TESTIMONIAL_SUCCESS = 'Testimonial Revised';
