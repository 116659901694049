import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as componentsActions from '../../../redux/actions/components';
import { bindActionCreators } from 'redux';
import { loadForm, scrollToTop } from '../../../utils';
import { toastr } from 'react-redux-toastr';

class ReplyButton extends Component {
  static propTypes = {
    commentId: PropTypes.string,
    actions: PropTypes.object,
    forms: PropTypes.array,
    isAuthenticated: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);

    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    event.preventDefault();

    const { commentId, forms, actions, isAuthenticated } = this.props;

    if (!isAuthenticated) {
      scrollToTop();
      return toastr.warning('Please Login/Signup');
    }

    const form = `reply-form-commentId-${commentId}`;

    if (!loadForm(forms, form)) {
      return actions.loadForm(form);
    }

    document.getElementById(form).focus();
  }

  render() {
    return(
      <span style={{paddingRight:5}}>
        <a
          onClick={this.onClick}
          className="reply-button">
          Reply
        </a>
      </span>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    forms: state.components.forms,
    isAuthenticated: state.auth.isAuthenticated
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = componentsActions;

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReplyButton);
