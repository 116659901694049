import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as categoryActions from '../../redux/actions/sb-category';
import SkillBuildersModal from '../../components/home/SkillBuildersModal';

class SkillBuildersModalContainer extends Component {
  static propTypes = {
    isRequesting: PropTypes.bool.isRequired,
    toggleOrgContent: PropTypes.bool,
    showSkillBuildersModal: PropTypes.bool.isRequired,
    skillbuilders: PropTypes.array.isRequired,
    toggleSkillBuildersModal: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    skillbuilderCategory: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state={
      skillBuildersLookupTable: []
    };

    this.handleCollapse = this.handleCollapse.bind(this);
  }

  componentWillMount() {
    this.props.actions.fetchSBcategory();
  }

  componentWillReceiveProps(nextProps){
    const { data } = this.props.skillbuilderCategory;
    const { isRequesting, data: nextCat } = nextProps.skillbuilderCategory;

    if(!isRequesting && data !== nextCat){
      const categoryArray = nextCat.map((cat) => {
        let data = {
          id: cat.id,
          name: cat.title,
          icon: ''
        };
        switch (cat.description) {
        case '1':
          data.icon = require('../../assets/images/sb-job-seeking.png');
          break;
        case '2':
          data.icon = require('../../assets/images/sb-self-management.png');
          break;
        case '3':
          data.icon = require('../../assets/images/sb-communication.png');
          break;
        case '5':
          data.icon = require('../../assets/images/sb-analytical.png');
          break;
        case '6':
          data.icon = require('../../assets/images/sb-planning.png');
          break;
        case '4':
          data.icon = require('../../assets/images/sb-people.png');
          break;
        }

        return data;
      });

      this.setState({
        skillBuildersLookupTable: [...categoryArray]
      });
    }
  }

  handleCollapse(category){
    const { skillBuildersLookupTable }= this.state;

    return ()=>{
      const resolveTable = skillBuildersLookupTable.map((item)=>{
        if (item.id === category){
          const obj = { ...item, open: !item.open};
          return obj;
        }
        return item;
      });

      this.setState({ skillBuildersLookupTable: resolveTable});
    };
  }

  render() {
    const { skillBuildersLookupTable } = this.state;
    const {
      isRequesting,
      skillbuilders,
      showSkillBuildersModal,
      toggleOrgContent,
      toggleSkillBuildersModal ,
      skillbuilderCategory
    } = this.props;

    return (
      <SkillBuildersModal
        isRequesting={isRequesting || skillbuilderCategory.isRequesting}
        skillBuildersLookupTable={skillBuildersLookupTable}
        skillbuilders={skillbuilders}
        isOrg={toggleOrgContent}
        showSkillBuildersModal={showSkillBuildersModal}
        toggleSkillBuildersModal={toggleSkillBuildersModal} />);
  }
}

const mapStateToProps = (state) => {
  return {
    skillbuilderCategory: state.skillbuilderCategory
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, categoryActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(SkillBuildersModalContainer);
