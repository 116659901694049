const EXAMPLES = {
  'experience': [
    {
      'tabTitle':'Marketing',
      'tabNumber':'1',
      'tabId': '1',
      'exampleTitle': 'Example of a Marketing Research Intern',
      'exampleBody': [
        'Coordinated 30 focus groups across 20 states to support market research team with consumer segmentation study for a high fashion retail client; identified three key consumer segments',
        'Simplified large data sets by using Pivot Tables in Excel to generate easy-to-understand reports',
        'Presented on one consumer segment to senior leaders; received praise for clear and engaging content'
      ]
    },
    {
      'tabTitle': 'Barista',
      'tabNumber': '2',
      'tabId': '2',
      'exampleTitle': 'Example of a Barista:',
      'exampleBody': [
        'Greet and serve over 250 customers daily; build customer loyalty by memorizing customers’ names and drink preferences to create a friendly, personalized customer experience',
        'Receive and process payment transactions and balance cash drawer at end of shift',
        'Clean and sterilize coffee brewing equipment daily to ensure food safety protocols',
        'Prepare specialty coffee drinks per instructions while ensuring adherence to company standards',
        'Trained four new employees on inventory management and point of sale (POS) systems to improve new-hires’ speed to proficiency'
      ]
    },
    {
      'tabTitle': 'Deskop Support',
      'tabNumber': '3',
      'tabId':'3',
      'exampleTitle':'Example of a Desktop Support Volunteer:',
      'exampleBody': [
        'Volunteer 5 hours a week diagnosing and troubleshooting systems issues for this community computer lab comprising 25 desktop computers and 2 network printers within a Windows network environment',
        'Collaborated with Lead PC Technician to install and update Symantec anti-virus software',
        'Resolved end-users’ complaints about slow boot times and sporadic internet connectivity by defragmenting hard drives, updating problematic device drivers, and removing programs from startup',
        'Shadowed and observed network support technicians during installation of two Cisco routers and one switch; learned basic configuration settings and fundamentals of network cabling',
        'Re-image desktops using Symantec Ghost for system updates and repairs'
      ]
    }
  ],
  'summary': [
    {
      'tabTitle': 'Web Designer',
      'tabNumber': '1',
      'tabId': '1',
      'exampleTitle': 'Description of an Aspiring Web Designer:',
      'exampleBody': [
        'I am currently pursuing a career as a Web Designer with plans to become an Adobe Certified Expert (ACE) in Adobe Photoshop and Illustrator within the next 6 months.',
        'I have a talent for designing creative and functional websites and enjoy meeting the challenge of combining beauty, simplicity, and usability in my designs to drive business goals. As a soon-to-be graduate of a web design program with over a year of volunteer experience in a web master/design function, I am positioning myself for entry level web design roles. From basic web design skills like user interface design to more advanced skills such as e-commerce integration, search engine optimization, and analytics, I have already gained exposure to web design knowledge that will allow me to make a positive impact.'
      ]
    },
    {
      'tabTitle': 'Accountant',
      'tabNumber': '2',
      'tabId': '2',
      'exampleTitle': 'Description of an Aspiring Accountant:',
      'exampleBody': [
        'My ultimate goal is to become a Certified Public Accountant (CPA).',
        'I enjoy working with numbers and look forward to working within a set of parameters to ethically drive improvements in revenues, profits, and EBITDA for small or medium size businesses. In addition to pursuing my Bachelor’s degree in Accounting, I’ve been fortunate to gain experience in financial auditing, preparing financial reports, filing payroll documents, and reconciling employee paid invoices through two internships I’ve completed. I’m currently interested in a part-time position or internship in an accounting-related function and am open to volunteering for a non-profit organization.'
      ]
    },
    {
      'tabTitle': 'Digital Marketer',
      'tabNumber': '3',
      'tabId': '3',
      'exampleTitle': 'Description of an Aspiring Digital Marketer:',
      'exampleBody': [
        'My name is John Doe and I’m currently pursuing my Bachelor of Science degree in Marketing.',
        'As a full-time college student, I’m focused on building my skills to pursue a career as a marketing professional. I have a love for digital media and find myself constantly using the latest social media tools so that I can analyze them and understand how consumers are using them. I “geek out” on technology and consider myself to be highly analytical. I’m very interested in diving into web analytics and cultivating my skills in analyzing data to make sense of consumer behavior.',
        'I’m currently seeking internship, volunteer, and part-time work opportunities in a marketing function, preferably helping with social media marketing, web analytics, or search engine optimization.'
      ]
    }

  ],
  'headlines' : [
    {
      'tabTitle': 'Marketing',
      'tabNumber': '1',
      'tabId': '1',
      'exampleTitle': 'Headline of a Person with Marketing Experience:',
      'exampleBody': [
        'Aspiring Marketing Pro focusing on Social Media Marketing (SMM)'
      ]
    },
    {
      'tabTitle': 'Accounting',
      'tabNumber': '2',
      'tabId': '2',
      'exampleTitle': 'Headline of a Person with no Experience Targeting an Accounting Role: ',
      'exampleBody': [
        'Accounting specialist seeking next opportunity | CPA Candidate'
      ]
    },
    {
      'tabTitle': 'Medical Assisting',
      'tabNumber': '3',
      'tabId': '3',
      'exampleTitle': 'Headline of a Person with Medical Assisting Experience:',
      'exampleBody': [
        'Certified Medical Assistant | Bi-Lingual in English and Spanish'
      ]
    }
  ],
  'Activities & Clubs' :[
    {
      'tabTitle': '',
      'tabNumber': '1',
      'tabId': '1',
      'exampleTitle': 'Examples of Activities & Clubs:',
      'exampleBody': [
        'Career Club',
        'Math Club',
        'Dance Team',
        'Women in STEM',
        'Amnesty International',
        'Rotary Club',
        'Coding Bootcamp'
      ]
    },
  ],
  'Honors & Awards' :[
    {
      'tabTitle': '',
      'tabNumber': '1',
      'tabId': '1',
      'exampleTitle': 'Examples of Honors & Awards:',
      'exampleBody': [
        'Volunteer of the Year',
        'Dean’s List',
        'National Merit Scholarship',
        'Best Speaker',
        'Customer Service Award'
      ]
    },
  ],
  'associations': [
    {
      'tabTitle': '',
      'tabNumber': '1',
      'tabId': '1',
      'exampleTitle': 'Associations Examples',
      'exampleBody': [
        'The Society for Human Resource Management (SHRM)',
        'Association of Information Technology Professionals (AITP)',
        'Medical Students Association',
        'Freelancer’s Union',
        'Graphic Artists Guild (GAG)'
      ]
    },
  ],
  'projects' : [
    {
      'tabTitle': 'Fundraising',
      'tabNumber': '1',
      'tabId': '1',
      'exampleTitle': 'Example of a Fundraising Project',
      'exampleBody': [
        'Conceptualized, planned, and coordinated annual block party fundraising event for my neighborhood Block Watch Association achieving $8,000 in in-kind donations and $2,500 in proceeds.'
      ]
    },
    {
      'tabTitle': 'Website',
      'tabNumber': '2',
      'tabId': '2',
      'exampleTitle': 'Example of a Website Project',
      'exampleBody': [
        'Assisted my church in creating a new website. I selected a WordPress theme, identified primary goals of the website, and established the site organization including main navigation and sub-menus to align with goals. Wrote web content and collaborated with designer for layout customization and graphics.'
      ]
    },
    {
      'tabTitle': 'Community Service',
      'tabNumber': '3',
      'tabId': '3',
      'exampleTitle': 'Example of a Community Service Project:',
      'exampleBody': [
        'Conceptualized, organized, and planned a neighborhood cleanup event. Recruited a committee consisting of 5 neighbors willing to donate necessary resources for the event such as tables, chairs, and trash bags. Led the development of a task schedule and resources list to prepare and execute a cleanup event within a 6-week timeframe. Delegated marketing activities such as flyer creation & distribution as well as social media posts, and gained sponsorship from a local coffee shop to provide free coffee and bagels. Event resulted in 50 participants and 25 blocks of trash and recycling cleanup and disposal.'
      ]
    },
  ],
  'Resume Experience' : [
    {
      'tabTitle': 'Sales Associate',
      'tabNumber': '1',
      'tabId': '1',
      'exampleTitle': 'Sales Associate',
      'exampleBody': [
        'Provided excellent customer service by maintaining store appearance to create a welcoming environment, greeting customers upon store entrance, and proactively assisting customers',
        'Used a consultative sales approach by asking probing questions and actively listening to identify customer needs and recommend products that matched customer preferences',
        'Successfully managed a cash drawer processing >$2K worth of transactions daily resulting in a balanced cash drawer, therefore reducing store losses and ensuring profitability'
      ]
    },
    {
      'tabTitle': 'Barista',
      'tabNumber': '2',
      'tabId': '2',
      'exampleTitle': 'Barista',
      'exampleBody': [
        'Greet and serve over 250 customers daily; build customer loyalty by memorizing customers’ names and drink preferences to create a friendly, personalized customer experience',
        'Receive and process payment transactions and balance cash drawer at end of shift',
        'Clean and sterilize coffee brewing equipment daily to ensure food safety protocols',
        'Prepare specialty coffee drinks per instructions while ensuring adherence to company standards',
        'Trained four new employees on inventory management and point of sale (POS) systems to improve new-hires’ speed to proficiency'
      ]
    },
    {
      'tabTitle': 'Desktop Support Volunteer',
      'tabNumber': '3',
      'tabId': '3',
      'exampleTitle': 'Desktop Support Volunteer',
      'exampleBody': [
        'Volunteer 5 hours a week diagnosing and troubleshooting systems issues for this community computer lab comprising 25 desktop computers and 2 network printers within Windows network environment',
        'Collaborated with Lead PC Technician to install and update Symantec anti-virus software',
        'Resolved end-users’ complaints about slow boot times and sporadic Internet connectivity by defragmenting hard drives, updating problematic device drivers, and removing programs from startup',
        'Shadowed and observed network support technicians during installation of two Cisco routers and one switch; learned basic configuration settings and fundamentals of network cabling',
        'Re-image desktops using Symantec Ghost for system updates and repairs'
      ]
    },
    {
      'tabTitle': 'Server',
      'tabNumber': '4',
      'tabId': '4',
      'exampleTitle': 'Server',
      'exampleBody': [
        'Take and deliver orders for up to 150 customers daily in a fast-paced, multitasking environment',
        'Deliver excellent customer service through warm attitude and friendly smile, resulting in a tip rate 10% higher than the average server',
        'Work closely with clients, kitchen staff, and management team to coordinate special events and banquet services for corporate clients',
        'Resolve customer complaints and order mistakes with patience and attention to detail'
      ]
    }
  ],
  'Resume Summary' : [
    {
      'tabTitle': 'Marketing Research',
      'tabNumber': '1',
      'tabId': '1',
      'exampleTitle': 'CAREER TARGET: MARKETING RESEARCH ANALYST',
      'exampleBody': [
        'Pursuing Bachelor of Science in Marketing with coursework in research and statistics.',
        'Completed a market research project examining factors influencing foreign market penetration for a US-based company, including analysis of local market trends and consumer preferences.',
        'Developed data analysis and visualization skills using Microsoft (MS) Excel.'
      ]
    },
    {
      'tabTitle': 'Medical Assistant',
      'tabNumber': '2',
      'tabId': '2',
      'exampleTitle': 'Summary of Qualifications',
      'exampleBody': [
        'Medical assisting student who has completed 60 hours of lab training in clinical patient care and possesses a sound understanding of medical office administration practices. Personable and professional with a natural ability to develop rapport with patients and strong working relationships with peers, physicians, and administrators.'
      ]
    },
    {
      'tabTitle': 'PC Support',
      'tabNumber': '3',
      'tabId': '3',
      'exampleTitle': 'CAREER TARGET: DESKTOP SUPPORT / PC TECHNICIAN',
      'exampleBody': [
        'Microsoft systems specialist capable of diagnosing and troubleshooting general hardware and software issues, installing and configuring operating systems and PC applications, and guiding end-users through appropriate steps to resolve technical challenges.',
        'Excellent analytical problem-solving abilities and a talent for mastering new technologies quickly.',
        'Competent communicator who can perform technical writing, procedural documentation, and oral presentations skillfully.'
      ]
    }
  ],
  'Resume Skills' : [
    {
      'tabTitle': 'Variety of Skills',
      'tabNumber': '1',
      'tabId': '1',
      'exampleTitle': 'Variety of Skills for Multiple Roles',
      'exampleBody': [
        'Spanish Fluency • Time Management • Problem Solving • Public Speaking • Team Collaboration • Data Analytics',
        'Cash Handling • Customer Service • Retail Sales • Food Handling • Scheduling • Project Management • Organization',
        'Process Improvement • Conflict Resolution • Interpersonal Communication • Rapport Building • Active Listening'
      ]
    },
    {
      'tabTitle': 'Market Research',
      'tabNumber': '2',
      'tabId': '2',
      'exampleTitle': 'Relevant Skills for a Marketing Research Intern',
      'exampleBody': [
        'Competitive Analysis • Consumer Segmentation • Research Design • Statistics • Survey Design • Google Analytics',
        'IBM SPSS Statistics • MS Excel (VLOOKUP, Pivot Tables, Charts) • MS PowerPoint • Adobe Photoshop'
      ]
    },
    {
      'tabTitle': 'Medical',
      'tabNumber': '3',
      'tabId': '3',
      'exampleTitle': 'Relevant Skills for a Medical Assistant',
      'exampleBody': [
        'Vitals & Blood Pressure • Injections & Blood Draws • Electrocardiograms (EKG) • Basic Wound Care',
        'Equipment Sterilization • Room Preparation • Medical Terminology • EMR/HER • Filing Charts',
        'Answering Phones • Scheduling Appointments • Records Maintenance'
      ]
    },
    {
      'tabTitle': 'Technical',
      'tabNumber': '4',
      'tabId': '4',
      'exampleTitle': 'Examples of Technical Skills',
      'exampleBody': [
        'Hardware: Desktops • Laptops • Cisco Routers • Peripherals • Mobile Devices (tablets & smartphones)',
        'Software: Microsoft Windows 7/8.1/10 • MS Server 2008/2012 R2 • Active Directory • Adobe Creative Cloud • Microsoft Office Suite • Slack • Jira • Autodesk 3ds Max • Autodesk Maya • AutoCAD • Zbrush',
        'Languages: SQL, PHP, Python, C++, Ruby, Java, JavaScript, HTML, CSS'
      ]
    }
  ],
  'Resume Certifications': [
    {
      'tabTitle': '',
      'tabNumber': '1',
      'tabId': '1',
      'exampleTitle': 'Certifications Examples',
      'exampleBody': [
        'Microsoft Technology Associate (MTA)',
        'CompTIA A +',
        'CompTIA Network +',
        'Certified Public Accountant (CPA)',
        'Certified Nursing Assistant (CNA)',
        'Google Analytics Individual Qualification (IQ)',
        'Certified Medical Assistant-CMA (AAMA)',
        'Adobe Certified Expert (ACE)',
        'Cisco Certified Design Professional',
        'Six Sigma Green Belt',
        'Citrix Certified Networking Professional (CCNP) Security',
        'Project Management Professional (PMP)',
        'National Certified Counselor (NCC)',
        'Board Certified in Therapeutic Massage and Bodywork (BCTMB)'
      ]
    }
  ]


};

export default EXAMPLES;
