import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as componentsActions from '../../redux/actions/components';
import Login from '../../containers/auth/Login';


class LoginPage extends Component{
  constructor(props){
    super(props);
    this.state = {
      isModalOpen: props.modal && props.modal === 'login-modal',
    };
  }

  componentWillMount() {
    this.props.actions.openModal('login-modal');
  }

  hideLoginModal = () => {
    this.props.actions.closeModal();
  }

  render() {
    return (
      <Login
        linkParams={this.props.params.splat}
        toggleLoginModal={this.hideLoginModal}
        showLoginModal={this.state.isModalOpen}/>
    );
  }
}

LoginPage.propTypes = {
  params: PropTypes.object,
  actions: PropTypes.object,
  modal: PropTypes.string,
};

const mapStateToProps = state => ({ modal: state.components.modal });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(componentsActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
