import config from './config';

/* eslint-disable no-undef */

const isProduction = process.env.APP_ENV === 'production';
const isDemo = process.env.APP_ENV === 'demo';

export const BASE_URL = isProduction
  ? config[process.env.APP_ENV].api
  : isDemo
    ? config['demo'].api
    : config['development'].api;

export const FRONT_URL = isProduction
  ? config[process.env.APP_ENV].api
  : isDemo
    ? config['demo'].localurl
    : config['development'].localurl;

export const SYNC_STORE = 'SYNC_STORE';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const TOGGLE_COMPONENT = 'TOGGLE_COMPONENT';
export const TOGGLE_AVATAR_MODAL = 'TOGGLE_AVATAR_MODAL';
export const TOGGLE_MOBILE_VIEW = 'TOGGLE_MOBILE_VIEW';
export const LOAD_FORM = 'LOAD_FORM';
export const RESET_FORMS = 'RESET_FORMS';
export const UNLOAD_FORM = 'UNLOAD_FORM';
export const LOAD_SECTION_FORM = 'LOAD_SECTION_FORM';
export const UNLOAD_SECTION_FORM = 'UNLOAD_SECTION_FORM';
export const LOAD_UPDATE_FORM = 'LOAD_UPDATE_FORM';
export const UNLOAD_UPDATE_FORM = 'UNLOAD_UPDATE_FORM';
export const SET_FORM_FOCUS = 'SET_FORM_FOCUS';
export const UNSET_FORM_FOCUS = 'UNSET_FORM_FOCUS';
export const INITIALIZE_DISCUSSIONS_STATE = 'INITIALIZE_DISCUSSIONS_STATE';
export const LOAD_INITIAL_COMMENTS = 'LOAD_INITIAL_COMMENTS';
export const LOAD_INITIAL_WALL_COMMENTS = 'LOAD_INITIAL_WALL_COMMENTS';
export const LOAD_WALL_COMMENT_REPLIES = 'LOAD_WALL_COMMENT_REPLIES';
export const INCREMENT_DISCUSSION_COMMENT_COUNT =
  'INCREMENT_DISCUSSION_COMMENT_COUNT';
export const DECREMENT_DISCUSSION_COMMENT_COUNT =
  'DECREMENT_DISCUSSION_COMMENT_COUNT';
export const INCREMENT_WALL_COMMENT_COUNT = 'INCREMENT_WALL_COMMENT_COUNT';
export const DECREMENT_WALL_COMMENT_COUNT = 'DECREMENT_WALL_COMMENT_COUNT';
export const INITIALIZE_COMMENTS = 'INITIALIZE_COMMENTS';
export const LOAD_PREVIOUS_REPLIES = 'LOAD_PREVIOUS_REPLIES';

export const INITIALIZE_MESSAGES_STORE = 'INITIALIZE_MESSAGES_STORE';

export const ROUTE_REDIRECT = 'ROUTE_REDIRECT';
export const SET_ROUTER_HOOKED_TO_A_ROUTE = 'SET_ROUTER_HOOKED_TO_A_ROUTE';

export const REMOVE_ROUTER_HOOKED_TO_A_ROUTE =
  'REMOVE_ROUTER_HOOKED_TO_A_ROUTE';
export const STORE_AN_ACTION = 'STORE_AN_ACTION';
export const CLEAR_ALL_ACTIONS_STORED = 'CLEAR_ALL_ACTIONS_STORED';

export const ACCESS_REQUEST = 'ACCESS_REQUEST';
export const ACCESS_SUCCESS = 'ACCESS_SUCCESS';
export const ACCESS_FAILURE = 'ACCESS_FAILURE';

export const SURVEY_FEEDBACK_REQUEST = 'SURVEY_FEEDBACK_REQUEST';
export const SURVEY_FEEDBACK_SUCCESS = 'SURVEY_FEEDBACK_SUCCESS';
export const SURVEY_FEEDBACK_FAILURE = 'SURVEY_FEEDBACK_FAILURE';

export const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE';

export const CREATE_SUBSCRIPTION_REQUEST = 'CREATE_SUBSCRIPTION_REQUEST';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_FAILURE = 'CREATE_SUBSCRIPTION_FAILURE';

export const JOIN_MEMBERSHIP_REQUEST = 'JOIN_MEMBERSHIP_REQUEST';
export const JOIN_MEMBERSHIP_SUCCESS = 'JOIN_MEMBERSHIP_SUCCESS';
export const JOIN_MEMBERSHIP_FAILURE = 'JOIN_MEMBERSHIP_FAILURE';

export const INDUSTRY_MENTOR_REQUESTS_REQUEST = 'INDUSTRY_MENTOR_REQUESTS_REQUEST';
export const INDUSTRY_MENTOR_REQUESTS_SUCCESS = 'INDUSTRY_MENTOR_REQUESTS_SUCCESS';
export const INDUSTRY_MENTOR_REQUESTS_FAILURE = 'INDUSTRY_MENTOR_REQUESTS_FAILURE';

export const RECRUITER_REQUESTS_REQUEST = 'RECRUITER_REQUESTS_REQUEST';
export const RECRUITER_REQUESTS_SUCCESS = 'RECRUITER_REQUESTS_SUCCESS';
export const RECRUITER_REQUESTS_FAILURE = 'RECRUITER_REQUESTS_FAILURE';

export const SELF_REGISTRATION_REQUEST = 'SELF_REGISTRATION_REQUEST';
export const SELF_REGISTRATION_SUCCESS = 'SELF_REGISTRATION_SUCCESS';
export const SELF_REGISTRATION_FAILURE = 'SELF_REGISTRATION_FAILURE';

export const EDUCATOR_SIGNUP_REQUEST = 'EDUCATOR_SIGNUP_REQUEST';
export const EDUCATOR_SIGNUP_SUCCESS = 'EDUCATOR_SIGNUP_SUCCESS';
export const EDUCATOR_SIGNUP_FAILURE = 'EDUCATOR_SIGNUP_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const CLEAR_USER_REDUCERS = 'CLEAR_USER_REDUCERS';

export const INSTITUTIONS_REQUEST = 'INSTITUTIONS_REQUEST';
export const INSTITUTIONS_SUCCESS = 'INSTITUTIONS_SUCCESS';
export const INSTITUTIONS_FAILURE = 'INSTITUTIONS_FAILURE';

export const SET_SELECTED_INSTITUTION = 'SET_SELECTED_INSTITUTION';
export const UPDATE_INSTITUTION_GROUPS_COUNT =
  'UPDATE_INSTITUTION_GROUPS_COUNT';

export const GROUPS_REQUEST = 'GROUPS_REQUEST';
export const GROUPS_SUCCESS = 'GROUPS_SUCCESS';
export const GROUPS_FAILURE = 'GROUPS_FAILURE';

export const EDUCATORS_REQUEST = 'EDUCATORS_REQUEST';
export const EDUCATORS_SUCCESS = 'EDUCATORS_SUCCESS';
export const EDUCATORS_FAILURE = 'EDUCATORS_FAILURE';

export const EDUCATOR_GROUPS_REQUEST = 'EDUCATOR_GROUPS_REQUEST';
export const EDUCATOR_GROUPS_SUCCESS = 'EDUCATOR_GROUPS_SUCCESS';
export const EDUCATOR_GROUPS_FAILURE = 'EDUCATOR_GROUPS_FAILURE';

export const EDUCATOR_GROUPS_STATISTICS_REQUEST =
  'EDUCATOR_GROUPS_STATISTICS_REQUEST';
export const EDUCATOR_GROUPS_STATISTICS_SUCCESS =
  'EDUCATOR_GROUPS_STATISTICS_SUCCESS';
export const EDUCATOR_GROUPS_STATISTICS_FAILURE =
  'EDUCATOR_GROUPS_STATISTICS_FAILURE';

export const INDUSTRY_REPRESENTATIVES_REQUEST = 'INDUSTRY_REPRESENTATIVES_REQUEST';
export const INDUSTRY_REPRESENTATIVES_SUCCESS = 'INDUSTRY_REPRESENTATIVES_SUCCESS';
export const INDUSTRY_REPRESENTATIVES_FAILURE = 'INDUSTRY_REPRESENTATIVES_FAILURE';

export const SET_IS_LOADING_MORE_GROUPS = 'SET_IS_LOADING_MORE_GROUPS';
export const SET_IS_SEARCHING_GROUPS = 'SET_IS_SEARCHING_GROUPS';

export const GROUP_ADMINS_REQUEST = 'GROUP_ADMINS_REQUEST';
export const GROUP_ADMINS_SUCCESS = 'GROUP_ADMINS_SUCCESS';
export const GROUP_ADMINS_FAILURE = 'GROUP_ADMINS_FAILURE';

export const RESET_GROUP_ADMINS = 'RESET_GROUP_ADMINS';

export const UPDATE_GROUP_ADD_ADMINS_REQUEST =
  'UPDATE_GROUP_ADD_ADMINS_REQUEST';
export const UPDATE_GROUP_ADD_ADMINS_SUCCESS =
  'UPDATE_GROUP_ADD_ADMINS_SUCCESS';
export const UPDATE_GROUP_ADD_ADMINS_FAILURE =
  'UPDATE_GROUP_ADD_ADMINS_FAILURE';

export const UPDATE_GROUP_REMOVE_ADMINS_REQUEST =
  'UPDATE_GROUP_REMOVE_ADMINS_REQUEST';
export const UPDATE_GROUP_REMOVE_ADMINS_SUCCESS =
  'UPDATE_GROUP_REMOVE_ADMINS_SUCCESS';
export const UPDATE_GROUP_REMOVE_ADMINS_FAILURE =
  'UPDATE_GROUP_REMOVE_ADMINS_FAILURE';

export const SET_SELECTED_GROUP = 'SET_SELECTED_GROUP';
export const SET_SELECTED_PARENT_GROUP = 'SET_SELECTED_PARENT_GROUP';
export const SET_SELECTED_EDUCATOR_GROUP = 'SET_SELECTED_EDUCATOR_GROUP';
export const SET_IS_AUTH_USER_SELECTED_INSTITUTION_ADMIN =
  'SET_IS_AUTH_USER_SELECTED_INSTITUTION_ADMIN';

export const USERS_REQUEST = 'USERS_REQUEST';
export const USERS_SUCCESS = 'USERS_SUCCESS';
export const USERS_FAILURE = 'USERS_FAILURE';

export const USERS_STATISTICS_REQUEST = 'USERS_STATISTICS_REQUEST';
export const USERS_STATISTICS_SUCCESS = 'USERS_STATISTICS_SUCCESS';
export const USERS_STATISTICS_FAILURE = 'USERS_STATISTICS_FAILURE';

export const JOBS_STATISTICS_REQUEST = 'JOBS_STATISTICS_REQUEST';
export const JOBS_STATISTICS_SUCCESS = 'JOBS_STATISTICS_SUCCESS';
export const JOBS_STATISTICS_FAILURE = 'JOBS_STATISTICS_FAILURE';

export const FILES_STATISTICS_REQUEST = 'FILES_STATISTICS_REQUEST';
export const FILES_STATISTICS_SUCCESS = 'FILES_STATISTICS_SUCCESS';
export const FILES_STATISTICS_FAILURE = 'FILES_STATISTICS_FAILURE';

export const SKILLS_STATISTICS_REQUEST = 'SKILLS_STATISTICS_REQUEST';
export const SKILLS_STATISTICS_SUCCESS = 'SKILLS_STATISTICS_SUCCESS';
export const SKILLS_STATISTICS_FAILURE = 'SKILLS_STATISTICS_FAILURE';

export const BADGES_STATISTICS_REQUEST = 'BADGES_STATISTICS_REQUEST';
export const BADGES_STATISTICS_SUCCESS = 'BADGES_STATISTICS_SUCCESS';
export const BADGES_STATISTICS_FAILURE = 'BADGES_STATISTICS_FAILURE';

export const SURVEYS_STATISTICS_REQUEST = 'SURVEYS_STATISTICS_REQUEST';
export const SURVEYS_STATISTICS_SUCCESS = 'SURVEYS_STATISTICS_SUCCESS';
export const SURVEYS_STATISTICS_FAILURE = 'SURVEYS_STATISTICS_FAILURE';

export const SURVEY_FEEDBACKS_REQUEST = 'SURVEY_FEEDBACKS_REQUEST';
export const SURVEY_FEEDBACKS_SUCCESS = 'SURVEY_FEEDBACKS_SUCCESS';
export const SURVEY_FEEDBACKS_FAILURE = 'SURVEY_FEEDBACKS_FAILURE';

export const GET_ALL_EDUCATORS_REQUEST = 'GET_ALL_EDUCATORS_REQUEST';
export const GET_ALL_EDUCATORS_SUCCESS = 'GET_ALL_EDUCATORS_SUCCESS';
export const GET_ALL_EDUCATORS_FAILURE = 'GET_ALL_EDUCATORS_FAILURE';

export const SET_SELECTED_EDUCATOR = 'SET_SELECTED_EDUCATOR';

export const GET_ALL_GROUPS_REQUEST = 'GET_ALL_GROUPS_REQUEST';
export const GET_ALL_GROUPS_SUCCESS = 'GET_ALL_GROUPS_SUCCESS';
export const GET_ALL_GROUPS_FAILURE = 'GET_ALL_GROUPS_FAILURE';

export const SET_SELECTED_STATISTICS_EDUCATOR_GROUP = 'SET_SELECTED_STATISTICS_EDUCATOR_GROUP';

export const FOLLOW_USER_REQUEST = 'FOLLOW_USER_REQUEST';
export const FOLLOW_USER_SUCCESS = 'FOLLOW_USER_SUCCESS';
export const FOLLOW_USER_FAILURE = 'FOLLOW_USER_FAILURE';

export const UNFOLLOW_USER_REQUEST = 'UNFOLLOW_USER_REQUEST';
export const UNFOLLOW_USER_SUCCESS = 'UNFOLLOW_USER_SUCCESS';
export const UNFOLLOW_USER_FAILURE = 'UNFOLLOW_USER_FAILURE';

export const SET_USERS_SORT_ORDER = 'SET_USERS_SORT_ORDER';
export const ONCHANGE_USERS_SEARCH_QUERY = 'ONCHANGE_USERS_SEARCH_QUERY';
export const ONCHANGE_USERS_FILTERS = 'ONCHANGE_USERS_FILTERS';
export const DISPLAY_ADVANCED_FILTERS = 'DISPLAY_ADVANCED_FILTERS';
export const RESET_FILTER_PARAMS = 'RESET_FILTER_PARAMS';

export const DISABLE_MESSAGE_REQUEST = 'DISABLE_MESSAGE_REQUEST';
export const DISABLE_MESSAGE_SUCCESS = 'DISABLE_MESSAGE_SUCCESS';
export const DISABLE_MESSAGE_FAILURE = 'DISABLE_MESSAGE_FAILURE';

export const CONTACT_US_REQUEST = 'CONTACT_US_REQUEST';
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS';
export const CONTACT_US_FAILURE = 'CONTACT_US_FAILURE';

export const REQUEST_PASSWORD_RESET_REQUEST = 'REQUEST_PASSWORD_RESET_REQUEST';
export const REQUEST_PASSWORD_RESET_SUCCESS = 'REQUEST_PASSWORD_RESET_SUCCESS';
export const REQUEST_PASSWORD_RESET_FAILURE = 'REQUEST_PASSWORD_RESET_FAILURE';

export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';

export const JOIN_GROUP_SUBMIT = 'JOIN_GROUP_SUBMIT';
export const JOIN_GROUP_SUCCESS = 'JOIN_GROUP_SUCCESS';
export const JOIN_GROUP_FAILURE = 'JOIN_GROUP_FAILURE';

export const LEAVE_GROUP_REQUEST = 'LEAVE_GROUP_REQUEST';
export const LEAVE_GROUP_SUCCESS = 'LEAVE_GROUP_SUCCESS';
export const LEAVE_GROUP_FAILURE = 'LEAVE_GROUP_FAILURE';

export const FETCH_MYGROUPS_REQUEST = 'FETCH_MYGROUPS_REQUEST';
export const FETCH_MYGROUPS_SUCCESS = 'FETCH_MYGROUPS_SUCCESS';
export const FETCH_MYGROUPS_FAILURE = 'FETCH_MYGROUPS_FAILURE';

export const GROUP_REQUEST = 'GROUP_REQUEST';
export const GROUP_SUCCESS = 'GROUP_SUCCESS';
export const GROUP_FAILURE = 'GROUP_FAILURE';

export const SET_SELECTED_CHILD_GROUP = 'SET_SELECTED_CHILD_GROUP';

export const GET_ADMIN_GROUP_REQUEST = 'GET_ADMIN_GROUP_REQUEST';
export const GET_ADMIN_GROUP_SUCCESS = 'GET_ADMIN_GROUP_SUCCESS';
export const GET_ADMIN_GROUP_FAILURE = 'GET_ADMIN_GROUP_FAILURE';

export const CREATE_GROUP_REQUEST = 'CREATE_GROUP_REQUEST';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAILURE = 'CREATE_GROUP_FAILURE';

export const UPDATE_GROUP_REQUEST = 'UPDATE_GROUP_REQUEST';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE';

export const UPDATE_EDUCATOR_GROUP_REQUEST = 'UPDATE_EDUCATOR_GROUP_REQUEST';
export const UPDATE_EDUCATOR_GROUP_SUCCESS = 'UPDATE_EDUCATOR_GROUP_SUCCESS';
export const UPDATE_EDUCATOR_GROUP_FAILURE = 'UPDATE_EDUCATOR_GROUP_FAILURE';

export const SET_IS_CHILD_GROUP = 'SET_IS_CHILD_GROUP';

export const ONCHANGE_GROUPS_SEARCH_QUERY = 'ONCHANGE_GROUPS_SEARCH_QUERY';

export const UPDATE_ADMIN_GROUP_REQUEST = 'UPDATE_ADMIN_GROUP_REQUEST';
export const UPDATE_ADMIN_GROUP_SUCCESS = 'UPDATE_ADMIN_GROUP_SUCCESS';
export const UPDATE_ADMIN_GROUP_FAILURE = 'UPDATE_ADMIN_GROUP_FAILURE';

export const UPDATE_ADMIN_INSTITUTION_GROUPS =
  'UPDATE_ADMIN_INSTITUTION_GROUPS';

export const UPDATE_ADMIN_GROUP_ADD_ADMIN_REQUEST =
  'UPDATE_ADMIN_GROUP_ADD_ADMIN_REQUEST';
export const UPDATE_ADMIN_GROUP_ADD_ADMIN_SUCCESS =
  'UPDATE_ADMIN_GROUP_ADD_ADMIN_SUCCESS';
export const UPDATE_ADMIN_GROUP_ADD_ADMIN_FAILURE =
  'UPDATE_ADMIN_GROUP_ADD_ADMIN_FAILURE';

export const UPDATE_ADMIN_GROUP_REMOVE_ADMIN_REQUEST =
  'UPDATE_ADMIN_GROUP_REMOVE_ADMIN_REQUEST';
export const UPDATE_ADMIN_GROUP_REMOVE_ADMIN_SUCCESS =
  'UPDATE_ADMIN_GROUP_REMOVE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_GROUP_REMOVE_ADMIN_FAILURE =
  'UPDATE_ADMIN_GROUP_REMOVE_ADMIN_FAILURE';

export const GET_INSTITUTION_GROUPS_REQUEST = 'GET_INSTITUTION_GROUPS_REQUEST';
export const GET_INSTITUTION_GROUPS_SUCCESS = 'GET_INSTITUTION_GROUPS_SUCCESS';
export const GET_INSTITUTION_GROUPS_FAILURE = 'GET_INSTITUTION_GROUPS_FAILURE';

export const CREATE_GROUPS_REQUEST = 'CREATE_GROUPS_REQUEST';
export const CREATE_GROUPS_SUCCESS = 'CREATE_GROUPS_SUCCESS';
export const CREATE_GROUPS_FAILURE = 'CREATE_GROUPS_FAILURE';

export const CREATE_EDUCATOR_GROUPS_REQUEST = 'CREATE_EDUCATOR_GROUPS_REQUEST';
export const CREATE_EDUCATOR_GROUPS_SUCCESS = 'CREATE_EDUCATOR_GROUPS_SUCCESS';
export const CREATE_EDUCATOR_GROUPS_FAILURE = 'CREATE_EDUCATOR_GROUPS_FAILURE';

export const RESET_SUBMITTING_DUPLICATE_GROUPS =
  'RESET_SUBMITTING_DUPLICATE_GROUPS';
export const RESET_SUBMITTING_DUPLICATE_EDUCATOR_GROUPS =
  'RESET_SUBMITTING_DUPLICATE_EDUCATOR_GROUPS';

export const CREATE_CHILD_GROUPS_REQUEST = 'CREATE_CHILD_GROUPS_REQUEST';
export const CREATE_CHILD_GROUPS_SUCCESS = 'CREATE_CHILD_GROUPS_SUCCESS';
export const CREATE_CHILD_GROUPS_FAILURE = 'CREATE_CHILD_GROUPS_FAILURE';

export const CREATE_ADMIN_GROUP_REQUEST = 'CREATE_ADMIN_GROUP_REQUEST';
export const CREATE_ADMIN_GROUP_SUCCESS = 'CREATE_ADMIN_GROUP_SUCCESS';
export const CREATE_ADMIN_GROUP_FAILURE = 'CREATE_ADMIN_GROUP_FAILURE';

export const UPDATE_IR_SETTINGS_REQUEST = 'UPDATE_IR_SETTINGS_REQUEST';
export const UPDATE_IR_SETTINGS_SUCCESS = 'UPDATE_IR_SETTINGS_SUCCESS';
export const UPDATE_IR_SETTINGS_FAILURE = 'UPDATE_IR_SETTINGS_FAILURE';

export const GET_IR_SETTINGS_REQUEST = 'GET_IR_SETTINGS_REQUEST';
export const GET_IR_SETTINGS_SUCCESS = 'GET_IR_SETTINGS_SUCCESS';
export const GET_IR_SETTINGS_FAILURE = 'GET_IR_SETTINGS_FAILURE';

export const UPDATE_SETTINGS_REQUEST = 'UPDATE_SETTINGS_REQUEST';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_FAILURE = 'UPDATE_SETTINGS_FAILURE';

export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_FAILURE = 'PROFILE_FAILURE';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';

export const INCREMENT_FOLLOWING_COUNT = 'INCREMENT_FOLLOWING_COUNT';
export const DECREMENT_FOLLOWING_COUNT = 'DECREMENT_FOLLOWING_COUNT';

export const CURRENT_USER_REQUEST = 'CURRENT_USER_REQUEST';
export const CURRENT_USER_SUCCESS = 'CURRENT_USER_SUCCESS';
export const CURRENT_USER_FAILURE = 'CURRENT_USER_FAILURE';

export const SHARE_DISCUSSION = 'SHARE_DISCUSSION';
export const RESET_SHARE_DISCUSSION = 'RESET_SHARE_DISCUSSION';

export const SHARE_WALLPOST = 'SHARE_WALLPOST';
export const RESET_SHARE_WALLPOST = 'RESET_SHARE_WALLPOST';

export const SKILL_BUILDERS_REQUEST = 'SKILL_BUILDERS_REQUEST';
export const SKILL_BUILDERS_SUCCESS = 'SKILL_BUILDERS_SUCCESS';
export const SKILL_BUILDERS_FAILURE = 'SKILL_BUILDERS_FAILURE';

export const RESET_UNIT = 'RESET_UNIT';

export const UNIT_REQUEST = 'UNIT_REQUEST';
export const UNIT_SUCCESS = 'UNIT_SUCCESS';
export const UNIT_FAILURE = 'UNIT_FAILURE';

export const CREATE_UNIT_REQUEST = 'CREATE_UNIT_REQUEST';
export const CREATE_UNIT_SUCCESS = 'CREATE_UNIT_SUCCESS';
export const CREATE_UNIT_FAILURE = 'CREATE_UNIT_FAILURE';

export const UPDATE_UNIT_REQUEST = 'UPDATE_UNIT_REQUEST';
export const UPDATE_UNIT_SUCCESS = 'UPDATE_UNIT_SUCCESS';
export const UPDATE_UNIT_FAILURE = 'UPDATE_UNIT_FAILURE';

export const UPDATE_UNIT_SECTIONS_ORDER = 'UPDATE_UNIT_SECTIONS_ORDER';

export const DELETE_UNIT_REQUEST = 'DELETE_UNIT_REQUEST';
export const DELETE_UNIT_SUCCESS = 'DELETE_UNIT_SUCCESS';
export const DELETE_UNIT_FAILURE = 'DELETE_UNIT_FAILURE';

export const RESET_BADGES = 'RESET_BADGES';
export const CLEAR_ISSUED_BADGES = 'CLEAR_ISSUED_BADGES';

export const SAVE_SELECTED_BADGES = 'SAVE_SELECTED_BADGES';
export const RESET_SELECTED_BADGES = 'RESET_SELECTED_BADGES';

export const ADD_UNIT_BADGE_REQUEST = 'ADD_UNIT_BADGE_REQUEST';
export const ADD_UNIT_BADGE_SUCCESS = 'ADD_UNIT_BADGE_SUCCESS';
export const ADD_UNIT_BADGE_FAILURE = 'ADD_UNIT_BADGE_FAILURE';

export const UPDATE_UNIT_BADGE_REQUEST = 'UPDATE_UNIT_BADGE_REQUEST';
export const UPDATE_UNIT_BADGE_SUCCESS = 'UPDATE_UNIT_BADGE_SUCCESS';
export const UPDATE_UNIT_BADGE_FAILURE = 'UPDATE_UNIT_BADGE_FAILURE';

export const UPDATE_UNIT_BADGE_ON_LOCAL_STORE =
  'UPDATE_UNIT_BADGE_ON_LOCAL_STORE';

export const DELETE_UNIT_BADGE_REQUEST = 'DELETE_UNIT_BADGE_REQUEST';
export const DELETE_UNIT_BADGE_SUCCESS = 'DELETE_UNIT_BADGE_SUCCESS';
export const DELETE_UNIT_BADGE_FAILURE = 'DELETE_UNIT_BADGE_FAILURE';

export const INITIALIZE_REFLECTION_STORE = 'INITIALIZE_REFLECTION_STORE';

export const REFLECTIONS_REQUEST = 'REFLECTIONS_REQUEST';
export const REFLECTIONS_SUCCESS = 'REFLECTIONS_SUCCESS';
export const REFLECTIONS_FAILURE = 'REFLECTIONS_FAILURE';

export const CREATE_REFLECTION_REQUEST = 'CREATE_REFLECTION_REQUEST';
export const CREATE_REFLECTION_SUCCESS = 'CREATE_REFLECTION_SUCCESS';
export const CREATE_REFLECTION_FAILURE = 'CREATE_REFLECTION_FAILURE';

export const UPDATE_REFLECTION_REQUEST = 'UPDATE_REFLECTION_REQUEST';
export const UPDATE_REFLECTION_SUCCESS = 'UPDATE_REFLECTION_SUCCESS';
export const UPDATE_REFLECTION_FAILURE = 'UPDATE_REFLECTION_FAILURE';

export const DELETE_REFLECTION_REQUEST = 'DELETE_REFLECTION_REQUEST';
export const DELETE_REFLECTION_SUCCESS = 'DELETE_REFLECTION_SUCCESS';
export const DELETE_REFLECTION_FAILURE = 'DELETE_REFLECTION_FAILURE';
export const SET_REFLECTION_DELETE_ID = 'SET_REFLECTION_DELETE_ID';

export const TOGGLE_IS_DRAGGING = 'TOGGLE_IS_DRAGGING';

export const SECTIONS_RESET = 'SECTIONS_RESET';
export const SECTIONS_REQUEST = 'SECTIONS_REQUEST';
export const SECTIONS_SUCCESS = 'SECTIONS_SUCCESS';
export const SECTIONS_FAILURE = 'SECTIONS_FAILURE';

export const CREATE_SECTION_REQUEST = 'CREATE_SECTION_REQUEST';
export const CREATE_SECTION_SUCCESS = 'CREATE_SECTION_SUCCESS';
export const CREATE_SECTION_FAILURE = 'CREATE_SECTION_FAILURE';

export const UPDATE_SECTION_INDEX_SUCCESS = 'UPDATE_SECTION_INDEX_SUCCESS';

export const UPDATE_SECTION_REQUEST = 'UPDATE_SECTION_REQUEST';
export const UPDATE_SECTION_SUCCESS = 'UPDATE_SECTION_SUCCESS';
export const UPDATE_SECTION_FAILURE = 'UPDATE_SECTION_FAILURE';

export const DELETE_SECTION_REQUEST = 'DELETE_SECTION_REQUEST';
export const DELETE_SECTION_SUCCESS = 'DELETE_SECTION_SUCCESS';
export const DELETE_SECTION_FAILURE = 'DELETE_SECTION_FAILURE';

export const RESET_RECENT_UPLOADED_FILE_ID = 'RESET_RECENT_UPLOADED_FILE_ID';

export const FILE_UPLOAD_REQUEST = 'FILE_UPLOAD_REQUEST';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_FAILURE = 'FILE_UPLOAD_FAILURE';

export const SB_FILE_UPLOAD_REQUEST = 'SB_FILE_UPLOAD_REQUEST';
export const SB_FILE_UPLOAD_SUCCESS = 'SB_FILE_UPLOAD_SUCCESS';
export const SB_FILE_UPLOAD_FAILURE = 'SB_FILE_UPLOAD_FAILURE';

export const DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAILURE = 'DELETE_FILE_FAILURE';

export const FETCH_FILE_REQUEST = 'FETCH_FILE_REQUEST';
export const FETCH_FILE_SUCCESS = 'FETCH_FILE_SUCCESS';
export const FETCH_FILE_FAILURE = 'FETCH_FILE_FAILURE';
export const RESET_FILES_REDUCER = 'RESET_FILES_REDUCER';

export const UPDATE_FILE_REQUEST = 'UPDATE_FILE_REQUEST';
export const UPDATE_FILE_SUCCESS = 'UPDATE_FILE_SUCCESS';
export const UPDATE_FILE_FAILURE = 'UPDATE_FILE_FAILURE';

export const UPDATE_FILE_EVIDENCE_PAGE_REQUEST =
  'UPDATE_FILE_EVIDENCE_PAGE_REQUEST';
export const UPDATE_FILE_EVIDENCE_PAGE_SUCCESS =
  'UPDATE_FILE_EVIDENCE_PAGE_SUCCESS';
export const UPDATE_FILE_EVIDENCE_PAGE_FAILURE =
  'UPDATE_FILE_EVIDENCE_PAGE_FAILURE';

export const FETCH_UNIT_FILE_REQUEST = 'FETCH_UNIT_FILE_REQUEST';
export const FETCH_UNIT_FILE_SUCCESS = 'FETCH_UNIT_FILE_SUCCESS';
export const FETCH_UNIT_FILE_FAILURE = 'FETCH_UNIT_FILE_FAILURE';

export const DISCUSSION_REQUEST = 'DISCUSSION_REQUEST';
export const DISCUSSION_SUCCESS = 'DISCUSSION_SUCCESS';
export const DISCUSSION_FAILURE = 'DISCUSSION_FAILURE';

export const DISCUSSIONS_REQUEST = 'DISCUSSIONS_REQUEST';
export const DISCUSSIONS_SUCCESS = 'DISCUSSIONS_SUCCESS';
export const DISCUSSIONS_FAILURE = 'DISCUSSIONS_FAILURE';

export const CREATE_DISCUSSION_REQUEST = 'CREATE_DISCUSSION_REQUEST';
export const CREATE_DISCUSSION_SUCCESS = 'CREATE_DISCUSSION_SUCCESS';
export const CREATE_DISCUSSION_FAILURE = 'CREATE_DISCUSSION_FAILURE';

export const UPDATE_DISCUSSION_REQUEST = 'UPDATE_DISCUSSION_REQUEST';
export const UPDATE_DISCUSSION_SUCCESS = 'UPDATE_DISCUSSION_SUCCESS';
export const UPDATE_DISCUSSION_FAILURE = 'UPDATE_DISCUSSION_FAILURE';

export const DELETE_DISCUSSION_REQUEST = 'DELETE_DISCUSSION_REQUEST';
export const DELETE_DISCUSSION_SUCCESS = 'DELETE_DISCUSSION_SUCCESS';
export const DELETE_DISCUSSION_FAILURE = 'DELETE_DISCUSSION_FAILURE';

export const CREATE_DISCUSSION_LIKE_REQUEST = 'CREATE_DISCUSSION_LIKE_REQUEST';
export const CREATE_DISCUSSION_LIKE_SUCCESS = 'CREATE_DISCUSSION_LIKE_SUCCESS';
export const CREATE_DISCUSSION_LIKE_FAILURE = 'CREATE_DISCUSSION_LIKE_FAILURE';

export const UNLIKE_DISCUSSION_POST_REQUEST = 'UNLIKE_DISCUSSION_POST_REQUEST';
export const UNLIKE_DISCUSSION_POST_SUCCESS = 'UNLIKE_DISCUSSION_POST_SUCCESS';
export const UNLIKE_DISCUSSION_POST_FAILURE = 'UNLIKE_DISCUSSION_POST_FAILURE';

export const COMMENTS_REQUEST = 'COMMENTS_REQUEST';
export const COMMENTS_SUCCESS = 'COMMENTS_SUCCESS';
export const COMMENTS_FAILURE = 'COMMENTS_FAILURE';

export const CREATE_COMMENT_REQUEST = 'CREATE_COMMENT_REQUEST';
export const CREATE_COMMENT_SUCCESS = 'CREATE_COMMENT_SUCCESS';
export const CREATE_COMMENT_FAILURE = 'CREATE_COMMENT_FAILURE';

export const CREATE_REPLY_REQUEST = 'CREATE_REPLY_REQUEST';
export const CREATE_REPLY_SUCCESS = 'CREATE_REPLY_SUCCESS';
export const CREATE_REPLY_FAILURE = 'CREATE_REPLY_FAILURE';

export const DELETE_REPLY_REQUEST = 'DELETE_REPLY_REQUEST';
export const DELETE_REPLY_SUCCESS = 'DELETE_REPLY_SUCCESS';
export const DELETE_REPLY_FAILURE = 'DELETE_REPLY_FAILURE';

export const UPDATE_REPLY_REQUEST = 'UPDATE_REPLY_REQUEST';
export const UPDATE_REPLY_SUCCESS = 'UPDATE_REPLY_SUCCESS';
export const UPDATE_REPLY_FAILURE = 'UPDATE_REPLY_FAILURE';

export const CREATE_REPLY_LIKE_REQUEST = 'CREATE_REPLY_LIKE_REQUEST';
export const CREATE_REPLY_LIKE_SUCCESS = 'CREATE_REPLY_LIKE_SUCCESS';
export const CREATE_REPLY_LIKE_FAILURE = 'CREATE_REPLY_LIKE_FAILURE';

export const UNLIKE_REPLY_REQUEST = 'UNLIKE_REPLY_REQUEST';
export const UNLIKE_REPLY_SUCCESS = 'UNLIKE_REPLY_SUCCESS';
export const UNLIKE_REPLY_FAILURE = 'UNLIKE_REPLY_FAILURE';

export const UPDATE_COMMENT_REQUEST = 'UPDATE_COMMENT_REQUEST';
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS';
export const UPDATE_COMMENT_FAILURE = 'UPDATE_COMMENT_FAILURE';

export const DELETE_COMMENT_REQUEST = 'DELETE_COMMENT_REQUEST';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_FAILURE = 'DELETE_COMMENT_FAILURE';

export const CREATE_COMMENT_LIKE_REQUEST = 'CREATE_COMMENT_LIKE_REQUEST';
export const CREATE_COMMENT_LIKE_SUCCESS = 'CREATE_COMMENT_LIKE_SUCCESS';
export const CREATE_COMMENT_LIKE_FAILURE = 'CREATE_COMMENT_LIKE_FAILURE';

export const UNLIKE_DISCUSSION_COMMENT_REQUEST =
  'UNLIKE_DISCUSSION_COMMENT_REQUEST';
export const UNLIKE_DISCUSSION_COMMENT_SUCCESS =
  'UNLIKE_DISCUSSION_COMMENT_SUCCESS';
export const UNLIKE_DISCUSSION_COMMENT_FAILURE =
  'UNLIKE_DISCUSSION_COMMENT_FAILURE';

export const WALL_POST_REQUEST = 'WALL_POST_REQUEST';
export const WALL_POST_SUCCESS = 'WALL_POST_SUCCESS';
export const WALL_POST_FAILURE = 'WALL_POST_FAILURE';

export const WALL_POSTS_REQUEST = 'WALL_POSTS_REQUEST';
export const WALL_POSTS_SUCCESS = 'WALL_POSTS_SUCCESS';
export const WALL_POSTS_FAILURE = 'WALL_POSTS_FAILURE';
export const RESET_DASHBOARD_WALL = 'RESET_DASHBOARD_WALL';

export const CREATE_WALL_POST_REQUEST = 'CREATE_WALL_POSTS_REQUEST';
export const CREATE_WALL_POST_SUCCESS = 'CREATE_WALL_POSTS_SUCCESS';
export const CREATE_WALL_POST_FAILURE = 'CREATE_WALL_POSTS_FAILURE';

export const UPDATE_WALL_POST_REQUEST = 'UPDATE_WALL_POST_REQUEST';
export const UPDATE_WALL_POST_SUCCESS = 'UPDATE_WALL_POST_SUCCESS';
export const UPDATE_WALL_POST_FAILURE = 'UPDATE_WALL_POST_FAILURE';

export const DELETE_WALL_POST_REQUEST = 'DELETE_WALL_POST_REQUEST';
export const DELETE_WALL_POST_SUCCESS = 'DELETE_WALL_POST_SUCCESS';
export const DELETE_WALL_POST_FAILURE = 'DELETE_WALL_POST_FAILURE';

export const CREATE_WALL_POST_LIKE_REQUEST = 'CREATE_WALL_POST_LIKE_REQUEST';
export const CREATE_WALL_POST_LIKE_SUCCESS = 'CREATE_WALL_POST_LIKE_SUCCESS';
export const CREATE_WALL_POST_LIKE_FAILURE = 'CREATE_WALL_POST_LIKE_FAILURE';

export const WALL_POST_UNLIKE_REQUEST = 'WALL_POST_UNLIKE_REQUEST';
export const WALL_POST_UNLIKE_SUCCESS = 'WALL_POST_UNLIKE_SUCCESS';
export const WALL_POST_UNLIKE_FAILURE = 'WALL_POST_UNLIKE_FAILURE';

export const WALL_POST_COMMENTS_REQUEST = 'WALL_POST_COMMENTS_REQUEST';
export const WALL_POST_COMMENTS_SUCCESS = 'WALL_POST_COMMENTS_SUCCESS';
export const WALL_POST_COMMENTS_FAILURE = 'WALL_POST_COMMENTS_FAILURE';
export const RESET_DASHBOARD_WALL_COMMENTS = 'RESET_DASHBOARD_WALL_COMMENTS';

export const CREATE_WALL_POST_COMMENT_REQUEST =
  'CREATE_WALL_POST_COMMENT_REQUEST';
export const CREATE_WALL_POST_COMMENT_SUCCESS =
  'CREATE_WALL_POST_COMMENT_SUCCESS';
export const CREATE_WALL_POST_COMMENT_FAILURE =
  'CREATE_WALL_POST_COMMENT_FAILURE';

export const CREATE_WALL_REPLY_REQUEST = 'CREATE_WALL_REPLY_REQUEST';
export const CREATE_WALL_REPLY_SUCCESS = 'CREATE_WALL_REPLY_SUCCESS';
export const CREATE_WALL_REPLY_FAILURE = 'CREATE_WALL_REPLY_FAILURE';

export const UPDATE_WALL_POST_COMMENT_REQUEST =
  'UPDATE_WALL_POST_COMMENT_REQUEST';
export const UPDATE_WALL_POST_COMMENT_SUCCESS =
  'UPDATE_WALL_POST_COMMENT_SUCCESS';
export const UPDATE_WALL_POST_COMMENT_FAILURE =
  'UPDATE_WALL_POST_COMMENT_FAILURE';

export const DELETE_WALL_POST_COMMENT_REQUEST =
  'DELETE_WALL_POST_COMMENT_REQUEST';
export const DELETE_WALL_POST_COMMENT_SUCCESS =
  'DELETE_WALL_POST_COMMENT_SUCCESS';
export const DELETE_WALL_POST_COMMENT_FAILURE =
  'DELETE_WALL_POST_COMMENT_FAILURE';

export const CREATE_WALL_POST_COMMENT_LIKE_REQUEST =
  'CREATE_WALL_POST_COMMENT_LIKE_REQUEST';
export const CREATE_WALL_POST_COMMENT_LIKE_SUCCESS =
  'CREATE_WALL_POST_COMMENT_LIKE_SUCCESS';
export const CREATE_WALL_POST_COMMENT_LIKE_FAILURE =
  'CREATE_WALL_POST_COMMENT_LIKE_FAILURE';

export const UNLIKE_WALL_POST_COMMENT_REQUEST =
  'UNLIKE_WALL_POST_COMMENT_REQUEST';
export const UNLIKE_WALL_POST_COMMENT_SUCCESS =
  'UNLIKE_WALL_POST_COMMENT_SUCCESS';
export const UNLIKE_WALL_POST_COMMENT_FAILURE =
  'UNLIKE_WALL_POST_COMMENT_FAILURE';

export const CREATE_WALL_POST_REPLY_LIKE_REQUEST =
  'CREATE_WALL_POST_REPLY_LIKE_REQUEST';
export const CREATE_WALL_POST_REPLY_LIKE_SUCCESS =
  'CREATE_WALL_POST_REPLY_LIKE_SUCCESS';
export const CREATE_WALL_POST_REPLY_LIKE_FAILURE =
  'CREATE_WALL_POST_REPLY_LIKE_FAILURE';

export const UNLIKE_WALL_POST_REPLY_REQUEST = 'UNLIKE_WALL_POST_REPLY_REQUEST';
export const UNLIKE_WALL_POST_REPLY_SUCCESS = 'UNLIKE_WALL_POST_REPLY_SUCCESS';
export const UNLIKE_WALL_POST_REPLY_FAILURE = 'UNLIKE_WALL_POST_REPLY_FAILURE';

export const UPDATE_WALL_POST_REPLY_REQUEST = 'UPDATE_WALL_POST_REPLY_REQUEST';
export const UPDATE_WALL_POST_REPLY_SUCCESS = 'UPDATE_WALL_POST_REPLY_SUCCESS';
export const UPDATE_WALL_POST_REPLY_FAILURE = 'UPDATE_WALL_POST_REPLY_FAILURE';

export const DELETE_WALL_POST_REPLY_REQUEST = 'DELETE_WALL_POST_REPLY_REQUEST';
export const DELETE_WALL_POST_REPLY_SUCCESS = 'DELETE_WALL_POST_REPLY_SUCCESS';
export const DELETE_WALL_POST_REPLY_FAILURE = 'DELETE_WALL_POST_REPLY_FAILURE';

export const EDUCATION_REQUEST = 'EDUCATION_REQUEST';
export const EDUCATION_SUCCESS = 'EDUCATION_SUCCESS';
export const EDUCATION_FAILURE = 'EDUCATION_FAILURE';

export const CREATE_EDUCATION_REQUEST = 'CREATE_EDUCATION_REQUEST';
export const CREATE_EDUCATION_SUCCESS = 'CREATE_EDUCATION_SUCCESS';
export const CREATE_EDUCATION_FAILURE = 'CREATE_EDUCATION_FAILURE';

export const UPDATE_EDUCATION_REQUEST = 'UPDATE_EDUCATION_REQUEST';
export const UPDATE_EDUCATION_SUCCESS = 'UPDATE_EDUCATION_SUCCESS';
export const UPDATE_EDUCATION_FAILURE = 'UPDATE_EDUCATION_FAILURE';

export const DELETE_EDUCATION_REQUEST = 'DELETE_EDUCATION_REQUEST';
export const DELETE_EDUCATION_SUCCESS = 'DELETE_EDUCATION_SUCCESS';
export const DELETE_EDUCATION_FAILURE = 'DELETE_EDUCATION_FAILURE';

export const EXPERIENCE_REQUEST = 'EXPERIENCE_REQUEST';
export const EXPERIENCE_SUCCESS = 'EXPERIENCE_SUCCESS';
export const EXPERIENCE_FAILURE = 'EXPERIENCE_FAILURE';

export const CREATE_EXPERIENCE_REQUEST = 'CREATE_EXPERIENCE_REQUEST';
export const CREATE_EXPERIENCE_SUCCESS = 'CREATE_EXPERIENCE_SUCCESS';
export const CREATE_EXPERIENCE_FAILURE = 'CREATE_EXPERIENCE_FAILURE';

export const UPDATE_EXPERIENCE_REQUEST = 'UPDATE_EXPERIENCE_REQUEST';
export const UPDATE_EXPERIENCE_SUCCESS = 'UPDATE_EXPERIENCE_SUCCESS';
export const UPDATE_EXPERIENCE_FAILURE = 'UPDATE_EXPERIENCE_FAILURE';

export const DELETE_EXPERIENCE_REQUEST = 'DELETE_EXPERIENCE_REQUEST';
export const DELETE_EXPERIENCE_SUCCESS = 'DELETE_EXPERIENCE_SUCCESS';
export const DELETE_EXPERIENCE_FAILURE = 'DELETE_EXPERIENCE_FAILURE';

export const SET_SKILL_BEING_UPDATED_IN_STORE =
  'SET_SKILL_BEING_UPDATED_IN_STORE';

export const UPDATE_SKILLS_ORDER_REQUEST = 'UPDATE_SKILLS_ORDER_REQUEST';
export const UPDATE_SKILLS_ORDER_SUCCESS = 'UPDATE_SKILLS_ORDER_SUCCESS';
export const UPDATE_SKILLS_ORDER_FAILURE = 'UPDATE_SKILLS_ORDER_FAILURE';

export const UPDATE_INTERESTS_ORDER_REQUEST = 'UPDATE_INTERESTS_ORDER_REQUEST';
export const UPDATE_INTERESTS_ORDER_SUCCESS = 'UPDATE_INTERESTS_ORDER_SUCCESS';
export const UPDATE_INTERESTS_ORDER_FAILURE = 'UPDATE_INTERESTS_ORDER_FAILURE';

export const DELETE_INTERESTS_SECTION_REQUEST =
  'DELETE_INTERESTS_SECTION_REQUEST';
export const DELETE_INTERESTS_SECTION_SUCCESS =
  'DELETE_INTERESTS_SECTION_SUCCESS';
export const DELETE_INTERESTS_SECTION_FAILURE =
  'DELETE_INTERESTS_SECTION_FAILURE';

export const SKILLS_REQUEST = 'SKILLS_REQUEST';
export const SKILLS_SUCCESS = 'SKILLS_SUCCESS';
export const SKILLS_FAILURE = 'SKILLS_FAILURE';

export const CREATE_SKILL_REQUEST = 'CREATE_SKILL_REQUEST';
export const CREATE_SKILL_SUCCESS = 'CREATE_SKILL_SUCCESS';
export const CREATE_SKILL_FAILURE = 'CREATE_SKILL_FAILURE';

export const UPDATE_SKILL_REQUEST = 'UPDATE_SKILL_REQUEST';
export const UPDATE_SKILL_SUCCESS = 'UPDATE_SKILL_SUCCESS';
export const UPDATE_SKILL_FAILURE = 'UPDATE_SKILL_FAILURE';

export const DELETE_SKILL_REQUEST = 'DELETE_SKILL_REQUEST';
export const DELETE_SKILL_SUCCESS = 'DELETE_SKILL_SUCCESS';
export const DELETE_SKILL_FAILURE = 'DELETE_SKILL_FAILURE';

export const CERTIFICATIONS_REQUEST = 'CERTIFICATIONS_REQUEST';
export const CERTIFICATIONS_SUCCESS = 'CERTIFICATIONS_SUCCESS';
export const CERTIFICATIONS_FAILURE = 'CERTIFICATIONS_FAILURE';

export const CREATE_CERTIFICATION_REQUEST = 'CREATE_CERTIFICATION_REQUEST';
export const CREATE_CERTIFICATION_SUCCESS = 'CREATE_CERTIFICATION_SUCCESS';
export const CREATE_CERTIFICATION_FAILURE = 'CREATE_CERTIFICATION_FAILURE';

export const UPDATE_CERTIFICATION_REQUEST = 'UPDATE_CERTIFICATION_REQUEST';
export const UPDATE_CERTIFICATION_SUCCESS = 'UPDATE_CERTIFICATION_SUCCESS';
export const UPDATE_CERTIFICATION_FAILURE = 'UPDATE_CERTIFICATION_FAILURE';

export const DELETE_CERTIFICATION_REQUEST = 'DELETE_CERTIFICATION_REQUEST';
export const DELETE_CERTIFICATION_SUCCESS = 'DELETE_CERTIFICATION_SUCCESS';
export const DELETE_CERTIFICATION_FAILURE = 'DELETE_CERTIFICATION_FAILURE';

export const PROJECTS_REQUEST = 'PROJECTS_REQUEST';
export const PROJECTS_SUCCESS = 'PROJECTS_SUCCESS';
export const PROJECTS_FAILURE = 'PROJECTS_FAILURE';

export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE';

export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAILURE = 'UPDATE_PROJECT_FAILURE';

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE';

export const AWARDS_REQUEST = 'AWARDS_REQUEST';
export const AWARDS_SUCCESS = 'AWARDS_SUCCESS';
export const AWARDS_FAILURE = 'AWARDS_FAILURE';

export const CREATE_AWARD_REQUEST = 'CREATE_AWARD_REQUEST';
export const CREATE_AWARD_SUCCESS = 'CREATE_AWARD_SUCCESS';
export const CREATE_AWARD_FAILURE = 'CREATE_AWARD_FAILURE';

export const UPDATE_AWARD_REQUEST = 'UPDATE_AWARD_REQUEST';
export const UPDATE_AWARD_SUCCESS = 'UPDATE_AWARD_SUCCESS';
export const UPDATE_AWARD_FAILURE = 'UPDATE_AWARD_FAILURE';

export const DELETE_AWARD_REQUEST = 'DELETE_AWARD_REQUEST';
export const DELETE_AWARD_SUCCESS = 'DELETE_AWARD_SUCCESS';
export const DELETE_AWARD_FAILURE = 'DELETE_AWARD_FAILURE';

export const INTERESTS_REQUEST = 'INTERESTS_REQUEST';
export const INTERESTS_SUCCESS = 'INTERESTS_SUCCESS';
export const INTERESTS_FAILURE = 'INTERESTS_FAILURE';

export const CREATE_INTEREST_REQUEST = 'CREATE_INTEREST_REQUEST';
export const CREATE_INTEREST_SUCCESS = 'CREATE_INTEREST_SUCCESS';
export const CREATE_INTEREST_FAILURE = 'CREATE_INTEREST_FAILURE';

export const UPDATE_INTEREST_REQUEST = 'UPDATE_INTEREST_REQUEST';
export const UPDATE_INTEREST_SUCCESS = 'UPDATE_INTEREST_SUCCESS';
export const UPDATE_INTEREST_FAILURE = 'UPDATE_INTEREST_FAILURE';

export const DELETE_INTEREST_REQUEST = 'DELETE_INTEREST_REQUEST';
export const DELETE_INTEREST_SUCCESS = 'DELETE_INTEREST_SUCCESS';
export const DELETE_INTEREST_FAILURE = 'DELETE_INTEREST_FAILURE';

export const ASSOCIATIONS_REQUEST = 'ASSOCIATIONS_REQUEST';
export const ASSOCIATIONS_SUCCESS = 'ASSOCIATIONS_SUCCESS';
export const ASSOCIATIONS_FAILURE = 'ASSOCIATIONS_FAILURE';

export const CREATE_ASSOCIATION_REQUEST = 'CREATE_ASSOCIATION_REQUEST';
export const CREATE_ASSOCIATION_SUCCESS = 'CREATE_ASSOCIATION_SUCCESS';
export const CREATE_ASSOCIATION_FAILURE = 'CREATE_ASSOCIATION_FAILURE';

export const UPDATE_ASSOCIATION_REQUEST = 'UPDATE_ASSOCIATION_REQUEST';
export const UPDATE_ASSOCIATION_SUCCESS = 'UPDATE_ASSOCIATION_SUCCESS';
export const UPDATE_ASSOCIATION_FAILURE = 'UPDATE_ASSOCIATION_FAILURE';

export const DELETE_ASSOCIATION_REQUEST = 'DELETE_ASSOCIATION_REQUEST';
export const DELETE_ASSOCIATION_SUCCESS = 'DELETE_ASSOCIATION_SUCCESS';
export const DELETE_ASSOCIATION_FAILURE = 'DELETE_ASSOCIATION_FAILURE';

export const PUBLICATIONS_REQUEST = 'PUBLICATIONS_REQUEST';
export const PUBLICATIONS_SUCCESS = 'PUBLICATIONS_SUCCESS';
export const PUBLICATIONS_FAILURE = 'PUBLICATIONS_FAILURE';

export const CREATE_PUBLICATION_REQUEST = 'CREATE_PUBLICATION_REQUEST';
export const CREATE_PUBLICATION_SUCCESS = 'CREATE_PUBLICATION_SUCCESS';
export const CREATE_PUBLICATION_FAILURE = 'CREATE_PUBLICATION_FAILURE';

export const UPDATE_PUBLICATION_REQUEST = 'UPDATE_PUBLICATION_REQUEST';
export const UPDATE_PUBLICATION_SUCCESS = 'UPDATE_PUBLICATION_SUCCESS';
export const UPDATE_PUBLICATION_FAILURE = 'UPDATE_PUBLICATION_FAILURE';

export const DELETE_PUBLICATION_REQUEST = 'DELETE_PUBLICATION_REQUEST';
export const DELETE_PUBLICATION_SUCCESS = 'DELETE_PUBLICATION_SUCCESS';
export const DELETE_PUBLICATION_FAILURE = 'DELETE_PUBLICATION_FAILURE';

export const CLUBS_REQUEST = 'CLUBS_REQUEST';
export const CLUBS_SUCCESS = 'CLUBS_SUCCESS';
export const CLUBS_FAILURE = 'CLUBS_FAILURE';

export const CREATE_CLUB_REQUEST = 'CREATE_CLUB_REQUEST';
export const CREATE_CLUB_SUCCESS = 'CREATE_CLUB_SUCCESS';
export const CREATE_CLUB_FAILURE = 'CREATE_CLUBS_FAILURE';

export const UPDATE_CLUB_REQUEST = 'UPDATE_CLUB_REQUEST';
export const UPDATE_CLUB_SUCCESS = 'UPDATE_CLUB_SUCCESS';
export const UPDATE_CLUB_FAILURE = 'UPDATE_CLUB_FAILURE';

export const DELETE_CLUB_REQUEST = 'DELETE_CLUB_REQUEST';
export const DELETE_CLUB_SUCCESS = 'DELETE_CLUB_SUCCESS';
export const DELETE_CLUB_FAILURE = 'DELETE_CLUB_FAILURE';

export const CONNECTS_REQUEST = 'CONNECTS_REQUEST';
export const CONNECTS_SUCCESS = 'CONNECTS_SUCCESS';
export const CONNECTS_FAILURE = 'CONNECTS_FAILURE';

export const CREATE_CONNECT_REQUEST = 'CREATE_CONNECT_REQUEST';
export const CREATE_CONNECT_SUCCESS = 'CREATE_CONNECT_SUCCESS';
export const CREATE_CONNECT_FAILURE = 'CREATE_CONNECT_FAILURE';

export const UPDATE_CONNECT_REQUEST = 'UPDATE_CONNECT_REQUEST';
export const UPDATE_CONNECT_SUCCESS = 'UPDATE_CONNECT_SUCCESS';
export const UPDATE_CONNECT_FAILURE = 'UPDATE_CONNECT_FAILURE';

export const DELETE_CONNECT_REQUEST = 'DELETE_CONNECT_REQUEST';
export const DELETE_CONNECT_SUCCESS = 'DELETE_CONNECT_SUCCESS';
export const DELETE_CONNECT_FAILURE = 'DELETE_CONNECT_FAILURE';

export const RESET_FOLLOWING_DATA = 'RESET_FOLLOWING_DATA';
export const RESET_FOLLOWER_DATA = 'RESET_FOLLOWER_DATA';

export const FOLLOWERS_REQUEST = 'FOLLOWERS_REQUEST';
export const FOLLOWERS_SUCCESS = 'FOLLOWERS_SUCCESS';
export const FOLLOWERS_FAILURE = 'FOLLOWERS_FAILURE';

export const CREATE_FOLLOWER_REQUEST = 'CREATE_FOLLOWER_REQUEST';
export const CREATE_FOLLOWER_SUCCESS = 'CREATE_FOLLOWER_SUCCESS';
export const CREATE_FOLLOWER_FAILURE = 'CREATE_FOLLOWER_FAILURE';

export const UPDATE_FOLLOWER_REQUEST = 'UPDATE_FOLLOWER_REQUEST';
export const UPDATE_FOLLOWER_SUCCESS = 'UPDATE_FOLLOWER_SUCCESS';
export const UPDATE_FOLLOWER_FAILURE = 'UPDATE_FOLLOWER_FAILURE';

export const DELETE_FOLLOWER_REQUEST = 'DELETE_FOLLOWER_REQUEST';
export const DELETE_FOLLOWER_SUCCESS = 'DELETE_FOLLOWER_SUCCESS';
export const DELETE_FOLLOWER_FAILURE = 'DELETE_FOLLOWER_FAILURE';

export const FOLLOWING_REQUEST = 'FOLLOWING_REQUEST';
export const FOLLOWING_SUCCESS = 'FOLLOWING_SUCCESS';
export const FOLLOWING_FAILURE = 'FOLLOWING_FAILURE';

export const CREATE_FOLLOWING_REQUEST = 'CREATE_FOLLOWING_REQUEST';
export const CREATE_FOLLOWING_SUCCESS = 'CREATE_FOLLOWING_SUCCESS';
export const CREATE_FOLLOWING_FAILURE = 'CREATE_FOLLOWING_FAILURE';

export const FOLLOW_AN_USER_ADMIN_PANEL_REQUEST =
  'FOLLOW_AN_USER_ADMIN_PANEL_REQUEST';
export const FOLLOW_AN_USER_ADMIN_PANEL_SUCCESS =
  'FOLLOW_AN_USER_ADMIN_PANEL_SUCCESS';
export const FOLLOW_AN_USER_ADMIN_PANEL_FAILURE =
  'FOLLOW_AN_USER_ADMIN_PANEL_FAILURE';

export const UPDATE_FOLLOWING_REQUEST = 'UPDATE_FOLLOWING_REQUEST';
export const UPDATE_FOLLOWING_SUCCESS = 'UPDATE_FOLLOWING_SUCCESS';
export const UPDATE_FOLLOWING_FAILURE = 'UPDATE_FOLLOWING_FAILURE';

export const DELETE_FOLLOWING_REQUEST = 'DELETE_FOLLOWING_REQUEST';
export const DELETE_FOLLOWING_SUCCESS = 'DELETE_FOLLOWING_SUCCESS';
export const DELETE_FOLLOWING_FAILURE = 'DELETE_FOLLOWING_FAILURE';

export const UNFOLLOW_AN_USER_ADMIN_PANEL_REQUEST =
  'UNFOLLOW_AN_USER_ADMIN_PANEL_REQUEST';
export const UNFOLLOW_AN_USER_ADMIN_PANEL_SUCCESS =
  'UNFOLLOW_AN_USER_ADMIN_PANEL_SUCCESS';
export const UNFOLLOW_AN_USER_ADMIN_PANEL_FAILURE =
  'UNFOLLOW_AN_USER_ADMIN_PANEL_FAILURE';

export const MESSAGES_REQUEST = 'MESSAGES_REQUEST';
export const MESSAGES_SUCCESS = 'MESSAGES_SUCCESS';
export const MESSAGES_FAILURE = 'MESSAGES_FAILURE';

export const SEARCH_MESSAGES_REQUEST = 'SEARCH_MESSAGES_REQUEST';
export const SEARCH_MESSAGES_SUCCESS = 'SEARCH_MESSAGES_SUCCESS';
export const SEARCH_MESSAGES_FAILURE = 'SEARCH_MESSAGES_FAILURE';

export const CREATE_MESSAGE_REQUEST = 'CREATE_MESSAGE_REQUEST';
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';
export const CREATE_MESSAGE_FAILURE = 'CREATE_MESSAGE_FAILURE';

export const DELETE_MESSAGE_REQUEST = 'DELETE_MESSAGE_REQUEST';
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';
export const DELETE_MESSAGE_FAILURE = 'DELETE_MESSAGE_FAILURE';

export const SET_SELECTED_NOTIFICATION = 'SET_SELECTED_NOTIFICATION';
export const RESET_SELECTED_NOTIFICATION = 'RESET_SELECTED_NOTIFICATION';
export const TOGGLE_NOTIFICATIONS_DROPDOWN = 'TOGGLE_NOTIFICATIONS_DROPDOWN';

export const NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_REQUEST';
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS';
export const NOTIFICATIONS_FAILURE = 'NOTIFICATIONS_FAILURE';
export const SET_NOTIFICATIONS_COUNT = 'SET_NOTIFICATIONS_COUNT';

export const NOTIFICATIONS_MARK_ALL_READ_REQUEST =
  'NOTIFICATIONS_MARK_ALL_READ_REQUEST';
export const NOTIFICATIONS_MARK_ALL_READ_SUCCESS =
  'NOTIFICATIONS_MARK_ALL_READ_SUCCESS';
export const NOTIFICATIONS_MARK_ALL_READ_FAILURE =
  'NOTIFICATIONS_MARK_ALL_READ_FAILURE';

export const NOTIFICATIONS_REMOVE_ALL_REQUEST =
  'NOTIFICATIONS_REMOVE_ALL_REQUEST';
export const NOTIFICATIONS_REMOVE_ALL_SUCCESS =
  'NOTIFICATIONS_REMOVE_ALL_SUCCESS';
export const NOTIFICATIONS_REMOVE_ALL_FAILURE =
  'NOTIFICATIONS_REMOVE_ALL_FAILURE';

export const UPDATE_NOTIFICATION_REQUEST = 'UPDATE_NOTIFICATION_REQUEST';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_FAILURE = 'UPDATE_NOTIFICATION_FAILURE';

export const DELETE_NOTIFICATION_REQUEST = 'DELETE_NOTIFICATION_REQUEST';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAILURE = 'DELETE_NOTIFICATION_FAILURE';

export const MARK_NOTIFICATION_AS_READ_REQUEST =
  'MARK_NOTIFICATION_AS_READ_REQUEST';
export const MARK_NOTIFICATION_AS_READ_SUCCESS =
  'MARK_NOTIFICATION_AS_READ_SUCCESS';
export const MARK_NOTIFICATION_AS_READ_FAILURE =
  'MARK_NOTIFICATION_AS_READ_FAILURE';

export const SEARCH_JOBS_REQUEST = 'SEARCH_JOBS_REQUEST';
export const SEARCH_JOBS_SUCCESS = 'SEARCH_JOBS_SUCCESS';
export const SEARCH_JOBS_FAILURE = 'SEARCH_JOBS_FAILURE';
export const RESET_JOB_REDUCER = 'RESET_JOB_REDUCER';
export const TOGGLE_ADVANCED_FILTER = 'TOGGLE_ADVANCED_FILTER';
export const UPDATE_QUERY_PARAMS = 'UPDATE_QUERY_PARAMS';
export const UPDATE_JOB_SEARCH_PROPERTIES = 'UPDATE_JOB_SEARCH_PROPERTIES';

export const JOB_MATCHES_REQUEST = 'JOB_MATCHES_REQUEST';
export const JOB_MATCHES_SUCCESS = 'JOB_MATCHES_SUCCESS';
export const JOB_MATCHES_FAILURE = 'JOB_MATCHES_FAILURE';

export const JOB_MATCHES_CITIES_REQUEST = 'JOB_MATCHES_CITIES_REQUEST';
export const JOB_MATCHES_CITIES_SUCCESS = 'JOB_MATCHES_CITIES_SUCCESS';
export const JOB_MATCHES_CITIES_FAILURE = 'JOB_MATCHES_CITIES_FAILURE';

export const UPDATE_JOB_MATCHES_QUERY_PARAMS = 'UPDATE_JOB_MATCHES_QUERY_PARAMS';

export const RESET_JOB_MATCHES = 'RESET_JOB_MATCHES';
export const RESET_JOB_MATCHES_DATA = 'RESET_JOB_MATCHES_DATA';
export const TOGGLE_SELECT_JOB_MATCH = 'TOGGLE_SELECT_JOB_MATCH';
export const TOGGLE_JOB_MATCHES_FILTER = 'TOGGLE_JOB_MATCHES_FILTER';
export const TOGGLE_SELECT_ALL_JOB_MATCHES = 'TOGGLE_SELECT_ALL_JOB_MATCHES';
export const TOGGLE_SORT_JOB_MATCHES_FILTER = 'TOGGLE_SORT_JOB_MATCHES_FILTER';
export const TOGGLE_JOB_MATCHES_LOCATION_FILTER = 'TOGGLE_JOB_MATCHES_LOCATION_FILTER';

export const ADD_JOB_TO_TRACKER_REQUEST = 'ADD_JOB_TO_TRACKER_REQUEST';
export const ADD_JOB_TO_TRACKER_SUCCESS = 'ADD_JOB_TO_TRACKER_SUCCESS';
export const ADD_JOB_TO_TRACKER_FAILURE = 'ADD_JOB_TO_TRACKER_FAILURE';

export const ADD_JOB_TO_BOARD_REQUEST = 'ADD_JOB_TO_BOARD_REQUEST';
export const ADD_JOB_TO_BOARD_SUCCESS = 'ADD_JOB_TO_BOARD_SUCCESS';
export const ADD_JOB_TO_BOARD_FAILURE = 'ADD_JOB_TO_BOARD_FAILURE';

export const FETCH_MY_JOBS_REQUEST = 'FETCH_MY_JOBS_REQUEST';
export const FETCH_MY_JOBS_SUCCESS = 'FETCH_MY_JOBS_SUCCESS';
export const FETCH_MY_JOBS_FAILURE = 'FETCH_MY_JOBS_FAILURE';
export const RESET_JOB_BOARD = 'RESET_JOB_BOARD';

export const UPDATE_JOB_DETAILS_REQUEST = 'UPDATE_JOB_DETAILS_REQUEST';
export const UPDATE_JOB_DETAILS_SUCCESS = 'UPDATE_JOB_DETAILS_SUCCESS';
export const UPDATE_JOB_DETAILS_FAILURE = 'UPDATE_JOB_DETAILS_FAILURE';

export const POST_NEW_JOB_REQUEST = 'POST_NEW_JOB_REQUEST';
export const POST_NEW_JOB_SUCCESS = 'POST_NEW_JOB_SUCCESS';
export const POST_NEW_JOB_FAILURE = 'POST_NEW_JOB_FAILURE';

export const DELETE_JOB_FROM_BOARD_REQUEST = 'DELETE_JOB_FROM_BOARD_REQUEST';
export const DELETE_JOB_FROM_BOARD_SUCCESS = 'DELETE_JOB_FROM_BOARD_SUCCESS';
export const DELETE_JOB_FROM_BOARD_FAILURE = 'DELETE_JOB_FROM_BOARD_FAILURE';

export const FETCH_JOB_NOTES_REQUEST = 'FETCH_JOB_NOTES_REQUEST';
export const FETCH_JOB_NOTES_SUCCESS = 'FETCH_JOB_NOTES_SUCCESS';
export const FETCH_JOB_NOTES_FAILURE = 'FETCH_JOB_NOTES_FAILURE';

export const ADD_JOB_NOTE_REQUEST = 'ADD_JOB_NOTE_REQUEST';
export const ADD_JOB_NOTE_SUCCESS = 'ADD_JOB_NOTE_SUCCESS';
export const ADD_JOB_NOTE_FAILURE = 'ADD_JOB_NOTE_FAILURE';

export const EDIT_JOB_NOTE_REQUEST = 'EDIT_JOB_NOTE_REQUEST';
export const EDIT_JOB_NOTE_SUCCESS = 'EDIT_JOB_NOTE_SUCCESS';
export const EDIT_JOB_NOTE_FAILURE = 'FETCH_JOB_DETAILS_FAILURE';

export const DELETE_JOB_NOTE_REQUEST = 'DELETE_JOB_NOTE_REQUEST';
export const DELETE_JOB_NOTE_SUCCESS = 'DELETE_JOB_NOTE_SUCCESS';
export const DELETE_JOB_NOTE_FAILURE = 'DELETE_JOB_NOTE_FAILURE';

export const CREATE_JOB_MATCH_SETTINGS_REQUEST = 'CREATE_JOB_MATCH_SETTINGS_REQUEST';
export const CREATE_JOB_MATCH_SETTINGS_SUCCESS = 'CREATE_JOB_MATCH_SETTINGS_SUCCESS';
export const CREATE_JOB_MATCH_SETTINGS_FAILURE = 'CREATE_JOB_MATCH_SETTINGS_FAILURE';

export const UPDATE_JOB_MATCH_SETTINGS_REQUEST = 'UPDATE_JOB_MATCH_SETTINGS_REQUEST';
export const UPDATE_JOB_MATCH_SETTINGS_SUCCESS = 'UPDATE_JOB_MATCH_SETTINGS_SUCCESS';
export const UPDATE_JOB_MATCH_SETTINGS_FAILURE = 'UPDATE_JOB_MATCH_SETTINGS_FAILURE';

export const JOB_MATCH_SETTINGS_REQUEST = 'JOB_MATCH_SETTINGS_REQUEST';
export const JOB_MATCH_SETTINGS_SUCCESS = 'JOB_MATCH_SETTINGS_SUCCESS';
export const JOB_MATCH_SETTINGS_FAILURE = 'JOB_MATCH_SETTINGS_FAILURE';

export const TOGGLE_JOB_MATCH_SETTINGS_INITIAL_SEARCH_REQUEST = 'TOGGLE_JOB_MATCH_SETTINGS_INITIAL_SEARCH_REQUEST';

export const GET_ISSUED_BADGES_REQUEST = 'GET_ISSUED_BADGES_REQUEST';
export const GET_ISSUED_BADGES_SUCCESS = 'GET_ISSUED_BADGES_SUCCESS';
export const GET_ISSUED_BADGES_FAILURE = 'GET_ISSUED_BADGES_FAILURE';
export const GET_MY_ISSUED_BADGES_SUCCESS = 'GET_MY_ISSUED_BADGES_SUCCESS';

export const BADGES_REQUEST = 'BADGES_REQUEST';
export const BADGES_SUCCESS = 'BADGES_SUCCESS';
export const BADGES_FAILURE = 'BADGES_FAILURE';

export const ISSUE_BADGE_REQUEST = 'ISSUE_BADGE_REQUEST';
export const ISSUE_BADGE_SUCCESS = 'ISSUE_BADGE_SUCCESS';
export const ISSUE_BADGE_FAILURE = 'ISSUE_BADGE_FAILURE';

export const BADGE_ASSERTION_REQUEST = 'BADGE_ASSERTION_REQUEST';
export const BADGE_ASSERTION_SUCCESS = 'BADGE_ASSERTION_SUCCESS';
export const BADGE_ASSERTION_FAILURE = 'BADGE_ASSERTIONS_FAILURE';

export const RESET_ASSERTION_REDUCER = 'RESET_ASSERTION_REDUCER';
export const ASSERTION_FILE_LOADED = 'ASSERTION_FILE_LOADED';

export const ADD_ACKNOWLEDGEMENT_REQUEST = 'ADD_ACKNOWLEDGEMENT_REQUEST';
export const ADD_ACKNOWLEDGEMENT_SUCCESS = 'ADD_ACKNOWLEDGEMENT_SUCCESS';
export const ADD_ACKNOWLEDGEMENT_FAILURE = 'ADD_ACKNOWLEDGEMENT_FAILURE';

export const REMOVE_ACKNOWLEDGEMENT_REQUEST = 'REMOVE_ACKNOWLEDGEMENT_REQUEST';
export const REMOVE_ACKNOWLEDGEMENT_SUCCESS = 'REMOVE_ACKNOWLEDGEMENT_SUCCESS';
export const REMOVE_ACKNOWLEDGEMENT_FAILURE = 'REMOVE_ACKNOWLEDGEMENT_FAILURE';

export const ADD_EVIDENCE_FILE_REQUEST = 'ADD_EVIDENCE_FILE_REQUEST';
export const ADD_EVIDENCE_FILE_SUCCESS = 'ADD_EVIDENCE_FILE_SUCCESS';
export const ADD_EVIDENCE_FILE_FAILURE = 'ADD_EVIDENCE_FILE_FAILURE';

export const REMOVE_EVIDENCE_FILE_REQUEST = 'REMOVE_EVIDENCE_FILE_REQUEST';
export const REMOVE_EVIDENCE_FILE_SUCCESS = 'REMOVE_EVIDENCE_FILE_SUCCESS';
export const REMOVE_EVIDENCE_FILE_FAILURE = 'REMOVE_EVIDENCE_FILE_FAILURE';

export const REMOVE_BADGE_EVIDENCE_FILE_REQUEST = 'REMOVE_BADGE_EVIDENCE_FILE_REQUEST';
export const REMOVE_BADGE_EVIDENCE_FILE_SUCCESS = 'REMOVE_BADGE_EVIDENCE_FILE_SUCCESS';
export const REMOVE_BADGE_EVIDENCE_FILE_FAILURE = 'REMOVE_BADGE_EVIDENCE_FILE_FAILURE';

export const UPDATE_STATEMENT_REQUEST = 'UPDATE_STATEMENT_REQUEST';
export const UPDATE_STATEMENT_SUCCESS = 'UPDATE_STATEMENT_SUCCESS';
export const UPDATE_STATEMENT_FAILURE = 'UPDATE_STATEMENT_FAILURE';

export const UPDATE_JUSTIFICATION_ANSWER_REQUEST =
  'UPDATE_JUSTIFICATION_ANSWER_REQUEST';
export const UPDATE_JUSTIFICATION_ANSWER_SUCCESS =
  'UPDATE_JUSTIFICATION_ANSWER_SUCCESS';
export const UPDATE_JUSTIFICATION_ANSWER_FAILURE =
  'UPDATE_JUSTIFICATION_ANSWER_FAILURE';

export const LIKE_EVIDENCE_FILE_REQUEST = 'LIKE_EVIDENCE_FILE_REQUEST';
export const LIKE_EVIDENCE_FILE_SUCCESS = 'LIKE_EVIDENCE_FILE_SUCCESS';
export const LIKE_EVIDENCE_FILE_FAILURE = 'LIKE_EVIDENCE_FILE_FAILURE';

export const UNLIKE_EVIDENCE_FILE_REQUEST = 'UNLIKE_EVIDENCE_FILE_REQUEST';
export const UNLIKE_EVIDENCE_FILE_SUCCESS = 'UNLIKE_EVIDENCE_FILE_SUCCESS';
export const UNLIKE_EVIDENCE_FILE_FAILURE = 'UNLIKE_EVIDENCE_FILE_FAILURE';

export const LOAD_ENDORSEMENT = 'LOAD_ENDORSEMENT';
export const UNLOAD_ENDORSEMENT = 'UNLOAD_ENDORSEMENT';
export const RESET_BADGE_ENDORSEMENT_REDUCER =
  'RESET_BADGE_ENDORSEMENT_REDUCER';

export const ASK_ENDORSEMENT_REQUEST = 'ASK_ENDORSEMENT_REQUEST';
export const ASK_ENDORSEMENT_SUCCESS = 'ASK_ENDORSEMENT_SUCCESS';
export const ASK_ENDORSEMENT_FAILURE = 'ASK_ENDORSEMENT_FAILURE';

export const ENDORSE_BADGE_REQUEST = 'ENDORSE_BADGE_REQUEST';
export const ENDORSE_BADGE_SUCCESS = 'ENDORSE_BADGE_SUCCESS';
export const ENDORSE_BADGE_FAILURE = 'ENDORSE_BADGE_FAILURE';

export const ENDORSEMENT_BY_ID_REQUEST = 'ENDORSEMENT_BY_ID_REQUEST';
export const ENDORSEMENT_BY_ID_SUCCESS = 'ENDORSEMENT_BY_ID_SUCCESS';
export const ENDORSEMENT_BY_ID_FAILURE = 'ENDORSEMENT_BY_ID_FAILURE';

export const CONFIRM_ENDORSEMENT_REQUEST = 'CONFIRM_ENDORSEMENT_REQUEST';
export const CONFIRM_ENDORSEMENT_SUCCESS = 'CONFIRM_ENDORSEMENT_SUCCESS';
export const CONFIRM_ENDORSEMENT_FAILURE = 'CONFIRM_ENDORSEMENT_FAILURE';

export const LINK_PORTFOLIO_FILE_REQUEST = 'LINK_PORTFOLIO_FILE_REQUEST';
export const LINK_PORTFOLIO_FILE_SUCCESS = 'LINK_PORTFOLIO_FILE_SUCCESS';
export const LINK_PORTFOLIO_FILE_FAILURE = 'LINK_PORTFOLIO_FILE_FAILURE';

export const LINK_EVIDENCE_FILES_REQUEST = 'LINK_EVIDENCE_FILES_REQUEST';
export const LINK_EVIDENCE_FILES_SUCCESS = 'LINK_EVIDENCE_FILES_SUCCESS';
export const LINK_EVIDENCE_FILES_FAILURE = 'LINK_EVIDENCE_FILES_FAILURE';

export const LINK_MY_SKILLS_REQUEST = 'LINK_MY_SKILLS_REQUEST';
export const LINK_MY_SKILLS_SUCCESS = 'LINK_MY_SKILLS_SUCCESS';
export const LINK_MY_SKILLS_FAILURE = 'LINK_MY_SKILLS_FAILURE';

export const GET_RESOURCE_DATA_REQUEST = 'GET_RESOURCE_DATA_REQUEST';
export const GET_RESOURCE_DATA_SUCCESS = 'GET_RESOURCE_DATA_SUCCESS';
export const GET_RESOURCE_DATA_FAILURE = 'GET_RESOURCE_DATA_FAILURE';

export const RESUME_REQUEST = 'RESUME_REQUEST';
export const RESUME_SUCCESS = 'RESUME_SUCCESS';
export const RESUME_FAILURE = 'RESUME_FAILURE';

export const CREATE_RESUME_REQUEST = 'CREATE_RESUME_REQUEST';
export const CREATE_RESUME_SUCCESS = 'CREATE_RESUME_SUCCESS';
export const CREATE_RESUME_FAILURE = 'CREATE_RESUME_FAILURE';

export const UPLOAD_RESUME_REQUEST = 'UPLOAD_RESUME_REQUEST';
export const UPLOAD_RESUME_SUCCESS = 'UPLOAD_RESUME_SUCCESS';
export const UPLOAD_RESUME_FAILURE = 'UPLOAD_RESUME_FAILURE';

export const DELETE_RESUME_REQUEST = 'DELETE_RESUME_REQUEST';
export const DELETE_RESUME_SUCCESS = 'DELETE_RESUME_SUCCESS';
export const DELETE_RESUME_FAILURE = 'DELETE_RESUME_FAILURE';

export const RESET_ORG_REDUCER = 'RESET_ORG_REDUCER';

export const GET_ALL_ORG_REQUEST = 'GET_ALL_ORG_REQUEST';
export const GET_ALL_ORG_SUCCESS = 'GET_ALL_ORG_SUCCESS';
export const GET_ALL_ORG_FAILURE = 'GET_ALL_ORG_FAILURE';

export const SEARCH_ORGANIZATIONS_REQUEST = 'SEARCH_ORGANIZATIONS_REQUEST';
export const SEARCH_ORGANIZATIONS_SUCCESS = 'SEARCH_ORGANIZATIONS_SUCCESS';
export const SEARCH_ORGANIZATIONS_FAILURE = 'SEARCH_ORGANIZATIONS_FAILURE';

export const CREATE_ORGANIZATION_REQUEST = 'CREATE_ORGANIZATION_REQUEST';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_FAILURE = 'CREATE_ORGANIZATION_FAILURE';

export const UPDATE_ORGANIZATION_REQUEST = 'UPDATE_ORGANIZATION_REQUEST';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_FAILURE = 'UPDATE_ORGANIZATION_FAILURE';

export const SET_SELECTED_ORGANIZATION = 'SET_SELECTED_ORGANIZATION';

export const ONCHANGE_ORGANIZATIONS_SEARCH_QUERY =
  'ONCHANGE_ORGANIZATIONS_SEARCH_QUERY';

export const INVITE_USERS_REQUEST = 'INVITE_USERS_REQUEST';
export const INVITE_USERS_SUCCESS = 'INVITE_USERS_SUCCESS';
export const INVITE_USERS_FAILURE = 'INVITE_USERS_FAILURE';

export const ORGANIZATION_INVITATION_REQUEST =
  'ORGANIZATION_INVITATION_REQUEST';
export const ORGANIZATION_INVITATION_SUCCESS =
  'ORGANIZATION_INVITATION_SUCCESS';
export const ORGANIZATION_INVITATION_FAILURE =
  'ORGANIZATION_INVITATION_FAILURE';

export const ORGANIZATION_USERS_REQUEST = 'ORGANIZATION_USERS_REQUEST';
export const ORGANIZATION_USERS_SUCCESS = 'ORGANIZATION_USERS_SUCCESS';
export const ORGANIZATION_USERS_FAILURE = 'ORGANIZATION_USERS_FAILURE';

export const ONCHANGE_ORGANIZATION_USERS_SEARCH_QUERY =
  'ONCHANGE_ORGANIZATION_USERS_SEARCH_QUERY';
export const ONCHANGE_ORGANIZATION_USERS_LOCATION =
  'ONCHANGE_ORGANIZATION_USERS_LOCATION';
export const ONCHANGE_ORGANIZATION_USERS_EMPLOYMENT_TYPE =
  'ONCHANGE_ORGANIZATION_USERS_EMPLOYMENT_TYPE';
export const ONCHANGE_ORGANIZATION_USERS_EMPLOYMENT_STATUS =
  'ONCHANGE_ORGANIZATION_USERS_EMPLOYMENT_STATUS';

export const ORGANIZATION_USERS_FILTERED_REQUEST =
  'ORGANIZATION_USERS_FILTERED_REQUEST';
export const ORGANIZATION_USERS_FILTERED_SUCCESS =
  'ORGANIZATION_USERS_FILTERED_SUCCESS';
export const ORGANIZATION_USERS_FILTERED_FAILURE =
  'ORGANIZATION_USERS_FILTERED_FAILURE';
export const ORGANIZATION_USERS_FILTERED_CLEAR_SUCCESS =
  'ORGANIZATION_USERS_FILTERED_CLEAR_SUCCESS';

export const ORGANIZATION_INSTITUTION_STATS_REQUEST =
  'ORGANIZATION_INSTITUTION_STATS_REQUEST';
export const ORGANIZATION_INSTITUTION_STATS_SUCCESS =
  'ORGANIZATION_INSTITUTION_STATS_SUCCESS';
export const ORGANIZATION_INSTITUTION_STATS_FAILURE =
  'ORGANIZATION_INSTITUTION_STATS_FAILURE';

export const SET_SELECTED_INSTITUTION_INDEX = 'SET_SELECTED_INSTITUTION_INDEX';
export const SET_NUMBER_OF_GROUPS_TO_CREATE = 'SET_NUMBER_OF_GROUPS_TO_CREATE';
export const SET_UNSAVED_CHILD_GROUPS_DATA = 'SET_UNSAVED_CHILD_GROUPS_DATA';
export const ORGANIZATION_GROUP_STATS_REQUEST =
  'ORGANIZATION_GROUP_STATS_REQUEST';
export const ORGANIZATION_GROUP_STATS_SUCCESS =
  'ORGANIZATION_GROUP_STATS_SUCCESS';
export const ORGANIZATION_GROUP_STATS_FAILURE =
  'ORGANIZATION_GROUP_STATS_FAILURE';

export const ORGANIZATION_GROUP_USERS_FILTERED_REQUEST =
  'ORGANIZATION_GROUP_USERS_FILTERED_REQUEST';
export const ORGANIZATION_GROUP_USERS_FILTERED_SUCCESS =
  'ORGANIZATION_GROUP_USERS_FILTERED_SUCCESS';
export const ORGANIZATION_GROUP_USERS_FILTERED_FAILURE =
  'ORGANIZATION_GROUP_USERS_FILTERED_FAILURE';

export const ONCHANGE_INVITATION_SEARCH_QUERY =
  'ONCHANGE_INVITATION_SEARCH_QUERY';

export const ORGANIZATION_INVITATION_FILTERED_REQUEST =
  'ORGANIZATION_INVITATION_FILTERED_REQUEST';
export const ORGANIZATION_INVITATION_FILTERED_SUCCESS =
  'ORGANIZATION_INVITATION_FILTERED_SUCCESS';
export const ORGANIZATION_INVITATION_FILTERED_FAILURE =
  'ORGANIZATION_INVITATION_FILTERED_FAILURE';

export const ORGANIZATION_GROUP_INVITATION_FILTERED_REQUEST =
  'ORGANIZATION_GROUP_INVITATION_FILTERED_REQUEST';
export const ORGANIZATION_GROUP_INVITATION_FILTERED_SUCCESS =
  'ORGANIZATION_GROUP_INVITATION_FILTERED_SUCCESS';
export const ORGANIZATION_GROUP_INVITATION_FILTERED_FAILURE =
  'ORGANIZATION_GROUP_INVITATION_FILTERED_FAILURE';

export const INVITE_USERS_TO_ORG_REQUEST = 'INVITE_USERS_TO_ORG_REQUEST';
export const INVITE_USERS_TO_ORG_SUCCESS = 'INVITE_USERS_TO_ORG_SUCCESS';
export const INVITE_USERS_TO_ORG_FAILURE = 'INVITE_USERS_TO_ORG_FAILURE';

export const RESEND_INVITE_TO_ORG_REQUEST = 'RESEND_INVITE_TO_ORG_REQUEST';
export const RESEND_INVITE_TO_ORG_SUCCESS = 'RESEND_INVITE_TO_ORG_SUCCESS';
export const RESEND_INVITE_TO_ORG_FAILURE = 'RESEND_INVITE_TO_ORG_FAILURE';

export const INVITE_DETAILS_REQUEST = 'INVITE_DETAILS_REQUEST';
export const INVITE_DETAILS_SUCCESS = 'INVITE_DETAILS_SUCCESS';
export const INVITE_DETAILS_FAILURE = 'INVITE_DETAILS_FAILURE';

export const REVOKE_INVITE_TO_ORG_REQUEST = 'REVOKE_INVITE_TO_ORG_REQUEST';
export const REVOKE_INVITE_TO_ORG_SUCCESS = 'REVOKE_INVITE_TO_ORG_SUCCESS';
export const REVOKE_INVITE_TO_ORG_FAILURE = 'REVOKE_INVITE_TO_ORG_FAILURE';

export const DECLINE_INVITE_REQUEST = 'DECLINE_INVITE_REQUEST';
export const DECLINE_INVITE_SUCCESS = 'DECLINE_INVITE_SUCCESS';
export const DECLINE_INVITE_FAILURE = 'DECLINE_INVITE_FAILURE';

export const JOIN_CP_REQUEST = 'JOIN_CP_REQUEST';
export const JOIN_CP_SUCCESS = 'JOIN_CP_SUCCESS';
export const JOIN_CP_FAILURE = 'JOIN_CP_FAILURE';

export const SET_PREVIEW_FILE = 'SET_PREVIEW_FILE';
export const CLEAR_PREVIEW_FILE = 'CLEAR_PREVIEW_FILE';

export const RESET_WEBPAGE_DETAILS = 'RESET_WEBPAGE_DETAILS';
export const WEBPAGE_DETAILS_REQUEST = 'WEBPAGE_DETAILS_REQUEST';
export const WEBPAGE_DETAILS_SUCCESS = 'WEBPAGE_DETAILS_SUCCESS';
export const WEBPAGE_DETAILS_FAILURE = 'WEBPAGE_DETAILS_FAILURE';

export const QUESTIONAIRES_REQUEST = 'QUESTIONAIRES_REQUEST';
export const QUESTIONAIRES_SUCCESS = 'QUESTIONAIRES_SUCCESS';
export const QUESTIONAIRES_FAILURE = 'QUESTIONAIRES_FAILURE';

export const SB_CATEGORY_REQUEST = 'SB_CATEGORY_REQUEST';
export const SB_CATEGORY_SUCCESS = 'SB_CATEGORY_SUCCESS';
export const SB_CATEGORY_FAILURE = 'SB_CATEGORY_FAILURE';

export const RESET_SOCIAL_LINKS_STATE = 'RESET_SOCIAL_LINKS_STATE';

export const USER_SOCIAL_LINKS_REQUEST = 'USER_SOCIAL_LINKS_REQUEST';
export const USER_SOCIAL_LINKS_SUCCESS = 'USER_SOCIAL_LINKS_SUCCESS';
export const USER_SOCIAL_LINKS_FAILURE = 'USER_SOCIAL_LINKS_FAILURE';

export const ADD_SOCIAL_LINKS_REQUEST = 'ADD_SOCIAL_LINKS_REQUEST';
export const ADD_SOCIAL_LINKS_SUCCESS = 'ADD_SOCIAL_LINKS_SUCCESS';
export const ADD_SOCIAL_LINKS_FAILURE = 'ADD_SOCIAL_LINKS_FAILURE';

export const UPDATE_SOCIAL_LINKS_REQUEST = 'UPDATE_SOCIAL_LINKS_REQUEST';
export const UPDATE_SOCIAL_LINKS_SUCCESS = 'UPDATE_SOCIAL_LINKS_SUCCESS';
export const UPDATE_SOCIAL_LINKS_FAILURE = 'UPDATE_SOCIAL_LINKS_FAILURE';

export const DELETE_SOCIAL_LINKS_REQUEST = 'DELETE_SOCIAL_LINKS_REQUEST';
export const DELETE_SOCIAL_LINKS_SUCCESS = 'DELETE_SOCIAL_LINKS_SUCCESS';
export const DELETE_SOCIAL_LINKS_FAILURE = 'DELETE_SOCIAL_LINKS_FAILURE';

export const LINK_NEW_PORTFOLIO_FILE_TO_A_BADGE_REQUEST =
  'LINK_NEW_PORTFOLIO_FILE_TO_A_BADGE_REQUEST';
export const LINK_NEW_PORTFOLIO_FILE_TO_A_BADGE_SUCCESS =
  'LINK_NEW_PORTFOLIO_FILE_TO_A_BADGE_SUCCESS';
export const LINK_NEW_PORTFOLIO_FILE_TO_A_BADGE_FAILURE =
  'LINK_NEW_PORTFOLIO_FILE_TO_A_BADGE_FAILURE';

export const LINK_NEW_PORTFOLIO_FILE_TO_A_BAKED_BADGE_REQUEST =
  'LINK_NEW_PORTFOLIO_FILE_TO_A_BAKED_BADGE_REQUEST';
export const LINK_NEW_PORTFOLIO_FILE_TO_A_BAKED_BADGE_SUCCESS =
  'LINK_NEW_PORTFOLIO_FILE_TO_A_BAKED_BADGE_SUCCESS';
export const LINK_NEW_PORTFOLIO_FILE_TO_A_BAKED_BADGE_FAILURE =
  'LINK_NEW_PORTFOLIO_FILE_TO_A_BAKED_BADGE_FAILURE';

export const UNIT_SKILL_REQUEST = 'UNIT_SKILL_REQUEST';
export const UNIT_SKILL_SUCCESS = 'UNIT_SKILL_SUCCESS';
export const UNIT_SKILL_FAILURE = 'UNIT_SKILL_FAILURE';
export const RESET_SKILLS_DATA = 'RESET_SKILLS_DATA';

export const UPDATE_UNIT_SKILL_FILES = 'UPDATE_UNIT_SKILL_FILES';

export const UNIT_FILE_COMMENTS_REQUEST = 'UNIT_FILE_COMMENTS_REQUEST';
export const UNIT_FILE_COMMENTS_SUCCESS = 'UNIT_FILE_COMMENTS_SUCCESS';
export const UNIT_FILE_COMMENTS_FAILURE = 'UNIT_FILE_COMMENTS_FAILURE';

export const ADD_UNIT_FILE_COMMENTS_REQUEST = 'ADD_UNIT_FILE_COMMENTS_REQUEST';
export const ADD_UNIT_FILE_COMMENTS_SUCCESS = 'ADD_UNIT_FILE_COMMENTS_SUCCESS';
export const ADD_UNIT_FILE_COMMENTS_FAILURE = 'ADD_UNIT_FILE_COMMENTS_FAILURE';

export const ADD_FILE_COMMENT_REPLY_REQUEST = 'ADD_FILE_COMMENT_REPLY_REQUEST';
export const ADD_FILE_COMMENT_REPLY_SUCCESS = 'ADD_FILE_COMMENT_REPLY_SUCCESS';
export const ADD_FILE_COMMENT_REPLY_FAILURE = 'ADD_FILE_COMMENT_REPLY_FAILURE';

export const UPDATE_FILE_COMMENTS_REQUEST = 'UPDATE_FILE_COMMENTS_REQUEST';
export const UPDATE_FILE_COMMENTS_SUCCESS = 'UPDATE_FILE_COMMENTS_SUCCESS';
export const UPDATE_FILE_COMMENTS_FAILURE = 'UPDATE_FILE_COMMENTS_FAILURE';

export const DELETE_FILE_COMMENT_REQUEST = 'DELETE_FILE_COMMENT_REQUEST';
export const DELETE_FILE_COMMENT_SUCCESS = 'DELETE_FILE_COMMENT_SUCCESS';
export const DELETE_FILE_COMMENT_FAILURE = 'DELETE_FILE_COMMENT_FAILURE';

export const DELETE_FILE_COMMENT_REPLY_REQUEST =
  'DELETE_FILE_COMMENT_REPLY_REQUEST';
export const DELETE_FILE_COMMENT_REPLY_SUCCESS =
  'DELETE_FILE_COMMENT_REPLY_SUCCESS';
export const DELETE_FILE_COMMENT_REPLY_FAILURE =
  'DELETE_FILE_COMMENT_REPLY_FAILURE';

export const LIKE_FILE_REQUEST = 'LIKE_FILE_REQUEST';
export const LIKE_FILE_SUCCESS = 'LIKE_FILE_SUCCESS';
export const LIKE_FILE_FAILURE = 'LIKE_FILE_FAILURE';

export const UNLIKE_FILE_REQUEST = 'UNLIKE_FILE_REQUEST';
export const UNLIKE_FILE_SUCCESS = 'UNLIKE_FILE_SUCCESS';
export const UNLIKE_FILE_FAILURE = 'UNLIKE_FILE_FAILURE';

export const LIKE_FILE_COMMENT_REQUEST = 'LIKE_FILE_COMMENT_REQUEST';
export const LIKE_FILE_COMMENT_SUCCESS = 'LIKE_FILE_COMMENT_SUCCESS';
export const LIKE_FILE_COMMENT_FAILURE = 'LIKE_FILE_COMMENT_FAILURE';

export const UPDATE_FILE_COMMENT_REPLY_REQUEST =
  'UPDATE_FILE_COMMENT_REPLY_REQUEST';
export const UPDATE_FILE_COMMENT_REPLY_SUCCESS =
  'UPDATE_FILE_COMMENT_REPLY_SUCCESS';
export const UPDATE_FILE_COMMENT_REPLY_FAILURE =
  'UPDATE_FILE_COMMENT_REPLY_FAILURE';

export const LIKE_FILE_COMMENT_REPLY_REQUEST =
  'LIKE_FILE_COMMENT_REPLY_REQUEST';
export const LIKE_FILE_COMMENT_REPLY_SUCCESS =
  'LIKE_FILE_COMMENT_REPLY_SUCCESS';
export const LIKE_FILE_COMMENT_REPLY_FAILURE =
  'LIKE_FILE_COMMENT_REPLY_FAILURE';

export const UNLIKE_FILE_COMMENT_REQUEST = 'UNLIKE_FILE_COMMENT_REQUEST';
export const UNLIKE_FILE_COMMENT_SUCCESS = 'UNLIKE_FILE_COMMENT_SUCCESS';
export const UNLIKE_FILE_COMMENT_FAILURE = 'UNLIKE_FILE_COMMENT_FAILURE';

export const UNLIKE_FILE_COMMENT_REPLY_REQUEST =
  'UNLIKE_FILE_COMMENT_REPLY_REQUEST';
export const UNLIKE_FILE_COMMENT_REPLY_SUCCESS =
  'UNLIKE_FILE_COMMENT_REPLY_SUCCESS';
export const UNLIKE_FILE_COMMENT_REPLY_FAILURE =
  'UNLIKE_FILE_COMMENT_REPLY_FAILURE';

export const LIKE_UNIT_SKILL_FILE_REQUEST = 'LIKE_UNIT_SKILL_FILE_REQUEST';
export const LIKE_UNIT_SKILL_FILE_SUCCESS = 'LIKE_UNIT_SKILL_FILE_SUCCESS';
export const LIKE_UNIT_SKILL_FILE_FAILURE = 'LIKE_UNIT_SKILL_FILE_FAILURE';

export const UNLIKE_UNIT_SKILL_FILE_REQUEST = 'UNLIKE_UNIT_SKILL_FILE_REQUEST';
export const UNLIKE_UNIT_SKILL_FILE_SUCCESS = 'UNLIKE_UNIT_SKILL_FILE_SUCCESS';
export const UNLIKE_UNIT_SKILL_FILE_FAILURE = 'UNLIKE_UNIT_SKILL_FILE_FAILURE';

export const INTERVIEW_PREP_VIDEOS_REQUEST = 'INTERVIEW_PREP_VIDEOS_REQUEST';
export const INTERVIEW_PREP_VIDEOS_SUCCESS = 'INTERVIEW_PREP_VIDEOS_SUCCESS';
export const INTERVIEW_PREP_VIDEOS_FAILURE = 'INTERVIEW_PREP_VIDEOS_FAILURE';

export const UNIT_INTERVIEW_PREP_VIDEO_REQUEST =
  'UNIT_INTERVIEW_PREP_VIDEO_REQUEST';
export const UNIT_INTERVIEW_PREP_VIDEO_SUCCESS =
  'UNIT_INTERVIEW_PREP_VIDEO_SUCCESS';
export const UNIT_INTERVIEW_PREP_VIDEO_FAILURE =
  'UNIT_INTERVIEW_PREP_VIDEO_FAILURE';

export const UNIT_VIDEO_COMMENTS_REQUEST = 'UNIT_VIDEO_COMMENTS_REQUEST';
export const UNIT_VIDEO_COMMENTS_SUCCESS = 'UNIT_VIDEO_COMMENTS_SUCCESS';
export const UNIT_VIDEO_COMMENTS_FAILURE = 'UNIT_VIDEO_COMMENTS_FAILURE';

export const LIKE_VIDEO_REQUEST = 'LIKE_VIDEO_REQUEST';
export const LIKE_VIDEO_SUCCESS = 'LIKE_VIDEO_SUCCESS';
export const LIKE_VIDEO_FAILURE = 'LIKE_VIDEO_FAILURE';

export const UNLIKE_VIDEO_REQUEST = 'UNLIKE_VIDEO_REQUEST';
export const UNLIKE_VIDEO_SUCCESS = 'UNLIKE_VIDEO_SUCCESS';
export const UNLIKE_VIDEO_FAILURE = 'UNLIKE_VIDEO_FAILURE';

export const ADD_VIDEO_COMMENT_REQUEST = 'ADD_VIDEO_COMMENT_REQUEST';
export const ADD_VIDEO_COMMENT_SUCCESS = 'ADD_VIDEO_COMMENT_SUCCESS';
export const ADD_VIDEO_COMMENT_FAILURE = 'ADD_VIDEO_COMMENT_FAILURE';

export const ADD_VIDEO_COMMENT_REPLY_REQUEST =
  'ADD_VIDEO_COMMENT_REPLY_REQUEST';
export const ADD_VIDEO_COMMENT_REPLY_SUCCESS =
  'ADD_VIDEO_COMMENT_REPLY_SUCCESS';
export const ADD_VIDEO_COMMENT_REPLY_FAILURE =
  'ADD_VIDEO_COMMENT_REPLY_FAILURE';

export const LIKE_VIDEO_COMMENT_REQUEST = 'LIKE_VIDEO_COMMENT_REQUEST';
export const LIKE_VIDEO_COMMENT_SUCCESS = 'LIKE_VIDEO_COMMENT_SUCCESS';
export const LIKE_VIDEO_COMMENT_FAILURE = 'LIKE_VIDEO_COMMENT_FAILURE';

export const UNLIKE_VIDEO_COMMENT_REQUEST = 'UNLIKE_VIDEO_COMMENT_REQUEST';
export const UNLIKE_VIDEO_COMMENT_SUCCESS = 'UNLIKE_VIDEO_COMMENT_SUCCESS';
export const UNLIKE_VIDEO_COMMENT_FAILURE = 'UNLIKE_VIDEO_COMMENT_FAILURE';

export const LIKE_VIDEO_COMMENT_REPLY_REQUEST =
  'LIKE_VIDEO_COMMENT_REPLY_REQUEST';
export const LIKE_VIDEO_COMMENT_REPLY_SUCCESS =
  'LIKE_VIDEO_COMMENT_REPLY_SUCCESS';
export const LIKE_VIDEO_COMMENT_REPLY_FAILURE =
  'LIKE_VIDEO_COMMENT_REPLY_FAILURE';

export const UNLIKE_VIDEO_COMMENT_REPLY_REQUEST =
  'UNLIKE_VIDEO_COMMENT_REPLY_REQUEST';
export const UNLIKE_VIDEO_COMMENT_REPLY_SUCCESS =
  'UNLIKE_VIDEO_COMMENT_REPLY_SUCCESS';
export const UNLIKE_VIDEO_COMMENT_REPLY_FAILURE =
  'UNLIKE_VIDEO_COMMENT_REPLY_FAILURE';

export const DELETE_VIDEO_COMMENT_REQUEST = 'DELETE_VIDEO_COMMENT_REQUEST';
export const DELETE_VIDEO_COMMENT_SUCCESS = 'DELETE_VIDEO_COMMENT_SUCCESS';
export const DELETE_VIDEO_COMMENT_FAILURE = 'DELETE_VIDEO_COMMENT_FAILURE';

export const DELETE_VIDEO_COMMENT_REPLY_REQUEST =
  'DELETE_VIDEO_COMMENT_REPLY_REQUEST';
export const DELETE_VIDEO_COMMENT_REPLY_SUCCESS =
  'DELETE_VIDEO_COMMENT_REPLY_SUCCESS';
export const DELETE_VIDEO_COMMENT_REPLY_FAILURE =
  'DELETE_VIDEO_COMMENT_REPLY_FAILURE';

export const UPDATE_VIDEO_COMMENT_REQUEST = 'UPDATE_VIDEO_COMMENT_REQUEST';
export const UPDATE_VIDEO_COMMENT_SUCCESS = 'UPDATE_VIDEO_COMMENT_SUCCESS';
export const UPDATE_VIDEO_COMMENT_FAILURE = 'UPDATE_VIDEO_COMMENT_FAILURE';

export const UPDATE_VIDEO_COMMENT_REPLY_REQUEST =
  'UPDATE_VIDEO_COMMENT_REPLY_REQUEST';
export const UPDATE_VIDEO_COMMENT_REPLY_SUCCESS =
  'UPDATE_VIDEO_COMMENT_REPLY_SUCCESS';
export const UPDATE_VIDEO_COMMENT_REPLY_FAILURE =
  'UPDATE_VIDEO_COMMENT_REPLY_FAILURE';

export const INITIALIZE_MESSAGE_REPLIES_STORE =
  'INITIALIZE_MESSAGE_REPLIES_STORE';

export const MESSAGE_REPLY_REQUEST = 'MESSAGE_REPLY_REQUEST';
export const MESSAGE_REPLY_SUCCESS = 'MESSAGE_REPLY_SUCCESS';
export const MESSAGE_REPLY_FAILURE = 'MESSAGE_REPLY_FAILURE';

export const REPLY_TO_A_THREAD_REQUEST = 'REPLY_TO_A_THREAD_REQUEST';
export const REPLY_TO_A_THREAD_SUCCESS = 'REPLY_TO_A_THREAD_SUCCESS';
export const REPLY_TO_A_THREAD_FAILURE = 'REPLY_TO_A_THREAD_FAILURE';

export const RESET_SEARCH_REDUCER = 'RESET_SEARCH_REDUCER';

export const CP_SEARCH_REQUEST = 'CP_SEARCH_REQUEST';
export const CP_SEARCH_SUCCESS = 'CP_SEARCH_SUCCESS';
export const CP_SEARCH_FAILURE = 'CP_SEARCH_FAILURE';

export const LIKE_POST_SEARCH_RESULT_REQUEST =
  'LIKE_POST_SEARCH_RESULT_REQUEST';
export const LIKE_POST_SEARCH_RESULT_SUCCESS =
  'LIKE_POST_SEARCH_RESULT_SUCCESS';
export const LIKE_POST_SEARCH_RESULT_FAILURE =
  'LIKE_POST_SEARCH_RESULT_FAILURE';

export const UNLIKE_POST_SEARCH_RESULT_REQUEST =
  'UNLIKE_POST_SEARCH_RESULT_REQUEST';
export const UNLIKE_POST_SEARCH_RESULT_SUCCESS =
  'UNLIKE_POST_SEARCH_RESULT_SUCCESS';
export const UNLIKE_POST_SEARCH_RESULT_FAILURE =
  'UNLIKE_POST_SEARCH_RESULT_FAILURE';

export const GET_POST_SEARCH_RESULT_COMMENTS_REQUEST =
  'GET_POST_SEARCH_RESULT_COMMENTS_REQUEST';
export const GET_POST_SEARCH_RESULT_COMMENTS_SUCCESS =
  'GET_POST_SEARCH_RESULT_COMMENTS_SUCCESS';
export const GET_POST_SEARCH_RESULT_COMMENTS_FAILURE =
  'GET_POST_SEARCH_RESULT_COMMENTS_FAILURE';

export const SAVE_SEARCH_RESULT_POST_COMMENT_REQUEST =
  'SAVE_SEARCH_RESULT_POST_COMMENT_REQUEST';
export const SAVE_SEARCH_RESULT_POST_COMMENT_SUCCESS =
  'SAVE_SEARCH_RESULT_POST_COMMENT_SUCCESS';
export const SAVE_SEARCH_RESULT_POST_COMMENT_FAILURE =
  'SAVE_SEARCH_RESULT_POST_COMMENT_FAILURE';

export const DELETE_SEARCH_RESULT_POST_COMMENT_REQUEST =
  'DELETE_SEARCH_RESULT_POST_COMMENT_REQUEST';
export const DELETE_SEARCH_RESULT_POST_COMMENT_SUCCESS =
  'DELETE_SEARCH_RESULT_POST_COMMENT_SUCCESS';
export const DELETE_SEARCH_RESULT_POST_COMMENT_FAILURE =
  'DELETE_SEARCH_RESULT_POST_COMMENT_FAILURE';

export const LIKE_SEARCH_RESULT_POST_COMMENT_REQUEST =
  'LIKE_SEARCH_RESULT_POST_COMMENT_REQUEST';
export const LIKE_SEARCH_RESULT_POST_COMMENT_SUCCESS =
  'LIKE_SEARCH_RESULT_POST_COMMENT_SUCCESS';
export const LIKE_SEARCH_RESULT_POST_COMMENT_FAILURE =
  'LIKE_SEARCH_RESULT_POST_COMMENT_FAILURE';

export const UNLIKE_SEARCH_RESULT_POST_COMMENT_REQUEST =
  'UNLIKE_SEARCH_RESULT_POST_COMMENT_REQUEST';
export const UNLIKE_SEARCH_RESULT_POST_COMMENT_SUCCESS =
  'UNLIKE_SEARCH_RESULT_POST_COMMENT_SUCCESS';
export const UNLIKE_SEARCH_RESULT_POST_COMMENT_FAILURE =
  'UNLIKE_SEARCH_RESULT_POST_COMMENT_FAILURE';

export const SHARE_RESULT_ITEM_POST = 'SHARE_RESULT_ITEM_POST';
export const RESET_SHARE_RESULT_ITEM_POST = 'RESET_SHARE_RESULT_ITEM_POST';
export const UPDATE_SEARCH_QUERY = 'UPDATE_SEARCH_QUERY';

export const FOLLOW_SEARCH_RESULT_PEOPLE_REQUEST =
  'FOLLOW_SEARCH_RESULT_PEOPLE_REQUEST';
export const FOLLOW_SEARCH_RESULT_PEOPLE_SUCCESS =
  'FOLLOW_SEARCH_RESULT_PEOPLE_SUCCESS';
export const FOLLOW_SEARCH_RESULT_PEOPLE_FAILURE =
  'FOLLOW_SEARCH_RESULT_PEOPLE_FAILURE';

export const UNFOLLOW_SEARCH_RESULT_PEOPLE_REQUEST =
  'UNFOLLOW_SEARCH_RESULT_PEOPLE_REQUEST';
export const UNFOLLOW_SEARCH_RESULT_PEOPLE_SUCCESS =
  'UNFOLLOW_SEARCH_RESULT_PEOPLE_SUCCESS';
export const UNFOLLOW_SEARCH_RESULT_PEOPLE_FAILURE =
  'UNFOLLOW_SEARCH_RESULT_PEOPLE_FAILURE';

export const DECREMENT_UNREAD_MESSAGES_COUNT =
  'DECREMENT_UNREAD_MESSAGES_COUNT';
export const SET_A_THREAD_AS_READ = 'SET_A_THREAD_AS_READ';

export const GET_UNIT_THREAD_REQUEST = 'GET_UNIT_THREAD_REQUEST';
export const GET_UNIT_THREAD_SUCCESS = 'GET_UNIT_THREAD_SUCCESS';
export const GET_UNIT_THREAD_FAILURE = 'GET_UNIT_THREAD_FAILURE';

export const LIKE_CP_PORTFOLIO_SEARCH_RESULT_REQUEST =
  'LIKE_CP_PORTFOLIO_SEARCH_RESULT_REQUEST';
export const LIKE_CP_PORTFOLIO_SEARCH_RESULT_SUCCESS =
  'LIKE_CP_PORTFOLIO_SEARCH_RESULT_SUCCESS';
export const LIKE_CP_PORTFOLIO_SEARCH_RESULT_FAILURE =
  'LIKE_CP_PORTFOLIO_SEARCH_RESULT_FAILURE';

export const UNLIKE_CP_PORTFOLIO_SEARCH_RESULT_REQUEST =
  'UNLIKE_CP_PORTFOLIO_SEARCH_RESULT_REQUEST';
export const UNLIKE_CP_PORTFOLIO_SEARCH_RESULT_SUCCESS =
  'UNLIKE_CP_PORTFOLIO_SEARCH_RESULT_SUCCESS';
export const UNLIKE_CP_PORTFOLIO_SEARCH_RESULT_FAILURE =
  'UNLIKE_CP_PORTFOLIO_SEARCH_RESULT_FAILURE';

export const SHARE_REFLECTIONS_REQUEST = 'SHARE_REFLECTIONS_REQUEST';
export const SHARE_REFLECTIONS_SUCCESS = 'SHARE_REFLECTIONS_SUCCESS';
export const SHARE_REFLECTIONS_FAILURE = 'SHARE_REFLECTIONS_FAILURE';

export const DOWNLOAD_REFLECTIONS_REQUEST = 'DOWNLOAD_REFLECTIONS_REQUEST';
export const DOWNLOAD_REFLECTIONS_SUCCESS = 'DOWNLOAD_REFLECTIONS_SUCCESS';
export const DOWNLOAD_REFLECTIONS_FAILURE = 'DOWNLOAD_REFLECTIONS_FAILURE';

export const BUY_SUBSCRIPTION_REQUEST = 'BUY_SUBSCRIPTION_REQUEST';
export const BUY_SUBSCRIPTION_SUCCESS = 'BUY_SUBSCRIPTION_SUCCESS';
export const BUY_SUBSCRIPTION_FAILURE = 'BUY_SUBSCRIPTION_FAILURE';

export const UPDATE_SEARCH_PARAMS = 'UPDATE_SEARCH_PARAMS';
export const CLEAR_ADVANCED_SEARCH_PARAMS = 'CLEAR_ADVANCED_SEARCH_PARAMS';

export const GET_EDUCATORS_REQUEST = 'GET_EDUCATORS_REQUEST';
export const GET_EDUCATORS_SUCCESS = 'GET_EDUCATORS_SUCCESS';
export const GET_EDUCATORS_FAILURE = 'GET_EDUCATORS_FAILURE';

export const GET_INDUSTRY_REPS_REQUEST = 'GET_INDUSTRY_REPS_REQUEST';
export const GET_INDUSTRY_REPS_SUCCESS = 'GET_INDUSTRY_REPS_SUCCESS';
export const GET_INDUSTRY_REPS_FAILURE = 'GET_INDUSTRY_REPS_FAILURE';

export const PEERS_REQUEST = 'PEERS_REQUEST';
export const PEERS_SUCCESS = 'PEERS_SUCCESS';
export const PEERS_FAILURE = 'PEERS_FAILURE';

export const GET_PEERS_REQUEST = 'GET_PEERS_REQUEST';
export const GET_PEERS_SUCCESS = 'GET_PEERS_SUCCESS';
export const GET_PEERS_FAILURE = 'GET_PEERS_FAILURE';

export const GET_FOLLOWERS_REQUEST = 'GET_FOLLOWERS_REQUEST';
export const GET_FOLLOWERS_SUCCESS = 'GET_FOLLOWERS_SUCCESS';
export const GET_FOLLOWERS_FAILURE = 'GET_FOLLOWERS_FAILURE';

export const REQUEST_FEEDBACK_REQUEST = 'REQUEST_FEEDBACK_REQUEST';
export const REQUEST_FEEDBACK_SUCCESS = 'REQUEST_FEEDBACK_SUCCESS';
export const REQUEST_FEEDBACK_FAILURE = 'REQUEST_FEEDBACK_FAILURE';

export const SKILL_CATEGORIES_REQUEST = 'SKILL_CATEGORIES_REQUEST';
export const SKILL_CATEGORIES_SUCCESS = 'SKILL_CATEGORIES_SUCCESS';
export const SKILL_CATEGORIES_FAILURE = 'SKILL_CATEGORIES_FAILURE';

export const SKILL_SUB_CATEGORIES_REQUEST = 'SKILL_SUB_CATEGORIES_REQUEST';
export const SKILL_SUB_CATEGORIES_SUCCESS = 'SKILL_SUB_CATEGORIES_SUCCESS';
export const SKILL_SUB_CATEGORIES_FAILURE = 'SKILL_SUB_CATEGORIES_FAILURE';

export const GET_MYSKILLS_REQUEST = 'GET_MYSKILLS_REQUEST';
export const GET_MYSKILLS_SUCCESS = 'GET_MYSKILLS_SUCCESS';
export const GET_MYSKILLS_FAILURE = 'GET_MYSKILLS_FAILURE';
export const CLEAR_MYSKILLS_SUCCESS = 'CLEAR_MYSKILLS_SUCCESS';

export const GET_MYSKILLSPAGE_REQUEST = 'GET_MYSKILLSPAGE_REQUEST';
export const GET_MYSKILLSPAGE_SUCCESS = 'GET_MYSKILLSPAGE_SUCCESS';
export const GET_MYSKILLSPAGE_FAILURE = 'GET_MYSKILLSPAGE_FAILURE';

export const GET_MYBADGES_REQUEST = 'GET_MYBADGES_REQUEST';
export const GET_MYBADGES_SUCCESS = 'GET_MYBADGES_SUCCESS';
export const GET_MYBADGES_FAILURE = 'GET_MYBADGES_FAILURE';
export const CLEAR_MYBADGES_SUCCESS = 'CLEAR_MYBADGES_SUCCESS';

export const GET_RATINGS_REQUEST = 'GET_RATINGS_REQUEST';
export const GET_RATINGS_SUCCESS = 'GET_RATINGS_SUCCESS';
export const GET_RATINGS_FAILURE = 'GET_RATINGS_FAILURE';

export const GET_REVIEWS_BREAKUP_REQUEST = 'GET_REVIEWS_BREAKUP_REQUEST';
export const GET_REVIEWS_BREAKUP_SUCCESS = 'GET_REVIEWS_BREAKUP_SUCCESS';
export const GET_REVIEWS_BREAKUP_FAILURE = 'GET_REVIEWS_BREAKUP_FAILURE';

export const GET_DEFAULT_SKILLS_REQUEST = 'GET_DEFAULT_SKILLS_REQUEST';
export const GET_DEFAULT_SKILLS_SUCCESS = 'GET_DEFAULT_SKILLS_SUCCESS';
export const GET_DEFAULT_SKILLS_FAILURE = 'GET_DEFAULT_SKILLS_FAILURE';

export const RESET_DEFAULT_SKILLS = 'RESET_DEFAULT_SKILLS';

export const LINKEDIN_LOGIN_REQUEST = 'LINKEDIN_LOGIN_REQUEST';
export const LINKEDIN_LOGIN_SUCCESS = 'LINKEDIN_LOGIN_SUCCESS';
export const LINKEDIN_LOGIN_FAILURE = 'LINKEDIN_LOGIN_FAILURE';

export const CREATE_PASSWORD_IR_REQUEST = 'CREATE_PASSWORD_IR_REQUEST';
export const CREATE_PASSWORD_IR_SUCCESS = 'CREATE_PASSWORD_IR_SUCCESS';
export const CREATE_PASSWORD_IR_FAILURE = 'CREATE_PASSWORD_IR_FAILURE';

export const CREATE_PASSWORD_PEER_REQUEST = 'CREATE_PASSWORD_PEER_REQUEST';
export const CREATE_PASSWORD_PEER_SUCCESS = 'CREATE_PASSWORD_PEER_SUCCESS';
export const CREATE_PASSWORD_PEER_FAILURE = 'CREATE_PASSWORD_PEER_FAILURE';

export const RESEND_FEEDBACK_REQUEST = 'RESEND_FEEDBACK_REQUEST';
export const RESEND_FEEDBACK_SUCCESS = 'RESEND_FEEDBACK_SUCCESS';
export const RESEND_FEEDBACK_FAILURE = 'RESEND_FEEDBACK_FAILURE';

export const EDIT_BADGE_VISIBILITY_REQUEST = 'EDIT_BADGE_VISIBILITY_REQUEST';
export const EDIT_BADGE_VISIBILITY_SUCCESS = 'EDIT_BADGE_VISIBILITY_SUCCESS';
export const EDIT_BADGE_VISIBILITY_FAILURE = 'EDIT_BADGE_VISIBILITY_FAILURE';

export const EDIT_SKILL_VISIBILITY_REQUEST = 'EDIT_SKILL_VISIBILITY_REQUEST';
export const EDIT_SKILL_VISIBILITY_SUCCESS = 'EDIT_SKILL_VISIBILITY_SUCCESS';
export const EDIT_SKILL_VISIBILITY_FAILURE = 'EDIT_SKILL_VISIBILITY_FAILURE';

export const IGNORE_FEEDBACK_REQUEST = 'IGNORE_FEEDBACK_REQUEST';
export const IGNORE_FEEDBACK_SUCCESS = 'IGNORE_FEEDBACK_SUCCESS';
export const IGNORE_FEEDBACK_FAILURE = 'IGNORE_FEEDBACK_FAILURE';

export const REMOVE_CANCELLED_FEEDBACK_REQUEST =
  'REMOVE_CANCELLED_FEEDBACK_REQUEST';
export const REMOVE_CANCELLED_FEEDBACK_SUCCESS =
  'REMOVE_CANCELLED_FEEDBACK_SUCCESS';
export const REMOVE_CANCELLED_FEEDBACK_FAILURE =
  'REMOVE_CANCELLED_FEEDBACK_FAILURE';

export const GET_ALL_TESTIMONIALS_REQUEST = 'GET_ALL_TESTIMONIALS_REQUEST';
export const GET_ALL_TESTIMONIALS_SUCCESS = 'GET_ALL_TESTIMONIALS_SUCCESS';
export const GET_ALL_TESTIMONIALS_FAILURE = 'GET_ALL_TESTIMONIALS_FAILURE';

export const REQUEST_REVISE_TESTIMONIALS_REQUEST = 'REQUEST_REVISE_TESTIMONIALS_REQUEST';
export const REQUEST_REVISE_TESTIMONIALS_SUCCESS = 'REQUEST_REVISE_TESTIMONIALS_SUCCESS';
export const REQUEST_REVISE_TESTIMONIALS_FAILURE = 'REQUEST_REVISE_TESTIMONIALS_FAILURE';

export const GET_TESTIMONIAL_REQUEST = 'GET_TESTIMONIAL_REQUEST';
export const GET_TESTIMONIAL_SUCCESS = 'GET_TESTIMONIAL_SUCCESS';
export const GET_TESTIMONIAL_FAILURE = 'GET_TESTIMONIAL_FAILURE';

export const SET_REVISE_TESTIMONIAL_REQUEST = 'SET_REVISE_TESTIMONIAL_REQUEST';
export const SET_REVISE_TESTIMONIAL_SUCCESS = 'SET_REVISE_TESTIMONIAL_REQUEST';
export const SET_REVISE_TESTIMONIAL_FAILURE = 'SET_REVISE_TESTIMONIAL_REQUEST';

export const EDIT_TESTIMONIAL_VISIBILITY_REQUEST = 'EDIT_TESTIMONIAL_VISIBILITY_REQUEST';
export const EDIT_TESTIMONIAL_VISIBILITY_SUCCESS = 'EDIT_TESTIMONIAL_VISIBILITY_REQUEST';
export const EDIT_TESTIMONIAL_VISIBILITY_FAILURE = 'EDIT_TESTIMONIAL_VISIBILITY_REQUEST';

export const GET_CLUSTERS_REQUEST = 'GET_CLUSTERS_REQUEST';
export const GET_CLUSTERS_SUCCESS = 'GET_CLUSTERS_SUCCESS';
export const GET_CLUSTERS_FAILURE = 'GET_CLUSTERS_FAILURE';

export const RESET_INDUSTRIES = 'RESET_INDUSTRIES';
export const GET_INDUSTRIES_REQUEST = 'GET_INDUSTRIES_REQUEST';
export const GET_INDUSTRIES_SUCCESS = 'GET_INDUSTRIES_SUCCESS';
export const GET_INDUSTRIES_FAILURE = 'GET_INDUSTRIES_FAILURE';

export const RESET_OCCUPATIONS = 'RESET_OCCUPATIONS';
export const GET_OCCUPATIONS_REQUEST = 'GET_OCCUPATIONS_REQUEST';
export const GET_OCCUPATIONS_SUCCESS = 'GET_OCCUPATIONS_SUCCESS';
export const GET_OCCUPATIONS_FAILURE = 'GET_OCCUPATIONS_FAILURE';

export const GET_CAREER_INTEREST_REQUEST = 'GET_CAREER_INTEREST_REQUEST';
export const GET_CAREER_INTEREST_SUCCESS = 'GET_CAREER_INTEREST_SUCCESS';
export const GET_CAREER_INTEREST_FAILURE = 'GET_CAREER_INTEREST_FAILURE';

export const ADD_IR_REQUEST = 'ADD_IR_REQUEST';
export const ADD_IR_SUCCESS = 'ADD_IR_SUCCESS';
export const ADD_IR_FAILURE = 'ADD_IR_FAILURE';

export const GET_IR_DATA_BY_SEARCH_REQUEST = 'GET_IR_DATA_BY_SEARCH_REQUEST';
export const GET_IR_DATA_BY_SEARCH_SUCCESS = 'GET_IR_DATA_BY_SEARCH_SUCCESS';
export const GET_IR_DATA_BY_SEARCH_FAILURE = 'GET_IR_DATA_BY_SEARCH_FAILURE';

export const GET_IR_LIST_REQUEST = 'GET_IR_LIST_REQUEST';
export const GET_IR_LIST_SUCCESS = 'GET_IR_LIST_SUCCESS';
export const GET_IR_LIST_FAILURE = 'GET_IR_LIST_FAILURE';
export const CLEAR_IR_LIST_SUCCESS = 'CLEAR_IR_LIST_SUCCESS';

export const GET_MANAGE_IR_LIST_REQUEST = 'GET_MANAGE_IR_LIST_REQUEST';
export const GET_MANAGE_IR_LIST_SUCCESS = 'GET_MANAGE_IR_LIST_SUCCESS';
export const GET_MANAGE_IR_LIST_FAILURE = 'GET_MANAGE_IR_LIST_FAILURE';

export const ADD_EXISTING_IR_REQUEST = 'ADD_EXISTING_IR_REQUEST';
export const ADD_EXISTING_IR_SUCCESS = 'ADD_EXISTING_IR_SUCCESS';
export const ADD_EXISTING_IR_FAILURE = 'ADD_EXISTING_IR_FAILURE';

export const REMOVE_EXISTING_IR_REQUEST = 'REMOVE_EXISTING_IR_REQUEST';
export const REMOVE_EXISTING_IR_SUCCESS = 'REMOVE_EXISTING_IR_SUCCESS';
export const REMOVE_EXISTING_IR_FAILURE = 'REMOVE_EXISTING_IR_FAILURE';

export const GET_IR_RANK_REQUEST = 'GET_IR_RANK_REQUEST';
export const GET_IR_RANK_SUCCESS = 'GET_IR_RANK_SUCCESS';
export const GET_IR_RANK_FAILURE = 'GET_IR_RANK_FAILURE';

export const GET_PRAISE_REQUEST = 'GET_PRAISE_REQUEST';
export const GET_PRAISE_SUCCESS = 'GET_PRAISE_SUCCESS';
export const GET_PRAISE_FAILURE = 'GET_PRAISE_FAILURE';

export const CLEAR_MYFEEDBACK_SUCCESS = 'CLEAR_MYFEEDBACK_SUCCESS';

export const GET_GIVEN_FEEDBACK_LIST_REQUEST =
  'GET_GIVEN_FEEDBACK_LIST_REQUEST';
export const GET_GIVEN_FEEDBACK_LIST_SUCCESS =
  'GET_GIVEN_FEEDBACK_LIST_SUCCESS';
export const GET_GIVEN_FEEDBACK_LIST_FAILURE =
  'GET_GIVEN_FEEDBACK_LIST_FAILURE';

export const GET_GIVEN_COMPLETED_FEEDBACK_LIST_REQUEST =
  'GET_GIVEN_COMPLETED_FEEDBACK_LIST_REQUEST';
export const GET_GIVEN_COMPLETED_FEEDBACK_LIST_SUCCESS =
  'GET_GIVEN_COMPLETED_FEEDBACK_LIST_SUCCESS';
export const GET_GIVEN_COMPLETED_FEEDBACK_LIST_FAILURE =
  'GET_GIVEN_COMPLETED_FEEDBACK_LIST_FAILURE';

export const GET_RECEIVED_FEEDBACK_LIST_REQUEST =
  'GET_RECEIVED_FEEDBACK_LIST_REQUEST';
export const GET_RECEIVED_FEEDBACK_LIST_SUCCESS =
  'GET_RECEIVED_FEEDBACK_LIST_SUCCESS';
export const GET_RECEIVED_FEEDBACK_LIST_FAILURE =
  'GET_RECEIVED_FEEDBACK_LIST_FAILURE';

export const GET_REQUESTED_FEEDBACK_LIST_REQUEST =
  'GET_REQUESTED_FEEDBACK_LIST_REQUEST';
export const GET_REQUESTED_FEEDBACK_LIST_SUCCESS =
  'GET_REQUESTED_FEEDBACK_LIST_SUCCESS';
export const GET_REQUESTED_FEEDBACK_LIST_FAILURE =
  'GET_REQUESTED_FEEDBACK_LIST_FAILURE';

export const GET_FEEDBACK_QUESTIONS_REQUEST = 'GET_FEEDBACK_QUESTIONS_REQUEST';
export const GET_FEEDBACK_QUESTIONS_SUCCESS = 'GET_FEEDBACK_QUESTIONS_SUCCESS';
export const GET_FEEDBACK_QUESTIONS_FAILURE = 'GET_FEEDBACK_QUESTIONS_FAILURE';

export const GET_FEEDBACK_DATA_REQUEST = 'GET_FEEDBACK_DATA_REQUEST';
export const GET_FEEDBACK_DATA_SUCCESS = 'GET_FEEDBACK_DATA_SUCCESS';
export const GET_FEEDBACK_DATA_FAILURE = 'GET_FEEDBACK_DATA_FAILURE';
export const RESET_FEEDBACK_DATA = 'RESET_FEEDBACK_DATA';
export const RESET_GIVE_FEEDBACK = 'RESET_GIVE_FEEDBACK';

export const POST_FEEDBACK_DATA_REQUEST = 'POST_FEEDBACK_DATA_REQUEST';
export const POST_FEEDBACK_DATA_SUCCESS = 'POST_FEEDBACK_DATA_SUCCESS';
export const POST_FEEDBACK_DATA_FAILURE = 'POST_FEEDBACK_DATA_FAILURE';

export const EDIT_FEEDBACK_DATA_REQUEST = 'EDIT_FEEDBACK_DATA_REQUEST';
export const EDIT_FEEDBACK_DATA_SUCCESS = 'EDIT_FEEDBACK_DATA_SUCCESS';
export const EDIT_FEEDBACK_DATA_FAILURE = 'EDIT_FEEDBACK_DATA_FAILURE';

export const GET_FEEDBACK_USER_DATA_REQUEST = 'GET_FEEDBACK_USER_DATA_REQUEST';
export const GET_FEEDBACK_USER_DATA_SUCCESS = 'GET_FEEDBACK_USER_DATA_SUCCESS';
export const GET_FEEDBACK_USER_DATA_FAILURE = 'GET_FEEDBACK_USER_DATA_FAILURE';

export const GET_RELATION_LIST_REQUEST = 'GET_RELATION_LIST_REQUEST';
export const GET_RELATION_LIST_SUCCESS = 'GET_RELATION_LIST_SUCCESS';
export const GET_RELATION_LIST_FAILURE = 'GET_RELATION_LIST_FAILURE';

export const GET_INVITE_TOKEN_REQUEST = 'GET_INVITE_TOKEN_REQUEST';
export const GET_INVITE_TOKEN_SUCCESS = 'GET_INVITE_TOKEN_SUCCESS';
export const GET_INVITE_TOKEN_FAILURE = 'GET_INVITE_TOKEN_FAILURE';

export const SEND_FEEDBACK_REQUEST_REQUEST = 'SEND_FEEDBACK_REQUEST_REQUEST';
export const SEND_FEEDBACK_REQUEST_SUCCESS = 'SEND_FEEDBACK_REQUEST_SUCCESS';
export const SEND_FEEDBACK_REQUEST_FAILURE = 'SEND_FEEDBACK_REQUEST_FAILURE';

export const GET_GIVEN_FEEDBACK_LIST_REQUEST_BY_RESOURCE =
  'GET_GIVEN_FEEDBACK_LIST_REQUEST_BY_RESOURCE';
export const GET_GIVEN_FEEDBACK_LIST_SUCCESS_BY_RESOURCE =
  'GET_GIVEN_FEEDBACK_LIST_SUCCESS_BY_RESOURCE';
export const GET_GIVEN_FEEDBACK_LIST_FAILURE_BY_RESOURCE =
  'GET_GIVEN_FEEDBACK_LIST_FAILURE_BY_RESOURCE';

export const SETTINGS_DATE_FORMAT = 'YYYY-MM-DD';
export const FILTER_OPTIONS_DATE_FORMAT = 'YYYYMMDD';
export const TABLE_DATE_FORMAT = 'MM-DD-YYYY';
export const FEEDBACK_DATE_FORMAT = 'MM/dd/yyyy';
export const FEEDBACK_REQUESTED_DATE_FORMAT = 'MM/DD/yyyy';
export const MONTH_YEAR_FORMAT = 'MM/YYYY';

export const CAREERPREPPED_LIMITED = 'CareerPrepped is in limited release.';

export const PAYMENT_METHOD = 'Payment Method';
export const CARD_NUMBER = 'Card Number';
export const VALID_THROUGH = 'Valid Thru';
export const CVV = 'CVV';
export const ZIPCODE = 'Zipcode';
export const PAY_WITH = 'Pay with ';

export const CP_LINK = 'www.careerprepped.com';
export const TERMS_LINK = 'terms@maxknowledge.com';


export const GET_FEEDBACK_PRAISE_OPTIONS_REQUEST =
  'GET_FEEDBACK_PRAISE_OPTIONS_REQUEST';
export const GET_FEEDBACK_PRAISE_OPTIONS_SUCCESS =
  'GET_FEEDBACK_PRAISE_OPTIONS_SUCCESS';
export const GET_FEEDBACK_PRAISE_OPTIONS_FAILURE =
  'GET_FEEDBACK_PRAISE_OPTIONS_FAILURE';

export const SUBMIT_FEEDBACK_PRAISE_REQUEST =
  'SUBMIT_FEEDBACK_PRAISE_REQUEST';
export const SUBMIT_FEEDBACK_PRAISE_SUCCESS =
  'SUBMIT_FEEDBACK_PRAISE_SUCCESS';
export const SUBMIT_FEEDBACK_PRAISE_FAILURE =
  'SUBMIT_FEEDBACK_PRAISE_FAILURE';

export const GET_MY_CP_STATUS_REQUEST =
  'GET_MY_CP_STATUS_REQUEST';
export const GET_MY_CP_STATUS_SUCCESS =
  'GET_MY_CP_STATUS_SUCCESS';
export const GET_MY_CP_STATUS_FAILURE =
  'GET_MY_CP_STATUS_FAILURE';

export const UPDATE_MY_CP_STATUS_REQUEST =
  'UPDATE_MY_CP_STATUS_REQUEST';
export const UPDATE_MY_CP_STATUS_SUCCESS =
  'UPDATE_MY_CP_STATUS_SUCCESS';
export const UPDATE_MY_CP_STATUS_FAILURE =
  'UPDATE_MY_CP_STATUS_FAILURE';

export const GET_COMPANY_LIST_REQUEST = 'GET_COMPANY_LIST_REQUEST';
export const GET_COMPANY_LIST_SUCCESS = 'GET_COMPANY_LIST_SUCCESS';
export const GET_COMPANY_LIST_FAILURE = 'GET_COMPANY_LIST_FAILURE';

export const GET_COUNTRIES_LIST_REQUEST = 'GET_COUNTRIES_LIST_REQUEST';
export const GET_COUNTRIES_LIST_SUCCESS = 'GET_COUNTRIES_LIST_SUCCESS';
export const GET_COUNTRIES_LIST_FAILURE = 'GET_COUNTRIES_LIST_FAILURE';

export const COUNTRIES_REQUEST = 'COUNTRIES_REQUEST';
export const COUNTRIES_SUCCESS = 'COUNTRIES_SUCCESS';
export const COUNTRIES_FAILURE = 'COUNTRIES_FAILURE';

export const STATES_REQUEST = 'STATES_REQUEST';
export const STATES_SUCCESS = 'STATES_SUCCESS';
export const STATES_FAILURE = 'STATES_FAILURE';

export const CITIES_REQUEST = 'CITIES_REQUEST';
export const CITIES_SUCCESS = 'CITIES_SUCCESS';
export const CITIES_FAILURE = 'CITIES_FAILURE';

export const CLUSTERS_REQUEST = 'CLUSTERS_REQUEST';
export const CLUSTERS_SUCCESS = 'CLUSTERS_SUCCESS';
export const CLUSTERS_FAILURE = 'CLUSTERS_FAILURE';

export const INDUSTRIES_REQUEST = 'INDUSTRIES_REQUEST';
export const INDUSTRIES_SUCCESS = 'INDUSTRIES_SUCCESS';
export const INDUSTRIES_FAILURE = 'INDUSTRIES_FAILURE';

export const OCCUPATIONS_REQUEST = 'OCCUPATIONS_REQUEST';
export const OCCUPATIONS_SUCCESS = 'OCCUPATIONS_SUCCESS';
export const OCCUPATIONS_FAILURE = 'OCCUPATIONS_FAILURE';
