import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { checkHttp } from '../../../utils';
import Parser from 'html-react-parser';

const styles = {
  userNameEditIcon: {
    cursor: 'pointer',
    marginLeft: 10,
  },

  editActions: {
    position: 'relative',
  }
};

const ProjectSectionCard = ({
  projectDetails,
  onEditProject,
  onRemoveProject
}) => {
  const isStartDateObject = typeof projectDetails.startdate === 'object';
  const startDateString = isStartDateObject ? projectDetails.startdate.date :
    projectDetails.startdate === '' ? undefined : projectDetails.startdate;

  const isEndDateObject = typeof projectDetails.enddate === 'object';
  const endDateString = isEndDateObject ? projectDetails.enddate.date :
    projectDetails.enddate === '' ? undefined : projectDetails.enddate;

  const startDate = moment(startDateString).format('MM/YYYY');
  let endDate;

  if (projectDetails.noend) {
    endDate = 'Present';
  } else {
    endDate = moment(endDateString).format('MM/YYYY');
  }

  const urlAvailable = projectDetails.url !== '';
  const urlString = checkHttp(projectDetails.url) ?
    projectDetails.url : `//${projectDetails.url}`;
  return (
    <div className="resume-section section-item">
      <span style={styles.editActions} className="float-right">
        <i
          onClick={onEditProject}
          id={projectDetails.id}
          style={styles.userNameEditIcon}
          className="fa fa-pencil"/>

        <i
          onClick={onRemoveProject}
          id={projectDetails.id}
          style={styles.userNameEditIcon}
          className="fa fa-close"/>
      </span>
      {!urlAvailable ?
        <span>{projectDetails.title}</span> :
        <span><a href={urlString} target="_blank" rel="noopener noreferrer">{projectDetails.title}</a></span>}

      <span style={{marginLeft: 10}}>({startDate} - {endDate})</span>
      <div>
        {Parser(projectDetails.description)}
      </div>
    </div>
  );
};

ProjectSectionCard.propTypes = {
  projectDetails: PropTypes.object.isRequired,
  onEditProject: PropTypes.func.isRequired,
  onRemoveProject: PropTypes.func.isRequired
};

export default ProjectSectionCard;
