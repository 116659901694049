import React, { Component } from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

class Privacy extends Component {
  render() {
    return (
      <div className="interior info terms-page">
        <Helmet title="Privacy"/>
        <div className="container">
          <div className="row">
            <div
              style={{paddingTop: 50}}
              className="content">
              <div className="info-title">
                <h2>Privacy Policy</h2>
              </div>
              <p>
                CareerPrepped is a service provided by MaxKnowledge, Inc. (“MaxKnowledge”, “we”, “us”). We care deeply about your privacy and security. This Privacy Policy (“Policy”) is designed to inform you about how we collect, use, and share your personal information.
              </p>
              <p>
                When you access or use our websites (“Site” or “Services”), you acknowledge that you have read this Policy and understand its contents. Your use of our Services and any dispute over privacy is subject to this Policy and our <Link to="/terms">Terms of Service</Link>.
              </p>
              <p>
                If you have any questions, concerns, or requests related to this Policy, please contact us by email at: <a href={'mailto:privacy@maxknowledge.com'}>privacy@maxknowledge.com</a>.
              </p>

              <hr/>
              <h6 className="font-weight-bold">Information Control and Display</h6>
              <p>
                We are the data controller of your information. If you obtain access to Services through sponsors such as your institution or employer, the third party sponsoring your participation may provide us information and therefore the third-party sponsor may control your information.
              </p>

              <p>
                Our Services allow you to make posts as well as share your details and information with others. You are in full control of your privacy settings for any information or materials you choose to publish on our Site.
              </p>

              <hr/>

              <h6 className="font-weight-bold">Use of Cookies</h6>
              <p>
                We use cookies on our Site. A cookie is a small file of letters and numbers that we place on your computer or mobile device to allow us to distinguish you from other users of our Site, which helps us to provide you with a better and more personalized experience when you browse our Site and use our Services.
              </p>
              <p>
                We use session cookies to enhance your navigation throughout our Site. We use Google Analytics and Microsoft Clarity cookies to help us measure and improve the performance of our Services. From time to time, we may also use retargeting cookies from our advertising partners to display our ads to your device across different websites, but only if you’ve visited our purchase forms. Our advertising partners may also show you other relevant ads.
              </p>
              <p>You can disable cookies through your browser's settings.</p>

              <hr/>
              <h6 className="font-weight-bold">How We Collect Personal Information</h6>
              <p>
                We can get your personal information when you:
              </p>
              <ul>
                <li>Buy or register for our Services</li>
                <li>Subscribe to newsletters, alerts or other services from us</li>
                <li>Ask us for more information about Services, or contact us with a question or complaint</li>
                <li>Visit or browse our website</li>
              </ul>
              <p>
                We may also collect information about you from other organizations such as business directories, list of attendees at a trade show, trade associations, our trade partners, or your sponsor.
              </p>

              <hr/>
              <h6 className="font-weight-bold">The Personal Information We Collect</h6>
              <p>The information we collect about you may include the following:</p>
              <ul>
                <li>Contact information, such as name, email address, mailing address, and phone number;</li>
                <li>Payment information, including payment card numbers, billing address, and bank account information;</li>
                <li>Your purchase history on our Site;</li>
                <li>Account log-in credentials;</li>
                <li>Your interests in our products;</li>
                <li>Your skill development activities on our Site;</li>
                <li>Your website browsing information about how you use our Site;</li>
                <li>Information about your computer hardware and software including your IP address, browser type, and domain names.</li>
              </ul>

              <hr/>
              <h6 className="font-weight-bold">How We May Use Personal Information</h6>
              <p>We may use your personal information in the following ways:</p>
              <ul>
                <li>To respond to your inquiries and fulfill your requests, such as to send you newsletters and e-mails;</li>
                <li>To send you administrative information, for example, information regarding Services and changes to our terms of service and policies;</li>
                <li>To complete and fulfill your purchase, for example, to process your payments, communicate with you regarding your purchase, and provide you with related customer service;</li>
                <li>To send you marketing communications that we believe may be of interest to you;</li>
                <li>To personalize your experience on our Services by presenting products and offers tailored to you;</li>
                <li>For our business purposes, such as data analysis, audits, developing new products, enhancing, improving, or modifying our Services, identifying usage trends, determining the effectiveness of our promotional campaigns, and operating and expanding our business activities.</li>
              </ul>

              <hr/>
              <h6 className="font-weight-bold">How We Protect Personal Information</h6>
              <p>
                We consider the confidentiality and security of your information very seriously. MaxKnowledge takes technical, administrative, and physical measures to protect your information from disclosure to or access by third parties. Please note that we do not guarantee the security of information, as no method of Internet transmission or storage is completely secure.
              </p>

              <hr/>
              <h6 className="font-weight-bold">When We May Share Personal Information</h6>
              <p>
                We respect the importance of privacy. We do not sell your personal information, nor do we share it with unaffiliated third parties. We may share personal information with third parties in the following ways:
              </p>

              <ul>
                <li>With our service providers who provide services such as payment processing, email delivery, auditing and other similar services</li>
                <li>With a sponsor, if the sponsor provides you access to our Services</li>
                <li>With other third parties in the event of any reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of our business, assets, or stock</li>
                <li>To comply with a legal process (subpoena or court order)</li>
              </ul>

              <hr/>
              <h6 className="font-weight-bold">Retention of Personal Information</h6>
              <p>
                We keep your information for no longer than necessary for the purposes for which the information is processed. Please note that we may retain and store your information in our databases and systems even after your account is deactivated as long as we have a legitimate purpose to do so and in accordance with applicable law. The length of time we retain information depends on the purposes for which we collect and use it and/or as required to comply with applicable laws.
              </p>

              <hr/>
              <h6 className="font-weight-bold">Children's Privacy</h6>
              <p>
                MaxKnowledge is not for children under the age of 13. If we become aware that we have inadvertently collected “personal information” (as defined by the United States Children’s Online Privacy Protection Act) from children under the age of 13 without legally-valid parental consent, we will take reasonable steps to delete it as soon as possible. We also comply with other age restrictions and requirements in accordance with applicable local laws.
              </p>

              <hr/>
              <h6 className="font-weight-bold">California Privacy Rights</h6>
              <p>
                If you are a California resident who has provided personal information to us to obtain products or services for personal, family, or household use, you are entitled to request and obtain from us once a calendar year information about personal information we shared, if any, with other businesses for their own direct marketing uses. To send us a request, please email <a href={'mailto:privacy@maxknowledge.com'}>privacy@maxknowledge.com</a> with “California Shine the Light Request” in your subject line.
              </p>

              <hr/>
              <h6 className="font-weight-bold">Information Storage and Transfer</h6>
              <p>
                We store information about our visitors and users on servers located in the United States. By using our Services, you consent to the storage of your information inside the United States. If you are using our Services from outside the United States, please know the information you submit will be transferred to and stored in servers in the United States. The data protection and other laws of the United States might not be as comprehensive as those in your country. By submitting your data and/or using our Services, you acknowledge the transfer, storing, and processing of your information in and to the United States.
              </p>
              <p>
                In accordance with applicable law, we may process your data to, or share your data with, service providers and others in countries other than your country of residence, including the United States. Such third parties may be engaged in, among other things, the provision of Services to you, the processing of transactions, and/or providing support services. We will take appropriate measures to ensure that such third parties protect personal information in accordance with this Privacy Policy. By providing us with your information, you acknowledge any such transfer, storage, or use.
              </p>

              <hr/>
              <h6 className="font-weight-bold">Your Legal Rights</h6>
              <p>
                Your local laws, such as in the European Union, may permit you to request that we:
              </p>

              <ul>
                <li>provide access to and/or a copy of certain information we hold about you;</li>
                <li>prevent the processing of your information for direct-marketing purposes, including any direct marketing processing based on profiling;</li>
                <li>update information which is out of date or incorrect;</li>
                <li>delete certain information which we are holding about you;</li>
                <li>restrict the way that we process and disclose some of your information;</li>
                <li>transfer your information to a third-party provider of services;</li>
                <li>revoke your consent for the processing of your information.</li>
              </ul>

              <p>
                We will consider all requests and provide our response within the time period stated by applicable law. Please note, however, that certain information may be exempt from such requests in some circumstances, which may include processing your information for our legitimate interests or to comply with a legal obligation. To the extent that you make such a request, we require certain information to help verify your request and prevent fraudulent information and removal requests. Specifically, we ask that you provide the following pieces of information:
              </p>

              <ul>
                <li>Username and email address associated with your User Account;</li>
                <li>Information you want to view and/or request removed;</li>
                <li>Country of residence; and</li>
                <li>A statement under penalty of perjury that all information in your request is truthful and that this is your User Account or that you have the authorization to make the request on behalf of the owner of the User Account.</li>
              </ul>

              <p>Depending on the circumstances and the information you request, we may require additional methods of verification at our discretion.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Privacy;
