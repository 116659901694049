import PropTypes from 'prop-types';
import React from 'react';
import TextInput from '../common/TextInput';

const PhoneNumber = ({
  phoneNumber,
  changePhoneNumber,
  error,
  isUpdating,
  onKeyPress
}) => {
  return(
    <TextInput
      type="tel"
      className="form-control input-styles-owht"
      inputId="phoneNumber"
      placeholder="Enter a ten-digit phone number"
      name="phoneNumber"
      label="Phone Number:"
      value={phoneNumber}
      onChange={changePhoneNumber}
      error={error}
      disabled={isUpdating}
      onKeyPress={onKeyPress}/>
  );
};

PhoneNumber.propTypes = {
  phoneNumber: PropTypes.string,
  changePhoneNumber: PropTypes.func,
  error: PropTypes.string,
  isUpdating: PropTypes.bool,
  onKeyPress: PropTypes.func
};

export default PhoneNumber;
