import PropTypes from 'prop-types';
import React from 'react';
import WallShareButton from '../../containers/dashboard/WallShareButton';
import WallLikeButton from '../../containers/dashboard/WallLikeButton';
import WallCommentButton from '../../containers/dashboard/WallCommentButton';

const WallSocialActions = ({type, wallPost, comment, postIndex}) => {
  return (
    <div className="post-actions">
      <WallLikeButton
        wallPost={wallPost}
        comment={comment}
        postIndex={postIndex}/>
      {type !== 'comment' ? <WallCommentButton wallPost={wallPost}/> : ''}
      {type !== 'comment' ? <WallShareButton discussion={wallPost}/> : ''}
    </div>
  );
};

WallSocialActions.propTypes = {
  type: PropTypes.string.isRequired,
  wallPost: PropTypes.object,
  comment: PropTypes.object,
  postIndex: PropTypes.number,
};

export default WallSocialActions;
