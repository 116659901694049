import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

const sortOptions = [
  { name: 'Newest', value: 'newest' },
  { name: 'Title', value: 'title' }
];

const SortFile = ({
  activeSort,
  isRequestingSort,
  isDropdownOpen,
  toggleDropdown,
  onSortChange
}) => {
  const btnLabel = sortOptions.find(option => option.value === activeSort);

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="activity-filter-sort">
          <div className="btn-group">
            <Dropdown
              disabled={isRequestingSort}
              isOpen={isDropdownOpen}
              toggle={toggleDropdown}>
              <DropdownToggle className="btn-dropdown-toggle">
                {btnLabel.name}
              </DropdownToggle>
              <DropdownMenu onClick={toggleDropdown}>
                {sortOptions.map((item) => {
                  return (
                    <a
                      className={classNames(
                        'dropdown-item grey-dropdown-btn',
                        { 'active-item-filter': item.value === activeSort }
                      )}
                      key={item.value}
                      onClick={onSortChange}
                      id={item.value}>
                      {item.name}
                    </a>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

SortFile.propTypes = {
  activeSort: PropTypes.string.isRequired,
  isDropdownOpen: PropTypes.bool.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  isRequestingSort: PropTypes.bool.isRequired
};

export default SortFile;
