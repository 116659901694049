import PropTypes from 'prop-types';
import React from 'react';

const CreateResumeHeader = ({ 
  title, 
  onBack, 
  onSave,
  onTogglePreview, 
  onChangeTitle 
}) => {
  return (
    <div className="titleinput">
      <div className="input-group">
        <span className="input-group-btn">
          <button 
            onClick={onBack}
            className="btn btn-secondary mr-2 d-flex align-items-center" 
            type="button">
            <i className="fa fa-angle-left fa-w"/> <span className="ml-2 d-none d-md-block">Back</span>
          </button>
        </span>
        <input 
          type="text" 
          className="form-control" 
          value={title}
          placeholder="Enter a title for your targeted resume"
          onChange={onChangeTitle}/>
        <span className="input-group-btn">
          <button 
            className="btn btn-primary ml-2 d-flex align-items-center" 
            type="button"
            onClick={onSave}>
            <i className="fa fa-save fa-w"/><span className="ml-2 d-none d-md-block">Save</span>
          </button>
        </span>
        <span className="input-group-btn ml-2">
          <button 
            className="btn btn-tertiary d-flex align-items-center" 
            type="button"
            onClick={onTogglePreview}>
            <i className="fa fa-eye fa-w"/><span className="ml-2 d-none d-md-block">Preview Resumé</span>
          </button>
        </span>
      </div>
    </div>
  );
};

CreateResumeHeader.propTypes = {
  title: PropTypes.string,
  onBack: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onTogglePreview: PropTypes.func.isRequired,
  onChangeTitle: PropTypes.func.isRequired
};

export default CreateResumeHeader;
