import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

const UnsavedChangesModal = ({
  isOpen,
  onCancel,
  onConfirm,
  headerTitle,
  bodyText,
  cancelBtnText,
  confirmBtnText,
  isSaving
}) => {
  if (isSaving) {
    return (
      <Modal
        size="lg"
        isOpen={isOpen}
        backdrop="static"
        toggle={onCancel}>
        <ModalBody>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <span>
              <i className="fa fa-spinner fa-pulse fa-2x fa-fw" />
            </span>
            <span style={{ paddingLeft: 10}}>
              Saving Resume
            </span>
          </div>
        </ModalBody>
      </Modal>);
  }

  return (
    <Modal
      backdrop="static"
      size="lg"
      isOpen={isOpen}
      toggle={onCancel}>
      <ModalHeader
        toggle={onCancel}>
        {headerTitle && headerTitle === '' ? 'Discard your Changes?' : headerTitle}
      </ModalHeader>
      <ModalBody>
        {bodyText && bodyText === '' ? 'You have some unsaved changes. Are you sure you want to discard your changes?': bodyText}
      </ModalBody>
      <ModalFooter>
        <button
          onClick={onCancel}
          className="btn btn-secondary">
          {cancelBtnText && cancelBtnText === '' ? 'Cancel' : cancelBtnText}
        </button>
        <button
          onClick={onConfirm}
          className="btn btn-primary">
          {confirmBtnText && confirmBtnText === '' ? 'Discard Changes' : confirmBtnText}
        </button>
      </ModalFooter>
    </Modal>
  );
};

UnsavedChangesModal.propTypes = {
  isSaving: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  headerTitle: PropTypes.string,
  bodyText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  confirmBtnText: PropTypes.string
};

export default UnsavedChangesModal;
