import initialState from './initialState';

import {
  CITIES_REQUEST,
  CITIES_SUCCESS,
  CITIES_FAILURE
} from '../../constants';

export default function reducer(state = initialState.cities, action) {
  switch (action.type) {
  case CITIES_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case CITIES_SUCCESS:
    return Object.assign({}, state, {
      isRequesting: false,
      page: action.data.page,
      pageCount: action.data.page_count,
      data: action.data._embedded.tree
    });

  case CITIES_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false
    });

  default:
    return state;
  }
}
