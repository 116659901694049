import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getUsers } from '../../redux/actions/users';
import {
  resetGroupAdmins,
  setSelectedGroup,
  getGroupAdmins
} from '../../redux/actions/groups';

import { Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';

import { buildBreadcrumbFullPath } from '../../utils';

class AdminBreadcrumb extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    allGroups: PropTypes.array.isRequired,
    parentPage: PropTypes.string.isRequired,
    selectedGroup: PropTypes.object.isRequired,
    selectedGroupsList: PropTypes.array.isRequired,
    selectedInstitution: PropTypes.object.isRequired,
    selectedParentGroup: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    event.preventDefault();

    const { id: groupId } = event.target;
    const {
      actions,
      allGroups,
      parentPage,
      selectedGroupsList,
      selectedInstitution: { id: institutionId, isAdmin: isInstitutionAdmin }
    } = this.props;

    let urlParamString = `group=${groupId}`;
    let selectedGroupItem = selectedGroupsList.find(item => item.id === groupId);

    if (isInstitutionAdmin && (groupId === institutionId)) {
      urlParamString = `institution=${institutionId}`;
      selectedGroupItem = allGroups[0];

      actions.resetGroupAdmins();
    }

    if (parentPage === 'manage-admins' && (groupId !== institutionId) && (groupId !== 'all-groups')) {
      actions.getGroupAdmins(groupId);
    }

    if (parentPage === 'admin-dashboard') {
      actions.getUsers(urlParamString);
    }

    actions.setSelectedGroup(selectedGroupItem);
  }

  render() {
    const {
      selectedGroupsList,
      selectedInstitution: {
        id: institutionId,
        name: institutionName,
        isAdmin: isInstitutionAdmin
      },
      selectedGroup: { id: selectedGroupId }
    } = this.props;

    return (
      <Row>
        <Col>
          <Breadcrumb style={{marginBottom: 24}}>
            <BreadcrumbItem active>
              {(isInstitutionAdmin && (selectedGroupId !== 'all-groups')) ?
                <a href="#"
                  onClick={this.onClick}
                  id={institutionId}>
                  {institutionName}
                </a> : institutionName}
            </BreadcrumbItem>
            {selectedGroupsList.map((item, index) =>
              (<BreadcrumbItem key={`breadcrumb-${item.id}`} active>
                {((index + 1) === (selectedGroupsList.length)) ?
                  item.label :
                  <a href="#"
                    onClick={this.onClick}
                    id={item.id}>
                    {item.label}
                  </a>}
              </BreadcrumbItem>))}
          </Breadcrumb>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    institutions: { selectedInstitution },
    groups: { selectedGroup, selectedParentGroup, data: allGroups }
  } = state;

  let selectedGroupsList = buildBreadcrumbFullPath(selectedParentGroup, selectedGroup);

  selectedGroupsList.pop();

  return {
    allGroups,
    selectedGroup,
    selectedParentGroup,
    selectedGroupsList,
    selectedInstitution
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    getUsers,
    setSelectedGroup,
    resetGroupAdmins,
    getGroupAdmins
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminBreadcrumb);
