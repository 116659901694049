import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const SkillItem = ({ skill, onViewEvidence }) => {

  const hasEvidence = skill.badgesCount > 0 || skill.filesCount > 0;
  const skillClass = classNames('skill-pill skill-pill-ellipsis modal-skill-pill', { 'linked': hasEvidence });

  return (
    <span className={skillClass} style={{fontWeight: 'normal'}}>
      {hasEvidence &&
        <>
          {skill.visibility ?
            <Link
              className="clickable orange link"
              to= {`/skill-evidence/${skill.id}`}
              onClick={onViewEvidence()}>
              <i className="fa fa-link link-icon"/>
              <span>{skill.name}</span>
            </Link> :
            <span>{skill.name}</span>}
        </>}
    </span>
  );
};

SkillItem.propTypes = {
  profile: PropTypes.object,
  skill: PropTypes.object.isRequired,
  onViewEvidence: PropTypes.func.isRequired
};

export default SkillItem;
