import { CALL_API } from '../../middlewares/api';

import {
  GET_ALL_TESTIMONIALS_REQUEST,
  GET_ALL_TESTIMONIALS_SUCCESS,
  GET_ALL_TESTIMONIALS_FAILURE,
  GET_TESTIMONIAL_REQUEST,
  GET_TESTIMONIAL_SUCCESS,
  GET_TESTIMONIAL_FAILURE,
  REQUEST_REVISE_TESTIMONIALS_REQUEST,
  REQUEST_REVISE_TESTIMONIALS_SUCCESS,
  REQUEST_REVISE_TESTIMONIALS_FAILURE,
  SET_REVISE_TESTIMONIAL_REQUEST,
  SET_REVISE_TESTIMONIAL_SUCCESS,
  SET_REVISE_TESTIMONIAL_FAILURE,
  EDIT_TESTIMONIAL_VISIBILITY_REQUEST,
  EDIT_TESTIMONIAL_VISIBILITY_SUCCESS,
  EDIT_TESTIMONIAL_VISIBILITY_FAILURE,
} from '../../constants';

export const getAllTestimonialsFromBadgeId = (badgeId, page = 1, receiverId = false, isAuthenticated = false) => {
  const apiUrl = (receiverId) ? `testimonial?resourceId=${badgeId}&page=${page}&receiverId=${receiverId}` : `testimonial?resourceId=${badgeId}&page=${page}`;

  return {
    [CALL_API]: {
      method: 'get',
      authenticated: isAuthenticated,
      endpoint: apiUrl,
      types: [
        GET_ALL_TESTIMONIALS_REQUEST,
        GET_ALL_TESTIMONIALS_SUCCESS,
        GET_ALL_TESTIMONIALS_FAILURE,
      ],
    },
  };
};

export const getAllTestimonialsFromSkillId = (skillId, page = 1) => {
  return {
    [CALL_API]: {
      method: 'get',
      authenticated: true,
      endpoint: `testimonial?skill_id=${skillId}&page=${page}`,
      types: [
        GET_ALL_TESTIMONIALS_REQUEST,
        GET_ALL_TESTIMONIALS_SUCCESS,
        GET_ALL_TESTIMONIALS_FAILURE,
      ],
    },
  };
};

export const requestReviseTestimonial = ({testimonialId, message}) => {
  return {
    [CALL_API]: {
      method: 'patch',
      data : {message},
      authenticated: true,
      endpoint: `revise-testimonial/${testimonialId}`,
      types: [
        REQUEST_REVISE_TESTIMONIALS_REQUEST,
        REQUEST_REVISE_TESTIMONIALS_SUCCESS,
        REQUEST_REVISE_TESTIMONIALS_FAILURE,
      ],
    },
  };
};

export const getTestimonialById = (testimonialId) => {
  return {
    [CALL_API]: {
      method: 'get',
      endpoint: `testimonial/${testimonialId}`,
      types: [
        GET_TESTIMONIAL_REQUEST,
        GET_TESTIMONIAL_SUCCESS,
        GET_TESTIMONIAL_FAILURE,
      ],
    },
  };
};

export const setReviseTestimonial = ({testimonialId, testimony}) => {
  return {
    [CALL_API]: {
      method: 'patch',
      data : {testimony},
      endpoint: `testimonial/${testimonialId}`,
      types: [
        SET_REVISE_TESTIMONIAL_REQUEST,
        SET_REVISE_TESTIMONIAL_SUCCESS,
        SET_REVISE_TESTIMONIAL_FAILURE,
      ],
    },
  };
};

export const editTestimonialVisibility = (data, testimonialId) => {
  const endpoint = `testimonial/${testimonialId}`;

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'put',
      authenticated: true,
      types: [
        EDIT_TESTIMONIAL_VISIBILITY_REQUEST,
        EDIT_TESTIMONIAL_VISIBILITY_SUCCESS,
        EDIT_TESTIMONIAL_VISIBILITY_FAILURE,
      ],
    },
  };
};
