import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as componentsActions from '../../redux/actions/components';
import * as skillBuildersActions from '../../redux/actions/skill-builders';

import TalentDevelopers from './sections/TalentDevelopers';
import SkillBadgeModal from '../../components/home/SkillBadgeModal';
import ToolboxModal from '../../components/home/ToolboxModal';
import EPortfolioModal from '../../components/home/EPortfolioModal';
import RealisticEndorsementsModal from '../../components/home/RealisticEndorsementsModal';
import SampleWebpageModal from '../../components/home/SampleWebpageModal';
import SkillBuildersModalContainer from '../home/SkillBuildersModalContainer';

class ForOrganisations extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    skillBuilders: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state={
      showSkillBuildersModal: false,
      showSkillBadgeModal: false,
      showToolboxModal: false,
      showEPortfolioModal: false,
      showRealisticEndorsementsModal: false,
      showSampleWebpageModal: false,
    };

    this.toggleSkillBuildersModal = this.toggleSkillBuildersModal.bind(this);
    this.toggleSkillBadgeModal = this.toggleSkillBadgeModal.bind(this);
    this.toggleToolboxModal = this.toggleToolboxModal.bind(this);
    this.toggleEPortfolioModal = this.toggleEPortfolioModal.bind(this);
    this.toggleRealisticEndorsementsModal = this.toggleRealisticEndorsementsModal.bind(this);
    this.toggleSampleWebpageModal = this.toggleSampleWebpageModal.bind(this);
    this.toggleRequestInvite = this.toggleRequestInvite.bind(this);
  }

  componentDidMount(){
    const { skillBuildersRequest } = this.props.actions;

    skillBuildersRequest();
  }

  toggleToolboxModal(){
    this.setState({
      showToolboxModal: !this.state.showToolboxModal
    });
  }

  toggleSkillBuildersModal(){
    this.setState({
      showSkillBuildersModal: !this.state.showSkillBuildersModal
    });
  }

  toggleSkillBadgeModal(){
    this.setState({
      showSkillBadgeModal: !this.state.showSkillBadgeModal
    });
  }

  toggleEPortfolioModal(){
    this.setState({
      showEPortfolioModal: !this.state.showEPortfolioModal
    });
  }

  toggleRealisticEndorsementsModal(){
    this.setState({
      showRealisticEndorsementsModal: !this.state.showRealisticEndorsementsModal
    });
  }

  toggleSampleWebpageModal(){
    this.setState({
      showSampleWebpageModal: !this.state.showSampleWebpageModal
    });
  }

  toggleRequestInvite(){
    this.props.actions.openModal('invite-modal');
  }

  render() {
    const { isRequesting, data: skillBuildersData } = this.props.skillBuilders;
    const isOrg = true;
    const {
      showToolboxModal,
      showSkillBuildersModal,
      showSkillBadgeModal,
      showEPortfolioModal,
      showRealisticEndorsementsModal,
      showSampleWebpageModal
    } = this.state;

    return (
      <div className="non-logged-homepage">
        <Helmet title="Talent Development Organizations"/>
        <TalentDevelopers toggleRequestInvite={this.toggleRequestInvite} />
        {/* <Improvement
          toggleRequestInvite={this.toggleRequestInvite}/> */}
        {/* <OrganizationalSections
          toggleRequestInvite={this.toggleRequestInvite}
          toggleSkillBadgeModal={this.toggleSkillBadgeModal}
          toggleEPortfolioModal={this.toggleEPortfolioModal}
          toggleSampleWebpageModal={this.toggleSampleWebpageModal}
          toggleToolboxModal={this.toggleToolboxModal}
          toggleSkillBuildersModal={this.toggleSkillBuildersModal}
          toggleJobCenterModal={this.toggleJobCenterModal}
          toggleRealisticEndorsementsModal={this.toggleRealisticEndorsementsModal}/>
        <Testimonials/> */}
        {/* <WhitePaperDownload
          toggleRequestInvite={this.toggleRequestInvite}/> */}

        {
          showSkillBuildersModal &&
            <SkillBuildersModalContainer
              isRequesting={isRequesting}
              skillbuilders={skillBuildersData}
              toggleOrgContent={isOrg}
              showSkillBuildersModal={showSkillBuildersModal}
              toggleSkillBuildersModal={this.toggleSkillBuildersModal} />
        }

        {
          showSkillBadgeModal &&
            <SkillBadgeModal
              toggleOrgContent={isOrg}
              showSkillBadgeModal={showSkillBadgeModal}
              toggleSkillBadgeModal={this.toggleSkillBadgeModal} />
        }

        {
          showToolboxModal &&
            <ToolboxModal
              toggleOrgContent={isOrg}
              showToolboxModal={showToolboxModal}
              toggleToolboxModal={this.toggleToolboxModal} />
        }

        {
          showEPortfolioModal &&
            <EPortfolioModal
              toggleOrgContent={isOrg}
              showEPortfolioModal={showEPortfolioModal}
              toggleEPortfolioModal={this.toggleEPortfolioModal}/>
        }

        {
          showRealisticEndorsementsModal &&
            <RealisticEndorsementsModal
              toggleOrgContent={isOrg}
              showRealisticEndorsementsModal={showRealisticEndorsementsModal}
              toggleRealisticEndorsementsModal={this.toggleRealisticEndorsementsModal}/>
        }

        {
          showSampleWebpageModal &&
            <SampleWebpageModal
              toggleOrgContent={isOrg}
              showSampleWebpageModal={showSampleWebpageModal}
              toggleSampleWebpageModal={this.toggleSampleWebpageModal}/>
        }

      </div>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    skillBuilders: state.skillBuilders,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({},componentsActions, skillBuildersActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(ForOrganisations);
