import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { isUndefined } from 'lodash';

import { withHooks } from '../../../utils/withHooks';

import * as webpageActions from '../../../redux/actions/webpage';
import * as networksActions from '../../../redux/actions/networks';
import * as componentActions from '../../../redux/actions/components';

import Loading from '../../../components/common/Loading';
import ProfileJumbotron from '../../../components/profile/header/ProfileJumbotron';
import SkillbadgesSideMenu from '../../../components/skill-badges/SkillbadgesSideMenu';
import Portfolio from '../../portfolio/Portfolio';
import RestrictedWebpageSection from '../../../components/profile/visiting/RestrictedWebpageSection';
import FileDetailsModal from '../../portfolio/FileDetailsModal';

class PortfolioDetails extends Component {
  static propTypes = {
    isRequesting: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    params: PropTypes.object,
    networks: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    profile: PropTypes.object.isRequired,
    modal: PropTypes.string
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      slug: null,
      viewingFileId: null,
      publicShareLink: false
    };
  }

  componentWillMount(){
    const { params, actions, data, isAuthenticated } = this.props;

    if(!Object.keys(data).length > 0 || params.slug !== data.id ){
      actions.clearWebpageData();
      actions.webpageDetailsRequest(params.slug, isAuthenticated);
    }

    if (! isUndefined(params.file)) {
      this.setState({
        slug: params.slug,
        viewingFileId: params.file,
        publicShareLink: true
      });
      actions.openModal(`open-view-file-modal-${params.file}`);
    }
  }

  componentWillReceiveProps(nextProps){
    const { data } = this.props;
    const { isRequesting, data: nextData } = nextProps;

    if(data !== nextData && !isRequesting && nextData.isPrivacyVisible ){
      this.props.actions.userSocialLinksRequest(nextData.id);
    }
  }


  render() {
    const {
      isRequesting,
      data: webpageData,
      currentUser,
      networks,
      modal
    } = this.props;
    const { viewingFileId, publicShareLink } = this.state;
    const isViewPortfolioModalOpen = modal && modal === `open-view-file-modal-${viewingFileId}`;

    if (isRequesting || Object.keys(webpageData).length === 0) {
      return (
        <div>
          <div className="loading-container">
            <Loading/>
          </div>
        </div>
      );
    }

    return (
      <div>
        <section id="profile">
          <ProfileJumbotron
            networks={networks}
            profile={webpageData}
            currentUser={currentUser}
            isPublicProfile/>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="content portfolio">
                  <div className="row">
                    <SkillbadgesSideMenu
                      isVisiting
                      profile={webpageData}/>
                    {publicShareLink &&
                      <FileDetailsModal
                        slug={this.state.slug}
                        fileId={viewingFileId}
                        isOpen={isViewPortfolioModalOpen}/>}
                    <Portfolio profile={webpageData}/>
                  </div>
                </div>
              </div>

              {!webpageData.isPrivacyVisible && <RestrictedWebpageSection/>}
            </div>
          </div>
        </section>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  const { webpage, auth, profile } = state;
  return {
    isRequesting: webpage.isRequesting,
    data: webpage.data,
    profile: profile.data,
    networks: state.networks,
    isAuthenticated: state.auth.isAuthenticated,
    currentUser: auth.data.currentUser,
    modal: state.components.modal
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, webpageActions, networksActions, componentActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};



export default withHooks(connect(mapStateToProps, mapDispatchToProps)(PortfolioDetails));
