import PropTypes from 'prop-types';
import React from 'react';
import NotesUnit from './NotesUnit';
import DeleteNoteModal from './DeleteNoteModal';

const JobUnitNotesList = ({
  loadForms,
  notes,
  onDeleteNote,
  onEditNote,
  isJobOwner,
  notesShowMore,
  onNotesShowMore,
  confirmDeleteNoteModalIsOpen,
  onConfirmDeleteNote,
  onCancelDeleteNote,
  isDeleting
}) => {
  return (
    <div>
      {notes.map((note, index) =>
        (<NotesUnit
          key={index}
          isJobOwner={isJobOwner}
          onEditNote={onEditNote}
          onDeleteNote={onDeleteNote}
          loadForms={loadForms}
          notesShowMore={notesShowMore}
          onNotesShowMore={onNotesShowMore}
          note={note}/>))}

      {confirmDeleteNoteModalIsOpen &&
        <DeleteNoteModal
          isDeleting={isDeleting}
          onConfirm={onConfirmDeleteNote}
          onCancel={onCancelDeleteNote}
          isOpen={confirmDeleteNoteModalIsOpen}/>}
    </div>
  );
};

JobUnitNotesList.propTypes = {
  onDeleteNote: PropTypes.func.isRequired,
  onEditNote: PropTypes.func.isRequired,
  loadForms: PropTypes.array.isRequired,
  notes: PropTypes.array,
  isJobOwner: PropTypes.bool.isRequired,
  notesShowMore: PropTypes.array.isRequired,
  onNotesShowMore: PropTypes.func.isRequired,
  confirmDeleteNoteModalIsOpen: PropTypes.bool.isRequired,
  onCancelDeleteNote: PropTypes.func.isRequired,
  onConfirmDeleteNote: PropTypes.func.isRequired,
  isDeleting: PropTypes.object.isRequired
};

export default JobUnitNotesList;
