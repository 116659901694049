import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { SimpleToolTipWrapper } from '../../common/ToolTipWrapper';

const RequestedReviewerComponent = (props) => {
  const rowDetails = props.rowData;

  function getAvatarAndUser(rowDetails) {
    if(rowDetails.fullname === 'Match not found'){
      return (
        <SimpleToolTipWrapper id={rowDetails.id} toolTipContent={
          <div
            className="inside-tooltip">
            We found no Industry Reps available for feedback in the past 14 days. They may simply be busy. You can request feedback again to start a new search for available Industry Reps.
          </div>
        }>
          {renderUserImageElement()}
        </SimpleToolTipWrapper>
      );
    }
    return renderUserImageElement();
  }

  function renderUserImageElement() {
    const rowDetails = props.rowData;
    return (
      <>
        {(rowDetails.feedbackstatus === 'completed' || rowDetails.feedbackstatus === 'pending') &&
          <img
            src={
              rowDetails.avatarURl
                ? rowDetails.avatarURl
                : require('../../../assets/images/avatar.jpg')
            }
            className="requestorAvatar"/>}
        <span> {rowDetails.fullname} </span>
      </>
    );
  }

  return (
    <td>
      <div className="d-flex align-items-center">
        {rowDetails.feedbackstatus === 'completed' || rowDetails.feedbackstatus === 'pending' ? (
          <Link to={`/cp/${rowDetails.vanity}`} className="user-name">
            {getAvatarAndUser(rowDetails)}
          </Link>
        ) : (
          <span> {getAvatarAndUser(rowDetails)} </span>
        )}
      </div>
    </td>
  );
};

RequestedReviewerComponent.propTypes = {
  rowData: PropTypes.object.isRequired,
};

export default RequestedReviewerComponent;
