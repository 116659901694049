import PropTypes from 'prop-types';
import React from 'react';
import _isObject from 'lodash/isObject';
import Button from '../../common/Button';
import TextInput from '../../common/TextInput';

const CertificationForm = ({
  certification,
  onChange,
  onSave,
  onCancel,
  onDelete,
  isSubmitting,
  isUpdating,
  isDeleting,
  errors,
  showDelete,
  onLinkCertification
}) => {
  const nameLabel = (
    <span>
      Certification or License Name:
    </span>
  );

  if (_isObject(certification.startdate)) {
    certification.startdate = certification.startdate.date.replace(' 00:00:00.000000', '');
  }

  if (_isObject(certification.enddate)) {
    certification.enddate = certification.enddate.date.replace(' 00:00:00.000000', '');
  }

  const endDateNote = certification.noend ? '' : 'Required';

  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          <TextInput
            name="name"
            label={nameLabel}
            note="Required"
            placeholder="Enter your certification or license name"
            error={errors.name}
            value={certification.name}
            onChange={onChange}/>
          <TextInput
            name="license"
            label="License Number: "
            placeholder="Enter your license number"
            error={errors.license}
            value={certification.license}
            onChange={onChange}/>
        </div>
        <div className="col-lg-6">
          <TextInput
            name="authority"
            label="Issuing Authority: "
            note="Required"
            placeholder="Enter your issuing authority"
            error={errors.authority}
            value={certification.authority}
            onChange={onChange}/>
          <TextInput
            name="url"
            label="Issuing Authority URL:"
            placeholder="Enter your issuing authority URL"
            error={errors.url}
            value={certification.url}
            onChange={onChange}/>
        </div>
        <div className="col-lg-12">
          <label className="form-control-label" htmlFor="">
            Dates Completed: <span className="note">(or expected completion date)</span>
          </label>
          <div className="row date">
            <div className="col-lg-6">
              <div className="form-group">
                <TextInput
                  type="date"
                  name="startdate"
                  label="Start Date: "
                  note="Required"
                  placeholder="Start Date - YYYY/MM/DD"
                  error={errors.startdate}
                  value={certification.startdate}
                  onChange={onChange}/>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <TextInput
                  type="date"
                  name="enddate"
                  label="End Date: "
                  note={endDateNote}
                  error={errors.enddate}
                  value={certification.enddate}
                  disabled={certification.noend}
                  onChange={onChange}/>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-check mb-3">
            <input
              name="noend"
              checked={certification.noend}
              className="form-check-input"
              type="checkbox"
              onChange={onChange}/>
            <label className="form-check-label">This credential does not expire</label>
          </div>
        </div>
        <div className="col-lg-12 mb-3">
          <a href="#" onClick={onLinkCertification}>
            <i className="fa fa-link"/> Link Your Certification or License as Evidence
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="action-bar text-right">
            {showDelete &&
              <Button
                type="submit"
                onClick={onDelete}
                styles={{marginLeft: 0}}
                disabled={isDeleting}
                buttonClass="btn-danger"
                buttonText={isDeleting ? 'Deleting...' : 'Delete'}/>}

            <Button
              type="button"
              onClick={onCancel}
              buttonClass="btn-secondary"
              buttonText="Cancel"/>

            <Button
              type="submit"
              onClick={onSave}
              disabled={isSubmitting || isUpdating}
              buttonClass="btn-primary"
              buttonText={isSubmitting || isUpdating ? 'Saving...' : 'Save'}/>
          </div>
        </div>
      </div>
    </div>
  );
};

CertificationForm.propTypes = {
  isSubmitting: PropTypes.bool,
  isUpdating: PropTypes.bool,
  isDeleting: PropTypes.bool,
  certification: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  errors: PropTypes.object.isRequired,
  showDelete: PropTypes.bool,
  onLinkCertification: PropTypes.func.isRequired
};

export default CertificationForm;
