import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReplyForm from '../../../components/skill-builders/discussions/ReplyForm';
import * as commentsActions from '../../../redux/actions/comments';
import * as wallCommentsActions from '../../../redux/actions/wall-post-comments';
import * as discussionsActions from '../../../redux/actions/discussions';
import * as wallpostActions from '../../../redux/actions/wall-posts';
import * as componentsActions from '../../../redux/actions/components';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash/isEmpty';
import { toastr } from 'react-redux-toastr';
import { loadForm } from '../../../utils';
import Validator from '../../../validator';

class ReplyFormHandler extends Component {
  static propTypes = {
    commentId: PropTypes.string,
    actions: PropTypes.object,
    discussionId: PropTypes.string,
    wallPostId: PropTypes.string,
    forms: PropTypes.array,
    isSubmitting: PropTypes.object,
    loadForms: PropTypes.array,
    profile: PropTypes.object.isRequired,
    ownState: PropTypes.object.isRequired,
    searchPage: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);

    this.state = Object.assign({}, this.props.ownState);

    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDropFile = this.onDropFile.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.clearImagePreview = this.clearImagePreview.bind(this);
    this.onEscape = this.onEscape.bind(this);
  }

  componentWillUnmount() {
    const {
      loadForms,
      commentId
    } = this.props;

    const isForm = loadForm(
      loadForms,
      `reply-form-commentId-${commentId}`
    );

    if (isForm)
      this.unloadUpdationForm();
  }

  unloadUpdationForm() {
    const { actions, commentId } = this.props;
    const form = `reply-form-commentId-${commentId}`;

    actions.unloadForm(form);
  }

  onEscape() {
    const form = `reply-form-commentId-${this.props.commentId}`;
    document.getElementById(form).blur();

    // Remove validation warnings, if any.
    this.setState({
      errors: {}
    });
  }

  onDropFile(files) {
    if (files[0].size > 8000000)
      return toastr.info('Sorry, this image exceeds the 8MB limit.');

    // focus on input field.
    const form = `reply-form-commentId-${this.props.commentId}`;
    document.getElementById(form).focus();

    const imagePreviewUrl = URL.createObjectURL(files[0]);

    this.setState({
      attachment: files[0],
      imagePreviewUrl
    });
  }

  handleKeyPress(event) {
    if (event.key === 'Enter')
      return this.onSave(event);

    if (event.key === 'Escape')
      return this.onEscape();
  }

  onChange(event) {
    this.setState({
      reply: event.target.value
    }, this.isValid);
  }

  onSave(event) {
    event.preventDefault(); // To prevent new line character.

    const {
      reply,
      attachment
    } = this.state;

    const {
      actions,
      discussionId,
      wallPostId,
      commentId,
      isSubmitting,
      searchPage
    } = this.props;

    if (!this.isValid() || isSubmitting.status)
      return;

    if (!isEmpty(wallPostId)) {
      this.saveWallReply();
      return ;
    }
    let formData = new FormData();
    formData.append('comment', reply);
    if (!isEmpty(attachment))
      formData.append('attachment', attachment);
    formData.append('discussion', discussionId);
    formData.append('parent', commentId);

    actions.saveUnitDiscussionReply(formData)
      .then(() => {
        this.handleResponse();
        if(!searchPage){
          actions.incrementDiscussionCommentCount(discussionId);
        }
      });
  }

  saveWallReply() {
    const {
      reply,
      attachment
    } = this.state;

    const {
      actions,
      wallPostId,
      commentId,
      searchPage
    } = this.props;

    if (! this.isValid()) return;

    let formData = new FormData();

    if(reply === '' && isEmpty(attachment))
      return toastr.error('Reply cannot be empty.');

    if(reply !== '')
      formData.append('comment', reply);
    if (!isEmpty(attachment))
      formData.append('attachment', attachment);
    formData.append('wall', wallPostId);
    formData.append('parent', commentId);

    actions.saveWallPostCommentReplies(formData)
      .then(() => {
        this.handleResponse();
        if(!searchPage)
          actions.incrementWallCommentCount(wallPostId);
      });
  }

  isValid(field = null) {
    const validate = Validator.createValidator({
      reply: ['maxLength|2000']
    }, this.state, field);

    const { isValid, errors } = validate;

    this.setState({ errors });

    return isValid;
  }

  handleResponse() {
    this.setState({
      reply: '',
      attachment: {},
      imagePreviewUrl: ''
    });
  }

  clearImagePreview() {
    this.setState({
      attachment: {},
      imagePreviewUrl: ''
    });
  }

  render() {
    const {
      reply,
      imagePreviewUrl,
      errors
    } = this.state;

    const {
      commentId,
      forms,
      profile
    } = this.props;

    const form = `reply-form-commentId-${this.props.commentId}`;
    const isForm = loadForm(forms, form);

    return(
      <div>
        {
          isForm &&
            <ReplyForm
              reply={reply}
              profile={profile}
              commentId={commentId}
              imagePreviewUrl={imagePreviewUrl}
              onChange={this.onChange}
              onDropFile={this.onDropFile}
              handleKeyPress={this.handleKeyPress}
              clearImagePreview={this.clearImagePreview}
              errors={errors}/>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let ownState = {
    reply: '',
    attachment: {},
    imagePreviewUrl: '',
    errors: {}
  };
  return {
    ownState,
    forms: state.components.forms,
    isSubmitting: state.comments.isSubmitting,
    loadForms: state.components.forms,
    profile: state.profile.data
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({},
    componentsActions,
    discussionsActions,
    commentsActions,
    wallpostActions,
    wallCommentsActions,
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReplyFormHandler);
