import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../common/Button';

const SituationForm = ({profile, onChange, onSave, onCancel, isUpdating}) => {
  const isWorking = String(profile.employmentstatus) !== '2' && String(profile.employmentstatus) !== '3';

  return (
    <div className="profile-item__card">
      <div className="subhead font-weight-bold mb-1">Your Employment Status</div>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-check mb-1">
            <label className="form-check-label">
              <input className="form-check-input" style={{marginRight: 10}}
                type="radio"
                onChange={onChange}
                name="employmentstatus"
                value="0"
                checked={String(profile.employmentstatus) === '0'}/>
              I’m working and not seeking a job
            </label>
          </div>
          <div className="form-check mb-1">
            <label className="form-check-label">
              <input className="form-check-input" style={{marginRight: 10}}
                type="radio"
                onChange={onChange}
                name="employmentstatus"
                value="1"
                checked={String(profile.employmentstatus) === '1'}/>
              I’m working but open to new opportunities
            </label>
          </div>
          <div className="form-check mb-1">
            <label className="form-check-label">
              <input className="form-check-input" style={{marginRight: 10}}
                type="radio"
                onChange={onChange}
                name="employmentstatus"
                value="2"
                checked={String(profile.employmentstatus) === '2'}/>
              I’m not working and actively seeking a job
            </label>
          </div>
          <div className="form-check mb-1">
            <label className="form-check-label">
              <input className="form-check-input" style={{marginRight: 10}}
                type="radio"
                onChange={onChange}
                name="employmentstatus"
                value="3"
                checked={String(profile.employmentstatus) === '3'}/>
              I’m not working and not seeking a job at this time
            </label>
          </div>
        </div>
      </div>
      <div className="subhead mb-2 mt-3">
        <div className="font-weight-bold">Employment Type</div>
        <span className="font12">
          (Choose the one that best describes your situation)
        </span>
      </div>
      <div className="row mb-3">
        <div className="col-lg-10">
          <div className="form-check mb-1">
            <label className="form-check-label">
              <input className="form-check-input" style={{marginRight: 10}}
                type="radio"
                onChange={onChange}
                name="employmenttype"
                value="0"
                checked={String(profile.employmenttype) === '0' && isWorking}/>
              Full-Time
            </label>
          </div>
          <div className="form-check mb-1">
            <label className="form-check-label">
              <input className="form-check-input" style={{marginRight: 10}}
                type="radio"
                onChange={onChange}
                name="employmenttype"
                value="3"
                checked={String(profile.employmenttype) === '3' && isWorking}/>
              Part-Time
            </label>
          </div>
          <div className="form-check mb-1">
            <label className="form-check-label">
              <input className="form-check-input" style={{marginRight: 10}}
                type="radio"
                onChange={onChange}
                name="employmenttype"
                value="1"
                checked={String(profile.employmenttype) === '1' && isWorking}/>
              Paid Internship
            </label>
          </div>
          <div className="form-check mb-1">
            <label className="form-check-label">
              <input className="form-check-input" style={{marginRight: 10}}
                type="radio"
                onChange={onChange}
                name="employmenttype"
                value="4"
                checked={String(profile.employmenttype) === '4' && isWorking}/>
              Unpaid Internship
            </label>
          </div>
          <div className="form-check mb-1">
            <label className="form-check-label">
              <input className="form-check-input" style={{marginRight: 10}}
                type="radio"
                onChange={onChange}
                name="employmenttype"
                value="2"
                checked={String(profile.employmenttype) === '2' && isWorking}/>
              Temporary Contract
            </label>
          </div>
          <div className="form-check mb-1">
            <label className="form-check-label">
              <input className="form-check-input" style={{marginRight: 10}}
                type="radio"
                onChange={onChange}
                name="employmenttype"
                value="5"
                checked={String(profile.employmenttype) === '5' && isWorking}/>
              Self-Employed
            </label>
          </div>
          <div className="form-check mb-1">
            <label className="form-check-label">
              <input className="form-check-input" style={{marginRight: 10}}
                type="radio"
                onChange={onChange}
                name="employmenttype"
                value="6"
                checked={!isWorking}/>
              Not Working
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="action-bar text-right">
            <Button
              type="button"
              onClick={onCancel}
              buttonClass="btn-secondary"
              buttonText="Cancel"/>
            <Button
              type="submit"
              onClick={onSave}
              disabled={isUpdating}
              buttonClass="btn-primary"
              buttonText={isUpdating ? 'Saving...' : 'Save'}/>
          </div>
        </div>
      </div>
    </div>
  );
};

SituationForm.propTypes = {
  profile: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired
};

export default SituationForm;
