import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import LazyLoadImage from '../../containers/common/lazy-load-image/LazyLoadImage';

const SharedBadgePost = ({ quote, badge }) => {
  const { badgeUrl, badge: badgeObject, user, id } = badge;
  const { name } = badgeObject;

  return (
    <div className="shared-item">
      <p className="quote">{quote}</p>
      <Link to={`/skill-badges/badge-details/${id}`}>
        <div className="shared-item-container">
          <div className="shared-item-image">
            <LazyLoadImage
              alt={`Shared skillbadge - ${name}`}
              srcLoaded={badgeUrl}/>
          </div>
          <div className="shared-item-description">
            <h6>{name}</h6>
            <div className="description">
              <p><strong>{user.name}</strong> got the <strong>{name}</strong> Skill Badge on CareerPrepped. Click to see details and evidence for this Skill Badge.</p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

SharedBadgePost.propTypes = {
  badge: PropTypes.object.isRequired,
  quote: PropTypes.string
};

export default SharedBadgePost;
