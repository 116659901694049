import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

const ResumeEducation = ({ resumeEducation }) => {
  return (
    <div className="resume-section">
      <div className="resume-title">
        {resumeEducation.title !== '' ? resumeEducation.title : 'Education'}
      </div>
      {resumeEducation.educationArray.map((education) => {
        const isStartDateObject = typeof education.startdate === 'object';
        const startDateString = isStartDateObject ? education.startdate.date :
          education.startdate === '' ? undefined : education.startdate;

        const isEndDateObject = typeof education.enddate === 'object';
        const endDateString = isEndDateObject ? education.enddate.date :
          education.enddate === '' ? undefined : education.enddate;

        const startDate = moment(startDateString).format('MM/YYYY');
        let endDate;

        if (education.noend) {
          endDate = 'Present';
        } else {
          endDate = moment(endDateString).format('MM/YYYY');
        }

        let institution = <span>{education.institution}</span>;

        if (education.insurl !== '') {
          institution = <span><a href={education.insurl} target="_blank" rel="noopener noreferrer">{education.institution}</a></span>;
        }

        return(
          <div key={education.id} style={{marginBottom: 10}}>
            <div className="info-split">
              <span className="float-xs-none float-left">
                <b>{education.program}: {education.major}</b> - {institution}
              </span>
              <span style={{marginLeft: 10}}>({startDate} – {endDate})</span><br/>
            </div>
            <p>
              <b>GPA</b>: {education.gpa}
            </p>
          </div>
        );
      })}
    </div>
  );
};

ResumeEducation.propTypes = {
  resumeEducation: PropTypes.object.isRequired,
};

export default ResumeEducation;
