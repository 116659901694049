import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withHooks } from '../../utils/withHooks';

import * as authenticationActions from '../../redux/actions/authentication';
import * as routerActions from '../../redux/actions/router';

import ProfileDropdownComponent from '../../components/navbar/ProfileDropdownComponent';

class ProfileDropdown extends PureComponent {
  static propTypes = {
    navigate: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    isHookedToARoute: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { collapse: false };

    this.toggleProfileDropdown = this.toggleProfileDropdown.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.handleClicks = this.handleClicks.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', this.handleClicks);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClicks);
  }

  handleClicks(e) {
    if(document.getElementById('nav-dd')){
      const isClickedInside = document.getElementById('nav-dd').contains(e.target);

      if (this.state.collapse && !isClickedInside){
        this.toggleProfileDropdown();
      }
    }
  }

  toggleProfileDropdown() {
    this.setState({ collapse: !this.state.collapse });
  }

  onLogout(){
    const { actions, navigate, isHookedToARoute } = this.props;

    navigate('/');

    if(isHookedToARoute.status){
      return actions.storeActionForUseAfterwards('userLogout');

    }

    actions.userLogout();
  }

  render() {
    return (
      <ProfileDropdownComponent
        {...this.props}
        {...this.state}
        onLogout={this.onLogout}
        toggleProfileDropdown={this.toggleProfileDropdown}/>
    );
  }
}

const mapStateToProps = ({
  router: { isHookedToARoute },
  profile: { data: profile }
}) => ({ profile, isHookedToARoute });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...routerActions,
    ...authenticationActions
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ProfileDropdown));
