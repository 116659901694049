import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Modal } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import Validator from '../../validator';
import { checkEmail } from '../../utils';
import { withHooks } from '../../utils/withHooks';
import classNames from 'classnames';

import * as routerActions from '../../redux/actions/router';
import * as authActions from '../../redux/actions/authentication';
import * as componentsActions from '../../redux/actions/components';

import ForgotPassword from './ForgotPassword';
import LoginForm from '../../components/auth/LoginForm';

class Login extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    credentials: PropTypes.object,
    showLoginModal: PropTypes.bool,
    toggleLoginModal: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    linkParams: PropTypes.string,
    location: PropTypes.object,
    actions: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      forgotPassword: false,
      newPassword: false,
      credentials: Object.assign({}, this.props.credentials)
    };

    this.refEmailForm = this.refEmailForm.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.authenticateUser = this.authenticateUser.bind(this);
    this.toggleForgotPassword = this.toggleForgotPassword.bind(this);
    this.updateCredentialState = this.updateCredentialState.bind(this);
    this.onCloseResetPassword = this.onCloseResetPassword.bind(this);
  }

  componentWillMount(){
    const { linkParams, location } = this.props;
    if (linkParams) {
      const [,,typeOfAuth] = location.pathname.split('/');
      const [token,emailId,] = linkParams && linkParams.split('/');

      if (checkEmail(emailId) && token && ['new-password', 'reset-password'].includes(typeOfAuth)) {
        this.setState({ newPassword: true });
      }
    } else {
      const [,,typeOfAuth,token,emailId] = location.pathname.split('/');
      if (checkEmail(decodeURIComponent(emailId)) && token && ['new-password', 'reset-password'].includes(typeOfAuth)) {
        this.setState({ newPassword: true });
      }
    }
  }

  componentDidMount(){
    if (this.email) {
      this.email.focus();
    }
  }

  toggleForgotPassword(){
    const { forgotPassword } = this.state;

    this.setState({
      forgotPassword: !forgotPassword
    });
  }

  isValid(field = null) {
    const { credentials, errors: prevErrors } = this.state;

    let validate = Validator.createValidator({
      email: ['required', 'email'],
      password: ['required', 'minLength|8']
    }, credentials, field);

    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  renderErrors() {
    let errorMessage = [];
    let { error } = this.props.auth;

    if (Object.keys(error).length > 0) {
      if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        errorMessage.push(error.message);
      } else {
        Object.keys(error).map(key => errorMessage.push(error[key][0]));
      }

      if (errorMessage.length == 0) return false;
      toastr.error(errorMessage.join('<br/>'));
    }
  }

  updateCredentialState(event) {
    const field = event.currentTarget['name'];
    let credentials = this.state.credentials;

    credentials[field] = event.currentTarget['value'];

    this.setState({ credentials }, () => this.isValid(field));
  }

  authenticateUser(event) {
    event.preventDefault();

    if (! this.isValid()) return false;

    if(this.props.auth.isRequesting) return false;

    this.props.actions.authenticateUser(this.state.credentials)
      .then(() => this.redirect());
  }

  redirect() {
    const { actions, navigate, router, auth, toggleLoginModal } = this.props;
    const { nextPathname } = router;

    if (auth.isAuthenticated) {
      toggleLoginModal();

      // If we need to redirect the user to the page coming from after login
      if (window.localStorage.getItem('landingPath')) {
        const landingPath = window.localStorage.getItem('landingPath');
        window.localStorage.removeItem('landingPath');
        actions.redirect(landingPath);
        navigate(landingPath);
        return;
      }

      if (nextPathname) {
        actions.redirect(nextPathname);
        navigate(nextPathname);
        return;
      }

      actions.redirect('/home');
      navigate('/home');
    }
  }

  handleKeyPress(event) {
    if (event.key === 'Enter')
      return this.authenticateUser(event);

    if (event.key === 'Escape')
      return this.toggleLoginModal();
  }

  refEmailForm(email) {
    this.email = email;
  }

  onCloseResetPassword(){
    const { closeModal } = this.props.actions;
    this.setState({newPassword: false}, () => {
      window.location.href = '/';
      closeModal();
    });
  }

  render() {
    const { auth, showLoginModal, toggleLoginModal, linkParams } = this.props;
    const { credentials, errors, forgotPassword, newPassword } = this.state;

    const component = forgotPassword || newPassword ?
      (<ForgotPassword
        linkParams={linkParams}
        newPassword={newPassword}
        onCloseResetPassword={this.onCloseResetPassword}
        toggleForgotPassword={this.toggleForgotPassword}/>) :
      (<LoginForm
        errors={errors}
        emailRef={this.refEmailForm}
        credentials={credentials}
        toggleLoginModal={toggleLoginModal}
        onSubmit={this.authenticateUser}
        forgotPassword={forgotPassword}
        handleKeyPress={this.handleKeyPress}
        isRequesting={auth.isRequesting}
        onChange={this.updateCredentialState}
        toggleForgotPassword={this.toggleForgotPassword}/>);

    const forgotModalCondition = forgotPassword || newPassword;
    return (
      <Modal
        isOpen={showLoginModal}
        toggle={toggleLoginModal}
        className={classNames(
          {'signinModal': !forgotModalCondition},
          {'forgotModal': forgotModalCondition}
        )}>
        {component}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  let credentials = { email: '', password: '' };

  return {
    credentials,
    auth: state.auth,
    router: state.router
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, routerActions, authActions, componentsActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(Login));
