import React from 'react';
import PropTypes from 'prop-types';
import {
  MATCHING,
} from '../../../constants/feedback';

const RequestedStatusComponent = (props) => {
  const rowDetails = props.rowData;
  return (
    <td>
      <div className={`requestStatusTag ${rowDetails.feedbackstatus}-request`}>
        {!rowDetails.fullname && rowDetails.feedbackstatus !== 'canceled'
          ? MATCHING
          : rowDetails.feedbackstatus == 'matching' ?
            'Finding Match' : rowDetails.feedbackstatus === 'pending' ?
              'Awaiting Reply' : rowDetails.feedbackstatus === 'canceled' ?
                'Request Canceled' : rowDetails.feedbackstatus === 'completed' ?
                  'Feedback Received' : rowDetails.feedbackstatus}
      </div>
    </td>
  );
};

RequestedStatusComponent.propTypes = {
  rowData: PropTypes.object.isRequired,
};

export default RequestedStatusComponent;
