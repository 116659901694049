import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

const ConfirmModal = ({
  isOpen,
  modalHeaderText,
  modalMessage,
  modalFooterButtonText,
  onCancel,
  onConfirm,
  isConfirming,
  confirminginProgressText,
}) => {
  return(
    <Modal
      className="modal-margin-top"
      isOpen={isOpen}
      toggle={onCancel}>
      <ModalHeader
        toggle={onCancel}>
        {modalHeaderText}
      </ModalHeader>

      {
        modalMessage &&
          <ModalBody>
            {modalMessage}
          </ModalBody>
      }

      <ModalFooter>
        <button
          onClick={onCancel}
          className="btn btn-secondary">
          Cancel
        </button>

        <button
          onClick={onConfirm}
          className="btn btn-primary"
          disabled={isConfirming}>
          {isConfirming ? confirminginProgressText : modalFooterButtonText}
        </button>
      </ModalFooter>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  modalHeaderText: PropTypes.string.isRequired,
  modalMessage: PropTypes.string,
  modalFooterButtonText: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isConfirming: PropTypes.bool,
  confirminginProgressText: PropTypes.string,
  disableConfirmBtn: PropTypes.bool,
};

export default ConfirmModal;
