import React from 'react';

import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import CloseIcon from '../../assets/images/close.svg';

import {
  IGNORE,
  CANCEL,
  REMOVE,
  REMOVE_CANCELED,
  FEEDBACK_REQUEST,
  IGNORE_FEEDBACK_CONFIRMATION,
  REMOVE_CONFIRMATION,
  RESEND_CONFIRMATION,
  RESEND,
  CANCEL_REQUEST_CONFIRMATION,
  NO_DONT,
  I_DO,
  YES,
} from '../../constants/feedback';

const IgnoreFeedbackRequest = ({
  isOpen,
  toggleModal,
  operationFeedback,
  requestFor,
}) => {

  function handleToggleModal(){
    toggleModal(requestFor);
  }

  function handleClick(){
    operationFeedback(requestFor);
    handleToggleModal();
  }

  return (
    <Modal
      centered
      className="customLevelUpModal"
      isOpen={isOpen}
      toggle={handleToggleModal}>
      <div className="modal-header">
        <span className="modal-title">
          {requestFor === REMOVE ? REMOVE_CANCELED : requestFor}{' '}
          {FEEDBACK_REQUEST}
        </span>
        <div
          onClick={handleToggleModal}
          className="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">
            <img src={CloseIcon} />
          </span>
        </div>
      </div>

      <ModalBody>
        {requestFor === IGNORE && <p>{IGNORE_FEEDBACK_CONFIRMATION}</p>}
        {requestFor !== IGNORE && requestFor !== REMOVE && requestFor !== RESEND && (
          <p>{CANCEL_REQUEST_CONFIRMATION}</p>
        )}
        {requestFor === REMOVE && <p>{REMOVE_CONFIRMATION}</p>}
        {requestFor === RESEND && <p>{RESEND_CONFIRMATION}</p>}        
      </ModalBody>
      <ModalFooter className="bordertop-0">
        <button
          className="btn btn-secondary"
          onClick={handleToggleModal}>
          {requestFor === IGNORE || requestFor === REMOVE || requestFor === RESEND ? CANCEL : NO_DONT}
        </button>
        <Button
          className="ml-1 sendButton"
          onClick={handleClick}>
          {requestFor === IGNORE || requestFor === REMOVE || requestFor === RESEND
            ? `${YES}, ${requestFor}`
            : I_DO}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

IgnoreFeedbackRequest.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  requestFor: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  ignoreFeedbackRequest: PropTypes.func,
  operationFeedback: PropTypes.func.isRequired,
};

export default IgnoreFeedbackRequest;