import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';

import { getRelationList } from '../../redux/actions/invite-users';

import { Input, Label, Form, FormGroup, Button } from 'reactstrap';

const Relationship = ({ proceed, actions, feedbackResponse, feedbackData, relationshipList }) => {
  const [relationError, setRelationError] = useState('');

  useEffect(() => {
    actions.getRelationList();
  }, [actions]);

  function onChange(event) {
    const { value, name } = event.target;

    feedbackResponse(name, value);
    setRelationError('');
  }

  function saveQuestionResponses() {
    if (feedbackData.length === 0) {
      setRelationError('Required');
      return;
    }

    proceed('step0');
    proceed('step1');
  }

  return (
    <div className="evidenceListControls">
      <section className="questionstoadmin px-2">
        <div className="question1 pt-2">
          <span className="font16 fontW600 mb-3 d-block mt-2">
            {feedbackData.length === 0 ?
              'Which best describes your current relationship to the feedback recipient?' :
              'Your relationship to the feedback recipient is saved. Update if needed before clicking "Next".'}
          </span>
          <Form className="answer">
            {relationshipList.map(relationship => (
              <FormGroup
                key={relationship.value}
                check
                className="custom-radio-wrapper form-check pl-0">
                <Label check className="container">
                  <span className="ml-3 font14"> {relationship.label}</span>
                  <Input
                    className="mr-1"
                    type="radio"
                    name="relation"
                    value={relationship.value}
                    invalid={relationError.length !== 0}
                    defaultChecked={relationship.value === feedbackData}
                    onChange={onChange}/>
                  <span className={`${relationError.length !== 0 ? 'is-invalid checkmark' : 'checkmark'}`} />
                </Label>
              </FormGroup>
            ))}
          </Form>
          <div className="form-control-feedback">
            {relationError}
          </div>
        </div>
        <div className="text-right mt-1">
          <Button
            onClick={saveQuestionResponses}
            className="sendButton">
            Next
          </Button>
        </div>
      </section>
    </div>
  );
};

Relationship.propTypes = {
  proceed: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  feedbackData: PropTypes.string.isRequired,
  feedbackResponse: PropTypes.func.isRequired,
  relationshipList: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  relationshipList: state.inviteUsers.data
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getRelationList }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Relationship);
