import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  requestFeedback,
  getPeers,
  getFollowers,
} from '../../redux/actions/myskills';
import { peersRequest } from '../../redux/actions/peers';

import {
  selectPeersPageCount,
  selectFormattedPeers
} from '../../redux/selectors/peers';

import { isEmpty, toInteger, uniqBy } from 'lodash';
import validator from '../../validator';

import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  NavItem,
  NavLink,
  Nav,
  Input,
  Label,
} from 'reactstrap';
import { AsyncPaginate } from 'react-select-async-paginate';

import CloseIcon from '../../assets/images/close.svg';
import AsyncPaginateOption from '../../components/myskills/AsyncPaginateOption';

const colourStyles = {
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#B2D4FF' : 'transparent'
    };
  }
};

class RequestPeers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: [],
      peerBasicDetails: [{ firstName: '', lastName: '', email: '' }],
      errors: { peerBasicDetails: [] },
      sendBtnDisabled: true,
      sendBtnText: 'Send',
    };

    this.remove = this.remove.bind(this);
    this.clearAll = this.clearAll.bind(this);
    this.getSelectCount = this.getSelectCount.bind(this);
    this.onSend = this.onSend.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.onChange = this.onChange.bind(this);
    this.loadOptions = this.loadOptions.bind(this);
  }

  componentDidMount() {
    this.props.peers.length == 0 &&
      this.props.actions.getPeers(this.props.currentUser.id);
    this.props.followers.length == 0 &&
      this.props.actions.getFollowers(this.props.currentUser.id);

    this.setIsOpen();
    document.addEventListener('mousedown', this.enableScroll, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.enableScroll, false);
  }

  async loadOptions(search, loadedOptions, { page }) {
    await this.props.actions.peersRequest(page, search);

    return {
      options: this.props.peers,
      hasMore: this.props.peersPageCount > page,
      additional: { page: page + 1 }
    };
  }

  onChange(value) {
    if (value.length > 10) return;

    this.setState({
      tags: [...value],
      sendBtnDisabled: (value.length === 0)
    });
  }

  enableScroll(e) {
    if(e.target.className === 'optionContainer') {
      setTimeout(() => {
        e.target.parentNode.classList.remove('displayNone');
        e.target.parentNode.classList.add('displayBlock');
      }, 260);

    }
  }

  setIsOpen() {
    this.setState({isOpen: this.props.isOpen});
  }

  getSelectCount() {
    const emptyArrayElements = this.state.peerBasicDetails.filter(
      x => !isEmpty(x.firstName) && !isEmpty(x.lastName) && !isEmpty(x.email)
    );
    const selectCount = this.state.tags.length + emptyArrayElements.length || 0;
    if (!selectCount) {
      return (
        <div className={`radialProgressBar progress-${selectCount}`}>
          <div className="overlay-0">{selectCount}</div>
        </div>
      );
    }
    return (
      <div className={`radialProgressBar progress-${selectCount}`}>
        <div className="overlay">{selectCount}</div>
      </div>
    );
  }

  clearAll() {
    this.setState({
      tags: [],
      peerBasicDetails: [{ firstName: '', lastName: '', email: '' }],
      sendBtnDisabled: true,
      sendBtnText: 'Send',
    });
  }

  ispeerBasicDetailsEmpty = () =>
    this.state.peerBasicDetails.some(input => input.email === '');

  remove(e) {
    const tag = e.target.getAttribute('data-value');

    const filteredTags = this.state.tags.filter(
      tagItem => tagItem.label !== tag
    );

    if( [...filteredTags].length === 0 ) {
      this.setState({sendBtnDisabled: true});
    }
    this.setState({ tags: [...filteredTags], sendBtnText: 'Send', });
  }

  handleInputChange = (e) => {
    if (this.state.peerBasicDetails.length + this.state.tags.length > 10) return;
    const { name, value } = e.target;

    const index = e.target.getAttribute('data-value');

    const list = [...this.state.peerBasicDetails];
    list[index][name] = value;
    this.setState({ peerBasicDetails: list });
  };

  // handle click event of the Remove button
  handleRemoveClick = (e) => {
    const index = e.target.getAttribute('data-value');

    const list = [...this.state.peerBasicDetails];
    if (this.state.peerBasicDetails.length > 1) {
      list.splice(index, 1);
      this.setState({ peerBasicDetails: list });
    } else {
      this.setState({
        peerBasicDetails: [{ firstName: '', lastName: '', email: '' }],
      });
    }
  };

  // handle click event of the Remove button
  handleRemoveAll = () => {
    this.setState({
      peerBasicDetails: [{ firstName: '', lastName: '', email: '' }],
    });
  };

  // handle click event of the Add button
  handleAddClick = () => {
    this.setState({
      peerBasicDetails: [
        ...this.state.peerBasicDetails,
        {
          firstName: '',
          lastName: '',
          email: '',
        },
      ],
    });
  };

  getValidExternalUsers = () => {
    const list = [...this.state.peerBasicDetails];
    return list.filter((element) => {
      if (!element.firstName && !element.lastName && !element.email) {
        return false;
      }

      if (!/^[A-Za-z _]*[A-Za-z][A-Za-z _]*$/.test(element.firstName)) {
        if (element.firstName.length <= 1 || element.firstName.length < 60) {
          return false;
        } else {
          return false;
        }
      }
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          element.email
        )
      ) {
        return false;
      } else {
        // ToDo: verify
        // if (this.props.profile.email === element.email) {
        //   // validationMsg = "error";
        //   // toastr.error("", "You cannot add your own email address");
        //   return false;
        // }
      }
      return true;
    });
  };

  onSend() {
    const list = [...this.state.peerBasicDetails];
    const { profile } = this.props;
    const stateInputErrors = [];

    const emailList = list.map(input => input.email);
    let emailRules = [
      'required',
      'email',
      `isNotSelfEmail|${profile.email}`,
      `isEmailUnique|${emailList}`,
    ];
    if (emailList.length === 1) {
      emailRules.pop();
    }
    const rules = {
      firstName: [
        'required',
        'minLength|1',
        'maxLength|60',
        'stringWithHyphenSpace',
      ],
      lastName: [
        'required',
        'minLength|1',
        'maxLength|60',
        'stringWithHyphenSpace',
      ],
      email: emailRules,
    };

    const updatedList = list.filter((element, index) => {
      const validate = validator.createValidator(rules, element);
      const { errors, isValid } = validate;
      if (!isEmpty(this.state.peerBasicDetails[0].firstName) &&
        !isEmpty(this.state.peerBasicDetails[0].email) &&
        !isEmpty(this.state.peerBasicDetails[0].lastName) &&
        !isValid) {
        const errorField = Object.keys(errors)[0];
        let inputDataError = {};

        if(errorField === 'email') {
          const errorMessageDetails = errors[errorField].split(',');
          const errorMessageString = errorMessageDetails[0];
          const initialOccurenceIndex = errorMessageDetails[1];
          if(index !== toInteger(initialOccurenceIndex)){

            inputDataError = {
              index: index,
              message: errorMessageString,
              field: errorField,
            };
          } else if(errorMessageDetails.length === 1) {
            inputDataError = {
              index: index,
              message: errors[errorField],
              field: errorField,
            };
          }
        }else{
          inputDataError = {
            index: index,
            message: errors[errorField],
            field: errorField,
          };
        }

        stateInputErrors.push(inputDataError);
        return false;
      }
      return true;
    });
    if (!isEmpty(stateInputErrors)) {
      this.setState({
        errors: {
          peerBasicDetails: stateInputErrors,
          sendBtnText: 'Send',
          sendBtnDisabled: true,
        },
      });
      return false;
    }

    this.setState({ peerBasicDetails: list, errors: { peerBasicDetails: [] }, sendBtnText: 'Send', sendBtnDisabled: true });
    const reqObj = {
      request_for_user_id: this.props.currentUser.id,
      userType: 'user',
      users: this.state.tags.map(t => t.value),
    };

    if (reqObj?.users?.length === 0 && updatedList.length === 0) {
      toastr.error(
        '',
        'Kindly Select or Enter at least one peer or one external user.'
      );
      return false;
    }

    if (updatedList.length > 0) {
      reqObj['external_users'] = updatedList;
    }

    if (this.props.skill_id) {
      reqObj.skill_id = this.props.skill_id;
    }
    if (this.props.badge_id) {
      reqObj.badge_issued_id = this.props.data.issuedID;
    }

    this.setState({
      sendBtnDisabled: true,
      sendBtnText: 'Sending...'
    });
    this.props.actions.requestFeedback(reqObj).then(() => {
      this.setState({
        tags: [],
        peerBasicDetails: [{ firstName: '', lastName: '', email: '' }],
      });
      toastr.success('Your feedback request was successfully sent');
      this.props.toggleModal('requestPeers');
    }).catch(() => {
      this.props.toggleModal('requestPeers');
    });
  }

  handleToggle() {
    this.setState({
      tags: [],
      peerBasicDetails: [{ firstName: '', lastName: '', email: '' }],
      sendBtnDisabled: true,
      sendBtnText: 'Send',
    });

    this.props.toggleModal('requestPeers');
  }

  noOptionsMessage() {
    return 'None available. Follow community members to request their feedback.';
  }

  render() {
    const { peers, followers } = this.props;
    const { isOpen } = this.state;
    const peersAndFollowers = uniqBy([...peers, ...followers], 'value');
    const errorState = this.state.errors;

    const isListFilled = this.state.peerBasicDetails.some(x => !isEmpty(x.firstName) && !isEmpty(x.lastName) && !isEmpty(x.email));

    return (
      <Modal
        centered
        className="customLevelUpModal modal-lg"
        isOpen={isOpen}
        toggle={this.handleToggle}>
        <div className="modal-header">
          <span className="modal-title">
            Request Feedback from up to 10 Individuals at a Time
          </span>
          <div
            onClick={this.handleToggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">
              <img src={CloseIcon} />
            </span>
          </div>
        </div>
        <ModalBody>
          <div>
            {!isEmpty(peersAndFollowers) && (
              <label>Choose from your CareerPrepped Network</label>
            )}
            <Row>
              <Col md="8">
                <AsyncPaginate
                  isMulti
                  cacheOptions
                  isSearchable
                  additional={{ page: 1 }}
                  isClearable={false}
                  loadOptions={this.loadOptions}
                  styles={colourStyles}
                  placeholder="Who do you want to ask for feedback?"
                  noOptionsMessage={this.noOptionsMessage}
                  className="multiselect-container multiSelectContainer"
                  components={{Option: AsyncPaginateOption}}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  value={this.state.tags}
                  controlShouldRenderValue={false}
                  onChange={this.onChange} />
              </Col>

              <Col md="4" className="pl-0">
                <div className="progressBar-wrapper">
                  {this.getSelectCount()}
                  <div className="font12">Added</div>
                </div>
              </Col>
            </Row>
          </div>
          <div>
            {this.state.tags.map(tag => (
              <span key={tag.value} className="d-inline-block reqtag">
                {tag.label}
                <i
                  data-value={tag.label}
                  className="fa fa-close ml-2 pointer"
                  onClick={this.remove}/>
              </span>
            ))}
            {this.state.tags.length > 1 && (
              <Nav>
                <NavItem>
                  <NavLink className="pl-0" onClick={this.clearAll} href="#">
                    <small>
                      <u> Remove All </u>
                    </small>
                  </NavLink>
                </NavItem>
              </Nav>
            )}
          </div>
          <br />
          <label>Ask Someone You Know Outside your CareerPrepped Network</label>
          {this.state.peerBasicDetails.map((x, i) => {
            const errorData = errorState.peerBasicDetails.filter(
              inputError => inputError.index === i
            );
            return (
              <div key={i}>
                <div key={i} className="formRowWrapper mb-0 extraMargin">
                  <div className="formRow justify-content-between pl-0 pr-3 pr-lg-0 pr-md-0 mb-1">
                    <input type="hidden" value="" />
                    <Input
                      name="firstName"
                      placeholder="Enter First Name"
                      value={x.firstName}
                      data-value={i}
                      autoComplete="off"
                      onChange={this.handleInputChange}
                      invalid={
                        !isEmpty(errorData) &&
                        errorData[0].field === 'firstName'
                      }
                      required/>
                    <div className="valid-feedback">Looks good!</div>
                    <Input
                      autoComplete="off"
                      name="lastName"
                      placeholder="Enter Last Name"
                      value={x.lastName}
                      data-value={i}
                      invalid={
                        !isEmpty(errorData) && errorData[0].field === 'lastName'
                      }
                      onChange={this.handleInputChange}/>
                    <Input
                      autoComplete="off"
                      name="email"
                      placeholder="Enter Email"
                      value={x.email}
                      data-value={i}
                      invalid={
                        !isEmpty(errorData) && errorData[0].field === 'email'
                      }
                      onChange={this.handleInputChange}/>
                  </div>
                  <div>
                    {i === 0 && (
                      <span
                        className="btn btn-link myFeedbackLink font12 d-flex align-items-center px-2 w-65px"
                        onClick={this.handleRemoveAll}>
                        Clear All
                      </span>
                    )}
                    {this.state.peerBasicDetails.length > 1 && i > 0 && (
                      <span
                        className="btn btn-link myFeedbackLink font12 d-flex align-items-center px-2"
                        data-value={i}
                        onClick={this.handleRemoveClick}>
                        <i className="fa fa-trash" />
                        &nbsp;Delete
                      </span>
                    )}
                  </div>
                </div>

                {!isEmpty(errorData) && (
                  <div>
                    <div className="form-control-feedback mt-0 mb-2">
                      {errorData[0].message}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          <div className="text-right d-flex mr-5 pr-4 justify-content-end">
            <span
              className="btn btn-link myFeedbackLink font12 d-block pr-0"
              onClick={this.handleAddClick}>
              <i className="fa fa-plus-square mt-1" />
              &nbsp;Add
            </span>
          </div>
          <Label for="exampleEmail">Subject</Label>
          <Input
            readOnly
            type="email"
            name="email"
            id="exampleEmail"
            className="font14 bg-white"
            value={`${this.props.name} has requested your feedback`}/>
          <div className="form-group mt-2 mb-0">
            <div className="emailbody">
              <span>{'Hi'}</span>
              <br />
              <br />
              <span>
                {`I'm using CareerPrepped to get feedback on how well I'm proving the skills I believe I have since proven skills matter most in the labor market. I hope you'll kindly consider reviewing the evidence of my skill ${this.props.data?.name} and give your thoughtful feedback on how well you believe I'm proving it with the evidence I've provided. Thank you in advance for considering!`}
              </span>
              <span className="mt-1 themeLink">
                <span className="pointer" disabled>
                  Review my evidence and give feedback
                </span>
              </span>
              <span className="text-break">
                If the link doesn't open in your browser, you can copy and paste
                the link in your browser<b>:</b>&nbsp;
                {`${`${window.location.protocol}//${window.location.hostname}${
                  window.location.port ? `:${window.location.port}` : ''
                }`}/givefeedback/abc`}

              </span>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="bordertop-0">
          <button className="btn btn-secondary" onClick={this.handleToggle}>
            Cancel
          </button>
          <Button
            onClick={this.onSend}
            className="ml-1 sendButton"
            disabled={!(!this.state.sendBtnDisabled || isListFilled)}
            color="warning">
            {this.state.sendBtnText}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

RequestPeers.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  peers: PropTypes.array.isRequired,
  followers: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  badge_id: PropTypes.string,
  skill_id: PropTypes.string,
  actions: PropTypes.object,
  data: PropTypes.object,
  name: PropTypes.string,
  profile: PropTypes.object,
  peersPageCount: PropTypes.number.isRequired
};

const mapStateToProps = (state) => {
  return {
    peers: selectFormattedPeers(state),
    peersPageCount: selectPeersPageCount(state),
    name: state.profile.data?.name,
    followers: state.myskills.data.followers,
    currentUser: state.auth.data.currentUser,
    profile: state.profile.data,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      peersRequest,
      requestFeedback,
      getPeers,
      getFollowers,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestPeers);
