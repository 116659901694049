import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import classNames from 'classnames';

import { formatGroupsBy } from '../../utils';

import { Collapse } from 'reactstrap';

import { getUsers } from '../../redux/actions/users';
import { getGroupAdmins, setSelectedGroup } from '../../redux/actions/groups';

class AdminSubGroup extends Component {
  static propTypes = {
    groups: PropTypes.array.isRequired,
    actions: PropTypes.object.isRequired,
    parentPage: PropTypes.string.isRequired,
    selectedGroup: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };

    this.handleToggle = this.handleToggle.bind(this);
    this.onClickChild = this.onClickChild.bind(this);
  }

  handleToggle() {
    const { isOpen } = this.state;

    this.setState({ isOpen : ! isOpen });
  }

  onClickChild(event) {
    const groupId = event.target.closest('a').id;
    const { isOpen } = this.state;
    const { actions, parentPage, selectedGroup } = this.props;

    const urlParamString = `group=${groupId}`;
    const selectedChildGroup = selectedGroup.children.find(item => item.id === groupId);

    if (parentPage === 'admin-dashboard') {
      actions.getUsers(urlParamString);
    }

    if (parentPage === 'manage-admins') {
      actions.getGroupAdmins(groupId);
    }

    actions.setSelectedGroup(selectedChildGroup, true);
    this.setState({ isOpen: ! isOpen });
  }

  render() {
    const { isOpen } = this.state;
    const { groups, selectedGroup } = this.props;

    return (
      <div className="row mt-2">
        <div className="col-lg-12">
          <div className="admin-list-header" onClick={this.handleToggle}>
            {`Groups Within ${selectedGroup.label}`}
            <i className={classNames('fa float-right', {
              'fa-chevron-down': !isOpen,
              'fa-chevron-up': isOpen
            })}/>
          </div>
          <Collapse isOpen={isOpen}>
            <div style={{marginTop: 8, boxShadow: '1px 1px 5px rgba(0,0,0,.05)', backgroundColor:'white', padding: 16, borderRadius: 4 }}>
              <div className="row">
                {groups.map((group, index) => (
                  <div className="col-lg-12" key={index}>
                    <div className="admin-item">
                      <div className="admin-name">{group.owner}</div>
                      <div className="admin-group-list">
                        {group.groups.map(item => (
                          <a
                            href="#"
                            className="item"
                            key={item.id}
                            id={item.id}
                            onClick={this.onClickChild}>
                            <div>
                              <span
                                className="img-responsive initial-avatar group">
                                {item.name.slice(0, 1)}
                              </span>
                            </div>
                            <div>
                              <div>
                                {`${item.name}`}
                              </div>
                              <div style={{
                                fontSize: 12
                              }}>
                                {`${item.groupUsersCount.toLocaleString()} Members`}
                              </div>
                            </div>
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* <div className="admins-more"><a href="">Show More</a></div> */}
            </div>
          </Collapse>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { groups: { selectedGroup } } = state;

  const groups = formatGroupsBy('owner', selectedGroup.children);

  return {
    groups,
    selectedGroup
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    getUsers,
    getGroupAdmins,
    setSelectedGroup
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminSubGroup);
