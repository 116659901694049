import initialState from './initialState';

import {
  INDUSTRIES_REQUEST,
  INDUSTRIES_SUCCESS,
  INDUSTRIES_FAILURE,
  RESET_INDUSTRIES
} from '../../constants';

export default function reducer(state = initialState.industries, action) {
  switch (action.type) {
  case INDUSTRIES_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case INDUSTRIES_SUCCESS:
    return Object.assign({}, state, {
      isRequesting: false,
      page: action.data.page,
      pageCount: action.data.page_count,
      data: action.data._embedded.career_clusters
    });

  case INDUSTRIES_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false
    });

  case RESET_INDUSTRIES:
    return Object.assign({}, state, { ...initialState.industries });

  default:
    return state;
  }
}
