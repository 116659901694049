import PropTypes from 'prop-types';
import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import _isUndefined from 'lodash/isUndefined';
import _flow from 'lodash/flow';

import SkillCard from './SkillCard';
import ItemTypes from '../../../constants/ItemTypes';

const cardSource = {
  beginDrag(props) {
    return {
      id: props.skill.id,
      index: props.index,
    };
  }
};

const cardTarget = {
  canDrop(props) {
    return !_isUndefined(props.reArrangeSkills);
  },

  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) return;


    //
    // Check if dropable
    //
    if (! monitor.canDrop()) return;

    // Perform the action
    props.reArrangeSkills(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item!
    // Generally it's better to avoid mutations,
    // but it's good here for performance sake
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};

const SkillCardContainer = ({
  isDragging,
  connectDragSource,
  connectDragPreview,
  connectDropTarget,
  isOver,
  skill,
  profile,
  isPublicProfile,
  onEditSkillClick,
  setSkillBeingUpdatedInStore,
}) => {
  const opacity = isDragging ? 0 : 1;
  const border = isOver ? '2px dashed #928e8e' : 'none';

  let component = (
    <div className="card" style={{opacity, border, marginBottom : 0}}>
      <SkillCard
        skill={skill}
        profile={profile}
        connectDragSource={connectDragSource}
        isPublicProfile={isPublicProfile}
        onEditSkillClick={onEditSkillClick}
        setSkillBeingUpdatedInStore={setSkillBeingUpdatedInStore} />
    </div>
  );

  return connectDropTarget(connectDragPreview(component));
};

SkillCardContainer.propTypes = {
  id: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.string,
  profile: PropTypes.object,
  index: PropTypes.number,
  isAdmin: PropTypes.bool,
};

export default _flow(DragSource(
  ItemTypes.SKILL,
  cardSource,
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  })),
DropTarget(
  ItemTypes.SKILL,
  cardTarget,
  (connect, monitor) => ({
    isOver: monitor.isOver(),
    connectDropTarget: connect.dropTarget()
  })
))(SkillCardContainer);
