import PropTypes from 'prop-types';
import React from 'react';

import TextInput from '../common/TextInput';

const PrimaryEmail = ({
  primaryEmail,
  error,
  changePrimaryEmail,
  isUpdating,
  onKeyPress
}) => {
  return(
    <TextInput
      type="text"
      label="Primary Email:"
      className="form-control input-styles-owht"
      inputId="email"
      placeholder={'Primary Email'}
      name="primaryEmail"
      value={primaryEmail}
      onChange={changePrimaryEmail}
      error={error}
      disabled={isUpdating}
      onKeyPress={onKeyPress}/>
  );
};

PrimaryEmail.propTypes = {
  primaryEmail: PropTypes.string,
  error: PropTypes.string,
  changePrimaryEmail: PropTypes.func,
  isUpdating: PropTypes.bool,
  onKeyPress: PropTypes.func
};

export default PrimaryEmail;
