import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import * as followersActions from '../../../redux/actions/followers';
import * as followingActions from '../../../redux/actions/following';
import * as messageActions from '../../../redux/actions/messages';
import FollowersList from '../../../components/profile/connects/FollowersList';
import MessageModal from '../../../components/profile/connects/MessageModal';
import AlertBox from '../../../components/common/AlertBox';

class Followers extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    followers: PropTypes.object.isRequired,
    following: PropTypes.object.isRequired,
    profile: PropTypes.object,
    isSubmitting: PropTypes.bool,
    message: PropTypes.object,
    messages: PropTypes.array,
    isAuthenticated: PropTypes.bool.isRequired
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      isSortByOpen: false,
      selected: [],
      error: {},
      modalOpen: false,
      message: Object.assign({}, props.message),
      allSelected: false
    };

    this.toggleSortBy = this.toggleSortBy.bind(this);
    this.selectFollower = this.selectFollower.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.bulkMessageSubmit = this.bulkMessageSubmit.bind(this);
    this.onFollowClick = this.onFollowClick.bind(this);
    this.onUnFollowClick = this.onUnFollowClick.bind(this);
    this.handleFollowResponse = this.handleFollowResponse.bind(this);
    this.selectAllClick = this.selectAllClick.bind(this);
    this.clearErrorMessage = this.clearErrorMessage.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
    this.onNext = this.onNext.bind(this);
    this.onPrev = this.onPrev.bind(this);
  }

  componentWillMount() {
    const { actions, isAuthenticated } = this.props;

    actions.followersRequest(isAuthenticated);
  }

  componentWillReceiveProps(nextProps){
    const { isSubmitting, messages } = nextProps;

    if(!isSubmitting && this.props.isSubmitting && messages !== this.props.messages){
      toastr.success('Your message has been sent.');
    }
  }

  toggleSortBy() {
    this.setState({ isSortByOpen: !this.state.isSortByOpen });
  }

  clearErrorMessage() {
    let { error } = this.state;
    error = {};

    this.setState({ error });
  }

  handleFollowResponse(id){
    this.setState(prevState => ({
      selected: prevState.selected.filter(e => e !== id)
    }));
  }

  onUnFollowClick() {
    if (this.state.selected.length === 0) {
      toastr.error('You must select at least one person');
      return;
    }

    let updates = [];
    let notFollowing = [];
    const { selected, error, allSelected} = this.state;
    const { actions, following, followers, isAuthenticated } = this.props;

    selected.forEach((id) => {
      const itemRecord = following.data.find(item => item.user.id === id);

      if (itemRecord) {
        updates.push(itemRecord);

        actions.deleteFollowing(itemRecord.record)
          .then(() => {
            this.setState({
              isDisabledFollow: false
            });
          });
      } else {
        const followerRecord = followers.data.find(item => item.id === id);

        if (followerRecord) notFollowing.push(followerRecord.name);
      }

      if(updates.length>0) this.props.actions.followingRequest(isAuthenticated);

      this.handleFollowResponse(id);
    });

    if (notFollowing.length > 0) {
      error['follow'] = `You are already not following: ${notFollowing.join()}`;

      this.setState({ error });
    }

    this.setState({ allSelected: ! allSelected });
  }

  onFollowClick() {
    if (this.state.selected.length === 0) {
      toastr.error('You must select at least one person');
      return;
    }

    let updates = [];
    let amFollowing = [];
    const { selected, error, allSelected } = this.state;
    const { actions, following, isAuthenticated } = this.props;

    selected.forEach((id) => {
      const itemRecord = following.data.find(item => item.user.id === id);

      if (itemRecord) {
        amFollowing.push(itemRecord.user.name);
      } else {
        updates.push(id);

        const hisProfile = { 'id': id };

        actions.saveFollower(hisProfile)
          .then(() => {
            this.setState({
              isDisabledFollow: true,
            });
            this.props.actions.followingRequest(isAuthenticated);
          });
      }

      this.handleFollowResponse(id);
    });

    if (amFollowing.length > 0) {
      error['follow'] = `You are already following: ${amFollowing.join()}`;

      this.setState({ error });
    }

    this.setState({ allSelected: ! allSelected });
  }

  selectAllClick(allSelected) {
    this.setState({ allSelected: !allSelected });

    if (allSelected) {
      this.setState({ selected: [] });
      return;
    }

    const followersArray = this.props.followers.data.map((item) => {
      return item.user.id;
    });

    this.setState((prevState) => {
      return { selected: Array.from(new Set([...prevState.selected, ...followersArray])) };
    });
  }

  selectFollower(id,add){
    const {selected} = this.state;

    let foundIndex = selected.findIndex((element) => {
      return (element === id);
    });

    if (foundIndex === -1 && add) {
      let all = false;

      if (this.props.followers.data.length === this.state.selected.length + 1) {
        all = true;
      }

      this.setState(prevState => ({
        allSelected: all,
        selected: [...prevState.selected, id],
      }));
    } else if (foundIndex !== -1 && !add) {
      this.setState(prevState => ({
        allSelected: false,
        selected: prevState.selected.filter(e => e !== id)
      }));
    }
  }

  toggleModal() {
    if (this.state.selected.length === 0) {
      toastr.error('You must select at least one recipient');
      return;
    }

    const message = { recipients: [], subject: '', body: '' };

    this.setState({
      message,
      modalOpen: !this.state.modalOpen
    });
  }

  onMessageChange(event) {
    const field = event.target.name;
    const { message } = this.state;

    message[field] = event.target.value;

    this.setState({ message });

    this.isValid(message);
  }

  isValid(value) {
    const { error } = this.state;

    this.setState({ error: {} });

    if (!value.body.length > 0) {
      error['body'] = 'Message cannot be empty';
      this.setState({ error });
    }

    if (!value.subject.length > 0) {
      error['subject'] = 'Subject cannot be empty';
      this.setState({ error });
    }

    return (value.subject.length > 0) && (value.body.length > 0);
  }

  onMessageSubmit(id) {
    const { message } = this.state;

    if (!this.isValid(message)) return;

    message.recipients = [id];
    this.props.actions.sendMessage(message);
  }

  handleResponse() {
    const message = { recipients: [], subject: '', body: '' };

    this.setState({
      message,
      allSelected: !this.state.allSelected,
      selected: []
    });

    this.toggleModal();
  }

  bulkMessageSubmit(){
    const { selected, message } = this.state;

    if (!this.isValid(message)) return;

    message.recipients = [...selected.map(id => id)];
    this.props.actions.sendMessage(message);
    this.handleResponse();
  }

  onNext(){
    const { actions, following, isAuthenticated } = this.props;
    const { page } = following.paginationData;
    actions.clearFollowingData();
    return actions.followersRequest(isAuthenticated, null, page+1);
  }

  onPrev(){
    const { actions, following, isAuthenticated } = this.props;
    const { page } = following.paginationData;
    actions.clearFollowingData();
    return actions.followersRequest(isAuthenticated, null, page-1);
  }

  onSelectPage(page){
    return () => {
      const { actions, isAuthenticated } = this.props;
      actions.clearFollowingData();

      return actions.followersRequest(isAuthenticated, null, page);
    };
  }

  render() {
    const { followers, profile } = this.props;
    const { page, page_count } = followers.paginationData;
    const { isSortByOpen, selected, error, allSelected } = this.state;

    return (
      <div className="col-lg-12">
        <Helmet title="Your Followers"/>
        {error['follow'] &&
          <AlertBox
            error={error['follow']}
            clearErrorMessage={this.clearErrorMessage} />
        }

        <FollowersList
          profile={profile}
          followers={followers}
          isSortByOpen={isSortByOpen}
          toggleSortBy={this.toggleSortBy}
          selectFollower={this.selectFollower}
          toggleModal={this.toggleModal}
          onFollowClick={this.onFollowClick}
          onUnFollowClick={this.onUnFollowClick}
          selected={selected}
          selectAllClick={this.selectAllClick}
          onNext={this.onNext}
          onPrev={this.onPrev}
          onSelectPage={this.onSelectPage}
          page={page}
          page_count={page_count}
          allSelected={allSelected} />

        <MessageModal
          toggleModal={this.toggleModal}
          modalOpen={this.state.modalOpen}
          error={error}
          isSubmitting={this.props.isSubmitting}
          message={this.state.message}
          onMessageChange={this.onMessageChange}
          onMessageSubmit={this.bulkMessageSubmit} />

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.auth;
  const message = { user: '', subject: '', message: '' };

  return {
    message,
    isAuthenticated,
    profile: state.profile.data,
    followers: state.followers,
    following: state.following,
    messages: state.messages.data,
    isSubmitting: state.messages.isSubmitting,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    followersActions,
    messageActions,
    followingActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Followers);
