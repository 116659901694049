import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class Research extends Component {
  render() {
    return (
      <div className="interior info">
        <Helmet title="Research"/>
        <div className="container">
          <div className="row">
            <div className="content research" style={{paddingTop: 50}}>
              <div className="info-title">
                <h2 className="mb-4">Research Reports</h2>
                <h3>A Sample of Research on the Essential Workplace Skills Critical to Employers &amp; the Economy</h3>
                <p className="small mb-2"><em>*These skills are often called “Soft Skills,” “Employability Skills,” “Non-Cognitive Skills,” “21st Century Skills,” “Portable Skills,” “Enterprise Skills,” “Social-Emotional Skills,” “Transferable Skills,” and many other names.</em></p>
              </div>

              <div className="sample-panel">
                <h5><a href="https://www.mckinsey.com/industries/public-and-social-sector/our-insights/defining-the-skills-citizens-will-need-in-the-future-world-of-work#" target="_blank" rel="noopener noreferrer">Defining the Skills Citizens Will Need in the Future World of Work</a></h5>
                <p className="mb-0">McKinsey & Company identified a set of 56 "foundational skills" needed across all industries that benefit every citizen such as critical thinking, communication and teamwork. The research shows that higher proficiency in them is already associated with a higher likelihood of employment, higher incomes, and job satisfaction.</p>
              </div>

              <div className="sample-panel">
                <h5><a href="https://www.wsj.com/articles/employers-find-soft-skills-like-critical-thinking-in-short-supply-1472549400" target="_blank" rel="noopener noreferrer">Employers Find ‘Soft Skills’ Like Critical Thinking in Short Supply</a></h5>
                <p className="mb-0">92% of executives say soft skills are equal to or more important than technical skills.</p>
              </div>

              <div className="sample-panel">
                <h5><a href="http://www.nationalsoftskills.org/the-soft-skills-disconnect/" target="_blank" rel="noopener noreferrer">The Soft Skills Disconnect</a></h5>
                <p className="mb-0">Research conducted by Harvard University, the Carnegie Foundation and Stanford Research Center has concluded that 85% of job success comes from having well‐developed soft and people skills, and only 15% of job success comes from technical skills and knowledge.</p>
              </div>

              <div className="sample-panel">
                <h5><a href="https://www.leadershipiq.com/blogs/leadershipiq/35354241-why-new-hires-fail-emotional-intelligence-vs-skills" target="_blank" rel="noopener noreferrer">Why New Hires Fail (Emotional Intelligence vs. Skills)</a></h5>
                <p className="mb-0">After tracking 20,000 newly hired employees at 312 private, public, healthcare, and business organizations, study shows 40% of new hires fail within 18 months because of inadequate soft skills.</p>
              </div>

              <div className="sample-panel">
                <h5><a href="http://unitedwayswva.org/wp-content/uploads/2019/07/Building-Tomorrows-Talent-Collaboration-Can-Close-Emerging-Skills-Gap.pdf" target="_blank" rel="noopener noreferrer">Building Tomorrow’s Talent: Collaboration Can Close Emerging Skills Gap</a></h5>
                <p className="mb-0">Report asserts that soft skills such as adaptability and complex problem-solving are more important than ever for recent college grads, mid-career professionals, and seasoned executives.</p>
              </div>

              <div className="sample-panel">
                <h5><a href="https://cdn2.hubspot.net/hubfs/3409306/Hanover-Research-Higher-Ed-Trend-Report-2019.pdf" target="_blank" rel="noopener noreferrer">Hanover Research - 2019 Trends in Higher Education</a></h5>
                <p className="mb-0">Research report states employers are united in their opinion that recent graduates lack necessary soft skills and that to bridge the gap, institutions are increasingly focusing on soft skill development with their students.</p>
              </div>

              <div className="sample-panel">
                <h5><a href="https://www.nber.org/papers/w21473" target="_blank" rel="noopener noreferrer">The Growing Importance of Social Skills in the Labor Market</a></h5>
                <p className="mb-0">Since 1980, jobs with high social skill requirements have experienced greater relative growth throughout the wage distribution.</p>
              </div>

              <div className="sample-panel">
                <h5><a href="http://www.naceweb.org/uploadedfiles/pages/knowledge/articles/career-readiness-fact-sheet.pdf" target="_blank" rel="noopener noreferrer">Career Readiness for the New College Graduate: A Definition and Competencies</a></h5>
                <p className="mb-0">Task force representatives from both higher education and the corporate world, identified competencies that define “career readiness” which comprise of “soft skills.”</p>
              </div>

              <div className="sample-panel">
                <h5><a href="http://www.burning-glass.com/wp-content/uploads/Human_Factor_Baseline_Skills_FINAL.pdf" target="_blank" rel="noopener noreferrer">The Human Factor: The Hard Time Employers Have Finding Soft Skills</a></h5>
                <p className="mb-0">Report shows soft skills are in high-demand yet undersupplied in the labor market.</p>
              </div>

              <div className="sample-panel">
                <h5><a href="https://files.eric.ed.gov/fulltext/ED519465.pdf" target="_blank" rel="noopener noreferrer">Are They Really Ready to Work? Employers’ Perspectives on the Basic Knowledge and Applied Skills of New Entrants to the 21st Century U.S. Workforce</a></h5>
                <p className="mb-0">An in-depth survey of over 400 employers across the U.S. indicated that, according to employers, many new workforce entrants lack skills essential to job success. Among the most important skills cited by employers were (1) Professionalism/Work Ethic, (2) Oral and Written Communications, (3) Teamwork/Collaboration, and (4) Critical Thinking/Problem Solving.</p>
              </div>

              <div className="sample-panel">
                <h5><a href="http://blog.adeccousa.com/the-skills-gap-and-the-state-of-the-economy/" target="_blank" rel="noopener noreferrer">State of the Economy and Employment Survey from Adecco Staffing U.S.</a></h5>
                <p className="mb-0">This 2013 study revealed that 92% of senior executives in the U.S. acknowledged the existence of a serious gap in workforce skills. Additionally, 44% of respondents in this study cited soft skills, such as communication, critical thinking, creativity, and collaboration, as the area with the biggest gap.</p>
              </div>

              <div className="sample-panel">
                <h5><a href="https://www.fya.org.au/wp-content/uploads/2018/06/FYA_TheNewWorkReality_sml.pdf" target="_blank" rel="noopener noreferrer">The New Work Reality</a></h5>
                <p className="mb-0">Following 14,000 young people over a decade, this report reveals the factors that accelerate the transition to full-time work. The number one factor was found to be building “enterprise skills” in education.</p>
              </div>

              <div className="sample-panel">
                <h5><a href="https://www.fya.org.au/wp-content/uploads/2016/04/The-New-Basics_Update_Web.pdf" target="_blank" rel="noopener noreferrer">The New Basics: Big data Reveals the Skills Young People Need for the New Work Order</a></h5>
                <p className="mb-0">Report confirms employers demand "enterprise skills,” such as problem solving, creativity, communications, teamwork, critical thinking, etc.</p>
              </div>

              <div className="sample-panel">
                <h5><a href="http://www.hamiltonproject.org/assets/files/seven_facts_noncognitive_skills_education_labor_market.pdf" target="_blank" rel="noopener noreferrer">The Hamilton Project: Seven Facts on Noncognitive Skills from Education to the Labor Market</a></h5>
                <p className="mb-0">U.S. economy is demanding more noncognitive skills and these skills strongly improve labor-market outcomes.</p>
              </div>

              <div className="sample-panel">
                <h5><a href="https://www.aacu.org/sites/default/files/files/LEAP/2015employerstudentsurvey.pdf" target="_blank" rel="noopener noreferrer">Falling Short? College Learning and Career Success. Selected Findings from Online Surveys of Employers and College Students Conducted on Behalf of the Association of American Colleges & Universities</a></h5>
                <p className="mb-0">Employers and college students indicate “soft skills” are most important to long-term career success. However, employers say college graduates are less prepared in important “soft skills” areas while graduates feel they are better prepared.</p>
              </div>

              <div className="sample-panel">
                <h5><a href="http://www3.weforum.org/docs/WEFUSA_NewVisionforEducation_Report2015.pdf" target="_blank" rel="noopener noreferrer">New Vision for Education: Unlocking the Potential of Technology</a></h5>
                <p className="mb-0">A study of nearly 100 countries reveals large gaps in selected indicators for many of the 16 most critical "21st-century skills." These gaps are clear signs that too many students are not getting the education they need to prosper in the 21st century and countries are not finding enough of the skilled workers they need to compete.</p>
              </div>

              <div className="sample-panel">
                <h5><a href="https://www.shrm.org/hr-today/trends-and-forecasting/research-and-surveys/pages/entry-level-applicant-job-skills-survey-.aspx" target="_blank" rel="noopener noreferrer">SHRM/MERCER Entry-Level Applicant Job Skills Survey</a></h5>
                <p className="mb-0">HR professionals say skills such as dependability and reliability, integrity, respect, and teamwork were "very" or "extremely" important when hiring for an entry-level position.</p>
              </div>

              <div className="sample-panel">
                <h5><a href="https://www.uschamberfoundation.org/sites/default/files/Bridging%20The%20Soft%20Skills%20Gap_White%20Paper%20FINAL_11.2.17%20.pdf" target="_blank" rel="noopener noreferrer">Bridging the Soft Skills Gap: How the Business and Education Sectors are Partnering to Prepare Students for the 21st Century Workforce</a></h5>
                <p className="mb-0">A growing number of employers across industries report that job applicants lack the basic skills needed to succeed in the workforce.</p>
              </div>

              <div className="sample-panel">
                <h5><a href="https://www.iyfnet.org/sites/default/files/library/Getting_Youth_In_The_Door_Soft_Skills_Report.pdf" target="_blank" rel="noopener noreferrer">Getting Youth in the Door: Defining Soft Skills Requirements for Entry-level Service Sector Jobs</a></h5>
                <p className="mb-0">Growing concern of businesses is that entry-level candidates lack critical competencies for entry-level employment in service sector jobs.</p>
              </div>

              <div className="sample-panel">
                <h5><a href="https://campustechnology.com/articles/2018/06/28/students-feel-more-secure-about-getting-jobs-but-skills-gaps-persist.aspx" target="_blank" rel="noopener noreferrer">McGraw-Hill Education Future Workforce Survey</a></h5>
                <p className="mb-0">A gap exists between how prepared students consider themselves for work in specific skill areas and how employers perceive student readiness. The widest gap was in career management skills.</p>
              </div>

              <div className="sample-panel">
                <h5><a href="https://www.epi.org/publication/the-need-to-address-noncognitive-skills-in-the-education-policy-agenda/" target="_blank" rel="noopener noreferrer">The Need to Address Noncognitive Skills in the Education Policy Agenda</a></h5>
                <p className="mb-0">Argues noncognitive skills are so critical, they should be an explicit pillar of education policy.</p>
              </div>

              <div className="sample-panel">
                <h5><a href="https://www.forbes.com/sites/dinamedland/2015/06/30/u-k-industry-in-call-to-create-a-formal-framework-for-soft-skills/#74299c845e27" target="_blank" rel="noopener noreferrer">U.K. Industry In Call To Create A Formal Framework For 'Soft Skills'</a></h5>
                <p className="mb-0">Cites a 2015 report from the Development Economics research group that identified five linked soft skillsets as vital for all UK workers and "worth over £88 billion in Gross Value Added to the UK economy each year.”</p>
              </div>

              <div className="sample-panel">
                <h5><a href="http://millennialbranding.com/2012/millennial-branding-student-employment-gap-study/" target="_blank" rel="noopener noreferrer">Millennial Branding Student Employment Gap Study</a></h5>
                <p className="mb-0">Study of 225 companies revealed five skills employers identified as most important / hardest to find were soft skills.</p>
              </div>

              <div className="sample-panel">
                <h5><a href="https://www.markle.org/sites/default/files/State-of-American-Jobs.pdf" target="_blank" rel="noopener noreferrer">The State of American Jobs: How the Shifting Economic Landscape is Reshaping Work and Society and Affecting the Way People Think About the Skills and Training They Need to Get Ahead</a></h5>
                <p className="mb-0">Based on a Pew Research Center survey of employed U.S. adults, conducted in association with the Markle Foundation, this research reveals the belief that "people skills" are extremely important to do their job.</p>
              </div>

              <div className="sample-panel">
                <h5><a href="https://www.acteonline.org/wp-content/uploads/2018/05/ReadinessForAllCareers-FactSheet.pdf" target="_blank" rel="noopener noreferrer">Readiness for All Careers Factsheet</a></h5>
                <p className="mb-0">Compiled research asserts the top 10 skills employers need are soft skills.</p>
              </div>

              <div className="sample-panel">
                <h5><a href="https://hbr.org/2018/05/automation-will-make-lifelong-learning-a-necessary-part-of-work" target="_blank" rel="noopener noreferrer">Automation Will Make Lifelong Learning a Necessary Part of Work</a></h5>
                <p className="mb-0">Summarizes research from McKinsey & Company showing the rising demand for “soft skills” education systems are not set up to impart.</p>
              </div>

              <div className="sample-panel">
                <h5><a href="https://slideplayer.com/slide/8778605/" target="_blank" rel="noopener noreferrer">Fitting In, Standing Out and Building Remarkable Work Teams</a></h5>
                <p className="mb-0">67% of HR managers said they’d hire a candidate with strong soft skills even if technical abilities were lacking, while just 9% would hire someone with strong technical credentials but weak soft skills.</p>
              </div>

            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default Research;
