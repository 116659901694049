import React from 'react';

const RestrictedWebpageSection = () => {
  return (
    <div className="col-lg-12">
      <div className="restricted-view-container">
        <div className="lock-icon-container">
          <span className="fa fa-lock"/>
          <p>This user is private.</p>
        </div>
      </div>
    </div>
  );
};

export default RestrictedWebpageSection;
