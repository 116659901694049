import React from 'react';

import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { AsyncPaginate } from 'react-select-async-paginate';

import { IR_FORM } from '../../../utilConstants';

const Interests = (props) => {
  const {
    index,
    inputData,
    handleRemoveAll,
    handleRemoveClick,
    clusterError = [],
    hideOccupation,
    clusterId,
    industryId,
    loadOptions,
    onChangeCareerClusters
  } = props;

  return (
    <div>
      <div key={index} className="formRowWrapper mb-0">
        <div className="formRow justify-content-between pl-0 pr-2 py-2 mb-1">
          <AsyncPaginate
            cacheOptions
            isSearchable
            name={`cluster-${index}`}
            index={index}
            classNamePrefix="select"
            className={`basic-single ${
              !isEmpty(clusterError) && clusterError[0].field === 'cluster'
                ? 'is-invalid'
                : ''
            }`}
            additional={{ page: 1, name: 'cluster', index }}
            isClearable={false}
            loadOptions={loadOptions}
            placeholder={IR_FORM.careerClusterPlaceholder}
            hideSelectedOptions={false}
            value={inputData.cluster || ''}
            onChange={onChangeCareerClusters} />

          <AsyncPaginate
            cacheOptions
            isSearchable
            name={`industry-${index}`}
            cacheUniqs={[clusterId]}
            classNamePrefix="select"
            className={`basic-single ${
              !isEmpty(clusterError) && (
                clusterError[0].field === 'cluster' || clusterError[0].field === 'industry')
                ? 'is-invalid'
                : ''
            }`}
            additional={{ page: 1, name: 'industry', index }}
            isClearable={false}
            loadOptions={loadOptions}
            placeholder={IR_FORM.chooseIndustryPlaceholder}
            hideSelectedOptions={false}
            value={inputData.industry || ''}
            onChange={onChangeCareerClusters} />

          {!hideOccupation &&
            <AsyncPaginate
              cacheOptions
              isSearchable
              name={`occupation-${index}`}
              cacheUniqs={[industryId]}
              classNamePrefix="select"
              className={`basic-single ${
                !isEmpty(clusterError) && (
                  clusterError[0].field === 'cluster' || clusterError[0].field === 'industry' || clusterError[0].field === 'occupation'
                )
                  ? 'is-invalid'
                  : ''
              }`}
              additional={{ page: 1, name: 'occupation', index }}
              isClearable={false}
              loadOptions={loadOptions}
              placeholder={IR_FORM.chooseOccupationPlaceholder}
              hideSelectedOptions={false}
              value={inputData.occupation || ''}
              onChange={onChangeCareerClusters} />}
        </div>
        <div>
          {index === 0 && (
            <span
              className="btn btn-link myFeedbackLink font12 d-flex align-items-center px-2 w-65px"
              onClick={handleRemoveAll}>
              Clear All
            </span>
          )}
          {index > 0 && (
            <span
              className="btn btn-link myFeedbackLink font12 d-flex align-items-center px-2"
              data-value={index}
              onClick={handleRemoveClick}>
              <i className="fa fa-trash" />
              &nbsp;Delete
            </span>
          )}
        </div>
      </div>

      {!isEmpty(clusterError) && (
        <div>
          <div className="form-control-feedback mt-0 mb-2">
            {clusterError[0].message}
          </div>
        </div>
      )}
    </div>
  );
};

Interests.propTypes = {
  index: PropTypes.number,
  inputData: PropTypes.object,
  handleRemoveAll: PropTypes.func,
  handleRemoveClick: PropTypes.func,
  clusterError: PropTypes.array,
  hideOccupation: PropTypes.bool,
  clusterId: PropTypes.string.isRequired,
  industryId: PropTypes.string,
  loadOptions: PropTypes.func.isRequired,
  onChangeCareerClusters: PropTypes.func.isRequired
};

export default Interests;
