import PropTypes from 'prop-types';
import React from 'react';

const PostLikeButton = ({ onLike, post, isLikingPost}) => {
  const postLikeCondition = post && post.liked;

  if (postLikeCondition) {
    return(
      <a
        className="clickable"
        onClick={onLike}>
        <span className="liked">
          <i className="fa fa-heart" />
          {
            isLikingPost ? 'Unliking ...':'Unlike'
          }
        </span>
      </a>
    );
  }

  return(
    <a
      className="like-btn"
      onClick={onLike}>
      <i className="fa fa-heart" />
      <span>
        {
          isLikingPost ? 'Liking ...':'Like'
        }
      </span>
    </a>
  );
};
PostLikeButton.propTypes = {
  onLike: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  isLikingPost: PropTypes.bool
};
export default PostLikeButton;
