import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

const DeleteNoteModal = ({ onCancel, onConfirm, isDeleting, isOpen}) => {
  const { status } = isDeleting;

  return(
    <Modal
      size="lg"
      className="modal-margin-top"
      backdrop="static"
      isOpen={isOpen}
      toggle={onCancel}>
      <ModalHeader
        className="no-border"
        toggle={onCancel}>Delete Note</ModalHeader>
      <ModalBody>
        <p>
          Are you sure you want to delete this note permanently?
        </p>
      </ModalBody>
      <ModalFooter className="no-border">
        <button
          onClick={onCancel}
          disabled={status}
          className="btn btn-secondary float-left">
          Cancel
        </button>

        <button
          disabled={status}
          onClick={onConfirm}
          className="btn btn-primary">
          {status ? 'Deleting...' : 'Delete'}
        </button>
      </ModalFooter>
    </Modal>
  );
};

DeleteNoteModal.propTypes = {
  isDeleting: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default DeleteNoteModal;
