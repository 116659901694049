import PropTypes from 'prop-types';
import React from 'react';
import { isStringOrObject } from '../../proptypes';

const Button = ({
  disabled,
  type,
  styles,
  buttonClass,
  buttonText,
  buttonId,
  dataToggle,
  ariaHasPopUp,
  ariaExpanded,
  onClick
}) => {
  let className = 'btn ';
  let defaultStyles = { marginLeft: 10 };

  buttonClass ? className += buttonClass : className;

  if (styles) {
    styles = Object.assign(defaultStyles, styles);
  } else {
    styles = defaultStyles;
  }

  return (
    <button
      onClick={onClick}
      id={buttonId}
      disabled={disabled}
      type={type || 'submit'}
      style={styles}
      className={className}
      data-toggle={dataToggle}
      aria-haspopup={ariaHasPopUp}
      aria-expanded={ariaExpanded}>
      {buttonText}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  styles: PropTypes.object,
  buttonClass: PropTypes.string,
  buttonText: isStringOrObject,
  buttonId: PropTypes.string,
  dataToggle: PropTypes.string,
  ariaHasPopUp: PropTypes.string,
  ariaExpanded: PropTypes.string
};

export default Button;
