import { isNull } from 'lodash';

import { CALL_API } from '../../middlewares/api';

import {
  RESET_JOB_BOARD,
  FETCH_MY_JOBS_REQUEST,
  FETCH_MY_JOBS_SUCCESS,
  FETCH_MY_JOBS_FAILURE,
  DELETE_JOB_FROM_BOARD_REQUEST,
  DELETE_JOB_FROM_BOARD_SUCCESS,
  DELETE_JOB_FROM_BOARD_FAILURE,
  UPDATE_JOB_DETAILS_REQUEST,
  UPDATE_JOB_DETAILS_SUCCESS,
  UPDATE_JOB_DETAILS_FAILURE,
  POST_NEW_JOB_REQUEST,
  POST_NEW_JOB_SUCCESS,
  POST_NEW_JOB_FAILURE,
} from '../../constants';

export const resetJobBoard = () => {
  return { type: RESET_JOB_BOARD };
};

export const fetchAllJobs = (userId, page = 1, sort = null, status = null) => {
  let endpoint = `job/job?user=${userId}&page=${page}`;

  endpoint = !isNull(sort) ? `${endpoint}&sort=${sort}` : endpoint;
  endpoint = !isNull(status) ? `${endpoint}&status=${status}` : endpoint;

  return {
    [CALL_API]: {
      authenticated: true,
      endpoint,
      method: 'get',
      types: [
        FETCH_MY_JOBS_REQUEST,
        FETCH_MY_JOBS_SUCCESS,
        FETCH_MY_JOBS_FAILURE,
      ],
    },
  };
};

export const postNewJob = (data) => {
  const endpoint = 'job/job';

  return {
    [CALL_API]: {
      authenticated: true,
      data,
      endpoint,
      method: 'post',
      types: [POST_NEW_JOB_REQUEST, POST_NEW_JOB_SUCCESS, POST_NEW_JOB_FAILURE],
    },
  };
};

export const updateJobDetails = (data) => {
  const { id: jobId } = data;

  const endpoint = `job/job/${jobId}`;

  return {
    [CALL_API]: {
      authenticated: true,
      id: jobId,
      data,
      endpoint,
      method: 'put',
      types: [
        UPDATE_JOB_DETAILS_REQUEST,
        UPDATE_JOB_DETAILS_SUCCESS,
        UPDATE_JOB_DETAILS_FAILURE,
      ],
    },
  };
};

export const deleteJobFromBoard = (jobId) => {
  const endpoint = `job/job/${jobId}`;

  return {
    [CALL_API]: {
      authenticated: true,
      id: jobId,
      endpoint,
      method: 'delete',
      types: [
        DELETE_JOB_FROM_BOARD_REQUEST,
        DELETE_JOB_FROM_BOARD_SUCCESS,
        DELETE_JOB_FROM_BOARD_FAILURE,
      ],
    },
  };
};
