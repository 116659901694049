import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import UserAvatarNameCard from '../../pure-components/UserAvatarNameCard';

import { isEmpty, isUndefined } from 'lodash';

const SkillbadgesSideMenu = ({ profile, skillBadge, changeLayout, layout, isVisiting }) => {
  const [ ,path, , userId] = location.pathname.split('/');
  const [ ,userName] = location.search.split('=');

  let ownerName = '';
  if (!isEmpty(userId)) {
    ownerName = `${decodeURIComponent(userName)}'s`;

    if (isUndefined(userName)) {
      ownerName = `${profile.name}'s`;
    }

    profile = profile?.requestorDetails;
  } else {
    ownerName = isVisiting ? `${profile.name}'s` : 'My';
  }

  return(
    <div className="border-bottom pb-2 mb-3">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="d-flex align-items-center">
          {profile && <UserAvatarNameCard profile={profile}/>}
          <span className="ml-2">{path === 'portfolio' ? ` ${ownerName} Career Portfolio` : ` ${ownerName} Skill Badges`}</span>
        </h2>
        {
          skillBadge &&
            <div className="view-selector">
              <a
                onClick={changeLayout('grid')}
                className={classNames('clickable grid-view-btn',{
                  'active': layout === 'grid'
                })}>
                <span className="fa fa-th"/>
              </a>
              <a
                onClick={changeLayout('list')}
                className={classNames('clickable list-view-btn',{
                  'active': layout === 'list'
                })}>
                <span className="fa fa-bars"/>
              </a>
            </div>
        }
      </div>
    </div>
  );
};

SkillbadgesSideMenu.propTypes = {
  profile: PropTypes.object.isRequired,
  skillBadge: PropTypes.bool,
  changeLayout: PropTypes.func,
  layout: PropTypes.string,
  isVisiting: PropTypes.bool
};

export default SkillbadgesSideMenu;
