import React from 'react';
import PropTypes from 'prop-types';

import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

const GroupRow = ({
  group,
  isOpenGroupAction,
  toggleGroupActions,
  onUpdateGroup,
  onUpdateGroupCode
}) => {
  return (
    <tr>
      <td>{group.name}</td>
      <td>{group.inviteCode}</td>
      <td style={{textAlign: 'center'}}>
        <div className="dropdown">
          <ButtonDropdown
            isOpen={(group.id === isOpenGroupAction)}
            toggle={toggleGroupActions(group.id)}>
            <DropdownToggle caret color="primary">
              Actions
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={onUpdateGroup(group)}>
                Edit Name
              </DropdownItem>
              <DropdownItem onClick={onUpdateGroupCode(group)}>
                Update Group Code
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </div>
      </td>
    </tr>
  );
};

GroupRow.propTypes = {
  group: PropTypes.object.isRequired,
  toggleGroupActions: PropTypes.func.isRequired,
  isOpenGroupAction: PropTypes.string.isRequired,
  onUpdateGroup: PropTypes.func.isRequired,
  onUpdateGroupCode: PropTypes.func.isRequired
};

export default GroupRow;
