import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Parser from 'html-react-parser';
import Slider from 'react-slick';
import classNames from 'classnames';

const settings = {
  dots: true,
  infinite: false,
  arrows:true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  adaptiveHeight: true,
  swipe:true,
  responsive: [
    { breakpoint: 560,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:false
      }
    }
  ]
};



class CustomSlider extends Component {
  static propTypes = {
    content: PropTypes.array.isRequired,
    unit: PropTypes.object.isRequired,
    onPersonalValuesSeeExamples: PropTypes.func,
    title: PropTypes.string
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      activeSlideIndex: 0,
      contentWithHyperlinks: []
    };

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.beforeChange = this.beforeChange.bind(this);
  }

  next() {
    const { content } = this.props;

    if ((content.length + 1) !== this.state.activeSlideIndex) {
      const activeSlideIndex = this.state.activeSlideIndex + 1;

      this.setState({ activeSlideIndex });
    }
  }

  beforeChange(oldIndex, newIndex){
    this.setState({
      activeSlideIndex: newIndex
    });
  }

  previous() {
    const { content } = this.props;

    if ((content.length + 1) !== this.state.activeSlideIndex) {
      const activeSlideIndex = this.state.activeSlideIndex - 1;

      this.setState({ activeSlideIndex });
    }
  }

  render() {
    const { content, onPersonalValuesSeeExamples, unit, title } = this.props;

    return(
      <div>
        <Slider
          {...settings}
          beforeChange={this.beforeChange}>
          {
            content.map((slide, index) => {
              let itemString = slide;
              const replacePersonalValuesModal = unit.slug === 'develop-self-awareness' && index === 6 &&
              title.search(new RegExp('Here are some ways you can define your core personal values:', 'i')) > -1;


              const modalButton = <span onClick={onPersonalValuesSeeExamples} className="clickable orange link">See examples of personal values</span>;

              if(replacePersonalValuesModal){
                itemString = itemString.replace('<a href="https://www.maxknowledge.com" target="_blank">See examples of personal values</a>.',
                  '');
                itemString = itemString.replace('<a href="https://www.maxknowledge.com/" target="_blank">See examples of personal values</a>.',
                  '');
              }
              return(
                <div key={index}>
                  <div className="slider-content">
                    <div className={classNames('panel-wrapper wow fadeIn', {
                      'inline-block': replacePersonalValuesModal
                    })}>
                      {Parser(itemString)}{replacePersonalValuesModal && modalButton}
                      {replacePersonalValuesModal && <span> to guide you.</span>}
                    </div>
                  </div>
                </div>
              );
            })
          }
        </Slider>
      </div>
    );
  }
}

export default CustomSlider;
