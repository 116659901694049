import PropTypes from 'prop-types';
import React from 'react';

const InstitutionCard = ({ profile }) => {
  return (
    <div>

      {profile.institution && profile.institution.length > 0 ?
        <div>{profile.institution}</div> : ''}

      {profile.program && profile.program.length > 0 ?
        `${profile.program}` : ''}

      {profile.major && profile.major.length > 0 ?
        ` | ${profile.major}` : ''}

    </div>
  );
};

InstitutionCard.propTypes = {
  profile: PropTypes.object.isRequired
};

export default InstitutionCard;
