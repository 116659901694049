import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

const DeleteFileConfirmModal = ({ fileId, onCancel, onConfirm, isDeleting}) => {
  const { status, id } = isDeleting;
  const isDeletingFile = status && fileId === id;

  if(isDeletingFile){
    return (
      <Modal
        size="lg"
        className="modal-margin-top"
        isOpen={fileId !== null}
        backdrop="static"
        toggle={onCancel}>
        <ModalBody>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <span>
              <i className="fa fa-spinner fa-pulse fa-2x fa-fw" />
            </span>
            <span style={{ paddingLeft: 10}}>
              Unlinking Portfolio File
            </span>
          </div>
        </ModalBody>
      </Modal>);
  }
  return(
    <Modal
      size="lg"
      className="modal-margin-top"
      isOpen={fileId !== null}
      toggle={onCancel}>
      <ModalHeader
        toggle={onCancel}>Unlink Portfolio File</ModalHeader>
      <ModalBody>
        <p>
          Are you sure you want to unlink this portfolio file from this Skill Badge?
          The file will remain in your portfolio but will no longer be linked as evidence for this Skill Badge.
        </p>
      </ModalBody>
      <ModalFooter>
        <button
          onClick={onCancel}
          className="btn btn-secondary">
          Cancel
        </button>

        <button
          onClick={onConfirm}
          className="btn btn-danger">
          Unlink
        </button>
      </ModalFooter>
    </Modal>
  );
};

DeleteFileConfirmModal.propTypes = {
  fileId: PropTypes.string,
  isDeleting: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default DeleteFileConfirmModal;
