import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import isEmpty from 'lodash/isEmpty';
import * as wallPostActions from '../../../redux/actions/wall-posts';
import * as discussionsActions from '../../../redux/actions/discussions';
import ShareModalCard from '../../../components/skill-builders/discussions/ShareModalCard';

class ShareModal extends Component {
  static propTypes = {
    unitId: PropTypes.string.isRequired,
    discussionShare: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      discussionShare: Object.assign(
        {},
        this.props.discussionShare,
        { permissions: '1', sharedQuotedText: '' }
      ),
      showMore: false,
      visibilityDropdownOpen: false
    };

    this.onChange = this.onChange.bind(this);
    this.onPost = this.onPost.bind(this);
    this.onShowMore = this.onShowMore.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSelectVisibilty = this.onSelectVisibilty.bind(this);
    this.onToggleVisibilityDropdown = this.onToggleVisibilityDropdown.bind(this);
  }

  closeModal() {
    this.props.actions.resetShareDiscussion();
  }

  onToggleVisibilityDropdown() {
    const { visibilityDropdownOpen } = this.state;
    this.setState({
      visibilityDropdownOpen: !visibilityDropdownOpen
    });
  }

  onSelectVisibilty(id){
    return () => {
      const wallShare = { ...this.state.wallShare, permissions:id};

      this.setState({
        wallShare,
        visibilityDropdownOpen: false
      });
    };
  }

  onChange(event) {
    const field = event.target.name;
    const { discussionShare } = this.state;

    discussionShare[field] = event.target.value;

    this.setState({ discussionShare });
  }

  onPost(event) {
    event.preventDefault();

    const { unitId: unit } = this.props;
    const { discussionShare } = this.state;
    const {
      post: postString,
      sharedQuotedText,
      id,
      sharedEntityType,
      sharedDiscussion,
      sharedSkillBuilderReflections
    } = discussionShare;

    if (sharedEntityType === 'skillBuilderReflections') {
      const reflectionArray = sharedSkillBuilderReflections.map(selected => selected.id);
      const refData = {
        unit,
        sharedQuotedText,
        post: postString,
        sharedSkillBuilderReflections: reflectionArray
      };

      return this.props.actions.saveUnitDiscussion(refData)
        .then(() => this.props.actions.resetShareDiscussion());
    }

    const post = (sharedDiscussion !== '') ? sharedDiscussion.post : postString;
    const discussionId = (sharedDiscussion !== '') ? sharedDiscussion.id : id;

    const data = {
      post,
      unit,
      sharedDiscussion: discussionId,
      sharedQuotedText
    };

    this.props.actions.saveUnitDiscussion(data)
      .then((response) => {    
        if(isEmpty(response.discussions.error)){
          toastr.success('', 'You successfully shared this as a post');
        }
        this.props.actions.resetShareDiscussion();
      });
  }

  onShowMore(){
    this.setState({
      showMore: !this.state.showMore
    });
  }

  render() {
    const { discussionShare, showMore, visibilityDropdownOpen } = this.state;
    const { isSubmitting, discussionShare: propsDiscussionshare } = this.props;

    return (
      <ShareModalCard
        hidePermissionsButton
        onPost={this.onPost}
        onShowMore={this.onShowMore}
        showMore={showMore}
        onChange={this.onChange}
        closeModal={this.closeModal}
        isSubmitting={isSubmitting}
        quote={discussionShare.sharedQuotedText}
        permissions={discussionShare.permissions}
        onSelectVisibilty={this.onSelectVisibilty}
        visibilityDropdownOpen={visibilityDropdownOpen}
        onToggleVisibilityDropdown={this.onToggleVisibilityDropdown}
        discussionShare={propsDiscussionshare}/>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    skillBuilders: { unit: { id } },
    discussions: { isSubmitting, discussionShare }
  } = state;

  return {
    unitId: id,
    isSubmitting,
    discussionShare,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, wallPostActions, discussionsActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareModal);
