import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as discussionActions from '../../../redux/actions/discussions';
import { toastr } from 'react-redux-toastr';
import { scrollToTop } from '../../../utils';
import classNames from 'classnames';

const styles = {
  icon: { marginRight: 5 }
};

class PostLikeButton extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    discussion: PropTypes.object,
    actions: PropTypes.object.isRequired,
    error: PropTypes.object,
    isSubmittingLike: PropTypes.object,
    isAuthenticated: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);

    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    event.preventDefault();

    const { userId, actions, discussion, isAuthenticated } = this.props;

    if (!isAuthenticated) {
      scrollToTop();
      return toastr.warning('Please Login/Signup');
    }

    if(discussion.liked){
      return actions.unlikeUnitDiscussion({discussion: discussion.id, userId});
    }

    return actions.saveUnitDiscussionLike({discussion: discussion.id});
  }

  render() {
    const { discussion, isSubmittingLike } = this.props;

    let postLikeText = discussion.liked ? 'Unlike' : 'Like';
    if (discussion && isSubmittingLike.discussionId === discussion.id) {
      postLikeText = `${postLikeText.slice(0, -1)}ing...`;
    }

    const iconStyles = discussion && discussion.likes > 0 ?
      Object.assign({}, styles.icon, { color: '#337ab7' }) : styles.icon;

    return (
      <a className="clickable" onClick={this.onClick} id={`like-button-${discussion.id}`}>
        <span className={classNames({
          'like-btn': !discussion.liked,
          'liked': discussion.liked
        })}>
          <i className="fa fa-heart" style={iconStyles}/>
          <span style={iconStyles}>{postLikeText}</span>
        </span>
      </a>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.discussions.error,
    userId: state.auth.data.currentUser.id,
    isSubmittingLike: state.discussions.isSubmittingLike,
    isAuthenticated: state.auth.isAuthenticated
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, discussionActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostLikeButton);
