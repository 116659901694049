import React from 'react';

import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';

import jobsConstants from '../../constants/jobs';

const JobSearchbar = ({
  search,
  onChange,
  onSubmit,
  isFinding,
  errors,
  stateId,
  countryId,
  loadOptions,
  onChangeDropdown,
  onClearKeywords,
  onClickReset,
  onChangeRadius
}) => {
  return (
    <div>
      <div className="jobboard-bar needs-validation">
        <div className="row">
          <div className="col">
            <div className="jobboard-form-container">
              <div className="jobboard-form-fields">
                <div className="form-group">
                  <label className="form-control-label mb-1">What</label>
                  <input
                    type="text"
                    name="query"
                    autoFocus
                    className="form-control"
                    value={search.query}
                    onChange={onChange}
                    placeholder="Job title, keywords or company name"/>
                  {(search.query.length !== 0) &&
                    <span
                      className="far fa-times"
                      onClick={onClearKeywords}
                      style={{
                        position: 'absolute',
                        zIndex: 9,
                        right: 15,
                        top: 41,
                        cursor: 'pointer'
                      }}/>}
                  {errors.query && <div className="invalid-feedback">{errors.query}</div>}
                </div>

                <div className="form-group">
                  <label className="form-control-label mb-1">Country</label>
                  <AsyncPaginate
                    cacheOptions
                    isSearchable
                    name="country"
                    isClearable={false}
                    additional={{ page: 1, name: 'country' }}
                    loadOptions={loadOptions}
                    placeholder="Country"
                    hideSelectedOptions={false}
                    value={search.country}
                    onChange={onChangeDropdown} />
                </div>

                <div className="form-group">
                  <label className="form-control-label mb-1">Region/State</label>
                  <AsyncPaginate
                    cacheOptions
                    isSearchable
                    name="state"
                    cacheUniqs={[countryId]}
                    isClearable={false}
                    additional={{ page: 1, name: 'state' }}
                    loadOptions={loadOptions}
                    placeholder="State"
                    hideSelectedOptions={false}
                    value={search.state}
                    onChange={onChangeDropdown} />
                </div>

                <div className="form-group">
                  <label className="form-control-label mb-1">City/Town</label>
                  <AsyncPaginate
                    cacheOptions
                    isSearchable
                    name="city"
                    cacheUniqs={[stateId]}
                    additional={{ page: 1, name: 'city' }}
                    isClearable={false}
                    loadOptions={loadOptions}
                    placeholder="City"
                    hideSelectedOptions={false}
                    value={search.city}
                    onChange={onChangeDropdown} />
                </div>

                <div className="form-group">
                  <label className="form-control-label mb-1" htmlFor="sort">Distance</label>
                  <ReactSelect
                    onChange={onChangeRadius}
                    clearable={false}
                    searchable={false}
                    simpleValue
                    value={search.radius}
                    options={jobsConstants.radiusOptions} />
                </div>
              </div>
              {/* Form Actions */}
              <div className="jobboard-form-options">
                <a href="#" className="btn btn-primary-outline text-orange" onClick={onClickReset}>Reset</a>
                <button
                  type="submit"
                  name="button"
                  className="btn btn-primary"
                  disabled={isFinding}
                  onClick={onSubmit}>
                  {isFinding ? 'Finding...' : 'Find Jobs'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

JobSearchbar.propTypes = {
  search: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isFinding: PropTypes.bool.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  stateId: PropTypes.string.isRequired,
  countryId: PropTypes.string.isRequired,
  loadOptions: PropTypes.func.isRequired,
  onChangeDropdown: PropTypes.func.isRequired,
  toggleRemoteJobs: PropTypes.func.isRequired,
  onChangeRadius: PropTypes.func.isRequired,
  onClickReset: PropTypes.func.isRequired,
  onClearKeywords: PropTypes.func.isRequired
};

export default JobSearchbar;
