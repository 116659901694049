import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isEmpty, isUndefined } from 'lodash';

import { openModal } from '../../redux/actions/components';
import { educatorSignUp } from '../../redux/actions/registration';

import { Helmet } from 'react-helmet';

import { withHooks } from '../../utils/withHooks';
import Validator from '../../validator';

import EducatorSignUpForm from '../../components/auth/EducatorSignUpForm';

const credentials = {
  firstName: '',
  lastName: '',
  roleType: '',
  organizationType: '',
  workEmail: '',
  alternateEmail: '',
  jobTitle: '',
  organization: ''
};

class EducatorSignUp extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    registration: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      credentials,
      displayAlertType: '',
      alternateEmailPopover: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onDismissAlert = this.onDismissAlert.bind(this);
    this.toggleLoginModal = this.toggleLoginModal.bind(this);
    this.onChangeCredentials = this.onChangeCredentials.bind(this);
    this.toggleAlternateEmailPopover = this.toggleAlternateEmailPopover.bind(this);
  }

  toggleLoginModal(event) {
    event.preventDefault();
    this.props.actions.openModal('login-modal');
  }

  toggleAlternateEmailPopover() {
    this.setState({ alternateEmailPopover: ! this.state.alternateEmailPopover });
  }

  isValid(field = null) {
    const { credentials, errors: prevErrors } = this.state;

    let validate = Validator.createValidator({
      firstName: ['required', 'minLength|1'],
      lastName: ['required', 'minLength|1'],
      roleType: ['required'],
      organizationType: ['required'],
      workEmail: ['required', 'email', 'workEmail'],
      alternateEmail: ['required', 'email', 'notMatch|workEmail'],
      jobTitle: ['required', 'minLength|1'],
      organization: ['required', 'minLength|1']
    }, credentials, field);

    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors) });

    return isValid;
  }

  onChangeCredentials(event) {
    const { name, value } = event.target;
    const credentials = Object.assign(
      {},
      this.state.credentials,
      { [name]: value }
    );

    this.setState({ credentials }, () => this.isValid(name));
  }

  onSubmit(event) {
    event.preventDefault();

    if (! this.isValid()) return;

    let { credentials: educatorObject } = this.state;

    educatorObject = Object.assign({}, educatorObject, { email: educatorObject.workEmail });

    delete educatorObject['workEmail'];

    this.props.actions.educatorSignUp(educatorObject)
      .then((state) => {
        const { registration } = state;
        let displayAlertType = 'success';

        if ((! isEmpty(registration.error)) && (! isUndefined(registration.error.errorType))) {
          displayAlertType = (registration.error.errorType === 'DuplicateEducatorRegistrationException') ? 'warning' : 'danger';
        }

        this.setState({ displayAlertType });
      })
      .catch((error) => {
        let displayAlertType = 'danger';

        if ((! isEmpty(error)) && (! isUndefined(error.errorType))) {
          displayAlertType = (error.errorType === 'DuplicateEducatorRegistrationException') ? 'warning' : displayAlertType;
        }

        this.setState({ displayAlertType });
      });
  }

  onKeyPress(event) {
    if (event.key === 'Enter') {
      this.onSubmit(event);
    }
  }

  onDismissAlert() {
    this.setState({ displayAlertType: '' });
  }

  render() {
    const {
      errors,
      credentials,
      displayAlertType,
      alternateEmailPopover
    } = this.state;

    const { registration, location: { pathname: routePath } } = this.props;

    return (
      <div style={{paddingBottom: 100}}>
        <Helmet title="Talent Developer Signup"/>
        <div className="container">
          <div className="row justify-content-md-center">
            <EducatorSignUpForm
              errors={errors}
              routePath={routePath}
              credentials={credentials}
              onSubmit={this.onSubmit}
              registration={registration}
              onKeyPress={this.onKeyPress}
              displayAlertType={displayAlertType}
              onChange={this.onChangeCredentials}
              onDismissAlert={this.onDismissAlert}
              toggleLoginModal={this.toggleLoginModal}
              alternateEmailPopover={alternateEmailPopover}
              toggleAlternateEmailPopover={this.toggleAlternateEmailPopover}/>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ registration }) => ({ registration });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    openModal,
    educatorSignUp
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(EducatorSignUp));
