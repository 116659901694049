import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { email } from '../../validator/rules';
import TextInput from '../../components/common/TextInput';
import Validator from '../../validator';
import * as reflectionActions from '../../redux/actions/reflections';
import * as componentActions from '../../redux/actions/components';
import { toastr } from 'react-redux-toastr';
import QuillEditor from '../../components/common/QuillEditor';
import Button from '../../components/common/Button';

const modules = {
  toolbar: [
    [{ 'align': [] }],
    ['bold', 'italic', 'underline'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    ['link'],
  ],
  clipboard: {
    matchVisual: false,
  }
};

const styles = {
  editorWrapper: {
    marginBottom: '1rem'
  }
};

class ShareReflectionViaEmails extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    reflection: PropTypes.object.isRequired,
    unit: PropTypes.object.isRequired,
    selectedReflections: PropTypes.array,
    reflectionResponses: PropTypes.object,
    closeModal: PropTypes.func,
    sharing: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      editorText: {
        message: this.props.reflection.template
      },
      reflection: Object.assign({}, this.props.reflection)
    };

    this.onChange = this.onChange.bind(this);
    this.onChangeEmails = this.onChangeEmails.bind(this);
    this.onChangeBody = this.onChangeBody.bind(this);
    this.onShareClick = this.onShareClick.bind(this);
    this.checkValidEmails = this.checkValidEmails.bind(this);
  }

  componentWillReceiveProps(nextProps){
    const { sharing: nextSharing } = nextProps;

    if(nextSharing.isSharing !== this.props.sharing.isSharing && !nextSharing.isSharing && nextSharing.successfullyShared){
      toastr.success('You\'ve successfully shared your reflection(s).');
      return this.props.closeModal();
    }
  }

  isValid(field = null) {
    const { reflection } = this.state;
    const rules = {
      emails: ['required'],
      message: ['required']
    };

    const validate = Validator.createValidator(rules, reflection, field);
    const { errors, isValid } = validate;

    this.setState({ errors });

    return isValid;
  }

  onChangeEmails(event) {
    const { value } = event.target;
    const { reflection} = this.state;

    reflection['emails'] = value;
    this.setState({ reflection }, () => this.checkValidEmails(value));
  }

  checkValidEmails(emails, shouldReturn){
    const emailArray = emails.split(',');
    const { errors } = this.state;

    if(emailArray.length > 0){
      const invalidEmailExist = emailArray.reduce((acc, next) => {
        const isInvalid = email(next.trim()) && email(next.trim()).length > 0;
        return isInvalid || acc;
      },false);

      this.setState({ errors : invalidEmailExist ? Object.assign({}, errors, {
        emails: emailArray.length > 1 ? 'Invalid emails': 'Invalid email'}) :
        Object.assign({}, errors, {
          emails: ''
        })
      });

      if(shouldReturn)
        return !invalidEmailExist;
    }
  }

  onChangeBody(value, ...params) {
    const { reflection, editorText} = this.state;

    reflection['message'] = value;
    editorText['message'] = params[2].getText().replace(/\r?\n|\r/gm, ' ');

    this.setState({ reflection, editorText }, () => this.isValid('message'));
  }

  onChange(event) {
    const { name, value } = event.target;
    const { reflection } = this.state;

    reflection[name] = value;

    this.setState({ reflection });

    this.isValid(name);
  }

  onShareClick(){
    const { reflection } = this.state;

    if(!this.isValid())
      return;

    if(! this.checkValidEmails(reflection.emails, true))
      return;

    const data= {
      subject: reflection.subject,
      body: reflection.message,
      emails: reflection.emails.split(',')
    };

    this.props.actions.shareReflections(data);


  }

  render() {
    const { reflection, errors } = this.state;
    const { closeModal, sharing } = this.props;

    return(
      <div className="seek-endorsement-content mt-3">
        <TextInput
          type="text"
          name="emails"
          error={errors.emails}
          value={reflection.emails}
          onChange={this.onChangeEmails}
          className="form-control emails"
          placeholder="Enter emails separated by commas "/>

        <TextInput
          type="text"
          name="subject"
          error={errors.subject}
          value={reflection.subject}
          onChange={this.onChange}
          placeholder="Subject"
          className="form-control emails"/>

        <div style={styles.editorWrapper}>
          <QuillEditor
            modules={modules}
            placeholder="Body of the email"
            onChange={this.onChangeBody}
            value={reflection.message}/>
        </div>
        <div
          className="modal-footer row pl-3 pr-3 pt-3 pb-0 mt-4">
          <Button
            type="button"
            onClick={closeModal}
            buttonClass="btn-secondary"
            buttonText="Cancel"/>
          <Button
            type="submit"
            onClick={this.onShareClick}
            buttonClass="btn btn-primary"
            buttonText={sharing.isSharing ? 'Sharing...' : 'Share'}/>
        </div>
      </div>
    );
  }
}

const getTemplate = (unit, selected, responses) => {
  const template = `
    Hi,<br/><br/>
I've joined Skill Builder, <strong><a href=${`https://www.careerprepped.com/skill-builders/${unit.slug}`}>${unit.code} ${unit.badge.name}</a></strong> on CareerPrepped. I wanted to share my answer(s) with you to the question(s) below from the Skill Builder:
<br/><br/>
${selected.includes('q1') ? `<strong>Question 1: ${unit.reflectone}</strong><br/>${responses.q1.answer}<br/><br/>`: ''}
${selected.includes('q2') ? `<strong>Question 2: ${unit.reflecttwo}</strong><br/>${responses.q2.answer}<br/><br/>`: ''}
${selected.includes('q3') ? `<strong>Question 3: ${unit.reflectthree}</strong><br/>${responses.q3.answer}<br/><br/>`: ''}
${selected.includes('q4') ? `<strong>Question 4: ${unit.reflectfour}</strong><br/>${responses.q4.answer}<br/><br/>`: ''}

I welcome any feedback or thoughts you have regarding my answer(s).`;

  return template;
};

const mapStateToProps = (state, ownProps) => {
  const { unit, selectedReflections, reflectionResponses } = ownProps;
  const { reflections, profile } = state;
  const template = getTemplate(unit, selectedReflections, reflectionResponses);
  const subjectString = `${profile.data.name} Shared ${selectedReflections.length > 1 ? 'Reflections': 'a Reflection'} from Skill Builder ${unit.code}`;

  const reflection = {
    message: template,
    emails: '',
    subject: subjectString,
  };

  return {
    reflection,
    sharing: reflections.sharing
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    reflectionActions,
    componentActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareReflectionViaEmails);
