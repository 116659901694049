import { CALL_API } from '../../middlewares/api';

import {
  SB_CATEGORY_REQUEST,
  SB_CATEGORY_SUCCESS,
  SB_CATEGORY_FAILURE,
} from '../../constants';

export const fetchSBcategory = () => {
  const endpoint = 'skillbuilder/categories';

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [SB_CATEGORY_REQUEST, SB_CATEGORY_SUCCESS, SB_CATEGORY_FAILURE],
    },
  };
};
