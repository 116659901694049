import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Button, UncontrolledPopover, PopoverBody, Progress } from 'reactstrap';

import classNames from 'classnames';

import { uniqueId } from '../../utils';
import StarRating from '../rating/StarRating';

const popoverId = `Popover-${uniqueId()}`;

const BadgeRatings = (props) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  function togglePopover() {
    setPopoverOpen(x => !x);
  }

  function seeFeedback() {
    togglePopover();
    props.seeAllFeedback();
  }

  function seeLinkPortfoliFiles() {
    togglePopover();
    props.showLinkPortfolioModal();
  }

  function seeEndorsementModal() {
    togglePopover();
    if(props.showEndorseModal) {
      props.showEndorseModal();
    }
  }

  function toggleReviewsModal(event) {
    event.stopPropagation();
    if(props.showReviewModal) {
      props.showReviewModal();
    }
  }

  return (
    <div className="skillRatingsXX mySkillsContainerStyle d-flex justify-content-md-start justify-content-center align-items-center mt-2">
      <div id={popoverId} onClick={togglePopover}>
        <StarRating
          rating={`${get(props, 'badge.avgRating', 0)}`}
          endorsementCount={parseInt(get(props, 'badge.endorsementCount', 0))}/>
      </div>
      <div className="text-right">
        <span
          className={
            classNames(
              'ml-3',
              {
                'text-white': props.skillEvidenceView,
                'text-orange': get(props, 'badge.avgRating', 0) === 0,
                'themeLink pointer': get(props, 'badge.avgRating', 0) > 0
              }
            )
          }
          onClick={get(props, 'badge.totalReview', 0) > 0 ? toggleReviewsModal : undefined}>
          {`${get(props, 'badge.totalReview', 0)} Reviews`}
        </span>
      </div>

      <UncontrolledPopover
        trigger="legacy"
        placement="right"
        className="mySkillsContainerStyle"
        isOpen={popoverOpen}
        target={popoverId}
        toggle={togglePopover}>
        <PopoverBody className="pt-3 mySkillsContainerStyle">
          <div className="px-2">
            <div className="d-flex justify-content-between">
              <StarRating
                rating={`${get(props, 'badge.avgRating', 0)}`}
                endorsementCount={parseInt(get(props, 'badge.endorsementCount', 0))}/>
              <span className="font-weight-bold ml-1 font16">
                {get(props, 'badge.avgRating', 0)} out of 5 Stars
              </span>
            </div>
            <p className="font-weight-bold mb-1 mt-3">
              {get(props, 'badge.totalReview', 0)} Reviews
            </p>
            <p className="popover-text">
              {props.skillEvidenceView ? 'Skills with strong portfolio evidence get the best ratings. Adding portfolio files, getting endorsements, and using feedback to make improvements can increase ratings.' : 'Skill Badges with strong evidence get the best ratings. Adding portfolio files, getting endorsements, and using feedback to make improvements can increase ratings.'}
            </p>
            {get(props, 'badge.badge_id') && (
              <>
                <p className="font-weight-bold mb-1 mt-3">
                  Question Response Ratings:
                </p>
                <div className="d-flex justify-content-around align-items-center">
                  <span>0</span>
                  <Progress
                    className="mx-1 d-inline-block mb-0 roundedCorners"
                    value={get(props, 'ratings.questionRespRating', 0) * 20}/>
                  <span>5</span>
                </div>
              </>
            )}
            <p className="font-weight-bold mb-1 mt-3">Portfolio Ratings:</p>
            <div className="d-flex justify-content-around align-items-center">
              <span>0</span>
              <Progress
                className="mx-1 d-inline-block mb-0 roundedCorners"
                value={get(props, 'ratings.porfolioRating', 0) * 20}/>
              <span>5</span>
            </div>
            {
              (props.isOwner &&
                parseFloat(get(props, 'ratings.avgRating', 0)) >= 4.5 && parseInt(get(props, 'ratings.endorsementCount', 0)) === 0 ?
                (<div className="mt-3 d-flex align-items-center mb-3">
                  <div className="iconWrapper ml-0 mr-3">
                    <i className="icon-star-border" />
                  </div>
                  <span className="orangeLink pointer"
                    onClick={seeEndorsementModal}>
                    Seek Endorsement to Level up
                  </span>
                </div>) : (parseInt(get(props, 'badge.filesCount', 0)) === 0 ?
                  <div className="mt-3 d-flex align-items-center mb-3">
                    <div className="iconWrapper ml-0 mr-3">
                      <i className="icon-star-border" />
                    </div>
                    <span className="orangeLink pointer"
                      onClick={seeLinkPortfoliFiles}>
                      Add portfolio evidence to level up
                    </span>
                  </div> : null)
              )
            }
          </div>

          {(props.isAdmin || props.isOwner) &&
            <div className="row popover-footer text-center p-1">
              <Button className="popover-button" onClick={seeFeedback}>
                See Feedback
              </Button>
            </div>}
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
};

BadgeRatings.propTypes = {
  seeAllFeedback: PropTypes.func.isRequired,
  showLinkPortfolioModal: PropTypes.func,
  showEndorseModal: PropTypes.func,
  showReviewModal: PropTypes.func,
  skillEvidenceView: PropTypes.bool,
  isOwner: PropTypes.bool,
  badge: PropTypes.object,
  isAdmin: PropTypes.bool.isRequired
};

export default BadgeRatings;
