import { sortBy, isEmpty } from 'lodash';

import initialState from './initialState';

import {
  GET_FEEDBACK_QUESTIONS_REQUEST,
  GET_FEEDBACK_QUESTIONS_SUCCESS,
  GET_FEEDBACK_QUESTIONS_FAILURE,
  GET_FEEDBACK_DATA_REQUEST,
  GET_FEEDBACK_DATA_SUCCESS,
  GET_FEEDBACK_DATA_FAILURE,
  RESET_FEEDBACK_DATA,
  POST_FEEDBACK_DATA_REQUEST,
  POST_FEEDBACK_DATA_SUCCESS,
  POST_FEEDBACK_DATA_FAILURE,
  EDIT_FEEDBACK_DATA_REQUEST,
  EDIT_FEEDBACK_DATA_SUCCESS,
  EDIT_FEEDBACK_DATA_FAILURE,
  GET_FEEDBACK_USER_DATA_REQUEST,
  GET_FEEDBACK_USER_DATA_SUCCESS,
  GET_FEEDBACK_USER_DATA_FAILURE,
  RESET_GIVE_FEEDBACK
} from '../../constants';

export default function reducer(
  state = initialState.feedbackQuestions,
  action
) {
  switch (action.type) {
  case GET_FEEDBACK_QUESTIONS_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };
  case GET_FEEDBACK_QUESTIONS_SUCCESS: {
    const feedbackQuestions = {
      likert: [],
      multichoice: [],
      likertQuestionText: '',
      multiChoiceQuestionText: '',
    };
    const portfolioQuestions = {
      likert: [],
      multichoice: [],
      likertQuestionText: '',
      multiChoiceQuestionText: '',
    };
    const feedbackQuestionsData = action?.data?._embedded?.feedback_questions;
    for (const questionData of feedbackQuestionsData) {
      if (questionData.questionType === 'portfolio') {
        if (questionData.multichoice) {
          for (let x in questionData.multichoice) {
            portfolioQuestions.multichoice.push(questionData.multichoice[x]);
          }
          portfolioQuestions.multiChoiceQuestionText = questionData.questionText;
          portfolioQuestions.multiChoiceQuestionId = questionData.questionId;
        }
        if (questionData.likert) {
          for (let x in questionData.likert) {
            portfolioQuestions.likert.push(questionData.likert[x]);
          }
          portfolioQuestions.likertQuestionText = questionData.questionText;
          portfolioQuestions.likeQuestionId = questionData.questionId;
        }
        if (questionData.comment) {
          portfolioQuestions.comment = questionData.comment;
          portfolioQuestions.commentQuestionText = questionData.questionText;
          portfolioQuestions.commentQuestionId = questionData.questionId;
        }
      }
      if (questionData.questionType === 'feedback_question') {
        if (questionData.multichoice) {
          for (let x in questionData.multichoice) {
            feedbackQuestions.multichoice.push(questionData.multichoice[x]);
          }
          feedbackQuestions[''];
          feedbackQuestions.multiChoiceQuestionText = questionData.questionText;
          feedbackQuestions.multiChoiceQuestionId = questionData.questionId;
        }
        if (questionData.likert) {
          for (let x in questionData.likert) {
            feedbackQuestions.likert.push(questionData.likert[x]);
          }
          feedbackQuestions.likertQuestionText = questionData.questionText;
          feedbackQuestions.likeQuestionId = questionData.questionId;
        }
        if (questionData.comment) {
          feedbackQuestions.comment = questionData.comment;
          feedbackQuestions.commentQuestionText = questionData.questionText;
          feedbackQuestions.commentQuestionId = questionData.questionId;
        }
      }
    }
    portfolioQuestions.likert = sortBy(portfolioQuestions.likert, [
      'answerValue',
    ]).reverse();
    feedbackQuestions.likert = sortBy(feedbackQuestions.likert, [
      'answerValue',
    ]).reverse();
    return {
      ...state,
      isRequesting: false,
      data: { ...state.data, feedbackQuestions, portfolioQuestions },
    };
  }
  case GET_FEEDBACK_QUESTIONS_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };
  case GET_FEEDBACK_DATA_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };
  case GET_FEEDBACK_DATA_SUCCESS: {
    const response = action.data?._embedded?.feedback_responses[0];
    let feedbackData = {};
    for (const userid in response) {
      const obj = response[userid];
      if (obj && typeof obj === 'object') {
        obj['praise'] = {
          givenOn: '11/12/2020',
          praises: [
            'Descriptive (Explained Clearly)',
            'Actionable (Can be acted on)',
            'Specific (Is precise)',
            'Helpful(Helps me improve)',
          ],
        };
        feedbackData = response[userid];
      }
    }
    return {
      ...state,
      isRequesting: false,
      data: { ...state.data, feedbackData },
    };
  }

  case GET_FEEDBACK_DATA_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  case RESET_FEEDBACK_DATA:
    return {
      ...state,
      isRequesting: false,
      // data: { ...state.data, feedbackData: {} },
      data: {feedbackData: {}, feedbackQuestions: {}, portfolioQuestions: {}},
    };

  case RESET_GIVE_FEEDBACK:
    return {
      ...state,
      ...initialState.feedbackQuestions
    };

  case POST_FEEDBACK_DATA_REQUEST:
    return {
      ...state,
      isSubmitting: 'request',
    };
  case POST_FEEDBACK_DATA_SUCCESS:
    return {
      ...state,
      isSubmitting: 'submitted',
      postData: action.data,
    };

  case POST_FEEDBACK_DATA_FAILURE:
    return {
      ...state,
      isSubmitting: 'error',
    };
  case EDIT_FEEDBACK_DATA_REQUEST:
    return {
      ...state,
      isSubmitting: 'request',
    };
  case EDIT_FEEDBACK_DATA_SUCCESS:
    return {
      ...state,
      isSubmitting: 'submitted',
      postData: action.data,
    };

  case EDIT_FEEDBACK_DATA_FAILURE:
    return {
      ...state,
      isSubmitting: 'error',
      error: action.error,
    };

  case GET_FEEDBACK_USER_DATA_REQUEST:
    return {
      ...state,
      isRequesting: 'request',
    };
  case GET_FEEDBACK_USER_DATA_SUCCESS:
    return {
      ...state,
      isRequesting: 'submitted',
      isSubmitting: 'request',
      getFeedbackUserDetail: !isEmpty(action.data?.detail) ? action.data?.detail[0]: {},
    };

  case GET_FEEDBACK_USER_DATA_FAILURE:
    return {
      ...state,
      isRequesting: 'error',
    };
  default:
    return state;
  }
}
