import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as wallActions from '../../redux/actions/wall-posts';
import * as searchActions from '../../redux/actions/search';
import * as wallCommentsActions from '../../redux/actions/wall-post-comments';

import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

import WallCommentCard from '../../components/dashboard/WallCommentCard';
import UserLikesListModal from '../../components/common/UserLikesListModal';

class WallCommentCardContainer extends PureComponent {
  static propTypes = {
    replies: PropTypes.array,
    actions: PropTypes.object,
    loadForms: PropTypes.array.isRequired,
    commentData: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    wallPostId: PropTypes.string.isRequired,
    isDeleting: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    isDeletingPostComment: PropTypes.object.isRequired,
    searchPage: PropTypes.bool,
    isMobile: PropTypes.bool.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      isOpen: false,
      isUserLikesTooltipOpen: false,
      isUserLikesListModalOpen: false,
      commentShowMore: { condition: false, id: null}
    };

    this.onClickDelete = this.onClickDelete.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.onCommentShowMore = this.onCommentShowMore.bind(this);
    this.toggleUserLikesTooltip = this.toggleUserLikesTooltip.bind(this);
    this.toggleUserLikesListModal = this.toggleUserLikesListModal.bind(this);
  }

  componentWillMount() {
    const {
      commentData,
      actions,
      replies
    } = this.props;

    if (commentData.replies) {
      const isReplyAlreadyLoaded = replies.filter(
        item => item.id === commentData.replies[0].id
      ).length > 0;

      if (!isReplyAlreadyLoaded)
        actions.loadCommentReplies(commentData.replies);
    }
  }

  onDelete() {
    const { actions, commentData, wallPostId, replies, searchPage } = this.props;

    const commentsToDelete = replies.filter(item => item.parent === commentData.id).length + 1;

    if(searchPage){
      return actions.deleteSearchResultWallPostComment(commentData.id, wallPostId);

    }

    actions.deleteWallPostComment(commentData.id, wallPostId)
      .then(() =>
        actions.decrementWallCommentCount(wallPostId, commentsToDelete, 'comment'));
  }

  toggleModal() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  onClickDelete() {
    this.setState({ isOpen: true });
  }

  onCommentShowMore(id){
    return () => {
      const { condition, id: conditionId } = this.state.commentShowMore;

      const newShowMore = conditionId === id && id !== null ? {condition: !condition, id: id} :
        { condition: true, id: id};

      this.setState({ commentShowMore: newShowMore });
    };
  }

  toggleUserLikesTooltip() {
    this.setState({
      isUserLikesTooltipOpen: ! this.state.isUserLikesTooltipOpen
    });
  }

  toggleUserLikesListModal() {
    this.setState({
      isUserLikesListModalOpen: ! this.state.isUserLikesListModalOpen
    });
  }

  render() {
    const {
      isOpen,
      commentShowMore,
      isUserLikesTooltipOpen,
      isUserLikesListModalOpen
    } = this.state;

    const {
      profile,
      isMobile,
      commentData,
      loadForms,
      wallPostId,
      replies,
      currentUser,
      isDeleting,
      isDeletingPostComment,
      searchPage
    } = this.props;

    const repliesToThisComment = replies.filter(reply =>
      reply.parent === commentData.id);

    return(
      <div>
        <WallCommentCard
          profile={profile}
          isMobile={isMobile}
          searchPage={searchPage}
          commentData={commentData}
          loadForms={loadForms}
          currentUser={currentUser}
          wallPostId={wallPostId}
          replies={repliesToThisComment}
          commentShowMore={commentShowMore}
          onClickDelete={this.onClickDelete}
          onCommentShowMore={this.onCommentShowMore}
          isUserLikesTooltipOpen={isUserLikesTooltipOpen}
          toggleUserLikesTooltip={this.toggleUserLikesTooltip}
          toggleUserLikesListModal={this.toggleUserLikesListModal}/>

        {(isUserLikesListModalOpen && (commentData.likesList.length > 0)) &&
          <UserLikesListModal
            userLikes={commentData.likesList}
            isOpen={isUserLikesListModalOpen}
            onClose={this.toggleUserLikesListModal}/>}

        <Modal
          backdrop="static"
          className="modal-margin-top"
          isOpen={isOpen} toggle={this.toggleModal}>
          <ModalHeader
            className="no-border"
            toggle={this.toggleModal}>
            Delete Comment
          </ModalHeader>
          <ModalBody>
            Are you sure you want to permanently delete this comment? If you do, it cannot be undone.
          </ModalBody>
          <ModalFooter className="no-border">
            <button
              onClick={this.toggleModal}
              className="btn btn-secondary">
              Cancel
            </button>

            <button
              onClick={this.onDelete}
              className="btn btn-danger"
              disabled={isDeleting.status}>
              {isDeleting.status || isDeletingPostComment.status ? 'Deleting...' : 'Permanently Delete'}
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.components.isMobile,
    replies: state.wallPostComments.replyData,
    isDeleting: state.wallPostComments.isDeleting,
    isDeletingPostComment: state.search.isDeletingPostComment,
    currentUser: state.auth.data.currentUser,
    profile: state.profile.data
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, wallCommentsActions, wallActions, searchActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WallCommentCardContainer);
