import React from 'react';

import PropTypes from 'prop-types';
import SkillsList from './SkillsList';
import Loading from '../common/Loading';
import InfiniteScroll from 'react-infinite-scroll-component';

const SearchYourSkills = ({
  callAPI,
  setSuggestSkillForm,
  defaultSkillsPaginationData,
  skills,
  selectedCustomSkills,
  selectCustomSkills,
  removeCustomSkills,
  initialSkillsRequest
}) => {
  const renderSuggestSkillsForm = () =>
    (<div className="skill-not-found">
      <h5 className="font14">Search results</h5>
      <div className="text-center my-5">
        <p className="font14 text-secondary">Skill not found</p>
        <div> <a className="clickable" onClick={setSuggestSkillForm}>Click here</a> to suggest a skill</div>
      </div>
    </div>);

  return (
    (skills.length > 0 && !initialSkillsRequest) ?
      <div id="scrollableDiv" style={{ height: 330}}>
        <h5 className="font14">Search results</h5>
        <InfiniteScroll
          dataLength={skills.length}
          next={callAPI}
          hasMore={defaultSkillsPaginationData.page < defaultSkillsPaginationData.pageCount}
          height={300}
          loader={<Loading/>}>
          <SkillsList
            skills={skills}
            selectedCustomSkills={selectedCustomSkills}
            selectCustomSkills={selectCustomSkills}
            removeCustomSkills={removeCustomSkills}/>
        </InfiniteScroll>
      </div>
      :
      renderSuggestSkillsForm()
  );
};

SearchYourSkills.propTypes = {
  setSuggestSkillForm: PropTypes.func.isRequired,
  callAPI: PropTypes.func.isRequired,
  skills: PropTypes.array.isRequired,
  selectCustomSkills: PropTypes.func.isRequired,
  removeCustomSkills: PropTypes.func.isRequired,
  initialSkillsRequest: PropTypes.bool.isRequired,
  selectedCustomSkills: PropTypes.array.isRequired,
  defaultSkillsPaginationData: PropTypes.object.isRequired
};

export default SearchYourSkills;
