import initialState from './initialState';

import {
  PROJECTS_REQUEST,
  PROJECTS_SUCCESS,
  PROJECTS_FAILURE,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAILURE,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILURE,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.projects, action) {
  switch (action.type) {
  case PROJECTS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case PROJECTS_SUCCESS:
    return Object.assign({}, state, {
      data: action.data._embedded.projects,
      isRequesting: false,
    });

  case PROJECTS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case CREATE_PROJECT_REQUEST:
    return Object.assign({}, state, { isSubmitting: true });

  case CREATE_PROJECT_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data, Object.assign({}, action.data)],
      isSubmitting: false,
    });

  case CREATE_PROJECT_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case UPDATE_PROJECT_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, projectIndex: action.index },
    });

  case UPDATE_PROJECT_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, state.isUpdating.projectIndex),
        Object.assign({}, action.data),
        ...state.data.slice(state.isUpdating.projectIndex + 1),
      ],
      isUpdating: { status: false, projectIndex: null },
    });

  case UPDATE_PROJECT_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false, projectIndex: null },
    });

  case DELETE_PROJECT_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: action.id },
    });

  case DELETE_PROJECT_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.filter(project => project.id !== state.isDeleting.id),
      ],
      isDeleting: { status: false, id: null },
    });

  case DELETE_PROJECT_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: { status: false, id: null },
    });

  default:
    return state;
  }
}
