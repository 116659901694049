import React from 'react';

import {
  Popover,
  PopoverBody
} from 'reactstrap';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

const IndustryColumn = (props) => {
  const rowDetails = props.rowData;
  let clusterData = JSON.parse(rowDetails.industryNames);
  const remainingData = cloneDeep(clusterData);

  if(clusterData.length > 1) {
    remainingData.shift();
  }

  return (
    <td scope="row">
      {clusterData.length > 1 ?
        <>
          <span>{clusterData[0].industryName || 'NA'}</span>
          <span id={`industry-${rowDetails.id}`}
            className="orange pointer ml-1"
            onClick={props.toggleIRViewIndustry(rowDetails.id)}>
            {`+${remainingData.length}`}
          </span>
          <Popover placement="bottom" className="industryCluster-popover"
            isOpen={rowDetails.id === props.industryPopOver}
            target={`industry-${rowDetails.id}`}>
            <PopoverBody>
              {remainingData.map((cluster, index) =>
                <span className="d-block" key={index}>{cluster.industryName || 'NA'}</span>)}
            </PopoverBody>
          </Popover>
        </> :
        <span>{clusterData[0]?.industryName || 'NA'}</span>}
    </td>
  );
};

IndustryColumn.propTypes = {
  rowData: PropTypes.object.isRequired,
  industryPopOver: PropTypes.string,
  toggleIRViewIndustry: PropTypes.func,
};

export default IndustryColumn;
