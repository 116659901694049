import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as interviewActions from '../../../redux/actions/interview';
import TextArea from '../../../components/common/TextArea';

class FileCommentReplyForm extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    avatar: PropTypes.string,
    name: PropTypes.string,
    replyData: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    isSubmitting: PropTypes.object.isRequired,
    videoId: PropTypes.string,
    parentId: PropTypes.string
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      replyData: Object.assign({}, this.props.replyData)
    };

    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  onChange(e){
    const { value, name } = e.target;

    const replyData = this.state.replyData;

    replyData[name] = value;

    this.setState({
      replyData
    });
  }

  onSave(){
    const { replyData } = this.state;
    const { videoId, parentId, actions, onCancel } = this.props;

    const data = {
      'comment': replyData.comment,
      'resource': videoId,
      'parent': parentId
    };

    actions.addCommentToVideo(data, 'reply').
      then(() => onCancel());
  }

  render() {
    const { replyData } = this.state;
    const { avatar, onCancel, isSubmitting, parentId, name } = this.props;
    const isSubmittingReply = isSubmitting.status && isSubmitting.commentId === parentId;

    return (
      <div className="tertiary-post-comment clearfix">
        <div className="left">
          {
            avatar && avatar !== '' ?
              <img 
                className="header-image small align-top" 
                src={avatar} 
                alt={`${name}'s Avatar`}/>:
              <span 
                style={{lineHeight: '24px'}}
                className="header-image small align-top initial-avatar">
                {name.slice(0,1)}
              </span>
          }
        </div>

        <div className="right">
          <div className="form-group post-comment">
            <div className="input-group">
              <TextArea
                className="form-control"
                name="comment"
                placeholder={'Write your reply...'}
                value={replyData.comment}
                rows="3"
                onChange={this.onChange}
                autofocus />
            </div>
            <div className="share-stats">
              <button 
                onClick={onCancel}
                disabled={isSubmittingReply}
                className="btn btn-secondary boder-1 float-left mt-1 mb-1">
                Cancel
              </button>
              <button 
                onClick={this.onSave}
                disabled={isSubmittingReply || replyData.comment === ''}
                className="btn btn-primary float-right mt-1 mb-1">
                {isSubmittingReply ? 'Replying': 'Reply'}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const replyData = {
    'comment': ''
  };
  return {
    avatar: state.profile.data.avatar,
    name: state.profile.data.name,
    isSubmitting: state.interview.unitVideoComments.isSubmitting,
    replyData
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, interviewActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileCommentReplyForm);
