import React from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { Col, Input } from 'reactstrap';

const UserInstitutionsDropdown = ({ institutions, onChangeInstitution }) => {
  const { selectedInstitution, data: userInstitutions } = institutions;

  return (
    <Col lg="4" md="5">
      <div className="dashboard-activity-filter">
        <Input
          type="select"
          name="activity-filter"
          value={selectedInstitution.id}
          className="form-control form-control-md dashboard-activity-filter-inputs"
          onChange={onChangeInstitution}>
          {userInstitutions.map(institution =>
            <option key={institution.id} value={institution.id}>{institution.name}</option>)}
        </Input>
      </div>
    </Col>
  );
};

UserInstitutionsDropdown.propTypes = {
  institutions: PropTypes.object.isRequired,
  onChangeInstitution: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  institutions: state.institutions
});

export default connect(mapStateToProps)(UserInstitutionsDropdown);
