import { CALL_API } from '../../middlewares/api';

import {
  CP_SEARCH_REQUEST,
  CP_SEARCH_SUCCESS,
  CP_SEARCH_FAILURE,
  RESET_SEARCH_REDUCER,
  GET_POST_SEARCH_RESULT_COMMENTS_REQUEST,
  GET_POST_SEARCH_RESULT_COMMENTS_SUCCESS,
  GET_POST_SEARCH_RESULT_COMMENTS_FAILURE,
  LIKE_POST_SEARCH_RESULT_REQUEST,
  LIKE_POST_SEARCH_RESULT_SUCCESS,
  LIKE_POST_SEARCH_RESULT_FAILURE,
  UNLIKE_POST_SEARCH_RESULT_REQUEST,
  UNLIKE_POST_SEARCH_RESULT_SUCCESS,
  UNLIKE_POST_SEARCH_RESULT_FAILURE,
  SAVE_SEARCH_RESULT_POST_COMMENT_REQUEST,
  SAVE_SEARCH_RESULT_POST_COMMENT_SUCCESS,
  SAVE_SEARCH_RESULT_POST_COMMENT_FAILURE,
  DELETE_SEARCH_RESULT_POST_COMMENT_REQUEST,
  DELETE_SEARCH_RESULT_POST_COMMENT_SUCCESS,
  DELETE_SEARCH_RESULT_POST_COMMENT_FAILURE,
  LIKE_SEARCH_RESULT_POST_COMMENT_REQUEST,
  LIKE_SEARCH_RESULT_POST_COMMENT_SUCCESS,
  LIKE_SEARCH_RESULT_POST_COMMENT_FAILURE,
  UNLIKE_SEARCH_RESULT_POST_COMMENT_REQUEST,
  UNLIKE_SEARCH_RESULT_POST_COMMENT_SUCCESS,
  UNLIKE_SEARCH_RESULT_POST_COMMENT_FAILURE,
  SHARE_RESULT_ITEM_POST,
  RESET_SHARE_RESULT_ITEM_POST,
  UPDATE_SEARCH_QUERY,
  FOLLOW_SEARCH_RESULT_PEOPLE_REQUEST,
  FOLLOW_SEARCH_RESULT_PEOPLE_SUCCESS,
  FOLLOW_SEARCH_RESULT_PEOPLE_FAILURE,
  UNFOLLOW_SEARCH_RESULT_PEOPLE_REQUEST,
  UNFOLLOW_SEARCH_RESULT_PEOPLE_SUCCESS,
  UNFOLLOW_SEARCH_RESULT_PEOPLE_FAILURE,
  LIKE_CP_PORTFOLIO_SEARCH_RESULT_REQUEST,
  LIKE_CP_PORTFOLIO_SEARCH_RESULT_SUCCESS,
  LIKE_CP_PORTFOLIO_SEARCH_RESULT_FAILURE,
  UNLIKE_CP_PORTFOLIO_SEARCH_RESULT_REQUEST,
  UNLIKE_CP_PORTFOLIO_SEARCH_RESULT_SUCCESS,
  UNLIKE_CP_PORTFOLIO_SEARCH_RESULT_FAILURE,
} from '../../constants';

export const clearSearchReducer = () => {
  return { type: RESET_SEARCH_REDUCER };
};

export const updateSearchQuery = (query) => {
  return dispatch => dispatch({ type: UPDATE_SEARCH_QUERY, query });
};

export const resetShareResultItemPost = () => {
  return { type: RESET_SHARE_RESULT_ITEM_POST };
};

export const shareResultItemPost = (wallpost) => {
  return dispatch => dispatch({ type: SHARE_RESULT_ITEM_POST, wallpost });
};

export const cpSearch = (query, type, page = 1, sort = 'ascending') => {
  const endpoint = `search?query=${query}&type=${type}&sort=${sort}&page=${page}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      id: type,
      query,
      authenticated: true,
      types: [CP_SEARCH_REQUEST, CP_SEARCH_SUCCESS, CP_SEARCH_FAILURE],
    },
  };
};

export const getPostSearchResultComments = (
  wallPostId,
  postIndex,
  pageNo = 1
) => {
  const endpoint = `discussion/wall_comment?wallpost=${wallPostId}&page=${pageNo}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      index: postIndex,
      types: [
        GET_POST_SEARCH_RESULT_COMMENTS_REQUEST,
        GET_POST_SEARCH_RESULT_COMMENTS_SUCCESS,
        GET_POST_SEARCH_RESULT_COMMENTS_FAILURE,
      ],
    },
  };
};

export const likePostSearchResult = (data) => {
  const endpoint = 'discussion/wall_like';

  return {
    [CALL_API]: {
      endpoint,
      data: {
        wall: data.wall,
      },
      method: 'post',
      authenticated: true,
      index: data.index,
      types: [
        LIKE_POST_SEARCH_RESULT_REQUEST,
        LIKE_POST_SEARCH_RESULT_SUCCESS,
        LIKE_POST_SEARCH_RESULT_FAILURE,
      ],
    },
  };
};

export const unlikePostSearchResult = (data) => {
  const endpoint = `discussion/wall_like/${data.wall}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'delete',
      authenticated: true,
      index: data.index,
      types: [
        UNLIKE_POST_SEARCH_RESULT_REQUEST,
        UNLIKE_POST_SEARCH_RESULT_SUCCESS,
        UNLIKE_POST_SEARCH_RESULT_FAILURE,
      ],
    },
  };
};

export const saveSearchResultPostComment = (data, wallPostId) => {
  const endpoint = 'discussion/wall_comment';

  return {
    [CALL_API]: {
      data,
      endpoint,
      id: wallPostId,
      method: 'post',
      authenticated: true,
      types: [
        SAVE_SEARCH_RESULT_POST_COMMENT_REQUEST,
        SAVE_SEARCH_RESULT_POST_COMMENT_SUCCESS,
        SAVE_SEARCH_RESULT_POST_COMMENT_FAILURE,
      ],
    },
  };
};

export const deleteSearchResultWallPostComment = (
  wallPostCommentId,
  wallPostId
) => {
  const endpoint = `discussion/wall_comment/${wallPostCommentId}`;

  return {
    [CALL_API]: {
      endpoint,
      id: {
        commentId: wallPostCommentId,
        wallPostId: wallPostId,
      },
      method: 'delete',
      authenticated: true,
      types: [
        DELETE_SEARCH_RESULT_POST_COMMENT_REQUEST,
        DELETE_SEARCH_RESULT_POST_COMMENT_SUCCESS,
        DELETE_SEARCH_RESULT_POST_COMMENT_FAILURE,
      ],
    },
  };
};

export const likeSearchResultWallPostComment = (data) => {
  const endpoint = 'discussion/wall_comment_like';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      id: {
        wallPostId: data.wallPostId,
        index: data.commentIndex,
      },
      types: [
        LIKE_SEARCH_RESULT_POST_COMMENT_REQUEST,
        LIKE_SEARCH_RESULT_POST_COMMENT_SUCCESS,
        LIKE_SEARCH_RESULT_POST_COMMENT_FAILURE,
      ],
    },
  };
};

export const unlikeSearchResultWallPostComment = (data) => {
  const endpoint = `discussion/wall_comment_like/${data.comment}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'delete',
      authenticated: true,
      id: {
        wallPostId: data.wallPostId,
        index: data.commentIndex,
      },
      types: [
        UNLIKE_SEARCH_RESULT_POST_COMMENT_REQUEST,
        UNLIKE_SEARCH_RESULT_POST_COMMENT_SUCCESS,
        UNLIKE_SEARCH_RESULT_POST_COMMENT_FAILURE,
      ],
    },
  };
};

export const followSearchResultPeople = (data) => {
  const endpoint = 'user/connect';

  return {
    [CALL_API]: {
      data,
      endpoint,
      id: data.user,
      method: 'post',
      authenticated: true,
      types: [
        FOLLOW_SEARCH_RESULT_PEOPLE_REQUEST,
        FOLLOW_SEARCH_RESULT_PEOPLE_SUCCESS,
        FOLLOW_SEARCH_RESULT_PEOPLE_FAILURE,
      ],
    },
  };
};

export const unfollowSearchResultPeople = (followingId, userId) => {
  const endpoint = `user/connect/${followingId}`;

  return {
    [CALL_API]: {
      endpoint,
      id: userId,
      method: 'delete',
      authenticated: true,
      types: [
        UNFOLLOW_SEARCH_RESULT_PEOPLE_REQUEST,
        UNFOLLOW_SEARCH_RESULT_PEOPLE_SUCCESS,
        UNFOLLOW_SEARCH_RESULT_PEOPLE_FAILURE,
      ],
    },
  };
};

export const likeCpSearchRessultFile = (data) => {
  const endpoint = 'file/likes';
  return {
    [CALL_API]: {
      endpoint,
      data,
      id: data.file,
      method: 'post',
      authenticated: true,
      types: [
        LIKE_CP_PORTFOLIO_SEARCH_RESULT_REQUEST,
        LIKE_CP_PORTFOLIO_SEARCH_RESULT_SUCCESS,
        LIKE_CP_PORTFOLIO_SEARCH_RESULT_FAILURE,
      ],
    },
  };
};

export const unlikeCpSearchRessultFile = (fileId) => {
  const endpoint = `file/likes/${fileId}`;
  return {
    [CALL_API]: {
      endpoint,
      id: fileId,
      method: 'delete',
      authenticated: true,
      types: [
        UNLIKE_CP_PORTFOLIO_SEARCH_RESULT_REQUEST,
        UNLIKE_CP_PORTFOLIO_SEARCH_RESULT_SUCCESS,
        UNLIKE_CP_PORTFOLIO_SEARCH_RESULT_FAILURE,
      ],
    },
  };
};
