import PropTypes from 'prop-types';
import React from 'react';
import { Collapse } from 'reactstrap';

const tempProfileSections = [
  {
    type: 'Club',
    name: 'Extracurricular Activites & Clubs'
  },
  {
    type: 'Certification',
    name: 'Certifications and Licenses'
  },
  {
    type: 'Award',
    name: 'Honors & Awards'
  },
  {
    type: 'Publication',
    name: 'Publications'
  },
  {
    type: 'Project',
    name: 'Projects'
  },
  {
    type: 'Association',
    name: 'Associations'
  },
  {
    type: 'Interests',
    name: 'Interests'
  }
];

const AddSections = ({ onToggleAdd, isOpen, onAddSection, profileOrder }) => {
  const profileSectionNames = profileOrder.map(pro => pro.name);
  const sectionArray = tempProfileSections.filter(sec => !profileSectionNames.includes(sec.type));

  if(sectionArray.length === 0){
    return null;
  }
  return (
    <div className="add-bar">
      <div className="row">
        <div className="col-lg-12">
          <a
            className="btn btn-primary full-width-btn mb-3"
            style={{cursor: 'pointer'}}
            onClick={onToggleAdd}>Add Sections</a>
          <Collapse isOpen={isOpen}>
            <div className="section-list">
              <div className="container">
                <div className="row">
                  {sectionArray.map((section, index) =>
                    (<div key={index} className="col-lg-6">
                      <a
                        className="clickable"
                        onClick={onAddSection(section.type)}>
                        <i className="fa fa-plus-circle"/>{section.name}
                      </a>
                    </div>))}
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
};

AddSections.propTypes = {
  onToggleAdd: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onAddSection: PropTypes.func.isRequired,
  profileOrder: PropTypes.array.isRequired
};

export default AddSections;
