import PropTypes from 'prop-types';
import React from 'react';
import JobUnitHeader from './JobUnitHeader';
import JobUnitDescription from './JobUnitDescription';
import JobUnitConnect from './JobUnitConnect';
import JobUnitNotes from './JobUnitNotes';

const Details = ({
  errors,
  data,
  loadForms,
  noteForm,
  onChangeNoteForm,
  jobNotes,
  isSubmitting,
  jobStatusDropdownOpen,
  togglejobStatusDropdown,
  onSelectJobStatus,
  onEditDetails,
  onNotePost,
  onDeleteNote,
  onEditNote,
  onShowMore,
  showMore,
  onNotesShowMore,
  notesShowMore,
  isDeleting,
  currentUser,
  confirmDeleteNoteModalIsOpen,
  onConfirmDeleteNote,
  onCancelDeleteNote
}) => {
  if (!data) return null;

  const{
    id,
    title,
    company,
    snippet,
    cnumber,
    cemail,
    cname,
    status: jobStatus,
    url: jobUrl,
    user
  } = data;

  const isJobOwner = currentUser.id === user.id;

  return (
    <div className="row">
      <div className="col-lg-7">
        <div className="job-detail">
          <JobUnitHeader
            id={id}
            user={user}
            isJobOwner={isJobOwner}
            currentUser={currentUser}
            company={company}
            jobStatus={jobStatus}
            onSelectJobStatus={onSelectJobStatus}
            jobStatusDropdownOpen={jobStatusDropdownOpen}
            togglejobStatusDropdown={togglejobStatusDropdown}
            title={title}/>

          {(jobUrl.length > 0) &&
            <p className="job-details-view">
              <i className="fa fa-link"/>
              <a
                className="view-job-details"
                target="_blank"
                href={jobUrl}>
                View job details
              </a>
            </p>}

          <JobUnitDescription
            id={id}
            onShowMore={onShowMore}
            showMore={showMore}
            loadForms={loadForms}
            isJobOwner={isJobOwner}
            onEditDetails={onEditDetails}
            description={snippet}/>
          <JobUnitConnect
            id={id}
            isJobOwner={isJobOwner}
            loadForms={loadForms}
            onEditDetails={onEditDetails}
            name={cname}
            phone={cnumber}
            email={cemail}/>
        </div>
      </div>
      <div className="col-lg-5">
        <JobUnitNotes
          errors={errors}
          isJobOwner={isJobOwner}
          onDeleteNote={onDeleteNote}
          onEditNote={onEditNote}
          loadForms={loadForms}
          notesShowMore={notesShowMore}
          onNotesShowMore={onNotesShowMore}
          onChangeNoteForm={onChangeNoteForm}
          isSubmitting={isSubmitting}
          onNotePost={onNotePost}
          jobNotes={jobNotes}
          noteForm={noteForm}
          isDeleting={isDeleting}
          confirmDeleteNoteModalIsOpen={confirmDeleteNoteModalIsOpen}
          onConfirmDeleteNote={onConfirmDeleteNote}
          onCancelDeleteNote={onCancelDeleteNote}
          isOpen={confirmDeleteNoteModalIsOpen}/>
      </div>
    </div>
  );
};

Details.propTypes = {
  data: PropTypes.object,
  noteForm: PropTypes.object.isRequired,
  loadForms: PropTypes.array.isRequired,
  jobNotes: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  jobStatusDropdownOpen: PropTypes.bool.isRequired,
  togglejobStatusDropdown: PropTypes.func.isRequired,
  onEditDetails: PropTypes.func.isRequired,
  onDeleteNote: PropTypes.func.isRequired,
  onEditNote: PropTypes.func.isRequired,
  onSelectJobStatus: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  onNotePost: PropTypes.func.isRequired,
  onChangeNoteForm: PropTypes.func.isRequired,
  showMore: PropTypes.bool.isRequired,
  onShowMore: PropTypes.func.isRequired,
  notesShowMore: PropTypes.array.isRequired,
  onNotesShowMore: PropTypes.func.isRequired,
  confirmDeleteNoteModalIsOpen: PropTypes.bool.isRequired,
  onCancelDeleteNote: PropTypes.func.isRequired,
  onConfirmDeleteNote: PropTypes.func.isRequired,
  isDeleting: PropTypes.object.isRequired
};

export default Details;
