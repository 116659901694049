import PropTypes from 'prop-types';
import React from 'react';
import SkillItem from './SkillItem';
import { Link } from 'react-router-dom';

const SkillList = ({
  profile,
  fileItem,
  showAllSkills,
  onToggleShowAllSkills,
  onViewEvidence
}) => {
  const showMoreCondition = fileItem.skills.length > 5;
  const skillsArray = showMoreCondition && !showAllSkills ? fileItem.skills.slice(0,5):
    fileItem.skills;

  if (fileItem.skills.length === 0) {
    return (
      <span style={{fontWeight: 'normal', color: '#31708f'}}>
        <strong>Heads up!</strong> Edit this file to attach skills or update your <Link to={`/cp/${profile.vanity}`}>Career Site</Link> information to add skills.
      </span>
    );
  }

  return (
    <div>
      {skillsArray.map(skill =>
        (<SkillItem
          key={skill.id}
          skill={skill}
          profile={profile}
          onViewEvidence={onViewEvidence}/>))}

      {
        showMoreCondition &&
          <span
            style={{borderRadius: 10, backgroundColor: '#6dabe4'}}
            className="modal-skill-pill skill-pill skill-pill-ellipsis">
            <a
              onClick={onToggleShowAllSkills}
              className="clickable orange">
              <span style={{ color: 'white' }}>{showAllSkills ? 'show less' : `+${fileItem.skills.length - 5} more`}</span>
            </a>
          </span>
      }
    </div>
  );
};

SkillList.propTypes = {
  showAllSkills: PropTypes.bool,
  onToggleShowAllSkills: PropTypes.func,
  profile: PropTypes.object,
  fileItem: PropTypes.object.isRequired,
  onViewEvidence: PropTypes.func.isRequired
};

export default SkillList;
