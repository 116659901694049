import PropTypes from 'prop-types';
import React from 'react';

import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PortfolioFileFormHandler from '../../containers/portfolio/PortfolioFileFormHandler';

const AddPortfolioModal = ({
  isOpen,
  onCancel,
  isEditingCard,
  fileItem,
  guideLinesTooltipOpen,
  toggleGuidelinesTooltip,
  skillEvidence
}) => {
  return(
    <Modal
      size="lg"
      className="portfolio-file-modal"
      isOpen={isOpen}
      backdrop="static"
      toggle={onCancel}>
      <ModalHeader
        toggle={onCancel}>
        {isEditingCard ? 'Update Portfolio File': 'Add Portfolio File'}
      </ModalHeader>
      <ModalBody>
        <PortfolioFileFormHandler
          onCancel={onCancel}
          guideLinesTooltipOpen={guideLinesTooltipOpen}
          toggleGuidelinesTooltip={toggleGuidelinesTooltip}
          isEditingCard={isEditingCard}
          fileItem={fileItem}
          skillEvidence={skillEvidence}/>
      </ModalBody>
    </Modal>
  );
};

AddPortfolioModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  isEditingCard: PropTypes.bool,
  fileItem: PropTypes.object,
  skillEvidence: PropTypes.bool,
  guideLinesTooltipOpen: PropTypes.bool,
  toggleGuidelinesTooltip: PropTypes.func
};

export default AddPortfolioModal;
