import PropTypes from 'prop-types';
import React from 'react';

import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Nav } from 'reactstrap';
import classnames from 'classnames';

const ModalDropDownNav = ({
  activeTab,
  toggleTab,
  exampleBase,
  dropDownOpen,
  toggleDropDown
}) => {
  return (
    exampleBase.length > 1 &&
      <Nav >
        <Dropdown isOpen={dropDownOpen} toggle={toggleDropDown} className="form-control form-control-lg">
          <DropdownToggle nav caret>
            Example Types
          </DropdownToggle>
          <DropdownMenu>
            {exampleBase.map((example, index) => {
              const { tabTitle, tabNumber } = example;

              return (
                <DropdownItem key={index}
                  className={classnames('clickable form-control-lg', {
                    active: activeTab === tabNumber,
                    orange: activeTab !== tabNumber,
                  })}
                  id={tabNumber}
                  onClick={toggleTab}>
                  {tabTitle}
                </DropdownItem>

              );
            })}
          </DropdownMenu>
        </Dropdown>
      </Nav>
  );
};

ModalDropDownNav.propTypes = {
  exampleBase: PropTypes.array.isRequired,
  activeTab: PropTypes.string,
  toggleTab: PropTypes.func.isRequired,
  dropDownOpen: PropTypes.bool.isRequired,
  toggleDropDown: PropTypes.func.isRequired
};

export default ModalDropDownNav;
