import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const DashboardAvatar = ({ profile, medium, small }) => {
  let avatarHeight = 90;
  let avatarWidth = 90;

  if (medium) {
    avatarHeight = 30;
    avatarWidth = 30;
  }else if (small) {
    avatarHeight = 24;
    avatarWidth = 24;
  }

  return (
    <Link to={`/cp/${profile.vanity}`}>
      {profile.avatar && profile.avatar.length > 0 ?
        <img
          height={avatarHeight}
          width={avatarWidth}
          className="header-image rounded-circle"
          src={profile.avatar}
          alt="Profile User Avatar"/> :
        <div
          style={{
            width: avatarWidth,
            height: avatarHeight,
            lineHeight: `${avatarHeight}px`,
            display: 'inline-block'
          }}
          className="rounded-circle initial-avatar">
          {profile.name && profile.name.slice(0,1)}
        </div>}
    </Link>
  );
};

DashboardAvatar.propTypes = {
  profile: PropTypes.object.isRequired,
  small: PropTypes.bool,
  medium: PropTypes.bool
};

export default DashboardAvatar;
