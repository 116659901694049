import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash';

const RequestedFeedbackHeader = (props) => {
  const [clickedHeaders, setClickedHeaders] = useState([]);
  const [nonClickedHeaders, setNonClickedHeaders] = useState([
    'sortRequest', 'resourceTypeRequest', 'reviewerRequest', 'resentdRequest', 'statusRequest', 
  ]);

  function clickHeader(event){
    const headerName = event.target.id;

    // update the noncliked headers
    if(includes(nonClickedHeaders, headerName)){
      const updatedNonClickedHeadersList = nonClickedHeaders.filter(header => header!== headerName);
      setNonClickedHeaders(updatedNonClickedHeadersList);
    }

    // update the clicked headers
    if(includes(clickedHeaders, headerName)){
      const updatedClickedHeadersList = clickedHeaders.filter(header => header!== headerName);
      setClickedHeaders(updatedClickedHeadersList);
    }else{
      setClickedHeaders([...clickedHeaders, headerName]);
    }
  }

  function onSortRequest(event){
    clickHeader(event);
    props.onSortTable(event);
  }

  function getSortIconClassName(column){
    if(includes(nonClickedHeaders, column)){
      return 'fa-sort';
    } else if(includes(clickedHeaders, column)){
      return 'fa-sort-down';
    }
    return 'fa-sort-up';
  }

  return (
    <>
      <th
        id="sortRequest"
        style={{ width: '12%' }}
        className="cursor-pointer"
        data-column-name="sortRequest"
        onClick={onSortRequest}>
        Requested on
        <i
          id="sortRequest"
          className={`ml-1 fas ${getSortIconClassName('sortRequest')}`}/>
      </th>
      <th
        id="resourceTypeRequest"
        data-column-name="resourceTypeRequest"
        onClick={onSortRequest}
        className="cursor-pointer"
        style={{ width: 200 }}>
        Skill
        <i
          id="resourceTypeRequest"
          className={`ml-1 fas ${getSortIconClassName('resourceTypeRequest')}`}/>
      </th>
      <th
        id="reviewerRequest"
        data-column-name="reviewerRequest"
        onClick={onSortRequest}
        className="cursor-pointer"
        style={{ width: 170 }}>
        Reviewer
        <i
          id="reviewerRequest"
          className={`ml-1 fas ${getSortIconClassName('reviewerRequest')}`}/>
      </th>
      <th
        id="resentdRequest"
        data-column-name="resentdRequest"
        onClick={onSortRequest}
        className="cursor-pointer"
        style={{ width: 150 }}>
        Resent On
        <i
          id="resentdRequest"
          className={`ml-1 fas ${getSortIconClassName('resentdRequest')}`}/>
      </th>
      <th
        id="statusRequest"
        data-column-name="statusRequest"
        onClick={onSortRequest}
        className="cursor-pointer"
        style={{ width: 150 }}>
        Status
        <i
          id="statusRequest"
          className={`ml-1 fas ${getSortIconClassName('statusRequest')}`}/>
      </th>
      <th style={{ width: 150}}>Action</th>
      {/* <th style={{ width: 150 }} /> */}
    </>
  );
};

RequestedFeedbackHeader.propTypes = {
  onSortTable: PropTypes.func.isRequired,
};

export default RequestedFeedbackHeader;