import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as authenticationActions from '../../redux/actions/authentication';

class InvitationLogin extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    inviteDetails: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(event) {
    event.preventDefault();

    const { userDetails, errors, actions } = this.props;

    if (Object.keys(errors).length > 0) return false;

    actions.authenticateUser({
      email: userDetails.email,
      password: userDetails.password
    });

  }

  render() {
    const { inviteDetails, isRequesting, errors, userDetails, onChange } = this.props;
    const { user } = inviteDetails;

    return (
      <div
        style={{margin: '10px auto'}}
        className="modal-body homepage-modal-body invitation-login">
        <h4 className="modal-title" id="myModalLabel">
          Sign in to join group
        </h4>
        <div className="login-form">
          <div style={{ textAlign: 'center'}}>
            {
              user.avatar && user.avatar !== '' ?
                <img
                  className="img-responsive avatar"
                  src={user.avatar}
                  alt={`${user.name}'s Avatar`}/>:
                <span
                  style={{
                    fontSize: 20,
                    lineHeight: '40px',
                    display: 'inline-block'
                  }}
                  className="img-responsive avatar initial-avatar">
                  {user.name && user.name.slice(0,1)}
                </span>
            }
            <p>{user.name}</p>
          </div>
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <label className="sr-only">Password</label>
              <input
                type="password"
                name="password"
                className="form-control"
                placeholder="Password"
                value={userDetails.password}
                onChange={onChange}/>
              {errors.password && <div className="text-danger">{errors.password}</div>}
            </div>

            <input
              type="submit"
              disabled={isRequesting}
              value={isRequesting ? 'Authenticating...' : 'Sign In'}
              className="btn btn-primary w-100"/>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isRequesting: state.auth.isRequesting
  };
};
const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, authenticationActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(InvitationLogin);
