import unionBy from 'lodash/unionBy';
import sortBy from 'lodash/sortBy';

import initialState from './initialState';
import {
  // badges
  CLEAR_ISSUED_BADGES,
  RESET_BADGES,
  BADGES_REQUEST,
  BADGES_SUCCESS,
  BADGES_FAILURE,
  GET_ISSUED_BADGES_REQUEST,
  GET_ISSUED_BADGES_SUCCESS,
  GET_ISSUED_BADGES_FAILURE,
  GET_MY_ISSUED_BADGES_SUCCESS,
  ISSUE_BADGE_REQUEST,
  ISSUE_BADGE_SUCCESS,
  ISSUE_BADGE_FAILURE,
  SAVE_SELECTED_BADGES,
  RESET_SELECTED_BADGES,
  LINK_PORTFOLIO_FILE_REQUEST,
  LINK_PORTFOLIO_FILE_SUCCESS,
  LINK_PORTFOLIO_FILE_FAILURE,
  LINK_MY_SKILLS_REQUEST,
  LINK_MY_SKILLS_SUCCESS,
  LINK_MY_SKILLS_FAILURE,
  // badge-assertion
  BADGE_ASSERTION_REQUEST,
  RESET_ASSERTION_REDUCER,
  ADD_EVIDENCE_FILE_SUCCESS,
  REMOVE_EVIDENCE_FILE_REQUEST,
  REMOVE_EVIDENCE_FILE_SUCCESS,
  REMOVE_EVIDENCE_FILE_FAILURE,
  // linking-files
  GET_RESOURCE_DATA_REQUEST,
  GET_RESOURCE_DATA_SUCCESS,
  GET_RESOURCE_DATA_FAILURE,
} from '../../constants';

const organizeBadges = (stateData, actionData, page) => {
  let prevStateData = [];
  if (stateData.length && page > 1) {
    prevStateData = stateData;
  }
  const issuedBadges = unionBy(prevStateData, actionData, 'id');
  const claimedBadges = issuedBadges.filter(badge => badge.isClaimed);
  const unclaimedBadges = sortBy(
    issuedBadges.filter(badge => !badge.isClaimed),
    filtered => filtered.unit.code
  );

  return [...claimedBadges, ...unclaimedBadges];
};

const updateBadgeFile = (
  data,
  isUpdatingId,
  type,
  isRemovingFileId,
  addedFile
) => {
  const indexOfBadge = data.findIndex(
    item => item?.issued?.id === isUpdatingId
  );
  const badge = data[indexOfBadge];

  return [
    ...data.slice(0, indexOfBadge),
    {
      ...badge,
      fileCount: type === 'add' ? badge?.fileCount + 1 : badge?.fileCount - 1,
      files: isRemovingFileId
        ? badge?.files.filter(file => file.id !== isRemovingFileId)
        : [...badge?.files, addedFile],
    },
    ...data.slice(indexOfBadge + 1),
  ];
};

// ToDo: Verify
// const updateBadgesAfterUploadingNew = (stateData, actionData)=> {
//   const updatedData = stateData.map((badge) => {
//     const updatedBadge = actionData.badges.find(badgeItem => badge.id === badgeItem.badgeid);
//     if (typeof updatedBadge !== 'undefined') {
//       const fileObject = {
//         id: actionData.id,
//         title: actionData.title,
//         description: actionData.description,
//         image: actionData.url,
//         visibility: actionData.visibility,
//         external: actionData.external,
//         mimetype: actionData.mimetype
//       };

//       return Object.assign({}, badge, {
//         files: [...badge.files, fileObject],
//         fileCount: badge.fileCount + 1
//       });
//     }

//     return badge;
//   });

//   return updatedData;
// };

export default function reducer(state = initialState.badges, action) {
  switch (action.type) {
  case SAVE_SELECTED_BADGES:
    return Object.assign({}, state, {
      selectedBadges: [...action.selectedBadges],
    });

  case RESET_SELECTED_BADGES:
    return Object.assign({}, state, { selectedBadges: [] });

  case RESET_BADGES:
    return Object.assign({}, state, {
      searchQuery: state.searchQuery,
      ...initialState.badges,
    });

  case CLEAR_ISSUED_BADGES:
    return Object.assign({}, state, {
      issuedBadges: initialState.badges.issuedBadges,
    });

  case BADGES_REQUEST:
    return Object.assign({}, state, {
      isRequesting: true,
    });

  case BADGES_SUCCESS:
    return Object.assign({}, state, {
      emptySearchResult: action.data._embedded.badge.length === 0,
      data: organizeBadges(
        state.data,
        action.data._embedded.badge,
        action.data.page
      ),
      paginationData: {
        page: action.data.page,
        page_count: action.data.page_count,
        total_items: action.data.total_items,
      },
      isRequesting: false,
    });

  case BADGES_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case GET_ISSUED_BADGES_REQUEST:
    return Object.assign({}, state, {
      issuedBadges: Object.assign({}, state.issuedBadges, {
        isRequesting: true,
      }),
    });

  case GET_ISSUED_BADGES_SUCCESS:
    return Object.assign({}, state, {
      issuedBadges: Object.assign({}, state.issuedBadges, {
        data: [...action.data._embedded.badge],
        paginationData: {
          page: action.data.page,
          page_count: action.data.page_count,
          total_items: action.data.total_items,
        },
        isRequesting: false,
      }),
    });
  case GET_MY_ISSUED_BADGES_SUCCESS:
    return Object.assign({}, state, {
      issuedBadges: Object.assign({}, state.issuedBadges, {
        data: [...action.data._embedded.issued],
        paginationData: {
          page: action.data.page,
          page_count: action.data.page_count,
          total_items: action.data.total_items,
        },
        isRequesting: false,
      }),
    });

  case GET_ISSUED_BADGES_FAILURE:
    return Object.assign({}, state, {
      issuedBadges: Object.assign({}, state.issuedBadges, {
        error: action.error,
        isRequesting: false,
      }),
    });

  case ISSUE_BADGE_REQUEST:
    return Object.assign({}, state, {
      isIssuingBadge: { status: true, badgeIndex: action.index },
    });

  case ISSUE_BADGE_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, state.isIssuingBadge.badgeIndex),
        Object.assign({}, action.data),
        ...state.data.slice(state.isIssuingBadge.badgeIndex + 1),
      ],
      paginationData: Object.assign({}, state.paginationData, {
        total_items: state.paginationData.total_items + 1,
      }),
      isIssuingBadge: { status: false, badgeIndex: null },
    });

  case ISSUE_BADGE_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isIssuingBadge: { status: false, badgeIndex: null },
    });

  case LINK_PORTFOLIO_FILE_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: true,
    });

  case LINK_PORTFOLIO_FILE_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.map((badge) => {
          const updatedBadge = action.data.badges.find(
            badgeItem => badge.id === badgeItem.id
          );

          if (typeof updatedBadge !== 'undefined') {
            return Object.assign({}, badge, {
              files: updatedBadge.files,
              fileCount: updatedBadge.fileCount,
            });
          }

          return badge;
        }),
      ],
      isSubmitting: false,
    });

  case LINK_PORTFOLIO_FILE_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case LINK_MY_SKILLS_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: true,
    });

  case LINK_MY_SKILLS_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.map((badge) => {
          const updatedBadge = action.data.badges.find(
            badgeItem => badge.id === badgeItem.id
          );

          if (typeof updatedBadge !== 'undefined') {
            return updatedBadge;
          }

          return badge;
        }),
      ],
      isSubmitting: false,
    });

  case LINK_MY_SKILLS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

    /* Reducers affected by Badge Assertion Actions */
  case BADGE_ASSERTION_REQUEST:
    return Object.assign({}, state, { isUpdatingId: action.id });

  case REMOVE_EVIDENCE_FILE_REQUEST:
    return Object.assign({}, state, { isRemovingFileId: action.id });

  case ADD_EVIDENCE_FILE_SUCCESS:
    return Object.assign({}, state, {
      data: updateBadgeFile(
        state.data,
        state.isUpdatingId,
        'add',
        state.isRemovingFileId,
        action.data
      ),
    });

  case REMOVE_EVIDENCE_FILE_SUCCESS:
    return Object.assign({}, state, {
      data: updateBadgeFile(
        state.data,
        state.isUpdatingId,
        'remove',
        state.isRemovingFileId,
        null
      ),
      isRemovingFileId: null,
    });

  case REMOVE_EVIDENCE_FILE_FAILURE:
    return Object.assign({}, state, { isRemovingFileId: null });

  case RESET_ASSERTION_REDUCER:
    return Object.assign({}, state, { isUpdatingId: null });

    /* End */

    /* Reducers affected by Files Actions */
  case GET_RESOURCE_DATA_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };

  case GET_RESOURCE_DATA_SUCCESS: {
    const resourceData = action.data;

    return {
      ...state,
      isRequesting: false,
      resourceData,
    };
  }
  case GET_RESOURCE_DATA_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };
    /* End */

  default:
    return state;
  }
}
