import { createSelector } from 'reselect';
import moment from 'moment';

const selectProfileState = state => state.profile;

export const selectProfile = createSelector(
  [selectProfileState],
  profile => profile.data
);

export const selectIsRecentEducatorAccount = createSelector(
  [selectProfile],
  ({ role, createdAt }) =>
    (role === 'educator') && moment(createdAt.date).isAfter('2022-11-01')
);
