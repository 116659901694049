import React from 'react';

import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';

import ProfileDropdown from '../../containers/navbar/ProfileDropdown';
import { pure } from 'recompose';

import {
  NovuProvider,
  PopoverNotificationCenter,
  NotificationBell
} from '@novu/notification-center';

const styles = {
  telegram: {
    fontSize: 20
  },
  badge: {
    marginLeft: 3
  },
  btnGroups: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap'
  }
};

const LoggedInNavbar = ({
  onSearchIconClicked,
  currentUser,
  setProfileDropdownState,
  isMobile
}) => {
  const navigate = useNavigate();

  function onNotificationClick(message) {
    if (message?.cta?.data?.url) {
      navigate(message.cta.data.url);
    }
  }

  function removeFooter() {
    return false;
  }

  return (
    <span>
      <div
        style={styles.btnGroups}
        className="nav navbar-nav justify-content-end">

        <span className="nav-item">
          <a
            title="Search"
            onClick={onSearchIconClicked}
            className="nav-link clickable">
            <i className="fa fa-search"/>
          </a>
        </span>

        <span className="nav-item position-relative">
          <Link
            to={'/messages'}
            className="nav-link" aria-label="Messages">
            <i style={styles.telegram} className="fab fa-telegram-plane"/>
            {currentUser && currentUser.unreadMessagesCount > 0 &&
              <span className="notification-pill" style={styles.badge}>
                {currentUser.unreadMessagesCount}
              </span>}
          </Link>
        </span>

        <span className="nav-item">
          <NovuProvider
            backendUrl={process.env.NOVU_BACKEND_URL}
            socketUrl={process.env.NOVU_SOCKET_URL}
            subscriberId={currentUser.id}
            subscriberHash={currentUser.subscriberHash}
            applicationIdentifier={process.env.NOVU_APPLICATION_IDENTIFIER}>
            <PopoverNotificationCenter
              colorScheme="light"
              offset={16}
              footer={removeFooter}
              showUserPreferences={false}
              onNotificationClick={onNotificationClick}>
              {({ unseenCount }) => <NotificationBell unseenCount={unseenCount}/>}
            </PopoverNotificationCenter>
          </NovuProvider>
        </span>

        <span className="nav-item dropdown">
          <ProfileDropdown
            currentUser={currentUser}
            isMobile={isMobile}
            setProfileDropdownState={setProfileDropdownState}/>
        </span>

      </div>
    </span>
  );
};

LoggedInNavbar.propTypes = {
  currentUser: PropTypes.object.isRequired,
  onSearchIconClicked: PropTypes.func,
  setProfileDropdownState: PropTypes.func,
  isMobile: PropTypes.bool,
};

export default pure(LoggedInNavbar);
