import { CALL_API } from '../../middlewares/api';

import {
  ORGANIZATION_USERS_REQUEST,
  ORGANIZATION_USERS_SUCCESS,
  ORGANIZATION_USERS_FAILURE,
  ORGANIZATION_USERS_FILTERED_REQUEST,
  ORGANIZATION_USERS_FILTERED_SUCCESS,
  ORGANIZATION_USERS_FILTERED_FAILURE,
  ORGANIZATION_USERS_FILTERED_CLEAR_SUCCESS,
  ORGANIZATION_GROUP_USERS_FILTERED_REQUEST,
  ORGANIZATION_GROUP_USERS_FILTERED_SUCCESS,
  ORGANIZATION_GROUP_USERS_FILTERED_FAILURE,
  FOLLOW_AN_USER_ADMIN_PANEL_REQUEST,
  FOLLOW_AN_USER_ADMIN_PANEL_SUCCESS,
  FOLLOW_AN_USER_ADMIN_PANEL_FAILURE,
  UNFOLLOW_AN_USER_ADMIN_PANEL_REQUEST,
  UNFOLLOW_AN_USER_ADMIN_PANEL_SUCCESS,
  UNFOLLOW_AN_USER_ADMIN_PANEL_FAILURE,
  ONCHANGE_ORGANIZATION_USERS_SEARCH_QUERY,
  ONCHANGE_ORGANIZATION_USERS_LOCATION,
  ONCHANGE_ORGANIZATION_USERS_EMPLOYMENT_TYPE,
  ONCHANGE_ORGANIZATION_USERS_EMPLOYMENT_STATUS,
} from '../../constants';

export const getUsers = (urlParamString) => {
  const endpoint = `institution/users?${urlParamString}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        ORGANIZATION_USERS_REQUEST,
        ORGANIZATION_USERS_SUCCESS,
        ORGANIZATION_USERS_FAILURE,
      ],
    },
  };
};

export const getAllUsersFiltered = (institutionId, query) => {
  const endpoint = `institution/users?institution=${institutionId}&${query}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        ORGANIZATION_USERS_FILTERED_REQUEST,
        ORGANIZATION_USERS_FILTERED_SUCCESS,
        ORGANIZATION_USERS_FILTERED_FAILURE,
      ],
    },
  };
};

export const getGroupUsersFiltered = (groupId, urlParams) => {
  const endpoint = `institution/users?group=${groupId}&=${urlParams}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        ORGANIZATION_GROUP_USERS_FILTERED_REQUEST,
        ORGANIZATION_GROUP_USERS_FILTERED_SUCCESS,
        ORGANIZATION_GROUP_USERS_FILTERED_FAILURE,
      ],
    },
  };
};

export const followAnUserInAdminPanel = (data) => {
  const endpoint = 'user/connect';

  return {
    [CALL_API]: {
      data,
      endpoint,
      id: data.user,
      method: 'post',
      authenticated: true,
      types: [
        FOLLOW_AN_USER_ADMIN_PANEL_REQUEST,
        FOLLOW_AN_USER_ADMIN_PANEL_SUCCESS,
        FOLLOW_AN_USER_ADMIN_PANEL_FAILURE,
      ],
    },
  };
};

export const unfollowAnUserInAdminPanel = (followingId, id) => {
  const endpoint = `user/connect/${followingId}`;

  return {
    [CALL_API]: {
      endpoint,
      id: id,
      method: 'delete',
      authenticated: true,
      types: [
        UNFOLLOW_AN_USER_ADMIN_PANEL_REQUEST,
        UNFOLLOW_AN_USER_ADMIN_PANEL_SUCCESS,
        UNFOLLOW_AN_USER_ADMIN_PANEL_FAILURE,
      ],
    },
  };
};

export const clearAllOrganizationUsers = () => {
  return dispatch =>
    dispatch({ type: ORGANIZATION_USERS_FILTERED_CLEAR_SUCCESS });
};

export const onChangeUsersSearchQuery = (usersSearchQuery) => {
  return dispatch =>
    dispatch({
      type: ONCHANGE_ORGANIZATION_USERS_SEARCH_QUERY,
      usersSearchQuery,
    });
};

export const onChangeUsersLocation = (usersLocation) => {
  return dispatch =>
    dispatch({ type: ONCHANGE_ORGANIZATION_USERS_LOCATION, usersLocation });
};

export const onChangeUsersEmploymentType = (employmentType) => {
  return dispatch =>
    dispatch({
      type: ONCHANGE_ORGANIZATION_USERS_EMPLOYMENT_TYPE,
      employmentType,
    });
};

export const onChangeUsersEmploymentStatus = (employmentStatus) => {
  return dispatch =>
    dispatch({
      type: ONCHANGE_ORGANIZATION_USERS_EMPLOYMENT_STATUS,
      employmentStatus,
    });
};
