import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import _isUndefined from 'lodash/isUndefined';

const Files = ({ fileData, attached}) => {
  let icon = 'fa fa-file';
  const url = _isUndefined(fileData.url) ? URL.createObjectURL(fileData.file) : fileData.url;
  const mimetype = _isUndefined(fileData.mimetype) ? fileData.file.type : fileData.mimetype;

  switch (mimetype) {
  case 'image/jpeg':
    icon = 'fa fa-file-image-o';
    break;
  case 'image/png':
    icon = 'fa fa-file-image-o';
    break;
  case 'application/pdf':
    icon = 'fa fa-file-pdf-o';
    break;
  }

  return(
    <div>
      <a href={url}
        target="_blank"
        rel="noopener"
        className={classNames(
          {'file': !attached}
        )}>
        <i className={icon}/>
        <span className="file-name">
          {fileData.title}
        </span>
      </a>
    </div>
  );
};

Files.propTypes = {
  attached: PropTypes.bool,
  fileData: PropTypes.object.isRequired
};

export default Files;
