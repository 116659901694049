import React from 'react';

import PropTypes from 'prop-types';

import SelectInput from '../common/SelectInput';

const PrivacySettings = ({
  changePrivacySetting,
  error,
  privacySettings,
  options,
  isUpdating,
}) => {
  return (
    <div className="form-group">
      <label>
        Career Site Privacy Settings :
      </label>

      <div className="input-group set">
        <div style={{ position: 'relative', width: 'calc(100% - 1px)' }}>
          <SelectInput
            id="privacySettings"
            name="privacySettings"
            value={privacySettings}
            onChange={changePrivacySetting}
            options={options}
            error={error}
            className="form-control input-styles-owht"
            disabled={isUpdating}/>
        </div>
      </div>
    </div>
  );
};

PrivacySettings.propTypes = {
  changePrivacySetting: PropTypes.func,
  error: PropTypes.string,
  privacySettings: PropTypes.number,
  options: PropTypes.array,
  isUpdating: PropTypes.bool,
};

export default PrivacySettings;