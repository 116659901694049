import React, {useState} from 'react';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import PropTypes from 'prop-types';

import ConfirmModal from '../../common/ConfirmModal';

const RequestedActionColumn = (props) => {
  const rowDetails = props.rowData;
  const followText = rowDetails.following ? 'Unfollow' : 'Follow';
  const followTextLabel = followText === 'Unfollow' ? `- ${followText}` : `+ ${followText}`;
  const [isConfirmModalOpen, setCofirmationModal] = useState(false);
  
  function onCloseConfirmModal(){
    setCofirmationModal(false);
  }

  function onConfirm(){
    props.removeIRView(rowDetails.id);
    onCloseConfirmModal();
  }

  function openModal(){
    setCofirmationModal(true);
  }

  return (
    <>
      <td>
        <div className="dropdown ir-dropdown">
          <ButtonDropdown
            isOpen={rowDetails.id === props.isOpenIRAction}
            toggle={props.toggleIRViewActions(rowDetails.id, rowDetails.name, rowDetails.following || '')}>
            <DropdownToggle caret color="primary">
              Actions
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-right">
              <DropdownItem onClick={props.onToggleMessagingModal}>
                Message
              </DropdownItem>
              <DropdownItem onClick={props.onFollow}>
                {props.isFollowing ? `${followText}ing...` : followTextLabel }
              </DropdownItem>
              <DropdownItem onClick={openModal}>
                Remove
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </div>
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          isConfirming={false}
          confirminginProgressText=""
          modalHeaderText="Remove Industry Rep"
          modalMessage={'The industry rep will no longer be available to provide feedback. Are you sure you want to remove the industry rep?'}
          modalFooterButtonText="Yes, Remove"
          onCancel={onCloseConfirmModal}
          onConfirm={onConfirm}/>
      </td>
    </>
  );
};

RequestedActionColumn.propTypes = {
  rowData: PropTypes.object.isRequired,
  resendFeedbackRequest: PropTypes.func,
  setCurrentRequest: PropTypes.func,
  toggleIgnore: PropTypes.func,
  isOpenIRAction: PropTypes.string,
  toggleIRViewActions: PropTypes.func,
  onToggleMessagingModal: PropTypes.func,
  isFollowing: PropTypes.bool,
  onFollow: PropTypes.func,
  removeIRView: PropTypes.func,
};

export default RequestedActionColumn;