import React from 'react';

import PropTypes from 'prop-types';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const jobStatusValues = [
  {
    title: 'Closed',
    value: 0,
    statusClass: 'closed'
  },
  {
    title: 'Saved',
    value: 1,
    statusClass: 'saved'
  },
  {
    title: 'Applied',
    value: 2,
    statusClass: 'applied'
  },
  {
    title: 'Interviewed',
    value: 3,
    statusClass: 'interviewed'
  },
  {
    title: 'Offered',
    value: 4,
    statusClass: 'offered'
  },
  {
    title: 'No Response',
    value: 5,
    statusClass: 'noresponse'
  },
  {
    title: 'Rejected',
    value: 6,
    statusClass: 'rejected'
  },
];

const JobCardDropup = ({
  id,
  isOpen,
  onToggle,
  jobStatus,
  isGuestUser,
  onSelectJobStatus
}) => {
  const activeStatus = jobStatusValues.find(item=>item.value === jobStatus);
  const dropDownButtonClass = `job-status-dropdown ${activeStatus.statusClass}`;

  return (
    <Dropdown
      className={dropDownButtonClass}
      isOpen={isOpen}
      toggle={onToggle}
      disabled={isGuestUser}
      direction="up"
      tag="div">
      <DropdownToggle
        className="btn"
        onClick={onToggle}
        tag="a"
        caret>
        {activeStatus.title}
      </DropdownToggle>
      <DropdownMenu>
        {jobStatusValues.map((option, index) =>
          (<DropdownItem
            key={index}
            tag="a"
            onClick={onSelectJobStatus(id, option.value)}>
            {option.title}
          </DropdownItem>))}
      </DropdownMenu>
    </Dropdown>
  );
};

JobCardDropup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onSelectJobStatus: PropTypes.func.isRequired,
  jobStatus: PropTypes.number,
  id: PropTypes.string,
  isGuestUser: PropTypes.bool.isRequired
};

export default JobCardDropup;
