import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import FileCard from '../../components/portfolio/FileCard';
import * as badgeAssertionActions from '../../redux/actions/badge-assertion';
import * as componentActions from '../../redux/actions/components';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import SharePortfolioModal from '../portfolio/SharePortfolioModal';

class BadgeEvidenceFileCardHandler extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    modal: PropTypes.string,
    userId: PropTypes.string,
    files: PropTypes.array,
    isLiking: PropTypes.object.isRequired,
    fileItem: PropTypes.object,
    isAuthenticated: PropTypes.bool.isRequired,
    onViewPortfolio: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);

    this.onViewFile = this.onViewFile.bind(this);
    this.onShareToCp = this.onShareToCp.bind(this);
    this.onUpdateFileLike = this.onUpdateFileLike.bind(this);
  }

  onViewFile(id){
    return () => {
      const { actions } = this.props;
      this.setState({
        viewingFileId: id
      }, () => actions.openModal(`open-view-file-modal-${id}`));
    };
  }

  onUpdateFileLike(fileId){
    const { files, actions, isAuthenticated } = this.props;
    return () => {
      if(!isAuthenticated)
        return toastr.error('Permission is Needed.', 'You must be signed in to like this file.');

      const file = files.find(file => file.id === fileId);

      if(file.liked){
        return actions.unlikeEvidenceFile(fileId);
      }

      const data = {
        'file': fileId
      };
      actions.likeEvidenceFile(data);
    };
  }

  onShareToCp(){
    this.props.actions.openModal(`share_file_in_baked_badge_to_cp_${this.props.fileItem.id}`);
  }

  render() {
    const { fileItem, isLiking, onViewPortfolio, modal } = this.props;
    const isLikingFile = isLiking.status && isLiking.id === fileItem.id;
    const isShareModalOpen = modal && modal === `share_file_in_baked_badge_to_cp_${fileItem.id}`;

    return(
      <div>
        <FileCard
          fileItem={fileItem}
          onViewFile={onViewPortfolio}
          onShareToCp={this.onShareToCp}
          visitingUser
          isLikingFile={isLikingFile}
          onUpdateFileLike={this.onUpdateFileLike}/>
        {
          isShareModalOpen && 
            <SharePortfolioModal 
              isOpen={isShareModalOpen}
              fileData={fileItem}/>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modal: state.components.modal,
    isLiking: state.badgeAssertion.isLikingFile,
    isAuthenticated: state.auth.isAuthenticated
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, badgeAssertionActions, componentActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BadgeEvidenceFileCardHandler);
