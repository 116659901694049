import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';
import { uniqueId } from '../../../utils';

const styles = {
  listGroup: {
    marginTop: 20,
    marginBottom: 0,
    marginLeft: -16,
    marginRight: -16
  },
  listGroupItem: {
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    borderBottom: '1px solid #ddd'
  }
};

const ListGroup = ({units}) => {
  return (
    <div className="list-group" style={styles.listGroup}>
      {units.map((unit) => {
        return (
          <Link
            to={`/admin/skill-builders/${unit.slug}`}
            key={uniqueId()}
            className="list-group-item"
            style={styles.listGroupItem}>
            {`${unit.code} ${unit.title}`}
          </Link>
        );
      })}
    </div>
  );
};

ListGroup.propTypes = {
  units: PropTypes.array.isRequired
};

export default ListGroup;
