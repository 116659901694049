import PropTypes from 'prop-types';
import React from 'react';
import BadgeAbilities from './BadgeAbilities';
import BadgeSupportingStatementContainer from './BadgeSupportingStatementContainer';
import BadgeSubmitSection from './BadgeSubmitSection';
import ClaimBadgeButton from '../../../containers/skill-builders/ClaimBadgeButton';

const BadgeUnit = ({
  unit,
  badge,
  errors,
  badgeImage,
  claimBadge,
  issuedBadge,
  selectedFile,
  onRemoveFile,
  onAddEvidence,
  acknowledgement,
  isAuthenticated,
  onChangeStatement,
  toggleShareAndVerifyCheckboxes,
  onChangeSelectedFile,
  onAcknowledgementChange,
  evidenceGuidelinesTooltipOpen,
  statementGuidelinesTooltipOpen,
  toggleEvidenceGuidelinesTooltip,
  toggleStatementGuidelinesTooltip,
  onChangeAddPortfolio,
  addPortfolioRadio,
  onCancelResetFile,
  onConfirmResetFile,
  onConfirmJustification,
  isValidClaimBadge,
  displayLinkingPortfolioModal,
  onDisplayLinkingPortfolioModal,

  onSelectFile,
  selectedFiles,
  onDeleteLoadedFile,
  onInsertLinkClicked,
  showInsertTextBox,
  guidelinesTooltipOpen,
  onDropFile,
  toggleGuidelinesTooltip
}) => {

  return (
    <div className="row claim-badge-sections">
      <div className="col-lg-3 text-center">
        <img
          className="mb-2"
          style={{width: 200, height: 200}}
          src={badgeImage}/>
      </div>
      <div className="col-lg-9">
        {(acknowledgement.length !== 0) &&
          <BadgeAbilities
            badge={badge}
            abilities={acknowledgement}
            achievedAbilities={issuedBadge.acknowledgement}
            onAcknowledgementChange={onAcknowledgementChange}/>}

        <BadgeSupportingStatementContainer
          unit={unit}
          errors={errors}
          badge={badge}
          issuedBadge={issuedBadge}
          addPortfolioRadio={addPortfolioRadio}
          onChangeStatement={onChangeStatement}
          onChangeAddPortfolio={onChangeAddPortfolio}
          onCancelResetFile={onCancelResetFile}
          onConfirmResetFile={onConfirmResetFile}
          statementGuidelinesTooltipOpen={statementGuidelinesTooltipOpen}
          toggleStatementGuidelinesTooltip={toggleStatementGuidelinesTooltip}
          toggleEvidenceGuidelinesTooltip={toggleEvidenceGuidelinesTooltip}
          selectedFile={selectedFile}
          onRemoveFile={onRemoveFile}
          onAddEvidence={onAddEvidence}
          isAuthenticated={isAuthenticated}
          onChangeSelectedFile={onChangeSelectedFile}
          evidenceGuidelinesTooltipOpen={evidenceGuidelinesTooltipOpen}
          onConfirmJustification={onConfirmJustification}
          displayLinkingPortfolioModal={displayLinkingPortfolioModal}
          onDisplayLinkingPortfolioModal={onDisplayLinkingPortfolioModal}
          onSelectFile={onSelectFile}
          selectedFiles={selectedFiles}
          onDeleteLoadedFile={onDeleteLoadedFile}
          onInsertLinkClicked={onInsertLinkClicked}
          showInsertTextBox={showInsertTextBox}
          guidelinesTooltipOpen={guidelinesTooltipOpen}
          onDropFile={onDropFile}
          toggleGuidelinesTooltip={toggleGuidelinesTooltip}/>

        <BadgeSubmitSection
          badge={badge}
          unit={unit}
          issuedBadge={issuedBadge}
          isAuthenticated={isAuthenticated}
          toggleShareAndVerifyCheckboxes={toggleShareAndVerifyCheckboxes}/>

        <ClaimBadgeButton
          isValidClaimBadge={isValidClaimBadge}
          claimBadge={claimBadge}/>

      </div>
    </div>
  );
};

BadgeUnit.propTypes = {
  unit: PropTypes.object,
  badge: PropTypes.object,
  isIssuingBadge: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  onChangeStatement: PropTypes.func.isRequired,
  toggleShareAndVerifyCheckboxes: PropTypes.func.isRequired,
  onAddEvidence: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  acknowledgement: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  badgeImage: PropTypes.string,
  onChangeSelectedFile: PropTypes.func.isRequired,
  issuedBadge: PropTypes.object.isRequired,
  selectedFile: PropTypes.object.isRequired,
  onAcknowledgementChange: PropTypes.func.isRequired,
  evidenceGuidelinesTooltipOpen: PropTypes.bool.isRequired,
  statementGuidelinesTooltipOpen: PropTypes.bool.isRequired,
  toggleEvidenceGuidelinesTooltip: PropTypes.func.isRequired,
  toggleStatementGuidelinesTooltip: PropTypes.func.isRequired,
  onChangeAddPortfolio: PropTypes.func,
  addPortfolioRadio: PropTypes.string,
  onCancelResetFile: PropTypes.func.isRequired,
  onConfirmResetFile: PropTypes.func.isRequired,
  onConfirmJustification: PropTypes.func,
  isValidClaimBadge: PropTypes.func.isRequired,
  claimBadge: PropTypes.func.isRequired,
  selectedFiles: PropTypes.array.isRequired,
  onSelectFile: PropTypes.func.isRequired,
  onDeleteLoadedFile: PropTypes.func.isRequired,
  onInsertLinkClicked: PropTypes.func.isRequired,
  showInsertTextBox: PropTypes.bool.isRequired,
  guidelinesTooltipOpen: PropTypes.bool.isRequired,
  onDropFile: PropTypes.func.isRequired,
  toggleGuidelinesTooltip: PropTypes.func.isRequired,
  displayLinkingPortfolioModal: PropTypes.bool,
  onDisplayLinkingPortfolioModal: PropTypes.func.isRequired
};

export default BadgeUnit;
