import React, { useState } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { useLocation, Link } from 'react-router-dom';
import classNames from 'classnames';

import MainMenuMobile from './MainMenuMobile';
import { CSSTransition } from 'react-transition-group';

const MainMenu = ({ profile, isMobile, currentUser }) => {
  const { pathname } = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const transitionClasses = {
    enter: 'animated',
    enterActive: 'animate__slideInLeft',
    exit: 'animated',
    exitActive: 'animate__slideOutLeft'
  };

  function onToggleMobileMenu() {
    setIsMobileMenuOpen(! isMobileMenuOpen);
  }

  const { role, adminInstitutions } = currentUser;
  const isEducator = role === 'educator';
  const isInstitutionAdmin = role === 'institution-admin';
  const isGroupAdmin = (role === 'user' || role === 'group-admin')
    && adminInstitutions && adminInstitutions.length > 0;

  return (
    <section>
      <div className="main-navigation-wrapper">
        <div className="container">
          <div className="main-navigation">
            <Link
              to="/home"
              className={classNames(
                'main-navigation-link',
                { active: pathname === '/home' }
              )}>
              <div className="main-navigation-item">
                <div className="title">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 20V14H14V20H19V12H22L12 3L2 12H5V20H10Z" fill="white"/>
                  </svg>
                </div>
              </div>
            </Link>
            <Link
              to={`/cp/${profile.vanity}`}
              className={classNames(
                'main-navigation-link',
                { active: pathname === `/cp/${profile.vanity}` }
              )}>
              <div className="main-navigation-item">
                <div className="title">Career Site</div>
              </div>
            </Link>
            <Link
              to="/skill-builders"
              className={classNames(
                'main-navigation-link',
                { active: pathname.includes('/skill-builders') }
              )}>
              <div className="main-navigation-item">
                <div className="title">Skill Builders</div>
              </div>
            </Link>
            <Link
              to="/myskills"
              className={classNames(
                'main-navigation-link',
                { active: pathname === '/myskills' }
              )}>
              <div className="main-navigation-item">
                <div className="title">Claimed Skills</div>
              </div>
            </Link>
            <Link
              to="/portfolio"
              className={classNames(
                'main-navigation-link',
                { active: pathname === '/portfolio' }
              )}>
              <div className="main-navigation-item">
                <div className="title">Portfolio</div>
              </div>
            </Link>
            <Link
              to="/myfeedback"
              className={classNames(
                'main-navigation-link',
                { active: pathname === '/myfeedback' }
              )}>
              <div className="main-navigation-item">
                <div className="title">Feedback</div>
              </div>
            </Link>
            <Link
              to="/job-center"
              className={classNames(
                'main-navigation-link',
                { active: pathname === '/job-center' }
              )}>
              <div className="main-navigation-item">
                <div className="title">Jobs</div>
              </div>
            </Link>
            <Link
              to="/community"
              className={classNames(
                'main-navigation-link',
                { active: pathname === '/community' }
              )}>
              <div className="main-navigation-item">
                <div className="title">Community</div>
              </div>
            </Link>
            <Link
              to="/resources"
              className={classNames(
                'main-navigation-link',
                { active: pathname === '/resources' }
              )}>
              <div className="main-navigation-item">
                <div className="title">Resources</div>
              </div>
            </Link>

            {isMobile && (
              <>
                <Link
                  to="/search"
                  className={classNames(
                    'main-navigation-link',
                    { active: pathname.includes('/search') }
                  )}>
                  <div className="main-navigation-item">
                    <div className="title">Search</div>
                  </div>
                </Link>

                <Link
                  to="/messages"
                  className={classNames(
                    'main-navigation-link',
                    { active: pathname.includes('/messages') }
                  )}>
                  <div className="main-navigation-item">
                    <div className="title">Messages</div>
                  </div>
                </Link>

                {(isEducator) &&
                  <Link
                    to="/educator/groups"
                    className={classNames(
                      'main-navigation-link',
                      { active: pathname.includes('/educator/groups') }
                    )}>
                    <div className="main-navigation-item">
                      <div className="title">Admin Panel</div>
                    </div>
                  </Link>}

                {(isInstitutionAdmin || isGroupAdmin) &&
                  <Link
                    to="/organization/admin"
                    className={classNames(
                      'main-navigation-link',
                      { active: pathname.includes('/organization/admin') }
                    )}>
                    <div className="main-navigation-item">
                      <div className="title">Admin Panel</div>
                    </div>
                  </Link>}

                <Link
                  to="/settings"
                  className={classNames(
                    'main-navigation-link',
                    { active: pathname.includes('/settings') }
                  )}>
                  <div className="main-navigation-item">
                    <div className="title">Settings & Privacy</div>
                  </div>
                </Link>
              </>
            )}

            <div
              className="main-navigation-mobile-menu-toggle"
              onClick={onToggleMobileMenu}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z" fill="white"/>
              </svg>
              Menu
            </div>
          </div>
        </div>
      </div>

      <CSSTransition
        in={isMobileMenuOpen}
        timeout={400}
        classNames={transitionClasses}
        unmountOnExit>
        <MainMenuMobile
          profile={profile}
          pathname={pathname}
          onToggleMobileMenu={onToggleMobileMenu}/>
      </CSSTransition>
    </section>
  );
};

MainMenu.propTypes = {
  profile: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired
};

const mapStateToProps = ({ components: { isMobile }, profile: { data: currentUser } }) =>
  ({ isMobile, currentUser });

export default connect(mapStateToProps)(MainMenu);
