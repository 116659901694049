import initialState from './initialState';
import {
  GET_FEEDBACK_PRAISE_OPTIONS_REQUEST,
  GET_FEEDBACK_PRAISE_OPTIONS_SUCCESS,
  GET_FEEDBACK_PRAISE_OPTIONS_FAILURE,
  SUBMIT_FEEDBACK_PRAISE_REQUEST,
  SUBMIT_FEEDBACK_PRAISE_SUCCESS,
  SUBMIT_FEEDBACK_PRAISE_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.feedbackOptions, action) {
    
  switch (action.type) {
    
  case GET_FEEDBACK_PRAISE_OPTIONS_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };
      
  case GET_FEEDBACK_PRAISE_OPTIONS_SUCCESS: {
    return {
      ...state,
      isRequesting: false,
      data: { ...action.data.detail },
    };
  }
      
  case GET_FEEDBACK_PRAISE_OPTIONS_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };
    
  case SUBMIT_FEEDBACK_PRAISE_REQUEST:  
  case SUBMIT_FEEDBACK_PRAISE_SUCCESS: 
  case SUBMIT_FEEDBACK_PRAISE_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  default:
    return state;
  }
}
