import React from 'react';
import RequestedActionComponent from './table-columns/RequestedActionComponent';
import RequestedBaseRequestedOn from './table-columns/RequestedBaseRequestedOn';
import RequestedResendComponent from './table-columns/RequestedResendComponent';
import RequestedReviewerComponent from './table-columns/RequestedReviewerComponent';
import RequestedSkillComponent from './table-columns/RequestedSkillComponent';
import RequestedStatusComponent from './table-columns/RequestedStatusComponent';


// MyFeedback Request Sorting Table Components -------------------------------------------------
const RequestedFeedbackComponent = (props) => {
  return props.tblData.map((feedback, index) => (
    <tr key={index}>
      <RequestedBaseRequestedOn rowData={feedback} {...props} />
      <RequestedSkillComponent rowData={feedback} {...props} />
      <RequestedReviewerComponent rowData={feedback} />
      <RequestedResendComponent rowData={feedback} {...props} />
      <RequestedStatusComponent rowData={feedback} />
      <RequestedActionComponent rowData={feedback} {...props} />
    </tr>
  ));
};

export default RequestedFeedbackComponent;