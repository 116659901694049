import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Validator from '../../validator';
import { loadForm, guid } from '../../utils';

import * as componentActions from '../../redux/actions/components';

import CertificationSectionCard from '../../components/resume/sections/CertificationSectionCard';
import CertificationForm from '../../components/profile/forms/CertificationForm';
import formWithModal from '../../components/profile/modal/formWithModal';
import DeleteSectionItemConfirmModal from '../../components/resume/DeleteSectionItemConfirmModal';

class ResumeCertifications extends Component {
  static propTypes = {
    certifications: PropTypes.object,
    onChangeSection: PropTypes.func.isRequired,
    onChangeSectionTitle: PropTypes.func.isRequired,
    onUnloadForm: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    resumeCertifications: PropTypes.object.isRequired,
    onLoadForm: PropTypes.func.isRequired,
    forms: PropTypes.array,
    onRemoveResumeSection: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      editingId: null,
      removeCertificationId: null,
      title: props.resumeCertifications.title,
      certifications: Object.assign({}, this.props.certifications),
    };

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onEditCertification = this.onEditCertification.bind(this);
    this.onRemoveCertification = this.onRemoveCertification.bind(this);
    this.onCancelRemoveCertification = this.onCancelRemoveCertification.bind(this);
    this.onConfirmRemoveCertification = this.onConfirmRemoveCertification.bind(this);
  }

  formatEndDate = (dateString) => {
    return dateString === '' ? null : dateString;
  };

  isValid(field = null) {
    const { noend, startdate } = this.state.certifications;
    let rules = {};
    if (noend === true) {
      rules = {
        name: ['required', 'minLength|6', 'maxLength|100'],
        authority: ['required', 'maxLength|200'],
        license: ['required', 'maxLength|100'],
        url: ['unsafeurl'],
        startdate: ['required', 'startdate'],
      };
    } else {
      rules = {
        name: ['required', 'minLength|6', 'maxLength|100'],
        authority: ['required', 'maxLength|200'],
        license: ['required', 'maxLength|100'],
        url: ['unsafeurl'],
        startdate: ['required', 'startdate'],
        enddate: ['required', 'enddate', `beforeStart|${this.formatEndDate(startdate)}`],
      };
    }
    const { certifications, errors: prevErrors} = this.state;
    const validate = Validator.createValidator(rules, certifications, field);
    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    if (noend === true) {
      delete prevErrors['enddate'];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  onChangeTitle(event){
    const { value } = event.target;
    this.props.onChangeSectionTitle(value, 'certifications');
  }

  handleChange(event){
    let { name, type, checked, value } = event.target;
    const { certifications } = this.state;
    value = type === 'checkbox' ? checked : value;
    certifications[name] = value;

    this.setState({ certifications }, () => this.isValid(name));

    if (name === 'startdate') {
      this.isValid('enddate');
    }

  }

  onSave(){
    const { certifications, editingId } = this.state;
    const { resumeCertifications } = this.props;

    if (!this.isValid())
      return;

    const newCertificate = Object.assign({},{
      id: guid()
    }, certifications );

    const index = resumeCertifications.certificationsArray.findIndex(certification => certification.id === editingId);

    const certificationsArray = editingId === null ? [
      ...resumeCertifications.certificationsArray,
      newCertificate
    ] : [
      ...resumeCertifications.certificationsArray.slice(0, index),
      newCertificate,
      ...resumeCertifications.certificationsArray.slice(index+1)
    ];

    const data = Object.assign({}, resumeCertifications,{
      certificationsArray
    });

    this.props.onChangeSection(data, 'certifications');
    this.onCancel();
  }

  onCancel(){
    const { unloadForm } = this.props.actions;
    this.clearFormField();
    unloadForm('editCertificationsForm');
  }

  clearFormField(){
    const certifications = {
      name: '',
      authority: '',
      url: '',
      startdate: '',
      enddate: '',
      license: '',
      noend: false
    };

    this.setState({
      certifications,
      errors: {},
      editingId: null
    });
  }

  onEditCertification(event){
    const { id } = event.target;
    const { loadForm } = this.props.actions;
    const { certificationsArray } = this.props.resumeCertifications;
    const certificationObject = certificationsArray.find(certification => certification.id === id);
    const certifications = Object.assign({}, this.state.certifications, certificationObject);
    this.setState({
      certifications,
      editingId: id
    }, ()=> loadForm('editCertificationsForm'));
  }

  onRemoveCertification(event){
    const { id: removeCertificationId } = event.target;

    this.setState({ removeCertificationId });
  }

  onConfirmRemoveCertification() {
    const { removeCertificationId } = this.state;
    const { resumeCertifications } = this.props;

    const certificationsArray = resumeCertifications
      .certificationsArray
      .filter(certification => certification.id !== removeCertificationId);

    const data = Object.assign({}, resumeCertifications, { certificationsArray });

    this.props.onChangeSection(data, 'certifications');
    this.onCancelRemoveCertification();
  }

  onCancelRemoveCertification() {
    this.setState({ removeCertificationId: null });
  }

  EditingCertifications = formWithModal(CertificationForm);

  render() {
    const { resumeCertifications, onLoadForm, forms, onRemoveResumeSection } = this.props;
    const { certifications, errors, removeCertificationId } = this.state;

    const isFormOpen = loadForm(forms, 'editCertificationsForm');
    const isConfirmModalOpen = removeCertificationId !== null;

    return (
      <div className="resume-section create-resume-section-card">
        <div className="create-resume-title">
          <input
            type="text"
            value={resumeCertifications.title}
            placeholder="Certifications header"
            className="header-edit-textbox"
            onChange={this.onChangeTitle}/>
          <button
            onClick={onRemoveResumeSection}
            id="Certification"
            className="btn btn-danger btn-sm">
            <i className="fa fa-close"/>
          </button>
        </div>
        {!isFormOpen &&
          <div className="section-list-container">
            {resumeCertifications.certificationsArray.map(certification =>
              (<CertificationSectionCard
                key={certification.id}
                certificationDetails={certification}
                onEditCertification={this.onEditCertification}
                onRemoveCertification={this.onRemoveCertification}/>))}
            <button
              onClick={onLoadForm}
              data-form-name="editCertificationsForm"
              className="btn btn-primary w-100">
              Add Certification
            </button>
          </div>}

        {isFormOpen &&
          <div className="resume-form-container">
            <this.EditingCertifications
              resume
              errors={errors}
              certification={certifications}
              onSave={this.onSave}
              onCancel={this.onCancel}
              onChange={this.handleChange}
              exampleType={'Resume Certifications'}/>
          </div>}

        {isConfirmModalOpen &&
          <DeleteSectionItemConfirmModal
            onCancel={this.onCancelRemoveCertification}
            onConfirm={this.onConfirmRemoveCertification}
            isOpen={isConfirmModalOpen}/>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const certifications = {
    name: '',
    authority: '',
    url: '',
    startdate: '',
    enddate: '',
    license: '',
    noend: false
  };

  return {
    certifications,
    forms: state.components.forms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(componentActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResumeCertifications);
