import {
  ROUTE_REDIRECT,
  SET_ROUTER_HOOKED_TO_A_ROUTE,
  REMOVE_ROUTER_HOOKED_TO_A_ROUTE,
  STORE_AN_ACTION,
  CLEAR_ALL_ACTIONS_STORED,
} from '../../constants';

export const routeRedirect = (pathname, nextPathname) => {
  return { type: ROUTE_REDIRECT, routes: { pathname, nextPathname } };
};

export const setRouterHookedToARoute = (route) => {
  return { type: SET_ROUTER_HOOKED_TO_A_ROUTE, route };
};

export const storeActionForUseAfterwards = (action) => {
  return { type: STORE_AN_ACTION, actionName: action };
};

export const clearAllActionsStored = () => {
  return { type: CLEAR_ALL_ACTIONS_STORED };
};

export const removeRouterHookedToARoute = () => {
  return { type: REMOVE_ROUTER_HOOKED_TO_A_ROUTE };
};

export const redirect = (pathname, nextPathname) => {
  return (dispatch) => {
    dispatch(routeRedirect(pathname, nextPathname));

    if (pathname) {
      // TODO: navigate...
    }
  };
};
