import { CALL_API } from '../../middlewares/api';
import {
  GET_CLUSTERS_REQUEST,
  GET_CLUSTERS_SUCCESS,
  GET_CLUSTERS_FAILURE,
  GET_INDUSTRIES_REQUEST,
  GET_INDUSTRIES_SUCCESS,
  GET_INDUSTRIES_FAILURE,
  GET_OCCUPATIONS_REQUEST,
  GET_OCCUPATIONS_SUCCESS,
  GET_OCCUPATIONS_FAILURE,
  ADD_IR_REQUEST,
  ADD_IR_SUCCESS,
  ADD_IR_FAILURE,
  GET_IR_DATA_BY_SEARCH_REQUEST,
  GET_IR_DATA_BY_SEARCH_SUCCESS,
  GET_IR_DATA_BY_SEARCH_FAILURE,
  GET_CAREER_INTEREST_REQUEST,
  GET_CAREER_INTEREST_SUCCESS,
  GET_CAREER_INTEREST_FAILURE,
  GET_COUNTRIES_LIST_REQUEST,
  GET_COUNTRIES_LIST_SUCCESS,
  GET_COUNTRIES_LIST_FAILURE,
} from '../../constants';

export const getClusters = () => {
  const endpoint = 'user/get-cluster-industry-occupation?flag=clusters';

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [GET_CLUSTERS_REQUEST, GET_CLUSTERS_SUCCESS, GET_CLUSTERS_FAILURE],
    },
  };
};

export const getIndustries = (parentID) => {
  const endpoint = `user/get-cluster-industry-occupation?flag=industries&parent_id=${parentID}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        GET_INDUSTRIES_REQUEST,
        GET_INDUSTRIES_SUCCESS,
        GET_INDUSTRIES_FAILURE,
      ],
    },
  };
};

export const getOccupations = (parentID) => {
  const endpoint = `user/get-cluster-industry-occupation?flag=occupations&parent_id=${parentID}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        GET_OCCUPATIONS_REQUEST,
        GET_OCCUPATIONS_SUCCESS,
        GET_OCCUPATIONS_FAILURE,
      ],
    },
  };
};

export const addIR = (data) => {
  const endpoint = 'user/ir_signup_admin';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [ADD_IR_REQUEST, ADD_IR_SUCCESS, ADD_IR_FAILURE],
    },
  };
};

export const getCountries = () => {
  const endpoint = 'countries';
  
  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        GET_COUNTRIES_LIST_REQUEST,
        GET_COUNTRIES_LIST_SUCCESS,
        GET_COUNTRIES_LIST_FAILURE,
      ],
    },
  };
};

export const getStates = (countryId) => {
  const endpoint = `countries?countryId=${countryId}`;
  
  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        GET_COUNTRIES_LIST_REQUEST,
        GET_COUNTRIES_LIST_SUCCESS,
        GET_COUNTRIES_LIST_FAILURE,
      ],
    },
  };
};

export const getCities = (stateId) => {
  const endpoint = `countries?stateId=${stateId}`;
  
  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        GET_COUNTRIES_LIST_REQUEST,
        GET_COUNTRIES_LIST_SUCCESS,
        GET_COUNTRIES_LIST_FAILURE,
      ],
    },
  };
};

export const getIRDataBySearch = (email) => {
  const endpoint = `user/ir_signup_admin?search=${email}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        GET_IR_DATA_BY_SEARCH_REQUEST,
        GET_IR_DATA_BY_SEARCH_SUCCESS,
        GET_IR_DATA_BY_SEARCH_FAILURE,
      ],
    },
  };
};

export const getCareerInterest = () => {
  const endpoint = 'user/get-cluster-industry-occupation/all';

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [GET_CAREER_INTEREST_REQUEST, GET_CAREER_INTEREST_SUCCESS, GET_CAREER_INTEREST_FAILURE],
    },
  };
};