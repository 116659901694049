import React from 'react';

import {
  Row,
  Col,
} from 'reactstrap';

import PropTypes from 'prop-types';

import TextInput from '../../common/TextInput';

const ContactInfoUpdateSection = ({ updatedProfileData, errors, handleUpdateContactInfo }) => {
  return (
    <div>
      <Row className="contactSection">
        <Col md="6" lg="6" xs="12">
          <TextInput
            name="email"
            label="Email"
            placeholder="Email"
            error={errors.email}
            value={updatedProfileData.email}
            onChange={handleUpdateContactInfo}/>

          <TextInput
            name="secondaryEmail"
            label="Alternate Email"
            placeholder="Alternate Email"
            error={errors.secondaryEmail}
            value={updatedProfileData.secondaryEmail}
            onChange={handleUpdateContactInfo}/>

          <TextInput
            name="phoneNumber"
            label="Phone Number"
            placeholder="e.g. 555-555-5555"
            error={errors.phoneNumber}
            value={updatedProfileData.phoneNumber}
            onChange={handleUpdateContactInfo}/>
        </Col>
      </Row>
    </div>
  );
};

ContactInfoUpdateSection.propTypes = {
  errors: PropTypes.object.isRequired,
  updatedProfileData: PropTypes.object.isRequired,
  handleUpdateContactInfo: PropTypes.func.isRequired
};

export default ContactInfoUpdateSection;
