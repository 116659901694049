import { isUndefined } from 'lodash';

import { CALL_API } from '../../middlewares/api';

import {
  GET_ALL_ORG_REQUEST,
  GET_ALL_ORG_SUCCESS,
  GET_ALL_ORG_FAILURE,
  CREATE_ORGANIZATION_REQUEST,
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_FAILURE,
  UPDATE_ORGANIZATION_REQUEST,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAILURE,
  RESET_ORG_REDUCER,
  SET_SELECTED_ORGANIZATION,
  SEARCH_ORGANIZATIONS_REQUEST,
  SEARCH_ORGANIZATIONS_SUCCESS,
  SEARCH_ORGANIZATIONS_FAILURE,
  ONCHANGE_ORGANIZATIONS_SEARCH_QUERY
} from '../../constants';

export const clearOrganisationReducer = () => {
  return { type: RESET_ORG_REDUCER };
};

export const getAllOrganizations = (urlParamString, isInitialRequest = false) => {
  let endpoint = 'institution/institution';

  if (!isUndefined(urlParamString)) {
    endpoint = `${endpoint}?${urlParamString}`;
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      isInitialRequest,
      authenticated: true,
      types: [GET_ALL_ORG_REQUEST, GET_ALL_ORG_SUCCESS, GET_ALL_ORG_FAILURE],
    },
  };
};

export const searchOrganizations = (urlParamString) => {
  let endpoint = 'institution/institution';

  if (!isUndefined(urlParamString)) {
    endpoint = `${endpoint}?${urlParamString}`;
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        SEARCH_ORGANIZATIONS_REQUEST,
        SEARCH_ORGANIZATIONS_SUCCESS,
        SEARCH_ORGANIZATIONS_FAILURE
      ],
    },
  };
};

export const saveOrganization = (data) => {
  const endpoint = 'institution/institution';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        CREATE_ORGANIZATION_REQUEST,
        CREATE_ORGANIZATION_SUCCESS,
        CREATE_ORGANIZATION_FAILURE,
      ],
    },
  };
};

export const updateOrganization = (id, data) => {
  const endpoint = `institution/institution/${id}`;

  return {
    [CALL_API]: {
      data,
      id,
      endpoint,
      method: 'patch',
      authenticated: true,
      types: [
        UPDATE_ORGANIZATION_REQUEST,
        UPDATE_ORGANIZATION_SUCCESS,
        UPDATE_ORGANIZATION_FAILURE,
      ],
    },
  };
};

export const onChangeOrganizationsSearchQuery = (searchQuery) => {
  return dispatch =>
    dispatch({ type: ONCHANGE_ORGANIZATIONS_SEARCH_QUERY, searchQuery });
};

export const setSelectedOrganization = selectedOrganization => ({
  type: SET_SELECTED_ORGANIZATION,
  selectedOrganization
});
