import PropTypes from 'prop-types';
import React from 'react';

const AuthorsCard = ({ publication }) => {
  return (
    <div className="authors">
      Author(s):
      <ul>
        {publication.author.split(',').map((item, index) => {
          return (
            <li key={index}>
              {item}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

AuthorsCard.propTypes = {
  publication: PropTypes.object.isRequired
};

export default AuthorsCard;
