import PropTypes from 'prop-types';
import React from 'react';
import WallCommentList from './WallCommentList';
import { pure } from 'recompose';
import _isEmpty from 'lodash/isEmpty';

const WallComment = ({wallPost, loadForms, postComments})=>{
  if(_isEmpty(postComments)) {
    return null;
  }

  return(
    <WallCommentList
      wallPostId={wallPost.id}
      postComments={postComments}
      loadForms={loadForms}/>
  );
};

WallComment.propTypes = {
  wallPost: PropTypes.object.isRequired,
  loadForms: PropTypes.array.isRequired,
  postComments: PropTypes.array.isRequired
};
export default pure(WallComment);
