import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../common/Button';
import TextInput from '../../common/TextInput';
import RichEditor from '../../../plugins/RichEditor';

const blockTypes = [
  { label: 'Blockquote', style: 'blockquote', icon: 'fa-quote-right' },
  { label: 'Link', style: 'LINK', icon: 'fa-link' }
];

const inlineTypes = [
  { label: 'Bold', style: 'BOLD', icon: 'fa-bold'},
  { label: 'Italic', style: 'ITALIC', icon: 'fa-italic' },
  { label: 'Underline', style: 'UNDERLINE', icon: 'fa-underline' }
];

const styles = {
  editorWrapper: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    padding: '.5rem .5rem',
    borderRadius: 4,
    marginBottom: '1rem'
  },
  withError: {
    border: '1px solid #d9534f',
    padding: '.5rem .5rem',
    borderRadius: 4,
  },
  errorTextWrapper: {
    padding: '.5rem 0',
  },
  panelHeading: { minHeight: 30, backgroundColor: '#fafafa', marginBottom: 10 },
  heading: { textAlign: 'center', paddingRight: 40, color: 'black', paddingTop: 10 }
};

const QuestionAnswerForm = ({
  isAdmin,
  onCancel,
  errors,
  section,
  onChange,
  onSave,
  onDelete,
  isSubmitting,
  isUpdating,
  isDeleting,
  formType,
  setEditorState,
  getEditorState
}) => {
  const hasAnswerError = Object.keys(errors).includes('answer');

  const saveButtonTextPrefix = section.id ? 'Updat' : 'Sav';

  return (
    <div>
      {isAdmin ?
        <div className="card-header" style={styles.panelHeading}>
          <h3 style={styles.heading}>
            Q & A
          </h3>
        </div> : ''}

      <div className="card-block p-3">
        <form id={formType ? `${formType.formName}_${formType.id}` : ''}>
          <div className="row">
            <div className="col-sm-12">
              <TextInput
                autoFocus
                name="question"
                error={errors.question}
                value={section.question}
                onChange={onChange}
                placeholder="Question"/>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div style={hasAnswerError ? styles.withError : styles.editorWrapper}>
                <RichEditor
                  placeholder="Answer"
                  blockTypes={blockTypes}
                  inlineTypes={inlineTypes}
                  setEditorState={setEditorState}
                  getEditorState={getEditorState}/>
              </div>
              {
                hasAnswerError &&
                  <label
                    className="form-control-label"
                    style={styles.errorTextWrapper}>
                    <span className="danger">{errors.answer}</span>
                  </label>
              }
            </div>
          </div>

          {!formType ?
            <Button
              type="submit"
              onClick={onDelete}
              disabled={isDeleting}
              styles={{marginLeft: 0}}
              buttonClass="btn-danger"
              buttonText={isDeleting ? 'Deleting...' : 'Delete'}/> : ''}
          <Button
            type="submit"
            onClick={onSave}
            disabled={isSubmitting || isUpdating}
            buttonClass="btn-primary float-right"
            buttonText={isSubmitting || isUpdating ? `${saveButtonTextPrefix}ing...` : `${saveButtonTextPrefix}e`}/>
          <Button
            type="button"
            buttonId={section ? `question-answer_${section.id}` : ''}
            onClick={onCancel}
            buttonClass="btn btn-secondary float-right"
            buttonText="Cancel"/>
        </form>
      </div>
    </div>
  );
};

QuestionAnswerForm.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  errors: PropTypes.object,
  section: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isUpdating: PropTypes.bool,
  isDeleting: PropTypes.bool,
  formType: PropTypes.object,
  setEditorState: PropTypes.func.isRequired,
  getEditorState: PropTypes.func.isRequired
};

export default QuestionAnswerForm;
