import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { scrollToTop, scrollToBottom } from '../../../utils';

class ScrollToEnds extends Component {
  static propTypes = {
    childComponent: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func
    ]),
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      isEligibleForScrollToBottom: true,
      isEligibleForScrollToTop: false
    };

    this.shouldRenderScrollToEnds = this.shouldRenderScrollToEnds.bind(this);
    this.shouldRenderScrollToBottom = this.shouldRenderScrollToBottom.bind(this);
    this.shouldRenderScrollToTop = this.shouldRenderScrollToTop.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.shouldRenderScrollToEnds, false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.shouldRenderScrollToEnds);
  }

  shouldRenderScrollToTop() {
    const {
      isEligibleForScrollToTop
    } = this.state;

    const pageYOffset = window.pageYOffset;

    const conditionToDoNothing = (
      (pageYOffset > 50 && isEligibleForScrollToTop)
      ||
      (pageYOffset < 50 && !isEligibleForScrollToTop)
    );

    if (conditionToDoNothing)
      return;

    if (pageYOffset < 50 && isEligibleForScrollToTop)
      return this.setState({
        isEligibleForScrollToTop: false
      });

    this.setState({
      isEligibleForScrollToTop: true
    });
  }

  shouldRenderScrollToBottom() {
    const {
      isEligibleForScrollToBottom
    } = this.state;

    const isFooterContentVisible = (
      window.innerHeight + window.pageYOffset - 0 >= document.body.offsetHeight
    );

    const conditionToDoNothing = (
      (isFooterContentVisible && !isEligibleForScrollToBottom)
      ||
      (!isFooterContentVisible && isEligibleForScrollToBottom)
    );

    if (conditionToDoNothing)
      return;

    if (isFooterContentVisible && isEligibleForScrollToBottom)
      return this.setState({
        isEligibleForScrollToBottom: false
      });

    this.setState({
      isEligibleForScrollToBottom: true
    });
  }

  shouldRenderScrollToEnds() {
    this.shouldRenderScrollToBottom();

    this.shouldRenderScrollToTop();
  }

  render() {
    const {
      isEligibleForScrollToTop,
      isEligibleForScrollToBottom
    } = this.state;

    return(
      <div>
        {
          isEligibleForScrollToTop &&
            <a className="scrollup" onClick={scrollToTop}>
              <i className="fa fa-long-arrow-up" aria-hidden="true" />
            </a>
        }

        {
          isEligibleForScrollToBottom &&
            <a className="scrolldown" onClick={scrollToBottom}>
              <i className="fa fa-long-arrow-down" aria-hidden="true"/>
            </a>
        }

        <this.props.childComponent
          {...this.props}/>
      </div>
    );
  }
}

export default ScrollToEnds;
