import { CALL_API } from '../../middlewares/api';

import {
  INTERESTS_REQUEST,
  INTERESTS_SUCCESS,
  INTERESTS_FAILURE,
  CREATE_INTEREST_REQUEST,
  CREATE_INTEREST_SUCCESS,
  CREATE_INTEREST_FAILURE,
  UPDATE_INTEREST_REQUEST,
  UPDATE_INTEREST_SUCCESS,
  UPDATE_INTEREST_FAILURE,
  DELETE_INTEREST_REQUEST,
  DELETE_INTEREST_SUCCESS,
  DELETE_INTEREST_FAILURE,
} from '../../constants';

export const interestsRequest = (userId) => {
  const endpoint = `user/interests?user=${userId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [INTERESTS_REQUEST, INTERESTS_SUCCESS, INTERESTS_FAILURE],
    },
  };
};

export const saveInterest = (data) => {
  const endpoint = 'user/interests';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        CREATE_INTEREST_REQUEST,
        CREATE_INTEREST_SUCCESS,
        CREATE_INTEREST_FAILURE,
      ],
    },
  };
};

export const updateInterest = (index, data) => {
  const endpoint = `user/interests/${data.id}`;

  return {
    [CALL_API]: {
      data,
      index,
      endpoint,
      method: 'put',
      authenticated: true,
      types: [
        UPDATE_INTEREST_REQUEST,
        UPDATE_INTEREST_SUCCESS,
        UPDATE_INTEREST_FAILURE,
      ],
    },
  };
};

export const deleteInterest = (interestId) => {
  const endpoint = `user/interests/${interestId}`;

  return {
    [CALL_API]: {
      endpoint,
      id: interestId,
      method: 'delete',
      authenticated: true,
      types: [
        DELETE_INTEREST_REQUEST,
        DELETE_INTEREST_SUCCESS,
        DELETE_INTEREST_FAILURE,
      ],
    },
  };
};
