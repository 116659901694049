import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { Input, Label, Collapse } from 'reactstrap';

import Button from '../common/Button';
import CpHtmlParser from '../../components/common/CpHtmlParser';
import FileDetailsModal from '../../containers/portfolio/FileDetailsModal';

class EndorsementRequestContents extends Component {
  constructor() {
    super();
    this.state = {
      portfolioId: '',
      testimonial: '',
    };
  }

  toggleViewPortfolio = fileId => () => {
    this.setState({ portfolioId: fileId });
    this.props.actions.openModal(`open-view-file-modal-${fileId}`);
  }

  render() {
    const {
      collapseData,
      handleCollapse,
      activeBadgeSlide,
      checkboxData,
      onCheckboxChange,
      requestor,
      singleBadge,
      onContinue,
      modal,
      handleTestimonial,
      testimonial,
    } = this.props;
    const {statement, justifications, acknowledgements, files, endorsements } = activeBadgeSlide;
    const confirmedEndorsements = endorsements.filter(endorsement => endorsement.name.length !== 0);
    const isViewPortfolioModalOpen = modal && modal === `open-view-file-modal-${this.state.portfolioId}`;

    return (
      <div>
        {!activeBadgeSlide.skill && (<div
          className={classNames('info-header clickable',{
            'collapsed': !collapseData[0].open
          })}>
          <div
            onClick={handleCollapse(collapseData[0].id)}>
            <div className="header">{collapseData[0].name}</div>
          </div>
          <Collapse isOpen={collapseData[0].open}>
            {
              acknowledgements?.length === 0 &&
                <p>No Claimed Abilities for this badge</p>
            }
            {
              acknowledgements?.length > 0 &&
                <div className="item-container">
                  {
                    acknowledgements.map(acknowledgement =>
                      <div className="item" key={acknowledgement.id}>{acknowledgement.acknowledgement}</div>)
                  }
                </div>
            }
          </Collapse>
        </div>)}
        {!activeBadgeSlide.skill && (<div
          className={classNames('info-header clickable',{
            'collapsed': !collapseData[1].open
          })}>
          <div
            onClick={handleCollapse(collapseData[1].id)}>
            <div className="header">{collapseData[1].name}</div>
          </div>
          <Collapse isOpen={collapseData[1].open}>
            {(statement && Object.keys(statement).length === 0 && (justifications.length === 0)) &&
              <p>No written justification for  this badge.</p>}

            {statement && Object.keys(statement).length > 0 &&
              <div className="item-container">
                <div className="item">
                  <div className="ql-editor no-padding-in-quill">
                    <CpHtmlParser htmlString={statement.statement}/>
                  </div>
                </div>
              </div>}

            {justifications?.length > 0 &&
              <div className="item-container">
                {justifications.map(justification =>
                  (<div className="item" key={justification.id}>
                    <div className="ql-editor no-padding-in-quill">
                      <b style={{fontSize: 16}}>{justification.question}</b>
                      <div style={{marginTop: 10}}>
                        <CpHtmlParser htmlString={justification.answer}/>
                      </div>
                    </div>
                  </div>))}
              </div>}

          </Collapse>
        </div>)}
        <div
          className={classNames('info-header clickable',{
            'collapsed': !collapseData[2].open
          })}>
          <div
            onClick={handleCollapse(collapseData[2].id)}>
            <div className="header">{collapseData[2].name}</div>
          </div>
          <Collapse isOpen={collapseData[2].open}>
            {
              files.length === 0 &&
                <p>No Portfolio Files for this badge</p>
            }
            {
              files.length > 0 &&
                <div className="item-container">
                  {
                    files.map(file =>
                      (<div className="item" key={file.id}>
                        <a
                          className="clickable orange"
                          onClick={this.toggleViewPortfolio(file.id)}>{file.title}
                        </a>
                      </div>))}

                  {isViewPortfolioModalOpen &&
                    <FileDetailsModal
                      fileId={this.state.portfolioId}
                      isOpen={isViewPortfolioModalOpen}/>
                  }
                </div>
            }
          </Collapse>
        </div>
        <div
          className={classNames('info-header clickable',{
            'collapsed': !collapseData[3].open
          })}>
          <div
            onClick={handleCollapse(collapseData[3].id)}>
            <div className="header">{collapseData[3].name}</div>
          </div>
          <Collapse isOpen={collapseData[3].open}>
            {
              confirmedEndorsements.length === 0 &&
                <p>{activeBadgeSlide.skill ? 'No one has endorsed this skill yet.' : 'No one has endorsed this badge yet.'}</p>
            }
            {
              confirmedEndorsements.length > 0 &&
                <div className="item-container">
                  {
                    confirmedEndorsements.map(endorsement =>
                      (<div key={endorsement.id} className="item">
                        <i className="fa fa-check-square"/> {`${endorsement.name}, ${endorsement.position}, ${endorsement.organization}`}
                      </div>))}
                </div>
            }
          </Collapse>
        </div>

        <div className="agreement p-3">
          <div className="form-group form-check">
            <input
              type="checkbox"
              name=""
              id={checkboxData.id}
              checked={checkboxData.checked}
              onChange={onCheckboxChange}
              className="form-check-input"/>
            <label className="form-check-label ml-1" htmlFor="i-accept">
              {activeBadgeSlide.skill ? `I've observed ${requestor.name}'s claimed skill in a training activity, project, or work setting. Based on my observation of ${requestor.name}'s skill and review of the portfolio files supplied as evidence, I confidently confirm ${requestor.name} has this skill.` : `I've observed ${requestor.name}'s claimed abilities in a training activity, project, or work setting. Based on my observation of ${requestor.name}'s abilities and review of the supplied evidence, I confidently confirm ${requestor.name} has this skill.`}
            </label>
          </div>
        </div>
        <Label
          className="pt-2"
          style={{ color: '#000' }}
          for="feedbackComment">
          <strong className="font16 mt-2">
            Consider providing a testimonial about {requestor.name}'s skill
          </strong>
        </Label>
        <Input
          type="textarea"
          placeholder={`Write your testimonial to praise ${requestor.name}'s skill.`}
          name="feedbackComment"
          value={testimonial}
          onChange={handleTestimonial}
          id="feedbackComment"/>       
        {
          singleBadge &&
            <Button
              type="button"
              buttonClass="btn mt-3 btn-primary"
              buttonText="Continue"
              styles={{ marginLeft: 0}}
              onClick={onContinue}
              disabled={!checkboxData.checked}/>
        }
      </div>
    );
  }
}


EndorsementRequestContents.propTypes = {
  actions: PropTypes.object,
  modal: PropTypes.string,
  collapseData: PropTypes.array.isRequired,
  handleCollapse: PropTypes.func.isRequired,
  activeBadgeSlide: PropTypes.object,
  checkboxData: PropTypes.object.isRequired,
  requestor: PropTypes.object,
  singleBadge: PropTypes.bool,
  onContinue: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  handleTestimonial: PropTypes.func,
  testimonial: PropTypes.string,
};

export default EndorsementRequestContents;
