import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getAllOrganizations,
  setSelectedOrganization
} from '../../redux/actions/organizations';

import {
  getUserAdminGroups,
  setIsAuthUserSelectedInstitutionAdmin
} from '../../redux/actions/groups';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Collapse } from 'reactstrap';

class OrganizationsDropdown extends Component {
  static propTypes = {
    page: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    groupsPage: PropTypes.number,
    parentPage: PropTypes.string,
    groupsPageCount: PropTypes.number,
    actions: PropTypes.object.isRequired,
    selectedOrganization: PropTypes.object.isRequired,
    userAdminGroups: PropTypes.array,
    institutionId: PropTypes.string,
    isRequesting: PropTypes.bool.isRequired,
    organizationsList: PropTypes.array.isRequired,
    organizationsCount: PropTypes.number.isRequired,
    institutionUsersCount: PropTypes.number
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };

    this.onLoadMore = this.onLoadMore.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.onSelectGroup = this.onSelectGroup.bind(this);
  }

  handleToggle() {
    const { isOpen } = this.state;

    this.setState({ isOpen : ! isOpen });
  }

  onLoadMore(event) {
    event.preventDefault();

    const {
      page,
      actions,
      pageCount
    } = this.props;

    if (page === pageCount) return;

    const urlParamString = `page=${page + 1}`;

    actions.getAllOrganizations(urlParamString);
  }

  onSelectGroup(event) {
    event.preventDefault();

    const organizationId = event.target.closest('a').id;
    const { actions, organizationsList } = this.props;

    const selectedOrganization = organizationsList.find(org => org.id === organizationId);
    //
    //    actions.resetFilterParams();
    actions.setSelectedOrganization(selectedOrganization);

    if (organizationId !== 'all-organizations') {
      actions.setIsAuthUserSelectedInstitutionAdmin(true);
      actions.getUserAdminGroups(organizationId, '', true);
    }

    //    this.fetchUsers(groupId);
    //    this.fetchGroupAdmins(groupId);
    this.setState({ isOpen: false });
  }

  fetchUsers() {
    //    const { actions, parentPage } = this.props;
    //
    //    if (parentPage !== 'admin-dashboard') {
    //      return;
    //    }
    //
    //    const urlProps = { ...this.props, groupId, page: 1 };
    //    const urlParamString = generateBaseRequestUrl(urlProps);
    //
    //    actions.getUsers(urlParamString);
  }

  fetchGroupAdmins() {
    //    const { actions, parentPage } = this.props;
    //
    //    if (parentPage !== 'manage-admins') {
    //      return;
    //    }
    //
    //    if (groupId === 'all-groups') {
    //      actions.resetGroupAdmins();
    //    }
    //
    //    if (groupId !== 'all-groups') {
    //      actions.getGroupAdmins(groupId);
    //    }
  }

  render() {
    const { isOpen } = this.state;
    const {
      page,
      pageCount,
      isRequesting,
      selectedOrganization,
      organizationsList,
      organizationsCount
    } = this.props;

    const hasMoreGroups = (pageCount > page);

    return (
      <div style={{marginBottom: 24}}>
        <div className="admin-list-header" onClick={this.handleToggle}>
          {`${selectedOrganization.name}`}
          <i className={classNames('fa float-right', {
            'fa-chevron-down': !isOpen,
            'fa-chevron-up': isOpen
          })}/>
        </div>

        <Collapse isOpen={isOpen}>
          <div
            style={{
              marginTop: 8,
              boxShadow: '1px 1px 5px rgba(0,0,0,.05)',
              backgroundColor:'white',
              padding: 16,
              borderRadius: 4
            }}>
            <div className="row">
              <div className="col-lg-12">
                {/* <GroupsSearch/> */}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="admin-item">
                  <div className="admin-group-list">
                    {organizationsList.map(organization => (
                      <a
                        href="#"
                        key={organization.id}
                        className="item"
                        id={organization.id}
                        onClick={this.onSelectGroup}>
                        <div>
                          <span
                            className="img-responsive initial-avatar group">
                            {organization.name.slice(0, 1)}
                          </span>
                        </div>
                        <div>
                          <div>
                            {`${organization.name}`}
                          </div>
                          <div style={{
                            fontSize: 12
                          }}>
                            {(organization.id === 'all-organizations') ?
                              `${organizationsCount.toLocaleString()} Organizations` :
                              `${organization.groupsCount.toLocaleString()} Groups`}
                          </div>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="admins-more">
              {! isRequesting && hasMoreGroups &&
                <a href="#" onClick={this.onLoadMore}>Show More</a>}

              {(! isRequesting) && (! hasMoreGroups) &&
                <span>No more groups</span>}

              {isRequesting &&
                <span>
                  <i className="fa fa-spinner fa-pulse fa-lg fa-fw" />
                  Loading...
                </span>}
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    organizations: {
      isRequesting,
      selectedOrganization,
      data: organizationsList,
      paginationData: {
        page,
        page_count: pageCount,
        total_items: organizationsCount
      }
    }
  } = state;

  return {
    page,
    pageCount,
    isRequesting,
    organizationsList,
    organizationsCount,
    selectedOrganization
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    getAllOrganizations,
    setIsAuthUserSelectedInstitutionAdmin,
    setSelectedOrganization,
    getUserAdminGroups
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationsDropdown);
