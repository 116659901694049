import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { capitalize, isEmpty} from 'lodash';

import * as wallPostActions from '../../redux/actions/wall-posts';
import * as searchActions from '../../redux/actions/search';
import { toastr } from 'react-redux-toastr';


import ShareModalCard from '../../components/skill-builders/discussions/ShareModalCard';

class WallShareModal extends Component {
  static propTypes = {
    wallShare: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    searchPage: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      wallShare: Object.assign({}, this.props.wallShare, { permissions: '1', sharedQuotedText: '' }),
      showMore: false,
      visibilityDropdownOpen: false
    };

    this.onChange = this.onChange.bind(this);
    this.onPost = this.onPost.bind(this);
    this.onShowMore = this.onShowMore.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSelectVisibilty = this.onSelectVisibilty.bind(this);
    this.onToggleVisibilityDropdown = this.onToggleVisibilityDropdown.bind(this);
  }

  onToggleShowAll() {
    this.setState({
      showAll: !this.state.showAll
    });
  }

  closeModal() {
    const { actions, searchPage } = this.props;

    if(searchPage){
      return actions.resetShareResultItemPost();
    }
    actions.resetShareWallPost();
  }

  onToggleVisibilityDropdown() {
    const { visibilityDropdownOpen } = this.state;
    this.setState({
      visibilityDropdownOpen: !visibilityDropdownOpen
    });
  }

  onSelectVisibilty(id){

    return () => {
      const wallShare = { ...this.state.wallShare, permissions:id};

      this.setState({
        wallShare,
        visibilityDropdownOpen: false
      });
    };
  }

  onChange(event) {
    const field = event.target.name;
    const { wallShare } = this.state;

    wallShare[field] = event.target.value;

    this.setState({ wallShare });
  }

  setReflectionAnswersContentContainerRef(div) {
    this.reflectionSharingContentsDiv = div;
  }

  onPost(event) {
    event.preventDefault();

    const { wallShare } = this.state;
    const {
      id,
      post: postString,
      sharedEntityType,
      sharedQuotedText,
      permissions,
      sharedBadgeIssued,
      sharedFile,
      sharedInterviewResource,
      sharedSkillBuilderReflections
    } = wallShare;

    let sharedPost = '';

    if ((sharedEntityType === 'wall') || (sharedEntityType === 'discussion')) {
      const sharedEntityKey = `shared${capitalize(sharedEntityType)}`;

      sharedPost = wallShare[sharedEntityKey];
    }

    const postId = (sharedPost !== '') ? sharedPost.id : id;
    const post = (sharedPost !== '') ? sharedPost.post : postString;

    let data = {
      post,
      permissions,
      sharedQuotedText
    };

    switch(sharedEntityType){

    case 'interviewResource':
      data = Object.assign({}, data, {
        sharedInterviewResource: sharedInterviewResource.id
      });
      break;

    case 'badgeIssued':
      data = Object.assign({}, data, {
        sharedBadgeIssued: sharedBadgeIssued.id
      });
      break;

    case 'file':
      data = Object.assign({}, data, {
        sharedFile: sharedFile.id
      });
      break;

    case 'skillBuilderReflections':{
      const reflectionArray = sharedSkillBuilderReflections.map(selected => selected.id);

      data = Object.assign({}, data, {
        sharedSkillBuilderReflections: reflectionArray
      });
      break;
    }

    default:
      data = Object.assign({}, data, {
        sharedWall: postId
      });
      break;

    }

    this.props.actions.saveWallPost(data)
      .then((response) => {
        if(isEmpty(response.wallPosts.error)){
          toastr.success('', 'You successfully shared this as a post');
        }
        this.closeModal();
      });
  }

  onShowMore(){
    this.setState({
      showMore: !this.state.showMore
    });
  }

  render() {
    const {
      showMore,
      wallShare,
      visibilityDropdownOpen,
    } = this.state;
    const { wallShare: propsWallShare, isSubmitting } = this.props;

    return (
      <ShareModalCard
        onPost={this.onPost}
        onShowMore={this.onShowMore}
        showMore={showMore}
        onChange={this.onChange}
        closeModal={this.closeModal}
        isSubmitting={isSubmitting}
        quote={wallShare.sharedQuotedText}
        permissions={wallShare.permissions}
        onSelectVisibilty={this.onSelectVisibilty}
        visibilityDropdownOpen={visibilityDropdownOpen}
        onToggleVisibilityDropdown={this.onToggleVisibilityDropdown}
        discussionShare={propsWallShare}/>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isSubmitting: state.wallPosts.isSubmitting,
    wallShare: ownProps.searchPage ? state.search.resultPostItemShare : state.wallPosts.wallShare
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, wallPostActions, searchActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WallShareModal);
