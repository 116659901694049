import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as searchActions from '../../redux/actions/search';
import * as routerActions from '../../redux/actions/router';
import * as profileActions from '../../redux/actions/profile';
import * as followingActions from '../../redux/actions/following';

import Loading from '../../components/common/Loading';
import PaginationHandler from '../../components/common/PaginationHandler';
import PeopleSearchItemHandler from './PeopleSearchItemHandler';

class CpPeopleSearch extends Component {
  static propTypes = {
    search: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    following: PropTypes.object,
    isAuthenticated: PropTypes.bool.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      sort: 'ascending',
    };

    this.onNext = this.onNext.bind(this);
    this.onPrev = this.onPrev.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
    this.onFollow = this.onFollow.bind(this);
    this.handleSort = this.handleSort.bind(this);
  }

  componentWillMount(){
    const { actions, search, isAuthenticated } = this.props;

    actions.followingRequest(isAuthenticated);

    if(search.query && search.query !== ''){
      actions.clearSearchReducer();
      actions.cpSearch(search.query, 'people');
    }
  }

  componentWillReceiveProps(nextProps){
    const { query: nextQuery } = nextProps.search;
    const { query } = this.props.search;

    if(nextQuery && nextQuery !== query){
      this.props.actions.clearSearchReducer();
      this.props.actions.cpSearch(nextQuery, 'people');
    }
  }

  handleSort(event){
    const sort = event.target.value;

    if (sort === this.state.sort)
      return;

    this.setState({
      sort
    }, () => {
      const { actions, search } = this.props;
      const { query, paginationData } = search;
      actions.clearSearchReducer();
      actions.cpSearch(query, 'people', paginationData.page, sort );
    });
  }

  onFollow(user){
    return () => {
      const { actions, following } = this.props;

      if(following.isRequesting)
        return;

      if(user.isUserFollower.status){
        if(user.isUserFollower.connectId !== '')
          return actions.unfollowSearchResultPeople(user.isUserFollower.connectId, user.id)
            .then(() => actions.decrementFollowingCount());

        return;
      }

      const data = { user: user.id };

      actions.followSearchResultPeople(data)
        .then(() => actions.incrementFollowingCount());
    };
  }

  onNext(){
    const { actions, search } = this.props;
    const { query, paginationData } = search;
    actions.clearSearchReducer();
    actions.cpSearch(query, 'people', paginationData.page + 1);
  }

  onPrev(){
    const { actions, search } = this.props;
    const { query, paginationData } = search;
    actions.clearSearchReducer();
    actions.cpSearch(query, 'people', paginationData.page - 1);
  }

  onSelectPage(page){
    return () => {
      const { actions, search } = this.props;
      const { query } = search;
      actions.clearSearchReducer();
      actions.cpSearch(query, 'people', page);
    };
  }

  render() {
    const { sort } = this.state;
    const { search } = this.props;
    const { data: results, paginationData, query, isRequesting } = search;

    if(query === '' || !query){
      return (
        <div className="searchResultsContainer">
          <div className="row search-hint no-badges-claimed-hint mt-0">
            <div className="col-sm-12">
              <span className="fa fa-info-circle" aria-hidden="true"/>
              <p>Search People, Skill Badges, Portfolio Files, or Posts.</p>
            </div>
          </div>
        </div>
      );
    }

    if(isRequesting && query && query !== ''){
      return(
        <div className="loading-container">
          <Loading/>
        </div>
      );
    }
    return (
      <div className="searchResultsContainer">
        <div className="container">
          <div className="searchResultsContent">
            <div className="searchResultsHeader clearfix">
              <div className="row align-items-center">
                <div className="col searchHeader">
                  <h2>Showing <strong>{paginationData.total_items} Results</strong> for <strong>"{query}"</strong></h2>
                </div>
                {
                  results.people.length > 1 &&
                    <div className="col-md-auto sortBy">
                      <form>
                        <div className="form-inline form-group mb-0">
                          <strong>
                            Sort by&nbsp;&nbsp;
                          </strong>
                          <select
                            value={sort}
                            onChange={this.handleSort}
                            className="form-control form-control-sm">
                            <option value="ascending">Ascending</option>
                            <option value="descending">Descending</option>
                          </select>
                        </div>
                      </form>
                    </div>
                }
              </div>
            </div>
            {results.people.length === 0 &&
              <div className="empty-search-container">
                <div className="message-box">
                  <h4>No results found</h4>
                  <div>Sorry, there were no matches for your search query.</div>
                </div>
              </div>}

            {results.people.map((resultItem, index) =>
              (<PeopleSearchItemHandler
                key={index}
                people={resultItem}
                onFollow={this.onFollow}/>))}

            {! isRequesting && results.people.length > 0 &&
              <PaginationHandler
                page={paginationData.page}
                page_count={paginationData.page_count}
                onNext={this.onNext}
                onPrev={this.onPrev}
                onSelectPage={this.onSelectPage}/>}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, search, following } = state;
  const { isAuthenticated } = auth;

  return {
    search,
    following,
    isAuthenticated
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    searchActions,
    routerActions,
    followingActions,
    profileActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(CpPeopleSearch);
