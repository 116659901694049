import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Outlet } from 'react-router-dom';

import { withHooks } from '../../utils/withHooks';

import * as searchActions from '../../redux/actions/search';
import * as routerActions from '../../redux/actions/router';

import { Helmet } from 'react-helmet';
import classNames from 'classnames';

class SearchWrapper extends Component {
  static propTypes = {
    search: PropTypes.object,
    navigate: PropTypes.func.isRequired,
    searchObj: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      searchValue : this.props.searchObj.searchValue,
      selectedType: this.props.searchObj.selectedType
    };

    this.onChange = this.onChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
    this.redirectRoute = this.redirectRoute.bind(this);
    this.onClearBtnClicked = this.onClearBtnClicked.bind(this);
  }

  componentWillMount(){
    const [,,typeofSearch] = location.pathname.split('/');
    const [,query] = location.search.split('?q=');

    if(typeofSearch){
      this.setState({
        selectedType : typeofSearch
      });
    }

    if(query && query !== ''){
      this.props.actions.updateSearchQuery(query);

      this.setState({
        searchValue: query
      });

    }
  }

  componentWillReceiveProps(nextProps){
    const { type } = nextProps.search;
    const [,,typeofSearch] = location.pathname.split('/');

    if(type !== typeofSearch && typeofSearch !== '' && typeofSearch){

      this.setState({
        selectedType: typeofSearch
      });
    }
  }

  onClearBtnClicked(){

    this.setState({
      searchValue : '',
      selectedType: 'people'
    });

    this.props.actions.clearSearchReducer();
  }

  handleKeyPress(event) {

    if (event.key === 'Enter')
      return this.onSubmit(event);

  }

  redirectRoute(){
    const { searchValue, selectedType } = this.state;
    const { navigate } = this.props;

    if(searchValue === ''){
      this.props.actions.redirect(`/search/${selectedType}`);
      return navigate(`/search/${selectedType}`);
    }

    this.props.actions.updateSearchQuery(searchValue);
    this.props.actions.redirect({pathname: `/search/${selectedType}`,
      search:`?query=${searchValue}`
    });

    navigate(`/search/${selectedType}?query=${searchValue}`);
  }

  onSubmit(event){
    event.preventDefault();
    const { searchValue } = this.state;
    this.props.actions.updateSearchQuery(searchValue);
    this.redirectRoute();
  }

  onChangeType(type){
    return () => {
      this.setState({
        selectedType: type
      }, () => {
        this.redirectRoute();
      });
    };
  }

  onChange(event) {
    const { value } = event.target;

    this.setState({
      searchValue: value
    });
  }

  render() {
    const { query, isRequesting } = this.props.search;
    const { searchValue, selectedType: type } = this.state;

    return (
      <section id="searchResults">
        <Helmet title={'Search'}/>
        <div className="searchStation">
          <div className="container">
            <div className="row">
              <div className="col">
                <form
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                  onSubmit={this.onSubmit}
                  onKeyPress={this.handleKeyPress}>
                  <div className="bigSearchInputContainer">
                    <input
                      autoFocus
                      id="bigSearch"
                      value={searchValue}
                      onChange={this.onChange}
                      placeholder="Search..." />

                    {
                      query && query !=='' &&
                        <button
                          onClick={this.onClearBtnClicked}
                          className="clear-search-btn clickable">
                          <i className="fas text-white fa-times"/>
                        </button>
                    }
                  </div>
                  <div>
                    <button
                      type="submit"
                      style={{marginLeft: 10}}
                      disabled={isRequesting}
                      onClick={this.onSubmit}
                      className="btn btn-xlg btn-primary">
                      <i className="fas fa-search"/>
                      <span className="ml-2 d-none d-lg-inline-block">Search</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="searchFilters">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <ul className="filter-list list-inline">
                  <li className="list-inline-item"><strong>Search for:</strong></li>
                  <li
                    onClick={this.onChangeType('people')}
                    className={classNames('list-inline-item',{'current': type === 'people' || type === null || !type})}><span className="clickable">People</span></li>
                  <li
                    onClick={this.onChangeType('badges')}
                    className={classNames('list-inline-item',{'current': type === 'badges'})}><span className="clickable">Skill Badges</span></li>
                  <li
                    onClick={this.onChangeType('files')}
                    className={classNames('list-inline-item',{'current': type === 'files'})}><span className="clickable">Portfolio Files</span></li>
                  <li
                    onClick={this.onChangeType('posts')}
                    className={classNames('list-inline-item',{'current': type === 'posts'})}><span className="clickable">Posts</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <Outlet/>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  const { search } = state;

  let searchObj = {
    searchValue : '',
    selectedType: 'people'
  };

  if(search.query)
    searchObj.searchValue = search.query;

  if(search.type)
    searchObj.selectedType = search.type;

  return {
    searchObj,
    search
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, searchActions, routerActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(SearchWrapper));
