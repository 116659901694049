import {
  SYNC_STORE,
  LOAD_FORM,
  RESET_FORMS,
  UNLOAD_FORM,
  LOAD_SECTION_FORM,
  UNLOAD_SECTION_FORM,
  LOAD_UPDATE_FORM,
  UNLOAD_UPDATE_FORM,
  SET_FORM_FOCUS,
  UNSET_FORM_FOCUS,
  OPEN_MODAL,
  CLOSE_MODAL,
  TOGGLE_COMPONENT,
  TOGGLE_AVATAR_MODAL,
  TOGGLE_MOBILE_VIEW,
} from '../../constants';

// For syncing redux store
export const syncStore = (newState) => {
  return { type: SYNC_STORE, newState };
};

export const toggleAvatarModal = (profile) => {
  return dispatch => dispatch({ type: TOGGLE_AVATAR_MODAL, data: profile });
};

export const openModal = modal => ({ type: OPEN_MODAL, modal });

export const closeModal = () => ({ type: CLOSE_MODAL });

export const toggleComponent = component => ({ type: TOGGLE_COMPONENT, component });

export const toggleMobileView = bool => ({ type: TOGGLE_MOBILE_VIEW, bool });

export const resetForms = () => {
  return { type: RESET_FORMS };
};

export const loadFormRequest = (form) => {
  return { type: LOAD_FORM, form };
};

export const unloadFormRequest = (form) => {
  return { type: UNLOAD_FORM, form };
};

export const loadSectionFormRequest = (form) => {
  return { type: LOAD_SECTION_FORM, form };
};

export const unloadSectionFormRequest = (form) => {
  return { type: UNLOAD_SECTION_FORM, form };
};

export const loadUpdateFormRequest = (form) => {
  return { type: LOAD_UPDATE_FORM, form };
};

export const unloadUpdateFormRequest = (form) => {
  return { type: UNLOAD_UPDATE_FORM, form };
};

export const setFormFocusRequest = (form) => {
  return { type: SET_FORM_FOCUS, form };
};

export const unsetFormFocusRequest = (form) => {
  return { type: UNSET_FORM_FOCUS, form };
};

export const loadForm = (form) => {
  return function (dispatch) {
    dispatch(loadFormRequest(form));
  };
};

export const unloadForm = (form) => {
  return function (dispatch) {
    dispatch(unloadFormRequest(form));
  };
};

export const loadSectionForm = (form) => {
  return function (dispatch) {
    dispatch(loadSectionFormRequest(form));
  };
};

export const unloadSectionForm = (form) => {
  return function (dispatch) {
    dispatch(unloadSectionFormRequest(form));
  };
};

export const loadUpdateForm = (form) => {
  return function (dispatch) {
    dispatch(loadUpdateFormRequest(form));
  };
};

export const unloadUpdateForm = (form) => {
  return function (dispatch) {
    dispatch(unloadUpdateFormRequest(form));
  };
};

export const setFormFocus = (form) => {
  return function (dispatch) {
    dispatch(setFormFocusRequest(form));
  };
};

export const unsetFormFocus = (form) => {
  return function (dispatch) {
    dispatch(unsetFormFocusRequest(form));
  };
};
