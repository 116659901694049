import PropTypes from 'prop-types';
import React from 'react';
import ReplyCardComponents from './ReplyCardComponents';
import ReplyForm from './ReplyForm';

const Replycard = ({
  reply,
  updatedReply,
  isForm,
  profile,
  onChange,
  onDropFile,
  clearImagePreview,
  handleKeyPress,
  onEscape,
  showMore,
  onshowMore,
  imagePreviewUrl,
  errors,
  currentUser,
  dashboard,
  searchPage
}) => {
  return(
    <div>
      {
        isForm ?
          <ReplyForm
            profile={profile}
            commentId={reply.id}
            onChange={onChange}
            clearImagePreview={clearImagePreview}
            onDropFile={onDropFile}
            reply={updatedReply}
            handleKeyPress={handleKeyPress}
            onEscape={onEscape}
            imagePreviewUrl={imagePreviewUrl}
            errors={errors}
            isForm/>
          :
          <ReplyCardComponents
            reply={reply}
            profile={profile}
            showMore={showMore}
            dashboard={dashboard}
            currentUser={currentUser}
            onshowMore={onshowMore}
            searchPage={searchPage}/>
      }
    </div>
  );
};

Replycard.propTypes = {
  reply: PropTypes.object,
  isForm: PropTypes.bool,
  updatedReply: PropTypes.string,
  onChange: PropTypes.func,
  handleKeyPress: PropTypes.func,
  clearImagePreview: PropTypes.func,
  onDropFile: PropTypes.func,
  onEscape: PropTypes.func,
  onshowMore: PropTypes.func.isRequired,
  onCommentShowMore: PropTypes.func.isRequired,
  commentShowMore: PropTypes.object.isRequired,
  showMore: PropTypes.object.isRequired,
  imagePreviewUrl: PropTypes.string,
  errors: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  dashboard: PropTypes.bool,
  searchPage: PropTypes.bool
};

export default Replycard;
