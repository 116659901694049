import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';

import {updateMyCareerPreppedStatus, getMyCareerPreppedStatus} from '../../../redux/actions/my-careerprepped-status';
import SituationForm from '../../../components/profile/forms/SituationForm';
import SituationCard from '../../../components/profile/sections/SituationCard';
import * as profileActions from '../../../redux/actions/profile';
import ProfileCardHeader from '../../../components/profile/header/ProfileCardHeader';
import { toastr } from 'react-redux-toastr';

class Situation extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    isPublicProfile: PropTypes.bool.isRequired,
    isEditingSections: PropTypes.bool,
    onToggleSectionEdit: PropTypes.func,
    isEditingFromModal: PropTypes.bool,
    onCancelModal: PropTypes.func,
    isEditing: PropTypes.bool,
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      isEditing: (this.props.isEditing) ? this.props.isEditing : false,
      isUpdating: false,
      profile: Object.assign({}, this.props.profile),
    };

    this.onEditClick = this.onEditClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (! Object.is(this.props.profile, nextProps.profile)) {
      this.setState({ profile: Object.assign({}, nextProps.profile) });
    }
  }

  onChange(event) {
    const { name, value } = event.target;
    const { profile } = this.state;

    profile[name] = value;

    if (name === 'employmentstatus') {
      const isWorking = profile.employmentstatus != 2 && profile.employmentstatus != 3;

      profile['employmenttype'] = (! isWorking) ? '6' : null;
    }

    this.setState({ profile });
  }

  onSave(event) {
    event.preventDefault();

    this.setState({ isUpdating: true });

    const { profile } = this.state;
    const { actions, onToggleSectionEdit, isEditingFromModal } = this.props;

    const data = {
      studentstatus: parseInt(profile.studentstatus),
      employmentstatus: parseInt(profile.employmentstatus),
      employmenttype:  parseInt(profile.employmenttype),
    };

    actions.updateProfile(profile.id, data)
      .then(async () => {
        if ( isEditingFromModal ) {
          toastr.success('Work Situation added successfully');
        }
        this.setState({ isEditing: false, isUpdating: false });
        await actions.updateMyCareerPreppedStatus();
        actions.getMyCareerPreppedStatus();
        onToggleSectionEdit('situation');
      });

  }

  onCancel() {
    this.setState({ isEditing: false, profile: Object.assign({}, this.props.profile) },
      () => this.props.onToggleSectionEdit('situation'));
  }

  onEditClick(event) {
    event.preventDefault();

    this.setState({ isEditing: true },
      () => this.props.onToggleSectionEdit('situation'));
  }

  render() {
    const { isPublicProfile, isEditingSections } = this.props;
    const { profile, isEditing, isUpdating } = this.state;

    return (
      <div className="current-status">
        {
          !isPublicProfile ?
            <div className="worksituation-modal">
              <ProfileCardHeader
                isEditing={isEditing}
                isEditingSections={isEditingSections}
                type="list"
                title="My Work Situation"
                onEditClick={this.onEditClick}
                isPublicProfile={isPublicProfile}
                hideMover
                icon="certifications"/>

              {isEditing ?
                <SituationForm
                  profile={profile}
                  onChange={this.onChange}
                  onSave={this.onSave}
                  onCancel={this.onCancel}
                  isUpdating={isUpdating}/> :
                <SituationCard
                  profile={this.props.profile}
                  onEditClick={this.onEditClick}
                  isPublicProfile={isPublicProfile}/>}
            </div>
            :
            null
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.data
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...profileActions,
      updateMyCareerPreppedStatus,
      getMyCareerPreppedStatus,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Situation);
