import PropTypes from 'prop-types';
import React from 'react';
import { loadForm } from '../../utils';
import JobConnectEditForm from '../../containers/jobs/JobConnectEditForm';

const JobUnitDescription = ({
  id,
  name,
  phone,
  email,
  loadForms,
  isJobOwner,
  onEditDetails
}) => {
  const contactId = `update-job-connect-form-${id}`;
  const isForm = loadForm(loadForms, contactId);
  const contactObject = {
    id: id,
    name: name,
    email: email,
    phone: phone,
    contactId: contactId
  };

  return (
    <div>
      <p>
        <span className="section-title">Contact</span>
        {
          (!isForm && isJobOwner)
          &&
            <i
              onClick={onEditDetails(contactId)}
              className="fa fa-pencil job-description-edit-btn"
              aria-hidden="true"/>
        }
      </p>
      {
        !isForm
        &&
          <div className="job-detail-contact">
            <div className="">
              <i className="fa fa-user"/> {name}
            </div>
            <div className="">
              <i className="fa fa-envelope"/> {email}
            </div>
            <div className="">
              <i className="fa fa-phone"/> {phone}
            </div>
          </div>
      }

      {isForm && <JobConnectEditForm contact={contactObject}/>}
    </div>
  );
};

JobUnitDescription.propTypes = {
  id: PropTypes.string.isRequired,
  loadForms: PropTypes.array.isRequired,
  isJobOwner: PropTypes.bool.isRequired,
  onEditDetails: PropTypes.func.isRequired,
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string
};

export default JobUnitDescription;
