import React from 'react';
import PropTypes from 'prop-types';

const CityColumn = (props) => {
  const rowDetails = props.rowData;
  return (
    <td scope="row">
      {` ${rowDetails.city ? rowDetails.city : 'NA'}${rowDetails.city && rowDetails.state ? ', ' : ''}${rowDetails.state ? rowDetails.state : ''}`}
    </td>
  );
};

CityColumn.propTypes = {
  rowData: PropTypes.object.isRequired,
};

export default CityColumn;
