import initialState from './initialState';

import {
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE,
  REQUEST_PASSWORD_RESET_REQUEST,
  REQUEST_PASSWORD_RESET_SUCCESS,
  REQUEST_PASSWORD_RESET_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.resetPassword, action) {
  switch (action.type) {
  case REQUEST_PASSWORD_RESET_REQUEST:
    return Object.assign({}, state, { isSubmitting: true });

  case REQUEST_PASSWORD_RESET_SUCCESS:
    return Object.assign({}, state, {
      data: action.data,
      isSubmitting: false,
    });

  case REQUEST_PASSWORD_RESET_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case PASSWORD_RESET_REQUEST:
    return Object.assign({}, state, { isSubmitting: true });

  case PASSWORD_RESET_SUCCESS:
    return Object.assign({}, state, {
      data: action.data,
      isSubmitting: false,
    });

  case PASSWORD_RESET_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  default:
    return state;
  }
}
