import React from 'react';

import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { ADD_SKILLS_HEADER } from '../../constants/myskills';
import CloseIcon from '../../assets/images/close.svg';

function SuccessMessage({ isOpen, toggleModal, successMessageText, modalHeaderTitle }) {
  return (
    <Modal
      centered
      className="customLevelUpModal"
      isOpen={isOpen}
      toggle={toggleModal}>
      <div className="modal-header">
        <span className="modal-title">{modalHeaderTitle}</span>
        <div
          onClick={toggleModal}
          className="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">
            <img src={CloseIcon} />
          </span>
        </div>
      </div>

      <ModalBody className="text-center">
        <i style={{ color: 'green' }} className="fa fa-check-circle fa-4x" />
        {modalHeaderTitle === ADD_SKILLS_HEADER && <p className="font-weight-bold">Skill(s) Added</p> }
        <p>{successMessageText}</p>
      </ModalBody>
      <ModalFooter className="bordertop-0 text-center">
        <Button onClick={toggleModal} className="addSkillButton">
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
}

SuccessMessage.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  successMessageText: PropTypes.string.isRequired,
  modalHeaderTitle: PropTypes.string.isRequired,
};

export default SuccessMessage;
