import PropTypes from 'prop-types';
import React from 'react';
import _isUndefined from 'lodash/isUndefined';
import Button from '../../common/Button';
import TextInput from '../../common/TextInput';

const SkillForm = ({
  skill,
  onChange,
  onSave,
  onCancel,
  onDelete,
  isSubmitting,
  isDeleting,
  errors,
  handleKeyPress,
  focusInitially,
  allowUpdate,
  isDeletingSkillAndUpdatingSkillsOrder,
  resume
}) => {
  const isApiCallInProgress = (
    isSubmitting || isDeleting ||
    isDeletingSkillAndUpdatingSkillsOrder
  );

  const disableSaveButton = (
    !_isUndefined(errors.name) ||
    skill.name === '' ||
    isApiCallInProgress || !allowUpdate
  );

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <TextInput
            label={'Name:'}
            note="Required"
            name="name"
            inputId="skill-name"
            placeholder="Enter your skill name"
            error={errors.name}
            value={skill.name}
            onChange={onChange}
            onKeyPress={handleKeyPress}
            disabled={isApiCallInProgress}
            autoFocus={focusInitially}/>

        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="action-bar text-right">
            {!_isUndefined(onDelete) &&
              <Button
                type="submit"
                buttonId="delete-skill"
                onClick={onDelete}
                styles={{marginLeft: 0, marginRight: 10}}
                disabled={isApiCallInProgress}
                buttonClass="btn-danger"
                buttonText={'Delete'}/>}

            <Button
              type="button"
              onClick={onCancel}
              buttonClass="btn-secondary"
              buttonText="Cancel"
              styles={{marginLeft: 0}}
              disabled={isApiCallInProgress}/>

            <Button
              type="submit"
              onClick={onSave}
              buttonClass="btn-primary"
              buttonText={isSubmitting ? 'Saving...' : 'Save'}
              disabled={disableSaveButton && !resume}/>
          </div>
        </div>
      </div>
    </div>
  );
};

SkillForm.propTypes = {
  errors: PropTypes.object,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  skill: PropTypes.object,
  handleKeyPress: PropTypes.func,
  focusInitially: PropTypes.bool,
  allowUpdate: PropTypes.bool,
  isDeletingSkillAndUpdatingSkillsOrder: PropTypes.bool,
  resume: PropTypes.bool
};

export default SkillForm;
