import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { omit } from 'lodash';

import Validator from '../../validator';

import * as registrationActions from '../../redux/actions/registration';
import * as componentsActions from '../../redux/actions/components';

import { Modal } from 'reactstrap';
import { toastr } from 'react-redux-toastr';

import RequestInviteForm from '../../components/auth/RequestInviteForm';

class RequestInvite extends Component {
  static propTypes = {
    pathname: PropTypes.string,
    request: PropTypes.object.isRequired,
    registration: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    showSignupModal: PropTypes.bool.isRequired,
    toggleSignupModal: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      request: Object.assign({}, this.props.request)
    };

    this.refNameField = this.refNameField.bind(this);
    this.requestInvite = this.requestInvite.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount(){
    document.addEventListener('keydown', this.handleKeyPress, false);

    if (this.name) {
      this.name.focus();
    }
  }

  componentWillUnmount(){
    document.removeEventListener('keydown', this.handleKeyPress, false);
  }

  isValid(field = null) {
    const { request, errors: prevErrors } = this.state;

    let validate = Validator.createValidator({
      firstName: ['required'],
      lastName: ['required'],
      jobTitle: ['required'],
      email: ['required', 'email'],
      phone: ['required'],
      organization: ['required']
    }, request, field);

    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  onChange(event) {
    const { name, value } = event.target;
    let { request } = this.state;

    request[name] = value;

    this.setState({ request }, () => this.isValid(name));
  }

  requestInvite(event) {
    event.preventDefault();

    let { request } = this.state;
    const { actions } = this.props;

    if (! this.isValid()) return false;

    const pathname = window.location.pathname;
    const firstName = request.firstName.trim();
    const lastName = request.lastName.trim();
    const name = `${firstName} ${lastName}`;
    let page = (pathname === '/') ? 'Homepage' : 'Talent Development Organizations';

    page = (pathname === '/skills-to-careers-coalition') ?
      'Skill to Careers Coalition' : page;

    const successMessage = (pathname === '/talent-development-organizations') ?
      'Thank you. A representative will contact you soon to coordinate a meeting.' :
      'Your request for an invitation was successfully submitted!';

    request.name = name;
    request.page = page;
    request.type = parseInt(request.type);

    request = omit(request, ['firstName', 'lastName']);

    actions.requestInvite(request)
      .then(() => {
        actions.closeModal('invite-modal');
        toastr.success('', successMessage);
      });
  }

  handleKeyPress(event) {
    if (event.key === 'Enter')
      return this.requestInvite(event);

    if (event.key === 'Escape')
      return this.props.toggleSignupModal();

  }

  refNameField(name) {
    this.name = name;
  }

  render() {
    const { pathname, registration, showSignupModal, toggleSignupModal } = this.props;
    const { request, errors } = this.state;

    return (
      <Modal
        isOpen={showSignupModal}
        size={'sm'}
        toggle={toggleSignupModal}
        className="signupModal">
        <div className="modal-body homepage-modal-body">
          <button onClick={toggleSignupModal} className="close">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4
            className="modal-title"
            id="myModalLabel"
            style={{marginBottom: 10}}>Contact us</h4>
          <p>
            {pathname === '/skills-to-careers-coalition' ?
              'Provide your contact information below and our team would be happy to connect with you to discuss joining the Skills to Careers Coalition.' :
              'Request a free product demonstration and get a price quote tailored to your organization\'s needs.'}
          </p>
          <RequestInviteForm
            errors={errors}
            request={request}
            nameRef={this.refNameField}
            toggleSignupModal={toggleSignupModal}
            isRequesting={registration.isRequesting}
            handleKeyPress={this.handleKeyPress}
            onChange={this.onChange}
            onSubmit={this.requestInvite} />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { registration } = state;
  const request = {
    firstName: '',
    lastName: '',
    jobTitle: '',
    email: '',
    phone: '',
    organization: '',
    additionalInformation: ''
  };

  return {
    request,
    registration
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    registrationActions,
    componentsActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestInvite);
