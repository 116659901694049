import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash';

const PendingFeedbackHeader = (props) => {
  const [clickedHeaders, setClickedHeaders] = useState([]);
  const [nonClickedHeaders, setNonClickedHeaders] = useState([
    'sortGiven', 'resourceTypeGiven', 'requestorGiven'
  ]);

  function clickHeader(event){
    const headerName = event.target.id;

    // update the noncliked headers
    if(includes(nonClickedHeaders, headerName)){
      const updatedNonClickedHeadersList = nonClickedHeaders.filter(header => header!== headerName);
      setNonClickedHeaders(updatedNonClickedHeadersList);
    }

    // update the clicked headers
    if(includes(clickedHeaders, headerName)){
      const updatedClickedHeadersList = clickedHeaders.filter(header => header!== headerName);
      setClickedHeaders(updatedClickedHeadersList);
    }else{
      setClickedHeaders([...clickedHeaders, headerName]);
    }
  }

  function onSortRequest(event){
    clickHeader(event);
    props.onSortTable(event);
  }

  function getSortIconClassName(column){
    if(includes(nonClickedHeaders, column)){
      return 'fa-sort';
    } else if(includes(clickedHeaders, column)){
      return 'fa-sort-down';
    }
    return 'fa-sort-up';
  }

  return (
    <>
      <th
        id="sortGiven"
        style={{ width: '20%' }}
        className="cursor-pointer"
        data-column-name="sortGiven"
        onClick={onSortRequest}>
        Requested on
        <i
          id="sortGiven"
          className={`ml-1 fas ${getSortIconClassName('sortGiven')}`}/>
      </th>
      <th
        id="resourceTypeGiven"
        data-column-name="resourceTypeGiven"
        onClick={onSortRequest}
        className="cursor-pointer"
        style={{ width: 250 }}>
        Skill
        <i
          id="resourceTypeGiven"
          className={`ml-1 fas ${getSortIconClassName('resourceTypeGiven')}`}/>
      </th>
      <th
        id="requestorGiven"
        data-column-name="requestorGiven"
        onClick={onSortRequest}
        className="cursor-pointer"
        style={{ width: 150 }}>
        Requestor
        <i
          id="requestorGiven"
          className={`ml-1 fas ${getSortIconClassName('requestorGiven')}`}/>
      </th>
      <th style={{ width: 100 }}>Action</th>
    </>
  );
};
  
PendingFeedbackHeader.propTypes = {
  onSortTable: PropTypes.func.isRequired,
};

export default PendingFeedbackHeader;