import { CALL_API } from '../../middlewares/api';
import {
  SET_SELECTED_CHILD_GROUP,
  GET_ADMIN_GROUP_REQUEST,
  GET_ADMIN_GROUP_SUCCESS,
  GET_ADMIN_GROUP_FAILURE,
  UPDATE_ADMIN_GROUP_REQUEST,
  UPDATE_ADMIN_GROUP_SUCCESS,
  UPDATE_ADMIN_GROUP_FAILURE,
  UPDATE_ADMIN_GROUP_ADD_ADMIN_REQUEST,
  UPDATE_ADMIN_GROUP_ADD_ADMIN_SUCCESS,
  UPDATE_ADMIN_GROUP_ADD_ADMIN_FAILURE,
  UPDATE_ADMIN_GROUP_REMOVE_ADMIN_REQUEST,
  UPDATE_ADMIN_GROUP_REMOVE_ADMIN_SUCCESS,
  UPDATE_ADMIN_GROUP_REMOVE_ADMIN_FAILURE,
  GET_INSTITUTION_GROUPS_REQUEST,
  GET_INSTITUTION_GROUPS_SUCCESS,
  GET_INSTITUTION_GROUPS_FAILURE,
  CREATE_ADMIN_GROUP_REQUEST,
  CREATE_ADMIN_GROUP_SUCCESS,
  CREATE_ADMIN_GROUP_FAILURE,
} from '../../constants';

export const setSelectedChildGroup = group => ({
  type: SET_SELECTED_CHILD_GROUP,
  group,
});

export const getAdminGroup = (groupId) => {
  const endpoint = `group/group/${groupId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        GET_ADMIN_GROUP_REQUEST,
        GET_ADMIN_GROUP_SUCCESS,
        GET_ADMIN_GROUP_FAILURE,
      ],
    },
  };
};

export const createAdminGroup = (institutionId, data) => {
  const endpoint = `institution/${institutionId}/groups`;

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'patch',
      authenticated: true,
      types: [
        CREATE_ADMIN_GROUP_REQUEST,
        CREATE_ADMIN_GROUP_SUCCESS,
        CREATE_ADMIN_GROUP_FAILURE,
      ],
    },
  };
};

export const updateAdminGroup = (groupId, data) => {
  const endpoint = `group/group/${groupId}`;

  return {
    [CALL_API]: {
      data,
      endpoint,
      id: groupId,
      method: 'patch',
      authenticated: true,
      types: [
        UPDATE_ADMIN_GROUP_REQUEST,
        UPDATE_ADMIN_GROUP_SUCCESS,
        UPDATE_ADMIN_GROUP_FAILURE,
      ],
    },
  };
};

export const updateAdminGroupAddAdmins = (groupId, data) => {
  const endpoint = `group/${groupId}/admins`;

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'patch',
      authenticated: true,
      types: [
        UPDATE_ADMIN_GROUP_ADD_ADMIN_REQUEST,
        UPDATE_ADMIN_GROUP_ADD_ADMIN_SUCCESS,
        UPDATE_ADMIN_GROUP_ADD_ADMIN_FAILURE,
      ],
    },
  };
};

export const updateAdminGroupRemoveAdmins = (groupId, data, adminId) => {
  const endpoint = `group/${groupId}/admins`;

  return {
    [CALL_API]: {
      data,
      id: adminId,
      endpoint,
      method: 'patch',
      authenticated: true,
      types: [
        UPDATE_ADMIN_GROUP_REMOVE_ADMIN_REQUEST,
        UPDATE_ADMIN_GROUP_REMOVE_ADMIN_SUCCESS,
        UPDATE_ADMIN_GROUP_REMOVE_ADMIN_FAILURE,
      ],
    },
  };
};

export const getInstitutionGroups = (institutionId) => {
  const endpoint = `group/group?institution=${institutionId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        GET_INSTITUTION_GROUPS_REQUEST,
        GET_INSTITUTION_GROUPS_SUCCESS,
        GET_INSTITUTION_GROUPS_FAILURE,
      ],
    },
  };
};
