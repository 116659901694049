import PropTypes from 'prop-types';
import React from 'react';
import TextInput from '../common/TextInput';

const ConfirmPassword = ({
  confirmPassword,
  changeConfirmPassword,
  error,
  isUpdating,
  onKeyPress
}) => {
  return(
    <TextInput
      type="password"
      className="form-control input-styles-owht"
      inputId="confirmPassword"
      label="Confirm Password"
      placeholder={'Confirm Password'}
      name="confirmPassword"
      value={confirmPassword}
      onChange={changeConfirmPassword}
      error={error}
      disabled={isUpdating}
      onKeyPress={onKeyPress}/>
  );
};

ConfirmPassword.propTypes = {
  confirmPassword: PropTypes.string,
  changeConfirmPassword: PropTypes.func,
  error: PropTypes.string,
  isUpdating: PropTypes.bool,
  onKeyPress: PropTypes.func
};

export default ConfirmPassword;
