import { CALL_API } from '../../middlewares/api';

import {
  SKILLS_REQUEST,
  SKILLS_SUCCESS,
  SKILLS_FAILURE,
  CREATE_SKILL_REQUEST,
  CREATE_SKILL_SUCCESS,
  CREATE_SKILL_FAILURE,
  UPDATE_SKILL_REQUEST,
  UPDATE_SKILL_SUCCESS,
  UPDATE_SKILL_FAILURE,
  DELETE_SKILL_REQUEST,
  DELETE_SKILL_SUCCESS,
  DELETE_SKILL_FAILURE,
  SET_SKILL_BEING_UPDATED_IN_STORE,
  UNIT_SKILL_REQUEST,
  UNIT_SKILL_SUCCESS,
  UNIT_SKILL_FAILURE,
  RESET_SKILLS_DATA,
  LIKE_UNIT_SKILL_FILE_REQUEST,
  LIKE_UNIT_SKILL_FILE_SUCCESS,
  LIKE_UNIT_SKILL_FILE_FAILURE,
  UNLIKE_UNIT_SKILL_FILE_REQUEST,
  UNLIKE_UNIT_SKILL_FILE_SUCCESS,
  UNLIKE_UNIT_SKILL_FILE_FAILURE,
  REMOVE_EVIDENCE_FILE_REQUEST,
  REMOVE_EVIDENCE_FILE_SUCCESS,
  REMOVE_EVIDENCE_FILE_FAILURE,
  LINK_EVIDENCE_FILES_REQUEST,
  LINK_EVIDENCE_FILES_SUCCESS,
  LINK_EVIDENCE_FILES_FAILURE,
  GET_MYSKILLSPAGE_REQUEST,
  GET_MYSKILLSPAGE_SUCCESS,
  GET_MYSKILLSPAGE_FAILURE,
  UPDATE_UNIT_SKILL_FILES
} from '../../constants';

export const setSkillBeingUpdatedInStore = (skill) => {
  return dispatch =>
    dispatch({ type: SET_SKILL_BEING_UPDATED_IN_STORE, skill: skill });
};

export const skillsRequest = (userId, noPagination) => {
  const endpoint = noPagination ? `user/skills?user=${userId}&page_size=50` : `user/skills?user=${userId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [SKILLS_REQUEST, SKILLS_SUCCESS, SKILLS_FAILURE],
    },
  };
};

export const getMySkills = (userId, _links, filterData, resourceType, page = 1, isSearchQuery = false) => {
  let endpoint = `my-skills?user=${userId}&page=${page}&resource_type=${resourceType}`;

  if (filterData) {
    endpoint = endpoint.concat(filterData);
  }

  return {
    [CALL_API]: {
      endpoint,
      id: userId,
      method: 'get',
      authenticated: true,
      types: [
        GET_MYSKILLSPAGE_REQUEST,
        GET_MYSKILLSPAGE_SUCCESS,
        GET_MYSKILLSPAGE_FAILURE
      ],
      requestData: {
        requestingPage: page,
        isSearch: isSearchQuery,
        resource: resourceType
      },
    },
  };
};

export const saveSkill = (data) => {
  const endpoint = 'user/skills';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [CREATE_SKILL_REQUEST, CREATE_SKILL_SUCCESS, CREATE_SKILL_FAILURE],
    },
  };
};

export const updateSkill = (index, data) => {
  const endpoint = `user/skills/${data.id}`;

  return {
    [CALL_API]: {
      data,
      index,
      endpoint,
      method: 'put',
      authenticated: true,
      types: [UPDATE_SKILL_REQUEST, UPDATE_SKILL_SUCCESS, UPDATE_SKILL_FAILURE],
    },
  };
};

export const deleteSkill = (skillId) => {
  const endpoint = `user/skills/${skillId}`;

  return {
    [CALL_API]: {
      endpoint,
      id: skillId,
      method: 'delete',
      authenticated: true,
      types: [DELETE_SKILL_REQUEST, DELETE_SKILL_SUCCESS, DELETE_SKILL_FAILURE],
    },
  };
};

export const unitSkillRequest = (isAuthenticated, skillId) => {
  const endpoint = `user/skills/${skillId}`;

  let apiProps = {
    endpoint,
    method: 'get',
    types: [UNIT_SKILL_REQUEST, UNIT_SKILL_SUCCESS, UNIT_SKILL_FAILURE],
  };

  if (isAuthenticated) {
    apiProps = Object.assign({}, apiProps, { authenticated: true });
  }

  return {
    [CALL_API]: apiProps,
  };
};

export const updateSkillUnitFiles = file => ({ type: UPDATE_UNIT_SKILL_FILES, file });

export const resetSkillRequest = () => {
  return { type: RESET_SKILLS_DATA };
};

export const likeUnitSkillFile = (data) => {
  const endpoint = 'file/likes';
  return {
    [CALL_API]: {
      endpoint,
      data,
      id: data.file,
      method: 'post',
      authenticated: true,
      types: [
        LIKE_UNIT_SKILL_FILE_REQUEST,
        LIKE_UNIT_SKILL_FILE_SUCCESS,
        LIKE_UNIT_SKILL_FILE_FAILURE,
      ],
    },
  };
};

export const unlikeUnitSkillFile = (fileId) => {
  const endpoint = `file/likes/${fileId}`;

  return {
    [CALL_API]: {
      endpoint,
      id: fileId,
      method: 'delete',
      authenticated: true,
      types: [
        UNLIKE_UNIT_SKILL_FILE_REQUEST,
        UNLIKE_UNIT_SKILL_FILE_SUCCESS,
        UNLIKE_UNIT_SKILL_FILE_FAILURE,
      ],
    },
  };
};

export const linkSkillsEvidenceFiles = (data, index) => {
  const endpoint = 'badge/file';

  return {
    [CALL_API]: {
      data,
      index,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        LINK_EVIDENCE_FILES_REQUEST,
        LINK_EVIDENCE_FILES_SUCCESS,
        LINK_EVIDENCE_FILES_FAILURE,
      ],
    },
  };
};

export const unlinkEvidenceFile = (fileId, data) => {
  const endpoint = `badge/file/${fileId}`;

  return {
    [CALL_API]: {
      id: fileId,
      endpoint,
      data,
      method: 'patch',
      authenticated: true,
      types: [
        REMOVE_EVIDENCE_FILE_REQUEST,
        REMOVE_EVIDENCE_FILE_SUCCESS,
        REMOVE_EVIDENCE_FILE_FAILURE,
      ],
    },
  };
};
