import PropTypes from 'prop-types';
import React from 'react';

import { isEmpty, isUndefined } from 'lodash';

import TextInput from '../common/TextInput';
import Alert from './Alert';

const signpImg = require('../../assets/images/logo-icon.png');

const SelfRegisterForm = ({
  credentials,
  errors,
  registration,
  onChange,
  onSubmit,
  handleKeyPress,
  displayAlertType,
  dismissAlert
}) => {
  let alertHeading = 'Thank you for signing up for CareerPrepped.';
  let alertMessage = 'You\'re almost finished! Please check your email to complete your account creation. We\'ve emailed you a link to setup your password and sign in. If you don\'t see the email in your inbox, please check any spam, junk, clutter, or promotions folders and search "CareerPrepped" in all email folders. If you still can\'t locate your email, contact support at careerprepped@maxknowledge.com and let us know you didn\'t get your sign up email.';

  if (! isUndefined(registration.error.errorType)) {
    alertHeading = 'There\'s a problem with the email entered.';
    alertMessage = registration.error.detail;

    if ((registration.error.errorType === 'DuplicateSelfRegistrationException')) {
      alertHeading = 'Duplicate registration requests.';
    }

    if ((registration.error.errorType === 'InvalidGroupCodeException')) {
      alertHeading = 'Invalid group code.';
    }
  }

  return (
    <div className="educator-signup col-10">
      <div className="self-register">
        <div style={{textAlign:'center'}}>
          <div className="double-lines"/>
          <img src={signpImg} style={{backgroundColor:'white'}}/>
        </div>
        <h4>
          Sign up for CareerPrepped
        </h4>
        <form onSubmit={onSubmit} onKeyPress={handleKeyPress}>
          <div className="row">
            {(! isEmpty(displayAlertType)) &&
              <Alert
                displayAlertType={displayAlertType}
                alertHeading={alertHeading}
                alertMessage={alertMessage}
                dismissAlert={dismissAlert}/>}

            <div className="col-md-6 col-sm-12">
              <TextInput
                name="email"
                label="Email"
                placeholder="Email"
                value={credentials.email}
                onChange={onChange}
                error={errors.email}
                autoFocus
                srOnly/>
            </div>
            <div className="col-md-6 col-sm-12">
              <TextInput
                name="confirmEmail"
                label="Confirm Email"
                placeholder="Confirm Email"
                value={credentials.confirmEmail}
                onChange={onChange}
                error={errors.confirmEmail}
                srOnly/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <TextInput
                name="groupCode"
                label="Group Code"
                placeholder="Group Code"
                value={credentials.groupCode}
                onChange={onChange}
                error={errors.groupCode}
                srOnly/>
            </div>
          </div>
          <input
            type="submit"
            disabled={registration.isSubmitting}
            value={registration.isSubmitting ? 'Submitting...' : 'Submit'}
            className="btn btn-primary btn-xlg w-100"/>
        </form>
      </div>
    </div>
  );
};

SelfRegisterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  handleKeyPress: PropTypes.func.isRequired,
  registration: PropTypes.object.isRequired,
  errors: PropTypes.object,
  credentials: PropTypes.object,
  displayAlertType: PropTypes.string.isRequired,
  dismissAlert: PropTypes.func.isRequired
};

export default SelfRegisterForm;
