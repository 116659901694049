import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getUserAdminGroups,
  resetGroupAdmins,
  setSelectedParentGroup,
  setIsAuthUserSelectedInstitutionAdmin
} from '../../redux/actions/groups';

import { toastr } from 'react-redux-toastr';
import { Container, Row, Col } from 'reactstrap';

import Loading from '../../components/common/Loading';
import AdminBreadcrumb from './AdminBreadcrumb';
import ParentGroupDropdown from './ParentGroupDropdown';
import AdminsDashboard from '../../components/admin/AdminsDashboard';

class OrganizationAdmins extends Component {
  static propTypes = {
    isAdmin: PropTypes.bool,
    actions: PropTypes.object.isRequired,
    isInitialRequest: PropTypes.bool.isRequired,
    selectedGroup: PropTypes.object.isRequired,
    isChildGroupSelected: PropTypes.bool.isRequired,
    selectedInstitutionId: PropTypes.string,
    userParentGroupsCount: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);

    this.onCopy = this.onCopy.bind(this);
  }

  componentWillMount() {
    const {
      actions,
      isAdmin,
      isInitialRequest,
      selectedInstitutionId
    } = this.props;

    if (selectedInstitutionId && (! isInitialRequest)) {
      actions.resetGroupAdmins();
      actions.setIsAuthUserSelectedInstitutionAdmin(isAdmin);
      actions.getUserAdminGroups(selectedInstitutionId, '', true);
    }
  }

  onCopy() {
    const { selectedGroup: { name } } = this.props;

    toastr.success(`You successfully copied the group code for ${name}.`);
  }

  render() {
    const {
      isInitialRequest,
      isChildGroupSelected,
      userParentGroupsCount
    } = this.props;

    return (
      <div className="admin-dashboard">
        <div className="interior invite-users mt-3">
          <Container>
            <AdminBreadcrumb parentPage="manage-admins"/>

            <Row>
              <Col>
                <div className="groupName mb-2">Manage Admins</div>
                <div className="group-content mb-3">
                  <p>
                    From this page, you can see any admins of any group but can only add or remove admins for groups you created.
                  </p>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                {((! isChildGroupSelected) && (userParentGroupsCount > 1)) &&
                  <ParentGroupDropdown parentPage="manage-admins"/>}
              </Col>
            </Row>

            {isInitialRequest && <Loading/>}

            {! isInitialRequest && <AdminsDashboard/>}
          </Container>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    groups: {
      isInitialRequest,
      selectedGroup,
      isChildGroupSelected,
      data: userAdminGroups
    },

    institutions: { selectedInstitution: { isAdmin, id: selectedInstitutionId } }
  } = state;

  const allGroups = userAdminGroups.filter(group => group.id !== 'all-groups');
  const userParentGroupsCount = allGroups.length;

  return {
    isAdmin,
    selectedGroup,
    isInitialRequest,
    userParentGroupsCount,
    isChildGroupSelected,
    selectedInstitutionId
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    resetGroupAdmins,
    getUserAdminGroups,
    setSelectedParentGroup,
    setIsAuthUserSelectedInstitutionAdmin
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationAdmins);
