import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { isUndefined } from 'lodash';

import { Row, Col } from 'reactstrap';

const AdminStatistics = ({
  totalUsersJoined,
  skillBadgesClaimed,
  skillBadgeEndorsements,
  portfolioFilesUploaded
}) => {
  return (
    <div className="row-statistics">
      <h2>Statistics</h2>
      <Row>
        <Col lg="3" sm="6">
          <div className="statsBox">
            <p className="number">
              {! isUndefined(totalUsersJoined) ?
                totalUsersJoined.toLocaleString() : '0'}
            </p>

            <p className="description">Total Users Joined</p>
          </div>
        </Col>
        <Col lg="3" sm="6">
          <div className="statsBox">
            <p className="number">
              {! isUndefined(portfolioFilesUploaded) ?
                portfolioFilesUploaded.toLocaleString() : '0'}
            </p>

            <p className="description">Portfolio Files Uploaded</p>
          </div>
        </Col>
        <Col lg="3" sm="6">
          <div className="statsBox">
            <p className="number">
              {! isUndefined(skillBadgesClaimed) ?
                skillBadgesClaimed.toLocaleString() : '0'}
            </p>

            <p className="description">Skill Badges Claimed</p>
          </div>
        </Col>
        <Col lg="3" sm="6">
          <div className="statsBox">
            <p className="number">
              {! isUndefined(skillBadgeEndorsements) ?
                skillBadgeEndorsements.toLocaleString() : '0'}
            </p>
            <p className="description">Skill Badge Endorsements</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

AdminStatistics.propTypes = {
  totalUsersJoined: PropTypes.string,
  skillBadgesClaimed: PropTypes.string,
  skillBadgeEndorsements: PropTypes.string,
  portfolioFilesUploaded: PropTypes.string
};

const mapStateToProps = (state) => {
  const {
    groups: {
      selectedGroup: {
        id: selectedGroupId,
        groupUsersCount,
        groupClaimedBadgesCount,
        groupBadgeEndorsementsCount,
        groupFilesCount
      },

      statistics
    },

    institutions: {
      selectedInstitution: {
        isAdmin: isInstitutionAdmin,
        usersCount,
        usersClaimedBadgesCount,
        usersFilesCount,
        usersBadgeEndorsementsCount
      }
    },

    profile: { data: { role } }
  } = state;

  let totalUsersJoined = groupUsersCount;
  let skillBadgesClaimed = groupClaimedBadgesCount;
  let skillBadgeEndorsements = groupBadgeEndorsementsCount;
  let portfolioFilesUploaded = groupFilesCount;

  if (isInstitutionAdmin && (selectedGroupId === 'all-groups')) {
    totalUsersJoined = usersCount;
    skillBadgesClaimed = usersClaimedBadgesCount;
    skillBadgeEndorsements = usersBadgeEndorsementsCount;
    portfolioFilesUploaded = usersFilesCount;
  }

  if ((role === 'educator') && (selectedGroupId === 'all-groups')) {
    ({
      usersCount: totalUsersJoined,
      usersClaimedBadgesCount: skillBadgesClaimed,
      usersFilesCount: portfolioFilesUploaded
    } = statistics);
  }

  return {
    totalUsersJoined,
    skillBadgesClaimed,
    skillBadgeEndorsements,
    portfolioFilesUploaded
  };
};

export default connect(mapStateToProps)(AdminStatistics);
