import React, { Component } from 'react';
import PropTypes from 'prop-types';

const styles = {
  icon: { marginRight: 5 },
};

class WallCommentButton extends Component {
  static propTypes = {
    wallPost: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    event.preventDefault();

    const { wallPost } = this.props;

    const form = `wall-comment-form-wallPostId-${wallPost.id}`;

    document.getElementById(form).focus();
  }

  render() {
    const { wallPost } = this.props;

    return (
      <a href="#"
        onClick={this.onClick}
        data-wallpost-id={`wall-post-${wallPost.id}`}>
        <i className="fa fa-comment" style={styles.icon}/>
        <span style={styles.icon}>Comment</span>
      </a>
    );
  }
}

export default WallCommentButton;
