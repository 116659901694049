import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import isEmpty from 'lodash/isEmpty';
import TextInput from '../../components/common/TextInput';
import Validator from '../../validator';
import * as badgeActions from '../../redux/actions/badges';
import * as endorsementActions from '../../redux/actions/badge-endorsement';
import * as componentActions from '../../redux/actions/components';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from '../../components/common/Button';
import { toastr } from 'react-redux-toastr';
import QuillEditor from '../../components/common/QuillEditor';

const modules = {
  toolbar: [
    [{ 'align': [] }],
    ['bold', 'italic', 'underline'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    ['link'],
  ],
  clipboard: {
    matchVisual: false,
  }
};

class EndorsementModal extends Component {
  static propTypes = {
    badges: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    badgeEndorsement: PropTypes.object.isRequired,
    currentUserEmail: PropTypes.string.isRequired,
    currentUserName: PropTypes.string.isRequired,
    onClose: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      endorsementName: props.badges.selectedBadges[0]?.name,
      endorsement: this.setInitialEndorsementState(props.badges.selectedBadges, props.currentUserName)
    };

    this.onChange = this.onChange.bind(this);
    this.requestEndorsements = this.requestEndorsements.bind(this);
    this.closeEndorsementRequest = this.closeEndorsementRequest.bind(this);
    this.onChangeBody = this.onChangeBody.bind(this);
  }

  setInitialEndorsementState(selectedBadges, currentUserName){
    const template = getTemplate(selectedBadges);

    const endorsement = {
      template,
      emails: '',
      bodyPlainText: '',
      subject: selectedBadges.length > 1 ? `${currentUserName} Requested Your Endorsement for Multiple Skills` :
        `${currentUserName} Requested Your Endorsement for ${selectedBadges[0].name}`
    };

    return endorsement;
  }

  isValid(field = null) {
    const { endorsement } = this.state;
    const rules = { emails: ['required'], bodyPlainText: ['required'] };

    const validate = Validator.createValidator(rules, endorsement, field);
    const { errors, isValid } = validate;

    this.setState({ errors });

    return isValid;
  }

  onChangeBody(value, ...params){
    const { endorsement } = this.state;
    const bodyPlainText = params[2].getText();

    endorsement.template = value;
    endorsement.bodyPlainText = bodyPlainText;

    this.setState({ endorsement, bodyPlainText }, () => this.isValid('bodyPlainText'));
  }

  onChange(event) {
    const { name, value } = event.target;
    const { endorsement } = this.state;

    endorsement[name] = value;

    this.setState({ endorsement });

    this.isValid(name);
  }

  requestEndorsements() {
    const { endorsement } = this.state;
    const { emails: emailString, subject, template: body } = endorsement;
    const { badges, actions } = this.props;

    if (! this.isValid()) return ;

    const emailsSpecialCharactersRegex = /[!#$%^&*()?"':;{}|<>]/;
    const isNotValidEmailString = emailsSpecialCharactersRegex.test(emailString);

    if (isNotValidEmailString) {
      toastr.error('Please check that you used a comma to separate the emails you entered so we can accurately process your emails.');
      return;
    }

    const badgeIssuedIds = badges.selectedBadges.map(
      badge => badge.issued.id
    );
    const resourceType = badges.selectedBadges.map(
      badge => badge.type
    );

    const requestEndorsementKey = resourceType[0] === 'skill' ? 'requestSkillEndorsements'
      : 'requestBadgeEndorsements';

    const resourceIds = resourceType[0] === 'skill' ? 'skillIds'
      : 'badgeIssuedIds';

    const emails = emailString.split(',');

    const data = { subject, body, [resourceIds]: badgeIssuedIds, emails };

    actions[requestEndorsementKey](data)
      .then(() => {
        emails.forEach((email) => {
          if (email.trim() === this.props.currentUserEmail) {
            throw 'Please send endorsement requests to emails of others who\'ve observed you use your skills rather than your own email.';
          }

          this.props.badges.selectedBadges.forEach((badge) => {
            if (! isEmpty(badge.endorsements)) {
              badge.endorsements.forEach((endorsement) => {
                if (!isEmpty(endorsement.endorsement_date) && endorsement.email === email.trim()) {// for confirmed endorsements
                  throw `${email} already endorsed you for ${badge.name}.
                  Please remove the email or de-select ${badge.name} from your bulk endorsement request.`;
                }
              });
            }
          });
        });

        toastr.success('Your Endorsement request has successfuly been sent.');
        this.closeEndorsementRequest();
      });
  }

  closeEndorsementRequest() {
    this.props.actions.resetSelectedBadges();
    this.props.actions.closeModal();
    this.props.onClose && this.props.onClose();
  }

  render() {
    const { endorsement, errors, endorsementName } = this.state;
    const { badges, badgeEndorsement } = this.props;
    const { selectedBadges } = badges;
    const { isSubmitting } = badgeEndorsement;

    const displayEndorsementModal = selectedBadges.length === 1 ? (selectedBadges[0].name === endorsementName) : selectedBadges.length > 0;

    return(
      <Modal
        backdrop="static"
        size="lg"
        className="seek-endorsement"
        isOpen={displayEndorsementModal}
        toggle={this.closeEndorsementRequest}>
        <ModalHeader
          toggle={this.closeEndorsementRequest}>
          Seek Endorsements
        </ModalHeader>
        <ModalBody>
          <div className="seek-endorsement-content">
            <p>Strengthen your claimed skill by getting endorsements from others who've observed your skill in action.</p>
            <div className="seek-endorsement-section">
              <h6 className="mb-2">Seek endorsements for:</h6>
              <div className="seek-for">
                {selectedBadges.map((badge, index) =>
                  (<span key={index}>
                    {badge.name}
                  </span>))}
              </div>
            </div>
            <div className="seek-endorsement-section">
              <h6 className="mb-2">Who do you want to seek endorsements from?</h6>
              <TextInput
                type="text"
                name="emails"
                error={errors.emails}
                value={endorsement.emails}
                onChange={this.onChange}
                className="form-control emails"
                wrapperStyle={{marginBottom: 0}}
                placeholder="Enter emails separated by commas"/>
            </div>
            <div className="seek-endorsement-section">
              <h6 className="mb-2">Seek Endorsement Message</h6>
              <TextInput
                type="text"
                name="subject"
                label="Subject"
                error={errors.subject}
                value={endorsement.subject}
                onChange={this.onChange}
                className="form-control emails"/>
              <div
                className={classNames('statement-form-container', {
                  'has-danger': errors.bodyPlainText
                })}>
                <QuillEditor
                  modules={modules}
                  value={endorsement.template}
                  placeholder="Seek endorsement message"
                  onChange={this.onChangeBody} />
                {errors.bodyPlainText && <div className="text-danger">{errors.bodyPlainText}</div>}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={this.closeEndorsementRequest}
            buttonClass="btn-secondary"
            styles={{marginLeft: 0}}
            buttonText="Cancel" />
          <Button
            onClick={this.requestEndorsements}
            buttonClass="btn-primary"
            disabled={!!isSubmitting}
            styles={{marginLeft: 0}}
            buttonText={isSubmitting ? 'Sending ...' : 'Send'} />
        </ModalFooter>
      </Modal>
    );
  }
}

const getTemplate = (selectedBadges) => {
  const isSingleBadge = selectedBadges.length === 1;

  const template = `
    <p>Hello,</p><p>I've added a skill I believe I have on CareerPrepped:</p>${isSingleBadge ? `<p><strong>${selectedBadges[0].name}</strong></p>` : ''}<p>CareerPrepped lets me seek skill endorsements to help prove my skills to others. Endorsers must attest that they've observed my claimed skill in a training activity, project, or work setting, and that they can confidently confirm that I possess the ${isSingleBadge ? 'claimed skill' : 'claimed skills'}.</p><p>Because I believe you’ve observed my use of ${isSingleBadge ? 'this skill' : 'these skills'}, I hope you'll kindly consider endorsing me for ${selectedBadges[0].name} and providing a testimonial about my skill.</p>`;
  return template;
};

const mapStateToProps = (state) => {
  const { badges, badgeEndorsement } = state;
  const currentUserEmail = state.profile.data.email;

  return {
    badges,
    badgeEndorsement,
    currentUserName: state.profile.data.name,
    currentUserEmail,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    badgeActions,
    endorsementActions,
    componentActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EndorsementModal);
