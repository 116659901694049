import { CALL_API } from '../../middlewares/api';

import {
  RESET_FOLLOWING_DATA,
  FOLLOWING_REQUEST,
  FOLLOWING_SUCCESS,
  FOLLOWING_FAILURE,
  CREATE_FOLLOWING_REQUEST,
  CREATE_FOLLOWING_SUCCESS,
  CREATE_FOLLOWING_FAILURE,
  UPDATE_FOLLOWING_REQUEST,
  UPDATE_FOLLOWING_SUCCESS,
  UPDATE_FOLLOWING_FAILURE,
  DELETE_FOLLOWING_REQUEST,
  DELETE_FOLLOWING_SUCCESS,
  DELETE_FOLLOWING_FAILURE,
} from '../../constants';

export const clearFollowingData = () => {
  return { type: RESET_FOLLOWING_DATA };
};

export const followingRequest = (isAuthenticated, userId = null, page = 1, exclude = false) => {
  let endpoint = `user/connect?page=${page}${(! isAuthenticated) ? `&user=${userId}` : ''}`;  

  if(isAuthenticated && exclude) {    
    endpoint= `${endpoint}&excludeUsersWithDisableMessaging=1`;
  } 

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: isAuthenticated,
      types: [FOLLOWING_REQUEST, FOLLOWING_SUCCESS, FOLLOWING_FAILURE],
    },
  };
};

export const saveFollowing = (data) => {
  const endpoint = 'user/connect';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        CREATE_FOLLOWING_REQUEST,
        CREATE_FOLLOWING_SUCCESS,
        CREATE_FOLLOWING_FAILURE,
      ],
    },
  };
};

export const updateFollowing = (index, data) => {
  const endpoint = `user/connect/${data.id}`;

  return {
    [CALL_API]: {
      data,
      index,
      endpoint,
      method: 'put',
      authenticated: true,
      types: [
        UPDATE_FOLLOWING_REQUEST,
        UPDATE_FOLLOWING_SUCCESS,
        UPDATE_FOLLOWING_FAILURE,
      ],
    },
  };
};

export const deleteFollowing = (followingId) => {
  const endpoint = `user/connect/${followingId}`;

  return {
    [CALL_API]: {
      endpoint,
      id: followingId,
      method: 'delete',
      authenticated: true,
      types: [
        DELETE_FOLLOWING_REQUEST,
        DELETE_FOLLOWING_SUCCESS,
        DELETE_FOLLOWING_FAILURE,
      ],
    },
  };
};
