import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';
import { loadForm } from '../../../utils';

import Timestamp from './Timestamp';
import PostActions from '../../../containers/skill-builders/discussions/PostActions';
import DiscussionsPostEdit from '../../../containers/skill-builders/discussions/DiscussionsPostEdit';
import SharedDiscussionReflectionEdit from '../../../containers/skill-builders/discussions/SharedDiscussionReflectionEdit';
import Loading from '../../common/Loading';
import SocialActions from './SocialActions';
import ShowMoreComments from './ShowMoreComments';
import DiscussionAvatar from './DiscussionAvatar';
import Comment from '../../../containers/skill-builders/discussions/Comment';
import CommentFormHandler from '../../../containers/skill-builders/discussions/CommentFormHandler';
import SharedPostOwnerText from './SharedPostOwnerText';
import PostItemContainer from '../../dashboard/PostItemContainer';

const PostCard = ({
  isAuthenticated,
  type,
  unit,
  discussion,
  loadForms,
  currentUser,
  discussionComments,
  likes,
  showMore,
  profile,
  onshowMore,
  commentShowMore,
  onCommentShowMore,
  renderMoreComments,
  isRequesting,
  actualCommentCount,
  onDeleteClick
}) => {
  const { user, sharedEntityType, sharedSkillBuilderReflections } = discussion;
  const isLoadForm = loadForm(loadForms, `update-post-form-${discussion.id}`);
  const totalComments = actualCommentCount;
  const renderedCommentsCount = discussionComments.reduce((acc, curr)=> curr.replies ? acc + curr.replies.length + 1 : acc + 1, 0);
  const isSharedPost = sharedEntityType !== '';
  const isSharedReflection = isSharedPost && sharedEntityType === 'skillBuilderReflections';

  let postType = null;

  switch(sharedEntityType){
  case 'interviewResource':
    postType = 'video';
    break;

  case 'wall':
    postType = 'post';
    break;

  case 'badgeIssued':
    postType = 'badge';
    break;

  case 'file':
    postType = 'portfolio file';
    break;

  case 'discussion':
    postType = 'discussion';
    break;

  default:
    postType = 'post';
    break;
  }

  return (
    <div className="post">
      <div className="primary-post">
        <DiscussionAvatar user={user}/>

        <div className="right">
          {((isAuthenticated && currentUser.id === user.id) || (isAuthenticated && profile.role === 'super-admin')) &&
            <div className="btn-group edit-menu align-top float-right">
              <PostActions discussion={discussion} onDeleteClick={onDeleteClick}/>
            </div>}

          <div className="profile-title">
            <Link
              to={`/cp/${user.vanity}`}
              className="profile-name">
              {user.name}
            </Link>

            {isSharedPost && sharedEntityType === 'skillBuilderReflections' &&
              <span className="shared-type-text">&nbsp;shared
                {user.name === sharedSkillBuilderReflections[0].user.name ?
                  ' their reflection(s)' :
                  <span>
                    &nbsp;
                    <Link
                      className="profile-name"
                      to={`/cp/${sharedSkillBuilderReflections[0].user.vanity}`}
                      target="_blank"
                      title={`Link to ${sharedSkillBuilderReflections[0].user.name}'s webpage`}>
                      {sharedSkillBuilderReflections[0].user.name}
                    </Link>'s reflection(s)
                  </span>
                }
              </span>}

            {isSharedPost && (sharedEntityType !== 'skillBuilderReflections') &&
              <SharedPostOwnerText
                postType={postType}
                discussionPost={discussion}/>}

            <Timestamp postedAt={discussion.posted.date}/>
          </div>

          {!isLoadForm &&
            <PostItemContainer
              showMore={showMore}
              onshowMore={onshowMore}
              wallPost={discussion}/>}

          {isLoadForm &&
            (!isSharedReflection ?
              <DiscussionsPostEdit
                unit={unit}
                discussion={discussion}/>:
              <SharedDiscussionReflectionEdit
                unit={unit}
                discussion={discussion}/>)
          }

          <div className="share-stats">
            <span>{likes} Likes</span>

            <span
              style={{paddingLeft:5}}>
              {totalComments} Comments
            </span>
          </div>
        </div>

        <SocialActions
          type={type}
          discussion={discussion}
          isAuthenticated={isAuthenticated}/>

      </div>

      <Comment
        loadForms={loadForms}
        discussion={discussion}
        commentShowMore={commentShowMore}
        onCommentShowMore={onCommentShowMore}
        discussionComments={discussionComments}/>

      {isRequesting.status && isRequesting.discussionId === discussion.id ?
        <div className="showMoreBtn">
          <Loading loaderStyles={{ marginTop: 0 }}/>
        </div> :
        <ShowMoreComments
          renderedCommentsCount={renderedCommentsCount}
          actualCommentCount={actualCommentCount}
          discussionComments={discussionComments}
          renderMoreComments={renderMoreComments}/>}

      {isAuthenticated &&
        <CommentFormHandler discussionId={discussion.id}/>}
    </div>
  );
};

PostCard.propTypes = {
  unit: PropTypes.object,
  type: PropTypes.string,
  discussion: PropTypes.object,
  profile: PropTypes.object.isRequired,
  loadForms: PropTypes.array.isRequired,
  onshowMore: PropTypes.func.isRequired,
  onCommentShowMore: PropTypes.func.isRequired,
  commentShowMore: PropTypes.object.isRequired,
  discussionComments: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  likes: PropTypes.number,
  showMore: PropTypes.object.isRequired,
  renderMoreComments: PropTypes.func,
  isRequesting: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  actualCommentCount: PropTypes.number,
  onDeleteClick: PropTypes.func.isRequired,
};

export default PostCard;
