import PropTypes from 'prop-types';
import React from 'react';

import Header from './Header';

const ReflectQuestionList = (props) => {
  const {
    stickyStyles,
    unitTitle,
    isAdmin,
    slug,
    unit,
    path,
    isRequesting,
    noSection,
    onClickReviewEssentials,
    onClickEngage,
    onClickGetBadge
  } = props;

  return (
    <div>
      <div >
        <Header
          stickyStyles={stickyStyles}
          unitTitle={unitTitle}
          isAdmin={isAdmin}
          slug={slug}
          unit={unit}
          path={path}
          isRequesting={isRequesting}
          noSection={noSection}
          onClickReviewEssentials={onClickReviewEssentials}
          onClickEngage={onClickEngage}
          onClickGetBadge={onClickGetBadge} />
      </div>

      <div className="essential-wrapper" id="essential-wrapper" >
        <section className="essential-reflection p-2">
          <h3 className="text-center mt-2 mb-3">Engage & Connect on this topic by considering the following:</h3>
          <div className="question-item">
            <p><span className="fa fa-lg fa-check text-success mr-1"/>{unit.reflectone} </p>
          </div>
          <div className="question-item">
            <p><span className="fa fa-lg fa-check text-success mr-1"/>{unit.reflecttwo} </p>
          </div>
          <div className="question-item">
            <p><span className="fa fa-lg fa-check text-success mr-1"/>{unit.reflectthree} </p>
          </div>
          <div className="question-item">
            <p><span className="fa fa-lg fa-check text-success mr-1"/>{unit.reflectfour} </p>
          </div>
        </section>
      </div>
    </div>
  );
};

ReflectQuestionList.propTypes = {
  slug: PropTypes.string,
  unit: PropTypes.object.isRequired,
  unitTitle: PropTypes.string,
  isAdmin: PropTypes.bool,
  stickyStyles: PropTypes.string,
  path: PropTypes.string,
  isRequesting: PropTypes.bool,
  noSection: PropTypes.bool,
  onClickEngage: PropTypes.func.isRequired,
  onClickGetBadge: PropTypes.func.isRequired,
  onClickReviewEssentials: PropTypes.func.isRequired
};

export default ReflectQuestionList;
