import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

import { Row, Col } from 'reactstrap';

const SignupFormComponent = ({
  onChange,
  formData,
  errors,
  onSubmit,
  isSubmitting,
  data
}) => {
  const success = !isSubmitting && data.status === 200;
  return(
    <div
      style={{margin: '10px auto'}}
      className="modal-body homepage-modal-body invitation-login">
      <h4 className="modal-title" id="myModalLabel">
        Sign Up to Become an Admin
      </h4>
      {
        success &&
          <div>
            <p className="success-message">Joined Careerprepped successfully.</p>
            <p>Please login to explore.</p>
          </div>
      }
      {
        !success &&
          <div className="login-form">
            <form onSubmit={onSubmit}>
              <Row>
                <Col md="6">
                  <div className="form-group">
                    <label className="sr-only">First Name</label>
                    <input
                      type="text"
                      name="firstname"
                      value={formData.firstname}
                      onChange={onChange}
                      className="form-control"
                      placeholder="First Name"/>
                    {
                      errors.firstname
                      &&
                        <div className="text-danger">{errors.firstname}</div>
                    }
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="sr-only">Last Name</label>
                    <input
                      type="text"
                      name="lastname"
                      value={formData.lastname}
                      onChange={onChange}
                      className="form-control"
                      placeholder="Last Name"/>
                    {
                      errors.lastname
                      &&
                        <div className="text-danger">{errors.lastname}</div>
                    }
                  </div>
                </Col>
              </Row>
              <div className="form-group">
                <label className="sr-only">Password</label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={onChange}
                  className="form-control"
                  placeholder="Password"/>
                {
                  errors.password
                  &&
                    <div className="text-danger">{errors.password}</div>
                }
              </div>
              <div className="form-group">
                <label className="sr-only">Confirm Password</label>
                <input
                  type="password"
                  name="rePassword"
                  value={formData.rePassword}
                  onChange={onChange}
                  className="form-control"
                  placeholder="Confirm Password"/>
                {
                  errors.rePassword
                  &&
                    <div className="text-danger">{errors.rePassword}</div>
                }
              </div>
              <input
                type="submit"
                disabled={isSubmitting}
                value={isSubmitting ? 'Submitting...': 'Submit'}
                className="btn btn-primary w-100"/>
              <p className="terms-text">By registering you agree to our <Link to="/terms" target="_blank">Terms</Link></p>
            </form>
          </div>
      }
    </div>
  );
};
SignupFormComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired
};
export default SignupFormComponent;
