import initialState from './initialState';

import {
  CERTIFICATIONS_REQUEST,
  CERTIFICATIONS_SUCCESS,
  CERTIFICATIONS_FAILURE,
  CREATE_CERTIFICATION_REQUEST,
  CREATE_CERTIFICATION_SUCCESS,
  CREATE_CERTIFICATION_FAILURE,
  UPDATE_CERTIFICATION_REQUEST,
  UPDATE_CERTIFICATION_SUCCESS,
  UPDATE_CERTIFICATION_FAILURE,
  DELETE_CERTIFICATION_REQUEST,
  DELETE_CERTIFICATION_SUCCESS,
  DELETE_CERTIFICATION_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.certifications, action) {
  switch (action.type) {
  case CERTIFICATIONS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case CERTIFICATIONS_SUCCESS:
    return Object.assign({}, state, {
      data: action.data._embedded.certification,
      isRequesting: false,
    });

  case CERTIFICATIONS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case CREATE_CERTIFICATION_REQUEST:
    return Object.assign({}, state, { isSubmitting: true });

  case CREATE_CERTIFICATION_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data, Object.assign({}, action.data)],
      isSubmitting: false,
    });

  case CREATE_CERTIFICATION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case UPDATE_CERTIFICATION_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, certificationIndex: action.index },
    });

  case UPDATE_CERTIFICATION_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, state.isUpdating.certificationIndex),
        Object.assign({}, action.data),
        ...state.data.slice(state.isUpdating.certificationIndex + 1),
      ],
      isUpdating: { status: false, certificationIndex: null },
    });

  case UPDATE_CERTIFICATION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false, certificationIndex: null },
    });

  case DELETE_CERTIFICATION_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: action.id },
    });

  case DELETE_CERTIFICATION_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.filter(
          certification => certification.id !== state.isDeleting.id
        ),
      ],
      isDeleting: { status: false, id: null },
    });

  case DELETE_CERTIFICATION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: { status: false, id: null },
    });

  default:
    return state;
  }
}
