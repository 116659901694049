import PropTypes from 'prop-types';
import React from 'react';

import { Link, useLocation } from 'react-router-dom';
import JobCardDropup from './JobCardDropup';
import moment from 'moment';

const SavedJobCard = ({
  data,
  onToggle,
  isOpen,
  onSelectJobStatus,
  isDeleting,
  deleteJob,
  isGuestUser
}) => {
  const {
    company,
    title,
    id,
    date,
    status: jobStatus
  } = data;
  const savedDate = moment(date.date).format('MMM DD, YYYY');
  const disabledCondition = isDeleting.id === id;

  const location = useLocation();
  const user = location.state && location.state.user;

  const linkToJobDetails = isGuestUser ?
    `/job-center/job-details/${id}` :
    `/job-center/my-jobs/${id}`;

  return (
    <div className="col-lg-4 job-saved-items">
      <div className="job-saved-item">
        {!isGuestUser &&
          <button
            onClick={deleteJob(id)}
            disabled={disabledCondition}
            type="button"
            className="btn close delete-job-btn">
            <span aria-hidden="true">&times;</span>
          </button>}
        <Link to={linkToJobDetails} state={{user}}>
          <div className="item-info">
            <div className="company-title">{company}</div>
            <div className="company-position">{title}</div>
            <div className="save-date date-job">{savedDate}</div>
          </div>
        </Link>
        <JobCardDropup
          id={id}
          jobStatus={jobStatus}
          isGuestUser={isGuestUser}
          onSelectJobStatus={onSelectJobStatus}
          isOpen={isOpen}
          onToggle={onToggle}/>
      </div>
    </div>
  );
};

SavedJobCard.propTypes = {
  data: PropTypes.object.isRequired,
  onToggle: PropTypes.func.isRequired,
  isGuestUser: PropTypes.bool.isRequired,
  onSelectJobStatus: PropTypes.func.isRequired,
  deleteJob: PropTypes.func.isRequired,
  isDeleting: PropTypes.object,
  isOpen: PropTypes.bool.isRequired
};

export default SavedJobCard;
