import PropTypes from 'prop-types';
import React from 'react';

import Loading from '../../common/Loading';

const DescriptionList = ({ unit, isRequesting }) => {
  if (isRequesting)
    return <Loading />;

  return (
    <div>
      {
        unit.description !== '' ?
          <ul className="fa-ul">
            {unit.description.split('^^/')
              .filter(item => item !== '')
              .map((item, index) => {
                return (
                  <li key={index}>
                    <i className="fa-li fa fa-check" />
                    {item}
                  </li>
                );
              })
            }
          </ul>
          :
          <div>{'No description yet'}</div>
      }
    </div>
  );
};

DescriptionList.propTypes = {
  unit: PropTypes.object,
  isRequesting: PropTypes.bool
};

export default DescriptionList;
