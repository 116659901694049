import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isEmpty } from 'lodash';

import {
  Container,
  Row,
  Col
} from 'reactstrap';
import { toastr } from 'react-redux-toastr';

import {
  updateGroup,
  getUserAdminGroups,
  resetSubmittingDuplicateGroups,
  setIsAuthUserSelectedInstitutionAdmin
} from '../../redux/actions/groups';

import { openModal, closeModal } from '../../redux/actions/components';

import GroupsTable from './GroupsTable';
import Loading from '../../components/common/Loading';
import AdminGroupAdd from '../../components/admin/AdminGroupAdd';
import FirstLoginPage from '../../components/admin/FirstLoginPage';
import AdminGroupAddModal from '../../components/admin/AdminGroupAddModal';
import UpdateCodeModal from '../../components/admin/UpdateCodeModal';
import CreateGroupAlert from '../../components/admin/CreateGroupAlert';
import AdminGroupEditModal from '../../components/admin/AdminGroupEditModal';

class ManageGroups extends Component {
  static propTypes = {
    modal: PropTypes.string,
    actions: PropTypes.object.isRequired,
    isRequesting: PropTypes.bool,
    isInitialRequest: PropTypes.bool,
    isUpdating: PropTypes.object.isRequired,
    isInstitutionAdmin: PropTypes.bool,
    selectedGroup: PropTypes.object.isRequired,
    selectedInstitutionId: PropTypes.string,
    parentGroupsCount: PropTypes.number,
    institutionName: PropTypes.string,
    error: PropTypes.object.isRequired,
    isSubmitting: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onConfirmUpdateCode = this.onConfirmUpdateCode.bind(this);
  }

  componentWillMount() {
    const { actions, isInitialRequest, isInstitutionAdmin, selectedInstitutionId } = this.props;

    if (selectedInstitutionId && (! isInitialRequest)) {
      actions.setIsAuthUserSelectedInstitutionAdmin(isInstitutionAdmin);
      actions.getUserAdminGroups(selectedInstitutionId, '', true);
    }
  }

  componentWillUnmount() {
    this.props.actions.resetSubmittingDuplicateGroups();
  }

  onCloseModal() {
    this.props.actions.closeModal();
  }

  onConfirmUpdateCode() {
    const { actions, selectedGroup: { id: groupId, name: groupName } } = this.props;

    actions.updateGroup(groupId, { 'inviteCode': true })
      .then((state) => {
        const { groups: { error, isUpdating: { status } } } = state;

        if ((! status) && (isEmpty(error))) {
          toastr.success('', `You successfully updated the group code for ${groupName}`);

          actions.closeModal();
        }
      });
  }

  render() {
    const {
      modal,
      error,
      selectedGroup,
      isRequesting,
      isUpdating,
      selectedInstitutionId,
      isInstitutionAdmin,
      parentGroupsCount,
      institutionName,
      isSubmitting: { status: isSubmitting, duplicateGroups }
    } = this.props;

    const isAddGroupModalOpen = (modal && modal === 'admin-group-add');
    const isConfirmUpdateCodeModalOpen = (modal && modal === 'confirm-update-group-code');
    const isGroupEditModalOpen = (modal && modal === 'group-edit');

    if (isRequesting) {
      return (
        <div className="loading-container">
          <Loading/>
        </div>
      );
    }

    return (
      <div>
        {(isInstitutionAdmin && (parentGroupsCount === 0)) ?
          <FirstLoginPage
            id={selectedInstitutionId}
            organization={institutionName}
            groundLocations={0}
            onlinePrograms={0}
            institutionType={0}/> :

          <div className="interior manage-groups mt-3">
            <Container>
              <Row>
                <Col>
                  <div className="groupName mb-2">Manage Groups</div>
                  <div className="group-content mb-3">
                    {isInstitutionAdmin ?
                      <p>
                        From this page, add new groups, edit names of existing groups, or update group codes below.
                      </p> :
                      <p>
                        Groups organize users you invite to join CareerPrepped. Every group has a group code you can share with users so they can use it to sign themselves up to the group you want them to join by visiting <a href="https://www.careerprepped.com/group" target="_blank">www.careerprepped.com/group</a>. As an admin, you can create "child" groups within your groups if you prefer organizing your users into smaller cohorts. You can also designate admins to groups you create who will have the same privileges as you for their own groups.
                      </p>}
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <h2>
                    Groups
                    {isInstitutionAdmin &&
                      <AdminGroupAdd>
                        <i className="fa fa-plus text-white" />{' '}Add New Group
                      </AdminGroupAdd>}
                  </h2>
                </Col>
                <Col xs="auto">
                  {
                    /*
                      This search should be for groups,
                      This is just here for display.
                    */
                  }
                  {/* <SearchInput
                    table="invitations"
                    actions={this.props.actions}
                    onChangeSearchQuery={this.props.actions.onChangeInvitationsSearchQuery}
                    selectedMetricValue={this.props.selectedMetricValue}
                    groupId={this.props.groupId}
                    institutionId={this.props.institutionId}
                    className="pull-left mr-1"

                    searchQuery={this.props.searchQuery}
                    isRequesting={this.props.isRequesting}/> */}
                </Col>
              </Row>
              <Row>
                <Col>
                  {(isEmpty(error) &&
                    (duplicateGroups.length !== 0) &&
                    (! isSubmitting) &&
                    (! isRequesting)) &&
                    <CreateGroupAlert/>}

                  <GroupsTable/>
                </Col>
              </Row>
            </Container>
          </div>}

        {isAddGroupModalOpen &&
          <AdminGroupAddModal
            modal={modal}
            institutionId={selectedInstitutionId}
            groupId={selectedGroup.id}
            onCancel={this.onCloseModal}
            toggle={this.onCloseModal}
            isOpen={isAddGroupModalOpen} />}

        {isConfirmUpdateCodeModalOpen &&
          <UpdateCodeModal
            groupName={selectedGroup.name}
            isOpen={isConfirmUpdateCodeModalOpen}
            onCancel={this.onCloseModal}
            onConfirm={this.onConfirmUpdateCode}
            isUpdating={isUpdating.status}/>}

        {isGroupEditModalOpen &&
          <AdminGroupEditModal
            {...this.props}
            onCancel={this.onCloseModal}
            toggle={this.onCloseModal}
            isOpen={isGroupEditModalOpen} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let {
    groups: {
      isRequesting,
      isInitialRequest,
      isUpdating,
      data: userAdminGroups,
      selectedGroup,
      isSubmitting,
      error
    },
    components: { modal },
    institutions: {
      selectedInstitution: {
        id: selectedInstitutionId,
        isAdmin: isInstitutionAdmin,
        groupsCount: parentGroupsCount,
        name: institutionName
      }
    }
  } = state;

  return {
    modal,
    error,
    isRequesting,
    isInitialRequest,
    isUpdating,
    selectedGroup,
    isSubmitting,
    userAdminGroups,
    parentGroupsCount,
    isInstitutionAdmin,
    institutionName,
    selectedInstitutionId
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    openModal,
    closeModal,
    updateGroup,
    getUserAdminGroups,
    resetSubmittingDuplicateGroups,
    setIsAuthUserSelectedInstitutionAdmin
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageGroups);
