import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { useDrop } from 'react-dnd';

import ItemTypes from '../../constants/ItemTypes';

import update from 'immutability-helper';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';

import * as componentsActions from '../../redux/actions/components';
import * as unitActions from '../../redux/actions/skill-builders';
import * as sectionActions from '../../redux/actions/sections';
import * as reflectionsActions from '../../redux/actions/reflections';

import { toastr } from 'react-redux-toastr';

import Section from './Section';
import ReflectionQuestion from '../../components/skill-builders/essentials/ReflectionQuestion';
import WorkValuesExampleModal from '../../components/skill-builders/essentials/WorkValuesExampleModal';
import PersonalValuesExampleModal from '../../components/skill-builders/essentials/PersonalValuesExampleModal';
import PersonalityTraitsExampleModal from '../../components/skill-builders/essentials/PersonalityTraitsExampleModal';
import WarningCard from '../../components/skill-builders/WarningCard';
import ShareReflectionModal from './ShareReflectionModal';
import ReflectionGuidanceModal from '../../components/skill-builders/ReflectionGuidanceModal';
import ShareReflectionsSection from '../../components/skill-builders/ShareReflectionsSection';

const SectionList = (props) => {
  const {
    unit,
    units,
    actions,
    modal,
    hover,
    onMouseEnter,
    onMouseLeave,
    onEditClick,
    isAdmin,
    profile,
    noSection,
    sectionsList,
    reflections,
    updateResourceForms,
    onCancel,
    reflectionsDataStore
  } = props;

  const [isDownloading, setIsDownloading] = useState(false);
  const [sections, setSections] = useState([...sectionsList]);
  const [individuallyShare, setIndividuallyShare] = useState(null);
  const [selectedReflections, setSelectedReflections] = useState([]);

  useEffect(() => {
    setSections([...sectionsList]);
  }, [sectionsList]);

  function toggleIsDragging() {
    actions.toggleIsDragging();
  }

  function moveCard(dragIndex, hoverIndex) {
    const dragItem = sections[dragIndex];

    setSections(prevState => (update(prevState, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragItem]
      ]
    })));
  }

  function moveCardPersist() {
    const sectionIdsArray = map(sections, 'id');
    const sectionIds = sectionIdsArray.join(',');
    const unitData = { ...unit, sectionsOrder: sectionIds };
    const unitIndex = units.findIndex(unitItem => unitItem.id === unit.id);

    actions.updateUnit(unitData, unitIndex)
      .then(() => actions.updateSectionsOrderSuccess(sections));
  }

  function onWorkValuesSeeExamples() {
    actions.openModal('work-values-examples-modal');
  }

  function onPersonalityTraitsSeeExamples() {
    actions.openModal('personality-traits-examples-modal');
  }

  function onPersonalValuesSeeExamples() {
    actions.openModal('personal-values-examples-modal');
  }

  function onCloseModal() {
    if(individuallyShare !== null){
      setIndividuallyShare(null);
    }

    actions.closeModal();
  }

  function onSelectReflection(id) {
    return () => {
      if (reflections[id].answer === '') {
        toastr.warning(`You can't share Question ${id.substring(id.length - 1)} because you haven't saved an answer yet.`);
        return;
      }

      setSelectedReflections(prevState => ([
        ...(
          prevState.includes(id) ?
            prevState.filter(itemId => itemId !== id) :
            [...prevState, id]
        )
      ]));
    };
  }

  function onShareReflection() {
    if (selectedReflections.length === 0) {
      toastr.warning('You must select at least one question to share.');
      return;
    }

    actions.openModal('share-reflection-modal');
  }

  async function onDownloadReflections() {
    setIsDownloading(true);

    if (reflectionsDataStore.data.length !== 4) {
      setIsDownloading(false);

      toastr.warning('You must save all your reflection answers to download.');
      return;
    }

    const reflectionIds = map(reflectionsDataStore.data, 'id');
    const data = { 'reflections': reflectionIds };

    const response = await actions.downloadReflections(data);

    let a = document.createElement('a');

    a.href = response.reflections.isDownloading.data.url;
    a.setAttribute('download', 'downloaded-document');
    a.style.display = 'none';

    document.body.appendChild(a);

    a.click();

    document.body.removeChild(a);

    setIsDownloading(false);
  }

  function onShareIndividualReflection(id) {
    return () => {
      if (reflections[id].answer === '') {
        toastr.warning(`You can't share Question ${id.substring(id.length - 1)} because you haven't saved an answer yet.`);
        return;
      }

      setIndividuallyShare(id);
      actions.openModal('share-reflection-modal');
    };
  }

  function toggleReflectionGuidanceModal(event) {
    event.preventDefault();

    if (modal === 'reflection-guidance-modal') {
      actions.closeModal();
      return;
    }

    actions.openModal('reflection-guidance-modal');
  }

  const { q1, q2, q3, q4 } = reflections;

  const isReflectionGuidanceModalOpen = modal && modal === 'reflection-guidance-modal';
  const isWorkValuesExampleModalOpen = modal && modal === 'work-values-examples-modal';
  const isPersonalityTraitsExampleModalOpen = modal && modal === 'personality-traits-examples-modal';
  const isPersonalValuesExampleModalOpen = modal && modal === 'personal-values-examples-modal';
  const isShareReflectionModalOpen = modal && modal === 'share-reflection-modal';
  const selectedReflectionsForSharing = individuallyShare !== null ? [individuallyShare] : selectedReflections;

  const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }));

  return (
    <div className="essential">
      {((!isUndefined(unit.reflectone) && unit.reflectone.length !== 0) ||
        (!isUndefined(unit.reflecttwo) && unit.reflecttwo.length !== 0)) &&
        <ReflectionQuestion
          unit={unit}
          isAdmin={isAdmin}
          onShareIndividualReflection={onShareIndividualReflection}
          responseOne={q1}
          responseTwo={q2}
          questionOne={unit.reflectone}
          questionTwo={unit.reflecttwo}
          nameOne="q1"
          nameTwo="q2"
          valueOne={q1.answer}
          valueTwo={q2.answer}
          toggleReflectionGuidanceModal={toggleReflectionGuidanceModal}/>}

      {noSection ?
        <WarningCard
          warning={'Oops ... there are no sections for this unit.'}/>
        :
        <div ref={drop}>
          {sections.map((section, index) =>
            (<Section
              key={section.id}
              index={index}
              unit={unit}
              section={section}
              profile={profile}
              isAdmin={isAdmin}
              hover={hover}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onEditClick={onEditClick}
              updateResourceForms={updateResourceForms}
              onCancel={onCancel}
              moveCard={moveCard}
              moveCardPersist={moveCardPersist}
              onWorkValuesSeeExamples={onWorkValuesSeeExamples}
              onPersonalityTraitsSeeExamples={onPersonalityTraitsSeeExamples}
              onPersonalValuesSeeExamples={onPersonalValuesSeeExamples}
              toggleIsDragging={toggleIsDragging}/>))}
        </div>}

      {((!isUndefined(unit.reflectthree) && unit.reflectthree.length !== 0) ||
        (!isUndefined(unit.reflectfour) && unit.reflectfour.length !== 0)) &&
        <ReflectionQuestion
          unit={unit}
          isAdmin={isAdmin}
          onShareIndividualReflection={onShareIndividualReflection}
          questionOne={unit.reflectthree}
          questionTwo={unit.reflectfour}
          nameOne="q3"
          nameTwo="q4"
          responseOne={q3}
          responseTwo={q4}
          valueOne={q3.answer}
          valueTwo={q4.answer}
          toggleReflectionGuidanceModal={toggleReflectionGuidanceModal}/>}

      {isWorkValuesExampleModalOpen &&
        <WorkValuesExampleModal
          isOpen={isWorkValuesExampleModalOpen}
          closeModal={onCloseModal}/>}

      {isPersonalityTraitsExampleModalOpen &&
        <PersonalityTraitsExampleModal
          isOpen={isPersonalityTraitsExampleModalOpen}
          closeModal={onCloseModal}/>}

      {isPersonalValuesExampleModalOpen &&
        <PersonalValuesExampleModal
          isOpen={isPersonalValuesExampleModalOpen}
          closeModal={onCloseModal}/>}

      {isShareReflectionModalOpen &&
        <ShareReflectionModal
          unit={unit}
          reflections={reflections}
          closeModal={onCloseModal}
          isOpen={isShareReflectionModalOpen}
          selectedReflections={selectedReflectionsForSharing}/>}

      {!isAdmin &&
        <div className="badge-relative">
          <ShareReflectionsSection
            unit={unit}
            onSelectReflection={onSelectReflection}
            onShareReflection={onShareReflection}
            selectedReflections={selectedReflections}
            isDownloading={isDownloading}
            onDownloadReflections={onDownloadReflections}/>
        </div>}

      {isReflectionGuidanceModalOpen &&
        <ReflectionGuidanceModal
          isOpen={isReflectionGuidanceModalOpen}
          toggleReflectionGuidanceModal={toggleReflectionGuidanceModal}/>}
    </div>
  );
};

SectionList.propTypes = {
  unit: PropTypes.object.isRequired,
  units: PropTypes.array.isRequired,
  hover: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  sectionsList: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  reflections: PropTypes.object.isRequired,
  updateResourceForms: PropTypes.array.isRequired,
  reflectionsDataInStore: PropTypes.object,
  noSection: PropTypes.bool.isRequired,
  modal: PropTypes.string,
  profile: PropTypes.object,
  reflectionsDataStore: PropTypes.object.isRequired
};

const getReflections = ({ data }) => {
  let result = {};

  data.forEach((reflection) => {
    const { question, answer, id } = reflection;

    result[question] = {
      id,
      question,
      answer
    };
  });

  return result;
};

const mapStateToProps = (state) => {
  const { reflections, components } = state;
  let reflectionsData = getReflections(reflections);

  const q1 = { question: 'q1', answer: '' };
  const q2 = { question: 'q2', answer: '' };
  const q3 = { question: 'q3', answer: '' };
  const q4 = { question: 'q4', answer: '' };

  const reflectionsArray = {
    q1: reflectionsData.q1 ? reflectionsData.q1 : q1,
    q2: reflectionsData.q2 ? reflectionsData.q2 : q2,
    q3: reflectionsData.q3 ? reflectionsData.q3 : q3,
    q4: reflectionsData.q4 ? reflectionsData.q4 : q4
  };

  return {
    profile: state.profile.data,
    reflections: reflectionsArray,
    sectionsList: state.sections.data,
    units: state.skillBuilders.data,
    reflectionsDataInStore: reflectionsData,
    modal: components.modal,
    reflectionsDataStore: reflections
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    unitActions,
    sectionActions,
    componentsActions,
    reflectionsActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionList);
