import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import Validator from '../../validator';
import ForgotPasswordForm from '../../components/auth/ForgotPasswordForm';
import NewPassword from './NewPassword';
import * as resetPasswordActions from '../../redux/actions/reset-password';

class ForgotPassword extends Component {
  static propTypes = {
    toggleForgotPassword: PropTypes.func.isRequired,
    forgotPassword: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    linkParams: PropTypes.string,
    newPassword: PropTypes.bool,
    onCloseResetPassword: PropTypes.func,
    resetPassword: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      errors: {},
      forgotPassword: Object.assign({}, this.props.forgotPassword)
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps){
    const { data } = this.props.resetPassword;
    const { isSubmitting: nextIsSubmitting, data: nextData } = nextProps.resetPassword;
    if (data !== nextData && nextData.status === 200 && !nextIsSubmitting) {
      const initState = {
        email: ''
      };

      this.setState({forgotPassword: Object.assign({}, initState)});
    }
  }

  isValid(field = null) {
    let validate = Validator.createValidator({
      email: ['required', 'email'],
    }, this.state.forgotPassword, field);

    let { isValid, errors } = validate;

    this.setState({ errors });

    return isValid;
  }

  onSubmit(event) {
    event.preventDefault();

    let { forgotPassword } = this.state;

    if (! this.isValid()) return false;

    this.props.actions.resetPasswordRequest(forgotPassword);

  }

  onChange(event) {
    const { name, value } = event.target;
    const { forgotPassword } = this.state;
    forgotPassword[name] = value;

    this.setState({ forgotPassword }, () => this.isValid(name));
  }

  render() {
    const { toggleForgotPassword, resetPassword, linkParams, newPassword, onCloseResetPassword } = this.props;
    const { forgotPassword , errors } = this.state;
    const { data, isSubmitting } = resetPassword;
    if ( newPassword ) {
      return <NewPassword linkParams={linkParams} onCloseResetPassword={onCloseResetPassword}/> ;
    }
    return(
      <div>
        <Helmet title="Forgot password"/>
        <ForgotPasswordForm
          data={data}
          email={forgotPassword.email}
          errors={errors}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
          isSubmitting={isSubmitting}
          toggleForgotPassword={toggleForgotPassword}/>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  let forgotPassword = { email: ''};

  return {
    forgotPassword,
    resetPassword: state.resetPassword
  };
};
const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({},resetPasswordActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
