import { CALL_API } from '../../middlewares/api';

import {
  CONTACT_US_REQUEST,
  CONTACT_US_SUCCESS,
  CONTACT_US_FAILURE,
} from '../../constants';

export const contactRequest = (data) => {
  const endpoint = 'contact';

  return {
    [CALL_API]: {
      endpoint,
      data,
      method: 'post',
      types: [CONTACT_US_REQUEST, CONTACT_US_SUCCESS, CONTACT_US_FAILURE],
    },
  };
};
