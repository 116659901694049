import React, {useState} from 'react';

import PropTypes from 'prop-types';
import { includes} from 'lodash';

import FeedbackLimitTooltip from '../FeedbackLimitTooltip';

const ManageIRHeader = (props) => {
  const [clickedHeaders, setClickedHeaders] = useState([]);
  const [nonClickedHeaders, setNonClickedHeaders] = useState([
    'name', 'cluster', 'industry', 'jobTitle', 'company', 'city'
  ]);

  function clickHeader(event){
    const headerName = event.target.id;

    // update the noncliked headers
    if(includes(nonClickedHeaders, headerName)){
      const updatedNonClickedHeadersList = nonClickedHeaders.filter(header => header!== headerName);
      setNonClickedHeaders(updatedNonClickedHeadersList);
    }

    // update the clicked headers
    if(includes(clickedHeaders, headerName)){
      const updatedClickedHeadersList = clickedHeaders.filter(header => header!== headerName);
      setClickedHeaders(updatedClickedHeadersList);
    }else{
      setClickedHeaders([...clickedHeaders, headerName]);
    }
  }

  function onSortRequest(event){
    clickHeader(event);
    props.onSortTable(event);
  }


  function getSortIconClassName(column){
    if(includes(nonClickedHeaders, column)){
      return 'fa-sort';
    } else if(includes(clickedHeaders, column)){
      return 'fa-sort-down';
    }
    return 'fa-sort-up';
  }

  return (
    <>
      <th
        id="name"
        className="cursor-pointer"
        data-column-name="name"
        onClick={onSortRequest}
        style={{ width: 180 }}>
        Name
        <i
          id="name"
          className={`ml-1 fas ${getSortIconClassName('name')}`}/>
      </th>
      <th
        id="cluster"
        data-column-name="cluster"
        onClick={onSortRequest}  
        className="cursor-pointer"
        style={{ width: 150 }}>
        Cluster
        <i
          id="cluster"
          className={`ml-1 fas ${getSortIconClassName('cluster')}`}/>
      </th>
      <th
        id="industry"
        data-column-name="industry"
        onClick={onSortRequest} 
        className="cursor-pointer"
        style={{ width: 150 }}>
        Industry
        <i
          id="industry"
          className={`ml-1 fas ${getSortIconClassName('industry')}`}/>
      </th>  
      <th
        id="jobTitle"
        data-column-name="jobTitle"
        onClick={onSortRequest} 
        className="cursor-pointer"
        style={{ width: 150 }}>
        Job Title
        <i
          id="jobTitle"
          className={`ml-1 fas ${getSortIconClassName('jobTitle')}`}/>
      </th>     
      <th
        id="company"
        data-column-name="company"
        onClick={onSortRequest} 
        className="cursor-pointer"
        style={{ width: 150 }}>
        Company
        <i
          id="company"
          className={`ml-1 fas ${getSortIconClassName('company')}`}/>
      </th>
      <th
        id="city"
        data-column-name="city"
        onClick={onSortRequest}
        className="cursor-pointer"
        style={{ width: 150 }}>
        Location
        <i
          id="city"
          className={`ml-1 fas ${getSortIconClassName('city')}`}/>
      </th>
      <th style={{ width: 150, textAlign: 'center' }}>
        Feedback Requests
      </th>
      <th style={{ width: 100, textAlign: 'center' }}>
        <FeedbackLimitTooltip />
      </th> 
      <th style={{width: 150}}>Action</th>
      {/* <th style={{width: 150, textAlign: 'center'}} /> */}
    </>);
};

ManageIRHeader.propTypes = {
  onSortTable: PropTypes.func.isRequired,
};

export default ManageIRHeader;