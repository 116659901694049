import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as resumeActions from '../../redux/actions/resume';
import * as componentActions from '../../redux/actions/components';
import { getMyCareerPreppedStatus } from '../../redux/actions/my-careerprepped-status';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { toastr } from 'react-redux-toastr';

import UnitResume from '../../components/resume/UnitResume';
import Loading from '../../components/common/Loading';
import DeleteResumeModal from '../../components/resume/DeleteResumeModal';

import { withHooks } from '../../utils/withHooks';

const validMimeType = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

class ResumeContainer extends Component {
  static propTypes = {
    resume: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    modal: PropTypes.string,
    params: PropTypes.object,
    location: PropTypes.object,
    profile: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      downloadDropdown: null
    };

    this.onUploadResume = this.onUploadResume.bind(this);
    this.toggleDownloadDropdown = this.toggleDownloadDropdown.bind(this);
    this.onDeleteResume = this.onDeleteResume.bind(this);
    this.onCancelDelete = this.onCancelDelete.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
  }

  componentDidMount(){
    const { isAuthenticated, actions, params } = this.props;

    if (isAuthenticated)
      actions.resumeRequest(params.userId);
  }

  componentDidUpdate(prevProps) {
    const { resume: { isDeleting: { id } }, params: prevParams } = prevProps;
    const { resume: { isDeleting: { id: nextId } }, params, actions } = this.props;

    if (nextId !== id && nextId === null) {
      this.onCancelDelete();
    }

    if (params !== prevParams) {
      actions.resumeRequest(params.userId);
    }
  }

  toggleDownloadDropdown(id){
    return () => {
      const { downloadDropdown } = this.state;
      const value = downloadDropdown === id ? null : id;
      this.setState({
        downloadDropdown : value
      });
    };
  }

  onDeleteResume(event){
    const { id } = event.currentTarget;

    this.setState({
      resumeId: id
    }, () => this.props.actions.openModal('confirmDeleteFileModal'));
  }

  onConfirmDelete(){
    const { resumeId } = this.state;

    this
      .props
      .actions
      .deleteResume(resumeId)
      .then(() => this.props.actions.getMyCareerPreppedStatus(this.props.profile.id));
  }

  onCancelDelete(){
    this.setState({
      resumeId: null
    }, () => this.props.actions.closeModal());
  }

  onUploadResume(event) {
    const files = event.target.files;
    const file = files[0];

    if (!validMimeType.includes(file.type)) {
      return toastr.error('', 'Resume files must be a PDF or Word .doc or .docx file.');
    }

    const formData = new FormData();
    formData.append('file', file);

    this.props
      .actions
      .uploadResume(formData)
      .then(() => {
        this.props.actions.getMyCareerPreppedStatus(this.props.profile.id);
        toastr.success('', 'Your resume was successfully uploaded.');
      });
  }

  render() {
    const { resume, modal, location: { state: routeState } } = this.props;
    const { isRequesting, isUploading, isDeleting, data: resumeArray } = resume;
    const { downloadDropdown, resumeId } = this.state;
    const fileDeleteModalOpen = resumeId !== null && modal === 'confirmDeleteFileModal';
    const isOwner = routeState === null;

    return (
      <div className="col-lg-12 content targeted-resume" style={{marginTop: 30}}>
        <Helmet title="Resumés"/>
        <div className="row">
          <div className="col-lg-12">
            <h2>Targeted Resume</h2>
            <div className="sub-menu"/>
          </div>
          <div className="col-lg-12">
            {isOwner ?
              <>
                Edit information in your Career Site to quickly create Targeted Resumes tailored to specific jobs you’re interested in applying to. Save your Targeted Resumes and download them when you want to apply to a job. Check out <Link to="/skill-builders/develop-targeted-resumes">Develop Targeted Resumes</Link> for tips on how to create an effective resume.
                <div className="filterbar">
                  <Link
                    style={{minWidth: '180px', maxWidth: '180px'}}
                    className="btn btn-primary mr-2"
                    to="/toolbox/resume/create">
                    Create new resume
                  </Link> Create a Targeted Resume from your Career Site.
                </div>
                <div className="filterbar">
                  <label
                    htmlFor="uploadResume"
                    className={
                      classNames('btn btn-primary mr-2', {
                        'disabled': isUploading
                      })}
                    style={{minWidth: '180px', maxWidth: '180px', fontWeight: 'normal'}}>
                    {isUploading ? 'Uploading Resume...' : 'Upload Resume'}
                  </label> Upload an existing resume to save and download when needed.
                  <input
                    type="file"
                    name="fname"
                    id="uploadResume"
                    style={{display:'none'}}
                    onChange={this.onUploadResume}
                    accept={validMimeType.toString()}/>
                </div>
              </> :
              <>
                {routeState.user.name}'s saved resumes are generated from their Career Site data which they've edited using the Resume Builder tool in the Job Center. As an admin, you can download {routeState.user.name}'s saved resumes as Word documents for viewing and editing.
              </>}

            <p className="mt-4 mb-2">
              <b>{`${isOwner ? '' : `${routeState.user.name}'s `}Saved Resumés`}</b>
            </p>

            {isRequesting &&
              <div className="loading-container">
                <Loading/>
              </div>}

            {(! isRequesting && (resumeArray.length === 0)) &&
              <div className="alert alert-info" role="alert">
                {`${isOwner ? 'You haven\'t' : 'This user hasn\'t'} saved any targeted resumes yet.`}
              </div>}

            {(! isRequesting && (resumeArray.length !== 0)) &&
              <div className="saved-resume-container">
                {!isRequesting && resumeArray.map(resume =>
                  (<UnitResume
                    key={resume.id}
                    resume={resume}
                    downloadDropdown={downloadDropdown}
                    toggleDownloadDropdown={this.toggleDownloadDropdown}
                    onDeleteResume={this.onDeleteResume}
                    title={resume.name}/>))}
              </div>}
          </div>
        </div>

        {fileDeleteModalOpen &&
          <DeleteResumeModal
            isOpen={fileDeleteModalOpen}
            resumeId={resumeId}
            isDeleting={isDeleting}
            onCancel={this.onCancelDelete}
            onConfirm={this.onConfirmDelete}/>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    resume: state.resume,
    modal: state.components.modal,
    profile: state.profile.data,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, resumeActions, componentActions, { getMyCareerPreppedStatus });

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(ResumeContainer));
