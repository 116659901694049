import initialState from './initialState';

import {
  RESET_JOB_MATCHES,
  RESET_JOB_MATCHES_DATA,

  JOB_MATCHES_REQUEST,
  JOB_MATCHES_SUCCESS,
  JOB_MATCHES_FAILURE,

  JOB_MATCHES_CITIES_REQUEST,
  JOB_MATCHES_CITIES_SUCCESS,
  JOB_MATCHES_CITIES_FAILURE,

  ADD_JOB_TO_TRACKER_REQUEST,
  ADD_JOB_TO_TRACKER_SUCCESS,
  ADD_JOB_TO_TRACKER_FAILURE,

  UPDATE_JOB_MATCHES_QUERY_PARAMS,

  TOGGLE_SELECT_JOB_MATCH,
  TOGGLE_SELECT_ALL_JOB_MATCHES,
  TOGGLE_SORT_JOB_MATCHES_FILTER,
  TOGGLE_JOB_MATCHES_FILTER,
  TOGGLE_JOB_MATCHES_LOCATION_FILTER
} from '../../constants';

export default function reducer(state = initialState.jobMatches, action) {
  switch (action.type) {
  case RESET_JOB_MATCHES:
    return Object.assign({}, state, {
      data: initialState.jobMatches.data,
      queryParams: initialState.jobMatches.queryParams,
      paginationData: {
        ...state.paginationData,
        pageCount: initialState.jobMatches.paginationData.pageCount
      }
    });

  case RESET_JOB_MATCHES_DATA:
    return Object.assign({}, state, {
      data: initialState.jobMatches.data,
      paginationData: {
        ...state.paginationData,
        page: initialState.jobMatches.paginationData.page,
        pageCount: initialState.jobMatches.paginationData.pageCount
      }
    });

  case JOB_MATCHES_REQUEST:
    return Object.assign({}, state, {
      isRequesting: true,
      paginationData: {
        ...state.paginationData,
        page: action.id
      }
    });

  case JOB_MATCHES_SUCCESS:
    return Object.assign({}, state, {
      isRequesting: false,
      paginationData: {
        ...state.paginationData,
        pageCount: Math.ceil(action.data.matchSize/state.paginationData.pageSize),
        totalItems: action.data.matchSize,
        hasMoreResults: action.data.hasMoreResults,
        matchSize: action.data.matchSize,
        searchAfter: action.data.searchAfter
      },
      data: [...state.data, ...action.data.job_matches]
    });

  case JOB_MATCHES_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false
    });

  case JOB_MATCHES_CITIES_REQUEST:
    return Object.assign({}, state, { isRequestingCities: true });

  case JOB_MATCHES_CITIES_SUCCESS:
    return Object.assign({}, state, {
      isRequestingCities: false,
      citiesPaginationData: {
        page: action.data.page,
        pageCount: action.data.page_count,
        totalItems: action.data.total_items
      },
      cities: [...state.cities, ...action.data._embedded.cities]
    });

  case JOB_MATCHES_CITIES_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequestingCities: false
    });

  case ADD_JOB_TO_TRACKER_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: true
    });

  case ADD_JOB_TO_TRACKER_SUCCESS:
    return Object.assign({}, state, {
      isSubmitting: false,
      data: [
        ...state.data.map((job) => {
          if (action.data.jobs.some(newJob => newJob.jobkey === job.jobkey)) {
            return { ...job, status: 1 };
          }

          return job;
        })
      ],
      selected: [],
      allDuplicatesSubmitted: action.data.jobs.length === 0
    });

  case ADD_JOB_TO_TRACKER_FAILURE:
    return Object.assign({}, state, {
      isSubmitting: false,
      error: action.error
    });

  case TOGGLE_SELECT_JOB_MATCH:
    return Object.assign({}, state, {
      selected: (state.selected.includes(action.selectedId)) ?
        [...state.selected.filter(jobId => jobId !== action.selectedId)] :
        [...state.selected, action.selectedId]
    });

  case TOGGLE_SELECT_ALL_JOB_MATCHES:
    return Object.assign({}, state, {
      selected: (state.selected.length !== state.data.length) ?
        [...state.data.map(job => job.id)] : []
    });

  case TOGGLE_SORT_JOB_MATCHES_FILTER:
    return Object.assign({}, state, {
      queryParams: {
        ...state.queryParams,
        sort: { ...state.queryParams.sort, ...action.sort }
      }
    });

  case UPDATE_JOB_MATCHES_QUERY_PARAMS:
    return Object.assign({}, state, {
      queryParams: {
        ...state.queryParams,
        ...action.queryParams
      }
    });

  case TOGGLE_JOB_MATCHES_FILTER:
    return Object.assign({}, state, {
      filters: {
        ...state.filters,
        [action.filterName]: action.filterValue === null ?
          null : { ...state.filters[action.filterName], ...action.filterValue }
      }
    });

  case TOGGLE_JOB_MATCHES_LOCATION_FILTER:
    return Object.assign({}, state, {
      filters: {
        ...state.filters,
        jobLocation: [...action.filterValues]
      }
    });

  default:
    return state;
  }
}
