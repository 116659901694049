import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import classNames from 'classnames';

const sortOptions = [
  { name: 'Newest', value: 'newest' },
  { name: 'Most Portfolio Files', value: 'most_portfolio_files' },
  { name: 'Most Endorsements', value: 'most_endorsements' },
  { name: 'Most Supporting Evidence', value: 'most_supporting_evidence' }
];

const SkillBadgesFilterBar = ({
  activeSort,
  searchValue,
  onToggleDropdown,
  isDropdownOpen,
  onApply,
  onSearchChange,
  onSearchSubmit,
  onSelectBulkChange,
  onSortChange,
  isRequestingSort,
  isRequestingSearch,
  selectedBulkAction,
  isOwner,
  onSearchKeyPress,
  hasNoClaimedBadge,
  onClearSearch
}) => {
  const btnLabel = sortOptions.find(option => option.value === activeSort);

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="filterbar mb-4">
          <div className="row">
            {
              isOwner && !hasNoClaimedBadge &&
                <div className="col-md-auto mb-2 mb-md-0">
                  <div className="d-flex align-items-center">
                    <select
                      className="form-control"
                      value={selectedBulkAction}
                      aria-label="Bulk Select"
                      onChange={onSelectBulkChange}>
                      <option value="none">Bulk Action</option>
                      <option value="endorsementModal">Seek Endorsements</option>
                    </select>
                    <button
                      type="button"
                      name="button"
                      onClick={onApply}
                      className="btn btn-primary ml-2 mr-2">
                      Apply
                    </button>
                    <Dropdown
                      disabled={isRequestingSort}
                      toggle={onToggleDropdown}
                      isOpen={isDropdownOpen}>
                      <DropdownToggle
                        id="dropdownMenuButton"
                        className="btn-dropdown-toggle">
                        {btnLabel.name}
                      </DropdownToggle>
                      <DropdownMenu onClick={onToggleDropdown}>
                        {sortOptions.map((item) => {
                          return (
                            <a
                              className={classNames(
                                'dropdown-item grey-dropdown-btn',
                                {'active-item-filter': item.value === activeSort}
                              )}
                              key={item.value}
                              onClick={onSortChange}
                              id={item.value}>
                              {item.name}
                            </a>
                          );
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
            }
            <div className="col-md-auto">
              <div className="form-group">
                <form
                  onKeyPress={onSearchKeyPress}
                  className="input-group">
                  <input type="text"
                    className="form-control"
                    value={searchValue}
                    onChange={onSearchChange}
                    aria-label="Search"
                    placeholder="Search"/>
                  {
                    searchValue.trim() !== '' &&
                      <span
                        onClick={onClearSearch}
                        className="clickable clear-searcbox">
                        x
                      </span>
                  }

                  <div className="input-group-append">
                    <button
                      disabled={isRequestingSearch}
                      className="btn btn-secondary"
                      type="submit"
                      onClick={onSearchSubmit}>
                      <i className="fa fa-search"><span className="sr-only">Search</span></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SkillBadgesFilterBar.propTypes = {
  hasNoClaimedBadge: PropTypes.bool,
  selectedBulkAction: PropTypes.string.isRequired,
  onApply: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onSearchSubmit: PropTypes.func.isRequired,
  isRequestingSearch: PropTypes.bool.isRequired,
  activeSort: PropTypes.string.isRequired,
  onSortChange: PropTypes.func.isRequired,
  onSelectBulkChange: PropTypes.func.isRequired,
  isDropdownOpen: PropTypes.bool.isRequired,
  onToggleDropdown: PropTypes.func.isRequired,
  isRequestingSort: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool,
  onClearSearch: PropTypes.func.isRequired,
  onSearchKeyPress: PropTypes.func.isRequired
};

export default SkillBadgesFilterBar;
