import { createSelector } from 'reselect';

const selectIndustryRepresentatives = state => state.industryRepresentatives;

export const selectIndustryRepresentativesList = createSelector(
  [selectIndustryRepresentatives],
  industryRepresentatives => industryRepresentatives.data
);

export const selectFormattedIndustryRepresentatives = createSelector(
  [selectIndustryRepresentativesList],
  industryRepresentatives =>
    industryRepresentatives.map(
      industryRepresentative => ({
        label: industryRepresentative.name,
        value: industryRepresentative.id
      })
    )
);

export const selectIndustryRepresentativesPageCount = createSelector(
  [selectIndustryRepresentatives],
  industryRepresentatives => industryRepresentatives.pageCount
);
