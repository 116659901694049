import PropTypes from 'prop-types';
import React from 'react';
import PortfolioTabContent from './PortfolioTabContent';
import PortfolioVisibiltyConfirmation from '../../containers/skill-badges/PortfolioVisibiltyConfirmation';
import classNames from 'classnames';

const LinkingPortfolioModalComponent = ({ 
  noOfPubicFiles,
  privateFiles,
  showPrivateConfirmation,
  activeTab, 
  onTabChange, 
  files, 
  isRequestingFiles,
  selectFile,
  selectedFiles,
  linkedFiledIds,
  closeModal,
  bakedBadgePage,
  onNext,
  onPrev,
  onSelectPage,
  fileData,
  skillsCard,
  getMyBadges,
  getMySkills,
  linkingSkill,
  onLinkedSkill,
}) => {

  function loadDetailsPage() {
    if (onLinkedSkill ){
      onLinkedSkill();
    }
  }


  if(showPrivateConfirmation){
    return (
      <PortfolioVisibiltyConfirmation
        noOfPubicFiles={noOfPubicFiles} 
        privateFiles={privateFiles}
        getMyBadges={getMyBadges}
        getMySkills={getMySkills}
        linkingSkill={linkingSkill}
        onLinkedSkill={loadDetailsPage}/>);
  }
 
  return(
    <div>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item">
          <a 
            onClick={onTabChange(0)}
            className={classNames('nav-link clickable link', {
              'orange': activeTab !== 0,
              'active': activeTab === 0
            })} 
            id="gallery-tab">
            Portfolio
          </a>
        </li>
        <li className="nav-item">
          <a 
            onClick={onTabChange(1)}
            className={classNames('nav-link clickable link', {
              'orange': activeTab !== 1,
              'active': activeTab === 1
            })}
            id="upload-tab">
            Upload New
          </a>
        </li>
      </ul>
      <PortfolioTabContent
        files={files}
        onNext={onNext}
        onPrev={onPrev}
        onSelectPage={onSelectPage}
        fileData={fileData}
        bakedBadgePage={bakedBadgePage}
        isRequestingFiles={isRequestingFiles}
        selectFile={selectFile}
        selectedFiles={selectedFiles}
        linkedFiledIds={linkedFiledIds}
        closeModal={closeModal}
        activeTab={activeTab}
        skillsCard={skillsCard}
        getMyBadges={getMyBadges}
        getMySkills={getMySkills}/>
    </div>
  );
};

LinkingPortfolioModalComponent.propTypes = {
  activeTab: PropTypes.number,
  onTabChange: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  selectFile: PropTypes.func.isRequired,
  isRequestingFiles: PropTypes.bool.isRequired,
  selectedFiles: PropTypes.array,
  closeModal: PropTypes.func.isRequired,
  bakedBadgePage: PropTypes.bool,
  privateFiles: PropTypes.array,
  linkedFiledIds: PropTypes.array.isRequired,
  showPrivateConfirmation: PropTypes.bool,
  noOfPubicFiles: PropTypes.number,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  onSelectPage: PropTypes.func,
  fileData: PropTypes.object,
  skillsCard: PropTypes.bool,
  getMyBadges: PropTypes.func,
  getMySkills: PropTypes.func,
  linkingSkill: PropTypes.bool,
  onLinkedSkill: PropTypes.func,
};

export default LinkingPortfolioModalComponent;
