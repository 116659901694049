import React, { PureComponent } from 'react';
import { Link, Outlet, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isNull, isUndefined } from 'lodash';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'reactstrap';

import { withHooks } from '../../utils/withHooks';

import {
  getUserAdminGroups,
  setIsAuthUserSelectedInstitutionAdmin
} from '../../redux/actions/groups';

import { profileRequest } from '../../redux/actions/profile';
import { getUserInstitutions, setSelectedInstitution } from '../../redux/actions/institutions';

import Loading from '../../components/common/Loading';
import UserInstitutionsDropdown from '../../components/admin/UserInstitutionsDropdown';

class AdminPageWrapper extends PureComponent {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    institutions: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    profile: PropTypes.object,
  }

  constructor(props) {
    super(props);

    this.onChangeInstitution = this.onChangeInstitution.bind(this);
  }

  componentWillMount() {
    const { actions, isAuthenticated, currentUser: { id: userId } } = this.props;

    actions.getUserInstitutions();

    if (isAuthenticated) {
      actions.profileRequest(userId);
    }
  }

  onChangeInstitution(event) {
    const {
      actions,
      institutions: { data: userInstitutions }
    } = this.props;
    const { value: institutionId } = event.target;

    const selectedInstitution = userInstitutions
      .find(institution => institution.id === institutionId);

    actions.setSelectedInstitution(selectedInstitution);

    const { isAdmin, id: selectedInstitutionId } = selectedInstitution;

    actions.setIsAuthUserSelectedInstitutionAdmin(isAdmin);
    actions.getUserAdminGroups(selectedInstitutionId);
  }

  render() {
    const { location, profile, institutions } = this.props;

    const {
      isRequesting,
      selectedInstitution,
      data: userInstitutions
    } = institutions;

    if (isRequesting) {
      return (
        <div className="loading-container">
          <Loading/>
        </div>
      );
    }

    const {
      id: selectedInstitutionId,
      isAdmin: isInstitutionAdmin,
      groupsCount: parentGroupsCount
    } = selectedInstitution;

    let adminInstitution = undefined;

    if (profile && profile.adminInstitutions) {
      adminInstitution = profile.adminInstitutions.find(institution => institution.id === selectedInstitutionId);
    }

    if (!isUndefined(adminInstitution) && !isNull(adminInstitution.subscriptionStatus) && adminInstitution.subscriptionStatus !== 'active') {
      return <Navigate to="/restart-subscription" state={{subscriptionType: 'organizationUser'}} replace />;
    }

    return (
      <div>
        <Helmet title="Organization - Dashboard"/>
        <div className="admin-navbar admin-panel" style={{ padding:16 }}>
          <Container>
            <Row>
              {(userInstitutions.length > 1) &&
                <UserInstitutionsDropdown
                  onChangeInstitution={this.onChangeInstitution}/>}
              <Col lg="8" md="7">
                <h2 className="mb-0">{selectedInstitution.name}</h2>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="admin-navbar">
          <Container>
            <Row>
              <Col lg="12">
                {((! isInstitutionAdmin) || (isInstitutionAdmin && (parentGroupsCount !== 0))) &&
                  <Link
                    to="/organization/admin"
                    className={location.pathname === '/organization/admin' ? 'active' : ''}>
                    Dashboard
                  </Link>}


                <Link
                  to="/organization/admin/manage-groups"
                  className={
                    (location.pathname === '/organization/admin/manage-groups' ||
                      location.pathname === `/organization/admin/${selectedInstitution.id}`) ?
                      'active' : ''
                  }>
                  Manage Groups
                </Link>

                {((! isInstitutionAdmin) || (isInstitutionAdmin && (parentGroupsCount !== 0))) &&
                  <Link
                    to="/organization/admin/manage"
                    className={location.pathname === '/organization/admin/manage' ? 'active' : ''}>
                    Manage Admins
                  </Link>}

                <Link
                  to="/organization/admin/manage-irs"
                  className={location.pathname === '/organization/admin/manage-irs' ? 'active' : ''}>
                  Manage Industry Mentors
                </Link>

                <Link
                  to="/organization/admin/job-sourcer-tool"
                  className={location.pathname === '/organization/admin/job-sourcer-tool' ? 'active' : ''}>
                  Job Sourcer Tool
                </Link>

                <Link
                  to="/organization/admin/resource-center"
                  className={location.pathname === '/organization/admin/resource-center' ? 'active' : ''}>
                  Resource Center
                </Link>
              </Col>
            </Row>
          </Container>
        </div>

        <Outlet/>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    auth: {
      isAuthenticated,
      data: { currentUser }
    },
    institutions
  } = state;

  return {
    currentUser,
    isAuthenticated,
    institutions,
    profile: state.profile.data,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    profileRequest,
    getUserInstitutions,
    setSelectedInstitution,
    getUserAdminGroups,
    setIsAuthUserSelectedInstitutionAdmin,
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AdminPageWrapper));
