import initialState from './initialState';

import { uniqBy, isUndefined } from 'lodash';

import {
  EDUCATORS_REQUEST,
  EDUCATORS_SUCCESS,
  EDUCATORS_FAILURE,
  EDUCATOR_GROUPS_REQUEST,
  EDUCATOR_GROUPS_SUCCESS,
  EDUCATOR_GROUPS_FAILURE,
  CREATE_EDUCATOR_GROUPS_REQUEST,
  CREATE_EDUCATOR_GROUPS_SUCCESS,
  CREATE_EDUCATOR_GROUPS_FAILURE,
  UPDATE_EDUCATOR_GROUP_REQUEST,
  UPDATE_EDUCATOR_GROUP_SUCCESS,
  UPDATE_EDUCATOR_GROUP_FAILURE,
  EDUCATOR_GROUPS_STATISTICS_REQUEST,
  EDUCATOR_GROUPS_STATISTICS_SUCCESS,
  EDUCATOR_GROUPS_STATISTICS_FAILURE,
  SET_SELECTED_EDUCATOR_GROUP,
  RESET_SUBMITTING_DUPLICATE_EDUCATOR_GROUPS,
} from '../../constants';

const populateGroups = (state, action) => {
  const {
    data: {
      _embedded: { groups },
      page: currentPage,
      page_size,
      page_count,
    },
  } = action;

  let {
    page: initialPage,
    pageSize: initialPageSize,
    pageCount: initialPageCount,
    selectedGroup: defaultSelectedGroup,
  } = initialState.groups;

  const page = isUndefined(currentPage) ? initialPage : currentPage;
  const pageSize = isUndefined(page_size) ? initialPageSize : page_size;
  const pageCount = isUndefined(page_count) ? initialPageCount : page_count;

  const allGroupsObject = {
    id: 'all-groups',
    name: 'All Groups',
    label: 'All Groups',
    value: 'all-groups',
  };

  defaultSelectedGroup = groups.length === 1 ? groups[0] : allGroupsObject;

  const groupsArray =
    groups.length === 1
      ? groups
      : state.isInitialRequest
        ? [allGroupsObject, ...groups]
        : [...state.groups, ...groups];

  return {
    groups: [...groupsArray],
    selectedGroup: defaultSelectedGroup,
    page,
    pageSize,
    pageCount,
    isRequesting: false,
    isInitialRequest: false,
  };
};

const updateGroup = (state, action) => {
  const groupIndex = state.groups.findIndex(
    group => group.id === state.selectedGroup.id
  );

  return [
    ...state.groups.slice(0, groupIndex),
    action.data,
    ...state.groups.slice(groupIndex + 1),
  ];
};

export default function reducer(state = initialState.educators, action) {
  switch (action.type) {
  case EDUCATORS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case EDUCATORS_SUCCESS: {
    let educators = action?.data?._embedded?.educators
      .filter(e => e?.username || e?.userName)
      .map((e) => {
        return {
          label: e?.username || e?.userName,
          value: e.id,
        };
      });
    educators = uniqBy(educators, 'value');

    return Object.assign({}, state, {
      isRequesting: false,
      page: action.data.page,
      pageCount: action.data.page_count,
      data: educators
    });
  }

  case EDUCATORS_FAILURE:
    return Object.assign({}, state, { isRequesting: false });

  case EDUCATOR_GROUPS_REQUEST:
    return Object.assign({}, state, {
      isRequesting: true,
      isInitialRequest: action.isInitialRequest,
    });

  case EDUCATOR_GROUPS_SUCCESS:
    return Object.assign({}, state, populateGroups(state, action));

  case EDUCATOR_GROUPS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
      isInitialRequest: false,
    });

  case EDUCATOR_GROUPS_STATISTICS_REQUEST:
    return Object.assign({}, state, { isRequestingStats: true });

  case EDUCATOR_GROUPS_STATISTICS_SUCCESS:
    return Object.assign({}, state, {
      statistics: action.data,
      isRequestingStats: false,
    });

  case EDUCATOR_GROUPS_STATISTICS_FAILURE:
    return Object.assign({}, state, {
      isRequestingStats: false,
      error: action.error,
    });

  case CREATE_EDUCATOR_GROUPS_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: { status: true },
    });

  case CREATE_EDUCATOR_GROUPS_SUCCESS:
    return Object.assign({}, state, {
      groups: [...action.data.groups, ...state.groups],
      isSubmitting: {
        status: false,
        duplicateGroups: action.data.duplicateGroups,
      },
    });

  case CREATE_EDUCATOR_GROUPS_FAILURE:
    return Object.assign({}, state, {
      isSubmitting: { status: false },
    });

  case UPDATE_EDUCATOR_GROUP_REQUEST:
    return Object.assign({}, state, { isUpdating: true });

  case UPDATE_EDUCATOR_GROUP_SUCCESS:
    return Object.assign({}, state, {
      groups: [...updateGroup(state, action)],
      isUpdating: false,
    });

  case UPDATE_EDUCATOR_GROUP_FAILURE:
    return Object.assign({}, state, {
      isUpdating: false,
      error: action.error,
    });

  case SET_SELECTED_EDUCATOR_GROUP:
    return Object.assign({}, state, { selectedGroup: action.selectedGroup });

  case RESET_SUBMITTING_DUPLICATE_EDUCATOR_GROUPS:
    return Object.assign({}, state, {
      isSubmitting: { ...initialState.educators.isSubmitting },
    });

  default:
    return state;
  }
}
