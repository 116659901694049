import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isEqual } from 'lodash';

import Validator from '../../validator';

import Select from 'react-select';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { AsyncPaginate } from 'react-select-async-paginate';

import CompareJobsModal from '../../components/jobs/CompareJobsModal';
import JobMatchesResults from '../../components/jobs/JobMatchesResults';
import InfiniteScroller from '../common/infinite-scroll/InfiniteScroller';
// import { UncontrolledTooltip } from 'reactstrap';

import { openModal } from '../../redux/actions/components';
import { toggleJobMatchSettingsInitialSearchRequest } from '../../redux/actions/job-match-settings';

import { getStates } from '../../redux/actions/states';
import { getCities } from '../../redux/actions/cities';

import {
  resetJobMatches,
  resetJobMatchesData,
  addJobsToTracker,
  requestJobMatches,
  toggleSortFilter,
  toggleJobMatchesFilter,
  requestJobMatchesCities,
  toggleSelectAllJobMatches,
  toggleJobMatchesLocationFilter,
  updateJobMatchesQueryParams
} from '../../redux/actions/job-matches';

import { selectModal } from '../../redux/selectors/components';

import { selectJobMatchSettingsObject } from '../../redux/selectors/job-match-settings';

import {
  selectFormattedStates,
  selectStatesPageCount
} from '../../redux/selectors/states';
import {
  selectFormattedCities,
  selectCitiesPageCount
} from '../../redux/selectors/cities';

import {
  selectJobMatchesList,
  selectJobMatchesFilters,
  selectSelectedJobMatches,
  selectIsRequestingJobMatches,
  selectIsSelectedAllJobMatches,
  selectIsSubmittingJobMatch,
  selectJobMatchesPaginationData,
  selectJobMatchesQueryParams
} from '../../redux/selectors/job-matches';

import jobsConstants from '../../constants/jobs';

class JobMatchesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stateId: '',
      errors: {}
    };

    this.callAPI = this.callAPI.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onSaveJobs = this.onSaveJobs.bind(this);
    this.loadOptions = this.loadOptions.bind(this);
    this.onChangeSort = this.onChangeSort.bind(this);
    this.onSelectAllJobs = this.onSelectAllJobs.bind(this);
    this.onChangeFilters = this.onChangeFilters.bind(this);
    this.onClickFilterJobs = this.onClickFilterJobs.bind(this);
    this.onChangeLocationFilters = this.onChangeLocationFilters.bind(this);
    this.onChangeQueryParams = this.onChangeQueryParams.bind(this);
    this.onChangeDropdown = this.onChangeDropdown.bind(this);
    this.onClickReset = this.onClickReset.bind(this);
    this.searchJobs = this.searchJobs.bind(this);
    this.onChangeRadius = this.onChangeRadius.bind(this);
  }

  componentDidMount() {
    if (!this.props.jobMatchSettings.initialSearchRequest) {
      this.props.actions.resetJobMatches();
    }
  }

  componentDidUpdate(prevProps) {
    const { actions, jobMatchSettings } = this.props;
    const isEqualJobMatchSettings = isEqual(jobMatchSettings, prevProps.jobMatchSettings);

    if (!isEqualJobMatchSettings && (jobMatchSettings.status) && (!jobMatchSettings.initialSearchRequest)) {
      actions.resetJobMatches();
      //      this.callAPI(1);
    }
  }

  async loadOptions(search, loadedOptions, actionMeta) {
    const { page, name: inputName } = actionMeta;
    const { actions } = this.props;

    if (inputName === 'state') {
      // default to US
      const country = '1eb2d271-1f99-11ec-a80e-0242ac120003';

      await actions.getStates(country, page, search);
    }

    if (inputName === 'city') {
      await actions.getCities(this.props.queryParams.state.value, page, search);
    }

    const options = (inputName === 'state') ? this.props.states : this.props.cities;

    const pageCount = (inputName === 'state') ?
      this.props.statesPageCount : this.props.citiesPageCount;

    return {
      options,
      hasMore: pageCount > page,
      additional: { page: page + 1, name: inputName }
    };
  }

  onChangeDropdown(value, actionMeta) {
    const { name } = actionMeta;

    const queryParams = {
      ...this.props.queryParams,
      [name]: value,
      ...((name === 'state') && { city: null })
    };

    this.props.actions.updateJobMatchesQueryParams(queryParams);

    this.setState({
      ...((name === 'state') && { stateId: value.id })
    },
    () => this.isValid(name));
  }

  isValid(field = null) {
    const rules = {
      query: ['required'],
      state: ['required'],
      city: ['required']
    };
    const { queryParams } = this.props;

    const validate = Validator.createValidator(rules, queryParams, field);
    const { errors, isValid } = validate;

    this.setState({ errors });

    return isValid;
  }

  onChangeQueryParams(event) {
    let { name, value } = event.target;

    if (name === 'remoteJobs') {
      value = !this.props.queryParams.remoteJobs;
    }

    const queryParams = {
      ...this.props.queryParams,
      [name]: value
    };

    this.props.actions.updateJobMatchesQueryParams(queryParams);
  }

  onChangeRadius(radius) {
    const queryParams = { ...this.props.queryParams, radius };

    this.props.actions.updateJobMatchesQueryParams(queryParams);
  }

  onClickReset(event) {
    event.preventDefault();
    this.props.actions.resetJobMatches();
  }

  onChangeFilters(filterValue, { name: filterName }) {
    this.props.actions.toggleJobMatchesFilter(filterName, filterValue);
  }

  async onClickFilterJobs() {
    const { sort, filters, actions } = this.props;

    actions.resetJobMatches();
    await actions.requestJobMatches(1, sort, filters);
  }

  onChangeLocationFilters(filterValues) {
    const isRemoteEnabled = filterValues.some(item => item.value === 'remoteJob');

    if (isRemoteEnabled && filterValues.length > 2) {
      return;
    }

    if (!isRemoteEnabled && filterValues.length > 1) {
      return;
    }

    this.props.actions.toggleJobMatchesLocationFilter(filterValues);
  }

  async onChangeSort(sort) {
    const { actions } = this.props;

    actions.resetJobMatchesData();
    actions.toggleSortFilter(sort);

    const queryParams = {
      ...this.props.queryParams,
      sort: { ...this.props.queryParams.sort, ...sort }
    };

    await actions.requestJobMatches(1, queryParams);
  }

  callAPI(pageNo) {
    const { actions, queryParams, paginationData } = this.props;

    if (pageNo !== 1) {
      actions.toggleJobMatchSettingsInitialSearchRequest(true);
    }

    return actions.requestJobMatches(pageNo, queryParams, paginationData);
  }

  searchJobs() {
    const { actions, queryParams } = this.props;

    actions.resetJobMatchesData();
    actions.requestJobMatches(1, queryParams);
  }

  onSelectAllJobs() {
    this.props.actions.toggleSelectAllJobMatches();
  }

  openModal(event) {
    const { target: { id } } = event;

    this.props.actions.openModal(id);
  }

  async onSaveJobs() {
    const { actions, selectedJobMatches } = this.props;
    const selectedCount = selectedJobMatches.length;

    let toastrType = 'success';
    let successMessage = selectedCount === 1 ?
      'This job was successfully saved! See all your saved jobs in "Job Tracker".' :
      'These jobs were successfully saved! See all your saved jobs in "Job Tracker".';

    await actions.addJobsToTracker({ jobMatchesIds: selectedJobMatches });

    if (this.props.allDuplicatesSubmitted) {
      toastrType = 'warning';
      successMessage = 'You\'ve already saved these jobs. See all your saved jobs in "Job Tracker".';
    }

    toastr[toastrType]('', successMessage);
  }

  render() {
    const {
      sort,
      modal,
      queryParams,
      jobMatches,
      isRequesting,
      paginationData
    } = this.props;

    const isCompareJobsModalOpen = modal === 'compare-jobs';

    return (
      <div>
        <div className="jobboard-bar">
          <div className="row">
            <div className="col">
              <div className="jobboard-form-container">
                <div className="jobboard-form-fields">
                  {/* Input - What */}
                  <div className="form-group">
                    <label className="form-control-label mb-1">What</label>
                    <input
                      type="text"
                      name="query"
                      autoFocus
                      className="form-control"
                      value={queryParams.query}
                      onChange={this.onChangeQueryParams}
                      placeholder="Job title, keywords or company name"/>
                  </div>
                  {/* Location - State */}
                  <div className="form-group">
                    <label className="form-control-label mb-1">State</label>
                    <AsyncPaginate
                      cacheOptions
                      isSearchable
                      name="state"
                      isClearable={false}
                      additional={{ page: 1, name: 'state' }}
                      loadOptions={this.loadOptions}
                      placeholder="State"
                      hideSelectedOptions={false}
                      value={queryParams.state}
                      onChange={this.onChangeDropdown} />
                  </div>
                  {/* Location - City */}
                  <div className="form-group">
                    <label className="form-control-label mb-1">City</label>
                    <AsyncPaginate
                      cacheOptions
                      isSearchable
                      name="city"
                      cacheUniqs={[this.state.stateId]}
                      additional={{ page: 1, name: 'city' }}
                      isClearable={false}
                      loadOptions={this.loadOptions}
                      placeholder="City"
                      hideSelectedOptions={false}
                      value={queryParams.city}
                      onChange={this.onChangeDropdown} />
                  </div>
                  <div className="form-group">
                    <label className="form-control-label mb-1">Distance</label>
                    <Select
                      onChange={this.onChangeRadius}
                      clearable={false}
                      searchable={false}
                      simpleValue
                      value={queryParams.radius}
                      options={jobsConstants.radiusOptions}
                      style={{ width: 80 }} />
                  </div>
                  {/* Checkbox - Remote Jobs */}
                  {/* <div className="remote-job-search form-group form-check">
                    <input
                      type="checkbox"
                      name="remoteJobs"
                      checked={queryParams.remoteJobs}
                      onChange={this.onChangeQueryParams}
                      className="form-check-input"
                      id="remote-jobs" />
                    <label
                      className="form-check-label"
                      htmlFor="remote-jobs">
                      Search remote jobs only
                    </label>
                    <i
                      id="tooltip-remote-jobs"
                      className="ml-1 fas fa-question-circle clickable text-orange"/>
                    <UncontrolledTooltip
                      placement="top"
                      target="tooltip-remote-jobs"
                      trigger="click">
                      When checked, this option prioritizes remote jobs. Any selected State and City will be ignored to focus on finding remote opportunities. Uncheck to include location preferences in your job search.
                    </UncontrolledTooltip>
                    </div> */}
                </div>
                {/* Form Actions */}
                <div className="jobboard-form-options">
                  <a
                    href="#"
                    className="btn btn-primary-outline text-orange"
                    onClick={this.onClickReset}>
                    Reset
                  </a>
                  <button
                    type="submit"
                    name="button"
                    className="btn btn-primary"
                    disabled={isRequesting}
                    onClick={this.searchJobs}>
                    {isRequesting ? 'Finding...' : 'Find Jobs'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cp-filter-bar mt-3">
          <Select
            name="sort"
            isSearchable={false}
            options={jobsConstants.textKernelSortOptions}
            value={sort}
            className="mr-0"
            classNamePrefix="filter-dropdown"
            onChange={this.onChangeSort}/>
        </div>

        <InfiniteScroller
          callAPI={this.callAPI}
          shouldCallAPIInitially={false}
          component={JobMatchesResults}
          page_count={paginationData.pageCount}
          page={paginationData.page}
          wallPosts={jobMatches}
          isLoaderInternal/>

        {isCompareJobsModalOpen && <CompareJobsModal/>}
      </div>
    );
  }
}

JobMatchesList.propTypes = {
  modal: PropTypes.string,
  sort: PropTypes.object.isRequired,
  cities: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  jobMatches: PropTypes.array.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  jobMatchSettings: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  paginationData: PropTypes.object.isRequired,
  selectedJobMatches: PropTypes.array.isRequired,
  allDuplicatesSubmitted: PropTypes.bool.isRequired,
  isSelectedAllJobMatches: PropTypes.bool.isRequired,
  queryParams: PropTypes.object.isRequired,
  states: PropTypes.array.isRequired,
  statesPageCount: PropTypes.number.isRequired,
  citiesPageCount: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  modal: selectModal(state),
  jobMatches: selectJobMatchesList(state),
  isSubmitting: selectIsSubmittingJobMatch(state),
  isRequesting: selectIsRequestingJobMatches(state),
  selectedJobMatches: selectSelectedJobMatches(state),
  jobMatchSettings: selectJobMatchSettingsObject(state),
  isSelectedAllJobMatches: selectIsSelectedAllJobMatches(state),
  paginationData: selectJobMatchesPaginationData(state),
  sort: state.jobMatches.queryParams.sort,
  queryParams: selectJobMatchesQueryParams(state),
  filters: selectJobMatchesFilters(state),
  states: selectFormattedStates(state),
  statesPageCount: selectStatesPageCount(state),
  cities: selectFormattedCities(state),
  citiesPageCount: selectCitiesPageCount(state),
  allDuplicatesSubmitted: state.jobMatches.allDuplicatesSubmitted
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    getStates,
    getCities,
    openModal,
    resetJobMatches,
    resetJobMatchesData,
    addJobsToTracker,
    requestJobMatches,
    toggleSelectAllJobMatches,
    toggleSortFilter,
    toggleJobMatchesFilter,
    requestJobMatchesCities,
    toggleJobMatchesLocationFilter,
    updateJobMatchesQueryParams,
    toggleJobMatchSettingsInitialSearchRequest
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(JobMatchesList);
