import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import _isObject from 'lodash/isObject';

import _isEmpty from 'lodash/isEmpty';
import { SimpleToolTipWrapper } from '../../components/common/ToolTipWrapper';
import Validator from '../../validator';
import { formatFormDataArray } from '../../utils';
import { withHooks } from '../../utils/withHooks';
import classNames from 'classnames';

import * as badgesActions from '../../redux/actions/badges';
import * as badgeAssertionActions from '../../redux/actions/badge-assertion';
import * as skillsActions from '../../redux/actions/skills';
import * as componentActions from '../../redux/actions/components';

import SkillBadgeCardCount from './skillBadgeCardCount';
import LinkingPortfolioModal from './LinkingPortfolioModal';
import EndorsementModal from './EndorsementModal';
import BadgePortfolioFiles from '../../components/skill-badges/BadgePortfolioFiles';
import UnitBadgeEndorsements from '../../components/skill-badges/UnitBadgeEndorsements';
import DeleteFileConfirmModal from '../../components/skill-badges/DeleteFileConfirmModal';

class UnitBadgeEvidence extends Component {
  static propTypes = {
    files: PropTypes.array,
    issuedBadgeId: PropTypes.string,
    location: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    unitSkill: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    badgeAssertion: PropTypes.object.isRequired,
    modal: PropTypes.string,
    endorsements: PropTypes.array,
    user: PropTypes.object,
    onClickEndorse: PropTypes.func.isRequired,
    isBadgeOwner: PropTypes.bool,
    onViewPortfolio: PropTypes.func.isRequired,
    isBadge: PropTypes.bool,
    onLinkedSkill: PropTypes.func,
    skillData: PropTypes.object,
    lastFileUploadedDate: PropTypes.string,
    lastEndorsementUploadedDate: PropTypes.string,
    endorsementCount: PropTypes.number,
    skillEvidenceView: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      fileId: null,
      errors: {},
      showAllFiles: false,
      selectedFile: {
        title: '',
        description: '',
        file: ''
      }
    };

    this.onCancel = this.onCancel.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onRemoveFile = this.onRemoveFile.bind(this);
    this.onRemoveFile = this.onRemoveFile.bind(this);
    this.onAddEvidence = this.onAddEvidence.bind(this);
    this.onFileSelected = this.onFileSelected.bind(this);
    this.onAddEvidenceCancel = this.onAddEvidenceCancel.bind(this);
    this.onChangeSelectedFile = this.onChangeSelectedFile.bind(this);
    this.onToggleLinkingFilesModal = this.onToggleLinkingFilesModal.bind(this);
    this.onToggleEndorseRequestModal = this.onToggleEndorseRequestModal.bind(this);
    this.toggleShowAllFiles = this.toggleShowAllFiles.bind(this);
  }

  isValid(field = null) {
    const rules = {
      title: ['required', 'minLength|6'],
      description: ['maxLength|100']
    };
    const { selectedFile } = this.state;

    const validate = Validator.createValidator(rules, selectedFile, field);
    const { errors, isValid } = validate;

    this.setState({ errors });

    return isValid;
  }

  onFileSelected(files) {
    if (files.length > 0 && _isObject(files[0])) {
      const selectedFile = Object.assign({}, this.state.selectedFile, {
        'file': files[0]
      });

      this.setState({ selectedFile });
    }
  }

  onAddEvidence() {
    const { issuedBadgeId, actions } = this.props;
    const { selectedFile } = this.state;

    if (! this.isValid()) return;

    let formData = new FormData();

    formData.append('file', selectedFile.file);
    formData.append('title', selectedFile.title);
    formData.append('description', selectedFile.description);
    const newFormData = formatFormDataArray(formData, 'badgeIssuedIds', [issuedBadgeId]);

    actions.addEvidenceFileToIssuedBadge(newFormData).
      then(()=>{
        const newSelectedFile = {
          title: '',
          description: '',
          file: ''
        };
        this.setState({ selectedFile : newSelectedFile });
      });
  }

  onToggleLinkingFilesModal() {
    const { modal, actions, badgeAssertion, skillData } = this.props;
    const isFilesModalOpen = modal && modal === 'fileModalInBakedBadgePage';
    if (!isFilesModalOpen) {
      const selectedBadges = ( !this.props.isBadge ) ? [
        {
          id: skillData.id,
          name: skillData.name,
          issued: { id: skillData.id },
          files: skillData.files,
          type: 'skill'
        }
      ] : [
        {
          id: badgeAssertion.data.badge.id,
          name: badgeAssertion.data.badge.name,
          issued: badgeAssertion.data.issued,
          files: badgeAssertion.data.files
        }
      ];

      actions.saveSelectedBadges(selectedBadges);
      return actions.openModal('fileModalInBakedBadgePage');
    }

    actions.closeModal();
  }

  onToggleEndorseRequestModal(){
    const { modal, actions, badgeAssertion, skillData } = this.props;
    const isEndorseRequestModalOpen = modal && modal === 'requestEndorseModalInBakedBadgePage';
    if(!isEndorseRequestModalOpen){
      const selectedBadges = ( !this.props.isBadge ) ?
        [{
          id: skillData.id,
          name: skillData.name,
          issued: '',
        }] : [{
          id: badgeAssertion.data.badge.id,
          name: badgeAssertion.data.badge.name,
          issued: badgeAssertion.data.issued,
        }];

      actions.saveSelectedBadges(selectedBadges);
      return actions.openModal('requestEndorseModalInBakedBadgePage');
    }

    actions.closeModal();
  }

  onChangeSelectedFile(event) {
    const { name, value } = event.target;

    const selectedFile = Object.assign({}, this.state.selectedFile, {
      [name]: value
    });

    this.setState({ selectedFile });
  }

  onRemoveFile(fileId) {
    return ()=>{
      this.setState({ fileId } , () => this.props.actions.openModal('confirmDeleteFileModal'));
    };
  }

  onConfirm(){
    const { issuedBadgeId, location: { pathname } } = this.props;
    const { fileId } = this.state;
    let data;
    if ( this.props.skillEvidenceView ) {
      data = {skillIds: [issuedBadgeId],};
    } else {
      data = {badgeIssuedIds: [issuedBadgeId],};
    }

    if (pathname.includes('badge-details')) {
      this.props.actions.unlinkBadgeEvidenceFile(fileId, data)
        .then(() => this.setState({ fileId: null }));

      return;
    }

    this.props.actions.unlinkEvidenceFile(fileId, data).
      then(() => this.setState({fileId: null}));
  }

  onCancel(){
    this.setState({
      fileId: null
    }, () => this.props.actions.closeModal());
  }

  onAddEvidenceCancel() {
    const selectedFile = {
      title: '',
      description: '',
      file: '',
    };

    this.setState({ selectedFile });
  }

  toggleShowAllFiles(){
    this.setState({
      showAllFiles: !this.state.showAllFiles
    });
  }

  render() {
    const { fileId, showAllFiles } = this.state;
    const {
      files,
      unitSkill,
      currentUser,
      badgeAssertion,
      issuedBadgeId,
      lastFileUploadedDate,
      lastEndorsementUploadedDate,
      endorsementCount,
      modal,
      endorsements,
      user,
      onClickEndorse,
      isBadgeOwner,
      onViewPortfolio,
      isBadge,
      skillData,
      onLinkedSkill,
      skillEvidenceView,
      location: { pathname }
    } = this.props;

    const showMoreFilesCondition = files && files.length > 5;
    const filesArray = showMoreFilesCondition && !showAllFiles ? files.slice(0,5):
      files;

    const isBadgeFilesOwner = badgeAssertion.data?.user?.id ? currentUser.id === badgeAssertion?.data?.user?.id :
      currentUser.id === user?.id;
    const condition = files && files.length > 0;
    const fileDeleteModalOpen = fileId !== null && modal === 'confirmDeleteFileModal';
    const isFilesModalOpen = modal && modal === 'fileModalInBakedBadgePage';
    const isEndorseRequestModalOpen = modal && modal === 'requestEndorseModalInBakedBadgePage';
    const isDeleting = pathname.includes('badge-details') ?
      badgeAssertion.isDeleting :
      unitSkill.isUpdating;

    return (
      <>
        <div className="section-badge">
          {skillEvidenceView ? (<h6 className="d-flex">
            <span>Portfolio Files</span>
            <SimpleToolTipWrapper
              id={`last_file-${issuedBadgeId}`}
              toolTipContent={<p>{`Last File: ${lastFileUploadedDate}`}</p>}>
              <p className="mb-0 ml-1">
                ({files ? files.length : 0})
              </p>
            </SimpleToolTipWrapper>
          </h6>): <h6>Supporting Evidence</h6>
          }

          <div className="section-badge-content">
            <div className="row">
              <div className="col-sm-12">
                <span className={`font14 subtitle ${skillEvidenceView ? '' : 'badgeOrangelink'}`}>
                  {skillEvidenceView ? '' : 'Portfolio Files'}
                  {
                    isBadgeFilesOwner &&
                    <div
                      className={`btn btn-tertiary btn-sm ${skillEvidenceView ? '' : 'ml-2'}`}
                      onClick={this.onToggleLinkingFilesModal}>
                      {skillEvidenceView ? 'Link Files' : 'Link Portfolio Evidence'}
                    </div>
                  }
                </span>
                <div id="portfolio" className={`mt-4 ${skillEvidenceView ? '' : 'mt-1'}`}>
                  {!condition && (
                    <p className="no-badge-content">No files available.</p>
                  )}
                  {condition &&
                  <div className="badge-portfolio">
                    {isBadge && filesArray.map((item, index) => (
                      <BadgePortfolioFiles
                        key={index}
                        file={item}
                        files={files}
                        unlink={this.onRemoveFile}
                        isOwner={isBadgeFilesOwner}
                        onViewPortfolio={onViewPortfolio}
                        skillEvidenceView={skillEvidenceView}/>
                    ))}

                    {!isBadge && !_isEmpty(filesArray) &&
                    filesArray.map((data, index) => (
                      <SkillBadgeCardCount
                        key={index}
                        fileKey={index}
                        data={files}
                        onViewPortfolio={onViewPortfolio}
                        skillEvidenceView={skillEvidenceView}
                        unlink={this.onRemoveFile}
                        isOwner={isBadgeFilesOwner}/>
                    ))}

                    {showMoreFilesCondition &&
                      <div>
                        <div
                          onClick={this.toggleShowAllFiles}
                          className={classNames('port-file show-more-portfolio-btn clickable',{
                            'add-more-height': isBadgeFilesOwner
                          })}>
                          <span>{showAllFiles ? 'show less' : `+${files.length - 5} more`}</span>
                        </div>
                      </div>
                    }
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
          {fileDeleteModalOpen && (
            <DeleteFileConfirmModal
              fileId={fileId}
              isDeleting={isDeleting}
              onCancel={this.onCancel}
              onConfirm={this.onConfirm}/>
          )}
          {isFilesModalOpen && (
            <LinkingPortfolioModal
              isBadge={isBadge}
              isOpen={isFilesModalOpen}
              bakedBadgePage
              linkingSkill={skillData?.id && true}
              onLinkedSkill={onLinkedSkill}/>
          )}
          {isEndorseRequestModalOpen && <EndorsementModal />}
        </div>
        <UnitBadgeEndorsements
          user={user}
          isBadgeOwner={isBadgeOwner}
          onClickEndorse={onClickEndorse}
          onToggleEndorseRequestModal={this.onToggleEndorseRequestModal}
          lastEndorsementUploadedDate={lastEndorsementUploadedDate}
          endorsementCount={endorsementCount}
          endorsements={endorsements}
          skillEvidenceView={skillEvidenceView}
          issuedBadgeId={issuedBadgeId}/>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, badgeAssertion, components, skills: { unitSkill } } = state;

  return {
    unitSkill,
    badgeAssertion,
    modal: components.modal,
    currentUser: auth.data.currentUser
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, skillsActions, componentActions, badgesActions, badgeAssertionActions);
  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(UnitBadgeEvidence));
