import { CALL_API } from '../../middlewares/api';

import {
  QUESTIONAIRES_REQUEST,
  QUESTIONAIRES_SUCCESS,
  QUESTIONAIRES_FAILURE,
} from '../../constants';

export const questionnairesRequest = () => {
  const endpoint = 'skillbuilder/questionnaires';

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        QUESTIONAIRES_REQUEST,
        QUESTIONAIRES_SUCCESS,
        QUESTIONAIRES_FAILURE,
      ],
    },
  };
};
