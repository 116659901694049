import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';
import moment from 'moment';
import { Button } from 'reactstrap';
import { get } from 'lodash';

import RequestTestimonialModal from './RequestTestimonialModal';
import ShowTestimonialModal from './ShowTestimonialModal';
import * as testimonialsActions from '../../redux/actions/testimonials';
import { SETTINGS_DATE_FORMAT } from '../../constants';

import ShowMoreText from 'react-show-more-text';

const Testimonials = (props) => {
  const [page, setPage] = useState(1);
  const [jsonFlag, setFlag] = useState({
    revision: false,
    showTestimonial: false,
  });
  const [testimonialData, setTestimonial] = useState({});
  const [showAllTestimonials, setShowAllTestimonials] = useState(false);
  const [testimonialDisplayState, setTestimonialDisplayState] = useState('none');

  useEffect(() => {
    const resourceId = props.issued && props.issued.id ?
      props.issued && props.issued.id :
      props.skill_id;

    props
      .actions
      .getAllTestimonialsFromBadgeId(
        resourceId,
        page,
        props?.userDetails?.id,
        props.isAuthenticated
      );
  },
  [
    page,
    props.actions,
    props.issued,
    props.issued?.id,
    props.skill_id,
    props.userDetails,
    props.isAuthenticated
  ]);

  function loadMore() {
    setPage(page + 1);
  }

  function toggleModal(modal) {
    let arr = [
      'revision',
      'showTestimonial',
    ];

    const d = {};
    arr.forEach((a) => {
      if (a === modal) {
        d[a] = [modal];
      } else {
        d[a] = false;
      }
    });

    setFlag({...jsonFlag, ...d});
  }

  function openRevisionModal(event){
    const testimonial = event.target.getAttribute('data-value');

    setTestimonial(JSON.parse(testimonial));
    setFlag({...jsonFlag, showTestimonial: false, revision: true});
  }

  function visibleTestimonial(event){
    const testimonial = event.target.getAttribute('data-value');

    setTestimonial(JSON.parse(testimonial));
    setFlag({...jsonFlag, showTestimonial: true, revision: false});
  }

  function setVisibility(event){
    const testimonial = event.target.getAttribute('data-value');

    confirmVisibility(JSON.parse(testimonial));
  }

  async function confirmVisibility(testimonial){
    await props.actions
      .editTestimonialVisibility(
        {
          visibility: testimonial.visibility ? 0 : 1,
        },
        testimonial.id
      );

    toggleModal(null);

    if (props.isAuthenticated) {
      const resourceId = props.issued?.id || props.skill_id;

      props
        .actions
        .getAllTestimonialsFromBadgeId(resourceId, page, props?.userDetails?.id, props.isAuthenticated);
    }
  }
  const [expand, setExpand] = useState(false);
  function onClick() {
    setExpand(!expand);
  }

  function toggleShowMoreTestimonials () {
    setShowAllTestimonials(!showAllTestimonials);
    (testimonialDisplayState == 'none') ? setTestimonialDisplayState('block') : setTestimonialDisplayState('none');
  }

  return (
    <div className="section-badge">
      <h6>Testimonials {props.skillEvidenceView && (`(${props.testimonials.data ? props.testimonials.data.length : 0})`)} </h6>

      <div className="section-badge-content pt-0">
        <div className="row">
          <div className="col-md-12">
            {!props.testimonials?.data?.length && (
              <div>
                <p className="pt-3 no-badge-content">
                  No Testimonials Yet!
                </p>
              </div>
            )}
            {props.testimonials?.data?.length > 0 &&
        props.testimonials.data?.map((testimonial, index) => {
          let displayValue = 'display';
          if(props.skillEvidenceView) {
            displayValue = index < 2 && !showAllTestimonials ? 'block' : testimonialDisplayState;
          }
          return (
            <div key={index} className="testimonials-content" style={{display: displayValue}}>
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12 d-flex flex-column justify-content-between mt-4 mt-md-0">
                  <div className="d-flex">
                    <div className="mr-2"><img className="testimonial-avatar" src={testimonial.avatar || require('../../assets/images/avatar.jpg')} /></div>
                    <div>
                      <span className="font-weight-bold d-block">
                        {testimonial.giverName}
                      </span>
                      <span className="d-block font12">{testimonial.jobTitle}</span>
                      <span className="font12">
                        {moment(testimonial.createdAt.date, SETTINGS_DATE_FORMAT).format(
                          'MM/DD/YYYY'
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-12 col-sm-12 mt-3 mt-lg-0 mt-md-0 mt-md-3 font14">
                  <div className="row align-items-center">
                    <div className={`${props.isOwner ? props.skillEvidenceView ? 'col-md-12 col-lg-6' : 'col-md-12 col-lg-5' : 'col-md-12 col-lg-12'}`}>
                      <ShowMoreText
                        lines={1}
                        more={'See More'}
                        less={'See Less'}
                        anchorClass={'d-block font14'}
                        truncatedEndingComponent={' '}
                        onClick={onClick}
                        expanded={expand}
                        width={480}>
                        {testimonial.testimony}
                      </ShowMoreText>
                    </div>
                    {
                      props.isOwner && <>
                        <div className={` font13 d-flex align-items-lg-end justify-content-start flex-column ${props.skillEvidenceView ? 'col-md-6 col-lg-3' : 'col'}`}>
                          <div className="showhideIcon">
                            <span>
                              {!testimonial.visibility ? 'This testimonial is hidden.' :
                                'This testimonial is visible.'}</span>
                            {!testimonial.visibility && <span
                              data-value={JSON.stringify(testimonial)}
                              onClick={localStorage.getItem([get(testimonial,'id','').concat('_show_testimonial')])
                                ? setVisibility
                                : visibleTestimonial
                              }
                              className="pointer orange">
                              <i className="fas fa-eye-slash" /> Show Testimonial
                            </span>}
                            {testimonial.visibility && <span
                              data-value={JSON.stringify(testimonial)}
                              onClick={
                                localStorage.getItem([
                                  get(testimonial,'id','').concat('_hide_testimonial'),
                                ])
                                  ? setVisibility
                                  : visibleTestimonial
                              }
                              className="pointer orange">
                              <i className="fas fa-eye ml-0" /> Hide Testimonial
                            </span>
                            }
                          </div>
                        </div>
                        <div className={`d-flex align-items-start justify-content-md-end justify-content-lg-end ${props.skillEvidenceView ? 'col-md-6 col-lg-3' : 'col'}`}>
                          <Button data-value={JSON.stringify(testimonial)} className="popover-button" onClick={openRevisionModal}>Ask For Revision</Button>
                        </div>
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
          );
        })}
            {props.testimonials?.data?.length > 0 &&
        props.testimonials.page_count > 1 &&
        props.testimonials.page_count < page && (<a onClick={loadMore}>Show more</a>)}
            {jsonFlag.showTestimonial && <ShowTestimonialModal
              toggleModal={toggleModal}
              confirmVisibility={confirmVisibility}
              testimonial={testimonialData}
              isOpen={jsonFlag.showTestimonial}
              isBadge={props.isBadge}/>}

            {jsonFlag.revision && <RequestTestimonialModal
              isOpen={jsonFlag.revision}
              toggleModal={toggleModal}
              testimonialData={testimonialData}/>}
            {props.skillEvidenceView && (props.testimonials?.data?.length - 2 > 0) && (<div className="section-showmorelink my-3" onClick={toggleShowMoreTestimonials}>Show ({props.testimonials?.data?.length - 2}) {showAllTestimonials ? 'less' : 'more'} testimonials</div>)
            }
          </div>
        </div>
      </div>
    </div>
  );
};

Testimonials.propTypes = {
  actions: PropTypes.object.isRequired,
  badgeId: PropTypes.string,
  issued: PropTypes.object,
  userDetails: PropTypes.object,
  testimonials: PropTypes.object,
  skill_id: PropTypes.string,
  receiverId: PropTypes.string,
  skillEvidenceView: PropTypes.bool,
  isOwner: PropTypes.bool,
  isBadge: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { auth, testimonials } = state;

  return {
    currentUser: auth.data.currentUser,
    testimonials,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, testimonialsActions);
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Testimonials);
