import React from 'react';

import PropTypes from 'prop-types';
import Linkify from 'react-linkify';
import { Link } from 'react-router-dom';

import VideoIframe from './VideoIframe';
import ImagePost from './ImagePost';
import ImageRenderer from './ImageRenderer';
import LinkPreview from './LinkPreview';
import CpHtmlParser from '../common/CpHtmlParser';

import { parseUrlString } from '../../utils';

import classNames from 'classnames';
import { pure } from 'recompose';

import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';


const CommentItem = ({
  wallPost,
  showMore,
  onshowMore,
  secondary,
}) => {
  const {
    id,
    post: postString ,
    user,
    attachment,
    comment,
    linkPreview
  } = wallPost;
  const { condition, id: showMoreId} = showMore;
  const post = secondary ? comment : postString;
  const postIsNotNill = !isNil(post);

  const parsedString = postIsNotNill && parseUrlString(post);
  const showMoreCondition = postIsNotNill && post.replace(/<[^>]+>/g, '').length > 400;
  const showFullPostText = condition && showMoreId === id;
  const lessComponentCondition = showMoreCondition && !showFullPostText;

  const wrapperClass = classNames({
    'profile-name': secondary,
    'post-content': !secondary
  });


  return (
    <div className={wrapperClass} style={{ whiteSpace: 'normal' }}>
      {
        secondary &&
          <span style={{paddingRight:5}} className="post-user-name">
            <Link
              to={`/cp/${user.vanity}`}
              style={{fontWeight: '500'}}
              className="user-name">
              {user.name}
            </Link>
          </span>
      }

      {
        postIsNotNill && lessComponentCondition &&
          <Linkify><CpHtmlParser htmlString={post.slice(0, 400)}/></Linkify>
      }

      {
        postIsNotNill && !lessComponentCondition &&
          <Linkify><CpHtmlParser htmlString={post}/></Linkify>
      }

      {
        showMoreCondition &&
          <span
            onClick={onshowMore(id)}
            className="show-more-btn">
            {lessComponentCondition ? '... Show more' : 'Show less'}
          </span>
      }

      {(!isEmpty(linkPreview)) && <LinkPreview linkPreview={linkPreview}/>}

      {(!isNil(parsedString) && parsedString.type === 'iframe' && attachment === '')
        && <VideoIframe url={parsedString.url} secondary={secondary}/>}

      {(!isNil(parsedString) && parsedString.type === 'image' && attachment === '')
        && <ImagePost url={parsedString.url} secondary={secondary}/>}

      {attachment && <ImageRenderer attachment={attachment} secondary={secondary}/>}
    </div>
  );
};

CommentItem.propTypes = {
  showMore: PropTypes.object,
  onshowMore: PropTypes.func,
  wallPost: PropTypes.object.isRequired,
  secondary: PropTypes.bool,
};

export default pure(CommentItem);
