import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loadForm, guid } from '../../utils';

import * as componentActions from '../../redux/actions/components';

import SkillSectionCard from '../../components/resume/sections/SkillSectionCard';
import formWithModal from '../../components/profile/modal/formWithModal';
import AddSkillForm from '../../components/resume/forms/AddSkillForm';
import SkillsEditModal from './SkillsEditModal';

import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

import _map from 'lodash/map';
import _pick from 'lodash/pick';
import _partialRight from 'lodash/partialRight';

class ResumeSkills extends Component {
  static propTypes = {
    skills: PropTypes.array,
    onChangeSection: PropTypes.func.isRequired,
    onChangeSectionTitle: PropTypes.func.isRequired,
    onUnloadForm: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    resumeSkills: PropTypes.object.isRequired,
    onLoadForm: PropTypes.func.isRequired,
    forms: PropTypes.array,
    modal: PropTypes.string
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      error: '',
      editingId: null,
      deleteSkillId: null,
      skills: this.props.skills,
    };

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onEditSkill = this.onEditSkill.bind(this);
    this.onRemoveSkill = this.onRemoveSkill.bind(this);
    this.onCancelRemoveSkill = this.onCancelRemoveSkill.bind(this);
    this.onConfirmRemoveSkill = this.onConfirmRemoveSkill.bind(this);

    this.onEditSkillsClick = this.onEditSkillsClick.bind(this);

    this.skillsHeader = null;
    this.setSkillsHeaderRef = (element) => {
      this.skillsHeader = element;
    };
  }

  componentWillReceiveProps(nextProps) {
    const isAddFormOpen = loadForm(nextProps.forms, 'addSkillsForm');

    if (isAddFormOpen) {
      const elementBoundingRect = this.skillsHeader.getBoundingClientRect();
      const bodyBoundingRect = document.body.getBoundingClientRect();
      const offset = elementBoundingRect.top - bodyBoundingRect.top;

      window.scrollTo(0, offset);
    }
  }

  onChangeTitle(event) {
    const { value } = event.target;
    this.props.onChangeSectionTitle(value, 'skills');
  }

  handleChange(value) {
    const formattedValue = _map(value, (item) => {
      if (item.value.includes('Create option ')) {
        const splittedValue = item.value.split('Create option ');

        return Object.assign({}, item, {
          value: splittedValue[1].replace(/"/g, ''),
          label: splittedValue[1].replace(/"/g, '')
        });
      }

      return item;
    });

    if (this.state.skills.length !== 0) {
      this.setState({ error: '' });
    }

    this.setState({ skills: formattedValue });
  }

  onSave() {
    const { skills } = this.state;
    const { resumeSkills } = this.props;

    if (skills.length === 0) {
      return this.setState({ error: 'Required' });
    }

    const newSkills = _map(skills, (skill) => {
      return {
        id: guid(),
        name: skill.value,
        ...skill
      };
    });

    const newSkillsOrder = _map(newSkills, _partialRight(_pick, ['id']));

    const skillsOrder = [
      ...resumeSkills.skillsOrder,
      ...newSkillsOrder
    ];

    const skillsArray = [
      ...resumeSkills.skillsArray,
      ...newSkills
    ];

    const data = Object.assign({}, resumeSkills, { skillsArray, skillsOrder });

    this.props.onChangeSection(data, 'skills');
    this.onCancel();
  }

  onCancel() {
    const { unloadForm } = this.props.actions;

    this.clearFormField();
    unloadForm('addSkillsForm');
  }

  clearFormField() {
    this.setState({
      skills: [],
      error: '',
      editingId: null
    });
  }

  onEditSkillsClick() {
    this.props.actions.openModal('resume-skills-edit-modal');
  }

  onEditSkill(event){
    const { id } = event.target;
    const { loadForm } = this.props.actions;
    const { skillsArray } = this.props.resumeSkills;
    const skillObject = skillsArray.find(skill => skill.id === id);
    const skills = Object.assign({}, this.state.skills, skillObject);

    this.setState({
      skills,
      editingId: id
    }, ()=> loadForm('editSkillsForm'));
  }

  onRemoveSkill(event) {
    const { id: deleteSkillId } = event.target;

    this.setState({ deleteSkillId });
  }

  onCancelRemoveSkill() {
    this.setState({ deleteSkillId: null });
  }

  onConfirmRemoveSkill() {
    const { deleteSkillId } = this.state;

    const { resumeSkills } = this.props;
    const skillsArray = resumeSkills.skillsArray.filter(skill => skill.id !== deleteSkillId);

    const data = Object.assign({}, resumeSkills, { skillsArray});

    this.props.onChangeSection(data, 'skills');
    this.setState({ deleteSkillId: null });
  }

  EditingSkills= formWithModal(AddSkillForm);

  render() {
    const { skills, error, deleteSkillId } = this.state;
    const { resumeSkills, onLoadForm, forms, modal, onChangeSection } = this.props;

    const isAddFormOpen = loadForm(forms, 'addSkillsForm');
    const isEditModalOpen = modal && (modal === 'resume-skills-edit-modal');

    const sectionComponent = (
      <div className="resume-section create-resume-section-card">
        <div className="create-resume-title">
          <input
            type="text"
            value={resumeSkills.title}
            placeholder="Skills header"
            className="header-edit-textbox"
            onChange={this.onChangeTitle}
            ref={this.setSkillsHeaderRef}/>

          {!(resumeSkills.skillsArray.length === 0) &&
            <span className="pt-1 pb-1 pl-2 pr-2 clickable">
              <i
                className="fa fa-pencil"
                style={{cursor: 'pointer'}}
                onClick={this.onEditSkillsClick}/>
            </span>}
        </div>

        {!isAddFormOpen &&
          <div className="section-list-container">
            {_map(resumeSkills.skillsOrder, (order) => {
              const skill = resumeSkills.skillsArray.find(item => item.id === order.id);

              if (! skill) return null;

              return (
                <SkillSectionCard
                  key={skill.id}
                  skillDetails={skill}
                  onEditSkill={this.onEditSkill}
                  onRemoveSkill={this.onRemoveSkill}/>
              );
            })}

            <button
              onClick={onLoadForm}
              data-form-name="addSkillsForm"
              className="btn btn-primary w-100">
              Add Skills
            </button>
          </div>}

        {isAddFormOpen &&
          <div className="resume-form-container">
            <this.EditingSkills
              error={error}
              skills={skills}
              onSave={this.onSave}
              onCancel={this.onCancel}
              onChange={this.handleChange}
              exampleType={'Resume Skills'} />
          </div>}

        {isEditModalOpen &&
          (<SkillsEditModal
            isOpen={isEditModalOpen}
            resumeSkills={resumeSkills}
            onChangeSection={onChangeSection}/>)}

        <Modal
          size="lg"
          className="modal-margin-top"
          backdrop="static"
          isOpen={(deleteSkillId !== null)}
          toggle={this.onCancelRemoveSkill}>
          <ModalHeader
            toggle={this.onCancelRemoveSkill}>Delete Skill</ModalHeader>
          <ModalBody>
            <p>Are you sure you want to remove this skill from your resume?</p>
          </ModalBody>
          <ModalFooter>
            <button
              onClick={this.onCancelRemoveSkill}
              className="btn btn-secondary">
              Cancel
            </button>

            <button
              onClick={this.onConfirmRemoveSkill}
              className="btn btn-primary">
              Yes, Remove
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );

    return sectionComponent;
  }
}

const mapStateToProps = (state) => {
  const skills = [];
  const { forms, modal } = state.components;

  return {
    skills,
    forms,
    modal
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(componentActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResumeSkills);
