import PropTypes from 'prop-types';
import React from 'react';

const EmptyMessages = ({ onComposeNew, userCount }) => {
  return (
    <section id="messaging">
      <div className="container">
        <div className="messagingContainer">
          <div className="search-hint row no-badges-claimed-hint mt-2">
            <div className="col-sm-12">
              <span className="fa fa-info-circle" aria-hidden="true"/>
              <h5 className="mb-0">No messages yet</h5>
              {userCount === 0 ?
                <p>You can compose messages to followers or people you follow. Follow others to start composing messages.</p>:
                <span className="clickable link orange" onClick={onComposeNew}>
                  <i style={{marginRight: 5}} className="fas fa-pencil-alt"/>&nbsp;Compose
                </span>}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

EmptyMessages.propTypes = {
  userCount: PropTypes.number,
  onComposeNew: PropTypes.func
};

export default EmptyMessages;
