import PropTypes from 'prop-types';
import React from 'react';
import ReplyList from './ReplyList';
import { pure } from 'recompose';
import _isEmpty from 'lodash/isEmpty';

const Reply = ({ replies, dashboard, searchPage }) => {
  if(_isEmpty(replies))
    return null;

  return(
    <ReplyList 
      searchPage={searchPage}
      replies={replies} 
      dashboard={dashboard}/>
  );
};

Reply.propTypes = {
  replies: PropTypes.array,
  searchPage: PropTypes.bool,
  dashboard: PropTypes.bool
};

export default pure(Reply);
