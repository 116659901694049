import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import _find from 'lodash/find';
import _isUndefined from 'lodash/isUndefined';

import DashboardForumForm from './DashboardForumForm';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { pure } from 'recompose'; 

const visibilityOptionsArray = [
  {
    title:'Everyone (Public)',
    value:'1',
    icon:'fa fa-globe'
  },
  {
    title:'Followers Only',
    value:'2',
    icon:'fa fa-user-circle-o'
  },
  {
    title:'Only Me',
    value:'0',
    icon:'fa fa-lock'
  },
];

const DashboardPostForm = ({
  onSave,
  wallPost,
  onSelectVisibilty,
  isSubmitting,
  isUpdating,
  postVisibilityDropdown,
  togglePostVisibilityDropdown,
  onCancel,
  onCancelEdit,
  handleHTMLChange,
  imagePreviewUrl,
  onDropFile
}) => {

  const buttonText = (onCancelEdit && isSubmitting) ? 'Updating...' :
    (onCancelEdit && !isUpdating) ? 'Update' : (isSubmitting || isUpdating) ? 'Posting...': 'Post';

  const dropdownButton = _find(visibilityOptionsArray, option =>
    option.value == wallPost.permissions);

  const visibilityDropdownShowCondition = (
    !_isUndefined(postVisibilityDropdown) &&
      !_isUndefined(togglePostVisibilityDropdown)
  );

  const bottomDivClass = classNames({
    'bottom': visibilityDropdownShowCondition,
    'bottom text-right': !visibilityDropdownShowCondition
  });

  const buttonClass = classNames('btn', 'btn-primary', {
    'float-right': visibilityDropdownShowCondition
  });

  return (
    <div className="forum-enter">
      <div className="top">
        <div className="form-inline">
          <DashboardForumForm
            type="text"
            name="post"
            value={wallPost.post}
            onCancel={onCancel}
            onCancelEdit={onCancelEdit}
            imagePreviewUrl={imagePreviewUrl}
            onDropFile={onDropFile}
            handleHTMLChange={handleHTMLChange}/>
        </div>
      </div>

      <div className={bottomDivClass}>
        {
          onCancelEdit &&
            <button
              type="button"
              name="button"
              className="btn btn-secondary"
              disabled={isSubmitting || isUpdating}
              onClick={onCancelEdit}>
              Cancel
            </button>
        }
        {visibilityDropdownShowCondition &&
          <Dropdown
            className="dropdown"
            isOpen={postVisibilityDropdown}
            toggle={togglePostVisibilityDropdown}>
            <DropdownToggle
              caret
              color="tertiary"
              className="btn btn-tertiary activity-filter-button">
              {dropdownButton == null ?
                'Who can see this post' :
                <span><i className={dropdownButton.icon}/> {dropdownButton.title}</span>}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu">
              {visibilityOptionsArray.map((option,index) =>
                (<a className="dropdown-item" key={index} onClick={onSelectVisibilty(option.value)}>
                  <i className={option.icon}/> {option.title}
                </a>))}
            </DropdownMenu>          
          </Dropdown>
        }
        <button
          style={{marginLeft:8}}
          type="submit"
          className={buttonClass}
          onClick={onSave}
          disabled={isSubmitting || isUpdating}>
          {buttonText}
        </button>
      </div>
    </div>
  );
};

DashboardPostForm.propTypes = {
  handleHTMLChange: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  wallPost: PropTypes.object.isRequired,
  onSelectVisibilty: PropTypes.func,
  togglePostVisibilityDropdown: PropTypes.func,
  postVisibilityDropdown: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isUpdating: PropTypes.bool,
  onCancel: PropTypes.func,
  onCancelEdit: PropTypes.func,
  imagePreviewUrl: PropTypes.string,
  onDropFile: PropTypes.func
};

export default pure(DashboardPostForm);
