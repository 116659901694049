import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { generateBaseRequestUrl } from '../../utils';

import { getUsers, resetFilterParams } from '../../redux/actions/users';
import {
  getGroupAdmins,
  resetGroupAdmins,
  setSelectedParentGroup,
  getUserAdminGroups
} from '../../redux/actions/groups';

import classNames from 'classnames';
import { Collapse } from 'reactstrap';

// import GroupsSearch from './GroupsSearch';

class ParentGroupDropdown extends Component {
  static propTypes = {
    page: PropTypes.number.isRequired,
    groupsPage: PropTypes.number.isRequired,
    parentPage: PropTypes.string.isRequired,
    groupsPageCount: PropTypes.number.isRequired,
    actions: PropTypes.object.isRequired,
    selectedGroup: PropTypes.object.isRequired,
    userAdminGroups: PropTypes.array.isRequired,
    institutionId: PropTypes.string,
    isRequesting: PropTypes.bool.isRequired,
    institutionUsersCount: PropTypes.string
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      keyword: props.selectedGroup.name
    };

    this.onLoadMore = this.onLoadMore.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.onSelectGroup = this.onSelectGroup.bind(this);
  }

  handleToggle() {
    const { isOpen } = this.state;

    this.setState({ isOpen : ! isOpen });
  }

  onLoadMore(event) {
    event.preventDefault();

    const {
      actions,
      groupsPage,
      groupsPageCount,
      institutionId
    } = this.props;

    if (groupsPage === groupsPageCount) return;

    const urlParamString = `&page=${groupsPage + 1}`;

    actions.getUserAdminGroups(institutionId, urlParamString);
  }

  onSelectGroup(event) {
    event.preventDefault();

    const groupId = event.target.closest('a').id;
    const { actions, userAdminGroups } = this.props;

    const selectedParentGroup = userAdminGroups.find(group => group.id === groupId);

    actions.resetFilterParams();
    actions.setSelectedParentGroup(selectedParentGroup);

    this.fetchUsers(groupId);
    this.fetchGroupAdmins(groupId);
    this.setState({ isOpen: false });
  }

  fetchUsers(groupId) {
    const { actions, parentPage } = this.props;

    if (parentPage !== 'admin-dashboard') {
      return;
    }

    const urlProps = { ...this.props, groupId, page: 1 };
    const urlParamString = generateBaseRequestUrl(urlProps);

    actions.getUsers(urlParamString);
  }

  fetchGroupAdmins(groupId) {
    const { actions, parentPage } = this.props;

    if (parentPage !== 'manage-admins') {
      return;
    }

    if (groupId === 'all-groups') {
      actions.resetGroupAdmins();
    }

    if (groupId !== 'all-groups') {
      actions.getGroupAdmins(groupId);
    }
  }

  render() {
    const { isOpen } = this.state;
    const {
      groupsPage,
      isRequesting,
      selectedGroup,
      groupsPageCount,
      userAdminGroups,
      institutionUsersCount
    } = this.props;

    const hasMoreGroups = (groupsPageCount > groupsPage);

    return (
      <div style={{marginBottom: 24}}>
        <div className="admin-list-header" onClick={this.handleToggle}>
          {`${selectedGroup.name}`}
          <i className={classNames('fa float-right', {
            'fa-chevron-down': !isOpen,
            'fa-chevron-up': isOpen
          })}/>
        </div>

        <Collapse isOpen={isOpen}>
          <div style={{marginTop: 8, boxShadow: '1px 1px 5px rgba(0,0,0,.05)', backgroundColor:'white', padding: 16, borderRadius: 4 }}>
            <div className="row">
              <div className="col-lg-12">
                {/* <GroupsSearch/> */}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="admin-item">
                  <div className="admin-group-list">
                    {userAdminGroups.map(group => (
                      <a
                        href="#"
                        key={group.id}
                        className="item"
                        id={group.id}
                        onClick={this.onSelectGroup}>
                        <div>
                          <span
                            className="img-responsive initial-avatar group">
                            {group.name.slice(0, 1)}
                          </span>
                        </div>
                        <div>
                          <div>
                            {`${group.name}`}
                          </div>
                          <div style={{
                            fontSize: 12
                          }}>
                            {`${
                              (group.id === 'all-groups') ?
                                institutionUsersCount.toLocaleString() :
                                group.groupUsersCount.toLocaleString()
                            } Members`}
                          </div>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="admins-more">
              {! isRequesting && hasMoreGroups &&
                <a href="#" onClick={this.onLoadMore}>Show More</a>}

              {(! isRequesting) && (! hasMoreGroups) &&
                <span>No more groups</span>}

              {isRequesting &&
                <span>
                  <i className="fa fa-spinner fa-pulse fa-lg fa-fw" />
                  Loading...
                </span>}
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    users: { page },
    institutions: {
      selectedInstitution: {
        id: institutionId,
        usersCount: institutionUsersCount
      }
    },
    groups: {
      page: groupsPage,
      pageCount: groupsPageCount,
      selectedGroup,
      isRequesting,
      data: userAdminGroups,
      isAuthUserSelectedInstitutionAdmin
    }
  } = state;

  return {
    page,
    groupsPage,
    institutionUsersCount,
    groupsPageCount,
    selectedGroup,
    userAdminGroups,
    institutionId,
    isRequesting,
    isAuthUserSelectedInstitutionAdmin
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    getUsers,
    getGroupAdmins,
    resetGroupAdmins,
    resetFilterParams,
    setSelectedParentGroup,

    getUserAdminGroups
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ParentGroupDropdown);
