import initialState from './initialState';

import {
  INTERVIEW_PREP_VIDEOS_REQUEST,
  INTERVIEW_PREP_VIDEOS_SUCCESS,
  INTERVIEW_PREP_VIDEOS_FAILURE,
  UNIT_INTERVIEW_PREP_VIDEO_REQUEST,
  UNIT_INTERVIEW_PREP_VIDEO_SUCCESS,
  UNIT_INTERVIEW_PREP_VIDEO_FAILURE,
  UNIT_VIDEO_COMMENTS_REQUEST,
  UNIT_VIDEO_COMMENTS_SUCCESS,
  UNIT_VIDEO_COMMENTS_FAILURE,
  LIKE_VIDEO_REQUEST,
  LIKE_VIDEO_SUCCESS,
  LIKE_VIDEO_FAILURE,
  UNLIKE_VIDEO_REQUEST,
  UNLIKE_VIDEO_SUCCESS,
  UNLIKE_VIDEO_FAILURE,
  ADD_VIDEO_COMMENT_REQUEST,
  ADD_VIDEO_COMMENT_SUCCESS,
  ADD_VIDEO_COMMENT_FAILURE,
  ADD_VIDEO_COMMENT_REPLY_REQUEST,
  ADD_VIDEO_COMMENT_REPLY_SUCCESS,
  ADD_VIDEO_COMMENT_REPLY_FAILURE,
  LIKE_VIDEO_COMMENT_REQUEST,
  LIKE_VIDEO_COMMENT_SUCCESS,
  LIKE_VIDEO_COMMENT_FAILURE,
  UNLIKE_VIDEO_COMMENT_REQUEST,
  UNLIKE_VIDEO_COMMENT_SUCCESS,
  UNLIKE_VIDEO_COMMENT_FAILURE,
  LIKE_VIDEO_COMMENT_REPLY_REQUEST,
  LIKE_VIDEO_COMMENT_REPLY_SUCCESS,
  LIKE_VIDEO_COMMENT_REPLY_FAILURE,
  UNLIKE_VIDEO_COMMENT_REPLY_REQUEST,
  UNLIKE_VIDEO_COMMENT_REPLY_SUCCESS,
  UNLIKE_VIDEO_COMMENT_REPLY_FAILURE,
  DELETE_VIDEO_COMMENT_REQUEST,
  DELETE_VIDEO_COMMENT_SUCCESS,
  DELETE_VIDEO_COMMENT_FAILURE,
  DELETE_VIDEO_COMMENT_REPLY_REQUEST,
  DELETE_VIDEO_COMMENT_REPLY_SUCCESS,
  DELETE_VIDEO_COMMENT_REPLY_FAILURE,
  UPDATE_VIDEO_COMMENT_REQUEST,
  UPDATE_VIDEO_COMMENT_SUCCESS,
  UPDATE_VIDEO_COMMENT_FAILURE,
  UPDATE_VIDEO_COMMENT_REPLY_REQUEST,
  UPDATE_VIDEO_COMMENT_REPLY_SUCCESS,
  UPDATE_VIDEO_COMMENT_REPLY_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.interview, action) {
  switch (action.type) {
  case INTERVIEW_PREP_VIDEOS_REQUEST:
    return Object.assign({}, state, {
      isRequesting: true,
    });

  case INTERVIEW_PREP_VIDEOS_SUCCESS:
    return Object.assign({}, state, {
      data: action.data._embedded.resources,
      isRequesting: false,
    });

  case INTERVIEW_PREP_VIDEOS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case UNIT_INTERVIEW_PREP_VIDEO_REQUEST:
    return Object.assign({}, state, {
      unitVideo: Object.assign({}, state.unitVideo, {
        isRequestingDetails: true,
      }),
    });

  case UNIT_INTERVIEW_PREP_VIDEO_SUCCESS:
    return Object.assign({}, state, {
      unitVideo: Object.assign({}, state.unitVideo, {
        isRequestingDetails: false,
        videoDetails: Object.assign({}, action.data),
      }),
    });

  case UNIT_INTERVIEW_PREP_VIDEO_FAILURE:
    return Object.assign({}, state, {
      unitVideo: Object.assign({}, state.unitVideo, {
        isRequestingDetails: false,
        error: action.error,
      }),
    });

  case UNIT_VIDEO_COMMENTS_REQUEST:
    return Object.assign({}, state, {
      unitVideoComments: Object.assign({}, state.unitVideoComments, {
        isRequesting: true,
      }),
    });

  case UNIT_VIDEO_COMMENTS_SUCCESS:
    return Object.assign({}, state, {
      unitVideoComments: Object.assign({}, state.unitVideoComments, {
        isRequesting: false,
        comments: action.data._embedded.comments,
        commentCount: action.data.total_items,
      }),
    });

  case UNIT_VIDEO_COMMENTS_FAILURE:
    return Object.assign({}, state, {
      unitVideoComments: Object.assign({}, state.unitVideoComments, {
        isRequesting: false,
        error: action.error,
      }),
    });

  case LIKE_VIDEO_REQUEST:
  case UNLIKE_VIDEO_REQUEST:
    return Object.assign({}, state, {
      unitVideo: Object.assign({}, state.unitVideo, {
        isLiking: {
          status: true,
          id: action.id,
        },
      }),
    });

  case LIKE_VIDEO_SUCCESS: {
    const { data, unitVideo } = state;
    const { isLiking, videoDetails } = unitVideo;

    const index = data.findIndex(item => item.id === isLiking.id);
    const updatedObject = Object.assign({}, data[index], {
      liked: true,
      like_count: data[index].like_count + 1,
    });

    const isInsideVideoDetailsPage =
        Object.keys(videoDetails).length > 0 && videoDetails.id === isLiking.id;

    return Object.assign({}, state, {
      data: [
        ...data.slice(0, index),
        updatedObject,
        ...data.slice(index + 1),
      ],
      unitVideo: isInsideVideoDetailsPage
        ? Object.assign({}, unitVideo, {
          isLiking: {
            status: false,
            id: null,
          },
          videoDetails: Object.assign({}, videoDetails, {
            liked: true,
            like_count: videoDetails.like_count + 1,
          }),
        })
        : Object.assign({}, unitVideo, {
          isLiking: {
            status: false,
            id: null,
          },
        }),
    });
  }

  case UNLIKE_VIDEO_SUCCESS: {
    const { data, unitVideo } = state;
    const { isLiking, videoDetails } = unitVideo;

    const index = data.findIndex(item => item.id === isLiking.id);
    const updatedObject = Object.assign({}, data[index], {
      liked: false,
      like_count: data[index].like_count - 1,
    });

    const isInsideVideoDetailsPage =
        Object.keys(videoDetails).length > 0 && videoDetails.id === isLiking.id;

    return Object.assign({}, state, {
      data: [
        ...data.slice(0, index),
        updatedObject,
        ...data.slice(index + 1),
      ],
      unitVideo: isInsideVideoDetailsPage
        ? Object.assign({}, unitVideo, {
          isLiking: {
            status: false,
            id: null,
          },
          videoDetails: Object.assign({}, videoDetails, {
            liked: false,
            like_count: videoDetails.like_count - 1,
          }),
        })
        : Object.assign({}, unitVideo, {
          isLiking: {
            status: false,
            id: null,
          },
        }),
    });
  }

  case LIKE_VIDEO_FAILURE:
  case UNLIKE_VIDEO_FAILURE:
    return Object.assign({}, state, {
      unitVideo: Object.assign({}, state.unitVideo, {
        isLiking: {
          status: false,
          id: null,
        },
        error: action.error,
      }),
    });

  case ADD_VIDEO_COMMENT_REQUEST:
    return Object.assign({}, state, {
      unitVideoComments: Object.assign({}, state.unitVideoComments, {
        isSubmitting: { status: true, commentId: null },
      }),
    });

  case ADD_VIDEO_COMMENT_SUCCESS: {
    const { unitVideoComments } = state;
    return Object.assign({}, state, {
      unitVideo: Object.assign({}, state.unitVideo, {
        videoDetails: Object.assign({}, state.unitVideo.videoDetails, {
          comment_count: state.unitVideo.videoDetails.comment_count + 1,
        }),
      }),
      unitVideoComments: Object.assign({}, unitVideoComments, {
        isSubmitting: { status: false, commentId: null },
        commentCount: unitVideoComments.commentCount + 1,
        comments: [...unitVideoComments.comments, action.data],
      }),
    });
  }

  case ADD_VIDEO_COMMENT_FAILURE:
    return Object.assign({}, state, {
      unitVideoComments: Object.assign({}, state.unitVideoComments, {
        isSubmitting: { status: false, commentId: null },
        error: action.error,
      }),
    });

  case LIKE_VIDEO_COMMENT_REQUEST:
  case UNLIKE_VIDEO_COMMENT_REQUEST:
    return Object.assign({}, state, {
      unitVideoComments: Object.assign({}, state.unitVideoComments, {
        isLiking: {
          status: true,
          id: action.id,
        },
      }),
    });

  case LIKE_VIDEO_COMMENT_SUCCESS: {
    const index = state.unitVideoComments.comments.findIndex(
      item => item.id === state.unitVideoComments.isLiking.id
    );
    const updatedObject = Object.assign(
      {},
      state.unitVideoComments.comments[index],
      {
        liked: true,
        likes: state.unitVideoComments.comments[index].likes + 1,
      }
    );

    return Object.assign({}, state, {
      unitVideoComments: Object.assign({}, state.unitVideoComments, {
        comments: [
          ...state.unitVideoComments.comments.slice(0, index),
          updatedObject,
          ...state.unitVideoComments.comments.slice(index + 1),
        ],
        isLiking: { status: false, id: null },
      }),
    });
  }

  case UNLIKE_VIDEO_COMMENT_SUCCESS: {
    const index = state.unitVideoComments.comments.findIndex(
      item => item.id === state.unitVideoComments.isLiking.id
    );
    const updatedObject = Object.assign(
      {},
      state.unitVideoComments.comments[index],
      {
        liked: false,
        likes: state.unitVideoComments.comments[index].likes - 1,
      }
    );

    return Object.assign({}, state, {
      unitVideoComments: Object.assign({}, state.unitVideoComments, {
        comments: [
          ...state.unitVideoComments.comments.slice(0, index),
          updatedObject,
          ...state.unitVideoComments.comments.slice(index + 1),
        ],
        isLiking: { status: false, id: null },
      }),
    });
  }

  case LIKE_VIDEO_COMMENT_FAILURE:
  case UNLIKE_VIDEO_COMMENT_FAILURE:
    return Object.assign({}, state, {
      unitVideoComments: Object.assign({}, state.unitVideoComments, {
        error: action.error,
        isLiking: { status: false, id: null },
      }),
    });

  case ADD_VIDEO_COMMENT_REPLY_REQUEST:
    return Object.assign({}, state, {
      unitVideoComments: Object.assign({}, state.unitVideoComments, {
        isSubmitting: { status: true, commentId: action.id },
      }),
    });

  case ADD_VIDEO_COMMENT_REPLY_SUCCESS: {
    const { unitVideoComments } = state;
    const { isSubmitting, comments } = unitVideoComments;
    const index = comments.findIndex(
      item => item.id === isSubmitting.commentId
    );
    const hasReplies = comments[index].replies;
    return Object.assign({}, state, {
      unitVideo: Object.assign({}, state.unitVideo, {
        videoDetails: Object.assign({}, state.unitVideo.videoDetails, {
          comment_count: state.unitVideo.videoDetails.comment_count + 1,
        }),
      }),
      unitVideoComments: Object.assign({}, unitVideoComments, {
        isSubmitting: { status: false, commentId: null },
        comments: [
          ...comments.slice(0, index),
          Object.assign({}, comments[index], {
            replies: hasReplies
              ? [...comments[index].replies, action.data]
              : [action.data],
          }),
          ...comments.slice(index + 1),
        ],
      }),
    });
  }

  case ADD_VIDEO_COMMENT_REPLY_FAILURE:
    return Object.assign({}, state, {
      unitVideoComments: Object.assign({}, state.unitVideoComments, {
        isSubmitting: { status: false, commentId: null },
        error: action.error,
      }),
    });

  case LIKE_VIDEO_COMMENT_REPLY_REQUEST:
  case UNLIKE_VIDEO_COMMENT_REPLY_REQUEST:
    return Object.assign({}, state, {
      unitVideoComments: Object.assign({}, state.unitVideoComments, {
        isLiking: {
          status: true,
          replyId: action.id,
        },
      }),
    });

  case LIKE_VIDEO_COMMENT_REPLY_SUCCESS: {
    const { unitVideoComments } = state;
    const { isLiking, comments } = unitVideoComments;
    const indexOfComment = comments.findIndex(item =>
      item.replies.reduce(
        (acc, curr) => (acc = curr.id === isLiking.replyId ? true : acc),
        false
      )
    );

    const replyIndex = comments[indexOfComment].replies.findIndex(
      item => item.id === isLiking.replyId
    );

    const parentComment = comments[indexOfComment];

    const updatedReplyObject = Object.assign(
      {},
      parentComment.replies[replyIndex],
      {
        liked: true,
        likes: parentComment.replies[replyIndex].likes + 1,
      }
    );

    const updatedObject = Object.assign({}, parentComment, {
      replies: [
        ...parentComment.replies.slice(0, replyIndex),
        Object.assign({}, updatedReplyObject),
        ...parentComment.replies.slice(replyIndex + 1),
      ],
    });

    return Object.assign({}, state, {
      unitVideoComments: Object.assign({}, unitVideoComments, {
        comments: [
          ...unitVideoComments.comments.slice(0, indexOfComment),
          updatedObject,
          ...unitVideoComments.comments.slice(indexOfComment + 1),
        ],
        isLiking: { status: false, replyId: null },
      }),
    });
  }

  case UNLIKE_VIDEO_COMMENT_REPLY_SUCCESS: {
    const { unitVideoComments } = state;
    const { isLiking, comments } = unitVideoComments;
    const indexOfComment = comments.findIndex(item =>
      item.replies.reduce(
        (acc, curr) => (acc = curr.id === isLiking.replyId ? true : acc),
        false
      )
    );

    const replyIndex = comments[indexOfComment].replies.findIndex(
      item => item.id === isLiking.replyId
    );

    const parentComment = comments[indexOfComment];

    const updatedReplyObject = Object.assign(
      {},
      parentComment.replies[replyIndex],
      {
        liked: false,
        likes: parentComment.replies[replyIndex].likes - 1,
      }
    );

    const updatedObject = Object.assign({}, parentComment, {
      replies: [
        ...parentComment.replies.slice(0, replyIndex),
        Object.assign({}, updatedReplyObject),
        ...parentComment.replies.slice(replyIndex + 1),
      ],
    });

    return Object.assign({}, state, {
      unitVideoComments: Object.assign({}, unitVideoComments, {
        comments: [
          ...unitVideoComments.comments.slice(0, indexOfComment),
          updatedObject,
          ...unitVideoComments.comments.slice(indexOfComment + 1),
        ],
        isLiking: { status: false, replyId: null },
      }),
    });
  }

  case LIKE_VIDEO_COMMENT_REPLY_FAILURE:
  case UNLIKE_VIDEO_COMMENT_REPLY_FAILURE:
    return Object.assign({}, state, {
      unitVideoComments: Object.assign({}, state.unitVideoComments, {
        error: action.error,
        isLiking: { status: false, replyId: null },
      }),
    });

  case DELETE_VIDEO_COMMENT_REQUEST:
    return Object.assign({}, state, {
      unitVideoComments: Object.assign({}, state.unitVideoComments, {
        isDeleting: { status: true, id: action.id },
      }),
    });

  case DELETE_VIDEO_COMMENT_SUCCESS: {
    const { unitVideoComments } = state;
    const { isDeleting, comments } = unitVideoComments;
    const indexOfComment = comments.findIndex(
      item => item.id === isDeleting.id
    );
    const parentComment = comments[indexOfComment];
    const totalReplies = parentComment.replies
      ? parentComment.replies.length
      : 0;

    return Object.assign({}, state, {
      unitVideo: Object.assign({}, state.unitVideo, {
        videoDetails: Object.assign({}, state.unitVideo.videoDetails, {
          comment_count:
              state.unitVideo.videoDetails.comment_count - 1 - totalReplies,
        }),
      }),
      unitVideoComments: Object.assign({}, state.unitVideoComments, {
        isDeleting: { status: false, id: null },
        comments: state.unitVideoComments.comments.filter(
          comm => comm.id !== state.unitVideoComments.isDeleting.id
        ),
      }),
    });
  }

  case DELETE_VIDEO_COMMENT_FAILURE:
    return Object.assign({}, state, {
      unitVideoComments: Object.assign({}, state.unitVideoComments, {
        isDeleting: { status: false, id: null },
        error: action.error,
      }),
    });

  case DELETE_VIDEO_COMMENT_REPLY_REQUEST:
    return Object.assign({}, state, {
      unitVideoComments: Object.assign({}, state.unitVideoComments, {
        isDeleting: { status: true, replyId: action.id, id: action.query },
      }),
    });

  case DELETE_VIDEO_COMMENT_REPLY_SUCCESS: {
    const { unitVideoComments } = state;
    const { isDeleting, comments } = unitVideoComments;
    const indexOfComment = comments.findIndex(
      item => item.id === isDeleting.id
    );
    const parentComment = comments[indexOfComment];

    const updatedObject = Object.assign({}, parentComment, {
      replies: parentComment.replies.filter(
        reply => reply.id !== isDeleting.replyId
      ),
    });

    return Object.assign({}, state, {
      unitVideo: Object.assign({}, state.unitVideo, {
        videoDetails: Object.assign({}, state.unitVideo.videoDetails, {
          comment_count: state.unitVideo.videoDetails.comment_count - 1,
        }),
      }),
      unitVideoComments: Object.assign({}, unitVideoComments, {
        comments: [
          ...unitVideoComments.comments.slice(0, indexOfComment),
          updatedObject,
          ...unitVideoComments.comments.slice(indexOfComment + 1),
        ],
        isDeleting: { status: false, replyId: null, id: null },
      }),
    });
  }

  case DELETE_VIDEO_COMMENT_REPLY_FAILURE:
    return Object.assign({}, state, {
      unitVideoComments: Object.assign({}, state.unitVideoComments, {
        isDeleting: { status: false, replyId: null, id: null },
        error: action.error,
      }),
    });

  case UPDATE_VIDEO_COMMENT_REQUEST:
    return Object.assign({}, state, {
      unitVideoComments: Object.assign({}, state.unitVideoComments, {
        isUpdating: { status: true, commentId: action.id },
      }),
    });

  case UPDATE_VIDEO_COMMENT_SUCCESS: {
    const index = state.unitVideoComments.comments.findIndex(
      item => item.id === action.data.id
    );
    const updatedComment = Object.assign(
      {},
      state.unitVideoComments.comments[index],
      {
        comment: action.data.comment,
      }
    );

    return Object.assign({}, state, {
      unitVideoComments: Object.assign({}, state.unitVideoComments, {
        comments: [
          ...state.unitVideoComments.comments.slice(0, index),
          updatedComment,
          ...state.unitVideoComments.comments.slice(index + 1),
        ],
        isUpdating: { status: false, commentId: null },
      }),
    });
  }

  case UPDATE_VIDEO_COMMENT_FAILURE:
    return Object.assign({}, state, {
      unitVideoComments: Object.assign({}, state.unitVideoComments, {
        isUpdating: { status: false, commentId: null },
        error: action.error,
      }),
    });

  case UPDATE_VIDEO_COMMENT_REPLY_REQUEST:
    return Object.assign({}, state, {
      unitVideoComments: Object.assign({}, state.unitVideoComments, {
        isUpdating: {
          status: true,
          replyId: action.id,
        },
      }),
    });

  case UPDATE_VIDEO_COMMENT_REPLY_SUCCESS: {
    const { unitVideoComments } = state;
    const { isUpdating, comments } = unitVideoComments;

    const indexOfComment = comments.findIndex(item =>
      item.replies.find(element => element.id === isUpdating.replyId));

    const replyIndex = comments[indexOfComment].replies.findIndex(
      item => item.id === isUpdating.replyId
    );

    const parentComment = comments[indexOfComment];

    const updatedReplyObject = Object.assign(
      {},
      parentComment.replies[replyIndex],
      {
        comment: action.data.comment,
      }
    );

    const updatedObject = Object.assign({}, parentComment, {
      replies: [
        ...parentComment.replies.slice(0, replyIndex),
        updatedReplyObject,
        ...parentComment.replies.slice(replyIndex + 1),
      ],
    });

    return Object.assign({}, state, {
      unitVideoComments: Object.assign({}, unitVideoComments, {
        comments: [
          ...unitVideoComments.comments.slice(0, indexOfComment),
          updatedObject,
          ...unitVideoComments.comments.slice(indexOfComment + 1),
        ],
        isUpdating: { status: false, replyId: null },
      }),
    });
  }

  case UPDATE_VIDEO_COMMENT_REPLY_FAILURE:
    return Object.assign({}, state, {
      unitVideoComments: Object.assign({}, state.unitVideoComments, {
        error: action.error,
        isUpdating: { status: false, replyId: null },
      }),
    });

  default:
    return state;
  }
}
