import { CALL_API } from '../../middlewares/api';

import {
  ORGANIZATION_GROUP_STATS_REQUEST,
  ORGANIZATION_GROUP_STATS_SUCCESS,
  ORGANIZATION_GROUP_STATS_FAILURE,
  ORGANIZATION_INSTITUTION_STATS_REQUEST,
  ORGANIZATION_INSTITUTION_STATS_SUCCESS,
  ORGANIZATION_INSTITUTION_STATS_FAILURE,
  SET_SELECTED_INSTITUTION_INDEX,
  SET_NUMBER_OF_GROUPS_TO_CREATE,
} from '../../constants';

export const setSelectedInstitutionIndex = (index) => {
  return { type: SET_SELECTED_INSTITUTION_INDEX, index };
};

export const setNumberOfGroupsToCreate = (numberOfGroupsToCreate) => {
  return { type: SET_NUMBER_OF_GROUPS_TO_CREATE, numberOfGroupsToCreate };
};

export const getGroup = (groupId) => {
  const endpoint = `group/group/${groupId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        ORGANIZATION_GROUP_STATS_REQUEST,
        ORGANIZATION_GROUP_STATS_SUCCESS,
        ORGANIZATION_GROUP_STATS_FAILURE,
      ],
    },
  };
};

export const getInstitution = (institutionId) => {
  const endpoint = `institution/institution/${institutionId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        ORGANIZATION_INSTITUTION_STATS_REQUEST,
        ORGANIZATION_INSTITUTION_STATS_SUCCESS,
        ORGANIZATION_INSTITUTION_STATS_FAILURE,
      ],
    },
  };
};
