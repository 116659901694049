import PropTypes from 'prop-types';
import React, { Component } from 'react';

class BulkActions extends Component {
  static propTypes = {
    toggleModal: PropTypes.func.isRequired,
    onFollowClick: PropTypes.func,
    onUnFollowClick: PropTypes.func.isRequired,
    selectAllClick: PropTypes.func.isRequired,
    isFollowingMode:PropTypes.bool,
    allSelected: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      value: 'bulkActions',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleClick() {
    switch (this.state.value) {
    case 'message':
      this.props.toggleModal();
      break;
    case 'follow':
      this.props.onFollowClick();
      break;
    case 'unfollow':
      this.props.onUnFollowClick();
      break;
    default:
    }
  }

  toggleAllSelected = () => {
    const { allSelected, selectAllClick } = this.props;
    selectAllClick(allSelected);
  };

  render () {
    return (
      <div className="filterbar">
        <div className="form-inline">
          <div className="form-group">
            <select
              className="form-control"
              id="exampleSelect1"
              value={this.state.value}
              onChange={this.handleChange}>
              <option value="bulkActions">Bulk Actions</option>
              <option value="message">Message</option>
              {/** !this.props.isFollowingMode &&
                <option value="follow">Follow</option>
                <option value="unfollow">Unfollow</option> */}
            </select>
            <button
              className="btn btn-primary"
              onClick={this.handleClick} >
              Apply
            </button>
            <button
              className="btn btn-transparent"
              onClick={this.toggleAllSelected} >
              {this.props.allSelected? 'De-Select All' : 'Select All'}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default BulkActions;
