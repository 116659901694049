import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import isEmpty from 'lodash/isEmpty';

import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';

import {
  Row,
  Col,
  PopoverBody,
  UncontrolledPopover
} from 'reactstrap';
import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';

import * as skillActions from '../../redux/actions/skills';
import * as profileActions from '../../redux/actions/profile';
import * as cpStatusActions from '../../redux/actions/my-careerprepped-status';
import { openModal, closeModal } from '../../redux/actions/components';
import { getMyCareerPreppedStatus } from '../../redux/actions/my-careerprepped-status';

import AddEducation from '../../components/dashboard/my-careerprepped-status-components/AddEducation';
import AddExperience from '../../components/dashboard/my-careerprepped-status-components/AddExperience';
import AddSummary from '../../components/dashboard/my-careerprepped-status-components/AddSummary';
import AddWorkSituation from '../../components/dashboard/my-careerprepped-status-components/AddWorkSituation';
import BasicEditModal from '../profile/header/BasicEditModal';
import AddYourCustomSkills from '../../components/myskills/AddYourCustomSkills';
import SummaryIcon from '../../assets/images/summary.svg';
import EducationIcon from '../../assets/images/education.svg';
import ExperienceIcon from '../../assets/images/experience.svg';
import WorkSituationIcon from '../../assets/images/worksituation.svg';
import LocationIcon from '../../assets/images/location.svg';
import HeadlineIcon from '../../assets/images/headline.svg';
import FiveSkillsIcon from '../../assets/images/fiveskills.svg';
import SkillEvidenceIcon from '../../assets/images/skillevidence.svg';
import CardAvatarIcon from '../../assets/images/cardavatar.svg';
import CardBackgroundIcon from '../../assets/images/cardbackground.svg';
import SkillBuilderIcon from '../../assets/images/skillbuilder.svg';
import SkillBuilderEvidenceIcon from '../../assets/images/skillbuilderevidence.svg';
import LaunchingIcon from '../../assets/images/icon-launching.svg';
import DevelopingIcon from '../../assets/images/icon-developing.svg';
import BeginningIcon from '../../assets/images/icon-beginning.svg';
import CPStatusSuccessfull from '../../components/dashboard/my-careerprepped-status-components/CPStatusSuccessfull';

class MyCareerPreppedStatus extends Component {
  static propTypes = {
    myCareerPreppedStatus: PropTypes.object.isRequired,
    skills: PropTypes.array.isRequired,
    profile: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      collapse: false,
      currentPage: 1,
      maxItemsPerPage: 4,
      stageClicked: false,
      isEducationModalOpen: false,
      isExperienceModalOpen: false,
      isSummaryModalOpen: false,
      isWorkSituationModalOpen: false,
      isCPStatusSuccessfullModalOpen: false,
      isHeadlineModalOpen: false,
      fieldsToShow: false,
      modalTitle: false,
      addYourCustomSkills: false,
      isUpdatingCoverPhoto: false,
      isUpdatingAvatar: false
    };

    this.changePage = this.changePage.bind(this);
    this.toggleEducationModal = this.toggleEducationModal.bind(this);
    this.toggleExperienceModal = this.toggleExperienceModal.bind(this);
    this.toggleSummaryModal = this.toggleSummaryModal.bind(this);
    this.toggleWorkSituationModal = this.toggleWorkSituationModal.bind(this);
    this.onEditBasicInfo = this.onEditBasicInfo.bind(this);
    this.toggleAddSkills = this.toggleAddSkills.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.onSelectAvatarOrCoverPhoto = this.onSelectAvatarOrCoverPhoto.bind(this);
    this.toggleCPStatusSuccessfullModal = this.toggleCPStatusSuccessfullModal.bind(this);
    this.toggleContactInfo = this.toggleContactInfo.bind(this);
  }

  componentDidMount() {
    const { profile, actions } = this.props;

    actions.skillsRequest(profile.id);
  }

  componentWillReceiveProps(nextProps) {
    const { profile, actions } = this.props;

    if (nextProps.profile.id && isEmpty(profile.id) && (!nextProps.isRequestingMyCareerPreppedStatus)) {
      actions.getMyCareerPreppedStatus(nextProps.profile.id);
      actions.skillsRequest(nextProps.profile.id);
    }
  }

  toggleEducationModal() {
    if (this.state.isEducationModalOpen) {
      const { profile, actions } = this.props;
      actions.getMyCareerPreppedStatus(profile.id).then((response) => {
        if (response.myCareerPreppedStatus.data.overallStatus > 99) {
          this.toggleCPStatusSuccessfullModal();
        }
      });
    }

    this.setState((state) => {
      return {
        isEducationModalOpen: !state.isEducationModalOpen,
      };
    });
  }

  toggleExperienceModal() {
    if (this.state.isExperienceModalOpen) {
      const { profile, actions } = this.props;
      actions.getMyCareerPreppedStatus(profile.id).then((response) => {
        if (response.myCareerPreppedStatus.data.overallStatus > 99) {
          this.toggleCPStatusSuccessfullModal();
        }
      });
    }

    this.setState((state) => {
      return {
        isExperienceModalOpen: !state.isExperienceModalOpen,
      };
    });
  }

  toggleSummaryModal() {
    if (this.state.isSummaryModalOpen) {
      const { profile, actions } = this.props;
      actions.getMyCareerPreppedStatus(profile.id).then((response) => {
        if (response.myCareerPreppedStatus.data.overallStatus > 99) {
          this.toggleCPStatusSuccessfullModal();
        }
      });
    }

    this.setState((state) => {
      return {
        isSummaryModalOpen: !state.isSummaryModalOpen,
      };
    });
  }

  toggleWorkSituationModal() {
    if (this.state.isWorkSituationModalOpen) {
      const { profile, actions } = this.props;
      actions.getMyCareerPreppedStatus(profile.id).then((response) => {
        if (response.myCareerPreppedStatus.data.overallStatus > 99) {
          this.toggleCPStatusSuccessfullModal();
        }
      });
    }

    this.setState((state) => {
      return {
        isWorkSituationModalOpen: !state.isWorkSituationModalOpen,
      };
    });
  }

  toggleContactInfo() {
    const { modal, actions, profile } = this.props;

    if (modal === 'contact-info') {
      actions.getMyCareerPreppedStatus(profile.id).then((response) => {
        if (response.myCareerPreppedStatus.data.overallStatus > 99) {
          this.toggleCPStatusSuccessfullModal();
        }
      });

      actions.closeModal();
      return;
    }

    actions.openModal('contact-info');
  }

  toggleCPStatusSuccessfullModal() {
    this.setState((state) => {
      return {
        isCPStatusSuccessfullModalOpen: !state.isCPStatusSuccessfullModalOpen,
      };
    });
  }

  onEditBasicInfo(event) {
    const passParam = event?.target?.getAttribute('data-value');
    if (this.state.isHeadlineModalOpen) {
      const { profile, actions } = this.props;
      actions.getMyCareerPreppedStatus(profile.id).then((response) => {
        if (response.myCareerPreppedStatus.data.overallStatus > 99) {
          this.toggleCPStatusSuccessfullModal();
        }
      });
    }

    this.setState((state) => {
      return {
        isHeadlineModalOpen: !state.isHeadlineModalOpen,
        fieldsToShow: passParam,
        modalTitle: ((passParam === 'headline') ? 'Add Headline' : 'Add Location'),
      };
    });
  }

  toggleAddSkills() {
    if (this.state.addYourCustomSkills) {
      const { profile, actions } = this.props;
      actions.getMyCareerPreppedStatus(profile.id).then((response) => {
        if (response.myCareerPreppedStatus.data.overallStatus > 99) {
          this.toggleCPStatusSuccessfullModal();
        }
      });
    }

    this.setState((state) => {
      return {
        addYourCustomSkills: !state.addYourCustomSkills,
      };
    });
  }

  changePage(event) {
    const direction = event.target.id;
    let { currentPage } = this.state;
    (direction === 'next') ? currentPage += 1 : currentPage -= 1;
    this.setState({ currentPage });
  }

  async onSelectAvatarOrCoverPhoto(files, fileRejections, event) {
    const { actions, profile } = this.props;

    const { name } = event.target;
    const isUpdating = name === 'avatar' ? 'isUpdatingAvatar' : 'isUpdatingCoverPhoto';

    this.setState({ [isUpdating]: true });

    const file = files[0];
    const formData = new FormData();

    formData.append(name, file);

    await actions.updateProfile(profile.id, formData);
    await actions.updateMyCareerPreppedStatus();
    actions.getMyCareerPreppedStatus();

    this.setState({ [isUpdating]: false });
  }

  generateButton(type, cpStatusTitleAlias) {
    switch (type) {
    case 'education': {
      return (
        <a
          href="#"
          onClick={this.toggleEducationModal}>
          {cpStatusTitleAlias[type]}
        </a>
      );
    }

    case 'experience': {
      return (
        <a
          href="#"
          onClick={this.toggleExperienceModal}>
          {cpStatusTitleAlias[type]}
        </a>
      );
    }

    case 'summary': {
      return (
        <a
          href="#"
          onClick={this.toggleSummaryModal}>
          {cpStatusTitleAlias[type]}
        </a>
      );
    }

    case 'worksituation': {
      return (
        <a
          href="#"
          onClick={this.toggleWorkSituationModal}>
          {cpStatusTitleAlias[type]}
        </a>
      );
    }

    case 'location': {
      return (
        <a
          href="#"
          data-value="location"
          onClick={this.onEditBasicInfo}>
          {cpStatusTitleAlias[type]}
        </a>
      );
    }

    case 'contact': {
      return (
        <a
          href="#"
          onClick={this.toggleContactInfo}>
          {cpStatusTitleAlias[type]}
        </a>
      );
    }

    case 'headline': {
      return (
        <a
          href="#"
          data-value="headline"
          onClick={this.onEditBasicInfo}>
          {cpStatusTitleAlias[type]}
        </a>
      );
    }

    case 'avatar': {
      return (
        <a
          href="#"
          disabled={this.state.isUpdatingAvatar}>
          <Dropzone
            multiple={false}
            className="upload-background-btn"
            accept="image/*"
            onDrop={this.onSelectAvatarOrCoverPhoto}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  {`${this.state.isUpdatingAvatar ? 'Adding...' : cpStatusTitleAlias[type]}`}
                  <input {...getInputProps()} name="avatar" />
                </div>
              </section>
            )}
          </Dropzone>
        </a>
      );
    }

    case 'backgroundimage': {
      return (
        <a
          href="#"
          disabled={this.state.isUpdatingCoverPhoto}>
          <Dropzone
            multiple={false}
            className="upload-background-btn"
            accept="image/*"
            onDrop={this.onSelectAvatarOrCoverPhoto}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  {`${this.state.isUpdatingCoverPhoto ? 'Adding...' : 'Add'}`}
                  <input {...getInputProps()} name="background" />
                </div>
              </section>
            )}
          </Dropzone>
        </a>
      );
    }

    case 'skillBuilder': {
      return <Link className="orange-btn" to="/skill-builders">Join</Link>;
    }

    case 'skillBuilderEvidence': {
      return <Link className="orange-btn" to="/skill-builders">Add</Link>;
    }

    case 'skills': {
      return (
        <a
          href="#"
          onClick={this.toggleAddSkills}>
          {cpStatusTitleAlias[type]}
        </a>
      );
    }

    case 'skillEvidence': {
      return <Link className="orange-btn" to="/myskills#otherSkills">Add</Link>;
    }
    }
  }

  getCardIcon(type) {
    switch (type) {
    case 'summary.svg': {
      return <img src={SummaryIcon} />;
    }

    case 'education.svg': {
      return <img src={EducationIcon} />;
    }

    case 'experience.svg': {
      return <img src={ExperienceIcon} />;
    }

    case 'worksituation.svg': {
      return <img src={WorkSituationIcon} />;
    }

    case 'location.svg': {
      return <img src={LocationIcon} />;
    }

    case 'headline.svg': {
      return <img src={HeadlineIcon} />;
    }

    case 'fiveskills.svg': {
      return <img src={FiveSkillsIcon} />;
    }

    case 'skillevidence.svg': {
      return <img src={SkillEvidenceIcon} />;
    }

    case 'cardavatar.svg': {
      return <img src={CardAvatarIcon} />;
    }

    case 'cardbackground.svg': {
      return <img src={CardBackgroundIcon} />;
    }

    case 'skillbuilder.svg': {
      return <img src={SkillBuilderIcon} />;
    }

    case 'skillbuilderevidence.svg': {
      return <img src={SkillBuilderEvidenceIcon} />;
    }
    }

  }

  toggleDropdown() {
    this.setState({ collapse: !this.state.collapse });
  }

  render() {
    const { myCareerPreppedStatus, profile, skills } = this.props;
    const percentile = (myCareerPreppedStatus.overallStage) ?
      myCareerPreppedStatus.overallStage : 0;

    const completedActions = myCareerPreppedStatus.completedCards ?
      myCareerPreppedStatus.completedCards.length : 0;

    const cpStatusTitleAlias = {
      'skills': 'Add 10 Skills',
      'education': 'Add Education',
      'experience': 'Add Experience',
      'summary': 'Add About Me',
      'location': 'Add Location',
      'worksituation': 'Add Work Situation',
      'headline': 'Add Headline',
      'avatar': 'Add Photo',
      'contact': 'Add Contact Info'
    };

    return (
      <div>
        <div className="home-widget-title-large d-flex justify-content-between">
          <div className="cp-status-header">
            <h3 className="d-flex">CareerPrepped Stage:&nbsp;
              {((completedActions + myCareerPreppedStatus.resumeAction) === 10) ? (
                <div className="cursor-pointer cp-status-level-pill" id="statusStage">
                  <div>Launching</div>
                </div>
              ) : completedActions >= 4 && completedActions < 10 ? (
                <div className="cursor-pointer cp-status-level-pill" id="statusStage">
                  <div>Developing</div>
                </div>
              ) : (
                <div className="cursor-pointer cp-status-level-pill" id="statusStage">
                  <div>Beginning</div>
                </div>
              )}
            </h3>
          </div>
        </div>
        <div className="cp-status-progress-bar-container">
          <ProgressBar
            percent={percentile}
            filledBackground="linear-gradient(to right, #fefb72, #f0bb31)" >
            <Step transition="scale">
              {({ accomplished }) => (
                <img
                  style={{ filter: `grayscale(${accomplished ? 0 : 100}%)` }}
                  width="20"
                  height="auto"
                  src={BeginningIcon} />
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished }) => (
                <img
                  style={{ filter: `grayscale(${accomplished ? 0 : 100}%)` }}
                  width="20"
                  height="auto"
                  src={DevelopingIcon} />
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished }) => (
                <img
                  style={{ filter: `grayscale(${accomplished ? 0 : 100}%)` }}
                  width="20"
                  height="auto"
                  src={LaunchingIcon} />
              )}
            </Step>
          </ProgressBar>
        </div>
        <UncontrolledPopover
          placement="bottom"
          target="statusStage"
          className="careerstatus-popover"
          trigger="legacy">
          <PopoverBody>
            <b>Actions Needed</b>
            {myCareerPreppedStatus &&
              myCareerPreppedStatus?.completedCards?.map((action) => {
                return (
                  <Row key={action.id}>
                    <Col xs="12">
                      <s><span className="text-muted">{cpStatusTitleAlias[action.statusType]}</span></s>
                    </Col>
                  </Row>
                );
              }
              )}

            {myCareerPreppedStatus &&
              myCareerPreppedStatus?.pendingCards?.map((action) => {
                return (
                  <Row key={action.id}>
                    <Col xs="12">
                      {this.generateButton(action.statusType, cpStatusTitleAlias)}
                    </Col>
                  </Row>
                );
              }
              )}

            <Row key="create-resume">
              <Col xs="12">
                {myCareerPreppedStatus.resumeAction ?
                  <s><span className="text-muted">Create Resume</span></s> :
                  <Link to="/job-center/resume-builder">Create Resume</Link>}
              </Col>
            </Row>
          </PopoverBody>
        </UncontrolledPopover>

        <AddEducation
          isOpen={this.state.isEducationModalOpen}
          toggleModal={this.toggleEducationModal}
          profile={profile} />

        <AddExperience
          isOpen={this.state.isExperienceModalOpen}
          toggleModal={this.toggleExperienceModal}
          profile={profile} />

        <AddSummary
          isOpen={this.state.isSummaryModalOpen}
          toggleModal={this.toggleSummaryModal}
          profile={profile} />

        <AddWorkSituation
          isOpen={this.state.isWorkSituationModalOpen}
          toggleModal={this.toggleWorkSituationModal} />

        <CPStatusSuccessfull
          isOpen={this.state.isCPStatusSuccessfullModalOpen}
          toggleModal={this.toggleCPStatusSuccessfullModal} />

        {this.state.isHeadlineModalOpen && (
          <BasicEditModal
            isOpen={this.state.isHeadlineModalOpen}
            onCancel={this.onEditBasicInfo}
            fieldsToShow={this.state.fieldsToShow}
            modalTitle={this.state.modalTitle} />)}

        {this.state.addYourCustomSkills &&
          <AddYourCustomSkills
            isOpen={this.state.addYourCustomSkills}
            toggleModal={this.toggleAddSkills}
            addedSkillsCount={skills ? skills.length : 0} />}

      </div>
    );
  }
}

MyCareerPreppedStatus.propTypes = {
  myCareerPreppedStatus: PropTypes.object,
  skills: PropTypes.array,
  profile: PropTypes.object,
  actions: PropTypes.object,
  modal: PropTypes.string,
  isRequestingMyCareerPreppedStatus: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
  const {
    components: { modal },
    skills: { data: skills },
    profile: { data: profile },
    myCareerPreppedStatus: {
      data: myCareerPreppedStatus,
      isRequesting: isRequestingMyCareerPreppedStatus
    }
  } = state;

  return {
    modal,
    skills,
    profile,
    myCareerPreppedStatus,
    isRequestingMyCareerPreppedStatus
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openModal,
      closeModal,
      getMyCareerPreppedStatus,
      ...skillActions,
      ...profileActions,
      ...cpStatusActions
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyCareerPreppedStatus);
