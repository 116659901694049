import initialState from './initialState';

import {
  RESET_JOB_BOARD,
  FETCH_MY_JOBS_REQUEST,
  FETCH_MY_JOBS_SUCCESS,
  FETCH_MY_JOBS_FAILURE,
  DELETE_JOB_FROM_BOARD_REQUEST,
  DELETE_JOB_FROM_BOARD_SUCCESS,
  DELETE_JOB_FROM_BOARD_FAILURE,
  UPDATE_JOB_DETAILS_REQUEST,
  UPDATE_JOB_DETAILS_SUCCESS,
  UPDATE_JOB_DETAILS_FAILURE,
  POST_NEW_JOB_REQUEST,
  POST_NEW_JOB_SUCCESS,
  POST_NEW_JOB_FAILURE,
} from '../../constants';

const updateDetails = (state, action) => {
  const { data, isUpdating } = state;
  const indexOfJob = data.findIndex(item => item.id === isUpdating.id);
  return {
    ...state,
    data: [
      ...data.slice(0, indexOfJob),
      action.data,
      ...data.slice(indexOfJob + 1),
    ],
  };
};

const updateJobBoard = (state, action) => {
  let returnData = [];
  const {
    data: prevResults,
    page: prevPage,
    page_count: prevPageCount,
  } = state;
  const { _embedded: actionData, page, page_count } = action;
  returnData = actionData.job;

  if (page && page !== 1) {
    returnData = [...prevResults, ...returnData];
  }

  const stateToReturn = {
    data: returnData,
    paginationData: {
      page: page || prevPage,
      page_count: page_count || prevPageCount,
    },
    isRequesting: false,
  };

  return stateToReturn;
};

export default function reducer(state = initialState.jobBoards, action) {
  switch (action.type) {
  case RESET_JOB_BOARD:
    return Object.assign({}, initialState.jobBoards);

  case FETCH_MY_JOBS_REQUEST:
    return Object.assign({}, state, {
      isRequesting: true,
    });

  case FETCH_MY_JOBS_SUCCESS:
    return Object.assign({}, state, updateJobBoard(state, action.data));

  case FETCH_MY_JOBS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case UPDATE_JOB_DETAILS_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, id: action.id },
    });

  case UPDATE_JOB_DETAILS_SUCCESS:
    return Object.assign({}, state, {
      ...updateDetails(state, action),
      isUpdating: { status: false, id: null },
    });

  case UPDATE_JOB_DETAILS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false, id: null },
    });

  case POST_NEW_JOB_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: true,
    });

  case POST_NEW_JOB_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data, action.data],
      isSubmitting: false,
    });

  case POST_NEW_JOB_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case DELETE_JOB_FROM_BOARD_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: action.id },
    });

  case DELETE_JOB_FROM_BOARD_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data.filter(item => item.id !== state.isDeleting.id)],
      isDeleting: { status: false, id: null },
    });

  case DELETE_JOB_FROM_BOARD_FAILURE:
    return Object.assign({}, state, {
      isDeleting: { status: false, id: null },
      error: action.error,
    });

  default:
    return state;
  }
}
