import PropTypes from 'prop-types';
import React from 'react';
import Timestamp from './Timestamp';
import CommentActions from '../../../containers/skill-builders/discussions/CommentActions';
import CommentLikeButtonContainer from '../../../containers/skill-builders/discussions/CommentLikeButtonContainer';
import ReplyButton from '../../../containers/skill-builders/discussions/ReplyButton';
import CommentFormHandler from '../../../containers/skill-builders/discussions/CommentFormHandler';
import { loadForm } from '../../../utils';
import ReplyFormHandler from '../../../containers/skill-builders/discussions/ReplyFormHandler';
import Reply from './Reply';
import CommentItem from '../../common/CommentItem';
import DiscussionAvatar from './DiscussionAvatar';

const CommentCard = ({
  profile,
  onClickDelete,
  commentData,
  loadForms,
  discussionId,
  replies,
  currentUser,
  commentShowMore,
  onCommentShowMore
}) => {
  const form = `update-comment-form-${commentData.id}`;

  const isForm = loadForm(loadForms, form);

  if (isForm) {
    return (
      <div>
        <CommentFormHandler
          comment={commentData}
          discussionId={discussionId}
          isForm={isForm}
          form={form}/>

        <div className="secondary-post" style={{borderTop: 'none'}}>
          <Reply replies={replies} />

          <ReplyFormHandler
            discussionId={discussionId}
            commentId={commentData.id}/>
        </div>
      </div>
    );
  }

  return (
    <div className="secondary-post">
      <DiscussionAvatar user={commentData.user}/>

      <div className="right">
        {((currentUser.id === commentData.user.id) || (profile.role === 'super-admin')) &&
          <CommentActions
            commentId={commentData.id}
            onClickDelete={onClickDelete}/>}

        <div className="profile-title">
          <CommentItem
            wallPost={commentData}
            showMore={commentShowMore}
            onshowMore={onCommentShowMore}
            secondary/>

          <div className="share-stats">
            <CommentLikeButtonContainer comment={commentData}/>

            <ReplyButton commentId={commentData.id}/>

            <span style={{paddingRight:5}}>{commentData.likes} Likes</span>

            <span style={{paddingRight:5}}>
              <Timestamp postedAt={commentData.posted.date}/>
            </span>
          </div>
        </div>
      </div>

      <Reply replies={replies} />

      <ReplyFormHandler
        discussionId={discussionId}
        commentId={commentData.id}/>
    </div>
  );
};

CommentCard.propTypes = {
  profile: PropTypes.object.isRequired,
  loadForms: PropTypes.array.isRequired,
  commentData: PropTypes.object.isRequired,
  discussionId: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  onCommentShowMore: PropTypes.func.isRequired,
  commentShowMore: PropTypes.object.isRequired,
  onClickDelete: PropTypes.func,
  replies: PropTypes.array,
};

export default CommentCard;
