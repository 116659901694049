import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';

import PropTypes from 'prop-types';
import _flatten from 'lodash/flatten';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import * as badgeAssertionActions from '../../redux/actions/badge-assertion';
import * as badgeActions from '../../redux/actions/badges';
import * as skillsActions from '../../redux/actions/skills';
import * as filesActions from '../../redux/actions/files';
import * as componentActions from '../../redux/actions/components';
import {updateMyCareerPreppedStatus, getMyCareerPreppedStatus} from '../../redux/actions/my-careerprepped-status';

import LinkingPortfolioModalComponent from '../../components/skill-badges/LinkingPortfolioModalComponent';
import { MAX_FILE_NO, MAX_UPLOAD_ERROR_MSG } from '../../utilConstants';

class LinkingPortfolioModal extends Component {
  static propTypes = {
    files: PropTypes.array.isRequired,
    badges: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
    fileData: PropTypes.object,
    bakedBadgePage: PropTypes.bool,
    isOpen: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    isRequestingFiles: PropTypes.bool.isRequired,
    skillsCard: PropTypes.bool,
    getMyBadges: PropTypes.func,
    getMySkills: PropTypes.func,
    linkingSkill: PropTypes.bool,
    onLinkedSkill: PropTypes.func,
    filesCount: PropTypes.string,
    skills: PropTypes.object.isRequired,
    myskills: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      selectedFiles: [],
      activeTab: 0,
      privateFiles: [],
      noOfPubicFiles: 0
    };

    this.linkfiles = this.linkfiles.bind(this);
    this.selectFile = this.selectFile.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
    this.onNext = this.onNext.bind(this);
    this.onPrev = this.onPrev.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
    this.closeLinkFilesRequest = this.closeLinkFilesRequest.bind(this);
  }

  componentWillMount() {
    const { actions, userId, isAuthenticated } = this.props;
    actions.clearFilesReducer();
    actions.fetchFiles(isAuthenticated, userId, 1, 'newest', '');
  }

  componentWillUnmount() {
    this.props.actions.clearFilesReducer();
  }

  selectFile(id) {
    return () => {
      const { selectedFiles } = this.state;
      const index = selectedFiles.indexOf(id);
      const updatedSelectedFiles = index !== -1 ?
        [...selectedFiles.slice(0, index), ...selectedFiles.slice(index+1)] :
        [ ...selectedFiles, id];

      this.setState({ selectedFiles: updatedSelectedFiles });
    };
  }

  linkfiles() {
    const { selectedFiles: fileIds } = this.state;
    const { skills, badges, actions, files, myskills, linkingSkill } = this.props;
    const badgeIssuedIds = badges.selectedBadges.map(
      badge => (badge.issued.id) ? badge.issued.id : badge.id
    );

    const selectedFiles = badges.selectedBadges.map(
      badge => badge.files
    );
    let maxfileUploadError = selectedFiles[0] && selectedFiles[0]?.length >= MAX_FILE_NO;

    if (!maxfileUploadError && fileIds.length === 0) {
      const errors = { file: 'You must select a file' };

      return this.setState({ errors });
    }

    const privateFiles = files.filter(file => file.visibility !== 'public' && fileIds.includes(file.id));
    const privateFilesIdsArray = privateFiles.map(file => file.id);
    const filteredFileIds = fileIds.filter(id => !privateFilesIdsArray.includes(id));

    maxfileUploadError = (selectedFiles[0]?.length + filteredFileIds.length) > MAX_FILE_NO;

    if (maxfileUploadError) {
      toastr.error('', MAX_UPLOAD_ERROR_MSG);
      return;
    }

    if(privateFiles.length > 0){
      this.setState({
        noOfPubicFiles: filteredFileIds.length,
        privateFiles: [...privateFiles],
        showAlertMessage: true
      });
    }

    const linkFileData = ( linkingSkill ) ? {'fileIds': filteredFileIds, 'skills': badgeIssuedIds, 'action': 'link_portfolio', 'type': 'skill'} : {'fileIds': filteredFileIds, 'badgeIssuedIds': badgeIssuedIds, 'action': 'link_portfolio'};

    if (filteredFileIds.length > 0) {
      if (linkingSkill) {
        const index = skills.data.findIndex(skill => skill.id === badges.selectedBadges[0].id);

        actions
          .linkSkillsEvidenceFiles(linkFileData, index)
          .then(async () => {
            await this.props.actions.updateMyCareerPreppedStatus();
            this.closeLinkFilesRequest();
          });

        return;
      }

      const index = myskills.data.badges.findIndex(badge => badge.badge_id === badges.selectedBadges[0].id);

      actions
        .linkfiles(linkFileData, index)
        .then(async () => {
          await this.props.actions.updateMyCareerPreppedStatus();
          this.closeLinkFilesRequest();
        });
    }
  }

  onTabChange(id){
    return () => {
      this.setState({
        activeTab: id
      });
    };
  }

  closeLinkFilesRequest(){
    this.props.actions.closeModal();
    this.props.actions.resetSelectedBadges();
  }

  onNext(){
    const { actions, userId, fileData, isAuthenticated } = this.props;
    actions.clearFilesReducer();
    return actions.fetchFiles(isAuthenticated, userId, fileData.page + 1, 'newest', '');
  }

  onPrev(){
    const { actions, userId, fileData, isAuthenticated } = this.props;
    actions.clearFilesReducer();
    return actions.fetchFiles(isAuthenticated, userId, fileData.page - 1, 'newest', '');
  }

  onSelectPage(page){
    return () => {
      const { actions, userId, isAuthenticated } = this.props;
      actions.clearFilesReducer();
      return actions.fetchFiles(isAuthenticated, userId, page, 'newest', '');
    };
  }

  render() {
    const {
      selectedFiles,
      activeTab,
      privateFiles,
      noOfPubicFiles,
    } = this.state;
    const {
      files,
      fileData,
      badges,
      skills,
      isRequestingFiles,
      bakedBadgePage,
      isOpen,
      skillsCard,
      getMyBadges,
      getMySkills,
      linkingSkill,
      onLinkedSkill
    } = this.props;

    const { isSubmitting } = badges;
    const showPrivateConfirmation = privateFiles.length > 0;
    const { unitSkill: { isUpdating: { status: isLinkingSkillFiles } } } = skills;
    const selectedBadgesFiles = badges.selectedBadges.filter(bad => bad.files && bad.files.length > 0).map(badge => badge.files);
    const linkedFileIds = _flatten(selectedBadgesFiles.map(skill => skill.map(nestedItem => nestedItem.id)));

    return(
      <Modal
        backdrop="static"
        className="portfolio-file-modal"
        size="lg"
        isOpen={isOpen}
        toggle={this.closeLinkFilesRequest}>
        <ModalHeader
          toggle={this.closeLinkFilesRequest}>
          Link Portfolio File(s)
        </ModalHeader>
        <ModalBody>
          <LinkingPortfolioModalComponent
            noOfPubicFiles={noOfPubicFiles}
            privateFiles={privateFiles}
            showPrivateConfirmation={showPrivateConfirmation}
            activeTab={activeTab}
            files={files}
            onNext={this.onNext}
            onPrev={this.onPrev}
            onSelectPage={this.onSelectPage}
            fileData={fileData}
            selectFile={this.selectFile}
            selectedFiles={selectedFiles}
            linkedFiledIds={linkedFileIds}
            isRequestingFiles={isRequestingFiles}
            onTabChange={this.onTabChange}
            bakedBadgePage={bakedBadgePage}
            closeModal={this.closeLinkFilesRequest}
            skillsCard={skillsCard}
            getMyBadges={getMyBadges}
            getMySkills={getMySkills}
            linkingSkill={linkingSkill}
            onLinkedSkill={onLinkedSkill}/>
        </ModalBody>
        <ModalFooter>
          {activeTab === 0 && privateFiles.length === 0 &&
            <div id="cancel-link">
              <button
                type="button"
                name="button"
                className="btn btn-secondary"
                style={{marginLeft: 0}}
                onClick={this.closeLinkFilesRequest}>
                Cancel
              </button>
              <button
                type="button"
                name="button"
                id="link-portfolio-files"
                className="btn btn-primary"
                style={{marginLeft: 10}}
                onClick={this.linkfiles}
                disabled={isSubmitting || isLinkingSkillFiles}>
                {isSubmitting || isLinkingSkillFiles ? 'Linking ...' : 'Link Portfolio File(s)'}
              </button>
            </div>}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { skills, files, badges, myskills } = state;

  return {
    skills,
    badges,
    myskills,
    files: files.data,
    fileData: files.fileData,
    isRequestingFiles: files.isRequesting,
    userId: state.auth.data.currentUser.id,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...filesActions,
      ...badgeActions,
      ...skillsActions,
      ...componentActions,
      ...badgeAssertionActions,
      updateMyCareerPreppedStatus,
      getMyCareerPreppedStatus,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LinkingPortfolioModal);
