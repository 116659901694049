import initialState from './initialState';

import {
  CONTACT_US_REQUEST,
  CONTACT_US_SUCCESS,
  CONTACT_US_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.contact, action) {
  switch (action.type) {
  case CONTACT_US_REQUEST:
    return Object.assign({}, state, { isSubmitting: true });

  case CONTACT_US_SUCCESS:
    return Object.assign({}, state, {
      data: action.data,
      isSubmitting: false,
    });

  case CONTACT_US_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  default:
    return state;
  }
}
