import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

const UpdateCodeModal = ({
  isOpen,
  onCancel,
  groupName,
  onConfirm,
  isUpdating
}) => {
  if (isUpdating) {
    return (
      <Modal
        size="lg"
        className="modal-margin-top"
        isOpen={isOpen}
        backdrop="static"
        toggle={onCancel}>
        <ModalBody>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <span>
              <i className="fa fa-spinner fa-pulse fa-2x fa-fw" />
            </span>
            <span style={{ paddingLeft: 10}}>
              Updating group code...
            </span>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  return (
    <Modal
      size="lg"
      className="modal-margin-top"
      backdrop="static"
      isOpen={isOpen}
      toggle={onCancel}>
      <ModalHeader
        className="no-border"
        toggle={onCancel}>Update Group Code for {groupName}</ModalHeader>
      <ModalBody>
        <p>
          Are you sure you want to update the group code for {groupName}?<br/>
          Once you click "Yes, Update" below, a new group code will be generated for {groupName}.
        </p>
      </ModalBody>
      <ModalFooter className="no-border">
        <button
          onClick={onCancel}
          className="btn btn-secondary float-left">
          Cancel
        </button>

        <button
          onClick={onConfirm}
          className="btn btn-primary">
          Yes, Update
        </button>
      </ModalFooter>
    </Modal>
  );
};

UpdateCodeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  groupName: PropTypes.string.isRequired,
  isUpdating: PropTypes.bool.isRequired
};

export default UpdateCodeModal;
