import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import _isUndefined from 'lodash/isUndefined';
import classNames from 'classnames';
import moment from 'moment';

import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import UnitBadgeAbilities from '../../components/skill-badges/UnitBadgeAbilities';
import CpHtmlParser from '../../components/common/CpHtmlParser';
import Label from 'reactstrap/lib/Label';
import BadgeRatings from '../../components/skill-badges/BadgeRatings';

const endorsedLogo = require('../../assets/images/overlay_endorsed_cropped.png');

class UnitBadgeTile extends Component{

  static propTypes = {
    modal: PropTypes.string,
    badge: PropTypes.object.isRequired,
    badgeAction: PropTypes.func,
    selectedBadges: PropTypes.array.isRequired,
    onSelectBadgeChange: PropTypes.func,
    layout: PropTypes.string,
    isOwner: PropTypes.bool
  }

  constructor(props){
    super(props);

    this.state = {
      isActionDropdownOpen: false
    };

    this.toggleActionDropdown = this.toggleActionDropdown.bind(this);
    this.selectAction = this.selectAction.bind(this);
  }

  toggleActionDropdown(){
    const { isActionDropdownOpen } = this.state;

    this.setState({
      isActionDropdownOpen: !isActionDropdownOpen
    });
  }

  selectAction(event){
    const { badge, badgeAction } = this.props;

    badgeAction(event, badge);
    this.toggleActionDropdown();
  }

  render(){
    const { isActionDropdownOpen } = this.state;
    const { layout, badge, modal, selectedBadges, onSelectBadgeChange, isOwner } = this.props;
    const { id, url, name, unit, fileCount, isClaimed, endorsements, skillsCount, issued, acknowlegements, personalStatements } = badge;

    const selectedCheckboxes = selectedBadges.map(badge => badge.id);
    const isChecked = selectedCheckboxes.includes(id) && modal === null;
    const confirmedEndorsements = ! _isUndefined(endorsements) ? endorsements.filter(endorsement => endorsement.endorsement_date !== null) : [];

    const tileContainerClass = classNames('list-grid-item',{
      'col-xl-3 col-lg-4 col-md-6 col-sm-12': layout === 'grid' || !layout,
      'col-sm-12': layout === 'list'
    });

    const tileClassName = classNames('badge-item detail-view ', {
      'inactive': !isClaimed,
      'selected': isChecked,
      'grid': layout === 'grid',
      'list': layout === 'list'
    });

    const badgeContentWrapper = classNames('badge-col-left col-sm-12', {
      'col-md-5 col-lg-4 col-xl-3': layout === 'list'
    });

    const issuedDate = issued.issuedAt && moment(issued.issuedAt.date).format('MM/DD/YYYY');
    const badgeALT = `Badge: ${unit.code} - ${unit.title} logo`;
    const badgeALTEndorsed = `Badge Endorsed: ${unit.code} - ${unit.title}`;
    const badgeALTClaimed = `Badge Claimed: ${unit.code} - ${unit.title} logo`;

    return (
      <div className={tileContainerClass}>
        <div className={tileClassName}>
          <div className="row">
            <div className={badgeContentWrapper}>
              <div className="badge-content">
                <div className="badge-action-bar">
                  {isClaimed && isOwner &&
                    <div>
                      <input
                        type="checkbox"
                        value={id}
                        checked={isChecked}
                        onChange={onSelectBadgeChange}
                        aria-label="badge select"
                        className="badge-select float-xs-left custom-badge-select"/>
                    </div>}

                  {isClaimed && isOwner &&
                    <Dropdown
                      className="link-menu dropdown float-right"
                      isOpen={isActionDropdownOpen}
                      toggle={this.toggleActionDropdown}>
                      <DropdownToggle caret className="btn-sm">
                        <span className="fa fa-cog"/><span className="sr-only">Badge Dropdown</span>
                      </DropdownToggle>
                      <DropdownMenu right>
                        <a
                          className="dropdown-item"
                          data-modal-name="fileModal"
                          onClick={this.selectAction}>
                          Link Portfolio File(s)
                        </a>
                        <a
                          className="dropdown-item"
                          data-modal-name="endorsementModal"
                          onClick={this.selectAction}>
                          Seek Endorsements
                        </a>
                        <a
                          className="dropdown-item"
                          data-modal-name="skillsModal"
                          onClick={this.selectAction}>
                          Link to My Skills
                        </a>
                      </DropdownMenu>
                    </Dropdown>}
                </div>

                <div className="badge-information">
                  {isClaimed &&
                    <Link
                      style={{pointerEvents: issued.visibility ? 'auto' : 'none'}}
                      to={`/skill-badges/badge-details/${issued.id}`}>
                      <div className="image image-relative">
                        <img
                          src={url}
                          alt={badgeALTClaimed}
                          height="140"
                          width="140"/>
                        {confirmedEndorsements.length > 0 &&
                          <img
                            className="badge-overlay-image"
                            src={endorsedLogo}
                            alt={badgeALTEndorsed}/>}
                      </div>
                    </Link>}

                  {!isClaimed &&
                    <div className="image">
                      <img
                        src={url}
                        alt={badgeALT}
                        height="140"
                        width="140"/>
                    </div>}

                  <div className="badge-details">
                    <h3 className="mb-0 mt-1 portfolio-title">{name}</h3>
                    {issuedDate ?
                      <span className="badge-date small">{issuedDate}</span> :
                      <span className="badge-date small" style={{padding: 10}}/>}
                  </div>
                </div>
                <div className="badge-stats">
                  <div className="stat-details">
                    <div className="float-left">
                      <i className="fa fa-briefcase"/>
                      <span className="stat-name">Portfolio Files</span>
                    </div>
                    <div className="float-right">
                      <p className="stat-number">{fileCount}</p>
                    </div>
                  </div>
                  <Label className="pending-reviews">
                    {'Pending Review'}
                  </Label>
                  <BadgeRatings badge={badge} />
                  <div className="stat-details">
                    <div className="float-left">
                      <i className="fa fa-check-circle"/>
                      <span className="stat-name">Endorsements</span>
                    </div>
                    <div className="float-right">
                      <p className="stat-number">{confirmedEndorsements.length}</p>
                    </div>
                  </div>
                  <div className="stat-details mb-0">
                    <div className="float-left">
                      <i className="fa fa-star"/>
                      <span className="stat-name">Skills Supported</span>
                    </div>
                    <div className="float-right">
                      <p className="stat-number">{skillsCount}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {layout === 'list' &&
              <div className="badge-col-right col-sm-12 col-md-7 col-lg-8 col-xl-9">
                <div className="badge-list-details mySkillsContainerStyle BadgeCardScore-style">
                  {isClaimed ?
                    <UnitBadgeAbilities acknowledgements={acknowlegements}/> :
                    <div className="section-badge">
                      <h6>Claimed Abilities</h6>
                      <div className="section-badge-content">
                        <p className="mb-0">You haven't claimed this badge yet.</p>
                      </div>
                    </div>}

                  <div className="section-badge">
                    <h6>Question Responses</h6>

                    {!isClaimed &&
                      <div className="section-badge-content">
                        <p className="mb-0">Your personal statement will display here when you claim this badge.</p>
                      </div>}

                    {personalStatements.length > 0 && isClaimed &&
                      <div className="section-badge-content">
                        <div className="ql-editor no-padding-in-quill">
                          <CpHtmlParser htmlString={personalStatements[0].statement}/>
                        </div>
                      </div>}
                  </div>
                  <div className="section-badge">
                    <div className="pt-0 section-badge-content">
                      {isClaimed ?
                        <Link
                          to={`/skill-badges/badge-details/${issued.id}`}
                          className="btn btn-primary mt-4">View More
                        </Link> :
                        <button disabled className="btn btn-primary mt-4">
                          View More
                        </button>}

                    </div>
                  </div>
                </div>
              </div>}
          </div>
        </div>
      </div>
    );
  }
}

export default UnitBadgeTile;
