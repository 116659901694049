import { createSelector } from 'reselect';

const selectPeers = state => state.peers;

export const selectPeersList = createSelector(
  [selectPeers],
  peers => peers.data
);

export const selectFormattedPeers = createSelector(
  [selectPeersList],
  peers =>
    peers.map(
      peer => ({
        label: peer.name,
        value: peer.id
      })
    )
);

export const selectPeersPageCount = createSelector(
  [selectPeers],
  peers => peers.pageCount
);
