import React, { Component } from 'react';

import { withHooks } from '../../utils/withHooks';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import modules from './global-competence-modules';

class ModuleDetails extends Component {
  static propTypes = {
    params: PropTypes.object.isRequired
  }

  constructor(props, context) {
    super(props, context);
  }

  render() {
    const { slug } = this.props.params;
    const module = modules.find(module => module.id === slug);
    const { title, url } = module;

    return (
      <div className="general-interior">
        <Helmet title={`Global Competence Modules: ${title}`}/>
        <div className="content subnav essential">
          <div className="bread">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/resources">Career Resources</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to="/resources/global-competence-modules">
                  Global Competence Modules
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {title}
              </li>
            </ol>
          </div>
        </div>

        <div className="content targeted-resume">
          <div id="prep-videos" className="row">
            <div className="col-lg-12">
              <div className="video-link">
                <iframe width="560" height="315" src={`${url}?rel=0&amp;showinfo=0`} frameBorder="0" allowFullScreen/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withHooks(ModuleDetails);
