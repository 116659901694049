import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { findDOMNode } from 'react-dom';
import { toastr } from 'react-redux-toastr';

import { Prompt } from 'react-router-dom';

import { withHooks } from '../../utils/withHooks';

import * as messagesActions from '../../redux/actions/messages';
import * as profileActions from '../../redux/actions/profile';
import * as messageRepliesActions from '../../redux/actions/message-replies';
import * as authenticationActions from '../../redux/actions/authentication';
import * as routerActions from '../../redux/actions/router';

import _isEmpty from 'lodash/isEmpty';

import ParticipantsDetailsModal from '../../components/messages/ParticipantsDetailsModal';
import MessageDetails from '../../components/messages/MessageDetails';
import DeleteMessageConfirmModal from '../../components/messages/DeleteMessageConfirmModal';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

class MessageDetailsHandler extends Component {
  static propTypes = {
    actions: PropTypes.object,
    messages: PropTypes.object,
    userId: PropTypes.string,
    isReplying: PropTypes.bool,
    isDeleting: PropTypes.object,
    params: PropTypes.object.isRequired,
    messageReplies: PropTypes.object,
    isFetchingReplies: PropTypes.bool,
    isMobile: PropTypes.bool,
    replies: PropTypes.array,
    currentProfile: PropTypes.object,
    unitThread: PropTypes.object,
    route: PropTypes.object,
    router: PropTypes.object,
    storedAction: PropTypes.string,
    isHookedToARoute: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      nextLocation: null,
      showCustomDialog: false,
      attachment: {},
      message: '',
      showDeleteModal: false,
      showUsersDetailsModal: false,
      isUserListTooltipOpen: false
    };

    this.onChangeMessage = this.onChangeMessage.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSelectFile = this.onSelectFile.bind(this);
    this.onRemoveAttachment = this.onRemoveAttachment.bind(this);
    this.onDeleteMessage = this.onDeleteMessage.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.toggleUserListTooltip = this.toggleUserListTooltip.bind(this);
    this.routerWillLeave = this.routerWillLeave.bind(this);
    this.toggleUserDetailsModal = this.toggleUserDetailsModal.bind(this);
    this.closeCustomDialog = this.closeCustomDialog.bind(this);
    this.onConfirmLeaveRoute = this.onConfirmLeaveRoute.bind(this);
    this.setMessageContentContainerRef = this.setMessageContentContainerRef.bind(this);
  }

  componentDidMount(){
    const { params, actions } = this.props;

    actions.initializeMessageRepliesStore();
    actions.getUnitThreadDetails(params.slug);
    actions.fetchMessageReplies(params.slug);

    const node = findDOMNode(this.messageDetailsDiv);

    if(node)
      node.addEventListener('scroll', this.handleScroll);
  }

  componentWillReceiveProps(nextProps){
    const { replies, isReplying, params, actions, unitThread } = nextProps;

    if(params !== this.props.params || (replies !== this.props.replies && !isReplying)){
      this.setState({
        message: '',
        attachment: {}
      });
    }

    if(params !== this.props.params){
      actions.initializeMessageRepliesStore();
      actions.getUnitThreadDetails(params.slug);
      actions.fetchMessageReplies(params.slug);
    }

    if(unitThread !== this.props.unitThread && !unitThread.isRequestingThread
      && Object.keys(unitThread.thread).length > 0 && unitThread.thread.readAt === null) {
      actions.setAsRead(unitThread.thread.id);
      actions.decrementUnreadCount();
    }
  }

  componentDidUpdate(prevProps){
    const { message, attachment } = this.state;
    const { replies, isFetchingReplies, isHookedToARoute, actions } = this.props;
    const { replies: prevReplies } = prevProps;
    const isLoadedInitially = replies !== prevReplies && prevReplies.length === 0 && !isFetchingReplies;
    if(this.messageDetailsDiv && isLoadedInitially){
      this.messageDetailsDiv.scrollTop = this.messageDetailsDiv.scrollHeight;
    }

    if((message !== '' || !_isEmpty(attachment)) && !isHookedToARoute.status ){
      return actions.setRouterHookedToARoute('messages');
    }

    if((message === '' && _isEmpty(attachment)) && isHookedToARoute.status){
      actions.removeRouterHookedToARoute();
    }
  }

  componentWillUnmount(){
    const node = findDOMNode(this.messageDetailsDiv);

    if(node)
      node.removeEventListener('scroll', this.handleScroll);

    if (this.state.removeLeaveHook)
      this.state.removeLeaveHook();

    this.props.actions.removeRouterHookedToARoute();
  }

  routerWillLeave(nextLocation) {
    const { message, attachment, nextLocation: nextLocationInState } = this.state;
    const { isDeleting } = this.props;

    if ((message !== '' || !_isEmpty(attachment)) && !isDeleting.status && nextLocationInState === null) {

      this.setState({
        showCustomDialog: true,
        nextLocation: nextLocation.pathname
      });
      return false;
    }else{
      this.setState({
        nextLocation: null
      });
      return true;
    }
  }

  closeCustomDialog(){
    this.setState({
      showCustomDialog: false,
      nextLocation: null
    }, () => {
      if(this.props.storedAction !== null){
        this.props.actions.clearAllActionsStored();
      }
    });
  }

  onConfirmLeaveRoute(){
    const { actions, storedAction } = this.props;
    const { nextLocation } = this.state;
    this.setState({
      showCustomDialog: false
    },() => {
      actions.removeRouterHookedToARoute();
      if(storedAction === 'userLogout'){
        actions.userLogout();
      }
      actions.redirect(nextLocation);
      actions.clearAllActionsStored();
    });
  }

  handleScroll(){
    const { messageReplies, actions, isFetchingReplies, unitThread } = this.props;
    const { page, page_count } = messageReplies.paginationData;
    const topReached = this.messageDetailsDiv.scrollTop === 0;

    if(topReached && page < page_count && !isFetchingReplies){
      return actions.fetchMessageReplies(unitThread.thread.id, page+1);
    }

  }


  onChangeMessage(event){
    const { value } = event.target;

    this.setState({
      message: value
    });
  }

  onSelectFile(files) {

    if (files.length === 0 || Object.keys(files[0]).length === 0)
      return ;

    const file = files[0];

    this.setState({
      attachment: file
    });
  }

  onRemoveAttachment(){
    this.setState({
      attachment: {}
    });
  }

  onSubmit(){
    const { message, attachment } = this.state;
    const { actions, unitThread } = this.props;

    if(message === ''){
      return toastr.error('', 'You must draft a message to reply.');
    }

    const data = Object.keys(attachment).length > 0 ?
      this.createFormData(unitThread.thread.id, message, attachment):
      {
        'thread': unitThread.thread.id,
        'body': message
      };

    actions.replyToAThread(data).
      then(() => {
        this.messageDetailsDiv.scrollTop = this.messageDetailsDiv.scrollHeight;
      });
  }

  createFormData(threadId, body, attachment) {
    let formData = new FormData();

    formData.append('thread', threadId);
    formData.append('body', body);
    formData.append('file', attachment);

    return formData;
  }



  onDeleteMessage(){
    this.setState({
      showDeleteModal: !this.state.showDeleteModal
    });

  }

  onConfirmDelete(){
    const { actions, unitThread } = this.props;

    actions.deleteMessage(unitThread.thread.id).
      then(() => this.onDeleteMessage());
  }

  setMessageContentContainerRef(div) {
    if(div)
      this.messageDetailsDiv = div;
  }

  toggleUserListTooltip(){
    this.setState({
      isUserListTooltipOpen: !this.state.isUserListTooltipOpen
    });
  }

  toggleUserDetailsModal(){
    this.setState({
      showUsersDetailsModal: !this.state.showUsersDetailsModal
    });
  }

  render() {
    const { message, attachment, showDeleteModal, isUserListTooltipOpen, showUsersDetailsModal, showCustomDialog } = this.state;
    const {
      messages,
      isReplying,
      isDeleting,
      isMobile,
      replies,
      isFetchingReplies,
      userId,
      unitThread,
      currentProfile,
      isHookedToARoute
    } = this.props;

    return(
      <div id="messagingDetailView" className="col-lg-8">
        <Prompt
          when={isHookedToARoute.status}
          message="It looks like you have an unsent draft message. Do you want to leave without sending?"/>
        <MessageDetails
          query={messages.query}
          messageContentContainerRef={this.setMessageContentContainerRef}
          isRequesting={isFetchingReplies}
          isReplying={isReplying}
          replies={replies}
          messageText={message}
          attachment={attachment}
          onSelectFile={this.onSelectFile}
          onRemoveAttachment={this.onRemoveAttachment}
          onChangeMessage={this.onChangeMessage}
          onSubmit={this.onSubmit}
          onDeleteMessage={this.onDeleteMessage}
          isDeleting={isDeleting}
          isMobile={isMobile}
          userId={userId}
          currentProfile={currentProfile}
          unitThread={unitThread}
          isUserListTooltipOpen={isUserListTooltipOpen}
          toggleUserListTooltip={this.toggleUserListTooltip}
          toggleUserDetailsModal={this.toggleUserDetailsModal}/>

        {
          showDeleteModal &&
            <DeleteMessageConfirmModal
              isOpen={showDeleteModal}
              onCancel={this.onDeleteMessage}
              onConfirm={this.onConfirmDelete}
              isDeleting={isDeleting}/>
        }

        {
          showUsersDetailsModal &&
            <ParticipantsDetailsModal
              currentProfile={currentProfile}
              isOpen={showUsersDetailsModal}
              participants={unitThread.thread.participants}
              onClose={this.toggleUserDetailsModal}/>
        }

        {
          showCustomDialog &&
            <Modal
              size="lg"
              className="modal-margin-top"
              isOpen={showCustomDialog}
              backdrop="static"
              toggle={this.closeCustomDialog}>
              <ModalHeader
                className="no-border"
                toggle={this.closeCustomDialog}>Leave this page?</ModalHeader>
              <ModalBody>
                <p>It looks like you have an unsent draft message. Do you want to leave without sending?</p>
              </ModalBody>
              <ModalFooter className="no-border">
                <button
                  onClick={this.closeCustomDialog}
                  className="btn btn-secondary float-left">
                  Stay
                </button>

                <button
                  onClick={this.onConfirmLeaveRoute}
                  className="btn btn-primary">
                  Yes, Leave
                </button>
              </ModalFooter>
            </Modal>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    messages: state.messages,
    messageReplies: state.messageReplies,
    unitThread: state.messageReplies.thread,
    replies: state.messageReplies.replies,
    isFetchingReplies: state.messageReplies.isRequesting,
    isDeleting: state.messages.isDeleting,
    isReplying: state.messageReplies.isSubmitting,
    isMobile: state.components.isMobile,
    userId: state.auth.data.currentUser.id,
    currentProfile: state.profile.data,
    isHookedToARoute: state.router.isHookedToARoute,
    storedAction: state.router.storedAction
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({},
    messagesActions,
    messageRepliesActions,
    profileActions,
    authenticationActions,
    routerActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(MessageDetailsHandler));
