import React, { Component } from 'react';
import Loading from '../../components/common/Loading';

class LoadingView extends Component {
  render() {
    return (
      <div className="interior info">
        <div className="loading-view">
          <Loading/>
        </div>
      </div>

    );
  }
}

export default LoadingView;
