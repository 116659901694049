import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withHooks } from '../../utils/withHooks';
import Validator from '../../validator';

import * as badgeAssertionActions from '../../redux/actions/badge-assertion';
import * as badgeEndorsementActions from '../../redux/actions/badge-endorsement';
import * as componentActions from '../../redux/actions/components';
import * as filesActions from '../../redux/actions/files';

import { Helmet } from 'react-helmet';

import Loading from '../../components/common/Loading';
import BadgeContent from '../../components/skill-badges/BadgeContent';
import EndorseModal from '../../components/skill-badges/EndorseModal';
import ShareBadgeDropdown from '../../components/skill-badges/ShareBadgeDropdown';
import ShareBadgeModal from './ShareBadgeModal';
import FileDetailsModal from '../portfolio/FileDetailsModal';
import ReviewsBar from '../../components/myskills/ReviewsBar';
import { Link } from 'react-router-dom';

class SkillBadgeUnit extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    badgeAssertion: PropTypes.object.isRequired,
    modal: PropTypes.string,
    endorserData: PropTypes.object,
    params: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    badgeEndorsement: PropTypes.object.isRequired,
  }

  constructor(props, context) {
    super(props, context);

    this.state ={
      errors: {},
      showShareDropdown: false,
      skillsObserved: false,
      fileId : null,
      endorserData: Object.assign({}, this.props.endorserData),
      reviewsBar: false,
    };

    this.onClickEndorse = this.onClickEndorse.bind(this);
    this.onShareClick = this.onShareClick.bind(this);
    this.onShareToCp = this.onShareToCp.bind(this);
    this.onCancelEndorse = this.onCancelEndorse.bind(this);
    this.onChangeEndorserData = this.onChangeEndorserData.bind(this);
    this.onToggleSkillsObserved = this.onToggleSkillsObserved.bind(this);
    this.onSubmitEndorsementConfirmation = this.onSubmitEndorsementConfirmation.bind(this);
    this.onViewPortfolio = this.onViewPortfolio.bind(this);
    this.onToggleReviewBarModal = this.onToggleReviewBarModal.bind(this);
    this.onLinkedSkill = this.onLinkedSkill.bind(this);
  }

  componentWillMount() {
    const { params, actions, isAuthenticated } = this.props;

    if (params.slug) {
      actions.requestBadgeAssertion(params.slug, isAuthenticated);
    }
  }

  componentWillReceiveProps(nextProps){
    const {
      actions,
      isAuthenticated,
      params: { slug },
      badgeEndorsement: { data }
    } = this.props;

    const {
      params: { slug: nextSlug },
      badgeEndorsement: { isSubmitting, data: nextData }
    } = nextProps;

    if ((!isSubmitting && data !== nextData && Object.keys(nextData).length > 0) || (slug !== nextSlug)) {
      actions.requestBadgeAssertion(nextSlug, isAuthenticated);
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;

    actions.clearAssertionState();
    localStorage.removeItem('mySkillsParams');
  }

  onLinkedSkill() {
    const { params, actions, isAuthenticated } = this.props;
    actions.requestBadgeAssertion(params.slug, isAuthenticated);
  }

  onShareClick(){
    const { showShareDropdown } = this.state;
    this.setState({
      showShareDropdown: !showShareDropdown
    });
  }

  onShareToCp(){
    this.setState({
      showShareDropdown: false
    },() => this.props.actions.openModal('share-badge-to-cp'));
  }

  isValid(field = null) {
    const rules = {
      name: ['required'],
      email: ['required', 'email'],
      position: ['required'],
      organization: ['required'],
    };
    const { endorserData, errors: prevErrors} = this.state;
    const validate = Validator.createValidator(rules, endorserData, field);
    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  onChangeEndorserData(event){
    const { name, value } = event.target;
    const { endorserData } = this.state;

    endorserData[name] = value;

    this.setState({ endorserData }, () => this.isValid(name));
  }

  onToggleSkillsObserved(){
    const { skillsObserved } = this.state;

    this.setState({
      skillsObserved: !skillsObserved
    });
  }

  onClickEndorse(){
    this.props.actions.openModal('endorseModal');
  }

  onSubmitEndorsementConfirmation(){
    const { endorserData } = this.state;
    const { params, actions } = this.props;

    if (! this.isValid()) {
      return ;
    }

    const data = Object.assign({}, endorserData,
      {
        'badgeIssuedId': params.slug
      }
    );

    actions.endorseBadge(data);
  }

  onCancelEndorse(){
    const { actions, badgeEndorsement } = this.props;
    const hasCompletedEndorsement = !badgeEndorsement.isSubmitting && Object.keys(badgeEndorsement.data).length > 0;

    if( hasCompletedEndorsement ){
      const newEndorserData = {
        name: '',
        position: '',
        organization: '',
        email: ''
      };

      this.setState({
        skillsObserved: false,
        endorserData: newEndorserData
      }, () => actions.clearEndorsementState());
    }
    actions.closeModal();
  }

  onViewPortfolio(id){
    return () => {
      const { actions } = this.props;
      this.setState({
        fileId: id
      }, () => actions.openModal('view-portfolio-modal-badge-details'));
    };
  }

  onToggleReviewBarModal() {
    const { reviewsBar } = this.state;
    this.setState({
      reviewsBar: !reviewsBar,
    });
  }

  goBack() {
    window.history.back();
  }

  render() {
    const { errors, endorserData, skillsObserved, showShareDropdown, fileId } = this.state;
    const { badgeAssertion, currentUser, modal, isAuthenticated, badgeEndorsement } = this.props;
    const { isRequesting, data:assertionData } = badgeAssertion;
    const isLoadingContents = isRequesting || Object.keys(assertionData).length === 0;
    const isEndorseModalOpen = modal && modal === 'endorseModal';

    const badgeName = isRequesting || Object.keys(assertionData).length === 0 ? '...' : assertionData.badge.name;
    const hasCompletedEndorsement = !badgeEndorsement.isSubmitting && Object.keys(badgeEndorsement.data).length > 0;
    const isShareBadgeModalOpen = modal && modal === 'share-badge-to-cp';
    const isViewPortfolioOpen = modal && modal === 'view-portfolio-modal-badge-details' && fileId !== null;

    const skillsPageLocation = localStorage.getItem('mySkillsParams') ? { ...JSON.parse(localStorage.getItem('mySkillsParams')), hash: '' } : '/myskills';

    return (
      <div className="general-interior">
        <Helmet title={`Skill Badges - ${badgeName}`}/>

        <div className="container">
          <div className="row">
            {isAuthenticated &&
              <div className="col-lg-12">
                <div className="content subnav essential">
                  <div className="bread">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link
                          to={skillsPageLocation}
                          className="orange clickable">
                          Skill Badges
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        {assertionData.badge ? assertionData.badge.name :'...'}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>}

          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="content badges badge-relative p-0">
                {
                  isEndorseModalOpen &&
                    <EndorseModal
                      isAuthenticated={isAuthenticated}
                      isSubmitting={badgeEndorsement.isSubmitting}
                      hasCompletedEndorsement={hasCompletedEndorsement}
                      badge={assertionData.badge}
                      unit={assertionData.unit}
                      issued={assertionData.issued}
                      errors={errors}
                      skillsObserved={skillsObserved}
                      onChangeEndorserData={this.onChangeEndorserData}
                      endorserData={endorserData}
                      user={assertionData.user}
                      onSubmitEndorsementConfirmation={this.onSubmitEndorsementConfirmation}
                      onToggleSkillsObserved={this.onToggleSkillsObserved}
                      onCancelEndorse={this.onCancelEndorse}/>
                }

                {
                  isViewPortfolioOpen &&
                    <FileDetailsModal
                      fileId={fileId}
                      isOpen={isViewPortfolioOpen}/>
                }

                {
                  showShareDropdown &&
                    <ShareBadgeDropdown
                      showShareDropdown={showShareDropdown}
                      assertionData={assertionData}
                      onShareClick={this.onShareClick}
                      onShareToCp={this.onShareToCp}/>
                }

                {isLoadingContents ?
                  <div className="loading-container">
                    <Loading />
                  </div> :
                  <BadgeContent
                    unit={assertionData.unit}
                    onShareClick={this.onShareClick}
                    issued={assertionData.issued}
                    currentUser={currentUser}
                    user={assertionData.user}
                    files={assertionData.files}
                    assertionBadge={assertionData.badge}
                    statements={assertionData.statement}
                    endorsements={assertionData?.issued?.endorsements}
                    onClickEndorse={this.onClickEndorse}
                    onViewPortfolio={this.onViewPortfolio}
                    acknowledgements={assertionData.acknowledgement}
                    showReviewModal = {this.onToggleReviewBarModal}
                    onLinkedSkill={this.onLinkedSkill}/>}
              </div>
            </div>
          </div>
        </div>

        {isShareBadgeModalOpen &&
          <ShareBadgeModal
            isOpen={isShareBadgeModalOpen}
            badgeAssertion={assertionData}/>}
        {this.state.reviewsBar && <ReviewsBar
          resourceId={assertionData.issued.id}
          isOpen={this.state.reviewsBar}
          toggleModal={this.onToggleReviewBarModal}/>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let endorserData = {
    name: '',
    position: '',
    organization: '',
    email: ''
  };

  return {
    endorserData,
    files: state.files,
    isRequestingFiles : state.files.isRequesting,
    profile: state.profile.data,
    modal: state.components.modal,
    isAuthenticated: state.auth.isAuthenticated,
    badgeAssertion : state.badgeAssertion,
    currentUser: state.auth.data.currentUser,
    badgeEndorsement: state.badgeEndorsement,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({},
    badgeEndorsementActions,
    badgeAssertionActions,
    componentActions,
    filesActions,
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withHooks(SkillBadgeUnit));
