import React from 'react';

import PropTypes from 'prop-types';

import SelectInput from '../common/SelectInput';
import { SimpleToolTipWrapper } from '../common/ToolTipWrapper';

const FeedbackFrequency = ({
  changePrivacySetting,
  error,
  options,
  isUpdating,
  name,
  value,
}) => {
  return (
    <>
      <div className="label-button">
        <label className="padright10">
          How many feedback requests would you like to receive weekly?
        </label>{' '}
        <SimpleToolTipWrapper
          id={'pause-feedback-requests'}
          toolTipContent={
            <div className="inside-tooltip">
              So that you can be responsive to feedback requests, set a limit
              for how many requests you want to receive weekly
            </div>
          }>
          <i className="fa fa-info-circle" />
        </SimpleToolTipWrapper>
      </div>
      <div className="form-group">
        <div className="input-group set">
          <div style={{ position: 'relative', width: 'calc(100% - 1px)' }}>
            <SelectInput
              id="privacySettings"
              name={name || 'privacySettings'}
              value={value || 3}
              onChange={changePrivacySetting}
              options={options}
              error={error}
              className="form-control input-styles-owht"
              disabled={isUpdating}/>
          </div>
        </div>
      </div>
    </>
  );
};

FeedbackFrequency.propTypes = {
  changePrivacySetting: PropTypes.func,
  error: PropTypes.string,
  options: PropTypes.array,
  isUpdating: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.number,
};

export default FeedbackFrequency;