import PropTypes from 'prop-types';
import React from 'react';

import Search from '../search/Search';
import StatusCell from './StatusCell';
import DateSentCell from './DateSentCell';
import InviteLinkCell from './InviteLinkCell';
import TableLoading from '../../../common/TableLoading';
import PaginationHandler from '../../../common/PaginationHandler.js';
import { Row, Col } from 'reactstrap';

const Table = ({
  onCopy,
  onNext,
  onPrev,
  onSelectPage,
  invitations,
  institutionId,
  onSortTable,
  onChangeSearchQuery
}) => {
  return (
    <div style={{position: 'relative'}}>
      <Row className="mb-1">
        <Col sm="8">
          <Search
            table="invitations"
            institutionId={institutionId}
            searchQuery={invitations.filters.searchQuery}
            isRequesting={invitations.isRequesting}
            onChangeSearchQuery={onChangeSearchQuery}/>
        </Col>
      </Row>

      <table className="cp-table mt-1 table table-responsive-lg">
        <thead>
          <tr className="cp-table-header">
            <th
              style={{width: '25%'}}
              className="cursor-pointer"
              data-column-name="email"
              onClick={onSortTable}>
              Email
            </th>

            <th
              style={{width: 100, textAlign: 'center'}}
              className="cursor-pointer"
              data-column-name="createdAt"
              onClick={onSortTable}>
              Date Sent
            </th>

            <th
              style={{width: 100, textAlign: 'center'}}
              className="cursor-pointer"
              data-column-name="group"
              onClick={onSortTable}>
              Group
            </th>

            <th
              style={{width: 100, textAlign: 'center'}}
              data-column-name="">
              Group Code
            </th>

            <th
              style={{width: 100, textAlign: 'center'}}
              className="cursor-pointer"
              data-column-name="status"
              onClick={onSortTable}>
              Status
            </th>

            <th style={{width: 100}}>Invite Link</th>
          </tr>
        </thead>

        <tbody>
          {(invitations.invites.length === 0) &&
            <tr><td colSpan="6" style={{textAlign: 'center'}}>{'Sorry, we couldn\'t find anything!'}</td></tr>}

          {(invitations.invites.length !== 0) && invitations.invites.map(invite => (
            <tr key={invite.id}>
              <td>{invite.email}</td>
              <td style={{textAlign: 'center'}}>
                <DateSentCell invite={invite}/>
              </td>
              <td style={{textAlign: 'center'}}>
                {invite.groupName}
              </td>
              <td>{invite.inviteCode}</td>
              <td><StatusCell invite={invite}/></td>
              <td>
                <InviteLinkCell invite={invite} onCopy={onCopy}/>
              </td>
            </tr>))}
        </tbody>

        <tfoot>
          <tr>
            <th style={{textAlign: 'center'}} colSpan="6">
              <PaginationHandler
                onNext={onNext}
                onPrev={onPrev}
                page={invitations.page}
                page_count={invitations.page_count}
                onSelectPage={onSelectPage}/>
            </th>
          </tr>
        </tfoot>
      </table>

      {invitations.isRequesting && <TableLoading/>}
    </div>
  );
};

Table.propTypes = {
  onCopy: PropTypes.func.isRequired,
  invitations: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  onSelectPage: PropTypes.func.isRequired,
  institutionId: PropTypes.string.isRequired,
  searchQuery: PropTypes.string,
  onSortTable: PropTypes.func.isRequired,
  onChangeSearchQuery: PropTypes.func.isRequired
};

export default Table;
