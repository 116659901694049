import initialState from './initialState';

import {
  GET_ALL_TESTIMONIALS_FAILURE,
  GET_ALL_TESTIMONIALS_SUCCESS,
  GET_ALL_TESTIMONIALS_REQUEST,
  REQUEST_REVISE_TESTIMONIALS_REQUEST,
  REQUEST_REVISE_TESTIMONIALS_SUCCESS,
  REQUEST_REVISE_TESTIMONIALS_FAILURE,
  GET_TESTIMONIAL_REQUEST,
  GET_TESTIMONIAL_SUCCESS,
  GET_TESTIMONIAL_FAILURE,
  SET_REVISE_TESTIMONIAL_REQUEST,
  SET_REVISE_TESTIMONIAL_SUCCESS,
  SET_REVISE_TESTIMONIAL_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.testimonials, action) {
  switch (action.type) {
  case GET_ALL_TESTIMONIALS_SUCCESS:
    return Object.assign({}, state, {
      isRequesting: false,
      data: action?.data?._embedded.testimonial,
      page: action?.data?.page,
      page_count: action?.data?.page_count,
      page_size: action?.data?.page_size,
      total_items: action?.data?.total_items,
    });
  case GET_ALL_TESTIMONIALS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });
  case GET_ALL_TESTIMONIALS_FAILURE:
    return Object.assign({}, { isRequesting: false, datA: [] });
  
  case REQUEST_REVISE_TESTIMONIALS_SUCCESS:
    return {...state, 
      isRequesting: false,     
      requestMessage: action.data,
    };
  case REQUEST_REVISE_TESTIMONIALS_REQUEST:
    return {...state, isRequesting: true };
  case REQUEST_REVISE_TESTIMONIALS_FAILURE:
    return Object.assign({}, { isRequesting: false });  
 
  case GET_TESTIMONIAL_REQUEST:
    return {...state, isRequesting: true };  
  case GET_TESTIMONIAL_SUCCESS:
    return {...state,
      isRequesting: false,  
      detail: action?.data?.detail,     
    }; 
  case GET_TESTIMONIAL_FAILURE:
    return Object.assign({}, { isRequesting: false });  

  case SET_REVISE_TESTIMONIAL_REQUEST:
    return {...state, 
      isRequesting: false,     
    };
  case SET_REVISE_TESTIMONIAL_SUCCESS:
    return {...state, isRequesting: true };
  case SET_REVISE_TESTIMONIAL_FAILURE:
    return Object.assign({}, { isRequesting: false });
    
  default:
    return state;
  }
}
