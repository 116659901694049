import initialState from './initialState';

import {
  INITIALIZE_REFLECTION_STORE,
  REFLECTIONS_REQUEST,
  REFLECTIONS_SUCCESS,
  REFLECTIONS_FAILURE,
  CREATE_REFLECTION_REQUEST,
  CREATE_REFLECTION_SUCCESS,
  CREATE_REFLECTION_FAILURE,
  UPDATE_REFLECTION_REQUEST,
  UPDATE_REFLECTION_SUCCESS,
  UPDATE_REFLECTION_FAILURE,
  SHARE_REFLECTIONS_REQUEST,
  SHARE_REFLECTIONS_SUCCESS,
  SHARE_REFLECTIONS_FAILURE,
  DELETE_REFLECTION_REQUEST,
  DELETE_REFLECTION_SUCCESS,
  DELETE_REFLECTION_FAILURE,
  SET_REFLECTION_DELETE_ID,
  DOWNLOAD_REFLECTIONS_REQUEST,
  DOWNLOAD_REFLECTIONS_SUCCESS,
  DOWNLOAD_REFLECTIONS_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.reflections, action) {
  switch (action.type) {
  case INITIALIZE_REFLECTION_STORE:
    return Object.assign({}, state, initialState.reflections);

  case REFLECTIONS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case REFLECTIONS_SUCCESS:
    return Object.assign({}, state, {
      data: action.data._embedded.reflection,
      isRequesting: false,
    });

  case REFLECTIONS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case CREATE_REFLECTION_REQUEST:
    return Object.assign({}, state, { isSubmitting: true });

  case CREATE_REFLECTION_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data, Object.assign({}, action.data)],
      isSubmitting: false,
    });

  case CREATE_REFLECTION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case UPDATE_REFLECTION_REQUEST:
    return Object.assign({}, state, {
      isUpdating: true,
    });

  case UPDATE_REFLECTION_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.filter(
          reflection => reflection.id !== action.data.id
        ),
        Object.assign({}, action.data),
      ],
      isUpdating: false,
    });

  case SET_REFLECTION_DELETE_ID:
    return {
      ...state,
      deleteId: action.id,
    };

  case UPDATE_REFLECTION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: false,
    });

  case SHARE_REFLECTIONS_REQUEST:
    return Object.assign({}, state, {
      sharing: {
        isSharing: true,
        successfullyShared: false,
      },
    });

  case SHARE_REFLECTIONS_SUCCESS:
    return Object.assign({}, state, {
      sharing: {
        isSharing: false,
        successfullyShared: true,
      },
    });

  case SHARE_REFLECTIONS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      sharing: {
        isSharing: false,
        successfullyShared: false,
      },
    });

  case DELETE_REFLECTION_REQUEST:
    return {
      ...state,
      isDeleting: { status: true, id: action.id },
    };

  case DELETE_REFLECTION_SUCCESS:
    return {
      ...state,
      data: [
        ...state.data.filter(
          reflection => reflection.id !== state.isDeleting.id
        ),
      ],
      isDeleting: { status: false, id: null },
    };

  case DELETE_REFLECTION_FAILURE:
    return {
      ...state,
      error: action.error,
      isDeleting: { status: false, id: null },
    };

  case DOWNLOAD_REFLECTIONS_REQUEST:
    return Object.assign({}, state, {
      isDownloading: { status: true },
    });

  case DOWNLOAD_REFLECTIONS_SUCCESS:
    return Object.assign({}, state, {
      isDownloading: { status: false, data: action.data },
    });

  case DOWNLOAD_REFLECTIONS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDownloading: { status: false },
    });

  default:
    return state;
  }
}
