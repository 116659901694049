import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  SETTINGS_DATE_FORMAT,
  FEEDBACK_REQUESTED_DATE_FORMAT,
} from '../../../constants';

const RequestedResendComponent = (props) => {
  const rowDetails = props.rowData;
  return (
    <td>
      {rowDetails?.resendOn &&
        `${moment(rowDetails?.resendOn, SETTINGS_DATE_FORMAT).format(
          FEEDBACK_REQUESTED_DATE_FORMAT
        )}`}
    </td>
  );
};

RequestedResendComponent.propTypes = {
  rowData: PropTypes.object.isRequired,
};

export default RequestedResendComponent;