import React from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';
import { Row, Col, Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { getFeedbackRating } from '../../utils';
import { SETTINGS_DATE_FORMAT, FEEDBACK_REQUESTED_DATE_FORMAT } from '../../constants';
import StarRating from '../rating/StarRating';
import CloseIcon from '../../assets/images/close.svg';

function ViewAll({ isOpen, toggleModal, feedback, action }) {

  return (
    <Modal
      centered
      className="customLevelUpModal"
      isOpen={isOpen}
      toggle={toggleModal}>
      <div className="modal-header">
        <span className="modal-title">Feedback Given</span>
        <div
          onClick={toggleModal}
          className="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">
            <img src={CloseIcon} />
          </span>
        </div>
      </div>

      <ModalBody>
        <Row className="mb-3">
          <Col xs="5" className="d-flex align-items-center">
            <img
              className="feedbackAvatar"
              src={
                feedback.avatarURl
                  ? feedback.avatarURl
                  : require('../../assets/images/avatar.jpg')
              }/>
            <div className="d-inline-block ml-3">
              <span className="d-block font-weight-bold">
                {feedback.fullname}
              </span>
              <div className="d-flex">
                {feedback.isRevised >= 1 && (
                  <i className="fa fa-pencil writtenOn" />
                )}
                <span className="d-block font12">{`${moment(
                  feedback?.feedbackResponseDate?.date,
                  SETTINGS_DATE_FORMAT
                ).format(FEEDBACK_REQUESTED_DATE_FORMAT)}`}</span>
              </div>
            </div>
          </Col>
          <Col xs="7" className="d-flex align-items-center justify-content-end">
            {feedback.resourceType === 'badge' &&
              <img width="60"
                className="feedbackGivenSkill mr-3"
                src={get(feedback, 'resourceUrl')}/>}
            <div className="d-inline-block">
              <div>
                <span className="font-weight-bold">
                  {feedback.resourcename}
                </span>
              </div>
              <div>
                <StarRating rating={getFeedbackRating(feedback)}/>
              </div>
            </div>
          </Col>
        </Row>
        {feedback.questionResponseRating.overall && (
          <p className="font-weight-bold">
            Question Response Ratings: &nbsp;
            <span className="font-weight-normal">{`${feedback.questionResponseRating.overall}`}</span>
            <br />
            <span className="font-weight-normal mb-2">
              {`${feedback.questionResponseRating.multichoiceComments}`}
            </span>
            <br />
            <span className="font-weight-normal mb-2">{`${feedback.questionResponseRating.additionalComment}`}</span>
          </p>
        )}
        {feedback.portfolioEvidenceRating.overall && (
          <p className="font-weight-bold">
            Portfolio Evidence Ratings: &nbsp;
            <span className="font-weight-normal">{`${feedback.portfolioEvidenceRating.overall}`}</span>
            <br />
            <span className="font-weight-normal text-justify text-break">{`${feedback.portfolioEvidenceRating.multichoiceComments}`}</span>
            <br />
            <span className="font-weight-normal mb-2 text-break">{`${feedback.portfolioEvidenceRating.additionalComment}`}</span>
          </p>
        )}
      </ModalBody>
      <ModalFooter className="bordertop-0">
        {action !== 'received' && (
          <Link
            className="respondLink"
            to={`/givefeedback/${feedback.feedbackRequestId}?revise`}
            state={{
              name: feedback?.fullname,
            }}>
            <Button className="ml-1 sendButton">Revise</Button>
          </Link>
        )}
      </ModalFooter>
    </Modal>
  );
}

ViewAll.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  feedback: PropTypes.object.isRequired,
  action: PropTypes.string,
};

export default ViewAll;
