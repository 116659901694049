import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Popover, PopoverBody } from 'reactstrap';

import { isEmpty } from 'lodash';

class Praises extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      popoverOpen: false,
      currentTitle: '',
    };

    this.togglePopover = this.togglePopover.bind(this);
  }

  getPraiseDetails = () => {
    if (!this.props.userPraises) return null;
    return this.props.userPraises.map((userPraise) => {
      const avatarImage = userPraise.avatar && !isEmpty(userPraise.avatar) ? userPraise.avatar : require('../../assets/images/avatar.jpg');
      return (
        <div key={userPraise.name} className="d-flex justify-content-between mb-3">
          <div>
            <img src={avatarImage} className="topPraisesAvatar" />
            <span>{userPraise.name}</span>
          </div>
          <span>{userPraise.praiseCount}</span>
        </div>
      );
    });
  };

  togglePopover(event){
    const { target: { id: currentTitle } } = event;

    this.setState((state) => {
      return {
        popoverOpen: !state.popoverOpen,
        currentTitle,
      };
    });
  }

  generatePopOverBody(praiseCount) {
    if ( praiseCount > 0 ) {
      return (
        <>
          <div
            className="d-flex justify-content-between mb-3"
            style={{ color: '#858B94' }}>
            <span>Praise Given By</span>
            <span>Count</span>
          </div>
          <div className="toppraise-popover-width custom-scrollbar">
            {this.getPraiseDetails()}
          </div>
        </>
      );
    } else {
      return (
        <div className="toppraise-popover-width">
          <div
            className="d-flex justify-content-between mb-1"
            style={{ color: '#858B94' }}>
            <span>You haven’t received praise yet</span>
          </div>
        </div>
      );
    }
  }

  render() {
    const { praiseCount, title } = this.props;
    const { popoverOpen } = this.state;
    return (
      <div className="d-inline-block">
        <div
          onMouseLeave={this.togglePopover}
          onMouseOver={this.togglePopover}
          id={title}
          className="topPraisesCount">
          {praiseCount}
        </div>
        <Popover className="praises-popover" placement="bottom" isOpen={popoverOpen} target={title}>
          <PopoverBody>
            {this.generatePopOverBody(praiseCount)}
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}

Praises.propTypes = {
  praiseCount: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  userPraises: PropTypes.array,
};

export default Praises;