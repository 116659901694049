import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withHooks } from '../../utils/withHooks';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { toastr } from 'react-redux-toastr';
import { isUndefined } from 'lodash';

import Table from '../../components/admin/manage-organizations/invites/Table';

import * as organizationInvitationsActions from '../../redux/actions/organization-invitations';

class ManageInvites extends Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    invitations: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      sort: {
        column: '',
        asc: false
      }
    };

    this.onCopy = this.onCopy.bind(this);
    this.onNext = this.onNext.bind(this);
    this.onPrev = this.onPrev.bind(this);
    this.onSortTable = this.onSortTable.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
  }

  componentWillMount() {
    if (! isUndefined(this.props.params.id)) {
      const urlParamString = `institution=${this.props.params.id}`;

      this.props.actions.getInvitations(urlParamString);
    }
  }

  onCopy() {
    toastr.success('You successfully copied the invite link.');
  }

  onNext() {
    const { params, actions, invitations } = this.props;
    const { page, filters } = invitations;
    const { column, asc } = this.state.sort;
    let urlParamString = `institution=${params.id}&page=${page + 1}`;

    if (column.length !== 0) {
      const sortParam = asc ? 'asc' : 'desc';

      urlParamString = `${urlParamString}&sort=${column}:${sortParam}`;
    }

    const searchQuery = filters.searchQuery.trim();

    if (searchQuery.length !== 0) {
      urlParamString = `${urlParamString}&query=${searchQuery}`;
    }

    actions.getInvitations(urlParamString);
  }

  onPrev() {
    const { params, actions, invitations } = this.props;
    const { page, filters } = invitations;
    const { column, asc } = this.state.sort;
    let urlParamString = `institution=${params.id}&page=${page - 1}`;

    if (column.length !== 0) {
      const sortParam = asc ? 'asc' : 'desc';

      urlParamString = `${urlParamString}&sort=${column}:${sortParam}`;
    }

    const searchQuery = filters.searchQuery.trim();

    if (searchQuery.length !== 0) {
      urlParamString = `${urlParamString}&query=${searchQuery}`;
    }

    actions.getInvitations(urlParamString);
  }

  onSelectPage(page) {
    return () => {
      const { params, actions, invitations } = this.props;
      const { column, asc } = this.state.sort;
      let urlParamString = `institution=${params.id}&page=${page}`;

      if (column.length !== 0) {
        const sortParam = asc ? 'asc' : 'desc';

        urlParamString = `${urlParamString}&sort=${column}:${sortParam}`;
      }

      const searchQuery = invitations.filters.searchQuery.trim();

      if (searchQuery.length !== 0) {
        urlParamString = `${urlParamString}&query=${searchQuery}`;
      }

      actions.getInvitations(urlParamString);
    };
  }

  onSortTable(event) {
    const column = event.currentTarget.dataset['columnName'];
    const asc = (! this.state.sort.asc);
    const { params, actions, invitations } = this.props;

    this.setState({ sort: { column, asc } });

    let urlParamString = `institution=${params.id}`;

    if (column.length !== 0) {
      const sortParam = asc ? 'asc' : 'desc';

      urlParamString = `${urlParamString}&sort=${column}:${sortParam}`;
    }

    const searchQuery = invitations.filters.searchQuery.trim();

    if (searchQuery.length !== 0) {
      urlParamString = `${urlParamString}&query=${searchQuery}`;
    }

    actions.getInvitations(urlParamString);
  }

  render() {
    const { actions, params, invitations } = this.props;

    return (
      <div className="interior invite-users">
        <Helmet title="Admin - Manage Invites"/>
        <div className="container">
          <div className="row">
            <div className="col-md-12 content follow">
              <h2>Manage Invites</h2>
              <Table
                invitations={invitations}
                onCopy={this.onCopy}
                onNext={this.onNext}
                onPrev={this.onPrev}
                onSelectPage={this.onSelectPage}
                institutionId={params.id}
                onSortTable={this.onSortTable}
                onChangeSearchQuery={actions.onChangeInvitationsSearchQuery}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    invitations: state.organizationInvitations
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(organizationInvitationsActions, dispatch)
  };
};

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(ManageInvites));
