import { CALL_API } from '../../middlewares/api';
import {
  CLUBS_REQUEST,
  CLUBS_SUCCESS,
  CLUBS_FAILURE,
  CREATE_CLUB_REQUEST,
  CREATE_CLUB_SUCCESS,
  CREATE_CLUB_FAILURE,
  UPDATE_CLUB_REQUEST,
  UPDATE_CLUB_SUCCESS,
  UPDATE_CLUB_FAILURE,
  DELETE_CLUB_REQUEST,
  DELETE_CLUB_SUCCESS,
  DELETE_CLUB_FAILURE,
} from '../../constants';

export const clubsRequest = (userId) => {
  const endpoint = `user/clubs?user=${userId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [CLUBS_REQUEST, CLUBS_SUCCESS, CLUBS_FAILURE],
    },
  };
};

export const saveClub = (data) => {
  const endpoint = 'user/clubs';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [CREATE_CLUB_REQUEST, CREATE_CLUB_SUCCESS, CREATE_CLUB_FAILURE],
    },
  };
};

export const updateClub = (index, data) => {
  const endpoint = `user/clubs/${data.id}`;

  return {
    [CALL_API]: {
      data,
      index,
      endpoint,
      method: 'put',
      authenticated: true,
      types: [UPDATE_CLUB_REQUEST, UPDATE_CLUB_SUCCESS, UPDATE_CLUB_FAILURE],
    },
  };
};

export const deleteClub = (clubId) => {
  const endpoint = `user/clubs/${clubId}`;

  return {
    [CALL_API]: {
      endpoint,
      id: clubId,
      method: 'delete',
      authenticated: true,
      types: [DELETE_CLUB_REQUEST, DELETE_CLUB_SUCCESS, DELETE_CLUB_FAILURE],
    },
  };
};
