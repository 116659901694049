import PropTypes from 'prop-types';
import React from 'react';

import ReactQuill, { Quill } from 'react-quill';

const defaultModules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],
    [{ 'align': [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'color': [] }],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
};

const formats = [
  'header',
  'bold', 'italic', 'underline',
  'list', 'bullet', 'indent', 'link',
  'align', 'image', 'color', 'background', 'video'
];

const BlockEmbed = Quill.import('blots/block/embed');

class VideoEmbeddedBlot extends BlockEmbed {
  static create(url) {
    let wrapperNode = document.createElement('div');
    wrapperNode.classList.add('embed-responsive', 'embed-responsive-16by9');
    let node = super.create();
    node.setAttribute('src', url);
    node.classList.add('embed-responsive-item');
    wrapperNode.appendChild(node);
    return wrapperNode;
  }

  static value(node) {
    return node.getAttribute('src');
  }
}
VideoEmbeddedBlot.blotName = 'video';
VideoEmbeddedBlot.tagName = 'iframe';
Quill.register(VideoEmbeddedBlot);

const QuillEditor = ({ value, onChange, placeholder, modules, className}) => {
  const moduleObject = modules ?
    modules :
    defaultModules;
  return (
    <ReactQuill
      id="quill-editor-boundary"
      value={value}
      theme="snow"
      placeholder={placeholder === ''? 'Write something...':placeholder}
      modules={moduleObject}
      formats={formats}
      className={className}
      bounds={'#quill-editor-boundary'}
      onChange={onChange} />
  );
};

QuillEditor.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  modules: PropTypes.object
};

export default QuillEditor;
