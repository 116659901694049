import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import NavbarAuthButtons from './NavbarAuthButtons';

const NavbarRight = ({ showNav, header, toggleLoginModal, toggleRequestInvite }) => {
  return (
    <div
      id="exCollapsingNavbar2"
      className={classNames(
        {'navbar-toggleable-xs': !header},
        {'in': showNav}
      )}>
      <NavbarAuthButtons
        header={header}
        toggleLoginModal={toggleLoginModal}
        toggleRequestInvite={toggleRequestInvite}/>
    </div>
  );
};

NavbarRight.propTypes = {
  toggleLoginModal: PropTypes.func.isRequired,
  toggleRequestInvite: PropTypes.func.isRequired,
  showNav: PropTypes.bool,
  header: PropTypes.bool,
};


export default NavbarRight;
