import PropTypes from 'prop-types';
import React from 'react';
import BadgeUnit from './BadgeUnit';
import Loading from '../../common/Loading';
import WarningCard from '../WarningCard';

import { isEmpty } from 'lodash';

const BadgeContainer = ({
  unit,
  badge,
  errors,
  claimBadge,
  issuedBadge,
  selectedFile,
  onRemoveFile,
  onAddEvidence,
  isIssuingBadge,
  isAuthenticated,
  acknowledgement,
  onChangeStatement,
  toggleShareAndVerifyCheckboxes,
  onChangeSelectedFile,
  onAcknowledgementChange,
  evidenceGuidelinesTooltipOpen,
  statementGuidelinesTooltipOpen,
  toggleEvidenceGuidelinesTooltip,
  toggleStatementGuidelinesTooltip,
  onChangeAddPortfolio,
  addPortfolioRadio,
  onCancelResetFile,
  onConfirmResetFile,
  isRequesting,
  onConfirmJustification,
  isValidClaimBadge,
  displayLinkingPortfolioModal,
  onDisplayLinkingPortfolioModal,

  selectedFiles,
  onSelectFile,
  onDeleteLoadedFile,
  onInsertLinkClicked,
  showInsertTextBox,
  guidelinesTooltipOpen,
  onDropFile,
  toggleGuidelinesTooltip
}) => {
  if(isRequesting || isEmpty(unit)){
    return(
      <div className="loading-container">
        <Loading/>
        <span>Loading... </span>
      </div>
    );
  }

  if (Object.keys(unit.badge).length === 0) {
    return (
      <WarningCard
        warning={'Oops ... there is no badge for this unit.'}/>
    );
  }

  const badgeImage = unit.badge.url;

  return (
    <div className="col">
      <div className="essential-wrapper">
        <div className="essential-intro claim-badge-container">
          <div id="claim-badge">
            <div className="content">
              <BadgeUnit
                unit={unit}
                badge={badge}
                errors={errors}
                addPortfolioRadio={addPortfolioRadio}
                badgeImage={badgeImage}
                claimBadge={claimBadge}
                issuedBadge={issuedBadge}
                selectedFile={selectedFile}
                onRemoveFile={onRemoveFile}
                onAddEvidence={onAddEvidence}
                isIssuingBadge={isIssuingBadge}
                acknowledgement={acknowledgement}
                isAuthenticated={isAuthenticated}
                onChangeStatement={onChangeStatement}
                onChangeSelectedFile={onChangeSelectedFile}
                toggleShareAndVerifyCheckboxes={toggleShareAndVerifyCheckboxes}
                onAcknowledgementChange={onAcknowledgementChange}
                evidenceGuidelinesTooltipOpen={evidenceGuidelinesTooltipOpen}
                statementGuidelinesTooltipOpen={statementGuidelinesTooltipOpen}
                toggleEvidenceGuidelinesTooltip={toggleEvidenceGuidelinesTooltip}
                toggleStatementGuidelinesTooltip={toggleStatementGuidelinesTooltip}
                onChangeAddPortfolio={onChangeAddPortfolio}
                onCancelResetFile={onCancelResetFile}
                onConfirmResetFile={onConfirmResetFile}
                onConfirmJustification={onConfirmJustification}
                isValidClaimBadge={isValidClaimBadge}
                displayLinkingPortfolioModal={displayLinkingPortfolioModal}
                onDisplayLinkingPortfolioModal={onDisplayLinkingPortfolioModal}
                selectedFiles={selectedFiles}
                onSelectFile={onSelectFile}
                onDeleteLoadedFile={onDeleteLoadedFile}
                onInsertLinkClicked={onInsertLinkClicked}
                showInsertTextBox={showInsertTextBox}
                guidelinesTooltipOpen={guidelinesTooltipOpen}
                onDropFile={onDropFile}
                toggleGuidelinesTooltip={toggleGuidelinesTooltip}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BadgeContainer.propTypes = {
  badge: PropTypes.object,
  unit: PropTypes.object,
  errors: PropTypes.object.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  issuedBadge: PropTypes.object.isRequired,
  onAddEvidence: PropTypes.func.isRequired,
  selectedFile: PropTypes.object.isRequired,
  isIssuingBadge: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  acknowledgement: PropTypes.array,
  onChangeStatement: PropTypes.func.isRequired,
  toggleShareAndVerifyCheckboxes: PropTypes.func.isRequired,
  onChangeSelectedFile: PropTypes.func.isRequired,
  onAcknowledgementChange: PropTypes.func.isRequired,
  evidenceGuidelinesTooltipOpen: PropTypes.bool.isRequired,
  statementGuidelinesTooltipOpen: PropTypes.bool.isRequired,
  toggleEvidenceGuidelinesTooltip: PropTypes.func.isRequired,
  toggleStatementGuidelinesTooltip: PropTypes.func.isRequired,
  onChangeAddPortfolio: PropTypes.func,
  addPortfolioRadio: PropTypes.string,
  onCancelResetFile: PropTypes.func.isRequired,
  onConfirmResetFile: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  onConfirmJustification: PropTypes.func,
  claimBadge: PropTypes.func.isRequired,
  isValidClaimBadge: PropTypes.func.isRequired,
  selectedFiles: PropTypes.array.isRequired,
  onSelectFile: PropTypes.func.isRequired,
  onDeleteLoadedFile: PropTypes.func.isRequired,
  onInsertLinkClicked: PropTypes.func.isRequired,
  showInsertTextBox: PropTypes.bool.isRequired,
  guidelinesTooltipOpen: PropTypes.bool.isRequired,
  onDropFile: PropTypes.func.isRequired,
  toggleGuidelinesTooltip: PropTypes.func.isRequired,
  displayLinkingPortfolioModal: PropTypes.bool,
  onDisplayLinkingPortfolioModal: PropTypes.func.isRequired
};

export default BadgeContainer;
