import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

class UnitVideoCommentComponent extends PureComponent {
  static propTypes = {
    comment: PropTypes.object.isRequired
  };


  render() {
    const { user, comment: commentText } = this.props.comment;

    return (
      <div className="profile-name">
        <span>
          <Link style={{fontWeight: '500'}} to={`/cp/${user.vanity}`}>{user.name}</Link>
        </span> {commentText}
      </div>
    );
  }
}

export default UnitVideoCommentComponent;
