import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../common/Button';
import TextArea from '../../common/TextArea';

const InterestForm = ({
  interest,
  onChange,
  onSave,
  onCancel,
  onDelete,
  isSubmitting,
  isUpdating,
  isDeleting,
  errors,
  showDelete
}) => {
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <TextArea
            name="name"
            placeholder="Enter your interests. (e.g. Volunteering, Web Design, Photography, Poetry, Music Production, Blogging, Entrepreneurship)"
            error={errors.name}
            value={interest.name}
            onChange={onChange}
            rows="5"
            cols="80"/>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="action-bar text-right">
            {showDelete &&
              <Button
                type="submit"
                onClick={onDelete}
                styles={{marginLeft: 0}}
                disabled={isDeleting}
                buttonClass="btn-danger"
                buttonText={isDeleting ? 'Deleting...' : 'Delete'}/>}

            <Button
              type="button"
              onClick={onCancel}
              buttonClass="btn-secondary"
              buttonText="Cancel"/>

            <Button
              type="submit"
              onClick={onSave}
              disabled={isSubmitting || isUpdating}
              buttonClass="btn-primary"
              buttonText={isSubmitting || isUpdating ? 'Saving...' : 'Save'}/>
          </div>
        </div>
      </div>
    </div>
  );
};

InterestForm.propTypes = {
  onDelete: PropTypes.func,
  isUpdating: PropTypes.bool,
  isDeleting: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  interest: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  showDelete: PropTypes.bool
};

export default InterestForm;
