export default {
  'regularUser': [
    {
      'header': 'Getting Started',
      'paragraph': 'See help articles on getting started on CareerPrepped',
      'url': 'https://help.careerprepped.com/help/getting-started'
    },
    {
      'header': 'About the Career Site',
      'paragraph': 'See help articles on what avatar photo to use, how to control privacy settings, and more',
      'url': 'https://help.careerprepped.com/help/careerprepped-career-site'
    },
    {
      'header': 'About Skill Builders',
      'paragraph': 'See help articles on getting started with Skill Builders',
      'url': 'https://help.careerprepped.com/help/careerprepped-skill-builders'
    },
    {
      'header': 'About the Career Portfolio',
      'paragraph': 'See help articles on what files you can save, what to put into your portfolio and more',
      'url': 'https://help.careerprepped.com/help/careerprepped-portfolio'
    }
  ],

  'talentDeveloper': [
    {
      'header': 'What are assignment ideas I can use?',
      'paragraph': 'See a platform demo & assignment ideas you can use',
      'url': 'https://institutions.careerprepped.com/help/what-are-some-assignment-ideas-i-can-use-in-my-classroom-if-i-m-an-instructor'
    },
    {
      'header': 'How do I invite users to my group(s)?',
      'paragraph': 'See how to invite users to join groups you\'ve created',
      'url': 'https://institutions.careerprepped.com/help/how-to-invite-users-to-my-group'
    },
    {
      'header': 'How do I create groups?',
      'paragraph': 'See how to create groups to organize your users',
      'url': 'https://institutions.careerprepped.com/help/how-do-i-setup-groups-for-my-account'
    },
    {
      'header': 'What are tips for giving feedback?',
      'paragraph': 'See tips for giving feedback on skill evidence',
      'url': 'https://help.careerprepped.com/help/tips-for-giving-feedback-on-skill-evidence'
    }
  ],

  'industryMentor': [
    {
      'header': 'What are tips for giving feedback?',
      'paragraph': 'See tips on giving feedback on skill evidence',
      'url': 'https://help.careerprepped.com/help/tips-for-giving-feedback-on-skill-evidence'
    },
    {
      'header': 'About the Career Site',
      'paragraph': 'See help articles on what avatar photo to use, how to control privacy settings, and more',
      'url': 'https://help.careerprepped.com/help/careerprepped-career-site'
    },
    {
      'header': 'About the Career Portfolio',
      'paragraph': 'See help articles on what files you can save, what to put into your portfolio and more.',
      'url': 'https://help.careerprepped.com/help/careerprepped-portfolio'
    },
    {
      'header': 'About Skill Builders',
      'paragraph': 'See help articles on getting started with Skill Builders',
      'url': 'https://help.careerprepped.com/help/careerprepped-skill-builders'
    }
  ]
};
