import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { checkHttp } from '../../../utils';
import AuthorsCard from './AuthorsCard';
import CpHtmlParser from '../../common/CpHtmlParser';

const styles = {
  editButton: {
    marginLeft: 10,
    cursor: 'pointer',
  }
};

const PublicationCard = ({
  publication,
  isPublicProfile,
  onEditPublicationClick
}) => {
  const urlAvailable = publication.puburl !== '';
  const authorAvailable = publication.author !== '';
  const urlString = checkHttp(publication.puburl) ?
    publication.puburl : `//${publication.puburl}`;

  return (
    <div className="card-block">
      <div className="publications">
        <div className="row">
          <div className="col-lg-12">
            <div className="display">
              <div className="item">
                <div className="title">{publication.title}
                  {! isPublicProfile ?
                    <i
                      style={styles.editButton}
                      onClick={onEditPublicationClick}
                      id={`update-publication-form-${publication.id}`}
                      className="fa fa-xs fa-pencil orange" aria-hidden="true"/> : ''}
                </div>
                <div className="publisher">
                  {urlAvailable ?
                    <a href={urlString} target="_blank" rel="noopener noreferrer">
                      {publication.publication}
                    </a> :
                    publication.publication}
                </div>
                <div className="date">
                  {moment(publication.pubdate.date).format('MMMM YYYY')}
                </div>
                <div className="description">
                  <div className="ql-editor no-padding-in-quill">
                    <CpHtmlParser htmlString={publication.description}/>
                  </div>
                </div>

                {authorAvailable && <AuthorsCard publication={publication}/>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PublicationCard.propTypes = {
  publication: PropTypes.object.isRequired,
  isPublicProfile: PropTypes.bool.isRequired,
  onEditPublicationClick: PropTypes.func.isRequired
};

export default PublicationCard;
