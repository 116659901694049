import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';
import DashboardAvatar from '../dashboard/DashboardAvatar';
import { pure } from 'recompose';

const SidebarUserInfo = ({ profile }) => {

  return(
    <div className="dashboard-user">
      <span className="align-middle">
        <DashboardAvatar profile={profile} medium/>
      </span>
      <Link to={`/cp/${profile.vanity}`} className="align-middle user-name">{profile.name}</Link>
    </div>
  );
};
SidebarUserInfo.propTypes = {
  profile: PropTypes.object.isRequired
};
export default pure(SidebarUserInfo);
