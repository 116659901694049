import React from 'react';

import PropTypes from 'prop-types';

const NameCell = ({ organization }) => {
  if (organization.publicUrl.length === 0) {
    return (
      <div>{organization.name}</div>
    );
  }

  return (
    <div>
      <a
        target="_blank"
        href={organization.publicUrl}>
        {organization.name}
      </a>
    </div>
  );
};

NameCell.propTypes = {
  organization: PropTypes.object.isRequired
};

export default NameCell;
