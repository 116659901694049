import PropTypes from 'prop-types';
import React from 'react';
import TextInput from '../common/TextInput';

const Password = ({
  password,
  changePassword,
  error,
  isUpdating,
  onKeyPress
}) => {
  return(
    <TextInput
      type="password"
      className="form-control input-styles-owht"
      inputId="password"
      placeholder={'Password'}
      name="password"
      label="Password:"
      value={password}
      onChange={changePassword}
      error={error}
      disabled={isUpdating}
      onKeyPress={onKeyPress}/>
  );
};

Password.propTypes = {
  password: PropTypes.string,
  changePassword: PropTypes.func,
  error: PropTypes.string,
  isUpdating: PropTypes.bool,
  onKeyPress: PropTypes.func
};

export default Password;
