import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withHooks } from '../../utils/withHooks';

import {
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
} from 'reactstrap';
import Button from '../common/Button';
import { toastr } from 'react-redux-toastr';

import { redirect } from '../../redux/actions/router';
import { setNumberOfGroupsToCreate } from '../../redux/actions/organization-stats';

class AdminGroupAddModal extends Component {
  static propTypes = {
    toggle: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    groupId: PropTypes.string,
    institutionId: PropTypes.string,
    groupNames: PropTypes.array,
    profile: PropTypes.object,
    groupsList: PropTypes.array.isRequired,
    navigate: PropTypes.func.isRequired
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      numberOfGroupsToCreate: 0
    };
  }

  onChange = (event) => {
    const { value: numberOfGroupsToCreate } = event.target;
    const { profile: { role }, groupsList: { length: groupsCount } } = this.props;

    const totalGroups = groupsCount + parseInt(numberOfGroupsToCreate);
    const groupsRemaining = (10 - groupsCount);

    if ((role === 'educator') && (totalGroups > 10)) {
      toastr.error('', `Your account is limited to 10 groups maximum. You have ${groupsRemaining} remaining groups you can create.`);
      return;
    }

    this.setState({ numberOfGroupsToCreate });
  }

  onSave = () => {
    const { actions, navigate, institutionId } = this.props;

    actions.setNumberOfGroupsToCreate(this.state.numberOfGroupsToCreate);

    if (institutionId) {
      actions.redirect(`/organization/admin/${institutionId}`);
      navigate(`/organization/admin/${institutionId}`);
      return;
    }

    actions.redirect('/educator/groups/create');
    navigate('/educator/groups/create');
  }

  render() {
    const { profile: { role } } = this.props;
    let headerDescription = 'Create as many groups as you want, 10 groups at a time.';

    if (role === 'educator') {
      headerDescription = 'Your educator account allows you to create up to 10 groups.';
    }

    return(
      <Modal
        size="md"
        centered
        autoFocus
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}>
        <ModalHeader
          toggle={this.props.toggle}>
          Add New Group(s)<br/>
          <small style={{fontSize: 14}}>{headerDescription}</small>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="numberOfGroupsToCreate">How many new groups do you want to add?</Label>
            <Input
              type="select"
              id="numberOfGroupsToCreate"
              value={this.state.numberOfGroupsToCreate}
              onChange={this.onChange}>
              <option>0</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
              <option>6</option>
              <option>7</option>
              <option>8</option>
              <option>9</option>
              <option>10</option>
            </Input>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            buttonClass="btn-secondary"
            onClick={this.props.onCancel}
            buttonText="Cancel"/>
          <Button
            style={{ marginLeft: 0 }}
            buttonText="Continue"
            buttonClass="btn-primary"
            disabled={parseInt(this.state.numberOfGroupsToCreate) === 0}
            onClick={this.onSave}/>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { educators: { groups }, profile: { data: profile } } = state;

  const groupsList = groups.filter(group => group.id !== 'all-groups');

  return {
    profile,
    groupsList
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    redirect,
    setNumberOfGroupsToCreate
  }, dispatch)
});

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(AdminGroupAddModal));
