import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UnitBadgeForm from './UnitBadgeForm';
import UnitBadgeCard from '../../../components/skill-builders/admin/UnitBadgeCard';
import * as componentsActions from '../../../redux/actions/components';
import * as unitActions from '../../../redux/actions/skill-builders';
import { loadForm } from '../../../utils';

const styles = {
  card: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop: 0
  }
};

class UnitBadge extends Component {
  static propTypes = {
    unit: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    loadForms: PropTypes.array.isRequired,
    isUpdatingBadge: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.onAddNewBadge = this.onAddNewBadge.bind(this);
    this.onEditBadge = this.onEditBadge.bind(this);
  }

  onAddNewBadge(event){
    event.preventDefault();

    const { actions } = this.props;

    actions.loadForm('add-new-badge-to-a-unit');
  }

  onEditBadge(event) {
    event.preventDefault();

    const { actions, unit } = this.props;

    actions.loadForm(`edit-unit-badge-${unit.badge.id}`);
  }

  render() {
    const { unit, loadForms, isUpdatingBadge } = this.props;
    const badgeAvailableCondition = unit.badge && Object.keys(unit.badge).length > 0;
    const isForm = loadForm(loadForms, 'add-new-badge-to-a-unit');


    return(
      <div>
        <div className="unit-section-title">
          <h6 className="unit-section-title-text">Unit Badge</h6>
        </div>
        <div className="card" style={styles.card}>
          <div className="card-block p-3">
            {badgeAvailableCondition &&
              <UnitBadgeCard
                unit={unit}
                loadForms={loadForms}
                onEditBadge={this.onEditBadge}
                isUpdatingBadge={isUpdatingBadge}/>}

            {!badgeAvailableCondition && !isForm &&
              <div className="search-hint badge-hint">
                <span className="fa fa-info-circle" aria-hidden="true"/>
                <p>This unit doesn't have a badge.</p>
                <button
                  className="btn btn-primary"
                  onClick={this.onAddNewBadge}>
                  Add a badge
                </button>
              </div>}

            {isForm &&
              <UnitBadgeForm unit={unit}/>}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const { skillBuilders, components } = state;

  return {
    loadForms: components.forms,
    isUpdatingBadge: skillBuilders.isUpdatingBadge,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, componentsActions, unitActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitBadge);
