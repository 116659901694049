import { isUndefined } from 'lodash';

import initialState from './initialState';

import {
  INVITE_DETAILS_REQUEST,
  INVITE_DETAILS_SUCCESS,
  INVITE_DETAILS_FAILURE,
  ORGANIZATION_INVITATION_REQUEST,
  ORGANIZATION_INVITATION_SUCCESS,
  ORGANIZATION_INVITATION_FAILURE,
  ORGANIZATION_GROUP_INVITATION_FILTERED_REQUEST,
  ORGANIZATION_GROUP_INVITATION_FILTERED_SUCCESS,
  ORGANIZATION_GROUP_INVITATION_FILTERED_FAILURE,
  INVITE_USERS_TO_ORG_REQUEST,
  INVITE_USERS_TO_ORG_SUCCESS,
  INVITE_USERS_TO_ORG_FAILURE,
  RESEND_INVITE_TO_ORG_REQUEST,
  RESEND_INVITE_TO_ORG_SUCCESS,
  RESEND_INVITE_TO_ORG_FAILURE,
  JOIN_CP_REQUEST,
  JOIN_CP_SUCCESS,
  JOIN_CP_FAILURE,
  REVOKE_INVITE_TO_ORG_REQUEST,
  REVOKE_INVITE_TO_ORG_SUCCESS,
  REVOKE_INVITE_TO_ORG_FAILURE,
  DECLINE_INVITE_REQUEST,
  DECLINE_INVITE_SUCCESS,
  DECLINE_INVITE_FAILURE,
  ONCHANGE_INVITATION_SEARCH_QUERY,
} from '../../constants';

const updateInvitation = (state, action, isUpdating) => {
  const { invites: data } = state;
  const indexOfInvite = data.findIndex(item => item.id === isUpdating.id);

  return [
    ...data.slice(0, indexOfInvite),
    action.data,
    ...data.slice(indexOfInvite + 1),
  ];
};

export default function reducer(
  state = initialState.organizationInvitations,
  action
) {
  switch (action.type) {
  case INVITE_DETAILS_REQUEST:
    return Object.assign({}, state, {
      isRequesting: true,
    });

  case INVITE_DETAILS_SUCCESS:
    return Object.assign({}, state, {
      inviteDetails: action.data,
      isRequesting: false,
    });

  case INVITE_DETAILS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case ORGANIZATION_INVITATION_REQUEST:
    return Object.assign({}, state, {
      isRequesting: true,
    });

  case ORGANIZATION_INVITATION_SUCCESS:
    return Object.assign({}, state, {
      invites: action.data._embedded.invitation,
      page: isUndefined(action.data.page)
        ? initialState.organizationInvitations.page
        : action.data.page,

      page_size: isUndefined(action.data.page_size)
        ? initialState.organizationInvitations.page_size
        : action.data.page_size,

      page_count: isUndefined(action.data.page_count)
        ? initialState.organizationInvitations.page_count
        : action.data.page_count,

      isRequesting: false,
    });

  case ORGANIZATION_INVITATION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case ORGANIZATION_GROUP_INVITATION_FILTERED_REQUEST:
    return Object.assign({}, state, {
      isRequesting: true,
    });

  case ORGANIZATION_GROUP_INVITATION_FILTERED_SUCCESS:
    return Object.assign({}, state, {
      invites: action.data._embedded.invitation,
      isRequesting: false,
    });

  case ORGANIZATION_GROUP_INVITATION_FILTERED_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case JOIN_CP_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: true,
    });

  case JOIN_CP_SUCCESS:
    return Object.assign({}, state, {
      data: action.data,
      isSubmitting: false,
    });

  case JOIN_CP_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case INVITE_USERS_TO_ORG_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: true,
    });

  case INVITE_USERS_TO_ORG_SUCCESS:
    return Object.assign({}, state, {
      invites: action.data.invitations
        ? [...action.data.invitations, ...state.invites]
        : state.invites,
      isSubmitting: false,
    });

  case INVITE_USERS_TO_ORG_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case RESEND_INVITE_TO_ORG_REQUEST:
    return Object.assign({}, state, {
      isResending: {
        status: true,
        id: action.id,
      },
    });

  case RESEND_INVITE_TO_ORG_SUCCESS:
    return Object.assign({}, state, {
      invites: updateInvitation(state, action, state.isResending),
      isResending: {
        status: false,
        id: null,
      },
    });

  case RESEND_INVITE_TO_ORG_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isResending: {
        status: false,
        id: null,
      },
    });

  case REVOKE_INVITE_TO_ORG_REQUEST:
    return Object.assign({}, state, {
      isRevoking: {
        status: true,
        id: action.id,
      },
    });

  case REVOKE_INVITE_TO_ORG_SUCCESS:
    return Object.assign({}, state, {
      invites: updateInvitation(state, action, state.isRevoking),
      isRevoking: {
        status: false,
        id: null,
      },
    });

  case REVOKE_INVITE_TO_ORG_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRevoking: {
        status: false,
        id: null,
      },
    });

  case DECLINE_INVITE_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: true,
    });

  case DECLINE_INVITE_SUCCESS:
    return Object.assign({}, state, {
      isSubmitting: false,
    });

  case DECLINE_INVITE_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case ONCHANGE_INVITATION_SEARCH_QUERY:
    return Object.assign({}, state, {
      filters: {
        ...state.filters,
        searchQuery: action.searchQuery,
      },
    });

  default:
    return state;
  }
}
