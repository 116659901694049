import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

const WallPostErrorModal = ({ onClose, isOpen }) => {
  return (
    <Modal
      size="lg"
      className="modal-margin-top wall-post-error-modal"
      isOpen={isOpen}
      backdrop="static"
      toggle={onClose}>
      <ModalHeader>
        Post Is Empty
      </ModalHeader>
      <ModalBody>
        Your post is empty. You must write something or attach a file to post to the activity stream.
      </ModalBody>
      <ModalFooter className="clearfix">
        <button
          onClick={onClose}
          className="btn btn-primary float-right">
          Close
        </button>
      </ModalFooter>  
    </Modal>
  );
};

WallPostErrorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default WallPostErrorModal;
