import React, { Component } from 'react';

import { Outlet } from 'react-router-dom';

class Dashboard extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <div>
        <Outlet/>
      </div>
    );
  }
}

export default Dashboard;
