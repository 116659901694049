import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import _isEmpty from 'lodash/isEmpty';
import Validator from '../../validator';
import { toastr } from 'react-redux-toastr';

import { Helmet } from 'react-helmet';
import { Modal } from 'reactstrap';
import { withHooks } from '../../utils/withHooks';
import Loading from '../../components/common/Loading';
import ForgotPassword from '../auth/ForgotPassword';
import SignupFormComponent from '../../components/auth/SignupFormComponent';
import LoginFormComponent from '../../components/auth/LoginFormComponent';

import * as orgInvitationActions from '../../redux/actions/organization-invitations';
import * as routerActions from '../../redux/actions/router';
import * as componentActions from '../../redux/actions/components';
import * as authenticationActions from '../../redux/actions/authentication';

const cpLogo = require('../../assets/images/CP_logo_no-mark.png');

class GroupInvitation extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    organizationInvitations: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    inviteDetails: PropTypes.object,
    auth: PropTypes.object,
    navigate: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      errors: {},
      isSignupProcess: false,
      forgotPassword: false,
      loadingUserDetails: false,
      newUser: this.setNewUser()
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.toggleForgotPassword = this.toggleForgotPassword.bind(this);
  }

  componentDidMount(){
    const { actions, isAuthenticated } = this.props;
    const { newUser } = this.state;

    const { token } = newUser;
    if(isAuthenticated)
      actions.userLogout();

    actions.getInviteDetails(token);

  }

  componentWillReceiveProps(nextProps){
    const { data } = this.props.auth;
    const { isRequesting: nextIsSubmitting, data: nextData, isAuthenticated } = nextProps.auth;
    if (data !== nextData && !nextIsSubmitting && isAuthenticated && !this.state.isSignupProcess) {
      this.setState({
        loadingUserDetails: true,
        showDeclinceModal: false,
      }, () => this.joinOrganisation());
    }

    const { data: signupData, inviteDetails } = this.props.organizationInvitations;
    const {
      isSubmitting: nextSignupIsSubmitting,
      data: nextSignupData,
      inviteDetails: nextInviteDetails
    } = nextProps.organizationInvitations;

    if (inviteDetails !== nextInviteDetails && nextInviteDetails.user.name){
      const { newUser } = this.state;
      newUser.name = nextInviteDetails.user.name;

      this.setState({
        newUser
      });
    }

    if((signupData !== nextSignupData) && (!_isEmpty(nextSignupData)) && (!nextSignupIsSubmitting) && isAuthenticated){
      this.props.actions.redirect('/organization/admin');
      this.props.navigate('/organization/admin');
    }

    if ((signupData !== nextSignupData) && (!nextSignupIsSubmitting) && (!isAuthenticated)) {
      this.setState({
        loadingUserDetails: true,
        showDeclinceModal: false,
      }, () => this.autoLogin());
    }
  }

  setNewUser(){
    const { '*': linkParams } = this.props.params;
    const linkParamsArray = linkParams.split('/');

    let newUser = {
      email: linkParamsArray[1],
      firstname: '',
      lastname: '',
      token: linkParamsArray[0],
      password: '',
      rePassword: ''
    };

    return newUser;
  }

  toggleForgotPassword(){
    const { forgotPassword } = this.state;

    this.setState({
      forgotPassword: !forgotPassword
    });
  }

  autoLogin() {
    const { email, password } = this.state.newUser;

    this.props.actions.authenticateUser({ email, password })
      .then(() => this.redirectAndCloseModal());
  }

  joinOrganisation() {
    const { actions, auth } = this.props;
    const { newUser: { token, name, password } } = this.state;

    const data = { token, name, password };

    actions.joinCP(data, auth.isAuthenticated);
  }

  redirectAndCloseModal() {
    const { actions, navigate, isAuthenticated } = this.props;
    const { redirect } = actions;

    if (isAuthenticated) {
      redirect('/organization/admin');
      navigate('/organization/admin');
    }
  }

  isValid(field = null) {
    let validate = Validator.createValidator({
      firstname: ['required', 'minLength|1'],
      lastname: ['required', 'minLength|1'],
      password: ['required', 'minLength|8'],
      rePassword: ['required', 'minLength|8']
    }, this.state.newUser, field);

    let { isValid, errors } = validate;

    this.setState({ errors });

    return isValid;
  }

  onLogin() {
    const { newUser, errors } = this.state;

    if (Object.keys(errors).length > 0) return false;

    this.props.actions.authenticateUser({
      email: newUser.email,
      password: newUser.password
    });
  }

  onSubmit(event) {
    event.preventDefault();

    const { actions, inviteDetails } = this.props;

    if(!_isEmpty(inviteDetails.user)){
      return this.onLogin();
    }

    const { newUser } = this.state;

    if (! this.isValid()) return false;

    if (newUser.password !== newUser.rePassword) {
      return toastr.error('Entered passwords do not match.');
    }

    const data = {
      'token': newUser.token,
      'name': `${newUser.firstname} ${newUser.lastname}`,
      'password': newUser.password,
    };

    this.setState({
      isSignupProcess: true
    }, () => actions.joinCP(data));

  }

  onChange(event) {
    let { name, value, type, checked } = event.target;
    const { newUser } = this.state;
    value = type === 'checkbox' ? checked : value;

    newUser[name] = value;

    this.setState({ newUser }, () => this.isValid(name));
  }

  handleKeyPress(event) {
    if (event.key === 'Enter')
      return this.onSubmit(event);

    if (event.key === 'Escape')
      return this.toggleLoginModal();
  }

  render() {
    const { organizationInvitations, inviteDetails, params: { '*': splat }, auth } = this.props;
    const { isSubmitting, data, isRequesting } = organizationInvitations;
    const {
      newUser,
      errors,
      loadingUserDetails,
      forgotPassword
    } = this.state;

    const isUserRegistered = !_isEmpty(inviteDetails.user);

    const component = !isUserRegistered ?
      (<SignupFormComponent
        onChange={this.onChange}
        formData={newUser}
        errors={errors}
        onSubmit={this.onSubmit}
        isSubmitting={isSubmitting}
        data={data}/>) :
      (<LoginFormComponent
        formData={newUser}
        user={inviteDetails.user}
        errors={errors}
        isRequesting={auth.isRequesting}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        toggleForgotPassword={this.toggleForgotPassword}
        handleKeyPress={this.handleKeyPress}/>);

    if (loadingUserDetails) {
      return(
        <div className="interior info">
          <Helmet title="Join CareerPrepped Group"/>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="content">
                  <div className="loading-container">
                    <Loading/>
                    <span>You're in! Just a sec - we're getting you all set up!</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }


    return (
      <div className="interior info">
        <Helmet title="Join CareerPrepped Group"/>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="content">
                {
                  isRequesting && Object.keys(inviteDetails).length === 0 ?
                    (<div className="loading-container">
                      <Loading/>
                      <span>Fetching invitation details ...</span>
                    </div>) :
                    inviteDetails.status === 'accepted' ?
                      (<div>
                        <h5 className="modal-title" id="myModalLabel">Become an Admin on&nbsp;&nbsp;
                          <img src={cpLogo} alt="Career Prepped" style={{height: 20}}/>
                        </h5>
                        <div className="invitation-body">
                          <div className="accept-invitation-message">
                            <p>You have already accepted this invitation.</p>
                          </div>
                        </div>
                      </div>):
                      (<div className="invitation-body" style={{marginTop: 0}}>
                        {component}
                      </div>)
                }
              </div>
            </div>
          </div>
        </div>
        {
          forgotPassword &&
            <Modal
              isOpen={forgotPassword}
              toggle={this.toggleForgotPassword}>
              <ForgotPassword
                linkParams={splat}
                toggleForgotPassword={this.toggleForgotPassword}/>
            </Modal>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inviteDetails: state.organizationInvitations.inviteDetails,
    isAuthenticated: state.auth.isAuthenticated,
    auth: state.auth,
    organizationInvitations: state.organizationInvitations
  };
};
const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({},
    orgInvitationActions,
    routerActions,
    componentActions,
    authenticationActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(withHooks(GroupInvitation));
