import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../common/Button';
import SelectInput from '../../common/SelectInput';

const sections = [
  {
    text: 'Paragraph',
    value: 'paragraph-form'
  },
  {
    text: 'Text List',
    value: 'text-list-form'
  },
  {
    text: 'Q & A',
    value: 'question-answer-form'
  },
  {
    text: 'Quiz',
    value: 'quiz-form'
  },
  {
    text: 'Image',
    value: 'image-form'
  },
  {
    text: 'Video',
    value: 'video-form'
  },
  {
    text: 'Thinking Break',
    value: 'thinking-break-form'
  },
  {
    text: 'Rolling Section',
    value: 'rolling-section-form'
  },
  {
    text: 'Dropdown Section',
    value: 'dropdown-section-form'
  }
];

const SelectSection = ({onChange, formName, onClick}) => {
  return (
    <div className="col-lg-12 mt-3 mb-3">
      <div className="input-group">
        <SelectInput
          onChange={onChange}
          value={formName}
          options={sections}
          name="section"
          fullWidth
          defaultOption="Select section type to add new section"/>
        <span className="input-group-btn">
          <Button
            type="button"
            buttonClass="ml-3 btn-success"
            buttonText="New Section"
            onClick={onClick}/>
        </span>  
      </div>
    </div>
  );
};

SelectSection.propTypes = {
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  formName: PropTypes.string
};

export default SelectSection;
