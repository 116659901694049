import React from 'react';

import PropTypes from 'prop-types';

import { loadForm } from '../../utils';

import UsernameSection from './UsernameSection';
import UserDetailsForm from '../../containers/resume/UserDetailsForm';
import ResumeSummary from '../../containers/resume/ResumeSummary';
import CreateResumeDraggableList from '../../containers/resume/CreateResumeDraggableList';

const CreateResumeSectionsContainer = ({
  resumeOrder,
  onRearrangeResumeOrder,
  errors,
  resume,
  onChange,
  onUnloadForm,
  preview,
  onLoadForm,
  forms,
  onChangeSection,
  onChangeSectionTitle,
  onSortClick,
  onRemoveResumeSection
}) => {

  return (
    <div className="resume-container">
      <div className="create-resume-summary-info-card">
        <div className="create-resume-section-card">
          <div className="create-resume-title user-detail-title">
            Contact Details
          </div>
          {loadForm(forms, 'editUsernameForm') ?
            <UserDetailsForm
              resume={resume}
              onChange={onChange}
              onUnloadForm={onUnloadForm}/> :
            <UsernameSection
              resume={resume}
              onLoadForm={onLoadForm}/>}
        </div>
        <ResumeSummary
          preview={preview}
          resumeSummary={resume.summary}
          errors={errors}
          forms={forms}
          onLoadForm={onLoadForm}
          onChangeSection={onChangeSection}
          onChangeSectionTitle={onChangeSectionTitle}
          onUnloadForm={onUnloadForm}/>
      </div>

      <div>
        <div className="card">
          <button
            onClick={onSortClick}
            className="btn btn-primary"
            style={{width: '100%'}}>
            Sort Resume Sections
          </button>
        </div>

        <CreateResumeDraggableList
          onRearrangeResumeOrder={onRearrangeResumeOrder}
          forms={forms}
          resume={resume}
          onUnloadForm={onUnloadForm}
          onLoadForm={onLoadForm}
          onChangeSection={onChangeSection}
          onChangeSectionTitle={onChangeSectionTitle}
          resumeOrder={resumeOrder}
          onRemoveResumeSection={onRemoveResumeSection}/>
      </div>
    </div>
  );
};

CreateResumeSectionsContainer.propTypes = {
  errors: PropTypes.object,
  resume: PropTypes.object.isRequired,
  resumeOrder: PropTypes.array,
  onRearrangeResumeOrder: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onUnloadForm: PropTypes.func.isRequired,
  preview: PropTypes.bool,
  onLoadForm: PropTypes.func.isRequired,
  forms: PropTypes.array,
  onChangeSection: PropTypes.func.isRequired,
  onChangeSectionTitle: PropTypes.func.isRequired,
  onSortClick: PropTypes.func.isRequired,
  onRemoveResumeSection: PropTypes.func.isRequired
};

export default CreateResumeSectionsContainer;
