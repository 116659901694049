import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'reactstrap';

const sampleWebpageIcon = require('../../assets/images/sample-webpage-1.jpg');

const SampleWebpageModal = ({showSampleWebpageModal,toggleSampleWebpageModal,toggleOrgContent}) => {
  const CareerSiteContent = toggleOrgContent ?
    (<div>
      <p style={{fontSize:14}}>
        Learners can link their Skill Badges and portfolio files to their listed skills allowing visitors to see evidence of their skill claims based on privacy settings.
      </p>
    </div>) :
    (<div>
      <p style={{fontSize:14}}>
        Your Career Site puts you in charge of your career story. You can link your badges and portfolio files to your listed skills to back them up with multi-media evidence for others to see based on your privacy settings.
      </p>
    </div>);
  return (
    <Modal className="custom-md-modal" isOpen={showSampleWebpageModal} toggle={toggleSampleWebpageModal} size={'md'}>
      <div className="modal1" id="portfolioFiles">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel">Career Site</h4>
            <button type="button" className="close" onClick={toggleSampleWebpageModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="text-center modal-body">
            <a href="https://www.careerprepped.com/cp/maria-lebron-" target="_blank" rel="noopener noreferrer"><img className="p-3 img-fluid img-responsive" src={sampleWebpageIcon} /></a>
            {CareerSiteContent}
          </div>
        </div>
      </div>
    </Modal>
  );
};

SampleWebpageModal.propTypes = {
  showSampleWebpageModal: PropTypes.bool,
  toggleOrgContent: PropTypes.bool,
  toggleSampleWebpageModal: PropTypes.func
};

export default SampleWebpageModal;
