import PropTypes from 'prop-types';
import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import _isUndefined from 'lodash/isUndefined';
import _flow from 'lodash/flow';

import InterestCard from './InterestCard';
import ItemTypes from '../../../constants/ItemTypes';

const cardSource = {
  beginDrag(props) {
    return {
      id: props.interest.id,
      index: props.index,
    };
  }
};

const cardTarget = {
  canDrop(props) {
    return !_isUndefined(props.reArrangeInterests);
  },

  drop(props, monitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    if (dragIndex === hoverIndex) return;
    if (! monitor.canDrop()) return;
    props.reArrangeInterests(dragIndex, hoverIndex);
    // Note: we're mutating here for performance sake to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};

const InterestCardContainer = ({
  isDragging,
  connectDragSource,
  connectDragPreview,
  connectDropTarget,
  isOver,
  interest,
  profile,
  isPublicProfile,
  onEditInterestClick,
  last,
  index,
}) => {
  const opacity = isDragging ? 0 : 1;
  const border = isOver ? '2px dashed #928e8e' : 'none';

  let component = (
    <div className="card" style={{opacity, border, marginBottom : 0}}>
      <InterestCard
        interest={interest}
        profile={profile}
        index={index}
        connectDragSource={connectDragSource}
        isPublicProfile={isPublicProfile}
        onEditInterestClick={onEditInterestClick}
        last={last}/>
    </div>
  );

  return connectDropTarget(connectDragPreview(component));
};

InterestCardContainer.propTypes = {
  id: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.string,
  profile: PropTypes.object,
  index: PropTypes.number,
  isAdmin: PropTypes.bool,
};

export default _flow(DragSource(
  ItemTypes.INTEREST,
  cardSource,
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  })),
DropTarget(
  ItemTypes.INTEREST,
  cardTarget,
  (connect, monitor) => ({
    isOver: monitor.isOver(),
    connectDropTarget: connect.dropTarget()
  })
))(InterestCardContainer);
