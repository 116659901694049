import React from 'react';
import PropTypes from 'prop-types';

import Loading from '../../common/Loading';

import Dropzone from 'react-dropzone';

const styles = {
  dropzoneStyle: {
    cursor: 'pointer',
  },
  activeStyle: {
    border: '2px dashed #9e9d9d'
  }
};

const AvatarCard = ({ profile, onChange, isUpdating, isPublicProfile, visitingUser, onViewAvatar }) => {
  const userImgSrc = (
    profile.avatar ?
      profile.avatar
      :
      require('../../../assets/images/avatar.jpg')
  );

  const validMimeType = 'image/*';
  if(isUpdating){
    return (
      <div
        style={{
          background: '#314d72 center center',
          backgroundSize: 'cover'
        }}
        className="user-avatar">
        <div className="add-profile-photo">
          <Loading/>
        </div>
      </div>
    );
  }

  if (isPublicProfile || visitingUser) {
    return (
      <div className="user-avatar clickable"
        onClick={onViewAvatar}
        style={{
          background: `url(${userImgSrc}) center center / cover`,
        }}/>
    );
  }

  return (
    <div className="user-avatar"
      style={{
        background: `url(${userImgSrc}) center center / cover`,
      }}>
      <Dropzone
        style={styles.dropzoneStyle}
        activeStyle={styles.activeStyle}
        multiple={false}
        className="add-profile-photo"
        accept={validMimeType}
        onDrop={onChange}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div className="add-profile-photo" {...getRootProps()}>
              <i className="fa fa-camera"/>
              <input {...getInputProps()}/>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
};

AvatarCard.propTypes = {
  profile: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  onViewAvatar: PropTypes.func,
  isPublicProfile: PropTypes.bool,
  visitingUser: PropTypes.bool
};

export default AvatarCard;
