import React, { useState } from 'react';

import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import { Input, Label, Form, FormGroup, Button } from 'reactstrap';

import {
  ENDORSEMENT_VALIDATION,
  ENDORSEMENT_CONFIRM,
} from '../../utilConstants';
import { isEmpty, isUndefined } from 'lodash';

const EndorsementFeedback = (props) => {
  const [selectedOption, setSelectedOption] = useState('yes');
  const [testimonial, setTestimonial] = useState(
    props.feedbackData?.testimonial
  );
  const [endorsement, setEndorsement] = useState(false);
  const [state, setState] = useState({
    jobTitle: props.profile.jobTitle,
    organization: props.profile.organization,
  });
  const [error, setError] = useState({});

  function onValueChange(event){
    setSelectedOption(event.target.value);
  }

  function saveEndorsement(){
    setError({});
    if (selectedOption === 'yes' && !endorsement) {
      setError({invalidCheck: 'Required'});
      return;
    }

    if (!state.jobTitle && !state.organization) {
      toastr.error('', ENDORSEMENT_VALIDATION.jobOrganization);
      return;
    }

    if (!state.jobTitle) {
      toastr.error('', ENDORSEMENT_VALIDATION.jobTitle);
      return;
    }

    if (!state.organization) {
      toastr.error('', ENDORSEMENT_VALIDATION.organization);
      return;
    }

    const feedback = {};
    if (selectedOption === 'yes') {
      feedback['testimonial'] = testimonial;
      feedback['endorsement'] = endorsement;
    } else {
      feedback['endorsement'] = false;
    }

    feedback['giverDetails'] = {
      jobTitle: state.jobTitle,
      organization: state.organization,
      name: props.profile?.name,
    };

    props.feedbackResponse('endorsement', feedback);
    props.toggleConfirmationModal();
  }

  function handleTestimonial(e){
    if (e.target.value.split(' ').length <= 200) setTestimonial(e.target.value);
  }

  const renderFirstQuestion = () => (
    <div className="question1 mb-3">
      <p className="font-weight-bold font16">
        {ENDORSEMENT_CONFIRM(props.name)}
      </p>
      <Form className="answer needs-validation" noValidate>
        <FormGroup check className="custom-radio-wrapper form-check pl-0">
          <Label check className="container">
            <span className="ml-3 font14"> Yes </span>
            <Input
              checked={selectedOption === 'yes'}
              onChange={onValueChange}
              className="mr-1"
              value="yes"
              type="radio"
              name="endorse"/>
            <span className="checkmark" />
          </Label>
        </FormGroup>
        <FormGroup check className="custom-radio-wrapper form-check pl-0">
          <Label check className="container">
            <span className="ml-3 font14"> Not right now </span>
            <Input
              checked={selectedOption === 'no'}
              onChange={onValueChange}
              className="mr-1"
              value="no"
              type="radio"
              name="endorse"/>
            <span className="checkmark" />
          </Label>
        </FormGroup>
      </Form>
    </div>
  );

  function handleChange(e){
    setState({ ...state, [e.target.id]: e.target.value });
  }

  const renderEndorsementSection = () => (
    <section className="endorsement-section">
      <FormGroup>
        <Label for="feedbackgiver" style={{ display: 'block' }}>
          <strong style={{ color: '#000' }}>{props.profile?.name}</strong>
        </Label>
        {props.profile?.jobTitle ? (
          <Label for="feedbackgiver" style={{ display: 'block' }}>
            <strong style={{ color: '#000' }}>{props.profile?.jobTitle}</strong>
          </Label>
        ) : (
          <Input
            type="text"
            name="jobTitle"
            id="jobTitle"
            value={state.jobTitle}
            onChange={handleChange}
            placeholder="Enter Job Title"/>
        )}

        {props.profile?.organization ? (
          <Label for="feedbackgiver" style={{ display: 'block' }}>
            <strong style={{ color: '#000' }}>
              {props.profile?.organization}
            </strong>
          </Label>
        ) : (
          <Input
            type="text"
            name="organization"
            id="organization"
            value={state.organization}
            onChange={handleChange}
            placeholder="Enter Organization"/>
        )}
      </FormGroup>
    </section>
  );

  function handleEndorsement(e){
    setEndorsement(e.target.checked);
  }

  const renderSecondQuestion = () => (
    <div className="question2">
      <Form className="needs-validation" noValidate>
        {selectedOption === 'yes' && (
          <div>
            <p className="font-weight-bold font16 mt-2">
              Please acknowledge you've observed {props.name}'s skills
            </p>
            <FormGroup check className="pl-0 custom-checkbox-wrapper">
              <Label check className="container">
                <Input
                  type="checkbox"
                  htmlFor="invalidCheck"
                  invalid={!isEmpty(error) && !isUndefined(error?.invalidCheck)}
                  onClick={handleEndorsement}/>
                <span className={`${!isEmpty(error) && error?.invalidCheck ? 'is-invalid checkmark' : 'checkmark'}`} />
                <span>
                  I've observed {props.name}'s claimed{' '}
                  {props.feedbackData.resourceType === 'badge'
                    ? 'abilities '
                    : 'skill '}
                  in a training activity, project, or work setting. Based on my
                  observation of {props.name}'s{' '}
                  {props.feedbackData.resourceType === 'badge'
                    ? 'abilities '
                    : 'skill '}{' '}
                  and review of the supplied evidence, I confidently confirm&nbsp;
                  {props.name} has this skill.
                </span>
              </Label>
            </FormGroup>
            <div className="form-control-feedback">
              {error?.invalidCheck || ''}
            </div>
            <FormGroup className="mt-3">
              <Label
                className="pt-2"
                style={{ color: '#000' }}
                for="feedbackComment">
                <strong className="font16 mt-2">
                  Consider providing a testimonial about {props.name}'s skill
                </strong>
              </Label>
              <Input
                type="textarea"
                placeholder={`Write your testimonial to praise ${props.name}'s skill.`}
                name="feedbackComment"
                value={testimonial}
                onChange={handleTestimonial}
                id="feedbackComment"/>
            </FormGroup>
          </div>
        )}
        <div className="mb-2 mt-5 custom-divider" />
        {renderEndorsementSection()}
      </Form>
    </div>
  );

  function handleProceed(){
    window.scrollTo(0, 0);
    props.proceed(
      props.feedbackData.resourceType === 'skill' ||
        (props.feedbackData.resourceType === 'badge' &&
          props.step === true)
        ? (props.resourceData?.files?.length > 0) ? 'step2' : 'step1'
        : 'step1'
    );
  }

  return (
    <div>
      <section className="questionstoadmin px-2">
        {renderFirstQuestion()}
        {renderSecondQuestion()}
      </section>
      <div className="text-right px-2">
        <button
          type="button"
          className="btn btn-secondary"
          data-value="previous"
          onClick={handleProceed}>
          Previous
        </button>
        <Button onClick={saveEndorsement} className="sendButton ml-2">
          I'm Finished
        </Button>
      </div>
    </div>
  );
};

EndorsementFeedback.propTypes = {
  toggleConfirmationModal: PropTypes.func.isRequired,
  proceed: PropTypes.func.isRequired,
  feedbackData: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  step: PropTypes.bool,
  feedbackResponse: PropTypes.func.isRequired,
  resourceData: PropTypes.object,
};

export default EndorsementFeedback;
