import React from 'react';
import PropTypes from 'prop-types';

import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import GroupAccordionButton from './GroupAccordionButton';
import CircleStar from '../svg/CircleStar';
import GroupAdminListModal from './GroupAdminListModal';
import { SimpleToolTipWrapper } from '../../components/common/ToolTipWrapper';

const GroupParentRow = ({
  group,
  onAddChildGroup,
  isOpenGroupAction,
  toggleGroupActions,
  toggleParentGroupRow,
  isOpenParentGroupRow,
  onUpdateGroup,
  onUpdateGroupCode,
  isGroupAdminListModalOpen,
  toggleGroupAdminListModal,
  isAuthUserSelectedInstitutionAdmin
}) => {
  const isOpenParentRow = (group.id === isOpenParentGroupRow);
  const containsChildRows = (group.children.length > 0);

  return (
    <tr>
      {(! isAuthUserSelectedInstitutionAdmin) &&
        <td>
          <GroupAccordionButton
            buttonId={group.id}
            onClick={toggleParentGroupRow}
            isOpenParentRow={isOpenParentRow}
            containsChildRows={containsChildRows}/>
        </td>}
      <td>
        <div className="table-group__name">
          <span
            className="img-responsive initial-avatar group">
            {group.name.slice(0, 1)}
          </span>
          <span style={{ flex: 1 }}>
            {group.name}
          </span>
        </div>
      </td>
      <td>
        <div className="table-group__admins-list">
          <div className="table-group__admins">
            {group.owner}&nbsp;
            <SimpleToolTipWrapper
              id={`group-owner-tooltip-${group.id}`}
              toolTipContent={
                <div className="inside-tooltip">
                  Group Owner
                </div>
              }>
              <CircleStar />
            </SimpleToolTipWrapper>
            {group.groupAdmins.length !== 0 ? `, ${group.groupAdmins[0].name}` : ''}
            {group.groupAdmins.length > 1 ? ', ' : ''}
            {group.groupAdmins.length > 1 ?
              <a href="#" onClick={toggleGroupAdminListModal(group.id)}>
                +{group.groupAdmins.length - 1} more
              </a> :
              ''}
          </div>
        </div>
      </td>
      <td>
        <div className="table-group__members text-center">
          {group.groupUsersCount}
        </div>
      </td>
      <td>{group.inviteCode}</td>
      <td>
        <div className="dropdown">
          <ButtonDropdown
            isOpen={(group.id === isOpenGroupAction)}
            toggle={toggleGroupActions(group.id)}>
            <DropdownToggle caret color="primary">
              Actions
            </DropdownToggle>
            <DropdownMenu>
              {(! isAuthUserSelectedInstitutionAdmin) &&
                <DropdownItem
                  onClick={onAddChildGroup(group)}>
                  Add Child Group(s)
                </DropdownItem>}

              {isAuthUserSelectedInstitutionAdmin &&
                <span>
                  <DropdownItem onClick={onUpdateGroup(group)}>
                    Edit Name
                  </DropdownItem>
                  <DropdownItem onClick={onUpdateGroupCode(group)}>
                    Update Group Code
                  </DropdownItem>
                </span>}
            </DropdownMenu>
          </ButtonDropdown>
        </div>
      </td>
      {(group.id === isGroupAdminListModalOpen) &&
        <GroupAdminListModal
          isOpen={group.id === isGroupAdminListModalOpen}
          onClose={toggleGroupAdminListModal(group.id)}
          group={group}/>}
    </tr>
  );
};

GroupParentRow.propTypes = {
  group: PropTypes.object.isRequired,
  toggleGroupActions: PropTypes.func.isRequired,
  isOpenGroupAction: PropTypes.string.isRequired,
  isOpenParentGroupRow: PropTypes.string.isRequired,
  toggleParentGroupRow: PropTypes.func.isRequired,
  onAddChildGroup: PropTypes.func.isRequired,
  onUpdateGroup: PropTypes.func.isRequired,
  onUpdateGroupCode: PropTypes.func.isRequired,
  toggleGroupAdminListModal: PropTypes.func.isRequired,
  isGroupAdminListModalOpen: PropTypes.string.isRequired,
  isAuthUserSelectedInstitutionAdmin: PropTypes.bool.isRequired
};

export default GroupParentRow;
