import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as interviewActions from '../../../redux/actions/interview';
import * as componentsActions from '../../../redux/actions/components';
import VideoCommentReplyForm from './VideoCommentReplyForm';
import UnitVideoCommentReplyHandler from './UnitVideoCommentReplyHandler';
import FileCommentActions from '../../portfolio/FileCommentActions';
import UnitVideoCommentComponent from './UnitVideoCommentComponent';
import { loadForm } from '../../../utils';
import moment from 'moment';
import classNames from 'classnames';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

class UnitVideoCommentHandler extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    comment: PropTypes.object.isRequired,
    commentData: PropTypes.object.isRequired,
    isUpdating: PropTypes.object.isRequired,
    profile: PropTypes.object,
    videoId: PropTypes.string,
    isLiking: PropTypes.object,
    isDeleting: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    loadForms: PropTypes.array.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      dropdownOpen: false,
      showReplyForm: false,
      showDeleteConfirmModal: false,
      commentData: Object.assign({}, this.props.commentData)
    };

    this.toggle = this.toggle.bind(this);
    this.onLike = this.onLike.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onReply = this.onReply.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.closeConfrimDeleteModal = this.closeConfrimDeleteModal.bind(this);
  }

  componentWillReceiveProps(nextProps){
    const { isDeleting } = nextProps;

    if(isDeleting !== this.props.isDeleting && !isDeleting.status){
      this.closeConfrimDeleteModal();
    }
  }

  onChange(e){
    const { name, value } = e.target;
    const { commentData } = this.state;

    commentData[name] = value;

    this.setState({ commentData });
  }

  toggle() {
    this.setState({ dropdownOpen: ! this.state.dropdownOpen });
  }

  onEdit() {
    const { commentData } = this.state;
    const { actions, comment } = this.props;
    const form = `edit-video-comment-form-${comment.id}`;

    const newCommentData = Object.assign({}, commentData, {
      'comment': comment.comment
    });

    this.setState({
      commentData: newCommentData
    }, () => actions.loadForm(form));
  }

  onLike(e){
    e.preventDefault();

    const { actions,comment } = this.props;
    const { liked, id } = comment;

    if(liked){
      return actions.unLikeVideoComment(id);
    }

    const data = {
      'comment': id
    };

    actions.onLikeVideoComment(data);
  }

  onDeleteClick(){
    this.setState({
      showDeleteConfirmModal: true
    });
  }

  closeConfrimDeleteModal(){
    this.setState({
      showDeleteConfirmModal: false
    });
  }

  onDelete() {
    const { actions, comment } = this.props;
    actions.deleteVideoComment(comment.id);
  }

  onCancel(){

    const { actions, comment } = this.props;
    const form = `edit-video-comment-form-${comment.id}`;

    this.setState({
      commentData: {
        'comment': ''
      }
    }, () => actions.unloadFormRequest(form));
  }

  onUpdate(){
    const { commentData } = this.state;
    const { actions,comment } = this.props;

    const data = {
      'comment': commentData.comment
    };

    actions.updateVideoComment(data, comment.id).
      then(() => this.setState({
        commentData: {
          'comment': ''
        }
      }, () => actions.unloadFormRequest(`edit-video-comment-form-${comment.id}`)));
  }

  onReply(){

    this.setState({
      showReplyForm: !this.state.showReplyForm
    });
  }

  render() {
    const { commentData, showReplyForm, showDeleteConfirmModal } = this.state;
    const { comment, loadForms, isUpdating, profile, videoId, isLiking, isDeleting } = this.props;
    const { user, likes, liked, createdAt, replies } = comment;
    const isOwner = profile.id === user.id;
    const isLoadForm = loadForm(loadForms, `edit-video-comment-form-${comment.id}`);
    const dateString = moment.utc(createdAt.date).fromNow();
    let likeBtnText = liked ? 'Unlike': 'Like';
    const isLikingFile = isLiking.status && isLiking.id === comment.id;

    if(isLikingFile){
      likeBtnText = `${likeBtnText.slice(0, -1)}ing...`;
    }

    return (
      <div className="secondary-post">
        <div className="left">
          <span>
            {
              user.avatar && user.avatar !== ''?
                <img
                  className="header-image medium align-top"
                  src={user.avatar}
                  alt={`${user.name}'s Avatar`}/>:
                <span
                  className="header-image medium align-top initial-avatar">
                  {user.name.slice(0,1)}
                </span>
            }
          </span>
        </div>

        {
          isLoadForm &&
            <div>
              <div className="comment-post right">
                <div id="comment-container">
                  <textarea
                    name="comment"
                    className="form-control"
                    value={commentData.comment}
                    onChange={this.onChange}
                    placeholder="What are your thoughts on this portfolio file?"
                    rows="3"
                    cols="60"/>

                  <button
                    onClick={this.onCancel}
                    className="btn btn-secondary boder-1 float-left mt-1 mb-1">
                    Cancel
                  </button>
                  <button
                    onClick={this.onUpdate}
                    disabled={isUpdating.status}
                    className="btn btn-primary float-right mt-1 mb-1">
                    {isUpdating.status ? 'Updating...' :'Update'}
                  </button>
                </div>
              </div>
              <div className="clearfix"/>
            </div>
        }

        {
          !isLoadForm &&
            <div className="right">
              {
                isOwner &&
                  <div className="btn-group edit-menu align-top float-right">
                    <FileCommentActions
                      onDeleteClick={this.onDeleteClick}
                      onEditClick={this.onEdit}
                      comment={comment}/>
                  </div>
              }
              <div className="profile-title">
                <UnitVideoCommentComponent
                  comment={comment}/>
                <div className="share-stats file-share">
                  <span>
                    <a
                      onClick={this.onLike}
                      className={classNames('clickable', {
                        'orange': !liked,
                        'blue': liked
                      })}>
                      <i className="fa fa-heart"/>
                      {likeBtnText}
                    </a>
                  </span>
                  <span>
                    <a onClick={this.onReply} className="clickable orange">
                      <i className="fa fa-comment"/>Reply
                    </a>
                  </span>
                  <span>{likes} Likes</span>
                  <span>{dateString}</span>
                </div>
              </div>
            </div>
        }

        {replies && replies.map(reply =>
          (<UnitVideoCommentReplyHandler
            isLiking={isLiking}
            key={reply.id}
            replyToPost = {this.onReply}
            profile={profile}
            reply={reply}/>))}

        {
          showReplyForm &&
            <VideoCommentReplyForm
              videoId={videoId}
              parentId={comment.id}
              onCancel={this.onReply}/>
        }

        {
          showDeleteConfirmModal &&
            <Modal
              backdrop="static"
              className="modal-margin-top"
              isOpen={showDeleteConfirmModal}
              toggle={this.closeConfrimDeleteModal}>
              <ModalHeader
                toggle={this.closeConfrimDeleteModal}>
                Delete Comment
              </ModalHeader>
              <ModalBody>
                Are you sure you want to permanently delete this comment? If you do, it cannot be undone.
              </ModalBody>
              <ModalFooter>
                <button
                  onClick={this.closeConfrimDeleteModal}
                  className="btn btn-secondary">
                  Cancel
                </button>

                <button
                  onClick={this.onDelete}
                  className="btn btn-danger"
                  disabled={isDeleting.status}>
                  {isDeleting.status ? 'Deleting...' : 'Permanently Delete'}
                </button>
              </ModalFooter>
            </Modal>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  const commentData = {
    'comment': ''
  };

  return {
    commentData,
    profile: state.profile.data,
    isUpdating: state.interview.unitVideoComments.isUpdating,
    isLiking: state.interview.unitVideoComments.isLiking,
    isDeleting: state.interview.unitVideoComments.isDeleting,
    loadForms: state.components.forms,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, interviewActions, componentsActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitVideoCommentHandler);
