import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import Parser from 'html-react-parser';

const ResumeExperience = ({ resumeExperiences }) => {
  return (
    <div className="resume-section">
      <div className="resume-title">
        {resumeExperiences.title !== '' ? resumeExperiences.title : 'Experiences'}
      </div>
      {resumeExperiences.experienceArray.map((experience) => {
        const isStartDateObject = typeof experience.startdate === 'object';
        const startDateString = isStartDateObject ? experience.startdate.date :
          experience.startdate === '' ? undefined : experience.startdate;

        const isEndDateObject = typeof experience.enddate === 'object';
        const endDateString = isEndDateObject ? experience.enddate.date :
          experience.enddate === '' ? undefined : experience.enddate;

        const startDate = moment(startDateString).format('MM/YYYY');
        let endDate;

        if (experience.noend) {
          endDate = 'Present';
        } else {
          endDate = moment(endDateString).format('MM/YYYY');
        }

        return(
          <div key={experience.id} style={{marginBottom: 10}}>
            {experience.orgurl === '' ?
              <span>{experience.orgname}</span> :
              <span><a href={experience.orgurl} target="_blank" rel="noopener noreferrer">{experience.orgname}</a></span>}

            <span style={{marginLeft: 10}}>({startDate} - {endDate})</span>
            <p><b>{experience.title}</b></p>
            {Parser(experience.description)}
          </div>
        );
      })}
    </div>
  );
};

ResumeExperience.propTypes = {
  resumeExperiences: PropTypes.object.isRequired,
};

export default ResumeExperience;
