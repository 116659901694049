import PropTypes from 'prop-types';
import React from 'react';

import { TabPane } from 'reactstrap';
import descriptionList from './descriptionList';

const ModalTabContent = ({ example, exampleType }) => {
  const {
    tabId,
    exampleTitle,
    exampleBody,
  } = example;

  const exampleList = descriptionList(exampleType, exampleBody);

  return (
    <TabPane tabId={tabId} className="mt-2">
      <div>
        <h5>{exampleTitle}</h5>
        {exampleList}
      </div>
    </TabPane>
  );
};

ModalTabContent.propTypes = {
  example: PropTypes.object.isRequired,
  exampleType: PropTypes.string.isRequired
};

export default ModalTabContent;
