function ReceiveLinkedInInvite() {
  if (window.location.search) {
    const searchParams = new URLSearchParams(window.location.search);

    let params = {};
    // Display the key/value pairs
    for (let pair of searchParams.entries()) {
      params[pair[0]] = pair[1];
    }

    if (params.error) {
      const errorMessage =
        params.error_description || 'Login failed. Please try again.';
      window.opener &&
        window.opener.postMessage(
          {
            error: params.error,
            state: params.state,
            errorMessage,
            from: 'Linked In',
          },
          window.location.origin
        );
      // Close tab if user cancelled login
      if (params.error === 'user_cancelled_login') {
        window.close();
      }
    }

    if (params.code) {
      window.opener &&
        window.opener.postMessage(
          {
            code: params.code,
            state: params.state,
            from: 'Linked In',
          },
          window.location.origin
        ) &&
        window.close();
    }
  }

  return null;
}

export default ReceiveLinkedInInvite;
