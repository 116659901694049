import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';

const styles = {
  dropzoneStyle: {
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 5
  },
  activeStyle: {
    border: '2px dashed #9e9d9d'
  },
  placeholder: {
    margin: 0,
    color: '#ff5000',
    fontWeight: '500'
  },
  supportedTypes: {
    margin: 0,
    color: 'rgba(28,36,58,.64)',
    fontSize: 14,
    fontWeight: '400'
  }
};

const validMimeType = [
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'image/*',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/plain',
  'application/postscript',
  'application/rtf'
];

const UploadFile = ({
  onDropFile,
  imagePreviewUrl
}) => {
  const imageLoaded = imagePreviewUrl !== '';

  return(
    <div>
      <div>
        {
          !imageLoaded &&
            <div style={{ marginTop: 5, marginBottom: 10 }}>
              <Dropzone
                style={styles.dropzoneStyle}
                activeStyle={styles.activeStyle}
                multiple={false}
                accept={validMimeType.toString()}
                onDrop={onDropFile}>
                {({ getRootProps, getInputProps }) => (
                  <>
                    <p style={styles.placeholder} {...getRootProps()}>
                      <input {...getInputProps()}/>
                      <i className="fa fa-upload upload-icon"/>
                      Upload a File
                    </p>
                    <p style={styles.supportedTypes}>
                      .pdf, .doc, .ppt, .xls, .png, .psd, etc.
                    </p>
                  </>
                )}
              </Dropzone>
            </div>
        }
      </div>
    </div>
  );
};

UploadFile.propTypes = {
  imagePreviewUrl: PropTypes.string,
  onDropFile: PropTypes.func.isRequired,
};

export default UploadFile;
