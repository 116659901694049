import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import _isUndefined from 'lodash/isUndefined';
import Parser from 'html-react-parser';

const ThingkingBreakCollapseData = ({data}) => {
  const { correct, feedback, thinkingBreakSectionId } = data;

  const sectionStyle = classNames('card-block reformated', {
    'alert-success': correct,
    'alert-warning': !correct,
    [thinkingBreakSectionId]: !_isUndefined(thinkingBreakSectionId)
  });

  const sectionHeading = correct ?
    'Thoughts that support this action' : 'Thoughts for your consideration';

  return (
    <div className={sectionStyle}>
      <strong>{sectionHeading}: </strong>
      {Parser(feedback)}
    </div>
  );
};

ThingkingBreakCollapseData.propTypes = {
  data: PropTypes.object.isRequired
};

export default ThingkingBreakCollapseData;
