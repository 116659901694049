import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, Row, Col, Label, FormGroup } from 'reactstrap';

import { getRelationList } from '../../redux/actions/invite-users';
import validator from '../../validator';
import CloseIcon from '../../assets/images/close.svg';
import CPLogo from '../../assets/images/CP_logo.svg';

const Relation = ({ isOpen, toggleModal, name, proceed }) => {
  const dispatch = useDispatch();
  const [relation, setRelation]= useState('');
  const [relationErrors, setRelationErrors] = useState({});
  const [btnText, setBtnText] = useState('Next');
  const [isBtnDisable, setIsBtnDisable] = useState(false);

  const relationList = useSelector(state => state.inviteUsers.data);

  function onRelationChange(e) {
    setRelation(e.value);
  }

  useEffect(() => {
    dispatch(getRelationList());
  }, [dispatch, isOpen]);

  function toggleRelationModal() {
    toggleModal('relationModal');
  }

  function submit(){
    const { errors, isValid } = validatePasswordForm();
    setRelationErrors(errors);
    if (!isValid) {
      return;
    }else{
      setBtnText('Submitting...');
      setIsBtnDisable(true);
      proceed('relationModal',relation);
    }
  }
  
  function validatePasswordForm(){
    const rules = {
      relation: ['required']
    };
    const validate = validator.createValidator(rules, {relation: relation});
    return validate;
  }
  
  return (
    <Modal size="lg" isOpen={isOpen} className={'externalUserInviteContainerStyle'}>
      <ModalBody className="p-0">
        <div
          onClick={toggleRelationModal}
          className="closeIcon"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">
            {' '}
            <img src={CloseIcon} />{' '}
          </span>
        </div>
        <Row className="mx-0">
          <Col className="leftPanel d-flex justify-content-center align-items-center col-lg-6 col-12">
            <h4 className="fontW600">
              How do you know <br /> {name}?
            </h4>
          </Col>
          <Col className="col-lg-6 col-12">
            <div className="rightPanel">
              <div>
                <div className="text-center mb-5">
                  <img
                    src={CPLogo}
                    className="logoImg"/>
                </div>
                <FormGroup>
                  <Label for="relation" className="fontW600 font16 grayLabel">
                    Select your Relationship to {name}
                  </Label>
                  <div className="w-100">
                    <Select
                      className={`basic-single mb-1 ${
                        !isEmpty(relationErrors) && relationErrors.relation ? 'is-invalid' : ''
                      }`}
                      onChange={onRelationChange}
                      placeholder="Choose one"
                      closeMenuOnSelect
                      options={relationList}
                      invalid={!isEmpty(relationErrors) && relationErrors?.relation}
                      clearable={false}/>
                    <div className="form-control-feedback">{relationErrors?.relation}</div>
                  </div>
                </FormGroup>
                <div className="text-right">
                  <Button onClick={submit} className="btn sendButton mt-2" disabled={isBtnDisable}>{btnText}</Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

Relation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  proceed: PropTypes.func.isRequired,
};

export default Relation;
