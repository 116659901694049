import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as componentActions from '../../redux/actions/components';
import * as wallPostCommentActions from '../../redux/actions/wall-post-comments';
import * as wallPostActions from '../../redux/actions/wall-posts';
import * as searchActions from '../../redux/actions/search';

import { toastr } from 'react-redux-toastr';
import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isObject';
import Validator from '../../validator';

import WallCommentForm from '../../components/dashboard/WallCommentForm';

class WallCommentFormHandler extends PureComponent {
  static propTypes = {
    styles: PropTypes.object,
    commentType: PropTypes.string,
    wallPostId: PropTypes.string,
    actions: PropTypes.object.isRequired,
    wallPostComment: PropTypes.object,
    profile: PropTypes.object.isRequired,
    form: PropTypes.string,
    isForm: PropTypes.bool,
    searchPage: PropTypes.bool,
    isSubmitting: PropTypes.object.isRequired,
    loadedComments: PropTypes.object.isRequired
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      wallPostComment: this.setInitialWallComment(props.wallPostComment),
      errors: {},
      attachment: this.setInitialWallCommentAttachment(props.wallPostComment),
      noTextCommentBox: true,
      imagePreviewUrl: ''
    };

    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDropFile = this.onDropFile.bind(this);
    this.onEscape = this.onEscape.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentWillReceiveProps(nextProps){
    const { isSubmitting: nextSubmitting, loadedComments: nextLoadedComments } = nextProps;
    const { isSubmitting, loadedComments, wallPostId, actions } = this.props;

    if(nextSubmitting !== isSubmitting && !nextSubmitting.status && isSubmitting.wallPostId === wallPostId &&
      nextLoadedComments[isSubmitting.wallPostId] !== loadedComments[isSubmitting.wallPostId]){

      actions.incrementWallCommentCount(wallPostId, 'comment');
      this.handleResponse();
      return this.onFocus();
    }

  }

  setInitialWallComment(wallCommentInProps){
    let wallPostComment = { comment: '', attachment: {} };

    if (wallCommentInProps && Object.keys(wallCommentInProps).length > 0) {
      wallPostComment = Object.assign({},
        wallPostComment,
        wallCommentInProps
      );
    }

    if(wallPostComment.comment === null)
      wallPostComment.comment = '';

    return wallPostComment;
  }

  setInitialWallCommentAttachment(wallCommentInProps){
    let attachment = {};

    if(wallCommentInProps && Object.keys(wallCommentInProps).length > 0 && wallCommentInProps.attachment){
      attachment = wallCommentInProps.attachment;
    }

    return attachment;
  }

  onDropFile(files) {
    if (files[0].size > 8000000)
      return toastr.info('Sorry, this image exceeds the 8MB limit.');

    // focus on input field.
    const form = `wall-comment-form-wallPostId-${this.props.wallPostId}`;
    document.getElementById(form).focus();

    const imagePreviewUrl = URL.createObjectURL(files[0]);

    this.setState({
      attachment: files[0],
      imagePreviewUrl
    });
  }

  isValid(field = null) {
    const validate = Validator.createValidator({
      comment: ['maxLength|1000']
    }, this.state.wallPostComment, field);

    const { isValid, errors } = validate;

    this.setState({ errors });

    return isValid;
  }

  handleResponse() {
    const wallPostCommentData = this.state.wallPostComment;

    this.setState({
      wallPostComment: {
        comment: '',
        attachment: {}
      },
      attachment: {},
      noTextCommentBox: true,
      imagePreviewUrl: ''
    });

    if (wallPostCommentData.id)
      this.props.actions
        .unloadForm(`update-wall-post-comment-form-${wallPostCommentData.id}`);
  }

  onCancel() {
    this.setState({
      attachment: {},
      imagePreviewUrl: ''
    });
  }

  onChange(event) {
    const el = event.target;
    el.style.cssText = 'height:auto; padding:5px; overflow:auto';
    el.style.cssText = `height: ${el.scrollHeight}px`;
    const field = el.name;
    const { wallPostComment } = this.state;

    wallPostComment[field] = el.value;

    this.setState({ wallPostComment, noTextCommentBox: el.value.length === 0 });

    this.isValid(field);
  }

  onEscape() {
    const {
      wallPostComment,
      actions
    } = this.props;

    const form = `update-wall-post-comment-form-${wallPostComment.id}`;

    actions.unloadForm(form);
  }

  handleKeyPress(event) {
    if (event.key === 'Enter')
      return this.onSave(event);

    if (event.key === 'Escape')
      return this.onEscape();
  }

  onFocus() {
    // focus on input field.
    let formId = `wall-comment-form-wallPostId-${this.props.wallPostId}`;

    if (this.props.form)
      formId = this.props.form;
    document.getElementById(formId).focus();
  }

  onSave(event) {
    if (event.key === 'Enter') {
      event.preventDefault();

      let { wallPostComment, attachment } = this.state;
      const { actions, wallPostId, searchPage } = this.props;

      if (! this.isValid()) return;

      wallPostComment.wall = wallPostId;

      let formData = new FormData();

      if(wallPostComment.comment === '' && _isEmpty(attachment))
        return toastr.error('Comment cannot be empty.');

      if(wallPostComment.comment !== '')
        formData.append('comment', wallPostComment.comment);
      if (!_isEmpty(attachment))
        formData.append('attachment', attachment);
      formData.append('wall', wallPostComment.wall);

      if (wallPostComment.id){
        if(_isEmpty(attachment)){
          formData.append('attachment', '');
        }

        return actions.updateWallPostComment(formData, wallPostComment.id, wallPostId)
          .then(() => {
            this.handleResponse();
          });
      }

      if(searchPage){
        return actions.saveSearchResultPostComment(formData, wallPostId).
          then(() => {
            this.handleResponse();
            this.onFocus();
          });
      }


      actions.saveWallPostComment(formData, wallPostId);
    }
  }


  render() {
    const { wallPostComment, imagePreviewUrl: previewUrl, errors, noTextCommentBox, attachment } = this.state;

    const { styles, wallPostId, commentType, profile, isForm } = this.props;
    let formId = `wall-comment-form-wallPostId-${wallPostId}`;
    if (this.props.form)
      formId = this.props.form;

    const imagePreviewUrl = _isObject(attachment) ? previewUrl : attachment;

    return (
      <WallCommentForm
        noTextCommentBox={noTextCommentBox}
        styles={styles}
        errors={errors}
        profile={profile}
        commentType={commentType}
        wallPostComment={wallPostComment}
        onChange={this.onChange}
        handleKeyPress={this.handleKeyPress}
        wallPostId={wallPostId}
        imagePreviewUrl={imagePreviewUrl}
        onCancel={this.onCancel}
        isForm={isForm}
        onEscape={this.onEscape}
        form={formId}
        onDropFile={this.onDropFile}/>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    profile: state.profile.data,
    isUpdating: state.wallPostComments.isUpdating,
    loadedComments: state.wallPostComments.loadedComments,
    isSubmitting: state.wallPostComments.isSubmitting
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({},
    componentActions,
    wallPostCommentActions,
    wallPostActions,
    searchActions
  );
  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WallCommentFormHandler);
