import { CALL_API } from '../../middlewares/api';

import {
  USERS_REQUEST,
  USERS_SUCCESS,
  USERS_FAILURE,
  FOLLOW_USER_REQUEST,
  FOLLOW_USER_SUCCESS,
  FOLLOW_USER_FAILURE,
  UNFOLLOW_USER_REQUEST,
  UNFOLLOW_USER_SUCCESS,
  UNFOLLOW_USER_FAILURE,
  SET_USERS_SORT_ORDER,
  ONCHANGE_USERS_SEARCH_QUERY,
  ONCHANGE_USERS_FILTERS,
  DISPLAY_ADVANCED_FILTERS,
  RESET_FILTER_PARAMS,
} from '../../constants';

export const getUsers = (urlParamString) => {
  const endpoint = `institution/users?${urlParamString}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [USERS_REQUEST, USERS_SUCCESS, USERS_FAILURE],
    },
  };
};

export const getEducatorUsers = (urlParamString) => {
  let endpoint = 'group/membership';

  if (urlParamString.length !== 0) {
    endpoint = `${endpoint}?${urlParamString}`;
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [USERS_REQUEST, USERS_SUCCESS, USERS_FAILURE],
    },
  };
};

export const setUsersSortOrder = sort => ({
  type: SET_USERS_SORT_ORDER,
  sort,
});

export const onChangeUsersSearchQuery = searchQuery => ({
  type: ONCHANGE_USERS_SEARCH_QUERY,
  searchQuery,
});

export const onChangeUsersFilters = filters => ({
  type: ONCHANGE_USERS_FILTERS,
  filters,
});

export const displayAdvancedFilters = displayAdvancedFilters => ({
  type: DISPLAY_ADVANCED_FILTERS,
  displayAdvancedFilters,
});

export const resetFilterParams = () => ({ type: RESET_FILTER_PARAMS });

export const followUser = (data, index) => {
  const endpoint = 'user/connect';

  return {
    [CALL_API]: {
      data,
      endpoint,
      index,
      method: 'post',
      authenticated: true,
      types: [FOLLOW_USER_REQUEST, FOLLOW_USER_SUCCESS, FOLLOW_USER_FAILURE],
    },
  };
};

export const unfollowUser = (followingId, index) => {
  const endpoint = `user/connect/${followingId}`;

  return {
    [CALL_API]: {
      endpoint,
      index,
      method: 'delete',
      authenticated: true,
      types: [
        UNFOLLOW_USER_REQUEST,
        UNFOLLOW_USER_SUCCESS,
        UNFOLLOW_USER_FAILURE,
      ],
    },
  };
};
