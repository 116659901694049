import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { openModal } from '../../redux/actions/components';
import {
  setSelectedGroup,
  getUserAdminGroups,
  setSelectedParentGroup
} from '../../redux/actions/groups';

import { Collapse } from 'reactstrap';

import GroupChildRow from '../../components/admin/GroupChildRow';
import GroupParentRow from '../../components/admin/GroupParentRow';
import PaginationHandler from '../../components/common/PaginationHandler';

class GroupsTable extends PureComponent {
  static propTypes = {
    page: PropTypes.number.isRequired,
    actions: PropTypes.object.isRequired,
    pageCount: PropTypes.number.isRequired,
    userAdminGroups: PropTypes.array.isRequired,
    selectedInstitutionId: PropTypes.string.isRequired,
    isAuthUserSelectedInstitutionAdmin: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpenGroupAction: '',
      isOpenParentGroupRow: '',
      isGroupAdminListModalOpen: ''
    };

    this.onPrev = this.onPrev.bind(this);
    this.onNext = this.onNext.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
    this.onAddChildGroup = this.onAddChildGroup.bind(this);
    this.onUpdateGroupCode = this.onUpdateGroupCode.bind(this);
    this.onUpdateGroup = this.onUpdateGroup.bind(this);
    this.toggleParentGroupRow = this.toggleParentGroupRow.bind(this);
    this.toggleGroupActions = this.toggleGroupActions.bind(this);
    this.toggleGroupAdminListModal = this.toggleGroupAdminListModal.bind(this);
  }

  toggleGroupAdminListModal(groupId) {
    return (event) => {
      event.preventDefault();

      let isGroupAdminListModalOpen = '';

      if (groupId !== this.state.isGroupAdminListModalOpen) {
        isGroupAdminListModalOpen = groupId;
      }

      this.setState({ isGroupAdminListModalOpen });
    };
  }

  toggleGroupActions(groupId) {
    return () => {
      if (groupId === this.state.isOpenGroupAction) {
        this.setState({ isOpenGroupAction: '' });
        return;
      }

      this.setState({ isOpenGroupAction: groupId });
    };
  }

  toggleParentGroupRow(event) {
    event.preventDefault();

    let { id: isOpenParentGroupRow } = event.target;

    const selectedRow = this.props.userAdminGroups.find(group => group.id === isOpenParentGroupRow);

    if (selectedRow.children.length === 0) {
      return;
    }

    if (isOpenParentGroupRow === this.state.isOpenParentGroupRow) {
      isOpenParentGroupRow = '';
    }

    this.setState({ isOpenParentGroupRow });
  }

  onAddChildGroup(group) {
    return () => {
      const { actions } = this.props;

      actions.setSelectedGroup(group);
      actions.openModal('admin-group-add');
    };
  }

  onUpdateGroupCode(group, parentGroup = null) {
    return () => {
      const { actions, isAuthUserSelectedInstitutionAdmin } = this.props;
      const isChildGroupSelected = (! isAuthUserSelectedInstitutionAdmin);

      if (! isAuthUserSelectedInstitutionAdmin) {
        actions.setSelectedParentGroup(parentGroup);
      }

      actions.setSelectedGroup(group, isChildGroupSelected);
      actions.openModal('confirm-update-group-code');
    };
  }

  onUpdateGroup(group, parentGroup = null) {
    return () => {
      const { actions, isAuthUserSelectedInstitutionAdmin } = this.props;
      const isChildGroupSelected = (! isAuthUserSelectedInstitutionAdmin);

      if (! isAuthUserSelectedInstitutionAdmin) {
        actions.setSelectedParentGroup(parentGroup);
      }

      actions.setSelectedGroup(group, isChildGroupSelected);
      actions.openModal('group-edit');
    };
  }

  onNext() {
    const { page: currentPage, actions, selectedInstitutionId } = this.props;
    const page = currentPage + 1;

    const urlParamString = `&page=${page}`;

    actions.getUserAdminGroups(selectedInstitutionId, urlParamString, true);
  }

  onPrev() {
    const { page: currentPage, actions, selectedInstitutionId } = this.props;
    const page = currentPage - 1;

    const urlParamString = `&page=${page}`;

    actions.getUserAdminGroups(selectedInstitutionId, urlParamString, true);
  }

  onSelectPage(page) {
    return () => {
      const { actions, selectedInstitutionId } = this.props;

      const urlParamString = `&page=${page}`;

      actions.getUserAdminGroups(selectedInstitutionId, urlParamString, true);
    };
  }

  renderItems() {
    const { userAdminGroups, isAuthUserSelectedInstitutionAdmin } = this.props;
    const { isOpenGroupAction, isOpenParentGroupRow, isGroupAdminListModalOpen } = this.state;

    return userAdminGroups.map((group) => {
      const rows = [];
      const isParentRowCollapsed = (group.id === isOpenParentGroupRow);

      const row = (
        <GroupParentRow
          key={group.id}
          group={group}
          onAddChildGroup={this.onAddChildGroup}
          isOpenGroupAction={isOpenGroupAction}
          toggleGroupActions={this.toggleGroupActions}
          isOpenParentGroupRow={isOpenParentGroupRow}
          toggleParentGroupRow={this.toggleParentGroupRow}
          onUpdateGroup={this.onUpdateGroup}
          onUpdateGroupCode={this.onUpdateGroupCode}
          isGroupAdminListModalOpen={isGroupAdminListModalOpen}
          toggleGroupAdminListModal={this.toggleGroupAdminListModal}
          isAuthUserSelectedInstitutionAdmin={isAuthUserSelectedInstitutionAdmin}/>
      );

      rows.push(row);

      if (group.children.length === 0) {
        return rows;
      }

      if (! isParentRowCollapsed) {
        return rows;
      }

      const groupChildRows = group.children.map((childGroup) => {
        return (
          <GroupChildRow
            key={childGroup.id}
            group={group}
            childGroup={childGroup}
            onUpdateGroup={this.onUpdateGroup}
            onUpdateGroupCode={this.onUpdateGroupCode}
            isOpenGroupAction={isOpenGroupAction}
            toggleGroupActions={this.toggleGroupActions}
            isOpenParentGroupRow={isOpenParentGroupRow}
            isGroupAdminListModalOpen={isGroupAdminListModalOpen}
            toggleGroupAdminListModal={this.toggleGroupAdminListModal}/>
        );
      });

      const childRows = (
        <tr key={`child-${group.id}`}>
          <td colSpan="6" className="hiddenRow">
            <Collapse
              isOpen={(group.id === isOpenParentGroupRow)}>
              <table className="group-item" style={{marginBottom:0}}>
                <tbody>
                  {groupChildRows}
                </tbody>
              </table>
            </Collapse>
          </td>
        </tr>
      );

      rows.push(childRows);

      return rows;
    });
  }

  render() {
    const { page, pageCount, isAuthUserSelectedInstitutionAdmin } = this.props;

    return (
      <table className="cp-table mt-1 table table-responsive-md">
        <thead>
          <tr className="cp-table-header">
            {(! isAuthUserSelectedInstitutionAdmin) && <th className="table-col-accordion">#</th>}
            <th className="table-col-group-name">Group Name</th>
            <th className="table-col-group-admins">Group Admins</th>
            <th className="table-col-members">Members</th>
            <th className="table-col-group-code">Group Code</th>
            <th className="table-col-actions">Actions</th>
          </tr>
        </thead>
        <tbody>
          {this.renderItems()}
        </tbody>
        <tfoot>
          <tr>
            <th style={{textAlign: 'center'}} colSpan={10}>
              <PaginationHandler
                page={page}
                page_count={pageCount}
                onNext={this.onNext}
                onPrev={this.onPrev}
                onSelectPage={this.onSelectPage}/>
            </th>
          </tr>
        </tfoot>
      </table>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    groups: {
      page,
      pageCount,
      data: groupsArray,
      isAuthUserSelectedInstitutionAdmin
    },

    institutions: {
      selectedInstitution: { id: selectedInstitutionId }
    }
  } = state;

  let userAdminGroups = groupsArray;

  if (isAuthUserSelectedInstitutionAdmin) {
    userAdminGroups = groupsArray.filter(group => group.id !== 'all-groups');
  }

  return {
    page,
    pageCount,
    userAdminGroups,
    selectedInstitutionId,
    isAuthUserSelectedInstitutionAdmin
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    openModal,
    setSelectedGroup,
    getUserAdminGroups,
    setSelectedParentGroup
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupsTable);
