import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _toArray from 'lodash/toArray';

import * as wallPostActions from '../../redux/actions/wall-posts';
import * as wallCommentsActions from '../../redux/actions/wall-post-comments';
import * as componentsActions from '../../redux/actions/components';

import WallCard from '../../components/dashboard/wall-card-components/WallCard';
import UserLikesListModal from '../../components/common/UserLikesListModal';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

class WallCardContainer extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    wallPost: PropTypes.object.isRequired,
    showMore: PropTypes.object.isRequired,
    loadedComments: PropTypes.array,
    commentShowMore: PropTypes.object.isRequired,
    dashboardComments: PropTypes.object.isRequired,
    wallPosts: PropTypes.object.isRequired,
    wallPostComments: PropTypes.array,
    postIndex: PropTypes.number,
    loadForms: PropTypes.array.isRequired,
    isDeleting: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    isMobile: PropTypes.bool.isRequired,
    profile: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      isUserLikesTooltipOpen: false,
      isUserLikesListModalOpen: false,
      showMore: Object.assign({}, this.props.showMore),
      commentShowMore: Object.assign({}, this.props.commentShowMore),
      wallPost: Object.assign({}, this.props.wallPost)
    };

    this.onshowMore = this.onshowMore.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onCommentShowMore = this.onCommentShowMore.bind(this);
    this.renderMoreComments = this.renderMoreComments.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.toggleUserLikesTooltip = this.toggleUserLikesTooltip.bind(this);
    this.toggleUserLikesListModal = this.toggleUserLikesListModal.bind(this);
  }

  componentWillMount() {
    const {
      wallPost,
      actions,
      dashboardComments
    } = this.props;

    const initialComments = wallPost.comments;
    // save the initial set of comments in state( wallPostComments )
    if (wallPost.comments && !dashboardComments.loadedComments[wallPost.id])
      actions.loadInitialWallComments({
        wallPostId: wallPost.id,
        comments: _toArray(initialComments)
      });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.wallPost !== this.props.wallPost) {
      this.setState({
        wallPost: Object.assign({}, nextProps.wallPost)
      });
    }
  }

  renderMoreComments() {
    const {
      wallPost,
      actions,
      dashboardComments
    } = this.props;

    const pageNo = dashboardComments.loadedComments[wallPost.id].length / 5 + 1;

    actions.unitWallPostCommentsRequest(wallPost.id, pageNo);
  }

  handleResponse() {
    const { wallPost } = this.state;

    this.props.actions.unloadForm(`update-wall-post-form-${wallPost.id}`);
  }

  toggleModal() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  onshowMore(postId) {
    return () => {
      const { condition } = this.state.showMore;
      const newShowMore = { condition: !condition, id: postId};

      this.setState({ showMore: newShowMore });
    };
  }

  onCommentShowMore(id){
    return () => {
      const { condition, id: conditionId } = this.state.commentShowMore;

      const newShowMore = conditionId === id && id !== null ? {condition: !condition, id: id} :
        { condition: true, id: id};

      this.setState({ commentShowMore: newShowMore });
    };
  }

  onDelete() {
    const { actions, wallPost } = this.props;

    actions.deleteWallPost(wallPost.id).then(() => this.handleResponse());
  }

  onDeleteClick() {
    this.setState({ isOpen: true });
  }

  onCloseModal(){
    this.props.actions.closeModal();
  }

  toggleUserLikesTooltip() {
    this.setState({
      isUserLikesTooltipOpen: ! this.state.isUserLikesTooltipOpen
    });
  }

  toggleUserLikesListModal() {
    this.setState({
      isUserLikesListModalOpen: ! this.state.isUserLikesListModalOpen
    });
  }

  render() {
    const {
      wallPost,
      errors,
      showMore,
      commentShowMore,
      isOpen,
      isUserLikesTooltipOpen,
      isUserLikesListModalOpen
    } = this.state;

    let {
      profile,
      isMobile,
      loadForms,
      wallPosts,
      postIndex,
      isDeleting,
      currentUser,
      loadedComments,
      wallPostComments,
      dashboardComments
    } = this.props;

    if (dashboardComments.loadedComments[wallPost.id]) // if it's not undefined
      loadedComments = dashboardComments.loadedComments[wallPost.id];

    return (
      <div className="forums">
        <WallCard
          profile={profile}
          isMobile={isMobile}
          wallPost={wallPost}
          wallPostComments={wallPostComments}
          discussionComments={loadedComments}
          postIndex={postIndex}
          loadForms={loadForms}
          onDeleteClick={this.onDeleteClick}
          onshowMore={this.onshowMore}
          onCommentShowMore={this.onCommentShowMore}
          showMore={showMore}
          actualCommentCount={wallPosts.commentCount[wallPost.id]}
          commentShowMore={commentShowMore}
          isRequesting={dashboardComments.isRequesting}
          renderMoreComments={this.renderMoreComments}
          errors={errors}
          currentUser={currentUser}
          isDeleting={isDeleting}
          isUserLikesTooltipOpen={isUserLikesTooltipOpen}
          toggleUserLikesTooltip={this.toggleUserLikesTooltip}
          toggleUserLikesListModal={this.toggleUserLikesListModal}/>

        {(isUserLikesListModalOpen && (wallPost.likesList.length > 0)) &&
          <UserLikesListModal
            userLikes={wallPost.likesList}
            isOpen={isUserLikesListModalOpen}
            onClose={this.toggleUserLikesListModal}/>}

        <Modal
          backdrop="static"
          className="modal-margin-top"
          isOpen={isOpen} toggle={this.toggleModal}>
          <ModalHeader
            toggle={this.toggleModal}>
            Delete Post
          </ModalHeader>
          <ModalBody>
            Are you sure you want to permanently delete this post? If you do, it cannot be undone.
          </ModalBody>
          <ModalFooter>
            <button
              onClick={this.toggleModal}
              className="btn btn-secondary">
              Cancel
            </button>

            <button
              onClick={this.onDelete}
              className="btn btn-danger"
              disabled={isDeleting.status}>
              {isDeleting.status ? 'Deleting...' : 'Permanently Delete'}
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const getComments = (id, comments) => {
  if (comments[id]) {
    return comments[id];
  }

  return [];
};

const mapStateToProps = (state, ownProps) => {
  let showMore = { condition: false, id: null};
  let commentShowMore = { condition: false, id: null};
  let wallPost = { post: '', attachment: {}, permissions: '' };
  let loadedComments = [];

  const {
    wallPosts,
    components,
    wallPostComments,
    auth: { data: { currentUser } },
    profile: { data: profile }
  } = state;
  const { isMobile, forms: loadForms } = components;

  if (ownProps.wallPost)
    wallPost = Object.assign({}, wallPost, ownProps.wallPost);

  const wallComments = getComments(wallPost.id, wallPostComments.loadedComments);

  return {
    profile,
    wallPost,
    showMore,
    isMobile,
    commentShowMore,
    wallPosts,
    loadForms,
    loadedComments,
    wallPostComments: wallComments,
    currentUser,
    dashboardComments: wallPostComments
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, wallPostActions, wallCommentsActions, componentsActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WallCardContainer);
