import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const imgToolboxResource = require('../../assets/images/img-toolbox-resources.png');
const imgToolboxResume = require('../../assets/images/img-toolbox-target.png');
const imgToolboxVideos = require('../../assets/images/img-toolbox-videos.png');

class ToolboxContainer extends Component {
  render() {
    return (
      <div className="content targeted-resume">
        <div className="row">
          <div className="col-lg-12">
            <h2>Toolbox</h2>
          </div>
        </div>
        <div className="row" style={{display: 'flex'}}>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <Link to="/toolbox/resume" className="toolbox-link">
              <div className="tool">
                <div className="tool-image">
                  <img src={imgToolboxResume} alt="Targeted Resumes" className="icon"/>
                </div>
                <div className="tool-content">
                  <div className="tool-source">CareerPrepped</div>
                  <h6>Targeted Resume Builder</h6>
                  <p>Build custom resumes from your Career Site to use when applying to jobs.</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <Link to="/toolbox/interview-prep-videos" className="toolbox-link">
              <div className="tool">
                <div className="tool-image">
                  <img src={imgToolboxVideos} alt="Interview Prep Videos" className="icon"/>
                </div>
                <div className="tool-content">
                  <div className="tool-source">CareerPrepped</div>
                  <h6>Interview Prep Videos</h6>
                  <p>Learn to answer common interview questions & watch sample responses.</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <Link to="/toolbox/free-career-resources" className="toolbox-link">
              <div className="tool">
                <div className="tool-image">
                  <img src={imgToolboxResource} alt="Free Career Resources" className="icon"/>
                </div>
                <div className="tool-content">
                  <div className="tool-source">External Links</div>
                  <h6>Free Career Resources</h6>
                  <p>Use a variety of curated free resources to explore and research career information, take self-assessments, & more.</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default ToolboxContainer;
