import { CALL_API } from '../../middlewares/api';

import {
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_SETTINGS_REQUEST,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAILURE,
  UPDATE_SKILLS_ORDER_REQUEST,
  UPDATE_SKILLS_ORDER_SUCCESS,
  UPDATE_SKILLS_ORDER_FAILURE,
  UPDATE_INTERESTS_ORDER_REQUEST,
  UPDATE_INTERESTS_ORDER_SUCCESS,
  UPDATE_INTERESTS_ORDER_FAILURE,
  DECREMENT_UNREAD_MESSAGES_COUNT,
  DELETE_INTERESTS_SECTION_REQUEST,
  DELETE_INTERESTS_SECTION_SUCCESS,
  DELETE_INTERESTS_SECTION_FAILURE,
  UPDATE_ADMIN_INSTITUTION_GROUPS,
  INCREMENT_FOLLOWING_COUNT,
  DECREMENT_FOLLOWING_COUNT,
  UPDATE_IR_SETTINGS_REQUEST,
  UPDATE_IR_SETTINGS_SUCCESS,
  UPDATE_IR_SETTINGS_FAILURE,
  GET_IR_SETTINGS_REQUEST,
  GET_IR_SETTINGS_SUCCESS,
  GET_IR_SETTINGS_FAILURE,
  SET_UNSAVED_CHILD_GROUPS_DATA,
  SET_IS_CHILD_GROUP,
  DISABLE_MESSAGE_REQUEST,
  DISABLE_MESSAGE_SUCCESS,
  DISABLE_MESSAGE_FAILURE,
  GET_COMPANY_LIST_REQUEST,
  GET_COMPANY_LIST_SUCCESS,
  GET_COMPANY_LIST_FAILURE,
  GET_COUNTRIES_LIST_REQUEST,
  GET_COUNTRIES_LIST_SUCCESS,
  GET_COUNTRIES_LIST_FAILURE,

} from '../../constants';
import { isEmpty } from 'lodash';

export const setIsChildGroup = (isChildGroup) => {
  return { type: SET_IS_CHILD_GROUP, isChildGroup };
};

export const decrementUnreadCount = () => {
  return dispatch => dispatch({ type: DECREMENT_UNREAD_MESSAGES_COUNT });
};

export const incrementFollowingCount = () => {
  return dispatch => dispatch({ type: INCREMENT_FOLLOWING_COUNT });
};

export const decrementFollowingCount = () => {
  return dispatch => dispatch({ type: DECREMENT_FOLLOWING_COUNT });
};

export const setUnsavedChildGroupsData = (data) => {
  return { type: SET_UNSAVED_CHILD_GROUPS_DATA, data };
};

// export const createChildGroups = (institutionId, data) => {
//  const endpoint = `institution/${institutionId}/groups`;
//
//  return {
//    [CALL_API]: {
//      data,
//      endpoint,
//      method: 'patch',
//      authenticated: true,
//      types: [
//        CREATE_CHILD_GROUPS_REQUEST,
//        CREATE_CHILD_GROUPS_SUCCESS,
//        CREATE_CHILD_GROUPS_FAILURE
//      ]
//    }
//  };
// };

export const updateAdminInstitutionGroups = (
  group,
  groupIndex,
  institutionIndex
) => {
  return dispatch =>
    dispatch({
      group,
      groupIndex,
      institutionIndex,
      type: UPDATE_ADMIN_INSTITUTION_GROUPS,
    });
};

export const profileRequest = (userId, isAuthenticated = false) => {
  const endpoint = `user/user/${userId}`;

  const callApiWithoutAuthentication = {
    endpoint,
    method: 'get',
    types: [PROFILE_REQUEST, PROFILE_SUCCESS, PROFILE_FAILURE],
  };

  return {
    [CALL_API]: isAuthenticated
      ? Object.assign({}, callApiWithoutAuthentication, {
        authenticated: true,
      })
      : Object.assign({}, callApiWithoutAuthentication),
  };
};

export const updateProfile = (userId, data) => {
  const endpoint = `user/profile/${userId}`;
  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'patch',
      authenticated: true,
      types: [
        UPDATE_PROFILE_REQUEST,
        UPDATE_PROFILE_SUCCESS,
        UPDATE_PROFILE_FAILURE,
      ],
    },
  };
};

export const updateSettings = (data, userId) => {
  const endpoint = `user/user/${userId}`;

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'patch',
      authenticated: true,
      types: [
        UPDATE_SETTINGS_REQUEST,
        UPDATE_SETTINGS_SUCCESS,
        UPDATE_SETTINGS_FAILURE,
      ],
    },
  };
};

export const updateIRSettings = (data) => {
  const endpoint = 'user/save_ir_configuration ';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'put',
      authenticated: true,
      types: [
        UPDATE_IR_SETTINGS_REQUEST,
        UPDATE_IR_SETTINGS_SUCCESS,
        UPDATE_IR_SETTINGS_FAILURE,
      ],
    },
  };
};

export const requestIRSettings = (userId) => {
  const endpoint = `user/get-ir-configuration-setting/${userId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        GET_IR_SETTINGS_REQUEST,
        GET_IR_SETTINGS_SUCCESS,
        GET_IR_SETTINGS_FAILURE,
      ],
    },
  };
};

export const updateSkillsOrder = (data, userId) => {
  const endpoint = `user/profile/${userId}`;

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'patch',
      authenticated: true,
      types: [
        UPDATE_SKILLS_ORDER_REQUEST,
        UPDATE_SKILLS_ORDER_SUCCESS,
        UPDATE_SKILLS_ORDER_FAILURE,
      ],
    },
  };
};

export const updateInterestsOrder = (data, userId) => {
  const endpoint = `user/profile/${userId}`;

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'patch',
      authenticated: true,
      types: [
        UPDATE_INTERESTS_ORDER_REQUEST,
        UPDATE_INTERESTS_ORDER_SUCCESS,
        UPDATE_INTERESTS_ORDER_FAILURE,
      ],
    },
  };
};

export const deleteInterestsSection = (data) => {
  const endpoint = 'user/interests';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        DELETE_INTERESTS_SECTION_REQUEST,
        DELETE_INTERESTS_SECTION_SUCCESS,
        DELETE_INTERESTS_SECTION_FAILURE,
      ],
    },
  };
};

export const disableMessage = (userId, data) => {
  const endpoint = `user/enable-disable-messaging/${userId}`;
 
  return {
    [CALL_API]: {
      endpoint,
      data,
      method: 'patch',
      authenticated: true,
      types: [
        DISABLE_MESSAGE_REQUEST,
        DISABLE_MESSAGE_SUCCESS,
        DISABLE_MESSAGE_FAILURE,
      ],
    },
  };
};

export const disableBulkMessages = (data, id) => {
  const endpoint = !isEmpty(id) ? `user/enable-disable-messaging/${id}` : 'user/enable-disable-messaging';

  return {
    [CALL_API]: {
      endpoint,
      data: { ...data },
      method: 'patch',
      authenticated: true,
      types: [
        DISABLE_MESSAGE_REQUEST,
        DISABLE_MESSAGE_SUCCESS,
        DISABLE_MESSAGE_FAILURE,
      ],
    },
  };
};

export const getCompaniesList = () => {
  const endpoint = 'user/companies';

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        GET_COMPANY_LIST_REQUEST,
        GET_COMPANY_LIST_SUCCESS,
        GET_COMPANY_LIST_FAILURE,
      ],
    },
  };
};

export const getCountriesList = (countryId = null, stateId = null) => {
  let endpoint = 'countries';
  if(countryId) {
    endpoint = `${endpoint}?countryId=${countryId}`;
  } else if(stateId) {
    endpoint = `${endpoint}?stateId=${stateId}`;
  } 
  
  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        GET_COUNTRIES_LIST_REQUEST,
        GET_COUNTRIES_LIST_SUCCESS,
        GET_COUNTRIES_LIST_FAILURE,
      ],
    },
  };
};
