import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link } from 'react-router-dom';
import SkillBadgesTiles from '../../components/skill-badges/SkillBadgesTiles';
import Loading from '../../components/common/Loading';

import * as badgeActions from '../../redux/actions/badges';
import * as badgeEndorsementActions from '../../redux/actions/badge-endorsement';
import * as componentActions from '../../redux/actions/components';

import EndorsementModal from './EndorsementModal';
import LinkingPortfolioModal from './LinkingPortfolioModal';
import LinkingSkillsModal from './LinkingSkillsModal';
import { toastr } from 'react-redux-toastr';
import SkillBadgesFilterBar from '../../components/skill-badges/SkillBadgesFilterbar';

class SkillBadgesContainer extends Component {
  static propTypes = {
    modal: PropTypes.string,
    userId: PropTypes.string,
    actions: PropTypes.object.isRequired,
    badges: PropTypes.object.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    selectedBadges: PropTypes.array.isRequired,
    profile: PropTypes.object,
    page: PropTypes.number,
    layout: PropTypes.string,
    isOwner: PropTypes.bool,
    isVisiting: PropTypes.bool
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      searchValue: '',
      showHint: true,
      activeSort: 'newest',
      isDropdownOpen: false,
      isRequestingSort: false,
      isRequestingSearch: false,
      selectedBulkAction: 'none'
    };

    this.onApply = this.onApply.bind(this);
    this.badgeAction = this.badgeAction.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchKeyPress = this.onSearchKeyPress.bind(this);
    this.onSelectBulkChange = this.onSelectBulkChange.bind(this);
    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.onToggleDropdown = this.onToggleDropdown.bind(this);
    this.onSelectBadgeChange = this.onSelectBadgeChange.bind(this);
    this.onClearSearch = this.onClearSearch.bind(this);
    this.onHideHint = this.onHideHint.bind(this);
  }

  onApply() {
    const { badges, actions } = this.props;
    const { selectedBulkAction } = this.state;

    if (selectedBulkAction === 'none') {
      return toastr.info('Select action to apply bulk actions.');
    }

    if (badges.selectedBadges.length === 0) {
      return toastr.info('Select badges to apply bulk actions.');
    }

    actions.openModal(selectedBulkAction);
  }

  onSelectBulkChange(event) {
    const { value: modalName } = event.target;

    this.setState({ selectedBulkAction: modalName });
  }

  onHideHint(){
    this.setState({
      showHint: false
    });
  }

  onSelectBadgeChange(event) {
    const { value } = event.target;
    const { actions, selectedBadges } = this.props;
    const { saveSelectedBadges } = actions;
    const selectedBadge = selectedBadges.find(badge => badge.id === value);

    if (selectedBadge) {
      const badgesSelected = selectedBadges.filter(badge => badge.id !== value);

      return saveSelectedBadges(badgesSelected);
    }

    const badge = this.props.badges.data.find(badge => badge.id === value);

    saveSelectedBadges([...selectedBadges, badge]);
  }

  onSearchChange(event) {
    const { value: searchValue } = event.target;

    this.setState({ searchValue });
  }

  onSearchSubmit() {
    this.setState({
      isRequestingSearch: true
    }, this.fetchSortedBadges());

    this.props.actions.resetBadges();
  }

  onSortChange(event) {
    const { id: sortValue } = event.target;
    const { actions } = this.props;

    const sortedTextDisplay = this.state.activeSort.replace(/_/g, ' ').replace(/\w\S*/g, function(sortedText){
      return sortedText.charAt(0).toUpperCase() + sortedText.substr(1).toLowerCase();
    });

    if (sortValue === this.state.activeSort) {
      return toastr.warning(`Already sorted by ${sortedTextDisplay}.`);
    }

    this.setState({
      activeSort: sortValue,
      isDropdownOpen: false,
      isRequestingSort: true,
    }, () => this.fetchSortedBadges());

    actions.resetBadges();
  }

  onSearchKeyPress(event) {
    if (event.key === 'Enter')
      return this.onSearchSubmit();
  }

  onClearSearch(){
    const { activeSort } = this.state;
    const { actions } = this.props;
    this.setState({
      searchValue: ''
    }, () =>
      actions.requestAllBadges(1, activeSort)
        .then(() => this.setState({
          isRequestingSort: false,
          isRequestingSearch: false
        })));
  }

  fetchSortedBadges() {
    const { activeSort, searchValue } = this.state;
    const { actions } = this.props;

    actions.requestAllBadges(1, activeSort, searchValue)
      .then(() => this.setState({
        isRequestingSort: false,
        isRequestingSearch: false
      }));
  }

  onToggleDropdown() {
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
  }

  badgeAction(event, selectedBadge) {
    const { target } = event;
    const { actions } = this.props;
    const modalName = target.dataset.modalName;

    actions.saveSelectedBadges([selectedBadge]);
    actions.openModal(modalName);
  }

  render() {

    const {
      showHint,
      activeSort,
      searchValue,
      isDropdownOpen,
      isRequestingSort,
      isRequestingSearch,
      selectedBulkAction
    } = this.state;

    const {
      selectedBadges,
      badges,
      isRequesting,
      modal,
      layout,
      isOwner,
      profile,
      isVisiting
    } = this.props;

    const isEndorsementsOpen = ((selectedBadges.length > 0) && (modal === 'endorsementModal'));
    const isFilesModalOpen = (modal && modal === 'fileModal');
    const isSkillsModalOpen = ((selectedBadges.length > 0) && (modal === 'skillsModal'));

    const hasNoClaimedBadge = isVisiting ?
      badges.issuedBadges.data.filter(badge => badge.isClaimed).length === 0 :
      badges.data.filter(badge => badge.isClaimed).length === 0;

    const badgesWithNoEndorsementOrFiles = badges.data.filter(badge => (badge.fileCount === 0 && badge.endorsementCount === 0) && badge.isClaimed);

    if(isRequesting){
      return(
        <div className="loading-container">
          <Loading/>
        </div>
      );
    }

    return (
      <div>
        {isEndorsementsOpen && <EndorsementModal/>}
        {isFilesModalOpen && <LinkingPortfolioModal isOpen={isFilesModalOpen}/>}
        {isSkillsModalOpen && <LinkingSkillsModal isOpen={isSkillsModalOpen}/>}

        {isOwner &&
          <SkillBadgesFilterBar
            isOwner={isOwner}
            activeSort={activeSort}
            hasNoClaimedBadge={hasNoClaimedBadge}
            searchValue={searchValue}
            selectedBulkAction={selectedBulkAction}
            isDropdownOpen={isDropdownOpen}
            onSortChange={this.onSortChange}
            onSearchSubmit={this.onSearchSubmit}
            onApply={this.onApply}
            onToggleDropdown={this.onToggleDropdown}
            onSearchChange={this.onSearchChange}
            onSelectBulkChange={this.onSelectBulkChange}
            onSearchKeyPress={this.onSearchKeyPress}
            isRequestingSort={isRequestingSort}
            isRequestingSearch={isRequestingSearch}
            onClearSearch={this.onClearSearch}/>}

        {(showHint && isOwner && badgesWithNoEndorsementOrFiles.length > 0) &&
          <div className="alert alert-warning alert-dismissible" role="alert">
            <div className="row align-items-center">
              <div className="col-lg-auto mb-2">
                <i className="fa fa-exclamation-triangle fa-2x"/>
              </div>
              <div className="col">
                Your Skill Badges need portfolio files and endorsements to back them up and be meaningful when showcased on your Career Site. The following badges don't have any portfolio files or endorsements:
                <div className="mt-2 mb-0">
                  {badgesWithNoEndorsementOrFiles.map((badge, index) =>
                    (<span key={badge.id}>
                      <Link
                        className="alert-link"
                        to={`/skill-badges/badge-details/${badge.issued.id}`}>
                        {badge.name}
                      </Link>
                      {
                        index+1 !== badgesWithNoEndorsementOrFiles.length &&
                          <span>, </span>
                      }
                    </span>))}
                </div>
              </div>
              <button type="button" className="close" onClick={this.onHideHint} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>}

        <SkillBadgesTiles
          profile={profile}
          layout={layout}
          isOwner={isOwner}
          modal={modal}
          hasNoClaimedBadge={hasNoClaimedBadge}
          badges={isVisiting ? badges.issuedBadges.data : badges.data}
          emptySearchResult={badges.emptySearchResult}
          callingAPI={isRequesting}
          selectedBadges={selectedBadges}
          isRequestingSort={isRequestingSort}
          isRequestingSearch={isRequestingSearch}
          badgeAction={this.badgeAction}
          onSelectBadgeChange={this.onSelectBadgeChange}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, badges, components } = state;

  return {
    badges,
    userId: auth.data.currentUser.id,
    selectedBadges: badges.selectedBadges,
    modal: components.modal
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    badgeActions,
    componentActions,
    badgeEndorsementActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(SkillBadgesContainer);
