import React from 'react';

import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';

import CloseIcon from '../../assets/images/close.svg';

const HideFromCareerSiteModal = ({
  isOpen,
  toggleModal,
  confirmHide,
  isBadge,
  resourceId,
}) => {
  
  function setLocalStorage(event){
    localStorage.setItem([`${resourceId}_hide`], event.target.checked);
  }

  return (
    <Modal
      centered
      className="customLevelUpModal"
      isOpen={isOpen}
      toggle={toggleModal}>
      <div className="modal-header">
        {isBadge && (
          <span className="modal-title">
            Hide from Displaying on Career Site
          </span>
        )}
        {!isBadge && (
          <span className="modal-title">
            Unlink Skill and Its Evidence Page on Career Site
          </span>
        )}
        <div
          onClick={toggleModal}
          className="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">
            <img src={CloseIcon} />
          </span>
        </div>
      </div>

      <ModalBody>
        {isBadge && (
          <p>
            This will hide your Skill Badge from displaying on your Career Site
            until you're ready to display it.
          </p>
        )}
        {!isBadge && (
          <p>
            This will remove the link from skill to its Evidence page on your
            Career Site.
          </p>
        )}
        <input
          className="mr-1"
          type="checkbox"
          onChange={setLocalStorage}/>
        <span>Don't show me again</span>
      </ModalBody>
      <ModalFooter className="bordertop-0">
        <Button className="ml-1 btn btn-secondary" onClick={toggleModal}>
          Cancel
        </Button>
        <Button className="ml-1 sendButton" onClick={confirmHide}>
          Okay
        </Button>
      </ModalFooter>
    </Modal>
  );
};

HideFromCareerSiteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  confirmHide: PropTypes.func.isRequired,
  isBadge: PropTypes.bool.isRequired,
  resourceId: PropTypes.string,
};

export default HideFromCareerSiteModal;