import PropTypes from 'prop-types';
import React from 'react';

import MessageListItemsWrapper from '../../containers/messages/MessageListItemsWrapper';

const MessagesList = ({
  toggleMessageModal,
  slug,
  isMobile,
  onChangeSearchText,
  searchText,
  query,
  handleKeyPress,
  onSubmitSearch,
  onClearSearch,
  isSearching,
  totalMessage
}) => {

  return(
    <div id="messagingListView" className="col-lg-4">
      <div className="messageListViewHeader">
        <h3>Messages ({totalMessage})</h3>
        <a onClick={toggleMessageModal} className="clickable">
          <button className="float-right btn-primary btn">
            <i style={{marginRight: 5}} className="fas fa-pencil-alt"/>&nbsp;Compose
          </button>
        </a>
      </div>
      <div className="messageListViewSearch">
        <form
          onSubmit={onSubmitSearch}
          onKeyPress={handleKeyPress}>
          <span className="messages-search-icon">
            {
              isSearching && query !== '' ?
                <i className="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"/> :
                <i className="fa fa-search" aria-hidden="true"/>
            }
          </span>
          <input
            id="messageSearch"
            type="text"
            className="form-control message-search"
            value={searchText}
            onChange={onChangeSearchText}
            placeholder="Search Messages..." />

          {
            query !== '' &&
              <span
                onClick={onClearSearch}
                className="messages-clear-icon clickable">
                <i className="fa fa-times" aria-hidden="true"/>
              </span>
          }

        </form>
      </div>

      <MessageListItemsWrapper
        isMobile={isMobile}
        slug={slug}/>
    </div>
  );
};

MessagesList.propTypes = {
  slug: PropTypes.string,
  toggleMessageModal: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  onChangeSearchText: PropTypes.func,
  searchText: PropTypes.string,
  query: PropTypes.string,
  onSubmitSearch: PropTypes.func,
  handleKeyPress: PropTypes.func,
  isSearching: PropTypes.bool,
  onClearSearch: PropTypes.func,
  totalMessage: PropTypes.number
};

export default MessagesList;
