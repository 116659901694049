import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as endorsementActions from '../../../redux/actions/badge-endorsement';
import * as badgeActions from '../../../redux/actions/badges';
import * as filesActions from '../../../redux/actions/files';
import * as componentActions from '../../../redux/actions/components';

import moment from 'moment';
import classNames from 'classnames';

import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { formatFormDataArray } from '../../../utils';
import { isEmpty } from 'lodash';

const visibilityOptions = [
  {id: 'public', name: 'Public', icon: 'fa fa-globe', desc: 'viewable to anyone' },
  {id: 'followers', name: 'Followers Only', icon: 'fa fa-user-circle-o' },
  {id: 'private', name: 'Private', icon: 'fa fa-lock', desc: 'viewable only to me' },
];

class CredentialVisibilityConfirmation extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    selectedBadges: PropTypes.array.isRequired,
    privateFiles: PropTypes.array.isRequired,
    isUpdating: PropTypes.bool,
    noOfPubicFiles: PropTypes.number,
    getMyBadges: PropTypes.func,
    getMySkills: PropTypes.func,
    linkingSkill: PropTypes.bool,
    onLinkedSkill: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);

    this.state={
      linkedFiles: 0,
      active: 0,
      done: false,
      selectedVisibiltyId: props.privateFiles[0].visibility,
      isDropupOpen: false,
      privateFiles: props.privateFiles
    };

    this.closeLinkFilesRequest = this.closeLinkFilesRequest.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onLinkFile = this.onLinkFile.bind(this);
    this.onToggleDropup = this.onToggleDropup.bind(this);
    this.onSkipFile = this.onSkipFile.bind(this);

  }

  closeLinkFilesRequest(){
    if(this.props.onLinkedSkill){
      this.props.onLinkedSkill();
    }
    this.props.actions.resetSelectedBadges();
    this.props.actions.closeModal();
  }

  onCancel(){
    this.setState({
      done: true
    });
  }

  onLinkFile(){
    const { active } = this.state;
    const { privateFiles, selectedBadges } = this.props;

    const badgeIssuedIds = selectedBadges.map((badge) => {
      if(badge.issued.id) {
        return badge.issued.id;
      } else {
        return badge.id;
      }
    });
    const fileId = [privateFiles[active].id];

    this.updateFileVisibilty(privateFiles[active], fileId, badgeIssuedIds);
  }

  onSkipFile(){
    const { active } = this.state;
    const { privateFiles } = this.props;
    this.setState({
      active: active + 1,
      selectedVisibiltyId: privateFiles[active+1].visibility
    });
  }

  updateFileVisibilty(file, fileId, badgeIssuedIds){
    const { actions, privateFiles, linkingSkill, getMyBadges, getMySkills } = this.props;
    const { selectedVisibiltyId, active, linkedFiles } = this.state;
    let formData = new FormData();
    formData.append('description', file.discription);
    formData.append('title', file.title);
    formData = formatFormDataArray(formData, 'badges', badgeIssuedIds);
    formData.append('visibility', selectedVisibiltyId);
    formData = formatFormDataArray(formData, 'skills', (!isEmpty(file.skills)) ? file.skills : badgeIssuedIds);
    formData.append('externalurl', file.url);

    actions.updateFile(file.id, formData)
      .then(() => {
        if(getMySkills || getMyBadges) {
          linkingSkill ?
            getMySkills(null, null, 1, null, true) :
            getMyBadges(null, null, 1, null, true);
        }
        if(active + 1 == privateFiles.length){
          return this.setState({
            linkedFiles: linkedFiles + 1,
            done: true
          });
        }
        this.setState({
          active: active + 1,
          selectedVisibiltyId: privateFiles[active+1].visibility,
          linkedFiles: linkedFiles + 1
        });
      });
  }

  onToggleDropup(){
    this.setState({
      isDropupOpen: !this.state.isDropupOpen
    });
  }

  onSelectVisibilty(id){
    return () => {
      this.setState({
        selectedVisibiltyId: id,
        isDropupOpen: false
      });
    };
  }

  render() {
    const { active, done, isDropupOpen, selectedVisibiltyId, linkedFiles } = this.state;
    const { privateFiles, isUpdating, noOfPubicFiles } = this.props;
    const enableLinkFileButton = selectedVisibiltyId === 'public';

    if(done){
      return(
        <div>
          <div className="modal-body p-2">
            <div className="success-request">{noOfPubicFiles + linkedFiles} Portfolio Files have been linked successfully!</div>
          </div>
          <div id="link-success" className="modal-footer">
            <button
              name="button"
              type="button"
              onClick={this.closeLinkFilesRequest}
              className="btn btn-primary">
              Done
            </button>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div id="portfolio-visibility" className="modal-body p-2">
          <div className="alert alert-warning alert-dismissible">
            Portfolio items linked to Skill Badges as evidence must be set to <strong>Public</strong>. Please set visibility to <strong>Public</strong> below to continue linking this file to your badge.
          </div>
          <h6>File {active+1} of {privateFiles.length}</h6>
          <div id="portfolio">
            <div className="row">
              {
                privateFiles.map((file,index) => {
                  if(index === active){
                    const { url, mimetype, title, modified } = file;
                    const dateString = moment(modified.date).format('MM/DD/YYYY');
                    const thumbnail = url;
                    const docFile = mimetype.includes('application');

                    const selectedVisibilty = visibilityOptions.find(option => option.id == selectedVisibiltyId) ;

                    return(
                      <div key={file.id} className="col-sm-12 col-md-12 col-lg-4 col-xl-3">
                        <div className="port-file">
                          <span>
                            {docFile &&
                              <div className="thumb file-icon-container">
                                <i className="fa fa-file"/>
                              </div>}

                            {mimetype === '' &&
                              <div className="thumb file-icon-container">
                                <i className="fa fa-youtube-play"/>
                              </div>}

                            {!docFile && mimetype !== '' &&
                              <img src={thumbnail} className="thumb"/>}
                          </span>
                          <div className="file-details">
                            <h3 className="mb-0">{title}</h3>
                            <span className="small">{dateString}</span>
                            <div className="dropdown dropup visibility portfolio-files-visibility mt-1">
                              <Dropdown
                                toggle={this.onToggleDropup}
                                isOpen={isDropupOpen}>
                                <DropdownToggle
                                  onClick={this.onToggleDropup}
                                  id="dropdownMenuButton">
                                  <i className={selectedVisibilty?.icon}/>{selectedVisibilty?.name}
                                </DropdownToggle>
                                <DropdownMenu>
                                  {
                                    visibilityOptions.map((option) =>{
                                      if(option.id === 'public'){
                                        return (
                                          <a
                                            key={option.id}
                                            className={classNames(
                                              'dropdown-item',
                                              { 'active-visibilty': option.id == selectedVisibiltyId}
                                            )}
                                            onClick={this.onSelectVisibilty(option.id)}>
                                            <i className={option.icon}/>{option.name}
                                            {option.desc && <span>{option.desc}</span>}
                                          </a>);
                                      }

                                      return (
                                        <span key={option.id} className="not-clickable dropdown-item-cus">
                                          <i className={option.icon}/>{option.name}
                                          {option.desc && <span>{option.desc}</span>}
                                        </span>
                                      );
                                    })
                                  }
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })
              }
            </div>
          </div>
        </div>
        <div id="skip-next" className="modal-footer">
          <button
            type="button"
            onClick={this.onCancel}
            disabled={isUpdating}
            className="btn btn-secondary">
            Cancel
          </button>
          {
            privateFiles.length > 1 && (active + 1 !== privateFiles.length) &&
              <button
                type="button"
                disabled={isUpdating}
                style={{marginLeft: 5}}
                onClick={this.onSkipFile}
                className="btn btn-secondary">
                Skip File
              </button>
          }

          <button
            style={{marginLeft: 5}}
            type="button"
            disabled={!enableLinkFileButton}
            onClick={this.onLinkFile}
            className="btn btn-primary">
            {isUpdating ? 'Linking...':'Link File'}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedBadges: state.badges.selectedBadges,
    isUpdating: state.files.isUpdating
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, endorsementActions, badgeActions, filesActions, componentActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CredentialVisibilityConfirmation);
