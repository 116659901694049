import PropTypes from 'prop-types';
import React from 'react';

const TotalUsersCell = props => <strong>{props.organization.usersCount}</strong>;

TotalUsersCell.propTypes = {
  organization: PropTypes.object.isRequired
};

export default TotalUsersCell;
