import React, { Component } from 'react';


import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import GiveFeedbackModal from './GiveFeedbackModal.js';
import RequestFeedbackModal from './RequestFeedbackModal.js';

class FeedbackWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      giveFeedback: false,
      requestFeedback: false,
    };
  }

  toggleFeedbackModal = () => {
    this.setState((state) => {
      return {
        giveFeedback: !state.giveFeedback,
      };
    });
  };

  toggleRequestFeedbackModal = () => {
    this.setState((state) => {
      return {
        requestFeedback: !state.requestFeedback,
      };
    });
  };

  render() {
    return (
      <>
        <div>
          <Helmet title="360 Feedback" />
          <div className="container">
            <div className="row">
              <button
                className="btn btn-primary"
                onClick={this.toggleFeedbackModal}>
                Give Feedback Modal
              </button>
              <button
                className="btn btn-primary"
                onClick={this.toggleRequestFeedbackModal}>
                Request Feedback Modal
              </button>
              {this.state.requestFeedback && (
                <RequestFeedbackModal
                  isOpen={this.state.requestFeedback}
                  onCancel={this.toggleRequestFeedbackModal}/>
              )}
              {this.state.giveFeedback && (
                <GiveFeedbackModal
                  isOpen={this.state.giveFeedback}
                  onCancel={this.toggleFeedbackModal}/>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

FeedbackWrapper.propTypes = {
  isAuthenticated: PropTypes.bool,
  profile: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { profile } = state;

  return {
    isAuthenticated: state.auth.isAuthenticated,
    profile: profile.data,
  };
};

export default connect(mapStateToProps)(FeedbackWrapper);
