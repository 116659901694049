import React from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { get } from 'lodash';
import classNames from 'classnames';

const styles = {
  editButton: {
    marginLeft: 16,
    cursor: 'pointer',
  },
};

const SkillCard = ({
  skill,
  isPublicProfile,
  onEditSkillClick,
  connectDragSource,
  setSkillBeingUpdatedInStore,
  toggleComponents
}) => {
  const showHiddenSkills = toggleComponents.includes('show-hidden-skills');

  const skillPillClassNames = classNames('skill-pill', {
    'visible-evidence': showHiddenSkills || get(skill, 'visibility', 1) === 1
  });

  return (
    <div className="skill">
      <div className={skillPillClassNames}>
        {skill && (showHiddenSkills || get(skill, 'visibility', 1) === 1) ?
          <Link
            to= {`/skill-evidence/${skill.id}`}
            onClick={setSkillBeingUpdatedInStore}>
            <i className="fa fa-link link-icon"/>
            <span style={styles.skillName}>{skill.name}</span>
          </Link> :
          <span style={styles.skillName}>{skill.name}</span>}

        {!isPublicProfile &&
          <span className="float-right skill-actions">
            <i
              style={styles.editButton}
              onClick={onEditSkillClick}
              id={`update-skill-form-${skill.id}`}
              className="fa fa-pencil"/>

            {connectDragSource(
              <i
                style={{cursor: 'move', marginLeft: 10}}
                className="fa fa-arrows"/>)}
          </span>}
      </div>
    </div>
  );
};

SkillCard.propTypes = {
  profile: PropTypes.object,
  skill: PropTypes.object.isRequired,
  isPublicProfile: PropTypes.bool.isRequired,
  onEditSkillClick: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  setSkillBeingUpdatedInStore: PropTypes.func,
  toggleComponents: PropTypes.array.isRequired,
};

const mapStateToProps = ({ components: { toggleComponents } }) => ({ toggleComponents });

export default connect(mapStateToProps)(SkillCard);
