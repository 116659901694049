import PropTypes from 'prop-types';
import React from 'react';

import BadgeSupportingEvidence from './BadgeSupportingEvidence';
import ConfirmResetFileModal from './ConfirmResetFileModal';
import { Tooltip } from 'reactstrap';
import JustificationQuestionForm from '../../../containers/skill-builders/JustificationQuestionForm';

const BadgeSupportingStatement = ({
  unit,
  badge,
  errors,
  issuedBadge,
  statementGuidelinesTooltipOpen,
  toggleStatementGuidelinesTooltip,
  onChangeAddPortfolio,
  addPortfolioRadio,
  selectedFile,
  onRemoveFile,
  onAddEvidence,
  isAuthenticated,
  onChangeSelectedFile,
  evidenceGuidelinesTooltipOpen,
  toggleEvidenceGuidelinesTooltip,
  onCancelResetFile,
  onConfirmResetFile,
  displayLinkingPortfolioModal,
  onDisplayLinkingPortfolioModal,
  selectedFiles,
  onSelectFile,
  onDeleteLoadedFile,
  onInsertLinkClicked,
  showInsertTextBox,
  guidelinesTooltipOpen,
  onDropFile,
  toggleGuidelinesTooltip
}) => {
  const { files } = issuedBadge;
  const pointerStyle = {
    color: '#ff5000',
    cursor: 'pointer'
  };
  const showAddPortfolioForm = addPortfolioRadio == '1' || files.length > 0;
  const showConfirmFileReset = addPortfolioRadio == '2' && files.length > 0;

  return (
    <div className="claim-item">
      <h5><strong>Step 2. Justify Your Skill Claim</strong> <span className="small text-danger"/></h5>
      <p className="mb-4">
        If you have a skill, you're expected to be able to articulate your skill and share stories of using it to achieve positive results. Employers assess your skill claims via interviews. Thus, to justify your skill claim which this Skill Badge represents, provide quality responses to the questions below. They'll be shown in your Skill Badge and <b>publicly visible</b>. Ensure your writing is free of typos.&nbsp;
        <a id="supporting-statement-guidelines" style={pointerStyle}>(See guidelines)</a><br/><br/>
        <i>*Note: If you are unable to answer the questions, it may be a sign you haven't acquired the skill, so it may be too early to claim your skill badge.</i>
        <Tooltip
          placement="top"
          isOpen={statementGuidelinesTooltipOpen}
          target="supporting-statement-guidelines"
          toggle={toggleStatementGuidelinesTooltip}>
          <div className="inside-tooltip" style={{fontSize: 12}}>
            <b>Guidelines for Written Justifications</b>
            <ul style={{padding: '10px 0px 20px 20px', textAlign: 'left', marginBottom: 0}}>
              <li>Write in the first-person voice (I, Me, My)</li>
              <li>Be specific when sharing examples of how you've applied your claimed abilities in your experience</li>
              <li>Clearly explain how your examples provide evidence of your claimed abilities.</li>
              <li>Be concise but include all relevant details</li>
              <li>Don’t share confidential or proprietary information</li>
              <li>Don’t have grammatical or spelling errors</li>
            </ul>
          </div>
        </Tooltip>
      </p>

      {badge.justifications.map(justification =>
        (<JustificationQuestionForm
          key={justification.question.id}
          unit={unit}
          badge={badge}
          justification={justification} />))}

      <h6 className="mt-2">
        <strong>Want to add portfolio files to further support your claimed skill?</strong>
        <small> (You can add later too)</small>
      </h6>
      <p className="mt-0 mb-0">
        <label>
          <input
            type="radio"
            name="supporting-evidence"
            checked={addPortfolioRadio == '1'}
            onChange={onChangeAddPortfolio}
            value="1"/>&nbsp;&nbsp;Yes, I would like to add files.
        </label>
      </p>
      <p className="mt-0 mb-0">
        <label>
          <input
            type="radio"
            name="supporting-evidence"
            checked={addPortfolioRadio == '2'}
            onChange={onChangeAddPortfolio}
            value="2"/>&nbsp;&nbsp;Not right now.
        </label>
      </p>

      {showAddPortfolioForm &&
        <BadgeSupportingEvidence
          badge={badge}
          errors={errors}
          issuedBadge={issuedBadge}
          selectedFile={selectedFile}
          onRemoveFile={onRemoveFile}
          onAddEvidence={onAddEvidence}
          isAuthenticated={isAuthenticated}
          onChangeSelectedFile={onChangeSelectedFile}
          evidenceGuidelinesTooltipOpen={evidenceGuidelinesTooltipOpen}
          toggleEvidenceGuidelinesTooltip={toggleEvidenceGuidelinesTooltip}
          displayLinkingPortfolioModal={displayLinkingPortfolioModal}
          onDisplayLinkingPortfolioModal={onDisplayLinkingPortfolioModal}
          selectedFiles={selectedFiles}
          onSelectFile={onSelectFile}
          onDeleteLoadedFile={onDeleteLoadedFile}
          onInsertLinkClicked={onInsertLinkClicked}
          showInsertTextBox={showInsertTextBox}
          guidelinesTooltipOpen={guidelinesTooltipOpen}
          onDropFile={onDropFile}
          toggleGuidelinesTooltip={toggleGuidelinesTooltip}/>}

      {showConfirmFileReset &&
        <ConfirmResetFileModal
          files={files}
          isOpen={showConfirmFileReset}
          onCancel={onCancelResetFile}
          onConfirm={onConfirmResetFile}/>}
    </div>
  );
};

BadgeSupportingStatement.propTypes = {
  unit: PropTypes.object.isRequired,
  badge: PropTypes.object,
  errors: PropTypes.object.isRequired,
  issuedBadge: PropTypes.object.isRequired,
  onChangeStatement: PropTypes.func.isRequired,
  statementGuidelinesTooltipOpen: PropTypes.bool.isRequired,
  toggleStatementGuidelinesTooltip: PropTypes.func.isRequired,
  onChangeAddPortfolio: PropTypes.func,
  addPortfolioRadio: PropTypes.string,
  selectedFile: PropTypes.object.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  onAddEvidence: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  onChangeSelectedFile: PropTypes.func.isRequired,
  evidenceGuidelinesTooltipOpen: PropTypes.bool.isRequired,
  toggleEvidenceGuidelinesTooltip: PropTypes.func.isRequired,
  onCancelResetFile: PropTypes.func.isRequired,
  onConfirmResetFile: PropTypes.func.isRequired,
  selectedFiles: PropTypes.array.isRequired,
  onSelectFile: PropTypes.func.isRequired,
  onDeleteLoadedFile: PropTypes.func.isRequired,
  onInsertLinkClicked: PropTypes.func.isRequired,
  showInsertTextBox: PropTypes.bool.isRequired,
  guidelinesTooltipOpen: PropTypes.bool.isRequired,
  onDropFile: PropTypes.func.isRequired,
  toggleGuidelinesTooltip: PropTypes.func.isRequired,
  displayLinkingPortfolioModal: PropTypes.bool,
  onDisplayLinkingPortfolioModal: PropTypes.func.isRequired
};

export default BadgeSupportingStatement;
