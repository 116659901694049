import initialState from './initialState';

import {
  AWARDS_REQUEST,
  AWARDS_SUCCESS,
  AWARDS_FAILURE,
  CREATE_AWARD_REQUEST,
  CREATE_AWARD_SUCCESS,
  CREATE_AWARD_FAILURE,
  UPDATE_AWARD_REQUEST,
  UPDATE_AWARD_SUCCESS,
  UPDATE_AWARD_FAILURE,
  DELETE_AWARD_REQUEST,
  DELETE_AWARD_SUCCESS,
  DELETE_AWARD_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.awards, action) {
  switch (action.type) {
  case AWARDS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case AWARDS_SUCCESS:
    return Object.assign({}, state, {
      data: action.data._embedded.awards,
      isRequesting: false,
    });

  case AWARDS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case CREATE_AWARD_REQUEST:
    return Object.assign({}, state, { isSubmitting: true });

  case CREATE_AWARD_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data, Object.assign({}, action.data)],
      isSubmitting: false,
    });

  case CREATE_AWARD_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case UPDATE_AWARD_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, awardIndex: action.index },
    });

  case UPDATE_AWARD_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, state.isUpdating.awardIndex),
        Object.assign({}, action.data),
        ...state.data.slice(state.isUpdating.awardIndex + 1),
      ],
      isUpdating: { status: false, awardIndex: null },
    });

  case UPDATE_AWARD_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false, awardIndex: null },
    });

  case DELETE_AWARD_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: action.id },
    });

  case DELETE_AWARD_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.filter(award => award.id !== state.isDeleting.id),
      ],
      isDeleting: { status: false, id: null },
    });

  case DELETE_AWARD_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: { status: false, id: null },
    });

  default:
    return state;
  }
}
