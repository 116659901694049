import PropTypes from 'prop-types';
import React from 'react';

const LocationCard = ({ profile }) => {
  return (
    <div>
      {profile.city ? profile.city.label : ''}

      {profile.state ?
        ` | ${profile.state.label}` : ''}
    </div>
  );
};

LocationCard.propTypes = {
  profile: PropTypes.object.isRequired
};

export default LocationCard;
