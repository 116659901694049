import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';

import * as authenticationAction from '../../redux/actions/authentication';

import CreatePassword from '../invite-pages/create-password/CreatePassword';
import Relation from '../invite-pages/Relation';
import { withHooks } from '../../utils/withHooks';
import {
  INVITATION_ACCEPTED,
  GO_TO,
  HOME_PAGE,
  SIGN_IN_INFO_MESSAGE,
} from '../../constants/invite';

class CreatePasswordForPeer extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    params: PropTypes.object,
    profile: PropTypes.object,
    auth: PropTypes.object,
    navigate: PropTypes.func,
    router: PropTypes.object,
    feedbackUserDetail: PropTypes.object.isRequired,
    password: PropTypes.string,
    setFeedbackData: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      relationModal: false,
      createPasswordModal: true,
      password: null,
      tokenIsUsed: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.proceed = this.proceed.bind(this);
    this.submitData = this.submitData.bind(this);
  }

  submitData(modal, relationValue) {
    const {feedbackUserDetail, actions, setFeedbackData} = this.props;
    
    if(modal === 'relationModal'){
      actions.createPasswordForPeer({
        token: feedbackUserDetail?.token,
        name: feedbackUserDetail?.giverName,
        requesterId: feedbackUserDetail?.requesterId,
        relationWithInvitee: relationValue, 
        password: this.state.password,
      }).then(() => {
        setFeedbackData();
      });
    }
  }
 
  proceed(modal, data) {
    let arr = ['relationModal', 'createPasswordModal'];

    this.setState((state) => { 
      const newState = {password: data.password};
      arr.forEach((a) => {
        if (a === modal) {
          newState[a] = !state[modal];
        } else {
          newState[a] = false;
        }
      });
      return newState;
    });     
  }

  toggleModal(modal) {
    let arr = ['relationModal', 'createPasswordModal'];
  
    this.setState((state) => { 
      const newState = {};
      arr.forEach((a) => {
        if (a === modal) {
          newState[a] = !state[modal];
        } else {
          newState[a] = false;
        }
      });
      return newState;
    }); 
  }

  render() {
    const {feedbackUserDetail} = this.props;

    return (
      <div className="container">
        <div className="row">
          <CreatePassword
            isOpen= {this.state.createPasswordModal}
            isProvideFeedback
            toggleModal={this.toggleModal}
            proceed={this.proceed}
            username={feedbackUserDetail?.requesterName}/>         
          <Relation
            isOpen={this.state.relationModal}
            toggleModal={this.toggleModal}
            name={feedbackUserDetail?.requesterName}
            proceed={this.submitData}/>
          {this.state.tokenIsUsed && (
            <div className="invitation-body">
              <div className="accept-invitation-message">
                <p className="mb-2">
                  Join
                  <img
                    src={require('../../assets/images/CP_logo_no-mark.png')}
                    className="img-responsive ml-2"
                    height="16"
                    alt="CareerPrepped"/>
                </p>
                <p>
                  {INVITATION_ACCEPTED}
                  {[
                    `${!this.props.profile?.name}` && `${GO_TO}`,
                    <a href="/" key={this.props.profile?.id || '0'}>
                      {HOME_PAGE}
                    </a>,
                    SIGN_IN_INFO_MESSAGE,
                  ]}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.data,
    auth: state.auth,
    router: state.router,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, authenticationAction);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(CreatePasswordForPeer));
