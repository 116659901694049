import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PublicationCard from '../../../components/profile/sections/PublicationCard';

class PublicationCardHandler extends Component {
  static propTypes = {
    publication: PropTypes.object.isRequired,
    isPublicProfile: PropTypes.bool.isRequired,
    onEdit: PropTypes.func
  }

  constructor(props, context) {
    super(props, context);

    this.onEditPublicationClick = this.onEditPublicationClick.bind(this);
  }

  onEditPublicationClick(event) {
    event.preventDefault();
    const { id } = this.props.publication;

    this.props.onEdit(id);
  }

  render() {
    const { publication, isPublicProfile } = this.props;

    return (
      <div className="profile-item__card-list">
        <PublicationCard
          publication={publication}
          isPublicProfile={isPublicProfile}
          onEditPublicationClick={this.onEditPublicationClick}/>
      </div>
    );
  }
}

export default PublicationCardHandler;
