import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withHooks } from '../../utils/withHooks';

import GlobalCompetenceSurveyForm from '../../components/toolbox/GlobalCompetenceSurveyForm';

import surveyQuestions from '../../components/toolbox/global-competence-survey-questions';

import { submitSurveyFeedback } from '../../redux/actions/registration';

class GlobalCompetenceSurvey extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      surveyQuestions: [
        ...surveyQuestions
      ],
      isValidFeedback: false
    };

    this.onSubmitFeedback = this.onSubmitFeedback.bind(this);
    this.onChangeFeedback = this.onChangeFeedback.bind(this);
  }

  isValidFeedback() {
    const { surveyQuestions } = this.state;

    const feedbacks = surveyQuestions.filter(item => (item.answer !== ''));

    const isValidFeedback = feedbacks.length === surveyQuestions.length;

    this.setState({ isValidFeedback });
  }

  onChangeFeedback(event) {
    const { value, name } = event.target;

    let { surveyQuestions } = this.state;

    const questionIndex = surveyQuestions.findIndex(question => question.id === name);
    const newSurveyQuestion = Object.assign({},
      surveyQuestions[questionIndex], {
        answer: value
      });

    surveyQuestions = [
      ...surveyQuestions.slice(0, questionIndex),
      newSurveyQuestion,
      ...surveyQuestions.slice(questionIndex + 1)
    ];

    this.setState({ surveyQuestions }, () => this.isValidFeedback());
  }

  onSubmitFeedback() {
    const { surveyQuestions } = this.state;
    const { actions, navigate } = this.props;

    const feedbacks = surveyQuestions.map(({ question, answer }) => ({ question, answer }));

    const data = { feedbacks };

    actions.submitSurveyFeedback(data)
      .then((state) => {
        if (!state.registration.isSubmitting) {
          navigate('/resources/global-competence-survey-success');
        }
      });
  }

  render() {
    const { isSubmitting } = this.props;
    const { surveyQuestions, isValidFeedback } = this.state;

    return (
      <div className="general-interior">
        <Helmet title="Global Competence Survey"/>
        <div className="content subnav essential">
          <div className="bread">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/resources">Career Resources</Link></li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to="/resources/global-competence-modules">
                  Global Competence Modules
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Global Competence Survey</li>
            </ol>
          </div>
        </div>

        <div className="content targeted-resume">
          <div className="row">
            <GlobalCompetenceSurveyForm
              isSubmitting={isSubmitting}
              surveyQuestions={surveyQuestions}
              isValidFeedback={isValidFeedback}
              onSubmitFeedback={this.onSubmitFeedback}
              onChangeFeedback={this.onChangeFeedback}/>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isSubmitting: state.registration.isSubmitting
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ submitSurveyFeedback }, dispatch)
});

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(GlobalCompetenceSurvey));
