import initialState from './initialState';

import {
  RESET_OCCUPATIONS,
  OCCUPATIONS_REQUEST,
  OCCUPATIONS_SUCCESS,
  OCCUPATIONS_FAILURE
} from '../../constants';

export default function reducer(state = initialState.occupations, action) {
  switch (action.type) {
  case OCCUPATIONS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case OCCUPATIONS_SUCCESS:
    return Object.assign({}, state, {
      isRequesting: false,
      page: action.data.page,
      pageCount: action.data.page_count,
      data: action.data._embedded.career_clusters
    });

  case OCCUPATIONS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false
    });

  case RESET_OCCUPATIONS:
    return Object.assign({}, state, { ...initialState.occupations });

  default:
    return state;
  }
}
