import React from 'react';

import PropTypes from 'prop-types';
import Situation from '../../../containers/profile/sections/Situation';
import { Modal, ModalBody } from 'reactstrap';
import CloseIcon from '../../../assets/images/close.svg';

function AddWorkSituation({isOpen, toggleModal}) {	
  function onToggleSectionEdit(){
    toggleModal();
  }

  return (
    <Modal
      centered
      className="customLevelUpModal"
      isOpen={isOpen}
      toggle={toggleModal}>
      <div className="modal-header">
        <span className="modal-title">Add Work Situation</span>
        <div
          onClick={toggleModal}
          className="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">
            <img src={CloseIcon} />
          </span>
        </div>
      </div>
      <ModalBody>
        <Situation
          onToggleSectionEdit={onToggleSectionEdit}
          isEditingSections
          isPublicProfile={false}
          isEditingFromModal
          onCancelModal={toggleModal}
          isEditing/>
      </ModalBody>
    </Modal>
  );
}

AddWorkSituation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default AddWorkSituation;