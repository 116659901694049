import { CALL_API } from '../../middlewares/api';

import {
  INITIALIZE_REFLECTION_STORE,
  REFLECTIONS_REQUEST,
  REFLECTIONS_SUCCESS,
  REFLECTIONS_FAILURE,
  CREATE_REFLECTION_REQUEST,
  CREATE_REFLECTION_SUCCESS,
  CREATE_REFLECTION_FAILURE,
  UPDATE_REFLECTION_REQUEST,
  UPDATE_REFLECTION_SUCCESS,
  UPDATE_REFLECTION_FAILURE,
  SHARE_REFLECTIONS_REQUEST,
  SHARE_REFLECTIONS_SUCCESS,
  SHARE_REFLECTIONS_FAILURE,
  DELETE_REFLECTION_REQUEST,
  DELETE_REFLECTION_SUCCESS,
  DELETE_REFLECTION_FAILURE,
  SET_REFLECTION_DELETE_ID,
  DOWNLOAD_REFLECTIONS_REQUEST,
  DOWNLOAD_REFLECTIONS_SUCCESS,
  DOWNLOAD_REFLECTIONS_FAILURE,
} from '../../constants';

export const initializeReflectionStore = () => {
  return dispatch => dispatch({ type: INITIALIZE_REFLECTION_STORE });
};

export const reflectionsRequest = (unitId) => {
  const endpoint = `skillbuilder/reflection?unit=${unitId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [REFLECTIONS_REQUEST, REFLECTIONS_SUCCESS, REFLECTIONS_FAILURE],
    },
  };
};

export const saveReflection = (data) => {
  const endpoint = 'skillbuilder/reflection';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        CREATE_REFLECTION_REQUEST,
        CREATE_REFLECTION_SUCCESS,
        CREATE_REFLECTION_FAILURE,
      ],
    },
  };
};

export const updateReflection = (data, refId) => {
  const endpoint = `skillbuilder/reflection/${refId}`;

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'put',
      authenticated: true,
      types: [
        UPDATE_REFLECTION_REQUEST,
        UPDATE_REFLECTION_SUCCESS,
        UPDATE_REFLECTION_FAILURE,
      ],
    },
  };
};

export const deleteReflection = (reflectionId) => {
  const endpoint = `skillbuilder/reflection/${reflectionId}`;

  return {
    [CALL_API]: {
      id: reflectionId,
      endpoint,
      method: 'delete',
      authenticated: true,
      types: [
        DELETE_REFLECTION_REQUEST,
        DELETE_REFLECTION_SUCCESS,
        DELETE_REFLECTION_FAILURE,
      ],
    },
  };
};

export const shareReflections = (data) => {
  const endpoint = 'skillbuilder/reflection_shares';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        SHARE_REFLECTIONS_REQUEST,
        SHARE_REFLECTIONS_SUCCESS,
        SHARE_REFLECTIONS_FAILURE,
      ],
    },
  };
};

export const downloadReflections = (data) => {
  const endpoint = 'skillbuilder/reflection_downloads';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        DOWNLOAD_REFLECTIONS_REQUEST,
        DOWNLOAD_REFLECTIONS_SUCCESS,
        DOWNLOAD_REFLECTIONS_FAILURE,
      ],
    },
  };
};

export const setDeleteReflectionId = (reflectionId) => {
  return {
    type: SET_REFLECTION_DELETE_ID,
    id: reflectionId,
  };
};
