import axios from 'axios';

import { CALL_API } from '../../middlewares/api';
import { updateUnitSectionsOrder } from './skill-builders';

import {
  BASE_URL,
  SECTIONS_RESET,
  SECTIONS_REQUEST,
  SECTIONS_SUCCESS,
  SECTIONS_FAILURE,
  CREATE_SECTION_REQUEST,
  CREATE_SECTION_SUCCESS,
  CREATE_SECTION_FAILURE,
  UPDATE_SECTION_INDEX_SUCCESS,
  UPDATE_SECTION_REQUEST,
  UPDATE_SECTION_SUCCESS,
  UPDATE_SECTION_FAILURE,
  DELETE_SECTION_REQUEST,
  DELETE_SECTION_SUCCESS,
  DELETE_SECTION_FAILURE,
  TOGGLE_IS_DRAGGING,
} from '../../constants';

export const createSectionRequest = () => {
  return { type: CREATE_SECTION_REQUEST };
};

export const createSectionSuccess = (data) => {
  return { type: CREATE_SECTION_SUCCESS, data };
};

export const createSectionFailure = (payload) => {
  return { type: CREATE_SECTION_FAILURE, payload };
};

export const toggleIsDragging = () => {
  return dispatch => dispatch({ type: TOGGLE_IS_DRAGGING });
};

export const sectionsReset = () => {
  return { type: SECTIONS_RESET };
};

export const updateSectionsOrderSuccess = (data) => {
  return { type: UPDATE_SECTION_INDEX_SUCCESS, data };
};

export const sectionsRequest = (sectionsOrderList) => {
  const endpoint = `skillbuilder/section?list=${sectionsOrderList}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [SECTIONS_REQUEST, SECTIONS_SUCCESS, SECTIONS_FAILURE],
    },
  };
};

const transformRequest = (data) => {
  let formData = new FormData();

  Object.keys(data).forEach(key => formData.append(key, data[key]));

  return formData;
};

export const saveSection = (sectionData, unit, unitIndex) => {
  const url = `${BASE_URL}skillbuilder/section`;

  return function (dispatch) {
    dispatch(createSectionRequest());

    const token = localStorage.getItem('careerPrepped.auth.token') || null;
    const headers = { Authorization: `Bearer ${token}` };
    const data = transformRequest(sectionData);
    const config = { method: 'post', url, headers, data };

    return axios(config)
      .then((response) => {
        const { data: responseData } = response;
        const { id: sectionId } = responseData;
        const { sectionsOrder } = unit;

        const sectionsArray =
          sectionsOrder.length === 0 ? [] : sectionsOrder.split(',');
        const sectionsList = [...sectionsArray, sectionId].join();
        const unitData = Object.assign({}, unit, {
          sectionsOrder: sectionsList,
        });

        dispatch(updateUnitSectionsOrder(unitData, unitIndex));
        dispatch(createSectionSuccess(responseData));
      })
      .catch((error) => {
        dispatch(createSectionFailure(error));
      });
  };
};

export const updateSection = (data, index) => {
  const endpoint = `skillbuilder/section/${data.id}`;

  return {
    [CALL_API]: {
      data,
      index,
      endpoint,
      method: 'put',
      authenticated: true,
      types: [
        UPDATE_SECTION_REQUEST,
        UPDATE_SECTION_SUCCESS,
        UPDATE_SECTION_FAILURE,
      ],
    },
  };
};

export const deleteSection = (sectionId) => {
  const endpoint = `skillbuilder/section/${sectionId}`;

  return {
    [CALL_API]: {
      endpoint,
      id: sectionId,
      method: 'delete',
      authenticated: true,
      types: [
        DELETE_SECTION_REQUEST,
        DELETE_SECTION_SUCCESS,
        DELETE_SECTION_FAILURE,
      ],
    },
  };
};
