import React from 'react';

import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';

import UnitAccordion from '../../components/skill-builders/UnitAccordion';
import Loading from '../common/Loading';

const SkillBuildersModal = ({
  isRequesting,
  skillBuildersLookupTable,
  showSkillBuildersModal,
  toggleSkillBuildersModal,
  skillbuilders,
  isOrg
}) => {
  const content = isRequesting ? <Loading/> :
    (<UnitAccordion
      homepageModal
      skillBuildersLookupTable={skillBuildersLookupTable}
      skillbuilders={skillbuilders}/>);

  return (
    <Modal isOpen={showSkillBuildersModal} toggle={toggleSkillBuildersModal} size={'md'}>
      <div className="skillbuilder sbmodals" id="skillBuilder">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel" style={{fontSize:'1.5rem'}}>Skill Builders</h4>
            <button type="button" className="close" onClick={toggleSkillBuildersModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="sb-cont">
              {isOrg &&
                <div>
                  <p>
                    Skill Builders are meaningful, interactive learning experiences. Each has reflection questions,  discussion forums, a workbook, and “Skill Challenges” to apply skills. Rubrics are available to support assessment of activities.
                  </p>
                </div>}

              {content}
              <p className="mt-3 text-center" style={{fontSize:14}}>
                CareerPrepped Skill Builders address skill topics essential for lifelong career success.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

SkillBuildersModal.propTypes = {
  showSkillBuildersModal: PropTypes.bool.isRequired,
  toggleSkillBuildersModal: PropTypes.func.isRequired,
  skillbuilders: PropTypes.array.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  isOrg: PropTypes.bool,
  skillBuildersLookupTable: PropTypes.array,
};

export default SkillBuildersModal;
