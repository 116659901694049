import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getUsers, resetFilterParams } from '../../redux/actions/users';
import {
  getGroupAdmins,
  resetGroupAdmins,
  setSelectedParentGroup,
  getUserAdminGroups
} from '../../redux/actions/groups';

import { getAllGroups, setSelectedEducatorGroup } from '../../redux/actions/statistics';

import classNames from 'classnames';
import { Collapse } from 'reactstrap';

// import GroupsSearch from './GroupsSearch';

class EducatorsAllGroupsDropdown extends Component {
  static propTypes = {
    educatorId: PropTypes.string,
    page: PropTypes.number.isRequired,
    groupsPage: PropTypes.number.isRequired,
    parentPage: PropTypes.string,
    groupsCount: PropTypes.number.isRequired,
    groupsPageCount: PropTypes.number.isRequired,
    actions: PropTypes.object.isRequired,
    selectedGroup: PropTypes.object.isRequired,
    educatorGroups: PropTypes.array.isRequired,
    institutionId: PropTypes.string,
    isRequesting: PropTypes.bool.isRequired,
    institutionUsersCount: PropTypes.number,
    educatorName: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      keyword: props.selectedGroup.name
    };

    this.onLoadMore = this.onLoadMore.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.onSelectGroup = this.onSelectGroup.bind(this);
  }

  handleToggle() {
    const { isOpen } = this.state;

    this.setState({ isOpen : ! isOpen });
  }

  onLoadMore(event) {
    event.preventDefault();

    const {
      actions,
      groupsPage,
      groupsPageCount,
      educatorId
    } = this.props;

    if (groupsPage === groupsPageCount) return;

    const urlParamString = `&page=${groupsPage + 1}`;

    actions.getAllGroups(educatorId, urlParamString);
  }

  onSelectGroup(event) {
    event.preventDefault();

    const groupId = event.target.closest('a').id;

    const { actions, educatorGroups } = this.props;

    const selectedGroup = educatorGroups.find(group => group.id === groupId);
    //
    //    actions.resetFilterParams();
    actions.setSelectedEducatorGroup(selectedGroup);
    //
    //    this.fetchUsers(groupId);
    //    this.fetchGroupAdmins(groupId);
    this.setState({ isOpen: false });
  }

  fetchUsers() {
    //    const { actions, parentPage } = this.props;
    //
    //    if (parentPage !== 'admin-dashboard') {
    //      return;
    //    }
    //
    //    const urlProps = { ...this.props, groupId, page: 1 };
    //    const urlParamString = generateBaseRequestUrl(urlProps);
    //
    //    actions.getUsers(urlParamString);
  }

  fetchGroupAdmins() {
    //    const { actions, parentPage } = this.props;
    //
    //    if (parentPage !== 'manage-admins') {
    //      return;
    //    }
    //
    //    if (groupId === 'all-groups') {
    //      actions.resetGroupAdmins();
    //    }
    //
    //    if (groupId !== 'all-groups') {
    //      actions.getGroupAdmins(groupId);
    //    }
  }

  render() {
    const { isOpen } = this.state;
    const {
      groupsCount,
      groupsPage,
      educatorName,
      isRequesting,
      groupsPageCount,
      educatorGroups
    } = this.props;

    const hasMoreGroups = (groupsPageCount > groupsPage);

    return (
      <div style={{marginBottom: 24}}>
        <div className="admin-list-header" onClick={this.handleToggle}>
          {`Groups Within ${educatorName}`}
          <i className={classNames('fa float-right', {
            'fa-chevron-down': !isOpen,
            'fa-chevron-up': isOpen
          })}/>
        </div>

        <Collapse isOpen={isOpen}>
          <div style={{marginTop: 8, boxShadow: '1px 1px 5px rgba(0,0,0,.05)', backgroundColor:'white', padding: 16, borderRadius: 4 }}>
            <div className="row">
              <div className="col-lg-12">
                {/* <GroupsSearch/> */}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="admin-item">
                  <div className="admin-group-list">
                    {educatorGroups.map(group => (
                      <a
                        href="#"
                        key={group.id}
                        className="item"
                        id={group.id}
                        onClick={this.onSelectGroup}>
                        <div>
                          <span
                            className="img-responsive initial-avatar group">
                            {group.name.slice(0, 1)}
                          </span>
                        </div>
                        <div>
                          <div>
                            {`${group.name}`}
                          </div>
                          <div style={{
                            fontSize: 12
                          }}>
                            {(group.id === 'all-groups') ?
                              `${groupsCount} Groups` : `${group.groupMembersCount} Members`}
                          </div>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="admins-more">
              {! isRequesting && hasMoreGroups &&
                <a href="#" onClick={this.onLoadMore}>Show More</a>}

              {(! isRequesting) && (! hasMoreGroups) &&
                <span>No more groups</span>}

              {isRequesting &&
                <span>
                  <i className="fa fa-spinner fa-pulse fa-lg fa-fw" />
                  Loading...
                </span>}
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    users: { page },
    institutions: {
      selectedInstitution: {
        id: institutionId,
        usersCount: institutionUsersCount
      }
    },
    statistics: {
      educators: {
        selectedEducator: { id: educatorId, name: educatorName }
      },
      groups: {
        paginationData: {
          page: groupsPage,
          page_count: groupsPageCount,
          total_items: groupsCount
        },
        selectedGroup,
        isRequesting,
        data: educatorGroups
      }
    }
  } = state;

  return {
    page,
    educatorId,
    groupsPage,
    groupsCount,
    educatorName,
    institutionUsersCount,
    groupsPageCount,
    selectedGroup,
    educatorGroups,
    institutionId,
    isRequesting
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    getUsers,
    getGroupAdmins,
    resetGroupAdmins,
    resetFilterParams,
    setSelectedParentGroup,

    getUserAdminGroups,

    getAllGroups,
    setSelectedEducatorGroup
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(EducatorsAllGroupsDropdown);
