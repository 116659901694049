import React from 'react';

import PropTypes from 'prop-types';

import ManageIRComponent from './ManageIRTableComponent';

const IRTable = ({ manageIRs,
  toggleIRViewActions,
  toggleIRViewCluster,
  toggleIRViewIndustry,
  isOpenIRAction,
  industryPopOver,
  clusterPopOver,
  onToggleMessagingModal,
  removeIRView,
  isFollowing,
  onFollow,
  onSortTable,
  page,
  pageCount,
  onNext,
  onPrev,
  onSelectPage,
  isSearching,
  searchText}) => {

  return (
    <ManageIRComponent
      tblData={manageIRs}
      paging={false}
      search={false}
      defaultCSS={false}
      status="manageIR"
      toggleIRViewActions={toggleIRViewActions}
      toggleIRViewCluster={toggleIRViewCluster}
      toggleIRViewIndustry={toggleIRViewIndustry}
      onToggleMessagingModal={onToggleMessagingModal}
      removeIRView={removeIRView}
      isOpenIRAction={isOpenIRAction}
      industryPopOver={industryPopOver}
      clusterPopOver={clusterPopOver}
      isFollowing={isFollowing}
      onFollow={onFollow}
      onSortTable={onSortTable}
      page={page}
      pageCount={pageCount}
      onNext={onNext}
      onPrev={onPrev}
      onSelectPage={onSelectPage}
      isSearching={isSearching}
      searchText={searchText}/>
  );
};

IRTable.propTypes = {
  manageIRs: PropTypes.array.isRequired,
  toggleIRViewActions: PropTypes.func,
  toggleIRViewCluster: PropTypes.func,
  toggleIRViewIndustry: PropTypes.func,
  isOpenIRAction: PropTypes.string,
  industryPopOver: PropTypes.string,
  clusterPopOver: PropTypes.string,
  onToggleMessagingModal: PropTypes.func,
  isFollowing: PropTypes.bool,
  onFollow: PropTypes.func,
  removeIRView: PropTypes.func,
  onSortTable: PropTypes.func,
  page: PropTypes.number,
  pageCount: PropTypes.number,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  onSelectPage: PropTypes.func,
  isSearching: PropTypes.bool,
  searchText: PropTypes.string
};

export default IRTable;
