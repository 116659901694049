import PropTypes from 'prop-types';
import React from 'react';

const RequestInviteForm = ({
  request,
  errors,
  isRequesting,
  onChange,
  onSubmit,
  handleKeyPress,
  nameRef
}) => {
  return (
    <form onSubmit={onSubmit} onKeyPress={handleKeyPress} autoComplete={'nope'}>
      <input type="hidden" value="prayer" />
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label className="sr-only">First Name</label>
            <input
              type="text"
              name="firstName"
              ref={nameRef || null}
              className="form-control"
              placeholder="First Name"
              onChange={onChange}
              value={request.firstName}/>
            {errors.firstName &&
              <div className="text-danger">{errors.firstName}</div>}
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label className="sr-only">Last Name</label>
            <input
              type="text"
              name="lastName"
              className="form-control"
              placeholder="Last Name"
              onChange={onChange}
              value={request.lastName}/>
            {errors.lastName &&
              <div className="text-danger">{errors.lastName}</div>}
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="sr-only">Job Title</label>
        <input
          type="text"
          name="jobTitle"
          className="form-control"
          placeholder="Job Title"
          onChange={onChange}
          value={request.jobTitle}/>
        {errors.jobTitle &&
          <div className="text-danger">{errors.jobTitle}</div>}
      </div>
      <div className="form-group">
        <label className="sr-only">Organization Name</label>
        <input
          type="text"
          name="organization"
          className="form-control"
          placeholder="Organization Name"
          value={request.organization}
          onChange={onChange}/>
        {errors.organization &&
          <div className="text-danger">{errors.organization}</div>}
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label className="sr-only">Email</label>
            <input
              type="text"
              name="email"
              className="form-control"
              placeholder="Email"
              autoComplete="nope"
              value={request.email}
              onChange={onChange}/>
            {errors.email &&
              <div className="text-danger">{errors.email}</div>}
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label className="sr-only">Phone</label>
            <input
              type="text"
              name="phone"
              className="form-control"
              placeholder="Phone"
              autoComplete="nope"
              value={request.phone}
              onChange={onChange}/>
            {errors.phone &&
              <div className="text-danger">{errors.phone}</div>}
          </div>
        </div>
      </div>
      <input
        type="submit"
        disabled={isRequesting}
        value={isRequesting ? 'Submitting...' : 'Submit'}
        className="btn btn-primary w-100"/>
    </form>
  );
};

RequestInviteForm.propTypes = {
  request: PropTypes.object,
  errors: PropTypes.object,
  handleKeyPress: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  nameRef: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default RequestInviteForm;
