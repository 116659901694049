import React, { PureComponent } from 'react';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getEducatorUsers, setUsersSortOrder } from '../../../redux/actions/users';

import classNames from 'classnames';
import Parser from 'html-react-parser';

import { generateRequestUrl, formattedAdminUsersListTableGroupsList } from '../../../utils';

import UsersTableNameCell from '../../../components/admin/table-custom-components/UsersTableNameCell';
import UsersTableFilesCell from '../../../components/admin/table-custom-components/UsersTableFilesCell';
import UsersTableBadgesCell from '../../../components/admin/table-custom-components/UsersTableBadgesCell';
import UsersTableDropdownAction from '../../../components/admin/table-custom-components/UsersTableDropdownAction';
import UsersTableLocationCell from '../../../components/admin/table-custom-components/UsersTableLocationCell';
import PaginationHandler from '../../../components/common/PaginationHandler';
import TableLoading from '../../../components/common/TableLoading';
import UsersTableStatusCell from '../../../components/admin/table-custom-components/UsersTableStatusCell';
import SeeFeedbackModal from '../../../containers/feedback/SeeFeedbackModal';

class AdminUsersTable extends PureComponent {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    groupId: PropTypes.string.isRequired,
    sort: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    usersList: PropTypes.array.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    profile: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      statusClicked: []
    };

    this.onFetchUsers = this.onFetchUsers.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
  }

  componentWillMount() {
    const { actions, groupId } = this.props;

    if (groupId) {
      let urlParamString = `group=${groupId}`;

      if (groupId === 'all-groups') {
        urlParamString = '';
      }

      actions.getEducatorUsers(urlParamString);
    }
  }

  onFetchUsers(event) {
    const column = event.currentTarget.dataset['columnName'];
    const { actions, sort: { order: sortOrder } } = this.props;

    const order = (! sortOrder);
    const sort = { column, order };

    actions.setUsersSortOrder(sort);

    const urlProps = { ...this.props, sort };
    const urlParamString = generateRequestUrl(urlProps);

    actions.getEducatorUsers(urlParamString);
  }

  onSelectPage(page) {
    return () => {
      const { actions } = this.props;
      const urlProps = { ...this.props, page };

      const urlParamString = generateRequestUrl(urlProps);

      actions.getEducatorUsers(urlParamString);
    };
  }

  onNext() {
    const { page: currentPage, actions } = this.props;
    const page = currentPage + 1;
    const urlProps = { ...this.props, page };

    const urlParamString = generateRequestUrl(urlProps);

    actions.getEducatorUsers(urlParamString);
  }

  onPrev() {
    const { page: currentPage, actions } = this.props;
    const page = currentPage - 1;
    const urlProps = { ...this.props, page };

    const urlParamString = generateRequestUrl(urlProps);

    actions.getEducatorUsers(urlParamString);
  }

  render() {
    const { page, pageCount, sort: { column }, usersList, profile } = this.props;

    return (
      <div style={{position: 'relative'}}>
        <table
          className="cp-table mt-1 table table-responsive-lg">
          <thead>
            <tr className="cp-table-header">
              <th
                style={{width: '30%'}}
                className="cursor-pointer"
                data-column-name="name"
                onClick={this.onFetchUsers}>
                Name
              </th>

              <th style={{width: 150, textAlign: 'center'}}>Group(s)</th>
              <th style={{width: 150, textAlign: 'center'}}>Status</th>

              <th style={{ width: 150, textAlign: 'center' }}>
                Skill Badges
              </th>

              <th
                style={{ width: 100, textAlign: 'center' }}
                className="cursor-pointer"
                data-column-name="filesCount"
                onClick={this.onFetchUsers}>
                Other Skills
              </th>

              <th
                style={{ width: 100, textAlign: 'center' }}
                className="cursor-pointer"
                data-column-name="badgesCount"
                onClick={this.onFetchUsers}>
                Portfolio Files
              </th>

              <th
                style={{width: 150}}
                className="cursor-pointer"
                data-column-name="location"
                onClick={this.onFetchUsers}>
                Location
              </th>
              <th style={{width: 100, textAlign: 'center'}}>Actions</th>
            </tr>
          </thead>

          <tbody>
            {(usersList.length === 0) &&
              <tr><td colSpan="7" style={{textAlign: 'center'}}>You do not have any users yet to display in your Dashboard</td></tr>}

            {usersList.map(user => (
              <tr key={user.id}>
                <td className={classNames({
                  'column-sorting': (column === 'name')
                })}>
                  <UsersTableNameCell user={user}/>
                </td>

                <td style={{textAlign: 'center'}}>
                  {Parser(formattedAdminUsersListTableGroupsList(user.groups))}
                </td>

                <td style={{textAlign: 'center'}}>
                  <UsersTableStatusCell user={user} statusClicked={[]}/>
                </td>

                <td style={{ textAlign: 'center' }}>
                  <Link
                    title={`Link to ${user.name}'s skills page`}
                    target="_blank"
                    to={
                      user.id !== profile?.id ?
                        `/myskills/${user.id}?username=${user.name}` :
                        '/myskills'
                    }>
                    {user.badgesCount}
                  </Link>
                </td>

                <td style={{textAlign: 'center'}} className={classNames({
                  'column-sorting': (column === 'filesCount')
                })}>
                  <UsersTableFilesCell user={user}/>
                </td>

                <td style={{textAlign: 'center'}} className={classNames({
                  'column-sorting': (column === 'badgesCount')
                })}>
                  <UsersTableBadgesCell user={user}/>
                </td>

                <td className={classNames({
                  'column-sorting': (column === 'location')
                })}>
                  <UsersTableLocationCell user={user} />
                </td>

                <td style={{textAlign: 'center'}}>
                  <UsersTableDropdownAction user={user}/>
                </td>
              </tr>))}
          </tbody>
          <tfoot>
            <tr>
              <th style={{textAlign: 'center'}} colSpan={10}>
                <PaginationHandler
                  page={page}
                  page_count={pageCount}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSelectPage={this.onSelectPage}/>
              </th>
            </tr>
          </tfoot>
        </table>

        {this.props.isRequesting && <TableLoading/>}

        {this.state.showSeeFeedbackModal && (
          <SeeFeedbackModal
            isOpen={this.state.showSeeFeedbackModal}
            onCancel={this.toggleSeeFeedbackModal}
            userDataForSeeFeedback={this.state.userDataForSeeFeedback}
            viewFeedback={this.toggleViewFeedbackModal}
            setDataForSeeFeedback={this.setSkillBadgeDataForSeeFeedback} />
        )}

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    users: { page, pageCount, sort, filters, isRequesting, data: usersList },
    educators: {
      selectedGroup: { id: groupId }
    },
    profile: { data: profile }
  } = state;

  return {
    sort,
    page,
    pageCount,
    filters,
    profile,
    usersList,
    isRequesting,
    groupId
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    getEducatorUsers,
    setUsersSortOrder
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsersTable);
