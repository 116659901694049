import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import CreateResumeHeader from './CreateResumeHeader';
import CreateResumeSectionsContainer from './CreateResumeSectionsContainer';

const CreateResumeComponent = ({
  onBack,
  onTogglePreview,
  onSave,
  onChangeTitle,
  resumeOrder,
  onRearrangeResumeOrder,
  errors,
  resume,
  profile,
  onChange,
  onUnloadForm,
  preview,
  onLoadForm,
  forms,
  onChangeSection,
  onChangeSectionTitle,
  onCancelModal,
  requestingResumeContents,
  onSortClick,
  onRemoveResumeSection
}) => {
  const { summary, education: resumeEducation, skills: resumeSkills, experience: resumeExperience } = resume;
  const hasAllRequiredSection = !requestingResumeContents && summary.summary !== '' && resumeEducation.educationArray.length > 0 &&
    resumeSkills.skillsArray.length > 0 && resumeExperience.experienceArray.length > 0;

  if(!hasAllRequiredSection){
    return (
      <div className="col-lg-12">
        <div className="create-wrapper restriction-modal">
          <div>
            <p>
              To get started, we need some basics for your resume that employers expect you to include. At a minimum, update your Career Site with details for your about, skills, education, and experience sections. Once you do that, CareerPrepped can help you create targeted resumes by transforming your Career Site details into a resume format optimized to pass computer screening software employers use to filter out resumes they receive. This will help you stand out once you apply! You'll be able to download your resume as a Word document to edit further or use to apply to jobs.
            </p>
          </div>
          <div className="action-bar">
            <Link to="/toolbox/resume" className="btn btn-secondary">Go Back</Link>
            <Link to={`/cp/${profile.vanity}`} className="btn btn-primary float-right">Update Career Site</Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="col-lg-12">
        <p className="create-resume-info">
          The information below was pulled directly from your Career Site. Make edits to create your targeted resume and save once finished. Saving will store your targeted resume as a Word document you can download to use or further edit.
        </p>
        <div className="create-wrapper">
          <CreateResumeHeader
            title={resume.title}
            onBack={onBack}
            onTogglePreview={onTogglePreview}
            onSave={onSave}
            onChangeTitle={onChangeTitle}
            preview={preview}/>
          <CreateResumeSectionsContainer
            resumeOrder={resumeOrder}
            requestingResumeContents={requestingResumeContents}
            onCancelModal={onCancelModal}
            onRearrangeResumeOrder={onRearrangeResumeOrder}
            errors={errors}
            resume={resume}
            onChange={onChange}
            onUnloadForm={onUnloadForm}
            preview={preview}
            onLoadForm={onLoadForm}
            onSortClick={onSortClick}
            forms={forms}
            onChangeSection={onChangeSection}
            onChangeSectionTitle={onChangeSectionTitle}
            onRemoveResumeSection={onRemoveResumeSection}/>
        </div>
      </div>
    </div>
  );
};

CreateResumeComponent.propTypes = {
  errors: PropTypes.object,
  onBack: PropTypes.func.isRequired,
  onTogglePreview: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onChangeTitle: PropTypes.func.isRequired,
  resumeOrder: PropTypes.array.isRequired,
  onRearrangeResumeOrder: PropTypes.func.isRequired,
  resume: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onUnloadForm: PropTypes.func.isRequired,
  preview: PropTypes.bool,
  onLoadForm: PropTypes.func.isRequired,
  forms: PropTypes.array,
  profile: PropTypes.object.isRequired,
  onChangeSection: PropTypes.func.isRequired,
  onChangeSectionTitle: PropTypes.func.isRequired,
  onCancelModal: PropTypes.func.isRequired,
  requestingResumeContents: PropTypes.bool.isRequired,
  onSortClick: PropTypes.func.isRequired,
  onRemoveResumeSection: PropTypes.func.isRequired
};

export default CreateResumeComponent;
