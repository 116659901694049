import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { Row, Col, Container } from 'reactstrap';

import SearchInput from '../users-list/search/SearchInput';
import AdminUsersTable from '../../../containers/admin/educators/AdminUsersTable';
import CopyGroupCodeButton from '../../../containers/admin/CopyGroupCodeButton';

const Dashboard = ({ selectedGroup }) => {
  return (
    <div>
      <Row>
        <Col sm="12" className="pl-0">
          <Container>
            <Row className="align-items-center">
              <Col>
                <div className="groupName">
                  {selectedGroup.name}
                </div>
              </Col>
              <Col>
                {(selectedGroup.id !== 'all-groups') && <CopyGroupCodeButton/>}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>

      <Row>
        <Col sm="12">
          <h2 style={{marginTop: 20}}>Users</h2>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col sm="8">
          <SearchInput/>
        </Col>
      </Row>

      <Row>
        <Col sm="12">
          <AdminUsersTable/>
        </Col>
      </Row>
    </div>
  );
};

Dashboard.propTypes = {
  selectedGroup: PropTypes.object.isRequired
};

const mapStateToProps = ({ educators: { selectedGroup } }) => ({ selectedGroup });

export default connect(mapStateToProps)(Dashboard);
