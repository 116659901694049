import PropTypes from 'prop-types';
import React from 'react';
import SelectPortfolioTab from './SelectPortfolioTab';
import UploadNewPortfolioTabContainer from '../../containers/skill-badges/UploadNewPortfolioTabContainer';

const PortfolioTabContent = ({ 
  activeTab, 
  files, 
  isRequestingFiles, 
  selectFile, 
  selectedFiles,
  linkedFiledIds,
  closeModal,
  bakedBadgePage,
  onNext,
  onPrev,
  onSelectPage,
  fileData,
  skillsCard,
  getMyBadges,
  getMySkills,
}) => {
  let component;
  const noOfFilesSelected = selectedFiles.length;
  
  switch(activeTab){
  case 0:
    component = (
      <div className="tab-content" id="myTabContent">
        <div className="p-2 tab-pane fade in show active" id="gallery" role="tabpanel" aria-labelledby="gallery">
          {
            noOfFilesSelected > 0 &&
              <h6>{noOfFilesSelected} Files Selected</h6>
          }
          <SelectPortfolioTab 
            files={files}
            onNext={onNext}
            onPrev={onPrev}
            onSelectPage={onSelectPage}
            fileData={fileData}
            selectFile={selectFile}
            selectedFiles={selectedFiles}
            linkedFiledIds={linkedFiledIds}
            isRequestingFiles={isRequestingFiles}/>
        </div>
      </div>
    );

    break;

  case 1:
    component = (
      <UploadNewPortfolioTabContainer 
        bakedBadgePage={bakedBadgePage}
        closeModal={closeModal}
        skillsCard={skillsCard}
        getMyBadges={getMyBadges}
        getMySkills={getMySkills}/>
    );
    break ;
  }
  return component;
};

PortfolioTabContent.propTypes = {
  activeTab: PropTypes.number,
  isRequestingFiles: PropTypes.bool.isRequired,
  files: PropTypes.array.isRequired,
  selectedFiles: PropTypes.array.isRequired,
  selectFile: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  bakedBadgePage: PropTypes.bool 
};

export default PortfolioTabContent;
