import PropTypes from 'prop-types';
import React from 'react';

const SearchComponent = ({
  searchValue,
  onSearchChange,
  onClearSearch,
  onSearchSubmit,
  isRequestingSearch,
  handleSearchFormKeyPress
}) => {
  return (
    <form
      onKeyPress={handleSearchFormKeyPress}
      className="input-group">
      <input type="text"
        className="form-control"
        value={searchValue}
        onChange={onSearchChange}
        aria-label="Search"
        placeholder="Search"/>
      {searchValue.trim() !== '' &&
        <span
          onClick={onClearSearch}
          className="clickable clear-searcbox">
          x
        </span>}
      <span className="input-group-append">
        <button
          disabled={isRequestingSearch}
          className="btn btn-secondary"
          type="button"
          onClick={onSearchSubmit}>
          <i className="fa fa-search"/><span className="sr-only">Search</span>
        </button>
      </span>
    </form>
  );
};

SearchComponent.propTypes = {
  searchValue: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onSearchSubmit: PropTypes.func.isRequired,
  isRequestingSearch: PropTypes.bool.isRequired,
  onClearSearch: PropTypes.func.isRequired,
  handleSearchFormKeyPress: PropTypes.func.isRequired
};

export default SearchComponent;
