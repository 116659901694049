import { CALL_API } from '../../middlewares/api';

import {
  RESET_JOB_REDUCER,
  SEARCH_JOBS_REQUEST,
  SEARCH_JOBS_SUCCESS,
  SEARCH_JOBS_FAILURE,
  ADD_JOB_TO_BOARD_REQUEST,
  ADD_JOB_TO_BOARD_SUCCESS,
  ADD_JOB_TO_BOARD_FAILURE,
  TOGGLE_ADVANCED_FILTER,
  UPDATE_QUERY_PARAMS,
  UPDATE_JOB_SEARCH_PROPERTIES
} from '../../constants';

export const resetJobSearch = () => {
  return { type: RESET_JOB_REDUCER };
};

export const toggleAdvancedFilter = () => {
  return { type: TOGGLE_ADVANCED_FILTER };
};

export const updateQueryParams = (params) => {
  return { type: UPDATE_QUERY_PARAMS, params };
};

export const searchJobs = (queryParam) => {
  const endpoint = 'job/job_search';
  const { startIndex } = queryParam;

  const data = Object.assign({}, queryParam, { start: startIndex });

  return {
    [CALL_API]: {
      data,
      query: data,
      endpoint,
      method: 'post',
      types: [SEARCH_JOBS_REQUEST, SEARCH_JOBS_SUCCESS, SEARCH_JOBS_FAILURE],
    },
  };
};

export const addJobToBoard = (data) => {
  const endpoint = 'job/job';

  return {
    [CALL_API]: {
      data,
      id: data.jobId,
      authenticated: true,
      endpoint,
      method: 'post',
      types: [
        ADD_JOB_TO_BOARD_REQUEST,
        ADD_JOB_TO_BOARD_SUCCESS,
        ADD_JOB_TO_BOARD_FAILURE,
      ],
    },
  };
};

export const updateJobSearchProperties = job => ({
  type: UPDATE_JOB_SEARCH_PROPERTIES,
  job
});
