import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import CpHtmlParser from '../common/CpHtmlParser';

import moment from 'moment';
import classNames from 'classnames';

// const industryOptions = [
//  {
//    value: 'accounting-finance-jobs',
//    label: 'Accounting & Finance Jobs'
//  },
//  {
//    value: 'it-jobs',
//    label: 'IT Jobs'
//  },
//  {
//    value: 'sales-jobs',
//    label: 'Sales Jobs'
//  },
//  {
//    value: 'customer-services-jobs',
//    label: 'Customer Services Jobs'
//  },
//  {
//    value: 'engineering-jobs',
//    label: 'Engineering Jobs'
//  },
//  {
//    value: 'hr-jobs',
//    label: 'HR & Recruitment Jobs'
//  },
//  {
//    value: 'healthcare-nursing-jobs',
//    label: 'Healthcare & Nursing Jobs'
//  },
//  {
//    value: 'hospitality-catering-jobs',
//    label: 'Hospitality & Catering Jobs'
//  },
//  {
//    value: 'pr-advertising-marketing-jobs',
//    label: 'PR, Advertising & Marketing Jobs'
//  },
//  {
//    value: 'logistics-warehouse-jobs',
//    label: 'Logistics & Warehouse Jobs'
//  },
//  {
//    value: 'teaching-jobs',
//    label: 'Teaching Jobs'
//  },
//  {
//    value: 'trade-construction-jobs',
//    label: 'Trade & Construction Jobs'
//  },
//  {
//    value: 'admin-jobs',
//    label: 'Admin Jobs'
//  },
//  {
//    value: 'legal-jobs',
//    label: 'Legal Jobs'
//  },
//  {
//    value: 'creative-design-jobs',
//    label: 'Creative & Design Jobs'
//  },
//  {
//    value: 'graduate-jobs',
//    label: 'Graduate Jobs'
//  },
//  {
//    value: 'retail-jobs',
//    label: 'Retail Jobs'
//  },
//  {
//    value: 'consultancy-jobs',
//    label: 'Consultancy Jobs'
//  },
//  {
//    value: 'manufacturing-jobs',
//    label: 'Manufacturing Jobs'
//  },
//  {
//    value: 'scientific-qa-jobs',
//    label: 'Scientific & QA Jobs'
//  },
//  {
//    value: 'social-work-jobs',
//    label: 'Social work Jobs'
//  },
//  {
//    value: 'travel-jobs',
//    label: 'Travel Jobs'
//  },
//  {
//    value: 'energy-oil-gas-jobs',
//    label: 'Energy, Oil & Gas Jobs'
//  },
//  {
//    value: 'property-jobs',
//    label: 'Property Jobs'
//  },
//  {
//    value: 'property-jobs',
//    label: 'Property Jobs'
//  },
//  {
//    value: 'charity-voluntary-jobs',
//    label: 'Charity & Voluntary Jobs'
//  },
//  {
//    value: 'domestic-help-cleaning-jobs',
//    label: 'Domestic help & Cleaning Jobs'
//  },
//  {
//    value: 'maintenance-jobs',
//    label: 'Maintenance Jobs'
//  },
//  {
//    value: 'part-time-jobs',
//    label: 'Part time Jobs'
//  },
//  {
//    value: 'other-general-jobs',
//    label: 'Other/General Jobs'
//  },
//  {
//    value: 'unknown',
//    label: 'Unknown'
//  }
// ];

const Results = ({ data, index, addToBoard, applyToJob, isSubmitting }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  function toggleTooltip() {
    setTooltipOpen(!tooltipOpen);
  }

  const {
    company,
    title,
    description,
    redirect_url,
    id,
    location,
    contract_time,
    created,
    category,
    isSavedProperty
  } = data;
  const dateConfig = {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    lastWeek: '[a week ago]',
    sameElse: 'MM/DD/YYYY'
  };
  const seeMoreCondition = description.replace(/<[^>]+>/g, '').length > 205;
  const descriptionString = description.replace(/<[^>]+>/g, '');
  const jobDescription = seeMoreCondition ?
    `${descriptionString.slice(0, 205)}...` : descriptionString.length > 0 ?
      descriptionString :
      'To see the full description of this job, you must visit the site it is published on';

  const displayDate = moment(created).calendar(dateConfig);
  const displayDateClassNames = classNames({
    'badge badge-pill': displayDate === 'Today',
    'card-text': displayDate !== 'Today'
  });
  const titleString = title.replace(/<[^>]+>/g, '');
  const isTitleMoreThanLimit = titleString.length > 30;
  const jobtitle = isTitleMoreThanLimit ?
    `${titleString.slice(0, 30)}...` : titleString;

  //  const industry = industryOptions.find(industry => industry.value === discipline_id);

  const tooltipText = isSubmitting.status ? 'Saving...' : isSavedProperty ? 'Saved Job' : 'Save Job';

  return (
    <div className="jobsearch-card-container">
      <div className="card h-100">
        <div className="card-body">
          <h5 className="card-title-container">
            <div className="card-title">
              <a href={redirect_url} target="_blank">{jobtitle}</a>
            </div>
            <div className="card-title-meta">
              <span className={displayDateClassNames}>{displayDate}</span>
              <span
                id={`Tooltip-${index}`}
                className="clickable-link btn btn-light"
                onClick={isSavedProperty ? undefined : addToBoard(id)}>
                <i className={classNames('fa-bookmark', {
                  'job-saved': isSavedProperty,
                  'fas': isSavedProperty,
                  'far': !isSavedProperty
                })}/>
                {/* Tool Tip for Job Save Status */}
                <Tooltip
                  placement="right"
                  isOpen={tooltipOpen}
                  target={`Tooltip-${index}`}
                  toggle={toggleTooltip}>
                  {tooltipText}
                </Tooltip>
                {/* <i className="far fa-bookmark"/> */}
              </span>
              <a href="#"
                onClick={applyToJob(id, redirect_url)}
                className="clickable-link btn orange-btn">
                Apply <i className="fas fa-external-link-alt"/>
              </a>
            </div>

          </h5>
          <div className="jobsearch-card-location-type">
            <p className="card-text mb-0">
              <b>{company.display_name}</b>
              {` - ${location.display_name}`}
            </p>
            <div className="jobsearch-type">
              <p className="card-text mb-0">
                <b>Job Type: </b>{contract_time ?? 'Not Provided'}
              </p>
              <p className="card-text mb-0">

                <b>Industry: </b>
                {category.label == '' ?? 'Not Provided'
                }{category.label}
              </p>
            </div>
          </div>
          <div className="jobsearch-card-description">
            <CpHtmlParser htmlString={jobDescription} />
            <a
              target="_blank"
              href={redirect_url}
              className="show-more-btn-job">
              See More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

Results.propTypes = {
  data: PropTypes.object.isRequired,
  addToBoard: PropTypes.func.isRequired,
  applyToJob: PropTypes.func.isRequired,
  isSubmitting: PropTypes.object,
  index: PropTypes.number.isRequired
};

export default Results;
