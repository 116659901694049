import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loadForm } from '../../../utils';
import isEmpty from 'lodash/isEmpty';
import { toastr } from 'react-redux-toastr';
import Validator from '../../../validator';

import * as componentsActions from '../../../redux/actions/components';
import * as commentsActions from '../../../redux/actions/comments';
import * as wallCommentActions from '../../../redux/actions/wall-post-comments';

import ReplyCard from '../../../components/skill-builders/discussions/ReplyCard';

class ReplyCardHandler extends Component {
  static propTypes = {
    actions: PropTypes.object,
    reply: PropTypes.object,
    forms: PropTypes.array,
    status: PropTypes.bool,
    dashboard: PropTypes.bool,
    profile: PropTypes.object.isRequired,
    showMore: PropTypes.object.isRequired,
    commentShowMore: PropTypes.object.isRequired,
    loadForms: PropTypes.array,
    searchPage: PropTypes.bool,
    isMobile: PropTypes.bool.isRequired,
    currentUser: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      isUserLikesTooltipOpen: false,
      isUserLikesListModalOpen: false,
      updatedReply: this.props.reply.comment,
      attachment: this.props.reply.attachment,
      showMore: Object.assign({}, this.props.showMore),
      commentShowMore: Object.assign({}, this.props.commentShowMore),
      imagePreviewUrl: this.props.reply.attachment
    };

    this.onChange = this.onChange.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onEscape = this.onEscape.bind(this);
    this.onshowMore = this.onshowMore.bind(this);
    this.onCommentShowMore = this.onCommentShowMore.bind(this);
    this.onDropFile = this.onDropFile.bind(this);
    this.clearImagePreview = this.clearImagePreview.bind(this);
    this.toggleUserLikesTooltip = this.toggleUserLikesTooltip.bind(this);
    this.toggleUserLikesListModal = this.toggleUserLikesListModal.bind(this);
  }

  componentWillUnmount() {
    const {
      loadForms,
      reply
    } = this.props;

    const isForm = loadForm(
      loadForms,
      `reply-form-commentId-${reply.id}`
    );

    if (isForm)
      this.unloadUpdationForm();
  }

  onFocus(item) {
    // focus on input field.
    const form = `reply-form-commentId-${item}`;
    document.getElementById(form).focus();
  }

  onDropFile(files) {
    if(files[0].size > 8000000)
      return toastr.info('Sorry, this image exceeds the 8MB limit.');

    this.setState({
      attachment: files[0],
      imagePreviewUrl: files[0].preview
    });

    this.onFocus(this.props.reply.id);
  }

  clearImagePreview() {
    this.setState({
      attachment: {},
      imagePreviewUrl: ''
    });

    this.onFocus(this.props.reply.id);
  }

  handleResponse() {
    this.unloadUpdationForm();

    this.setState({
      updatedReply: this.props.reply.comment,
      attachment: this.props.reply.attachment,
      imagePreviewUrl: this.props.reply.attachment
    });
  }

  onEscape() {
    const { reply } = this.props;

    this.setState({
      updatedReply: reply.comment,
      imagePreviewUrl: reply.attachment
    }, this.unloadUpdationForm);
  }

  handleKeyPress(event) {
    if (event.key === 'Enter')
      return this.onUpdate(event);

    if (event.key === 'Escape')
      return this.onEscape();
  }

  onChange(event) {
    this.setState({
      updatedReply: event.target.value
    }, this.isValid);
  }

  unloadUpdationForm() {
    const { actions, reply } = this.props;
    const form = `update-reply-form-${reply.id}`;

    actions.unloadForm(form);

    // Remove validation warnings, if any.
    this.setState({
      errors: {}
    });
  }

  onUpdate(event) {
    event.preventDefault();

    const { updatedReply, attachment } = this.state;
    const { actions, reply, status, dashboard } = this.props;

    if (!this.isValid() || status)
      return;

    if(updatedReply === '' && isEmpty(attachment))
      return toastr.error('Reply cannot be empty.');

    // user presses Enter without changing the content of reply.
    if (updatedReply === reply.comment && attachment === reply.attachment)
      return this.unloadUpdationForm();

    const actionToCall = dashboard ? 'updateWallPostReply' : 'updateUnitDiscussionReply';
    actions[actionToCall](this.createFormData(), reply.id)
      .then(() => this.handleResponse());
  }

  createFormData() {
    const {
      updatedReply,
      attachment,
      imagePreviewUrl
    } = this.state;

    const {
      reply,
      dashboard
    } = this.props;

    let formData = new FormData();
    if(updatedReply !== '')
      formData.append('comment', updatedReply);

    // For dashboard post
    if (dashboard) {

      formData.append('wall', reply.wallpost);

      formData.append('parent', reply.parent);

      if (!isEmpty(attachment)) {
        formData.append('attachment', attachment);
        return formData;
      }

      if (!imagePreviewUrl)
        formData.append('attachment', '');

      return formData;
    }

    // For discussion post
    formData.append('discussion', reply.discussion);

    formData.append('parent', reply.parent);

    if (!isEmpty(attachment)) {
      formData.append('attachment', attachment);
      return formData;
    }

    if (!imagePreviewUrl)
      formData.append('attachment', '');

    return formData;
  }

  isValid(field = null) {
    const validate = Validator.createValidator({
      updatedReply: ['maxLength|2000']
    }, this.state, field);

    const { isValid, errors } = validate;

    this.setState({ errors });

    return isValid;
  }

  onshowMore(postId){
    return () => {
      const { condition } = this.state.showMore;
      const newShowMore = { condition: !condition, id: postId};

      this.setState({ showMore: newShowMore });
    };
  }

  onCommentShowMore(id){
    return () => {
      const { id: conditionId } = this.state.commentShowMore;

      const condition = (conditionId !== id);
      const commentShowMore = { condition, id };

      this.setState({ commentShowMore });
    };
  }

  toggleUserLikesTooltip() {
    this.setState({
      isUserLikesTooltipOpen: ! this.state.isUserLikesTooltipOpen
    });
  }

  toggleUserLikesListModal() {
    this.setState({
      isUserLikesListModalOpen: ! this.state.isUserLikesListModalOpen
    });
  }

  render() {
    const {
      reply,
      forms,
      currentUser,
      dashboard,
      profile,
      isMobile
    } = this.props;

    const {
      errors,
      updatedReply,
      showMore,
      commentShowMore,
      imagePreviewUrl,
      searchPage,
      isUserLikesTooltipOpen,
      isUserLikesListModalOpen
    } = this.state;

    const isForm = loadForm(forms, `update-reply-form-${reply.id}`);

    return(
      <ReplyCard
        reply={reply}
        profile={profile}
        updatedReply={updatedReply}
        isForm={isForm}
        onChange={this.onChange}
        currentUser={currentUser}
        clearImagePreview={this.clearImagePreview}
        onDropFile={this.onDropFile}
        errors={errors}
        handleKeyPress={this.handleKeyPress}
        onEscape={this.onEscape}
        showMore={showMore}
        dashboard={dashboard}
        onshowMore={this.onshowMore}
        commentShowMore={commentShowMore}
        onCommentShowMore={this.onCommentShowMore}
        imagePreviewUrl={imagePreviewUrl}
        searchPage={searchPage}
        isMobile={isMobile}
        isUserLikesTooltipOpen={isUserLikesTooltipOpen}
        isUserLikesListModalOpen={isUserLikesListModalOpen}
        toggleUserLikesTooltip={this.toggleUserLikesTooltip}
        toggleUserLikesListModal={this.toggleUserLikesListModal}/>
    );
  }
}

const mapStateToProps = (state) => {
  let showMore = { condition: false, id: null};
  let commentShowMore = { condition: false, id: null};

  const { forms, isMobile } = state.components;

  return {
    forms,
    showMore,
    isMobile,
    commentShowMore,
    loadForms: forms,
    profile: state.profile.data,
    status: state.comments.isUpdating.status,
    currentUser: state.auth.data.currentUser
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, componentsActions, commentsActions, wallCommentActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReplyCardHandler);
