import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';

class AdminTools extends Component {
  render() {
    return (
      <div className="interior invite-users">
        <Helmet title="Admin Tools"/>
        <div className="container">
          <div className="row">
            <div className="content follow">
              <h2>Admin Tools</h2>
              <div>
                <div>
                  <Link
                    to="/admin/skill-builders"
                    className="view-webpage-link">
                    Authoring Tool
                  </Link>
                </div>
                <div>
                  <Link
                    to="/admin/manage-organizations"
                    className="view-webpage-link">
                    Manage Organizations
                  </Link>
                </div>
                <div>
                  <Link
                    to="/admin/manage-access-requests"
                    className="view-webpage-link">
                    Manage Access Requests
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminTools;
