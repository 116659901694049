import PropTypes from 'prop-types';
import React from 'react';

import _isEmpty from 'lodash/isEmpty';

import EndSection from './EndSection';
import SelectSection from '../admin/SelectSection';
import SectionForm from '../admin/SectionForm';
import DescriptionList from '../admin/DescriptionList';
import SectionList from '../../../containers/skill-builders/SectionList';
import Loading from '../../common/Loading';

const RenderEssentials = ({
  noSection,
  unit,
  isRequesting,
  checkAdmin,
  onSelectChange,
  formName,
  onSelectSubmit,
  forms,
  onCancel,
  sections,
  onMouseEnter,
  onMouseLeave,
  hover,
  updateResourceForms,
  onEdit,
  isAuthenticated,
  slug
}) => {
  return(
    <div className="essential-wrapper" id="essential-wrapper">
      <div className="essential-intro">
        <div className="content">
          <div className="info">
            <div className="row">
              <div className="col-lg-5">
                <h2>{'Having this skill means you\'re able to...'}</h2>

                {!_isEmpty(unit) &&
                  <DescriptionList
                    unit={unit}
                    isRequesting={isRequesting}/>}
              </div>
              <div className="col-lg-7">
                <p>
                  This Skill Builder provides the basic essentials to help you develop a specific skill. Reflect on the essential things you need to know and practice this skill using the tips & strategies presented to you. Refer back any time if you ever need help with any particular work situation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {checkAdmin() &&
        <SelectSection
          onChange={onSelectChange}
          formType={formName}
          onClick={onSelectSubmit}/>}

      {checkAdmin() && forms.map(form =>
        (<SectionForm
          key={`${form.formName}_${form.id}`}
          unit={unit}
          isAdmin={checkAdmin()}
          formType={form}
          onCancel={onCancel}/>))}

      {sections.isRequesting ?
        <Loading loaderStyles={{ marginTop: 20, marginBottom: 20 }}/> :
        <SectionList
          unit={unit}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          hover={hover}
          updateResourceForms={updateResourceForms}
          onEditClick={onEdit}
          onCancel={onCancel}
          isAuthenticated={isAuthenticated}
          isAdmin={checkAdmin()}
          noSection={noSection}/>}

      {!checkAdmin() &&
        <EndSection
          engageLink={slug}
          unit={unit}
          isRequesting={isRequesting}
          noSection={noSection}/>}
    </div>
  );
};

RenderEssentials.propTypes = {
  noSection: PropTypes.bool,
  unit: PropTypes.object,
  isRequesting: PropTypes.bool,
  checkAdmin: PropTypes.func,
  onSelectChange: PropTypes.func,
  formName: PropTypes.string,
  onSelectSubmit: PropTypes.func,
  forms: PropTypes.array,
  onCancel: PropTypes.func,
  sections: PropTypes.object,
  onMouseEnter:PropTypes.func,
  onMouseLeave: PropTypes.func,
  hover: PropTypes.object,
  updateResourceForms: PropTypes.array,
  onEdit: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  slug: PropTypes.string
};

export default RenderEssentials;
