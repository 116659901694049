import { includes, isEmpty, forEach, compact, flatten } from 'lodash';

const getDuplicateClusterDetails = (selectedIndustries) => {
  const duplicates = [];
  forEach(selectedIndustries, (checkingIndustry) => {
    const collectedDuplicateData = [];
    selectedIndustries.find((element) => {
      if (element.industry?.label === checkingIndustry.industry?.label) {
        collectedDuplicateData.push(checkingIndustry);
      }
    });
    collectedDuplicateData.shift();
    duplicates.push(collectedDuplicateData);
  });
  return duplicates;
};

const getSelectedIndustries = (clusterInputList, checkingCluster) => clusterInputList.filter((clusterInput, index) => {
  if(clusterInput.cluster?.label === checkingCluster){
    return ({
      ...clusterInput,
      indexPosition: index,
    });
  }
}
);

export const validateCluster = (clusterInputList) => {
  const checkedClusters = [];
  let validationResult = {};
  let duplicateDetails = [];
  clusterInputList.filter((input) => {
    const checkingCluster = input.cluster?.label;
    if(!includes(checkedClusters, checkingCluster)){
      checkedClusters.push(checkingCluster);
      const selectedIndustries = getSelectedIndustries(clusterInputList, checkingCluster);
      const duplicates = getDuplicateClusterDetails(selectedIndustries);
      
      if(!isEmpty(duplicates)){
        forEach(duplicates, (duplicateData, index) =>{
          if(index > 0){
            duplicateDetails.push(duplicateData);
          }
        });
      }
      if(!isEmpty(compact(flatten(duplicates))) && duplicates.length > 1){
        validationResult = {isValid: false, errorMessage: 'This industry is already added.'};
        return true;
      }
    }
    return false;
  });

  const updatedDuplicateData = compact(flatten(duplicateDetails));
  if(isEmpty(updatedDuplicateData)){
    validationResult = {isValid: true, errorMessage: ''};
  }
  return isEmpty(validationResult) ? {isValid: true, errorMessage: ''} : {...validationResult, details: updatedDuplicateData} ;
};