import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as discussionsActions from '../../../redux/actions/discussions';
import * as componentsActions from '../../../redux/actions/components';
import * as commentsActions from '../../../redux/actions/comments';

import toArray from 'lodash/toArray';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { loadForm } from '../../../utils';

import PostCard from '../../../components/skill-builders/discussions/PostCard';

class PostCardContainer extends Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    discussion: PropTypes.object.isRequired,
    showMore: PropTypes.object.isRequired,
    commentShowMore: PropTypes.object.isRequired,
    discussions: PropTypes.object.isRequired,
    loadedComments: PropTypes.array,
    unit: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired,
    loadForms: PropTypes.array.isRequired,
    currentUser: PropTypes.object.isRequired,
    comments: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      showMore: Object.assign({}, this.props.showMore),
      commentShowMore: Object.assign({}, this.props.commentShowMore),
      discussion: { post: '', attachment: {}, permissions: '' },
      attachment: {}
    };

    this.onshowMore = this.onshowMore.bind(this);
    this.onCommentShowMore = this.onCommentShowMore.bind(this);
    this.renderMoreComments = this.renderMoreComments.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
  }

  componentWillMount() {
    const {
      discussion,
      actions,
      comments
    } = this.props;

    const initialComments = discussion.comments;

    // save the initial set of comments in state( comments )
    if (discussion.comments && !comments.loadedComments[discussion.id])
      actions.loadInitialComments({
        discussionId: discussion.id,
        comments: toArray(initialComments)
      });
  }

  componentWillUnmount() {
    const {
      loadForms,
      discussion
    } = this.props;

    const isForm = loadForm(
      loadForms,
      `update-post-form-${discussion.id}`
    );

    if (isForm)
      this.handleResponse();
  }

  renderMoreComments() {
    const {
      discussion,
      actions,
      comments
    } = this.props;

    const pageNo = comments.loadedComments[discussion.id].length / 5 + 1;

    actions.unitDiscussionCommentsRequest(discussion.id, pageNo);
  }

  handleResponse() {
    const { discussion } = this.props;

    this.props.actions.unloadForm(`update-post-form-${discussion.id}`);
  }

  onDeleteClick(){
    this.setState({
      isOpen: true
    });
  }

  onshowMore(postId){
    return () => {
      const { condition } = this.state.showMore;
      const newShowMore = { condition: !condition, id: postId};

      this.setState({ showMore: newShowMore });
    };
  }

  onCommentShowMore(id){
    return () => {
      const { id: conditionId } = this.state.commentShowMore;

      const condition = (conditionId !== id);
      const commentShowMore = { condition, id };

      this.setState({ commentShowMore });
    };
  }

  toggleModal() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  onDelete() {
    const {
      actions,
      discussion
    } = this.props;

    actions.deleteUnitDiscussion(discussion.id)
      .then(() => {
        this.handleResponse();
      });
  }

  render() {
    const { errors, showMore, commentShowMore, isOpen } = this.state;

    let {
      loadForms,
      comments,
      loadedComments,
      discussion,
      discussions,
      isAuthenticated,
      currentUser,
      unit,
      profile
    } = this.props;

    if (comments.loadedComments[discussion.id]) // if it's not undefined
      loadedComments = comments.loadedComments[discussion.id];

    return (
      <div className="forums">
        <PostCard
          unit={unit}
          type="post"
          profile={profile}
          currentUser={currentUser}
          loadForms={loadForms}
          discussion={discussion}
          likes={this.props.discussion.like_count}
          discussionComments={loadedComments}
          showMore={showMore}
          onshowMore={this.onshowMore}
          commentShowMore={commentShowMore}
          onCommentShowMore={this.onCommentShowMore}
          onDeleteClick={this.onDeleteClick}
          errors={errors}
          renderMoreComments={this.renderMoreComments}
          isRequesting={comments.isRequesting}
          actualCommentCount={discussions.commentCount[discussion.id]}
          isAuthenticated={isAuthenticated}/>

        <Modal
          backdrop="static"
          className="modal-margin-top"
          isOpen={isOpen}
          toggle={this.toggleModal}>
          <ModalHeader
            toggle={this.toggleModal}>
            Delete Post
          </ModalHeader>
          <ModalBody>
            Are you sure you want to permanently delete this post? If you do, it cannot be undone.
          </ModalBody>
          <ModalFooter>
            <button
              onClick={this.toggleModal}
              className="btn btn-secondary">
              Cancel
            </button>

            <button
              onClick={this.onDelete}
              className="btn btn-danger"
              disabled={discussions.isDeleting.status}>
              {discussions.isDeleting.status ? 'Deleting...' : 'Permanently Delete'}
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let loadedComments = [];

  let showMore = { condition: false, id: null};
  let commentShowMore = { condition: false, id: null};

  const { auth, profile, components, discussions } = state;

  const { forms } = components;

  return {
    showMore,
    commentShowMore,
    discussions,
    loadedComments,
    profile: profile.data,
    currentUser: auth.data.currentUser,
    isAuthenticated: state.auth.isAuthenticated,
    loadForms: forms,
    comments: state.comments
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {}, discussionsActions, componentsActions, commentsActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostCardContainer);
