import PropTypes from 'prop-types';
import React from 'react';
import TextArea from '../../components/common/TextArea';
import Dropzone from 'react-dropzone';
import ImagePreview from '../common/ImagePreview';
import _isUndefined from 'lodash/isUndefined';

const pinStyles = {
  avatarInput: {
    position: 'absolute',
  },
  label: {
    cursor: 'pointer'
  },
  closeIcon: {
    position: 'absolute',
    top: 5,
    right: 5,
    zIndex: 99,
    cursor: 'pointer',
    color: '#ff5000',
    fontSize: 20
  }
};

const WallCommentForm = ({
  noTextCommentBox,
  wallPostComment,
  errors,
  onChange,
  handleKeyPress,
  styles,
  commentType,
  imagePreviewUrl,
  onCancel,
  onDropFile,
  profile,
  form,
  isForm
}) => {
  const noAvatar = (_isUndefined(profile.avatar) || profile.avatar === '');

  let commentFormClass;
  let wrapperClass = 'post-comment';
  const { comment:error} = errors;

  if (error && error.length > 0) {
    wrapperClass += ' ' + 'error';
  }

  let imageClass = 'header-image medium align-top';
  switch (commentType) {
  case 'tertiary':
    commentFormClass = 'tertiary-post-comment';
    imageClass = 'header-image small align-top';
    break;
  case 'secondary':
    commentFormClass = 'secondary-post-comment tertiary-post';
    imageClass = 'header-image small align-top';
    break;
  default:
    commentFormClass = 'secondary-post-comment';
    break;
  }

  const validMimeType = 'image/*';
  return (
    <div className={commentFormClass}>
      <div className="left">
        {
          noAvatar ?
            <span
              className={`${imageClass} initial-avatar`}>
              {profile.name && profile.name.slice(0,1)}
            </span>:
            <img
              className={imageClass}
              width="64"
              height="64"
              src={profile.avatar}
              alt="Profile Avatar"/>
        }
      </div>
      <div className="right">
        <form className={wrapperClass}>
          <div className="form-group">
            <div className="input-group">
              <TextArea
                styles={styles}
                inputId={form}
                autoHeight={noTextCommentBox}
                rows="1"
                name="comment"
                placeholder="Write a comment..."
                value={wallPostComment.comment}
                onChange={onChange}
                onKeyPress={handleKeyPress}
                autofocus={!!isForm}/>

              <label
                style={pinStyles.label}
                className="input-group-addon align-topm"
                title="attach">
                <Dropzone
                  style={pinStyles.avatarInput}
                  multiple={false}
                  accept={validMimeType}
                  onDrop={onDropFile}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()}/>
                      </div>
                    </section>
                  )}
                </Dropzone>

                <a className="browse-icon">
                  <i className="fa fa-paperclip"/>
                </a>
              </label>
            </div>
          </div>
        </form>

        {error && <div className="error-feedback">{error}</div>}

        <ImagePreview
          imagePreviewUrl={imagePreviewUrl}
          onCancel={onCancel}
          closeIcon={pinStyles.closeIcon}
          secondary/>

        <div className="share-stats" style={{ marginTop: -16 }}>
          <span>Press Enter to post.</span>
        </div>
      </div>
    </div>
  );
};

WallCommentForm.propTypes = {
  handleKeyPress: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  commentType: PropTypes.string,
  styles: PropTypes.object,
  wallPostId: PropTypes.string,
  wallPostComment: PropTypes.object.isRequired,
  textareaRef: PropTypes.func,
  errors: PropTypes.object,
  imagePreviewUrl: PropTypes.string,
  onCancel: PropTypes.func,
  onDropFile: PropTypes.func,
  isForm: PropTypes.bool,
  noTextCommentBox: PropTypes.bool,
  form: PropTypes.string,
};

export default WallCommentForm;
