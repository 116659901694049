import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as jobSearchActions from '../../redux/actions/job-search';

import { withHooks } from '../../utils/withHooks';

import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'reactstrap';
import { Link, Outlet } from 'react-router-dom';
import classNames from 'classnames';

class JobsWrapper extends Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  }

  constructor(props, context) {
    super(props, context);
  }

  componentWillUnmount() {
    this.props.actions.resetJobSearch();
  }

  render() {
    const { location } = this.props;

    return (
      <div>
        <Helmet title="Job Search"/>

        <div className="admin-navbar admin-panel" style={{ padding:16 }}>
          <Container>
            <Row>
              <Col lg="8" md="7">
                <h2 className="mb-0">Job Center</h2>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="admin-navbar">
          <Container>
            <Row>
              <Col lg="12">
                <Link
                  to="/job-center"
                  className={classNames({ active: location.pathname === '/job-center' })}>
                  Job Match
                </Link>
                {/* <Link
                  to="/job-center/job-search"
                  className={classNames({ active: location.pathname === '/job-center/job-search' })}>
                  Job Search
                  </Link> */}
                <Link
                  to="/job-center/job-tracker"
                  className={classNames({
                    active: location.pathname.includes('/job-tracker') ||
                      location.pathname.includes('/my-jobs')
                  })}>
                  Job Tracker
                </Link>
                <Link
                  to="/job-center/resume-builder"
                  className={classNames({
                    active: location.pathname.includes('/job-center/resume-builder')
                  })}>
                  Resume Builder
                </Link>
                <Link
                  to="/job-center/interview-videos"
                  className={classNames({
                    active: location.pathname.includes('/interview-videos') ||
                      location.pathname.includes('/interview-prep-videos')
                  })}>
                  Interview Videos
                </Link>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="container">
          <div className="row">
            <Outlet/>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ profile: { data: profile } }) => ({ profile });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(jobSearchActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(JobsWrapper));
