import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'reactstrap';

const icon_review = require('../../assets/images/icon_review.png');
const icon_assess = require('../../assets/images/icon_assess.png');
const icon_claim = require('../../assets/images/icon_badge.png');

const SkillBadgeModal = ({showSkillBadgeModal, toggleSkillBadgeModal,toggleOrgContent}) => {
  const SkillBadge1 = toggleOrgContent ?
    (<div>
      <p>
        Learners join any one of 43 Skill Builders to build, refresh or strengthen an in-demand skill.
      </p>
    </div>) :
    (<div>
      <p>
        Join a Skill Builder for a skill you want to build, refresh, or strengthen.
      </p>
    </div>);
  const SkillBadge2 = toggleOrgContent ?
    (<div>
      <p>
        Learners reflect on their experiences demonstrating the skill & their resulting achievements.
      </p>
    </div>) :
    (<div>
      <p>
        Think about your experiences, how you used the skill & what you achieved as a result.
      </p>
    </div>);
  const SkillBadge3 = toggleOrgContent ?
    (<div>
      <p>
        Learners answer interview questions to claim a badge, link artifacts, & get endorsements to prove their skill.
      </p>
    </div>) :
    (<div>
      <p>
        Answer interview questions to get a badge. Link portfolio files to it and get endorsements to prove your skill.
      </p>
    </div>);
  return (
    <Modal className="" isOpen={showSkillBadgeModal} toggle={toggleSkillBadgeModal} size={'lg'}>
      <div className="modal1 skill-badge-modal" id="skillBadge">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel">Skill Badges</h4>
            <button type="button" className="close" onClick={toggleSkillBadgeModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="text-center modal-body">
            <div className="row">
              <div className="col-lg-4">
                <div className="img">
                  <span>1</span>
                  <img src={icon_review} />
                </div>
                <div className="title">Join a Skill Builder</div>
                {SkillBadge1}
              </div>

              <div className="col-lg-4">
                <div className="img">
                  <span>2</span>
                  <img src={icon_assess} />
                </div>
                <div className="title">Reflect on Your Skill</div>
                {SkillBadge2}
              </div>

              <div className="col-lg-4">
                <div className="img">
                  <span>3</span>
                  <img src={icon_claim} />
                </div>
                <div className="title">Claim a Skill Badge</div>
                {SkillBadge3}
              </div>
            </div>
            {/* <img className="img-fluid img-responsive" src={mozOpenBadgeIcon} width="350px" style={{marginTop: -15, marginBottom: -10}}/>
            <p style={{fontSize:14}}>Anyone can claim to have a skill, but not everyone can prove it. Skill Badges are an innovative way to help you prove your hidden skills with  authentic evidence others can review and endorse.</p> */}
          </div>
        </div>
      </div>
    </Modal>
  );
};

SkillBadgeModal.propTypes = {
  showSkillBadgeModal: PropTypes.bool,
  toggleOrgContent: PropTypes.bool,
  toggleSkillBadgeModal: PropTypes.func
};

export default SkillBadgeModal;
