import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const VideoIframe = ({url, secondary}) => {
  const commentVideo= classNames(
    { 'comment-img': secondary }
  );

  return (
    <div className={commentVideo} style={{ marginTop: 10 }}>
      <div className="embed-responsive embed-responsive-16by9">
        <iframe
          src={url}
          allowFullScreen
          style={{'overflow': 'hidden'}}
          scrolling="no"
          className="embed-responsive-item"/>
      </div>
    </div>
  );
};

VideoIframe.propTypes = {
  url: PropTypes.string.isRequired,
  secondary: PropTypes.bool
};

export default VideoIframe;
