import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { withHooks } from '../../utils/withHooks';

import { Helmet } from 'react-helmet';

import ProfileSectionList from './sections/ProfileSectionList';
import RestrictedWebpageSection from '../../components/profile/visiting/RestrictedWebpageSection';

class Profile extends Component {
  static propTypes = {
    profile: PropTypes.object,
    isAuthenticated: PropTypes.bool.isRequired,
    isInPreviewPage: PropTypes.bool.isRequired
  }

  constructor(props, context) {
    super(props, context);
  }

  render() {
    const { isInPreviewPage, profile, isAuthenticated } = this.props;
    const isPrivatePreview = isInPreviewPage && profile.privacySettings === 1;
    const isPublicProfile = isInPreviewPage || (!isInPreviewPage && !isAuthenticated);

    return (
      <div className="profile-page col-lg-12">
        <Helmet title={`${profile.name}'s Webpage`}/>
        {
          isPrivatePreview &&
            <div className="row">
              <RestrictedWebpageSection/>
            </div>
        }
        {
          !isPrivatePreview &&
            <ProfileSectionList isPublicProfile={isPublicProfile}/>
        }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const splittedURL = ownProps.location.pathname.split('/');

  const isInPreviewPage = splittedURL[3] === 'preview';

  return {
    isInPreviewPage,
    isAuthenticated: state.auth.isAuthenticated,
    profile: state.profile.data
  };
};

export default withHooks(connect(mapStateToProps)(Profile));
