import React from 'react';

import PropTypes from 'prop-types';
import { Tooltip, ModalFooter } from 'reactstrap';

import TextInput from '../common/TextInput';
import TextArea from '../common/TextArea';
import FileFormButtons from './FileFormButtons';
import UploadFile from './UploadFile';
import LoadedFile from './LoadedFile';
import SupportingEvidenceHandler from '../../containers/portfolio/SupportingEvidenceHandler';

import { parseUrlString } from '../../utils';

const PortfolioFileForm = ({
  onDropFile,
  onChange,
  onSaveSupportingEvidence,
  description,
  files,
  skills,
  badges,
  isEditingCard,
  onDelete,
  onCancel,
  onUpdate,
  title,
  mimetype,
  imagePreviewUrl,
  url,
  imagePreviewText,
  errors,
  selectedVisibiltyId,
  visibilityDropdownOpen,
  onToggleVisibilityDropdown,
  onSelectVisibilty,
  getSelectedBadges,
  getSelectedSkills,
  onInsertLinkClicked,
  showInsertTextBox,
  onDeleteLoadedFile,
  guideLinesTooltipOpen,
  toggleGuidelinesTooltip,
  skillEvidence,
  unitSkill
}) => {
  const updateCard = isEditingCard ? { onDelete, onCancel, onUpdate } : null;
  const parseUrl = parseUrlString(url);
  const portfolioFileLoaded = mimetype !== null;

  const fileUrl = imagePreviewUrl !== '' ? imagePreviewUrl : url;
  const seletedSkillsOrBadges = skills.length > 0 || badges.length > 0;
  const fileUploadError = (errors.file && errors.file.length > 0) && (errors.url && errors.url.length > 0);

  const descriptionLabel = (
    <small>
      A strong reflection...
      <ol>
        <li>Describes your file so others understand what it is.</li>
        <li>Explains the knowledge, skills, and abilities you feel the file shows.</li>
        <li>Explains what you learned or reinforced from the experience.</li>
        <li>Explains how what you learned can be applied in your career.</li>
      </ol>
    </small>
  );

  return (
    <div>
      <div className="content experience add-portfolio-files p-1">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <div className="form-step">
                    <div className="form-step__label">
                      <div className="form-step__step">1</div>
                      <label>Add a portfolio file:</label>&nbsp;
                      <small>
                        <a href="#" id="adding-portfolio-guidelines">(See guidelines)</a>
                      </small>
                      <Tooltip
                        placement="bottom"
                        isOpen={guideLinesTooltipOpen}
                        target="adding-portfolio-guidelines"
                        toggle={toggleGuidelinesTooltip}>
                        <div className="inside-tooltip" style={{fontSize: 12}}>
                          <div><b>Portfolio File Guidelines</b></div>
                          <div style={{textAlign: 'left', marginTop: 10}}>
                            Attach items such as awards, training certificates, and/or work samples.
                          </div>

                          <div style={{textAlign: 'left', marginTop: 10}}><b>All items must...</b></div>
                          <ul style={{padding: '0px 0px 20px 20px', textAlign: 'left', marginBottom: 0}}>
                            <li>not violate copyright or any other laws</li>
                            <li>not be confidential or sensitive in nature</li>
                            <li>respect privacy of others (You can remove/blackout names from evidence you upload and indicate "For confidentiality, names have been removed.")</li>
                          </ul>
                        </div>
                      </Tooltip>
                    </div>
                    {
                      (!showInsertTextBox && !portfolioFileLoaded) &&
                        <div className="form-step__content portfolio-file-selector-container">
                          <UploadFile
                            onDropFile={onDropFile}
                            imagePreviewUrl={imagePreviewUrl}/>
                          <hr className="hr-line"/>
                          <div
                            onClick={onInsertLinkClicked}
                            className="insert-link">
                            <span className="insert-text">
                              <i className="fa fa-link upload-icon"/>
                              Paste a Website Link
                            </span>
                            <span className="supported-types">
                              YouTube or any Website
                            </span>
                          </div>
                          {errors.file && <div className="form-control-feedback text-danger">Upload a File or Insert a Link to a Video File </div>}
                        </div>
                    }

                    {
                      (showInsertTextBox && !portfolioFileLoaded) &&
                        <div className="form-step__content insert-url-textinput-conatiner">
                          <span
                            onClick={onInsertLinkClicked}
                            className="close-icon">
                            <i className="fa fa-times"/>
                          </span>
                          <span style={{ width: '90%'}}>
                            <TextInput
                              name="url"
                              placeholder="Paste a link to showcase your work from another website"
                              value={url}
                              style={{ marginBottom : 0}}
                              error={errors.url}
                              onChange={onChange}/>
                            <div className="pf-help-text">
                              Example: https://youtu.be/z_7UlNyZaKY
                            </div>
                          </span>
                        </div>
                    }

                    {
                      portfolioFileLoaded &&
                        <div className="form-step__content">
                          <LoadedFile
                            imagePreviewUrl={fileUrl}
                            imagePreviewText={imagePreviewText}
                            parseUrl={parseUrl}
                            mimetype={mimetype}
                            onDeleteLoadedFile={onDeleteLoadedFile}/>
                        </div>
                    }

                    {
                      fileUploadError &&
                        <div className="file-error">
                          Please select either a file to upload or a video link.
                        </div>
                    }
                  </div>
                  <div className="form-step">
                    <div className="form-step__label">
                      <div className="form-step__step">2</div>
                      <label>Give a descriptive title for your portfolio project:</label>
                    </div>
                    <div className="form-step__content">
                      <TextInput
                        name="title"
                        placeholder="Examples: Report on History of Film, Logo Design, Group Presentation, etc."
                        value={title}
                        onChange={onChange}
                        label="Project Title"
                        redText="Required"
                        error={errors.title}/>
                    </div>
                  </div>
                  <div className="form-step">
                    <div className="form-step__label">
                      <div className="form-step__step">3</div>
                      <label>This file shows evidence of the following skills I feel I have:</label>
                    </div>
                    <div className="form-step__content">
                      <SupportingEvidenceHandler
                        skillEvidence={skillEvidence}
                        isEditingCard={isEditingCard}
                        selectedSkills={skills}
                        selectedBadges={badges}
                        getSelectedBadges={getSelectedBadges}
                        getSelectedSkills={getSelectedSkills}/>
                    </div>
                  </div>
                  <div className="form-step">
                    <div className="form-step__label">
                      <div className="form-step__step">4</div>
                      <label>Reflect on your portfolio project:</label>
                    </div>
                    <div className="form-step__content">
                      <TextArea
                        name="description"
                        value={description}
                        onChange={onChange}
                        rows={'5'}
                        label="Portfolio Reflection"
                        labelDescription={descriptionLabel}
                        redText="Required"
                        placeholder={'Write your thoughtful reflection. Strong reflections showcase your higher-order thinking skills and will help you remember your skills, achievements, and learning over time. If you take time to build a thoughtful portfolio, it will become an asset to help you stand out in the job market.'}
                        error={errors.description}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalFooter className="row">
        <FileFormButtons
          files={files}
          unitSkill={unitSkill}
          onSaveSupportingEvidence={onSaveSupportingEvidence}
          onCancel={onCancel}
          seletedSkillsOrBadges={seletedSkillsOrBadges}
          updateCard={updateCard}
          selectedVisibiltyId={selectedVisibiltyId}
          visibilityDropdownOpen={visibilityDropdownOpen}
          onToggleVisibilityDropdown={onToggleVisibilityDropdown}
          onSelectVisibilty={onSelectVisibilty}/>
      </ModalFooter>
    </div>
  );
};

PortfolioFileForm.propTypes = {
  onDropFile: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSaveSupportingEvidence: PropTypes.func.isRequired,
  description: PropTypes.string,
  files: PropTypes.object.isRequired,
  unitSkill: PropTypes.object,
  badges: PropTypes.array,
  skills: PropTypes.array,
  isEditingCard: PropTypes.bool,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  imagePreviewUrl: PropTypes.string,
  imagePreviewText: PropTypes.string,
  errors: PropTypes.object,
  url: PropTypes.string,
  mimetype: PropTypes.string,
  selectedVisibiltyId: PropTypes.string.isRequired,
  visibilityDropdownOpen: PropTypes.bool.isRequired,
  onToggleVisibilityDropdown: PropTypes.func.isRequired,
  onSelectVisibilty: PropTypes.func.isRequired,
  getSelectedBadges: PropTypes.func.isRequired,
  getSelectedSkills: PropTypes.func.isRequired,
  onInsertLinkClicked: PropTypes.func.isRequired,
  showInsertTextBox: PropTypes.bool.isRequired,
  onDeleteLoadedFile: PropTypes.func.isRequired,
  guideLinesTooltipOpen: PropTypes.bool,
  toggleGuidelinesTooltip: PropTypes.func,
  skillEvidence: PropTypes.bool
};

export default PortfolioFileForm;
