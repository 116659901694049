import { CALL_API } from '../../middlewares/api';

import {
  SKILL_CATEGORIES_REQUEST,
  SKILL_CATEGORIES_SUCCESS,
  SKILL_CATEGORIES_FAILURE,

  SKILL_SUB_CATEGORIES_REQUEST,
  SKILL_SUB_CATEGORIES_SUCCESS,
  SKILL_SUB_CATEGORIES_FAILURE
} from '../../constants';

export const getSkillCategories = (page = 1, query = '') => {
  let endpoint = `user/skill-categories?page=${page}`;

  if (query.length > 0) {
    endpoint = `${endpoint}&query=${query}`;
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        SKILL_CATEGORIES_REQUEST,
        SKILL_CATEGORIES_SUCCESS,
        SKILL_CATEGORIES_FAILURE
      ]
    }
  };
};

export const getSkillSubCategories = (categoryId, page = 1, query = '') => {
  let endpoint = `user/skill-categories?page=${page}&skillCategoryId=${categoryId}`;

  if (query.length > 0) {
    endpoint = `${endpoint}&query=${query}`;
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        SKILL_SUB_CATEGORIES_REQUEST,
        SKILL_SUB_CATEGORIES_SUCCESS,
        SKILL_SUB_CATEGORIES_FAILURE
      ]
    }
  };
};
