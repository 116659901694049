import { CALL_API } from '../../middlewares/api';
import {
  COMMENTS_REQUEST,
  COMMENTS_SUCCESS,
  COMMENTS_FAILURE,
  CREATE_COMMENT_LIKE_REQUEST,
  CREATE_COMMENT_LIKE_SUCCESS,
  CREATE_COMMENT_LIKE_FAILURE,
  CREATE_COMMENT_REQUEST,
  CREATE_COMMENT_SUCCESS,
  CREATE_COMMENT_FAILURE,
  UPDATE_COMMENT_REQUEST,
  UPDATE_COMMENT_SUCCESS,
  UPDATE_COMMENT_FAILURE,
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_FAILURE,
  CREATE_REPLY_REQUEST,
  CREATE_REPLY_SUCCESS,
  CREATE_UNIT_FAILURE,
  DELETE_REPLY_REQUEST,
  DELETE_REPLY_SUCCESS,
  DELETE_REPLY_FAILURE,
  UPDATE_REPLY_REQUEST,
  UPDATE_REPLY_SUCCESS,
  UPDATE_REPLY_FAILURE,
  CREATE_REPLY_LIKE_REQUEST,
  CREATE_REPLY_LIKE_SUCCESS,
  CREATE_REPLY_LIKE_FAILURE,
  LOAD_INITIAL_COMMENTS,
  INITIALIZE_COMMENTS,
  LOAD_PREVIOUS_REPLIES,
  UNLIKE_REPLY_REQUEST,
  UNLIKE_REPLY_SUCCESS,
  UNLIKE_REPLY_FAILURE,
  UNLIKE_DISCUSSION_COMMENT_REQUEST,
  UNLIKE_DISCUSSION_COMMENT_SUCCESS,
  UNLIKE_DISCUSSION_COMMENT_FAILURE,
} from '../../constants';

export const initializeComments = () => {
  return dispatch => dispatch({ type: INITIALIZE_COMMENTS });
};

export const loadInitialComments = (data) => {
  return dispatch => dispatch({ type: LOAD_INITIAL_COMMENTS, data });
};

export const loadPreviousReplies = (replies) => {
  return dispatch => dispatch({ type: LOAD_PREVIOUS_REPLIES, replies });
};

export const unitDiscussionCommentsRequest = (discussionId, pageNo) => {
  const endpoint = `discussion/comment?discussion=${discussionId}&page=${pageNo}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      id: discussionId,
      types: [COMMENTS_REQUEST, COMMENTS_SUCCESS, COMMENTS_FAILURE],
    },
  };
};

export const saveUnitDiscussionComment = (discussionId, data) => {
  const endpoint = 'discussion/comment';
  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      id: discussionId,
      types: [
        CREATE_COMMENT_REQUEST,
        CREATE_COMMENT_SUCCESS,
        CREATE_COMMENT_FAILURE,
      ],
    },
  };
};

export const saveUnitDiscussionReply = (data) => {
  const endpoint = 'discussion/comment';
  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [CREATE_REPLY_REQUEST, CREATE_REPLY_SUCCESS, CREATE_UNIT_FAILURE],
    },
  };
};

export const saveUnitDiscussionCommentLike = (data, discussionId) => {
  const endpoint = 'discussion/comment_like';

  return {
    [CALL_API]: {
      data,
      id: { commentId: data.comment, discussionId },
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        CREATE_COMMENT_LIKE_REQUEST,
        CREATE_COMMENT_LIKE_SUCCESS,
        CREATE_COMMENT_LIKE_FAILURE,
      ],
    },
  };
};

export const unlikeUnitDiscussionComment = (data, discussionId) => {
  const endpoint = `discussion/comment_like/${data.comment}`;

  return {
    [CALL_API]: {
      id: { commentId: data.comment, discussionId },
      userId: data.userId,
      endpoint,
      method: 'delete',
      authenticated: true,
      types: [
        UNLIKE_DISCUSSION_COMMENT_REQUEST,
        UNLIKE_DISCUSSION_COMMENT_SUCCESS,
        UNLIKE_DISCUSSION_COMMENT_FAILURE,
      ],
    },
  };
};

export const saveUnitDiscussionReplyLike = (data) => {
  const endpoint = 'discussion/comment_like';

  return {
    [CALL_API]: {
      data,
      id: data.comment,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        CREATE_REPLY_LIKE_REQUEST,
        CREATE_REPLY_LIKE_SUCCESS,
        CREATE_REPLY_LIKE_FAILURE,
      ],
    },
  };
};

export const unlikeUnitDiscussionReply = (data) => {
  const { userId, comment: id } = data;
  const endpoint = `discussion/comment_like/${id}`;

  return {
    [CALL_API]: {
      id,
      userId,
      endpoint,
      method: 'delete',
      authenticated: true,
      types: [UNLIKE_REPLY_REQUEST, UNLIKE_REPLY_SUCCESS, UNLIKE_REPLY_FAILURE],
    },
  };
};

export const updateUnitDiscussionComment = (discussionId, commentId, data) => {
  const endpoint = `discussion/comment/${commentId}`;

  return {
    [CALL_API]: {
      data,
      id: discussionId,
      endpoint,
      method: 'put',
      authenticated: true,
      types: [
        UPDATE_COMMENT_REQUEST,
        UPDATE_COMMENT_SUCCESS,
        UPDATE_COMMENT_FAILURE,
      ],
    },
  };
};

export const updateUnitDiscussionReply = (data, id) => {
  const endpoint = `discussion/comment/${id}`;

  return {
    [CALL_API]: {
      id: id,
      data,
      endpoint,
      method: 'put',
      authenticated: true,
      types: [UPDATE_REPLY_REQUEST, UPDATE_REPLY_SUCCESS, UPDATE_REPLY_FAILURE],
    },
  };
};

export const deleteUnitDiscussionComment = (commentId, discussionId) => {
  const endpoint = `discussion/comment/${commentId}`;

  return {
    [CALL_API]: {
      endpoint,
      id: { commentId: commentId, discussionId: discussionId },
      method: 'delete',
      authenticated: true,
      types: [
        DELETE_COMMENT_REQUEST,
        DELETE_COMMENT_SUCCESS,
        DELETE_COMMENT_FAILURE,
      ],
    },
  };
};

export const deleteUnitDiscussionReply = (replyId) => {
  const endpoint = `discussion/comment/${replyId}`;
  return {
    [CALL_API]: {
      id: replyId,
      endpoint,
      method: 'delete',
      authenticated: true,
      types: [DELETE_REPLY_REQUEST, DELETE_REPLY_SUCCESS, DELETE_REPLY_FAILURE],
    },
  };
};
