import PropTypes from 'prop-types';
import React from 'react';

import Button from '../../common/Button';
import SectionQuestionForm from '../../../containers/skill-builders/admin/SectionQuestionForm';
import ThinkingBreakAnswer from './ThinkingBreakAnswer';
import ThinkingBreakAnswerForm from '../../../containers/skill-builders/admin/ThinkingBreakAnswerForm';

const styles = {
  panelHeading: { minHeight: 30, backgroundColor: '#fafafa', marginBottom: 10 },
  heading: { textAlign: 'center', paddingRight: 40, color: 'black', paddingTop: 10 }
};

const ThinkingBreakForm = ({
  isAdmin,
  onCancel,
  errors,
  section,
  onChange,
  onSave,
  onDelete,
  isSubmitting,
  isUpdating,
  isDeleting,
  formType,
  onDeleteListItem,
  editingItemId,
  onEditListItem,
  onAddAnswer,
  onUpdateAnswer,
  onChangeQuestion
}) => {
  const formId = formType && `${formType.formName}_${formType.id}`;
  const thinkingBreakAnswer = section.content.map((item, index) => {
    const checked = section.correct === index;

    return (
      <ThinkingBreakAnswer
        key={`thinking-break_${item.itemId}`}
        item={item}
        index={index}
        errors={errors}
        checked={checked}
        onChange={onChange}
        onDeleteListItem={onDeleteListItem}
        onEditListItem={onEditListItem}/>
    );
  });

  return (
    <div>
      {isAdmin &&
        <div className="card-header" style={styles.panelHeading}>
          <h3 style={styles.heading}>
            Thinking break
          </h3>
        </div>}

      <div className="card-block p-3">
        <form id={formId}>
          <SectionQuestionForm
            errors={errors}
            question={section.title}
            onChangeQuestion={onChangeQuestion}/>
          <div>
            <h5>Thinking break answer form</h5>
            <hr/>
            <ThinkingBreakAnswerForm
              content={section.content}
              editingItemId={editingItemId}
              onAddAnswer={onAddAnswer}
              onUpdateAnswer={onUpdateAnswer}/>
          </div>

          <h5>Thinking break options</h5>
          <hr/>

          <div className="row">
            <div className="col-sm-12">
              {section.content.length === 0 &&
                <div className="search-hint badge-hint">
                  <span className="fa fa-info-circle" aria-hidden="true"/>
                  <p>This section does not have quiz options yet.</p>
                </div>}

              {section.content.length > 0 &&
                <ul className="list-group" style={{marginBottom: 20}}>
                  {thinkingBreakAnswer}
                </ul>}
            </div>
          </div>

          {!formType ?
            <Button
              type="submit"
              onClick={onDelete}
              disabled={isDeleting}
              buttonClass="btn-danger"
              styles={{marginLeft: 0}}
              buttonText={isDeleting ? 'Deleting...' : 'Delete'}/> : ''}

          <Button
            type="submit"
            onClick={onSave}
            disabled={isSubmitting || isUpdating}
            buttonClass="btn-primary float-right"
            buttonText={isSubmitting || isUpdating ? 'Saving...' : 'Save'}/>

          <Button
            type="button"
            buttonId={section ? `thinking-break_${section.id}` : ''}
            onClick={onCancel}
            buttonClass="btn-secondary float-right"
            buttonText="Cancel"/>
        </form>
      </div>
    </div>
  );
};

ThinkingBreakForm.propTypes = {
  section: PropTypes.object,
  errors: PropTypes.object,
  formType: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDeleteListItem: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  onAddAnswer: PropTypes.func.isRequired,
  editingItemId: PropTypes.string,
  onEditListItem: PropTypes.func.isRequired,
  onUpdateAnswer: PropTypes.func.isRequired,
  onChangeQuestion: PropTypes.func.isRequired
};

export default ThinkingBreakForm;
