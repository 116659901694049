import React, { useState } from 'react';

import { connect, useDispatch } from 'react-redux';

import {
  Row,
  Col,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import SearchInput from './users-list/search/SearchInput';
import AdminSubGroups from '../../containers/admin/AdminSubGroups';
import AdminStatistics from '../../containers/admin/AdminStatistics';
import AdminUsersTable from '../../containers/admin/AdminUsersTable';
import CopyGroupCodeButton from '../../containers/admin/CopyGroupCodeButton';
import AdvancedFilterControls from './users-list/search/AdvancedFilterControls';
import ToggleAdvancedFilterButton from './users-list/search/ToggleAdvancedFilterButton';

import { disableBulkMessages } from '../../redux/actions/profile';
import { getUsers, setUsersSortOrder } from '../../redux/actions/users';

import { flatten, includes, isEmpty } from 'lodash';
import { generateRequestUrl } from '../../utils';

const Dashboard = ({
  sort,
  page,
  filters,
  selectedGroup,
  toggleAdvancedFilter,
  displayAdvancedFilters,
  selectedInstitutionId,
  isAuthUserSelectedInstitutionAdmin
}) => {
  const [isOpen, setOpen]= useState(false);
  const [bulkStatus, setStatus]= useState('');
  const [selectedUsersList, setSelectedUsersList] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isNameOrderOpen, setIsNameOrderOpen] = useState(false);

  const dispatch = useDispatch();

  function toggleActions(){
    setOpen(prev => !prev);
  }

  function toggleNameOrderAction() {
    setIsNameOrderOpen(prevState => !prevState);
  }

  async function toggleNameOrder(event) {
    const orderBy = event.target.dataset.orderBy;

    const sortParams = { ...sort, orderBy, column: 'name' };

    dispatch(setUsersSortOrder(sortParams));

    const urlProps = {
      page,
      filters,
      sort: sortParams,
      groupId: selectedGroup.id,
      isAuthUserSelectedInstitutionAdmin,
      institutionId: selectedInstitutionId,
    };
    const urlParamString = generateRequestUrl(urlProps);

    await dispatch(getUsers(urlParamString));
  }

  function submitBulkDisableMessage(e){
    const action = e.target.getAttribute('data-label');

    disableMessages(action);
  }

  async function disableMessages(action){
    if(isEmpty(selectedUsersList) && !isAllSelected) {
      toastr.error('', 'Please select user.');
      return;
    }
    const status = action === 'enable' ? 1 : 0;

    let data = {
      users: flatten(selectedUsersList),
      privateMessagingStatus: status
    };

    if(isAllSelected){
      data = {
        data: {institutionId: selectedInstitutionId,
          privateMessagingStatus: status,
        }
      };
    }

    await dispatch(disableBulkMessages(data, flatten(selectedUsersList)[0]));
    setStatus(action);
  }

  function setSelectedUsers(id) {
    if(typeof id === 'string'){
      if(includes(selectedUsersList, id)){
        const selectedUser = selectedUsersList.filter(userId => userId !== id);
        setSelectedUsersList(selectedUser);
      } else {
        const updatingData = isEmpty(selectedUsersList) ? [id] : [...selectedUsersList, id];
        setSelectedUsersList(updatingData);
      }
    } else {
      setSelectedUsersList([]);
    }
  }

  return (
    <div>
      <Row className="align-items-center">
        <Col>
          <div className="groupName">
            {selectedGroup.name &&
              <span
                className="img-responsive initial-avatar group">
                {selectedGroup.name.slice(0, 1)}
              </span>}

            {selectedGroup.name}
          </div>
        </Col>
        <Col>
          {(selectedGroup.id !== 'all-groups') && <CopyGroupCodeButton/>}
        </Col>
      </Row>

      <Row>
        <Col>
          {(selectedGroup.children) &&
            (selectedGroup.children.length !== 0) &&
            <AdminSubGroups parentPage="admin-dashboard"/>}
        </Col>
      </Row>

      <Row>
        <Col>
          <AdminStatistics/>
        </Col>
      </Row>

      <Row>
        <Col sm="12">
          <h2>Users</h2>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col className="col-12 col-md-5 mb-3 mb-md-0 mb-lg-0">
          <SearchInput/>
        </Col>
        <Col>
          <ToggleAdvancedFilterButton
            toggleAdvancedFilter={toggleAdvancedFilter}/>
        </Col>
        <Col xs="auto">
          <ButtonDropdown
            isOpen={isNameOrderOpen}
            toggle={toggleNameOrderAction}
            style={{marginRight: 10}}>
            <DropdownToggle caret>
              Sort by First Name
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                name="firstLast"
                data-order-by="firstName"
                onClick={toggleNameOrder}
                disabled={sort.orderBy === 'firstName'}>
                First name, Last name
              </DropdownItem>
              <DropdownItem
                name="lastFirst"
                data-order-by="lastName"
                onClick={toggleNameOrder}
                disabled={sort.orderBy === 'lastName'}>
                Last name, First name
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>

          <ButtonDropdown
            isOpen={isOpen}
            data-value="isAllOpen"
            toggle={toggleActions}>
            <DropdownToggle caret>
              Bulk Actions
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                name="enableDM"
                data-label="enable"
                onClick={submitBulkDisableMessage}>
                Enable DM to IMs
              </DropdownItem>
              <DropdownItem
                name="disableDM"
                data-label="disable"
                onClick={submitBulkDisableMessage}>
                Disable DM to IMs
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </Col>

      </Row>

      <Row>
        <Col sm="12">
          {displayAdvancedFilters && <AdvancedFilterControls/>}
        </Col>
      </Row>

      <Row>
        <Col sm="12">
          <AdminUsersTable
            nameOrder={sort.orderBy}
            bulkStatus={bulkStatus}
            disableMessages={disableMessages}
            setSelectedUsers={setSelectedUsers}
            setIsAllSelected={setIsAllSelected}
            isAllSelected={isAllSelected}/>
        </Col>
      </Row>
    </div>
  );
};

Dashboard.propTypes = {
  page: PropTypes.number.isRequired,
  sort: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  selectedGroup: PropTypes.object.isRequired,
  toggleAdvancedFilter: PropTypes.func.isRequired,
  displayAdvancedFilters: PropTypes.bool.isRequired,
  selectedInstitutionId: PropTypes.string,
  isAuthUserSelectedInstitutionAdmin: PropTypes.bool.isRequired
};

const mapStateToProps = ({
  users: { sort, page, filters, displayAdvancedFilters },
  groups: { selectedGroup, isAuthUserSelectedInstitutionAdmin }
}) =>
  ({
    sort,
    page,
    filters,
    isAuthUserSelectedInstitutionAdmin,
    selectedGroup,
    displayAdvancedFilters
  });

export default connect(mapStateToProps)(Dashboard);
