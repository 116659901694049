import React from 'react';
import PropTypes from 'prop-types';

import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

const ProfileModal = ({
  isOpen,
  toggle,
  headerText,
  body,
  onClick,
  disabled,
  buttonText
}) => {
  return (
    <Modal
      className="modal-margin-top"
      isOpen={isOpen}
      toggle={toggle}>
      <ModalHeader
        toggle={toggle}>
        {headerText}
      </ModalHeader>

      <ModalBody>
        {body}
      </ModalBody>

      <ModalFooter>
        <button
          onClick={toggle}
          className="btn btn-secondary">
          Cancel
        </button>

        <button
          onClick={onClick}
          className="btn btn-primary"
          disabled={disabled}>
          {buttonText}
        </button>
      </ModalFooter>
    </Modal>
  );
};

ProfileModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired
};

export default ProfileModal;
