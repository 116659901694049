import moment from 'moment';

import initialState from './initialState';
import {
  GET_GIVEN_FEEDBACK_LIST_REQUEST,
  GET_GIVEN_FEEDBACK_LIST_SUCCESS,
  GET_GIVEN_FEEDBACK_LIST_FAILURE,
  GET_GIVEN_COMPLETED_FEEDBACK_LIST_REQUEST,
  GET_GIVEN_COMPLETED_FEEDBACK_LIST_SUCCESS,
  GET_GIVEN_COMPLETED_FEEDBACK_LIST_FAILURE,
  GET_RECEIVED_FEEDBACK_LIST_REQUEST,
  GET_RECEIVED_FEEDBACK_LIST_SUCCESS,
  GET_RECEIVED_FEEDBACK_LIST_FAILURE,
  GET_REQUESTED_FEEDBACK_LIST_REQUEST,
  GET_REQUESTED_FEEDBACK_LIST_SUCCESS,
  GET_REQUESTED_FEEDBACK_LIST_FAILURE,
  GET_GIVEN_FEEDBACK_LIST_REQUEST_BY_RESOURCE,
  GET_GIVEN_FEEDBACK_LIST_SUCCESS_BY_RESOURCE,
  GET_GIVEN_FEEDBACK_LIST_FAILURE_BY_RESOURCE,
  GET_IR_RANK_REQUEST,
  GET_IR_RANK_SUCCESS,
  GET_IR_RANK_FAILURE,
  GET_PRAISE_REQUEST,
  GET_PRAISE_SUCCESS,
  GET_PRAISE_FAILURE,
  CLEAR_MYFEEDBACK_SUCCESS,
} from '../../constants';

export default function reducer(state = initialState.feedbackList, action) {
  switch (action.type) {
  case GET_GIVEN_FEEDBACK_LIST_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };
  case GET_GIVEN_FEEDBACK_LIST_SUCCESS: {
    const responseList = action.data._embedded?.feedback_responses;
    const pendingFeedback = [];
    responseList?.forEach((response) => {
      for (const userid in response) {
        const obj = response[userid];
        obj['requestedDate'] = moment(
          obj?.feedbackRequestDate?.date
        ).valueOf();
        if (obj && typeof obj === 'object') {
          obj['praise'] = {
            givenOn: '11/12/2020',
            praises: [
              'Descriptive (Explained Clearly)',
              'Actionable (Can be acted on)',
              'Specific (Is precise)',
              'Helpful(Helps me improve)',
            ],
          };
          if (obj['feedbackstatus'] === 'pending') {
            pendingFeedback.push(obj);
          }
        }
      }
    });
    const _links = action.data._links;
    return {
      ...state,
      isRequesting: false,
      data: {
        ...state.data,
        pendingFeedback: (!_links || _links?.self?.href.includes('page=1'))
          ? [...pendingFeedback]
          : [...state.data.pendingFeedback, ...pendingFeedback],
        totalPendingCount: action.data.total_items,
        pendingFeedbackLink: _links,
      },
    };
  }
  case GET_GIVEN_FEEDBACK_LIST_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  case GET_GIVEN_COMPLETED_FEEDBACK_LIST_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };
  case GET_GIVEN_COMPLETED_FEEDBACK_LIST_SUCCESS: {
    const responseList = action.data._embedded?.feedback_responses;
    const completedFeedback = [];
    responseList?.forEach((response) => {
      for (const userid in response) {
        const obj = response[userid];
        if (obj && typeof obj === 'object') {
          // obj['isRevised'] = true;
          obj['praise'] = {
            givenOn: '11/12/2020',
            praises: [
              'Descriptive (Explained Clearly)',
              'Actionable (Can be acted on)',
              'Specific (Is precise)',
              'Helpful(Helps me improve)',
            ],
          };

          if (obj['feedbackstatus'] === 'completed') {
            completedFeedback.push(response[userid]);
          }
        }
      }
    });
    const _links = action.data._links;
    return {
      ...state,
      isRequesting: false,
      data: {
        ...state.data,
        completedFeedbackLink: _links,
        completedFeedback: _links?.self?.href.includes('page=1')
          ? [...completedFeedback]
          : [...state.data.completedFeedback, ...completedFeedback],
        totalCompletedCount: action.data.total_items,
      },
    };
  }
  case GET_GIVEN_COMPLETED_FEEDBACK_LIST_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  case GET_GIVEN_FEEDBACK_LIST_REQUEST_BY_RESOURCE:
    return {
      ...state,
      isRequesting: true,
    };
  case GET_GIVEN_FEEDBACK_LIST_SUCCESS_BY_RESOURCE: {
    const responseList = action.data._embedded?.feedback_responses;

    const resourceFeedback = [];
    responseList?.forEach((response) => {
      for (const userid in response) {
        const obj = response[userid];
        if (obj && typeof obj === 'object') {
          // obj['isRevised'] = true;
          obj['praise'] = {
            givenOn: '11/12/2020',
            praises: [
              'Descriptive (Explained Clearly)',
              'Actionable (Can be acted on)',
              'Specific (Is precise)',
              'Helpful(Helps me improve)',
            ],
          };
          resourceFeedback.push(response[userid]);
        }
      }
    });
    return {
      ...state,
      isRequesting: false,
      data: {
        ...state.data,
        resourceFeedback,
      },
    };
  }
  case GET_GIVEN_FEEDBACK_LIST_FAILURE_BY_RESOURCE:
    return {
      ...state,
      isRequesting: false,
    };

  case GET_RECEIVED_FEEDBACK_LIST_REQUEST:
    return {
      ...state,
      isFeedbackReq: true,
    };
  case GET_RECEIVED_FEEDBACK_LIST_SUCCESS: {
    const receivedResponse = action.data?._embedded?.feedback_responses;
    const received = [];
    receivedResponse?.forEach((response) => {
      for (const userid in response) {
        const obj = response[userid];
        if (obj && typeof obj === 'object') {
          obj['praise'] = {
            givenOn: '11/12/2020',
            praises: [
              'Descriptive (Explained Clearly)',
              'Actionable (Can be acted on)',
              'Specific (Is precise)',
              'Helpful(Helps me improve)',
            ],
          };
          received.push(response[userid]);
        }
      }
    });
    const _links = action.data._links;
    return {
      ...state,
      isRequesting: false,
      data: {
        ...state.data,
        received: _links?.self?.href.includes('page=1')
          ? [...received]
          : [...state.data.received, ...received],
        receivedFeedbackLink: _links,
        totalReceivedCount: action.data.total_items,
      },
    };
  }
  case GET_RECEIVED_FEEDBACK_LIST_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  case GET_REQUESTED_FEEDBACK_LIST_REQUEST:
    return {
      ...state,
      isFeedbackReq: true,
    };
  case GET_REQUESTED_FEEDBACK_LIST_SUCCESS: {
    const requestedResponse = action.data?._embedded?.feedback_responses;
    const requested = [];
    requestedResponse?.forEach((response) => {
      for (const userid in response) {
        const obj = response[userid];
        obj['requestedDate'] = moment(
          obj?.feedbackRequestDate?.date
        ).valueOf();
        obj['resendDate'] = obj.resendOn
          ? moment(obj?.resendOn).valueOf()
          : null;
        if (obj && typeof obj === 'object') {
          obj['praise'] = {
            givenOn: '11/12/2020',
            praises: [
              'Descriptive (Explained Clearly)',
              'Actionable (Can be acted on)',
              'Specific (Is precise)',
              'Helpful(Helps me improve)',
            ],
          };
          requested.push(obj);
        }
      }
    });
    const _links = action.data._links;
    return {
      ...state,
      isRequesting: false,
      data: {
        ...state.data,
        requested: _links?.self?.href.includes('page=1')
          ? [...requested]
          : [...state.data.requested, ...requested],
        requestLink: _links,
        totalRequestedCount: action.data.total_items,
      },
    };
  }

  case GET_REQUESTED_FEEDBACK_LIST_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  case GET_IR_RANK_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };
  case GET_IR_RANK_SUCCESS: {
    const irRank = action.data.detail;

    return {
      ...state,
      isRequesting: false,
      data: {
        ...state.data,
        irRank,
      },
    };
  }
  case GET_IR_RANK_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  case GET_PRAISE_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };
  case GET_PRAISE_SUCCESS: {
    const feedbackPraises = action.data.detail;
    return {
      ...state,
      isRequesting: false,
      data: {
        ...state.data,
        feedbackPraises,
      },
    };
  }
  case GET_PRAISE_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  case CLEAR_MYFEEDBACK_SUCCESS:{
    const obj = {};

    if(action.status){
      obj[action.status] = [];
    }else{
      obj['pendingFeedback'] = [];
      obj['completedFeedback'] = [];
      obj['received'] = [];
      obj['requested'] = [];
    }

    return {
      ...state,
      data: {
        ...state.data,
        ...obj,
      }
    };
  }

  default:
    return state;
  }
}
