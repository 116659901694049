import initialState from './initialState';

import {
  PUBLICATIONS_REQUEST,
  PUBLICATIONS_SUCCESS,
  PUBLICATIONS_FAILURE,
  CREATE_PUBLICATION_REQUEST,
  CREATE_PUBLICATION_SUCCESS,
  CREATE_PUBLICATION_FAILURE,
  UPDATE_PUBLICATION_REQUEST,
  UPDATE_PUBLICATION_SUCCESS,
  UPDATE_PUBLICATION_FAILURE,
  DELETE_PUBLICATION_REQUEST,
  DELETE_PUBLICATION_SUCCESS,
  DELETE_PUBLICATION_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.publications, action) {
  switch (action.type) {
  case PUBLICATIONS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case PUBLICATIONS_SUCCESS:
    return Object.assign({}, state, {
      data: action.data._embedded.publication,
      isRequesting: false,
    });

  case PUBLICATIONS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case CREATE_PUBLICATION_REQUEST:
    return Object.assign({}, state, { isSubmitting: true });

  case CREATE_PUBLICATION_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data, Object.assign({}, action.data)],
      isSubmitting: false,
    });

  case CREATE_PUBLICATION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case UPDATE_PUBLICATION_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, publicationIndex: action.index },
    });

  case UPDATE_PUBLICATION_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, state.isUpdating.publicationIndex),
        Object.assign({}, action.data),
        ...state.data.slice(state.isUpdating.publicationIndex + 1),
      ],
      isUpdating: { status: false, publicationIndex: null },
    });

  case UPDATE_PUBLICATION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false, publicationIndex: null },
    });

  case DELETE_PUBLICATION_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: action.id },
    });

  case DELETE_PUBLICATION_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.filter(
          publication => publication.id !== state.isDeleting.id
        ),
      ],
      isDeleting: { status: false, id: null },
    });

  case DELETE_PUBLICATION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: { status: false, id: null },
    });

  default:
    return state;
  }
}
