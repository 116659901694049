import PropTypes from 'prop-types';
import React from 'react';
import LazyLoad from 'react-lazyload';
import Loading from '../common/Loading';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

const ImagePost = ({url, secondary}) => {
  return (
    <div style={{
      marginTop: 10,
      maxWidth: secondary ? '75%' : '100%'
    }}>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <LazyLoad 
          throttle={100} 
          height={200} 
          placeholder={
            <div 
              style={{
                width: '100%',
                height: 200,
                color: '#fff',
                backgroundColor: '#E0E0E0'
              }}
              className="loading-container">
              <Loading/>
            </div>
          }>
          <ReactCSSTransitionGroup
            transitionName="lazyImage"
            transitionAppear
            transitionAppearTimeout={500}
            transitionEnterTimeout={500}
            transitionLeaveTimeout={300}>
            <img 
              style={{
                maxWidth: '100%'
              }}
              src={url} 
              alt="an image"/>
          </ReactCSSTransitionGroup>
        </LazyLoad>
      </a>
    </div>
  );
};

ImagePost.propTypes = {
  url: PropTypes.string.isRequired,
  secondary: PropTypes.bool
};

export default ImagePost;
