import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';

import * as registrationActions from '../../redux/actions/registration';
import * as componentsActions from '../../redux/actions/components';

import PropTypes from 'prop-types';

import Validator from '../../validator';
import RecruiterListForm from '../../components/industry-mentor/RecruiterListForm';

const recruiter = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  jobTitle: '',
  companyName: '',
  country: '',
  state: '',
  city: ''
};

class RecruiterListModal extends Component {
  static propTypes = {
    modal: PropTypes.string.isRequired,
    actions: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      recruiter,
      isOpenRecruiterListModal: false
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.closeRecruiterListModal = this.closeRecruiterListModal.bind(this);
  }

  isValid(field = null) {
    const { recruiter, errors: prevErrors } = this.state;

    let validate = Validator.createValidator({
      'firstName': ['required'],
      'lastName': ['required'],
      'email': ['required', 'email'],
      'phoneNumber': ['phone'],
      'jobTitle': ['required'],
      'companyName': ['required'],
      'country': ['required'],
      'state': ['required'],
      'city': ['required']
    }, recruiter, field);

    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  onChange(event) {
    const { name, value } = event.target;
    let { recruiter } = this.state;

    recruiter = { ...recruiter, [name]: value };

    this.setState({ recruiter }, () => this.isValid(name));
  }

  closeRecruiterListModal() {
    this.props.actions.closeModal();
  }

  onSubmit(event) {
    event.preventDefault();

    const { recruiter: recruiterState } = this.state;

    if (! this.isValid()) return false;

    const { firstName, lastName, companyName: organization, ...recruiterProps } = recruiterState;
    const name = `${firstName.trim()} ${lastName.trim()}`;

    const data = { ...recruiterProps, name, organization };

    this.props.actions.recruiterRequest(data)
      .then(() => {
        this.setState({ recruiter });
        this.closeRecruiterListModal();
        toastr.success('Your recruiter request was successfully submitted!');
      });
  }

  render() {
    const { modal, isSubmitting } = this.props;
    const { recruiter, errors } = this.state;

    const isOpenRecruiterListModal = modal === 'recruiter-list-modal';

    return (
      <div>
        {isOpenRecruiterListModal &&
          <RecruiterListForm
            errors={errors}
            recruiter={recruiter}
            onChange={this.onChange}
            isSubmitting={isSubmitting}
            onSubmit={this.onSubmit}
            isOpenRecruiterListModal={isOpenRecruiterListModal}
            closeRecruiterListModal={this.closeRecruiterListModal} />}
      </div>
    );
  }
}
const mapStateToProps = ({ registration: { isSubmitting }, components: { modal } }) => {
  return {
    modal,
    isSubmitting
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, componentsActions, registrationActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterListModal);
