import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const ImagePreview = ({
  imagePreviewUrl,
  onCancel,
  closeIcon,
  secondary,
  styles,
  tertiary,
  primary
}) => {
  const imgPreviewStyles = classNames({
    'imgPreview': primary,
    'imgPreviewComment': secondary,
    'imgPreviewComment reply-form': tertiary
  });

  return(
    <div>
      {
        imagePreviewUrl &&
          <div className={imgPreviewStyles} style={styles}>
            <img src={imagePreviewUrl}/>

            <i
              className="fa fa-times-circle-o"
              aria-hidden="true"
              style={closeIcon}
              onClick={onCancel}/>
          </div>
      }
    </div>
  );
};

ImagePreview.propTypes = {
  imagePreviewUrl: PropTypes.string,
  onCancel: PropTypes.func,
  closeIcon: PropTypes.object,
  secondary: PropTypes.bool,
  styles: PropTypes.object,
  tertiary: PropTypes.bool,
  primary: PropTypes.bool
};

export default ImagePreview;
