import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { redirect } from '../../../redux/actions/router';
import { getEducatorGroups, getEducatorGroupsStatistics } from '../../../redux/actions/educators';

import { Row, Container } from 'reactstrap';

import Loading from '../../../components/common/Loading';
import GroupDropdown from './GroupDropdown';
import Dashboard from '../../../components/admin/educators/Dashboard';

class EducatorDashboard extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    actions:PropTypes.object.isRequired,
    isAdmin: PropTypes.bool,
    groupsCount: PropTypes.number.isRequired,
    displayAdvancedFilters: PropTypes.bool.isRequired,
    isInitialRequest: PropTypes.bool.isRequired,
    parentGroupsCount: PropTypes.number,
    selectedInstitutionId: PropTypes.string
  }

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    const {
      userId,
      actions,
      groupsCount
    } = this.props;

    if (groupsCount === 0) {
      actions.redirect('/educator/groups/manage');
    }

    actions.getEducatorGroups(true, '');
    actions.getEducatorGroupsStatistics(userId);
  }

  render() {
    const { groupsCount, isInitialRequest } = this.props;

    return (
      <div className="admin-dashboard">
        <div className="interior invite-users mt-3">
          <Container>
            <Row>
              <Container>
                {((groupsCount > 1) && (! isInitialRequest)) && <GroupDropdown/>}
              </Container>
            </Row>

            {(isInitialRequest) && <Loading/>}

            {(! isInitialRequest) && <Dashboard />}
          </Container>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    educators: {
      isInitialRequest,
      groups: groupsList,
      isRequesting: isRequestingGroups
    },
    users: { displayAdvancedFilters },
    institutions: {
      selectedInstitution: {
        isAdmin,
        groupsCount: parentGroupsCount,
        id: selectedInstitutionId
      }
    },
    auth: { data: { currentUser: { id: userId } } }
  } = state;

  const allGroups = groupsList.filter(group => group.id !== 'all-groups');
  const { length: groupsCount } = allGroups;

  return {
    userId,
    isAdmin,
    groupsCount,
    isRequestingGroups,
    isInitialRequest,
    groupsList,
    parentGroupsCount,
    selectedInstitutionId,
    displayAdvancedFilters
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    redirect,
    getEducatorGroups,
    getEducatorGroupsStatistics
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(EducatorDashboard);
