import { createSelector } from 'reselect';

const selectJobMatchSettings = state => state.jobMatchSettings;

export const selectJobMatchSettingsObject = createSelector(
  [selectJobMatchSettings],
  jobMatchSettings => jobMatchSettings.data
);

export const selectIsRequestingJobMatchSettings = createSelector(
  [selectJobMatchSettings],
  jobMatchSettings => jobMatchSettings.isRequesting
);

export const selectIsSubmittingJobMatchSettings = createSelector(
  [selectJobMatchSettings],
  jobMatchSettings => jobMatchSettings.isSubmitting
);

export const selectIsUpdatingJobMatchSettings = createSelector(
  [selectJobMatchSettings],
  jobMatchSettings => jobMatchSettings.isUpdating
);
