import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toastr } from 'react-redux-toastr';
import { parseJSON, stringifyJSON } from '../../../utils';

import * as profileActions from '../../../redux/actions/profile';
import * as componentsActions from '../../../redux/actions/components';

import ProfileSection from './ProfileSection';
import Summary from './Summary';
import Situation from './Situation';
import InitialSituation from './InitialSituation';
import AddSections from '../../../components/profile/sections/AddSections';
import SkillBadgeSectionContainer from './SkillBadgeSectionContainer';
import SortSectionsModal from './SortSectionsModal';
import MyCareerInterests from './MyCareerInterests';

class ProfileSectionList extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    profileOrder: PropTypes.array.isRequired,
    isPublicProfile: PropTypes.bool.isRequired,
    showVisibilitySelect: PropTypes.bool,
    modal: PropTypes.string,
    onChangeVisibility: PropTypes.func
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      isAddOpen: false,
      editingSection: [],
      showHiddenSkillBadges: true
    };

    this.onAddSection = this.onAddSection.bind(this);
    this.onToggleAdd = this.onToggleAdd.bind(this);
    this.sortSections = this.sortSections.bind(this);
    this.onToggleSectionEdit = this.onToggleSectionEdit.bind(this);
    this.onConfirmDeleteSection = this.onConfirmDeleteSection.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.profileOrder.length < this.props.profileOrder.length){
      toastr.success('Section deleted.');
    }
  }

  sortSections(){
    this.props.actions.openModal('sort-webpage-sections-modal');
  }

  onAddSection(sectionName){
    return () => {
      const { profile, profileOrder } = this.props;
      const nextId = profileOrder.reduce((acc, curr)=> acc > curr.id ? acc : curr.id, 0) + 1;

      const nextOrder = {
        id: nextId,
        name: sectionName
      };

      const profileData = {
        profileorder: stringifyJSON([...profileOrder, nextOrder])
      };

      this.props.actions.updateProfile(profile.id, profileData);
    };
  }

  onConfirmDeleteSection(sectionName){
    const filteredProfileOrder = this.props.profileOrder.filter(ord => ord.name !== sectionName);
    const data = {
      profileorder : stringifyJSON(filteredProfileOrder)
    };

    return this.props.actions.updateProfile(this.props.profile.id, data);
  }

  onToggleAdd(){
    const { isAddOpen } = this.state;

    this.setState({
      isAddOpen: !isAddOpen
    });
  }

  onToggleSectionEdit(type){
    const { editingSection } = this.state;
    const index = editingSection.indexOf(type);
    const updatededitingSection = index !== -1 ?
      [...editingSection.slice(0, index), ...editingSection.slice(index+1)] :
      [ ...editingSection, type];

    this.setState({ editingSection: updatededitingSection });
  }

  setShowHiddenSkillBadges = (event) => {
    const { checked } = event.target;
    this.setState({
      showHiddenSkillBadges: checked
    });
  }

  render() {
    const { editingSection, isAddOpen } = this.state;
    const {
      isPublicProfile,
      showVisibilitySelect,
      onChangeVisibility,
      profile,
      modal,
      profileOrder,
    } = this.props;
    const showInitialSituation = profile.employmentstatus === null || profile.employmenttype === null;
    const showSortModal = modal && modal === 'sort-webpage-sections-modal';

    return (
      <div className="row">
        {!isPublicProfile &&
          <div className="col-lg-4">
            <div className="profile-item">
              {showInitialSituation && <InitialSituation/>}

              {!showInitialSituation &&
                <Situation
                  onToggleSectionEdit={this.onToggleSectionEdit}
                  isEditingSections={this.isEditingSections}
                  isPublicProfile={isPublicProfile}/>}
            </div>

            <MyCareerInterests/>
          </div>}

        <div
          className={
            classNames(
              'mb5 col-lg-8',
              {
                'col-lg-12' : isPublicProfile,
                'col-lg-8' : !isPublicProfile
              }
            )}>
          {
            !isPublicProfile &&
              <AddSections
                isOpen={isAddOpen}
                profileOrder={profileOrder}
                onAddSection={this.onAddSection}
                onToggleAdd={this.onToggleAdd}/>
          }
          {
            !isPublicProfile &&
              <div className="mb-4 border-bottom-solid pb-3">
                <button
                  onClick={this.sortSections}
                  className="btn btn-primary-outline"
                  style={{width: '100%'}}>
                  Sort Sections
                </button>
              </div>
          }
          <div className="profile-item summary">
            <Summary
              profile={profile}
              isPublicProfile={isPublicProfile}
              onChangeVisibility={onChangeVisibility}
              onToggleSectionEdit={this.onToggleSectionEdit}
              isEditingSections={this.isEditingSections}
              showVisibilitySelect={showVisibilitySelect}/>

          </div>
          <SkillBadgeSectionContainer
            isPublicProfile={isPublicProfile}
            profile={profile}
            setShowHiddenSkillBadges={this.setShowHiddenSkillBadges}
            showHiddenSkillBadges={this.state.showHiddenSkillBadges}/>

          {profileOrder.map((section, index) =>
            (<ProfileSection
              key={index}
              index={index}
              section={section}
              profile={profile}
              showVisibilitySelect={showVisibilitySelect}
              onChangeVisibility={onChangeVisibility}
              editingSection={editingSection}
              onConfirmDeleteSection={this.onConfirmDeleteSection}
              onToggleSectionEdit={this.onToggleSectionEdit}
              isPublicProfile={isPublicProfile}/>))}
        </div>
        {
          showSortModal &&
            <SortSectionsModal
              profileOrder={profileOrder}
              isOpen={showSortModal}/>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const defaultOrder = [
    { id: 1, name: 'Skills' },
    { id: 2, name: 'Education' },
    { id: 3, name: 'Experience' },
  ];

  const profileOrder = typeof state.profile.data.profileorder === 'undefined' ?
    defaultOrder :
    state.profile.data.profileorder.length === 0 ?
      defaultOrder :
      parseJSON(state.profile.data.profileorder);

  return {
    profileOrder,
    modal: state.components.modal,
    profile: state.profile.data,
    isSubmitting: state.profile.isSubmitting
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, componentsActions, profileActions);
  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSectionList);
