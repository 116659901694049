import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { Outlet } from 'react-router-dom';

import { Helmet } from 'react-helmet';

class ToolboxWrapper extends Component {
  static propTypes = {
    profile: PropTypes.object.isRequired
  }

  render() {
    return (
      <div>
        <Helmet title="Toolbox"/>
        <div className="global-competencies">
          <div className="container">
            <div className="row">
              <Outlet/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ profile: { data: profile } }) => ({ profile });

export default connect(mapStateToProps)(ToolboxWrapper);
