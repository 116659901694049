import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as routerActions from '../../../redux/actions/router';
import * as profileActions from '../../../redux/actions/profile';

import { withHooks } from '../../../utils/withHooks';
import { callApi } from '../../../middlewares/api';

import Portfolio from '../../portfolio/Portfolio';
import SkillbadgesSideMenu from '../../../components/skill-badges/SkillbadgesSideMenu';

class PortfolioWrapper extends Component {
  static propTypes = {
    params: PropTypes.object,
    location: PropTypes.object,
    profile: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    currentUserId: PropTypes.string.isRequired,
    isAuthenticated: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      requestorDetails: false,
    };
  }

  componentWillMount() {
    const { actions, currentUserId, isAuthenticated } = this.props;

    actions.profileRequest(currentUserId, isAuthenticated);
  }

  componentDidMount() {
    this.getRequestorDetails();
  }

  getRequestorDetails() {
    const userId = this.props?.params?.userId;

    if (userId) {
      callApi('', 'get', `user/user/${userId}`, true).then((response) => {
        this.setState({requestorDetails: response.data});
      });
    }
  }

  render() {
    let { profile } = this.props;

    if ( this.state.requestorDetails ) {
      profile = {...profile, requestorDetails: this.state.requestorDetails};
    }

    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="content badges">
                <div className="row">
                  <div className="col-lg-12">
                    <SkillbadgesSideMenu profile={profile} />
                    {Object.keys(profile).length > 0 && <Portfolio profile={profile}/>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    profile: {
      isRequesting,
      data: profile,
    },
    auth: {
      data: {
        currentUser: { id: currentUserId }
      },
      isAuthenticated
    }
  } = state;

  return {
    profile,
    isRequesting,
    currentUserId,
    isAuthenticated
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, routerActions, profileActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(PortfolioWrapper));
