import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import { isEmpty } from 'lodash';

import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import * as followingActions from '../../../redux/actions/following';
import * as messagesActions from '../../../redux/actions/messages';
import * as componentActions from '../../../redux/actions/components';

import MessageModal from '../../../components/profile/connects/MessageModal';
import ContactInfo from '../../../components/profile/connects/ContactInfo';

class VisitionUserActions extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    owner: PropTypes.object,
    profile:PropTypes.object,
    isAuthenticated: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.object,
    isSubmittingMessage: PropTypes.bool,
    message: PropTypes.object,
    following: PropTypes.object,
    modal: PropTypes.string,
    currentUserProfileData: PropTypes.object.isRequired
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      error: {},
      showMessageModal: false,
      showContactInfoModal:false,
      showContactInfoUpdateModal:false,
      message: Object.assign({}, props.message),
    };

    this.onFollow = this.onFollow.bind(this);
    this.onToggleMessagingModal = this.onToggleMessagingModal.bind(this);
    this.onToggleContactInfoModal = this.onToggleContactInfoModal.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.isValid = this.isValid.bind(this);
  }

  componentDidMount() {

    const { owner, isAuthenticated } = this.props;

    this.props.actions.followingRequest(isAuthenticated, owner.id);
  }

  onFollow(){
    const { actions, owner, following } = this.props;

    if(following.isRequesting)
      return;

    if(owner.isUserFollower.status){
      if(owner.isUserFollower.connectId !== '')
        return actions.deleteFollowing(owner.isUserFollower.connectId);

      return;

    }

    const data = {
      user: owner.id
    };
    actions.saveFollowing(data);
  }

  onToggleMessagingModal() {
    const { owner } = this.props;

    if(owner?.privateMessagingDisabled){
      toastr.info('', 'The organizational admin has disabled private messaging to this user');
      return;
    }

    this.setState({
      showMessageModal: !this.state.showMessageModal,
    });
  }

  onToggleContactInfoModal() {
    const { modal, actions } = this.props;

    if (modal === 'contact-info') {
      actions.closeModal();
      return;
    }

    actions.openModal('contact-info');
  }

  onMessageChange(event) {
    const field = event.target.name;
    const { message } = this.state;

    message[field] = event.target.value;

    this.setState({ message }, () => this.isValid(message));
  }

  isValid(value) {
    const { error } = this.state;

    this.setState({ error: {} });

    if (!value.body.length > 0) {
      error['body'] = 'Message cannot be empty';
      this.setState({ error });
    }
    if (!value.subject.length > 0) {
      error['subject'] = 'Subject cannot be empty';
      this.setState({ error });
    }
    return (value.subject.length > 0) && (value.body.length > 0);
  }

  onMessageSubmit() {
    const { message } = this.state;
    const { owner, actions } = this.props;

    if (!this.isValid(message)) return;

    message.recipients = [owner.id];
    actions.sendMessage(message).
      then(() => this.handleResponse());
  }

  handleResponse() {

    const message = { recipients: [], subject: '', body: '' };

    this.setState({
      message,
    }, () => this.onToggleMessagingModal());
  }

  render() {
    const { showMessageModal, error, message } = this.state;
    const {
      modal,
      isSubmitting,
      owner,
      isAuthenticated,
      isSubmittingMessage,
      following,
      currentUserProfileData
    } = this.props;

    const { isUserFollower } = owner;
    const followText = isUserFollower.status ? 'Unfollow' : 'Follow';
    const [,path,] = location.pathname.split('/');
    const isInPortfolio = path === 'portfolio';

    const showContactInfo = modal === 'contact-info';

    const allowedAdmins = ['institution-admin', 'group-admin'];
    const isSuperAdmin = currentUserProfileData.role === 'super-admin';

    let isAffiliated = false;

    if (!isEmpty(currentUserProfileData)) {
      isAffiliated = isAuthenticated &&
        currentUserProfileData
          .affiliatedOrganizations
          .some(currentUserOrg =>
            owner.affiliatedOrganizations.some(ownerOrg => ownerOrg.id === currentUserOrg.id)
          );
    }

    const isAllowedAdmins = allowedAdmins.includes(currentUserProfileData.role) && isAffiliated;
    const displayContactInfoButton = isAuthenticated && (isAllowedAdmins || isSuperAdmin);

    return (
      <div className="container" style={{ position: 'relative '}}>
        <div className="menu-profile-settings">
          {displayContactInfoButton &&
            <a
              onClick={this.onToggleContactInfoModal}
              className="clickable message mb-2">
              Contact Info
            </a>}

          {isAuthenticated && owner.role !== 'bot' && owner.role !== 'super-admin' &&
            <a
              onClick={this.onFollow}
              className="follow clickable mb-2">
              {isSubmitting.status || following.isDeleting.status ?
                `${followText}ing...` :
                `${isUserFollower ? '':'+'} ${followText}`}
            </a>}

          {isAuthenticated && owner.role !== 'bot' && owner.role !== 'super-admin' &&
            <a
              onClick={this.onToggleMessagingModal}
              className="clickable message mb-2">
              Message Me
            </a>}

          {owner.isPrivacyVisible &&
            !isInPortfolio &&
            owner.role !== 'bot' &&
            owner.role !== 'super-admin' &&
              <Link to={`/portfolio/${owner.id}`}className="view-portfolio mb-2">
                View Portfolio
              </Link>}

          {showMessageModal &&
            <MessageModal
              toggleModal={this.onToggleMessagingModal}
              modalOpen={showMessageModal}
              error={error}
              isSubmitting={isSubmittingMessage}
              message={message}
              onMessageChange={this.onMessageChange}
              onMessageSubmit={this.onMessageSubmit} />}

          {showContactInfo && <ContactInfo owner={owner} />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const message = { recipients: [], subject: '', body: '' };
  const { components: { modal } } = state;

  return {
    modal,
    message,
    following: state.following,
    isSubmitting: state.following.isSubmitting,
    isSubmittingMessage: state.messages.isSubmitting,
    isAuthenticated: state.auth.isAuthenticated,
    currentUserProfileData: state.profile.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, followingActions, messagesActions, componentActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VisitionUserActions);
