import unionBy from 'lodash/unionBy';
import sortBy from 'lodash/sortBy';

import initialState from './initialState';
import {
  RESET_DASHBOARD_WALL_COMMENTS,
  LOAD_INITIAL_WALL_COMMENTS,
  LOAD_WALL_COMMENT_REPLIES,
  WALL_POST_COMMENTS_REQUEST,
  WALL_POST_COMMENTS_SUCCESS,
  WALL_POST_COMMENTS_FAILURE,
  CREATE_WALL_POST_COMMENT_REQUEST,
  CREATE_WALL_POST_COMMENT_SUCCESS,
  CREATE_WALL_POST_COMMENT_FAILURE,
  CREATE_WALL_POST_COMMENT_LIKE_REQUEST,
  CREATE_WALL_POST_COMMENT_LIKE_SUCCESS,
  CREATE_WALL_POST_COMMENT_LIKE_FAILURE,
  UPDATE_WALL_POST_COMMENT_REQUEST,
  UPDATE_WALL_POST_COMMENT_SUCCESS,
  UPDATE_WALL_POST_COMMENT_FAILURE,
  DELETE_WALL_POST_COMMENT_REQUEST,
  DELETE_WALL_POST_COMMENT_SUCCESS,
  DELETE_WALL_POST_COMMENT_FAILURE,
  CREATE_WALL_REPLY_REQUEST,
  CREATE_WALL_REPLY_SUCCESS,
  CREATE_WALL_REPLY_FAILURE,
  CREATE_WALL_POST_REPLY_LIKE_REQUEST,
  CREATE_WALL_POST_REPLY_LIKE_SUCCESS,
  CREATE_WALL_POST_REPLY_LIKE_FAILURE,
  DELETE_WALL_POST_REPLY_REQUEST,
  DELETE_WALL_POST_REPLY_SUCCESS,
  DELETE_WALL_POST_REPLY_FAILURE,
  UPDATE_WALL_POST_REPLY_REQUEST,
  UPDATE_WALL_POST_REPLY_SUCCESS,
  UPDATE_WALL_POST_REPLY_FAILURE,
  UNLIKE_WALL_POST_COMMENT_REQUEST,
  UNLIKE_WALL_POST_COMMENT_SUCCESS,
  UNLIKE_WALL_POST_COMMENT_FAILURE,
  UNLIKE_WALL_POST_REPLY_REQUEST,
  UNLIKE_WALL_POST_REPLY_SUCCESS,
  UNLIKE_WALL_POST_REPLY_FAILURE,
} from '../../constants';

const createComment = (state, data) => {
  const { wallPostId: id } = state.isSubmitting;

  let creatorObject = {
    [id]: [data],
  };

  if (state.loadedComments[id])
    creatorObject = {
      [id]: [...state.loadedComments[id], Object.assign({}, data)],
    };

  return Object.assign({}, state, {
    loadedComments: Object.assign({}, state.loadedComments, creatorObject),
    isSubmitting: { status: false, wallPostId: null },
  });
};

export default function reducer(state = initialState.wallPostComments, action) {
  switch (action.type) {
  case RESET_DASHBOARD_WALL_COMMENTS:
    return Object.assign({}, initialState.wallPostComments);

  case LOAD_INITIAL_WALL_COMMENTS:
    return Object.assign({}, state, {
      loadedComments: Object.assign({}, state.loadedComments, {
        [action.data.wallPostId]: action.data.comments,
      }),
    });

  case LOAD_WALL_COMMENT_REPLIES:
    return Object.assign({}, state, {
      replyData: state.replyData.concat(action.replies),
    });

  case WALL_POST_COMMENTS_REQUEST:
    return Object.assign({}, state, {
      isRequesting: { status: true, wallPostId: action.id },
    });

  case WALL_POST_COMMENTS_SUCCESS: {
    const commentData = action.data._embedded.wall_comment;
    const id = state.isRequesting.wallPostId;

    return Object.assign({}, state, {
      loadedComments: Object.assign({}, state.loadedComments, {
        [id]: sortBy(unionBy(state.loadedComments[id], commentData, 'id'), [
          item => item.posted.date,
        ]),
      }),
      isRequesting: { status: false, discussionId: null },
    });
  }

  case WALL_POST_COMMENTS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: { status: false, wallPostId: null },
    });

  case CREATE_WALL_POST_COMMENT_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: { status: true, wallPostId: action.id },
    });

  case CREATE_WALL_POST_COMMENT_SUCCESS:
    return createComment(state, action.data);

  case CREATE_WALL_POST_COMMENT_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: { status: true, wallPostId: null },
    });

  case CREATE_WALL_POST_COMMENT_LIKE_REQUEST:
  case UNLIKE_WALL_POST_COMMENT_REQUEST:
    return Object.assign({}, state, {
      isSubmittingLike: {
        status: true,
        userId: action.userId,
        wallPostCommentIndex: action.id,
      },
    });

  case CREATE_WALL_POST_COMMENT_LIKE_SUCCESS: {
    const { wallPostId, index } = state.isSubmittingLike.wallPostCommentIndex;
    const arrayToUpdate = state.loadedComments[wallPostId];

    return Object.assign({}, state, {
      loadedComments: Object.assign({}, state.loadedComments, {
        [wallPostId]: [
          ...arrayToUpdate.slice(0, index),
          Object.assign({}, arrayToUpdate[index], {
            likes: arrayToUpdate[index].likes + 1,
            liked: true,
            likesList: [
              ...arrayToUpdate[index].likesList,
              Object.assign({}, action.data),
            ],
          }),
          ...arrayToUpdate.slice(index + 1),
        ],
      }),
      isSubmittingLike: {
        status: false,
        userId: null,
        wallPostCommentIndex: null,
      },
    });
  }

  case UNLIKE_WALL_POST_COMMENT_SUCCESS: {
    const { wallPostId, index } = state.isSubmittingLike.wallPostCommentIndex;
    const arrayToUpdate = state.loadedComments[wallPostId];

    return Object.assign({}, state, {
      loadedComments: Object.assign({}, state.loadedComments, {
        [wallPostId]: [
          ...arrayToUpdate.slice(0, index),
          Object.assign({}, arrayToUpdate[index], {
            likes: arrayToUpdate[index].likes - 1,
            liked: false,
            likesList: [
              ...arrayToUpdate[index].likesList.filter(
                like => like.id !== state.isSubmittingLike
              ),
            ],
          }),
          ...arrayToUpdate.slice(index + 1),
        ],
      }),
      isSubmittingLike: {
        status: false,
        userId: null,
        wallPostCommentIndex: null,
      },
    });
  }

  case CREATE_WALL_POST_COMMENT_LIKE_FAILURE:
  case UNLIKE_WALL_POST_COMMENT_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmittingLike: { status: false, wallPostCommentIndex: null },
    });

  case UPDATE_WALL_POST_COMMENT_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, wallPostId: action.id },
    });

  case UPDATE_WALL_POST_COMMENT_SUCCESS: {
    const id = state.isUpdating.wallPostId;

    const loadedCommentIndex = state.loadedComments[id].findIndex(
      comment => comment.id === action.data.id
    );
    return Object.assign({}, state, {
      loadedComments: Object.assign({}, state.loadedComments, {
        [id]: [
          ...state.loadedComments[id].slice(0, loadedCommentIndex),
          Object.assign(
            {},
            state.loadedComments[id][loadedCommentIndex],
            action.data
          ),
          ...state.loadedComments[id].slice(loadedCommentIndex + 1),
        ],
      }),
      isUpdating: { status: false, wallPostId: null },
    });
  }

  case UPDATE_WALL_POST_COMMENT_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false, commentId: null },
    });

  case DELETE_WALL_POST_COMMENT_REQUEST:
    return Object.assign({}, state, {
      isDeleting: {
        status: true,
        id: action.id.commentId,
        wallPostId: action.id.wallPostId,
      },
    });

  case DELETE_WALL_POST_COMMENT_SUCCESS:
    return Object.assign({}, state, {
      loadedComments: Object.assign({}, state.loadedComments, {
        [state.isDeleting.wallPostId]: state.loadedComments[
          state.isDeleting.wallPostId
        ].filter(comment => comment.id !== state.isDeleting.id),
      }),
      isDeleting: { status: false, id: null, wallPostId: null },
    });

  case DELETE_WALL_POST_COMMENT_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: { status: false, id: null, wallPostId: null },
    });

  case CREATE_WALL_REPLY_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: { status: true, wallPostId: null },
    });

  case CREATE_WALL_REPLY_SUCCESS:
    return Object.assign({}, state, {
      replyData: [...state.replyData, Object.assign({}, action.data)],
      isSubmitting: { status: false, wallPostId: null },
    });

  case CREATE_WALL_REPLY_FAILURE:
    return Object.assign({}, state, {
      isSubmitting: { status: false, wallPostId: null },
    });

  case UPDATE_WALL_POST_REPLY_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, wallPostId: action.id },
    });

  case UPDATE_WALL_POST_REPLY_SUCCESS: {
    const index = state.replyData.findIndex(
      item => item.id === state.isUpdating.wallPostId
    );

    return Object.assign({}, state, {
      replyData: [
        ...state.replyData.slice(0, index),
        Object.assign({}, state.replyData[index], action.data),
        ...state.replyData.slice(index + 1),
      ],
      isUpdating: { status: false, wallPostId: null },
    });
  }

  case UPDATE_WALL_POST_REPLY_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false, wallPostId: null },
    });

  case DELETE_WALL_POST_REPLY_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: action.id },
    });

  case DELETE_WALL_POST_REPLY_SUCCESS:
    return Object.assign({}, state, {
      replyData: [
        ...state.replyData.filter(
          reply => reply.id !== state.isDeleting.id
        ),
      ],
      isDeleting: { status: false, id: null },
    });

  case DELETE_WALL_POST_REPLY_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: { status: false, id: null },
    });

  case CREATE_WALL_POST_REPLY_LIKE_REQUEST:
  case UNLIKE_WALL_POST_REPLY_REQUEST:
    return Object.assign({}, state, {
      isSubmittingLike: {
        status: true,
        wallPostCommentIndex: action.id,
        userId: action.userId,
      },
    });

  case CREATE_WALL_POST_REPLY_LIKE_SUCCESS: {
    const index = state.replyData.findIndex(
      reply => reply.id === state.isSubmittingLike.wallPostCommentIndex
    );

    return Object.assign({}, state, {
      replyData: [
        ...state.replyData.slice(0, index),
        Object.assign({}, state.replyData[index], {
          likes: state.replyData[index].likes + 1,
          liked: true,
          likesList: [
            ...state.replyData[index].likesList,
            Object.assign({}, action.data),
          ],
        }),
        ...state.replyData.slice(index + 1),
      ],
      isSubmittingLike: { status: false, wallPostCommentIndex: null },
    });
  }

  case UNLIKE_WALL_POST_REPLY_SUCCESS: {
    const index = state.replyData.findIndex(
      reply => reply.id === state.isSubmittingLike.wallPostCommentIndex
    );

    return Object.assign({}, state, {
      replyData: [
        ...state.replyData.slice(0, index),
        Object.assign({}, state.replyData[index], {
          likes: state.replyData[index].likes - 1,
          liked: false,
          likesList: [
            ...state.replyData[index].likesList.filter(
              like => like.id !== state.isSubmittingLike.userId
            ),
          ],
        }),
        ...state.replyData.slice(index + 1),
      ],
      isSubmittingLike: { status: false, wallPostCommentIndex: null },
    });
  }

  case CREATE_WALL_POST_REPLY_LIKE_FAILURE:
  case UNLIKE_WALL_POST_REPLY_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmittingLike: { status: false, wallPostCommentIndex: null },
    });
  default:
    return state;
  }
}
