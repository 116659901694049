import React from 'react';

import PropTypes from 'prop-types';

import SavedJobCard from './SavedJobCard';
import Loading from '../common/Loading';
import DeleteJobModal from './DeleteJobModal';

const SavedJobsList = ({
  jobs,
  deleteJob,
  isDeleting,
  isGuestUser,
  isRequesting,
  callingAPI,
  onSelectJobStatus,
  toggleJobStatusDropUp,
  activeJobStatusDropup,
  confirmDeleteJobModalIsOpen,
  onConfirmDeleteJob,
  onCancelDeleteJob
}) => {
  if (callingAPI || isRequesting) {
    return (
      <div style={{padding: 20}}>
        <Loading/>
      </div>
    );
  }

  if ((!callingAPI || !isRequesting) && jobs.length === 0) {
    return (
      <div className="alert alert-info" role="alert">
        No jobs to display.
      </div>
    );
  }

  return(
    <div className="row job-saved-tiles">
      {jobs.map((job, index)=>
        (<SavedJobCard
          key={index}
          data={job}
          deleteJob={deleteJob}
          isDeleting={isDeleting}
          isGuestUser={isGuestUser}
          onSelectJobStatus={onSelectJobStatus}
          onToggle={toggleJobStatusDropUp(index)}
          isOpen={activeJobStatusDropup === index}/>))}

      {confirmDeleteJobModalIsOpen &&
        <DeleteJobModal
          onCancel={onCancelDeleteJob}
          onConfirm={onConfirmDeleteJob}
          isDeleting={isDeleting}
          isOpen={confirmDeleteJobModalIsOpen}/>}
    </div>
  );
};

SavedJobsList.propTypes = {
  isDeleting: PropTypes.object,
  isGuestUser: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  jobs: PropTypes.array.isRequired,
  deleteJob: PropTypes.func.isRequired,
  callingAPI: PropTypes.bool,
  activeJobStatusDropup: PropTypes.number,
  toggleJobStatusDropUp: PropTypes.func.isRequired,
  onSelectJobStatus: PropTypes.func.isRequired,
  confirmDeleteJobModalIsOpen: PropTypes.bool.isRequired,
  onConfirmDeleteJob: PropTypes.func.isRequired,
  onCancelDeleteJob: PropTypes.func.isRequired
};
export default SavedJobsList;
