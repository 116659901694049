/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import moment from 'moment';
import _isUndefined from 'lodash/isUndefined';
import {
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label
} from 'reactstrap';
import { SimpleToolTipWrapper } from '../common/ToolTipWrapper';

import { deleteSkill } from '../../redux/actions/skills';
import { updateSkillsOrder } from '../../redux/actions/profile';
import { getRatings, getEducators, getIndustryReps } from '../../redux/actions/myskills';
import { updateMyCareerPreppedStatus } from '../../redux/actions/my-careerprepped-status';

import { prefixWithZero } from '../../utils';
import UnitBadgeEvidence from '../../containers/skill-badges/UnitBadgeEvidence';
import UnitBadgeAbilities from './UnitBadgeAbilities';
import UnitBadgeSkills from '../../containers/skill-badges/UnitBadgeSkills';
import UnitBadgeStatement from '../../containers/skill-badges/UnitBadgeStatement';
import UnitBadgeJustifications from '../../containers/skill-badges/UnitBadgeJustifications';
import Testimonials from '../../containers/skill-badges/Testimonials';
import RequestFeedbackModal from '../../containers/feedback/RequestFeedbackModal';
import BadgeRatings from './BadgeRatings';
import AllFeedbackReceived from '../myskills/AllFeedbackReceived';
import LevelUpBadgeModal from '../myskills/LevelUpBadgeModal';
import RequestEducators from '../myskills/RequestEducators';
import RequestPeers from '../myskills/RequestPeers';
import IRReqSubmitted from '../myskills/IRReqSubmitted';
import RequestIRs from '../myskills/RequestIRs';
import PortfolioFileModal from '../myskills/PortfolioFileUploadModal';
import ProfileModal from '../../components/profile/modal/ProfileModal';

const endorsedLogo = require('../../assets/images/overlay_endorsed_cropped.png');

const BadgeContent = ({
  user,
  unit,
  issued,
  assertionBadge,
  acknowledgements,
  files,
  endorsements,
  onClickEndorse,
  currentUser,
  onShareClick,
  onViewPortfolio,
  statements,
  skillBadges,
  skill_id,
  skillData,
  onLinkedSkill,
  showLinkPortfolioModal,
  skillEvidenceView = false,
  showReviewModal,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const issuedOn = moment(issued?.issuedAt.date).format('MMMM DD, YYYY');
  const isBadgeOwner = currentUser.id === user?.id;
  const isOwner = currentUser?.id === user?.id;
  const isBadge = !!issued?.id;

  const profile = useSelector(state => state.profile.data);
  const isDeleting = useSelector(state => state.myskills.isDeleting);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  const endorsementsList = !_isUndefined(endorsements)
    ? endorsements?.filter(endorsement => endorsement.name.length !== 0)
    : [];

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [requestFeedback, setRequestFeedback] = useState(false);
  const [allFeedbackReceived, setAllFeedbackReceived] = useState(false);
  const [requestModal, setRequestModal] = useState(false);
  const [fileUploaded, setFileUploadedModal] = useState(false);
  const [requestEducators, setRequestEducators] = useState(false);
  const [requestPeers, setRequestPeers] = useState(false);
  const [requestIRs, setRequestIRs] = useState(false);
  const [iRRequestSubmiited, setIRRequestSubmiited] = useState(false);
  const [toggleDeleteModal, setToggleDeleteModal] = useState(false);

  const ratingsArr = useSelector(({ myskills }) => (myskills.data.ratings));

  const educatorsArr = useSelector(({ myskills }) => {
    if (isAuthenticated) {
      return myskills.data.educators;
    } else {
      return [];
    }
  });

  const industryReps = useSelector(({ myskills }) => {
    if (isAuthenticated) {
      return myskills.data.industryReps;
    } else {
      return [];
    }
  });

  const [ratings, setRatings] = useState(false);
  const isAdmin = ['institution-admin', 'super-admin'].indexOf(profile.role) !== -1;

  function toggleRequestFeedbackModal() {
    setRequestFeedback(!requestFeedback);
  }

  function toggleRequestModal() {
    if ( isBadge ) {
      toggleModal('requestModal');
    } else if ( !isBadge && files.length > 0 ) {
      toggleModal('requestModal');
    } else {
      toggleModal('fileUploaded');
    }
  }

  const times = (files || [])
    .map(x => moment(x.modified.date || x.uploaded.date))
    .sort((x, y) => y - x);

  const endorse = endorsementsList
    .map(x => moment(x.endorsement_date.date))
    .sort((x, y) => y - x);

  const data = {
    badge_id: assertionBadge?.id,
    skill_id,
    createdAt: issued?.issuedAt.date,
    filesCount: files ? files.length : 0,
    imageURL: isBadge ? assertionBadge?.image : skillData?.url,
    issuedID: issued?.id,
    lastEndorsementGivenOn: endorse?.length > 0 ? endorse[0].format('MM/DD/YYYY') : 'N/A',
    lastPortfolioUploadedOn: times?.length > 0 ? times[0].format('MM/DD/YYYY') : 'N/A',
    name: isBadge ? assertionBadge?.name : skillData?.name,
    user,
    visibility: issued?.visibility,
    ...ratings,
  };

  useEffect(() => {
    if (ratingsArr && ratingsArr.length > 0) {
      const ratingTmp = ratingsArr?.find(x => issued?.id ? x?.badgeId === issued?.id : x?.skillId === skill_id,
      );

      setRatings(ratingTmp);
    }
  }, [issued?.id, skill_id, ratingsArr]);

  function toggleDropdown() {
    setDropdownOpen(!dropdownOpen);
  }

  function seeAllFeedback() {
    setAllFeedbackReceived(true);
  }

  function closeAllFeedback() {
    setAllFeedbackReceived(false);
  }

  function toggleModal(modal) {
    switch (modal) {
    case 'requestModal':
      setRequestModal(x => !x);
      break;
    case 'fileUploaded':
      setFileUploadedModal(x => !x);
      break;
    case 'requestEducators':
      setRequestEducators(x => !x);
      break;
    case 'requestPeers':
      setRequestPeers(x => !x);
      break;
    case 'requestIRs':
      setRequestIRs(x => !x);
      break;
    case 'iRRequestSubmiited':
      setIRRequestSubmiited(x => !x);
      break;
    }
  }

  function proceed(modal) {
    setRequestModal(false);
    setFileUploadedModal(false);
    setRequestEducators(false);
    setRequestPeers(false);
    setRequestIRs(false);
    setIRRequestSubmiited(false);

    switch (modal) {
    case 'requestModal':
      setRequestModal(x => !x);
      break;
    case 'fileUploaded':
      setFileUploadedModal(x => !x);
      break;
    case 'requestEducators':
      setRequestEducators(x => !x);
      break;
    case 'requestPeers':
      setRequestPeers(x => !x);
      break;
    case 'requestIRs':
      setRequestIRs(x => !x);
      break;
    case 'iRRequestSubmiited':
      setIRRequestSubmiited(x => !x);
      break;
    }
  }

  function openFeedbackProcess() {
    window.location.href = (skill_id) ? `/admingivefeedback/skill/${skill_id}` : `/admingivefeedback/badge/${issued?.id}`;
  }

  function onToggleDeleteModal() {
    setToggleDeleteModal(toggleDeleteModal => !toggleDeleteModal);
  }

  async function onDelete() {
    const skill = {
      skillsOrder: profile.skillsOrder.split(',').filter(skillId => skillId !== skill_id).join()
    };

    await dispatch(deleteSkill(skill_id));

    setToggleDeleteModal(toggleDeleteModal => !toggleDeleteModal);
    dispatch(updateSkillsOrder(skill, currentUser.id));
    dispatch(updateMyCareerPreppedStatus());

    navigate('/myskills#otherSkills');
  }

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getEducators());
      dispatch(getIndustryReps());
    }

    dispatch(getRatings(issued?.id ? 'badge' : 'skill', issued?.id ? issued?.id : skill_id, user?.id));
  }, [dispatch, issued?.id, skill_id, user, isAuthenticated]);

  return (
    <div>
      <Container className="badge-header">
        <div className="d-block d-md-flex d-lg-flex align-items-center text-center text-md-left text-lg-left">
          <div className="mr-4 mb-3 mb-md-0 mb-lg-0">
            <div>
              <Link
                to={`/cp/${user?.vanity}`}
                title={`Link to ${user?.name}’s Career Site`}
                style={{ overflowWrap: 'break-word' }}>
                {user?.avatar && user?.avatar !== '' ? (
                  <img
                    className="media-object header-image large"
                    src={user.avatar}
                    alt={`${user.name}'s Avatar`}/>
                ) : (
                  <span
                    style={{
                      lineHeight: '60px',
                      fontSize: 32,
                    }}
                    className="media-object header-image large initial-avatar">
                    {user?.name?.slice(0, 1)}
                  </span>
                )}
              </Link>
            </div>
          </div>
          <div className="d-block d-md-flex d-lg-flex justify-content-between w-100 align-items-center">
            <div>
              <h4 className="media-title mb-0 martop10">
                {
                  skillEvidenceView ?
                    `Supporting Evidence for ${isBadge ? assertionBadge.name : skillData?.name}` :
                    user.name
                }
              </h4>
              <div className="media-website mb-0">
                {!skillEvidenceView && <Link
                  title={`Link to ${user?.name}’s Career Site`}
                  to={`/cp/${user?.vanity}`}>
                  www.careerprepped.com/cp/{user?.vanity}
                </Link>}
                {skillEvidenceView && (!ratings || (ratings && parseInt(ratings.totalReview) === 0)) && (
                  <Label className="pending-reviews text-white">
                    {'Pending Review'}
                  </Label>
                )}
                {skillEvidenceView && (
                  <BadgeRatings
                    isAdmin={isAdmin}
                    ratings={ratings}
                    seeAllFeedback={seeAllFeedback}
                    badge={data}
                    skillEvidenceView
                    showLinkPortfolioModal={showLinkPortfolioModal}
                    showEndorseModal={onClickEndorse}
                    showReviewModal={showReviewModal}
                    isOwner={isOwner}/>)}
              </div>
            </div>
            <div className="mt-3 mt-md-0 mt-lg-0">
              {isAuthenticated && (isAdmin || isOwner) && (
                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                  <DropdownToggle caret className="dropdownAction addSkillButton">
                    Actions
                  </DropdownToggle>
                  <DropdownMenu>

                    {isAdmin && user.id !== currentUser.id && (files?.length > 0 || issued?.id) && (
                      <DropdownItem onClick={openFeedbackProcess}>
                        Give Feedback
                      </DropdownItem>
                    )}

                    {isAdmin && user.id !== currentUser.id && (files?.length > 0 || issued?.id) && (
                      <DropdownItem onClick={toggleRequestFeedbackModal}>
                        Request Feedback
                      </DropdownItem>
                    )}
                    {user?.id == currentUser.id &&
                    <DropdownItem onClick={toggleRequestModal}>
                      Seek Feedback
                    </DropdownItem>
                    }

                    <DropdownItem onClick={seeAllFeedback}>
                      See Feedback
                    </DropdownItem>

                    {((! isBadge) && isOwner) &&
                      <DropdownItem onClick={onToggleDeleteModal}>
                        Delete
                      </DropdownItem>}

                  </DropdownMenu>
                </Dropdown>
              )}

              {requestFeedback && (
                <RequestFeedbackModal
                  isOpen={requestFeedback}
                  onCancel={toggleRequestFeedbackModal}
                  badgeIssuedId={issued?.id}
                  skillId={skill_id}
                  assertionBadge={assertionBadge || data}
                  user={user}/>
              )}
              <a
                onClick={onShareClick}
                style={{ color: '#fff', display: 'none' }}
                className="clickable btn btn-tertiary">
                <i className="fa fa-share"/> Share
              </a>
            </div>
          </div>
        </div>
      </Container>
      <div className="row">
        <div className="col-lg-12">
          <div className="badge-item detail-view mb-0">
            <div className="row no-gutters">
              {!skillEvidenceView && <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3">
                <div className="badge-content">
                  <div className="badge-information">
                    <div className="image image-relative">
                      <img
                        src={isBadge ? assertionBadge.image : skillData?.url ? skillData?.url :
                          require('../../assets/images/temp/skill.png')}
                        alt="Badge Image"
                        height="140"
                        width="140"/>
                      {endorsementsList && endorsementsList.length > 0 && (
                        <img
                          className="badge-overlay-image"
                          src={endorsedLogo}/>
                      )}
                    </div>
                    <div className="badge-details">
                      <h4 className="mb-0 mt-1">{isBadge ? assertionBadge?.name : skillData?.name}</h4>
                    </div>
                    {(!ratings || (ratings && parseInt(ratings.totalReview) === 0)) && (
                      <Label className="pending-reviews">
                        {'Pending Review'}
                      </Label>
                    )}
                    {<BadgeRatings
                      isAdmin={isAdmin}
                      isOwner={isOwner}
                      ratings={ratings}
                      seeAllFeedback={seeAllFeedback}
                      badge={data}
                      showReviewModal={showReviewModal}/>
                    }
                  </div>
                  <div className="badge-stats parent">
                    <div className="stat-details float-left">
                      <SimpleToolTipWrapper
                        id={`last_file-${isBadge ? issued?.id : skill_id}`}
                        toolTipContent={<p>{`Last File: ${data.lastPortfolioUploadedOn}`}</p>}>
                        <p
                          className="stat-number">
                          {prefixWithZero(files ? files.length : 0)}
                        </p>
                      </SimpleToolTipWrapper>
                      <span className="stat-name">Portfolio Files</span>
                    </div>
                    <div className="stat-details pull-left">
                      <SimpleToolTipWrapper
                        id={`last_endorsement-${isBadge ? issued?.id : skill_id}`}
                        toolTipContent={<p>{`Last Endorsement:  ${data.lastEndorsementGivenOn}`}</p>}>
                        <p className="stat-number">
                          {endorsementsList && prefixWithZero(endorsementsList.length)}
                        </p>
                      </SimpleToolTipWrapper>
                      <span className="stat-name">Endorsements</span>
                    </div>
                  </div>
                  {isBadge && <div className="badge-meta">
                    <div className="heading mt-1">Claimed on</div>
                    <p style={{ fontSize: 14 }}>{issuedOn}</p>
                    <div className="heading">Processed by</div>
                    <a
                      href="https://www.careerprepped.com"
                      target="_blank"
                      rel="noopener noreferrer">
                      www.careerprepped.com
                    </a>
                    <div className="heading mt-2">Skill Builder</div>
                    <p>
                      <Link
                        style={{ fontSize: 14 }}
                        to={`/skill-builders/${unit?.slug}`}>
                        SB {unit?.code}
                      </Link>
                    </p>
                    <div className="mt-1">
                      <Link style={{ fontSize: 14 }} to="/skillbadge-criteria">Badge Criteria</Link>
                    </div>
                  </div>
                  }
                </div>
              </div>}
              <div className={`col-md-12 ${skillEvidenceView ? '' : 'col-md-12 col-lg-12 col-xl-9'}`}>
                <div className={`badge-list-details mySkillsContainerStyle BadgeCardScore-style ${skillEvidenceView ? '' : 'BadgeContentBorder'}`} >
                  {!skillEvidenceView && isBadge && <UnitBadgeAbilities acknowledgements={acknowledgements} />}

                  {!skillEvidenceView && isBadge && statements.length > 0 ? (
                    <UnitBadgeStatement />
                  ) : (
                    !skillEvidenceView && isBadge && <UnitBadgeJustifications />
                  )}

                  {!skillEvidenceView && <UnitBadgeSkills files={files} isBadge={!!issued?.id}
                    skillBadges={skillBadges}/>}
                  {<UnitBadgeEvidence
                    onViewPortfolio={onViewPortfolio}
                    files={files}
                    endorsements={endorsements}
                    onClickEndorse={onClickEndorse}
                    user={user}
                    isBadgeOwner={isBadgeOwner}
                    isBadge={!!issued?.id}
                    issuedBadgeId={issued?.id ? issued?.id : skill_id}
                    skillData={skillData}
                    onLinkedSkill={onLinkedSkill}
                    lastFileUploadedDate={data.lastPortfolioUploadedOn}
                    lastEndorsementUploadedDate={data.lastEndorsementGivenOn}
                    skillEvidenceView={skillEvidenceView}
                    endorsementCount={endorsementsList && endorsementsList?.length}/>
                  }
                  {<Testimonials skillEvidenceView={skillEvidenceView} receiverId={user?.id} issued={issued} isBadge={isBadge} skill_id={skill_id} userDetails={user} isOwner={isOwner}/>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ProfileModal
        isOpen={toggleDeleteModal}
        toggle={onToggleDeleteModal}
        headerText="Delete Skill"
        body="Are you sure you want to delete this skill?"
        onClick={onDelete}
        disabled={isDeleting.status}
        buttonText={isDeleting.status ? 'Deleting...' : 'Delete'}/>

      {allFeedbackReceived && (
        <AllFeedbackReceived
          data={data}
          toggleModal={closeAllFeedback}
          ratings={getRatingsFilter(ratings)}
          isOpen={allFeedbackReceived}/>
      )}
      {fileUploaded && (
        <PortfolioFileModal
          isOpen={fileUploaded}
          toggleModal={toggleModal}
          proceedWithFile={showLinkPortfolioModal}
          selectedId={data.skill_id || skill_id}/>
      )}
      {requestModal && (
        <LevelUpBadgeModal
          isOpen={requestModal}
          toggleModal={toggleModal}
          proceed={proceed}
          educators={educatorsArr}
          industryReps={industryReps}
          type={data.skill_id ? 'Skill' : 'Badge'}/>
      )}
      {requestEducators && (
        <RequestEducators
          badge_id={unit?.id}
          data={data}
          skill_id={data.skill_id || skill_id}
          isOpen={requestEducators}
          toggleModal={toggleModal}/>
      )}
      {requestPeers && (
        <RequestPeers
          badge_id={unit?.id}
          data={data}
          skill_id={data.skill_id || skill_id}
          isOpen={requestPeers}
          toggleModal={toggleModal}/>
      )}
      {requestIRs && (
        <RequestIRs
          badge_id={unit?.id}
          data={data}
          skill_id={data.skill_id || skill_id}
          isOpen={requestIRs}
          toggleModal={toggleModal}
          proceed={proceed}/>
      )}
      {iRRequestSubmiited && (
        <IRReqSubmitted isOpen={iRRequestSubmiited} toggleModal={toggleModal} />
      )}
    </div>
  );
};

const getRatingsFilter = (ratings) => {
  return (
    (ratings) || {
      endorsementCount: 0,
      endorsements: 0,
      endorsementDate: null,
      portfolioFileCount: 0,
      questionResponseRating: 0,
      portfolioRating: 0,
      final_score: 0,
    }
  );
};

BadgeContent.propTypes = {
  assertionBadge: PropTypes.object,
  acknowledgements: PropTypes.array,
  files: PropTypes.array,
  user: PropTypes.object,
  endorsements: PropTypes.array,
  statements: PropTypes.array,
  issued: PropTypes.object,
  unit: PropTypes.object,
  currentUser: PropTypes.object,
  onShareClick: PropTypes.func,
  onViewPortfolio: PropTypes.func.isRequired,
  onClickEndorse: PropTypes.func.isRequired,
  isBadge: PropTypes.string,
  skill_id: PropTypes.string,
  skillData: PropTypes.object,
  skillBadges: PropTypes.array,
  onLinkedSkill: PropTypes.func,
  showLinkPortfolioModal: PropTypes.func,
  showReviewModal: PropTypes.func,
  skillEvidenceView: PropTypes.bool
};

export default BadgeContent;
