import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as badgeActions from '../../redux/actions/badges';
import * as componentActions from '../../redux/actions/components';
import * as skillsActions from '../../redux/actions/skills';

import _flatten from 'lodash/flatten';

import { Modal } from 'reactstrap';
import Loading from '../../components/common/Loading';
import UnitSkill from '../../components/skill-badges/UnitSkill';

class LinkingSkillsModal extends Component {
  static propTypes = {
    badges: PropTypes.object.isRequired,
    skills: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    assertion: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    userId: PropTypes.string
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      searchText: '',
      selectedSkills: [],
    };

    this.selectSkill = this.selectSkill.bind(this);
    this.closeLinkSkillsRequest = this.closeLinkSkillsRequest.bind(this);
    this.linkSkills = this.linkSkills.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentWillMount(){
    const { actions, badges, userId, assertion } = this.props;
    actions.skillsRequest(userId);
    const selectedBadgesSkills = badges.selectedBadges.
      filter(bad => assertion ?
        bad.issued.skills && bad.issued.skills.length > 0:
        bad.skills && bad.skills.length > 0).
      map(badge => assertion ?
        badge.issued.skills :
        badge.skills);

    const selectedSkillsIds = _flatten(selectedBadgesSkills.
      map(skill => skill.map(nestedItem => nestedItem.id)));

    if(selectedSkillsIds.length > 0){
      this.setState({
        selectedSkills: selectedSkillsIds
      });

    }
  }

  closeLinkSkillsRequest() {
    this.props.actions.resetSelectedBadges();
    this.props.actions.closeModal();
  }

  onChange(event){
    const { value } = event.target;

    this.setState({
      searchText: value
    });
  }

  selectSkill(id) {
    return () => {
      const { selectedSkills } = this.state;
      const index = selectedSkills.indexOf(id);
      const updatedSelectedSkills = index !== -1 ?
        [...selectedSkills.slice(0, index), ...selectedSkills.slice(index+1)] :
        [ ...selectedSkills, id];

      this.setState({ selectedSkills: updatedSelectedSkills });
    };
  }

  linkSkills(){
    const { selectedSkills } = this.state;
    const { badges, actions } = this.props;
    const badgesIssuedIds = badges.selectedBadges.map(badge => badge.issued.id);

    const data = {
      badgesIssuedIds: badgesIssuedIds,
      skillsIds: selectedSkills
    };

    actions.linkMySkills(data)
      .then(() => {
        this.closeLinkMySkillsRequest();
      });

  }

  closeLinkMySkillsRequest(){
    this.props.actions.resetSelectedBadges();
    this.props.actions.closeModal();
  }

  render() {
    const { errors, selectedSkills, searchText } = this.state;
    const { badges, skills, isOpen } = this.props;
    const { isSubmitting } = badges;
    const { isRequesting, data: skillsData } = skills;
    const matchedSkills = skillsData.filter(skill => skill.name.search(new RegExp(searchText, 'i')) > -1);

    return(
      <Modal
        backdrop="static"
        size="lg"
        className="linking-skills-modal"
        isOpen={isOpen}
        toggle={this.closeLinkSkillsRequest}>
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title">Link This Badge to My Other Skills</h6>
            <button
              onClick={this.closeLinkSkillsRequest}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {
              (!isRequesting && skillsData.length !== 0) &&
                <div>
                  <div className="link-skills pt-0 pb-0">
                    <small>Add or remove Other Skills this badge supports:</small>
                    <div className="input-group">
                      <input
                        type="text"
                        id="skillSearch"
                        className="form-control"
                        value={searchText}
                        onChange={this.onChange}
                        placeholder="Search for..."/>
                      <span className="input-group-btn">
                        <button className="btn btn-primary" type="button"><i className="fa fa-search fa-fw"/> Search</button>
                      </span>
                    </div>
                  </div>
                  <hr className="mb-1 mt-1 separator" />
                </div>
            }
            <div className="link-skills-content">
              {Object.keys(errors).length > 0 &&
                <p className="text-danger mb-0">{errors.skill}</p>}

              {isRequesting && <Loading/>}

              {!isRequesting &&
                matchedSkills.map(skill=>
                  (<UnitSkill
                    key={skill.id}
                    skill={skill}
                    selectSkill={this.selectSkill}
                    selectedSkills={selectedSkills}/>))}

              {(!isRequesting && skillsData.length === 0) &&
                <p>You don't have any skills listed in your Career Site. Please add skills to your Career Site so that you can then link this Badge to your Career Site skills.</p>}
            </div>

          </div>
          {
            !(skillsData.length === 0 || isRequesting) &&
              <div className="modal-footer">
                <button
                  type="button"
                  name="button"
                  className="btn btn-secondary"
                  onClick={this.closeLinkSkillsRequest}>
                  Cancel
                </button>
                <button
                  type="button"
                  name="button"
                  disabled={isSubmitting}
                  className="btn btn-primary"
                  style={{marginLeft: 5}}
                  onClick={this.linkSkills}>
                  {isSubmitting ? 'Saving...' : 'Save'}
                </button>
              </div>
          }
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { badges, skills } = state;

  return {
    badges,
    skills,
    userId: state.auth.data.currentUser.id
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, badgeActions, componentActions, skillsActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkingSkillsModal);
