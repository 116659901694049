import PropTypes from 'prop-types';
import React from 'react';

import _isUndefined from 'lodash/isUndefined';
import { uniqueId, parseJSON } from '../../../utils';

import QuizOptions from './QuizOptions';
import classNames from 'classnames';
import AdminSectionHeader from '../admin/AdminSectionHeader';
import Parser from 'html-react-parser';

const QuizSection = ({
  drag,
  drop,
  preview,
  section,
  hover,
  isAdmin,
  onEditClick,
  selectedOption,
  onAnswerSelected
}) => {
  let contents = [];
  const data = parseJSON(section.content);
  const { title, content, correct, feedback } = data;
  const quizSectionId = `quiz-section-${section.id}`;

  Object.keys(content).forEach((key) => {
    const value = content[key];
    const correctAnswer = correct === parseInt(key);
    const radioClass = classNames({
      'correct-answer': correctAnswer
    });

    contents.push(
      <QuizOptions
        key={uniqueId()}
        name="quiz"
        selectedOption={selectedOption}
        value={value}
        label={value}
        radioClass={radioClass}
        quizSectionId={quizSectionId}
        onAnswerSelected={onAnswerSelected}/>
    );
  });

  return (
    <section className="essential-multiple" id={quizSectionId} ref={preview}>
      {isAdmin &&
        <div style={{ marginTop: -20 }}>
          <AdminSectionHeader
            hover={hover}
            drag={drag}
            drop={drop}
            title="Quiz"
            sectionId={section.id}
            onEditClick={onEditClick}
            componentName={quizSectionId}/>
        </div>}

      <div className="section-icon">
        <svg viewBox="0 0 120 100">
          <path d="M38,2 L82,2 A12,12 0 0,1 94,10 L112,44 A12,12 0 0,1 112,56 L94,90 A12,12 0 0,1 82,98 L38,98 A12,12 0 0,1 26,90 L8,56 A12,12 0 0,1 8,44 L26,10 A12,12 0 0,1 38,2" />
        </svg>
        <div className="icon"><div className="ico-multi"/></div>
      </div>

      <div className="question-item">
        <h4 className={quizSectionId}>{Parser(title)}</h4>

        <ul className="multi-choice">
          {contents}
        </ul>

        {selectedOption !== null && !_isUndefined(feedback) &&
          <div className={`quiz-feedback ${quizSectionId}`}>
            {Parser(feedback)}
          </div>}
      </div>
    </section>
  );
};

QuizSection.propTypes = {
  hover: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  section: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onAnswerSelected: PropTypes.func.isRequired,
  selectedOption: PropTypes.string,
  drag: PropTypes.func.isRequired,
  drop: PropTypes.func.isRequired,
  preview: PropTypes.func.isRequired
};

export default QuizSection;
