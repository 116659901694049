import PropTypes from 'prop-types';
import React from 'react';
import { parseJSON } from '../../../utils';
import AdminSectionHeader from '../admin/AdminSectionHeader';
import ReadSpeakerButton from '../../common/ReadSpeakerButton';
import Parser from 'html-react-parser';

const TextListSection = ({
  drag,
  drop,
  preview,
  section,
  hover,
  isAdmin,
  onEditClick
}) => {
  const data = parseJSON(section.content);
  const textListId = `text-list-${section.id}`;
  const url = window.location.href;
  const encodedUrl = encodeURIComponent(url);
  const { title, content } = data;

  return (
    <section className="essential-bullet" id={textListId} ref={preview}>
      {isAdmin &&
        <div style={{ marginTop: -20 }}>
          <AdminSectionHeader
            drag={drag}
            drop={drop}
            hover={hover}
            title="Text list"
            sectionId={section.id}
            onEditClick={onEditClick}
            componentName={textListId}/>
        </div>}

      <div className="section-icon">
        <svg viewBox="0 0 120 100">
          <path d="M38,2 L82,2 A12,12 0 0,1 94,10 L112,44 A12,12 0 0,1 112,56 L94,90 A12,12 0 0,1 82,98 L38,98 A12,12 0 0,1 26,90 L8,56 A12,12 0 0,1 8,44 L26,10 A12,12 0 0,1 38,2" />
        </svg>
        <div className="icon"><div className="ico-bullet1"/></div>
      </div>

      <ReadSpeakerButton sectionClass={textListId} encodedUrl={encodedUrl}/>

      {title && <h4 className={`${textListId}`}>{title}</h4>}

      <ul className={`fa-ul ${textListId}`}>
        {content.map((item, index) => {
          return (
            <li key={index}>
              <i className="fa-li fa fa-chevron-right"/>
              {Parser(item)}
            </li>
          );
        })}
      </ul>
    </section>
  );
};

TextListSection.propTypes = {
  hover: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  section: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
  drag: PropTypes.func.isRequired,
  drop: PropTypes.func.isRequired,
  preview: PropTypes.func.isRequired
};

export default TextListSection;
