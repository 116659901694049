import { CALL_API } from '../../middlewares/api';

import {
  MESSAGES_REQUEST,
  MESSAGES_SUCCESS,
  MESSAGES_FAILURE,
  CREATE_MESSAGE_REQUEST,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_FAILURE,
  DELETE_MESSAGE_REQUEST,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_FAILURE,
  INITIALIZE_MESSAGES_STORE,
  SET_A_THREAD_AS_READ,
  SEARCH_MESSAGES_REQUEST,
  SEARCH_MESSAGES_SUCCESS,
  SEARCH_MESSAGES_FAILURE,
} from '../../constants';

export const initializeMessagesStore = () => {
  return dispatch => dispatch({ type: INITIALIZE_MESSAGES_STORE });
};

export const setAsRead = id => ({ type: SET_A_THREAD_AS_READ, id });

export const messagesRequest = (pageNo = 1) => {
  const endpoint = `message/threads?page=${pageNo}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [MESSAGES_REQUEST, MESSAGES_SUCCESS, MESSAGES_FAILURE],
    },
  };
};

export const sendMessage = (data) => {
  const endpoint = 'message/threads';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        CREATE_MESSAGE_REQUEST,
        CREATE_MESSAGE_SUCCESS,
        CREATE_MESSAGE_FAILURE,
      ],
    },
  };
};

export const deleteMessage = (messageId) => {
  const endpoint = `message/threads/${messageId}`;

  return {
    [CALL_API]: {
      id: messageId,
      endpoint,
      method: 'delete',
      authenticated: true,
      types: [
        DELETE_MESSAGE_REQUEST,
        DELETE_MESSAGE_SUCCESS,
        DELETE_MESSAGE_FAILURE,
      ],
    },
  };
};

export const searchMessages = (query, pageNo = 1) => {
  const endpoint =
    query === ''
      ? `message/threads?page=${pageNo}`
      : `message/threads?query=${query}&page=${pageNo}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      query,
      authenticated: true,
      types: [
        SEARCH_MESSAGES_REQUEST,
        SEARCH_MESSAGES_SUCCESS,
        SEARCH_MESSAGES_FAILURE,
      ],
    },
  };
};
