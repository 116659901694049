import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class SkillBadgeCriteria extends Component {
  render() {
    return (
      <div className="interior info">
        <Helmet title="Skill Badge Criteria"/>
        <div className="container">
          <div className="row">
            <div className="content research">
              <div className="col-12">
                <div className="info-title">
                  <h2>Skill Badge Criteria</h2>
                </div>
              </div>
              <div className="col-12">
                <h3 className="font-weight-bold">Skill Badges</h3>
                <p>Skill Badges provide recognition for specific skills individuals have developed within or beyond traditional, formal contexts and can support with evidence. CareerPrepped believes individuals must be responsible for evidencing their skill claims. Thus, to receive a Skill Badge, individuals must independently validate their skill by completing a guided self-assessment process that encourages the type of self-reflection and self-awareness necessary in the real world. To support this process, CareerPrepped offers a Skill Builder for each Skill Badge to help individuals develop the skill through a continuous process of learning, reflection and practice.</p>
                <h3 className="font-weight-bold">Skill Badge Criteria</h3>
                <p>Individuals must complete a self-assessment acknowledging they possess the abilities that define the skill for which they seek recognition. They must provide a written response to interview questions that elicit narratives of skill demonstration in order to justify their claimed abilities authentically. Individuals can also attach portfolio files as another layer of evidence to prove their claimed abilities. Individuals can further substantiate their skill by getting their Skill Badge endorsed.</p>
                <h3 className="font-weight-bold">Badge Endorsement Criteria</h3>
                <p>Endorsers must attest that they have observed the abilities the individual claims to possess in a training activity, project, or work setting, and that they can confidently confirm that the endorsed individual possesses the abilities their Skill Badge represents. Endorsers must indicate their name, position, and organization.</p>
              </div>

            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default SkillBadgeCriteria;
