import PropTypes from 'prop-types';
import React from 'react';

const ShowMoreComments = ({
  actualCommentCount,
  renderedCommentsCount,
  renderMoreComments
}) => {

  if(renderedCommentsCount < actualCommentCount){
    return(
      <div>
        <p onClick={renderMoreComments} className="showMoreBtn">
          Show More Comments
        </p>
      </div>
    );
  }

  return null;
};

ShowMoreComments.propTypes = {
  renderedCommentsCount: PropTypes.number,
  actualCommentCount: PropTypes.number,
  renderMoreComments: PropTypes.func,
};

export default ShowMoreComments;
