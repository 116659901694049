import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';
import { capitalize } from 'lodash';

const StatusCell = ({ invite }) => {
  let statusClass;

  switch(invite.status) {
  case 'accepted':
    statusClass = 'success';
    break;

  case 'pending':
    statusClass = 'warning';
    break;

  case 'declined':
  case 'decline':
    statusClass = 'danger';
    break;

  default:
    statusClass = 'revoked';
  }

  return (
    <Button
      className="btn-block"
      style={{cursor: 'default'}}
      color={statusClass}
      size="sm">
      {capitalize(invite.status)}
    </Button>
  );
};

StatusCell.propTypes = {
  invite: PropTypes.object.isRequired
};

export default StatusCell;
