import PropTypes from 'prop-types';
import React from 'react';
import AvatarCard from './AvatarCard';
import LocationCard from './LocationCard';
import InstitutionCard from './InstitutionCard';
import ButtonActionsFollowing from './ButtonActionsFollowing';
import MessageModal from './MessageModal';
import { Link } from 'react-router-dom';

const FollowingCard = ({
  isCheckedUser,
  checkUser,
  onUnfollowClick,
  profile,
  modalOpen,
  toggleModal,
  error,
  isSubmitting,
  message,
  onMessageChange,
  onMessageSubmit,
  isDeleting,
  recordId,
  isUserFollower
}) => {
  const isUnFollowing = isDeleting.status && (isDeleting.id.indexOf(recordId) !== -1);

  return (
    <div className={isCheckedUser ? 'follow-tile checked' : 'follow-tile'}>
      <div className="follower-item">
        <div className="follower-info">
          <div className="select">
            <input
              className="select-box"
              type="checkbox"
              onClick={checkUser}
              checked={isCheckedUser} />
          </div>
          <div className="follower-content">
            <Link to={`/cp/${profile.vanity}`}>
              <AvatarCard profile={profile} />
            </Link>
            <div className="follower-meta">
              <div className="follower-name">
                <Link to={`/cp/${profile.vanity}`}>
                  {profile.name}
                </Link>
              </div>
              <div>
                {profile.headline && profile.headline.length > 0 &&
                  <p className="two-line-body one">{profile.headline}</p>
                }
                <LocationCard profile={profile}/>
                <InstitutionCard profile={profile}/>
              </div>
            </div>
          </div>
        </div>
        <ButtonActionsFollowing
          isUnFollowing={isUnFollowing}
          onUnfollowClick={onUnfollowClick}
          toggleModal={toggleModal}
          isUserFollower={isUserFollower}
          recordId={recordId} />
      </div>
      <MessageModal
        toggleModal={toggleModal}
        modalOpen={modalOpen}
        error={error}
        isSubmitting={isSubmitting}
        message={message}
        onMessageChange={onMessageChange}
        onMessageSubmit={onMessageSubmit} />
    </div>
  );
};

FollowingCard.propTypes = {
  isCheckedUser: PropTypes.bool.isRequired,
  checkUser: PropTypes.func.isRequired,
  onReportClick: PropTypes.func.isRequired,
  onHideClick: PropTypes.func.isRequired,
  onBlockClick: PropTypes.func.isRequired,
  profile: PropTypes.object,
  toggleDropdown: PropTypes.func.isRequired,
  dropdownOpen: PropTypes.bool.isRequired,
  onUnfollowClick: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
  message: PropTypes.object,
  isSubmitting: PropTypes.bool,
  modalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onMessageChange: PropTypes.func.isRequired,
  onMessageSubmit: PropTypes.func,
  isDeleting: PropTypes.object,
  recordId: PropTypes.string,
  isUserFollower: PropTypes.bool
};

export default FollowingCard;
