import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { pure } from 'recompose';

const UnitCard = ({unit, homepageModal}) => {

  const { isClaimed, url } = unit.badge;
  const earnedBadge = `Earned Badge: ${unit.title}`;
  const className = classNames('badge-status', {
    'completed': isClaimed,
  });

  const path = '/skill-builders';

  if(homepageModal){
    return(
      <a className="link orange">
        <span>
          {unit.code}
        </span>
        {unit.title}
        <span className={className} />
        {isClaimed && <img className="earned-badge-icon" src={url} alt="badge image"/>}
      </a>
    );
  }

  return (
    <Link
      to={unit.isavailable ? `${path}/${unit.slug}` : `${path}/#`}>
      <span>
        {unit.code}
      </span>
      {unit.title}
      <span className={className} />
      {isClaimed && <img className="earned-badge-icon" src={url} alt={earnedBadge}/>}
    </Link>
  );
};

UnitCard.propTypes = {
  homepageModal: PropTypes.bool,
  unit: PropTypes.object.isRequired
};

export default pure(UnitCard);
