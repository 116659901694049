import PropTypes from 'prop-types';
import React from 'react';

import { parseJSON } from '../../../utils';

import AdminSectionHeader from '../admin/AdminSectionHeader';
import ReadSpeakerButton from '../../common/ReadSpeakerButton';
import CustomSlider from '../../../containers/skill-builders/CustomSlider';
import Parser from 'html-react-parser';

const RollingSection = ({
  drag,
  drop,
  preview,
  unit,
  onPersonalValuesSeeExamples,
  section,
  hover,
  isAdmin,
  onEditClick
}) => {
  const data = parseJSON(section.content);
  const rollingSectionId = `rolling-section-${section.id}`;
  const url = window.location.href;
  const encodedUrl = encodeURIComponent(url);
  const speakerId = `read-speaker-${rollingSectionId}`;
  const { title, content } = data;

  return (
    <section className="essential-bullets" id={rollingSectionId} ref={preview}>
      {isAdmin &&
        <div style={{ marginTop: -20 }}>
          <AdminSectionHeader
            hover={hover}
            drag={drag}
            drop={drop}
            title="Rolling list"
            sectionId={section.id}
            onEditClick={onEditClick}
            componentName={rollingSectionId}/>
        </div>}

      <div className="section-icon">
        <svg viewBox="0 0 120 100">
          <path d="M38,2 L82,2 A12,12 0 0,1 94,10 L112,44 A12,12 0 0,1 112,56 L94,90 A12,12 0 0,1 82,98 L38,98 A12,12 0 0,1 26,90 L8,56 A12,12 0 0,1 8,44 L26,10 A12,12 0 0,1 38,2" />
        </svg>
        <div className="icon"><div className="ico-bullet2"/></div>
      </div>
      <ReadSpeakerButton sectionClass={rollingSectionId} encodedUrl={encodedUrl}/>

      <div id={speakerId}>
        {title && <h4>{title}</h4>}

        <CustomSlider
          onPersonalValuesSeeExamples={onPersonalValuesSeeExamples}
          title={title}
          unit={unit}
          content={content}/>
      </div>
      <div id={`list-${speakerId}`} style={{display: 'none'}}>
        {title && <h4 className={rollingSectionId}>{title}</h4>}

        <ul>
          {content.map(item =><li key={item} className={rollingSectionId}>{Parser(item)}</li>)}
        </ul>
      </div>
    </section>
  );
};

RollingSection.propTypes = {
  unit: PropTypes.object.isRequired,
  onPersonalValuesSeeExamples: PropTypes.func,
  hover: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  section: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
  drag: PropTypes.func.isRequired,
  drop: PropTypes.func.isRequired,
  preview: PropTypes.func.isRequired
};

export default RollingSection;
