import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'reactstrap';

const PersonalValuesExampleModal = ({isOpen, closeModal}) => {
  return (
    <Modal isOpen={isOpen} toggle={closeModal} size={'lg'}>
      <div className="see-examples-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title" id="myModalLabel">Examples of Personal Values</h6>
            <button type="button" className="close" onClick={closeModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="exmples-list">
              <p><strong>Achievement:</strong> Successfully accomplishing goals</p>
              <p><strong>Companionship:</strong> Friendship and feeling close to others</p>
              <p><strong>Creativity:</strong> Using imagination and having original ideas</p>
              <p><strong>Family time:</strong> Dedicating time to spend with family</p>
              <p><strong>Flexibility:</strong> Being willing to change or compromise</p>
              <p><strong>Generosity:</strong> Willingness to give to others</p>
              <p><strong>Happiness:</strong> Feeling joy, pleasure, or contentment</p>
              <p><strong>Health:</strong> Being fit and free from injury or illness</p>
              <p><strong>Helping others:</strong> Offering service or resources to others</p>
              <p><strong>Honesty:</strong> Keeping promises and being truthful</p>
              <p><strong>Independence:</strong> Self-reliant and not depending on others</p>
              <p><strong>Integrity:</strong> Acting in accordance to your values</p>
              <p><strong>Justice:</strong> Fairness and equitable treatment of others</p>
              <p><strong>Learning:</strong> Acquiring knowledge and skills</p>
              <p><strong>Loyalty:</strong> Devotion or faithfulness to a person or institution</p>
              <p><strong>Mental challenge:</strong> Challenges that stimulate your mind</p>
              <p><strong>Neatness and orderliness:</strong> Organization and tidiness</p>
              <p><strong>Personal appearance:</strong> Being well-dressed and groomed</p>
              <p><strong>Power:</strong> Control over others or a course of events</p>
              <p><strong>Recognition:</strong> Acknowledgement and appreciation</p>
              <p><strong>Reputation:</strong> Beliefs or opinions people have of others</p>
              <p><strong>Respect:</strong> Esteem, dignity, or regard for self and others</p>
              <p><strong>Security:</strong> Feeling safe or protected from danger or threat</p>
              <p><strong>Social status:</strong> High standing among others in society</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

PersonalValuesExampleModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func
};

export default PersonalValuesExampleModal;
