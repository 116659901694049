import { normalize, schema } from 'normalizr';

import initialState from './initialState';
import {
  SHARE_WALLPOST,
  RESET_SHARE_WALLPOST,
  RESET_DASHBOARD_WALL,
  WALL_POST_REQUEST,
  WALL_POST_SUCCESS,
  WALL_POST_FAILURE,
  WALL_POSTS_REQUEST,
  WALL_POSTS_SUCCESS,
  WALL_POSTS_FAILURE,
  CREATE_WALL_POST_REQUEST,
  CREATE_WALL_POST_SUCCESS,
  CREATE_WALL_POST_FAILURE,
  CREATE_WALL_POST_LIKE_REQUEST,
  CREATE_WALL_POST_LIKE_SUCCESS,
  CREATE_WALL_POST_LIKE_FAILURE,
  WALL_POST_UNLIKE_REQUEST,
  WALL_POST_UNLIKE_SUCCESS,
  WALL_POST_UNLIKE_FAILURE,
  UPDATE_WALL_POST_REQUEST,
  UPDATE_WALL_POST_SUCCESS,
  UPDATE_WALL_POST_FAILURE,
  DELETE_WALL_POST_REQUEST,
  DELETE_WALL_POST_SUCCESS,
  DELETE_WALL_POST_FAILURE,
  INCREMENT_WALL_COMMENT_COUNT,
  DECREMENT_WALL_COMMENT_COUNT,
} from '../../constants';

const updateWallReducer = (state, action) => {
  let returnData = [];
  const {
    data: prevResults,
    page: prevPage,
    page_count: prevPageCount,
  } = state;
  const { _embedded: actionData, page, page_count } = action;
  returnData = actionData.wall;

  if (page && page !== 1) {
    returnData = [...prevResults, ...returnData];
  }

  const stateToReturn = {
    data: returnData,
    paginationData: {
      page: page || prevPage,
      page_count: page_count || prevPageCount,
    },
    commentCount: Object.assign(
      {},
      state.commentCount,
      actionData.wall.reduce((item, obj) => {
        item[obj.id] = obj.comment_count;
        return item;
      }, {})
    ),
    isRequesting: false,
  };

  return stateToReturn;
};

export default function reducer(state = initialState.wallPosts, action) {
  switch (action.type) {
  case RESET_DASHBOARD_WALL:
    return Object.assign({}, initialState.wallPosts);

  case DECREMENT_WALL_COMMENT_COUNT: {
    const index = state.data.findIndex(
      wallpost => wallpost.id === action.id
    );

    let returnObject = {};
    if (action.typeOfComment)
      returnObject = { [action.id]: state.commentCount[action.id] - 1 };

    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, index),
        Object.assign({}, state.data[index], {
          comment_count: state.data[index].comment_count - action.count,
        }),
        ...state.data.slice(index + 1),
      ],
      commentCount: Object.assign({}, state.commentCount, returnObject),
    });
  }

  case INCREMENT_WALL_COMMENT_COUNT: {
    const index = state.data.findIndex(
      wallPost => wallPost.id === action.id
    );

    let returnObject = {};
    if (action.typeOfComment)
      returnObject = { [action.id]: state.commentCount[action.id] + 1 };

    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, index),
        Object.assign({}, state.data[index], {
          comment_count: state.data[index].comment_count + 1,
        }),
        ...state.data.slice(index + 1),
      ],
      commentCount: Object.assign({}, state.commentCount, returnObject),
    });
  }

  case SHARE_WALLPOST:
    return Object.assign({}, state, { wallShare: action.wallpost });

  case RESET_SHARE_WALLPOST:
    return Object.assign({}, state, { wallShare: action.wallpost });

  case WALL_POST_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case WALL_POST_SUCCESS:
    return Object.assign({}, initialState.wallPosts, {
      data: [action.data],

      isRequesting: false,
    });

  case WALL_POST_FAILURE:
    return Object.assign({}, initialState.wallPosts, {
      error: action.error,
      isRequesting: false,
    });

  case WALL_POSTS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case WALL_POSTS_SUCCESS: {
    const user = new schema.Entity('users', {}, { idAttribute: 'id' });
    const comments = new schema.Entity(
      'comments',
      { user: user },
      { idAttribute: 'id' }
    );
    const post = new schema.Entity(
      'post',
      { user: user, comments: [comments] },
      {
        idAttribute: 'id',
        // Apply everything from entityB over entityA, except for "favorites"
        mergeStrategy: (entityA, entityB) => ({
          ...entityA,
          ...entityB,
          favorites: entityA.favorites,
        }),
      }
    );

    const normalizedData = normalize(action.data._embedded.wall, [post]);

    return Object.assign(
      {},
      state,
      {
        wallPost: {
          byId: normalizedData.entities.post,
          allIds: normalizedData.result,
        },
      },
      updateWallReducer(state, action.data)
    );
  }

  case WALL_POSTS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case CREATE_WALL_POST_REQUEST:
    return Object.assign({}, state, { isSubmitting: true });

  case CREATE_WALL_POST_SUCCESS:
    return Object.assign({}, state, {
      data: [Object.assign({}, action.data), ...state.data],
      commentCount: Object.assign(
        {},
        {
          [action.data.id]: 0,
        },
        state.commentCount
      ),
      isSubmitting: false,
    });

  case CREATE_WALL_POST_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case CREATE_WALL_POST_LIKE_REQUEST:
  case WALL_POST_UNLIKE_REQUEST:
    return Object.assign({}, state, {
      isSubmittingLike: {
        status: true,
        wallPostIndex: action.index,
        userId: action.userId,
      },
    });

  case CREATE_WALL_POST_LIKE_SUCCESS: {
    const { wallPostIndex: index } = state.isSubmittingLike;
    const objectToUpdate = Object.assign({}, state.data[index], {
      like_count: state.data[index].like_count + 1,
      liked: true,
      likesList: [
        ...state.data[index].likesList,
        Object.assign({}, action.data),
      ],
    });

    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, index),
        objectToUpdate,
        ...state.data.slice(index + 1),
      ],
      isSubmittingLike: { status: false, wallPostIndex: null },
    });
  }

  case WALL_POST_UNLIKE_SUCCESS: {
    const { wallPostIndex: index, userId } = state.isSubmittingLike;
    const objectToUpdate = Object.assign({}, state.data[index], {
      like_count: state.data[index].like_count - 1,
      liked: false,
      likesList: [
        ...state.data[index].likesList.filter(like => like.id !== userId),
      ],
    });

    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, index),
        objectToUpdate,
        ...state.data.slice(index + 1),
      ],
      isSubmittingLike: {
        status: false,
        wallPostIndex: null,
        userId: null,
      },
    });
  }

  case CREATE_WALL_POST_LIKE_FAILURE:
  case WALL_POST_UNLIKE_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmittingLike: {
        status: false,
        userId: null,
        wallPostIndex: null,
      },
    });

  case UPDATE_WALL_POST_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, id: action.id },
    });

  case UPDATE_WALL_POST_SUCCESS: {
    const index = state.data.findIndex(
      item => item.id === state.isUpdating.id
    );

    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, index),
        Object.assign({}, state.data[index], action.data),
        ...state.data.slice(index + 1),
      ],
      isUpdating: { status: false, id: null },
      error: {},
    });
  }

  case UPDATE_WALL_POST_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false, id: null },
    });

  case DELETE_WALL_POST_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: action.id },
    });

  case DELETE_WALL_POST_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data.filter(post => post.id !== state.isDeleting.id)],
      isDeleting: { status: false, id: null },
    });

  case DELETE_WALL_POST_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: { status: false, id: null },
    });

  default:
    return state;
  }
}
