import initialState from './initialState';

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  CURRENT_USER_REQUEST,
  CURRENT_USER_SUCCESS,
  CURRENT_USER_FAILURE,
  CLEAR_USER_REDUCERS,
  LINKEDIN_LOGIN_REQUEST,
  LINKEDIN_LOGIN_SUCCESS,
  LINKEDIN_LOGIN_FAILURE,
  CREATE_PASSWORD_IR_REQUEST,
  CREATE_PASSWORD_IR_SUCCESS,
  CREATE_PASSWORD_IR_FAILURE,
  CREATE_PASSWORD_PEER_REQUEST,
  CREATE_PASSWORD_PEER_SUCCESS,
  CREATE_PASSWORD_PEER_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.auth, action) {
  switch (action.type) {
  case CLEAR_USER_REDUCERS:
    return Object.assign({}, initialState.auth, {
      lastLoggedInUserId: action.currentUserId,
    });

  case LOGIN_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case LOGIN_SUCCESS:
    return Object.assign({}, state, {
      isRequesting: false, 
      isAuthenticated: true,
      data: {
        currentUser: {
          id: action.data.user_id,
          name: action.data.name,
        },
      },
    });

  case LOGIN_FAILURE: {
    return Object.assign({}, state, {
      isRequesting: false,
    });
  }

  case CURRENT_USER_REQUEST:
    return Object.assign({}, state, action.payload);

  case CURRENT_USER_SUCCESS:
    return Object.assign({}, state, {
      data: {
        currentUser: {
          id: action.data.id,
          name: action.data.name,
        },
      },
      isRequesting: false,
      isAuthenticated: true,
    });

  case CURRENT_USER_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case LINKEDIN_LOGIN_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case LINKEDIN_LOGIN_SUCCESS:
    return Object.assign({}, state, {
      isRequesting: false,
      isAuthenticated: true,
      data: {
        currentUser: {
          id: action.data.user_id,
          name: action.data.name,
        },
      },
    });

  case LINKEDIN_LOGIN_FAILURE: {
    return Object.assign({}, state, {
      isRequesting: false,
    });
  }

  case CREATE_PASSWORD_IR_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case CREATE_PASSWORD_IR_SUCCESS:
    return Object.assign({}, state, {
      isRequesting: false,
      isAuthenticated: true,
      data: { 
        currentUser: {
          id: action.data.user_id
        },},
    });

  case CREATE_PASSWORD_IR_FAILURE: {
    return Object.assign({}, state, {
      isRequesting: false,
    });
  }

  case CREATE_PASSWORD_PEER_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case CREATE_PASSWORD_PEER_SUCCESS:
    return Object.assign({}, state, {
      isRequesting: false,
      isAuthenticated: true,
      data: { 
        currentUser: {
          id: action.data.user_id
        },},
    });

  case CREATE_PASSWORD_PEER_FAILURE: {
    return Object.assign({}, state, {
      isRequesting: false,
    });
  } 
  
  default:
    return state;
  }
}
