import { CALL_API } from '../../middlewares/api';

import {
  ACCESS_REQUEST,
  ACCESS_SUCCESS,
  ACCESS_FAILURE,
  REGISTRATION_REQUEST,
  REGISTRATION_SUCCESS,
  REGISTRATION_FAILURE,
  SELF_REGISTRATION_REQUEST,
  SELF_REGISTRATION_SUCCESS,
  SELF_REGISTRATION_FAILURE,
  JOIN_MEMBERSHIP_REQUEST,
  JOIN_MEMBERSHIP_SUCCESS,
  JOIN_MEMBERSHIP_FAILURE,
  EDUCATOR_SIGNUP_REQUEST,
  EDUCATOR_SIGNUP_SUCCESS,
  EDUCATOR_SIGNUP_FAILURE,
  RECRUITER_REQUESTS_REQUEST,
  RECRUITER_REQUESTS_SUCCESS,
  RECRUITER_REQUESTS_FAILURE,
  INDUSTRY_MENTOR_REQUESTS_REQUEST,
  INDUSTRY_MENTOR_REQUESTS_SUCCESS,
  INDUSTRY_MENTOR_REQUESTS_FAILURE,
  SURVEY_FEEDBACK_REQUEST,
  SURVEY_FEEDBACK_SUCCESS,
  SURVEY_FEEDBACK_FAILURE
} from '../../constants';

export const submitSurveyFeedback = (data) => {
  const endpoint = 'access/survey-feedbacks';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      types: [
        SURVEY_FEEDBACK_REQUEST,
        SURVEY_FEEDBACK_SUCCESS,
        SURVEY_FEEDBACK_FAILURE
      ]
    }
  };
};

export const requestInvite = (data) => {
  const endpoint = 'access/requests';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      types: [REGISTRATION_REQUEST, REGISTRATION_SUCCESS, REGISTRATION_FAILURE],
    },
  };
};

export const joinMembership = (data) => {
  const endpoint = 'access/join-membership';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      types: [
        JOIN_MEMBERSHIP_REQUEST,
        JOIN_MEMBERSHIP_SUCCESS,
        JOIN_MEMBERSHIP_FAILURE,
      ],
    },
  };
};

export const requestSelfRegistration = (data) => {
  const endpoint = 'group/self-invite';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      types: [
        SELF_REGISTRATION_REQUEST,
        SELF_REGISTRATION_SUCCESS,
        SELF_REGISTRATION_FAILURE,
      ],
    },
  };
};

export const fetchAllAccessRequests = () => {
  const endpoint = 'access/requests';

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [ACCESS_REQUEST, ACCESS_SUCCESS, ACCESS_FAILURE],
    },
  };
};

export const educatorSignUp = (data) => {
  const endpoint = 'access/signup';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      types: [
        EDUCATOR_SIGNUP_REQUEST,
        EDUCATOR_SIGNUP_SUCCESS,
        EDUCATOR_SIGNUP_FAILURE,
      ],
    },
  };
};

export const industryMentorRequest = (data) => {
  const endpoint = 'access/industry-mentor-requests';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      types: [
        INDUSTRY_MENTOR_REQUESTS_REQUEST,
        INDUSTRY_MENTOR_REQUESTS_SUCCESS,
        INDUSTRY_MENTOR_REQUESTS_FAILURE
      ]
    }
  };
};

export const recruiterRequest = (data) => {
  const endpoint = 'access/recruiter-requests';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      types: [
        RECRUITER_REQUESTS_REQUEST,
        RECRUITER_REQUESTS_SUCCESS,
        RECRUITER_REQUESTS_FAILURE
      ]
    }
  };
};
