import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import QuillEditor from '../common/QuillEditor';
import Dropzone from 'react-dropzone';
import ImagePreview from '../common/ImagePreview';

const styles = {
  avatarInput: {
    position: 'absolute',
  },
  label: {
    cursor: 'pointer'
  },
  closeIcon: {
    position: 'absolute',
    top: -12,
    right: -12,
    zIndex: 99,
    cursor: 'pointer',
    color: '#ff5000',
    fontSize: 20
  }
};

const modules = {
  toolbar: [
    ['link', 'video'],
    ['bold', 'italic', 'underline'],
    [{ 'color': [] }, { 'background': [] }]
  ],
  clipboard: {
    matchVisual: false,
  }
};

const DashboardForumForm = ({
  value,
  name,
  label,
  error,
  handleHTMLChange,
  onCancel,
  imagePreviewUrl,
  onDropFile
}) => {

  const wrapperClass = classNames('form-inline write-post', {
    'has-error': error && error.length > 0
  });

  const validMimeType = 'image/*';

  return (
    <form className={wrapperClass}>
      {label && <label htmlFor={name}>{label}</label>}

      <div className="form-group">
        <div className="input-group quill-relative-inner">
          <QuillEditor
            modules={modules}
            value={value}
            className="quill-editor-no-boundary quill-relative-inner"
            placeholder="Start a conversation"
            onChange={handleHTMLChange} />

          <ImagePreview
            imagePreviewUrl={imagePreviewUrl}
            onCancel={onCancel}
            closeIcon={styles.closeIcon}
            styles={{ marginTop: 20 }}
            primary/>

          <label
            style={styles.label}>
            <Dropzone
              style={styles.avatarInput}
              multiple={false}
              accept={validMimeType}
              onDrop={onDropFile}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()}/>
                  </div>
                </section>
              )}
            </Dropzone>

            <span className="browse-icon-dashboard-forum">
              <i className="fa fa-picture-o"/>
            </span>
          </label>
        </div>
      </div>
    </form>
  );
};

DashboardForumForm.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  textareaRef: PropTypes.func,
  error: PropTypes.string,
  handleHTMLChange: PropTypes.func,
  imagePreviewUrl: PropTypes.string,
  onCancel: PropTypes.func,
  onDropFile: PropTypes.func
};

export default DashboardForumForm;
