import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as commentActions from '../../../redux/actions/comments';
import { toastr } from 'react-redux-toastr';
import { scrollToTop } from '../../../utils';
import CommentLikeButton from '../../../components/common/CommentLikeButton';

class CommentLikeButtonContainer extends Component {
  static propTypes = {
    comment: PropTypes.object,
    actions: PropTypes.object.isRequired,
    error: PropTypes.object,
    isSubmittingLike: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    userId: PropTypes.string.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    event.preventDefault();

    const { userId, actions, comment, isAuthenticated } = this.props;

    if (!isAuthenticated) {
      scrollToTop();
      return toastr.warning('Please Login/Signup');
    }

    if(comment.liked){
      return actions.unlikeUnitDiscussionComment({comment: comment.id, userId}, comment.discussion);
    }

    actions.saveUnitDiscussionCommentLike({comment: comment.id}, comment.discussion);
  }

  render() {
    const { comment, isSubmittingLike } = this.props;

    return(
      <CommentLikeButton
        onLike={this.onClick}
        comment={comment}
        isLikingComment={comment &&
          isSubmittingLike.status &&
          isSubmittingLike.commentId === comment.id}/>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.comments.error,
    userId: state.auth.data.currentUser.id,
    isSubmittingLike: state.comments.isSubmittingLike,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, commentActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentLikeButtonContainer);
