import { CALL_API } from '../../middlewares/api';

import {
  CITIES_REQUEST,
  CITIES_SUCCESS,
  CITIES_FAILURE
} from '../../constants';

export const getCities = (stateId, page = 1, query = '') => {
  let endpoint = `countries?stateId=${stateId}&page=${page}`;

  if (query.length > 0) {
    endpoint = `${endpoint}&query=${query}`;
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [
        CITIES_REQUEST,
        CITIES_SUCCESS,
        CITIES_FAILURE
      ]
    }
  };
};
