import initialState from './initialState';

import {
  RESET_FOLLOWING_DATA,
  FOLLOWING_REQUEST,
  FOLLOWING_SUCCESS,
  FOLLOWING_FAILURE,
  CREATE_FOLLOWING_REQUEST,
  CREATE_FOLLOWING_SUCCESS,
  UPDATE_FOLLOWING_REQUEST,
  UPDATE_FOLLOWING_SUCCESS,
  UPDATE_FOLLOWING_FAILURE,
  DELETE_FOLLOWING_REQUEST,
  DELETE_FOLLOWING_SUCCESS,
  DELETE_FOLLOWING_FAILURE,
  FOLLOW_SEARCH_RESULT_PEOPLE_SUCCESS,
  UNFOLLOW_SEARCH_RESULT_PEOPLE_REQUEST,
  UNFOLLOW_SEARCH_RESULT_PEOPLE_SUCCESS,
  UNFOLLOW_SEARCH_RESULT_PEOPLE_FAILURE,
  CREATE_FOLLOWER_SUCCESS,
} from '../../constants';

export default function reducer(state = initialState.following, action) {
  switch (action.type) {
  case RESET_FOLLOWING_DATA:
    return Object.assign({}, state, { data: [] });

  case FOLLOWING_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case FOLLOWING_SUCCESS:
    return Object.assign({}, state, {
      data: action.data._embedded.connect,
      paginationData: {
        page: action.data.page,
        page_count: action.data.page_count,
        total_items: action.data.total_items,
      },
      isRequesting: false,
    });

  case FOLLOWING_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case CREATE_FOLLOWING_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: { status: true, profile: action.profile },
    });

  case CREATE_FOLLOWING_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data, Object.assign({}, action.data)],
      isSubmitting: { status: false, profile: null },
    });

  case FOLLOW_SEARCH_RESULT_PEOPLE_SUCCESS:
  case CREATE_FOLLOWER_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data, Object.assign({}, action.data)],
    });

  case UPDATE_FOLLOWING_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, followingIndex: action.index },
    });

  case UPDATE_FOLLOWING_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, state.isUpdating.followingIndex),
        Object.assign({}, action.data),
        ...state.data.slice(state.isUpdating.followingIndex + 1),
      ],
      isUpdating: { status: false, followingIndex: null },
    });

  case UPDATE_FOLLOWING_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false, followingIndex: null },
    });

  case DELETE_FOLLOWING_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: [...state.isDeleting.id, action.id] },
    });

  case DELETE_FOLLOWING_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.filter(
          following => state.isDeleting.id.indexOf(following.record) === -1
        ),
      ],
      isDeleting: { status: false, id: [] },
    });

  case DELETE_FOLLOWING_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: { status: false, id: [] },
    });

  case UNFOLLOW_SEARCH_RESULT_PEOPLE_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: [...state.isDeleting.id, action.id] },
    });

  case UNFOLLOW_SEARCH_RESULT_PEOPLE_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.filter(
          following =>
          // following.user.id !== state.isDeleting.id)
            state.isDeleting.id.indexOf(following.record) === -1
        ),
      ],
      isDeleting: { status: false, id: [] },
    });

  case UNFOLLOW_SEARCH_RESULT_PEOPLE_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: { status: false, id: [] },
    });

  default:
    return state;
  }
}
