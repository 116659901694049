import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getMyBadges, getMySkills, getRatings, getMySkillsPage, clearMySkills, clearMyBadges } from '../../redux/actions/myskills';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Button,
  Input,
} from 'reactstrap';
import { toastr } from 'react-redux-toastr';

import StarRating from '../../components/rating/StarRating';
import { getSkillsRating } from '../../utils';
import { isEmpty } from 'lodash';
import CloseIcon from '../../assets/images/close.svg';
import './feedback.css';
import BadgesSkillsList from './BadgesSkillsList';
import { SEARCH } from '../../utilConstants';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import InputGroup from 'reactstrap/lib/InputGroup';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';


const prefixWithZero = (num) => {
  return Number(num)?.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
};

class SeeFeedbackModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputvalue: '',
      selectSkillsBadge: true,
      isRequesting: false,
      resetPage: false,
    };
  }

  componentDidMount() {
    this.getSkillBadgesWithRatings();
  }

  componentWillUnmount() {
    this.props.actions.clearMySkills();
    this.props.actions.clearMyBadges();
  }

  inputStringonChange = (e) => {
    this.setState({
      inputvalue: e.target.value,
      resetPage: false,
    }, () => {
      if (isEmpty(this.state.inputvalue)) {
        this.setState({ resetPage: true });
        this.searchSkillBadge(true);
      }
    });
  };

  searchSkillBadge = (resetSearch = false) => {
    const { inputvalue } = this.state;
    const { actions } = this.props;
    if (!resetSearch && (isEmpty(inputvalue) || inputvalue.length === 1)) {
      return;
    }
    this.setState({ resetPage: true });
    actions.clearMySkills();
    actions.clearMyBadges();
    const queryParam = `&search=${this.state.inputvalue}`;
    this.getMySkills(null, queryParam, 1, 'skill');
    this.getMySkills(null, queryParam, 1, 'badge');
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.searchSkillBadge();
      return true;
    }
  }

  handleRadioChange = (event) => {
    const { setDataForSeeFeedback } = this.props;

    let skillBadgeReview = JSON.parse(event.target.getAttribute('data')).avgRating;

    if(parseInt(skillBadgeReview) !== 0){
      this.setState({
        selectSkillsBadge: false
      });
    }

    setDataForSeeFeedback(JSON.parse(event.target.getAttribute('data')), JSON.parse(event.target.getAttribute('starrating')));
  };

  handleClickBtn = (event) => {
    event.target.checked = false;
    toastr.info('', 'This claimed skill hasn\'t been reviewed yet so it has no feedback. Select a claimed skill that has been reviewed.');
    this.setState({
      selectSkillsBadge: true
    });
  }

  handleClickBtnReturn = () => {
    return true;
  }

  getSkillBadgesWithRatings = () => {
    const { actions } = this.props;
    actions.clearMySkills();
    actions.clearMyBadges();
    this.getMySkills(null, null, 1, 'skill');
    this.getMySkills(null, null, 1, 'badge');
  };

  getMySkills = async (links, filter = null, page = 1, resource = null) => {
    let { resourceTypeParam } = this.state;
    if (resource) {
      resourceTypeParam = resource;
    }
    this.setState({ isRequesting: true });
    const skillsList = await this.props.actions.getMySkillsPage(this.getUser()?.id, links, filter, resourceTypeParam, page);
    this.setState({ isRequesting: false });
    return skillsList;
  };

  getUser = () => {
    return this.props.userDataForSeeFeedback?.userData ?? this.props.user;
  };

  getRatings = (id) => {
    return (
      (this.props.skillBadgeList &&
        this.props.skillBadgeList.find(r => (r?.skillId === id || r?.badgeId === id))) || {
        endorsementCount: 0,
        endorsements: 0,
        endorsementDate: null,
        portfolioFileCount: 0,
        questionRespRating: 0,
        porfolioRating: 0,
        final_score: 0,
      }
    );
  };

  seeFeedback = () => {
    this.props.viewFeedback();
  }

  getStarRating(data, showAvgRating = true) {
    if (showAvgRating) {
      return getSkillsRating(this.getRatings(data.skill_id || data.issuedID));
    }
  }

  render() {

    const filteredResult = this.props.skillBadgeList;

    return (
      <Modal
        backdrop="static"
        className="modal-md"
        isOpen={this.props.isOpen}
        toggle={this.props.onCancel}>
        <div className="modal-header">
          <span className="modal-title">
            {`See Feedback on ${this.getUser()?.name}'s Claimed Skills`}
          </span>
          <button
            onClick={this.props.onCancel}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">
              <img src={CloseIcon} />
            </span>
          </button>
        </div>
        <ModalBody className="preview-resume-modal-body">
          <p style={{ marginTop: '2%' }}>
            {' '}
            Choose a claimed skill with reviews to see the given feedback.
          </p>
          <div className="feedback-search">
            <Row>
              <Col md="7" className="pr-md-0">
                <FormGroup>
                  <InputGroup>
                    <Input
                      type="text"
                      name="search"
                      id="search"
                      value={this.state.inputvalue}
                      placeholder={SEARCH}
                      onChange={this.inputStringonChange}
                      onKeyPress={this.handleKeyPress} />
                    <InputGroupAddon className="pointer" addonType="append" onClick={this.searchSkillBadge}>
                      <i className="fa fa-search" />
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div id="scrollableDiv" style={{ height: 300 }}>
            <BadgesSkillsList
              filteredResultsLength={filteredResult.length}
              getMySkills={this.getMySkills}
              badgesTotalPage={this.props.badgesTotalPage}
              skillsTotalPage={this.props.skillsTotalPage}
              searchValue={this.state.inputvalue}
              isRequesting={this.state.isRequesting}
              resetPage={this.state.resetPage}>
              {filteredResult &&
                filteredResult.map((data, key) => {
                  const endorsementCount = this.getRatings(data.skill_id || data.issuedID);
                  return (
                    <Card key={key}>
                      <CardBody className="pb-0 px-0 customPadding d-flex">
                        <div className="d-flex justify-content-center giveFeedbackContainerStyle">
                          <FormGroup
                            className="custom-radio-wrapper form-check pl-0">
                            <Label check className="container">
                              <Input
                                className="mr-1"
                                type="radio"
                                readOnly={parseInt(data.avgRating) === 0}
                                value={(data.skill_id) ? data.skill_id : data.issuedID}
                                data-type={(data.skill_id) ? 'skill' : 'badge'}
                                data={JSON.stringify(data)}
                                starrating={JSON.stringify(this.getRatings(data.skill_id || data.issuedID))}
                                onChange={this.handleRadioChange}
                                onClick={(parseInt(data.avgRating) === 0) ? this.handleClickBtn : this.handleClickBtnReturn}
                                name="selectskill"/>
                              <span className="checkmark" />
                            </Label>
                          </FormGroup>
                          <div className="darkBlueImage mx-3">
                            <img
                              style={{ height: '60px', width: 'auto' }}
                              src={data.imageURL} />
                          </div>
                        </div>
                        <div>
                          <CardTitle tag="h5">{data.name}</CardTitle>
                          <div className="d-flex align-items-center justify-content-between">
                            <div>
                              <div id="Popover1">

                                <StarRating rating={data.avgRating} endorsementCount={parseInt(endorsementCount?.endorsementCount)} />
                              </div>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="counts">
                              <span>
                                <strong className="font14 align-text-top">
                                  {prefixWithZero(data.filesCount)}{' '}
                                </strong>
                              </span>
                              <p className="d-inline-block"> Portfolio Files</p>
                            </div>
                            <div className="counts">
                              <span>
                                <strong className="font14 align-text-top">
                                  {prefixWithZero(data.endorsementCount)}{' '}
                                </strong>
                              </span>
                              <p className="d-inline-block"> Endorsements</p>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  );
                })}
            </BadgesSkillsList>
          </div>
        </ModalBody>
        <ModalFooter className="bordertop-0">
          <button
            className="btn btn-secondary"
            onClick={this.props.onCancel}>
            Cancel
          </button>
          <Button
            className="ml-1 sendButton"
            color="warning"
            onClick={this.seeFeedback}
            disabled={this.state.selectSkillsBadge}>
            See Feedback
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

SeeFeedbackModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  userDataForSeeFeedback: PropTypes.object,
  actions: PropTypes.object.isRequired,
  skillBadgeList: PropTypes.array,
  badgeIssuedId: PropTypes.string,
  skillId: PropTypes.string,
  assertionBadge: PropTypes.object,
  user: PropTypes.object,
  setDataForSeeFeedback: PropTypes.func,
  viewFeedback: PropTypes.func,
  badgesTotalPage: PropTypes.number,
  skillsTotalPage: PropTypes.number,
};


const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getMyBadges,
      getMySkills,
      getRatings,
      getMySkillsPage,
      clearMyBadges,
      clearMySkills,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => {
  let list = [];

  if (state.myskills.data.skills && state.myskills.data.skills.length) {
    list = [...state.myskills.data.skills];
  }
  if (state.myskills.data.badges && state.myskills.data.badges.length) {
    list = [...list, ...state.myskills.data.badges];
  }

  list = [...list, ...state.myskills.data.ratings];

  return {
    skillBadgeList: list,
    skillsTotalPage: state.myskills.data.skillsTotalPage,
    badgesTotalPage: state.myskills.data.badgesTotalPage,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeeFeedbackModal);