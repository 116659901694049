import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import _isUndefined from 'lodash/isUndefined';
import _isEmpty from 'lodash/isEmpty';
import _values from 'lodash/values';
import _keys from 'lodash/keys';
import _isNull from 'lodash/isNull';
import { toastr } from 'react-redux-toastr';
import { get, includes, isUndefined } from 'lodash';

import * as profileActions from '../../redux/actions/profile';

import Validator from '../../validator';
import PrivacySettingsTab from './PrivacySettingsTab';

const privacyOptions = [
  {
    value: 0,
    text: 'Public (viewable to anyone)',
    icon: 'fa fa-globe',
  },
  {
    value: 1,
    text: 'Private (viewable only to me)',
    icon: 'fa fa-lock',
  },
  {
    value: 2,
    text: 'Followers Only',
    icon: 'fa fa-user-circle-o',
  },
  {
    value: 3,
    text: 'Following Only',
    icon: 'fa fa-user-circle',
  },
  {
    value: 4,
    text: 'Followers and Following',
    icon: 'fa fa-users',
  },
];

class Settings extends Component {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    isRequesting: PropTypes.bool,
    actions: PropTypes.object,
    userId: PropTypes.string,
    isUpdating: PropTypes.bool,
    error: PropTypes.object,
    updatedSettings: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      popoverOpen: false,
      errors: {},
      profile: {
        email: '',
        secondaryEmail: '',
        privacySettings: 0,
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        roleType: '',
        organizationType: '',
        organization: '',
        jobTitle: '',
      },
      industryRepresentativeConfiguration: {
        frequencyInWeeks: 3,
        pauseFeedbackRequests: false,
        pauseStartDate: '',
        pauseEndDate: '',
        disableFeedbackRequests: false,
      },
      originalIRSetting: {
      },
      allowUpdate: false,
      updateSettingsText: 'Save Changes',
      privacyOptions: privacyOptions,
    };

    this.togglePopover = this.togglePopover.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitChange = this.submitChange.bind(this);
    this.submitIRChange = this.submitIRChange.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.doesChangeExist = this.doesChangeExist.bind(this);
    this.focusOnErrorField = this.focusOnErrorField.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
  }

  componentDidMount() {
    const { actions, userId } = this.props;

    // handle page reload.
    if (!_isUndefined(userId)) {
      actions.profileRequest(userId).then(() => {
        Object.keys(this.state.profile).forEach((key) => {
          if (
            !_isUndefined(this.props.profile[key]) &&
            !_isNull(this.props.profile[key])
          ) {
            this.setState({
              profile: Object.assign({}, this.state.profile, {
                [key]: this.props.profile[key],
              }),
            });
          }
        });
      });

      if (this.props.profile.role === 'industry-representative') {
        actions.requestIRSettings(userId).then(() => {
          const incomingIRConfig = this.props.profile
            .industryRepresentativeConfiguration;

          const IrConfig = {
            pauseFeedbackRequests: incomingIRConfig.pauseFeedbackRequests,
            frequencyInWeeks: incomingIRConfig.frequencyInWeeks,
            pauseStartDate: get(incomingIRConfig, 'pauseStartDate.date', null),
            pauseEndDate: get(incomingIRConfig, 'pauseEndDate.date', null),
            disableFeedbackRequests: incomingIRConfig.disableFeedbackRequests === 1,
          };

          this.setState({
            industryRepresentativeConfiguration: IrConfig,
            originalIRSetting: incomingIRConfig,
          });
        });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error !== this.props.error)
      this.setState({ errors: nextProps.error });

    if(nextProps.updatedSettings){
      if (this.props.profile.role === 'industry-representative') {
        nextProps.actions.requestIRSettings(nextProps.profile.id).then(() => {
          const incomingIRConfig = this.props.profile
            .industryRepresentativeConfiguration;

          const IrConfig = {
            pauseFeedbackRequests: incomingIRConfig.pauseFeedbackRequests,
            frequencyInWeeks: incomingIRConfig.frequencyInWeeks,
            pauseStartDate: get(incomingIRConfig, 'pauseStartDate.date', null),
            pauseEndDate: get(incomingIRConfig, 'pauseEndDate.date', null),
            disableFeedbackRequests: incomingIRConfig.disableFeedbackRequests === 1,
          };
          this.setState({
            industryRepresentativeConfiguration: IrConfig,
            originalIRSetting: incomingIRConfig,
          });
        });
      }
    }
  }

  isValidIRConfig = () => {
    const industryRepresentativeConfigurationState = this.state
      .industryRepresentativeConfiguration;
    if (!industryRepresentativeConfigurationState.pauseFeedbackRequests)
      return true;

    return (
      industryRepresentativeConfigurationState.pauseFeedbackRequests &&
      industryRepresentativeConfigurationState.pauseStartDate &&
      industryRepresentativeConfigurationState.pauseEndDate
    );
  };

  isValid(field = null) {
    let rules = {
      email: ['email'],
      secondaryEmail: ['email', 'notMatch|email'],
      phoneNumber: ['phone'],
      password: ['minLength|8'],
      confirmPassword: ['match|password'],
    };

    const educatorRules = {
      roleType: ['required'],
      organizationType: ['required'],
      organization: ['required', 'minLength|1'],
      jobTitle: ['required', 'minLength|1'],
    };

    const {
      profile: { role },
    } = this.props;

    if (role === 'educator') {
      rules = Object.assign({}, rules, educatorRules);
    }

    const validate = Validator.createValidator(
      rules,
      this.state.profile,
      field
    );

    let { isValid, errors } = validate;

    this.setState({ errors });
    return isValid;
  }

  togglePopover() {
    this.setState((prevState) => {
      return { popoverOpen: !prevState.popoverOpen };
    });
  }

  onEscape(id) {
    document.getElementById(id).blur();
  }

  onKeyPress(event) {
    if (event.key === 'Enter') return this.submitChange();

    if (event.key === 'Escape') this.onEscape(event.target.id);
  }

  handleChange(event) {
    const { profile, allowUpdate } = this.state;
    if (!allowUpdate)
      this.setState({
        allowUpdate: true,
      });

    let { target: { name, value } } = event;

    let updatedProfile;
    let updatedIRSettings;
    if (name === 'privacySettings' || name === 'frequencyInWeeks')
      value = parseInt(value);
    if (
      includes(
        [
          'frequencyInWeeks',
          'pauseFeedbackRequests',
          'pauseStartDate',
          'pauseEndDate',
          'disableFeedbackRequests',
        ],
        name
      )
    ) {
      updatedIRSettings = {
        ...this.state.industryRepresentativeConfiguration,
        pauseEndDate:
          name === 'pauseStartDate'
            ? null
            : this.state.industryRepresentativeConfiguration.pauseEndDate,
        [name]: value,
      };
      if (name === 'pauseFeedbackRequests' && !value) {
        updatedIRSettings = {
          ...updatedIRSettings,
          pauseStartDate: null,
          pauseEndDate: null,
        };
      }
    } else {
      updatedProfile = Object.assign({}, profile, { [name]: value });
    }
    if (updatedProfile) {
      this.setState({ profile: updatedProfile }, () => this.isValid(name));
    }
    if (updatedIRSettings) {
      this.setState(
        { industryRepresentativeConfiguration: updatedIRSettings },
        () => this.isValid(name)
      );
    }
  }

  submitIRChange() {
    if (!this.isValidIRConfig())
      return this.setState(
        {
          allowUpdate: false,
        },
        () => toastr.warning('Please select from and to dates to pause requests')
      );

    // If all's valid then check if values have changed
    const { status, updateObj } = this.doesIRChangeExist();
    let updatedIRData = updateObj;
    if (!status)
      return this.setState(
        {
          allowUpdate: false,
        },
        () => toastr.warning('All values are unchanged')
      );
    this.setState({updateSettingsText: 'Saving...'});

    const { actions, userId } = this.props;
    updatedIRData = {
      industryRepresentativeConfiguration: this.state
        .industryRepresentativeConfiguration,
    };

    if(this.state
      .industryRepresentativeConfiguration.pauseStartDate){
      updatedIRData = {
        industryRepresentativeConfiguration: {...this.state
          .industryRepresentativeConfiguration,
        pauseStartDate: `${this.state
          .industryRepresentativeConfiguration.pauseStartDate.split(' ')[0]} 00:00:00`,
        pauseEndDate: `${this.state
          .industryRepresentativeConfiguration.pauseEndDate.split(' ')[0]} 23:59:59`
        }
      };
    }

    actions.updateIRSettings(updatedIRData).then(() => {
      localStorage.setItem('IRSettingsUpdated', 'true');
      this.setState({updateSettingsText: 'Save changes'});
      this.handleResponse();
      actions.requestIRSettings(userId);
    });
  }

  submitChange() {
    // timeout so that errors{} isn't received as an empty object here.
    if (!this.isValid())
      return setTimeout(() => {
        this.focusOnErrorField(_keys(this.state.errors)[0]);
      }, 100);

    // If all's valid then check if values have changed
    const { status, updateObj } = this.doesChangeExist();

    if (!status)
      return this.setState(
        {
          allowUpdate: false,
        },
        () => toastr.warning('All values are unchanged')
      );

    const { actions, userId } = this.props;

    actions.updateSettings(updateObj, userId).then(() => this.handleResponse());
  }

  handleResponse() {
    if (_isEmpty(this.props.error)) return this.initializeState();

    const obj = this.props.error.validation_messages;

    _keys(obj)
      .reverse()
      .forEach((item) => {
        this.setState({
          errors: Object.assign({}, this.state.errors, {
            [item]: _values(obj[item])[0],
          }),
        });
      });

    this.focusOnErrorField(_keys(obj)[0]);
  }

  focusOnErrorField(id) {
    document.getElementById(id).focus();
  }

  initializeState() {
    const { profile } = this.state;

    this.setState({
      profile: Object.assign({}, profile, {
        password: '',
        confirmPassword: '',
      }),
      allowUpdate: false,
    });

    toastr.success('Settings updated');
  }

  doesIRChangeExist = () => {
    const { industryRepresentativeConfiguration: updatedIRconfig, originalIRSetting } = this.state;

    const { profile } = this.props;
    let IRConfig = profile.industryRepresentativeConfiguration;
    if(isUndefined(IRConfig)){
      IRConfig = originalIRSetting;
    }

    let updateObj = {};
    Object.keys(updatedIRconfig).forEach((key) => {
      if (key === 'pauseEndDate' || key === 'pauseStartDate') {
        if (IRConfig[key] !== updatedIRconfig[key]) {
          if (IRConfig[key] && IRConfig[key].date) {
            if (IRConfig[key].date !== updatedIRconfig[key]) {
              const incomingDate =
                IRConfig[key] && IRConfig[key].date
                  ? IRConfig[key].date.split(' ')[0]
                  : IRConfig[key];
              const updatedDate = updatedIRconfig[key]
                ? updatedIRconfig[key].split('T')[0]
                : updatedIRconfig[key];
              if (incomingDate !== updatedDate)
                updateObj[key] = updatedIRconfig[key];
            }
          }
          if (IRConfig[key] === null) {
            updateObj[key] = updatedIRconfig[key];
          }
        }
      } else if (key === 'disableFeedbackRequests') {
        const disableFeedbackReqValue = updatedIRconfig[key] ? 1 : 0;
        if (IRConfig && IRConfig[key] !== disableFeedbackReqValue)
          updateObj[key] = disableFeedbackReqValue;
      } else if (
        key === 'frequencyInWeeks' &&
        IRConfig &&
        !_isUndefined(IRConfig[key]) &&
        updatedIRconfig[key] !== IRConfig[key]
      )
        updateObj[key] = updatedIRconfig[key];
    });

    if (_isEmpty(updateObj)) {
      return { status: false, updateObj };
    }

    return { status: true, updateObj };
  };

  doesChangeExist() {
    const { profile: updatedProfile } = this.state;

    const { profile } = this.props;

    let updateObj = {};

    Object.keys(updatedProfile).forEach((key) => {
      if (!_isUndefined(profile[key]) && updatedProfile[key] !== profile[key])
        updateObj[key] = updatedProfile[key];

      if (key === 'password' && updatedProfile[key] !== '')
        updateObj[key] = updatedProfile[key];
    });

    if (_isEmpty(updateObj)) {
      return { status: false, updateObj };
    }

    return { status: true, updateObj };
  }

  render() {
    const { profile, isRequesting, isUpdating } = this.props;

    const {
      popoverOpen,
      errors,
      profile: profileState,
      allowUpdate,
      updateSettingsText,
      privacyOptions,
      industryRepresentativeConfiguration,
    } = this.state;

    return (
      <div>
        <Helmet title="Settings" />

        <div className="container privacySettingsContainerStyle">
          <div className="row">
            <div className="col-lg-12 content settings">
              <div className="row">
                <div className="col-lg-12">
                  <h2>Settings & Privacy</h2>
                </div>

                <div className="col-lg-12">
                  <PrivacySettingsTab
                    profile={profile}
                    profileState={profileState}
                    industryRepresentativeConfigurationState={
                      industryRepresentativeConfiguration
                    }
                    errors={errors}
                    handleChange={this.handleChange}
                    isUpdating={isUpdating}
                    onKeyPress={this.onKeyPress}
                    togglePopover={this.togglePopover}
                    popoverOpen={popoverOpen}
                    allowUpdate={allowUpdate}
                    submitChange={this.submitChange}
                    submitIRChange={this.submitIRChange}
                    isRequesting={isRequesting}
                    updateSettingsText={updateSettingsText}
                    privacyOptions={privacyOptions}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.data,
    isRequesting: state.profile.isRequesting,
    userId: state.auth.data.currentUser.id,
    isUpdating: state.profile.isUpdating,
    updatedSettings: state.profile.updatedSettings,
    error: state.profile.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, profileActions);

  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
