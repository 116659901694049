import PropTypes from 'prop-types';
import React from 'react';

const WarningCard = ({
  warning,
}) => {
  return(
    <div className="essential-intro" id="warning-card">
      <div className="content">
        <div className="info">
          <div className="row">
            <h5 style={{ color: 'rgba(0,0,0,0.5)', textAlign: 'center'}}>
              {warning}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

WarningCard.propTypes = {
  warning: PropTypes.string
};

export default WarningCard;
