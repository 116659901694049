import React from 'react';

import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import PortfolioPreview from '../portfolio/PortfolioPreview';
import CloseIcon from '../../assets/images/close.svg';

const FileViewerModal = ({
  isFileModalOpen,
  closeModal,
  title,
  url,
  mimetype,
  description
}) => {
  return (
    <Modal
      size="lg"
      className="portfolio-modal"
      isOpen={isFileModalOpen}
      backdrop="static"
      toggle={closeModal}>
      <div className="portfolio-wrap modal-content">
        <ModalHeader>
          {title}
          <div className="close"
            onClick={closeModal}
            data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">
              <img src={CloseIcon} />
            </span>
          </div>
        </ModalHeader>
        <ModalBody>
          <div style={{textAlign: 'center'}}>
            <PortfolioPreview url={url} mimetype={mimetype} />
            <p>{description}</p>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

FileViewerModal.propTypes = {
  isFileModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  mimetype: PropTypes.string,
  description: PropTypes.sting
};

export default FileViewerModal;
