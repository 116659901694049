import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as componentsActions from '../../redux/actions/components';

import { Helmet } from 'react-helmet';

import Talent from '../../components/home/sections/Talent';

import { checkEmail } from '../../utils';
import { withHooks } from '../../utils/withHooks';

const hideNavLookupArray = ['invitation-password', 'signup', 'new-password', 'group-admin-invite'];

class Home extends Component {
  static propTypes = {
    modal: PropTypes.string,
    params: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      showNav: false,
      showSkillBuildersModal: false,
      animate: false,
      showSkillBadgeModal: false,
      showToolboxModal: false,
      showEPortfolioModal: false,
      showJobCenterModal: false,
      showRealisticEndorsementsModal: false,
      showSampleWebpageModal: false,
      visitedSections: []
    };

    this.toggleNav = this.toggleNav.bind(this);
    this.toggleSkillBuildersModal = this.toggleSkillBuildersModal.bind(this);
    this.toggleSkillBadgeModal = this.toggleSkillBadgeModal.bind(this);
    this.toggleToolboxModal = this.toggleToolboxModal.bind(this);
    this.toggleEPortfolioModal = this.toggleEPortfolioModal.bind(this);
    this.toggleRealisticEndorsementsModal = this.toggleRealisticEndorsementsModal.bind(this);
    this.toggleSignUpModal = this.toggleSignUpModal.bind(this);
  }

  componentWillMount() {
    const { actions, location, params: { '*': linkParams } } = this.props;
    const [,path,subpath] = location.pathname.split('/');

    if (linkParams && !(path === 'auth' && hideNavLookupArray.includes(subpath))) {
      const [token,emailId,] = linkParams && linkParams.split('/');

      if (checkEmail(emailId) && token) {
        actions.openModal('login-modal');
      }
    }

    if ( this.props.params && this.props.params['*'] ) {
      const [token,emailId,] = this.props.params['*'].split('/');
      if (checkEmail(emailId) && token) {
        actions.openModal('login-modal');
      }
    }

    if (window.localStorage.getItem('landingPath')) {
      actions.openModal('login-modal');
    }
  }

  toggleNav() {
    this.setState({
      showNav: !this.state.showNav
    });
  }

  toggleToolboxModal(){
    this.setState({
      showToolboxModal: !this.state.showToolboxModal
    });
  }

  toggleSkillBuildersModal(){
    this.setState({
      showSkillBuildersModal: !this.state.showSkillBuildersModal
    });
  }

  toggleSkillBadgeModal(){
    this.setState({
      showSkillBadgeModal: !this.state.showSkillBadgeModal
    });
  }

  toggleEPortfolioModal(){
    this.setState({
      showEPortfolioModal: !this.state.showEPortfolioModal
    });
  }

  toggleRealisticEndorsementsModal(){
    this.setState({
      showRealisticEndorsementsModal: !this.state.showRealisticEndorsementsModal
    });
  }

  toggleSignUpModal(event) {
    event.preventDefault();

    if (this.props.modal === 'signup-modal') {
      this.props.actions.closeModal();
      return;
    }

    this.props.actions.openModal('signup-modal');
  }

  render() {
    return (
      <div className="non-logged-homepage">
        <Helmet title="Home"/>

        <Talent toggleSignUpModal={this.toggleSignUpModal} />
      </div>
    );
  }
}

const mapStateToProps = ({ components: { modal } }) => ({ modal });

const mapDispatchToProps = dispatch =>
  ({ actions: bindActionCreators(componentsActions, dispatch)});

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(Home));
