import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';
import LazyLoadImage from '../../containers/common/lazy-load-image/LazyLoadImage';

const SharedInterviewVideoPost = ({ quote, video }) => {
  const { id, title, thumbnail } = video;

  return (
    <div className="shared-item">
      <p className="quote">{quote}</p>
      <Link to={`/toolbox/interview-prep-videos/${id}`}>
        <div className="shared-item-container">
          <div className="shared-item-image video-interview">
            <LazyLoadImage
              alt={`Shared interview video - ${title}`}
              srcLoaded={thumbnail}/>
          </div>
          <div className="shared-item-description">
            <h6>{title}</h6>
            <div className="description">
              <p>Click to see details for this Interview Prep Video.</p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

SharedInterviewVideoPost.propTypes = {
  video: PropTypes.object.isRequired,
  quote: PropTypes.string
};

export default SharedInterviewVideoPost;
