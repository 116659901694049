import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import classNames from 'classnames';
import { isEmpty, isEqual } from 'lodash';

import * as routerActions from '../../redux/actions/router';

class ClaimBadgeButton extends Component {
  static propTypes = {
    isIssuingBadge: PropTypes.bool,
    badge: PropTypes.object,
    isValidClaimBadge: PropTypes.func.isRequired,
    claimBadge: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    const isValid = this.props.isValidClaimBadge();

    this.state = {
      isValid
    };
  }

  componentWillReceiveProps(nextProps) {
    if (! isEmpty(nextProps.badge) && (! isEqual(nextProps.badge, this.props.badge))) {
      const isValid = this.props.isValidClaimBadge();

      if (isValid) {
        this.props.actions.removeRouterHookedToARoute();
      }

      this.setState({ isValid });
    }
  }

  render() {
    const buttonClassName = classNames('btn btn-primary btn-xlg',
      { 'claimed-badge-button': this.props.badge && this.props.badge.isClaimed }
    );

    let buttonText = 'Claim Badge';

    if (this.props.badge && this.props.badge.isClaimed) {
      buttonText = 'Claimed';
    }

    if (this.props.isIssuingBadge) {
      buttonText = 'Claiming Badge...';
    }

    return (
      <div className="mt-2 text-center">
        <button
          style={{marginTop:20}}
          type="text"
          disabled={! this.state.isValid}
          className={buttonClassName}
          onClick={this.props.claimBadge}>
          {buttonText}
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    badge: state.skillBuilders.unit.badge,
    isIssuingBadge: state.badges.isIssuingBadge.status
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(routerActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ClaimBadgeButton);
