import PropTypes from 'prop-types';
import React from 'react';
import CpHtmlParser from '../../common/CpHtmlParser';

const styles = {
  editButton: {
    marginLeft: 10,
    cursor: 'pointer',
  }
};

const ClubCard = ({club, onEditClubClick, isPublicProfile}) => {
  return (
    <div className="card-block">
      <div className="activities">
        <div className="row">
          <div className="col-lg-12">
            <div className="display">
              <div className="item">
                <div className="title">{club.name}
                  {! isPublicProfile ?
                    <i
                      style={styles.editButton}
                      onClick={onEditClubClick}
                      id={`update-club-form-${club.id}`}
                      className="fa fa-xs fa-pencil orange" aria-hidden="true"/> : ''}</div>
                <div className="description">
                  <div className="ql-editor no-padding-in-quill">
                    <CpHtmlParser htmlString={club.description}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ClubCard.propTypes = {
  club: PropTypes.object.isRequired,
  isPublicProfile: PropTypes.bool.isRequired,
  onEditClubClick: PropTypes.func.isRequired
};

export default ClubCard;
