import PropTypes from 'prop-types';
import React from 'react';

const LoadedAttachment = ({
  mimetype,
  imagePreviewUrl,
  imagePreviewText,
  onDeleteLoadedAttachment
}) => {
  const docFile = mimetype && mimetype.includes('application') || mimetype.includes('text');
  const previewImage = mimetype && mimetype.includes('image') ? imagePreviewUrl : null;
  const showTruncated = imagePreviewText.length > 25;
  const splittedImagePreviewText = imagePreviewText.split('.');
  const extension = splittedImagePreviewText[splittedImagePreviewText.length - 1];
  const fileName = splittedImagePreviewText.slice(0, -1).join('.');

  const content = docFile ?
    (<div className="loaded-image-container file-icon-container">
      <i className="fa fa-file-text-o"/>
      <span className="loaded-filename doc-file">
        <span className="name">
          {
            showTruncated ?
              `${fileName.substring(0, 15)}...${fileName.substring(fileName.length-8, fileName.length)}.${extension}`:
              imagePreviewText
          }
        </span>
      </span>
    </div>) :
    (<span className="loaded-image-container">
      <img
        className="loaded-image"
        src={previewImage}/>

      <span className="loaded-filename doc-file">
        <span className="name">
          {
            showTruncated ?
              `${fileName.substring(0, 15)}...${fileName.substring(fileName.length-8, fileName.length)}.${extension}`:
              imagePreviewText
          }
        </span>
      </span>
    </span>);

  return(
    <div className="portfolio-file-selector-container loaded-file">
      {content}
      <span
        onClick={onDeleteLoadedAttachment}
        className="btn">
        <i className="fa fa-trash text-danger upload-icon"/>
      </span>
    </div>
  );
};

LoadedAttachment.propTypes = {
  onDeleteLoadedAttachment: PropTypes.func,
  imagePreviewText: PropTypes.string,
  imagePreviewUrl: PropTypes.string,
  mimetype: PropTypes.string
};

export default LoadedAttachment;
