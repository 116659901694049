import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Tooltip } from 'reactstrap';
import QuillEditor from '../../components/common/QuillEditor';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import classNames from 'classnames';
import { has, pick } from 'lodash';
import Validator from '../../validator';

import Button from '../../components/common/Button';

import * as skillBuildersActions from '../../redux/actions/skill-builders';
import * as badgeAssertionActions from '../../redux/actions/badge-assertion';

const modules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'align': [] }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    ['link'],
  ],
  clipboard: {
    matchVisual: false,
  }
};

class JustificationAnswerForm extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    justification: PropTypes.object.isRequired,
    badgeAssertion: PropTypes.object.isRequired,
    onCancelEditAnswer: PropTypes.func.isRequired,
    isUpdatingJustificationAnswer: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      editorPlainText: { justification: '' },
      guidelinesTooltipOpen: false,
      justification: Object.assign({}, this.props.justification)
    };

    this.onSave = this.onSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleGuidelinesTooltip = this.toggleGuidelinesTooltip.bind(this);
  }

  isValid(field = null) {
    const rules = {
      justification: ['required', 'minWords|50', `maxWords|${this.props.justification.wordLimit}`]
    };
    const { editorPlainText } = this.state;

    const validate = Validator.createValidator(rules, editorPlainText, field);
    const { errors, isValid } = validate;

    this.setState({ errors });

    return isValid;
  }

  toggleGuidelinesTooltip() {
    this.setState({
      guidelinesTooltipOpen: !this.state.guidelinesTooltipOpen
    });
  }

  handleChange(value, ...params) {
    const editorPlainText = params[2].getText();

    const justification = Object.assign({}, this.state.justification, {
      answer: value
    });

    const justificationObject = { justification: editorPlainText };
    this.setState({ editorPlainText: justificationObject, justification }, () => this.isValid('justification'));
  }

  onSave() {
    const { justification } = this.state;
    const index = this.props.badgeAssertion.data.justifications.findIndex(justification =>
      justification.id === this.state.justification.id);

    const data = pick(justification, ['answer']);

    this.props.actions.updateJustificationAnswer(justification.id, index, data)
      .then(() => {
        this.props.onCancelEditAnswer();
      });
  }

  render() {
    const { errors, guidelinesTooltipOpen, justification } = this.state;
    const { isUpdatingJustificationAnswer } = this.props;
    const { question, answer, questionNumber, wordLimit } = justification;
    const pointerStyle = {
      color: '#ff5000',
      cursor: 'pointer'
    };

    return (
      <div className="claim-item" style={{marginBottom: 20}}>
        <strong>Justify Your Skill Claim</strong> <span className="small text-danger"/>
        <p className="mb-2">
          If you have a skill, you're expected to be able to articulate your skill and share stories of using it to achieve positive results. Employers assess your skill claims via interviews. Thus, to justify your skill claim which this Skill Badge represents, provide quality responses to the questions below. They'll be shown in your Skill Badge and <b>publicly visible</b>. Ensure your writing is free of typos.&nbsp;
          <a style={pointerStyle} id="supporting-statement-guidelines">(See guidelines)</a><br/><br/>
          <i>*Note: If you are unable to answer the questions, it may be a sign you haven't acquired the skill, so it may be too early to claim your skill badge.</i>
          <Tooltip
            placement="top"
            isOpen={guidelinesTooltipOpen}
            target="supporting-statement-guidelines"
            toggle={this.toggleGuidelinesTooltip}>
            <div className="inside-tooltip" style={{fontSize: 12}}>
              <b>Guidelines for Written Justifications</b>
              <ul style={{padding: '10px 0px 20px 20px', textAlign: 'left', marginBottom: 0}}>
                <li>Write in the first-person voice (I, Me, My)</li>
                <li>Be specific when sharing examples of how you've applied your claimed abilities in your experience</li>
                <li>Clearly explain how your examples provide evidence of your claimed abilities.</li>
                <li>Be concise but include all relevant details</li>
                <li>Don’t share confidential or proprietary information</li>
                <li>Don’t have grammatical or spelling errors</li>
              </ul>
            </div>
          </Tooltip>
        </p>
        <div style={{marginBottom: (questionNumber !== 2) ? 10 : 0}}>
          <span style={{fontWeight: 'bold'}}>
            {question}
          </span>
          <span> ({wordLimit} words max)</span>
        </div>
        {(questionNumber === 2) && <div>
          Ensure your answer addresses all three parts.
          <ul>
            <li>Describe the situation and/or task.</li>
            <li>Describe the action(s) you took that demonstrate your skill.</li>
            <li>Describe the positive result(s) achieved.</li>
          </ul>
        </div>}
        <div className={classNames('statement-form-container', {
          'has-danger': errors.justification
        })}>
          <QuillEditor
            value={answer}
            onChange={this.handleChange}
            modules={modules} />

          {errors.justification && <div className="text-danger">{errors.justification}</div>}
        </div>
        <div
          style={{textAlign: 'right', marginTop: 10}}
          className="clearfix">
          <Button
            onClick={this.props.onCancelEditAnswer}
            styles={{marginLeft: 0}}
            buttonClass="btn-secondary float-left"
            buttonText="Cancel"
            disabled={isUpdatingJustificationAnswer.status}/>
          <Button
            onClick={this.onSave}
            buttonClass="btn-primary"
            buttonText={isUpdatingJustificationAnswer.status ? 'Saving...' : 'Save'}
            disabled={(isUpdatingJustificationAnswer.status || (has(errors, 'justification')))}/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { badgeAssertion } = state;

  return {
    isUpdatingJustificationAnswer: badgeAssertion.isUpdatingJustificationAnswer
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({},
    skillBuildersActions,
    badgeAssertionActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(JustificationAnswerForm);
