import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { parseJSON } from '../../../utils';

import AdminSectionHeader from '../admin/AdminSectionHeader';
import { Collapse } from 'reactstrap';
import ReadSpeakerButton from '../../common/ReadSpeakerButton';
import Parser from 'html-react-parser';

const DropdownSection = ({
  drag,
  drop,
  preview,
  onWorkValuesSeeExamples,
  onPersonalityTraitsSeeExamples,
  unit,
  section,
  hover,
  isAdmin,
  onEditClick,
  toggleBulletSectionAccordion,
  bulletSectionCollapse
}) => {
  const data = parseJSON(section.content);
  const dropDownSectionId = `dropdown-section-${section.id}`;
  const url = window.location.href;
  const encodedUrl = encodeURIComponent(url);
  const speakerId = `read-speaker-${dropDownSectionId}`;
  const { title, content, feedback } = data;

  return (
    <section className="essential-collapse" id={dropDownSectionId} ref={preview}>
      {isAdmin &&
        <div style={{ marginTop: -20 }}>
          <AdminSectionHeader
            hover={hover}
            drag={drag}
            drop={drop}
            title="Dropdown list"
            sectionId={section.id}
            onEditClick={onEditClick}
            componentName={dropDownSectionId}
            headerStyles={{ paddingTop: 20, paddingBottom: 10 }}/>
        </div>}

      <div className="section-icon">
        <svg viewBox="0 0 120 100">
          <path d="M38,2 L82,2 A12,12 0 0,1 94,10 L112,44 A12,12 0 0,1 112,56 L94,90 A12,12 0 0,1 82,98 L38,98 A12,12 0 0,1 26,90 L8,56 A12,12 0 0,1 8,44 L26,10 A12,12 0 0,1 38,2" />
        </svg>
        <div className="icon"><div className="ico-collapse"/></div>
      </div>

      <ReadSpeakerButton sectionClass={dropDownSectionId} encodedUrl={encodedUrl}/>

      <div id={speakerId}>
        <h4>{title}:</h4>

        <div id="accordion" role="tablist" aria-multiselectable="true">
          {content.map((item, index)=>{
            const buttonStyle = classNames('show-pointer', {
              'collapsed': !bulletSectionCollapse[index]
            });

            let itemFeedback = feedback[index];

            const searchAndReplaceLink = unit.slug === 'create-a-career-path' || unit.slug === 'develop-self-awareness';

            const replacePersonalityTraitModal = searchAndReplaceLink &&
              item.search(new RegExp('Self-Reflection', 'i')) > -1;

            const replaceSeeExampesModal = searchAndReplaceLink && item.search(new RegExp('Core Work Values', 'i')) > -1;

            const modalButton = <span onClick={onPersonalityTraitsSeeExamples} className="clickable orange link">See examples of personality traits</span>;

            const workValuesModalButton = <span onClick={onWorkValuesSeeExamples} className="clickable orange link">See examples of work values</span>;

            if(replacePersonalityTraitModal){
              itemFeedback = itemFeedback.replace('<a href="https://www.maxknowledge.com" target="_blank">See examples of personality traits</a>.', '');
              itemFeedback = itemFeedback.replace('<a href="https://www.maxknowledge.com/" target="_blank">See examples of personality traits</a>.', '');
            }

            if(replaceSeeExampesModal){
              itemFeedback = itemFeedback.replace('<a href="https://www.maxknowledge.com" target="_blank">See examples of work values</a>.', '');
              itemFeedback = itemFeedback.replace('<a href="https://www.maxknowledge.com/" target="_blank">See examples of work values</a>.', '');
            }

            return(
              <div className="card" key={index}>
                <div className="card-header" role="tab" id="headingOne">
                  <div
                    onClick={toggleBulletSectionAccordion(index)}
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    className={buttonStyle}>
                    {item}
                  </div>
                </div>

                <Collapse isOpen={bulletSectionCollapse[index]}>
                  <div className={classNames('card-block', {
                    'inline-block': replacePersonalityTraitModal || replaceSeeExampesModal
                  })}>
                    {Parser(itemFeedback)}
                    {replacePersonalityTraitModal && modalButton}
                    {replaceSeeExampesModal && workValuesModalButton}
                    {(replaceSeeExampesModal || replacePersonalityTraitModal) && <span>.</span>}
                  </div>
                </Collapse>
              </div>
            );
          })}
        </div>
      </div>

      <div id={`list-${speakerId}`} style={{display: 'none'}}>
        <h4 className={dropDownSectionId}>{title}</h4>
        <div>
          {content.map((item,index)=>{
            return(
              <div key={index} className={dropDownSectionId}>
                <b>{item}</b>
                {Parser(feedback[index])}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

DropdownSection.propTypes = {
  hover: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  section: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
  bulletSectionCollapse: PropTypes.object.isRequired,
  toggleBulletSectionAccordion: PropTypes.func.isRequired,
  unit: PropTypes.object,
  onWorkValuesSeeExamples: PropTypes.func,
  onPersonalityTraitsSeeExamples: PropTypes.func,
  drag: PropTypes.func.isRequired,
  drop: PropTypes.func.isRequired,
  preview: PropTypes.func.isRequired
};

export default DropdownSection;
