import PropTypes from 'prop-types';
import React from 'react';
import ActivitySort from '../../common/ActivitySort';
import PostCardRenderer from './PostCardRenderer';

const PostList = (props) => {
  return (
    <div>
      {
        props.discussions.length >= 2 &&
          <ActivitySort
            activeSort={props.activeSort}
            isSortByOpen={props.isSortByOpen}
            toggleSortBy={props.toggleSortBy}
            handleBeforeSorting={props.handleBeforeSorting}
            isAuthenticated={props.isAuthenticated}/>
      }

      <PostCardRenderer {...props}/>
    </div>
  );
};

PostList.propTypes = {
  unit: PropTypes.object.isRequired,
  activeSort: PropTypes.string,
  discussions: PropTypes.array.isRequired,
  discussionShare: PropTypes.object.isRequired,
  isSortByOpen: PropTypes.bool.isRequired,
  toggleSortBy: PropTypes.func.isRequired,
  handleBeforeSorting: PropTypes.func,
  callingAPI: PropTypes.bool,
  isAuthenticated: PropTypes.bool
};

export default PostList;
