import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';
import _isObject from 'lodash/isObject';
import { toastr } from 'react-redux-toastr';

import * as followerActions from '../../../redux/actions/followers';
import * as followingActions from '../../../redux/actions/following';
import * as messageActions from '../../../redux/actions/messages';
import * as profileActions from '../../../redux/actions/profile';
import FollowingCard from '../../../components/profile/connects/FollowingCard';

class Following extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    profile: PropTypes.object,
    isSubmitting: PropTypes.bool,
    message: PropTypes.object,
    isRequesting: PropTypes.bool,
    isDeleting: PropTypes.object,
    selectFollower: PropTypes.func,
    selected: PropTypes.array.isRequired
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      error: {},
      modalOpen: false,
      message: Object.assign({}, props.message),
      dropdownOpen: false,
      isSortByOpen: false,
      isCheckedUser: false
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.onUnfollowClick = this.onUnfollowClick.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleSortBy = this.toggleSortBy.bind(this);
    this.onHideClick = this.onHideClick.bind(this);
    this.onBlockClick = this.onBlockClick.bind(this);
    this.onReportClick = this.onReportClick.bind(this);
    this.checkUser = this.checkUser.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { selected, profile } = nextProps;
    if (selected.find(item => item === profile.user.id)) {
      this.setState({
        isCheckedUser: true,
      });
    } else {
      this.setState({
        isCheckedUser: false,
      });
    }
  }

  checkUser() {
    const { selectFollower, profile } = this.props;

    if (!this.state.isCheckedUser) {
      selectFollower(profile.user.id, true);
    } else {
      selectFollower(profile.user.id, false);
    }
  }

  onReportClick() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  onHideClick() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  onBlockClick() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  toggleDropdown() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  toggleSortBy() {
    this.setState({ isSortByOpen: !this.state.isSortByOpen });
  }

  toggleModal() {
    const message = { recipients: '', subject: '', body: '' };
    const { profile } = this.props; 
   
    if(profile?.user?.privateMessagingDisabled){
      toastr.info('', 'The organizational admin has disabled private messaging to this user');
      return;
    }

    this.setState({
      message,
      modalOpen: !this.state.modalOpen
    });
  }

  onMessageChange(event) {
    const field = event.target.name;
    const { message } = this.state;

    message[field] = event.target.value;

    this.setState({ message });

    this.isValid(message);

  }

  isValid(value) {
    const { error } = this.state;

    this.setState({ error: {} });

    if (!value.body.length > 0) {
      error['body'] = 'Message cannot be empty';
      this.setState({ error });
    }
    if (!value.subject.length > 0) {
      error['subject'] = 'Subject cannot be empty';
      this.setState({ error });
    }
    return (value.subject.length > 0) && (value.body.length > 0);
  }

  onMessageSubmit() {
    const { profile } = this.props;
    const { message } = this.state;


    if (!this.isValid(message)) return;

    message.recipients = [profile.user.id];
    this.props.actions.sendMessage(message)
      .then(() => this.handleResponse());
  }

  handleResponse() {
    const message = { recipients: [], subject: '', body: '' };

    this.setState({message});
    this.toggleModal();
  }

  onUnfollowClick(recordId) {
    return () => {
      const { actions } = this.props;
      actions.deleteFollowing(recordId)
        .then(() => {
          actions.decrementFollowingCount();
          this.setState({ dropdownOpen: !this.state.dropdownOpen });
        });
    };
  }

  render() {
    const { profile, isSubmitting, isDeleting } = this.props;
    const {
      dropdownOpen, isSortByOpen, isCheckedUser, modalOpen, error,
      message
    } = this.state;

    return (
      <div className="follow-list-item">
        {
          _isObject(profile) &&
            <FollowingCard
              isUserFollower
              profile={profile.user}
              recordId={profile.record}
              isCheckedUser={isCheckedUser}
              checkUser={this.checkUser}
              onReportClick={this.onReportClick}
              onHideClick={this.onHideClick}
              onBlockClick={this.onBlockClick}
              isSortByOpen={isSortByOpen}
              toggleSortBy={this.toggleSortBy}
              onUnfollowClick={this.onUnfollowClick}
              toggleDropdown={this.toggleDropdown}
              dropdownOpen={dropdownOpen}
              modalOpen={modalOpen}
              toggleModal={this.toggleModal}
              error={error}
              isSubmitting={isSubmitting}
              isDeleting={isDeleting}
              message={message}
              onMessageChange={this.onMessageChange}
              onMessageSubmit={this.onMessageSubmit} />
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const message = { recipients: '', subject: '', body: '' };

  return {
    message,
    isSubmitting: state.messages.isSubmitting,
    following: state.following
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    followerActions,
    messageActions,
    followingActions,
    profileActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Following);
