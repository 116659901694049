import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as filesActions from '../../redux/actions/files';
import TextArea from '../../components/common/TextArea';

class FileCommentReplyForm extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    avatar: PropTypes.string,
    name: PropTypes.string,
    replyData: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    isSubmitting: PropTypes.object.isRequired,
    fileId: PropTypes.string,
    parentId: PropTypes.string
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      replyData: Object.assign({}, this.props.replyData)
    };

    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  onChange(e){
    const { value, name } = e.target;

    const replyData = this.state.replyData;

    replyData[name] = value;

    this.setState({
      replyData
    });
  }

  onSave(){
    const { replyData } = this.state;
    const { fileId, parentId, actions, onCancel } = this.props;

    const data = {
      'comment': replyData.comment,
      'file': fileId,
      'parent': parentId
    };

    actions.addCommentToFile(data, 'reply').
      then(() => onCancel());
  }

  render() {
    const { replyData } = this.state;
    const { avatar, onCancel, isSubmitting, parentId, name } = this.props;
    const isSubmittingReply = isSubmitting.status && isSubmitting.commentId === parentId;

    return (
      <div className="tertiary-post-comment clearfix">
        <div className="left">

          {
            avatar && avatar !== '' ?
              <img 
                className="header-image small align-top" 
                src={avatar} 
                alt="User avatar"/>:
              <span 
                style={{
                  lineHeight: '24px'
                }}
                className="header-image small align-top initial-avatar">
                {name.slice(0,1)}
              </span>
          }
        </div>

        <div className="right">
          <div className="form-group post-comment mb-2">
            <div className="input-group">
              <TextArea
                className="form-control"
                name="comment"
                placeholder={'Write your reply...'}
                value={replyData.comment}
                rows="3"
                onChange={this.onChange}
                autofocus />
            </div>
          </div>
          <div className="share-stats d-flex justify-content-end mt-2 mb-2">
            <button 
              onClick={onCancel}
              disabled={isSubmittingReply}
              className="btn btn-secondary">
              Cancel
            </button>
            <button 
              onClick={this.onSave}
              disabled={isSubmittingReply}
              className="btn btn-primary ml-2">
              {isSubmittingReply ? 'Replying': 'Reply'}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const replyData = {
    'comment': ''
  };
  return {
    name: state.profile.data.name,
    avatar: state.profile.data.avatar,
    isSubmitting: state.files.unitFileComments.isSubmitting,
    replyData
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, filesActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileCommentReplyForm);
