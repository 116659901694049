export const NEWEST = 'Newest';
export const SKILL = 'skill';
export const BADGE = 'badge';
export const SKILLS = 'Skills';
export const BADGES = 'Badges';
export const OLDEST = 'Oldest';
export const SKILL_A_Z = 'Name (A-Z)';
export const SKILL_Z_A = 'Name (Z-A)';
export const RATING_HIGH = 'Star Rating (High to Low)';
export const RATING_LOW = 'Star Rating (Low to High)';
export const ALL_SKILLS = 'All Skills';
export const BOTH_SKILLS = 'Both Skills';
export const ADD_SKILLS = '+ Add Skills';
export const SHOW_MORE = 'Show More';

export const ADD_SKILLS_HEADER = 'Add Other Skills You Have';

export const EMPTY_SYMBOL = [
  'fa fa-star-o fa-lg orange',
  'fa fa-star-o fa-lg orange',
  'fa fa-star-o fa-lg orange',
  'fa fa-star-o fa-lg orange',
  'fa fa-star-o fa-lg orange',
];
export const FULL_SYMBOL = [
  'fa fa-star fa-lg orange',
  'fa fa-star fa-lg orange',
  'fa fa-star fa-lg orange',
  'fa fa-star fa-lg orange',
  'fa fa-star fa-lg orange',
];

export const ADD_SKILLS_MESSAGE = 'Congrats on adding your skill(s). Each skill is also shown on your Career Site in your "Skills" section. Now, add evidence and level up each skill!';
export const SUGGEST_SKILLS_MESSAGE = 'Your skill suggestion was successfully submitted. It will be reviewed and be considered for our growing skills database';
