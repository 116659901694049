import React from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { Container, Row, FormGroup, Input } from 'reactstrap';

import { selectIsRecentEducatorAccount } from '../../../redux/selectors/profile';

const styles = {
  groupNameLabel: {
    position: 'absolute',
    top: 30,
    left: 51,
    right: 40
  },

  groupNameInput: {
    marginTop: 50
  },

  addMoreCard: {
    border: '1px solid #dedede',
    boxShadow: '0px 0px 5px #00000033',
    WebkitBoxShadow: '0px 0px 5px #00000033',
    MozBoxShadow: '0px 0px 5px #00000033',
    msBoxShadow: '0px 0px 5px #00000033',
    OBoxShadow: '0px 0px 5px #00000033',
    minHeight:100
  },

  addMoreCardHeader: {
    color: '#fff',
    backgroundColor: '#999',
    borderBottom: '1px solid #999'
  },

  addMoreCardBlock: {
    color: '#999',
    cursor: 'pointer',
    paddingTop: 40
  },

  addMorePlusIcon: {
    color: '#ff5000'
  },

  addMoreCardText: {
    fontSize: 12,
    marginBottom: 5
  }
};

function EducatorGroupCardsList(props) {
  const {
    onChangeGroupName,
    groupsState,
    groupsData,
    onCardRemove,
    onCardAdd,
    isRecentEducatorAccount
  } = props;

  return (
    <Row>
      {(groupsState.length !== 0) &&
        <div className="col-md-12">
          <h3 className="box-title">
            Name New Group(s)
          </h3>
        </div>}

      {groupsState.map((obj, index) => {
        return (
          <div className="col-md-6" key={index}>
            <div className="box-wrapper" style={{minHeight: 210}}>
              <Container>
                <FormGroup>
                  <label style={styles.groupNameLabel}>
                    Group Name
                    {(!isRecentEducatorAccount) &&
                      <a
                        id={obj.id}
                        href="#"
                        onClick={onCardRemove}
                        className="float-sm-right">x</a>}
                  </label>
                  <Input
                    type="text"
                    name={`${obj.id}`}
                    value={obj.name}
                    onChange={onChangeGroupName}
                    className="form-control"
                    placeholder="Enter group name"
                    style={styles.groupNameInput} />

                  {obj.nameErrors &&
                    <small className="text-danger">
                      Group name cannot not be empty
                    </small>}

                </FormGroup>
              </Container>
            </div>
          </div>
        );
      })}

      {(!isRecentEducatorAccount) && ((groupsState.length + groupsData.length) < 10) &&
        <div className="col-md-6">
          <div className="card height-hack" style={styles.addMoreCard}>
            <div className="card-header" style={styles.addMoreCardHeader}>
              Need to add another group?
            </div>
            <div
              className="card-block text-center"
              style={styles.addMoreCardBlock}
              onClick={onCardAdd}>
              <i className="fas fa-users fa-2x"/>
              <p className="card-text" style={styles.addMoreCardText}>Click to add another</p>
              <i className="fas fa-plus-circle" style={styles.addMorePlusIcon}/>
            </div>
          </div>
        </div>}
    </Row>
  );
}

EducatorGroupCardsList.propTypes = {
  role: PropTypes.string.isRequired,
  onCardAdd: PropTypes.func.isRequired,
  onCardRemove: PropTypes.func.isRequired,
  groupsState: PropTypes.array.isRequired,
  groupsData: PropTypes.array.isRequired,
  onChangeGroupName: PropTypes.func.isRequired,
  onChangeAdminEmails: PropTypes.func.isRequired,
  isRecentEducatorAccount: PropTypes.bool.isRequired
};

const mapStateToProps = state =>
  ({ isRecentEducatorAccount: selectIsRecentEducatorAccount(state) });

export default connect(mapStateToProps)(EducatorGroupCardsList);
