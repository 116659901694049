import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Validator from '../../../validator';

import * as awardActions from '../../../redux/actions/awards';
import * as componentsActions from '../../../redux/actions/components';

import Loading from '../../../components/common/Loading';
import AwardForm from '../../../components/profile/forms/AwardForm';
import AwardList from '../../../components/profile/sections/AwardList';
import ConfirmModal from '../../../components/portfolio/ConfirmModal';
import ProfileCardHeader from '../../../components/profile/header/ProfileCardHeader';
import formWithModal from '../../../components/profile/modal/formWithModal';

class Award extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    award: PropTypes.object.isRequired,
    awards: PropTypes.array.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    isPublicProfile: PropTypes.bool.isRequired,
    showVisibilitySelect: PropTypes.bool,
    onChangeVisibility: PropTypes.func,
    onToggleSectionEdit: PropTypes.func,
    isEditingSections: PropTypes.bool,
    onConfirmDeleteSection: PropTypes.func,
    modal: PropTypes.string,
    isUpdatingProfile: PropTypes.bool,
    profile: PropTypes.object,
    isDeleting: PropTypes.bool,
    isUpdating: PropTypes.bool
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      isEditing: false,
      isSubmitting: false,
      editingId: null,
      award: Object.assign({}, this.props.award),
      editorText: {description: this.props.award.description}
    };

    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onDeleteSection = this.onDeleteSection.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.onEditAward = this.onEditAward.bind(this);
    this.onDeleteAward = this.onDeleteAward.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
  }

  componentDidMount() {
    const { profile } = this.props;
    this.props.actions.awardsRequest(profile.id);
  }

  isValid(field = null) {
    const rules = {
      title: ['required', 'minLength|6', 'maxLength|100'],
      issuer: ['required', 'maxLength|100'],
      url: ['unsafeurl'],
      date: ['required', 'startdate'],
    };

    const { award, errors: prevErrors } = this.state;
    const validate = Validator.createValidator(rules, award, field);
    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }
    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  isValidDescription(field = null) {
    const rules = {
      description: ['maxWords|200']
    };
    const { editorText, errors: prevErrors } = this.state;
    const validate = Validator.createValidator(rules, editorText, field);
    const { errors, isValid } = validate;

    if (field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }
    this.setState({ errors: Object.assign({}, prevErrors, errors) });

    return isValid;
  }


  onChange(event) {
    const { name, value } = event.target;
    const { award } = this.state;

    award[name] = value;

    this.setState({ award }, () => this.isValid(name));
  }

  onChangeDescription(value, ...params) {
    const { award, editorText } = this.state;

    award['description'] = value;
    editorText['description'] = params[2].getText().replace(/\r?\n|\r/gm, ' ');

    this.setState({ award, editorText }, () => this.isValidDescription('description'));
  }

  onSave(event) {
    event.preventDefault();

    this.setState({ isSubmitting: true });

    if (! this.isValid() || ! this.isValidDescription()) {
      return this.setState({ isSubmitting: false });
    }

    const { award, editingId } = this.state;
    const { actions, awards } = this.props;

    if(editingId !== null){
      const index = awards.findIndex(element =>
        element.id === award.id);

      return actions.updateAward(index, award)
        .then(() => this.handleResponse());
    }

    this.props.actions.saveAward(award)
      .then(() => this.handleResponse());
  }

  onCancel() {
    this.setState({
      isEditing: false,
      errors: {},
      editingId: null,
      award: this.props.award
    }, () => this.props.onToggleSectionEdit('award'));
  }

  onEditClick(event) {
    event.preventDefault();

    this.setState({
      isEditing: true
    }, () => this.props.onToggleSectionEdit('award'));
  }

  onDeleteSection(){
    this.props.actions.openModal('delete-webpage-award-section-modal');
  }

  onCloseModal(){
    this.props.actions.closeModal();
  }

  onDeleteConfirm(){
    const { awards, actions } = this.props;

    awards.map(award => actions.deleteAward(award.id));
    this.props.onConfirmDeleteSection('Award');
  }

  onEditAward(id){
    const { awards } = this.props;
    const awardObject = awards.find(award => award.id === id);
    const award = Object.assign({}, this.state.award, awardObject);
    this.setState({
      isEditing: true,
      award,
      editingId: id
    }, () => this.props.onToggleSectionEdit('award'));
  }

  onDeleteAward(event) {
    event.preventDefault();

    const { award } = this.state;
    this.props.actions.deleteAward(award.id)
      .then(() => this.handleResponse());
  }

  handleResponse() {
    const award = {
      title: '',
      date: '',
      url: '',
      description: '',
      issuer: ''
    };

    this.setState({
      isEditing: false,
      isSubmitting: false,
      award,
      editingId: null
    }, () => this.props.onToggleSectionEdit('award'));
  }

  EditingAward= formWithModal(AwardForm);

  render() {
    const {
      modal,
      awards,
      isRequesting,
      isPublicProfile,
      showVisibilitySelect,
      onChangeVisibility,
      isEditingSections,
      isUpdatingProfile,
      isUpdating,
      isDeleting
    } = this.props;
    const { errors, award, isEditing, isSubmitting, editingId } = this.state;

    if(awards.length === 0 && isPublicProfile){
      return null;
    }

    const isDeleteConfirmModalOpen = modal && modal === 'delete-webpage-award-section-modal';

    return (
      <div
        className="profile-item awards"
        style={{marginBottom: 15}}>
        <ProfileCardHeader
          isEditing={isEditing}
          isEditingSections={isEditingSections}
          type="list"
          title="Honors & Awards"
          isPublicProfile={isPublicProfile}
          onEditClick={this.onEditClick}
          showVisibilitySelect={showVisibilitySelect}
          onDeleteClick={this.onDeleteSection}
          onChangeVisibility={onChangeVisibility}
          icon="awards"
          isDeletable/>

        {isRequesting ?
          <Loading/> :
          isEditing ?
            <div className="profile-item__card">
              <this.EditingAward
                errors={errors}
                award={award}
                isSubmitting={isSubmitting}
                isUpdating={isUpdating}
                isDeleting={isDeleting}
                showDelete={editingId !== null}
                onChange={this.onChange}
                onSave={this.onSave}
                onCancel={this.onCancel}
                exampleType={'Honors & Awards'}
                onDelete={this.onDeleteAward}
                onChangeDescription={this.onChangeDescription}/>
            </div> :
            <AwardList
              awards={awards}
              onEdit={this.onEditAward}
              isPublicProfile={isPublicProfile}/>}

        {isDeleteConfirmModalOpen &&
          <ConfirmModal
            modalHeaderText="Delete Section"
            modalMessage="Are you sure you want to permanently delete this Career Site section?"
            modalFooterButtonText="Yes, Delete"
            onCancel={this.onCloseModal}
            onConfirm={this.onDeleteConfirm}
            isConfirming={isUpdatingProfile}
            confirminginProgressText="Deleting..."
            isOpen={isDeleteConfirmModalOpen}/>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const award = {
    title: '',
    date: '',
    url: '',
    description: '',
    issuer: ''
  };

  return {
    award,
    modal: state.components.modal,
    awards: state.awards.data,
    isUpdatingProfile: state.profile.isUpdating,
    isRequesting: state.awards.isRequesting,
    isDeleting: state.awards.isDeleting.status,
    isUpdating: state.awards.isUpdating.status
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({},
    awardActions,
    componentsActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Award);
