import PropTypes from 'prop-types';
import React from 'react';
import TextInput from '../../common/TextInput';
import TextArea from '../../common/TextArea';
import Button from '../../common/Button';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

const MessageModal = ({
  modalOpen,
  toggleModal,
  error,
  isSubmitting,
  message,
  onMessageChange,
  onMessageSubmit,
  disableSubmit
}) => {
  return (
    <Modal
      backdrop="static"
      isOpen={modalOpen}
      toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>
        Message
      </ModalHeader>
      <ModalBody>
        <TextInput
          name="subject"
          type="textarea"
          error={error.subject}
          value={message.subject}
          onChange={onMessageChange}
          placeholder="Enter subject..."/>
        <TextArea
          name="body"
          type="textarea"
          error={error.body}
          value={message.body}
          onChange={onMessageChange}
          placeholder="Enter message..."/>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={isSubmitting}
          onClick={toggleModal}
          buttonClass="btn-secondary"
          buttonText="Cancel"/>
        <Button
          disabled={disableSubmit || isSubmitting || false}
          onClick={onMessageSubmit}
          buttonClass="btn-primary"
          buttonText={isSubmitting ? 'Sending...' : 'Send'}/>
      </ModalFooter>
    </Modal>
  );
};

MessageModal.propTypes = {
  error: PropTypes.object,
  modalOpen: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  message: PropTypes.object,
  onMessageChange: PropTypes.func,
  onMessageSubmit: PropTypes.func,
  toggleModal: PropTypes.func,
  disableSubmit: PropTypes.bool,
};

export default MessageModal;
