import React from 'react';

import PropTypes from 'prop-types';

import StyleButton from './StyleButton';

const InlineStyleControls = (props) => {
  const { editorState, editorUniqueId } = props;
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <span
      className="btn-group btn-group-sm"
      role="group"
      aria-label="Inline style controls">
      {props.inlineTypes.map(type => (
        <StyleButton
          key={type.label}
          editorUniqueId={editorUniqueId}
          active={currentStyle.has(type.style)}
          label={type.label}
          icon={type.icon}
          onToggle={props.onToggle}
          style={type.style}/>
      ))}
    </span>
  );
};

InlineStyleControls.propTypes = {
  onToggle: PropTypes.func.isRequired,
  editorState: PropTypes.object.isRequired,
  editorUniqueId: PropTypes.string.isRequired,
  inlineTypes: PropTypes.array.isRequired,
};

export default InlineStyleControls;
