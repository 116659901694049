import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../common/Button';
import TextArea from '../../common/TextArea';
import TextInput from '../../common/TextInput';

const styles = {
  panelHeading: { minHeight: 30, backgroundColor: '#fafafa', marginBottom: 10 },
  heading: { textAlign: 'center', paddingRight: 40, color: 'black', paddingTop: 10 }
};

const VideoForm = ({
  isAdmin,
  onCancel,
  errors,
  section,
  onChange,
  onSave,
  onDelete,
  isSubmitting,
  isUpdating,
  isDeleting,
  formType
}) => {
  return (
    <div>
      {isAdmin &&
        <div className="card-header" style={styles.panelHeading}>
          <h3 style={styles.heading}>
            Video
          </h3>
        </div>}

      <div className="card-block p-3">
        <form id={formType ? `${formType.formName}_${formType.id}` : ''}>
          <div className="row">
            <div className="col-sm-12">
              <TextInput
                autoFocus
                label="Title"
                name="title"
                error={errors.title}
                value={section.title}
                onChange={onChange}
                placeholder="Title"/>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <TextArea
                label="Video"
                name="url"
                error={errors.url}
                placeholder="Enter video url or paste embed code"
                rows="4"
                cols=""
                onChange={onChange}
                value={section.url}/>
            </div>
          </div>

          {!formType ?
            <Button
              type="submit"
              onClick={onDelete}
              disabled={isDeleting}
              styles={{marginLeft: 0}}
              buttonClass="btn-danger"
              buttonText={isDeleting ? 'Deleting...' : 'Delete'}/> : ''}

          <Button
            type="submit"
            onClick={onSave}
            disabled={isSubmitting || isUpdating}
            buttonClass="btn-primary float-right"
            buttonText={isSubmitting || isUpdating ? 'Saving...' : 'Save'}/>

          <Button
            type="button"
            buttonId={section ? `text-list_${section.id}` : ''}
            onClick={onCancel}
            buttonClass="btn-secondary float-right"
            buttonText="Cancel"/>
        </form>
      </div>
    </div>
  );
};

VideoForm.propTypes = {
  section: PropTypes.object,
  errors: PropTypes.object,
  formType: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired
};

export default VideoForm;
