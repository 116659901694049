import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import RichEditor from '../../../plugins/RichEditor';

const blockTypes = [
  { label: 'Link', style: 'LINK', icon: 'fa-link' }
];

const inlineTypes = [
  { label: 'Bold', style: 'BOLD', icon: 'fa-bold'},
  { label: 'Italic', style: 'ITALIC', icon: 'fa-italic' },
  { label: 'Underline', style: 'UNDERLINE', icon: 'fa-underline' }
];

const styles = {
  editorWrapper: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    padding: '.5rem .5rem',
    borderRadius: 4,
  },
  withError: {
    border: '1px solid #d9534f',
    padding: '.5rem .5rem',
    borderRadius: 4,
  },
  addListItemGroup: {
    position: 'absolute',
    right: 10,
    fontSize: '.8rem'
  },
  errorText: {
    fontSize: 14,
    color: '#d9534f'
  },
  cardWrapper: { marginBottom: 15 }
};

class QuizSectionAnswerForm extends Component {

  static propTypes = {
    onAddOption: PropTypes.func.isRequired,
    onEditOption: PropTypes.func.isRequired,
    sectionContent: PropTypes.array.isRequired,
    editingItemId: PropTypes.string
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: '',
      editListItem: {},
      resetEditorState: false,
      updateEditorState: false,
      editorState: EditorState.createEmpty(),
    };

    this.addQuizOption = this.addQuizOption.bind(this);

    this.setEditorState = this.setEditorState.bind(this);
    this.getEditorState = editorState => this._getEditorState(editorState);
  }

  componentWillReceiveProps(nextProps){
    const { editingItemId, sectionContent } = nextProps;
    if(editingItemId !== null){
      const editingItem = sectionContent.find(content => content.itemId === editingItemId);
      const blocksFromHTML = convertFromHTML(editingItem.value);

      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );

      const editorState = EditorState.createWithContent(contentState);

      this.setState(
        { editorState, updateEditorState: true },
        () => this.setState({ updateEditorState: false }));
    }
  }

  setEditorState() {
    return this.state.editorState;
  }

  _getEditorState(editorState) {
    const currentContent = editorState.getCurrentContent();

    if (! currentContent.hasText())
      return this.setState ({ errors : ''});

    const answerPlainText = currentContent.getPlainText();
    const errors = answerPlainText.length < 1 ? 'Must be at least of one character' : '';

    this.setState({ editorState, errors });
  }

  addQuizOption(event){
    event.preventDefault();
    const { editingItemId } = this.props;
    const { editorState, errors } = this.state;
    const currentContent = editorState.getCurrentContent();

    if (! currentContent.hasText() || errors !== '') return;

    const answer = stateToHTML(currentContent);
    if(editingItemId !== null){
      this.props.onEditOption(answer);
    }else{
      this.props.onAddOption(answer);
    }

    this.setState({ resetEditorState: true }, () => {
      if (this.state.resetEditorState) {
        this.setState({ resetEditorState: false });
      }
    });
  }

  render() {
    const { resetEditorState, updateEditorState, errors } = this.state;
    const { editingItemId } = this.props;
    const buttonText = editingItemId !== null ? 'Update Quiz Option': 'Add Quiz Option';
    const wrapperStyle = errors === '' ? styles.editorWrapper : styles.withError;

    return (
      <div className="row">
        <div className="col-sm-12" style={styles.cardWrapper}>
          <label className="form-control-label" htmlFor="feedback">Quiz Option</label>
          <div className="input-group">

            <div style={wrapperStyle}>
              <span
                className="btn-group btn-group-sm"
                onClick={this.addQuizOption}
                style={styles.addListItemGroup}>
                <span
                  className="btn btn-secondary">
                  {buttonText}&nbsp;<i className="fa fa-plus"/>
                </span>
              </span>
              <RichEditor
                resetEditorState={resetEditorState}
                updateEditorState={updateEditorState}
                blockTypes={blockTypes}
                inlineTypes={inlineTypes}
                placeholder="Enter answer..."
                setEditorState={this.setEditorState}
                getEditorState={this.getEditorState}/>
            </div>

            {errors !== '' && <span style={styles.errorText}>{errors}</span>}
          </div>
        </div>
      </div>

    );
  }
}

export default QuizSectionAnswerForm;
