import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Validator from '../../../validator';
import update from 'immutability-helper';
import { toastr } from 'react-redux-toastr';
import { handleKeyDown } from '../../../utils';

import _keys from 'lodash/keys';
import _isNull from 'lodash/isNull';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import _isEqual from 'lodash/isEqual';

import * as skillActions from '../../../redux/actions/skills';
import * as profileActions from '../../../redux/actions/profile';
import * as componentActions from '../../../redux/actions/components';
import * as myCareerPreppedStatus from '../../../redux/actions/my-careerprepped-status';

import Loading from '../../../components/common/Loading';
import ProfileCardHeader from '../../../components/profile/header/ProfileCardHeader';
import SkillContent from '../../../components/profile/sections/SkillContent';
import AddYourCustomSkills from '../../../components/myskills/AddYourCustomSkills';
import { withHooks } from '../../../utils/withHooks';

class Skills extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    skills: PropTypes.array.isRequired,
    skill: PropTypes.object.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    isPublicProfile: PropTypes.bool.isRequired,
    onToggleSectionEdit: PropTypes.func,
    profile: PropTypes.object.isRequired,
    userId: PropTypes.string,
    isUpdatingSkillOrder: PropTypes.object,
    isRequestingProfile: PropTypes.bool,
    isEditingSections: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    location: PropTypes.object,
    toggleComponents: PropTypes.array.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      isEditing: false,
      skillsOrder: props.profile.skillsOrder.split(',').filter(id => !_isEmpty(id)).map(id => ({ id })),
      skill: Object.assign({}, props.skill),
      focusPreference: ['name'],
      isItemBeingEdited: false,
      allowUpdate: false,
      addYourCustomSkills: false,
    };

    this.onSave = this.onSave.bind(this);
    this.reArrangeSkills = this.reArrangeSkills.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.focusOnInputField = this.focusOnInputField.bind(this);
    this.editItemIsClicked = this.editItemIsClicked.bind(this);
    this.toggleAddSkills = this.toggleAddSkills.bind(this);
    this.toggleShowHiddenSkills = this.toggleShowHiddenSkills.bind(this);
  }

  componentDidMount() {
    const { actions, profile } = this.props;

    actions.skillsRequest(profile.id, true);
    localStorage.setItem('mySkillsParams', JSON.stringify(this.props.location));
  }


  componentWillReceiveProps(nextProps) {
    const { profile: { skillsOrder }} = this.props;
    const { profile: { skillsOrder: nextSkillsOrder }} = nextProps;

    const currentSkillsOrder = skillsOrder.split(',').filter(id => !_isEmpty(id)).map(id => ({ id }));
    const latestSkillsOrder = (nextSkillsOrder || '').split(',').filter(id => !_isEmpty(id)).map(id => ({ id }));
    const skillsOrderIsUpdatedSuccessfully = !_isEqual(currentSkillsOrder, latestSkillsOrder);

    if (skillsOrderIsUpdatedSuccessfully)
      this.setState({
        skillsOrder: latestSkillsOrder,
      }, () => this.handleResponse());

    if(this.props.isSubmitting && !nextProps.isSubmitting){
      if(nextProps.skills.length > this.props.skills.length){
        toastr.success('Skill(s) saved');
      }
    }
  }

  toggleShowHiddenSkills() {
    this.props.actions.toggleComponent('show-hidden-skills');
  }

  isValid(field = null, separateRules = null) {
    let rules = {};

    rules = {
      name: ['required', 'minLength|3', 'maxLength|80']
    };

    if (!_isNull(separateRules))
      rules = separateRules;

    const validate = Validator.createValidator(
      rules, this.state.skill, field
    );
    const { isValid, errors } = validate;

    // Validation errors should persist in the form until they're resolved.
    const inputFieldValueIsChanged = !_isNull(field);
    const fieldValueWasInvalid = Object.prototype.hasOwnProperty.call(this.state.errors, field);
    const errorsAreResolved = _isEmpty(errors);
    const fieldValueIsNowValid = (
      inputFieldValueIsChanged && fieldValueWasInvalid && errorsAreResolved
    );

    if (fieldValueIsNowValid)
      this.setState({
        errors: _omit(this.state.errors, [field])
      });

    else {
      /* if isValid is called before trying to create new skill or trying to add
      new evidence; which results in an invalid form.
      */
      this.setState({
        errors: Object.assign({}, this.state.errors, errors)
      });
    }

    return isValid;
  }

  onChange(event) {
    const { name, value } = event.target;
    const { skill } = this.state;

    skill[name] = value;

    this.setState(
      { skill, allowUpdate: true }, () => this.isValid(name)
    );
  }

  onSave(event) {
    event.preventDefault();

    const { skill } = this.state;
    const { actions, skills, profile } = this.props;

    if (!this.isValid())
      return this.focusOnInputField();

    const splittedSkillsNames = skill.name.split(',');

    const hasDuplicate = skills.reduce((acc, next) => {
      const isDuplicate = splittedSkillsNames.includes(next.name);
      return isDuplicate || acc;
    },false);

    if(hasDuplicate)
      return toastr.error('You\'ve already entered that skill. Enter a new skill.');

    if(this.props.skills.length + splittedSkillsNames.length > 25){
      return toastr.error('You can list 25 skills at maximum');
    }
    if(this.props.skills.length < 25){
      actions.saveSkill({ name: skill.name })
        .then(() => actions.profileRequest(this.props.userId))
        .then(() => actions.skillsRequest(profile.id, true))
        .then(async () => {
          this.setState(
            {
              isEditing: false,
              skill: { name: '' },
            },
            () => this.props.onToggleSectionEdit('skills')
          );
          await actions.updateMyCareerPreppedStatus();
          actions.getMyCareerPreppedStatus();
        });
    }
  }

  onCancel() {
    const initialState = {
      isEditing: false,
      errors: {},
      skill: {
        name: ''
      },
      allowUpdate: false
    };

    this.setState(initialState);
    this.props.onToggleSectionEdit('skills');
  }

  reArrangeSkills(dragIndex, hoverIndex) {
    const { skillsOrder } = this.state;
    const { profile, actions } = this.props;
    const dragItem = skillsOrder[dragIndex];

    this.setState(update(this.state, {
      skillsOrder: {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragItem]
        ]
      }
    }),
    () => {
      actions.updateProfile(profile.id, {
        skillsOrder: this.state.skillsOrder
          .slice(0)
          .map(skill => skill.id)
          .join()
      });
    });
  }

  onEditClick(event) {
    event.preventDefault();
    this.toggleAddSkills();
  }


  editItemIsClicked() {
    const { isItemBeingEdited } = this.state;
    this.setState({
      isItemBeingEdited: !isItemBeingEdited
    });

    this.props.onToggleSectionEdit('skill-item');
  }

  handleResponse() {
    const isEditing = false;
    const skill = {
      name: ''
    };

    this.setState({ isEditing, skill, errors: {} });
  }

  handleKeyPress(event) {
    let onEnter = this.onSave;
    handleKeyDown(event, onEnter);
  }

  focusOnInputField(field = null) {
    // focus on 1st field with an error.
    if (_isNull(field)) {
      return setTimeout(() => {
        const {
          errors,
          focusPreference
        } = this.state;

        const keysArr = _keys(errors);

        for (let item of focusPreference) {
          const match = keysArr.filter(field => field === item);

          if (match.length === 1)
            return document.getElementById(`skill-${match[0]}`).focus();
        }
      }, 100);
    }

    // focus on a given field.
    document.getElementById(field).focus();
  }

  toggleAddSkills() {
    this.setState((state) => {
      return {
        addYourCustomSkills: !state.addYourCustomSkills,
      };
    });
  }

  getMySkills = async () => {
    const { actions, profile } = this.props;
    const skillsList = await actions.skillsRequest(profile.id, true);
    await actions.profileRequest(this.props.userId);
    this.setState({addedSkillsCount: skillsList.myskills.data?.skills?.length || 0});
    return skillsList;
  };

  render() {
    const {
      isRequesting,
      isPublicProfile,
      skills,
      isRequestingProfile,
      isEditingSections,
      userId,
      profile,
      isSubmitting,
      toggleComponents
    } = this.props;

    const {
      errors,
      skill,
      isEditing,
      skillsOrder,
      isItemBeingEdited,
      allowUpdate
    } = this.state;

    if((skills.length === 0 || skillsOrder.length === 0) && isPublicProfile){
      return null;
    }

    return (
      <div className="profile-item skill">
        <ProfileCardHeader
          isEditing={isEditing}
          isEditingSections={isEditingSections}
          type="list"
          title="Skills"
          onEditClick={this.onEditClick}
          isPublicProfile={isPublicProfile}
          currentUser={userId}
          profileId={profile.id}
          icon="skill"
          toggleComponents={toggleComponents}
          toggleShowHiddenSkills={this.toggleShowHiddenSkills}/>

        {isRequesting || isRequestingProfile ?
          <Loading />
          :
          <div className="profile-item__card-list">
            <SkillContent
              isEditing={isEditing}
              isPublicProfile={isPublicProfile}
              reArrangeSkills={this.reArrangeSkills}
              errors={errors}
              skill={skill}
              isSubmitting={isSubmitting}
              onChange={this.onChange}
              onSave={this.onSave}
              onCancel={this.onCancel}
              handleKeyPress={this.handleKeyPress}
              skills={skills}
              skillsOrder={skillsOrder}
              editItemIsClicked={this.editItemIsClicked}
              isItemBeingEdited={isItemBeingEdited}
              onAddClicked={this.onEditClick}
              allowUpdate={allowUpdate}/>
          </div>
        }


        {
          this.state.addYourCustomSkills &&
          <AddYourCustomSkills
            isOpen={this.state.addYourCustomSkills}
            toggleModal={this.toggleAddSkills}
            addedSkillsCount={skills ? skills.length : 0}
            refreshMySkills={this.getMySkills}/>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const skill = { name: '' };
  const {
    skills: {
      isUpdating: isUpdatingSkillOrder,
      isSubmitting,
      isRequesting,
      data: skills
    },
    profile: { isRequesting: isRequestingProfile },
    components: { toggleComponents },
    auth: { data: { currentUser: { id: userId } } }
  } = state;

  return {
    skill,
    skills,
    isRequesting,
    isSubmitting,
    userId,
    isUpdatingSkillOrder,
    isRequestingProfile,
    toggleComponents
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    skillActions,
    profileActions,
    componentActions,
    myCareerPreppedStatus
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(Skills));
