import PropTypes from 'prop-types';
import React from 'react';
import WallCommentCardContainer from '../../containers/dashboard/WallCommentCardContainer';
import { pure } from 'recompose';

const WallCommentList = ({
  wallPostId,
  loadForms,
  postComments
}) => {
  return (
    <div>
      {postComments.map((comment, index) => {
        const lastIndex = ((postComments.length - 1) === index);

        return(
          <div key={comment.id}>
            <WallCommentCardContainer
              commentData={comment}
              lastIndex={lastIndex}
              loadForms={loadForms}
              wallPostId={wallPostId}/>
          </div>
        );
      })}
    </div>
  );
};

WallCommentList.propTypes = {
  loadForms: PropTypes.array.isRequired,
  wallPostId: PropTypes.string.isRequired,
  postComments: PropTypes.array.isRequired
};

export default pure(WallCommentList);
