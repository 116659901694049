import PropTypes from 'prop-types';
import React from 'react';

const Label = ({ str, optional }) => {
  const note = optional ? 'Optional' : 'Required';

  return(
    <div className="form-control-label" style={{ marginBottom: 6 }}>
      {`${str}:`}
      <span style={{ marginLeft: 4, fontSize: 13 }}>
        ({!optional && '*'}
        <span style={{ fontStyle: 'italic' }}>{note}</span>
        )
      </span>
    </div>
  );
};

Label.propTypes = {
  str: PropTypes.string.isRequired,
  optional: PropTypes.bool
};

export default Label;
