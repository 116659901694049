import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateGroup } from '../../redux/actions/groups';
import { updateEducatorGroup } from '../../redux/actions/educators';

import { isEmpty } from 'lodash';

import Button from '../common/Button';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
} from 'reactstrap';
import { toastr } from 'react-redux-toastr';

class AdminGroupEditModal extends Component {
  static propTypes = {
    toggle: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    isUpdating: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    role: PropTypes.string.isRequired,
    selectedGroup: PropTypes.object.isRequired,
    isUpdatingEducatorGroup: PropTypes.bool.isRequired
  }

  constructor(props, context) {
    super(props, context);
    const groupName = this.props.selectedGroup.name;

    this.state = {
      errors: {
        empty: false,
        duplicate: false,
      },
      groupName,
    };
  }

  onChange = (event) => {
    const { target: { value: groupName } } = event;

    this.setState({
      groupName,
      errors: {
        empty: groupName === ''
      }
    });
  }

  onSave = () => {
    const successMessage = 'Successfully edited name';

    const { groupName: name } = this.state;
    const data = { name };

    const { role, actions, selectedGroup: { id: groupId } } = this.props;

    if (role === 'educator') {
      actions.updateEducatorGroup(groupId, data)
        .then((state) => {
          const { educators: { error, isUpdating } } = state;

          if ((! isUpdating) && isEmpty(error)) {
            toastr.success('', successMessage);
            this.props.onCancel();
          }
        });

      return;
    }

    actions.updateGroup(groupId, data)
      .then((state) => {
        const { groups: { error, isUpdating: { status } } } = state;

        if ((! status) && isEmpty(error)) {
          toastr.success('', successMessage);
          this.props.onCancel();
        }
      });
  }

  render() {
    const {
      isUpdating: { status: isUpdatingStatus },
      isUpdatingEducatorGroup,
      selectedGroup: { name }
    } = this.props;

    const { groupName, errors: { empty, duplicate } } = this.state;

    return(
      <Modal
        size="md"
        centered
        autoFocus
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}>
        <ModalHeader
          toggle={this.props.toggle}>
          {`Edit name of ${name}`}
        </ModalHeader>
        <ModalBody>
          <div className={`${empty || duplicate ? 'has-danger' : ''}`}>
            <FormGroup>
              <label className="form-control-label">Edit name</label>
              <Input
                type="text"
                value={groupName}
                onChange={this.onChange}
                placeholder="New group name"/>
              {empty && <small className="text-danger">Name cannot be blank.</small>}
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            buttonClass="btn-secondary"
            onClick={this.props.onCancel}
            buttonText="Cancel" />
          <Button
            disabled={empty || isUpdatingStatus || isUpdatingEducatorGroup}
            onClick={this.onSave}
            buttonClass="btn-primary"
            styles={{ marginLeft: 4 }}
            buttonText={(isUpdatingStatus || isUpdatingEducatorGroup) ? ' Saving...' : ' Save'} />
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    groups: { selectedGroup: institutionSelectedGroup, isUpdating },
    educators: { selectedGroup: educatorSelectedGroup, isUpdating: isUpdatingEducatorGroup },
    profile: { data: { role } }
  } = state;

  const selectedGroup = (role === 'educator') ? educatorSelectedGroup : institutionSelectedGroup;

  return {
    role,
    isUpdating,
    selectedGroup,
    isUpdatingEducatorGroup
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    updateGroup,
    updateEducatorGroup
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminGroupEditModal);
