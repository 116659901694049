import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withHooks } from '../../utils/withHooks';

import { isUndefined } from 'lodash';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import SavedJobsFilter from '../../components/jobs/SavedJobsFilter';
import SavedJobsContainer from '../../components/jobs/SavedJobsContainer';

import * as jobBoardActions from '../../redux/actions/job-boards';
import * as componentsActions from '../../redux/actions/components';

class JobBoardComponent extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    jobBoards: PropTypes.object.isRequired,
    loadForms: PropTypes.array.isRequired,
    callingAPI: PropTypes.bool,
    modal: PropTypes.string,
    params: PropTypes.object,
    location: PropTypes.object.isRequired
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      deleteJobId: null,
      activeFilter: null,
      activeSort: null,
      filterDropdownOpen: false,
      sortDropdownOpen: false,
      activeJobStatusDropup: null,
    };

    this.toggleFilterDropdown = this.toggleFilterDropdown.bind(this);
    this.toggleSortDropdown = this.toggleSortDropdown.bind(this);
    this.toggleJobStatusDropUp = this.toggleJobStatusDropUp.bind(this);
    this.updateJobStatus = this.updateJobStatus.bind(this);
    this.deleteJob = this.deleteJob.bind(this);
    this.onConfirmDeleteJob = this.onConfirmDeleteJob.bind(this);
    this.onCancelDeleteJob = this.onCancelDeleteJob.bind(this);
    this.onAddNewJob = this.onAddNewJob.bind(this);
    this.onSelectFilter = this.onSelectFilter.bind(this);
    this.onSelectSort = this.onSelectSort.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { id: nextJobBoardId } = nextProps.jobBoards.isDeleting;
    const { id: jobBoardId } = this.props.jobBoards.isDeleting;

    if ((nextJobBoardId !== jobBoardId) && (nextJobBoardId === null)) {
      this.onCancelDeleteJob();
    }
  }

  updateJobStatus(jobId, statusId){
    const { updateJobDetails } = this.props.actions;

    return ()=>{
      this.setState({activeJobStatusDropup: null});
      updateJobDetails({
        id: jobId,
        status: statusId
      });
    };
  }

  deleteJob(deleteJobId){
    return () => {
      this.setState({ deleteJobId });
      this.props.actions.openModal('confirmDeleteJobModal');
    };
  }

  onConfirmDeleteJob() {
    const { deleteJobId } = this.state;
    const { deleteJobFromBoard } = this.props.actions;

    deleteJobFromBoard(deleteJobId)
      .then(() => this.setState({ deleteJobId: null }));
  }

  onCancelDeleteJob() {
    this.setState({ deleteJobId: null });
    this.props.actions.closeModal();
  }

  onAddNewJob(){
    const { actions } = this.props;

    actions.loadForm('add-new-job-to-board');
  }

  onSelectFilter(activeFilter){
    return () => {
      this.setState({ activeFilter, filterDropdownOpen: false });

      const { activeSort } = this.state;
      const { jobBoards, currentUser, params } = this.props;
      const { id: currentUserId } = currentUser;
      const { pageNo } = jobBoards.paginationData;

      const userId = params.userId ?? currentUserId;

      this.props.actions.fetchAllJobs(userId, pageNo, activeSort, activeFilter);
    };
  }

  onSelectSort(activeSort){
    return () => {
      this.setState({ activeSort, sortDropdownOpen: false });

      const { activeFilter } = this.state;
      const { jobBoards, currentUser, params } = this.props;
      const { id: currentUserId } = currentUser;
      const { pageNo } = jobBoards.paginationData;

      const userId = params.userId ?? currentUserId;

      this.props.actions.fetchAllJobs(userId, pageNo, activeSort, activeFilter);
    };
  }

  toggleJobStatusDropUp(id){
    const { activeJobStatusDropup } = this.state;

    return ()=>{
      this.setState({
        activeJobStatusDropup: activeJobStatusDropup === id ? null : id
      });
    };
  }

  toggleFilterDropdown(){
    const { filterDropdownOpen } = this.state;

    this.setState({
      filterDropdownOpen: !filterDropdownOpen
    });
  }

  toggleSortDropdown(){
    const { sortDropdownOpen } = this.state;

    this.setState({
      sortDropdownOpen: !sortDropdownOpen
    });
  }

  render() {
    const {
      filterDropdownOpen,
      sortDropdownOpen,
      activeJobStatusDropup,
      activeFilter,
      activeSort
    } = this.state;

    const { modal, params, jobBoards, location } = this.props;
    const { isDeleting, data, isRequesting } = jobBoards;
    const confirmDeleteJobModalIsOpen = modal === 'confirmDeleteJobModal';
    const isGuestUser = !isUndefined(params.userId);

    return (
      <div className="col-lg-12 content jobboard">
        <Helmet title="My Jobs"/>
        <div className="mb-3">
          {isGuestUser ?
            `${location.state.user.firstName}'s saved jobs are organized in their "Job Tracker" using labeled cards (Saved, Closed, Applied, Interviewed, Offered, No Response, or Rejected). Clicking on a card reveals contact information and notes. As an Admin, you can view ${location.state.user.firstName}'s saved jobs and notes but cannot edit the information.` :
            'Manage your saved jobs and track your application process by using the labels in the drop down menu for each saved job. Click on any job to add contact details and important notes like who you spoke with, who you know, or what additional steps you took after applying. You can delete your saved jobs or decide to keep some saved to remember companies you like or job descriptions that might help you tailor future resumes to your targeted job goal.'}
        </div>
        {!isGuestUser &&
          <div className="job-saved-item" style={{marginBottom: 20}}>
            <button
              type="button"
              name="button"
              onClick={this.onAddNewJob}
              className="btn btn-primary">
              Add Job
            </button>
          </div>}
        <SavedJobsFilter
          filterDropdownOpen={filterDropdownOpen}
          sortDropdownOpen={sortDropdownOpen}
          onSelectFilter={this.onSelectFilter}
          onSelectSort={this.onSelectSort}
          activeFilter={activeFilter}
          activeSort={activeSort}
          toggleFilterDropdown={this.toggleFilterDropdown}
          toggleSortDropdown={this.toggleSortDropdown}/>

        <SavedJobsContainer
          callingAPI={this.props.callingAPI}
          toggleJobStatusDropUp={this.toggleJobStatusDropUp}
          activeJobStatusDropup={activeJobStatusDropup}
          onSelectJobStatus={this.updateJobStatus}
          loadForms={this.props.loadForms}
          deleteJob={this.deleteJob}
          isDeleting={isDeleting}
          jobs={data}
          isGuestUser={isGuestUser}
          isRequesting={isRequesting}
          confirmDeleteJobModalIsOpen={confirmDeleteJobModalIsOpen}
          onConfirmDeleteJob={this.onConfirmDeleteJob}
          onCancelDeleteJob={this.onCancelDeleteJob}/>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  const { jobBoards, components } = state;

  return {
    jobBoards,
    modal: components.modal,
    loadForms: components.forms,
    currentUser: state.auth.data.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, jobBoardActions, componentsActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};


export default withHooks(connect(mapStateToProps,mapDispatchToProps)(JobBoardComponent));
