import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Row, Col } from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getJobsStatistics } from '../../redux/actions/statistics';

const JobsActivity = ({ actions, userId, jobsStatistics }) => {
  useEffect(() => {
    actions.getJobsStatistics(userId);
  }, [actions, userId]);

  return (
    <div className="dashboard-profile-card">
      <div className="dashboard-skill-status-header no-border">
        Jobs Activity
      </div>
      <div className="dashboard-profile-stats-container">
        <Row>
          <Col>
            <div className="dashboard-profile-stat no-border">
              <div className="dashboard-skill-status-progress-title">
                <h4><Link to="/job-center/job-tracker">{jobsStatistics?.saved}</Link></h4>
                <span>Saved</span>
              </div>
            </div>
          </Col>
          <Col>
            <div className="dashboard-profile-stat">
              <div className="dashboard-skill-status-progress-title">
                <h4><Link to="/job-center/job-tracker">{jobsStatistics?.applied}</Link></h4>
                <span>Applied</span>
              </div>
            </div>
          </Col>
          <Col>
            <div className="dashboard-profile-stat">
              <div className="dashboard-skill-status-progress-title">
                <h4><Link to="/job-center/job-tracker">{jobsStatistics?.interviewed}</Link></h4>
                <span>Interviewed</span>
              </div>
            </div>
          </Col>
          <Col>
            <div className="dashboard-profile-stat">
              <div className="dashboard-skill-status-progress-title">
                <h4><Link to="/job-center/job-tracker">{jobsStatistics?.offered}</Link></h4>
                <span>Offered</span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

JobsActivity.propTypes = {
  actions: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  jobsStatistics: PropTypes.object.isRequired
};

const mapStateToProps = ({
  auth: { data: { currentUser: { id: userId } } },
  statistics: { jobs: { data: jobsStatistics } }
}) => ({ userId, jobsStatistics });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    getJobsStatistics
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(JobsActivity);
