import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { checkHttp } from '../../../utils';
import Parser from 'html-react-parser';

const styles = {
  userNameEditIcon: {
    cursor: 'pointer',
    marginLeft: 10,
  },

  editActions: {
    position: 'relative',
  }
};

const PublicationSectionCard = ({
  publicationDetails,
  onEditPublication,
  onRemovePublication
}) => {
  const isPubDateObject = typeof publicationDetails.pubdate === 'object';
  const pubDateString = isPubDateObject ? publicationDetails.pubdate.date :
    publicationDetails.pubdate === '' ? undefined : publicationDetails.pubdate;
  const date = moment(pubDateString).format('MM/YYYY');

  const urlAvailable = publicationDetails.puburl !== '';
  const urlString = checkHttp(publicationDetails.puburl) ?
    publicationDetails.puburl : `//${publicationDetails.puburl}`;
  const isAuthorAvailable = publicationDetails.author !== '';

  return (
    <div className="resume-section section-item">
      <span style={styles.editActions} className="float-right">
        <i
          onClick={onEditPublication}
          id={publicationDetails.id}
          style={styles.userNameEditIcon}
          className="fa fa-pencil"/>

        <i
          onClick={onRemovePublication}
          id={publicationDetails.id}
          style={styles.userNameEditIcon}
          className="fa fa-close"/>
      </span>
      <span><b>{publicationDetails.title}</b> - {!urlAvailable ?
        publicationDetails.publication :
        <a href={urlString} target="_blank" rel="noopener noreferrer">{publicationDetails.publication}</a>}
      </span>
      <span style={{marginLeft: 10}}>({date})</span>
      {isAuthorAvailable && <p><b>Author(s):</b> {publicationDetails.author}</p>}
      {Parser(publicationDetails.description)}
    </div>
  );
};

PublicationSectionCard.propTypes = {
  publicationDetails: PropTypes.object.isRequired,
  onEditPublication: PropTypes.func.isRequired,
  onRemovePublication: PropTypes.func.isRequired
};

export default PublicationSectionCard;
