import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getEducators } from '../../redux/actions/educators';
import { requestFeedback } from '../../redux/actions/myskills';

import PropTypes from 'prop-types';

import { toastr } from 'react-redux-toastr';
import CloseIcon from '../../assets/images/close.svg';
import AsyncPaginateOption from './AsyncPaginateOption';
import { AsyncPaginate } from 'react-select-async-paginate';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  NavItem,
  NavLink,
  Nav,
} from 'reactstrap';

const colourStyles = {
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#B2D4FF' : 'transparent'
    };
  }
};

class RequestEducators extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: [],
      isSubmitting: false,
      sendBtnDisabled: true,
    };

    this.remove = this.remove.bind(this);
    this.clearAll = this.clearAll.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.loadOptions = this.loadOptions.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  async loadOptions(search, loadedOptions, { page }) {
    let endpoint = `institution/educators?page=${page}`;

    if (search) {
      endpoint = `${endpoint}&query=${search}`;
    }

    await this.props.actions.getEducators(endpoint);

    return {
      options: this.props.educators,
      hasMore: this.props.pageCount > page,
      additional: { page: page + 1 }
    };
  }

  onChange(value) {
    if (value && value.length > 10) return;

    this.setState({
      tags: [...value],
      sendBtnDisabled: (value.length === 0)
    });
  }

  clearAll() {
    this.setState({ tags: [], sendBtnDisabled: true });
  }

  getSelectCount() {
    const selectCount = this.state.tags.length || 0;
    if (!selectCount) {
      return (
        <div className={`radialProgressBar progress-${selectCount}`}>
          <div className="overlay-0">{selectCount}</div>
        </div>
      );
    }
    return (
      <div className={`radialProgressBar progress-${selectCount}`}>
        <div className="overlay">{selectCount}</div>
      </div>
    );
  }

  handleToggle(){
    this.props.toggleModal('requestEducators');
  }

  onSend = () => {
    const reqObj = {
      request_for_user_id: this.props.currentUser.id,
      userType: 'educator',
      users: this.state.tags.map(tag => tag.value),
    };

    if (reqObj?.users?.length === 0) {
      toastr.error('', 'Kindly Select  at least one educator.');
      return false;
    }

    if (this.props.skill_id) {
      reqObj.skill_id = this.props.skill_id;
    }
    if (this.props.badge_id) {
      reqObj.badge_issued_id = this.props.data.issuedID;
    }
    this.setState({ isSubmitting: true });
    this.props.actions.requestFeedback(reqObj).then(() => {
      this.setState({ tags: [] });
      toastr.success('Your feedback request was successfully sent');
      this.props.toggleModal('requestEducators');
    }).catch(() => {
      this.props.toggleModal('requestEducators');
    });
  };

  remove(event) {
    const tag = event.target.getAttribute('data-value');

    let arr = this.state.tags.filter(t => t.label !== tag);
    if ( [...arr].length === 0 ) {
      this.setState({sendBtnDisabled: true});
    }
    this.setState({ tags: [...arr] });
  }

  render() {
    const { isOpen } = this.props;

    return (
      <Modal
        centered
        className="customLevelUpModal"
        isOpen={isOpen}
        toggle={this.handleToggle}>
        <div className="modal-header">
          <span className="modal-title">
            Request Feedback from up to 10 Individuals at a Time
          </span>
          <div
            onClick={this.handleToggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">
              <img src={CloseIcon} />
            </span>
          </div>
        </div>
        <ModalBody>
          <Row>
            <Col sm="8" md="8" lg="8">
              <AsyncPaginate
                isMulti
                cacheOptions
                isSearchable
                name="educators"
                additional={{ page: 1 }}
                isClearable={false}
                loadOptions={this.loadOptions}
                styles={colourStyles}
                placeholder="Who do you want to ask for feedback?"
                className="multiselect-container multiSelectContainer"
                components={{Option: AsyncPaginateOption}}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                value={this.state.tags}
                controlShouldRenderValue={false}
                onChange={this.onChange} />
            </Col>
            <Col sm="4" md="4" lg="4" className="pl-0">
              <div className="progressBar-wrapper">
                {this.getSelectCount()}
                <div className="font12 ml-1">Added</div>
              </div>
            </Col>
          </Row>
          <div className="mb-5">
            {this.state.tags.map(tag => (
              <span key={tag.value} className="d-inline-block reqtag">
                {tag.label}
                <i
                  data-value={tag.label}
                  className="fa fa-close pointer"
                  style={{ marginLeft: '5px' }}
                  onClick={this.remove}/>
              </span>
            ))}
            {this.state.tags.length > 1 && (
              <Nav>
                <NavItem>
                  <NavLink className="pl-0" onClick={this.clearAll} href="#">
                    <small> <u> Remove All </u></small>
                  </NavLink>
                </NavItem>
              </Nav>
            )}
          </div>
        </ModalBody>
        <ModalFooter className="bordertop-0 pb-2">
          <button
            className="btn btn-secondary"
            onClick={this.handleToggle}>
            Cancel
          </button>
          <Button
            onClick={this.onSend}
            className="mx-1 sendButton"
            color="warning"
            disabled={this.state.sendBtnDisabled || this.state.isSubmitting}>
            {this.state.isSubmitting ? 'Sending...' : 'Send'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

RequestEducators.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  educators: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  badge_id: PropTypes.string,
  skill_id: PropTypes.string,
  data: PropTypes.object,
  pageCount: PropTypes.number.isRequired
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ requestFeedback, getEducators }, dispatch),
});

const mapStateToProps = (state) => {
  const {
    auth: { data: { currentUser } },
    educators: {
      pageCount,
      data: educators
    }
  } = state;

  return {
    pageCount,
    educators,
    currentUser
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestEducators);
