import React from 'react';

const CircleStar = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.49288 1.41675C4.58288 1.41675 1.41663 4.59008 1.41663 8.50008C1.41663 12.4101 4.58288 15.5834 8.49288 15.5834C12.41 15.5834 15.5833 12.4101 15.5833 8.50008C15.5833 4.59008 12.41 1.41675 8.49288 1.41675ZM11.4962 12.7501L8.49996 10.9438L5.50371 12.7501L6.29704 9.343L3.65496 7.05508L7.13996 6.75758L8.49996 3.54175L9.85996 6.7505L13.345 7.048L10.7029 9.33592L11.4962 12.7501Z" fill="#F08523"/>
    </svg>
  );
};

export default CircleStar;
