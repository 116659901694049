import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../components/common/Loading';
import { isEmpty } from 'lodash';

const BadgesSkillsList = (props) => {

  const [resourceBadgePage, setResourceBadgePage] = useState(1);
  const [resourceSkillPage, setResourceSkillPage] = useState(1);
  const [hideLoader, setHideLoader] = useState(false);

  useEffect(() => {
    // reset page number for pagination in case of search
    if(props.resetPage) {
      setResourceBadgePage(1);
      setResourceSkillPage(1);
    }
  }, [props.resetPage]);

  async function handleBadgeSkillPagination() {
    let searchValue = isEmpty(props.searchValue) ? null : props.searchValue;

    // reset page number for pagination in case of search
    if(props.resetPage) {
      setResourceBadgePage(1);
      setResourceSkillPage(1);
    }

    const updatedResourceBadgePage = resourceBadgePage + 1;
    const updatedResourceSkillPage = resourceSkillPage + 1;
    const totalBadgePage = props.badgesTotalPage + 1;
    const totalSkillPage = props.skillsTotalPage + 1;

    // hide loader for pagination
    setHideLoader(true);
    if(totalBadgePage > updatedResourceBadgePage){
      setResourceBadgePage(updatedResourceBadgePage);
      await props.getMySkills(null, searchValue, updatedResourceBadgePage, 'badge');
    }

    if(totalSkillPage > updatedResourceSkillPage){
      setResourceSkillPage(updatedResourceSkillPage);
      await props.getMySkills(null, searchValue, updatedResourceSkillPage, 'skill');
    }

    setHideLoader(false);
  }

  return (
    <>
      {
        !hideLoader && props.isRequesting ? <Loading />
          : (
            <InfiniteScroll
              dataLength={props.filteredResultsLength}
              next={handleBadgeSkillPagination}
              height={300}
              hasMore>
              {props.filteredResultsLength === 0 ? <p>No data to display</p> : props.children}
            </InfiniteScroll>
          )
      }
    </>
  );
};

BadgesSkillsList.propTypes = {
  children: PropTypes.array,
  filteredResultsLength: PropTypes.number,
  getMySkills: PropTypes.func,
  skillsTotalPage: PropTypes.number,
  badgesTotalPage: PropTypes.number,
  searchValue: PropTypes.string,
  skillsTotalItems: PropTypes.string,
  badgesTotalItems: PropTypes.string,
  isRequesting: PropTypes.bool,
  resetPage: PropTypes.bool,
};

export default BadgesSkillsList;