import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { toastr } from 'react-redux-toastr';

import ToggleSwitch from '../../components/common/toggleSwitch';

const IRConfigAlert = (props) => {
  const [showAlert, setShowAlert] = useState(false);
  const { actions, industryRepresentativeConfiguration, profile } = props;
  const industryRepresentativeConfigurationState =
    industryRepresentativeConfiguration;

  useEffect(() => {
    setShowAlert(!(localStorage.getItem('IRConfigBar') === 'false'));
  }, []);

  async function handleChange() {
    const updatedIRData = {
      industryRepresentativeConfiguration: {
        ...industryRepresentativeConfigurationState,
        disableFeedbackRequests: false,
        pauseEndDate: null,
        pauseStartDate: null,
      },
    };
    await actions.updateIRSettings(updatedIRData);
    actions.requestIRSettings(profile.id);
    setShowAlert(false);
    toastr.success('Settings updated');
    localStorage.removeItem('IRConfigBar');
    localStorage.setItem('PullUpdated', 'true');
  }

  function onDismissAlert() {
    localStorage.setItem('IRConfigBar', 'false');
    setShowAlert(false);
  }

  const barMessage =
    industryRepresentativeConfiguration.disableFeedbackRequests === 1
      ? 'stopped'
      : 'paused';

  return (
    <div className="ir-config-bar">
      <div className="container">
        <Alert
          className="ir-config-bar"
          color="primary"
          isOpen={showAlert}
          toggle={onDismissAlert}>
          {`You have ${barMessage} all feedback requests. Turn Back On.`}
          <ToggleSwitch
            pauseDisableRequest={false}
            changePauseDisableRequest={handleChange}
            isUpdating={props.isUpdating}
            name="disableFeedbackRequestsBar"
            className="ml-2"/>
        </Alert>
      </div>
    </div>
  );
};

IRConfigAlert.propTypes = {
  profile: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  isUpdating: PropTypes.func,
  industryRepresentativeConfiguration: PropTypes.object,
};

export default IRConfigAlert;
