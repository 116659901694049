import PropTypes from 'prop-types';
import React from 'react';

import { isEmpty, isUndefined } from 'lodash';

import TextInput from '../common/TextInput';
import Alert from './Alert';

const signpImg = require('../../assets/images/img-iaf-logo.jpg');

const SelfRegisterForm = ({
  credentials,
  errors,
  registration,
  onChange,
  onSubmit,
  handleKeyPress,
  displayAlertType,
  dismissAlert
}) => {
  let alertHeading = 'Thank you for signing up for CareerPrepped.';
  let alertMessage = 'You\'re almost finished! Please check your email to complete your account creation. We\'ve emailed you a link to setup your password and sign in. If you don\'t see the email in your inbox, please check any spam, junk, clutter, or promotions folders and search "CareerPrepped" in all email folders. If you still can\'t locate your email, contact support at careerprepped@maxknowledge.com and let us know you didn\'t get your sign up email.';

  if (! isUndefined(registration.error.errorType)) {
    alertHeading = 'There\'s a problem with the email entered.';
    alertMessage = registration.error.detail;

    if ((registration.error.errorType === 'DuplicateSelfRegistrationException')) {
      alertHeading = 'Duplicate registration requests.';
    }

    if ((registration.error.errorType === 'InvalidGroupCodeException')) {
      alertHeading = 'Invalid group code.';
    }
  }

  return (
    <div className="educator-signup iaf col-10">
      <h1>Join Imagine America on CareerPrepped</h1>
      <p>The Imagine America Foundation's (IAF) goal isn't just to help you train for a new career; we want to help you achieve lifelong career success! That's why we partnered with CareerPrepped, a lifelong career success platform with an all-in-one suite of tools that not only brings the jobs to you, but it also helps you stand out, get the interview, and land the job you want. Stay connected with us and join the IAF group now!</p>
      <div className="self-register">
        <div style={{textAlign:'center'}}>
          <div className="double-lines" />
          <img src={signpImg} style={{backgroundColor:'white',height:'90px'}}/>
        </div>
        <h4>
          Sign up for CareerPrepped
        </h4>
        <form onSubmit={onSubmit} onKeyPress={handleKeyPress}>
          <div className="row">
            {(! isEmpty(displayAlertType)) &&
              <Alert
                displayAlertType={displayAlertType}
                alertHeading={alertHeading}
                alertMessage={alertMessage}
                dismissAlert={dismissAlert}/>}

            <div className="col-md-6 col-sm-12">
              <TextInput
                name="email"
                label="Email"
                placeholder="Email"
                value={credentials.email}
                onChange={onChange}
                error={errors.email}
                autoFocus
                srOnly/>
            </div>
            <div className="col-md-6 col-sm-12">
              <TextInput
                name="confirmEmail"
                label="Confirm Email"
                placeholder="Confirm Email"
                value={credentials.confirmEmail}
                onChange={onChange}
                error={errors.confirmEmail}
                srOnly/>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <TextInput
                name="groupCode"
                label="Group Code"
                placeholder="Group Code"
                value={credentials.groupCode}
                onChange={onChange}
                error={errors.groupCode}
                srOnly/>
            </div>
          </div>
          <input
            type="submit"
            disabled={registration.isSubmitting}
            value={registration.isSubmitting ? 'Signing Up...' : 'Sign Up'}
            className="btn btn-primary btn-xlg w-100"/>
        </form>
      </div>
      <div className="join-iaf-group">
        <h1>Unlock the Benefits of Joining Our CareerPrepped Group</h1>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="item">
                <p><i className="fas fa-check"/> Become eligible for IAF scholarships and grants</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="item">
                <p><i className="fas fa-check"/> Build a portfolio to showcase your skills & achievements</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="item">
                <p><i className="fas fa-check"/> Get matched to jobs that fit your skills from across the internet</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="item">
                <p><i className="fas fa-check"/> Develop soft skills all employers want with access to Skill Builders</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="item">
                <p><i className="fas fa-check"/> Standout to employers with your own personal career site</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="item">
                <p><i className="fas fa-check"/> Build resumes, get interview tips, track job applications & more!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SelfRegisterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  handleKeyPress: PropTypes.func.isRequired,
  registration: PropTypes.object.isRequired,
  errors: PropTypes.object,
  credentials: PropTypes.object,
  displayAlertType: PropTypes.string.isRequired,
  dismissAlert: PropTypes.func.isRequired
};

export default SelfRegisterForm;
