import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as commentsActions from '../../../redux/actions/comments';
import * as wallPostCommentActions from '../../../redux/actions/wall-post-comments';
import { toastr } from 'react-redux-toastr';
import { scrollToTop } from '../../../utils';
import CommentLikeButton from '../../../components/common/CommentLikeButton';

class ReplyLikeButton extends Component {
  static propTypes = {
    reply: PropTypes.object,
    dashboard: PropTypes.bool,
    actions: PropTypes.object,
    replyData: PropTypes.array,
    userId: PropTypes.string.isRequired,
    isSubmittingLike: PropTypes.object.isRequired,
    isSubmittingDashboardLike: PropTypes.object.isRequired,
    error: PropTypes.object,
    isAuthenticated: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { userId, actions, reply, isAuthenticated, dashboard } = this.props;

    const likeData = { 'comment': reply.id, userId };

    if (!isAuthenticated) {
      scrollToTop();
      return toastr.warning('Please Login/Signup');
    }

    if(dashboard && reply.liked){
      return actions.unlikeWallPostReply(likeData);
    }

    if(reply.liked){
      return actions.unlikeUnitDiscussionReply(likeData);
    }

    const actionsToCall = dashboard ? 'saveWallPostReplyLike': 'saveUnitDiscussionReplyLike';

    actions[actionsToCall](likeData);
  }

  render() {
    const {
      isSubmittingLike,
      reply,
      dashboard,
      isSubmittingDashboardLike
    } = this.props;

    const {status, wallPostCommentIndex} = isSubmittingDashboardLike;
    const isLikingReply = dashboard ?
      status && wallPostCommentIndex === reply.id :
      isSubmittingLike.status && isSubmittingLike.commentId === reply.id;

    return(
      <CommentLikeButton
        isLikingComment={isLikingReply}
        comment={reply}
        onLike={this.onClick}/>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    replyData: state.comments.replyData,
    isSubmittingLike: state.comments.isSubmittingLike,
    isSubmittingDashboardLike: state.wallPostComments.isSubmittingLike,
    error: state.comments.error,
    isAuthenticated: state.auth.isAuthenticated,
    userId: state.auth.data.currentUser.id
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, commentsActions, wallPostCommentActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReplyLikeButton);
