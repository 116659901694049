import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import TextArea from '../../common/TextArea';
import DiscussionAvatar from './DiscussionAvatar';
import ImagePreview from '../../common/ImagePreview';

const pinStyles = {
  avatarInput: {
    position: 'absolute',
  },
  label: {
    cursor: 'pointer'
  },
  closeIcon: {
    position: 'absolute',
    top: 5,
    right: 5,
    zIndex: 99,
    cursor: 'pointer',
    color: '#ff5000',
    fontSize: 20
  }
};

const CommentForm = ({
  comment,
  onChange,
  handleKeyPress,
  styles,
  imagePreviewUrl,
  onCancel,
  onDropFile,
  errors,
  textareaRef,
  isForm,
  onEscape,
  form,
  profile
}) => {
  const validMimeType = 'image/*';
  const { comment: error } = errors;
  const wrapperClass = classNames('post-comment', {
    'error': (error && error.length > 0)
  });

  return (
    <div className="secondary-post-comment">
      <DiscussionAvatar user={profile}/>

      <div className="right">
        <form className={wrapperClass}>
          <div className="form-group">
            <div className="input-group">
              <TextArea
                styles={styles}
                inputId={form}
                textareaRef={textareaRef}
                rows="1"
                name="comment"
                placeholder="Write your comment..."
                value={comment.comment}
                onChange={onChange}
                onKeyPress={handleKeyPress}
                autofocus={!!isForm}/>

              <label
                style={pinStyles.label}
                className="input-group-addon align-topm">
                <Dropzone
                  style={pinStyles.avatarInput}
                  multiple={false}
                  accept={validMimeType}
                  onDrop={onDropFile}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()}/>
                      </div>
                    </section>
                  )}
                </Dropzone>

                <a className="browse-icon">
                  <i className="fa fa-paperclip"/>
                </a>
              </label>

            </div>
          </div>
        </form>
        {error && <div className="error-feedback">{error}</div>}

        <ImagePreview
          imagePreviewUrl={imagePreviewUrl}
          onCancel={onCancel}
          closeIcon={pinStyles.closeIcon}
          secondary/>

        <div className="share-stats" style={{ marginTop: -16 }}>
          {isForm ?
            <span>
              Press Esc to {' '}
              <a
                onClick={onEscape}
                className="reply-button">
                cancel
              </a>
              .
            </span> :
            <span>Press Enter to post.</span>}
        </div>
      </div>
    </div>
  );
};

CommentForm.propTypes = {
  styles: PropTypes.object,
  textareaRef: PropTypes.func.isRequired,
  handleKeyPress: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  comment: PropTypes.object.isRequired,
  errors: PropTypes.object,
  imagePreviewUrl: PropTypes.string,
  onCancel: PropTypes.func,
  onDropFile: PropTypes.func,
  onEscape: PropTypes.func,
  isForm: PropTypes.bool,
  form: PropTypes.string,
  profile: PropTypes.object.isRequired
};

export default CommentForm;
