import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import TableLoading from '../../components/common/TableLoading';

const DocsViewer = ({ fileUrl, width, height }) => {
  const style = {
    width: width,
    height: height,
    border: 'none'
  };

  const iframeRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [iframeTimeoutId, setIframeTimeoutId] = useState(undefined);

  useEffect(() => {
    const intervalId = setInterval(updateIframeSrc, 1000 * 2);

    setIframeTimeoutId(intervalId);
  }, [updateIframeSrc]);

  function iframeLoaded() {
    setLoaded(true);
    clearInterval(iframeTimeoutId);
  }

  const getIframeLink = useCallback(() => {
    return `https://docs.google.com/viewer?url=${fileUrl}&embedded=true`;
  }, [fileUrl]);

  const updateIframeSrc = useCallback(() => {
    if (iframeRef.current) {
      iframeRef.current.src = getIframeLink();
    }
  }, [getIframeLink]);

  return (
    <>
      <iframe
        style={style}
        ref={iframeRef}
        onLoad={iframeLoaded}
        onError={updateIframeSrc}
        src={getIframeLink()}/>
      {! loaded && <TableLoading/>}
    </>
  );
};

DocsViewer.propTypes = {
  fileUrl: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string
};

export default DocsViewer;
