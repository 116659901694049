import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as interviewActions from '../../../redux/actions/interview';

import { Link } from 'react-router-dom';
import UnitVideoCommentHandler from './UnitVideoCommentHandler';

class VideoComments extends Component {
  static propTypes = {
    params: PropTypes.object,
    comments: PropTypes.array,
    actions: PropTypes.object.isRequired,
    unitVideo: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    commentData: PropTypes.object.isRequired,
    videoDetails: PropTypes.object.isRequired,
    unitVideoComments: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      commentData: Object.assign({}, this.props.commentData)
    };

    this.onChange = this.onChange.bind(this);
    this.onComment = this.onComment.bind(this);
  }

  onComment(e){
    e.preventDefault();

    const { commentData } = this.state;
    const { videoDetails, actions } = this.props;

    const data = {
      'resource': videoDetails.id,
      'comment': commentData.comment
    };

    actions.addCommentToVideo(data).
      then(() => this.setState({ commentData: {
        'comment': ''
      }}));
  }

  onChange(e){
    const { name, value } = e.target;
    const { commentData } = this.state;

    commentData[name] = value;

    this.setState({ commentData });
  }

  render() {
    const { commentData } = this.state;
    const { comments, profile, unitVideoComments, videoDetails } = this.props;
    const { isSubmitting } = unitVideoComments;
    const isSubmittingNewComment = isSubmitting.status && isSubmitting.commentId === null;

    return (
      <div className="portfolio-comments mt-0 mb-2">
        <h4 className="mb-1">Comments</h4>
        <div className="forums">
          <div className="post">
            <div className="primary-post">
              <div className="comment-form-container">
                <div className="left">
                  <Link to={`/cp/${profile.vanity}`}>
                    {
                      profile.avatar && profile.avatar !== '' ?
                        <img
                          className="header-image medium align-top"
                          src={profile.avatar}
                          alt={`${profile.name}'s Avatar`}/>:
                        <span
                          className="header-image medium align-top initial-avatar">
                          {profile.name.slice(0,1)}
                        </span>
                    }
                  </Link>
                </div>
                <div className="comment-post right">
                  <div id="comment-container">
                    <textarea
                      id="comment"
                      name="comment"
                      rows="3"
                      cols="60"
                      className="form-control"
                      placeholder="Video comments"
                      value={commentData.comment}
                      onChange={this.onChange}/>
                    <button
                      disabled={isSubmittingNewComment || commentData.comment === ''}
                      onClick={this.onComment}
                      className="btn btn-primary float-right mt-1 mb-1">
                      {isSubmittingNewComment ? 'Commenting...' :'Comment'}
                    </button>
                  </div>
                </div>
              </div>
              <div className="clearfix"/>
            </div>
          </div>
        </div>
        {comments.length > 0 &&
          <div className="forums">
            <div className="post">
              {comments.map((comment, index) =>
                (<UnitVideoCommentHandler
                  key={index}
                  videoId={videoDetails.id}
                  comment={comment}/>)
              )}
            </div>
          </div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const commentData = {
    'comment': ''
  };

  return {
    commentData,
    profile: state.profile.data,
    unitVideo: state.interview.unitVideo,
    unitVideoComments: state.interview.unitVideoComments,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, interviewActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(VideoComments);
