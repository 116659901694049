import PropTypes from 'prop-types';
import React from 'react';
import AcknowledgementListInput from './AcknowledgementListInput';

const styles = {
  card: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop: 0
  }
};

const AcknowledgementListForm = ({
  errors,
  onChange,
  acknowledgement,
  onAddAcknowledgement,
  onDeleteAcknowledgement
}) => {
  return (
    <div>
      <div className="unit-section-title">
        <h6 className="unit-section-title-text">Unit Acknowledgements</h6>
      </div>
      <div className="card" style={styles.card}>
        <div className="card-block p-3">
          {acknowledgement.map((item, index) => {
            return (<AcknowledgementListInput
              index={index}
              key={`list-item_${item.itemId}`}
              name={`acknowledgement[${index}].value`}
              error={errors[`acknowledgement[${index}].value`]}
              item={item}
              onChange={onChange}
              onDeleteListItem={onDeleteAcknowledgement}/>);
          })}

          <div className="search-hint badge-hint">
            {acknowledgement.length === 0 &&
              <div>
                <span className="fa fa-info-circle" aria-hidden="true"/>
                <p>This unit doesn't have any acknowledgement.</p>
              </div>}
            <button
              type="button"
              onClick={onAddAcknowledgement}
              className="btn btn-primary">
              Add acknowledgement
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

AcknowledgementListForm.propTypes = {
  errors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  acknowledgement: PropTypes.array.isRequired,
  onAddAcknowledgement: PropTypes.func.isRequired,
  onDeleteAcknowledgement: PropTypes.func.isRequired,
};

export default AcknowledgementListForm;
