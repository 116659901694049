import { startCase, isEmpty as isEmptyObject, lowerCase, includes, uniq, forEach } from 'lodash';
import moment from 'moment';

import { countWords } from '../utils';

const isEmpty = value =>
  value === undefined || value === null || value === '';

export const join = rules => (value, data) =>
  rules
    .map(rule => rule(value, data))
    .filter(error => !!error)[0 /* first error */];

export function email(value) {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  if (!isEmpty(value) && !regex.test(value)) {
    return 'Invalid email address';
  }
}

export function workEmail(value) {
  const businessDomains = [
    'gmail',
    'hotmail',
    'yahoo',
    'protonmail',
    'aol',
    'icloud',
    'outlook',
  ];

  if (!isEmpty(value)) {
    const host = value.substring(value.lastIndexOf('@') + 1);
    const domain = host.substring(0, host.indexOf('.'));
    const invalidEmail = businessDomains.includes(domain);

    if (invalidEmail) {
      return 'Invalid work email address';
    }
  }
}

export function alpha(value) {
  const regex = /^[A-Za-z]+$/;

  if (!isEmpty(value) && !regex.test(value)) {
    return 'Please input alphabet characters only';
  }
}

export function required(value) {
  const type = typeof value;
  const restrictedTrimType = ['number', 'boolean', 'object'];

  if (isEmpty(value)) {
    return 'Required';
  }
  if (!restrictedTrimType.includes(type) && value.trim().length === 0) {
    return 'Required';
  }
}

export function minLength(min) {
  return (value) => {
    if (!isEmpty(value) && value.length < min) {
      return ` Must be at least ${min} characters`;
    }
  };
}

export function maxLength(max) {
  return (value) => {
    if (!isEmpty(value) && value.length > max) {
      return ` Must be no more than ${max} characters`;
    }
  };
}

export function maxWords(max) {
  return (value) => {
    if (!isEmpty(value) && countWords(value) > max) {
      return ` Must be no more than ${max} words`;
    }
  };
}

export function minWords(min) {
  return (value) => {
    if (!isEmpty(value) && countWords(value) < min) {
      return ` Must be at least ${min} words`;
    }
  };
}

export function integer(value) {
  if (!Number.isInteger(Number(value))) {
    return 'Must be an integer';
  }
}

export function lessThan(max) {
  return (value) => {
    if (parseFloat(value) > parseFloat(max)) {
      return ` Must not be greater than ${max}`;
    }
  };
}

export function oneOf(enumeration) {
  return (value) => {
    if (!~enumeration.indexOf(value)) {
      return ` Must be one of: ${enumeration.join(', ')}`;
    }
  };
}

export function match(field) {
  return (value, data) => {
    if (data) {
      if (value !== data[field]) {
        return `Must match ${field} field`;
      }
    }
  };
}

export function notMatch(field) {
  return (value, data) => {
    if (data) {
      if (value === data[field]) {
        const fieldName = startCase(field);

        return `Must not match ${fieldName.toLowerCase()} field`;
      }
    }
  };
}

export function unsafeurl(value) {
  const regex = /^(https?:\/\/)?((w{3}\.)?)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g;

  if (!isEmpty(value) && !regex.test(value)) {
    return 'Must be a valid URL.';
  }
}

export function url(value) {
  const regex = /https?:\/\/(www\.)?[^\s.]+\.[^\s]{2,}/g;

  if (!isEmpty(value) && !regex.test(value)) {
    return 'Must be a valid URL.';
  }
}

export function video(value) {
  const youtubeRegex = /^(https?:\/\/)?((w{3}\.)?)(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/;
  const vimeoRegex = /^(https?:\/\/)?((w{3}\.)?)(vimeo.com)\/(watch)?(\?v=)?(\S+)?/;

  if (!isEmpty(value) && !youtubeRegex.test(value) && !vimeoRegex.test(value)) {
    return 'Must be a valid Youtube or Vimeo URL.';
  }
}

export function choseAtleastOne(items) {
  const options = items.split(',');

  return (value, data) => {
    if (
      options
        .map(field => data[field])
        .filter(
          fieldValue => !isEmptyObject(fieldValue) && !isEmpty(fieldValue)
        ).length === 0
    )
      return `Chose atleast one amongst ${items}`;
  };
}

export function phone(value) {
  const regex = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;

  if (!isEmpty(value) && !regex.test(value)) {
    return 'Invalid phone number';
  }
}

export function startdateYear(value = '') {
  const startDate = parseInt(value, 10);
  const currentYear = new Date().getFullYear();
  if (startDate > currentYear || startDate < currentYear - 75) {
    return `Start date must not be greater than ${currentYear} or less than ${
      currentYear - 75
    }`;
  }
}

export function enddate(value = '') {
  const endDate = parseInt(value, 10);
  const currentYear = new Date().getFullYear();
  if (endDate > currentYear + 10 || endDate < currentYear - 75) {
    return `End date must not be greater than ${
      currentYear + 10
    } or less than ${currentYear - 75}`;
  }
}

export function beforeStart(start) {
  return (value) => {
    if (new Date(value) < new Date(start)) {
      return 'End Date cannot be before Start Date';
    }
  };
}

export function startdate(value = '') {
  const day = moment(value);
  const today = moment();
  const earliest = moment().subtract(75, 'years');

  if (day.isAfter(today) || day.isBefore(earliest)) {
    return `Start date cannot be greater than ${today.format(
      'MM/DD/YYYY'
    )} or less than ${earliest.format('MM/DD/YYYY')}.`;
  }
}

export function isNumber(value) {
  if (isNaN(value)) {
    return `"${value}" is not a number`;
  }
}

export function stringWithHyphenSpace(value) {
  const regex = /^([a-zA-Z]|\s|-)*$/;

  if (!isEmpty(value) && !regex.test(value)) {
    return 'Only letters, spaces and hyphens are accepted';
  }
}

export function isNotSelfEmail(selfEmail) {
  return (inputValue) => {
    if (inputValue === selfEmail) {
      return 'You cannot add your own email address';
    }
  };
}

export function acceptWorkEmail(value) {
  const regex = /^.*@(.*).com$/;
  const emailDomain = value ? value.match(regex) : null;
  const invalidDomains = ['gmail', 'yahoo', 'hotmail', 'protonmail', 'iCloud', 'aol', 'zohomail'];
  if (!isEmpty(value) && emailDomain && includes(invalidDomains, lowerCase(emailDomain[1]))) {
    return 'Please use a work email address';
  }
}

export function confirmPasswordMatch(password) {
  return (inputValue) => {
    if (inputValue !== password) {
      return 'Entered passwords do not match.';
    }
  };
}

export function isEmailUnique(emailListData) {
  return (inputValue) => {
    const emailList = emailListData.split(',');
    const uniqueEmailList = uniq(emailList);
    let errorMessage = 'The email address is already added';
    let emailOccurence = [];

    // add the email occurence index in the message for display purpose
    forEach(emailList, (email, index) => {
      if(email === inputValue) {
        errorMessage = `${errorMessage},${index}`;
        emailOccurence.push(index);
      }
    });
    if (inputValue && uniqueEmailList.length !== emailList.length && emailOccurence.length > 1) {
      return errorMessage;
    }
  };
}

export function isNotSameAsWorkEmail(workEmail) {
  return (inputValue) => {
    if(!isEmpty(workEmail) && !isEmpty(inputValue) && inputValue === workEmail){
      return 'Alternate email id should be different from work email id';
    }
  };
}
