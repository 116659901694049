import React from 'react';

import PropTypes from 'prop-types';

const HelpTopicCard = ({ header, paragraph, url }) => {
  return (
    <div className="dashboard-card mb-3 dashboard-help-topic">
      <div className="dashboard-card-content-w-icon">
        <div className="dashboard-help-content">
          <h2>
            <a href={url} target="_blank">{header}</a>
          </h2>
          <p>{paragraph}</p>
        </div>
        <div>
          <a href={url} target="_blank">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" fill="#F05323"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

HelpTopicCard.propTypes = {
  url: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired
};

export default HelpTopicCard;
