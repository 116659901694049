import React from 'react';
import PropTypes from 'prop-types';
import { isNull } from 'lodash';

const UsersTableLocationCell = ({ user: { city, state } }) => {
  let location = 'Not Provided';

  if ((! isNull(city)) && (! isNull(state))) {
    location = `${city.label}, ${state.label}`;
  }

  return (
    <span>{location}</span>
  );
};

UsersTableLocationCell.propTypes = {
  user: PropTypes.object.isRequired
};

export default UsersTableLocationCell;
