import React, { Component } from 'react';

import BadgeSupportingStatement from './BadgeSupportingStatement';

class BadgeSupportingStatementContainer extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <div>
        <BadgeSupportingStatement {...this.props}/>
      </div>
    );
  }
}

export default BadgeSupportingStatementContainer;
