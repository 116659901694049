import PropTypes from 'prop-types';
import React from 'react';
import SummarySection from './SummarySection';

const ResumeSummary = ({ summary }) => {
  return (
    <div>
      <div className="resume-title">
        {summary.title !== '' ? summary.title : 'Summary'}
      </div>
      <SummarySection summary={summary.summary}/>
    </div>
  );
};

ResumeSummary.propTypes = {
  summary: PropTypes.object.isRequired,
};

export default ResumeSummary;
