import PropTypes from 'prop-types';
import React from 'react';

import Parser from 'html-react-parser';

const styles = {
  deleteButton: {
    cursor: 'pointer',
    color: '#f00'
  },

  editButton: {
    marginRight: 10,
    cursor: 'pointer'
  },

  buttonWrapper: {
    fontSize: 10,
    position: 'absolute',
    right: 10,
    top: 10
  },

  listGroupItem: { paddingBottom: 0 }
};

const DropdownListItems = ({ section, onEditListItem, onDeleteListItem }) => {
  return (
    <div>
      <h6>List Items</h6>
      <hr/>

      <div className="row">
        <div className="col-sm-12">
          {section.content.length === 0 &&
            <div className="search-hint badge-hint">
              <span className="fa fa-info-circle" aria-hidden="true"/>
              <p>This section does not have list items yet.</p>
            </div>}

          {section.content.length > 0 &&
            <ul className="list-group" style={{marginBottom: 20}}>
              {section.content.map(item =>
                (<li
                  key={item.itemId}
                  className="list-group-item"
                  style={styles.listGroupItem}>
                  <h6>{item.value}</h6>
                  {Parser(item.feedback)}
                  <span id={item.itemId} style={styles.buttonWrapper}>
                    <i
                      className="fa fa-pencil"
                      style={styles.editButton}
                      onClick={onEditListItem}/>
                    <i
                      className="fa fa-close"
                      style={styles.deleteButton}
                      onClick={onDeleteListItem}/>
                  </span>
                </li>))}
            </ul>}
        </div>
      </div>
    </div>
  );
};

DropdownListItems.propTypes = {
  section: PropTypes.object.isRequired,
  onEditListItem: PropTypes.func.isRequired,
  onDeleteListItem: PropTypes.func.isRequired
};

export default DropdownListItems;
