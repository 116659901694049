import PropTypes from 'prop-types';
import React from 'react';
import Loading from '../../common/Loading';
import HeaderContent from './HeaderContent';

const Header = (props) => {
  const {
    stickyStyles,
    unitTitle,
    isAdmin,
    slug,
    unit,
    path,
    isRequesting,
    noSection,
    onClickReviewEssentials,
    onClickEngage,
    onClickGetBadge
  } = props;

  return(
    <div className={stickyStyles} id={'essHeader'}>
      {
        isRequesting ?
          <Loading loaderStyles={{ color: 'white', marginBottom: 10, marginTop: 10 }}/>
          :
          <HeaderContent
            unitTitle={unitTitle}
            isAdmin={isAdmin}
            slug={slug}
            unit={unit}
            path={path}
            noSection={noSection}
            onClickReviewEssentials={onClickReviewEssentials}
            onClickEngage={onClickEngage}
            onClickGetBadge={onClickGetBadge}/>
      }
    </div>
  );
};

Header.propTypes = {
  slug: PropTypes.string,
  unit: PropTypes.object.isRequired,
  unitTitle: PropTypes.string,
  isAdmin: PropTypes.bool,
  stickyStyles: PropTypes.string,
  path: PropTypes.string,
  isRequesting: PropTypes.bool,
  noSection: PropTypes.bool,
  onClickEngage: PropTypes.func.isRequired,
  onClickGetBadge: PropTypes.func.isRequired,
  onClickReviewEssentials: PropTypes.func.isRequired
};

export default Header;
