import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { sendMessage } from '../../../redux/actions/messages';
import { followUser, unfollowUser } from '../../../redux/actions/users';
import {
  incrementFollowingCount,
  decrementFollowingCount,
} from '../../../redux/actions/profile';

import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import Validator from '../../../validator';
import MessageModal from '../../profile/connects/MessageModal';

import { isEmpty, isUndefined } from 'lodash';

import { withHooks } from '../../../utils/withHooks';

class UsersTableDropdownAction extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    message: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    following: PropTypes.object.isRequired,
    currentUserId: PropTypes.string.isRequired,
    isSubmittingMessage: PropTypes.bool.isRequired,
    toggleRequestFeedbackModal: PropTypes.func,
    toggleGiveFeedbackModal: PropTypes.func,
    toggleSeeFeedbackModal: PropTypes.func,
    setUserDataForFeedbackRequest: PropTypes.func,
    setUserDataForGiveFeedback: PropTypes.func,
    setUserDataForSeeFeedback: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      isOpen: false,
      isMessageModalOpen: false,
      message: { ...props.message },
    };

    this.onViewResumes = this.onViewResumes.bind(this);
    this.onViewSavedJobs = this.onViewSavedJobs.bind(this);
    this.onFollowUnfollow = this.onFollowUnfollow.bind(this);
    this.toggleActions = this.toggleActions.bind(this);
    this.toggleMessageModal = this.toggleMessageModal.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);
    this.onSubmitMessage = this.onSubmitMessage.bind(this);
    this.setUserDataForFeedbackRequest = this.setUserDataForFeedbackRequest.bind(this);
    this.setUserDataForGiveFeedback = this.setUserDataForGiveFeedback.bind(this);
    this.setUserDataForSeeFeedback = this.setUserDataForSeeFeedback.bind(this);
  }

  toggleActions() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  toggleMessageModal() {
    this.setState({ isMessageModalOpen: !this.state.isMessageModalOpen });
  }

  onChangeMessage(event) {
    const { value, name } = event.target;
    const { message } = this.state;

    message[name] = value;

    this.setState({ message }, () => this.isValid(name));
  }

  isValid(field = null) {
    const validate = Validator.createValidator(
      {
        subject: ['required'],
        body: ['required'],
      },
      this.state.message,
      field
    );

    const { isValid, errors } = validate;

    let errorsObj = Object.assign({}, this.state.errors, errors);

    if (field && isUndefined(errors[field])) {
      delete errorsObj[field];
    }

    if (!field && isEmpty(errors)) {
      errorsObj = errors;
    }

    this.setState({ errors: errorsObj });

    return isValid;
  }

  onSubmitMessage() {
    const { message } = this.state;
    const {
      actions,
      user: { id: userId },
    } = this.props;

    if (!this.isValid()) return;

    message.recipients = [userId];
    actions.sendMessage(message).then(() => this.handleResponse());
  }

  handleResponse() {
    const message = { recipients: [], subject: '', body: '' };

    this.setState(
      {
        message,
      },
      () => this.toggleMessageModal()
    );
  }

  onFollowUnfollow() {
    const {
      users,
      actions,
      user: {
        id: userId,
        name,
        isUserFollower: { status: isCurrentUserFollower, connectId },
      },
      following: { isRequesting },
    } = this.props;

    if (isRequesting) return;

    const recordIndex = users.data.findIndex(user => user.id === userId);

    if (isCurrentUserFollower && connectId !== '') {
      actions.unfollowUser(connectId, recordIndex).then((state) => {
        const {
          users: {
            error,
            isFollowing: { status },
          },
        } = state;

        if (!status && isEmpty(error)) {
          actions.decrementFollowingCount();
          toastr.success('', `Successfully unfollowed ${name}.`);
        }
      });

      return;
    }

    const data = { user: userId };

    actions.followUser(data, recordIndex).then((state) => {
      const {
        users: {
          error,
          isFollowing: { status },
        },
      } = state;

      if (!status && isEmpty(error)) {
        actions.incrementFollowingCount();
        toastr.success('', `Successfully followed ${name}.`);
      }
    });
  }

  setUserDataForFeedbackRequest(){
    const {
      user, toggleRequestFeedbackModal, setUserDataForFeedbackRequest
    } = this.props;
    toggleRequestFeedbackModal();
    setUserDataForFeedbackRequest({
      userId: user.id,
      userData: user,
    });
  }

  setUserDataForGiveFeedback(){
    const {
      user, toggleGiveFeedbackModal, setUserDataForGiveFeedback
    } = this.props;
    toggleGiveFeedbackModal();
    setUserDataForGiveFeedback({
      userId: user.id,
      userData: user,
    });
  }

  setUserDataForSeeFeedback(){
    const {
      user, toggleSeeFeedbackModal, setUserDataForSeeFeedback
    } = this.props;
    toggleSeeFeedbackModal();
    setUserDataForSeeFeedback({
      userId: user.id,
      userData: user,
    });
  }

  onViewResumes() {
    const { user, navigate } = this.props;

    navigate(`/job-center/resume-builder/${user.id}`, { state: { user } });
  }

  onViewSavedJobs() {
    const { user, navigate } = this.props;

    navigate(`/job-center/job-tracker/${user.id}`, { state: { user } });
  }

  render() {
    const {
      profile,
      currentUserId,
      isSubmittingMessage,
      users: {
        isFollowing: { status: isFollowing },
      },
      user: {
        id: userId,
        isUserFollower: { status: isCurrentUserFollower },
      },
    } = this.props;

    const { isOpen, errors, message, isMessageModalOpen } = this.state;

    const connectText = isCurrentUserFollower ? '- Unfollow' : '+ Follow';
    const followText = isFollowing ? `${connectText}ing` : connectText;
    const isEducator = profile.role === 'educator';

    return (
      <div>
        <ButtonDropdown
          disabled={userId === currentUserId}
          isOpen={isOpen}
          toggle={this.toggleActions}>
          <DropdownToggle
            outline
            color="dropdown"
            className="btn-dropdown">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots" viewBox="0 0 16 16">
              <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3"/>
            </svg>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={this.toggleMessageModal}>
              Message
            </DropdownItem>

            {(!isEducator) &&
              <DropdownItem
                value={userId}
                onClick={this.setUserDataForFeedbackRequest}>
                Request Feedback
              </DropdownItem>}

            {(!isEducator) &&
              <DropdownItem
                value={userId}
                onClick={this.setUserDataForGiveFeedback}>
                Give Feedback
              </DropdownItem>}

            {(!isEducator) &&
              <DropdownItem
                value={userId}
                onClick={this.setUserDataForSeeFeedback}>
                See Feedback
              </DropdownItem>}

            <DropdownItem
              disabled={isFollowing}
              onClick={this.onFollowUnfollow}>
              {followText}
            </DropdownItem>

            {(!isEducator) &&
              <DropdownItem
                value={userId}
                onClick={this.onViewResumes}>
                View Resumes
              </DropdownItem>}

            {(!isEducator) &&
              <DropdownItem
                value={userId}
                onClick={this.onViewSavedJobs}>
                View Saved Jobs
              </DropdownItem>}
          </DropdownMenu>
        </ButtonDropdown>

        {isMessageModalOpen && (
          <MessageModal
            toggleModal={this.toggleMessageModal}
            modalOpen={isMessageModalOpen}
            error={errors}
            isSubmitting={isSubmittingMessage}
            message={message}
            onMessageChange={this.onChangeMessage}
            onMessageSubmit={this.onSubmitMessage}/>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    users,
    following,
    messages: { isSubmitting: isSubmittingMessage },
    auth: {
      data: {
        currentUser: { id: currentUserId },
      },
    },
    profile: { data: profile }
  } = state;

  return {
    users,
    profile,
    following,
    currentUserId,
    isSubmittingMessage,
    message: { recipients: [], subject: '', body: '' },
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      followUser,
      unfollowUser,
      sendMessage,
      incrementFollowingCount,
      decrementFollowingCount,
    },
    dispatch
  ),
});

export default withHooks(connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersTableDropdownAction));
