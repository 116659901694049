import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { closeModal } from '../../redux/actions/components';
import { updateGroupAddAdmins } from '../../redux/actions/groups';

import { toastr } from 'react-redux-toastr';
import _uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';

import {
  Modal,
  ModalHeader,
  ModalFooter,
  Input,
  Row,
  Col,
  ModalBody,
} from 'reactstrap';

import Button from '../common/Button';
import ConfirmModal from '../portfolio/ConfirmModal';
import { email } from '../../validator/rules';

class AdminGroupAddAdminModal extends Component {
  static propTypes = {
    modal: PropTypes.string.isRequired,
    institution: PropTypes.string,
    actions: PropTypes.object.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    groupName: PropTypes.string,
    groupId: PropTypes.string,
    invitedAdmins: PropTypes.array,
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      isConfirmModalOpen: false,
      errors: {
        email: false,
      },
      emails: '',
      emailsArray: [],
      cancelBtnDisabled: false,
    };

    this.onCloseModal = this.onCloseModal.bind(this);
  }

  onCloseModal() {
    this.props.actions.closeModal();
  }

  onChange = (event) => {
    const emails = event.target.value;

    this.setState({ emails });
    this.checkValidEmails(event.target.value);
  }

  checkValidEmails = (emails) => {
    const emailsArray = emails.split(/[ ,]+/).filter(item => item !== '');

    if(emailsArray.length > 0){
      const invalidEmailExist = emailsArray.reduce((acc, next) => {
        const isInvalid = email(next.trim()) && email(next.trim()).length > 0;

        return isInvalid || acc;
      }, false);

      this.setState({
        emailsArray,
        errors: {
          ...this.state.errors,
          email: invalidEmailExist,
        },
      });
    }
  }

  onOpenConfirmModal = () => {
    this.setState({ isConfirmModalOpen: true });
  }

  onCloseConfirmModal = () => {
    this.setState({ isConfirmModalOpen: false });
  }

  onConfirmAddAdmin = () => {
    const { errors, emails, emailsArray } = this.state;
    const { actions, groupId } = this.props;

    this.setState({ cancelBtnDisabled: true });

    if (errors.email === false && emails) {
      const data = { emails: _uniq(emailsArray) };

      actions
        .updateGroupAddAdmins(groupId, data)
        .then((state) => {
          const { groups: { error, isUpdating: { status } } } = state;

          if ((! status) && isEmpty(error)) {
            toastr.success('Successfully invited new admin(s)');

            this.setState({ cancelBtnDisabled: false });
            this.onCloseModal();
          }
        });
    }

    this.onCloseConfirmModal();
  }

  render() {
    const { modal, groupName, isUpdating } = this.props;
    const { errors, emails, isConfirmModalOpen } = this.state;

    return(
      <Modal
        size="md"
        centered
        autoFocus
        isOpen={(modal === 'admin-group-add-admin')}
        toggle={this.onCloseModal}>
        <ModalHeader
          toggle={this.onCloseModal}>
          {`Add Admin(s) to ${groupName}`}
        </ModalHeader>

        <ModalBody>
          <Row className={`${errors.email ? 'has-danger' : ''}`}>
            <Col>
              <div>
                <h6>
                  {`Designate new Admin(s) for ${groupName}`}
                </h6>
              </div>
              <Input
                type="text"
                value={emails}
                onChange={this.onChange}
                className="form-control emails"
                placeholder="Enter emails seperated by a comma"/>
              {errors.email &&
                <small className="text-danger">Please enter valid email</small>}
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button
            disabled={this.state.cancelBtnDisabled}
            onClick={this.onCloseModal}
            buttonClass="btn-secondary"
            buttonText="Cancel" />
          <Button
            disabled={errors.email || (! emails)}
            onClick={this.onOpenConfirmModal}
            buttonClass="btn-primary"
            buttonText={isUpdating ? 'Saving...' : 'Save'}/>
        </ModalFooter>

        <ConfirmModal
          isOpen={isConfirmModalOpen}
          confirminginProgressText="Finishing..."
          modalHeaderText="Adding admins"
          modalMessage={`Are you sure you want to add admin(s) to ${groupName}?`}
          modalFooterButtonText="Yes, Add"
          onCancel={this.onCloseConfirmModal}
          onConfirm={this.onConfirmAddAdmin}/>
      </Modal>
    );
  }
}

const mapStateToProps = (state)=> {
  const {
    components: { modal },
    groups: {
      isUpdating: { status: isUpdating },
      selectedGroup: { id: groupId, name: groupName }
    }
  } = state;

  return {
    modal,
    groupId,
    groupName,
    isUpdating
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    closeModal,
    updateGroupAddAdmins
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminGroupAddAdminModal);
