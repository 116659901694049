import { CALL_API } from '../../middlewares/api';

import {
  INSTITUTIONS_REQUEST,
  INSTITUTIONS_SUCCESS,
  INSTITUTIONS_FAILURE,
  SET_SELECTED_INSTITUTION,
  UPDATE_INSTITUTION_GROUPS_COUNT,
} from '../../constants';

export const getUserInstitutions = () => {
  const endpoint = 'user/institutions';

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [INSTITUTIONS_REQUEST, INSTITUTIONS_SUCCESS, INSTITUTIONS_FAILURE],
    },
  };
};

export const setSelectedInstitution = selectedInstitution => ({
  type: SET_SELECTED_INSTITUTION,
  selectedInstitution,
});

export const updateInstitutionGroupsCount = groupsCount => ({
  type: UPDATE_INSTITUTION_GROUPS_COUNT,
  groupsCount,
});
