import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import PortfolioPreview from '../../components/portfolio/PortfolioPreview';
import SkillList from '../../components/portfolio/SkillList';
import FileComments from './FileComments';
import FileCommentForm from './FileCommentForm';
import classNames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCardActions from '../../containers/portfolio/FileCardActions';
import { Link } from 'react-router-dom';

const FileDetails = ({
  onChangeRoute,
  profile,
  showAllSkills,
  onToggleShowAllSkills,
  details,
  onShareClick,
  closeModal,
  isRequestingComments,
  comments,
  commentData,
  onChange,
  onCopy,
  onComment,
  onLikeFile,
  isSubmitting,
  onViewEvidence,
  isAuthenticated,
  onEditFile
}) => {
  const {
    id: fileId,
    user,
    title,
    uploaded,
    description,
    skills,
    badges,
    url,
    mimetype,
    like_count,
    comment_count,
    liked
  } = details;

  const savedDate = uploaded && moment(uploaded.date).format('MMM DD, YYYY');
  const fileUrl = `${location.href}/${user.vanity}/portfolio-details/${fileId}`;
  const isOwner = (profile.id === user.id);
  const fullYear = new Date().getFullYear();

  return (
    <div>
      <div className="modal-header">
        <div className="media mb-1">
          <div className="media-left media-middle">
            <span title={`Link to ${user.name}’s Career Site`}>
              <a className="clickable" onClick={onChangeRoute(`/cp/${user.vanity}`)}>
                {
                  user.avatar && user.avatar !== ''?
                    <img
                      className="media-object header-image medium"
                      src={user.avatar}
                      alt="User avatar"/>:
                    <span
                      className="media-object header-image medium initial-avatar">
                      {user.name && user.name.slice(0,1)}
                    </span>
                }
              </a>
            </span>
          </div>
          <div className="media-body ml-2">
            <h5 className="modal-title">{title}</h5>
            <p className="modal-author">by <a className="clickable orange link" onClick={onChangeRoute(`/cp/${user.vanity}`)} title={`Link to ${user.name}’s Career Site`}><strong>{user.name}</strong></a> on <strong>{savedDate}</strong>.</p>
            <div className="modal-description mt-2">
              <p>{description}</p>
            </div>
          </div>
        </div>
        <button type="button" className="close" onClick={closeModal} aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body portfolio-body">
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <PortfolioPreview
                url={url}
                mimetype={mimetype}/>
              <div className="report-abuse">
                © {fullYear} • All content within this project is strictly the property of {user.name} and is not for public use without permission.
              </div>
            </div>
            <div className="col-sm-4">
              {
                skills.length > 0 &&
                  <div className="portfolio-sidebar mb-1">
                    <div className="group-container">
                      <h6 className="mb-1 font-weight-bold">Skills Demonstrated</h6>
                      <SkillList
                        profile={profile}
                        fileItem={details}
                        showAllSkills={showAllSkills}
                        onToggleShowAllSkills={onToggleShowAllSkills}
                        onViewEvidence={onViewEvidence}/>
                    </div>
                  </div>
              }

              {
                badges.length > 0 &&
                  <div className="portfolio-sidebar mb-1">
                    <div className="skillbuilder-group">
                      <div className="group-container">
                        <h6 className="mb-1 font-weight-bold">Skill Badges Supported</h6>
                        {badges
                          .sort((badge1, badge2) => Number(badge1.unit.code) - Number(badge2.unit.code))
                          .map((badge, index) =>
                            (<div key={index}>
                              <Link
                                onClick={onViewEvidence()}
                                to={`/skill-badges/badge-details/${badge.id}`}
                                className="skill-badges clickable orange link">
                                {`${badge.unit.code} ${badge.badgeName}`}
                              </Link>
                            </div>))}
                      </div>
                    </div>
                  </div>
              }

            </div>
            <div className="col-sm-8">
              <hr className="clearfix"/>
              <div className="portfolio-stats mb-1">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <a
                      onClick={onLikeFile}
                      className="clickable"
                      title={liked ? 'UNLIKE': 'LIKE'}>
                      <i className={classNames('fa fa-heart', {
                        'liked-heart': liked
                      })} aria-hidden="true"/>
                    </a>{like_count}
                  </li>
                  <li className="list-inline-item"><i className="fa fa-comment" aria-hidden="true"/>{comment_count}</li>
                </ul>
                <div className="stats__share-actions">
                  <a
                    onClick={onShareClick}
                    className="clickable"
                    title="SHARE">
                    <i className="fa fa-share" aria-hidden="true" style={{margin:'0'}}/>
                  </a>
                  {isOwner &&
                    <CopyToClipboard text={fileUrl} onCopy={onCopy}>
                      <a className="clickable" title="Copy Link">
                        <i className="fa fa-link" aria-hidden="true" style={{margin:'0'}}/>
                      </a>
                    </CopyToClipboard>}

                  {isOwner &&
                    <FileCardActions
                      hideEdit
                      onEdit={onEditFile}
                      fileItem={details}/>}
                </div>
              </div>
              <hr style={{clear:'both'}} />

              <div className="portfolio-comments mt-0 mb-2">
                <h4 className="mb-3">Comments</h4>
                {
                  isAuthenticated && profile.name &&
                    <FileCommentForm
                      profile={profile}
                      commentData={commentData}
                      onChange={onChange}
                      onComment={onComment}
                      isSubmitting={isSubmitting.status && isSubmitting.fileId === fileId}/>
                }
                <FileComments
                  fileId={fileId}
                  profile={profile}
                  comments={comments}
                  isRequestingComments={isRequestingComments}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FileDetails.propTypes = {
  onChangeRoute: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  showAllSkills: PropTypes.bool,
  onToggleShowAllSkills: PropTypes.func,
  details: PropTypes.object.isRequired,
  onShareClick: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isRequestingComments: PropTypes.bool,
  comments: PropTypes.array.isRequired,
  commentData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onComment: PropTypes.func.isRequired,
  onCopy: PropTypes.func,
  onLikeFile: PropTypes.func.isRequired,
  isSubmitting: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
  onViewEvidence: PropTypes.func.isRequired,
  onEditFile: PropTypes.func
};

export default FileDetails;
