import PropTypes from 'prop-types';
import React from 'react';

import Quiz from '../../../containers/skill-builders/admin/Quiz';
import Image from '../../../containers/skill-builders/admin/Image';
import Video from '../../../containers/skill-builders/admin/Video';
import TextList from '../../../containers/skill-builders/admin/TextList';
import Paragraph from '../../../containers/skill-builders/admin/Paragraph';
import ThinkingBreak from '../../../containers/skill-builders/admin/ThinkingBreak';
import QuestionAnswer from '../../../containers/skill-builders/admin/QuestionAnswer';
import Rolling from '../../../containers/skill-builders/admin/Rolling';
import Dropdown from '../../../containers/skill-builders/admin/Dropdown';

const SectionForm = ({formType, isAdmin, onCancel, unit}) => {
  let component;

  switch(formType.formName) {
  case 'question-answer-form':
    component = (
      <QuestionAnswer
        unit={unit}
        isAdmin={isAdmin}
        onCancel={onCancel}
        formType={formType}/>
    );
    break;

  case 'quiz-form':
    component = (
      <Quiz
        unit={unit}
        isAdmin={isAdmin}
        onCancel={onCancel}
        formType={formType}/>
    );
    break;

  case 'image-form':
    component = (
      <Image
        unit={unit}
        isAdmin={isAdmin}
        onCancel={onCancel}
        formType={formType}/>
    );
    break;

  case 'text-list-form':
    component = (
      <TextList
        unit={unit}
        isAdmin={isAdmin}
        onCancel={onCancel}
        formType={formType}/>
    );
    break;

  case 'video-form':
    component = (
      <Video
        unit={unit}
        isAdmin={isAdmin}
        onCancel={onCancel}
        formType={formType}/>
    );
    break;

  case 'thinking-break-form':
    component = (
      <ThinkingBreak
        unit={unit}
        isAdmin={isAdmin}
        onCancel={onCancel}
        formType={formType}/>
    );
    break;

  case 'paragraph-form':
    component = (
      <Paragraph
        unit={unit}
        isAdmin={isAdmin}
        onCancel={onCancel}
        formType={formType}/>
    );
    break;

  case 'rolling-section-form':
    component = (<Rolling
      unit={unit}
      isAdmin={isAdmin}
      onCancel={onCancel}
      formType={formType}/>);
    break;

  case 'dropdown-section-form':
    component = (<Dropdown
      unit={unit}
      isAdmin={isAdmin}
      onCancel={onCancel}
      formType={formType}/>);
    break;

  default:
    '';
  }

  return (
    <div style={{marginTop: 30, padding: 16}}>
      {formType && <div className="card panel-admin">{component}</div>}
    </div>
  );
};

SectionForm.propTypes = {
  formType: PropTypes.object,
  isAdmin: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  unit: PropTypes.object.isRequired
};

export default SectionForm;
