import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as webpageActions from '../../../redux/actions/webpage';
import * as networksActions from '../../../redux/actions/networks';

import Loading from '../../../components/common/Loading';
import ProfileJumbotron from '../../../components/profile/header/ProfileJumbotron';
import SkillbadgesSideMenu from '../../../components/skill-badges/SkillbadgesSideMenu';
import Portfolio from '../../portfolio/Portfolio';
import RestrictedWebpageSection from '../../../components/profile/visiting/RestrictedWebpageSection';
import { withHooks } from '../../../utils/withHooks';

class WebpagePortfolio extends Component {
  static propTypes = {
    isRequesting: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    params: PropTypes.object,
    networks: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    profile: PropTypes.object.isRequired
  }

  componentWillMount(){
    const { params, actions, isAuthenticated } = this.props;

    if(params.slug){
      actions.clearWebpageData();
      actions.webpageDetailsRequest(params.slug, isAuthenticated);
    }
  }

  componentWillReceiveProps(nextProps){
    const { data } = this.props;
    const { isRequesting, data: nextData } = nextProps;

    if(data !== nextData && !isRequesting && nextData.isPrivacyVisible ){
      this.props.actions.userSocialLinksRequest(nextData.id);
    }
  }


  render() {
    const {
      isRequesting,
      data: webpageData,
      currentUser,
      networks
    } = this.props;

    if (isRequesting || Object.keys(webpageData).length === 0) {
      return(
        <div>
          <div className="loading-container">
            <Loading/>
          </div>
        </div>
      );
    }

    return (
      <div>
        <section id="profile">
          <ProfileJumbotron
            networks={networks}
            profile={webpageData}
            currentUser={currentUser}/>
          <div className="container">
            <div className="row">
              {
                webpageData.isPrivacyVisible &&
                  <div className="col-lg-12">
                    <div className="content portfolio">
                      <div className="row flex-column">
                        <SkillbadgesSideMenu
                          isVisiting
                          profile={webpageData}/>
                        <Portfolio profile={webpageData}/>
                      </div>
                    </div>
                  </div>
              }

              {
                !webpageData.isPrivacyVisible &&
                  <RestrictedWebpageSection/>
              }
            </div>
          </div>
        </section>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  const { webpage, auth, profile } = state;
  return {
    isRequesting: webpage.isRequesting,
    data: webpage.data,
    profile: profile.data,
    networks: state.networks,
    isAuthenticated: state.auth.isAuthenticated,
    currentUser: auth.data.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, webpageActions, networksActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};



export default withHooks(connect(mapStateToProps, mapDispatchToProps)(WebpagePortfolio));
