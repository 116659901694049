import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Dropzone from 'react-dropzone';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toastr } from 'react-redux-toastr';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import Validator from '../../../validator';
import TextInput from '../../../components/common/TextInput';
import TextArea from '../../../components/common/TextArea';

import * as componentsActions from '../../../redux/actions/components';
import * as unitActions from '../../../redux/actions/skill-builders';

const styles = {
  dropzoneStyle: {
    width: '100%',
    height: 40,
    border: '2px dashed #d9d9d9',
    borderRadius: 5,
    cursor: 'pointer',
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  activeStyle: {
    border: '2px dashed #9e9d9d'
  },
  placeholder: { margin: 0, color: '#8e8d8d' }
};

class UnitBadgeForm extends Component {
  static propTypes = {
    unit: PropTypes.object.isRequired,
    badge: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    isSubmittingBadge: PropTypes.object.isRequired,
    isUpdatingBadge: PropTypes.object.isRequired,
    unitBadge: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      errors: {},
      imagePreviewUrl: '',
      badge: Object.assign({}, this.props.badge)
    };

    this.onSave = this.onSave.bind(this);
    this.formatData = this.formatData.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onImageDrop = this.onImageDrop.bind(this);
    this.onDeleteLoadedFile = this.onDeleteLoadedFile.bind(this);
  }

  componentWillMount(){
    const { unit, unitBadge } = this.props;
    const { badge } = this.state;
    if (unitBadge) {
      Object.keys(unitBadge).map(item => badge[item] = unitBadge[item]);
    }

    const { url } = badge;
    badge.image = url;
    delete badge.url;
    badge['unit'] = unit.id;

    this.setState({
      badge,
      imagePreviewUrl: unitBadge ? unitBadge.url: ''
    });
  }

  isValid(field = null) {
    let validate = Validator.createValidator({
      name: ['required', 'minLength|6'],
      slug: ['required', 'minLength|6'],
      criteria: ['required', 'url'],
      description: ['required', 'minLength|10', 'maxLength|450'],
      unit: ['required']
    }, this.state.badge, field);

    let { isValid, errors } = validate;

    this.setState({ errors });

    return isValid;
  }

  onChange(event) {
    const { name, value } = event.target;
    const { badge } = this.state;
    badge[name] = value;

    this.setState({ badge }, () => this.isValid(name));
  }

  onImageDrop(files) {
    if (files.length === 0 || !isObject(files[0]))
      return toastr.error('File Selection Error');

    const { badge } = this.state;
    badge.image = files[0];

    this.setState({
      badge,
      imagePreviewUrl: files[0].preview
    });
  }

  onDeleteLoadedFile(){
    const { badge } = this.state;
    badge.image = {};
    this.setState({
      imagePreviewUrl: '',
      badge
    });
  }

  onCancel(event) {
    event.preventDefault();
    const { actions, unitBadge } = this.props;
    if (unitBadge) {
      return actions.unloadForm(`edit-unit-badge-${unitBadge.id}`);
    }
    actions.unloadForm('add-new-badge-to-a-unit');
  }

  onSave(event) {
    event.preventDefault();

    const { actions, unitBadge } = this.props;
    const { badge } = this.state;

    if(isEmpty(badge.image)){
      return toastr.error('Badge image is required');
    }

    if (! this.isValid()) return false;

    if (unitBadge) {
      return this.updateBadge();
    }
    actions.addUnitBadge(this.formatData(), badge.unit)
      .then(() => actions.unloadForm('add-new-badge-to-a-unit'));
  }

  updateBadge() {
    const { badge } = this.state;
    const { actions, unitBadge } = this.props;

    actions.updateUnitBadge(this.formatData(), unitBadge.id, badge.unit)
      .then(() => actions.unloadForm(`edit-unit-badge-${unitBadge.id}`));
  }

  formatData(){
    const { badge } = this.state;
    let formData = new FormData();
    formData.append('name', badge.name);
    formData.append('slug', badge.slug);
    formData.append('criteria', badge.criteria);
    formData.append('unit', badge.unit);
    if(isObject(badge.image)){
      formData.append('image', badge.image);
    }

    formData.append('description', badge.description);
    return formData;
  }

  render() {
    const { isSubmittingBadge, unitBadge, isUpdatingBadge } = this.props;
    const isSubmitting = isSubmittingBadge.status;
    const isUpdating = isUpdatingBadge.status;
    const { errors, badge, imagePreviewUrl } = this.state;

    const submitBtnText = unitBadge ?
      isUpdating ? 'Updating badge...': 'Update badge' :
      isSubmitting ? 'Adding badge...':'Add badge';

    return(
      <div className="add-job-form">
        <div className="row form-group">
          <div className="col-sm-12">
            <TextInput
              error={errors.name}
              name="name"
              label="Badge name"
              placeholder="Badge name"
              onChange={this.onChange}
              value={badge.name}/>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-sm-6">
            <TextInput
              error={errors.slug}
              name="slug"
              placeholder="Badge slug"
              label="Badge slug"
              onChange={this.onChange}
              value={badge.slug}/>
          </div>
          <div className="col-sm-6">
            <TextInput
              error={errors.criteria}
              name="criteria"
              placeholder="Badge criteria url"
              label="Badge criteria url"
              onChange={this.onChange}
              value={badge.criteria}/>
          </div>
        </div>
        {
          imagePreviewUrl === '' ?
            <Dropzone
              style={styles.dropzoneStyle}
              activeStyle={styles.activeStyle}
              multiple={false}
              accept="image/*"
              onDrop={this.onImageDrop}>
              <p style={styles.placeholder}>
                Drop an image or click to select file to upload.
              </p>
            </Dropzone>
            : (
              <div className="portfolio-file-selector-container loaded-file">
                <span className="loaded-image-container">
                  <img
                    className="loaded-image badge-image-admin"
                    src={imagePreviewUrl}/>
                </span>
                <span
                  onClick={this.onDeleteLoadedFile}
                  className="btn">
                  <i className="fa fa-trash upload-icon"/>
                </span>
              </div>
            )
        }

        <div className="row form-group">
          <div className="col-sm-12">
            <TextArea
              label="Description"
              name="description"
              error={errors.description}
              placeholder="A short badge description"
              rows="4"
              cols=""
              onChange={this.onChange}
              value={badge.description}/>
          </div>
        </div>
        <div className="form-group text-right">
          <button
            type="button"
            name="button"
            className="btn btn-secondary"
            disabled={isSubmitting || isUpdating}
            onClick={this.onCancel}>
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary float-right"
            name="button"
            disabled={isSubmitting || isUpdating}
            onClick={this.onSave}>
            {submitBtnText}
          </button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const { skillBuilders } = state;
  let badge = {
    name: '',
    slug: '',
    image: {},
    criteria: '',
    description: '',
    unit: ''
  };
  return {
    badge,
    isSubmittingBadge: skillBuilders.isSubmittingBadge,
    isUpdatingBadge: skillBuilders.isUpdatingBadge,
  };
};
const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, componentsActions, unitActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UnitBadgeForm);
