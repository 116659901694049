import PropTypes from 'prop-types';
import React from 'react';
import CommentButton from '../../../containers/skill-builders/discussions/CommentButton';
import ShareButton from '../../../containers/skill-builders/discussions/ShareButton';
import PostLikeButton from '../../../containers/skill-builders/discussions/PostLikeButton';

const SocialActions = ({type, discussion, comment, isAuthenticated}) => {
  return (
    <div className="post-actions">
      <PostLikeButton 
        discussion={discussion} 
        comment={comment}/>
      {type !== 'comment' ? <CommentButton discussion={discussion} isAuthenticated={isAuthenticated}/> : ''}
      {type !== 'comment' ? <ShareButton discussion={discussion}/> : ''}
    </div>
  );
};

SocialActions.propTypes = {
  type: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  discussion: PropTypes.object,
  comment: PropTypes.object
};

export default SocialActions;
