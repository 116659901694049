import React from 'react';
import PropTypes from 'prop-types';

const SkillsList = ({
  skills,
  selectedCustomSkills,
  selectCustomSkills,
  removeCustomSkills
}) => {
  return (
    <div className="skill-suggestions">
      {skills.map(skillSet =>
        (<div className="skill-box" key={skillSet.name}>
          {skillSet.infoUrl ?
            <div className="ellipsed-wrapper">
              <a href={skillSet.infoUrl} target="_blank">
                {skillSet.name}
              </a>
            </div> :
            <div className="ellipsed-wrapper">
              <span>{skillSet.name}</span>
            </div>}

          {!selectedCustomSkills.includes(skillSet.id) && (
            <i
              aria-hidden="true"
              className="icon-add pointer"
              data-value={skillSet.id}
              onClick={selectCustomSkills} />
          )}
          {selectedCustomSkills.includes(skillSet.id) && (
            <i
              aria-hidden="true"
              style={{ color: '#F04E30' }}
              className="icon-checkbox orange pointer"
              data-value={skillSet.id}
              onClick={removeCustomSkills}/>
          )}
        </div>))}
    </div>
  );
};

SkillsList.propTypes = {
  skills: PropTypes.array.isRequired,
  selectCustomSkills: PropTypes.func.isRequired,
  removeCustomSkills: PropTypes.func.isRequired,
  selectedCustomSkills: PropTypes.array.isRequired
};

export default SkillsList;
