import PropTypes from 'prop-types';
import React from 'react';

const LoginFormComponent = ({
  formData,
  user,
  errors,
  isRequesting,
  onChange,
  onSubmit,
  handleKeyPress,
  toggleForgotPassword
}) => {
  return (
    <div
      style={{margin: '10px auto'}}
      className="modal-body homepage-modal-body invitation-login">
      <h4 className="modal-title m-0" id="myModalLabel">
        Sign in to become an admin
      </h4>
      <div className="login-form">
        <div style={{ textAlign: 'center'}}>
          {
            user.avatar && user.avatar !== '' ?
              <img
                className="img-responsive avatar"
                src={user.avatar}
                alt={`${user.name}'s Avatar`}/>:
              <span
                style={{
                  fontSize: 20,
                  lineHeight: '40px',
                  display: 'inline-block'
                }}
                className="img-responsive avatar initial-avatar">
                {user.name && user.name.slice(0,1)}
              </span>
          }
          <p>{user.name}</p>
        </div>
        <form
          onKeyPress={handleKeyPress}
          onSubmit={onSubmit}>
          <div className="form-group">
            <label className="sr-only">Password</label>
            <input
              type="password"
              name="password"
              className="form-control"
              placeholder="Password"
              value={formData.password}
              onChange={onChange}/>
            {errors.password && <div className="text-danger">{errors.password}</div>}
          </div>

          <input
            type="submit"
            disabled={isRequesting}
            value={isRequesting ? 'Authenticating...' : 'Sign In'}
            className="btn btn-primary w-100"/>
          <a onClick={toggleForgotPassword} className="forgot-password link-orange">Forgot Password?</a>
        </form>
      </div>
    </div>
  );
};

LoginFormComponent.propTypes = {
  formData: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  handleKeyPress: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  credentials: PropTypes.object,
  toggleForgotPassword: PropTypes.func.isRequired
};

export default LoginFormComponent;
