import PropTypes from 'prop-types';
import React from 'react';
import SidebarUserInfo from '../../../components/common/SidebarUserInfo';
import DiscussionsPostFormContainer from '../../../containers/skill-builders/discussions/DiscussionsPostFormContainer';

import { Row, Col } from 'reactstrap';

const FormAndSideBar = ({
  isAuthenticated,
  profile,
  route,
  unit
}) => {
  return(
    <div className="container community-container mb-3">
      {
        isAuthenticated &&
          <Row>
            <Col className="justify-content-center">
              <div className="community-grid mt-0">
                <SidebarUserInfo profile={profile}/>
                <DiscussionsPostFormContainer route={route} unit={unit}/>
              </div>
            </Col>
          </Row>
      }
    </div>
  );
};

FormAndSideBar.propTypes = {
  unit: PropTypes.object.isRequired,
  route: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  profile: PropTypes.object
};

export default FormAndSideBar;
