import PropTypes from 'prop-types';
import React from 'react';

const ForgotPasswordForm = ({ toggleForgotPassword, onChange, email, errors, onSubmit, isSubmitting, data }) => {
  const success = !isSubmitting && data.status === 200;

  return(
    <div>
      <div className="modal-body homepage-modal-body">
        <button onClick={toggleForgotPassword} className="close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 id="myModalLabel">Forgot your password?</h4>
        {
          success &&
            <div>
              <p className="success-message">Reset password link email sent successfully.</p>
            </div>
        }
        {
          !success &&
            <form onSubmit={onSubmit}>
              <div className="form-group">
                <p>Please enter your email.</p>
                <label className="sr-only">Email</label>
                <input
                  type="text"
                  name="email"
                  value={email}
                  onChange={onChange}
                  className="form-control"
                  placeholder="Email"/>
                {
                  errors.email
                  &&
                    <div className="text-danger">{errors.email}</div>
                }
                <input
                  type="submit"
                  value={isSubmitting ? 'Submitting ...': 'Submit'}
                  className="btn btn-primary w-100 mt-3"/>
              </div>
            </form>
        }
      </div>
    </div>
  );
};
ForgotPasswordForm.propTypes = {
  toggleForgotPassword: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  email: PropTypes.string,
  errors: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired
};
export default ForgotPasswordForm;
