import { CALL_API } from '../../middlewares/api';

import {
  INTERVIEW_PREP_VIDEOS_REQUEST,
  INTERVIEW_PREP_VIDEOS_SUCCESS,
  INTERVIEW_PREP_VIDEOS_FAILURE,
  UNIT_INTERVIEW_PREP_VIDEO_REQUEST,
  UNIT_INTERVIEW_PREP_VIDEO_SUCCESS,
  UNIT_INTERVIEW_PREP_VIDEO_FAILURE,
  UNIT_VIDEO_COMMENTS_REQUEST,
  UNIT_VIDEO_COMMENTS_SUCCESS,
  UNIT_VIDEO_COMMENTS_FAILURE,
  LIKE_VIDEO_REQUEST,
  LIKE_VIDEO_SUCCESS,
  LIKE_VIDEO_FAILURE,
  UNLIKE_VIDEO_REQUEST,
  UNLIKE_VIDEO_SUCCESS,
  UNLIKE_VIDEO_FAILURE,
  ADD_VIDEO_COMMENT_REQUEST,
  ADD_VIDEO_COMMENT_SUCCESS,
  ADD_VIDEO_COMMENT_FAILURE,
  ADD_VIDEO_COMMENT_REPLY_REQUEST,
  ADD_VIDEO_COMMENT_REPLY_SUCCESS,
  ADD_VIDEO_COMMENT_REPLY_FAILURE,
  LIKE_VIDEO_COMMENT_REQUEST,
  LIKE_VIDEO_COMMENT_SUCCESS,
  LIKE_VIDEO_COMMENT_FAILURE,
  UNLIKE_VIDEO_COMMENT_REQUEST,
  UNLIKE_VIDEO_COMMENT_SUCCESS,
  UNLIKE_VIDEO_COMMENT_FAILURE,
  LIKE_VIDEO_COMMENT_REPLY_REQUEST,
  LIKE_VIDEO_COMMENT_REPLY_SUCCESS,
  LIKE_VIDEO_COMMENT_REPLY_FAILURE,
  UNLIKE_VIDEO_COMMENT_REPLY_REQUEST,
  UNLIKE_VIDEO_COMMENT_REPLY_SUCCESS,
  UNLIKE_VIDEO_COMMENT_REPLY_FAILURE,
  DELETE_VIDEO_COMMENT_REQUEST,
  DELETE_VIDEO_COMMENT_SUCCESS,
  DELETE_VIDEO_COMMENT_FAILURE,
  DELETE_VIDEO_COMMENT_REPLY_REQUEST,
  DELETE_VIDEO_COMMENT_REPLY_SUCCESS,
  DELETE_VIDEO_COMMENT_REPLY_FAILURE,
  UPDATE_VIDEO_COMMENT_REQUEST,
  UPDATE_VIDEO_COMMENT_SUCCESS,
  UPDATE_VIDEO_COMMENT_FAILURE,
  UPDATE_VIDEO_COMMENT_REPLY_REQUEST,
  UPDATE_VIDEO_COMMENT_REPLY_SUCCESS,
  UPDATE_VIDEO_COMMENT_REPLY_FAILURE,
} from '../../constants';

export const fetchInterviewPrepVideos = () => {
  const endpoint = 'interview/resources';

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        INTERVIEW_PREP_VIDEOS_REQUEST,
        INTERVIEW_PREP_VIDEOS_SUCCESS,
        INTERVIEW_PREP_VIDEOS_FAILURE,
      ],
    },
  };
};

export const fetchUnitInterviewPrepVideo = (videoId) => {
  const endpoint = `interview/resources/${videoId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        UNIT_INTERVIEW_PREP_VIDEO_REQUEST,
        UNIT_INTERVIEW_PREP_VIDEO_SUCCESS,
        UNIT_INTERVIEW_PREP_VIDEO_FAILURE,
      ],
    },
  };
};

export const getUnitVideoComments = (videoId) => {
  const endpoint = `interview/comments?resource=${videoId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        UNIT_VIDEO_COMMENTS_REQUEST,
        UNIT_VIDEO_COMMENTS_SUCCESS,
        UNIT_VIDEO_COMMENTS_FAILURE,
      ],
    },
  };
};

export const onLikeVideo = (data) => {
  const endpoint = 'interview/likes';

  return {
    [CALL_API]: {
      endpoint,
      method: 'post',
      id: data.resource,
      data,
      authenticated: true,
      types: [LIKE_VIDEO_REQUEST, LIKE_VIDEO_SUCCESS, LIKE_VIDEO_FAILURE],
    },
  };
};

export const unlikeVideo = (resourceId) => {
  const endpoint = `interview/likes/${resourceId}`;

  return {
    [CALL_API]: {
      endpoint,
      id: resourceId,
      method: 'delete',
      authenticated: true,
      types: [UNLIKE_VIDEO_REQUEST, UNLIKE_VIDEO_SUCCESS, UNLIKE_VIDEO_FAILURE],
    },
  };
};

export const addCommentToVideo = (data, reply) => {
  const endpoint = 'interview/comments';

  return {
    [CALL_API]: {
      endpoint,
      data,
      id: reply && reply === 'reply' ? data.parent : data.resource,
      method: 'post',
      authenticated: true,
      types:
        reply && reply === 'reply'
          ? [
            ADD_VIDEO_COMMENT_REPLY_REQUEST,
            ADD_VIDEO_COMMENT_REPLY_SUCCESS,
            ADD_VIDEO_COMMENT_REPLY_FAILURE,
          ]
          : [
            ADD_VIDEO_COMMENT_REQUEST,
            ADD_VIDEO_COMMENT_SUCCESS,
            ADD_VIDEO_COMMENT_FAILURE,
          ],
    },
  };
};

export const onLikeVideoComment = (data, reply) => {
  const endpoint = 'interview/comment_likes';

  return {
    [CALL_API]: {
      endpoint,
      method: 'post',
      id: data.comment,
      data,
      authenticated: true,
      types:
        reply && reply === 'reply'
          ? [
            LIKE_VIDEO_COMMENT_REPLY_REQUEST,
            LIKE_VIDEO_COMMENT_REPLY_SUCCESS,
            LIKE_VIDEO_COMMENT_REPLY_FAILURE,
          ]
          : [
            LIKE_VIDEO_COMMENT_REQUEST,
            LIKE_VIDEO_COMMENT_SUCCESS,
            LIKE_VIDEO_COMMENT_FAILURE,
          ],
    },
  };
};

export const unLikeVideoComment = (commentId, reply) => {
  const endpoint = `interview/comment_likes/${commentId}`;

  return {
    [CALL_API]: {
      endpoint,
      id: commentId,
      method: 'delete',
      authenticated: true,
      types:
        reply && reply === 'reply'
          ? [
            UNLIKE_VIDEO_COMMENT_REPLY_REQUEST,
            UNLIKE_VIDEO_COMMENT_REPLY_SUCCESS,
            UNLIKE_VIDEO_COMMENT_REPLY_FAILURE,
          ]
          : [
            UNLIKE_VIDEO_COMMENT_REQUEST,
            UNLIKE_VIDEO_COMMENT_SUCCESS,
            UNLIKE_VIDEO_COMMENT_FAILURE,
          ],
    },
  };
};

export const deleteVideoComment = (id, parentId) => {
  const endpoint = `interview/comments/${id}`;
  return {
    [CALL_API]: {
      endpoint,
      id: id,
      query: parentId,
      method: 'delete',
      authenticated: true,
      types: parentId
        ? [
          DELETE_VIDEO_COMMENT_REPLY_REQUEST,
          DELETE_VIDEO_COMMENT_REPLY_SUCCESS,
          DELETE_VIDEO_COMMENT_REPLY_FAILURE,
        ]
        : [
          DELETE_VIDEO_COMMENT_REQUEST,
          DELETE_VIDEO_COMMENT_SUCCESS,
          DELETE_VIDEO_COMMENT_FAILURE,
        ],
    },
  };
};

export const updateVideoComment = (data, commentId, reply) => {
  const endpoint = `interview/comments/${commentId}`;
  return {
    [CALL_API]: {
      endpoint,
      data,
      id: commentId,
      method: 'patch',
      authenticated: true,
      types:
        reply && reply === 'reply'
          ? [
            UPDATE_VIDEO_COMMENT_REPLY_REQUEST,
            UPDATE_VIDEO_COMMENT_REPLY_SUCCESS,
            UPDATE_VIDEO_COMMENT_REPLY_FAILURE,
          ]
          : [
            UPDATE_VIDEO_COMMENT_REQUEST,
            UPDATE_VIDEO_COMMENT_SUCCESS,
            UPDATE_VIDEO_COMMENT_FAILURE,
          ],
    },
  };
};
