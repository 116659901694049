import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { checkHttp } from '../../../utils';

const styles = {
  userNameEditIcon: {
    cursor: 'pointer',
    marginLeft: 10,
  },

  editActions: {
    position: 'relative',
  }
};

const CertificationSectionCard = ({
  certificationDetails,
  onEditCertification,
  onRemoveCertification
}) => {
  const isStartDateObject = typeof certificationDetails.startdate === 'object';
  const startDateString = isStartDateObject ? certificationDetails.startdate.date :
    certificationDetails.startdate === '' ? undefined : certificationDetails.startdate;

  const isEndDateObject = typeof certificationDetails.enddate === 'object';
  const endDateString = isEndDateObject ? certificationDetails.enddate.date :
    certificationDetails.enddate === '' ? undefined : certificationDetails.enddate;

  const startDate = moment(startDateString).format('MM/YYYY');
  let endDate;

  if (certificationDetails.noend) {
    endDate = 'Present';
  } else {
    endDate = moment(endDateString).format('MM/YYYY');
  }

  const urlAvailable = certificationDetails.url !== '';
  const urlString = checkHttp(certificationDetails.url) ?
    certificationDetails.url : `//${certificationDetails.url}`;

  return (
    <div className="resume-section section-item">
      <span style={styles.editActions} className="float-right">
        <i
          onClick={onEditCertification}
          id={certificationDetails.id}
          style={styles.userNameEditIcon}
          className="fa fa-pencil"/>

        <i
          onClick={onRemoveCertification}
          id={certificationDetails.id}
          style={styles.userNameEditIcon}
          className="fa fa-close"/>
      </span>
      <span>{certificationDetails.name} - {!urlAvailable ?
        certificationDetails.authority :
        <a href={urlString} target="_blank" rel="noopener noreferrer">{certificationDetails.authority}</a>}
      </span>
      <span style={{marginLeft: 10}}>({startDate} - {endDate})</span>
      <p>License Number: {certificationDetails.license}</p>
    </div>
  );
};

CertificationSectionCard.propTypes = {
  certificationDetails: PropTypes.object.isRequired,
  onEditCertification: PropTypes.func.isRequired,
  onRemoveCertification: PropTypes.func.isRequired
};

export default CertificationSectionCard;
