import React, { useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { openModal, closeModal } from '../../redux/actions/components';
import { createSubscription } from '../../redux/actions/subscriptions';

import { useLocation, Link } from 'react-router-dom';

import { isNull } from 'lodash';

import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Subscription = ({ modal, actions }) => {
  const { state } = useLocation();

  const defaultTab = !isNull(state) ? state.defaultTab : 'for-individuals';

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeTab, setActiveTab] = useState(defaultTab);

  function toggleTabs(tab) {
    return () => {
      setActiveTab(tab);
    };
  }

  function toggleSignUpModal(event) {
    event.preventDefault();

    if (modal === 'signup-modal') {
      actions.closeModal();
      return;
    }

    actions.openModal('signup-modal');
  }

  function toggleRecruiterListModal() {
    if (modal === 'recruiter-list-modal') {
      actions.closeModal();
      return;
    }

    actions.openModal('recruiter-list-modal');
  }

  async function onSubmit(event) {
    const { name: productName } = event.target;

    setIsSubmitting(!isSubmitting);

    const store = await actions.createSubscription({ productName });

    setIsSubmitting(false);
    window.location.href = store.subscriptions.data.url;
  }

  return (
    <div className="interior essential  homepage_nli">
      <Helmet title="Sign Up"/>
      <div className="talent-development">
        <div className="toolkit">
          <header>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h1 style={{position: 'relative', top: -24}}>
                    Sign up to CareerPrepped
                  </h1>
                </div>
              </div>
            </div>
          </header>
          <section className="sign-up-tabs">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className={classNames(
                          'nav-link',
                          { active: activeTab === 'for-individuals' }
                        )}
                        onClick={toggleTabs('for-individuals')}
                        id="for-individuals-tab"
                        data-toggle="tab"
                        data-target="#for-individuals"
                        type="button"
                        role="tab"
                        aria-controls="for-individuals"
                        aria-selected="true">
                        For Talent
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={classNames(
                          'nav-link',
                          { active: activeTab === 'for-organizations' }
                        )}
                        onClick={toggleTabs('for-organizations')}
                        id="for-organizations-tab"
                        data-toggle="tab"
                        data-target="#for-organizations"
                        type="button"
                        role="tab"
                        aria-controls="for-organizations"
                        aria-selected="false">
                        For Talent Developers
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={classNames(
                          'nav-link',
                          { active: activeTab === 'for-employers' }
                        )}
                        onClick={toggleTabs('for-employers')}
                        id="for-employers-tab"
                        data-toggle="tab"
                        data-target="#for-employers"
                        type="button"
                        role="tab"
                        aria-controls="for-employers"
                        aria-selected="false">
                        For Talent Employers
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className={classNames(
                        'tab-pane fade',
                        { 'show active': activeTab === 'for-individuals' }
                      )}
                      id="for-individuals" role="tabpanel" aria-labelledby="home-tab">
                      <div className="tab-grid">
                        <div className="individuals-sign-up-card">
                          <div className="sign-up-card-title">Free Sign Up</div>
                          <p>Got a group code?<br/>Sign up for free using your group code.</p>
                          <a
                            href="#"
                            className="ml-3 mr-3 btn btn-primary"
                            onClick={toggleSignUpModal}>
                            Sign Up with Group Code
                          </a>
                        </div>
                        <div className="individuals-sign-up-card">
                          <div className="sign-up-card-title">Individual Talent Subscription</div>
                          <p><strong>$8/month</strong><br/>Cancel anytime you want</p>
                          <button
                            onClick={onSubmit}
                            name="TALENT"
                            disabled={isSubmitting}
                            className="ml-3 mr-3 btn btn-primary text-center">
                            {isSubmitting ? 'Submitting...' : 'Start My Subscription'}
                          </button>
                        </div>
                      </div>
                      <div className="tab-grid grid-full mt-3">
                        <div className="individuals-sign-up-card p-3">
                          <h5>Elevate Your Career Success with Unlimited Access to the<br/>CareerPrepped Toolkit & Community</h5>
                          <div className="split-list">
                            <ul className="benefit-list list-left">
                              <li><span>Career Site</span></li>
                              <li><span>Resume Builder</span></li>
                              <li><span>Job Match</span></li>
                              <li><span>Job Tracker</span></li>
                              <li><span>Interview Prep Video Library</span></li>
                            </ul>
                            <ul className="benefit-list list-right">
                              <li><span>Career Portfolio</span></li>
                              <li><span>Skill Builders for Essential Skills</span></li>
                              <li><span>Skill Badges for Essential Skills</span></li>
                              <li><span>Skill Feedback & Ratings</span></li>
                              <li><span>Skill Endorsements & Testimonials</span></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={classNames(
                        'tab-pane fade',
                        { 'show active': activeTab === 'for-organizations' }
                      )}
                      id="for-organizations"
                      role="tabpanel"
                      aria-labelledby="profile-tab">
                      <div className="tab-grid grid-full">
                        <div className="organizations-card text-center">
                          <div className="sign-up-card-title">Talent Developer Subscription</div>
                          <div className="d-flex">
                            <div className="organizations-pricing-info">
                              <p className="price">$100<span className="sup-price">/Month</span></p>
                              <p className="sub-text orange">Up to 100 users</p>
                              <p className="sub-text size-up"><strong>Only $1/month</strong> per additional user</p>
                              <p className="price">$1,000<span className="sup-price">/Month</span></p>
                              <p className="sub-text orange mb-3">Unlimited Users</p>
                              <p className="sub-text smaller">Monthly Subscription<br/>Cancel Anytime!</p>
                              <p className="sub-text">Compare to our individual subscription at $8/user per month - sponsor access for your users at a fraction of the cost! Plus, all sponsored users keep <strong>lifetime access</strong>, even if you cancel!</p>

                              <button
                                onClick={onSubmit}
                                name="TALENT_DEVELOPER"
                                disabled={isSubmitting}
                                className="btn btn-primary text-center">
                                {isSubmitting ? 'Submitting...' : 'Start My Subscription'}
                              </button>
                            </div>
                            <div className="organizations-benefits">
                              <h4>Effortlessly Scale Career Support Services</h4>
                              <p>Boost job placement and empower users to take control of their career success with a comprehensive suite of self-help tools.</p>

                              <h4>Free Access to Admin Tools & Support Resources</h4>
                              <p>Organize users in unlimited groups, assign unlimited admins, and use our resources to enrich your programs/services with stats on usage.</p>

                              <h4>Engage Employers in a New Virtual Way</h4>
                              <p>Help your users prove their skills with evidence and engage your industry partners in the process, connecting them with potential hires in a meaningful way.</p>

                              <h4>Differentiate Your Institution Among Employers</h4>
                              <p>Position your institution as a top talent source by offering candidates with evidence-backed skill claims.</p>

                              <h4>Boost Your Institution's Appeal with Prospects</h4>
                              <p>Offer comprehensive, lifelong career support, positioning your institution as the go-to place for individuals seeking to land their dream jobs.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={classNames(
                        'tab-pane fade',
                        { 'show active': activeTab === 'for-employers' }
                      )}
                      id="for-employers"
                      role="tabpanel"
                      aria-labelledby="contact-tab">
                      <div className="tab-grid">
                        <div className="individuals-sign-up-card">
                          <div className="sign-up-card-title">Free Industry Mentor Sign Up</div>
                          <p>Help prepare the future workforce.<br/>Connect with potential hires in a meaningful way.</p>
                          <Link
                            to="/industry-mentor"
                            className="ml-3 mr-3 btn btn-primary">
                            Learn More & Sign Up
                          </Link>
                        </div>
                        <div className="individuals-sign-up-card">
                          <div className="sign-up-card-title">Talent Employer Subscription</div>
                          <p>Recruit talent with verifiable skills.<br/>Reduce employee recruitment, training and turnover costs.</p>
                          <a
                            href="#"
                            className="ml-3 mr-3 btn btn-primary"
                            onClick={toggleRecruiterListModal}>
                            Join Our Recruiter List
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

Subscription.propTypes = {
  modal: PropTypes.string,
  actions: PropTypes.object.isRequired
};

const mapStateToProps = ({ components: { modal } }) => ({ modal });

const mapDispatchToProps = dispatch =>
  ({ actions: bindActionCreators({ openModal, closeModal, createSubscription }, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
