import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const UsersTableFilesCell = (props) => {
  const {user, profile, institutionId} = props;
  return (
    <Link
      title={`Link to ${user.name}'s skills page`}
      target="_blank"
      to={
        user.id !== profile?.id ?
          `/myskills/${user.id}?username=${user.name}&institutionId=${institutionId}#otherSkills` :
          '/myskills#otherSkills'
      }>
      {user.totalSkills}
    </Link>
  );
};

UsersTableFilesCell.propTypes = {
  user: PropTypes.object.isRequired,
  profile: PropTypes.object,
  institutionId: PropTypes.string
};

export default UsersTableFilesCell;
