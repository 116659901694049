import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import pick from 'lodash/pick';
import isUndefined from 'lodash/isUndefined';
import { url } from '../../../validator/rules';
import { parseJSON, uniqueId } from '../../../utils';
import { toastr } from 'react-redux-toastr';
import update from 'immutability-helper';

import ImageForm from '../../../components/skill-builders/admin/ImageForm';

import {
  uploadSBFile,
  deleteFile,
  resetRecentUploadedFileId
} from '../../../redux/actions/files';

import {
  saveSection,
  updateSection,
  deleteSection
} from '../../../redux/actions/sections';

import { updateUnit } from '../../../redux/actions/skill-builders';

import {
  unloadUpdateForm,
  unloadSectionForm
} from '../../../redux/actions/components';

function Image(props) {
  const {
    files,
    imagesList,
    sectionObject,
    sections,
    actions,
    unit,
    units,
    isAdmin,
    onCancel,
    isSubmitting,
    isUpdating,
    isDeleting,
    formType
  } = props;

  const [caption, setCaption] = useState('');
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState({});
  const [deletedFileId, setDeletedFileId] = useState(null);
  const [images, setImages] = useState([...imagesList]);
  const [section, setSection] = useState(Object.assign({}, sectionObject));

  useEffect(() => {
    if (deletedFileId) {
      setImages(prevState => prevState.filter(image => image.id !== deletedFileId));
      setDeletedFileId(null);
    }

    if (files.isUploading.recentId) {
      let image = files.skillbuilderFiles[0];
      image = pick(image, ['id', 'url', 'external', 'description', 'title']);

      setImages(prevState => ([...prevState, image]));
      setCaption('');
      setDescription('');
    }
  }, [files, deletedFileId]);

  async function onImageDrop(acceptedFiles) {
    if (acceptedFiles.length === 0)
      return toastr.error('No files selected');

    if (caption === '')
      return toastr.error('Image title can\'t be empty');

    if (description === '')
      return toastr.error('Image description can\'t be empty');

    let formData = new FormData();

    formData.append('file', acceptedFiles[0]);
    formData.append('title', caption);
    formData.append('description', description);
    formData.append('section', 'skillbuilder');

    await actions.uploadSBFile(formData);

    actions.resetRecentUploadedFileId();
    toastr.success('File uploaded successfully');
  }

  function updateSectionState(event) {
    event.preventDefault();

    const { name, value } = event.target;

    switch(name) {
    case 'description':
      setDescription(value);
      break;

    case 'caption':
      setCaption(value);
      break;

    default:
      setSection(prevState => ({ ...prevState, [name]: value }));
    }
  }

  function moveCard(dragIndex, hoverIndex) {
    const dragItem = images[dragIndex];

    setImages(prevState => update(prevState, {
      images: {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragItem]
        ]
      }
    }));

    if (! section.id) return;

    const sectionIndex = sections.findIndex(sectionItem =>
      sectionItem.id === section.id);

    const sectionData = formatData(images);

    sectionData.id = section.id;

    actions.updateSection(sectionData, sectionIndex);
  }

  function formatData(images) {
    const { title, sectiontype } = section;
    const content = JSON.stringify({ title, images });

    return {
      content,
      unit: unit.id,
      sectiontype
    };
  }

  async function saveSection(event) {
    event.preventDefault();

    const { url: urlString } = section;
    const validateUrl = url(urlString);

    if (! isUndefined(validateUrl)) {
      setErrors({ url: validateUrl });
      return;
    }

    if (! isUndefined(urlString) && urlString.length !== 0) {
      images.push({ id: null, url: urlString });
    }

    if (images.length === 0) {
      setErrors({ url: 'Enter URL to a web accessible image or upload image' });
      return;
    }

    const formId = event.target.closest('form').id;
    const formIdArray = formId.split('_');
    const formIndex = parseInt(formIdArray[1]);
    const sectionData = formatData(images);

    if (section.id) {
      sectionData.id = section.id;

      const sectionIndex = sections.findIndex(sectionItem =>
        sectionItem.id === section.id);

      await actions.updateSection(sectionData, sectionIndex);

      toastr.success('Section Updated.');
      actions.unloadUpdateForm(String(section.id));

      return;
    }

    const unitIndex = units.findIndex(unitItem => unitItem.id === unit.id);

    await actions.saveSection(sectionData, unit, unitIndex);

    toastr.success('Section saved.');
    actions.unloadSectionForm({ id: formIndex });
  }

  function addListItem(event) {
    event.preventDefault();

    let { content } = section;
    content.push({ itemId: uniqueId(), value: ''});

    setSection(prevState => ({ ...prevState, content }));
  }

  function deleteListItem(event) {
    event.preventDefault();

    const itemId = event.target.closest('.row').id;

    setSection(prevState => ({
      ...prevState,
      content: prevState.content.filter(item => item.id !== itemId)
    }));
  }

  async function deleteSection(event) {
    event.preventDefault();

    await actions.deleteSection(section.id);

    const sectionsArray = unit.sectionsOrder.split(',');
    const sectionsList = [...sectionsArray.filter(id => id !== section.id)];
    const sectionsOrder = sectionsList.join();
    const unitData = Object.assign({}, unit, { sectionsOrder });

    const unitIndex = units.findIndex(unitItem => unitItem.id === unit.id);

    await actions.updateUnit(unitData, unitIndex);

    toastr.success('Section deleted.');
    actions.unloadUpdateForm(String(section.id));
  }

  async function deleteImage(event) {
    const { id } = event.target;

    setDeletedFileId(id);
    await actions.deleteFile(id);
  }

  return (
    <ImageForm
      caption={caption}
      description={description}
      isUploading={files.isUploading.status}
      isAdmin={isAdmin}
      errors={errors}
      section={section}
      onCancel={onCancel}
      isSubmitting={isSubmitting}
      isUpdating={isUpdating}
      sDeleting={isDeleting}
      formType={formType}
      onChange={updateSectionState}
      onSave={saveSection}
      onImageDrop={onImageDrop}
      onAddListItem={addListItem}
      onDeleteListItem={deleteListItem}
      onDelete={deleteSection}
      images={images}
      moveCard={moveCard}
      onDeleteImage={deleteImage}/>
  );
}

Image.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  formType: PropTypes.object,
  actions:PropTypes.object.isRequired,
  unit: PropTypes.object.isRequired,
  units: PropTypes.array.isRequired,
  sections: PropTypes.array.isRequired,
  sectionObject: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  imagesList: PropTypes.array.isRequired,
  files: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  let images = [];
  let section = { sectiontype: 'image', title: '', url: '' };
  const { files, sections, skillBuilders } = state;
  const { isSubmitting, isUpdating, isDeleting } = sections;
  const { section: sectionData } = ownProps;

  if (sectionData) {
    const { id, content } = sectionData;
    const data = parseJSON(content);
    let { url, title, images: imagesData } = data;

    if (! isUndefined(imagesData)) {
      imagesData = imagesData.map(image => (
        Object.assign({}, image, { title: image.description })
      ));

      images = [...imagesData];
    }

    section = Object.assign({}, section, { id, url, title });
  }

  return {
    imagesList: images,
    files,
    sectionObject: section,
    unit: ownProps.unit,
    isSubmitting,
    sections: sections.data,
    units: skillBuilders.data,
    isUpdating: isUpdating.status,
    isDeleting: isDeleting.status
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    updateUnit,
    uploadSBFile,
    deleteFile,
    resetRecentUploadedFileId,
    saveSection,
    updateSection,
    deleteSection,
    unloadUpdateForm,
    unloadSectionForm
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Image);
