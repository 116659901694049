import React, { useState } from 'react';

import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Input,
  Label,
  Form,
  FormGroup,
  Button,
  CardImg,
} from 'reactstrap';

import { isEmpty, isNull, isUndefined } from 'lodash';

import FileViewerModal from './FileViewerModal';
import Loading from '../common/Loading';
import SeePortfolioTipsModal from './SeePortfolioTipsModal';

const PortfolioEvidences = ({
  portfolioEvidenceRating,
  questions,
  feedbackData,
  feedbackResponse,
  proceed,
  name,
  toggleConfirmationModal,
  resourceData,
}) => {
  const [comment, setComment] = useState(
    portfolioEvidenceRating?.additionalComment
  );
  const [like, setLike] = useState(portfolioEvidenceRating?.like);
  const [multiOption, setMultiOption] = useState(
    portfolioEvidenceRating?.multiOption
  );
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);
  const [modalFileDetails, setModalFileDetails] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  const [isOpenPortfolioFilesTips, setIsOpenPortfolioFilesTips] = useState(false);

  function setMultiChoice(e) {
    const {
      target: { value: answerId },
    } = e;

    if (e.target.checked) {
      multiOption
        ? setMultiOption([...multiOption, answerId])
        : setMultiOption([answerId]);
    } else {
      setMultiOption(multiOption.filter(item => item !== answerId));
    }
  }

  function togglePortfolioFilesTips(event) {
    event.preventDefault();

    setIsOpenPortfolioFilesTips(!isOpenPortfolioFilesTips);
  }

  function savePortfolioEvidence(event) {
    const clickedButton = event.target.getAttribute('data-value');
    const {
      target: { value: step },
    } = event;

    let isErrorMessageAdded = false;
    let errorMessageDetails = {};

    if (clickedButton !== 'previous' && !like) {
      isErrorMessageAdded = !isErrorMessageAdded ? true : isErrorMessageAdded;
      errorMessageDetails = {...errorMessageDetails, radio: 'Required'};
    }

    if (clickedButton !== 'previous' && ((multiOption && multiOption.length === 0) || !multiOption)) {
      isErrorMessageAdded = !isErrorMessageAdded ? true : isErrorMessageAdded;
      errorMessageDetails = {...errorMessageDetails, check: 'Required'};
    }

    if(isErrorMessageAdded) {
      setErrorMessage(errorMessageDetails);
      return;
    }

    const feedback = {};
    if (like) {
      feedback['like'] = like;
    }
    if (multiOption) {
      feedback['multiOption'] = multiOption;
    }
    if (comment) {
      feedback['additionalComment'] = comment;
    }

    feedbackResponse('portfolioEvidenceRating', feedback);
    if (step === 'step4') {
      toggleConfirmationModal();
    } else proceed(step);
  }

  function handleComment(e) {
    if (e.target.value.split(' ').length <= 200) setComment(e.target.value);
  }

  function handleLike(event) {
    const {
      target: { value: answerId },
    } = event;

    setLike(answerId);
  }

  const getLikert = () => {
    return (
      <>
        {questions.likert.map(likert => (
          <FormGroup
            key={likert.answerId}
            check
            className="custom-radio-wrapper form-check pl-0">
            <Label check className="container">
              <span className="ml-3 font14"> {likert.answerText}</span>
              <Input
                className="mr-1"
                type="radio"
                name="radio1"
                invalid={!isEmpty(errorMessage) && !isUndefined(errorMessage?.radio)}
                defaultChecked={
                  likert.answerId === portfolioEvidenceRating?.like
                }
                value={likert.answerId}
                onChange={handleLike}/>
              <span className={`${!isEmpty(errorMessage) && errorMessage?.radio ? 'is-invalid checkmark' : 'checkmark'}`}/>
            </Label>
          </FormGroup>
        ))}
      </>
    );
  };

  const getmultichoice1 = (arr) => {
    return (
      <>
        {arr.map(a => (
          <FormGroup
            key={a.answerId}
            check
            className="pl-0 custom-checkbox-wrapper">
            <Label check className="container">
              {a.answerText}
              <Input
                type="checkbox"
                className="mr-1"
                value={a.answerId}
                defaultChecked={multiOption?.includes(a.answerId)}
                invalid={!isEmpty(errorMessage) && !isUndefined(errorMessage?.check)}
                onClick={setMultiChoice}/>
              <span className={`${!isEmpty(errorMessage) && errorMessage?.check ? 'is-invalid checkmark' : 'checkmark'}`} />
            </Label>
          </FormGroup>
        ))}
      </>
    );
  };

  const getMultiChoice = () => {
    if (questions.multichoice.length) {
      let p1 = questions.multichoice.slice(0, 5);
      let p2 = questions.multichoice.slice(5, questions.multichoice.length);
      return (
        <>
          <Row>
            <Col md="6">{getmultichoice1(p1)}</Col>
            <Col md="6">{getmultichoice1(p2)}</Col>
          </Row>
        </>
      );
    }
    return null;
  };

  function closeModal() {
    setIsFileModalOpen(false);
  }

  function openFileModal(event) {
    const title = event.target.getAttribute('data-title');
    const url = event.target.getAttribute('data-url');
    const mimetype = event.target.getAttribute('data-mimetype');
    const description = event.target.getAttribute('data-description');

    if(title && url && !isNull(mimetype)){
      const fileDetails = {
        title,
        url,
        mimetype,
        description
      };
      setIsFileModalOpen(true);
      setModalFileDetails(fileDetails);
    } else {
      setIsFileModalOpen(false);
    }
  }

  function evidenceList() {
    if(isUndefined(resourceData.files)){
      return <div className="px-3 d-flex justify-content-center w-100"><Loading /></div>;
    }
    if (!resourceData.files?.length === 0) {
      return (
        <p className="px-2 font-weight-bold">No portfolio evidence attached</p>
      );
    }
    return resourceData?.files?.map((file) => {
      const docFile =
        file.mimetype.includes('application') || file.mimetype.includes('text');
      return (
        <Col
          md="3"
          key={file.id}>
          <div
            className="port-file pointer"
            onClick={openFileModal}
            data-title={file.title}
            data-url={file.url}
            data-mimetype={file.mimetype}
            data-description={file.description}>
            {!docFile && file.mimetype !== '' && (
              <div
                className="portfolio-file-image-container"
                data-title={file.title}
                data-url={file.url}
                data-mimetype={file.mimetype}
                data-description={file.description}>
                <span>
                  <CardImg
                    top
                    data-title={file.title}
                    data-url={file.url}
                    data-mimetype={file.mimetype}
                    data-description={file.description}
                    src={file.url}
                    alt="Card image cap"/>
                </span>
              </div>
            )}
            <div
              data-title={file.title}
              data-url={file.url}
              data-mimetype={file.mimetype}
              data-description={file.description}>
              {docFile && (
                <div className="thumb baked-badge-thumb file-icon-container" data-title={file.title} data-url={file.url} data-mimetype={file.mimetype} data-description={file.description}>
                  <i
                    className="fa fa-file-text-o"
                    data-title={file.title}
                    data-url={file.url}
                    data-mimetype={file.mimetype}
                    data-description={file.description}/>
                </div>
              )}
              {file.mimetype === '' && (
                <div className="thumb baked-badge-thumb file-icon-container" data-title={file.title} data-url={file.url} data-mimetype={file.mimetype} data-description={file.description}>
                  <i
                    className="fa fa-youtube-play"
                    data-title={file.title}
                    data-url={file.url}
                    data-mimetype={file.mimetype}
                    data-description={file.description}/>
                </div>
              )}
              <div className="file-details" data-title={file.title} data-url={file.url} data-mimetype={file.mimetype} data-description={file.description}>
                <h5
                  data-title={file.title}
                  data-url={file.url}
                  data-mimetype={file.mimetype}
                  data-description={file.description}
                  className="fontW600 font14 text-truncate mb-1">
                  {file?.title}
                </h5>
              </div>
            </div>
          </div>
        </Col>
      );
    });
  }

  return (
    <Row>
      <Col lg="12">
        <div className="evidenceListControls">
          <Row>
            <Col lg="12">
              <section className="evidenceList">
                <div className="section-name">
                  <strong>Portfolio Evidence</strong>
                </div>
                <Row className="px-1">{evidenceList()}</Row>
              </section>
              {resourceData.files?.length > 0 && !isEmpty(modalFileDetails) && (
                <FileViewerModal
                  isFileModalOpen={isFileModalOpen}
                  closeModal={closeModal}
                  title={modalFileDetails.title}
                  url={modalFileDetails.url}
                  mimetype={modalFileDetails.mimetype}
                  description={modalFileDetails.description}/>
              )}
              <section className="questionstoadmin mt-3 px-2">
                <div className="question1">
                  <p>
                    <span className="font-weight-bold">
                      {questions.likertQuestionText}
                    </span>&nbsp;
                    <a href="#" onClick={togglePortfolioFilesTips}>
                      (Tips for evaluating portfolio files)
                    </a>
                  </p>
                  <Form className="answer">{getLikert()}</Form>
                  <div className="form-control-feedback">
                    {errorMessage.radio || ''}
                  </div>
                </div>
                <div className="question2 pt-1">
                  <div className="fontW600 font16 mt-2">
                    {questions.multiChoiceQuestionText}
                  </div>
                  <p className="font14 mb-3 text-secondary">
                    <em>(Select all that apply and/or leave your own comments)</em>
                  </p>
                  <Form className="answer">
                    {getMultiChoice()}
                    <div className="form-control-feedback">
                      {errorMessage.check || ''}
                    </div>
                    <FormGroup className="mt-3 mb-2">
                      <Label style={{ color: '#000' }} for="feedbackComment">
                        {questions.commentQuestionText}
                      </Label>
                      <Input
                        type="textarea"
                        placeholder={`Write your comments to praise and/or help improve ${name?.split(' ')[0]}’s portfolio files.`}
                        name="feedbackComment"
                        value={comment}
                        onChange={handleComment}
                        id="feedbackComment"/>
                    </FormGroup>
                  </Form>
                </div>
                <div className="text-right mt-2">
                  {feedbackData?.resourceType === 'badge' && (
                    <button
                      className="btn btn-secondary px-2 mr-1"
                      value="step1"
                      data-value="previous"
                      onClick={savePortfolioEvidence}>
                      Previous
                    </button>
                  )}
                  {feedbackData?.feedbackstatus === 'pending' &&
                  feedbackData?.feedbackResponseDate === '' ?
                    <Button
                      value="step3"
                      data-value="next"
                      onClick={savePortfolioEvidence}
                      className=" sendButton">
                      Next
                    </Button> :
                    <Button
                      value="step4"
                      data-value="finished"
                      onClick={savePortfolioEvidence}
                      className="sendButton ml-2">
                      I'm Finished
                    </Button>}
                </div>
              </section>
            </Col>
          </Row>
        </div>
      </Col>

      {isOpenPortfolioFilesTips &&
        <SeePortfolioTipsModal
          isOpen={isOpenPortfolioFilesTips}
          onToggle={togglePortfolioFilesTips}/>}
    </Row>
  );
};

PortfolioEvidences.propTypes = {
  proceed: PropTypes.func.isRequired,
  questions: PropTypes.object.isRequired,
  portfolioEvidenceRating: PropTypes.object.isRequired,
  resourceData: PropTypes.object.isRequired,
  feedbackResponse: PropTypes.func.isRequired,
  feedbackData: PropTypes.object.isRequired,
  name: PropTypes.string,
  toggleConfirmationModal: PropTypes.func,
};

export default PortfolioEvidences;
