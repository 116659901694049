import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

const sampleBadgeIcon = require('../../../assets/images/badge_sample.png');

const settings = {
  dots: false,
  infinite: false,
  arrows:true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: false,
  swipe:true,
  responsive: [
    { breakpoint: 560,
      settings: {
        slidesToShow: 2, slidesToScroll: 1, arrows:false, dots:true
      }
    }
  ]
};


const SkillBadgesSection = ({ profile, badges, isVisiting, showHidden, isPublicProfile, displayHiddenBadges }) => {

  const sliderSetting = Object.assign({}, settings, {
    swipe: badges.length > 3,
    responsive: [
      Object.assign({}, settings.responsive[0],{
        settings: Object.assign({}, settings.responsive[0].settings, {
          swipe: badges.length > 2
        })
      })
    ]
  });


  if(badges.length === 0){
    return(
      <div className="badge-none empty-section-message">
        {isVisiting ?
          `${profile.name} hasn't claimed any Skill Badges yet` :
          <span>Review <Link to="/skill-builders" className="btn-text-link">Skill Builders</Link> and claim Skill Badges to showcase them here.</span>}
      </div>
    );
  }

  let visibleBadges = badges;
  if (isPublicProfile && !displayHiddenBadges) {
    visibleBadges = showHidden ? badges : badges.filter(badge => badge.issued.visibility);
  }

  return (
    <div>
      <div className="container badge-custom-list">
        {
          isEmpty(visibleBadges) ? <p>{`${profile.name} has no Skill Badges to show yet.`}</p> :
            (<Slider {...sliderSetting}>

              {visibleBadges.map((badge, index) =>{
                const badgeWebpageALT = `Badge Earned: ${badge.name}`;
                const thumbnail = badge.url ? badge.url : sampleBadgeIcon;
                const badgeImageClassName = classNames(
                  'image-responsive badge-image',
                  {
                    'hiddenOpacity': !showHidden && !badge.issued.visibility
                  }
                );

                return (
                  <div key={index}>
                    <Link to={`/skill-badges/badge-details/${badge.issued.id}`}>
                      <img
                        src={thumbnail}
                        className={badgeImageClassName}
                        alt={badgeWebpageALT}/>
                    </Link>
                  </div>
                );
              })}
            </Slider>)
        }
      </div>
    </div>
  );
};

SkillBadgesSection.propTypes = {
  profile: PropTypes.object,
  isVisiting: PropTypes.bool,
  badges: PropTypes.array,
  showHidden: PropTypes.bool,
  isPublicProfile: PropTypes.bool,
  displayHiddenBadges: PropTypes.bool,
};

export default SkillBadgesSection;
