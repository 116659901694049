import { CALL_API } from '../../middlewares/api';

import {
  GET_IR_LIST_REQUEST,
  GET_IR_LIST_SUCCESS,
  GET_IR_LIST_FAILURE,
  CLEAR_IR_LIST_SUCCESS,
  ADD_EXISTING_IR_REQUEST,
  ADD_EXISTING_IR_SUCCESS,
  ADD_EXISTING_IR_FAILURE,
  GET_MANAGE_IR_LIST_REQUEST,
  GET_MANAGE_IR_LIST_SUCCESS,
  GET_MANAGE_IR_LIST_FAILURE,
  REMOVE_EXISTING_IR_REQUEST,
  REMOVE_EXISTING_IR_SUCCESS,
  REMOVE_EXISTING_IR_FAILURE,
} from '../../constants';

export const getIRList = (institutionId) => {
  const endpoint = `institution/irs/${institutionId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [GET_IR_LIST_REQUEST, GET_IR_LIST_SUCCESS, GET_IR_LIST_FAILURE],
    },
  };
};

export const getManageIRList = (institutionId, search='', param = null) => {
  let endpoint = `user/manage-industry-representatives/${institutionId}?search=${search}`;

  if(param){
    endpoint = endpoint.concat(param);
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [GET_MANAGE_IR_LIST_REQUEST, GET_MANAGE_IR_LIST_SUCCESS, GET_MANAGE_IR_LIST_FAILURE],
    },
  };
};

export const addExistingIR = (data) => {
  const endpoint = 'institution/irs';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [ADD_EXISTING_IR_REQUEST, ADD_EXISTING_IR_SUCCESS, ADD_EXISTING_IR_FAILURE],
    },
  };
};

export const removeExistingIR = (data) => {
  const endpoint = 'institution/irs';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'delete',
      authenticated: true,
      types: [REMOVE_EXISTING_IR_REQUEST, REMOVE_EXISTING_IR_SUCCESS, REMOVE_EXISTING_IR_FAILURE],
    },
  };
};

export const clearIRList = () => {
  return dispatch => dispatch({ type: CLEAR_IR_LIST_SUCCESS });
};
