import React from 'react';
import PropTypes from 'prop-types';

import CityColumn from './table-columns/CityColumn';
import ClusterColumn from './table-columns/ClusterColumn';
import CompanyColumn from './table-columns/CompanyColumn';
import FrequencyInWeeksColumn from './table-columns/FrequencyInWeeksColumn';
import JobTitleColumn from './table-columns/JobTitleColumn';
import NameImageColumn from './table-columns/NameImageColumn';
import RequestedActionColumn from './table-columns/RequestedActionComponent';
import IndustryColumn from './table-columns/IndustryColumn';
import RequestsCountColumn from './table-columns/RequestsCountComponent';

// Manage IR Sorting Table Components -------------------------------------------------
const ManageIRRowComponent = (props) => {
  return (props.tblData.length > 0) ?
    props.tblData.map(item => (
      <tr key={item.id}>
        <NameImageColumn rowData={item} {...props}/>
        <ClusterColumn rowData={item} {...props}/>
        <IndustryColumn rowData={item} {...props}/>
        <JobTitleColumn rowData={item} {...props}/>
        <CompanyColumn rowData={item} {...props}/>
        <CityColumn rowData={item} {...props}/>
        <RequestsCountColumn rowData={item} {...props}/>
        <FrequencyInWeeksColumn rowData={item} {...props}/>
        <RequestedActionColumn rowData={item} {...props}/>
      </tr>
    )) :
    <tr>
      <td align="center" style={{textAlign: 'center'}} colSpan="9">
        Sorry, we couldn't find anything!
      </td>
    </tr>;
};

ManageIRRowComponent.propTypes = {
  tblData: PropTypes.array
};

export default ManageIRRowComponent;
