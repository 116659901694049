import PropTypes from 'prop-types';
import React from 'react';

import moment from 'moment';

import { Link } from 'react-router-dom';
import Linkify from 'react-linkify';
import Parser from 'html-react-parser';

const MessageDetailsItem = ({ reply, userId }) => {
  const { body, user, createdAt, file } = reply;
  const isUserOwner = userId === user.id;
  const isFileAttached = Object.keys(file).length > 0;
  let fileComponent = null;
  const date = moment.utc(createdAt.date).format('YYYY-MM-DD HH:mm:ss');
  const stillUtc = moment.utc(date).toDate();
  const timeString = moment(stillUtc).local().calendar();

  if(isFileAttached){
    fileComponent = file.mimeType.includes('application') || file.mimeType.includes('text') ?
      (<a
        target="_blank"
        rel="noopener"
        href={file.url}
        style={{marginTop: 10}}
        className="thumb file-icon-container">
        <i className="fa fa-file-text-o"/>
      </a>) :
      (<a
        target="_blank"
        rel="noopener"
        href={file.url}
        className="portfolio-file-image-container">
        <span>
          <img src={file.url} className="thumb-image"/>
        </span>
      </a>);
  }

  if(!isUserOwner){
    return (
      <div className="messageFromContainer clearfix">
        {(user.vanity === 'career-prepped-bot') ?
          <span className="messageFromAvatar">
            {user.avatar !== '' ?
              <img
                className="profile-avatar media-object"
                src={user.avatar}
                alt="User avatar"/>:
              <span
                style={{
                  lineHeight: '64px',
                  fontSize: 32
                }}
                className="profile-avatar media-object initial-avatar">
                {user.name && user.name.slice(0,1)}
              </span>}
          </span> :
          <Link to={`/cp/${user.vanity}`} className="messageFromAvatar">
            {user.avatar !== '' ?
              <img
                className="profile-avatar media-object"
                src={user.avatar}
                alt="User avatar"/>:
              <span
                style={{
                  lineHeight: '64px',
                  fontSize: 32
                }}
                className="profile-avatar media-object initial-avatar">
                {user.name && user.name.slice(0,1)}
              </span>}
          </Link>}
        <div className="messageFromBody">
          <div className="messageChatAuthor">
            {(user.vanity === 'career-prepped-bot') ?
              <span style={{color: '#041e41'}}>{user.name}</span> :
              <Link
                to={`/cp/${user.vanity}`}
                style={{color: '#041e41'}}>
                {user.name}
              </Link>}
          </div>
          <div className="messageChatBubble fromOther">
            <Linkify>{Parser(body.replace('\n- CareerPrepped Support Team', '<br>- CareerPrepped Support Team'))}</Linkify>
            {
              isFileAttached && fileComponent
            }
          </div>
        </div>
        <div className="messageFromMeta text-left clearfix">
          <span className="messageDate">{timeString}</span>
        </div>
      </div>
    );
  }

  return(
    <div className="messageToContainer clearfix">
      {(user.vanity === 'career-prepped-bot') ?
        <span className="messageToAvatar">
          {user.avatar !== '' ?
            <img
              className="profile-avatar media-object"
              src={user.avatar}
              alt="User avatar"/>:
            <span
              style={{
                lineHeight: '64px',
                fontSize: 32
              }}
              className="profile-avatar media-object initial-avatar">
              {user.name && user.name.slice(0,1)}
            </span>}
        </span> :
        <Link to={`/cp/${user.vanity}`} className="messageToAvatar">
          {user.avatar !== '' ?
            <img
              className="profile-avatar media-object"
              src={user.avatar}
              alt="User avatar"/>:
            <span
              style={{
                lineHeight: '64px',
                fontSize: 32
              }}
              className="profile-avatar media-object initial-avatar">
              {user.name && user.name.slice(0,1)}
            </span>}
        </Link>}
      <div className="messageToBody">
        <div className="messageChatAuthor">
          {(user.vanity === 'career-prepped-bot') ?
            <span style={{color: '#041e41'}}>{user.name}</span> :
            <Link
              to={`/cp/${user.vanity}`}
              style={{color: '#041e41'}}>
              {user.name}
            </Link>}
        </div>
        <div className="messageChatBubble fromUser">
          <Linkify>{Parser(body.replace('\n- CareerPrepped Support Team', '<br>- CareerPrepped Support Team'))}</Linkify>

          {isFileAttached && fileComponent}
        </div>
      </div>
      <div className="messageToMeta text-right clearfix">
        <span className="messageDate">{timeString}</span>
      </div>
    </div>
  );
};

MessageDetailsItem.propTypes = {
  reply: PropTypes.object.isRequired,
  userId: PropTypes.string
};

export default MessageDetailsItem;
