import initialState from './initialState';

import {
  RESET_JOB_REDUCER,
  SEARCH_JOBS_REQUEST,
  SEARCH_JOBS_SUCCESS,
  SEARCH_JOBS_FAILURE,
  ADD_JOB_TO_BOARD_REQUEST,
  ADD_JOB_TO_BOARD_SUCCESS,
  ADD_JOB_TO_BOARD_FAILURE,
  TOGGLE_ADVANCED_FILTER,
  UPDATE_QUERY_PARAMS,
  UPDATE_JOB_SEARCH_PROPERTIES
} from '../../constants';

const handleSearchResults = (prevState, action) => {
  let prevResults = [];
  const prevStateResults = prevState.results;
  const actionResults = action.results;

  if (!actionResults) return { empty: true };

  if (prevStateResults && actionResults.page !== 0) {
    prevResults = prevStateResults;
  }
  const stateToReturn = {
    ...actionResults,
    results: [...prevResults, ...actionResults.results],
  };
  delete stateToReturn['empty'];
  return stateToReturn;
};

export default function reducer(state = initialState.jobSearch, action) {
  switch (action.type) {
  case TOGGLE_ADVANCED_FILTER:
    return Object.assign({}, state, {
      displayAdvancedFilter: !state.displayAdvancedFilter,
    });

  case RESET_JOB_REDUCER:
    return Object.assign({}, initialState.jobSearch);

  case UPDATE_QUERY_PARAMS:
    return Object.assign({}, state, {
      queryParams: Object.assign({}, state.queryParams, action.params),
    });

  case SEARCH_JOBS_REQUEST:
    return Object.assign({}, state, {
      queryParams: Object.assign({}, state.queryParams, action.query),
      isRequesting: true,
    });

  case SEARCH_JOBS_SUCCESS:
    return Object.assign({}, state, {
      data: handleSearchResults(state.data, action.data),
      isRequesting: false,
    });

  case SEARCH_JOBS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case ADD_JOB_TO_BOARD_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: { status: true, id: action.id },
    });

  case ADD_JOB_TO_BOARD_SUCCESS:
    return Object.assign({}, state, {
      isSubmitting: { status: false, id: null },
      data: Object.assign({}, state.data, {
        results: [
          ...state.data.results.map((result) => {
            if (result.id === action.data.jobkey) {
              return Object.assign({}, result, { isSavedProperty: 1 });
            }

            return result;
          }),
        ],
      }),
    });

  case ADD_JOB_TO_BOARD_FAILURE:
    return Object.assign({}, state, {
      isSubmitting: { status: false, id: null },
      error: action.error,
    });

  case UPDATE_JOB_SEARCH_PROPERTIES:
    return Object.assign({}, state, {
      data: Object.assign({}, state.data, {
        results: [
          ...state.data.results.map((result) => {
            if (result.job_id === action.job.jobkey) {
              return Object.assign({}, result, { ...action.job });
            }

            return result;
          })
        ]
      })
    });

  default:
    return state;
  }
}
