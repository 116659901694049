import React from 'react';

import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import TextInput from '../common/TextInput';
import SelectInput from '../common/SelectInput';

const industryRoleOptions = [
  { text: 'Recruiter', value: 'Recruiter' },
  { text: 'Hiring Manager', value: 'Hiring Manager' },
  { text: 'Supervisor', value: 'Supervisor' },
  { text: 'Coach/Trainer', value: 'Coach/Trainer' },
  { text: 'Executive', value: 'Executive' },
  { text: 'Working Professional', value: 'Working Professional' },
  { text: 'Retired Professional', value: 'Retired Professional' }
];
const referralOptions = [
  { text: 'MaxKnowledge | CareerPrepped', value: 'MaxKnowledge | CareerPrepped' },
  { text: 'ACTE | CTE Learn', value: 'ACTE | CTE Learn' },
  { text: 'ACCSC', value: 'ACCSC' },
  { text: 'CECU', value: 'CECU' },
  { text: 'DEAC', value: 'DEAC' },
  { text: 'IAF', value: 'IAF' },
  { text: 'Media', value: 'Media' },
  { text: 'Other', value: 'Other' },
];
const IndustryMentorForm = ({
  errors,
  onChange,
  onSubmit,
  isSubmitting,
  industryMentor,
  toggleIndustryMentorModal,
  isOpenIndustryMentorModal
}) => {
  return (
    <Modal
      centered
      isOpen={isOpenIndustryMentorModal}
      toggle={toggleIndustryMentorModal}
      size={'md'}
      className="talent-signup-modal">
      <ModalHeader
        className="no-border"
        toggle={toggleIndustryMentorModal}>
        Industry Mentor Account
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-lg-6">
            <TextInput
              name="firstName"
              placeholder="First Name"
              error={errors.firstName}
              value={industryMentor.firstName}
              onChange={onChange}/>
          </div>
          <div className="col-lg-6">
            <TextInput
              name="lastName"
              placeholder="Last Name"
              error={errors.lastName}
              value={industryMentor.lastName}
              onChange={onChange}/>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <TextInput
              autoFocus
              name="email"
              placeholder="Email"
              error={errors.email}
              value={industryMentor.email}
              onChange={onChange}/>
          </div>
          <div className="col-lg-6">
            <TextInput
              name="phoneNumber"
              placeholder="Phone Number (Optional)"
              error={errors.phoneNumber}
              value={industryMentor.phoneNumber}
              onChange={onChange}/>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <TextInput
              name="jobTitle"
              placeholder="Job Title"
              error={errors.jobTitle}
              value={industryMentor.jobTitle}
              onChange={onChange}/>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <TextInput
              name="companyName"
              placeholder="Company Name"
              error={errors.companyName}
              value={industryMentor.companyName}
              onChange={onChange}/>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <p style={{'textAlign':'left'}}><small>Provide a way for us to verify your identity</small></p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <TextInput
              name="linkedInProfile"
              placeholder="LinkedIn Profile URL"
              error={errors.linkedInProfile}
              value={industryMentor.linkedInProfile}
              onChange={onChange}/>
          </div>
          <div className="col-lg-6">
            <TextInput
              name="alternativeOnlineProfile"
              placeholder="Online Profile URL"
              error={errors.alternativeOnlineProfile}
              value={industryMentor.alternativeOnlineProfile}
              onChange={onChange}/>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <p style={{'textAlign':'left'}}>
              <small>
                Select the industry role that best describes you:
              </small>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <SelectInput
              name="industryRole"
              defaultOption="Select industry role"
              options={industryRoleOptions}
              error={errors.industryRole}
              value={industryMentor.industryRole}
              onChange={onChange}/>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <p style={{'textAlign':'left'}}>
              <small>
                How did you hear about this opportunity?
              </small>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <SelectInput
              name="referral"
              defaultOption="Select"
              options={referralOptions}
              error={errors.referral}
              value={industryMentor.referral}
              onChange={onChange}/>
          </div>
        </div>

        <div className="account_form_submit-cont" style={{textAlign: 'center'}}>
          <button
            className="btn account-signup-btn"
            style={{'textTransform':'none','display':'inline-block','whiteSpace':'normal'}}
            onClick={onSubmit}
            disabled={isSubmitting}>
            <strong>
              {isSubmitting ? 'Signing up...' : 'Sign Up'}
            </strong>
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

IndustryMentorForm.propTypes = {
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  industryMentor: PropTypes.object.isRequired,
  isOpenIndustryMentorModal: PropTypes.bool.isRequired,
  toggleIndustryMentorModal: PropTypes.func.isRequired
};

export default IndustryMentorForm;
