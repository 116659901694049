import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadForm } from '../../utils';
import * as componentActions from '../../redux/actions/components';
import SummarySection from '../../components/resume/SummarySection';
import SummaryForm from './SummaryForm';
import formWithModal from '../../components/profile/modal/formWithModal';

const styles = {
  userNameEditIcon: {
    cursor: 'pointer'
  },

  editActions: {
    position: 'relative',
  }
};

class ResumeSummary extends Component {
  static propTypes = {
    summary: PropTypes.object,
    onChangeSection: PropTypes.func.isRequired,
    onChangeSectionTitle: PropTypes.func.isRequired,
    onUnloadForm: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    resumeSummary: PropTypes.object.isRequired,
    onLoadForm: PropTypes.func.isRequired,
    forms: PropTypes.array,
  };

  constructor(props, context) {
    super(props, context);

    this.onChangeTitle = this.onChangeTitle.bind(this);
  }

  onChangeTitle(event){
    const { value } = event.target;
    this.props.onChangeSectionTitle(value, 'summary');
  }

  EditingSummary = formWithModal(SummaryForm);

  render() {
    const { resumeSummary, onLoadForm, forms, onUnloadForm, onChangeSection } = this.props;
    const isFormOpen = loadForm(forms, 'editSummaryForm');

    return (
      <div className="resume-section create-resume-section-card">
        <div className="create-resume-title">
          <input
            type="text"
            value={resumeSummary.title}
            placeholder="Summary header"
            className="header-edit-textbox"
            onChange={this.onChangeTitle}/>
        </div>
        {!isFormOpen &&
          <div className="section-list-container">
            <div className="resume-section section-item summary">
              <span style={styles.editActions} className="float-right">
                <i
                  onClick={onLoadForm}
                  data-form-name="editSummaryForm"
                  style={styles.userNameEditIcon}
                  className="fa fa-pencil"/>
              </span>
              <SummarySection summary={resumeSummary.summary}/>
            </div>
          </div>}

        {
          isFormOpen &&
            <div style={{border: '1px solid #e3e5e5', padding: 15, fontSize: 14}}>
              <this.EditingSummary
                summary={resumeSummary}
                onUnloadForm={onUnloadForm}
                onChange={onChangeSection}
                exampleType={'Resume Summary'} />
            </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    forms: state.components.forms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(componentActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResumeSummary);
