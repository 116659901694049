import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import UploadPortfolioFilesTabContent from './UploadPortfolioFilesTabContent';
import SelectPortfolioFilesTabContent from './SelectPortfolioFilesTabContent';
import PortfolioVisibiltyConfirmation from '../../../containers/skill-badges/PortfolioVisibiltyConfirmation';

const LinkPortfolioFilesTab = ({
  badge,
  noOfPubicFiles,
  privateFiles,
  showPrivateConfirmation,
  activeTab,
  onTabChange,
  files,
  isRequestingFiles,
  selectedFiles,
  linkedFiledIds,
  closeModal,
  bakedBadgePage,
  onNext,
  onPrev,
  onSelectPage,
  fileData,
  skillsCard,
  getMyBadges,
  getMySkills,
  linkingSkill,
  onLinkedSkill,
  errors,
  selectedFile,
  onSelectFile,
  onDeleteLoadedFile,
  onInsertLinkClicked,
  showInsertTextBox,
  guidelinesTooltipOpen,
  onChangeSelectedFile,
  onDropFile,
  toggleGuidelinesTooltip
}) => {
  let component;
  const noOfFilesSelected = selectedFiles.length;

  switch(activeTab) {
  case 0:
    component = (
      <div className="tab-content" id="myTabContent">
        <div
          className="p-2 tab-pane fade in show active"
          id="gallery"
          role="tabpanel"
          aria-labelledby="gallery">
          {noOfFilesSelected > 0 && <h6>{noOfFilesSelected} Files Selected</h6>}

          <SelectPortfolioFilesTabContent
            badge={badge}
            files={files}
            onNext={onNext}
            onPrev={onPrev}
            onSelectPage={onSelectPage}
            fileData={fileData}
            onSelectFile={onSelectFile}
            selectedFiles={selectedFiles}
            linkedFiledIds={linkedFiledIds}
            isRequestingFiles={isRequestingFiles}/>
        </div>
      </div>
    );
    break;

  case 1:
    component = (
      <UploadPortfolioFilesTabContent
        bakedBadgePage={bakedBadgePage}
        closeModal={closeModal}
        skillsCard={skillsCard}
        getMyBadges={getMyBadges}
        getMySkills={getMySkills}
        errors={errors}
        selectedFile={selectedFile}
        onDeleteLoadedFile={onDeleteLoadedFile}
        onInsertLinkClicked={onInsertLinkClicked}
        showInsertTextBox={showInsertTextBox}
        guidelinesTooltipOpen={guidelinesTooltipOpen}
        onChange={onChangeSelectedFile}
        onDropFile={onDropFile}
        toggleGuidelinesTooltip={toggleGuidelinesTooltip}/>
    );
    break ;
  }

  function loadDetailsPage() {
    if (onLinkedSkill ){
      onLinkedSkill();
    }
  }

  if (showPrivateConfirmation) {
    return (
      <PortfolioVisibiltyConfirmation
        noOfPubicFiles={noOfPubicFiles}
        privateFiles={privateFiles}
        getMyBadges={getMyBadges}
        getMySkills={getMySkills}
        linkingSkill={linkingSkill}
        onLinkedSkill={loadDetailsPage}/>
    );
  }

  return (
    <div>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item">
          <a
            onClick={onTabChange(0)}
            className={classNames('nav-link clickable link', {
              'orange': activeTab !== 0,
              'active': activeTab === 0
            })}
            id="gallery-tab">
            Portfolio
          </a>
        </li>
        <li className="nav-item">
          <a
            onClick={onTabChange(1)}
            className={classNames('nav-link clickable link', {
              'orange': activeTab !== 1,
              'active': activeTab === 1
            })}
            id="upload-tab">
            Upload New
          </a>
        </li>
      </ul>
      {component}
    </div>
  );
};

LinkPortfolioFilesTab.propTypes = {
  activeTab: PropTypes.number,
  onTabChange: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  isRequestingFiles: PropTypes.bool.isRequired,
  selectedFiles: PropTypes.array,
  selectedFile: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  bakedBadgePage: PropTypes.bool,
  privateFiles: PropTypes.array,
  linkedFiledIds: PropTypes.array.isRequired,
  showPrivateConfirmation: PropTypes.bool,
  noOfPubicFiles: PropTypes.number,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  onSelectPage: PropTypes.func,
  fileData: PropTypes.object,
  skillsCard: PropTypes.bool,
  getMyBadges: PropTypes.func,
  getMySkills: PropTypes.func,
  linkingSkill: PropTypes.bool,
  onLinkedSkill: PropTypes.func,
  onSelectFile: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  badge: PropTypes.object.isRequired,
  onDeleteLoadedFile: PropTypes.func.isRequired,
  onInsertLinkClicked: PropTypes.func.isRequired,
  showInsertTextBox: PropTypes.bool.isRequired,
  guidelinesTooltipOpen: PropTypes.bool.isRequired,
  onDropFile: PropTypes.func.isRequired,
  toggleGuidelinesTooltip: PropTypes.func.isRequired,
  onChangeSelectedFile: PropTypes.func.isRequired
};

export default LinkPortfolioFilesTab;
