import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isEmpty, isUndefined } from 'lodash';

import { openModal, closeModal } from '../../redux/actions/components';
import {
  resetGroupAdmins,
  getGroupAdmins,
  updateGroupRemoveAdmins,
} from '../../redux/actions/groups';

import { toastr } from 'react-redux-toastr';
import { Container, Row, Col } from 'reactstrap';
import ConfirmModal from '../common/ConfirmModal';
import TableLoading from '../common/TableLoading';
import PaginationHandler from '../common/PaginationHandler';
import UsersTableNameCell from './table-custom-components/UsersTableNameCell';

class AdminListTable extends Component {
  static propTypes = {
    page: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    actions: PropTypes.object.isRequired,
    groupId: PropTypes.string,
    group: PropTypes.object.isRequired,
    modal: PropTypes.string,
    currentUserId: PropTypes.string.isRequired,
    isGroupOwner: PropTypes.bool.isRequired,
    selectedGroup: PropTypes.object.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    selectedGroupAdmins: PropTypes.array.isRequired,
    isRequestingGroupAdmins: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      adminId: '',
      adminName: ''
    };

    this.onPrev = this.onPrev.bind(this);
    this.onNext = this.onNext.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onConfirmRemoveAdmin = this.onConfirmRemoveAdmin.bind(this);
  }

  componentWillMount() {
    const { actions, selectedGroup: { id: groupId } } = this.props;

    if ((groupId !== 'all-groups') && (! isUndefined(groupId))) {
      actions.getGroupAdmins(groupId);
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;

    actions.resetGroupAdmins();
  }

  onOpenModal(e) {
    const { id: adminId, name: adminName } = e.currentTarget;

    this.setState({ adminId, adminName }, () => {
      this.props.actions.openModal('admin-group-remove-admin');
    });
  }

  onCloseModal() {
    this.setState({ adminId: '', adminName: '' }, () => {
      this.props.actions.closeModal();
    });
  }

  onConfirmRemoveAdmin() {
    const { adminId } = this.state;
    const { selectedGroup: { id: groupId }, actions } = this.props;

    actions.updateGroupRemoveAdmins(groupId, { revokeAdmins: [adminId] }, adminId)
      .then((state) => {
        const { groups: { error, isUpdating: { status: isUpdating } } } = state;

        if (isEmpty(error) && (! isUpdating)) {
          toastr.success('', 'Admin removed successfully.');
          this.onCloseModal();
        }
      });
  }

  onNext() {
    const { page: currentPage, actions, selectedGroup: { id: groupId } } = this.props;
    const page = currentPage + 1;
    const urlParamString = `&page=${page}`;

    actions.getGroupAdmins(groupId, urlParamString);
  }

  onPrev() {
    const { page: currentPage, actions, selectedGroup: { id: groupId } } = this.props;
    const page = currentPage - 1;
    const urlParamString = `&page=${page}`;

    actions.getGroupAdmins(groupId, urlParamString);
  }

  onSelectPage(page) {
    return () => {
      const { actions, selectedGroup: { id: groupId } } = this.props;
      const urlParamString = `&page=${page}`;

      actions.getGroupAdmins(groupId, urlParamString);
    };
  }

  render() {
    const {
      modal,
      page,
      pageCount,
      isUpdating,
      selectedGroup,
      isGroupOwner,
      currentUserId,
      selectedGroupAdmins,
      isRequestingGroupAdmins
    } = this.props;
    const { adminName } = this.state;
    const { name: groupName } = selectedGroup;

    const modalMessage = `Are you sure you want to remove ${adminName} as admin in ${groupName}?`;

    return (
      <Container>
        <Row className="mb-1">
          <Col sm="12">
            <div style={{position: 'relative'}}>
              <table className="cp-table table table-responsive-md">
                <thead>
                  <tr className="cp-table-header">
                    <th style={{width: '80%'}}>Name</th>

                    <th style={{width: 100, textAlign: 'center'}}>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {(selectedGroup.id === 'all-groups') &&
                    <tr>
                      <td colSpan="2" style={{textAlign: 'center'}}>
                        Select group to display admins.
                      </td>
                    </tr>}

                  {((selectedGroup.id !== 'all-groups') && (selectedGroupAdmins.length === 0)) &&
                    <tr>
                      <td colSpan="2" style={{textAlign: 'center'}}>
                        This group doesn't have any admins yet.
                      </td>
                    </tr>}

                  {selectedGroupAdmins.map((admin) => {
                    const canDelete = isGroupOwner && (admin.id !== currentUserId);

                    return (
                      <tr key={admin.id}>
                        <td>
                          <UsersTableNameCell user={admin}/>
                        </td>

                        {canDelete ?
                          <td style={{textAlign: 'center'}}>
                            <a
                              id={admin.id}
                              name={admin.name}
                              onClick={this.onOpenModal}
                              className="btn btn-danger">
                              <i className="fa fa-trash"/>{' '}Remove
                            </a>
                          </td> : <td/>}
                      </tr>);
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <th style={{textAlign: 'center'}} colSpan={10}>
                      <PaginationHandler
                        page={page}
                        page_count={pageCount}
                        onNext={this.onNext}
                        onPrev={this.onPrev}
                        onSelectPage={this.onSelectPage}/>
                    </th>
                  </tr>
                </tfoot>
              </table>

              {isRequestingGroupAdmins && <TableLoading/>}

              {modal && (modal === 'admin-group-remove-admin') &&
                <ConfirmModal
                  isOpen={modal && (modal === 'admin-group-remove-admin')}
                  confirminginProgressText="Removing..."
                  modalHeaderText="Removing admin"
                  modalMessage={modalMessage}
                  modalFooterButtonText="Yes, Remove"
                  onCancel={this.onCloseModal}
                  isConfirming={isUpdating}
                  onConfirm={this.onConfirmRemoveAdmin}/>}

            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    components: { modal },
    adminGroups: group,

    groups: {
      groupAdminsPage: page,
      groupAdminsPageCount: pageCount,
      selectedGroup,
      selectedGroupAdmins,
      isUpdating: { status: isUpdating },
      isRequestingGroupAdmins
    },

    auth: { data: { currentUser: { id: currentUserId } } }
  } = state;

  const isGroupOwner = currentUserId === selectedGroup.ownerId;

  return {
    page,
    group,
    modal,
    pageCount,
    isUpdating,
    selectedGroup,
    isGroupOwner,
    currentUserId,
    selectedGroupAdmins,
    isRequestingGroupAdmins
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    openModal,
    closeModal,
    getGroupAdmins,
    resetGroupAdmins,
    updateGroupRemoveAdmins
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminListTable);
