import PropTypes from 'prop-types';
import React from 'react';

import Search from '../search/Search';
import PaginationHandler from '../../../common/PaginationHandler.js';
import NameCell from './NameCell';
import PrimaryAdminCell from './PrimaryAdminCell';
import TotalUsersCell from './TotalUsersCell';
import ActionsCell from './ActionsCell';
import { Row, Col } from 'reactstrap';
import TableLoading from '../../../common/TableLoading';

const Table = ({
  page,
  page_count,
  onNext,
  onPrev,
  onSelectPage,
  isRequesting,
  organizationArray,
  onEditOrganization,
  searchQuery,
  onSortTable,
  onChangeSearchQuery
}) => {
  return (
    <div style={{position: 'relative'}}>
      <Row className="mb-1">
        <Col sm="8">
          <Search
            table="organizations"
            searchQuery={searchQuery}
            isRequesting={isRequesting}
            onChangeSearchQuery={onChangeSearchQuery}/>
        </Col>
      </Row>
      <table className="cp-table mt-2 table table-responsive-lg">
        <thead>
          <tr className="cp-table-header">
            <th
              style={{width: '30%'}}
              className="cursor-pointer"
              data-column-name="organizationName"
              onClick={onSortTable}>
              Organization
            </th>

            <th
              style={{width: 100, textAlign: 'center'}}
              className="cursor-pointer"
              data-column-name="adminName"
              onClick={onSortTable}>
              Primary Admin
            </th>

            <th
              style={{width: 100, textAlign: 'center'}}
              className="cursor-pointer"
              data-column-name="groups"
              onClick={onSortTable}>
              Groups
            </th>

            <th
              style={{width: 100, textAlign: 'center'}}
              className="cursor-pointer"
              data-column-name="users"
              onClick={onSortTable}>
              Total Users
            </th>

            <th style={{width: 100}}>Actions</th>
          </tr>
        </thead>

        <tbody>
          {(organizationArray.length === 0) &&
            <tr><td colSpan="5" style={{textAlign: 'center'}}>{'Sorry, we couldn\'t find anything!'}</td></tr>}

          {(organizationArray.length !== 0) && organizationArray.map(organization => (
            <tr key={organization.id}>
              <td><NameCell organization={organization}/></td>
              <td style={{textAlign: 'center'}}>
                <PrimaryAdminCell organization={organization}/>
              </td>
              <td style={{textAlign: 'center'}}>
                {organization.groupsCount}
              </td>
              <td style={{textAlign: 'center'}}>
                <TotalUsersCell organization={organization}/>
              </td>
              <td>
                <ActionsCell
                  organization={organization}
                  onEditOrganization={onEditOrganization}/>
              </td>
            </tr>
          ))}
        </tbody>

        <tfoot>
          <tr>
            <th style={{textAlign: 'center'}} colSpan={10}>
              <PaginationHandler
                onNext={onNext}
                onPrev={onPrev}
                onSelectPage={onSelectPage}
                page={page}
                page_count={page_count}/>
            </th>
          </tr>
        </tfoot>
      </table>

      {isRequesting && <TableLoading/>}
    </div>
  );
};

Table.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  page_count: PropTypes.number.isRequired,
  onSelectPage: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  organizationArray: PropTypes.array.isRequired,
  onEditOrganization: PropTypes.func.isRequired,
  searchQuery: PropTypes.string,
  onSortTable: PropTypes.func.isRequired,
  onChangeSearchQuery: PropTypes.func.isRequired
};

export default Table;
