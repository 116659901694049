import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { Label, Input, Button } from 'reactstrap';
import Validator from '../../validator';

import _isEmpty from 'lodash/isEmpty';

import { getTestimonialById, setReviseTestimonial } from '../../redux/actions/testimonials';
import ConfirmSubmit from './ConfirmSubmit';
import { withHooks } from '../../utils/withHooks';

import { REVISED_TESTIMONIAL_SUCCESS } from '../../utilConstants';
function ReviseTestimonial(props){
  const dispatch = useDispatch();
  const [testimonial, setTestimonial] = useState();
  const [errors, setErrors] = useState();
  const [openModal, setOpenModal] = useState(false);

  const testimonialId = props?.params?.testimonialId;
  const testimonialData = useSelector(
    state => state.testimonials.detail,
  );


  useEffect(() =>{
    dispatch(getTestimonialById(testimonialId));
  },[dispatch, testimonialId]);

  function handleTestimonial(e){
    setTestimonial(e.target.value);
  }

  function validateTestimonial(field = null){
    const rules = {
      testimonial: [
        'required','minLength|1', 'maxLength|200'
      ]
    };
    const validate = Validator.createValidator(rules, {testimonial}, field);
    return validate;
  }

  function sendReviseTestimonial(){
    const { errors, isValid } = validateTestimonial();

    if(!isValid){
      setErrors( errors );
      return;
    }

    setOpenModal(true);
  }

  async function saveReviseTestimonial(){
    const data = {
      testimonialId :  testimonialId,
      testimony : testimonial,
    };

    await dispatch(setReviseTestimonial(data));
    toastr.success(REVISED_TESTIMONIAL_SUCCESS);
    setOpenModal(!openModal);
  }

  function toggleModal() {
    setOpenModal(!openModal);
  }

  return (
    <div className="container">
      <div className="row">

        <div className="invitation-body">
          <div className="accept-invitation-message">
            {!testimonialData?.isRevised ?
              <div className="d-flex flex-column">
                <h5>Original Testimonial</h5>
                <Label
                  className="pt-2"
                  style={{ color: '#000' }}
                  for="feedbackComment">
                  <p className="mb-0">
                    {testimonialData?.testimony}
                  </p>
                </Label>
                <Input
                  type="textarea"
                  row="15"
                  placeholder="Write your revised testimonial here."
                  name="testimonial"
                  value={testimonial}
                  onChange={handleTestimonial}
                  invalid={!_isEmpty(errors) && errors.testimonial}
                  id="feedbackComment"/>
                <div className="form-control-feedback">
                  {errors?.testimonial}
                </div>

                <div className="text-right">
                  <Button className="ml-1 mt-3 sendButton" onClick={sendReviseTestimonial}>
                    Finish
                  </Button>
                </div>
              </div> :
              'You have already completed the revision process'
            }
          </div>
        </div>

      </div>
      <ConfirmSubmit
        isOpen={openModal}
        toggleModal={toggleModal}
        saveTestimonial={saveReviseTestimonial}/>
    </div>
  );
}

ReviseTestimonial.propTypes = {
  props: PropTypes.object,
  params: PropTypes.object,
};

export default withHooks(ReviseTestimonial);
