import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'reactstrap';

const interviewPreppedVideoIcon = require('../../assets/images/career-toolbox-modal.png');

const ToolboxModal = ({isQACommons, showToolboxModal, toggleToolboxModal}) => {
  return (
    <Modal className="custom-md-modal" isOpen={showToolboxModal} toggle={toggleToolboxModal} size={'lg'}>
      <div id="skillBadge">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel">Career Toolbox</h4>
            <button type="button" className="close" onClick={toggleToolboxModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <div className="skillbuilder-item">
                    <img
                      src={interviewPreppedVideoIcon}
                      className="img-fluid"
                      alt="Interview Prep Videos"/>
                  </div>
                </div>
                <div
                  style={{marginTop: 10}}
                  className="col-lg-12 text-center">
                  <p style={{fontSize:14}}>
                    {`${isQACommons ? 'Learners get a Targeted Resume Builder to build custom resumes from their Career Site and a collection of Interview Prep Videos to help them answer common interview questions. Learners also have a collection of curated tools to research & explore careers, search & compare salaries, and more!' : 'You get a Targeted Resume Builder to build custom resumes from your Career Site and a collection of Interview Prep Videos to help you answer common interview questions. You also have a collection of curated tools to research & explore careers, search & compare salaries, and more!'}`}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ToolboxModal.propTypes = {
  isQACommons: PropTypes.bool,
  showToolboxModal: PropTypes.bool,
  toggleToolboxModal: PropTypes.func
};

export default ToolboxModal;
