import { CALL_API } from '../../middlewares/api';
import {
  GET_MY_CP_STATUS_REQUEST,
  GET_MY_CP_STATUS_SUCCESS,
  GET_MY_CP_STATUS_FAILURE,

  UPDATE_MY_CP_STATUS_REQUEST,
  UPDATE_MY_CP_STATUS_SUCCESS,
  UPDATE_MY_CP_STATUS_FAILURE,  
} from '../../constants';
import { isNull } from 'lodash';
import store from '../..';

export const getMyCareerPreppedStatus = (userIdInput = null) => {
  const userId = isNull(userIdInput) ? store.getState().profile.data.id : userIdInput;
  const endpoint = `my-status/${userId}`;

  return {
    [CALL_API]: {
      method: 'get',
      authenticated: true,
      endpoint: endpoint,
      types: [
        GET_MY_CP_STATUS_REQUEST,
        GET_MY_CP_STATUS_SUCCESS,
        GET_MY_CP_STATUS_FAILURE,
      ],
    },
  };
};


export const updateMyCareerPreppedStatus = () => {
  const endpoint = 'my-status';

  return {
    [CALL_API]: {
      method: 'post',
      authenticated: true,
      endpoint: endpoint,
      types: [
        UPDATE_MY_CP_STATUS_REQUEST,
        UPDATE_MY_CP_STATUS_SUCCESS,
        UPDATE_MY_CP_STATUS_FAILURE,
      ],
    },
  };
};

// export const updateMyCareerPreppedStatus = async () => {
//   await processUpdateMyCareerPreppedStatus();
//   // .then((response) => {
//   console.log("check data ===>");
//   const globalState = store.getState();
//   console.log('see the data ==> ', globalState, globalState.profile.data.id);
//   await getMyCareerPreppedStatus(globalState.profile.data.id);
//   // });
// };