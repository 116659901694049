import React from 'react';
import PropTypes from 'prop-types';

const GroupAccordionButton = ({ buttonId, onClick, isOpenParentRow, containsChildRows }) => {
  return (
    <span className="table__accordion-btn">
      {containsChildRows ?
        <a href="#"
          id={buttonId}
          onClick={onClick}
          style={{textDecoration:'none'}}>{isOpenParentRow ? '-' : '+'}</a> :
        <span>+</span>}
    </span>
  );
};

GroupAccordionButton.propTypes = {
  buttonId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isOpenParentRow: PropTypes.bool.isRequired,
  containsChildRows: PropTypes.bool.isRequired
};

export default GroupAccordionButton;
