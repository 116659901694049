import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import Parser from 'html-react-parser';

import { loadForm } from '../../utils';
import JobDescriptionForm from '../../containers/jobs/JobDescriptionForm';

const JobUnitDescription = ({
  id,
  loadForms,
  description,
  onEditDetails,
  showMore,
  onShowMore,
  isJobOwner
}) => {
  const showMoreCondition = !showMore && description.length > 250;
  const descriptionId = `update-job-description-form-${id}`;
  const descriptionToDisplay = showMoreCondition ? `${description.slice(0,250)} ...` : description;

  const descriptionObject = {
    id: id,
    text: description,
    descriptionId: descriptionId
  };

  const btnStyle = classNames('show-more-btn-job', {
    'hide': description.length < 250,
  });


  const isForm = loadForm(loadForms, descriptionId);
  return (
    <div>
      <div>
        <p>
          <span className="section-title job-description-header">Job Description</span>
          {
            (!isForm && isJobOwner)
            &&
              <i
                onClick={onEditDetails(descriptionId)}
                className="fa fa-pencil job-description-edit-btn"
                aria-hidden="true"/>
          }
        </p>
      </div>
      {
        !isForm
        &&
          <div className="job-description-text">
            {Parser(descriptionToDisplay)}
            <span
              className={btnStyle}
              onClick={onShowMore}>
              {showMoreCondition ? 'Read more': 'Read Less'}
            </span>
          </div>
      }
      {
        isForm
        &&
          <JobDescriptionForm
            description={descriptionObject}/>
      }
    </div>
  );
};

JobUnitDescription.propTypes = {
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  loadForms: PropTypes.array.isRequired,
  isJobOwner: PropTypes.bool.isRequired,
  onEditDetails: PropTypes.func.isRequired,
  showMore: PropTypes.bool.isRequired,
  onShowMore: PropTypes.func.isRequired
};

export default JobUnitDescription;
