import PropTypes from 'prop-types';
import React from 'react';
import TextInput from '../../common/TextInput';

const styles = {
  card: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop: 0
  }
};

const UnitReflections = ({onChange, unit, errors, handleKeyPress}) => {
  return(
    <div>
      <div className="unit-section-title">
        <h6 className="unit-section-title-text">Unit Reflection Questions</h6>
      </div>
      <div className="card" style={styles.card}>
        <div className="card-block p-3">
          <div className="row">
            <div className="col-sm-12">
              <TextInput
                name="reflectone"
                placeholder="First Reflection Question"
                label="First Reflection Question"
                onChange={onChange}
                value={unit.reflectone}
                error={errors.reflectone}
                onKeyPress={handleKeyPress}/>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <TextInput
                name="reflecttwo"
                placeholder="Second Reflection Question"
                label="Second Reflection Question"
                onChange={onChange}
                value={unit.reflecttwo}
                error={errors.reflecttwo}
                onKeyPress={handleKeyPress}/>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <TextInput
                name="reflectthree"
                placeholder="Third Reflection Question"
                label="Third Reflection Question"
                onChange={onChange}
                value={unit.reflectthree}
                error={errors.reflectthree}/>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <TextInput
                name="reflectfour"
                placeholder="Fourth Reflection Question"
                label="Fourth Reflection Question"
                onChange={onChange}
                value={unit.reflectfour}
                error={errors.reflectfour}
                onKeyPress={handleKeyPress}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
UnitReflections.propTypes = {
  onChange: PropTypes.func.isRequired,
  unit: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleKeyPress: PropTypes.func.isRequired
};
export default UnitReflections;
