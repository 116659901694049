import PropTypes from 'prop-types';
import React from 'react';

import Loading from '../common/Loading';
import FileCardHandler from '../../containers/portfolio/FileCardHandler';

const FileCardRenderer = ({
  callingAPI,
  files,
  isRequestingSort,
  isRequestingSearch,
  noPortfolioFiles,
  onAddPortfolio,
  isOwner,
  profile
}) => {
  const [ ,userName] = location.search.split('=');
  if (isRequestingSort || isRequestingSearch) {
    return(
      <div style={{ padding: 20 }}>
        <Loading />
      </div>
    );
  }

  if (noPortfolioFiles && !callingAPI && isOwner) {
    return (
      <div className="search-hint row no-badges-claimed-hint mt-2">
        <div className="col-sm-12">
          <span className="fa fa-info-circle" aria-hidden="true"/>
          <p className="mb-0">Click <span className="clickable link orange" onClick={onAddPortfolio}>Add Portfolio File</span> to get started. You control your privacy settings so only showcase what you want and keep other files private until you’re ready to show them so others can leave comments and feedback.</p>
        </div>
      </div>
    );
  }

  if(noPortfolioFiles && !callingAPI && !isOwner){
    return (
      <div className="row search-hint no-badges-claimed-hint mt-0">
        <div className="col-sm-12">
          <span className="fa fa-info-circle" aria-hidden="true"/>
          <p className="mt-0 mb-1">{(profile && profile.name && typeof(userName) === 'undefined') ? profile.name : decodeURIComponent(userName)} doesn't have any portfolio files to display yet.</p>
        </div>
      </div>
    );
  }

  return(
    <div className="portfolio-container">
      {files.data.map(fileItem =>
        (<FileCardHandler key={fileItem.id} fileItem={fileItem}/>))}
      <div style={{ gridColumn: '1/-1', padding: 20 }}>
        {callingAPI && <Loading />}
      </div>
    </div>
  );
};

FileCardRenderer.propTypes = {
  files: PropTypes.object.isRequired,
  callingAPI: PropTypes.bool.isRequired,
  isRequestingSort: PropTypes.bool.isRequired,
  isRequestingSearch: PropTypes.bool.isRequired,
  skills: PropTypes.array,
  onAddPortfolio: PropTypes.func,
  noPortfolioFiles: PropTypes.bool,
  isOwner: PropTypes.bool,
  profile: PropTypes.object
};

export default FileCardRenderer;
