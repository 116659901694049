export default [{
  'label': 'General Agriculture',
  'value': 'General Agriculture'
}, {
  'label': 'Agriculture Production and Management',
  'value': 'Agriculture Production and Management'
}, {
  'label': 'Agricultural Economics',
  'value': 'Agricultural Economics'
}, {
  'label': 'Animal Sciences',
  'value': 'Animal Sciences'
}, {
  'label': 'Food Science',
  'value': 'Food Science'
}, {
  'label': 'Plant Science and Agronomy',
  'value': 'Plant Science and Agronomy'
}, {
  'label': 'Soil Science',
  'value': 'Soil Science'
}, {
  'label': 'Miscellaneous Agriculture',
  'value': 'Miscellaneous Agriculture'
}, {
  'label': 'Forestry',
  'value': 'Forestry'
}, {
  'label': 'Natural Resources Management',
  'value': 'Natural Resources Management'
}, {
  'label': 'Fine Arts',
  'value': 'Fine Arts'
}, {
  'label': 'Drama and Theater Arts',
  'value': 'Drama and Theater Arts'
}, {
  'label': 'Music',
  'value': 'Music'
}, {
  'label': 'Visual and Performing Arts',
  'value': 'Visual and Performing Arts'
}, {
  'label': 'Commercial Art and Graphic Design',
  'value': 'Commercial Art and Graphic Design'
}, {
  'label': 'Film Video and Photographic Arts',
  'value': 'Film Video and Photographic Arts'
}, {
  'label': 'Studio Arts',
  'value': 'Studio Arts'
}, {
  'label': 'Miscellaneous Fine Arts',
  'value': 'Miscellaneous Fine Arts'
}, {
  'label': 'Environmental Science',
  'value': 'Environmental Science'
}, {
  'label': 'Biology',
  'value': 'Biology'
}, {
  'label': 'Biochemical Sciences',
  'value': 'Biochemical Sciences'
}, {
  'label': 'Botany',
  'value': 'Botany'
}, {
  'label': 'Molecular Biology',
  'value': 'Molecular Biology'
}, {
  'label': 'Ecology',
  'value': 'Ecology'
}, {
  'label': 'Genetics',
  'value': 'Genetics'
}, {
  'label': 'Microbiology',
  'value': 'Microbiology'
}, {
  'label': 'Pharmacology',
  'value': 'Pharmacology'
}, {
  'label': 'Physiology',
  'value': 'Physiology'
}, {
  'label': 'Zoology',
  'value': 'Zoology'
}, {
  'label': 'Neuroscience',
  'value': 'Neuroscience'
}, {
  'label': 'Miscellaneous Biology',
  'value': 'Miscellaneous Biology'
}, {
  'label': 'Cognitive Science and Biopsychology',
  'value': 'Cognitive Science and Biopsychology'
}, {
  'label': 'General Business',
  'value': 'General Business'
}, {
  'label': 'Accounting',
  'value': 'Accounting'
}, {
  'label': 'Actuarial Science',
  'value': 'Actuarial Science'
}, {
  'label': 'Business Management and Administration',
  'value': 'Business Management and Administration'
}, {
  'label': 'Operations Logistics and E-commerce',
  'value': 'Operations Logistics and E-commerce'
}, {
  'label': 'Business Economics',
  'value': 'Business Economics'
}, {
  'label': 'Marketing and Marketing Research',
  'value': 'Marketing and Marketing Research'
}, {
  'label': 'Finance',
  'value': 'Finance'
}, {
  'label': 'Human Resources and Personnel Management',
  'value': 'Human Resources and Personnel Management'
}, {
  'label': 'International Business',
  'value': 'International Business'
}, {
  'label': 'Hospitality Management',
  'value': 'Hospitality Management'
}, {
  'label': 'Management Information Systems and Statistics',
  'value': 'Management Information Systems and Statistics'
}, {
  'label': 'Miscellaneous Business & Medical Administration',
  'value': 'Miscellaneous Business & Medical Administration'
}, {
  'label': 'Communications',
  'value': 'Communications'
}, {
  'label': 'Journalism',
  'value': 'Journalism'
}, {
  'label': 'Mass Media',
  'value': 'Mass Media'
}, {
  'label': 'Advertising and Public Relations',
  'value': 'Advertising and Public Relations'
}, {
  'label': 'Communication Technologies',
  'value': 'Communication Technologies'
}, {
  'label': 'Computer and Information Systems',
  'value': 'Computer and Information Systems'
}, {
  'label': 'Computer Programming and Data Processing',
  'value': 'Computer Programming and Data Processing'
}, {
  'label': 'Computer Science',
  'value': 'Computer Science'
}, {
  'label': 'Information Sciences',
  'value': 'Information Sciences'
}, {
  'label': 'Computer Administration Management and Security',
  'value': 'Computer Administration Management and Security'
}, {
  'label': 'Computer Networking and Telecommunications',
  'value': 'Computer Networking and Telecommunications'
}, {
  'label': 'Mathematics',
  'value': 'Mathematics'
}, {
  'label': 'Applied Mathematics',
  'value': 'Applied Mathematics'
}, {
  'label': 'Statistics and Decision Science',
  'value': 'Statistics and Decision Science'
}, {
  'label': 'Mathematics and Computer Science',
  'value': 'Mathematics and Computer Science'
}, {
  'label': 'General Education',
  'value': 'General Education'
}, {
  'label': 'Educational Administration and Supervision',
  'value': 'Educational Administration and Supervision'
}, {
  'label': 'School Student Counseling',
  'value': 'School Student Counseling'
}, {
  'label': 'Elementary Education',
  'value': 'Elementary Education'
}, {
  'label': 'Mathematics Teacher Education',
  'value': 'Mathematics Teacher Education'
}, {
  'label': 'Physical and Health Education Teaching',
  'value': 'Physical and Health Education Teaching'
}, {
  'label': 'Early Childhood Education',
  'value': 'Early Childhood Education'
}, {
  'label': 'Science and Computer Teacher Education',
  'value': 'Science and Computer Teacher Education'
}, {
  'label': 'Secondary Teacher Education',
  'value': 'Secondary Teacher Education'
}, {
  'label': 'Special Needs Education',
  'value': 'Special Needs Education'
}, {
  'label': 'Social Science Or History Teacher Education',
  'value': 'Social Science Or History Teacher Education'
}, {
  'label': 'Teacher Education: Multiple Levels',
  'value': 'Teacher Education: Multiple Levels'
}, {
  'label': 'Language and Drama Education',
  'value': 'Language and Drama Education'
}, {
  'label': 'Art and Music Education',
  'value': 'Art and Music Education'
}, {
  'label': 'Miscellaneous Education',
  'value': 'Miscellaneous Education'
}, {
  'label': 'Library Science',
  'value': 'Library Science'
}, {
  'label': 'Architecture',
  'value': 'Architecture'
}, {
  'label': 'General Engineering',
  'value': 'General Engineering'
}, {
  'label': 'Aerospace Engineering',
  'value': 'Aerospace Engineering'
}, {
  'label': 'Biological Engineering',
  'value': 'Biological Engineering'
}, {
  'label': 'Architectural Engineering',
  'value': 'Architectural Engineering'
}, {
  'label': 'Biomedical Engineering',
  'value': 'Biomedical Engineering'
}, {
  'label': 'Chemical Engineering',
  'value': 'Chemical Engineering'
}, {
  'label': 'Civil Engineering',
  'value': 'Civil Engineering'
}, {
  'label': 'Computer Engineering',
  'value': 'Computer Engineering'
}, {
  'label': 'Electrical Engineering',
  'value': 'Electrical Engineering'
}, {
  'label': 'Engineering Mechanics Physics and Science',
  'value': 'Engineering Mechanics Physics and Science'
}, {
  'label': 'Environmental Engineering',
  'value': 'Environmental Engineering'
}, {
  'label': 'Geological and Geophysical Engineering',
  'value': 'Geological and Geophysical Engineering'
}, {
  'label': 'Industrial and Manufacturing Engineering',
  'value': 'Industrial and Manufacturing Engineering'
}, {
  'label': 'Materials Engineering and Materials Science',
  'value': 'Materials Engineering and Materials Science'
}, {
  'label': 'Mechanical Engineering',
  'value': 'Mechanical Engineering'
}, {
  'label': 'Metallurgical Engineering',
  'value': 'Metallurgical Engineering'
}, {
  'label': 'Mining and Mineral Engineering',
  'value': 'Mining and Mineral Engineering'
}, {
  'label': 'Naval Architecture and Marine Engineering',
  'value': 'Naval Architecture and Marine Engineering'
}, {
  'label': 'Nuclear Engineering',
  'value': 'Nuclear Engineering'
}, {
  'label': 'Petroleum Engineering',
  'value': 'Petroleum Engineering'
}, {
  'label': 'Miscellaneous Engineering',
  'value': 'Miscellaneous Engineering'
}, {
  'label': 'Engineering Technologies',
  'value': 'Engineering Technologies'
}, {
  'label': 'Engineering and Industrial Management',
  'value': 'Engineering and Industrial Management'
}, {
  'label': 'Electrical Engineering Technology',
  'value': 'Electrical Engineering Technology'
}, {
  'label': 'Industrial Production Technologies',
  'value': 'Industrial Production Technologies'
}, {
  'label': 'Mechanical Engineering Related Technologies',
  'value': 'Mechanical Engineering Related Technologies'
}, {
  'label': 'Miscellaneous Engineering Technologies',
  'value': 'Miscellaneous Engineering Technologies'
}, {
  'label': 'Materials Science',
  'value': 'Materials Science'
}, {
  'label': 'Nutrition Sciences',
  'value': 'Nutrition Sciences'
}, {
  'label': 'General Medical and Health Services',
  'value': 'General Medical and Health Services'
}, {
  'label': 'Communication Disorders Sciences and Services',
  'value': 'Communication Disorders Sciences and Services'
}, {
  'label': 'Health and Medical Administrative Services',
  'value': 'Health and Medical Administrative Services'
}, {
  'label': 'Medical Assisting Services',
  'value': 'Medical Assisting Services'
}, {
  'label': 'Medical Technologies Technicians',
  'value': 'Medical Technologies Technicians'
}, {
  'label': 'Health and Medical Preparatory Programs',
  'value': 'Health and Medical Preparatory Programs'
}, {
  'label': 'Nursing',
  'value': 'Nursing'
}, {
  'label': 'Pharmacy Pharmaceutical Sciences and Administration',
  'value': 'Pharmacy Pharmaceutical Sciences and Administration'
}, {
  'label': 'Treatment Therapy Professions',
  'value': 'Treatment Therapy Professions'
}, {
  'label': 'Community and Public Health',
  'value': 'Community and Public Health'
}, {
  'label': 'Miscellaneous Health Medical Professions',
  'value': 'Miscellaneous Health Medical Professions'
}, {
  'label': 'Area Ethnic and Civilization Studies',
  'value': 'Area Ethnic and Civilization Studies'
}, {
  'label': 'Linguistics and Comparative Language and Literature',
  'value': 'Linguistics and Comparative Language and Literature'
}, {
  'label': 'French German Latin and Other Common Foreign Language Studies',
  'value': 'French German Latin and Other Common Foreign Language Studies'
}, {
  'label': 'Other Foreign Languages',
  'value': 'Other Foreign Languages'
}, {
  'label': 'English Language and Literature',
  'value': 'English Language and Literature'
}, {
  'label': 'Composition and Rhetoric',
  'value': 'Composition and Rhetoric'
}, {
  'label': 'Liberal Arts',
  'value': 'Liberal Arts'
}, {
  'label': 'Humanities',
  'value': 'Humanities'
}, {
  'label': 'Intercultural and International Studies',
  'value': 'Intercultural and International Studies'
}, {
  'label': 'Philosophy and Religious Studies',
  'value': 'Philosophy and Religious Studies'
}, {
  'label': 'Theology and Religious Vocations',
  'value': 'Theology and Religious Vocations'
}, {
  'label': 'Anthropology and Archeology',
  'value': 'Anthropology and Archeology'
}, {
  'label': 'Art History and Criticism',
  'value': 'Art History and Criticism'
}, {
  'label': 'History',
  'value': 'History'
}, {
  'label': 'United States History',
  'value': 'United States History'
}, {
  'label': 'Cosmetology Services and Culinary Arts',
  'value': 'Cosmetology Services and Culinary Arts'
}, {
  'label': 'Family and Consumer Sciences',
  'value': 'Family and Consumer Sciences'
}, {
  'label': 'Military Technologies',
  'value': 'Military Technologies'
}, {
  'label': 'Physical Fitness Parks Recreation and Leisure',
  'value': 'Physical Fitness Parks Recreation and Leisure'
}, {
  'label': 'Construction Services',
  'value': 'Construction Services'
}, {
  'label': 'Electrical, Mechanical, and Precision Technologies and Production',
  'value': 'Electrical, Mechanical, and Precision Technologies and Production'
}, {
  'label': 'Transportation Sciences and Technologies',
  'value': 'Transportation Sciences and Technologies'
}, {
  'label': 'Multi/interdisciplinary Studies',
  'value': 'Multi/interdisciplinary Studies'
}, {
  'label': 'Court Reporting',
  'value': 'Court Reporting'
}, {
  'label': 'Pre-law and Legal Studies',
  'value': 'Pre-law and Legal Studies'
}, {
  'label': 'Criminal Justice',
  'value': 'Criminal Justice'
}, {
  'label': 'Fire Protection',
  'value': 'Fire Protection'
}, {
  'label': 'Public Administration',
  'value': 'Public Administration'
}, {
  'label': 'Public Policy',
  'value': 'Public Policy'
}, {
  'label': 'N/A (less Than Bachelor\'s Degree)',
  'value': 'N/A (less Than Bachelor\'s Degree)'
}, {
  'label': 'Physical Sciences',
  'value': 'Physical Sciences'
}, {
  'label': 'Astronomy and Astrophysics',
  'value': 'Astronomy and Astrophysics'
}, {
  'label': 'Atmospheric Sciences and Meteorology',
  'value': 'Atmospheric Sciences and Meteorology'
}, {
  'label': 'Chemistry',
  'value': 'Chemistry'
}, {
  'label': 'Geology and Earth Science',
  'value': 'Geology and Earth Science'
}, {
  'label': 'Geosciences',
  'value': 'Geosciences'
}, {
  'label': 'Oceanography',
  'value': 'Oceanography'
}, {
  'label': 'Physics',
  'value': 'Physics'
}, {
  'label': 'Multi-disciplinary Or General Science',
  'value': 'Multi-disciplinary Or General Science'
}, {
  'label': 'Nuclear, Industrial Radiology, and Biological Technologies',
  'value': 'Nuclear, Industrial Radiology, and Biological Technologies'
}, {
  'label': 'Psychology',
  'value': 'Psychology'
}, {
  'label': 'Educational Psychology',
  'value': 'Educational Psychology'
}, {
  'label': 'Clinical Psychology',
  'value': 'Clinical Psychology'
}, {
  'label': 'Counseling Psychology',
  'value': 'Counseling Psychology'
}, {
  'label': 'Industrial and Organizational Psychology',
  'value': 'Industrial and Organizational Psychology'
}, {
  'label': 'Social Psychology',
  'value': 'Social Psychology'
}, {
  'label': 'Miscellaneous Psychology',
  'value': 'Miscellaneous Psychology'
}, {
  'label': 'Human Services and Community Organization',
  'value': 'Human Services and Community Organization'
}, {
  'label': 'Social Work',
  'value': 'Social Work'
}, {
  'label': 'Interdisciplinary Social Sciences',
  'value': 'Interdisciplinary Social Sciences'
}, {
  'label': 'General Social Sciences',
  'value': 'General Social Sciences'
}, {
  'label': 'Economics',
  'value': 'Economics'
}, {
  'label': 'Criminology',
  'value': 'Criminology'
}, {
  'label': 'Geography',
  'value': 'Geography'
}, {
  'label': 'International Relations',
  'value': 'International Relations'
}, {
  'label': 'Political Science and Government',
  'value': 'Political Science and Government'
}, {
  'label': 'Sociology',
  'value': 'Sociology'
}, {
  'label': 'Miscellaneous Social Sciences',
  'value': 'Miscellaneous Social Sciences'
}];