import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { withHooks } from '../../../utils/withHooks';

import { toastr } from 'react-redux-toastr';

class StickyDiv extends Component {
  static propTypes = {
    component: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func
    ]),
    startId: PropTypes.string,
    endId: PropTypes.string,
    defaultCss: PropTypes.string,
    disableSticking: PropTypes.bool,
    gap: PropTypes.number,
    isAuthenticated: PropTypes.bool,
    slug: PropTypes.string,
    unit: PropTypes.object,
    navigate: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      doNotScroll: false,
      prevOffsetTop: Infinity,
      height: 66,
      gap: this.props.gap || 0,
      stickyStyles: this.props.defaultCss
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.onClickReviewEssentials = this.onClickReviewEssentials.bind(this);
    this.onClickEngage = this.onClickEngage.bind(this);
    this.onClickGetBadge = this.onClickGetBadge.bind(this);
  }

  componentDidMount() {
    if (!this.props.disableSticking)
      window.addEventListener('scroll', this.handleScroll, false);
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.doNotScroll !== this.state.doNotScroll)
      this.setState({
        stickyStyles: classNames(this.props.defaultCss, {
          'navbar-fixed-top': nextState.doNotScroll
        })
      });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  onClickReviewEssentials(event){
    event.preventDefault();

    const { isAuthenticated, navigate, slug} = this.props;
    const redirectPath = 'skill-builders';

    if(!isAuthenticated)
      return toastr.error('Permission is Needed.', 'You must be signed in to access.');

    navigate(`/${redirectPath}/essentials/${slug}`);

  }

  onClickEngage(event){
    event.preventDefault();

    const { isAuthenticated, navigate, slug } = this.props;
    const redirectPath = 'skill-builders';

    if(!isAuthenticated)
      return toastr.error('Permission is Needed.', 'You must be signed in to join the discussion.');

    navigate(`/${redirectPath}/discussions/${slug}`);

  }

  onClickGetBadge(link){
    return () => {
      const { isAuthenticated, navigate } = this.props;

      if(!isAuthenticated)
        return toastr.error('Permission is Needed.', 'You must be signed in to claim a badge.');

      navigate(link);
    };
  }

  handleScroll() {
    const {
      startId,
      endId
    } = this.props;

    let start = document.getElementById(startId);
    let end = document.getElementById(endId);

    if(!start || !end)
      return;

    if (start.offsetTop !== 0)
      this.setState({
        height: end.offsetTop - start.offsetTop - this.state.gap,
        prevOffsetTop: start.offsetTop
      });

    if (window.pageYOffset >= this.state.prevOffsetTop && !this.state.doNotScroll) {
      return this.setState({
        doNotScroll: true
      });
    }

    if (window.pageYOffset <= this.state.prevOffsetTop && this.state.doNotScroll) {
      return this.setState({
        doNotScroll: false
      });
    }
  }

  render() {
    const {
      doNotScroll,
      height,
      stickyStyles
    } = this.state;

    return(
      <div className="col-lg-12">
        {doNotScroll &&
          <div style={{ opacity: 0, height: height }}>
            This transparent div is added to compensate the decreased height
          </div>}

        <this.props.component
          stickyStyles={stickyStyles}
          onClickReviewEssentials={this.onClickReviewEssentials}
          onClickEngage={this.onClickEngage}
          onClickGetBadge={this.onClickGetBadge}
          {...this.props}/>
      </div>
    );
  }
}

export default withHooks(StickyDiv);
