import { CALL_API } from '../../middlewares/api';

import { isUndefined } from 'lodash';

import {
  USERS_STATISTICS_REQUEST,
  USERS_STATISTICS_SUCCESS,
  USERS_STATISTICS_FAILURE,

  GET_ALL_EDUCATORS_REQUEST,
  GET_ALL_EDUCATORS_SUCCESS,
  GET_ALL_EDUCATORS_FAILURE,

  SET_SELECTED_EDUCATOR,

  GET_ALL_GROUPS_REQUEST,
  GET_ALL_GROUPS_SUCCESS,
  GET_ALL_GROUPS_FAILURE,

  JOBS_STATISTICS_REQUEST,
  JOBS_STATISTICS_SUCCESS,
  JOBS_STATISTICS_FAILURE,

  FILES_STATISTICS_REQUEST,
  FILES_STATISTICS_SUCCESS,
  FILES_STATISTICS_FAILURE,

  SKILLS_STATISTICS_REQUEST,
  SKILLS_STATISTICS_SUCCESS,
  SKILLS_STATISTICS_FAILURE,

  BADGES_STATISTICS_REQUEST,
  BADGES_STATISTICS_SUCCESS,
  BADGES_STATISTICS_FAILURE,

  SURVEYS_STATISTICS_REQUEST,
  SURVEYS_STATISTICS_SUCCESS,
  SURVEYS_STATISTICS_FAILURE,

  SURVEY_FEEDBACKS_REQUEST,
  SURVEY_FEEDBACKS_SUCCESS,
  SURVEY_FEEDBACKS_FAILURE,

  SET_SELECTED_STATISTICS_EDUCATOR_GROUP
} from '../../constants';

export const getUsersStatistics = (userId) => {
  const endpoint = `user/statistics/${userId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        USERS_STATISTICS_REQUEST,
        USERS_STATISTICS_SUCCESS,
        USERS_STATISTICS_FAILURE
      ]
    }
  };
};

export const getJobsStatistics = (userId) => {
  const endpoint = `statistics/jobs/${userId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        JOBS_STATISTICS_REQUEST,
        JOBS_STATISTICS_SUCCESS,
        JOBS_STATISTICS_FAILURE
      ]
    }
  };
};

export const getSkillsStatistics = (userId) => {
  const endpoint = `statistics/skills/${userId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        SKILLS_STATISTICS_REQUEST,
        SKILLS_STATISTICS_SUCCESS,
        SKILLS_STATISTICS_FAILURE
      ]
    }
  };
};

export const getFilesStatistics = (userId) => {
  const endpoint = `statistics/files/${userId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        FILES_STATISTICS_REQUEST,
        FILES_STATISTICS_SUCCESS,
        FILES_STATISTICS_FAILURE
      ]
    }
  };
};

export const getBadgesStatistics = () => {
  const endpoint = 'statistics/skills';

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        BADGES_STATISTICS_REQUEST,
        BADGES_STATISTICS_SUCCESS,
        BADGES_STATISTICS_FAILURE
      ]
    }
  };
};

export const getSurveysStatistics = (skillBuilderUnitId = '') => {
  let endpoint = 'statistics/surveys';

  if (skillBuilderUnitId.length > 0) {
    endpoint = `${endpoint}?skillBuilderUnitId=${skillBuilderUnitId}`;
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        SURVEYS_STATISTICS_REQUEST,
        SURVEYS_STATISTICS_SUCCESS,
        SURVEYS_STATISTICS_FAILURE
      ]
    }
  };
};

export const getSurveyFeedbacks = (urlParamString = '') => {
  let endpoint = 'statistics/survey-feedbacks';

  if (urlParamString.length > 0) {
    endpoint = `${endpoint}?${urlParamString}`;
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        SURVEY_FEEDBACKS_REQUEST,
        SURVEY_FEEDBACKS_SUCCESS,
        SURVEY_FEEDBACKS_FAILURE
      ]
    }
  };
};

export const getAllEducators = (urlParamString, isInitialRequest = false) => {
  let endpoint = 'statistics/educators';

  if (!isUndefined(urlParamString)) {
    endpoint = `${endpoint}?${urlParamString}`;
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      isInitialRequest,
      authenticated: true,
      types: [
        GET_ALL_EDUCATORS_REQUEST,
        GET_ALL_EDUCATORS_SUCCESS,
        GET_ALL_EDUCATORS_FAILURE
      ]
    }
  };
};

export const setSelectedEducator = selectedEducator => ({
  type: SET_SELECTED_EDUCATOR,
  selectedEducator
});

export const getAllGroups = (
  educatorId,
  urlParamString = '',
  isInitialRequest = false
) => {
  let endpoint = `group/statistics?educator=${educatorId}`;

  if (urlParamString.length !== 0) {
    endpoint = `${endpoint}${urlParamString}`;
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      isInitialRequest,
      authenticated: true,
      types: [
        GET_ALL_GROUPS_REQUEST,
        GET_ALL_GROUPS_SUCCESS,
        GET_ALL_GROUPS_FAILURE
      ]
    }
  };
};

export const setSelectedEducatorGroup = selectedGroup => ({
  type: SET_SELECTED_STATISTICS_EDUCATOR_GROUP,
  selectedGroup
});
