import initialState from './initialState';

import {
  INTERESTS_REQUEST,
  INTERESTS_SUCCESS,
  INTERESTS_FAILURE,
  CREATE_INTEREST_REQUEST,
  CREATE_INTEREST_SUCCESS,
  CREATE_INTEREST_FAILURE,
  UPDATE_INTEREST_REQUEST,
  UPDATE_INTEREST_SUCCESS,
  UPDATE_INTEREST_FAILURE,
  DELETE_INTEREST_REQUEST,
  DELETE_INTEREST_SUCCESS,
  DELETE_INTEREST_FAILURE,
  DELETE_INTERESTS_SECTION_REQUEST,
  DELETE_INTERESTS_SECTION_SUCCESS,
  DELETE_INTERESTS_SECTION_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.interests, action) {
  switch (action.type) {
  case INTERESTS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case INTERESTS_SUCCESS:
    return Object.assign({}, state, {
      data: action.data._embedded.interests,
      isRequesting: false,
    });

  case INTERESTS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case CREATE_INTEREST_REQUEST:
    return Object.assign({}, state, { isSubmitting: true });

  case CREATE_INTEREST_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data, ...action.data.interests],
      isSubmitting: false,
    });

  case CREATE_INTEREST_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case UPDATE_INTEREST_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, interestIndex: action.index },
    });

  case UPDATE_INTEREST_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, state.isUpdating.interestIndex),
        Object.assign({}, action.data),
        ...state.data.slice(state.isUpdating.interestIndex + 1),
      ],
      isUpdating: { status: false, interestIndex: null },
    });

  case UPDATE_INTEREST_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false, interestIndex: null },
    });

  case DELETE_INTEREST_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: action.id },
    });

  case DELETE_INTEREST_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.filter(
          interest => interest.id !== state.isDeleting.id
        ),
      ],
      isDeleting: { status: false, id: null },
    });

  case DELETE_INTEREST_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: { status: false, id: null },
    });

  case DELETE_INTERESTS_SECTION_REQUEST:
    return {
      ...state,
      isReqesting: true,
    };

  case DELETE_INTERESTS_SECTION_SUCCESS:
    return {
      ...state,
      data: [...action.data.interests],
      isReqesting: false,
    };

  case DELETE_INTERESTS_SECTION_FAILURE:
    return {
      ...state,
      error: action.error,
      isReqesting: false,
    };

  default:
    return state;
  }
}
