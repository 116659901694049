import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  editButton: {
    marginLeft: 10,
    fontWeight: 'normal',
    cursor: 'pointer',
    fontSize: 10
  }
};

const InterestCard = ({
  interest,
  onEditInterestClick,
  connectDragSource,
  isPublicProfile,
}) => {
  return (
    <span
      style={{marginRight: 5, marginBottom: 5, padding: 8, fontWeight: 'normal'}}
      className="badge badge-pill badge-primary">
      {interest.name}

      {!isPublicProfile ?
        <span>
          <i
            style={styles.editButton}
            onClick={onEditInterestClick}
            id={`update-interest-form-${interest.id}`}
            className="fa fa-pencil" aria-hidden="true"/>

          {connectDragSource(
            <i
              style={{cursor: 'move', fontSize: 12, marginLeft: 10}}
              className="fa fa-arrows"/>)}
        </span>
        :
        null
      }
    </span>
  );
};

InterestCard.propTypes = {
  interest: PropTypes.object.isRequired,
  isPublicProfile: PropTypes.bool.isRequired,
  onEditInterestClick: PropTypes.func.isRequired,
  last: PropTypes.bool.isRequired,
  connectDragSource: PropTypes.func.isRequired,
};

export default InterestCard;
