import PropTypes from 'prop-types';
import React from 'react';

import _isObject from 'lodash/isObject';

import Button from '../../common/Button';
import TextInput from '../../common/TextInput';
import QuillEditor from '../../common/QuillEditor';
import classNames from 'classnames';

const modules = {
  toolbar: [
    [{ 'align': [] }],
    ['bold', 'italic', 'underline'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    ['link'],
  ],
  clipboard: {
    matchVisual: false,
  }
};

const ProjectForm = ({
  project,
  onChange,
  onSave,
  onCancel,
  onDelete,
  isSubmitting,
  isUpdating,
  isDeleting,
  errors,
  showDelete,
  onChangeDescription
}) => {
  const titleLabel = (
    <span>
      Title:
    </span>
  );

  if (_isObject(project.startdate)) {
    project.startdate = project.startdate.date.replace(' 00:00:00.000000', '');
  }

  if (_isObject(project.enddate)) {
    project.enddate = project.enddate.date.replace(' 00:00:00.000000', '');
  }

  const endDateNote = project.noend ? '' : 'Required';

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <TextInput
            name="title"
            label={titleLabel}
            note="Required"
            placeholder="Enter your title"
            error={errors.title}
            value={project.title}
            onChange={onChange}/>
        </div>
        <div className="col-lg-12">
          <div style={{marginBottom: 10}}
            className={classNames('statement-form-container', {
              'has-danger': errors.description
            })}>
            <label className={classNames('form-control-label', {'text-danger': errors.description })}>
              Description: <span className="note">(Required)</span></label>
            <QuillEditor
              modules={modules}
              placeholder="Enter your project description"
              onChange={onChangeDescription}
              value={project.description}/>

            {errors.description &&
              <div className="text-danger">{errors.description}</div>}
          </div>
        </div>
        <div className="col-lg-12">
          <TextInput
            name="url"
            label="Project URL:"
            placeholder="Enter your project URL"
            error={errors.url}
            value={project.url}
            onChange={onChange}/>
        </div>
        <div className="col-lg-12">
          <div className="row date">
            <div className="col-lg-6">
              <TextInput
                type="date"
                name="startdate"
                label="Start Date: "
                note="Required"
                error={errors.startdate}
                value={project.startdate}
                onChange={onChange}/>
            </div>
            <div className="col-lg-6">
              <TextInput
                type="date"
                name="enddate"
                label="End Date: "
                note={endDateNote}
                error={errors.enddate}
                disabled={project.noend}
                value={project.enddate}
                onChange={onChange}/>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-check mb-3">
            <input
              name="noend"
              checked={project.noend}
              onChange={onChange}
              className="form-check-input"
              type="checkbox"/>
            <label className="form-check-label">Check if present</label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="action-bar text-right">
            {showDelete &&
              <Button
                type="submit"
                onClick={onDelete}
                styles={{marginLeft: 0}}
                disabled={isDeleting}
                buttonClass="btn-danger"
                buttonText={isDeleting ? 'Deleting...' : 'Delete'}/>}
            <Button
              type="button"
              onClick={onCancel}
              buttonClass="btn-secondary"
              buttonText="Cancel"/>
            <Button
              type="submit"
              onClick={onSave}
              disabled={isSubmitting || isUpdating}
              buttonClass="btn-primary"
              buttonText={isSubmitting || isUpdating ? 'Saving...' : 'Save'}/>
          </div>
        </div>
      </div>
    </div>
  );
};

ProjectForm.propTypes = {
  isSubmitting: PropTypes.bool,
  isUpdating: PropTypes.bool,
  isDeleting: PropTypes.bool,
  project: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  errors: PropTypes.object.isRequired,
  showDelete: PropTypes.bool,
  onChangeDescription: PropTypes.func
};

export default ProjectForm;
