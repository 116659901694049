import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { checkHttp } from '../../utils';

const ResumeExperience = ({ resumeCertifications }) => {
  return (
    <div className="resume-section">
      <div className="resume-title">
        {resumeCertifications.title !== '' ? resumeCertifications.title : 'Certifications'}
      </div>
      {
        resumeCertifications.certificationsArray.map((certification) =>{
          const isStartDateObject = typeof certification.startdate === 'object';
          const startDateString = isStartDateObject ? certification.startdate.date :
            certification.startdate === '' ? undefined : certification.startdate;

          const isEndDateObject = typeof certification.enddate === 'object';
          const endDateString = isEndDateObject ? certification.enddate.date :
            certification.enddate === '' ? undefined : certification.enddate;

          const startDate = moment(startDateString).format('MM/YYYY');
          let endDate;

          if (certification.noend) {
            endDate = 'Present';
          } else {
            endDate = moment(endDateString).format('MM/YYYY');
          }

          const urlAvailable = certification.url !== '';
          const urlString = checkHttp(certification.url) ?
            certification.url : `//${certification.url}`;

          return(
            <div key={certification.id} style={{marginBottom: 10}}>
              <span>{certification.name} - {!urlAvailable ?
                certification.authority :
                <a href={urlString} target="_blank" rel="noopener noreferrer">{certification.authority}</a>}
              </span>
              <span style={{marginLeft: 10}}>({startDate} - {endDate})</span>
              <p>License Number: {certification.license}</p>
            </div>
          );
        })
      }
    </div>
  );
};

ResumeExperience.propTypes = {
  resumeCertifications: PropTypes.object.isRequired,
};

export default ResumeExperience;
