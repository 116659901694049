import { createSelector } from 'reselect';
import { uniqueId } from '../../utils';

const selectCountries = state => state.countries;

export const selectCountriesList = createSelector(
  [selectCountries],
  countries => countries.data
);

export const selectFormattedCountries = createSelector(
  [selectCountriesList],
  countries =>
    countries.map(
      country => ({
        id: uniqueId(),
        label: country.countryName,
        value: country.countryId,
        code: country.countryCode
      })
    )
);

export const selectCountriesPageCount = createSelector(
  [selectCountries],
  countries => countries.pageCount
);
