import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as profileActions from '../../../redux/actions/profile';
import * as cpStatusActions from '../../../redux/actions/my-careerprepped-status';

import Button from '../../../components/common/Button';

class Situation extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    isUpdating: PropTypes.bool
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      profile: Object.assign({}, this.props.profile)
    };

    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  onChange(event) {
    const { name, value } = event.target;
    const { profile } = this.state;

    const isWorking = ((String(profile.employmentstatus) !== '2') && (String(profile.employmentstatus) !== '3'));
    const isNotWorkingSelected = ((name === 'employmentstatus') && ((value === '2') || (value === '3')));

    if (isWorking && (name === 'employmenttype') && value === '6') return;

    if (isNotWorkingSelected) {
      profile['employmenttype'] = '6';
    }

    if ((! isNotWorkingSelected) && (String(profile.employmenttype) === '6')) {
      profile.employmenttype = null;
    }

    profile[name] = value;

    this.setState({ profile });
  }

  onSave(event) {
    event.preventDefault();

    const { profile } = this.state;

    const data = {
      employmentstatus: parseInt(profile.employmentstatus),
      employmenttype: parseInt(profile.employmenttype)
    };
    this.props.actions.updateProfile(profile.id, data).then(async () => {
      await this.props.actions.updateMyCareerPreppedStatus();
      this.props.actions.getMyCareerPreppedStatus();
    });
  }

  render() {
    const { profile } = this.state;
    const { isUpdating } = this.props;
    const enableSaveButton = profile.employmentstatus !== null && profile.employmenttype !== null;
    const isWorking = String(profile.employmentstatus) !== '2' && String(profile.employmentstatus) !== '3';

    return (
      <div className="profile-item__card primary">
        <div className="head">My Work Situation</div>
        <p className="note">(This information is only visible to you and will help ensure notifications and messages from CareerPrepped are relevant to your situation)</p>
        <div className="subhead font-weight-bold">Your Employment Status </div>
        <div className="form-check">
          <label className="form-check-label">
            <input
              className="form-check-input"
              style={{marginRight: 5}}
              type="radio"
              name="employmentstatus"
              value="0"
              onChange={this.onChange}
              checked={String(profile.employmentstatus) === '0'}/> I'm working and not seeking a job
          </label>
          <label className="form-check-label">
            <input
              className="form-check-input"
              style={{marginRight: 5}}
              type="radio"
              name="employmentstatus"
              value="1"
              onChange={this.onChange}
              checked={String(profile.employmentstatus) === '1'}/> I'm working but open to new opportunities
          </label>
          <label className="form-check-label">
            <input
              className="form-check-input"
              style={{marginRight: 5}}
              type="radio"
              name="employmentstatus"
              value="2"
              onChange={this.onChange}
              checked={String(profile.employmentstatus) === '2'}/> I'm not working and actively seeking a job
          </label>
          <label className="form-check-label">
            <input
              className="form-check-input"
              style={{marginRight: 5}}
              type="radio"
              name="employmentstatus"
              value="3"
              onChange={this.onChange}
              checked={String(profile.employmentstatus) === '3'}/> I'm not working and not seeking a job at this time
          </label>
        </div>

        <div className="subhead mb-2 mt-3">
          <div className="font-weight-bold">Employment Type</div>
          <span className="font12">
            (Choose the one that best describes your situation)
          </span>
        </div>
        <div className="row mb-3">
          <div className="col-lg-10">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  style={{marginRight: 5}}
                  type="radio"
                  onChange={this.onChange}
                  name="employmenttype"
                  value="0"
                  checked={((String(profile.employmenttype) === '0') && isWorking)}/> Full-Time
              </label>
            </div>
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  style={{marginRight: 5}}
                  type="radio"
                  onChange={this.onChange}
                  name="employmenttype"
                  value="3"
                  checked={((String(profile.employmenttype) === '3') && isWorking)}/> Part-Time
              </label>
            </div>
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  style={{marginRight: 5}}
                  type="radio"
                  onChange={this.onChange}
                  name="employmenttype"
                  value="1"
                  checked={((String(profile.employmenttype) === '1') && isWorking)}/> Paid Internship
              </label>
            </div>
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  style={{marginRight: 5}}
                  type="radio"
                  onChange={this.onChange}
                  name="employmenttype"
                  value="4"
                  checked={((String(profile.employmenttype) === '4') && isWorking)}/> Unpaid Internship
              </label>
            </div>
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  style={{marginRight: 5}}
                  type="radio"
                  onChange={this.onChange}
                  name="employmenttype"
                  value="2"
                  checked={((String(profile.employmenttype) === '2') && isWorking)}/> Temporary Contract
              </label>
            </div>
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  style={{marginRight: 5}}
                  type="radio"
                  onChange={this.onChange}
                  name="employmenttype"
                  value="5"
                  checked={((String(profile.employmenttype) === '5') && isWorking)}/> Self-employed
              </label>
            </div>
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  style={{marginRight: 5}}
                  type="radio"
                  onChange={this.onChange}
                  name="employmenttype"
                  value="6"
                  checked={!isWorking}/> Not working
              </label>
            </div>
          </div>
        </div>
        
        <div className="action-bar text-right">
          <Button
            type="submit"
            onClick={this.onSave}
            disabled={isUpdating || !enableSaveButton}
            buttonClass="btn btn-primary"
            buttonText={isUpdating && enableSaveButton ? 'Saving...' : 'Save'}/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.data,
    isUpdating: state.profile.isUpdating
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, profileActions, cpStatusActions);
  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Situation);
