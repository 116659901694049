import { combineReducers } from 'redux';

import { USER_LOGOUT, SYNC_STORE } from '../../constants';

import institutions from './institutions';
import groups from './groups';
import peers from './peers';
import educators from './educators';
import users from './users';
import statistics from './statistics';
import messageReplies from './message-replies';
import messages from './messages';
import files from './files';
import clubs from './clubs';
import contact from './contact';
import resetPassword from './reset-password';
import router from './router';
import badges from './badges';
import skills from './skills';
import awards from './awards';
import group from './group';
import profile from './profile';
import projects from './projects';
import sections from './sections';
import comments from './comments';
import interests from './interests';
import auth from './authentication';
import followers from './followers';
import following from './following';
import education from './education';
import wallPosts from './wall-posts';
import jobNotes from './job-notes';
import jobSearch from './job-search';
import jobBoards from './job-boards';
import jobMatchSettings from './job-match-settings';
import jobMatches from './job-matches';
import components from './components';
import experiences from './experience';
import discussions from './discussions';
import reflections from './reflections';
import associations from './associations';
import registration from './registration';
import subscriptions from './subscriptions';
import publications from './publications';
import notifications from './notifications';
import skillBuilders from './skill-builders';
import certifications from './certification';
import badgeAssertion from './badge-assertion';
import badgeEndorsement from './badge-endorsement';
import wallPostComments from './wall-post-comments';
import resume from './resume';
import webpage from './webpage';
import organizations from './organizations';
import organizationInvitations from './organization-invitations';
import organizationUsers from './organization-users';
import organizationStats from './organization-stats';
import adminGroups from './admin-group';
import questionnaires from './questionnaires';
import skillbuilderCategory from './sb-category';
import networks from './networks';
import interview from './interview';
import search from './search';
import initialState from './initialState';
import myskills from './myskills';
import skillCategories from './skill-categories';
import testimonials from './testimonials';
import myfeedback from './myfeedback';
import countries from './countries';
import states from './states';
import cities from './cities';
import clusters from './clusters';
import industries from './industries';
import occupations from './occupations';
import clusterIndustryOccupation from './clusterIndustryOccupation';
import irs from './irs';
import givefeedback from './givefeedback';
import inviteUsers from './invite-users';
import feedbackPraise from './feedback-praise';
import myCareerPreppedStatus from './my-careerprepped-status';
import industryRepresentatives from './industry-representatives';
import { reducer as toastr } from 'react-redux-toastr';

const appReducer = combineReducers({
  /**
   * Router reducers
   */
  router,

  /**
   * Toastr reducers
   */
  toastr,

  /**
   * App components reducers
   */
  components,

  /**
   * Data reducers
   */
  auth,
  resetPassword,
  group,
  profile,
  notifications,
  messages,
  messageReplies,
  education,
  experiences,
  certifications,
  projects,
  publications,
  awards,
  skills,
  interests,
  associations,
  clubs,
  contact,
  badges,
  jobSearch,
  jobBoards,
  jobNotes,
  jobMatchSettings,
  jobMatches,
  followers,
  following,
  registration,
  subscriptions,
  skillBuilders,
  reflections,
  sections,
  discussions,
  comments,
  wallPosts,
  wallPostComments,
  files,
  badgeAssertion,
  badgeEndorsement,
  resume,
  webpage,
  organizations,
  organizationInvitations,
  organizationUsers,
  organizationStats,
  adminGroups,
  questionnaires,
  skillbuilderCategory,
  networks,
  interview,
  search,
  peers,
  educators,
  industryRepresentatives,
  institutions,
  groups,
  users,
  statistics,
  myskills,
  skillCategories,
  testimonials,
  myfeedback,
  countries,
  states,
  cities,
  clusters,
  industries,
  occupations,
  clusterIndustryOccupation,
  irs,
  givefeedback,
  inviteUsers,
  feedbackPraise,
  myCareerPreppedStatus,
});

const rootReducer = (state, action) => {
  if (action.type === SYNC_STORE) {
    state = Object.assign({}, state, {
      profile: action.newState.profile,
      auth: Object.assign({}, state.auth, {
        ...action.newState.auth,
        lastLoggedInUserId: state.auth.lastLoggedInUserId,
      }),
    });
  }

  if (action.type === USER_LOGOUT) {
    state = {
      components: Object.assign({}, initialState.components, {
        isMobile: state.components.isMobile,
      }),
    };
  }

  return appReducer(state, action);
};

export default rootReducer;
