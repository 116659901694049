import PropTypes from 'prop-types';
import React from 'react';

import ReflectionQuestionContainer from '../../../containers/skill-builders/ReflectionQuestionContainer';

const ReflectionQuestion = ({
  unit,
  responseOne,
  responseTwo,
  isAdmin,
  nameOne,
  nameTwo,
  questionOne,
  questionTwo,
  valueOne,
  valueTwo,
  onShareIndividualReflection,
  toggleReflectionGuidanceModal
}) => {
  if (!isAdmin) {
    return (
      <section className="essential-reflection">
        <div className="essential-reflection--header">
          <h2>Reflection Questions</h2>
          <a
            href="#"
            className="guidance-link clickable"
            onClick={toggleReflectionGuidanceModal}>
            See Guidance
          </a>
        </div>
        <div className="section-icon">
          <svg viewBox="0 0 120 100">
            <path d="M38,2 L82,2 A12,12 0 0,1 94,10 L112,44 A12,12 0 0,1 112,56 L94,90 A12,12 0 0,1 82,98 L38,98 A12,12 0 0,1 26,90 L8,56 A12,12 0 0,1 8,44 L26,10 A12,12 0 0,1 38,2" />
          </svg>
          <div className="icon">
            <div className="ico-reflect"/>
          </div>
        </div>

        <div className="row" style={{ marginRight:1, marginLeft:1 }}>
          {
            questionOne.length > 0 &&
              <ReflectionQuestionContainer
                unit={unit}
                response={responseOne}
                question={questionOne}
                value={valueOne}
                name={nameOne}
                onShareIndividualReflection={onShareIndividualReflection}/>
          }
          {
            questionTwo.length > 0 &&
              <ReflectionQuestionContainer
                unit={unit}
                response={responseTwo}
                question={questionTwo}
                value={valueTwo}
                name={nameTwo}
                onShareIndividualReflection={onShareIndividualReflection}/>
          }
        </div>
      </section>
    );
  } else {
    return null;
  }
};

ReflectionQuestion.propTypes = {
  isAdmin: PropTypes.bool,
  nameOne: PropTypes.string,
  nameTwo: PropTypes.string,
  questionOne: PropTypes.string,
  questionTwo: PropTypes.string,
  valueOne: PropTypes.string,
  valueTwo: PropTypes.string,
  unit: PropTypes.object,
  responseOne: PropTypes.object,
  responseTwo: PropTypes.object,
  onShareIndividualReflection: PropTypes.func,
  toggleReflectionGuidanceModal: PropTypes.func.isRequired
};

export default ReflectionQuestion;
