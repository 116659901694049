import PropTypes from 'prop-types';
import React, { Component } from 'react';
import InterestCardContainer from '../../../components/profile/sections/InterestCardContainer';

class InterestCardHandler extends Component {
  static propTypes = {
    interest: PropTypes.object.isRequired,
    isPublicProfile: PropTypes.bool.isRequired,
    last: PropTypes.bool.isRequired,
    onEdit: PropTypes.func,
    reArrangeInterests: PropTypes.func,
    index: PropTypes.number,
  }

  constructor(props, context) {
    super(props, context);
    this.onEditInterestClick = this.onEditInterestClick.bind(this);
  }

  onEditInterestClick(event) {
    event.preventDefault();
    const { id } = this.props.interest;

    this.props.onEdit(id);
  }

  render() {
    const { interest, isPublicProfile, last, reArrangeInterests, index } = this.props;

    return (
      <div className="float-left card-item">
        <InterestCardContainer
          interest={interest}
          index={index}
          reArrangeInterests={reArrangeInterests}
          isPublicProfile={isPublicProfile}
          onEditInterestClick={this.onEditInterestClick}
          last={last}/>
      </div>
    );
  }
}

export default InterestCardHandler;
