import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import update from 'immutability-helper';
import { toastr } from 'react-redux-toastr';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import Validator from '../../../validator';

import * as interestActions from '../../../redux/actions/interests';
import * as profileActions from '../../../redux/actions/profile';
import * as componentsActions from '../../../redux/actions/components';

import Loading from '../../../components/common/Loading';
import ConfirmModal from '../../../components/portfolio/ConfirmModal';
import InterestForm from '../../../components/profile/forms/InterestForm';
import InterestList from '../../../components/profile/sections/InterestList';
import ProfileCardHeader from '../../../components/profile/header/ProfileCardHeader';

class Interests extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    interests: PropTypes.array.isRequired,
    interest: PropTypes.object.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isPublicProfile: PropTypes.bool.isRequired,
    showVisibilitySelect: PropTypes.bool,
    onChangeVisibility: PropTypes.func,
    isEditingSections: PropTypes.bool,
    onToggleSectionEdit: PropTypes.func,
    onConfirmDeleteSection: PropTypes.func,
    modal: PropTypes.string,
    profile: PropTypes.object,
    isDeleting: PropTypes.bool,
    isUpdating: PropTypes.bool,
    interestsOrder: PropTypes.string
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      isEditing: false,
      isSubmitting: false,
      isConfirmingDeleteSection: false,
      editingId: null,
      interestsOrder: props.profile.interestsOrder.split(',').filter(id => !_isEmpty(id)).map(id => ({ id })),
      interest: Object.assign({}, this.props.interest)
    };

    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onDeleteSection = this.onDeleteSection.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.onEditInterest = this.onEditInterest.bind(this);
    this.onDeleteInterest = this.onDeleteInterest.bind(this);
  }

  componentDidMount() {
    const { actions, profile } = this.props;

    actions.interestsRequest(profile.id);
  }

  componentWillReceiveProps(nextProps) {
    const { profile: { interestsOrder }} = this.props;
    const { profile: { interestsOrder: nextinterestsOrder }} = nextProps;

    const currentinterestsOrder = interestsOrder.split(',').filter(id => !_isEmpty(id)).map(id => ({ id }));
    const latestinterestsOrder = (nextinterestsOrder || '').split(',').filter(id => !_isEmpty(id)).map(id => ({ id }));
    const interestsOrderIsUpdatedSuccessfully = !_isEqual(currentinterestsOrder, latestinterestsOrder);

    if (interestsOrderIsUpdatedSuccessfully){
      this.setState({
        interestsOrder: latestinterestsOrder,
      }, () => this.handleResponse());
    }

    if(nextProps.interests.length > this.props.interests.length && this.state.isSubmitting){
      toastr.success('Interest(s) saved');
    }

    if(this.props.isDeleting && !nextProps.isDeleting && nextProps.interests.length < this.props.interests.length){
      toastr.success('Interest(s) Deleted');
    }
  }

  setInitialOrders(interestsOrder){
    if(interestsOrder)
      return interestsOrder === '' ? [] :
        interestsOrder.split(',').filter(id => id !== '');

    return [];
  }

  isValid(field = null) {
    const rules = {
      name: ['required', 'minLength|2', 'maxLength|80']
    };

    const { interest, errors: prevErrors} = this.state;
    const validate = Validator.createValidator(rules, interest, field);
    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  onChange(event) {
    const { name, value } = event.target;
    const { interest } = this.state;

    interest[name] = value;

    this.setState({ interest }, () => this.isValid(name));
  }

  onSave(event) {
    event.preventDefault();

    this.setState({ isSubmitting: true });

    if (! this.isValid()) {
      return this.setState({ isSubmitting: false });
    }

    const { interest, editingId, interestsOrder } = this.state;
    const { actions, interests, profile } = this.props;

    if(editingId !== null){
      const index = interests.findIndex(element =>
        element.id === interest.id);

      return actions.updateInterest(index, interest)
        .then(() => this.handleResponse());
    }
    const numberOfInterestsAdded = interest.name.split(',').filter(int => int !== '').length;

    if(interestsOrder.length + numberOfInterestsAdded > 20){
      return this.setState({ isSubmitting: false }, ()=> toastr.warning('You can list 20 interests at maximum.'));
    }

    this.props.actions.saveInterest(interest)
      .then(() => actions.profileRequest(profile.id))
      .then(() => this.handleResponse());
  }

  onCancel() {
    this.setState({
      isEditing: false,
      errors: {},
      editingId: null,
      interest: this.props.interest
    }, () => this.props.onToggleSectionEdit('interests'));
  }

  onEditClick(event) {
    event.preventDefault();

    this.setState({ isEditing: true },
      () => this.props.onToggleSectionEdit('interests'));
  }

  onDeleteSection(){
    this.props.actions.openModal('delete-webpage-interests-section-modal');
  }

  onCloseModal(){
    this.props.actions.closeModal();
  }

  onDeleteConfirm(){
    const { interests, actions, onConfirmDeleteSection, profile } = this.props;
    const data = {
      method: 'delete',
      items: interests.map(interest => interest.id),
    };

    this.setState({
      isConfirmingDeleteSection: true,
    });

    actions.deleteInterestsSection(data)
      .then(() => {
        onConfirmDeleteSection('Interests')
          .then(() => {
            actions.profileRequest(profile.id)
              .then(() => {
                this.setState({
                  isConfirmingDeleteSection: true,
                });
                actions.closeModal();
              });
          });
      });
  }

  handleResponse() {
    const interest = { name: '' };

    this.setState({
      isEditing: false,
      isSubmitting: false,
      editingId: null,
      interest
    }, () => this.props.onToggleSectionEdit('interests'));
  }

  onEditInterest(id){
    const { interests } = this.props;
    const interestObject = interests.find(interest => interest.id === id);
    const interest = Object.assign({}, this.state.interest, interestObject);
    this.setState({
      isEditing: true,
      interest,
      editingId: id
    }, () => this.props.onToggleSectionEdit('interest'));
  }

  onDeleteInterest(event) {
    event.preventDefault();

    const { actions, profile } = this.props;
    const { interest: interestToBeDeleted, interestsOrder } = this.state;
    const updatedInterestsOrderObj = {
      interestsOrder: interestsOrder
        .slice(0)
        .filter(interest => interest !== interestToBeDeleted.id)
        .map(interest => interest.id)
        .join(),
    };

    actions.deleteInterest(interestToBeDeleted.id)
      .then(() => {
        actions.updateInterestsOrder(updatedInterestsOrderObj, profile.id)
          .then(() => {
            this.handleResponse();
          });
      });
  }

  reArrangeInterests = (dragIndex, hoverIndex) => {
    const { interestsOrder } = this.state;
    const { profile, actions } = this.props;
    const dragItem = interestsOrder[dragIndex];

    this.setState(update(this.state, {
      interestsOrder: {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragItem]
        ]
      }
    }),
    () => {
      actions.updateProfile(profile.id, {
        interestsOrder: this.state.interestsOrder
          .slice(0)
          .map(skill => skill.id)
          .join()
      });
    });
  }

  render() {
    const {
      modal,
      interests,
      isRequesting,
      isPublicProfile,
      showVisibilitySelect,
      onChangeVisibility,
      isEditingSections,
      isUpdating,
      isDeleting
    } = this.props;
    const { errors, interest, isEditing, isSubmitting, editingId, interestsOrder } = this.state;
    if(interests.length === 0 && isPublicProfile){
      return null;
    }

    const isDeleteConfirmModalOpen = modal && modal === 'delete-webpage-interests-section-modal';

    return (
      <div className="profile-item interests">
        <ProfileCardHeader
          isEditing={isEditing}
          isEditingSections={isEditingSections}
          type="list"
          title="Interests"
          onEditClick={this.onEditClick}
          isPublicProfile={isPublicProfile}
          showVisibilitySelect={showVisibilitySelect}
          onDeleteClick={this.onDeleteSection}
          onChangeVisibility={onChangeVisibility}
          icon="interests"
          isDeletable/>

        {isRequesting ?
          <Loading/> :
          <div>
            {isEditing ?
              <InterestForm
                errors={errors}
                interest={interest}
                isSubmitting={isSubmitting}
                isUpdating={isUpdating}
                isDeleting={isDeleting}
                showDelete={editingId !== null}
                onChange={this.onChange}
                onSave={this.onSave}
                onCancel={this.onCancel}
                onDelete={this.onDeleteInterest}/> :
              <InterestList
                interestsOrder={interestsOrder}
                reArrangeInterests={this.reArrangeInterests}
                interests={interests}
                onEdit={this.onEditInterest}
                isPublicProfile={isPublicProfile}/>
            }
          </div>
        }
        {
          isDeleteConfirmModalOpen &&
            <ConfirmModal
              modalHeaderText="Delete Section"
              modalMessage="Are you sure you want to permanently delete this Career Site section?"
              modalFooterButtonText="Yes, Delete"
              onCancel={this.onCloseModal}
              onConfirm={this.onDeleteConfirm}
              isConfirming={this.state.isConfirmingDeleteSection}
              confirminginProgressText="Deleting..."
              isOpen={isDeleteConfirmModalOpen}/>
        }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const interest = { name: ''};

  return {
    interest,
    modal: state.components.modal,
    interests: state.interests.data,
    interestsOrder: ownProps.profile.interestsOrder,
    isRequesting: state.interests.isRequesting,
    isSubmitting: state.interests.isSubmitting,
    isDeleting: state.interests.isDeleting.status,
    isUpdating: state.interests.isUpdating.status
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, interestActions, profileActions, componentsActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Interests);
