import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Bar } from 'react-chartjs-2';
import Loading from '../common/Loading';

import { getReviewsBreakup } from '../../redux/actions/myskills';
import CloseIcon from '../../assets/images/close.svg';

const options = {
  y: {
    min: 0,
    max: 5,
  }
};

class ReviewsBar extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleTriggerEvent = this.handleTriggerEvent.bind(this);
  }

  componentDidMount() {
    this.props.actions.getReviewsBreakup(this.props.resourceId);
  }

  handleTriggerEvent() {
    this.props.toggleModal('reviewsBar');
  }

  render() {
    return (
      <Modal
        centered
        className="customLevelUpModal"
        isOpen={this.props.isOpen}>
        <div className="modal-header" style={{justifyContent: 'end'}}>
          <span className="modal-title">
            <div
              onClick={this.handleTriggerEvent}
              className="close"
              data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">
                <img src={CloseIcon} />
              </span>
            </div>
          </span>
        </div>
        <ModalBody>
          {this.props.isRequesting ?
            <Loading /> :
            <Bar data={this.props.data} options={options}/>}
        </ModalBody>
        <ModalFooter className="bordertop-0" />
      </Modal>
    );
  }
}

ReviewsBar.propTypes = {
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  resourceId: PropTypes.string.isRequired,
  isRequesting: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isRequesting: state.myskills.isRequesting,
  data: state.myskills.data.reviewsBreakupData
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getReviewsBreakup }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsBar);
