import { CALL_API } from '../../middlewares/api';
import {
  AWARDS_REQUEST,
  AWARDS_SUCCESS,
  AWARDS_FAILURE,
  CREATE_AWARD_REQUEST,
  CREATE_AWARD_SUCCESS,
  CREATE_AWARD_FAILURE,
  UPDATE_AWARD_REQUEST,
  UPDATE_AWARD_SUCCESS,
  UPDATE_AWARD_FAILURE,
  DELETE_AWARD_REQUEST,
  DELETE_AWARD_SUCCESS,
  DELETE_AWARD_FAILURE,
} from '../../constants';

export const awardsRequest = (userId) => {
  const endpoint = `user/awards?user=${userId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [AWARDS_REQUEST, AWARDS_SUCCESS, AWARDS_FAILURE],
    },
  };
};

export const saveAward = (data) => {
  const endpoint = 'user/awards';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [CREATE_AWARD_REQUEST, CREATE_AWARD_SUCCESS, CREATE_AWARD_FAILURE],
    },
  };
};

export const updateAward = (index, data) => {
  const endpoint = `user/awards/${data.id}`;

  return {
    [CALL_API]: {
      data,
      index,
      endpoint,
      method: 'put',
      authenticated: true,
      types: [UPDATE_AWARD_REQUEST, UPDATE_AWARD_SUCCESS, UPDATE_AWARD_FAILURE],
    },
  };
};

export const deleteAward = (awardId) => {
  const endpoint = `user/awards/${awardId}`;

  return {
    [CALL_API]: {
      endpoint,
      id: awardId,
      method: 'delete',
      authenticated: true,
      types: [DELETE_AWARD_REQUEST, DELETE_AWARD_SUCCESS, DELETE_AWARD_FAILURE],
    },
  };
};
