import concat from 'lodash/concat';

import initialState from './initialState';

import {
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_SETTINGS_REQUEST,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAILURE,
  UPDATE_IR_SETTINGS_REQUEST,
  UPDATE_IR_SETTINGS_SUCCESS,
  UPDATE_IR_SETTINGS_FAILURE,
  GET_IR_SETTINGS_REQUEST,
  GET_IR_SETTINGS_SUCCESS,
  GET_IR_SETTINGS_FAILURE,
  UPDATE_SKILLS_ORDER_REQUEST,
  UPDATE_SKILLS_ORDER_SUCCESS,
  UPDATE_SKILLS_ORDER_FAILURE,
  UPDATE_INTERESTS_ORDER_REQUEST,
  UPDATE_INTERESTS_ORDER_SUCCESS,
  UPDATE_INTERESTS_ORDER_FAILURE,
  DECREMENT_UNREAD_MESSAGES_COUNT,
  CREATE_MESSAGE_SUCCESS,
  CLEAR_USER_REDUCERS,
  CREATE_ADMIN_GROUP_SUCCESS,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ADMIN_GROUP_REQUEST,
  UPDATE_ADMIN_GROUP_SUCCESS,
  // This is to update interestOrder array after we add new interest(s)
  CREATE_INTEREST_SUCCESS,
  DELETE_INTEREST_REQUEST,
  DELETE_INTEREST_SUCCESS,
  UPDATE_ADMIN_INSTITUTION_GROUPS,
  INCREMENT_FOLLOWING_COUNT,
  DECREMENT_FOLLOWING_COUNT,
  CREATE_CHILD_GROUPS_REQUEST,
  CREATE_CHILD_GROUPS_SUCCESS,
  CREATE_CHILD_GROUPS_FAILURE,
  SET_UNSAVED_CHILD_GROUPS_DATA,
  SET_IS_CHILD_GROUP,
  GET_COMPANY_LIST_REQUEST,
  GET_COMPANY_LIST_SUCCESS,
  GET_COMPANY_LIST_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.profile, action) {
  switch (action.type) {
  case SET_UNSAVED_CHILD_GROUPS_DATA:
    return Object.assign({}, state, {
      unsavedChildGroupsData: {
        ...state.unsavedChildGroupsData,
        ...action.data,
      },
    });

  case CREATE_CHILD_GROUPS_REQUEST:
    return Object.assign({}, state, {
      unsavedChildGroupsData: {
        ...state.unsavedChildGroupsData,
        isSubmitting: true,
      },
    });

  case CREATE_CHILD_GROUPS_SUCCESS: {
    return Object.assign({}, state, {
      data: Object.assign({}, state.data, {
        adminInstitutions: [
          ...state.data.adminInstitutions.map((institution) => {
            const {
              institutionId,
              parentGroupIndex,
            } = state.unsavedChildGroupsData;

            if (institution.id === institutionId) {
              const group = institution.groups[parentGroupIndex];

              const parentGroup = Object.assign({}, group, {
                children: [...action.data.groups, ...group.children],
              });

              return Object.assign({}, institution, {
                groups: [
                  ...institution.groups.slice(0, parentGroupIndex),
                  Object.assign({}, parentGroup),
                  ...institution.groups.slice(parentGroupIndex + 1),
                ],
              });
            }

            return institution;
          }),
        ],
      }),

      unsavedChildGroupsData: {
        isSubmitting: false,
        institutionId: null,
        parentGroupId: null,
        parentGroupIndex: null,
      },
    });
  }

  case CREATE_CHILD_GROUPS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      unsavedChildGroupsData: {
        isSubmitting: false,
        institutionId: null,
        parentGroupId: null,
        parentGroupIndex: null,
      },
    });

  case CLEAR_USER_REDUCERS:
    return initialState.profile;

  case UPDATE_SKILLS_ORDER_REQUEST:
    return Object.assign({}, state, { isUpdating: true });

  case UPDATE_SKILLS_ORDER_SUCCESS:
    return Object.assign({}, state, {
      data: Object.assign({}, state.data, {
        skillsOrder: action.data.skillsOrder,
      }),
      isUpdating: false,
      error: {},
    });

  case UPDATE_SKILLS_ORDER_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: false,
    });

  case UPDATE_INTERESTS_ORDER_REQUEST:
    return Object.assign({}, state, { isUpdating: true });

  case UPDATE_INTERESTS_ORDER_SUCCESS:
    return Object.assign({}, state, {
      data: Object.assign({}, state.data, {
        interestsOrder: action.data.interestsOrder,
      }),
      isUpdating: false,
      error: {},
    });

  case UPDATE_INTERESTS_ORDER_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: false,
    });

  case PROFILE_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case PROFILE_SUCCESS:
    return Object.assign({}, state, {
      data: action.data,
      isRequesting: false,
    });

  case PROFILE_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case UPDATE_PROFILE_REQUEST:
    return Object.assign({}, state, { isUpdating: true });

  case UPDATE_PROFILE_SUCCESS:
    return Object.assign({}, state, {
      data: action.data,
      isUpdating: false,
    });

  case UPDATE_PROFILE_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: false,
    });

  case UPDATE_SETTINGS_REQUEST:
    return Object.assign({}, state, { isUpdating: true });

  case UPDATE_SETTINGS_SUCCESS:
    return Object.assign({}, state, {
      data: Object.assign({}, state.data, action.data),
      error: {},
      isUpdating: false,
    });

  case UPDATE_SETTINGS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: false,
    });

  case UPDATE_IR_SETTINGS_REQUEST:
    return Object.assign({}, state, { isUpdating: true });

  case UPDATE_IR_SETTINGS_SUCCESS:
    return Object.assign({}, state, {
      // data: Object.assign({}, state.data, action.data),
      error: {},
      isUpdating: false,
      updatedSettings: true,
    });

  case UPDATE_IR_SETTINGS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: false,
    });

  case GET_IR_SETTINGS_REQUEST:
    return Object.assign({}, state, { isUpdating: true });

  case GET_IR_SETTINGS_SUCCESS:
    return Object.assign({}, state, {
      data: {
        ...state.data,
        industryRepresentativeConfiguration: action.data?.detail,
      },
      industryRepresentativeConfiguration: action.data?.detail,
      error: {},
      isUpdating: false,
      updatedSettings: false
    });

  case GET_IR_SETTINGS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: false,
    });

  case DECREMENT_UNREAD_MESSAGES_COUNT:
    return Object.assign({}, state, {
      data: Object.assign({}, state.data, {
        unreadMessagesCount: state.data.unreadMessagesCount - 1,
      }),
    });

  case INCREMENT_FOLLOWING_COUNT:
    return Object.assign({}, state, {
      data: Object.assign({}, state.data, {
        followingCount: state.data.followingCount + 1,
      }),
    });

  case DECREMENT_FOLLOWING_COUNT:
    return Object.assign({}, state, {
      data: Object.assign({}, state.data, {
        followingCount: state.data.followingCount - 1,
      }),
    });

  case CREATE_MESSAGE_SUCCESS:
    return Object.assign({}, state, {
      data: Object.assign({}, state.data, {
        unreadMessagesCount: state.data.unreadMessagesCount + 1,
      }),
    });

  case CREATE_ADMIN_GROUP_SUCCESS: {
    const adminInstitutionsToUpdate = state.data.adminInstitutions[0];
    return Object.assign({}, state, {
      data: Object.assign({}, state.data, {
        adminInstitutions: [
          {
            ...adminInstitutionsToUpdate,
            groups: [
              ...adminInstitutionsToUpdate.groups,
              ...action.data.groups.map((group) => {
                return {
                  ...group,
                  label: group.name,
                  value: group.name,
                };
              }),
            ],
          },
        ],
      }),
    });
  }

  case SET_IS_CHILD_GROUP:
    return Object.assign({}, state, {
      isUpdatingGroup: {
        ...state.isUpdatingGroup,
        isChildGroup: action.isChildGroup,
      },
    });

  case UPDATE_ADMIN_GROUP_REQUEST: {
    return Object.assign({}, state, {
      isUpdatingGroup: {
        ...state.isUpdatingGroup,
        status: true,
        groupId: action.id,
      },
    });
  }

  case UPDATE_ADMIN_GROUP_SUCCESS: {
    const { groupId, isChildGroup } = state.isUpdatingGroup;

    if (isChildGroup) {
      return state;
    }

    const adminInstitutions = state.data.adminInstitutions;
    const indexOfInstitution = state.data.adminInstitutions.findIndex(
      item =>
        item.groups.reduce((acc, next) => next.id === groupId || acc, false)
    );

    const groupsBelongingToTheInstitution =
        adminInstitutions[indexOfInstitution].groups;
    const indexOfGroup = groupsBelongingToTheInstitution.findIndex(
      gp => gp.id === groupId
    );
    const groupToUpdate = Object.assign(
      {},
      groupsBelongingToTheInstitution[indexOfGroup],
      {
        name: action.data.name,
        label: action.data.name,
        value: action.data.id,
      }
    );

    return Object.assign({}, state, {
      data: Object.assign({}, state.data, {
        adminInstitutions: [
          ...adminInstitutions.slice(0, indexOfInstitution),
          Object.assign({}, adminInstitutions[indexOfInstitution], {
            groups: [
              ...groupsBelongingToTheInstitution.slice(0, indexOfGroup),
              groupToUpdate,
              ...groupsBelongingToTheInstitution.slice(indexOfGroup + 1),
            ],
          }),
          ...adminInstitutions.slice(indexOfInstitution + 1),
        ],
      }),
    });
  }

  case UPDATE_ADMIN_INSTITUTION_GROUPS:
    return Object.assign({}, state, {
      data: Object.assign({}, state.data, {
        adminInstitutions: [
          ...state.data.adminInstitutions.slice(0, action.institutionIndex),
          Object.assign(
            {},
            state.data.adminInstitutions[action.institutionIndex],
            {
              groups: [
                ...state.data.adminInstitutions[
                  action.institutionIndex
                ].groups.slice(0, action.groupIndex),
                action.group,
                ...state.data.adminInstitutions[
                  action.institutionIndex
                ].groups.slice(action.groupIndex + 1),
              ],
            }
          ),
          ...state.data.adminInstitutions.slice(action.institutionIndex + 1),
        ],
      }),
    });

  case UPDATE_ORGANIZATION_SUCCESS: {
    const adminInstitutionsToUpdate = state.data.adminInstitutions[0];
    return Object.assign({}, state, {
      data: Object.assign({}, state.data, {
        adminInstitutions: [
          {
            ...adminInstitutionsToUpdate,
            institutionType: action.data.institutionType,
            groundLocations: Number(action.data.groundLocations),
            onlinePrograms: Number(action.data.onlinePrograms),
          },
        ],
      }),
    });
  }

  case CREATE_INTEREST_SUCCESS: {
    const newlyAddedInterestIds = action.data.interests.map(
      weNeedId => weNeedId.id
    );
    const newInterestOrdersArray = concat(
      state.data.interestsOrder.split(','),
      newlyAddedInterestIds
    ).toString();

    return Object.assign({}, state, {
      data: Object.assign({}, state.data, {
        interestsOrder: newInterestOrdersArray,
      }),
    });
  }

  case DELETE_INTEREST_REQUEST:
    return Object.assign({}, state, {
      deletingInterestId: action.id,
    });

  case DELETE_INTEREST_SUCCESS: {
    const updatedInterestOrdersArray = state.data.interestsOrder
      .split(',')
      .filter(order => order !== state.deletingInterestId)
      .toString();

    return Object.assign({}, state, {
      data: Object.assign({}, state.data, {
        interestsOrder: updatedInterestOrdersArray,
      }),
      deletingInterestId: null,
    });
  }

  case GET_COMPANY_LIST_REQUEST:
    return Object.assign({}, state, { isUpdating: true });

  case GET_COMPANY_LIST_SUCCESS: {
    const companies = action.data.detail.map((input) => {
      return { label: input, value: input };
    });
    return Object.assign({}, state, {
      companies,
      error: {},
      isUpdating: false,
    });
  }

  case GET_COMPANY_LIST_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: false,
    });

  default:
    return state;
  }
}
