import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  CANCEL,
  REMOVE,
  RESEND,
} from '../../../constants/feedback';
import { useSelector } from 'react-redux';

const RequestedActionComponent = (props) => {
  const rowDetails = props.rowData;
  const currentUser = useSelector(({ profile }) => profile.data);

  function resendFeedbackRequest() {
    props.setCurrentRequest(rowDetails.feedbackRequestId);
    props.toggleIgnore(RESEND);
  }

  function setCurrentRequestCancel() {
    props.setCurrentRequest(rowDetails.feedbackRequestId);
    props.toggleIgnore('Cancel');
  }

  function setCurrentRequestRemove() {
    props.setCurrentRequest(rowDetails.feedbackRequestId);
    props.toggleIgnore(REMOVE);
  }

  const RemoveButton = () => (<button
    type="button"
    className="btn btn-secondary w-100"
    onClick={setCurrentRequestRemove}>
    {REMOVE}
  </button>);

  const componentBody = () => (
    <>
      <td>
        <div className="d-flex justify-content-end">
          {rowDetails.feedbackstatus !== 'completed' &&
            rowDetails.feedbackstatus !== 'canceled' &&
            rowDetails.feedbackstatus !== 'matching' &&
            rowDetails.feedbackstatus !== 'removed' &&
            rowDetails.fullname && (
            <Button
              className="sendButton mr-3 respondButton"
              onClick={resendFeedbackRequest}>
              {RESEND}
            </Button>
          )}
          {rowDetails.feedbackstatus !== 'completed' &&
            rowDetails.feedbackstatus !== 'canceled' && (
            <button
              onClick={setCurrentRequestCancel}
              type="button"
              className="btn btn-secondary w-100">
              {CANCEL}
            </button>
          )}
          {rowDetails.feedbackstatus !== 'completed' &&
            rowDetails.feedbackstatus === 'canceled' && <RemoveButton />}
        </div>
      </td>
    </>
  );

  // #2489 If Requester has raised request for other student, he should be able to see Action buttons
  if (
    rowDetails.requesterId === currentUser.id &&
    rowDetails.requesterId !== rowDetails.requestForUserId
  ) {
    return componentBody();
  }

  // Requester is Admin
  if (rowDetails.requestForUserId !== rowDetails.requesterId) {
    if(rowDetails.feedbackstatus === 'canceled'){
      return (
        <>
          <td>
            <div className="d-flex justify-content-end">
              <RemoveButton />
            </div>
          </td>
        </>
      );
    }else {
      return (
        <td />
      );
    }
  }

  return componentBody();
};

RequestedActionComponent.propTypes = {
  rowData: PropTypes.object.isRequired,
};

export default RequestedActionComponent;