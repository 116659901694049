import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as filesActions from '../../redux/actions/files';
import * as componentsActions from '../../redux/actions/components';

import { Link } from 'react-router-dom';
import { loadForm } from '../../utils';
import FileCommentActions from './FileCommentActions';
import moment from 'moment';
import classNames from 'classnames';
import { toastr } from 'react-redux-toastr';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

class UnitFileReplyHandler extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    reply: PropTypes.object.isRequired,
    replyData: PropTypes.object.isRequired,
    isUpdating: PropTypes.object.isRequired,
    profile: PropTypes.object,
    replyToPost: PropTypes.func,
    isLiking: PropTypes.object,
    isDeleting: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    loadForms: PropTypes.array.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      dropdownOpen: false,
      showDeleteConfirmModal: false,
      replyData: Object.assign({}, this.props.replyData)
    };

    this.toggle = this.toggle.bind(this);
    this.onLike = this.onLike.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.closeConfrimDeleteModal = this.closeConfrimDeleteModal.bind(this);
  }

  componentWillReceiveProps(nextProps){
    const { isDeleting } = nextProps;

    if(isDeleting !== this.props.isDeleting && !isDeleting.status){
      this.closeConfrimDeleteModal();
    }
  }

  onChange(e){
    const { name, value } = e.target;
    const { replyData } = this.state;

    replyData[name] = value;

    this.setState({ replyData });
  }

  toggle() {
    this.setState({ dropdownOpen: ! this.state.dropdownOpen });
  }

  onEdit() {
    const { replyData } = this.state;
    const { actions, reply } = this.props;
    const form = `edit-file-comment-form-${reply.id}`;


    const newreplyData = Object.assign({}, replyData, {
      'comment': reply.comment
    });

    this.setState({
      replyData: newreplyData
    }, () => actions.loadForm(form));
  }

  onLike(e){
    e.preventDefault();

    const { actions, reply, isAuthenticated } = this.props;
    const { liked, id } = reply;

    if(!isAuthenticated){
      return toastr.error('Please Login/Signup');
    }

    if(liked){
      return actions.unlikeFileComment(id, 'reply');
    }

    const data = {
      'comment': id
    };

    actions.likeFileComment(data, 'reply');
  }

  onDelete() {
    const { actions, reply } = this.props;
    actions.deleteFileComment(reply.id, reply.parent);
  }

  onDeleteClick(){
    this.setState({
      showDeleteConfirmModal: true
    });
  }

  closeConfrimDeleteModal(){
    this.setState({
      showDeleteConfirmModal: false
    });
  }

  onCancel(e){
    e.preventDefault();

    const { actions, reply } = this.props;
    const form = `edit-file-comment-form-${reply.id}`;

    this.setState({
      replyData: {
        'comment': ''
      }
    }, () => actions.unloadFormRequest(form));
  }

  onUpdate(e){
    e.preventDefault();

    const { replyData } = this.state;
    const { actions, reply } = this.props;

    const data = {
      'comment': replyData.comment
    };

    actions.updateFileComment(data, reply.id, 'reply').
      then(() => this.setState({
        replyData: {
          'comment': ''
        }
      }, () => actions.unloadFormRequest(`edit-file-comment-form-${reply.id}`)));
  }

  render() {
    const { replyData, showDeleteConfirmModal } = this.state;
    const { reply, loadForms, isUpdating, profile, isLiking, isDeleting, replyToPost } = this.props;
    const { user, comment: commentText, likes, liked, createdAt } = reply;
    const isOwner = profile.id === user.id;
    const isLoadForm = loadForm(loadForms, `edit-file-comment-form-${reply.id}`);
    const dateString = moment.utc(createdAt.date).fromNow();

    let likeBtnText = liked ? 'Unlike': 'Like';
    const isLikingFile = isLiking.status && isLiking.replyId === reply.id;

    if(isLikingFile){
      likeBtnText = `${likeBtnText.slice(0, -1)}ing...`;
    }

    if(isLoadForm){
      return(
        <div className="tertiary-post">
          <div className="comment-form-container">
            <div className="left">
              <span>
                {
                  user.avatar && user.avatar !== ''?
                    <img
                      className="header-image medium align-top"
                      src={user.avatar}
                      alt={`${user.name}'s Avatar`}/>:
                    <span
                      className="header-image medium align-top initial-avatar">
                      {user.name.slice(0,1)}
                    </span>
                }
              </span>
            </div>
            <div className="comment-post right">
              <div id="comment-container">
                <textarea
                  name="comment"
                  className="form-control"
                  value={replyData.comment}
                  onChange={this.onChange}
                  placeholder="What are your thoughts on this portfolio file?"
                  rows="3"
                  cols="60"/>

                <button
                  onClick={this.onCancel}
                  className="btn btn-secondary boder-1 float-left mt-1 mb-1">
                  Cancel
                </button>
                <button
                  onClick={this.onUpdate}
                  className="btn btn-primary float-right mt-1 mb-1">
                  {isUpdating.status ? 'Updating...' :'Update'}
                </button>
              </div>
            </div>
          </div>
          <div className="clearfix"/>
        </div>
      );
    }

    return (
      <div className="tertiary-post">
        <div className="left">
          <span>
            {
              user.avatar && user.avatar !== ''?
                <img
                  className="header-image medium align-top"
                  src={user.avatar}
                  alt={`${user.name}'s Avatar`}/>:
                <span
                  className="header-image medium align-top initial-avatar">
                  {user.name.slice(0,1)}
                </span>
            }
          </span>
        </div>
        <div className="right">
          {
            isOwner &&
              <div className="btn-group edit-menu align-top float-right">
                <FileCommentActions
                  onDeleteClick={this.onDeleteClick}
                  onEditClick={this.onEdit}
                  comment={reply}/>
              </div>
          }
          <div className="profile-title">
            <div className="profile-name">
              <span>
                <Link to={`/cp/${user.vanity}`}>{user.name}</Link>
              </span> {commentText}
            </div>
            <div className="share-stats file-share">
              <a
                onClick={this.onLike}
                className={classNames('clickable', {
                  'orange': !liked,
                  'blue': liked
                })}>
                <i className="fa fa-heart" />
                <span>
                  {likeBtnText}
                </span>
              </a>
              <span>
                <a onClick={replyToPost} className="clickable orange">
                  <i className="fa fa-comment"/>Reply
                </a>
              </span>
              <span>{likes} Likes</span>
              <span>{dateString}</span>
            </div>
          </div>
        </div>
        {
          showDeleteConfirmModal &&
            <Modal
              backdrop="static"
              className="modal-margin-top"
              isOpen={showDeleteConfirmModal}
              toggle={this.closeConfrimDeleteModal}>
              <ModalHeader
                toggle={this.closeConfrimDeleteModal}>
                Delete Reply
              </ModalHeader>
              <ModalBody>
                Are you sure you want to permanently delete this reply? If you do, it cannot be undone.
              </ModalBody>
              <ModalFooter>
                <button
                  onClick={this.closeConfrimDeleteModal}
                  className="btn btn-secondary">
                  Cancel
                </button>

                <button
                  onClick={this.onDelete}
                  className="btn btn-danger"
                  disabled={isDeleting.status}>
                  {isDeleting.status ? 'Deleting...' : 'Permanently Delete'}
                </button>
              </ModalFooter>
            </Modal>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const replyData = {
    'comment': ''
  };
  return {
    replyData,
    isUpdating: state.files.unitFileComments.isUpdating,
    isDeleting: state.files.unitFileComments.isDeleting,
    loadForms: state.components.forms,
    isAuthenticated: state.auth.isAuthenticated
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, filesActions, componentsActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitFileReplyHandler);
