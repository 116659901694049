import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import { Helmet } from 'react-helmet';
import CheckoutForm from '../../components/checkout/CheckoutForm';
import Loading from '../../components/common/Loading';
import * as subscriptionActions from '../../redux/actions/subscriptions';

import classNames from 'classnames';
import Braintree from 'braintree-web/client';
import HostedFields from 'braintree-web/hosted-fields';

const authorization = 'sandbox_jq4kbf9h_xdh3wvhn2ts76mv3';
const styles = {
  'input': {
    'font-size': '14px',
    'font-family': 'helvetica, tahoma, calibri, sans-serif',
    'color': '#3a3a3a',
  }
};

const fields = {
  number: {
    selector: '#card-number',
    placeholder: '1111 1111 1111 1111'
  },
  cvv: {
    selector: '#cvv',
    placeholder: '123'
  },
  expirationDate: {
    selector: '#expiration-date',
    placeholder: '10 / 2019'
  },
  postalCode: {
    selector: '#postal-code',
    placeholder: '90210'
  }
};

class Checkout extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      cardType: '',
      cardNiceType: 'Card',
      initializationError: '',
      isInitializingBraintree: true,
      subscriptionPlan: '1234567788889900'
    };

    this.setAuthorization();

    this.onSubmit = this.onSubmit.bind(this);
  }

  setAuthorization() {
    if (! authorization) {
      this.teardown();
    }

    if (authorization) {
      Braintree.create({ authorization }, (error, clientInstance) => {
        if (error) {
          this.setState({ initializationError: error });
          return;
        }

        this.setState({ initializationError: '' });
        this.onAuthorizationSuccess();
        this.create(clientInstance);
      });
    }
  }

  create(client) {
    HostedFields.create({ client, styles, fields }, (error, hostedFields) => {
      if (error) {
        // TODO: handle errors here...
        this.setState({ initializationError: error });
        return;
      }

      this.hostedFields = hostedFields;

      this.setState({ initializationError: '' });

      this.hostedFields.on('empty', () => {
        this.setState({ cardType: '', cardNiceType: 'Card' });
      });

      this.hostedFields.on('cardTypeChange', (event) => {
        if (event.cards.length === 1) {
          const cardType = event.cards[0].type;
          const cardNiceType = event.cards[0].niceType;

          this.setState({ cardType, cardNiceType });
        }
      });
    });
  }

  onAuthorizationSuccess() {
    this.setState({ isInitializingBraintree: false });
  }

  teardown() {
    if (this.hostedFields) {
      this.hostedFields.teardown();
    }
  }

  onSubmit(event) {
    event.preventDefault();

    const { subscriptionPlan } = this.state;

    if (subscriptionPlan === '') {
      // TODO: handle errors here...
      toastr.error('Please select a subscription plan');
      return;
    }

    this.hostedFields.tokenize({}, (error, payload) => {
      if (error) {
        // TODO: handle errors here..
        toastr.error('All fields are required to make a payment.');
        return;
      }

      this.setState({ initializationError: '' });

      const data = {
        subscriptionPlan,
        paymentMethodNonce: payload.nonce
      };

      this.props.actions.buySubscriptionPlan(data);
    });
  }

  render() {
    const { cardType, cardNiceType, isInitializingBraintree, initializationError } = this.state;

    const formClassNames = classNames('col-md-6 offset-md-3', {
      'visa': (cardType === 'visa'),
      'master-card': (cardType === 'master-card'),
      'maestro': (cardType === 'maestro'),
      'american-express': (cardType === 'american-express'),
      'discover': (cardType === 'discover'),
      'unionpay': (cardType === 'unionpay')
    });

    const headerClassNames = classNames('payment-header', {
      'header-slide': (cardType.length !== 0)
    });

    return (
      <div className="interior info">
        <Helmet title="Checkout"/>
        <div className="container">
          <div className="content" style={{padding: 100}}>
            {isInitializingBraintree && <div className="loading-container"><Loading/></div>}

            {(! isInitializingBraintree && (initializationError.length === 0)) &&
              <CheckoutForm
                headerClassNames={headerClassNames}
                formClassNames={formClassNames}
                cardType={cardType}
                cardNiceType={cardNiceType}
                onSubmit={this.onSubmit}/>}

            {(! isInitializingBraintree && (initializationError.length !== 0)) &&
              <div>
                <h2 style={{textAlign: 'center'}}>Payment Page Error</h2>
                <p>Ooops! Seems like there is a hitch. Please refresh the page to try again or contact the support team.</p>
              </div>}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({},
    subscriptionActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(null, mapDispatchToProps)(Checkout);
