import React from 'react';

import PropTypes from 'prop-types';
import {
  Row,
  Col,
  PopoverBody,
  UncontrolledPopover,
} from 'reactstrap';
import classNames from 'classnames';

const UsersTableStatusCell = ({ user }) => {
  const cpStatusTitleAlias = {
    'skills': 'Add 10 Skills',
    'education': 'Add Education',
    'experience': 'Add Experience',
    'summary': 'Add About Me',
    'location': 'Add Location',
    'worksituation': 'Add Work Situation',
    'headline': 'Add Headline',
    'avatar': 'Add Photo',
    'contact': 'Add Contact Info'
  };

  return (
    <div>
      <span
        className="font14 font-weight-bold"
        style={{ textAlign: 'center' }} >
        <div className="text-center">
          <div id={`statusStage${user.id}`} className="pointer">
            <span className={classNames({
              'text-danger': user.userStatus.overallStatus < 100,
              'text-success': user.userStatus.overallStatus === 100
            })}>
              {`${user.userStatus.overallStatus}%`}
            </span>
          </div>
        </div>
      </span>

      <UncontrolledPopover
        placement="bottom"
        target={`statusStage${user.id}`}
        className="careerstatus-popover"
        trigger="legacy">
        <PopoverBody>
          <b>Actions Needed</b>
          {user.userStatus &&
            user.userStatus?.completedCards?.map((action) => {
              return (
                <Row key={action.id}>
                  <Col xs="12">
                    <s><span className="text-muted">{cpStatusTitleAlias[action.statusType]}</span></s>
                  </Col>
                </Row>
              );
            }
            )}

          {user.userStatus &&
            user.userStatus?.pendingCards?.map((action) => {
              return (
                <Row key={action.id}>
                  <Col xs="12">
                    <span>{cpStatusTitleAlias[action.statusType]}</span>
                  </Col>
                </Row>
              );
            }
            )}
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
};

UsersTableStatusCell.propTypes = {
  user: PropTypes.object,
  statusClicked: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default UsersTableStatusCell;
