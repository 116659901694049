import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as wallPostCommentActions from '../../redux/actions/wall-post-comments';
import * as wallPostActions from '../../redux/actions/wall-posts';
import * as searchActions from '../../redux/actions/search';

import CommentLikeButton from '../../components/common/CommentLikeButton';
import PostLikeButton from '../../components/common/PostLikeButton';

class WallLikeButton extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    comment: PropTypes.object,
    postIndex: PropTypes.number,
    wallPost: PropTypes.object,
    wallPostId: PropTypes.string,
    isSubmittingLike: PropTypes.object,
    actions: PropTypes.object.isRequired,
    wallPosts: PropTypes.array.isRequired,
    wallPostComments: PropTypes.object.isRequired,
    isLikingPostComment: PropTypes.object,
    searchPostItem: PropTypes.array.isRequired,
    searchPage: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { actions, wallPosts, wallPost, userId } = this.props;

    const wallPostIndex = wallPosts.findIndex(wallPostItem =>
      wallPostItem.id === wallPost.id);

    const wallLike = { wall: wallPost.id, wallPostIndex, userId };

    if(wallPost.liked){
      return actions.unlikeWallPost(wallLike);
    }

    actions.saveWallPostLike(wallLike);
  }

  onLikeComment(commentIndex) {
    return () => {
      const { userId, actions, comment, wallPostId, searchPage } = this.props;

      const commentLike = {
        userId,
        comment: comment.id,
        wallPostId,
        commentIndex
      };

      if(comment.liked){
        return searchPage ? actions.unlikeSearchResultWallPostComment(commentLike):
          actions.unlikeWallPostComment(commentLike);
      }

      return searchPage ? actions.likeSearchResultWallPostComment(commentLike):
        actions.saveWallPostCommentLike(commentLike);
    };
  }

  render() {
    const {
      comment,
      wallPost,
      wallPostId,
      isSubmittingLike,
      postIndex,
      wallPostComments,
      searchPage,
      searchPostItem,
      isLikingPostComment
    } = this.props;

    if (wallPostId) {
      const { status, wallPostCommentIndex } = wallPostComments.isSubmittingLike;
      const { status: statusInSearch, index: indexInSearch } = isLikingPostComment;

      const commentIndex = searchPage ?
        searchPostItem.find(wall =>
          wall.id === wallPostId).comments &&
            searchPostItem.find(wall => wall.id === wallPostId).
              comments.findIndex(curr => curr.id ===comment.id ) :
        wallPostComments.loadedComments[wallPostId] &&
          wallPostComments.loadedComments[wallPostId].findIndex(commentItem =>
            commentItem.id === comment.id);

      const isLikingComment = (status && wallPostCommentIndex.index === commentIndex) || (statusInSearch && indexInSearch === commentIndex);

      return(
        <CommentLikeButton
          comment={comment}
          isLikingComment={isLikingComment}
          onLike={this.onLikeComment(commentIndex)}/>
      );
    }

    const { status, wallPostIndex } = isSubmittingLike;
    const isLikingPost = status && wallPostIndex === postIndex;
    return (
      <PostLikeButton
        isLikingPost={isLikingPost}
        post={wallPost}
        onLike={this.onClick}/>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.auth.data.currentUser.id,
    searchPostItem : state.search.data.posts,
    wallPosts: state.wallPosts.data,
    wallPostComments: state.wallPostComments,
    isLikingPostComment: state.search.isLikingPostComment,
    isSubmittingLike: state.wallPosts.isSubmittingLike,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, wallPostActions, wallPostCommentActions, searchActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WallLikeButton);
