import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import classNames from 'classnames';
import TextInput from '../../common/TextInput';

const socialArray = [
  {
    id: 1,
    name: 'twitter',
    icon: 'fa fa-twitter-square'
  },
  {
    id: 2,
    name: 'linkedin',
    icon: 'fa fa-linkedin-square'
  },
  {
    id: 3,
    name: 'github',
    icon: 'fa fa-github-square'
  },
  {
    id: 4,
    name: 'facebook',
    icon: 'fa fa-facebook-square'
  },
  {
    id: 5,
    name: 'instagram',
    icon: 'fa fa-instagram'
  },
  {
    id: 6,
    name: 'youtube',
    icon: 'fa fa-youtube-square'
  },
  {
    id: 7,
    name: 'vimeo',
    icon: 'fa fa-vimeo-square'
  },
  {
    id: 8,
    name: 'other',
    icon: 'fa fa-globe'
  }
];

const SocialLinksModal = ({
  isOpen,
  toggleModal,
  selectSocialType,
  selectedType,
  link,
  onChangeLink,
  onSaveLinks,
  isSubmitting,
}) => {
  const selectedLinkName = selectedType.charAt(0).toUpperCase() + selectedType.substr(1);
  const errorText = link.error ? 'You must provide a valid URL. If you want to remove the link from your Career Site, click delete to remove it': '';
  const confirmDelete = (event) => {
    if (confirm('Do you want to DELETE THIS LINK?')) onSaveLinks(event);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} backdrop="static">
      <div className="modal-content">

        <ModalHeader toggle={toggleModal}>
          Add Links to Your Other Sites
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row social-icon-group">
              {socialArray.map(social =>
                (<a
                  key={social.id}
                  onClick={selectSocialType(social.name)}
                  className={classNames('social-add-icon col clickable',{
                    'selected': social.name === selectedType
                  })}>
                  <i className={social.icon}/>
                </a>))}
            </div>
          </div>

          <div className="form-group">
            <TextInput
              type="text"
              name="link"
              value={link.url}
              onChange={onChangeLink}
              className="form-control"
              error={errorText}
              placeholder={`Enter ${selectedLinkName} URL`}/>
          </div>
        </ModalBody>
        <ModalFooter>
          {/* eslint-disable react/jsx-no-bind */}
          <button
            type="button"
            name="delete"
            className="btn btn-link"
            onClick={confirmDelete}>
            <span className="text-danger name" id="delete" >
              Delete
            </span>
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={toggleModal}>
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={onSaveLinks}>
            {isSubmitting ? 'Saving...': 'Save'}
          </button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

SocialLinksModal.propTypes = {
  isOpen: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  toggleModal: PropTypes.func,
  selectSocialType: PropTypes.func.isRequired,
  selectedType: PropTypes.string,
  link: PropTypes.object.isRequired,
  onSaveLinks: PropTypes.func.isRequired,
  onChangeLink: PropTypes.func.isRequired,
};

export default SocialLinksModal;
