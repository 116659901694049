import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { skillsRequest } from '../../redux/actions/skills';
import { updateProfile } from '../../redux/actions/profile';
import { openModal, closeModal } from '../../redux/actions/components';
import { getMyCareerPreppedStatus, updateMyCareerPreppedStatus } from '../../redux/actions/my-careerprepped-status';

import { isNull } from 'lodash';

import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Helmet } from 'react-helmet';
import { Link, Navigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { ProgressBar } from 'react-step-progress-bar';
import { Col, Container, Row, PopoverBody, UncontrolledPopover } from 'reactstrap';

import MyCareerPreppedStatus from './MyCareerPreppedStatus';
import BasicEditModal from '../profile/header/BasicEditModal';
import JobsSection from '../../components/dashboard/JobsSection';
import SkillsActivity from '../../components/dashboard/SkillsActivity';
import JobsActivity from '../../components/dashboard/JobsActivity';
import SkillsSection from '../../components/dashboard/SkillsSection';
import DashboardAlert from '../../components/dashboard/DashboardAlert';
import DashboardOverviewModal from '../../components/dashboard/DashboardOverviewModal';
import ContactInfo from '../../components/profile/connects/ContactInfo';
import PopularHelpTopics from '../../components/dashboard/PopularHelpTopics';
import AddYourCustomSkills from '../../components/myskills/AddYourCustomSkills';
import AddSummary from '../../components/dashboard/my-careerprepped-status-components/AddSummary';
import AddEducation from '../../components/dashboard/my-careerprepped-status-components/AddEducation';
import AddExperience from '../../components/dashboard/my-careerprepped-status-components/AddExperience';
import AddWorkSituation from '../../components/dashboard/my-careerprepped-status-components/AddWorkSituation';
import CPStatusSuccessfull from '../../components/dashboard/my-careerprepped-status-components/CPStatusSuccessfull';

const transitionClasses = {
  exit: 'animated',
  exitActive: 'animate__slideOutUp'
};

const defaultAvatar = require('../../assets/images/avatar.jpg');
const defaultWebpageBackground = require('../../assets/images/profile-default-bg.png');

const cpStatusTitleAlias = {
  'skills': 'Add 10 Skills',
  'education': 'Add Education',
  'experience': 'Add Experience',
  'summary': 'Add About Me',
  'location': 'Add Location',
  'worksituation': 'Add Work Situation',
  'headline': 'Add Headline',
  'avatar': 'Add Photo',
  'contact': 'Add Contact Info'
};

const HomePage = ({ modal, skills, actions, profile, isRequestingProfile, myCareerPreppedStatus }) => {
  const [isEducationModalOpen, setIsEducationModalOpen] = useState(false);
  const [addYourCustomSkills, setAddYourCustomSkills] = useState(false);
  const [isExperienceModalOpen, setIsExperienceModalOpen] = useState(false);
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);
  const [isHeadlineModalOpen, setIsHeadlineModalOpen] = useState(false);
  const [fieldsToShow, setFieldsToShow] = useState(false);
  const [modalTitle, setModalTitle] = useState(false);
  const [isUpdatingAvatar, setIsUpdatingAvatar] = useState(false);
  const [isWorkSituationModalOpen, setIsWorkSituationModalOpen] = useState(false);
  const [isDashboardOverviewModalOpen, setIsDashboardOverviewModalOpen] = useState(false);
  const [isCPStatusSuccessfullModalOpen, setIsCPStatusSuccessfullModalOpen] = useState(false);

  useEffect(() => {
    actions.skillsRequest(profile.id);
  }, [actions, profile]);

  function onDismissBanner(event) {
    event.preventDefault();

    actions.updateProfile(profile.id, { dismissBanner: 1 });
  }

  const isActiveBanner = profile && !!profile.dismissBanner;
  const firstName = profile && profile.name && profile.name.split(' ')[0];

  const backgroundImage = profile && profile.background !== '' ?
    profile.background : defaultWebpageBackground;

  const avatar = profile && profile.avatar !== '' ?
    profile.avatar : defaultAvatar;

  const percentile = (myCareerPreppedStatus.overallStatus) ? myCareerPreppedStatus.overallStatus : 0;
  const resumePercentile = myCareerPreppedStatus.totalResumes > 0 ? 100 : 0;

  function toggleDashboardOverviewModal(event) {
    event.preventDefault();

    setIsDashboardOverviewModalOpen(!isDashboardOverviewModalOpen);
  }

  function toggleEducationModal() {
    if (isEducationModalOpen) {
      actions.getMyCareerPreppedStatus(profile.id).then((response) => {
        if (response.myCareerPreppedStatus.data.overallStage > 99) {
          toggleCPStatusSuccessfullModal();
        }
      });
    }

    setIsEducationModalOpen(!isEducationModalOpen);
  }

  function toggleAddSkills(event) {
    event.preventDefault();

    if (addYourCustomSkills) {
      actions.getMyCareerPreppedStatus(profile.id).then((response) => {
        if (response.myCareerPreppedStatus.data.overallStage > 99) {
          toggleCPStatusSuccessfullModal();
        }
      });
    }

    setAddYourCustomSkills(!addYourCustomSkills);
  }

  function toggleExperienceModal() {
    if (isExperienceModalOpen) {
      actions.getMyCareerPreppedStatus(profile.id).then((response) => {
        if (response.myCareerPreppedStatus.data.overallStage > 99) {
          toggleCPStatusSuccessfullModal();
        }
      });
    }

    setIsExperienceModalOpen(!isExperienceModalOpen);
  }

  function toggleSummaryModal() {
    if (isSummaryModalOpen) {
      actions.getMyCareerPreppedStatus(profile.id).then((response) => {
        if (response.myCareerPreppedStatus.data.overallStage > 99) {
          toggleCPStatusSuccessfullModal();
        }
      });
    }

    setIsSummaryModalOpen(!isSummaryModalOpen);
  }

  function toggleWorkSituationModal() {
    if (isWorkSituationModalOpen) {
      actions.getMyCareerPreppedStatus(profile.id).then((response) => {
        if (response.myCareerPreppedStatus.data.overallStage > 99) {
          toggleCPStatusSuccessfullModal();
        }
      });
    }

    setIsWorkSituationModalOpen(!isWorkSituationModalOpen);
  }

  function toggleContactInfo(event) {
    event.preventDefault();

    if (modal === 'contact-info') {
      actions.getMyCareerPreppedStatus(profile.id).then((response) => {
        if (response.myCareerPreppedStatus.data.overallStage > 99) {
          toggleCPStatusSuccessfullModal();
        }
      });

      actions.closeModal();
      return;
    }

    actions.openModal('contact-info');
  }

  function toggleCPStatusSuccessfullModal() {
    setIsCPStatusSuccessfullModalOpen(!isCPStatusSuccessfullModalOpen);
  }

  function onEditBasicInfo(event) {
    let passParam = 'location';

    if (event) {
      passParam = event.target.getAttribute('data-value');
    }

    if (isHeadlineModalOpen) {
      actions.getMyCareerPreppedStatus(profile.id).then((response) => {
        if (response.myCareerPreppedStatus.data.overallStage > 99) {
          toggleCPStatusSuccessfullModal();
        }
      });
    }

    setIsHeadlineModalOpen(!isHeadlineModalOpen);

    setFieldsToShow(passParam);
    setModalTitle(passParam === 'headline' ? 'Add Headline' : 'Add Location');
  }

  async function onSelectAvatarOrCoverPhoto(files, fileRejections, event) {
    setIsUpdatingAvatar(true);

    const { name } = event.target;

    const file = files[0];
    const formData = new FormData();

    formData.append(name, file);

    await actions.updateProfile(profile.id, formData);
    await actions.updateMyCareerPreppedStatus();

    actions.getMyCareerPreppedStatus(profile.id).then((response) => {
      if (response.myCareerPreppedStatus.data.overallStage > 99) {
        toggleCPStatusSuccessfullModal();
      }
    });

    setIsUpdatingAvatar(false);
  }

  function generateButton(type, cpStatusTitleAlias) {
    switch (type) {
    case 'education': {
      return (
        <a
          href="#"
          onClick={toggleEducationModal}>
          {cpStatusTitleAlias[type]}
        </a>
      );
    }

    case 'experience': {
      return (
        <a
          href="#"
          onClick={toggleExperienceModal}>
          {cpStatusTitleAlias[type]}
        </a>
      );
    }

    case 'summary': {
      return (
        <a
          href="#"
          onClick={toggleSummaryModal}>
          {cpStatusTitleAlias[type]}
        </a>
      );
    }

    case 'worksituation': {
      return (
        <a
          href="#"
          onClick={toggleWorkSituationModal}>
          {cpStatusTitleAlias[type]}
        </a>
      );
    }

    case 'location': {
      return (
        <a
          href="#"
          data-value="location"
          onClick={onEditBasicInfo}>
          {cpStatusTitleAlias[type]}
        </a>
      );
    }

    case 'contact': {
      return (
        <a
          href="#"
          data-value="contact"
          onClick={toggleContactInfo}>
          {cpStatusTitleAlias[type]}
        </a>
      );
    }

    case 'headline': {
      return (
        <a
          href="#"
          data-value="headline"
          onClick={onEditBasicInfo}>
          {cpStatusTitleAlias[type]}
        </a>
      );
    }

    case 'avatar': {
      return (
        <a
          href="#"
          disabled={isUpdatingAvatar}>
          <Dropzone
            multiple={false}
            className="upload-background-btn"
            accept="image/*"
            onDrop={onSelectAvatarOrCoverPhoto}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  {`${isUpdatingAvatar ? 'Adding...' : cpStatusTitleAlias[type]}`}
                  <input {...getInputProps()} name="avatar" />
                </div>
              </section>
            )}
          </Dropzone>
        </a>
      );
    }

    case 'skills': {
      return (
        <a
          href="#"
          onClick={toggleAddSkills}>
          {cpStatusTitleAlias[type]}
        </a>
      );
    }
    }
  }

  if ((profile.role === 'user') && (!isNull(profile.customerId)) && (profile.individualSubscriptionStatus !== 'active')) {
    return <Navigate to="/restart-subscription" state={{subscriptionType: 'individualUser'}} replace />;
  }

  return (
    <div className="dashboard-content">
      <Helmet title="Home" />
      <Container>
        <Row>
          <Col>
            <h1 className="dashboard-header">Hi {firstName}!</h1>
          </Col>
        </Row>

        <CSSTransition
          in={((!isRequestingProfile) && (!isActiveBanner))}
          timeout={200}
          classNames={transitionClasses}
          unmountOnExit>
          <DashboardAlert
            profile={profile}
            onDismissBanner={onDismissBanner}
            toggleDashboardOverviewModal={toggleDashboardOverviewModal}/>
        </CSSTransition>

        {isDashboardOverviewModalOpen &&
          <DashboardOverviewModal
            isOpen={isDashboardOverviewModalOpen}
            toggleModal={toggleDashboardOverviewModal}/>}

        <Row>
          <Col lg="4">
            <div className="dashboard-profile-card">
              <div
                className="dashboard-profile-bg"
                style={{ backgroundImage: `url("${backgroundImage}")` }} />
              <div className="dashboard-profile-info">
                <div className="dashboard-profile-avatar">
                  <Link to={`/cp/${profile.vanity}`}>
                    <img className="img-fluid" src={avatar} />
                  </Link>
                </div>
                <h4>{profile.name}</h4>
                <p>{profile.headline}</p>
                <Link to={`/cp/${profile.vanity}`}>{`${window.location.host}/cp/${profile.vanity}`}</Link>
              </div>
              <div className="dashboard-profile-stats-container">
                <Row>
                  <Col>
                    <div className="dashboard-profile-stat">
                      <h4>
                        <Link to={`/cp/${profile.vanity}/following`}>
                          {profile.followingCount}
                        </Link>
                      </h4>
                      <span>Following</span>
                    </div>
                  </Col>
                  <Col>
                    <div className="dashboard-profile-stat">
                      <h4>
                        <Link to={`/cp/${profile.vanity}/followers`}>
                          {profile.followersCount}
                        </Link>
                      </h4>
                      <span>Followers</span>
                    </div>
                  </Col>
                  <Col>
                    <div className="dashboard-profile-stat">
                      <h4>
                        <Link to="/portfolio">{profile.filesCount}</Link>
                      </h4>
                      <span>Portfolio</span>
                    </div>
                  </Col>
                </Row>
              </div>

            </div>

            <SkillsActivity />
            <JobsActivity />
          </Col>

          {/* Career Site Link */}
          <Col>
            <Row>
              <Col>
                <div className="dashboard-cp-status-container">
                  <MyCareerPreppedStatus />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="dashboard-cp-description-container">
                  <h3>Improve Your CareerPrepped Stage</h3>
                  <p>
                    {myCareerPreppedStatus?.completedCards?.length == 9 &&
                      myCareerPreppedStatus?.totalResumes > 0 ?
                      'You\'ve got the basics down. Just keep proving your skills, and keep your Career Site up-to-date so you\'re always ready to stand out with proven abilities.' :
                      'Your stage reflects your basic readiness to pursue jobs and showcase yourself to employers. Go from beginning to launching by finishing the key actions below.'}
                  </p>
                </div>
              </Col>
            </Row>

            {(myCareerPreppedStatus?.totalActions < 10) &&
              <Row>
                <Col>
                  <Row>
                    <Col md="6" className="mb-4">
                      <div className="dashboard-card pb-0">
                        <div className="dashboard-card-content">
                          <div className="dashboard-card-content-w-icon">
                            <div className="d-flex align-items-center w-100">
                              <div className="dashboard-icon-background primary">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM15 18H4V14H15V18ZM15 13H4V9H15V13ZM20 18H16V9H20V18Z" fill="white"/>
                                </svg>
                              </div>
                              <div className="dashboard-card-info">
                                <div className="dashboard-card-info-header">
                                  <h3><Link to={`/cp/${profile.vanity}`}>Career Site</Link></h3>
                                  <span>{myCareerPreppedStatus?.completedCards?.length} of 9 completed</span>
                                </div>
                                <div className="cp-status-progress-bar-container">
                                  <ProgressBar
                                    percent={percentile}
                                    filledBackground="#F05323" />
                                </div>
                              </div>
                              <UncontrolledPopover
                                placement="bottom"
                                target="missing-actions-popover"
                                className="careerstatus-popover"
                                trigger="legacy">
                                <PopoverBody>
                                  <b>Actions Needed</b>
                                  {myCareerPreppedStatus &&
                                    myCareerPreppedStatus?.completedCards?.map((action) => {
                                      return (
                                        <Row key={action.id}>
                                          <Col xs="12">
                                            <s><span className="text-muted">{cpStatusTitleAlias[action.statusType]}</span></s>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                    )}

                                  {myCareerPreppedStatus &&
                                    myCareerPreppedStatus?.pendingCards?.map((action) => {
                                      return (
                                        <Row key={action.id}>
                                          <Col xs="12">
                                            {generateButton(action.statusType, cpStatusTitleAlias)}
                                          </Col>
                                        </Row>
                                      );
                                    }
                                    )}
                                </PopoverBody>
                              </UncontrolledPopover>
                            </div>
                          </div>
                          <div className="dashboard-card-info-footer">
                            <a className="cursor-pointer" id="missing-actions-popover">
                              View Missing Actions
                            </a>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md="6" className="mb-4">
                      <div className="dashboard-card pb-0">
                        <div className="dashboard-card-content">
                          <div className="dashboard-card-content-w-icon">
                            <div className="d-flex align-items-center w-100">
                              <div className="dashboard-icon-background primary">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <rect width="24" height="24" fill="#F05323"/>
                                  <path d="M7 17H14V15H7V17ZM7 13H17V11H7V13ZM7 9H17V7H7V9ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 19H19V5H5V19Z" fill="white"/>
                                </svg>
                              </div>

                              <div className="dashboard-card-info">
                                <div className="dashboard-card-info-header">
                                  <h3><Link to="/job-center/resume-builder">Resume</Link></h3>
                                  <span>
                                    {myCareerPreppedStatus?.totalResumes == 0 ?
                                      'No resume created' :
                                      `${myCareerPreppedStatus?.totalResumes} Resumes`}
                                  </span>
                                </div>
                                <div className="cp-status-progress-bar-container">
                                  <ProgressBar
                                    percent={resumePercentile}
                                    filledBackground="#F05323" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="dashboard-card-info-footer">
                            <Link to="/job-center/resume-builder">
                              Create Resume
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>}
            <hr className="mt-4 mb-4" />
            <Row>
              <Col>
                <Row className="mb-4">
                  <Col>
                    <Row>
                      <Col md="6" sm="12" className="mb-md-0 mb-3">
                        <SkillsSection />
                      </Col>
                      <Col md="6" sm="12">
                        <JobsSection />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr className="mt-0 mb-4" />
            <PopularHelpTopics/>
          </Col>
        </Row>

        <AddEducation
          isOpen={isEducationModalOpen}
          toggleModal={toggleEducationModal}
          profile={profile} />

        <AddExperience
          isOpen={isExperienceModalOpen}
          toggleModal={toggleExperienceModal}
          profile={profile} />

        <AddSummary
          isOpen={isSummaryModalOpen}
          toggleModal={toggleSummaryModal}
          profile={profile} />

        <AddWorkSituation
          isOpen={isWorkSituationModalOpen}
          toggleModal={toggleWorkSituationModal} />

        <ContactInfo owner={profile} displayUpdateContactForm />

        <CPStatusSuccessfull
          isOpen={isCPStatusSuccessfullModalOpen}
          toggleModal={toggleCPStatusSuccessfullModal} />

        {isHeadlineModalOpen && (
          <BasicEditModal
            isOpen={isHeadlineModalOpen}
            onCancel={onEditBasicInfo}
            fieldsToShow={fieldsToShow}
            modalTitle={modalTitle} />)}

        {addYourCustomSkills &&
          <AddYourCustomSkills
            isOpen={addYourCustomSkills}
            toggleModal={toggleAddSkills}
            addedSkillsCount={skills ? skills.length : 0} />}

      </Container>
    </div>
  );
};

HomePage.propTypes = {
  modal: PropTypes.string,
  skills: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  isRequestingProfile: PropTypes.bool.isRequired,
  myCareerPreppedStatus: PropTypes.object.isRequired
};

const mapStateToProps = ({
  components: { modal },
  skills: {
    data: skills
  },
  profile: {
    data: profile,
    isRequesting: isRequestingProfile
  },
  myCareerPreppedStatus: { data: myCareerPreppedStatus }
}) =>
  ({ modal, skills, profile, isRequestingProfile, myCareerPreppedStatus });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    openModal,
    closeModal,
    skillsRequest,
    updateProfile,
    getMyCareerPreppedStatus,
    updateMyCareerPreppedStatus
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
