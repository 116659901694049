import { CALL_API } from '../../middlewares/api';

import {
  RESET_OCCUPATIONS,
  OCCUPATIONS_REQUEST,
  OCCUPATIONS_SUCCESS,
  OCCUPATIONS_FAILURE
} from '../../constants';

export const getCareerOccupations = (industryId, page = 1, query = '') => {
  let endpoint = `user/career-clusters?industry=${industryId}&page=${page}`;

  if (query.length > 0) {
    endpoint = `${endpoint}&query=${query}`;
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        OCCUPATIONS_REQUEST,
        OCCUPATIONS_SUCCESS,
        OCCUPATIONS_FAILURE
      ]
    }
  };
};

export const resetOccupations = () => ({ type: RESET_OCCUPATIONS });
