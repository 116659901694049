import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

import { MAX_FILE_NO } from '../../utilConstants';

const BadgeOptionItem = ({ badge, selectedBadges, toggleBadgeCheckbox }) => {

  return (
    <div key={badge.id} className="portfolio-item">
      <input
        type="checkbox"
        value={badge.issued.id}
        style={{marginRight: 10}}
        checked={selectedBadges.includes(badge.issued.id)}
        disabled={badge.files?.length >= MAX_FILE_NO}
        onChange={toggleBadgeCheckbox}/>

      <Link
        target="_blank"
        to={`/skill-badges/badge-details/${badge.issued.id}`}>
        {`${badge.unit.code} ${badge.name}`}
      </Link>
    </div>
  );
};

BadgeOptionItem.propTypes = {
  badge: PropTypes.object.isRequired,
  selectedBadges: PropTypes.array.isRequired,
  toggleBadgeCheckbox: PropTypes.func.isRequired
};

export default BadgeOptionItem;
