import { CALL_API } from '../../middlewares/api';

import {
  EXPERIENCE_REQUEST,
  EXPERIENCE_SUCCESS,
  EXPERIENCE_FAILURE,
  CREATE_EXPERIENCE_REQUEST,
  CREATE_EXPERIENCE_SUCCESS,
  CREATE_EXPERIENCE_FAILURE,
  UPDATE_EXPERIENCE_REQUEST,
  UPDATE_EXPERIENCE_SUCCESS,
  UPDATE_EXPERIENCE_FAILURE,
  DELETE_EXPERIENCE_REQUEST,
  DELETE_EXPERIENCE_SUCCESS,
  DELETE_EXPERIENCE_FAILURE,
} from '../../constants';

export const experienceRequest = (userId) => {
  const endpoint = `user/experience?user=${userId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [EXPERIENCE_REQUEST, EXPERIENCE_SUCCESS, EXPERIENCE_FAILURE],
    },
  };
};

export const saveExperience = (data) => {
  const endpoint = 'user/experience';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        CREATE_EXPERIENCE_REQUEST,
        CREATE_EXPERIENCE_SUCCESS,
        CREATE_EXPERIENCE_FAILURE,
      ],
    },
  };
};

export const updateExperience = (index, data) => {
  const endpoint = `user/experience/${data.id}`;

  return {
    [CALL_API]: {
      data,
      index,
      endpoint,
      method: 'put',
      authenticated: true,
      types: [
        UPDATE_EXPERIENCE_REQUEST,
        UPDATE_EXPERIENCE_SUCCESS,
        UPDATE_EXPERIENCE_FAILURE,
      ],
    },
  };
};

export const deleteExperience = (experienceId) => {
  const endpoint = `user/experience/${experienceId}`;

  return {
    [CALL_API]: {
      endpoint,
      id: experienceId,
      method: 'delete',
      authenticated: true,
      types: [
        DELETE_EXPERIENCE_REQUEST,
        DELETE_EXPERIENCE_SUCCESS,
        DELETE_EXPERIENCE_FAILURE,
      ],
    },
  };
};
