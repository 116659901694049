import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateProfile } from '../../../redux/actions/profile';

import Avatar from '../../../containers/profile/header/Avatar';
import UserDetails from './UserDetails';
import ProfileShare from './ProfileShare';
import CareerSiteOverviewModal from '../sections/CareerSiteOverviewModal';

import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';

import {
  Alert,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import VisitingUserActions from '../../../containers/profile/header/VisitingUserActions';

const defaultWebpageBackground = require('../../../assets/images/profile-default-bg.png');

const goBack = () => window.history.back();

const ProfileJumbotron = ({
  actions,
  profile,
  currentUser,
  isPublicProfile,
  onToggleSocialModal,
  onToggleContactInfoModal,
  onEditBasicInfo,
  hideVisitingActions,
  isOwner,
  isUpdating,
  loadedCoverPic,
  coverPicFile,
  onSelectNewCover,
  isUpdateCoverDropdownOpen,
  clearBackgroundPicData,
  onToggleCoverDropdown,
  onSaveCoverPic,
  onRemoveBackground,
  networks,
  isAuthenticated
}) => {
  const [visibleCareerSiteOverviewModal, setVisibleCareerSiteOverviewModal] = useState(false);

  const visitingUser = profile.id !== currentUser.id;
  const showCoverPicControlBar = !visitingUser && !isPublicProfile && loadedCoverPic !== null && coverPicFile !== null;
  const backgroundDong = profile.background && profile.background !== '';
  let backgroundImage = backgroundDong ?
    profile.background : defaultWebpageBackground;

  const isActiveCareerSiteOverview = profile && !!profile.dismissCareerSiteOverview;

  function onDismissCareerSiteOverview() {
    actions.updateProfile(profile.id, { dismissCareerSiteOverview: 1 });
  }

  function toggleCareerSiteOverviewModal(event) {
    event.preventDefault();

    setVisibleCareerSiteOverviewModal(!visibleCareerSiteOverviewModal);
  }

  return (
    <div>
      {
        !visitingUser && isPublicProfile &&
          <div className="container profile-share">
            <div className="row">
              <div className="col-lg-12">
                <div className="profile-share-container exit-preview-container">
                  <div
                    onClick={goBack}
                    className="exit-preview clickable">
                    <i className="fa fa-close"/>
                    <span style={{paddingLeft: 10}}>
                      Exit Preview Mode
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
      }
      <div className="career-site__profile-banner">
        {showCoverPicControlBar &&
          <div
            style={{
              zIndex: 999999,
              background: `url(${loadedCoverPic}) center center no-repeat`,
              backgroundSize: 'cover',
              position: 'relative',
              height: 398
            }}>
            <div className="cover-control-bar">
              <button
                onClick={onSaveCoverPic}
                disabled={isUpdating}
                className="btn btn-success float-right save-btn">
                {isUpdating ? 'Saving...':'Save'}
              </button>
              <button
                onClick={clearBackgroundPicData}
                disabled={isUpdating}
                className="btn btn-secondary float-right">Cancel</button>
            </div>
          </div>}
        <div
          style={showCoverPicControlBar ? { display: 'none'} :{
            background: `url(${backgroundImage}) center center no-repeat`,
            backgroundSize: 'cover',
          }}
          className="background-image">
          {
            visitingUser && !hideVisitingActions &&
              <VisitingUserActions owner={profile}/>
          }
          {
            !visitingUser && isPublicProfile &&
              <div className="container" style={{ position: 'relative' }}>
                <div className="menu-profile-settings">
                  <a className="follow">
                    + Follow
                  </a>
                  <a className="message">Message Me</a>
                  <a className="view-portfolio">View Portfolio</a>
                </div>
              </div>
          }
          {
            !isPublicProfile && !visitingUser &&
            <div className="container" style={{ position: 'relative' }}>
              <div className="edit-profile-btn-group">
                <div
                  onClick={onEditBasicInfo}
                  className="intro-edit-icon clickable">
                  <span>
                    <i className="fa fa-pencil align-middle"/>
                    <span><span className="d-none d-sm-inline-block">Edit Basic</span> Info</span>
                  </span>
                </div>
                <Dropdown
                  toggle={onToggleCoverDropdown}
                  isOpen={isUpdateCoverDropdownOpen}
                  className="add-background-photo camera-icon clickable">
                  <DropdownToggle tag="span">
                    <i className="fa fa-camera"/>
                    <span><span className="d-none d-sm-inline-block">Edit Background</span> Photo</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    {
                      backgroundDong &&
                        <DropdownItem onClick={onRemoveBackground}>
                          Remove
                        </DropdownItem>
                    }

                    <button className="dropdown-item">
                      <Dropzone
                        multiple={false}
                        className="upload-background-btn"
                        accept="image/*"
                        onDrop={onSelectNewCover}>
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps()}>
                              Upload photo
                              <input {...getInputProps()}/>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </button>
                    <DropdownItem onClick={onToggleCoverDropdown}>
                      Cancel
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Link to={`/cp/${profile.vanity}/preview`} className="view-webpage mt-2 mt-md-0">Preview</Link>
              </div>
            </div>
          }
          <div className="container user-profile-maininfo">
            <span>
              <Avatar
                profile={profile}
                visitingUser={visitingUser}
                isPublicProfile={isPublicProfile}/>
            </span>
            <div>
              <UserDetails
                profile={profile}
                isOwner={isOwner}
                isPublicProfile={isPublicProfile}/>
            </div>
          </div>
        </div>
      </div>
      <ProfileShare
        isOwner={isOwner}
        profile={profile}
        networks={networks}
        isPrivacyVisible={profile.isPrivacyVisible}
        onToggleSocialModal={onToggleSocialModal}
        onToggleContactInfoModal = {onToggleContactInfoModal}
        isPublicProfile={isPublicProfile}
        privacySettings={profile.privacySettings}
        isAuthenticated={isAuthenticated}/>

      {(isOwner && (!isPublicProfile)) &&
        <div className="container profile-share">
          <div className="row">
            <div className="col-lg-12">
              <Alert
                color="info"
                isOpen={!isActiveCareerSiteOverview}
                toggle={onDismissCareerSiteOverview}>
                <div className="alert-content">
                  <div className="alert-content-left">
                    <h6 className="alert-heading">
                      Career Site Overview
                    </h6>
                    <p>Learn more about using the Career Site with this video</p>
                  </div>
                  <div className="alert-content-right">
                    <a
                      href="#"
                      className="btn btn-primary"
                      onClick={toggleCareerSiteOverviewModal}>
                      Watch Overview
                    </a>
                  </div>
                </div>
              </Alert>
            </div>
          </div>
        </div>}

      {visibleCareerSiteOverviewModal &&
        <CareerSiteOverviewModal
          isOpen={visibleCareerSiteOverviewModal}
          toggleModal={toggleCareerSiteOverviewModal}/>}
    </div>
  );
};

ProfileJumbotron.propTypes = {
  isOwner: PropTypes.bool,
  actions: PropTypes.object.isRequired,
  onEditBasicInfo: PropTypes.func,
  profile: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  isPublicProfile: PropTypes.bool,
  onToggleSocialModal: PropTypes.func,
  hideVisitingActions: PropTypes.bool,
  networks: PropTypes.object,
  isUpdating: PropTypes.bool,
  loadedCoverPic: PropTypes.string,
  coverPicFile: PropTypes.object,
  onSelectNewCover: PropTypes.func,
  isUpdateCoverDropdownOpen: PropTypes.bool,
  onToggleCoverDropdown: PropTypes.func,
  onSaveCoverPic: PropTypes.func,
  clearBackgroundPicData: PropTypes.func,
  onRemoveBackground: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  onToggleContactInfoModal: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ updateProfile }, dispatch)
});

export default connect(null, mapDispatchToProps)(ProfileJumbotron);
