import React, { Component } from 'react';

import JobMatchesList from './JobMatchesList';

class JobMatches extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <div>
        <JobMatchesList />
      </div>
    );
  }
}

export default JobMatches;
