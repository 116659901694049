import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import { isNull, isUndefined } from 'lodash';
import * as routerActions from '../../redux/actions/router';
import * as filesActions from '../../redux/actions/files';
import * as componentActions from '../../redux/actions/components';
import * as skillActions from '../../redux/actions/skills';
import FileDetails from '../../components/portfolio/FileDetails';
import Loading from '../../components/common/Loading';
import SharePortfolioModal from './SharePortfolioModal';

class FileDetailsModal extends Component {
  static propTypes = {
    fileId: PropTypes.string,
    isOpen: PropTypes.bool,
    actions: PropTypes.object.isRequired,
    fileDetails: PropTypes.object,
    skills: PropTypes.object.isRequired,
    profile: PropTypes.object,
    isRequestingComments: PropTypes.bool,
    comments: PropTypes.array.isRequired,
    isSubmitting: PropTypes.object.isRequired,
    commentData: PropTypes.object.isRequired,
    vanity: PropTypes.string,
    userId: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    isRequestingFile: PropTypes.bool,
    slug: PropTypes.string,
    onEditFile: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);

    this.state={
      commentData: Object.assign({}, this.props.commentData),
      showShareModal: false,
      showAllSkills: false
    };


    this.closeModal = this.closeModal.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCopy = this.onCopy.bind(this);
    this.onComment = this.onComment.bind(this);
    this.onLikeFile = this.onLikeFile.bind(this);
    this.onShareClick = this.onShareClick.bind(this);
    this.onViewEvidence = this.onViewEvidence.bind(this);
    this.onChangeRoute = this.onChangeRoute.bind(this);
    this.onToggleShowAllSkills = this.onToggleShowAllSkills.bind(this);
  }

  componentDidMount(){
    const { actions, fileId, profile, isAuthenticated } = this.props;
    actions.skillsRequest(profile.id);

    if(fileId){
      actions.fetchFileById(fileId, isAuthenticated);
      actions.getUnitFileComments(fileId, isAuthenticated);
    }

  }

  onShareClick(){
    this.setState({
      showShareModal: !this.state.showShareModal
    });
  }

  onToggleShowAllSkills(){
    this.setState({
      showAllSkills: !this.state.showAllSkills
    });
  }

  closeModal(){
    this.props.actions.clearPreviewFile();
    this.props.actions.closeModal();

    if (! isUndefined(this.props.slug)) {
      this.props.actions.redirect(`/cp/${this.props.slug}`);
    }
  }

  onChange(e){
    const { name, value } = e.target;
    const { commentData } = this.state;

    commentData[name] = value;

    this.setState({ commentData });
  }

  onLikeFile(e){
    e.preventDefault();
    const { actions, fileDetails, isAuthenticated } = this.props;
    const { liked, id } = fileDetails.data;

    if(!isAuthenticated){
      return toastr.error('Please Login/Signup');
    }

    if(liked){
      return actions.unlikeFile(id);
    }

    const data = {
      'file': fileDetails.data.id
    };

    actions.likeFile(data);
  }

  onCopy() {
    this.setState({ copied: true });
    toastr.success('Link successfully copied!');
  }

  onComment(e){
    e.preventDefault();
    const { commentData } = this.state;
    const { fileId, actions } = this.props;

    const data = {
      'file': fileId,
      'comment': commentData.comment
    };

    actions.addCommentToFile(data).
      then(() => this.setState({ commentData: {
        'comment': ''
      }}));

  }

  onViewEvidence(){
    return () => {
      const { actions } = this.props;
      actions.closeModal();
    };
  }

  onChangeRoute(route = null){
    return () => {
      this.closeModal();
      if(!isNull(route)){
        this.props.actions.redirect(route);
      }
    };
  }

  render() {
    const { commentData, showAllSkills, showShareModal } = this.state;
    const {
      isOpen,
      fileDetails,
      skills: skillsInRedux,
      profile,
      isRequestingFile,
      isRequestingComments,
      comments,
      isSubmitting,
      isAuthenticated
    } = this.props;
    const { isRequesting } = skillsInRedux;
    const hasCompletedLoading = !isRequesting && !isRequestingFile &&
      Object.keys(fileDetails.data).length > 0;

    return(
      <Modal
        size="lg"
        className="portfolio-modal"
        isOpen={isOpen}
        backdrop="static"
        toggle={this.closeModal}>
        <div className="portfolio-wrap modal-content">
          {!hasCompletedLoading &&
            <div className="loading-container">
              Loading file details ...<Loading/>
            </div>}

          {hasCompletedLoading &&
            <FileDetails
              isAuthenticated={isAuthenticated}
              onChangeRoute={this.onChangeRoute}
              profile={profile}
              showAllSkills={showAllSkills}
              onToggleShowAllSkills={this.onToggleShowAllSkills}
              details={fileDetails.data}
              onShareClick={this.onShareClick}
              closeModal={this.closeModal}
              isRequestingComments={isRequestingComments}
              comments={comments}
              commentData={commentData}
              onChange={this.onChange}
              onCopy={this.onCopy}
              onComment={this.onComment}
              onLikeFile={this.onLikeFile}
              isSubmitting={isSubmitting}
              onViewEvidence={this.onViewEvidence}
              onEditFile={this.props.onEditFile}/>}

          {showShareModal &&
            <SharePortfolioModal
              isOpen={showShareModal}
              onClose={this.onShareClick}
              fileData={fileDetails.data}/>}

        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const commentData = {
    'comment': ''
  };

  const { files, skills, profile, auth } = state;
  return {
    commentData,
    skills: skills,
    profile: profile.data,
    isRequestingFile: files.unitFile.isRequesting,
    fileDetails: files.unitFile,
    comments: files.unitFileComments.comments,
    isRequestingComments: files.unitFileComments.isRequesting,
    isSubmitting: files.unitFileComments.isSubmitting,
    selectedPortfolio: files.selectedPortfolio,
    isAuthenticated: auth.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, filesActions, componentActions, skillActions, routerActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(FileDetailsModal);
