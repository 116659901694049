import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as skillBuildersActions from '../../redux/actions/skill-builders';
import * as authActions from '../../redux/actions/authentication';

import { Outlet } from 'react-router-dom';

class SkillBuilders extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired
  };

  componentWillMount(){
    const { actions, isAuthenticated } = this.props;
    if(isAuthenticated){
      actions.checkForToken();
    }
  }

  componentDidMount() {
    const { isAuthenticated, actions } = this.props;

    actions.skillBuildersRequest(isAuthenticated);
  }

  render() {
    return <Outlet/>;
  }
}
const mapStateToProps = ({ auth: { isAuthenticated } }) => ({ isAuthenticated });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...authActions, ...skillBuildersActions }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SkillBuilders);
