import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';
import _isObject from 'lodash/isObject';
import { toastr } from 'react-redux-toastr';

import * as followerActions from '../../../redux/actions/followers';
import * as followingActions from '../../../redux/actions/following';
import * as messageActions from '../../../redux/actions/messages';
import * as profileActions from '../../../redux/actions/profile';

import FollowerCard from '../../../components/profile/connects/FollowerCard';

class Follower extends Component {
  static propTypes = {
    actions: PropTypes.object,
    profile: PropTypes.object,
    following: PropTypes.object,
    isSubmitting: PropTypes.bool,
    message: PropTypes.object,
    isRequesting: PropTypes.bool,
    isDeleting: PropTypes.bool,
    selectFollower: PropTypes.func,
    selected: PropTypes.array.isRequired,
    isDeletingFollower: PropTypes.object,
    isSubmittingFollower: PropTypes.object
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      error: {},
      modalOpen: false,
      dropdownOpen: false,
      message: Object.assign({}, props.message),
      isCheckedUser: false
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.onFollowClick = this.onFollowClick.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);

    this.onHideClick = this.onHideClick.bind(this);
    this.onBlockClick = this.onBlockClick.bind(this);
    this.onReportClick = this.onReportClick.bind(this);
    this.checkUser = this.checkUser.bind(this);
    this.onUnfollowClick = this.onUnfollowClick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { selected, profile } = nextProps;

    if (selected.find(item => item === profile.user.id)) {
      this.setState({ isCheckedUser: true });
    } else {
      this.setState({ isCheckedUser: false });
    }
  }

  onUnfollowClick(recordId) {
    return () => {
      const { actions } = this.props;

      actions.deleteFollowing(recordId)
        .then(() => {
          actions.decrementFollowingCount();
          this.setState({ dropdownOpen: !this.state.dropdownOpen });
        });
    };
  }

  onFollowClick(event) {
    event.preventDefault();

    const { actions, profile } = this.props;

    actions.saveFollower(profile.user)
      .then(() => actions.incrementFollowingCount());

    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  checkUser() {
    const { selectFollower, profile } = this.props;

    if (!this.state.isCheckedUser){
      selectFollower(profile.user.id,true);
    } else {
      selectFollower(profile.user.id,false);
    }
  }

  onReportClick() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  onHideClick() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  onBlockClick() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  toggleDropdown() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  toggleModal() {
    const message = { recipients: [], subject: '', body: '' };
    const { profile } = this.props;
   
    if(profile?.user?.privateMessagingDisabled){
      toastr.info('', 'The organizational admin has disabled private messaging to this user');
      return;
    }

    this.setState({
      message,
      modalOpen: ! this.state.modalOpen
    });
  }

  onMessageChange(event) {
    const field = event.target.name;
    const { message } = this.state;

    message[field] = event.target.value;

    this.setState({ message });

    this.isValid(message);
  }

  isValid(value) {
    const { error } = this.state;

    this.setState({ error: {} });

    if (! value.body.length > 0) {
      error['body'] = 'Message cannot be empty';
      this.setState({ error });
    }

    if (!value.subject.length > 0) {
      error['subject'] = 'Subject cannot be empty';
      this.setState({ error });
    }

    return (value.subject.length > 0) && (value.body.length > 0);
  }

  onMessageSubmit() {
    const { profile } = this.props;
    const { message } = this.state;

    if (! this.isValid(message)) return;

    message.recipients = [profile.user.id];
    this.props.actions.sendMessage(message)
      .then(() => this.handleResponse());
  }

  handleResponse() {
    const message = { recipients: [], subject: '', body: '' };

    this.setState({ message });
    this.toggleModal();
  }

  render() {
    const { profile, isSubmitting, isSubmittingFollower, isDeletingFollower } = this.props;
    const {
      dropdownOpen, modalOpen, error, message, isSortByOpen,
      isCheckedUser
    } = this.state;

    const isUserFollower = profile.isUserFollower && profile.isUserFollower.status;

    return (
      <div className="follow-list-item">
        {
          _isObject(profile.user) &&
            <FollowerCard
              onUnfollowClick={this.onUnfollowClick}
              isCheckedUser={isCheckedUser}
              checkUser={this.checkUser}
              onReportClick={this.onReportClick}
              onHideClick={this.onHideClick}
              onBlockClick={this.onBlockClick}
              isSortByOpen={isSortByOpen}
              toggleSortBy={this.toggleSortBy}
              onFollowClick={this.onFollowClick}
              profile={profile.user}
              recordId={profile.isUserFollower.connectId}
              toggleDropdown={this.toggleDropdown}
              dropdownOpen={dropdownOpen}
              modalOpen={modalOpen}
              toggleModal={this.toggleModal}
              error={error}
              isSubmitting={isSubmitting}
              isSubmittingFollower={isSubmittingFollower}
              isDeletingFollower={isDeletingFollower}
              message={message}
              isUserFollower={isUserFollower}
              onMessageChange={this.onMessageChange}
              onMessageSubmit={this.onMessageSubmit}/>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const message = { recipients: [], subject: '', body: '' };
  const notification = { userid: '', message: '' };

  return {
    message,
    notification,
    isSubmitting: state.messages.isSubmitting,
    isSubmittingFollower: state.followers.isSubmitting,
    isDeletingFollower: state.following.isDeleting,
    following: state.following
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    followerActions,
    messageActions,
    followingActions,
    profileActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Follower);
