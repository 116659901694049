import PropTypes from 'prop-types';
import React from 'react';
import SortFile from '../../components/portfolio/SortFile';
import SearchComponent from '../../components/portfolio/SearchComponent';

const SortAndSearch = ({
  activeSort,
  searchValue,
  onSearchChange,
  onSearchSubmit,
  onSortChange,
  toggleDropdown,
  isDropdownOpen,
  isRequestingSort,
  isRequestingSearch,
  handleSearchFormKeyPress,
  onAddPortfolio,
  noPortfolioFiles,
  onClearSearch
}) => {
  return (
    <div className="filterbar">
      <div className="row">
        <div className="col-md-auto mb-2 mb-md-0">
          <div className="d-flex align-items-center">
            <button
              type="button"
              name="button"
              onClick={onAddPortfolio}
              className="btn btn-primary mr-3">
              Add Portfolio File
            </button>
            {
              !noPortfolioFiles &&
                <div className="form-group">
                  <SortFile
                    activeSort={activeSort}
                    isRequestingSort={isRequestingSort}
                    isDropdownOpen={isDropdownOpen}
                    onSortChange={onSortChange}
                    toggleDropdown={toggleDropdown}/>
                </div>
            }
          </div>
        </div>
        {
          !noPortfolioFiles &&
            <div className="col-md-auto p-md-0">
              <SearchComponent
                searchValue={searchValue}
                onClearSearch={onClearSearch}
                handleSearchFormKeyPress={handleSearchFormKeyPress}
                onSearchChange={onSearchChange}
                onSearchSubmit={onSearchSubmit}
                isRequestingSearch={isRequestingSearch}/>
            </div>
        }
      </div>
    </div>
  );
};

SortAndSearch.propTypes = {
  noPortfolioFiles: PropTypes.bool,
  onAddPortfolio: PropTypes.func.isRequired,
  activeSort: PropTypes.string.isRequired,
  onSortChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onSearchSubmit: PropTypes.func.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  isDropdownOpen: PropTypes.bool.isRequired,
  isRequestingSort: PropTypes.bool.isRequired,
  isRequestingSearch: PropTypes.bool.isRequired,
  handleSearchFormKeyPress: PropTypes.func.isRequired,
  onClearSearch: PropTypes.func.isRequired
};

export default SortAndSearch;

