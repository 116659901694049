import PropTypes from 'prop-types';
import React from 'react';
import AvatarCard from './AvatarCard';
import MessageModal from './MessageModal';
import LocationCard from './LocationCard';
import InstitutionCard from './InstitutionCard';
import ButtonActionsFollowing from './ButtonActionsFollowing';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const FollowerCard = ({
  onUnfollowClick,
  isCheckedUser,
  checkUser,
  onFollowClick,
  profile,
  modalOpen,
  toggleModal,
  error,
  isSubmitting,
  message,
  isUserFollower,
  onMessageChange,
  onMessageSubmit,
  isDeletingFollower,
  isSubmittingFollower,
  recordId
}) => {
  const cardStyles = classNames({
    'follow-tile checked': isCheckedUser,
    'follow-tile': !isCheckedUser
  });

  const isFollowing = isSubmittingFollower.status &&
    isSubmittingFollower.id === profile.id;

  const isUnFollowing = isDeletingFollower.status &&
    (isDeletingFollower.id.indexOf(recordId) !== -1);

  return (
    <div className={cardStyles}>
      <div className="follower-item">
        <div className="follower-info">
          <div className="select">
            <input
              className="select-box"
              type="checkbox"
              onClick={checkUser}
              checked={isCheckedUser} />
          </div>
          <div className="follower-content">
            <Link to={`/cp/${profile.vanity}`}>
              <AvatarCard profile={profile} />
            </Link>
            <div>
              <div className="follower-name">
                <Link to={`/cp/${profile.vanity}`}>
                  {profile.name}
                </Link>
              </div>
              <div className="follower-meta">
                {profile.headline && profile.headline.length > 0 &&
                  <p className="two-line-body one">{profile.headline}</p>
                }
                <LocationCard profile={profile}/>
                <InstitutionCard profile={profile}/>
              </div>
            </div>
          </div>
        </div>
        <ButtonActionsFollowing
          isUserFollower={isUserFollower}
          isFollowing={isFollowing}
          isUnFollowing={isUnFollowing}
          onUnfollowClick={onUnfollowClick}
          onFollowClick={onFollowClick}
          toggleModal={toggleModal}
          recordId={recordId} />
      </div>
      <MessageModal
        toggleModal={toggleModal}
        modalOpen={modalOpen}
        error={error}
        isSubmitting={isSubmitting}
        message={message}
        onMessageChange={onMessageChange}
        onMessageSubmit={onMessageSubmit}/>
    </div>
  );
};

FollowerCard.propTypes = {
  onUnfollowClick: PropTypes.func,
  isCheckedUser: PropTypes.bool.isRequired,
  checkUser: PropTypes.func.isRequired,
  onReportClick: PropTypes.func.isRequired,
  onHideClick: PropTypes.func.isRequired,
  onBlockClick: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
  message: PropTypes.object,
  isSubmitting: PropTypes.bool,
  profile: PropTypes.object,
  toggleDropdown: PropTypes.func.isRequired,
  dropdownOpen: PropTypes.bool.isRequired,
  onFollowClick: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onMessageChange: PropTypes.func.isRequired,
  onMessageSubmit: PropTypes.func,
  isDeletingFollower: PropTypes.object,
  isSubmittingFollower: PropTypes.object,
  recordId: PropTypes.string,
  isUserFollower: PropTypes.bool
};

export default FollowerCard;
