import initialState from './initialState';

import {
  GET_CLUSTERS_REQUEST,
  GET_CLUSTERS_SUCCESS,
  GET_CLUSTERS_FAILURE,
  GET_INDUSTRIES_REQUEST,
  GET_INDUSTRIES_SUCCESS,
  GET_INDUSTRIES_FAILURE,
  GET_OCCUPATIONS_REQUEST,
  GET_OCCUPATIONS_SUCCESS,
  GET_OCCUPATIONS_FAILURE,
  GET_IR_DATA_BY_SEARCH_REQUEST,
  GET_IR_DATA_BY_SEARCH_SUCCESS,
  GET_IR_DATA_BY_SEARCH_FAILURE,
  GET_CAREER_INTEREST_REQUEST,
  GET_CAREER_INTEREST_SUCCESS,
  GET_CAREER_INTEREST_FAILURE,
  GET_COUNTRIES_LIST_REQUEST,
  GET_COUNTRIES_LIST_SUCCESS,
  GET_COUNTRIES_LIST_FAILURE,
} from '../../constants';
import { isEmpty } from 'lodash';

export default function reducer(
  state = initialState.clusterIndustryOccupation,
  action
) {
  switch (action.type) {
  case GET_CLUSTERS_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };
  case GET_CLUSTERS_SUCCESS: {
    const clusterIndustries = action.data._embedded.getClusterIndustryOccupation[0];
    const keys = Object.keys(clusterIndustries);
    const clusters = keys.map((k) => {
      return {
        label: clusterIndustries[k],
        value: k,
      };
    });
    return {
      ...state,
      isRequesting: false,
      data: {
        ...state.data,
        industries: [],
        occupations: [],
        clusters,
      },
    };
  }

  case GET_CLUSTERS_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  case GET_INDUSTRIES_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };
  case GET_INDUSTRIES_SUCCESS: {
    const clusterIndustries = action.data._embedded.getClusterIndustryOccupation[0];
    const keys = Object.keys(clusterIndustries);
    const industries = keys.map((k) => {
      return {
        label: clusterIndustries[k],
        value: k,
      };
    });
    return {
      ...state,
      isRequesting: false,
      data: {
        ...state.data,
        occupations: [],
        industries,
      },
    };
  }

  case GET_INDUSTRIES_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  case GET_OCCUPATIONS_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };
  case GET_OCCUPATIONS_SUCCESS: {
    const clusterIndustries = action.data._embedded.getClusterIndustryOccupation[0];
    const keys = Object.keys(clusterIndustries);
    const occupations = keys.map((k) => {
      return {
        label: clusterIndustries[k],
        value: k,
      };
    });
    return {
      ...state,
      isRequesting: false,
      data: {
        ...state.data,
        occupations,
      },
    };
  }

  case GET_OCCUPATIONS_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  case GET_IR_DATA_BY_SEARCH_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };
  case GET_IR_DATA_BY_SEARCH_SUCCESS: {

    const data = action.data.detail.map((res) => {
      const parseCluster = JSON.parse(res.cluster);

      const inputList = parseCluster.map((input) => {
        return {
          cluster: {'label' : input.clusterName, 'value': parseInt(input.clusterId)},
          industry:{'label': input.industryName, 'value': parseInt(input.industryId)},
          occupation: {'label':input.occupationName,'value': parseInt(input.occupationId)},
        };
      });

      return {
        industryRepId : res.id,
        label : res.email,
        value: res.email,
        city:{'label' : res.city,'value' : res.city},
        inputList,
        company: res.company,
        country: {'label' : res.country,'value' : res.country},
        workEmail: res.email,
        jobTitle: res.jobTitle,
        firstName:  res.name.split(' ')[0],
        lastName: res.name.split(' ')[1],
        alternativeEmail: res.secondaryEmail,
        stateProvince: {'label' : res.state,'value' : res.state},
      };
    });

    return {
      ...state,
      isRequesting: false,
      data: {
        ...state.data,
        searchList: data,
      },
    };
  }

  case GET_IR_DATA_BY_SEARCH_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  case GET_CAREER_INTEREST_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };
  case GET_CAREER_INTEREST_SUCCESS: {
    const clusterIndustries = action.data.detail;
    let rowCounts = [];
    const clusters = clusterIndustries.map((clusterItem) => {
      // manipulate cluster keys and store all cluster childrens under it
      rowCounts[`C${clusterItem.value}`] = clusterItem.children.length;
      return {
        label: clusterItem.label,
        value: `C${clusterItem.value}`,
        className:'ci-main-category',
        // manipulate industry keys and store all industry childrens under it
        children: clusterItem.children.map((industryItem) => {
          rowCounts[`C${clusterItem.value}`] = rowCounts[`C${clusterItem.value}`] + industryItem.children.length;
          rowCounts[`I${industryItem.value}`] = industryItem.children.length;
          return {
            label: industryItem.label,
            value: `I${industryItem.value}`,
            className:'ci-bold-subtitle',
            // manipulate ocupation keys
            children: industryItem.children.map((occupationItem) => {
              return {
                label: occupationItem.label,
                value: `O${occupationItem.value}`,
                className:'my-2',
              };
            })
          };
        })
      };
    });
    return {
      ...state,
      isRequesting: false,
      data: {
        ...state.data,
        careerInterest: clusters,
        rowCounts
      },
    };
  }

  case GET_CAREER_INTEREST_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  case GET_COUNTRIES_LIST_REQUEST:
    return Object.assign({}, state, { isUpdating: true });

  case GET_COUNTRIES_LIST_SUCCESS: {
    if(!isEmpty(action.data.detail) && action.data.detail[0].countryId){
      return Object.assign({}, state, {
        countriesList: action.data.detail,
        statesList: [],
        citiesList: [],
        error: {},
        isUpdating: false,
      });
    } else if(!isEmpty(action.data.detail) && action.data.detail[0].stateId){
      return Object.assign({}, state, {
        statesList: action.data.detail,
        citiesList: [],
        error: {},
        isUpdating: false,
      });
    } else if(!isEmpty(action.data.detail) && action.data.detail[0].cityId){
      return Object.assign({}, state, {
        citiesList: !isEmpty(action.data.detail) && action.data.detail[0].cityId ?
          action.data.detail : [],
        error: {},
        isUpdating: false,
      });
    }
    return Object.assign({}, state, {
      statesList: [],
      citiesList: [],
      error: {},
      isUpdating: false,
    });
  }

  case GET_COUNTRIES_LIST_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: false,
    });

  default:
    return state;
  }
}
