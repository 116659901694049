import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

const ManageGroupsExampleModal = ({ isOpen, onCancel }) => {
  return (
    <Modal
      size="lg"
      className="modal-margin-top"
      isOpen={isOpen}
      backdrop="static"
      toggle={onCancel}>
      <ModalHeader toggle={onCancel}>Example of a Group Hierarchy</ModalHeader>
      <ModalBody>
        <h4>Top of the Hierarchy</h4>
        <p>An organizational admin may create groups for different learning units and designate admins who oversee each learning unit.</p>

        <h4>2nd Tier of the Hierarchy</h4>
        <p>The learning unit admins might then create groups for their affiliated team members designating them as the admin of their own group.</p>

        <h4>3rd Tier of the Hierarchy</h4>
        <p>In turn, each team member may then create groups for the different cohorts of learners they work with to invite learners to those groups.</p>
      </ModalBody>
      <ModalFooter>
        <button
          onClick={onCancel}
          className="btn btn-secondary">
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
};

ManageGroupsExampleModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ManageGroupsExampleModal;
