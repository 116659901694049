import PropTypes from 'prop-types';
import React from 'react';
import { uniqueId } from '../../../utils';
import AssociationCardHandler from '../../../containers/profile/sections/AssociationCardHandler';

const AssociationList = ({associations, isPublicProfile, onEdit}) => {
  if (associations.length === 0) {
    return(
      <div className="empty-section-message">
        <p>Tell people about your leadership or involvement with professional associations or groups.</p>
      </div>
    );
  }

  return (
    <div>
      {associations.map(association =>
        (<AssociationCardHandler
          key={uniqueId()}
          association={association}
          onEdit={onEdit}
          isPublicProfile={isPublicProfile}/>))}
    </div>
  );
};

AssociationList.propTypes = {
  associations: PropTypes.array.isRequired,
  isPublicProfile: PropTypes.bool.isRequired,
  onEdit: PropTypes.func
};

export default AssociationList;
