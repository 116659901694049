import { CALL_API } from '../../middlewares/api';

import {
  EDUCATORS_REQUEST,
  EDUCATORS_SUCCESS,
  EDUCATORS_FAILURE,
  EDUCATOR_GROUPS_REQUEST,
  EDUCATOR_GROUPS_SUCCESS,
  EDUCATOR_GROUPS_FAILURE,
  CREATE_EDUCATOR_GROUPS_REQUEST,
  CREATE_EDUCATOR_GROUPS_SUCCESS,
  CREATE_EDUCATOR_GROUPS_FAILURE,
  UPDATE_EDUCATOR_GROUP_REQUEST,
  UPDATE_EDUCATOR_GROUP_SUCCESS,
  UPDATE_EDUCATOR_GROUP_FAILURE,
  EDUCATOR_GROUPS_STATISTICS_REQUEST,
  EDUCATOR_GROUPS_STATISTICS_SUCCESS,
  EDUCATOR_GROUPS_STATISTICS_FAILURE,
  RESET_SUBMITTING_DUPLICATE_EDUCATOR_GROUPS,
  SET_SELECTED_EDUCATOR_GROUP
} from '../../constants';

export const getEducators = (endPoint=false) => {
  let endpoint = (endPoint) ? `${endPoint}&page_size=10` : 'institution/educators?page_size=10';

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        EDUCATORS_REQUEST,
        EDUCATORS_SUCCESS,
        EDUCATORS_FAILURE
      ],
    },
  };
};

export const getEducatorGroups = (
  isInitialRequest = false,
  urlParamString = ''
) => {
  let endpoint = 'user/groups';

  if (urlParamString.length !== 0) {
    endpoint = `${endpoint}${urlParamString}`;
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      isInitialRequest,
      authenticated: true,
      types: [
        EDUCATOR_GROUPS_REQUEST,
        EDUCATOR_GROUPS_SUCCESS,
        EDUCATOR_GROUPS_FAILURE,
      ],
    },
  };
};

export const getEducatorGroupsStatistics = (userId) => {
  const endpoint = `group/statistics/${userId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        EDUCATOR_GROUPS_STATISTICS_REQUEST,
        EDUCATOR_GROUPS_STATISTICS_SUCCESS,
        EDUCATOR_GROUPS_STATISTICS_FAILURE,
      ],
    },
  };
};

export const createEducatorGroups = (data) => {
  const endpoint = 'group/group';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        CREATE_EDUCATOR_GROUPS_REQUEST,
        CREATE_EDUCATOR_GROUPS_SUCCESS,
        CREATE_EDUCATOR_GROUPS_FAILURE,
      ],
    },
  };
};

export const updateEducatorGroup = (groupId, data) => {
  const endpoint = `group/group/${groupId}`;

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'patch',
      authenticated: true,
      types: [
        UPDATE_EDUCATOR_GROUP_REQUEST,
        UPDATE_EDUCATOR_GROUP_SUCCESS,
        UPDATE_EDUCATOR_GROUP_FAILURE,
      ],
    },
  };
};

export const setSelectedEducatorGroup = selectedGroup => ({
  type: SET_SELECTED_EDUCATOR_GROUP,
  selectedGroup,
});

export const resetSubmittingDuplicateEducatorGroups = () => ({
  type: RESET_SUBMITTING_DUPLICATE_EDUCATOR_GROUPS,
});
