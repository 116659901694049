import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class UserAvatarNameCard extends PureComponent {
  static propTypes = {
    profile: PropTypes.object.isRequired,
  };

  render() {
    const { profile } = this.props;

    return (
      <Link to={`/cp/${profile.vanity}`}>
        {profile.avatar && profile.avatar !== '' ? (
          <img
            className="profile-image large"
            src={profile.avatar}
            style={{
              objectFit: 'cover',
              backgroundSize: 'contain',
            }}
            alt={`${profile.name}'s Avatar`}/>
        ) : (
          <span
            style={{
              lineHeight: '50px',
              fontSize: 24
            }}
            className="header-image large initial-avatar">
            {profile.name && profile.name.slice(0, 1)}
          </span>
        )}
      </Link>
    );
  }
}

export default UserAvatarNameCard;
