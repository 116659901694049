import PropTypes from 'prop-types';
import React from 'react';

const options = [
  { text: 'Support Request'},
  { text: 'Sales Question'},
  { text: 'Feedback or Bug Report'},
  { text: 'Media Inquiry'},
  { text: 'Partnership Inquiry'},
  { text: 'Authorship Inquiry'},
  { text: 'Other Inquiry'},
];

const ContactForm = ({ contact, errors, onChange, onSubmit, isSubmitting }) => {
  return (
    <div className="col-lg-12 interior info terms-page">
      <div className="info-bg"/>
      <div style={{padding: 50}} className="content terms">
        <div className="info-title">
          <h2>Get in touch with CareerPrepped</h2>
        </div>
        <form className="contact-form" onSubmit={onSubmit}>
          <div className="row">
            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="" className="sr-only"/>
                <input
                  onChange={onChange}
                  name="name"
                  type="text"
                  value={contact.name}
                  className="form-control"
                  placeholder="Name" />
                {errors.name && <div className="text-danger">{errors.name}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="" className="sr-only"/>
                <input
                  onChange={onChange}
                  value={contact.email}
                  name="email"
                  type="text"
                  className="form-control"
                  placeholder="Email" />
                {errors.email && <div className="text-danger">{errors.email}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="" className="sr-only"/>
                <input
                  onChange={onChange}
                  type="text"
                  name="phoneNumber"
                  value={contact.phoneNumber}
                  className="form-control"
                  placeholder="Phone(Optional)" />
                {errors.phoneNumber && <div className="text-danger">{errors.phoneNumber}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="" className="sr-only"/>
                <select
                  name="subject"
                  value={contact.subject}
                  className="form-control"
                  onChange={onChange}>
                  <option value="">Please select</option>
                  {options.map((option, index) => {
                    return (
                      <option key={index} value={option.text}>
                        {option.text}
                      </option>
                    );
                  })}
                </select>
                {errors.subject && <div className="text-danger">{errors.subject}</div>}
              </div>
            </div>
            <div className="col-lg-8">
              <div className="form-group">
                <textarea
                  name="message"
                  rows="8"
                  cols="80"
                  onChange={onChange}
                  value={contact.message}
                  placeholder="Message"
                  className="form-control"/>
              </div>
              {errors.message && <div className="text-danger">{errors.message}</div>}
            </div>
            <div className="col-lg-12">
              <button
                type="submit"
                className="btn btn-primary float-right">
                {isSubmitting ? 'Submitting ....': 'Submit'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

ContactForm.propTypes = {
  contact: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default ContactForm;
