import PropTypes from 'prop-types';
import React from 'react';
import Loading from '../../../components/common/Loading';

const WrapperComponent = (props) => {
  const {
    isLoaderInternal,
    callingAPI,
    loaderStyles,
    className,
    loaderClassName
  } = props;

  if (isLoaderInternal)
    return <props.component {...props} callingAPI={callingAPI}/>;

  return(
    <div className={className}>
      <props.component {...props}/>

      {callingAPI &&
        <div className="row">
          <div
            style={Object.assign({}, { paddingBottom: 50 }, loaderStyles)}
            className={loaderClassName}>
            <Loading />
          </div>
        </div>}
    </div>
  );
};

WrapperComponent.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ]),
  callingAPI: PropTypes.bool,
  isLoaderInternal: PropTypes.bool,
  loaderStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  className: PropTypes.string,
  loaderClassName: PropTypes.string,
  loaderStyles: PropTypes.object
};

export default WrapperComponent;
