import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { checkHttp } from '../../utils';
import Parser from 'html-react-parser';

const ResumePublications = ({ resumePublications }) => {
  return (
    <div className="resume-section">
      <div className="resume-title">
        {resumePublications.title !== '' ? resumePublications.title : 'Publications'}
      </div>
      {resumePublications.publicationsArray.map((publication) => {
        const isAuthorAvailable = publication.author !== '';
        const isPubDateObject = typeof publication.pubdate === 'object';
        const pubDateString = isPubDateObject ? publication.pubdate.date :
          publication.pubdate === '' ? undefined : publication.pubdate;
        const date = moment(pubDateString).format('MM/YYYY');

        const urlAvailable = publication.puburl !== '';
        const urlString = checkHttp(publication.puburl) ?
          publication.puburl : `//${publication.puburl}`;

        return(
          <div key={publication.id} style={{marginBottom: 10}}>
            <span><b>{publication.title}</b> - {!urlAvailable ?
              publication.publication :
              <a href={urlString} target="_blank" rel="noopener noreferrer">{publication.publication}</a>}
            </span>
            <span style={{marginLeft: 10}}>({date})</span>
            {isAuthorAvailable && <p><b>Author(s):</b> {publication.author}</p>}
            {Parser(publication.description)}
          </div>
        );
      })}
    </div>
  );
};

ResumePublications.propTypes = {
  resumePublications: PropTypes.object.isRequired,
};

export default ResumePublications;
