import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'lodash';
import { Breadcrumb, BreadcrumbItem, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import { withHooks } from '../../utils/withHooks';

import {
  getfeedbackQuestions,
  getFeedbackData,
  postFeedbackData,
  editFeedbackData,
  getFeedbackStatusByRequestId,
  resetFeedbackData,
} from '../../redux/actions/givefeedback';
import {getResourceData} from '../../redux/actions/badges';
import * as orgInvitationActions from '../../redux/actions/organization-invitations';
import {unitSkillRequest,resetSkillRequest} from '../../redux/actions/skills';
import {requestBadgeAssertion} from '../../redux/actions/badge-assertion';

import QuestionResponses from '../../components/give-feedback/QuestionResponses';
import PortfolioEvidences from '../../components/give-feedback/PortfolioEvidences';
import EndorsementFeedback from '../../components/give-feedback/EndorsementFeedback';
import Message from '../../components/give-feedback/Message';
import ConfirmSubmit from '../../components/give-feedback/confirmsubmit';
import ConfirmModal from '../../components/portfolio/ConfirmModal';
import Relationship from '../../components/give-feedback/Relationship';

import CreatePasswordForPeer from './CreatePasswordForPeer';
import Loading from '../../components/common/Loading';
import { userLogout } from '../../redux/actions/authentication';
import { redirect } from '../../redux/actions/router';

class GiveFeedbackWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step0: true,
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      relation: this.props.relation,
      questionResponseRating: {},
      portfolioEvidenceRating: {},
      endorsement: {},
      dataSubmit: false,
      confirmSubmit: false,
      showConfirmationModal: false,
      prompt : true,
      resourceFlag : false,
      showCompleteAlert: false,
      showCancelAlert: true,
    };

    this.toggleConfirmationModal = this.toggleConfirmationModal.bind(this);
    this.proceed = this.proceed.bind(this);
    this.setFeedbackData = this.setFeedbackData.bind(this);
  }

  componentWillMount() {
    const {actions, params, location} = this.props;
    window.localStorage.removeItem('landingPath');
    actions.getFeedbackStatusByRequestId(
      params?.feedbackRequestID
    ).then((response) => {
      const feedbackUserDetail = response.givefeedback.getFeedbackUserDetail;
      if(this.props.isAuthenticated && feedbackUserDetail && (feedbackUserDetail?.giverName !== this.props.name)) {
        this.props.actions.userLogout();
        this.props.actions.resetFeedbackData();
        this.props.actions.resetSkillRequest();
        this.setState({prompt: false});
        window.onbeforeunload = () => {
          return '';
        };
        this.props.navigate('/');
        window.location.href = location.pathname;
      } else if(this.props.isAuthenticated) {
        this.setFeedbackData();
        window.onbeforeunload = () => {
          return 'Changes you made might get lost!';
        };
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ relation: nextProps.relation });

    if (this.state.step0 && nextProps.profile.role === 'industry-representative') {
      this.proceed('step1');
    }

    if (
      Object.prototype.hasOwnProperty.call(
        nextProps.feedbackData,
        'feedbackRequestId'
      ) &&
      nextProps.feedbackQuestions?.likert?.length > 0
    ) {
      if(isEmpty(nextProps.location.search)
        && nextProps.feedbackData.feedbackstatus === 'completed'){
        this.setState({showCompleteAlert: true});
      }

      if ( isEmpty(nextProps.location.search) && nextProps.feedbackData.feedbackstatus === 'canceled' ) {
        window.location.href = '/home';
      }

      this.setData(
        nextProps.feedbackData,
        'questionResponseRating',
        nextProps.feedbackQuestions
      );
    }
    if (
      Object.prototype.hasOwnProperty.call(
        nextProps.feedbackData,
        'feedbackRequestId'
      ) &&
      nextProps?.portfolioQuestions?.likert?.length > 0
    ) {
      this.setData(
        nextProps.feedbackData,
        'portfolioEvidenceRating',
        nextProps.portfolioQuestions
      );
    }

    if (nextProps.isSubmitting === 'submitted' && this.state.dataSubmit) {
      this.setState({
        confirmSubmit: !this.state.confirmSubmit,
        dataSubmit: false,
      });
      this.proceed('step4');
    }

    if(nextProps.feedbackData?.resourceType === 'skill' && !this.state.resourceFlag){
      this.props.actions.unitSkillRequest(this.props.isAuthenticated, nextProps.feedbackData?.resourceId);
      this.setState({resourceFlag: true});
    }
    if(nextProps.feedbackData?.resourceType === 'badge' && !this.state.resourceFlag){
      this.props.actions.getResourceData(nextProps.feedbackData?.resourceId);
      this.props.actions.requestBadgeAssertion(nextProps.feedbackData?.resourceId, this.props.isAuthenticated);
      this.setState({resourceFlag: true});
    }

    if(!this.props.isAuthenticated && !this.state.relationModal && nextProps.feedbackUserDetail?.giverExists === '1' && nextProps.feedbackUserDetail?.status != 'canceled') {
      this.showMessageForNotLoggedInUser(nextProps.feedbackUserDetail);
    }
  }

  componentWillUnmount() {
    this.props.actions.resetFeedbackData();
    this.props.actions.resetSkillRequest();
    window.onbeforeunload = () => {
      return '';
    };
  }

  setFeedbackData(){
    const {isAuthenticated, actions, params} = this.props;
    if(isAuthenticated){
      actions.getFeedbackData(params?.feedbackRequestID);
      actions.getFeedbackStatusByRequestId(params?.feedbackRequestID);
      actions.getfeedbackQuestions(
        params?.feedbackRequestID,
        params
      );
    }
  }

  setData = (feedbackData, param1, questions) => {
    const jsonData = {};
    jsonData['like'] = questions.likert?.find(
      todo => todo.answerText === feedbackData[param1]?.overall
    )?.answerId;
    const multiChoiceArr = feedbackData[param1]?.multichoiceComments?.replace('key,','key')?.split(
      ','
    );
    jsonData['multiOption'] = questions.multichoice
      ?.filter(item => multiChoiceArr?.includes(item.answerText?.replace('key,','key')))
      .map(data => data.answerId);
    jsonData['additionalComment'] = feedbackData[param1]?.additionalComment;
    if(param1 === 'portfolioEvidenceRating'){
      (jsonData.like) ? this.setState({ [param1]: jsonData }) : this.setState({ [param1]: jsonData });
    } else {
      this.setState({ [param1]: jsonData });
    }
  };

  toggleConfirmationModal() {
    this.setState((state) => {
      return {
        confirmSubmit: !state.confirmSubmit,
        prompt: false
      };
    });
  }

  proceed(step) {
    let arr = ['step0', 'step1', 'step2', 'step3', 'step4'];

    this.setState((state) => {
      const d = {};
      arr.forEach((a) => {
        if (a === step) {
          d[a] = !state[step];
        } else {
          d[a] = false;
        }
      });
      return d;
    });
  }

  stepProgressBar(feedbackData, portfolioQuestions) {
    let step0 = '';
    let step1 = '';
    let step2 = '';
    let step0Text = '';
    let step1Text = '';
    let step2Text = '';
    let step3Text = '';

    const steps = feedbackData?.resourceType === 'skill' && !this.state.step3 && !this.state.step0 ? 'step2' : 'step1';

    if (this.state.step0) {
      step0 = 'completed';
      step0Text = 'activeText';
    }

    if (this.props.profile.role === 'industry-representative') {
      step0 = 'completed';
      step0Text = 'activeText';
      step1 = 'completed';
      step1Text = feedbackData?.resourceType === 'badge' ? 'activeText' : '';
    }

    if (this.state.step1 && feedbackData?.resourceType === 'badge') {
      step0 = this.state.relation.length > 0 ? 'completed' : '';
      step1 = 'completed';
      step0Text = 'activeText';
      step1Text = feedbackData?.resourceType === 'badge' ? 'activeText' : '';
    }
    if ((portfolioQuestions?.likert?.length > 0 && !this.state.step1 && !this.state.step0) || steps === 'step2') {
      step0 = this.state.relation.length > 0 ? 'completed' : '';
      step1 = feedbackData?.resourceType === 'badge' ? 'completed' : '';
      step2 = 'completed';
      step0Text = 'activeText';
      step1Text = 'activeText';
      step2Text = feedbackData?.resourceType === 'badge' ? 'activeText' : '';
    }
    if (this.state.step3) {
      step0 = this.state.relation.length > 0 ? 'completed' : '';
      step1 = feedbackData?.resourceType === 'badge' ? 'completed' : '';
      step2 = portfolioQuestions?.likert?.length > 0 ? 'completed' : '';
      step0Text = 'activeText';
      step1Text = feedbackData?.resourceType === 'badge' ? 'activeText' : '';
      step2Text = portfolioQuestions?.likert?.length > 0 ? 'activeText' : '';
      step3Text = 'activeText';
    }
    return (
      <div id="stepProgressBar" className="stepper-wrapper">
        {this.props.profile.role !== 'industry-representative' &&
          <div className={`stepper-item ${step0}`}>
            <div className="step-counter"/>
            <p className={`step-name ${step0Text}`}>Relationship</p>
          </div>}
        {feedbackData?.resourceType === 'badge' && (
          <div className={`stepper-item ${step1}`}>
            <div className="step-counter"/>
            <p className={`step-name ${step1Text}`}>Question Responses</p>
          </div>
        )}
        {portfolioQuestions?.likert?.length > 0 && (
          <div className={`stepper-item ${step2}`}>
            <div className="step-counter"/>
            <p className={`step-name ${step2Text}`}>Portfolio Evidence</p>
          </div>
        )}
        {feedbackData?.feedbackstatus === 'pending' && (
          <div className="stepper-item">
            <div className="step-counter"/>
            <p className={`step-name ${step3Text}`}>Endorsements</p>
          </div>
        )}
      </div>
    );
  }

  saveFeedBack = (state, feedbackData) => {
    this.setState({ [state]: feedbackData });
  };

  postFeedback = () => {
    const body = {};
    const { actions, feedbackData, feedbackQuestions, portfolioQuestions } = this.props;
    const { relation, questionResponseRating, portfolioEvidenceRating } = this.state;
    if (this.state.questionResponseRating && questionResponseRating.multiOption?.length > 0 && questionResponseRating.like) {
      body[feedbackQuestions.commentQuestionId] =
        questionResponseRating.additionalComment;
      body[feedbackQuestions.likeQuestionId] = questionResponseRating.like;
      body[feedbackQuestions.multiChoiceQuestionId] =
        questionResponseRating.multiOption;
    }
    if (this.state.portfolioEvidenceRating) {
      body[portfolioQuestions.commentQuestionId] =
        portfolioEvidenceRating?.additionalComment;
      body[portfolioQuestions.likeQuestionId] = portfolioEvidenceRating?.like;
      body[portfolioQuestions.multiChoiceQuestionId] =
        portfolioEvidenceRating?.multiOption;
    }

    const data = {
      relationId: relation,
      feedbackRequestId: this.props.params.feedbackRequestID,
      responseDetails: { ...body },
      ...this.state.endorsement,
    };

    feedbackData?.feedbackstatus === 'pending' && feedbackData?.feedbackResponseDate === '' ?
      actions.postFeedbackData(data) :
      actions.editFeedbackData(data);

    this.setState({ dataSubmit: true });
  };

  onDismiss = () => {
    this.setState({showCompleteAlert: false});
    window.location.href = '/myfeedback';
  }

  onCloseModal = () => {
    this.setState({showCancelAlert: false});
  }

  openSignInModal = () => {
    this.setState({showCancelAlert: false});
    document.getElementsByClassName('clickable')[0].click();
  }

  onNotSignInCloseModal = (feedbackUserDetail) => {
    this.setState({showCancelAlert: false});
    const {navigate, location} = this.props;
    if ( feedbackUserDetail.giverExists === '1' ) {
      window.localStorage.setItem('landingPath', location.pathname);
      navigate('/');
    }
  }

  getAlertText = () => this.props.feedbackData.feedbackstatus === 'completed' ?
    <p>You have already given the feedback. If you’d like to revise it, you can see a history of your given feedback and choose to revise any feedback already given in the <a href="/myfeedback">“Given”</a> section of your “My Feedback” page</p>
    : <p>Thank you for your willingness to provide feedback, but the requester has cancelled this request.</p>

  getAlertTitle = () => this.props.feedbackData.feedbackstatus === 'completed' ? 'Completed feedback' : 'Feedback Request Canceled';

  getCanceledText = (giverName, requesterName) => (<>
    <p className="mb-0">{`Hi ${giverName}!`}</p>
    <p>{`${requesterName} has cancelled this request. This may be because ${requesterName} is not yet ready for feedback after all. However, you’re welcomed to join the CareerPrepped community to advance your career and get feedback on your skills`}</p>
  </>)

  getAlertForCancelledRequest = (feedbackUserDetail) => {
    const giverName = feedbackUserDetail.giverName.split(' ')[0];
    const requesterName = feedbackUserDetail.requesterName.split(' ')[0];
    const headerText = 'Feedback Request Canceled';

    return (
      <ConfirmModal
        isOpen={this.state.showCancelAlert}
        confirminginProgressText={'OK'}
        modalHeaderText={headerText}
        modalMessage=""
        modalMessageParam={this.getCanceledText(giverName, requesterName)}
        modalFooterButtonText={'Close'}
        onCancel={this.onCloseModal}
        onConfirm={this.onCloseModal}
        onlyConfirmButton/>
    );
  }

  showMessageForNotLoggedInUser = (feedbackUserDetail) => {
    this.onNotSignInCloseModal(feedbackUserDetail);
  }

  showModalForExistingUserCancelledReq = () => {
    return (
      <ConfirmModal
        isOpen={this.state.showCancelAlert}
        confirminginProgressText={'OK'}
        modalHeaderText={this.getAlertTitle()}
        modalMessage=""
        modalMessageParam={this.getAlertText()}
        modalFooterButtonText={'Close'}
        onCancel={this.onCloseModal}
        onConfirm={this.onCloseModal}
        onlyConfirmButton/>
    );
  }

  render() {
    const { feedbackData, portfolioQuestions, isSubmitting, resourceData, isAuthenticated, feedbackUserDetail } = this.props;
    const name = feedbackData.otherUserFullName;
    const steps = feedbackData?.resourceType === 'skill' && !this.state.step3 && !this.state.step0 ? 'step2' : 'step1';

    const imageLink = feedbackData?.resourceType === 'skill' ? resourceData.url : resourceData?.badge?.image ;
    const defaultImageLink = feedbackData?.resourceType === 'skill' ? '../../assets/images/temp/skill.png' : '../../assets/images/temp/badge.png';
    if((isAuthenticated && isEmpty(resourceData)) || (isEmpty(feedbackData)) && isEmpty(feedbackUserDetail)){
      return (<div className="container giveFeedbackContainerStyle pt-5">
        <Loading />
      </div>);
    }

    return (
      <div className="container giveFeedbackContainerStyle py-1">
        {!isAuthenticated && !this.state.relationModal && feedbackUserDetail?.giverExists === '1' && feedbackUserDetail?.status === 'canceled' && (
          this.showModalForExistingUserCancelledReq()
        )}
        {!isAuthenticated &&
          !this.state.relationModal && feedbackUserDetail?.giverExists=== '0' &&
          (
            <>
              <CreatePasswordForPeer
                feedbackUserDetail={feedbackUserDetail}
                setFeedbackData={this.setFeedbackData}/>
              {feedbackUserDetail?.status == 'canceled' && this.getAlertForCancelledRequest(feedbackUserDetail)}
            </>
          )
        }
        {isAuthenticated && <>
          <ConfirmModal
            isOpen={this.state.showCompleteAlert}
            confirminginProgressText={'OK'}
            modalHeaderText={this.getAlertTitle()}
            modalMessage=""
            modalMessageParam={this.getAlertText()}
            modalFooterButtonText={'Close'}
            onCancel={this.onDismiss}
            onConfirm={this.onDismiss}
            onlyConfirmButton/>
          <div className="mt-1">
            <Prompt
              when={this.state.prompt}
              message="Are you sure you want to leave this page before you finish? Any progress you’ve made will be lost."/>
            <Breadcrumb>
              <BreadcrumbItem active>
                {feedbackData.resourcename}
              </BreadcrumbItem>
            </Breadcrumb>
          </div>
          <div className="container pb-4 container-border">
            <Row className="bluebox">
              <Col md="4" className="darkblue py-2">
                {
                  feedbackData?.resourceType !== 'skill' &&
                  <div className="darkBlueImage py-3">
                    <img width="80" src={imageLink ? imageLink : defaultImageLink} />
                  </div>
                }
                <div className="darkBlueText">
                  <strong>{feedbackData.resourcename}</strong>
                </div>
              </Col>
              <Col md="8" className="lightblue py-3">
                <p>
                  <strong>Give your feedback to help {name}</strong>
                </p>
                <span className="font14">
                  {name} claims to have the skill {feedbackData.resourcename}.
                  Please review the evidence below. Your feedback will help{' '}
                  {name} improve how well this claimed skill is being proven.
                </span>
              </Col>
            </Row>
            {(this.state.step0 ||
              this.state.step1 ||
              this.state.step2 ||
              this.state.step3 ||
              steps === 'step2') &&
              !this.state.step4 &&
              this.stepProgressBar(feedbackData, portfolioQuestions)}

            {this.state.step0 && (this.props.profile.role !== 'industry-representative') &&
              <Relationship
                proceed={this.proceed}
                feedbackResponse={this.saveFeedBack}
                feedbackData={this.state.relation}/>}

            {this.state.step1 &&
              feedbackData?.resourceType === 'badge' && resourceData?.badge?.id &&
              ((feedbackData?.feedbackstatus === 'completed' && this.state.questionResponseRating?.like)
                || (feedbackData?.feedbackstatus === 'pending' && this.state.questionResponseRating)
                || feedbackData?.feedbackstatus === 'canceled'
              )
              && (
                <QuestionResponses
                  feedbackData={this.state.questionResponseRating}
                  resourceData={resourceData}
                  name={name}
                  feedbackResponse={this.saveFeedBack}
                  status={feedbackData?.feedbackstatus}
                  questions={this.props.feedbackQuestions}
                  toggleConfirmationModal={this.toggleConfirmationModal}
                  step2={portfolioQuestions?.likert?.length > 0}
                  proceed={this.proceed} />
              )}
            {(this.state.step2 || steps === 'step2') &&
              isSubmitting !== 'submitted' &&
              feedbackData &&
              ((feedbackData?.feedbackstatus === 'completed' && this.state.portfolioEvidenceRating)
              || (feedbackData?.feedbackstatus === 'pending' && this.state.portfolioEvidenceRating)
              || feedbackData?.feedbackstatus === 'canceled'
              )
              &&
              ((feedbackData?.resourceType === 'badge' && resourceData.files?.length > 0) || feedbackData?.resourceType === 'skill')
              && portfolioQuestions?.likert?.length > 0 && (
              <PortfolioEvidences
                portfolioEvidenceRating={this.state.portfolioEvidenceRating}
                resourceData={resourceData}
                name={name}
                feedbackData={feedbackData}
                feedbackResponse={this.saveFeedBack}
                questions={portfolioQuestions}
                proceed={this.proceed}
                toggleConfirmationModal={this.toggleConfirmationModal} />
            )}
            {this.state.step3 && (
              <EndorsementFeedback
                feedbackData={feedbackData}
                name={name}
                profile={this.props.profile}
                feedbackResponse={this.saveFeedBack}
                proceed={this.proceed}
                step={portfolioQuestions?.likert.length > 0}
                toggleConfirmationModal={this.toggleConfirmationModal} />
            )}
            {this.state.confirmSubmit && (
              <ConfirmSubmit
                isOpen={this.state.confirmSubmit}
                toggleModal={this.toggleConfirmationModal}
                saveFeedback={this.postFeedback}
                status={feedbackData?.feedbackstatus}
                proceed={this.proceed}
                name={name}
                submitRequest={this.state.dataSubmit} />
            )}
            {this.state.step4 && (
              <Message
                status={feedbackData?.feedbackstatus}
                name={name} />
            )}

            {/* {this.state.showConfirmationeModal && (
              <ConfirmationModal
                onCancel={this.onCloseModal}
                leavePage={this.handleLeavePage}
                isOpen={this.state.showConfirmationeModal} />
            )}  */}

          </div>
        </>}
      </div>
    );
  }
}

GiveFeedbackWrapper.propTypes = {
  feedback: PropTypes.object,
  actions: PropTypes.object.isRequired,
  isSubmitting: PropTypes.string.isRequired,
  feedbackData: PropTypes.object.isRequired,
  feedbackQuestions: PropTypes.object.isRequired,
  portfolioQuestions: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  resourceData: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object,
  navigate: PropTypes.func,
  router: PropTypes.object,
  feedbackUserDetail: PropTypes.object,
  setFeedbackData: PropTypes.func,
  name: PropTypes.string,
  relation: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getfeedbackQuestions,
      getFeedbackData,
      postFeedbackData,
      editFeedbackData,
      getResourceData,
      resetFeedbackData,
      unitSkillRequest,
      resetSkillRequest,
      getFeedbackStatusByRequestId,
      requestBadgeAssertion,
      orgInvitationActions,
      userLogout,
      redirect,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => {
  const resources= state.givefeedback.data?.feedbackData?.resourceType === 'skill' ?
    state.skills.unitSkill.data : state.badgeAssertion?.data;

  const relation = state.givefeedback.getFeedbackUserDetail.relation ?
    state.givefeedback.getFeedbackUserDetail.relation : '';

  return {
    relation,
    name: state.profile.data?.name,
    profile: state.profile.data,
    feedbackQuestions: state.givefeedback.data.feedbackQuestions,
    portfolioQuestions: state.givefeedback.data.portfolioQuestions,
    feedbackData: state.givefeedback.data.feedbackData,
    isSubmitting: state.givefeedback.isSubmitting,
    isAuthenticated: state.auth.isAuthenticated,
    feedbackUserDetail: state.givefeedback.getFeedbackUserDetail,
    resourceData: resources
  };
};

export default withHooks(
  connect(mapStateToProps, mapDispatchToProps)(GiveFeedbackWrapper)
);
