import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import moment from 'moment';
import { Helmet } from 'react-helmet';
import { parseJSON, difference } from '../../utils';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _reject from 'lodash/reject';
import _omit from 'lodash/omit';
import _isNull from 'lodash/isNull';
import { toastr } from 'react-redux-toastr';

import { withHooks } from '../../utils/withHooks';
import { Prompt } from 'react-router-dom';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

import * as authenticationActions from '../../redux/actions/authentication';
import * as routerActions from '../../redux/actions/router';
import * as clubActions from '../../redux/actions/clubs';
import * as awardActions from '../../redux/actions/awards';
import * as skillActions from '../../redux/actions/skills';
import * as profileActions from '../../redux/actions/profile';
import * as projectActions from '../../redux/actions/projects';
import * as educationActions from '../../redux/actions/education';
import * as associationActions from '../../redux/actions/associations';
import * as componentActions from '../../redux/actions/components';
import * as experienceActions from '../../redux/actions/experience';
import * as publicationActions from '../../redux/actions/publications';
import * as certificationActions from '../../redux/actions/certification';
import * as resumeActions from '../../redux/actions/resume';
import { getMyCareerPreppedStatus } from '../../redux/actions/my-careerprepped-status';

import CreateResumeComponent from '../../components/resume/CreateResumeComponent';
import PreviewResumeModal from '../../components/resume/PreviewResumeModal';
import UnsavedChangesModal from '../../components/common/UnsavedChangesModal';
import Loading from '../../components/common/Loading';
import SortSectionsModal from '../profile/sections/SortSectionsModal';
import DeleteSectionConfirmModal from '../../components/resume/DeleteSectionConfirmModal';

class CreateResume extends Component {
  static propTypes = {
    forms: PropTypes.array.isRequired,
    skills: PropTypes.object.isRequired,
    awards: PropTypes.object.isRequired,
    education: PropTypes.object.isRequired,
    projects: PropTypes.object.isRequired,
    certifications: PropTypes.object.isRequired,
    experiences: PropTypes.object.isRequired,
    publications: PropTypes.object.isRequired,
    associations: PropTypes.object.isRequired,
    clubs: PropTypes.object.isRequired,
    resumeOrder: PropTypes.array.isRequired,
    resume: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    modal: PropTypes.string,
    isSaving: PropTypes.bool,
    resumeInStore: PropTypes.array,
    isHookedToARoute: PropTypes.object.isRequired,
    storedAction: PropTypes.string,
    navigate: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      isResumeSaved: false,
      isEditUsernameSection: false,
      backHasBeenClicked: false,
      resumeOrder: this.props.resumeOrder,
      removeResumeSectionId: null,
      showCustomDialog: false,
      nextLocation: null,
      resume: Object.assign({}, this.props.resume)
    };

    this.onBack = this.onBack.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onSaveConfirm = this.onSaveConfirm.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeSection = this.onChangeSection.bind(this);
    this.onChangeSectionTitle = this.onChangeSectionTitle.bind(this);
    this.onLoadForm = this.onLoadForm.bind(this);
    this.onUnloadForm = this.onUnloadForm.bind(this);
    this.onBackConfirm = this.onBackConfirm.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onChangeDetails = this.onChangeDetails.bind(this);
    this.onTogglePreview = this.onTogglePreview.bind(this);
    this.onRearrangeResumeOrder = this.onRearrangeResumeOrder.bind(this);
    this.initializeState = this.initializeState.bind(this);
    this.onCancelModal = this.onCancelModal.bind(this);
    this.onSortClick = this.onSortClick.bind(this);
    this.routerWillLeave = this.routerWillLeave.bind(this);
    this.closeCustomDialog = this.closeCustomDialog.bind(this);
    this.onConfirmLeaveRoute = this.onConfirmLeaveRoute.bind(this);
    this.onRemoveResumeSection = this.onRemoveResumeSection.bind(this);
    this.onRemoveResumeSectionCancel = this.onRemoveResumeSectionCancel.bind(this);
    this.onRemoveResumeSectionConfirm = this.onRemoveResumeSectionConfirm.bind(this);
  }

  componentDidMount() {
    const { currentUser } = this.props;

    const { id } = currentUser;
    if (id) {
      const {
        clubsRequest,
        awardsRequest,
        skillsRequest,
        profileRequest,
        projectsRequest,
        educationRequest,
        experienceRequest,
        publicationsRequest,
        associationsRequest,
        certificationRequest,
        setRouterHookedToARoute
      } = this.props.actions;

      setRouterHookedToARoute('create-resume');
      profileRequest(id);
      clubsRequest(id);
      awardsRequest(id);
      skillsRequest(id);
      projectsRequest(id);
      educationRequest(id);
      experienceRequest(id);
      associationsRequest(id);
      publicationsRequest(id);
      certificationRequest(id);

      this.props.actions.loadForm('editUsernameForm');
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isSaving } = nextProps;
    const { isSaving: isCurrentlySaving } = this.props;
    const hasChangedOrder = difference(nextProps.resumeOrder, this.props.resumeOrder);

    if(! _isEmpty(hasChangedOrder)){
      this.setState({ resumeOrder: nextProps.resumeOrder });
    }

    if (! _isEqual(nextProps.resume, this.props.resume)) {
      this.setState({ resume: nextProps.resume });
    }

    if(!isSaving && isCurrentlySaving && isCurrentlySaving !== isSaving){
      this.initializeState();
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = () => {
      return null;
    };
    this.props.actions.removeRouterHookedToARoute();
  }

  routerWillLeave(nextLocation) {
    const { backHasBeenClicked, nextLocation: nextLocationInState, isResumeSaved } = this.state;
    const { resume } = this.props;

    const hasAllRequiredSection = resume.summary.summary !== '' && resume.education.educationArray.length > 0 &&
    resume.skills.skillsArray.length > 0 && resume.experience.experienceArray.length > 0;


    if (!isResumeSaved && !backHasBeenClicked && hasAllRequiredSection && nextLocationInState === null) {

      this.setState({
        showCustomDialog: true,
        nextLocation: nextLocation.pathname
      });
      return false;
    }else{
      this.setState({
        nextLocation: null
      });
      return true;
    }
  }

  closeCustomDialog(){
    this.setState({
      showCustomDialog: false,
      nextLocation: null
    }, () => {
      if(this.props.storedAction !== null){
        this.props.actions.clearAllActionsStored();
      }
    });
  }

  onConfirmLeaveRoute(){
    const { actions, storedAction } = this.props;
    const { nextLocation } = this.state;
    this.setState({
      showCustomDialog: false
    },() => {
      actions.removeRouterHookedToARoute();
      if(storedAction === 'userLogout'){
        actions.userLogout();
      }
      actions.redirect(nextLocation);
      actions.clearAllActionsStored();
    });
  }

  initializeState() {
    this.setState({
      errors: {},
      isEditUsernameSection: false,
      resume: Object.assign({}, this.props.resume),
      resumeOrder: defaultOrder,
      resumeTitle: '',
    },() => this.props.actions.closeModal());
  }

  onBack() {
    const { backHasBeenClicked, isResumeSaved } = this.state;
    const { actions, navigate } = this.props;

    this.setState({
      backHasBeenClicked: !backHasBeenClicked ? !backHasBeenClicked: backHasBeenClicked
    }, () => {
      if(!isResumeSaved){
        return actions.openModal('unsaved_changes_modal');
      }

      if(isResumeSaved){
        navigate('/toolbox/resume');
        return actions.redirect('/toolbox/resume');
      }
    });
  }

  onBackConfirm(){
    this.props.navigate('/toolbox/resume');
    this.props.actions.redirect('/toolbox/resume');
    this.props.actions.closeModal();
  }

  onCloseModal(){
    this.setState({ backHasBeenClicked : false},() => this.props.actions.closeModal());
  }

  onCancelModal(){
    this.props.navigate('/toolbox/resume');
    this.props.actions.redirect('/toolbox/resume');
  }

  onRemoveResumeSection(event) {
    const section = event.currentTarget.id;

    this.setState({ removeResumeSectionId: section });
  }

  onRemoveResumeSectionCancel() {
    this.setState({ removeResumeSectionId: null });
  }

  onRemoveResumeSectionConfirm() {
    const { removeResumeSectionId } = this.state;

    let { resume, resumeOrder } = this.state;

    switch (removeResumeSectionId) {
    case 'Association':
      resume = _omit(resume, 'associations');
      resumeOrder = _reject(resumeOrder, { name: 'Association' });
      break;

    case 'Project':
      resume = _omit(resume, 'projects');
      resumeOrder = _reject(resumeOrder, { name: 'Project' });
      break;

    case 'Publication':
      resume = _omit(resume, 'publications');
      resumeOrder = _reject(resumeOrder, { name: 'Publication' });
      break;

    case 'Certification':
      resume = _omit(resume, 'certifications');
      resumeOrder = _reject(resumeOrder, { name: 'Certification' });
      break;

    case 'Award':
      resume = _omit(resume, 'awards');
      resumeOrder = _reject(resumeOrder, { name: 'Award' });
      break;

    default:
      resume = _omit(resume, 'clubs');
      resumeOrder = _reject(resumeOrder, { name: 'Club' });
      break;
    }

    this.setState({resume, resumeOrder});
    this.onRemoveResumeSectionCancel();
  }

  onSave() {
    const { resume } = this.state;

    if (resume.title === '') {
      return toastr.error('Resume title cannot be empty.');
    }

    if (resume.name === '') {
      return toastr.error('Please fill out and save all contact details before trying to save your resume.');
    }

    if (resume.city === '') {
      return toastr.error('Please fill out and save all contact details before trying to save your resume.');
    }

    if (resume.state === '') {
      return toastr.error('Please fill out and save all contact details before trying to save your resume.');
    }

    if (resume.email === '') {
      return toastr.error('Please fill out and save all contact details before trying to save your resume.');
    }

    if (resume.phone === '') {
      return toastr.error('Please fill out and save all contact details before trying to save your resume.');
    }

    if (resume.vanity === '') {
      return toastr.error('Please fill out and save all contact details before trying to save your resume.');
    }

    this.props.actions.openModal('save-confirm-modal');
  }

  getAwards(resume) {
    return resume.awards.awardsArray.map((award) => {
      const isDateObject = typeof award.date === 'object';
      const dateString = isDateObject ? award.date.date :
        award.date === '' ? undefined : award.date;

      const date = moment(dateString).format('MM/YYYY');

      const description = (award.description !== '') ? award.description : '';

      let awardString = `<p><b>${award.title}</b> - ${award.issuer}&emsp;(${date})</p>${description}`;

      if(award.url !== ''){
        awardString = `<p><b>${award.title}</b> - <a href='${award.url}' target='_blank'>${award.issuer}</a>&emsp;(${date})</p>${description}`;
      }

      return {awardString};
    });
  }

  getEducation(resume) {
    return resume.education.educationArray.map((education) => {
      const isStartDateObject = typeof education.startdate === 'object';
      const startDateString = isStartDateObject ? education.startdate.date :
        education.startdate === '' ? undefined : education.startdate;

      const isEndDateObject = typeof education.enddate === 'object';
      const endDateString = isEndDateObject ? education.enddate.date :
        education.enddate === '' ? undefined : education.enddate;

      const startDate = moment(startDateString).format('MM/YYYY');
      let endDate;

      if (education.noend) {
        endDate = 'Present';
      } else {
        endDate = moment(endDateString).format('MM/YYYY');
      }

      const gpa = (education.gpa.length !== 0) ? `<br/><b>GPA</b>: ${education.gpa}` : '';

      let educationString = `<p><b>${education.program}: ${education.major}</b> &#x2010; ${education.institution}&emsp;(${startDate} &#x2010; ${endDate})${gpa}</p>`;

      if (education.insurl !== '') {
        educationString = `<p><b>${education.program}: ${education.major}</b> &#x2010; <a href='${education.insurl}' target='_blank'>${education.institution}</a>&emsp;(${startDate} &#x2010; ${endDate})${gpa}</p>`;
      }

      return {educationString};
    });
  }

  getExperiences(resume) {
    return resume.experience.experienceArray.map((experience) => {
      const isStartDateObject = typeof experience.startdate === 'object';
      const startDateString = isStartDateObject ? experience.startdate.date :
        experience.startdate === '' ? undefined : experience.startdate;

      const isEndDateObject = typeof experience.enddate === 'object';
      const endDateString = isEndDateObject ? experience.enddate.date :
        experience.enddate === '' ? undefined : experience.enddate;

      const startDate = moment(startDateString).format('MM/YYYY');
      let endDate;

      if (experience.noend) {
        endDate = 'Present';
      } else {
        endDate = moment(endDateString).format('MM/YYYY');
      }

      const description = (experience.description !== '') ? experience.description : '';

      let experienceString = `<p>${experience.orgname}&emsp;(${startDate} - ${endDate})<br/><b>${experience.title}</b></p>${description}`;

      if (experience.orgurl !== '') {
        experienceString = `<p><a href='${experience.orgurl}' target='_blank'>${experience.orgname}</a>&emsp;(${startDate} - ${endDate})<br/><b>${experience.title}</b></p>${description}`;
      }

      return {experienceString};
    });
  }

  getClubs(resume) {
    return resume.clubs.clubsArray.map((club) => {
      const description = (club.description !== '') ? club.description : '';

      let clubString = `<p><b>${club.name}</b></p>${description}`;

      return {clubString};
    });
  }

  getPublications(resume) {
    return resume.publications.publicationsArray.map((publication) => {
      const isDateObject = typeof publication.date === 'object';
      const dateString = isDateObject ? publication.date.date :
        publication.date === '' ? undefined : publication.date;

      const date = moment(dateString).format('MM/YYYY');
      const author = (publication.author !== '') ? `<br/><b>Author(s):</b> ${publication.author}` : '';
      const description = (publication.description !== '') ? publication.description : '';

      let publicationString = `<p><b>${publication.title}</b> - ${publication.publication}&emsp;(${date})${author}${description}</p>`;

      if(publication.url !== '') {
        publicationString = `<p><b>${publication.title}</b> - <a href='${publication.puburl}' target='_blank'>${publication.publication}</a>&emsp;(${date})${author}${description}</p>`;
      }

      return {publicationString};
    });
  }

  getCertifications(resume) {
    return resume.certifications.certificationsArray.map((certification)=> {
      const isStartDateObject = typeof certification.startdate === 'object';
      const startDateString = isStartDateObject ? certification.startdate.date :
        certification.startdate === '' ? undefined : certification.startdate;

      const isEndDateObject = typeof certification.enddate === 'object';
      const endDateString = isEndDateObject ? certification.enddate.date :
        certification.enddate === '' ? undefined : certification.enddate;

      const startDate = moment(startDateString).format('MM/YYYY');
      let endDate;

      if (certification.noend) {
        endDate = 'Present';
      } else {
        endDate = moment(endDateString).format('MM/YYYY');
      }

      const license = (certification.license !== '') ? `<br/><b>License Number:</b> ${certification.license}` : '';

      let certificationString = `<p><b>${certification.name}</b> - ${certification.authority}&emsp;(${startDate} - ${endDate})${license}</p>`;

      if(certification.url !== ''){
        certificationString = `<p><b>${certification.name}</b> - <a href='${certification.url}' target='_blank'>${certification.authority}</a>&emsp;(${startDate} - ${endDate})${license}</p>`;
      }

      return {certificationString};
    });
  }

  getProjects(resume) {
    return resume.projects.projectsArray.map((project) => {
      const isStartDateObject = typeof project.startdate === 'object';
      const startDateString = isStartDateObject ? project.startdate.date :
        project.startdate === '' ? undefined : project.startdate;
      const isEndDateObject = typeof project.enddate === 'object';
      const endDateString = isEndDateObject ? project.enddate.date :
        project.enddate === '' ? undefined : project.enddate;

      const startDate = moment(startDateString).format('MM/YYYY');
      let endDate;

      if (project.noend) {
        endDate = 'Present';
      } else {
        endDate = moment(endDateString).format('MM/YYYY');
      }

      const description = (project.description !== '') ? project.description : '';

      let projectString = `<p><b>${project.title}</b>&emsp;(${startDate} - ${endDate})</p>${description}`;

      if (project.url !== '') {
        projectString = `<p><b><a href='${project.url}' target='_blank'>${project.title}</a></b>&emsp;(${startDate} - ${endDate})</p>${description}`;
      }

      return {projectString};
    });
  }

  getAssociations(resume) {
    return resume.associations.associationsArray.map((association) => {
      const isStartDateObject = typeof association.startdate === 'object';
      const startDateString = isStartDateObject ? association.startdate.date :
        association.startdate === '' ? undefined : association.startdate;

      const isEndDateObject = typeof association.enddate === 'object';
      const endDateString = isEndDateObject ? association.enddate.date :
        association.enddate === '' ? undefined : association.enddate;

      const startDate = moment(startDateString).format('MM/YYYY');
      let endDate;

      if (association.noend) {
        endDate = 'Present';
      } else {
        endDate = moment(endDateString).format('MM/YYYY');
      }

      const description = (association.description !== '') ? association.description : '';

      let associationString = `<p><b>${association.name}</b>&emsp;(${startDate} - ${endDate})<br/><b>Position(s):</b> ${association.position}</p>${description}`;

      if(association.url !== ''){
        associationString = `<p><b><a href='${association.url}' target='_blank'>${association.name}</a></b>&emsp;(${startDate} - ${endDate})<br/><b>Position(s):</b> ${association.position}</p>${description}`;
      }

      return {associationString};
    });
  }

  formatData(resume, order){
    const sectionsOrder = order.map(section => section.name.toLowerCase());
    const education = this.getEducation(resume);
    const experiences = this.getExperiences(resume);

    let resumeData = {
      resumeName: resume.title,
      name: resume.name,
      city: resume.city,
      state: resume.state,
      email: resume.email,
      vanity: resume.vanity,
      zip: resume.zip,
      phoneNumber: resume.phone,
      sectionsOrder,
      summary: {
        title: resume.summary.title,
        summaryString: resume.summary.summary
      },
      skills: {
        title: resume.skills.title,
        items: resume.skills.skillsArray,
        skillsOrder: resume.skills.skillsOrder
      },
      education: {
        title: resume.education.title,
        items: education
      },
      experience : {
        title: resume.experience.title,
        items: experiences
      }
    };

    if (resume.clubs) {
      const clubs = this.getClubs(resume);

      resumeData.club = {
        title: resume.clubs.title,
        items: clubs
      };
    }

    if (resume.certifications) {
      const certifications = this.getCertifications(resume);

      resumeData.certification = {
        title: resume.certifications.title,
        items: certifications
      };
    }

    if (resume.awards) {
      const awards = this.getAwards(resume);

      resumeData.award = {
        title: resume.awards.title,
        items: awards
      };
    }

    if (resume.publications) {
      const publications = this.getPublications(resume);

      resumeData.publication = {
        title: resume.publications.title,
        items: publications
      };
    }

    if (resume.projects) {
      const projects = this.getProjects(resume);

      resumeData.project = {
        title: resume.projects.title,
        items: projects
      };
    }

    if (resume.associations) {
      const associations = this.getAssociations(resume);

      resumeData.association = {
        title: resume.associations.title,
        items: associations
      };
    }

    return resumeData;
  }

  onSaveConfirm(){
    const { actions, navigate, profile } = this.props;
    const { resume, resumeOrder } = this.state;

    const data = this.formatData(resume, resumeOrder);

    this.setState({ isResumeSaved: true });

    actions.createResume(data)
      .then( async () => {
        await actions.getMyCareerPreppedStatus(profile.id);
        navigate('/toolbox/resume');
      });
  }

  onChange(updatedResume) {
    const resume = Object.assign({}, this.state.resume, updatedResume);

    this.setState({ resume });
  }

  onTogglePreview(){
    const { actions, modal } = this.props;
    if(modal && modal === 'preview-resume-modal'){
      return this.onCloseModal();
    }

    actions.openModal('preview-resume-modal');
  }

  onChangeTitle(event) {
    const { value } = event.target;
    const resume = Object.assign({}, this.state.resume, { title: value });

    this.setState({ resume });
  }

  onChangeDetails(value, plainText, name){
    const resume = Object.assign({}, this.state.resume, { [name]: value });
    const validatorState = Object.assign({}, this.state.validatorState, { [name]: plainText });

    this.setState({
      resume,
      validatorState
    });
  }

  onChangeSection(sectionData, sectionName) {
    const resume = Object.assign({}, this.state.resume, {
      [sectionName]: sectionData
    });

    this.setState({ resume });
  }

  onChangeSectionTitle(title, sectionName){
    const resume = Object.assign({}, this.state.resume, {
      [sectionName]: Object.assign({}, this.state.resume[sectionName],{
        title
      })
    });

    this.setState({resume});
  }

  onLoadForm(event) {
    const formName = event.target.dataset.formName;

    this.props.actions.loadForm(formName);
  }

  onUnloadForm(event) {
    const { id } = event.target;

    this.props.actions.unloadForm(id);
  }

  onRearrangeResumeOrder(newOrder){
    const { hasChangedOrder } = this.state;

    this.setState({
      resumeOrder: newOrder,
      hasChangedOrder: !hasChangedOrder ? !hasChangedOrder: hasChangedOrder
    }, () => {
      if(!hasChangedOrder && !this.props.isHookedToARoute.status){
        this.props.actions.setRouterHookedToARoute('create-resume');
      }
    });
  }

  onSortClick(){
    this.props.actions.openModal('sort-resume-sections-modal');
  }

  render() {
    const { errors, resume, resumeOrder, removeResumeSectionId, showCustomDialog } = this.state;
    const {
      forms,
      experiences,
      skills,
      education,
      certifications,
      projects,
      publications,
      awards,
      associations,
      clubs,
      modal,
      isSaving,
      profile,
      isHookedToARoute
    } = this.props;

    const requestingResumeContents = skills.isRequesting || education.isRequesting ||
      experiences.isRequesting || clubs.isRequesting || certifications.isRequesting ||
      awards.isRequesting || publications.isRequesting || projects.isRequesting || associations.isRequesting;

    const showSortModal = modal && modal === 'sort-resume-sections-modal';
    const showUnsavedModal = modal && modal === 'unsaved_changes_modal';
    const preview = modal && modal === 'preview-resume-modal';
    const showSaveConfirmModal = modal && modal === 'save-confirm-modal';

    const headerTitle = showUnsavedModal ? 'Discard your Changes' : 'Save Resume';
    const bodyText = showUnsavedModal ?
      'Are you sure you want to leave this page? Your resume will not be saved.':
      'Are you sure you\'re finished? Saving will make your changes final. You’ll be able to download your resume as a Word document once saved if you want to make more edits.';

    const cancelBtnText = showUnsavedModal ?
      'Stay' : 'No, Keep Editing';

    const confirmBtnText = showUnsavedModal ?
      'Yes, Leave' : 'Yes, I’m Finished';

    return (
      <div className="col-lg-12">
        <Helmet title={preview ? 'Targeted Resumé - Preview' : 'Targeted Resumé - Create'}/>
        <div className="content targeted-resume create" style={{marginTop: 30}}>
          <Prompt
            when={isHookedToARoute.status}
            message="Are you sure you want to leave this page? Any changes may be unsaved."/>
          {(showUnsavedModal || showSaveConfirmModal) &&
            <UnsavedChangesModal
              headerTitle={headerTitle}
              bodyText={bodyText}
              cancelBtnText={cancelBtnText}
              confirmBtnText={confirmBtnText}
              isSaving={isSaving}
              onConfirm={showUnsavedModal ? this.onBackConfirm : this.onSaveConfirm}
              onCancel={this.onCloseModal}
              isOpen={showUnsavedModal || showSaveConfirmModal}/>}

          {preview &&
            <PreviewResumeModal
              isOpen={preview}
              onCancel={this.onTogglePreview}
              resume={resume}
              resumeOrder={resumeOrder}/>}

          <div className="row">
            <div className="col-lg-12">
              <h2>Create Targeted Resume</h2>
            </div>
            {(requestingResumeContents && !preview) ?
              <div className="loading-container">
                <Loading/>
                <span>Preparing your resume... </span>
              </div> :
              <CreateResumeComponent
                onBack={this.onBack}
                onTogglePreview={this.onTogglePreview}
                onSave={this.onSave}
                onChangeTitle={this.onChangeTitle}
                resumeOrder={resumeOrder}
                profile={profile}
                onRearrangeResumeOrder={this.onRearrangeResumeOrder}
                errors={errors}
                resume={resume}
                onChange={this.onChange}
                onUnloadForm={this.onUnloadForm}
                preview={preview}
                onLoadForm={this.onLoadForm}
                onSortClick={this.onSortClick}
                forms={forms}
                onChangeSection={this.onChangeSection}
                onChangeSectionTitle={this.onChangeSectionTitle}
                onCancelModal={this.onCancelModal}
                requestingResumeContents={requestingResumeContents}
                onRemoveResumeSection={this.onRemoveResumeSection}/>}
          </div>

          {showSortModal &&
            <SortSectionsModal
              profileOrder={resumeOrder}
              onRearrangeResumeOrder={this.onRearrangeResumeOrder}
              isOpen={showSortModal}/>}

          {removeResumeSectionId &&
            <DeleteSectionConfirmModal
              isOpen={(removeResumeSectionId !== null)}
              onCancel={this.onRemoveResumeSectionCancel}
              onConfirm={this.onRemoveResumeSectionConfirm}/>}

          {
            showCustomDialog &&
              <Modal
                size="lg"
                isOpen={showCustomDialog}
                backdrop="static"
                toggle={this.closeCustomDialog}>
                <ModalHeader
                  toggle={this.closeCustomDialog}>Leave this page?</ModalHeader>
                <ModalBody>
                  <p>Are you sure you want to leave this page? Any changes may be unsaved.</p>
                </ModalBody>
                <ModalFooter>
                  <button
                    onClick={this.closeCustomDialog}
                    className="btn btn-secondary float-left">
                    Stay
                  </button>
                  <button
                    onClick={this.onConfirmLeaveRoute}
                    className="btn btn-primary">
                    Yes, Leave
                  </button>
                </ModalFooter>
              </Modal>
          }
        </div>
      </div>
    );
  }
}

const defaultOrder = [
  { id: 1, name: 'Skills' },
  { id: 2, name: 'Education' },
  { id: 3, name: 'Experience' },
  { id: 4, name: 'Project' },
  { id: 6, name: 'Publication' },
  { id: 7, name: 'Certification' },
  { id: 8, name: 'Award' },
  { id: 9, name: 'Club' },
  { id: 10, name: 'Association' }
];

const mapStateToProps = (state) => {
  const { profile } = state;

  let resumeOrder = defaultOrder;

  let resume = {
    title: '',
    name: '',
    city: '',
    state: '',
    email: '',
    vanity: '',
    zip: '',
    phone: '',
    summary: {
      title:'Summary',
      summary: ''
    },
    skills: {
      title: 'Skills',
      skillsOrder: [],
      skillsArray: []
    },
    education: {
      title: 'Education',
      educationArray: []
    },
    experience: {
      title: 'Experience',
      experienceArray: []
    },
    clubs: {
      title: 'Extracurricular activities & clubs',
      clubsArray: []
    },
    certifications: {
      title: 'Certifications and Licenses',
      certifications: '',
      certificationsArray: []
    },
    awards: {
      title: 'Honors & Awards',
      awards: '',
      awardsArray: []
    },
    publications: {
      title: 'Publications',
      publications: '',
      publicationsArray: []
    },
    projects: {
      title: 'Projects',
      projects: '',
      projectsArray: []
    },
    associations: {
      title: 'Associations',
      associations: '',
      associationsArray: []
    }
  };


  if (! _isEmpty(profile.data)) {
    const { name, city, state, zip, phoneNumber: phone, email, vanity, summary: profileDataSummary } = profile.data;
    const skillsOrder = profile.data.skillsOrder.split(',').filter(id => id !== '').map(id => ({ id }));

    if (profile.data.profileorder.length !== 0) {
      resumeOrder = parseJSON(profile.data.profileorder);
      resumeOrder = _reject(resumeOrder, { 'name': 'Interests' });
    }

    resume = Object.assign({}, resume, {
      name,
      city: _isNull(city) ? '' : city.label,
      state: _isNull(state) ? '' : state.label,
      phone,
      email,
      zip,
      vanity,
      summary : Object.assign({}, resume.summary, {
        summary: profileDataSummary
      }),
      skills: Object.assign({}, resume.skills, { skillsOrder })
    });
  }

  const { data: skillsData } = state.skills;
  const skills = Object.assign({}, resume.skills, {
    skillsArray: skillsData
  });

  if (skillsData.length === 0) {
    resumeOrder = _reject(resumeOrder, { 'name': 'Skills' });
  }

  const { data: educationData } = state.education;
  const education = Object.assign({}, resume.education, {
    educationArray: educationData
  });

  if (educationData.length === 0) {
    resumeOrder = _reject(resumeOrder, { 'name': 'Education' });
  }

  const { data: experienceData } = state.experiences;
  const experience = Object.assign({}, resume.experience, {
    experienceArray: experienceData
  });

  if (experienceData.length === 0) {
    resumeOrder = _reject(resumeOrder, { 'name': 'Experience' });
  }

  const { data: clubsData } = state.clubs;
  const clubs = Object.assign({}, resume.clubs, {
    clubsArray: clubsData
  });

  if (clubsData.length === 0) {
    resumeOrder = _reject(resumeOrder, { 'name': 'Club' });
  }

  const { data: certificationsData } = state.certifications;
  const certifications = Object.assign({}, resume.certifications, {
    certificationsArray: certificationsData
  });

  if (certificationsData.length === 0) {
    resumeOrder = _reject(resumeOrder, { 'name': 'Certification' });
  }

  const { data: awardsData } = state.awards;
  const awards = Object.assign({}, resume.awards, {
    awardsArray: awardsData
  });

  if (awardsData.length === 0) {
    resumeOrder = _reject(resumeOrder, { 'name': 'Award' });
  }

  const { data: publicationsData } = state.publications;
  const publications = Object.assign({}, resume.publications, {
    publicationsArray: publicationsData
  });

  if (publicationsData.length === 0) {
    resumeOrder = _reject(resumeOrder, { 'name': 'Publication' });
  }

  const { data: projectsData } = state.projects;
  const projects = Object.assign({}, resume.projects, {
    projectsArray: projectsData
  });

  if (projectsData.length === 0) {
    resumeOrder = _reject(resumeOrder, { 'name': 'Project' });
  }

  const { data: associationsData } = state.associations;
  const associations = Object.assign({}, resume.associations, {
    associationsArray: associationsData
  });

  if (associationsData.length === 0) {
    resumeOrder = _reject(resumeOrder, { 'name': 'Association' });
  }

  resume = Object.assign({}, resume, {
    clubs,
    awards,
    projects,
    education,
    experience,
    associations,
    publications,
    certifications,
    skills
  });

  return {
    resume,
    resumeOrder,
    modal: state.components.modal,
    profile: state.profile.data,
    experiences: state.experiences,
    skills: state.skills,
    clubs: state.clubs,
    education: state.education,
    certifications: state.certifications,
    awards: state.awards,
    publications: state.publications,
    projects: state.projects,
    associations: state.associations,
    forms: state.components.forms,
    currentUser: state.auth.data.currentUser,
    isSaving: state.resume.isSubmitting,
    resumeInStore: state.resume.data,
    isHookedToARoute: state.router.isHookedToARoute,
    storedAction: state.router.storedAction
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    routerActions,
    clubActions,
    skillActions,
    awardActions,
    profileActions,
    projectActions,
    educationActions,
    componentActions,
    experienceActions,
    associationActions,
    publicationActions,
    certificationActions,
    resumeActions,
    authenticationActions,
    { getMyCareerPreppedStatus }
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(CreateResume));
