import React from 'react';

import { isEmpty } from 'lodash';
import { pure } from 'recompose';
import classNames from 'classnames';

import { parseUrlString } from '../../utils';

import PropTypes from 'prop-types';
import Linkify from 'react-linkify';
import { Link } from 'react-router-dom';

import VideoIframe from './VideoIframe';
import ImagePost from './ImagePost';
import ImageRenderer from './ImageRenderer';
import CpHtmlParser from '../common/CpHtmlParser';
import LinkPreview from './LinkPreview';
import SharedReflectionAnswersPostContainer from '../../containers/dashboard/SharedReflectionAnswersPostContainer';

const PostItem = ({
  wallPost,
  showMore,
  onshowMore,
  secondary,
}) => {

  const {
    id,
    post: postString ,
    user,
    attachment,
    comment,
    sharedEntityType,
    sharedQuotedText,
    linkPreview,
    sharedSkillBuilderReflections
  } = wallPost;
  const { condition, id: showMoreId} = showMore;
  const post = secondary ? comment : postString;

  const parsedString = parseUrlString(post);

  const showMoreCondition = post.replace(/<[^>]+>/g, '').length > 400;
  const showFullPostText = condition && showMoreId === id;
  const lessComponentCondition = showMoreCondition && !showFullPostText;

  const wrapperClass = classNames({
    'profile-name': secondary,
    'post-content': !secondary
  });

  if(sharedEntityType === 'skillBuilderReflections'){
    return (
      <div className={wrapperClass}>
        {
          secondary &&
            <span style={{paddingRight:5}}>
              <Link
                to={`/cp/${user.vanity}`}
                style={{fontWeight: '500'}}
                className="user-name">
                {user.name}
              </Link>
            </span>
        }
        <SharedReflectionAnswersPostContainer
          id={id}
          quote={sharedQuotedText}
          reflection={sharedSkillBuilderReflections}/>
      </div>
    );
  }


  return (
    <div className={wrapperClass} style={{ whiteSpace: 'normal' }}>
      {secondary &&
        <span style={{paddingRight:5}} className="post-user-name">
          <Link
            to={`/cp/${user.vanity}`}
            style={{fontWeight: '500'}}
            className="user-name">
            {user.name}
          </Link>
        </span>}

      {lessComponentCondition ?
        <Linkify properties={{target: '_blank'}}>
          <CpHtmlParser htmlString={post.slice(0, 400)}/>
        </Linkify> :
        <Linkify properties={{target: '_blank'}}>
          <CpHtmlParser htmlString={post}/>
        </Linkify>}

      {showMoreCondition &&
        <span
          onClick={onshowMore(id)}
          className="show-more-btn">
          {lessComponentCondition ? '... Show more' : 'Show less'}
        </span>}

      {(!isEmpty(linkPreview)) && <LinkPreview linkPreview={linkPreview}/>}

      {(parsedString && parsedString.type === 'iframe' && attachment === '')
        && <VideoIframe url={parsedString.url} secondary={secondary}/>}

      {(parsedString && parsedString.type === 'image' && attachment === '')
        && <ImagePost url={parsedString.url} secondary={secondary}/>}

      {attachment && <ImageRenderer attachment={attachment} secondary={secondary}/>}
    </div>
  );
};

PostItem.propTypes = {
  showMore: PropTypes.object,
  onshowMore: PropTypes.func,
  wallPost: PropTypes.object.isRequired,
  secondary: PropTypes.bool,
};

export default pure(PostItem);
