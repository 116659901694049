import PropTypes from 'prop-types';
import React from 'react';

import SidebarUserInfo from '../common/SidebarUserInfo.js';
import WallPostErrorModal from './WallPostErrorModal';
import WallContainer from './WallContainer';
import ActivitySort from '../common/ActivitySort';
import ActivityFilter from '../common/ActivityFilter';
import DashboardPostFormContainer from '../../containers/dashboard/DashboardPostFormContainer';

import { Row, Col } from 'reactstrap';

const ActivityFeedPane = ({
  route,
  profile,
  sort,
  wallPosts,
  isSortByOpen,
  toggleSortBy,
  handleBeforeSorting,
  isAuthenticated,
  callAPI,
  isRequestingSort,
  onChangeActivityFilter,
  shouldCallAPIInitially,
  wallShare,
  emptyPostModal,
  onCloseModal
}) => {
  return (
    <div>
      <div className="container community-container mb-3">
        <Row>
          <Col className="justify-content-center">
            <div className="community-grid">
              <SidebarUserInfo profile={profile}/>
              <DashboardPostFormContainer route={route}/>
            </div>
          </Col>
        </Row>
      </div>

      {wallPosts.data.length >= 2 &&
        <div className="container community-container">
          <div className="activity-feed-filter-sort">
            <ActivityFilter
              onChangeActivityFilter={onChangeActivityFilter}/>
            <ActivitySort
              dashboard
              activeSort={sort}
              isSortByOpen={isSortByOpen}
              toggleSortBy={toggleSortBy}
              handleBeforeSorting={handleBeforeSorting}
              isAuthenticated={isAuthenticated}/>
          </div>
        </div>}

      <WallContainer
        wallPosts={wallPosts}
        callAPI={callAPI}
        isRequestingSort={isRequestingSort}
        onChangeActivityFilter={onChangeActivityFilter}
        shouldCallAPIInitially={shouldCallAPIInitially}
        wallShare={wallShare}
        isAuthenticated={isAuthenticated}/>

      {emptyPostModal &&
        <WallPostErrorModal
          isOpen={emptyPostModal}
          onClose={onCloseModal}/>}
    </div>
  );
};

ActivityFeedPane.propTypes = {
  route: PropTypes.object,
  sort: PropTypes.string.isRequired,
  isSortByOpen: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isRequestingSort: PropTypes.bool.isRequired,
  toggleSortBy: PropTypes.func.isRequired,
  handleBeforeSorting: PropTypes.func.isRequired,
  callAPI: PropTypes.func.isRequired,
  onChangeActivityFilter: PropTypes.func.isRequired,
  shouldCallAPIInitially: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  wallPosts: PropTypes.object.isRequired,
  wallShare: PropTypes.object.isRequired,
  emptyPostModal: PropTypes.bool
};

export default ActivityFeedPane;
