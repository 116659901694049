import PropTypes from 'prop-types';
import React from 'react';

import moment from 'moment';
import _find from 'lodash/find';

const visibilityOptionsArray = [
  {
    title:'Everyone (Public)',
    value:'1',
    icon:'fa fa-globe'
  },
  {
    title:'Followers Only',
    value:'2',
    icon:'fa fa-user-circle-o'
  },
  {
    title:'Only Me',
    value:'0',
    icon:'fa fa-lock'
  },
];

const Timestamp = ({postedAt, visibilty}) => {
  const visibiltyObj = _find(visibilityOptionsArray, option =>
    option.value == visibilty);

  return (
    <div className="posted-on">
      {moment.utc(postedAt).fromNow()}
      {visibiltyObj && <span className="visibilty-icon"><i className={visibiltyObj.icon}/></span>}
    </div>
  );
};

Timestamp.propTypes = {
  postedAt: PropTypes.string,
  visibilty: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default Timestamp;
