import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Validator from '../../validator';
import CpHtmlParser from '../../components/common/CpHtmlParser';
import * as badgeAssertionActions from '../../redux/actions/badge-assertion';
import EditBadgeSupportingStatementForm from '../../components/skill-badges/EditBadgeSupportingStatementForm.js';

class UnitBadgeStatement extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    badgeAssertion: PropTypes.object.isRequired,
    issuedStatement: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      editStatement: false,
      statementGuidelinesTooltipOpen: false,
      issuedStatement: Object.assign({}, this.props.issuedStatement),
      editorText: {statement: this.props.issuedStatement.statement}
    };

    this.onSubmitStatement = this.onSubmitStatement.bind(this);
    this.onChangeStatement = this.onChangeStatement.bind(this);
    this.onClickEditStatement = this.onClickEditStatement.bind(this);
    this.onToggleEditStatement = this.onToggleEditStatement.bind(this);
    this.toggleStatementGuidelinesTooltip = this.toggleStatementGuidelinesTooltip.bind(this);
  }

  isValid(field = null) {
    const rules = {
      statement: ['required', 'minWords|50', 'maxWords|500']
    };
    const { editorText } = this.state;

    const validate = Validator.createValidator(rules, editorText, field);
    const { errors, isValid } = validate;

    this.setState({ errors });

    return isValid;
  }

  onToggleEditStatement() {
    let { issuedStatement } = this.state;

    issuedStatement = Object.assign({}, issuedStatement, {
      statement: this.props.badgeAssertion.data.statement[0].statement
    });

    this.setState({ editStatement: !this.state.editStatement, issuedStatement });
  }

  onClickEditStatement(event) {
    event.preventDefault();

    this.onToggleEditStatement();
  }

  onChangeStatement(value,...params) {
    const issuedStatement = Object.assign({}, this.state.issuedStatement, {
      statement: value
    });
    const editorText = { statement: params[2].getText().replace(/\r?\n|\r/gm, ' ') };

    this.setState({ issuedStatement, editorText });

    this.isValid('statement');
  }

  onSubmitStatement() {
    if (! this.isValid()) return false;

    const { actions, badgeAssertion } = this.props;
    const issuedStatement = Object.assign({}, this.state.issuedStatement, {
      badge: badgeAssertion.data.badge.id
    });

    actions.updateStatement(issuedStatement, badgeAssertion.data.statement[0].id)
      .then(() => {
        this.onToggleEditStatement();
      });
  }

  toggleStatementGuidelinesTooltip() {
    this.setState({
      statementGuidelinesTooltipOpen: !this.state.statementGuidelinesTooltipOpen
    });
  }

  render() {
    const { currentUser, badgeAssertion } = this.props;
    const {
      errors,
      editStatement,
      issuedStatement,
      statementGuidelinesTooltipOpen
    } = this.state;
    const isBadgeStatementOwner = currentUser.id === badgeAssertion.data.user.id;

    return (
      <div className="section-badge">
        <h6>
          Written Justification:&nbsp;
          {!editStatement && isBadgeStatementOwner &&
            <a
              onClick={this.onClickEditStatement}
              className="clickable small float-right">
              <i style={{paddingRight: 5}} className="fa fa-pencil"/>Edit
            </a>}
        </h6>
        <div className="section-badge-content">
          {editStatement ?
            <EditBadgeSupportingStatementForm
              errors={errors}
              isUpdating={badgeAssertion.isUpdating}
              statement={issuedStatement.statement}
              onChangeStatement={this.onChangeStatement}
              onSubmitStatement={this.onSubmitStatement}
              onToggleEditStatement={this.onToggleEditStatement}
              statementGuidelinesTooltipOpen={statementGuidelinesTooltipOpen}
              toggleStatementGuidelinesTooltip={this.toggleStatementGuidelinesTooltip}/> :
            <div className="ql-editor no-padding-in-quill">
              <CpHtmlParser htmlString={issuedStatement.statement}/>
            </div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, badgeAssertion } = state;
  let issuedStatement = {
    statement: ''
  };

  if (! _isEmpty(badgeAssertion.data)) {
    const statement = (badgeAssertion.data.statement.length === 0) ? '' :
      badgeAssertion.data.statement[0].statement;

    issuedStatement = Object.assign({}, issuedStatement, { statement });
  }

  return {
    badgeAssertion,
    issuedStatement,
    currentUser: auth.data.currentUser
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(badgeAssertionActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitBadgeStatement);
