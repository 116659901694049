import React from 'react';

import { SimpleToolTipWrapper } from '../common/ToolTipWrapper';

const FeedbackLimitTooltip = () => {
  return (
    <>
      Feedback Limit
      <SimpleToolTipWrapper
        id="manage-irs-list-feedback-limit-count"
        toolTipContent={
          <div className="inside-tooltip">
            The limit of weekly feedback requests the Industry Mentor has set based on their availability
          </div>
        }>
        <i className="fa fa-info-circle ml-1" />
      </SimpleToolTipWrapper>
    </>
  );
};

export default FeedbackLimitTooltip;
