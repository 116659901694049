import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link } from 'react-router-dom';

import * as badgeActions from '../../redux/actions/badges';
import * as endorsementActions from '../../redux/actions/badge-endorsement';
import * as componentActions from '../../redux/actions/components';

import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

class ConfirmEndorsementModal extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    skippedBadges: PropTypes.array,
    onConfirm: PropTypes.func.isRequired,
    onSkip: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    requestor: PropTypes.object.isRequired,
    isBadge: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);

    this.closeEndorsementRequest = this.closeEndorsementRequest.bind(this);
  }

  closeEndorsementRequest() {
    this.props.actions.closeModal();
  }

  render() {
    const { skippedBadges, requestor, onConfirm, onSkip, isSubmitting, isOpen, isBadge } = this.props;

    if(isSubmitting){
      return (
        <Modal
          size="lg"
          className="modal-margin-top"
          isOpen={isOpen}
          backdrop="static"
          toggle={this.closeEndorsementRequest}>
          <ModalBody>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <span>
                <i className="fa fa-spinner fa-pulse fa-2x fa-fw" />
              </span>
              <span style={{ paddingLeft: 10}}>
                Endorsing...
              </span>
            </div>
          </ModalBody>
        </Modal>);
    }

    return(
      <Modal
        size="lg"
        className="modal-margin-top"
        isOpen={isOpen}
        toggle={this.closeEndorsementRequest}>
        <ModalHeader
          toggle={this.closeEndorsementRequest}>Confirm Endorsement</ModalHeader>
        <ModalBody>
          {skippedBadges.length > 0 &&
            <p>
              <strong>You din't confirm that {requestor.name} possesses:</strong>
              {skippedBadges.map((data, index) =>
                (<span key={index}>
                  <Link
                    to={`/skill-badges/badge-details/${data.id}`}
                    target="_blank">
                    <span style={{paddingLeft: 5}}>
                      {data.name}
                    </span>
                  </Link>
                  {index !== skippedBadges.length - 1 && <span className="name-gap">,</span>}
                </span>))}
            </p>}

          <h6>Do you want to endorse {requestor.name} for these {isBadge ? 'badges' : 'skills'}?</h6>
        </ModalBody>
        <ModalFooter>
          <button
            onClick={onSkip}
            className="btn btn-secondary">
            Don't Endorse
          </button>

          <button
            onClick={onConfirm}
            className="btn btn-primary">
            Yes, Endorse
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}



const mapStateToProps = (state) => {
  const { badges, badgeEndorsement } = state;
  return {
    badges,
    badgeEndorsement
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    badgeActions,
    endorsementActions,
    componentActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEndorsementModal);
