import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { openModal } from '../../redux/actions/components';

import { toastr } from 'react-redux-toastr';

import AdminGroupAddAdminModal from './AdminGroupAddAdminModal';

class AdminGroupAddAdmin extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    children: PropTypes.array.isRequired,
    invitedAdmins: PropTypes.array,
    modal: PropTypes.string,
    selectedGroupId: PropTypes.string
  }

  constructor(props) {
    super(props);

    this.onOpenModal = this.onOpenModal.bind(this);
  }

  onOpenModal() {
    const { selectedGroupId } = this.props;

    if (selectedGroupId === 'all-groups') {
      toastr.info('', 'Select a group to add an admin.');
      return;
    }

    this.props.actions.openModal('admin-group-add-admin');
  }

  render() {
    const { modal } = this.props;

    return (
      <span>
        <a
          onClick={this.onOpenModal}
          className="btn btn-sm ml-2 btn-primary">
          {this.props.children}
        </a>

        {(modal === 'admin-group-add-admin') && <AdminGroupAddAdminModal />}
      </span>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    components: { modal },
    groups: { selectedGroup: { id: selectedGroupId } }
  } = state;

  return {
    modal,
    selectedGroupId
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ openModal }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminGroupAddAdmin);
