import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Modal } from 'reactstrap';
import { email } from '../../validator/rules';
import ShareReflectionModalForms from '../../components/skill-builders/essentials/ShareReflectionModalForms';

class ShareReflectionModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func,
    unit: PropTypes.object,
    selectedReflections: PropTypes.array,
    reflections: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);

    this.state={
      typeofShare: 'activity-stream',
      emails: '',
      errors: false,
    };

    this.onSelectTypeOfSharing = this.onSelectTypeOfSharing.bind(this);
    this.onChangeEmails = this.onChangeEmails.bind(this);
  }

  onChangeEmails(event) {
    const { value } = event.target;

    this.setState({ emails: value }, () => this.checkValidEmails(value));
  }

  checkValidEmails(emails){
    const emailArray = emails.split(',');

    if(emailArray.length > 0) {
      const invalidEmailExist = emailArray.reduce((acc, next) => {
        const isInvalid = email(next.trim()) && email(next.trim()).length > 0;
        return isInvalid || acc;
      },false);
      this.setState({ errors : invalidEmailExist});
    }
  }

  onSelectTypeOfSharing(type){
    return () => {
      this.setState({
        typeofShare: type
      });
    };
  }

  render() {
    const { typeofShare, errors, emails } = this.state;
    const { isOpen, closeModal, unit, selectedReflections, reflections } = this.props;

    return(
      <Modal
        size="lg"
        isOpen={isOpen}
        id="shareAnswers"
        backdrop="static"
        toggle={closeModal}>
        <div className="modal-content column">
          <div className="modal-header">
            <h5 className="modal-title">Share Reflection(s)</h5>
            <button
              type="button"
              onClick={closeModal}
              className="close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>Choose how you'd like to share your reflection(s) to the following question(s):</p>
            <ul className="list-unstyled" style={{margin: '0 30px 15px'}}>
              {
                selectedReflections.includes('q1') &&
                  <li>
                    <strong>Question 1 - {unit.reflectone}</strong>
                  </li>
              }
              {
                selectedReflections.includes('q2') &&
                  <li>
                    <strong>Question 2 - {unit.reflecttwo}</strong>
                  </li>
              }
              {
                selectedReflections.includes('q3') &&
                  <li>
                    <strong>Question 3 - {unit.reflectthree}</strong>
                  </li>
              }
              {
                selectedReflections.includes('q4') &&
                  <li>
                    <strong>Question 4 - {unit.reflectfour}</strong>
                  </li>
              }
            </ul>
            <div
              className="custom-control custom-checkbox">
              <input
                type="checkbox"
                checked={typeofShare === 'activity-stream'}
                onChange={this.onSelectTypeOfSharing('activity-stream')}
                className="custom-control-input"/>
              <label
                onClick={this.onSelectTypeOfSharing('activity-stream')}
                className="custom-control-label">
                <span className="custom-control-indicator"/>
                <span className="custom-control-description">Share to my activity stream</span>
              </label>
            </div>
            <div
              className="custom-control custom-checkbox">
              <input
                type="checkbox"
                checked={typeofShare === 'engage-and-connect'}
                onChange={this.onSelectTypeOfSharing('engage-and-connect')}
                className="custom-control-input"/>
              <label
                onClick={this.onSelectTypeOfSharing('engage-and-connect')}
                className="custom-control-label">
                <span className="custom-control-indicator"/>
                <span className="custom-control-description">Share to the Engage & Connect stream</span>
              </label>
            </div>
            <div
              className="custom-control custom-checkbox">
              <input
                type="checkbox"
                checked={typeofShare === 'email'}
                onChange={this.onSelectTypeOfSharing('email')}
                className="custom-control-input"/>
              <label
                onClick={this.onSelectTypeOfSharing('email')}
                className="custom-control-label">
                <span className="custom-control-indicator"/>
                <span className="custom-control-description">Share via email to...</span>
              </label>
            </div>

            <ShareReflectionModalForms
              unit={unit}
              closeModal={closeModal}
              reflections={reflections}
              selectedReflections={selectedReflections}
              typeofShare={typeofShare}
              errors={errors}
              emails={emails}
              onChangeEmails={this.onChangeEmails}/>

          </div>
        </div>
      </Modal>
    );
  }
}


export default ShareReflectionModal;
