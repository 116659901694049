import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './LazyLoadImage.css';

import LazyLoad from 'react-lazyload';
import Loading from '../../../components/common/Loading';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

class LazyLoadImage extends Component {

  static propTypes = {
    srcLoaded: PropTypes.string,
    imageClassName: PropTypes.string,
    alt: PropTypes.string
  }

  constructor(props) {
    super(props);
    this.imageDivRef = null;

    this.state={
      width: null,
      height: null
    };
  }

  componentDidMount() {
        
    const hdLoaderImg = new Image();

    hdLoaderImg.src = this.props.srcLoaded;

    this.setImageSize(hdLoaderImg);

  }

  setImageSize(img) {

    this.setState({
      width: img.naturalWidth,
      height: img.naturalHeight
    });
  }

  render() {
    const { imageClassName, srcLoaded, alt } = this.props;
    
    return (
      <LazyLoad 
        throttle={100} 
        height={200} 
        placeholder={
          <div 
            style={{
              width: '100%',
              height: '200px',
              color: '#fff',
              backgroundColor: '#E0E0E0'
            }}
            className={`loading-container ${imageClassName}`}>
            <Loading/>
          </div>
        }>
        <ReactCSSTransitionGroup
          transitionName="lazyImage"
          transitionAppear
          transitionAppearTimeout={500}
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}>
          <img 
            className={imageClassName} 
            src={srcLoaded} 
            alt={alt ? alt : 'an image'}/>
        </ReactCSSTransitionGroup>
      </LazyLoad>
    );
  }

}

export default LazyLoadImage;