import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { checkHttp } from '../../../utils';

const styles = {
  editButton: {
    marginLeft: 10,
    cursor: 'pointer',
  }
};

const EducationCard = ({
  educationData,
  onEditEducationClick,
  isPublicProfile
}) => {

  const startDate = moment(educationData.startdate.date).format('MMMM YYYY');
  let endDate;

  const hasNoInstituteUrl = educationData.insurl === '';
  if (educationData.noend) {
    endDate = 'Present';
  } else {
    endDate = moment(educationData.enddate.date).format('MMMM YYYY');
  }

  const urlString = checkHttp(educationData.insurl) ?
    educationData.insurl : `//${educationData.insurl}`;

  const replacer = (match, p1) => {
    return p1;
  };
  const formattedGPA = educationData.gpa.replace(/(\d)(0+$)/, replacer);

  return (
    <div className="card-block">
      <div className="education">
        <div className="row">
          <div className="col-lg-12">
            <div className="display">
              <div className="item">
                <div className="institution">
                  {
                    hasNoInstituteUrl ?
                      educationData.institution :
                      <a
                        href={urlString}
                        target="_blank"
                        rel="noopener"
                        className="location">
                        {educationData.institution}
                      </a>
                  }

                  {! isPublicProfile &&
                    <i
                      style={styles.editButton}
                      onClick={onEditEducationClick}
                      id={`update-education-form-${educationData.id}`}
                      className="fa fa-xs fa-pencil orange" aria-hidden="true"/>}
                </div>
                <div className="program">
                  {
                    educationData.program && educationData.program !== '' &&
                      educationData.program
                  }
                  {
                    educationData.major && educationData.major !== '' &&
                      <span>
                        ,&nbsp;
                        {educationData.major}
                      </span>
                  }
                </div>
                <div className="date">
                  {startDate}&nbsp;-&nbsp;{endDate}
                </div>
                {
                  educationData.gpa && educationData.gpa !== '' &&
                    <div className="gpa">GPA: {formattedGPA}</div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EducationCard.propTypes = {
  educationData: PropTypes.object.isRequired,
  isPublicProfile: PropTypes.bool.isRequired,
  onEditEducationClick: PropTypes.func.isRequired
};

export default EducationCard;
