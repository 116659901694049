import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { parseJSON } from '../../../utils';

import AdminSectionHeader from '../admin/AdminSectionHeader';
import { Collapse } from 'reactstrap';
import ReadSpeakerButton from '../../common/ReadSpeakerButton';
import Parser from 'html-react-parser';

const QuestionAnswerSection = ({
  drag,
  drop,
  preview,
  unit,
  section,
  isAdmin,
  profile,
  hover,
  onEditClick,
  collapse,
  toggleCollapsible
}) => {
  let data = parseJSON(section.content);
  const buttonStyle = classNames('item no-padding-right', {
    'collapsed': !collapse
  });
  const questionAnswerId = `question-answer-${section.id}`;
  const url = window.location.href;
  const encodedUrl = encodeURIComponent(url);
  const speakerId = `read-speaker-${questionAnswerId}`;
  let answer = data.answer;
  const userWebpageLink = `https://www.careerprepped.com/cp/${profile.vanity}`;

  const searchAndReplaceWebpageLink = unit.slug === 'develop-targeted-resumes' || unit.slug === 'develop-a-career-portfolio';

  if(searchAndReplaceWebpageLink && data.question.search(new RegExp('How is a Career Site different than a targeted resume?', 'i')) > -1 ){
    answer = answer.replace('<a href="https://www.maxknowledge.com/" target="_blank">Career Site</a>',
      `<a href="${userWebpageLink}" target="_blank">Career Site</a>`);
  }

  if(searchAndReplaceWebpageLink && data.question.search(new RegExp('Can portfolio items be used to enhance my resume?', 'i')) > -1){
    answer = answer.replace('<a href="https://www.maxknowledge.com/" target="_blank">Career Site</a>',
      `<a href="${userWebpageLink}" target="_blank">Career Site</a>`);
  }

  return (
    <section className="essential-question" id={questionAnswerId} ref={preview}>
      {isAdmin &&
        <div style={{ marginTop: -20 }}>
          <AdminSectionHeader
            hover={hover}
            drag={drag}
            drop={drop}
            title="Q & A"
            sectionId={section.id}
            onEditClick={onEditClick}
            componentName={questionAnswerId}/>
        </div>}

      <div className="section-icon">
        <svg viewBox="0 0 120 100">
          <path d="M38,2 L82,2 A12,12 0 0,1 94,10 L112,44 A12,12 0 0,1 112,56 L94,90 A12,12 0 0,1 82,98 L38,98 A12,12 0 0,1 26,90 L8,56 A12,12 0 0,1 8,44 L26,10 A12,12 0 0,1 38,2" />
        </svg>
        <div className="icon"><div className="ico-question"/></div>
      </div>

      <ReadSpeakerButton
        encodedUrl={encodedUrl}
        sectionClass={questionAnswerId}/>

      <div id={speakerId} className="question">
        <div
          className={buttonStyle}
          onClick={toggleCollapsible}
          data-toggle="collapse"
          data-target="#question2-collapse"
          aria-expanded="false"
          aria-controls="collapseExample">
          {data.question}
          <span className="float-right">
            <i className={classNames('fa', {
              'fa-chevron-down': !collapse,
              'fa-chevron-up': collapse
            })}/>
          </span>
        </div>

        <Collapse isOpen={collapse}>
          <div className="card card-block">
            {Parser(answer)}
          </div>
        </Collapse>
      </div>

      <div
        id={`list-${speakerId}`}
        className={questionAnswerId}
        style={{display: 'none'}}>
        <span>{data.question}</span>

        {Parser(answer)}
      </div>
    </section>
  );
};


QuestionAnswerSection.propTypes = {
  hover: PropTypes.object.isRequired,
  unit: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  section: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
  collapse: PropTypes.bool.isRequired,
  toggleCollapsible: PropTypes.func.isRequired,
  profile: PropTypes.object,
  drag: PropTypes.func.isRequired,
  drop: PropTypes.func.isRequired,
  preview: PropTypes.func.isRequired
};

export default QuestionAnswerSection;
