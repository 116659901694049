import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getSkillsStatistics } from '../../redux/actions/statistics';

const SkillsActivity = ({ actions, userId, skillsStatistics }) => {
  useEffect(() => {
    actions.getSkillsStatistics(userId);
  }, [actions, userId]);

  return (
    <div className="dashboard-skill-status-container">
      <div className="dashboard-skill-status-header">
        Skills Activity
      </div>
      <div className="dashboard-skill-status-content">
        <div className="dashboard-skill-status-progress-container">
          <div className="dashboard-skill-status-progress-header">
            <div className="dashboard-skill-status-progress-title">
              {skillsStatistics.totalSkillsClaimed} Skills Total
            </div>
          </div>
          <div className="dashboard-skill-status-caption">
            Your skills activity snapshot:
          </div>
        </div>
        <div className="dashboard-skill-status-progress-container">
          <div className="dashboard-skill-status-progress-header">
            <div className="dashboard-skill-status-skills-stat">
              {skillsStatistics.totalSkillsClaimed > 0 && skillsStatistics.skillsWithoutEvidence == 0 ?
                'All skills have evidence!' :
                <span>
                  <b>{`${skillsStatistics.skillsWithoutEvidence}`} skills</b> need <b>evidence</b>
                </span>
              }
            </div>
            <div className={classNames('dashboard-skill-status-progress-pill', {
              'status--great': skillsStatistics.totalSkillsClaimed > 0 && skillsStatistics.skillsWithoutEvidence == 0,
              'status--incomplete': !(skillsStatistics.totalSkillsClaimed > 0 && skillsStatistics.skillsWithoutEvidence == 0)
            })}>
              {
                skillsStatistics.totalSkillsClaimed > 0 && skillsStatistics.skillsWithoutEvidence == 0 ?
                  'DONE' : 'INCOMPLETE'
              }
            </div>
          </div>
          <div className="dashboard-skill-status-action">
            <Link to="/myskills#otherSkills">Add Evidence <i className="fa fa-sm fa-arrow-right"/></Link>
          </div>
        </div>
        <div className="dashboard-skill-status-progress-container">
          <div className="dashboard-skill-status-progress-header">
            <div className="dashboard-skill-status-skills-stat">
              {skillsStatistics.totalSkillsClaimed > 0 && skillsStatistics.skillsWithoutRatings == 0 ?
                'All skills have feedback ratings!' :
                <span>
                  <b>{`${skillsStatistics.skillsWithoutRatings}`} skills</b> awaiting <b>feedback</b>
                </span>
              }
            </div>
            <div className={classNames('dashboard-skill-status-progress-pill', {
              'status--great': skillsStatistics.totalSkillsClaimed > 0 && skillsStatistics.skillsWithoutRatings == 0,
              'status--incomplete': !(skillsStatistics.totalSkillsClaimed > 0 && skillsStatistics.skillsWithoutRatings == 0)
            })}>
              {
                skillsStatistics.totalSkillsClaimed > 0 && skillsStatistics.skillsWithoutRatings == 0 ?
                  'DONE' : 'INCOMPLETE'
              }
            </div>
          </div>
          <div className="dashboard-skill-status-action">
            <Link to="/myskills#otherSkills">Seek Feedback <i className="fa fa-sm fa-arrow-right"/></Link>
          </div>
        </div>
        <div className="dashboard-skill-status-progress-container">
          <div className="dashboard-skill-status-progress-header">
            <div className="dashboard-skill-status-skills-stat">
              {skillsStatistics.totalSkillsClaimed > 0 && skillsStatistics.skillsWithoutEndorsement == 0 ?
                'All skills are endorsed!' :
                <span>
                  <b>{`${skillsStatistics.skillsWithoutEndorsement}`} skills</b> awaiting <b>endorsement</b>
                </span>
              }
            </div>
            <div className={classNames('dashboard-skill-status-progress-pill', {
              'status--great': skillsStatistics.totalSkillsClaimed > 0 && skillsStatistics.skillsWithoutEndorsement == 0,
              'status--incomplete': !(skillsStatistics.totalSkillsClaimed > 0 && skillsStatistics.skillsWithoutEndorsement == 0)
            })}>
              {
                skillsStatistics.totalSkillsClaimed > 0 && skillsStatistics.skillsWithoutEndorsement == 0 ?
                  'DONE' : 'INCOMPLETE'
              }
            </div>
          </div>
          <div className="dashboard-skill-status-action">
            <Link to="/myskills#otherSkills">Seek Endorsements <i className="fa fa-sm fa-arrow-right"/></Link>
          </div>
        </div>
      </div>
    </div>
  );
};

SkillsActivity.propTypes = {
  actions: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  skillsStatistics: PropTypes.object.isRequired
};

const mapStateToProps = ({
  auth: { data: { currentUser: { id: userId } } },
  statistics: { skills: { data: skillsStatistics } }
}) => ({ userId, skillsStatistics });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    getSkillsStatistics
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SkillsActivity);
