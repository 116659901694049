import initialState from './initialState';

import {
  USERS_STATISTICS_REQUEST,
  USERS_STATISTICS_SUCCESS,
  USERS_STATISTICS_FAILURE,

  GET_ALL_EDUCATORS_REQUEST,
  GET_ALL_EDUCATORS_SUCCESS,
  GET_ALL_EDUCATORS_FAILURE,

  SET_SELECTED_EDUCATOR,

  GET_ALL_GROUPS_REQUEST,
  GET_ALL_GROUPS_SUCCESS,
  GET_ALL_GROUPS_FAILURE,

  JOBS_STATISTICS_REQUEST,
  JOBS_STATISTICS_SUCCESS,
  JOBS_STATISTICS_FAILURE,

  FILES_STATISTICS_REQUEST,
  FILES_STATISTICS_SUCCESS,
  FILES_STATISTICS_FAILURE,

  SKILLS_STATISTICS_REQUEST,
  SKILLS_STATISTICS_SUCCESS,
  SKILLS_STATISTICS_FAILURE,

  BADGES_STATISTICS_REQUEST,
  BADGES_STATISTICS_SUCCESS,
  BADGES_STATISTICS_FAILURE,

  SURVEYS_STATISTICS_REQUEST,
  SURVEYS_STATISTICS_SUCCESS,
  SURVEYS_STATISTICS_FAILURE,

  SURVEY_FEEDBACKS_REQUEST,
  SURVEY_FEEDBACKS_SUCCESS,
  SURVEY_FEEDBACKS_FAILURE,

  SET_SELECTED_STATISTICS_EDUCATOR_GROUP
} from '../../constants';

const allEducators = {
  id: 'all-educators',
  name: 'All Educators'
};

const allGroups = {
  id: 'all-groups',
  name: 'All Groups'
};

export default function reducer(state = initialState.statistics, action) {
  switch (action.type) {
  case USERS_STATISTICS_REQUEST:
    return Object.assign({}, state, {
      users: {
        ...state.users,
        isRequesting: true
      }});

  case USERS_STATISTICS_SUCCESS:
    return Object.assign({}, state, {
      users: {
        ...state.users,
        isRequesting: false,
        data: action.data
      }
    });

  case USERS_STATISTICS_FAILURE:
    return Object.assign({}, state, {
      users: {
        ...state.users,
        error: action.error,
        isRequesting: false
      }
    });

  case JOBS_STATISTICS_REQUEST:
    return Object.assign({}, state, {
      jobs: {
        ...state.jobs,
        isRequesting: true
      }
    });

  case JOBS_STATISTICS_SUCCESS:
    return Object.assign({}, state, {
      jobs: {
        ...state.jobs,
        isRequesting: false,
        data: action.data
      }
    });

  case JOBS_STATISTICS_FAILURE:
    return Object.assign({}, state, {
      jobs: {
        ...state.jobs,
        error: action.error,
        isRequesting: false
      }
    });

  case FILES_STATISTICS_REQUEST:
    return Object.assign({}, state, {
      files: {
        ...state.files,
        isRequesting: true
      }
    });

  case FILES_STATISTICS_SUCCESS:
    return Object.assign({}, state, {
      files: {
        ...state.files,
        isRequesting: false,
        data: action.data
      }
    });

  case FILES_STATISTICS_FAILURE:
    return Object.assign({}, state, {
      files: {
        ...state.files,
        error: action.error,
        isRequesting: false
      }
    });

  case SKILLS_STATISTICS_REQUEST:
    return Object.assign({}, state, {
      skills: {
        ...state.skills,
        isRequesting: true
      }
    });

  case SKILLS_STATISTICS_SUCCESS:
    return Object.assign({}, state, {
      skills: {
        ...state.skills,
        isRequesting: false,
        data: action.data
      }
    });

  case SKILLS_STATISTICS_FAILURE:
    return Object.assign({}, state, {
      skills: {
        ...state.skills,
        error: action.error,
        isRequesting: false
      }
    });

  case BADGES_STATISTICS_REQUEST:
    return Object.assign({}, state, {
      badges: {
        ...state.badges,
        isRequesting: false
      }
    });

  case BADGES_STATISTICS_SUCCESS:
    return Object.assign({}, state, {
      badges: {
        ...state.badges,
        data: action.data._embedded.skills,
        isRequesting: false
      }
    });

  case BADGES_STATISTICS_FAILURE:
    return Object.assign({}, state, {
      badges: {
        ...state.badges,
        error: action.error,
        isRequesting: false
      }
    });

  case SURVEYS_STATISTICS_REQUEST:
    return Object.assign({}, state, {
      surveys: {
        ...state.surveys,
        isRequesting: true
      }
    });

  case SURVEYS_STATISTICS_SUCCESS:
    return Object.assign({}, state, {
      surveys: {
        ...state.surveys,
        data: action.data._embedded.surveys,
        isRequesting: false
      }
    });

  case SURVEYS_STATISTICS_FAILURE:
    return Object.assign({}, state, {
      surveys: {
        ...state.surveys,
        error: action.error,
        isRequesting: false
      }
    });

  case SURVEY_FEEDBACKS_REQUEST:
    return Object.assign({}, state, {
      surveyFeedbacks: {
        ...state.surveyFeedbacks,
        isRequesting: true
      }
    });

  case SURVEY_FEEDBACKS_SUCCESS:
    return Object.assign({}, state, {
      surveyFeedbacks: {
        ...state.surveyFeedbacks,
        data: action.data._embedded.survey_feedbacks,
        paginationData: {
          page: action.data.page,
          page_count: action.data.page_count,
          total_items: action.data.total_items
        },
        isRequesting: false
      }
    });

  case SURVEY_FEEDBACKS_FAILURE:
    return Object.assign({}, state, {
      surveyFeedbacks: {
        ...state.surveyFeedbacks,
        error: action.error,
        isRequesting: false
      }
    });

  case GET_ALL_EDUCATORS_REQUEST:
    return Object.assign({}, state, {
      educators: {
        ...state.educators,
        isRequesting: true,
        isInitialRequest: action.isInitialRequest
      }
    });

  case GET_ALL_EDUCATORS_SUCCESS:
    return Object.assign({}, state, {
      educators: {
        ...state.educators,
        isRequesting: false,
        isInitialRequest: false,

        data: state.educators.data.length === 0 &&
          action.data._embedded.educators.length === 1 ?
          action.data._embedded.educators : state.educators.isInitialRequest ?
            [allEducators, ...action.data._embedded.educators] :
            [...state.educators.data, ...action.data._embedded.educators],

        selectedEducator: state.educators.data.length === 0 &&
          action.data._embedded.educators.length === 1 ?
          action.data._embedded.educators[0] : allEducators,

        paginationData: {
          page: action.data.page,
          page_count: action.data.page_count,
          total_items: action.data.total_items
        }
      }
    });

  case GET_ALL_EDUCATORS_FAILURE:
    return Object.assign({}, state, {
      educators: {
        ...state.educators,
        error: action.error,
        isRequesting: false,
        isInitialRequest: false
      }
    });

  case SET_SELECTED_EDUCATOR:
    return Object.assign({}, state, {
      educators: {
        ...state.educators,
        selectedEducator: action.selectedEducator
      }
    });

  case GET_ALL_GROUPS_REQUEST:
    return Object.assign({}, state, {
      groups: {
        ...state.groups,
        isRequesting: true,
        isInitialRequest: action.isInitialRequest
      }
    });

  case GET_ALL_GROUPS_SUCCESS:
    return Object.assign({}, state, {
      groups: {
        ...state.groups,
        isRequesting: false,
        isInitialRequest: false,

        data: state.groups.data.length === 0 &&
          action.data._embedded.statistics.length === 1 ?
          action.data._embedded.statistics : state.groups.isInitialRequest ?
            [allGroups, ...action.data._embedded.statistics] :
            [...state.groups.data, ...action.data._embedded.statistics],

        selectedGroup: state.groups.data.length === 0 &&
          action.data._embedded.statistics.length === 1 ?
          action.data._embedded.statistics[0] : allGroups,

        paginationData: {
          page: action.data.page,
          page_count: action.data.page_count,
          total_items: action.data.total_items
        }
      }
    });

  case GET_ALL_GROUPS_FAILURE:
    return Object.assign({}, state, {
      groups: {
        ...state.groups,
        error: action.error,
        isRequesting: false,
        isInitialRequest: false
      }
    });

  case SET_SELECTED_STATISTICS_EDUCATOR_GROUP:
    return Object.assign({}, state, {
      groups: {
        ...state.groups,
        selectedGroup: action.selectedGroup
      }
    });

  default:
    return state;
  }
}
