import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { findDOMNode } from 'react-dom';

import { withHooks } from '../../utils/withHooks';

import * as messagesActions from '../../redux/actions/messages';
import * as followersActions from '../../redux/actions/followers';
import * as routerActions from '../../redux/actions/router';

import MessageListItem from '../../components/messages/MessageListItem';
import Loading from '../../components/common/Loading';
import DeleteMessageConfirmModal from '../../components/messages/DeleteMessageConfirmModal';

class MessageListItemsWrapper extends Component {
  static propTypes = {
    actions: PropTypes.object,
    messages: PropTypes.object,
    isMobile: PropTypes.bool,
    isDeleting: PropTypes.object,
    slug: PropTypes.string,
    navigate: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state ={
      messageToDelete: null
    };

    this.setDivRef = this.setDivRef.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.onThrashIconClicked = this.onThrashIconClicked.bind(this);
    this.onMessageItemClicked = this.onMessageItemClicked.bind(this);
  }

  componentDidMount(){
    if(this.props.isMobile){
      return window.
        addEventListener('scroll', this.handleScroll);
    }

    findDOMNode(this.scrollDiv).
      addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount(){
    if(this.props.isMobile){
      return window
        .removeEventListener('scroll', this.handleScroll);
    }

    findDOMNode(this.scrollDiv).
      removeEventListener('scroll', this.handleScroll);
  }

  setDivRef(div) {
    if(div)
      this.scrollDiv = div;
  }

  handleScroll(){
    const { messages, actions } = this.props;
    const { page, page_count } = messages.paginationData;

    if(page < page_count && !messages.isRequesting && (window.innerHeight + window.pageYOffset - 30) >= document.body.offsetHeight)
      return actions.messagesRequest(page+1);

    const endReached = this.scrollDiv.offsetHeight + this.scrollDiv.scrollTop == this.scrollDiv.scrollHeight;

    if(endReached && page < page_count && !messages.isRequesting){
      return actions.messagesRequest(page+1);
    }

  }

  onThrashIconClicked(event){
    event.preventDefault();

    this.setState({
      messageToDelete: event.target.id
    });
  }

  closeDeleteModal(){
    this.setState({
      messageToDelete: null
    });
  }

  onConfirmDelete(){
    const { actions } = this.props;

    actions.deleteMessage(this.state.messageToDelete).
      then(() => this.closeDeleteModal());
  }

  onMessageItemClicked(id){
    return () => {
      this.props.actions.redirect(`/messages/${id}`);
      this.props.navigate(`/messages/${id}`);
    };
  }

  render() {
    const { messages, isMobile, isDeleting } = this.props;
    const { data, isRequesting, query } = messages;
    const { messageToDelete } = this.state;

    return(
      <div
        ref={this.setDivRef}
        className="messageListViewContainer">
        {data.map((message,index) =>
          (<MessageListItem
            key={index}
            onMessageItemClicked={this.onMessageItemClicked}
            slug={this.props.slug}
            isMobile={isMobile}
            message={message}
            onThrashIconClicked={this.onThrashIconClicked}/>))}

        {
          data.length === 0 && query !== '' &&
            <div style={{padding: 20}}>No results found.</div>
        }

        {isRequesting && <div className="p-2"><Loading/></div>}

        {
          messageToDelete !== null &&
            <DeleteMessageConfirmModal
              isOpen={messageToDelete !== null}
              onCancel={this.closeDeleteModal}
              onConfirm={this.onConfirmDelete}
              isDeleting={isDeleting}/>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    messages: state.messages,
    userId: state.auth.data.currentUser.id,
    isDeleting: state.messages.isDeleting,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, messagesActions, followersActions, routerActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(MessageListItemsWrapper));
