import PropTypes from 'prop-types';
import React from 'react';
import CpHtmlParser from '../common/CpHtmlParser';

const SummarySection = ({ summary }) => {
  return (
    <div className="ql-editor no-padding-in-quill">
      <CpHtmlParser htmlString={summary}/>
    </div>
  );
};

SummarySection.propTypes = {
  summary: PropTypes.string
};

export default SummarySection;
