import { CALL_API } from '../../middlewares/api';

import {
  PUBLICATIONS_REQUEST,
  PUBLICATIONS_SUCCESS,
  PUBLICATIONS_FAILURE,
  CREATE_PUBLICATION_REQUEST,
  CREATE_PUBLICATION_SUCCESS,
  CREATE_PUBLICATION_FAILURE,
  UPDATE_PUBLICATION_REQUEST,
  UPDATE_PUBLICATION_SUCCESS,
  UPDATE_PUBLICATION_FAILURE,
  DELETE_PUBLICATION_REQUEST,
  DELETE_PUBLICATION_SUCCESS,
  DELETE_PUBLICATION_FAILURE,
} from '../../constants';

export const publicationsRequest = (userId) => {
  const endpoint = `user/publication?user=${userId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [PUBLICATIONS_REQUEST, PUBLICATIONS_SUCCESS, PUBLICATIONS_FAILURE],
    },
  };
};

export const savePublication = (data) => {
  const endpoint = 'user/publication';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        CREATE_PUBLICATION_REQUEST,
        CREATE_PUBLICATION_SUCCESS,
        CREATE_PUBLICATION_FAILURE,
      ],
    },
  };
};

export const updatePublication = (index, data) => {
  const endpoint = `user/publication/${data.id}`;

  return {
    [CALL_API]: {
      data,
      index,
      endpoint,
      method: 'put',
      authenticated: true,
      types: [
        UPDATE_PUBLICATION_REQUEST,
        UPDATE_PUBLICATION_SUCCESS,
        UPDATE_PUBLICATION_FAILURE,
      ],
    },
  };
};

export const deletePublication = (publicationId) => {
  const endpoint = `user/publication/${publicationId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'delete',
      id: publicationId,
      authenticated: true,
      types: [
        DELETE_PUBLICATION_REQUEST,
        DELETE_PUBLICATION_SUCCESS,
        DELETE_PUBLICATION_FAILURE,
      ],
    },
  };
};
