import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { checkHttp } from '../../../utils';
import CpHtmlParser from '../../common/CpHtmlParser';

const styles = {
  editButton: {
    marginLeft: 10,
    cursor: 'pointer',
  }
};

const ProjectCard = ({ project, onEditProjectClick, isPublicProfile }) => {
  const startDate = moment(project.startdate.date).format('MMMM YYYY');
  let endDate;

  if (project.noend) {
    endDate = 'Present';
  } else {
    endDate = moment(project.enddate.date).format('MMMM YYYY');
  }

  const urlAvailable = project.url !== '';
  const urlString = checkHttp(project.url) ?
    project.url : `//${project.url}`;

  return (
    <div className="card-block">
      <div className="projects">
        <div className="row">
          <div className="col-lg-12">
            <div className="display">
              <div className="item">
                <div className="title">
                  {!urlAvailable ?
                    project.title :
                    <a href={urlString} target="_blank" rel="noopener noreferrer">{project.title}</a>}

                  {! isPublicProfile ?
                    <i
                      style={styles.editButton}
                      onClick={onEditProjectClick}
                      id={`update-project-form-${project.id}`}
                      className="fa fa-xs fa-pencil orange" aria-hidden="true"/> : ''}
                </div>
                <div className="description">
                  <div className="ql-editor no-padding-in-quill">
                    <CpHtmlParser htmlString={project.description}/>
                  </div>
                </div>
                <div className="date">{startDate}&nbsp;-&nbsp;{endDate}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProjectCard.propTypes = {
  project: PropTypes.object.isRequired,
  isPublicProfile: PropTypes.bool.isRequired,
  onEditProjectClick: PropTypes.func.isRequired
};

export default ProjectCard;
