import initialState from './initialState';

import {
  MESSAGES_REQUEST,
  MESSAGES_SUCCESS,
  MESSAGES_FAILURE,
  CREATE_MESSAGE_REQUEST,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_FAILURE,
  DELETE_MESSAGE_REQUEST,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_FAILURE,
  INITIALIZE_MESSAGES_STORE,
  SET_A_THREAD_AS_READ,
  SEARCH_MESSAGES_REQUEST,
  SEARCH_MESSAGES_SUCCESS,
  SEARCH_MESSAGES_FAILURE,
  REPLY_TO_A_THREAD_REQUEST,
  REPLY_TO_A_THREAD_SUCCESS,
  REPLY_TO_A_THREAD_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.messages, action) {
  switch (action.type) {
  case INITIALIZE_MESSAGES_STORE:
    return Object.assign({}, state, initialState.messages);

  case SET_A_THREAD_AS_READ: {
    const index = state.data.findIndex(item => item.id === action.id);

    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, index),
        Object.assign({}, state.data[index], {
          readAt: {
            date: new Date(),
            timezone_type: 3,
            timezone: 'UTC',
          },
        }),
        ...state.data.slice(index + 1),
      ],
    });
  }

  case MESSAGES_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case MESSAGES_SUCCESS:
    return Object.assign({}, state, {
      data: state.data.concat(action.data._embedded.threads),
      isRequesting: false,
      error: {},
      paginationData: {
        page: action.data.page,
        page_count: action.data.page_count,
        total_items: action.data.total_items,
      },
    });

  case MESSAGES_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case SEARCH_MESSAGES_REQUEST:
    return Object.assign({}, state, {
      isSearching: true,
      query: action.query,
    });

  case SEARCH_MESSAGES_SUCCESS:
    return Object.assign({}, state, {
      data: action.data._embedded.threads,
      isSearching: false,
      error: {},
      paginationData: Object.assign({}, state.paginationData, {
        page: action.data.page,
        page_count: action.data.page_count,
      }),
    });

  case SEARCH_MESSAGES_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSearching: false,
    });

  case CREATE_MESSAGE_REQUEST:
    return Object.assign({}, state, { isSubmitting: true });

  case CREATE_MESSAGE_SUCCESS: {
    return Object.assign({}, state, {
      error: {},
      isSubmitting: false,
      data: [action.data, ...state.data],
      paginationData: Object.assign({}, state.paginationData, {
        total_items: state.paginationData.total_items + 1,
      }),
    });
  }

  case CREATE_MESSAGE_FAILURE:
    return Object.assign({}, state, { isSubmitting: false });

  case DELETE_MESSAGE_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, messageId: action.id },
    });

  case DELETE_MESSAGE_SUCCESS: {
    const dataAfterDeleting = state.data.filter(
      item => item.id !== state.isDeleting.messageId
    );

    return Object.assign({}, state, {
      isDeleting: { status: false, messageId: null },
      error: {},
      data: dataAfterDeleting,
      paginationData: Object.assign({}, state.paginationData, {
        total_items: state.paginationData.total_items - 1,
      }),
    });
  }

  case DELETE_MESSAGE_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: { status: false, messageId: null },
    });

  case REPLY_TO_A_THREAD_REQUEST:
    return Object.assign({}, state, {
      isReplying: { status: true, messageId: action.id },
    });

  case REPLY_TO_A_THREAD_SUCCESS: {
    const { isReplying, data } = state;
    const index = data.findIndex(dat => dat.id === isReplying.messageId);

    return Object.assign({}, state, {
      data:
          index >= 0
            ? [
              ...state.data.slice(0, index),
              Object.assign({}, state.data[index], {
                user: action.data.user,
                lastBody: action.data.body,
              }),
              ...state.data.slice(index + 1),
            ]
            : data,
      isReplying: { status: false, messageId: null },
    });
  }

  case REPLY_TO_A_THREAD_FAILURE:
    return Object.assign({}, state, {
      isReplying: { status: false, messageId: null },
    });

  default:
    return state;
  }
}
