import PropTypes from 'prop-types';
import React from 'react';

import Button from '../../common/Button';
import TextArea from '../../common/TextArea';

const ReflectionQuestionForm = ({
  question,
  hasAnswer,
  name,
  value,
  error,
  onChange,
  onSave,
  isSubmitting,
  onCancel,
  onClearText
}) => {
  return (
    <div className="question-item">
      <div className="form-group">
        <div>
          <p className="float-left">
            {question}
          </p>
          {
            hasAnswer &&
              <a
                style={{color: 'inherit'}}
                className="clickable float-right">
                <span
                  id={name}
                  onClick={onCancel}
                  className="far fa-times-circle fa-lg"/>
              </a>
          }
        </div>

        <TextArea
          name={name}
          value={value}
          error={error}
          onChange={onChange}
          styles={{marginTop: 0, color: 'white'}}
          placeholder="Reflect and write your thoughtful response here. Consider sharing your response to help others learn from your insights and experiences."
          rows="5"
          cols="40"/>
        <div className="row align-items-center">
          <div className="col-7">
            <em style={{fontSize: 14}}>2,000 characters max.</em>
          </div>
          <div className="col-5">
            <div className="question-item--button-group">
              <div>
                {
                  !hasAnswer && value !== '' &&
                    <Button
                      buttonId={name}
                      type="button"
                      onClick={onClearText}
                      buttonClass="btn-secondary"
                      buttonText="Clear"
                      styles={{ marginLeft: 0 }}/>
                }
              </div>
              <div>
                <Button
                  buttonId={name}
                  type="submit"
                  onClick={onSave}
                  disabled={isSubmitting}
                  buttonClass="btn-primary"
                  buttonText={isSubmitting ? 'Saving...' : 'Save'}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ReflectionQuestionForm.propTypes = {
  error: PropTypes.string,
  question: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  hasAnswer: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onClearText: PropTypes.func
};

export default ReflectionQuestionForm;
