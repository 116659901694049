import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import update from 'immutability-helper';
import { stringifyJSON } from '../../../utils';

import * as profileActions from '../../../redux/actions/profile';
import * as componentsActions from '../../../redux/actions/components';

import SortSectionCard from './SortSectionCard';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class SortSectionsModal extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    profileOrder: PropTypes.array,
    isUpdating: PropTypes.bool.isRequired,
    onRearrangeResumeOrder: PropTypes.func,
    isOpen: PropTypes.bool.isRequired

  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      profileOrder: this.props.profileOrder
    };

    this.moveCard = this.moveCard.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSave = this.onSave.bind(this);
  }


  moveCard(dragIndex, hoverIndex) {
    const { profileOrder } = this.state;
    const dragItem = profileOrder[dragIndex];

    this.setState(update(this.state, {
      profileOrder: {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragItem]
        ]
      }
    }));
  }

  onCancel(){
    this.props.actions.closeModal();
  }

  onSave(){
    const { profile, onRearrangeResumeOrder } = this.props;
    const { profileOrder } = this.state;

    if(onRearrangeResumeOrder){
      onRearrangeResumeOrder(profileOrder);
      return this.onCancel();
    }

    const profileData = {
      profileorder: stringifyJSON(profileOrder)
    };

    this.props.actions.updateProfile(profile.id, profileData).
      then(() => this.onCancel());
  }


  render() {
    const { isOpen, isUpdating } = this.props;
    const { profileOrder } = this.state;

    return (
      <Modal
        backdrop="static"
        size={'md'}
        isOpen={isOpen}
        toggle={this.onCancel}>
        <ModalHeader
          toggle={this.onCancel}>
          Sort Career Site Sections
        </ModalHeader>
        <ModalBody>
          <ul className="sort-section-container">
            {profileOrder.map((item, index)=>
              (<SortSectionCard
                key={index}
                index={index}
                moveCard={this.moveCard}
                section={item}/>))}
          </ul>
        </ModalBody>
        <ModalFooter>
          <button
            onClick={this.onCancel}
            disabled={isUpdating}
            className="btn btn-secondary">
            Cancel
          </button>

          <button
            onClick={this.onSave}
            disabled={isUpdating}
            className="btn btn-primary">
            {isUpdating ? 'Applying...' : 'Apply'}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state,ownProps) => {
  const defaultOrder = [
    { id: 1, name: 'Skills' },
    { id: 2, name: 'Education' },
    { id: 3, name: 'Experience' },
  ];
  const profileOrder = ownProps.profileOrder === 'undefined' ?
    defaultOrder :
    ownProps.profileOrder.length === 0 ?
      defaultOrder :
      ownProps.profileOrder;

  return {
    profileOrder,
    isUpdating: state.profile.isUpdating,
    profile: state.profile.data
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, componentsActions, profileActions);
  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SortSectionsModal);
