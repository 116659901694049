import React, { Component } from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

class SignUp extends Component {
  render() {
    return (
      <div className="interior essential  homepage_nli">
        <Helmet title="Sign Up"/>
        <div className="talent-development">
          <div className="toolkit">
            <header>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <h1>
                      Which account fits you?
                    </h1>
                  </div>
                </div>
              </div>
            </header>
            <section>
              <div className="container">
                <div className="account-grid">
                  <div className="account-card">
                    <div className="account-desc">
                      <h4>For Talent</h4>
                      <p>Students, job seekers, growth seekers, or career changers using CareerPrepped for personal use.</p>
                    </div>
                    <Link
                      state={{defaultTab: 'for-individuals'}}
                      className="btn btn-primary"
                      to="/auth/subscription">
                      This account fits me best
                    </Link>
                  </div>
                  <div className="account-card">
                    <div className="account-desc">
                      <h4>For Talent Developers</h4>
                      <p>Career colleges, CTE programs, bootcamps, or workforce orgs using CareerPrepped to prepare skilled talent for career success.</p>
                    </div>
                    <Link
                      state={{defaultTab: 'for-organizations'}}
                      className="btn btn-primary"
                      to="/auth/subscription">
                      This account fits me best
                    </Link>
                  </div>
                  <div className="account-card">
                    <div className="account-desc">
                      <h4>For Talent Employers</h4>
                      <p>Recruiters, managers, staffing experts, or industry pros using CareerPrepped to find, attract, and hire skilled talent.</p>
                    </div>
                    <Link
                      state={{defaultTab: 'for-employers'}}
                      className="btn btn-primary"
                      to="/auth/subscription">
                      This account fits me best
                    </Link>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default SignUp;
