import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Validator from '../../../validator';

import * as clubActions from '../../../redux/actions/clubs';
import * as componentsActions from '../../../redux/actions/components';

import Loading from '../../../components/common/Loading';
import ConfirmModal from '../../../components/portfolio/ConfirmModal';
import ClubForm from '../../../components/profile/forms/ClubForm';
import ClubList from '../../../components/profile/sections/ClubList';
import ProfileCardHeader from '../../../components/profile/header/ProfileCardHeader';
import formWithModal from '../../../components/profile/modal/formWithModal';

class Club extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    club: PropTypes.object.isRequired,
    clubs: PropTypes.array.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    isPublicProfile: PropTypes.bool.isRequired,
    showVisibilitySelect: PropTypes.bool,
    onChangeVisibility: PropTypes.func,
    isEditingSections: PropTypes.bool,
    onToggleSectionEdit: PropTypes.func,
    onConfirmDeleteSection: PropTypes.func,
    modal: PropTypes.string,
    isUpdatingProfile: PropTypes.bool,
    profile: PropTypes.object,
    isDeleting: PropTypes.bool.isRequired,
    isUpdating: PropTypes.bool.isRequired,
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      isEditing: false,
      isSubmitting: false,
      editingId: null,
      club: Object.assign({}, this.props.club),
      editorText: {description: this.props.club.description}
    };

    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onDeleteSection = this.onDeleteSection.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.onDeleteClub = this.onDeleteClub.bind(this);
    this.onEditClub = this.onEditClub.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
  }

  componentDidMount() {
    const { profile } = this.props;
    this.props.actions.clubsRequest(profile.id);
  }

  isValid(field = null) {
    const rules = {
      name: ['required', 'minLength|6', 'maxLength|100'],
    };
    const { club, errors: prevErrors} = this.state;
    const validate = Validator.createValidator(rules, club, field);
    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  isValidDescription(field = null) {
    const rules = {
      description: ['maxWords|200']
    };
    const { editorText, errors: prevErrors } = this.state;
    const validate = Validator.createValidator(rules, editorText, field);
    const { errors, isValid } = validate;

    if (field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }
    this.setState({ errors: Object.assign({}, prevErrors, errors) });

    return isValid;
  }

  onChange(event) {
    const { name, value } = event.target;
    const { club } = this.state;

    club[name] = value;

    this.setState({ club }, () => this.isValid(name));
  }

  onChangeDescription(value, ...params) {
    const { club, editorText } = this.state;

    club['description'] = value;
    editorText['description'] = params[2].getText().replace(/\r?\n|\r/gm, ' ');

    this.setState({ club, editorText }, () => this.isValidDescription('description'));
  }

  onSave(event) {
    event.preventDefault();

    this.setState({ isSubmitting: true });

    if (! this.isValid() || ! this.isValidDescription()) {
      return this.setState({ isSubmitting: false });
    }

    const { club, editingId } = this.state;
    const { actions, clubs } = this.props;

    if(editingId !== null){
      const index = clubs.findIndex(element =>
        element.id === club.id);

      return actions.updateClub(index, club)
        .then(() => this.handleResponse());
    }

    this.props.actions.saveClub(club)
      .then(() => this.handleResponse());
  }

  handleResponse() {
    const club = { name: '', description: '' };

    this.setState({
      isEditing: false,
      isSubmitting: false,
      club,
      editingId: null
    }, () => this.props.onToggleSectionEdit('club'));
  }

  onCancel() {
    this.setState({
      isEditing: false,
      errors: {},
      editingId: null,
      club: this.props.club
    }, () => this.props.onToggleSectionEdit('club'));
  }

  onEditClick(event) {
    event.preventDefault();

    this.setState({ isEditing: true }, () => this.props.onToggleSectionEdit('club'));
  }

  EditingClub = formWithModal(ClubForm);

  onDeleteSection(){
    this.props.actions.openModal('delete-webpage-club-section-modal');
  }

  onCloseModal(){
    this.props.actions.closeModal();
  }

  onDeleteConfirm(){
    const { clubs, actions } = this.props;

    clubs.map(club => actions.deleteClub(club.id));
    this.props.onConfirmDeleteSection('Club');
  }

  onEditClub(id){
    const { clubs } = this.props;
    const clubObject = clubs.find(club => club.id === id);
    const club = Object.assign({}, this.state.club, clubObject);
    this.setState({
      isEditing: true,
      club,
      editingId: id
    }, () => this.props.onToggleSectionEdit('club'));
  }

  onDeleteClub(event) {
    event.preventDefault();

    const { club } = this.state;
    this.props.actions.deleteClub(club.id)
      .then(() => this.handleResponse());
  }

  render() {
    const {
      modal,
      clubs,
      isRequesting,
      isPublicProfile,
      showVisibilitySelect,
      onChangeVisibility,
      isEditingSections,
      isUpdatingProfile,
      isUpdating,
      isDeleting
    } = this.props;
    const { errors, club, isEditing, isSubmitting, editingId } = this.state;

    if(clubs.length === 0 && isPublicProfile){
      return null;
    }

    const isDeleteConfirmModalOpen = modal && modal === 'delete-webpage-club-section-modal';

    return (
      <div className="profile-item activities">
        <ProfileCardHeader
          isEditing={isEditing}
          isEditingSections={isEditingSections}
          type="list"
          title="Extracurricular Activites & Clubs"
          onEditClick={this.onEditClick}
          isPublicProfile={isPublicProfile}
          showVisibilitySelect={showVisibilitySelect}
          onDeleteClick={this.onDeleteSection}
          onChangeVisibility={onChangeVisibility}
          icon="activities"
          isDeletable/>

        {isRequesting ?
          <Loading/> :
          isEditing ?
            <div className="profile-item__card">
              <this.EditingClub
                errors={errors}
                club={club}
                isSubmitting={isSubmitting}
                isUpdating={isUpdating}
                isDeleting={isDeleting}
                showDelete={editingId !== null}
                onChange={this.onChange}
                onSave={this.onSave}
                onCancel={this.onCancel}
                exampleType={'Activities & Clubs'}
                onChangeDescription={this.onChangeDescription}
                onDelete={this.onDeleteClub}/>
            </div> :
            <ClubList
              clubs={clubs}
              onEdit={this.onEditClub}
              isPublicProfile={isPublicProfile}/>}
        {
          isDeleteConfirmModalOpen &&
            <ConfirmModal
              modalHeaderText="Delete Section"
              modalMessage="Are you sure you want to permanently delete this Career Site section?"
              modalFooterButtonText="Yes, Delete"
              onCancel={this.onCloseModal}
              onConfirm={this.onDeleteConfirm}
              isConfirming={isUpdatingProfile}
              confirminginProgressText="Deleting..."
              isOpen={isDeleteConfirmModalOpen}/>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const club = { name: '', description: '' };

  return {
    club,
    modal: state.components.modal,
    clubs: state.clubs.data,
    isUpdatingProfile: state.profile.isUpdating,
    isRequesting: state.clubs.isRequesting,
    isDeleting: state.clubs.isDeleting.status,
    isUpdating: state.clubs.isUpdating.status
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, componentsActions, clubActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Club);
