import { isUndefined } from 'lodash';

import initialState from './initialState';

import {
  USERS_REQUEST,
  USERS_SUCCESS,
  USERS_FAILURE,
  FOLLOW_USER_REQUEST,
  FOLLOW_USER_SUCCESS,
  FOLLOW_USER_FAILURE,
  UNFOLLOW_USER_REQUEST,
  UNFOLLOW_USER_SUCCESS,
  UNFOLLOW_USER_FAILURE,
  SET_USERS_SORT_ORDER,
  ONCHANGE_USERS_SEARCH_QUERY,
  ONCHANGE_USERS_FILTERS,
  DISPLAY_ADVANCED_FILTERS,
  RESET_FILTER_PARAMS,
} from '../../constants';

export default function reducer(state = initialState.users, action) {
  switch (action.type) {
  case USERS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case USERS_SUCCESS:
    return Object.assign({}, state, {
      data: isUndefined(action.data._embedded.users)
        ? action.data._embedded.membership
        : action.data._embedded.users,

      page: isUndefined(action.data.page)
        ? initialState.users.page
        : action.data.page,

      pageSize: isUndefined(action.data.page_size)
        ? initialState.users.pageSize
        : action.data.page_size,

      pageCount: isUndefined(action.data.page_count)
        ? initialState.users.pageCount
        : action.data.page_count,

      isAllMessageDisabled: action.data._embedded.users?.every( res =>
        res.privateMessagingDisabled
      ),
        
      isRequesting: false,
    });

  case USERS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case SET_USERS_SORT_ORDER:
    return Object.assign({}, state, { sort: action.sort });

  case FOLLOW_USER_REQUEST:
  case UNFOLLOW_USER_REQUEST:
    return Object.assign({}, state, {
      isFollowing: {
        status: true,
        index: action.index,
      },
    });

  case FOLLOW_USER_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, state.isFollowing.index),
        Object.assign({}, state.data[state.isFollowing.index], {
          isUserFollower: {
            status: true,
            connectId: action.data.record,
          },
        }),
        ...state.data.slice(state.isFollowing.index + 1),
      ],
      isFollowing: {
        status: false,
        index: null,
      },
    });

  case UNFOLLOW_USER_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, state.isFollowing.index),
        Object.assign({}, state.data[state.isFollowing.index], {
          isUserFollower: {
            status: false,
            connectId: '',
          },
        }),
        ...state.data.slice(state.isFollowing.index + 1),
      ],
      isFollowing: {
        status: false,
        index: null,
      },
    });

  case FOLLOW_USER_FAILURE:
  case UNFOLLOW_USER_FAILURE:
    return Object.assign({}, state, {
      isFollowing: {
        status: false,
        index: null,
      },
    });

  case ONCHANGE_USERS_SEARCH_QUERY:
    return Object.assign({}, state, {
      filters: {
        ...state.filters,
        searchQuery: action.searchQuery,
      },
    });

  case ONCHANGE_USERS_FILTERS:
    return Object.assign({}, state, {
      filters: {
        ...state.filters,
        ...action.filters,
      },
    });

  case DISPLAY_ADVANCED_FILTERS:
    return Object.assign({}, state, {
      filters: action.displayAdvancedFilters
        ? state.filters
        : initialState.users.filters,
      page: initialState.users.page,
      displayAdvancedFilters: action.displayAdvancedFilters,
    });

  case RESET_FILTER_PARAMS:
    return Object.assign({}, state, {
      page: initialState.users.page,
      pageCount: initialState.users.pageCount,
      pageSize: initialState.users.pageSize,
      sort: initialState.users.sort,
      filters: initialState.users.filters,
    });

  default:
    return state;
  }
}
