import initialState from './initialState';

import {
  ACCESS_REQUEST,
  ACCESS_SUCCESS,
  ACCESS_FAILURE,
  REGISTRATION_REQUEST,
  REGISTRATION_SUCCESS,
  REGISTRATION_FAILURE,
  SELF_REGISTRATION_REQUEST,
  SELF_REGISTRATION_SUCCESS,
  SELF_REGISTRATION_FAILURE,
  SURVEY_FEEDBACK_REQUEST,
  SURVEY_FEEDBACK_SUCCESS,
  SURVEY_FEEDBACK_FAILURE,
  JOIN_MEMBERSHIP_REQUEST,
  JOIN_MEMBERSHIP_SUCCESS,
  JOIN_MEMBERSHIP_FAILURE,
  EDUCATOR_SIGNUP_REQUEST,
  EDUCATOR_SIGNUP_SUCCESS,
  EDUCATOR_SIGNUP_FAILURE,
  RECRUITER_REQUESTS_REQUEST,
  RECRUITER_REQUESTS_SUCCESS,
  RECRUITER_REQUESTS_FAILURE,
  INDUSTRY_MENTOR_REQUESTS_REQUEST,
  INDUSTRY_MENTOR_REQUESTS_SUCCESS,
  INDUSTRY_MENTOR_REQUESTS_FAILURE
} from '../../constants';

export default function reducer(state = initialState.registration, action) {
  switch (action.type) {
  case SURVEY_FEEDBACK_REQUEST:
    return Object.assign({}, state, { isSubmitting: true });

  case SURVEY_FEEDBACK_SUCCESS:
    return Object.assign({}, state, {
      isSubmitting: false,
      feedbacks: action.data.feedbacks
    });

  case SURVEY_FEEDBACK_FAILURE:
    return Object.assign({}, state, {
      isSubmitting: false,
      error: action.error
    });

  case REGISTRATION_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case REGISTRATION_SUCCESS:
    return Object.assign({}, state, { isRequesting: false, status: true });

  case REGISTRATION_FAILURE:
    return Object.assign({}, state, { isRequesting: false });

  case JOIN_MEMBERSHIP_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case JOIN_MEMBERSHIP_SUCCESS:
    return Object.assign({}, state, { isRequesting: false });

  case JOIN_MEMBERSHIP_FAILURE:
    return Object.assign({}, state, { isRequesting: false });

  case ACCESS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case ACCESS_SUCCESS:
    return Object.assign({}, state, {
      isRequesting: false,
      accessRequests: action.data._embedded.requests,
    });

  case ACCESS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case SELF_REGISTRATION_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: true,
    });

  case SELF_REGISTRATION_SUCCESS:
    return Object.assign({}, state, {
      error: {},
      isSubmitting: false,
      data: action.data,
    });

  case SELF_REGISTRATION_FAILURE:
    return Object.assign({}, state, {
      isSubmitting: false,
      error: action.error,
    });

  case EDUCATOR_SIGNUP_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: true,
    });

  case EDUCATOR_SIGNUP_SUCCESS:
    return Object.assign({}, state, {
      error: {},
      isSubmitting: false,
      data: action.data,
    });

  case EDUCATOR_SIGNUP_FAILURE:
    return Object.assign({}, state, {
      isSubmitting: false,
      error: action.error,
    });

  case INDUSTRY_MENTOR_REQUESTS_REQUEST:
    return Object.assign({}, state, { isSubmitting: true });

  case INDUSTRY_MENTOR_REQUESTS_SUCCESS:
    return Object.assign({}, state, { isSubmitting: false });

  case INDUSTRY_MENTOR_REQUESTS_FAILURE:
    return Object.assign({}, state, { isSubmitting: false, error: action.error });

  case RECRUITER_REQUESTS_REQUEST:
    return Object.assign({}, state, { isSubmitting: true });

  case RECRUITER_REQUESTS_SUCCESS:
    return Object.assign({}, state, { isSubmitting: false });

  case RECRUITER_REQUESTS_FAILURE:
    return Object.assign({}, state, { isSubmitting: false, error: action.error });

  default:
    return state;
  }
}
