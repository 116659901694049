import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

const GroupAdminListModal = ({ isOpen, onClose, group }) => {
  return (
    <Modal
      size="md"
      className="modal-margin-top"
      backdrop="static"
      isOpen={isOpen}
      toggle={onClose}>
      <ModalHeader
        toggle={onClose}>{group.name}</ModalHeader>
      <ModalBody>
        <h6 style={{ fontWeight: 'bold' }} className="mb-3">Group Admins</h6>
        <ul className="mb-0">
          {group.groupAdmins.map(admin => <li key={admin.id}>{admin.name}</li>)}
        </ul>
      </ModalBody>
      <ModalFooter>
        <button
          onClick={onClose}
          className="btn btn-secondary float-left">
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
};

GroupAdminListModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired
};

export default GroupAdminListModal;
