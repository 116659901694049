import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';
import LoadedAttachment from './LoadedAttachment';
import Loading from '../common/Loading';
import TextArea from '../common/TextArea';
import MessageDetailsItem from './MessageDetailsItem';
import MessageDetailsHeader from './MessageDetailsHeader';

const styles = {
  dropzoneStyle: {
    cursor: 'pointer',
    marginLeft: 10,
    display: 'inline-block'
  },
  activeStyle: {
    border: '1px dashed #9e9d9d'
  }
};

const MessageDetails = ({
  query,
  unitThread,
  messageText,
  onChangeMessage,
  onSubmit,
  isReplying,
  onSelectFile,
  onRemoveAttachment,
  onDeleteMessage,
  attachment,
  isDeleting,
  messageContentContainerRef,
  isMobile,
  isRequesting,
  replies,
  userId,
  currentProfile,
  isUserListTooltipOpen,
  toggleUserListTooltip,
  toggleUserDetailsModal
}) => {
  const validMimeType = 'image/*, application/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/*';

  if(Object.keys(unitThread.thread).length === 0 && query !== '' && !isRequesting)
    return (
      <div>
        <div className="messageDetailContainer border-bottom-solid clearfix">
          <div
            ref={messageContentContainerRef}
            className="messageContentContainer"
            style={{padding: '20px', textAlign: 'center', marginTop: 50}}>
            No message to display.
          </div>
        </div>
      </div>
    );

  const isNoReply = Object.keys(unitThread.thread).length > 0 &&
    unitThread.thread.sender.email === 'do-not-reply@careerprepped.com';

  return(
    <>
      <div className="messageDetailContainer border-bottom-solid clearfix">
        <MessageDetailsHeader
          isMobile={isMobile}
          thread={unitThread.thread}
          isDeleting={isDeleting}
          currentProfile={currentProfile}
          isUserListTooltipOpen={isUserListTooltipOpen}
          toggleUserListTooltip={toggleUserListTooltip}
          toggleUserDetailsModal={toggleUserDetailsModal}
          onDeleteMessage={onDeleteMessage}/>

        <div
          ref={messageContentContainerRef}
          className="messageContentContainer"
          style={{maxHeight: 500, padding: '20px 0', overflowY: 'auto', overflowX: 'hidden'}}>

          {
            isRequesting &&
              <div className="loading-container">
                <Loading/>
              </div>
          }

          {replies && replies.slice().reverse().map((reply, index) =>
            (<MessageDetailsItem key={index} reply={reply} userId={userId}/>))}

        </div>
        {
          Object.keys(attachment).length > 0 &&
            <div
              style={{borderTop: '1px solid #D7D7D7'}}
              className="loaded-attachment-preview messageTextarea">
              <LoadedAttachment
                onDeleteLoadedAttachment={onRemoveAttachment}
                mimetype={attachment.type}
                imagePreviewUrl={attachment.preview}
                imagePreviewText={attachment.name}/>
            </div>
        }
      </div>
      {!isNoReply && (
        <div className="messageTextarea">
          <div className="input-group">
            <TextArea
              name="message"
              type="text"
              rows="3"
              value={messageText}
              onChange={onChangeMessage}
              className="form-control"
              disabled={unitThread?.thread?.privateMessagingDisabled}
              placeholder={unitThread?.thread?.privateMessagingDisabled ? 'The organizational admin has disabled private messaging to this user': 'Type your message here...'}/>
          </div>
          <div className="input-group">
            <button
              disabled={isReplying}
              onClick={onSubmit}
              className="btn btn-primary"
              style={{ marginRight: 16 }}
              type="button">
              {isReplying ? 'Replying...' : 'Reply'}
            </button>
            <Dropzone
              style={styles.dropzoneStyle}
              activeStyle={styles.activeStyle}
              multiple={false}
              accept={validMimeType}
              onDrop={onSelectFile}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <i className="fas fa-paperclip" />
                  </div>
                </section>
              )}
            </Dropzone>
          </div> 
        </div>
      )}      
    </>
  );
};

MessageDetails.propTypes = {
  query: PropTypes.string,
  messages: PropTypes.array,
  unitThread: PropTypes.object,
  onRemoveAttachment: PropTypes.func,
  messageText: PropTypes.string,
  onChangeMessage: PropTypes.func,
  onSubmit: PropTypes.func,
  isReplying: PropTypes.bool,
  onSelectFile: PropTypes.func,
  onDeleteMessage: PropTypes.func,
  isDeleting: PropTypes.object,
  attachment: PropTypes.object,
  messageContentContainerRef: PropTypes.func,
  isMobile: PropTypes.bool,
  isRequesting: PropTypes.bool,
  replies: PropTypes.array,
  userId: PropTypes.string,
  currentProfile: PropTypes.object,
  isUserListTooltipOpen: PropTypes.bool,
  toggleUserListTooltip: PropTypes.func,
  toggleUserDetailsModal: PropTypes.func
};

export default MessageDetails;
