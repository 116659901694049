import PropTypes from 'prop-types';
import React from 'react';
import UsernameSection from './UsernameSection';
import ResumeSummary from './ResumeSummary';
import PreviewResumeSectionCardContainer from './PreviewResumeSectionCardContainer';

const PreviewResumeSectionsContainer = ({ resumeOrder, resume }) => {
  return (
    <div className="resume-container">
      <UsernameSection preview resume={resume}/>
      <ResumeSummary summary={resume.summary}/>

      <div>
        {resumeOrder.map(item =>
          (<PreviewResumeSectionCardContainer
            key={item.id}
            section={item}
            resume={resume}/>))}
      </div>
    </div>
  );
};

PreviewResumeSectionsContainer.propTypes = {
  resume: PropTypes.object.isRequired,
  resumeOrder: PropTypes.array,
};

export default PreviewResumeSectionsContainer;
