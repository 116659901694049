import PropTypes from 'prop-types';
import React from 'react';
import TextInput from '../common/TextInput';
import Button from '../common/Button';

const OrganizationForm = ({
  editingId,
  errors,
  organization,
  onChangeOrganizationDetails,
  onCancel,
  onSaveOrganization,
  isSubmitting,
  isUpdating
}) => {
  const submitButtonText = editingId !== null ? 'Save' : 'Save & Send';

  return (
    <div className="invite-form">
      <TextInput
        name="name"
        label={'Organization Name'}
        placeholder="Organization Name"
        error={errors.name}
        value={organization.name}
        onChange={onChangeOrganizationDetails}/>
      <TextInput
        name="publicUrl"
        label={'Organization Url'}
        placeholder="Organization Url"
        error={errors.publicUrl}
        value={organization.publicUrl}
        onChange={onChangeOrganizationDetails}/>
      <TextInput
        name="adminName"
        label={'Organization Admin Name'}
        placeholder="Admin Name"
        error={errors.adminName}
        value={organization.adminName}
        onChange={onChangeOrganizationDetails}/>
      <TextInput
        name="email"
        label={'Organization Admin Email'}
        placeholder="Admin Email"
        error={errors.email}
        value={organization.email}
        onChange={onChangeOrganizationDetails}/>
      <div style={{marginBottom: 50}}>
        <Button
          onClick={onSaveOrganization}
          disabled={isSubmitting || isUpdating.status}
          type="button"
          buttonClass="btn-primary float-right"
          buttonText={isSubmitting || isUpdating.status ? 'Saving...': submitButtonText}/>

        <Button
          onClick={onCancel}
          type="button"
          disabled={isSubmitting || isUpdating.status}
          buttonClass="btn-secondary float-right"
          buttonText="Cancel"/>
      </div>
    </div>
  );
};

OrganizationForm.propTypes = {
  editingId: PropTypes.string,
  isUpdating: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  onChangeOrganizationDetails: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSaveOrganization: PropTypes.func.isRequired,
};

export default OrganizationForm;
