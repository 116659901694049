import PropTypes from 'prop-types';
import React from 'react';
import UnitBadgeTile from '../../containers/skill-badges/UnitBadgeTile';
import Loading from '../common/Loading';
import { Link } from 'react-router-dom';

const styles = {
  header: {
    color: 'rgba(0,0,0,0.5)',
    textAlign: 'center',
    marginTop: 55
  },

  wrapper : { padding: 20 }
};

const SkillBadgesTiles = ({
  isOwner,
  modal,
  badges,
  badgeAction,
  callingAPI,
  selectedBadges,
  isRequestingSort,
  isRequestingSearch,
  onSelectBadgeChange,
  hasNoClaimedBadge,
  emptySearchResult,
  layout,
  profile
}) => {
  const ownerName = isOwner ? 'You': profile.name;
  if (isRequestingSort || isRequestingSearch) {
    return(
      <div style={{ padding: 20 }}>
        <Loading />
      </div>
    );
  }

  if (emptySearchResult) {
    return (
      <div>
        <h4 style={styles.header}>
          Sorry, we couldn't find anything!
        </h4>
      </div>
    );
  }

  return (
    <div>
      {
        hasNoClaimedBadge &&
          <div className="row search-hint no-badges-claimed-hint mt-0">
            <div className="col-sm-12">
              <span className="fa fa-info-circle" aria-hidden="true"/>
              <p className="mt-0 mb-1">{ownerName} {isOwner ? 'haven\'t': 'hasn\'t'} claimed any Skill Badges on this page yet. {isOwner && <span>Review <Link to="/skill-builders">Skill Builders</Link> to start claiming Skill Badges. If you want to know more about badges, check out the articles, <a target="_blank" rel="noopener noreferrer" href="https://help.careerprepped.com/help/what-are-skill-badges">what are Skill Badges</a> and <a target="_blank" rel="noopener noreferrer" href="https://help.careerprepped.com/help/how-do-i-get-a-skill-badge">how do I get Skill Badges?</a></span>}</p>
            </div>
          </div>
      }
      <div className="row" id="list-grid-view">
        {badges.map((badge, index) =>
          (<UnitBadgeTile
            key={index}
            isOwner={isOwner}
            modal={modal}
            badge={badge}
            layout={layout}
            selectedBadges={selectedBadges}
            onSelectBadgeChange={onSelectBadgeChange}
            badgeAction={badgeAction}/>))}

        <div style={styles.loaderWrapper}>
          {callingAPI && <Loading />}
        </div>
      </div>
    </div>
  );
};

SkillBadgesTiles.propTypes = {
  modal: PropTypes.string,
  isOwner: PropTypes.bool,
  hasNoClaimedBadge: PropTypes.bool,
  badges: PropTypes.array.isRequired,
  callingAPI: PropTypes.bool.isRequired,
  badgeAction: PropTypes.func.isRequired,
  selectedBadges: PropTypes.array.isRequired,
  isRequestingSort: PropTypes.bool.isRequired,
  isRequestingSearch: PropTypes.bool.isRequired,
  onSelectBadgeChange: PropTypes.func.isRequired,
  layout: PropTypes.string,
  emptySearchResult: PropTypes.bool.isRequired,
  profile: PropTypes.object
};

export default SkillBadgesTiles;
