import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'lodash';
import { Breadcrumb, BreadcrumbItem, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import { withHooks } from '../../utils/withHooks';

import {
  getfeedbackQuestions,
  getFeedbackData,
  postFeedbackData,
  editFeedbackData,
  resetGiveFeedback,
  getFeedbackStatusByRequestId,
} from '../../redux/actions/givefeedback';
import {getResourceData} from '../../redux/actions/badges';
import * as orgInvitationActions from '../../redux/actions/organization-invitations';
import {unitSkillRequest} from '../../redux/actions/skills';
import {requestBadgeAssertion} from '../../redux/actions/badge-assertion';

import QuestionResponses from '../../components/give-feedback/QuestionResponses';
import PortfolioEvidences from '../../components/give-feedback/PortfolioEvidences';
import EndorsementFeedback from '../../components/give-feedback/EndorsementFeedback';
import Message from '../../components/give-feedback/Message';
import ConfirmSubmit from '../../components/give-feedback/confirmsubmit';
import ConfirmModal from '../../components/portfolio/ConfirmModal';
import Relationship from '../../components/give-feedback/Relationship';

import CreatePasswordForPeer from './CreatePasswordForPeer';
import Loading from '../../components/common/Loading';

class GiveFeedbackWrapperAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step0: true,
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      relation: this.props.relation,
      questionResponseRating: {},
      portfolioEvidenceRating: {},
      endorsement: {},
      dataSubmit: false,
      confirmSubmit: false,
      showConfirmationModal: false,
      prompt : true,
      resourceFlag : false,
      showCompleteAlert: false,
      nextPropsFeedbackData: {},
      showLoader: true,
    };

    this.toggleConfirmationModal = this.toggleConfirmationModal.bind(this);
    this.proceed = this.proceed.bind(this);
    this.setFeedbackData = this.setFeedbackData.bind(this);
  }

  componentDidMount() {
    const {isAuthenticated, actions} = this.props;
    if(isAuthenticated){
      this.setFeedbackData();
    }else{
      actions.getFeedbackStatusByRequestId('admin');
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ relation: nextProps.relation });

    if (
      Object.prototype.hasOwnProperty.call(
        this.state.nextPropsFeedbackData,
        'feedbackRequestId'
      ) &&
      nextProps.feedbackQuestions.likert.length > 0
    ) {
      if(isEmpty(nextProps.location.search)
        && this.state.nextPropsFeedbackData.feedbackstatus === 'completed'
       || this.state.nextPropsFeedbackData.feedbackstatus === 'canceled'){
        this.setState({showCompleteAlert: true});
      }
      this.setData(
        this.state.nextPropsFeedbackData,
        'questionResponseRating',
        nextProps.feedbackQuestions
      );
    }
    if (
      Object.prototype.hasOwnProperty.call(
        this.state.nextPropsFeedbackData,
        'feedbackRequestId'
      ) &&
      nextProps?.portfolioQuestions?.likert.length > 0
    ) {
      this.setData(
        this.state.nextPropsFeedbackData,
        'portfolioEvidenceRating',
        nextProps.portfolioQuestions
      );
    }

    if (nextProps.isSubmitting === 'submitted' && this.state.dataSubmit) {
      this.setState({
        confirmSubmit: !this.state.confirmSubmit,
        dataSubmit: false,
      });
      this.proceed('step4');
    }

    if(this.props.params.type === 'skill' && !this.state.resourceFlag){
      this.props.actions.unitSkillRequest(this.props.isAuthenticated, this.props.params.rquestId);
      this.setState({resourceFlag: true});
    }
    if(this.props.params.type === 'badge' && !this.state.resourceFlag){
      this.props.actions.getResourceData(this.props.params.rquestId);
      this.props.actions.requestBadgeAssertion(this.props.params.rquestId, this.props.isAuthenticated);
      this.setState({resourceFlag: true});
    }

    const nextPropsFeedbackData = {
      'feedbackResponseDate': '',
      'feedbackstatus': 'pending',
      'otherUserFullName': nextProps?.resourceData?.user?.name,
      'requesterAvatarURl': nextProps?.resourceData?.user?.avatar,
      'requesterId': nextProps?.resourceData?.user?.id,
      'requesterName': nextProps?.resourceData?.user?.name,
      'requesterVanity': nextProps?.resourceData?.user?.vanity,


      'resourceId' : (this.props.params.type === 'skill') ? nextProps?.resourceData?.id : nextProps?.resourceData?.issued?.id,
      'resourceType': this.props.params.type,
      'resourcename': (this.props.params.type === 'skill') ? nextProps?.resourceData?.name : nextProps?.resourceData?.badge?.name,
      'resourceUrl' : (this.props.params.type === 'skill') ? nextProps?.resourceData?.url : nextProps?.resourceData?.badge?.image,
    };
    this.setState({
      nextPropsFeedbackData: {...nextPropsFeedbackData}
    });
  }

  componentWillUnmount() {
    this.props.actions.resetGiveFeedback();
  }

  setFeedbackData(){
    const { params, isAuthenticated, actions } = this.props;

    if(isAuthenticated){
      actions.getFeedbackData('admin').then(() => {
        setTimeout(() => {
          this.setState({showLoader: false});
        }, 2000);
      });

      actions.getfeedbackQuestions('admin', params);
    }
  }

  setData = (feedbackData, param1, questions) => {
    const jsonData = {};
    jsonData['like'] = questions.likert?.find(
      todo => todo.answerText === feedbackData[param1]?.overall
    )?.answerId;
    const multiChoiceArr = feedbackData[param1]?.multichoiceComments?.replace('key,','key')?.split(
      ','
    );
    jsonData['multiOption'] = questions.multichoice
      ?.filter(item => multiChoiceArr?.includes(item.answerText?.replace('key,','key')))
      .map(data => data.answerId);
    jsonData['additionalComment'] = feedbackData[param1]?.additionalComment;

    this.setState({ [param1]: jsonData });
  };

  toggleConfirmationModal() {
    this.setState((state) => {
      return {
        confirmSubmit: !state.confirmSubmit,
        prompt: false
      };
    });
  }

  proceed(step) {
    let arr = ['step0', 'step1', 'step2', 'step3', 'step4'];

    this.setState((state) => {
      const d = {};
      arr.forEach((a) => {
        if (a === step) {
          d[a] = !state[step];
        } else {
          d[a] = false;
        }
      });
      return d;
    });
  }

  stepProgressBar(feedbackData, portfolioQuestions) {
    let step0 = '';
    let step1 = '';
    let step2 = '';
    let step0Text = '';
    let step1Text = '';
    let step2Text = '';
    let step3Text = '';

    const steps = feedbackData?.resourceType === 'skill' && !this.state.step3 && !this.state.step0 ? 'step2' : 'step1';

    if (this.state.step0) {
      step0 = 'completed';
      step0Text = 'activeText';
    }

    if (this.state.step1 && feedbackData?.resourceType === 'badge') {
      step0 = this.state.relation.length > 0 ? 'completed' : '';
      step1 = 'completed';
      step0Text = 'activeText';
      step1Text = 'activeText';
    }
    if ((portfolioQuestions?.likert.length > 0 && !this.state.step1 && !this.state.step0) || steps === 'step2') {
      step0 = this.state.relation.length > 0 ? 'completed' : '';
      step1 = feedbackData?.resourceType === 'badge' ? 'completed' : '';
      step2 = 'completed';
      step0Text = 'activeText';
      step1Text = 'activeText';
      step2Text = feedbackData?.resourceType === 'badge' ? 'activeText' : '';
    }
    if (this.state.step3) {
      step0 = this.state.relation.length > 0 ? 'completed' : '';
      step1 = feedbackData?.resourceType === 'badge' ? 'completed' : '';
      step2 = portfolioQuestions.likert.length > 0 ? 'completed' : '';
      step0Text = 'activeText';
      step1Text = feedbackData?.resourceType === 'badge' ? 'activeText' : '';
      step2Text = portfolioQuestions.likert.length > 0 ? 'activeText' : '';
      step3Text = 'activeText';
    }
    return (
      <div id="stepProgressBar" className="stepper-wrapper">
        {this.props.profile.role !== 'industry-representative' &&
          <div className={`stepper-item ${step0}`}>
            <div className="step-counter"/>
            <p className={`step-name ${step0Text}`}>Relationship</p>
          </div>}

        {feedbackData?.resourceType === 'badge' && (
          <div className={`stepper-item ${step1}`}>
            <div className="step-counter"/>
            <p className={`step-name ${step1Text}`}>Question Responses</p>
          </div>
        )}

        {portfolioQuestions?.likert?.length > 0 && (
          <div className={`stepper-item ${step2}`}>
            <div className="step-counter"/>
            <p className={`step-name ${step2Text}`}>Portfolio Evidence</p>
          </div>
        )}

        {feedbackData?.feedbackstatus === 'pending' && (
          <div className="stepper-item">
            <div className="step-counter"/>
            <p className={`step-name ${step3Text}`}>Endorsements</p>
          </div>
        )}
      </div>
    );
  }

  saveFeedBack = (state, feedbackData) => {
    this.setState({ [state]: feedbackData });
  };

  postFeedback = () => {
    const body = {};
    const { feedbackQuestions, portfolioQuestions } = this.props;
    const { relation, questionResponseRating, portfolioEvidenceRating } = this.state;
    if (this.state.questionResponseRating && questionResponseRating.multiOption?.length > 0 && questionResponseRating.like) {
      body[feedbackQuestions.commentQuestionId] =
        questionResponseRating.additionalComment;
      body[feedbackQuestions.likeQuestionId] = questionResponseRating.like;
      body[feedbackQuestions.multiChoiceQuestionId] =
        questionResponseRating.multiOption;
    }
    if (this.state.portfolioEvidenceRating) {
      body[portfolioQuestions.commentQuestionId] =
        portfolioEvidenceRating?.additionalComment;
      body[portfolioQuestions.likeQuestionId] = portfolioEvidenceRating?.like;
      body[portfolioQuestions.multiChoiceQuestionId] =
        portfolioEvidenceRating?.multiOption;
    }

    let data = {};
    if ( this.state.nextPropsFeedbackData.resourceType === 'skill' ) {
      data = { skill_id: this.state.nextPropsFeedbackData.resourceId };
    }

    if ( this.state.nextPropsFeedbackData.resourceType == 'badge' ) {
      data = { badge_issued_id: this.state.nextPropsFeedbackData.resourceId };
    }

    data = {
      relationId: relation,
      ...data,
      request_for_user_id: this.state.nextPropsFeedbackData.requesterId,
      responseDetails: { ...body },
      ...this.state.endorsement,
      endorsement: this.state.endorsement.endorsement ? 1 : 0
    };

    this.state.nextPropsFeedbackData?.feedbackstatus === 'pending'
      ? this.props.actions.postFeedbackData(data)
      : this.props.actions.editFeedbackData(data);

    this.setState({ dataSubmit: true });
  };

  onDismiss = () => {
    this.setState({showCompleteAlert: false});
    window.location.href = '/myfeedback';
  }

  getAlertText = () => this.state.nextPropsFeedbackData.feedbackstatus === 'completed' ?
    <p>You have already given the feedback. If you’d like to revise it, you can see a history of your given feedback and choose to revise any feedback already given in the <a href="/myfeedback">“Given”</a> section of your “My Feedback” page</p>
    : <p>Thank you for your willingness to provide feedback, but the requester has cancelled this request.</p>

  getAlertTitle = () => this.state.nextPropsFeedbackData.feedbackstatus === 'completed' ? 'Completed feedback' : 'Feedback Request Canceled';

  render() {
    const { portfolioQuestions, isSubmitting, resourceData, isAuthenticated, feedbackUserDetail } = this.props;
    const feedbackData = this.state.nextPropsFeedbackData;
    const name = feedbackData.otherUserFullName;
    const steps = feedbackData?.resourceType === 'skill' && !this.state.step3 && !this.state.step0 ? 'step2' : 'step1';

    const imageLink = feedbackData?.resourceType === 'skill' ? resourceData.url : resourceData?.badge?.image ;
    const defaultImageLink = feedbackData?.resourceType === 'skill' ? '../../assets/images/temp/skill.png' : '../../assets/images/temp/badge.png';
    if(isEmpty(feedbackData) || this.state.showLoader){
      return (<div className="container giveFeedbackContainerStyle pt-5">
        <Loading />
      </div>);
    }

    return (
      <div className="container giveFeedbackContainerStyle py-1">
        {!isAuthenticated &&
          !this.state.relationModal && feedbackUserDetail?.giverExists=== '0' &&
          <CreatePasswordForPeer
            feedbackUserDetail={feedbackUserDetail}
            setFeedbackData={this.setFeedbackData}/>}
        {isAuthenticated && <>
          <ConfirmModal
            isOpen={this.state.showCompleteAlert}
            confirminginProgressText={'OK'}
            modalHeaderText={this.getAlertTitle()}
            modalMessage={this.getAlertText()}
            modalFooterButtonText={'Close'}
            onCancel={this.onDismiss}
            onConfirm={this.onDismiss}
            onlyConfirmButton/>
          <div className="mt-1">
            <Prompt
              when={this.state.prompt}
              message="Are you sure you want to leave this page before you finish? Any progress you’ve made will be lost."/>
            <Breadcrumb>
              <BreadcrumbItem active>
                {feedbackData.resourcename}
              </BreadcrumbItem>
            </Breadcrumb>
          </div>
          <div className="container pb-4 container-border">
            <Row className="bluebox">
              <Col md="4" className="darkblue py-2">
                {feedbackData.resourceType === 'badge' &&(
                  <div className="darkBlueImage py-3">
                    <img width="80" src={imageLink ? imageLink : defaultImageLink} />
                  </div>
                )}
                <div className="darkBlueText">
                  <strong>{feedbackData.resourcename}</strong>
                </div>
              </Col>
              <Col md="8" className="lightblue py-3">
                <p>
                  <strong>Give your feedback to help {name}</strong>
                </p>
                <span className="font14">
                  {name} claims to have the skill {feedbackData.resourcename}.
                  Please review the evidence below. Your feedback will help{' '}
                  {name} improve how well this claimed skill is being proven.
                </span>
              </Col>
            </Row>
            {(this.state.step0 ||
              this.state.step1 ||
              this.state.step2 ||
              this.state.step3 ||
              steps === 'step2') &&
              !this.state.step4 &&
              this.stepProgressBar(feedbackData, portfolioQuestions, resourceData)}

            {this.state.step0 &&
              <Relationship
                proceed={this.proceed}
                feedbackResponse={this.saveFeedBack}
                feedbackData={this.state.relation}/>}

            {this.state.step1 &&
              feedbackData?.resourceType === 'badge' && resourceData?.badge?.id &&
              ((feedbackData?.feedbackstatus === 'completed' && this.state.questionResponseRating?.like)
                || (feedbackData?.feedbackstatus === 'pending' && this.state.questionResponseRating)
                || feedbackData?.feedbackstatus === 'canceled'
              )
              && (
                <QuestionResponses
                  feedbackData={this.state.questionResponseRating}
                  resourceData={resourceData}
                  name={name}
                  feedbackResponse={this.saveFeedBack}
                  status={feedbackData?.feedbackstatus}
                  questions={this.props.feedbackQuestions}
                  toggleConfirmationModal={this.toggleConfirmationModal}
                  step2={portfolioQuestions?.likert.length > 0}
                  proceed={this.proceed} />
              )}
            {(this.state.step2 || steps === 'step2') &&
              isSubmitting !== 'submitted' &&
              feedbackData &&
              ((feedbackData?.feedbackstatus === 'completed' && this.state.portfolioEvidenceRating?.like)
              || (feedbackData?.feedbackstatus === 'pending' && this.state.portfolioEvidenceRating)
              || feedbackData?.feedbackstatus === 'canceled'
              )
              &&
              ((feedbackData?.resourceType === 'badge' && resourceData.files?.length > 0) || feedbackData?.resourceType === 'skill')
              && portfolioQuestions?.likert?.length > 0 && (
              <PortfolioEvidences
                portfolioEvidenceRating={this.state.portfolioEvidenceRating}
                resourceData={resourceData}
                name={name}
                feedbackData={feedbackData}
                feedbackResponse={this.saveFeedBack}
                questions={portfolioQuestions}
                proceed={this.proceed}
                toggleConfirmationModal={this.toggleConfirmationModal} />
            )}
            {this.state.step3 && (
              <EndorsementFeedback
                feedbackData={feedbackData}
                resourceData={resourceData}
                name={name}
                profile={this.props.profile}
                feedbackResponse={this.saveFeedBack}
                proceed={this.proceed}
                step={portfolioQuestions?.likert.length > 0}
                toggleConfirmationModal={this.toggleConfirmationModal} />
            )}
            {this.state.confirmSubmit && (
              <ConfirmSubmit
                isOpen={this.state.confirmSubmit}
                toggleModal={this.toggleConfirmationModal}
                saveFeedback={this.postFeedback}
                submitRequest={this.state.dataSubmit}
                status={feedbackData?.feedbackstatus}
                proceed={this.proceed}
                name={name} />
            )}
            {this.state.step4 && (
              <Message
                status={feedbackData?.feedbackstatus}
                name={name} />
            )}
          </div>
        </>}
      </div>
    );
  }
}

GiveFeedbackWrapperAdmin.propTypes = {
  feedback: PropTypes.object,
  actions: PropTypes.object.isRequired,
  isSubmitting: PropTypes.string.isRequired,
  feedbackData: PropTypes.object.isRequired,
  feedbackQuestions: PropTypes.object.isRequired,
  portfolioQuestions: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  resourceData: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object,
  navigate: PropTypes.func,
  router: PropTypes.object,
  relation: PropTypes.string,
  feedbackUserDetail: PropTypes.object,
  setFeedbackData: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getfeedbackQuestions,
      getFeedbackData,
      postFeedbackData,
      editFeedbackData,
      getResourceData,
      unitSkillRequest,
      resetGiveFeedback,
      getFeedbackStatusByRequestId,
      requestBadgeAssertion,
      orgInvitationActions,
    },
    dispatch
  ),
});

const mapStateToProps = (state, ownProps) => {
  const resources = ownProps.params.type === 'skill' ?
    state.skills.unitSkill.data : state.badgeAssertion?.data;

  const relation = state.givefeedback.getFeedbackUserDetail.relation ?
    state.givefeedback.getFeedbackUserDetail.relation : '';

  return {
    relation,
    name: state.profile.data?.name,
    profile: state.profile.data,
    feedbackQuestions: state.givefeedback.data.feedbackQuestions,
    portfolioQuestions: state.givefeedback.data.portfolioQuestions,
    feedbackData: state.givefeedback.data.feedbackData,
    isSubmitting: state.givefeedback.isSubmitting,
    isAuthenticated: state.auth.isAuthenticated,
    feedbackUserDetail: state.givefeedback.getFeedbackUserDetail,
    resourceData: resources,
  };
};

export default withHooks(
  connect(mapStateToProps, mapDispatchToProps)(GiveFeedbackWrapperAdmin)
);
