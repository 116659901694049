import React from 'react';

import PropTypes from 'prop-types';

import moment from 'moment';
import classNames from 'classnames';

import { checkUrl } from '../../../utils';

import Loading from '../../common/Loading';
import PaginationHandler from '../../common/PaginationHandler';

import IconBookmark from '../../../assets/images/icon-bookmark.svg';
import IconVideo from '../../../assets/images/icon-video.svg';

const SelectPortfolioFilesTabContent = ({
  files,
  isRequestingFiles,
  selectedFiles,
  linkedFiledIds,
  onNext,
  onPrev,
  onSelectPage,
  fileData,
  onSelectFile
}) => {
  const { page, page_count } = fileData;

  if(isRequestingFiles){
    return(
      <div className="loading-container">
        <Loading/>
      </div>
    );
  }

  return (
    <div>
      <div id="portfolio" className="mt-1">
        <div className="portfolio-container">
          {
            files.map((file, index) => {
              const { modified, title, url, mimetype, id } = file;
              const fileAlreadyLinked = linkedFiledIds.includes(id);

              const modifiedDate = modified && moment(modified.date).format('MM/DD/YYYY');

              const docFile = mimetype.includes('application') || mimetype.includes('text');
              const videoFile = checkUrl(url, 'youtube') || checkUrl(url, 'vimeo');
              const webLink = (!videoFile && !docFile && !mimetype.includes('image'));

              const fileCardClassNames = classNames('port-file', {
                'portfolio-img': mimetype.includes('image'),
                'portfolio-doc': docFile,
                'portfolio-video': videoFile,
                'portfolio-weblink': webLink,
                'linked': fileAlreadyLinked,
                'clickable': !fileAlreadyLinked,
                'selected': selectedFiles.includes(id)
              });

              return(
                <div key={index}>
                  <div
                    className={fileCardClassNames}
                    onClick={fileAlreadyLinked ? null : onSelectFile(id)}>
                    <div className="portfolio-file-image">
                      {docFile &&
                        <div className="thumb file-icon-container">
                          <i className="fa fa-file-text-o"/>
                        </div>}

                      {videoFile &&
                        <div className="thumb file-icon-container">
                          <i>
                            <img src={IconVideo} />
                          </i>
                        </div>}

                      {webLink &&
                        <div className="thumb file-icon-container">
                          <i>
                            <img src={IconBookmark} />
                          </i>
                        </div>}

                      {!docFile && mimetype !== '' &&
                        <div className="portfolio-file-image-container">
                          <span>
                            <img src={url} className="thumb-image"/>
                          </span>
                        </div>}
                    </div>
                    <div className="file-details">
                      <h3 className="portfolio-title">{title}</h3>
                      <div className="portfolio-description">{modifiedDate}</div>
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>
        {
          (!isRequestingFiles && files.length > 0) &&
            <PaginationHandler
              onNext={onNext}
              onPrev={onPrev}
              onSelectPage={onSelectPage}
              page={page}
              page_count={page_count}/>
        }
      </div>
    </div>
  );
};

SelectPortfolioFilesTabContent.propTypes = {
  files: PropTypes.array.isRequired,
  isRequestingFiles: PropTypes.bool.isRequired,
  selectedFiles: PropTypes.array.isRequired,
  onSelectFile: PropTypes.func.isRequired,
  linkedFiledIds: PropTypes.array.isRequired,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  onSelectPage: PropTypes.func,
  fileData: PropTypes.object
};

export default SelectPortfolioFilesTabContent;
