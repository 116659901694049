import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import classNames from 'classnames';
import { toastr } from 'react-redux-toastr';

import * as searchActions from '../../redux/actions/search';
import * as routerActions from '../../redux/actions/router';
import * as messagesActions from '../../redux/actions/messages';

import MessageModal from '../../components/profile/connects/MessageModal';

class PeopleSearchItemHandler extends Component {
  static propTypes = {
    search: PropTypes.object.isRequired,
    people: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    onFollow: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,
    message: PropTypes.object,
    currentUser: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      error: {},
      showMessageModal: false,
      message: Object.assign({}, props.message),
    };

    this.onToggleMessagingModal = this.onToggleMessagingModal.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.isValid = this.isValid.bind(this);
  }

  onToggleMessagingModal() {
    const {people} = this.props;

    if(people.privateMessagingDisabled){
      toastr.info('', 'The organizational admin has disabled private messaging to this user');
      return;
    }

    this.setState({
      showMessageModal: !this.state.showMessageModal
    });
  }

  onMessageChange(event) {
    const field = event.target.name;
    const { message } = this.state;

    message[field] = event.target.value;

    this.setState({ message }, () => this.isValid(message));
  }

  isValid(value) {
    const { error } = this.state;

    this.setState({ error: {} });

    if (!value.body.length > 0) {
      error['body'] = 'Message cannot be empty';
      this.setState({ error });
    }
    if (!value.subject.length > 0) {
      error['subject'] = 'Subject cannot be empty';
      this.setState({ error });
    }
    return (value.subject.length > 0) && (value.body.length > 0);
  }

  onMessageSubmit() {
    const { message } = this.state;
    const { people, actions } = this.props;

    if (!this.isValid(message)) return;

    message.recipients = [people.id];
    actions.sendMessage(message).
      then(() => this.handleResponse());
  }

  handleResponse() {
    const message = { recipients: [], subject: '', body: '' };

    this.setState({
      message,
    }, () => this.onToggleMessagingModal());
  }

  render() {
    const { showMessageModal, message, error } = this.state;
    const { onFollow, people, isSubmitting, search, currentUser } = this.props;
    const { isFollowing } = search;
    const {
      isUserFollower,
      isUserFollowing,
      headline,
      vanity,
      name,
      followersCount,
      filesCount,
      badgesCount,
      id,
      avatar,
      role,
    } =people;
    const isCurrentUser = currentUser.id === id;
    const isFollowingUser = isFollowing.status && isFollowing.userId === id;
    let followBtnText = isUserFollower.status ? 'Unfollow': 'Follow';
    if (isFollowingUser) {
      followBtnText = `${followBtnText}ing...`;
    }

    return (
      <div className="searchResultsCard">
        <div className="search-item--people">
          <div className="search-info-container">
            <div className="search-content">
              <div className="search-avatar">
                <Link to={`/cp/${vanity}`}>
                  {
                    avatar && avatar.length !== 0 ?
                      <img
                        className="profile-avatar"
                        src={avatar}
                        alt={`${name}'s Avatar`}/>:
                      <span
                        style={{lineHeight: '64px', fontSize: 32}}
                        className="profile-avatar initial-avatar">
                        {name.slice(0,1)}
                      </span>
                  }
                </Link>
              </div>
              <div>
                <div className="search-info">
                  <div className="search-name">
                    <Link to={`/cp/${vanity}`}>{name}</Link>
                    {isUserFollower.status &&
                      <span className="tag tag-pill tag-default">
                        Following
                      </span>
                    }
                  </div>
                  <div className="searchResultsCardTitle">{headline}</div>
                  <ul className="searchResultsCardMeta list-inline">
                    <li className="list-inline-item">
                      {
                        filesCount === 0 ?
                          filesCount === 1 ? `${filesCount} Portfolio File` : `${filesCount} Portfolio Files` :
                          <Link to={`/portfolio/${id}`}>
                            {filesCount === 1 ? `${filesCount} Portfolio File` : `${filesCount} Portfolio Files`}
                          </Link>
                      }
                    </li>
                    <li className="list-inline-item">
                      {badgesCount === 1 ? `${badgesCount} Badge` : `${badgesCount} Badges`}
                      {/*
                        badgesCount === 0 ?
                          badgesCount === 1 ? `${badgesCount} Badge` : `${badgesCount} Badges`:
                          <Link to={`/skill-badges/${id}`}>
                            {badgesCount === 1 ? `${badgesCount} Badge` : `${badgesCount} Badges`}
                          </Link>
                      */}
                    </li>
                    <li className="list-inline-item">
                      {followersCount === 1 ? `${followersCount} Follower` : `${followersCount} Followers`}
                    </li>
                    {isUserFollowing.status &&
                      <li className="list-inline-item">
                        <span className="text-muted">Follows you</span>
                      </li>
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {
            !isCurrentUser && role !== 'bot' && role !== 'super-admin' &&
              <div className="searchResultsCardActions">
                <button
                  className={
                    classNames(
                      'btn',
                      {
                        'btn-danger': isUserFollower.status,
                        'btn-follow': !isUserFollower.status
                      }
                    )}
                  onClick={onFollow(people)}>
                  {
                    isUserFollower.status ?
                      <i
                        style={{fontSize: 12}}
                        className="d-lg-none fas fa-user-minus" />
                      :
                      <i
                        style={{fontSize: 12}}
                        className="d-lg-none fas fa-user-plus" />
                  }
                  <span className="d-none d-lg-block">
                    {followBtnText}
                  </span>
                </button>
                <button
                  onClick={this.onToggleMessagingModal}
                  className="btn btn-primary">
                  <i className="fab fa-telegram-plane d-lg-none"/>
                  <span className="d-none d-lg-block">Message</span>
                </button>
              </div>
          }
        </div>
        {
          showMessageModal &&
            <MessageModal
              toggleModal={this.onToggleMessagingModal}
              modalOpen={showMessageModal}
              error={error}
              isSubmitting={isSubmitting}
              message={message}
              onMessageChange={this.onMessageChange}
              onMessageSubmit={this.onMessageSubmit} />
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const message = { recipients: [], subject: '', body: '' };

  return {
    message,
    isSubmitting: state.messages.isSubmitting,
    search: state.search,
    currentUser: state.auth.data.currentUser
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, searchActions, routerActions, messagesActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(PeopleSearchItemHandler);
