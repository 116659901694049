import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import _isEmpty from 'lodash/isEmpty';

import { email } from '../../validator/rules';
import { uniqueId } from '../../utils';
import { withHooks } from '../../utils/withHooks';

import { createGroups } from '../../redux/actions/groups';
import { createEducatorGroups } from '../../redux/actions/educators';
import { updateInstitutionGroupsCount } from '../../redux/actions/institutions';

import * as profileActions from '../../redux/actions/profile';
import * as componentActions from '../../redux/actions/components';
import * as adminGroupActions from '../../redux/actions/admin-group';
import * as routerActions from '../../redux/actions/router';
import * as organizationStatsActions from '../../redux/actions/organization-stats';

import { selectIsRecentEducatorAccount } from '../../redux/selectors/profile';

import { Container, Row, Col } from 'reactstrap';

import Button from '../../components/common/Button';

import { toastr } from 'react-redux-toastr';

import ConfirmModal from '../../components/portfolio/ConfirmModal';
import GroupCardsList from '../../components/admin/groups-components/GroupCardsList';
import EducatorGroupCardsList from '../../components/admin/educators/EducatorGroupCardsList';

class CreateGroups extends Component {
  static propTypes = {
    id: PropTypes.string,
    organization: PropTypes.string,
    role: PropTypes.string.isRequired,
    groundLocations: PropTypes.number,
    onlinePrograms: PropTypes.number,
    group: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    parentGroupId: PropTypes.string,
    isInstitutionAdmin: PropTypes.bool,
    organizationStats: PropTypes.object.isRequired,
    unsavedChildGroupsData: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool,
    groupsData: PropTypes.array.isRequired,
    educatorGroups: PropTypes.array.isRequired,
    isRecentEducatorAccount: PropTypes.bool.isRequired,
    isSubmittingEducatorGroups: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      isConfirmModalOpen: false,
      groupsState: this.getInitialGroupsToCreate(props.organizationStats.numberOfGroupsToCreate)
    };
  }

  componentWillMount() {
    const { actions, isRecentEducatorAccount } = this.props;

    if (isRecentEducatorAccount) {
      actions.setNumberOfGroupsToCreate(1);
      this.setState({
        groupsState: this.getInitialGroupsToCreate(1)
      });
    }
  }

  componentWillReceiveProps(nextProps){
    const {
      role,
      groupsData,
      isSubmitting,
      educatorGroups,
      isSubmittingEducatorGroups
    } = nextProps;

    const educatorGroupsComplete = (
      (! isSubmittingEducatorGroups) &&
        (isSubmittingEducatorGroups !== this.props.isSubmittingEducatorGroups) &&
        (! _isEmpty(educatorGroups))
    );

    const isEducator = (role === 'educator');

    if (isEducator && educatorGroupsComplete) {
      this.setState({ isConfirmModalOpen: false });
      return;
    }

    const groupsSubmittingComplete = (
      (! isSubmitting) &&
        (isSubmitting !== this.props.isSubmitting) &&
        (! _isEmpty(groupsData))
    );

    if (groupsSubmittingComplete) {
      this.setState({ isConfirmModalOpen: false });
    }
  }

  componentWillUnmount() {
    this.props.actions.closeModal();
  }

  getInitialGroupsToCreate(number) {
    return Array(parseInt(number))
      .fill()
      .map(() => ({
        id: uniqueId(),
        name: '',
        emails: '',
        nameErrors: false,
        errors: false
      }));
  }

  onChangeAdminEmails = (e) => {
    const value = e.currentTarget.value;
    const inputName = e.target.name;
    const { groupsState } = this.state;
    const id = inputName.split('email-')[1];
    const index = groupsState.findIndex(group => group.id === id);

    const nextGroupsState = [
      ...groupsState.slice(0, index),
      Object.assign({},{
        ...groupsState[index],
        emails: value,
        errors: this.checkValidEmails(value)
      }),
      ...groupsState.slice(index + 1)
    ];

    this.setState({ groupsState: nextGroupsState });
  }

  checkValidEmails = (input) => {
    const emailArray = input.split(',');

    if (emailArray.length > 0) {
      const invalidEmailExist = emailArray.reduce((acc, next) => {
        const isInvalid = email(next.trim()) && email(next.trim()).length > 0;

        return isInvalid || acc;
      }, false);

      return invalidEmailExist;
    }
  }

  onChangeGroupName = (e) => {
    const value = e.currentTarget.value;
    const inputName = e.target.name;

    const { groupsState } = this.state;
    const index = groupsState.findIndex(data => data.id === inputName);

    return this.setState({
      groupsState: [
        ...groupsState.slice(0, index),
        Object.assign({},{
          ...groupsState[index],
          name: value,
          nameErrors: value === ''
        }),
        ...groupsState.slice(index+1)
      ]
    }, () => this.checkValidEmails(value, inputName));
  }

  onFinish = () => {
    if (this.state.groupsState.length === 0) {
      return toastr.error('Create at least one group to finish.');
    }

    this.setState({
      isConfirmModalOpen: true,
    });
  }

  onConfirmFinishSetup = () => {
    const { groupsState } = this.state;
    const {
      params,
      actions,
      navigate,
      parentGroupId,
      isInstitutionAdmin
    } = this.props;

    const groupData = {
      groups: groupsState.
        map(item => Object.assign({}, {
          name: item.name,
          description: 'Description of Group',
          emails: item.emails.split(',').map(email => email.trim()).filter(email => email !== '')
        }))
    };

    if (parentGroupId && (parentGroupId !== 'all-groups') && (! isInstitutionAdmin)) {
      groupData.parentGroupId = parentGroupId;
    }

    if (! params.organizationId) {
      actions.createEducatorGroups(groupData)
        .then(() => {
          actions.redirect('/educator/groups/manage');
          navigate('/educator/groups/manage');
        });

      return;
    }

    actions.createGroups(params.organizationId, groupData)
      .then(() => {
        actions.updateInstitutionGroupsCount(groupsState.length);
        actions.redirect('/organization/admin/manage-groups');
        navigate('/organization/admin/manage-groups');
      });
  }

  disableFinishButton = () => {
    const { groupsState } = this.state;
    let disableCondition = false;

    disableCondition = [...groupsState].some((next) => {
      const emailsHasError = next.errors;
      const nameHasError = next.name === '' || next.nameErrors;
      return emailsHasError || nameHasError;
    });

    return disableCondition;
  }

  onCancelConfirmModal = () => {
    this.setState({
      isConfirmModalOpen: false,
    });
  }

  onCardRemove = (event) => {
    event.preventDefault();

    const groupCardId = event.target.id;
    const groupsState = this.state.groupsState.filter(group => group.id !== groupCardId);

    this.setState({ groupsState });
  }

  onCardAdd = () => {
    let { groupsState } = this.state;

    const data = {
      id: uniqueId(),
      name: '',
      emails: '',
      nameErrors: false,
      errors: false
    };

    groupsState.push(data);

    this.setState({ groupsState });
  }

  render() {
    const {
      role,
      educatorGroups,
      isSubmitting,
      organizationStats,
      isSubmittingEducatorGroups
    } = this.props;
    const { groupsState } = this.state;

    const isEducator = (role === 'educator');
    const isConfirming = isEducator ? isSubmittingEducatorGroups : isSubmitting;

    return (
      <Container>
        <Row>
          <Col lg="12">
            <div className="admin-title-container">
              <h1>Add New Group(s)</h1>
              <p>
                {`${(role === 'educator') ? 'To finish adding a group, you must name it first.' : 'To finish adding new groups, you must name them first.'}${(role !== 'educator') ? ' You can always edit your names later if you wish. It\'s optional to designate admins for each group if you\'d like to do that now. However, you can skip for now and add admins for each group later if you wish. ' : ' '}Once you name your new group${(role !== 'educator') ? 's' : ''}, click "I'm Finished" to complete the process.`}
              </p>
            </div>
          </Col>
        </Row>

        {isEducator ?
          <EducatorGroupCardsList
            role={role}
            groupsData={educatorGroups}
            onChangeAdminEmails={this.onChangeAdminEmails}
            onChangeGroupName={this.onChangeGroupName}
            organizationStats={organizationStats}
            groupsState={groupsState}
            onCardRemove={this.onCardRemove}
            onCardAdd={this.onCardAdd}/> :

          <GroupCardsList
            onChangeAdminEmails={this.onChangeAdminEmails}
            onChangeGroupName={this.onChangeGroupName}
            organizationStats={organizationStats}
            groupsState={groupsState}
            onCardRemove={this.onCardRemove}
            onCardAdd={this.onCardAdd}/>}

        <Row>
          <Col lg="12">
            <Button
              styles={{marginBottom: 20}}
              buttonText="I'm Finished"
              buttonClass="btn-primary float-right"
              onClick={this.onFinish}
              disabled={this.disableFinishButton() || (groupsState.length === 0)}/>
          </Col>
        </Row>

        {this.state.isConfirmModalOpen &&
          <ConfirmModal
            isOpen={this.state.isConfirmModalOpen}
            isConfirming={isConfirming}
            confirminginProgressText="Finishing..."
            modalHeaderText="Finish Adding New Group(s)"
            modalMessage={`Are you sure you're finished adding your new group(s)? Once you finish, your new group(s) will be added${(! isEducator) ? ' and any admin(s) you designated will be notified via email.' : '.'}`}
            modalFooterButtonText="Yes, I'm Finished"
            onCancel={this.onCancelConfirmModal}
            onConfirm={this.onConfirmFinishSetup}/>}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    adminGroups: group,
    organizationStats,
    profile: { unsavedChildGroupsData, data: { role } },

    groups: {
      data: groupsData,
      selectedGroup: { id: parentGroupId },
      isSubmitting: { status: isSubmitting }
    },
    educators: {
      groups,
      isSubmitting: { status: isSubmittingEducatorGroups }
    },
    institutions: { selectedInstitution: { isAdmin: isInstitutionAdmin } }
  } = state;

  const educatorGroups = groups.filter(group => group.id !== 'all-groups');

  return {
    role,
    group,
    groupsData,
    isSubmitting,
    parentGroupId,
    educatorGroups,
    organizationStats,
    isInstitutionAdmin,
    unsavedChildGroupsData,
    isSubmittingEducatorGroups,
    isRecentEducatorAccount: selectIsRecentEducatorAccount(state)
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    createGroups,
    createEducatorGroups,
    updateInstitutionGroupsCount,
    ...componentActions,
    ...adminGroupActions,
    ...routerActions,
    ...profileActions,
    ...organizationStatsActions
  }, dispatch),
});

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(CreateGroups));
