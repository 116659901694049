import React from 'react';

import moment from 'moment';
import classNames from 'classnames';
import { checkUrl } from '../../utils';

import PropTypes from 'prop-types';
import LazyLoadImage from '../../containers/common/lazy-load-image/LazyLoadImage';

import IconBookmark from '../../../src/assets/images/icon-bookmark.svg';
import IconVideo from '../../../src/assets/images/icon-video.svg';

const SkillBadgeCardCount = ({data, fileKey, onViewPortfolio, unlink, isOwner}) => {
  let thumbnail = data[fileKey].image ? data[fileKey].image :data[fileKey].url;
  const dateString = data[fileKey].modified ?
    moment(data[fileKey].modified.date).format('MM/DD/YYYY') : 'N/A';

  const docFile = data[fileKey].mimetype.includes('application') ||
    data[fileKey].mimetype.includes('text');

  const videoFile = checkUrl(data[fileKey].url, 'youtube') || checkUrl(data[fileKey].url, 'vimeo');
  const webLink = (!videoFile && !docFile && !data[fileKey].mimetype.includes('image'));

  const fileCardClassNames = classNames('port-file', {
    'portfolio-img': data[fileKey].mimetype.includes('image'),
    'portfolio-doc': docFile,
    'portfolio-video': videoFile,
    'portfolio-weblink': webLink
  });

  return (
    <div className={fileCardClassNames}>
      <div className="portfolio-file-image clickable"
        onClick={onViewPortfolio(data[fileKey].id)}
        data-toggle="modal"
        data-target="#examplePortfolio">
        {docFile &&
          <div className="thumb file-icon-container">
            <i className="fa fa-file-text-o"/>
          </div>}

        {videoFile &&
          <div className="thumb file-icon-container">
            <i>
              <img src={IconVideo} />
            </i>
          </div>}

        {webLink &&
          <div className="thumb file-icon-container">
            <i>
              <img src={IconBookmark} />
            </i>
          </div>}

        {!docFile && data[fileKey].mimetype !== '' &&
          <div className="portfolio-file-image-container">
            <LazyLoadImage
              imageClassName="thumb-image"
              alt={`${data[fileKey].title}'s thumnail`}
              srcLoaded={thumbnail}/>
          </div>}
      </div>
      <div className="file-details">
        <h3 className="portfolio-title">
          <a
            onClick={onViewPortfolio(data[fileKey].id)}
            className="clickable">{data[fileKey].title}</a>
        </h3>
        <div className="portfolio-description">{dateString}</div>
      </div>
      {isOwner &&
        <div className="portfolio-stats">
          <a
            className="display-block clickable orange font13" onClick={unlink(data[fileKey].id)}>
            Unlink
          </a>
        </div>
      }
    </div>
  );
};

SkillBadgeCardCount.propTypes = {
  data: PropTypes.array.isRequired,
  onViewPortfolio: PropTypes.func,
  unlink: PropTypes.func.isRequired,
  fileKey: PropTypes.number,
  isOwner: PropTypes.bool,
};

export default SkillBadgeCardCount;
