import React from 'react';

import PropTypes from 'prop-types';

import moment from 'moment';
import classNames from 'classnames';

import { checkUrl } from '../../utils';

import BadgeEvidenceFileCardHandler from '../../containers/skill-badges/BadgeEvidenceFileCardHandler';
import LazyLoadImage from '../../containers/common/lazy-load-image/LazyLoadImage';

import IconBookmark from '../../../src/assets/images/icon-bookmark.svg';
import IconVideo from '../../../src/assets/images/icon-video.svg';

const BadgePortfolioFiles = ({
  file,
  unlink,
  isOwner,
  onViewPortfolio,
  files
}) => {
  const dateString = file.modified ? moment(file.modified.date).format('MM/DD/YYYY'): 'N/A';

  if(!isOwner){
    return (
      <BadgeEvidenceFileCardHandler
        onViewPortfolio={onViewPortfolio}
        files={files}
        fileItem={file}/>
    );
  }

  const docFile = file.mimetype.includes('application') || file.mimetype.includes('text');
  const videoFile = checkUrl(file.url, 'youtube') || checkUrl(file.url, 'vimeo');
  const webLink = (!videoFile && !docFile && !file.mimetype.includes('image'));

  const fileCardClassNames = classNames('port-file', {
    'portfolio-img': file.mimetype.includes('image'),
    'portfolio-doc': docFile,
    'portfolio-video': videoFile,
    'portfolio-weblink': webLink
  });

  return (
    <div className={fileCardClassNames}>
      <div className="portfolio-file-image clickable"
        onClick={onViewPortfolio(file.id)}
        data-toggle="modal"
        data-target="#examplePortfolio">
        {docFile &&
          <div className="thumb file-icon-container">
            <i className="fa fa-file-text-o"/>
          </div>}

        {videoFile &&
          <div className="thumb file-icon-container">
            <i>
              <img src={IconVideo} />
            </i>
          </div>}

        {webLink &&
          <div className="thumb file-icon-container">
            <i>
              <img src={IconBookmark} />
            </i>
          </div>}

        {!docFile && file.mimetype !== '' &&
          <div className="portfolio-file-image-container">
            <LazyLoadImage
              imageClassName="thumb-image"
              alt={`${file.title}'s thumnail`}
              srcLoaded={file.url}/>
          </div>}
      </div>
      <div className="file-details">
        <h3 className="portfolio-title">
          <a
            onClick={onViewPortfolio(file.id)}
            className="clickable">{file.title}</a>
        </h3>
        <div className="portfolio-description">{dateString}</div>
      </div>
      {isOwner &&
        <div className="portfolio-stats">
          <a
            className="display-block clickable orange font13" onClick={unlink(file.id)}>
            Unlink
          </a>
        </div>
      }
    </div>

  );
};

BadgePortfolioFiles.propTypes = {
  onViewPortfolio: PropTypes.func.isRequired,
  onLikeEvidenceFile: PropTypes.func,
  file: PropTypes.object.isRequired,
  isOwner: PropTypes.bool,
  files: PropTypes.array,
  unlink: PropTypes.func.isRequired
};

export default BadgePortfolioFiles;
