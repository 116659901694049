import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Tooltip } from 'reactstrap';
import Chance from 'chance';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

const chance = new Chance();
const randomId = `simple-tooltip-${chance.hash({ length: 5})}`;

export class SimpleToolTipWrapper extends Component {
  static propTypes = {
    children: PropTypes.element,
    toolTipContent: PropTypes.element,
    id: PropTypes.string,
    wrapperClassNames: PropTypes.string
  }

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    const wrapperClassNames = classNames({
      [`${this.props.wrapperClassNames}`]: !isUndefined(this.props.wrapperClassNames)
    });

    return (
      <span className={wrapperClassNames}>
        <span href="#" id={this.props.id || randomId}>{this.props.children}</span>
        <Tooltip
          placement="top"
          isOpen={this.state.tooltipOpen}
          target={this.props.id || randomId}
          toggle={this.toggle}>
          {this.props.toolTipContent}
        </Tooltip>
      </span>
    );
  }
}
