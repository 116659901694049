import PropTypes from 'prop-types';
import React from 'react';

const SkillSectionCard = ({ skillDetails }) => {
  let skillName = skillDetails.name;

  if (skillDetails.visibility) {
    const href = `/skill-evidence/${skillDetails.id}`;

    skillName = <a href={href}>{skillDetails.name}</a>;
  }

  return (
    <div className="resume-section section-item">
      <span><b>{skillName}</b></span>
    </div>
  );
};

SkillSectionCard.propTypes = {
  skillDetails: PropTypes.object.isRequired
};

export default SkillSectionCard;
