import React from 'react';

import PropTypes from 'prop-types';

const styles = {
  link: {
    color: '#ff5000',
  },
};

const Link = ({ entityKey, children, contentState }) => {
  const { url } = contentState.getEntity(entityKey).getData();

  return (
    <a href={url} style={styles.link}>
      {children}
    </a>
  );
};

Link.propTypes = {
  entityKey: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
  contentState: PropTypes.object.isRequired,
};

export default Link;
