import React from 'react';

import PropTypes from 'prop-types';

const GlobalCompetenceSurveyForm = ({
  isSubmitting,
  surveyQuestions,
  isValidFeedback,
  onChangeFeedback,
  onSubmitFeedback
}) => {
  return (
    <div className="col-lg-12">
      <h2 className="mb-1">Global Competence Survey</h2>
      <p>To receive your certificate, please respond to the following questions and click Submit.</p>
      {surveyQuestions.map((record) => {
        const { id, question, choices, answer } = record;

        return(
          <div key={id}>
            <p className="mt-2">
              <strong>{question}</strong>
            </p>
            {choices.map((choice, index) =>
              (<p key={index} className="mb-0">
                <label>
                  <input
                    type="radio"
                    name={id}
                    checked={answer === choice}
                    onChange={onChangeFeedback}
                    value={choice}/>&nbsp;&nbsp;{choice}
                </label>
              </p>))}
          </div>
        );
      })}

      <button
        disabled={(!isValidFeedback) || isSubmitting}
        onClick={onSubmitFeedback}
        className="btn orange-btn mt-1">
        {isSubmitting ? 'Submitting...' : 'Submit'}
      </button>
    </div>
  );
};

GlobalCompetenceSurveyForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  surveyQuestions: PropTypes.array.isRequired,
  isValidFeedback: PropTypes.bool.isRequired,
  onChangeFeedback: PropTypes.func.isRequired,
  onSubmitFeedback: PropTypes.func.isRequired
};

export default GlobalCompetenceSurveyForm;
