import React, { useState, useEffect } from 'react';


import { Link } from 'react-router-dom';
import TestimonialSlider from '../TestimonialSlider';

const imgTDHero = require('../../../assets/images/homepage/organization/img-td-hero.jpg');
const imgHomepageUnleash = require('../../../assets/images/homepage/individual/img-homepage-potential.jpg');
const imgHomepageHighlight = require('../../../assets/images/homepage/individual/img-homepage-highlight.jpg');
const imgTDStand = require('../../../assets/images/homepage/organization/img-td-standout.jpg');
const imgTDHelp = require('../../../assets/images/homepage/organization/img-td-help.jpg');
const imgTDaccsc = require('../../../assets/images/homepage/organization/img-td-accsc.jpg');
const imgTDdeac = require('../../../assets/images/homepage/organization/img-td-deac.jpg');
const imgTDbccs = require('../../../assets/images/homepage/organization/img-td-bccs.jpg');
const imgTDacte = require('../../../assets/images/homepage/organization/img-td-acte.jpg');
const imgTDcecu = require('../../../assets/images/homepage/organization/img-td-cecu.jpg');
const imgTDnvde = require('../../../assets/images/homepage/organization/img-td-nvde.jpg');
const TalentDevelopers = () => {
  const words = ['students', 'grads', 'alum', 'job seekers'];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(false);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        // Cycles through 4 words
        setCurrentIndex(prevIndex => (prevIndex + 1) % words.length); 
        setFade(false);
      }, 500); 
      // Matches the CSS animation duration
    }, 3000); 
    // Change text every 2 seconds
    return () => clearInterval(intervalId); 
  }, [words.length]);

  return (
    <div className="talent-developers">
      <header>
        <div className="header-content">
          <div className="header-item">
            <div className="header-heading">
              <h1>Give your <div className="text-container"><span className={fade ? 'fade-out-slide-up' : 'fade-in-slide-up'}>{words[currentIndex]}</span></div><br /> a suite of self-help tools to drive their career success.</h1>
            </div>
            <p>CareerPrepped<sup style={{fontSize:'14px'}}>®</sup> helps you easily support your skilled talent whenever they need a hand. Our tools help them highlight their skills, secure their dream jobs, and grow their careers.</p>
            <div className="get-started">
              <Link
                state={{defaultTab: 'for-organizations'}}
                to="/auth/subscription">
                Get Started
              </Link>
            </div>
          </div>
          <div className="header-item">
            <img src={imgTDHero} alt="CareerPrepped - Talent Developers" className="img-fluid d-block mx-auto"/>
          </div>
        </div>
      </header>
      <article className="section white">  
        <div className="container">
          <div className="section-heading">
            <h2>Help them compete in an AI-world.</h2>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="section-container">
                <div className="section-container-item order-sm-1">
                  <img src={imgHomepageUnleash} className="img-fluid d-block mx-auto" alt="CareerPrepped - Talent Developers - AI" />
                </div>
                <div className="section-container-item order-sm-2 p-5">
                  <p>
                    In today's world, AI can easily write cover letters and craft generic resumes that look alike. It's becoming harder for employers to gauge a person's true abilities.
                  </p>

                  <p>
                    And as AI advances, human skills like communication and teamwork are in higher demand in the workforce.
                  </p>

                  <p>
                    Today's talent must prove their applied skills and highlight their human skills better than the competition to land the jobs they want.
                  </p>

                  <p>
                    Our tools help you tackle these problems and more. They enable your institution to show it's the best place to find skilled talent in today's AI-driven job market.
                  </p>
                  <div className="get-started center">
                    <Link
                      state={{defaultTab: 'for-organizations'}}
                      to="/auth/subscription"
                      className="btn signup-btn">
                      Get Started Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>  
      <article className="section grey">
        <div className="container">
          <div className="section-heading">
            <h2>Help them showcase their skills. </h2>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="section-container">
                <div className="section-container-item order-sm-1 order-md-2">
                  <img src={imgHomepageHighlight} alt="CareerPrepped - Talent Developers - Showcase their skills" className="img-fluid d-block mx-auto" />
                </div>
                <div className="section-container-item order-sm-2 order-md-1 p-5">
                  <p>
                    Make it easy for your skilled talent to prove their skills to the world and keep a lifelong record of their skills as they grow their careers.
                  </p>
                  <p>
                    As a member of CareerPrepped's supportive community, they can ask peers, educators, and industry pros for feedback on how well they've proven their skills.  
                  </p>
                  <p>
                    With feedback, they can learn their strengths and where they need to level up. This helps them ensure their skills are visible and recognized by employers.
                  </p>
                  <p>
                    They also get tools to enhance and highlight their "human skills" - the essential skills all employers want.
                  </p>
                  <div className="get-started center">
                    <Link
                      state={{defaultTab: 'for-organizations'}}
                      to="/auth/subscription"
                      className="btn signup-btn">
                      Get Started Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="section white">  
        <div className="container">
          <div className="section-heading">
            <h2>Help them stand out in the crowd.</h2>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="section-container">
                <div className="section-container-item order-sm-1">
                  <img src={imgTDStand} className="img-fluid d-block mx-auto" alt="CareerPrepped - Talent Developers - Stand out in the crowd" />
                </div>
                <div className="section-container-item order-sm-2 p-5">
                  <p>
                    Enable your skilled talent to stand out beyond a basic resume or profile. CareerPrepped gives them a complete Career Site with multiple pages to showcase their skills and achievements.
                  </p>
                  <p>
                    And with a single click, they can turn their Career Site into an interactive resume with clickable links to prove their claimed skills with evidence.
                  </p>
                  <div className="get-started center">
                    <Link
                      state={{defaultTab: 'for-organizations'}}
                      to="/auth/subscription"
                      className="btn signup-btn">
                      Get Started Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>  
      <article className="section blue-1">
        <div className="container">
          <div className="section-heading">
            <h2>Help them get hired faster.</h2>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="section-container">
                <div className="section-container-item order-sm-1 order-md-2">
                  <img src={imgTDHelp} alt="CareerPrepped - Talent Developers - Help them get hired faster" className="img-fluid d-block mx-auto" />
                </div>
                <div className="section-container-item order-sm-2 order-md-1 p-5">
                  <p>
                    Let your skilled talent discover opportunities they may not have known existed with our AI-driven Job Match tool. It scours thousands of job sites to find personalized job matches based on their preferences and goals.
                  </p>
                  <p>
                    With our Job Tracker tool, they can save the jobs they like, keep track of company contacts, and take notes on their progress. They can track every step of the way from applying to getting a job offer.
                  </p>
                  <div className="get-started center">
                    <Link
                      state={{defaultTab: 'for-organizations'}}
                      to="/auth/subscription"
                      className="btn signup-btn">
                      Get Started Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="section blue">
        <div className="container">
          <div className="section-heading">
            <h2 className="mb-4">Adopted by hundreds of career and technical education institutions.</h2>
            <p>Leverage CareerPrepped as a “hands-free” platform and give instant access to your students, graduates, alumni, and job seekers.</p>
            <p>Optionally integrate any of our tools in your programs and services using our institutional support resources.</p>
            <p>Elevate and scale your career support services today!<br/>Unlock the power of CareerPrepped's Toolkit.</p>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="section-container">
                <div className="section-container-item order-sm-1 order-md-2">
                  <div className="section-bullets">
                    <ul className="fa-ul">
                      <li><span className="fa-li"><i className="fa fa-genderless" /></span>Career Site</li>
                      <li><span className="fa-li"><i className="fa fa-genderless" /></span>Resume Builder</li>
                      <li><span className="fa-li"><i className="fa fa-genderless" /></span>Job Match</li>
                      <li><span className="fa-li"><i className="fa fa-genderless" /></span>Job Tracker</li>
                      <li><span className="fa-li"><i className="fa fa-genderless" /></span>Interview Prep Video Library</li>
                    </ul>  
                    <ul className="fa-ul">
                      <li><span className="fa-li"><i className="fa fa-genderless" /></span>Career Portfolio </li>
                      <li><span className="fa-li"><i className="fa fa-genderless" /></span>Skill Builders for Essential Skills </li>
                      <li><span className="fa-li"><i className="fa fa-genderless" /></span>Skill Badges for Essential Skills </li>
                      <li><span className="fa-li"><i className="fa fa-genderless" /></span>Skill Feedback & Ratings </li>
                      <li><span className="fa-li"><i className="fa fa-genderless" /></span>Skill Endorsements & Testimonials </li>
                    </ul>  
                  </div>
                  <div className="section-action">
                    <div className="get-started toolkit-action center">
                      <Link
                        to="/toolkit"
                        className="toolkit">
                        Explore the Toolkit
                      </Link>
                      <Link
                        state={{defaultTab: 'for-organizations'}}
                        to="/auth/subscription"
                        className="btn signup-btn">
                        Get Started Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="section white">
        <div className="container">
          <div className="section-heading">
            <h2 className="mb-4">CareerPrepped has been endorsed by premier career education associations, accrediting bodies, and state agencies to facilitate career readiness, work-based learning, and skills-based hiring.</h2>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="section-container">
                <div className="section-container-item order-sm-1 order-md-2">
                  <div className="section-endorsed">
                    <div><img src={imgTDaccsc} alt="CareerPrepped - Talent Developers - ACCSC" /></div>
                    <div><img src={imgTDdeac} alt="CareerPrepped - Talent Developers - DEAC" /></div>
                    <div><img src={imgTDbccs} alt="CareerPrepped - Talent Developers - BCCS" /></div>
                    <div><img src={imgTDacte} alt="CareerPrepped - Talent Developers - ACTE" /></div>
                    <div><img src={imgTDcecu} alt="CareerPrepped - Talent Developers - CECU" /></div>
                    <div><img src={imgTDnvde} alt="CareerPrepped - Talent Developers - NVDE" /></div>
                  </div>
                  <div className="section-action">
                    <div className="get-started toolkit-action center">
                      <Link
                        state={{defaultTab: 'for-organizations'}}
                        to="/auth/subscription">
                        Get Started Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="iltestimonials">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <TestimonialSlider />
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

export default TalentDevelopers;
