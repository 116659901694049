import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ThinkingBreakAnswerTextForm from './ThinkingBreakAnswerTextForm';
import ThinkingBreakAnswerFeedbackForm from './ThinkingBreakAnswerFeedbackForm';

class ThinkingBreakAnswerForm extends Component {
  static propTypes = {
    editingItemId: PropTypes.string,
    content: PropTypes.array.isRequired,
    onAddAnswer: PropTypes.func.isRequired,
    onUpdateAnswer: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      textErrors: '',
      feedbackErrors: '',
      text: '',
      feedback: ''
    };

    this.onChangeText = this.onChangeText.bind(this);
    this.onChangeFeedback = this.onChangeFeedback.bind(this);
    this.onSaveAnswer = this.onSaveAnswer.bind(this);
  }

  componentWillReceiveProps(nextProps){
    const { editingItemId } = this.props;
    const { editingItemId : nextEditingId, content } = nextProps;
    if(editingItemId !== nextEditingId && nextEditingId !== null){
      const editingItem = content.find(item => item.itemId === nextEditingId);
      this.setState({
        text: editingItem.value,
        feedback: editingItem.feedback,
        feedbackErrors: '',
        textErrors: ''
      });
    }
  }

  onChangeText(text, error){
    this.setState({
      text: text,
      textErrors: error
    });
  }

  onChangeFeedback(text, error){
    this.setState({
      feedback: text,
      feedbackErrors: error ? error : ''
    });
  }


  onSaveAnswer(event){
    event.preventDefault();

    const { editingItemId } = this.props;
    const { textErrors, feedbackErrors, text, feedback } = this.state;

    if(textErrors !== '' || feedbackErrors !== ''){
      return ;
    }

    const actionToTrigger = editingItemId !== null ? 'onUpdateAnswer' : 'onAddAnswer';

    this.props[`${actionToTrigger}`](text, feedback);
    this.setState({
      text: '',
      feedback: ''
    });

  }

  render() {
    const { editingItemId } = this.props;
    const { textErrors, text, feedbackErrors, feedback } = this.state;

    return (
      <div className="thinking-break-answer-form-wrapper mb-4">
        <div className="row">
          <ThinkingBreakAnswerTextForm
            errors={textErrors}
            text={text}
            editingItemId={this.props.editingItemId}
            onChangeText={this.onChangeText}/>
          <ThinkingBreakAnswerFeedbackForm
            errors={feedbackErrors}
            editingItemId={this.props.editingItemId}
            feedback={feedback}
            onChangeFeedback={this.onChangeFeedback}/>
        </div>
        <button
          style={{marginLeft: 0}}
          type="button"
          onClick={this.onSaveAnswer}
          className="btn btn-secondary">
          {editingItemId !== null ? 'Update Answer' : 'Add Answer'}&nbsp;
          <i className="fa fa-plus"/>
        </button>
        <hr className="clearfix" />
      </div>

    );
  }
}

export default ThinkingBreakAnswerForm;
