import initialState from './initialState';

import {
  RESET_ASSERTION_REDUCER,
  BADGE_ASSERTION_REQUEST,
  BADGE_ASSERTION_SUCCESS,
  BADGE_ASSERTION_FAILURE,
  ADD_ACKNOWLEDGEMENT_REQUEST,
  ADD_ACKNOWLEDGEMENT_SUCCESS,
  ADD_ACKNOWLEDGEMENT_FAILURE,
  REMOVE_ACKNOWLEDGEMENT_REQUEST,
  REMOVE_ACKNOWLEDGEMENT_SUCCESS,
  REMOVE_ACKNOWLEDGEMENT_FAILURE,
  UPDATE_STATEMENT_REQUEST,
  UPDATE_STATEMENT_SUCCESS,
  UPDATE_STATEMENT_FAILURE,
  UPDATE_JUSTIFICATION_ANSWER_REQUEST,
  UPDATE_JUSTIFICATION_ANSWER_SUCCESS,
  UPDATE_JUSTIFICATION_ANSWER_FAILURE,
  ADD_EVIDENCE_FILE_REQUEST,
  ADD_EVIDENCE_FILE_SUCCESS,
  ADD_EVIDENCE_FILE_FAILURE,
  REMOVE_BADGE_EVIDENCE_FILE_REQUEST,
  REMOVE_BADGE_EVIDENCE_FILE_SUCCESS,
  REMOVE_BADGE_EVIDENCE_FILE_FAILURE,
  LIKE_EVIDENCE_FILE_REQUEST,
  LIKE_EVIDENCE_FILE_SUCCESS,
  LIKE_EVIDENCE_FILE_FAILURE,
  UNLIKE_EVIDENCE_FILE_REQUEST,
  UNLIKE_EVIDENCE_FILE_SUCCESS,
  UNLIKE_EVIDENCE_FILE_FAILURE,
  // linking files
  LINK_NEW_PORTFOLIO_FILE_TO_A_BAKED_BADGE_SUCCESS,
  LINK_PORTFOLIO_FILE_SUCCESS,
  // linking skills
  LINK_MY_SKILLS_SUCCESS,
} from '../../constants';

const addAcknowledgement = (stateData, actionData) => {
  let acknowledgements = [];
  if (stateData.acknowledgements) {
    acknowledgements = [...stateData.acknowledgements];
  }
  return [...acknowledgements, actionData];
};

const addEvidenceFile = (stateData, actionData) => {
  let files = [];
  if (stateData.files) {
    files = [...stateData.files];
  }
  return [...files, actionData];
};

const linkEvidenceFile = (stateData, actionData) => {
  const updatedBadge = actionData.badges.find(
    badgeItem => stateData.badge.id === badgeItem.id
  );

  if (updatedBadge) {
    return [...updatedBadge.files];
  }

  return [...stateData.files];
};

export default function reducer(state = initialState.badgeAssertion, action) {
  switch (action.type) {
  case RESET_ASSERTION_REDUCER:
    return Object.assign({}, initialState.badgeAssertion);

  case BADGE_ASSERTION_REQUEST:
    return Object.assign({}, state, {
      isRequesting: true,
    });

  case BADGE_ASSERTION_SUCCESS: {
    return Object.assign({}, state, {
      data: action.data,
      isRequesting: false,
    });
  }

  case BADGE_ASSERTION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case ADD_ACKNOWLEDGEMENT_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: true,
    });

  case ADD_ACKNOWLEDGEMENT_SUCCESS:
    return Object.assign({}, state, {
      data: {
        ...state.data,
        acknowledgements: addAcknowledgement(state.data, action.data),
      },
      isSubmitting: false,
    });

  case ADD_ACKNOWLEDGEMENT_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case REMOVE_ACKNOWLEDGEMENT_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: action.id },
    });

  case REMOVE_ACKNOWLEDGEMENT_SUCCESS:
    return Object.assign({}, state, {
      data: {
        ...state.data,
        acknowledgements: state.data.acknowledgements.filter(
          ack => ack.id !== state.isDeleting.id
        ),
      },
      iisDeleting: { status: false, id: null },
    });

  case REMOVE_ACKNOWLEDGEMENT_FAILURE:
    return Object.assign({}, state, {
      isDeleting: { status: false, id: null },
      error: action.error,
    });

  case UPDATE_STATEMENT_REQUEST:
    return Object.assign({}, state, {
      isUpdating: true,
    });

  case UPDATE_STATEMENT_SUCCESS:
    return Object.assign({}, state, {
      data: {
        ...state.data,
        statement: [action.data],
      },
      isUpdating: false,
    });

  case UPDATE_STATEMENT_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: false,
    });

  case UPDATE_JUSTIFICATION_ANSWER_REQUEST:
    return Object.assign({}, state, {
      isUpdatingJustificationAnswer: { status: true, index: action.index },
    });

  case UPDATE_JUSTIFICATION_ANSWER_SUCCESS:
    return Object.assign({}, state, {
      data: {
        ...state.data,
        justifications: [
          ...state.data.justifications.slice(
            0,
            state.isUpdatingJustificationAnswer.index
          ),
          action.data,
          ...state.data.justifications.slice(
            state.isUpdatingJustificationAnswer.index + 1
          ),
        ],
      },
      isUpdatingJustificationAnswer: { status: false, index: null },
    });

  case UPDATE_JUSTIFICATION_ANSWER_FAILURE:
    return Object.assign({}, state, {
      isUpdatingJustificationAnswer: { status: false, index: null },
    });

  case ADD_EVIDENCE_FILE_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: true,
    });

  case ADD_EVIDENCE_FILE_SUCCESS:
    return Object.assign({}, state, {
      data: {
        ...state.data,
        files: addEvidenceFile(state.data, action.data),
      },
      isSubmitting: false,
    });

  case ADD_EVIDENCE_FILE_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case REMOVE_BADGE_EVIDENCE_FILE_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: action.id }
    });

  case REMOVE_BADGE_EVIDENCE_FILE_SUCCESS:
    return Object.assign({}, state, {
      data: {
        ...state.data,
        files: state.data.files.filter(file => file.id !== state.isDeleting.id)
      },
      isDeleting: { status: false, id: null }
    });

  case REMOVE_BADGE_EVIDENCE_FILE_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: { status: false, id: null }
    });

  case LIKE_EVIDENCE_FILE_REQUEST:
  case UNLIKE_EVIDENCE_FILE_REQUEST:
    return Object.assign({}, state, {
      isLikingFile: { status: true, id: action.id },
    });

  case LIKE_EVIDENCE_FILE_SUCCESS: {
    const { isLikingFile, data } = state;
    const { files } = data;
    const fileIndex = files.findIndex(file => file.id === isLikingFile.id);
    const updatedFileObject = Object.assign({}, files[fileIndex], {
      liked: true,
      like_count: files[fileIndex].like_count + 1,
    });

    return Object.assign({}, state, {
      data: {
        ...state.data,
        files: [
          ...files.slice(0, fileIndex),
          Object.assign({}, updatedFileObject),
          ...files.slice(fileIndex + 1),
        ],
      },
      isLikingFile: { status: false, id: null },
    });
  }

  case UNLIKE_EVIDENCE_FILE_SUCCESS: {
    const { isLikingFile, data } = state;
    const { files } = data;
    const fileIndex = files.findIndex(file => file.id === isLikingFile.id);
    const updatedFileObject = Object.assign({}, files[fileIndex], {
      liked: false,
      like_count: files[fileIndex].like_count - 1,
    });

    return Object.assign({}, state, {
      data: {
        ...state.data,
        files: [
          ...files.slice(0, fileIndex),
          Object.assign({}, updatedFileObject),
          ...files.slice(fileIndex + 1),
        ],
      },
      isLikingFile: { status: false, id: null },
    });
  }

  case LIKE_EVIDENCE_FILE_FAILURE:
  case UNLIKE_EVIDENCE_FILE_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isLikingFile: { status: false, id: null },
    });

    /* Reducers affected by Files Actions */
  case LINK_PORTFOLIO_FILE_SUCCESS: {
    if (Object.keys(state.data).length > 0) {
      return Object.assign({}, state, {
        data: {
          ...state.data,
          files: linkEvidenceFile(state.data, action.data),
        },
      });
    }

    return state;
  }

  case LINK_NEW_PORTFOLIO_FILE_TO_A_BAKED_BADGE_SUCCESS: {
    if (Object.keys(state.data).length > 0) {
      return Object.assign({}, state, {
        data: {
          ...state.data,
          files: addEvidenceFile(state.data, action.data),
        },
        isSubmitting: false,
      });
    }

    return state;
  }

  /* End */

  /* Reducers affected by Linking Skills */
  case LINK_MY_SKILLS_SUCCESS:
    return Object.keys(state.data).length > 0
      ? Object.assign({}, state, {
        data: {
          ...state.data,
          issued: Object.assign({}, state.data.issued, {
            skills: [...action.data.badges[0].skills],
          }),
        },
        isSubmitting: false,
      })
      : state;

    /* End */

  default:
    return state;
  }
}
