import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip } from 'reactstrap';
import Button from '../common/Button';
import classNames from 'classnames';
import { has } from 'lodash';
import QuillEditor from '../../components/common/QuillEditor';

const EditBadgeSupportingStatementForm = ({
  errors,
  statement,
  isUpdating,
  onChangeStatement,
  onSubmitStatement,
  onToggleEditStatement,
  statementGuidelinesTooltipOpen,
  toggleStatementGuidelinesTooltip
}) => {
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'align': [] }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      ['link'],
    ],
    clipboard: {
      matchVisual: false,
    }
  };
  const pointerStyle = {
    color: '#ff5000',
    cursor: 'pointer'
  };

  return (
    <div className="claim-item">
      <strong>Provide a Written Justification</strong>
      <p className="note">
        If you have a skill, you're expected to be able to articulate your skill and share stories of using it to achieve positive results. Employers assess your skill claims via interviews. Thus, to justify your skill claim which this Skill Badge represents, provide quality responses to the questions below. They'll be shown in your Skill Badge and <b>publicly visible</b>. Ensure your writing is free of typos.&nbsp;Keep the (500 words max.)&nbsp;
        <a style={pointerStyle} id="supporting-statement-guidelines">(See guidelines)</a><br/><br/>
        <i>*Note: If you are unable to answer the questions, it may be a sign you haven't acquired the skill, so it may be too early to claim your skill badge.</i>
        <Tooltip
          placement="top"
          isOpen={statementGuidelinesTooltipOpen}
          target="supporting-statement-guidelines"
          toggle={toggleStatementGuidelinesTooltip}>
          <div className="inside-tooltip" style={{fontSize: 12}}>
            <b>Guidelines for Written Justifications</b>
            <ul style={{padding: '10px 0px 20px 20px', textAlign: 'left', marginBottom: 0}}>
              <li>Write in the first-person voice (I, Me, My)</li>
              <li>Be specific when sharing examples of how you've applied claimed abilities in your experience</li>
              <li>Clearly explain how your examples provide evidence of your claimed abilities</li>
              <li>Be concise but include all relevant details</li>
              <li>Don’t share confidential or proprietary information</li>
              <li>Don’t have grammatical or spelling errors</li>
            </ul>
          </div>
        </Tooltip>
      </p>
      <div className={classNames('statement-form-container', {
        'has-danger': errors.statement
      })}>
        <QuillEditor
          value={statement}
          placeholder="Imagine interviewing for a job. How would you respond if the employer said, 'what makes you think you have this skill?' Provide 2-3 specific examples of how you've demonstrated the abilities that define this skill. Share any specific, positive results or achievements you've had that demonstrate effective use of this skill. Also, feel free to share things you've done to independently learn more about this skill such as books you've read, online courses you've completed, certificates you've received or other credentials you have directly relevant to this skill."
          onChange={onChangeStatement}
          modules={modules} />

        {errors.statement && <div className="text-danger">{errors.statement}</div>}
      </div>
      <div
        style={{textAlign: 'right', marginTop: 10}}
        className="clearfix">
        <Button
          disabled={isUpdating}
          onClick={onToggleEditStatement}
          styles={{marginLeft: 0}}
          buttonClass="btn-secondary float-left"
          buttonText="Cancel"/>
        <Button
          disabled={(isUpdating || (has(errors, 'statement')))}
          onClick={onSubmitStatement}
          buttonClass="btn-primary"
          buttonText={isUpdating ? 'Saving...' : 'Save'}/>
      </div>
    </div>
  );
};

EditBadgeSupportingStatementForm.propTypes = {
  statement: PropTypes.string,
  errors: PropTypes.object.isRequired,
  isUpdating:PropTypes.bool.isRequired,
  onSubmitStatement: PropTypes.func.isRequired,
  onChangeStatement: PropTypes.func.isRequired,
  onToggleEditStatement: PropTypes.func.isRequired,
  statementGuidelinesTooltipOpen: PropTypes.bool.isRequired,
  toggleStatementGuidelinesTooltip: PropTypes.func.isRequired
};

export default EditBadgeSupportingStatementForm;
