import PropTypes from 'prop-types';
import React from 'react';
import ActionsWrapper from './ActionsWrapper';

const ActionsCell = (props) => {
  return (
    <ActionsWrapper
      {...props}
      as="actionsCellMenu"
      placement="bottom">
      <button
        className="btn btn-secondary dropdown-toggle btn-sm"
        type="button">
        Actions
      </button>
    </ActionsWrapper>
  );
};

ActionsCell.propTypes = {
  user: PropTypes.object,
};

export default ActionsCell;
