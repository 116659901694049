import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isOverflown } from '../../../utils';

import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import * as componentsActions from '../../../redux/actions/components';
import * as discussionsActions from '../../../redux/actions/discussions';

import Button from '../../../components/common/Button';
import TextArea from '../../../components/common/TextArea';

const discussionInitialState = { sharedQuotedText: '', permissions: '1' };

class SharedDiscussionReflectionEdit extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    discussion: PropTypes.object.isRequired,
    isUpdating: PropTypes.bool.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      showAll: false,
      isContentsOverflown: false,
      discussion: this.setInitialDiscussion(props.discussion),
      isCancelModalOpen: false
    };

    this.onCancel = this.onCancel.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onToggleCancelModal = this.onToggleCancelModal.bind(this);
    this.onCancelConfirm = this.onCancelConfirm.bind(this);
    this.onToggleShowAll = this.onToggleShowAll.bind(this);
    this.setReflectionAnswersContentContainerRef = this.setReflectionAnswersContentContainerRef.bind(this);
  }

  componentDidMount(){
    const refDiv = this[`reflectionAnswerDiv-${this.props.discussion.id}`];

    if(refDiv){
      /* eslint-disable react/no-did-mount-set-state */
      this.setState({
        isContentsOverflown: isOverflown(refDiv)
      });
    }
  }

  componentDidUpdate(prevProps, prevState){
    const { showAll } = prevState;

    if(showAll !== this.state.showAll){
      const refDiv = this[`reflectionAnswerDiv-${this.props.discussion.id}`];

      if(refDiv){
        /* eslint-disable react/no-did-update-set-state */
        this.setState({
          isContentsOverflown: isOverflown(refDiv)
        });
      }
    }
  }

  setInitialDiscussion(discussionInProps){
    if(discussionInProps){
      return Object.assign({}, discussionInitialState, discussionInProps);
    }

    return discussionInitialState;
  }

  onToggleShowAll() {
    this.setState({
      showAll: !this.state.showAll
    });
  }

  setReflectionAnswersContentContainerRef(div) {
    this[`reflectionAnswerDiv-${this.props.discussion.id}`] = div;
  }

  onChange(event) {
    const field = event.target.name;
    const { discussion } = this.state;

    discussion[field] = event.target.value;

    this.setState({ discussion });
  }


  onCancelConfirm(){
    const { actions, discussion } = this.props;
    const form = `update-post-form-${discussion.id}`;

    actions.unloadForm(form);
  }

  onCancel(){
    const { discussion } = this.state;
    const { sharedQuotedText } = this.props.discussion;
    const {
      sharedQuotedText: stateQuote
    } = discussion;

    if(sharedQuotedText !== stateQuote){
      return this.onToggleCancelModal();
    }

    this.onCancelConfirm();
  }

  toggleVisibilityDropdown(){
    this.setState({
      visibilityDropdownOpen: !this.state.visibilityDropdownOpen
    });
  }

  onToggleCancelModal(){
    this.setState({
      isCancelModalOpen: !this.state.isCancelModalOpen
    });
  }

  onSelectVisibilty(id){
    return () => {
      const wallPostObject = { ...this.state.wallPost, permissions:id};

      this.setState({
        wallPost: wallPostObject,
        visibilityDropdownOpen: false
      });
    };
  }

  onUpdate(event) {
    event.preventDefault();

    const { discussion: discussionState } = this.state;
    const { actions } = this.props;

    const quoteState = discussionState.sharedQuotedText.trim();

    const discussion = Object.assign({}, discussionState, { sharedQuotedText: quoteState });

    const data = {
      'sharedQuotedText': discussionState.sharedQuotedText
    };

    actions.updateUnitDiscussion(discussion.id, data)
      .then(() => this.handleResponse());
  }

  handleResponse() {
    const { discussion } = this.props;

    this.props.actions.unloadForm(`update-post-form-${discussion.id}`);
  }

  render() {
    const { isCancelModalOpen, showAll, isContentsOverflown, discussion } = this.state;
    const { isUpdating } = this.props;
    const { sharedQuotedText, sharedSkillBuilderReflections } = discussion;

    return (
      <div>
        <div className="content experience add-portfolio-files">
          <div className="row">
            <div className="col-lg-12">
              <div className="shared-item">
                <TextArea
                  name="sharedQuotedText"
                  type="text"
                  rows="3"
                  value={sharedQuotedText}
                  onChange={this.onChange}
                  placeholder="Say something about this badge"/>
                <div className="shared-item">
                  {
                    sharedSkillBuilderReflections.length > 0 &&
                      <div style={{display: 'block'}} className="shared-item-container">
                        <div className="shared-item-description reflection-sharing">
                          <p>I just answered the reflection question(s) below from <Link to={`/skill-builders/${sharedSkillBuilderReflections[0].unit.slug}`}>{sharedSkillBuilderReflections[0].unit.title}</Link></p>
                          <hr/>
                          <div
                            style={{
                              maxHeight: showAll ? 'initial': '100%',
                              overflow: isContentsOverflown ? 'hidden' : 'initial'
                            }}
                            ref={this.setReflectionAnswersContentContainerRef}
                            className="shared-reflection-contents">
                            {
                              sharedSkillBuilderReflections.map((ref, index) =>
                                (<div key={ref.id}>
                                  <strong>{ref.question}</strong><br/>
                                  {ref.answer}{index + 1 !== sharedSkillBuilderReflections.length && <span><br/><br/></span>}
                                </div>))
                            }
                          </div>
                          {
                            (isContentsOverflown || showAll) &&
                              <div
                                onClick={this.onToggleShowAll}
                                className="clickable link"
                                style={{marginTop: 10, fontWeight: '600'}}>
                                {showAll ? 'Show less': '...Show more'}</div>
                          }
                        </div>
                      </div>
                  }
                </div>
              </div>
              <div className="portfolio-buttons margin-with-border col-lg-12">
                <Button
                  type="button"
                  disabled={isUpdating}
                  onClick={this.onCancel}
                  buttonClass="btn-secondary"
                  buttonText="Cancel"/>
                <Button
                  type="submit"
                  disabled={isUpdating}
                  onClick={this.onUpdate}
                  buttonClass="btn btn-primary"
                  buttonText={isUpdating ? 'Updating...' : 'Update'}/>
              </div>
            </div>
          </div>
        </div>
        <Modal
          backdrop="static"
          className="modal-margin-top"
          isOpen={isCancelModalOpen}
          toggle={this.onToggleCancelModal}>
          <ModalHeader
            toggle={this.onToggleCancelModal}>
            Discard you changes
          </ModalHeader>
          <ModalBody>
            Are you sure you want to discard your changes?
          </ModalBody>
          <ModalFooter>
            <button
              onClick={this.onToggleCancelModal}
              className="btn btn-secondary">
              No
            </button>

            <button
              onClick={this.onCancelConfirm}
              className="btn btn-primary">
              Discard Changes
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    isUpdating: state.discussions.isUpdating.status
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, componentsActions, discussionsActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SharedDiscussionReflectionEdit);
