import initialState from './initialState';

import {
  ASSOCIATIONS_REQUEST,
  ASSOCIATIONS_SUCCESS,
  ASSOCIATIONS_FAILURE,
  CREATE_ASSOCIATION_REQUEST,
  CREATE_ASSOCIATION_SUCCESS,
  CREATE_ASSOCIATION_FAILURE,
  UPDATE_ASSOCIATION_REQUEST,
  UPDATE_ASSOCIATION_SUCCESS,
  UPDATE_ASSOCIATION_FAILURE,
  DELETE_ASSOCIATION_REQUEST,
  DELETE_ASSOCIATION_SUCCESS,
  DELETE_ASSOCIATION_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.associations, action) {
  switch (action.type) {
  case ASSOCIATIONS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case ASSOCIATIONS_SUCCESS:
    return Object.assign({}, state, {
      data: action.data._embedded.association,
      isRequesting: false,
    });

  case ASSOCIATIONS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case CREATE_ASSOCIATION_REQUEST:
    return Object.assign({}, state, { isSubmitting: true });

  case CREATE_ASSOCIATION_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data, Object.assign({}, action.data)],
      isSubmitting: false,
    });

  case CREATE_ASSOCIATION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case UPDATE_ASSOCIATION_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, associationIndex: action.index },
    });

  case UPDATE_ASSOCIATION_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, state.isUpdating.associationIndex),
        Object.assign({}, action.data),
        ...state.data.slice(state.isUpdating.associationIndex + 1),
      ],
      isUpdating: { status: false, associationIndex: null },
    });

  case UPDATE_ASSOCIATION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false, associationIndex: null },
    });

  case DELETE_ASSOCIATION_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: action.id },
    });

  case DELETE_ASSOCIATION_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.filter(
          association => association.id !== state.isDeleting.id
        ),
      ],
      isDeleting: { status: false, id: null },
    });

  case DELETE_ASSOCIATION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: { status: false, id: null },
    });

  default:
    return state;
  }
}
