import { CALL_API } from '../../middlewares/api';

import {
  CREATE_SUBSCRIPTION_REQUEST,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAILURE
} from '../../constants';

export const createSubscription = (data) => {
  const endpoint = 'billing/subscriptions';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      types: [
        CREATE_SUBSCRIPTION_REQUEST,
        CREATE_SUBSCRIPTION_SUCCESS,
        CREATE_SUBSCRIPTION_FAILURE,
      ],
    },
  };
};
