import PropTypes from 'prop-types';
import React from 'react';
import Parser from 'html-react-parser';

const LessPost = ({post, textLimit=400}) => {
  let lengthOfText = 0;
  let nextLengthOfText = 0;
  const arrayToMap = typeof post[0] === 'string' ? post : post[0]; 

  return (
    <span className="dont-break-out">
      {arrayToMap.map((item, index)=>{
        if (React.isValidElement(item)) {
          const lengthOfUrl = item.key.length;
          nextLengthOfText = nextLengthOfText + lengthOfUrl;

          if (nextLengthOfText > textLimit) return;

          return <span key={index}>{item}</span>;
        }

        const textLength = item.length;

        lengthOfText = nextLengthOfText;
        nextLengthOfText = nextLengthOfText + textLength;

        if (nextLengthOfText > textLimit) {
          const lengthToCut = textLimit - lengthOfText;
          const parsedItem = Parser(item.slice(0, lengthToCut));

          return <span key={index}>{parsedItem}</span>;
        }

        return <span key={index}>{Parser(item)}</span>;
      })}
    </span>
  );
};

LessPost.propTypes = {
  post: PropTypes.array.isRequired,
  textLimit: PropTypes.number
};

export default LessPost;
