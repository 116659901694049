import unionBy from 'lodash/unionBy';
import sortBy from 'lodash/sortBy';

import initialState from './initialState';
import {
  COMMENTS_REQUEST,
  COMMENTS_SUCCESS,
  COMMENTS_FAILURE,
  CREATE_COMMENT_LIKE_REQUEST,
  CREATE_COMMENT_LIKE_SUCCESS,
  CREATE_COMMENT_LIKE_FAILURE,
  CREATE_COMMENT_REQUEST,
  CREATE_COMMENT_SUCCESS,
  CREATE_COMMENT_FAILURE,
  UPDATE_COMMENT_REQUEST,
  UPDATE_COMMENT_SUCCESS,
  UPDATE_COMMENT_FAILURE,
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_FAILURE,
  CREATE_REPLY_REQUEST,
  CREATE_REPLY_SUCCESS,
  CREATE_REPLY_FAILURE,
  DELETE_REPLY_REQUEST,
  DELETE_REPLY_SUCCESS,
  DELETE_REPLY_FAILURE,
  UPDATE_REPLY_REQUEST,
  UPDATE_REPLY_SUCCESS,
  UPDATE_REPLY_FAILURE,
  CREATE_REPLY_LIKE_REQUEST,
  CREATE_REPLY_LIKE_SUCCESS,
  CREATE_REPLY_LIKE_FAILURE,
  UNLIKE_DISCUSSION_COMMENT_REQUEST,
  UNLIKE_DISCUSSION_COMMENT_SUCCESS,
  UNLIKE_DISCUSSION_COMMENT_FAILURE,
  LOAD_INITIAL_COMMENTS,
  INITIALIZE_COMMENTS,
  LOAD_PREVIOUS_REPLIES,
  UNLIKE_REPLY_REQUEST,
  UNLIKE_REPLY_SUCCESS,
  UNLIKE_REPLY_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.comments, action) {
  switch (action.type) {
  case INITIALIZE_COMMENTS:
    return Object.assign({}, state, initialState.comments);

  case LOAD_INITIAL_COMMENTS:
    return Object.assign({}, state, {
      loadedComments: Object.assign({}, state.loadedComments, {
        [action.data.discussionId]: action.data.comments,
      }),
    });

  case LOAD_PREVIOUS_REPLIES:
    return Object.assign({}, state, {
      replyData: state.replyData.concat(action.replies),
    });

  case COMMENTS_REQUEST:
    return Object.assign({}, state, {
      isRequesting: { status: true, discussionId: action.id },
    });

  case COMMENTS_SUCCESS: {
    const commentData = action.data._embedded.comment;
    const id = state.isRequesting.discussionId;

    return Object.assign({}, state, {
      loadedComments: Object.assign({}, state.loadedComments, {
        [id]: sortBy(unionBy(state.loadedComments[id], commentData, 'id'), [
          item => item.posted.date,
        ]),
      }),
      isRequesting: { status: false, discussionId: null },
    });
  }

  case COMMENTS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: { status: false, discussionId: null },
    });

  case CREATE_COMMENT_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: { status: true, discussionId: action.id },
    });

  case CREATE_COMMENT_SUCCESS:
    return createComment(state, action.data);

  case CREATE_COMMENT_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: { status: false, discussionId: null },
    });

  case CREATE_REPLY_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: { status: true, discussionId: null },
    });

  case CREATE_REPLY_SUCCESS:
    return Object.assign(
      {},
      state,
      {
        replyData: [...state.replyData, Object.assign({}, action.data)],
      },
      { isSubmitting: { status: false, discussionId: null } }
    );

  case CREATE_REPLY_FAILURE:
    return Object.assign({}, state, {
      isSubmitting: { status: false, discussionId: null },
    });

  case CREATE_COMMENT_LIKE_REQUEST:
  case UNLIKE_DISCUSSION_COMMENT_REQUEST:
    return Object.assign({}, state, {
      isSubmittingLike: {
        status: true,
        commentId: action.id.commentId,
        discussionId: action.id.discussionId,
      },
    });

  case CREATE_COMMENT_LIKE_SUCCESS: {
    const id = state.isSubmittingLike.discussionId;

    const index = state.loadedComments[id].findIndex(
      comment => comment.id === state.isSubmittingLike.commentId
    );

    return Object.assign({}, state, {
      loadedComments: Object.assign({}, state.loadedComments, {
        [id]: [
          ...state.loadedComments[id].slice(0, index),
          Object.assign({}, state.loadedComments[id][index], {
            liked: true,
            likes: state.loadedComments[id][index].likes + 1,
          }),
          ...state.loadedComments[id].slice(index + 1),
        ],
      }),
      isSubmittingLike: {
        status: false,
        commentId: null,
        discussionId: null,
      },
      error: {},
    });
  }

  case UNLIKE_DISCUSSION_COMMENT_SUCCESS: {
    const id = state.isSubmittingLike.discussionId;

    const index = state.loadedComments[id].findIndex(
      comment => comment.id === state.isSubmittingLike.commentId
    );

    return Object.assign({}, state, {
      loadedComments: Object.assign({}, state.loadedComments, {
        [id]: [
          ...state.loadedComments[id].slice(0, index),
          Object.assign({}, state.loadedComments[id][index], {
            liked: false,
            likes: state.loadedComments[id][index].likes - 1,
          }),
          ...state.loadedComments[id].slice(index + 1),
        ],
      }),
      isSubmittingLike: {
        status: false,
        commentId: null,
        discussionId: null,
      },
      error: {},
    });
  }

  case CREATE_COMMENT_LIKE_FAILURE:
  case UNLIKE_DISCUSSION_COMMENT_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmittingLike: {
        status: false,
        commentId: null,
        discussionId: null,
      },
    });

  case CREATE_REPLY_LIKE_REQUEST:
  case UNLIKE_REPLY_REQUEST:
    return Object.assign({}, state, {
      isSubmittingLike: {
        status: true,
        commentId: action.id,
        discussionId: null,
      },
    });

  case CREATE_REPLY_LIKE_SUCCESS: {
    const index = state.replyData.findIndex(
      reply => reply.id === state.isSubmittingLike.commentId
    );

    return Object.assign({}, state, {
      replyData: [
        ...state.replyData.slice(0, index),
        Object.assign({}, state.replyData[index], {
          liked: true,
          likes: state.replyData[index].likes + 1,
        }),
        ...state.replyData.slice(index + 1),
      ],
      isSubmittingLike: {
        status: false,
        commentId: null,
        discussionId: null,
      },
      error: {},
    });
  }

  case UNLIKE_REPLY_SUCCESS: {
    const index = state.replyData.findIndex(
      reply => reply.id === state.isSubmittingLike.commentId
    );

    return Object.assign({}, state, {
      replyData: [
        ...state.replyData.slice(0, index),
        Object.assign({}, state.replyData[index], {
          liked: false,
          likes: state.replyData[index].likes - 1,
        }),
        ...state.replyData.slice(index + 1),
      ],
      isSubmittingLike: {
        status: false,
        commentId: null,
        discussionId: null,
      },
      error: {},
    });
  }

  case CREATE_REPLY_LIKE_FAILURE:
  case UNLIKE_REPLY_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmittingLike: {
        status: false,
        commentId: null,
        discussionId: null,
      },
    });

  case UPDATE_COMMENT_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, discussionId: action.id },
    });

  case UPDATE_COMMENT_SUCCESS: {
    const id = state.isUpdating.discussionId;

    const loadedCommentIndex = state.loadedComments[id].findIndex(
      comment => comment.id === action.data.id
    );

    return Object.assign({}, state, {
      loadedComments: Object.assign({}, state.loadedComments, {
        [id]: [
          ...state.loadedComments[id].slice(0, loadedCommentIndex),
          Object.assign(
            {},
            state.loadedComments[id][loadedCommentIndex],
            action.data
          ),
          ...state.loadedComments[id].slice(loadedCommentIndex + 1),
        ],
      }),
      isUpdating: { status: false, discussionId: null },
    });
  }

  case UPDATE_COMMENT_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false, discussionId: null },
    });

  case UPDATE_REPLY_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, id: action.id },
    });

  case UPDATE_REPLY_SUCCESS: {
    const index = state.replyData.findIndex(
      item => item.id === state.isUpdating.id
    );

    return Object.assign({}, state, {
      replyData: [
        ...state.replyData.slice(0, index),
        Object.assign({}, state.replyData[index], action.data),
        ...state.replyData.slice(index + 1),
      ],
      isUpdating: { status: false, id: null },
    });
  }

  case UPDATE_REPLY_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false, id: null },
    });

  case DELETE_COMMENT_REQUEST:
    return Object.assign({}, state, {
      isDeleting: {
        status: true,
        id: action.id.commentId,
        discussionId: action.id.discussionId,
      },
    });

  case DELETE_COMMENT_SUCCESS:
    return Object.assign({}, state, {
      loadedComments: Object.assign({}, state.loadedComments, {
        [state.isDeleting.discussionId]: state.loadedComments[
          state.isDeleting.discussionId
        ].filter(comment => comment.id !== state.isDeleting.id),
      }),
      isDeleting: { status: false, id: null, discussionId: null },
    });

  case DELETE_COMMENT_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: { status: false, id: null, discussionId: null },
    });

  case DELETE_REPLY_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: action.id },
    });

  case DELETE_REPLY_SUCCESS:
    return Object.assign({}, state, {
      replyData: [
        ...state.replyData.filter(
          reply => reply.id !== state.isDeleting.id
        ),
      ],
      isDeleting: { status: false, id: null },
    });

  case DELETE_REPLY_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: { status: false, id: null },
    });

  default:
    return state;
  }
}

const createComment = (state, data) => {
  const id = state.isSubmitting.discussionId;

  let creatorObject = {
    [id]: [data],
  };

  if (state.loadedComments[id])
    creatorObject = {
      [id]: [...state.loadedComments[id], Object.assign({}, data)],
    };

  return Object.assign({}, state, {
    loadedComments: Object.assign({}, state.loadedComments, creatorObject),
    isSubmitting: { status: false, discussionId: null },
  });
};
