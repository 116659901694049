import React from 'react';

import { Link } from 'react-router-dom';

import modules from '../../containers/toolbox/global-competence-modules';

const GlobalCompetenceModulesList = () => {
  return(
    <div>
      {modules.map((module, index) => {
        const { id, title, description } = module;

        return (
          <div key={index} className="col-lg-12 module__card__wrapper">
            <Link to={`/toolbox/global-competence-modules/${id}`}>
              <div className="module__card">
                <div className="description">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M8 5v14l11-7z"/>
                  </svg>
                  <h3>{title}</h3>
                </div>
                <p>{description}</p>
              </div>
            </Link>
          </div>
        );
      })}

      <div className="col-lg-12">
        <div className="claim__certificate">
          <Link
            className="btn btn-xlg orange-btn"
            to="/toolbox/global-competence-survey">
            Claim Certificate of Learning
          </Link>
          <p>Issued by the sponsor of this learning opportunity when you've completed your review of all modules.</p>
        </div>
      </div>
    </div>
  );
};

export default GlobalCompetenceModulesList;
