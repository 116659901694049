import PropTypes from 'prop-types';
import React from 'react';
import { pure } from 'recompose';
import ReplyCardHandler from '../../../containers/skill-builders/discussions/ReplyCardHandler';

const ReplyList = ({ replies, dashboard, searchPage }) => {
  return(
    <div>
      {replies && replies.map(reply =>
        (<ReplyCardHandler
          key={reply.id}
          dashboard={dashboard}
          searchPage={searchPage}
          reply={reply}/>))}
    </div>
  );
};

ReplyList.propTypes = {
  replies: PropTypes.array,
  searchPage: PropTypes.bool,
  dashboard: PropTypes.bool
};

export default pure(ReplyList);
