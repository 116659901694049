import PropTypes from 'prop-types';
import React from 'react';
import Parser from 'html-react-parser';

const ResumeClubs = ({ resumeClubs }) => {
  return (
    <div className="resume-section">
      <div className="resume-title">
        {resumeClubs.title !== '' ? resumeClubs.title : 'Extracurricular activities & clubs'}
      </div>
      {resumeClubs.clubsArray.map((club) => {
        return(
          <div key={club.id} style={{marginBottom: 10}}>
            <span>{club.name}</span>
            {Parser(club.description)}
          </div>
        );
      })}
    </div>
  );
};

ResumeClubs.propTypes = {
  resumeClubs: PropTypes.object.isRequired
};

export default ResumeClubs;
