import { CALL_API } from '../../middlewares/api';

import {
  MESSAGE_REPLY_REQUEST,
  MESSAGE_REPLY_SUCCESS,
  MESSAGE_REPLY_FAILURE,
  REPLY_TO_A_THREAD_REQUEST,
  REPLY_TO_A_THREAD_SUCCESS,
  REPLY_TO_A_THREAD_FAILURE,
  INITIALIZE_MESSAGE_REPLIES_STORE,
  GET_UNIT_THREAD_REQUEST,
  GET_UNIT_THREAD_SUCCESS,
  GET_UNIT_THREAD_FAILURE,
} from '../../constants';

export const initializeMessageRepliesStore = () => {
  return dispatch => dispatch({ type: INITIALIZE_MESSAGE_REPLIES_STORE });
};

export const fetchMessageReplies = (threadId, pageNo = 1) => {
  const endpoint = `messages/replies?thread=${threadId}&page=${pageNo}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        MESSAGE_REPLY_REQUEST,
        MESSAGE_REPLY_SUCCESS,
        MESSAGE_REPLY_FAILURE,
      ],
    },
  };
};

export const replyToAThread = (data) => {
  const endpoint = 'messages/replies';

  return {
    [CALL_API]: {
      data,
      endpoint,
      id: data.thread,
      method: 'post',
      authenticated: true,
      types: [
        REPLY_TO_A_THREAD_REQUEST,
        REPLY_TO_A_THREAD_SUCCESS,
        REPLY_TO_A_THREAD_FAILURE,
      ],
    },
  };
};

export const getUnitThreadDetails = (threadId) => {
  const endpoint = `message/threads/${threadId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        GET_UNIT_THREAD_REQUEST,
        GET_UNIT_THREAD_SUCCESS,
        GET_UNIT_THREAD_FAILURE,
      ],
    },
  };
};
