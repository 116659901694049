import { CALL_API } from '../../middlewares/api';

import {
  RESET_DASHBOARD_WALL_COMMENTS,
  WALL_POST_COMMENTS_REQUEST,
  LOAD_INITIAL_WALL_COMMENTS,
  LOAD_WALL_COMMENT_REPLIES,
  WALL_POST_COMMENTS_SUCCESS,
  WALL_POST_COMMENTS_FAILURE,
  CREATE_WALL_POST_COMMENT_REQUEST,
  CREATE_WALL_POST_COMMENT_SUCCESS,
  CREATE_WALL_POST_COMMENT_FAILURE,
  CREATE_WALL_POST_COMMENT_LIKE_REQUEST,
  CREATE_WALL_POST_COMMENT_LIKE_SUCCESS,
  CREATE_WALL_POST_COMMENT_LIKE_FAILURE,
  UPDATE_WALL_POST_COMMENT_REQUEST,
  UPDATE_WALL_POST_COMMENT_SUCCESS,
  UPDATE_WALL_POST_COMMENT_FAILURE,
  DELETE_WALL_POST_COMMENT_REQUEST,
  DELETE_WALL_POST_COMMENT_SUCCESS,
  DELETE_WALL_POST_COMMENT_FAILURE,
  CREATE_WALL_REPLY_REQUEST,
  CREATE_WALL_REPLY_SUCCESS,
  CREATE_WALL_REPLY_FAILURE,
  CREATE_WALL_POST_REPLY_LIKE_SUCCESS,
  CREATE_WALL_POST_REPLY_LIKE_FAILURE,
  CREATE_WALL_POST_REPLY_LIKE_REQUEST,
  DELETE_WALL_POST_REPLY_REQUEST,
  DELETE_WALL_POST_REPLY_SUCCESS,
  DELETE_WALL_POST_REPLY_FAILURE,
  UPDATE_WALL_POST_REPLY_REQUEST,
  UPDATE_WALL_POST_REPLY_SUCCESS,
  UPDATE_WALL_POST_REPLY_FAILURE,
  UNLIKE_WALL_POST_COMMENT_REQUEST,
  UNLIKE_WALL_POST_COMMENT_SUCCESS,
  UNLIKE_WALL_POST_COMMENT_FAILURE,
  UNLIKE_WALL_POST_REPLY_REQUEST,
  UNLIKE_WALL_POST_REPLY_SUCCESS,
  UNLIKE_WALL_POST_REPLY_FAILURE,
} from '../../constants';

export const resetWallComments = () => {
  return { type: RESET_DASHBOARD_WALL_COMMENTS };
};

export const loadInitialWallComments = (data) => {
  return dispatch => dispatch({ type: LOAD_INITIAL_WALL_COMMENTS, data });
};

export const loadCommentReplies = (replies) => {
  return dispatch => dispatch({ type: LOAD_WALL_COMMENT_REPLIES, replies });
};

export const unitWallPostCommentsRequest = (wallPostId, pageNo) => {
  const endpoint = `discussion/wall_comment?wallpost=${wallPostId}&page=${pageNo}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      id: wallPostId,
      types: [
        WALL_POST_COMMENTS_REQUEST,
        WALL_POST_COMMENTS_SUCCESS,
        WALL_POST_COMMENTS_FAILURE,
      ],
    },
  };
};

export const saveWallPostComment = (data, wallPostId) => {
  const endpoint = 'discussion/wall_comment';

  return {
    [CALL_API]: {
      data,
      endpoint,
      id: wallPostId,
      method: 'post',
      authenticated: true,
      types: [
        CREATE_WALL_POST_COMMENT_REQUEST,
        CREATE_WALL_POST_COMMENT_SUCCESS,
        CREATE_WALL_POST_COMMENT_FAILURE,
      ],
    },
  };
};

export const saveWallPostCommentReplies = (data) => {
  const endpoint = 'discussion/wall_comment';
  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        CREATE_WALL_REPLY_REQUEST,
        CREATE_WALL_REPLY_SUCCESS,
        CREATE_WALL_REPLY_FAILURE,
      ],
    },
  };
};

export const saveWallPostCommentLike = (data) => {
  const endpoint = 'discussion/wall_comment_like';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      id: {
        wallPostId: data.wallPostId,
        index: data.commentIndex,
      },
      types: [
        CREATE_WALL_POST_COMMENT_LIKE_REQUEST,
        CREATE_WALL_POST_COMMENT_LIKE_SUCCESS,
        CREATE_WALL_POST_COMMENT_LIKE_FAILURE,
      ],
    },
  };
};

export const unlikeWallPostComment = (data) => {
  const { userId, comment, wallPostId, commentIndex } = data;
  const endpoint = `discussion/wall_comment_like/${comment}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'delete',
      authenticated: true,
      id: {
        wallPostId,
        index: commentIndex,
      },
      userId,
      types: [
        UNLIKE_WALL_POST_COMMENT_REQUEST,
        UNLIKE_WALL_POST_COMMENT_SUCCESS,
        UNLIKE_WALL_POST_COMMENT_FAILURE,
      ],
    },
  };
};

export const updateWallPostComment = (data, commentId, wallPostId) => {
  const endpoint = `discussion/wall_comment/${commentId}`;

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'patch',
      id: wallPostId,
      authenticated: true,
      types: [
        UPDATE_WALL_POST_COMMENT_REQUEST,
        UPDATE_WALL_POST_COMMENT_SUCCESS,
        UPDATE_WALL_POST_COMMENT_FAILURE,
      ],
    },
  };
};

export const deleteWallPostComment = (wallPostCommentId, wallPostId) => {
  const endpoint = `discussion/wall_comment/${wallPostCommentId}`;

  return {
    [CALL_API]: {
      endpoint,
      id: {
        commentId: wallPostCommentId,
        wallPostId: wallPostId,
      },
      method: 'delete',
      authenticated: true,
      types: [
        DELETE_WALL_POST_COMMENT_REQUEST,
        DELETE_WALL_POST_COMMENT_SUCCESS,
        DELETE_WALL_POST_COMMENT_FAILURE,
      ],
    },
  };
};

export const saveWallPostReplyLike = (data) => {
  const endpoint = 'discussion/wall_comment_like';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      id: data.comment,
      types: [
        CREATE_WALL_POST_REPLY_LIKE_REQUEST,
        CREATE_WALL_POST_REPLY_LIKE_SUCCESS,
        CREATE_WALL_POST_REPLY_LIKE_FAILURE,
      ],
    },
  };
};

export const unlikeWallPostReply = (data) => {
  const { userId, comment: id } = data;

  const endpoint = `discussion/wall_comment_like/${id}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'delete',
      authenticated: true,
      id,
      userId,
      types: [
        UNLIKE_WALL_POST_REPLY_REQUEST,
        UNLIKE_WALL_POST_REPLY_SUCCESS,
        UNLIKE_WALL_POST_REPLY_FAILURE,
      ],
    },
  };
};

export const updateWallPostReply = (data, id) => {
  const endpoint = `discussion/wall_comment/${id}`;

  return {
    [CALL_API]: {
      id: id,
      data,
      endpoint,
      method: 'put',
      authenticated: true,
      types: [
        UPDATE_WALL_POST_REPLY_REQUEST,
        UPDATE_WALL_POST_REPLY_SUCCESS,
        UPDATE_WALL_POST_REPLY_FAILURE,
      ],
    },
  };
};

export const deleteWallPostReply = (replyId) => {
  const endpoint = `discussion/wall_comment/${replyId}`;

  return {
    [CALL_API]: {
      endpoint,
      id: replyId,
      method: 'delete',
      authenticated: true,
      types: [
        DELETE_WALL_POST_REPLY_REQUEST,
        DELETE_WALL_POST_REPLY_SUCCESS,
        DELETE_WALL_POST_REPLY_FAILURE,
      ],
    },
  };
};
