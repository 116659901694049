import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { parseJSON } from '../../utils';
import isUndefined from 'lodash/isUndefined';

import PhotoSwipe from 'photoswipe';
import { Tooltip } from 'reactstrap';
import Slider from 'react-slick';
import PhotoswipeUIDefault from 'photoswipe/dist/photoswipe-ui-default';
import ReadSpeakerButton from '../../components/common/ReadSpeakerButton';
import AdminSectionHeader from '../../components/skill-builders/admin/AdminSectionHeader';
import Gallery from '../../components/skill-builders/essentials/Gallery';
import LazyLoadImage from '../common/lazy-load-image/LazyLoadImage';

const settings = {
  dots: false,
  infinite: false,
  arrows:true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  adaptiveHeight: true,
  swipe:true,
  responsive: [
    { breakpoint: 560,
      settings: {
        slidesToShow: 1, slidesToScroll: 1, arrows:false, dots:true
      }
    }
  ]
};

class ImageSection extends Component {
  static propTypes = {
    hover: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    section: PropTypes.object.isRequired,
    onEditClick: PropTypes.func.isRequired,
    drag: PropTypes.func.isRequired,
    drop: PropTypes.func.isRequired,
    preview: PropTypes.func.isRequired
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      activeSlideIndex: 0,
      tooltipOpen: false
    };

    this.openPhotoSwipe = this.openPhotoSwipe.bind(this);
    this.changeActiveSlideIndex = this.changeActiveSlideIndex.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.beforeChange = this.beforeChange.bind(this);
  }

  getImageSize(src) {
    let img = new Image();
    img.src = src;

    return {
      w: img.width,
      h: img.height
    };
  }

  toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }


  getImages() {
    const { section } = this.props;
    let data = parseJSON(section.content);
    const { images } = data;

    return images.map((image) => {
      const size = this.getImageSize(image.url);
      const { url: src, description: title } = image;

      return Object.assign({}, image, { src, title, ...size });
    });
  }

  openPhotoSwipe(event) {
    event.preventDefault();

    const {
      activeSlideIndex
    } = this.state;

    const items = this.getImages();
    const options = {
      index: activeSlideIndex,
      shareEl: false,
      preload: [1, 1],
      showAnimationDuration: 0,
      hideAnimationDuration: 0
    };

    const pswpElement = document.querySelectorAll('.pswp')[0];

    const gallery = new PhotoSwipe( pswpElement, PhotoswipeUIDefault, items, options );

    gallery.init();
  }

  changeActiveSlideIndex(event) {
    const sourceId = event.target.id;

    if (sourceId === 'prev-img')
      return this.setState({
        activeSlideIndex: this.state.activeSlideIndex - 1
      });

    this.setState({
      activeSlideIndex: this.state.activeSlideIndex + 1
    });
  }

  beforeChange(oldIndex, newIndex){
    this.setState({
      activeSlideIndex: newIndex
    });
  }

  render() {
    const { drag, drop, preview, section, hover, isAdmin, onEditClick } = this.props;

    const {
      activeSlideIndex,
      tooltipOpen
    } = this.state;

    let data = parseJSON(section.content);
    const { title, images } = data;
    const imageSectionId = `dropdown-section-${section.id}`;
    const url = window.location.href;
    const encodedUrl = encodeURIComponent(url);
    const speakerId = `read-speaker-${imageSectionId}`;

    return (
      <section
        ref={preview}
        className="essential-image"
        id={`image-section-${section.id}`}>

        {isAdmin &&
          <div style={{ marginTop: -20 }}>
            <AdminSectionHeader
              hover={hover}
              drag={drag}
              drop={drop}
              title="Image"
              sectionId={section.id}
              onEditClick={onEditClick}
              componentName={`image-section-${section.id}`}/>
          </div>}

        <div className="section-icon">
          <svg viewBox="0 0 120 100">
            <path d="M38,2 L82,2 A12,12 0 0,1 94,10 L112,44 A12,12 0 0,1 112,56 L94,90 A12,12 0 0,1 82,98 L38,98 A12,12 0 0,1 26,90 L8,56 A12,12 0 0,1 8,44 L26,10 A12,12 0 0,1 38,2" />
          </svg>
          <div className="icon"><div className="ico-image"/></div>
        </div>

        <ReadSpeakerButton sectionClass={imageSectionId} encodedUrl={encodedUrl}/>

        <div id={speakerId}>
          {title && <h4>{title}</h4>}

          <Slider
            {...settings}
            beforeChange={this.beforeChange}>
            {! isUndefined(images) &&
              images
                .map((image, index)=>
                  (<div key={index} className="image-slider">
                    <LazyLoadImage
                      imageClassName="img-fluid"
                      srcLoaded={image.url}
                      alt={image.description}/>
                    <div
                      id={`image-viewer-tooltip-${image.id}`}
                      className="image-section-icon-open"
                      onClick={this.openPhotoSwipe}>
                      <i className="fa fa-expand"/>
                    </div>
                    <Tooltip
                      placement="top"
                      isOpen={tooltipOpen && index === activeSlideIndex}
                      target={`image-viewer-tooltip-${image.id}`}
                      toggle={this.toggleTooltip}>
                      <div className="inside-tooltip">
                        Enlarge
                      </div>
                    </Tooltip>
                  </div>))}
          </Slider>

          {! isUndefined(images) && <Gallery/>}

          <div className="slide-indicator">
            {activeSlideIndex + 1} of {images.length}
          </div>
        </div>
        <div id={`list-${speakerId}`} style={{display: 'none'}}>
          <h4 className={imageSectionId}>{title}</h4>
          <div>
            {images.map((item,index)=>{
              const { title, description } = item;
              return(
                <div key={index} className={imageSectionId}>
                  <b>Image title : {title}</b>
                  <p><strong>Image description </strong> : {description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default ImageSection;
