import React from 'react';

import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const CareerSiteOverviewModal = ({ isOpen, toggleModal }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggleModal} backdrop="static">
      <div className="modal-content">
        <ModalHeader toggle={toggleModal}>
          Career Site Overview
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/HE8wjKNd1Qg?si=H9_7PVNkeNm6SE6B"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen/>

              <div className="col">
                <p style={{textAlign: 'center', marginTop: '1rem'}}>
                  Learn more about the Career Site in our <a href="https://help.careerprepped.com/help/careerprepped-career-site" target="_blank">help center</a>.
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-primary"
            onClick={toggleModal}>
            Close
          </button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

CareerSiteOverviewModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func
};

export default CareerSiteOverviewModal;
