import React from 'react';
import Loading from './Loading';

const TableLoading = () => {
  return (
    <div className="loading">
      <Loading loaderStyles={{
        position: 'absolute',
        display: 'block',
        textAlign: 'center',
        width: '100%',
        top: '50%',
        left: 0,
        fontSize: 15,
        color: 'rgba(0, 0, 0, 0.6)',
        transform: 'translateY(-52%)',
        transition: 'all .3s cubic-bezier(0.250, 0.460, 0.450, 0.940)'
      }}/>
    </div>
  );
};

export default TableLoading;
