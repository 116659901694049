import React from 'react';

import moment from 'moment';

import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';

import PortfolioPreview from '../../portfolio/PortfolioPreview';

const CertificationEvidenceDetails = ({ isOpen, evidence, closeModal, onChangeRoute }) => {
  const { user, url, title, uploaded, description, mimetype } = evidence;

  const fullYear = new Date().getFullYear();
  const savedDate = uploaded && moment(uploaded.date).format('MMM DD, YYYY');

  return (
    <Modal
      size="lg"
      className="portfolio-modal"
      isOpen={isOpen}
      backdrop="static"
      toggle={closeModal(null)}>
      <div className="portfolio-wrap modal-content">

        <div className="modal-header">
          <div className="media mb-1">
            <div className="media-left media-middle">
              <span title={`Link to ${user.name}’s Career Site`}>
                <a className="clickable" onClick={onChangeRoute(`/cp/${user.vanity}`)}>
                  {
                    user.avatar && user.avatar !== ''?
                      <img
                        className="media-object header-image medium"
                        src={user.avatar}
                        alt="User avatar"/>:
                      <span
                        className="media-object header-image medium initial-avatar">
                        {user.name && user.name.slice(0,1)}
                      </span>
                  }
                </a>
              </span>
            </div>
            <div className="media-body ml-2">
              <h5 className="modal-title">{title}</h5>
              <p className="modal-author">by <a className="clickable orange link" onClick={onChangeRoute(`/cp/${user.vanity}`)} title={`Link to ${user.name}’s Career Site`}><strong>{user.name}</strong></a> on <strong>{savedDate}</strong>.</p>
              <div className="modal-description mt-2">
                <p>{description}</p>
              </div>
            </div>
          </div>
          <button type="button" className="close" onClick={closeModal(null)} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body portfolio-body">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <PortfolioPreview
                  url={url}
                  mimetype={mimetype}/>
                <div className="report-abuse">
                  © {fullYear} • All content within this project is strictly the property of {user.name} and is not for public use without permission.
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Modal>
  );
};

CertificationEvidenceDetails.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  evidence: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
  onChangeRoute: PropTypes.func.isRequired
};

export default CertificationEvidenceDetails;
