import PropTypes from 'prop-types';
import React from 'react';
import { uniqueId } from '../../../utils';
import PublicationCardHandler from '../../../containers/profile/sections/PublicationCardHandler';

const PublicationList = ({publications, isPublicProfile, onEdit}) => {

  const sortPublication = (publications) => {
    const newPublicationList = [].concat(publications);
    return newPublicationList.sort(function(a, b){
      return new Date(b.pubdate.date) - new Date(a.pubdate.date);
    });
  };

  const publicationSortedList = sortPublication(publications);

  if(publications.length === 0){
    return(
      <div className="empty-section-message">
        <p>Show off any published works you may have such as articles, books, research papers, etc.</p>
      </div>
    );
  }

  return (
    <div>
      {publicationSortedList.map(publication =>
        (<PublicationCardHandler
          key={uniqueId()}
          publication={publication}
          onEdit={onEdit}
          isPublicProfile={isPublicProfile}/>))}
    </div>
  );
};

PublicationList.propTypes = {
  publications: PropTypes.array.isRequired,
  isPublicProfile: PropTypes.bool.isRequired,
  onEdit: PropTypes.func
};

export default PublicationList;
