import PropTypes from 'prop-types';
import React from 'react';

const employmentStatuses = [
  'I’m working and not seeking a job',
  'I’m working but open to new opportunities',
  'I’m not working and actively seeking a job',
  'I’m not working and not seeking a job at this time'
];

const employmentTypes = [
  'Full-Time',
  'Paid Internship',
  'Temporary Contract',
  'Part-Time',
  'Unpaid Internship',
  'Self-Employed',
  'Not Working'
];

const SituationCard = ({profile}) => {
  return (
    <div className="profile-item__card">
      <div className="subhead">
        <strong>Your Employment Status:</strong><br/>
        <span>{employmentStatuses[profile.employmentstatus]}</span>
      </div>

      <div className="subhead">
        <strong>Employment Type:</strong><br/>
        <span>{employmentTypes[profile.employmenttype]}</span>
      </div>
    </div>
  );
};

SituationCard.propTypes = {
  profile: PropTypes.object.isRequired
};

export default SituationCard;
