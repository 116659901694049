import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import TextInput from '../common/TextInput';

const cpLogo = require('../../assets/images/CP_logo_no-mark.png');

const InvitationSignupForm = ({
  onChange,
  formData,
  errors,
  onSubmit,
  isSubmitting,
  data,
}) => {
  const success = !isSubmitting && data.status === 200;

  return(
    <div className="modal-body homepage-modal-body invitation-login">
      <h4 className="modal-title" id="myModalLabel" style={{marginBottom:'25px'}}>Join &nbsp;&nbsp;
        <img src={cpLogo} alt="Career Prepped" style={{height: 26}}/>
      </h4>
      {success &&
        <div>
          <p className="success-message">Joined Careerprepped successfully.</p>
          <p>Please login to explore.</p>
        </div>}

      {!success &&
        <div className="login-form">
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-lg-6">
                <TextInput
                  srOnly
                  autoFocus
                  label="First Name"
                  name="firstname"
                  value={formData.firstname}
                  onChange={onChange}
                  placeholder="First Name"
                  error={errors.firstname}/>
              </div>

              <div className="form-group col-lg-6">
                <TextInput
                  srOnly
                  label="Last Name"
                  name="lastname"
                  value={formData.lastname}
                  onChange={onChange}
                  placeholder="Last Name"
                  error={errors.lastname}/>
              </div>
            </div>
            <div className="form-group">
              <TextInput
                srOnly
                type="password"
                label="Password"
                name="password"
                value={formData.password}
                onChange={onChange}
                placeholder="Password"
                error={errors.password}/>
            </div>
            <div className="form-group">
              <TextInput
                srOnly
                type="password"
                label="Confirm Password"
                name="rePassword"
                value={formData.rePassword}
                onChange={onChange}
                placeholder="Confirm Password"
                error={errors.rePassword}/>
            </div>
            <input
              type="submit"
              disabled={isSubmitting}
              value={isSubmitting ? 'Submitting...': 'Submit'}
              className="btn btn-primary w-100"/>
            <p className="terms-text">By registering you agree to our <Link to="/terms" target="_blank">Terms</Link></p>
          </form>
        </div>}
    </div>
  );
};

InvitationSignupForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired
};

export default InvitationSignupForm;
