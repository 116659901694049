import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PreviewResumeSectionsContainer from './PreviewResumeSectionsContainer';

const PreviewResumeModal = ({ isOpen, onCancel, resume, resumeOrder }) => {
  return (
    <Modal
      backdrop="static"
      className="modal-lg"
      isOpen={isOpen}
      toggle={onCancel}>
      <ModalHeader
        className="no-border"
        toggle={onCancel}>
        <span style={{paddingLeft: 10}}>
          Preview Resumé
        </span>
      </ModalHeader>
      <ModalBody className="preview-resume-modal-body">
        <div className="content targeted-resume create">
          <div className="row">
            <div className="col-lg-12">
              <div className="create-wrapper">
                <PreviewResumeSectionsContainer
                  resume={resume}
                  resumeOrder={resumeOrder}/>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

PreviewResumeModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  resume: PropTypes.object.isRequired,
  resumeOrder: PropTypes.array.isRequired
};

export default PreviewResumeModal;
