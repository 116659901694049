import React from 'react';
import PropTypes from 'prop-types';

import MainMenu from '../../components/navbar/MainMenu';

const MainNavigation = ({ profile }) => {
  return (
    <div>
      <MainMenu profile={profile} />
    </div>
  );
};

MainNavigation.propTypes = {
  profile: PropTypes.object
};

export default MainNavigation;
