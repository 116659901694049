import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';

import ListGroup from './ListGroup';
import Loading from '../../common/Loading';

const styles = {
  sidePanel: {
    borderBottom: 'none'
  },
  sidePanelBody: {
    paddingBottom: 0
  },
  inputGroup: {
    marginTop: 20,
    width: '100%'
  }
};

const SidePanel = ({
  skillBuilders,
  isRequesting,
  onSearch
}) => {
  return (
    <div className="card pt-3 pl-3 pr-3 pb-0 mb-4" style={styles.sidePanel}>
      <div className="card-block" style={styles.sidePanelBody}>
        <Link to={'/admin/skill-builders/unit'} className="btn btn-success w-100">
          <i className="fa fa-plus mr-2"/>Add new essential
        </Link>
        <div className="input-group" style={styles.inputGroup}>
          <input
            type="text"
            onChange={onSearch}
            className="form-control"
            placeholder="Search..." />
        </div>

        {(isRequesting) ? <Loading/> : <ListGroup units={skillBuilders}/>}
      </div>
    </div>
  );
};

SidePanel.propTypes = {
  onSearch: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  skillBuilders: PropTypes.array.isRequired,
};

export default SidePanel;
