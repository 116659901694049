import PropTypes from 'prop-types';
import React from 'react';

import { Modal } from 'reactstrap';
import { Link } from 'react-router-dom';
import TextInput from '../common/TextInput';
import Button from '../common/Button';

const EndorseModal = ({
  isAuthenticated,
  isSubmitting,
  badge,
  unit,
  issued,
  user,
  onCancelEndorse,
  errors,
  skillsObserved,
  endorserData,
  onChangeEndorserData,
  onToggleSkillsObserved,
  onSubmitEndorsementConfirmation,
  hasCompletedEndorsement
}) => {

  const { name, email, position, organization } = endorserData;
  const buttonText = isSubmitting ? 'Endorsing...' : 'Endorse';
  const modalHeader = hasCompletedEndorsement ?
    <h5>Badge successfully endorsed</h5> :
    <h5 className="modal-title" id="myModalLabel">Endorse {user.name} for <Link to={`/skill-badges/badge-details/${issued.id}`} target="_blank">{badge.name}</Link></h5>;

  return (
    <Modal
      className="modal-margin-top"
      isOpen={Object.keys(unit).length>0}
      toggle={onCancelEndorse}
      size={'md'}>
      <div className="sbmodals" id="skillBadge">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" onClick={onCancelEndorse}>
              <span aria-hidden="true">&times;</span>
            </button>
            {modalHeader}
          </div>
          <div className="modal-body">
            {
              !hasCompletedEndorsement &&
                <div>
                  <p>Please provide your name, position, and organization.</p>
                  <div className="form-group">
                    <TextInput
                      label="Name"
                      type="text"
                      name="name"
                      error={errors.name}
                      value={name}
                      className="form-control"
                      onChange={onChangeEndorserData}
                      placeholder="Name"/>
                  </div>
                  <div className="form-group">
                    <TextInput
                      label="Email"
                      type="text"
                      name="email"
                      error={errors.email}
                      value={email}
                      className="form-control"
                      onChange={onChangeEndorserData}
                      placeholder="Email"/>
                  </div>
                  <div className="form-group">
                    <TextInput
                      label="Position"
                      type="text"
                      name="position"
                      error={errors.position}
                      value={position}
                      className="form-control"
                      onChange={onChangeEndorserData}
                      placeholder="Position"/>
                  </div>
                  <div className="form-group">
                    <TextInput
                      label="Organization"
                      type="text"
                      name="organization"
                      error={errors.organization}
                      value={organization}
                      className="form-control"
                      onChange={onChangeEndorserData}
                      placeholder="Organization"/>
                  </div>

                  <div className="form-inline agreement">
                    <div className="form-group">
                      <label htmlFor="i-accept">
                        <input
                          type="checkbox"
                          name="verified"
                          checked={skillsObserved}
                          onChange={onToggleSkillsObserved}
                          className="form-control"/>{`I've observed ${user.name}'s claimed abilities in a training activity, project, or work setting.
                          Based on my observation of ${user.name} and review of the supplied evidence, I confidently confirm {user.name} has this skill.`}
                      </label>
                    </div>
                  </div>

                  <Button
                    disabled={!skillsObserved || isSubmitting}
                    type="button"
                    onClick={onSubmitEndorsementConfirmation}
                    buttonClass="btn btn-primary"
                    buttonText={buttonText}/>

                </div>
            }

            {
              hasCompletedEndorsement &&
                <div className="container">
                  <div className="successfully-endorsed">
                    <h5 className="success-request">
                      Thank you for endorsing {user.name}'s Skill!
                    </h5>
                    {
                      !isAuthenticated &&
                        <span>
                          <h6>Have you explored how CareerPrepped can help you or your organization?</h6>
                          <Link to="/">Learn More</Link>
                        </span>
                    }
                  </div>
                </div>
            }
          </div>
        </div>
      </div>
    </Modal>
  );
};

EndorseModal.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  hasCompletedEndorsement: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  unit: PropTypes.object.isRequired,
  onCancelEndorse: PropTypes.func.isRequired,
  errors: PropTypes.object,
  issued: PropTypes.object,
  badge: PropTypes.object.isRequired,
  skillsObserved: PropTypes.bool.isRequired,
  onToggleSkillsObserved: PropTypes.func.isRequired,
  endorserData: PropTypes.object.isRequired,
  onChangeEndorserData: PropTypes.func.isRequired,
  onSubmitEndorsementConfirmation: PropTypes.func.isRequired
};

export default EndorseModal;
