import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { MAX_FILE_NO } from '../../utilConstants';

const SkillOptionItem = ({ skill, selectedSkills, toggleSkillCheckbox }) => {
  const hasEvidence = skill.badgesCount > 0 || skill.filesCount > 0;
  const skillClass = classNames('skill-pill mr-1 mb-1 portfolio-skills-container', {
    'linked': hasEvidence
  });

  return (
    <div className={skillClass}>
      <input
        className="link-cb"
        type="checkbox"
        checked={selectedSkills.includes(skill.id)}
        disabled={skill.files?.length >= MAX_FILE_NO}
        onChange={toggleSkillCheckbox(skill.id)}/>
      <span
        className={classNames('portfolio-skills', { 'linked': hasEvidence })}
        onClick={
          skill.files?.length >= MAX_FILE_NO ?
            undefined : toggleSkillCheckbox(skill.id)
        }>
        {skill.name}
      </span>
    </div>
  );
};

SkillOptionItem.propTypes = {
  profile: PropTypes.object,
  skill: PropTypes.object.isRequired,
  selectedSkills: PropTypes.array.isRequired,
  toggleSkillCheckbox: PropTypes.func.isRequired
};

export default SkillOptionItem;
