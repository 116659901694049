import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  formControl: {
    height: 45,
    borderRadius: 0
  }
};

const CheckoutForm = ({ onSubmit, cardType, cardNiceType, formClassNames, headerClassNames }) => {
  return(
    <div className="row">
      <div className={headerClassNames}>
        <h2 style={{marginBottom: 20, textAlign: 'center'}}>Payment Method</h2>
      </div>
      <form id="checkout-form" className={formClassNames}>
        <div className="row">
          <div className="form-group col-xs-12">
            <label className="control-label">Card Number</label>
            <div className="form-control" style={styles.formControl} id="card-number"/>
            <div id="card-image" className={cardType}/>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-sm-4">
            <label className="control-label">Valid Thru</label>
            <div className="form-control" style={styles.formControl} id="expiration-date"/>
          </div>

          <div className="form-group col-sm-4">
            <label className="control-label">CVV</label>
            <div className="form-control" style={styles.formControl} id="cvv"/>
          </div>

          <div className="form-group col-sm-4">
            <label className="control-label">Zipcode</label>
            <div className="form-control" style={styles.formControl} id="postal-code"/>
          </div>
        </div>

        <button className="btn btn-success btn-lg align-middle" onClick={onSubmit}>
          Pay with <span id="card-type">{cardNiceType}</span>
        </button>
      </form>
    </div>
  );
};

CheckoutForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  cardType: PropTypes.string.isRequired,
  cardNiceType: PropTypes.string.isRequired,
  formClassNames: PropTypes.string.isRequired,
  headerClassNames: PropTypes.string.isRequired
};

export default CheckoutForm;
