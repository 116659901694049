import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {Input, Label, FormGroup } from 'reactstrap';
import * as badgeActions from '../../../redux/actions/badges';
import Loading from '../../../components/common/Loading';
import SkillBadgesSection from '../../../components/profile/sections/SkillBadgesSection';

const sbIcon = require('../../../assets/images/logo-icon.png');

class SkillBadgeSectionContainer extends Component {
  static propTypes = {
    issuedBadges: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    isPublicProfile: PropTypes.bool,
    isVisiting: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    profile: PropTypes.object,
    setShowHiddenSkillBadges: PropTypes.func,
    showHiddenSkillBadges: PropTypes.bool,
    displayHiddenBadges: PropTypes.bool,
  };

  componentWillMount() {
    const { actions, profile } = this.props;
    actions.getMyIssuedBadges(profile.id);
  }

  render() {
    const { issuedBadges, isVisiting, profile, isPublicProfile, setShowHiddenSkillBadges, showHiddenSkillBadges = false, displayHiddenBadges } = this.props;
    const { isRequesting, data } = issuedBadges;

    return (
      <div className="profile-item badges">
        <div className="head">
          <div className="d-flex align-items-center">
            <img className="mr-2" src={sbIcon} alt="" width="32" />
            Skill Badges
          </div>
          {(!isPublicProfile || displayHiddenBadges) &&
            <FormGroup check className="pl-0 custom-checkbox-wrapper">
              <Label check className="container pl-4 m-0">
                Show Hidden Badges
                <Input type="checkbox" checked={showHiddenSkillBadges} className="mr-1" onChange={setShowHiddenSkillBadges}/>
                <span className="checkmark" />
              </Label>
            </FormGroup>
          }
        </div>
        {isRequesting && <Loading />}
        {!isRequesting && (
          <SkillBadgesSection
            profile={profile}
            isVisiting={isVisiting || isPublicProfile}
            isPublicProfile={isPublicProfile}
            badges={data}
            showHidden={showHiddenSkillBadges}
            displayHiddenBadges={displayHiddenBadges} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { badges } = state;

  return {
    issuedBadges: badges.issuedBadges,
    isAuthenticated: state.auth.isAuthenticated,
    currentUser: state.auth.data.currentUser
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, badgeActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillBadgeSectionContainer);
