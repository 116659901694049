import uniqBy from 'lodash/uniqBy';

import initialState from './initialState';

import {
  MESSAGE_REPLY_REQUEST,
  MESSAGE_REPLY_SUCCESS,
  MESSAGE_REPLY_FAILURE,
  REPLY_TO_A_THREAD_REQUEST,
  REPLY_TO_A_THREAD_SUCCESS,
  REPLY_TO_A_THREAD_FAILURE,
  INITIALIZE_MESSAGE_REPLIES_STORE,
  GET_UNIT_THREAD_REQUEST,
  GET_UNIT_THREAD_SUCCESS,
  GET_UNIT_THREAD_FAILURE,
  SET_A_THREAD_AS_READ,
} from '../../constants';

export default function reducer(state = initialState.messageReplies, action) {
  switch (action.type) {
  case INITIALIZE_MESSAGE_REPLIES_STORE:
    return Object.assign({}, state, initialState.messageReplies);

  case MESSAGE_REPLY_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case MESSAGE_REPLY_SUCCESS:
    return Object.assign({}, state, {
      replies: uniqBy(
        state.replies.concat(action.data._embedded.replies),
        item => item.id
      ),
      isRequesting: false,
      paginationData: {
        page: action.data.page,
        page_count: action.data.page_count,
      },
    });

  case MESSAGE_REPLY_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case SET_A_THREAD_AS_READ:
    return Object.assign({}, state, {
      thread: Object.assign({}, state.thread, {
        thread: Object.assign({}, state.thread.thread, {
          readAt: {
            date: new Date(),
            timezone_type: 3,
            timezone: 'UTC',
          },
        }),
      }),
    });
  case REPLY_TO_A_THREAD_REQUEST:
    return Object.assign({}, state, { isSubmitting: true });

  case REPLY_TO_A_THREAD_SUCCESS: {
    return Object.assign({}, state, {
      error: {},
      isSubmitting: false,
      replies: [action.data, ...state.replies],
      thread: Object.assign({}, state.thread, {
        thread: Object.assign({}, state.thread.thread, {
          user: action.data.user,
          lastBody: action.data.body,
        }),
      }),
    });
  }

  case REPLY_TO_A_THREAD_FAILURE:
    return Object.assign({}, state, { isSubmitting: false });

  case GET_UNIT_THREAD_REQUEST:
    return Object.assign({}, state, {
      thread: Object.assign({}, state.thread, {
        isRequestingThread: true,
      }),
    });

  case GET_UNIT_THREAD_SUCCESS:
    return Object.assign({}, state, {
      thread: Object.assign({}, state.thread, {
        isRequestingThread: false,
        thread: action.data,
      }),
    });

  case GET_UNIT_THREAD_FAILURE:
    return Object.assign({}, state, {
      thread: Object.assign({}, state.thread, {
        isRequestingThread: false,
        error: action.error,
      }),
    });

  default:
    return state;
  }
}
