import { CALL_API } from '../../middlewares/api';

import {
  CREATE_JOB_MATCH_SETTINGS_REQUEST,
  CREATE_JOB_MATCH_SETTINGS_SUCCESS,
  CREATE_JOB_MATCH_SETTINGS_FAILURE,
  UPDATE_JOB_MATCH_SETTINGS_REQUEST,
  UPDATE_JOB_MATCH_SETTINGS_SUCCESS,
  UPDATE_JOB_MATCH_SETTINGS_FAILURE,
  JOB_MATCH_SETTINGS_REQUEST,
  JOB_MATCH_SETTINGS_SUCCESS,
  JOB_MATCH_SETTINGS_FAILURE,
  TOGGLE_JOB_MATCH_SETTINGS_INITIAL_SEARCH_REQUEST
} from '../../constants';

export const toggleJobMatchSettingsInitialSearchRequest = (initialSearchRequest = false) => ({
  type: TOGGLE_JOB_MATCH_SETTINGS_INITIAL_SEARCH_REQUEST, initialSearchRequest
});

export const saveSettings = (data) => {
  const endpoint = 'job/settings';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        CREATE_JOB_MATCH_SETTINGS_REQUEST,
        CREATE_JOB_MATCH_SETTINGS_SUCCESS,
        CREATE_JOB_MATCH_SETTINGS_FAILURE
      ]
    }
  };
};

export const updateSettings = (id, data) => {
  const endpoint = `job/settings/${id}`;

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'patch',
      authenticated: true,
      types: [
        UPDATE_JOB_MATCH_SETTINGS_REQUEST,
        UPDATE_JOB_MATCH_SETTINGS_SUCCESS,
        UPDATE_JOB_MATCH_SETTINGS_FAILURE
      ]
    }
  };
};

export const fetchJobMatchSettings = (userId) => {
  const endpoint = `job/settings/${userId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        JOB_MATCH_SETTINGS_REQUEST,
        JOB_MATCH_SETTINGS_SUCCESS,
        JOB_MATCH_SETTINGS_FAILURE
      ]
    }
  };
};
