import React from 'react';
import { pure } from 'recompose';

import { useParams } from 'react-router-dom';

import { isUndefined } from 'lodash';

import PropTypes from 'prop-types';

import WallComponent from './WallComponent';
import InfiniteScroller from '../../containers/common/infinite-scroll/InfiniteScroller';

const WallContainer = ({
  callAPI,
  wallPosts,
  wallShare,
  shouldCallAPIInitially
}) => {
  const { postId } = useParams();
  const { paginationData } = wallPosts;

  return (
    <div className="container community-container">
      <div className="row">
        <div className="col-lg-12">
          {(!isUndefined(postId)) &&
            <WallComponent
              wallPosts={wallPosts}
              wallShare={wallShare}/>}

          {isUndefined(postId) &&
            <InfiniteScroller
              component={WallComponent}
              callAPI={callAPI}
              page_count={paginationData.page_count}
              page={paginationData.page}
              shouldCallAPIInitially={shouldCallAPIInitially}
              wallShare={wallShare}
              wallPosts={wallPosts}
              isLoaderInternal/>}
        </div>
      </div>
    </div>
  );
};

WallContainer.propTypes = {
  wallPosts: PropTypes.object.isRequired,
  wallShare: PropTypes.object.isRequired,
  callAPI: PropTypes.func.isRequired,
  shouldCallAPIInitially: PropTypes.bool,
};

export default pure(WallContainer);
