import React, { useState } from 'react';

import PropTypes from 'prop-types';
import Switch from 'react-switch';
import ConfirmModal from '../portfolio/ConfirmModal';
import {
  FINISHING,
  NO_CANCEL,
  STOP_FEEDBACK,
  STOP_FEEDBACK_CONFIRMATION,
  YES_STOP_REQUEST,
} from '../../constants/common';

const ToggleSwitch = (props) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmData, setConfirmData] = useState({});

  const { pauseDisableRequest, changePauseDisableRequest, name } = props;

  function onCloseConfirmModal(){
    setShowConfirmModal(false);
  }

  function onConfirmChange(){
    setShowConfirmModal(false);
    changePauseDisableRequest(confirmData);
  }

  function handleChange(checked){
    if (name === 'disableFeedbackRequests') {
      if (!pauseDisableRequest) {
        setConfirmData({
          target: { value: checked, name: name },
        });
        setShowConfirmModal(true);
      } else {
        changePauseDisableRequest({
          target: { value: checked, name: name },
        });
      }
    } else {
      changePauseDisableRequest({
        target: { value: checked, name: name },
      });
    }
  }

  return (
    <span className="form-group">
      <Switch
        checked={pauseDisableRequest}
        onChange={handleChange}
        onColor="#86d3ff"
        onHandleColor="#2693e6"
        handleDiameter={20}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={12}
        width={32}
        className="react-switch"
        id="material-switch"
        disabled={props.disabled || false}/>
      <ConfirmModal
        isOpen={showConfirmModal}
        confirminginProgressText={FINISHING}
        modalHeaderText={STOP_FEEDBACK}
        modalMessage={STOP_FEEDBACK_CONFIRMATION}
        modalFooterButtonText={YES_STOP_REQUEST}
        cancelText={NO_CANCEL}
        onCancel={onCloseConfirmModal}
        onConfirm={onConfirmChange}/>
    </span>
  );
};

ToggleSwitch.propTypes = {
  pauseDisableRequest: PropTypes.bool,
  changePauseDisableRequest: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ToggleSwitch;