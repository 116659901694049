import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown } from 'reactstrap';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';

const CareerPreppedLogo = require('../../assets/images/logo-icon.png');

const ShareBadgeDropdown = ({ showShareDropdown, assertionData, onShareClick, onShareToCp }) => {
  return (
    <Dropdown
      className="share-badge-dropdown dropup"
      isOpen={showShareDropdown}
      toggle={onShareClick}>
      <div className="dropdown-content">
        <h6 className="title">Share this badge</h6>
        <div className="social-share-option">
          <a
            onClick={onShareToCp}
            className="dropdown-item portfolio-action-item">
            <span className="icon-holder cp-icon">
              <img className="cp-logo" src={CareerPreppedLogo}/>
            </span>
            CareerPrepped
          </a>
          <FacebookShareButton
            image={assertionData.badge.image}
            beforeOnClick={onShareClick}
            url={`http://www.careerprepped.com/skill-badges/badge-details/${assertionData.issued.id}`}
            children={<a className="dropdown-item portfolio-action-item">
              <span className="icon-holder facebook-icon">
                <i className="fa fa-facebook-official"/>
              </span>
              Facebook
            </a>}
            quote={assertionData.badge.name}
            className="shareIcons"/>
          <TwitterShareButton
            beforeOnClick={onShareClick}
            children={<a className="dropdown-item portfolio-action-item">
              <span className="icon-holder twitter-icon">
                <i className="fa fa-twitter"/>
              </span>
              Twitter
            </a>}
            url={`http://www.careerprepped.com/skill-badges/badge-details/${assertionData.issued.id}`}
            className="shareIcons"
            hashtags={['hireme','careerdevelopment','lifelonglearner', 'digitalcredentials']}
            title={assertionData.badge.name}/>

          <LinkedinShareButton
            beforeOnClick={onShareClick}
            children={<a className="dropdown-item portfolio-action-item">
              <span className="icon-holder linkedin-icon">
                <i className="fa fa-linkedin-square"/>
              </span>
              Linkedin
            </a>}
            url={`http://www.careerprepped.com/skill-badges/badge-details/${assertionData.issued.id}`}
            className="shareIcons"
            description={`Check out my ${assertionData.badge.name} Skill Badge on CareerPrepped.com!`}
            title={assertionData.badge.name}/>
        </div>
      </div>
    </Dropdown>
  );
};

ShareBadgeDropdown.propTypes = {
  assertionData: PropTypes.object,
  showShareDropdown: PropTypes.bool,
  onShareClick: PropTypes.func,
  onShareToCp: PropTypes.func
};

export default ShareBadgeDropdown;
