export const updateElementsCount = (classNameSelector, allClusterDetails) => {
  const rowCounts = allClusterDetails.rowCounts;
  const clusterHeaders = document.querySelectorAll(classNameSelector);

  clusterHeaders.forEach((clusterElement) => {
    if (clusterElement.querySelector('.ci-bold-subtitle')) {
      const clusterSubHeaders = clusterElement.querySelectorAll('.ci-bold-subtitle');
      createCounterElement(clusterSubHeaders, allClusterDetails);
    }

    let arrayElements = clusterElement
      .querySelector('input[type=checkbox]')
      .id
      .split('-');

    arrayElements.splice(0, 2);

    const parentSelectCountId = arrayElements.join('-');

    const counterElement = document.createElement('span');

    counterElement.classList.add('clusterElementCounter');
    counterElement.innerHTML = `<span id='selCount${parentSelectCountId}'>0</span>/<span>${rowCounts[parentSelectCountId]}</span>`;
    clusterElement.firstElementChild.appendChild(counterElement);

    const clusterSubHeaders = clusterElement.querySelectorAll('.rct-collapse-btn');
    clusterSubHeaders.forEach((clusterSubElement) => {
      clusterSubElement.onclick = event => addCounterToChild(event, allClusterDetails);
    });
  });
};

export const createCounterElement = (clusterSubHeaders, allClusterDetails) => {
  const rowCounts = allClusterDetails.rowCounts;

  clusterSubHeaders.forEach((clusterSubElement) => {
    let arrayElements = clusterSubElement
      .querySelector('input[type=checkbox]')
      .id
      .split('-');

    arrayElements.splice(0, 2);

    const parentSelectCountId = arrayElements.join('-');

    clusterSubElement.querySelector('.clusterSubElementCounter')?.remove();

    const counterElement = document.createElement('span');

    counterElement.classList.add('clusterSubElementCounter');
    counterElement.innerHTML = `<span id='selCount${parentSelectCountId}'>0</span>/<span>${rowCounts[parentSelectCountId]}</span>`;
    clusterSubElement.firstElementChild.appendChild(counterElement);
  });
};

export const addCounterToChild = (event, allClusterDetails) => {
  setTimeout(() => {
    const clusterSubHeaders = event.target.parentNode.parentNode.parentNode.querySelectorAll('.ci-bold-subtitle');

    createCounterElement(clusterSubHeaders, allClusterDetails);
  }, 200);
};

export const updateSelectedCounts = () => {
  const clusterHeaders = document.querySelectorAll('.ci-main-category');

  setTimeout(() => {
    clusterHeaders.forEach((clusterElement) => {
      let getCheckedCount = clusterElement.querySelectorAll('.rct-icon-check').length;
      getCheckedCount += clusterElement.querySelectorAll('.rct-icon-half-check').length;

      if ( getCheckedCount > 0 ) {
        // Get Count for closed parents
        if (
          clusterElement.querySelector('ol') === null &&
          (
            clusterElement.firstElementChild.querySelector('.rct-icon-check') ||
            clusterElement.firstElementChild.querySelector('.rct-icon-half-check')
          )
        ) {
          const getclusterElementId = clusterElement.firstElementChild.querySelector('.clusterElementCounter span:last-child').innerHTML;
          getCheckedCount += parseInt(getclusterElementId);
        }

        // Get Count for closed sub parents
        const subHeaderElement = clusterElement.querySelectorAll('.ci-bold-subtitle');

        subHeaderElement.forEach((subElement) => {
          if (
            subElement.querySelector('ol') === null &&
            (
              subElement.firstElementChild.querySelector('.rct-icon-check') ||
              subElement.firstElementChild.querySelector('.rct-icon-half-check')
            )
          ) {
            const getSubEleId = subElement.firstElementChild.querySelector('.clusterSubElementCounter span:last-child');
            getCheckedCount += (getSubEleId) ? parseInt(getSubEleId.innerHTML) : 0;
          }
        });

        let arrayElements = clusterElement
          .firstElementChild
          .querySelector('input[type=checkbox]')
          .id
          .split('-');

        arrayElements.splice(0, 2);

        const getClusterEleId = arrayElements.join('-');
        const element = document.querySelector(`#selCount${getClusterEleId}`);

        if (element) {
          element.innerHTML = (parseInt(getCheckedCount) - 1);
        }
      } else {
        let arrayElements = clusterElement
          .firstElementChild
          .querySelector('input[type=checkbox]')
          .id
          .split('-');

        arrayElements.splice(0, 2);

        const getClusterEleId = arrayElements.join('-');
        const element = document.querySelector(`#selCount${getClusterEleId}`);

        element.innerHTML = '0';
      }
    });
  }, 200);
};

export const updateSubSelectedCounts = () => {
  const clusterSubHeaders = document.querySelectorAll('.ci-bold-subtitle');

  setTimeout(() => {
    clusterSubHeaders.forEach((subElement) => {
      let getCheckedCount = subElement.querySelectorAll('.rct-icon-check').length;
      getCheckedCount += subElement.querySelectorAll('.rct-icon-half-check').length;

      if (getCheckedCount > 0) {
        if (
          subElement.querySelector('ol') === null &&
          (
            subElement.firstElementChild.querySelector('.rct-icon-check') ||
            subElement.firstElementChild.querySelector('.rct-icon-half-check')
          )
        ) {
          const getSubEleId = subElement.firstElementChild.querySelector('.clusterSubElementCounter span:last-child');

          getCheckedCount += (getSubEleId) ? parseInt(getSubEleId.innerHTML) : 0;
        }

        let arrayElements = subElement
          .firstElementChild
          .querySelector('input[type=checkbox]')
          .id
          .split('-');

        arrayElements.splice(0, 2);

        const getSubEleId = arrayElements.join('-');
        const element = document.querySelector(`#selCount${getSubEleId}`);

        if (element) {
          element.innerHTML = (parseInt(getCheckedCount) - 1);
        }
      } else {
        let arrayElements = subElement
          .firstElementChild
          .querySelector('input[type=checkbox]')
          .id
          .split('-');

        arrayElements.splice(0, 2);

        const getSubEleId = arrayElements.join('-');

        const element = document.querySelector(`#selCount${getSubEleId}`);

        (element) ? element.innerHTML = '0' : '';
      }
    });
  });
};
