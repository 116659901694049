import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setSelectedEducatorGroup } from '../../../redux/actions/educators';
import { getEducatorUsers, resetFilterParams } from '../../../redux/actions/users';

import classNames from 'classnames';
import { Collapse } from 'reactstrap';

class GroupDropdown extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    selectedGroup: PropTypes.object.isRequired,
    groups: PropTypes.array.isRequired,
    totalEducatorUsersCount: PropTypes.number.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      keyword: props.selectedGroup.name
    };

    this.handleToggle = this.handleToggle.bind(this);
    this.onSelectGroup = this.onSelectGroup.bind(this);
  }

  handleToggle() {
    const { isOpen } = this.state;

    this.setState({ isOpen : ! isOpen });
  }

  onSelectGroup(event) {
    event.preventDefault();

    const { id: groupId } = event.target;
    const { actions, groups } = this.props;

    const selectedGroup = groups.find(group => group.id === groupId);

    actions.resetFilterParams();
    actions.setSelectedEducatorGroup(selectedGroup);

    this.fetchUsers(groupId);
    this.setState({ isOpen: false });
  }

  fetchUsers(groupId) {
    const { actions } = this.props;

    if (groupId) {
      let urlParamString = `group=${groupId}`;

      if (groupId === 'all-groups') {
        urlParamString = '';
      }

      actions.getEducatorUsers(urlParamString);
    }
  }

  render() {
    const { isOpen } = this.state;
    const {
      selectedGroup,
      groups,
      totalEducatorUsersCount
    } = this.props;

    return (
      <div style={{marginBottom: 24, marginTop: 24}}>
        <div className="admin-list-header" onClick={this.handleToggle}>
          {`${selectedGroup.name}`}
          <i className={classNames('fa float-right', {
            'fa-chevron-down': !isOpen,
            'fa-chevron-up': isOpen
          })}/>
        </div>

        <Collapse isOpen={isOpen}>
          <div style={{marginTop: 8, boxShadow: '1px 1px 5px rgba(0,0,0,.05)', backgroundColor:'white', padding: 16, borderRadius: 4 }}>
            <div className="row">
              <div className="col-lg-12">
                <div className="admin-item" style={{marginTop: 20}}>
                  <div className="admin-group-list">
                    {groups.map(group => (
                      <a
                        href="#"
                        key={group.id}
                        className="item"
                        id={group.id}
                        onClick={this.onSelectGroup}>
                        {`${group.name} (${
                          (group.id === 'all-groups') ?
                            totalEducatorUsersCount.toLocaleString() :
                            group.groupUsersCount.toLocaleString()
                        })`}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    educators: {
      groups,
      selectedGroup,
      statistics: { usersCount: totalEducatorUsersCount }
    }
  } = state;

  return {
    selectedGroup,
    groups,
    totalEducatorUsersCount
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    getEducatorUsers,
    resetFilterParams,
    setSelectedEducatorGroup
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupDropdown);
