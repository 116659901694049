import PropTypes from 'prop-types';
import React, { Component } from 'react';
import isNil from 'lodash/fp/isNil';


class PreviewAvatarModal extends Component {
  constructor(props) {
    super(props);

    this.refModal = this.refModal.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keyup', this.handleKeyUp, false);
    document.addEventListener('click', this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleKeyUp, false);
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  refModal(node){
    this.modal = node;
  }

  handleKeyUp(e) {
    const { onCloseRequest } = this.props;
    const keys = {
      27: () => {
        e.preventDefault();
        onCloseRequest();
        window.removeEventListener('keyup', this.handleKeyUp, false);
      },
    };

    if (keys[e.keyCode]) { 
      keys[e.keyCode](); 
    }
  }

  handleOutsideClick(e) {
    const { onCloseRequest } = this.props;

    if (!isNil(this.modal)) {
      if (!this.modal.contains(e.target)) {
        onCloseRequest();
        document.removeEventListener('click', this.handleOutsideClick, false);
      }
    }
  }

  render () {
    const {
      onCloseRequest,
      children
    } = this.props;
    
    return (
      <div className="custom-modal-overlay">
        <div className="custom-modal" ref={this.refModal}>
          {children}
        </div>

        <button
          type="button"
          className="close-button"
          onClick={onCloseRequest}/>
      </div>
    );
  }
}

PreviewAvatarModal.propTypes = {
  onCloseRequest: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default PreviewAvatarModal;
