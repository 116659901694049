import initialState from './initialState';

import {
  RESET_FOLLOWER_DATA,
  FOLLOWERS_REQUEST,
  FOLLOWERS_SUCCESS,
  FOLLOWERS_FAILURE,
  CREATE_FOLLOWER_REQUEST,
  CREATE_FOLLOWER_SUCCESS,
  CREATE_FOLLOWER_FAILURE,
  UPDATE_FOLLOWER_REQUEST,
  UPDATE_FOLLOWER_SUCCESS,
  UPDATE_FOLLOWER_FAILURE,
  DELETE_FOLLOWING_REQUEST,
  DELETE_FOLLOWING_SUCCESS,
  DELETE_FOLLOWING_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.followers, action) {
  switch (action.type) {
  case RESET_FOLLOWER_DATA:
    return Object.assign({}, state, { data: [] });
  case FOLLOWERS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case FOLLOWERS_SUCCESS:
    return Object.assign({}, state, {
      data: action.data._embedded.connect,
      paginationData: {
        page: action.data.page,
        page_count: action.data.page_count,
        total_items: action.data.total_items,
      },
      isRequesting: false,
    });

  case FOLLOWERS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case CREATE_FOLLOWER_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: { status: true, id: action.profile.id },
    });

  case CREATE_FOLLOWER_SUCCESS: {
    const index = state.data.findIndex(
      data => data.user.id === state.isSubmitting.id
    );
    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, index),
        Object.assign({}, state.data[index], {
          isUserFollower: {
            status: true,
            connectId: action.data.record,
          },
        }),
        ...state.data.slice(index + 1),
      ],
      isSubmitting: { status: false, id: null },
    });
  }

  case CREATE_FOLLOWER_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: { status: false, id: null },
    });

  case UPDATE_FOLLOWER_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, followerIndex: action.index },
    });

  case UPDATE_FOLLOWER_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, state.isUpdating.followerIndex),
        Object.assign({}, action.data),
        ...state.data.slice(state.isUpdating.followerIndex + 1),
      ],
      isUpdating: { status: false, followerIndex: null },
    });

  case UPDATE_FOLLOWER_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false, followerIndex: null },
    });

  case DELETE_FOLLOWING_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: action.id },
    });

  case DELETE_FOLLOWING_SUCCESS: {
    const followerIndex = state.data.findIndex(
      data => data.isUserFollower.connectId === state.isDeleting.id
    );
    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, followerIndex),
        Object.assign({}, state.data[followerIndex], {
          isUserFollower: {
            status: false,
            connectId: '',
          },
        }),
        ...state.data.slice(followerIndex + 1),
      ],
      isDeleting: { status: false, id: null },
    });
  }

  case DELETE_FOLLOWING_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: { status: false, id: null },
    });

  default:
    return state;
  }
}
