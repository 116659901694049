import PropTypes from 'prop-types';
import React from 'react';
import { uniqueId } from '../../../utils';
import AwardCardHandler from '../../../containers/profile/sections/AwardCardHandler';

const AwardList = ({awards, isPublicProfile, onEdit}) => {

  const sortAward = (awards) => {
    const newAwardList = [].concat(awards);
    return newAwardList.sort(function(a, b){
      return new Date(b.date.date) - new Date(a.date.date);
    });
  };

  const awardSortedList = sortAward(awards);

  if(awards.length === 0){
    return(
      <div className="empty-section-message">
        <p>Show off any honors & awards that recognize your achievements.</p>
      </div>
    );
  }

  return (
    <div>
      {awardSortedList.map(award =>
        (<AwardCardHandler
          key={uniqueId()}
          award={award}
          onEdit={onEdit}
          isPublicProfile={isPublicProfile}/>))}
    </div>
  );
};

AwardList.propTypes = {
  awards: PropTypes.array.isRequired,
  isPublicProfile: PropTypes.bool.isRequired,
  onEdit: PropTypes.func
};

export default AwardList;
