import PropTypes from 'prop-types';
import React from 'react';
import SkillsList from '../../../containers/profile/sections/SkillsList';
import SkillForm from '../forms/SkillForm';

const SkillContent = ({
  isEditing,
  isPublicProfile,
  reArrangeSkills,
  errors,
  skill,
  isSubmitting,
  onChange,
  onSave,
  onCancel,
  addEvidence,
  removeEvidence,
  handleKeyPress,
  skills,
  skillsOrder,
  editItemIsClicked,
  isItemBeingEdited,
  clearEvidenceErrors,
  allowUpdate,
  clearEvidenceSection,
  onAddClicked
}) => {

  if (!isEditing)
    return (
      <SkillsList
        onAddClicked={onAddClicked}
        reArrangeSkills={reArrangeSkills}
        isPublicProfile={isPublicProfile}
        skills={skills}
        skillsOrder={skillsOrder}
        editItemIsClicked={editItemIsClicked}
        isItemBeingEdited={isItemBeingEdited}/>
    );

  return (
    <SkillForm
      errors={errors}
      skill={skill}
      isSubmitting={isSubmitting}
      onChange={onChange}
      onSave={onSave}
      onCancel={onCancel}
      addEvidence={addEvidence}
      removeEvidence={removeEvidence}
      handleKeyPress={handleKeyPress}
      clearEvidenceErrors={clearEvidenceErrors}
      allowUpdate={allowUpdate}
      clearEvidenceSection={clearEvidenceSection}/>
  );
};

SkillContent.propTypes = {
  isEditing: PropTypes.bool,
  isPublicProfile: PropTypes.bool,
  reArrangeSkills: PropTypes.func,
  errors: PropTypes.object,
  skill: PropTypes.object,
  isSubmitting: PropTypes.bool,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  addEvidence: PropTypes.func,
  removeEvidence: PropTypes.func,
  handleKeyPress: PropTypes.func,
  skills: PropTypes.array,
  skillsOrder: PropTypes.array,
  editItemIsClicked: PropTypes.func,
  isItemBeingEdited: PropTypes.bool,
  clearEvidenceErrors: PropTypes.func,
  allowUpdate: PropTypes.bool,
  clearEvidenceSection: PropTypes.func,
  onAddClicked: PropTypes.func,
};

export default SkillContent;
