import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Helmet } from 'react-helmet';
import classNames from 'classnames';

import SkillBadgeModal from '../../components/home/SkillBadgeModal';
import IndustryMentorsModal from '../../components/home/IndustryMentorsModal';
import SkillBuildersModalContainer from '../home/SkillBuildersModalContainer';

import { openModal } from '../../redux/actions/components';
import * as skillBuildersActions from '../../redux/actions/skill-builders';

const careerPortfolio = require('../../assets/images/toolkit/img-toolkit-careerportfolio.jpg');
const careerSite = require('../../assets/images/toolkit/img-toolkit-careersite.jpg');
const interviewVideos = require('../../assets/images/toolkit/img-toolkit-interviewprepvideos.jpg');
const jobMatch = require('../../assets/images/toolkit/img-toolkit-jobmatch.jpg');
const jobTracker = require('../../assets/images/toolkit/img-toolkit-jobtracker.jpg');
const skillBadges = require('../../assets/images/toolkit/img-toolkit-skillbadges.jpg');
const skillBuilders = require('../../assets/images/toolkit/img-toolkit-skillbuilders.jpg');
const skillEndorsements = require('../../assets/images/toolkit/img-toolkit-skillendorsements.jpg');
const skillFeedback = require('../../assets/images/toolkit/img-toolkit-skillfeedback.jpg');
const resumeBuilder = require('../../assets/images/toolkit/img-toolkit-targetedresume.jpg');
const imgToolkitHero = require('../../assets/images/toolkit/img-toolkit-hero.jpg');

class Toolkit extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    skillBuilders: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      activeSlideOne: 'careerSite',
      showSkillBadgeModal: false,
      showSkillBuildersModal: false,
      showIndustryMentorsModal: false
    };

    this.onClickSlide = this.onClickSlide.bind(this);
    this.toggleSkillBadgeModal = this.toggleSkillBadgeModal.bind(this);
    this.toggleSkillBuildersModal = this.toggleSkillBuildersModal.bind(this);
    this.toggleJoinMembershipModal = this.toggleJoinMembershipModal.bind(this);
    this.toggleIndustryMentorsModal = this.toggleIndustryMentorsModal.bind(this);
  }

  componentDidMount(){
    const { skillBuildersRequest } = this.props.actions;

    skillBuildersRequest();
  }

  toggleSkillBadgeModal(event){
    event.preventDefault();

    this.setState({
      showSkillBadgeModal: !this.state.showSkillBadgeModal
    });
  }

  toggleSkillBuildersModal(event){
    event.preventDefault();

    this.setState({ showSkillBuildersModal: !this.state.showSkillBuildersModal });
  }

  toggleIndustryMentorsModal(event) {
    event.preventDefault();

    this.setState({ showIndustryMentorsModal: !this.state.showIndustryMentorsModal });
  }

  onClickSlide(event) {
    event.preventDefault();

    const { id, dataset: { name } } = event.target;

    this.setState({ [name]: id });
  }

  toggleJoinMembershipModal() {
    this.props.actions.openModal('join-membership-modal');
  }

  render() {
    const {
      activeSlideOne,
      showSkillBadgeModal,
      showSkillBuildersModal,
      showIndustryMentorsModal
    } = this.state;

    const { isRequesting, data: skillBuildersData } = this.props.skillBuilders;

    return (
      <div className="for-org non-logged-homepage">
        <Helmet title="Toolkit"/>
        <div className="toolkit-slider">
          <header>
            <div className="header-content">
              <div className="header-item">
                <div className="header-heading">
                  <h1>Career Success Toolkit</h1>
                </div>
                <p>CareerPrepped empowers you with effective tools all in one place to help you show off your skills and secure your dream job.</p>
              </div>
              <div className="header-item">
                <img src={imgToolkitHero} alt="CareerPrepped - Showcase your skills" className="img-fluid d-block mx-auto"/>
              </div>
            </div>
          </header>
          <section className="section-skills">
            <p className="instructions">Click on each tool below to see a description of the tool.</p>
            <div className="ss-navigation">
              <a
                href="#"
                id="careerSite"
                data-name="activeSlideOne"
                onClick={this.onClickSlide}
                className={classNames({ active: activeSlideOne === 'careerSite' })}>
                Career Site
              </a>
              <a
                href="#"
                id="resumeBuilder"
                data-name="activeSlideOne"
                onClick={this.onClickSlide}
                className={classNames({ active: activeSlideOne === 'resumeBuilder' })}>
                Resume Builder
              </a>
              <a
                href="#"
                id="jobMatch"
                data-name="activeSlideOne"
                onClick={this.onClickSlide}
                className={classNames({ active: activeSlideOne === 'jobMatch' })}>
                Job Match
              </a>
              <a
                href="#"
                id="jobTracker"
                data-name="activeSlideOne"
                onClick={this.onClickSlide}
                className={classNames({ active: activeSlideOne === 'jobTracker' })}>
                Job Tracker
              </a>
              <a
                href="#"
                id="interviewVideos"
                data-name="activeSlideOne"
                onClick={this.onClickSlide}
                className={classNames({ active: activeSlideOne === 'interviewVideos' })}>
                Interview Prep Video Library
              </a>
              <a
                href="#"
                id="careerPortfolio"
                data-name="activeSlideOne"
                onClick={this.onClickSlide}
                className={classNames({ active: activeSlideOne === 'careerPortfolio' })}>
                Career Portfolio
              </a>
              <a
                href="#"
                id="skillBuilders"
                data-name="activeSlideOne"
                onClick={this.onClickSlide}
                className={classNames({ active: activeSlideOne === 'skillBuilders' })}>
                Skill Builders
              </a>
              <a
                href="#"
                id="skillBadges"
                data-name="activeSlideOne"
                onClick={this.onClickSlide}
                className={classNames({ active: activeSlideOne === 'skillBadges' })}>
                Skill Badges
              </a>
              <a
                href="#"
                id="skillFeedback"
                data-name="activeSlideOne"
                onClick={this.onClickSlide}
                className={classNames({ active: activeSlideOne === 'skillFeedback' })}>
                Skill Feedback & Ratings
              </a>
              <a
                href="#"
                id="skillEndorsements"
                data-name="activeSlideOne"
                onClick={this.onClickSlide}
                className={classNames({ active: activeSlideOne === 'skillEndorsements' })}>
                Skill Endorsements & Testimonials
              </a>
              
            </div>

            <div className="gallery">
              <div className={
                classNames('gallery-item',
                  {
                    'animated fadeIn': activeSlideOne === 'careerSite',
                    'active': activeSlideOne === 'careerSite'
                  })}>
                <div className="gallery-item-section-container">
                  <div className="gallery-item-section">
                    <img src={careerSite} className="img-fluid d-block mx-auto"/>
                  </div>
                  <div className="gallery-item-section">
                    <div className="p-4">
                      <div className="title-inside">Career Site</div>
                      <p>
                        Don't settle for blending in. Say no to boring resumes and basic profiles. Set yourself apart from the crowd by showing off what makes you unique. Create your own personal Career Site with links to multiple pages to showcase your skills and achievements beyond your education and job history. It's time to make a statement and stand out!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={
                classNames('gallery-item',
                  {
                    'animated fadeIn': activeSlideOne === 'resumeBuilder',
                    'active': activeSlideOne === 'resumeBuilder'
                  })}>
                <div className="gallery-item-section-container">
                  <div className="gallery-item-section">
                    <img src={resumeBuilder} className="img-fluid"/>
                  </div>
                  <div className="gallery-item-section">
                    <div className="p-4">
                      <div className="title-inside">Resume Builder</div>
                      <p>
                        With just a click, transform your Career Site into an interactive resume. It has clickable links to back up your skills with evidence. Edit it on the fly to save countless resumes customized for the job, company, or hiring manager you're targeting. Shine bright with a personalized resume for each job you go after. 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={
                classNames('gallery-item',
                  {
                    'animated fadeIn': activeSlideOne === 'jobMatch',
                    'active': activeSlideOne === 'jobMatch'
                  })}>
                <div className="gallery-item-section-container">
                  <div className="gallery-item-section">
                    <img src={jobMatch} className="img-fluid"/>
                  </div>
                  <div className="gallery-item-section">
                    <div className="p-4">
                      <div className="title-inside">Job Match</div>
                      <p>
                        Discover your dream job with our Job Match tool, powered by AI. It looks through thousands of job sites to find the perfect match for you based on your goals and preferences. Uncover exciting jobs you might not have known existed. 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={
                classNames('gallery-item',
                  {
                    'animated fadeIn': activeSlideOne === 'jobTracker',
                    'active': activeSlideOne === 'jobTracker'
                  })}>
                <div className="gallery-item-section-container">
                  <div className="gallery-item-section">
                    <img src={jobTracker} className="img-fluid"/>
                  </div>
                  <div className="gallery-item-section">
                    <div className="p-4">
                      <div className="title-inside">Job Tracker</div>
                      <p>
                        Keep all your job applications in one organized spot. Save the jobs you like, jot down notes, and keep contact details handy. Track your journey from applying to landing your dream job!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={
                classNames('gallery-item',
                  {
                    'animated fadeIn': activeSlideOne === 'interviewVideos',
                    'active': activeSlideOne === 'interviewVideos'
                  })}>
                <div className="gallery-item-section-container">
                  <div className="gallery-item-section">
                    <img src={interviewVideos} className="img-fluid"/>
                  </div>
                  <div className="gallery-item-section">
                    <div className="p-4">
                      <div className="title-inside">Interview Prep Video Library</div>
                      <p>
                        Get ready to ace your next interview! Watch videos to learn how to answer common interview questions like a pro. See examples of both weak and strong responses with helpful feedback. Get the tools you need to shine and turn your interview into a job offer!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={
                classNames('gallery-item',
                  {
                    'animated fadeIn': activeSlideOne === 'careerPortfolio',
                    'active': activeSlideOne === 'careerPortfolio'
                  })}>
                <div className="gallery-item-section-container">
                  <div className="gallery-item-section">
                    <img src={careerPortfolio} className="img-fluid"/>
                  </div>
                  <div className="gallery-item-section">
                    <div className="p-4">
                      <div className="title-inside">Career Portfolio</div>
                      <p>
                        Unlock the power of your achievements! Build a digital treasure trove to showcase proof of your skills and accomplishments. With privacy controls and unlimited storage, you can store school projects, work samples, credentials, awards and more. Let your achievements speak louder than words on a resume! 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={
                classNames('gallery-item',
                  {
                    'animated fadeIn': activeSlideOne === 'skillBuilders',
                    'active': activeSlideOne === 'skillBuilders'
                  })}>
                <div className="gallery-item-section-container">
                  <div className="gallery-item-section">
                    <img src={skillBuilders} className="img-fluid"/>
                  </div>
                  <div className="gallery-item-section">
                    <div className="p-4">
                      <div className="title-inside">Skill Builders <span>for Essential Skills</span></div>
                      <p>
                        Put a spotlight on your "human skills" - these are among the essential skills all employers crave. And they are in higher demand in today's AI-driven world. Get ahead with 40+ <a href="#" onClick={this.toggleSkillBuildersModal}>Skill Builders</a> to boost your essentials skills and set yourself apart from your competition. These are the skills you need for lifelong career success, as reported by hundreds of industry surveys nationwide.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={
                classNames('gallery-item',
                  {
                    'animated fadeIn': activeSlideOne === 'skillBadges',
                    'active': activeSlideOne === 'skillBadges'
                  })}>
                <div className="gallery-item-section-container">
                  <div className="gallery-item-section">
                    <img src={skillBadges} className="img-fluid"/>
                  </div>
                  <div className="gallery-item-section">
                    <div className="p-4">
                      <div className="title-inside">Skill Badges <span>for Essential Skills</span></div>
                      <p>
                        Claim <a href="#" onClick={this.toggleSkillBadgeModal}>Skill Badges</a> to prove your essential skills with real evidence that others can review and endorse. Make sure employers know without a doubt that you have the skills <Link to="/research" target="_blank" rel="noopener noreferrer">research</Link> proves they're looking for but have a hard time finding in job candidates.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={
                classNames('gallery-item',
                  {
                    'animated fadeIn': activeSlideOne === 'skillEndorsements',
                    'active': activeSlideOne === 'skillEndorsements'
                  })}>
                <div className="gallery-item-section-container">
                  <div className="gallery-item-section">
                    <img src={skillEndorsements} className="img-fluid"/>
                  </div>
                  <div className="gallery-item-section">
                    <div className="p-4">
                      <div className="title-inside">Skill Endorsements & Testimonials</div>
                      <p>
                        Get strong endorsements and testimonials from those who've seen your skills firsthand. Don't settle for weak praises from strangers. Verified endorsements, backed by proof, help you stand out and impress employers. Start collecting them and make your claimed skills more credible! 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={
                classNames('gallery-item',
                  {
                    'animated fadeIn': activeSlideOne === 'skillFeedback',
                    'active': activeSlideOne === 'skillFeedback'
                  })}>
                <div className="gallery-item-section-container">
                  <div className="gallery-item-section">
                    <img src={skillFeedback} className="img-fluid"/>
                  </div>
                  <div className="gallery-item-section">
                    <div className="p-4">
                      <div className="title-inside">Skill Feedback & Ratings</div>
                      <p>
                        Get your claimed skills recognized through the power of feedback. CareerPrepped lets you provide evidence to prove each skill you have and then get feedback from community members and <a href="#" onClick={this.toggleIndustryMentorsModal}>industry mentors</a> on how well you’ve proven them. With feedback, learn your strengths and where you need to level up. Improve your skill evidence and increase your feedback ratings to showcase your proven skills to employers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {showSkillBadgeModal &&
            <SkillBadgeModal
              showSkillBadgeModal={showSkillBadgeModal}
              toggleSkillBadgeModal={this.toggleSkillBadgeModal} />}

          {showIndustryMentorsModal &&
            <IndustryMentorsModal
              showIndustryMentorsModal={showIndustryMentorsModal}
              toggleIndustryMentorsModal={this.toggleIndustryMentorsModal} />}

          {showSkillBuildersModal &&
            <SkillBuildersModalContainer
              isRequesting={isRequesting}
              skillbuilders={skillBuildersData}
              showSkillBuildersModal={showSkillBuildersModal}
              toggleSkillBuildersModal={this.toggleSkillBuildersModal} />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    skillBuilders: state.skillBuilders
  };
};

const mapDispatchToProps = dispatch =>
  ({ actions: bindActionCreators({ openModal, ...skillBuildersActions }, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(Toolkit);
