import React from 'react';
import PropTypes from 'prop-types';

import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';

const UserLikesListModal = ({ isOpen, onClose, userLikes }) => {
  return(
    <Modal
      className="modal-margin-top"
      isOpen={isOpen}
      toggle={onClose}>
      <ModalHeader
        style={{paddingBottom: 0}}
        toggle={onClose}>
        {`${userLikes.length} Like${userLikes.length > 1 ? 's' : ''}`}
      </ModalHeader>
      <ModalBody style={{paddingTop: 0}}>
        {
          userLikes.map((user, index) => {
            const { name, avatar: userPic, vanity } = user;

            return (
              <div key={index} className="user-details-in-messaging">
                <Link to={`/cp/${vanity}`} className="user-image">
                  {
                    userPic !== '' ?
                      <img
                        className="image"
                        width="64"
                        height="64"
                        src={userPic}/>:
                      <div
                        className="image initial-avatar">
                        {name.slice(0,1)}
                      </div>
                  }
                </Link>
                <Link to={`/cp/${vanity}`}>
                  {name}
                </Link>
              </div>
            );
          })
        }
      </ModalBody>
    </Modal>
  );
};

UserLikesListModal.propTypes = {
  isOpen: PropTypes.bool,
  userLikes: PropTypes.array,
  onClose: PropTypes.func
};

export default UserLikesListModal;
