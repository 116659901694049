import { createSelector } from 'reselect';
import { uniqueId } from '../../utils';

const selectSkillCategories = state => state.skillCategories;

export const selectCategoriesList = createSelector(
  [selectSkillCategories],
  skillCategories => skillCategories.categories
);

export const selectFormattedCategories = createSelector(
  [selectCategoriesList],
  categories =>
    categories.map(
      category => ({
        id: uniqueId(),
        label: category.name,
        value: category.id
      })
    )
);

export const selectCategoriesPageCount = createSelector(
  [selectSkillCategories],
  categories => categories.categoriesPageCount
);

export const selectSubCategoriesList = createSelector(
  [selectSkillCategories],
  skillCategories => skillCategories.subCategories
);

export const selectFormattedSubCategories = createSelector(
  [selectSubCategoriesList],
  categories =>
    categories.map(
      category => ({
        id: uniqueId(),
        label: category.name,
        value: category.id
      })
    )
);

export const selectSubCategoriesPageCount = createSelector(
  [selectSkillCategories],
  categories => categories.subCategoriesPageCount
);
