import PropTypes from 'prop-types';
import React from 'react';

import Parser from 'html-react-parser';
import { Link } from 'react-router-dom';

import moment from 'moment';

const SkillBadgesSearchItem = ({ badge }) => {
  const { url, name, user, personalStatement, fileCount, skillsCount, endorsementCount, issued } = badge;
  const issuedDate = moment(issued.issuedAt.date).format('MM/DD/YYYY');

  return (
    <div className="searchBadgeCard">
      <div className="row">
        <div className="col-lg-12">
          <div className="badgeBG">
            <div className="row row-flex">
              <div className="col-lg-3 badgeInfoContainer">
                <div className="badgeInfo text-center">
                  <div className="image">
                    <Link to={`/skill-badges/badge-details/${issued.id}`}>
                      <img src={url} alt="" height="80" width="80"/>
                    </Link>
                  </div>
                  <div className="badgeDetails">
                    <h4 className="mb-0 mt-1">{name}</h4>
                    <span className="badgeDate small">{issuedDate}</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 badgeContentContainer">
                <div className="badgeContent">
                  <div className="badgeContentHeader">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="media align-items-center">
                          <div className="media-left mr-3">
                            <Link
                              to={`/cp/${user.vanity}`}
                              title={`Link to ${user.name}’s Career Site`}>
                              {
                                user.avatar && user.avatar.length !== 0 ?
                                  <img
                                    className="profile-avatar media-object"
                                    src={user.avatar}
                                    alt={`${user.name}'s Avatar`}/>:
                                  <span
                                    style={{
                                      lineHeight: '60px',
                                      fontSize: 32
                                    }}
                                    className="profile-avatar media-object initial-avatar">
                                    {user.name.slice(0,1)}
                                  </span>
                              }
                            </Link>
                          </div>
                          <div className="media-body">
                            <h4 className="media-heading searchResultsCardName">
                              <Link
                                to={`/cp/${user.vanity}`}
                                title={`Link to ${user.name}’s Career Site`}>
                                {user.name}
                              </Link>
                            </h4>
                            <span className="searchResultsCardTitle">{user.headline}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="badge-stats">
                          <div className="stat-details">
                            <div className="float-left">
                              <i className="fa fa-briefcase"/><span className="stat-name">Portfolio Files</span>
                            </div>
                            <div className="float-right">
                              <p className="stat-number">{fileCount}</p>
                            </div>
                          </div>
                          <div className="stat-details">
                            <div className="float-left">
                              <i className="fa fa-check-circle"/><span className="stat-name">Endorsements</span>
                            </div>
                            <div className="float-right">
                              <p className="stat-number">{endorsementCount}</p>
                            </div>
                          </div>
                          <div className="stat-details">
                            <div className="float-left">
                              <i className="fa fa-star"/><span className="stat-name">Skills Supported</span>
                            </div>
                            <div className="float-right">
                              <p className="stat-number">{skillsCount}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="badgeContentDescription">
                    <h5>Question Responses</h5>
                    {Parser(personalStatement)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>);
};

SkillBadgesSearchItem.propTypes = {
  badge: PropTypes.object.isRequired
};

export default SkillBadgesSearchItem;
