import React from 'react';

import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import CloseIcon from '../../assets/images/close.svg';

const ConfirmSubmit = ({
  isOpen,
  status,
  toggleModal,
  proceed,
  saveFeedback,
  submitRequest
}) => {
  function handleProceed() {
    proceed('step1');
  }

  function handleToggleModal() {
    toggleModal();
  }

  function closeModal() {
    toggleModal();
    handleProceed();
  }

  return (
    <Modal
      centered
      className="customLevelUpModal"
      isOpen={isOpen}
      toggle={handleProceed}>
      <div className="modal-header">
        <span className="modal-title">{status === 'pending' ? 'Finish Giving Feedback'
          :'Finish Revising Your Feedback'}</span>
        <div
          onClick={closeModal}
          className="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">
            <img src={CloseIcon} />{' '}
          </span>
        </div>
      </div>

      <ModalBody>
        <p style={{ color: '#55595C' }}>
          {status === 'pending'
            ? 'Are you sure you\'re finished giving your feedback?'
            : 'Your revised feedback and comments will replace your previous feedback & comments. Are you sure you’re ready to finish your revision?'}
        </p>
      </ModalBody>
      <ModalFooter className="bordertop-0">
        <button
          type="button"
          onClick={handleToggleModal}
          className="btn btn-secondary">
          Cancel
        </button>
        <Button className="ml-1 sendButton" onClick={saveFeedback} disabled={submitRequest}>
          {status === 'pending' ? 'I\'m finished':'Yes, Revise'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ConfirmSubmit.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  proceed: PropTypes.func.isRequired,
  status: PropTypes.string,
  saveFeedback: PropTypes.func,
  submitRequest: PropTypes.bool,
};

export default ConfirmSubmit;
