import React from 'react';

import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import CloseIcon from '../../assets/images/close.svg';

const ShowTestimonialModal = ({
  testimonial,
  isOpen,
  toggleModal,
  confirmVisibility,
}) => {
  const resourceType = testimonial?.badgeId ? 'badge' : 'Skill';

  function setLocalStorage(event){
    localStorage.setItem([`${testimonial?.id}_${testimonial?.visibility ? 'hide' : 'show'}_testimonial`], event.target.checked);
  }

  function setVisiblity(){
    confirmVisibility(testimonial);
  }

  return (
    <Modal
      centered
      className="customLevelUpModal"
      isOpen={isOpen}
      toggle={toggleModal}>
      <div className="modal-header">
        <span className="modal-title">
          {testimonial?.visibility ? 'Hide Testimonial' : 'Show Testimonial'}
        </span>
        <div
          onClick={toggleModal}
          className="close pointer"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">
            <img src={CloseIcon} />
          </span>
        </div>
      </div>

      <ModalBody>
        <p>
          {testimonial?.visibility ? `This will hide this testimonial from displaying on your ${resourceType} evidence page until you choose to show it again.`:
            `This will display the testimonial on the ${resourceType} evidence page.`}
        </p>
        <input
          className="mr-1"
          type="checkbox"
          onChange={setLocalStorage}/>
        <span>Don't show me again</span>
      </ModalBody>
      <ModalFooter className="bordertop-0">
        <Button className="ml-1 btn btn-secondary" onClick={toggleModal}>
          Cancel
        </Button>
        <Button className="ml-1 sendButton" onClick={setVisiblity}>
          Okay
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ShowTestimonialModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isBadge: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  confirmVisibility: PropTypes.func.isRequired,
  testimonial: PropTypes.object,
};

export default ShowTestimonialModal;
