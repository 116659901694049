import initialState from './initialState';
import {
  GET_MY_CP_STATUS_REQUEST,
  GET_MY_CP_STATUS_SUCCESS,
  GET_MY_CP_STATUS_FAILURE,
  UPDATE_MY_CP_STATUS_REQUEST,
  UPDATE_MY_CP_STATUS_SUCCESS,
  UPDATE_MY_CP_STATUS_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.myCareerPreppedStatus, action) {

  switch (action.type) {

  case GET_MY_CP_STATUS_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };

  case GET_MY_CP_STATUS_SUCCESS: {
    return {
      ...state,
      isRequesting: false,
      data: { ...action.data?.detail },
    };
  }

  case GET_MY_CP_STATUS_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  case UPDATE_MY_CP_STATUS_REQUEST:
  case UPDATE_MY_CP_STATUS_SUCCESS:
  case UPDATE_MY_CP_STATUS_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  default:
    return state;
  }
}
