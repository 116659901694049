import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isEmpty } from 'lodash';

import { closeModal } from '../../../redux/actions/components';
import { profileRequest } from '../../../redux/actions/profile';
import { getEducatorGroups, updateEducatorGroup } from '../../../redux/actions/educators';

import { selectIsRecentEducatorAccount } from '../../../redux/selectors/profile';

import { Helmet } from 'react-helmet';
import { toastr } from 'react-redux-toastr';
import { Row, Col, Container } from 'reactstrap';

import GroupsTable from './GroupsTable';
import InitialLandingPage from './InitialLandingPage';
import Loading from '../../../components/common/Loading';
import AdminGroupAdd from '../../../components/admin/AdminGroupAdd';
import UpdateCodeModal from '../../../components/admin/UpdateCodeModal';
import CreateGroupAlert from '../../../components/admin/CreateGroupAlert';
import AdminGroupEditModal from '../../../components/admin/AdminGroupEditModal';
import AdminGroupAddModal from '../../../components/admin/AdminGroupAddModal';

class EducatorManageGroups extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    error: PropTypes.object.isRequired,
    modal: PropTypes.string,
    actions: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    groupsCount: PropTypes.number.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    selectedGroup: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    isInitialRequest: PropTypes.bool.isRequired,
    duplicateGroups: PropTypes.array.isRequired,
    isRequestingProfile: PropTypes.bool.isRequired,
    isRecentEducatorAccount: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props);

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onConfirmUpdateCode = this.onConfirmUpdateCode.bind(this);
  }

  componentDidMount() {
    const { actions, isRequesting } = this.props;
    if (! isRequesting) {
      actions.getEducatorGroups(true, '');
    }
  }

  onCloseModal() {
    this.props.actions.closeModal();
  }

  onConfirmUpdateCode() {
    const { actions, selectedGroup: { id: groupId, name: groupName } } = this.props;

    actions.updateEducatorGroup(groupId, { 'inviteCode': true })
      .then((state) => {
        const { groups: { error, isUpdating: { status } } } = state;

        if ((! status) && (isEmpty(error))) {
          toastr.success('', `You successfully updated the group code for ${groupName}`);

          actions.closeModal();
        }
      });
  }

  render() {
    const {
      error,
      modal,
      isUpdating,
      groupsCount,
      isRequesting,
      isSubmitting,
      selectedGroup,
      duplicateGroups,
      isRecentEducatorAccount
    } = this.props;

    const isGroupEditModalOpen = (modal && modal === 'group-edit');
    const isAddGroupModalOpen = (modal && modal === 'admin-group-add');
    const isConfirmUpdateCodeModalOpen = (modal && modal === 'confirm-update-group-code');

    if (isRequesting) {
      return (
        <div className="loading-container">
          <Loading/>
        </div>
      );
    }

    return (
      <div>
        <Helmet title="Educator - Manage Groups"/>
        <div className="interior manage-groups mt-1">
          <Container>
            <Row>
              <Col>
                <div className="groupName mb-2">Manage Groups</div>
                <div className="group-content mb-3">
                  <p>
                    Groups are how you organize your users. Your free Talent Developer account lets you create one group. When you create a group, it has a "group code" you can share with users so they can sign themselves up at <a href="https://careerprepped.com/group" target="_blank">https://www.careerprepped.com/group</a> and join the group you want them to join.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>

          {groupsCount === 0 ?
            <InitialLandingPage/> :

            <Container>
              <Row className="mt-3">
                <Col>
                  <h2>
                    Groups
                    {(groupsCount < 10) && (!isRecentEducatorAccount) &&
                      <AdminGroupAdd>
                        <i className="fa fa-plus text-white" />{' '}Add New Group
                      </AdminGroupAdd>}
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  {(isEmpty(error) &&
                    (duplicateGroups.length !== 0) &&
                    (! isSubmitting) &&
                    (! isRequesting)) &&
                    <CreateGroupAlert/>}

                  <GroupsTable/>
                </Col>
              </Row>
            </Container>}
        </div>

        {isAddGroupModalOpen &&
          <AdminGroupAddModal
            onCancel={this.onCloseModal}
            toggle={this.onCloseModal}
            isOpen={isAddGroupModalOpen} />}

        {isConfirmUpdateCodeModalOpen &&
          <UpdateCodeModal
            groupName={selectedGroup.name}
            isOpen={isConfirmUpdateCodeModalOpen}
            onCancel={this.onCloseModal}
            onConfirm={this.onConfirmUpdateCode}
            isUpdating={isUpdating}/>}

        {isGroupEditModalOpen &&
          <AdminGroupEditModal
            onCancel={this.onCloseModal}
            toggle={this.onCloseModal}
            isOpen={isGroupEditModalOpen} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    educators: {
      error,
      isUpdating,
      isRequesting,
      selectedGroup,
      isInitialRequest,
      groups: groupsList,
      isSubmitting: {
        status: isSubmitting,
        duplicateGroups
      }
    },
    components: { modal },
    profile: { data: profile, isRequesting: isRequestingProfile },
    auth: { isAuthenticated, data: { currentUser: { id: userId } } }
  } = state;

  const allGroups = groupsList.filter(group => group.id !== 'all-groups');
  const { length: groupsCount } = allGroups;

  return {
    error,
    modal,
    userId,
    profile,
    groupsCount,
    isUpdating,
    isRequesting,
    isInitialRequest,
    isRequestingProfile,
    isSubmitting,
    selectedGroup,
    isAuthenticated,
    duplicateGroups,
    isRecentEducatorAccount: selectIsRecentEducatorAccount(state)
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    closeModal,
    updateEducatorGroup,
    profileRequest,
    getEducatorGroups
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(EducatorManageGroups);
