import PropTypes from 'prop-types';
import React from 'react';
import { uniqueId } from '../../../utils';
import EducationCardHandler from '../../../containers/profile/sections/EducationCardHandler';

const EducationList = ({education, isPublicProfile, onEdit}) => {
  const sortEducation = (education) => {
    const newEducationList = [].concat(education);
    return newEducationList.sort(function(a, b){
      return new Date(b.enddate.date) - new Date(a.enddate.date);
    });
  };

  const educationSortedList = sortEducation(education);

  if(education.length === 0){
    return(
      <div className="empty-section-message">
        <p>Add education to let people know what you're learning or have learned.</p>
      </div>
    );
  }
  return (
    <div>
      {educationSortedList.map(item =>
        (<EducationCardHandler
          key={uniqueId()}
          education={item}
          onEdit={onEdit}
          isPublicProfile={isPublicProfile}/>))}
    </div>
  );
};

EducationList.propTypes = {
  education: PropTypes.array.isRequired,
  isPublicProfile: PropTypes.bool.isRequired,
  onEdit: PropTypes.func,
};

export default EducationList;
