import React from 'react';

import { Link } from 'react-router-dom';

import { Helmet } from 'react-helmet';

const PaymentStatus = () => {
  return (
    <div className="interior essential  homepage_nli">
      <Helmet title="Payment Status"/>
      <div className="talent-development">
        <div className="toolkit">
          <header>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h1>
                    Success
                  </h1>
                </div>
              </div>
            </div>
          </header>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="account-card" style={{marginTop: 32}}>
                    <h4>Thank You for Your Purchase</h4>
                    <p className="account-subheader">Your Payment Was Successful</p>

                    <div className="account-desc-container">
                      <h5>What's Next?</h5>
                      <ul>
                        <li><strong>1. Check your email:</strong> We've sent you a confirmation email with instructions to set up your password.</li>
                        <li><strong>2. Sign in:</strong> Use the link in the email to create your password and sign in to access your account.</li>
                      </ul>
                      <h6>Need Help?</h6>
                      <Link
                        to="/contact"
                        className="btn btn-sm btn-primary">
                        Contact Support
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PaymentStatus;
