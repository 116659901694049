import PropTypes from 'prop-types';
import React from 'react';

import BadgeAbilitiesCheckbox from '../../../containers/skill-builders/BadgeAbilitiesCheckbox';

const BadgeAbilities = ({abilities, badge, achievedAbilities, onAcknowledgementChange}) => {
  return (
    <div className="claim-item">
      <h5><strong>Step 1. Acknowledge Your Abilities</strong> <span className="small text-danger"/></h5>
      <div className="ability-check">
        <div className="form-check">
          {abilities.map((item, index) => {
            const isChecked = achievedAbilities.includes(item.value);

            return (
              <BadgeAbilitiesCheckbox
                badge={badge}
                key={index}
                isChecked={isChecked}
                item={item}
                onAcknowledgementChange={onAcknowledgementChange}/>
            );
          })}
        </div>
      </div>
    </div>
  );
};

BadgeAbilities.propTypes = {
  badge: PropTypes.object,
  abilities: PropTypes.array.isRequired,
  achievedAbilities: PropTypes.array.isRequired,
  onAcknowledgementChange: PropTypes.func.isRequired
};

export default BadgeAbilities;
