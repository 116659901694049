export const TERMS_OF_SERVICE = 'Terms of Service';
export const TERMS_OF_SERVICE_INFO =
  'This Terms of Service Agreement (“Agreement”) governs use of online services of CareerPrepped, a service provided by MaxKnowledge, Inc. (“MaxKnowledge”, also referred to as “we”, “us”, or “our”), including but not limited to the CareerPrepped website';
export const TERMS_OF_SERVICE_INFO_CONTINUED =
  ', and all affiliated subdomains and portals, mobile applications, features, content, and products and services (collectively, “Site” or “Services”).';
export const MAXKNOWLEDGE_INFO =
  'MaxKnowledge reserves the right to update or make changes to this Agreement from time to time and may provide you with notice of such changes by any reasonable means, including without limitation, by posting the revised version of this Agreement on our Site. Your access to or use of the Site following changes to this Agreement will constitute your acceptance of those changes.';
export const AGREEMENT_QUESTION =
  'If you have any questions regarding this Agreement, please email us at';

export const PRIVACY_POLICY = 'Privacy Policy';
export const PRIVACY_POLICY_INFO =
  'explains how we treat your personal data and protect your privacy when you use our Services. By using our Services, you agree that MaxKnowledge can use such data in accordance with our Privacy Policy.';

export const USING_SERVICE = 'USING OUR SERVICES';
export const USING_SERVICE_INFO = 'Subject to this Agreement, MaxKnowledge grants you a limited, non-exclusive, non-transferable, and revocable license to use our Services. To use our Services, you will need to register and obtain an account. You are solely responsible for maintaining the confidentiality of your account, username and password (collectively, your "Account") and for all activities associated with or occurring under your Account. You represent and warrant that your Account information will be accurate at all times and you agree not to misclassify your Account. You must notify us immediately of any unauthorized use of your Account and any other breach of security.';
export const USING_SERVICE_INFO_PART1 =
  'We provide users of our Site with access to a variety of tools and resources. The quality of streaming content, as well as the download speed of downloadable content, may be affected by a variety of factors such as your location, the content being streamed or downloaded, and the speed of your Internet connection. MaxKnowledge makes no representation or warranty regarding access to content available through the Site, including the quality of streaming content and the download speed of downloadable content.';
export const USING_SERVICE_INFO_PART2 =
  'The content provided through our Site is designed to provide practical and useful information on the subject matter covered. We attempt to ensure that information provided on or in connection with the Site is complete, accurate, and current. Despite our efforts, such information may occasionally be inaccurate, incomplete, or out of date. Accordingly, we make no representation as to the completeness or accuracy of such information, including all descriptions, images, references, features, and content described on the Site. Such information is also subject to change at any time without notice. It is your responsibility to ascertain and obey all applicable local, state, federal, and international laws in regard to the use of any information from the Site.';
export const USING_SERVICE_INFO_PART3 =
  'You may download content from our Site only for your personal, non-commercial use, unless you obtain MaxKnowledge’s written permission to otherwise use the content. Schools that sponsor their students may download content from our site for uses related to their students use of Services. Using our Services does not give you ownership of any intellectual property rights in any part of our Site or Services.';

export const HONOUR_CODE = 'HONOR CODE';
export const CODE_OF_CONDUCT =
  'You agree to abide by the following code of conduct:';
export const CODE_OF_CONDUCT_PART1 = 'You will register for only one account.';
export const CODE_OF_CONDUCT_PART2 =
  'You represent and warrant that all information submitted to our Site in connection with such registration is complete and accurate.';
export const CODE_OF_CONDUCT_PART3 =
  'You will not engage in any activities that will misrepresent your achievements or dishonestly misrepresent the achievements of others.';

export const USER_CONDUCT = 'USER CONDUCT';
export const USER_CONDUCT_INFO =
  'You must comply with all applicable laws, rules, and regulations while accessing or using the Site. In addition, we expect users to respect the rights and dignity of others.';

export const SITE_INFO =
  'You must not post, transmit, or otherwise make available on our Site:';
export const SITE_INFO_PART1 =
  'Anything that could be threatening, harassing, discriminatory, degrading, hateful, intimidating, defamatory, fraudulent, or otherwise tortious;';
export const SITE_INFO_PART2 =
  'Anything that could give rise to criminal or civil liability or that encourages conduct that would constitute a criminal offense, including any material protected by copyright, trademark, trade secret, right of publicity, or any other proprietary right for which you do not have the express prior consent of the owner of such right or in violation of any contractual, fiduciary, or other legal obligation;';
export const SITE_INFO_PART3 =
  'Any virus, worm, Trojan horse, spyware, or other computer code, file, or program that is potentially harmful or invasive, or is intended to damage or hijack the operation of, or to monitor the use of, any hardware, software, or equipment;';
export const SITE_INFO_PART4 = 'Any unsolicited or unauthorized advertising, promotional material, "junk mail," "spam," "chain letter," "pyramid scheme," investment opportunity, or other form of solicitation.';

export const SITE_INFO_PART5 = 'Furthermore, you must not:';
export const SITE_INFO_PART6 =
  'Interfere with or disrupt the operation of the Site (including any action that imposes an unreasonable or disproportionately large load on the Site) or violate any requirements, procedures, policies, or regulations of the servers or networks used to make our Services available;';
export const SITE_INFO_PART7 =
  'Modify, adapt, make derivate works of, translate, reverse engineer, decompile, or disassemble any part of our Site or Services;';
export const SITE_INFO_PART8 =
  'Remove any copyright, trademark, or other proprietary rights notice from the Site or any materials available through the Site;';
export const SITE_INFO_PART9 =
  'Frame or mirror any part of the Site without our express prior written consent;';
export const SITE_INFO_PART10 =
  'Systematically download or store content from the Site;';
export const SITE_INFO_PART11 = 'Use any robot, spider, site search/retrieval application or other manual or automatic device to retrieve, index, “scrape,” “data mine” or in any way gather content of the Site or reproduce or circumvent the navigational structure or presentation of the Site. Notwithstanding the foregoing, MaxKnowledge grants the operators of public search engines permission to use spiders to copy materials from the Site for the sole purpose of, and solely to the extent necessary for, creating publicly available searchable indices of the materials, but not caches or archives of such materials. MaxKnowledge reserves the right to revoke these exceptions either generally or in specific cases.';

export const COPYRIGHT = 'COPYRIGHT';
export const COPYRIGHT_INFO_PART1 =
  'For any content that you make available on our Site, you hereby grant to us a worldwide, royalty-free, non-exclusive, irrevocable, transferable, and fully sub-licensable license, to reproduce, distribute, perform and display, adapt, or modify in any format or media, in each case, solely to the extent necessary for us to provide the Services to you.';
export const COPYRIGHT_INFO_PART2 =
  'All content and software on our Site is the property of MaxKnowledge and its licensors. No material (including but not limited to text, images, audio, video, and any software-related files) may be copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way or decompiled, reverse engineered, or disassembled, except that one copy of your training materials may be printed for your own personal, non-commercial use.';
export const COPYRIGHT_INFO_PART3 =
  'The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides recourse for copyright owners who believe that material appearing on the Internet infringes their rights under U.S. copyright law. If you believe in good faith that materials available on our Site infringe your copyright, you may send MaxKnowledge a written notice by email requesting that MaxKnowledge remove the material or block access to it. If you believe in good faith that someone has wrongly filed a notice of copyright infringement against you, the DMCA permits you to send MaxKnowledge a counter-notice. Notices and counter-notices must meet the then-current statutory requirements imposed by the DMCA. Please visit http://www.copyright.gov for details.';

export const TRADEMARKS = 'TRADEMARKS';
export const TRADEMARKS_INFO =
  'Trade names, trademarks, and service marks on our Site, including without limitation CareerPrepped, the CareerPrepped logo, MaxKnowledge, and the MaxKnowledge logo are owned by MaxKnowledge. Such trade names, trademarks, and service marks, whether registered or unregistered, may not be used in connection with any product or service that is not ours, or in any manner that is likely to cause confusion. Nothing contained on our Site should be construed as granting, by implication, estoppel or otherwise, any license or right to use any such trade names, trademarks, or service marks without the express prior written consent of the owner.';

export const PURCHASES = 'PURCHASES';
export const PURCHASES_INFO =
  'We use third-party service providers to enable e-commerce functionality on our Site for purchase of fee-based products and services. If you wish to purchase any product or service made available by us through the Site, you may be asked to supply certain information relevant to your transaction, including your credit card number information. You represent and warrant that you have the legal right to use any credit card(s) utilized in connection with any transaction. By submitting such information, you grant to MaxKnowledge the right to provide such information to third parties for purposes of facilitating the completion of transactions initiated by you or on your behalf. Verification of information may be required prior to the acknowledgment or completion of any transaction.';

export const CANCEL_SUBSCRIPTION = 'CANCELING YOUR SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_INFO =
  'You may cancel your subscription at any time.  After you cancel, you may access the services that you purchased until your subscription ends.  CareerPrepped does not make partial refunds for the remainder of your subscription term.';

export const DISCLAIMER = 'DISCLAIMER OF WARRANTIES';
export const DISCLAIMER_INFO_PART1 =
  'Our Services are provided to you “as is” without any warranties of any kind. You agree that you must evaluate, and that you bear all risks associated with the use of, our Services, including without limitation, any reliance on the accuracy, completeness, or usefulness of any materials available through the Site. MaxKnowledge disclaims all warranties with respect to the Site to the fullest extent permissible under applicable law, including the warranties of merchantability, fitness for a particular purpose, noninfringement, and title.';
export const DISCLAIMER_INFO_PART2 =
  'While we try to maintain the integrity and security of the Site and the servers from which the Site and Services are operated, we do not guarantee that the Site or Services will be or remain secure, complete or correct, or that access to the Site or Services will be uninterrupted.';
export const DISCLAIMER_INFO_PART3 =
  'While on our Site, you could be directed to other web sites or resources. MaxKnowledge has no control over such sites and resources and is not responsible for the availability of such external sites or resources, and does not endorse and is not responsible or liable for any content, advertising, products, or other materials on or available from such sites or resources.';

export const LIMITATION = 'LIMITATION OF LIABILITY';
export const LIMITATION_INFO =
  'MaxKnowledge will not be liable for any indirect, incidental, consequential, special, exemplary, or punitive damages of any kind, under any contract, negligence, strict liability or other theory, including without limitation, damages for loss of security of information, loss of revenues or profits, loss of data or use, or other intangible losses. In particular, and without limitation, MaxKnowledge will not be liable for damages of any kind resulting from your access to or use of, or inability to access or use the Site, or from any content posted on the Site by MaxKnowledge or any third party. Your sole and exclusive remedy for dissatisfaction with the Site is to stop using our Site and Services. The maximum liability of MaxKnowledge for all damages, losses, and causes of action, whether in contract, tort (including without limitation negligence) or otherwise, shall be the total amount, if any, paid by you to MaxKnowledge to access and use the Site during the past six months.';

export const INDEMNIFICATION = 'INDEMNIFICATION';
export const INDEMNIFICATION_INFO =
  'Except to the extent prohibited under applicable law, you agree to defend, indemnify, and hold harmless MaxKnowledge against all claims, losses, costs, and expenses (including attorneys’ fees) made by any third party related to (a) your use of the Site or activities in connection with the Site; (b) any violation or alleged violation of this Agreement by you; or (c) any claim that any of your submissions or any use or exploitation thereof caused damage to or infringed upon or violated the rights of a third party, including past, present, or future infringement, misappropriation, libel, defamation, invasion of privacy or right of publicity, or violation of any right related to the foregoing.';

export const GOVERNING_LAW = 'GOVERNING LAW AND ARBITRATION';
export const GOVERNING_LAW_INFO =
  'This Agreement is governed by the laws of the State of California, County of Orange, without regard to its conflicts of law provisions, and regardless of your location. All disputes arising out of or related to this Agreement or any aspect of the relationship between you and MaxKnowledge, whether based in contract, tort, statute, fraud, misrepresentation or any other legal theory, shall be resolved through final and binding arbitration before a neutral arbitrator instead of in a court by a judge or jury and, by your access to or use of this Site, you agree that you and MaxKnowledge are each waiving the right to trial by a jury. By your access to or use of the Site, you agree that any arbitration under this Agreement will take place on an individual basis. The arbitration will be administered by the American Arbitration Association under its Commercial Arbitration Rules, as amended by this Agreement. Any in-person hearings or appearances shall be held in County of Orange, State of California, U.S.A. Arbitration proceedings shall be conducted in a manner that preserves confidentiality. The arbitrator\'s decision shall follow this Agreement and shall be final and binding.';
