import PropTypes from 'prop-types';
import React from 'react';

const BadgeFeedback = ({feedbackArray, onChangeFeedback, category}) => {
  const filteredFeedback = feedbackArray.filter(feedback => feedback.choices.length > 0 );
  const additionalFeedback = feedbackArray.find(feed => feed.choices.length === 0);

  return (
    <div className="claim-item">
      <h5 className="mb-2">
        <strong>Please complete the short survey below to get your badge</strong>
        <span className="small text-danger"/>
      </h5>
      {
        filteredFeedback.map((feedback, index) => {
          return(
            <div key={feedback.question}>
              <p className="mt-2">
                <strong>{feedback.questionText}{index+1 < 3 ? ` ${category.title}.`: ''}</strong>
              </p>
              {feedback.choices.map((choice, index) =>
                (<p key={index} className="mb-0">
                  <label>
                    <input
                      type="radio"
                      name={feedback.question}
                      checked={feedback.feedback === choice}
                      onChange={onChangeFeedback}
                      value={choice}/>&nbsp;&nbsp;{choice}
                  </label>
                </p>))}
            </div>
          );
        })
      }

      {
        additionalFeedback &&
          <div>
            <p className="mt-2">
              <strong>{additionalFeedback.questionText}:</strong> (optional)
            </p>
            <textarea
              className="form-control"
              name={additionalFeedback.question}
              rows="8"
              cols="80"
              onChange={onChangeFeedback}
              value={additionalFeedback.feedback}
              placeholder="Please tell us what you liked about this Skill Builder and what you didn’t like. If you’ve noticed anything that we need to remedy, please be specific. We’d appreciate any suggestions to improve any part of this Skill Builder, including the reflect & practice content, the engage & connect feature, or the badge claiming process."/>
          </div>
      }


    </div>
  );
};

BadgeFeedback.propTypes = {
  feedbackArray: PropTypes.array,
  onChangeFeedback: PropTypes.func,
  category: PropTypes.object
};

export default BadgeFeedback;
