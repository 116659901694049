import { CALL_API } from '../../middlewares/api';

import {
  RESEND_FEEDBACK_FAILURE,
  RESEND_FEEDBACK_SUCCESS,
  RESEND_FEEDBACK_REQUEST,
  IGNORE_FEEDBACK_FAILURE,
  IGNORE_FEEDBACK_SUCCESS,
  IGNORE_FEEDBACK_REQUEST,
  GET_GIVEN_FEEDBACK_LIST_REQUEST,
  GET_GIVEN_FEEDBACK_LIST_SUCCESS,
  GET_GIVEN_FEEDBACK_LIST_FAILURE,
  GET_GIVEN_COMPLETED_FEEDBACK_LIST_REQUEST,
  GET_GIVEN_COMPLETED_FEEDBACK_LIST_SUCCESS,
  GET_GIVEN_COMPLETED_FEEDBACK_LIST_FAILURE,
  GET_RECEIVED_FEEDBACK_LIST_REQUEST,
  GET_RECEIVED_FEEDBACK_LIST_SUCCESS,
  GET_RECEIVED_FEEDBACK_LIST_FAILURE,
  GET_REQUESTED_FEEDBACK_LIST_REQUEST,
  GET_REQUESTED_FEEDBACK_LIST_SUCCESS,
  GET_REQUESTED_FEEDBACK_LIST_FAILURE,
  SEND_FEEDBACK_REQUEST_REQUEST,
  SEND_FEEDBACK_REQUEST_SUCCESS,
  SEND_FEEDBACK_REQUEST_FAILURE,
  GET_GIVEN_FEEDBACK_LIST_REQUEST_BY_RESOURCE,
  GET_GIVEN_FEEDBACK_LIST_SUCCESS_BY_RESOURCE,
  GET_GIVEN_FEEDBACK_LIST_FAILURE_BY_RESOURCE,
  REMOVE_CANCELLED_FEEDBACK_REQUEST,
  REMOVE_CANCELLED_FEEDBACK_SUCCESS,
  REMOVE_CANCELLED_FEEDBACK_FAILURE,
  GET_IR_RANK_REQUEST,
  GET_IR_RANK_SUCCESS,
  GET_IR_RANK_FAILURE,
  GET_PRAISE_REQUEST,
  GET_PRAISE_SUCCESS,
  GET_PRAISE_FAILURE,
  CLEAR_MYFEEDBACK_SUCCESS,
} from '../../constants';

export const resendFeedbackRequest = (requestId) => {
  return {
    [CALL_API]: {
      method: 'patch',
      authenticated: true,
      endpoint: `feedback/resend-feedback-request/${requestId}`,
      types: [
        RESEND_FEEDBACK_REQUEST,
        RESEND_FEEDBACK_SUCCESS,
        RESEND_FEEDBACK_FAILURE,
      ],
    },
  };
};

export const ignoreFeedbackRequest = (requestId, status) => {
  return {
    [CALL_API]: {
      data: { status },
      method: 'patch',
      authenticated: true,
      endpoint: `feedback/cancel-feedback/${requestId}`,
      types: [
        IGNORE_FEEDBACK_REQUEST,
        IGNORE_FEEDBACK_SUCCESS,
        IGNORE_FEEDBACK_FAILURE,
      ],
    },
  };
};

export const removeCancelledFeedbackRequest = (requestId) => {
  return {
    [CALL_API]: {
      method: 'delete',
      authenticated: true,
      endpoint: `feedback/feedback_request/${requestId}`,
      types: [
        REMOVE_CANCELLED_FEEDBACK_REQUEST,
        REMOVE_CANCELLED_FEEDBACK_SUCCESS,
        REMOVE_CANCELLED_FEEDBACK_FAILURE,
      ],
    },
  };
};

export const getGivenFeedbacks = (userid, _links, param = null) => {
  let endpoint = _links
    ? _links.replace(/^http[s]?:\/\/.+?\//, '')
    : `feedback/feedbacks?user_type=giver&user_id=${userid}&feedback_status=pending&resource_type=all`;

  if(param){
    endpoint = endpoint.concat(param);
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        GET_GIVEN_FEEDBACK_LIST_REQUEST,
        GET_GIVEN_FEEDBACK_LIST_SUCCESS,
        GET_GIVEN_FEEDBACK_LIST_FAILURE,
      ],
    },
  };
};

export const getGivenCompletedFeedbacks = (userid, _links, param = null) => {
  let endpoint = _links
    ? _links.replace(/^http[s]?:\/\/.+?\//, '')
    : `feedback/feedbacks?user_type=giver&user_id=${userid}&feedback_status=completed`;

  if(param){
    endpoint = endpoint.concat(param);
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        GET_GIVEN_COMPLETED_FEEDBACK_LIST_REQUEST,
        GET_GIVEN_COMPLETED_FEEDBACK_LIST_SUCCESS,
        GET_GIVEN_COMPLETED_FEEDBACK_LIST_FAILURE,
      ],
    },
  };
};

export const getGivenFeedbacksByResource = (
  type,
  userid,
  resource_id,
  user_type,
  param = null,
) => {
  let endpoint = `feedback/feedbacks?resource_type=${type}&user_id=${userid}
       &resource_id=${resource_id}&user_type=${user_type}&feedback_status=completed`;

  if(param){
    endpoint = endpoint.concat(param);
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        GET_GIVEN_FEEDBACK_LIST_REQUEST_BY_RESOURCE,
        GET_GIVEN_FEEDBACK_LIST_SUCCESS_BY_RESOURCE,
        GET_GIVEN_FEEDBACK_LIST_FAILURE_BY_RESOURCE,
      ],
    },
  };
};

export const getRequestedFeedbacks = (userid, _links, param = null) => {
  let endpoint = _links
    ? _links.replace(/^http[s]?:\/\/.+?\//, '')
    : `feedback/feedbacks?user_type=requester&user_id=${userid}`;

  if(param){
    endpoint = endpoint.concat(param);
  }else{
    endpoint = `${endpoint}&feedback_status=completed,canceled,matching,pending&resource_type=all`;
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        GET_REQUESTED_FEEDBACK_LIST_REQUEST,
        GET_REQUESTED_FEEDBACK_LIST_SUCCESS,
        GET_REQUESTED_FEEDBACK_LIST_FAILURE,
      ],
    },
  };
};

export const getReceivedFeedbacks = (userid, _links, param = null) => {
  let endpoint = _links
    ? _links.replace(/^http[s]?:\/\/.+?\//, '')
    : `feedback/feedbacks?user_type=requester&user_id=${userid}&feedback_status=completed&resource_type=all`;

  if(param){
    endpoint = endpoint.concat(param);
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        GET_RECEIVED_FEEDBACK_LIST_REQUEST,
        GET_RECEIVED_FEEDBACK_LIST_SUCCESS,
        GET_RECEIVED_FEEDBACK_LIST_FAILURE,
      ],
    },
  };
};

export const sendFeedbackRequest = (data) => {
  return {
    [CALL_API]: {
      data,
      method: 'post',
      authenticated: true,
      endpoint: 'feedback/feedback_request',
      types: [
        SEND_FEEDBACK_REQUEST_REQUEST,
        SEND_FEEDBACK_REQUEST_SUCCESS,
        SEND_FEEDBACK_REQUEST_FAILURE,
      ],
    },
  };
};

export const getIRRank = () => {
  const endpoint = 'user/feedback-rank';

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [GET_IR_RANK_REQUEST, GET_IR_RANK_SUCCESS, GET_IR_RANK_FAILURE],
    },
  };
};

export const getFeedbackTopPraises = () => {
  const endpoint = 'feedback-praise';

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [GET_PRAISE_REQUEST, GET_PRAISE_SUCCESS, GET_PRAISE_FAILURE],
    },
  };
};

export const clearMyFeedback = (feedbackStatus) => {
  return dispatch => dispatch({type: CLEAR_MYFEEDBACK_SUCCESS, status: feedbackStatus});
};
