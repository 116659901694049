import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import { withHooks } from '../../utils/withHooks';

import * as discussionsActions from '../../redux/actions/discussions';
import * as commentsActions from '../../redux/actions/comments';
import * as skillBuildersActions from '../../redux/actions/skill-builders';

import { Helmet } from 'react-helmet';
import { toastr } from 'react-redux-toastr';
import Post from '../../containers/skill-builders/discussions/Post';
import StickyDiv from '../common/stickyDiv/StickyDiv';
import ReflectQuestionList from '../../components/skill-builders/essentials/ReflectQuestionList';

class Discussions extends Component {
  static propTypes = {
    unit: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    params: PropTypes.object,
    discussions: PropTypes.object,
    isAuthenticated: PropTypes.bool.isRequired,
    location: PropTypes.object,
    pathname: PropTypes.string,
    route: PropTypes.object,
    isRequestingUnit: PropTypes.bool.isRequired,
    navigate: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      isRequestingSort: false,
      sort: 'date',
      shouldCallAPIInitially: true,
      path: '',
      unitTitle: '',
      noSection: false
    };

    this.callAPI = this.callAPI.bind(this);
    this.fetchSortedDiscussions = this.fetchSortedDiscussions.bind(this);
    this.handleBeforeSorting = this.handleBeforeSorting.bind(this);
    this.initializeStore = this.initializeStore.bind(this);
  }

  componentWillMount(){
    this.props.actions.initializeDiscussionsState();
  }

  componentDidMount(){
    const { params, isAuthenticated, navigate } = this.props;

    if(!isAuthenticated){
      const redirectPath = 'skill-builders';
      toastr.error('Permission is Needed.', 'You must be signed in to join the discussion.');
      return navigate(`/${redirectPath}/${params.slug}`);
    }

    this.props.actions.unitRequest(params.slug, isAuthenticated);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEmpty(nextProps.unit) && nextProps.unit !== this.props.unit) {
      const unitTitle = `${nextProps.unit.code} ${nextProps.unit.title}`;

      this.setState({
        unitTitle,
        path: nextProps.location.pathname
      });
    }

    if (`${nextProps.unit.sections}`.trim() === '')
      return this.setState({
        noSection: true,
        claimBadgeText: 'Unit Incomplete'
      });
  }

  componentWillUpdate(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname)
      this.setState({
        path: nextProps.location.pathname
      });
  }

  componentDidUpdate(prevProps) {
    const { params, actions } = this.props;

    if ((params.postId !== prevProps.params.postId) && (!isUndefined(params.postId))) {
      actions.initializeDiscussionsState();
      actions.unitDiscussionRequest(params.postId);
    }
  }

  handleBeforeSorting(event) {
    const sort = event.target.id;

    if (sort === this.state.sort)
      return toastr.warning(`Already sorted by ${sort}`);

    this.setState({
      shouldCallAPIInitially: false,
      isRequestingSort: true,
      sort
    }, this.fetchSortedDiscussions);

    this.initializeStore();
  }

  initializeStore() {
    const {
      actions
    } = this.props;

    actions.initializeDiscussionsState();
    actions.initializeComments();
  }

  fetchSortedDiscussions() {
    const { isAuthenticated, unit, actions } = this.props;
    const {
      sort
    } = this.state;

    const unitId = unit.id;
    actions.unitDiscussionsRequest(unitId, isAuthenticated, 1, sort)
      .then(() => {
        this.setState({
          isRequestingSort: false
        });
      });
  }

  callAPI(pageNo) {
    const { isAuthenticated, unit, actions, params } = this.props;
    const {
      sort
    } = this.state;

    const unitId = unit.id;

    if (!isUndefined(params.postId)) {
      return actions.unitDiscussionRequest(params.postId);
    }

    return actions.unitDiscussionsRequest(unitId, isAuthenticated, pageNo, sort);
  }

  render() {
    const { unit, isAuthenticated, discussions, isRequestingUnit, params } = this.props;

    const { page_count, page } = discussions.paginationData;
    const {
      isRequestingSort,
      shouldCallAPIInitially,
      path,
      unitTitle,
      noSection,
      sort
    } = this.state;

    return (
      <div>
        <Helmet
          title={`Skill Builders - Engage & Connect - ${unit.code} ${unit.title}`}/>

        <div className="container">
          <div className="row" style={{ marginTop: 30, marginBottom: 30 }}>
            <StickyDiv
              component={ReflectQuestionList}
              startId={'essHeader'}
              endId={'essential-wrapper'}
              gap={30}
              defaultCss={'intro-header container engage mb-0'}
              unit={unit}
              slug={params.slug}
              unitTitle={unitTitle}
              path={path}
              isRequesting={isRequestingUnit}
              isAuthenticated={isAuthenticated}
              noSection={noSection}/>

          </div>

          {!isEmpty(unit) && isAuthenticated &&
            <Post
              route={this.props.route}
              unit={unit}
              activeSort={sort}
              isRequestingSort={isRequestingSort}
              handleBeforeSorting={this.handleBeforeSorting}
              callAPI={this.callAPI}
              page_count={page_count}
              page={page}
              initializeStore={this.initializeStore}
              shouldCallAPIInitially={shouldCallAPIInitially}/>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  discussions,
  auth: { isAuthenticated },
  profile: { data: profile },
  skillBuilders: { unit, isRequestingUnit }
}) => ({
  unit,
  profile,
  isRequestingUnit,
  discussions,
  isAuthenticated
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...commentsActions,
    ...discussionsActions,
    ...skillBuildersActions
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Discussions));
