import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toastr } from 'react-redux-toastr';
import { Helmet } from 'react-helmet';
import ContactForm from '../../components/contact/ContactForm';
import Validator from '../../validator';

import * as contactUsActions from '../../redux/actions/contact';

class Contact extends Component {
  static propTypes = {
    contact: PropTypes.object.isRequired,
    contactState: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      contact: Object.assign({}, this.props.contact)
    };

    this.submit = this.submit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(nextProps){
    const { data } = this.props.contactState;
    const { isSubmitting: nextIsSubmitting, data: nextData } = nextProps.contactState;
    if (data !== nextData && nextData.status === 200 && !nextIsSubmitting) {
      const initState = {
        name: '',
        email: '',
        phoneNumber: '',
        subject: '',
        message: ''
      };

      this.setState({contact: Object.assign({}, initState)},
        () => toastr.success('Your query has been successfully submitted.'));
    }
  }

  isValid(field = null) {
    const { contact, errors: prevErrors } = this.state;

    let validate = Validator.createValidator({
      name: ['required', 'minLength|4'],
      email: ['required', 'email'],
      phoneNumber: ['phone'],
      subject: ['required'],
      message: ['required', 'minLength|8']
    }, contact, field);

    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  onChange(event) {
    const { name, value } = event.target;
    let { contact } = this.state;

    contact[name] = value;

    this.setState({ contact }, () => this.isValid(name));
  }

  submit(event) {
    event.preventDefault();

    let { contact } = this.state;

    if (! this.isValid()) return false;

    this.props.actions.contactRequest(contact);
  }


  render() {
    const { errors, contact } = this.state;
    const { isSubmitting } = this.props.contactState;
    return (
      <div className="container">
        <Helmet title="Contact"/>
        <div className="row">
          <ContactForm
            onSubmit={this.submit}
            onChange={this.onChange}
            isSubmitting={isSubmitting}
            contact={contact}
            errors={errors}/>
        </div>
      </div>

    );
  }
}

const mapStateToProps = (state) => {
  let contact = {
    name: '',
    email: '',
    phoneNumber: '',
    subject: '',
    message: ''
  };

  return {
    contact,
    contactState: state.contact
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({},contactUsActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
