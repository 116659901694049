import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import { parseJSON, uniqueId } from '../../../utils';
import Validator from '../../../validator';
import * as sectionActions from '../../../redux/actions/sections';
import * as unitActions from '../../../redux/actions/skill-builders';
import * as componentsActions from '../../../redux/actions/components';
import TextListForm from '../../../components/skill-builders/admin/TextListForm';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

class Rolling extends Component {
  static propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    formType: PropTypes.object,
    actions:PropTypes.object.isRequired,
    unit: PropTypes.object.isRequired,
    units: PropTypes.array.isRequired,
    sections: PropTypes.array.isRequired,
    section: PropTypes.object,
    isSubmitting: PropTypes.bool.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    isDeleting: PropTypes.bool.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      editListItem: {},
      resetEditorState: false,
      updateEditorState: false,
      editorState: EditorState.createEmpty(),
      section: Object.assign({}, this.props.section)
    };

    this.saveSection = this.saveSection.bind(this);
    this.addListItem = this.addListItem.bind(this);
    this.deleteSection = this.deleteSection.bind(this);
    this.updateSectionState = this.updateSectionState.bind(this);

    this.onEditListItem = this.editListItem.bind(this);
    this.setEditorState = this.setEditorState.bind(this);
    this.onDeleteListItem = this.deleteListItem.bind(this);
    this.getEditorState = editorState => this._getEditorState(editorState);
  }

  componentDidMount() {
    window.onbeforeunload = () => {
      return 'Changes you made might get lost!';
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = () => {
      return null;
    };
  }

  isValid(field = null) {
    const rules = {
      'title': ['required', 'minLength|1']
    };

    let validate = Validator.createValidator(rules, this.state.section, field);
    let { isValid, errors } = validate;

    let errorsObj = Object.assign({}, this.state.errors, errors);

    if (field && isUndefined(errors[field])) {
      delete errorsObj[field];
    }

    if (!field && isEmpty(errors)) {
      errorsObj = errors;
    }

    this.setState({ errors: errorsObj });

    return isValid;
  }

  setEditorState() {
    return this.state.editorState;
  }

  _getEditorState(editorState) {
    const currentContent = editorState.getCurrentContent();
    const plainText = currentContent.getPlainText();
    const section = Object.assign({}, this.state.section, { editorText: plainText });
    this.setState({ editorState, section });
  }

  addListItem(event) {
    event.preventDefault();

    const { editorState, section: currentSection, editListItem } = this.state;
    let { content } = currentSection;

    const currentContent = editorState.getCurrentContent();

    if (! currentContent.hasText()) 
      return toastr.error('List item cannot be blank.');

    const value = stateToHTML(currentContent);

    if (! isUndefined(editListItem.itemId)) {
      return this.setListItem(editListItem.itemId, value);
    }

    content.push({ itemId: uniqueId(), value });

    const section = Object.assign({}, currentSection, { content });

    this.setState({ section, resetEditorState: true }, () => {
      if (this.state.resetEditorState) {
        this.setState({ resetEditorState: false });
      }
    });
  }

  editListItem(event) {
    event.preventDefault();

    const itemId = event.target.closest('span').id;
    const { section } = this.state;
    const editListItem = find(section.content, item => item.itemId === itemId);
    const blocksFromHTML = convertFromHTML(editListItem.value);

    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    const editorState = EditorState.createWithContent(contentState);

    this.setState(
      { editorState, editListItem, updateEditorState: true, errors: {} },
      () => this.setState({ updateEditorState: false }));
  }

  setListItem(itemId, value, name) {
    const { section } = this.state;

    const newSection = Object.assign({}, section, {
      content: [
        ...section.content.map((item) => {
          if (item.itemId === itemId) {
            return Object.assign({}, item, { value });
          }

          return item;
        })
      ]
    });

    this.setState(
      { section: newSection, resetEditorState: true, editListItem: {} },
      () => {
        if (this.state.resetEditorState) {
          this.setState({ resetEditorState: false });
        }
      }
    );

    this.isValid(name);
  }

  setTitle(name, value) {
    const { section } = this.state;
    let newSection = Object.assign({}, section, { [name]: value });

    this.setState({ section: newSection }, () => this.isValid(name));
  }

  updateSectionState(event) {
    event.preventDefault();

    const { name, value } = event.target;

    if (name === 'title') {
      return this.setTitle(name, value);
    }
  }

  formatData() {
    const { section } = this.state;
    const { title, content: currentContent, sectiontype } = section;
    const contentArray = currentContent.map(item => item.value);
    const content = JSON.stringify({ title, content: contentArray });

    return { content, sectiontype, unit: this.props.unit.id };
  }

  saveSection(event) {
    event.preventDefault();

    const { section: sectionInState } = this.state;
    const { unit, units, section, sections, actions } = this.props;

    if (! this.isValid()) return;

    if (sectionInState.content.length < 2) 
      return toastr.error('Must contain atleast two list item');

    const formId = event.target.closest('form').id;
    const formIdArray = formId.split('_');
    const formIndex = parseInt(formIdArray[1]);
    const sectionData = this.formatData();

    if (section.id) {
      sectionData.id = section.id;

      const sectionIndex = sections.findIndex(sectionItem =>
        sectionItem.id === section.id);

      return actions.updateSection(sectionData, sectionIndex)
        .then(() => {
          this.handleResponse('Section updated.', section.id);
        });
    }

    const unitIndex = units.findIndex(unitItem => unitItem.id === unit.id);

    actions.saveSection(sectionData, unit, unitIndex)
      .then(() => {
        this.handleResponse('Section saved.', null, formIndex);
      });
  }

  handleResponse(message, sectionId, formIndex) {
    toastr.success(message);

    if (sectionId) {
      return this.props.actions.unloadUpdateForm(String(sectionId));
    }

    this.props.actions.unloadSectionForm({ id: formIndex });
  }

  deleteListItem(event) {
    event.preventDefault();

    const { section } = this.state;
    const { content } = section;
    const itemId = event.target.closest('span').id;

    const newSection = Object.assign({}, section, {
      content: content.filter(item => itemId !== item.itemId)
    });

    this.setState({ section: newSection });
  }

  deleteSection(event) {
    event.preventDefault();

    const { actions, section, unit, units } = this.props;

    actions.deleteSection(section.id)
      .then(() => {
        const sectionsArray = unit.sections.split(',');
        const sectionsList = [...sectionsArray.filter(id => id !== section.id)];

        const sectionsString = sectionsList.join();
        const unitData = Object.assign({}, unit);
        const unitIndex = units.findIndex(unitItem => unitItem.id === unit.id);

        unitData.sections = sectionsString;

        actions.updateUnit(unitData, unitIndex)
          .then(() => {
            toastr.success('Section deleted.');
            actions.unloadUpdateForm(String(section.id));
          });
      });
  }

  render() {
    const {
      isAdmin,
      onCancel,
      isSubmitting,
      isUpdating,
      formType,
      isDeleting
    } = this.props;

    const { 
      errors, 
      section, 
      resetEditorState, 
      updateEditorState,
      editListItem 
    } = this.state;

    return (
      <TextListForm
        headerTitle="Rolling list"
        isAdmin={isAdmin}
        isEditing={editListItem.itemId}
        errors={errors}
        section={section}
        onCancel={onCancel}
        isSubmitting={isSubmitting}
        isUpdating={isUpdating}
        isDeleting={isDeleting}
        formType={formType}
        onChange={this.updateSectionState}
        onSave={this.saveSection}
        listItemsLimit={15}
        resetEditorState={resetEditorState}
        updateEditorState={updateEditorState}
        onEditListItem={this.onEditListItem}
        onAddListItem={this.addListItem}
        onDeleteListItem={this.onDeleteListItem}
        onDelete={this.deleteSection}
        setEditorState={this.setEditorState}
        getEditorState={this.getEditorState}/>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let section = {
    sectiontype: 'bullet2',
    title: '',
    editorText: '',
    content: []
  };
  const { isSubmitting, isUpdating, isDeleting } = state.sections;

  if (ownProps.section) {
    const { id, content: sectionContent } = ownProps.section;
    const data = parseJSON(sectionContent);
    const { title, content: dataContent } = data;
    const content = dataContent.map(item => ({ itemId: uniqueId(), value: item }));

    section = Object.assign({}, section, { id, title, content });
  }

  return {
    section,
    unit: ownProps.unit,
    isSubmitting,
    sections: state.sections.data,
    units: state.skillBuilders.data,
    isUpdating: isUpdating.status,
    isDeleting: isDeleting.status
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    unitActions,
    sectionActions,
    componentsActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rolling);
