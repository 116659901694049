import React from 'react';

import { checkUrl, parseUrlString } from '../../utils';

import PropTypes from 'prop-types';

import VideoIframe from '../common/VideoIframe';
import DocsViewer from '../common/DocsViewer';
import IconBookmark from '../../../src/assets/images/icon-bookmark.svg';

const style = {
  position: 'relative',
};

const PortfolioPreview = ({ url, mimetype }) => {
  const imgFile = mimetype.includes('image');
  const videoFile = (checkUrl(url, 'youtube') || checkUrl(url, 'vimeo'));
  const docFile = mimetype.includes('application') || mimetype.includes('text');
  const webLink = (!imgFile && !docFile && !videoFile);

  return (
    <div className="portfolio-content" style={style}>
      {imgFile &&
        <div className="portfolio-file-image">
          <img
            src={url}
            alt="avatar"
            className="img-fluid"/>
        </div>}

      {webLink &&
        <div className="portfolio-weblink">
          <a href={url} target="_blank">
            <div className="portfolio-file-image">
              <img
                src={IconBookmark}
                alt="avatar"
                className="img-fluid"/>
            </div>
          </a>
        </div>}

      {docFile &&
        <DocsViewer
          width="100%"
          height="430px"
          fileUrl={url}/>}

      {videoFile &&
        <VideoIframe url={parseUrlString(url)?.url || ''}/>}
    </div>
  );
};

PortfolioPreview.propTypes = {
  url: PropTypes.string.isRequired,
  mimetype: PropTypes.string
};

export default PortfolioPreview;
