import { CALL_API } from '../../middlewares/api';
import {
  LOAD_ENDORSEMENT,
  UNLOAD_ENDORSEMENT,
  RESET_BADGE_ENDORSEMENT_REDUCER,
  ASK_ENDORSEMENT_REQUEST,
  ASK_ENDORSEMENT_SUCCESS,
  ASK_ENDORSEMENT_FAILURE,
  ENDORSEMENT_BY_ID_REQUEST,
  ENDORSEMENT_BY_ID_SUCCESS,
  ENDORSEMENT_BY_ID_FAILURE,
  CONFIRM_ENDORSEMENT_REQUEST,
  CONFIRM_ENDORSEMENT_SUCCESS,
  CONFIRM_ENDORSEMENT_FAILURE,
  ENDORSE_BADGE_REQUEST,
  ENDORSE_BADGE_SUCCESS,
  ENDORSE_BADGE_FAILURE,
} from '../../constants';

export const clearEndorsementState = () => {
  return { type: RESET_BADGE_ENDORSEMENT_REDUCER };
};

export const loadEndorsementRequest = (selected) => {
  return { type: LOAD_ENDORSEMENT, selected };
};

export const unloadEndorsementRequest = () => {
  return { type: UNLOAD_ENDORSEMENT };
};

export const loadEndorsement = (selected) => {
  return function (dispatch) {
    dispatch(loadEndorsementRequest(selected));
  };
};

export const requestBadgeEndorsements = (data) => {
  const endpoint = 'badge/endorsement';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        ASK_ENDORSEMENT_REQUEST,
        ASK_ENDORSEMENT_SUCCESS,
        ASK_ENDORSEMENT_FAILURE,
      ],
    },
  };
};

export const requestBadgeEndorsementsById = (id) => {
  const endpoint = `badge/request/${id}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [
        ENDORSEMENT_BY_ID_REQUEST,
        ENDORSEMENT_BY_ID_SUCCESS,
        ENDORSEMENT_BY_ID_FAILURE,
      ],
    },
  };
};

export const confirmBadgeEndorsement = (data, uniqueId) => {
  const endpoint = `badge/request/${uniqueId}`;

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'put',
      types: [
        CONFIRM_ENDORSEMENT_REQUEST,
        CONFIRM_ENDORSEMENT_SUCCESS,
        CONFIRM_ENDORSEMENT_FAILURE,
      ],
    },
  };
};

export const endorseBadge = (data) => {
  const endpoint = 'badge/endorse';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      types: [
        ENDORSE_BADGE_REQUEST,
        ENDORSE_BADGE_SUCCESS,
        ENDORSE_BADGE_FAILURE,
      ],
    },
  };
};

export const requestSkillEndorsementsById = (id) => {
  const endpoint = `skill/request/${id}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [
        ENDORSEMENT_BY_ID_REQUEST,
        ENDORSEMENT_BY_ID_SUCCESS,
        ENDORSEMENT_BY_ID_FAILURE,
      ],
    },
  };
};

export const confirmSkillEndorsement = (data, uniqueId) => {
  const endpoint = `skill/request/${uniqueId}`;

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'put',
      types: [
        CONFIRM_ENDORSEMENT_REQUEST,
        CONFIRM_ENDORSEMENT_SUCCESS,
        CONFIRM_ENDORSEMENT_FAILURE,
      ],
    },
  };
};

export const requestSkillEndorsements = (data) => {
  const endpoint = 'skill/endorsement';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        ASK_ENDORSEMENT_REQUEST,
        ASK_ENDORSEMENT_SUCCESS,
        ASK_ENDORSEMENT_FAILURE,
      ],
    },
  };
};