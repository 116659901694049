import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Helmet } from 'react-helmet';

class Connect extends Component {
  static propTypes = {
    profile: PropTypes.object.isRequired
  }

  render() {
    return (
      <div>
        <Helmet title="Connect"/>
        <div className="container connect">
          <div className="row">
            <div className="content">
              <h2>Connect</h2>
              <div className="row parent">
                <div className="col-lg-6 col-md-6 child">
                  <div className="connect-box invite-all">
                    <p>
                      Build your followers and start following people you know by inviting them to connect with you on CareerPrepped.
                    </p>
                    <div className="form-group">
                      <textarea name="name"
                        className="form-control input-styles-owht"
                        rows="4"
                        cols="80"/>
                    </div>
                    <p>Import contacts:</p>
                    <div className="connect-toolbox">
                      <a href="#">
                        <img
                          src="../../assets/images/ico-outlook.jpg"
                          width="22" height="22" alt="" />
                        Outlook
                      </a>
                      <a href="#">
                        <img
                          src="../../assets/images/ico-gmail.jpg"
                          width="22" height="22" alt="" />
                        Gmail
                      </a>
                      <a href="#">
                        <img
                          src="../../assets/images/ico-yahoo.jpg"
                          width="22"
                          height="22" alt="" />
                        Yahoo
                      </a>
                      <a href="#">
                        <img
                          src="../../assets/images/ico-aol.jpg"
                          width="22"
                          height="22" alt="" />
                        Aol
                      </a>
                    </div>
                    <div className="text-right">
                      <button
                        type="button"
                        name="button"
                        className="btn btn-primary">
                        Send invite(s)
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 child">
                  <div className="connect-box invite-advisor">
                    <p>
                      See if career advisors from your institution are already on CareerPrepped to connect with them so you don’t miss out on important career advice, news, and information.
                    </p>
                    <div className="form-group">
                      <textarea
                        name="name"
                        className="form-control input-styles-owht"
                        rows="4" cols="80"/>
                    </div>
                    <div className="text-right">
                      <button
                        type="button"
                        name="button"
                        className="btn btn-primary">
                        Send invite(s)
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ profile: { data: profile } }) => ({ profile });

export default connect(mapStateToProps)(Connect);
