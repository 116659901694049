import React, { Component } from 'react';

import { withHooks } from '../../utils/withHooks';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

class AdminJobCenterWrapper extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired
  }

  constructor(props, context) {
    super(props, context);
  }

  render() {
    const { location: { state: routeState, pathname } } = this.props;

    const pageName = pathname.includes('job-tracker') || pathname.includes('job-details') ? 'Jobs' : 'Resumes';

    return (
      <div>
        <Helmet title="Job Search"/>

        <div className="admin-navbar admin-panel" style={{ padding:16 }}>
          <Container>
            <Row>
              <Col lg="8" md="7">
                <h3 className="mb-0">
                  {`${routeState.user.name}'s Saved ${pageName}`}
                </h3>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="container">
          <div className="row">
            <Outlet/>
          </div>
        </div>
      </div>
    );
  }
}

export default withHooks(AdminJobCenterWrapper);
