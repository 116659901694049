import PropTypes from 'prop-types';
import React from 'react';

import _isUndefined from 'lodash/isUndefined';

const ReadSpeakerButton = ({ sectionId, sectionClass, encodedUrl }) => {
  let endpoint = `//app-na.readspeaker.com/cgi-bin/rsent?customerid=7139&lang=en_au&readid=${sectionId}&url=${encodedUrl}`;

  if (!_isUndefined(sectionClass)) {
    endpoint = `//app-na.readspeaker.com/cgi-bin/rsent?customerid=7139&lang=en_au&readclass=${sectionClass}&url=${encodedUrl}`;
  }

  const buttonId = _isUndefined(sectionId) ? sectionClass : sectionId;

  return (
    <div className="readspeaker-container">
      <div
        id={`readspeaker-button-${buttonId}`}
        className="rs_skip rsbtn rs_preserve">

        <a
          rel="nofollow"
          className="rsbtn_play"
          accessKey="L"
          title="Listen to this content"
          href={endpoint}>
          <span className="rsbtn_left rsimg rspart">
            <span className="rsbtn_text">
              <span>Listen</span>
            </span>
          </span>
          <span className="rsbtn_right rsimg rsplay rspart"/>
        </a>

      </div>
    </div>
  );
};

ReadSpeakerButton.propTypes = {
  sectionId: PropTypes.string,
  sectionClass: PropTypes.string,
  encodedUrl: PropTypes.string.isRequired
};

export default ReadSpeakerButton;
