import { CALL_API } from '../../middlewares/api';

import {
  PEERS_REQUEST,
  PEERS_SUCCESS,
  PEERS_FAILURE
} from '../../constants';

export const peersRequest = (page = 1, query = '') => {
  let endpoint = `user/peers?page=${page}`;

  if (query.length > 0) {
    endpoint = `${endpoint}&query=${query}`;
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        PEERS_REQUEST,
        PEERS_SUCCESS,
        PEERS_FAILURE
      ]
    }
  };
};
