import React from 'react';

import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import TextArea from '../../common/TextArea';
import _isEmpty from 'lodash/isEmpty';
import ImagePreview from '../../common/ImagePreview';

const pinStyles = {
  avatarInput: {
    position: 'absolute',
  },
  label: {
    cursor: 'pointer'
  },
  closeIcon: {
    position: 'absolute',
    top: 5,
    right: 5,
    zIndex: 99,
    cursor: 'pointer',
    color: '#ff5000',
    fontSize: 20
  }
};

const ReplyForm = ({
  reply,
  profile,
  commentId,
  imagePreviewUrl,
  clearImagePreview,
  onDropFile,
  handleKeyPress,
  onChange,
  onEscape,
  errors,
  isForm
}) => {
  const form = `reply-form-commentId-${commentId}`;

  let wrapperClass = 'post-comment';

  if (!_isEmpty(errors)) {
    wrapperClass += ' ' + 'error';
  }

  const validMimeType = 'image/*';

  return(
    <div className="tertiary-post-comment clearfix">
      <div className="left">
        {
          profile.avatar && profile.avatar !== '' ?
            <img
              className="header-image small align-top"
              src={profile.avatar}
              alt="User avatar"/>:
            <span
              style={{
                lineHeight: '24px'
              }}
              className="header-image small align-top initial-avatar">
              {profile.name.slice(0,1)}
            </span>
        }
      </div>

      <div className="right">
        <form className={wrapperClass}>
          <div className="form-group">
            <div className="input-group">
              <TextArea
                inputId={form}
                className="form-control"
                name={'reply'}
                placeholder={'Write your reply...'}
                value={reply}
                onKeyPress={handleKeyPress}
                rows={'1'}
                onChange={onChange}
                autofocus />

              <label
                style={pinStyles.label}
                className="input-group-addon align-topm">
                <Dropzone
                  style={pinStyles.avatarInput}
                  multiple={false}
                  accept={validMimeType}
                  onDrop={onDropFile}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()}/>
                      </div>
                    </section>
                  )}
                </Dropzone>

                <a>
                  <i className="fa fa-paperclip"/>
                </a>
              </label>
            </div>
          </div>
        </form>
        <div className="share-stats">
          <span>Press Enter to post.</span>
        </div>


        {
          !_isEmpty(errors) &&
            <div className="error-feedback">
              {errors.reply || errors.updatedReply}
            </div>
        }

        <ImagePreview
          imagePreviewUrl={imagePreviewUrl}
          onCancel={clearImagePreview}
          closeIcon={pinStyles.closeIcon}
          tertiary/>

        {
          isForm &&
            <div className="share-stats">
              <span>
                Press Esc to {' '}
                <a
                  onClick={onEscape}
                  className="reply-button">
                  cancel
                </a>
                .
              </span>
            </div>
        }
      </div>
    </div>
  );
};

ReplyForm.propTypes = {
  reply: PropTypes.string,
  commentId: PropTypes.string,
  imagePreviewUrl: PropTypes.string,
  clearImagePreview: PropTypes.func,
  onDropFile: PropTypes.func,
  handleKeyPress: PropTypes.func,
  onChange: PropTypes.func,
  onEscape: PropTypes.func,
  errors: PropTypes.object,
  isForm: PropTypes.bool,
  profile: PropTypes.object,
};

export default ReplyForm;
