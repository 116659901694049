import React from 'react';

import PropTypes from 'prop-types';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';

const QACommonsLogo = require('../../assets/images/QA_logo.png');

const Header = ({
  mediaDropdownOpen,
  toggleMediaDropdown,
  whitePapersDropdownOpen,
  toggleWhitePapersDropdown
}) => {
  return (
    <section className="navigation-homepage qac">
      <div id="homepage-nav" className="navbar navbar-fixed-top scrolled">
        <div className="container">
          <a
            className="navbar-brand home"
            href="https://theqacommons.org/">
            <img src={QACommonsLogo} className="img-fluid img-responsive"/>
          </a>
          <ul className="nav navbar-nav float-sm-right hide-for-mobiles navbar-right">
            <li className="nav-item">
              <Dropdown isOpen={whitePapersDropdownOpen} toggle={toggleWhitePapersDropdown} direction="down" className="menu-dropdown">
                <DropdownToggle caret>CareerPrepped White Papers</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>
                    <a href="https://media.maxknowledge.com/careerprepped/CareerPrepped-Whitepaper-The-21st-Century-Career-Why-Perpetual-Career-Preparation-is-Necessary-to-Survive-and-Thrive-in-Todays-Economy.pdf" className="link-blue" target="_blank">21st Century Career</a>
                  </DropdownItem>
                  <DropdownItem>
                    <a href="https://media.maxknowledge.com/careerprepped/CareerPrepped-Whitepaper-Open-Badges-for-Empowerment_How-Open-Badges-Can-Be-Used-to-Recognize-and-Communicate-Hidden-Human-Capital.pdf" className="link-blue" target="_blank">Open Badges for Empowerment </a>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </li>
            <li className="nav-item">
              <Dropdown isOpen={mediaDropdownOpen} toggle={toggleMediaDropdown} direction="down" className="menu-dropdown">
                <DropdownToggle caret>Media</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>
                    <a href="https://global-competence-lessons.s3.us-west-2.amazonaws.com/Quality-Assurance-Commons-CareerPrepped.pdf" className="link-blue" target="_blank">CareerPrepped Overview</a>
                  </DropdownItem>
                  <DropdownItem>
                    <a href="https://global-competence-lessons.s3.us-west-2.amazonaws.com/Employability-Skills-Real-World-Examples-QA-Commons.pdf" className="link-blue" target="_blank">Real-World Examples</a>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </li>
            <li className="nav-item">
              <a href="https://theqacommons.org/" target="_blank" className="nav-link modal-link cursor-hand" style={{fontSize:18,lineHeight: 1.25}}>
                QA Commons
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

Header.propTypes = {
  mediaDropdownOpen: PropTypes.bool.isRequired,
  toggleMediaDropdown: PropTypes.func.isRequired,
  whitePapersDropdownOpen: PropTypes.bool.isRequired,
  toggleWhitePapersDropdown: PropTypes.func.isRequired
};

export default Header;
