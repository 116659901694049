import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  answer: {
    fontStyle: 'italic',
    marginTop: 16,
    marginBottom: 16,
    backgroundColor: '#7597c6',
    padding: 10,
    paddingLeft: 20,
    paddingRight: 10
  }
};

const ReflectionQuestionSection = ({
  nameOne,
  nameTwo,
  valueOne,
  valueTwo,
  questionOne,
  questionTwo,
  onReflectionEdit,
  onShareIndividualReflection,
  onDelete
}) => {
  return (
    <div className="question-item">
      <div className="question-prompt">
        <span>{questionOne || questionTwo}</span>
        <div className="question-prompt-edit">
          <a className="clickable" style={{color: 'inherit'}}>
            <i
              id={nameOne || nameTwo}
              onClick={onReflectionEdit}
              className="fa fa-pencil"/>
          </a>
          <a className="clickable" style={{color: 'inherit'}}>
            <i
              id={nameOne || nameTwo}
              onClick={onDelete}
              className="fa fa-trash ml-3"/>
          </a>
        </div>
      </div>

      <p style={styles.answer}>{valueOne || valueTwo}</p>
      <div className="question-item--button-group justify-content-end">
        <div>
          <button
            type="button"
            name="button"
            onClick={onShareIndividualReflection(nameOne || nameTwo)}
            className="btn btn-tertiary">
            <i className="fa fa-share mr-1"/> Share
          </button>
        </div>
      </div>
    </div>
  );
};

ReflectionQuestionSection.propTypes = {
  nameOne: PropTypes.string,
  nameTwo: PropTypes.string,
  valueOne: PropTypes.string,
  valueTwo: PropTypes.string,
  questionOne: PropTypes.string,
  questionTwo: PropTypes.string,
  onDelete: PropTypes.func,
  onReflectionEdit: PropTypes.func.isRequired,
  onShareIndividualReflection: PropTypes.func
};

export default ReflectionQuestionSection;
