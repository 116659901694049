import PropTypes from 'prop-types';
import React from 'react';

const UnitBadgeAbilities = ({acknowledgements}) => {
  const noAcknowledgements = acknowledgements && acknowledgements.length > 0;

  return (
    <div className="section-badge">
      <h6>Claimed Abilities</h6>
      <div className="section-badge-content">
        {!noAcknowledgements && <p className="no-badge-content">No acknowledgements available.</p>}

        {noAcknowledgements &&
          <ul>
            {acknowledgements.map((item, index)=><li key={index}>{item.acknowledgement}</li>)}
          </ul>}
      </div>
    </div>
  );
};

UnitBadgeAbilities.propTypes = {
  acknowledgements: PropTypes.array
};

export default UnitBadgeAbilities;
