import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const Message = ({ name, status }) => {
  return (
    <div className="text-center">
      <i
        className="fa fa-check-circle fa-4x my-2"
        style={{ color: '#60C455' }}/>
      <p className="font-weight-bold my-2">
        {status === 'pending'
          ? `You have successfully submitted feedback for ${name}`
          : `You have successfully revised feedback for ${name}`}
      </p>
      <Link className="respondLink" to={'/myfeedback'}>
        <Button className="sendButton my-2">Done</Button>
      </Link>
    </div>
  );
};

Message.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default Message;