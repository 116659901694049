import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ResumeSkills from './ResumeSkills';
import ResumeEducation from './ResumeEducation';
import ResumeExperience from './ResumeExperience';
import ResumeClubs from './ResumeClubs';
import ResumeCertifications from './ResumeCertifications';
import ResumeAwards from './ResumeAwards';
import ResumePublications from './ResumePublications';
import ResumeProjects from './ResumeProjects';
import ResumeAssociations from './ResumeAssociations';

class CreateResumeSection extends Component {
  static propTypes = {
    section: PropTypes.object.isRequired,
    resume: PropTypes.object.isRequired,
    onUnloadForm: PropTypes.func.isRequired,
    onLoadForm: PropTypes.func.isRequired,
    forms: PropTypes.array,
    index: PropTypes.number.isRequired,
    onChangeSection: PropTypes.func.isRequired,
    onChangeSectionTitle: PropTypes.func.isRequired,
    onRemoveResumeSection: PropTypes.func.isRequired
  }

  render() {
    const {
      forms,
      onUnloadForm,
      onLoadForm,
      onChangeSection,
      onChangeSectionTitle,
      section,
      resume,
      index,
      onRemoveResumeSection
    } = this.props;

    let sectionComponent;

    switch(section.name) {
    case 'Award':
      sectionComponent = (
        <ResumeAwards
          forms={forms}
          id={section.id}
          index={index}
          resumeAwards={resume.awards}
          onChangeSection={onChangeSection}
          onChangeSectionTitle={onChangeSectionTitle}
          onUnloadForm={onUnloadForm}
          onLoadForm={onLoadForm}
          onRemoveResumeSection={onRemoveResumeSection}/>
      );
      break;

    case 'Skills':
      sectionComponent = (
        <ResumeSkills
          forms={forms}
          id={section.id}
          index={index}
          resumeSkills={resume.skills}
          onChangeSection={onChangeSection}
          onChangeSectionTitle={onChangeSectionTitle}
          onUnloadForm={onUnloadForm}
          onLoadForm={onLoadForm}/>
      );
      break;

    case 'Education':
      sectionComponent = (
        <ResumeEducation
          forms={forms}
          id={section.id}
          index={index}
          resumeEducation={resume.education}
          onChangeSection={onChangeSection}
          onChangeSectionTitle={onChangeSectionTitle}
          onUnloadForm={onUnloadForm}
          onLoadForm={onLoadForm}/>
      );
      break;

    case 'Experience':
      sectionComponent = (
        <ResumeExperience
          forms={forms}
          id={section.id}
          index={index}
          resumeExperiences={resume.experience}
          onChangeSection={onChangeSection}
          onChangeSectionTitle={onChangeSectionTitle}
          onUnloadForm={onUnloadForm}
          onLoadForm={onLoadForm}/>
      );
      break;

    case 'Club':
      sectionComponent = (
        <ResumeClubs
          forms={forms}
          id={section.id}
          index={index}
          resumeClubs={resume.clubs}
          onChangeSection={onChangeSection}
          onChangeSectionTitle={onChangeSectionTitle}
          onUnloadForm={onUnloadForm}
          onLoadForm={onLoadForm}
          onRemoveResumeSection={onRemoveResumeSection}/>
      );
      break;

    case 'Certification':
      sectionComponent = (
        <ResumeCertifications
          forms={forms}
          id={section.id}
          index={index}
          resumeCertifications={resume.certifications}
          onChangeSection={onChangeSection}
          onChangeSectionTitle={onChangeSectionTitle}
          onUnloadForm={onUnloadForm}
          onLoadForm={onLoadForm}
          onRemoveResumeSection={onRemoveResumeSection}/>
      );
      break;

    case 'Publication':
      sectionComponent = (
        <ResumePublications
          forms={forms}
          id={section.id}
          index={index}
          resumePublications={resume.publications}
          onChangeSection={onChangeSection}
          onChangeSectionTitle={onChangeSectionTitle}
          onUnloadForm={onUnloadForm}
          onLoadForm={onLoadForm}
          onRemoveResumeSection={onRemoveResumeSection}/>
      );
      break;

    case 'Project':
      sectionComponent = (
        <ResumeProjects
          forms={forms}
          id={section.id}
          index={index}
          resumeProjects={resume.projects}
          onChangeSection={onChangeSection}
          onChangeSectionTitle={onChangeSectionTitle}
          onUnloadForm={onUnloadForm}
          onLoadForm={onLoadForm}
          onRemoveResumeSection={onRemoveResumeSection}/>
      );
      break;

    case 'Association':
      sectionComponent = (
        <ResumeAssociations
          forms={forms}
          id={section.id}
          index={index}
          resumeAssociations={resume.associations}
          onChangeSection={onChangeSection}
          onChangeSectionTitle={onChangeSectionTitle}
          onUnloadForm={onUnloadForm}
          onLoadForm={onLoadForm}
          onRemoveResumeSection={onRemoveResumeSection}/>
      );
      break;

    default:
      '';
    }

    let component = (
      <div
        className="card admin-panel">
        {sectionComponent}
      </div>
    );

    return component;
  }
}

export default CreateResumeSection;
