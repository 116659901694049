import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';

import { useDrag, useDrop } from 'react-dnd';

import ItemTypes from '../../constants/ItemTypes';

import QuizSection from '../../components/skill-builders/essentials/QuizSection';
import Quiz from './admin/Quiz';
import ImageSection from './ImageSection';
import Image from './admin/Image';
import Rolling from './admin/Rolling';
import RollingSection from '../../components/skill-builders/essentials/RollingSection';
import Dropdown from './admin/Dropdown';
import DropdownSection from '../../components/skill-builders/essentials/DropdownSection';
import TextList from './admin/TextList';
import TextListSection from '../../components/skill-builders/essentials/TextListSection';
import VideoSection from './VideoSection';
import Video from './admin/Video';
import ParagraphSection from '../../components/skill-builders/essentials/ParagraphSection';
import Paragraph from './admin/Paragraph';
import QuestionAnswer from './admin/QuestionAnswer';
import ThinkingBreakSection from '../../components/skill-builders/essentials/ThinkingBreakSection';
import ThinkingBreak from './admin/ThinkingBreak';
import QuestionAnswerSection from '../../components/skill-builders/essentials/QuestionAnswerSection';

const Section = (props) => {
  const {
    unit,
    index,
    section,
    profile,
    hover,
    onCancel,
    onEditClick,
    moveCard,
    moveCardPersist,
    toggleIsDragging,
    updateResourceForms,
    isAdmin,
    onPersonalValuesSeeExamples,
    onWorkValuesSeeExamples,
    onPersonalityTraitsSeeExamples,
    onMouseEnter,
    onMouseLeave
  } = props;

  const [selectedOption, setSelectedOption] = useState(null);
  const [bulletSectionCollapse, setBulletSectionCollapse] = useState({});
  const [thinkingBreakCollapse, setThinkingBreakCollapse] = useState({});
  const [collapseQuestionAnswer, setCollapseQuestionAnswer] = useState([]);

  const ref = useRef(null);

  useEffect(() => {
    window.ReadSpeaker.q(() => {
      window.rspkr.ui.addClickEvents();
    });

    return () => {
      window.ReadSpeaker.q(() => {
        window.rspkr.pl.stop();
      });
    };
  }, []);

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect: monitor => ({
      isOver: monitor.isOver()
    }),
    hover(item, monitor) {
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) return;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      // Perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item!
      // Generally it's better to avoid mutations,
      // but it's good here for performance sake
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop: item => ({ id: item.id, index: item.index })
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    item: () => {
      toggleIsDragging();

      return {
        id: section.id,
        index,
        originalIndex: index
      };
    },
    end: (item, monitor) => {
      toggleIsDragging();

      const { originalIndex } = item;

      if (! monitor.didDrop()) return;

      if (originalIndex === index) return;

      moveCardPersist();
    }
  });

  function toggleQuestionAnswer() {
    setCollapseQuestionAnswer(prevState => ([
      ...(
        prevState.includes(section.id) ?
          prevState.filter(id => id !== section.id) :
          [...prevState, section.id]
      )
    ]));
  }

  function onAnswerSelected(value) {
    return () => {
      if (selectedOption !== null) return;

      setSelectedOption(value);
    };
  }

  function toggleBulletSectionAccordion(id) {
    return () => {
      setBulletSectionCollapse(prevState => ({ ...prevState, [id]: !prevState[id] }));
    };
  }

  function toggleThinkingBreakAccordion(id) {
    return () => {
      setThinkingBreakCollapse(prevState => ({ ...prevState, [id]: !prevState[id] }));
    };
  }

  let sectionComponent;
  let sectionClass = '';

  switch(section.sectiontype) {
  case 'qa':
    sectionClass = 'essential ess-question';
    sectionComponent = updateResourceForms.length > 0 && isAdmin &&
      updateResourceForms.includes(String(section.id)) ?
      (<QuestionAnswer
        unit={unit}
        section={section}
        isAdmin={isAdmin}
        onCancel={onCancel}/>) :
      (<QuestionAnswerSection
        drag={drag}
        drop={drop}
        preview={preview}
        unit={unit}
        profile={profile}
        section={section}
        isAdmin={isAdmin}
        hover={hover}
        onEditClick={onEditClick}
        collapse={collapseQuestionAnswer.includes(section.id)}
        toggleCollapsible={toggleQuestionAnswer}/>);
    break;

  case 'quiz':
    sectionClass = 'essential ess-multiple';
    sectionComponent = updateResourceForms.length > 0 && isAdmin &&
      updateResourceForms.includes(String(section.id)) ?
      (<Quiz
        unit={unit}
        section={section}
        isAdmin={isAdmin}
        onCancel={onCancel}/>) :
      (<QuizSection
        drag={drag}
        drop={drop}
        preview={preview}
        section={section}
        isAdmin={isAdmin}
        onAnswerSelected={onAnswerSelected}
        selectedOption={selectedOption}
        hover={hover}
        onEditClick={onEditClick}/>);
    break;

  case 'image':
    sectionClass = 'essential ess-image';
    sectionComponent = updateResourceForms.length > 0 && isAdmin &&
      updateResourceForms.includes(String(section.id)) ?
      (<Image
        unit={unit}
        section={section}
        isAdmin={isAdmin}
        onCancel={onCancel}/>) :
      (<ImageSection
        drag={drag}
        drop={drop}
        preview={preview}
        section={section}
        isAdmin={isAdmin}
        hover={hover}
        onEditClick={onEditClick}/>);
    break;

  case 'bullet':
    sectionClass = 'essential ess-bullet';
    sectionComponent = updateResourceForms.length > 0 && isAdmin &&
      updateResourceForms.includes(String(section.id)) ?
      (<TextList
        unit={unit}
        section={section}
        isAdmin={isAdmin}
        onCancel={onCancel}/>) :
      (<TextListSection
        drag={drag}
        drop={drop}
        preview={preview}
        section={section}
        isAdmin={isAdmin}
        hover={hover}
        onEditClick={onEditClick}/>);
    break;

  case 'bullet1':
    sectionClass = 'essential ess-bullet';
    sectionComponent = updateResourceForms.length > 0 && isAdmin &&
      updateResourceForms.includes(String(section.id)) ?
      (<TextList
        unit={unit}
        section={section}
        isAdmin={isAdmin}
        onCancel={onCancel}/>) :
      (<TextListSection
        drag={drag}
        drop={drop}
        preview={preview}
        section={section}
        isAdmin={isAdmin}
        hover={hover}
        onEditClick={onEditClick}/>);
    break;

  case 'bullet2':
    sectionClass = 'essential ess-bullets';
    sectionComponent = updateResourceForms.length > 0 && isAdmin &&
      updateResourceForms.includes(String(section.id)) ?
      (<Rolling
        unit={unit}
        section={section}
        isAdmin={isAdmin}
        onCancel={onCancel}/>) :
      (<RollingSection
        drag={drag}
        drop={drop}
        preview={preview}
        unit={unit}
        onPersonalValuesSeeExamples={onPersonalValuesSeeExamples}
        section={section}
        isAdmin={isAdmin}
        hover={hover}
        onEditClick={onEditClick}/>);
    break;

  case 'bullet3':
    sectionClass = 'essential ess-collapse';
    sectionComponent = updateResourceForms.length > 0 && isAdmin &&
      updateResourceForms.includes(String(section.id)) ?
      (<Dropdown
        unit={unit}
        section={section}
        isAdmin={isAdmin}
        onCancel={onCancel}/>) :
      (<DropdownSection
        drag={drag}
        drop={drop}
        preview={preview}
        unit={unit}
        section={section}
        isAdmin={isAdmin}
        onWorkValuesSeeExamples={onWorkValuesSeeExamples}
        onPersonalityTraitsSeeExamples={onPersonalityTraitsSeeExamples}
        bulletSectionCollapse={bulletSectionCollapse}
        toggleBulletSectionAccordion={toggleBulletSectionAccordion}
        hover={hover}
        onEditClick={onEditClick}/>);
    break;

  case 'youtube':
    sectionClass = 'essential ess-media';
    sectionComponent = updateResourceForms.length > 0 && isAdmin &&
      updateResourceForms.includes(String(section.id)) ?
      (<Video
        unit={unit}
        section={section}
        isAdmin={isAdmin}
        onCancel={onCancel}/>) :
      (<VideoSection
        drag={drag}
        drop={drop}
        preview={preview}
        section={section}
        isAdmin={isAdmin}
        hover={hover}
        onEditClick={onEditClick}/>);
    break;

  case 'thinkingbreak':
    sectionClass = 'essential ess-thinking';
    sectionComponent = updateResourceForms.length > 0 && isAdmin &&
      updateResourceForms.includes(String(section.id)) ?
      (<ThinkingBreak
        unit={unit}
        section={section}
        isAdmin={isAdmin}
        onCancel={onCancel}/>) :
      (<ThinkingBreakSection
        drag={drag}
        drop={drop}
        preview={preview}
        section={section}
        isAdmin={isAdmin}
        hover={hover}
        thinkingBreakCollapse={thinkingBreakCollapse}
        toggleThinkingBreakAccordion={toggleThinkingBreakAccordion}
        onEditClick={onEditClick}/>);
    break;

  case 'paragraph':
    sectionClass = 'essential ess-content';
    sectionComponent = updateResourceForms.length > 0 && isAdmin &&
      updateResourceForms.includes(String(section.id)) ?
      (<Paragraph
        hover={hover}
        unit={unit}
        section={section}
        isAdmin={isAdmin}
        onCancel={onCancel}/>) :
      (<ParagraphSection
        drag={drag}
        drop={drop}
        preview={preview}
        section={section}
        isAdmin={isAdmin}
        hover={hover}
        onEditClick={onEditClick}/>);
    break;

  default:
    sectionClass='display-none';
  }

  const opacity = isDragging ? 0 : 1;
  const border = isOver ? '2px dashed #928e8e' : 'none';

  return (
    <section
      ref={ref}
      style={{opacity, border}}
      className={sectionClass}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      {sectionComponent}
    </section>
  );
};

Section.propTypes = {
  index: PropTypes.number.isRequired,
  moveCard: PropTypes.func.isRequired,
  moveCardPersist: PropTypes.func.isRequired,
  isDragging: PropTypes.bool,
  hover: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  section: PropTypes.object,
  onEditClick: PropTypes.func.isRequired,
  unit: PropTypes.object.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isOver: PropTypes.bool,
  updateResourceForms: PropTypes.array.isRequired,
  profile: PropTypes.object,
  toggleIsDragging: PropTypes.func.isRequired,
  onWorkValuesSeeExamples: PropTypes.func,
  onPersonalityTraitsSeeExamples: PropTypes.func,
  onPersonalValuesSeeExamples: PropTypes.func
};

export default Section;
