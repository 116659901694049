import React from 'react';

import PropTypes from 'prop-types';
import moment from 'moment';

import Col from 'reactstrap/lib/Col';

import { SETTINGS_DATE_FORMAT, TABLE_DATE_FORMAT } from '../../constants';

const SkillBadgeCardScore = ({data}) => {
  return (
    <Col lg="4" md="6" sm="12" xs="12" className="mb-3">
      <div className="card mb-3 BadgeCardScore-style">
        <img            
          src={require('../../assets/images/temp/badge.png')}
          style={{ width: '100%' }}/>
        <div className="card-title">{data.name}</div>
        <span className="date-label">
          {data.issued?.issuedAt?.date ? moment(data.issued?.issuedAt?.date,
            SETTINGS_DATE_FORMAT
          ).format(TABLE_DATE_FORMAT) : ''
          }
        </span>
        <div className="card-footer border-0">
          <button className="score">{data?.unit?.code}</button>
        </div>
      </div>
    </Col>
  );
};

SkillBadgeCardScore.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SkillBadgeCardScore;