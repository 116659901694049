import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const FollowersButton = ({
  isAuthenticated,
  profile: { vanity, followersCount }
}) => {
  return (
    <Link
      to={isAuthenticated ? `/cp/${vanity}/followers` : `/cp/${vanity}`}
      className="stat-block-item">
      <span className="stat-number">{followersCount}</span> Followers
    </Link>
  );
};

FollowersButton.propTypes = {
  profile: PropTypes.object,
  isAuthenticated: PropTypes.bool.isRequired
};

export default FollowersButton;
