import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';
import classNames from 'classnames';
import _isObject from 'lodash/isObject';

import Loading from '../../common/Loading';
import EndSectionSkillChallenge from '../../../containers/skill-builders/EndSectionSkillChallenge';

const engageIcon = require('../../../assets/images/icon-engage.png');

const EndSection = ({engageLink, noSection, unit, isRequesting}) => {
  const isBadgeClaimed = _isObject(unit.badge) && unit.badge.isClaimed;
  const hasNoBadge = unit.badge === '';
  let claimBadgeText = noSection ? 'Unit Incomplete' : 'Claim Badge';

  const claimBadgeStyles = classNames('btn', {
    'claimed': noSection || hasNoBadge,
    'baked-badge navy-blur-btn': isBadgeClaimed
  });

  const redirectPath = 'skill-builders';

  let claimBadgeAnchorLink = (!noSection && !isRequesting) ? `/${redirectPath}/claim-badge/${unit.slug}` : '';

  if(hasNoBadge){
    claimBadgeText = 'No Badge Available';
    claimBadgeAnchorLink = '';
  }

  if (isBadgeClaimed) {
    claimBadgeText = 'View Your Badge';
    claimBadgeAnchorLink = `/skill-badges/badge-details/${unit.badge.issued.id}`;
  }

  const badgeIcon = (_isObject(unit.badge) && unit.badge.url) ? unit.badge.url : undefined;

  return (
    <section className="endpoint">
      <div className="row group">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 end-section-container">
          <EndSectionSkillChallenge unit={unit} isRequesting={isRequesting} />
        </div>

        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 end-section-container">
          <article className="essential-action engage">
            <div className="icon endsection-icon-container engage-n-connect-icon">
              <img className="img-responsive img-fluid" src={engageIcon} width="42" height="auto" />
            </div>
            <p>
              Share your thoughts and insights on developing this skill. Comment on existing posts and link up with your peers.
            </p>
            <Link
              className="btn"
              to={`/${redirectPath}/discussions/${engageLink}`}>
              Engage &amp; Connect
            </Link>
          </article>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 end-section-container">
          <article className="essential-action engage">
            {isRequesting && <div className="icon engage-n-connect-icon"><Loading /></div>}

            {(!hasNoBadge && !isRequesting) &&
              <div className="icon badge-icon no-backgroud engage-n-connect-icon">
                <img src={badgeIcon} width="100" className="unit-badge-image"/>
              </div>}

            {(hasNoBadge && !isRequesting) &&
              <div className="icon engage-n-connect-icon"><i className="fa fa-certificate"/></div>}

            <p>
              Get your Skill Badge if you believe you’ve acquired this skill and can support your claim with evidence.
            </p>
            <Link
              to={claimBadgeAnchorLink}
              className={claimBadgeStyles}>
              {claimBadgeText}
            </Link>
          </article>
        </div>
      </div>
    </section>
  );
};

EndSection.propTypes = {
  engageLink: PropTypes.string.isRequired,
  noSection: PropTypes.bool.isRequired,
  unit: PropTypes.object,
  isRequesting: PropTypes.bool
};

export default EndSection;
