import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import classNames from 'classnames';
import { parseUrlString, isOverflown } from '../../utils';
import reactStringReplace from 'react-string-replace';

import { Dropdown, DropdownToggle, DropdownMenu, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import * as componentsActions from '../../redux/actions/components';
import * as wallPostsActions from '../../redux/actions/wall-posts';

import Button from '../../components/common/Button';
import TextArea from '../../components/common/TextArea';
import ImageRenderer from '../../components/common/ImageRenderer';
import LessPost from '../../components/common/LessPost';
import FullPost from '../../components/common/FullPost';
import VideoIframe from '../../components/common/VideoIframe';
import ImagePost from '../../components/common/ImagePost';
import { Link } from 'react-router-dom';

const visibilityOptionsArray = [
  {
    title:'Everyone (Public)',
    alias: 'public',
    value:'1'
  },
  {
    title:'Followers Only',
    alias: 'followers',
    value:'2'
  },
  {
    title:'Only Me',
    alias: 'private',
    value:'0'
  },
];

const wallPost = { sharedQuotedText: '', permissions: '1' };

class SharedPostEdit extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    wallPost: PropTypes.object.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    sharedWall: PropTypes.object,
    showMore: PropTypes.object.isRequired,
    onshowMore: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      showAll: false,
      isContentsOverflown: false,
      visibilityDropdownOpen: false,
      wallPost: this.setInitialWallPost(props.wallPost),
      isCancelModalOpen: false
    };

    this.onCancel = this.onCancel.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onSelectVisibilty = this.onSelectVisibilty.bind(this);
    this.onToggleCancelModal = this.onToggleCancelModal.bind(this);
    this.onCancelConfirm = this.onCancelConfirm.bind(this);
    this.toggleVisibilityDropdown = this.toggleVisibilityDropdown.bind(this);
    this.onToggleShowAll = this.onToggleShowAll.bind(this);
    this.setReflectionAnswersContentContainerRef = this.setReflectionAnswersContentContainerRef.bind(this);
  }

  componentDidMount(){
    const refDiv = this[`reflectionAnswerDiv-${this.props.wallPost.id}`];

    if(refDiv){
      /* eslint-disable react/no-did-mount-set-state */
      this.setState({
        isContentsOverflown: isOverflown(refDiv)
      });
    }
  }

  componentDidUpdate(prevProps, prevState){
    const { showAll } = prevState;

    if(showAll !== this.state.showAll){
      const refDiv = this[`reflectionAnswerDiv-${this.props.wallPost.id}`];

      if(refDiv){
        /* eslint-disable react/no-did-update-set-state */
        this.setState({
          isContentsOverflown: isOverflown(refDiv)
        });
      }
    }
  }

  setInitialWallPost(wallPostInProps){
    if(wallPostInProps){
      return Object.assign({}, wallPost, wallPostInProps);
    }

    return wallPost;
  }

  onToggleShowAll() {
    this.setState({
      showAll: !this.state.showAll
    });
  }

  setReflectionAnswersContentContainerRef(div) {
    this[`reflectionAnswerDiv-${this.props.wallPost.id}`] = div;
  }

  onChange(event) {
    const field = event.target.name;
    const { wallPost } = this.state;

    wallPost[field] = event.target.value;

    this.setState({ wallPost });
  }


  onCancelConfirm(){
    const { actions, wallPost } = this.props;
    const form = `update-wall-post-form-${wallPost.id}`;

    actions.unloadForm(form);
  }

  onCancel(){
    const { wallPost } = this.state;
    const { sharedQuotedText, permissions } = this.props.wallPost;
    const {
      sharedQuotedText: stateQuote,
      permissions: statePermissions
    } = wallPost;

    if(sharedQuotedText !== stateQuote || permissions !== statePermissions){
      return this.onToggleCancelModal();
    }

    this.onCancelConfirm();
  }

  toggleVisibilityDropdown(){
    this.setState({
      visibilityDropdownOpen: !this.state.visibilityDropdownOpen
    });
  }

  onToggleCancelModal(){
    this.setState({
      isCancelModalOpen: !this.state.isCancelModalOpen
    });
  }

  onSelectVisibilty(id){
    return () => {
      const wallPostObject = { ...this.state.wallPost, permissions:id};

      this.setState({
        wallPost: wallPostObject,
        visibilityDropdownOpen: false
      });
    };
  }

  onUpdate(event) {
    event.preventDefault();

    const { wallPost: wallPostState } = this.state;
    const { actions } = this.props;

    const quoteState = wallPostState.sharedQuotedText.trim();

    const wallPost = Object.assign({}, wallPostState, { sharedQuotedText: quoteState });

    const dropdownButton = visibilityOptionsArray.find(option =>
      option.value == wallPostState.permissions || option.alias == wallPostState.permissions);

    const data = {
      'permissions': dropdownButton.value,
      'sharedQuotedText': wallPostState.sharedQuotedText
    };

    actions.updateWallPost(data, wallPost.id)
      .then(() => this.handleResponse());
  }

  handleResponse() {
    const { wallPost } = this.state;

    this.props.actions.unloadForm(`update-wall-post-form-${wallPost.id}`);
  }

  render() {
    const {
      wallPost,
      visibilityDropdownOpen,
      isCancelModalOpen,
      showAll,
      isContentsOverflown
    } = this.state;
    const { isUpdating, showMore, onshowMore } = this.props;
    const {
      sharedBadgeIssued,
      sharedFile,
      sharedQuotedText,
      sharedEntityType,
      sharedInterviewResource,
      sharedSkillBuilderReflections
    } = wallPost;

    const dropdownButton = visibilityOptionsArray.find(option =>
      option.value == wallPost.permissions);

    let component = null;

    switch(sharedEntityType){
    case 'interviewResource':{
      const { title, thumbnail } = sharedInterviewResource;
      component = (<div className="shared-item-container">
        <div className="shared-item-image">
          <img src={thumbnail}/>
        </div>
        <div className="shared-item-description">
          <h6>{title}</h6>
          <div className="description">
            <p>Click to see details for this Interview Prep Video.</p>
          </div>
        </div>
      </div>);
    }
      break;

    case 'badgeIssued':{
      const { badgeUrl, badge: badgeObject } = sharedBadgeIssued;
      const { name } = badgeObject;
      component = (
        <div className="shared-item-container">
          <div className="shared-item-image">
            <img src={badgeUrl}/>
          </div>
          <div className="shared-item-description">
            <h6>{name}</h6>
            <div className="description">
              <p>Check out my {name} Skill Badge!</p>
            </div>
          </div>
        </div>
      );
    }
      break;

    case 'file':{
      const { url, title, mimetype } = sharedFile;
      let thumbnail = url;
      const docFile = mimetype.includes('application') || mimetype.includes('text');
      const parsedString = parseUrlString(url);
      const isVideoFile = parsedString && parsedString.type === 'iframe';
      component = (
        <div className={classNames('shared-item-container',{
          'video-file': isVideoFile
        })}>
          {
            docFile ?
              <div className="shared-item-image">
                <div className="file-icon-container shared-file-display">
                  <i className="fa fa-file-text-o"/>
                </div>
              </div>
              :
              isVideoFile ?
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    src={parsedString.url}
                    allowFullScreen
                    style={{'overflow': 'hidden'}}
                    scrolling="no"
                    className="embed-responsive-item"/>
                </div>:
                <div className="shared-item-image">
                  <img src={thumbnail}/>
                </div>
          }
          <div className="shared-item-description">
            <h6>{title}</h6>
            <div className="description">
              <p>Click to see details for this Portfolio File.</p>
            </div>
          </div>
        </div>
      );
    }
      break;

    case 'skillBuilderReflections':{
      component = (
        <div className="shared-item">
          {
            sharedSkillBuilderReflections.length > 0 &&
              <div style={{display: 'block'}} className="shared-item-container">
                <div className="shared-item-description reflection-sharing">
                  <p>I just answered the reflection question(s) below from <Link to={`/skill-builders/${sharedSkillBuilderReflections[0].unit.slug}`}>{sharedSkillBuilderReflections[0].unit.title}</Link></p>
                  <hr/>
                  <div
                    style={{
                      maxHeight: showAll ? 'initial': '100%',
                      overflow: isContentsOverflown ? 'hidden' : 'initial'
                    }}
                    ref={this.setReflectionAnswersContentContainerRef}
                    className="shared-reflection-contents">
                    {sharedSkillBuilderReflections.map((ref, index) =>
                      (<div key={ref.id}>
                        <strong>{ref.question}</strong><br/>
                        {ref.answer}{index + 1 !== sharedSkillBuilderReflections.length && <span><br/><br/></span>}
                      </div>))}
                  </div>
                  {
                    (isContentsOverflown || showAll) &&
                      <div
                        onClick={this.onToggleShowAll}
                        className="clickable link"
                        style={{marginTop: 10, fontWeight: '600'}}>
                        {showAll ? 'Show less': '...Show more'}</div>
                  }
                </div>
              </div>
          }
        </div>
      );
    }
      break;

    default:{
      const postTypeName = sharedEntityType === 'wall' ?
        'sharedWall' : 'sharedDiscussion';
      const { post, attachment, secondary } = wallPost[postTypeName];
      const parsedUrlString = parseUrlString(post);
      const urlRegex = /(https?:\/\/\S+)/g;

      const string = reactStringReplace(post, urlRegex, (match, i) => {
        const htmlRegex = /(<([^>]+)>)/ig;
        const url = match.replace(htmlRegex, '');

        return <a key={url + i} href={url} target="_blank" rel="noopener noreferrer">{url}</a>;
      });

      const showMoreCondition = post.length > 250;
      const lessComponentCondition = showMoreCondition && !showMore;
      component = (
        <div className="share-post primary-post">
          <div className="right" style={{marginLeft: 0}}>
            <div className="post-content" style={{ whiteSpace: 'normal' }}>
              {
                lessComponentCondition ?
                  <LessPost
                    textLimit={250}
                    post={string}/>
                  :
                  <FullPost
                    post={string}/>
              }

              {
                showMoreCondition &&
                  <span
                    onClick={onshowMore}
                    className="show-more-btn">
                    {lessComponentCondition ? '... Show more' : 'Show less'}
                  </span>
              }
            </div>

            {(parsedUrlString && parsedUrlString.type === 'iframe')&& <VideoIframe url={parsedUrlString.url}/>}

            {(parsedUrlString && parsedUrlString.type === 'image')&& <ImagePost url={parsedUrlString.url}/>}

            {attachment && <ImageRenderer attachment={attachment} secondary={secondary}/>}
          </div>
        </div>
      );
    }
      break;
    }

    const isPrivateFile = sharedFile && sharedFile.visibility === 'private';
    const isFollowersOnly = sharedFile && sharedFile.visibility === 'followers';

    return (
      <div>
        <div className="content experience add-portfolio-files">
          <div className="row">
            <div className="col-lg-12">
              <div className="shared-item">
                <TextArea
                  name="sharedQuotedText"
                  type="text"
                  rows="3"
                  value={sharedQuotedText}
                  onChange={this.onChange}
                  placeholder="Say something about this badge"/>
                {component}
              </div>
              <div className="portfolio-buttons margin-with-border pl-0 pr-0 pb-0">
                <Button
                  type="button"
                  disabled={isUpdating}
                  onClick={this.onCancel}
                  buttonClass="btn-secondary"
                  buttonText="Cancel"/>
                <Dropdown
                  className="dropdown"
                  isOpen={visibilityDropdownOpen}
                  toggle={this.toggleVisibilityDropdown}>
                  <DropdownToggle
                    caret
                    color="tertiary"
                    className="btn btn-tertiary activity-filter-button share-visibilty">
                    {dropdownButton == null ? 'Who can see this post' : dropdownButton.title}
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu">
                    {
                      isFollowersOnly || isPrivateFile ?
                        <span className="not-clickable dropdown-item-cus">
                          {visibilityOptionsArray[0].title}
                        </span> :
                        <a className="dropdown-item" onClick={this.onSelectVisibilty(visibilityOptionsArray[0].value)}>
                          {visibilityOptionsArray[0].title}
                        </a>
                    }

                    {
                      !isFollowersOnly && isPrivateFile ?
                        <span className="not-clickable dropdown-item-cus">
                          {visibilityOptionsArray[1].title}
                        </span> :
                        <a className="dropdown-item" onClick={this.onSelectVisibilty(visibilityOptionsArray[1].value)}>
                          {visibilityOptionsArray[1].title}
                        </a>
                    }

                    <a className="dropdown-item" onClick={this.onSelectVisibilty(visibilityOptionsArray[2].value)}>
                      {visibilityOptionsArray[2].title}
                    </a>
                  </DropdownMenu>
                </Dropdown>
                <Button
                  type="submit"
                  disabled={isUpdating}
                  onClick={this.onUpdate}
                  buttonClass="btn btn-primary"
                  buttonText={isUpdating ? 'Updating...' : 'Update'}/>
              </div>
            </div>
          </div>
        </div>
        <Modal
          backdrop="static"
          className="modal-margin-top"
          isOpen={isCancelModalOpen}
          toggle={this.onToggleCancelModal}>
          <ModalHeader
            className="no-border"
            toggle={this.onToggleCancelModal}>
            Discard you changes
          </ModalHeader>
          <ModalBody>
            Are you sure you want to discard your changes?
          </ModalBody>
          <ModalFooter className="no-border">
            <button
              onClick={this.onToggleCancelModal}
              className="btn btn-secondary">
              No
            </button>

            <button
              onClick={this.onCancelConfirm}
              className="btn btn-primary">
              Discard Changes
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    isUpdating: state.wallPosts.isUpdating.status
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, componentsActions, wallPostsActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SharedPostEdit);
