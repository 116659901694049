import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as messagesActions from '../../redux/actions/messages';
import * as followersActions from '../../redux/actions/followers';
import * as followingActions from '../../redux/actions/following';
import * as routerActions from '../../redux/actions/router';

import { Outlet } from 'react-router-dom';

import _uniqBy from 'lodash/uniqBy';

import { withHooks } from '../../utils/withHooks';

import { Helmet } from 'react-helmet';

import MessagesList from '../../components/messages/MessagesList';
import Loading from '../../components/common/Loading';
import ComposeMessageModal from './ComposeMessageModal';
import EmptyMessages from '../../components/messages/EmptyMessages';

class MessagesWrapper extends Component {
  static propTypes = {
    profile: PropTypes.object,
    actions: PropTypes.object,
    messages: PropTypes.object,
    location: PropTypes.object,
    followers: PropTypes.array,
    userId: PropTypes.string,
    params: PropTypes.object,
    isMobile: PropTypes.bool,
    selectableUsers: PropTypes.array,
    navigate: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      searchText: '',
      showMessageModal: false
    };

    this.toggleMessageModal = this.toggleMessageModal.bind(this);
    this.onChangeSearchText = this.onChangeSearchText.bind(this);
    this.onSubmitSearch = this.onSubmitSearch.bind(this);
    this.onClearSearch = this.onClearSearch.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }


  componentDidMount(){
    this.initialApiCalls();
  }

  componentWillReceiveProps(nextProps){
    const { data: nextData, isRequesting: nextRequesting, isDeleting: nextIsDeleting, isSearching: nextSearching } = nextProps.messages;
    const { isRequesting, isDeleting, isSearching } = this.props.messages;

    if(nextProps.location !== this.props.location && !nextProps.location.pathname.split('/')[2]){
      this.initialApiCalls();
    }

    if(!nextRequesting && isRequesting !== nextRequesting && nextData.length > 0 && !this.props.params.slug && !nextProps.isMobile){
      const firstMessage = nextData[0];

      if(firstMessage)
        return this.props.navigate(`/messages/${firstMessage.id}`);
    }

    if(!nextSearching && isSearching !== nextSearching && nextData.length > 0 && !nextProps.isMobile){
      const firstMessage = nextData[0];

      if(firstMessage)
        return this.props.navigate(`/messages/${firstMessage.id}`);
    }

    if(this.props.isMobile !== nextProps.isMobile && !nextProps.isMobile && this.props.isMobile && !this.props.params.slug){
      const firstMessage = nextData[0];
      if(firstMessage)
        return this.props.navigate(`/messages/${firstMessage.id}`);
    }

    if(isDeleting !== nextIsDeleting && !nextIsDeleting.status && !nextData.find(item => item.id === isDeleting.messageId)){
      const firstMessage = nextData[0];
      if(firstMessage)
        return nextProps.isMobile ? this.props.navigate('/messages') : this.props.navigate(`/messages/${firstMessage.id}`);
    }
  }

  initialApiCalls(){
    const { isAuthenticated } = this.props;

    this.props.actions.initializeMessagesStore();
    this.props.actions.messagesRequest();
    this.props.actions.followersRequest(isAuthenticated, null, 1, true);
    this.props.actions.followingRequest(isAuthenticated, null, 1, true);
  }

  toggleMessageModal(){
    this.setState({
      showMessageModal: !this.state.showMessageModal
    });
  }

  onChangeSearchText(e){
    const { value } = e.target;

    this.setState({
      searchText: value
    });
  }

  handleKeyPress(event) {

    if (event.key === 'Enter')
      return this.onSubmitSearch(event);

  }

  onSubmitSearch(event){
    event.preventDefault();
    const { searchText } = this.state;

    this.props.actions.searchMessages(searchText);
  }

  onClearSearch(){
    this.setState({searchText: ''}, () => this.props.actions.searchMessages(''));
  }

  render() {
    const { showMessageModal, searchText } = this.state;
    const {
      messages,
      params,
      isMobile,
      location: { pathname },
      selectableUsers
    } = this.props;

    const slug = pathname.split('/')[2];

    const { isRequesting, data, isSearching, query, paginationData } = messages;

    return(
      <div>
        <Helmet title="Messages"/>
        {isRequesting && data.length === 0 ?
          <div className="loading-container">
            <Loading/>
          </div> :
          (data.length === 0 && query === '' && !isSearching) ?
            <EmptyMessages
              userCount={selectableUsers.length}
              onComposeNew={this.toggleMessageModal}/> :
            <section id="messaging" style={{paddingBottom: 1}}>
              <div className="container">
                <div className="messagingContainer">
                  <div className="row">
                    {((params.slug && !isMobile) || (!params.slug && isMobile) || (!params.slug && !isMobile)) &&
                      <MessagesList
                        query={query}
                        isSearching={isSearching}
                        searchText={searchText}
                        onChangeSearchText={this.onChangeSearchText}
                        onSubmitSearch={this.onSubmitSearch}
                        toggleMessageModal={this.toggleMessageModal}
                        handleKeyPress={this.handleKeyPress}
                        slug={slug}
                        onClearSearch={this.onClearSearch}
                        isMobile={isMobile}
                        totalMessage={paginationData.total_items}/>}

                    <Outlet/>
                  </div>
                </div>
              </div>
            </section>}

        {showMessageModal &&
          <ComposeMessageModal
            selectableUsers={selectableUsers}
            isOpen={showMessageModal}
            toggleModal={this.toggleMessageModal}/>}
      </div>
    );
  }
}

const mergeFollowingAndFollowers = (followers, following) => {
  const arrayOfUsers = [...followers.map(user => user.user), ...following.map(user => user.user)];

  return _uniqBy(arrayOfUsers, item => item.id).map(user => Object.assign({}, {
    'user': user,
    'label': user.name,
    'value': user.id
  }));

};

const mapStateToProps = (state) => {
  const { auth, followers, following } = state;
  const { isAuthenticated } = auth;
  const selectableUsers = mergeFollowingAndFollowers(followers.data, following.data);

  return {
    isAuthenticated,
    profile: state.profile.data,
    selectableUsers,
    messages: state.messages,
    followers: state.followers.data,
    isMobile: state.components.isMobile,
    userId: state.auth.data.currentUser.id
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, messagesActions, followersActions, routerActions, followingActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(MessagesWrapper));
