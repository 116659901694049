import PropTypes from 'prop-types';
import React from 'react';
import RichEditor from '../../../plugins/RichEditor';

const blockTypes = [
  { label: 'Blockquote', style: 'blockquote', icon: 'fa-quote-right' },
  { label: 'Link', style: 'LINK', icon: 'fa-link' }
];

const inlineTypes = [
  { label: 'Bold', style: 'BOLD', icon: 'fa-bold'},
  { label: 'Italic', style: 'ITALIC', icon: 'fa-italic' },
  { label: 'Underline', style: 'UNDERLINE', icon: 'fa-underline' }
];

const styles = {
  editorWrapper: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    padding: '.5rem .5rem',
    borderRadius: 4,
    marginBottom: '1rem',
    width: '100%'
  },
  withError: {
    border: '1px solid #d9534f',
    padding: '.5rem .5rem',
    borderRadius: 4,
    marginBottom: '1rem'
  },
  addListItemGroup: {
    position: 'absolute',
    right: 10,
  },
  cardWrapper: { marginBottom: 15 }
};

const DropdownInput = ({
  errors,
  section,
  resetEditorState,
  updateEditorState,
  getEditorState,
  setEditorState,
  onAddListItem,
  listItemsLimit
}) => {
  const hasError = Object.keys(errors).includes('feedbackText');

  return (
    <div className="row">
      <div className="col-sm-12" style={styles.cardWrapper}>
        <div className="input-group">

          <div style={hasError ? styles.withError : styles.editorWrapper}>
            <span
              className="btn-group btn-group-sm"
              style={styles.addListItemGroup}>
              <span
                className="btn btn-sm btn-secondary"
                onClick={onAddListItem}
                disabled={section.content.length === listItemsLimit}>
                Add List Item<i className="ml-2 fa fa-plus"/>
              </span>
            </span>
            <RichEditor
              resetEditorState={resetEditorState}
              updateEditorState={updateEditorState}
              blockTypes={blockTypes}
              inlineTypes={inlineTypes}
              placeholder="Enter feedback..."
              setEditorState={setEditorState}
              getEditorState={getEditorState}/>
          </div>
          {
            hasError &&
              <label
                className="form-control-label"
                style={styles.errorTextWrapper}>
                <span className="danger">{errors.feedbackText}</span>
              </label>
          }

        </div>
      </div>
    </div>
  );
};

DropdownInput.propTypes = {
  errors: PropTypes.object,
  section: PropTypes.object.isRequired,
  getEditorState: PropTypes.func.isRequired,
  setEditorState: PropTypes.func.isRequired,
  onAddListItem: PropTypes.func.isRequired,
  listItemsLimit: PropTypes.number.isRequired,
  resetEditorState: PropTypes.bool.isRequired,
  updateEditorState: PropTypes.bool.isRequired
};

export default DropdownInput;
