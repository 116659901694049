import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Collapse } from 'reactstrap';
import classNames from 'classnames';
import UnitList from './UnitList';

class UnitAccordionContent extends PureComponent {

  static propTypes = {
    elem: PropTypes.object.isRequired,
    homepageModal: PropTypes.bool,
    index: PropTypes.number,
    units: PropTypes.array.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state={
      isOpen: false
    };

    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle(){
    const { isOpen } = this.state;

    this.setState({
      isOpen : !isOpen
    });
  }

  render(){
    const { isOpen } = this.state;
    const { elem, index, homepageModal, units } = this.props;
    const unitALT = `Unit Icon: ${elem.name}`;

    return (
      <div className="sb-category">
        <div
          className={classNames('sb-item', {
            'collapsed': !isOpen })}
          onClick={this.handleToggle}>
          <div className="header">
            <img src={elem.icon} alt={unitALT}/>
            <div className="title">
              {elem.name}
            </div>
            <div className="arrow">
              <i className="fa fa-caret-down"/>
            </div>
          </div>
        </div>
        <Collapse isOpen={isOpen}>
          <UnitList
            key={index}
            homepageModal={homepageModal}
            units={units}/>
        </Collapse>
      </div>
    );
  }
}

export default UnitAccordionContent;
