import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loadForm, guid } from '../../utils';

import * as componentActions from '../../redux/actions/components';

import Validator from '../../validator';
import AssociationSectionCard from '../../components/resume/sections/AssociationSectionCard';
import AssociationForm from '../../components/profile/forms/AssociationForm';
import DeleteSectionItemConfirmModal from '../../components/resume/DeleteSectionItemConfirmModal';

class ResumeAssociations extends Component {
  static propTypes = {
    associations: PropTypes.object,
    onChangeSection: PropTypes.func.isRequired,
    onChangeSectionTitle: PropTypes.func.isRequired,
    onUnloadForm: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    resumeAssociations: PropTypes.object.isRequired,
    onLoadForm: PropTypes.func.isRequired,
    forms: PropTypes.array,
    onRemoveResumeSection: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      editingId: null,
      removeAssociationId: null,
      title: props.resumeAssociations.title,
      associations: Object.assign({}, this.props.associations),
      editorText: { description: this.props.associations.description }
    };

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onEditAssociation = this.onEditAssociation.bind(this);
    this.onRemoveAssociation = this.onRemoveAssociation.bind(this);
    this.onRichEditorChange = this.onRichEditorChange.bind(this);
    this.onCancelRemoveAssociation = this.onCancelRemoveAssociation.bind(this);
    this.onConfirmRemoveAssociation = this.onConfirmRemoveAssociation.bind(this);
  }

  formatEndDate = (dateString) => {
    return dateString === '' ? null : dateString;
  };

  isValid(field = null) {
    const { noend, startdate } = this.state.associations;
    let rules = {};
    if (noend === true) {
      rules = {
        name: ['required', 'minLength|6', 'maxLength|100'],
        position: ['maxLength|200'],
        url: ['unsafeurl'],
        startdate: ['required', 'startdate'],
      };
    } else {
      rules = {
        name: ['required', 'minLength|6', 'maxLength|100'],
        position: ['maxLength|200'],
        url: ['unsafeurl'],
        startdate: ['required', 'startdate'],
        enddate: ['required', 'startdate', `beforeStart|${this.formatEndDate(startdate)}`],
      };
    }

    const { associations, errors: prevErrors} = this.state;
    const validate = Validator.createValidator(rules, associations, field);
    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    if (noend === true) {
      delete prevErrors['enddate'];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  isValidDescription(field = null) {
    const rules = {
      description: ['maxWords|200']
    };
    const { editorText, errors: prevErrors } = this.state;
    const validate = Validator.createValidator(rules, editorText, field);
    const { errors, isValid } = validate;

    if (field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }
    this.setState({ errors: Object.assign({}, prevErrors, errors) });

    return isValid;
  }

  onChangeTitle(event){
    const { value } = event.target;
    this.props.onChangeSectionTitle(value, 'associations');
  }

  handleChange(event){
    let { name, type, checked, value } = event.target;
    const { associations } = this.state;
    value = type === 'checkbox' ? checked : value;
    associations[name] = value;

    this.setState({ associations }, () => this.isValid(name));

    if (name === 'startdate') {
      this.isValid('enddate');
    }

  }

  onRichEditorChange(description, ...params) {
    let { associations, editorText} = this.state;
    editorText['description'] = params[2].getText().replace(/\r?\n|\r/gm, ' ');

    associations = Object.assign({}, associations, { description });

    this.setState({ associations, editorText }, () => this.isValidDescription('description'));
  }

  onSave(){
    const { associations, editingId } = this.state;
    const { resumeAssociations } = this.props;

    if (!this.isValid() || !this.isValidDescription())
      return;

    const newAssoc = Object.assign({},{
      id: guid()
    }, associations );

    const index = resumeAssociations.associationsArray.findIndex(assoc => assoc.id === editingId);

    const associationsArray = editingId === null ? [
      ...resumeAssociations.associationsArray,
      newAssoc
    ] : [
      ...resumeAssociations.associationsArray.slice(0, index),
      newAssoc,
      ...resumeAssociations.associationsArray.slice(index+1)
    ];

    const data = Object.assign({}, resumeAssociations,{
      associationsArray
    });

    this.props.onChangeSection(data, 'associations');
    this.onCancel();
  }

  onCancel(){
    const { unloadForm } = this.props.actions;
    this.clearFormField();
    unloadForm('editAssociationsForm');
  }

  clearFormField(){
    const associations = {
      startdate: '',
      enddate: '',
      noend: false,
      name: '',
      position: '',
      url: '',
      description: '',
    };

    this.setState({
      associations,
      errors: {},
      editingId: null
    });
  }

  onEditAssociation(event){
    const { id } = event.target;
    const { loadForm } = this.props.actions;
    const { associationsArray } = this.props.resumeAssociations;
    const associationObject = associationsArray.find(association => association.id === id);
    const associations = Object.assign({}, this.state.associations, associationObject);
    this.setState({
      associations,
      editingId: id
    }, ()=> loadForm('editAssociationsForm'));
  }

  onRemoveAssociation(event) {
    const { id: removeAssociationId } = event.target;

    this.setState({ removeAssociationId });
  }

  onConfirmRemoveAssociation() {
    const { removeAssociationId } = this.state;
    const { resumeAssociations } = this.props;

    const associationsArray = resumeAssociations
      .associationsArray
      .filter(assoc => assoc.id !== removeAssociationId);

    const data = Object.assign({}, resumeAssociations, { associationsArray });

    this.props.onChangeSection(data, 'associations');
    this.onCancelRemoveAssociation();
  }

  onCancelRemoveAssociation() {
    this.setState({ removeAssociationId: null });
  }

  render() {
    const { resumeAssociations, onLoadForm, forms, onRemoveResumeSection } = this.props;
    const { associations, errors, removeAssociationId } = this.state;

    const isFormOpen = loadForm(forms, 'editAssociationsForm');
    const isConfirmModalOpen = removeAssociationId !== null;

    return (
      <div className="resume-section create-resume-section-card">
        <div className="create-resume-title">
          <input
            type="text"
            value={resumeAssociations.title}
            placeholder="Associations header"
            className="header-edit-textbox"
            onChange={this.onChangeTitle}/>
          <button
            onClick={onRemoveResumeSection}
            id="Association"
            className="btn btn-sm btn-danger float-right">
            <i className="fa fa-close"/>
          </button>
        </div>
        {
          !isFormOpen &&
            <div className="section-list-container">
              {resumeAssociations.associationsArray.map(association =>
                (<AssociationSectionCard
                  key={association.id}
                  associationDetails={association}
                  onEditAssociation={this.onEditAssociation}
                  onRemoveAssociation={this.onRemoveAssociation}/>))}
              <button
                onClick={onLoadForm}
                data-form-name="editAssociationsForm"
                className="btn btn-primary w-100">
                Add Association
              </button>
            </div>
        }

        {isFormOpen &&
          <div className="resume-form-container">
            <AssociationForm
              errors={errors}
              association={associations}
              onSave={this.onSave}
              onCancel={this.onCancel}
              onChange={this.handleChange}
              onChangeDescription={this.onRichEditorChange}/>
          </div>}

        {isConfirmModalOpen &&
          <DeleteSectionItemConfirmModal
            onCancel={this.onCancelRemoveAssociation}
            onConfirm={this.onConfirmRemoveAssociation}
            isOpen={isConfirmModalOpen}/>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const associations = {
    startdate: '',
    enddate: '',
    noend: false,
    name: '',
    position: '',
    url: '',
    description: '',
  };

  return {
    associations,
    forms: state.components.forms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(componentActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResumeAssociations);
