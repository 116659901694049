import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Route, useNavigate } from 'react-router-dom';

import { checkForToken } from '../../redux/actions/authentication';
import { redirect } from '../../redux/actions/router';

import UnAuthorisedLogin from '../home/UnAuthorisedLogin';

// class RequireAuth extends Component {
//  static propTypes = {
//    dispatch: PropTypes.func.isRequired,
//    location: PropTypes.object.isRequired,
//    children: PropTypes.object.isRequired,
//    isAuthenticated: PropTypes.bool.isRequired,
//    currentUser: PropTypes.object.isRequired,
//  };
//
//  componentDidMount() {
//    const { dispatch, isAuthenticated, location, currentUser } = this.props;
//    if(isAuthenticated){
//      dispatch(checkForToken(currentUser.id));
//    }
//    this.checkAuth(isAuthenticated, location);
//  }
//
//  // componentWillReceiveProps(nextProps) {
//  //   // if(nextProps.isAuthenticated !== this.props.isAuthenticated){
//  //   //   console.log("RequireAuth - there is an authentication change",nextProps.location);
//  //   //   this.props.dispatch(checkForToken(this.props.currentUser.id));
//  //   //   this.checkAuth(nextProps.isAuthenticated, nextProps.location);
//  //   // }
//
//  //   // if (nextProps.location !== this.props.location) {
//  //   //   console.log("the current userId in RequireAuth(`componentWillReceiveProps`)",nextProps.currentUser.id);
//  //   //   this.props.dispatch(checkForToken(nextProps.currentUser.id));
//  //   //   this.checkAuth(nextProps.isAuthenticated, nextProps.location);
//  //   // }
//  // }
//
//  checkAuth(isAuthenticated, location) {
//    const token = localStorage.getItem('careerPrepped.auth.token') || null;
//    if (!isAuthenticated || !token) {
//      this.props.dispatch(redirect('/reenter-credentials', location.pathname));
//    }
//  }
//
//  render() {
//    const { children, isAuthenticated } = this.props;
//
//    console.log('Children: ', children);
//
//    const [,path, subpath] = location.pathname.split('/');
//    const unAuthDomains = ["skill-builders", "cp", "auth"];
//    const inSkillBadgesNotLoggedIn = path === "skill-badges" && subpath;
//    const inPortfolioNotLoggedIn = path === "portfolio" && subpath;
//    const token = localStorage.getItem('careerPrepped.auth.token') || null;
//
//    if ( (!isAuthenticated || !token) && !unAuthDomains.includes(path) &&
//      !inSkillBadgesNotLoggedIn && !inPortfolioNotLoggedIn ) {
//      return null;
//    }
//
//    return (
//      <div>{children}</div>
//    );
//  }
// }

const RequireAuth = ({ path, element, actions, children, currentUser, isAuthenticated }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const { redirect, checkForToken } = actions;

    if (isAuthenticated) {
      checkForToken(currentUser.id);
    }

    if (!isAuthenticated) {
      window.localStorage.setItem('landingPath', location.pathname);
      redirect('/', location.pathname);
      navigate('/');
    }
  }, [isAuthenticated, currentUser.id, actions, navigate]);

  const [, mainPath, subpath] = location.pathname.split('/');
  const unAuthDomains = ['skill-builders', 'cp', 'auth'];
  const inSkillBadgesNotLoggedIn = mainPath === 'skill-badges' && subpath;
  const inPortfolioNotLoggedIn = mainPath === 'portfolio' && subpath;
  const token = localStorage.getItem('careerPrepped.auth.token') || null;

  if ( (!isAuthenticated || !token) && !unAuthDomains.includes(mainPath) &&
    !inSkillBadgesNotLoggedIn && !inPortfolioNotLoggedIn ) {
    return <UnAuthorisedLogin/>;
  }

  return (
    <Route path={path} element={element}>
      {children}
    </Route>
  );
};

RequireAuth.propTypes = {
  path: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  element: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
  currentUser: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

const mapStateToProps = ({ auth: { isAuthenticated, data: { currentUser } } }) =>
  ({ currentUser, isAuthenticated });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    redirect,
    checkForToken
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(RequireAuth);
