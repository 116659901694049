import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { withHooks } from '../../utils/withHooks';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toastr } from 'react-redux-toastr';
import Loading from '../../components/common/Loading';
import { Form, FormGroup, Input } from 'reactstrap';
import Validator from '../../validator';

import * as authActions from '../../redux/actions/authentication';
import * as resetPasswordActions from '../../redux/actions/reset-password';
import * as organizationActions from '../../redux/actions/organizations';
import * as componentActions from '../../redux/actions/components';
import * as routerActions from '../../redux/actions/router';

class OrganizationInitialSetup extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    resetPassword: PropTypes.object.isRequired,
    auth: PropTypes.object,
    organizations: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    route: PropTypes.object,
    router: PropTypes.object,
    navigate: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      canLeave: false,
      loadingUserDetails: false,
      newPassword: {
        password: '',
        rePassword: ''
      },
      email: props.params['*'].split('/')[1],
      token: props.params['*'].split('/')[0]
    };

    this.onSignIn = this.onSignIn.bind(this);
    this.onChangeNewPassword = this.onChangeNewPassword.bind(this);
  }

  componentDidMount(){
    const { actions, auth } = this.props;

    if(auth.isAuthenticated)
      actions.userLogout();
  }

  componentWillReceiveProps(nextProps){
    const { actions, navigate } = this.props;
    const { newPassword, email } = this.state;
    const { data } = this.props.resetPassword;
    const { isSubmitting: nextIsSubmitting, data: nextData } = nextProps.resetPassword;

    if (data !== nextData && nextData.status === 200 && !nextIsSubmitting) {
      const credentials = {
        email: email,
        password: newPassword.password
      };

      this.setState({
        loadingUserDetails: true,
      },
      () => actions.authenticateUser(credentials)
        .then((state) => {
          if (state.auth.isAuthenticated) {
            this.setState({
              canLeave: true,
              loadingUserDetails: false
            });

            const accountType = nextProps.params['*'].split('/')[2];

            if (accountType === 'educator') {
              actions.redirect('/educator/groups/manage');
              navigate('/educator/groups/manage');
              return;
            }

            actions.redirect('/organization/admin/manage-groups');
            navigate('/organization/admin/manage-groups');
          }
        })
      );
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = () => {
      return null;
    };
  }

  isValid(field = null) {
    let validate = Validator.createValidator({
      password: ['required', 'minLength|8'],
      rePassword: ['required', 'minLength|8']
    }, this.state.newPassword, field);

    let { isValid, errors } = validate;

    this.setState({ errors });

    return isValid;
  }

  onChangeNewPassword(event){
    const { value, name } = event.target;

    this.setState({ [name]: value });
    const { newPassword } = this.state;
    newPassword[name] = value;

    this.setState({ newPassword }, () => this.isValid(name));
  }

  onSignIn(event){
    event.preventDefault();

    const { newPassword, email, token } = this.state;

    if (! this.isValid()) return false;

    if (newPassword.password !== newPassword.rePassword) {
      return toastr.error('Entered passwords does not match.');
    }

    const data = {
      'email': email,
      'token': token,
      'password': newPassword.password,
      'passwordConfirmation': newPassword.rePassword,
      'isInstitutionalAdminOnboarding': true
    };

    this.props.actions.resetPassword(data);
  }

  render() {
    const {
      newPassword,
      errors,
      loadingUserDetails
    } = this.state;
    const { isSubmitting } = this.props.resetPassword;

    if(loadingUserDetails){
      return(
        <div className="interior info">
          <Helmet title="Join CareerPrepped"/>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="content">
                  <div className="loading-container">
                    <Loading/>
                    <span>You're in! Just a sec - we're getting you all set up!</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <Helmet title="Organization - Initial Setup"/>
        <div className="interior info">
          <div className="container">
            <div className="row justify-content-md-center">
              <div
                style={{textAlign: 'center'}}
                className="content">
                <div>
                  <h6 className="mb-20">Create your password and sign in.</h6>
                  <Form>
                    <FormGroup>
                      <label className="sr-only">Password</label>
                      <Input
                        type="password"
                        name="password"
                        value={newPassword.password}
                        onChange={this.onChangeNewPassword}
                        placeholder="Password"/>
                      {errors.password &&
                        <div className="text-danger">{errors.password}</div>}
                    </FormGroup>
                    <FormGroup>
                      <label className="sr-only">Confirm Password</label>
                      <Input
                        type="password"
                        name="rePassword"
                        value={newPassword.rePassword}
                        onChange={this.onChangeNewPassword}
                        placeholder="Confirm Password"/>
                      {errors.rePassword &&
                        <div className="text-danger">{errors.rePassword}</div>}
                    </FormGroup>
                    <input
                      type="submit"
                      style={{marginTop: 10, width: 280}}
                      onClick={this.onSignIn}
                      value={isSubmitting? 'Authenticating...': 'Sign In'}
                      className="btn btn-primary"/>

                    <p className="terms-text">By registering you agree to our <Link to="/terms" target="_blank">Terms</Link></p>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    resetPassword: state.resetPassword,
    profile: state.profile,
    auth: state.auth,
    organizations: state.organizations
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({},
    organizationActions,
    resetPasswordActions,
    authActions,
    componentActions,
    routerActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(OrganizationInitialSetup));
