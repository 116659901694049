import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const ParticipantsDetailsModal = ({ isOpen, participants, onClose, currentProfile }) => {
  const participantsToShow = participants.filter(par => par.id !== currentProfile.id);

  return(
    <Modal
      className="modal-margin-top"
      isOpen={isOpen}
      toggle={onClose}>
      <ModalHeader
        style={{paddingBottom: 0}}
        toggle={onClose}>
        List of participants
      </ModalHeader>
      <ModalBody style={{paddingTop: 0}}>
        {participantsToShow.map(({ name, avatar: userPic, vanity }, index) => (
          <div key={index} className="user-details-in-messaging">
            {(vanity === 'career-prepped-bot') ?
              <span className="user-image">
                {userPic !== '' ?
                  <img className="image" width="64" height="64" src={userPic}/>:
                  <span className="image initial-avatar">{name.slice(0,1)}</span>}
              </span> :
              <Link to={`/cp/${vanity}`} className="user-image">
                {userPic !== '' ?
                  <img className="image" width="64" height="64" src={userPic}/>:
                  <div className="image initial-avatar">{name.slice(0,1)}</div>}
              </Link>}

            {(vanity === 'career-prepped-bot') ?
              <span>{name}</span> :
              <Link to={`/cp/${vanity}`}>{name}</Link>}
          </div>))}
      </ModalBody>
    </Modal>
  );
};

ParticipantsDetailsModal.propTypes = {
  isOpen: PropTypes.bool,
  participants: PropTypes.array,
  onClose: PropTypes.func,
  currentProfile: PropTypes.object
};

export default ParticipantsDetailsModal;
