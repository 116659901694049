import initialState from './initialState';

import {
  USER_SOCIAL_LINKS_REQUEST,
  USER_SOCIAL_LINKS_SUCCESS,
  USER_SOCIAL_LINKS_FAILURE,
  ADD_SOCIAL_LINKS_REQUEST,
  ADD_SOCIAL_LINKS_SUCCESS,
  ADD_SOCIAL_LINKS_FAILURE,
  UPDATE_SOCIAL_LINKS_REQUEST,
  UPDATE_SOCIAL_LINKS_SUCCESS,
  UPDATE_SOCIAL_LINKS_FAILURE,
  RESET_SOCIAL_LINKS_STATE,
} from '../../constants';

const updateLink = (state, action) => {
  const { data, isUpdating } = state;
  const indexOfLink = data.findIndex(item => item.id === isUpdating.id);

  return {
    ...state,
    data: [
      ...data.slice(0, indexOfLink),
      action.data,
      ...data.slice(indexOfLink + 1),
    ],
  };
};

export default function reducer(state = initialState.networks, action) {
  switch (action.type) {
  case RESET_SOCIAL_LINKS_STATE:
    return Object.assign({}, initialState.networks);

  case USER_SOCIAL_LINKS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case USER_SOCIAL_LINKS_SUCCESS:
    return Object.assign({}, state, {
      data: action.data._embedded.networks,
      isRequesting: false,
    });

  case USER_SOCIAL_LINKS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case ADD_SOCIAL_LINKS_REQUEST:
    return Object.assign({}, state, { isSubmitting: true });

  case ADD_SOCIAL_LINKS_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data, ...action.data.networks],
      isSubmitting: false,
    });

  case ADD_SOCIAL_LINKS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });
  case UPDATE_SOCIAL_LINKS_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, id: action.id },
    });

  case UPDATE_SOCIAL_LINKS_SUCCESS:
    return Object.assign({}, state, {
      ...updateLink(state, action),
      isUpdating: { status: false, id: null },
    });

  case UPDATE_SOCIAL_LINKS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false, id: null },
    });
  default:
    return state;
  }
}
