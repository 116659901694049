import { createSelector } from 'reselect';

const selectOccupations = state => state.occupations;

export const selectOccupationsList = createSelector(
  [selectOccupations],
  occupations => occupations.data
);

export const selectFormattedOccupations = createSelector(
  [selectOccupationsList],
  occupations =>
    occupations.map(
      occupation => ({
        label: occupation.name,
        value: occupation.id
      })
    )
);

export const selectOccupationsPageCount = createSelector(
  [selectOccupations],
  occupations => occupations.pageCount
);
