import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toastr } from 'react-redux-toastr';
import Validator from '../../validator';
import { formatFormDataArray, checkUrl } from '../../utils';

import * as fileActions from '../../redux/actions/files';
import * as componentActions from '../../redux/actions/components';

import PortfolioFileForm from '../../components/portfolio/PortfolioFileForm';

class PortfolioFileFormHandler extends Component {
  static propTypes = {
    actions: PropTypes.object,
    files: PropTypes.object,
    isEditingCard: PropTypes.bool,
    onCancel: PropTypes.func,
    fileItem: PropTypes.object,
    skillEvidence: PropTypes.bool,
    guideLinesTooltipOpen: PropTypes.bool,
    unitSkill: PropTypes.object,
    toggleGuidelinesTooltip: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      description: '',
      file: {},
      errors: {},
      title: '',
      imagePreviewUrl: '',
      url: '',
      visibilityDropdownOpen: false,
      selectedVisibiltyId: 'public',
      imagePreviewText: '',
      badges: [],
      skills: [],
      showInsertTextBox: false,
      mimetype: null
    };

    this.onDropFile = this.onDropFile.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSaveSupportingEvidence = this.onSaveSupportingEvidence.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onToggleVisibilityDropdown = this.onToggleVisibilityDropdown.bind(this);
    this.onSelectVisibilty = this.onSelectVisibilty.bind(this);
    this.setupImagePreview = this.setupImagePreview.bind(this);
    this.getSelectedBadges = selectedBadges => this.getSelectedEvidenceBadges(selectedBadges);
    this.getSelectedSkills = selectedSkills => this.getSelectedEvidenceSkills(selectedSkills);
    this.onUpdateConfirm = this.onUpdateConfirm.bind(this);
    this.onInsertLinkClicked = this.onInsertLinkClicked.bind(this);
    this.onDeleteLoadedFile = this.onDeleteLoadedFile.bind(this);
  }

  componentWillMount() {
    const {
      isEditingCard,
      fileItem
    } = this.props;

    if (isEditingCard && fileItem) {
      const { description, title, url, visibility, skills, badges, mimetype } = fileItem;

      this.setState({
        description,
        title,
        url,
        selectedVisibiltyId: visibility,
        skills,
        badges,
        mimetype
      });
    }
  }

  componentDidMount() {
    window.onbeforeunload = () => {
      return 'Changes you made might get lost!';
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = () => {
      return null;
    };
  }

  setupImagePreview(file) {
    this.setState({
      imagePreviewUrl: URL.createObjectURL(file),
      imagePreviewText: file.name,
      mimetype: file.type,
      file: file
    },() => this.isValid('file'));
  }

  onInsertLinkClicked(){
    const { showInsertTextBox } = this.state;

    this.setState({
      showInsertTextBox: !showInsertTextBox
    }, () => this.onDeleteLoadedFile);
  }

  onDeleteLoadedFile(){
    this.setState({
      imagePreviewUrl: '',
      file: {},
      imagePreviewText: '',
      url: '',
      showInsertTextBox: false,
      mimetype: null
    });
  }

  onDropFile(files) {
    if(files[0].size > 8000000)
      return toastr.info('Sorry, this image exceeds the 8MB limit.');

    this.setupImagePreview(files[0]);
  }

  onToggleVisibilityDropdown() {
    const { visibilityDropdownOpen } = this.state;
    this.setState({
      visibilityDropdownOpen: !visibilityDropdownOpen
    });
  }

  onSelectVisibilty(id){
    return () => {
      this.setState({
        selectedVisibiltyId: id,
        visibilityDropdownOpen: false
      });
    };
  }

  onChange(event) {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    }, () => this.isValid(name));

    if (name === 'url') {
      if (checkUrl(value, 'youtube') || checkUrl(value, 'vimeo')) {
        this.setState({
          file: {},
          imagePreviewUrl: '',
          mimetype: null
        }, () => this.isValid(name));
      }
    }
  }

  createFormData(description, file, title, selectedVisibiltyId, externalurl, badges, skills) {
    let formData = new FormData();

    const badgeIdsArray = badges.map(badge => typeof badge === 'object' ? badge.id : badge );
    const skillIdsArray = skills.map(skill => typeof skill === 'object' ? skill.id : skill );

    formData.append('description', description);
    formData.append('title', title);
    formData = formatFormDataArray(formData, 'badges', badgeIdsArray);
    formData.append('visibility', selectedVisibiltyId);
    formData = formatFormDataArray(formData, 'skills', skillIdsArray);
    formData.append('section', 'portfolio');

    if (externalurl !== '') {
      formData.append('externalurl', externalurl);
      return formData;
    }

    if(file){
      formData.append('file', file);
    }

    return formData;
  }

  getSelectedEvidenceBadges(badges) {
    this.setState({ badges });
  }

  getSelectedEvidenceSkills(skills) {
    this.setState({ skills });
  }

  isValid(field = null) {
    const validate = Validator.createValidator(
      {
        title: ['required', 'maxLength|60'],
        description: ['required', 'minLength|1', 'maxLength|600'],
        url: ['url'],
        file: ['choseAtleastOne|file,url']
      },
      this.state,
      field
    );

    const { errors: prevErrors } = this.state;
    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  onSaveSupportingEvidence() {
    if (!this.isValid()) return;

    let {
      description,
      file,
      title,
      badges,
      skills,
      url: externalurl,
      selectedVisibiltyId,
    } = this.state;

    this.props.actions.uploadFile(
      this.createFormData(description, file, title, selectedVisibiltyId, externalurl, badges, skills)
    )
      .then(() => {
        this.handleResponse('File uploaded successfully');
      });
  }

  handleResponse(msg) {
    this.setState({
      description: '',
      file: {},
      errors: {},
      title: '',
      imagePreviewUrl: '',
      url: '',
      badges: [],
      skills: [],
      showInsertTextBox: false,
      mimetype: null
    });

    this.props.onCancel();

    toastr.success(msg);
  }

  onUpdate(){
    if (!this.isValid()) return;

    this.onUpdateConfirm();
  }

  onUpdateConfirm() {

    const {
      description, file, title, url: externalurl, selectedVisibiltyId, badges, skills
    } = this.state;

    const { actions, fileItem, skillEvidence } = this.props;

    actions.updateFile(
      fileItem.id,
      this.createFormData(description, file, title, selectedVisibiltyId, externalurl, badges, skills),
      skillEvidence
    )
      .then(() => {
        this.handleResponse('File updated successfully');
      });
  }

  render() {
    const {
      description,
      title,
      imagePreviewUrl,
      url,
      imagePreviewText,
      errors,
      visibilityDropdownOpen,
      selectedVisibiltyId,
      skills,
      badges,
      showInsertTextBox,
      mimetype
    } = this.state;

    const {
      files,
      isEditingCard,
      onCancel,
      fileItem,
      guideLinesTooltipOpen,
      toggleGuidelinesTooltip,
      skillEvidence,
      unitSkill
    } = this.props;

    return (
      <div>
        <PortfolioFileForm
          onDropFile={this.onDropFile}
          onInsertLinkClicked={this.onInsertLinkClicked}
          onDeleteLoadedFile={this.onDeleteLoadedFile}
          showInsertTextBox={showInsertTextBox}
          onChange={this.onChange}
          onSaveSupportingEvidence={this.onSaveSupportingEvidence}
          onCancel={onCancel}
          description={description}
          files={files}
          skills={skills}
          badges={badges}
          isEditingCard={isEditingCard}
          onDelete={this.onDelete}
          onUpdate={this.onUpdate}
          title={title}
          mimetype={mimetype}
          imagePreviewUrl={imagePreviewUrl}
          fileItem={fileItem}
          url={url}
          imagePreviewText={imagePreviewText}
          errors={errors}
          selectedVisibiltyId={selectedVisibiltyId}
          visibilityDropdownOpen={visibilityDropdownOpen}
          onToggleVisibilityDropdown={this.onToggleVisibilityDropdown}
          onSelectVisibilty={this.onSelectVisibilty}
          getSelectedBadges={this.getSelectedBadges}
          getSelectedSkills={this.getSelectedSkills}
          guideLinesTooltipOpen={guideLinesTooltipOpen}
          toggleGuidelinesTooltip={toggleGuidelinesTooltip}
          skillEvidence={skillEvidence}
          unitSkill={unitSkill}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    files: state.files,
    unitSkill: state.skills.unitSkill
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, fileActions, componentActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioFileFormHandler);
