import PropTypes from 'prop-types';
import React from 'react';

const BadgeSubmitSection = ({
  badge,
  issuedBadge,
  isAuthenticated,
  toggleShareAndVerifyCheckboxes
}) => {
  return (
    <div>
      <div className="claim-item">
        <h5>
          <strong>Step 3.  Verify Your Evidence</strong>
          <span className="small text-danger"/>
        </h5>
        <label className="form-check-label mt-1">
          <input
            disabled={!badge || !isAuthenticated}
            className="form-check-input"
            type="checkbox"
            name="verified"
            value={issuedBadge.verify}
            checked={issuedBadge.verified}
            onChange={toggleShareAndVerifyCheckboxes}/>
          <p className="verify">
            I verify that my acknowledgement and justification statements are truthful reflections of my personal beliefs. I understand that all supporting evidence I provide for my skill claim will be linked to my Skill Badge.
          </p>
          <p>
            <em>You’ll have an opportunity to further strengthen your claimed Skill Badge by seeking endorsements from others.</em>
          </p>
        </label>
      </div>
    </div>
  );
};

BadgeSubmitSection.propTypes = {
  badge: PropTypes.object,
  issuedBadge: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  toggleShareAndVerifyCheckboxes: PropTypes.func.isRequired
};

export default BadgeSubmitSection;
