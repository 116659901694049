import React from 'react';
import BaseActionComponent from '../../containers/my-feedback/baseActionComponent';
import BaseRequestedOn from './table-columns/BaseRequestedOn';
import BaseRequestorComponent from './table-columns/BaseRequestorComponent';
import BaseSkillComponent from './table-columns/BaseSkillComponent';

// MyFeedback Request Sorting Table Components -------------------------------------------------
const PendingFeedbackComponent = (props) => {
  return props.tblData.map(feedback => (
    <tr key={feedback.feedbackRequestId}>
      <BaseRequestedOn rowData={feedback} {...props} />
      <BaseSkillComponent rowData={feedback} {...props} />
      <BaseRequestorComponent rowData={feedback} />
      <BaseActionComponent rowData={feedback} {...props} />
    </tr>
  ));
};

export default PendingFeedbackComponent;