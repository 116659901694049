import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { resetSubmittingDuplicateGroups } from '../../redux/actions/groups';
import { resetSubmittingDuplicateEducatorGroups } from '../../redux/actions/educators';

import { Alert } from 'reactstrap';

class CreateGroupAlert extends Component {
  static propTypes = {
    role: PropTypes.string.isRequired,
    actions: PropTypes.object.isRequired,
    duplicateGroups: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props);

    this.onDismiss = this.onDismiss.bind(this);
  }

  onDismiss() {
    const { role, actions } = this.props;

    if (role === 'educator') {
      actions.resetSubmittingDuplicateEducatorGroups();
      return;
    }

    actions.resetSubmittingDuplicateGroups();
  }

  render() {
    const { duplicateGroups } = this.props;

    const visible = duplicateGroups.length !== 0;

    return (
      <Alert color="primary" isOpen={visible} toggle={this.onDismiss}>
        You have duplicate group names. Please choose a unique name for each group you create.<br/>
        <ul>
          {duplicateGroups.map(group => (
            <li key={group.id}>{group.name}</li>
          ))}
        </ul>
      </Alert>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    profile: { data: { role } },
    groups: { isSubmitting: { duplicateGroups: institutionDuplicateGroups } },
    educators: { isSubmitting: { duplicateGroups: educatorDuplicateGroups } }
  } = state;

  const duplicateGroups = (role === 'educator') ?
    educatorDuplicateGroups : institutionDuplicateGroups;

  return {
    role,
    duplicateGroups
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    resetSubmittingDuplicateGroups,
    resetSubmittingDuplicateEducatorGroups
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroupAlert);
