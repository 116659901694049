import PropTypes from 'prop-types';
import React from 'react';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import classNames from 'classnames';

const jobStatusValues = [
  {
    title: 'All Jobs',
    value: null
  },
  {
    title: 'Closed',
    value: 0
  },
  {
    title: 'Saved Jobs',
    value: 1
  },
  {
    title: 'Applications',
    value: 2
  },
  {
    title: 'Interviews',
    value: 3
  },
  {
    title: 'Offers',
    value: 4
  },
  {
    title: 'No Response',
    value: 5
  },
  {
    title: 'Rejected',
    value: 6
  },
];

const jobSortValues = [
  {
    title: 'Sort',
    value: null
  },
  {
    title: 'Date Created',
    value: 'date'
  },
  {
    title: 'Job Title (A-Z)',
    value: 'title'
  },
  {
    title: 'Most Recent Notes',
    value: 'recent_note'
  }
];

const SavedJobsFilter = ({
  filterDropdownOpen,
  sortDropdownOpen,
  toggleFilterDropdown,
  toggleSortDropdown,
  onSelectFilter,
  onSelectSort,
  activeFilter,
  activeSort
}) => {
  const activeFilterItem = jobStatusValues.find(option => option.value === activeFilter);
  const activeSortItem = jobSortValues.find(option => option.value === activeSort);

  return (
    <div>
      <div className="filterbar filterbar-jobcenter jobboard-bar-filter mb-3">
        <Dropdown isOpen={filterDropdownOpen} toggle={toggleFilterDropdown}>
          <DropdownToggle caret className="btn-dropdown-toggle">
            {activeFilterItem === null ? 'All jobs' : activeFilterItem.title}
          </DropdownToggle>
          <DropdownMenu>
            {jobStatusValues.map((option, index) =>
              (<DropdownItem key={index}
                className={classNames(
                  'dropdown-item grey-dropdown-btn',
                  { 'active-item-filter': option.value === activeFilter})}
                onClick={onSelectFilter(option.value)}>{option.title}</DropdownItem>))}
          </DropdownMenu>
        </Dropdown>

        <Dropdown isOpen={sortDropdownOpen} toggle={toggleSortDropdown}>
          <DropdownToggle caret className="btn-dropdown-toggle">
            {activeSortItem === null ? 'Sort' : activeSortItem.title}
          </DropdownToggle>
          <DropdownMenu>
            {jobSortValues.map((item, index) =>
              (<DropdownItem key={index}
                className={classNames(
                  'dropdown-item grey-dropdown-btn',
                  { 'active-item-filter': item.value === activeSort })}
                onClick={onSelectSort(item.value)}>{item.title}</DropdownItem>))}
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};

SavedJobsFilter.propTypes = {
  toggleFilterDropdown: PropTypes.func.isRequired,
  toggleSortDropdown: PropTypes.func.isRequired,
  onSelectFilter: PropTypes.func.isRequired,
  onSelectSort: PropTypes.func.isRequired,
  sortDropdownOpen: PropTypes.bool.isRequired,
  filterDropdownOpen: PropTypes.bool.isRequired,
  activeFilter: PropTypes.number,
  activeSort: PropTypes.string
};

export default SavedJobsFilter;
