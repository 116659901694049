import PropTypes from 'prop-types';
import React, { Component } from 'react';

import CreateResumeSection from './CreateResumeSection';

class CreateResumeDraggableList extends Component {
  static propTypes = {
    resumeOrder: PropTypes.array,
    onRearrangeResumeOrder: PropTypes.func.isRequired,
    resume: PropTypes.object.isRequired,
    onUnloadForm: PropTypes.func.isRequired,
    onLoadForm: PropTypes.func.isRequired,
    forms: PropTypes.array,
    onChangeSection: PropTypes.func.isRequired,
    onChangeSectionTitle: PropTypes.func.isRequired,
    onRemoveResumeSection: PropTypes.func.isRequired
  }

  render() {
    const {
      forms,
      onUnloadForm,
      onChangeSection,
      onLoadForm,
      onChangeSectionTitle,
      resume,
      resumeOrder,
      onRemoveResumeSection
    } = this.props;

    return (
      <div>
        {resumeOrder.map((section, index) =>
          (<CreateResumeSection
            key={section.id}
            index={index}
            forms={forms}
            onUnloadForm={onUnloadForm}
            onLoadForm={onLoadForm}
            onChangeSection={onChangeSection}
            onChangeSectionTitle={onChangeSectionTitle}
            section={section}
            resume={resume}
            onRemoveResumeSection={onRemoveResumeSection}/>
          ))}
      </div>
    );
  }
}


export default CreateResumeDraggableList;
