import PropTypes from 'prop-types';
import React from 'react';

const CommentLikeButton = ({ onLike, comment, isLikingComment}) => {
  const commentLikeCondition = comment && comment.liked;

  if (commentLikeCondition) {
    return(
      <span style={{paddingRight: 5, color: '#337ab7'}}>
        <a
          className="clickable"
          onClick={onLike}>
          {
            isLikingComment ? 'Unliking ...':'Unlike'
          }
        </a>
      </span>
    );
  }

  return(
    <span style={{paddingRight:5}}>
      <a
        className="like-btn"
        onClick={onLike}>
        {
          isLikingComment ? 'Liking ...':'Like'
        }
      </a>
    </span>
  );
};
CommentLikeButton.propTypes = {
  onLike: PropTypes.func.isRequired,
  isLikingComment: PropTypes.bool,
  comment: PropTypes.object
};
export default CommentLikeButton;
