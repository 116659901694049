import React from 'react';

import PropTypes from 'prop-types';
import Education from '../../../containers/profile/sections/Education';
import { Modal, ModalBody } from 'reactstrap';
import CloseIcon from '../../../assets/images/close.svg';

function AddEducation({isOpen, toggleModal, profile}) {	
  function onChangeVisibility(){}
  function onToggleSectionEdit(){}

  return (
    <Modal
      size="lg"
      centered
      className="customLevelUpModal"
      isOpen={isOpen}
      toggle={toggleModal}>
      <div className="modal-header">
        <span className="modal-title">Add Education</span>
        <div
          onClick={toggleModal}
          className="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">
            <img src={CloseIcon} />
          </span>
        </div>
      </div>
      <ModalBody>
        <Education
          profile={profile}
          isPublicProfile={false}
          onChangeVisibility={onChangeVisibility}
          onToggleSectionEdit={onToggleSectionEdit}
          isEditingSections
          isEditing
          onCancelModal={toggleModal}
          showVisibilitySelect
          isEditingFromModal/>
      </ModalBody>
    </Modal>
  );
}

AddEducation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

export default AddEducation;