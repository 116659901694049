import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import { toastr } from 'react-redux-toastr';

import { withHooks } from '../../utils/withHooks';

import * as sectionsActions from '../../redux/actions/sections';
import * as reflectionsActions from '../../redux/actions/reflections';
import * as skillBuildersActions from '../../redux/actions/skill-builders';

import StickyDiv from '../common/stickyDiv/StickyDiv';
import Header from '../../components/skill-builders/essentials/Header';
import DescriptionList from '../../components/skill-builders/admin/DescriptionList';
import Loading from '../../components/common/Loading';

const engageIcon = require('../../assets/images/icon-engage.png');
const reviewIcon = require('../../assets/images/icon-review.png');

class SkillBuilder extends Component {
  static propTypes = {
    slug: PropTypes.string,
    unit: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    isRequestingUnit: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    location: PropTypes.object,
    pathname: PropTypes.string,
    navigate: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      path: '',
      unitTitle: '',
      noSection: false,
      claimBadgeText: 'Claim Badge',
      claimBadgeStyles: 'btn'
    };

    this.onClickReviewEssentials = this.onClickReviewEssentials.bind(this);
    this.onClickEngage = this.onClickEngage.bind(this);
    this.onClickGetBadge = this.onClickGetBadge.bind(this);
  }

  componentDidMount() {
    const { params, actions, isAuthenticated } = this.props;
    const { slug } = params;

    actions.unitRequest(slug, isAuthenticated);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEmpty(nextProps.unit) && nextProps.unit !== this.props.unit) {
      const unitTitle = `${nextProps.unit.code} ${nextProps.unit.title}`;

      this.setState({
        unitTitle,
        path: nextProps.location.pathname
      });
    }

    if (!nextProps.isRequestingUnit && `${nextProps.unit.sections}`.trim() === '')
      return this.setState({
        noSection: true,
        claimBadgeText: 'Unit Incomplete',
        claimBadgeStyles: 'btn claimed'
      });

    if (!nextProps.isRequestingUnit)
      this.setState({
        noSection: false,
        claimBadgeText: 'Claim Badge',
        claimBadgeStyles: 'btn'
      });
  }

  componentWillUpdate(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname)
      this.setState({
        path: nextProps.location.pathname
      });
  }

  onClickReviewEssentials(event){
    event.preventDefault();
    const { isAuthenticated, navigate, params } = this.props;
    const redirectPath = 'skill-builders';

    if(!isAuthenticated)
      return toastr.error('Permission is Needed.', 'You must be signed in to access.');

    navigate(`/${redirectPath}/essentials/${params.slug}`);

  }

  onClickEngage(event){
    event.preventDefault();
    const { isAuthenticated, navigate, params } = this.props;
    const redirectPath = 'skill-builders';

    if(!isAuthenticated)
      return toastr.error('Permission is Needed.', 'You must be signed in to join the discussion.');

    navigate(`/${redirectPath}/discussions/${params.slug}`);

  }

  onClickGetBadge(link){
    return () => {
      const { isAuthenticated, navigate } = this.props;

      if(!isAuthenticated)
        return toastr.error('Permission is Needed.', 'You must be signed in to claim a badge.');

      navigate(link);
    };
  }

  render() {
    const { unit, params, isAuthenticated, isRequestingUnit } = this.props;
    const { slug } = params;

    const {
      unitTitle,
      path,
      noSection
    } = this.state;

    const isBadgeClaimed = isObject(unit.badge) && unit.badge.isClaimed;
    const unitHasSections = !isUndefined(unit.sectionsOrder) && unit.sectionsOrder.length !== 0;
    let claimBadgeText = !unitHasSections ? 'Unit Incomplete' : 'Claim Badge';
    const hasNoBadge = unit.badge === '';

    const claimBadgeStyles = classNames('btn', {
      'claimed': !unitHasSections || hasNoBadge,
      'baked-badge': isBadgeClaimed
    });

    const redirectPath = 'skill-builders';

    let claimBadgeAnchorLink = unitHasSections ? `/${redirectPath}/claim-badge/${slug}` : null;

    if(hasNoBadge){
      claimBadgeText = 'No Badge Available';
      claimBadgeAnchorLink = null;
    }

    if (isBadgeClaimed) {
      claimBadgeText = 'View Your Badge';
      claimBadgeAnchorLink = `/skill-badges/badge-details/${unit.badge.issued.id}`;
    }

    const badgeIcon = isObject(unit.badge) ? unit.badge.url : undefined;

    return (
      <div>
        <Helmet title={`Skill Builders - ${unit.code} ${unit.title}`}/>

        <div className="container" style={{ marginTop: 30 }}>
          <div className="row">
            <StickyDiv
              component={Header}
              startId={'essHeader'}
              endId={'skill-builder-individual'}
              defaultCss={'intro-header container'}
              unit={unit}
              slug={slug}
              unitTitle={unitTitle}
              path={path}
              isAuthenticated={isAuthenticated}
              isRequesting={isRequestingUnit}
              noSection={noSection}/>

            <div className="col-lg-12">
              <div className="essential-wrapper" id="skill-builder-individual">
                <div className="essential-intro">
                  <div className="content">
                    <div className="info">
                      <div className="row">
                        <div className="col-lg-5">
                          <h2>{'Having this skill means you\'re able to...'}</h2>

                          {!isEmpty(unit) &&
                            <DescriptionList
                              unit={unit}
                              isRequesting={isRequestingUnit}/>}
                        </div>

                        <div className="col-lg-7">
                          <p/>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-4">
                        <div className="essential-action review">
                          <div className="icon endsection-icon-container">
                            <img className="img-responsive img-fluid" src={reviewIcon} width="42" height="auto" />
                          </div>

                          <p>
                            Reflect on this skill & practice using practical tips presented in a concise format.
                          </p>

                          <a onClick={this.onClickReviewEssentials} className="btn">
                            <p style={{ color: 'white' }}>
                              Reflect & Practice
                            </p>
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="essential-action engage">
                          <div className="icon endsection-icon-container">
                            <img className="img-responsive img-fluid" src={engageIcon} width="42" height="auto" />
                          </div>

                          <p>
                            Share your thoughts and insights on developing this skill. Comment on existing posts and link up with your peers.
                          </p>

                          <a onClick={this.onClickEngage} className="btn">
                            <p style={{ color: 'white' }}>
                              Engage &amp; Connect
                            </p>
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="essential-action engage">
                          {isRequestingUnit &&
                            <div className="icon endsection-icon-container"><Loading /></div>}

                          {(!hasNoBadge && !isRequestingUnit) &&
                            <div className="icon badge-icon no-backgroud">
                              <img src={badgeIcon} width="100" className="unit-badge-image"/>
                            </div>}

                          {(hasNoBadge && !isRequestingUnit) &&
                            <div className="icon endsection-icon-container"><i className="fa fa-certificate"/></div>}

                          <p>
                            Get your Skill Badge if you believe you’ve acquired this skill and can support your claim with evidence.
                          </p>

                          <a
                            onClick={this.onClickGetBadge(claimBadgeAnchorLink)}
                            className={claimBadgeStyles}>
                            {isRequestingUnit ? <Loading /> : claimBadgeText}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { profile, skillBuilders, auth } = state;

  return {
    unit: skillBuilders.unit,
    isRequestingUnit: skillBuilders.isRequestingUnit,
    isAuthenticated: auth.isAuthenticated,
    skillBuilders: skillBuilders.data,
    profile: profile.data
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    sectionsActions,
    reflectionsActions,
    skillBuildersActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SkillBuilder));
