import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

const DateSentCell = ({ invite }) => {
  return (
    <span>
      {moment(invite.createdAt.date).format('MM/DD/YY')}
    </span>
  );
};

DateSentCell.propTypes = {
  invite: PropTypes.object.isRequired
};

export default DateSentCell;
