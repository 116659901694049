import isNull from 'lodash/isNull';

import initialState from './initialState';

import {
  SECTIONS_RESET,
  SECTIONS_REQUEST,
  SECTIONS_SUCCESS,
  SECTIONS_FAILURE,
  CREATE_SECTION_REQUEST,
  CREATE_SECTION_SUCCESS,
  CREATE_SECTION_FAILURE,
  UPDATE_SECTION_INDEX_SUCCESS,
  UPDATE_SECTION_REQUEST,
  UPDATE_SECTION_SUCCESS,
  UPDATE_SECTION_FAILURE,
  DELETE_SECTION_REQUEST,
  DELETE_SECTION_SUCCESS,
  DELETE_SECTION_FAILURE,
  TOGGLE_IS_DRAGGING,
} from '../../constants';

export default function reducer(state = initialState.sections, action) {
  switch (action.type) {
  case TOGGLE_IS_DRAGGING:
    return Object.assign({}, state, { isDragging: !state.isDragging });

  case SECTIONS_RESET:
    return initialState.sections;

  case SECTIONS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case SECTIONS_SUCCESS:
    return Object.assign({}, state, {
      data: isNull(action.data._embedded.section[0])
        ? initialState.sections.data
        : action.data._embedded.section,
      isRequesting: false,
    });

  case SECTIONS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case CREATE_SECTION_REQUEST:
    return Object.assign({}, state, { isSubmitting: true });

  case CREATE_SECTION_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data, Object.assign({}, action.data)],
      isSubmitting: false,
    });

  case CREATE_SECTION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case UPDATE_SECTION_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, sectionIndex: action.index },
    });

  case UPDATE_SECTION_INDEX_SUCCESS:
    return Object.assign({}, state, {
      data: action.data,
    });

  case UPDATE_SECTION_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, state.isUpdating.sectionIndex),
        Object.assign({}, action.data),
        ...state.data.slice(state.isUpdating.sectionIndex + 1),
      ],
      isUpdating: { status: false, sectionIndex: null },
    });

  case UPDATE_SECTION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false, sectionIndex: null },
    });

  case DELETE_SECTION_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: action.id },
    });

  case DELETE_SECTION_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.filter(section => section.id !== state.isDeleting.id),
      ],
      isDeleting: { status: false, id: null },
    });

  case DELETE_SECTION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: { status: false, id: null },
    });

  default:
    return state;
  }
}
