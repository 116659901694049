import PropTypes from 'prop-types';
import React from 'react';

import SavedJobsList from './SavedJobList';
import AddJob from '../../containers/jobs/AddJob';

import { loadForm } from '../../utils';

const SavedJobsContainer = ({
  jobs,
  isDeleting,
  isGuestUser,
  isRequesting,
  activeJobStatusDropup,
  toggleJobStatusDropUp,
  onSelectJobStatus,
  deleteJob,
  loadForms,
  callingAPI,
  confirmDeleteJobModalIsOpen,
  onConfirmDeleteJob,
  onCancelDeleteJob
}) => {
  const isForm = loadForm(loadForms, 'add-new-job-to-board');

  if (isForm) {
    return <AddJob />;
  }

  return (
    <SavedJobsList
      jobs={jobs}
      isGuestUser={isGuestUser}
      callingAPI={callingAPI}
      deleteJob={deleteJob}
      isDeleting={isDeleting}
      isRequesting={isRequesting}
      onSelectJobStatus={onSelectJobStatus}
      toggleJobStatusDropUp={toggleJobStatusDropUp}
      activeJobStatusDropup={activeJobStatusDropup}
      onConfirmDeleteJob={onConfirmDeleteJob}
      onCancelDeleteJob={onCancelDeleteJob}
      confirmDeleteJobModalIsOpen={confirmDeleteJobModalIsOpen}/>
  );
};

SavedJobsContainer.propTypes = {
  isGuestUser: PropTypes.bool.isRequired,
  toggleJobStatusDropUp: PropTypes.func.isRequired,
  onSelectJobStatus: PropTypes.func.isRequired,
  loadForms: PropTypes.array.isRequired,
  jobs: PropTypes.array.isRequired,
  deleteJob: PropTypes.func.isRequired,
  activeJobStatusDropup: PropTypes.number,
  isDeleting: PropTypes.object,
  isRequesting: PropTypes.bool.isRequired,
  callingAPI: PropTypes.bool,
  confirmDeleteJobModalIsOpen: PropTypes.bool.isRequired,
  onConfirmDeleteJob: PropTypes.func.isRequired,
  onCancelDeleteJob: PropTypes.func.isRequired
};

export default SavedJobsContainer;
