import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Validator from '../../validator';
import { loadForm, guid } from '../../utils';

import * as componentActions from '../../redux/actions/components';

import PublicationSectionCard from '../../components/resume/sections/PublicationSectionCard';
import PublicationForm from '../../components/profile/forms/PublicationForm';
import DeleteSectionItemConfirmModal from '../../components/resume/DeleteSectionItemConfirmModal';

class ResumePublications extends Component {
  static propTypes = {
    publications: PropTypes.object,
    onChangeSection: PropTypes.func.isRequired,
    onChangeSectionTitle: PropTypes.func.isRequired,
    onUnloadForm: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    resumePublications: PropTypes.object.isRequired,
    onLoadForm: PropTypes.func.isRequired,
    forms: PropTypes.array,
    onRemoveResumeSection: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      editingId: null,
      removePublicationId: null,
      title: props.resumePublications.title,
      publications: Object.assign({}, this.props.publications),
      editorText: {description: this.props.publications.description}
    };

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onEditPublication = this.onEditPublication.bind(this);
    this.onRemovePublication = this.onRemovePublication.bind(this);
    this.onRichEditorChange = this.onRichEditorChange.bind(this);
    this.onCancelRemovePublication = this.onCancelRemovePublication.bind(this);
    this.onConfirmRemovePublication = this.onConfirmRemovePublication.bind(this);
  }

  isValid(field = null) {
    const rules = {
      title: ['required', 'minLength|6', 'maxLength|300'],
      author: ['maxLength|300'],
      publication: ['maxLength|300'],
      puburl: ['unsafeurl'],
      pubdate: ['required', 'startdateYear'],
    };

    const { publications, errors: prevErrors} = this.state;
    const validate = Validator.createValidator(rules, publications, field);
    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  isValidDescription(field = null) {
    const rules = {
      description: ['maxWords|200']
    };
    const { editorText, errors: prevErrors } = this.state;
    const validate = Validator.createValidator(rules, editorText, field);
    const { errors, isValid } = validate;

    if (field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }
    this.setState({ errors: Object.assign({}, prevErrors, errors) });

    return isValid;
  }


  onChangeTitle(event){
    const { value } = event.target;
    this.props.onChangeSectionTitle(value, 'publications');
  }

  handleChange(event){
    let { name, value } = event.target;
    const { publications } = this.state;

    publications[name] = value;

    this.setState({ publications }, () => this.isValid(name));
  }

  onRichEditorChange(description, ...params) {
    let { publications, editorText } = this.state;

    publications = Object.assign({}, publications, { description });
    editorText['description'] = params[2].getText().replace(/\r?\n|\r/gm, ' ');

    this.setState({ publications, editorText }, () => this.isValidDescription('description') );
  }

  onSave(){
    const { publications, editingId } = this.state;
    const { resumePublications } = this.props;

    if (!this.isValid() || !this.isValidDescription())
      return;

    const newPublication = Object.assign({},{
      id: guid()
    }, publications );

    const index = resumePublications.publicationsArray.findIndex(publication => publication.id === editingId);

    const publicationsArray = editingId === null ? [
      ...resumePublications.publicationsArray,
      newPublication
    ] : [
      ...resumePublications.publicationsArray.slice(0, index),
      newPublication,
      ...resumePublications.publicationsArray.slice(index+1)
    ];

    const data = Object.assign({}, resumePublications,{
      publicationsArray
    });

    this.props.onChangeSection(data, 'publications');
    this.onCancel();
  }

  onCancel(){
    const { unloadForm } = this.props.actions;
    this.clearFormField();
    unloadForm('editPublicationsForm');
  }

  clearFormField(){
    const publications = {
      title: '',
      publication: '',
      puburl: '',
      pubdate: '',
      author: '',
      description: '',
    };

    this.setState({
      publications,
      errors: {},
      editingId: null
    });
  }

  onEditPublication(event){
    const { id } = event.target;
    const { loadForm } = this.props.actions;
    const { publicationsArray } = this.props.resumePublications;
    const publicationObject = publicationsArray.find(publication => publication.id === id);
    const publications = Object.assign({}, this.state.publications, publicationObject);

    this.setState({
      publications,
      editingId: id
    }, ()=> loadForm('editPublicationsForm'));
  }

  onRemovePublication(event){
    const { id: removePublicationId } = event.target;

    this.setState({ removePublicationId });
  }

  onConfirmRemovePublication() {
    const { removePublicationId } = this.state;
    const { resumePublications } = this.props;

    const publicationsArray = resumePublications
      .publicationsArray
      .filter(publication => publication.id !== removePublicationId);

    const data = Object.assign({}, resumePublications, { publicationsArray });

    this.props.onChangeSection(data, 'publications');
    this.onCancelRemovePublication();
  }

  onCancelRemovePublication() {
    this.setState({ removePublicationId: null });
  }

  render() {
    const { resumePublications, onLoadForm, forms, onRemoveResumeSection } = this.props;
    const { publications, errors, removePublicationId } = this.state;

    const isFormOpen = loadForm(forms, 'editPublicationsForm');
    const isConfirmModalOpen = removePublicationId !== null;

    return (
      <div className="resume-section create-resume-section-card">
        <div className="create-resume-title">
          <input
            type="text"
            value={resumePublications.title}
            placeholder="Publications header"
            className="header-edit-textbox"
            onChange={this.onChangeTitle}/>
          <button
            onClick={onRemoveResumeSection}
            id="Publication"
            className="btn btn-danger btn-sm">
            <i className="fa fa-close"/>
          </button>
        </div>
        {
          !isFormOpen &&
            <div className="section-list-container">
              {resumePublications.publicationsArray.map(publication=>
                (<PublicationSectionCard
                  key={publication.id}
                  publicationDetails={publication}
                  onEditPublication={this.onEditPublication}
                  onRemovePublication={this.onRemovePublication}/>))}
              <button
                onClick={onLoadForm}
                data-form-name="editPublicationsForm"
                className="btn btn-primary w-100">
                Add Publication
              </button>
            </div>
        }

        {isFormOpen &&
          <div className="resume-form-container">
            <PublicationForm
              resume
              errors={errors}
              publication={publications}
              onSave={this.onSave}
              onCancel={this.onCancel}
              onChange={this.handleChange}
              onChangeDescription={this.onRichEditorChange}/>
          </div>}

        {isConfirmModalOpen &&
          <DeleteSectionItemConfirmModal
            onCancel={this.onCancelRemovePublication}
            onConfirm={this.onConfirmRemovePublication}
            isOpen={isConfirmModalOpen}/>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const publications = {
    title: '',
    publication: '',
    puburl: '',
    pubdate: '',
    author: '',
    description: '',
  };

  return {
    publications,
    forms: state.components.forms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(componentActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResumePublications);
