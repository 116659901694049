import { CALL_API } from '../../middlewares/api';

import {
  GROUPS_REQUEST,
  GROUPS_SUCCESS,
  GROUPS_FAILURE,
  GROUP_ADMINS_REQUEST,
  GROUP_ADMINS_SUCCESS,
  GROUP_ADMINS_FAILURE,
  RESET_GROUP_ADMINS,
  CREATE_GROUPS_REQUEST,
  CREATE_GROUPS_SUCCESS,
  CREATE_GROUPS_FAILURE,
  RESET_SUBMITTING_DUPLICATE_GROUPS,
  UPDATE_GROUP_REQUEST,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAILURE,
  UPDATE_GROUP_ADD_ADMINS_REQUEST,
  UPDATE_GROUP_ADD_ADMINS_SUCCESS,
  UPDATE_GROUP_ADD_ADMINS_FAILURE,
  UPDATE_GROUP_REMOVE_ADMINS_REQUEST,
  UPDATE_GROUP_REMOVE_ADMINS_SUCCESS,
  UPDATE_GROUP_REMOVE_ADMINS_FAILURE,
  ONCHANGE_GROUPS_SEARCH_QUERY,
  SET_SELECTED_GROUP,
  SET_SELECTED_PARENT_GROUP,
  SET_IS_AUTH_USER_SELECTED_INSTITUTION_ADMIN,
} from '../../constants';

export const getUserAdminGroups = (
  selectedInstitutionId = '',
  urlParamString = '',
  isInitialRequest = false
) => {
  let endpoint = 'user/groups';

  if (selectedInstitutionId.length !== 0) {
    endpoint = `${endpoint}?institution=${selectedInstitutionId}`;
  }

  if (urlParamString.length !== 0) {
    endpoint = `${endpoint}${urlParamString}`;
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      isInitialRequest,
      authenticated: true,
      types: [GROUPS_REQUEST, GROUPS_SUCCESS, GROUPS_FAILURE],
    },
  };
};

export const getGroupAdmins = (groupId, urlParamString = '') => {
  const endpoint = `institution/admins?group=${groupId}${
    urlParamString.length !== 0 ? urlParamString : ''
  }`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [GROUP_ADMINS_REQUEST, GROUP_ADMINS_SUCCESS, GROUP_ADMINS_FAILURE],
    },
  };
};

export const createGroups = (institutionId, data) => {
  const endpoint = `institution/${institutionId}/groups`;

  const id = data.parentGroupId ? data.parentGroupId : null;

  return {
    [CALL_API]: {
      data,
      endpoint,
      id,
      method: 'patch',
      authenticated: true,
      types: [
        CREATE_GROUPS_REQUEST,
        CREATE_GROUPS_SUCCESS,
        CREATE_GROUPS_FAILURE,
      ],
    },
  };
};

export const updateGroup = (groupId, data) => {
  const endpoint = `group/group/${groupId}`;

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'patch',
      authenticated: true,
      types: [UPDATE_GROUP_REQUEST, UPDATE_GROUP_SUCCESS, UPDATE_GROUP_FAILURE],
    },
  };
};

export const updateGroupAddAdmins = (groupId, data) => {
  const endpoint = `group/${groupId}/admins`;

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'patch',
      authenticated: true,
      types: [
        UPDATE_GROUP_ADD_ADMINS_REQUEST,
        UPDATE_GROUP_ADD_ADMINS_SUCCESS,
        UPDATE_GROUP_ADD_ADMINS_FAILURE,
      ],
    },
  };
};

export const updateGroupRemoveAdmins = (groupId, data, adminId) => {
  const endpoint = `group/${groupId}/admins`;

  return {
    [CALL_API]: {
      data,
      id: adminId,
      endpoint,
      method: 'patch',
      authenticated: true,
      types: [
        UPDATE_GROUP_REMOVE_ADMINS_REQUEST,
        UPDATE_GROUP_REMOVE_ADMINS_SUCCESS,
        UPDATE_GROUP_REMOVE_ADMINS_FAILURE,
      ],
    },
  };
};

export const resetGroupAdmins = () => ({ type: RESET_GROUP_ADMINS });

export const setSelectedGroup = (
  selectedGroup,
  isChildGroupSelected = false
) => ({ type: SET_SELECTED_GROUP, selectedGroup, isChildGroupSelected });

export const setSelectedParentGroup = selectedParentGroup => ({
  type: SET_SELECTED_PARENT_GROUP,
  selectedParentGroup,
});

export const setIsAuthUserSelectedInstitutionAdmin = isAdmin => ({
  type: SET_IS_AUTH_USER_SELECTED_INSTITUTION_ADMIN,
  isAdmin,
});

export const resetSubmittingDuplicateGroups = () => ({
  type: RESET_SUBMITTING_DUPLICATE_GROUPS,
});

export const onChangeGroupsSearchQuery = searchQuery => ({
  type: ONCHANGE_GROUPS_SEARCH_QUERY,
  searchQuery,
});
