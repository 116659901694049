import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import RichEditor from '../../../plugins/RichEditor';

const blockTypes = [
  { label: 'Link', style: 'LINK', icon: 'fa-link' },
  { label: 'ul', style: 'unordered-list-item', icon: 'fa-list-ul' },
  { label: 'ol', style: 'ordered-list-item', icon: 'fa-list-ol' }
];

const inlineTypes = [
  { label: 'Bold', style: 'BOLD', icon: 'fa-bold'},
  { label: 'Italic', style: 'ITALIC', icon: 'fa-italic' },
  { label: 'Underline', style: 'UNDERLINE', icon: 'fa-underline' }
];

const styles = {
  editorWrapper: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    padding: '.5rem .5rem',
    borderRadius: 4,
  },
  withError: {
    border: '1px solid #d9534f',
    padding: '.5rem .5rem',
    borderRadius: 4,
  },
  addListItemGroup: {
    position: 'absolute',
    right: 10,
    fontSize: '.8rem'
  },
  errorText: {
    fontSize: 14,
    color: '#d9534f'
  },
  cardWrapper: { marginBottom: 15 }
};

class QuizSectionForm extends Component {

  static propTypes = {
    errors: PropTypes.object,
    question: PropTypes.string,
    onChangeQuestion: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    let editorState = EditorState.createEmpty();

    if (props.question !== '') {
      const blocksFromHTML = convertFromHTML(props.question);

      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );

      editorState = EditorState.createWithContent(contentState);
    }
    this.state = {
      resetEditorState: false,
      updateEditorState: false,
      editorState,
    };

    this.setEditorState = this.setEditorState.bind(this);
    this.getEditorState = editorState => this._getEditorState(editorState);
    this.setDomEditorRef = ref => this.domEditor = ref;
  }

  componentDidMount(){
    this.domEditor.focus();
  }

  setEditorState() {
    return this.state.editorState;
  }

  _getEditorState(editorState) {
    const currentContent = editorState.getCurrentContent();

    if (! currentContent.hasText())
      return this.props.onChangeQuestion('', '');

    const questionPlainText = currentContent.getPlainText();

    const errors = questionPlainText.length < 1 ? 'Must be at least of one character' : '';

    const question = stateToHTML(currentContent);

    this.setState({ editorState }, () => this.props.onChangeQuestion(question, errors));
  }

  render() {
    const { resetEditorState, updateEditorState } = this.state;
    const { errors } = this.props;
    const wrapperStyle = !errors.title ? styles.editorWrapper : styles.withError;

    return (
      <div className="row">
        <div className="col-sm-12" style={styles.cardWrapper}>
          <label className="form-control-label" htmlFor="feedback">Question</label>
          <div className="input-group">

            <div style={wrapperStyle}>
              <RichEditor
                ref={this.setDomEditorRef}
                blockTypes={blockTypes}
                inlineTypes={inlineTypes}
                placeholder="Question"
                resetEditorState={resetEditorState}
                updateEditorState={updateEditorState}
                setEditorState={this.setEditorState}
                getEditorState={this.getEditorState}/>
            </div>

            {errors.title && <span style={styles.errorText}>{errors.title}</span>}
          </div>
        </div>
      </div>

    );
  }
}

export default QuizSectionForm;
