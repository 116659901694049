import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withHooks } from '../../../utils/withHooks';

import { redirect } from '../../../redux/actions/router';
import { openModal } from '../../../redux/actions/components';
import { setNumberOfGroupsToCreate } from '../../../redux/actions/organization-stats';

import { selectIsRecentEducatorAccount } from '../../../redux/selectors/profile';

import PropTypes from 'prop-types';
import { Row, Col, Container } from 'reactstrap';

class InitialLandingPage extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    isRecentEducatorAccount: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props);

    this.onOpenAddGroupModal = this.onOpenAddGroupModal.bind(this);
  }

  onOpenAddGroupModal() {
    const { actions, navigate, isRecentEducatorAccount } = this.props;

    if (isRecentEducatorAccount) {
      actions.setNumberOfGroupsToCreate(1);
      actions.redirect('/educator/groups/create');
      navigate('/educator/groups/create');
      return;
    }

    actions.openModal('admin-group-add');
  }

  render() {
    return (
      <Container>
        <Row>
          <Col sm="12" md={{size: 6, offset: 3}} lg={{size: 4, offset: 4}}>
            <div style={{marginTop: 30}}>
              <div className="card add-more-card">
                <div className="card-header add-more-card-header">
                  Add group(s) to get started!
                </div>
                <div
                  className="card-block text-center add-more-card-block"
                  onClick={this.onOpenAddGroupModal}>
                  <i className="fas fa-users fa-2x"/>
                  <p className="card-text add-more-card-text">
                    Click to add group(s)
                  </p>
                  <i className="fas fa-plus-circle add-more-plus-icon"/>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state =>
  ({ isRecentEducatorAccount: selectIsRecentEducatorAccount(state) });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    redirect,
    openModal,
    setNumberOfGroupsToCreate
  }, dispatch)
});

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(InitialLandingPage));
