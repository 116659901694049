import PropTypes from 'prop-types';
import React from 'react';

import Follower from '../../../containers/profile/connects/Follower';
import PaginationHandler from '../../common/PaginationHandler';

const FollowerCardRenderer = ({
  followers,
  selectFollower,
  selected,
  onNext,
  onPrev,
  onSelectPage,
  page,
  page_count
}) => {
  return(
    <div>
      {followers.data.length > 0 && followers.data.map((follower, index) =>
        (<Follower
          key={index}
          profile={follower}
          selectFollower={selectFollower}
          selected={selected} />))}

      {followers.data.length > 0 &&
        <PaginationHandler
          onNext={onNext}
          onPrev={onPrev}
          onSelectPage={onSelectPage}
          page={page}
          page_count={page_count}/>}
    </div>
  );
};

FollowerCardRenderer.propTypes = {
  followers: PropTypes.object,
  selectFollower: PropTypes.func,
  selected: PropTypes.array,
  isDeleting: PropTypes.object,
  isSubmitting: PropTypes.object,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  onSelectPage: PropTypes.func.isRequired,
  page: PropTypes.number,
  page_count: PropTypes.number
};

export default FollowerCardRenderer;
