import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

class Partners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state_partners: [
        {
          id: 'akacte',
          link: 'https://www.acteonline.org/alaska/',
          img: 'https://media.maxknowledge.com/images/providers/akacte.gif',
          title:'Alaska Association for Career & Technical Education'
        },
        {
          id: 'aces',
          link: 'https://careerstates.org/',
          img: 'https://media.maxknowledge.com/images/providers/aces.gif',
          title:'Alliance for Career Education in the States'
        },
        {
          id: 'apsa',
          link: 'http://www.arizonapsa.org',
          img: 'https://media.maxknowledge.com/images/providers/apsa.gif',
          title:'Arizona Private School Association'
        },

        {
          id: 'aracte',
          link: 'https://www.arkansasacte.org/',
          img: 'https://media.maxknowledge.com/images/providers/aracte.gif',
          title:'Arkansas Association for Career & Technical Education'
        },

        {
          id: 'caacte',
          link: 'https://www.acteonline.org/california-association-for-career-and-technical-education/',
          img: 'https://media.maxknowledge.com/images/providers/caacte.gif',
          title:'California Association for Career & Technical Education'
        },

        {
          id: 'capps',
          link: 'http://www.cappsonline.org',
          img: 'https://media.maxknowledge.com/images/providers/capps.gif',
          title:'The California Association of Private Postsecondary Schools'
        },

        {
          id: 'ccst',
          link: 'http://www.ccst.org',
          img: 'https://media.maxknowledge.com/images/providers/ccst.gif',
          title:'Career Colleges and Schools of Texas'
        },

        {
          id: 'cnyscs',
          link: 'http://www.cnyscs.com/',
          img: 'https://media.maxknowledge.com/images/providers/cnyscs.gif',
          title:'Coalition of New York State Career Schools'
        },

        {
          id: 'coacte',
          link: 'http://www.cacte.org/home/',
          img: 'https://media.maxknowledge.com/images/providers/coacte.gif',
          title:'Colorado Association for Career & Technical Education'
        },

        {
          id: 'dcacte',
          link: 'https://www.acteonline.org/district-of-columbia-association-for-career-and-technical-education/',
          img: 'https://media.maxknowledge.com/images/providers/dcacte.gif',
          title:'District of Columbia Association for Career & Technical Education'
        },

        {
          id: 'fapsc',
          link: 'http://www.fapsc.org',
          img: 'https://media.maxknowledge.com/images/providers/fapsc.gif',
          title:'Florida Association of Postsecondary Schools and Colleges'
        },
        {
          id: 'gace',
          link: 'https://careerstates.org/georgia',
          img: 'https://media.maxknowledge.com/images/providers/gace.gif',
          title:'Georgia Alliance for Career Education'
        },
        {
          id: 'guacte',
          link: 'https://guamacte.org/',
          img: 'https://media.maxknowledge.com/images/providers/guacte.gif',
          title:'Guam Association for Career & Technical Education'
        },

        {
          id: 'iaacte',
          link: 'https://portal.cte.idaho.gov/',
          img: 'https://media.maxknowledge.com/images/providers/iaacte.gif',
          title:'Iowa Association for Career & Technical Education'
        },

        {
          id: 'idacte',
          link: 'https://portal.cte.idaho.gov/',
          img: 'https://media.maxknowledge.com/images/providers/idacte.gif',
          title:'Idaho Career & Technical Education'
        },
        {
          id: 'inacte',
          link: 'https://indianaacte.org/',
          img: 'https://media.maxknowledge.com/images/providers/inacte.gif',
          title:'Indiana Career & Technical Education'
        },

        {
          id: 'kaccs',
          link: 'http://kycareercolleges.org',
          img: 'https://media.maxknowledge.com/images/providers/kaccs.gif',
          title:'The Kentucky Association of Career Colleges'
        },

        {
          id: 'kyacte',
          link: 'http://kacteonline.org/',
          img: 'https://media.maxknowledge.com/images/providers/kyacte.gif',
          title:'Kentucky Association for Career & Technical Education'
        },

        {
          id: 'lapcs',
          link: 'http://lapcs.net/',
          img: 'https://media.maxknowledge.com/images/providers/lapcs.gif',
          title:'Louisiana Association of Private Colleges and Schools'
        },

        {
          id: 'mdacte',
          link: 'https://acte-maryland.org/index.php',
          img: 'https://media.maxknowledge.com/images/providers/mdacte.gif',
          title:'Maryland Career and Technology Administrators'
        },

        {
          id: 'miacte',
          link: 'https://www.acteonline.org/miacte/',
          img: 'https://media.maxknowledge.com/images/providers/miacte.gif',
          title:'Michigan Association for Career & Technical Education'
        },

        {
          id: 'moacte',
          link: 'https://www.acteonline.org/missouri/',
          img: 'https://media.maxknowledge.com/images/providers/moacte.gif',
          title:'Missouri Association for Career & Technical Education'
        },

        {
          id: 'mtacte',
          link: 'http://www.montanaacte.org/',
          img: 'https://media.maxknowledge.com/images/providers/mtacte.gif',
          title:'Montana Association for Career & Technical Education'
        },

        {
          id: 'nbacte',
          link: 'https://www.acteonline.org/actenebraska/',
          img: 'https://media.maxknowledge.com/images/providers/neacte.gif',
          title:'Nebraska Association for Career & Technical Education'
        },
        {
          id: 'nacc',
          link: 'https://careerstates.org/nevada',
          img: 'https://media.maxknowledge.com/images/providers/nacc.gif',
          title:'Nevada Association of Career Colleges'
        },
        {
          id: 'nvacte',
          link: 'https://www.acteonline.org/nevada-association-for-career-and-technical-education/',
          img: 'https://media.maxknowledge.com/images/providers/nvacte.gif',
          title:'Nevada Association for Career & Technical Education'
        },
        {
          id: 'neacc',
          link: 'https://careerstates.org/new-england',
          img: 'https://media.maxknowledge.com/images/providers/neacc.gif',
          title:'New England Association of Career Colleges'
        },
        {
          id: 'nmcc',
          link: 'https://careerstates.org/new-mexico-1',
          img: 'https://media.maxknowledge.com/images/providers/nmcc.gif',
          title:'New Mexico Career College Association'
        },
        {
          id: 'nmacte',
          link: 'https://www.acteonline.org/new-mexico-association-for-career-and-technical-education/',
          img: 'https://media.maxknowledge.com/images/providers/nmacte.gif',
          title:'New Mexico Association for Career & Technical Education'
        },

        {
          id: 'nyacte',
          link: 'https://www.acteonline.org/new-york-state-association-for-career-and-technical-education/',
          img: 'https://media.maxknowledge.com/images/providers/nyacte.gif',
          title:'New York State Association for Career & Technical Education'
        },

        {
          id: 'ncacte',
          link: 'https://www.acteonline.org/northcarolina/',
          img: 'https://media.maxknowledge.com/images/providers/ncacte.gif',
          title:'North Carolina Association for Career & Technical Education'
        },

        {
          id: 'nwccf',
          link: 'http://www.nwcareercolleges.org',
          img: 'https://media.maxknowledge.com/images/providers/nwccf.gif',
          title:'The Northwest Career Colleges Federation'
        },

        {
          id: 'nwcci',
          link: 'https://nwccidaho.org/',
          img: 'https://media.maxknowledge.com/images/providers/nwcci.gif',
          title:'Northwest Career Colleges Idaho'
        },

        {
          id: 'ndacte',
          link: 'https://nd.ctelearn.org/',
          img: 'https://media.maxknowledge.com/images/providers/ndacte.gif',
          title:'North Dakota Association for Career & Technical Education'
        },

        {
          id: 'nwccor',
          link: 'http://www.nwcc-or.com/',
          img: 'https://media.maxknowledge.com/images/providers/nwccor.gif',
          title:'Northwest Career Colleges Oregon'
        },

        {
          id: 'omaccs',
          link: 'https://omaccs.org/',
          img: 'https://media.maxknowledge.com/images/providers/omaccs.gif',
          title:'Ohio-Michigan Association of Career Colleges and Schools'
        },

        {
          id: 'oracte',
          link: 'https://www.acteonline.org/oregon-association-for-career-and-technical-education/',
          img: 'https://media.maxknowledge.com/images/providers/oracte.gif',
          title:'Oregon Association for Career & Technical Education'
        },
        {
          id: 'pcsanj',
          link: 'https://careerstates.org/new-jersey',
          img: 'https://media.maxknowledge.com/images/providers/pcsanj.gif',
          title:'Private College and School Association of New Jersey'
        },
        {
          id: 'taics',
          link: 'http://www.taics.org',
          img: 'https://media.maxknowledge.com/images/providers/taics.gif',
          title:'Tennessee Association of Independent Colleges and Schools'
        },

        {
          id: 'txacte',
          link: 'https://www.ctat.org/default.aspx',
          img: 'https://media.maxknowledge.com/images/providers/txacte.gif',
          title:'The Career and Technical Association of Texas'
        },

        {
          id: 'vcca',
          link: 'http://www.va-cca.org',
          img: 'https://media.maxknowledge.com/images/providers/vcca.gif',
          title:'Virginia Career College Association'
        },

        {
          id: 'utacte',
          link: 'https://www.acteonline.org/utah-acte/',
          img: 'https://media.maxknowledge.com/images/providers/utacte.gif',
          title:'Utah Association for Career & Technical Education'
        },
        {
          id: 'ucca',
          link: 'https://careerstates.org/utah',
          img: 'https://media.maxknowledge.com/images/providers/ucca.gif',
          title:'Utah Career College Association'
        },
        

        {
          id: 'waacte',
          link: 'https://www.wa-acte.org/',
          img: 'https://media.maxknowledge.com/images/providers/waacte.gif',
          title:'Washington Association for Career & Technical Education'
        },

        {
          id: 'wvacte',
          link: 'https://www.acteonline.org/west-virginia-association-for-career-and-technical-education/',
          img: 'https://media.maxknowledge.com/images/providers/wvacte.gif',
          title:'West Virginia Association for Career & Technical Education'
        }
      ]
    };
  }

  render() {
    const img_accsc = require('../../assets/images/homepage/organization/img-logo-ACCSC.gif');
    const img_deac = 'https://media.maxknowledge.com/sites/global/img/logos/accreditors/deac_accred.jpg';
    const img_comta = 'https://media.maxknowledge.com/sites/global/img/logos/accreditors/comta_accred.jpg';
    const img_inqaahe = 'https://media.maxknowledge.com/sites/global/img/logos/accreditors/inqaahe_accred.jpg';
    const img_abhes = 'https://media.maxknowledge.com/sites/global/img/logos/accreditors/abhes_accred.jpg';
    const img_acte = 'https://media.maxknowledge.com/images/sponsor-logos/ACTE-sponsored.png';
    const img_cecu = 'https://media.maxknowledge.com/images/sponsor-logos/CECU-sponsored.png';
    const img_nacc = 'https://media.maxknowledge.com/sites/global/img/logos/partners/NACC.jpg';
    const img_aacs = 'https://media.maxknowledge.com/images/providers/aacs.gif';
    const img_amfte = 'https://media.maxknowledge.com/images/providers/afmte.gif';

    const logoStyle = {
      padding: '15px',
      border: '1px solid #eaeaea',
      marginBottom: '7px',
      borderRadius: '5px',
      textAlign: 'center',
      boxShadow: '0 3px #eaeaea',
      display:'block',
      fontSize: '13px',
    };
    const imgStyle = {
      height: '50px',
    };

    const items = this.state.state_partners.map((item,key) =>
      (<div className="col-lg-6 mb-2" key={key}>
        <a target="_blank" style={logoStyle} href={item.link}>
          <img src={item.img} className="img-fluid"/>
          <div className="mt-1">{item.title}</div>
        </a>
      </div>)
    );

    return (
      <div className="interior info partners">
        <Helmet title="Partners"/>

        <div className="container">
          <div className="row">

            <div style={{paddingTop: 50}} className="content">

              <div
                className="info-title">
                <h2>Partners</h2>
                <p>
                  CareerPrepped is powered by MaxKnowledge. Members of our partnering organizations below qualify for special offers sponsored by MaxKnowledge.
                </p>
                <article>
                  <h3>Accrediting & Quality Assurance Agencies</h3>
                  <div className="row">
                    <div className="col-lg-4">
                      <a target="_blank" href="http://www.accsc.org/">
                        <img src={img_accsc} style={{marginTop:15}} height="83"/>
                      </a>
                    </div>
                    <div className="col-lg-8">
                      <div className="testimonial" style={{marginTop: '15px'}}>
                        <div className="testimonial-box">
                          <div className="testimonial-box-content">
                            ACCSC has adopted CareerPrepped as a membership service for ACCSC-accredited institutions. We’re particularly pleased with the platform’s self-directed and competency-based approach to help students build their soft skills, which are essential for the workplace and seemingly in demand by employers across an array of industries.
                          </div>
                          <div className="testimonial-box-author">
                            <span className="name">Dr. Michale McComis</span>
                            <span className="position">Executive Director</span>
                            <span className="institution">Accrediting Commission of Career Schools and Colleges</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row mb-1">
                    <div className="col-lg-6" >
                      <a target="_blank" href="https://www.deac.org/" style={logoStyle}><img src={img_deac} className="img-fluid" /></a>
                    </div>
                    <div className="col-lg-6" >
                      <a target="_blank" href="https://www.abhes.org/" style={logoStyle}><img src={img_abhes} className="img-fluid" /></a>
                    </div>
                    <div className="col-lg-6" >
                      <a target="_blank" href="https://comta.org/" style={logoStyle}><img src={img_comta} className="img-fluid" /></a>
                    </div>
                    <div className="col-lg-6" >
                      <a target="_blank" href="https://www.inqaahe.org/" style={logoStyle}><img src={img_inqaahe} className="img-fluid" /></a>
                    </div>
                  </div>
                </article>

                <article>
                  <h3>National Associations</h3>

                  <div className="row">
                    <div className="col-lg-4">
                      <a target="_blank" href="https://www.acteonline.org/">
                        <img src={img_acte} className="img-fluid mt-1" />
                      </a>
                    </div>
                    <div className="col-lg-8">
                      <div className="testimonial" style={{marginTop: '15px'}}>
                        <div className="testimonial-box">
                          <div className="testimonial-box-content">
                            MaxKnowledge is at the epicenter of ACTE’s efforts to bring high-quality professional development to the CTE field and to support CTE students for career success. Their new CareerPrepped service is destined to become a critical part of preparing the nation's learners for the world of work, with a rich set of workforce readiness tools and an environment that allows learners and potential employers to connect.
                          </div>
                          <div className="testimonial-box-author">
                            <span className="name">Michael Connet</span>
                            <span className="position">Associate Deputy Executive Director</span>
                            <span className="institution">Association for Career and Technical Education</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row mb-1">
                    <div className="col-lg-6">
                      <a target="_blank" style={logoStyle} href="https://www.career.org/">
                        <img src={img_cecu} className="img-fluid" style={imgStyle}/>
                      </a>
                    </div>
                    <div className="col-lg-6">
                      <a target="_blank" style={logoStyle} href="https://nacc.ca/">
                        <img src={img_nacc} className="img-fluid" style={imgStyle}/>
                      </a>
                    </div>
                  </div>
                </article>

                <article>
                  <h3>State/Regional Associations</h3>
                  <div className="row mb-1">
                    {items}
                  </div>
                </article>
                <article>
                  <h3>Professional/Trade Associations</h3>
                  <div className="row mb-1">
                    <div className="col-lg-6">
                      <a target="_blank" style={logoStyle} href="https://www.beautyschools.org/">
                        <img src={img_aacs} className="img-fluid" />
                        <div className="mt-1">American Association of Cosmetology Schools</div>
                      </a>
                    </div>
                    <div className="col-lg-6">
                      <a target="_blank" style={logoStyle} href="https://www.afmte.org/">
                        <img src={img_amfte} className="img-fluid" />
                        <div className="mt-1">Alliance for Massage Therapy Education</div>
                      </a>
                    </div>
                  </div>
                </article>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Partners;
