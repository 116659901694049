import React, { Component } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import 'react-step-progress-bar/styles.css';

import MyCareerInterest from './MyCareerInterest';
import ProfileCardHeader from '../../../components/profile/header/ProfileCardHeader';
import CareerInterestsListModal from '../../../components/profile/modal/CareerInterestsListModal';

class MyCareerInterests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editCareerInterests: false,
      isCareerInterestsListModalOpen: false
    };

    this.toggleEditCareerInterests = this.toggleEditCareerInterests.bind(this);
    this.toggleCareerInterestsListModal = this.toggleCareerInterestsListModal.bind(this);
  }

  toggleEditCareerInterests() {
    this.setState({ editCareerInterests: !this.state.editCareerInterests });
  }

  toggleCareerInterestsListModal() {
    this.setState({ isCareerInterestsListModalOpen: !this.state.isCareerInterestsListModalOpen });
  }

  render() {
    const { editCareerInterests, isCareerInterestsListModalOpen } = this.state;
    const { profile, isRequesting, careerClusters } = this.props;

    return (
      <div className="profile-item">
        <ProfileCardHeader
          isEditing={isRequesting}
          isEditingSections={isRequesting}
          type="list"
          title="Career Interests"
          onEditClick={this.toggleEditCareerInterests}
          isPublicProfile={false}
          hideMover
          icon="certifications"/>

        {editCareerInterests && <MyCareerInterest
          isOpen={editCareerInterests}
          toggleModal={this.toggleEditCareerInterests}
          profile={profile}
          headerText={'Update Your Career Interests'}
          isDisabled={isRequesting}
          isLoading={isRequesting}
          careerClusters={careerClusters}
          isForCareerInterest/>}

        <div className="profile-item__card">
          {(careerClusters.length > 0) ?
            careerClusters?.slice(0,3).map(({ occupationName }, index) => {
              return (
                <div key={index} className="skill-pill mr-2 mb-2">
                  {occupationName}
                </div>
              );
            }) :
            <p>You have not added career interests yet</p>}
          {(careerClusters.length > 3) &&
            <div>
              <a
                className="clickable link"
                onClick={this.toggleCareerInterestsListModal}>
                + {careerClusters.length-3} More
              </a>
            </div>}
        </div>

        {isCareerInterestsListModalOpen &&
          <CareerInterestsListModal
            careerClusters={careerClusters}
            isOpen={isCareerInterestsListModalOpen}
            onClose={this.toggleCareerInterestsListModal}/>}
      </div>
    );
  }
}

MyCareerInterests.propTypes = {
  careerClusters: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
  isRequesting: PropTypes.bool.isRequired
};

const mapStateToProps = ({ profile: { isRequesting, data: profile } }) => ({
  profile,
  isRequesting,
  careerClusters: profile.careerClusters
});

export default connect(mapStateToProps)(MyCareerInterests);
