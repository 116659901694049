import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';

import * as profileActions from '../../../redux/actions/profile';
import {updateMyCareerPreppedStatus, getMyCareerPreppedStatus} from '../../../redux/actions/my-careerprepped-status';

import Validator from '../../../validator';
import { checkUrl, parseUrlString } from '../../../utils';
import SummaryForm from '../../../components/profile/forms/SummaryForm';
import SummaryCard from '../../../components/profile/sections/SummaryCard';
import ProfileCardHeader from '../../../components/profile/header/ProfileCardHeader';
import formWithModal from '../../../components/profile/modal/formWithModal';
import { toastr } from 'react-redux-toastr';

class Summary extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    isPublicProfile: PropTypes.bool.isRequired,
    showVisibilitySelect: PropTypes.bool,
    onChangeVisibility: PropTypes.func,
    isEditingSections: PropTypes.bool,
    isEditing: PropTypes.bool,
    onCancelModal: PropTypes.func,
    onToggleSectionEdit: PropTypes.func,
    isSummaryMandatory: PropTypes.bool,
    isEditingFromModal: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      isEditing: ( (this.props.isEditing) ? this.props.isEditing : false ),
      isUpdating: false,
      videoPreview: '',
      iframePreview: '',
      profile: Object.assign({}, this.props.profile)
    };

    this.onEditClick = this.onEditClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onRemoveVideo = this.onRemoveVideo.bind(this);
    this.onChangeSummary = this.onChangeSummary.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentWillReceiveProps(nextProps){
    const { video } = nextProps.profile;

    if(video && video !== ''){
      const iframeRegex = /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/g;
      if(iframeRegex.test(video)){
        return this.setState({
          iframePreview : video
        });
      }

      this.setState({
        videoPreview: video
      });
    }
  }

  isValid(field = null) {
    let rules = {
      summary: ['minLength|12'],
      video: ['url']
    };

    if ( this.props.isSummaryMandatory ) {
      rules.summary.push('required');
    }

    const { profile, errors: prevErrors} = this.state;
    const validate = Validator.createValidator(rules, profile, field);
    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  onChange(event) {
    const { name, value } = event.target;
    const { profile } = this.state;

    profile[name] = value;

    if (name === 'video') {
      if(checkUrl(value, 'youtube') || checkUrl(value, 'vimeo')){
        return this.setState({
          videoPreview: value,
          profile
        }, () => this.isValid(name));
      }

      if(parseUrlString(value) === 'embed'){
        return this.setState({
          iframePreview: value,
          profile
        }, () => this.isValid(name));
      }

      this.setState({
        videoPreview: '',
        iframePreview: '',
        profile
      }, () => this.isValid(name));
    }
  }

  onRemoveVideo(){
    const { profile } = this.state;

    profile['video'] = '';

    this.setState({
      profile,
      videoPreview: '',
      iframePreview: ''
    }, () => this.isValid(name));
  }

  onChangeSummary(value) {
    const { profile } = this.state;

    profile['summary'] = value;
    this.setState({ profile }, () => this.isValid('summary'));
  }

  onSave(event) {
    event.preventDefault();

    const { profile } = this.state;
    const { actions, isEditingFromModal, onCancelModal } = this.props;

    if (! this.isValid()) return;

    const profileData = {
      summary: profile.summary,
      video: profile.video
    };

    this.setState({ isUpdating: true });

    actions.updateProfile(profile.id, profileData)
      .then(async () => {
        await actions.updateMyCareerPreppedStatus();
        actions.getMyCareerPreppedStatus();
        if ( isEditingFromModal ) {
          toastr.success('About me section successfully added');
          onCancelModal();
        }
        this.handleResponse();
      });
  }

  onCancel() {
    const { video } = this.props.profile;

    const iframeRegex = /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/g;
    const isIframe = iframeRegex.test(video);

    this.setState({
      isEditing: false,
      errors: {},
      iframePreview: isIframe ? video : '',
      videoPreview: !isIframe ? video : '',
      profile: Object.assign({}, this.props.profile)
    }, () => this.props.onToggleSectionEdit('summary'));
  }

  onEditClick(event) {
    event.preventDefault();

    this.setState({ isEditing: true },
      () => this.props.onToggleSectionEdit('summary'));
  }

  handleResponse() {
    this.setState({ isEditing: false, isUpdating: false },
      () => this.props.onToggleSectionEdit('summary'));
  }

  EditingSummary= formWithModal(SummaryForm);

  render() {
    const {
      isPublicProfile,
      showVisibilitySelect,
      onChangeVisibility,
      isEditingSections
    } = this.props;
    const { errors, profile, isEditing, isUpdating, videoPreview, iframePreview } = this.state;

    return (
      <div>
        <ProfileCardHeader
          isEditing={isEditing}
          isEditingSections={isEditingSections}
          onEditClick={this.onEditClick}
          isPublicProfile={isPublicProfile}
          showVisibilitySelect={showVisibilitySelect}
          onChangeVisibility={onChangeVisibility}
          type="list"
          icon="summary"
          hideMover
          title="About Me" />

        {isEditing ?
          <this.EditingSummary
            errors={errors}
            onRemoveVideo={this.onRemoveVideo}
            videoPreview={videoPreview}
            iframePreview={iframePreview}
            profile={profile}
            onChange={this.onChange}
            onChangeSummary={this.onChangeSummary}
            onSave={this.onSave}
            onCancel={(this.props.onCancelModal) ? this.props.onCancelModal : this.onCancel}
            isUpdating={isUpdating}
            exampleType={'Summary'} /> :
          <SummaryCard
            profile={this.props.profile}
            onEditClick={this.onEditClick}
            isPublicProfile={isPublicProfile}/>}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...profileActions,
      updateMyCareerPreppedStatus,
      getMyCareerPreppedStatus,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(Summary);
