import React from 'react';

import { SimpleToolTipWrapper } from '../../common/ToolTipWrapper';

const SkillsHeaderTextTip = () => (
  <span className="skills-header-tooltip d-flex align-items-center">
    <small>
      Click
      <span className="text-orange"> orange </span>
      skills for evidence
    </small>
    <SimpleToolTipWrapper
      id={'skills-header-tip-text'}
      toolTipContent={
        <div
          style={{fontSize: 12}}
          className="inside-tooltip">
          Skill claims backed by evidence are listed in orange. Click orange skills to see the evidence
        </div>
      }>
      <small><i className="fa text-orange fa-question-circle"/></small>
    </SimpleToolTipWrapper>
  </span>
);

export default SkillsHeaderTextTip;
