import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Validator from '../../validator';
import * as componentActions from '../../redux/actions/components';
import * as organizationsActions from '../../redux/actions/organizations';
import OrganizationsContainer from '../../components/admin/OrganizationsContainer';

class ManageOrganizations extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    forms: PropTypes.array,
    isAuthenticated: PropTypes.bool.isRequired,
    organization: PropTypes.object.isRequired,
    organizations: PropTypes.object.isRequired
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      editingId: null,
      sort: {
        column: '',
        asc: false
      },
      organization: Object.assign({}, this.props.organization)
    };

    this.onCancel = this.onCancel.bind(this);
    this.onAddOrganization = this.onAddOrganization.bind(this);
    this.onEditOrganization = this.onEditOrganization.bind(this);
    this.onSaveOrganization = this.onSaveOrganization.bind(this);
    this.onChangeOrganizationDetails = this.onChangeOrganizationDetails.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
    this.onNext = this.onNext.bind(this);
    this.onPrev = this.onPrev.bind(this);
    this.onSortTable = this.onSortTable.bind(this);
  }

  componentDidMount(){
    const { isAuthenticated, actions } = this.props;

    if(isAuthenticated)
      actions.getAllOrganizations(undefined, true);
  }

  onCancel(){
    const { unloadForm } = this.props.actions;
    this.clearFormField();
    unloadForm('addOrganizationForm');
  }

  clearFormField(){
    const organization = {
      name: '',
      adminName: '',
      email: '',
      publicUrl: ''
    };

    this.setState({
      organization,
      editingId: null
    });
  }

  onChangeOrganizationDetails(event){
    const { name, value } = event.target;
    const { organization } = this.state;

    organization[name] = value;

    this.setState({ organization }, () => this.isValid(name));
  }

  onSaveOrganization(){
    const { organization, editingId } = this.state;

    if(!this.isValid())
      return;

    if(editingId === null){
      return this.props.actions.saveOrganization(organization).
        then(() => this.onCancel());
    }

    this.props.actions.updateOrganization(editingId, organization).
      then(() => this.onCancel());

  }

  isValid(field = null) {
    const rules = {
      'name': ['required', 'minLength|6'],
      'adminName': ['required', 'minLength|6'],
      'email': ['required', 'email'],
      'publicUrl': ['required', 'url']
    };

    const { organization, errors: prevErrors} = this.state;
    const validate = Validator.createValidator(rules, organization, field);
    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  onAddOrganization() {
    const { loadForm } = this.props.actions;
    loadForm('addOrganizationForm');
  }

  onEditOrganization(organizationObj) {
    return () => {
      const { loadForm } = this.props.actions;

      const organization = {
        name: organizationObj.name,
        publicUrl: organizationObj.publicUrl,
        adminName: organizationObj.adminName,
        email: organizationObj.email
      };

      this.setState({
        organization,
        editingId: organizationObj.id
      }, ()=> loadForm('addOrganizationForm'));
    };
  }

  onNext() {
    const { actions, organizations } = this.props;
    const { page } = organizations.paginationData;
    const { column, asc } = this.state.sort;
    let urlParamString = `page=${page + 1}`;

    if (column.length !== 0) {
      const sortParam = asc ? 'asc' : 'desc';

      urlParamString = `${urlParamString}&sort=${column}:${sortParam}`;
    }

    const searchQuery = organizations.filters.searchQuery.trim();

    if (searchQuery.length !== 0) {
      urlParamString = `${urlParamString}&query=${searchQuery}`;
    }

    actions.clearOrganisationReducer();

    return actions.getAllOrganizations(urlParamString, true);
  }

  onPrev(){
    const { actions, organizations } = this.props;
    const { page } = organizations.paginationData;
    const { column, asc } = this.state.sort;
    let urlParamString = `page=${page - 1}`;

    if (column.length !== 0) {
      const sortParam = asc ? 'asc' : 'desc';

      urlParamString = `${urlParamString}&sort=${column}:${sortParam}`;
    }

    const searchQuery = organizations.filters.searchQuery.trim();

    if (searchQuery.length !== 0) {
      urlParamString = `${urlParamString}&query=${searchQuery}`;
    }

    actions.clearOrganisationReducer();
    return actions.getAllOrganizations(urlParamString, true);
  }

  onSelectPage(page){
    return () => {
      const { actions, organizations } = this.props;
      const { column, asc } = this.state.sort;
      let urlParamString = `page=${page}`;

      if (column.length !== 0) {
        const sortParam = asc ? 'asc' : 'desc';

        urlParamString = `${urlParamString}&sort=${column}:${sortParam}`;
      }

      const searchQuery = organizations.filters.searchQuery.trim();

      if (searchQuery.length !== 0) {
        urlParamString = `${urlParamString}&query=${searchQuery}`;
      }

      actions.clearOrganisationReducer();

      return actions.getAllOrganizations(urlParamString, true);
    };
  }

  onSortTable(event) {
    const column = event.currentTarget.dataset['columnName'];
    const asc = (! this.state.sort.asc);

    this.setState({ sort: { column, asc } });

    let urlParamString = '';

    if (column.length !== 0) {
      const sortParam = asc ? 'asc' : 'desc';

      urlParamString = `sort=${column}:${sortParam}`;
    }

    const { actions, organizations } = this.props;
    const searchQuery = organizations.filters.searchQuery.trim();

    if (searchQuery.length !== 0) {
      urlParamString = `${urlParamString}&query=${searchQuery}`;
    }

    actions.getAllOrganizations(urlParamString, true);
  }

  render() {
    const { errors, organization, editingId } = this.state;
    const { forms, actions, organizations: organizationsInStore } = this.props;
    const { isRequesting, data: organizationData, paginationData } = organizationsInStore;
    const organizationArray = organizationData.filter(org => org.id !== 'all-organizations');

    return (
      <div className="interior invite-users">
        <Helmet title="Admin - Manage Organizations"/>
        <div className="container">
          <div className="row">
            <div className="content follow col-md-12">
              <h2>Manage Organizations</h2>
              <OrganizationsContainer
                forms={forms}
                editingId={editingId}
                isRequesting={isRequesting}
                onNext={this.onNext}
                onPrev={this.onPrev}
                onSelectPage={this.onSelectPage}
                page={paginationData.page}
                page_count={paginationData.page_count}
                errors={errors}
                organization={organization}
                organizationArray={organizationArray}
                onChangeOrganizationDetails={this.onChangeOrganizationDetails}
                onCancel={this.onCancel}
                onChangeOrganizationsSearchQuery={actions.onChangeOrganizationsSearchQuery}
                organizationObject={organizationsInStore}
                onEditOrganization={this.onEditOrganization}
                onSaveOrganization={this.onSaveOrganization}
                onAddOrganization={this.onAddOrganization}
                onSortTable={this.onSortTable}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const organization = {
    name: '',
    adminName: '',
    email: '',
    publicUrl: ''
  };

  return {
    organization,
    isAuthenticated: state.auth.isAuthenticated,
    organizations: state.organizations,
    forms: state.components.forms,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, componentActions, organizationsActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageOrganizations);
