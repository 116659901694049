import PropTypes from 'prop-types';
import React from 'react';
import ShareReflectionViaEmails from '../../../containers/skill-builders/ShareReflectionViaEmails';
import ShareReflectionToActivityStream from '../../../containers/skill-builders/ShareReflectionToActivityStream';

const ShareReflectionModalForms = ({
  typeofShare,
  unit,
  reflections,
  selectedReflections,
  closeModal
}) => {

  return (
    <div>
      {
        typeofShare === 'email' &&
          <ShareReflectionViaEmails
            unit={unit}
            closeModal={closeModal}
            reflectionResponses={reflections}
            selectedReflections={selectedReflections}/>
      }
      {
        (typeofShare === 'activity-stream' || typeofShare === 'engage-and-connect') &&
          <ShareReflectionToActivityStream
            shareToEngageAndConnect={typeofShare === 'engage-and-connect'}
            unit={unit}
            closeModal={closeModal}
            reflectionResponses={reflections}
            selectedReflections={selectedReflections}/>
      }
    </div>
  );
};

ShareReflectionModalForms.propTypes = {
  typeofShare: PropTypes.string,
  unit: PropTypes.object,
  selectedReflections: PropTypes.array,
  closeModal: PropTypes.func,
  reflections: PropTypes.object,
};

export default ShareReflectionModalForms;
