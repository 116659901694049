import initialState from './initialState';

import {
  CREATE_SUBSCRIPTION_REQUEST,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAILURE
} from '../../constants';

export default function reducer(state = initialState.subscriptions, action) {
  switch (action.type) {

  case CREATE_SUBSCRIPTION_REQUEST:
    return Object.assign({}, state, {
      isSubmitting: true
    });

  case CREATE_SUBSCRIPTION_SUCCESS:
    return Object.assign({}, state, {
      data: action.data,
      isSubmitting: false
    });

  case CREATE_SUBSCRIPTION_FAILURE:
    return Object.assign({}, state, {
      isSubmitting: false,
      error: action.error
    });

  default:
    return state;
  }
}
