import React from 'react';

import { pure } from 'recompose';

import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

const ActivityFilter = ({ onChangeActivityFilter }) => {
  return (
    <div className="dashboard-activity-filter">
      <label
        htmlFor="activity-filter"
        className="sr-only">
        Activity Filter
      </label>
      <Input
        type="select"
        name="activity-filter"
        id="activity-filter"
        className="form-control form-control-md dashboard-activity-filter-inputs"
        onChange={onChangeActivityFilter}>
        <option value="all_activity">All Activity</option>
        <option value="my_activity">My Activity</option>
        <option value="following_activity">Following Activity</option>
      </Input>
    </div>
  );
};

ActivityFilter.propTypes = {
  onChangeActivityFilter: PropTypes.func.isRequired
};

export default pure(ActivityFilter);
