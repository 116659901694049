import initialState from './initialState';

import {
  PEERS_REQUEST,
  PEERS_SUCCESS,
  PEERS_FAILURE
} from '../../constants';

export default function reducer(state = initialState.peers, action) {
  switch (action.type) {
  case PEERS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case PEERS_SUCCESS:
    return Object.assign({}, state, {
      isRequesting: false,
      page: action.data.page,
      pageCount: action.data.page_count,
      data: action.data._embedded.peers
    });

  case PEERS_FAILURE:
    return Object.assign({}, state, {
      isRequesting: false,
      error: action.error
    });

  default:
    return state;
  }
}
