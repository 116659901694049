import React from 'react';

import { Link } from 'react-router-dom';
import Chance from 'chance';
import { SimpleToolTipWrapper } from '../common/ToolTipWrapper';

const JobsSection = () => {
  const chance = new Chance();
  const randomId1 = `simple-tooltip-${chance.hash({ length: 5})}`;
  const randomId2 = `simple-tooltip-${chance.hash({ length: 5})}`;
  const randomId3 = `simple-tooltip-${chance.hash({ length: 5})}`;
  const randomId4 = `simple-tooltip-${chance.hash({ length: 5})}`;

  return (
    <div className="dashboard-quick-links-container">
      <div className="dashboard-quick-links-header">
        Tools to Get Your Next Job
      </div>
      <div className="dashboard-quick-links-menu">
        <ul>
          <h4 className="dashboard-quick-links-section-header">Craft Resumes & Master Interviews</h4>
          <li>
            <h4>
              <SimpleToolTipWrapper id={randomId1} toolTipContent={
                <div className="inside-tooltip">
                  Turn your Career Site into a resume with links to show proof of your skills.
                </div>
              }>
                <Link to="/job-center/resume-builder">Resume Builder</Link>
              </SimpleToolTipWrapper>
            </h4>
          </li>
          <li>
            <h4>
              <SimpleToolTipWrapper id={randomId2} toolTipContent={
                <div className="inside-tooltip">
                  Get tips and watch examples of strong interview answers to ace your next interview.
                </div>
              }>
                <Link to="/job-center/interview-videos">Interview Videos</Link>
              </SimpleToolTipWrapper>
            </h4>
          </li>
        </ul>
        <ul className="mb-0">
          <h4 className="dashboard-quick-links-section-header">Find Jobs & Track Your Progress</h4>
          <li>
            <h4>
              <SimpleToolTipWrapper id={randomId3} toolTipContent={
                <div className="inside-tooltip">
                  Find jobs tailored to your goals and interests.
                </div>
              }>
                <Link to="/job-center">Job Match</Link>
              </SimpleToolTipWrapper>
            </h4>
          </li>
          <li>
            <h4>
              <SimpleToolTipWrapper id={randomId4} toolTipContent={
                <div className="inside-tooltip">
                  Track your job applications and progress all in one spot.
                </div>
              }>
                <Link to="/job-center/job-tracker">Job Tracker</Link>
              </SimpleToolTipWrapper>
            </h4>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default JobsSection;
