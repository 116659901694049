import { createSelector } from 'reselect';
import { uniqueId } from '../../utils';

const selectStates = state => state.states;

export const selectStatesList = createSelector(
  [selectStates],
  states => states.data
);

export const selectFormattedStates = createSelector(
  [selectStatesList],
  states =>
    states.map(
      state => ({
        id: uniqueId(),
        label: state.stateName,
        value: state.stateId,
        abbr: state.abbreviation
      })
    )
);

export const selectStatesPageCount = createSelector(
  [selectStates],
  states => states.pageCount
);
