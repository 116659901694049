import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import Switch from 'react-switch';

import {
  disableMessage,
} from '../../../redux/actions/profile';

const UsersTableDisableMessageCell = (props) => {
  const { user } = props;
  const { privateMessagingDisabled } = user;
  const [checked, setChecked]= useState(privateMessagingDisabled);
  const dispatch = useDispatch();

  async function handleChange(value){
    setChecked(value);
    let data= {
      privateMessagingStatus : value ? 0 : 1
    };
    await dispatch(disableMessage(user.id, data));
    props.getUserList();
  }

  useEffect(() =>{
    setChecked(privateMessagingDisabled);
  },[privateMessagingDisabled]);

  return <strong><Switch onChange={handleChange} checked={checked} height={20} width={45}/></strong>;
};

UsersTableDisableMessageCell.propTypes = {
  user: PropTypes.object.isRequired,
  getUserList: PropTypes.func.isRequired,
};

export default UsersTableDisableMessageCell;
