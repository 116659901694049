import PropTypes from 'prop-types';
import React from 'react';

const LocationCard = ({ profile }) => {
  return (
    <span className="location">
      {`${profile.city ? profile.city.label : ''}${profile.city && profile.state ? ', ' : ''}${profile.state ? profile.state.label : ''}`}
    </span>
  );
};

LocationCard.propTypes = {
  profile: PropTypes.object.isRequired
};

export default LocationCard;
