import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { withHooks } from '../../utils/withHooks';
import Validator from '../../validator';

import * as skillsActions from '../../redux/actions/skills';
import * as componentActions from '../../redux/actions/components';
import * as filesActions from '../../redux/actions/files';
import * as badgesActions from '../../redux/actions/badges';

import Loading from '../../components/common/Loading';
import BadgeContent from '../../components/skill-badges/BadgeContent';
import FileDetailsModal from '../portfolio/FileDetailsModal';
import ReviewsBar from '../../components/myskills/ReviewsBar';

class SkillUnit extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    modal: PropTypes.string,
    endorserData: PropTypes.object,
    params: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    unitSkill: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      showShareDropdown: false,
      skillsObserved: false,
      fileId: null,
      endorserData: Object.assign({}, this.props.endorserData),
      reviewsBar: false,
    };

    this.onClickEndorse = this.onClickEndorse.bind(this);
    this.onShareClick = this.onShareClick.bind(this);
    this.onShareToCp = this.onShareToCp.bind(this);
    this.onChangeEndorserData = this.onChangeEndorserData.bind(this);
    this.onToggleSkillsObserved = this.onToggleSkillsObserved.bind(this);
    this.onSubmitEndorsementConfirmation = this.onSubmitEndorsementConfirmation.bind(
      this
    );
    this.onViewPortfolio = this.onViewPortfolio.bind(this);
    this.onLinkedSkill = this.onLinkedSkill.bind(this);
    this.onToggleLinkingFilesModal = this.onToggleLinkingFilesModal.bind(this);
    this.onToggleReviewBarModal = this.onToggleReviewBarModal.bind(this);
  }

  componentDidMount() {
    const { params, actions, isAuthenticated } = this.props;

    if(params.slug) {
      actions.unitSkillRequest(isAuthenticated, params.slug);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { params: { slug: nextSlug } } = nextProps;
    const { params: { slug }, actions, isAuthenticated } = this.props;

    if (nextSlug && (slug !== nextSlug)) {
      actions.unitSkillRequest(isAuthenticated, nextSlug);
    }
  }

  componentWillUnmount() {
    localStorage.removeItem('mySkillsParams');
  }

  onShareClick() {
    const { showShareDropdown } = this.state;
    this.setState({
      showShareDropdown: !showShareDropdown,
    });
  }

  onShareToCp() {
    this.setState(
      {
        showShareDropdown: false,
      },
      () => this.props.actions.openModal('share-badge-to-cp')
    );
  }

  isValid(field = null) {
    const rules = {
      name: ['required'],
      email: ['required', 'email'],
      position: ['required'],
      organization: ['required'],
    };
    const { endorserData, errors: prevErrors } = this.state;
    const validate = Validator.createValidator(rules, endorserData, field);
    const { errors, isValid } = validate;

    if (field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors) });

    return isValid;
  }

  onChangeEndorserData(event) {
    const { name, value } = event.target;
    const { endorserData } = this.state;

    endorserData[name] = value;

    this.setState({ endorserData }, () => this.isValid(name));
  }

  onToggleSkillsObserved() {
    const { skillsObserved } = this.state;

    this.setState({
      skillsObserved: !skillsObserved,
    });
  }

  onClickEndorse() {
    const { actions, unitSkill } = this.props;
    const selectedBadges = [{
      id: unitSkill.data.id,
      name: unitSkill.data.name,
      issued: '',
    }];

    actions.saveSelectedBadges(selectedBadges);
    this.props.actions.openModal('requestEndorseModalInBakedBadgePage');
  }

  onSubmitEndorsementConfirmation() {
    const { endorserData } = this.state;
    const { params, actions } = this.props;

    if (!this.isValid()) {
      return;
    }

    const data = Object.assign({}, endorserData, {
      badgeIssuedId: params.slug,
    });

    actions.endorseBadge(data);
  }

  onViewPortfolio(id) {
    return () => {
      const { actions } = this.props;
      this.setState(
        {
          fileId: id,
        },
        () => actions.openModal('view-portfolio-modal-badge-details')
      );
    };
  }

  onLinkedSkill() {
    const { params, actions, isAuthenticated } = this.props;
    actions.unitSkillRequest(isAuthenticated, params.slug);
  }

  onToggleLinkingFilesModal() {
    const { modal, actions, unitSkill } = this.props;
    const { data: skillData } = unitSkill;
    const isFilesModalOpen = modal && modal === 'fileModalInBakedBadgePage';
    if (!isFilesModalOpen) {
      const selectedBadges = [{ id: skillData.id, name: skillData.name, issued: '', files: skillData.files}];

      actions.saveSelectedBadges(selectedBadges);
      return actions.openModal('fileModalInBakedBadgePage');
    }

    actions.closeModal();
  }

  onToggleReviewBarModal() {
    const { reviewsBar } = this.state;
    this.setState({
      reviewsBar: !reviewsBar,
    });
  }

  goBack() {
    window.history.back();
  }

  render() {
    const {
      fileId,
    } = this.state;
    const {
      unitSkill,
      currentUser,
      modal,
      isAuthenticated,
    } = this.props;
    const { isRequesting, data: skillData } = unitSkill;

    const { name, files,endorsements, badges, user,id: skill_id } = skillData;

    const isLoadingContents =
      isRequesting || Object.keys(skillData).length === 0;

    const badgeName =
      isRequesting || Object.keys(skillData).length === 0
        ? '...'
        : skillData.name;

    const isViewPortfolioOpen =
      modal &&
      modal === 'view-portfolio-modal-badge-details' &&
      fileId !== null;

    const skillsPageLocation = localStorage.getItem('mySkillsParams') ?
      {
        ...JSON.parse(localStorage.getItem('mySkillsParams')),
        hash: '#otherSkills'
      } :
      '/myskills#otherSkills';

    return (
      <div>
        <Helmet title={`Other Skills - ${badgeName}`} />

        <div className="container">
          <div className="row">
            {isAuthenticated && (
              <div className="col-lg-12">
                <div className="content subnav essential">
                  <div className="bread">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link
                          to={skillsPageLocation}
                          className="orange clickable">
                          Other Skills
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        {name ? name : '...'}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="content badges badge-relative p-0">

                {isViewPortfolioOpen && (
                  <FileDetailsModal
                    fileId={fileId}
                    isOpen={isViewPortfolioOpen}/>
                )}
                {(isLoadingContents && isAuthenticated) ? (
                  <div className="loading-container">
                    <Loading />
                  </div>
                ) : (
                  <BadgeContent
                    onShareClick={this.onShareClick}
                    currentUser={currentUser}
                    user={user}
                    files={files}
                    skillData={skillData}
                    skillBadges={badges}
                    skill_id= {skill_id}
                    endorsements={endorsements}
                    onClickEndorse={this.onClickEndorse}
                    onViewPortfolio={this.onViewPortfolio}
                    onLinkedSkill={this.onLinkedSkill}
                    showLinkPortfolioModal={this.onToggleLinkingFilesModal}
                    showReviewModal = {this.onToggleReviewBarModal}
                    skillEvidenceView/>
                )}
              </div>
            </div>
          </div>

          {this.state.reviewsBar && <ReviewsBar
            resourceId={skill_id}
            isOpen={this.state.reviewsBar}
            toggleModal={this.onToggleReviewBarModal}/>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state.skills.unitSkill);
  return {
    files: state.files,
    isRequestingFiles: state.files.isRequesting,
    profile: state.profile.data,
    modal: state.components.modal,
    isAuthenticated: state.auth.isAuthenticated,
    unitSkill: state.skills.unitSkill,
    currentUser: state.auth.data.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    componentActions,
    filesActions,
    skillsActions,
    badgesActions,
  );

  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withHooks(SkillUnit));
