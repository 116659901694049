import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { checkHttp } from '../../utils';
import Parser from 'html-react-parser';

const ResumeProjects = ({ resumeProjects }) => {
  return (
    <div className="resume-section">
      <div className="resume-title">
        {resumeProjects.title !== '' ? resumeProjects.title : 'Projects'}
      </div>
      {
        resumeProjects.projectsArray.map((project) =>{
          const isStartDateObject = typeof project.startdate === 'object';
          const startDateString = isStartDateObject ? project.startdate.date :
            project.startdate === '' ? undefined : project.startdate;

          const isEndDateObject = typeof project.enddate === 'object';
          const endDateString = isEndDateObject ? project.enddate.date :
            project.enddate === '' ? undefined : project.enddate;

          const startDate = moment(startDateString).format('MM/YYYY');
          let endDate;

          if (project.noend) {
            endDate = 'Present';
          } else {
            endDate = moment(endDateString).format('MM/YYYY');
          }

          const urlAvailable = project.url !== '';
          const urlString = checkHttp(project.url) ?
            project.url : `//${project.url}`;

          return(
            <div key={project.id} style={{marginBottom: 10}}>
              {!urlAvailable ?
                <span>{project.title}</span> :
                <span><a href={urlString} target="_blank" rel="noopener noreferrer">{project.title}</a></span>}

              <span style={{marginLeft: 10}}>({startDate} - {endDate})</span>
              <div>{Parser(project.description)}</div>
            </div>
          );
        })
      }
    </div>
  );
};

ResumeProjects.propTypes = {
  resumeProjects: PropTypes.object.isRequired,
};

export default ResumeProjects;
