import React, { useState } from 'react';

import { isEmpty, isUndefined } from 'lodash';

import PropTypes from 'prop-types';
import Parser from 'html-react-parser';
import SeeTipsModal from '../skill-builders/claim-badge/SeeTipsModal';
import { Row, Col, Input, Label, Form, FormGroup, Button } from 'reactstrap';

const QuestionResponses = ({
  feedbackData,
  questions,
  step2,
  feedbackResponse,
  status,
  proceed,
  name,
  toggleConfirmationModal,
  resourceData,
}) => {
  const [additionalComment, setComment] = useState(
    feedbackData?.additionalComment
  );
  const [like, setLike] = useState(feedbackData?.like);
  const [multiOption, setMultiOption] = useState(feedbackData?.multiOption);
  const [errorMessage, setErrorMessage] = useState({});
  const [showExampleModal, setShowExampleModal] = useState(false);

  function onToggleExampleModal(event) {
    event.preventDefault();

    setShowExampleModal(!showExampleModal);
  }

  function setMultiChoice(e){
    const { target: { value: answerId } } = e;

    if (e.target.checked) {
      multiOption
        ? setMultiOption([...multiOption, answerId])
        : setMultiOption([answerId]);
    } else {
      setMultiOption(multiOption.filter(item => item !== answerId));
    }
  }

  function saveQuestionResponses(){
    let isErrorMessageAdded = false;
    let errorMessageDetails = {};
    if (!like) {
      isErrorMessageAdded = !isErrorMessageAdded ? true : isErrorMessageAdded;
      // const message = `Please select the ${lowerFirst(questions.likertQuestionText)}`;
      errorMessageDetails = {...errorMessageDetails, radio: 'Required'};
    }

    if ((multiOption && multiOption.length === 0) || !multiOption) {
      isErrorMessageAdded = !isErrorMessageAdded ? true : isErrorMessageAdded;
      // const message = `Please select the ${lowerFirst(questions.multiChoiceQuestionText)}`;
      errorMessageDetails = {...errorMessageDetails, check: 'Required'};
    }

    if(isErrorMessageAdded) {
      setErrorMessage(errorMessageDetails);
      return;
    }

    const feedback = {};
    if (like) {
      feedback['like'] = like;
    }
    if (multiOption) {
      feedback['multiOption'] = multiOption;
    }
    if (additionalComment) {
      feedback['additionalComment'] = additionalComment;
    }

    feedbackResponse('questionResponseRating', feedback);
    if (resourceData?.files?.length > 0) {
      proceed('step2');
    }else if (status === 'pending' || status === 'completed') {
      proceed('step3');
    } else toggleConfirmationModal();
  }

  function handleComment(e){
    if (e.target.value.split(' ').length <= 200) setComment(e.target.value);
  }

  function handleLike(event){
    const { target: { value: answerId } } = event;

    setLike(answerId);
  }

  const getLikert = () => {
    return (
      <>
        {questions?.likert?.map(likert => (
          <FormGroup
            key={likert.answerId}
            check
            className="custom-radio-wrapper form-check pl-0">
            <Label check className="container">
              <span className="ml-3 font14"> {likert.answerText}</span>
              <Input
                className="mr-1"
                type="radio"
                name="radio1"
                value={likert.answerId}
                invalid={!isEmpty(errorMessage) && !isUndefined(errorMessage?.radio)}
                defaultChecked={likert.answerId === feedbackData?.like}
                onChange={handleLike}/>
              <span className={`${!isEmpty(errorMessage) && errorMessage?.radio ? 'is-invalid checkmark' : 'checkmark'}`} />
            </Label>
          </FormGroup>
        ))}
      </>
    );
  };

  const getmultichoice1 = (multiChoiceList) => {
    return (
      <>
        {multiChoiceList.map(multiChoice => (
          <FormGroup
            key={multiChoice.answerId}
            check
            className="pl-0 custom-checkbox-wrapper">
            <Label check className="container">
              {multiChoice.answerText}
              <Input
                type="checkbox"
                className="mr-1"
                value={multiChoice.answerId}
                defaultChecked={multiOption?.includes(multiChoice.answerId)}
                invalid={!isEmpty(errorMessage) && !isUndefined(errorMessage?.check)}
                onClick={setMultiChoice}/>
              <span className={`${!isEmpty(errorMessage) && errorMessage.check ? 'is-invalid checkmark' : 'checkmark'}`} />
            </Label>
          </FormGroup>
        ))}
      </>
    );
  };

  const getMultiChoice = () => {
    if (questions?.multichoice?.length) {
      const multichoiceBatchFive = questions?.multichoice.slice(0, 5);
      const multichoiceBatchAfterFive = questions?.multichoice.slice(5, questions.multichoice.length);
      return (
        <>
          <Row>
            <Col md="6">{getmultichoice1(multichoiceBatchFive)}</Col>
            <Col md="6">{getmultichoice1(multichoiceBatchAfterFive)}</Col>
          </Row>
        </>
      );
    }
    return null;
  };

  return (
    <div className="evidenceListControls">
      <section>
        <div className="section-name">
          {resourceData?.acknowledgement?.length > 0 && (
            <strong>Claimed Abilities</strong>
          )}
        </div>
        <ul className="answer answer-list">
          {resourceData?.acknowledgement?.map(acknowledgementData => (
            <li key={acknowledgementData.id}>{acknowledgementData.acknowledgement}</li>
          ))}
        </ul>
      </section>
      <section>
        <div className="section-name">
          <strong>Question Responses</strong>
        </div>
        <div className="px-2">
          {resourceData?.justifications?.map(
            (personalStatement) =>{
              return (
                <div key={personalStatement.id}>
                  <span className="font14 fontW600 d-block mb-10"> {personalStatement.question}</span>

                  {/* <div className="mb-3">
                    <a href="#" className="underline"  onClick={onToggleExampleModal}>
                      (Tips for evaluating this response)
                    </a>
                  </div> */}

                  {Parser(personalStatement.answer)}
                </div>
              );
            }
          )}
        </div>
        <div />
      </section>
      <section className="questionstoadmin px-2">
        <div className="question1 pt-2">
          <span className="font16 fontW600 mb-3 d-block mt-2">
            {questions?.likertQuestionText}
          </span>
          <Form className="answer">{getLikert()}</Form>
          <div className="form-control-feedback">
            {errorMessage.radio || ''}
          </div>
        </div>
        <div className="question2 pt-2">
          <p className="font-weight-bold mt-2">
            {questions?.multiChoiceQuestionText}
            <span className="text-secondary mt-1 d-block font-weight-normal">
              <em>(Select all that apply and/or leave your own comments)</em>
            </span>
          </p>
          <Form className="answer">
            {getMultiChoice()}
            <div className="form-control-feedback">
              {errorMessage.check || ''}
            </div>
            <FormGroup className="mt-3">
              <Label style={{ color: '#000' }} for="feedbackComment">
                {questions?.commentQuestionText}
              </Label>
              <Input
                type="textarea"
                placeholder={`Write your comments to praise and/or help improve ${name?.split(' ')[0]}’s question responses.`}
                name="feedbackComment"
                value={additionalComment}
                onChange={handleComment}
                id="feedbackComment"/>
            </FormGroup>
          </Form>
        </div>
        <div className="text-right mt-1">
          {!step2 ? (
            <>
              <Button
                onClick={saveQuestionResponses}
                className="sendButton ml-2">
                I'm Finished
              </Button>
            </>
          ) : (
            <Button
              onClick={saveQuestionResponses}
              className="sendButton">
              Next
            </Button>
          )}
        </div>
      </section>

      {showExampleModal &&
        <SeeTipsModal
          isOpen={showExampleModal}
          onToggle={onToggleExampleModal} />}
    </div>
  );
};


QuestionResponses.propTypes = {
  proceed: PropTypes.func.isRequired,
  questions: PropTypes.object.isRequired,
  feedbackData: PropTypes.object.isRequired,
  resourceData: PropTypes.object.isRequired,
  feedbackResponse: PropTypes.func.isRequired,
  step2: PropTypes.bool,
  status: PropTypes.string,
  name: PropTypes.string,
  toggleConfirmationModal: PropTypes.func,
};

export default QuestionResponses;
