import initialState from './initialState';

import {
  INDUSTRY_REPRESENTATIVES_REQUEST,
  INDUSTRY_REPRESENTATIVES_SUCCESS,
  INDUSTRY_REPRESENTATIVES_FAILURE
} from '../../constants';

export default function reducer(state = initialState.industryRepresentatives, action) {
  switch (action.type) {
  case INDUSTRY_REPRESENTATIVES_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case INDUSTRY_REPRESENTATIVES_SUCCESS:
    return Object.assign({}, state, {
      isRequesting: false,
      page: action.data.page,
      pageCount: action.data.page_count,
      data: action.data._embedded.industry_representative
    });

  case INDUSTRY_REPRESENTATIVES_FAILURE:
    return Object.assign({}, state, {
      isRequesting: false,
      error: action.error
    });

  default:
    return state;
  }
}
