import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toastr } from 'react-redux-toastr';
import { scrollToTop } from '../../../utils';

import * as discussionsActions from '../../../redux/actions/discussions';

const styles = {
  icon: { marginRight: 5 },
  iconText:{ marginRight: 15 }
};

class ShareButton extends Component {
  static propTypes = {
    discussion: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);

    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    event.preventDefault();

    const { discussion, isAuthenticated } = this.props;

    if (!isAuthenticated) {
      scrollToTop();

      return toastr.error('Nonauthorized', 'Please Login or Signup to share the post.');
    }

    this.props.actions.shareDiscussion(discussion);
  }

  render() {
    return (
      <a href="#"
        onClick={this.onClick}
        data-toggle="modal"
        data-target="#confirm-post-share">
        <i className="fa fa-share" style={styles.icon}/>
        <span style={styles.iconText}>Share</span>
      </a>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(discussionsActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareButton);
