import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as registrationActions from '../../redux/actions/registration';

import { Helmet } from 'react-helmet';
import { useTable } from 'react-table';
import { CSVLink } from 'react-csv';

const Table = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr style={{backgroundColor: '#fff'}} key={i} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, i) => (
              <th key={i} {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr style={{backgroundColor: '#fff'}} key={i} {...row.getRowProps()}>
              {row.cells.map((cell, i) => {
                return <td key={i} {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const ManageAccessRequests = ({ isAuthenticated, actions, registration }) => {
  const { fetchAllAccessRequests } = actions;

  useEffect(() => {
    if (isAuthenticated) {
      fetchAllAccessRequests();
    }
  }, [isAuthenticated, fetchAllAccessRequests]);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name'
      },{
        Header: 'Email',
        accessor: 'email'
      },{
        Header: 'Phone',
        accessor: 'phone'
      },{
        Header: 'Organization',
        accessor: 'organization'
      },{
        Header: 'Job Title',
        accessor: 'jobTitle'
      },{
        id: 'type',
        Header: 'Type',
        accessor: request => (request.type === 0 ? 'For Myself' : 'For My Organization')
      },{
        Header: 'Page',
        accessor: 'page'
      },{
        id: 'createdAt',
        Header: 'Created At',
        accessor: request => (moment(request.createdAt.date).format('MM/DD/YYYY HH:mm'))
      }
    ], []);

  const headers = [
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Organization', key: 'organization' },
    { label: 'Page', key: 'page' }
  ];

  return (
    <div className="interior invite-users">
      <Helmet title="Admin - Manage Organizations"/>
      <div className="container">
        <div className="row">
          <div className="content follow">
            <h2 style={{marginBottom: 20}}>Manage Access Requests</h2>
            <CSVLink
              filename="cp-access-requests.csv"
              className="btn btn-primary"
              data={registration.accessRequests}
              headers={headers}>
              Download CSV
            </CSVLink>
            <div style={{marginTop: 20}}>
              <Table columns={columns} data={registration.accessRequests}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired
};

ManageAccessRequests.propTypes = {
  actions: PropTypes.object.isRequired,
  registration: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
  const { auth, registration } = state;

  return {
    registration,
    isAuthenticated: auth.isAuthenticated
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(registrationActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAccessRequests);
