import React from 'react';
import PropTypes from 'prop-types';

const JobTitleColumn = (props) => {
  const rowDetails = props.rowData;
  return (
    <td scope="row">{rowDetails.jobTitle}</td>
  );
};

JobTitleColumn.propTypes = {
  rowData: PropTypes.object.isRequired,
};

export default JobTitleColumn;