import { CALL_API } from '../../middlewares/api';
import {
  BADGE_ASSERTION_REQUEST,
  BADGE_ASSERTION_SUCCESS,
  BADGE_ASSERTION_FAILURE,
  ADD_ACKNOWLEDGEMENT_REQUEST,
  ADD_ACKNOWLEDGEMENT_SUCCESS,
  ADD_ACKNOWLEDGEMENT_FAILURE,
  REMOVE_ACKNOWLEDGEMENT_REQUEST,
  REMOVE_ACKNOWLEDGEMENT_SUCCESS,
  REMOVE_ACKNOWLEDGEMENT_FAILURE,
  UPDATE_STATEMENT_REQUEST,
  UPDATE_STATEMENT_SUCCESS,
  UPDATE_STATEMENT_FAILURE,
  UPDATE_JUSTIFICATION_ANSWER_REQUEST,
  UPDATE_JUSTIFICATION_ANSWER_SUCCESS,
  UPDATE_JUSTIFICATION_ANSWER_FAILURE,
  RESET_ASSERTION_REDUCER,
  ADD_EVIDENCE_FILE_REQUEST,
  ADD_EVIDENCE_FILE_SUCCESS,
  ADD_EVIDENCE_FILE_FAILURE,
  REMOVE_EVIDENCE_FILE_REQUEST,
  REMOVE_EVIDENCE_FILE_SUCCESS,
  REMOVE_EVIDENCE_FILE_FAILURE,
  REMOVE_BADGE_EVIDENCE_FILE_REQUEST,
  REMOVE_BADGE_EVIDENCE_FILE_SUCCESS,
  REMOVE_BADGE_EVIDENCE_FILE_FAILURE,
  LIKE_EVIDENCE_FILE_REQUEST,
  LIKE_EVIDENCE_FILE_SUCCESS,
  LIKE_EVIDENCE_FILE_FAILURE,
  UNLIKE_EVIDENCE_FILE_REQUEST,
  UNLIKE_EVIDENCE_FILE_SUCCESS,
  UNLIKE_EVIDENCE_FILE_FAILURE,
  CLEAR_USER_REDUCERS,
} from '../../constants';

export const clearAssertionState = () => {
  return { type: RESET_ASSERTION_REDUCER };
};

export const clearUserReducers = () => {
  return { type: CLEAR_USER_REDUCERS };
};

export const requestBadgeAssertion = (badgeIssuedId, isAuthenticated) => {
  return function (dispatch) {
    const endpoint = `badge/assertion/${badgeIssuedId}`;
    const token = localStorage.getItem('careerPrepped.auth.token') || null;

    if (isAuthenticated && !token) {
      dispatch(clearUserReducers());
    }

    const callApiWithoutAuthentication = {
      endpoint,
      method: 'get',
      id: badgeIssuedId,
      types: [
        BADGE_ASSERTION_REQUEST,
        BADGE_ASSERTION_SUCCESS,
        BADGE_ASSERTION_FAILURE,
      ],
    };

    dispatch({
      [CALL_API]:
        isAuthenticated && token
          ? Object.assign({}, callApiWithoutAuthentication, {
            authenticated: true,
          })
          : Object.assign({}, callApiWithoutAuthentication),
    });
  };
};

export const addAcknowledgement = (data) => {
  const endpoint = 'badge/acknowledgement';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        ADD_ACKNOWLEDGEMENT_REQUEST,
        ADD_ACKNOWLEDGEMENT_SUCCESS,
        ADD_ACKNOWLEDGEMENT_FAILURE,
      ],
    },
  };
};

export const removeAcknowledgement = (id) => {
  const endpoint = `badge/acknowledgement/${id}`;

  return {
    [CALL_API]: {
      id: id,
      endpoint,
      method: 'delete',
      authenticated: true,
      types: [
        REMOVE_ACKNOWLEDGEMENT_REQUEST,
        REMOVE_ACKNOWLEDGEMENT_SUCCESS,
        REMOVE_ACKNOWLEDGEMENT_FAILURE,
      ],
    },
  };
};

export const addEvidenceFileToIssuedBadge = (data) => {
  const endpoint = 'badge/file';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        ADD_EVIDENCE_FILE_REQUEST,
        ADD_EVIDENCE_FILE_SUCCESS,
        ADD_EVIDENCE_FILE_FAILURE,
      ],
    },
  };
};

export const removeEvidenceFile = (id) => {
  const endpoint = `file/file/${id}`;

  return {
    [CALL_API]: {
      id: id,
      endpoint,
      method: 'delete',
      authenticated: true,
      types: [
        REMOVE_EVIDENCE_FILE_REQUEST,
        REMOVE_EVIDENCE_FILE_SUCCESS,
        REMOVE_EVIDENCE_FILE_FAILURE,
      ],
    },
  };
};

export const unlinkBadgeEvidenceFile = (fileId, data) => {
  const endpoint = `badge/file/${fileId}`;

  return {
    [CALL_API]: {
      id: fileId,
      endpoint,
      data,
      method: 'patch',
      authenticated: true,
      types: [
        REMOVE_BADGE_EVIDENCE_FILE_REQUEST,
        REMOVE_BADGE_EVIDENCE_FILE_SUCCESS,
        REMOVE_BADGE_EVIDENCE_FILE_FAILURE,
      ],
    },
  };
};

export const updateStatement = (data, statementId) => {
  const endpoint = `badge/statement/${statementId}`;

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'put',
      authenticated: true,
      types: [
        UPDATE_STATEMENT_REQUEST,
        UPDATE_STATEMENT_SUCCESS,
        UPDATE_STATEMENT_FAILURE,
      ],
    },
  };
};

export const updateJustificationAnswer = (id, index, data) => {
  const endpoint = `badge/justification-answers/${id}`;

  return {
    [CALL_API]: {
      data,
      index,
      endpoint,
      method: 'patch',
      authenticated: true,
      types: [
        UPDATE_JUSTIFICATION_ANSWER_REQUEST,
        UPDATE_JUSTIFICATION_ANSWER_SUCCESS,
        UPDATE_JUSTIFICATION_ANSWER_FAILURE,
      ],
    },
  };
};

export const likeEvidenceFile = (data) => {
  const endpoint = 'file/likes';
  return {
    [CALL_API]: {
      endpoint,
      data,
      id: data.file,
      method: 'post',
      authenticated: true,
      types: [
        LIKE_EVIDENCE_FILE_REQUEST,
        LIKE_EVIDENCE_FILE_SUCCESS,
        LIKE_EVIDENCE_FILE_FAILURE,
      ],
    },
  };
};

export const unlikeEvidenceFile = (fileId) => {
  const endpoint = `file/likes/${fileId}`;
  return {
    [CALL_API]: {
      endpoint,
      id: fileId,
      method: 'delete',
      authenticated: true,
      types: [
        UNLIKE_EVIDENCE_FILE_REQUEST,
        UNLIKE_EVIDENCE_FILE_SUCCESS,
        UNLIKE_EVIDENCE_FILE_FAILURE,
      ],
    },
  };
};
