export default {
  'production': {
    'api': 'https://api.careerprepped.com/',
    'localurl': 'https://careerprepped.com',
  },

  'demo': {
    'api': 'https://demoapi.careerprepped.com/',
    'localurl': 'https://demo.careerprepped.com',
  },

  'staging': {
    'api': 'https://stgapi.careerprepped.com/',
    'localurl': 'https://stg.careerprepped.com',
  },

  'development': {
    'api': 'https://devapi.careerprepped.com/',
    //    'api': 'http://localhost/',
    'localurl': 'https://dev.careerprepped.com/',
  }
};
