import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col, Modal, ModalBody } from 'reactstrap';

import CloseIcon from '../../assets/images/close.svg';

const LevelUpBadgeModal = ({ isOpen, toggleModal, proceed, educators, type, industryReps }) => {

  function handleToggleHandle(){
    toggleModal('requestModal');
  }

  function handleRequestEducators(){
    proceed('requestEducators');
  }

  function handleRequestIRs(){
    proceed('requestIRs');
  }

  function handleRequestPeers(){
    proceed('requestPeers');
  }

  return (
    <Modal
      centered
      className="customLevelUpModal"
      isOpen={isOpen}
      toggle={handleToggleHandle}>
      <div className="modal-header">
        <span className="modal-title">{`Level Up ${type} with Feedback`}</span>
        <div
          onClick={handleToggleHandle}
          className="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">
            <img src={CloseIcon} />
          </span>
        </div>
      </div>

      <ModalBody>
        <div style={{ paddingBottom: '15px' }}>
          <span>Who do you want to get feedback from?</span>
        </div>
        <Row
          style={{ cursor: 'pointer', ...((industryReps === 0) && { cursor: 'default' }) }}
          onClick={(industryReps > 0) ? handleRequestIRs : undefined}
          className={classNames({ 'row-unavailable': industryReps === 0 })}>
          <Col sm="2">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={require('../../assets/images/industryrepresentative.png')}
                className="responsive"/>
            </div>
          </Col>
          <Col sm="9">
            <div>
              <span
                style={{
                  display: 'block',
                  marginBottom: '0.4rem',
                }}>
                <strong style={{ fontSize: 18 }}>
                  CareerPrepped Industry Mentors
                </strong>
              </span>
              <p className="text-secondary">
                {(industryReps > 0) ?
                  'Get matched to industry professionals for feedback. These professionals represent talent employers on CareerPrepped. They\'ve joined to help members prove their skills.' :
                  'No match is available for you at this time.'}
              </p>
            </div>
            <hr />
          </Col>
        </Row>

        <Row
          onClick={(educators.length > 0) ? handleRequestEducators : undefined}
          style={{ cursor: 'pointer', ...((educators.length === 0) && { cursor: 'default' }) }}
          className={classNames({ 'row-unavailable': educators.length === 0 })}>
          <Col sm="2">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={require('../../assets/images/educators.png')}
                className="responsive"/>
            </div>
          </Col>
          <Col sm="9">
            <div>
              <span
                style={{
                  display: 'block',
                  marginBottom: '0.4rem',
                }}>
                <strong style={{ fontSize: 18 }}>CareerPrepped Talent Developers</strong>
              </span>
              <p className="text-secondary">
                {(educators.length > 0) ?
                  'Get feedback from Talent Developers in your CareerPrepped network. Talent Developers are professionals working in career readiness, work-based learning, and job placement programs, helping members succeed in their careers.' :
                  'No Talent Developers are currently in your network.'}
              </p>
            </div>
            <hr />
          </Col>
        </Row>

        <Row
          style={{ cursor: 'pointer' }}
          onClick={handleRequestPeers}>
          <Col sm="2">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={require('../../assets/images/peers.png')}
                className="responsive"/>
            </div>
          </Col>
          <Col sm="9">
            <div>
              <span
                style={{
                  display: 'block',
                  marginBottom: '0.4rem',
                }}>
                <strong style={{ fontSize: 18 }}>Others in Your Network</strong>
              </span>
              <span className="text-secondary">
                Get feedback from anyone in your CareerPrepped network, apart from Industry Mentors and Talent Developers. You can also invite others to join you on CareerPrepped to give you feedback.
              </span>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

LevelUpBadgeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  proceed: PropTypes.func.isRequired,
  educators: PropTypes.array,
  industryReps: PropTypes.number,
  type: PropTypes.string,
};

export default LevelUpBadgeModal;
