import PropTypes from 'prop-types';
import React from 'react';
import UnitCard from './UnitCard';

const UnitList = ({units, homepageModal}) => {
  return (
    <div className="list">
      {units.map((unit, index) =>
        (<UnitCard
          key={`${unit.id}${index}`}
          homepageModal={homepageModal}
          unit={unit}/>))}
    </div>
  );
};

UnitList.propTypes = {
  homepageModal: PropTypes.bool,
  units: PropTypes.array.isRequired
};

export default UnitList;
