import React from 'react';

import {
  Row,
  Col,
} from 'reactstrap';

import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

const styles = {
  contactInfoStyle: {
    marginBottom: '20px',
  },
  contactsError: {
    color: 'red',
  },
  editButton: {
    fontSize: 14
  }
};

const ContactInfoSection = ({ updatedProfileData, currentUserId, toggleUpdateContactForm }) => {
  const { host } = window.location;
  const isOwner = currentUserId === updatedProfileData.id;

  return (
    <div>
      <Row>
        <Col md="10" xs="8">
          <b>Contact Info</b>  {isOwner &&
            <i className="fa fa-pencil clickable add-button"
              style={styles.editButton}
              aria-hidden="true"
              onClick={toggleUpdateContactForm}/>}
        </Col>
      </Row>
      <br/>

      <Row style={styles.contactInfoStyle}>
        <Col md="1" xs="1">
          <div><i className="fa fa-globe" aria-hidden="true" /></div>
        </Col>
        <Col md="11" xs="11">
          <Row>
            <Col md="12"><b>CP Career Site URL</b></Col>
          </Row>
          <Row>
            <Col md="12">
              <Link
                to={`/cp/${updatedProfileData.vanity}`}
                className="view-webpage mt-2 mt-md-0">
                {`${host}/cp/${updatedProfileData.vanity}`}
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={styles.contactInfoStyle}>
        <Col md="1" xs="1">
          <i className="fa fa-envelope" aria-hidden="true" />
        </Col>
        <Col md="11" xs="11">
          <Row>
            <Col md="12"><b>Email</b> </Col>
          </Row>
          <Row>
            <Col md="12">{updatedProfileData.email}</Col>
          </Row>
        </Col>
      </Row>

      {(updatedProfileData.secondaryEmail.length !== 0) &&
        <Row style={styles.contactInfoStyle}>
          <Col md="1" xs="1">
            <i className="fa fa-envelope-open" aria-hidden="true" />
          </Col>
          <Col md="11" xs="11">
            <Row>
              <Col md="12"><b>Alternate Email</b> </Col>
            </Row>
            <Row>
              <Col md="12">{updatedProfileData.secondaryEmail}</Col>
            </Row>
          </Col>
        </Row>}

      {(updatedProfileData.phoneNumber.length !== 0) &&
        <Row style={styles.contactInfoStyle} >
          <Col md="1" xs="1">
            <i className="fa fa-phone" aria-hidden="true" />
          </Col>
          <Col md="11" xs="11">
            <Row>
              <Col md="12"><b>Phone number</b></Col>
            </Row>
            <Row>
              <Col md="12">{updatedProfileData.phoneNumber}</Col>
            </Row>
          </Col>
        </Row>}
    </div>
  );
};

ContactInfoSection.propTypes = {
  updatedProfileData: PropTypes.object.isRequired,
  currentUserId: PropTypes.string.isRequired,
  toggleUpdateContactForm: PropTypes.func.isRequired
};

export default ContactInfoSection;
