import React from 'react';

import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';

const QACModal = ({
  errors,
  request,
  onChange,
  requestInvite,
  isRequesting,
  refNameField,
  showRequestInviteModal,
  toggleRequestInviteModal
}) => {
  return (
    <Modal isOpen={showRequestInviteModal} toggle={toggleRequestInviteModal} size={'md'} centered>
      <div className="skillbuilder sbmodals" id="skillBuilder">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title"
              id="myModalLabel"
              style={{fontSize:'1.5rem'}}>
              Request CareerPrepped Account
            </h5>
            <button type="button" className="close" onClick={toggleRequestInviteModal} style={{marginTop: '-0.7rem'}}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="sb-cont">
              <form onSubmit={requestInvite} autoComplete={'nope'}>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="sr-only">First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        ref={refNameField}
                        className="form-control"
                        placeholder="First Name"
                        onChange={onChange}
                        value={request.firstName}/>
                      {errors.firstName &&
                        <div className="text-danger">{errors.firstName}</div>}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="sr-only">Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        className="form-control"
                        placeholder="Last Name"
                        onChange={onChange}
                        value={request.lastName}/>
                      {errors.lastName &&
                        <div className="text-danger">{errors.lastName}</div>}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="sr-only">Job Title</label>
                  <input
                    type="text"
                    name="jobTitle"
                    className="form-control"
                    placeholder="Job Title"
                    onChange={onChange}
                    value={request.jobTitle}/>
                  {errors.jobTitle &&
                    <div className="text-danger">{errors.jobTitle}</div>}
                </div>
                <div className="form-group">
                  <label className="sr-only">Organization Name</label>
                  <input
                    type="text"
                    name="organization"
                    className="form-control"
                    placeholder="Organization Name"
                    onChange={onChange}
                    value={request.organization}/>
                  {errors.organization &&
                    <div className="text-danger">{errors.organization}</div>}
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="sr-only">Email</label>
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        onChange={onChange}
                        value={request.email}/>
                      {errors.email &&
                        <div className="text-danger">{errors.email}</div>}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="sr-only">Phone</label>
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        placeholder="Phone"
                        onChange={onChange}
                        value={request.phone}/>
                      {errors.phone &&
                        <div className="text-danger">{errors.phone}</div>}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <label className="sr-only">Additional Information</label>
                    <textarea
                      name="additionalInformation"
                      className="form-control"
                      placeholder="Please provide information about your EEQ Certification or your plans to apply for certification"
                      rows="5"
                      autoComplete="nope"
                      onChange={onChange}
                      value={request.additionalInformation}/>
                    {errors.additionalInformation &&
                      <div className="text-danger">{errors.additionalInformation}</div>}
                  </div>
                </div>
                <input
                  type="submit"
                  disabled={isRequesting}
                  value={isRequesting ? 'Requesting...' : 'Request'}
                  className="btn sign-in-button qa-commons-modal-btn" style={{marginTop: '15px', color: 'white',width:'100%'}}/>
                <p className="terms-text">
                  CareerPrepped organizational accounts are sponsored by QA Commons in partnership with MaxKnowledge, to support schools in seeking or maintaining EEQ Certification.
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

QACModal.propTypes = {
  errors: PropTypes.object,
  request: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  showRequestInviteModal: PropTypes.bool.isRequired,
  toggleRequestInviteModal: PropTypes.func.isRequired,
  refNameField: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  requestInvite: PropTypes.func.isRequired
};

export default QACModal;
