import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getAllOrganizations } from '../../redux/actions/organizations';
import {
  getAllEducators,
  getJobsStatistics,
  getUsersStatistics,
  getSkillsStatistics,
  getBadgesStatistics,
  getSurveysStatistics,
  getFilesStatistics,
  getSurveyFeedbacks
} from '../../redux/actions/statistics';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Label, Input } from 'reactstrap';

import AdminSubGroups from './AdminSubGroups';
import EducatorsDropdown from './EducatorsDropdown';
import Loading from '../../components/common/Loading';
import OrganizationsDropdown from './OrganizationsDropdown';
import EducatorsAllGroupsDropdown from './EducatorsAllGroupsDropdown';
import SkillBadgeSurvey from '../../components/admin/SkillBadgeSurvey';
import TableLoading from '../../components/common/TableLoading';
import PaginationHandler from '../../components/common/PaginationHandler';
import OrganizationsParentGroupDropdown from './OrganizationsParentGroupDropdown';

const Statistics = ({
  userId,
  actions,
  statistics,
  selectedGroup,
  isRequestingGroups,
  isInitialRequest,
  isAuthenticated,
  selectedOrganization,
  isChildGroupSelected,
  userParentGroupsCount
}) => {
  const {
    getAllEducators,
    getUsersStatistics,
    getJobsStatistics,
    getAllOrganizations,
    getBadgesStatistics,
    getSkillsStatistics,
    getSurveysStatistics,
    getFilesStatistics,
    getSurveyFeedbacks
  } = actions;

  const [selectedBadge, setSelectedBadge] = useState('');

  useEffect(() => {
    getJobsStatistics(userId);
    getUsersStatistics(userId);
    getSkillsStatistics(userId);
    getFilesStatistics(userId);
    getBadgesStatistics();
    getSurveysStatistics();
    getSurveyFeedbacks();

    if (isAuthenticated) {
      getAllOrganizations(undefined, true)
        .then(() => getAllEducators(undefined, true));
    }
  }, [
    userId,
    getAllEducators,
    getAllOrganizations,
    isAuthenticated,
    getJobsStatistics,
    getUsersStatistics,
    getSkillsStatistics,
    getBadgesStatistics,
    getSurveysStatistics,
    getFilesStatistics,
    getSurveyFeedbacks
  ]);

  function onSelectBadge(event) {
    const skillBuilderUnitId = event.target.value;

    setSelectedBadge(skillBuilderUnitId);
    getSurveysStatistics(skillBuilderUnitId);
  }

  function onNext() {
    const { page } = statistics.surveyFeedbacks.paginationData;
    const urlParamString = `page=${page + 1}`;

    getSurveyFeedbacks(urlParamString);
  }

  function onPrev() {
    const { page } = statistics.surveyFeedbacks.paginationData;
    const urlParamString = `page=${page - 1}`;

    getSurveyFeedbacks(urlParamString);
  }

  function onSelectPage(page) {
    return () => {
      const urlParamString = `page=${page}`;

      getSurveyFeedbacks(urlParamString);
    };
  }

  return (
    <div className="admin-dashboard">
      <div className="interior invite-users">
        <Helmet title="Admin - Statistics"/>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 content follow">
              <div style={{marginTop: 20}}>
                <h2>PRODUCT USAGE</h2>
                <div className="row">
                  <div className="col-lg-6">
                    {statistics.users.isRequesting && <Loading />}
                    {!statistics.users.isRequesting &&
                      <div>
                        <ul>
                          <li>Total Users: {statistics.users.data.totalUsers}</li>
                          <ul>
                            <li>Total Industry Mentors: {statistics.users.data.totalIndustryMentors}</li>
                            <li>Total Admins: {statistics.users.data.totalAdmins}</li>
                            <li>Total Talent Developers: {statistics.users.data.totalTalentDevelopers}</li>
                            <li>Total Regular Users: {statistics.users.data.totalRegularUsers}</li>
                          </ul>
                        </ul>
                      </div>}
                  </div>
                  <div className="col-lg-6">
                    {statistics.jobs.isRequesting && <Loading />}
                    {!statistics.jobs.isRequesting &&
                      <div>
                        <ul>
                          <li>Total Jobs in Job Tracker: {statistics.jobs.data.total}</li>
                          <ul style={{columnCount: 2}}>
                            <li>Total Closed: {statistics.jobs.data.closed}</li>
                            <li>Total Saved: {statistics.jobs.data.saved}</li>
                            <li>Total Applied: {statistics.jobs.data.applied}</li>
                            <li>Total Interviewed: {statistics.jobs.data.interviewed}</li>
                            <li>Total Offered: {statistics.jobs.data.offered}</li>
                            <li>Total No Response: {statistics.jobs.data.noResponse}</li>
                            <li>Total Rejected: {statistics.jobs.data.rejected}</li>
                          </ul>
                        </ul>
                      </div>}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    {statistics.skills.isRequesting && <Loading />}
                    {!statistics.skills.isRequesting &&
                      <div>
                        <ul>
                          <li>Total Claimed Skills: {statistics.skills.data.totalSkillsClaimed}</li>
                          <ul>
                            <li>Total "Other Skills" Claimed: {statistics.skills.data.totalSkills}</li>
                            <li>Total Skill Badges Claimed: {statistics.skills.data.totalBadgesIssued}</li>
                          </ul>
                        </ul>
                      </div>}
                  </div>

                  <div className="col-lg-6">
                    {statistics.files.isRequesting && <Loading />}
                    {!statistics.files.isRequesting &&
                      <div>
                        <ul>
                          <li>Total Portfolio Files: {statistics.files.data.totalPortfolioFiles}</li>
                        </ul>
                      </div>}
                  </div>
                </div>

                <h6>Total of Each Skill Badge Claimed</h6>
                <div className="row">
                  <div className="col-lg-12">
                    {statistics.badges.isRequesting && <Loading />}
                    {!statistics.badges.isRequesting &&
                      <ul style={{columnCount: 3}}>
                        {statistics.badges.data.map(badge =>
                          <li key={badge.id}>{`${badge.name}: ${badge.totalClaimed}`}</li>)}
                      </ul>}
                  </div>
                </div>

                <h6>Skill Badge Survey Data</h6>
                <div className="row">
                  <div className="col-lg-12">
                    <Label>Skill Badge Filter</Label>
                    <Input
                      type="select"
                      name="filter"
                      value={selectedBadge}
                      onChange={onSelectBadge}>
                      <option key="all-badges" value="">All Badges</option>
                      {statistics.badges.data.map(badge =>
                        <option key={badge.unitId} value={badge.unitId}>{badge.name}</option>)}
                    </Input>

                    <div style={{marginTop: 20}}>
                      {statistics.surveys.isRequesting && <Loading />}
                      {!statistics.surveys.isRequesting &&
                        <SkillBadgeSurvey statistics={statistics} />}
                    </div>
                  </div>
                </div>

                <h6>Survey Feedbacks</h6>
                <div className="row">
                  <div className="col-lg-12">
                    <table className="cp-table mt-2 table table-responsive-lg">
                      <thead>
                        <tr className="cp-table-header">
                          <th>#</th>
                          <th>Feedback</th>
                        </tr>
                      </thead>

                      <tbody>
                        {(statistics.surveyFeedbacks.data.length === 0) &&
                          <tr>
                            <td style={{textAlign: 'center'}} colSpan="2">
                              {'Sorry, we couldn\'t find anything!'}
                            </td>
                          </tr>}

                        {(statistics.surveyFeedbacks.data.length !== 0) &&
                          statistics.surveyFeedbacks.data.map((surveyFeedback, index) => (
                            <tr key={surveyFeedback.id}>
                              <td>{index + 1}</td>
                              <td>{surveyFeedback.feedback}</td>
                            </tr>
                          ))}
                      </tbody>

                      <tfoot>
                        <tr>
                          <th style={{textAlign: 'center'}} colSpan="2">
                            <PaginationHandler
                              onNext={onNext}
                              onPrev={onPrev}
                              onSelectPage={onSelectPage}
                              page={statistics.surveyFeedbacks.paginationData.page}
                              page_count={statistics.surveyFeedbacks.paginationData.page_count}/>
                          </th>
                        </tr>
                      </tfoot>
                    </table>

                    {statistics.surveyFeedbacks.isRequesting && <TableLoading/>}
                  </div>
                </div>

                <h5>Organizations</h5>
                {!statistics.users.isRequesting && isInitialRequest && <Loading/>}
                {!statistics.users.isRequesting && !isInitialRequest && <OrganizationsDropdown/>}

                {isRequestingGroups && !isChildGroupSelected && <Loading/>}
                {((! isChildGroupSelected) && (userParentGroupsCount > 0)) &&
                  <>
                    <h6>
                      {selectedOrganization.name}
                    </h6>
                    <OrganizationsParentGroupDropdown parentPage="admin-dashboard"/>
                  </>}

                {(selectedGroup.children) &&
                  (selectedGroup.children.length !== 0) &&
                  <AdminSubGroups parentPage="admin-dashboard"/>}

                <br/>
                <h5>Talent Developer Accounts</h5>
                {statistics.educators.isInitialRequest && <Loading/>}
                {!isInitialRequest &&
                  !statistics.educators.isInitialRequest &&
                  statistics.educators.data.length !== 0 &&
                  <EducatorsDropdown/>}

                {statistics.groups.isRequesting &&
                  statistics.groups.isInitialRequest &&
                  <Loading/>}

                {!statistics.groups.isRequesting &&
                  statistics.groups.data.length > 0 &&
                  <>
                    <h6>{statistics.educators.selectedEducator.name}</h6>
                    <EducatorsAllGroupsDropdown/>
                  </>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Statistics.propTypes = {
  userId: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  statistics: PropTypes.object.isRequired,
  selectedGroup: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isInitialRequest: PropTypes.bool.isRequired,
  isRequestingGroups: PropTypes.bool.isRequired,
  isChildGroupSelected: PropTypes.bool.isRequired,
  selectedOrganization: PropTypes.object.isRequired,
  userParentGroupsCount: PropTypes.number.isRequired
};

const mapStateToProps = (state) => {
  const {
    statistics,
    organizations: {
      isInitialRequest,
      selectedOrganization
    },
    auth: { isAuthenticated, data: { currentUser: { id: userId } } },
    groups: {
      selectedGroup,
      isRequesting: isRequestingGroups,
      isChildGroupSelected,
      data: userAdminGroups
    }
  } = state;

  const allGroups = userAdminGroups.filter(group => group.id !== 'all-groups');
  const userParentGroupsCount = allGroups.length;

  return {
    userId,
    statistics,
    selectedGroup,
    isAuthenticated,
    isInitialRequest,
    isRequestingGroups,
    selectedOrganization,
    isChildGroupSelected,
    userParentGroupsCount
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      getAllEducators,
      getUsersStatistics,
      getAllOrganizations,
      getJobsStatistics,
      getSkillsStatistics,
      getBadgesStatistics,
      getSurveysStatistics,
      getFilesStatistics,
      getSurveyFeedbacks
    }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
