import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from '../../common/Button';
import TextInput from '../../common/TextInput';
import Loading from '../../common/Loading';
import ImageList from '../essentials/ImageList';

const styles = {
  dropzoneStyle: {
    width: '100%',
    height: 50,
    border: '2px dashed #d9d9d9',
    borderRadius: 5,
    cursor: 'pointer',
    marginTop: 10,
    marginBottom: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  activeStyle: {
    border: '2px dashed #9e9d9d'
  },
  placeholder: { margin: 0, color: '#8e8d8d' },
  panelHeading: { minHeight: 30, backgroundColor: '#fafafa', marginBottom: 10 },
  heading: { textAlign: 'center', paddingRight: 40, color: 'black', paddingTop: 10 }
};

const ImageForm = ({
  isAdmin,
  isUploading,
  onCancel,
  errors,
  section,
  onChange,
  onSave,
  onDelete,
  isSubmitting,
  isUpdating,
  isDeleting,
  formType,
  onImageDrop,
  caption,
  description,
  images,
  onDeleteImage,
  moveCard
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: onImageDrop });

  const style = useMemo(() => ({
    ...styles.dropzoneStyle,
    ...(isDragActive ? styles.activeStyle : {})
  }), [isDragActive]);

  return (
    <div>
      {isAdmin &&
        <div className="card-header" style={styles.panelHeading}>
          <h3 style={styles.heading}>
            Image
          </h3>
        </div>}

      <form id={formType ? `${formType.formName}_${formType.id}` : ''}>
        <div className="card-body">
          {errors && errors.url &&
            <div className="alert alert-danger">{errors.url}</div>}

          <div className="row">
            <div className="col-sm-12">
              <TextInput
                autoFocus
                label="Title"
                name="title"
                value={section.title}
                onChange={onChange}
                placeholder="Title"/>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <TextInput
                label="URL"
                name="url"
                type="url"
                value={section.url}
                onChange={onChange}
                placeholder="Enter URL to a web accessible image or upload image below."/>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <TextInput
                label="Image title"
                name="caption"
                type="text"
                value={caption}
                onChange={onChange}
                placeholder="Enter image title."/>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <TextInput
                label="Image description"
                name="description"
                type="text"
                note="This will be the image's alt text"
                value={description}
                onChange={onChange}
                placeholder="Enter image description."/>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              {isUploading ?
                <Loading/> :
                <div {...getRootProps({style})}>
                  <input {...getInputProps()} />
                  <p style={styles.placeholder}>
                    {isDragActive ?
                      'Drop the file here...' :
                      'Drop an image or click to select file to upload'}
                  </p>
                </div>}
              {/** <Dropzone
                  style={styles.dropzoneStyle}
                  activeStyle={styles.activeStyle}
                  multiple={false}
                  accept="image/*"
                  onDrop={onImageDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()}/>
                      <p style={styles.placeholder}>
                        Drop an image or click to select file to upload.
                      </p>
                    </div>
                  )}
                  </Dropzone>*/}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <ImageList
                images={images}
                isAdmin={isAdmin}
                section={section}
                moveCard={moveCard}
                onDeleteImage={onDeleteImage}
                isEditing/>
            </div>
          </div>

        </div>
        <div>
          <hr />
          {!formType &&
            <Button
              type="submit"
              onClick={onDelete}
              disabled={isDeleting}
              styles={{marginLeft: 0}}
              buttonClass="btn-danger"
              buttonText={isDeleting ? 'Deleting...' : 'Delete'}/>}

          <Button
            type="submit"
            onClick={onSave}
            disabled={isSubmitting || isUpdating}
            buttonClass="btn-primary float-right"
            buttonText={isSubmitting || isUpdating ? 'Saving...' : 'Save'}/>

          <Button
            type="button"
            buttonId={section ? `text-list_${section.id}` : ''}
            onClick={onCancel}
            buttonClass="btn-secondary float-right"
            buttonText="Cancel"/>
        </div>
      </form>
    </div>
  );
};

ImageForm.propTypes = {
  section: PropTypes.object,
  errors: PropTypes.object,
  formType: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool,
  onImageDrop: PropTypes.func.isRequired,
  isUploading: PropTypes.bool.isRequired,
  caption: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  moveCard: PropTypes.func,
  description: PropTypes.string,
  onDeleteImage: PropTypes.func.isRequired
};

export default ImageForm;
