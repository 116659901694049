import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const PaginationHandler = ({ page, page_count, onNext, onPrev, onSelectPage}) => {
  if(page === page_count && page_count === 1){
    return (
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-12">
          <ul className="pagination justify-content-center">
            <li className="btn btn-primary-outline clickable disabled mr-2">
              <a tabIndex="-1">Previous</a>
            </li>
            <li className="page-item clickable link active"><a className="page-link">1</a></li>
            <li className="btn btn-primary clickable disabled ml-2">
              <a tabIndex="-1">Next</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
  return (
    <div className="row justify-content-center align-items-center">
      <div className="col-lg-12">
        <ul className="pagination justify-content-center">

          <li className={classNames('btn btn-primary-outline clickable mr-2', {
            'disabled': page === 1
          })}>
            <a
              onClick={onPrev}
              tabIndex="-1">Previous</a>
          </li>
          {
            (page - 2 > 0) &&
              <li className="page-item clickable link disabled">
                <a className="page-link">...</a>
              </li>
          }
          {
            (page - 1 > 0) &&
              <li className="page-item clickable link">
                <a
                  className="page-link"
                  onClick={onSelectPage(page-1)}>{page - 1}</a>
              </li>
          }
          <li className="page-item clickable link active">
            <a
              className="page-link"
              onClick={onSelectPage(page)}>{page}</a>
          </li>
          {
            (page + 1 <= page_count) &&
              <li className="page-item clickable link">
                <a
                  className="page-link"
                  onClick={onSelectPage(page+1)}>{page + 1}</a>
              </li>
          }
          {
            (page + 2 <= page_count) &&
              <li className="page-item clickable link">
                <a
                  className="page-link"
                  onClick={onSelectPage(page+2)}>{page + 2}</a>
              </li>
          }
          {
            (page + 3 <= page_count) &&
              <li className="page-item clickable link">
                <a
                  className="page-link"
                  onClick={onSelectPage(page+3)}>{page + 3}</a>
              </li>
          }
          {
            (page + 4 < page_count) &&
              <li className="page-item clickable link disabled">
                <a className="page-link">...</a>
              </li>
          }
          <li className={classNames('btn btn-primary clickable ml-2', {
            'disabled': page === page_count
          })}>
            <a onClick={onNext}>Next</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

PaginationHandler.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  page: PropTypes.number,
  page_count: PropTypes.number,
  onSelectPage: PropTypes.func
};

export default PaginationHandler;
