import initialState from './initialState';

import {
  QUESTIONAIRES_REQUEST,
  QUESTIONAIRES_SUCCESS,
  QUESTIONAIRES_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.questionaires, action) {
  switch (action.type) {
  case QUESTIONAIRES_REQUEST:
    return Object.assign({}, state, {
      isRequesting: true,
    });

  case QUESTIONAIRES_SUCCESS:
    return Object.assign({}, state, {
      data: action.data._embedded.questionnaires,
      isRequesting: false,
    });

  case QUESTIONAIRES_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  default:
    return state;
  }
}
