import PropTypes from 'prop-types';
import React from 'react';
import SidebarUserInfo from '../../common/SidebarUserInfo';

const LevelPageComponent = ({ profile }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="content level">
          <div className="row">
            <div className="col-lg-3">
              <h2>Your Level</h2>
              <SidebarUserInfo profile={profile}/>
            </div>
            <div className="col-lg-9">
              <strong>Factors Evaluated:</strong>
              <div className="level-item">
                <p>
                  <strong>
                    Profile Strength:
                  </strong>
                  {' '}Strong profiles gain more interest from employers and demonstrate your ability to market yourself.
                </p>
                <div className="level-bar">
                  <div className="overlay"/>
                  <div className="prog skew p1"/>
                  <div className="prog skew p2"/>
                  <div className="prog skew p3"/>
                  <div className="indicator" data-percent="50%"/>
                </div>
                <p>
                  Improve your profile strength as you add detail to your Professional Profile and increase profile views.
                </p>
              </div>
              <div className="level-item">
                <p><strong>Skills Strength:</strong>
                  {' '}Having skills employers want and providing evidence of your skills improves your ability to attract employers.
                </p>
                <div className="level-bar">
                  <div className="overlay"/>
                  <div className="prog skew p1"/>
                  <div className="prog skew p2"/>
                  <div className="prog skew p3"/>
                  <div className="indicator" data-percent="50%"/>
                </div>
                <p>
                  Improve your skills strength by claiming Skill Badges and earning endorsements for your badges.
                </p>
              </div>
              <div className="level-item">
                <p><strong>Post Strength:</strong>
                  {' '}Positive reactions to your posts demonstrates your ability to influence others which is a sign of leadership.
                </p>
                <div className="level-bar">
                  <div className="overlay"/>
                  <div className="prog skew p1"/>
                  <div className="prog skew p2"/>
                  <div className="prog skew p3"/>
                  <div className="indicator" data-percent="50%"/>
                </div>
                <p>
                  Improve your post strength by publishing helpful posts that earn you followers, shares, and likes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

LevelPageComponent.propTypes = {
  profile: PropTypes.object.isRequired
};

export default LevelPageComponent;
