import React from 'react';

import _isUndefined from 'lodash/isUndefined';

import LocationCard from './LocationCard';

import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const UserDetails = ({ profile, isOwner, isPublicProfile }) => {
  const { isPrivacyVisible } = profile;
  const isEmptyHeadline = !_isUndefined(profile.headline) && profile.headline === '';

  function onCopy() {
    toastr.success('', `You successfully copied the Career Site URL for ${profile.name}`);
  }

  const profileUrl = `${window.location.host}/cp/${profile.vanity}`;

  return (
    <div className="profile-info-box">
      <div className="profile-info-item">
        <span className="name">
          {profile.name}
        </span>
      </div>
      {
        (isPrivacyVisible || isOwner) &&
          <div>
            {
              !isEmptyHeadline &&
                <div className="profile-info-item">
                  <span className="headline">
                    {profile.headline}
                  </span>
                </div>
            }

            {
              isEmptyHeadline && isOwner && !isPublicProfile &&
                <div className="profile-info-item">
                  <span className="headline">
                    Enter your professional headline
                  </span>
                </div>
            }

            <div className="profile-info-item">
              <span className="weblink">
                {profile.vanity ? profileUrl : 'Vanity URL'}

                {profile.vanity &&
                  <CopyToClipboard text={profileUrl} onCopy={onCopy}>
                    <i className="pl-2 fas fa-copy clickable" />
                  </CopyToClipboard>}
              </span>
            </div>

            <div className="profile-info-item">
              <LocationCard profile={profile}/>
            </div>
          </div>
      }
    </div>
  );
};

UserDetails.propTypes = {
  isOwner: PropTypes.bool,
  isPublicProfile: PropTypes.bool,
  profile: PropTypes.object.isRequired
};

export default UserDetails;
