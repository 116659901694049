import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import classNames from 'classnames';
import { parseUrlString } from '../../utils';

import * as componentActions from '../../redux/actions/components';

import FileDetailsModal from '../../containers/portfolio/FileDetailsModal';
import LazyLoadImage from '../../containers/common/lazy-load-image/LazyLoadImage';

class SharedFilePost extends Component {
  constructor() {
    super();

    this.openSharedFileModal = this.openSharedFileModal.bind(this);
    this.isFileDetailsModalOpen = this.isFileDetailsModalOpen.bind(this);
  }

  componentWillUnmount() {
    this.props.actions.closeModal();
  }

  openSharedFileModal() {
    this.props.actions.openModal(`show-files-details-modal-${this.props.file.id}`);
  }

  isFileDetailsModalOpen() {
    return this.props.modal &&
      this.props.modal === `show-files-details-modal-${this.props.file.id}`;
  }


  render() {
    const { url, title, mimetype, id } = this.props.file;
    const docFile = mimetype.includes('application') || mimetype.includes('text');
    const parsedString = parseUrlString(url);
    const isVideoFile = parsedString && parsedString.type === 'iframe';

    return (
      <div className="shared-item">
        <p className="quote">{this.props.quote}</p>
        <div
          onClick={this.openSharedFileModal}
          style={{cursor: 'pointer'}}>
          {this.isFileDetailsModalOpen() &&
            <FileDetailsModal
              fileId={id}
              vanity={this.props.user.vanity}
              isOpen={this.isFileDetailsModalOpen()}/>}
          <div className={classNames('shared-item-container',{
            'video-file': isVideoFile
          })}>
            {
              docFile ?
                <div className="shared-item-image">
                  <div className="file-icon-container shared-file-display">
                    <i className="fa fa-file-text-o"/>
                  </div>
                </div>
                :
                isVideoFile ?
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      src={parsedString.url}
                      allowFullScreen
                      style={{'overflow': 'hidden'}}
                      scrolling="no"
                      className="embed-responsive-item"/>
                  </div>:
                  <div className="shared-item-image">
                    <LazyLoadImage
                      alt={`Shared portfolio file - ${title}`}
                      srcLoaded={url}/>
                  </div>
            }
            <div className="shared-item-description">
              <h6>{title}</h6>
              <div className="description">
                <p>Click to see details for this Portfolio File.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ modal: state.components.modal });

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(componentActions, dispatch)});

SharedFilePost.propTypes = {
  file: PropTypes.object.isRequired,
  user: PropTypes.object,
  quote: PropTypes.string,
  actions: PropTypes.object.isRequired,
  modal: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(SharedFilePost);
