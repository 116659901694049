import PropTypes from 'prop-types';

import React, { Component } from 'react';

import {
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { Link } from 'react-router-dom';

class ActionsCellMenu extends Component {
  static propTypes = {
    onToggleMessagingModal: PropTypes.func.isRequired,
    onFollow: PropTypes.func.isRequired,
    isSubmitting: PropTypes.object,
    following: PropTypes.object,
    organization: PropTypes.object.isRequired,
    onEditOrganization: PropTypes.func.isRequired
  }

  render() {
    const path = `/admin/manage-invites/${this.props.organization.id}`;

    return (
      <DropdownMenu>
        <DropdownItem
          onClick={this.props.onEditOrganization(this.props.organization)}>
          Edit
        </DropdownItem>

        <DropdownItem
          onClick={this.props.onFollow}>
          <Link to={path}>
            See Invites
          </Link>
        </DropdownItem>
      </DropdownMenu>
    );
  }
}

export default ActionsCellMenu;
