import PropTypes from 'prop-types';
import React from 'react';

import { NavItem, NavLink, Nav } from 'reactstrap';
import classnames from 'classnames';

const ModalTabNav = ({ activeTab, toggleTab, exampleBase }) => {
  return (
    <Nav tabs >
      {exampleBase.map((example,index) => {
        const { tabTitle, tabTitle2, tabNumber } = example;

        return (
          <NavItem key={index} fill="true">
            {tabTitle !== '' &&
              <NavLink
                className={classnames('clickable link', {
                  active: activeTab === tabNumber,
                  orange: activeTab !== tabNumber,
                })}
                id={tabNumber}
                onClick={toggleTab}>
                {tabTitle}
                {tabTitle2 && <div id={tabNumber}>{tabTitle2}</div>}
              </NavLink>}
          </NavItem>
        );
      })}
    </Nav>
  );
};

ModalTabNav.propTypes = {
  exampleBase: PropTypes.array.isRequired,
  activeTab: PropTypes.string,
  toggleTab: PropTypes.func.isRequired
};

export default ModalTabNav;
