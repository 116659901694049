export default [
  {
    id: '36ea08cf-73dd-4cfa-98ed-fe7fe9c7afe8',
    title: 'Module 1: Defining Global Competence',
    description: 'One in ten Americans is foreign born, and local communities — urban, suburban, and rural — are growing more diverse. One in five jobs is tied to international trade. This means that you must be prepared for work and civic roles in an environment where success increasingly requires the ability to work with people from diverse backgrounds in global markets. This module explores the definition of global competence and asks you to evaluate your own global competence.',
    url: 'https://global-competence-lessons.s3.us-west-2.amazonaws.com/STU+Mod+01/story.html'
  },
  {
    id: '2bf88b46-e627-4b36-8a69-e46d4ba4ae35',
    title: 'Module 2: Global Competence on the Job and in the Community',
    description: 'This module will help you explore global connections in work and in your community. It explores on-the-job situations requiring global competence and introduces the UN Sustainable Development Goals (SDGs) as a framework for connecting global issues and local challenges.',
    url: 'https://global-competence-lessons.s3.us-west-2.amazonaws.com/STU+Mod+02/story.html'
  },
  {
    id: '83a24f82-69d7-46c3-85e2-c346bac716e2',
    title: 'Module 3: Preparing for Global in My Career Field',
    description: 'This module will help you understand the international requirements of jobs in your career field and provides ideas to continue developing global skills by using rubrics for self-reflection.',
    url: 'https://global-competence-lessons.s3.us-west-2.amazonaws.com/STU+Mod+03/story.html'
  },
  {
    id: '1a3c1c03-a25d-4644-867f-8594084312f8',
    title: 'Module 4: My Global Competence Plan',
    description: 'This module will assist you in creating your own global competence goals and plan as well as help you articulate your skills to potential employers.',
    url: 'https://global-competence-lessons.s3.us-west-2.amazonaws.com/STU+Mod+04/story.html'
  }
];
