import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const ShareReflectionsSection = ({
  selectedReflections,
  onSelectReflection,
  onShareReflection,
  unit,
  onDownloadReflections,
  isDownloading
}) => {
  return (
    <section className="share-answers">
      <div className="row">
        <div className="col-sm-12">
          <h4>Share your reflection(s)</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-9">
          {
            unit.reflectone &&
              <div
                onClick={onSelectReflection('q1')}
                className={classNames('badge', {
                  'badge-active': selectedReflections.indexOf('q1') > -1,
                  'badge-default': selectedReflections.indexOf('q1') < 0
                })}>
                Question 1 {selectedReflections.indexOf('q1') > -1 && <i className="fa fa-check"/>}
              </div>
          }
          {
            unit.reflecttwo &&
              <div
                onClick={onSelectReflection('q2')}
                className={classNames('badge', {
                  'badge-active': selectedReflections.indexOf('q2') > -1,
                  'badge-default': selectedReflections.indexOf('q2') < 0
                })}>
                Question 2 {selectedReflections.indexOf('q2') > -1 && <i id={'q2'} className="fa fa-check"/>}
              </div>
          }

          {
            unit.reflectthree &&
              <div
                onClick={onSelectReflection('q3')}
                className={classNames('badge', {
                  'badge-active': selectedReflections.indexOf('q3') > -1,
                  'badge-default': selectedReflections.indexOf('q3') < 0
                })}>
                Question 3 {selectedReflections.indexOf('q3') > -1 && <i id={'q3'} className="fa fa-check"/>}
              </div>
          }

          {
            unit.reflectfour &&
              <div
                onClick={onSelectReflection('q4')}
                className={classNames('badge', {
                  'badge-active': selectedReflections.indexOf('q4') > -1,
                  'badge-default': selectedReflections.indexOf('q4') < 0
                })}>
                Question 4 {selectedReflections.indexOf('q4') > -1 && <i id={'q4'} className="fa fa-check"/>}
              </div>
          }
        </div>
        <div className="col-md-6 col-lg-3 text-right">
          <button
            onClick={onDownloadReflections}
            className="btn btn-tertiary"
            disabled={isDownloading}>
            <span className="fas fa-download"/> Download
          </button>
          <button
            style={{marginLeft: 10}}
            onClick={onShareReflection}
            className="btn btn-primary">
            Share
          </button>
        </div>
      </div>
    </section>
  );
};

ShareReflectionsSection.propTypes = {
  unit: PropTypes.object,
  selectedReflections: PropTypes.array,
  isDownloading: PropTypes.bool.isRequired,
  onSelectReflection: PropTypes.func.isRequired,
  onShareReflection: PropTypes.func.isRequired,
  onDownloadReflections: PropTypes.func.isRequired
};

export default ShareReflectionsSection;
