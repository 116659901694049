import React, { Component } from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import Validator from '../../validator';
import QuillEditor from '../../components/common/QuillEditor';
import SeeExampleModal from '../../components/skill-builders/claim-badge/SeeExampleModal';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as skillBuildersActions from '../../redux/actions/skill-builders';

const modules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'align': [] }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
    ['link'],
  ],
  clipboard: {
    matchVisual: false,
  }
};

class JustificationQuestionForm extends Component {
  static propTypes = {
    unit: PropTypes.object.isRequired,
    badge: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    justification: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      showExampleModal: false,
      editorPlainText: { justification: '' }
    };

    this.handleChange = this.handleChange.bind(this);
    this.onToggleExampleModal = this.onToggleExampleModal.bind(this);
  }

  onToggleExampleModal(event) {
    event.preventDefault();

    const { showExampleModal } = this.state;
    this.setState({
      showExampleModal: !showExampleModal
    });
  }

  isValid(field = null) {
    const rules = {
      justification: ['required', 'minWords|50', `maxWords|${this.props.justification.question.wordLimit}`]
    };
    const { editorPlainText } = this.state;

    const validate = Validator.createValidator(rules, editorPlainText, field);
    const { errors, isValid } = validate;

    this.setState({ errors });

    return isValid;
  }

  handleChange(value, ...params) {
    const editorPlainText = params[2].getText();

    const index = this.props.badge.justifications.findIndex(justification =>
      justification.question.id === this.props.justification.question.id);

    const newJustification = Object.assign({}, this.props.badge.justifications[index], {
      answer: {
        ...this.props.badge.justifications[index].answer,
        answerText: value,
        editorPlainText
      }
    });

    const issuedBadge = Object.assign({}, this.props.badge, {
      justifications: [
        ...this.props.badge.justifications.slice(0, index),
        newJustification,
        ...this.props.badge.justifications.slice(index + 1)
      ]
    });

    this.props.actions.updateUnitBadgeOnLocalStore(issuedBadge, this.props.unit.id);

    const justificationObject = { justification: editorPlainText };
    this.setState({ editorPlainText: justificationObject }, () => this.isValid('justification'));
  }

  render() {
    const { errors, showExampleModal } = this.state;
    const { justification } = this.props;
    const { question, answer } = justification;

    return (
      <div style={{marginBottom: 20}}>
        <div style={{marginTop: 10, marginBottom: question.questionNumber === 1 ? 10 : 0}}>
          <span style={{fontWeight: 'bold'}}>
            {question.questionText}
          </span>
          <span> ({question.wordLimit} words max) </span>
          <a href="#" onClick={this.onToggleExampleModal}>See Example</a>
        </div>
        {(question.questionNumber === 2) && <div>
          Ensure your answer addresses all three parts.
          <ul>
            <li>Describe the situation and/or task.</li>
            <li>Describe the action(s) you took that demonstrate your skill.</li>
            <li>Describe the positive result(s) achieved.</li>
          </ul>
        </div>}
        <div className={classNames('statement-form-container', {
          'has-danger': errors.justification
        })}>
          <QuillEditor
            value={answer.answerText}
            onChange={this.handleChange}
            modules={modules} />

          {errors.justification &&
            <div className="text-danger">{errors.justification}</div>}
        </div>

        {showExampleModal &&
          <SeeExampleModal
            question={question}
            isOpen={showExampleModal}
            onToggle={this.onToggleExampleModal} />}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({},
    skillBuildersActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};


export default connect(null, mapDispatchToProps)(JustificationQuestionForm);
