import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const SharedReflectionAnswersPost = ({
  quote,
  reflection,
  showAll,
  onToggleShowAll,
  isContentsOverflown,
  setReflectionAnswersContentContainerRef }) => {
  return (
    <div className="shared-item">
      <p className="quote">{quote}</p>

      {
        reflection.length > 0 &&
          <div style={{display: 'block'}} className="shared-item-container">
            <div className="shared-item-description reflection-sharing">
              <p>I just answered the reflection question(s) below from <Link to={`/skill-builders/${reflection[0].unit.slug}`}>{reflection[0].unit.title}</Link></p>
              <hr/>
              <div
                style={{
                  maxHeight: showAll ? 'initial': '100%',
                  overflow: isContentsOverflown ? 'hidden' : 'initial'
                }}
                ref={setReflectionAnswersContentContainerRef}
                className="shared-reflection-contents">
                {
                  reflection.map((ref, index) =>
                    (<div key={ref.id}>
                      <strong>{ref.question}</strong><br/>
                      {ref.answer}{index + 1 !== reflection.length && <span><br/><br/></span>}
                    </div>))}
              </div>
              {
                (isContentsOverflown || showAll) &&
                  <div
                    onClick={onToggleShowAll}
                    className="clickable link"
                    style={{marginTop: 10, fontWeight: '600'}}>
                    {showAll ? 'Show less': '...Show more'}</div>
              }
            </div>
          </div>
      }
    </div>
  );
};

SharedReflectionAnswersPost.propTypes = {
  quote: PropTypes.string,
  reflection: PropTypes.array.isRequired,
  isContentsOverflown: PropTypes.bool,
  showAll: PropTypes.bool,
  onToggleShowAll: PropTypes.func,
  setReflectionAnswersContentContainerRef: PropTypes.func
};

export default SharedReflectionAnswersPost;
