export default {
  workbooks: [
    {
      title: 'Self Management Skills',
      units: [
        {
          code: '1.10',
          title: 'Develop Self-Awareness Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Self-Management+Skills/Develop-Self-Awareness-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1vARDid3AzrO6A2lZS7j2oMXJgeZ-_6Tt/edit?usp=sharing&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Self-Management+Skills/Develop-Self-Awareness-Workbook.pdf'
        },
        {
          code: '1.15',
          title: 'Achieve Personal Goals Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Self-Management+Skills/Achieve-Personal-Goals-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1jvoRLbqb2gfhxrAfeseLhqRSeXING4r4/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Self-Management+Skills/Achieve-Personal-Goals-Workbook.pdf'
        },
        {
          code: '1.20',
          title: 'Manage Time Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Self-Management+Skills/Manage-Time-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1_fbWv3ckSrzs6kssMeMvo2IzpXRitn2O/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Self-Management+Skills/Manage-Time-Workbook.pdf'
        },
        {
          code: '1.25',
          title: 'Manage Stress Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Self-Management+Skills/Manage-Stress-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1xF_LD0SsX9HaKcJ5qDguUmm7N6azkYAm/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Self-Management+Skills/Manage-Stress-Workbook.pdf'
        },
        {
          code: '1.30',
          title: 'Manage Self-Directed Learning Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Self-Management+Skills/Manage-Self-Directed-Learning-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1wbbQd805t8gLu6xLlNJSpfHaTrgTxbSG/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Self-Management+Skills/Manage-Self-Directed-Learning-Workbook.pdf'
        },
        {
          code: '1.35',
          title: 'Model Professional Behavior Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Self-Management+Skills/Model-Professional-Behavior-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1yGCxeIKq8la61-bva9tnSsxNuaE2UP6f/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Self-Management+Skills/Model-Professional-Behavior-Workbook.pdf'
        },
        {
          code: '1.40',
          title: 'Manage Personal Budget Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Self-Management+Skills/Manage-Personal-Budget-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1bSFrx9udAEKPRIzvU1boMNV3Mo2Mqg1N/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Self-Management+Skills/Manage-Personal-Budget-Workbook.pdf'
        },
        {
          code: '1.45',
          title: 'Manage Credit Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Self-Management+Skills/Manage-Credit-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1x13wjchAlnXvb5i9Onibtidf05FNGwq4/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Self-Management+Skills/Manage-Credit-Workbook.pdf'
        },
        {
          code: '1.50',
          title: 'Manage Personal Debt Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Self-Management+Skills/Manage-Personal-Debt-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1sKKJqnFS8dSSU40NoLfMH9WxayiSGk0f/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Self-Management+Skills/Manage-Personal-Debt-Workbook.pdf'
        },
        {
          code: '1.55',
          title: 'Protect Identity Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Self-Management+Skills/Protect-Identity-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1XzCAgXfLR7js99GTS6D1YRRLIS-9XjrG/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Self-Management+Skills/Protect-Identity-Workbook.pdf'
        }
      ]
    },

    {
      title: 'Communication Skills',
      units: [
        {
          code: '2.10',
          title: 'Speak with Intention Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Communication+Skills/Speak-with-Intention-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/10MqRcKHX_0q2V76mzbzDAV0fGbph7W0R/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Communication+Skills/Speak-with-Intention-Workbook.pdf'
        },
        {
          code: '2.15',
          title: 'Write for Results Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Communication+Skills/Write-for-Results-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1CbsRU0w7M-ohrcO326FSc-rCHhob2xYL/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Communication+Skills/Write-for-Results-Workbook.pdf'
        },
        {
          code: '2.20',
          title: 'Listen Actively Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Communication+Skills/Listen-Actively-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1v7twfnDx9JhwYxShIo3u8odU6C7r3DWW/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Communication+Skills/Listen-Actively-Workbook.pdf'
        },
        {
          code: '2.25',
          title: 'Interpret Nonverbal Communication Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Communication+Skills/Interpret-Nonverbal-Communication-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1ad9monHa-LtQvqm1vXbmhXia0JNigRG9/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Communication+Skills/Interpret-Nonverbal-Communication-Workbook.pdf'
        },
        {
          code: '2.30',
          title: 'Present for Results Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Communication+Skills/Present-for-Results-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/19e7DOBzygXoTXjmBR6ZlPY1j4hsAzHd0/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Communication+Skills/Present-for-Results-Workbook.pdf'
        },
        {
          code: '2.35',
          title: 'Conduct Effective Meetings Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Communication+Skills/Conduct-Effective-Meetings-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/17LnZVBpmxCuHwYC_8TWG40FXwayOaILb/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Communication+Skills/Conduct-Effective-Meetings-Workbook.pdf'
        },
        {
          code: '2.40',
          title: 'Communicate Effectively on the Phone Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Communication+Skills/Communicate-Effectively-on-the-Phone-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1Qbvh6lDrtITXWdgPpN4CDfJXJ_PWrjtc/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Communication+Skills/Communicate-Effectively-on-the-Phone-Workbook.pdf'
        }
      ]
    },

    {
      title: 'People Skills',
      units: [
        {
          code: '3.10',
          title: 'Greet Others Professionally Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/People+Skills/Greet-Others-Professionally-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1Ubfsmb48ghsOFqvTCKGJkl4lDJjlv4WX/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/People+Skills/Greet-Others-Professionally-Workbook.pdf'
        },
        {
          code: '3.15',
          title: 'Work Productively in a Team Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/People+Skills/Work-Productively-in-a-Team-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/19uz-XN6G4HKumLSLSbg4DBcV5uWQhP7D/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/People+Skills/Work-Productively-in-a-Team-Workbook.pdf'
        },
        {
          code: '3.20',
          title: 'Manage Relationship with Your Boss Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/People+Skills/Manage-Relationship-with-Your-Boss-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1I-PE46UoCjFm0qvX3xCXvEVv6ewE7Szs/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/People+Skills/Manage-Relationship-with-Your-Boss-Workbook.pdf'
        },
        {
          code: '3.25',
          title: 'Provide Constructive Feedback Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/People+Skills/Provide-Constructive-Feedback-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/12dm60JWn_eaIYS_TwQWtvh2JDSsG5U4_/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/People+Skills/Provide-Constructive-Feedback-Workbook.pdf'
        },
        {
          code: '3.30',
          title: 'Receive Feedback Constructively Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/People+Skills/Receive-Feedback-Constructively-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1ZynYmrMlR0Rye_HZ3lWcIu0gb_NRw37A/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/People+Skills/Receive-Feedback-Constructively-Workbook.pdf'
        },
        {
          code: '3.35',
          title: 'Manage Interpersonal Conflict Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/People+Skills/Manage-Interpersonal-Conflict-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1miEnSzceBTaEqT1X2tVSRa0aghBB9SRG/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/People+Skills/Manage-Interpersonal-Conflict-Workbook.pdf'
        },
        {
          code: '3.40',
          title: 'Negotiate Mutual Solutions Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/People+Skills/Negotiate-Mutual-Solutions-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1hBNZRB_9fNGY8EtmfN5PIu3N010Gm7Dp/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/People+Skills/Negotiate-Mutual-Solutions-Workbook.pdf'
        },
        {
          code: '3.45',
          title: 'Delegate Effectively Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/People+Skills/Delegate-Effectively-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1sqTzOR6D2rPsG_ulMs9oKqIZsit9Y9sC/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/People+Skills/Delegate-Effectively-Workbook.pdf'
        },
        {
          code: '3.50',
          title: 'Lead for Results Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/People+Skills/Lead-for-Results-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1HduoQSZ3jfUFKP6X7WgWIdANWIrTKLem/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/People+Skills/Lead-for-Results-Workbook.pdf'
        }
      ]
    },

    {
      title: 'Analytical Skills',
      units: [
        {
          code: '4.10',
          title: 'Derive Logical Conclusions Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Analytical+Skills/Derive-Logical-Conclusions-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1McaqmR7BA8SXHpcWmXjn9JtazaVTCpUs/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Analytical+Skills/Derive-Logical-Conclusions-Workbook.pdf'
        },
        {
          code: '4.15',
          title: 'Solve Problems Effectively Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Analytical+Skills/Solve-Problems-Effectively-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1hsxMKanNNwBokykc2eZYUHkK4YpgHskJ/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Analytical+Skills/Solve-Problems-Effectively-Workbook.pdf'
        },
        {
          code: '4.20',
          title: 'Make Informed Decisions Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Analytical+Skills/Make-Informed-Decisioins-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1Bcg5OkYl_MB2KXTV_YkxvGIXBRs-VvqS/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Analytical+Skills/Make-Informed-Decisioins-Workbook.pdf'
        },
        {
          code: '4.25',
          title: 'Develop Creative Solutions Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Analytical+Skills/Develop-Creative-Solutions-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/13Dc3UaW1d0LdhqwYBKxypAUJogmc9jyq/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Analytical+Skills/Develop-Creative-Solutions-Workbook.pdf'
        }
      ]
    },

    {
      title: 'Planning Skills',
      units: [
        {
          code: '5.10',
          title: 'Design Feasible Plans Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Planning+Skills/Design-Feasible-Plans-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1K-X5R5Ma7slKxTuRwRP67PxKe-FWp313/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Planning+Skills/Design-Feasible-Plans-Workbook.pdf'
        },
        {
          code: '5.15',
          title: 'Develop Realistic Schedules Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Planning+Skills/Develop-Realistic-Schedules-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/14JclUzztWyXRTxilJq1V6W1m5gqrO-__/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Planning+Skills/Develop-Realistic-Schedules-Workbook.pdf'
        },
        {
          code: '5.20',
          title: 'Allocate Resources Efficiently Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Planning+Skills/Allocate-Resources-Effectively-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1MkrH93M2HzgiKyBCMhj9qctyr9E_z560/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Planning+Skills/Allocate-Resources-Effectively-Workbook.pdf'
        },
        {
          code: '5.25',
          title: 'Manage Projects Effectively Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Planning+Skills/Manage-Projects-Effectively-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1RLUMMVoz4H6RCFewmm-lrjuGKu-tAboz/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Planning+Skills/Manage-Projects-Effectively-Workbook.pdf'
        }
      ]
    },

    {
      title: 'Career Management Skills',
      units: [
        {
          code: '6.10',
          title: 'Create a Career Path Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Career+Management+Skills/Create-a-Career-Path-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1hDdHm-P9VFZlMciHYbdC5J3xOqo1l4fK/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Career+Management+Skills/Create-a-Career-Path-Workbook.pdf'
        },
        {
          code: '6.15',
          title: 'Develop Targeted Resumes Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Career+Management+Skills/Develop-Targeted-Resumes-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1HWnIjok-irdEZHy93p7m2PBLScih3reA/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Career+Management+Skills/Develop-Targeted-Resumes-Workbook.pdf'
        },
        {
          code: '6.20',
          title: 'Develop Targeted Cover Letters Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Career+Management+Skills/Develop-Targeted-Cover-Letters-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1_m8CBTwxyuTRwfrCq-ENNfo0zGSXMqqd/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Career+Management+Skills/Develop-Targeted-Cover-Letters-Workbook.pdf'
        },
        {
          code: '6.25',
          title: 'Develop a Career Portfolio Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Career+Management+Skills/Develop-a-Career-Portfolio-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/19ZVCYJw8YzGurL0muIWv5Xe1o8TPrfu0/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Career+Management+Skills/Develop-a-Career-Portfolio-Workbook.pdf'
        },
        {
          code: '6.30',
          title: 'Develop a Professional Network Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Career+Management+Skills/Develop-a-Professional-Network-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1PgNuP12EHzjuO8CI4loywIM1NSNSJbrC/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Career+Management+Skills/Develop-a-Professional-Network-Workbook.pdf'
        },
        {
          code: '6.35',
          title: 'Manage Your Online Reputation Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Career+Management+Skills/Manage-Your-Online-Reputation-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1FsWxVYb_zsptqA_pmCJT6KCfCwaiMv0w/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Career+Management+Skills/Manage-Your-Online-Reputation-Workbook.pdf'
        },
        {
          code: '6.40',
          title: 'Manage Your Job Search Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Career+Management+Skills/Manage-Your-Job-Search-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1N5bo7YxaidU7ZxqLJJJr1U758BYJhW9K/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Career+Management+Skills/Manage-Your-Job-Search-Workbook.pdf'
        },
        {
          code: '6.45',
          title: 'Excel in Job Interviews Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Career+Management+Skills/Excel-in-Job-Interviews-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1vpYG56mFE_BtG0gXiiglPJEfc6c4lHW8/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Career+Management+Skills/Excel-in-Job-Interviews-Workbook.pdf'
        },
        {
          code: '6.50',
          title: 'Negotiate Job Offers Workbook',
          wordDocumentUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Career+Management+Skills/Negotiate-Job-Offers-Workbook.docx',
          googleDocUrl: 'https://docs.google.com/document/d/1-4mtTWDT4ubOp80QL5CbAXFpXoPglZ1e/edit?usp=share_link&ouid=101712602211613426871&rtpof=true&sd=true',
          pdfUrl: 'https://workbooks.s3-us-west-2.amazonaws.com/Career+Management+Skills/Negotiate-Job-Offers-Workbook.pdf'
        }
      ]
    }
  ],

  skillChallenges: [
    {
      title: 'Self-Management Skills',
      googleDriveUrl: 'https://drive.google.com/drive/folders/1jdJtRntCk5ATZNmg0ssJZZp6jfSaKP78?usp=share_link',
      units: [
        {
          code: '1.10',
          title: 'Develop Self-Awareness',
          files: [
            {
              title: 'Identify Personality Traits',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Develop-Self-Awareness_Skill-Challenge-1_Identify-Your-Dominant-Personality-Traits.docx'
            },

            {
              title: 'Identify Personal Values',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Develop-Self-Awareness_Skill-Challenge-2_Identify-Your-Core-Personal-Values.docx'
            }
          ]
        },
        {
          code: '1.15',
          title: 'Achieve Personal Goals',
          files: [
            {
              title: 'Set a Smart Goal',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Achieve-Personal-Goals_Skill-Challenge-1_Set-a-SMART-Goal.docx',
            },

            {
              title: 'Describe a Goal Achieved',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Achieve-Personal-Goals_Skill-Challenge-2_Describe-a-Goal-You-Achieved.docx'
            }
          ]
        },
        {
          code: '1.20',
          title: 'Manage Time',
          files: [
            {
              title: 'Categorize Tasks on a Covey Quadrant',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Manage-Time_Skill-Challenge-1_Categorize-Tasks-on-a-Covey-Quadrant.docx'
            },

            {
              title: 'Practice Single Tasking',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Manage-Time_Skill-Challenge-2_Practice-Single-Tasking-to-Improve-Focus.docx'
            }
          ]
        },
        {
          code: '1.25',
          title: 'Manage Stress',
          files: [
            {
              title: 'Incorporate Stress Management into Life',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Manage-Stress_Skill-Challenge-1_Incorporate-Stress-Management-into-Your-Life.docx'
            },

            {
              title: 'Make Stress Your Friend',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Manage-Stress_Skill-Challenge-2_Make-Stress-Your-Friend.docx'
            }
          ]
        },
        {
          code: '1.30',
          title: 'Manage Self-Directed Learning',
          files: [
            {
              title: 'Develop Growth Mindset',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Manage-Self-Directed-Learning_Skill-Challenge-1_Demonstrate-a-Growth-Mindset.docx'
            },

            {
              title: 'Develop Personal Learning Plan',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Manage-Self-Directed-Learning_Skill-Challenge-2_Develop-a-Personal-Learning-Plan.docx'
            }
          ]
        },
        {
          code: '1.35',
          title: 'Model Professional Behavior',
          files: [
            {
              title: 'Take a Photo in Professional Dress',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Model-Professional-Behavior_Skill-Challenge-1_Take-a-Photograph-in-Professional-Dress.docx'
            },

            {
              title: 'Provide Examples of Professional Qualities',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Model-Professional-Behavior_Skill-Challenge-2_Provide-Examples-of-Professional-Qualities.docx'
            }
          ]
        },
        {
          code: '1.40',
          title: 'Manage Personal Budget',
          files: [
            {
              title: 'Revise Budget to Increase Savings',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Manage-Personal-Budget_Skill-Challenge-1_Revise-Budget-to-Increase-Savings.docx'
            }
          ]
        },
        {
          code: '1.45',
          title: 'Manage Credit',
          files: [
            {
              title: 'Develop Credit Improvement Strategies',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Manage-Credit_Skill-Challenge-1_Develop-Credit-Improvement-Strategies.docx'
            }
          ]
        },
        {
          code: '1.50',
          title: 'Manage Personal Debt',
          files: [
            {
              title: 'Present Student Loan Repayment Options',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Manage-Personal-Debt_Skill-Challenge-1_Present-Student-Loan-Repayment-Options.docx'
            }
          ]
        },
        {
          code: '1.55',
          title: 'Protect Identity',
          files: [
            {
              title: 'Create Identity Protection Contract',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Protect-Identity_Skill-Challenge-1_Create-an-Identity-Protection-Contract.docx'
            },

            {
              title: 'Analyze Threats in Social Media Profile',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Protect-Identity_Skill-Challenge-2_Analyze-Threats-in-Social-Media-Profile.docx'
            }
          ]
        }
      ]
    },

    {
      title: 'Communication Skills',
      googleDriveUrl: 'https://drive.google.com/drive/folders/1h_PglaY9DU34QzfKQOzgRLJCcZXqdXh7?usp=share_link',
      units: [
        {
          code: '2.10',
          title: 'Speak with Intention',
          files: [
            {
              title: 'Explain Your Greatest Strength',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Speak-with-Intention_Skill-Challenge-1_Explain-Your-Greatest-Strength.docx'
            },

            {
              title: 'Speak Engagingly About Your Profession',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Speak-with-Intention_Skill-Challenge-2_Speak-Engagingly-About-Your-Profession.docx'
            }
          ]
        },
        {
          code: '2.15',
          title: 'Write for Results',
          files: [
            {
              title: 'Craft a Professional Email',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Write-for-Results_Skill-Challenge-1_Craft-a-Professional-Email.docx'
            },

            {
              title: 'Explain Your Interpretation of a Quote',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Write-for-Results_Skill-Challenge-2_Explain-Your-Interpretation-of-a-Quotation.docx'
            }
          ]
        },
        {
          code: '2.20',
          title: 'Listen Actively',
          files: [
            {
              title: 'Self Assess Your Active Listening Skills',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Listen-Actively_Skill-Challenge-1_Self-Assess-Your-Active-Listening-Skills.docx'
            },

            {
              title: 'Describe an Empathetic Listening Experience',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Listen-Actively_Skill-Challenge-2_Describe-an-Empathetic-Listening-Experience.docx'
            }
          ]
        },
        {
          code: '2.25',
          title: 'Interpret Nonverbal Communication',
          files: [
            {
              title: 'Research Multicultural Nonverbal Comm.',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Interpret-Nonverbal-Communication_Skill-Challenge-1_Research-and-Share-Multicultural-Nonverbal-Communication.docx'
            },

            {
              title: 'Analyze Nonverbal Comm.',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Interpret-Nonverbal-Communication_Skill-Challenge-2_Analyze-Nonverbal-Communication-in-a-Speech.docx'
            }
          ]
        },
        {
          code: '2.30',
          title: 'Present for Results',
          files: [
            {
              title: 'Create a Self-Presentation Video',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Present-for-Results_Skill-Challenge-1_Create-a-Self-Presentation-Video.docx'
            },

            {
              title: 'Teach Something to Others',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Present-for-Results_Skill-Challenge-2_Teach-Something-to-Others.docx'
            }
          ]
        },
        {
          code: '2.35',
          title: 'Conduct Effective Meetings',
          files: [
            {
              title: 'Prepare a Meeting Agenda',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Conduct-Effective-Meetings_Skill-Challenge-1_Prepare-a-Meeting-Agenda.docx'
            },

            {
              title: 'Facilitate a Meeting & Gather Feedback',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Conduct-Effective-Meetings_Skill-Challenge-2_Facilitate-a-Meeting-and-Gather-Feedback.docx'
            }
          ]
        },
        {
          code: '2.40',
          title: 'Communicate Effectively on the Phone',
          files: [
            {
              title: 'Develop a Phone Etiquette Tip Sheet',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Communicate-Effectively-on-the-Phone_Skill-Challenge-1_Develop-a-Phone-Etiquette-Tip-Sheet.docx'
            },

            {
              title: 'Write a Phone Script',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Communicate-Effectively-on-the-Phone_Skill-Challenge-2_Write-a-Phone-Script.docx'
            }
          ]
        }
      ]
    },

    {
      title: 'People Skills',
      googleDriveUrl: 'https://drive.google.com/drive/folders/1V5KMErA3j888NzlIPQqy7NwSqws6CHtf?usp=share_link',
      units: [
        {
          code: '3.10',
          title: 'Greet Others Professionally',
          files: [
            {
              title: 'Make a Professional Intro',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Greet-Others-Professionally_Skill-Challenge-1_Make-a-Professional-Introduction.docx'
            },

            {
              title: 'Show a Culturally Diverse Professional Greeting',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Greet-Others-Professionally_Skill-Challenge-2_Show-a-Culturally-Diverse-Professional-Greeting.docx'
            }
          ]
        },
        {
          code: '3.15',
          title: 'Work Productively in a Team',
          files: [
            {
              title: 'Share a Group Project Accomplishment',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Work-Productively-in-a-Team_Skill-Challenge-1_Share-a-Group-Project-Accomplishment.docx'
            },

            {
              title: 'Complete a Team Effectiveness Evaluation',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Work-Productively-in-a-Team_Skill-Challenge-2_Complete-Team-Effectiveness-Evaluation.docx'
            }
          ]
        },
        {
          code: '3.20',
          title: 'Manage Relationship with Your Boss',
          files: [
            {
              title: 'Get a Testimonial',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Manage-Relationship-with-Your-Boss_Skill-Challenge-1_Obtain-a-Testimonial-from-Your-Boss.docx'
            },

            {
              title: 'Describe Examples of Working Well with Boss',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Manage-Relationship-with-Your-Boss_Skill-Challenge-2_Describe-Examples-of-Working-Well-with-Boss.docx'
            }
          ]
        },
        {
          code: '3.25',
          title: 'Provide Constructive Feedback',
          files: [
            {
              title: 'Revise Poorly Written Feedback',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Provide-Constructive-Feedback_Skill-Challenge-1_Revise-Poorly-Written-Feedback.docx'
            },

            {
              title: 'Give Feedback on a Presentation',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Provide-Constructive-Feedback_Skill-Challenge-2_Give-Feedback-on-a-Presentation.docx'
            }
          ]
        },
        {
          code: '3.30',
          title: 'Receive Feedback Constructively',
          files: [
            {
              title: 'Reflect on Feedback to Improve',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Receive-Feedback-Constructively_Skill-Challenge-1_Reflect-on-Feedback-to-Improve.docx'
            },

            {
              title: 'Ask for Feedback and A.C.T.',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Receive-Feedback-Constructively_Skill-Challenge-2_Ask-for-Feedback-and-A.C.T.docx'
            }
          ]
        },
        {
          code: '3.35',
          title: 'Manage Interpersonal Conflict',
          files: [
            {
              title: 'Assess Your Conflict Management Style',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Manage-Interpersonal-Conflict_Skill-Challenge-1_Assess-Your-Conflict-Management-Style.docx'
            },

            {
              title: 'Share an Effective Example',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Manage-Interpersonal-Conflict_Skill-Challenge-2_Share-an-Effective-Conflict-Management-Example.docx'
            }
          ]
        },
        {
          code: '3.40',
          title: 'Negotiate Mutual Solutions',
          files: [
            {
              title: 'Prepare for a Future Negotiation',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Negotiate-Mutual-Solutions_Skill-Challenge-1_Prepare-for-a-Future-Negotiation.docx'
            },

            {
              title: 'Analyze a Past Negotiation for Win Win',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Negotiate-Mutual-Solutions_Skill-Challenge-2_Analyze-a-Past-Negotiation-for-Win-Win.docx'
            }
          ]
        },
        {
          code: '3.45',
          title: 'Delegate Effectively',
          files: [
            {
              title: 'Prepare a Delegation Task Analysis',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Delegate-Effectively_Skill-Challenge-1_Prepare-a-Delegation-Task-Analysis.docx'
            },

            {
              title: 'Solve a Delegation Problem',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Delegate-Effectively_Skill-Challenge-2_Solve-a-Delegation-Problem.docx'
            }
          ]
        },
        {
          code: '3.50',
          title: 'Lead for Results',
          files: [
            {
              title: 'Develop Your Professional Vision',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Lead-for-Results_Skill-Challenge-1_Develop-Your-Professional-Vision.docx'
            },

            {
              title: 'Assess Your Leadership Style',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Lead-for-Results_Skill-Challenge-2_Assess-Your-Leadership-Style.docx'
            }
          ]
        }
      ]
    },

    {
      title: 'Analytical Skills',
      googleDriveUrl: 'https://drive.google.com/drive/folders/1-fjLBSFyZONHr7Ax_OKpEBonhar0to3U?usp=share_link',
      units: [
        {
          code: '4.10',
          title: 'Derive Logical Conclusions',
          files: [
            {
              title: 'Identify Logical Fallacies',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Derive-Logical-Conclusions_Skill-Challenge-1_Identify-Logical-Fallacies.docx'
            },

            {
              title: 'Develop a Sound Argument',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Derive-Logical-Conclusions_Skill-Challenge-2_Develop-a-Sound-Argument.docx'
            }
          ]
        },
        {
          code: '4.15',
          title: 'Solve Problems Effectively',
          files: [
            {
              title: 'Describe How You Solved a Problem',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Solve-Problems-Effectively_Skill-Challenge-1_Describe-How-You-Solved-a-Problem.docx'
            },

            {
              title: 'Propose a Solution to an Industry Problem',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Solve-Problems-Effectively_Skill-Challenge-2_Propose-a-Solution-to-an-Industry-Problem.docx'
            }
          ]
        },
        {
          code: '4.20',
          title: 'Make Informed Decisions',
          files: [
            {
              title: 'Demonstrate Your Decision Making Process',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Make-Informed-Decisions_Skill-Challenge-1_Demonstrate-Your-Decision-Making-Process.docx'
            },

            {
              title: 'Evaluate Alternative Options',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Make-Informed-Decisions_Skill-Challenge-2_Evaluate-Alternative-Options.docx'
            }
          ]
        },
        {
          code: '4.25',
          title: 'Develop Creative Solutions',
          files: [
            {
              title: 'Generate Creative Ideas',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Develop-Creative-Solutions_Skill-Challenge-1_Generate-Creative-Ideas.docx'
            },

            {
              title: 'Take the Paper Clip Challenge',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Develop-Creative-Solutions_Skill-Challenge-2_Take-the-Paper-Clip-Challenge.docx'
            }
          ]
        }
      ]
    },

    {
      title: 'Planning Skills',
      googleDriveUrl: 'https://drive.google.com/drive/folders/1FQG3qCKWNQCOQgdUDQJtpnPiPidb0g8C?usp=share_link',
      units: [
        {
          code: '5.10',
          title: 'Design Feasible Plans',
          files: [
            {
              title: 'Break Down Big Project Into Bites',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Design-Feasible-Plans_Skill-Challenge-1_Break-Down-Big-Project-Into-Bites.docx'
            },

            {
              title: 'Create a Contingency Plan',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Design-Feasible-Plans_Skill-Challenge-2_Create-a-Contingency-Plan.docx'
            }
          ]
        },
        {
          code: '5.15',
          title: 'Develop Realistic Schedules',
          files: [
            {
              title: 'Analyze Your Schedule and Priorities',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Develop-Realistic-Schedules_Skill-Challenge-1_Analyze-Your-Schedule-and-Priorities.docx'
            },

            {
              title: 'Reduce Procrastination',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Develop-Realistic-Schedules_Skill-Challenge-2_Reduce-Procrastination.docx'
            }
          ]
        },
        {
          code: '5.20',
          title: 'Allocate Resources Efficiently',
          files: [
            {
              title: 'Allocate Resources for a Community Project',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Allocate-Resources-Efficiently_Skill-Challenge-1_Allocate-Resources-for-a-Community-Project.docx'
            },

            {
              title: 'Reflect on Project Resource Allocation',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Allocate-Resources-Efficiently_Skill-Challenge-2_Reflect-on-Project-Resource-Allocation.docx'
            }
          ]
        },
        {
          code: '5.25',
          title: 'Manage Projects Effectively',
          files: [
            {
              title: 'Develop a Project Charter',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Manage-Projects-Effectively_Skill-Challenge-1_Develop-a-Project-Charter.docx'
            },

            {
              title: 'Create a Work Breakdown Structure',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Manage-Projects-Effectively_Skill-Challenge-2_Create-a-Work-Breakdown-Structure.docx'
            }
          ]
        }
      ]
    },

    {
      title: 'Career Management Skills',
      googleDriveUrl: 'https://drive.google.com/drive/folders/1esC8Y2maCWRRvMF6nAc-NjjdG9631dqn?usp=share_link',
      units: [
        {
          code: '6.10',
          title: 'Create a Career Path',
          files: [
            {
              title: 'Conduct a 360 Assessment',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Create-a-Career-Path_Skill-Challenge-1_Conduct-a-360-Assessment.docx'
            },

            {
              title: 'Develop a Career Path Roadmap',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Create-a-Career-Path_Skill-Challenge-2_Develop-a-Career-Path-Roadmap.docx'
            }
          ]
        },
        {
          code: '6.15',
          title: 'Develop Targeted Resumes',
          files: [
            {
              title: 'Write a Targeted Resume',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Develop-Targeted-Resumes_Skill-Challenge-1_Write-a-Targeted-Resume.docx'
            }
          ]
        },
        {
          code: '6.20',
          title: 'Develop Targeted Cover Letters',
          files: [
            {
              title: 'Write a Targeted Cover Letter',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Develop-Targeted-Cover-Letters_Skill-Challenge-1_Write-a-Targeted-Cover-Letter.docx'
            }
          ]
        },
        {
          code: '6.25',
          title: 'Develop a Career Portfolio',
          files: [
            {
              title: 'Build Your Career Portfolio',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Develop-a-Career-Portfolio_Skill-Challenge-1_Build-Your-Career-Portfolio.docx'
            }
          ]
        },
        {
          code: '6.30',
          title: 'Develop a Professional Network',
          files: [
            {
              title: 'Create a Personal Branding Statement',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Develop-a-Professional-Network_Skill-Challenge-1_Create-a-Personal-Branding-Statement.docx'
            },

            {
              title: 'Develop a List of Contacts',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Develop-a-Professional-Network_Skill-Challenge-2_Develop-a-List-of-Contacts.docx'
            }
          ]
        },
        {
          code: '6.35',
          title: 'Manage Your Online Reputation',
          files: [
            {
              title: 'Create Your Career Site',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Manage-Your-Online-Reputation_Skill-Challenge-1_Create-Your-Career-Site.docx'
            },

            {
              title: 'Diagnose Your Current Online Presence',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Manage-Your-Online-Reputation_Skill-Challenge-2_Diagnose-Your-Current-Online-Presence.docx'
            }
          ]
        },
        {
          code: '6.40',
          title: 'Manage Your Job Search',
          files: [
            {
              title: 'Develop a Job Leads List',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Manage-Your-Job-Search_Skill-Challenge-1_Develop-a-Job-Leads-List.docx'
            },

            {
              title: 'Conduct an Informational Interview',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Manage-Your-Job-Search_Skill-Challenge-2_Conduct-an-Informational-Interview.docx'
            }
          ]
        },
        {
          code: '6.45',
          title: 'Excel in Job Interviews',
          files: [
            {
              title: 'Prepare for a Job Interview',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Excel-in-Job-Interviews_Skill-Challenge-1_Prepare-for-a-Job-Interview.docx'
            },

            {
              title: 'Record Video of Interview Answer',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Excel-in-Job-Interviews_Skill-Challenge-2_Record-Video-of-Interview-Answer.docx'
            }
          ]
        },
        {
          code: '6.50',
          title: 'Negotiate Job Offers',
          files: [
            {
              title: 'Research Job Salary Ranges',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Negotiate-Job-Offers_Skill-Challenge-1_Research-Job-Salary-Ranges.docx'
            },

            {
              title: 'Evaluate a Job Offer',
              url: 'https://skill-challenges.s3-us-west-2.amazonaws.com/Negotiate-Job-Offers_Skill-Challenge-2_Evaluate-a-Job-Offer.docx'
            }
          ]
        }
      ]
    }
  ],

  helpArticles: [
    {
      title: 'Frequently Asked Admin Questions',
      articles: [
        {
          title: 'Can I see a demo of CareerPrepped?',
          link: 'https://institutions.careerprepped.com/help/can-i-see-a-demo-of-careerprepped'
        },
        {
          title: 'How do I create groups for my account?',
          link: 'https://institutions.careerprepped.com/help/how-do-i-setup-groups-for-my-account'
        },
        {
          title: 'How do I invite students to my group(s)?',
          link: 'https://institutions.careerprepped.com/help/how-to-invite-students-to-my-group'
        },
        {
          title: 'What are some assignment ideas I can use in my classroom?',
          link: 'https://institutions.careerprepped.com/help/what-are-some-assignment-ideas-i-can-use-in-my-classroom-if-i-m-an-instructor'
        },
        {
          title: 'What are strategies to implement CareerPrepped?',
          link: 'https://institutions.careerprepped.com/help/careerprepped-implementation-strategies'
        }
      ]
    },

    {
      title: 'Using Core Features of CareerPrepped',
      articles: [
        {
          title: 'Creating Your Career Site',
          link: 'https://help.careerprepped.com/help/creating-your-career-site'
        },
        {
          title: 'Privacy settings for your Career Site',
          link: 'https://help.careerprepped.com/help/how-do-i-change-the-privacy-settings-of-my-career-site'
        },
        {
          title: 'What is a Skill Builder?',
          link: 'https://help.careerprepped.com/help/what-is-a-skill-builder'
        },
        {
          title: 'How to save and download reflections from a Skill Builder',
          link: 'https://help.careerprepped.com/help/how-do-i-save-and-download-reflections-from-a-skill-builder'
        },
        {
          title: 'How do I get Skill Badges?',
          link: 'https://help.careerprepped.com/help/how-do-i-get-a-skill-badge'
        },
        {
          title: 'How do I get Skill Badge endorsements?',
          link: 'https://help.careerprepped.com/help/getting-skill-badge-endorsements'
        },
        {
          title: 'How to write effective answers to questions when claiming Skill Badges',
          link: 'https://help.careerprepped.com/help/how-can-i-write-effective-answers-to-the-questions-when-claiming-my-skill-badge'
        },
        {
          title: 'What is the Career Toolbox?',
          link: 'https://help.careerprepped.com/help/what-is-the-careerprepped-toolbox'
        },
        {
          title: 'What is a Career Portfolio?',
          link: 'https://help.careerprepped.com/help/what-is-a-career-portfolio'
        },
        {
          title: 'Does CareerPrepped provide a job board?',
          link: 'https://help.careerprepped.com/help/does-careerprepped-provide-a-job-board'
        }
      ]
    },

    {
      title: 'Marketing Resources',
      articles: [
        {
          title: 'Customizable 8 X 10 posters to market CareerPrepped',
          link: 'https://institutions.careerprepped.com/help/careerprepped-posters'
        },
        {
          title: 'Shareable infographics that inform students of the importance of "soft skills"',
          link: 'https://institutions.careerprepped.com/help/educational-infographics'
        },
        {
          title: 'Customizable brand awareness graphics to promote CareerPrepped',
          link: 'https://institutions.careerprepped.com/help/brand-awareness-graphics'
        }
      ]
    },

    {
      title: 'Resources to Help Students Understand the Importance of "Soft Skills"',
      articles: [
        {
          title: 'Compiled research reports',
          link: 'https://www.careerprepped.com/research'
        },
        {
          title: '"Soft skills" and the skills gap (Videos)',
          link: 'https://institutions.careerprepped.com/help/soft-skills-and-the-skills-gap'
        }
      ]
    },

    {
      title: 'Resources to Better Understand the Future of Work and Rising Importance of "Soft Skills"',
      articles: [
        {
          title: 'The Future of Work / 4th Industrial Revolution (Videos)',
          link: 'https://institutions.careerprepped.com/help/the-future-of-work-4th-industrial-revolution'
        },
        {
          title: '21st Century Career White Paper',
          link: 'https://media.maxknowledge.com/careerprepped/CareerPrepped-Whitepaper-The-21st-Century-Career-Why-Perpetual-Career-Preparation-is-Necessary-to-Survive-and-Thrive-in-Todays-Economy.pdf'
        }
      ]
    }
  ]
};
