import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

const DeleteSectionItemConfirmModal = ({ onCancel, onConfirm, isOpen}) => {
  return (
    <Modal
      size="lg"
      className="modal-margin-top"
      backdrop="static"
      isOpen={isOpen}
      toggle={onCancel}>
      <ModalHeader
        toggle={onCancel}>Delete</ModalHeader>
      <ModalBody>
        <p>
          Are you sure you want to delete this from your resume?
        </p>
      </ModalBody>
      <ModalFooter>
        <button
          onClick={onCancel}
          className="btn btn-secondary">
          Cancel
        </button>

        <button
          onClick={onConfirm}
          className="btn btn-danger">
          Delete
        </button>
      </ModalFooter>
    </Modal>
  );
};

DeleteSectionItemConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default DeleteSectionItemConfirmModal;
