import { CALL_API } from '../../middlewares/api';

import {
  CLUSTERS_REQUEST,
  CLUSTERS_SUCCESS,
  CLUSTERS_FAILURE
} from '../../constants';

export const getCareerClusters = (page = 1, query = '') => {
  let endpoint = `user/career-clusters?page=${page}`;

  if (query.length > 0) {
    endpoint = `${endpoint}&query=${query}`;
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        CLUSTERS_REQUEST,
        CLUSTERS_SUCCESS,
        CLUSTERS_FAILURE
      ]
    }
  };
};
