import { CALL_API } from '../../middlewares/api';

import {
  SHARE_WALLPOST,
  RESET_SHARE_WALLPOST,
  RESET_DASHBOARD_WALL,
  INCREMENT_WALL_COMMENT_COUNT,
  DECREMENT_WALL_COMMENT_COUNT,
  WALL_POSTS_REQUEST,
  WALL_POSTS_SUCCESS,
  WALL_POSTS_FAILURE,
  WALL_POST_REQUEST,
  WALL_POST_SUCCESS,
  WALL_POST_FAILURE,
  CREATE_WALL_POST_REQUEST,
  CREATE_WALL_POST_SUCCESS,
  CREATE_WALL_POST_FAILURE,
  CREATE_WALL_POST_LIKE_REQUEST,
  CREATE_WALL_POST_LIKE_SUCCESS,
  CREATE_WALL_POST_LIKE_FAILURE,
  WALL_POST_UNLIKE_REQUEST,
  WALL_POST_UNLIKE_SUCCESS,
  WALL_POST_UNLIKE_FAILURE,
  UPDATE_WALL_POST_REQUEST,
  UPDATE_WALL_POST_SUCCESS,
  UPDATE_WALL_POST_FAILURE,
  DELETE_WALL_POST_REQUEST,
  DELETE_WALL_POST_SUCCESS,
  DELETE_WALL_POST_FAILURE,
} from '../../constants';

export const resetWall = () => {
  return { type: RESET_DASHBOARD_WALL };
};

export const shareWallpost = (wallpost) => {
  return dispatch => dispatch({ type: SHARE_WALLPOST, wallpost });
};

export const resetShareWallPost = () => {
  return dispatch => dispatch({ type: RESET_SHARE_WALLPOST, wallpost: {} });
};

export const incrementWallCommentCount = (id, typeOfComment) => {
  return dispatch =>
    dispatch({
      type: INCREMENT_WALL_COMMENT_COUNT,
      id,
      typeOfComment,
    });
};

export const decrementWallCommentCount = (id, count, typeOfComment) => {
  return dispatch =>
    dispatch({
      type: DECREMENT_WALL_COMMENT_COUNT,
      id,
      count,
      typeOfComment,
    });
};

export const wallPostRequest = (postId) => {
  const endpoint = `discussion/wall/${postId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [WALL_POST_REQUEST, WALL_POST_SUCCESS, WALL_POST_FAILURE],
    },
  };
};

export const wallPostsRequest = (
  userId,
  pageNo = 1,
  sortBy,
  activityFilter
) => {
  const endpoint = `discussion/wall?user=${userId}&page=${pageNo}&type=${activityFilter}&sort=${sortBy}&comment=1`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [WALL_POSTS_REQUEST, WALL_POSTS_SUCCESS, WALL_POSTS_FAILURE],
    },
  };
};

export const saveWallPost = (data) => {
  const endpoint = 'discussion/wall';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        CREATE_WALL_POST_REQUEST,
        CREATE_WALL_POST_SUCCESS,
        CREATE_WALL_POST_FAILURE,
      ],
    },
  };
};

export const saveWallPostLike = (data) => {
  const endpoint = 'discussion/wall_like';

  return {
    [CALL_API]: {
      endpoint,
      data: Object.assign(
        {},
        {
          wall: data.wall,
        }
      ),
      method: 'post',
      authenticated: true,
      index: data.wallPostIndex,
      types: [
        CREATE_WALL_POST_LIKE_REQUEST,
        CREATE_WALL_POST_LIKE_SUCCESS,
        CREATE_WALL_POST_LIKE_FAILURE,
      ],
    },
  };
};

export const unlikeWallPost = (data) => {
  const { wall, userId, wallPostIndex } = data;
  const endpoint = `discussion/wall_like/${wall}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'delete',
      authenticated: true,
      userId,
      index: wallPostIndex,
      types: [
        WALL_POST_UNLIKE_REQUEST,
        WALL_POST_UNLIKE_SUCCESS,
        WALL_POST_UNLIKE_FAILURE,
      ],
    },
  };
};

export const updateWallPost = (data, id) => {
  const endpoint = `discussion/wall/${id}`;

  return {
    [CALL_API]: {
      data,
      id,
      endpoint,
      method: 'patch',
      authenticated: true,
      types: [
        UPDATE_WALL_POST_REQUEST,
        UPDATE_WALL_POST_SUCCESS,
        UPDATE_WALL_POST_FAILURE,
      ],
    },
  };
};

export const deleteWallPost = (wallPostId) => {
  const endpoint = `discussion/wall/${wallPostId}`;

  return {
    [CALL_API]: {
      endpoint,
      id: wallPostId,
      method: 'delete',
      authenticated: true,
      types: [
        DELETE_WALL_POST_REQUEST,
        DELETE_WALL_POST_SUCCESS,
        DELETE_WALL_POST_FAILURE,
      ],
    },
  };
};
