import PropTypes from 'prop-types';
import React from 'react';
import { uniqueId } from '../../../utils';
import ProjectCardHandler from '../../../containers/profile/sections/ProjectCardHandler';

const ProjectList = ({projects, isPublicProfile, onEdit}) => {

  const sortProject = (projects) => {
    const newProjectList = [].concat(projects);
    return newProjectList.sort(function(a, b){
      return new Date(b.enddate.date) - new Date(a.enddate.date);
    });
  };

  const projectSortedList = sortProject(projects);

  if(projects.length === 0){
    return(
      <div className="empty-section-message">
        <p>Let people know about your projects that you care about like a website you built, a program you coded, a fundraiser, or a volunteer project.</p>
      </div>
    );
  }

  return (
    <div>
      {projectSortedList.map(project =>
        (<ProjectCardHandler
          key={uniqueId()}
          project={project}
          onEdit={onEdit}
          isPublicProfile={isPublicProfile}/>))}
    </div>
  );
};

ProjectList.propTypes = {
  projects: PropTypes.array.isRequired,
  isPublicProfile: PropTypes.bool.isRequired,
  onEdit: PropTypes.func
};

export default ProjectList;
