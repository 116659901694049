import React from 'react';

import PropTypes from 'prop-types';


import ManageIRHeader from './table-columns/ManageIRHeader';
import ManageIRRowComponent from './ManageIRRowComponent';
import PaginationHandler from '../common/PaginationHandler';
import { isEmpty } from 'lodash';
import Loading from '../common/Loading';

function ManageIRTableComponent(props){

  const getHeaderData = {
    manageIR : <ManageIRHeader onSortTable={props.onSortTable}/>, 
  };

  const Map = {
    'manageIR': ManageIRRowComponent
  };

  const Tagname = Map[props.status];

  return(
    <div className="table-responsive" style={{ position: 'relative' }}>
      <table className="cp-table mt-1 table">
        <thead>
          <tr className="cp-table-header">
            {getHeaderData[props.status]}
          </tr>  
        </thead>
        <tbody>
          {(props.isSearching) ? <tr><td colSpan="100%" className="w-100 text-center py-5"><Loading /></td></tr> :
            isEmpty(props.tblData) ? 
              !isEmpty(props.searchText) ? (<tr>
                <td colSpan="100%" className="w-100 text-center py-5">
                  <p className="mb-0">Sorry, we couldn't find anything!</p>
                </td>
              </tr>) : (<tr>
                <td colSpan="100%" className="w-100 text-center py-5">
                  <p className="mb-0">No industry mentors have been added yet</p>
                </td>
              </tr>) : (
                <Tagname {...props}/>
              )
          }
        </tbody>  
        <tfoot>
          <tr>
            <th style={{ textAlign: 'center' }} colSpan={10}>
              <PaginationHandler
                page={props.page}
                page_count={props.pageCount}
                onNext={props.onNext}
                onPrev={props.onPrev}
                onSelectPage={props.onSelectPage}/>
            </th>
          </tr>
        </tfoot>    
      </table>
    </div>
  ); 
}

ManageIRTableComponent.propTypes = {
  status: PropTypes.string.isRequired,
  onSortTable: PropTypes.func.isRequired,
  page: PropTypes.number,
  pageCount: PropTypes.number,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  onSelectPage: PropTypes.func,
  tblData: PropTypes.array,
  isSearching: PropTypes.bool,
  searchText: PropTypes.string
};

export default ManageIRTableComponent;
