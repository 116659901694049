import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'reactstrap';

const facilitateIcon = require('../../assets/images/facilitate.png');

const RealisticEndorsementModal = ({showRealisticEndorsementsModal, toggleRealisticEndorsementsModal}) => {
  return (
    <Modal className="custom-md-modal" isOpen={showRealisticEndorsementsModal} toggle={toggleRealisticEndorsementsModal} size={'md'}>
      <div className="modal1" id="portfolioFiles">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myModalLabel">Realistic Endorsements</h4>
            <button type="button" className="close" onClick={toggleRealisticEndorsementsModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="text-center modal-body">
            <img className="p-3 img-fluid img-responsive" src={facilitateIcon} width="350px" style={{marginTop: -15, marginBottom: -10}}/>
            <p style={{fontSize:14}}>Endorsers must attest that they’ve observed an individual’s claimed abilities in a training activity, project, or work setting.</p>
            <p style={{fontSize:14}}>This gives every user the opportunity to demonstrate their soft skills and gain endorsements from a variety of experiences - not just employment experience which early professionals often lack.</p>
            <p style={{fontSize:14}}>Endorsers must indicate their name, position, and organization.</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

RealisticEndorsementModal.propTypes = {
  showRealisticEndorsementsModal: PropTypes.bool,
  toggleRealisticEndorsementsModal: PropTypes.func
};

export default RealisticEndorsementModal;
