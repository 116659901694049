import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const MainMenuMobile = ({ profile, pathname, onToggleMobileMenu }) => {
  return (
    <div className="main-navigation-mobile-menu toggled">
      <div className="main-navigation-mobile-menu__container">
        <div
          className="main-navigation-mobile-menu__close"
          onClick={onToggleMobileMenu}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="black"/>
          </svg>
        </div>
        <Link
          to="/home"
          onClick={onToggleMobileMenu}
          className={classNames(
            'main-navigation-link',
            { active: pathname === '/home' }
          )}>
          <div className="main-navigation-item">
            <div className="title">
              Home
            </div>
          </div>
        </Link>
        <Link
          to={`/cp/${profile.vanity}`}
          onClick={onToggleMobileMenu}
          className={classNames(
            'main-navigation-link',
            { active: pathname === `/cp/${profile.vanity}` }
          )}>
          <div className="main-navigation-item">
            <div className="title">Career Site</div>
          </div>
        </Link>
        <Link
          to="/skill-builders"
          onClick={onToggleMobileMenu}
          className={classNames(
            'main-navigation-link',
            { active: pathname.includes('/skill-builders') }
          )}>
          <div className="main-navigation-item">
            <div className="title">Skill Builders</div>
          </div>
        </Link>
        <Link
          to="/myskills"
          onClick={onToggleMobileMenu}
          className={classNames(
            'main-navigation-link',
            { active: pathname === '/myskills' }
          )}>
          <div className="main-navigation-item">
            <div className="title">Claimed Skills</div>
          </div>
        </Link>
        <Link
          to="/portfolio"
          onClick={onToggleMobileMenu}
          className={classNames(
            'main-navigation-link',
            { active: pathname === '/portfolio' }
          )}>
          <div className="main-navigation-item">
            <div className="title">Portfolio</div>
          </div>
        </Link>
        <Link
          to="/myfeedback"
          onClick={onToggleMobileMenu}
          className={classNames(
            'main-navigation-link',
            { active: pathname === '/myfeedback' }
          )}>
          <div className="main-navigation-item">
            <div className="title">Feedback</div>
          </div>
        </Link>
        <Link
          to="/job-center"
          onClick={onToggleMobileMenu}
          className={classNames(
            'main-navigation-link',
            { active: pathname === '/job-center' }
          )}>
          <div className="main-navigation-item">
            <div className="title">Jobs</div>
          </div>
        </Link>
        <Link
          to="/community"
          onClick={onToggleMobileMenu}
          className={classNames(
            'main-navigation-link',
            { active: pathname === '/community' }
          )}>
          <div className="main-navigation-item">
            <div className="title">Community</div>
          </div>
        </Link>
        <Link
          to="/resources"
          onClick={onToggleMobileMenu}
          className={classNames(
            'main-navigation-link',
            { active: pathname === '/resources' }
          )}>
          <div className="main-navigation-item">
            <div className="title">Resources</div>
          </div>
        </Link>

        <Link
          to="/messages"
          onClick={onToggleMobileMenu}
          className={classNames(
            'main-navigation-link',
            { active: pathname.includes('/messages') }
          )}>
          <div className="main-navigation-item">
            <div className="title">Messages</div>
          </div>
        </Link>
      </div>
    </div>
  );
};

MainMenuMobile.propTypes = {
  profile: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  onToggleMobileMenu: PropTypes.func.isRequired
};

export default MainMenuMobile;
