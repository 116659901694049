import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { omit, isEmpty } from 'lodash';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import * as routerActions from '../../../redux/actions/router';
import * as profileActions from '../../../redux/actions/profile';
import {
  updateMyCareerPreppedStatus,
  getMyCareerPreppedStatus
} from '../../../redux/actions/my-careerprepped-status';
import { getCountries } from '../../../redux/actions/countries';
import { getStates } from '../../../redux/actions/states';
import { getCities } from '../../../redux/actions/cities';

import {
  selectFormattedCountries,
  selectCountriesPageCount
} from '../../../redux/selectors/countries';
import {
  selectFormattedStates,
  selectStatesPageCount
} from '../../../redux/selectors/states';
import {
  selectFormattedCities,
  selectCitiesPageCount
} from '../../../redux/selectors/cities';

import Validator from '../../../validator';
import BasicInfoForm from '../../../components/profile/forms/BasicInfoForm';
import formWithModal from '../../../components/profile/modal/formWithModal';
import { toastr } from 'react-redux-toastr';

class BasicEditModal extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    info: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    fieldsToShow: PropTypes.string,
    modalTitle: PropTypes.string,
    cities: PropTypes.array.isRequired,
    states: PropTypes.array.isRequired,
    countries: PropTypes.array.isRequired,
    citiesPageCount: PropTypes.number.isRequired,
    statesPageCount: PropTypes.number.isRequired,
    countriesPageCount: PropTypes.number.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      info: Object.assign({}, this.props.info)
    };

    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.loadOptions = this.loadOptions.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);
  }

  componentWillReceiveProps(nextPops){
    const { profile: nextProfile, isUpdating } = nextPops;
    const { profile, actions } = this.props;

    if(nextProfile !== profile && !isUpdating){
      if(nextProfile.vanity !== profile.vanity){
        actions.redirect(`/cp/${nextProfile.vanity}`);
      }
      this.props.onCancel();
    }
  }

  isValid(field = null) {
    let rules = {
      firstname: ['required', 'maxLength|60'],
      lastname: ['required', 'maxLength|60'],
      headline: ['minLength|6', 'maxLength|65'],
      vanity: ['required'],
      city: [],
      state: [],
      country: []
    };

    if (this.props.fieldsToShow === 'headline') {
      rules.headline.push('required');
    }

    if (this.props.fieldsToShow === 'location') {
      rules.city.push('required');
      rules.state.push('required');
      rules.country.push('required');
    }

    const { info, errors: prevErrors} = this.state;
    const validate = Validator.createValidator(rules, info, field);
    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  onChange(event) {
    const { name, value } = event.target;
    const { info } = this.state;

    info[name] = value;

    this.setState({ info }, () => this.isValid(name));
  }

  onChangeLocation(value, actionMeta) {
    const { name } = actionMeta;
    const { info } = this.state;

    info[name] = value;

    this.setState({ info }, () => this.isValid(name));
  }

  async loadOptions(search, loadedOptions, actionMeta) {
    const { page, name: inputName } = actionMeta;

    if (inputName === 'country') {
      await this.props.actions.getCountries(page, search);
    }

    if (inputName === 'state' && this.state.info.country.value) {
      await this.props.actions.getStates(this.state.info.country.value, page, search);
    }

    if (inputName === 'city' && this.state.info.state.value) {
      await this.props.actions.getCities(this.state.info.state.value, page, search);
    }

    const options = (inputName === 'country') ?
      this.props.countries : (inputName === 'state') ?
        this.props.states : this.props.cities;

    const pageCount = (inputName === 'country') ?
      this.props.countriesPageCount : (inputName === 'state') ?
        this.props.statesPageCount : this.props.citiesPageCount;

    return {
      options,
      hasMore: pageCount > page,
      additional: { page: page + 1, name: inputName }
    };
  }

  onSave(event){
    event.preventDefault();

    const { profile: { id: profileId }, actions, fieldsToShow } = this.props;
    const { info } = this.state;

    if (!this.isValid()) {
      return;
    }

    const name = `${info.firstname.trim()} ${info.lastname.trim()}`;
    let infoData = omit(info, ['firstname', 'lastname', 'vanity', 'country', 'state']);

    infoData = {
      ...infoData,
      name,
      ...((infoData.city) && { city: infoData.city.value })
    };

    actions.updateProfile(profileId, infoData).then(async () => {
      (fieldsToShow === 'headline') && toastr.success('Headline added successfully');
      (fieldsToShow === 'location') && toastr.success('Location added successfully');
      await actions.updateMyCareerPreppedStatus();
      actions.getMyCareerPreppedStatus();
    });
  }

  EditingBasicInfo = (this.props.fieldsToShow === 'headline' || this.props.fieldsToShow === undefined) ? formWithModal(BasicInfoForm) : BasicInfoForm;


  render() {
    return (
      <Modal
        size="lg"
        backdrop="static"
        className="modal-margin-top"
        isOpen={this.props.isOpen}
        toggle={this.props.onCancel}>
        <ModalHeader toggle={this.props.onCancel}>{(this.props.modalTitle) ? this.props.modalTitle: 'Edit Basic Info'}</ModalHeader>
        <ModalBody>
          <this.EditingBasicInfo
            info={this.state.info}
            isUpdating={this.props.isUpdating}
            errors={this.state.errors}
            onChange={this.onChange}
            loadOptions={this.loadOptions}
            onSave={this.onSave}
            onCancel={this.props.onCancel}
            exampleType={'Headlines'}
            onChangeLocation={this.onChangeLocation}
            fieldsToShow={this.props.fieldsToShow || undefined}/>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { profile } = state;

  let info = {
    firstname: '',
    lastname: '',
    headline: '',
    vanity: '',
    country: '',
    city: '',
    state: '',
  };

  if (! isEmpty(profile.data)) {
    const {
      name,
      headline,
      vanity,
      country,
      state,
      city
    } = profile.data;

    const [firstname, lastname] = name.split(' ');

    info = { ...info, headline, vanity, country, city, state, firstname, lastname };
  }

  return {
    info,
    isUpdating: profile.isUpdating,
    profile: profile.data,
    states: selectFormattedStates(state),
    statesPageCount: selectStatesPageCount(state),
    cities: selectFormattedCities(state),
    citiesPageCount: selectCitiesPageCount(state),
    countries: selectFormattedCountries(state),
    countriesPageCount: selectCountriesPageCount(state)
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...profileActions,
      ...routerActions,
      getStates,
      getCities,
      getCountries,
      updateMyCareerPreppedStatus,
      getMyCareerPreppedStatus,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicEditModal);
