import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { Prompt } from 'react-router-dom';
import { isEmpty, isUndefined } from 'lodash';

import { checkUrl, parseJSON } from '../../utils';
import { withHooks } from '../../utils/withHooks';
import Validator from '../../validator';
import { MAX_FILE_NO, MAX_UPLOAD_ERROR_MSG } from '../../utilConstants';

import * as badgeActions from '../../redux/actions/badges';
import * as discussionActions from '../../redux/actions/discussions';
import * as skillBuildersActions from '../../redux/actions/skill-builders';
import * as questionnairesActions from '../../redux/actions/questionnaires';
import * as routerActions from '../../redux/actions/router';
import { openModal, closeModal } from '../../redux/actions/components';

import StickyDiv from '../common/stickyDiv/StickyDiv';
import Header from '../../components/skill-builders/essentials/Header';
import BadgeContainer from '../../components/skill-builders/claim-badge/BadgeContainer';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

class ClaimBadge extends Component {
  static propTypes = {
    files: PropTypes.array.isRequired,
    unit: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    location: PropTypes.object,
    pathname: PropTypes.string,
    currentUser: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    isIssuingBadge: PropTypes.bool,
    badge: PropTypes.object,
    params: PropTypes.object,
    issuedBadge: PropTypes.object.isRequired,
    badges: PropTypes.array.isRequired,
    isRequestingUnit: PropTypes.bool.isRequired,
    isRequestingQuestionaires: PropTypes.bool.isRequired,
    storedAction: PropTypes.string,
    navigate: PropTypes.func.isRequired,
    modal: PropTypes.string,
    isHookedToARoute: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      path: '',
      unitTitle: '',
      errors: {},
      addPortfolioRadio: null,
      selectedFile: {
        title: '',
        description: '',
        file: '',
        url: ''
      },
      isClaimBadgeSaved: false,
      showCustomDialog: false,
      backHasBeenClicked: false,
      nextLocation: null,
      evidenceGuidelinesTooltipOpen: false,
      statementGuidelinesTooltipOpen: false,
      justificationConfirmed: false,
      issuedBadge: Object.assign({}, this.props.issuedBadge),
      editorText: { statement: this.props.issuedBadge.statement },
      selectedFiles: [],
      guidelinesTooltipOpen: false,
      showInsertTextBox: false
    };

    this.onRemoveFile = this.onRemoveFile.bind(this);
    this.onAddEvidence = this.onAddEvidence.bind(this);
    this.onChangeStatement = this.onChangeStatement.bind(this);
    this.onChangeSelectedFile = this.onChangeSelectedFile.bind(this);
    this.onAcknowledgementChange = this.onAcknowledgementChange.bind(this);
    this.toggleShareAndVerifyCheckboxes = this.toggleShareAndVerifyCheckboxes.bind(this);
    this.toggleEvidenceGuidelinesTooltip = this.toggleEvidenceGuidelinesTooltip.bind(this);
    this.toggleStatementGuidelinesTooltip = this.toggleStatementGuidelinesTooltip.bind(this);
    this.onChangeAddPortfolio = this.onChangeAddPortfolio.bind(this);
    this.onCancelResetFile = this.onCancelResetFile.bind(this);
    this.onConfirmResetFile = this.onConfirmResetFile.bind(this);
    this.onConfirmJustification = this.onConfirmJustification.bind(this);
    this.justifyStatement = this.justifyStatement.bind(this);
    this.isValidClaimBadge = this.isValidClaimBadge.bind(this);
    this.claimBadge = this.claimBadge.bind(this);
    this.closeCustomDialog = this.closeCustomDialog.bind(this);
    this.onConfirmLeaveRoute = this.onConfirmLeaveRoute.bind(this);
    this.onDropFile = this.onDropFile.bind(this);
    this.onSelectFile = this.onSelectFile.bind(this);
    this.onInsertLinkClicked = this.onInsertLinkClicked.bind(this);
    this.onDeleteLoadedFile = this.onDeleteLoadedFile.bind(this);
    this.toggleGuidelinesTooltip = this.toggleGuidelinesTooltip.bind(this);
    this.onDisplayLinkingPortfolioModal = this.onDisplayLinkingPortfolioModal.bind(this);
  }

  componentWillMount(){
    const { params, isAuthenticated, actions, unit, badge } = this.props;

    if (!isAuthenticated) {
      const redirectPath = 'skill-builders';
      toastr.error('Permission is Needed.', 'You must be signed in to claim a badge.');
      return actions.redirect(`/${redirectPath}/${params.slug}`);
    }

    actions.unitRequest(params.slug, isAuthenticated);
    actions.questionnairesRequest();

    if (! isEmpty(unit)) {
      const unitTitle = `${unit.code} ${unit.title}`;

      this.setState({ unitTitle });
    }

    if (! isEmpty(badge) && (! isUndefined(badge.files) && (badge.files.length !== 0))) {
      this.getFiles(badge).then((files) => {
        const issuedBadge = Object.assign({}, this.state.issuedBadge, { files });

        this.setState({ issuedBadge, addPortfolioRadio: '1' });
      });
    }
  }

  componentDidMount() {
    const { actions } = this.props;

    actions.setRouterHookedToARoute('claim-badge');
  }

  componentWillReceiveProps(nextprops){
    if (!isEmpty(nextprops.unit) && nextprops.unit !== this.props.unit) {
      const unitTitle = `${nextprops.unit.code} ${nextprops.unit.title}`;

      this.setState({
        unitTitle,
        path: nextprops.location.pathname
      });
    }

    if (
      !nextprops.isRequestingUnit &&
        !isEmpty(nextprops.unit) &&
        (nextprops.unit.badge.issued !== '') &&
        nextprops.unit.badge.isClaimed
    ) {
      this.props.navigate(`/skill-badges/badge-details/${nextprops.unit.badge.issued.id}`);
    }
  }

  componentWillUpdate(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname)
      this.setState({ path: nextProps.location.pathname });
  }

  componentWillUnmount() {
    this.props.actions.removeRouterHookedToARoute();
  }

  onDropFile(files) {
    if(files[0].size > 25000000)
      return toastr.info('Sorry, this image exceeds the 8MB limit.');

    this.setupImagePreview(files[0]);
  }

  onSelectFile(id) {
    return () => {
      const { selectedFiles: files } = this.state;
      const index = files.indexOf(id);

      const selectedFiles = index !== -1 ?
        [...files.slice(0, index), ...files.slice(index + 1)] :
        [...files, id];

      this.setState({ selectedFiles });
    };
  }

  setupImagePreview(file) {
    this.setState({
      selectedFile: {
        ...this.state.selectedFile,
        file
      }
    },() => this.isValid('file'));
  }

  onInsertLinkClicked(){
    const { showInsertTextBox } = this.state;

    this.setState({
      showInsertTextBox: !showInsertTextBox,
      selectedFile: { ...this.state.selectedFile, file: '', url: '' }
    });
  }

  onDeleteLoadedFile(){
    this.setState({
      showInsertTextBox: false,
      selectedFile: { ...this.state.selectedFile, file: '', url: '' }
    });
  }

  toggleGuidelinesTooltip(){
    this.setState({
      guidelinesTooltipOpen: !this.state.guidelinesTooltipOpen
    });
  }

  closeCustomDialog(){
    this.setState({
      showCustomDialog: false,
      nextLocation: null
    }, () => {
      if(this.props.storedAction !== null){
        this.props.actions.clearAllActionsStored();
      }
    });
  }

  onDisplayLinkingPortfolioModal() {
    const { actions } = this.props;

    actions.openModal('fileModalInBakedBadgePage');
  }

  onConfirmLeaveRoute(){
    const { actions, storedAction } = this.props;
    const { nextLocation } = this.state;
    this.setState({
      showCustomDialog: false
    },() => {
      actions.removeRouterHookedToARoute();
      if(storedAction === 'userLogout'){
        actions.userLogout();
      }
      actions.redirect(nextLocation);
      actions.clearAllActionsStored();
    });
  }

  async getFiles(badge) {
    const filesPromises = badge.files.map(async (fileObject) => {
      const file = await this.getFileFromUrl(fileObject);

      return {
        ...fileObject,
        file
      };
    });

    return await Promise.all(filesPromises).then(files => files);
  }

  getFileFromUrl(fileObject) {
    try {
      const response = axios({
        method: 'get',
        url: fileObject.file,
        responseType: 'blob'
      }).then(r => new File([r.data], fileObject.filename, { type: fileObject.type }));

      return response;
    } catch (error) {
      toastr.error(error);
    }
  }

  isValid(field = null) {
    const rules = {
      title: ['required', 'minLength|1'],
      description: ['required', 'minLength|1', 'maxLength|100'],
      url: ['choseAtleastOne|file,url', 'url'],
      file: ['choseAtleastOne|file,url']
    };
    const { selectedFile } = this.state;

    const validate = Validator.createValidator(rules, selectedFile, field);
    const { errors, isValid } = validate;

    this.setState({ errors });

    return isValid;
  }

  isValidStatement(field = null) {
    const rules = {
      statement: ['required', 'minWords|50', 'maxWords|500']
    };
    const { editorText } = this.state;

    const validate = Validator.createValidator(rules, editorText, field);
    const { errors, isValid } = validate;

    this.setState({ errors });

    return isValid;
  }

  justifyStatement(){
    this.setState({
      justificationConfirmed: true,
      showConfirmJustificationModal: false
    });
  }

  onConfirmJustification(){
    const { showConfirmJustificationModal } = this.state;

    if (!showConfirmJustificationModal && !this.isValidStatement()) {
      return toastr.error('You can confirm your written justification once you\'ve finished writing it.');
    }
    this.setState({
      showConfirmJustificationModal: !showConfirmJustificationModal
    });
  }

  onAcknowledgementChange(value) {
    const { issuedBadge: badgeData } = this.state;

    if (badgeData.acknowledgement.includes(value)) {
      const acknowledgement = badgeData.acknowledgement.filter( item => item !== value );

      const issuedBadge = Object.assign({}, badgeData, { acknowledgement });

      const newIssuedBadge = Object.assign({}, this.props.badge, { acknowledgement });

      this.props.actions.updateUnitBadgeOnLocalStore(newIssuedBadge, this.props.unit.id);

      return this.setState({ issuedBadge });
    }

    const issuedBadge = Object.assign({}, badgeData, {
      acknowledgement: [
        ...badgeData.acknowledgement,
        value
      ]
    });

    const newIssuedBadge = Object.assign({}, this.props.badge, {
      acknowledgement: [
        ...badgeData.acknowledgement,
        value
      ]
    });

    this.setState({ issuedBadge });
    this.props.actions.updateUnitBadgeOnLocalStore(newIssuedBadge, this.props.unit.id);
  }

  onChangeStatement(value,...params) {
    const issuedBadge = Object.assign({}, this.state.issuedBadge, {
      statement: value
    });
    const editorText = { statement: params[2].getText().replace(/\r?\n|\r/gm,' ') };
    this.setState({ issuedBadge, editorText }, () => this.isValidStatement('statement'));
  }

  onChangeSelectedFile(event) {
    const { name, value } = event.target;

    const selectedFile = Object.assign({}, this.state.selectedFile, {
      [name]: value
    });

    this.setState({ selectedFile }, () => this.isValid(name));

    if (name === 'url') {
      if (checkUrl(value, 'youtube') || checkUrl(value, 'vimeo')) {
        this.setState({
          selectedFile: { ...selectedFile, file: '' }
        }, () => this.isValid(name));
      }
    }
  }

  toggleShareAndVerifyCheckboxes(event) {
    const { name } = event.target;
    const { [name]: value } = this.state.issuedBadge;
    const issuedBadge = Object.assign({}, this.state.issuedBadge, {
      [name]: ! value
    });

    const newIssuedBadge = Object.assign({}, this.props.badge, {
      verified: ! value
    });

    this.setState({ issuedBadge });
    this.props.actions.updateUnitBadgeOnLocalStore(newIssuedBadge, this.props.unit.id);
  }

  onAddEvidence() {
    const isUploadFormDirty = Object.values(this.state.selectedFile).some(value => value !== '');

    const uploadedFiles = this.props.badge.files.filter(file => file.id === undefined);
    const newlySelectedFiles = this.state.selectedFiles.filter(fileId => !this.state.issuedBadge.selectedFiles.includes(fileId));

    const uploadedFilesUpdated = [
      ...uploadedFiles,
      ...(isUploadFormDirty ? [this.state.selectedFile] : [])
    ];

    const totalFilesCount = uploadedFilesUpdated.length + this.state.issuedBadge.selectedFiles.length + newlySelectedFiles.length;

    if (totalFilesCount > MAX_FILE_NO) {
      toastr.error('', MAX_UPLOAD_ERROR_MSG);
      return;
    }

    if (isUploadFormDirty && (! this.isValid())) return;

    const selectedFilesObjects = this.props.files.filter(file => newlySelectedFiles.includes(file.id));

    const issuedBadge = Object.assign({}, this.state.issuedBadge, {
      selectedFiles: [
        ...this.state.issuedBadge.selectedFiles,
        ...newlySelectedFiles
      ],
      files: [
        ...this.state.issuedBadge.files,
        ...selectedFilesObjects,
        ...(isUploadFormDirty ? [this.state.selectedFile] : [])
      ]
    });

    const selectedFile = {
      title: '',
      description: '',
      file: '',
      url: ''
    };

    let fileData = {};

    if (this.state.selectedFile.url !== '') {
      fileData = { externalurl: this.state.selectedFile.url };
    }

    if (this.state.selectedFile.file) {
      fileData = {
        filename: this.state.selectedFile.file.name,
        file: URL.createObjectURL(this.state.selectedFile.file),
        type: this.state.selectedFile.file.type
      };
    }

    const updatedSelectedFile = Object.assign({}, this.state.selectedFile, fileData);

    const newIssuedBadge = Object.assign({}, this.props.badge, {
      selectedFiles: [
        ...this.state.issuedBadge.selectedFiles,
        ...newlySelectedFiles
      ],
      files: [
        ...this.props.badge.files,
        ...selectedFilesObjects,
        ...(!isEmpty(fileData) ? [updatedSelectedFile] : [])
      ]
    });

    this.setState({ issuedBadge, selectedFile, selectedFiles: [], showInsertTextBox: false });
    this.props.actions.updateUnitBadgeOnLocalStore(newIssuedBadge, this.props.unit.id);

    this.props.actions.closeModal();
  }

  onRemoveFile(event) {
    const index = parseInt(event.target.dataset.index);

    const { files } = this.state.issuedBadge;
    let selectedFiles = [ ...this.state.selectedFiles ];
    const deletedFile = this.props.badge.files.find((file, fileIndex) => fileIndex === index);

    if (deletedFile.id) {
      selectedFiles = selectedFiles.filter(id => id !== deletedFile.id);
    }

    const issuedBadge = Object.assign({}, this.state.issuedBadge, {
      files: [
        ...files.filter((file, fileIndex) => fileIndex !== index)
      ],

      selectedFiles: [ ...selectedFiles ]
    });

    const newIssuedBadge = Object.assign({}, this.props.badge, {
      files: [
        ...this.props.badge.files.filter((file, fileIndex) => (fileIndex !== index))
      ],

      selectedFiles: [ ...selectedFiles ]
    });

    this.setState({ issuedBadge, selectedFiles });
    this.props.actions.updateUnitBadgeOnLocalStore(newIssuedBadge, this.props.unit.id);
  }

  isValidJustification(justification) {
    const rules = {
      justification: ['required', 'minWords|50', `maxWords|${justification.question.wordLimit}`]
    };

    const validate = Validator.createValidator(rules, justification, 'justification');
    const { errors } = validate;

    this.setState({ errors });

    return validate;
  }

  isValidClaimBadge() {
    const { unit, badge } = this.props;
    const { verified, acknowledgement } = this.state.issuedBadge;
    let justificationErrors = [];

    const isValidAcknowledgement = (acknowledgement.length === unit.acknowledgement.length);

    badge.justifications.forEach((justification) => {
      const justificationData = (justification.answer.editorPlainText) ? justification.answer.editorPlainText : '';
      const justificationObj = { justification: justificationData, question: justification.question };
      let { errors, isValid } = this.isValidJustification(justificationObj);

      if (! isValid) {
        errors['questionId'] = justification.question.id;

        justificationErrors = [...justificationErrors, errors];
      }
    });

    const isValidClaimBadge = ((badge.justifications.length !== 0) && (justificationErrors.length === 0)) && isValidAcknowledgement && verified;

    this.setState({ isValidClaimBadge });

    return isValidClaimBadge;
  }

  claimBadge() {
    const { params, navigate, isAuthenticated } = this.props;

    if (! isAuthenticated) {
      toastr.error('Nonauthorized', 'Please login/signup to claim this badge');

      return;
    }

    if (! this.state.isValidClaimBadge) return;

    this.setState({ isClaimBadgeSaved: true }, () => {
      navigate(`/skill-builders/claim-badge-survey/${params.slug}`);
    });
  }

  toggleStatementGuidelinesTooltip() {
    this.setState({
      statementGuidelinesTooltipOpen: !this.state.statementGuidelinesTooltipOpen
    });
  }

  toggleEvidenceGuidelinesTooltip() {
    this.setState({
      evidenceGuidelinesTooltipOpen: !this.state.evidenceGuidelinesTooltipOpen
    });
  }

  onChangeAddPortfolio(event){
    const { value } = event.target;
    this.setState({
      addPortfolioRadio: value
    });
  }

  onCancelResetFile(){
    this.setState({
      addPortfolioRadio: '1'
    });
  }

  onConfirmResetFile(){
    const issuedBadge = Object.assign({}, this.state.issuedBadge, {
      files: []
    });

    this.setState({
      addPortfolioRadio: null,
      issuedBadge
    });
  }

  render() {
    const {
      unitTitle,
      path,
      errors,
      issuedBadge,
      selectedFile,
      evidenceGuidelinesTooltipOpen,
      statementGuidelinesTooltipOpen,
      addPortfolioRadio,
      showCustomDialog,
      selectedFiles,
      guidelinesTooltipOpen,
      showInsertTextBox
    } = this.state;

    const {
      unit,
      badge,
      isIssuingBadge,
      isAuthenticated,
      isRequestingUnit,
      isRequestingQuestionaires,
      params,
      modal,
      isHookedToARoute
    } = this.props;

    const displayLinkingPortfolioModal = modal && modal === 'fileModalInBakedBadgePage';

    return (
      <div>
        <Helmet
          title={`Skill Builders - Claim Badge - ${unit.code} ${unit.title}`}/>

        <div className="container">
          <div className="row" style={{ marginTop: 30 }}>
            <StickyDiv
              component={Header}
              startId={'essHeader'}
              endId={
                unit.badge && Object.keys(unit.badge).length === 0 ?
                  'warning-card' : 'claim-badge'
              }
              defaultCss={'intro-header container'}
              unit={unit}
              slug={params.slug}
              unitTitle={unitTitle}
              path={path}
              isAuthenticated={isAuthenticated}
              isRequesting={isRequestingUnit}/>

            <Prompt
              when={isHookedToARoute.status}
              message="Are you sure you want to leave this page? Any changes may be unsaved."/>

            <BadgeContainer
              isRequesting={isRequestingUnit || isRequestingQuestionaires}
              unit={unit}
              badge={badge}
              errors={errors}
              isValidClaimBadge={this.isValidClaimBadge}
              addPortfolioRadio={addPortfolioRadio}
              issuedBadge={issuedBadge}
              selectedFile={selectedFile}
              issueBadge={this.issueBadge}
              claimBadge={this.claimBadge}
              isIssuingBadge={isIssuingBadge}
              onRemoveFile={this.onRemoveFile}
              isAuthenticated={isAuthenticated}
              onAddEvidence={this.onAddEvidence}
              acknowledgement={unit.acknowledgement}
              onChangeStatement={this.onChangeStatement}
              toggleShareAndVerifyCheckboxes={this.toggleShareAndVerifyCheckboxes}
              onChangeSelectedFile={this.onChangeSelectedFile}
              onAcknowledgementChange={this.onAcknowledgementChange}
              evidenceGuidelinesTooltipOpen={evidenceGuidelinesTooltipOpen}
              statementGuidelinesTooltipOpen={statementGuidelinesTooltipOpen}
              toggleEvidenceGuidelinesTooltip={this.toggleEvidenceGuidelinesTooltip}
              toggleStatementGuidelinesTooltip={this.toggleStatementGuidelinesTooltip}
              onCancelResetFile={this.onCancelResetFile}
              onConfirmResetFile={this.onConfirmResetFile}
              onChangeAddPortfolio={this.onChangeAddPortfolio}
              onConfirmJustification={this.onConfirmJustification}
              displayLinkingPortfolioModal={displayLinkingPortfolioModal}
              onDisplayLinkingPortfolioModal={this.onDisplayLinkingPortfolioModal}

              selectedFiles={selectedFiles}
              onSelectFile={this.onSelectFile}
              onDeleteLoadedFile={this.onDeleteLoadedFile}
              onInsertLinkClicked={this.onInsertLinkClicked}
              showInsertTextBox={showInsertTextBox}
              guidelinesTooltipOpen={guidelinesTooltipOpen}
              onDropFile={this.onDropFile}
              toggleGuidelinesTooltip={this.toggleGuidelinesTooltip}/>
          </div>
        </div>

        {showCustomDialog &&
          <Modal
            size="lg"
            className="modal-margin-top"
            isOpen={showCustomDialog}
            backdrop="static"
            toggle={this.closeCustomDialog}>
            <ModalHeader
              toggle={this.closeCustomDialog}>Leave this page?</ModalHeader>
            <ModalBody>
              <p>Are you sure you want to leave this page? Any changes may be unsaved.</p>
            </ModalBody>
            <ModalFooter>
              <button
                onClick={this.closeCustomDialog}
                className="btn btn-secondary">
                Stay
              </button>

              <button
                onClick={this.onConfirmLeaveRoute}
                className="btn btn-primary">
                Yes, Leave
              </button>
            </ModalFooter>
          </Modal>}
      </div>
    );
  }
}

const formatUnit = (unit) => {
  if (!isEmpty(unit)) {
    return {
      ...unit,
      acknowledgement: getAcknowledgement(unit.acknowledgement)
    };
  }

  return {};
};

const getAcknowledgement = (ack) => {
  return ack === '' ? [] : parseJSON(ack);
};

const mapStateToProps = (state) => {
  let { unit, badge } = {};

  let issuedBadge = {
    acknowledgement: [],
    statement: '',
    files: [],
    selectedFiles: [],
    verified: false,
    feedbacks:[],
    justifications: []
  };

  if (! isEmpty(state.questionnaires.data)) {
    const feedbacks = state.questionnaires.data.map((question) => {
      const { choices, id, question: text } = question;
      return Object.assign({},{
        question: id,
        feedback: '',
        questionText: text,
        choices: choices === '' ? [] :parseJSON(choices)
      });
    });

    issuedBadge.feedbacks = feedbacks;
  }

  unit = formatUnit(state.skillBuilders.unit);
  badge = unit.badge;

  if (! isEmpty(badge)) {
    const justifications = badge.justifications.map((justification) => {
      if (isEmpty(justification.answer)) {
        return Object.assign({}, justification, { answer: { answerText: '' }});
      }

      return justification;
    });

    issuedBadge.justifications = justifications;

    if (! isUndefined(badge.acknowledgement)) {
      issuedBadge = Object.assign({}, issuedBadge, { acknowledgement: badge.acknowledgement });
    }

    if (! isUndefined(badge.verified)) {
      issuedBadge = Object.assign({}, issuedBadge, { verified: badge.verified });
    }
  }

  return {
    unit,
    badge,
    issuedBadge,
    files: state.files.data,
    badges: state.badges.data,
    profile: state.profile.data,
    modal: state.components.modal,
    currentUser: state.auth.data.currentUser,
    isAuthenticated: state.auth.isAuthenticated,
    isIssuingBadge: state.badges.isIssuingBadge.status,
    isRequestingQuestionaires: state.questionnaires.isRequesting,
    isRequestingUnit: state.skillBuilders.isRequestingUnit,
    storedAction: state.router.storedAction,
    isHookedToARoute: state.router.isHookedToARoute
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({},
    badgeActions,
    discussionActions,
    skillBuildersActions,
    questionnairesActions,
    routerActions,
    { openModal, closeModal }
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ClaimBadge));
