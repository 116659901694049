import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const descriptionList = (
  exampleType,
  exampleBody
) => {

  const chunkArray = (myArray, chunk_size) => {
    let index = 0;
    const arrayLength = myArray.length;
    let tempArray = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
      let myChunk = myArray.slice(index, index + chunk_size);
      tempArray.push(myChunk);
    }

    return tempArray;
  };

  switch (exampleType) {
  case 'Resume Certifications':
  case 'Associations':
  case 'Resume Experience':
  case 'Resume Summary':
  case 'Experience': 
    return (
      <ul className="pl-0">
        {
          exampleBody.map((item, index) => {
            if (exampleBody.length > 1 ) {
              return <li key={index} className="ml-2">{item}</li>;
            } else {
              return <div key={index} className="ml-0">{item}</div>;
            }
          })
        }
      </ul>
    );
  
  // case "Resume Certifications": 
  // case "Associations":
  case 'Honors & Awards':
  case 'Activities & Clubs': {
    let rowArray = chunkArray(exampleBody,2);
    const noPad = {paddingLeft: '0px'};
    return( 
      <Container style={noPad}>
        {
          rowArray.map((exampleRow,index) => {
            const [ colA = '', colB = '' ] = exampleRow;
            return(
              <Row key={index} >
                <Col className="col ml-0" xs="6" md="6" >{colA}</Col>
                <Col className="col" xs="6" md="6">{colB}</Col>
              </Row>
            );
          })
        }
      </Container>
    );
  }
    
  default:
    return exampleBody.map((item, index) => {
      return <p key={index} className="ml-0">{item}</p>;
    });
  }
};

export default descriptionList;