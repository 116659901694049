import PropTypes from 'prop-types';
import React from 'react';

const Loading = ({ loaderStyles }) => {
  return (
    <div style={
      Object.assign({}, { 'textAlign': 'center' }, loaderStyles)
    }>
      <i className="fa fa-spinner fa-pulse fa-2x fa-fw" />
    </div>
  );
};

Loading.propTypes = {
  loaderStyles: PropTypes.object
};

export default Loading;
