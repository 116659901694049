import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import ItemTypes from '../../constants/ItemTypes';
import { useDrag, useDrop } from 'react-dnd';

import classNames from 'classnames';

import SkillEditCard from './SkillEditCard';

const SkillEdit = ({
  onChangeTitle,
  skill,
  index,
  moveCard,
  onDelete
}) => {
  const ref = useRef(null);

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect: monitor => ({
      isOver: monitor.isOver()
    }),
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = index;
      const hoverIndex = monitor.getItem().index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return {
        index,
        id: skill.id,
      };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div ref={preview} className={classNames('skill-item', {
      'is-over': isOver,
      'is-dragging': isDragging
    })}>
      <SkillEditCard
        ref={ref}
        onChangeTitle={onChangeTitle}
        onDelete={onDelete}
        skill={skill} />
    </div>
  );
};

SkillEdit.propTypes = {
  index: PropTypes.number.isRequired,
  skill: PropTypes.object,
  moveCard: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChangeTitle: PropTypes.func.isRequired
};

export default SkillEdit;