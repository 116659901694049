import React, { Component } from 'react';

import {
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
  Popover,
  PopoverBody,
  Collapse,
  Table,
} from 'reactstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { toastr } from 'react-redux-toastr';
import { get } from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';

import TopPraises from '../../components/myfeedback/TopPraises';
import FeedbackGiven from '../../components/myfeedback/FeedbackGiven';
import FeedbackReceived from '../../components/myfeedback/FeedbackReceived';
import IgnoreFeedbackRequest from '../../components/myfeedback/IgnoreFeedbackRequest';
import { FEEDBACK_DATE_FORMAT, SETTINGS_DATE_FORMAT } from '../../constants';
import { SimpleToolTipWrapper } from '../../components/common/ToolTipWrapper';

const myRequests = [
  {
    requestID: '1234',
    requestedOn: '11/14/2020',
    skill: 'Communicate Effectively on the Phone',
    skillImage: '',
    reviewer: 'Melrose Andrade',
    requestorAvatar: 'https://randomuser.me/api/portraits/men/71.jpg',
    status: 'pending',
    lastReminderOn: '11/20/2020',
  },
  {
    requestID: '4321',
    requestedOn: '11/14/2020',
    skill: 'Greet Others Professionally',
    skillImage: '',
    reviewer: 'Brett Savage',
    requestorAvatar: 'https://randomuser.me/api/portraits/men/71.jpg',
    lastReminderOn: '',
    status: 'completed',
  },
  {
    requestID: '3214',
    requestedOn: '11/14/2020',
    skill: 'Design Feasible Plans',
    skillImage: '',
    reviewer: 'Daniel Goleman',
    requestorAvatar: 'https://randomuser.me/api/portraits/men/71.jpg',
    lastReminderOn: '',
    status: 'cancelled',
  },
  {
    requestID: '3241',
    requestedOn: '11/14/2020',
    skill: 'Design Feasible Plans',
    skillImage: '',
    reviewer: '',
    requestorAvatar:
      'https://thumbs.dreamstime.com/b/creative-illustration-default-avatar-profile-placeholder-isolated-background-art-design-grey-photo-blank-template-mockup-144855718.jpg',
    lastReminderOn: '',
    status: 'matching',
  },
];

const feedbackGiven = [
  {
    id: 123,
    skillName: 'Develops Targeted Resumes',
    userImageURL: 'https://randomuser.me/api/portraits/men/46.jpg',
    userName: 'Jeff Weiner',
    feedbackGivenOn: '11/14/2020',
    ratingGiven: 3,
    questionResponseRating: {
      overall: ' Agree',
      description: 'are specific and clear, persuasively support the skill ',
    },
    portfolioEvidenceRating: {
      overall: ' Agree',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec feugiat massa mattis nunc ultrices bibendum. Fusce a mi erat. Sed et dui lacinia, posuere felis quis, mattis justo. Praesent dui nisl, congue quis malesuada ut, ornare sit amet mauris',
    },
    praise: {
      givenOn: '11/12/2020',
      praises: [
        'Descriptive (Explained Clearly)',
        'Actionable (Can be acted on)',
        'Specific (Is precise)',
        'Helpful(Helps me improve)',
      ],
    },
    isRevised: true,
  },
  {
    id: 234,
    skillName: 'Allocate Resource Efficiently',
    userImageURL: 'https://randomuser.me/api/portraits/men/58.jpg',
    userName: 'Jimmy Hicks',
    feedbackGivenOn: '11/14/2020',
    ratingGiven: 4,
    questionResponseRating: {
      overall: ' Agree',
      description: 'are specific and clear, persuasively support the skill ',
    },
    portfolioEvidenceRating: {
      overall: ' Agree',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec feugiat massa mattis nunc ultrices bibendum. Fusce a mi erat. Sed et dui lacinia, posuere felis quis, mattis justo. Praesent dui nisl, congue quis malesuada ut, ornare sit amet mauris',
    },
    praise: {
      givenOn: '11/12/2020',
      praises: [
        'Descriptive (Explained Clearly)',
        'Actionable (Can be acted on)',
        'Specific (Is precise)',
        'Helpful(Helps me improve)',
      ],
    },
  },
];

const requestReceived = {
  totalCount: 300,
  page: 1,
  requests: [
    {
      requestID: '1234',
      requestedOn: '11/14/2020',
      skill: 'Communicate Effectively on the Phone',
      skillImage: '',
      requestor: 'Melrose Andrade',
      requestorAvatar: '',
      lastReminderOn: '11/20/2020',
    },
    {
      requestID: '4321',
      requestedOn: '11/14/2020',
      skill: 'Greet Others Professionally',
      skillImage: '',
      requestor: 'Brett Savage',
      requestorAvatar: '',
      lastReminderOn: '',
    },
    {
      requestID: '3214',
      requestedOn: '11/14/2020',
      skill: 'Design Feasible Plans',
      skillImage: '',
      requestor: 'Daniel Goleman',
      requestorAvatar: '',
      lastReminderOn: '',
    },
  ],
};

class IRFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapseOpen: true,
      filtered: feedbackGiven,
      filteredMyRequests: myRequests,
      currentTab: 'given',
      isIgnoreModalOpen: false,
      isRankPopoverOpen: false,
    };
  }

  setCurrentTab(currentTab) {
    this.setState({ currentTab });
  }

  toggleCollapse = () => {
    this.setState((state) => {
      return {
        isCollapseOpen: !state.isCollapseOpen,
      };
    });
  };

  toggleIgnore = () => {
    this.setState((state) => {
      return {
        isIgnoreModalOpen: !state.isIgnoreModalOpen,
      };
    });
  };

  topPraises = {
    descriptive: 3,
    actionable: 8,
    specific: 3,
    helpful: 2,
    kind: 1,
    accurate: 1,
    timely: 3,
  };

  searchTextHandler = (e) => {
    let filtered = [];
    if (e.target.value) {
      filtered = feedbackGiven.filter(
        feedback =>
          feedback.skillName
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          feedback.userName.toLowerCase().includes(e.target.value.toLowerCase())
      );
    } else {
      filtered = feedbackGiven;
    }
    this.setState({ filtered });
  };

  searchMyRequest = (e) => {
    let filteredMyRequests = [];
    if (e.target.value) {
      filteredMyRequests = myRequests.filter(
        requests =>
          requests.skill.toLowerCase().includes(e.target.value.toLowerCase()) ||
          requests.reviewer.toLowerCase().includes(e.target.value.toLowerCase())
      );
    } else {
      filteredMyRequests = myRequests;
    }
    this.setState({ filteredMyRequests });
  };

  toggleRankPopover = () => {
    this.setState((state) => {
      return {
        isRankPopoverOpen: !state.isRankPopoverOpen,
      };
    });
  };

  // make following function a class component
  getFeedbackGiven = () => {
    return this.state.filtered.map(feedback => (
      <FeedbackGiven key={feedback.id} feedback={feedback} />
    ));
  };

  // make following function a class component
  getFeedbackReceived = () => {
    return this.state.filtered.map(feedback => (
      <FeedbackReceived key={feedback.id} feedback={feedback} />
    ));
  };

  getMyRequests = () => {
    return (
      <tbody>
        {this.state.filteredMyRequests.map((myRequests) => {
          return (
            <tr key={myRequests.requestID}>
              <td scope="row">{myRequests.requestedOn}</td>
              <td>
                <div className="d-flex align-items-center">
                  <img
                    className="receivedRequestImage"
                    src={get(myRequests, 'resourceUrl','../../assets/images/temp/badge.png')}/>
                  <span>{myRequests.skill}</span>
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <img
                    src={myRequests.requestorAvatar}
                    className="requestorAvatar"/>
                  {myRequests.reviewer && (
                    <span>
                      <u>{myRequests.reviewer}</u>
                    </span>
                  )}
                  {!myRequests.reviewer && <span>Finding Matches</span>}
                </div>
              </td>
              <td>{`${myRequests.lastReminderOn}`}</td>
              <td>
                <div
                  className={`requestStatusTag ${myRequests.status}-request`}>
                  {myRequests.status}
                </div>
              </td>
              <td className="pr-0">
                {myRequests.status !== 'completed' &&
                  myRequests.status !== 'cancelled' &&
                  myRequests.status !== 'matching' && (
                  <Button className="sendButton respondButton mr-0">
                    Resend
                  </Button>
                )}
              </td>
              <td>
                {myRequests.status !== 'cancelled' &&
                  myRequests.status !== 'completed' && (
                  <button type="button" className="btn btn-secondary">
                    Cancel
                  </button>
                )}
                {myRequests.status === 'cancelled' &&
                  myRequests.status !== 'completed' && (
                  <button type="button" className="btn btn-secondary">
                    Remove
                  </button>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  getTableContent = () => {
    return (
      <tbody>
        {requestReceived.requests.map((receivedRequests) => {
          return (
            <tr key={receivedRequests.requestID}>
              <td scope="row">
                {receivedRequests.requestedOn}
                {receivedRequests.lastReminderOn && (
                  <SimpleToolTipWrapper
                    id={'ir-feedback-reminder-info'}
                    toolTipContent={
                      <div className="inside-tooltip">
                        {`You were sent a reminder on ${receivedRequests.lastReminderOn}`}
                      </div>
                    }>
                    <i className="fa fa-info-circle infoIcon" />
                  </SimpleToolTipWrapper>
                )}
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <img
                    className="receivedRequestImage"
                    src={get(receivedRequests, 'resourceUrl','../../assets/images/temp/badge.png')}/>
                  <span>{receivedRequests.skill}</span>
                </div>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <img
                    src="https://randomuser.me/api/portraits/men/46.jpg"
                    className="requestorAvatar"/>
                  <span>{receivedRequests.requestor}</span>
                </div>
              </td>
              <td>
                <Button className="sendButton respondButton mr-3">
                  Respond
                </Button>
                <button
                  onClick={this.toggleIgnore}
                  type="button"
                  className="btn btn-secondary">
                  Ignore
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  handleDatePicker = (event) => {
    const { filteredMyRequests } = this.state;
    if (!this.state.start_date) {
      toastr.error('', 'Please select start date.');
      return false;
    }

    if (moment(event).isBefore(this.state.start_date)) {
      toastr.error('', '"To” date should be after “From” date.');
      return false;
    }

    if (this.state.start_date && event) {
      if (
        moment(event).isSame(this.state.start_date) ||
        moment(event).isAfter(this.state.start_date)
      ) {
        const filteredRequested = filteredMyRequests.filter(
          req =>
            (moment(this.state.start_date).isSame(
              moment(req?.feedbackRequestDate?.date).format(
                SETTINGS_DATE_FORMAT
              )
            ) ||
              moment(this.state.start_date).isBefore(
                moment(req?.feedbackRequestDate?.date).format(
                  SETTINGS_DATE_FORMAT
                )
              )) &&
            (moment(event).isAfter(
              moment(req?.feedbackRequestDate?.date).format(
                SETTINGS_DATE_FORMAT
              )
            ) ||
              moment(event).isSame(
                moment(req?.feedbackRequestDate?.date).format(
                  SETTINGS_DATE_FORMAT
                )
              ))
        );

        this.setState({
          end_date: event,
          filteredMyRequests: filteredRequested,
        });
      }
    }
  };

  setTab = (event) => {
    const tab = event.target.getAttribute('data-value');
    this.setState({ currentTab: tab });
  };

  handleChangeDate = event =>
    this.setState({ start_date: event, end_date: null });

  render() {
    return (
      <div className="mySkillsContainer container">
        <Row>
          <Col md="8">
            <h4>{'My Feedback'}</h4>
            <Row>
              <div
                onClick={this.setTab}
                data-value={'given'}
                className={`pointer customBadge ${
                  this.state.currentTab === 'given' ? 'selected' : ''
                }`}>
                Given (15)
              </div>
              <div
                onClick={this.setTab}
                data-value={'received'}
                className={`pointer customBadge ${
                  this.state.currentTab === 'received' ? 'selected' : ''
                }`}>
                Received (35)
              </div>
              <div
                onClick={this.setTab}
                data-value={'myRequests'}
                className={`pointer customBadge ${
                  this.state.currentTab === 'myRequests' ? 'selected' : ''
                }`}>
                My Requests (10)
              </div>
            </Row>
          </Col>
          {this.state.currentTab === 'given' && (
            <Col md="4">
              <div className="text-center mb-2">
                <h4>My Feedback Rank </h4>
                <div>
                  <img
                    id="my_feedback_rank"
                    className="my-feedback-rank"
                    src="../../assets/images/temp/my_feedback_rank.png"
                    onMouseLeave={this.toggleRankPopover}
                    onMouseOver={this.toggleRankPopover}/>
                  <Popover
                    placement="bottom"
                    isOpen={this.state.isRankPopoverOpen}
                    target="my_feedback_rank"
                    toggle={this.toggleRankPopover}>
                    <PopoverBody>
                      <p className="text-justify">
                        Rank is based on response rate to feedback requests.
                        After 5 responses, you can move beyond starter status.
                        You've given feedback 5 times and have had 7 requests.
                        Thus, your current rank is <strong>'Coach'</strong>.
                      </p>
                      <div>
                        <p>
                          <span className="font-weight-bold">Starter:</span>{' '}
                          0%-25% ratio
                        </p>
                        <p>
                          <span className="font-weight-bold">Coach:</span>{' '}
                          26%-49% ratio
                        </p>
                        <p>
                          <span className="font-weight-bold">Leader:</span>{' '}
                          50%-74% ratio
                        </p>
                        <p>
                          <span className="font-weight-bold">
                            Top Reviewer:
                          </span>{' '}
                          75%+ ratio
                        </p>
                      </div>
                    </PopoverBody>
                  </Popover>
                </div>
              </div>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <TopPraises topPraises={this.topPraises} />
          </Col>
        </Row>
        <hr className="mb-0" />
        {/* GIVEN TAB CONTENT STARTS HERE*/}
        {this.state.currentTab === 'given' && (
          <div>
            <p>
              Manage feedback requests & feedback you've given and see praise
              you've received for your feedback.
            </p>
            <Row>
              <Col md="9">
                <div className="d-flex justify-content-between">
                  <p className="font-weight-bold font18">
                    Feedback Requests (3)
                  </p>
                  {!this.state.isCollapseOpen && (
                    <i
                      onClick={this.toggleCollapse}
                      className="fa fa-chevron-down pointer"/>
                  )}
                  {this.state.isCollapseOpen && (
                    <i
                      onClick={this.toggleCollapse}
                      className="fa fa-chevron-up pointer"/>
                  )}
                </div>
                <Collapse isOpen={this.state.isCollapseOpen} id="myfeedback">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Requested on</th>
                        <th>Skill</th>
                        <th>Requestor</th>
                        <th colSpan="2">Action</th>
                        <th />
                      </tr>
                    </thead>
                    {this.getTableContent()}
                  </Table>
                  <div className="text-center themeLinkWithoutUnderline p-1">
                    <span className="pointer orange">Show More</span>
                  </div>
                </Collapse>
              </Col>
            </Row>
            <p className="font-weight-bold font18">Feedback Given</p>
            <div className="d-flex align-items-center myskillAdvSearch mb-2">
              <Row className="w-100">
                <Col md="3">
                  <FormGroup>
                    <InputGroup>
                      <Input
                        type="text"
                        name="search"
                        id="search"
                        placeholder="Search"
                        onChange={this.searchTextHandler}/>
                      <InputGroupAddon className="pointer" addonType="append">
                        <i className="fa fa-search" />
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <ButtonDropdown isOpen={false}>
                      <DropdownToggle caret>Newest</DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem>Newest</DropdownItem>
                      </DropdownMenu>
                    </ButtonDropdown>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            {this.getFeedbackGiven()}
            <div className="text-center themeLinkWithoutUnderline p-1">
              <span className="pointer">Show More</span>
            </div>
          </div>
        )}
        {/* GIVEN TAB CONTENT ENDS HERE*/}
        {/* RECEIVED TAB CONTENT STARTS HERE*/}
        {this.state.currentTab === 'received' && (
          <div>
            <p>
              From here, see all the feedback you've received from others on
              your skills and give praise for the feedback.
            </p>
            <p className="font-weight-bold font18">Feedback Received</p>
            <div className="d-flex align-items-center myskillAdvSearch mb-2">
              <Row className="w-100">
                <Col md="3">
                  <FormGroup>
                    <InputGroup>
                      <Input
                        type="text"
                        name="search"
                        id="search"
                        placeholder="Search"
                        onChange={this.searchTextHandler}/>
                      <InputGroupAddon className="pointer" addonType="append">
                        <i className="fa fa-search" />
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <ButtonDropdown isOpen={false}>
                      <DropdownToggle caret>Newest</DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem>Newest</DropdownItem>
                      </DropdownMenu>
                    </ButtonDropdown>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            {this.getFeedbackReceived()}
            <div className="text-center themeLinkWithoutUnderline p-1">
              <span className="pointer">Show More</span>
            </div>
          </div>
        )}
        {/* RECEIVED TAB CONTENT ENDS HERE*/}
        {/* MY REQUEST TAB CONTENT STARTS HERE*/}
        {this.state.currentTab === 'myRequests' && (
          <div>
            <p>
              From here, see the requests you've made for feedback, when you
              made your requests, the status, and use actions to manage your
              requests.
            </p>
            <Row className="myskillAdvSearch">
              <Col md="9" className="pr-4">
                <Row>
                  <Col md="4" className="pr-0">
                    <FormGroup>
                      <InputGroup>
                        <Input
                          type="text"
                          name="search"
                          id="search"
                          placeholder="Search"
                          onChange={this.searchMyRequest}/>
                        <InputGroupAddon className="pointer" addonType="append">
                          <i className="fa fa-search" />
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="4" className="pr-0">
                    <FormGroup>
                      <ButtonDropdown isOpen={false}>
                        <DropdownToggle caret>Status</DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem>Status</DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <ButtonDropdown isOpen={false}>
                        <DropdownToggle caret>All Skills</DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem>All Skills</DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md="3">
                <Row>
                  <Col md="6" className="pl-0">
                    <FormGroup>
                      <DatePicker
                        selected={
                          this.state.start_date &&
                          new Date(this.state.start_date)
                        }
                        dateFormat={FEEDBACK_DATE_FORMAT}
                        onChange={this.handleChangeDate}
                        className="form-control dateIcon"
                        placeholderText="From"/>
                    </FormGroup>
                  </Col>
                  <Col md="6" className="pl-0">
                    {/* <FormGroup> */}
                    <DatePicker
                      selected={
                        this.state.end_date && new Date(this.state.end_date)
                      }
                      dateFormat={FEEDBACK_DATE_FORMAT}
                      onChange={this.handleDatePicker}
                      className="form-control dateIcon"
                      placeholderText="To"/>
                    {/* </FormGroup> */}
                  </Col>
                </Row>
              </Col>
            </Row>
            <div id="myfeedback">
              <Table responsive>
                <thead>
                  <tr>
                    <th>Requested on</th>
                    <th>Skill</th>
                    <th>Reviewer</th>
                    <th>Resent On</th>
                    <th>Status</th>
                    <th colSpan="2" width="2%">
                      Action
                    </th>
                    <th />
                  </tr>
                </thead>
                {this.getMyRequests()}
              </Table>
            </div>
          </div>
        )}

        {/* TEMP CODE */}
        <IgnoreFeedbackRequest
          isOpen={this.state.isIgnoreModalOpen}
          toggleModal={this.toggleIgnore}/>
      </div>
    );
  }
}

export default IRFeedback;
