import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import { SimpleToolTipWrapper } from '../../../components/common/ToolTipWrapper';

const FrequencyInWeeksColumn = ({ rowData: rowDetails }) => {
  let isFeedbackRequestsPaused = false;

  if (rowDetails.pauseEndDate !== null) {
    const now = moment();

    isFeedbackRequestsPaused = now.isBetween(rowDetails.pauseStartDate.date, rowDetails.pauseEndDate.date);
  }

  return (
    <td scope="row" className="text-center">
      {(rowDetails.disableFeedbackRequest === 1) ?
        <>0 (<span className="text-danger">Stopped</span>)&nbsp;
          <SimpleToolTipWrapper id={`tooltip-${rowDetails.id}`} toolTipContent={
            <div className="inside-tooltip">
              Industry Mentor is not receiving feedback requests
            </div>
          }>
            <i className="fa fa-question-circle" style={{ cursor: 'pointer' }}/>
          </SimpleToolTipWrapper>
        </> :
        isFeedbackRequestsPaused ?
          <>0 (<span className="text-warning">Paused</span>)&nbsp;
            <SimpleToolTipWrapper id={`tooltip-${rowDetails.id}`} toolTipContent={
              <div className="inside-tooltip">
                Industry Mentor is not receiving feedback requests until {moment(rowDetails.pauseEndDate.date).format('MM/DD/YYYY')}
              </div>
            }>
              <i className="fa fa-question-circle" style={{ cursor: 'pointer' }}/>
            </SimpleToolTipWrapper>
          </> :
          <>{rowDetails.frequencyInWeeks}</>}
    </td>
  );
};

FrequencyInWeeksColumn.propTypes = {
  rowData: PropTypes.object.isRequired,
};

export default FrequencyInWeeksColumn;
