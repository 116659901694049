const frequencyOptions = [
  {
    value: '1',
    text: '1 request per week',
  },
  {
    value: '2',
    text: '2 requests per week',
  },
  {
    value: '3',
    text: '3 requests per week',
  },
  {
    value: '4',
    text: '4 requests per week',
  },
  {
    value: '5',
    text: '5 requests per week',
  },
  {
    value: '6',
    text: '6 requests per week',
  },
  {
    value: '7',
    text: '7 requests per week',
  },
  {
    value: '8',
    text: '8 requests per week',
  },
  {
    value: '9',
    text: '9 requests per week',
  },
  {
    value: '10',
    text: '10 requests per week',
  },
  {
    value: '11',
    text: '11 requests per week',
  },
  {
    value: '12',
    text: '12 requests per week',
  },
  {
    value: '13',
    text: '13 requests per week',
  },
  {
    value: '14',
    text: '14 requests per week',
  },
  {
    value: '15',
    text: '15 requests per week',
  },
  {
    value: '16',
    text: '16 requests per week',
  },
  {
    value: '17',
    text: '17 requests per week',
  },
  {
    value: '18',
    text: '18 requests per week',
  },
  {
    value: '19',
    text: '19 requests per week',
  },

  {
    value: '20',
    text: '20 requests per week',
  },
  {
    value: '21',
    text: '21 requests per week',
  },
  {
    value: '22',
    text: '22 requests per week',
  },
  {
    value: '23',
    text: '23 requests per week',
  },
  {
    value: '24',
    text: '24 requests per week',
  },
  {
    value: '25',
    text: '25 requests per week',
  },
  {
    value: '26',
    text: '26 requests per week',
  },
  {
    value: '27',
    text: '27 requests per week',
  },
  {
    value: '28',
    text: '28 requests per week',
  },
  {
    value: '29',
    text: '29 requests per week',
  },
  {
    value: '30',
    text: '30 requests per week',
  },
];

export default frequencyOptions;