import React from 'react';

import PropTypes from 'prop-types';

import PraiseCount from './PraiseCount';
import { SimpleToolTipWrapper } from '../common/ToolTipWrapper';

const TopPraises = ({ topPraises }) => {

  return (
    <div className="top-praises">
      <div className="top-praises__title">
        <p className="d-inline-block font-weight-bold mb-0">Top Praises</p>
      </div>
      <div className="d-flex row flex-wrap align-items-center">
        <p className="top-praises__subtext col-12 col-md-auto mb-2 mb-md-0">People say your feedback is...</p>
        {topPraises && topPraises.map((topPraise, index) => {
          const praiseCountTitle = `topPraisefor-${index}`;
          return (
            <div className="d-flex justify-content-between col-6 col-md-auto" key={index}>
              <SimpleToolTipWrapper
                id={`feedback-top-praises-${index}`}
                toolTipContent={
                  <p>{topPraise.praiseDescription}</p>
                }>
                <p
                  className="d-inline-block topPraisesLabel">
                  {topPraise.praiseTitle}
                </p>
              </SimpleToolTipWrapper>
              <PraiseCount praiseCount={topPraise.totalPraiseCount} title={praiseCountTitle} userPraises={topPraise.praiseGivenByUsers}/>
            </div>
          );
        })}
      </div>
    </div>
  );
};

TopPraises.propTypes = {
  topPraises: PropTypes.array,
};

export default TopPraises;
