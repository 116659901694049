import initialState from './initialState';

import {
  EDUCATION_REQUEST,
  EDUCATION_SUCCESS,
  EDUCATION_FAILURE,
  CREATE_EDUCATION_REQUEST,
  CREATE_EDUCATION_SUCCESS,
  CREATE_EDUCATION_FAILURE,
  UPDATE_EDUCATION_REQUEST,
  UPDATE_EDUCATION_SUCCESS,
  UPDATE_EDUCATION_FAILURE,
  DELETE_EDUCATION_REQUEST,
  DELETE_EDUCATION_SUCCESS,
  DELETE_EDUCATION_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.education, action) {
  switch (action.type) {
  case EDUCATION_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case EDUCATION_SUCCESS:
    return Object.assign({}, state, {
      data: action.data._embedded.education,
      isRequesting: false,
    });

  case EDUCATION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case CREATE_EDUCATION_REQUEST:
    return Object.assign({}, state, { isSubmitting: true });

  case CREATE_EDUCATION_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data, Object.assign({}, action.data)],
      isSubmitting: false,
    });

  case CREATE_EDUCATION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case UPDATE_EDUCATION_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, educationIndex: action.index },
    });

  case UPDATE_EDUCATION_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, state.isUpdating.educationIndex),
        Object.assign({}, action.data),
        ...state.data.slice(state.isUpdating.educationIndex + 1),
      ],
      isUpdating: { status: false, educationIndex: null },
    });

  case UPDATE_EDUCATION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false, educationIndex: null },
    });

  case DELETE_EDUCATION_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: action.id },
    });

  case DELETE_EDUCATION_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.filter(
          education => education.id !== state.isDeleting.id
        ),
      ],
      isDeleting: { status: false, id: null },
    });

  case DELETE_EDUCATION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: { status: false, id: null },
    });

  default:
    return state;
  }
}
