import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useNavigate, useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';

import { DndProvider } from 'react-dnd';
// import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';

const App = ({ children, isAuthenticated }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let nextLocation = location.pathname;

    if (isAuthenticated) {
      if (location.pathname === '/') {
        nextLocation = '/home';

        navigate(nextLocation, { replace: true });
      }
    }
  });

  return (
    <div>
      <Helmet
        titleTemplate="%s - CareerPrepped"
        defaultTitle="CareerPrepped"/>

      <DndProvider backend={HTML5Backend}>
        {children}
      </DndProvider>
    </div>
  );
};

App.propTypes = {
  children: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

const mapStateToProps = ({ auth: { isAuthenticated } }) => ({ isAuthenticated });

export default connect(mapStateToProps)(App);
