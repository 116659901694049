import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withHooks } from '../../../utils/withHooks';

import { profileRequest } from '../../../redux/actions/profile';
import { getEducatorGroups } from '../../../redux/actions/educators';

import { Link, Outlet } from 'react-router-dom';
import { Row, Col, Container } from 'reactstrap';

import Loading from '../../../components/common/Loading';

class EducatorPageWrapper extends Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    actions: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    groupsCount: PropTypes.number.isRequired,
    isAuthenticated: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      isRequestingData: false
    };
  }

  componentWillMount() {
    const { userId, actions, isAuthenticated } = this.props;

    if (isAuthenticated) {
      this.setState({ isRequestingData: true });

      actions.profileRequest(userId);
      actions.getEducatorGroups(true, '')
        .then(() => this.setState({ isRequestingData: false }));
    }
  }

  render() {
    const { groupsCount, profile: { name, organization } } = this.props;

    if (this.state.isRequestingData) {
      return (
        <div className="loading-container">
          <Loading/>
        </div>
      );
    }

    return (
      <div>
        <div className="admin-navbar admin-panel" style={{ padding: 16 }}>
          <Container>
            <Row>
              <Col lg="8" md="7">
                <h2 className="mb-0">{`${organization} | ${name}`}</h2>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="admin-navbar">
          <Container>
            <Row>
              <Col lg="12">
                {(groupsCount !== 0) &&
                  <Link
                    to="/educator/groups"
                    className={location.pathname === '/educator/groups' ? 'active' : ''}>
                    Dashboard
                  </Link>}
                <Link
                  to="/educator/groups/manage"
                  className={
                    (location.pathname === '/educator/groups/manage' || location.pathname === '/educator/groups/create') ?
                      'active' : ''
                  }>
                  Manage Groups
                </Link>
                {(groupsCount !== 0) &&
                  <Link
                    to="/educator/groups/resource-center"
                    className={location.pathname === '/educator/groups/resource-center' ? 'active' : ''}>
                    Resource Center
                  </Link>}
              </Col>
            </Row>
          </Container>
        </div>

        <Outlet/>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    profile: { data: profile },
    auth: { isAuthenticated, data: { currentUser: { id: userId } } },
    educators: { groups: groupsList }
  } = state;

  const allGroups = groupsList.filter(group => group.id !== 'all-groups');
  const { length: groupsCount } = allGroups;

  return {
    userId,
    profile,
    groupsCount,
    isAuthenticated
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    profileRequest,
    getEducatorGroups
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(EducatorPageWrapper));
