/* eslint-disable import/default */
import '@babel/polyfill';
import React from 'react';
import { render } from 'react-dom';
// import Perf from 'react-addons-perf';
import configRoutes from './routes';
import ScrollToTop from './scroll-to-top';
import LoadingView from './containers/layout/LoadingView';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './redux/store/configureStore';
import ReduxToastr from 'react-redux-toastr';
import Favicon from 'react-favicon';
import { syncStore } from './redux/actions/components';
import { redirect } from './redux/actions/router';
import { clearUserReducers } from './redux/actions/authentication';

const favicon = require('./assets/images/favicon.png');

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/index.scss';
import '../node_modules/photoswipe/dist/photoswipe.css';
import '../node_modules/photoswipe/dist/default-skin/default-skin.css';
import '../node_modules/react-redux-toastr/src/styles/index.scss';
import '../node_modules/slick-carousel/slick/slick.css';
import '../node_modules/slick-carousel/slick/slick-theme.css';
import '../node_modules/animate.css/animate.min.css';
import 'react-quill/dist/quill.snow.css';

const {store, persistor} = configureStore();

export default store;

// window.Perf = Perf;

// if (process.env.NODE_ENV !== 'production') {
//   const {whyDidYouUpdate} = require('why-did-you-update');
//   whyDidYouUpdate(React);
// }

// Perf.start();
const reHydrateStore = (currentState) => {
  const data = localStorage.getItem('persist:root');
  if (data) {
    const parsedData = JSON.parse(data);
    let hydratedStateParsed = {...currentState};
    if (typeof parsedData === 'object') {
      Object.keys(parsedData).forEach((key) => {
        if (key === '_persist') return;
        hydratedStateParsed[key] = JSON.parse(parsedData[key]);
      });
    }
    return hydratedStateParsed;
  }
  return undefined;
};

window.addEventListener('storage', (event) => {
  const { key, newValue } = event;
  const currentState = store.getState();
  const hydratedState = reHydrateStore(currentState);
  const currentUserId = hydratedState && hydratedState.auth.data.currentUser.id;
  const tokenhasBeenWiped = key === 'careerPrepped.auth.token' && newValue === null;

  if(tokenhasBeenWiped){
    store.dispatch(clearUserReducers(currentUserId));
    const [,path, subpath] = location.pathname.split('/');
    const unAuthDomains = ['skill-builders', 'cp', 'auth'];
    const inSkillBadgesNotLoggedIn = path === 'skill-badges' && subpath;
    const inPortfolioNotLoggedIn = path === 'portfolio' && subpath;

    if (!unAuthDomains.includes(path) &&
    !inSkillBadgesNotLoggedIn && !inPortfolioNotLoggedIn ){
      store.dispatch(redirect('/reenter-credentials', location.pathname));
    }
  }

  // If isAuthenticated has changed Rehydrate state by using store.dispatch
  if((hydratedState.auth.isAuthenticated !== currentState.auth.isAuthenticated) ||
  (hydratedState.auth.data.currentUser.id !== currentState.auth.data.currentUser.id)){
    if((hydratedState.auth.data.currentUser.id !==
        currentState.auth.data.currentUser.id && hydratedState.auth.isAuthenticated )){
      store.dispatch(redirect('/home'));
    }

    store.dispatch(syncStore(hydratedState));
  }

}, false);

document.addEventListener('visibilitychange', () => {

  if (document.visibilityState === 'visible') {
    const currentState = store.getState();
    let newState = reHydrateStore(currentState);

    // If isAuthenticated has changed Rehydrate state by using store.dispatch
    if((newState.auth.isAuthenticated !== currentState.auth.isAuthenticated) ||
    (newState.auth.data.currentUser.id !== currentState.auth.data.currentUser.id)){
      delete newState._persist;

      if((newState.auth.data.currentUser.id !==
          currentState.auth.data.currentUser.id && newState.auth.isAuthenticated )){
        store.dispatch(redirect('/home'));
      }

      store.dispatch(syncStore(newState));
    }
  }
}, false);

render(
  <div>
    <Favicon url={favicon} />
    <Provider store={store}>
      <PersistGate loading={<LoadingView/>} persistor={persistor}>
        <div>
          <BrowserRouter>
            <ScrollToTop/>
            {configRoutes(store)}
          </BrowserRouter>
          <ReduxToastr
            transitionIn="fadeIn"
            transitionOut="fadeOut"/>
        </div>
      </PersistGate>
    </Provider>
  </div>,
  document.getElementById('app')
);
// Perf.stop();
// Perf.printWasted();
