import PropTypes from 'prop-types';
import React from 'react';
import Loading from '../../common/Loading';
import BulkActions from './BulkActions';
import FollowerCardRenderer from './FollowerCardRenderer';
import SideMenuLinks from './SideMenuLinks';

const FollowersList = ({
  profile,
  followers,
  selectFollower,
  toggleModal,
  onFollowClick,
  onUnFollowClick,
  selected,
  selectAllClick,
  allSelected,
  onNext,
  onPrev,
  onSelectPage,
  page,
  page_count
}) => {

  const { isRequesting } = followers;

  return (
    <div className="interior">
      <div className="content follow">
        <div className="row">
          <div className="col-lg-12">
            <h2>Followers</h2>
            <SideMenuLinks
              profile={profile}
              isFollowerActive/>
          </div>
          <div className="col-lg-12">
            <BulkActions
              toggleModal={toggleModal}
              onFollowClick={onFollowClick}
              onUnFollowClick={onUnFollowClick}
              selectAllClick={selectAllClick}
              allSelected={allSelected} />
            {
              isRequesting ?
                <Loading /> 
                :
                <FollowerCardRenderer
                  onNext={onNext}
                  onPrev={onPrev}
                  onSelectPage={onSelectPage}
                  page={page}
                  page_count={page_count}
                  followers={followers}
                  selectFollower={selectFollower}
                  selected={selected} />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

FollowersList.propTypes = {
  followers: PropTypes.object.isRequired,
  profile: PropTypes.object,
  selectFollower: PropTypes.func,
  toggleModal: PropTypes.func,
  onFollowClick: PropTypes.func,
  onUnFollowClick: PropTypes.func,
  selected: PropTypes.array,
  selectAllClick: PropTypes.func,
  allSelected: PropTypes.bool,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  onSelectPage: PropTypes.func.isRequired,
  page: PropTypes.number,
  page_count: PropTypes.number
};

export default FollowersList;
