import React, { useState } from 'react';

import PropTypes from 'prop-types';
import moment from 'moment';
import _isUndefined from 'lodash/isUndefined';
import { SimpleToolTipWrapper } from '../../components/common/ToolTipWrapper';

const UnitBadgeEndorsements = ({ isBadgeOwner, endorsements, onToggleEndorseRequestModal, issuedBadgeId, lastEndorsementUploadedDate, endorsementCount, skillEvidenceView}) => {
  const endorsementsList = ! _isUndefined(endorsements) ? endorsements.filter(endorsement => endorsement.name.length !== 0) : [];
  const condition = endorsementsList.length > 0;
  const [showAllEndorsements, setShowAllEndorsements] = useState(false);

  function toggleShowMoreEndorsements() {
    setShowAllEndorsements(!showAllEndorsements);
  }

  return (
    <div className="section-badge">
      {skillEvidenceView ? (<h6 className="d-flex">
        <span>Endorsements</span>
        <SimpleToolTipWrapper
          id={`last_endorsement-${issuedBadgeId}`}
          toolTipContent={<p>{`Last Endorsement:  ${lastEndorsementUploadedDate}`}</p>}>
          <p className="stat-number mb-0 ml-2">
            ({endorsementCount})
          </p>
        </SimpleToolTipWrapper>
      </h6>): <h6>Endorsements</h6>
      }
      <div className="section-badge-content">
        <div className="row">
          <div className="col-md-12">
            <span className={`subtitle ${skillEvidenceView ? '' : 'badgeOrangelink'}`}>
              {isBadgeOwner &&
              <div
                onClick={onToggleEndorseRequestModal}
                className="btn btn-tertiary btn-sm mb-2">Seek Endorsements</div>}
            </span>
            {!condition &&
            <div className="mt-1">
              <p className="no-badge-content">No one has endorsed yet!</p>
            </div>}

            {condition &&
            <ul className="list-unstyled mb-0 p-0">
              {endorsementsList.map((item, index) =>(
                (skillEvidenceView ?
                  ( index < 2 ) ?
                    <li className="font14" key={index}>{`${item.name}, ${item.position}, ${item.organization}`} <span className="font14">- {item.endorsement_date?.date && moment(item.endorsement_date?.date).format('MM/DD/YYYY')}</span></li>
                    :
                    <div key={index} style={{display: showAllEndorsements ? 'block' : 'none'}}>
                      <li className="font14" key={index}>{`${item.name}, ${item.position}, ${item.organization}`} <span className="font14">- {item.endorsement_date?.date && moment(item.endorsement_date?.date).format('MM/DD/YYYY')}</span></li>
                    </div>
                  : <li className="font14" key={index}>{`${item.name}, ${item.position}, ${item.organization}`} <span className="font14">- {item.endorsement_date?.date && moment(item.endorsement_date?.date).format('MM/DD/YYYY')}</span></li>
                )
              ))}
            </ul>}
            {skillEvidenceView && (endorsementCount - 2 > 0) &&
              (<div
                className="section-showmorelink border-top"
                onClick={toggleShowMoreEndorsements}>
                Show ({endorsementCount - 2}) {showAllEndorsements ? 'less' : 'more'} endorsements
              </div>)}
          </div>
        </div>
      </div>
    </div>
  );
};

UnitBadgeEndorsements.propTypes = {
  endorsements: PropTypes.array,
  user: PropTypes.object,
  isBadgeOwner: PropTypes.bool,
  onClickEndorse: PropTypes.func,
  onToggleEndorseRequestModal: PropTypes.func,
  issuedBadgeId: PropTypes.string,
  lastEndorsementUploadedDate: PropTypes.string,
  endorsementCount: PropTypes.number,
  skillEvidenceView: PropTypes.bool
};

export default UnitBadgeEndorsements;
