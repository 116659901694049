import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getIRList, clearIRList } from '../../redux/actions/irs';
import {
  clearMyBadges,
  clearMySkills,
  getMyBadges,
  getMySkills,
  getRatings,
  getMySkillsPage,
} from '../../redux/actions/myskills';
import { getEducators } from '../../redux/actions/educators';
import { industryRepresentativesRequest } from '../../redux/actions/industry-representatives';
import { sendFeedbackRequest } from '../../redux/actions/feedback';

import {
  selectFormattedIndustryRepresentatives,
  selectIndustryRepresentativesPageCount
} from '../../redux/selectors/industry-representatives';

import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  NavItem,
  NavLink,
  Nav,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardTitle,
} from 'reactstrap';
import { toastr } from 'react-redux-toastr';

import {
  FEEDBACK_HEADER,
  REVIEWERS_ADDED,
  ASK_FOR_REVIEW,
  ASK_IR_REVIEW,
  SELECT_BADGE_HEADER,
  SELECT_REVIEWER_PLACEHOLDER,
  CHOOSE_SKILL_CLAIM,
} from './constants';
import {
  REMOVE_ALL,
  SEARCH,
  PORTFOLIO_FILES,
  NEXT,
  BACK,
  CANCEL,
} from '../../utilConstants';
import { getSkillsRating } from '../../utils';

import { isEmpty } from 'lodash';

import CloseIcon from '../../assets/images/close.svg';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import InputGroup from 'reactstrap/lib/InputGroup';
import { AsyncPaginate } from 'react-select-async-paginate';

import StarRating from '../../components/rating/StarRating';
import BadgesSkillsList from './BadgesSkillsList';
import AsyncPaginateOption from '../../components/myskills/AsyncPaginateOption';

const prefixWithZero = (num) => {
  return Number(num)?.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
};

const colourStyles = {
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#B2D4FF' : 'transparent'
    };
  }
};

class RequestFeedbackModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: 'Sara',
      skillName: 'Communicate Effectively on the phone',
      adminTags: [],
      industryRepTags: [],
      isTwoStep: props.isTwoStep || false,
      currentStep: props.isTwoStep === true ? 1 : 2,
      user: null,
      badgeIssuedId: null,
      assertionBadge: null,
      selectedSkillBadgeClass: null,
      inputvalue: '',
      isRequesting: false,
      resetPage: false,
      requestBtnDisabled: true,
      requestBtnText: 'Request Feedback',
    };

    this.removeAdminTags = this.removeAdminTags.bind(this);
    this.clearAdmin = this.clearAdmin.bind(this);
    this.clearIR = this.clearIR.bind(this);
    this.removeIRTags = this.removeIRTags.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.loadAdminOptions = this.loadAdminOptions.bind(this);
  }

  componentDidMount() {
    if (this.state.currentStep === 1) {
      this.getSkillBadgesWithRatings();
    }
    if (this.state.currentStep === 2) {
      this.loadDataForStepTwo();
    }
  }

  loadDataForStepTwo = () => {
    this.props.actions.clearIRList();
  };

  async loadAdminOptions(search, loadedOptions, actionMeta) {
    const { page, name: inputName } = actionMeta;
    const institutionId = isEmpty(this.props.selectedInstitution) ?
      this.props.institutionId :
      this.props.selectedInstitution.id;

    let endpoint = `institution/educators?page=${page}&request_for_user=${this.getUser().id}&institution_id=${institutionId}`;

    if (search) {
      endpoint = `${endpoint}&query=${search}`;
    }

    (inputName === 'admin') ?
      await this.props.actions.getEducators(endpoint) :
      await this.props.actions.industryRepresentativesRequest(page, search);

    const options = (inputName === 'admin') ?
      this.props.educators :
      this.props.industryRepresentatives;

    const pageCount = (inputName === 'admin') ?
      this.props.educatorsPageCount :
      this.props.industryRepsPageCount;

    return {
      options,
      hasMore: pageCount > page,
      additional: { page: page + 1, name: inputName }
    };
  }

  onChange(value, actionMeta) {
    const { name: inputName } = actionMeta;
    const statePropertyName = inputName === 'admin' ? 'industryRep' : inputName;

    const reviewers = [...value, ...this.state[`${statePropertyName}Tags`]];

    if (reviewers.length > 10) return;

    this.setState({
      [`${inputName}Tags`]: [...value],
      requestBtnDisabled: (reviewers.length === 0)
    });
  }

  previous = () => {
    this.clearSelectedSkillBadgeClass();
    this.setState(state => ({ currentStep: state.currentStep - 1 }));
  };

  next = () => {
    this.clearAdmin();
    this.clearIR();
    this.setState(state => ({ currentStep: state.currentStep + 1 }));
    this.loadDataForStepTwo();
  };

  inputStringonChange = (e) => {
    this.setState({
      inputvalue: e.target.value,
      resetPage: false,
    }, () => {
      if (isEmpty(this.state.inputvalue)) {
        this.setState({ resetPage: true });
        this.searchSkillBadge(true);
      }
    });
  };

  clearSelectedSkillBadgeClass = () => {
    this.setState({
      selectedSkillBadgeClass: null,
    });
  };

  radioOnChange = (event) => {
    const skillId = event.target.value;

    const skill = this.props.skillBadgeList.find(skill =>
      (skill?.skill_id ?? skill?.badge_id) === skillId);

    let selectedSkillBadgeClass = null;

    if (skill.skill_id !== null && skill.filesCount > 0 || skill.skill_id === null) {
      selectedSkillBadgeClass = skill;
    }

    this.setState({ selectedSkillBadgeClass });
  };


  handleClickBtn = (event) => {
    event.target.checked = false;
    toastr.info(
      '',
      'This skill claim has no evidence to be reviewed for feedback yet. Select a skill claim with evidence to be reviewed.'
    );
  };

  handleClickBtnReturn = () => {
    return true;
  };

  clearAdmin() {
    this.setState({
      adminTags: [],
      requestBtnDisabled: this.state.industryRepTags.length === 0
    });
  }

  clearIR() {
    this.setState({
      industryRepTags: [],
      requestBtnDisabled: this.state.adminTags.length === 0
    });
  }

  getSelectCount() {
    const selectCount =
      this.state.adminTags.length + this.state.industryRepTags.length || 0;

    if (!selectCount) {
      return (
        <div className={`radialProgressBar progress-${selectCount}`}>
          <div className="overlay-0">{selectCount}</div>
        </div>
      );
    }

    return (
      <div className={`radialProgressBar progress-${selectCount}`}>
        <div className="overlay">{selectCount}</div>
      </div>
    );
  }

  removeAdminTags(event) {
    const adminTag = event.target.getAttribute('data-value');
    const arr = this.state.adminTags.filter(tag => tag.value !== adminTag);
    const reviewers = [...arr, ...this.state.industryRepTags];

    this.setState({ adminTags: [...arr], requestBtnDisabled: (reviewers.length === 0) });
  }

  removeIRTags(event) {
    const irTag = event.target.getAttribute('data-value');
    const arr = this.state.industryRepTags.filter(t => t.value !== irTag);
    const reviewers = [...arr, ...this.state.adminTags];

    this.setState({ industryRepTags: [...arr], requestBtnDisabled: (reviewers.length === 0) });
  }

  onSubmit() {
    let payload = {
      admins: this.state.adminTags.map(adminTag => adminTag.value),
      irs: this.state.industryRepTags.map(irTag => irTag.value),
      request_for_user_id: this.getUser().id,
    };

    if (this.getBadgeIssuedId()) {
      payload['badge_issued_id'] = this.getBadgeIssuedId();
    } else if (this.getSkillId()) {
      payload['skill_id'] = this.getSkillId();
    }

    this.setState({
      requestBtnDisabled: true,
      requestBtnText: 'Requesting...',
    });

    this.props.actions.sendFeedbackRequest(payload).then(() => {
      toastr.success('The Feedback request sent successfully.');
      this.clearAdmin();
      this.clearIR();
      this.props.onCancel();
    });
  }

  getUserId() {
    return this.props.userDataForFeedbackRequest?.userId;
  }

  getSkillBadgesWithRatings = () => {
    const { actions } = this.props;
    actions.clearMySkills();
    actions.clearMyBadges();

    this.getMySkills(null, null, 1, 'skill');
    this.getMySkills(null, null, 1, 'badge');
  };

  getMySkills = async (links, filter = null, page = 1, resource = null) => {
    let { resourceTypeParam } = this.state;
    if (resource) {
      resourceTypeParam = resource;
    }
    this.setState({ isRequesting: true });
    const skillsList = await this.props.actions.getMySkillsPage(this.getUserId(), links, filter, resourceTypeParam, page);
    this.setState({ isRequesting: false });
    return skillsList;
  };

  searchSkillBadge = (resetSearch = false) => {
    const { inputvalue } = this.state;
    const { actions } = this.props;
    if (!resetSearch && (isEmpty(inputvalue) || inputvalue.length === 1)) {
      return;
    }
    this.setState({ resetPage: true });
    actions.clearMySkills();
    actions.clearMyBadges();
    const queryParam = `&search=${this.state.inputvalue}`;
    this.getMySkills(null, queryParam, 1, 'skill');
    this.getMySkills(null, queryParam, 1, 'badge');
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.searchSkillBadge();
      return true;
    }
  }

  stepTwoBody = () => {
    return (
      <>
        <Row className="mt-3 mb-4">
          <Col sm="8" md="8" lg="8">
            <span className="font14 fontW600">{FEEDBACK_HEADER}</span>
          </Col>
          <Col sm="4" md="4" lg="4" className="pl-0">
            <div className="progressBar-wrapper">
              {this.getSelectCount()}
              <p className="font12 mb-0">{REVIEWERS_ADDED}</p>
            </div>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col md="10" className="font14 mb-6">
            {ASK_FOR_REVIEW}
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <AsyncPaginate
              isMulti
              cacheOptions
              isSearchable
              name="admin"
              additional={{ page: 1, name: 'admin' }}
              isClearable={false}
              loadOptions={this.loadAdminOptions}
              styles={colourStyles}
              placeholder={SELECT_REVIEWER_PLACEHOLDER}
              className="multiselect-container multiSelectContainer"
              components={{ Option: AsyncPaginateOption }}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              value={this.state.adminTags}
              controlShouldRenderValue={false}
              onChange={this.onChange} />
          </Col>
        </Row>

        <div className="mb-1">
          {this.state.adminTags.map(adminTag => (
            <span key={`admin${adminTag.value}`} className="d-inline-block reqtag">
              {adminTag.label}
              <i
                className="fa fa-close pointer"
                style={{ marginLeft: '5px' }}
                data-value={adminTag.value}
                onClick={this.removeAdminTags} />
            </span>
          ))}
          {this.state.adminTags.length > 1 && (
            <Nav>
              <NavItem>
                <NavLink className="pl-0" onClick={this.clearAdmin} href="#">
                  <small>
                    <u> {REMOVE_ALL} </u>
                  </small>
                </NavLink>
              </NavItem>
            </Nav>
          )}
        </div>
        <br />
        <Row>
          <Col md="10" className="font14 mb-6">
            {ASK_IR_REVIEW}
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <AsyncPaginate
              isMulti
              cacheOptions
              isSearchable
              name="industryRep"
              additional={{ page: 1, name: 'industryRep' }}
              isClearable={false}
              loadOptions={this.loadAdminOptions}
              styles={colourStyles}
              placeholder={SELECT_REVIEWER_PLACEHOLDER}
              className="multiselect-container multiSelectContainer"
              components={{ Option: AsyncPaginateOption }}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              value={this.state.industryRepTags}
              controlShouldRenderValue={false}
              onChange={this.onChange} />
          </Col>
        </Row>

        <div className="mb-3">
          {this.state.industryRepTags.map(industryRepTag => (
            <span key={industryRepTag.value} className="d-inline-block reqtag">
              {industryRepTag.label}
              <i
                className="fa fa-close pointer"
                style={{ marginLeft: '5px' }}
                data-value={industryRepTag.value}
                onClick={this.removeIRTags} />
            </span>
          ))}
          {this.state.industryRepTags.length > 1 && (
            <Nav>
              <NavItem>
                <NavLink className="pl-0" onClick={this.clearIR} href="#">
                  <small>
                    <u> {REMOVE_ALL} </u>
                  </small>
                </NavLink>
              </NavItem>
            </Nav>
          )}
        </div>
      </>
    );
  };

  stepOneBody = () => {
    const filteredResult = this.props.skillBadgeList;

    return (
      <>
        <p style={{ marginTop: '2%' }}> {CHOOSE_SKILL_CLAIM}</p>
        <div className="feedback-search">
          <Row>
            <Col md="7" className="pr-md-0">
              <FormGroup>
                <InputGroup>
                  <Input
                    type="text"
                    name="search"
                    id="search"
                    value={this.state.inputvalue}
                    placeholder={SEARCH}
                    onChange={this.inputStringonChange}
                    onKeyPress={this.handleKeyPress} />
                  <InputGroupAddon className="pointer" addonType="append" onClick={this.searchSkillBadge}>
                    <i className="fa fa-search" />
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div id="scrollableDiv" style={{ height: 300 }}>
          <BadgesSkillsList
            filteredResultsLength={filteredResult.length}
            getMySkills={this.getMySkills}
            badgesTotalPage={this.props.badgesTotalPage}
            skillsTotalPage={this.props.skillsTotalPage}
            searchValue={this.state.inputvalue}
            isRequesting={this.state.isRequesting}
            resetPage={this.state.resetPage}>
            {filteredResult &&
              filteredResult.map((data, key) => {
                const endorsementCount = this.getRatings(data.skill_id || data.issuedID);
                return (
                  <Card key={key}>
                    <CardBody className="pb-0 px-0 customPadding d-flex">
                      <div className="d-flex justify-content-center giveFeedbackContainerStyle">
                        <FormGroup className="custom-radio-wrapper form-check pl-0">
                          <Label check className="container">
                            <Input
                              className="mr-1"
                              type="radio"
                              onChange={this.radioOnChange}
                              onClick={
                                data.skill_id && data.filesCount === 0
                                  ? this.handleClickBtn
                                  : this.handleClickBtnReturn
                              }
                              value={data.skill_id ?? data.badge_id}
                              checked={
                                (data.skill_id ?? data.badge_id) ===
                                (this.state.selectedSkillBadgeClass?.skill_id ??
                                  this.state.selectedSkillBadgeClass?.badge_id)
                              }
                              name={`selectskill-${data.skill_id ?? data.badge_id}`} />
                            <span className="checkmark" />
                          </Label>
                        </FormGroup>
                        <div className="darkBlueImage mx-3">
                          <img
                            style={{ height: '60px', width: 'auto' }}
                            src={data.imageURL} />
                        </div>
                      </div>
                      <div>
                        <CardTitle tag="h5">{data.name}</CardTitle>
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            <div id="Popover1">
                              <StarRating rating={data.avgRating} endorsementCount={parseInt(endorsementCount?.endorsementCount)} />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="counts">
                            <span>
                              <strong className="font14">
                                {prefixWithZero(data.filesCount)}{' '}
                              </strong>
                            </span>
                            <p className="d-inline-block"> {PORTFOLIO_FILES}</p>
                          </div>
                          <div className="counts">
                            <span>
                              <strong className="font14 ">
                                {prefixWithZero(data.endorsementCount)}{' '}
                              </strong>
                            </span>
                            <p className="d-inline-block"> Endorsements</p>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                );
              })}
          </BadgesSkillsList>
        </div>
      </>
    );
  };

  stepTwoTitle = () => {
    return (
      <span className="modal-title">
        Request Feedback for {this.getUser()?.name}'s{' '}
        {this.getBadgeIssuedId() ? 'Badge' : 'Skill'} -{' '}
        {this.getAssertionBadge()?.name}
      </span>
    );
  };

  getUser = () => {
    return this.props.userDataForFeedbackRequest?.userData ?? this.props.user;
  };

  getBadgeIssuedId = () => {
    return (
      this.state.selectedSkillBadgeClass?.issuedID ?? this.props.badgeIssuedId
    );
  };

  getSkillId = () => {
    return this.state.selectedSkillBadgeClass?.skill_id ?? this.props.skillId;
  };

  getAssertionBadge = () => {
    return this.state.selectedSkillBadgeClass ?? this.props.assertionBadge;
  };

  stepOneTitle = () => {
    return (
      <span className="modal-title">
        {SELECT_BADGE_HEADER(this.getUser()?.name)}
      </span>
    );
  };

  getRatings = (id) => {
    return (
      (this.props.skillBadgeList &&
        this.props.skillBadgeList.find(
          r => r?.skillId === id || r?.badgeId === id
        )) || {
        endorsementCount: 0,
        endorsements: 0,
        endorsementDate: null,
        portfolioFileCount: 0,
        questionRespRating: 0,
        porfolioRating: 0,
        final_score: 0,
      }
    );
  };

  getStarRating(data, showAvgRating = true) {
    if (showAvgRating) {
      return getSkillsRating(this.getRatings(data.skill_id || data.issuedID));
    }
  }

  render() {
    return (
      <Modal
        backdrop="static"
        className="modal-md customLevelUpModal"
        isOpen={this.props.isOpen}
        toggle={this.props.onCancel}>

        <div className="modal-header">
          {this.state.currentStep === 1 && this.stepOneTitle()}
          {this.state.currentStep === 2 && this.stepTwoTitle()}
          <button
            onClick={this.props.onCancel}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">
              {' '}
              <img src={CloseIcon} />{' '}
            </span>
          </button>
        </div>
        <ModalBody className="preview-resume-modal-body">
          {this.state.currentStep === 2 && this.stepTwoBody()}
          {this.state.currentStep === 1 && this.stepOneBody()}
        </ModalBody>
        <ModalFooter className="bordertop-0">
          {this.state.currentStep === 1 && (
            <>
              <Button
                className="ml-1 sendButton"
                color="warning"
                disabled={this.state.selectedSkillBadgeClass === null}
                onClick={this.next}>
                {NEXT}
              </Button>
            </>
          )}
          {this.state.currentStep === 2 && (
            <>
              {this.state.isTwoStep === true ? (
                <button
                  className="btn btn-secondary"
                  onClick={this.previous}>
                  {BACK}
                </button>
              ) : (
                <button
                  className="btn btn-secondary"
                  onClick={this.props.onCancel}>
                  {CANCEL}
                </button>
              )}
              <Button
                className="ml-1 sendButton"
                color="warning"
                disabled={this.state.requestBtnDisabled}
                onClick={this.onSubmit}>
                {this.state.requestBtnText}
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

RequestFeedbackModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  irs: PropTypes.array.isRequired,
  isTwoStep: PropTypes.bool,
  adminInstitutions: PropTypes.array,
  selectedInstitution: PropTypes.object,
  userDataForFeedbackRequest: PropTypes.object,
  organizationUsers: PropTypes.array.isRequired,
  skillBadgeList: PropTypes.array,
  user: PropTypes.object,
  badgeIssuedId: PropTypes.string,
  skillId: PropTypes.string,
  assertionBadge: PropTypes.object,
  badgesTotalPage: PropTypes.number,
  skillsTotalPage: PropTypes.number,
  educators: PropTypes.array.isRequired,
  institutionId: PropTypes.string,
  educatorsPageCount: PropTypes.number.isRequired,
  industryRepsPageCount: PropTypes.number.isRequired,
  industryRepresentatives: PropTypes.array.isRequired
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getIRList,
      getEducators,
      sendFeedbackRequest,
      clearIRList,
      getMyBadges,
      getMySkills,
      clearMySkills,
      clearMyBadges,
      getRatings,
      getMySkillsPage,
      industryRepresentativesRequest
    },
    dispatch
  ),
});

const mapStateToProps = (state) => {
  let list = [];
  if (state.myskills.data.skills && state.myskills.data.skills.length) {
    list = [...state.myskills.data.skills];
  }
  if (state.myskills.data.badges && state.myskills.data.badges.length) {
    list = [...list, ...state.myskills.data.badges];
  }

  list = [...list, ...state.myskills.data.ratings];

  return {
    irs: state.irs.data,
    currentUser: state.auth.data.currentUser,
    adminInstitutions: state.profile.data.adminInstitutions,
    selectedInstitution: state.institutions.selectedInstitution,
    organizationUsers: state.myskills.data.educators,
    profile: state.profile.data,
    skillsTotalPage: state.myskills.data.skillsTotalPage,
    badgesTotalPage: state.myskills.data.badgesTotalPage,
    skillBadgeList: list,
    skillsTotalItems: state.myskills.data.skillsTotalItems,
    badgesTotalItems: state.myskills.data.badgesTotalItems,
    educatorsPageCount: state.educators.pageCount,
    educators: state.educators.data,
    industryRepsPageCount: selectIndustryRepresentativesPageCount(state),
    industryRepresentatives: selectFormattedIndustryRepresentatives(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestFeedbackModal);
