import PropTypes from 'prop-types';
import React from 'react';
import { Collapse } from 'reactstrap';
import Parser from 'html-react-parser';
import classNames from 'classnames';
import ThinkingBreakCollapseData from './ThinkingBreakCollapseData';

const ThinkingBreakSectionCard = ({
  onClick,
  thinkingBreakCollapse,
  content,
  correct,
  feedback
}) => {
  const data = {
    'correct': correct,
    'feedback': feedback
  };

  return (
    <div className="card">
      <div className="card-header collapsed" role="tab" id="headingOne" onClick={onClick}>
        <span className="float-right">
          <i className={classNames('fa', {
            'fa-chevron-down': !thinkingBreakCollapse,
            'fa-chevron-up': thinkingBreakCollapse
          })}/>
        </span>
        <div style={{marginRight:20}}>
          {Parser(content)}
        </div>
      </div>

      <Collapse isOpen={thinkingBreakCollapse}>
        <ThinkingBreakCollapseData data={data}/>
      </Collapse>
    </div>
  );
};

ThinkingBreakSectionCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  correct: PropTypes.bool.isRequired,
  feedback: PropTypes.string.isRequired,
  thinkingBreakCollapse: PropTypes.bool
};

export default ThinkingBreakSectionCard;
