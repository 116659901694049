import React from 'react';
import { pure } from 'recompose';
import { Link } from 'react-router-dom';

const imgJenny = require('../../../assets/images/homepage/img-testimonial-jennyj.png');
const imgJeremy = require('../../../assets/images/homepage/img-testimonial-jeremyb.png');
const imgSarah = require('../../../assets/images/homepage/img-testimonial-sarahk.png');
const imgHomepageHero = require('../../../assets/images/homepage/individual/img-homepage-hero-solo.png');
const imgHomepageUnleash = require('../../../assets/images/homepage/individual/img-homepage-potential.jpg');
const imgHomepageHighlight = require('../../../assets/images/homepage/individual/img-homepage-highlight.jpg');
const imgHomepageSettle = require('../../../assets/images/homepage/individual/img-homepage-settle.jpg');
const imgHomepageUnlock = require('../../../assets/images/homepage/individual/img-homepage-unlock.jpg');

const Talent = () => {
  return (
    <div className="talent">
      <header>
        <div className="header-content">
          <div className="header-item">
            <div className="header-heading">
              <h1>Showcase your skills. </h1>
              <h1>Secure your dream job. </h1>
            </div>
            <p>Spotlight your skills and take control of your career journey with our all-in-one suite of tools that help you stand out, get hired and get ahead.</p>
            <div className="get-started">
              <Link
                state={{defaultTab: 'for-individuals'}}
                to="/auth/subscription">
                Get Started
              </Link>
            </div>
          </div>
          <div className="header-item">
            <img src={imgHomepageHero} alt="CareerPrepped - Showcase your skills" className="img-fluid d-block mx-auto"/>
          </div>
        </div>
      </header>

      <article className="section white">  
        <div className="container">
          <div className="section-heading">
            <h2>Unleash your potential. </h2>
            <h2>Get the jobs you deserve and never let your skills go unnoticed.</h2>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="section-container">
                <div className="section-container-item order-sm-1">
                  <img src={imgHomepageUnleash} className="img-fluid d-block mx-auto" alt="CareerPrepped - Unleash your potential" />
                </div>
                <div className="section-container-item order-sm-2 p-5">
                  <p>
                    Today, where machines do more work for us and AI makes cookie-cutter resumes for 
                    everyone, it's harder for employers to know a person's true abilities.
                  </p>
                  <p>
                    Plus, as AI takes center stage, human skills like communication and teamwork are now 
                    more crucial in the workplace.
                  </p>
                  <p>
                    You're skilled. Your potential is limitless. But getting employers to see it shouldn't be so hard.  
                  </p>
                  <p>
                    Our tools help you showcase your skills in a way that's impossible to ignore. We make 
                    your value crystal clear to employers, so you can land the opportunities you deserve.
                  </p>
                  <div className="get-started center">
                    <Link
                      state={{defaultTab: 'for-individuals'}}
                      to="/auth/subscription">
                      Get Started
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="section grey">
        <div className="container">
          <div className="section-heading">
            <h2>Highlight your value.</h2>
            <h2>Focus on what matters most - your skills.</h2>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="section-container">
                <div className="section-container-item order-sm-1 order-md-2">
                  <img src={imgHomepageHighlight} alt="CareerPrepped - Highlight your value" className="img-fluid d-block mx-auto" />
                </div>
                <div className="section-container-item order-sm-2 order-md-1 p-5">
                  <p>
                    Showcase all that you offer! Your skills are your ticket to success. Whether gained through life, education, or work, they're your most valuable asset. Make them visible!
                  </p>
                  <p>
                    We help you build, capture, and prove your skills, so you stand out from the crowd. Get feedback from your network and industry pros on the skills you prove to know your strengths and where you need to level up.   
                  </p>
                  <p>
                    Plus, get tools to enhance and highlight your "human skills" – essential skills every employer craves.
                  </p>
                  <p>
                    Start building your lifelong record of skills. Take it with you as you grow your career and keep adding to it as you grow your skills. 
                  </p>
                  <div className="get-started center">
                    <Link
                      state={{defaultTab: 'for-individuals'}}
                      to="/auth/subscription">
                      Get Started
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="section white">  
        <div className="container">
          <div className="section-heading">
            <h2>Don't settle for blending in.</h2>
            <h2>Stand out by going beyond a basic profile or resume.</h2>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="section-container">
                <div className="section-container-item">
                  <img src={imgHomepageSettle} className="img-fluid d-block mx-auto" alt="CareerPrepped - Stand out" />
                </div>
                <div className="section-container-item p-5">
                  <p>
                    Create your own Career Site with CareerPrepped! Unlike a basic profile, your Career Site links to multiple pages to showcase your skills and achievements beyond your education and job history.
                  </p>
                  <p>
                    With just a click, transform your Career Site into an interactive resume. It has clickable links to prove your skills with evidence. Impress employers even before you interview!
                  </p>
                  <p>
                    Connect any of your online profiles to your Career Site. This way, your entire online presence is together in one place. Easily share it with employers!
                  </p>
                  <div className="get-started center">
                    <Link
                      state={{defaultTab: 'for-individuals'}}
                      to="/auth/subscription">
                      Get Started
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="section grey-1">
        <div className="container">
          <div className="section-heading">
            <h2>Unlock new opportunities.</h2>
            <h2>Look beyond traditional job boards and networking sites.</h2>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="section-container">
                <div className="section-container-item order-sm-1 order-md-2">
                  <img src={imgHomepageUnlock} alt="CareerPrepped - Unlock opportunities" className="img-fluid d-block mx-auto" />
                </div>
                <div className="section-container-item order-sm-2 order-md-1 p-5">
                  <p>
                    CareerPrepped's Job Match tool, powered by AI, looks through thousands of job sites to give you personalized job matches that fit your goals and preferences. It helps you discover jobs you might not have known existed.
                  </p>
                  <p>
                    And our Job Tracker tool keeps all your job applications in one spot. Save the jobs you like, jot down notes, and store important contacts. Track every step from applying to and landing the job you want.
                  </p>
                  <div className="get-started center">
                    <Link
                      state={{defaultTab: 'for-individuals'}}
                      to="/auth/subscription">
                      Get Started
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="section blue">
        <div className="container">
          <div className="section-heading">
            <h2>CareerPrepped offers you a complete toolkit to achieve lifelong career success.</h2>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="section-container">
                <div className="section-container-item order-sm-1 order-md-2">
                  <div className="section-bullets">
                    <ul className="fa-ul">
                      <li><span className="fa-li"><i className="fa fa-genderless" /></span>Career Site</li>
                      <li><span className="fa-li"><i className="fa fa-genderless" /></span>Resume Builder</li>
                      <li><span className="fa-li"><i className="fa fa-genderless" /></span>Job Match</li>
                      <li><span className="fa-li"><i className="fa fa-genderless" /></span>Job Tracker</li>
                      <li><span className="fa-li"><i className="fa fa-genderless" /></span>Interview Prep Video Library</li>
                    </ul>  
                    <ul className="fa-ul">
                      <li><span className="fa-li"><i className="fa fa-genderless" /></span>Career Portfolio </li>
                      <li><span className="fa-li"><i className="fa fa-genderless" /></span>Skill Builders for Essential Skills </li>
                      <li><span className="fa-li"><i className="fa fa-genderless" /></span>Skill Badges for Essential Skills </li>
                      <li><span className="fa-li"><i className="fa fa-genderless" /></span>Skill Feedback & Ratings </li>
                      <li><span className="fa-li"><i className="fa fa-genderless" /></span>Skill Endorsements & Testimonials </li>
                    </ul>  
                  </div>
                  <div className="section-action">
                    <div className="get-started toolkit-action center">
                      <Link
                        state={{defaultTab: 'for-individuals'}}
                        to="/toolkit"
                        className="toolkit">
                        Explore the Toolkit
                      </Link>
                      <Link
                        state={{defaultTab: 'for-individuals'}}
                        to="/auth/subscription">
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="testimonials">
        <h2>What Community Members Are Saying.</h2>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-xl-4">
              <div className="testimonial-item">
                <div className="testimonial-item_profile">
                  <img src={imgSarah} className="img-fluid" alt="CareerPrepped helped prove skills"/>
                </div>
                <div className="testimonial-item_quote">
                  CareerPrepped helped me realize if I can prove my skills, it doesn't matter how I got them. The feedback tool helped me understand my strengths, how others view my skills, and how I can improve so I can stand out when applying for jobs.
                </div>
                <div className="testimonial-item_author">
                  Sarah K.
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-sm-12 col-xl-4">
              <div className="testimonial-item">
                <div className="testimonial-item_profile">
                  <img src={imgJeremy} className="img-fluid" alt="CareerPrepped is a one-stop shop"/>
                </div>
                <div className="testimonial-item_quote">
                  I feel like I found a one-stop shop to manage my career. I can capture my skills, show my work samples, get feedback from the community, get job matches, track my job search progress, and more - it's super convenient.
                </div>
                <div className="testimonial-item_author">
                  Jeremy B.
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-sm-12 col-xl-4">
              <div className="testimonial-item">
                <div className="testimonial-item_profile">
                  <img src={imgJenny} className="img-fluid" alt="Careerprepped - Life experiences matter"/>
                </div>
                <div className="testimonial-item_quote">
                  I realized the skills I've developed from life experience matter. The tools are great but the biggest surprise for me was how much my confidence has been boosted. I feel I have more control over my story, my career, and my future.
                </div>
                <div className="testimonial-item_author">
                  Jenny J.
                </div>
              </div>
            </div>
          </div>
          <div className="get-started center">
            <Link
              state={{defaultTab: 'for-individuals'}}
              to="/auth/subscription">
              Join the Community - Get Started Now
            </Link>
          </div>
        </div>
      </article>
    </div>
  );
};

export default pure(Talent);
