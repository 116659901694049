import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';

const UsersTableCheckedMessageCell = (props) => {
  const { user, setSelectedUsers, isAllSelected } = props;
  const [checked, setChecked]= useState(isAllSelected);

  async function handleChange(e){
    const { id, checked } = e.target;
    setChecked(checked);
    setSelectedUsers(id);
  }


  useEffect(() =>{
    const checkedComputedValue = props.isMasterCheckboxClicked ? isAllSelected : checked;
    setChecked(checkedComputedValue);
    props.setIsMasterCheckboxClicked(false);
  },[isAllSelected, checked, props ]);

  return (<div className="pl-0 custom-checkbox-wrapper"> <label check="true" className="container mr-0 pl-0"><input                   
    checked={checked}
    onChange={handleChange}
    className=""
    id={user.id}
    type="checkbox"/><span className="checkmark" /></label></div>);
};

UsersTableCheckedMessageCell.propTypes = {
  user: PropTypes.object.isRequired,
  getUserList: PropTypes.func.isRequired,
  setSelectedUsers: PropTypes.func,
  isAllSelected: PropTypes.bool,
  isMasterCheckboxClicked: PropTypes.bool,
  setIsMasterCheckboxClicked: PropTypes.func,
};

export default UsersTableCheckedMessageCell;
