import PropTypes from 'prop-types';
import React from 'react';
import JobUnitNotesForm from './JobUnitNotesForm';
import JobUnitNotesList from './JobUnitNotesList';

const JobUnitNotes = ({
  errors,
  noteForm,
  loadForms,
  isJobOwner,
  onChangeNoteForm,
  onNotePost,
  onDeleteNote,
  onEditNote,
  jobNotes,
  isSubmitting,
  notesShowMore,
  onNotesShowMore,
  confirmDeleteNoteModalIsOpen,
  onConfirmDeleteNote,
  onCancelDeleteNote,
  isDeleting
}) => {
  return (
    <div className="job-notes">
      <JobUnitNotesForm
        errors={errors}
        isJobOwner={isJobOwner}
        onChangeNoteForm={onChangeNoteForm}
        onNotePost={onNotePost}
        isSubmitting={isSubmitting}
        noteForm={noteForm}/>
      <JobUnitNotesList
        isJobOwner={isJobOwner}
        onEditNote={onEditNote}
        onDeleteNote={onDeleteNote}
        loadForms={loadForms}
        notesShowMore={notesShowMore}
        onNotesShowMore={onNotesShowMore}
        notes={jobNotes}
        isDeleting={isDeleting}
        confirmDeleteNoteModalIsOpen={confirmDeleteNoteModalIsOpen}
        onConfirmDeleteNote={onConfirmDeleteNote}
        onCancelDeleteNote={onCancelDeleteNote}
        isOpen={confirmDeleteNoteModalIsOpen}/>
    </div>
  );
};

JobUnitNotes.propTypes = {
  noteForm: PropTypes.object.isRequired,
  jobNotes: PropTypes.array.isRequired,
  onNotePost: PropTypes.func.isRequired,
  isJobOwner: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  loadForms: PropTypes.array.isRequired,
  onDeleteNote: PropTypes.func.isRequired,
  onEditNote: PropTypes.func.isRequired,
  onChangeNoteForm: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  notesShowMore: PropTypes.array.isRequired,
  onNotesShowMore: PropTypes.func.isRequired,
  confirmDeleteNoteModalIsOpen: PropTypes.bool.isRequired,
  onCancelDeleteNote: PropTypes.func.isRequired,
  onConfirmDeleteNote: PropTypes.func.isRequired,
  isDeleting: PropTypes.object.isRequired
};

export default JobUnitNotes;
