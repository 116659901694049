import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from '../../components/common/Button';
import QuillEditor from '../../components/common/QuillEditor';

const modules = {
  toolbar: [
    [{ 'align': [] }],
    ['bold', 'italic', 'underline'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{ 'indent': '-1' }, { 'indent': '+1' }], 
    ['link'],
  ],
  clipboard: {
    matchVisual: false,
  }
};

class SummaryForm extends Component {
  static propTypes = {
    summary: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onUnloadForm: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);
    let summaryHTML = '';

    if (props.summary.summary !== '') {
      summaryHTML = props.summary.summary;
    }

    this.state = { summaryHTML };

    this.onSave = this.onSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    window.onbeforeunload = () => {
      return 'Changes you made might get lost!';
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = () => {
      return null;
    };
  }

  onSave(event){
    const { summaryHTML } = this.state;
    const { summary } = this.props;

    const data = Object.assign({}, summary, {
      summary: summaryHTML
    });

    this.props.onChange(data, 'summary');
    this.props.onUnloadForm(event);
  }

  handleChange(value){
    this.setState({
      summaryHTML: value
    });
  }

  render() {
    const { onUnloadForm } = this.props;
    const { summaryHTML } = this.state;

    return (
      <div>
        <div className="row">
          <div className="col-sm-12">
            <QuillEditor
              modules={modules}
              value={summaryHTML}
              placeholder="Summary"
              onChange={this.handleChange} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 text-right">
            <hr/>
            <Button
              buttonText="Cancel"
              styles={{marginLeft: 0}}
              buttonId="editSummaryForm"
              onClick={onUnloadForm}
              buttonClass="btn-secondary"/>
            <Button
              buttonText="Save"
              buttonId="editSummaryForm"
              onClick={this.onSave}
              buttonClass="btn-primary"/>
          </div>
        </div>
      </div>
    );
  }
}

export default SummaryForm;
