import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  userNameEditIcon: {
    cursor: 'pointer'
  }
};

const UsernameSection = ({ preview, resume, onLoadForm }) => {
  const { host, protocol } = window.location;
  const vanityUrl = `${protocol}//${host}/cp/${resume.vanity}`;

  return (
    <div className="section-list-container">
      <div className="resume-section section-item user-details">
        {
          !preview &&
            <span className="float-right action-section">
              <i
                onClick={onLoadForm}
                data-form-name="editUsernameForm"
                style={styles.userNameEditIcon}
                className="fa fa-pencil"/>
            </span>
        }
        <div className="user-name">
          {resume.name}
        </div>
        <div className="user-contact">
          {(resume.city !== '') && <span>{resume.city}</span>}
          {((resume.city !== '') && resume.state !== '') && <span>, </span>}
          {(resume.state !== '') && <span>{resume.state}</span>}
          {(resume.zip !== '') && <span> {resume.zip}</span>}
          {((resume.city !== '') || (resume.state !== '') || (resume.zip !== '')) && <span> • </span>}
          {(resume.phone !== '') && <span>{resume.phone} • </span>}
          {(resume.email !== '') && <span><a href={`mailto:${resume.email}`} target="_top">{resume.email}</a> • </span>}
          {(resume.vanity !== '') && <span><a href={vanityUrl} target="_blank" rel="noopener noreferrer">{vanityUrl}</a></span>}
        </div>
      </div>
    </div>
  );
};

UsernameSection.propTypes = {
  preview: PropTypes.bool,
  resume: PropTypes.object.isRequired,
  onLoadForm: PropTypes.func
};

export default UsernameSection;
