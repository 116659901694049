import PropTypes from 'prop-types';
import React from 'react';

import { parseUrlString } from '../../utils';

import VideoIframe from './VideoIframe';
import ImagePost from './ImagePost';
import ImageRenderer from './ImageRenderer';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Timestamp from '../skill-builders/discussions/Timestamp';
import Linkify from 'react-linkify';
import Parser from 'html-react-parser';

const SharedPost = ({
  wallPost,
  originalPost,
  showMore,
  onshowMore,
  secondary,
}) => {
  const { post: postString, attachment, posted, user: originalPostUser } = originalPost;
  const { id, user, comment, sharedQuotedText } = wallPost;
  const { condition, id: showMoreId} = showMore;
  const post = secondary ? comment : postString;

  const parsedString = parseUrlString(post);

  const showMoreCondition = post.replace(/<[^>]+>/g, '').length > 400;
  const showFullPostText = condition && showMoreId === id;
  const lessComponentCondition = showMoreCondition && !showFullPostText;

  const wrapperClass = classNames({
    'profile-name': secondary,
    'post-content': !secondary
  });

  return (
    <div className={wrapperClass} style={{ whiteSpace: 'normal' }}>
      {
        secondary &&
          <span style={{paddingRight:5}}>
            <Link
              to={`/cp/${user.vanity}`}
              className="user-name">
              {user.name}
            </Link>
          </span>
      }

      <div className="shared-item">
        <p className="quote">{sharedQuotedText}</p>
      </div>

      <div className="post">
        <div className="share-post primary-post">
          <div className="left">
            <Link to={`/cp/${originalPostUser.vanity}`}>
              {
                originalPostUser.avatar && originalPostUser.avatar !== ''?
                  <img
                    className="header-image medium align-top"
                    src={originalPostUser.avatar}
                    alt={`${originalPostUser.name}'s Avatar`}/>:
                  <span
                    className="header-image medium align-top initial-avatar">
                    {originalPostUser.name.slice(0,1)}
                  </span>
              }
            </Link>
          </div>
          <div className="right">
            <div className="profile-title">
              <span className="profile-name">
                <Link
                  to={`/cp/${originalPostUser.vanity}`}
                  className="user-name">
                  {originalPostUser.name}
                </Link>
              </span>
              <Timestamp postedAt={posted.date}/>
            </div>
            <div className="post-content" style={{ whiteSpace: 'normal' }}>
              {
                lessComponentCondition ?
                  <Linkify>{Parser(post.slice(0, 400))}</Linkify>
                  :
                  <Linkify>{Parser(post)}</Linkify>
              }

              {
                showMoreCondition &&
                  <span
                    onClick={onshowMore(id)}
                    className="show-more-btn">
                    {lessComponentCondition ? '... Show more' : 'Show less'}
                  </span>
              }

              {(parsedString && parsedString.type === 'iframe' && attachment === '')
                && <VideoIframe url={parsedString.url} secondary={secondary}/>}

              {(parsedString && parsedString.type === 'image' && attachment === '')
                && <ImagePost url={parsedString.url} secondary={secondary}/>}

              {
                attachment &&
                  <ImageRenderer
                    attachment={attachment}
                    secondary={secondary}/>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SharedPost.propTypes = {
  showMore: PropTypes.object.isRequired,
  onshowMore: PropTypes.func.isRequired,
  wallPost: PropTypes.object.isRequired,
  originalPost: PropTypes.object.isRequired,
  secondary: PropTypes.bool,
};

export default SharedPost;
