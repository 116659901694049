import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { openModal } from '../../../redux/actions/components';
import { setSelectedEducatorGroup } from '../../../redux/actions/educators';

import GroupRow from './GroupRow';

class GroupsTable extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    groupsList: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpenGroupAction: ''
    };

    this.onUpdateGroup = this.onUpdateGroup.bind(this);
    this.onUpdateGroupCode = this.onUpdateGroupCode.bind(this);
    this.toggleGroupActions = this.toggleGroupActions.bind(this);
  }

  toggleGroupActions(groupId) {
    return () => {
      if (groupId === this.state.isOpenGroupAction) {
        this.setState({ isOpenGroupAction: '' });
        return;
      }

      this.setState({ isOpenGroupAction: groupId });
    };
  }

  onUpdateGroup(group) {
    return () => {
      this.updateGroup(group, 'group-edit');
    };
  }

  onUpdateGroupCode(group) {
    return () => {
      this.updateGroup(group, 'confirm-update-group-code');
    };
  }

  updateGroup(group, modalName) {
    const { actions } = this.props;

    actions.setSelectedEducatorGroup(group);
    actions.openModal(modalName);
  }

  render() {
    const { groupsList } = this.props;
    const { isOpenGroupAction } = this.state;

    return (
      <table className="cp-table mt-1 table-responsive-md">
        <thead>
          <tr className="cp-table-header">
            <th width="300">Group Name</th>
            <th width="100">Group Code</th>
            <th width="100" style={{textAlign:'center'}}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {groupsList.map(group =>
            (<GroupRow
              key={group.id}
              group={group}
              onUpdateGroup={this.onUpdateGroup}
              isOpenGroupAction={isOpenGroupAction}
              onUpdateGroupCode={this.onUpdateGroupCode}
              toggleGroupActions={this.toggleGroupActions}/>))}
        </tbody>
        <tfoot/>
      </table>
    );
  }
}

const mapStateToProps = ({ educators: { groups } }) => {
  const groupsList = groups.filter(group => group.id !== 'all-groups');

  return {
    groupsList
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    openModal,
    setSelectedEducatorGroup
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupsTable);
