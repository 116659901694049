import PropTypes from 'prop-types';
import React from 'react';
import { uniqueId } from '../../../utils';
import CertificationCardHandler from '../../../containers/profile/sections/CertificationCardHandler';

const CertificationList = ({
  certifications,
  isPublicProfile,
  onEdit,
  onDisplayEvidence
}) => {
  const sortCertification = (certifications) => {
    const newCertificationList = [].concat(certifications);
    return newCertificationList.sort(function(a, b){
      return new Date(b.enddate.date) - new Date(a.enddate.date);
    });
  };

  const certificationSortedList = sortCertification(certifications);
  if(certifications.length === 0){
    return(
      <div className="empty-section-message">
        <p>Add any certifications, or licenses you have to showcase your credentials.</p>
      </div>
    );
  }
  return (
    <div>
      {certificationSortedList.map(certification =>
        (<CertificationCardHandler
          key={uniqueId()}
          certification={certification}
          onEdit={onEdit}
          isPublicProfile={isPublicProfile}
          onDisplayEvidence={onDisplayEvidence}/>))}
    </div>
  );
};

CertificationList.propTypes = {
  certifications: PropTypes.array.isRequired,
  isPublicProfile: PropTypes.bool.isRequired,
  onEdit: PropTypes.func,
  onDisplayEvidence: PropTypes.func
};

export default CertificationList;
