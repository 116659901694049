import PropTypes from 'prop-types';
import React from 'react';
import { pure } from 'recompose';
import _find from 'lodash/find';
import _isUndefined from 'lodash/isUndefined';

import { uniqueId } from '../../../utils';
import InterestCardHandler from '../../../containers/profile/sections/InterestCardHandler';

const InterestList = ({
  interests,
  isPublicProfile,
  onEdit,
  interestsOrder,
  reArrangeInterests,
}) => {
  if(interests.length === 0){
    return(
      <div className="empty-section-message">
        <p>Let people know what you're passionate about, causes that interest you, or things you enjoy doing.</p>
      </div>
    );
  }

  return (
    <div className="row">
      <div className="col-lg-12">
        {interestsOrder.map((order, index) => {
          const interest = _find(interests, interest => interest.id === order.id);

          if(_isUndefined(interest)) return null;

          if(interest){
            return index === interests.length -1 ?
              <InterestCardHandler
                key={uniqueId()}
                index={index}
                reArrangeInterests={reArrangeInterests}
                interest={interest}
                isPublicProfile={isPublicProfile}
                onEdit={onEdit}
                last/>
              :
              <InterestCardHandler
                key={uniqueId()}
                index={index}
                reArrangeInterests={reArrangeInterests}
                interest={interest}
                isPublicProfile={isPublicProfile}
                onEdit={onEdit}
                last={false}/>;
          }
        })}
      </div>
    </div>
  );
};

InterestList.propTypes = {
  interestsOrder: PropTypes.array.isRequired,
  interests: PropTypes.array.isRequired,
  isPublicProfile: PropTypes.bool.isRequired,
  onEdit: PropTypes.func,
  reArrangeInterests: PropTypes.func,
};

export default pure(InterestList);
