import React from 'react';

import Club from '../../../containers/profile/sections/Club';
import Award from '../../../containers/profile/sections/Award';
import Interests from '../../../containers/profile/sections/Interests';
import Project from '../../../containers/profile/sections/Project';
import WebpageSkills from '../../../containers/profile/visiting/WebpageSkills';
import Education from '../../../containers/profile/sections/Education';
import Experience from '../../../containers/profile/sections/Experience';
import Publication from '../../../containers/profile/sections/Publication';
import Association from '../../../containers/profile/sections/Association';
import Certification from '../../../containers/profile/sections/Certification';

const WebpageSection = ({section, isPublicProfile, profile, isAdminViewing = false }) => {
  let sectionComponent = null;

  switch(section.name) {
  case 'Award':
    sectionComponent = (<Award
      profile={profile}
      isPublicProfile={isPublicProfile}/>);
    break;

  case 'Association':
    sectionComponent = (<Association
      profile={profile}
      isPublicProfile={isPublicProfile}/>);
    break;

  case 'Club':
    sectionComponent = (<Club
      profile={profile}
      isPublicProfile={isPublicProfile}/>);
    break;

  case 'Certification':
    sectionComponent = (<Certification
      profile={profile}
      isPublicProfile={isPublicProfile}/>);
    break;

  case 'Education':
    sectionComponent = (<Education
      profile={profile}
      isPublicProfile={isPublicProfile}/>);
    break;

  case 'Experience':
    sectionComponent = (<Experience
      profile={profile}
      isPublicProfile={isPublicProfile}/>);
    break;

  case 'Interests':
    sectionComponent = (<Interests
      profile={profile}
      isPublicProfile={isPublicProfile}/>);
    break;

  case 'Project':
    sectionComponent = (<Project
      profile={profile}
      isPublicProfile={isPublicProfile}/>);
    break;

  case 'Publication':
    sectionComponent = (<Publication
      profile={profile}
      isPublicProfile={isPublicProfile}/>);
    break;

  case 'Skills':
    sectionComponent = (<WebpageSkills
      profile={profile}
      isPublicProfile={isPublicProfile}
      isAdminViewing={isAdminViewing}/>);
    break;

  default:
    '';
  }

  return sectionComponent;

};

export default WebpageSection;
