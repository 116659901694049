import React from 'react';

import moment from 'moment';
import classNames from 'classnames';

import PropTypes from 'prop-types';

import CpHtmlParser from '../common/CpHtmlParser';

const employmentTypeOptions = [
  'Full-Time',
  'Part-Time',
  'Full-Time/Part-Time'
];

const educationLevelOptions = [
  'Secondary Education',
  'Vocational Education',
  'Bachelor',
  'Master',
  'Post-Master'
];

const dateConfig = {
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  nextWeek: 'dddd',
  lastDay: '[Yesterday]',
  lastWeek: '[a week ago]',
  sameElse: 'MM/DD/YYYY'
};

const JobMatchCard = ({ job }) => {
  const seeMoreCondition = job.description.replace(/<[^>]+>/g, '').length > 205;
  const descriptionString = job.description.replace(/<[^>]+>/g, '');
  const description = seeMoreCondition ?
    `${descriptionString.slice(0, 205)}...` : descriptionString.length > 0 ?
      descriptionString :
      'To see the full description of this job, you must visit the site it is published on';

  const titleString = job.title.replace(/<[^>]+>/g, '');
  const isTitleMoreThanLimit = titleString.length > 30;
  const title = isTitleMoreThanLimit ?
    `${titleString.slice(0, 30)}...` : titleString;

  const city = job.city.replace(/<[^>]+>/g, '');

  const educationLevelOption = educationLevelOptions[parseInt(job.educationLevel)-1];
  const educationLevel = educationLevelOption ? educationLevelOption : 'Unspecified';

  const employmentTypeOption = employmentTypeOptions[parseInt(job.employmentType)-1];
  const employmentType = employmentTypeOption ? employmentTypeOption : 'Unspecified';

  const displayDate = moment(job.postingDate.date).calendar(dateConfig);
  const displayDateClassNames = classNames({
    'badge badge-pill': displayDate === 'Today',
    'card-text': displayDate !== 'Today'
  });

  return (
    <div className="jobmatch-card-container">
      <div className="card h-100">
        <div className="card-body">
          <h5 className="card-title-container">
            <div className="card-title">
              <a href={job.url} target="_blank">{title}</a>
            </div>
            <div className="card-title-meta">
              <span className={displayDateClassNames}>{displayDate}</span>
            </div>
          </h5>
          <div className="jobmatch-card-location-type">
            <p className="card-text mb-1">
              {job.company} - {city}
            </p>
            <div className="jobmatch-type">
              <p className="card-text mb-0">
                <b>Employment Type: </b>{employmentType}
              </p>
              <p className="card-text mb-0">
                <b>Education Level: </b>{educationLevel}
              </p>
            </div>
          </div>
          <div className="jobmatch-card-description">
            <CpHtmlParser htmlString={description} />
            <a
              target="_blank"
              href={job.url}
              className="show-more-btn-job">
              See more
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

JobMatchCard.propTypes = {
  job: PropTypes.object.isRequired,
};

export default JobMatchCard;
