import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import Parser from 'html-react-parser';

const QuizOptions = ({
  name,
  label,
  value,
  radioClass,
  onAnswerSelected,
  selectedOption,
  quizSectionId
}) => {
  const spanClass = classNames(quizSectionId, {
    [radioClass]: selectedOption !== null
  });

  return (
    <li onClick={onAnswerSelected(value)}>
      <input
        name={name}
        type="radio"
        value={value}
        onChange={onAnswerSelected(value)}
        disabled={selectedOption !== null}
        checked={value === selectedOption}/>

      <span className={spanClass}>
        {Parser(label)}
      </span>
    </li>
  );
};

QuizOptions.propTypes = {
  radioClass: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onAnswerSelected: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  selectedOption: PropTypes.string,
  quizSectionId: PropTypes.string.isRequired
};

export default QuizOptions;
