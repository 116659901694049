import PropTypes from 'prop-types';
import React from 'react';

import { Collapse } from 'reactstrap';
import BadgeOptionItem from './BadgeOptionItem';

const BadgeOptionsList = ({ isBadgesShown, badges, selectedBadges, toggleBadgeCheckbox }) => {
  return (
    <Collapse isOpen={isBadgesShown}>
      <div className="support-container">
        {badges.map(badge =>
          (<BadgeOptionItem
            key={badge.id}
            badge={badge}
            selectedBadges={selectedBadges}
            toggleBadgeCheckbox={toggleBadgeCheckbox}/>))}
      </div>
    </Collapse>
  );
};

BadgeOptionsList.propTypes = {
  isBadgesShown: PropTypes.bool,
  badges: PropTypes.array.isRequired,
  selectedBadges: PropTypes.array.isRequired,
  toggleBadgeCheckbox: PropTypes.func.isRequired
};

export default BadgeOptionsList;
