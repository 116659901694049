import PropTypes from 'prop-types';
import React from 'react';

import { Dropdown } from 'reactstrap';
import { find } from 'lodash';

const jobStatusValues = [
  {
    title: 'Closed',
    value: 0,
    statusClass: 'closed'
  },
  {
    title: 'Saved',
    value: 1,
    statusClass: 'saved'
  },
  {
    title: 'Applied',
    value: 2,
    statusClass: 'applied'
  },
  {
    title: 'Interviewed',
    value: 3,
    statusClass: 'interviewed'
  },
  {
    title: 'Offered',
    value: 4,
    statusClass: 'offered'
  },
  {
    title: 'No Response',
    value: 5,
    statusClass: 'noresponse'
  },
  {
    title: 'Rejected',
    value: 6,
    statusClass: 'rejected'
  },
];

const JobUnitHeader = ({
  id,
  company,
  title,
  jobStatusDropdownOpen,
  togglejobStatusDropdown,
  onSelectJobStatus,
  jobStatus,
  isJobOwner
}) =>{
  const dropdownButton = find(jobStatusValues,option=>option.value === jobStatus);
  const dropDownButtonClass = `btn-group job-status-dropdown
      ${dropdownButton == null ? '': dropdownButton.statusClass}`;

  return(
    <div>
      <div className="job-status">
        <div className="job-status-container">
          <Dropdown
            disabled={!isJobOwner}
            className={dropDownButtonClass}
            isOpen={jobStatusDropdownOpen}
            toggle={togglejobStatusDropdown}>
            <a
              onClick={togglejobStatusDropdown}
              className="btn dropdown-toggle grey-dropdown-btn">
              {dropdownButton == null ? 'Saved' : dropdownButton.title}
              <span className="caret"/>
            </a>
            <ul className="dropdown-menu dropdown-menu-right">
              {jobStatusValues.map((option, index) =>
                (<a className="dropdown-item grey-dropdown-btn"
                  key={index}
                  onClick={onSelectJobStatus(id,option.value)}>
                  {option.title}
                </a>))}
            </ul>
          </Dropdown>
        </div>
      </div>
      <p className="job-detail-title">{company}</p>
      <p className="job-detail-position">{title}</p>
    </div>
  );
};

JobUnitHeader.propTypes = {
  isJobOwner: PropTypes.bool.isRequired,
  jobStatusDropdownOpen: PropTypes.bool.isRequired,
  togglejobStatusDropdown: PropTypes.func.isRequired,
  onSelectJobStatus: PropTypes.func.isRequired,
  jobStatus: PropTypes.number,
  company: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string
};

export default JobUnitHeader;
