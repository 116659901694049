import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const UsersTableBadgesCell = (props) => {
  const {user, profile} = props;
  return (
    <Link
      title={`Link to ${user.name}'s career portfolio`}
      target="_blank"
      to={user.id !== profile?.id ? `/portfolio/user/${user.id}?username=${user.name}` : '/portfolio'}>
      {user.filesCount}
    </Link>
  );
};

UsersTableBadgesCell.propTypes = {
  user: PropTypes.object.isRequired,
  profile: PropTypes.object,
};

export default UsersTableBadgesCell;
