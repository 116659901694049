import PropTypes from 'prop-types';
import React from 'react';
import { loadForm } from '../../utils';
import JobNotesEditForm from '../../containers/jobs/JobNotesEditForm';
import Note from './Note';

const NotesUnit = ({
  loadForms,
  note,
  onDeleteNote,
  onEditNote,
  isJobOwner,
  notesShowMore,
  onNotesShowMore
}) => {
  const { id, jobid, note: noteText } = note;
  const notesFormId = `update-job-note-form-${id}`;
  const noteObject = {
    id: id,
    jobId: jobid,
    text: noteText,
    noteId: notesFormId
  };

  const isForm = loadForm(loadForms, notesFormId);

  const component = !isForm ?
    (<Note
      note={note}
      isJobOwner={isJobOwner}
      notesShowMore={notesShowMore}
      onNotesShowMore={onNotesShowMore}
      onEdit={onEditNote(notesFormId)}
      onDelete={onDeleteNote}/>) :
    <JobNotesEditForm note={noteObject}/>;

  return (
    <div className="note-item">
      {component}
    </div>
  );
};

NotesUnit.propTypes = {
  onDeleteNote: PropTypes.func.isRequired,
  onEditNote: PropTypes.func.isRequired,
  loadForms: PropTypes.array.isRequired,
  note: PropTypes.object,
  isJobOwner: PropTypes.bool.isRequired,
  notesShowMore: PropTypes.array.isRequired,
  onNotesShowMore: PropTypes.func.isRequired
};

export default NotesUnit;
