import React from 'react';

import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import CloseIcon from '../../assets/images/close.svg';

const ShowOnCareerSiteModal = ({
  resourceId,
  isOpen,
  toggleModal,
  confirmShow,
  isBadge,
}) => {
   
  function setLocalStorage(event){
    localStorage.setItem([`${resourceId}_show`], event.target.checked);
  }

  return (
    <Modal
      centered
      className="customLevelUpModal"
      isOpen={isOpen}
      toggle={toggleModal}>
      <div className="modal-header">
        {isBadge && (
          <span className="modal-title">Show Badge on Career Site</span>
        )}
        {!isBadge && (
          <span className="modal-title">
            Link Skill and Its Evidence Page on Career Site
          </span>
        )}
        <div
          onClick={toggleModal}
          className="close pointer"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">
            <img src={CloseIcon} />
          </span>
        </div>
      </div>

      <ModalBody>
        {!isBadge && (
          <p>
            This will link skill to its Evidence page on your Career Site to
            show your evidence and rating.
          </p>
        )}
        {isBadge && <p>This will show your Skill Badge on your Career Site.</p>}
        <input
          className="mr-1"
          type="checkbox"
          onChange={setLocalStorage}/>
        <span>Don't show me again</span>
      </ModalBody>
      <ModalFooter className="bordertop-0">
        <Button className="ml-1" onClick={toggleModal}>
          Cancel
        </Button>
        <Button className="ml-1 sendButton" onClick={confirmShow}>
          Okay
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ShowOnCareerSiteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isBadge: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  confirmShow: PropTypes.func.isRequired,
  resourceId: PropTypes.string,
};

export default ShowOnCareerSiteModal;