import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CertificationCard from '../../../components/profile/sections/CertificationCard';

class CertificationCardHandler extends Component {
  static propTypes = {
    certification: PropTypes.object.isRequired,
    isPublicProfile: PropTypes.bool.isRequired,
    onEdit: PropTypes.func,
    onDisplayEvidence: PropTypes.func
  }

  constructor(props, context) {
    super(props, context);

    this.onEditCertificationClick = this.onEditCertificationClick.bind(this);
  }

  onEditCertificationClick(event) {
    event.preventDefault();
    const { id } = this.props.certification;

    this.props.onEdit(id);
  }

  render() {
    const { certification, isPublicProfile, onDisplayEvidence } = this.props;

    return (
      <div className="profile-item__card-list">
        <CertificationCard
          certification={certification}
          isPublicProfile={isPublicProfile}
          onDisplayEvidence={onDisplayEvidence}
          onEditCertificationClick={this.onEditCertificationClick}/>
      </div>
    );
  }
}

export default CertificationCardHandler;
