import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import { omit, isNull } from 'lodash';

import Validator from '../../../validator';

import { formatFormDataArray } from '../../../utils';

import * as routeActions from '../../../redux/actions/router';
import * as certificationActions from '../../../redux/actions/certification';
import * as componentsActions from '../../../redux/actions/components';

import Loading from '../../../components/common/Loading';
import ConfirmModal from '../../../components/portfolio/ConfirmModal';
import CertificationForm from '../../../components/profile/forms/CertificationForm';
import CertificationList from '../../../components/profile/sections/CertificationList';
import ProfileCardHeader from '../../../components/profile/header/ProfileCardHeader';
import LinkCertification from './LinkCertification';
import CertificationEvidenceDetails from '../../../components/profile/sections/CertificationEvidenceDetails';

class Certification extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    certification: PropTypes.object.isRequired,
    certifications: PropTypes.array.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    isPublicProfile: PropTypes.bool.isRequired,
    showVisibilitySelect: PropTypes.bool,
    onChangeVisibility: PropTypes.func,
    isEditingSections: PropTypes.bool,
    onToggleSectionEdit: PropTypes.func,
    onConfirmDeleteSection: PropTypes.func,
    modal: PropTypes.string,
    isUpdatingProfile: PropTypes.bool,
    profile: PropTypes.object,
    isDeleting: PropTypes.bool.isRequired,
    isUpdating: PropTypes.bool.isRequired,
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      isEditing: false,
      isSubmitting: false,
      editingId: null,
      selectedFiles: [],
      selectedFile: {
        title: '',
        description: '',
        file: '',
        externalurl: ''
      },
      showInsertTextBox: false,
      displayEvidence: null,
      certification: Object.assign({}, this.props.certification)
    };

    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onDeleteSection = this.onDeleteSection.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.onEditCertification = this.onEditCertification.bind(this);
    this.onDeleteCertification = this.onDeleteCertification.bind(this);

    this.onDropFile = this.onDropFile.bind(this);
    this.onSelectFile = this.onSelectFile.bind(this);
    this.onDeleteLoadedFile = this.onDeleteLoadedFile.bind(this);
    this.onInsertLinkClicked = this.onInsertLinkClicked.bind(this);
    this.onLinkCertification = this.onLinkCertification.bind(this);
    this.onChangeSelectedFile = this.onChangeSelectedFile.bind(this);
    this.onDisplayEvidence = this.onDisplayEvidence.bind(this);
    this.onChangeRoute = this.onChangeRoute.bind(this);
  }

  componentDidMount() {
    const { profile } = this.props;
    this.props.actions.certificationRequest(profile.id);
  }

  formatEndDate = (dateString) => {
    return dateString === '' ? null : dateString;
  };

  isValid(field = null) {
    const { noend, startdate } = this.state.certification;
    let rules = {};
    if (noend === true) {
      rules = {
        name: ['required', 'maxLength|100'],
        authority: ['required', 'maxLength|200'],
        license: ['maxLength|100'],
        url: ['unsafeurl'],
        startdate: ['required', 'startdate'],
      };
    } else {
      rules = {
        name: ['required', 'maxLength|100'],
        authority: ['required', 'maxLength|200'],
        license: ['maxLength|100'],
        url: ['unsafeurl'],
        startdate: ['required', 'startdate'],
        enddate: ['required', 'enddate', `beforeStart|${this.formatEndDate(startdate)}`],
      };
    }
    const { certification, errors: prevErrors} = this.state;
    const validate = Validator.createValidator(rules, certification, field);
    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    if (noend === true) {
      delete prevErrors['enddate'];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  isValidFile(field = null) {
    const rules = {
      title: ['required', 'minLength|1'],
      description: ['required', 'minLength|1', 'maxLength|100'],
      externalurl: ['choseAtleastOne|file,externalurl', 'url'],
      file: ['choseAtleastOne|file,url']
    };
    const { selectedFile } = this.state;

    const validate = Validator.createValidator(rules, selectedFile, field);
    const { errors, isValid } = validate;

    this.setState({ errors });

    return isValid;
  }

  onChange(event) {
    let { name, value, type, checked } = event.target;
    const { certification } = this.state;

    value = type === 'checkbox' ? checked : value;

    certification[name] = value;

    this.setState({ certification }, () => this.isValid(name));

    if (name === 'startdate') {
      this.isValid('enddate');
    }

  }

  getFormData(data) {
    let formData = new FormData();

    for (const prop in data) {
      const propertyValue = data[prop];

      if (Array.isArray(propertyValue)) {
        formData = formatFormDataArray(formData, prop, propertyValue);
        continue;
      }

      formData.append(`${prop}`, propertyValue);
    }

    return formData;
  }

  onSave(event) {
    event.preventDefault();

    this.setState({ isSubmitting: true });

    if (! this.isValid()) {
      return this.setState({ isSubmitting: false });
    }

    const {
      certification,
      editingId,
      selectedFile,
      selectedFiles
    } = this.state;
    const { actions, certifications } = this.props;

    const evidenceFile = (selectedFile.file !== '') ? selectedFile.file : '';
    const evidence = ((selectedFile.file !== '') || (selectedFile.externalurl !== '')) ? [{...omit(selectedFile, ['file'])}] : [];

    const evidenceFileId = (selectedFile.file === '' && selectedFiles.length > 0) ? selectedFiles[0] : '';

    const data = {
      ...omit(certification, ['id', 'evidence', '_links']),
      evidence,
      evidenceFile,
      evidenceFileId
    };

    const formData = this.getFormData(data);

    if(editingId !== null){
      const index = certifications.findIndex(element =>
        element.id === certification.id);

      return actions.updateCertification(index, formData, certification.id)
        .then(() => this.handleResponse());
    }

    this.props.actions.saveCertification(formData)
      .then(() => this.handleResponse());
  }

  onCancel() {
    this.setState({
      isEditing: false,
      errors: {},
      editingId: null,
      certification: this.props.certification
    }, () => this.props.onToggleSectionEdit('certification'));
  }

  onEditClick(event) {
    event.preventDefault();

    this.setState({ isEditing: true },
      () => this.props.onToggleSectionEdit('certification'));
  }

  onDeleteSection(){
    this.props.actions.openModal('delete-webpage-certification-section-modal');
  }

  onCloseModal(){
    this.props.actions.closeModal();
  }

  onDeleteConfirm(){
    const { certifications, actions } = this.props;

    certifications.map(certification => actions.deleteCertification(certification.id));
    this.props.onConfirmDeleteSection('Certification');
  }

  onEditCertification(id){
    const { certifications } = this.props;
    const certObject = certifications.find(cert => cert.id === id);
    const certification = Object.assign({}, this.state.certification, certObject);
    this.setState({
      isEditing: true,
      certification,
      editingId: id
    }, () => this.props.onToggleSectionEdit('certification'));
  }

  onDeleteCertification(event) {
    event.preventDefault();

    const { certification } = this.state;
    this.props.actions.deleteCertification(certification.id)
      .then(() => this.handleResponse());
  }

  handleResponse() {
    const certification = {
      name: '',
      authority: '',
      url: '',
      startdate: '',
      enddate: '',
      license: '',
      noend: false
    };

    this.setState({
      isEditing: false,
      isSubmitting: false,
      editingId: null,
      certification,
      selectedFiles: [],
      selectedFile: {
        title: '',
        description: '',
        file: '',
        externalurl: ''
      }
    }, () => this.props.onToggleSectionEdit('certification'));
  }

  onLinkCertification(event) {
    event.preventDefault();
    this.props.actions.openModal('link-credential-modal');
  }

  onSelectFile(id) {
    return () => {
      const selectedFiles = id === this.state.selectedFiles[0] ? [] : [id];

      this.setState({ selectedFiles });
    };
  }

  onDropFile(files) {
    if(files[0].size > 25000000)
      return toastr.info('Sorry, this image exceeds the 8MB limit.');

    this.setupImagePreview(files[0]);
  }

  setupImagePreview(file) {
    this.setState({
      selectedFile: {
        ...this.state.selectedFile,
        file
      }
    },() => this.isValidFile('file'));
  }

  onChangeSelectedFile(event) {
    const { name, value } = event.target;

    const selectedFile = Object.assign({}, this.state.selectedFile, {
      [name]: value
    });

    this.setState({ selectedFile },() => this.isValidFile(name));
  }

  onInsertLinkClicked(){
    const { showInsertTextBox } = this.state;

    this.setState({
      showInsertTextBox: !showInsertTextBox,
      selectedFile: {
        ...this.state.selectedFile,
        url: ''
      }
    });
  }

  onDeleteLoadedFile(){
    this.setState({
      showInsertTextBox: false,
      selectedFile: { ...this.state.selectedFile, file: '', url: '' }
    });
  }

  onDisplayEvidence(evidence) {
    return (event) => {
      event.preventDefault();
      this.setState({ displayEvidence: evidence });
    };
  }

  onChangeRoute(route = null) {
    return () => {
      this.setState({ displayEvidence: null });

      if (!isNull(route)) {
        this.props.actions.redirect(route);
      }
    };
  }

  render() {
    const {
      modal,
      certifications,
      isRequesting,
      isPublicProfile,
      showVisibilitySelect,
      onChangeVisibility,
      isEditingSections,
      isUpdatingProfile,
      isUpdating,
      isDeleting
    } = this.props;
    const {
      errors,
      certification,
      isEditing,
      isSubmitting,
      editingId,
      selectedFile,
      selectedFiles,
      showInsertTextBox,
      displayEvidence
    } = this.state;

    if(certifications.length === 0 && isPublicProfile){
      return null;
    }

    const isLinkCertificationModalOpen = modal && modal === 'link-credential-modal';
    const isDeleteConfirmModalOpen = modal && modal === 'delete-webpage-certification-section-modal';

    return (
      <div className="profile-item certifications">
        <ProfileCardHeader
          isEditing={isEditing}
          isEditingSections={isEditingSections}
          type="list"
          title="Certifications and Licenses"
          onEditClick={this.onEditClick}
          isPublicProfile={isPublicProfile}
          showVisibilitySelect={showVisibilitySelect}
          onDeleteClick={this.onDeleteSection}
          onChangeVisibility={onChangeVisibility}
          icon="certifications"
          isDeletable/>

        {isRequesting ?
          <Loading/> :
          isEditing ?
            <div className="profile-item__card">
              <CertificationForm
                errors={errors}
                certification={certification}
                isSubmitting={isSubmitting}
                isUpdating={isUpdating}
                isDeleting={isDeleting}
                showDelete={editingId !== null}
                onChange={this.onChange}
                onSave={this.onSave}
                onCancel={this.onCancel}
                onLinkCertification={this.onLinkCertification}
                onDelete={this.onDeleteCertification}/>
            </div> :
            <CertificationList
              certifications={certifications}
              onEdit={this.onEditCertification}
              isPublicProfile={isPublicProfile}
              onDisplayEvidence={this.onDisplayEvidence}/>}

        {isDeleteConfirmModalOpen &&
          <ConfirmModal
            modalHeaderText="Delete Section"
            modalMessage="Are you sure you want to permanently delete this Career Site section?"
            modalFooterButtonText="Yes, Delete"
            onCancel={this.onCloseModal}
            onConfirm={this.onDeleteConfirm}
            isConfirming={isUpdatingProfile}
            confirminginProgressText="Deleting..."
            isOpen={isDeleteConfirmModalOpen}/>}

        {isLinkCertificationModalOpen &&
          <LinkCertification
            errors={errors}
            onDropFile={this.onDropFile}
            selectedFile={selectedFile}
            selectedFiles={selectedFiles}
            onSelectFile={this.onSelectFile}
            showInsertTextBox={showInsertTextBox}
            onAddEvidence={this.onCloseModal}
            onDeleteLoadedFile={this.onDeleteLoadedFile}
            onInsertLinkClicked={this.onInsertLinkClicked}
            onChangeSelectedFile={this.onChangeSelectedFile}/>}

        {displayEvidence &&
          <CertificationEvidenceDetails
            isOpen={!isNull(displayEvidence)}
            evidence={displayEvidence}
            onChangeRoute={this.onChangeRoute}
            closeModal={this.onDisplayEvidence}/>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const certification = {
    name: '',
    authority: '',
    url: '',
    startdate: '',
    enddate: '',
    license: '',
    noend: false
  };

  return {
    certification,
    modal: state.components.modal,
    certifications: state.certifications.data,
    isUpdatingProfile: state.profile.isUpdating,
    isRequesting: state.certifications.isRequesting,
    isDeleting: state.certifications.isDeleting.status,
    isUpdating: state.certifications.isUpdating.status
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    certificationActions,
    componentsActions,
    routeActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Certification);
