import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Validator from '../../validator';
import { loadForm, guid } from '../../utils';

import * as componentActions from '../../redux/actions/components';

import ExperienceSectionCard from '../../components/resume/sections/ExperienceSectionCard';
import ExperienceForm from '../../components/profile/forms/ExperienceForm';
import formWithModal from '../../components/profile/modal/formWithModal';
import DeleteSectionItemConfirmModal from '../../components/resume/DeleteSectionItemConfirmModal';

class ResumeExperience extends Component {
  static propTypes = {
    experience: PropTypes.object,
    onChangeSection: PropTypes.func.isRequired,
    onChangeSectionTitle: PropTypes.func.isRequired,
    onUnloadForm: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    resumeExperiences: PropTypes.object.isRequired,
    onLoadForm: PropTypes.func.isRequired,
    forms: PropTypes.array,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      editingId: null,
      removeExperienceId: null,
      title: props.resumeExperiences.title,
      experience: Object.assign({}, this.props.experience),
      editorText: {description: this.props.experience.description}
    };

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onEditExperience = this.onEditExperience.bind(this);
    this.onRemoveExperience = this.onRemoveExperience.bind(this);
    this.onRichEditorChange = this.onRichEditorChange.bind(this);
    this.onConfirmRemoveExperience = this.onConfirmRemoveExperience.bind(this);
    this.onCancelRemoveExperience = this.onCancelRemoveExperience.bind(this);
  }

  formatEndDate = (dateString) => {
    return dateString === '' ? null : dateString;
  };

  isValid(field = null) {
    const noend = this.state.experience.noend;
    let rules = {};
    if (noend === true) {
      rules = {
        title: ['required', 'minLength|6','maxLength|100'],
        orgname: ['required', 'minLength|6','maxLength|100'],
        startdate: ['required', 'startdate'],
        orgurl: ['unsafeurl'],
      };
    } else {
      rules = {
        title: ['required', 'minLength|6','maxLength|100'],
        orgname: ['required', 'minLength|6','maxLength|100'],
        startdate: ['required', 'startdate'],
        enddate: ['required', 'enddate', `beforeStart|${this.formatEndDate(this.state.experience.startdate)}`],
        orgurl: ['unsafeurl']
      };
    }
    const { experience, errors: prevErrors} = this.state;
    const validate = Validator.createValidator(rules, experience, field);
    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    if (noend === true) {
      delete prevErrors['enddate'];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  isValidDescription(field = null) {
    const rules = {
      description: ['maxWords|200']
    };
    const { editorText, errors: prevErrors } = this.state;
    const validate = Validator.createValidator(rules, editorText, field);
    const { errors, isValid } = validate;

    if (field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }
    this.setState({ errors: Object.assign({}, prevErrors, errors) });

    return isValid;
  }

  onChangeTitle(event){
    const { value } = event.target;
    this.props.onChangeSectionTitle(value, 'experience');
  }

  handleChange(event){
    let { name, type, checked, value } = event.target;
    const { experience } = this.state;
    value = type === 'checkbox' ? checked : value;
    experience[name] = value;

    this.setState({ experience }, () => this.isValid(name));
    if (name === 'startdate') {
      this.isValid('enddate');
    }

  }

  onRichEditorChange(value, ...params) {
    const { experience, editorText } = this.state;

    experience['description'] = value;
    editorText['description'] = params[2].getText().replace(/\r?\n|\r/gm, ' ');

    this.setState({ experience, editorText }, () => this.isValidDescription('description'));
  }

  onSave(){
    let { experience, editingId } = this.state;
    const { resumeExperiences } = this.props;

    if (!this.isValid() || !this.isValidDescription())
      return;

    const newExperience = Object.assign({},{
      id: guid()
    }, experience );

    const index = resumeExperiences
      .experienceArray
      .findIndex(experience => experience.id === editingId);

    const experienceArray = editingId === null ? [
      ...resumeExperiences.experienceArray,
      newExperience
    ] : [
      ...resumeExperiences.experienceArray.slice(0, index),
      experience,
      ...resumeExperiences.experienceArray.slice(index+1)
    ];

    const data = Object.assign({}, resumeExperiences,{
      experienceArray
    });

    this.props.onChangeSection(data, 'experience');
    this.onCancel();
  }

  onCancel() {
    const { unloadForm } = this.props.actions;
    this.clearFormField();
    unloadForm('editExperienceForm');
  }

  clearFormField() {
    const experience = {
      title: '',
      orgname: '',
      orgurl: '',
      description: '',
      startdate: '',
      enddate: '',
      noend: false
    };

    this.setState({
      experience,
      errors: {},
      editingId: null,
    });
  }

  onEditExperience(event) {
    const { id } = event.target;
    const { loadForm } = this.props.actions;
    const { experienceArray } = this.props.resumeExperiences;
    const experienceObject = experienceArray.find(experience => experience.id === id);
    const experience = Object.assign({}, this.state.experience, experienceObject);

    this.setState({
      experience,
      editingId: id,
    }, ()=> loadForm('editExperienceForm'));
  }

  onRemoveExperience(event) {
    const { id: removeExperienceId } = event.target;

    this.setState({ removeExperienceId });
  }

  onConfirmRemoveExperience() {
    const { removeExperienceId } = this.state;
    const { resumeExperiences } = this.props;

    const experienceArray = resumeExperiences
      .experienceArray
      .filter(experience => experience.id !== removeExperienceId);

    const data = Object.assign({}, resumeExperiences,{ experienceArray });

    this.props.onChangeSection(data, 'experience');
    this.onCancelRemoveExperience();
  }

  onCancelRemoveExperience() {
    this.setState({ removeExperienceId: null });
  }

  EditingExperience = formWithModal(ExperienceForm);

  render() {
    const { resumeExperiences, onLoadForm, forms } = this.props;
    const { experience, errors, removeExperienceId } = this.state;

    const isFormOpen = loadForm(forms, 'editExperienceForm');
    const isConfirmModalOpen = removeExperienceId !== null;

    return (
      <div className="resume-section create-resume-section-card">
        <div className="create-resume-title">
          <input
            type="text"
            value={resumeExperiences.title}
            placeholder="Experiences header"
            className="header-edit-textbox"
            onChange={this.onChangeTitle}/>
        </div>

        {!isFormOpen &&
          <div className="section-list-container">
            {resumeExperiences.experienceArray.map(experience =>
              (<ExperienceSectionCard
                key={experience.id}
                experienceDetails={experience}
                onEditExperience={this.onEditExperience}
                onRemoveExperience={this.onRemoveExperience}/>))}

            <button
              onClick={onLoadForm}
              data-form-name="editExperienceForm"
              className="btn btn-primary w-100">
              Add Experience
            </button>
          </div>}

        {isFormOpen &&
          <div className="resume-form-container">
            <this.EditingExperience
              resume
              errors={errors}
              experience={experience}
              onSave={this.onSave}
              onCancel={this.onCancel}
              onChange={this.handleChange}
              onChangeDescription={this.onRichEditorChange}
              exampleType={'Resume Experience'} />
          </div>}

        {isConfirmModalOpen &&
          <DeleteSectionItemConfirmModal
            onCancel={this.onCancelRemoveExperience}
            onConfirm={this.onConfirmRemoveExperience}
            isOpen={isConfirmModalOpen}/>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const experience = {
    title: '',
    orgname: '',
    orgurl: '',
    description: '',
    startdate: '',
    enddate: '',
    noend: false
  };

  return {
    experience,
    forms: state.components.forms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(componentActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResumeExperience);
