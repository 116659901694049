import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import _isObject from 'lodash/isObject';

import AvatarCard from '../../../components/profile/header/AvatarCard';

import * as profileActions from '../../../redux/actions/profile';
import * as routerActions from '../../../redux/actions/router';
import * as componentsActions from '../../../redux/actions/components';
import {updateMyCareerPreppedStatus, getMyCareerPreppedStatus} from '../../../redux/actions/my-careerprepped-status';

class Avatar extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    isPublicProfile: PropTypes.bool,
    visitingUser: PropTypes.bool
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      isUpdating: false,
      profile: Object.assign({}, this.props.profile)
    };

    this.onChange = this.onChange.bind(this);
    this.onViewAvatar = this.onViewAvatar.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (! Object.is(this.props.profile, nextProps.profile)) {
      this.setState({ profile: Object.assign({}, nextProps.profile) });
    }
  }

  componentWillUnmount() {
    this.props.actions.closeModal();
  }

  onViewAvatar(){
    let defaultAvatar;
    let profile;

    if(!this.props.profile.avatar){
      defaultAvatar = require('../../../assets/images/avatar.jpg');
      profile = { ...this.props.profile, avatar: defaultAvatar };
    }

    const [,path,] = location.pathname.split('/');

    if(path === 'skill-badges' || path === 'portfolio'){
      return this.props.actions.redirect(`/cp/${this.props.profile.vanity}`);
    }

    this.props.actions.toggleAvatarModal(profile ? profile : this.props.profile);
  }

  onChange(files) {
    if (files.length > 0 && _isObject(files[0])) {
      this.setState({ isUpdating: true });
      const { profile } = this.state;
      const { actions } = this.props;
      const file = files[0];
      const formData = new FormData();
      formData.append('avatar', file);

      actions.updateProfile(profile.id, formData)
        .then(async () => {
          this.setState({ isUpdating: false });
          await actions.updateMyCareerPreppedStatus();
          actions.getMyCareerPreppedStatus();
        });
    }
  }

  render() {
    const { isUpdating, profile } = this.state;
    const { visitingUser, isPublicProfile } = this.props;

    return (
      <AvatarCard
        profile={profile}
        isUpdating={isUpdating}
        isPublicProfile={isPublicProfile}
        visitingUser={visitingUser}
        onViewAvatar={this.onViewAvatar}
        onChange={this.onChange}/>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...profileActions,
      ...routerActions,
      ...componentsActions,
      updateMyCareerPreppedStatus,
      getMyCareerPreppedStatus,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(Avatar);
