import React from 'react';
import { Alert } from 'reactstrap';

const AdminHome = () => {
  return (
    <Alert color="info">
      <strong>Heads up!</strong>
      &nbsp;Select or create a new essential.
    </Alert>
  );
};

export default AdminHome;
