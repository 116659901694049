import PropTypes from 'prop-types';
import React from 'react';

import HeaderLinks from './HeaderLinks';
import { Link } from 'react-router-dom';

const HeaderContent = ({
  unitTitle,
  isAdmin,
  slug,
  unit,
  path,
  noSection,
  onClickReviewEssentials,
  onClickEngage,
  onClickGetBadge
}) => {
  return(
    <div className="row align-items-center justify-content-between">
      <div className="col-lg-6 col-sm-auto">
        <Link to="/skill-builders"><h1>{unitTitle}</h1></Link>
      </div>
      {!isAdmin &&
        <div className="col-lg-auto">
          <HeaderLinks
            slug={slug}
            path={path}
            unit={unit}
            onClickReviewEssentials={onClickReviewEssentials}
            onClickEngage={onClickEngage}
            onClickGetBadge={onClickGetBadge}
            noSection={noSection}/>
        </div>}
      {isAdmin ?
        <div className="col-sm-auto">
          <span>
            <Link
              className="edit-button"
              style={{minWidth: 30, border: 'none'}}
              to={`/admin/skill-builders/unit/${unit.id}`}>

              <i className="fa fa-pencil" aria-hidden="true"/>
            </Link>
          </span> 
        </div>: null}
    </div>
  );
};

HeaderContent.propTypes = {
  slug: PropTypes.string,
  unit: PropTypes.object.isRequired,
  unitTitle: PropTypes.string,
  isAdmin: PropTypes.bool,
  stickyStyles: PropTypes.string,
  path: PropTypes.string,
  isRequesting: PropTypes.bool,
  noSection: PropTypes.bool,
  onClickEngage: PropTypes.func.isRequired,
  onClickGetBadge: PropTypes.func.isRequired,
  onClickReviewEssentials: PropTypes.func.isRequired
};

export default HeaderContent;
