import PropTypes from 'prop-types';
import React from 'react';
import ResumeSkills from './ResumeSkills';
import ResumeEducation from './ResumeEducation';
import ResumeExperience from './ResumeExperience';
import ResumeClubs from './ResumeClubs';
import ResumeCertifications from './ResumeCertifications';
import ResumeAwards from './ResumeAwards';
import ResumePublications from './ResumePublications';
import ResumeProjects from './ResumeProjects';
import ResumeAssociations from './ResumeAssociations';

const PreviewResumeSectionCardContainer = ({ section,resume }) => {
  let sectionComponent;

  switch(section.name) {

  case 'Skills':
    sectionComponent = (
      <ResumeSkills
        resumeSkills={resume.skills}/>
    );
    break;

  case 'Education':
    sectionComponent = (
      <ResumeEducation
        resumeEducation={resume.education}/>
    );
    break;

  case 'Experience':
    sectionComponent = (
      <ResumeExperience
        resumeExperiences={resume.experience}/>
    );
    break;

  case 'Club':
    sectionComponent = (
      <ResumeClubs
        resumeClubs={resume.clubs}/>
    );
    break;

  case 'Certification':
    sectionComponent = (
      <ResumeCertifications
        resumeCertifications={resume.certifications}/>
    );
    break;

  case 'Award':
    sectionComponent = (
      <ResumeAwards
        resumeAwards={resume.awards}/>
    );
    break;

  case 'Publication':
    sectionComponent = (
      <ResumePublications
        resumePublications={resume.publications}/>
    );
    break;

  case 'Project':
    sectionComponent = (
      <ResumeProjects
        resumeProjects={resume.projects}/>
    );
    break;

  case 'Association':
    sectionComponent = (
      <ResumeAssociations
        resumeAssociations={resume.associations}/>
    );
    break;

  default:
    sectionComponent = null;
  }

  return sectionComponent;
};

PreviewResumeSectionCardContainer.propTypes = {
  resume: PropTypes.object.isRequired,
  resumeOrder: PropTypes.array,
};

export default PreviewResumeSectionCardContainer;
