import PropTypes from 'prop-types';
import React from 'react';

import Following from '../../../containers/profile/connects/Following';
import PaginationHandler from '../../common/PaginationHandler';

const FollowingCardRenderer = ({
  following,
  selectFollower,
  selected,
  isDeleting,
  onNext,
  onPrev,
  onSelectPage,
  page,
  page_count
}) => {
  return(
    <div className="follow-list-wrapper">
      {
        following.data.length > 0 &&
          <div className="follow-list">
            {following.data.map((follower, index) =>
              (<Following
                key={index}
                isDeleting={isDeleting}
                profile={follower}
                selectFollower={selectFollower}
                selected={selected}/>))}

            {following.data.length > 0 &&
              <PaginationHandler
                onNext={onNext}
                onPrev={onPrev}
                onSelectPage={onSelectPage}
                page={page}
                page_count={page_count}/>}
          </div>
      }
    </div>
  );
};

FollowingCardRenderer.propTypes = {
  following: PropTypes.object,
  selectFollower: PropTypes.func,
  selected: PropTypes.array,
  isDeleting: PropTypes.object,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  onSelectPage: PropTypes.func.isRequired,
  page: PropTypes.number,
  page_count: PropTypes.number
};

export default FollowingCardRenderer;
