import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toastr } from 'react-redux-toastr';
import { isNull } from 'lodash';

import * as filesActions from '../../redux/actions/files';
import * as skillActions from '../../redux/actions/skills';
import * as componentActions from '../../redux/actions/components';

import FileDetailsModal from './FileDetailsModal';
import FileCard from '../../components/portfolio/FileCard';
import ConfirmModal from '../../components/portfolio/ConfirmModal';
import AddPortfolioModal from '../../components/portfolio/AddPortfolioModal';
import SharePortfolioModal from './SharePortfolioModal';

class FileCardHandler extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    userId: PropTypes.string,
    files: PropTypes.object.isRequired,
    fileItem: PropTypes.object,
    modal: PropTypes.string,
    bakedBadge: PropTypes.bool,
    onLikeEvidenceFile: PropTypes.func,
    isLiking: PropTypes.object.isRequired,
    isOwner: PropTypes.bool,
    skillEvidence: PropTypes.bool,
    onUpdateFileLike: PropTypes.func,
    searchPage: PropTypes.bool,
    onPortfolioSearchLike: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      viewingFileId: null,
      isEditingCard: false,
      guideLinesTooltipOpen: false,
    };

    this.onViewFile = this.onViewFile.bind(this);
    this.onCopy = this.onCopy.bind(this);
    this.onEditFile = this.onEditFile.bind(this);
    this.onUpdateFileLike = this.onUpdateFileLike.bind(this);
    this.onShareToCp = this.onShareToCp.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onCancelModal = this.onCancelModal.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.toggleGuidelinesTooltip = this.toggleGuidelinesTooltip.bind(this);
  }

  componentWillUnmount() {
    if(this.props.modal)
      this.props.actions.closeModal();
  }
  onCopy() {
    this.setState({ copied: true });
    toastr.success('Link successfully copied!');
  }
  onShareToCp(){
    this.setState({
      showShareDropdown: false
    },() => this.props.actions.openModal(`share-file-details-to-cp-${this.props.fileItem.id}`));
  }

  onEditFile() {
    this.setState({ isEditingCard: true });
  }

  onCancel() {
    this.setState({ isEditingCard: false });
  }

  onCancelModal(){
    this.props.actions.closeModal();
  }

  onViewFile(id){
    return () => {
      const { actions } = this.props;
      this.setState({
        viewingFileId: id
      }, () => actions.openModal(`open-view-file-modal-${id}`));
    };
  }

  onConfirmDelete(){
    const { actions, fileItem } = this.props;

    actions.deleteFile(fileItem.id)
      .then(() => {
        this.props.actions.closeModal();
        toastr.success('File deleted successfully');
      });
  }

  toggleGuidelinesTooltip(){
    this.setState({
      guideLinesTooltipOpen: !this.state.guideLinesTooltipOpen
    });
  }

  onUpdateFileLike(fileId){
    const {
      skillEvidence,
      onUpdateFileLike,
      actions,
      fileItem,
      bakedBadge,
      onLikeEvidenceFile,
      searchPage,
      onPortfolioSearchLike
    } = this.props;

    if(skillEvidence){
      return onUpdateFileLike(fileId);
    }

    if(searchPage){
      return onPortfolioSearchLike(fileId);
    }

    return () => {
      if(!this.props.userId){
        return toastr.error('Please Login/Signup');
      }

      if(bakedBadge){
        return onLikeEvidenceFile(fileId);
      }

      if(fileItem.liked){
        return actions.unlikeFile(fileId);
      }

      const data = {
        'file': fileId
      };

      actions.likeFile(data);
    };
  }

  render() {
    const {
      fileItem,
      modal,
      files,
      userId,
      isLiking,
      isOwner,
      skillEvidence
    } = this.props;

    const { isEditingCard, guideLinesTooltipOpen, viewingFileId } = this.state;
    const isViewPortfolioModalOpen = ((modal && modal === `open-view-file-modal-${fileItem.id}`) && (! isNull(viewingFileId)));
    const isDeleteModalOpen = modal && modal === `delete-portfolio-modal-${fileItem.id}`;
    const isShareModalOpen = modal && modal === `share-file-details-to-cp-${fileItem.id}`;
    const [,,slug] = location.pathname.split('/');
    const visitingUser = slug && userId !== slug && !isOwner;
    const isLikingFile = isLiking.status && isLiking.id === fileItem.id;

    return(
      <div className="portfolio-grid-item">
        {isEditingCard &&
          <AddPortfolioModal
            isOpen={isEditingCard}
            onCancel={this.onCancel}
            isEditingCard={isEditingCard}
            guideLinesTooltipOpen={guideLinesTooltipOpen}
            toggleGuidelinesTooltip={this.toggleGuidelinesTooltip}
            fileItem={fileItem}
            skillEvidence={skillEvidence}/>
        }
        <FileCard
          fileItem={fileItem}
          onUpdateFileLike={this.onUpdateFileLike}
          onShareToCp={this.onShareToCp}
          onEditFile={this.onEditFile}
          onViewFile={this.onViewFile}
          onCopy={this.onCopy}
          visitingUser={visitingUser}
          isLikingFile={isLikingFile}
          setSkillBeingUpdatedInStore={this.setSkillBeingUpdatedInStore}/>

        {
          isShareModalOpen &&
            <SharePortfolioModal
              isOpen={isShareModalOpen}
              fileData={fileItem}/>
        }

        {
          isViewPortfolioModalOpen &&
            <FileDetailsModal
              fileId={viewingFileId}
              onEditFile={this.onEditFile}
              isOpen={isViewPortfolioModalOpen}/>
        }
        {
          isDeleteModalOpen &&
            <ConfirmModal
              isOpen={isDeleteModalOpen}
              isConfirming={files.isDeleting.status}
              confirminginProgressText="Deleting..."
              modalHeaderText="Delete portfolio file"
              modalMessage="Are you sure you want to delete this portfolio file?"
              modalFooterButtonText="Yes, Delete"
              onCancel={this.onCancelModal}
              onConfirm={this.onConfirmDelete}/>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    files: state.files,
    modal: state.components.modal,
    isLiking: state.files.isLiking,
    userId: state.auth.data.currentUser.id
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, filesActions, skillActions, componentActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileCardHandler);
