import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getClusters,
  getIndustries,
  getOccupations,
  addIR,
  getIRDataBySearch,
} from '../../redux/actions/clusterIndustryOccupation';

import { getCareerClusters } from '../../redux/actions/clusters';
import { resetIndustries, getCareerIndustries } from '../../redux/actions/industries';
import { resetOccupations, getCareerOccupations } from '../../redux/actions/occupations';

import { sendMessage } from '../../redux/actions/messages';
import { profileRequest, getCompaniesList } from '../../redux/actions/profile';
import { saveFollowing, deleteFollowing } from '../../redux/actions/following';
import { getManageIRList, addExistingIR, clearIRList, removeExistingIR } from '../../redux/actions/irs';

import { getCountries } from '../../redux/actions/countries';
import { getStates } from '../../redux/actions/states';
import { getCities } from '../../redux/actions/cities';

import {
  selectFormattedCountries,
  selectCountriesPageCount
} from '../../redux/selectors/countries';
import {
  selectFormattedStates,
  selectStatesPageCount
} from '../../redux/selectors/states';
import {
  selectFormattedCities,
  selectCitiesPageCount
} from '../../redux/selectors/cities';

import {
  selectFormattedClusters,
  selectClustersPageCount
} from '../../redux/selectors/clusters';
import {
  selectFormattedIndustries,
  selectIndustriesPageCount
} from '../../redux/selectors/industries';
import {
  selectFormattedOccupations,
  selectOccupationsPageCount
} from '../../redux/selectors/occupations';

import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { isEmpty, isUndefined } from 'lodash';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup,
  Container,
} from 'reactstrap';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';

import SearchInput from '../../components/admin/users-list/search/SearchInput';
import {
  IR_SUCCESS,
  IR_REMOVE,
  CREATE_IR_CONFIRMATION,
  CANCEL,
  IR_FORM,
  ADD_IR,
  ADD,
  ADD_IR_REP,
} from '../../utilConstants';
import Interests from '../../components/myskills/skillCardComponents/Interests';
import validator from '../../validator';
import { validateCluster } from '../../validator/clusterValidator';
import IRTable from '../../components/manage-ir/IrTable';
import SendMessageModal from '../../components/manage-ir/SendMessageModal';

import CloseIcon from '../../assets/images/close.svg';

class ManageIRs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clusterIndustryList: [{}],
      countries: [],
      cities: [],
      isOpen: false,
      confirmationModalIsOpen: false,
      errors: [],
      clusterErrors: [],
      consolidatedClusterError: [],
      consolidatedClusterErrorDetails: [],
      isWorkEmailSelected: false,
      workEmail: '',
      viewIRList: [],
      isOpenIRAction: '',
      industryPopOver: '',
      clusterPopOver: '',
      showMessageModal: false,
      selectedUserId: '',
      isFollowing: false,
      followingUserName: '',
      followerConnectId: '',
      sort: { column: '', asc: '' },
      searchText: '',
      addBtnText: 'Yes, Add',
      addBtnClicked: false,
      isSearching: true,
      clusterId: '',
      countryId: '',
      stateProvinceId: ''
    };

    this.defaultValue = {
      label: '',
      value: '',
    };

    this.loadOptions = this.loadOptions.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);
    this.loadLocationOptions = this.loadLocationOptions.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
    this.onChangeCareerClusters = this.onChangeCareerClusters.bind(this);
  }

  componentDidMount() {
    const { actions } = this.props;

    if (!isEmpty(this.props.selectedInstitution)) {
      const institutionId = this.props.selectedInstitution.id;
      actions.getManageIRList(institutionId, '').then(() => {
        this.setState({ isSearching: false });
      });
    }
    actions.getCompaniesList();
  }

  componentWillReceiveProps(nextProps) {
    if (isEmpty(this.state.viewIRList) && nextProps.manageIrs) {
      this.setState({ viewIRList: nextProps.manageIrs });
    }
  }

  componentWillUnmount() {
    this.props.actions.clearIRList();
  }

  toggleLoading = () =>
    this.setState(state => ({ isLoading: !state.isLoading }));

  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.clusterIndustryList];
    list[index][name] = value;
    this.setState({ clusterIndustryList: list });
  };

  handleAddClick = () => {
    this.setState({
      clusterIndustryList: [...this.state.clusterIndustryList, {}],
    });
  };

  // handle click event of the Remove button
  handleRemoveClick = (e) => {
    const index = e.target.getAttribute('data-value');

    const list = [...this.state.clusterIndustryList];
    if (this.state.clusterIndustryList.length > 1) {
      list.splice(index, 1);
      this.setState({ clusterIndustryList: list });
    } else {
      this.setState({
        clusterIndustryList: [{}],
      });
    }
  };

  // handle click event of the Remove button
  handleRemoveAll = () => {
    this.setState({
      clusterIndustryList: [{}],
    });
  };

  async loadLocationOptions(search, loadedOptions, actionMeta) {
    const { page, name: inputName } = actionMeta;

    if (inputName === 'country') {
      await this.props.actions.getCountries(page, search);
    }

    if (inputName === 'stateProvince' && (! isUndefined(this.state.country))) {
      await this.props.actions.getStates(this.state.country.value, page, search);
    }

    if (inputName === 'city' && (! isUndefined(this.state.stateProvince))) {
      await this.props.actions.getCities(this.state.stateProvince.value, page, search);
    }

    const options = (inputName === 'country') ?
      this.props.countries : (inputName === 'stateProvince') ?
        this.props.states : this.props.cities;

    const pageCount = (inputName === 'country') ?
      this.props.countriesPageCount : (inputName === 'stateProvince') ?
        this.props.statesPageCount : this.props.citiesPageCount;

    return {
      options,
      hasMore: pageCount > page,
      additional: { page: page + 1, name: inputName }
    };
  }

  onChangeLocation(value, actionMeta) {
    const { name } = actionMeta;

    const city = null;
    const stateProvince = null;

    this.setState({
      [name]: value,
      [`${name}Id`]: value.id,
      ...((name === 'country') && { stateProvince, city }),
      ...((name === 'stateProvince') && { city })
    });
  }

  async loadOptions(search, loadedOptions, actionMeta) {
    const { page, index, name: inputName } = actionMeta;
    const { inputList, clusterIndustryList, isWorkEmailSelected } = this.state;
    const clusterStore = isWorkEmailSelected ? inputList : clusterIndustryList;
    const inputData = clusterStore[index];

    if (inputName === 'cluster') {
      await this.props.actions.getCareerClusters(page, search);
    }

    if (inputName === 'industry' && (! isUndefined(inputData.cluster))) {
      await this.props.actions.getCareerIndustries(inputData.cluster.value, page, search);
    }

    if (inputName === 'occupation' && (! isUndefined(inputData.industry))) {
      await this.props.actions.getCareerOccupations(inputData.industry.value, page, search);
    }

    const options = (inputName === 'cluster') ?
      this.props.clusters : (inputName === 'industry') ?
        this.props.industries : this.props.occupations;

    const pageCount = (inputName === 'cluster') ?
      this.props.clustersPageCount : (inputName === 'industry') ?
        this.props.industriesPageCount : this.props.occupationsPageCount;

    return {
      options,
      hasMore: pageCount > page,
      additional: { page: page + 1, name: inputName }
    };
  }

  onChangeCareerClusters(elemValue, actionMeta) {
    const { name: inputName } = actionMeta;
    const { clusterIndustryList } = this.state;

    const industry = null;
    const [name, indexString] = inputName.split('-');
    const index = parseInt(indexString);

    const list = [
      ...clusterIndustryList.slice(0, index),
      {
        ...clusterIndustryList[index],
        [name]: { index, ...elemValue },
        ...((name === 'cluster') && { industry })
      },
      ...clusterIndustryList.slice(index + 1)
    ];

    this.setState({ clusterIndustryList: list, [`${name}Id`]: elemValue.id });
  }

  handleOccupationChange = (e) => {
    const index = e.index;
    const list = [...this.state.clusterIndustryList];
    list[index].occupation = e;
    this.setState({ clusterIndustryList: list });
  };

  onInputChange = (option, { action }) => {
    if (action === 'input-change') {
      const optionLength = option.length;

      if (optionLength > 2) {
        this.props.actions.getIRDataBySearch(option);
      }
      this.setState({
        workEmail: option,
        isWorkEmailSelected: false,
      });
    }
  };

  handleSelectData = (selectedOption) => {
    this.setState({ ...selectedOption, isWorkEmailSelected: true });
  }

  handleFirstNameInput = (e) => {
    const firstName = e.target.value;
    this.setState({
      firstName,
    });
  };

  handleLastNameInput = (e) => {
    const lastName = e.target.value;
    this.setState({
      lastName,
    });
  };

  handleJobTitleInput = (e) => {
    const jobTitle = e.target.value;
    this.setState({
      jobTitle,
    });
  };

  onInputChangeCompany = (option, { action }) => {
    if (action === 'input-change') {
      this.setState({
        company: option
      });
    }
  };

  handleCompanyInput = (selectedOption) => {
    this.setState({ company: selectedOption.value });
  }

  validateIRForm = () => {
    const rules = {
      workEmail: [
        'required',
        'email',
        `isNotSelfEmail|${this.props.profile.email}`
      ],
      firstName: [
        'required',
        'minLength|1',
        'maxLength|60',
        'stringWithHyphenSpace',
      ],
      lastName: [
        'required',
        'minLength|1',
        'maxLength|60',
        'stringWithHyphenSpace',
      ],
      jobTitle: ['maxLength|100'],
      company: ['required'],
      country: ['required'],
      stateProvince: ['required'],
      city: ['required'],
    };
    const validate = validator.createValidator(rules, this.state);
    this.isValidClusterInput();
    return validate;
  };

  isValidClusterInput = () => {
    const { clusterIndustryList } = this.state;
    const stateInputErrors = [];
    const clusterRules = {
      cluster: ['required'],
      industry: ['required']
    };

    let isValidCluster = true;

    clusterIndustryList.filter((element, index) => {
      const validate = validator.createValidator(clusterRules, element);
      const { errors, isValid } = validate;
      if (!isValid) {
        isValidCluster = isValid;
        const errorField = Object.keys(errors)[0];
        const inputDataError = {
          index: index,
          message: errors[errorField],
          field: errorField,
        };

        stateInputErrors.push(inputDataError);
        return false;
      }
      return true;
    });
    if (!isEmpty(stateInputErrors)) {
      this.setState({ clusterErrors: stateInputErrors });
    }
    return isValidCluster;
  };

  addIndustryRepresentative = () => {
    this.setState({
      errors: [],
      clusterErrors: [],
      consolidatedClusterError: [],
      consolidatedClusterErrorDetails: [],
    });

    const { errors, isValid } = this.validateIRForm();
    if (!isValid) {
      this.setState({ errors: errors });
      this.toggleConfirmationModal();
      return;
    } else if (!this.state.isWorkEmailSelected) {
      const isValidCluster = this.isValidClusterInput();
      if (!isValidCluster) {
        this.toggleConfirmationModal();
        return;
      }
    }

    const clusterValidation = validateCluster(this.state.clusterIndustryList);
    if (!clusterValidation.isValid) {
      this.setState({
        consolidatedClusterError: clusterValidation.errorMessage,
        consolidatedClusterErrorDetails: clusterValidation.details
      });
      this.toggleConfirmationModal();
      return;
    }

    const {
      selectedInstitution: { id: institutionId },
    } = this.props;

    if (!this.state.isWorkEmailSelected) {
      const reqObj = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        workEmail: this.state.workEmail,
        industries: this.state.clusterIndustryList.map(elem => elem.industry.value).toString(),
        careerCluster: this.state.clusterIndustryList.map((input) => {
          return {
            clusterId: input.cluster.value,
            industryId: input.industry.value,
            clusterName: input.cluster.label,
            industryName: input.industry.label
          };
        }),
        jobTitle: this.state.jobTitle,
        company: this.state.company,
        city: this.state.city.value,
        state: this.state.stateProvince.stateName || this.state.stateProvince.value,
        country: this.state.country.countryName || this.state.country.value,
        institution: institutionId,
      };

      this.setState({ addBtnText: 'Adding...', addBtnClicked: true });
      this.props.actions.addIR(reqObj).then(() => {
        toastr.success(IR_SUCCESS);
        this.toggleModal();
        this.toggleConfirmationModal();
        this.setState({ addBtnText: 'Yes, Add', addBtnClicked: false });
      }).catch(() => {
        this.toggleModal();
        this.toggleConfirmationModal();
        this.setState({ addBtnText: 'Yes, Add', addBtnClicked: false });
      });
    } else {
      const reqObject = {
        industry_representative: this.state.industryRepId,
        institution: institutionId,
      };
      this.setState({ addBtnText: 'Adding...', addBtnClicked: true });
      this.props.actions.addExistingIR(reqObject).then(() => {
        toastr.success(IR_SUCCESS);
        this.toggleModal();
        this.toggleConfirmationModal();
        this.setState({ addBtnText: 'Yes, Add', addBtnClicked: false });
      }).catch(() => {
        this.toggleModal();
        this.toggleConfirmationModal();
        this.setState({ addBtnText: 'Yes, Add', addBtnClicked: false });
      });
    }
  };

  toggleModal = () => {
    this.setState({
      isOpen: false,
      errors: [],
      clusterErrors: [],
      clusterIndustryList: [{}],
      isWorkEmailSelected: false,
      workEmail: '',
      firstName: '',
      lastName: '',
      jobTitle: '',
      company: '',
      country: '',
      stateProvince: '',
      city: '',
    });
  };

  toggleConfirmationModal = () => {
    this.setState({
      confirmationModalIsOpen: !this.state.confirmationModalIsOpen,
    });
  };

  confirmationModal = () => {
    return (
      <Modal centered className="" isOpen={this.state.confirmationModalIsOpen}>
        <div className="modal-header">
          <span className="modal-title">{CREATE_IR_CONFIRMATION}</span>
          <div
            onClick={this.toggleConfirmationModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">
              <img src={CloseIcon} />{' '}
            </span>
          </div>
        </div>
        <ModalFooter className="bordertop-0">
          <button
            type="button"
            onClick={this.toggleConfirmationModal}
            className="btn btn-secondary">
            {CANCEL}
          </button>
          <Button
            className="ml-1 sendButton"
            onClick={this.addIndustryRepresentative}
            disabled={this.state.addBtnClicked}>
            {this.state.addBtnText}
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  renderClusterIndustryList = () => {
    const { isRequesting } = this.props;
    const { clusterId, industryId, clusterErrors, isWorkEmailSelected, inputList, clusterIndustryList, consolidatedClusterError, consolidatedClusterErrorDetails } = this.state;
    const clusterStore = isWorkEmailSelected ? inputList : clusterIndustryList;
    return clusterStore.map((inputData, index) => {
      const clusterError = clusterErrors.filter(
        error => error.index === index
      );

      const isDuplicate = consolidatedClusterErrorDetails.filter(errorDetails => errorDetails.industry.index === index);
      return (
        <div key={`interests-${index}`} className={`${!isEmpty(isDuplicate) ? 'is-invalid' : ''}`}>
          <Interests
            index={index}
            clusterId={clusterId}
            industryId={industryId}
            key={`${index}-interests`}
            loadOptions={this.loadOptions}
            onChangeCareerClusters={this.onChangeCareerClusters}
            handleOccupationChange={this.handleOccupationChange}
            isDisabled={isRequesting || this.state.isWorkEmailSelected}
            isLoading={isRequesting}
            inputData={inputData}
            handleRemoveClick={this.handleRemoveClick}
            handleRemoveAll={this.handleRemoveAll}
            clusterError={clusterError}
            hideOccupation />
          <div className="form-control-feedback">
            {!isEmpty(isDuplicate) ? consolidatedClusterError : ''}
          </div>
        </div>
      );
    });
  };

  renderModal = () => {
    const { searchList } = this.props;
    const { isOpen, errors, countryId, stateProvinceId, isWorkEmailSelected } = this.state;

    return (
      <Modal
        id="addIRModal"
        centered
        className="customLevelUpModal modal-lg"
        isOpen={isOpen}>
        <div className="modal-header">
          <span className="modal-title">{ADD_IR}</span>
          <div
            onClick={this.toggleModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">
              <img src={CloseIcon} />
            </span>
          </div>
        </div>
        <ModalBody>
          <Form>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="firstName">{IR_FORM.firstName}</Label>
                  <Input
                    type="text"
                    name="firstName"
                    id="firstName"
                    defaultValue={this.state.firstName}
                    placeholder={IR_FORM.firstName}
                    readOnly={isWorkEmailSelected}
                    invalid={!isEmpty(errors) && !isEmpty(errors.firstName)}
                    onChange={this.handleFirstNameInput} />
                  <div className="form-control-feedback">
                    {errors?.firstName}
                  </div>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="lastName">{IR_FORM.lastName}</Label>
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    defaultValue={this.state.lastName}
                    placeholder={IR_FORM.lastName}
                    readOnly={isWorkEmailSelected}
                    invalid={!isEmpty(errors) && !isEmpty(errors.lastName)}
                    onChange={this.handleLastNameInput} />
                  <div className="form-control-feedback">
                    {errors?.lastName}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label for="workEmail">{IR_FORM.email}</Label>
                  <Select
                    autoFocus
                    closeMenuOnSelect={false}
                    placeholder={IR_FORM.email}
                    onInputChange={this.onInputChange}
                    onChange={this.handleSelectData}
                    classNamePrefix="select"
                    className={`form-group mb-1 ${!isEmpty(errors) && errors.workEmail ? 'is-invalid' : ''}`}
                    options={searchList}
                    inputValue={this.state.workEmail}
                    invalid={!isEmpty(errors) && errors.workEmail} />

                  <div className="form-control-feedback">
                    {errors?.workEmail}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <label>
              Add career clusters and industries this Industry Mentor represents
            </label>
            <div>
              {this.renderClusterIndustryList()}
            </div>
            {!isWorkEmailSelected && (
              <div className="text-right d-flex mr-5 pr-3 justify-content-end">
                <span
                  className="btn btn-link myFeedbackLink font12 d-block pr-0"
                  onClick={this.handleAddClick}>
                  <i className="fa fa-plus-square mt-1 mr-1" />
                  {` ${ADD}`}
                </span>
              </div>
            )}

            <FormGroup>
              <Label for="jobTitle">{IR_FORM.jobTitle}</Label>
              <Input
                type="text"
                name="jobTitle"
                id="jobTitle"
                defaultValue={this.state.jobTitle}
                placeholder={IR_FORM.jobTitle}
                readOnly={isWorkEmailSelected}
                invalid={!isEmpty(errors) && !isEmpty(errors.jobTitle)}
                onChange={this.handleJobTitleInput} />
              <div className="form-control-feedback">{errors?.jobTitle}</div>
            </FormGroup>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="companyName">{IR_FORM.companyName}</Label>
                  {isWorkEmailSelected ?
                    <Input
                      type="text"
                      name="companyName"
                      id="companyName"
                      invalid={!isEmpty(errors) && !isEmpty(errors.company)}
                      defaultValue={this.state.company}
                      readOnly />
                    :
                    <Select
                      closeMenuOnSelect={false}
                      placeholder={IR_FORM.companyName}
                      onInputChange={this.onInputChangeCompany}
                      onChange={this.handleCompanyInput}
                      classNamePrefix="select"
                      className={`basic-single mb-1 ${!isEmpty(errors) && errors.company ? 'is-invalid' : ''}`}
                      options={this.props.companies || []}
                      inputValue={this.state.company}
                      value={this.state.company}
                      onSelectResetsInput={false}
                      onBlurResetsInput={false}
                      invalid={!isEmpty(errors) && errors.company} />
                  }
                  <div className="form-control-feedback">{errors?.company}</div>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="country">{IR_FORM.country}</Label>

                  <AsyncPaginate
                    cacheOptions
                    isSearchable
                    name="country"
                    classNamePrefix="select"
                    className={`basic-single ${errors?.country ? 'is-invalid' : ''}`}
                    additional={{ page: 1, name: 'country' }}
                    isClearable={false}
                    loadOptions={this.loadLocationOptions}
                    placeholder="Country"
                    hideSelectedOptions={false}
                    value={this.state.country}
                    onChange={this.onChangeLocation} />

                  <div className="form-control-feedback">{errors?.country}</div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="stateProvince">{IR_FORM.stateProvince}</Label>

                  <AsyncPaginate
                    cacheOptions
                    isSearchable
                    name="stateProvince"
                    classNamePrefix="select"
                    className={`basic-single ${
                      errors?.country || errors?.stateProvince ? 'is-invalid' : ''
                    }`}
                    cacheUniqs={[countryId]}
                    additional={{ page: 1, name: 'stateProvince' }}
                    isClearable={false}
                    loadOptions={this.loadLocationOptions}
                    placeholder={IR_FORM.stateProvince}
                    hideSelectedOptions={false}
                    value={this.state.stateProvince}
                    onChange={this.onChangeLocation} />

                  <div className="form-control-feedback">
                    {errors?.stateProvince}
                  </div>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="city">{IR_FORM.city}</Label>

                  <AsyncPaginate
                    cacheOptions
                    isSearchable
                    name="city"
                    classNamePrefix="select"
                    className={`basic-single ${
                      errors?.country || errors?.stateProvince || errors?.city ? 'is-invalid' : ''
                    }`}
                    cacheUniqs={[stateProvinceId]}
                    additional={{ page: 1, name: 'city' }}
                    isClearable={false}
                    loadOptions={this.loadLocationOptions}
                    placeholder={IR_FORM.city}
                    hideSelectedOptions={false}
                    value={this.state.city}
                    onChange={this.onChangeLocation} />

                  <div className="form-control-feedback">{errors?.city}</div>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter className="bordertop-0">
          <button className="btn btn-secondary" onClick={this.toggleModal}>
            {CANCEL}
          </button>
          <Button
            disabled={isEmpty(this.state.workEmail)}
            onClick={this.toggleConfirmationModal}
            className="ml-1 sendButton"
            color="warning">
            {ADD}
          </Button>
        </ModalFooter>
      </Modal>
    );
  };
  openModalTruthy = () => this.setState({ isOpen: true, isOpenIRAction: '', industryPopOver: '', clusterPopOver: '' });

  toggleIRViewActions = (irId, name, connectId = '') => {
    return () => {
      if (irId === this.state.isOpenIRAction) {
        this.setState({ isOpenIRAction: '' });
        return;
      }

      this.setState({ isOpenIRAction: irId, industryPopOver: '', clusterPopOver: '', selectedUserId: irId, followingUserName: name, followerConnectId: connectId });
    };
  }

  toggleIRViewCluster = (irId) => {
    return () => {
      if (irId === this.state.clusterPopOver) {
        this.setState({ clusterPopOver: '' });
        return;
      }

      this.setState({ clusterPopOver: irId, industryPopOver: '' });
    };
  }

  toggleIRViewIndustry = (irId) => {
    return () => {
      if (irId === this.state.industryPopOver) {
        this.setState({ industryPopOver: '' });
        return;
      }

      this.setState({ industryPopOver: irId, clusterPopOver: '' });
    };
  }

  onToggleMessagingModal = () => {
    this.setState({
      showMessageModal: !this.state.showMessageModal,
    });
  }

  onFollow = async () => {
    const { actions, userId } = this.props;
    const { selectedUserId, followingUserName, followerConnectId } = this.state;
    if (!isEmpty(followerConnectId)) {
      // to unfollow an IR
      await actions.deleteFollowing(followerConnectId);
      await this.fetchLatestIRDetails();
      actions.profileRequest(userId);
      toastr.success('', `Successfully unfollowed ${followingUserName}.`);
      return;
    }

    // to follow an IR
    const data = {
      user: selectedUserId,
    };
    this.setState({ isFollowing: true });
    await actions.saveFollowing(data);
    toastr.success('', `Successfully followed ${followingUserName}.`);
    this.setState({ isFollowing: false });
    await this.fetchLatestIRDetails();
    actions.profileRequest(userId);
  }

  fetchLatestIRDetails = async () => {
    if (!isEmpty(this.props.selectedInstitution)) {
      const institutionId = this.props.selectedInstitution.id;
      this.setState({ isSearching: true });
      await this.props.actions.getManageIRList(institutionId, '').then(() => {
        this.setState({ isSearching: false });
      });
      this.setState({ viewIRList: this.props.manageIrs });
    }
  }

  removeIRView = async (id) => {
    const {
      selectedInstitution: { id: institutionId },
    } = this.props;

    const reqObject = {
      industry_representative: id,
      institution: institutionId,
    };
    this.props.actions.removeExistingIR(reqObject).then(() => {
      toastr.success(IR_REMOVE);
      this.fetchLatestIRDetails();
    });
  }

  onSearchInput = async (search) => {
    const {
      selectedInstitution: { id: institutionId },
    } = this.props;
    if (institutionId) {
      this.setState({ isSearching: true });
      await this.props.actions.getManageIRList(institutionId, search).then(() => {
        this.setState({ isSearching: false });
      });
      this.setState({ viewIRList: this.props.manageIrs, searchText: search });
    }
  }

  // Manage IR -- Table Sorting
  onSortTable = async (event) => {
    const column = event.currentTarget.dataset['columnName'];
    const { selectedInstitution, actions, page } = this.props;
    let urlParamString;
    const asc = (!this.state.sort.asc);

    actions.clearIRList();

    this.setState({ sort: { column, asc }, isSearching: true });

    if (column.length !== 0) {
      const sortParam = asc ? 'asc' : 'desc';

      urlParamString = `&sort=${column}-${sortParam}&page=${page}`;
    }

    if (!isEmpty(selectedInstitution)) {
      const institutionId = selectedInstitution.id;
      await actions.getManageIRList(institutionId, this.state.searchText, urlParamString).then(() => {
        this.setState({ isSearching: false });
      });
      this.setState({ viewIRList: this.props.manageIrs });
    }
  }

  onNext = async () => {
    const { page: currentPage, actions } = this.props;
    const page = currentPage + 1;
    const {
      selectedInstitution: { id: institutionId },
    } = this.props;
    if (institutionId) {
      let urlParams = this.generateURLParams();
      urlParams += `&page=${page}`;
      this.setState({ isSearching: true });
      await actions.getManageIRList(institutionId, this.state.searchText, urlParams).then(() => {
        this.setState({ isSearching: false });
      });
      this.setState({ viewIRList: this.props.manageIrs });
    }
  }

  onPrev = async () => {
    const { page: currentPage, actions } = this.props;
    const page = currentPage - 1;
    const {
      selectedInstitution: { id: institutionId },
    } = this.props;
    if (institutionId) {
      let urlParams = this.generateURLParams();
      urlParams += `&page=${page}`;
      this.setState({ isSearching: true });
      await actions.getManageIRList(institutionId, this.state.searchText, urlParams).then(() => {
        this.setState({ isSearching: false });
      });
      this.setState({ viewIRList: this.props.manageIrs });
    }
  }

  onSelectPage = (page) => {
    return () => {
      const { actions } = this.props;
      const {
        selectedInstitution: { id: institutionId },
      } = this.props;
      if (institutionId) {
        let urlParams = this.generateURLParams();
        urlParams += `&page=${page}`;
        this.setState({ isSearching: true });
        actions.getManageIRList(institutionId, this.state.searchText, urlParams).then(() => {
          this.setState({ viewIRList: this.props.manageIrs, isSearching: false });
        });
      }
    };
  }

  generateURLParams = () => {
    const sort = this.state.sort;
    let urlParams = '';
    if (!isEmpty(sort.column)) {
      const sortParam = sort.asc ? 'asc' : 'desc';
      urlParams = `&sort=${sort.column}-${sortParam}`;
    }

    return urlParams;
  }

  render() {
    const { viewIRList, isOpenIRAction, industryPopOver, clusterPopOver, showMessageModal, selectedUserId, isFollowing, followingUserName } = this.state;
    const { page, pageCount } = this.props;
    return (
      <div className="admin-dashboard">
        <div className="interior invite-users mt-1">
          <Container>
            <Row className="mb-3 mt-4">
              <Col sm="12" className="mb-3 mb-md-0 mb-lg-0">
                <div className="groupName">Manage Industry Mentors</div>
                <div className="group-content mb-3">
                  <p>
                    From this page, add and manage Industry Mentors you know and trust who are willing to give feedback on your users' evidence of claimed skills. Once added, users can request their feedback on any skill or you can request feedback on their behalf. Get industry directly involved in closing the skills gap and better understand industry perceptions of your users' claimed skills.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <h2>
                  Industry Mentors {' '}
                  <Button
                    onClick={this.openModalTruthy}
                    color="primary"
                    className="pull-right">
                    <i className="fa fa-plus text-white" />{' '}{ADD_IR_REP}
                  </Button>
                </h2>
              </Col>
            </Row>
            <Row className="mb-3 mt-4">
              <Col sm="8" className="mb-3 mb-md-0 mb-lg-0">
                <SearchInput
                  placeholder="Search Industry Mentor"
                  searchManageIR={this.onSearchInput}
                  isRequestingIR={this.state.isSearching} />
              </Col>
            </Row>
            <Row className="manageIRTableContainerStyle">
              <Col id="myfeedback" className="cp-custom-table">
                {
                  <IRTable manageIRs={viewIRList}
                    toggleIRViewActions={this.toggleIRViewActions}
                    toggleIRViewCluster={this.toggleIRViewCluster}
                    toggleIRViewIndustry={this.toggleIRViewIndustry}
                    onToggleMessagingModal={this.onToggleMessagingModal}
                    removeIRView={this.removeIRView}
                    isOpenIRAction={isOpenIRAction}
                    industryPopOver={industryPopOver}
                    clusterPopOver={clusterPopOver}
                    isFollowing={isFollowing}
                    onSortTable={this.onSortTable}
                    onFollow={this.onFollow}
                    page={page}
                    pageCount={pageCount}
                    onNext={this.onNext}
                    onPrev={this.onPrev}
                    onSelectPage={this.onSelectPage}
                    isSearching={this.state.isSearching}
                    searchText={this.state.searchText} />
                }
              </Col>
            </Row>
          </Container>
        </div>
        {this.state.isOpen && this.renderModal()}
        {this.state.confirmationModalIsOpen && this.confirmationModal()}
        {showMessageModal && <SendMessageModal
          onToggleMessagingModal={this.onToggleMessagingModal}
          showMessageModal={showMessageModal}
          selectedUserId={selectedUserId}
          sendMessage={this.props.actions.sendMessage}
          selectedUserName={followingUserName} />}
      </div>
    );
  }
}

ManageIRs.propTypes = {
  actions: PropTypes.object.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  clusters: PropTypes.array.isRequired,
  industries: PropTypes.array.isRequired,
  occupations: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  selectedInstitution: PropTypes.object,
  selectedGroup: PropTypes.object,
  profile: PropTypes.object,
  searchList: PropTypes.array,
  manageIrs: PropTypes.array,
  companies: PropTypes.array,
  countries: PropTypes.array,
  states: PropTypes.array,
  cities: PropTypes.array,
  page: PropTypes.number,
  pageCount: PropTypes.number,
  countriesPageCount: PropTypes.number.isRequired,
  statesPageCount: PropTypes.number.isRequired,
  citiesPageCount: PropTypes.number.isRequired,
  clustersPageCount: PropTypes.number.isRequired,
  industriesPageCount: PropTypes.number.isRequired,
  occupationsPageCount: PropTypes.number.isRequired
};

const mapStateToProps = (state) => {
  const {
    profile,
    institutions: { selectedInstitution },
    groups: { selectedGroup },
    irs: { manageIrs },
    auth: { data: { currentUser: { id: userId } } },
  } = state;

  return {
    isRequesting: state.clusterIndustryOccupation.isRequesting,
    searchList: state.clusterIndustryOccupation.data.searchList,
    profile: profile.data,
    companies: profile.companies || [],
    selectedGroup,
    selectedInstitution,
    manageIrs,
    page: state.irs.page,
    pageCount: state.irs.pageCount,
    userId,
    clusters: selectFormattedClusters(state),
    clustersPageCount: selectClustersPageCount(state),
    industries: selectFormattedIndustries(state),
    industriesPageCount: selectIndustriesPageCount(state),
    occupations: selectFormattedOccupations(state),
    occupationsPageCount: selectOccupationsPageCount(state),
    states: selectFormattedStates(state),
    statesPageCount: selectStatesPageCount(state),
    cities: selectFormattedCities(state),
    citiesPageCount: selectCitiesPageCount(state),
    countries: selectFormattedCountries(state),
    countriesPageCount: selectCountriesPageCount(state)
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getClusters,
      getIndustries,
      getOccupations,
      addIR,
      getManageIRList,
      getIRDataBySearch,
      addExistingIR,
      clearIRList,
      sendMessage,
      saveFollowing,
      deleteFollowing,
      removeExistingIR,
      profileRequest,
      getCompaniesList,
      getCountries,
      getStates,
      getCities,
      resetIndustries,
      resetOccupations,
      getCareerClusters,
      getCareerIndustries,
      getCareerOccupations
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageIRs);
