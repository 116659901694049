import initialState from './initialState';

import {
  SKILL_CATEGORIES_REQUEST,
  SKILL_CATEGORIES_SUCCESS,
  SKILL_CATEGORIES_FAILURE,

  SKILL_SUB_CATEGORIES_REQUEST,
  SKILL_SUB_CATEGORIES_SUCCESS,
  SKILL_SUB_CATEGORIES_FAILURE
} from '../../constants';

export default function reducer(state = initialState.skillCategories, action) {
  switch (action.type) {
  case SKILL_CATEGORIES_REQUEST:
    return Object.assign({}, state, { isRequestingCategories: true });

  case SKILL_CATEGORIES_SUCCESS:
    return Object.assign({}, state, {
      isRequestingCategories: false,
      categoriesPage: action.data.page,
      categoriesPageCount: action.data.page_count,
      categories: action.data._embedded.skill_categories
    });

  case SKILL_CATEGORIES_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequestingCategories: false
    });

  case SKILL_SUB_CATEGORIES_REQUEST:
    return Object.assign({}, state, { isRequestingSubCategories: true });

  case SKILL_SUB_CATEGORIES_SUCCESS:
    return Object.assign({}, state, {
      isRequestingSubCategories: false,
      subCategoriesPage: action.data.page,
      subCategoriesPageCount: action.data.page_count,
      subCategories: action.data._embedded.skill_categories
    });

  case SKILL_SUB_CATEGORIES_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequestingSubCategories: false
    });

  default:
    return state;
  }
}
