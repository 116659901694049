/*  This is a higher order Component that takes a Component (WrappedComponent) and returns a new component.
    The props to the original Component are passed through and accessesd with {...this.props}. It returns a link that will show
    a modal of tabbed examples of a given type.
    To use this pass the WrappedComponet as an argument i.e EditingWrapped = fromWithModal(WrappedComponent);
    and replace Wrapped component with this.EditingWrapped. Leave all the props the same and one --  exampleType={"Resume Certifications"}
    It will then render the show examples link which when clicked shows the modal of tabbed examples.
 */

import React, { Component } from 'react';
import ExampleModal from './ExampleModal';

const formWithModal = (WrappedForm) => {
  return class FormWithModal extends Component {
    constructor(props) {
      super(props);

    }

    render() {
      return (
        <div>
          <ExampleModal
            {...this.props} />
          <WrappedForm
            {...this.props} />
        </div>
      );
    }
  };
};

export default formWithModal;
