import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toastr } from 'react-redux-toastr';
import isEmpty from 'lodash/isEmpty';

import * as componentActions from '../../redux/actions/components';
import * as wallPostActions from '../../redux/actions/wall-posts';
import * as authActions from '../../redux/actions/authentication';

import TextArea from '../../components/common/TextArea';
import Validator from '../../validator';
import { Dropdown, DropdownToggle, DropdownMenu, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Button from '../../components/common/Button';

const visibilityOptionsArray = [
  {
    title:'Everyone (Public)',
    alias: 'public',
    value:'1'
  },
  {
    title:'Followers Only',
    alias: 'followers',
    value:'2'
  },
  {
    title:'Only Me',
    alias: 'private',
    value:'0'
  },
];


class SharePortfolioModal extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    shareData: PropTypes.object.isRequired,
    fileData: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool,
    isAuthenticating: PropTypes.bool,
    onClose: PropTypes.func
  }

  constructor(props, context) {
    super(props, context);

    this.state ={
      errors: {},
      visibilityDropdownOpen: false,
      shareData: Object.assign({}, this.props.shareData)
    };

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onShare = this.onShare.bind(this);
    this.onChangeShareData = this.onChangeShareData.bind(this);
    this.onToggleVisibilityDropdown = this.onToggleVisibilityDropdown.bind(this);
    this.onSelectVisibilty = this.onSelectVisibilty.bind(this);
  }

  isValid(field = null) {
    const { shareData, errors: prevErrors } = this.state;

    let validate = Validator.createValidator({
      email: ['required', 'email'],
      password: ['required', 'minLength|8']
    }, shareData, field);

    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  onChangeShareData(event) {
    let { value, name } = event.target;
    const { shareData } = this.state;

    shareData[name] = value;

    this.setState({ shareData }, () => this.isValid(name));
  }

  onToggleVisibilityDropdown() {
    const { visibilityDropdownOpen } = this.state;
    this.setState({
      visibilityDropdownOpen: !visibilityDropdownOpen
    });
  }

  onSelectVisibilty(id){

    return () => {
      const shareData = { ...this.state.shareData, permissions:id};

      this.setState({
        shareData,
        visibilityDropdownOpen: false
      });
    };
  }

  onCloseModal(){
    const { onClose, actions } = this.props;

    if(onClose){
      return onClose();
    }

    actions.closeModal();
  }

  onShare(){
    const { shareData } = this.state;
    const { isAuthenticated, actions, fileData } = this.props;
    const { title, id, description } = fileData;
    const dropdownButton = visibilityOptionsArray.find(option =>
      option.value == shareData.permissions || option.alias == shareData.permissions);

    const data = {
      'post': `${title} : ${description}`,
      'permissions': dropdownButton.value,
      'sharedFile': id,
      'sharedQuotedText': shareData.quote
    };


    if(!isAuthenticated){
      const credential = {
        'email': shareData.email,
        'password': shareData.password
      };

      if(!this.isValid())
        return;

      return actions
        .authenticateUser(credential)
        .then(() =>
          actions
            .saveWallPost(data)
            .then(() => {
              if(this.props.onClose){
                this.props.onClose();
              }
              this.props.actions.closeModal();
            }));
    }

    actions.saveWallPost(data).
      then((response) => {

        if(this.props.onClose){
          this.props.onClose();
        }

        if(isEmpty(response.wallPosts.error)){
          toastr.success('', 'You successfully shared this as a post');
        }

        this.props.actions.closeModal();
      });
  }

  render() {
    const { visibilityDropdownOpen, shareData, errors } = this.state;
    const { isOpen, fileData, isSubmitting, isAuthenticated, isAuthenticating } = this.props;
    const { title, url, mimetype } = fileData;
    const dropdownButton = visibilityOptionsArray.find(option =>
      option.value == shareData.permissions || option.alias == shareData.permissions);

    let modalMessage = null;
    const docFile = mimetype.includes('application') || mimetype.includes('text');

    const isPrivateFile = fileData.visibility === 'private';
    const isFollowersOnly = fileData.visibility === 'followers';

    if(isPrivateFile){
      modalMessage = <p>Files set to <strong>Private</strong> can only be shared to your activity stream with <strong>Only Me</strong> visibility setting.</p>;
    }

    if(isFollowersOnly){
      modalMessage = <p>Files set to <strong>Followers Only</strong> can only be shared to your activity stream with either <strong>Followers Only</strong> or <strong>Only Me</strong> visibility setting.</p>;
    }

    return (
      <Modal
        backdrop="static"
        isOpen={isOpen}
        toggle={this.onCloseModal}>
        <ModalHeader
          toggle={this.onCloseModal}>
          Share Portfolio File
        </ModalHeader>
        <ModalBody>
          <div className="shared-item">
            <TextArea
              name="quote"
              type="text"
              rows="3"
              value={shareData.quote}
              onChange={this.onChangeShareData}
              placeholder="Say something about this file"/>
            <div>
              <div className="shared-item-container">
                <div className="shared-item-image">
                  {
                    !docFile && mimetype !== '' &&
                      <img src={url}/>
                  }

                  {
                    docFile &&
                      <div className="file-icon-container shared-file-display">
                        <i className="fa fa-file-text-o"/>
                      </div>
                  }
                  {
                    !docFile && mimetype === '' &&
                      <div className="file-icon-container shared-file-display">
                        <i className="fa fa-youtube-play" />
                      </div>
                  }
                </div>
                <div className="shared-item-description">
                  <h6>{title}</h6>
                  <div className="description">
                    <p>Click to see details for this Portfolio File.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            !isAuthenticated &&
              <div className="login-form-share-badge row">
                <div className="form-group col-sm-12 col-md-6 col-lg-6">
                  <label className="sr-only">Email</label>
                  <input
                    type="text"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    value={shareData.email}
                    onChange={this.onChangeShareData}/>
                  {errors.email &&
                    <div className="text-danger">{errors.email}</div>}
                </div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6">
                  <label className="sr-only">Password</label>
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                    value={shareData.password}
                    onChange={this.onChangeShareData}/>
                  {errors.password &&
                    <div className="text-danger">{errors.password}</div>}
                </div>
              </div>
          }
          <div className="portfolio-upload-note" style={{paddingTop: 30}}>
            {modalMessage}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            disabled={isSubmitting || isAuthenticating}
            onClick={this.onCloseModal}
            buttonClass="btn-secondary"
            styles={{ marginLeft: 0 }}
            buttonText="Cancel" />
          <div className="portfolio-files-visibility">
            <Dropdown
              isOpen={visibilityDropdownOpen}
              toggle={this.onToggleVisibilityDropdown}>
              <DropdownToggle
                color="tertiary">
                {dropdownButton == null ? 'Who can see this post' : dropdownButton.title}
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu">
                {
                  isFollowersOnly || isPrivateFile ?
                    <span className="not-clickable dropdown-item-cus">
                      {visibilityOptionsArray[0].title}
                    </span> :
                    <a className="dropdown-item" onClick={this.onSelectVisibilty(visibilityOptionsArray[0].value)}>
                      {visibilityOptionsArray[0].title}
                    </a>
                }
                {
                  !isFollowersOnly && isPrivateFile ?
                    <span className="not-clickable dropdown-item-cus">
                      {visibilityOptionsArray[1].title}
                    </span> :
                    <a className="dropdown-item" onClick={this.onSelectVisibilty(visibilityOptionsArray[1].value)}>
                      {visibilityOptionsArray[1].title}
                    </a>
                }
                <a className="dropdown-item" onClick={this.onSelectVisibilty(visibilityOptionsArray[2].value)}>
                  {visibilityOptionsArray[2].title}
                </a>
              </DropdownMenu>
            </Dropdown>
          </div>
          <Button
            type="submit"
            disabled={isSubmitting || isAuthenticating}
            onClick={this.onShare}
            buttonClass="btn-primary"
            styles={{ marginLeft: 0 }}
            buttonText={isSubmitting || isAuthenticating ? 'Sharing...' :
              isAuthenticated ? 'Share': 'Login and Share'}/>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let shareData = {
    email: '',
    password: '',
    quote: '',
    permissions: '1'
  };

  shareData.permissions = ownProps.fileData.visibility;

  return {
    shareData,
    isSubmitting: state.wallPosts.isSubmitting,
    isAuthenticating: state.auth.isRequesting,
    isAuthenticated: state.auth.isAuthenticated
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({},
    componentActions,
    wallPostActions,
    authActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(SharePortfolioModal);
