import React from 'react';

import PropTypes from 'prop-types';

import Rating from 'react-rating';
import { inRange, isUndefined } from 'lodash';

const StarRating = ({ rating, endorsementCount = 0, skipEndorsement = false }) => {
  const emptySymbol = <img src={require('../../assets/images/star-line.png')} className="icon" />;
  const fullSymbol = <img src={require('../../assets/images/star-solid.png')} className="icon" />;

  const deriveRating = () => {
    const convertedRating = parseFloat(rating);
    switch (true) {
    case inRange(convertedRating, 0.01, 0.99):
      return 0.5;
    case inRange(convertedRating, 1.0, 1.44):
      return 1;
    case inRange(convertedRating, 1.45, 1.54):
      return 1.5;
    case inRange(convertedRating, 1.55, 2.44):
      return 2;
    case inRange(convertedRating, 2.45, 2.99):
      return 2.5;
    case inRange(convertedRating, 3.0, 3.54):
      return 3;
    case inRange(convertedRating, 3.55, 3.99):
      return 3.5;
    case inRange(convertedRating, 4, 4.44):
      return 4;
    case skipEndorsement && convertedRating === 5.0:
      return 5;
    case inRange(convertedRating, 4.45, 4.99):
    case convertedRating === 5.0 && endorsementCount === 0:
      return 4.5;
    case convertedRating === 5.0 && endorsementCount > 0:
      return 5;
    default:
      return 0;
    }
  };

  return (
    <div className="ratingStar pointer">
      {
        isUndefined(rating) || rating === 0 ? <p className="mb-0">No Review</p>
          : <Rating
            readonly
            initialRating={
              skipEndorsement ? parseFloat(rating) : deriveRating()
            }
            emptySymbol={emptySymbol}
            fullSymbol={fullSymbol}
            fractions={2} />
      }

    </div>
  );
};

StarRating.propTypes = {
  rating: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  endorsementCount: PropTypes.number,
  skipEndorsement: PropTypes.bool,
};

export default StarRating;
