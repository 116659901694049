import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Loading from '../common/Loading';
import ShareVideoModal from '../../containers/toolbox/interview-videos/ShareVideoModal';
import LazyLoadImage from '../../containers/common/lazy-load-image/LazyLoadImage';

const InterviewPrepVideosList = ({ videoList, isRequesting, onLikeVideo, isLiking, onShareToCp, modal }) => {

  if(isRequesting){
    return(
      <div className="loading-container">
        <Loading/>
      </div>
    );
  }

  return(
    <div className="row">
      {
        videoList.map((video, index) => {
          const { id, title, thumbnail, like_count, liked, comment_count } = video;
          const isLikingVideo = isLiking.status && isLiking.id === video.id;
          const isVideoShareModalOpen = modal && modal === `share-video-from-list-to-cp-${id}`;

          return (
            <div key={index} className="col-lg-3 col-md-4">
              <div className="video">
                <Link to={`/toolbox/interview-prep-videos/${id}`}>
                  <div className="sr-only">{title}</div>
                  <div className="thumb">
                    <LazyLoadImage
                      alt={`${title}'s thumnail`}
                      imageClassName="img-fluid"
                      srcLoaded={thumbnail} />
                  </div>
                </Link>
                <div className="description">
                  <Link to={`/toolbox/interview-prep-videos/${id}`}>
                    <h3>{title}</h3>
                  </Link>
                  <p className="hidden">
                    Get detailed advice on how to answer common interview questions. Watch samples of ineffective vs. effective responses with feedback you can use to ace your next interview.
                  </p>
                </div>
                <div className="portfolio-stats">
                  <ul className="list-inline portfolio-details-list">
                    <li className="list-inline-item">
                      {
                        isLikingVideo ?
                          <span style={{cursor: 'no-drop'}}><i className="fa fa-heart" aria-hidden="true"/></span> :
                          <a
                            className="clickable"
                            onClick={onLikeVideo(id)}
                            title={liked ? 'UNLIKE': 'LIKE'}>
                            <i className={classNames('fa fa-heart', {
                              'liked-heart': liked
                            })} aria-hidden="true"/>
                          </a>
                      }
                      {like_count}
                    </li>
                    <li className="list-inline-item" title="Comments"><i className="fa fa-comment" aria-hidden="true"/>{comment_count}</li>
                  </ul>
                  <a
                    onClick={onShareToCp(id)}
                    className="clickable"
                    title="SHARE">
                    <i className="fa fa-share pr-0" aria-hidden="true"/>
                  </a>
                </div>
              </div>
              {
                isVideoShareModalOpen &&
                  <ShareVideoModal
                    isOpen={isVideoShareModalOpen}
                    videoData={video}/>
              }
            </div>
          );
        })
      }
    </div>
  );
};

InterviewPrepVideosList.propTypes = {
  onLikeVideo: PropTypes.func.isRequired,
  videoList: PropTypes.array.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  isLiking: PropTypes.object,
  onShareToCp: PropTypes.func,
  modal: PropTypes.string
};

export default InterviewPrepVideosList;
