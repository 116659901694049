import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import _isEmpty from 'lodash/isEmpty';

import { email } from '../../validator/rules';

import {
  Container,
  Row,
  Col
} from 'reactstrap';

import * as componentActions from '../../redux/actions/components';
import * as adminGroupActions from '../../redux/actions/admin-group';
import * as routerActions from '../../redux/actions/router';

import ManageGroupsExampleModal from './ManageGroupsExampleModal';

const styles = {
  groupNameLabel: {
    position: 'absolute',
    top: 20,
    left: 51,
    right: 40
  },

  groupNameInput: {
    marginTop: 30
  },

  addMoreCard: {
    border: '1px solid #dedede',
    boxShadow: '0px 0px 5px #00000033',
    WebkitBoxShadow: '0px 0px 5px #00000033',
    MozBoxShadow: '0px 0px 5px #00000033',
    msBoxShadow: '0px 0px 5px #00000033',
    OBoxShadow: '0px 0px 5px #00000033'
  },

  addMoreCardHeader: {
    color: '#fff',
    backgroundColor: '#999',
    borderBottom: '1px solid #999'
  },

  addMoreCardBlock: {
    color: '#999',
    cursor: 'pointer'
  },

  addMorePlusIcon: {
    color: '#ff5000'
  },

  addMoreCardText: {
    fontSize: 12,
    marginBottom: 5
  }
};

class FirstLoginPage extends Component {
  static propTypes = {
    id: PropTypes.string,
    organization: PropTypes.string,
    groundLocations: PropTypes.number,
    onlinePrograms: PropTypes.number,
    group: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    modal: PropTypes.string
  }

  constructor(props) {
    super(props);

    let getInitialOnlinePrograms = () => {
      const formData = Array(props.onlinePrograms).fill().map((e,i)=>{
        const data = {
          id: i+1,
          name:'',
          emails:'',
          nameErrors: false,
          errors: false
        };

        return data;
      });


      return formData;
    };

    let getInitialGroundLocations = () => {
      const formData = Array(props.groundLocations).fill().map((e,i)=>{
        const data = {
          id: i+1,
          name:'',
          emails:'',
          nameErrors: false,
          errors: false
        };

        return data;
      });


      return formData;
    };


    this.state = {
      isConfirmModalOpen: false,
      groundLocationsState: getInitialGroundLocations(),
      onlineProgramsState: getInitialOnlinePrograms()
    };

    this.onClickManageGroupsExample = this.onClickManageGroupsExample.bind(this);
    this.onCloseManageGroupsExample = this.onCloseManageGroupsExample.bind(this);
  }

  componentWillReceiveProps(nextProps){
    const { isSubmitting, data } = nextProps.group;

    if(!isSubmitting && isSubmitting !== this.props.group.isSubmitting && !_isEmpty(data)){
      this.setState({
        isConfirmModalOpen: false
      });
    }
  }

  onChangeAdminEmails = (e) => {
    const value = e.currentTarget.value;
    const inputName = e.target.name;
    const { onlineProgramsState, groundLocationsState } = this.state;

    const isOnlineProgram = inputName.includes('online-');

    if(isOnlineProgram){
      const id = inputName.split('online-')[1];
      const index = onlineProgramsState.findIndex(data => data.id == id);

      const nextOnlinePrograms = [
        ...onlineProgramsState.slice(0, index),
        Object.assign({},{
          ...onlineProgramsState[index],
          emails: value,
          errors: this.checkValidEmails(value)
        }),
        ...onlineProgramsState.slice(index+1)
      ];

      return this.setState({
        onlineProgramsState: nextOnlinePrograms
      });
    }

    const id = inputName.split('ground-')[1];
    const index = groundLocationsState.findIndex(data => data.id == id);
    const nextGroundLocations = [
      ...groundLocationsState.slice(0, index),
      Object.assign({},{
        ...groundLocationsState[index],
        emails: value,
        errors: this.checkValidEmails(value)
      }),
      ...groundLocationsState.slice(index+1)
    ];

    this.setState({
      groundLocationsState: nextGroundLocations
    });
  }

  checkValidEmails = (input) => {
    const emailArray = input.split(',');
    if(emailArray.length > 0){
      const invalidEmailExist = emailArray.reduce((acc, next) => {
        const isInvalid = email(next.trim()) && email(next.trim()).length > 0;
        return isInvalid || acc;
      }, false);
      return invalidEmailExist;
    }
  }

  onChangeLocationName = (e) => {
    const value = e.currentTarget.value;
    const inputName = e.target.name;

    const { onlineProgramsState, groundLocationsState } = this.state;

    const isOnlineProgram = inputName.includes('online-');

    if(isOnlineProgram){
      const id = inputName.split('online-')[1];
      const index = onlineProgramsState.findIndex(data => data.id == id);

      return this.setState({
        onlineProgramsState: [
          ...onlineProgramsState.slice(0, index),
          Object.assign({},{
            ...onlineProgramsState[index],
            name: value,
            nameErrors: value === ''
          }),
          ...onlineProgramsState.slice(index+1)
        ]
      },() => this.checkValidEmails(value, inputName));
    }

    const id = inputName.split('ground-')[1];
    const index = groundLocationsState.findIndex(data => data.id == id);

    this.setState({
      groundLocationsState: [
        ...groundLocationsState.slice(0, index),
        Object.assign({},{
          ...groundLocationsState[index],
          name: value,
          nameErrors: value === ''
        }),
        ...groundLocationsState.slice(index+1)
      ]
    });
  }

  onFinish = () => {
    this.setState({
      isConfirmModalOpen: true,
    });
  }

  onConfirmFinishSetup = () => {
    const { groundLocationsState, onlineProgramsState } = this.state;
    const { id, actions } = this.props;

    const groupData = {
      groups: [...groundLocationsState, ...onlineProgramsState].
        map(item => Object.assign({}, {
          name: item.name,
          description: 'Description of Group',
          emails: item.emails.split(',').map(email => email.trim()).filter(email => email !== '')
        }))
    };

    actions.createAdminGroup(id,groupData);
  }

  disableFinishButton = () => {
    const { onlineProgramsState, groundLocationsState } = this.state;
    let disableCondition = false;

    disableCondition = [...onlineProgramsState, ...groundLocationsState].some((next) => {
      const emailsHasError = next.errors;
      const nameHasError = next.name === '' || next.nameErrors;
      return emailsHasError || nameHasError;
    });
    return disableCondition;
  }

  onCancelConfirmModal = () => {
    this.setState({
      isConfirmModalOpen: false,
    });
  }

  onOpenAddAdminGroupModal = () => {
    this.props.actions.openModal('admin-group-add');
  }

  onClickManageGroupsExample(event) {
    event.preventDefault();

    this.props.actions.openModal('manage-groups-example');
  }

  onCloseManageGroupsExample() {
    this.props.actions.closeModal();
  }

  render() {
    const { modal } = this.props;
    const isManageGroupsExampleModalOpen = (modal && (modal === 'manage-groups-example'));

    return (
      <Container>
        <Row>
          <Col sm="12" md={{size: 8, offset: 2}}>
            <div className="admin-title-container">
              <h1>Manage Groups</h1>
              <p>
                Your account lets you create unlimited groups (10 at a time). For each, you can designate admins who can create their own groups with admins who can do the same. This lets you create a group hierarchy reflecting your organization's needs. You'll be able to see stats for all groups you create, and all the "child" groups other admins create nested within your own as your groups represent the "top of the hierarchy". <a href="#" onClick={this.onClickManageGroupsExample}>Need an example?</a><br/><br/>
              </p>
              <p>Each group created will have a unique group code that can be shared with users who can sign themselves up at <a href="https://careerprepped.com/group" target="_blank">www.careerprepped.com/group</a> to join the appropriate group.</p>
            </div>
          </Col>
          <Col sm="12" md={{size: 6, offset: 3}} lg={{size: 4, offset: 4}}>
            <div>
              <div className="card" style={styles.addMoreCard}>
                <div className="card-header" style={styles.addMoreCardHeader}>
                  Add group(s) to get started!
                </div>
                <div
                  className="card-block text-center"
                  style={styles.addMoreCardBlock}
                  onClick={this.onOpenAddAdminGroupModal}>
                  <i className="fas fa-users fa-2x"/>
                  <p className="card-text" style={styles.addMoreCardText}>
                    Click to add group(s)
                  </p>
                  <i className="fas fa-plus-circle" style={styles.addMorePlusIcon}/>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        {isManageGroupsExampleModalOpen &&
          <ManageGroupsExampleModal
            onCancel={this.onCloseManageGroupsExample}
            isOpen={isManageGroupsExampleModalOpen}/>}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    group: state.adminGroups,
    modal: state.components.modal
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    ...componentActions,
    ...adminGroupActions,
    ...routerActions
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FirstLoginPage);
