import React from 'react';

import PropTypes from 'prop-types';
import { Popover, PopoverBody } from 'reactstrap';

import TextInput from '../common/TextInput';

const SecondaryEmail = ({
  profile,
  secondaryEmail,
  changeSecondaryEmail,
  error,
  isUpdating,
  onKeyPress,
  togglePopover,
  popoverOpen,
}) => {
  const label = `${
    profile.role === 'educator' ? 'Alternate' : 'Recovery'
  } Email:`;

  return (
    <div className="position-relative">
      <TextInput
        type="text"
        className="form-control input-styles-owht"
        inputId="secondaryEmail"
        label={label}
        placeholder={label}
        name="secondaryEmail"
        value={secondaryEmail}
        onChange={changeSecondaryEmail}
        error={error}
        disabled={isUpdating}
        onKeyPress={onKeyPress}/>

      <div className="input-group-addon input-help-icon ml-2">
        <button id="Popover1" className="btn-icon--rounded" onClick={togglePopover}>
          ?
        </button>

        <Popover placement="right" isOpen={popoverOpen} target="Popover1">
          <PopoverBody>
            We'll send a temporary password here if you forget your password
            and/or can't access your primary email.
          </PopoverBody>
        </Popover>
      </div>
    </div>
    // </div>
  );
};

SecondaryEmail.propTypes = {
  profile: PropTypes.object,
  secondaryEmail: PropTypes.string,
  changeSecondaryEmail: PropTypes.func,
  error: PropTypes.string,
  isUpdating: PropTypes.bool,
  onKeyPress: PropTypes.func,
  togglePopover: PropTypes.func,
  popoverOpen: PropTypes.bool,
};

export default SecondaryEmail;
