import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SharedReflectionAnswersPost from '../../components/common/SharedReflectionAnswersPost';
import { isOverflown } from '../../utils';


class SharedReflectionAnswersPostContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    quote: PropTypes.string,
    reflection: PropTypes.array.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      showAll: false,
      isContentsOverflown: false
    };

    this.onToggleShowAll = this.onToggleShowAll.bind(this);
    this.setReflectionAnswersContentContainerRef = this.setReflectionAnswersContentContainerRef.bind(this);
  }

  componentDidMount(){
    const refDiv = this[`reflectionAnswerDiv-${this.props.id}`];

    if(refDiv){
      /* eslint-disable react/no-did-mount-set-state */
      this.setState({
        isContentsOverflown: isOverflown(refDiv)
      });
    }
  }

  componentDidUpdate(prevProps, prevState){
    const { showAll } = prevState;

    if(showAll !== this.state.showAll){
      const refDiv = this[`reflectionAnswerDiv-${this.props.id}`];

      if(refDiv){
        /* eslint-disable react/no-did-update-set-state */
        this.setState({
          isContentsOverflown: isOverflown(refDiv)
        });
      }
    }
  }

  onToggleShowAll() {
    this.setState({
      showAll: !this.state.showAll
    });
  }

  setReflectionAnswersContentContainerRef(div) {
    this[`reflectionAnswerDiv-${this.props.id}`] = div;
  }

  render() {
    const { showAll, isContentsOverflown } = this.state;
    const { quote, reflection } = this.props;

    return (
      <div>
        <SharedReflectionAnswersPost
          setReflectionAnswersContentContainerRef={this.setReflectionAnswersContentContainerRef}
          quote={quote}
          showAll={showAll}
          onToggleShowAll={this.onToggleShowAll}
          isContentsOverflown={isContentsOverflown}
          reflection={reflection} />
      </div>
    );
  }
}

export default SharedReflectionAnswersPostContainer;
