import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as interviewActions from '../../redux/actions/interview';
import * as componentsActions from '../../redux/actions/components';

class FreeCareerResources extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);
  }

  render() {
    const resCareeronstop = require('../../assets/images/img-resource-careeronstop.jpg');
    const resIndeed = require('../../assets/images/img-resource-indeed.jpg');
    const resOnet = require('../../assets/images/img-resource-onet.jpg');
    const resCredentialEngine = require('../../assets/images/img-credential-engine.jpg');
    const digitalPromise = require('../../assets/images/img-digital-promise.jpg');
    const resourceData = [
      {
        id:1,
        img: resCredentialEngine,
        title: 'Credential Finder',
        source:'Credential Engine',
        link:'https://www.credentialfinder.org/search',
        content:'Search an online registry providing information on the credentials available in the market and the competencies and skills built into them.'
      },
      {
        id:2,
        img: digitalPromise,
        title: 'Global Competence Modules',
        source:'Digital Promise',
        link: `${location.origin}/resources/global-competence-modules`,
        content:'Develop your global competence skills in demand for any career field through four sponsored learning modules.'
      },
      {
        id:3,
        img: resCareeronstop,
        title: 'Occupational Profile',
        source:'CareerOneStop',
        link:'https://www.careeronestop.org/Toolkit/Careers/Occupations/occupation-profile.aspx',
        content:'Learn details about any occupation such as job duties, wages, projected growth, and training needed.'
      },
      {
        id:4,
        img: resCareeronstop,
        title: 'Interest Assessment',
        source:'CareerOneStop',
        link:'https://www.careeronestop.org/toolkit/careers/interest-assessment.aspx',
        content:'Answer 30 quick questions about what you like and don’t like to explore careers that match your interests.'
      },
      {
        id:5,
        img: resCareeronstop,
        title: 'Work Values Matcher',
        source:'CareerOneStop',
        link:'https://www.careeronestop.org/Toolkit/Careers/work-values-matcher.aspx',
        content:'Use a quick card sort activity to identify your top work values which are what’s most important to you in work.'
      },
      {
        id:6,
        img: resCareeronstop,
        title: 'Skills Matcher',
        source:'CareerOneStop',
        link:'https://www.careeronestop.org/toolkit/Skills/skills-matcher.aspx',
        content:'Rate your levels on 40 key workplace skills and learn about careers that match your ratings.'
      },
      {
        id:7,
        img: resOnet,
        title: 'Interest Profiler',
        source:'MyNextMove',
        link:'https://www.mynextmove.org/explore/ip',
        content:'Learn your interests, how they relate to the world of work, and what kinds of careers you may want to explore.'
      },
      {
        id:8,
        img: resOnet,
        title: 'MOC Crosswalk',
        source:'O*NET',
        link:'https://www.onetonline.org/crosswalk/MOC/',
        content:'Identify skills, experience, etc. acquired in military and translate that into civilian language & opportunities.'
      },
      {
        id:9,
        img: resCareeronstop,
        title: 'Veterans Job Matcher',
        source:'CareerOneStop',
        link:'https://www.careeronestop.org/Toolkit/Jobs/match-veteran-jobs.aspx',
        content:'Enter your U.S. military job to find related civilian careers to potentially transition into.'
      },
      {
        id:10,
        img: resIndeed,
        title: 'Salary Calculator',
        source:'Indeed',
        link:'https://www.indeed.com/salaries',
        content:'Search and compare salaries for different jobs in different locations from Indeed salary data.'
      },

    ];

    const listResources = resourceData.map(resource =>
      (<div key={resource.id} value={resource.title} className="col-lg-3 resource" style={{marginBottom:30}}>
        <div className="resource-item">
          <div className="resource-item-img"><img src={resource.img} alt={resource.title} /></div>
          <div className="resource-item-title">
            <span className="badge">{resource.title}</span>
          </div>
          <div className="resource-item-source">{resource.source}</div>
          <div className="resource-item-link"><a href={resource.link} target="_blank">{resource.title} <i className="fa fa-external-link"/></a></div>
          <div className="resource-item-content">{resource.content}</div>
        </div>
      </div>)
    );

    return (
      <div>
        <Helmet title="Interview Prep Videos"/>
        <div className="content free-resources">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="mb-1">Free Career Resources</h2>
              <p>Use a variety of free tools we’ve collected for you. Read the descriptions of each to easily learn what each tool helps you do. Click on the tool to access it. These tools are from external sites and will open in a new tab.</p>
            </div>
          </div>
          <div id="free-resources" className="row display-flex">
            {listResources}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modal: state.components.modal,
    interview: state.interview
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, interviewActions, componentsActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FreeCareerResources);
