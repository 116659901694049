import PropTypes from 'prop-types';
import React from 'react';

const ResumeSkills = ({ resumeSkills }) => {
  return (
    <div className="resume-section">
      <div className="resume-title">
        {resumeSkills.title !== '' ? resumeSkills.title : 'Skills'}
      </div>

      {resumeSkills.skillsOrder.map((order, index) => {
        const skill = resumeSkills.skillsArray.find(item => item.id === order.id);

        if (! skill) return null;

        const icon = (resumeSkills.skillsArray.length !== (index + 1)) ? <span> • </span> : '';

        let skillName = skill.name;

        if (skill.visibility) {
          const href = `/skill-evidence/${skill.id}`;

          skillName = <a href={href}>{skill.name}</a>;
        }

        return <span key={skill.id}>{skillName}{icon}</span>;
      })}

    </div>
  );
};

ResumeSkills.propTypes = {
  resumeSkills: PropTypes.object.isRequired,
};

export default ResumeSkills;
