import PropTypes from 'prop-types';
import React from 'react';
import IconBookmark from '../../../src/assets/images/icon-bookmark.svg';

const LoadedFile = ({
  parseUrl,
  mimetype,
  imagePreviewUrl,
  imagePreviewText,
  onDeleteLoadedFile
}) => {
  const docFile = mimetype && mimetype.includes('application') || mimetype.includes('text');
  const previewImage = mimetype && mimetype.includes('image') ? imagePreviewUrl : null;
  const showTruncated = imagePreviewText.length > 25;
  const splittedImagePreviewText = imagePreviewText.split('.');
  const extension = splittedImagePreviewText[splittedImagePreviewText.length - 1];
  const fileName = splittedImagePreviewText.slice(0, -1).join('.');

  const content = parseUrl && parseUrl.type === 'iframe' ?
    (<iframe
      src={parseUrl.url}
      allowFullScreen
      style={{'overflow': 'hidden'}}
      scrolling="no"
      className="embed-responsive-item"/>) :
    docFile ?
      <div className="loaded-image-container file-icon-container">
        <i className="fa fa-file-text-o"/>
        <span className="loaded-filename doc-file">
          <span className="name">
            {
              showTruncated ?
                `${fileName.substring(0, 15)}...${fileName.substring(fileName.length-8, fileName.length)}.${extension}`:
                imagePreviewText
            }
          </span>
        </span>
      </div> :
      previewImage ?
        <span className="loaded-image-container">
          <img
            className="loaded-image"
            src={previewImage}/>

          <span className="loaded-filename doc-file">
            <span className="name">
              {
                showTruncated ?
                  `${fileName.substring(0, 15)}...${fileName.substring(fileName.length-8, fileName.length)}.${extension}`:
                  imagePreviewText
              }
            </span>
          </span>
        </span> :
        <span className="loaded-image-container">
          <div className="portfolio-weblink">
            <div className="portfolio-file-image">
              <img
                className="loaded-image"
                src={IconBookmark}/>
            </div>
          </div>

          <span className="loaded-filename doc-file">
            <span className="name">
              {
                showTruncated ?
                  `${fileName.substring(0, 15)}...${fileName.substring(fileName.length-8, fileName.length)}.${extension}`:
                  imagePreviewText
              }
            </span>
          </span>
        </span>;

  return(
    <div className="portfolio-file-selector-container loaded-file">
      {content}
      <span
        onClick={onDeleteLoadedFile}
        className="btn">
        <i className="fa fa-trash upload-icon trash-icon"/>
      </span>
    </div>
  );
};

LoadedFile.propTypes = {
  parseUrl: PropTypes.object,
  onDeleteLoadedFile: PropTypes.func,
  imagePreviewText: PropTypes.string,
  imagePreviewUrl: PropTypes.string,
  mimetype: PropTypes.string
};

export default LoadedFile;
