import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';
import {
  Input,
  Row,
  Col,
  Modal,
  ModalBody,
  Progress,
  FormGroup,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { Multiselect } from 'multiselect-react-dropdown';

import 'react-datepicker/dist/react-datepicker.css';

import { getGivenFeedbacksByResource } from '../../redux/actions/feedback';
import {
  FEEDBACK_DATE_FORMAT,
  SETTINGS_DATE_FORMAT,
  FEEDBACK_REQUESTED_DATE_FORMAT,
} from '../../constants';

import StarRating from '../rating/StarRating';
import { getFeedbackRating, getSkillsRating } from '../../utils';
import CloseIcon from '../../assets/images/close.svg';
import Loading from '../common/Loading';

const allRatings = [
  { label: '0 to 1', value: '0-1' },
  { label: '1 to 2', value: '1-2' },
  { label: '2 to 3', value: '2-3' },
  { label: '3 to 4', value: '3-4' },
  { label: '4 to 5', value: '4-5' },
];

class AllFeedbackReceived extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
      filtered: [],
      startDate: null,
      filteredResourceFeedback: null,
      searchParam: '',
      ratings: [],
      isSearchChanged: false,
      showLoader: true,
      isFiltering: false
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleDatePicker = this.handleDatePicker.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.selectRatingOption = this.selectRatingOption.bind(this);
  }

  componentDidMount() {
    this.getFeedbackData();
  }

  setStartDate(event) {
    this.setState({ startDate: event, endDate: null });
  }

  getQueryParam(option){
    let param= '';

    if(option !== 'search' && this.state.searchParam?.length > 2) { // to add search param in url
      param = `&search_giver_name=${this.state.searchParam?.toLowerCase()}`;
    }

    if(option !== 'rating' && !isEmpty(this.state.ratings)) { // to add rating param in url
      this.state.ratings.forEach((item) => {
        if(item.value){
          param = `${param}&ratings[]=${item.value}`;
        }
      });
    }

    if(option !== 'date' && this.state.startDate && this.state.endDate) { // to add date param in url
      param = `${param}&from_date=${moment(this.state.startDate).format(
        SETTINGS_DATE_FORMAT
      )}&to_date=${moment(this.state.endDate).format(
        SETTINGS_DATE_FORMAT
      )}`;
    }

    return param;
  }

  handleDatePicker = (event) => {
    if (!this.state.startDate) {
      toastr.error('', 'Please select start date.');
      return false;
    }

    if (moment(event).isBefore(this.state.startDate)) {
      toastr.error('', '"To" date should be after "From" date');
      return false;
    }

    if (this.state.startDate && event) {
      if (
        moment(event).isSame(this.state.startDate) ||
        moment(event).isAfter(this.state.startDate)
      ) {
        let param = '';

        param = this.getQueryParam('date');

        param = `${param}&from_date=${moment(this.state.startDate).format(
          SETTINGS_DATE_FORMAT
        )}&to_date=${moment(event).format(
          SETTINGS_DATE_FORMAT
        )}`;

        this.getFeedbackData(param);

        this.setState({ endDate: event });
      }
    }
  };

  handleSearch(event) {
    this.setState({searchParam: event.target.value, isSearchChanged: true});
  }

  submitSearch = () => {
    const { searchParam, isSearchChanged } = this.state;

    if(!isSearchChanged) {
      return;
    }

    this.setState({isSearchChanged: false});

    let param = '';

    param = this.getQueryParam('search');
    if(searchParam?.length > 2) {
      param = `&search_giver_name=${searchParam?.toLowerCase()}`;
    }


    if(searchParam?.length > 2 || !searchParam) {
      this.getFeedbackData(param);
    }
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter'){
      this.submitSearch();
      return true;
    }
  }

  getFeedbackData(param = null) {
    const { data } = this.props;
    const resourType= data.skill_id ? 'skill' : 'badge';
    const resourId= data.skill_id ? data.skill_id : data.issuedID;
    if(param != null) {
      this.setState({isFiltering: true});
    }
    this.props.actions.getGivenFeedbacksByResource(
      resourType,
      data.user?.id,
      resourId,
      'requester',
      param
    ).then(() => {
      this.setState({ showLoader: false });
    });
  }

  selectRatingOption(val){
    const ratings= [];
    let param = '';
    param = this.getQueryParam('rating');

    val.forEach((item) => {
      if(item.value){
        ratings.push(item.value);
        param = `${param}&ratings[]=${item.value}`;
      }
    });
    this.getFeedbackData(param);

    this.setState({
      ratings: val,
    });
  }

  getFeedbackReceived = (filtered) => {
    return filtered.map((f, i) => (
      <div key={`${f.id}-${i}-key`}>
        <Row key={f.id} className="container">
          <Col md="2" lg="1" className="px-0 col-3">
            <Link to={`/cp/${f.vanity}`} className="user-name">
              <img
                className="feedbackAvatar"
                src={
                  f.avatarURl
                    ? f.avatarURl
                    : require('../../assets/images/avatar.jpg')
                }/>
            </Link>
          </Col>
          <Col md="10" lg="2" className="pl-0 mb-4 col-9 mb-lg-0">
            <span>
              <Link to={`/cp/${f.vanity}`} className="user-name">
                <strong>{`${f.fullname}`}</strong>
              </Link>
            </span>
            <div className="d-flex">
              {f.isRevised && <i className="fa fa-pencil writtenOn" />}
              <span className="font12">{`${moment(
                f?.feedbackResponseDate?.date,
                SETTINGS_DATE_FORMAT
              ).format(FEEDBACK_REQUESTED_DATE_FORMAT)}`}</span>
            </div>
            <StarRating rating={getFeedbackRating(f)} skipEndorsement/>
          </Col>
          <Col md="12" lg="9" className="pl-0">
            {f.questionResponseRating.overall && (
              <p className="font-weight-bold">
                Question Response Ratings: &nbsp;
                <span className="font-weight-normal">{`${f.questionResponseRating.overall}`}</span>
                <br />
                <span className="font-weight-normal">
                  {`${f.questionResponseRating.multichoiceComments}`}
                </span>
                <br />
                <span className="font-weight-normal text-justify text-break custom-ellipsis">
                  {`${f.questionResponseRating.additionalComment}`}
                </span>
              </p>
            )}
            {f.portfolioEvidenceRating && (
              <p className="font-weight-bold">
                Portfolio Evidence Ratings: &nbsp;
                <span className="font-weight-normal">{`${f.portfolioEvidenceRating.overall}`}</span>
                <br />
                <span className="font-weight-normal text-justify text-break custom-ellipsis">{`${f.portfolioEvidenceRating.multichoiceComments}`}</span>
                <br />
                <span className="font-weight-normal text-justify text-break custom-ellipsis">
                  {`${f.portfolioEvidenceRating.additionalComment}`}
                </span>
              </p>
            )}
          </Col>
        </Row>
        <div className="grayLine my-3" />
      </div>
    ));
  };

  toggleDropdown = () => {
    this.setState((state) => {
      return {
        isDropdownOpen: !state.isDropdownOpen,
      };
    });
  };

  getStarRating(data){
    if(
      (data.questionRespRating !== '0.00' &&
      data.questionRespRating !== 0 &&
      data.porfolioRating !== 0 &&
      data.porfolioRating !== '0.00')){
      return data.avgRating;
    }else if(data.questionRespRating !== '0.00' && data.questionRespRating !== 0){
      return parseFloat(data.questionRespRating);
    }else{
      return parseFloat(data.porfolioRating);
    }
  }

  render() {
    const { isOpen, toggleModal, data, ratings, resourceFeedback } = this.props;
    const { filteredResourceFeedback, isFiltering } = this.state;
    const defaultImageLink = data.skill_id ? '../../assets/images/temp/skill.png' : '../../assets/images/temp/badge.png';

    return (
      <Modal
        centered
        size="lg"
        className="customLevelUpModal mySkillsContainerStyle"
        isOpen={isOpen}
        toggle={toggleModal}>
        <div className="modal-header">
          <span className="modal-title">All Feedback Received</span>
          <div
            onClick={toggleModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">
              <img src={CloseIcon} />
            </span>
          </div>
        </div>
        <ModalBody>
          {this.state.showLoader ? <Loading/> : <div>
            <div className="row">
              <div className="col-md-12 col-lg-5 d-flex">
                {
                  !data.skill_id &&
                  <div>
                    <img
                      className="skillImg"
                      src={data.imageURL ? data.imageURL : defaultImageLink}/>
                  </div>
                }
                <div className="justify-content-start ml-2">
                  <p className="d-block font-weight-bold mb-2">{data.name}</p>
                  <div className="d-flex">
                    <StarRating
                      skipEndorsement
                      rating={getSkillsRating(data)}
                      endorsementCount={parseInt(data.endorsementCount)}/>
                    <p className="d-inline-block font-weight-bold p-0 ml-3 mb-0 mt-1 font12">
                      {getSkillsRating(data) || 0}{' '}
                      out of 5 stars
                    </p>
                  </div>
                </div>
              </div>

              {data.badge_id && (
                <div className="col-md-12 col-lg-3">
                  <p className="font-weight-bold mb-1 font12 mt-2">
                    Question Response Ratings:
                  </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="pr-1">0</span>
                    {/* <Progress className="roundedCorners mb-0" value={data.questionResponseRating * 20} /> */}
                    <Progress
                      className="roundedCorners mb-0"
                      value={(ratings.questionRespRating || 0) * 20}/>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"/>
                    </div>
                    <span className="pl-1">5</span>
                  </div>
                </div>
              )}
              {
                <div className="col-md-12 col-lg-3 px-lg-2 ml-0 ml-lg-4">
                  <p className="font-weight-bold mb-1 font12 mt-2">Portfolio Ratings:</p>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="pr-1">0</span>
                    <Progress
                      className="roundedCorners mb-0"
                      value={(ratings.porfolioRating || 0) * 20}/>
                    <span className="pl-1">5</span>
                  </div>
                </div>
              }
            </div>
            <div className="grayLine mt-2 mb-4" />
            <Row className="mt-2 myskillAdvSearch">
              <Col md="12" lg="6">
                <Row>
                  <Col md="12" lg="6">
                    <FormGroup>
                      <InputGroup>
                        <Input
                          type="text"
                          name="search"
                          id="search"
                          placeholder="Search"
                          onChange={this.handleSearch}
                          onKeyPress={this.handleKeyPress}/>
                        <InputGroupAddon className="pointer" addonType="append" onClick={this.submitSearch}>
                          <i className="fa fa-search" />
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="12" lg="6" className="pl-lg-0">
                    <Multiselect
                      options={allRatings} // Options to display in the dropdown
                      ref={this.multiselectRef}
                      value={this.state.ratings}
                      onSelect={this.selectRatingOption}
                      onRemove={this.selectRatingOption}
                      selectedValues={this.state.ratings}
                      displayValue="label"
                      showCheckbox
                      placeholder="All Ratings"
                      selectionLimit={10}
                      closeOnSelect={false}
                      isClearable={false}/>
                  </Col>
                </Row>
              </Col>
              <Col md="12" lg="5">
                <Row className="py-3 py-lg-0">
                  <Col xs="12" md="6" lg="5" className="pl-lg-0">
                    <FormGroup>
                      <DatePicker
                        selected={
                          this.state.startDate && new Date(this.state.startDate)
                        }
                        dateFormat={FEEDBACK_DATE_FORMAT}
                        onChange={this.setStartDate}
                        onBlur={this.handleSearch}
                        className="form-control dateIcon"
                        placeholderText="From"/>
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="5" className="pl-lg-0">
                    <DatePicker
                      selected={
                        this.state.endDate && new Date(this.state.endDate)
                      }
                      dateFormat={FEEDBACK_DATE_FORMAT}
                      onChange={this.handleDatePicker}
                      className="form-control dateIcon"
                      placeholderText="To"/>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div>
              <p>
                <strong>{`Total Feedback (${resourceFeedback.length})`}</strong>
              </p>
            </div>
            {/* This is going to be separate component */}
            <div className="custom-scrollbar">
              {resourceFeedback.length > 0 ?
                this.getFeedbackReceived(
                  filteredResourceFeedback
                    ? filteredResourceFeedback
                    : resourceFeedback
                ): isFiltering ?
                  <p>Sorry, we couldn't find anything!</p> :
                  <p>No feedback received yet</p>}
            </div>
            {/* This is going to be separate component */}
          </div>
          }
        </ModalBody>
      </Modal>
    );
  }
}

AllFeedbackReceived.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  actions: PropTypes.object,
  resourceFeedback: PropTypes.array.isRequired,
  ratings: PropTypes.object,
  rating: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    resourceFeedback: state.myfeedback.data.resourceFeedback,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getGivenFeedbacksByResource,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllFeedbackReceived);
