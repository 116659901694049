import PropTypes from 'prop-types';
import React from 'react';
import CommentList from '../../../components/skill-builders/discussions/CommentList';

const Comment = ({
  discussion,
  loadForms,
  discussionComments,
  commentShowMore,
  onCommentShowMore
}) => {
  return(
    <CommentList
      discussionId={discussion.id}
      comments={discussionComments}
      commentShowMore={commentShowMore}
      onCommentShowMore={onCommentShowMore}
      loadForms={loadForms}/>
  );
};

Comment.propTypes = {
  discussion: PropTypes.object.isRequired,
  loadForms: PropTypes.array.isRequired,
  onCommentShowMore: PropTypes.func.isRequired,
  commentShowMore: PropTypes.object.isRequired,
  discussionComments: PropTypes.array.isRequired
};
export default Comment;
