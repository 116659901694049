import PropTypes from 'prop-types';
import React from 'react';

import { Tooltip,Row,Col } from 'reactstrap';
import { Link } from 'react-router-dom';


const MessageDetailsHeader = ({
  isMobile,
  thread,
  isDeleting,
  onDeleteMessage,
  currentProfile,
  isUserListTooltipOpen,
  toggleUserListTooltip,
  toggleUserDetailsModal
}) => {

  if (Object.keys(thread).length === 0) {
    return (
      <div className="messageDetailHeader clearfix" />
    );
  }

  const { subject, participants, id } = thread;
  const isDeletingMessage = isDeleting.status && isDeleting.messageId === id;
  const participantsToShow = participants.filter(par => par.id !== currentProfile.id);

  let userListString = '';

  switch (participantsToShow.length) {
  case 1:
    userListString = participantsToShow[0].name;
    break;

  default:
    userListString = `${participantsToShow[0].name} and `;
    break;
  }

  return (
    <div
      style={{ position: 'relative' }}
      className="messageDetailHeader clearfix">
      {
        isMobile &&
        <Row>
          <Col xs="1">
            <Link to="/messages" className="back-button">
              <i className="fa fa-chevron-left" />
            </Link>
          </Col>
          <Col xs="10">
            <h2>{subject}</h2>
          </Col>
        </Row>      
      }
      { !isMobile && <h2>{subject}</h2>}
      
      <div
        className="header-body">
        <p
          onClick={toggleUserDetailsModal}
          className="messageFromName link">
          {participantsToShow.length > 1 ?
            <span href="#" id="showUserListTooltip">
              {userListString}

              {participantsToShow.length - 1 === 1 ?
                `${participantsToShow.length - 1} other` :
                `${participantsToShow.length - 1} others`}
            </span> :
            userListString}
        </p>
      </div>

      {isDeletingMessage ?
        <a className="delete-message-details fas fa-trash-alt fa-lg" /> :
        <a
          onClick={onDeleteMessage}
          className="delete-message-details clickable orange fas fa-trash-alt fa-lg" />}

      {
        participantsToShow.length > 1 && !isMobile &&
        <Tooltip
          placement="bottom"
          isOpen={isUserListTooltipOpen}
          target="showUserListTooltip"
          toggle={toggleUserListTooltip}>
          <div className="inside-tooltip" style={{ fontSize: 12 }}>
            <ul style={{ padding: '10px', textAlign: 'left', marginBottom: 0, listStyle: 'none' }}>
              {
                participantsToShow.map((par, index) =>
                  <li key={index}>{par.name}</li>)
              }
            </ul>
          </div>
        </Tooltip>
      }
    </div>
  );
};

MessageDetailsHeader.propTypes = {
  isMobile: PropTypes.bool,
  thread: PropTypes.object,
  isDeleting: PropTypes.object,
  onDeleteMessage: PropTypes.func.isRequired,
  currentProfile: PropTypes.object,
  isUserListTooltipOpen: PropTypes.bool,
  toggleUserListTooltip: PropTypes.func,
  toggleUserDetailsModal: PropTypes.func
};

export default MessageDetailsHeader;
