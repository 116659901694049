import PropTypes from 'prop-types';
import React from 'react';
import DescriptionsForm from './DescriptionsForm';
import TextInput from '../../common/TextInput';
import SelectInput from '../../common/SelectInput';

const styles = {
  card: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop: 0
  }
};

const UnitDetails = ({
  onChange,
  unit,
  errors,
  handleKeyPress,
  onChangeDesc,
  descriptions,
  availability,
  categories
}) => {

  const categoryInputArray = categories.map(cat => Object.assign({},
    { text: cat.title, value: cat.id }));

  return(
    <div>
      <div className="unit-section-title">
        <h6 className="unit-section-title-text">Unit Details</h6>
      </div>
      <div className="card" style={styles.card}>
        <div className="card-block p-3">
          <div className="row">
            <div className="col-sm-12">
              <TextInput
                name="title"
                placeholder="Title"
                label="Title"
                onChange={onChange}
                value={unit.title}
                error={errors.title}
                onKeyPress={handleKeyPress}/>
            </div>
            <DescriptionsForm
              error={errors.description}
              unitId={unit.id}
              onChangeDesc={onChangeDesc}
              descriptions={descriptions}/>
            <div className="col-sm-6">
              <TextInput
                name="slug"
                label="Semantic URL"
                placeholder="Semantic URL"
                onChange={onChange}
                value={unit.slug}
                error={errors.slug}
                onKeyPress={handleKeyPress}/>
            </div>
            <div className="col-sm-6">
              <TextInput
                name="code"
                label="Code"
                placeholder="Code"
                onChange={onChange}
                value={unit.code}
                error={errors.code}
                onKeyPress={handleKeyPress}/>
            </div>
            <div className="col-sm-6">
              <SelectInput
                options={categoryInputArray}
                label="Category"
                name="category"
                onChange={onChange}
                value={unit.category.id}
                defaultOption="Select category"
                error={errors.category}/>
            </div>
            <div className="col-sm-6">
              <SelectInput
                label="Availability"
                onChange={onChange}
                value={unit.isavailable}
                options={availability}
                name="isavailable"
                defaultOption="Select availability"
                error={errors.isavailable}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
UnitDetails.propTypes = {
  onChange: PropTypes.func.isRequired,
  unit: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleKeyPress: PropTypes.func.isRequired,
  onChangeDesc: PropTypes.func,
  descriptions: PropTypes.array,
  categories: PropTypes.array,
  availability: PropTypes.array,
};
export default UnitDetails;
