import React from 'react';

import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, Button, FormGroup } from 'reactstrap';
import { isEmpty } from 'lodash';
import CloseIcon from '../../assets/images/close.svg';

function Praise({ isOpen, toggleModal, feedback, praiseOptions, submitPraise, storePraiseOption, givePraise, seePraiseSelection }) {

  const getPraiseFields = () => praiseOptions?.options?.map((key) => {
    return (
      <FormGroup className="mb-0" key={key.optionId}>
        <div className="custom-checkbox-wrapper">
          <label className="container">
            {key.option} <i>({key.optionDescription})</i>
            <input type="checkbox" name="check" value={key.optionId}
              onChange={storePraiseOption}/>
            <span className="checkmark" />
          </label>
        </div>
      </FormGroup>
    );
  });

  const getGivenPraiseFields = () => seePraiseSelection?.map((row) => {
    return (
      <FormGroup className="mb-0" key={row.id}>
        <div className="custom-checkbox-wrapper">
          <label className="container">
            {row.answerText} <i>({row.answerDescription})</i>
            <input type="checkbox" name="check" value={row.id} checked readOnly/>
            <span className="checkmark" />
          </label>
        </div>
      </FormGroup>
    );
  });

  const generateModalButtons = () => {
    if (!isEmpty(seePraiseSelection) ) {
      return (
        <button className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>
      );
    } else {
      return (
        <div>
          <button className="btn btn-secondary" onClick={toggleModal}>
            Cancel
          </button>
          <Button className="ml-1 sendButton" onClick={submitPraise} disabled={givePraise}>
            Give Praise
          </Button>
        </div>
      );
    }
  };

  const generateModalTitle = () => {
    if (!isEmpty(seePraiseSelection) ) {
      return (`Praise You Gave to ${feedback.fullname.split(' ')[0]}`);
    } else {
      return (`${praiseOptions?.optionTitle?.replace('{{FIRST NAME}}', feedback.fullname.split(' ')[0])}`);
    }
  };

  return (
    <Modal
      centered
      className="customLevelUpModal"
      isOpen={isOpen}
      toggle={toggleModal}>
      <div className="modal-header">
        <span className="modal-title">{generateModalTitle()}</span>
        <div
          onClick={toggleModal}
          className="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">
            <img src={CloseIcon} />
          </span>
        </div>
      </div>
      <ModalBody>
        {isEmpty(seePraiseSelection) ? <p>The feedback was: (Select all that apply)</p>: <p>You said the feedback you received was:</p>}
        {!isEmpty(seePraiseSelection) ? getGivenPraiseFields() : getPraiseFields()}
      </ModalBody>
      <ModalFooter className="bordertop-0">
        {generateModalButtons()}
      </ModalFooter>
    </Modal>
  );
}

Praise.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  givePraise: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  feedback: PropTypes.object.isRequired,
  praiseOptions: PropTypes.object.isRequired,
  submitPraise: PropTypes.func.isRequired,
  storePraiseOption: PropTypes.func.isRequired,
  seePraiseSelection: PropTypes.array,
};

export default Praise;
