import PropTypes from 'prop-types';
import React from 'react';

import { parseJSON } from '../../../utils';

import AdminSectionHeader from '../admin/AdminSectionHeader';
import ThinkingBreakSectionCard from './ThinkingBreakSectionCard';
import Parser from 'html-react-parser';

const ThinkingBreakSection = ({
  drag,
  drop,
  preview,
  section,
  hover,
  isAdmin,
  onEditClick,
  toggleThinkingBreakAccordion,
  thinkingBreakCollapse
}) => {
  let contents = [];
  const data = parseJSON(section.content);
  const { title, content, feedback, correct } = data;
  const thinkingBreakSectionId = `thinking-break-${section.id}`;

  Object.keys(content).forEach((key) => {
    const correctAnswer = correct === parseInt(key);
    const feedbackData = feedback ? feedback[key] : [];

    contents.push(
      <ThinkingBreakSectionCard
        key={key}
        onClick={toggleThinkingBreakAccordion(key)}
        content={content[key]}
        correct={correctAnswer}
        thinkingBreakSectionId={thinkingBreakSectionId}
        thinkingBreakCollapse={thinkingBreakCollapse[key]}
        feedback={feedbackData}/>
    );
  });

  return (
    <section className="essential-thinking" id={thinkingBreakSectionId} ref={preview}>
      {isAdmin &&
        <div style={{ marginTop: -20 }}>
          <AdminSectionHeader
            hover={hover}
            drag={drag}
            drop={drop}
            title="Thinking break"
            sectionId={section.id}
            onEditClick={onEditClick}
            componentName={thinkingBreakSectionId}/>
        </div>}

      <div className="section-icon">
        <svg viewBox="0 0 120 100">
          <path d="M38,2 L82,2 A12,12 0 0,1 94,10 L112,44 A12,12 0 0,1 112,56 L94,90 A12,12 0 0,1 82,98 L38,98 A12,12 0 0,1 26,90 L8,56 A12,12 0 0,1 8,44 L26,10 A12,12 0 0,1 38,2" />
        </svg>
        <div className="icon"><div className="ico-thinking"/></div>
      </div>

      <div>
        <div className="question">{Parser(title)}</div>
        <div id="accordion" role="tablist" aria-multiselectable="true">
          {contents}
        </div>
      </div>
    </section>
  );
};

ThinkingBreakSection.propTypes = {
  hover: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  section: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
  thinkingBreakCollapse: PropTypes.object.isRequired,
  toggleThinkingBreakAccordion: PropTypes.func.isRequired,
  drag: PropTypes.func.isRequired,
  drop: PropTypes.func.isRequired,
  preview: PropTypes.func.isRequired
};

export default ThinkingBreakSection;
