import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { checkIfAuthTokenPresent } from '../../utils';
import * as componentsActions from '../../redux/actions/components';
import * as wallPostsActions from '../../redux/actions/wall-posts';
import * as routerActions from '../../redux/actions/router';
import * as authenticationActions from '../../redux/actions/authentication';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

class WallPostActions extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    wallPost: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    onDeleteClick: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      dropdownOpen: false
    };

    this.toggle = this.toggle.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  toggle() {
    this.setState({ dropdownOpen: ! this.state.dropdownOpen });
  }

  onEdit() {
    const { actions, wallPost, currentUser } = this.props;

    if(!checkIfAuthTokenPresent()){
      actions.clearUserReducers(currentUser.id);
      return actions.redirect('/reenter-credentials', location.pathname);
    }

    const form = `update-wall-post-form-${wallPost.id}`;

    actions.loadForm(form);
    this.toggle();
  }

  onDelete(){
    const { onDeleteClick, actions, currentUser } = this.props;

    if(!checkIfAuthTokenPresent()){
      actions.clearUserReducers(currentUser.id);
      return actions.redirect('/reenter-credentials', location.pathname);
    }

    onDeleteClick();
    this.toggle();
  }

  render() {
    return (
      <span>
        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle className="edit-menu-button">
            &#9679;&#9679;&#9679;
          </DropdownToggle>
          <DropdownMenu right>
            <a className="dropdown-item" onClick={this.onEdit}>Edit post</a>
            <a className="dropdown-item" onClick={this.onDelete}>Delete post</a>
          </DropdownMenu>
        </Dropdown>
      </span>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.data.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, componentsActions, wallPostsActions, routerActions, authenticationActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WallPostActions);
