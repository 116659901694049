import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const BaseRequestorComponent = (props) => {
  const rowDetails = props.rowData;
  let avatar;
  let name;
  let vanity;

  if (rowDetails.requestForUserId === rowDetails.requesterId) {
    name = rowDetails.fullname;
    avatar = rowDetails.avatarURl;
    vanity = rowDetails.vanity;
  } else {
    name = `${rowDetails.requesterName} for ${rowDetails.fullname}`;
    avatar = rowDetails.requesterAvatarURl;
    vanity = rowDetails.requesterVanity;
  }

  return (
    <td>
      <div className="d-flex align-items-center">
        <Link
          to={`/cp/${vanity}`}
          className="user-name d-flex align-items-center">
          <img
            src={avatar ? avatar : require('../../../assets/images/avatar.jpg')}
            className="requestorAvatar"/>
          <span>{name}</span>
        </Link>
      </div>
    </td>
  );
};
  
BaseRequestorComponent.propTypes = {
  rowData: PropTypes.object.isRequired,
};

export default BaseRequestorComponent;