import initialState from './initialState';

import {
  GROUP_REQUEST,
  GROUP_SUCCESS,
  GROUP_FAILURE,
  CREATE_GROUP_REQUEST,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAILURE,
  UPDATE_GROUP_REQUEST,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAILURE,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILURE,
  JOIN_GROUP_SUBMIT,
  JOIN_GROUP_SUCCESS,
  JOIN_GROUP_FAILURE,
  LEAVE_GROUP_REQUEST,
  LEAVE_GROUP_SUCCESS,
  LEAVE_GROUP_FAILURE,
  FETCH_MYGROUPS_REQUEST,
  FETCH_MYGROUPS_SUCCESS,
  FETCH_MYGROUPS_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.group, action) {
  switch (action.type) {
  case GROUP_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case GROUP_SUCCESS:
    if (action.data === '') {
      return Object.assign({}, state, {
        isRequesting: false,
      });
    }
    return Object.assign({}, state, {
      data: action.data._embedded.group,
      isRequesting: false,
    });

  case GROUP_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case CREATE_GROUP_REQUEST:
    return Object.assign({}, state, { isSubmitting: true });

  case CREATE_GROUP_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data, Object.assign({}, action.data)],
      isSubmitting: false,
    });

  case CREATE_GROUP_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case UPDATE_GROUP_REQUEST:
    return Object.assign({}, state, { isUpdating: true });

  case UPDATE_GROUP_SUCCESS: {
    const index = state.data.findIndex(item => item.id === action.data.id);

    return Object.assign(
      {},
      state,
      {
        data: [
          ...state.data.slice(0, index),
          Object.assign({}, action.data),
          ...state.data.slice(index + 1),
        ],
      },
      { isUpdating: false }
    );
  }

  case UPDATE_GROUP_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: false,
    });

  case DELETE_GROUP_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: action.id },
    });

  case DELETE_GROUP_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.filter(group => group.id !== state.isDeleting.id),
      ],
      isDeleting: { status: false, id: null },
    });

  case DELETE_GROUP_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: { status: false, id: null },
    });

  case JOIN_GROUP_SUBMIT:
    return Object.assign({}, state, { isSubmitting: true });

  case JOIN_GROUP_SUCCESS:
    return Object.assign({}, state, {
      myGroups: [...state.myGroups, Object.assign({}, action.data)],
      error: {},
      isSubmitting: false,
    });

  case JOIN_GROUP_FAILURE:
    return Object.assign({}, state, {
      error: Object.assign({}, action.error),
      isSubmitting: false,
    });

  case LEAVE_GROUP_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: action.id },
    });

  case LEAVE_GROUP_SUCCESS:
    return Object.assign({}, state, {
      myGroups: [
        ...state.myGroups.filter(
          myGroup => myGroup.id !== state.isDeleting.id
        ),
      ],
      error: {},
      isDeleting: { status: false, id: null },
    });

  case LEAVE_GROUP_FAILURE:
    return Object.assign({}, state, {
      error: Object.assign({}, action.error),
      isDeleting: { status: false, id: null },
    });

  case FETCH_MYGROUPS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case FETCH_MYGROUPS_SUCCESS:
    if (action.data === '') {
      return Object.assign({}, state, {
        myGroups: [],
        error: {},
        isRequesting: false,
      });
    }
    return Object.assign({}, state, {
      myGroups: [
        ...action.data._embedded.group.filter(
          group => group.status === 'member'
        ),
      ],
      error: {},
      isRequesting: false,
    });

  case FETCH_MYGROUPS_FAILURE:
    return Object.assign({}, state, {
      error: Object.assign({}, action.error),
      isRequesting: false,
    });

  default:
    return state;
  }
}
