import PropTypes from 'prop-types';
import React from 'react';
import _isObject from 'lodash/isObject';
import Button from '../../common/Button';
import TextInput from '../../common/TextInput';
import QuillEditor from '../../common/QuillEditor';
import classNames from 'classnames';

const modules = {
  toolbar: [
    [{ 'align': [] }],
    ['bold', 'italic', 'underline'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{ 'indent': '-1' }, { 'indent': '+1' }],  
    ['link'],
  ],
  clipboard: {
    matchVisual: false,
  }
};

const PublicationForm = ({
  publication,
  onChange,
  onSave,
  isDeleting,
  onCancel,
  onDelete,
  isSubmitting,
  isUpdating,
  errors,
  showDelete,
  onChangeDescription
}) => {
  const titleLabel = (
    <span>
      Title: 
    </span>
  );

  if (_isObject(publication.pubdate)) {
    publication.pubdate = publication.pubdate.date.replace(' 00:00:00.000000', '');
  }

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <TextInput
            name="title"
            label={titleLabel}
            note="Required"
            placeholder="Enter your title"
            error={errors.title}
            value={publication.title}
            onChange={onChange}/>
          <TextInput
            name="author"
            label="Author(s):"
            placeholder="Author(s) - Comma separated for a list"
            error={errors.author}
            value={publication.author}
            onChange={onChange}/>

          <div style={{marginBottom: 10}}
            className={classNames('statement-form-container', {
              'has-danger': errors.description
            })}>
            <label className="form-control-label">Description:</label>
            <QuillEditor
              modules={modules}
              placeholder="Description"
              onChange={onChangeDescription}
              value={publication.description}/>
            
            {errors.description &&
              <div className="text-danger">{errors.description}</div>}
          </div>
        </div>
        <div className="col-lg-12">
          <TextInput
            name="publication"
            label="Publication/Publisher:"
            placeholder="Enter your publication/publisher"
            error={errors.publication}
            value={publication.publication}
            onChange={onChange}/>
        </div>
        <div className="col-lg-6">
          <TextInput
            name="puburl"
            label="Publication/Publisher URL:"
            placeholder="Enter your publication/publisher URL"
            error={errors.puburl}
            value={publication.puburl}
            onChange={onChange}/>
        </div>
        <div className="col-lg-6">
          <TextInput
            type="date"
            name="pubdate"
            label="Date: "
            note="Required"
            error={errors.pubdate}
            value={publication.pubdate}
            onChange={onChange}/>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="action-bar text-right">
            {showDelete &&
              <Button
                type="submit"
                onClick={onDelete}
                styles={{marginLeft: 0}}
                disabled={isDeleting}
                buttonClass="btn-danger"
                buttonText={isDeleting ? 'Deleting...' : 'Delete'}/>}

            <Button
              type="button"
              onClick={onCancel}
              buttonClass="btn-secondary"
              buttonText="Cancel"/>
            <Button
              type="submit"
              onClick={onSave}
              disabled={isSubmitting || isUpdating}
              buttonClass="btn-primary"
              buttonText={isSubmitting || isUpdating ? 'Saving...' : 'Save'}/>

          </div>
        </div>
      </div>
    </div>
  );
};

PublicationForm.propTypes = {
  onDelete: PropTypes.func,
  isUpdating: PropTypes.bool,
  isDeleting: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  publication: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  showDelete: PropTypes.bool,
  onChangeDescription: PropTypes.func
};

export default PublicationForm;
