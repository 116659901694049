import React from 'react';

import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

const SeePortfolioTipsModal = ({ isOpen, onToggle }) => {
  return(
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="lg"
      toggle={onToggle}>
      <ModalHeader
        className="share-modal"
        toggle={onToggle}>Tips for Evaluating Portfolio Files</ModalHeader>
      <ModalBody>
        <div>

          <div className="goodBadSection">
            <div className="goodSection">
              <div className="goodSection-header">
                <i className="far fa-lg text-success mr-2 fa-check-circle" />
                <h3>Signs of Strong Skill Evidence</h3>
              </div>
              <div className="goodSection-list">
                <ul>
                  <li><i className="far mr-1 fa-check text-success" /> Evidence clearly relates to skill (is relevant)</li>
                  <li><i className="far mr-1 fa-check text-success"/> Evidence provided is sufficient to make a judgement</li>
                  <li><i className="far mr-1 fa-check text-success"/> A wide variety of evidence is provided</li>
                  <li><i className="far mr-1 fa-check text-success"/> Evidence shows skill applied at varying levels of ability</li>
                  <li><i className="far mr-1 fa-check text-success"/> Evidence is persuasive (strongly supports skill)</li>
                  <li><i className="far mr-1 fa-check text-success"/> Evidence is presented professionally</li>
                </ul>
              </div>
            </div>
            <div className="badSection">
              <div className="badSection-header">
                <i className="far fa-lg text-danger mr-2 fa-times-circle" />
                <h3>Signs of Weak Skill Evidence</h3>
              </div>
              <div className="badSection-list">
                <ul>
                  <li><i className="fal mr-1 fa-times text-danger"/> Evidence doesn't clearly relate to skill (is irrelevant)</li>
                  <li><i className="fal mr-1 fa-times text-danger"/> Evidence provided is insufficient to make a judgement</li>
                  <li><i className="fal mr-1 fa-times text-danger"/> A limited variety of evidence is provided</li>
                  <li><i className="fal mr-1 fa-times text-danger"/> Evidence shows skill applied at minimal levels of ability</li>
                  <li><i className="fal mr-1 fa-times text-danger"/> Evidence is unconvincing (minimally supports skill)</li>
                  <li><i className="fal mr-1 fa-times text-danger"/> Evidence is presented unprofessionally</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-secondary" onClick={onToggle}>Close</button>
      </ModalFooter>
    </Modal>
  );
};

SeePortfolioTipsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired
};

export default SeePortfolioTipsModal;
