import PropTypes from 'prop-types';
import React from 'react';
import Files from '../../common/Files';

const EvidenceList = ({ files, onRemoveFile }) => {
  return(
    <div>
      {files && files.map((file, index) => {
        return(
          <div className="attached" key={index}>
            <i
              data-index={index}
              onClick={onRemoveFile}
              className="fa fa-minus-circle remove-btn" />
            <Files fileData={file} attached/>
          </div>);
      })}
    </div>
  );
};

EvidenceList.propTypes = {
  files: PropTypes.array,
  onRemoveFile: PropTypes.func.isRequired,
};

export default EvidenceList;
