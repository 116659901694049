import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../common/Button';
import TextInput from '../../common/TextInput';

const AcknowledgementListInput = ({ item, error, index, onChange, onDeleteListItem }) => {
  return (
    <div className="row" id={item.itemId} data-index={index}>
      <div className="col-sm-12 mb-3">
        <div className="input-group">
          <TextInput
            name={`content[${index}].value`}
            error={error}
            value={item.value}
            onChange={onChange}
            placeholder="List Item"
            formOnly />
          <span className="input-group-append" id="item-addon1">
            <Button
              type="button"
              styles={{marginLeft: 0, minWidth: '1%'}}
              onClick={onDeleteListItem}
              buttonClass="btn-secondary"
              buttonText={<i className="fa fa-trash"/>}/>
          </span>
        </div>
      </div>
    </div>
  );
};

AcknowledgementListInput.propTypes = {
  item: PropTypes.object,
  error: PropTypes.string,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onDeleteListItem: PropTypes.func.isRequired
};

export default AcknowledgementListInput;
