import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as skillActions from '../../../redux/actions/skills';
import SkillCardContainer from '../../../components/profile/sections/SkillCardContainer';

class SkillCardHandler extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    skill: PropTypes.object.isRequired,
    isPublicProfile: PropTypes.bool,
    reArrangeSkills: PropTypes.func,
    index: PropTypes.number,
    profile: PropTypes.object,
    editItemIsClicked: PropTypes.func,
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      skill: Object.assign({}, this.props.skill),
    };

    this.onEditSkillClick = this.onEditSkillClick.bind(this);
    this.setSkillBeingUpdatedInStore = this.setSkillBeingUpdatedInStore.bind(this);
  }

  onEditSkillClick(event) {
    event.preventDefault();

    const {
      editItemIsClicked
    } = this.props;

    this.setSkillBeingUpdatedInStore();

    editItemIsClicked();
  }

  setSkillBeingUpdatedInStore() {
    const {
      skill,
      actions
    } = this.props;

    actions.setSkillBeingUpdatedInStore(skill);
  }

  render() {
    const { skill } = this.state;

    const {
      isPublicProfile,
      index,
      profile,
      reArrangeSkills,
    } = this.props;

    return (
      <div className={'mr-2 mb-2'} style={{display: 'inline-block'}}>
        <SkillCardContainer
          skill={skill}
          index={index}
          profile={profile}
          reArrangeSkills={reArrangeSkills}
          isPublicProfile={isPublicProfile}
          onEditSkillClick={this.onEditSkillClick}
          setSkillBeingUpdatedInStore={this.setSkillBeingUpdatedInStore} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let skill = {
    name: ''
  };

  if (ownProps.skill) {
    skill = Object.assign({}, skill, ownProps.skill);
  }

  return {
    skill,
    profile: state.profile.data,
    skillBeingUpdated: state.skills.skillBeingUpdated
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, skillActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillCardHandler);
