import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as endorsementActions from '../../redux/actions/badge-endorsement';
import * as componentActions from '../../redux/actions/components';

import { withHooks } from '../../utils/withHooks';

import { Link } from 'react-router-dom';
import Validator from '../../validator';
import EndorsementRequestContents from '../../components/skill-badges/EndorsementRequestContents';
import EndorseForm from '../../components/skill-badges/EndorseForm';

import Loading from '../../components/common/Loading';
import ConfirmEndorsementModal from './ConfirmEndorsementModal';

const collapseData = [
  {
    id: 1,
    name: 'Claimed Abilities',
    open: false,
  },
  {
    id: 2,
    name: 'Written Justification',
    open: false,
  },
  {
    id: 3,
    name: 'Portfolio Files',
    open: false,
  },
  {
    id: 4,
    name: 'Current Endorsements',
    open: false,
  }
];

class EndorsementRequest extends Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    endorsement: PropTypes.object.isRequired,
    userData: PropTypes.object.isRequired,
    requestedEndorsements: PropTypes.array,
    isAuthenticated: PropTypes.bool,
    modal: PropTypes.string
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      errors: {},
      skip: false,
      showEndorseForm: false,
      checkboxData: [],
      isCollapse: false,
      activeSlideIndex: 0,
      userData: Object.assign({}, this.props.userData),
      endorsementContentCollapseData: [...collapseData],
      isBadge: false
    };

    this.toggle = this.toggle.bind(this);
    this.onPrev = this.onPrev.bind(this);
    this.onNext = this.onNext.bind(this);
    this.onSkip = this.onSkip.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onEndorse = this.onEndorse.bind(this);
    this.onContinue = this.onContinue.bind(this);
    this.handleCollapse = this.handleCollapse.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.updateUserDataState = this.updateUserDataState.bind(this);
    this.handleTestimonial = this.handleTestimonial.bind(this);
  }

  componentDidMount(){
    const { params, actions } = this.props;

    const requestEndorsementAction = params?.resourceType === 'skill' ? 'requestSkillEndorsementsById' : 'requestBadgeEndorsementsById';
    actions[requestEndorsementAction](params?.id);
  }

  componentWillReceiveProps(nextProps){
    const { isRequesting, requestedEndorsements, isSubmitting, data } = nextProps.endorsement;
    const hasCompletedRequestingContent = !isRequesting && Object.keys(requestedEndorsements).length > 0;
    const hasCompletedNextEndorsement = !isSubmitting && Object.keys(data).length > 0;
    const hasCompletedEndorsement = !this.props.endorsement.isSubmitting && Object.keys(this.props.endorsement.data).length > 0;

    if(requestedEndorsements !== this.props.endorsement.requestedEndorsements && hasCompletedRequestingContent){
      const checkboxData = requestedEndorsements.map(endorsement => Object.assign({}, {
        id: endorsement.badgeIssued?.id || endorsement.skill?.id,
        code: endorsement.unit?.code,
        slug: endorsement.unit?.slug,
        name: endorsement.badge?.name,
        checked: false,
      }));

      this.setState({
        checkboxData,
        isBadge: requestedEndorsements[0].badge
      });
    }

    if(hasCompletedEndorsement !== hasCompletedNextEndorsement && hasCompletedNextEndorsement){
      this.props.actions.closeModal();
    }
  }

  componentWillUnmount(){
    this.props.actions.clearEndorsementState();
  }

  isValid(field = null) {
    const rules = {
      name: ['required'],
      position: ['required'],
      organization: ['required']
    };
    const { userData, errors: prevErrors} = this.state;
    const validate = Validator.createValidator(rules, userData, field);
    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  updateUserDataState(event) {
    const { name, value } = event.target;
    let { userData } = this.state;

    userData[name] = value;

    this.setState({ userData }, () => this.isValid(name));
  }

  handleCollapse(catId){
    const { endorsementContentCollapseData }= this.state;

    return ()=>{
      const resolveTable = endorsementContentCollapseData.map((item)=>{
        if (item.id === catId){
          const obj = { ...item, open: !item.open};
          return obj;
        }
        return item;
      });

      this.setState({ endorsementContentCollapseData: resolveTable});
    };
  }

  toggle() {
    this.setState({ isCollapse: !this.state.isCollapse });
  }

  onContinue() {
    this.setState({ showEndorseForm: true });
  }

  onNext(){
    const { activeSlideIndex } = this.state;
    if(activeSlideIndex + 1 === this.props.endorsement.requestedEndorsements.length ){
      return this.setState({
        showEndorseForm: true
      });
    }
    this.setState({
      activeSlideIndex : activeSlideIndex+1,
      endorsementContentCollapseData: [...collapseData]
    });
  }

  onPrev(){
    const { activeSlideIndex, showEndorseForm } = this.state;
    if(activeSlideIndex + 1 === this.props.endorsement.requestedEndorsements.length && showEndorseForm ){
      return this.setState({
        showEndorseForm: false
      });
    }

    this.setState({
      activeSlideIndex : activeSlideIndex-1,
      endorsementContentCollapseData: [...collapseData]
    });
  }

  onCheckboxChange(event){
    const checkboxId = event.target.id;

    const { checkboxData } = this.state;
    const index = checkboxData.findIndex(item=>item.id === checkboxId);
    const checkboxCheckedObject = checkboxData[index];
    const updatedCheckboxData = [
      ...checkboxData.slice(0, index),
      {
        ...checkboxCheckedObject,
        checked: !checkboxCheckedObject?.checked

      },
      ...checkboxData.slice(index+1)
    ];

    this.setState({
      checkboxData: updatedCheckboxData
    });
  }

  handleTestimonial(e){
    if (e.target.value.split(' ').length <= 200){
      this.setState({ testimonial : e.target.value });
    }
  }

  onEndorse() {
    const { checkboxData, userData, skip, testimonial } = this.state;

    if (! this.isValid()) {
      return ;
    }

    const checkedBadgesIds = checkboxData.filter(data => data.checked).map(badge => badge.id);
    if(!skip){
      return this.props.actions.openModal('endorsement-confirm-modal');
    }

    const issueIds = {
      [checkedBadgesIds] : {
        testimonial : testimonial ? testimonial : ''
      }
    };

    this.submitEndorsement(issueIds, userData);
    this.props.actions.closeModal();
  }

  onConfirm(){
    const { checkboxData } = this.state;
    const confirmedCheckboxData = checkboxData.map(data => Object.assign({},data,{
      checked: true
    }));

    this.setState({
      checkboxData: confirmedCheckboxData,
      skip: true
    }, () => this.onEndorse());
  }

  onSkip(){
    this.setState({
      skip: false
    }, () => this.props.actions.closeModal());
  }

  submitEndorsement(issuedIds, userData) {
    const { params, actions } = this.props;

    const data = Object.assign(
      {},
      {
        ...userData,
        [params?.resourceType === 'skill' ? 'skillIds': 'badgeIssuedIds']: issuedIds,
      }
    );

    const sumbitEndorsementAction = params?.resourceType === 'skill' ? 'confirmSkillEndorsement' : 'confirmBadgeEndorsement';

    actions[sumbitEndorsementAction](data, params?.id);
  }

  getEvidenceLink(requestor, isBadge, activeBadgeSlide) {
    if (isBadge && activeBadgeSlide?.badgeIssued?.visibility === 0) {
      return <p>{requestor.name}'s evidence for <span className="orange">{activeBadgeSlide?.badge?.name}</span> is below for your review:</p>;
    } else if (!isBadge && activeBadgeSlide?.skill?.visibility === 0) {
      return <p>{requestor.name}'s evidence for <span className="orange">{activeBadgeSlide?.skill?.name}</span> is below for your review:</p>;
    } else {
      return <p>{requestor.name}'s evidence for <Link to={`${(isBadge) ? `/skill-badges/badge-details/${activeBadgeSlide?.badgeIssued?.id}` : `/skill-evidence/${activeBadgeSlide?.skill?.id}`}`} target="_blank">{(isBadge) ? activeBadgeSlide?.badge?.name : activeBadgeSlide?.skill?.name}</Link> is below for your review:</p>;
    }
  }

  render() {
    const {
      endorsementContentCollapseData,
      activeSlideIndex,
      showEndorseForm,
      checkboxData,
      userData,
      errors,
      isBadge,
    } = this.state;

    const { params, endorsement, modal, isAuthenticated } = this.props;
    const { isRequesting, requestedEndorsements, isSubmitting, data } = endorsement;
    const hasCompletedRequestingContent = !isRequesting && Object.keys(requestedEndorsements).length > 0;

    const moreThanOneRequest = requestedEndorsements.length > 1;
    const activeBadgeSlide = requestedEndorsements[activeSlideIndex];
    const resourceType = params.resourceType === 'badge' ? 'badgeIssued' : params.resourceType;
    const activeCheckboxObject = checkboxData[activeSlideIndex];
    const requestor = hasCompletedRequestingContent && activeBadgeSlide.user;
    const checkedBadges = checkboxData.filter(data => data.checked);
    const skippedBadges = checkboxData.filter(data => !data.checked);
    const hasCompletedEndorsement = !isSubmitting && Object.keys(data).length > 0;
    const isConfirmModalOpen = modal && modal === 'endorsement-confirm-modal';

    const contentHeader = showEndorseForm ?
      (<p><strong>Skill Badges that you are endorsing ({checkedBadges.length}/{requestedEndorsements.length})</strong> :
        {checkedBadges.map((data, index) =>
          (<span key={index}>
            <Link
              to={`/skill-badges/badge-details/${data.id}`}
              target="_blank">
              <span style={{paddingLeft: 5}}>
                {data.name}
              </span>
            </Link>
            {index !== checkedBadges.length - 1 && <span className="name-gap">,</span>}
          </span>))}
      </p>) :
      <p><strong>{requestor.name} has requested that you endorse {moreThanOneRequest ? 'several skills' : 'a skill'} you've observed in a training activity, project, or work setting: </strong></p>;

    if(hasCompletedEndorsement){
      return(
        <div className="container">
          <div className="content badges">
            <div className="successfully-endorsed">
              <h5 className="message">
                Thank you for endorsing {requestor.name}'s {moreThanOneRequest ? 'skill badges!' : 'skill badge!'}
              </h5>

              {!isAuthenticated && <h6>Have you explored how CareerPrepped can help you or your organization?</h6>}
              <Link to={isAuthenticated ? '/home' : '/'}>Go to Home Page</Link>
            </div>
          </div>
        </div>
      );
    }

    return(
      <div className="container">
        {isConfirmModalOpen &&
          <ConfirmEndorsementModal
            isOpen={isConfirmModalOpen}
            isSubmitting={isSubmitting}
            requestor={requestor}
            onConfirm={this.onConfirm}
            onSkip={this.onSkip}
            skippedBadges={skippedBadges}
            isBadge={isBadge}/>}

        <div className="content badges">
          <div className="row">
            <div className="col-lg-12">
              <h2>Endorsement Request</h2>
            </div>
            <div className="col-lg-12">
              {!hasCompletedRequestingContent && <div><Loading/></div>}
              {(hasCompletedRequestingContent) &&
                <div>
                  {contentHeader}
                  <div className="endorsement-request">
                    {!showEndorseForm &&
                      <div>
                        <p>{requestor.name}'s evidence for <Link to={`/skill-badges/badge-details/${activeBadgeSlide[resourceType].id}`} target="_blank">{activeBadgeSlide[params.resourceType].name}</Link> is below for your review:</p>
                        <EndorsementRequestContents
                          collapseData={endorsementContentCollapseData}
                          activeBadgeSlide={activeBadgeSlide}
                          checkboxData={activeCheckboxObject}
                          onCheckboxChange={this.onCheckboxChange}
                          requestor={requestor}
                          singleBadge={!moreThanOneRequest}
                          onContinue={this.onContinue}
                          handleCollapse={this.handleCollapse}
                          modal={modal}
                          actions={this.props.actions}/>
                      </div>}
                    {showEndorseForm &&
                      <EndorseForm
                        errors={errors}
                        isSubmitting={isSubmitting && !isConfirmModalOpen}
                        userData={userData}
                        requestor={requestor}
                        onEndorse={this.onEndorse}
                        checkedAnyBadges={checkedBadges.length > 0}
                        updateUserDataState={this.updateUserDataState}/>}

                    <div className="controls">
                      {activeSlideIndex !== 0 && moreThanOneRequest &&
                        <a onClick={this.onPrev} className="prev endorsement-request-slider-btn">
                          <i className="fa fa-caret-left"/> PREV
                        </a>}
                      {(!showEndorseForm && moreThanOneRequest) &&
                        <a onClick={this.onNext} className="next endorsement-request-slider-btn">
                          NEXT <i className="fa fa-caret-right"/>
                        </a>}
                    </div>
                  </div>
                </div>}
              {!showEndorseForm &&
                <div className="text-right counter">
                  {activeSlideIndex+1} of {requestedEndorsements.length}
                </div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const userData = {
    name: '',
    position: '',
    organization: ''
  };

  return {
    userData,
    isAuthenticated: state.auth.isAuthenticated,
    modal: state.components.modal,
    endorsement: state.badgeEndorsement,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, endorsementActions, componentActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(EndorsementRequest));
