import React from 'react';

import PropTypes from 'prop-types';

const SkillBadgeSurvey = ({ statistics }) => {
  return (
    <span>
      {statistics.surveys.data.length === 0 &&
        <p>Nothing to show!</p>}

      {statistics.surveys.data.length > 0 &&
        <ul>
          {statistics.surveys.data.map((survey, index) => {
            return (
              <span key={survey.id}>
                <li>{`Question ${index + 1}:`} {survey.question}</li>
                <ul>
                  <li>Total Ratings: {survey.totalRatings}</li>
                  <li>Strongly Agree: {survey.stronglyAgree}</li>
                  <li>Agree: {survey.agree}</li>
                  <li>Neutral: {survey.neutral}</li>
                  <li>Disagree: {survey.disagree}</li>
                  <li>Strongly Disagree: {survey.stronglyDisagree}</li>
                </ul>
              </span>
            );
          }
          )}
        </ul>}
    </span>
  );
};

SkillBadgeSurvey.propTypes = {
  statistics: PropTypes.object.isRequired
};

export default SkillBadgeSurvey;
