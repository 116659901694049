import PropTypes from 'prop-types';
import React from 'react';
import WebpageSection from './WebpageSection';

const WebpageSectionList = ({ profileOrder, profile, isAdminViewing = false }) => {
  return (
    <div>
      {profileOrder.map((section, index) =>
        (<WebpageSection
          key={section.id}
          index={index}
          isPublicProfile
          profile={profile}
          section={section}
          isAdminViewing={isAdminViewing} />))}
    </div>
  );
};

WebpageSectionList.propTypes = {
  profileOrder: PropTypes.array,
  profile: PropTypes.object,
  isAdminViewing: PropTypes.bool
};

export default WebpageSectionList;
