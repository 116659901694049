import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Helmet } from 'react-helmet';

import {
  AGREEMENT_QUESTION,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_INFO,
  CODE_OF_CONDUCT,
  CODE_OF_CONDUCT_PART1,
  CODE_OF_CONDUCT_PART2,
  CODE_OF_CONDUCT_PART3,
  COPYRIGHT,
  COPYRIGHT_INFO_PART1,
  COPYRIGHT_INFO_PART2,
  COPYRIGHT_INFO_PART3,
  DISCLAIMER,
  DISCLAIMER_INFO_PART1,
  DISCLAIMER_INFO_PART2,
  DISCLAIMER_INFO_PART3,
  GOVERNING_LAW,
  GOVERNING_LAW_INFO,
  HONOUR_CODE,
  INDEMNIFICATION,
  INDEMNIFICATION_INFO,
  LIMITATION,
  LIMITATION_INFO,
  MAXKNOWLEDGE_INFO,
  PRIVACY_POLICY,
  PRIVACY_POLICY_INFO,
  PURCHASES,
  PURCHASES_INFO,
  SITE_INFO,
  SITE_INFO_PART1,
  SITE_INFO_PART10,
  SITE_INFO_PART11,
  SITE_INFO_PART2,
  SITE_INFO_PART3,
  SITE_INFO_PART4,
  SITE_INFO_PART5,
  SITE_INFO_PART6,
  SITE_INFO_PART7,
  SITE_INFO_PART8,
  SITE_INFO_PART9,
  TERMS_OF_SERVICE,
  TERMS_OF_SERVICE_INFO,
  TERMS_OF_SERVICE_INFO_CONTINUED,
  TRADEMARKS,
  TRADEMARKS_INFO,
  USER_CONDUCT,
  USER_CONDUCT_INFO,
  USING_SERVICE,
  USING_SERVICE_INFO,
  USING_SERVICE_INFO_PART1,
  USING_SERVICE_INFO_PART2,
  USING_SERVICE_INFO_PART3,
} from '../../constants/terms';
import { CP_LINK, TERMS_LINK } from '../../constants';

class Terms extends Component {
  render() {
    return (
      <div className="interior info terms-page">
        <Helmet title="Terms" />
        <div className="container">
          <div className="row">
            <div style={{ paddingTop: 50 }} className="content terms">
              <div className="info-title">
                <h2>{TERMS_OF_SERVICE}</h2>
              </div>
              <p>
                {TERMS_OF_SERVICE_INFO}{' '}
                <a
                  href="https://www.careerprepped.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  {CP_LINK}
                </a>
                {TERMS_OF_SERVICE_INFO_CONTINUED}
              </p>
              <p>{MAXKNOWLEDGE_INFO}</p>
              <p>
                {AGREEMENT_QUESTION}{' '}
                <a href={'mailto:terms@maxknowledge.com'}>{TERMS_LINK}</a>.
              </p>

              <hr />
              <h6 className="font-weight-bold">PRIVACY POLICY</h6>
              <p>
                Our <Link to="/privacy">{PRIVACY_POLICY}</Link>{' '}
                {PRIVACY_POLICY_INFO}
              </p>

              <hr />
              <h6 className="font-weight-bold">{USING_SERVICE}</h6>
              <p>{USING_SERVICE_INFO}</p>
              <p>{USING_SERVICE_INFO_PART1}</p>
              <p>{USING_SERVICE_INFO_PART2}</p>
              <p>{USING_SERVICE_INFO_PART3}</p>

              <hr />
              <h6 className="font-weight-bold">{HONOUR_CODE}</h6>
              <p>{CODE_OF_CONDUCT}</p>
              <ul>
                <li>{CODE_OF_CONDUCT_PART1}</li>
                <li>{CODE_OF_CONDUCT_PART2}</li>
                <li>{CODE_OF_CONDUCT_PART3}</li>
              </ul>

              <hr />
              <h6 className="font-weight-bold">{USER_CONDUCT}</h6>
              <p>{USER_CONDUCT_INFO}</p>
              <p>{SITE_INFO}</p>
              <ul className="ul-lower-latin">
                <li>{SITE_INFO_PART1}</li>
                <li>{SITE_INFO_PART2}</li>
                <li>{SITE_INFO_PART3}</li>
                <li>{SITE_INFO_PART4}</li>
              </ul>
              <p>{SITE_INFO_PART5}</p>
              <ul className="ul-lower-latin ul-lower-latin-1">
                <li>{SITE_INFO_PART6}</li>
                <li>{SITE_INFO_PART7}</li>
                <li>{SITE_INFO_PART8}</li>
                <li>{SITE_INFO_PART9}</li>
                <li>{SITE_INFO_PART10}</li>
                <li>{SITE_INFO_PART11} </li>
              </ul>

              <hr />
              <h6 className="font-weight-bold">{COPYRIGHT}</h6>
              <p>{COPYRIGHT_INFO_PART1}</p>
              <p>{COPYRIGHT_INFO_PART2}</p>
              <p>{COPYRIGHT_INFO_PART3}</p>

              <hr />
              <h6 className="font-weight-bold">{TRADEMARKS}</h6>
              <p>{TRADEMARKS_INFO}</p>

              <hr />
              <h6 className="font-weight-bold">{PURCHASES}</h6>
              <p>{PURCHASES_INFO}</p>

              <hr />
              <h6 className="font-weight-bold">{CANCEL_SUBSCRIPTION}</h6>
              <p>{CANCEL_SUBSCRIPTION_INFO}</p>

              <hr />
              <h6 className="font-weight-bold text-danger">{DISCLAIMER}</h6>
              <p>{DISCLAIMER_INFO_PART1}</p>

              <p>{DISCLAIMER_INFO_PART2}</p>

              <p>{DISCLAIMER_INFO_PART3}</p>

              <hr />
              <h6 className="font-weight-bold text-danger">{LIMITATION}</h6>
              <p>{LIMITATION_INFO}</p>

              <hr />
              <h6 className="font-weight-bold text-danger">
                {INDEMNIFICATION}
              </h6>
              <p>{INDEMNIFICATION_INFO}</p>

              <hr />
              <h6 className="font-weight-bold text-danger">{GOVERNING_LAW}</h6>
              <p>{GOVERNING_LAW_INFO}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Terms;
