import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { AsyncPaginate } from 'react-select-async-paginate';

import SuccessMessage from './SuccessMessage';
import Loading from '../common/Loading';
import SearchYourSkills from './searchYourSkills';
import CloseIcon from '../../assets/images/close.svg';

import { isEmpty, isNull, isUndefined } from 'lodash';
import { callApi } from '../../middlewares/api';

import {
  ADD_SKILLS_HEADER,
  ADD_SKILLS_MESSAGE,
  SUGGEST_SKILLS_MESSAGE
} from '../../constants/myskills';

import { getSkillsStatistics } from '../../redux/actions/statistics';
import { getDefaultSkills, resetDefaultSkills } from '../../redux/actions/myskills';
import {
  updateMyCareerPreppedStatus,
  getMyCareerPreppedStatus
} from '../../redux/actions/my-careerprepped-status';

import { getSkillCategories, getSkillSubCategories } from '../../redux/actions/skill-categories';

import {
  selectFormattedCategories,
  selectCategoriesPageCount,
  selectFormattedSubCategories,
  selectSubCategoriesPageCount
} from '../../redux/selectors/skill-categories';

const SKILLS_LIMIT = 50;
const SKILLS_PER_PAGE = 50;

class AddYourCustomSkills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
      selectedCustomSkills: [],
      newSkill: '',
      addNewSkill: false,
      successMessage: false,
      filtered: [],
      isSkillsRequesting: true,
      isAddingSkill: false,
      searchInputText: '',
      searchSubmitClicked: false,
      showSuggestSkillForm: false,
      showCustomSuggestions: true,
      pageNumber: 0,
      hasMoreSearchResults: true,
      setFromProps: false,
      suggestedSkillName: '',
      successMessageText: ADD_SKILLS_MESSAGE,
      categoryId: '',
      initialSkillsRequest: false
    };

    this.callAPI = this.callAPI.bind(this);
    this.onClearKeywords = this.onClearKeywords.bind(this);
    this.onChangeCategory = this.onChangeCategory.bind(this);
    this.loadCategoryOptions = this.loadCategoryOptions.bind(this);
    this.selectCustomSkills = this.selectCustomSkills.bind(this);
  }

  componentWillMount(){
    this.setState({ initialSkillsRequest: true });
    this.props.actions.getDefaultSkills()
      .then(() => this.setState({ initialSkillsRequest: false }));
  }

  componentWillReceiveProps(nextProps) {
    if(isEmpty(this.state.filtered) || (this.props.defaultSkills && nextProps.defaultSkills
      && this.props.defaultSkills.length !== nextProps.defaultSkills.length)) {
      this.setState({filtered: nextProps.defaultSkills || [], isSkillsRequesting: false, setFromProps: false});
    }
  }

  async loadCategoryOptions(search, loadedOptions, actionMeta) {
    const { page, name: inputName } = actionMeta;

    if (inputName === 'category') {
      await this.props.actions.getSkillCategories(page, search);
    }

    if (inputName === 'subCategory' && (! isUndefined(this.state.category))) {
      await this.props.actions.getSkillSubCategories(this.state.category.value, page, search);
    }

    const options = (inputName === 'category') ?
      this.props.categories : this.props.subCategories;

    const pageCount = (inputName === 'category') ?
      this.props.categoriesPageCount : this.props.subCategoriesPageCount;

    return {
      options,
      hasMore: pageCount > page,
      additional: { page: page + 1, name: inputName }
    };
  }

  onChangeCategory(data, actionMeta) {
    const { name } = actionMeta;

    this.props.actions.resetDefaultSkills();

    let showCustomSuggestions = true;
    const subCategory = null;
    const selectedValueId = data ? data.id : '';
    const selectedValue = data ? data.value : '';

    this.setState({
      [name]: data,
      [`${name}Id`]: selectedValueId,
      ...((name === 'category') && { subCategory }),
      isSkillsRequesting: true,
      hasMoreSearchResults: true
    });

    let categoryId = '';
    let subCategoryId = '';

    if (name === 'category') {
      categoryId = selectedValue;
      showCustomSuggestions = !data;
    }

    if (name === 'subCategory') {
      subCategoryId = selectedValue;
      showCustomSuggestions = false;
    }

    if ((name === 'subCategory') && isNull(data)) {
      showCustomSuggestions = false;
      categoryId = this.state.category.value;
    }

    this.setState({ initialSkillsRequest: true });

    this
      .props
      .actions
      .getDefaultSkills(this.state.searchInputText, 1, categoryId, subCategoryId)
      .then(() => this.setState({
        pageNumber: 1,
        isSearchChanged: true,
        isSkillsRequesting: false,
        searchSubmitClicked: true,
        showCustomSuggestions,
        initialSkillsRequest: false
      }));
  }

  callAPI() {
    let query = '';
    let categoryId = '';
    let subCategoryId = '';

    const page = this.props.defaultSkillsPaginationData.page + 1;

    if (this.state.category) {
      categoryId = this.state.category.value;
    }

    if (this.state.subCategory) {
      categoryId = '';
      subCategoryId = this.state.subCategory.value;
    }

    if (this.state.searchInputText) {
      query = this.state.searchInputText;
    }

    this.props.actions.getDefaultSkills(query, page, categoryId, subCategoryId);
  }

  onClearKeywords() {
    this.setState({ searchInputText: '', initialSkillsRequest: true });

    let categoryId = '';
    let subCategoryId = '';
    let showCustomSuggestions = true;

    if (this.state.category) {
      showCustomSuggestions = false;
      categoryId = this.state.category.value;
    }

    if (this.state.subCategory) {
      categoryId = '';
      showCustomSuggestions = false;
      subCategoryId = this.state.subCategory.value;
    }

    this.props
      .actions
      .getDefaultSkills('', 1, categoryId, subCategoryId)
      .then(() => this.setState({ initialSkillsRequest: false, showCustomSuggestions }));
  }

  searchTextHandler = async (event) => {
    const searchText = event.target.value;
    this.setState({searchInputText: searchText, pageNumber: 0, hasMoreSearchResults: true, isSearchChanged: true});
    if (isEmpty(searchText)) {
      let categoryId = '';
      let subCategoryId = '';
      let showCustomSuggestions = true;

      if (this.state.category) {
        showCustomSuggestions = false;
        categoryId = this.state.category.value;
      }

      if (this.state.subCategory) {
        categoryId = '';
        showCustomSuggestions = false;
        subCategoryId = this.state.subCategory.value;
      }

      this.setState({ initialSkillsRequest: true });

      this.props
        .actions
        .getDefaultSkills('', 1, categoryId, subCategoryId)
        .then(() => this.setState({ initialSkillsRequest: false }));

      this.setState({
        isSkillsRequesting: false,
        searchSubmitClicked: false,
        showCustomSuggestions,
        filtered: [],
        setFromProps: true,
        selectedCustomSkills: [],
        suggestedSkillName: ''
      });
    }
  };

  handleSuggestSkillNameChange = event => this.setState({suggestedSkillName: event.target.value});

  toggleSuccessMessageModal = (event) => {
    const buttonID = event.target.id;
    this.setState({isAddingSkill: true});
    buttonID === 'addSkillsButton' ? this.handleAddSkill() : this.handleSuggestSkill();
    this.setState({successMessage: true});
  };

  handleSuggestSkill = async () => {
    const {suggestedSkillName} = this.state;
    const {actions} = this.props;
    if(isEmpty(suggestedSkillName)){
      return;
    }
    const requestObject = {
      name: suggestedSkillName
    };
    callApi(requestObject, 'post', 'skill/suggested', true).then(async () => {
      this.setState({
        successMessageText: SUGGEST_SKILLS_MESSAGE,
      });
      await actions.updateMyCareerPreppedStatus();
      actions.getMyCareerPreppedStatus();
    }).catch(() => {
      toastr.error('Skill suggestion was not added. Please contact the admin.');
    });
  }

  handleAddSkill = async () => {
    const { refreshMySkills, actions, userId } = this.props;
    const requestObject = {
      skillIds: this.state.selectedCustomSkills
    };

    await callApi(requestObject, 'post', 'user/skills', true);
    this.setState({ newSkill: ''});
    await actions.updateMyCareerPreppedStatus();
    actions.getMyCareerPreppedStatus();
    actions.getSkillsStatistics(userId);

    if(refreshMySkills) {
      refreshMySkills();
    }
  }

  selectCustomSkills = (event) => {
    const { addedSkillsCount } = this.props;
    if(parseInt(addedSkillsCount) < SKILLS_LIMIT) {
      const cskillId = event.target.getAttribute('data-value');
      const arr = [...this.state.selectedCustomSkills, cskillId];
      this.setState({ selectedCustomSkills: arr, suggestedSkillName: '' });
    } else {
      toastr.error('You can add only 50 skills.');
    }
  }

  removeCustomSkills = (event) => {
    const cskillId = event.target.getAttribute('data-value');

    const arr = this.state.selectedCustomSkills.filter(
      skill => cskillId !== skill
    );
    this.setState({ selectedCustomSkills: arr, suggestedSkillName: '' });
  }

  renderCustomSkillsList = () => {
    if(this.state.isSkillsRequesting) {
      return <Loading />;
    }
    return this.state?.filtered?.map((skillSet) => {
      return (
        <div className="skill-box" key={skillSet.name}>
          {
            skillSet.infoUrl ? <div className="ellipsed-wrapper"><a href={skillSet.infoUrl} target="_blank">{skillSet.name}</a></div> : <div className="ellipsed-wrapper"><span>{skillSet.name}</span></div>
          }
          {!this.state.selectedCustomSkills.includes(skillSet.id) && (
            <i
              aria-hidden="true"
              className="icon-add pointer"
              data-value={skillSet.id}
              onClick={this.selectCustomSkills} />
          )}
          {this.state.selectedCustomSkills.includes(skillSet.id) && (
            <i
              aria-hidden="true"
              style={{ color: '#F04E30' }}
              className="icon-checkbox orange pointer"
              data-value={skillSet.id}
              onClick={this.removeCustomSkills}/>
          )}
        </div>
      );
    });
  };

  setHasMoreSearchResults = value => this.setState({hasMoreSearchResults: value});

  handleSubmitSearchSkill = async (event = null) => {
    const { searchInputText, isSearchChanged } = this.state;

    // do not allow search in case of empty or one character
    if(isEmpty(searchInputText) || searchInputText.length === 1) {
      return;
    }
    if(this.state.pageNumber === 0 && !isSearchChanged){
      return;
    }

    // set pagenumber and make API call
    let callingPageNumber = this.state.pageNumber + 1;

    // If search initiated on search icon click then do not paginate
    if(!isNull(event)) {
      this.setState({pageNumber: 0});
      callingPageNumber = 1;
    }

    // start loader for the first search
    if(callingPageNumber === 1) {
      this.setState({isSkillsRequesting: true});
    }

    // categories
    let categoryId = '';
    let subCategoryId = '';

    if (this.state.category) {
      categoryId = this.state.category.value;
    }

    if (this.state.subCategory) {
      subCategoryId = this.state.subCategory.value;
    }

    await this.props.actions.getDefaultSkills(searchInputText, callingPageNumber, categoryId, subCategoryId);

    // update corresponding states and update response data
    this.setState({
      searchSubmitClicked: true,
      showCustomSuggestions: false,
      pageNumber: callingPageNumber,
      isSkillsRequesting: false,
      isSearchChanged: false,
    });

    // reset selection of skills if next search is empty
    if(isEmpty(this.props.defaultSkills)) {
      this.setState({
        selectedCustomSkills: [],
        suggestedSkillName: '',
      });
    }

    if(callingPageNumber <= 1) {
      this.setState({filtered: this.props.defaultSkills});
    } else if(callingPageNumber > 1) {
      const skillsList = [...this.state.filtered, ...this.props.defaultSkills];
      this.setState({filtered: skillsList});
    }

    if(this.props.defaultSkills.length < SKILLS_PER_PAGE) {
      this.setState({hasMoreSearchResults: false});
    }
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter'){
      // If search initiated on hitting enter then do not paginate
      if(this.state.isSearchChanged) {
        this.setState({ pageNumber: 0 }, () => this.handleSubmitSearchSkill());
      }
      return true;
    }
  }

  setSuggestSkillForm = () => this.setState({showSuggestSkillForm: true, showCustomSuggestions: false});

  renderSkillListHeader = () => (
    <>
      <p className="font12">
        Add up to 50 skills, prove each with evidence, collect feedback, and decide when to showcase your evidence and feedback ratings on your Career Site. <a href="https://help.careerprepped.com/help/how-do-i-show-or-hide-skill-evidence-pages-on-my-career-site" className="underline" target="_blank">Learn more</a>.
      </p>
      <FormGroup className="myskillAdvSearch">
        <InputGroup>
          <Input
            type="text"
            name="search"
            id="search"
            placeholder="Search skills by name to add (e.g. Photoshop)"
            value={this.state.searchInputText}
            onKeyPress={this.handleKeyPress}
            onChange={this.searchTextHandler}/>
          <InputGroupAddon className="pointer" addonType="append" data-value="searchIcon" onClick={this.handleSubmitSearchSkill}>
            <i className="fa fa-search" />
          </InputGroupAddon>
        </InputGroup>
        {(this.state.searchInputText.length !== 0) &&
          <span
            className="far fa-times"
            onClick={this.onClearKeywords}
            style={{
              position: 'absolute',
              zIndex: 9,
              right: 80,
              top: 82,
              cursor: 'pointer'
            }}/>}
      </FormGroup>
      <div className="row">
        <div className="col-sm-12 col-md-6 pr-md-2">
          <AsyncPaginate
            isClearable
            cacheOptions
            isSearchable
            name="category"
            classNamePrefix="select"
            className={`form-group basic-single ${this.state.errors?.category ? 'is-invalid' : ''}`}
            additional={{ page: 1, name: 'category' }}
            loadOptions={this.loadCategoryOptions}
            placeholder="Search by Category: All"
            hideSelectedOptions={false}
            value={this.state.category}
            onChange={this.onChangeCategory} />
        </div>
        <div className="col-sm-12 col-md-6 pl-md-2">
          <AsyncPaginate
            isClearable
            cacheOptions
            isSearchable
            name="subCategory"
            classNamePrefix="select"
            className={`form-group basic-single ${
              this.state.errors?.category || this.state.errors?.subCategory ? 'is-invalid' : ''
            }`}
            cacheUniqs={[this.state.categoryId]}
            additional={{ page: 1, name: 'subCategory' }}
            loadOptions={this.loadCategoryOptions}
            placeholder="Search by Sub Category: All"
            hideSelectedOptions={false}
            value={this.state.subCategory}
            onChange={this.onChangeCategory} />
        </div>
      </div>
    </>
  )

  renderAddSuggestSkillButton = () => {
    const {showSuggestSkillForm} = this.state;
    return (showSuggestSkillForm ?
      <Button
        id="suggestSkillButton"
        type="submit"
        className="sendButton"
        disabled={isEmpty(this.state.suggestedSkillName) || this.state.isAddingSkill}
        onClick={this.toggleSuccessMessageModal}>
        {this.state.isAddingSkill ? 'Suggesting...': 'Suggest a Skill'}
      </Button> :
      <Button
        id="addSkillsButton"
        type="submit"
        className="sendButton"
        disabled={isEmpty(this.state.selectedCustomSkills) || this.state.isAddingSkill}
        onClick={this.toggleSuccessMessageModal}>
        {this.state.isAddingSkill ? 'Adding...': 'Add Skills'}
      </Button>);
  }

  render() {
    const {
      isOpen,
      toggleModal,
      isRequesting,
      defaultSkills,
      defaultSkillsPaginationData
    } = this.props;

    const {
      filtered,
      searchSubmitClicked,
      showSuggestSkillForm,
      showCustomSuggestions,
      hasMoreSearchResults,
      selectedCustomSkills,
      initialSkillsRequest
    } = this.state;

    const modalHeaderTitle = showSuggestSkillForm ? 'Suggest a Skill' : ADD_SKILLS_HEADER;

    return (
      <div>
        <Modal
          centered
          className="customLevelUpModal modal-lg"
          innerRef="skillsModal"
          isOpen={isOpen}
          toggle={toggleModal}>
          <div className="modal-header">
            <span className="modal-title">{modalHeaderTitle}</span>
            <div
              onClick={toggleModal}
              className="close"
              data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">
                <img src={CloseIcon} />
              </span>
            </div>
          </div>
          <ModalBody>
            {!showSuggestSkillForm && this.renderSkillListHeader()}

            {initialSkillsRequest && <Loading />}

            {
              ((searchSubmitClicked && !showSuggestSkillForm) && (!initialSkillsRequest) && (!showCustomSuggestions)) &&
                <SearchYourSkills
                  searchedResult={filtered}
                  setSuggestSkillForm={this.setSuggestSkillForm}
                  handleSubmitSearchSkill={this.handleSubmitSearchSkill}
                  renderCustomSkillsList={this.renderCustomSkillsList}
                  setHasMoreSearchResults={this.setHasMoreSearchResults}
                  hasMoreSearchResults={hasMoreSearchResults}
                  isSkillsRequesting={this.state.isSkillsRequesting}
                  callAPI={this.callAPI}
                  isRequesting={isRequesting}
                  skills={defaultSkills}
                  selectedCustomSkills={selectedCustomSkills}
                  selectCustomSkills={this.selectCustomSkills}
                  removeCustomSkills={this.removeCustomSkills}
                  initialSkillsRequest={initialSkillsRequest}
                  defaultSkillsPaginationData={defaultSkillsPaginationData}/>
            }

            {
              (showCustomSuggestions && !isEmpty(this.state?.filtered) ) &&
                (<div>
                  <h5 className="font14">Skill suggestions</h5>
                  <div className="custom-scrollbar">
                    {
                      isEmpty(this.state?.filtered) && !this.state.isSkillsRequesting
                        ? <p className="font14 text-secondary text-center">All 25 default skills are added</p>
                        : <div className={`${this.state.isSkillsRequesting ? 'd-block' : 'skill-suggestions'}`}>
                          {this.renderCustomSkillsList()}
                        </div>
                    }
                  </div>
                </div>
                )}
            {isEmpty(this.state?.filtered) && this.state.isSkillsRequesting && <div>
              <h5 className="font14">Skill suggestions</h5>
              <div className="custom-scrollbar"><Loading /></div>
            </div>}
          </ModalBody>
          <ModalFooter className="addCustomSkillFooter">
            <div className="d-flex justify-content-between w-100 align-items-center">
              <div className="pointer d-flex align-items-center px-0 nav-link" />
              {this.renderAddSuggestSkillButton()}
            </div>
          </ModalFooter>
        </Modal>
        <SuccessMessage
          toggleModal={this.props.toggleModal}
          isOpen={this.state.successMessage}
          successMessageText={this.state.successMessageText}
          modalHeaderTitle={modalHeaderTitle}/>
      </div>
    );
  }
}

AddYourCustomSkills.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  getDefaultSkills: PropTypes.func,
  defaultSkills: PropTypes.array,
  actions: PropTypes.object,
  refreshMySkills: PropTypes.func,
  addedSkillsCount: PropTypes.number,
  categories: PropTypes.array.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  subCategories: PropTypes.array.isRequired,
  categoriesPageCount: PropTypes.number.isRequired,
  subCategoriesPageCount: PropTypes.number.isRequired,
  defaultSkillsPaginationData: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  const { auth: { data: { currentUser: { id: userId } } } } = state;

  return {
    userId,
    defaultSkillsPaginationData: state.myskills.defaultSkillsPaginationData,
    defaultSkills: state.myskills.data.defaultSkills,
    isRequesting: state.myskills.isRequesting,
    categories: selectFormattedCategories(state),
    categoriesPageCount: selectCategoriesPageCount(state),
    subCategories: selectFormattedSubCategories(state),
    subCategoriesPageCount: selectSubCategoriesPageCount(state)
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getDefaultSkills,
      resetDefaultSkills,
      updateMyCareerPreppedStatus,
      getMyCareerPreppedStatus,
      getSkillCategories,
      getSkillSubCategories,
      getSkillsStatistics
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddYourCustomSkills);
