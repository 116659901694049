import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const PrimaryAdminCell = ({ organization }) => {
  if (organization.adminName === null) {
    return (
      <div>None Designated</div>
    );
  }

  return (
    <div>
      <Link
        title={`Link to ${organization.adminName}'s email`}
        to={`mailto:${organization.email}`}>
        {organization.adminName}
      </Link>
    </div>
  );
};

PrimaryAdminCell.propTypes = {
  organization: PropTypes.object.isRequired
};

export default PrimaryAdminCell;
