import { CALL_API } from '../../middlewares/api';

import {
  WEBPAGE_DETAILS_REQUEST,
  WEBPAGE_DETAILS_SUCCESS,
  WEBPAGE_DETAILS_FAILURE,
  RESET_WEBPAGE_DETAILS
} from '../../constants';

export const clearWebpageData = () => {
  return { type: RESET_WEBPAGE_DETAILS };
};

export const webpageDetailsRequest = (userId, isAuth) => {
  const endpoint = `user/user/${userId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: isAuth,
      types: [
        WEBPAGE_DETAILS_REQUEST,
        WEBPAGE_DETAILS_SUCCESS,
        WEBPAGE_DETAILS_FAILURE,
      ],
    },
  };
};
