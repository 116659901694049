import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import SkillBadges from '../skill-badges/SkillBadges';

class SkillBadgeWrapper extends PureComponent {
  static propTypes = {
    profile: PropTypes.object.isRequired,
  }

  constructor(props, context) {
    super(props, context);
  }

  render() {
    const { profile } = this.props;

    return (
      <div>
        <Helmet title="Skill Badges"/>

        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="content badges">
                <SkillBadges profile={profile}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  const { profile } = state;

  return {
    profile: profile.data
  };
};

export default connect(mapStateToProps, null)(SkillBadgeWrapper);
