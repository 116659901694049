import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { checkHttp } from '../../../utils';
import CpHtmlParser from '../../common/CpHtmlParser';

const styles = {
  editButton: {
    marginLeft: 10,
    cursor: 'pointer',
  }
};

const AssociationCard = ({
  association,
  isPublicProfile,
  onEditAssociationClick
}) => {
  let endDate = 'Present';
  const startDate = moment(association.startdate.date).format('MMMM YYYY');

  if (! association.noend) {
    endDate = moment(association.enddate.date).format('MMMM YYYY');
  }

  const urlAvailable = association.url !== '';
  const urlString = checkHttp(association.url) ?
    association.url : `//${association.url}`;

  return (
    <div className="card-block">
      <div className="associations">
        <div className="row">
          <div className="col-lg-12">
            <div className="display">
              <div className="item">
                <div className="title">
                  {
                    urlAvailable ?
                      <a href={urlString} target="_blank" rel="noopener noreferrer">
                        {association.name}
                      </a>:
                      association.name
                  }

                  {!isPublicProfile &&
                    <i
                      style={styles.editButton}
                      onClick={onEditAssociationClick}
                      id={`update-association-form-${association.id}`}
                      className="fa fa-xs fa-pencil orange" aria-hidden="true"/>}

                </div>
                <div className="positions">
                  {association.position}
                </div>
                <div className="date">{startDate}&nbsp;-&nbsp;{endDate}</div>
                <div className="description">
                  <div className="ql-editor no-padding-in-quill">
                    <CpHtmlParser htmlString={association.description}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AssociationCard.propTypes = {
  association: PropTypes.object.isRequired,
  onEditAssociationClick: PropTypes.func.isRequired,
  isPublicProfile: PropTypes.bool.isRequired
};

export default AssociationCard;
