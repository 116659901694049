import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { requestFeedback } from '../../redux/actions/myskills';

import { get, isNull, isEmpty } from 'lodash';

import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

import UpdateDetails from './UpdateDetails';
import CloseIcon from '../../assets/images/close.svg';

class RequestIRs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      updateDetails:false,
    };

    this.handleToggle = this.handleToggle.bind(this);
    this.processSendRequest = this.processSendRequest.bind(this);
    this.toggleUpdateDetailsModal = this.toggleUpdateDetailsModal.bind(this);
  }

  onSend = () => {
    const { profile: { city, state, careerClusters } } = this.props;

    if (!isEmpty(careerClusters) && !isNull(state) && !isNull(city)) {
      this.processSendRequest();
      return;
    }

    this.setState({ updateDetails: !this.state.updateDetails });
  };

  processSendRequest() {
    const currentUser = this.props.currentUser;
    const reqObj = {
      request_for_user_id: currentUser.id,
      userType: 'industry-representative',
    };

    if (this.props.skill_id) {
      reqObj.skill_id = this.props.skill_id;
    }

    if (this.props.badge_id) {
      reqObj.badge_issued_id = this.props.data.issuedID;
    }

    this.props.actions.requestFeedback(reqObj).then(() => {
      toastr.success('Your feedback request was successfully sent');
      this.props.toggleModal('requestEducators');
      this.props.proceed('iRRequestSubmiited');
    });
  }

  handleToggle() {
    this.props.toggleModal('requestIRs');
  }

  toggleUpdateDetailsModal() {
    this.setState({ updateDetails: ! this.state.updateDetails });
    this.handleToggle();
  }

  render() {
    const { isOpen, profile } = this.props;

    return (
      <>
        <Modal
          centered
          className="customLevelUpModal"
          isOpen={isOpen}
          toggle={this.handleToggle}>
          <div className="modal-header">
            <span className="modal-title">
              Request Feedback from Industry Mentor
            </span>
            <div
              onClick={this.handleToggle}
              className="close"
              data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">
                {' '}
                <img src={CloseIcon} />{' '}
              </span>
            </div>
          </div>
          <ModalBody>
            <p
              style={{ color: '#55595C' }}>{'Industry Mentors are working professionals who’ve joined the CareerPrepped community to evaluate, rate, and offer feedback on how well they feel your evidence proves your claimed skill. Ensure your evidence is ready to be reviewed to make a positive impression.'}</p>
            <p
              style={{ color: '#55595C' }}>{'Are you sure you\'re ready to have your skill evidence reviewed?\n'}</p>
          </ModalBody>
          <ModalFooter className="bordertop-0">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={this.handleToggle}>
              Not yet
            </button>
            <Button className="ml-1 sendButton" onClick={this.onSend}>
              Yes, I'm ready
            </Button>
          </ModalFooter>
        </Modal>
        <UpdateDetails
          isOpen={this.state.updateDetails}
          toggleModal={this.toggleUpdateDetailsModal}
          proceed={this.props.proceed}
          profile={profile}
          processSendRequest={this.processSendRequest}
          clusterDetails={get(profile, 'clusterDetails', [])}
          isForCareerInterest={!(isEmpty(profile?.state) || isEmpty(profile?.city) || isEmpty(profile?.clusterDetails))}/>
      </>
    );
  }
}

RequestIRs.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  proceed: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  badge_id: PropTypes.string,
  skill_id: PropTypes.string,
  actions: PropTypes.object.isRequired,
  data: PropTypes.object,
  profile: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.data.currentUser,
    profile: state.profile?.data,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      requestFeedback,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestIRs);
