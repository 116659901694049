import PropTypes from 'prop-types';
import React, { Component } from 'react';
import EducationCard from '../../../components/profile/sections/EducationCard';

class EducationCardHandler extends Component {
  static propTypes = {
    education: PropTypes.object.isRequired,
    isPublicProfile: PropTypes.bool.isRequired,
    onEdit: PropTypes.func
  }

  constructor(props, context) {
    super(props, context);

    this.onEditEducationClick = this.onEditEducationClick.bind(this);
  }

  onEditEducationClick(event) {
    event.preventDefault();
    const { id } = this.props.education;
    this.props.onEdit(id);
  }


  render() {
    const { isPublicProfile, education } = this.props;

    return (
      <div className="profile-item__card-list">
        <EducationCard
          educationData={education}
          isPublicProfile={isPublicProfile}
          onEditEducationClick={this.onEditEducationClick}/>
      </div>
    );
  }
}

export default EducationCardHandler;
