import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  ButtonDropdown,
  DropdownToggle
} from 'reactstrap';
import ActionsCellMenu from './ActionsCellMenu';

import Validator from '../../../../validator';
import { isEmpty, isUndefined } from 'lodash';

import * as messagesActions from '../../../../redux/actions/messages';
import * as organizationUserActions from '../../../../redux/actions/organization-users';
import * as organizationActions from '../../../../redux/actions/organization-invitations';

class ActionsWrapper extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    isSubmitting: PropTypes.object,
    isSubmittingMessage: PropTypes.bool,
    organizationInvitations: PropTypes.object.isRequired,
    message: PropTypes.object,
    following: PropTypes.object,
    as: PropTypes.string.isRequired,
    placement: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
    isUserFollower: PropTypes.object,

    organization: PropTypes.object.isRequired,
    onEditOrganization: PropTypes.func.isRequired
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      tooltipOpen: false,
      errors: {},
      showMessageModal: false,
      message: {...props.message},
    };
  }

  onFollow = () => {
  }

  onToggleMessagingModal = () => {
    this.setState({
      showMessageModal: !this.state.showMessageModal
    });
  }

  onMessageChange = (event) => {
    const field = event.target.name;
    const { message } = this.state;

    message[field] = event.target.value;

    this.setState({ message }, () => this.isValid(field));
  }

  isValid(field = null) {
    const validate = Validator.createValidator({
      subject: ['required'],
      body: ['required']
    }, this.state.message, field);

    const { isValid, errors } = validate;

    let errorsObj = Object.assign({}, this.state.errors, errors);

    if (field && isUndefined(errors[field])) {
      delete errorsObj[field];
    }

    if (! field && isEmpty(errors)) {
      errorsObj = errors;
    }

    this.setState({ errors: errorsObj });

    return isValid;
  }

  onMessageSubmit = () => {
  }

  handleResponse = () => {
    const message = { recipients: [], subject: '', body: '' };

    this.setState({
      message,
    }, () => this.onToggleMessagingModal());
  }

  onToggleTooltip = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    return (
      <div>
        <ButtonDropdown
          isOpen={this.state.tooltipOpen}
          toggle={this.onToggleTooltip}>
          <DropdownToggle
            caret
            color="primary">
            Actions
          </DropdownToggle>
          {this.props.as === 'actionsCellMenu' &&
            <ActionsCellMenu
              onFollow={this.onFollow}
              onToggleMessagingModal={this.onToggleMessagingModal}
              isSubmitting={this.props.isSubmitting}
              isSubmittingMessage={this.props.isSubmittingMessage}
              following={this.props.following}

              organization={this.props.organization}
              onEditOrganization={this.props.onEditOrganization}/>}
        </ButtonDropdown>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    message: { recipients: [], subject: '', body: '' },
    following: state.following,
    isSubmitting: state.organizationUsers.isFollowing,
    isSubmittingMessage: state.messages.isSubmitting,
    organizationInvitations: state.organizationInvitations,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, messagesActions, organizationActions, organizationUserActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionsWrapper);
