import React, { Component } from 'react';

import classNames from 'classnames';

import resourceCenterContent from '../../constants/resource-center-content';

import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent
} from 'reactstrap';

class ResourceCenter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1'
    };

    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(activeTab) {
    return () => {
      if (this.state.activeTab !== activeTab) {
        this.setState({ activeTab });
      }
    };
  }

  render() {
    const { activeTab } = this.state;

    return (
      <div className="interior resource-center mt-3">
        <Container>
          <Row>
            <Col>
              <div className="groupName">Resource Center</div>
              <p>Resources to help you maximize the use of CareerPrepped.</p>
            </Col>
          </Row>
        </Container>

        <Container>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classNames({ active: activeTab === '1' })}
                onClick={this.toggleTab('1')}>
                Workbooks
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classNames({ active: activeTab === '2' })}
                onClick={this.toggleTab('2')}>
                Skill Challenges
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classNames({ active: activeTab === '3' })}
                onClick={this.toggleTab('3')}>
                Help Articles
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <div style={{marginTop: 20}}>
                    <div className="description">
                      <p>
                        Skill Builder workbooks are learning materials you can use as assigned "activity workbooks" for each Skill Builder. Workbooks guide users through activities that encourage self-reflection, self-assessment, and practice of each skill. They can be customized as Word or Google files before using as an assignment or downloaded as a fillable PDF. Have users submit their assignments as you normally request them.
                      </p>
                    </div>

                    <div className="list">
                      {resourceCenterContent.workbooks.map((workbook, index) =>
                        (<div key={index} className="list-item">
                          <div className="title">{workbook.title}</div>
                          {workbook.units.map((unit, index) =>
                            (<div key={index}>
                              <span className="unit-code">{unit.code}</span>
                              {unit.title}
                              <span className="download-links">
                                ({((unit.wordDocumentUrl === '') && (unit.pdfUrl === '') && (unit.googleDocUrl === '')) ?
                                  <i>Coming Soon</i> :
                                  <span>
                                    <a href={unit.wordDocumentUrl}>Word</a> | <a href={unit.googleDocUrl} target="_blank">Google Doc</a> | <a href={unit.pdfUrl} target="_blank">PDF</a>
                                  </span>})
                              </span>
                            </div>))}
                        </div>))}
                    </div>
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <div style={{marginTop: 20}}>
                    <div className="description">
                      <p>
                        Skill Challenges let users practice skills and create portfolio evidence they can store in their Portfolio and link to skill claims. Users can click <strong>"Accept Challenge"</strong> at the bottom of every Skill Builder to download the corresponding document. However, all Skill Challenge documents can be downloaded from here, customized as needed and used as assignments. If you notice any issues such as broken links, email <a href="mailto:careerprepped@maxknowledge.com">careerprepped@maxknowledge.com</a> to report the problem.
                      </p>
                    </div>

                    <div className="list">
                      {resourceCenterContent.skillChallenges.map((challenge, index) =>
                        (<div key={index} className="list-item">
                          <div className="title">{challenge.title} (<a href={challenge.googleDriveUrl} target="_blank">Google Drive</a>)</div>
                          {challenge.units.map((unit, index) =>
                            (<div key={index}>
                              <span className="unit-code">{unit.code}</span>
                              {unit.title}
                              <span className="download-links">
                                ({unit.files.map((file, index) =>
                                  (<span key={index}>
                                    <a href={file.url}>{file.title}</a>
                                    {`${(unit.files.length > 1) && (unit.files.length !== (index+1)) ? ' | ' : ''}`}
                                  </span>))})
                              </span>
                            </div>))}
                        </div>))}
                    </div>
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row>
                <Col sm="12">
                  <div style={{marginTop: 20}}>
                    <div className="description">
                      <p>
                        CareerPrepped has two separate support centers. The help center is for all users with help articles focused on core features and account settings. The institutional support center is specifically for admins with help articles focused on admin features and maximizing use of the platform with your users. The following are highlighted articles from both support centers which address common questions.
                      </p>
                    </div>

                    <div className="list">
                      {resourceCenterContent.helpArticles.map((item, index) =>
                        (<div key={index} className="list-item">
                          <div className="title">{item.title}</div>
                          <ul>
                            {item.articles.map((article, index) =>
                              (<li key={index}>
                                <a href={article.link} target="_blank">{article.title}</a>
                              </li>))}
                          </ul>
                        </div>))}
                    </div>
                  </div>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Container>
      </div>
    );
  }
}

export default ResourceCenter;
