import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import * as componentsActions from '../../../redux/actions/components';
import * as commentsActions from '../../../redux/actions/comments';
import * as discussionsActions from '../../../redux/actions/discussions';
import * as wallPostActions from '../../../redux/actions/wall-posts';
import * as wallPostCommentActions from '../../../redux/actions/wall-post-comments';

class ReplyActions extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    reply: PropTypes.object,
    forms: PropTypes.array,
    status: PropTypes.bool,
    searchPage: PropTypes.bool,
    dashboard: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      dropdownOpen: false
    };

    this.toggle = this.toggle.bind(this);
    this.onLoadEditReplyForm = this.onLoadEditReplyForm.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  toggle() {
    this.setState({ dropdownOpen: ! this.state.dropdownOpen });
  }

  onLoadEditReplyForm() {
    const { actions, reply } = this.props;
    actions.loadForm(`update-reply-form-${reply.id}`);
  }

  onDelete() {
    const { actions, reply, dashboard, searchPage } = this.props;

    const discussionId = dashboard ? reply.wallpost : reply.discussion;
    const actionsToCall = dashboard ? 'deleteWallPostReply': 'deleteUnitDiscussionReply';
    const onsuccessAction = dashboard ? 'decrementWallCommentCount':'decrementDiscussionCommentCount';

    actions[actionsToCall](reply.id)
      .then(() => {
        if(!searchPage)
          actions[onsuccessAction](discussionId, 1);
      });
  }

  render() {
    const { status } = this.props;

    return (
      <div className="btn-group edit-menu align-top float-right action-dropdowns">
        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle className="edit-menu-button">
            &#9679;&#9679;&#9679;
          </DropdownToggle>

          <DropdownMenu className="dropdown-item-left dropdown-menu">
            <a
              className="dropdown-item"
              onClick={this.onLoadEditReplyForm}>
              Edit reply
            </a>

            <a
              className="dropdown-item"
              onClick={this.onDelete}>
              {status ? 'Deleting...' : 'Delete reply'}
            </a>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    forms: state.components.forms,
    status: state.comments.isDeleting.status
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({},
    componentsActions,
    commentsActions,
    discussionsActions,
    wallPostCommentActions,
    wallPostActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReplyActions);
