import initialState from './initialState';

import {
  STATES_REQUEST,
  STATES_SUCCESS,
  STATES_FAILURE
} from '../../constants';

export default function reducer(state = initialState.states, action) {
  switch (action.type) {
  case STATES_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case STATES_SUCCESS:
    return Object.assign({}, state, {
      isRequesting: false,
      page: action.data.page,
      pageCount: action.data.page_count,
      data: action.data._embedded.tree
    });

  case STATES_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false
    });

  default:
    return state;
  }
}
