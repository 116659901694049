import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Validator from '../../validator';
import { loadForm, guid } from '../../utils';

import * as componentActions from '../../redux/actions/components';

import ClubSectionCard from '../../components/resume/sections/ClubSectionCard';
import ClubForm from '../../components/profile/forms/ClubForm';
import DeleteSectionItemConfirmModal from '../../components/resume/DeleteSectionItemConfirmModal';

class ResumeClubs extends Component {
  static propTypes = {
    clubs: PropTypes.object,
    onChangeSection: PropTypes.func.isRequired,
    onChangeSectionTitle: PropTypes.func.isRequired,
    onUnloadForm: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    resumeClubs: PropTypes.object.isRequired,
    onLoadForm: PropTypes.func.isRequired,
    forms: PropTypes.array,
    onRemoveResumeSection: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      editingId: null,
      removeClubId: null,
      clubs: Object.assign({}, this.props.clubs),
      editorText: {description: this.props.clubs.description}
    };

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onEditClub = this.onEditClub.bind(this);
    this.onRemoveClub = this.onRemoveClub.bind(this);
    this.onRichEditorChange = this.onRichEditorChange.bind(this);
    this.onCancelRemoveClub = this.onCancelRemoveClub.bind(this);
    this.onConfirmRemoveClub = this.onConfirmRemoveClub.bind(this);
  }

  isValid(field = null) {
    const rules = {
      name: ['required', 'minLength|6','maxLength|100'],
    };

    const { clubs, errors: prevErrors} = this.state;
    const validate = Validator.createValidator(rules, clubs, field);
    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  isValidDescription(field = null) {
    const rules = {
      description: ['maxWords|200']
    };
    const { editorText, errors: prevErrors } = this.state;
    const validate = Validator.createValidator(rules, editorText, field);
    const { errors, isValid } = validate;

    if (field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }
    this.setState({ errors: Object.assign({}, prevErrors, errors) });

    return isValid;
  }

  onChangeTitle(event){
    const { value } = event.target;
    this.props.onChangeSectionTitle(value, 'clubs');
  }

  handleChange(event){
    let { name, value } = event.target;
    const { clubs } = this.state;

    clubs[name] = value;

    this.setState({ clubs }, () => this.isValid(name));
  }

  onRichEditorChange(description, ...params) {
    let { clubs, editorText } = this.state;

    clubs = Object.assign({}, clubs, { description });
    editorText['description'] = params[2].getText().replace(/\r?\n|\r/gm, ' ');

    this.setState({ clubs, editorText }, () => this.isValidDescription('description'));
  }

  onSave(){
    const { clubs, editingId } = this.state;
    const { resumeClubs } = this.props;

    if (!this.isValid() || ! this.isValidDescription())
      return;

    const newClub = Object.assign({},{
      id: guid()
    }, clubs );

    const index = resumeClubs.clubsArray.findIndex(club => club.id === editingId);

    const clubsArray = editingId === null ? [
      ...resumeClubs.clubsArray,
      newClub
    ] : [
      ...resumeClubs.clubsArray.slice(0, index),
      newClub,
      ...resumeClubs.clubsArray.slice(index+1)
    ];

    const data = Object.assign({}, resumeClubs,{
      clubsArray
    });

    this.props.onChangeSection(data, 'clubs');
    this.onCancel();
  }

  onCancel(){
    const { unloadForm } = this.props.actions;
    this.clearFormField();
    unloadForm('editClubsForm');
  }

  clearFormField(){
    const clubs = {
      name: '',
      description: ''
    };

    this.setState({
      clubs,
      errors: {},
      editingId: null
    });
  }

  onEditClub(event) {
    const { id } = event.target;
    const { loadForm } = this.props.actions;
    const { clubsArray } = this.props.resumeClubs;
    const clubObject = clubsArray.find(club => club.id === id);
    const clubs = Object.assign({}, this.state.clubs, clubObject);
    this.setState({
      clubs,
      editingId: id
    }, ()=> loadForm('editClubsForm'));
  }

  onRemoveClub(event) {
    const { id: removeClubId } = event.target;

    this.setState({ removeClubId });
  }

  onConfirmRemoveClub() {
    const { removeClubId } = this.state;
    const { resumeClubs } = this.props;

    const clubsArray = resumeClubs
      .clubsArray
      .filter(club => club.id !== removeClubId);

    const data = Object.assign({}, resumeClubs, { clubsArray });

    this.props.onChangeSection(data, 'clubs');
    this.onCancelRemoveClub();
  }

  onCancelRemoveClub() {
    this.setState({ removeClubId: null });
  }

  render() {
    const { resumeClubs, onLoadForm, forms, onRemoveResumeSection } = this.props;
    const { clubs, errors, removeClubId } = this.state;

    const isFormOpen = loadForm(forms, 'editClubsForm');
    const isConfirmModalOpen = removeClubId !== null;

    return (
      <div className="resume-section create-resume-section-card">
        <div className="create-resume-title">
          <input
            type="text"
            value={resumeClubs.title}
            placeholder="Clubs header"
            className="header-edit-textbox"
            onChange={this.onChangeTitle}/>
          <button
            onClick={onRemoveResumeSection}
            id="Club"
            className="btn btn-danger btn-sm">
            <i className="fa fa-close"/>
          </button>
        </div>
        {
          !isFormOpen &&
            <div className="section-list-container">
              {resumeClubs.clubsArray.map(club =>
                (<ClubSectionCard
                  key={club.id}
                  clubsDetails={club}
                  onEditClub={this.onEditClub}
                  onRemoveClub={this.onRemoveClub}/>))}
              <button
                onClick={onLoadForm}
                data-form-name="editClubsForm"
                className="btn btn-primary w-100">
                Add Club
              </button>
            </div>
        }

        {isFormOpen &&
          <div className="resume-form-container">
            <ClubForm
              errors={errors}
              club={clubs}
              onSave={this.onSave}
              onCancel={this.onCancel}
              onChange={this.handleChange}
              onChangeDescription={this.onRichEditorChange}/>
          </div>}

        {isConfirmModalOpen &&
          <DeleteSectionItemConfirmModal
            onCancel={this.onCancelRemoveClub}
            onConfirm={this.onConfirmRemoveClub}
            isOpen={isConfirmModalOpen}/>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const clubs = {
    title: '',
    orgname: '',
    orgurl: '',
    description: '',
    startdate: '',
    enddate: '',
    noend: false
  };

  return {
    clubs,
    forms: state.components.forms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(componentActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResumeClubs);
