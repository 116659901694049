import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import SkillOptionsList from './SkillOptionsList';
import BadgeOptionsList from './BadgeOptionsList';

const SupportingEvidence = ({
  profile,
  skills,
  badges,
  isSkillsShown,
  toggleIsSkillsShown,
  isBadgesShown,
  selectedBadges,
  selectedSkills,
  toggleIsBadgesShown,
  toggleBadgeCheckbox,
  toggleSkillCheckbox,
  setSkillWithEvidence
}) => {
  const caretStyleSkills = classNames({
    'fa fa-caret-up': isSkillsShown,
    'fa fa-caret-down': !isSkillsShown
  });

  const caretStyleBadges = classNames({
    'fa fa-caret-up': isBadgesShown,
    'fa fa-caret-down': !isBadgesShown
  });

  return(
    <div>
      <div className="evidence__container">
        <p
          onClick={toggleIsBadgesShown}
          className="hoverIt evidence__title"
          style={{marginBottom: 0}}>
          My Skill Badges
          <i
            className={caretStyleBadges}
            aria-hidden="true"
            style={{ marginLeft: 5 }}/>
        </p>

        <BadgeOptionsList
          badges={badges}
          isBadgesShown={isBadgesShown}
          selectedBadges={selectedBadges}
          toggleBadgeCheckbox={toggleBadgeCheckbox}/>
      </div>
      <div className="evidence__container">
        <p onClick={toggleIsSkillsShown} className="hoverIt evidence__title">
          My Other Skills
          <i className={caretStyleSkills} aria-hidden="true" style={{ marginLeft: 5 }}/>
        </p>
        <SkillOptionsList
          isSkillsShown={isSkillsShown}
          skills={skills}
          profile={profile}
          selectedSkills={selectedSkills}
          toggleSkillCheckbox={toggleSkillCheckbox}
          setSkillWithEvidence={setSkillWithEvidence}/>
      </div>
    </div>
  );
};

SupportingEvidence.propTypes = {
  profile: PropTypes.object,
  skills: PropTypes.array.isRequired,
  badges: PropTypes.array.isRequired,
  isSkillsShown: PropTypes.bool,
  toggleIsSkillsShown: PropTypes.func,
  isBadgesShown: PropTypes.bool,
  selectedBadges: PropTypes.array.isRequired,
  selectedSkills: PropTypes.array.isRequired,
  toggleIsBadgesShown: PropTypes.func.isRequired,
  toggleBadgeCheckbox: PropTypes.func.isRequired,
  toggleSkillCheckbox: PropTypes.func.isRequired,
  setSkillWithEvidence: PropTypes.func.isRequired
};

export default SupportingEvidence;
