import { CALL_API } from '../../middlewares/api';
import {
  CERTIFICATIONS_REQUEST,
  CERTIFICATIONS_SUCCESS,
  CERTIFICATIONS_FAILURE,
  CREATE_CERTIFICATION_REQUEST,
  CREATE_CERTIFICATION_SUCCESS,
  CREATE_CERTIFICATION_FAILURE,
  UPDATE_CERTIFICATION_REQUEST,
  UPDATE_CERTIFICATION_SUCCESS,
  UPDATE_CERTIFICATION_FAILURE,
  DELETE_CERTIFICATION_REQUEST,
  DELETE_CERTIFICATION_SUCCESS,
  DELETE_CERTIFICATION_FAILURE,
} from '../../constants';

export const certificationRequest = (userId) => {
  const endpoint = `user/certification?user=${userId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [
        CERTIFICATIONS_REQUEST,
        CERTIFICATIONS_SUCCESS,
        CERTIFICATIONS_FAILURE,
      ],
    },
  };
};

export const saveCertification = (data) => {
  const endpoint = 'user/certification';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        CREATE_CERTIFICATION_REQUEST,
        CREATE_CERTIFICATION_SUCCESS,
        CREATE_CERTIFICATION_FAILURE,
      ],
    },
  };
};

export const updateCertification = (index, data, certificationId) => {
  const endpoint = `user/certification/${certificationId}`;

  return {
    [CALL_API]: {
      data,
      index,
      endpoint,
      method: 'put',
      authenticated: true,
      types: [
        UPDATE_CERTIFICATION_REQUEST,
        UPDATE_CERTIFICATION_SUCCESS,
        UPDATE_CERTIFICATION_FAILURE,
      ],
    },
  };
};

export const deleteCertification = (certificationId) => {
  const endpoint = `user/certification/${certificationId}`;

  return {
    [CALL_API]: {
      endpoint,
      id: certificationId,
      method: 'delete',
      authenticated: true,
      types: [
        DELETE_CERTIFICATION_REQUEST,
        DELETE_CERTIFICATION_SUCCESS,
        DELETE_CERTIFICATION_FAILURE,
      ],
    },
  };
};
