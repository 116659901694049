import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const FollowingButton = ({
  isAuthenticated,
  profile: { vanity, followingCount }
}) => {
  return (
    <Link
      to={isAuthenticated ? `/cp/${vanity}/following` : `/cp/${vanity}`}
      className="stat-block-item">
      <span className="stat-number">{followingCount}</span> Following
    </Link>
  );
};

FollowingButton.propTypes = {
  profile: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

export default FollowingButton;
