export const REMOVE = 'Remove';
export const REMOVE_CANCELED = 'Remove Canceled';
export const FEEDBACK_REQUEST = 'Feedback Request';
export const IGNORE_FEEDBACK_CONFIRMATION =
  'Are you sure you want to ignore this feedback request? The Requester will be notified that this feedback request was canceled.';
export const IGNORE = 'Ignore';
export const REMOVE_CONFIRMATION =
  'Are you sure you want to remove this request?';
export const RESEND_CONFIRMATION =
  'Are you sure you want to resend this request?';  
export const CANCEL_REQUEST_CONFIRMATION =
  'Are you sure you want to cancel this feedback request?';
export const NO_DONT = 'No, I don\'t';
export const I_DO = 'Yes, I do';
export const YES = 'Yes';
export const CANCEL = 'Cancel';
export const FEEDBACK_REQUEST_CANCEL_SUCCESS =
  'The feedback request was successfully canceled';
export const FEEDBACK_STATUS_MESSAGE = status =>
  `Your feedback request was successfully ${status}.`;
export const FEEDBACK_RESEND_SUCCESS = 'Your feedback request was successfully resent.';
export const FEEDBACK_REMOVED_SUCCESS =
  'Your feedback request was successfully removed.';
export const START_DATE_VALIDATION = 'Please select start date.';
export const DATE_RANGE_VALIDATION = '"To" date should be after “From” date.';
export const RESPOND = 'Respond';
export const GIVEN = 'Given';
export const RECEIVED = 'Received';
export const MY_REQUESTS = 'My Requests';
export const MANAGE_FEEDBACK_HEADER = `Manage feedback requests & feedback you've given and see praise
you've received for your feedback.`;
export const FEEDBACK_REQUESTS = 'Feedback Requests';
export const SHOW_MORE = 'Show More';
export const NO_RECORDS = 'Sorry, we couldn\'t find anything!';
export const NO_GIVEN_RECORDS = 'You currently have no requests for feedback';
export const FEEDBACK_GIVEN = 'Feedback Given';
export const VIEW_FEEDBACK_HEADER =
  'From here, see all the feedback you\'ve received from others on your skills and give praise for the feedback.';
export const FEEDBACK_RECEIVED = 'Feedback Received';
export const VIEW_REQUESTS_HEADER = 'From here, see the requests you\'ve made for feedback, when you made your requests, the status, and use actions to manage your requests.';
export const ALL = 'All';
export const SKILLS = 'Skills';
export const BADGE = 'Badge';
export const FROM = 'From';
export const TO = 'To';

export const FINDING_MATCHES = 'Finding Matches';
export const MATCHING = 'matching';
export const RESEND = 'Resend';

export const FEEDBACK_REQUEST_STATUS = {
  PENDING   : 'pending',
  COMPLETED : 'completed',
  CANCELED : 'canceled',
  IGNORED   : 'ignored',
  REMOVED   : 'removed',
};