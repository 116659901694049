import { CALL_API } from '../../middlewares/api';

import {
  PROJECTS_REQUEST,
  PROJECTS_SUCCESS,
  PROJECTS_FAILURE,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAILURE,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILURE,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
} from '../../constants';

export const projectsRequest = (userId) => {
  const endpoint = `user/projects?user=${userId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [PROJECTS_REQUEST, PROJECTS_SUCCESS, PROJECTS_FAILURE],
    },
  };
};

export const saveProject = (data) => {
  const endpoint = 'user/projects';
  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        CREATE_PROJECT_REQUEST,
        CREATE_PROJECT_SUCCESS,
        CREATE_PROJECT_FAILURE,
      ],
    },
  };
};

export const updateProject = (index, data) => {
  const endpoint = `user/projects/${data.id}`;

  return {
    [CALL_API]: {
      data,
      index,
      endpoint,
      method: 'put',
      authenticated: true,
      types: [
        UPDATE_PROJECT_REQUEST,
        UPDATE_PROJECT_SUCCESS,
        UPDATE_PROJECT_FAILURE,
      ],
    },
  };
};

export const deleteProject = (projectId) => {
  const endpoint = `user/projects/${projectId}`;

  return {
    [CALL_API]: {
      endpoint,
      id: projectId,
      method: 'delete',
      authenticated: true,
      types: [
        DELETE_PROJECT_REQUEST,
        DELETE_PROJECT_SUCCESS,
        DELETE_PROJECT_FAILURE,
      ],
    },
  };
};
