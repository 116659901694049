import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'reactstrap';

const WorkValuesExampleModal = ({isOpen, closeModal}) => {
  return (
    <Modal isOpen={isOpen} toggle={closeModal} size={'lg'}>
      <div className="see-examples-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title" id="myModalLabel">Examples of Work Values</h6>
            <button type="button" className="close" onClick={closeModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="exmples-list">
              <p><strong>Service:</strong> Contribute to the community and society</p>
              <p><strong>Public contact:</strong> Have lots of daily contact with people</p>
              <p><strong>Collaboration:</strong> Work with others as part of a team</p>
              <p><strong>Self-reliance:</strong> Have minimal contact with others at work</p> 
              <p><strong>Competition:</strong> Match your abilities against others</p>
              <p><strong>Power & authority:</strong> Control the activities of others, decide courses of action and policies</p>
              <p><strong>Pressure:</strong> Enjoy a deadline-driven setting</p>
              <p><strong>Influence:</strong> Change attitudes or opinions of others</p>
              <p><strong>Profit:</strong> Accrue large amounts of money or material gain</p>
              <p><strong>Knowledge:</strong> Pursue truth, and understanding in your work</p>
              <p><strong>Recognition:</strong> Do work recognized in a visible, public way</p>
              <p><strong>Artistic expression:</strong> Work creatively in art or related fields</p>
              <p><strong>Creativity:</strong> Create ideas, programs, and work processes</p>
              <p><strong>Moral fulfillment:</strong> Do work that contributes to your morals</p>
              <p><strong>Variety:</strong> Enjoy change and variety of tasks at work</p> 
              <p><strong>Stability:</strong> Enjoy routine, predictable tasks at work</p> 
              <p><strong>Structure:</strong> Predetermined and clearly defined tasks</p>
              <p><strong>Precision:</strong> Enjoy work with little room for error</p>
              <p><strong>Fast pace:</strong> Do work that must be done swiftly</p>
              <p><strong>Adventure:</strong> Do work that involves frequent risk-taking</p>
              <p><strong>Safety:</strong> Work that is low risk and unlikely to result in injury</p>
              <p><strong>Autonomy:</strong> Do work with minimal direction from others</p>
              <p><strong>Physical challenge:</strong> Do work with physical demands</p>
              <p><strong>Location:</strong> Work in a location that matches lifestyle</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

WorkValuesExampleModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func
};

export default WorkValuesExampleModal;
