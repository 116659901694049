import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ProjectCard from '../../../components/profile/sections/ProjectCard';

class ProjectCardHandler extends Component {
  static propTypes = {
    project: PropTypes.object.isRequired,
    isPublicProfile: PropTypes.bool.isRequired,
    onEdit: PropTypes.func
  }

  constructor(props, context) {
    super(props, context);

    this.onEditProjectClick = this.onEditProjectClick.bind(this);
  }

  onEditProjectClick(event) {
    event.preventDefault();
    const { id } = this.props.project;

    this.props.onEdit(id);
  }

  render() {
    const { project, isPublicProfile } = this.props;

    return (
      <div className="profile-item__card-list">
        <ProjectCard
          project={project}
          isPublicProfile={isPublicProfile}
          onEditProjectClick={this.onEditProjectClick}/>
      </div>
    );
  }
}

export default ProjectCardHandler;
