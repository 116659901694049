import PropTypes from 'prop-types';
import React from 'react';

import moment from 'moment';
import classNames from 'classnames';

const Note = ({
  note,
  onDelete,
  onEdit,
  isJobOwner,
  notesShowMore,
  onNotesShowMore
}) => {
  const { note: noteText, posted, id } = note;
  const noteIndex = notesShowMore.findIndex(note => note == id);

  const lengthLimit = noteText.length > 100;
  const showMoreCondtn = lengthLimit && noteIndex === -1;
  const noteTextToDisplay = showMoreCondtn ? `${noteText.slice(0,100)} ...` : noteText;

  const btnStyle = classNames('show-more-btn-job', {
    'hide': !lengthLimit,
  });

  return (
    <div>
      <p className="note-timestamp">{moment.utc(posted.date).fromNow()}
        {isJobOwner &&
          <span className="float-right">
            <button
              onClick={onEdit}
              className="btn-transparent notes-btns">
              <i className="fa fa-pencil" aria-hidden="true"/>
            </button>
            <button
              onClick={onDelete(id)}
              className="btn-transparent notes-btns">
              <i className="fa fa-times" aria-hidden="true"/>
            </button>
          </span>}
      </p>
      <div className="job-note-text">
        {noteTextToDisplay}
        {lengthLimit &&
          <span
            className={btnStyle}
            onClick={onNotesShowMore(id)}>
            {showMoreCondtn ? '... Read more': 'Read less'}
          </span>}
      </div>
    </div>
  );
};

Note.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  note: PropTypes.object.isRequired,
  isJobOwner: PropTypes.bool.isRequired,
  notesShowMore: PropTypes.array.isRequired,
  onNotesShowMore: PropTypes.func.isRequired
};

export default Note;
