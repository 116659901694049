import { isEmpty } from 'lodash';

import initialState from './initialState';

import {
  SET_SELECTED_CHILD_GROUP,
  GET_ADMIN_GROUP_REQUEST,
  GET_ADMIN_GROUP_SUCCESS,
  GET_ADMIN_GROUP_FAILURE,
  UPDATE_ADMIN_GROUP_REQUEST,
  UPDATE_ADMIN_GROUP_SUCCESS,
  UPDATE_ADMIN_GROUP_FAILURE,
  UPDATE_ADMIN_GROUP_ADD_ADMIN_REQUEST,
  UPDATE_ADMIN_GROUP_ADD_ADMIN_SUCCESS,
  UPDATE_ADMIN_GROUP_ADD_ADMIN_FAILURE,
  UPDATE_ADMIN_GROUP_REMOVE_ADMIN_REQUEST,
  UPDATE_ADMIN_GROUP_REMOVE_ADMIN_SUCCESS,
  UPDATE_ADMIN_GROUP_REMOVE_ADMIN_FAILURE,
  GET_INSTITUTION_GROUPS_REQUEST,
  GET_INSTITUTION_GROUPS_SUCCESS,
  GET_INSTITUTION_GROUPS_FAILURE,
  CREATE_ADMIN_GROUP_REQUEST,
  CREATE_ADMIN_GROUP_SUCCESS,
  CREATE_ADMIN_GROUP_FAILURE,
} from '../../constants';

const editGroup = (state, action) => {
  const { institutionGroups, isUpdating, selectedChildGroup } = state;

  if (!isEmpty(selectedChildGroup)) {
    return {
      ...state,
      selectedChildGroup: {
        ...selectedChildGroup,
        ...action.data,
      },
    };
  }

  const indexOfJob = institutionGroups.data.findIndex(
    item => item.id === isUpdating.id
  );

  return {
    ...state,
    institutionGroups: {
      ...state.institutionGroups,
      data: [
        ...institutionGroups.data.slice(0, indexOfJob),
        Object.assign({}, action.data, {
          label: action.data.name,
          value: action.data.id,
        }),
        ...institutionGroups.data.slice(indexOfJob + 1),
      ],
    },
  };
};

export default function reducer(state = initialState.adminGroups, action) {
  switch (action.type) {
  case SET_SELECTED_CHILD_GROUP:
    return Object.assign({}, state, {
      selectedChildGroup: action.group,
    });

  case GET_ADMIN_GROUP_REQUEST:
    return {
      ...state,
      isRequesting: true,
    };

  case GET_ADMIN_GROUP_SUCCESS:
    return {
      ...state,
      isRequesting: false,
      status: true,
      data: action.data,
    };

  case GET_ADMIN_GROUP_FAILURE:
    return {
      ...state,
      isRequesting: false,
    };

  case UPDATE_ADMIN_GROUP_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, id: action.id },
    });

  case UPDATE_ADMIN_GROUP_SUCCESS:
    return Object.assign({}, state, {
      ...editGroup(state, action),
      isUpdating: { status: false, id: null },
    });

  case UPDATE_ADMIN_GROUP_FAILURE:
    return Object.assign({}, state, {
      isUpdating: { status: false, id: null },
    });

  case UPDATE_ADMIN_GROUP_ADD_ADMIN_REQUEST:
    return {
      ...state,
      isUpdating: { status: true, id: action.id },
    };

  case UPDATE_ADMIN_GROUP_ADD_ADMIN_SUCCESS:
    return Object.assign({}, state, {
      isUpdating: { status: false, id: null },
    });

  case UPDATE_ADMIN_GROUP_ADD_ADMIN_FAILURE:
    return {
      ...state,
      error: action.error,
      isUpdating: { status: false, id: null },
    };

  case UPDATE_ADMIN_GROUP_REMOVE_ADMIN_REQUEST:
    return {
      ...state,
      isUpdating: { status: true, id: action.id },
    };

  case UPDATE_ADMIN_GROUP_REMOVE_ADMIN_SUCCESS:
    return Object.assign({}, state, {
      data: {
        ...state.data,
        groupAdmins: [
          ...state.data.groupAdmins.filter(
            admin => admin.id !== state.isUpdating.id
          ),
        ],
      },
      isUpdating: { status: false, id: null },
    });

  case UPDATE_ADMIN_GROUP_REMOVE_ADMIN_FAILURE:
    return {
      ...state,
      error: action.error,
      isUpdating: { status: false, id: null },
    };

  case GET_INSTITUTION_GROUPS_REQUEST:
    return {
      ...state,
      institutionGroups: {
        ...state.institutionGroups,
        isRequesting: true,
      },
    };

  case GET_INSTITUTION_GROUPS_SUCCESS: {
    return {
      ...state,
      institutionGroups: {
        ...state.institutionGroups,
        data: action.data._embedded.group.map((group) => {
          return {
            ...group,
            label: group.name,
            value: group.name,
          };
        }),
        isRequesting: false,
      },
    };
  }

  case GET_INSTITUTION_GROUPS_FAILURE:
    return {
      ...state,
      institutionGroups: {
        ...state.institutionGroups,
        error: action.error,
        isRequesting: false,
      },
    };

  case CREATE_ADMIN_GROUP_REQUEST:
    return {
      ...state,
      isSubmitting: true,
    };

  case CREATE_ADMIN_GROUP_SUCCESS: {
    return {
      ...state,
      institutionGroups: {
        ...state.institutionGroups,
        data: [
          ...state.institutionGroups.data,
          ...action.data.groups.map((group) => {
            return {
              ...group,
              label: group.name,
              value: group.name,
            };
          }),
        ],
      },
      isSubmitting: false,
    };
  }

  case CREATE_ADMIN_GROUP_FAILURE:
    return {
      ...state,
      error: action.error,
      isSubmitting: false,
    };

  default:
    return state;
  }
}
