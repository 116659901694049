import { CALL_API } from '../../middlewares/api';

import {
  USER_SOCIAL_LINKS_REQUEST,
  USER_SOCIAL_LINKS_SUCCESS,
  USER_SOCIAL_LINKS_FAILURE,
  ADD_SOCIAL_LINKS_REQUEST,
  ADD_SOCIAL_LINKS_SUCCESS,
  ADD_SOCIAL_LINKS_FAILURE,
  UPDATE_SOCIAL_LINKS_REQUEST,
  UPDATE_SOCIAL_LINKS_SUCCESS,
  UPDATE_SOCIAL_LINKS_FAILURE,
  DELETE_SOCIAL_LINKS_REQUEST,
  DELETE_SOCIAL_LINKS_SUCCESS,
  DELETE_SOCIAL_LINKS_FAILURE,
  RESET_SOCIAL_LINKS_STATE,
} from '../../constants';

export const resetSocialLinksState = () => {
  return { type: RESET_SOCIAL_LINKS_STATE };
};

export const userSocialLinksRequest = (userId) => {
  const endpoint = `user/networks?user=${userId}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [
        USER_SOCIAL_LINKS_REQUEST,
        USER_SOCIAL_LINKS_SUCCESS,
        USER_SOCIAL_LINKS_FAILURE,
      ],
    },
  };
};

export const addSocialLink = (data) => {
  const endpoint = 'user/networks';

  return {
    [CALL_API]: {
      endpoint,
      data,
      method: 'post',
      authenticated: true,
      types: [
        ADD_SOCIAL_LINKS_REQUEST,
        ADD_SOCIAL_LINKS_SUCCESS,
        ADD_SOCIAL_LINKS_FAILURE,
      ],
    },
  };
};

export const updateSocialLink = (linkId, data) => {
  const endpoint = `user/networks/${linkId}`;

  return {
    [CALL_API]: {
      endpoint,
      data,
      id: linkId,
      method: 'patch',
      authenticated: true,
      types: [
        UPDATE_SOCIAL_LINKS_REQUEST,
        UPDATE_SOCIAL_LINKS_SUCCESS,
        UPDATE_SOCIAL_LINKS_FAILURE,
      ],
    },
  };
};

export const deleteSocialLink = (linkId, data) => {
  const endpoint = `user/networks/${linkId}`;

  return {
    [CALL_API]: {
      endpoint,
      data,
      id: linkId,
      method: 'delete',
      authenticated: true,
      types: [
        DELETE_SOCIAL_LINKS_REQUEST,
        DELETE_SOCIAL_LINKS_SUCCESS,
        DELETE_SOCIAL_LINKS_FAILURE,
      ],
    },
  };
};
