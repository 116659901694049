import React from 'react';

import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

const UserGroupsListModal = ({ isOpen, onClose, user }) => {
  return (
    <Modal
      size="md"
      className="modal-margin-top"
      backdrop="static"
      isOpen={isOpen}
      toggle={onClose}>
      <ModalHeader toggle={onClose}>
        {`${user.name}'s Group(s)`}
      </ModalHeader>
      <ModalBody>
        <ul className="mb-0">
          {user.groups.map(group => <li key={group.id}>{group.name}</li>)}
        </ul>
      </ModalBody>
      <ModalFooter>
        <button
          onClick={onClose}
          className="btn btn-secondary float-left">
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
};

UserGroupsListModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default UserGroupsListModal;
