import React from 'react';
import PropTypes from 'prop-types';

import { loadForm } from '../../utils';

import { Tooltip } from 'reactstrap';
import Timestamp from '../skill-builders/discussions/Timestamp';
import WallCommentActions from '../../containers/dashboard/WallCommentActions';
import WallLikeButton from '../../containers/dashboard/WallLikeButton';
import WallCommentFormHandler from '../../containers/dashboard/WallCommentFormHandler';
import CommentItem from '../common/CommentItem';
import DiscussionAvatar from '../skill-builders/discussions/DiscussionAvatar';
import ReplyButton from '../../containers/skill-builders/discussions/ReplyButton';
import Reply from '../skill-builders/discussions/Reply';
import ReplyFormHandler from '../../containers/skill-builders/discussions/ReplyFormHandler';

const WallCommentCard = ({
  profile,
  commentData,
  loadForms,
  commentShowMore,
  onCommentShowMore,
  wallPostId,
  replies,
  onClickDelete,
  currentUser,
  searchPage,
  isMobile,
  isUserLikesTooltipOpen,
  toggleUserLikesTooltip,
  toggleUserLikesListModal
}) => {
  let component;
  const { user } = commentData;
  const form = `update-wall-post-comment-form-${commentData.id}`;
  const isForm = loadForm(loadForms, form);

  if (isForm) {
    component = (
      <div>
        <WallCommentFormHandler
          isForm={isForm}
          form={form}
          wallPostId={wallPostId}
          wallPostComment={commentData}/>
        <div className="secondary-post" style={{borderTop: 'none'}}>
          <Reply replies={replies} dashboard/>

          <ReplyFormHandler
            wallPostId={wallPostId}
            commentId={commentData.id}/>
        </div>
      </div>
    );
  } else {
    component = (
      <div className="secondary-post">
        <DiscussionAvatar user={user}/>
        <div className="right">
          {((currentUser.id === user.id) || profile.role === 'super-admin') &&
            <WallCommentActions
              wallPostCommentId={commentData.id}
              onClickDelete={onClickDelete}/>}

          <div className="profile-title">
            <CommentItem
              wallPost={commentData}
              showMore={commentShowMore}
              onshowMore={onCommentShowMore}
              secondary
              isUserLikesTooltipOpen={isUserLikesTooltipOpen}
              toggleUserLikesTooltip={toggleUserLikesTooltip}/>
            <div className="share-stats">
              <WallLikeButton
                searchPage={searchPage}
                comment={commentData}
                wallPostId={wallPostId}/>

              <ReplyButton commentId={commentData.id}/>

              <span
                id={`tooltip-${commentData.id}`}
                className="likes"
                style={{paddingRight:5}}
                onClick={toggleUserLikesListModal}>
                {commentData.likes} Likes
              </span>

              {(commentData.likesList.length > 0) && (! isMobile) &&
                <Tooltip
                  placement="bottom"
                  isOpen={isUserLikesTooltipOpen}
                  target={`tooltip-${commentData.id}`}
                  toggle={toggleUserLikesTooltip}>
                  <div className="inside-tooltip" style={{fontSize: 12}}>
                    <ul
                      style={{padding: '10px',
                        textAlign: 'left',
                        marginBottom: 0,
                        listStyle: 'none'}}>
                      {
                        commentData.likesList.map((user, index) =>
                          <li key={index}>{user.name}</li>)
                      }
                    </ul>
                  </div>
                </Tooltip>}

              <span style={{paddingRight:5}}>
                <Timestamp postedAt={commentData.posted.date}/>
              </span>
            </div>
          </div>
        </div>
        <Reply
          dashboard
          searchPage={searchPage}
          replies={replies}/>

        <ReplyFormHandler
          searchPage={searchPage}
          wallPostId={wallPostId}
          commentId={commentData.id}/>
      </div>
    );
  }
  return (
    <div>
      {component}
    </div>
  );
};

WallCommentCard.propTypes = {
  loadForms: PropTypes.array.isRequired,
  wallPostId: PropTypes.string.isRequired,
  replies: PropTypes.array,
  commentData: PropTypes.object.isRequired,
  onCommentShowMore: PropTypes.func.isRequired,
  commentShowMore: PropTypes.object.isRequired,
  onClickDelete: PropTypes.func,
  searchPage: PropTypes.bool,
  currentUser: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
  isUserLikesTooltipOpen: PropTypes.bool.isRequired,
  toggleUserLikesTooltip: PropTypes.func.isRequired,
  toggleUserLikesListModal: PropTypes.func.isRequired
};

export default WallCommentCard;
