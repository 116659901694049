import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextArea from '../../components/common/TextArea';
import Validator from '../../validator';
import * as jobNotesActions from '../../redux/actions/job-notes';
import * as componentsActions from '../../redux/actions/components';

class JobNotesEditForm extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    note: PropTypes.object.isRequired,
    isUpdating: PropTypes.object.isRequired
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      note: this.props.note
    };

    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  isValid(field = null) {
    const validate = Validator.createValidator({
      text: ['required'],
    }, this.state.note, field);

    const { isValid, errors } = validate;

    this.setState({ errors });

    return isValid;
  }

  handleResponse() {
    const { noteId } = this.state.note;

    if (noteId)
      this.props.actions
        .unloadForm(noteId);
  }

  onSave(event){
    event.preventDefault();

    const { id, text: note, jobId } = this.state.note;
    const { actions } = this.props;

    if (!this.isValid()) return ;

    const data = {
      job:jobId,
      note: note
    };

    actions.editNote(id,data)
      .then(() => this.handleResponse());
  }

  onChange(event){
    const field = event.target.name;
    const { note } = this.state;

    note[field] = event.target.value;

    this.setState({ note });

    this.isValid();
  }

  onCancel(){
    const { noteId } = this.state.note;

    this.props.actions
      .unloadForm(noteId);
  }

  render() {
    const { note, errors } = this.state;
    const { isUpdating } = this.props;

    return (
      <div>
        <form>
          <div className="form-group">
            <TextArea
              className="form-control input-styles-owht"
              name="text"
              autoFocus
              rows="6"
              cols=""
              error={errors.text}
              onChange={this.onChange}
              value={note.text} />
          </div>
          <div className="form-group text-right">
            <button
              type="button"
              className="btn btn-secondary"
              name="button"
              onClick={this.onCancel}>
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary ml-2"
              name="button"
              onClick={this.onSave}>
              {isUpdating.status ? 'Updating ...' : 'Update'}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let note = { text: '' };

  if (ownProps.note) {
    note = Object.assign(
      {},
      note,
      ownProps.note
    );
  }

  return {
    note,
    isUpdating: state.jobNotes.isUpdating
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, jobNotesActions, componentsActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobNotesEditForm);
