import React from 'react';
import PropTypes from 'prop-types';

import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import CircleStar from '../svg/CircleStar';
import GroupAdminListModal from './GroupAdminListModal';

const GroupChildRow = ({
  group,
  childGroup,
  isOpenGroupAction,
  toggleGroupActions,
  onUpdateGroupCode,
  onUpdateGroup,
  isGroupAdminListModalOpen,
  toggleGroupAdminListModal
}) => {
  return (
    <tr className="group-sub-item">
      <td className="table-col-accordion"><span className="table__accordion-btn">&nbsp;</span></td>
      <td className="table-col-group-name">
        <div className="table-group__name">
          <span
            className="img-responsive initial-avatar group">
            {childGroup.name.slice(0, 1)}
          </span>
          <span style={{ flex: 1 }}>
            {childGroup.name}
          </span>
        </div>
      </td>
      <td className="table-col-group-admins">
        <div className="table-group__admins-list">
          <div className="table-group__admins">
            {childGroup.owner} <CircleStar />{childGroup.groupAdmins.length !== 0 ? `, ${childGroup.groupAdmins[0].name}` : ''}{childGroup.groupAdmins.length > 1 ? ', ' : ''}{childGroup.groupAdmins.length > 1 ? <a href="#" onClick={toggleGroupAdminListModal(childGroup.id)}>+{childGroup.groupAdmins.length - 1} more</a> : ''}
          </div>
        </div>
      </td>
      <td className="table-col-members">
        <div className="table-group__members text-center">
          {childGroup.groupUsersCount}
        </div>
      </td>
      <td className="table-col-group-code">{childGroup.inviteCode}</td>
      <td className="table-col-actions">
        <ButtonDropdown
          isOpen={(childGroup.id === isOpenGroupAction)}
          toggle={toggleGroupActions(childGroup.id)}>
          <DropdownToggle caret color="primary">
            Actions
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={onUpdateGroup(childGroup, group)}>
              Edit Name
            </DropdownItem>
            <DropdownItem onClick={onUpdateGroupCode(childGroup, group)}>
              Update Group Code
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      </td>
      {(childGroup.id === isGroupAdminListModalOpen) &&
        <GroupAdminListModal
          isOpen={childGroup.id === isGroupAdminListModalOpen}
          onClose={toggleGroupAdminListModal(childGroup.id)}
          group={childGroup}/>}
    </tr>
  );
};

GroupChildRow.propTypes = {
  group: PropTypes.object.isRequired,
  childGroup: PropTypes.object.isRequired,
  isOpenGroupAction: PropTypes.string.isRequired,
  toggleGroupActions: PropTypes.func.isRequired,
  onUpdateGroupCode: PropTypes.func.isRequired,
  onUpdateGroup: PropTypes.func.isRequired,
  toggleGroupAdminListModal: PropTypes.func.isRequired,
  isGroupAdminListModalOpen: PropTypes.string.isRequired
};

export default GroupChildRow;
