import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import DashboardPostForm from '../../components/dashboard/DashboardPostForm';
import * as componentsActions from '../../redux/actions/components';
import * as wallPostsActions from '../../redux/actions/wall-posts';

const wallPost = { post: '', attachment: {}, permissions: '1' };

class DashboardPostEdit extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    wallPost: PropTypes.object.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    wallId: PropTypes.string
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      postVisibilityDropdown: false,
      imagePreviewUrl: '',
      wallPost: this.setInitialWallPost(props.wallPost),
      plainText: '',
      isCancelModalOpen: false
    };

    this.onCancel = this.onCancel.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onSelectVisibilty = this.onSelectVisibilty.bind(this);
    this.onToggleCancelModal = this.onToggleCancelModal.bind(this);
    this.onCancelConfirm = this.onCancelConfirm.bind(this);
    this.togglePostVisibilityDropdown = this.togglePostVisibilityDropdown.bind(this);
    this.handleHTMLChange = this.handleHTMLChange.bind(this);
    this.onDropFile = this.onDropFile.bind(this);
    this.onRemoveAttachment = this.onRemoveAttachment.bind(this);
  }

  componentDidMount() {
    window.onbeforeunload = () => {
      return 'Changes you made might get lost!';
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = () => {
      return null;
    };
  }

  setInitialWallPost(wallPostInProps){
    if(wallPostInProps){
      return {
        post: wallPostInProps.post,
        attachment: wallPostInProps.attachment,
        permissions: wallPostInProps.permissions
      };
    }

    return wallPost;
  }

  onDropFile(files) {
    const { wallPost } = this.state;

    if(files[0].size > 8000000)
      return toastr.info('Sorry, this image exceeds the 8MB limit.');

    wallPost['attachment'] = files[0];

    this.setState({
      wallPost,
      imagePreviewUrl: files[0].preview
    });
  }

  onRemoveAttachment(){
    const { wallPost } = this.state;

    wallPost['attachment'] = {};

    this.setState({
      wallPost
    });
  }

  onCancelConfirm(){
    const { actions, wallId } = this.props;
    const form = `update-wall-post-form-${wallId}`;

    actions.unloadForm(form);
  }

  onCancel(){
    const { wallPost } = this.state;
    const { post, permissions } = this.props.wallPost;
    const { post: statePost, permissions: statePermissions } = wallPost;

    if(post !== statePost || permissions !== statePermissions){
      return this.onToggleCancelModal();
    }

    this.onCancelConfirm();
  }

  togglePostVisibilityDropdown(){
    this.setState({
      postVisibilityDropdown: !this.state.postVisibilityDropdown
    });
  }

  onToggleCancelModal(){
    this.setState({
      isCancelModalOpen: !this.state.isCancelModalOpen
    });
  }

  onSelectVisibilty(id) {
    return ()=> {
      
      const wallPostObject = { ...this.state.wallPost, permissions:id};
  
      this.setState({
        plainText:this.state.wallPost.post,
        wallPost:wallPostObject,
        postVisibilityDropdown:false
      });
    };
  }

  onUpdate(event) {
    event.preventDefault();

    const { wallPost: wallPostState, plainText } = this.state;
    const { actions, wallId } = this.props;

    if(plainText === '' && isEmpty(wallPostState.attachment)){
      return this.props.actions.openModal('emptyPostModal');
    }

    let formData = new FormData();
    formData.append('post', plainText === '' ? '<br>' : wallPostState.post);
    formData.append('attachment', !isEmpty(wallPostState.attachment) ? wallPostState.attachment : '');
    formData.append('permissions', wallPostState.permissions);

    actions.updateWallPost(formData, wallId)
      .then(() => this.handleResponse());
  }

  handleResponse() {
    const { wallId } = this.props;

    this.props.actions.unloadForm(`update-wall-post-form-${wallId}`);
  }

  handleHTMLChange(content, delta, source, editor){
    let { wallPost } = this.state;

    wallPost.post = content;
    this.setState({
      wallPost,
      plainText:  editor.getText(content).trim()
    });
  }

  render() {
    const {
      wallPost,
      imagePreviewUrl: previewUrl,
      postVisibilityDropdown,
      isCancelModalOpen
    } = this.state;

    const { attachment } = wallPost;
    const { isUpdating } = this.props;
    const imagePreviewUrl = isObject(attachment) ? previewUrl : attachment;

    return (
      <div>
        <DashboardPostForm
          handleHTMLChange={this.handleHTMLChange}
          onSave={this.onUpdate}
          wallPost={wallPost}
          onSelectVisibilty={this.onSelectVisibilty}
          postVisibilityDropdown={postVisibilityDropdown}
          togglePostVisibilityDropdown={this.togglePostVisibilityDropdown}
          isSubmitting={isUpdating}
          imagePreviewUrl={imagePreviewUrl}
          onCancel={this.onRemoveAttachment}
          onCancelEdit={this.onCancel}
          onDropFile={this.onDropFile}/>
        <Modal
          backdrop="static"
          className="modal-margin-top"
          isOpen={isCancelModalOpen}
          toggle={this.onToggleCancelModal}>
          <ModalHeader
            className="no-border"
            toggle={this.onToggleCancelModal}>
            Discard you changes
          </ModalHeader>
          <ModalBody>
            Are you sure you want to discard your changes?
          </ModalBody>
          <ModalFooter className="no-border">
            <button
              onClick={this.onToggleCancelModal}
              className="btn btn-secondary">
              No
            </button>

            <button
              onClick={this.onCancelConfirm}
              className="btn btn-primary">
              Discard Changes
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {


  const { wallPosts, components } = state;
  const { forms } = components;
  const { isUpdating } = wallPosts;

  return {
    loadForms: forms,
    isUpdating: isUpdating.status
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, componentsActions, wallPostsActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPostEdit);
