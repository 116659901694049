import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { get, isUndefined } from 'lodash';

import { BASE_URL, USER_LOGOUT, CLEAR_USER_REDUCERS } from '../constants';

export function callApi(data, method, endpoint, authenticated) {
  const url = BASE_URL + endpoint;
  const token = localStorage.getItem('careerPrepped.auth.token') || null;
  let config = { method, url, responseType: 'json' };

  if (authenticated) {
    if (!token) {
      return Promise.resolve('USER_LOGOUT');
    }

    config.headers = { Authorization: `Bearer ${token}` };
  }

  if (data) {
    config.data = data;
  }

  return axios(config)
    .then((response) => {
      const newToken = get(response, 'data.access_token');

      if (!isUndefined(newToken)) {
        localStorage.setItem('careerPrepped.auth.token', newToken);
      }

      return response;
    })
    .catch((errorResponse) => {
      let { response: error } = errorResponse;

      if (isUndefined(error)) {
        error = errorResponse;
      }

      const { status } = error;
      let errorMessage = [];

      const errors = get(error, 'data.errors');
      const detail = get(error, 'data.detail');
      const errorType = get(error, 'data.errorType');

      if (status === 401) {
        const [, path, subpath] = location.pathname.split('/');
        const unAuthDomains = [
          'skill-builders',
          'essentials',
          'discussions',
          'cp',
          'auth',
        ];
        const inSkillBadgesNotLoggedIn = path === 'skill-badges' && subpath;
        const inPortfolioNotLoggedIn = path === 'portfolio' && subpath;

        if (
          !unAuthDomains.includes(path) &&
          !inSkillBadgesNotLoggedIn &&
          !inPortfolioNotLoggedIn
        ) {
          if (!isUndefined(detail)) {
            toastr.error('', detail);
          }

          return Promise.resolve('USER_LOGOUT');
        }

        return Promise.reject('AUTH_REQUIRED');
      }

      if (!isUndefined(errors)) {
        errorMessage = Object.keys(errors).map(key => errors[key][0]);
      }

      if (isUndefined(errors) && !isUndefined(detail)) {
        errorMessage = [detail];
      }

      if (isUndefined(errorType) && errorMessage.length !== 0) {
        toastr.error('', errorMessage.join('<br/>'));
      }

      return error;
    });
}

export const CALL_API = Symbol('Call API');

export default store => next => (action) => {
  const callAPI = action[CALL_API];

  if (typeof callAPI === 'undefined') return next(action);

  let {
    data,
    method,
    endpoint,
    types,
    authenticated,
    id,
    isInitialRequest,
    query,
    index,
    userId,
    profile,
    requestData = {}
  } = callAPI;

  const [requestType, successType, errorType] = types;
  let requestAction = { type: requestType };

  if (typeof id !== 'undefined') requestAction.id = id;

  if (typeof query !== 'undefined') requestAction.query = query;

  if (typeof userId !== 'undefined') requestAction.userId = userId;

  if (typeof index !== 'undefined') requestAction.index = index;

  if (typeof profile !== 'undefined') requestAction.profile = profile;

  if (typeof isInitialRequest !== 'undefined')
    requestAction.isInitialRequest = isInitialRequest;

  next(requestAction);

  return callApi(data, method, endpoint, authenticated).then(
    (response) => {
      if (response === 'USER_LOGOUT') {
        localStorage.removeItem('careerPrepped.auth.token');
        localStorage.removeItem('IRConfigBar');

        const [, path, subpath, thirdPath] = location.pathname.split('/');
        const unAuthDomains = [
          'skill-builders',
          'essentials',
          'discussions',
          'cp',
          'auth',
        ];
        const inSkillBadgesNotLoggedIn = path === 'skill-badges' && subpath;
        const inPortfolioNotLoggedIn = path === 'portfolio' && subpath;

        if (
          (unAuthDomains.includes(path) ||
            inSkillBadgesNotLoggedIn ||
            inPortfolioNotLoggedIn) &&
          !(path === 'cp' && thirdPath)
        ) {
          return next({ type: CLEAR_USER_REDUCERS });
        }

        if (requestType !== 'LOGIN_REQUEST') {
          next({ type: USER_LOGOUT });
        }

        if (requestType === 'LOGIN_REQUEST') {
          return next({ type: errorType });
        }
      }

      if (response instanceof Error) {
        next({ error: response, type: errorType });

        toastr.error(response.message, 'Please check your internet connection and try again.');

        return Promise.reject(response.message);
      }

      let { status, data } = response;

      if (status >= 300) {
        next({ error: data, type: errorType });

        return Promise.reject(data);
      }

      next({ data, type: successType, requestData });

      return Promise.resolve(store.getState());
    },
    (error) => {
      if (error === 'AUTH_REQUIRED') {
        next({ type: USER_LOGOUT });
      }

      next({ error, type: errorType });

      return Promise.reject(error);
    }
  );
};
