import PropTypes from 'prop-types';
import React from 'react';

import Button from '../../common/Button';
import TextInput from '../../common/TextInput';

import Select from 'react-select';
import Creatable from 'react-select/creatable/dist/react-select.esm';

import tempMajors from './major-list';
import { checkIfEnterHit } from '../../../utils';
import classNames from 'classnames';

import _isObject from 'lodash/isObject';

const tempPrograms = [
  { label: 'Certificate/Diploma', value: 'Certificate/Diploma' },
  { label: 'Associate\'s Degree', value: 'Associate\'s Degree' },
  { label: 'Bachelor\'s Degree', value: 'Bachelor\'s Degree' },
  { label: 'Master\'s Degree', value: 'Master\'s Degree' },
  { label: 'J.D.', value: 'J.D.' },
  { label: 'Ed.D.', value: 'Ed.D.' },
  { label: 'M.D.', value: 'M.D.' },
  { label: 'Ph.D.', value: 'Ph.D.' },
  { label: 'Doctorate Degree', value: 'Doctorate Degree' },
  { label: 'Coursework (program not completed)', value: 'Coursework (program not completed)' }
];

const EducationForm = ({
  errors,
  educationData,
  onChange,
  onChangeMajor,
  onChangeProgram,
  onSave,
  isDeleting,
  onCancel,
  onDelete,
  isUpdating,
  isSubmitting,
  showDelete
}) => {
  const { program, major } = educationData;

  const programValue = typeof program === 'object' ? program :
    program === '' ? null :
      { label: program, value: program };

  const majorValue = typeof major === 'object' ? major :
    major === '' ? null :
      { label: major, value: major };

  const institutionLabel = (
    <span>
      Institution:
    </span>
  );

  const replacer = (match,p1) => {
    return p1;
  };
  const formattedGPA = educationData.gpa.replace(/(\d)(0+$)/, replacer);

  if (_isObject(educationData.startdate)) {
    educationData.startdate = educationData.startdate.date.replace(' 00:00:00.000000', '');
  }

  if (_isObject(educationData.enddate)) {
    educationData.enddate = educationData.enddate.date.replace(' 00:00:00.000000', '');
  }
  const endDateNote = educationData.noend ? '' : 'Required';

  const hasProgramError = errors && errors.program && errors.program.length > 0;
  const styles = {
    hasError: {
      borderColor: hasProgramError ? '#d9534f' : ''
    },

    errorFeedback: {
      color: '#d9534f',
      marginTop: '.25rem'
    },

    errorLabel: {
      color: hasProgramError ? '#d9534f' : ''
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <TextInput
            name="institution"
            label={institutionLabel}
            note="Required"
            placeholder="Enter your institution's name"
            error={errors.institution}
            value={educationData.institution}
            onChange={onChange}/>
        </div>
        <div className="col-lg-6">
          <div style={{marginBottom: '1rem'}}>
            <label className="form-control-label" style={styles.errorLabel}>Program:</label>
            <span className={classNames(
              'note',
              'pl-1',
              'Required',
              {required: hasProgramError})}>(Required)</span>
            <Select
              name="program"
              placeholder="Please select a program"
              onChange={onChangeProgram}
              value={programValue}
              options={tempPrograms}
              style={styles.hasError}/>
            {hasProgramError && <div style={styles.errorFeedback}>{errors.program}</div>}
          </div>
          <TextInput
            name="insurl"
            label="Institution URL:"
            placeholder="Enter your institution's URL"
            error={errors.insurl}
            value={educationData.insurl}
            onChange={onChange}/>
        </div>
        <div className="col-lg-6">
          <label className="form-control-label">Major:</label>
          <Creatable
            name="major"
            placeholder="Select or type in your major"
            onChange={onChangeMajor}
            value={majorValue}
            className={'form-group'}
            backspaceRemoves
            shouldKeyDownEventCreateNewOption={checkIfEnterHit}
            options={tempMajors}/>
          <TextInput
            name="gpa"
            type="text"
            label="GPA:"
            placeholder="Enter your GPA"
            error={errors.gpa}
            value={formattedGPA}
            onChange={onChange}/>
        </div>
        <div className="col-lg-12">
          <label className="form-control-label" htmlFor="">
            Dates Attended: <span className="note">(or expected graduation date)</span>
          </label>
        </div>
        <div className="col-lg-6 date">
          <TextInput
            type="date"
            name="startdate"
            label="Start Date: "
            note="Required"
            placeholder="Start Date - YYYY/MM/DD"
            error={errors.startdate}
            value={educationData.startdate}
            onChange={onChange}/>
        </div>
        <div className="col-lg-6 date">
          <TextInput
            type="date"
            name="enddate"
            label="End Date: "
            note={endDateNote}
            error={errors.enddate}
            value={educationData.enddate}
            disabled={educationData.noend}
            onChange={onChange}/>
        </div>
        <div className="col-lg-12">
          <div className="form-check mb-3">
            <input
              name="noend"
              checked={educationData.noend}
              className="form-check-input"
              type="checkbox"
              onChange={onChange}/>
            <label className="form-check-label">Check if expected date</label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="action-bar text-right">
            {showDelete &&
              <Button
                type="submit"
                onClick={onDelete}
                styles={{marginLeft: 0}}
                disabled={isDeleting}
                buttonClass="btn-danger"
                buttonText={isDeleting ? 'Deleting...' : 'Delete'}/>}

            <Button
              type="button"
              onClick={onCancel}
              buttonClass="btn-secondary"
              buttonText="Cancel"/>

            <Button
              type="submit"
              onClick={onSave}
              disabled={isSubmitting || isUpdating}
              buttonClass="btn-primary"
              buttonText={isSubmitting || isUpdating ? 'Saving...' : 'Save'}/>
          </div>
        </div>
      </div>
    </div>
  );
};

EducationForm.propTypes = {
  onDelete: PropTypes.func,
  isUpdating: PropTypes.bool,
  isDeleting: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeMajor: PropTypes.func.isRequired,
  onChangeProgram: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  educationData: PropTypes.object.isRequired,
  showDelete: PropTypes.bool
};

export default EducationForm;
