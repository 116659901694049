import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { capitalize } from 'lodash';
import { parseUrlString } from '../../../utils';

import VideoIframe from '../../common/VideoIframe';
import ImagePost from '../../common/ImagePost';
import ImageRenderer from '../../common/ImageRenderer';
import TextArea from '../../common/TextArea';
import Timestamp from '../../skill-builders/discussions/Timestamp';
import Button from '../../common/Button';
import Linkify from 'react-linkify';
import Parser from 'html-react-parser';
import { Dropdown, DropdownToggle, DropdownMenu, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const visibilityOptionsArray = [
  {
    title:'Everyone (Public)',
    value:'1'
  },
  {
    title:'Followers Only',
    value:'2'
  },
  {
    title:'Only Me',
    value:'0'
  },
];

const ShareModalCard = ({
  quote,
  permissions,
  discussionShare,
  onPost,
  onChange,
  isSubmitting,
  closeModal,
  showMore,
  onShowMore,
  onSelectVisibilty,
  hidePermissionsButton,
  onToggleVisibilityDropdown,
  visibilityDropdownOpen
}) => {
  const {
    post: postString,
    attachment: attachmentString,
    sharedEntityType,
    sharedBadgeIssued,
    sharedFile,
    sharedInterviewResource,
    user: userObject,
    posted,
    sharedSkillBuilderReflections
  } = discussionShare;

  let sharedPost = '';

  if ((sharedEntityType === 'wall') || (sharedEntityType === 'discussion')) {
    const sharedEntityKey = `shared${capitalize(sharedEntityType)}`;

    sharedPost = discussionShare[sharedEntityKey];
  }

  const user = (sharedPost !== '') ? sharedPost.user : userObject;
  const post = (sharedPost !== '') ? sharedPost.post : postString;
  const attachment = (sharedPost !== '') ? sharedPost.attachment : attachmentString;

  const parsedUrlString = parseUrlString(post);
  const minHeight = parsedUrlString != null ? 250 : 0;
  const isOpen = Object.keys(discussionShare).length > 0;
  const showMoreCondition = post.length > 250;
  const lessComponentCondition = showMoreCondition && !showMore;

  if(sharedEntityType === 'file' && sharedFile && sharedFile.visibility !== 'public'){
    return(
      <Modal
        backdrop="static"
        isOpen={isOpen}
        toggle={closeModal}>
        <ModalBody>
          <button type="button" className="close" onClick={closeModal}>
            <span aria-hidden="true">&times;</span>
          </button>
          <p>This file can't be shared because the owner has restricted its visibility. You can see it because you meet the owner's visibility requirements.</p>
        </ModalBody>
      </Modal>
    );
  }

  let shareBody = null;

  const dropdownButton = visibilityOptionsArray.find(option =>
    option.value == permissions);

  switch(sharedEntityType){

  case 'interviewResource':{
    const { title, thumbnail } = sharedInterviewResource;

    shareBody = (
      <div className="shared-item">
        <div className="shared-item-container">
          <div className="shared-item-image">
            <img src={thumbnail}/>
          </div>
          <div className="shared-item-description">
            <h6>{title}</h6>
            <div className="description">
              <p>Click to see details for this Interview Prep Video.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
    break;
  case 'badgeIssued':{
    const { badgeUrl, badge: badgeObject } = sharedBadgeIssued;
    const name = badgeObject ? badgeObject.name : '';
    shareBody = (
      <div className="shared-item">
        <div className="shared-item-container">
          <div className="shared-item-image">
            <img src={badgeUrl}/>
          </div>
          <div className="shared-item-description">
            <h6>{name}</h6>
            <div className="description">
              <p>Check out my {name} Skill Badge!</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
    break;

  case 'file':{
    const { url, title, mimetype } = sharedFile;
    let thumbnail = url;
    const docFile = mimetype.includes('application') || mimetype.includes('text');
    const parsedString = parseUrlString(url);
    const isVideoFile = parsedString && parsedString.type === 'iframe';
    shareBody = (
      <div className="shared-item">
        <div className={classNames('shared-item-container',{
          'video-file': isVideoFile
        })}>
          {
            docFile ?
              <div className="shared-item-image">
                <div className="file-icon-container shared-file-display">
                  <i className="fa fa-file-text-o"/>
                </div>
              </div>
              :
              isVideoFile ?
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    src={parsedString.url}
                    allowFullScreen
                    style={{'overflow': 'hidden'}}
                    scrolling="no"
                    className="embed-responsive-item"/>
                </div>:
                <div className="shared-item-image">
                  <img src={thumbnail}/>
                </div>
          }
          <div className="shared-item-description">
            <h6>{title}</h6>
            <div className="description">
              <p>Click to see details for this Portfolio File.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
    break;

  case 'skillBuilderReflections':{
    shareBody = (
      <div className="shared-item">
        <div className="shared-item-container">
          <div className="shared-item-description reflection-sharing">
            <p>I just answered the reflection question(s) below from <span style={{color: '#ff5000'}}>{sharedSkillBuilderReflections[0].unit.title}</span></p>
            <hr/>
            <div className="shared-reflection-contents">
              {
                sharedSkillBuilderReflections.map((reflection, index) => {
                  return (
                    <div key={index}>
                      <strong>{reflection.question}</strong><br/>
                      {reflection.answer}{index + 1 < sharedSkillBuilderReflections.length && <span><br/><br/></span>}
                    </div>);
                })
              }
            </div>
          </div>
        </div>
      </div>);
  }
    break;

  default:{
    shareBody = (
      <div className="post">
        <div className="share-post primary-post">
          <div className="left">
            <span>
              {
                user.avatar && user.avatar.length !== 0 ?
                  <img
                    className="header-image medium align-top"
                    src={user.avatar}
                    alt="User avatar"/>:
                  <span
                    className="header-image medium align-top initial-avatar">
                    {user.name.slice(0,1)}
                  </span>
              }
            </span>
          </div>
          <div className="right">
            <div className="profile-title">
              <span className="profile-name" style={{fontWeight: '500'}}>{user.name}</span>
              <Timestamp postedAt={posted.date} visibilty={permissions}/>
            </div>
            <div className="post-content" style={{ whiteSpace: 'normal' }}>
              {
                lessComponentCondition ?
                  <Linkify>{Parser(post.slice(0, 250))}</Linkify>
                  :
                  <Linkify>{Parser(post)}</Linkify>
              }

              {
                showMoreCondition &&
                  <span
                    onClick={onShowMore}
                    className="show-more-btn">
                    {lessComponentCondition ? '... Show more' : 'Show less'}
                  </span>
              }
            </div>

            {(parsedUrlString && parsedUrlString.type === 'iframe')&& <VideoIframe url={parsedUrlString.url}/>}

            {(parsedUrlString && parsedUrlString.type === 'image')&& <ImagePost url={parsedUrlString.url}/>}

            {attachment &&
              <ImageRenderer
                attachment={attachment}
                secondary={discussionShare.secondary}/>}
          </div>
        </div>
      </div>
    );
  }
    break;
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      toggle={closeModal}>
      <ModalHeader toggle={closeModal}>Share Post</ModalHeader>
      <ModalBody>
        <div style={{minHeight}}>
          <div>
            <TextArea
              name="sharedQuotedText"
              type="text"
              rows="3"
              value={quote}
              onChange={onChange}
              placeholder="Say something about this post"/>
          </div>
          <div className="seek-endorsement-content">
            {shareBody}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          disabled={isSubmitting}
          onClick={closeModal}
          buttonClass="btn-secondary"
          styles={{ marginLeft: 0 }}
          buttonText="Cancel"/>
        {(! hidePermissionsButton) &&
          <Dropdown
            isOpen={visibilityDropdownOpen}
            toggle={onToggleVisibilityDropdown}>
            <DropdownToggle
              caret
              tag="button"
              type="button"
              className="btn btn-tertiary">
              {dropdownButton == null ? 'Who can see this post' : dropdownButton.title}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu">
              {visibilityOptionsArray.map((option,index) => {
                return (
                  <a className="dropdown-item" key={index} onClick={onSelectVisibilty(option.value)}>
                    {option.title}
                  </a>
                );
              })}
            </DropdownMenu>
          </Dropdown>
        }
        <Button
          type="submit"
          disabled={isSubmitting}
          onClick={onPost}
          buttonClass="btn-primary"
          styles={{ marginLeft: 0 }}
          buttonText={isSubmitting ? 'Sharing...' : 'Share'}/>
      </ModalFooter>
    </Modal>
  );
};

ShareModalCard.propTypes = {
  onPost: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  permissions: PropTypes.string.isRequired,
  discussionShare: PropTypes.object.isRequired,
  quote: PropTypes.string.isRequired,
  showMore: PropTypes.bool,
  onShowMore: PropTypes.func,
  onSelectVisibilty: PropTypes.func,
  onToggleVisibilityDropdown: PropTypes.func,
  visibilityDropdownOpen: PropTypes.bool,
  hidePermissionsButton: PropTypes.bool
};

export default ShareModalCard;
