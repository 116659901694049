import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as filesActions from '../../redux/actions/files';
import * as componentsActions from '../../redux/actions/components';
import * as wallPostsActions from '../../redux/actions/wall-posts';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { BASE_URL } from '../../constants';

class FileCardActions extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    fileItem: PropTypes.object.isRequired,
    onEdit: PropTypes.func,
    hideEdit: PropTypes.bool,
    isAuthenticated: PropTypes.bool.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      dropdownOpen: false
    };

    this.toggle = this.toggle.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  toggle() {
    this.setState({ dropdownOpen: ! this.state.dropdownOpen });
  }

  onEdit() {
    this.props.onEdit();
    this.toggle();
  }

  onDelete(){
    const { id } = this.props.fileItem;
    this.props.actions.openModal(`delete-portfolio-modal-${id}`);
    this.toggle();
  }

  render() {
    const downloadUrl = `${BASE_URL}file/portfolio-downloads/${this.props.fileItem.id}`;

    return (
      <div>
        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle className="edit-menu-button">
            &#9679;&#9679;&#9679;
          </DropdownToggle>
          <DropdownMenu className="dropdown-item-left dropdown-menu dropdown-menu-right">
            {! this.props.hideEdit && <a
              className="dropdown-item portfolio-action-item"
              onClick={this.onEdit}> Edit <i className="fa fa-pencil" aria-hidden="true"/></a>}

            {this.props.fileItem.mimetype &&
              <a
                className="dropdown-item portfolio-action-item"
                href={downloadUrl}> Download <i className="fa fa-download" aria-hidden="true"/>
              </a>}
            <a className="dropdown-item portfolio-action-item" onClick={this.onDelete}> Delete <i className="fa fa-trash" aria-hidden="true"/></a>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, filesActions, componentsActions, wallPostsActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

const mapStateToProps = state => ({ isAuthenticated: state.auth.isAuthenticated});


export default connect(mapStateToProps, mapDispatchToProps)(FileCardActions);
