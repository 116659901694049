import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ModalTabNav from './ModalTabNav';
import ModalDropDownNav from './ModalDropDownNav';
import ModalTabContent from './ModalTabContent';
import EXAMPLES from './exampleData.js';
import { Modal, ModalHeader, ModalBody, ModalFooter, TabContent } from 'reactstrap';

class ExampleModal extends Component {
  static propTypes = {
    exampleType: PropTypes.string.isRequired,
    buttonMargin: PropTypes.string
  }

  constructor(props) {
    super(props);

    const buttonClass = this.props.buttonMargin ?
      `vertical-align btn btn-tertiary mb-3 ${this.props.buttonMargin}` :
      'vertical-align btn btn-tertiary mb-3 mt-1';

    this.state = {
      activeTab: '1',
      dropDownOpen: false,
      showExample: false,
      buttonClass: buttonClass,
      xSmall: window.innerWidth <= 594
    };

    this.showExamples = this.showExamples.bind(this);
    this.selectedTab = this.state.activeTab;
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab.target.id) {
      this.setState({
        activeTab: tab.target.id
      });
    }
  }

  toggleDropDown = () => {
    this.setState((prevState) => {
      return {
        dropDownOpen: !prevState.dropDownOpen
      };
    });
  };

  showExamples() {
    this.setState((prevState) => {
      return {
        showExample: !prevState.showExample
      };
    });
  }

  getExampleBase = (objType) => {
    switch (objType) {
    case 'Experience':
      return EXAMPLES['experience'];
    case 'Summary':
      return EXAMPLES['summary'];
    case 'Resume Experience':
      return EXAMPLES['Resume Experience'];
    case 'Resume Summary':
      return EXAMPLES['Resume Summary'];
    case 'Resume Skills':
      return EXAMPLES['Resume Skills'];
    case 'Resume Certifications':
      return EXAMPLES['Resume Certifications'];
    case 'Project':
      return EXAMPLES['projects'];
    case 'Honors & Awards':
      return EXAMPLES['Honors & Awards'];
    case 'Associations':
      return EXAMPLES['associations'];
    case 'Activities & Clubs':
      return EXAMPLES['Activities & Clubs'];
    case 'Headlines':
      return EXAMPLES['headlines'];
    }
  }

  exampleBase = this.getExampleBase(this.props.exampleType);

  exampleBodies = this.exampleBase.map((example) => {
    return <ModalTabContent key={example.tabId} example={example} exampleType={this.props.exampleType} />;
  });

  render () {
    const exampleTypeText = this.props.exampleType === 'Summary' ?
      'About Me Section' :
      this.props.exampleType;

    return (
      <div>
        <button
          onClick={this.showExamples}
          className={this.state.buttonClass}>
          Click to show {exampleTypeText} Examples
        </button>
        <Modal
          isOpen={this.state.showExample}
          toggle={this.showExamples}
          backdrop={'static'}
          autoFocus={false}
          keyboard={false}
          size="lg">
          <ModalHeader
            toggle={this.showExamples}>
            {exampleTypeText} Examples
          </ModalHeader>
          <ModalBody>
            {this.state.xSmall ?
              <ModalDropDownNav
                toggleTab={this.toggle}
                activeTab={this.state.activeTab}
                exampleBase={this.exampleBase}
                dropDownOpen={this.state.dropDownOpen}
                toggleDropDown={this.toggleDropDown} />
              :
              <ModalTabNav
                toggleTab={this.toggle}
                activeTab={this.state.activeTab}
                exampleBase={this.exampleBase} />
            }
            <TabContent activeTab={this.state.activeTab}>
              {this.exampleBodies}
            </TabContent>
          </ModalBody>
          <ModalFooter>
            <button
              onClick={this.showExamples}
              className="btn btn-primary">
              Close Examples
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

}

export default ExampleModal;
