import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AwardCard from '../../../components/profile/sections/AwardCard';

class AwardCardHandler extends Component {
  static propTypes = {
    award: PropTypes.object.isRequired,
    isPublicProfile: PropTypes.bool.isRequired,
    onEdit: PropTypes.func,
  }

  constructor(props, context) {
    super(props, context);

    this.onEditAwardClick = this.onEditAwardClick.bind(this);
  }

  onEditAwardClick(event) {
    event.preventDefault();
    const { id } = this.props.award;
    this.props.onEdit(id);
  }

  render() {
    const { award, isPublicProfile } = this.props;

    return (
      <div className="profile-item__card-list">
        <AwardCard
          award={award}
          isPublicProfile={isPublicProfile}
          onEditAwardClick={this.onEditAwardClick}/>
      </div>
    );
  }
}

export default AwardCardHandler;
