import { CALL_API } from '../../middlewares/api';

import {
  INVITE_DETAILS_REQUEST,
  INVITE_DETAILS_SUCCESS,
  INVITE_DETAILS_FAILURE,
  ORGANIZATION_INVITATION_REQUEST,
  ORGANIZATION_INVITATION_SUCCESS,
  ORGANIZATION_INVITATION_FAILURE,
  ORGANIZATION_INVITATION_FILTERED_REQUEST,
  ORGANIZATION_INVITATION_FILTERED_SUCCESS,
  ORGANIZATION_INVITATION_FILTERED_FAILURE,
  ORGANIZATION_GROUP_INVITATION_FILTERED_REQUEST,
  ORGANIZATION_GROUP_INVITATION_FILTERED_SUCCESS,
  ORGANIZATION_GROUP_INVITATION_FILTERED_FAILURE,
  INVITE_USERS_TO_ORG_REQUEST,
  INVITE_USERS_TO_ORG_SUCCESS,
  INVITE_USERS_TO_ORG_FAILURE,
  RESEND_INVITE_TO_ORG_REQUEST,
  RESEND_INVITE_TO_ORG_SUCCESS,
  RESEND_INVITE_TO_ORG_FAILURE,
  JOIN_CP_REQUEST,
  JOIN_CP_SUCCESS,
  JOIN_CP_FAILURE,
  REVOKE_INVITE_TO_ORG_REQUEST,
  REVOKE_INVITE_TO_ORG_SUCCESS,
  REVOKE_INVITE_TO_ORG_FAILURE,
  DECLINE_INVITE_REQUEST,
  DECLINE_INVITE_SUCCESS,
  DECLINE_INVITE_FAILURE,
  ONCHANGE_INVITATION_SEARCH_QUERY,
} from '../../constants';

export const getInvitations = (urlParamString) => {
  const endpoint = `institution/invitation?${urlParamString}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        ORGANIZATION_INVITATION_REQUEST,
        ORGANIZATION_INVITATION_SUCCESS,
        ORGANIZATION_INVITATION_FAILURE,
      ],
    },
  };
};

export const getAllInvitationsFiltered = (institutionId, queryParam) => {
  let endpoint = `institution/invitation?institution=${institutionId}&${queryParam}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        ORGANIZATION_INVITATION_FILTERED_REQUEST,
        ORGANIZATION_INVITATION_FILTERED_SUCCESS,
        ORGANIZATION_INVITATION_FILTERED_FAILURE,
      ],
    },
  };
};

export const getGroupInvitationsFiltered = (groupId, queryParam) => {
  const endpoint = `institution/invitation?group=${groupId}&${queryParam}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        ORGANIZATION_GROUP_INVITATION_FILTERED_REQUEST,
        ORGANIZATION_GROUP_INVITATION_FILTERED_SUCCESS,
        ORGANIZATION_GROUP_INVITATION_FILTERED_FAILURE,
      ],
    },
  };
};

export const getInviteDetails = (invitationToken) => {
  const endpoint = `institution/invitation/${invitationToken}`;

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      types: [
        INVITE_DETAILS_REQUEST,
        INVITE_DETAILS_SUCCESS,
        INVITE_DETAILS_FAILURE,
      ],
    },
  };
};

export const inviteUsers = (data) => {
  const endpoint = 'institution/invitation';

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [
        INVITE_USERS_TO_ORG_REQUEST,
        INVITE_USERS_TO_ORG_SUCCESS,
        INVITE_USERS_TO_ORG_FAILURE,
      ],
    },
  };
};

export const resendInvitation = (data, id) => {
  const endpoint = `institution/invitation/${id}`;

  return {
    [CALL_API]: {
      data,
      id,
      endpoint,
      method: 'patch',
      authenticated: true,
      types: [
        RESEND_INVITE_TO_ORG_REQUEST,
        RESEND_INVITE_TO_ORG_SUCCESS,
        RESEND_INVITE_TO_ORG_FAILURE,
      ],
    },
  };
};

export const revokeInvitation = (data, inviteId) => {
  const endpoint = `institution/invitation/${inviteId}`;

  return {
    [CALL_API]: {
      data,
      endpoint,
      id: inviteId,
      method: 'patch',
      authenticated: true,
      types: [
        REVOKE_INVITE_TO_ORG_REQUEST,
        REVOKE_INVITE_TO_ORG_SUCCESS,
        REVOKE_INVITE_TO_ORG_FAILURE,
      ],
    },
  };
};

export const joinCP = (data, isAuthenticated) => {
  const endpoint = 'institution/signup';

  let apiProps = {
    data,
    endpoint,
    method: 'post',
    types: [JOIN_CP_REQUEST, JOIN_CP_SUCCESS, JOIN_CP_FAILURE],
  };

  if (isAuthenticated) {
    apiProps = Object.assign({}, apiProps, { authenticated: isAuthenticated });
  }

  return {
    [CALL_API]: apiProps,
  };
};

export const declineInvitation = (data, inviteId) => {
  const endpoint = `institution/signup/${inviteId}`;

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'patch',
      types: [
        DECLINE_INVITE_REQUEST,
        DECLINE_INVITE_SUCCESS,
        DECLINE_INVITE_FAILURE,
      ],
    },
  };
};

export const onChangeInvitationsSearchQuery = (searchQuery) => {
  return dispatch =>
    dispatch({ type: ONCHANGE_INVITATION_SEARCH_QUERY, searchQuery });
};
