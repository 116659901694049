import PropTypes from 'prop-types';
import React from 'react';

import Creatable from 'react-select/creatable/dist/react-select.esm';
import { checkIfEnterHit } from '../../../utils';

const DescriptionsForm = ({
  error,
  unitId,
  onChangeDesc,
  descriptions,
}) => {
  let wrapperClass = 'col-sm-12';

  if (error) {
    wrapperClass += ' ' + 'invalid';
  }

  return(
    <div className={wrapperClass}>
      <label className="form-control-label">
        Descriptions <small>(Add comma separated description list items)</small>
      </label>
      <Creatable
        name={`description-${unitId}`}
        placeholder="Having this skill means you're able to..."
        onChange={onChangeDesc}
        value={descriptions}
        className={'form-group'}
        backspaceRemoves
        shouldKeyDownEventCreateNewOption={checkIfEnterHit}
        multi/>

      {error &&
        <div
          className="form-group"
          style={{ color: '#d9534f', fontSize: 15, marginTop: -12 }}>
          Required
        </div>}
    </div>
  );
};

DescriptionsForm.propTypes = {
  error: PropTypes.string,
  unitId: PropTypes.string,
  onChangeDesc: PropTypes.func,
  descriptions: PropTypes.array
};

export default DescriptionsForm;
