import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { checkHttp } from '../../../utils';
import CpHtmlParser from '../../common/CpHtmlParser';

const styles = {
  editButton: {
    marginLeft: 10,
    cursor: 'pointer',
  }
};

const ExperienceCard = ({
  experience,
  onEditExperienceClick,
  isPublicProfile
}) => {
  const { id, title, description, orgname, orgurl, startdate, enddate, noend} = experience;
  const startDate = moment(startdate.date).format('MMMM YYYY');
  let endDate;

  if (noend) {
    endDate = 'Present';
  } else {
    endDate = moment(enddate.date).format('MMMM YYYY');
  }

  const urlAvailable = orgurl !== '';
  const urlString = checkHttp(orgurl) ?
    orgurl : `//${orgurl}`;

  return (
    <div className="card-block">
      <div className="experience">
        <div className="row">
          <div className="col-lg-12">
            <div className="display">
              <div className="item">
                <div className="title">
                  {title}
                  {! isPublicProfile &&
                    <i
                      style={styles.editButton}
                      onClick={onEditExperienceClick}
                      id={`update-experience-form-${id}`}
                      className="fa fa-xs fa-pencil orange" aria-hidden="true"/>}
                </div>
                <div className="organization">
                  {
                    urlAvailable ? <a href={urlString} target="_blank" rel="noopener noreferrer">{orgname}</a> :
                      orgname
                  }
                </div>
                <div className="date">
                  {startDate}&nbsp;-&nbsp;{endDate}
                </div>
                <div className="ql-editor no-padding-in-quill description">
                  <CpHtmlParser htmlString={description}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ExperienceCard.propTypes = {
  experience: PropTypes.object.isRequired,
  isPublicProfile: PropTypes.bool.isRequired,
  onEditExperienceClick: PropTypes.func.isRequired
};

export default ExperienceCard;
