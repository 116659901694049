import React from 'react';

import classNames from 'classnames';
import _filter from 'lodash/filter';

import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import UnitAccordionContent from './UnitAccordionContent';
import SkillBuildersOverviewModal from './SkillBuildersOverviewModal';

const UnitAccordion = ({
  skillBuildersLookupTable,
  skillbuilders,
  auth,
  homepageModal,
  isActiveSkillBuildersOverview,
  onDismissSkillBuildersOverview,
  toggleSkillBuildersOverviewModal,
  isSkillBuildersOverviewModalOpen
}) => {
  return (
    <div className={classNames({'content': auth})}>
      {auth &&<h1 className="skill-builders-header">CareerPrepped Skill Builders</h1>}
      <p>Access 40+ Skill Builders to build, refresh, or strengthen skills that all employers want. Claim Skill Badges for the skills you feel you have and are ready to prove with evidence.</p>

      {auth &&
        <div className="profile-share">
          <Alert
            color="info"
            isOpen={!isActiveSkillBuildersOverview}
            toggle={onDismissSkillBuildersOverview}>
            <div className="alert-content">
              <div className="alert-content-left">
                <h6 className="alert-heading">
                  Skill Builders Overview
                </h6>
                <p>Learn more about Skill Builders with this video</p>
              </div>
              <div className="alert-content-right">
                <a
                  href="#"
                  className="btn btn-primary"
                  onClick={toggleSkillBuildersOverviewModal}>
                  Watch Overview
                </a>
              </div>
            </div>
          </Alert>
        </div>}

      {isSkillBuildersOverviewModalOpen &&
        <SkillBuildersOverviewModal
          isOpen={isSkillBuildersOverviewModalOpen}
          toggleModal={toggleSkillBuildersOverviewModal}/>}

      <div className={classNames({'sb-cont': auth})}>
        {
          skillBuildersLookupTable.map((elem, index)=>{
            const units = _filter(skillbuilders,obj=>obj.category.id === elem.id && obj.isavailable);
            return (
              <UnitAccordionContent
                key={index}
                elem={elem}
                index={index}
                homepageModal={homepageModal}
                units={units}/>);
          })
        }
        {
          !homepageModal &&
            <div style={{marginTop:10}}>
              <a target="_blank" rel="noopener noreferrer" href="https://help.careerprepped.com/help/what-is-a-skill-builder">What is a Skill Builder?</a>
            </div>
        }

      </div>
    </div>
  );
};

UnitAccordion.propTypes = {
  skillBuildersLookupTable: PropTypes.array.isRequired,
  skillbuilders: PropTypes.array.isRequired,
  homepageModal: PropTypes.bool,
  auth: PropTypes.bool,
  isActiveSkillBuildersOverview: PropTypes.bool.isRequired,
  onDismissSkillBuildersOverview: PropTypes.func.isRequired,
  toggleSkillBuildersOverviewModal: PropTypes.func.isRequired,
  isSkillBuildersOverviewModalOpen: PropTypes.bool.isRequired
};

export default UnitAccordion;
