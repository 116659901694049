import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import LevelPageComponent from '../../../components/profile/connects/LevelPageComponent';

class LevelPage extends Component {
  static propTypes = {
    profile: PropTypes.object.isRequired
  }

  render() {
    const { profile } = this.props;

    return (
      <div>
        <Helmet title="Profile - Your Level"/>

        <LevelPageComponent
          profile={profile}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.data,
  };
};

export default connect(mapStateToProps)(LevelPage);
