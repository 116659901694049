import initialState from './initialState';

import {
  RESET_UNIT,
  UNIT_REQUEST,
  UNIT_SUCCESS,
  UNIT_FAILURE,
  CREATE_UNIT_REQUEST,
  CREATE_UNIT_SUCCESS,
  CREATE_UNIT_FAILURE,
  UPDATE_UNIT_REQUEST,
  UPDATE_UNIT_SUCCESS,
  UPDATE_UNIT_FAILURE,
  DELETE_UNIT_REQUEST,
  DELETE_UNIT_SUCCESS,
  DELETE_UNIT_FAILURE,
  ADD_UNIT_BADGE_REQUEST,
  ADD_UNIT_BADGE_SUCCESS,
  ADD_UNIT_BADGE_FAILURE,
  UPDATE_UNIT_BADGE_REQUEST,
  UPDATE_UNIT_BADGE_SUCCESS,
  UPDATE_UNIT_BADGE_FAILURE,
  DELETE_UNIT_BADGE_REQUEST,
  DELETE_UNIT_BADGE_SUCCESS,
  DELETE_UNIT_BADGE_FAILURE,
  SKILL_BUILDERS_REQUEST,
  SKILL_BUILDERS_SUCCESS,
  SKILL_BUILDERS_FAILURE,
  UPDATE_UNIT_SECTIONS_ORDER,
  ISSUE_BADGE_SUCCESS,
  UPDATE_UNIT_BADGE_ON_LOCAL_STORE,
} from '../../constants';

export default function reducer(state = initialState.skillBuilders, action) {
  switch (action.type) {
  case SKILL_BUILDERS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case SKILL_BUILDERS_SUCCESS:
    return Object.assign({}, state, {
      data: action.data._embedded.unit,
      isRequesting: false,
    });

  case SKILL_BUILDERS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case RESET_UNIT:
    return Object.assign({}, state, {
      unit: initialState.skillBuilders.unit,
    });

  case UNIT_REQUEST:
    return Object.assign({}, state, {
      isRequestingUnit: true,
    });

  case UNIT_SUCCESS:
    return Object.assign({}, state, {
      unit: action.data,
      isRequestingUnit: false,
    });

  case UNIT_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequestingUnit: false,
    });

  case UPDATE_UNIT_SECTIONS_ORDER:
    return Object.assign({}, state, {
      unit: action.data,
      data: [
        ...state.data.slice(0, action.unitIndex),
        Object.assign({}, action.data),
        ...state.data.slice(action.unitIndex + 1),
      ],
    });

  case CREATE_UNIT_REQUEST:
    return Object.assign({}, state, { isSubmitting: true });

  case CREATE_UNIT_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data, Object.assign({}, action.data)],
      isSubmitting: false,
    });

  case CREATE_UNIT_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmitting: false,
    });

  case UPDATE_UNIT_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, unitIndex: action.index },
    });

  case UPDATE_UNIT_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, state.isUpdating.unitIndex),
        Object.assign({}, action.data),
        ...state.data.slice(state.isUpdating.unitIndex + 1),
      ],
      isUpdating: { status: false, unitIndex: null },
    });

  case UPDATE_UNIT_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false, unitIndex: null },
    });

  case DELETE_UNIT_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, id: action.id },
    });

  case DELETE_UNIT_SUCCESS:
    return Object.assign({}, state, {
      data: [...state.data.filter(unit => unit.id !== state.isDeleting.id)],
      isDeleting: { status: false, id: null },
    });

  case DELETE_UNIT_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: { status: false, id: null },
    });

  case ADD_UNIT_BADGE_REQUEST:
    return Object.assign({}, state, {
      isSubmittingBadge: { status: true, id: action.id },
    });

  case ADD_UNIT_BADGE_SUCCESS:
    return Object.assign({}, state, {
      ...updateUnitBadge(state, action, state.isSubmittingBadge.id),
      isSubmittingBadge: { status: false, id: null },
    });

  case ADD_UNIT_BADGE_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isSubmittingBadge: { status: false, id: null },
    });

  case UPDATE_UNIT_BADGE_REQUEST:
    return Object.assign({}, state, {
      isUpdatingBadge: { status: true, id: action.id },
    });

  case UPDATE_UNIT_BADGE_SUCCESS:
    return Object.assign({}, state, {
      ...updateUnitBadge(state, action, state.isUpdatingBadge.id),
      isUpdatingBadge: { status: false, id: null },
    });

  case UPDATE_UNIT_BADGE_ON_LOCAL_STORE:
    return Object.assign({}, state, {
      ...updateUnitBadge(state, action, action.unitId),
    });

  case UPDATE_UNIT_BADGE_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdatingBadge: { status: false, id: null },
    });

  case DELETE_UNIT_BADGE_REQUEST:
    return Object.assign({}, state, {
      isDeletingBadge: { status: true, id: action.id },
    });

  case DELETE_UNIT_BADGE_SUCCESS:
    return Object.assign({}, state, {
      ...updateUnitBadge(state, action, state.isDeletingBadge.id),
      isDeletingBadge: { status: false, id: null },
    });

  case DELETE_UNIT_BADGE_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeletingBadge: { status: false, id: null },
    });

    /* Affected by claim badge*/
  case ISSUE_BADGE_SUCCESS:
    return Object.assign({}, state, {
      ...updateUnitBadge(state, action, action.data.id),
    });

  default:
    return state;
  }
}

const updateUnitBadge = (state, action, unitId) => {
  const { data, unit: unitInState } = state;
  const indexOfUnit = data.findIndex(item => item.id === unitId);
  const newUnitInState = Object.assign({}, unitInState, { badge: action.data });

  return {
    ...state,
    unit: newUnitInState,
    data: [
      ...data.slice(0, indexOfUnit),
      newUnitInState,
      ...data.slice(indexOfUnit + 1),
    ],
  };
};
