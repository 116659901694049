import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import Parser from 'html-react-parser';

const styles = {
  userNameEditIcon: {
    cursor: 'pointer',
    marginLeft: 10,
  },

  editActions: {
    position: 'relative',
  }
};

const AssociationSectionCard = ({
  associationDetails,
  onEditAssociation,
  onRemoveAssociation
}) => {
  const isStartDateObject = typeof associationDetails.startdate === 'object';
  const startDateString = isStartDateObject ? associationDetails.startdate.date :
    associationDetails.startdate === '' ? undefined : associationDetails.startdate;

  const isEndDateObject = typeof associationDetails.enddate === 'object';
  const endDateString = isEndDateObject ? associationDetails.enddate.date :
    associationDetails.enddate === '' ? undefined : associationDetails.enddate;

  const startDate = moment(startDateString).format('MM/YYYY');
  let endDate;

  if (associationDetails.noend) {
    endDate = 'Present';
  } else {
    endDate = moment(endDateString).format('MM/YYYY');
  }

  return (
    <div className="resume-section section-item">
      <span style={styles.editActions} className="float-right">
        <i
          onClick={onEditAssociation}
          id={associationDetails.id}
          style={styles.userNameEditIcon}
          className="fa fa-pencil"/>

        <i
          onClick={onRemoveAssociation}
          id={associationDetails.id}
          style={styles.userNameEditIcon}
          className="fa fa-close"/>
      </span>
      {associationDetails.url === '' ?
        <span><b>{associationDetails.name}</b></span> :
        <span>
          <b>
            <a href={associationDetails.url} target="_blank" rel="noopener noreferrer">
              {associationDetails.name}
            </a>
          </b>
        </span>}

      <span style={{marginLeft: 10}}>({startDate} - {endDate})</span>
      <p><b>Position(s):</b> {associationDetails.position}</p>
      {Parser(associationDetails.description)}
    </div>
  );
};

AssociationSectionCard.propTypes = {
  associationDetails: PropTypes.object.isRequired,
  onEditAssociation: PropTypes.func.isRequired,
  onRemoveAssociation: PropTypes.func.isRequired
};

export default AssociationSectionCard;
