import React from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

export const withHooks = (Component) => {
  let WithHooks = (props) => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    return (
      <Component
        params={params}
        location={location}
        navigate={navigate}
        {...props}/>
    );
  };

  WithHooks.displayName = `withHooks(${Component.displayName})`;

  return WithHooks;
};
