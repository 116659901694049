import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getUsers, setUsersSortOrder } from '../../redux/actions/users';
import {
  disableBulkMessages,
} from '../../redux/actions/profile';

import { isEmpty } from 'lodash';

import { generateRequestUrl } from '../../utils';

import { UncontrolledPopover, PopoverBody } from 'reactstrap';

import UserGroupsListModal from '../../components/admin/UserGroupsListModal';
import UsersTableCheckedMessageCell from '../../components/admin/table-custom-components/UsersTableCheckedMessageCell';
import UsersTableNameCell from '../../components/admin/table-custom-components/UsersTableNameCell';
import UsersTableStatusCell from '../../components/admin/table-custom-components/UsersTableStatusCell';
import UsersTableFilesCell from '../../components/admin/table-custom-components/UsersTableFilesCell';
import UsersTableBadgesCell from '../../components/admin/table-custom-components/UsersTableBadgesCell';
import UsersTableDropdownAction from '../../components/admin/table-custom-components/UsersTableDropdownAction';
import UsersTableDisableMessageCell from '../../components/admin/table-custom-components/UsersTableDisableMessageCell';
import PaginationHandler from '../../components/common/PaginationHandler';
import TableLoading from '../../components/common/TableLoading';
import RequestFeedbackModal from '../../containers/feedback/RequestFeedbackModal';
import GiveFeedbackModal from '../../containers/feedback/GiveFeedbackModal';
import SeeFeedbackModal from '../../containers/feedback/SeeFeedbackModal';
import AllFeedbackReceived from '../../components/myskills/AllFeedbackReceived';
import { SimpleToolTipWrapper } from '../../components/common/ToolTipWrapper';

class AdminUsersTable extends PureComponent {
  static propTypes = {
    page: PropTypes.number.isRequired,
    sort: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    groupId: PropTypes.string,
    pageCount: PropTypes.number.isRequired,
    usersList: PropTypes.array.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    institutionId: PropTypes.string,
    isAuthUserSelectedInstitutionAdmin: PropTypes.bool.isRequired,
    bulkStatus: PropTypes.string,
    disableMessages: PropTypes.func,
    isAllMessageDisabled: PropTypes.bool,
    profile: PropTypes.object.isRequired,
    setSelectedUsers: PropTypes.func,
    setIsAllSelected: PropTypes.func,
    isAllSelected: PropTypes.bool,
    filters: PropTypes.object,
    nameOrder: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showRequestFeedbackModal: false,
      bulkStatus: '',
      showGiveFeedbackModal: false,
      showSeeFeedbackModal: false,
      showViewFeedbackModal: false,
      userDataForFeedbackRequest: {
        userId: null,
        userData: {},
      },
      userDataForGiveFeedback: {
        userId: null,
        userData: {},
      },
      userDataForSeeFeedback: {
        userId: null,
        userData: {},
      },
      skillBadgeDataForSeeFeedback: false,
      skillBadgeRatingsDataForSeeFeedback: false,
      statusClicked: [],
      isMasterCheckboxClicked: false,
      isUserGroupsListOpen: ''
    };

    this.onNext = this.onNext.bind(this);
    this.onPrev = this.onPrev.bind(this);
    this.onFetchUsers = this.onFetchUsers.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
    this.getUserList = this.getUserList.bind(this);
    this.toggleUserGroupsListModal = this.toggleUserGroupsListModal.bind(this);
  }

  componentWillMount() {
    this.getUserList();
  }

  componentWillReceiveProps(nextProps) {
    const { bulkStatus } = nextProps;
    if (bulkStatus && bulkStatus !== this.state.bulkStatus) {
      this.getUserList();
      this.setState({ bulkStatus });
    }
  }

  toggleUserGroupsListModal(userId) {
    return (event) => {
      event.preventDefault();

      let isUserGroupsListOpen = '';

      if (userId !== this.state.isUserGroupsListOpen) {
        isUserGroupsListOpen = userId;
      }

      this.setState({ isUserGroupsListOpen });
    };
  }

  getUserList() {
    const {
      actions,
      groupId,
      institutionId,
      isAuthUserSelectedInstitutionAdmin,
    } = this.props;
    if (groupId) {
      let urlParamString = `group=${groupId}`;

      if (isAuthUserSelectedInstitutionAdmin && groupId === 'all-groups') {
        urlParamString = `institution=${institutionId}`;
      }

      actions.getUsers(urlParamString).then((response) => {
        this.setClickedStatus(response);
      });
    }
  }

  toggleRequestFeedbackModal = () => {
    this.setState(state => ({
      showRequestFeedbackModal: !state.showRequestFeedbackModal,
    }));
  };

  toggleGiveFeedbackModal = () => {
    this.setState(state => ({
      showGiveFeedbackModal: !state.showGiveFeedbackModal,
    }));
  };

  toggleSeeFeedbackModal = () => {
    this.setState(state => ({
      showSeeFeedbackModal: !state.showSeeFeedbackModal,
    }));
  };

  toggleViewFeedbackModal = () => {
    this.setState(state => ({
      showViewFeedbackModal: !state.showViewFeedbackModal,
    }));
  };

  setUserDataForFeedbackRequest = (data) => {
    this.setState(state => ({
      userDataForFeedbackRequest: {
        ...state.userDataForFeedbackRequest,
        ...data,
      },
    }));
  };

  setUserDataForGiveFeedback = (data) => {
    this.setState(state => ({
      userDataForGiveFeedback: {
        ...state.userDataForGiveFeedback,
        ...data,
      },
    }));
  };

  setUserDataForSeeFeedback = (data) => {
    this.setState(state => ({
      userDataForSeeFeedback: {
        ...state.userDataForSeeFeedback,
        ...data,
      },
    }));
  };

  setSkillBadgeDataForSeeFeedback = (data, ratings) => {
    this.setState({
      skillBadgeDataForSeeFeedback: {
        ...data,
      },
      skillBadgeRatingsDataForSeeFeedback: {
        ...ratings,
      }
    });
  };

  clearUserDataForFeedbackRequest = () => {
    this.setState({
      userDataForFeedbackRequest: {
        userId: null,
        userData: {},
      },
    });
  };

  onFetchUsers(event) {
    const column = event.currentTarget.dataset['columnName'];
    const {
      actions,
      sort: { orderBy, order: sortOrder },
    } = this.props;

    const order = !sortOrder;
    const sort = { column, order, orderBy };

    actions.setUsersSortOrder(sort);

    const urlProps = { ...this.props, sort };
    const urlParamString = generateRequestUrl(urlProps);

    actions.getUsers(urlParamString).then((response) => {
      this.setClickedStatus(response);
    });
  }

  onSelectPage(page) {
    return () => {
      const { actions } = this.props;
      const urlProps = { ...this.props, page };

      const urlParamString = generateRequestUrl(urlProps);

      actions.getUsers(urlParamString).then((response) => {
        this.setClickedStatus(response);
      });
    };
  }

  onNext() {
    const { page: currentPage, actions } = this.props;
    const page = currentPage + 1;
    const urlProps = { ...this.props, page };

    const urlParamString = generateRequestUrl(urlProps);

    actions.getUsers(urlParamString).then((response) => {
      this.setClickedStatus(response);
    });
  }

  onPrev() {
    const { page: currentPage, actions } = this.props;
    const page = currentPage - 1;
    const urlProps = { ...this.props, page };

    const urlParamString = generateRequestUrl(urlProps);

    actions.getUsers(urlParamString).then((response) => {
      this.setClickedStatus(response);
    });
  }

  cancelRequestFeedbackModal = () => {
    this.toggleRequestFeedbackModal();
    this.clearUserDataForFeedbackRequest();
  };

  cancelGiveFeedbackModal = () => {
    this.toggleGiveFeedbackModal();
  };

  cancelSeeFeedbackModal = () => {
    this.setState({
      userDataForSeeFeedback: {
        userId: null,
        userData: {},
      },
    });
    this.toggleSeeFeedbackModal();
  };

  cancelViewFeedbackModal = () => {
    this.toggleViewFeedbackModal();
  };

  submitBulkDisableMessage = (e) => {
    this.props.setIsAllSelected(e.target.checked);
    this.props.setSelectedUsers([]);
    this.setState({ isMasterCheckboxClicked: true });
  }

  setIsMasterCheckboxClicked = value => this.setState({ isMasterCheckboxClicked: value })


  setClickedStatus = (response) => {
    let allUserIds = [];
    response.users.data.map((user) => {
      allUserIds[user.id] = false;
    });
    this.setState({
      statusClicked: allUserIds
    });
  }

  render() {
    const {
      page,
      pageCount,
      sort: { column },
      usersList,
      profile,
      nameOrder,
      setSelectedUsers,
      isAllSelected,
      isRequesting,
      institutionId
    } = this.props;

    return (
      <div style={{ position: 'relative' }} className="adminDashboard-scrollbar">
        <table className="cp-table mt-1 table table-responsive-lg">
          <thead>
            <tr className="cp-table-header">
              <th
                className="cursor-pointer"
                data-column-name="name">
                <div className="pl-0 custom-checkbox-wrapper">
                  <label check="true" className="container mr-0 pl-0 mt-n4">
                    <input
                      onChange={this.submitBulkDisableMessage}
                      className=""
                      type="checkbox" />
                    <span className="checkmark" />
                  </label></div>
              </th>
              <th
                style={{ width: '25%' }}
                className="cursor-pointer"
                data-column-name="name"
                onClick={this.onFetchUsers}>
                Name
              </th>

              <th
                style={{ width: 150, textAlign: 'center' }}
                className="cursor-pointer"
                data-column-name="careerSite">
                Career Site
              </th>

              <th
                style={{ width: 150, textAlign: 'center' }}
                className="cursor-pointer"
                data-column-name="resumes">
                Resumes
              </th>

              <th
                style={{ width: 150, textAlign: 'center' }}
                className="cursor-pointer"
                data-column-name="experiences">
                <span id="popover-experiences">
                  Experiences
                  <i className="fa fa-question-circle ml-1 orange" />
                </span>
              </th>
              <UncontrolledPopover
                placement="top"
                target="popover-experiences"
                trigger="legacy">
                <PopoverBody>
                  Number of experiences documented in user’s Career Site.
                </PopoverBody>
              </UncontrolledPopover>

              <th
                style={{ width: 150, textAlign: 'center' }}
                className="cursor-pointer"
                data-column-name="credentials">
                <span id="popover-credentials">
                  Credentials
                  <i className="fa fa-question-circle ml-1 orange" />
                </span>
              </th>
              <UncontrolledPopover
                placement="top"
                target="popover-credentials"
                trigger="legacy">
                <PopoverBody>
                  Education, certifications, and licenses documented in user’s Career Site.
                </PopoverBody>
              </UncontrolledPopover>

              <th style={{ width: 150, textAlign: 'center' }}>
                Skill Badges
              </th>

              <th
                style={{ width: 100, textAlign: 'center' }}
                className="cursor-pointer"
                data-column-name="filesCount"
                onClick={this.onFetchUsers}>
                Other Skills
              </th>

              <th
                style={{ width: 100, textAlign: 'center' }}
                className="cursor-pointer"
                data-column-name="badgesCount"
                onClick={this.onFetchUsers}>
                Port. Files
              </th>

              <th style={{ width: 100, textAlign: 'center' }}>
                <SimpleToolTipWrapper
                  id="manage-irs-list-feedback-limit-count"
                  toolTipContent={
                    <div className="inside-tooltip">
                      Allow user to DM an Industry Mentor
                    </div>
                  }>
                  <>
                    DMs
                    <i className="fa fa-info-circle ml-1" />
                  </>
                </SimpleToolTipWrapper>
              </th>
              <th style={{ width: 100, textAlign: 'center' }}>Actions</th>
            </tr>
          </thead>

          <tbody>
            {!isRequesting && usersList.length === 0 && (
              !isEmpty(this.props.filters.searchQuery) ? (
                <tr>
                  <td colSpan="10" style={{ textAlign: 'center' }}>
                    {'Sorry, we couldn\'t find anything!'}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan="10" style={{ textAlign: 'center' }}>
                    {'There are no users yet'}
                  </td>
                </tr>
              ))}

            {usersList.map(user => (
              <tr key={user.id}>
                <td >
                  <UsersTableCheckedMessageCell
                    user={user}
                    getUserList={this.getUserList}
                    setSelectedUsers={setSelectedUsers}
                    isAllSelected={isAllSelected}
                    setIsMasterCheckboxClicked={this.setIsMasterCheckboxClicked}
                    isMasterCheckboxClicked={this.state.isMasterCheckboxClicked} />
                </td>
                <td
                  className={classNames({
                    'column-sorting': column === 'name',
                  })}>
                  <UsersTableNameCell user={user} nameOrder={nameOrder} />
                </td>

                <td style={{ textAlign: 'center' }}>
                  <UsersTableStatusCell user={user} statusClicked={this.state.statusClicked} />
                </td>

                <td
                  style={{ textAlign: 'center' }}
                  className={classNames({
                    'column-sorting': column === 'resumes',
                  })}>
                  <Link
                    title={`Link to ${user.name}'s resume page`}
                    state={{ user }}
                    to={`/job-center/resume-builder/${user.id}`}>
                    {user.totalResumes}
                  </Link>
                </td>

                <td
                  style={{ textAlign: 'center' }}
                  className={classNames({
                    'column-sorting': column === 'experiences',
                  })}>
                  <Link
                    title={`Link to ${user.name}'s Career Site`}
                    target="_blank"
                    to={`/cp/${user.vanity}`}>
                    {user.totalExperiences}
                  </Link>
                </td>

                <td
                  style={{ textAlign: 'center' }}
                  className={classNames({
                    'column-sorting': column === 'credentials',
                  })}>
                  <Link
                    title={`Link to ${user.name}'s Career Site`}
                    target="_blank"
                    to={`/cp/${user.vanity}`}>
                    {user.totalCredentials}
                  </Link>
                </td>

                <td style={{ textAlign: 'center' }}>
                  <Link
                    title={`Link to ${user.name}'s skills page`}
                    target="_blank"
                    to={
                      user.id !== profile?.id ?
                        `/myskills/${user.id}?username=${user.name}&institutionId=${institutionId}` :
                        '/myskills'
                    }>
                    {user.totalBadges}
                  </Link>
                </td>

                <td
                  style={{ textAlign: 'center' }}
                  className={classNames({
                    'column-sorting': column === 'filesCount',
                  })}>
                  <UsersTableFilesCell
                    user={user}
                    profile={profile}
                    institutionId={institutionId}/>
                </td>

                <td
                  style={{ textAlign: 'center' }}
                  className={classNames({
                    'column-sorting': column === 'badgesCount',
                  })}>
                  <UsersTableBadgesCell user={user} profile={profile} />
                </td>

                <td
                  className={classNames({
                    'column-sorting': column === 'location',
                  })}>
                  <UsersTableDisableMessageCell user={user} getUserList={this.getUserList} />
                </td>

                <td style={{ textAlign: 'center' }}>
                  <UsersTableDropdownAction
                    user={user}
                    toggleRequestFeedbackModal={this.toggleRequestFeedbackModal}
                    setUserDataForFeedbackRequest={
                      this.setUserDataForFeedbackRequest
                    }
                    toggleGiveFeedbackModal={this.toggleGiveFeedbackModal}
                    setUserDataForGiveFeedback={this.setUserDataForGiveFeedback}
                    toggleSeeFeedbackModal={this.toggleSeeFeedbackModal}
                    setUserDataForSeeFeedback={this.setUserDataForSeeFeedback} />
                </td>

                {(user.id === this.state.isUserGroupsListOpen) &&
                  <UserGroupsListModal
                    user={user}
                    onClose={this.toggleUserGroupsListModal(user.id)}
                    isOpen={user.id === this.state.isUserGroupsListOpen}/>}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th style={{ textAlign: 'center' }} colSpan={10}>
                <PaginationHandler
                  page={page}
                  page_count={pageCount}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSelectPage={this.onSelectPage} />
              </th>
            </tr>
          </tfoot>
        </table>
        {this.state.showRequestFeedbackModal && (
          <RequestFeedbackModal
            isOpen={this.state.showRequestFeedbackModal}
            onCancel={this.cancelRequestFeedbackModal}
            isTwoStep
            userDataForFeedbackRequest={this.state.userDataForFeedbackRequest}
            clearUserDataForFeedbackRequest={
              this.clearUserDataForFeedbackRequest
            } />
        )}

        {this.state.showGiveFeedbackModal && (
          <GiveFeedbackModal
            isOpen={this.state.showGiveFeedbackModal}
            onCancel={this.cancelGiveFeedbackModal}
            userDataForGiveFeedback={this.state.userDataForGiveFeedback} />
        )}

        {this.state.showSeeFeedbackModal && (
          <SeeFeedbackModal
            isOpen={this.state.showSeeFeedbackModal}
            onCancel={this.cancelSeeFeedbackModal}
            userDataForSeeFeedback={this.state.userDataForSeeFeedback}
            viewFeedback={this.toggleViewFeedbackModal}
            setDataForSeeFeedback={this.setSkillBadgeDataForSeeFeedback} />
        )}

        {this.state.showViewFeedbackModal && (
          <AllFeedbackReceived
            data={this.state.skillBadgeDataForSeeFeedback}
            ratings={this.state.skillBadgeRatingsDataForSeeFeedback}
            toggleModal={this.cancelViewFeedbackModal}
            isOpen={this.state.showViewFeedbackModal} />
        )}
        {this.props.isRequesting && <TableLoading />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    users: { page, pageCount, sort, filters, isRequesting, data: usersList, isAllMessageDisabled },
    institutions: {
      selectedInstitution: { id: institutionId },
    },
    groups: {
      selectedGroup: { id: groupId },
      isChildGroupSelected,
      isAuthUserSelectedInstitutionAdmin,
    },
    profile,
  } = state;

  return {
    sort,
    page,
    pageCount,
    filters,
    isAllMessageDisabled,
    usersList,
    isRequesting,
    groupId,
    institutionId,
    isChildGroupSelected,
    isAuthUserSelectedInstitutionAdmin,
    profile: profile.data,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getUsers,
      setUsersSortOrder,
      disableBulkMessages,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsersTable);
