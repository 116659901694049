import React from 'react';

import PropTypes from 'prop-types';
import { get } from 'lodash';

const RequestsCountColumn = (props) => {
  const rowDetails = props.rowData;
  const feedbackRequests = get(rowDetails, 'feedbackRequests', []);

  return (
    <td scope="row">{feedbackRequests.map((requestCount, index) => {
      return <span className="d-block" key={index}>{requestCount}</span>;
    })}</td>
  );
};

RequestsCountColumn.propTypes = {
  rowData: PropTypes.object.isRequired,
};

export default RequestsCountColumn;