import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as sectionsActions from '../../redux/actions/sections';
import * as componentsActions from '../../redux/actions/components';
import * as skillBuilderActions from '../../redux/actions/skill-builders';
import * as reflectionsActions from '../../redux/actions/reflections';
import * as routerActions from '../../redux/actions/router';

import { withHooks } from '../../utils/withHooks';

import { Helmet } from 'react-helmet';
import Header from '../../components/skill-builders/essentials/Header';
import StickyDiv from '../../containers/common/stickyDiv/StickyDiv';
import isEmpty from 'lodash/isEmpty';
import { toastr } from 'react-redux-toastr';
import classNames from 'classnames';
import RenderEssentials from '../../components/skill-builders/essentials/RenderEssentials';

class SkillBuilderEssentials extends Component {
  static propTypes = {
    unit: PropTypes.object.isRequired,
    forms: PropTypes.array.isRequired,
    params: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    sections: PropTypes.object.isRequired,
    isRequestingUnit: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    updateResourceForms: PropTypes.array.isRequired,
    location: PropTypes.object,
    pathname: PropTypes.string,
    isRequesting: PropTypes.bool,
    navigate: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      formName: '',
      sections: [...this.props.sections.data],
      hover: { status: false, componentName: null },
      unitTitle: '',
      path: '',
      noSection: false
    };

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSelectSubmit = this.onSelectSubmit.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.checkAdmin = this.checkAdmin.bind(this);
  }

  componentDidMount() {
    const { params, actions, navigate, isAuthenticated } = this.props;
    const { slug } = params;

    if(!isAuthenticated){
      const redirectPath = 'skill-builders';

      toastr.error('Permission is Needed.', 'You must be signed in to access.');

      return navigate(`/${redirectPath}/${slug}`);
    }


    actions.essentialsRequest(slug, isAuthenticated);
  }

  componentWillReceiveProps(nextprops) {
    if (this.props.params.slug !== nextprops.params.slug) {
      this.props.actions.essentialsRequest(nextprops.params.slug, this.props.isAuthenticated);
    }

    if (!isEmpty(nextprops.unit) && nextprops.unit !== this.props.unit) {
      const unitTitle = `${nextprops.unit.code} ${nextprops.unit.title}`;

      this.setState({
        unitTitle,
        path: nextprops.location.pathname
      });
    }

    if (!nextprops.isRequestingUnit && `${nextprops.unit.sections}`.trim() === '')
      return this.setState({
        noSection: true
      });

    if (!nextprops.isRequestingUnit)
      this.setState({
        noSection: false
      });
  }

  componentWillUpdate(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname)
      this.setState({
        path: nextProps.location.pathname
      });
  }

  componentWillUnmount() {
    const { actions } = this.props;

    actions.resetForms();
    actions.sectionsReset();
  }

  onMouseEnter(e) {
    e.preventDefault();
    const componentName = e.currentTarget.children[0].id;

    this.setState({ hover: { status: true, componentName } });
  }

  onMouseLeave(e) {
    e.preventDefault();
    const componentName = e.currentTarget.children[0].id;

    this.setState({ hover: { status: false, componentName } });
  }

  checkAdmin() {
    const pathArray = window.location.pathname.split('/');

    return !!(pathArray.includes('admin'));
  }

  onSelectChange(event) {
    this.setState({ formName: event.currentTarget['value'] });
  }

  onEdit(event) {
    const componentId = event.currentTarget.closest('h3').id;
    const idElements = componentId.split('_');
    const id = idElements[1];

    this.props.actions.loadUpdateForm(id);
  }

  onCancelUpdate(event) {
    const componentId = event.target.id;
    const idElements = componentId.split('_');
    const id = idElements[1];

    this.props.actions.unloadUpdateForm(id);
  }

  onCancel(event) {
    const formId = event.target.closest('form').id;

    if (! formId) return this.onCancelUpdate(event);

    const idElements = formId.split('_');
    const formName = idElements[0];
    const id = parseInt(idElements[1]);
    const form = { id, formName, formType: 'sections' };

    this.props.actions.unloadSectionForm(form);
  }

  onSelectSubmit() {
    let forms = this.props.forms;
    const id = forms.length + 1;
    const form = { id, formType: 'sections', formName: this.state.formName };

    this.props.actions.loadSectionForm(form);
  }

  render() {
    const {
      unit,
      sections,
      forms,
      params,
      isRequestingUnit,
      isAuthenticated,
      isRequesting,
      updateResourceForms
    } = this.props;

    const { slug } = params;

    const {
      unitTitle,
      path,
      noSection,
      formName,
      hover
    } = this.state;

    const containerClass = classNames('row', { 'essential-container': !this.checkAdmin() });

    return (
      <div>
        <Helmet title={`Skill Builders - Essentials - ${unit.code} ${unit.title}`}/>

        <div className="container">
          <div className={containerClass}>
            <StickyDiv
              component={Header}
              startId={'essHeader'}
              endId={'essential-wrapper'}
              defaultCss={'intro-header container'}
              unit={unit}
              disableSticking={this.checkAdmin()}
              isAdmin={this.checkAdmin()}
              slug={slug}
              unitTitle={unitTitle}
              path={path}
              isAuthenticated={isAuthenticated}
              isRequesting={isRequestingUnit}
              noSection={noSection}/>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <RenderEssentials
                noSection={noSection}
                isRequesting={isRequesting || isRequestingUnit}
                unit={unit}
                checkAdmin={this.checkAdmin}
                onSelectChange={this.onSelectChange}
                formName={formName}
                onSelectSubmit={this.onSelectSubmit}
                forms={forms}
                onCancel={this.onCancel}
                sections={sections}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                hover={hover}
                updateResourceForms={updateResourceForms}
                onEdit={this.onEdit}
                isAuthenticated={isAuthenticated}
                slug={slug}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, sections, profile, components, skillBuilders } = state;

  return {
    sections,
    profile: profile.data,
    unit: skillBuilders.unit,
    forms: components.sectionForms,
    isAuthenticated: auth.isAuthenticated,
    isRequestingUnit: skillBuilders.isRequestingUnit,
    updateResourceForms: components.updateResourceForms,
    isRequesting: skillBuilders.isRequesting
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    sectionsActions,
    componentsActions,
    reflectionsActions,
    skillBuilderActions,
    routerActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SkillBuilderEssentials));
