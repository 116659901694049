import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import { scrollToTop } from '../../../utils';

const styles = {
  icon: { marginRight: 5 }
};

class CommentButton extends Component {
  static propTypes = {
    discussion: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool,
  };

  constructor(props, context){
    super(props, context);

    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    event.preventDefault();

    const { discussion, isAuthenticated } = this.props;

    if (!isAuthenticated) {
      scrollToTop();
      return toastr.error('Nonauthorized', 'Please Login or Signup to comment the post.');
    }

    const form = `comment-form-discussionId-${discussion.id}`;

    document.getElementById(form).focus();
  }

  render() {
    const { discussion } = this.props;

    return (
      <a href="#"
        onClick={this.onClick}
        data-discussion-id={`discussion-${discussion.id}`}>
        <i className="fa fa-comment" style={styles.icon}/>
        <span style={styles.icon}>Comment</span>
      </a>
    );
  }
}

export default CommentButton;
