import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import DashboardPostForm from '../../../components/dashboard/DashboardPostForm';
import * as componentsActions from '../../../redux/actions/components';
import * as discussionsActions from '../../../redux/actions/discussions';

const initialDiscussion = { post: '' };

class DiscussionsPostEdit extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    discussion: PropTypes.object.isRequired,
    isUpdating: PropTypes.bool.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      imagePreviewUrl: '',
      plainText: '',
      discussion: this.setInitialDiscussion(props.discussion),
      isCancelModalOpen: false
    };

    this.onCancel = this.onCancel.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onToggleCancelModal = this.onToggleCancelModal.bind(this);
    this.onCancelConfirm = this.onCancelConfirm.bind(this);
    this.handleHTMLChange = this.handleHTMLChange.bind(this);
    this.onDropFile = this.onDropFile.bind(this);
    this.onRemoveAttachment = this.onRemoveAttachment.bind(this);
  }

  componentDidMount() {
    window.onbeforeunload = () => {
      return 'Changes you made might get lost!';
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = () => {
      return null;
    };
  }

  setInitialDiscussion(discussionInProps){
    if(discussionInProps){
      return Object.assign({}, initialDiscussion, discussionInProps);
    }

    return initialDiscussion;
  }

  onDropFile(files) {
    const { discussion } = this.state;

    if(files[0].size > 8000000)
      return toastr.info('Sorry, this image exceeds the 8MB limit.');

    discussion['attachment'] = files[0];

    this.setState({
      discussion,
      imagePreviewUrl: files[0].preview
    });
  }

  onRemoveAttachment(){
    const { discussion } = this.state;

    discussion['attachment'] = {};

    this.setState({
      discussion
    });
  }

  onChange(event) {
    const field = event.target.name;
    const { discussion } = this.state;

    discussion[field] = event.target.value;

    this.setState({ discussion });
  }

  onCancelConfirm(){
    const { actions, discussion } = this.props;
    const form = `update-post-form-${discussion.id}`;

    actions.unloadForm(form);
  }

  onCancel(){
    const { discussion } = this.state;
    const { post, permissions } = this.props.discussion;
    const { post: statePost, permissions: statePermissions } = discussion;

    if(post !== statePost || permissions !== statePermissions){
      return this.onToggleCancelModal();
    }

    this.onCancelConfirm();
  }

  onToggleCancelModal(){
    this.setState({
      isCancelModalOpen: !this.state.isCancelModalOpen
    });
  }

  onUpdate(event) {
    event.preventDefault();

    const { discussion: discussionPostState, plainText } = this.state;
    const { actions, discussion } = this.props;
    
    if(plainText === '' && isEmpty(discussionPostState.attachment)){
      return this.props.actions.openModal('emptyPostModal');
    }

    let formData = new FormData();
    formData.append('post', plainText === '' ? '<br>' : discussionPostState.post);
    formData.append('attachment', !isEmpty(discussionPostState.attachment) ? discussionPostState.attachment : '');

    actions.updateUnitDiscussion(discussion.id, formData)
      .then(() => this.handleResponse());
  }

  handleResponse() {
    const { discussion } = this.props;

    this.props.actions.unloadForm(`update-post-form-${discussion.id}`);
  }

  handleHTMLChange(content, delta, source, editor){
    let { discussion } = this.state;


    discussion.post = content;
    this.setState({ 
      discussion,
      plainText:  editor.getText(content).trim() 
    });
  }

  render() {
    const { 
      discussion, 
      imagePreviewUrl: previewUrl,
      isCancelModalOpen 
    } = this.state;
    const { attachment } = discussion;
    const { isUpdating } = this.props;
    const imagePreviewUrl = isObject(attachment) ? previewUrl : attachment;

    return (
      <div>
        <DashboardPostForm
          handleHTMLChange={this.handleHTMLChange}
          onSave={this.onUpdate}
          wallPost={discussion}
          isSubmitting={isUpdating}
          imagePreviewUrl={imagePreviewUrl}
          onCancel={this.onRemoveAttachment}
          onCancelEdit={this.onCancel}
          onDropFile={this.onDropFile}/>
        <Modal 
          backdrop="static"
          className="modal-margin-top" 
          isOpen={isCancelModalOpen} 
          toggle={this.onToggleCancelModal}>
          <ModalHeader
            toggle={this.onToggleCancelModal}>
            Discard you changes
          </ModalHeader>
          <ModalBody>
            Are you sure you want to discard your changes?
          </ModalBody>
          <ModalFooter>
            <button
              onClick={this.onToggleCancelModal}
              className="btn btn-secondary">
              No
            </button>

            <button
              onClick={this.onCancelConfirm}
              className="btn btn-primary">
              Discard Changes
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  const { discussions, components } = state;
  const { forms } = components;
  const { isUpdating } = discussions;

  return {
    loadForms: forms,
    isUpdating: isUpdating.status
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, componentsActions, discussionsActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscussionsPostEdit);
