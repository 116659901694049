import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Prompt, Navigate } from 'react-router-dom';

import axios from 'axios';
import classNames from 'classnames';
import { filter, isEmpty, isEqual, isUndefined } from 'lodash';

import { parseJSON, formatFormDataArray } from '../../utils';
import { withHooks } from '../../utils/withHooks';
import Validator from '../../validator';

import { toastr } from 'react-redux-toastr';

import * as badgeActions from '../../redux/actions/badges';
import * as discussionActions from '../../redux/actions/discussions';
import * as skillBuildersActions from '../../redux/actions/skill-builders';
import * as questionnairesActions from '../../redux/actions/questionnaires';
import * as routerActions from '../../redux/actions/router';
import {
  getMyCareerPreppedStatus,
  updateMyCareerPreppedStatus
} from '../../redux/actions/my-careerprepped-status';

import StickyDiv from '../common/stickyDiv/StickyDiv';
import Header from '../../components/skill-builders/essentials/Header';
import BadgeFeedback from '../../components/skill-builders/claim-badge/BadgeFeedback';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

class ClaimBadgeSurvey extends Component {
  static propTypes = {
    unit: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    location: PropTypes.object,
    pathname: PropTypes.string,
    currentUser: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    isIssuingBadge: PropTypes.bool,
    badge: PropTypes.object,
    params: PropTypes.object,
    issuedBadge: PropTypes.object.isRequired,
    badges: PropTypes.array.isRequired,
    isRequestingUnit: PropTypes.bool.isRequired,
    isRequestingQuestionaires: PropTypes.bool.isRequired,
    feedbacks: PropTypes.array.isRequired,
    storedAction: PropTypes.string,
    navigate: PropTypes.func.isRequired,
    isHookedToARoute: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      path: '',
      unitTitle: '',
      errors: {},
      addPortfolioRadio: null,
      selectedFile: {
        title: '',
        description: '',
        file: ''
      },
      files: [],
      isClaimBadgeSaved: false,
      showCustomDialog: false,
      backHasBeenClicked: false,
      nextLocation: null,
      feedbacks: this.props.feedbacks,
      evidenceGuidelinesTooltipOpen: false,
      statementGuidelinesTooltipOpen: false,
      issuedBadge: Object.assign({}, this.props.issuedBadge),
      isValidFeedback: false
    };

    this.issueBadge = this.issueBadge.bind(this);
    this.onChangeFeedback = this.onChangeFeedback.bind(this);
    this.closeCustomDialog = this.closeCustomDialog.bind(this);
    this.onConfirmLeaveRoute = this.onConfirmLeaveRoute.bind(this);
  }

  componentWillMount(){
    const { params, isAuthenticated, actions, unit, badge } = this.props;

    if(!isAuthenticated){
      const redirectPath = 'skill-builders';
      toastr.error('Permission is Needed.', 'You must be signed in to claim a badge.');
      return actions.redirect(`/${redirectPath}/${params.slug}`);
    }

    if (!isEmpty(unit)) {
      const unitTitle = `${unit.code} ${unit.title}`;

      this.setState({ unitTitle });
    }

    if (! isEmpty(badge)) {
      this.isValidFeedback();
      this.getFiles(badge).then(files => this.setState({ files }));
    }
  }

  componentDidMount() {
    const { unit, actions } = this.props;

    if (isEmpty(unit)) {
      toastr.error('Guided Claim Badge Process.', 'You must begin the process from step 1.');
    }

    actions.setRouterHookedToARoute('claim-badge-survey');
  }

  componentWillReceiveProps(nextprops) {
    if (!isEmpty(nextprops.unit) && nextprops.unit !== this.props.unit) {
      const unitTitle = `${nextprops.unit.code} ${nextprops.unit.title}`;

      this.setState({
        unitTitle,
        path: nextprops.location.pathname
      });
    }

    if(!isEmpty(nextprops.unit) && nextprops.unit.badge.issued !== this.props.unit.badge.issued && nextprops.unit.badge.isClaimed){
      this.props.navigate(`/skill-badges/badge-details/${nextprops.unit.badge.issued.id}`);
    }

    if (! isEmpty(nextprops.badge) && (! isEqual(nextprops.badge, this.props.badge))) {
      this.isValidFeedback();
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = () => {
      return null;
    };
    this.props.actions.removeRouterHookedToARoute();
  }

  closeCustomDialog(){
    this.setState({
      showCustomDialog: false,
      nextLocation: null
    }, () => {
      if(this.props.storedAction !== null){
        this.props.actions.clearAllActionsStored();
      }
    });
  }

  onConfirmLeaveRoute(){
    const { actions, storedAction } = this.props;
    const { nextLocation } = this.state;
    this.setState({
      showCustomDialog: false
    },() => {
      actions.removeRouterHookedToARoute();
      if(storedAction === 'userLogout'){
        actions.userLogout();
      }
      actions.redirect(nextLocation);
      actions.clearAllActionsStored();
    });
  }

  isValid(field = null) {
    const rules = {
      title: ['required', 'minLength|1'],
      description: ['required', 'minLength|1', 'maxLength|100']
    };
    const { selectedFile } = this.state;

    const validate = Validator.createValidator(rules, selectedFile, field);
    const { errors, isValid } = validate;

    this.setState({ errors });

    return isValid;
  }

  isValidStatement(field = null) {
    const rules = {
      statement: ['required', 'minWords|50', 'maxWords|500']
    };
    const { editorText } = this.state;

    const validate = Validator.createValidator(rules, editorText, field);
    const { errors, isValid } = validate;

    this.setState({ errors });

    return isValid;
  }

  toggleShareAndVerifyCheckboxes(event) {
    const { name } = event.target;
    const { [name]: value } = this.state.issuedBadge;
    const issuedBadge = Object.assign({}, this.state.issuedBadge, {
      [name]: ! value
    });

    this.setState({ issuedBadge });
  }

  getIssuedBadge(issuedBadge) {
    let formData = new FormData();

    for (const prop in issuedBadge) {
      const propertyValue = issuedBadge[prop];

      if (Array.isArray(propertyValue)) {
        formData = formatFormDataArray(formData, prop, propertyValue);
        continue;
      }

      formData.append(`${prop}`, propertyValue);
    }

    return formData;
  }

  isValidJustification(justification) {
    const rules = {
      justification: ['required', 'minWords|50', `maxWords|${justification.question.wordLimit}`]
    };

    const validate = Validator.createValidator(rules, justification, 'justification');
    const { errors } = validate;

    this.setState({ errors });

    return validate;
  }

  isValidClaimBadgeForm() {
    const { unit, badge } = this.props;
    const { verified, feedbacks, acknowledgement } = this.state.issuedBadge;
    let justificationErrors = [];

    const isValidAcknowledgement = (acknowledgement.length === unit.acknowledgement.length);

    if (! isValidAcknowledgement) {
      toastr.error('Acknowledge Abilities', 'You need to posses all the abilities to earn this badge.');
      return;
    }

    badge.justifications.forEach((justification) => {
      const justificationData = (justification.answer.editorPlainText) ? justification.answer.editorPlainText : '';
      const justificationObj = { justification: justificationData, question: justification.question };
      let { errors, isValid } = this.isValidJustification(justificationObj);

      if (! isValid) {
        errors['questionId'] = justification.question.id;

        justificationErrors = [...justificationErrors, errors];
      }
    });

    if (justificationErrors.length !== 0) {
      toastr.error('Justify Your Skill Claim', 'All justificaton questions must be answered to claim this badge.');

      return;
    }

    const feedbackArray = filter(feedbacks, feedbackObj =>
      ((feedbackObj.choices.length !== 0) && feedbackObj.feedback !== ''));

    const isValidFeedback = (feedbackArray.length === (feedbacks.length - 1));

    if (! isValidFeedback) {
      toastr.error('Give Us Your Feedback', 'Please provide feedback for all the required fields.');

      return;
    }

    if (! verified) {
      toastr.error('You must verify your acknowledgement.');

      return;
    }

    const isValidClaimBadge = (justificationErrors.length === 0) && isValidFeedback && isValidAcknowledgement && verified;

    return isValidClaimBadge;
  }

  async getFiles(badge) {
    const filesPromises = badge.files.map(async (fileObject) => {
      if (!fileObject.file) return fileObject;

      const file = await this.getFileFromUrl(fileObject);

      return {
        ...fileObject,
        file
      };
    });

    return await Promise.all(filesPromises).then(files => files);
  }

  getFileFromUrl(fileObject) {
    try {
      const response = axios({
        method: 'get',
        url: fileObject.file,
        responseType: 'blob'
      }).then(r => new File([r.data], fileObject.filename, { type: fileObject.type }));

      return response;
    } catch (error) {
      toastr.error(error);
    }
  }

  issueBadge() {
    const { issuedBadge } = this.state;
    const { unit, badge, badges, actions, currentUser, isAuthenticated } = this.props;

    if (!isAuthenticated) {
      toastr.error('Nonauthorized', 'Please login/signup to claim this badge');
      return;
    }

    if (! this.isValidClaimBadgeForm()) return;

    this.setState({ isClaimBadgeSaved: true });

    const data = {
      'user': currentUser.id,
      'badge': unit.badge.id,
      ...Object.assign({}, issuedBadge, {
        feedbacks: issuedBadge.feedbacks.map((feed) => {
          const { feedback, question } = feed;

          return {
            question,
            feedback
          };
        }),

        justifications: badge.justifications.map((justification) => {
          const { answer, question } = justification;

          return {
            question: question.id,
            answer: answer.answerText
          };
        }),

        files: this.state.files.filter(file => file.id === undefined),
        ...(badge.selectedFiles && { selectedFiles: badge.selectedFiles })
      })
    };

    const formData = this.getIssuedBadge(data);
    const badgeIndex = badges.findIndex(badge => badge.id === unit.badge.id);

    actions.removeRouterHookedToARoute();
    actions.issueBadge(formData, badgeIndex).then(async () => {
      if (issuedBadge.sharePost) {
        const postData = new FormData();

        postData.append('post', issuedBadge.statement);
        postData.append('unit', unit.id);

        actions.saveUnitDiscussion(postData);
      }
      await actions.updateMyCareerPreppedStatus();
      actions.getMyCareerPreppedStatus();
    });
  }

  isValidFeedback() {
    const { feedbacks } = this.state.issuedBadge;
    const feedbackArray = filter(feedbacks, feedbackObj =>
      ((feedbackObj.choices.length !== 0) && feedbackObj.feedback !== ''));

    const isValidFeedback = (feedbackArray.length === (feedbacks.length - 1));

    this.setState({ isValidFeedback });
  }

  onChangeFeedback(event){
    const { value, name } = event.target;

    let { feedbacks, issuedBadge } = this.state;

    const indexOfFeedback = issuedBadge.feedbacks.findIndex(feedback => feedback.question === name);

    const newFeedbackObject = Object.assign({},
      issuedBadge.feedbacks[indexOfFeedback], {
        feedback: value
      });

    feedbacks = [
      ...feedbacks.slice(0, indexOfFeedback),
      newFeedbackObject,
      ...feedbacks.slice(indexOfFeedback + 1)
    ];

    issuedBadge = Object.assign({}, this.state.issuedBadge, {
      feedbacks: [
        ...issuedBadge.feedbacks.slice(0, indexOfFeedback),
        newFeedbackObject,
        ...issuedBadge.feedbacks.slice(indexOfFeedback+1)
      ]
    });

    this.setState({ issuedBadge, feedbacks }, () => this.isValidFeedback());

    this.props.actions.updateUnitBadgeOnLocalStore(issuedBadge, this.props.unit.id);
  }

  render() {
    const {
      unitTitle,
      path,
      issuedBadge,
      showCustomDialog
    } = this.state;

    const {
      unit,
      badge,
      isAuthenticated,
      isRequestingUnit,
      params,
      isHookedToARoute
    } = this.props;

    const buttonClassName = classNames('btn btn-primary btn-xlg',
      { 'claimed-badge-button': badge && badge.isClaimed }
    );

    let buttonText = 'Get Badge';

    if (this.props.badge && this.props.badge.isClaimed) {
      buttonText = 'Claimed';
    }

    if (this.props.isIssuingBadge) {
      buttonText = 'Getting Badge...';
    }

    if (isEmpty(unit)) {
      const redirectURL = `/skill-builders/claim-badge/${params.slug}`;

      return <Navigate to={redirectURL} />;
    }

    return (
      <div>
        <Helmet
          title={`Skill Builders - Claim Badge Survey - ${unit.code} ${unit.title}`}/>

        <div className="container">
          <div className="row" style={{ marginTop: 30 }}>
            <StickyDiv
              component={Header}
              startId={'essHeader'}
              endId={
                Object.keys(unit.badge).length === 0 ?
                  'warning-card' : 'claim-badge'
              }
              defaultCss={'intro-header container'}
              unit={unit}
              slug={params.slug}
              unitTitle={unitTitle}
              path={path}
              isAuthenticated={isAuthenticated}
              isRequesting={isRequestingUnit}/>

            <Prompt
              when={isHookedToARoute.status}
              message="Are you sure you want to leave this page? Any changes may be unsaved."/>
            <div className="col-lg-12">
              <div className="essential-wrapper">
                <div className="content" style={{marginBottom: 0}}>
                  <BadgeFeedback
                    category={unit.category}
                    onChangeFeedback={this.onChangeFeedback}
                    feedbackArray={issuedBadge.feedbacks}/>

                  <div className="mt-2 text-center">
                    <button
                      style={{marginTop: 20}}
                      type="text"
                      disabled={(!this.state.isValidFeedback) || this.props.isIssuingBadge}
                      className={buttonClassName}
                      onClick={this.issueBadge}>
                      {buttonText}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showCustomDialog &&
          <Modal
            size="lg"
            className="modal-margin-top"
            isOpen={showCustomDialog}
            backdrop="static"
            toggle={this.closeCustomDialog}>
            <ModalHeader
              toggle={this.closeCustomDialog}>Leave this page?</ModalHeader>
            <ModalBody>
              <p>Are you sure you want to leave this page? Any changes may be unsaved.</p>
            </ModalBody>
            <ModalFooter>
              <button
                onClick={this.closeCustomDialog}
                className="btn btn-secondary">
                Stay
              </button>

              <button
                onClick={this.onConfirmLeaveRoute}
                className="btn btn-primary">
                Yes, Leave
              </button>
            </ModalFooter>
          </Modal>}
      </div>
    );
  }
}

const formatUnit = (unit) => {
  if (!isEmpty(unit)) {
    return {
      ...unit,
      acknowledgement: getAcknowledgement(unit.acknowledgement)
    };
  }

  return {};
};

const getAcknowledgement = (ack) => {
  return ack === '' ? [] : parseJSON(ack);
};

const mapStateToProps = (state) => {
  let { unit } = {};

  let feedbacks = [];

  if (! isEmpty(state.questionnaires.data)) {
    feedbacks = state.questionnaires.data.map((question) => {
      const { choices, id, question: text } = question;
      let feedback = '';

      if (! isUndefined(state.skillBuilders.unit.badge.feedbacks)) {
        const feedbackElement = state.skillBuilders.unit.badge.feedbacks.find(element => element.question === id);

        if (! isUndefined(feedbackElement)) {
          feedback = feedbackElement.feedback;
        }
      }

      return Object.assign({}, {
        question: id,
        feedback,
        questionText: text,
        choices: choices === '' ? [] :parseJSON(choices)
      });
    });

  }

  const issuedBadge = Object.assign({}, state.skillBuilders.unit.badge, { feedbacks });

  unit = formatUnit(state.skillBuilders.unit);

  return {
    unit,
    feedbacks,
    issuedBadge,
    badge: unit.badge,
    badges: state.badges.data,
    profile: state.profile.data,
    currentUser: state.auth.data.currentUser,
    isAuthenticated: state.auth.isAuthenticated,
    isIssuingBadge: state.badges.isIssuingBadge.status,
    isRequestingQuestionaires: state.questionnaires.isRequesting,
    isRequestingUnit: state.skillBuilders.isRequestingUnit,
    storedAction: state.router.storedAction,
    isHookedToARoute: state.router.isHookedToARoute
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...badgeActions,
      ...discussionActions,
      ...skillBuildersActions,
      ...questionnairesActions,
      ...routerActions,
      updateMyCareerPreppedStatus,
      getMyCareerPreppedStatus,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ClaimBadgeSurvey));
