import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as componentActions from '../../redux/actions/components';
import * as wallPostActions from '../../redux/actions/wall-posts';
import * as authActions from '../../redux/actions/authentication';
import TextArea from '../../components/common/TextArea';
import Validator from '../../validator';
import { Dropdown, DropdownToggle, DropdownMenu, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Button from '../../components/common/Button';

const visibilityOptionsArray = [
  {
    title:'Everyone (Public)',
    value:'1'
  },
  {
    title:'Followers Only',
    value:'2'
  },
  {
    title:'Only Me',
    value:'0'
  },
];


class ShareBadgeModal extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    shareData: PropTypes.object.isRequired,
    badgeAssertion: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool,
    isAuthenticating: PropTypes.bool
  }

  constructor(props, context) {
    super(props, context);

    this.state ={
      errors: {},
      visibilityDropdownOpen: false,
      shareData: Object.assign({}, this.props.shareData)
    };

    this.onCloseModal = this.onCloseModal.bind(this);
    this.onShare = this.onShare.bind(this);
    this.onChangeShareData = this.onChangeShareData.bind(this);
    this.onToggleVisibilityDropdown = this.onToggleVisibilityDropdown.bind(this);
    this.onSelectVisibilty = this.onSelectVisibilty.bind(this);
  }

  isValid(field = null) {
    const { shareData, errors: prevErrors } = this.state;

    let validate = Validator.createValidator({
      email: ['required', 'email'],
      password: ['required', 'minLength|8']
    }, shareData, field);

    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  onChangeShareData(event) {
    let { value, name } = event.target;
    const { shareData } = this.state;

    shareData[name] = value;

    this.setState({ shareData }, () => this.isValid(name));
  }

  onToggleVisibilityDropdown() {
    const { visibilityDropdownOpen } = this.state;
    this.setState({
      visibilityDropdownOpen: !visibilityDropdownOpen
    });
  }

  onSelectVisibilty(id){

    return () => {
      const shareData = { ...this.state.shareData, permissions:id};

      this.setState({
        shareData,
        visibilityDropdownOpen: false
      });
    };
  }

  onCloseModal(){
    this.props.actions.closeModal();
  }

  onShare(){
    const { shareData } = this.state;
    const { isAuthenticated, actions, badgeAssertion } = this.props;
    const { badge, issued } = badgeAssertion;

    const data = {
      'post': `${badge.name} : ${badge.description}`,
      'permissions': shareData.permissions,
      'sharedBadgeIssued': issued.id,
      'sharedQuotedText': shareData.quote
    };

    if (!isAuthenticated) {
      const credential = {
        'email': shareData.email,
        'password': shareData.password
      };

      if(!this.isValid())
        return;

      return actions
        .authenticateUser(credential)
        .then(() =>
          actions
            .saveWallPost(data)
            .then(() => this.props.actions.closeModal())
        );
    }

    actions.saveWallPost(data).
      then(() => this.props.actions.closeModal());
  }

  render() {
    const { visibilityDropdownOpen, shareData, errors } = this.state;
    const { isOpen, badgeAssertion, isSubmitting, isAuthenticated, isAuthenticating } = this.props;
    const { name: userName } = badgeAssertion.user;
    const { name, image } = badgeAssertion.badge;
    const dropdownButton = visibilityOptionsArray.find(option =>
      option.value == shareData.permissions);

    return (
      <Modal
        backdrop="static"
        size="md"
        className="modal-margin-top"
        isOpen={isOpen}
        toggle={this.onCloseModal}>
        <ModalHeader
          className="share-modal"
          toggle={this.onCloseModal}>
          Share Badge
        </ModalHeader>
        <ModalBody className="content experience">
          <div className="shared-item">
            <TextArea
              name="quote"
              type="text"
              rows="3"
              value={shareData.quote}
              onChange={this.onChangeShareData}
              placeholder="Say something about this badge"/>
            <div>
              <div className="shared-item-container">
                <div className="shared-item-image">
                  <img src={image}/>
                </div>
                <div className="shared-item-description">
                  <h6>{name}</h6>
                  <div className="description">
                    <p><strong>{userName}</strong> got the <strong>{name}</strong> Skill Badge on CareerPrepped. Click to see details and evidence for this Skill Badge.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            !isAuthenticated &&
              <div className="login-form-share-badge row">
                <div className="form-group col-sm-12 col-md-6 col-lg-6">
                  <label className="sr-only">Email</label>
                  <input
                    type="text"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    value={shareData.email}
                    onChange={this.onChangeShareData}/>
                  {errors.email && <div className="text-danger">{errors.email}</div>}
                </div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6">
                  <label className="sr-only">Password</label>
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                    value={shareData.password}
                    onChange={this.onChangeShareData}/>
                  {errors.password && <div className="text-danger">{errors.password}</div>}
                </div>
              </div>
          }
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            disabled={isSubmitting || isAuthenticating}
            onClick={this.onCloseModal}
            buttonClass="btn-secondary"
            buttonText="Cancel"/>
          <Dropdown
            className="dropdown"
            isOpen={visibilityDropdownOpen}
            toggle={this.onToggleVisibilityDropdown}>
            <DropdownToggle
              caret
              color="tertiary"
              className="btn btn-tertiary activity-filter-button share-visibilty">
              {dropdownButton == null ? 'Who can see this post' : dropdownButton.title}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu">
              {visibilityOptionsArray.map((option,index) => {
                return (
                  <a className="dropdown-item" key={index} onClick={this.onSelectVisibilty(option.value)}>
                    {option.title}
                  </a>
                );
              })}
            </DropdownMenu>
          </Dropdown>
          <Button
            type="submit"
            disabled={isSubmitting || isAuthenticating}
            onClick={this.onShare}
            buttonClass="btn-primary"
            buttonText={isSubmitting || isAuthenticating ? 'Sharing...' :
              isAuthenticated ? 'Share': 'Login and Share'}/>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  let shareData = {
    email: '',
    password: '',
    quote: '',
    permissions: '1'
  };

  return {
    shareData,
    isSubmitting: state.wallPosts.isSubmitting,
    isAuthenticating: state.auth.isRequesting,
    isAuthenticated: state.auth.isAuthenticated
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({},
    componentActions,
    wallPostActions,
    authActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(ShareBadgeModal);
