import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'reactstrap';

const ToggleAdvancedFilterButton = ({ toggleAdvancedFilter }) => {
  return (
    <Button
      color="secondary"
      onClick={toggleAdvancedFilter}>
      Advanced Filters
    </Button>
  );
};

ToggleAdvancedFilterButton.propTypes = {
  toggleAdvancedFilter: PropTypes.func.isRequired,
};

export default ToggleAdvancedFilterButton;
