import PropTypes from 'prop-types';
import React from 'react';
import Timestamp from './Timestamp';
import ReplyActions from '../../../containers/skill-builders/discussions/ReplyActions';
import ReplyLikeButton from '../../../containers/skill-builders/discussions/ReplyLikeButton';
import PostItem from '../../common/PostItem';
import DiscussionAvatar from './DiscussionAvatar';
import ReplyButton from '../../../containers/skill-builders/discussions/ReplyButton';

const ReplyCardComponents = ({
  reply,
  profile,
  showMore,
  onshowMore,
  currentUser,
  dashboard,
  searchPage
}) => {
  return(
    <div className="tertiary-post">
      <DiscussionAvatar user={reply.user}/>

      <div className="right">
        {((currentUser.id === reply.user.id) || (profile.role === 'super-admin')) &&
          <ReplyActions
            searchPage={searchPage}
            reply={reply}
            dashboard={dashboard}/>}

        <div className="profile-title">
          <PostItem
            wallPost={reply}
            showMore={showMore}
            onshowMore={onshowMore}
            secondary/>

          <div className="share-stats">
            <ReplyLikeButton reply={reply} discussionReply dashboard={dashboard}/>

            <ReplyButton commentId={reply.parent}/>

            <span style={{paddingRight: 5}}>{reply.likes} Likes</span>

            <span style={{paddingRight: 5}}>
              <Timestamp postedAt={reply.posted.date}/>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

ReplyCardComponents.propTypes = {
  reply: PropTypes.object,
  onshowMore: PropTypes.func.isRequired,
  showMore: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  searchPage: PropTypes.bool,
  dashboard: PropTypes.bool,
  profile: PropTypes.object.isRequired
};

export default ReplyCardComponents;
