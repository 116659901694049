import PropTypes from 'prop-types';
import React from 'react';
import _capitalize from 'lodash/capitalize';
import { Link } from 'react-router-dom';

let sharedPostOwnerPronoun;

const SharedPostOwnerText = ({ discussionPost, postType }) => {
  const sharedPostType = (postType === 'discussion') ? 'post' : postType;

  if(discussionPost.sharedEntityType === 'discussion' || discussionPost.sharedEntityType === 'file'){
    const sharedPostKey = `shared${_capitalize(discussionPost.sharedEntityType)}`;

    const userName = discussionPost[sharedPostKey].user.name;

    sharedPostOwnerPronoun = discussionPost.user.name === userName ?
      <span>their own</span> :
      (<span>
        <Link
          className="profile-name"
          to={`/cp/${discussionPost[sharedPostKey].user.vanity}`}
          target="_blank"
          title={`Link to ${userName}’s Career Site`}>
          {userName}
        </Link>'s
      </span>);
  } else sharedPostOwnerPronoun = 'a';

  return (
    <span className="shared-type-text">
      &nbsp;shared {sharedPostOwnerPronoun}{` ${sharedPostType}`}
    </span>
  );
};

SharedPostOwnerText.propTypes = {
  discussionPost: PropTypes.object.isRequired,
  postType: PropTypes.string.isRequired,
};

export default SharedPostOwnerText;
