import moment from 'moment';

import initialState from './initialState';

import {
  NOTIFICATIONS_REQUEST,
  NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_FAILURE,
  UPDATE_NOTIFICATION_REQUEST,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAILURE,
  DELETE_NOTIFICATION_REQUEST,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAILURE,
  TOGGLE_NOTIFICATIONS_DROPDOWN,
  MARK_NOTIFICATION_AS_READ_REQUEST,
  MARK_NOTIFICATION_AS_READ_SUCCESS,
  MARK_NOTIFICATION_AS_READ_FAILURE,
  SET_NOTIFICATIONS_COUNT,
  SET_SELECTED_NOTIFICATION,
  RESET_SELECTED_NOTIFICATION,
  NOTIFICATIONS_MARK_ALL_READ_REQUEST,
  NOTIFICATIONS_MARK_ALL_READ_SUCCESS,
  NOTIFICATIONS_MARK_ALL_READ_FAILURE,
  NOTIFICATIONS_REMOVE_ALL_REQUEST,
  NOTIFICATIONS_REMOVE_ALL_SUCCESS,
  NOTIFICATIONS_REMOVE_ALL_FAILURE,
} from '../../constants';

export default function reducer(state = initialState.notifications, action) {
  switch (action.type) {
  case TOGGLE_NOTIFICATIONS_DROPDOWN:
    return Object.assign({}, state, {
      isNotificationDropdownOpen: !state.isNotificationDropdownOpen,
    });

  case SET_SELECTED_NOTIFICATION:
    return Object.assign({}, state, {
      selectedNotificationId: action.selectedNotificationId,
    });

  case RESET_SELECTED_NOTIFICATION:
    return Object.assign({}, state, { selectedNotificationId: null });

  case SET_NOTIFICATIONS_COUNT:
    return Object.assign({}, state, {
      unreadCount: action.data.notificationsCount,
    });

  case NOTIFICATIONS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case NOTIFICATIONS_SUCCESS:
    return Object.assign({}, state, {
      data: action.data._embedded.feed,
      isRequesting: false,
      error: {},
    });

  case NOTIFICATIONS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case DELETE_NOTIFICATION_REQUEST:
    return Object.assign({}, state, {
      isDeleting: { status: true, notificationId: action.id },
    });

  case DELETE_NOTIFICATION_SUCCESS: {
    const dataAfterDeleting = state.data.filter(
      item => item.id !== state.isDeleting.notificationId
    );

    return Object.assign({}, state, {
      data: dataAfterDeleting,
      isDeleting: { status: false, notificationId: null },
      error: {},
      unreadCount: dataAfterDeleting.filter(item => item.readAt === null)
        .length,
    });
  }

  case DELETE_NOTIFICATION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isDeleting: { status: false, notificationId: null },
    });

  case UPDATE_NOTIFICATION_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, index: action.index },
    });

  case UPDATE_NOTIFICATION_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, state.isUpdating.index),
        Object.assign({}, action.data),
        ...state.data.slice(state.isUpdating.index + 1),
      ],
      isUpdating: { status: false, index: null },
    });

  case UPDATE_NOTIFICATION_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false, index: null },
    });

  case MARK_NOTIFICATION_AS_READ_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true, notificationId: action.id },
    });

  case MARK_NOTIFICATION_AS_READ_SUCCESS: {
    const index = state.data.findIndex(
      item => item.id === state.isUpdating.notificationId
    );

    return Object.assign({}, state, {
      data: [
        ...state.data.slice(0, index),
        Object.assign({}, action.data),
        ...state.data.slice(index + 1),
      ],
      isUpdating: { status: false, notificationId: null },
      selectedNotificationId: null,
      error: {},
    });
  }

  case MARK_NOTIFICATION_AS_READ_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false, notificationId: null },
    });

  case NOTIFICATIONS_MARK_ALL_READ_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true },
    });

  case NOTIFICATIONS_MARK_ALL_READ_SUCCESS:
    return Object.assign({}, state, {
      data: [
        ...state.data.map(notification =>
          Object.assign({}, notification, { readAt: moment() })
        ),
      ],
      isUpdating: { status: false },
      unreadCount: 0,
    });

  case NOTIFICATIONS_MARK_ALL_READ_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false },
    });

  case NOTIFICATIONS_REMOVE_ALL_REQUEST:
    return Object.assign({}, state, {
      isUpdating: { status: true },
    });

  case NOTIFICATIONS_REMOVE_ALL_SUCCESS:
    return Object.assign({}, state, initialState.notifications);

  case NOTIFICATIONS_REMOVE_ALL_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isUpdating: { status: false },
    });

  default:
    return state;
  }
}
