import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

const DeleteResumeModal = ({ resumeId, onCancel, onConfirm, isDeleting, isOpen}) => {
  const { status, id } = isDeleting;
  const isDeletingFile = status && resumeId === id;

  if(isDeletingFile){
    return (
      <Modal
        size="lg"
        className="modal-margin-top"
        isOpen={isOpen}
        backdrop="static"
        toggle={onCancel}>
        <ModalBody>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <span>
              <i className="fa fa-spinner fa-pulse fa-2x fa-fw" />
            </span>
            <span style={{ paddingLeft: 10}}>
              Deleting Resume
            </span>
          </div>
        </ModalBody>
      </Modal>);
  }
  return(
    <Modal
      size="lg"
      className="modal-margin-top"
      backdrop="static"
      isOpen={isOpen}
      toggle={onCancel}>
      <ModalHeader
        className="no-border"
        toggle={onCancel}>Delete Resume</ModalHeader>
      <ModalBody>
        <p>
          Are you sure you want to delete this resume permanently?
        </p>
      </ModalBody>
      <ModalFooter className="no-border">
        <button
          onClick={onCancel}
          className="btn btn-secondary">
          Cancel
        </button>

        <button
          onClick={onConfirm}
          className="btn btn-danger">
          Delete
        </button>
      </ModalFooter>
    </Modal>
  );
};

DeleteResumeModal.propTypes = {
  resumeId: PropTypes.string,
  isDeleting: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default DeleteResumeModal;
