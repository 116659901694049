import React, { Component } from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import GlobalCompetenceModulesList from '../../components/toolbox/GlobalCompetenceModulesList';

const sponsoredBrandDigitalPromise = require('../../assets/images/dp-logo.png');
const sponsoredBrandECMC = require('../../assets/images/ecmc-logo.png');

class GlobalCompetenceModules extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <div className="general-interior">
        <Helmet title="Global Competence Modules"/>
        <div className="content subnav essential">
          <div className="bread">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/resources">Career Resources</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Global Competence Modules</li>
            </ol>
          </div>
        </div>

        <div className="content targeted-resume">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="mb-1">Global Competence Modules</h2>
              <p>Learn employability skills in demand for any career field and explore the international connections of your specific pathway.</p>
              <p>These training modules have been designed for high school and postsecondary  students in Career and Technical Education (CTE) programs. However, the training is useful for anyone seeking to enhance their global employability skills. Each module is self-paced and takes about 15-20 minutes to complete.</p>
              <h6>Learning Objectives:</h6>
              <ul>
                <li>Identify and articulate the skills of global competence and global career ready practices</li>
                <li>Apply research skills to explore global readiness within your career field</li>
                <li>Analyze personal global readiness in your career field</li>
                <li>Explore application of global competence within domestic and international CTE career fields</li>
              </ul>
              <div className="sponsored__wrapper">
                <img src={sponsoredBrandDigitalPromise} />
                <img src={sponsoredBrandECMC} />
                <p className="sponsored__text">Sponsored by <a target="_blank" href="https://digitalpromise.org/">Digital Promise</a> through generous support from <a target="_blank" href="https://ecmcfoundation.org/">ECMC Foundation</a>.</p>
              </div>
            </div>
          </div>
          <div id="prep-videos" className="row">
            <div className="mt-2 mb-2">
              <GlobalCompetenceModulesList />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GlobalCompetenceModules;
