import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'reactstrap';

const PersonalityTraitsExampleModal = ({isOpen, closeModal}) => {
  return (
    <Modal isOpen={isOpen} toggle={closeModal} size={'lg'}>
      <div className="see-examples-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title" id="myModalLabel">Examples of Personality Traits</h6>
            <button type="button" className="close" onClick={closeModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="exmples-list">
              <p><strong>Honest:</strong> I keep my promises and am truthful</p>
              <p><strong>Courteous:</strong> I’m thoughtful of others</p>
              <p><strong>Responsible:</strong> I’m accountable and follow through on tasks</p>
              <p><strong>Cooperative:</strong> I work well with others</p>
              <p><strong>Loyal:</strong> I show devotion to people and/or things</p>
              <p><strong>Enthusiastic:</strong> I show eagerness and excitement in what I do</p>
              <p><strong>Open-minded:</strong> I’m receptive to others’ opinions and ideas</p>
              <p><strong>Self-controlled:</strong> I control my emotions and actions</p>
              <p><strong>Influential:</strong> I motivate or encourage others</p>
              <p><strong>Adaptive:</strong> I adapt when necessary and accept change</p>
              <p><strong>Industrious:</strong> I am consistently active</p>
              <p><strong>Careful:</strong> I give watchful attention to people and/or things</p>
              <p><strong>Self-reliant:</strong> I confidently do things myself</p>
              <p><strong>Compassionate:</strong> I empathize with the feelings of others</p>
              <p><strong>Dedicated:</strong> I devote time and energy to causes and/or goals</p>
              <p><strong>Competitive:</strong> I strive to win and be my best</p>
              <p><strong>Patient:</strong> I am able to wait and I take my time</p>
              <p><strong>Perfectionist:</strong> I aim for the highest degree of excellence</p>
              <p><strong>Courageous:</strong> I face danger or difficulties in spite of my fears</p>
              <p><strong>Decisive:</strong> I make decisions promptly and definitely</p>
              <p><strong>Driven:</strong> I have the energy to get things done</p>
              <p><strong>Perseverance:</strong> I persist despite difficulties or failures</p>
              <p><strong>Calmness:</strong> I am serene</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

PersonalityTraitsExampleModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func
};

export default PersonalityTraitsExampleModal;
