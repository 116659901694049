import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { parseJSON, uniqueId } from '../../../utils';
import { toastr } from 'react-redux-toastr';
import Validator from '../../../validator';

import * as sectionActions from '../../../redux/actions/sections';
import * as unitActions from '../../../redux/actions/skill-builders';
import * as componentsActions from '../../../redux/actions/components';

import VideoForm from '../../../components/skill-builders/admin/VideoForm';

class Video extends Component {
  static propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    formType: PropTypes.object,
    actions:PropTypes.object.isRequired,
    unit: PropTypes.object.isRequired,
    units: PropTypes.array.isRequired,
    sections: PropTypes.array.isRequired,
    section: PropTypes.object,
    isSubmitting: PropTypes.bool.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    isDeleting: PropTypes.bool.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      section: Object.assign({}, this.props.section)
    };

    this.updateSectionState = this.updateSectionState.bind(this);
    this.saveSection = this.saveSection.bind(this);
    this.deleteListItem = this.deleteListItem.bind(this);
    this.addListItem = this.addListItem.bind(this);
    this.deleteSection = this.deleteSection.bind(this);
  }

  componentDidMount() {
    window.onbeforeunload = () => {
      return 'Changes you made might get lost!';
    };
  }

  componentWillUnmount() {
    window.onbeforeunload = () => {
      return null;
    };
  }

  isValid(field = null) {
    let validate = Validator.createValidator({
      title: ['required', 'minLength|1'],
      url: ['required', 'url']
    },
    this.state.section,
    field
    );

    let { isValid, errors } = validate;

    this.setState({ errors });

    return isValid;
  }

  updateSectionState(event) {
    event.preventDefault();

    const field = event.target.name;
    const { section } = this.state;

    section[field] = event.target.value;

    this.setState({ section }, () => this.isValid(field));
  }

  formatData() {
    const { section } = this.state;
    const { title, url, sectiontype } = section;
    const content = JSON.stringify({ title, url });

    return {
      content,
      sectiontype,
      unit: this.props.unit.id
    };
  }

  saveSection(event) {
    event.preventDefault();

    if (! this.isValid()) return;

    const { unit, units, section, sections, actions } = this.props;
    const formId = event.target.parentElement.id;
    const formIdArray = formId.split('_');
    const formIndex = parseInt(formIdArray[1]);
    const sectionData = this.formatData();

    if (section.id) {
      sectionData.id = section.id;

      const sectionIndex = sections.findIndex(sectionItem =>
        sectionItem.id === section.id);

      return actions.updateSection(sectionData, sectionIndex)
        .then(() => {
          toastr.success('Section Updated.');
          actions.unloadUpdateForm(String(section.id));
        });
    }

    const unitIndex = units.findIndex(unitItem => unitItem.id === unit.id);

    actions.saveSection(sectionData, unit, unitIndex)
      .then(() => {
        toastr.success('Section saved.');
        actions.unloadSectionForm({ id: formIndex });
      });
  }

  addListItem(event) {
    event.preventDefault();

    let content = this.state.section.content;
    content.push({ itemId: uniqueId(), value: ''});

    const section = Object.assign({}, this.state.section, { content });

    this.setState({ section });
  }

  deleteListItem(event) {
    event.preventDefault();

    const { section } = this.state;
    const { content } = section;
    const itemId = event.target.closest('.row').id;

    const newSection = Object.assign({}, section, {
      content: content.filter(item => itemId !== item.itemId)
    });

    this.setState({ section: newSection });
  }

  deleteSection(event) {
    event.preventDefault();

    const { actions, section, unit, units } = this.props;

    actions.deleteSection(section.id)
      .then(() => {
        const sectionsArray = unit.sections.split(',');
        const sectionsList = [...sectionsArray.filter(id => id !== section.id)];

        const sectionsString = sectionsList.join();
        const unitData = Object.assign({}, unit);
        const unitIndex = units.findIndex(unitItem => unitItem.id === unit.id);

        unitData.sections = sectionsString;

        actions.updateUnit(unitData, unitIndex)
          .then(() => {
            toastr.success('Section deleted.');
            actions.unloadUpdateForm(String(section.id));
          });
      });
  }

  render() {
    const { errors, section } = this.state;
    const {
      isAdmin,
      onCancel,
      isSubmitting,
      isUpdating,
      formType,
      isDeleting
    } = this.props;

    return (
      <VideoForm
        isAdmin={isAdmin}
        errors={errors}
        section={section}
        onCancel={onCancel}
        isSubmitting={isSubmitting}
        isUpdating={isUpdating}
        isDeleting={isDeleting}
        formType={formType}
        onChange={this.updateSectionState}
        onSave={this.saveSection}
        onAddListItem={this.addListItem}
        onDeleteListItem={this.deleteListItem}
        onDelete={this.deleteSection}/>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let section = { sectiontype: 'youtube', title: '', url: '' };
  const { isSubmitting, isUpdating, isDeleting } = state.sections;

  if (ownProps.section) {
    let data = parseJSON(ownProps.section.content);

    section.id = ownProps.section.id;
    section.title = data.title;
    section.url = data.url;
  }

  return {
    section,
    unit: ownProps.unit,
    isSubmitting,
    units: state.skillBuilders.data,
    sections: state.sections.data,
    isUpdating: isUpdating.status,
    isDeleting: isDeleting.status
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign(
    {},
    unitActions,
    sectionActions,
    componentsActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Video);
