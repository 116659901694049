import React from 'react';
import PropTypes from 'prop-types';


const CompanyColumn = (props) => {
  const rowDetails = props.rowData;
  return (
    <td scope="row">{rowDetails.company}</td>
  );
};
  
CompanyColumn.propTypes = {
  rowData: PropTypes.object.isRequired,
};

export default CompanyColumn;