import React from 'react';

import PropTypes from 'prop-types';
import Parser from 'html-react-parser';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

const SeeTipsModal = ({
  isOpen,
  onToggle,
  justification
}) => {
  return(
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="lg"
      toggle={onToggle}>
      <ModalHeader
        className="share-modal"
        toggle={onToggle}>Tips for Evaluating this Response</ModalHeader>
      <ModalBody>
        <div>
          <div className="alert alert-warning">
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <i className="fal fa-2x fa-lightbulb-on text-orange mr-3" />
              <p className="mb-0">
                {justification.questionNumber === 1 ?
                  'Look for clear explanations of specific techniques, methods, or behaviors that demonstrate how the skill is applied to achieve positive results. See a simple example response below.' :
                  'Look for a specific example that clearly explains the situation for context, the task that needed to be done, the specific actions taken, and the positive results achieved.'}<br/>
                <a
                  className="underline"
                  href="https://help.careerprepped.com/help/tips-for-giving-feedback-on-skill-evidence"
                  target="_blank">
                  Get more tips
                </a>.
              </p>
            </div>
          </div>
          {Parser(justification.exampleResponse)}
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-secondary" onClick={onToggle}>Close</button>
      </ModalFooter>
    </Modal>
  );
};

SeeTipsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  justification: PropTypes.object.isRequired
};

export default SeeTipsModal;
