import { CALL_API } from '../../middlewares/api';

import {
  RESET_INDUSTRIES,
  INDUSTRIES_REQUEST,
  INDUSTRIES_SUCCESS,
  INDUSTRIES_FAILURE
} from '../../constants';

export const getCareerIndustries = (clusterId, page = 1, query = '') => {
  let endpoint = `user/career-clusters?cluster=${clusterId}&page=${page}`;

  if (query.length > 0) {
    endpoint = `${endpoint}&query=${query}`;
  }

  return {
    [CALL_API]: {
      endpoint,
      method: 'get',
      authenticated: true,
      types: [
        INDUSTRIES_REQUEST,
        INDUSTRIES_SUCCESS,
        INDUSTRIES_FAILURE
      ]
    }
  };
};

export const resetIndustries = () => ({ type: RESET_INDUSTRIES });
