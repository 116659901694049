import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

const NavbarAuthButtons = ({ header, toggleLoginModal}) => {
  const [,path,] = location.pathname.split('/');
  const inForOrgPage = path === 'for-organizations';

  return(
    <div>
      <div className="d-xs-block d-sm-none">
        <div>
          <button
            className="btn btn-homepage-signup btn-block"
            onClick={toggleLoginModal}>
            Sign In
          </button>
        </div>
        <div>
          {inForOrgPage ?
            <Link
              to="/"
              style={{color: '#fff'}}
              className="btn btn-tertiary btn-block">
              For Individuals
            </Link> :
            <Link
              to="/for-organizations"
              style={{color: '#fff'}}
              className="btn btn-tertiary btn-block">
              For Organizations
            </Link>}
        </div>
      </div>
      <ul
        className={classNames('nav d-none d-sm-flex', {
          'navbar-right': header
        })}>
        <li className="nav-item">
          <a
            style={{marginRight: 20}}
            onClick={toggleLoginModal}
            className="nav-link modal-link clickable">
            Sign In
          </a>
        </li>

        <li className="nav-item">
          {
            inForOrgPage ?
              <Link
                to="/"
                style={{color: '#fff'}}
                className="btn btn-tertiary btn-block">
                For Individuals
              </Link> :
              <Link
                to="/for-organizations"
                style={{color: '#fff'}}
                className="btn btn-tertiary btn-block">
                For Organizations
              </Link>
          }
        </li>
      </ul>
    </div>
  );
};

NavbarAuthButtons.propTypes = {
  toggleLoginModal: PropTypes.func.isRequired,
  header: PropTypes.bool
};

export default NavbarAuthButtons;
