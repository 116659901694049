import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toastr } from 'react-redux-toastr';

import TextInput from '../../components/common/TextInput';
import TextArea from '../../components/common/TextArea';
import Validator from '../../validator';

import * as jobBoardActions from '../../redux/actions/job-boards';
import * as componentsActions from '../../redux/actions/components';

class AddJob extends Component {
  static propTypes = {
    job: PropTypes.object,
    isSubmitting: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      job: Object.assign({}, this.props.job)
    };

    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onSave(){
    const { job } = this.state;

    if (! this.isValid()) {
      return ;
    }

    this.props.actions
      .postNewJob(job)
      .then(() => {
        this.onCancel();
        toastr.success('Job added successfully');
      });
  }

  onCancel(){
    this.props.actions
      .unloadForm('add-new-job-to-board');
  }

  isValid(field = null) {
    const rules = {
      title: ['required', 'minLength|1'],
      company: ['required', 'minLength|1'],
      source: ['minLength|1'],
      snippet: ['required', 'minLength|10'],
      url: ['unsafeurl'],
      cemail: ['email'],
      cnumber: ['phone']
    };
    const { job } = this.state;

    const validate = Validator.createValidator(rules, job, field);
    const { errors, isValid } = validate;

    this.setState({ errors });

    return isValid;
  }

  onChange(event){
    const { name, value } = event.target;
    const { job } = this.state;

    job[name] = value;

    this.setState({ job });

    this.isValid(name);
  }
  render() {

    const { job, errors } = this.state;
    const { isSubmitting } = this.props;

    return (
      <div className="card p-3">
        <div className="card-block add-job-form">
          <form>
            <div className="row form-group">
              <div className="col-sm-6">
                <TextInput
                  error={errors.title}
                  name="title"
                  placeholder="Title"
                  onChange={this.onChange}
                  value={job.title}/>
              </div>
              <div className="col-sm-6">
                <TextInput
                  error={errors.company}
                  name="company"
                  placeholder="Company"
                  onChange={this.onChange}
                  value={job.company}/>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-6">
                <TextInput
                  error={errors.city}
                  name="city"
                  placeholder="City"
                  onChange={this.onChange}
                  value={job.city}/>
              </div>
              <div className="col-sm-6">
                <TextInput
                  error={errors.country}
                  name="country"
                  placeholder="Country"
                  onChange={this.onChange}
                  value={job.country}/>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-6">
                <TextInput
                  error={errors.source}
                  name="source"
                  placeholder="Source"
                  onChange={this.onChange}
                  value={job.source}/>
              </div>
              <div className="col-sm-6">
                <TextInput
                  error={errors.url}
                  name="url"
                  placeholder="Source url"
                  onChange={this.onChange}
                  value={job.url}/>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-12">
                <TextArea
                  name="snippet"
                  error={errors.snippet}
                  placeholder="A short job description"
                  rows="6"
                  cols=""
                  onChange={this.onChange}
                  value={job.snippet}/>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-4">
                <TextInput
                  error={errors.cname}
                  name="cname"
                  placeholder="Contact name"
                  onChange={this.onChange}
                  value={job.cname}/>
              </div>
              <div className="col-sm-4">
                <TextInput
                  error={errors.cemail}
                  name="cemail"
                  placeholder="Contact email"
                  onChange={this.onChange}
                  value={job.cemail}/>
              </div>
              <div className="col-sm-4">
                <TextInput
                  error={errors.cnumber}
                  name="cnumber"
                  placeholder="Contact number"
                  onChange={this.onChange}
                  value={job.cnumber}/>
              </div>
            </div>
            <div className="form-group text-right">
              <button
                type="button"
                name="button"
                className="btn btn-secondary"
                onClick={this.onCancel}>
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary ml-2"
                name="button"
                onClick={this.onSave}>
                {isSubmitting ? 'Adding ...' : 'Add'}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let job = {
    title: '',
    company: '',
    city: '',
    state: '',
    country: '',
    source: '',
    url: '',
    snippet: '',
    cname: '',
    cemail: '',
    cnumber: ''
  };

  const { isSubmitting } = state.jobBoards;

  return {
    job,
    isSubmitting
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, jobBoardActions, componentsActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddJob);
