import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as commentsActions from '../../../redux/actions/comments';
import * as discussionsActions from '../../../redux/actions/discussions';

import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import CommentCard from '../../../components/skill-builders/discussions/CommentCard';

class CommentCardContainer extends Component {
  static propTypes = {
    replies: PropTypes.array,
    actions: PropTypes.object,
    profile: PropTypes.object.isRequired,
    loadForms: PropTypes.array.isRequired,
    commentData: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    discussionId: PropTypes.string.isRequired,
    onCommentShowMore: PropTypes.func.isRequired,
    commentShowMore: PropTypes.object.isRequired,
    isDeleting: PropTypes.object.isRequired,
    replyData: PropTypes.array.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      isOpen: false,
    };

    this.onClickDelete = this.onClickDelete.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentWillMount() {
    const {
      commentData,
      actions,
      replies
    } = this.props;

    if (commentData.replies) {
      const isReplyAlreadyLoaded = replies.filter(
        item => item.id === commentData.replies[0].id
      ).length > 0;

      if (!isReplyAlreadyLoaded)
        actions.loadPreviousReplies(commentData.replies);
    }
  }

  onDelete() {
    const { actions, commentData, discussionId, replyData } = this.props;

    const commentsToDelete = replyData.filter(item => item.parent === commentData.id).length + 1;

    actions.deleteUnitDiscussionComment(commentData.id, discussionId)
      .then(() => {
        actions.decrementDiscussionCommentCount(discussionId, commentsToDelete, 'comment');
      });
  }

  toggleModal() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  onClickDelete(){
    this.setState({
      isOpen: true
    });
  }

  render() {
    const { isOpen } = this.state;

    const {
      profile,
      commentData,
      loadForms,
      discussionId,
      replies,
      currentUser,
      commentShowMore,
      onCommentShowMore,
      isDeleting
    } = this.props;

    const repliesToThisComment = replies.filter(reply =>
      reply.parent === commentData.id);

    return(
      <div>
        <CommentCard
          profile={profile}
          commentData={commentData}
          loadForms={loadForms}
          currentUser={currentUser}
          discussionId={discussionId}
          replies={repliesToThisComment}
          onClickDelete={this.onClickDelete}
          commentShowMore={commentShowMore}
          onCommentShowMore={onCommentShowMore}/>
        <Modal
          backdrop="static"
          className="modal-margin-top"
          isOpen={isOpen}
          toggle={this.toggleModal}>
          <ModalHeader
            toggle={this.toggleModal}>
            Delete Comment
          </ModalHeader>
          <ModalBody>
            Are you sure you want to permanently delete this comment? If you do, it cannot be undone.
          </ModalBody>
          <ModalFooter>
            <button
              onClick={this.toggleModal}
              className="btn btn-secondary">
              Cancel
            </button>

            <button
              onClick={this.onDelete}
              className="btn btn-danger"
              disabled={isDeleting.status}>
              {isDeleting.status ? 'Deleting...' : 'Permanently Delete'}
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.data,
    replies: state.comments.replyData,
    isDeleting: state.comments.isDeleting,
    currentUser: state.auth.data.currentUser,
    replyData: state.comments.replyData
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({},
    commentsActions,
    discussionsActions
  );

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentCardContainer);
