import initialState from './initialState';

import {
  INSTITUTIONS_REQUEST,
  INSTITUTIONS_SUCCESS,
  INSTITUTIONS_FAILURE,
  SET_SELECTED_INSTITUTION,
  UPDATE_INSTITUTION_GROUPS_COUNT,
} from '../../constants';

export default function reducer(state = initialState.institutions, action) {
  switch (action.type) {
  case INSTITUTIONS_REQUEST:
    return Object.assign({}, state, { isRequesting: true });

  case INSTITUTIONS_SUCCESS:
    return Object.assign({}, state, {
      data: action.data._embedded.institutions,
      selectedInstitution: action.data._embedded.institutions[0],
      isRequesting: false,
    });

  case INSTITUTIONS_FAILURE:
    return Object.assign({}, state, {
      error: action.error,
      isRequesting: false,
    });

  case SET_SELECTED_INSTITUTION:
    return Object.assign({}, state, {
      selectedInstitution: action.selectedInstitution,
    });

  case UPDATE_INSTITUTION_GROUPS_COUNT:
    return Object.assign({}, state, {
      ...state,
      selectedInstitution: {
        ...state.selectedInstitution,
        groupsCount: action.groupsCount,
      },
    });

  default:
    return state;
  }
}
