import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { withHooks } from '../../../utils/withHooks';

import Loading from '../../../components/common/Loading';
import ProfileJumbotron from '../../../components/profile/header/ProfileJumbotron';

import * as webpageActions from '../../../redux/actions/webpage';
import * as networksActions from '../../../redux/actions/networks';

import SkillBadges from '../../skill-badges/SkillBadges';
import RestrictedWebpageSection from '../../../components/profile/visiting/RestrictedWebpageSection';

class WebpageSkillBadges extends Component {
  static propTypes = {
    isRequesting: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    networks: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    params: PropTypes.object
  }

  componentWillMount(){
    const { params, actions, data, isAuthenticated } = this.props;

    if(!Object.keys(data).length > 0 || params.slug !== data.id )
      actions.webpageDetailsRequest(params.slug, isAuthenticated);
  }

  componentWillReceiveProps(nextProps){
    const { data } = this.props;
    const { isRequesting, data: nextData } = nextProps;

    if(data !== nextData && !isRequesting && nextData.isPrivacyVisible ){
      this.props.actions.userSocialLinksRequest(nextData.id);
    }
  }

  render() {
    const {
      isRequesting,
      data: webpageData,
      currentUser,
      networks
    } = this.props;

    if (isRequesting || Object.keys(webpageData).length === 0) {
      return (
        <div>
          <div className="loading-container">
            <Loading/>
          </div>
        </div>
      );
    }

    return (
      <div>
        <section id="profile">
          <ProfileJumbotron
            networks={networks}
            profile={webpageData}
            currentUser={currentUser}/>
        </section>
        <div className="container">
          <div className="row">
            {
              webpageData.isPrivacyVisible &&
                <div className="col-lg-12">
                  <div className="content badges">
                    <div className="row">
                      <div className="col-lg-12">
                        <SkillBadges profile={webpageData} isVisiting/>
                      </div>
                    </div>
                  </div>
                </div>
            }
            {
              !webpageData.isPrivacyVisible &&
                <RestrictedWebpageSection/>
            }
          </div>
        </div>
      </div>

    );
  }
}

const mapStateToProps = (state) => {
  const { webpage, auth, profile } = state;
  return {
    isRequesting: webpage.isRequesting,
    data: webpage.data,
    profile: profile.data,
    networks: state.networks,
    isAuthenticated: state.auth.isAuthenticated,
    currentUser: auth.data.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, webpageActions, networksActions);

  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(WebpageSkillBadges));
