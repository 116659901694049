import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withHooks } from '../../utils/withHooks';

import PropTypes from 'prop-types';

import { redirect } from '../../redux/actions/router';
import { openModal } from '../../redux/actions/components';
import { setNumberOfGroupsToCreate } from '../../redux/actions/organization-stats';

import { selectIsRecentEducatorAccount } from '../../redux/selectors/profile';

class AdminGroupAdd extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    children: PropTypes.array.isRequired,
    navigate: PropTypes.func.isRequired,
    isRecentEducatorAccount: PropTypes.bool.isRequired
  }

  onOpenModal = () => {
    const { actions, navigate, isRecentEducatorAccount } = this.props;

    if (isRecentEducatorAccount) {
      actions.setNumberOfGroupsToCreate(1);
      actions.redirect('/educator/groups/create');
      navigate('/educator/groups/create');
      return;
    }

    actions.openModal('admin-group-add');
  }

  render() {
    return (
      <span
        className="btn btn-sm ml-2 btn-primary"
        onClick={this.onOpenModal}>
        {this.props.children}
      </span>
    );
  }
}

const mapStateToProps = state =>
  ({ isRecentEducatorAccount: selectIsRecentEducatorAccount(state) });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ openModal, redirect, setNumberOfGroupsToCreate }, dispatch)
});

export default withHooks(connect(mapStateToProps, mapDispatchToProps)(AdminGroupAdd));
