import axios from 'axios';

import { CALL_API } from '../../middlewares/api';
import { sectionsReset, sectionsRequest } from './sections';
import { reflectionsRequest } from './reflections';

import {
  BASE_URL,
  RESET_UNIT,
  UNIT_REQUEST,
  UNIT_SUCCESS,
  UNIT_FAILURE,
  CREATE_UNIT_REQUEST,
  CREATE_UNIT_SUCCESS,
  CREATE_UNIT_FAILURE,
  UPDATE_UNIT_REQUEST,
  UPDATE_UNIT_SUCCESS,
  UPDATE_UNIT_FAILURE,
  DELETE_UNIT_REQUEST,
  DELETE_UNIT_SUCCESS,
  DELETE_UNIT_FAILURE,
  SKILL_BUILDERS_REQUEST,
  SKILL_BUILDERS_SUCCESS,
  SKILL_BUILDERS_FAILURE,
  ADD_UNIT_BADGE_REQUEST,
  ADD_UNIT_BADGE_SUCCESS,
  ADD_UNIT_BADGE_FAILURE,
  UPDATE_UNIT_BADGE_REQUEST,
  UPDATE_UNIT_BADGE_SUCCESS,
  UPDATE_UNIT_BADGE_FAILURE,
  DELETE_UNIT_BADGE_REQUEST,
  DELETE_UNIT_BADGE_SUCCESS,
  DELETE_UNIT_BADGE_FAILURE,
  UPDATE_UNIT_SECTIONS_ORDER,
  UPDATE_UNIT_BADGE_ON_LOCAL_STORE,
} from '../../constants';

export const resetUnit = () => {
  return { type: RESET_UNIT };
};

export const fetchUnitRequest = () => {
  return { type: UNIT_REQUEST };
};

export const fetchUnitSuccess = (data) => {
  return { type: UNIT_SUCCESS, data };
};

export const fetchUnitFailure = (payload) => {
  return { type: UNIT_FAILURE, payload };
};

export const updateUnitSectionsOrder = (data, unitIndex) => {
  return { type: UPDATE_UNIT_SECTIONS_ORDER, data, unitIndex };
};

export const updateUnitBadgeOnLocalStore = (data, unitId) => {
  return { type: UPDATE_UNIT_BADGE_ON_LOCAL_STORE, data, unitId };
};

export const skillBuildersRequest = (isAuthenticated = false) => {
  const endpoint = 'skillbuilder/unit';
  const token = localStorage.getItem('careerPrepped.auth.token') || null;

  return {
    [CALL_API]:
      isAuthenticated && token
        ? {
          endpoint,
          method: 'get',
          authenticated: true,
          types: [
            SKILL_BUILDERS_REQUEST,
            SKILL_BUILDERS_SUCCESS,
            SKILL_BUILDERS_FAILURE,
          ],
        }
        : {
          endpoint,
          method: 'get',
          types: [
            SKILL_BUILDERS_REQUEST,
            SKILL_BUILDERS_SUCCESS,
            SKILL_BUILDERS_FAILURE,
          ],
        },
  };
};

export const essentialsRequest = (unitIdOrSlug, isAuthenticated) => {
  const url = `${BASE_URL}skillbuilder/unit/${unitIdOrSlug}`;

  return function (dispatch) {
    dispatch(fetchUnitRequest());

    let token = localStorage.getItem('careerPrepped.auth.token') || null;

    const config = isAuthenticated
      ? { url, method: 'get', headers: { Authorization: `Bearer ${token}` } }
      : { url, method: 'get' };

    return axios(config)
      .then((response) => {
        const { data: responseData } = response;
        const { id, sectionsOrder } = responseData;

        if (id && isAuthenticated) dispatch(reflectionsRequest(id));

        dispatch(sectionsReset());

        if (sectionsOrder && sectionsOrder.length > 0)
          dispatch(sectionsRequest(sectionsOrder));

        dispatch(fetchUnitSuccess(responseData));
      })
      .catch((error) => {
        dispatch(fetchUnitFailure(error));
      });
  };
};

export const unitRequest = (unitIdOrSlug, isAuthenticated) => {
  const endpoint = `skillbuilder/unit/${unitIdOrSlug}`;

  let apiProps = {
    endpoint,
    method: 'get',
    types: [UNIT_REQUEST, UNIT_SUCCESS, UNIT_FAILURE],
  };

  if (isAuthenticated) {
    apiProps = Object.assign({}, apiProps, { authenticated: isAuthenticated });
  }

  return {
    [CALL_API]: apiProps,
  };
};

export const saveUnit = (data) => {
  const endpoint = 'skillbuilder/unit';

  data.isavailable = data.isavailable === 'true' || data.isavailable === true;

  return {
    [CALL_API]: {
      data,
      endpoint,
      method: 'post',
      authenticated: true,
      types: [CREATE_UNIT_REQUEST, CREATE_UNIT_SUCCESS, CREATE_UNIT_FAILURE],
    },
  };
};

export const updateUnit = (data, index) => {
  const endpoint = `skillbuilder/unit/${data.id}`;

  data.isavailable = data.isavailable === 'true' || data.isavailable === true;

  return {
    [CALL_API]: {
      data,
      index,
      endpoint,
      method: 'put',
      authenticated: true,
      types: [UPDATE_UNIT_REQUEST, UPDATE_UNIT_SUCCESS, UPDATE_UNIT_FAILURE],
    },
  };
};

export const deleteUnit = (unitId) => {
  const endpoint = `skillbuilder/unit/${unitId}`;

  return {
    [CALL_API]: {
      endpoint,
      id: unitId,
      method: 'delete',
      authenticated: true,
      types: [DELETE_UNIT_REQUEST, DELETE_UNIT_SUCCESS, DELETE_UNIT_FAILURE],
    },
  };
};

export const addUnitBadge = (data, unit) => {
  const endpoint = 'badge/badge';

  return {
    [CALL_API]: {
      endpoint,
      data: data,
      id: unit,
      method: 'post',
      authenticated: true,
      types: [
        ADD_UNIT_BADGE_REQUEST,
        ADD_UNIT_BADGE_SUCCESS,
        ADD_UNIT_BADGE_FAILURE,
      ],
    },
  };
};

export const updateUnitBadge = (data, badgeID, unit) => {
  const endpoint = `badge/badge/${badgeID}`;

  return {
    [CALL_API]: {
      endpoint,
      data: data,
      id: unit,
      method: 'patch',
      authenticated: true,
      types: [
        UPDATE_UNIT_BADGE_REQUEST,
        UPDATE_UNIT_BADGE_SUCCESS,
        UPDATE_UNIT_BADGE_FAILURE,
      ],
    },
  };
};

export const deleteUnitBadge = (badgeId, unitId) => {
  const endpoint = `badge/badge/${badgeId}`;

  return {
    [CALL_API]: {
      endpoint,
      id: unitId,
      method: 'delete',
      authenticated: true,
      types: [
        DELETE_UNIT_BADGE_REQUEST,
        DELETE_UNIT_BADGE_SUCCESS,
        DELETE_UNIT_BADGE_FAILURE,
      ],
    },
  };
};
