import React, { Component } from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

class StyleButton extends Component {
  static propTypes = {
    style: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onToggle: PropTypes.func.isRequired,
    editorUniqueId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.onToggle = this.onToggle.bind(this);
  }

  onToggle(event) {
    event.preventDefault();

    this.props.onToggle(this.props.style);
  }

  render() {
    const { icon, active, label, editorUniqueId } = this.props;
    const wrapperClassName = classNames('btn', 'btn-secondary', {
      active: active,
    });

    const iconClassName = classNames('fa', icon);

    return (
      <span
        id={`${label}-${editorUniqueId}`}
        className={wrapperClassName}
        onMouseDown={this.onToggle}>
        <i className={iconClassName} />
      </span>
    );
  }
}

export default StyleButton;
