import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as publicationActions from '../../../redux/actions/publications';
import * as componentsActions from '../../../redux/actions/components';

import Validator from '../../../validator';

import Loading from '../../../components/common/Loading';
import PublicationForm from '../../../components/profile/forms/PublicationForm';
import PublicationList from '../../../components/profile/sections/PublicationList';
import ConfirmModal from '../../../components/portfolio/ConfirmModal';
import ProfileCardHeader from '../../../components/profile/header/ProfileCardHeader';

class Publication extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    publication: PropTypes.object.isRequired,
    publications: PropTypes.array.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    isPublicProfile: PropTypes.bool.isRequired,
    showVisibilitySelect: PropTypes.bool,
    onChangeVisibility: PropTypes.func,
    isEditingSections: PropTypes.bool,
    onToggleSectionEdit: PropTypes.func,
    onConfirmDeleteSection: PropTypes.func,
    modal: PropTypes.string,
    isUpdatingProfile: PropTypes.bool,
    profile: PropTypes.object,
    isDeleting: PropTypes.bool,
    isUpdating: PropTypes.bool
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      errors: {},
      isEditing: false,
      isSubmitting: false,
      editingId: null,
      publication: Object.assign({}, this.props.publication),
      editorText: { description: this.props.publications.description }
    };

    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDeleteSection = this.onDeleteSection.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.onEditPublication = this.onEditPublication.bind(this);
    this.onDeletePublication = this.onDeletePublication.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
  }

  componentDidMount() {
    const { profile } = this.props;
    this.props.actions.publicationsRequest(profile.id);
  }

  isValid(field = null) {
    const rules = {
      title: ['required', 'minLength|6', 'maxLength|300'],
      author: ['maxLength|300'],
      publication: ['maxLength|300'],
      puburl: ['unsafeurl'],
      pubdate: ['required', 'startdateYear'],
    };

    const { publication, errors: prevErrors} = this.state;
    const validate = Validator.createValidator(rules, publication, field);
    const { errors, isValid } = validate;

    if ( field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: Object.assign({}, prevErrors, errors)});

    return isValid;
  }

  isValidDescription(field = null) {
    const rules = {
      description: ['maxWords|200']
    };
    const { editorText, errors: prevErrors } = this.state;
    const validate = Validator.createValidator(rules, editorText, field);
    const { errors, isValid } = validate;

    if (field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }
    this.setState({ errors: Object.assign({}, prevErrors, errors) });

    return isValid;
  }

  onChange(event) {
    const { name, value } = event.target;
    const { publication } = this.state;

    publication[name] = value;
    this.setState({ publication }, () => this.isValid(name));
  }

  onChangeDescription(value, ...params) {
    const { publication, editorText } = this.state;

    publication['description'] = value;
    editorText['description'] = params[2].getText().replace(/\r?\n|\r/gm, ' ');

    this.setState({ publication, editorText }, () => this.isValidDescription('description'));
  }

  onSave(event) {
    event.preventDefault();

    this.setState({ isSubmitting: true });

    if (! this.isValid() || ! this.isValidDescription()) {
      return this.setState({ isSubmitting: false });
    }

    const { publication, editingId } = this.state;
    const { actions, publications } = this.props;

    if(editingId !== null){
      const index = publications.findIndex(element =>
        element.id === publication.id);

      return actions.updatePublication(index, publication)
        .then(() => this.handleResponse());
    }

    this.props.actions.savePublication(publication)
      .then(() => this.handleResponse());
  }

  onEditPublication(id){
    const { publications } = this.props;
    const pubObject = publications.find(pub => pub.id === id);
    const publication = Object.assign({}, this.state.publication, pubObject);
    this.setState({
      isEditing: true,
      publication,
      editingId: id
    }, () => this.props.onToggleSectionEdit('publication'));
  }

  onDeletePublication(event) {
    event.preventDefault();

    const { publication } = this.state;
    this.props.actions.deletePublication(publication.id)
      .then(() => this.handleResponse());
  }

  onCancel() {
    this.setState({
      isEditing: false,
      errors: {},
      editingId: null,
      publication: this.props.publication
    }, () => this.props.onToggleSectionEdit('publication'));
  }

  onEditClick(event) {
    event.preventDefault();

    this.setState({ isEditing: true },
      () => this.props.onToggleSectionEdit('publication'));
  }

  onDeleteSection(){
    this.props.actions.openModal('delete-webpage-publication-section-modal');
  }

  onCloseModal(){
    this.props.actions.closeModal();
  }

  onDeleteConfirm(){
    const { publications, actions } = this.props;

    publications.map(publication => actions.deletePublication(publication.id));
    this.props.onConfirmDeleteSection('Publication');
  }

  handleResponse() {
    const publication = {
      title: '',
      publication: '',
      puburl: '',
      pubdate: '',
      author: '',
      description: ''
    };

    this.setState({ isEditing: false, isSubmitting: false, publication },
      () => this.props.onToggleSectionEdit('publication'));
  }

  render() {
    const {
      modal,
      publications,
      isRequesting,
      isPublicProfile,
      showVisibilitySelect,
      onChangeVisibility,
      isEditingSections,
      isUpdatingProfile,
      isUpdating,
      isDeleting
    } = this.props;
    const { errors, publication, isEditing, isSubmitting, editingId } = this.state;

    if(publications.length === 0 && isPublicProfile){
      return null;
    }

    const isDeleteConfirmModalOpen = modal && modal === 'delete-webpage-publication-section-modal';

    return (
      <div className="profile-item publications">
        <ProfileCardHeader
          isEditing={isEditing}
          isEditingSections={isEditingSections}
          type="list"
          title="Publications"
          onEditClick={this.onEditClick}
          isPublicProfile={isPublicProfile}
          showVisibilitySelect={showVisibilitySelect}
          onDeleteClick={this.onDeleteSection}
          onChangeVisibility={onChangeVisibility}
          icon="publications"
          isDeletable/>

        {isRequesting ?
          <Loading/> :
          isEditing ?
            <div className="profile-item__card">
              <PublicationForm
                errors={errors}
                publication={publication}
                isSubmitting={isSubmitting}
                isUpdating={isUpdating}
                isDeleting={isDeleting}
                showDelete={editingId !== null}
                onChange={this.onChange}
                onSave={this.onSave}
                onCancel={this.onCancel}
                onChangeDescription={this.onChangeDescription}
                onDelete={this.onDeletePublication}/> 
            </div> :
            <PublicationList
              publications={publications}
              onEdit={this.onEditPublication}
              isPublicProfile={isPublicProfile}/>}

        {
          isDeleteConfirmModalOpen &&
            <ConfirmModal
              modalHeaderText="Delete Section"
              modalMessage="Are you sure you want to permanently delete this Career Site section?"
              modalFooterButtonText="Yes, Delete"
              onCancel={this.onCloseModal}
              onConfirm={this.onDeleteConfirm}
              isConfirming={isUpdatingProfile}
              confirminginProgressText="Deleting..."
              isOpen={isDeleteConfirmModalOpen}/>
        }

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const publication = {
    title: '',
    publication: '',
    puburl: '',
    pubdate: '',
    author: '',
    description: ''
  };

  return {
    publication,
    modal: state.components.modal,
    publications: state.publications.data,
    isUpdatingProfile: state.profile.isUpdating,
    isRequesting: state.publications.isRequesting,
    isDeleting: state.publications.isDeleting.status,
    isUpdating: state.publications.isUpdating.status
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, publicationActions, componentsActions);
  return {
    actions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Publication);
